import { DatasourcedWidgetMetadata } from '@/modules/ta/widget/models/metadata/DataSourcedWidgetMetadata';
import { GoalDrawOption } from '@/modules/ta/widget/models/drawoptions/GoalsDrawOption';

export class GoalWidgetMetadata extends DatasourcedWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new GoalDrawOption(model.draw_options);
  }
}
