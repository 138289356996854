'use strict';
import angular from 'angular';
import $ from 'jquery';

import exportBuilderStandaloneHtmlUrl from './exportbuilder.standalone.html';

angular.module('exportbuilder.directives')
    .directive('exportBuilderStandalone', exportBuilderStandalone);

//
//  directive export builder: show the main base page of the builder
//
exportBuilderStandalone.$inject = [];
function exportBuilderStandalone() {
    return {
        restrict: 'E',
        controller: 'ExportBuilderStandaloneController',
        templateUrl: exportBuilderStandaloneHtmlUrl
    }
}