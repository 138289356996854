import { ChartDrawOption } from '@/modules/ta/widget/models/drawoptions/ChartDrawOption';

export class PieChartDrawOption extends ChartDrawOption {
  /**
   * @var {boolean}
   */
  show_labels;

  /**
   * @var {boolean}
   */
  show_label_names;

  /**
   * @var {boolean}
   */
  show_label_values;

  /**
   * @var {boolean}
   */
  show_label_percent;

  /**
   * @var {boolean}
   */
  is_normalized;

  /**
   * @var {boolean}
   */
  start_angle;

  /**
   * @var {number}
   */
  other_percent;

  /**
   * @var {boolean}
   */
  label_percent;

  /**
   * @var {boolean}
   */
  inner_radius;

  /**
   * @var {number}
   */
  rounded_corners;

  /**
   * @var {boolean}
   */
  show_column_shadows;

  /**
   * @var {number}
   */
  pie_semicircle_angle;

  /**
   * @var {string}
   */
  gradientType;

  /**
   * @var {string}
   */
  gradientColor;

  /**
   * @var {string}
   */
  gradient_color;

  /**
   * @var {boolean}
   */
  showGrainyPattern;

  /**
   * @var {number}
   */
  grainyPatternSize;

  /**
   * @var {number}
   */
  grainyPatternDensity;

  /**
   * @var {boolean}
   */
  showDonutSum;

  /**
   * @var {boolean}
   */
  showBorders;

  /**
   * @var {number}
   */
  pie_rotation_angle;

  /**
   * @var {string}
   */
  pieGradientOptions;

  /**
   * @var {string}
   */
  pieColorPicker;

  /**
   * @var {number}
   */
  v2InnerRadius;

  /**
   * @var {boolean}
   */
  pieVariableRadius;

  /**
   * @var {boolean}
   */
  classicDrillDownBehaviour;

  /**
   * @var {string}
   */
  fontColorPicker;

  /**
   * @var {string}
   */
  alignLabels;

  constructor(model = {}) {
    super(model);

    this.show_labels = model.show_labels !== undefined ? model.show_labels : true;
    this.show_label_percent =
      model.show_label_percent !== undefined ? model.show_label_percent : true;
    this.show_label_names = model.show_label_names !== undefined ? model.show_label_names : true;
    this.show_label_values = model.show_label_values || false;
    this.start_angle = model.start_angle || 90;
    this.is_normalized = model.is_normalized || false;
    this.other_percent = model.other_percent || 0;
    this.label_percent = model.label_percent || 0;
    this.inner_radius = model.inner_radius || 0;
    this.hide_grid_lines = model.hide_grid_lines || false;
    this.has_tooltip = model.has_tooltip ?? true;
    this.depth = model.depth ?? 0;
    this.angle = model.angle ?? 0;
    this.is_hand_drawn = model.is_hand_drawn ?? false;
    this.rounded_corners = model.rounded_corners ?? 0;
    this.show_column_shadows = model.show_column_shadows ?? false;
    this.pie_semicircle_angle = model.pie_semicircle_angle;
    this.gradientType = model.background_gradient;
    this.gradientColor = model.gradient_color;
    this.showGrainyPattern = model.show_grainy_pattern ?? false;
    this.grainyPatternSize = model.grainy_pattern_size ?? 1;
    this.grainyPatternDensity = model.pie_grain_density ?? 0;
    this.showDonutSum = model.pie_donut_sum ?? false;
    this.showBorders = model.pie_border_stroke ?? false;
    this.pie_rotation_angle = model.pie_rotation_angle ?? 0;
    this.pieGradientOptions = model.pie_gradient_options;
    this.pieColorPicker = model.pie_color_picker;
    this.v2InnerRadius = model.v2_inner_radius ?? 40;
    this.pieVariableRadius = model.pie_variable_radius ?? false;
    this.classicDrillDownBehaviour = model.classic_drill_down_behaviour ?? false;
    this.fontColorPicker = model.font_color_picker;
    this.alignLabels = model.align_labels ?? false;
  }
}
