'use strict';
import angular from 'angular';
import './design.layout.constant';
import './base/design.layout.services';
import './base/design.layout.directives';
import './isotope/design.isotope';
import './drawoptionpanel/drawoptionpanel.module';

angular.module('design.layout', [
    'design.layout.constants',
    'design.layout.services',
    'design.layout.directives',
    'design.isotope',
    'layout.drawoptionpanel'
]);