"use strict";
import $ from 'jquery';

var Core = $.core.admin.role = {

    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {

    },
    events: function () {
        $('#role-association-field').hide();
        Core.roleTypeBinding();
        Core.toggleFields($('#role_type').val());
        Core.roleAssociationBinding();
        Core.toggleAssociation($('#role_association').val());
    },
    roleAssociationBinding: function() {
        $('#role_association').change(function() {
            Core.toggleAssociation($(this).val());
        });
    },
    roleTypeBinding: function() {
        $('#role_type').change(function() {
            Core.toggleFields($(this).val());
        });
    },
    toggleAssociation: function(val) {
        var $associationAgent = $('.association-agent');
        var $associationClusterAdmin = $('.association-cluster-admin');
        var $clusterSelector = $('.cluster-selector');

        $associationAgent.hide();
        $associationClusterAdmin.hide();

        if (val == 'agent') {
            $associationAgent.show();
            $clusterSelector.show();
        }
        else if (val == 'cluster_admin') {
            $associationClusterAdmin.show();
            $clusterSelector.hide();
        }
    },
    toggleFields: function(val) {
        var $onlyRoletypeAnalytics = $('.only-roletype-analytics');
        var $onlyRoletypeIotool = $('.only-roletype-iotool');

        $onlyRoletypeAnalytics.hide();
        $onlyRoletypeIotool.hide();
        if (val == 'io_tool') {
            $onlyRoletypeIotool.show();

            $('#role_association').val('agent');
            $('#role-association-field').hide();
        }
        else if (val == 'analytics') {
            $onlyRoletypeAnalytics.show();

            $('#role-association-field').show();

            if($('#role_association').val() === ''){
                $('#role_association').val('agent');
                $('#role_association').trigger('change');
            }
            if($('#role_association').val() === 'agent'){
                $('#role_association').trigger('change');
            }

        }
    }
};