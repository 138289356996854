'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('campaign.ctrls', [])

    .controller('CampaignListController', CampaignListController)
    .controller('CampaignController', CampaignController);

//
// Campaign grid controller
//
CampaignListController.$inject = ['$scope', '$state', 'CampaignService'];
function CampaignListController($scope, $state, CampaignService) {

    $scope.applyFilter = function() {
        var selectedData = $('#service_id').data('select2').data();
        if (selectedData != null) {
            if ($state.params.service_id != selectedData.service_id) {
                $.core.main.showAjaxMainLoading({text: 'Applying ' + selectedData.text + ' filter...'});
                $state.go('campaigns.filter', {service_id: selectedData.service_id});
            }
            else {
                $.core.main.notify('Data source already selected', $.globals.notify.warning);
            }
        }
        else {
            $.core.main.notify('You must select a data source first', $.globals.notify.warning);
        }
    };
}

//
// Campaign controller
//
CampaignController.$inject = ['$scope', '$timeout', 'CampaignService'];
function CampaignController($scope, $timeout, CampaignService) {
}