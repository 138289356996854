import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function useTooltipAdjustments(context) {
  const { config } = context();
  const MINIMUM_WIDTH = 5;
  const NON_MOUSEOVER_WIDTH = 2;

  function adjustWidth(element, width) {
    if (width < NON_MOUSEOVER_WIDTH) {
      element.set(Constant.TOP_WIDTH, MINIMUM_WIDTH);
      element.set(Constant.BOTTOM_WIDTH, MINIMUM_WIDTH);

      return MINIMUM_WIDTH;
    }

    return width;
  }

  function checkForTooNarrowSlices(series) {
    const targetWidth = config.value.isRotated ? Constant.TOP_WIDTH : Constant.BOTTOM_WIDTH;

    series.slices.template?.adapters.add(targetWidth, (widthElement, target) => {
      if (!target) {
        return widthElement;
      }

      return adjustWidth(target, widthElement);
    });

    if (!config.value.smoothLines) {
      series.links.template?.adapters.add(targetWidth, (widthElement, target) => {
        if (!target) {
          return widthElement;
        }

        adjustWidth(target, widthElement);

        return widthElement;
      });
    }
  }

  return {
    checkForTooNarrowSlices,
  };
}
