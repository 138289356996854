export const mutations = {
  /**
   * @param {OAuthState} state
   * @param {Object} params
   */
  setOAuthParams(state, params) {
    state.oAuthParams = params;
  },

  /**
   * @param {OAuthState} state
   */
  resetOAuthParams(state) {
    state.oAuthParams = null;
  },
};
