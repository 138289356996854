'use strict';
import angular from 'angular';

angular.module('servicedetails.ctrls', [])

    .controller('ServiceDetailController', ServiceDetailController);

//
// Service details controller
//
ServiceDetailController.$inject = ['$scope', 'ServiceDetailFactory'];
function ServiceDetailController($scope, ServiceDetailFactory) {
}