import { useChart } from '@/modules/core/charts/am5/base/composables/useChart';
import { useXYAxes } from '@/modules/core/charts/am5/base/composables/axis/useXYAxes';
import { useXYSeries } from '@/modules/core/charts/am5/base/composables/series/useXYSeries';
import { useScrollbar } from '@/modules/core/charts/am5/base/composables/useScrollbar';
import { useBullets } from '@/modules/core/charts/am5/line/composables/useBullets';
import { useXYTooltip } from '@/modules/core/charts/am5/base/composables/useXYTooltip';
import { useLegend } from '@/modules/core/charts/am5/base/composables/useLegend';
import { useMetricLabels } from '@/modules/core/charts/am5/bar/composables/useMetricLabels';
import { useMetricLabels as useLineMetricLabels } from '@/modules/core/charts/am5/line/composables/useMetricLabels';
import { useCustomZoomOutButton } from '@/modules/core/charts/am5/base/composables/useCustomZoomOutButton';
import { useXYDrillDown } from '@/modules/core/charts/am5/base/composables/useXYDrillDown';
import { useXYAxesTooltip } from '@/modules/core/charts/am5/base/composables/axis/useXYAxesTooltip';
import { useXYChart } from '@/modules/core/charts/am5/base/composables/useXYChart';
import { useSeries } from '@/modules/core/charts/am5/line/composables/useSeries';
import { useBenchmarkSeries } from '@/modules/core/charts/am5/base/composables/useBenchmarkSeries';

export function useComboChart(context) {
  const { chart, config } = context();

  const { configureChart } = useChart(context);
  const { createAxes } = useXYAxes(context);
  const { createColumnSeries, applyDataToAllSeries } = useXYSeries(context);
  const { createLineSeries } = useSeries(context);
  const { createXYChart, createXYCursor } = useXYChart(context);
  const { createScrollbar } = useScrollbar(context);
  const { createBullets } = useBullets(context);
  const { createTooltip } = useXYTooltip(context);
  const { createLegend } = useLegend(context);
  const { createMetricLabels } = useMetricLabels(context);
  const { createMetricLabels: createLineMetricLabels } = useLineMetricLabels(context);
  const { changeZoomOutButton } = useCustomZoomOutButton(context);
  const { createDrillDownEffect } = useXYDrillDown(context);
  const { createAxesTooltip } = useXYAxesTooltip(context);
  const { addBenchmarkSeries } = useBenchmarkSeries(context);

  function initComboChart() {
    createXYChart();
    configureChart();
    createAxes();

    createColumnSeries();
    createLineSeries();
    createBullets();

    changeZoomOutButton();

    if (config.value.hasValueScrollbar) {
      createScrollbar();
    }

    if (config.value.legend.active) {
      createLegend();
    }

    if (config.value.hasTooltip) {
      createTooltip();
      createAxesTooltip();
      createXYCursor();
    }

    if (config.value.showMetricLabels) {
      createMetricLabels();
      createLineMetricLabels();
    }

    applyDataToAllSeries();

    createDrillDownEffect();

    if (config.value.benchmarks?.enabled) {
      addBenchmarkSeries();
    }
  }

  return {
    chart,
    initComboChart,
  };
}
