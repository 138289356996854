import { DurationAxis } from '@amcharts/amcharts5/xy';

export function useDurationAxis(context) {
  const { root, config } = context();

  function createDurationAxis(props, renderer) {
    const axis = DurationAxis.new(root.value, {
      renderer,
    });
    axis.data.setAll(config.value.data);
    return axis;
  }

  return {
    createDurationAxis,
  };
}
