'use strict';
import angular from 'angular';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import SeleniumHelper from '../../../seleniumtest';

import alertModalHtmlUrl from './alert.modal.html';
import alertItemGroupbySelectHtmlUrl from '../item/alert.item.groupby.select.html';

angular.module('core.alert.components', [])

    .component('alertModal', {
        templateUrl: alertModalHtmlUrl,
        bindings: {
            isAdminPage: '<'
        },
        controller: AlertModalController,
        controllerAs: 'vm'
    })

    .component('alertGroupbySelect', {
        templateUrl: alertItemGroupbySelectHtmlUrl,
        bindings: {
            datasource: '=',
            selectedGroupingField: '=',
            groupingFields: '=',
            groupbyColumns: '<',
            state: '='
        },
        controller: AlertGroupbySelectController,
        controllerAs: 'vm'
    })
    .component('alertCondtionSelect', {
        templateUrl: alertItemGroupbySelectHtmlUrl,
        bindings: {
            datasource: '<',
            selectedConditionField: '=',
            conditionFields: '=',
            conditionColumns: '<',
            state: '=',
            onChange: '<'
        },
        controller: AlertConditionSelectController,
        controllerAs: 'vm'
    });


/**
 * @ngInject
 */
function AlertModalController(
    $scope,
    $timeout,
    $AlertEvents,
    PubSub,
    DataSourceType,
    WidgetFactory,
    AlertFactory,
    AlertItemFactory,
    AlertEnumFactory,
    AlertModelFactory,
    UIFactory,
    AppFactory,
    LayoutFactory,
    UserFactory
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.state = AlertModelFactory.getDefaultState();

    vm.initAlert = initAlert;
    vm.saveAlert = saveAlert;
    vm.removeAlert = removeAlert;
    vm.addAlert = addAlert;
    vm.reset = reset;

    function $onInit() {
        _registerEvents();

        AlertEnumFactory.getAlertAggregationType().then(function(data) {
            vm.aggregationTypes = data;
        });
        vm.services = AppFactory.getConnectedServices();

        // Option object inherited by all child alert items
        var options = vm.options = {};

        //Intialize the list of users
        options.recipientSelectOptions = AlertModelFactory.getRecipientSelectOptions();

        UserFactory.getData({
            user_type: AlertFactory.getUserTypeParams(true, false),
            status: 'active'
        }).then(function(data){
            var users = data.plain();
            options.recipientSelectOptions.data = AlertFactory.genUserSelectData(users);
            options.recipientSelectOptions.loaded = true;
        });
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _registerEvents() {
        PubSub.on($AlertEvents.INIT_ALERT, initAlert);
    }

    function _unregisterEvents() {
        PubSub.off($AlertEvents.INIT_ALERT, initAlert);
    }

    /**
     * @param alert Alert to edit
     */
    function initAlert(alert) {
        UIFactory.showModal('edit-alert-modal');
        var isNew = _.isNil(alert);
        vm.model = AlertModelFactory.getDefaultAlertModel(alert);

        if (_.isEmpty(vm.model.alerts)) {
            addAlert();
        }
        AlertFactory.initAlerts(vm.model, isNew);
    }

    function saveAlert() {
        AlertFactory.saveItemAlert(vm.model, vm.state);
    }

    function addAlert() {
        vm.model.alerts.push(createAlertObject());
        vm.state.showDelete = vm.model.alerts.length > 1;
    }

    function removeAlert(alertToRemove) {
        AlertFactory.removeAlert(vm.model, vm.state, alertToRemove,function() {
            swal.close();
        });
    }

    //Intialize the alert object
    function createAlertObject(){
        return AlertModelFactory.getNewItemAlert(vm.model);
    }

    /**
     * Variables to reset when leaving the alert process (i.e. exiting the modal)
     */
    function reset() {
        // As a safety measure force a scope digest here since closing the modal happens does not respond to ng-click
        $scope.$evalAsync(function() {
            vm.model = {};
            vm.state = AlertModelFactory.getDefaultState();
        });
    }
}

/**
 * @ngInject
 */
function AlertGroupbySelectController(
    $AlertEvents,
    $timeout,
    $scope,
    $element,
    PubSub,
    AppFactory,
    AlertFactory,
    AlertModelFactory,
    DataSourceFactory,
    DataSourceColumnFactory
){

    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.selectOnChange = onChange;
    vm.updateGroupingFields = updateGroupingFields;

    var output = [];
    var curGroupbyColumn;

    function $onInit() {
        _registerEvents();

        var queryParams = {
            is_groupable: true,
            is_primary_date_field: false,
            widget_request: true
        };

        vm.selectOptions = AlertModelFactory.getGroupbySelectOptions();

        // The widgetFactoryConfig will help specify what kind of datasourced data config we need
        if (_.isEmpty(vm.groupbyColumns)) {
            DataSourceFactory.getColumns(vm.datasource, queryParams).then(function (columns) {
                vm.groupbyColumns = columns;
                buildGroupby();
                vm.selectOptions.loaded = true;
            });
        } else {
            vm.selectOptions.loaded = true;
            buildGroupby();
        }
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _registerEvents() {
        PubSub.on($AlertEvents.UPDATE_GROUPBY, updateGroupingFields);
    }

    function _unregisterEvents() {
        PubSub.off($AlertEvents.UPDATE_GROUPBY, updateGroupingFields);
    }

    function buildSelect(columns, curGroupbyColumn) {
        vm.selectOptions.formatSelection = vm.selectOptions.formatResult;
        SeleniumHelper.addSelectClasses(vm.selectOptions, 'group-by-column');

        if (_.isEmpty(output)) {
            output = DataSourceColumnFactory.preProcessGroupColumns(columns);
        }

        if (_.isObject(curGroupbyColumn)) {
            curGroupbyColumn.id = curGroupbyColumn.field;
            curGroupbyColumn.text = curGroupbyColumn.label;
        }

        selectColumn();
    }

    function buildGroupby() {
        updateCurrentGroupbyColumn();
        buildSelect(vm.groupbyColumns, curGroupbyColumn);
    }

    function selectColumn() {
        // Modify the select2 data and disable the selected item
        // NOTE: the format being passed here in the data object is
        // actually the groupby_field_format from output

        if (curGroupbyColumn) {
            var group = _.find(output, {groupby_field_format: curGroupbyColumn.groupby_field_format});
            disableColumn(group);
        } else {
            _.each(output, function(group) {
                disableColumn(group);
            });
        }

        vm.selectOptions.data = output;
        vm.selectedOptions = curGroupbyColumn;
    }

    function disableColumn(group) {
        _.each(group.children, function (item) {
            item.disabled = false;
        });

        _.each(group.children, function (item) {
            _.each(vm.groupingFields, function(selected) {
                if (selected.field === item.id) {
                    item.disabled = true;
                }
            });
        });
    }

    function onChange($element) {
        $scope.$evalAsync(function() {
            var data = $element.select2('data');
            updateGroupby(data);
        });
    }

    function updateGroupby(data) {
        var oldValue = {
            field: vm.selectedGroupingField.field,
            label: vm.selectedGroupingField.label
        };

        var newValue = {
            field: data.id,
            label: data.text
        };
        var values = {oldValue: oldValue, newValue: newValue};
        // update current selection
        vm.selectedGroupingField.field = data.id;
        vm.selectedGroupingField.label = data.text;
        updateGroupingFields(values);
        PubSub.emit($AlertEvents.UPDATE_GROUPBY, values, true);
    }

    /**
     * Update selected group by fields
     * @param values
     */
    function updateGroupingFields(values) {
        var oldValue = values.oldValue;
        var newValue = values.newValue;
        if (!_.isEmpty(newValue)) {
            // update old value to maintain the order
            _.each(vm.groupingFields, function(item) {
                if (!_.isNil(item.field) && item.field === oldValue.field) {
                    item.field = newValue.field;
                    item.label = newValue.label;
                    vm.selectedGroupingField = item;
                    return false; // break
                }
            });
        }
        updateCurrentGroupbyColumn();
        selectColumn();
    }

    function updateCurrentGroupbyColumn() {
        if (vm.selectedGroupingField && vm.selectedGroupingField.field) {
            curGroupbyColumn = _.find(vm.groupbyColumns, {field: vm.selectedGroupingField.field});
        } else {
            curGroupbyColumn = null;
        }
    }
}

function AlertConditionSelectController(
    $AlertEvents,
    $timeout,
    $scope,
    $element,
    PubSub,
    AppFactory,
    AlertFactory,
    AlertModelFactory,
    DataSourceFactory,
    DataSourceColumnFactory
){

    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.selectOnChange = onChange;
    vm.updateConditionFields = updateConditionFields;

    var output = [];
    var curConditionColumn;

    function $onInit() {
        _registerEvents();

        vm.selectOptions = AlertModelFactory.getConditionSelectOptions();

        var queryParams = {
            is_hidden: false
        };

        // The widgetFactoryConfig will help specify what kind of datasourced data config we need
        if (_.isEmpty(vm.conditionColumns)) {
            DataSourceFactory.getColumns(vm.datasource, queryParams).then(function (columns) {
                vm.conditionColumns = columns;
                buildCondition();
                vm.selectOptions.loaded = true;
            });
        } else {
            vm.selectOptions.loaded = true;
            buildCondition();
        }
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _registerEvents() {
        PubSub.on($AlertEvents.UPDATE_CONDITION, updateConditionFields);
    }

    function _unregisterEvents() {
        PubSub.off($AlertEvents.UPDATE_CONDITION, updateConditionFields);
    }

    function buildSelect(columns, curConditionColumn) {
        vm.selectOptions.formatSelection = vm.selectOptions.formatResult;
        SeleniumHelper.addSelectClasses(vm.selectOptions, 'group-by-column');

        if (_.isEmpty(output)) {
            output = DataSourceColumnFactory.preProcessGroupColumns(columns);
        }

        if (_.isObject(curConditionColumn)) {
            curConditionColumn.id = curConditionColumn.field;
            curConditionColumn.text = curConditionColumn.label;
        }

        selectColumn();
    }

    function buildCondition() {
        updateCurrentConditionColumn();
        buildSelect(vm.conditionColumns, curConditionColumn);
    }

    function selectColumn() {
        // Modify the select2 data and disable the selected item
        // NOTE: the format being passed here in the data object is
        // actually the condition_field_format from output

        _.each(output, function(group) {
            disableColumn(group);
        });

        vm.selectOptions.data = output;
        vm.selectedOptions = curConditionColumn;
    }

    function disableColumn(group) {
        _.each(group.children, function (item) {
            item.disabled = false;
        });

        _.each(group.children, function (item) {
            _.each(vm.conditionFields, function(selected) {
                if (selected.metric_column === item.id) {
                    item.disabled = true;
                }
            });
        });
    }

    function onChange($element) {
        $scope.$evalAsync(function() {
            var data = $element.select2('data');
            updateCondition(data);
        });
    }

    function updateCondition(data) {
        var oldValue = {
            metric_column: vm.selectedConditionField.metric_column
        };

        var newValue = {
            metric_column: data.id
        };
        var values = {oldValue: oldValue, newValue: newValue};
        // update current selection
        vm.selectedConditionField.metric_column = data.id;
        updateConditionFields(values);
        PubSub.emit($AlertEvents.UPDATE_CONDITION, values, true);
    }

    /**
     * Update selected condition fields
     * @param values
     */
    function updateConditionFields(values) {
        var oldValue = values.oldValue;
        var newValue = values.newValue;
        if (!_.isEmpty(newValue)) {
            // update old value to maintain the order
            _.each(vm.conditionFields, function(item) {
                if (!_.isNil(item.metric_column) && item.metric_column === oldValue.metric_column) {
                    item.metric_column = newValue.metric_column;
                    vm.selectedConditionField = item;
                    return false; // break
                }
            });
        }
        AlertFactory.updateMetricPrecision(vm.conditionColumns, vm.conditionFields);
        updateCurrentConditionColumn();
        selectColumn();
    }

    function updateCurrentConditionColumn() {
        if (vm.selectedConditionField && vm.selectedConditionField.metric_column) {
            curConditionColumn = _.find(vm.conditionColumns, {field: vm.selectedConditionField.metric_column});
        } else {
            curConditionColumn = null;
        }
    }
}