import { Tooltip } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function useTooltip(context) {
  const { root, chart } = context();

  /**
   * @param labelGetter {function(series: Series, integer): string}
   */
  function createTooltip(labelGetter) {
    chart.value.series.each((series, seriesIndex) => {
      const labelText = labelGetter(series, seriesIndex);
      if (labelText) {
        series.set(
          Constant.TOOLTIP,
          Tooltip.new(root.value, {
            labelText,
            pointerOrientation: Constant.HORIZONTAL,
          })
        );
      }
    });
  }

  return {
    createTooltip,
  };
}
