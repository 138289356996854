export class BulletConfig {
  /**
   * Is bullets enabled or not
   * @var {boolean}
   */
  enabled;

  /**
   * Color of bullets
   * @var {string}
   */
  fill;

  /**
   * Opacity of filled color
   * @var {integer}
   */
  fillOpacity;

  /**
   * Shape of bullets
   * @var {string}
   */
  shape;

  /**
   * Rotation of shape
   * @var {number}
   */
  rotation;

  constructor(model = {}) {
    this.enabled = model.enabled;
    this.fill = model.fill;
    this.fillOpacity = model.fillOpacity;
    this.shape = model.shape;
    this.rotation = model.rotation ?? 0;
  }
}
