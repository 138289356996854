'use strict';
import angular from 'angular';
import $ from 'jquery';
import swal from 'bootstrap-sweetalert';

import messengerPanelHtmlUrl from './messenger.panel.html';
import messengerHtmlUrl from './messenger.html';
import messengerThreadHtmlUrl from './messenger.thread.html';

angular.module('core.messenger.components', [])
    .component('messengerPanelBefore', {})
    .component('messengerPanelAfter', {})
    .component('messengerPanel', {
        templateUrl: messengerPanelHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            options: '<',
            threadOptions: '<'
        },
        transclude: {
            panelBefore: '?messengerPanelBefore',
            panelAfter: '?messengerPanelAfter',
        },
        controller: MessengerPanelController
    })
    .component('messenger', {
        templateUrl: messengerHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            threads: '<',
            threadOptions: '<'
        },
        controller: MessengerController
    })
    .component('messengerThread', {
        templateUrl: messengerThreadHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            thread: '='
        },
        controller: MessengerThreadController
    });

function MessengerPanelController(
    SlidePanelFactory,
    MessengerFactory
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.onClose = onClose;
    vm.isLoading = MessengerFactory.getIsLoading;
    vm.getThreads = MessengerFactory.getThreads;

    function $onInit() {
        MessengerFactory.setOptions(vm.options);
    }

    function onClose() {
        _closePanel();
    }

    /**
     * @private
     */
    function _closePanel() {
        MessengerFactory.clearThreads();
        MessengerFactory.setOptions();
        SlidePanelFactory.closeAll();
    }
}

function MessengerController(
    PubSub,
    $timeout,
    $MessengerEvents,
    MessengerFactory
) {
    var vm = this;
    var $textInput = null;
    vm.isInputActive = false;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.saveThread = saveThread;
    vm.getCurrentUserImage = MessengerFactory.getCurrentUserImage;
    vm.newThread = vm.threadOptions;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _setInputBindings() {
        $textInput.on('keydown keypress', _enterFnHandler);
        $textInput.on('focus', _focusHandler);
        $textInput.on('blur', _blurHandler);
    }

    function _unsetInputBindings() {
        $textInput.off('keydown keypress', _enterFnHandler);
        $textInput.off('focus', _focusHandler);
        $textInput.off('blur', _blurHandler);
    }

    function _focusHandler() {
        $timeout(function() {
            vm.isInputActive = true;
        }, 0);
    }

    function _blurHandler(event) {
        $timeout(function() {
            var $el = angular.element(event.currentTarget);
            vm.isInputActive = $el.val().length;
        }, 0);
    }

    function _enterFnHandler(event) {
        var $el = event.currentTarget;
        if (event.shiftKey && event.which === 13) { // Shift + Enter
            $el.style.height = '5px';
            $el.style.height = ($el.scrollHeight + 5) + 'px';
        } else if (event.which === 8) { // Backspace
            var position = window.getSelection().getRangeAt(0).startOffset;
            if (position === 0) {
                $el.style.height = ($el.style.height - 32) + 'px';
            }
        } else if (event.which === 13) { // Enter
            event.preventDefault();
            saveThread(vm.newThread);
        }
    }

    function saveThread(thread) {
        PubSub.emit($MessengerEvents.SAVE_THREAD, thread);
    }

    function _saveThreadCallback() {
        vm.newThread.closeEdit();
        vm.newThread.reset();
    }

    function _registerEvents() {
        PubSub.on($MessengerEvents.SAVE_THREAD_CALLBACK, _saveThreadCallback);
        $timeout(function() {
            $textInput = angular.element('messenger textarea');
            _setInputBindings();
        }, 100, false);
    }

    function _unregisterEvents() {
        PubSub.off($MessengerEvents.SAVE_THREAD_CALLBACK, _saveThreadCallback);
        _unsetInputBindings();
    }
}

function MessengerThreadController(
    PubSub,
    UIFactory,
    $MessengerEvents,
    MessengerFactory
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getPrettyTime = MessengerFactory.getPrettyTime;
    vm.getAuthorName = MessengerFactory.getAuthorName;
    vm.confirmDeleteThread = confirmDeleteThread;
    vm.editThread = editThread;
    vm.cancelEditThread = cancelEditThread;
    vm.saveThread = saveThread;

    function $onInit() {
        _registerEvents();
    }

    function editThread(thread) {
        thread.initEdit();
        PubSub.emit($MessengerEvents.EDIT_THREAD, thread);
    }

    function saveThread(thread) {
        PubSub.emit($MessengerEvents.SAVE_THREAD, thread);
    }

    function cancelEditThread(thread) {
        thread.isEditing = false;
        thread.closeEdit();
    }

    function $onDestroy() {
        PubSub.off($MessengerEvents.DELETE_THREAD_CALLBACK, _deleteThreadCallback);
    }

    function confirmDeleteThread() {
        var options = {};
        options.text = 'This will remove the note from this thread but will be stored for audit.';
        options.callback = function() {
            _deleteThread(vm.thread.id);
        };
        UIFactory.confirmDelete(options);
    }

    function _deleteThread(id) {
        PubSub.emit($MessengerEvents.DELETE_THREAD, id);
    }

    function _deleteThreadCallback() {
        swal.close();
        UIFactory.notify.showSuccess('Message successfully deleted');
    }

    function _registerEvents() {
        PubSub.on($MessengerEvents.DELETE_THREAD_CALLBACK, _deleteThreadCallback);
    }

}