<template>
  <ChartWrapper v-bind="$props" :is-data-empty="isDataEmpty" :context="context" />
</template>

<script>
import ChartWrapper from '@/modules/core/charts/am5/base/components/ChartWrapper.vue';
import { createChartContext } from '@/modules/core/charts/am5/base/composables/createChartContext';
import { isEmpty, isNil } from 'lodash';
import { usePieChart } from '@/modules/core/charts/am5/pie/composables/usePieChart';
import { WidgetEvents } from '@/modules/core/charts/am5/charts.constants';
import { BasePropsMixin } from '@/modules/core/charts/am5/base/mixins/BasePropsMixin';
import { WidgetEvent } from '@/modules/ta/widget/widget.constants';
import EventBus from '@/modules/core/app/helpers/EventBus';

export default {
  name: 'PieChart',
  mixins: [BasePropsMixin],
  components: {
    ChartWrapper,
  },
  props: {
    isComparison: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    EventBus.listen(`${WidgetEvent.RELOAD_CHART}`, () => {
      this.initPieChart;
    });
    this.initPieChart;
  },
  setup(props, componentContext) {
    const context = createChartContext(props.config);
    const { refresh, onBind, onFrameEnded } = context();
    const { initPieChart } = usePieChart(context);

    onBind(() => {
      initPieChart();
    });

    onFrameEnded(() => {
      componentContext.emit(WidgetEvents.FRAME_ENDED);
    });

    return {
      context,
      refresh,
    };
  },
  computed: {
    isDataEmpty() {
      return isNil(this.config.data) || isEmpty(this.config.data);
    },
  },
};
</script>
