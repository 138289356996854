import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { SessionState } from './session.state';
import { getters } from './session.getters';
import { mutations } from './session.mutations';
import { actions } from './session.actions';

VueXHelper.setStoreModule(Module.SESSION, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new SessionState(),
  getters,
  mutations,
  actions,
};
