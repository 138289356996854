import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import { MomentDateFormat } from 'coreModules/daterange/base/daterange.constants';
import {ReportElementMacroTypes, ReportElementShapeTypes} from "../../exportbuilder.constants";

angular.module('exportbuilder.dashboard.services', [])
    .factory('ExportBuilderFacadeUIService', ExportBuilderFacadeUIService)
    .factory('ExportBuilderFilterService', ExportBuilderFilterService)
    .factory('ExportBuilderDashboardService', ExportBuilderDashboardService);

/**
 * @ngInject
 */
function ExportBuilderFacadeUIService(
    $rootScope,
    $timeout,
    $q,
    gettextCatalog,
    PubSub,
    $ExportBuilderIconModalEvents,
    $ExportBuilderDashboardEvents,
    $WidgetEvents,
    $WidgetLibraryEvents,
    ReportStudioTemplateDataService,
    ExportBuilderDashboardPageService,
    ExportBuilderDashboardService,
    WidgetBuilderUIService,
    ExportBuilderElementActionService,
    ExportBuilderDashboardUtilService,
    UIFactory,
    ExportBuilderDashboardItemService,
    ExportBuilderDashboardExportOptionsUIService,
    $ExportBuilderTemplateDownloadModalEvents,
    $ExportBuilderShortcutsModalEvents,
    ExportBuilderShortcutsModalUIService,
    ExportBuilderElementIconModalUIService,
    ExportBuilderPageAssignmentModalUIService,
    ExportBuilderDashboardUIModelFactory,
    FullScreenUtilUIService,
    ExportBuilderFilterPanelModelFactory,
    ExportBuilderFilterService,
    $ExportBuilderFormatChangeModalEvents,
    ExportBuilderFormatModalUIService,
    UndoRedoService,
    ReportElementTypes,
    ReportEmptyExecutiveSummary
) {
    /**
     * @type {FacadeUIState}
     */
    var uiState = ExportBuilderDashboardUIModelFactory.getFacadeUIState();

    return {
        getState: getState,
        resetState: resetState,
        setIsResizing: setIsResizing,
        getIsResizing: getIsResizing,
        setImageIsUploading: setImageIsUploading,
        getImageIsUploading: getImageIsUploading,
        toggleGridMode: toggleGridMode,
        getIsGridMode: getIsGridMode,
        toggleOutlineActive: toggleOutlineActive,
        getIsOutlineActive: getIsOutlineActive,
        toggleOverflow: toggleOverflow,
        getIsOverflow: getIsOverflow,
        refreshWidgets: refreshWidgets,
        setElementChangingImage: setElementChangingImage,
        getEditingElementImage: getEditingElementImage,
        setIsPressingShift: setIsPressingShift,
        getIsPressingShift: getIsPressingShift,
        setIsPressingCMD: setIsPressingCMD,
        getIsPressingCMD: getIsPressingCMD,
        showWidgetLibraryModal: showWidgetLibraryModal,
        showNewIconModal: showNewIconModal,
        showExportOptionsModal: showExportOptionsModal,
        showKeyboardShortCutsModal: showKeyboardShortCutsModal,
        showFormatModal: showFormatModal,

        setIsDownloadingReport: setIsDownloadingReport,
        getIsDownloadingReport: getIsDownloadingReport,
        setIsSavingEntireReport: setIsSavingEntireReport,
        getIsSavingEntireReport: getIsSavingEntireReport,
        setIsPublishReport: setIsPublishReport,
        getIsPublishReport: getIsPublishReport,

        focusOnElement: focusOnElement,
        setFocusToElements: setFocusToElements,

        removeItem: removeItem,
        removePage: removePage,

        performUndoRedo: performUndoRedo,
        performUndo: performUndo,
        performRedo: performRedo,
        selectAll: selectAll,

        setPresentationMode: setPresentationMode,
        togglePresentationMode: togglePresentationMode,
        getPresentationMode: getPresentationMode,

        moveCurrentItemUp: moveCurrentItemUp,
        moveCurrentItemDown: moveCurrentItemDown,
        moveCurrentItemLeft: moveCurrentItemLeft,
        moveCurrentItemRight: moveCurrentItemRight,

        focusOnNextItem: focusOnNextItem,
        copyToClipboard: copyToClipboard,
        cutToClipboard: cutToClipboard,
        paste: paste,

        showImageDialog: showImageDialog,
        showImageDialogFromKeyboard: showImageDialogFromKeyboard,
        changeImage: changeImage,
        fetchNewGlobalFilter: fetchNewGlobalFilter
    };

    function getState() {
        return uiState;
    }

    function resetState() {
        _.assign(uiState, ExportBuilderDashboardUIModelFactory.getFacadeUIState())
    }

    function setIsResizing(value) {
        uiState.isResizing = value;
    }

    function getIsResizing() {
        return uiState.isResizing;
    }

    function setImageIsUploading(value) {
        uiState.imageIsUploading = value;
    }

    function getImageIsUploading() {
        return uiState.imageIsUploading;
    }

    function toggleGridMode() {
        uiState.gridMode = !uiState.gridMode;
        PubSub.emit($ExportBuilderDashboardEvents.IS_GRID_MODE, uiState.gridMode);
    }

    function getIsGridMode() {
        return uiState.gridMode;
    }

    function toggleOutlineActive() {
        uiState.showElementSizes = !uiState.showElementSizes;
    }

    function getIsOutlineActive() {
        return uiState.showElementSizes;
    }

    function toggleOverflow() {
        uiState.isOverflow = !uiState.isOverflow;
    }

    function getIsOverflow() {
        return uiState.isOverflow;
    }

    function refreshWidgets() {
        ExportBuilderDashboardService.emptyAllWidgetCache();
        $rootScope.$broadcast($WidgetEvents.WIDGET_REBUILD);
        PubSub.emit($WidgetEvents.WIDGET_REBUILD);
    }

    function setElementChangingImage(element) {
        uiState.elementChangingImage = element;
    }

    function getEditingElementImage() {
        return uiState.elementChangingImage
    }

    function setIsPressingShift(value) {
        uiState.isPressingShift = value;
    }

    function getIsPressingShift() {
        return uiState.isPressingShift;
    }

    function setIsPressingCMD(value) {
        uiState.isPressingCMD = value;
    }

    function getIsPressingCMD() {
        return uiState.isPressingCMD;
    }

    function setIsDownloadingReport(value) {
        uiState.isDownloadingReport = value;
        const report = ExportBuilderDashboardService.getReport();
        if (report) {
            report.isDownloading = value;
        }
        PubSub.$emit($ExportBuilderDashboardEvents.IS_DOWNLOADING, value);
    }

    function getIsDownloadingReport() {
        return uiState.isDownloadingReport;
    }

    function setIsSavingEntireReport(value) {
        uiState.isSavingReport = value;
    }

    function getIsSavingEntireReport() {
        return uiState.isSavingReport;
    }

    function setIsPublishReport(value) {
        uiState.isPublishingReport = value;
    }

    function getIsPublishReport() {
        return uiState.isPublishingReport;
    }

    function focusOnElement(element) {
        if (uiState.isPressingCMD) {
            ExportBuilderDashboardService.addFocusToElement(element)
        } else {
            ExportBuilderDashboardService.focusOnItem(element);
        }
    }

    function setFocusToElements(elements) {
        if (uiState.isPressingCMD) {
            _.each(elements, ExportBuilderDashboardService.addFocusToElement);
        } else {
            ExportBuilderDashboardService.setFocusToElements(elements);
        }
    }

    function showWidgetLibraryModal() {
        if (_isShowingModal() || angular.element(':focus').length) {
            return;
        }
        PubSub.emit($WidgetLibraryEvents.INIT_MODAL);
    }

    function showNewIconModal() {
        if (_isShowingModal() || angular.element(':focus').length) {
            return;
        }
        PubSub.emit($ExportBuilderIconModalEvents.OPEN_NEW);
    }

    function showExportOptionsModal() {
        if (_isShowingModal() || angular.element(':focus').length) {
            return;
        }
        PubSub.emit($ExportBuilderTemplateDownloadModalEvents.OPEN);
    }

    function showKeyboardShortCutsModal() {
        if (_isShowingModal() || angular.element(':focus').length) {
            return;
        }
        PubSub.emit($ExportBuilderShortcutsModalEvents.OPEN);
    }

    function showFormatModal() {
        if (_isShowingModal()) {
            return;
        }
        PubSub.emit($ExportBuilderFormatChangeModalEvents.OPEN);
    }

    function showImageDialog(event) {
        if (_isShowingModal()) {
            return;
        }

        if (!uiState.imageIsUploading && !$(event.target).is('#export_builder_fileupload')) {
            //we need a timeout to detach the trigger from the draw cycle
            $timeout(function () {
                // event.target is in fact #export_builder_fileupload
                $('#export_builder_fileupload').click();
            }, 0, false);
        }
        event.stopPropagation();
        event.preventDefault();
    }

    function showImageDialogFromKeyboard(event) {
        if (_isShowingModal()) {
            return;
        }

        if (!uiState.imageIsUploading && !$(event.target).is('#export_builder_fileupload')) {
            // need to trigger a click on the element in order for this to work, with no timeout.
            $('#export_builder_fileupload').click();
        }
        event.stopPropagation();
        event.preventDefault();
    }

    function changeImage(event, element) {
        if (_isShowingModal()) {
            return;
        }
        setElementChangingImage(element);
        showImageDialog(event)
    }

    function fetchNewGlobalFilter(widget, filter) {
        ExportBuilderFilterService.addOrDeleteFilterIfNeeded(widget, filter)
            .then(globalFilter => {
                PubSub.emit($ExportBuilderDashboardEvents.ON_NEW_GLOBAL_FILTER_ADDED, globalFilter);
            });
    }

    function setPresentationMode(value) {
        ExportBuilderDashboardService.getBuilder().isPresentationMode = value;
    }

    function togglePresentationMode() {
        var builder = ExportBuilderDashboardService.getBuilder();
        builder.isPresentationMode = !builder.isPresentationMode;
        if (builder.isPresentationMode) {
            FullScreenUtilUIService.goFullScreen();
        } else {
            FullScreenUtilUIService.exitFullScreen();
        }
        $timeout(function () {
            ExportBuilderDashboardService.calculateZoom();
        }, 300);
        $timeout(function () {
            determineNextFocusPage();
        }, 300);
        PubSub.emit($ExportBuilderDashboardEvents.ON_PRESENTATION_MODE);
    }

    function getPresentationMode() {
        return ExportBuilderDashboardService.getBuilder().isPresentationMode;
    }

    function moveCurrentItemUp(multiplier) {
        if (ExportBuilderDashboardService.getBuilder().currentItem && !angular.element(':focus').length) {
            if (_.isNil(multiplier)) {
                multiplier = uiState.isPressingShift ? 5 : null
            }
            ExportBuilderElementActionService.setMoveY(-1, multiplier);
        }
    }

    function moveCurrentItemDown(multiplier) {
        if (ExportBuilderDashboardService.getBuilder().currentItem && !angular.element(':focus').length) {
            if (_.isNil(multiplier)) {
                multiplier = uiState.isPressingShift ? 5 : null
            }
            ExportBuilderElementActionService.setMoveY(1, multiplier);
        }
    }

    function moveCurrentItemLeft(multiplier) {
        if (ExportBuilderDashboardService.getBuilder().currentItem && !angular.element(':focus').length) {
            if (_.isNil(multiplier)) {
                multiplier = uiState.isPressingShift ? 5 : null
            }
            ExportBuilderElementActionService.setMoveX(-1, multiplier);
        }
    }

    function moveCurrentItemRight(multiplier) {
        if (ExportBuilderDashboardService.getBuilder().currentItem && !angular.element(':focus').length) {
            if (_.isNil(multiplier)) {
                multiplier = uiState.isPressingShift ? 5 : null
            }
            ExportBuilderElementActionService.setMoveX(1, multiplier);
        }
    }

    function focusOnNextItem() {
        var builder = ExportBuilderDashboardService.getBuilder();
        var currentItem = builder.currentItem;
        var currentPage = builder.currentPage;

        var nextItem;

        if (currentPage.elements.length === 0) {
            return;
        }

        if (!currentItem) {
            nextItem = currentPage.elements[0];
        }

        _.isUndefined(nextItem)
        && _.each(currentPage.elements, function (item, index) {
            if (item.id === currentItem.id) {
                nextItem = currentPage.elements[(index + 1)];
                if (_.isUndefined(nextItem)) {
                    nextItem = currentPage.elements[0];
                }
                return false;
            }
        });

        ExportBuilderDashboardService.focusOnItem(nextItem);
    }

    /**
     * Remove item proxy UI method
     * @param item
     * @returns {*}
     */
    function removeItem(item) {
        var defer = $q.defer();
        ExportBuilderElementActionService.setDelete();
        defer.resolve();
        return defer.promise;
    }

    function removePage(pageIndex) {
        var defer = $q.defer();

        UIFactory.confirmDelete(_.assign(_deleteOptions(), {
            confirmFn: function(isConfirm) {
                if (isConfirm) {
                    try {
                        ExportBuilderDashboardService.deletePageAtIndex(pageIndex)
                            .then(function () {
                                defer.resolve();
                            });
                    } catch (e) {
                        UIFactory.notify.showWarning(e.message);
                        defer.reject();
                    }
                } else {
                    defer.resolve();
                }
            }
        }));

        return defer.promise;
    }

    function performUndoRedo() {
        _clearSelection();
        uiState.isPressingShift
            ? UndoRedoService.redo()
            : UndoRedoService.undo();
    }

    function performUndo() {
        _clearSelection();
        UndoRedoService.undo();
    }

    function performRedo() {
        _clearSelection();
        UndoRedoService.redo()
    }

    function _clearSelection() {
        if (!WidgetBuilderUIService.isActive()) {
            ExportBuilderDashboardService.clearSelection();
        }
    }

    function selectAll() {
        ExportBuilderDashboardService.setFocusToElements(ExportBuilderDashboardService.getBuilder().currentPage.elements);
    }

    function _deleteOptions() {
        return {
            title: gettextCatalog.getString('Are you sure?'),
            text: gettextCatalog.getString('You will not be able to undo this action!'),
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonColor: gettextCatalog.getString('#d9534f'),
            confirmButtonText: gettextCatalog.getString('Yes, delete it!')
        }
    }

    /**
     * Copies the element to the report clipboard
     */
    function copyToClipboard(element) {
        if (ExportBuilderDashboardService.getBuilder().elements.length === 1
            && !angular.element(':focus').length
            && element.id) {

            var element = ReportStudioTemplateDataService.getElementInPage(element.id, ExportBuilderDashboardService.getBuilder().currentPage);
            uiState.clipboard = angular.copy(element);
            uiState.clipboard.setUnfocus();

            UIFactory.notify.hideAll();
            UIFactory.notify.showSuccess(gettextCatalog.getString('Copied!'));
        }
    }

    /**
     * Copies the element to the clipboard and removes it from the report
     */
    function cutToClipboard(element) {
        if (ExportBuilderDashboardService.getBuilder().elements.length === 1
            && !angular.element(':focus').length
            && element.id) {

            var pageElement = ReportStudioTemplateDataService.getElementInPage(element.id, ExportBuilderDashboardService.getBuilder().currentPage);
            uiState.clipboard = angular.copy(pageElement);
            uiState.clipboard.setUnfocus();

            ExportBuilderElementActionService.setCut(pageElement);

            UIFactory.notify.hideAll();
            UIFactory.notify.showSuccess('Cut!');
        }
    }

    /**
     * Paste the copie/cut element to the current page of the report
     */
    function paste() {
        if (uiState.clipboard && !angular.element(':focus').length) {
            ExportBuilderElementActionService.setPaste(uiState.clipboard);
            UIFactory.notify.hideAll();
            UIFactory.notify.showSuccess(gettextCatalog.getString('Pasted!'));
        }
    }

    /**
     * If the currentpage is Exec Summary Page, and
     * If widget metadata content is empty messsage then
     * Based on the index, will either go to next page or previous page
     */
    function determineNextFocusPage() {
      const builder = ExportBuilderDashboardService.getBuilder();
      if (builder.currentPage.is_executive_summary_page) {
        const isEmptyExecutiveSummaryWidget = !!_.find(
          builder.currentPage.elements,
          (element) => {
            return (
              element.type === ReportElementTypes.WIDGET &&
              !_.isEmpty(element.widget) &&
              !_.isNull(element.widget) &&
              element.widget.metadata.content ===
                ReportEmptyExecutiveSummary.EMPTY_MESSAGE
            );
          }
        );
        if (isEmptyExecutiveSummaryWidget) {
          if (builder.currentPageIndex < builder.report.pages.length - 1) {
            ExportBuilderDashboardService.goToNextPage();
          } else {
            ExportBuilderDashboardService.goToPreviousPage();
          }
        }
      }
    }

    /**
     * Knowing if a modal is presented over the builder
     * @returns {Boolean}
     * @private
     */
    function _isShowingModal() {
        return WidgetBuilderUIService.isActive()
            || ExportBuilderShortcutsModalUIService.getIsActive()
            || ExportBuilderDashboardExportOptionsUIService.getIsActive()
            || ExportBuilderElementIconModalUIService.getIsActive()
            || ExportBuilderPageAssignmentModalUIService.getIsActive()
            || ExportBuilderFormatModalUIService.getIsActive();
    }
}

/**
 * @ngInject
 */
function ExportBuilderFilterService(
    $q,
    WidgetUtilService,
    ExportBuilderFilterResource,
    ReportStudioTemplateDataService,
    ExportBuilderDashboardService,
    $ExportBuilderDashboardEvents,
    PubSub
) {
    return {
        getGlobalFiltersOptions: getGlobalFiltersOptions,
        updateGlobalFilters: updateGlobalFilters,
        resetGlobalFilters: resetGlobalFilters,
        addOrDeleteFilterIfNeeded: addOrDeleteFilterIfNeeded,
        invalidateWidgetCache: invalidateWidgetCache,
        removeFilter: removeFilter,
        removeExposedFilter: removeExposedFilter
    };

    /**
     * Retrieves global filters
     * @returns {*}
     */
    function getGlobalFiltersOptions() {
        return ExportBuilderFilterResource.getGlobalFiltersOptions(ReportStudioTemplateDataService.getReport())
            .then(filterOptions => {
                ReportStudioTemplateDataService.setGlobalFilters(filterOptions);
                return filterOptions;
            });
    }

    /**
     * Assign values for each global filter
     * @param filterOptions
     * @returns {*}
     */
    function updateGlobalFilters(filterOptions) {

        const report = ReportStudioTemplateDataService.getReport();

        if (_.isEmpty(report.global_filters)) {
            report.global_filters = {};
        }

        const filtersDidChange = _getChangedFilters(report, filterOptions);

        return ExportBuilderFilterResource.saveGlobalFilters(ReportStudioTemplateDataService.getReport(), report.global_filters)
            .then(() => {
                invalidateWidgetCache(filtersDidChange);
            });
    }

    /**
     * Removes all values from each global filter
     * @param filterOptions
     * @returns {*}
     */
    function resetGlobalFilters(filterOptions = null) {
        const report = ReportStudioTemplateDataService.getReport();

        const filtersDidChange = _getFiltersWithValues(report, filterOptions);

        report.global_filters = {};

        return ExportBuilderFilterResource.clearGlobalFilters(report)
            .then(() => {
                invalidateWidgetCache(filtersDidChange);
            });
    }

    /**
     * Based on the widget, check if needed to get new global filter
     * @param widget
     * @param filter
     */
    function addOrDeleteFilterIfNeeded(widget, filter) {
        const defer = $q.defer();

        let needToFetch = true;
        const globalFilters = ReportStudioTemplateDataService.getGlobalFilters() || {};
        const filterKeys = Object.keys(globalFilters);
        _.each(filterKeys, function (filterKey) {
            if (filter && filter.expose_as_report_filter && !filter.expose_as_dash_filter) {
                const [type, dataSourceId, dataView, filterField] = filterKey.split('|');
                if (type && dataSourceId && dataView && filterField) {
                    if (filter.field === filterField &&
                    globalFilters[filterKey].data_source.id === widget.data_source_id &&
                    globalFilters[filterKey].data_source.type === widget.data_type &&
                    widget.metadata.data_source.data_view === dataView) {
                        return needToFetch = false;
                    }
                }
            } else if (globalFilters[filterKey].data_source.id === widget.data_source_id
                && globalFilters[filterKey].data_source.type === widget.data_type) {
                return needToFetch = false;
            }
        });

        if (needToFetch) {
            _fetchNewGlobalFilter(widget, filter)
                .then(globalFilter => {
                    if (globalFilter) {
                        if (widget.metadata.filter_set_id) {
                            const [filterKey] = Object.keys(globalFilter);
                            if (filterKey && filter) {
                                globalFilter[filterKey].source_filter_set_id = widget.metadata.filter_set_id;
                                globalFilter[filterKey].limit_available_values = filter.limit_available_values;
                            }
                        }
                        _success(globalFilter);
                        defer.resolve(globalFilter)
                    } else {
                        removeFilter(widget, filter)
                        defer.reject();
                    }
                })
                .catch(() => {
                    defer.reject();
                });
        } else {
            removeFilter(widget, filter);
            defer.reject();
        }

        function _success(globalFilter) {
            let globalFilters = ReportStudioTemplateDataService.getGlobalFilters();
            globalFilters = {...globalFilters, ...globalFilter };
            ReportStudioTemplateDataService.setGlobalFilters(globalFilters);
        }

        return defer.promise;
    }

    function _getFiltersWithValues(report, filterOptions = []) {
        const filtersDidChange = {};
        _.each(filterOptions, filterOption => {
            if (!_.isEmpty(filterOption.currentValues)) {
                const filter = ReportStudioTemplateDataService.getGlobalFilters()[filterOption.key];
                const key = `${filter.data_source.type}|${filter.data_source.id}`;
                filtersDidChange[key] = filter.data_source;
            }
        });
        return filtersDidChange;
    }

    /**
     *
     * @param widget
     * @param globalFilter
     * @param isRemoved
     */
    function removeFilter(widget ,globalFilter, isRemoved) {
        if (globalFilter && (!globalFilter.expose_as_report_filter || isRemoved)) {
            removeWidgetFilter(widget, globalFilter);
        }
    }

    function removeExposedFilter(widget ,globalFilter) {
        if (globalFilter && globalFilter.expose_as_report_filter) {
            removeWidgetFilter(widget, globalFilter);
        }
    }

    function removeWidgetFilter(widget, globalFilter) {
        let globalFilters = ReportStudioTemplateDataService.getGlobalFilters();
        const { id, type, data_view } = widget.metadata.data_source;
        const key = [type, id, data_view, globalFilter.field].join("|");
        if (globalFilters[key]) {
            delete globalFilters[key];
            ReportStudioTemplateDataService.setGlobalFilters(globalFilters);
            PubSub.emit($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_REMOVED, key);
        }
    }

    /**
     *
     * @param widget
     * @param filter
     * @returns {*}
     * @private
     */
    function _fetchNewGlobalFilter(widget, filter) {
        const { id, type, data_view } = widget.metadata.data_source;
        const params = filter ? {id, type, data_view, field: filter.field} : { id, type };
        return ExportBuilderFilterResource.getSingleGlobalFilter(ReportStudioTemplateDataService.getReport(), params);
    }

    function _getChangedFilters(report, filterOptions) {
        const filtersDidChange = {};
        _.each(filterOptions, filterOption => {
            let filter = report.global_filters[filterOption.key];

            if (!_.isEmpty(filterOption.currentValues)) {

                if (!_areFiltersEqual(filter, filterOption)) {
                    const key = `${filterOption.data_source.type}|${filterOption.data_source.id}`;
                    filtersDidChange[key] = filterOption.data_source;
                }

                if (!filter) {
                    filter = angular.copy(ReportStudioTemplateDataService.getGlobalFilters()[filterOption.key]);
                }

                filter.values = filterOption.currentValues;
                filter.type = filterOption.type;

                if (_.isEmpty(filter.values)) {
                    delete report.global_filters[filterOption.key];
                } else {
                    report.global_filters[filterOption.key] = filter;
                }
            } else {
                if (filter) {
                    const key = `${filterOption.data_source.type}|${filterOption.data_source.id}`;
                    filtersDidChange[key] = filterOption.data_source;
                }
                delete report.global_filters[filterOption.key];
            }
        });

        if (report.global_filters) {
            const globalFilterKeys = Object.keys(report.global_filters);
            const filterOptionsKeys = [];
            filterOptions.forEach((filterOption) => {
                filterOptionsKeys.push(filterOption.key)
            });
            _.each(globalFilterKeys, filterKey => {
                if (!filterOptionsKeys.includes(filterKey)) {
                    const [type, id] = filterKey.split('|');
                    const newKey = type+'|'+id;
                    filtersDidChange[newKey] = report.global_filters[filterKey].data_source;
                    delete report.global_filters[filterKey];
                }
            })
        }

        return filtersDidChange;
    }

    function _areFiltersEqual(filter, filterOption) {
        if (!filter
            || filter && !filter.values
            || filter.values.length !== filterOption.currentValues.length || filter.type !== filterOption.type) {
            return false;
        }

        filterOption.currentValues = _.sortBy(filterOption.currentValues, 'id');
        filter.values = _.sortBy(filter.values, 'id');

        return JSON.stringify(filter.values) === JSON.stringify(filterOption.currentValues);
    }

    function invalidateWidgetCache(filtersDidChange) {
      const elementIdsToNotify = [];
      if (!_.isNull(ReportStudioTemplateDataService.getReport())) {
        _.each(ReportStudioTemplateDataService.getReport().pages, (page) => {
          _.each(page.elements, (element) => {
            if (!element.isTypeWidget()) {
              return;
            }

            const key = `${element.widget.data_type}|${element.widget.data_source_id}`;

            if (!_.isNil(filtersDidChange[key])) {
              ExportBuilderDashboardService.emptyWidgetCache(element.widget);
              elementIdsToNotify.push(element.id);
            }
          });
        });
      }

      _.each(elementIdsToNotify, (elementId) => {
        PubSub.emit(
          $ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + elementId
        );
      });
    }
}

/**
 * @constructor
 * @ngInject
 */
function ExportBuilderDashboardService(
    $rootScope,
    $q,
    $timeout,
    ExportBuilderDashboardUtilService,
    WidgetUtilService,
    WidgetType,
    AppFactory,
    gettextCatalog,
    WidgetFactory,
    ExportBuilderResource,
    DrawOptionFactory,
    ExportElementResource,
    ReportElementTypes,
    ZIndexProps,
    WidgetSize,
    ReportTemplateType,
    PageSpecifications,
    PageFormatConstants,
    PageAspectRatio,
    PageOrientation,
    PageGridConstants,
    ExportBuilderPrefixes,
    ExportBuilderHelper,
    DrawOption,
    UIFactory,
    BuilderPanel,
    PageLayerPanel,
    ReportStudioTemplateDataService,
    PubSub,
    ReportElementMetadataOptionsService,
    $ExportBuilderDashboardEvents,
    $WidgetEvents,
    ExportBuilderDashboardSyncService,
    ReportElementTextDesignOptions,
    ReportElementDesignOptionConstants,
    $ExportBuilderDashboardModelEvents,
    ExportBuilderDashboardConstants,
    ExportBuilderDashboardModelFactory,
    ExportFactory,
    FontSize,
    ExportBuilderNewReportAdminService,
    $WidgetFilterEvents,
    $state,
    UserLoginFactory,
    WindowUtilUIService,
    $WidgetBuilderEvents
) {
    var localData = {}; //will contain data used in standalone mode only

    // will contain all items that are awaiting to be saved
    // used to avoid saving all default data at first opening
    var pendingItems = {};
    var _ratio = 0;
    var _minimumZoomPercent = 15;
    var _minimumRatio = -18;

    var originalPageMargins = {
        left: 40,
        right: 40
    };

    var sharedData = {
        isAuthoring: false,
        active: false,
        readOnly: false,
        pageId: null,
        elements: []
    };

    return {
        getBuilder: getBuilder,
        getReport: getReport,
        setEntity: setEntity,
        getEntity: getEntity,
        getPercentZoom: getPercentZoom,
        getZoom: getZoom,

        setActive: setActive,
        resetData: resetData,
        setReadonly: setReadonly,
        setLocalData: setLocalData,

        getPageAtIndex: getPageAtIndex,
        getItemFromId: getItemFromId,
        toggleLandscape: toggleLandscape,
        getIsLandscape: getIsLandscape,
        initWithReportId: initWithReportId,
        calculateZoom: calculateZoom,
        syncShowOnEveryPageItem: syncShowOnEveryPageItem,
        copyItemToPage: copyItemToPage,
        reorderPagesIndeces: reorderPagesIndeces,
        reorderItemsZIndeces: reorderItemsZIndeces,

        setFocusToElements: setFocusToElements,
        addFocusToElement: addFocusToElement,
        focusOnItem: focusOnItem,
        clearSelection: clearSelection,
        updateCurrentElementSelection: updateCurrentElementSelection,
        toggleItemHidden: toggleItemHidden,
        goToNextPage: goToNextPage,
        goToPreviousPage: goToPreviousPage,
        getPreviousDesiredPageIndex: getPreviousDesiredPageIndex,
        getFirstDesiredPageIndex: getFirstDesiredPageIndex,
        getNextDesiredPageIndex: getNextDesiredPageIndex,
        goToPageIndex: goToPageIndex,
        setReportDataFields: setReportDataFields,
        updateWidgetsForDataDisplay: updateWidgetsForDataDisplay,
        toggleFitToPage: toggleFitToPage,
        getIsFitToPage: getIsFitToPage,
        getWidgetModelForSpecialEdit: getWidgetModelForSpecialEdit,

        setSelectedFilter: setSelectedFilter,
        unsetFilter: unsetFilter,
        getFilter: getFilter,

        assignFrontEndProperties: assignFrontEndProperties,
        emptyWidgetCache: emptyWidgetCache,
        emptyCurrentPageWidgetCache: emptyCurrentPageWidgetCache,
        emptyAllWidgetCache: emptyAllWidgetCache,
        createEmptyReport: createEmptyReport,
        saveEntireReport: saveEntireReport,

        createNewPageAtIndex: createNewPageAtIndex,
        deletePageAtIndex: deletePageAtIndex,
        addPageAtIndex: addPageAtIndex,

        getNewItemId: getNewItemId,
        addNewItem: addNewItem,
        initNewItem: initNewItem,
        addItem: addItem,
        updateItem: updateItem,
        removeItem: removeItem,
        copyItem: copyItem,
        updateItemWidget: updateItemWidget,
        delayUpdateItem: delayUpdateItem,
        delayUpdateElements: delayUpdateElements,
        updatePageNumber: updatePageNumber,
        delayUpdateWidgetItem: delayUpdateWidgetItem,

        toggleEditOnItem: toggleEditOnItem,
        savePendingItems: savePendingItems,
        unHideAll: unHideAll,
        nextAvailableZIndex: nextAvailableZIndex,
        saveReportTitle: saveReportTitle,

        updateReport: updateReport,
        delayUpdateReport: delayUpdateReport,

        toggleReportWidgetDrawOption: toggleReportWidgetDrawOption,

        getFirstAvailablePosition: getFirstAvailablePosition,
        nextAvailableZIndexForItems: nextAvailableZIndexForItems,
        setColorPaletteColor: setColorPaletteColor,
        setColorPalette: setColorPalette,
        setFontSize: setFontSize,

        setCluster: setCluster,
        setClient: setClient,
        setClientGroup: setClientGroup,
        setFilterMode: setFilterMode,
        setFontType: setFontType,
        updateReportAssociations: updateReportAssociations,
        saveDateRange: saveDateRange,
        setAspectRatio: setAspectRatio,
        assignDatasourcesToPages: assignDatasourcesToPages,
        updateAssignmentOptions: updateAssignmentOptions,
        setWidgetModelForSpecialEdit: setWidgetModelForSpecialEdit,
        getSkipDrawOptions: getSkipDrawOptions,
        getSkipDrawOptionsDashboard: getSkipDrawOptionsDashboard,
        canEditReport: canEditReport,
        addPagesToReport: addPagesToReport,
        removePagesFromReport:removePagesFromReport,
        updatePageIndex: updatePageIndex
    };

    function addPagesToReport() {
      if (sharedData.report.is_preview) {
        return true;
      }

      let pageIndex = -1;
      const reportPages = _.clone(sharedData.report.pages);
      reportPages.forEach(page => {
        ++pageIndex;
        page.elements.every(element => {
          const widget = element.widget;
          if (
            widget &&
            widget.type === WidgetType.DATAGRID &&
            widget.metadata.draw_options.split_in_multiple_pages
          ) {
            const widgetMetadata = widget.metadata;
            widgetMetadata.needs_pagination = true;
            widgetMetadata.draw_options.original_display_length = widgetMetadata.draw_options.display_length;
            widgetMetadata.draw_options.display_length = widgetMetadata.draw_options.rows_per_page;
            page.metadata.needs_pagination = true;
            const totalPages = widgetMetadata.draw_options.number_of_page;
            if (totalPages === 1) {
              widgetMetadata.show_overflow_message = true;
              addNotificationItem(page, element);
            }
            widgetMetadata.dynamic.predefined_data = null;
            sharedData.report.pages[pageIndex] = page;
            pageIndex = addPagesWithOffset(page, element, pageIndex);
            // break;
            return false;
          }
          return true;
        });
      });
    }

    function addPagesWithOffset(page, element, pageIndex) {
      let offset = 0;
      const widgetMetadata = element.widget.metadata;
      const totalPages = widgetMetadata.draw_options.number_of_page;
      const newPageElements = widgetMetadata.draw_options.repeat_full_layout
        ? _.cloneDeep(page.elements)
        : [_.cloneDeep(element)];

      while (++offset < totalPages) {
        const newPage = ExportBuilderDashboardModelFactory.getReportPageModel({
          id: randomId(),
          report_id: sharedData.report.id,
          title: getNextTitle(page.title),
          metadata: _.cloneDeep(page.metadata),
          page_index: ++pageIndex,
          elements: []
        });
        newPage.metadata.hide_if_empty_widget = true;
        newPageElements.forEach(newPageElement => {
          const pElement = _.cloneDeep(newPageElement);
          pElement.id = randomId();
          pElement.page_id = newPage.id;
          pElement.metadata = _.cloneDeep(pElement.metadata);
          if (pElement.widget) {
            if (pElement.widget_id === element.widget_id) {
              const widgetMetadata = (pElement.widget.metadata = _.cloneDeep(
                pElement.widget.metadata
              ));
              widgetMetadata.dynamic = _.cloneDeep(widgetMetadata.dynamic);
              widgetMetadata.draw_options = _.cloneDeep(
                widgetMetadata.draw_options
              );
              widgetMetadata.offset = offset;
              widgetMetadata.dynamic.predefined_data = null;
              pElement.widget.title = getNextTitle(
                pElement.widget.title,
                offset
              );
            }
            pElement.is_dynamic_element = true;
            pElement.widget_id = pElement.widget.id = randomId();
          }
          newPage.elements.push(pElement);
        });
        sharedData.report.pages.splice(pageIndex, 0, newPage);
        if (offset === totalPages - 1) {
          addNotificationItem(newPage, element);
          if (page.overflow_truncate_message_element_id) {
              page.elements = page.elements.filter(
                  item => item.id !== page.overflow_truncate_message_element_id
              );
          }
        }
      }
      return pageIndex;
    }

    function addNotificationItem(page) {
        if (
          page.overflow_truncate_message_element_id &&
          page.elements.find(
            item => item.id === page.overflow_truncate_message_element_id
          )
        ) {
          return;
        }
        page.overflow_truncate_message_element_id = getUniqueItemId();
        page.elements = page.elements.map((element) => {
            if (element.widget && element.widget.metadata.type === WidgetType.DATAGRID && element.widget.metadata.needs_pagination) {
                element.widget.metadata.show_overflow_message = true;
            }
            return element;
        });

    }

    function removePagesFromReport() {
      let lastVisitedPage = sharedData.report.pages[0];
      sharedData.report.pages = sharedData.report.pages.filter(page => {
        const pageMetadata = page.metadata;
        if (pageMetadata.hide_if_empty_widget) {
          if (sharedData.currentPageIndex === page.page_index) {
            sharedData.currentPage = lastVisitedPage;
          }
          return false;
        }
        if (pageMetadata.needs_pagination) {
          page.elements.forEach(element => {
            const widget = element.widget;
            if (widget && widget.metadata.needs_pagination) {
              widget.metadata.draw_options.display_length = widget.metadata.draw_options.original_display_length;
              delete widget.metadata.draw_options.original_display_length;
              delete widget.metadata.needs_pagination;
            }
            if (element.id === page.overflow_truncate_message_element_id) {
                element.height = 0;
            }
          });
          delete pageMetadata.needs_pagination;
        }
        lastVisitedPage = page;
        return true;
      });
    }

    function updatePageIndex() {
      let index = 0;
      sharedData.report.pages = sharedData.report.pages.map(page => {
        page.page_index = index++;
        page.number = index;
        if (sharedData.currentPage.id === page.id) {
          sharedData.currentPageIndex = page.page_index;
        }
        return page;
      });
      syncShowOnEveryPage();
    }

    function getNextTitle(title, offset = 0) {
      if (!WidgetFactory.isHTML(title)) {
          let prefix = "";
          if (offset) {
              prefix = ` [Cont.] - ${offset + 1} `;
          }
          return `${title}${prefix}`
      }
      return WidgetFactory.appendOffset(title, offset);
    }

    function assignDatasourcesToPages(pages) {
        sharedData.report.pages = pages;
        let pagesToUpdate = _.map(pages,  (page) => {
            return {id: page.id, metadata: page.metadata}
        });

        return ExportBuilderResource.batchUpdatePages(sharedData.report, pagesToUpdate)
            .then(() => {
                updateAssignmentOptions();
            });
    }


    function updateAssignmentOptions() {
        ExportBuilderResource.get(sharedData.report.id)
            .then((report) => {
                let pageIndexMap = new Map();
                let pagesArr = _.map(report.pages, (page) => {
                    return page;
                });
                pagesArr.forEach((page, index) => {
                    pageIndexMap.set(page.id, index);
                })
                sharedData.report.pages.sort((a, b) => a.page_index - b.page_index);
                let counter = 1;
                sharedData.report.pages.forEach((page, index) => {
                    if (sharedData.report.pages[index] && pagesArr[pageIndexMap.get(page.id)]) {
                        sharedData.report.pages[index].metadata.assignment_type = pagesArr[pageIndexMap.get(page.id)].metadata.assignment_type;
                        if (pagesArr[pageIndexMap.get(page.id)].metadata.assigned_values) {
                            sharedData.report.pages[index].metadata.assigned_values = pagesArr[pageIndexMap.get(page.id)].metadata.assigned_values;
                        }
                        sharedData.report.pages[index].metadata.isHidden = pagesArr[pageIndexMap.get(page.id)].metadata.isHidden;
                        if (!sharedData.report.pages[index].metadata.isHidden) {
                            sharedData.report.pages[index].number = counter++;
                        } else {
                            sharedData.report.pages[index].number = null;
                        }
                    }
                });
            })
            .finally(() => {
                setNextActivePage();
                PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ASSIGNMENT, false);
            });
    }

    function setNextActivePage() {
        let currentPageIndex = sharedData.currentPage.page_index;
        let reportPages = sharedData.report.pages;
        reportPages.sort((a, b) =>  a.page_index - b.page_index);
        if (reportPages[currentPageIndex].metadata.isHidden) {
            let desiredPageIndex;
            if (currentPageIndex + 1 < reportPages.length) {
                desiredPageIndex =  getNextDesiredPageIndex();
                desiredPageIndex = desiredPageIndex === undefined ? getPreviousDesiredPageIndex() : desiredPageIndex;
            }
            else {
                desiredPageIndex = getPreviousDesiredPageIndex();
            }
            if (desiredPageIndex) { // all pages are hidden
                goToPageIndex(desiredPageIndex);
            }
        } else {
            goToPageIndex(currentPageIndex);
        }
    }

    function setAspectRatio(value) {
        sharedData.report.format = value;
        ExportBuilderResource.updateField(sharedData.report.id, 'format', sharedData.report.format);
        calculateZoom();
        PubSub.emit($ExportBuilderDashboardEvents.ON_ASPECT_RATIO_CHANGE, value);
    }

    function getBuilder() {
        return sharedData;
    }

    function getReport() {
        return sharedData.report;
    }

    function canEditReport() {
        if (sharedData.report) {
            return sharedData.report.can_be_edited;
        }
        return false;
    }

    function setEntity(value) {
        ReportStudioTemplateDataService.setEntity(value);
        PubSub.emit($ExportBuilderDashboardEvents.SELECTED_ENTITY_CHANGED);
    }

    function getEntity() {
        return ReportStudioTemplateDataService.getEntity()
    }

    function getPercentZoom() {
        return getZoom() / 100;
    }

    function getZoom() {
        return sharedData.zoom;
    }

    function setActive (value) {
        ReportStudioTemplateDataService.setIsActive(value);
        sharedData.isAuthoring = value;
    }

    function setSelectedFilter(filter) {
        return ExportBuilderResource.setFilter(_.pick(filter, ['type', 'id']))
            .then(entity => {
                entity.text = filter.text;
                setEntity(entity);
            });
    }

    function unsetFilter() {
        return ExportBuilderResource.unsetFilter().then(() => {
            setEntity(null);
        });
    }

    function getFilter() {
        var defer = $q.defer();

        if (_.isEmpty(ReportStudioTemplateDataService.getEntity())) {
            return ExportBuilderResource.getFilter()
                .then(function (filter) {
                    setEntity(filter);
                    return filter;
                });
        }

        defer.resolve(getEntity());

        return defer.promise;
    }

    /**
     * Set report properties for data filtering
     * @param model
     */
    function setReportDataFields(model) {
        if (!model.is_preview) {
            sharedData.dateRange = model.dateRange
                ? model.dateRange
                : null;
        }
        sharedData.report.is_preview = model.is_preview;

        updateWidgetsForDataDisplay();

        if (sharedData.report.can_be_edited) {
            updateReport();
        }
    }

    function _setItemPropertiesForPreview(item) {
        item.widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA] = true;
        item.widget.metadata.dynamic.predefined_data = null;
    }

    function _setItemPropertiesForData(item) {
        delete item.widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA];
        item.widget.metadata.dynamic.predefined_data = null;
    }

    function toggleFitToPage() {
        if (getIsLandscape()) {
            return;
        }

        sharedData.fitToPage = !sharedData.fitToPage;
        calculateZoom();
    }

    function getIsFitToPage() {
        return sharedData.fitToPage;
    }

    /**
     * Reset all shared Data
     * pass the report as a reference
     */
    async function resetData(report = null, options = {}) {

        _.assign(sharedData, {
            report: report,
            zoom: 100, //zoom needed to display the page in the screen
            pageMarginLeft: originalPageMargins.left, //margin on the left of the pages
            currentPage: null, //currently focused page
            currentItem: null, //currently focused item, if any
            isResizing: false, //true only when the user is resizing an item (used to avoid unwanted un-focus of item)
            currentPageIndex: 0, //index of the page where new items should be placed
            loaded: false, //set to true when all data have been loaded, and everything has been layouted
            isAuthoring: true,
            isPresentationMode: false,
            elements: [], // elements that are selected,
            specialEditWidgetModel: null // save in memory widget when entering special edit mode
        });

        //reset pending items array
        pendingItems = {};

        if (!report) {
            /* resetting the client currency */
            AppFactory.setCurrentClientCurrency('');
            return;
        }

        if (sharedData.report.pages
            && sharedData.report.pages.length) {
            sharedData.currentPage = sharedData.report.pages[0];
        }

        if (AppFactory.getUser().isAgent()) {
            const filter = await getFilter();
            if (sharedData.report.client) {
                sharedData.report.is_preview = false;
                _setEntityFromClient();

                /* set client currency if present */
                AppFactory.setCurrentClientCurrency(sharedData.report.client.currency_code);

                if (!ExportFactory.getIsExporting()) {
                    updateWidgetsForDataDisplay();
                }
                AppFactory.setPageTitle(sharedData.report.title);
                return ;
            }
            if (filter) {
                AppFactory.setPageTitle(sharedData.report.title);
                return
            }

        }

        // when not exporting, always force sample data when coming into the report studio
        // unless client assignment is specified
        if ((!sharedData.readOnly && sharedData.report)
            || _.isEmpty(sharedData.report.client)) {

            if ('sample_data' in options) {
                sharedData.report.is_preview = !!options.sample_data.bool();
            }
        }

        if (!_.isEmpty(sharedData.report.client)) {
            _setEntityFromClient();

            /* set client currency if present */
            AppFactory.setCurrentClientCurrency(sharedData.report.client.currency_code);

            if (!ExportFactory.getIsExporting()) {
                updateWidgetsForDataDisplay();
            }
        }

        if (!_.isEmpty(sharedData.report.client_group)) {
            _setEntityFromClientGroup();

            if (!ExportFactory.getIsExporting()) {
                updateWidgetsForDataDisplay();
            }
        }
        AppFactory.setPageTitle(sharedData.report.title);
    }

    /**
     * Updates all widgets in the report for sample data or not
     */
    function updateWidgetsForDataDisplay() {
        _.each(sharedData.report.pages, function (page) {
            _.each(page.elements, function (item) {
                if (item.type === ReportElementTypes.WIDGET) {
                    sharedData.report.is_preview
                        ? _setItemPropertiesForPreview(item)
                        : _setItemPropertiesForData(item)
                }
            });
        });
    }

    /**
     * return the first occurrence of an item in every pages
     * @param itemId
     * @returns {*}
     */
    function getItemFromId(itemId) {
        var item = null;
        _.find(sharedData.report.pages, function(page) {
            if (page && page.elements) {
                var foundItem = _.find(page.elements, function(item) {
                    return item.id == itemId;
                });
                if (foundItem) {
                    item = foundItem;
                    return true;
                }
            }
        });

        if (!item) {
            item = _.find(sharedData.report.elements, function(item) {
                return item.id == itemId;
            });
        }

        return item;
    }

    /**
     * Helper function to find a page at a given index
     * @param index
     * @returns {*}
     */
    function getPageAtIndex(index) {
        return _.find(sharedData.report.pages, function (page) {
            return page.page_index == index; // double equal is wanted here.
        })
    }

    /**
     * Get a unique local ID for the just-created item.
     * This id will be override by the actual id from the DB once
     * it's created
     */
    function getUniqueItemId() {
        return _.uniqueId(ExportBuilderPrefixes.ITEMID);
    }

    /**
     * Adds a new page to the report at specified index
     * @param newPageIndex
     * @param copy
     * @param isExecSummaryPage
     * @param widgetBuilderModel
     * @returns {*}
     */
    function createNewPageAtIndex(newPageIndex, copy, isExecSummaryPage, widgetBuilderModel = {}) {

        // check if last page is back page
        var pageAtIndex = getPageAtIndex(newPageIndex - 1);
        if (newPageIndex === sharedData.report.pages.length) {
            if (pageAtIndex && pageAtIndex.is_back_page) {
                newPageIndex--;
            }
        }

        var pagesToUpdate = [];
        var tailIndex = sharedData.report.pages.length - 1;
        while ((tailIndex + 1) > newPageIndex) {
            var page = getPageAtIndex(tailIndex);
            page.page_index = parseInt(page.page_index) + 1;
            sharedData.report.pages[(tailIndex + 1)] = page;
            var pageToUpdate = angular.copy(page);
            delete pageToUpdate.elements;
            pagesToUpdate.push(pageToUpdate);
            tailIndex--;
        }

        // get show_on_every_page elements
        var newPage = initNewPage(newPageIndex, angular.copy(sharedData.report.elements), isExecSummaryPage);

        sharedData.report.pages[newPageIndex] = newPage;

        // only send what needs to change
        pagesToUpdate = _.map(pagesToUpdate, function (pageItem) {
            return {id: pageItem.id, page_index: pageItem.page_index}
        });

        var createNewPagePromise = copy
            ? ExportBuilderResource.copyPage(sharedData.report, pageAtIndex, {page_index: newPageIndex, is_back_page: false, is_cover_page: false})
            : ExportBuilderResource.createNewPage(sharedData.report, newPage);

        var batchUpdatePromise = ExportBuilderResource.batchUpdatePages(sharedData.report, pagesToUpdate);

        return $q.all([createNewPagePromise, batchUpdatePromise])
            .then(function (data) {

                if (copy) {
                    newPage = data[0];
                    newPage.elements = _.map(newPage.elements, function (element) {
                        element = ExportBuilderDashboardModelFactory.getReportElementModel(element);
                        assignFrontEndProperties(element);
                        return element;
                    });
                    sharedData.report.pages[newPage.page_index] = ExportBuilderDashboardModelFactory.getReportPageModel(newPage);
                } else {
                    newPage.id = data[0];
                }

                focusOnItem(null);
                goToPageIndex(newPageIndex);
                updatePageNumber();
                addExecutiveSummaryWidget(isExecSummaryPage, widgetBuilderModel);
                PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ADDED);

                return newPage;
            });
    }

    /**
     * Tries to delete page at specified index
     * @param pageIndex
     * @returns {*}
     * @throws
     */
    function deletePageAtIndex(pageIndex) {
        if (sharedData.report.pages.length === 1) {
            throw new Error(gettextCatalog.getString('Cannot delete last page on report'));
        }

        pageIndex = parseInt(pageIndex);

        var pageToDelete = _.remove(sharedData.report.pages, function (page) {
            return page.page_index == pageIndex; // double equal is wanted here.
        })[0];

        pageToDelete.is_deleted = true;

        var pagesToUpdate = [];
        var index = pageIndex;

        while (index < sharedData.report.pages.length) {
            var page = sharedData.report.pages[index];
            page.page_index = index;
            sharedData.report.pages[page.page_index] = page;
            pagesToUpdate.push(angular.copy(page));
            ++index;
        }

        pagesToUpdate.push(pageToDelete);

        // only send what needs to change
        pagesToUpdate = _.map(pagesToUpdate, function (page) {
            return {id: page.id, page_index: page.page_index, is_deleted: page.is_deleted}
        });

        var updatePromise = ExportBuilderResource.batchUpdatePages(sharedData.report, pagesToUpdate);

        focusOnItem(null);
        goToPageIndex((pageIndex - 1));
        PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_DELETED);

        return $q.all([updatePromise])
            .then(function () {
                updatePageNumber();
            });
    }

    function addPageAtIndex(newPage, newPageIndex) {

        // check if last page is back page
        var pageAtIndex = getPageAtIndex(newPageIndex - 1);
        if (newPageIndex === sharedData.report.pages.length) {
            if (pageAtIndex && pageAtIndex.is_back_page) {
                newPageIndex--;
            }
        }

        var pagesToUpdate = [];
        var tailIndex = sharedData.report.pages.length - 1;
        while ((tailIndex + 1) > newPageIndex) {
            var page = getPageAtIndex(tailIndex);
            page.page_index = parseInt(page.page_index) + 1;
            sharedData.report.pages[(tailIndex + 1)] = page;
            var pageToUpdate = angular.copy(page);
            delete pageToUpdate.elements;
            pagesToUpdate.push(pageToUpdate);
            tailIndex--;
        }

        newPage.is_deleted = false;
        sharedData.report.pages[newPageIndex] = newPage;

        // only send what needs to change
        pagesToUpdate = _.map(pagesToUpdate, function (pageItem) {
            return {id: pageItem.id, page_index: pageItem.page_index}
        });
        pagesToUpdate.push(newPage);

        var updatePromise = ExportBuilderResource.batchUpdatePages(sharedData.report, pagesToUpdate);

        return $q.all([updatePromise])
            .then(function (data) {

                clearSelection();
                goToPageIndex(newPageIndex);
                updatePageNumber();
                PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ADDED);

                return newPage;
            });
    }

    function updatePageNumber() {
        sharedData.report.number_of_pages = sharedData.report.pages.length;
        return ExportBuilderResource.updateField(sharedData.report.id, 'number_of_pages', sharedData.report.number_of_pages);
    }

    /**
     * @returns {string}
     */
    function getNewItemId() {
        return ExportBuilderPrefixes.ITEMID+randomId();
    }

    /**
     * Generates a random id
     * @returns {string}
     */
    function randomId() {
        return (Math.floor(Math.random() * 1000000) + 1).toString();
    }

    /**
     * Save an item into the backend
     * @param item
     * @param doNotSavePendingItems
     * @returns {*}
     */
    function _saveItem(item, doNotSavePendingItems) {

        if (sharedData.readOnly) {
            return Promise.resolve();
        }

        if (!doNotSavePendingItems) {
            savePendingItems();
        }

        var itemToSave = angular.copy(item);

        //ensure that we don't save unwanted props
        itemToSave = _.omit(itemToSave, ['isEditing']);

        //if the id is temporary
        var oldItemId = null;
        if (_.includes(itemToSave.id, ExportBuilderPrefixes.ITEMID)) {
            oldItemId = itemToSave.id;
            itemToSave.id = 0;
            itemToSave.page_id = sharedData.currentPage.id;
        }

        return ExportElementResource.save(sharedData.report, itemToSave).then(function(newSavedItem) {
            item.id = newSavedItem.id ? newSavedItem.id : newSavedItem;

            // when
            if (item.isTypeWidget() && newSavedItem.widget) {
                item.widget.id = newSavedItem.widget.id;
            }

            if (!_.isNil(oldItemId)) {
                _syncItemsIfNeeded(oldItemId, item);
                PubSub.emit($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED + oldItemId, newSavedItem);
            }
            oldItemId = null;
            return item;
        });
    }

    /**
     * Sync any pending items to the server
     * @param oldItemId
     * @param item
     * @private
     */
    function _syncItemsIfNeeded(oldItemId, item) {
        if (pendingItems[oldItemId]) {
            _saveItem(item, true);
            delete pendingItems[oldItemId];
        }
    }

    /**
     * Go thru all the pages to ensure the z-indexes are correct
     */
    function ensureZIndexContinuityOnAllPages() {
        _.each(sharedData.report.pages, function(page, pageIndex){
            ensureZIndexContinuity(pageIndex);
        });
    }

    /**
     * Go through all the existing items to find a space for a
     * specific widget, creates a new page if no position was found
     * in the existing pages
     * @param pages
     * @param widget
     * @param item
     * @return an object that contains: page_index, top, left, height, width
     */
    function getFirstAvailablePosition(pages, widget, item) {
        var pos = {
            page_index: 0
        };

        var pagesAsArray =  ExportBuilderHelper.getArrayFromRawPagesObject(pages);

        //for now, just get the last item at the bottom
        // and then append the new item after it, if there's space in the page
        var lastItem = {
            page: -1,
            top: 0,
            left: 0,
            height: 0,
            width: 0
        };

        _.each(pagesAsArray, function(items, pageIndex) {
            if (pageIndex > lastItem.page) {
                lastItem.page = pageIndex;
                _.each(items, function(currentItem) {
                    if (currentItem.top + currentItem.height > lastItem.top + lastItem.height) {
                        lastItem.top = currentItem.top;
                        lastItem.left = currentItem.left;
                        lastItem.height = currentItem.height;
                        lastItem.width = currentItem.width;
                    }
                });
            }
        });

        pos.y_position = lastItem.top + lastItem.height + PageSpecifications.INNER_MARGINS;

        // ensure there is space after the last item
        if (lastItem.page > -1 &&  pos.y_position + item.height < 100) {
            pos.page_index = lastItem.page;
            pos.x_position = PageSpecifications.INNER_MARGINS;
        }
        // if there is no space after the last item, create a new page
        else {
            pos.page_index = lastItem.page + 1;
            pos.y_position = PageSpecifications.INNER_MARGINS;
            pos.x_position = PageSpecifications.INNER_MARGINS;
        }

        return pos;
    }

    /**
     * Used only from standalone to ensure nothing gets updated from there.
     */
    function setReadonly() {
        sharedData.readOnly = true;
    }

    /**
     * Update the local data object
     * only used by the standalone access to export builder
     */
    function setLocalData(data) {
        localData = data;

        ReportStudioTemplateDataService.setEntity(data.filter);
        resetData(ExportBuilderDashboardModelFactory.getExportReportTemplateModel(data.report));

        ReportStudioTemplateDataService.setReport(sharedData.report);

        /* Set client currency if any passed */
        if(sharedData.report && sharedData.report.client) {
            AppFactory.setCurrentClientCurrency(sharedData.report.client.currency_code);
        }
    }

    function _syncPageIndexes() {
        _.each(sharedData.report.pages, function (page, index) {
            page.index = index;
        });
    }

    /**
     * Toggle the landscape mode
     */
    function toggleLandscape() {
        sharedData.fitToPage = false;

        sharedData.report.orientation = sharedData.report.orientation === PageOrientation.TYPE_LANDSCAPE ? PageOrientation.TYPE_PORTRAIT : PageOrientation.TYPE_LANDSCAPE;

        ExportBuilderResource.updateField(sharedData.report.id, 'orientation', sharedData.report.orientation);

        var itemsToSync = [];

        //sync show-on-every-page duplicate ghosts
        _.each(itemsToSync, function(item) {
            syncShowOnEveryPageItem(item.id, item, 0);
        });

        calculateZoom();

        PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ROTATE);
    }

    /**
     * @returns {boolean}
     */
    function getIsLandscape() {
        return sharedData.report.orientation === PageOrientation.TYPE_LANDSCAPE;
    }

    /**
     * Removes predefined data from all widgets on the current page
     */
    function emptyCurrentPageWidgetCache() {
        _.each(sharedData.currentPage.elements, function (item) {
            if (item.type === ReportElementTypes.WIDGET) {
                item.widget.metadata.dynamic.predefined_data = null;
            }
        });
    }

    /**
     * Invalidates widget cache in memory
     * @param widget
     */
    function emptyWidgetCache(widget) {
        widget.metadata.dynamic.predefined_data = null;
    }

    /**
     * Removes predefined data from all widgets in the report
     */
    function emptyAllWidgetCache() {
        _.each(sharedData.report.pages, function (page) {
            _.each(page.elements, function (item) {
                if (item.isTypeWidget()) {
                    sharedData.report.is_preview
                        ? _setItemPropertiesForPreview(item)
                        : _setItemPropertiesForData(item)
                }
            });
        });
    }

    /**
     * Creates a new report
     * @returns {*}
     */
    function createEmptyReport() {

        var report = {
            is_preview: true,
            title: gettextCatalog.getString('Untitled Report'),
            orientation: PageOrientation.TYPE_LANDSCAPE,
            type: ReportTemplateType.TYPE_CUSTOM,
            report_language: ExportBuilderNewReportAdminService.getSelectedLanguage(),
            format: PageFormatConstants.WIDESCREEN,
            metadata: {}
        };

        return ExportBuilderResource.createBlankReport(report)
            .then(function(newReport) {
                resetData(newReport);
                return sharedData.report;
            });
    }

    function saveEntireReport() {
        if (!sharedData.report || !sharedData.report.can_be_edited) {return}

        let reportToSave = angular.copy(sharedData.report);
        _trimElementsInReport(reportToSave);

        return ExportBuilderResource.saveFullReport(reportToSave);
    }

    /**
     * Initialize the grid
     * @returns {*}
     */
    function initGrid() {
        var deferred = $q.defer();

        calculateZoom(); //calculate zoom

        var setAsLoaded = function() {
            if (sharedData.report.pages.length === 0) {
                createNewPage([]); //create an empty first page if necessary
            }
            setTimeout(function() {
                sharedData.loaded = true; //set as loaded (animates css)
                deferred.resolve();
            }, 1000);
        };

        setAsLoaded();

        return deferred.promise;
    }

    /**
     * init with builder ID as parameter
     * ensure the report is loaded before actually initializing the grid
     * @param id
     * @param options
     * @returns {*}
     */
    function initWithReportId(id, options = {}) {
        var deferred = $q.defer();

        sharedData.isAuthoring = true;

        ExportBuilderResource.get(id).then(function(report) {
            let counter = 1;
            // init with the report
            resetData(report, options);
            report.pages = _.map(report.pages, function (page) {
                page = ExportBuilderDashboardModelFactory.getReportPageModel(page);
                page.elements = mapElements(page.elements);
                if (!page.metadata.isHidden) {
                    page.number = counter;
                    counter++;
                } else {
                    page.number = null;
                }
                return page;
            });

            report.elements = mapElements(report.elements);
            sharedData.report = ExportBuilderDashboardModelFactory.getReportTemplateModel(report);

            //then load the grid
            initGrid().then(function () {
                ReportStudioTemplateDataService.setReport(sharedData.report);
                sharedData.currentPage = sharedData.report.pages[0];
                deferred.resolve();
            });

            /**
             * helper function
             * @param elements
             * @returns {Array}
             */
            function mapElements(elements) {
                return _.map(elements, function (element) {
                    element = ExportBuilderDashboardModelFactory.getReportElementModel(element);
                    if (element.widget) {
                        assignFrontEndProperties(element);
                    }
                    return element;
                });
            }
        }).catch(function(error) {
            const route = UserLoginFactory.getDefaultHomeRoute(AppFactory.getUser(), true);
            if (window.isNUI) {
                $timeout(function() {
                    WindowUtilUIService.navigateToNewApp(route.state);
                }, 3000);
            } else {
                $state.go(route.state, route.params, route.options);
            }
        });

        return deferred.promise;
    }

    /**
     * Calculate the zoom needed to make the full page fit in the page
     * @param ratio
     */
    function calculateZoom() {
        //never change the zoom when exporting
        if (sharedData.readOnly) {
            return;
        }
        let aspectRatio = PageAspectRatio.STANDARD;
        if (sharedData.report && sharedData.report.format) {
            aspectRatio = sharedData.report.format === PageFormatConstants.STANDARD ? PageAspectRatio.STANDARD : PageAspectRatio.WIDESCREEN;
        }

        var pageWidth = PageSpecifications.WIDTH / aspectRatio; //original page width
        var pageHeight = PageSpecifications.WIDTH;

        if (getIsLandscape()) {
            pageWidth = PageSpecifications.WIDTH; //original page width
            pageHeight = PageSpecifications.WIDTH / aspectRatio; //original page width
        }

        if (sharedData.currentPage.metadata.needs_pagination && !sharedData.currentPage.metadata.hide_if_empty_widget) {
            $rootScope.$broadcast($WidgetEvents.WIDGET_REBUILD);
        }
        if (sharedData.isPresentationMode) {
            var canvasWidth = angular.element(window).width();
            var canvasHeight = angular.element(window).height();
            var xZoom = canvasWidth / pageWidth * 100;
            var yZoom = canvasHeight / pageHeight * 100;

            sharedData.marginTop = 0;
            sharedData.zoom = Math.min(yZoom, xZoom);

            PubSub.emit($ExportBuilderDashboardEvents.ZOOM_CALCULATED);
            return;
        }

        var canvasWidth = angular.element(window).width() - PageLayerPanel.WIDTH - BuilderPanel.WIDTH;
        var canvasHeight = angular.element(window).height() - 82;

        var xZoom = ((canvasWidth - originalPageMargins.left - originalPageMargins.right)) / pageWidth * 100;
        var yZoom = ((canvasHeight - originalPageMargins.left - originalPageMargins.right)) / pageHeight * 100;

        sharedData.zoom = Math.min(yZoom, xZoom);

        if (sharedData.fitToPage) {
            sharedData.zoom = xZoom;

            sharedData.marginTop = ((PageSpecifications.WIDTH * sharedData.zoom / 100) - canvasHeight) / 2 + 40;
            if (sharedData.marginTop < 0) {
                sharedData.marginTop = 0;
            }
        } else {
            sharedData.marginTop = 0;
        }

        //do not allow over-zoom data
        if (sharedData.zoom > 100) {
            sharedData.zoom = 100;
        } else if (sharedData.zoom < _minimumZoomPercent) {
            sharedData.zoom = _minimumZoomPercent;
        }

        PubSub.emit($ExportBuilderDashboardEvents.ZOOM_CALCULATED);
    }

    /**
     * Creates a new page with items
     * @param items
     * @param atIndex
     */
    function createNewPage(items, atIndex) {

        //assign current page to all items
        _.each(items, function(item) {
            item.page_index = atIndex ? atIndex : sharedData.report.pages.length
        });

        if (!_.isUndefined(atIndex)) {
            sharedData.report.pages[atIndex] = {
                elements: items,
                index: atIndex
            };
        } else {
            sharedData.report.pages.push({
                elements: items
            });
        }

        // ensure show-on-every-pages is sync properly
        syncShowOnEveryPage();
    }

    /**
     *
     * @param atIndex
     * @param [elements]
     * @returns {ReportPageModel}
     */
    function initNewPage(atIndex, elements, isExecSummaryPage) {
        var pageIndex = _.isUndefined(atIndex) ? sharedData.report.pages.length: atIndex;
        const pageTitle = isExecSummaryPage ? gettextCatalog.getString('Executive Summary Page') : gettextCatalog.getString('Untitled Page');
        return ExportBuilderDashboardModelFactory.getReportPageModel({
            id: null,
            title: pageTitle,
            report_id: sharedData.report.id,
            elements: elements,
            page_index: pageIndex,
            is_executive_summary_page: isExecSummaryPage,
        });
    }

    /**
     * Sync every show-on-every-page items from the first page
     */
    function syncShowOnEveryPage() {
        if (sharedData.report.pages[0]) {
            _.each(sharedData.report.pages[0].elements, function(item) {
                syncShowOnEveryPageItem(item.id, {}, 0);
            });
        }

    }

    /**
     * Sync show-on-every-page items with a starting item
     * all the items will have the same properties
     * @param itemId
     * @param props
     * @param currentPage
     */
    function syncShowOnEveryPageItem(itemId, props, currentPage) {
        props = props || {};

        //ensure we're targeting the proper kind of item
        if (!itemId || props.type === ReportElementTypes.WIDGET) {
            return;
        }

        //get complete item from it's ID
        var completeProps = getItemFromId(itemId);
        if (!completeProps.show_on_every_page) {
            return;
        }

        //go through all the page and apply props to every widget with the same id
        _.each(sharedData.report.pages, function(page, pageIndex) {
            if (page.is_cover_page || page.is_back_page) {return;}

            //don't update current page item, since it's the one that triggers this reflow
            if (pageIndex !== currentPage) {
                var ghostItem = _.find(page.elements, { 'id' : itemId });
                var propsCopy = angular.copy(props);
                if (!_.isUndefined(ghostItem)) {
                    _.assign(ghostItem, propsCopy);
                }
                //if the item does not exist, create it
                else {
                    _.assign(completeProps, propsCopy);
                    page.elements.push(completeProps);
                }
            }
        });

    }

    /**
     * Adds a new element to the report
     * @param type
     * @param params
     * @param widget
     * @param [returnPromise]
     * @returns {*}
     */
    function addNewItem(type, params, widget, returnPromise) {
        var gridUnitPercent = 100 / PageGridConstants.Y;

        params.x_position = gridUnitPercent * 8;
        params.y_position = gridUnitPercent * 8;
        params.width = gridUnitPercent * 12; // 12 squares
        params.height = gridUnitPercent * 12; // 12 squares

        if (type === ReportElementTypes.WIDGET) {
            params.width *= 2;
            params.height *= 2;
        }

        var item = initNewItem(type, params, widget);
        item.id = getNewItemId();
        item.z_index = null;
        var promise = addItem(item, sharedData.currentPage.id);
        if (returnPromise) {
            return {item: item, promise: promise};
        } else {
            return item;
        }
    }

    /**
     * Add an item to the current page
     * @param item
     * @param pageId
     * @param noSave
     * @returns {*}
     */
    function addItem(item, pageId, noSave) {
        _preAddItem(item, pageId);
        if (!noSave) {
            return _saveItem(item).then(function () {
                assignFrontEndProperties(item);
                return item;
            });
        }
    }

    /**
     * Adds required props before saving/copying an item
     * @param item
     * @param pageId
     * @returns {*}
     * @private
     */
    function _preAddItem(item, pageId) {
        //add the widget by default to the current focus page
        pageId = _.isUndefined(pageId) ? sharedData.currentPage.id : pageId;
        var page = ReportStudioTemplateDataService.getPageById(pageId);

        //save the builder id
        if (item.show_on_every_page) {
            item.report_id = sharedData.report.id;
            sharedData.report.elements.push(item);
            page.elements.unshift(item);
            syncShowOnEveryPageItem(item.id, item, page.page_index);
        } else {
            item.page_id = page.id;
            //get the higher available z-index for the item
            item.z_index = item.z_index || nextAvailableZIndex(page.page_index);
            //append the item to the page
            page.elements.unshift(item);
        }

        if (item.widget && item.widget.id && item.widget && item.widget.metadata && item.widget.metadata.dynamic && item.widget.metadata.dynamic.filters) {
            PubSub.emit($WidgetFilterEvents.UPDATE_GLOBAL_FILTERS, {currentWidget: item.widget})
            $timeout(() => {
                PubSub.emit($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_APPLY);
            },0);
        }

        ExportBuilderDashboardUtilService.snapItemToGrid(item);

        return item;
    }

    /**
     * @param {ReportTemplateModel} report
     * @private
     */
    function _trimElementsInReport(report) {
        _.each(report.pages, page => {
            _.each(page.elements, element => {
                if (element.isTypeWidget()) {
                    element.widget_needs_update = true;

                    // If media widget was never loaded in the report, dont send it to be saved
                    if (
                      (element.widget.type === WidgetType.MEDIA &&
                        _.isNil(element.widget.metadata.content)) ||
                      element.widget.type === WidgetType.EXECUTIVESUMMARY
                    ) {
                      element.widget_needs_update = false;
                    }

                    element.widget = WidgetUtilService.trimWidgetMetadata(element.widget);
                }
            })
        });
    }

    var debounceSaveItem;
    /**
     * @param item {ReportElementModel}
     */
    function delayUpdateItem(item) {
        if (!debounceSaveItem) {
            debounceSaveItem = _.debounce(updateItem, ExportBuilderDashboardConstants.DELAY_REPORT);
        }

        var currentItem = getItemFromId(item.id);
        ExportBuilderDashboardUtilService.snapItemToGrid(currentItem);
        _syncItemShowOnEveryPageIfNeeded(currentItem);
        PubSub.emit($ExportBuilderDashboardEvents.ON_ITEM_UPDATED+currentItem.id, currentItem);

        // update server
        // the include is needed to not send updates when the item is being created in the background
        if (!_.includes(currentItem.id, ExportBuilderPrefixes.ITEMID)) {
            ExportBuilderDashboardSyncService.setElement(currentItem);
            // debounceSaveItem(currentItem);
        }
        if (_.includes(currentItem.id, ExportBuilderPrefixes.ITEMID)) {
            pendingItems[currentItem.id] = true;
        }
    }

    function delayUpdateElements(elements) {
        _.each(elements, ExportBuilderDashboardSyncService.setElement);
    }

    /**
     * Updates widget of the element
     * @param {ReportElementModel} element
     */
    function delayUpdateWidgetItem(element) {
        element.widget_needs_update = true;
        delayUpdateElements([element]);
    }

    /**
     * Updates an element
     * @param item
     * @param props
     * @param noSave
     */
    function updateItem(item, props, noSave) {
        if (!item || item.id.contains(ExportBuilderPrefixes.ITEMID)) {
            return;
        }
        var currentItem = getItemFromId(item.id);
        if (!currentItem) {
            return;
        }

        //update properties
        _.assign(currentItem, props);

        _syncItemShowOnEveryPageIfNeeded(item);

        // update server
        // the include is needed to not send updates when the item is being created in the background
        if (!noSave) {
            _saveItem(currentItem);
        }
        if (_.includes(currentItem.id, ExportBuilderPrefixes.ITEMID)) {
            pendingItems[currentItem.id] = true;
        }

        ExportBuilderDashboardUtilService.snapItemToGrid(currentItem);

        PubSub.emit($ExportBuilderDashboardEvents.ON_ITEM_UPDATED+currentItem.id, currentItem);
    }

    /**
     * Removes an element
     * @param item
     */
    function removeItem(item) {
        item = getItemFromId(item.id);
        if (!item || item.id.contains(ExportBuilderPrefixes.ITEMID)) { // cant delete with temp id
            return;
        }

        //go through all the pages because one item can
        //be displayed on multiple pages
        _.each(sharedData.report.pages, function(page) {
            _.remove(page.elements, {'id': item.id});
        });

        // Update server
        var removeItemPromise = ExportElementResource.remove(item);

        //ensure zIndex continuity
        ensureZIndexContinuity(item.page_index);

        if (item.widget) {
            removeGlobalFilters(item.widget);
        }
        focusOnItem(null);

        PubSub.emit($ExportBuilderDashboardEvents.ON_ITEM_DELETED, item);

        return removeItemPromise;
    }

    /**
     * Copies the item to the current page of the report
     * @param item
     * @param returnPromise
     */
    function copyItem(item, returnPromise) {
        var newItem = ExportBuilderDashboardModelFactory.getReportElementModel(_.cloneDeep(item));

        newItem.id = ExportBuilderPrefixes.ITEMID+randomId();
        newItem.isEditing = false;
        newItem.focused = false;

        if ((sharedData.currentPage.is_cover_page || sharedData.currentPage.is_back_page) && item.show_on_every_page) {
            return;
        }

        newItem.page_id = sharedData.currentPage.id;

        var overrides = {
            page_id: newItem.page_id
        };

        _preAddItem(newItem, newItem.page_id);

        var promise = ExportElementResource.copy(item, overrides).then(function (newSavedItem) {
            var oldItemId = newItem.id;
            newItem.id = newSavedItem.id;
            if (newItem.isTypeWidget()) {
                newItem.widget_id = newSavedItem.widget_id;
                newItem.widget = newSavedItem.widget;
            }
            _syncItemsIfNeeded(oldItemId, newItem);
            PubSub.emit($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED + oldItemId, newSavedItem);
            assignFrontEndProperties(newItem);

            return newItem;
        });

        focusOnItem(null);
        PubSub.emit($ExportBuilderDashboardEvents.ON_ITEM_ADDED);

        if (returnPromise) {
            return {item: item, promise: promise};
        } else {
            return item;
        }
    }

    /**
     * Creates a new element
     * @param type
     * @param params
     * @param widget
     * @param zIndex
     * @returns ReportElementModel
     */
    function initNewItem(type, params, widget, zIndex) {
        var id = widget ? ExportBuilderPrefixes.WIDGETID + widget.id : (params.id ? params.id : null);

        var model = ExportBuilderDashboardModelFactory.getReportElementModel({
            id: id || getUniqueItemId(),
            type: type,
            z_index: zIndex || ZIndexProps.BASE,
            y_position: params.y_position,
            x_position: params.x_position,
            height: params.height,
            width: params.width,
            isEditing: !!params.isEditing,
            snap_to_grid: params.snap_to_grid || true
        });
        var baseOptions = ReportElementMetadataOptionsService.getDefaultElementBaseOptions(widget?.metadata);
        switch(type) {
            case ReportElementTypes.WIDGET:
                model.metadata = {};
                model.metadata.design_options = ReportElementMetadataOptionsService.getDefaultElementWidgetDesignOptions();
                model.widget = angular.copy(widget);
                model.widget.is_export = true;
                model.show_on_every_page = false; // we dont support this for widgets
                model.widget.metadata.draw_options.show_background.value = false;
                if (model.widget.metadata.draw_options.show_borders) {
                    model.widget.metadata.draw_options.show_borders.value = false;
                } else {
                    model.widget.metadata.draw_options.show_borders = {value: false, overridden: false}
                }
                assignFrontEndProperties(model);
                model.widget_id = widget.id;
                break;
            case ReportElementTypes.SHAPE:
                model.metadata = {};
                model.metadata.design_options = ReportElementMetadataOptionsService.getDefaultShapeDesignOptions(params.shape_type, sharedData.report.getRandomThemeColor());
                model.metadata.shape_type = params.shape_type;
                model.snap_to_grid = false;
                // to make sure we start with a perfect square/circle
                model.width = 250;
                model.height = 250;
                model.x_position = 100;
                model.y_position = 100;
                break;
            case ReportElementTypes.ICON:
                model.metadata = {};
                model.metadata.icon = params.icon || 'icon-start';
                model.metadata.design_options = ReportElementMetadataOptionsService.getDefaultIconDesignOptions();
                break;
            case ReportElementTypes.IMAGE:
                model.show_on_every_page = params.show_on_every_page == 1;
                model.metadata = {
                    macro_type: params.macro_type,
                    image_url: params.image_url,
                    design_options: ReportElementMetadataOptionsService.getDefaultImageDesignOptions()
                };
                break;
            case ReportElementTypes.TEXT:
                model.show_on_every_page = params.show_on_every_page == 1;
                model.metadata = {
                    macro_type: params.macro_type,
                    design_options: ReportElementMetadataOptionsService.getDefaultTextDesignOptions()
                };
                break;
        }
        model.metadata.base_options = baseOptions;

        return model;
    }

    /**
     * @param newWidget
     * @returns {*} element
     */
    function updateItemWidget(newWidget) {
        var updatedElement = null;
        _.each(sharedData.currentPage.elements, function (element) {
            if (element.type === ReportElementTypes.WIDGET && element.widget.id === newWidget.id) {
                element.widget = newWidget;
                assignFrontEndProperties(element);
                emptyWidgetCache(element.widget);

                updatedElement = element;
                return false;
            }
        });
        return updatedElement;
    }

    /**
     * Assign default properties to widget for report
     * @private
     * @param {ReportElementModel} element
     */
    function assignFrontEndProperties(element) {
      if (element.isTypeWidget()) {
        element.widget.is_export = true;

        if (
          !_.isNull(sharedData.report.is_preview) &&
          sharedData.report.is_preview
        ) {
          element.widget.metadata.draw_options[
            DrawOption.FORCE_SAMPLE_DATA
          ] = true;
        } else {
          delete element.widget.metadata.draw_options[
            DrawOption.FORCE_SAMPLE_DATA
          ];
        }
      }
    }

    /**
     * Toggle the edit state of the widget
     * this is used only for WYSIWYG items
     * @param item
     */
    function toggleEditOnItem(item) {
        if (!item || item.type !== ReportElementTypes.TEXT) {
            return;
        }
        item.isEditing = !item.isEditing;
    }

    /**
     * @param items
     * @returns {*}
     */
    function reorderPagesIndeces(items) {
        var pages = _.map(sharedData.report.pages, function (page) {
            return {id: page.id, page_index: page.page_index}
        });
        sharedData.report.pages = _.sortBy(sharedData.report.pages, ['page_index']);
        return ExportBuilderResource.batchUpdatePages(sharedData.report, pages);
    }

    /**
     * @param items
     * @returns {*}
     */
    function reorderItemsZIndeces(items, page) {
        page = page || sharedData.currentPage;
        page.elements = _.orderBy(items, ['z_index'], ['desc']);

        _.each(page.elements, ExportBuilderDashboardSyncService.setElement);
    }

    /**
     * Moves an item to another page
     * @param item
     * @param fromPageIndex
     * @param toPageIndex
     * @param returnPromise
     */
    function copyItemToPage(item, fromPageIndex, toPageIndex, returnPromise) {
        if (toPageIndex < 0 || toPageIndex > sharedData.report.pages.length - 1) {
            throw new Error(gettextCatalog.getString('Cannot move item to this page.'));
            return;
        }

        var toPage = getPageAtIndex(toPageIndex);

        if ((toPage.is_cover_page || toPage.is_back_page) && item.show_on_every_page) {
            throw new Error(gettextCatalog.getString('Cannot copy \'show on every page\' element to ' + (toPage.is_cover_page ? 'cover page' : 'back page')));
            return;
        }

        goToPageIndex(toPageIndex);

        return copyItem(item, returnPromise);
    }

    /**
     * Save all items that are in the "save pending items"
     * @returns {*}
     */
    function savePendingItems() {
        if (pendingItems.length && !sharedData.readOnly) {

            var saveItems = [];

            _.each(pendingItems, function (item) {
                //save it
                saveItems.push(_saveItem(item, true));
            });

            //then clean the pending items array
            pendingItems = {};

            return Promise.all(saveItems);
        }
        else {
            return Promise.resolve();
        }
    }

    function setFocusToElements(elements) {
        elements = _.filter(elements, function (element) {return !element.metadata.set_as_background});
        _.each(sharedData.elements, function (element) {
            element.focused = false;
            element.isEditing = false;
        });
        sharedData.elements.clear();
        _.each(elements, addFocusToElement);

        _recalculateCommonProperties();

        emitNewItemFocus();
    }

    function addFocusToElement(element) {
        if (!element
            || _.find(sharedData.elements, {id: element.id})) {
            return
        }

        element.focused = true;
        element.isEditing = true;
        sharedData.elements.push(element);
        _recalculateCommonProperties();

        emitNewItemFocus();
    }

    function updateCurrentElementSelection() {
        _recalculateCommonProperties();
        emitNewItemFocus();
    }

    function clearSelection() {
        _.each(sharedData.elements, function (element) {
            element.focused = false;
            element.isEditing = false;

            if (element.isInSpecialEditMode
                && element.isGeoWidget()) {
                PubSub.emit($ExportBuilderDashboardEvents.WIDGET_ELEMENT_SPECIAL_EDIT_NEEDS_UPDATE, element);
            }
            element.isInSpecialEditMode = false;
        });
        sharedData.elements.clear();
        focusOnItem(null);
    }

    /**
     *
     * @param widget
     */
    function removeGlobalFilters(widget) {
        if (widget.metadata.dynamic.filters && widget.metadata.dynamic.filters.length) {
            widget.metadata.dynamic.filters.forEach(filter => {
                ExportBuilderFilterService.removeFilter(widget, filter, true);
            });
        }
    }

    /**
     * Makes the element ready for focus, enables all item properties and resize
     * @param item
     */
    function focusOnItem(item) {
        if (item) {
            item = getItemFromId(item.id);
        }

        _.each(sharedData.elements, function (element) {
            element.focused = false;
            element.isEditing = false;
            if (element.isInSpecialEditMode
                && element.isGeoWidget()) {
                PubSub.emit($ExportBuilderDashboardEvents.WIDGET_ELEMENT_SPECIAL_EDIT_NEEDS_UPDATE, element);
            }
            element.isInSpecialEditMode = false;
        });
        sharedData.elements.clear();

        //test against new item to avoid un-natural use of focus
        if (item && sharedData.currentItem === item || sharedData.readOnly) {
            return; //do nothing
        }

        //if there is already a focused item
        if (sharedData.currentItem) {
            //unfocus it
            sharedData.currentItem.focused = false;
            //ensure to remove the editing mode
            sharedData.currentItem.isEditing = false;
            sharedData.currentItem = null;
        }

        //focus on the new item
        sharedData.currentItem = item;

        //this allows us to call focusOnItem(null) to unfocus
        if (item) {
            addFocusToElement(item);
        }

        _recalculateCommonProperties();

        PubSub.$emit($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, sharedData.currentItem);
    }

    function emitNewItemFocus() {
        PubSub.$emit($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, sharedData.currentItem);
    }

    function _recalculateCommonProperties() {
        if (!sharedData.elements.length) {return}

        var topLevelMetadataProps = commonDifferentProperties(_.map(sharedData.elements, function (element) {
            return element.metadata;
        }));

        var designOptions = commonDifferentProperties(_.map(sharedData.elements, function (element) {
            return element.metadata.design_options;
        }));

        var baseOptions = commonDifferentProperties(_.map(sharedData.elements, function (element) {
            return element.metadata.base_options;
        }));

        var shadowElement = commonDifferentProperties(sharedData.elements);
        shadowElement.metadata = topLevelMetadataProps;
        shadowElement.metadata.base_options = baseOptions;
        shadowElement.metadata.design_options = designOptions;

        if (shadowElement.type === ReportElementTypes.WIDGET) {
            var widget_draw_options = commonDifferentProperties(_.map(sharedData.elements, function (element) {
                return _.extend(angular.copy(element.widget.metadata.draw_options), {
                    'show_header': element.widget.metadata.draw_options.show_header.value,
                    'show_title': element.widget.metadata.draw_options.show_title.value,
                    'show_data_source': element.widget.metadata.draw_options.show_data_source.value,
                    'show_metric_name': element.widget.metadata.draw_options.show_metric_name.value,
                    'show_header_overridden': element.widget.metadata.draw_options.show_data_source.overridden,
                    'show_title_overridden': element.widget.metadata.draw_options.show_data_source.overridden,
                    'show_data_source_overridden': element.widget.metadata.draw_options.show_data_source.overridden,
                    'show_metric_name_overridden': element.widget.metadata.draw_options.show_metric_name.overridden,
                });
            }));

            if (!shadowElement.widget) {
                shadowElement.widget = {
                    metadata : {}
                }
            }
            shadowElement.widget.metadata.draw_options = widget_draw_options;

            shadowElement.widget.metadata.draw_options.show_header = {
              value: widget_draw_options.show_header,
              overridden: widget_draw_options.show_header_overridden,
            };
            shadowElement.widget.metadata.draw_options.show_title = {
              value: widget_draw_options.show_title,
              overridden: widget_draw_options.show_title_overridden,
            };
            shadowElement.widget.metadata.draw_options.show_data_source = {
              value: widget_draw_options.show_data_source,
              overridden: widget_draw_options.show_data_source_overridden,
            };
            shadowElement.widget.metadata.draw_options.show_metric_name = {
              value: widget_draw_options.show_metric_name,
              overridden: widget_draw_options.show_metric_name_overridden,
            };
        }

        sharedData.currentItem = ExportBuilderDashboardModelFactory.getReportElementModel(shadowElement);
    }

    function commonDifferentProperties(objects) {
        var common = JSON.parse(JSON.stringify(objects[0]));
        var unmatchedProps = {};
        for (var i = 1; i < objects.length; i++) {
            for (var prop in objects[i]) {
                checkProps(objects[i],common,prop);
            }
            for (var commProp in common) {
                checkProps(common,objects[i],commProp);
            }
        }
        return common;

        function checkProps(source, target, prop) {
            if (source.hasOwnProperty(prop)) {
                var val = source[prop];

                // does not have value
                if (!target.hasOwnProperty(prop)) {
                    unmatchedProps[prop] = true;
                    delete common[prop];
                }

                // common but is values are different, sent to null
                if (target.hasOwnProperty(prop) && target[prop] !== val) {
                    common[prop] = null;
                }
            }
        }
    }

    function toggleItemHidden(item) {
        item.isHidden = !item.isHidden;
        item.focused = false;

        // unset currentitem
        if (sharedData.currentItem && item.id === sharedData.currentItem.id) {
            sharedData.currentItem = null;
        }
    }

    function unHideAll() {
        _.each(sharedData.currentPage.elements, function (item) {
            item.isHidden = false;
            item.focused = false;
        });
    }

    /**
     * Go thru all the items in the to ensure the z-indexes are correct
     * @param pageIndex
     */
    function ensureZIndexContinuity(pageIndex) {
        var changedItems = [];

        if (!_.isNaN(pageIndex) && _.isObject(sharedData.report.pages[pageIndex]) && _.isArray(sharedData.report.pages[pageIndex].elements)) {
            //order items by z-index
            var orderedItems = _.orderBy(sharedData.report.pages[pageIndex].elements, ['z_index']);
            if (orderedItems.length) {
                for (var i = 0; i < orderedItems.length; i++) {
                    //if the z_index is not what it's suppose to be
                    if (orderedItems[i].z_index !== ZIndexProps.BASE + (i * ZIndexProps.STEP)) {
                        //fix it
                        orderedItems[i].z_index = ZIndexProps.BASE + (i * ZIndexProps.STEP);

                        //save the item
                        changedItems.push(orderedItems[i]);
                    }
                }
            }
        }

        //save all changed items for the page
        _.each(changedItems, _saveItem);
    }

    /**
     * returns the next available z-index for a specific page
     * @param pageIndex
     * @returns {*}
     */
    function nextAvailableZIndex(pageIndex) {
        return nextAvailableZIndexForItems(sharedData.report.pages[pageIndex].elements);
    }

    function setWidgetModelForSpecialEdit(widget) {
        sharedData.specialEditWidgetModel = angular.copy(widget);
    }

    function getSkipDrawOptions(widgetType) {
        switch (widgetType) {
            case WidgetType.DATAGRID:
                return [
                    `${DrawOption.GRID_COLLAPSE_IN_MODAL},${DrawOption.GRID_IS_RESPONSIVE},${DrawOption.GRID_PAGINATE},${DrawOption.STYLIZED_EXCEL},${DrawOption.REPORT_DISPLAY_LENGTH}`
                ];
            case WidgetType.PIECHART:
            case WidgetType.FUNNELCHART:
            case WidgetType.GAUGECHART:
                return [
                    `${DrawOption.HAS_TOOLTIP}`
                ];
            case WidgetType.COMBINATIONCHART:
            case WidgetType.LINECHART:
            case WidgetType.BUBBLECHART:
                return [
                    `${DrawOption.HAS_TOOLTIP},${DrawOption.HAS_VALUE_SCROLLER}`
                ];
            case WidgetType.BARCHART:
                return [
                    `${DrawOption.HAS_VALUE_SCROLLER},${DrawOption.HAS_TOOLTIP},${DrawOption.HIDE_GRID_LINES}`,
                ];
            default:
                return [];
        }
    }

    function getSkipDrawOptionsDashboard(widgetType) {
        switch (widgetType) {
            case WidgetType.DATAGRID:
                return [
                    `${DrawOption.SPLIT_IN_MULTIPLE_PAGES},${DrawOption.ROWS_PER_PAGE},${DrawOption.NUMBER_OF_PAGE},${DrawOption.REPEAT_FULL_LAYOUT},${DrawOption.GRID_MAX_HEIGHT_IMAGE}`,
                ];
            default:
                return [];
        }
    }

    function getWidgetModelForSpecialEdit() {
        return sharedData.specialEditWidgetModel;
    }


    //
    //  returns the next available z-index for a specific set of items
    //
    function nextAvailableZIndexForItems(items) {
        var found = _.maxBy(items, 'z_index');
        return found ? found.z_index + 1 : ZIndexProps.BASE + ZIndexProps.STEP;
    }

    function setColorPaletteColor(newColor, atIndex) {
        if (_.findIndex(sharedData.report.metadata.chart_palette, atIndex) === -1) {
            return;
        }
        sharedData.report.metadata.chart_palette[atIndex] = newColor;
    }

    function setColorPalette(newColorPalette) {
        sharedData.report.metadata.chart_palette = newColorPalette;
        delayReportSave();
        PubSub.emit($ExportBuilderDashboardEvents.CHART_PALETTE, sharedData.report.metadata.chart_palette);
    }

    function setFontSize(newFontSize) {
        if ([FontSize.SIZE_SMALL, FontSize.SIZE_MEDIUM, FontSize.SIZE_LARGE].includes(newFontSize)) {
            sharedData.report.metadata.font_size = newFontSize;
            if (sharedData.report.metadata.datagrid_with_fixed_header_size) {
                sharedData.report.metadata.datagrid_with_fixed_header_size = false;
            }
            delayReportSave();
        }
    }

    function setCluster(cluster) {
        if (cluster == sharedData.report.cluster) {
            return;
        }

        sharedData.report.cluster = _.pick(cluster, ['id', 'text']);
    }

    function setClientGroup(clientGroup) {
        if (clientGroup === sharedData.report.client_group) {
            return;
        }

        if (_.isEmpty(clientGroup)) {
            sharedData.report.client_group = null;
            sharedData.report.client_group_id = null;
            sharedData.report.client_logo_url = null;
            setEntity(null);
        } else {
            sharedData.report.client_group = _.pick(clientGroup, ['id', 'text']);
            _setEntityFromClientGroup();
        }
    }

    function setClient(client) {
        if (client == sharedData.report.client) {
            return;
        }

        if (!_.isEmpty(client)) {
            sharedData.report.is_preview = false
        }

        sharedData.report.client = _.pick(client, ['id', 'text']);
        if (_.isEmpty(sharedData.report.client)) {
            sharedData.report.client = null;
            sharedData.report.client_id = null
        }

        if (sharedData.report.client) {
            sharedData.report.client_id = sharedData.report.client.id
            ExportBuilderResource.getUserLogo(sharedData.report.client.id)
                .then(data => {
                    sharedData.report.client_logo_url = data;
                    _setEntityFromClient();
                });
        } else {
            sharedData.report.client_logo_url = null;
            setEntity(null);
        }
        PubSub.emit($ExportBuilderDashboardModelEvents.DID_SET_CLIENT, sharedData.report.client);
    }

    function setFilterMode(filterMode) {
        sharedData.report.metadata.filter_mode = filterMode;
    }

    function setFontType(fontType) {
        sharedData.report.metadata.font_type = fontType;
    }

    function updateReportAssociations() {
        const model = {
            id: sharedData.report.id,
            selectedCluster: sharedData.report.cluster,
            selectedClient: sharedData.report.client,
            selectedClientGroup: sharedData.report.client_group,
            filterMode: sharedData.report.metadata.filter_mode,
            fontType: sharedData.report.metadata.font_type,
            is_preview: sharedData.report.is_preview
        };
        return ExportBuilderResource.save(model);
    }

    /**
     * Set session date variables in backend
     * @returns {*}
     */
    function saveDateRange() {
        const dateRange = AppFactory.getDateRange();
        const comparisonDateRange = AppFactory.getComparisonDateRange();
        return ExportBuilderResource.saveDateRange({
            start_time: moment.unix(dateRange.start).utc().format(MomentDateFormat.ISO),
            end_time: moment.unix(dateRange.end).utc().format(MomentDateFormat.ISO),
            comparison_start_time: moment.unix(comparisonDateRange.start).utc().format(MomentDateFormat.ISO),
            comparison_end_time: moment.unix(comparisonDateRange.end).utc().format(MomentDateFormat.ISO),
            comparison_active: comparisonDateRange.enabled,
            relative_date_range: dateRange.relativeRange,
            comparison_period: null
        });
    }

    /**
     * Send title to the server
     */
    function saveReportTitle() {
        ExportBuilderResource.updateField(sharedData.report.id, 'title', sharedData.report.title);
    }

    function _setEntityFromClient() {
        const { id, text } = sharedData.report.client;
        let entity = {id, text, type: 'client', logo: sharedData.report.client_logo_url};
        setEntity(entity);
    }

    /**
     *
     * @private
     */
    function _setEntityFromClientGroup() {
        const { id, text } = sharedData.report.client_group;
        let entity = {id, text, type: 'client_group'};
        setEntity(entity);
    }

    /**
     * Find and replace item as show_on_every_page since they are duplicates
     *  in page elements
     * @param item
     * @private
     */
    function _syncItemShowOnEveryPageIfNeeded(item) {
        if (!item.show_on_every_page) {
            return;
        }

        _.each(sharedData.report.pages, function (page) {
            var index = _.findIndex(page.elements, {id: item.id});
            if (index >= 0) {
                page.elements.splice(index, 1, item);
            }
        });

        var index = _.findIndex(sharedData.report.elements, {id: item.id});
        if (index >= 0) {
            sharedData.report.elements.splice(index, 1, item);
        }
    }

    var debounceUpdate;
    function delayUpdateReport() {
        if (!debounceUpdate) {
            debounceUpdate = _.debounce(updateReport, ExportBuilderDashboardConstants.DELAY_REPORT);
        }
        debounceUpdate();
    }

    function delayReportSave() {
        ExportBuilderDashboardSyncService.setReport(sharedData.report);
    }

    function toggleReportWidgetDrawOption(drawOptionKey, value) {
        var report = sharedData.report;

        report.metadata.draw_options[drawOptionKey].value = value;
        report.metadata.draw_options[drawOptionKey].overridden = !report.metadata.draw_options[drawOptionKey].value;

        PubSub.emit($ExportBuilderDashboardEvents.REPORT_DRAW_OPTIONS, angular.copy(report.metadata.draw_options));

        delayUpdateReport();
    }

    /**
     * Updates report to API
     */
    function updateReport() {
        sharedData.report.number_of_pages = sharedData.report.pages.length;
        AppFactory.setPageTitle(sharedData.report.title);
        return ExportBuilderResource.save(sharedData.report);
    }

    function getFirstDesiredPageIndex() {
        if (sharedData.report.pages[0].metadata.isHidden) {
            return getNextDesiredPageIndex();
        }
        return 0;
    }

    function getNextDesiredPageIndex() {
        for(let i = sharedData.currentPageIndex +1; i < sharedData.report.pages.length ; i++) {
            if (!sharedData.report.pages[i].metadata.isHidden) {
                return sharedData.report.pages[i].page_index;
            }
        }
    }

    /**
     * Goes to the next available page
     */
    function goToNextPage() {
        sharedData.previousPageIndex = sharedData.currentPageIndex;
        var desiredPageIndex = getNextDesiredPageIndex();

        if (desiredPageIndex === sharedData.currentPageIndex) {
            return;
        }

        if (desiredPageIndex <= sharedData.report.pages.length - 1) {
            clearSelection();
            sharedData.currentPage = sharedData.report.pages[desiredPageIndex];
            sharedData.currentPageIndex = desiredPageIndex;
            ExportBuilderDashboardUtilService.snapItemsToGrid(sharedData.currentPage.elements);
            PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_CHANGED);
        }
    }

    function getPreviousDesiredPageIndex() {
        for(let i = sharedData.currentPageIndex -1; i >=0 ; i--) {
            if (!sharedData.report.pages[i].metadata.isHidden) {
                return sharedData.report.pages[i].page_index;
            }
        }
    }

    /**
     * Goes to the next available previous page
     */
    function goToPreviousPage() {
        sharedData.previousPageIndex = sharedData.currentPageIndex;
        var desiredPageIndex = getPreviousDesiredPageIndex();

        if (desiredPageIndex === sharedData.currentPageIndex) {
            return;
        }

        if (desiredPageIndex >= 0) {
            clearSelection();
            sharedData.currentPage = sharedData.report.pages[desiredPageIndex];
            sharedData.currentPageIndex = desiredPageIndex;
            ExportBuilderDashboardUtilService.snapItemsToGrid(sharedData.currentPage.elements);
            PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_CHANGED);
        }
    }

    /**
     * Goes to desired page index
     * @param desiredPageIndex
     */
    function goToPageIndex(desiredPageIndex) {
        if (desiredPageIndex < 0) {
            desiredPageIndex = 0;
        }
        if (desiredPageIndex >= 0 && desiredPageIndex <= sharedData.report.pages.length - 1) {
            clearSelection();
            sharedData.currentPage = sharedData.report.pages[desiredPageIndex];
            sharedData.currentPageIndex = desiredPageIndex;
            ExportBuilderDashboardUtilService.snapItemsToGrid(sharedData.currentPage.elements);
            PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_CHANGED);
        }
    }

    function addExecutiveSummaryWidget(isExecSummaryPage = true, widgetBuilderModel = {}) {
        const latestCurrentPage = sharedData.currentPage;
        if (
            isExecSummaryPage &&
          !_.isEmpty(widgetBuilderModel)
        ) {
            WidgetFactory.save(widgetBuilderModel).then((newWidget) => {
              PubSub.emit($WidgetBuilderEvents.ADD_WIDGET, newWidget);
              addNewItem(
                ReportElementTypes.WIDGET,
                {
                  page_id: latestCurrentPage.id,
                  report_id: latestCurrentPage.report_id,
                },
                newWidget,
                false
              );
            });
        }
    }

}
