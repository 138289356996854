'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.create.services', [])

    .factory('WidgetCreateFactory', WidgetCreateFactory);

/**
 * @constructor
 * @ngInject
 */
function WidgetCreateFactory(
    $timeout,
    $WidgetEvents,
    ScopeFactory,
    DesignFactory,
    WidgetType,
    $DrawOptionPanelEvents,
    $WidgetCreateEvents,
    PubSub,
    WidgetUtilService
) {
    var $createWidgetScope;

    /**
     * Refers to the widget currently being added/edited
     * @type null|{}
     */
    var currentWidget = null;

    /**
     * Refers to the widget that is candidate for being swapped
     * @type {null}
     */
    var swappedWidget = null;

    _registerEvents();

    return {
        getCurrentWidget: getCurrentWidget,
        setCurrentWidget: setCurrentWidget,
        resetCurrentWidget: resetCurrentWidget,
        getSwappedWidget: getSwappedWidget,
        setSwappedWidget: setSwappedWidget,
        hasSwappedWidget: hasSwappedWidget,
        mapSwappedWidgetData: mapSwappedWidgetData,
        resetSwappedWidget: resetSwappedWidget,
        setBaseWidgetModel: setBaseWidgetModel,
        setBaseWidgetMetadata: setBaseWidgetMetadata,
        isActive: isActive,
        $registerScope: $registerScope,
        $getScope: $getScope,
        $initNew: $initNew,
        $initEdit: $initEdit,
        $rebuildWidget: $rebuildWidget,
        $applySortOrder: $applySortOrder,
        $applyMultiSortOrder: $applyMultiSortOrder,
        $applyYAxisPosition: $applyYAxisPosition,
        $updateWidgetDimensions: $updateWidgetDimensions
    };

    function getCurrentWidget() {
        return currentWidget;
    }

    function setCurrentWidget(val) {
        currentWidget = val;
    }

    function resetCurrentWidget() {
        currentWidget = null;
    }

    function getSwappedWidget() {
        return swappedWidget;
    }

    function setSwappedWidget(val) {
        swappedWidget = val;
    }

    function hasSwappedWidget() {
        return !_.isNull(swappedWidget);
    }

    /**
     * @param data
     */
    function mapSwappedWidgetData(data) {
        data.id = swappedWidget.id;
        data.width = swappedWidget.width;
        data.height = swappedWidget.height;
        data.display_order = swappedWidget.display_order;
    }

    function resetSwappedWidget() {
        swappedWidget = null;
    }

    /**
     * Is widget creation mode active?
     * @returns {boolean}
     */
    function isActive() {
        return !_.isNull(currentWidget);
    }

    /**
     * Set base model needed for all widgets
     * @param model
     * @param currentLayout
     */
    function setBaseWidgetModel(model, widgetTypeId) {
        model = model || {};
        model.id = null;
        model.layout_id = DesignFactory.getCurrentLayout().id;
        model.type = widgetTypeId || null;
        model.grouping = null;
        model.display_order = 1;
        model.is_predefined = false;
        setBaseWidgetMetadata(model);

        return model;
    }

    /**
     * Set base King metadata object for all widgets
     * @param model
     */
    function setBaseWidgetMetadata(model) {
        var metadata = model.metadata = {};
        model.title = '';

        var dataSource = metadata.data_source = {};

        // Always add dynamic properties regardless of widget type
        metadata.dynamic = {};
        metadata.dynamic.filters = [];
        metadata.dynamic.filter_set_name = null;
        metadata.dynamic.alerts = [];
        metadata.dynamic.warnings = [];
        metadata.dynamic.errors = [];
        metadata.dynamic.annotations = null;
        metadata.dynamic.predefined_data = null;
        metadata.dynamic.predefined_filters = null;
        metadata.dynamic.is_item_not_found = false;

        dataSource.type = null;
        dataSource.id = null;
        dataSource.data_view = null;
        dataSource.color = '#3498db';
        dataSource.name = null;

        dataSource.requires_group_by = false;
        dataSource.requires_date_range = false;
        dataSource.is_of_type_service = false;
        dataSource.include_num_entities = false;
        dataSource.include_data_freshness_date = false;
        dataSource.requires_group_by = false;
        dataSource.primary_date_field = 'log_date';
        dataSource.include_num_entities = false;
        dataSource.id_name = null;
    }

    /**
     * Based on widget type and amount of data we will determine a suitable widget size
     * @param widget
     * @returns {string}
     */
    function resolveWidgetDimensions() {
        // Need to give a time buffer to change widget type when rebuilding
        $timeout(function() {
            var widget = getCurrentWidget();
            var numColumns = !_.isUndefined(widget.metadata.data_columns)
                ? widget.metadata.data_columns.selected.length
                : 1;
            var widgetType = widget.type;

            // Always create widget in full width initially
            widget.width = WidgetUtilService.maxWidgetWidth;

            switch (widgetType) {
                case WidgetType.ACCOUNTMANAGER:
                case WidgetType.DATAGRID:
                    widget.height = 7;
                    break;

                case WidgetType.GOAL:
                    widget.height = 3;
                    break;

                case WidgetType.BIGNUMBER:
                    // Grow height for every full row of numbers
                    var maxInRow = 6;
                    widget.height = numColumns >= maxInRow ? 3 + Math.floor(numColumns / maxInRow) : 3;
                    break;

                case WidgetType.BARCHART:
                case WidgetType.LINECHART:
                case WidgetType.COMBINATIONCHART:
                    // Grow for every 2 column added
                    widget.height = WidgetUtilService.maxWidgetHeight / 2;
                    break;

                case WidgetType.PIECHART:
                case WidgetType.FUNNELCHART:
                    // Grow for every 2 column added
                    widget.height = 4 + Math.ceil(numColumns / 2);
                    break;

                case WidgetType.GEOCHART:
                    widget.height = WidgetUtilService.maxWidgetHeight / 2;
                    break;

                case WidgetType.MEDIA:
                    widget.height = WidgetUtilService.maxWidgetHeight / 2;
                    break;

                case null:
                    widget.height = WidgetUtilService.maxWidgetHeight / 2;
                    widget.width = WidgetUtilService.maxWidgetWidth;
                    break;

                default:
                    // Default to min width and height
                    widget.height = WidgetUtilService.maxWidgetHeight / 2;
            }

            if (widget.height > WidgetUtilService.maxWidgetHeight) {
                widget.height = WidgetUtilService.maxWidgetHeight;
            }

            // Update the computed width/height (i.e. px and % value)
            widget.computedWidth = WidgetUtilService.getWidgetWidth(widget.width);
            widget.computedHeight = WidgetUtilService.getWidgetHeight(widget.height);
        }, 0);
    }

    /**
     * Local implementation of $registerScope
     * @param scope
     */
    function $registerScope(scope) {
        $createWidgetScope = ScopeFactory.$registerScope(scope, 'create-widget-modal');
    }

    /**
     * Returns the current target scope
     * @returns {*}
     */
    function $getScope() {
        return $createWidgetScope;
    }

    /**
     * Returns the preview widget scope
     * @returns {*}
     */
    function $getPreviewWidgetScope() {
        var $previewWidgetScope = ScopeFactory.$getScope('widget-preview');
        if (_.isUndefined($previewWidgetScope)) {
            debugger;
        }
        return $previewWidgetScope;
    }

    /**
     * When in edit mode, $rebuildWidget will always rebuild the preview widget
     * @param id
     */
    function $rebuildWidget() {
        // On rebuild always require a little buffer
        $timeout(function() {
            $getPreviewWidgetScope().$broadcast($WidgetEvents.WIDGET_REBUILD);
        }, 0, false);
    }

    /**
     * When in edit mode, $applySortOrder will apply sort for selected column and rebuild the preview widget
     * @param column
     */
    function $applySortOrder(column) {
        // On rebuild always require a little buffer
        $timeout(function() {
            $getPreviewWidgetScope().$broadcast('widget:setOrder', column, false);
        }, 0, false);
    }

    /**
     * When in edit mode, $applyMultiSortOrder will apply multi sort for selected column and rebuild the preview widget
     * @param column
     */
    function $applyMultiSortOrder(column) {
        // On rebuild always require a little buffer
        $timeout(function() {
            $getPreviewWidgetScope().$broadcast('widget:setOrder', column, true);
        }, 0, false);
    }

    /**
     * When in edit mode, $applyYAxisPosition will apply y-axis position for selected column and rebuild the preview widget
     * @param column
     */
    function $applyYAxisPosition(column) {
        $timeout(function() {
            $getPreviewWidgetScope().$broadcast('widget:setYAxisPosition', column);
        }, 0, false);
    }

    /**
     * Proxy for creating a new widget
     * @param state
     * @param typeGrouping
     */
    function $initNew(state, typeGrouping) {
        angular.element('#create-widget-modal').modal('show');
        $createWidgetScope.initNew(state, typeGrouping);
    }

    /**
     * Proxy for editing a widget
     * @param state
     * @param widget
     */
    function $initEdit(state, widget) {
        angular.element('#create-widget-modal').modal('show');
        $createWidgetScope.initEdit(state, widget);
    }

    /**
     * Proxy for handling widget column removal
     */
    function $updateWidgetDimensions() {
        resolveWidgetDimensions();
    }

    /**
     * Set new draw options to widget in current layout
     * @param widgetId
     * @param drawOptions
     */
    function _updateWidgetDrawOptions(widgetId, drawOptions) {
        currentWidget.metadata.draw_options = drawOptions;
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetCreateEvents.INIT_CREATE, function (typeGrouping) {
            $initNew(typeGrouping);
        });
        PubSub.on($DrawOptionPanelEvents.EDIT_WIDGET_DRAW_OPTIONS, function (data) {
            _updateWidgetDrawOptions(data.widgetId, data.drawOptions);
        });
    }
}