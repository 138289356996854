'use strict';
import angular from 'angular';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';

import widgetHeaderModalHtmlUrl from './widget.header.modal.html';
import { WidgetHeader } from "../design.constants";

angular.module('widget.header.modal.directive', [])
    .component('widgetHeaderModal', {
        templateUrl: widgetHeaderModalHtmlUrl,
        controller: widgetHeaderModalController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function widgetHeaderModalController(
    $element,
    $scope,
    WidgetHeaderModalFactory,
    gettextCatalog,
    UIFactory,
    WidgetType
) {
    var vm = this;
    vm.widgetHeaderLength = WidgetHeader.CHAR_LENGTH;
    vm.textOptions = {
        minHeight: '100%',
        focus: true,
        dialogsFade: true,
        dialogsInBody: true,
        disableResizeEditor: true,
        placeholder: gettextCatalog.getString('Please enter text in order to edit Widget Header'),
        toolbar: [
            ['headline', ['style']],
            ['fontface', ['fontname']],
            ['textsize', ['fontsize']],
            ['fontclr', ['color']],
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['alignment', ['paragraph', 'lineheight']],
            ['height', ['height']],
            ['view', ['fullscreen', 'codeview']],
            ['edit', ['undo', 'redo']],
            ['help', ['help']]
        ],
        styleTags: ['p','h1','h2','h3','h4','h5','h6'],
        maximumImageFileSize: 5242880,
        callbacks: {
            onImageUploadError: function () {
                UIFactory.notify.showError(gettextCatalog.getString('Image is too large'));
            },
            onKeydown: function (event) {
                if (event.keyCode === 13) return event.preventDefault();
            },
            onPaste: function (event) {
                let pastedText = ((event.originalEvent || event).clipboardData || window.clipboardData).getData('Text');
                let $editable = angular.element("#widget-header-modal-summernote");
                event.preventDefault();
                pastedText = pastedText.replaceAll("\n", "");
                $editable.summernote('insertText', pastedText);
            },
        },
        codeviewFilter: true,
        maxTextLength: WidgetHeader.CHAR_LENGTH,
        fontNames: ["Arial","Arial Black","Comic Sans MS","Courier New","Helvetica Neue","Helvetica","Impact","Lato","Lucida Grande","Tahoma","Times New Roman","Verdana"],
        fontNamesIgnoreCheck: ["Arial","Arial Black","Comic Sans MS","Courier New","Helvetica Neue","Helvetica","Impact","Lato","Lucida Grande","Tahoma","Times New Roman","Verdana"]
    };

    vm.onChangeCallback = function() {};

    vm.shared = WidgetHeaderModalFactory.shared;
    vm.fnCanSave = fnCanSave;
    vm.canShowWarningMessage = canShowWarningMessage;
    vm.removeNewLines = removeNewLines;
    vm.checkISNUI = checkISNUI;

    registerEvents();

    function registerEvents() {
        $element.on('show.bs.modal', onModalShow);
        $element.on('hide.bs.modal', onModalClose);
    }

    function onModalShow() {
        vm.activeWidgetId = WidgetHeaderModalFactory.getActiveWidgetId();
        vm.widget = WidgetHeaderModalFactory.getWidgetData();
        vm.widgetHeader = vm.widget.title;
        disableCodeView();

        let $editable = angular.element("#widget-header-modal-summernote");
        let $codeViewStatus = angular.element(".code-view-help-description");

        //white-space: pre-wrap - helps the issue of the cursor moving to the first position when typing spaces in Firefox.
        angular.element(".note-editable").css('white-space','pre-wrap');
        angular.element(".btn-codeview").on("click", function () {
          if ($editable.summernote("codeview.isActivated")) {
            $codeViewStatus.removeClass("hidden");
          } else {
            $codeViewStatus.addClass("hidden");
          }
        });
    }

    function onModalClose() {
    }

    /**
     * 
     * @returns {string}
     */
    function checkISNUI() {
      return window.isNUI
        ? "message is-warning message-body"
        : "alert alert-warning";
    }
    
    function disableCodeView() {
        let codeViewTextArea = document.getElementById('widget-header-modal').getElementsByClassName('note-codable');
        if (codeViewTextArea.length > 0) {
            codeViewTextArea[0].disabled = true;
        }
    }

    function fnCanSave() {
        return !_.isEmpty(vm.widget)
            && (
                (
                    vm.widget.type !== WidgetType.MEDIA && !_.isEmpty(vm.widgetHeader) && !_.isEmpty(vm.activeWidgetId)
                ) || (vm.widget.type === WidgetType.MEDIA)
            )
            && vm.widgetHeader !== '<p><br></p>'
            && vm.widgetHeader.length <= WidgetHeader.CHAR_LENGTH;
    }

    function canShowWarningMessage() {
        removeNewLines();
        return !_.isEmpty(vm.widgetHeader)
            && vm.widgetHeader.length > WidgetHeader.CHAR_LENGTH;
    }

    function removeNewLines() {
        if (!_.isEmpty(vm.widgetHeader)) {
            const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (!isFirefox) {
                vm.widgetHeader = vm.widgetHeader.replaceAll('<br>', '');
            }
            vm.widgetHeader = vm.widgetHeader.replaceAll('<br/>', '');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<p></p>','');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<h1></h1>','');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<h2></h2>','');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<h3></h3>','');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<h4></h4>','');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<h5></h5>','');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<h6></h6>','');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<div></div>','');
            vm.widgetHeader = vm.widgetHeader.replaceAll('<span></span>','');
        }
    }
}