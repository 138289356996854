export const ClusterType = {
  SINGLE: 'single',
  GROUP: 'group',
};

export const ClusterStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const ClusterRoute = {
  CLUSTERS: 'clusters',
};

export const ClusterPathName = {
  CLUSTERS: 'clusters',
  CLUSTERFORM_NEW: 'clusterform:new',
  CLUSTERFORM: 'clusterform',
};

export const ClusterTitles = [
  'Users',
  'Clients',
  'Client Groups',
  'Data Profiles',
  'Roles',
  'Margin Rules',
  'Report Cover',
  'Scheduled Reports',
  'Business Unit Group',
  'Alerts',
];

export const ClusterUsageNames = [
  'user',
  'client',
  'clientgroup',
  'reportingprofile',
  'role',
  'marginrule',
  'reportingtemplate',
  'scheduledreport',
  'cluster',
  'alert',
];

export const PredefinedFilterName = 'status';
