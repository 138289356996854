'use strict';
import angular from 'angular';

angular.module('core.filterset.services', [])

    .factory('FilterSetFactory', FilterSetFactory);

FilterSetFactory.$inject = ['Restangular'];
function FilterSetFactory(Restangular) {
    var filtersets = Restangular.service('filtersets');

    var save = function(model) {
        return filtersets.all(model.id).post(model);
    };

    return {
        filtersets: filtersets,
        save: save
    }
}