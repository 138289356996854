'use strict';
import angular from 'angular';
import widgetProbeTogglerHtmlUrl from './widget.probe.toggler.html';

angular.module('widget.probe.directives', [])

    .directive('probeToggler', probeToggler);

function probeToggler() {
    return {
        restrict: 'E',
        templateUrl: widgetProbeTogglerHtmlUrl,
        scope: {
            widget: '<',
            widgetId: '<',
        },
        controller: 'ProbeDataController',
        link: function(scope, el) {
            // This is needed since the widget.loaded is one time bound so if override
            // the date range the probe toggler will only hide itself after a refresh
            if (scope.widget.metadata.is_overriding_date_range) {
                el.hide();
            }
        }
    }
}