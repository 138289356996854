export const WorkerManagerDefaultOptions = {
  STATUS_URL: '/jobstatus',
  JOB_COMPLETION: 'job_completion',
  POLL_INTERVAL: [1000, 2000, 5000, 10000, 15000, 30000], // 1, 2, 5, 10, 15, 30 seconds
  MAX_POLL_COUNT: 10,
};

export const WorkerManagerJobStatus = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
