export const ListControlViewType = {
  GALLERY: 'gallery',
  DATATABLE: 'datatable',
  LISTCARDS: 'listcards',
};

export const FilterType = {
  BTW: 'btw',
  GT: 'gt',
  LT: 'lt',
  SINGLE_SELECT: 'single_select',
};

export const PredefinedFilterSlotPrefix = 'predefined_filter_';

export const PredefinedFilterChangeEvent = 'on-predefined-filter-change';
