import moment from 'moment';
import { getters } from '@/modules/core/app/helpers/store';
import { RelativeDateRangeKey } from '@/modules/core/daterange/daterange.constants';

export class BaseDateRangeConfig {
  /**
   * @type {Array.<RelativeDateRange>}
   */
  relativeRanges;

  constructor() {
    this.relativeRanges = {};

    // Additional NON-datarangepicker props
    if (getters.daterange && getters.daterange.getRelativeDateRanges) {
      this.relativeRanges = getters?.daterange?.getRelativeDateRanges();
    }
  }

  /**
   * @param key
   * @returns {{start: moment.Moment, end: moment.Moment}}
   */
  getDateRangeFromRelativeRange(key) {
    let startDate = this.relativeRanges[key].start;
    let endDate = this.relativeRanges[key].end;

    // If DEFAULT, make sure to use current dashboard date (since it may have been updated in the frontend)
    if (key === RelativeDateRangeKey.DEFAULT) {
      let dateRange = {};
      if (getters.daterange && getters.daterange.getRelativeDateRanges) {
        dateRange = getters?.daterange?.getDateRange();
      }

      startDate = dateRange.start_date;
      endDate = dateRange.end_date;
    }

    return {
      key,
      start: moment(startDate),
      end: moment(endDate),
    };
  }
}

export default new BaseDateRangeConfig();
