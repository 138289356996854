import angular from 'angular';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';

import exportBuilderLayersPaneHtmlUrl from './exportbuilder.layers.pane.html';
import exportBuilderLayersPaneContentHtmlUrl from './exportbuilder.layers.pane.content.html';
import exportBuilderLayersElementItemHtmlUrl from './exportbuilder.layers.element.item.html';
import exportBuilderLayersPaneContentItemHtmlUrl from './exportbuilder.layers.pane.content.item.html';

angular.module('exportbuilder.layers.components', [])
    .component('exportBuilderLayersPagePanel', {
        bindings: {
        },
        templateUrl: exportBuilderLayersPaneHtmlUrl,
        controller: ExportBuilderLayersPageController,
        controllerAs: 'vm'
    })
    .component('exportBuilderLayersPageContent', {
        bindings: {
            pageIndex: '<',
            page: '<'
        },
        templateUrl: exportBuilderLayersPaneContentHtmlUrl,
        controller: ExportBuilderLayersPageContentController,
        controllerAs: 'vm'
    })
    .component('exportBuilderLayersElementItem', {
        bindings: {
            item: '='
        },
        templateUrl: exportBuilderLayersElementItemHtmlUrl,
        controller: ExportBuilderLayersElementItemController,
        controllerAs: 'vm'
    })
    .component('exportBuilderLayersPaneContentItem', {
        bindings: {
            item: '='
        },
        templateUrl: exportBuilderLayersPaneContentItemHtmlUrl,
        controller: ExportBuilderLayersPaneContentItemController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderLayersPageController(
    $scope,
    ExportBuilderDashboardService,
    ExportBuilderElementActionService,
    ExportBuilderFacadeUIService,
    ExportBuilderDashboardUIModelFactory,
    ExportBuilderDashboardPageService,
    ExportBuilderPageAssignmentType,
    UIFactory,
    PubSub,
    $ExportBuilderDashboardEvents,
    $ExportBuilderPageAssignmentModalEvents,
    DataSourceFactory,
    AppFactory,
    AppModule
) {
    var vm = this;

    vm.selectedTabIndex = 0;
    vm.builder = null;
    vm.isLoading = false;
    vm.pageRightClick = false;
    vm.uiState = ExportBuilderFacadeUIService.getState();

    vm.reportDrawOptionsProperties = [];
    vm.widgetDrawOptionsProperties = [];
    vm.originalIndeces = [];
    vm.originalElementIndeces = [];

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.isNUI = window.isNUI;
    vm.getDataSourceColor = getDataSourceColor;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getDataSourceName = getDataSourceName;
    vm.getToolTipText = getToolTipText;
    vm.onAssignDataSourceClicked = onAssignDataSourceClicked;
    vm.canShowEnableExecutiveSummaryOption = canShowEnableExecutiveSummaryOption;
    vm.onEnableExecutiveSummaryClicked = onEnableExecutiveSummaryClicked;
    vm.loadingText = '';
    vm.isProductAvaliable = AppFactory.getUser().isModuleAvailable(AppModule.DYNAMIC_LAYOUTS);
    vm.canShowAssignmentOption = false;
    $scope.$watch(() => vm.builder.report.pages.length, (nV, oV) => {
        if (nV !== oV) {
            updatePageNumbers();
        }
    });

    function updatePageNumbers () {
        let counter = 1;
        for (let i = 0; i < vm.builder.report.pages.length; i++ ){
            vm.builder.report.pages[i].number = null;
            if(!vm.builder.report.pages[i].metadata.isHidden) {
                vm.builder.report.pages[i].number = counter;
                counter++;
            }
        }
    }

    /**
     * @type {LayersPageSortOption}
     */
    vm.sortablePageOptions = ExportBuilderDashboardUIModelFactory.getLayersPageSortOptions({
        start: onPageSortStartCallback,
        stop: onPageSortCallback
    });

    /**
     * @type {LayersElementSortOption}
     */
    vm.sortableElementOptions = ExportBuilderDashboardUIModelFactory.getLayersElementSortOptions({
        start: onElementSortStartCallback,
        stop: onSortCallback
    });

    vm.onToggleTab = onToggleTab;
    vm.onPageSortCallback = onPageSortCallback;
    vm.onSortCallback = onSortCallback;
    vm.onPageClicked = onPageClicked;
    vm.onAddPageClickedAtIndex = onAddPageClickedAtIndex;
    vm.unhideAllClicked = unhideAllClicked;
    vm.getPagesLength = getPagesLength;
    vm.setEnableExecutiveSummary = setEnableExecutiveSummary;

    function $onInit() {
        _registerEvents();
        vm.builder = ExportBuilderDashboardService.getBuilder();

        if (!vm.builder.report.can_be_edited) {
            vm.sortablePageOptions.disabled = true;
            vm.sortableElementOptions.disabled = true;
        }

        vm.canShowAssignmentOption = AppFactory.getUser().isModuleAvailable(AppModule.REPORT_STUDIO_PAGE_ASSIGNMENT)
            && ExportBuilderDashboardService.canEditReport();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * @param tabIndex
     */
    function onToggleTab(tabIndex) {
        vm.selectedTabIndex = tabIndex;
    }


    function onPageSortStartCallback() {
        vm.originalIndeces = _.reduce(vm.builder.report.pages, function (accum, page) {
            accum[page.id] = page.page_index;
            return accum;
        }, {});
    }

    function onPageSortCallback() {
        var currentIndex = 0;
        _.each(vm.builder.report.pages, function (page) {
            page.page_index = currentIndex++;
        });
        vm.builder.currentPageIndex = vm.builder.currentPage.page_index;
        updatePageNumbers();
        ExportBuilderElementActionService.reorderPagesIndeces(vm.originalIndeces, vm.builder.report.pages);
    }

    function onAssignDataSourceClicked(page) {
        PubSub.emit($ExportBuilderPageAssignmentModalEvents.OPEN, page);
    }

    /**
     * @param page
     * @returns {boolean|*|boolean}
     */
    function canShowEnableExecutiveSummaryOption(page) {
      return (
        page.is_executive_summary_page &&
        !page.executive_summary_enabled &&
        page.metadata.isHidden
      );
    }

    function onEnableExecutiveSummaryClicked(page) {
        ExportBuilderElementActionService.setEnableExecutiveSummary(page);
    }

    function getPagesLength() {
        let pageCounter = 0;
        vm.builder.report.pages.forEach((page) => !page.metadata.isHidden && pageCounter++);
        return pageCounter;
    }

    function onElementSortStartCallback() {
        vm.originalElementIndeces = _.reduce(vm.builder.currentPage.elements, function (accum, element) {
            accum[element.id] = element.z_index;
            return accum;
        }, {});
        console.log(vm.originalElementIndeces);
    }

    /**
     * @returns {*}
     */
    function onSortCallback() {
        var currentIndex = vm.builder.currentPage.elements.length - 1;
        _.each(vm.builder.currentPage.elements, function (element) {
            element.z_index = currentIndex--;
        });
        ExportBuilderElementActionService.reorderItemsZIndeces(vm.originalElementIndeces, vm.builder.currentPage.elements);
    }

    /**
     * @param index
     */
    function createNewPageEvent(index) {
        ExportBuilderElementActionService.setAddPageAtIndex(index);
    }

    function copyPageEvent(pageId) {
        ExportBuilderElementActionService.setDuplicatePage(pageId);
    }

    function deletePageEvent(pageIndex) {
        UIFactory.confirmDelete({
            showCancelButton: true,
            confirmFn: function(isConfirm) {
                if (isConfirm) {
                    try {
                        ExportBuilderElementActionService.setDeletePageAtIndex(pageIndex);
                    } catch (e) {
                        UIFactory.notify.showWarning(e.message);
                        vm.isLoading = false;
                    }
                }
            }
        });
    }

    function getDataSourceColor(metadata) {
        if (metadata.assignment_type === ExportBuilderPageAssignmentType.PRODUCT && vm.isProductAvaliable) {
            return DataSourceFactory.getDataSourceColor(metadata.assigned_values);
        } else if (metadata.assignment_type === ExportBuilderPageAssignmentType.DATA_CATEGORY) {
            return DataSourceFactory.getDataSourceColor(metadata.assigned_values[0]);
        }

    }

    function getDataSourceIcon(metadata) {
        if (metadata.assignment_type === ExportBuilderPageAssignmentType.PRODUCT && vm.isProductAvaliable) {
            return DataSourceFactory.getDataSourceIcon(metadata.assigned_values);
        } else if (metadata.assignment_type === ExportBuilderPageAssignmentType.DATA_CATEGORY) {
            return DataSourceFactory.getDataSourceIcon(metadata.assigned_values[0]);
        }
    }

    function getDataSourceName(metadata) {
        if (metadata.assignment_type === ExportBuilderPageAssignmentType.PRODUCT && vm.isProductAvaliable) {
            return DataSourceFactory.getDataSourceName(metadata.assigned_values);
        } else if (metadata.assignment_type === ExportBuilderPageAssignmentType.DATA_CATEGORY) {
            return DataSourceFactory.getDataSourceName(metadata.assigned_values[0]);
        }
    }

    function getToolTipText(metadata) {
        return 'Page assigned to ' + getDataSourceName(metadata);
    }


    function onPageClicked(page) {
        if (vm.sortablePageOptions.isSorting) {return}

        // If page already selected, go to elements panel
        if (vm.builder.currentPage.id == page.id
            && vm.builder.report.can_be_edited) {
            vm.selectedTabIndex = 1;
        } else {
            ExportBuilderDashboardService.goToPageIndex(parseInt(page.page_index));
        }
    }

    function onAddPageClickedAtIndex(index) {
        vm.isLoading = true;
        ExportBuilderDashboardService.createNewPageAtIndex(index).finally(function () {
            vm.isLoading = false;
        });
    }

    function unhideAllClicked() {
        ExportBuilderDashboardService.unHideAll()
    }

    function makeCoverPage(page) {
        try {
            page.is_cover_page
                ? ExportBuilderElementActionService.unsetPageAsCoverPage(page)
                : ExportBuilderElementActionService.setPageAsCoverPage(page);
        } catch (e) {
            UIFactory.notify.showWarning(e.message);
        }
    }

    function makeBackPage(page) {
        try {
            page.is_back_page
                ? ExportBuilderElementActionService.unsetPageAsBackPage(page)
                : ExportBuilderElementActionService.setPageAsBackPage(page);
        } catch (e) {
            UIFactory.notify.showWarning(e.message);
        }
    }

    /**
     * @param page
     */
    function setEnableExecutiveSummary(page) {
        try {
            page.executive_summary_enabled
                ? ExportBuilderElementActionService.unsetEnableExecutiveSummary(page)
                : ExportBuilderElementActionService.setEnableExecutiveSummary(page);
        } catch (e) {
            UIFactory.notify.showWarning(e.message);
        }
    }

    function onPageAdding(isAdding) {
        vm.isLoading = isAdding;
    }

    function onAssignDataSource(isAdding) {
        if (!isAdding)
            vm.builder.report.pages = ExportBuilderDashboardService.getBuilder().report.pages;
        vm.isLoading = isAdding;
    }

    function setLoadingText(text) {
            vm.loadingText = text ? 'Loading Pages For' + text : '...';
            vm.isLoading = true;
            ExportBuilderDashboardService.updateAssignmentOptions();
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.CREATE_PAGE, createNewPageEvent);
        PubSub.on($ExportBuilderDashboardEvents.COPY_PAGE, copyPageEvent);
        PubSub.on($ExportBuilderDashboardEvents.DELETE_PAGE, deletePageEvent);
        PubSub.on($ExportBuilderDashboardEvents.MAKE_COVER_PAGE, makeCoverPage);
        PubSub.on($ExportBuilderDashboardEvents.MAKE_BACK_PAGE, makeBackPage);
        PubSub.on($ExportBuilderDashboardEvents.ON_PAGE_ADDING, onPageAdding);
        PubSub.on($ExportBuilderDashboardEvents.ON_PAGE_ASSIGNMENT, onAssignDataSource);
        PubSub.on($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT, setLoadingText);
        PubSub.on($ExportBuilderDashboardEvents.SET_ENABLE_EXECUTIVE_SUMMARY, setEnableExecutiveSummary);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.CREATE_PAGE, createNewPageEvent);
        PubSub.off($ExportBuilderDashboardEvents.COPY_PAGE, copyPageEvent);
        PubSub.off($ExportBuilderDashboardEvents.DELETE_PAGE, deletePageEvent);
        PubSub.off($ExportBuilderDashboardEvents.MAKE_COVER_PAGE, makeCoverPage);
        PubSub.off($ExportBuilderDashboardEvents.MAKE_BACK_PAGE, makeBackPage);
        PubSub.off($ExportBuilderDashboardEvents.ON_PAGE_ADDING, onPageAdding);
        PubSub.off($ExportBuilderDashboardEvents.ON_PAGE_ASSIGNMENT, onAssignDataSource);
        PubSub.off($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT, setLoadingText);
        PubSub.off($ExportBuilderDashboardEvents.SET_ENABLE_EXECUTIVE_SUMMARY, setEnableExecutiveSummary);
    }
}

/**
 * @ngInject
 */
function ExportBuilderLayersPageContentController(
    PubSub,
    $scope,
    $element,
    $timeout,
    ExportBuilderDashboardUIModelFactory,
    $ExportBuilderDashboardEvents,
    ReportStudioTemplateDataService,
    gettextCatalog
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.showBanner = showBanner;
    vm.getBannerText = getBannerText;
    vm.getElementIcon = getElementIcon;
    vm.getElementName = getElementName;
    vm.onPageRightClick = onPageRightClick;

    function $onInit() {
    }

    function $onDestroy() {

    }

    function showBanner() {
        return vm.page.is_cover_page || vm.page.is_back_page;
    }

    function getBannerText() {
        return vm.page.is_cover_page ? gettextCatalog.getString('Cover') : gettextCatalog.getString('Back');
    }

    function getElementIcon(item) {
        return ExportBuilderDashboardUIModelFactory.getFullElementViewModel(item).icon;
    }

    function getElementName(item) {
        return ExportBuilderDashboardUIModelFactory.getFullElementViewModel(item).name;
    }

    function onPageRightClick(position) {
        if (!ReportStudioTemplateDataService.getReport().can_be_edited) {
            return;
        }
        
        PubSub.emit($ExportBuilderDashboardEvents.RIGHT_CLICK_MENU, {
            position: position,
            type: 'page_preview',
            model: vm.page
        });
    }
}

/**
 * @ngInject
 */
function ExportBuilderLayersElementItemController(
    WidgetFactory,
    ReportElementTypes,
    DrawOptionPanelFactory,
    ExportBuilderDashboardService,
    ExportBuilderDashboardUIModelFactory,
    $ExportBuilderDashboardEvents,
    ReportElementTextDesignOptions,
    PubSub,
    gettextCatalog
) {
    var vm = this;

    //TODO: create model
    vm.element = {};

    vm.$onInit = $onInit;
    vm.getElementTitle = getElementTitle;
    vm.toggleFocus = toggleFocus;
    vm.removeItem = removeItem;
    vm.onHiddenClicked = onHiddenClicked;
    vm.onExportElementListItemRightClicked = onExportElementListItemRightClicked;
    vm.isNUI = window.isNUI;

    function $onInit() {
        vm.element = ExportBuilderDashboardUIModelFactory.getFullElementViewModel(vm.item);
    }

    function getElementTitle() {
        if (vm.item.isTypeText()) {
            var textIsNotDefault = vm.item.metadata.design_options.text !== ReportElementTextDesignOptions.TEXT_EMPTY_PLACEHOLDER;
            if (textIsNotDefault) {
                return vm.item.metadata.design_options.text;
            }
        }
        return WidgetFactory.getWidgetPlainTitle(vm.element.title);
    }

    function toggleFocus($event) {
        ExportBuilderDashboardService.focusOnItem(vm.item);
        //ensure the event does not bubble to background
        $event.stopPropagation();
    }

    function removeItem() {
        swal({
                title: gettextCatalog.getString("Are you sure?"),
                text: gettextCatalog.getString('You will not be able to undo this action!'),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d9534f",
                confirmButtonText: gettextCatalog.getString('Yes, delete it!'),
                closeOnConfirm: true
            },
            function(isConfirm){
                if (isConfirm) {
                    ExportBuilderDashboardService.removeItem(vm.item);
                }
            });
    }

    function onHiddenClicked($event) {
        $event.stopPropagation();

        ExportBuilderDashboardService.toggleItemHidden(vm.item);
    }

    function onExportElementListItemRightClicked(position) {
        PubSub.emit($ExportBuilderDashboardEvents.RIGHT_CLICK_MENU, {position: position, type: 'element', model: vm.item});
    }

}

/**
 * @ngInject
 */
function ExportBuilderLayersPaneContentItemController(
  $timeout,
  ExportBuilderDashboardUIModelFactory,
  PubSub,
  $ExportBuilderDashboardEvents,
  $ExportBuilderDashboardModelEvents
) {
    let vm = this;
    vm.icon;
    vm.name;
    vm.refresh = false;
    
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
  
    function $onInit() {
        PubSub.on($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED + vm.item.id, _updateEvents);
        PubSub.on($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + vm.item.id, _refresh);
  
      _update();
    }
    
    function $onDestroy() {
        PubSub.off($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED + vm.item.id, _updateEvents);
        PubSub.off($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + vm.item.id, _refresh);
    }
    
    function _updateEvents() {
        PubSub.off($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + vm.item.id, _refresh);
        PubSub.on($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + vm.item.id, _refresh);
    }
  
    function _refresh(updatedItem) {
        if (!_shouldUpdate(updatedItem)) {
            return;
        }
        
        vm.refresh = true;
        $timeout(() => {
            vm.refresh = false;
            _update();
        }, 0);
    }
    
    function _update() {
        vm.icon = ExportBuilderDashboardUIModelFactory.getFullElementViewModel(vm.item).icon;
        vm.text = ExportBuilderDashboardUIModelFactory.getFullElementViewModel(vm.item).name;
    }
  
  /**
   * Checks if updating UI is actually required. Only valid for widgets for now.
   *
   * @param updatedItem
   * @returns {boolean}
   * @private
   */
    function _shouldUpdate(updatedItem) {
        if (!updatedItem || !updatedItem.widget) {
            return false;
        }
        
        if (updatedItem.widget.type !== vm.item.widget.type) {
            return false;
        }
        
        return true;
    }
}