'use strict';
import angular from 'angular';
import $ from 'jquery';
import './tag.values.constants';
import './tag.values.ctrls';
import './tag.values.services';
import './tag.values.resources';
import './tag.values.components';
import './details/tag.values.details.module';

import tagValuesHtmlUrl from './tag.values.html';

angular.module('core.tag.values', [
    'core.tag.values.constants',
    'core.tag.values.ctrls',
    'core.tag.values.services',
    'core.tag.values.resources',
    'core.tag.values.components',

    'tag.values.details'
])
    .config(['$stateProvider', 'AppConfig', 'TagValuesRouteParams', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, TagValuesRouteParams, SwitchAppServiceProvider) {
        $stateProvider
            .state(TagValuesRouteParams.STATE_NAME, {
                parent:'layout',
                url: TagValuesRouteParams.URL,
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn(TagValuesRouteParams.STATE_NAME),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_TAG_VALUES;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: tagValuesHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            });
    }]);