import { Graphics, Tooltip } from '@amcharts/amcharts5';
import { color } from '@/modules/core/charts/am5/charts.helper';
import {
  ButtonConstant,
  Constant,
  PointerOrientation,
} from '@/modules/core/charts/am5/charts.constants';

export function useCustomZoomOutButton(context) {
  const { root, chart, isDarkTheme, config } = context();

  function changeZoomOutButton() {
    const { zoomOutButton } = chart.value;

    /* Customizing zoom out button */
    zoomOutButton.setAll({
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8,
      tooltipText: ButtonConstant.RESET_VIEW,
      forceHidden: config.value.isSparkLine,
      icon: Graphics.new(root.value, {
        stroke: isDarkTheme.value ? color(0xaaaaaa) : color(0x777777),
        strokeWidth: 2,
        /* eslint-disable tap/no-raw-text-js */
        svgPath:
          'M8 3V6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8H3M21 8H18C17.4696 8 16.9609 7.78929 16.5858 7.41421C16.2107 7.03914 16 6.53043 16 6V3M16 21V18C16 17.4696 16.2107 16.9609 16.5858 16.5858C16.9609 16.2107 17.4696 16 18 16H21M3 16H6C6.53043 16 7.03914 16.2107 7.41421 16.5858C7.78929 16.9609 8 17.4696 8 18V21',
        /* eslint-disable tap/no-raw-text-js */
      }),
    });
    zoomOutButton.get(Constant.BACKGROUND).setAll({
      strokeWidth: 1,
      stroke: color(ButtonConstant.COLOR),
      fill: isDarkTheme.value ? color(0x000000) : color(0xffffff),
      fillOpacity: 0.6,
    });

    /* Adding tooltip on button */
    const zoomOutButtonTooltip = Tooltip.new(root.value, {
      dy: 16,
      pointerOrientation: PointerOrientation.DEFAULT,
    });
    zoomOutButtonTooltip.get(Constant.BACKGROUND).setAll({
      fill: isDarkTheme.value ? color(0xffffff) : color(0x000000),
      fillOpacity: 1,
    });
    zoomOutButton.set(Constant.TOOLTIP, zoomOutButtonTooltip);
  }

  return {
    changeZoomOutButton,
  };
}
