class WidgetContextFactory {
  /**
   * @var {WidgetContext}
   */
  widgetContext;

  /**
   * @param context
   */
  setContext(context) {
    this.widgetContext = context;
  }

  /**
   * @returns {WidgetContext}
   */
  getContext() {
    return this.widgetContext;
  }
}

export default new WidgetContextFactory();
