'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import { AppModule, InstanceStatus } from 'coreModules/shared/scripts/app.constants';

angular.module('core.user.services', [])
    .factory('UserFactory', UserFactory)
    .factory('UserLoginFactory', UserLoginFactory);

/**
 * @ngInject
 */
function UserFactory(
    Restangular,
    AppFactory,
    UserColumns,
    ColumnValueType,
    ReportStudioTemplateDataService,
    $rootScope,
    $http
) {
    var users = Restangular.all('users');
    var userImage = Restangular.all('userimages');
    var values = users.one('values');

    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        if(!_.isEmpty(ReportStudioTemplateDataService.getReport())){
            const languageReport = ReportStudioTemplateDataService.getReportLanguage();
            _.assign(queryParams, {lang: languageReport});
        }

        return users.getList(_.extend({
            is_selectable: true,
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        return users.getList(queryParams);
    }

    /**
     * Post image to backend
     * @param userId
     * @returns {*}
     */
    function uploadImage(image, responseHandler, errorHandler) {
        var formData = new FormData();
        formData.append('user_image', image);

        $.ajax({
            "url": userImage.getRequestedUrl(),
            "type": "POST",
            "data": formData,
            "contentType": false,
            "processData": false,
            "success": function (json) {
                if (responseHandler) {
                    responseHandler(json.data);
                }
            }
        }).fail(function(err) {
            if (errorHandler) {
                var error = _.first(JSON.parse(err.responseText).data);
                errorHandler(error);
            }
        });
    }

    /**
     * Delete image url
     * @param imageId
     * @returns {*}
     */
    function deleteImage(imageId) {
        return userImage.all(imageId).remove();
    }

    /**
     * Get upload image url
     * @param userId
     * @returns {*}
     */
    function getUploadUrl() {
        return userImage.getRequestedUrl();
    }
    
    /**
     * Get user image based on user id
     *
     * @param userId
     */
    function getImage(userId) {
        return Restangular.one('userimages', userId).get();
    }

    /**
     * @param queryParams
     * @returns {*}
     */
    function getImpersonationList(queryParams) {
        return users.one('impersonation').get(queryParams);
    }

    /**
     * Get values for a specific column (enum values)
     * @param fields
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }
    
    /**
     * Get values for all possible distinct specific field
     * @param fields
     * @returns {*}
     */
    function getFieldValues(fields) {
        return values.one(ColumnValueType.DISTINCT).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Return user specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.customRenders[UserColumns.ID] = function (data, type, full) {
            var href= '#/users/detail/' + data;
            return '<a href="' + href + '">' + data + '</a>';
        };
        return dtOptions;
    }

    function isOnboarding() {
        return $http.get($rootScope.util.apiUrl('session/isOnboarding'));
    }

    function getOnboardingStep() {
        return $http.get($rootScope.util.mageUrl('session/getOnboardingStep'));
    }

    function updateOnboardingStep() {
        return this.getOnboardingStep().success(function(json) {
            $.core.main.updateOnboarding(json.data);
        });
    }

    function setCurrentUserLanguage(language) {
        return users.all('language').post({report_language: language});
    }


    function updateAppPreference(queryParams) {
        if (!AppFactory.isUserModuleAvailable(AppModule.NUI)) {
            return Promise.resolve();
        }
        
        return users
            .all('updateapppreference')
            .post(queryParams);
    }

    return {
        users: users,
        getColumns: getColumns,
        getFieldValues: getFieldValues,
        getColumnValues: getColumnValues,
        getData: getData,
        uploadImage: uploadImage,
        deleteImage: deleteImage,
        getUploadUrl: getUploadUrl,
        getImage: getImage,
        getImpersonationList: getImpersonationList,
        getDTOptions: getDTOptions,
        isOnboarding: isOnboarding,
        getOnboardingStep: getOnboardingStep,
        updateOnboardingStep: updateOnboardingStep,
        setCurrentUserLanguage: setCurrentUserLanguage,
        updateAppPreference: updateAppPreference
    }
}

/**
 * @ngInject
 */
function UserLoginFactory(
    $rootScope,
    UserModelFactory
) {
    var eventState;
    var intendedState;

    return {
        setUrl: setUrl,
        getUrl: getUrl,
        setIntendedState: setIntendedState,
        getIntendedState: getIntendedState,
        setEventState: setEventState,
        getEventState: getEventState,
        getRedirectRoute: getRedirectRoute,
        getDefaultHomeRoute: getDefaultHomeRoute
    };

    var _url = null;
    function setUrl(value) {
        _url = value
    }

    function getUrl() {
        return _url;
    }

    function setIntendedState(state, params) {
        intendedState = _.isNil(state) ? null : {
            state: state,
            params: params
        };
    }

    function getIntendedState() {
        return intendedState;
    }

    function getDefaultHomeRoute(user, forceHomePage) {
        var intendedState = getIntendedState();
        // billing AND service.manage states take precedence and NEED to be before everything else
        if (user.instanceStatus == InstanceStatus.SUSPENDED_UNPAID || user.instanceStatus == InstanceStatus.SUSPENDED_INACTIVE) {
            return {state: 'billing'};
        }
        else if (user.isOnboarding) {
            return {state: 'service.manage'};
        }

        var homeRoute = UserModelFactory.getHomeRoute();

        var getIOPrefix = function(route) {
            return _.includes(['orders'], route) ? 'io_' : '';
        };


        if (!forceHomePage && intendedState && intendedState.state) {
            return intendedState;
        } else if (user.homePage) {
            if (user.homePage.isInt()) {
                homeRoute.state = 'dash';
                homeRoute.params = {'id': user.homePage};
            }
            else if (user.isIOAuthorized || user.isIORoute) {
                // Home page is order, tasks, or, etc...
                if (user.isIOHomePage) {
                    // We are in ? /client/iotool : /client/analytics
                    homeRoute.state = user.isIORoute ? user.homePage : getIOPrefix(user.homePage) + user.homePage;
                }
                // Home page is data source overview, custom dashboard, or etc...
                else {
                    // We are in ? /client/iotool : /client/analytics
                    homeRoute.state = user.homePage;
                }
            }
            else {
                homeRoute.state = user.homePage;
            }
        }

        homeRoute.params['query_params'] = $rootScope.query_params;

        return homeRoute;
    }

    /**
     * Tracks the changes in application route states
     * @param state
     */
    function setEventState(state) {
        eventState = state;
    }

    /**
     * Getter for application route states
     * @return {*}
     */
    function getEventState() {
        return eventState
    }

    /**
     * Returns the required route to redirect a user.
     * @param user
     * @return {RouteProps}
     */
    function getRedirectRoute(user) {
        var route = UserModelFactory.getRouteProps();

        if (eventState && eventState.fromState.name) { // if user navigated from our app to a page not allowed to see
            route.state = eventState.fromState.name;
            route.params = eventState.fromParams;
        } else { // if the user did not navigate to the page directly (clicked external link)
            route = UserModelFactory.getRouteProps(getDefaultHomeRoute(user));
        }

        return route;
    }
}