'use strict';
import angular from 'angular';
import './serviceclientmapping.constants';
import './base/serviceclientmapping.components';
import './base/serviceclientmapping.models';
import './base/serviceclientmapping.services';
import './base/serviceclientmapping.resources';

import serviceClientMappingHtmlUrl from './base/serviceclientmapping.html';
import serviceClientMappingLogHtmlUrl from './base/serviceclientmapping.log.html';
import serviceClientMappingLogResultsHtmlUrl from './base/serviceclientmapping.log.results.html';

angular.module('core.serviceclientmapping', [
    'serviceclientmapping.constants',
    'serviceclientmapping.components',
    'serviceclientmapping.models',
    'serviceclientmapping.services',
    'serviceclientmapping.resources'
])
    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('serviceclientmapping', {
                parent:'layout',
                url: '/serviceclientmapping',
                resolve: {
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_SERVICE_CLIENT_MAPPING;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: serviceClientMappingHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
            .state('serviceclientmappinglog', {
                parent:'layout',
                url: '/serviceclientmappinglog',
                resolve: {
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_SERVICE_CLIENT_MAPPING_LOG;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: serviceClientMappingLogHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
            .state('serviceclientmappingresult', {
                parent:'layout',
                url: '/serviceclientmappinglog/results/:mapping_id/:id',
                resolve: {
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_SERVICE_CLIENT_MAPPING_LOG_RESULT;
                    },
                    vars: function($stateParams) {
                        return $stateParams;
                    }
                },
                views: {
                    '': {
                        templateUrl: serviceClientMappingLogResultsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);