"use strict";
import $ from 'jquery';

var Core = $.core.services.details = {
    label: null,
    customerId: null,
    serviceId: null,
    categoryId: null,
    metricsData: [],
    brandMappings: {},

    initialize: function () {
        $.getJSON($.fn.apiUrl('branding/mappings'), '', function(result) {
            Core.brandMappings = result.data;
        });
        this.build();
        this.events();
    },
    build: function () {
        //Render service metric template
        this.serviceMetricsOverview();
    },
    events: function () {
    },
    serviceMetricsOverviewBindings: function () {
        $('div#campaign-stats div.stat-item div.selection-button').click(function() {
            Core.getServiceSubMetrics($(this));
        });
    },
    //Steprep stuff
    stepRepClientSelectBinding: function() {
        var steprepUrl = '#/index/details/service/steprep/itemkey/';
        $('#client-select').change(function() {
            Core.setStepRepDashboard(steprepUrl, false)
        });
        Core.setStepRepDashboard(steprepUrl, true);
    },
    setStepRepDashboard: function(steprepUrl, isFirstRun) {
        var $select = $('#client-select');
        if ($select.val() != '') {
            $('#steprep-dashboard-container').show();
            $('#steprep-dashboard-container h3.panel-title span.placeholder').html($select.find('option:selected').text() + ' dashboard');
            if (!isFirstRun) {
                $.core.main.showAjaxMainLoading({ timeout: 0, text: 'Fetching dashboard' });
                window.location.href = $.fn.appUrl(steprepUrl + $select.val());
            }
        }
    },
    //
    // Templating
    //
    serviceMetricsOverview: function () {
        var $container = $('#service-metrics');
        Core.serviceId = $container.data('serviceId');
        Core.categoryId = $container.data('categoryId');
        Core.customerId = $container.data('customerId');
        $.getJSON($.fn.actionUrl('serviceDetails/serviceMetricsOverview'), { customer_id: Core.customerId, service_id: Core.serviceId, category_id: Core.categoryId }, function (data) {
            Core.metricsData = data;
            //Data massaging
            $.each(data, function(i, val) {
                if (val.hasDelta) {
                    var current = val.deltaValues[0];
                    var prior = val.deltaValues[1];
                    val.delta = $.fn.getDeltaPercentage(current, prior);
                    if (current > prior) {
                        val.deltaDirection = 'plus';
                    }
                    else if (current < prior) {
                        val.deltaDirection = 'minus';
                    }
                    else {
                        val.deltaDirection = 'zero';
                    }
                }

                // val.label == 'Avg. Position' should be removed once Avg. Position has a customFormat
                if (val.label === 'Avg. Position') {
                    val.customFormat = 'decimal';
                }

                $container.append($('#service-metrics-template').render(val));

                if (val.deltaDirection == 'zero') {
                    $($('div#campaign-stats div.stat-item div.selection-button')[i]).hide();
                }
            });
            Core.serviceMetricsOverviewBindings();
            $.core.main.tooltip($container);
        });
    },
    getServiceSubMetrics: function ($elm) {
        var currentIndex = $elm.parent().index();
        var currentMetric = Core.metricsData[currentIndex];
        var subMetrics = currentMetric.subMetrics;

        var $container = $('div#campaign-stats');
        $container.find('div.selection-button').removeClass('active');
        $elm.addClass('active');

        var prior = subMetrics[0];
        var current = subMetrics[1];

        var legendData = {};
        legendData.title = currentMetric.label;
        legendData.priorDescription = prior.label;
        legendData.currentDescription = current.label;
        legendData.priorDateFrom = prior.date_from;
        legendData.priorDateTo = prior.date_to;
        legendData.currentDateFrom = current.date_from;
        legendData.currentDateTo = current.date_to;
        $container.find('div.period-legend-holder').html($('#period-legend-template').render(legendData));

        var barChartData = {};
        barChartData.title = currentMetric.label;
        barChartData.previousValue = prior.value == null ? 0 : parseFloat( prior.value);
        barChartData.currentValue = current.value == null ? 0 : parseFloat( current.value);

        var $chart = $container.find('div.chart');
        $.core.chart.buildDualBarChart($chart, barChartData, { width: $chart.width() / 2, height: 100, rotated: true });

        //Since the submetric charts increase page height, we need to update the datatable fixed header
        $.core.datatable.updateFixedHeaders();
    },
    triggerPreview: function (id){
        var $modal = $('#preview-modal');
        $modal.modal('show');
        $.get($.fn.actionUrl('serviceDetails/html_preview?id=' + id)).done(function(html) {
            $modal.find('div.modal-body').html(html);
        });
    }

};