import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { DashboardState } from '@/modules/core/dashboard/store/dashboard.state.js';
import { getters } from '@/modules/core/dashboard/store/dashboard.getters.js';
import { mutations } from '@/modules/core/dashboard/store/dashboard.mutations.js';
import { actions } from '@/modules/core/dashboard/store/dashboard.actions.js';
import { store as layout } from '@/modules/core/dashboard/store/layout';
import { store as dashExport } from '@/modules/ta/dashboard/export/store';
import { store as drilldown } from '@/modules/ta/dashboard/drilldown';

VueXHelper.setStoreModule(Module.DASHBOARD, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new DashboardState(),
  getters,
  mutations,
  actions,
  modules: {
    layout,
    drilldown,
    export: dashExport,
  },
};
