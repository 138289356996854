'use strict';
import angular from 'angular';

angular.module('widget.accountmanager.resources', [])

    .factory('AccountManagerResource', AccountManagerResource);

/**
 * @ngInject
 */
function AccountManagerResource(
    WidgetDataSourceFactory,
    UserFactory,
    ClientFactory
) {
    return {
        getOwner: getOwner,
        getClient: getClient,
        getImage: getImage,
        getData: getData
    };

    /**
     * Returns account owner
     * @param id
     * @param params
     */
    function getOwner(id, params) {
        return UserFactory.users.one(id).get(params).then(function(json) {
            return json.plain();
        });
    }

    /**
     * Returns client for viewAsClient
     * @param id
     * @param params
     */
    function getClient(id, fields) {
        return ClientFactory.clients.one(`${id}`).get({fields: fields}).then(function(json) {
            return json.plain();
        });
    }

    /**
     *
     * @param imageId
     * @returns {*}
     */
    function getImage(imageId) {
        return UserFactory.getImage(imageId);
    }

    /**
     * Returns data for big numbers
     * @param widget
     * @returns {{}}
     */
    function getData(widget) {
        return WidgetDataSourceFactory.getData(widget, {aggregate: true}, null);
    }
}