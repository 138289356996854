'use strict';
import angular from 'angular';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';

import leadsNotesPanelHtmlUrl from './leads.notes.panel.html';

angular.module('core.leads.components')
    .component('leadsNotesPanel', {
        templateUrl: leadsNotesPanelHtmlUrl,
        controllerAs: 'vm',
        controller: LeadsNotesController
    });

function LeadsNotesController(
    $element,
    PubSub,
    LeadsNotesFactory,
    LeadsNotesModelsFactory,
    MessengerFactory,
    MessengerModelFactory,
    SlidePanelFactory,
    $SlidePanelEvents,
    $MessengerEvents
) {
    var vm = this;
    var _$currentRow = null;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.hasNotes = hasNotes;
    vm.isActive = LeadsNotesFactory.getIsActive;

    function $onInit() {
        // messengerOptions is for the messenger panel
        vm.messengerOptions = MessengerModelFactory.getOptions({
            id: 'leads-notes-panel',
            title: 'Leads Notes Panel'
        });

        // IMPORTANT: In order to correctly initialize messenger panel, PubSubs must only
        // be initialized inside of "$MessengerEvents.INIT_PANEL + vm.messengerOptions.id"
        PubSub.on($MessengerEvents.INIT_PANEL + vm.messengerOptions.id, _initPanel);
        $element.appendTo(document.body);
    }

    function $onDestroy() {
        $element.remove();
        PubSub.off($MessengerEvents.INIT_PANEL + vm.messengerOptions.id, _initPanel);
        _closePanel();
    }

    function hasNotes() {
        return MessengerFactory.hasThreads();
    }

    /**
     * @param options
     * @private
     */
    function _initPanel(options) {
        SlidePanelFactory.closeAll(vm.messengerOptions.id);
        var lead = options.data;
        _$currentRow = options.$sourceEl;
        LeadsNotesFactory.initLeadNotes(lead);
        vm.messengerOptions.title = LeadsNotesFactory.getPanelTitle(lead);
        vm.threadOptions = LeadsNotesModelsFactory.getLeadNote({
            lead_id: lead.lead_id
        });
        _registerEvents();
    }

    /**
     * @param thread
     * @returns {angular.IPromise<any>}
     * @private
     */
    function _onAddLeadNote(thread) {
        thread.lead_id = LeadsNotesFactory.getLeadId();
        thread = LeadsNotesModelsFactory.getLeadNote(thread);
        return LeadsNotesFactory.addLeadNote(thread).then(function() {
            _updateNoteCountDisplay();
            PubSub.emit($MessengerEvents.SAVE_THREAD_CALLBACK, thread);
        });
    }

    /**
     * @param id
     * @returns {angular.IPromise<any>}
     * @private
     */
    function _onDeleteLeadNote(id) {
        return LeadsNotesFactory.deleteLeadNote(id).then(function() {
            _updateNoteCountDisplay();
            PubSub.emit($MessengerEvents.DELETE_THREAD_CALLBACK);
        }, function() {
            swal.enableButtons();
        });
    }

    /**
     * @private
     */
    function _updateNoteCountDisplay() {
        var count = MessengerFactory.getThreadCount();
        if (_$currentRow) {
            var $icon = _$currentRow.find('i.icon');
            var $text = _$currentRow.find('span');
            if (count === 0) {
                $icon.removeClass('icon-sticky-note').addClass('icon-sticky-note-o');
            } else {
                $icon.removeClass('icon-sticky-note-o').addClass('icon-sticky-note');
            }
            $text.html(count);
            _enableHistoryActionButton();
        }
    }

    /**
     * Helper function to enable history action button
     * @private
     */
    function _enableHistoryActionButton() {
        if (_.isNil(_$currentRow)) {
            return;
        }

        var _$actionCell = _$currentRow.closest('.note_count').siblings('.action-cell');
        if (_$actionCell.length) {
            _$actionCell.find('.history-action').attr('disabled', false);
        }
    }

    /**
     * @private
     */
    function _closePanel() {
        LeadsNotesFactory.setInactive();
        _unregisterEvents();
    }

    /**
     *
     * @param sourcePanel
     * @private
     */
    function _closeAll(sourcePanel) {
        if (sourcePanel !== vm.messengerOptions.id) {
            _closePanel();
        }
    }

    function _registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _closeAll);
        PubSub.on($MessengerEvents.SAVE_THREAD, _onAddLeadNote);
        PubSub.on($MessengerEvents.DELETE_THREAD, _onDeleteLeadNote);
    }

    function _unregisterEvents() {
        PubSub.off($SlidePanelEvents.CLOSE_ALL, _closeAll);
        PubSub.off($MessengerEvents.SAVE_THREAD, _onAddLeadNote);
        PubSub.off($MessengerEvents.DELETE_THREAD, _onDeleteLeadNote);
    }
}