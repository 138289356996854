import { find } from 'lodash';
import { memoize } from '@/modules/core/app/utils/ArrayUtil';

export class BaseForm {
  /**
   * @var {array}
   */
  data;

  /**
   * @var {object}
   */
  columns;

  constructor({ data, columns }) {
    this.data = data || [];
    this.columns = memoize(columns || {}, 'field');
    this.undo = false;
    this._mapColumnValuesToModel();
    this.preProcess();
  }

  /**
   * @returns {Array}
   */
  getFormData() {
    return this.data;
  }

  /**
   * @returns {string}
   */
  getSuccessMessage() {
    return i18n.t(`{option} successfully saved!`, { option: this.getPrimaryNameValue() });
  }

  /**
   * @returns {boolean}
   */
  isNew() {
    return !this.getPrimaryKeyValue();
  }

  /**
   * @returns {string}
   */
  getPrimaryKeyValue() {
    const primaryColumn = find(this.columns, { is_primary_key: true });
    return primaryColumn ? this.data[primaryColumn.field] : null;
  }

  /**
   * @returns {string}
   */
  getPrimaryNameValue() {
    return this.data[find(this.columns, { is_primary_name_field: true }).field];
  }

  /**
   * To be implemented by sub-class
   * @protected
   * { column_field: Model }
   * @returns {{}}
   */
  columnValueModels() {
    return {};
  }

  /**
   * @returns {boolean}
   */
  canUndo() {
    return !this.isNew() && this.undo;
  }

  /**
   * To be implemented by sub-class if necessary
   */
  preProcess() {}

  /**
   * If the form can perform the undo action
   *
   * @param {boolean} value
   */
  setUndo(value) {
    this.undo = value;
  }

  /**
   * @throws Should throw when data structure is not valid
   */
  validate() {}

  /**
   * Converts columns values to model if specified
   * @private
   */
  _mapColumnValuesToModel() {
    const valueModels = this.columnValueModels();
    Object.keys(valueModels).forEach((columnField) => {
      this.columns[columnField].values = this.columns[columnField].values.map(
        (value) => new valueModels[columnField](value)
      );
    });
  }
}
