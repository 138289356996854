import { ThemeType } from '../app/constants/app.constants';

/**
 * Define a set of dark theme colors to be used for amCharts when platform dark mode is enabled
 *
 * @param target
 */
export async function applyThemeOnAmCharts(themeType) {
  const core = await import(/* webpackChunkName: "amcharts-core" */ '@amcharts/amcharts4/core');
  const am4themes_darkTheme = (target) => {
    if (target instanceof core.InterfaceColorSet) {
      target.setFor('text', core.color('#ffffff'));
      target.setFor('background', core.color('#181818'));
      target.setFor('grid', core.color('#cccccc'));
      target.setFor('alternativeBackground', core.color('#cccccc'));
      target.setFor('alternativeText', core.color('#000000'));
    }
  };
  if (themeType === ThemeType.DARK) {
    core.useTheme(am4themes_darkTheme);
  } else {
    core.unuseTheme(am4themes_darkTheme);
  }
}
