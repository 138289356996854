'use strict';
import angular from 'angular';

angular.module('tag.values.details.services', [])
    .factory('TagValuesDetailsService', TagValuesDetailsService);

/**
 * @ngInject
 */
function TagValuesDetailsService() {

    var defaultProps = {
        state: {
            isLoading: true
        },
        data: {
            tags: []
        }
    };

    var props = angular.copy(defaultProps);

    return {
    };
}