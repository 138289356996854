import { getters } from '@/modules/core/app/helpers/store';
import { UserType } from '@/modules/ta/user/user.constants';
import { FormSelectOption } from '@/modules/core/form/models/FormSelectOption';
import { deepClone } from '@/modules/core/helper';

export class RoleRestrictedUserTypeSelectOption extends FormSelectOption {
  /**
   * @var {{key, value}}
   */
  userTypes;

  /**
   * @var {boolean}
   */
  canSelectUsersAtAndBelow;

  /**
   * @var {number}
   */
  numberOfAssignedUsers;

  setUserTypes(value) {
    this.userTypes = value;
  }

  constructor(config = {}) {
    super(Object.assign(config, { valueIsObject: false }));
    this.canSelectUsersAtAndBelow = config.canSelectUsersAtAndBelow;
    this.numberOfAssignedUsers = config.numberOfAssignedUsers ?? 0;
  }

  /**
   * @override
   *
   * @returns {(string)[]}
   */
  getData() {
    const { user_type: currentUserType } = getters.session.getUser();

    let values = this._retrieveUserTypesBelowUserType(currentUserType);
    const canSelectUserAtAndBelow =
      this.canSelectUsersAtAndBelow ?? getters.session.canCreateUsersAtAndBelow();
    if (canSelectUserAtAndBelow || this.numberOfAssignedUsers > 0) {
      values[currentUserType] = true;
    }

    values = this._filterOutUserTypes(values);

    return {
      values,
    };
  }

  /**
   * @param {string} userType
   * @returns {Object.<string, null>}
   * @private
   */
  _retrieveUserTypesBelowUserType(userType) {
    switch (userType) {
      case UserType.SUPERADMIN:
        return {
          [UserType.CLUSTERADMIN]: true,
          [UserType.AGENT]: true,
          [UserType.CLIENT]: true,
        };
      case UserType.CLUSTERADMIN:
        return {
          [UserType.AGENT]: true,
          [UserType.CLIENT]: true,
        };
      case UserType.AGENT:
        return {
          [UserType.CLIENT]: true,
        };
      case UserType.CLIENT:
      default:
        return {};
    }
  }

  /**
   * @private
   */
  _filterOutUserTypes(values) {
    const userTypes = deepClone(this.userTypes);
    return userTypes.filter((userType) => values[userType.key]);
  }
}
