'use strict';
import angular from 'angular';
import './tapcolors.constants';
import './base/tapcolors.models';
import './base/tapcolors.directive';
import './base/tapcolors.service';


angular.module('core.tapcolors', [
    'core.tapcolors.constants',
    'core.tapcolors.models',
    'core.tapcolors.directives',
    'core.tapcolors.services'
])

    .config(['TapColorsServiceProvider', function (TapColorsServiceProvider) {
        TapColorsServiceProvider
            .setColors([
                '#FFFFFF',
                '#00294f',
                '#9e9b9b',
                '#4eaaff',
                '#00ffb5',
                '#009630',
                '#00B19B',
                '#1247A6',
                '#6850C3',
                '#B13BA9',
                '#C91814',
                '#F56021',
                '#F5CB5C'
            ])
            .setSize(175)
            .setDismissOnSelection(true)
    }])