import { ExportType } from '@/modules/ta/reportstudio/reportstudio.constants';

export const getters = {
  /**
   * @param {ExportState} state
   * @returns {boolean}
   */
  isExporting(state) {
    return state.isExporting;
  },
  /**
   * @param {ExportState} state
   * @returns {boolean}
   */
  isScheduledExport(state) {
    return state.config.exportType !== ExportType.TYPE_SCHEDULED_REPORT_PDF;
  },
  /**
   * @param {ExportState} state
   * @returns {{}}
   */
  getConfig(state) {
    return state.config;
  },
  /**
   * exports are either used as html in the browser or puppeteer
   * @returns {boolean}
   */
  getIsCapturing({ config }) {
    return !config.isHtml;
  },
};
