'use strict';
import angular from 'angular';
import _ from 'lodash';

import widgetAccountManagerHtmlUrl from './widget.accountmanager.html';

angular.module('widget.accountmanager.components', [])
    .component('accountManagerWidget', {
        templateUrl: widgetAccountManagerHtmlUrl,
        bindings: {
            widget: '<',
            state: '=',
            widgetIsPredefined: '<'
        },
        controllerAs: 'vm',
        controller: AccountManagerWidgetController
    });

/**
 * @ngInject
 */
function AccountManagerWidgetController(
    $scope,
    LoadingState,
    $WidgetEvents,
    AccountManagerModelFactory,
    AccountManagerFactory,
    WidgetType,
    UserType,
    ColumnFormat,
    AppFactory,
    WidgetFactory,
    WidgetCreateTypeFactory,
    PubSub,
    $UserImageUploadEvents,
    DashboardContextService
) {

    var vm = this;

    /**
     *
     * @type {null}
     */
    vm.managerImage = null;

    /**
     *
     * @type {AccountManagerWidget}
     */
    vm.accountManagerWidget = AccountManagerModelFactory.getAccountManagerWidget();
    vm.hasNoData = AccountManagerFactory.hasNoData;

    vm.$onInit = onInit();

    vm.isExportingReportStudio = DashboardContextService.isExportingReportStudio;

    function onInit() {
        // INIT
        registerEvents();
        buildAccountManager();
    }

    /**
     * Build ACCOUNTMANAGER widget
     */
    function buildAccountManager() {
        var isPreview = vm.state && vm.state.isPreview;
        vm.managerImage = null;

        if ((vm.state && vm.state.canSave) || !isPreview) {

            vm.state.loadingState = LoadingState.FETCHING;

            var metadata = vm.widget.metadata || {};
            if (!_.isEmpty(metadata) && !_.isEmpty(metadata.dynamic) && metadata.dynamic.predefined_data) {
                applyData(metadata.dynamic.predefined_data.data, vm.widget);
            } else {
                AccountManagerFactory.getManagerData(metadata.data_columns.selected).then(function(data) {
                    applyData(data, vm.widget);
                });
            }
        }
    }

    function applyData(data, widget) {
        let metadata = widget.metadata;
        var selectedColumns = metadata.data_columns && metadata.data_columns.selected ? metadata.data_columns.selected : {};

        vm.accountManagerWidget = AccountManagerModelFactory.getAccountManagerWidget(widget.id, data.user_image_metadata, data, selectedColumns);

        var connectionType = metadata.admin_source && metadata.admin_source.id;

        var currentUser = AppFactory.getUser();
        var userId = currentUser.isClient() ? currentUser[connectionType] : currentUser.userId;

        if (_.isEmpty(vm.accountManagerWidget.predefinedData)) {
            vm.widget.metadata.noDataAction = AccountManagerFactory.getWarningMessage(userId, metadata);
        }

        vm.state.loadingState = _.isEmpty(data) ? LoadingState.NO_DATA_ACTIONABLE : LoadingState.DONE;
        WidgetFactory.widgetLoaded(widget);
    }

    /**
     * Handles if and how we should rebuild the account manager widget
     */
    function handleRebuild() {
        buildAccountManager();
    }

    var $widgetRebuildFn;
    function registerEvents() {
        // need to pubsub
        PubSub.on($UserImageUploadEvents.IMAGE_CHANGE, _onImageChange);

        $widgetRebuildFn = $scope.$on($WidgetEvents.WIDGET_REBUILD, handleRebuild);

        $scope.$on('$destroy', function () {
            $widgetRebuildFn();
            PubSub.off($UserImageUploadEvents.IMAGE_CHANGE, _onImageChange);
        });
    }

    /**
     * Event trigger when user image upload has new image
     * @param user
     * @private
     */
    function _onImageChange(user) {
        handleRebuild();
    }
}