import { useChart } from '@/modules/core/charts/am5/base/composables/useChart';
import { useXYAxes } from '@/modules/core/charts/am5/base/composables/axis/useXYAxes';
import { useXYSeries } from '@/modules/core/charts/am5/base/composables/series/useXYSeries';
import { useScrollbar } from '@/modules/core/charts/am5/base/composables/useScrollbar';
import { useLegend } from '@/modules/core/charts/am5/base/composables/useLegend';
import { useMetricLabels } from '@/modules/core/charts/am5/bar/composables/useMetricLabels';
import { useBullets } from '@/modules/core/charts/am5/bar/composables/useBullets';
import { useCustomZoomOutButton } from '@/modules/core/charts/am5/base/composables/useCustomZoomOutButton';
import { useXYDrillDown } from '@/modules/core/charts/am5/base/composables/useXYDrillDown';
import { useXYAxesTooltip } from '@/modules/core/charts/am5/base/composables/axis/useXYAxesTooltip';
import { useXYTooltip } from '@/modules/core/charts/am5/base/composables/useXYTooltip';
import { useRadarChart } from '@/modules/core/charts/am5/bar/composables/useRadarChart';
import { useXYChart } from '@/modules/core/charts/am5/base/composables/useXYChart';
import { useRadialAxes } from '@/modules/core/charts/am5/bar/composables/useRadialAxes';
import { useBenchmarkSeries } from '@/modules/core/charts/am5/base/composables/useBenchmarkSeries';

export function useBarChart(context) {
  const { chart, config } = context();

  const { configureChart } = useChart(context);
  const { createAxes } = useXYAxes(context);
  const { createRadialAxes } = useRadialAxes(context);
  const { createAllSeries, applyDataToAllSeries } = useXYSeries(context);
  const { createScrollbar, createScrollbarX } = useScrollbar(context);
  const { createBullets } = useBullets(context);
  const { createTooltip } = useXYTooltip(context);
  const { createLegend } = useLegend(context);
  const { createMetricLabels } = useMetricLabels(context);
  const { changeZoomOutButton } = useCustomZoomOutButton(context);
  const { createDrillDownEffect } = useXYDrillDown(context);
  const { createAxesTooltip } = useXYAxesTooltip(context);
  const { createXYChart, createXYCursor } = useXYChart(context);
  const { createRadarChart, createRadarCursor } = useRadarChart(context);
  const { addBenchmarkSeries } = useBenchmarkSeries(context);

  function initBarChart() {
    const isRadialChart = config.value.isRadialHistogram || config.value.isRadialBar;

    if (isRadialChart) {
      createRadarChart();
      createRadarCursor();
    } else {
      createXYChart();
      createXYCursor();
    }

    configureChart();

    if (isRadialChart) {
      createRadialAxes();
    } else {
      createAxes();
    }

    createAllSeries();
    changeZoomOutButton();

    if (config.value.hasValueScrollbar) {
      config.value.isRadialHistogram ? createScrollbarX() : createScrollbar();
    }

    if (config.value.legend.active) {
      createLegend();
    }

    if (config.value.hasTooltip) {
      createTooltip();
      createAxesTooltip();
    }

    if (config.value.showMetricLabels) {
      createMetricLabels();
    }

    if (config.value.lollipopChart) {
      createBullets();
    }

    applyDataToAllSeries();

    createDrillDownEffect();

    if (config.value.benchmarks?.enabled) {
      addBenchmarkSeries();
    }
  }

  return {
    chart,
    initBarChart,
  };
}
