import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('design.details.models', [])
    .factory('DetailsModalModelFactory', DetailsModalModelFactory);

/**
 * @ngInject
 */
function DetailsModalModelFactory(
    DetailsModalSize
) {

    return {
        getModalOptions: getModalOptions,
        getDisplayModel: getDisplayModel
    };

    function getModalOptions(model) {
        return new DetailsModalOptions(model);
    }

    function getDisplayModel() {
        return new DisplayModel();
    }

    /**
     * Initializer model for DetailsModalService
     * @param model
     * model.dataSourceType REQUIRED
     * model.resource       REQUIRED
     * model.display        REQUIRED
     * @constructor
     */
    function DetailsModalOptions(model) {
        var self = this;

        // required fields
        self.dataSourceType = model.dataSourceType;
        self.resource = model.resource;
        self.display = model.display;

        // optional fields
        self.promises = model.promises || [];
        self.row = model.row || null;
        self.modalSize = model.modalSize || DetailsModalSize.FULL;
        self.index = model.index;
        self.hideRowButtons = _.isUndefined(model.hideRowButtons) ? false: model.hideRowButtons;

        self.getRow = function(table, index) {
            var $tableEl = $(table.table().container());
            var $row = $($tableEl.find('tbody').children()[index]);
            var $editButton = $row.find('[data-field=id]');
            return $editButton.trigger('click');
        };
    }

    /**
     * Required object model to set up display for a detail modal
     * @param model
     * @constructor
     */
    function DisplayModel(model) {
        var self = this;

        self.icon = model.icon || '';
        self.title = model.title || '';
        self.loadingMessage = model.loadingMessage || 'Loading...';
        self.hideTitleIcon = !!model.hideTitleIcon;
    }
}