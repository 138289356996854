import { DashboardFontSize, DefaultChartPalette } from '@/modules/ta/dashboard/dashboard.constants';
import { deepClone } from '@/modules/core/helper';
import { SelectedEntity } from '@/modules/ta/dashboard/models/SelectedEntity';

export class DashboardMetadata {
  /**
   * @var {SelectedEntity}
   */
  selected_entity;

  /**
   * @var {array}
   */
  chart_palette;

  /**
   * @var {string}
   */
  font_size;

  /**
   * @var {boolean}
   */
  append_exported_datagrids;

  /**
   * @var {boolean}
   */
  append_exported_datagrids_to_layout;

  /**
   * @var {boolean}
   */
  hide_empty_exported_widgets;

  /**
   * @var {boolean}
   */
  include_summary_page;

  /**
   * @var {string}
   */
  description;

  /**
   * @var {array}
   */
  dashboard_filters;

  /**
   * @var {boolean}
   */
  disable_dash_filters;

  /**
   * @var {boolean}
   */
  disable_comparison;

  /**
   * @var {array}
   */
  tags;

  /**
   * @var {boolean}
   */
  requires_refresh_on_date_range_change;

  /**
   * @var {boolean}
   */
  enable_demo_mode;

  /**
   * @var {string}
   */
  created_from;

  constructor(model = {}) {
    this.selected_entity = model.selected_entity && new SelectedEntity(model.selected_entity);
    this.chart_palette = model.chart_palette || deepClone(DefaultChartPalette);
    this.font_size = model.font_size || DashboardFontSize[0].value;
    this.append_exported_datagrids = model.append_exported_datagrids;
    this.append_exported_datagrids_to_layout = model.append_exported_datagrids_to_layout;
    this.hide_empty_exported_widgets = model.hide_empty_exported_widgets;
    this.include_summary_page = model.include_summary_page || false;
    this.description = model.description;
    this.dashboard_filters = model.dashboard_filters;
    this.disable_dash_filters = model.disable_dash_filters;
    this.disable_comparison = model.disable_comparison;
    this.tags = model.tags;
    this.requires_refresh_on_date_range_change = model.requires_refresh_on_date_range_change;
    this.enable_demo_mode = !!model.enable_demo_mode; // !! because for old dashboards this field won't be available in metadata
    this.created_from = model.created_from;
  }
}
