import angular from 'angular';
import _ from 'lodash';

angular.module('exportbuilder.page.services', [])
    .factory('ExportBuilderDashboardPageService', ExportBuilderDashboardPageService)
    .factory('ReportPageDesignOptionsFactory', ReportPageDesignOptionsFactory);

/**
 * @ngInject
 */
function ExportBuilderDashboardPageService(
    $q,
    $timeout,
    PubSub,
    ExportBuilderDashboardModelFactory,
    ExportBuilderDashboardConstants,
    ExportBuilderDashboardService,
    ExportBuilderFilterService,
    ExportBuilderPageResource,
    $ExportBuilderDashboardEvents,
    ExportBuilderResource
) {
    return {
        setPageAsCoverPage: setPageAsCoverPage,
        setPageAsBackPage: setPageAsBackPage,
        setEnableExecutiveSummary: setEnableExecutiveSummary,
        unsetPageAsCoverPage: unsetPageAsCoverPage,
        unsetPageAsBackPage: unsetPageAsBackPage,
        unsetEnableExecutiveSummary: unsetEnableExecutiveSummary,
        getPageFromId: getPageFromId,
        getPageAtIndex: getPageAtIndex,

        copyPage: copyPage,

        setTitle: setTitle,
        setBackgroundColor: setBackgroundColor,
        setOpacity: setOpacity,

        delayUpdatePage: delayUpdatePage,

        deleteElements: deleteElements,
        addElements: addElements
    };

    /**
     * Sets page as cover page, un sets if any the other cover page
     * @param page
     * @returns {*}
     */
    function setPageAsCoverPage(page) {
        if (page.page_index > 0) {
            throw new Error('Can only set first page as cover page');
        }
        if (!_.isEmpty(_.find(ExportBuilderDashboardService.getReport().pages, {is_cover_page: true}))) {
            throw new Error('As cover page has already been set');
        }
        var report = ExportBuilderDashboardService.getReport();

        var pagesToUpdate = [];

        page.is_cover_page = true;

        // remove show_on_every_page elements if any
        _.remove(page.elements, {show_on_every_page: true});

        pagesToUpdate.push(page);

        pagesToUpdate = _.map(pagesToUpdate, function (page) {
            return {id: page.id, is_cover_page: page.is_cover_page}
        });

        return ExportBuilderPageResource.batchUpdate(report, pagesToUpdate);
    }

    /**
     * Sets page as back page, un sets if any the other cover page
     * @param page
     * @returns {*}
     */
    function setPageAsBackPage(page) {
        var report = ExportBuilderDashboardService.getReport();

        if (page.page_index != report.pages.length - 1) {
            throw new Exception('Can only set last page as back page');
        }
        if (!_.isEmpty(_.find(ExportBuilderDashboardService.getReport().pages, {is_back_page: true}))) {
            throw new Exception('As back page has already been set');
        }
        var pagesToUpdate = [];

        var coverPage = _.find(report.pages, {is_back_page: true});
        if (coverPage) {
            coverPage.is_back_page = false;
            pagesToUpdate.push(pagesToUpdate);
        }
        page.is_back_page = true;

        // remove show_on_every_page elements if any
        _.remove(page.elements, {show_on_every_page: true});

        pagesToUpdate.push(page);

        pagesToUpdate = _.map(pagesToUpdate, function (page) {
            return {id: page.id, is_back_page: page.is_back_page}
        });

        return ExportBuilderPageResource.batchUpdate(report, pagesToUpdate);
    }

    /**
     * Sets page as executive summary page
     * @param page
     * @returns {*}
     */
    function setEnableExecutiveSummary(page) {
        const report = ExportBuilderDashboardService.getReport();
        let pagesToUpdate = [];
        page.executive_summary_enabled = true;
        page.metadata.isHidden = false;
        pagesToUpdate.push(page);
        pagesToUpdate = _.map(pagesToUpdate, function (page) {
            return {id: page.id, executive_summary_enabled: page.executive_summary_enabled}
        });

        return ExportBuilderPageResource.batchUpdate(report, pagesToUpdate);
    }

    /**
     * @param {ReportPageModel} page
     */
    function unsetPageAsCoverPage(page) {
        if (!page.is_cover_page) {
            return;
        }

        var report = ExportBuilderDashboardService.getReport();
        page.is_cover_page = false;
        page.elements = page.elements.concat(angular.copy(report.elements));

        return ExportBuilderPageResource.batchUpdate(report, [_.pick(page, ['id', 'is_cover_page'])]);
    }

    /**
     * @param {ReportPageModel} page
     */
    function unsetPageAsBackPage(page) {
        if (!page.is_back_page) {
            return;
        }

        var report = ExportBuilderDashboardService.getReport();
        page.is_back_page = false;
        page.elements = page.elements.concat(angular.copy(report.elements));

        return ExportBuilderPageResource.batchUpdate(report, [_.pick(page, ['id', 'is_back_page'])]);
    }

    /**
     * Unsets page as executive summary page
     * @param page
     * @returns {*}
     */
    function unsetEnableExecutiveSummary(page) {
        if (!page.is_executive_summary_page) {
            return;
        }

        const report = ExportBuilderDashboardService.getReport();
        page.executive_summary_enabled = false;
        page.metadata.isHidden = true;
        page.elements = page.elements.concat(angular.copy(report.elements));

        return ExportBuilderPageResource.batchUpdate(report, [_.pick(page, ['id', 'executive_summary_enabled'])]);
    }

    /**
     * Helper function to find a page from its id
     * @param index
     * @returns {*}
     */
    function getPageFromId(pageId) {
        var report = ExportBuilderDashboardService.getReport();
        return _.find(report.pages, function (page) {
            return page.id == pageId; // double equal is wanted here.
        })
    }

    /**
     * Helper function to find a page at a given index
     * @param index
     * @returns {*}
     */
    function getPageAtIndex(index) {
        var report = ExportBuilderDashboardService.getReport();
        return _.find(report.pages, function (page) {
            return page.page_index == index; // double equal is wanted here.
        })
    }

    function copyPage(pageId) {
        var defer = $q.defer();

        var page = getPageFromId(pageId);

        if (!page) {
            defer.resolve();
            return defer.promise;
        }

        ExportBuilderDashboardService.createNewPageAtIndex(parseInt(page.page_index) + 1, true)
            .then(function (newPage) {
                defer.resolve(newPage);
            });

        return defer.promise;
    }

    function setTitle(page, title) {
        var builder = ExportBuilderDashboardService.getBuilder();
        page.title = title;
        delayUpdatePage(builder.report, page);
    }

    function setBackgroundColor(page, color) {
        var builder = ExportBuilderDashboardService.getBuilder();
        page.metadata.design_options.background_color = color;
        delayUpdatePage(builder.report, page);
    }

    function setOpacity(value) {
        var builder = ExportBuilderDashboardService.getBuilder();
        builder.currentPage.metadata.design_options.opacity = value;
        delayUpdatePage(builder.report, builder.currentPage);
    }

    function _updatePage(report, page) {
        ExportBuilderPageResource.update(report, page)
    }

    var debounceUpdate;
    function delayUpdatePage(report, page) {
        if (!debounceUpdate) {
            debounceUpdate = _.debounce(_updatePage, ExportBuilderDashboardConstants.DELAY_REPORT);
        }
        debounceUpdate(report, page);
    }

    function deleteElements(elements) {
        _.each(elements, function (element) {
            element.is_deleted = true;
            element.setUnfocus();
        });

        var elementIds = _.map(elements, function (element) {return element.id});

        _.each(ExportBuilderDashboardService.getReport().pages, function(page) {
            _.remove(page.elements, function (element) {
                return _.includes(elementIds, element.id);
            });
        });
        elements.forEach((element) => {
            if (element.widget && element.widget.metadata.dynamic && element.widget.metadata.dynamic.filters) {
                element.widget.metadata.dynamic.filters.forEach(filter => {
                    ExportBuilderFilterService.removeExposedFilter(element.widget, filter);
                });
            }
        })
        $timeout(() => {
            PubSub.emit($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_APPLY);
        },0);
        var deletePromise = ExportBuilderResource.batchUpdateElements(ExportBuilderDashboardService.getReport(), elements);

        ExportBuilderDashboardService.clearSelection();

        return deletePromise;
    }

    function addElements(elements, page_id) {
        var page = _.isUndefined(page_id) ? ExportBuilderDashboardService.getBuilder().currentPage : getPageFromId(page_id);

        _.each(elements, function (element) {
            element.setUnfocus();
            element.is_deleted = false;
            ExportBuilderDashboardService.addItem(element, page.id, true);
        });
        var promise = ExportBuilderResource.batchUpdateElements(ExportBuilderDashboardService.getReport(), elements);
        return promise;
    }
}

/**
 * @ngInject
 */
function ReportPageDesignOptionsFactory() {

    return {
        getDefaultPageMetadata: getDefaultPageMetadata,
        getDefaultPageDesignOptions: getDefaultPageDesignOptions,
    };

    function getDefaultPageMetadata() {
        return {
            'design_options': getDefaultPageDesignOptions(),
        }
    }

    function getDefaultPageDesignOptions() {
        return {
            'background_color': '#ffffff',
            'opacity': 1
        }
    }
}