import angular from 'angular';

class WorkerManagerService {
  static $inject = [
    'PusherService',
    'PusherConstants',
    'WorkerManagerResource',
    'WorkerManagerConstants'
  ];

  constructor(PusherService, PusherConstants, WorkerManagerResource, WorkerManagerConstants) {
    this.PusherService = PusherService;
    this.PusherConstants = PusherConstants;
    this.WorkerManagerResource = WorkerManagerResource;
    this.WorkerManagerConstants = WorkerManagerConstants;
  }

  /**
   * Use work(url, channel, options) instead
   * Returns a promise that resolves with worker-manager job response
   * @param url
   * @param channel
   * @param params
   * @param retry
   * @param event
   * @returns {Promise}
   */
  async get(url, channel, retry = false, params = {}, event = 'job_completion') {
    const options = { retry, params, event };
    return this.work(url, channel, options);
  }

  /**
   * Returns a promise that resolves with worker-manager job response
   * @param url
   * @param channel
   * @param options
   * @param pollInterval
   * @param pollCount
   * @returns {Promise}
   */
  async work(url, channel, options, pollInterval = this.PusherConstants.PUSHER_POLL_INTERVAL_SHORT, pollCount = this.PusherConstants.PUSHER_MAX_POLL_COUNT) {
    return new Promise((resolve, reject) => {
      const defaults = {
        retry: false,
        method: this.WorkerManagerConstants.GET,
        statusUrl: this.WorkerManagerConstants.STATUS_URL,
        event: this.WorkerManagerConstants.JOB_COMPLETION,
        params: {},
        body: {},
        headers: {}
      };
      const workerOptions = this.mergeOptions(defaults, options);

      let curJobId = null;
      let timeoutCount = 0;
      const requestTimeoutHandler = () => {
        errorHandler(new Error('Request timed out'));
      };
      const timeoutHandler = () => {
        timeoutCount += 1;
        if (timeoutCount < pollCount) {
          this.WorkerManagerResource.get(workerOptions.statusUrl, { jobid: curJobId })
            .then(responseHandler)
            .catch(errorHandler);
        } else {
          requestTimeoutHandler();
        }
      };
      const responseHandler = response => {
        if (response.status === this.PusherConstants.PUSHER_JOB_SUCCESS) {
          resolve(response.output);
        } else if (response.status === this.PusherConstants.PUSHER_JOB_LOADING) {
          curJobId = response.job_id;
          this.PusherService.waitForPusherEvent(
            channel,
            workerOptions.event,
            eventHandler,
            timeoutHandler,
            pollInterval
          );
        } else {
          const err = new Error(response.error.message);
          err.response = response;
          errorHandler(err);
        }
      };
      const errorHandler = error => {
        reject(error);
      };
      const eventHandler = response => {
        if (curJobId === response.jobId) {
          this.WorkerManagerResource.get(workerOptions.statusUrl, { jobid: curJobId })
            .then(responseHandler)
            .catch(errorHandler);
          return true;
        }
        return false;
      };
      this._initialCall(url, workerOptions, responseHandler, errorHandler);
    });
  }

  /**
   * Makes initial worker manager call based in the options given
   * @param url
   * @param workerOptions
   * @param responseHandler
   * @param errorHandler
   * @private
   */
  _initialCall(url, workerOptions, responseHandler, errorHandler) {
    switch (workerOptions.method) {
      case this.WorkerManagerConstants.GET:
        this.WorkerManagerResource.get(
          url,
          { ...workerOptions.params, retry: workerOptions.retry },
          workerOptions.headers
        )
          .then(responseHandler)
          .catch(errorHandler);
        break;
      case this.WorkerManagerConstants.PUT:
        this.WorkerManagerResource.put(
          url,
          { ...workerOptions.params, retry: workerOptions.retry },
          workerOptions.body,
          workerOptions.headers
        )
          .then(responseHandler)
          .catch(errorHandler);
        break;
      case this.WorkerManagerConstants.POST:
        this.WorkerManagerResource.post(
          url,
          { ...workerOptions.params, retry: workerOptions.retry },
          workerOptions.body,
          workerOptions.headers
        )
          .then(responseHandler)
          .catch(errorHandler);
        break;
      case this.WorkerManagerConstants.DELETE:
        this.WorkerManagerResource.delete(
          url,
          { ...workerOptions.params, retry: workerOptions.retry },
          workerOptions.headers
        )
          .then(responseHandler)
          .catch(errorHandler);
        break;
      default:
        errorHandler(new Error('Unknown method ' + workerOptions.method));
        break;
    }
  }

  /**
   * Merges changes into the default options
   * @returns {Object<options>}
   */
  mergeOptions(defaults, changes) {
    for (let key in changes) {
      try {
        if (changes[key].constructor === Object) {
          defaults[key] = this.mergeOptions(defaults[key], changes[key]);
        } else {
          defaults[key] = changes[key];
        }
      } catch (e) {
        defaults[key] = changes[key];
      }
    }
    return defaults;
  }
}

export default angular
  .module('worker.services', [])
  .service('WorkerManagerService', WorkerManagerService);
