'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.resize.services', [])
    .constant('$WidgetResizeEvents', $WidgetResizeEvents())
    .factory('WidgetResizeService', WidgetResizeService);

function $WidgetResizeEvents() {
    return {
        RESIZE: 'WidgetResize:RESIZE'
    };
}

/**
 * @param DesignFactory
 * @param WidgetSize
 * @param WidgetType
 * @param WidgetFactory
 * @param WidgetResizeResourceFactory
 * @param PubSub
 * @constructor
 *
 * @ngInject
 */
function WidgetResizeService(
    $WidgetResizeEvents,
    DataGridFactory,
    DesignFactory,
    WidgetSize,
    WidgetType,
    WidgetFactory,
    WidgetResizeResourceFactory,
    PubSub,
    $PageGenerateThumbnailEvents
) {

    let defaultProps = {
        resizingWidget: null,
        state: {
            isActive: false
        },
        data: {
            originalWidth: null,
            originalHeight: null,
            defaultMinWidth: WidgetSize.MIN_WIDTH,
            defaultMinHeight: WidgetSize.MIN_HEIGHT,
            minWidth: null,
            minHeight: null,
            currentWidgetId: -1,
            currentWidth: -1,
            currentHeight: -1,
            dimensionBoxes: []
        }
    };
    let props = _.cloneDeep(defaultProps);

    return {
        init: init,
        getDimensionBoxes: getDimensionBoxes,
        setResize: setResize,
        resizeWidget: resizeWidget,
        onHover: onHover,
        onHoverOut: onHoverOut,
        getIsActive: getIsActive,

        // Events
        onWidgetResize: onWidgetResize,
        offWidgetResize: offWidgetResize,
        getResizeWidgetPosition: getResizeWidgetPosition
    };

    function onWidgetResize(callback) {
        PubSub.on($WidgetResizeEvents.RESIZE, callback);
    }

    function offWidgetResize(callback) {
        PubSub.off($WidgetResizeEvents.RESIZE, callback);
    }

    /**
     * Proxy for triggering the resize grid and setting the widget into resize mode
     * @param widget
     */
    function init(widget) {
        props.resizingWidget = widget;
        initResize(widget);
    }

    /**
     * Triggers the resize grid into active mode
     * @param widget
     */
    function initResize(widget) {
        _generateInitialDimensionBoxes();
        _setMinDimensions(widget.type);
        _setOriginalWidgetVariables(widget.width, widget.height);
        _setGridState();
        _setWidgetVariables(widget.id, widget.width, widget.height);

        props.state.isActive = true;
        PubSub.emit($WidgetResizeEvents.RESIZE, props.state.isActive);
    }

    /**
     * Updates active state
     * @param val
     */
    function setActive(val) {
        props.state.isActive = val;
    }

    /**
     * Get current active state
     * @returns {boolean}
     */
    function getIsActive() {
        return props.state.isActive;
    }

    /**
     * DimensionBox Model to map box properties
     * @param options Information to construct
     * @param {Number} options.height
     * @param {Number} options.width
     * @param {Boolean} [options.isActive]      Defaults to FALSE
     * @param {Boolean} [options.isSelected]    Defaults to FALSE
     * @param {Boolean} [options.isDefault]     Defaults to FALSE
     * @constructor
     */

    function DimensionBox(options) {
        this.height = options.height;
        this.width = options.width;
        this.isActive = options.isActive || false;
        this.isSelected = options.isSelected || false;
        this.isDefault = options.isDefault || false;
        this.id = this.width + '-' + this.height;
    }

    function setResize(width, height) {

        let newWidth = width >= props.data.minWidth ? width : props.data.minWidth; // Save min width if less than min
        let newHeight = height >= props.data.minHeight ? height : props.data.minHeight; // Save min height if less than min
        let widgetModel = {
            id: props.data.currentWidgetId,
            width: newWidth,
            height: newHeight
        };

        WidgetFactory.setWidgetDimensions(widgetModel);
        WidgetResizeResourceFactory.resize(widgetModel).then((widget) => {
            if (widget) {
                PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: DesignFactory.getWidget(widget.id).layout_id, page: DesignFactory.getCurrentPage() });
            }
        });

        _resetProps();
        PubSub.emit($WidgetResizeEvents.RESIZE, props.state.isActive);
        DataGridFactory.updateScrollBody(widgetModel.id);
    }

    function resizeWidget(widgetId, width, height) {
        let newWidth = width >= props.data.minWidth ? width : props.data.minWidth; // Save min width if less than min
        let newHeight = height >= props.data.minHeight ? height : props.data.minHeight; // Save min height if less than min
        let widgetModel = {
            id: widgetId,
            width: newWidth,
            height: newHeight
        };

        WidgetFactory.setWidgetDimensions(widgetModel);

        _resetProps();
        PubSub.emit($WidgetResizeEvents.RESIZE, props.state.isActive);
        DataGridFactory.updateScrollBody(widgetModel.id);
    }

    function getResizeWidgetPosition() {
        return WidgetFactory.getWidgetPosition(props.resizingWidget);
    }

    /**
     * When hovering IN over a dimension box
     * @param dimensionBox
     */
    function onHover(dimensionBox) {
        let currentWidth = dimensionBox.width;
        let currentHeight = dimensionBox.height;

        _.each(props.data.dimensionBoxes, function (box) {
            let boxWidth = box.width;
            let boxHeight = box.height;
            if (boxWidth <= currentWidth && boxHeight <= currentHeight) {
                box.isActive = true;
            }
        });

        if (currentWidth <= props.data.minWidth) {
            _.each(props.data.dimensionBoxes, function (box) {
                if (box.width <= props.data.minWidth && box.height <= currentHeight) {
                    box.isActive = true;
                }
            });
        } else if (currentHeight < props.data.minHeight) {
            _.each(props.data.dimensionBoxes, function (box) {
                if (box.width <= currentWidth && box.height <= props.data.minHeight) {
                    box.isActive = true;
                }
            });
        }
        _updateCurrentDimensions(currentWidth, currentHeight);
    }

    /**
     * When hovering OUT of a dimension box
     * @param dimensionBox
     */
    function onHoverOut(dimensionBox, $index) {
        _.each(props.data.dimensionBoxes, function (box) {
            box.isActive = false;
        });
        _updateCurrentDimensions(props.data.originalWidth, props.data.originalHeight);
    }

    function _resetProps() {
        props = _.cloneDeep(defaultProps);
    }

    /**
     *
     * @returns {Array}
     * @private
     */
    function _generateInitialDimensionBoxes() {
        let maxWidgetWidth = WidgetSize.MAX_WIDTH; // grid units
        let maxWidgetHeight = WidgetSize.MAX_HEIGHT; // grid units

        let boxes = [];
        for (let i=1; i <= maxWidgetHeight; i++) {
            for (let j=1; j <= maxWidgetWidth; j++) {
                boxes.push(new DimensionBox({width: j, height: i}));
            }
        }
        props.data.dimensionBoxes = boxes;
    }

    /**
     *
     * @param widgetType
     * @private
     */
    function _setMinDimensions(widgetType) {
        switch(widgetType) {
            case WidgetType.MEDIA:
                props.data.minWidth = props.data.defaultMinWidth;
                props.data.minHeight = 1;
                break;
            default:
                props.data.minWidth = props.data.defaultMinWidth;
                props.data.minHeight = props.data.defaultMinHeight;
                break;
        }
    }

    /**
     *
     * @param width
     * @param height
     * @private
     */
    function _setOriginalWidgetVariables(width, height) {
        props.data.originalWidth = width;
        props.data.originalHeight = height;
    }

    /**
     *
     * @param widgetId
     * @param width
     * @param height
     * @private
     */
    function _setWidgetVariables(widgetId, width, height) {
        props.data.currentWidgetId = widgetId;
        _updateCurrentDimensions(width, height);
    }

    /**
     * Sets grids initial state
     * @private
     */
    function _setGridState() {
        _.each(props.data.dimensionBoxes, function (dimensionBox) {
            dimensionBox.isSelected = false;
            dimensionBox.isDefault = false;

            let boxWidth = dimensionBox.width;
            let boxHeight = dimensionBox.height;
            if (boxWidth <= props.data.originalWidth && boxHeight <= props.data.originalHeight) {
                dimensionBox.isActive = true;
                dimensionBox.isSelected = true;
            }
            if (boxWidth <= props.data.minWidth && boxHeight <= props.data.minHeight) {
                dimensionBox.isDefault = true;
            }
        });
    }

    /**
     * @param width
     * @param height
     * @private
     */
    function _updateCurrentDimensions(width, height) {
        props.data.currentWidth = width;
        props.data.currentHeight = height;
    }

    /**
     * Getter for dimensionBoxes
     * @returns {Array}
     */
    function getDimensionBoxes() {
        return props.data.dimensionBoxes;
    }

}