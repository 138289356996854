<template>
  <section>
    <img v-if="isExporting" :src="image" alt="Widget" />
    <div ref="container" class="chart-container" :class="{ 'am5-exporting': isExporting }">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import { Constant, ExportingFormats } from '@/modules/core/charts/am5/charts.constants';

export default {
  name: 'ChartContext',
  props: {
    context: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      image: null,
      isExporting: false,
    };
  },
  mounted() {
    const { exporter, init, config, onBind, root } = this.context();
    const parentThis = this;
    this.isExporting = config.value.isExporting;

    onBind(async () => {
      let timeout;

      if (this.isExporting) {
        root.value.events.on(Constant.FRAME_ENDED, exportChart);
      }

      function exportChart() {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(async () => {
          root.value.events.off(Constant.FRAME_ENDED, exportChart);
          parentThis.image = await exporter.value.export(ExportingFormats.PNG);
        }, 100);
      }
    });

    init(this.$refs.container);
  },
};
</script>

<style scoped lang="scss">
section {
  display: grid !important;
  grid-template-columns: 1fr;

  > img,
  > div.chart-container {
    grid-row-start: 1;
    grid-column-start: 1;
  }

  > div.chart-container.am5-exporting {
    visibility: hidden;
  }
}
</style>
