'use strict';
import angular from 'angular';
import './scripts/importwizard.constant';
import './scripts/importwizard.ctrls';
import './scripts/importwizard.directives';
import './scripts/importwizard.services';

import importWizardListHtmlUrl from './views/importwizardlist.html';
import importWizardHtmlUrl from './views/importwizard.html';
import manualUploadHtmlUrl from './views/manualupload.html';
import deleteDataHtmlUrl from './views/deletedata.html';

angular.module('importwizard', [
    'importwizard.constant',
    'importwizard.ctrls',
    'importwizard.directives',
    'importwizard.services'
])

    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {
        $stateProvider
            .state('importwizard', {
                parent: 'layout',
                url: '/importwizard',
                controller: 'ImportWizardListController',
                templateUrl: importWizardListHtmlUrl,
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('importwizard'),
                    data: function (ImportWizardFactory) {
                        // note: "active:true" is hard-coded here to meet default behaviour of TA-3906
                        return ImportWizardFactory.services.getList({all: true, summary: true, active: true});
                    },
                    metadata: function (ImportWizardFactory) {
                        return ImportWizardFactory.services.getList({summary: true, metadata: true, is_oui: true});
                    }
                }
            })
            .state('importwizard.filter', {
                parent: 'layout',
                url: '/importwizard/:filter',
                controller: 'ImportWizardListController',
                templateUrl: importWizardListHtmlUrl,
                resolve: {
                    // note: "active:!true" is hard-coded or absent here to meet default behaviour of TA-3906
                    data: function (ImportWizardFactory, $stateParams) {
                        if ('inactive' == $stateParams.filter)
                        {
                            return ImportWizardFactory.services.getList({all: true, summary: true, active: '!true'});
                        }
                        else
                        {
                            return ImportWizardFactory.services.getList({all: true, summary: true});
                        }
                    },
                    metadata: function (ImportWizardFactory) {
                        return ImportWizardFactory.services.getList({summary: true, metadata: true});
                    }
                }
            })
            .state('importwizard.detail', {
                parent: 'layout',
                url: '/importwizard/detail/:id?serviceName&serviceIcon&serviceColor&pi_temp_id&check_code&import&auth_type',
                params: {
                    serviceName: null,
                    serviceIcon: null,
                    serviceColor: null,
                    pi_temp_id: null,
                    check_code: null,
                    import: null,
                    auth_type: null
                },
                controller: 'ImportWizardController',
                templateUrl: importWizardHtmlUrl,
                resolve: {
                    service: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.services.one($stateParams.id).get({all: true});
                    },
                    oauthUser: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.getOauthUser($stateParams.id);
                    },
                    authTypes: function (ImportWizardFactory) {
                        return ImportWizardFactory.getAuthTypes();
                    },
                    dateFormats: function (ImportWizardFactory) {
                        return ImportWizardFactory.getDateFormats();
                    },
                    isCloning: function () {
                        return false;
                    },
                    subleveldata: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.services.getList({all: true, summary: true, active: true, parent_id: $stateParams.id, validate_smart_connector: true});
                    },
                    sublevelmetadata: function (ImportWizardFactory) {
                        return ImportWizardFactory.services.getList({summary: true, metadata: true, sublist: true});
                    },
                    parents: function () {
                        return [];
                    },
                    sc_modules: function (ImportWizardFactory) {
                        return ImportWizardFactory.getSCModules();
                    },
                    genericInfo: function (ImportWizardFactory) {
                        return ImportWizardFactory.getGenericInfo();
                    }
                }
            })
            .state('importwizard.sub', {
                parent: 'layout',
                url: '/importwizard/detail/:parent_id/sub/:id?serviceName&serviceIcon&serviceColor&pi_temp_id&check_code',
                params: {
                    serviceName: null,
                    serviceIcon: null,
                    serviceColor: null,
                    pi_temp_id: null,
                    check_code: null
                },
                controller: 'ImportWizardController',
                templateUrl: importWizardHtmlUrl,
                resolve: {
                    service: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.services.one($stateParams.id).get({all: true});
                    },
                    oauthUser: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.getOauthUser($stateParams.id);
                    },
                    authTypes: function (ImportWizardFactory) {
                        return ImportWizardFactory.getAuthTypes();
                    },
                    dateFormats: function (ImportWizardFactory) {
                        return ImportWizardFactory.getDateFormats();
                    },
                    isCloning: function () {
                        return false;
                    },
                    subleveldata: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.services.getList({all: true, summary: true, active: true, parent_id: $stateParams.id, validate_smart_connector: true});
                    },
                    sublevelmetadata: function (ImportWizardFactory) {
                        return ImportWizardFactory.services.getList({summary: true, metadata: true, sublist: true});
                    },
                    parents: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.getAvailableParentMapping($stateParams.parent_id);
                    },
                    sc_modules: function (ImportWizardFactory) {
                        return ImportWizardFactory.getSCModules();
                    },
                    genericInfo: function (ImportWizardFactory) {
                        return ImportWizardFactory.getGenericInfo();
                    }
                }
            })
            .state('importwizard.clone', {
                parent: 'layout',
                url: '/importwizard/clone/:id?pi_temp_id&check_code',
                params: {
                    pi_temp_id: null,
                    check_code: null
                },
                controller: 'ImportWizardController',
                templateUrl: importWizardHtmlUrl,
                resolve: {
                    service: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.services.one($stateParams.id).get({all: true})
                    },
                    oauthUser: function () {
                        return true;
                    },
                    authTypes: function (ImportWizardFactory) {
                        return ImportWizardFactory.getAuthTypes();
                    },
                    dateFormats: function (ImportWizardFactory) {
                        return ImportWizardFactory.getDateFormats();
                    },
                    isCloning: function () {
                        return true;
                    },
                    subleveldata: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.services.getList({all: true, summary: true, active: true, parent_id: $stateParams.id});
                    },
                    sublevelmetadata: function (ImportWizardFactory) {
                        return ImportWizardFactory.services.getList({summary: true, metadata: true, sublist: true});
                    },
                    parents: function () {
                        return [];
                    },
                    sc_modules: function (ImportWizardFactory) {
                        return ImportWizardFactory.getSCModules();
                    },
                    genericInfo: function (ImportWizardFactory) {
                        return ImportWizardFactory.getGenericInfo();
                    }
                }
            })
            .state('importwizard.manualupload', {
                parent: 'layout',
                url: '/importwizard/manual-upload/:id',
                controller: 'ImportWizardManualUploadController',
                templateUrl: manualUploadHtmlUrl,
                resolve: {
                    service: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.services.one($stateParams.id).get({all: true})
                    },
                    awsBackupFiles: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.getAWSBackupFiles($stateParams.id);
                    },
                    views: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.views($stateParams.id);
                    },
                }
            })
            .state('importwizard.deletedata', {
                parent: 'layout',
                url: '/importwizard/delete-data/:id',
                controller: 'ImportWizardDeleteDataController',
                templateUrl: deleteDataHtmlUrl,
                resolve: {
                    service: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.services.one($stateParams.id).get({all: true})
                    },
                    awsBackupFiles: function ($stateParams, ImportWizardFactory) {
                        return ImportWizardFactory.getAWSBackupFiles($stateParams.id);
                    },
                }
            })
    }]);
