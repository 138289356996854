import { SeriesType } from '@/modules/core/charts/am5/charts.constants';
import { BarChartConfig } from '@/modules/core/charts/am5/bar/models/BarChartConfig';

/**
 *
 */
export class ComboChartConfig extends BarChartConfig {
  constructor(model = {}) {
    super(model);
    this.series = this.series.filter((singleSeries) =>
      [
        SeriesType.COLUMN,
        SeriesType.LINE,
        SeriesType.SMOOTHEDLINE_X,
        SeriesType.SMOOTHEDLINE_Y,
        SeriesType.SMOOTHEDLINE_XY,
      ].includes(singleSeries.seriesType)
    );
  }
}
