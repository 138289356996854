import { BaseDrawOption } from '@/modules/ta/widget/models/drawoptions/BaseDrawOption';
import { isNil } from 'lodash';

export class DataGridDrawOption extends BaseDrawOption {
  /**
   * @var {}
   */
  grid_paginate;

  /**
   * @var {}
   */
  grid_is_responsive;

  /**
   * @var {}
   */
  grid_collapse_in_modal;

  /**
   * @var {}
   */
  show_total_row;

  /**
   * @var {}
   */
  grid_total_row_bottom;

  /**
   * @var {}
   */
  grid_full_image_size;

  /**
   * @var {}
   */
  grid_preview_as_iframe;

  /**
   * @var {}
   */
  pivot_grid;

  /**
   * @var {}
   */
  grid_iframe_capture_delay;

  /**
   * @var {number}
   */
  display_length;

  /**
   * @var {number}
   */
  report_display_length;

  /**
   * @var {}
   */
  percent_of_total;

  /**
   * @var {}
   */
  grid_theme;

  /**
   * @var {string}
   */
  plot_type;

  /**
   * @var {}
   */
  wrap_text_name;

  /**
   * @var {}
   */
  stylized_excel;

  /**
   * @var {}
   */
  embed_sparklines_type;

  /**
   * @var {}
   */
  embed_sparkline_date_range;

  constructor(model = {}) {
    super(model);

    this.report_display_length = model.report_display_length || 'default';
    this.display_length = model.display_length || 10;
    this.grid_paginate = model.grid_paginate || false;
    this.grid_is_responsive =
      model.grid_is_responsive !== undefined ? model.grid_is_responsive : true;
    this.grid_collapse_in_modal =
      model.grid_collapse_in_modal !== undefined ? model.grid_collapse_in_modal : true;
    this.show_total_row = model.show_total_row || false;
    this.grid_total_row_bottom = model.grid_total_row_bottom || false;
    this.grid_full_image_size = model.grid_full_image_size || false;
    this.grid_preview_as_iframe = model.grid_preview_as_iframe || false;
    this.pivot_grid = model.pivot_grid || false;
    this.grid_iframe_capture_delay = model.grid_iframe_capture_delay || false;
    this.percent_of_total = model.percent_of_total || false;
    this.grid_theme = model.grid_theme || false;
    this.plot_type = isNil(model.plot_type) ? null : model.plot_type;
    this.wrap_text_name = model.wrap_text_name || false;
    this.stylized_excel = model.stylized_excel || false;
    this.embed_sparklines_type = model.embed_sparklines_type || false;
    this.embed_sparkline_date_range = model.embed_sparkline_date_range || false;
  }
}
