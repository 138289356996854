import angular from "angular";
import $ from "jquery";
import userChangeLanguageModalHtmlUrl from "./user.language.modal.html";

class ChangeLanguageController {
    /**
     * @ngInject
     */
    constructor(
      UserFactory,
      UIFactory,
      AppModelFactory,
      AppFactory,
      $window,
      gettextCatalog
    ) {
        this.vm = this;
        this.UserFactory = UserFactory;
        this.UIFactory = UIFactory;
        this.$window = $window;

        this.selectOptions = AppModelFactory.getSelectOptions({
            label: gettextCatalog.getString('language'),
            getDataCall: async () => UserFactory.getColumnValues('report_language'),
            formatData: data => {
                 this.languages = data.map(datum => ({id: datum.key, text: datum.value}));
                 this.language = _.find(this.languages, {id: AppFactory.getUser().reportLanguage}) || null;
            },
        });
        this.userHasLanguage = !!AppFactory.getUser().reportLanguage;
        this.isSetting = false;
    }

    async setLanguage(language) {
        this.isSetting = true;
        await this.UserFactory.setCurrentUserLanguage(language);
        this.$window.location = this.$window.location.origin;
        this.isSetting = false;
        this.UIFactory.hideModal('change-language-modal');
    }
}

angular.module('user.language.components', [])
  .component('userChangeLanguageModal', {
    bindings: {
    },
    templateUrl: userChangeLanguageModalHtmlUrl,
    controller: ChangeLanguageController,
    controllerAs: 'vm'
  });