import {
  GradientTargets,
  WidgetBackgroundGradientColorOptions,
} from '@/modules/ta/widget/widget.constants';
import { color, GrainPattern, percent } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { useGradient } from '@/modules/core/charts/am5/base/composables/fills/useGradient';

export function useAxisRange(context) {
  const { config, root } = context();
  const { createLinearGradient, shouldApplyGradient, createRadialGradient } = useGradient(context);

  function addAxisRange(axis, axisRange, maxValue, isComparison = false, hasMultipleAxis = false) {
    const dataItem = axis.makeDataItem({
      above: true,
      value: axisRange.value,
      endValue: axisRange.endValue,
    });

    axis.createAxisRange(dataItem);

    let bandColor = axisRange.fill;
    if (axisRange.value !== 0) {
      if (config.value.fillType === WidgetBackgroundGradientColorOptions.SOLID) {
        bandColor = config.value.backgroundColor;
      }
    }

    const dataItemSettings = {
      opacity: isComparison ? 0.6 : 1,
      innerRadius: config.value.getGaugeThickness(),
      visible: true,
      fill: color(bandColor),
    };

    // if there's a multiple axis then it means we need to adjust the thickness to match
    if (hasMultipleAxis) {
      dataItemSettings.innerRadius = -Math.abs(config.value.getGaugeThickness() / 8 - 10);
    }

    // 0 value is the first axis Range so the one that we're applying color to
    if (axisRange.value === 0) {
      if (config.value.grainDensity) {
        dataItemSettings.fillPattern = GrainPattern.new(root.value, {
          density: config.value.grainDensity / 100,
        });
      }

      // apply gradient to the metric
      if (
        config.value.fillType !== WidgetBackgroundGradientColorOptions.SOLID &&
        shouldApplyGradient(GradientTargets.METRIC, config.value.gaugeGradientTarget)
      ) {
        dataItemSettings.fillGradient = createGradientFrom(
          axisRange.fill,
          config.value.gradientColor
        );
      }
    } else if (config.value.fillType !== WidgetBackgroundGradientColorOptions.SOLID) {
      // apply gradient to the band
      if (shouldApplyGradient(GradientTargets.BAND, config.value.gaugeGradientTarget)) {
        dataItemSettings.fillGradient = createGradientFrom(
          config.value.gradientColor,
          config.value.gaugeGradientTo,
          GradientTargets.BAND
        );
      }
    }

    const shadowSettings = axisRange.value !== 0 ? {} : config.value.getShadowSettings();

    dataItem.get(Constant.AXIS_FILL).setAll({
      ...dataItemSettings,
      ...shadowSettings,
    });

    dataItem.get(Constant.TICK).setAll({
      visible: false,
    });

    return dataItem;
  }

  function createGradientFrom(fromColor, toColor, target = GradientTargets.METRIC) {
    if (target === GradientTargets.BAND) {
      return createRadialGradient([fromColor, fromColor, toColor], {
        x: percent(45),
        y: percent(0),
        radius: percent(100),
      });
    }

    const gradientStops = [fromColor, toColor];

    // repeated fillColor for horizontal gradient so the chosen color is in the middle
    if (config.value.fillType === WidgetBackgroundGradientColorOptions.LINEAR) {
      gradientStops.push(fromColor);
    }

    return createLinearGradient(
      gradientStops,
      config.value.fillType === WidgetBackgroundGradientColorOptions.LINEAR_Y ? 90 : 0
    );
  }

  return {
    addAxisRange,
  };
}
