import Vue from 'vue';
import { isEmpty } from 'lodash';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { KeyboardEvent } from '@/modules/core/ui/ui.constants';
import { BaseUIService } from '@/modules/core/ui/services/BaseUIService';

export class KeyboardAdapter extends BaseUIService {
  /**
   * @type {Object.<string, number>}
   */
  listeners;

  /**
   * @type {boolean}
   */
  isPressingShift;

  constructor() {
    super();
    this.listeners = {};
    this.init();
  }

  /**
   * Registers subscriber to desired keyboard event
   * @param keyboardEvent
   * @param cb
   */
  on(keyboardEvent, cb) {
    this._add(keyboardEvent);
    EventBus.listen(keyboardEvent, cb);
  }

  /**
   * Unregisters subscriber from desired keyboard event
   * @param keyboardEvent
   * @param cb
   */
  off(keyboardEvent, cb) {
    this._remove(keyboardEvent);
    EventBus.destroy(keyboardEvent, cb);
  }

  /**
   * Sets up keyboard events
   */
  init() {
    document.addEventListener('keydown', this.onKeyDown.bind(this));
    document.addEventListener('keyup', this.onKeyUp.bind(this));
  }

  /**
   * Trigger manually a keyboard event
   * @param eventKey
   */
  trigger(eventKey) {
    const keyEvent = KeyboardEvent.getEventsMetadata(eventKey);
    this._sendEvent({ keyEvent, forceTrigger: true });
  }

  /**
   * @param {Event} event
   */
  onKeyUp(event) {
    this.checkShiftKeyPressed(event);
  }

  /**
   * @param {Event} event
   */
  onKeyDown(event) {
    this.checkShiftKeyPressed(event);

    if (!this.hasListeners()) {
      return;
    }

    KeyboardEvent.getKeys().forEach((key) => {
      const keyEvent = KeyboardEvent.getEventsMetadata(key);
      this._sendEvent({ keyEvent, event });
    });
  }

  /**
   * @param {{}} keyEvent
   * @param {Event} event
   * @param {boolean} forceTrigger
   * @private
   */
  _sendEvent({ keyEvent, event = null, forceTrigger = false }) {
    if (!forceTrigger) {
      const isPressingCommand = event?.ctrlKey || event?.metaKey;

      if (!keyEvent || event?.keyCode !== keyEvent?.value) {
        return;
      }

      // check if this key is required to having the command/ctrl key pressed
      if (keyEvent.combo && !isPressingCommand) {
        return;
      }

      // when command/ctrl is not required for key
      if (!keyEvent.combo && isPressingCommand) {
        return;
      }

      if (keyEvent.needsShiftKey && !this.isPressingShift) {
        return;
      }
    }

    if (keyEvent.override && !document.querySelectorAll(':focus').length) {
      if (window.event) {
        window.event.cancelBubble = true;
      }
      event?.preventDefault();
      event?.stopPropagation();
    }

    Vue.nextTick(() => {
      EventBus.signal(keyEvent.name, event);
    });
  }

  /**
   * @param {Event} event
   */
  checkShiftKeyPressed(event) {
    if (event.keyCode === 16) {
      this.isPressingShift = event.shiftKey;
    }
  }

  /**
   * @returns {boolean}
   */
  hasListeners() {
    return !isEmpty(this.listeners);
  }

  /**
   * @param keyEvent
   * @returns {boolean}
   */
  hasListener(keyEvent) {
    return !!this.listeners[keyEvent];
  }

  /**
   * @private
   *
   * Increase listener count
   */
  _add(keyboardEvent) {
    if (!this.listeners[keyboardEvent]) {
      this.listeners[keyboardEvent] = 0;
    }
    this.listeners[keyboardEvent] += 1;
  }

  /**
   * @private
   *
   * Reduce listener count
   */
  _remove(keyboardEvent) {
    this.listeners[keyboardEvent] -= 1;
  }
}

export default new KeyboardAdapter();
