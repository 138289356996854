import { color, percent, RoundedRectangle, Tooltip } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { useTitle } from '@/modules/core/charts/am5/bar/composables/useTitle';

export function useNoticeIcon(context) {
  const { root } = context();
  const { addTitle } = useTitle(context);

  function createNoticeIcon(tooltipText, canIncludeInteractivity) {
    const titleText = canIncludeInteractivity ? '?' : tooltipText;

    // label with a background so it can be targeted by tooltip
    const label = addTitle(titleText, {
      fill: color(0x000000),
      x: percent(100),
      centerX: percent(100),
      tooltipText,
      background: RoundedRectangle.new(root.value, {
        fill: color(0xeeeeee),
        fillOpacity: 1,
        stroke: color(0x000000),
      }),
    });

    if (canIncludeInteractivity) {
      const tooltip = Tooltip.new(root.value, {
        pointerOrientation: Constant.RIGHT,
        textAlign: Constant.CENTER,
      });
      tooltip.get(Constant.BACKGROUND).setAll({
        fill: color(0xeeeeee),
      });

      label.set(Constant.TOOLTIP, tooltip);
    }

    return label;
  }

  return {
    createNoticeIcon,
  };
}
