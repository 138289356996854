import angular from 'angular';
import $ from "jquery";
import manageProductsAdminHtmlUrl from './manageproducts.admin.html';

class ManageProductsDashController {
    /**
     * @ngInject
     */
    constructor(
        ManageProductsFactory,
        AppFactory,
        AppModule
    ) {
        this.vm = this;
        this.ManageProductsFactory = ManageProductsFactory;
        this.AppFactory = AppFactory;
        this.AppModule = AppModule;
        this.vm.addProductClicked = this.addProductClicked;
        this.vm.showAddProductButton = !this.AppFactory.getUser().isModuleAvailable(this.AppModule.IO_TOOL);

    }

    addProductClicked() {
        return this.ManageProductsFactory.setNewDetails();
    }
}

angular.module('core.manageproducts.components', [])
    .component('manageProductsAdmin', {
        bindings: {
            currentModule: '<',
            state: '<',
            design: '<',
            showDetailsModal: '<'
        },
        templateUrl: manageProductsAdminHtmlUrl,
        controller: ManageProductsDashController,
        controllerAs: 'vm'
    });