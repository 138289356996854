<template>
  <b-field
    :horizontal="horizontal"
    :class="{ 'show-as-label': showAsInputOnFocusOnly }"
    :type="fieldType"
    :title="fieldMessage"
    data-cy="input-field"
  >
    <template v-if="showLabel" #label>
      <!-- Slot for overriding label template -->
      <slot name="label">
        <b-tooltip
          :active="isTooltipActive"
          :label="formattedTooltip"
          type="is-dark"
          :multilined="true"
          :dashed="isTooltipActive"
          :append-to-body="appendToBody"
          data-cy="input-tooltip"
        >
          {{ formattedLabel }}
          <span v-if="showRequiredAsterisk" v-once>*</span>
        </b-tooltip>
      </slot>
      <p class="sub-label" data-cy="sub-label">
        <slot name="sub-label" />
      </p>
    </template>
    <b-tooltip
      class="readonly-tooltip"
      :active="!!(readOnly && readOnlyTooltip)"
      :label="readOnlyTooltip"
      :position="readOnlyTooltipPosition"
      type="is-dark"
      :multilined="true"
      :dashed="false"
      data-cy="input-tooltip"
    >
      <ValidationProvider
        :rules="rules"
        v-slot="validationProps"
        :name="formattedLabel"
        :vid="computedName"
        :mode="ruleMode"
        :debounce="validateAfter"
        :immediate="validateImmediately"
      >
        <b-input
          v-if="!isPhoneNumberInput && !isCurrencyInput"
          v-model="computedValue"
          :class="getClasses(validationProps)"
          :type="type"
          :icon="icon"
          :icon-pack="iconPack"
          :max="max"
          :min="min"
          :minlength="minLength"
          :maxlength="maxLength"
          :disabled="readOnly"
          ref="refInput"
          :step="step"
          :placeholder="computedPlaceholder"
          :use-html5-validation="useHtml5Validation"
          :password-reveal="passwordReveal"
          data-cy="input-element"
          @keyup.native.enter="onEnter"
          @keyup.native="onKeyUp"
          @keypress.native="filterInputValue"
          @blur="onBlur"
        />
        <div v-else-if="isCurrencyInput" class="control is-clearfix">
          <CurrencyInput
            v-model.lazy="computedValue"
            :class="getClasses(validationProps)"
            :disabled="readOnly"
            ref="refInput"
            :placeholder="computedPlaceholder"
            :options="computedCurrencyOptions"
            class="input"
            data-cy="currency-element"
            @keyup.native.enter="onEnter"
            @keyup.native="onKeyUp"
            @blur="onBlur"
          />
        </div>
        <VueTelInput
          v-else
          v-model="computedValue"
          ref="refInput"
          :dynamic-placeholder="true"
          mode="international"
          :valid-characters-only="true"
          :preferred-countries="phonePreferredCountries"
          :style-classes="phoneWrapperClasses"
          :input-options="phoneInputOptions"
          data-cy="tele-input"
          @keyup.native.enter="onEnter"
          @keyup.native="onKeyUp"
          @blur="onBlur"
        >
          <template #arrow-icon="{ open }">
            <span class="vti__dropdown-arrow">{{ open ? '▲' : '▼' }}</span>
          </template>
        </VueTelInput>
        <slot name="error" v-bind="validationProps">
          <template v-if="hasValidationErrors(validationProps)">
            <span
              class="has-text-danger is-size-7 is-flex-baseline is-absolute validation-errors show-with-background"
              data-cy="validation-errors"
            >
              <b-icon
                pack="ti"
                custom-class="ti-close-circle"
                size="is-small"
                class="icon-button"
                data-cy="close-icon"
              />
              {{ validationProps.errors.join(', ') }}
            </span>
          </template>
        </slot>
      </ValidationProvider>
    </b-tooltip>
  </b-field>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { FormInputMixin } from '@/modules/core/form/mixins/FormInputMixin';
import CurrencyInput from '@/modules/core/form/components/CurrencyInput';

const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "vue-tel-input" */ 'vue-tel-input'),
    import(/* webpackChunkName: "vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
  ]).then(([{ VueTelInput: VueTelInputComponent }]) => VueTelInputComponent);

export default {
  mixins: [FormInputMixin],
  components: { ValidationProvider, VueTelInput, CurrencyInput },
  props: {
    type: { type: String, default: 'text' },
    icon: { type: String, default: '' },
    iconPack: { type: String, default: 'fa' },
    max: { type: [Number, String], default: undefined },
    min: { type: [Number, String], default: undefined },
    step: { type: [Number, String], default: undefined },
    isInteger: { type: Boolean, default: undefined },
    currencyOptions: { type: Object, default: undefined },
    fieldType: { type: String, default: undefined },
    fieldMessage: { type: String, default: undefined },
    useHtml5Validation: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    passwordReveal: {
      type: Boolean,
      default: false,
    },
  },
  static: {
    // These countries will appear on the top of the list
    phonePreferredCountries: ['US', 'CA', 'GB'],
    phoneWrapperClasses: ['control', 'is-clearfix'],
    phoneInputClasses: ['input'],
    defaultCurrencyOptions: {
      locale: 'en',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      useGrouping: true,
      accountingSign: false,
      valueAsInteger: true,
      precision: 0,
      valueRange: { min: 0 },
    },
  },
  data() {
    return {
      phoneInputOptions: {
        autocomplete: 'on',
        maxlength: 25,
        styleClasses: ['input'],
        placeholder: i18n.$t('Enter a phone number'),
        type: 'tel',
      },
    };
  },
  computed: {
    computedPlaceholder() {
      return this.placeholder || this.$t(`Enter {label}`, { label: this.column?.label || '' });
    },
    isPhoneNumberInput() {
      return ['phone', 'tel'].includes(this.type);
    },
    isCurrencyInput() {
      return this.type === 'currency';
    },
    computedCurrencyOptions() {
      return {
        ...this.defaultCurrencyOptions,
        ...(this.currencyOptions || {}),
      };
    },
  },
  methods: {
    setFocus() {
      this.$refs.refInput?.$refs.input?.focus();
    },
    filterInputValue(event) {
      // Restrict decimal number if isInteger provided as true
      if (this.type === 'number' && this.isInteger && event.keyCode === 46) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep() {
  textarea,
  textarea:disabled {
    border-radius: rem(5px);
    border: 1px solid $grey-lighter;
  }
  .control.has-icons-left {
    .icon {
      color: $grey;
    }
  }
}
.vue-tel-input {
  border-radius: rem(5px);
  background-color: $background-color;
  border-color: $grey-lighter;
  color: #363636;

  &:hover {
    border-color: $grey-light;
  }

  &:focus-within {
    box-shadow: none;
    border-color: $grey-light;
  }

  :deep() {
    .vti__input {
      border-radius: rem(5px);
    }

    .vti__dropdown {
      border-top-left-radius: rem(5px);
      border-bottom-left-radius: rem(5px);

      &:focus {
        outline: none;
      }
    }

    .vti__dropdown-list {
      &.below {
        top: rem(32px);
        border-bottom-left-radius: rem(5px);
        border-bottom-right-radius: rem(5px);
      }
      &.top {
        border-top-left-radius: rem(5px);
        border-top-right-radius: rem(5px);
      }
    }

    .vti__dropdown-item {
      padding: rem(6px);

      strong {
        font-weight: 400;
      }
    }
  }
}

.is-horizontal {
  :deep() {
    .field-label {
      text-align: left;
      width: 35%;
      flex-basis: unset;

      .label {
        font-size: rem(14px);
        margin-top: rem(5px);
      }
      .sub-label {
        font-size: rem(12px);
        line-height: 1;
        font-weight: normal;
      }
    }
    .field-body {
      width: 65%;
      flex-basis: unset;
    }
  }
}

.readonly-tooltip {
  width: 100%;

  &.is-right {
    :deep() {
      .tooltip-content {
        left: 50%;
      }
    }
  }
}

.show-as-label {
  :deep() {
    .field-body {
      &:not(:hover) {
        .input,
        .vue-tel-input {
          border: rem(1px) solid transparent;
        }
      }

      input:disabled,
      textarea:disabled {
        border: rem(1px) solid transparent;
        background-color: transparent;
        color: $text;
        cursor: default;
      }
    }
  }
}
.show-with-background {
  background-color: $background-color;
  margin-top: rem(3px);
  .icon {
    height: rem(18px);
  }
}
</style>
