'use strict';
import angular from 'angular';

angular.module('core.liveintegration.services', [])
    .service('LiveIntegrationFactory', LiveIntegrationFactory);

function LiveIntegrationFactory()
 {
    /**
     * Returns if alert options can be included in widgets.
     * @param widget
     * @returns {*}
     */
    function canIncludeAlertOption(widget) {
        return !widget.has_live_integration;
    }
    
    /**
     * Returns if annotation option can be included in a widget.
     * @param widget
     * @returns {*}
     */
    function canIncludeAnnotationOption(widget) {
        return !widget.has_live_integration;
    }

    /**
     * Used to disable filters such as client/clientgroup/businessunit dropdowns,
     * @returns {*}
     */
    function canIncludeFilterOption(widget) {
        return !widget.has_live_integration;
    }
    
    /**
     * Used to check whether to show the probe toggle on widgets.
     * @param widget
     * @returns {*}
     */
    function canIncludeProbeToggler(widget) {
        return !widget.has_live_integration;
    }

    /**
     * For LiveIntegrations Worker Manager Service should be used.
     * @returns {*}
     */
    function shouldUseLIWorkerManagerService(isDynamicLiveIntegrationEnabled) {
        return isDynamicLiveIntegrationEnabled;
    }

    return {
        canIncludeAlertOption: canIncludeAlertOption,
        canIncludeAnnotationOption: canIncludeAnnotationOption,
        canIncludeFilterOption: canIncludeFilterOption,
        canIncludeProbeToggler: canIncludeProbeToggler,
        shouldUseLIWorkerManagerService: shouldUseLIWorkerManagerService,
    }
}
