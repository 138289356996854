'use strict';
import angular from 'angular';

angular.module('core.tag.values.services', [])
    .factory('TagValuesFactory', TagValuesFactory);

/**
 * @ngInject
 */
function TagValuesFactory(
    $q,
    ServiceFactory,
    TagValuesResource,
    DetailsModalFactory,
    DataSourceType,
    TagUIResource,
    DetailsModalSize,
    DetailsModalModelFactory
) {
    return {
        setNewDetails: setNewDetails,
        setEditDetails: setEditDetails
    };

    /**
     * Set ups detail modal as new data entity
     * @returns {*}
     */
    function setNewDetails() {
        DetailsModalFactory.setAsNew(true);
        return _setDetails([], 'New Tag Value');
    }

    /**
     * Sets up detail modal with data entity
     * @param data
     * @param row
     */
    function setEditDetails(data, row) {
        var promises = [
            TagValuesResource.get(data.id)
        ];
        return _setDetails(promises, _getDisplayTitle(data, row), row, DetailsModalSize.SMALL);
    }

    function _getDisplayTitle(data, row) {
        return TagUIResource.renderTagHeader(data);
    }

    /**
     * Sets up details
     * @private
     */
    function _setDetails(promises, title, row, modalSize) {
        return DetailsModalFactory.setDetails(DetailsModalModelFactory.getModalOptions({
            dataSourceType: DataSourceType.TAG_VALUE,
            promises: promises,
            resource: TagValuesResource.getResource(),
            display: {
                title: title,
                loadingMessage: 'Loading Tag Value',
                icon: 'icon-tags',
                hideTitleIcon: true
            },
            row: row,
            modalSize: modalSize
        }));
    }

}