<template>
  <ChartWrapper v-bind="$props" :is-data-empty="isDataEmpty" :context="context" />
</template>

<script>
import { useFunnelChart } from '@/modules/core/charts/am5/funnel/composables/useFunnelChart';
import ChartWrapper from '@/modules/core/charts/am5/base/components/ChartWrapper.vue';
import { createChartContext } from '@/modules/core/charts/am5/base/composables/createChartContext';
import { isEmpty, isNil } from 'lodash';
import { WidgetConfig, WidgetEvents } from '@/modules/ta/widget/widget.constants';

export default {
  name: 'FunnelChart',
  components: {
    ChartWrapper,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    errorMessage: { type: String, required: false, default: undefined },
    disableErrorMessages: {
      type: Boolean,
      default: false,
    },
    widgetType: { type: String, default: undefined },
  },
  setup(props, componentContext) {
    const context = createChartContext(props.config);
    const { refresh, onBind, onFrameEnded } = context();
    const { initFunnelChart } = useFunnelChart(context);

    onBind(() => {
      initFunnelChart();
    });

    onFrameEnded(() => {
      componentContext.emit(WidgetEvents.FRAME_ENDED);
    });

    return {
      context,
      refresh,
      initFunnelChart,
    };
  },
  computed: {
    isDataEmpty() {
      const { cached_data } = this.config.get(WidgetConfig.WIDGET);
      return isNil(cached_data) || isEmpty(cached_data);
    },
  },
  watch: {
    config(value) {
      this.refresh(value);
    },
  },
};
</script>
