import UserResource from '@/modules/ta/user/resources/UserResource';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import { UserStatus, UserType } from '@/modules/ta/user/user.constants';

export class UserService extends BaseService {
  /**
   * @param id
   * @param value
   * @returns {Promise<*>}
   */
  async setShowWelcome(id, value) {
    return UserResource.setShowWelcome(id, value);
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  getActiveUsers(params = {}) {
    return UserResource.getItems({ ...params, status: UserStatus.ACTIVE });
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  getNoneClientActiveUsers(params = {}) {
    return this.getActiveUsers({ ...params, user_type: `!${UserType.CLIENT}` });
  }

  /**
   * @returns {Promise<*>}
   */
  getAvatars() {
    return UserResource.getAvatars();
  }

  /**
   *
   * @param userId
   * @returns {Promise<*>}
   */
  async getUserListSettings(userId) {
    return UserResource.getUserListSettings(userId);
  }

  /**
   *
   * @param listSettings
   * @returns {Promise<Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *>}
   */
  async setUserListSettings(listSettings) {
    return UserResource.setUserListSettings(listSettings);
  }
}

export default new UserService();
