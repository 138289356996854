import angular from 'angular';
import $ from 'jquery';

import tagAdminHtmlUrl from './tag.admin.html';

angular.module('core.tag.components', [])
    .component('tagAdmin', {
        bindings: {
            currentModule: '<',
            state: '<',
            design: '<',
            showDetailsModal: '<'
        },
        templateUrl: tagAdminHtmlUrl,
        controller: TagAdmin,
        controllerAs: 'vm'
    });

function TagAdmin(
    TagFactory,
    PubSub,
    $DetailsModalFactoryEvents
) {
    var vm = this;

    vm.addTagClicked = addTagClicked;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        PubSub.on($DetailsModalFactoryEvents.POST_SAVE, _postSaveTag);
    }

    function $onDestroy() {
        PubSub.off($DetailsModalFactoryEvents.POST_SAVE, _postSaveTag);
    }

    function addTagClicked() {
        TagFactory.setNewDetails()
    }

    /**
     * React when creating/editing tag
     * @private
     */
    function _postSaveTag() {
        TagFactory.getTags(true);
    }
}