'use strict';
import angular from 'angular';
import $ from 'jquery';

import historyPanelHtmlUrl from './history.panel.html';

angular.module('core.history.components', [])
    .component('historyPanel', {
        templateUrl: historyPanelHtmlUrl,
        controllerAs: 'vm',
        controller: HistoryController
    });

function HistoryController(
    $element,
    PubSub,
    HistoryFactory,
    MessengerFactory,
    LeadColumns,
    MessengerModelFactory,
    SlidePanelFactory,
    $SlidePanelEvents,
    $MessengerEvents,
    $DetailsModalFactoryEvents
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.isActive = HistoryFactory.getIsActive;

    function $onInit() {
        // messengerOptions is for the messenger panel
        vm.messengerOptions = MessengerModelFactory.getOptions({
            id: 'leads-history-panel',
            title: 'History Panel'
        });

        vm.threadOptions = HistoryFactory.getThreadOptions();

        // IMPORTANT: In order to correctly initialize messenger panel, PubSubs must only
        // be initialized inside of "$MessengerEvents.INIT_PANEL + vm.messengerOptions.id"
        PubSub.on($MessengerEvents.INIT_PANEL + vm.messengerOptions.id, _initPanel);
        $element.appendTo(document.body);
    }

    function $onDestroy() {
        $element.remove();
        PubSub.off($MessengerEvents.INIT_PANEL + vm.messengerOptions.id, _initPanel);
        _closePanel();
    }

    /**
     * @param options
     * @private
     */
    function _initPanel(options) {
        SlidePanelFactory.closeAll(vm.messengerOptions.id);
        var data = options.data;
        HistoryFactory.initHistory('lead', data);
        vm.messengerOptions.title = HistoryFactory.getPanelTitle(data);
        _registerEvents();
    }

    /**
     * @private
     */
    function _closePanel() {
        HistoryFactory.setInactive();
        _unregisterEvents();
    }

    /**
     *
     * @param sourcePanel
     * @private
     */
    function _closeAll(sourcePanel) {
        if (sourcePanel !== vm.messengerOptions.id) {
            _closePanel();
        }
    }

    function _registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _closeAll);
        PubSub.on($DetailsModalFactoryEvents.CLOSED, _closePanel);
    }

    function _unregisterEvents() {
        PubSub.off($SlidePanelEvents.CLOSE_ALL, _closeAll);
        PubSub.off($DetailsModalFactoryEvents.CLOSED, _closePanel);
    }
}