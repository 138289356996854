"use strict";

import angular from "angular";

export const $WidgetHeaderEvents = {
  REFRESH_TITLE: "$WidgetHeaderEvents:REFRESH_TITLE",
};

export const $WidgetClientDiscrepancy = {
  CLEAR: "$WidgetClientDiscrepancy:CLEAR",
};

export const WidgetTypeGrouping = {
  DATASOURCED: "datasourced",
  CHART: "chart",
  ADMIN: "admin",
  PREMADE: "premade",
  SERIES: "series",
  DISPLAY: "display",
  CHATGPT: "chatgpt",
  EXECUTIVESUMMARY: "executivesummary",
};

export const WidgetType = {
  BIGNUMBER: "bignumber",
  DATAGRID: "datagrid",
  TOPPERFORMER: "topperformer",
  BARCHART: "barchart",
  BUBBLECHART: "bubblechart",
  COMBINATIONCHART: "combinationchart",
  LINECHART: "linechart",
  PIECHART: "piechart",
  GEOCHART: "geochart",
  GAUGECHART: "gaugechart",
  FUNNELCHART: "funnelchart",
  MEDIA: "media",
  ACCOUNTMANAGER: "accountmanager",
  GOAL: "goal",
  GOALCHART: "goalchart",
  MAPBOX: "mapbox",
  CHATGPT: "chatgpt",
  EXECUTIVESUMMARY: "executivesummary",
};

export const BulletShapeIcons = {
  CIRCLE: "fa-circle",
  DIAMOND: "fa-square fa-rotate-45",
  ASTERISK: "fa-star",
  STAR: "fa-star",
  TRIANGLE: "fa-caret-up",
  TRIANGLE_UP: "fa-caret-up",
  TRIANGLE_DOWN: "fa-caret-down",
  TRIANGLE_LEFT: "fa-caret-left",
  TRIANGLE_RIGHT: "fa-caret-right",
  SQUARE: "fa-square",
}

export const WidgetSize = {
  BASE_HEIGHT: 80, // px
  MIN_WIDTH: 3, // grid units
  MIN_HEIGHT: 1, // grid units
  MAX_WIDTH: 12, // grid units
  PREVIEW_HEIGHT: 5, // grid units
  MAX_HEIGHT: 14, // grid units
};

export const WidgetData = {
  MAX_RESULTS: 1000,
  MAX_DISPLAY_RECORDS: 100,
};

export const TimeGrouping = {
  GROUPING_YEARLY: "yearly",
  GROUPING_MONTHLY: "monthly",
  GROUPING_WEEKLY: "weekly",
  GROUPING_DAILY: "daily",
  GROUPING_HOURLY: "hourly",
  GROUPING_MONTH_OF_YEAR: "monthofyear",
  GROUPING_DAY_OF_MONTH: "dayofmonth",
  GROUPING_DAY_OF_WEEK: "dayofweek",
  GROUPING_HOUR_OF_DAY: "hourofday",
  GROUPING_DYNAMIC: "dynamic",
  GROUPING_QUARTERLY: "quarterly",
  GROUPING_NON_ISO_WEEK: "nonisoweek",
  GROUPING_HOURLY_ADVERTISER: "hourlystatsadvertiser",
  GROUPING_HOURLY_AUDIENCE: "hourlystatsaudience",
};

export const WeeklyStartDay = {
  SUNDAY: "sunday",
  MONDAY: "monday",
};

export const ChartDataType = {
  SERIAL: "serial",
  PIE: "pie",
  FUNNEL: "funnel",
  GAUGE: "gauge",
  GEOCHART: "map",
};

export const LoadingState = {
  LOADING: "loading",
  FETCHING: "fetching",
  BUILDING: "building",
  NO_RECORDS: "noRecords", // There are no records AT ALL in the system (ex: no campaigns)
  NO_DATA: "noData", // There are records in the system, but none returned for the given filters/dateranges
  ALL_EMPTY_DATA: "allEmptyData", // We have records but they are all null or 0s
  NO_COLUMNS: "noColumns", // There may be data but there are no columns to display them with
  INACTIVE: "inactive", // Means the widget is not connected for whoever is in conetext of the dashboard
  HAS_ERROR: "hasError",
  INCOMPLETE: "incomplete",
  TOO_MUCH_DATA: "tooMuchData", // Too much data to display in the widget
  ERROR: "error",
  NO_DATA_ACTIONABLE: "noDataActionable",
  DONE: false,
  GEO_PLOT_CHANGE_HEAT_MAP: "geoPlotChangeHeatMap",
  GEO_PLOT_CHANGE_BUBBLE_MAP: "geoPlotChangeBubbleMap",
  BUBBLE_CHART_REQUIRED_METRICS: "bubbleChartRequiredMetrics",
  HAS_GLOBAL_FILTERS: "hasGlobalFilters",
};

export const ChartPlotType = {
  CLUSTERED: 'clustered',
  LINE: 'line',
  LINE_V2: 'line(v2)',
  STACKED: 'regular',
  FULL_STACKED: '100%',
  DEEP_STACKED: '3d',
  AREA: 'area',
  AREA_V2: 'area(v2)',
  HEAT_MAP: 'heat',
  MAPBOX: "mapbox",
  BUBBLE_MAP: 'bubble',
  DEFAULT: 'default',
  MULTI_METRIC: "multi_metric",
  COMPARISON: "comparison",
  SPARKLINE: "sparkline",
  CLASSIC: 'classic',
  FUNNEL: 'funnel',
  PYRAMID: 'pyramid',
  CONDITIONAL_MAP: "conditional",
  GROUPED_COLUMN: "grouped_column",
  STACKED_V2: 'stacked(v2)',
  CLUSTERED_V2: 'clustered(v2)',
  FULL_STACKED_V2: 'full_stacked(v2)',
  RADIAL_HISTOGRAM_V2: 'radial_histogram(v2)',
  RADIAL_BAR_V2: 'radial_bar(v2)',
  LAYERED_V2: 'layered(v2)',
  LOLLIPOP_V2: 'lollipop(v2)',
  GAUGE_V2: 'gauge(v2)',
  MULTI_AXIS_GAUGE: 'gauge_multi_axis(v2)',
  PICTORIAL: "pictorial(v2)",
  EMBEDDED_SPARKLINES: "embedded_sparklines",
};

export const BenchmarkEnabledPlotTypes = {
  [ChartPlotType.LINE_V2]: true,
  [ChartPlotType.CLUSTERED_V2]: true,
  [ChartPlotType.STACKED_V2]: true,
  [ChartPlotType.LAYERED_V2]: true,
  [ChartPlotType.LOLLIPOP_V2]: true,
};

export const ComparisonOptions = {
  MONTH_OVER_MONTH: "month_over_month",
  QUARTER_OVER_QUARTER: "quarter_over_quarter",
  YEAR_OVER_YEAR: "year_over_year",
};

export const SparklineOptions = {
  LAST_3_MONTHS: "last_3_months",
  LAST_6_MONTHS: "last_6_months",
  YEAR_TO_DATE: "year_to_date",
};

export const DateFormatType = {
  DEFAULT: "default",
  NO_YEAR: "noYear",
};

export const WrapFormat = {
  CIRCLE: "circle",
  SQUARE: "square",
};

export const BackgroundColor = {
  DATASOURCE: "datasource",
  THEME: "theme",
  CUSTOM: "custom",
};

export const CenterNumbers = {
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right",
};

export const GradientTypes = {
  SOLID: "solid",
  LINEAR: "linear",
  LINEAR_Y: "linear-y",
  RADIAL: "radial",
};

export const BubbleShapes = {
  CIRCLE: "circle",
  DIAMOND: "diamond",
  STAR: "star",
  TRIANGLE: "triangle",
};

export const BulletShapes = {
  CIRCLE: 'circle',
  DIAMOND: 'diamond',
  STAR: 'star',
  TRIANGLE: 'triangle',
  TRIANGLE_DOWN: 'triangle-down',
  TRIANGLE_LEFT: 'triangle-left',
  TRIANGLE_RIGHT: 'triangle-right',
  SQUARE: 'square',
};

export const LineTypes = {
  LINE: "line",
  STEP_LINE: "step_line",
};

export const SparkLineTypes = {
  LINE: "line",
  COLUMN: "column",
};

export const HandTypes = {
  NONE: "none",
  NEEDLE: "needle",
  POINTER: "pointer",
  TRIANGLE: "triangle",
  LINE: "line",
};

export const GaugeBigNumberPosition = {
  NONE: "none",
  HAND: "hand",
  HAND_TRANSPARENT: "hand_transparent",
  INSIDE: "inside",
  BELOW: "below",
};

export const GradientTargets = {
  EVERYTHING: "everything",
  HAND: "hand",
  METRIC: "metric",
  BAND: "band",
  HAND_METRIC: "hand_metric",
  HAND_BAND: "hand_band",
  METRIC_BAND: "metric_band",
};

export const DisabledColumnState = {
  GROUP_BY: "groupBy",
  UNUSED_METRIC: "unusedMetric",
  FIRST_SELECTED_METRIC: "firstSelectedMetric",
};

export const MapTypes = {
  STREET: "street",
  OUTDOOR: "outdoor",
  LIGHT: "light",
  DARK: "dark",
  SATELLITE: "satellite",
  SATELLITE_STREET: "satelliteStreet",
  NAVIGATION_DAY: "navigationDay",
  NAVIGATION_NIGHT: "navigationNight",
};

export const WidgetSearchFilter = {
  LIKE_VALUE: "-_-",
};

export const WidgetValue = {
  NO_VALUE: "-",
};

export const GeoConfigurationType = {
  COUNTY: "county",
  STATE: "state",
  COUNTRY: "country",
  PIN: "pin",
};

export const $WidgetEvents = {
  WIDGET_REBUILD: "$WidgetEvents:rebuild",
  WIDGET_FETCH_STATUS: "$WidgetEvents:fetchStatus",
  SWAP_WIDGET: "$WidgetEvents:swapWidget",
  DATASOURCED_WIDGET_LOADED: "$WidgetEvents:datasourcedWidgetLoaded",
  WIDGET_INITIATED: "$WidgetEvents:widgetInitiated",
  WIDGET_LOADED: "$WidgetEvents:widgetLoaded",
  AM5_COUNTRY_DRILLDOWN: "$WidgetEvents:am5updatecountrydrilldown",
};

export const ComparisonType = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
  NEUTRAL: "neutral",
  BOTH: "both",
};

export const ConditionalType = {
  BETWEEN: "between",
  GREATER_THAN: "greater_than",
  LESS_THAN: "less_than",
};

export const ConditionalTypeRuleValues = {
  MIN: "min",
  MAX: "max",
};

export const WidgetDataViews = {
  DATA_VIEW_AGE: "Age",
};

export const PictorialTypes = {
  MALE: "male",
  MUG: "mug",
  FEMALE: "female",
  KEY: "key",
  APPLE: "apple",
  MOUNTAIN: "mountain",
  SAILBOAT: "sailboat",
  BULB: "bulb",
  THERMOMETER: "thermometer",
  TREE: "tree",
  ROCKETSHIP: "rocketship",
  HAMMER: "hammer",
  SKYSCRAPER: "skyscraper",
  EVERGREEN: "evergreen",
  CUP: "cup",
  HEART: "heart",
  HOUSE: "house",
};

export const BenchmarkLineStyle = {
  DASHED: 'dashed',
  DOTTED: 'dotted',
  SOLID: 'solid',
  NONE: 'none',
};

angular
  .module("design.widget.constants", [])
  .constant("$WidgetHeaderEvents", $WidgetHeaderEvents)
  .constant("WidgetTypeGrouping", WidgetTypeGrouping)
  .constant("WidgetType", WidgetType)
  .constant("MapTypes", MapTypes)
  .constant("HandTypes", HandTypes)
  .constant("GradientTargets", GradientTargets)
  .constant("WidgetSize", WidgetSize)
  .constant("WidgetData", WidgetData)
  .constant("TimeGrouping", TimeGrouping)
  .constant("ChartDataType", ChartDataType)
  .constant("LoadingState", LoadingState)
  .constant("ChartPlotType", ChartPlotType)
  .constant("DateFormatType", DateFormatType)
  .constant("WrapFormat", WrapFormat)
  .constant("BackgroundColor", BackgroundColor)
  .constant("BubbleShapes", BubbleShapes)
  .constant("BulletShapes", BulletShapes)
  .constant("BulletShapeIcons", BulletShapeIcons)
  .constant("LineTypes", LineTypes)
  .constant("SparkLineTypes", SparkLineTypes)
  .constant("CenterNumbers", CenterNumbers)
  .constant("GradientTypes", GradientTypes)
  .constant("GaugeBigNumberPosition", GaugeBigNumberPosition)
  .constant("DisabledColumnState", DisabledColumnState)
  .constant("WidgetSearchFilter", WidgetSearchFilter)
  .constant("GeoConfigurationType", GeoConfigurationType)
  .constant("WidgetValue", WidgetValue)
  .constant("ComparisonOptions", ComparisonOptions)
  .constant("SparklineOptions", SparklineOptions)
  .constant("$WidgetEvents", $WidgetEvents)
  .constant("ComparisonType", ComparisonType)
  .constant("ConditionalType", ConditionalType)
  .constant("PictorialTypes", PictorialTypes)
  .constant("BenchmarkLineStyle", BenchmarkLineStyle)
  .constant("BenchmarkEnabledPlotTypes", BenchmarkEnabledPlotTypes)
  .constant("WidgetDataViews", WidgetDataViews);
