export const mutations = {
  addToUndoStack(state, mutation) {
    state.undoStack.push(mutation);
  },
  addToDoStack(state, mutation) {
    state.doStack.push(mutation);
  },
  clearUndoStack(state) {
    state.undoStack = [];
  },
  clearDoStack(state) {
    state.doStack = [];
  },
  popDoStack(state) {
    state.doStack.pop();
  },
  popUndoStack(state) {
    state.undoStack.pop();
  },
  updateCurrentOperationPromise(state, currentOperationPromise) {
    state.currentOperationPromise = currentOperationPromise;
  },
};
