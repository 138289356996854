import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  /**
   * @param {Array.<number>} clusterIds
   * @returns {Promise<void>}
   */
  async setSelectedClusters({}, clusterIds) {
    commits.platformfilter.setSelectedClusters(clusterIds);
  },
};
