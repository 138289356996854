import { DrawOptionItem } from '@/modules/ta/widget/models/drawoptions/DrawOptionItem';

export class BaseDrawOption {
  /**
   * @var {DrawOptionItem}
   */
  show_borders;

  /**
   * @var {DrawOptionItem}
   */
  show_shadow;

  /**
   * @var {DrawOptionItem}
   */
  show_header;

  /**
   * @var {DrawOptionItem}
   */
  show_title;

  /**
   * @var {DrawOptionItem}
   */
  show_data_source;

  /**
   * @var {DrawOptionItem}
   */
  show_widgets_with_no_data;

  /**
   * @var {DrawOptionItem}
   */
  show_background;

  /**
   * @var {string}
   */
  date_format_type;

  /**
   * @var {boolean}
   */
  has_legend;

  /**
   * @var {boolean}
   */
  is_rotated;

  /**
   * @var {boolean}
   */
  has_value_scroller;

  /**
   * @var {boolean}
   */
  show_labels;

  /**
   * @var {boolean}
   */
  is_y_axis_moved;

  /**
   * @var {boolean}
   */
  is_normalized;

  /**
   * @var {boolean}
   */
  is_zero_scaled;

  /**
   * @var {boolean}
   */
  has_tooltip;

  /**
   * @var {boolean}
   */
  hide_grid_lines;

  /**
   * @var {number}
   */
  depth;

  /**
   * @var {number}
   */
  angle;

  /**
   * @var {boolean}
   */
  show_metric_labels;

  /**
   * @var {boolean}
   */
  show_empty_dates;

  /**
   * @var {boolean}
   */
  is_hand_drawn;

  /**
   * @var {boolean}
   */
  show_sample_data;

  /**
   * @var {boolean}
   */
  force_sample_data;

  /**
   * @var {boolean}
   */
  is_solid_gauge;

  /**
   * @var {boolean}
   */
  show_label_values;

  /**
   * @var {boolean}
   */
  show_label_percent;

  /**
   * @var {boolean}
   */
  show_label_names;

  /**
   * @var {number}
   */
  start_angle;

  /**
   * @var {number}
   */
  inner_radius;

  /**
   * @var {boolean}
   */
  other_percent;

  /**
   * @var {boolean}
   */
  label_percent;

  /**
   * @var {boolean}
   */
  grid_paginate;

  /**
   * @var {boolean}
   */
  grid_is_responsive;

  /**
   * @var {boolean}
   */
  grid_collapse_in_modal;

  /**
   * @var {boolean}
   */
  show_total_row;

  /**
   * @var {boolean}
   */
  grid_total_row_bottom;

  /**
   * @var {boolean}
   */
  grid_full_image_size;

  /**
   * @var {boolean}
   */
  grid_preview_as_iframe;

  /**
   * @var {boolean}
   */
  pivot_grid;

  /**
   * @var {boolean}
   */
  grid_iframe_capture_delay;

  /**
   * @var {string}
   */
  plot_type;

  /**
   * @var {boolean}
   */
  grid_theme;

  /**
   * @var {boolean}
   */
  wrap_text_name;

  constructor(model = {}) {
    this.show_borders = model.show_borders
      ? new DrawOptionItem(model.show_borders)
      : BaseDrawOption.buildDefaultShowBorders();

    this.show_shadow = model.show_shadow
      ? new DrawOptionItem(model.show_shadow)
      : BaseDrawOption.buildDefaultShowShadow();

    this.show_header = model.show_header
      ? new DrawOptionItem(model.show_header)
      : BaseDrawOption.buildDefaultShowHeader();

    this.show_title = model.show_title
      ? new DrawOptionItem(model.show_title)
      : BaseDrawOption.buildDefaultShowTitle();

    this.show_data_source = model.show_data_source
      ? new DrawOptionItem(model.show_data_source)
      : BaseDrawOption.buildDefaultShowDataSource();

    this.show_widgets_with_no_data = model.show_widgets_with_no_data
      ? new DrawOptionItem(model.show_widgets_with_no_data)
      : BaseDrawOption.buildDefaultShowWidgetsWithNoData();

    this.show_background = model.show_background
      ? new DrawOptionItem(model.show_background)
      : BaseDrawOption.buildDefaultShowBackground();

    this.date_format_type = model.date_format_type;
    this.has_legend = model.has_legend;
    this.is_rotated = model.is_rotated;
    this.has_value_scroller = model.has_value_scroller;
    this.show_labels = model.show_labels;
    this.is_y_axis_moved = model.is_y_axis_moved;
    this.is_normalized = model.is_normalized;
    this.is_zero_scaled = model.is_zero_scaled;
    this.has_tooltip = model.has_tooltip;
    this.hide_grid_lines = model.hide_grid_lines;
    this.depth = model.depth ?? 0;
    this.angle = model.angle ?? 0;
    this.show_metric_labels = model.show_metric_labels;
    this.show_empty_dates = model.show_empty_dates;
    this.is_hand_drawn = model.is_hand_drawn;
    this.show_sample_data = model.show_sample_data ?? false;
    this.force_sample_data = model.force_sample_data ?? false;
    this.is_solid_gauge = model.is_solid_gauge;
    this.show_label_values = model.show_label_values;
    this.show_label_percent = model.show_label_percent;
    this.show_label_names = model.show_label_names;
    this.start_angle = model.start_angle;
    this.inner_radius = model.inner_radius;
    this.other_percent = model.other_percent;
    this.label_percent = model.label_percent ?? 0;
    this.grid_paginate = model.grid_paginate || false;
    this.grid_is_responsive =
      model.grid_is_responsive !== undefined ? model.grid_is_responsive : true;
    this.grid_collapse_in_modal =
      model.grid_collapse_in_modal !== undefined ? model.grid_collapse_in_modal : true;
    this.show_total_row = model.show_total_row || false;
    this.grid_total_row_bottom = model.grid_total_row_bottom || false;
    this.grid_full_image_size = model.grid_full_image_size || false;
    this.grid_preview_as_iframe = model.grid_preview_as_iframe || false;
    this.pivot_grid = model.pivot_grid || false;
    this.font_size = model.font_size || 32;
    this.plot_type = model.plot_type;
    this.grid_theme = model.grid_theme || false;
    this.wrap_text_name = model.wrap_text_name || false;
  }

  static buildDefaultShowBorders() {
    return new DrawOptionItem({
      value: true,
      overridden: false,
      metadata: {
        color: '#ffffff',
        alpha: 1,
      },
    });
  }

  static buildDefaultShowHeader() {
    return new DrawOptionItem({
      value: true,
      overridden: false,
    });
  }

  static buildDefaultShowTitle() {
    return new DrawOptionItem({
      value: true,
      overridden: false,
    });
  }

  static buildDefaultShowDataSource() {
    return new DrawOptionItem({
      value: true,
      overridden: false,
    });
  }

  static buildDefaultShowBackground() {
    return new DrawOptionItem({
      value: true,
      overridden: false,
    });
  }

  static buildDefaultShowWidgetsWithNoData() {
    return new DrawOptionItem({
      value: true,
      overridden: false,
    });
  }

  static buildDefaultShowShadow() {
    return new DrawOptionItem({
      value: false,
      overridden: false,
      metadata: {
        alpha: 1,
        color: '#EBEBEB',
        size: 1,
      },
    });
  }
}
