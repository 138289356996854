import angular from 'angular';
import $ from 'jquery';
import moment from 'moment';
import _ from 'lodash';

import exportBuilderQuickExportHtmlUrl from './exportbuilder.quickexport.html';
import { UserType } from 'coreModules/shared/scripts/app.constants';

angular.module('exportbuilder.admin.components')
    .component('exportBuilderQuickExportModal', {
        bindings: {
            report: '<',
            didDeleteReport: '<'
        },
        templateUrl: exportBuilderQuickExportHtmlUrl,
        controller: ExportBuilderQuickExportModalController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderQuickExportModalController(
    $ExportBuilderQuickExportModalEvents,
    PubSub,
    $timeout,
    $rootScope,
    $element,
    ExportBuilderDashboardService,
    AppFactory,
    AppModelFactory,
    ExportBuilderDashboardModelFactory,
    ExportBuilderDashboardUIModelFactory,
    ClientFactory,
    ClientGroupFactory,
    ReportingProfileFactory,
    UIFactory,
    $WidgetEvents,
    DateRangeFactory,
    RelativeDateRange,
    ExportBuilderPanelConstants,
    ExportReportFactory,
    ExportBuilderAdminDataService,
    ExportType,
    DataSourceType,
    AppModule
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.isFooterActive = isFooterActive;
    vm.onChangeCluster = onChangeCluster;
    vm.onChangeClient = onChangeClient;
    vm.onChangeClientGroup = onChangeClientGroup;
    vm.toggleComparison = toggleComparison;
    vm.exportPDF = exportPDF;
    vm.exportPPT = exportPPT;
    vm.sendMail = sendMail;
    vm.toggleSendingMail = toggleSendingMail;
    vm.onClose = onClose;
    vm.canShowClusterOptions = canShowClusterOptions;
    vm.onChangeUsers = onChangeUsers;
    vm.canSendMail = canSendMail;
    vm.resetSkipAttachment = resetSkipAttachment;
    vm.onChangeSmartCampaign = onChangeSmartCampaign;

    /**
     * @type {PanelClusterOption}
     */
    vm.clusterOption;

    /**
     * @type {PanelClientSelectOption}
     */
    vm.clientSelectOptions;

    /**
     * @type {PanelClientGroupSelectOption}
     */
    vm.clientGroupSelectOptions;


    vm.userOptions;

    vm.isSendingMail = false;
    vm.canShowSmartCampaignOption = false;

    vm.dateRangeValue = '';
    vm.comparisonDateRangeValue = '';

    /**
     * @type {PanelViewModel}
     */
    vm.model = ExportBuilderDashboardModelFactory.getPanelViewModel();

    function $onInit() {
        vm.canShowSmartCampaignOption = AppFactory.isUserModuleAvailable(AppModule.SMART_CAMPAIGNS) && AppFactory.getUser().smartCampaignsEnabled;
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function isFooterActive() {
        return vm.model.isSampleData || (vm.model.selectedClient || vm.model.selectedClientGroup || vm.model.selectedCluster || vm.model.selectedSmartCampaign);
    }

    function canSendMail() {
        return vm.model.export_type && ((vm.model.users && vm.model.users.length) || vm.model.recipients);
    }

    function _getEntity() {
        return _.find([vm.model.selectedClient, vm.model.selectedClientGroup, vm.model.selectedCluster, vm.model.selectedSmartCampaign], function (object) {
            return !_.isNil(object);
        })
    }

    function exportPPT() {
        vm.loaderIcon = 'icon-file-powerpoint-o';
        _export(ExportType.TYPE_EXPORT_PPT);
    }

    function exportPDF() {
        vm.loaderIcon = 'icon-file-pdf-o';
        _export(ExportType.TYPE_EXPORT_PDF);
    }

    function toggleSendingMail() {
        vm.isSendingMail = !vm.isSendingMail;
    }

    function sendMail() {
        vm.isLoading = true;
        var report = ExportBuilderAdminDataService.getQuickExportReport();
        var selectedEntity = _getEntity();
        var params = { pageId: report.id };
        if (vm.model.isSampleData) {
            params.sample_data = true;
        } else {
            params.data_source_type = selectedEntity.type;
            params.data_source_type_id = selectedEntity.id;
        }
        params.daterange = DateRangeFactory.buildDateQueryParam();

        if (Array.isArray(vm.model.users)) {
            params.users = vm.model.users.map(function (user) {return user.id;});
        }

        params.recipients = vm.model.recipients;
        params.type = vm.model.export_type;
        params.enable_ez_login = vm.model.enable_ez_login;
        params.skip_attachment = vm.model.skip_attachment;
        ExportReportFactory.sendMail(params).then(function(result) {
            UIFactory.notify.showSuccess('Email sent successfully');
            onClose();
        }).catch(function (e) {
            if (e.data && e.data.error) {
                UIFactory.notify.showError(e.data.data, { autoHide: false });
            } else {
                UIFactory.notify.showError(e.msg);
                onClose();
            }
        })
        .finally(function () {
            vm.isLoading = false;
        });
    }

    function _export(type) {
        vm.isLoading = true;
        var report = ExportBuilderAdminDataService.getQuickExportReport();
        var selectedEntity = _getEntity();
        var params = {pageId: report.id};

        if (vm.model.isSampleData) {
            params.sample_data = true;
        } else {
            params.data_source_type = selectedEntity.type;
            params.data_source_type_id = selectedEntity.id;
        }

        var exportUrl = null;
        switch (type) {
            case ExportType.TYPE_EXPORT_PDF:
                exportUrl = ExportReportFactory.exportBuilderToPdf(params).getRequestedUrl();
                break;
            case ExportType.TYPE_EXPORT_PPT:
                exportUrl = ExportReportFactory.exportBuilderToPpt(params).getRequestedUrl();
                break;
            default:
                console.error('Unhandled export type: ' + type);
                return;
        }

        $.fileDownload(exportUrl, _fileDownloadCallback());
    }

    function _fileDownloadCallback() {
        return {
            successCallback: function () {
                $timeout(function () {
                    _close();
                }, 0);
            },
            failCallback: function () {
                $timeout(function () {
                    vm.isLoading = false;
                }, 0);
            }
        }
    }

    function onChangeClient($el, event) {
        vm.model.selectedClient = $el.select2('data');
        vm.model.selectedClient.type = DataSourceType.CLIENT;
        vm.model.selectedClientGroup = null;
        vm.model.selectedCluster = null;
        vm.model.selectedSmartCampaign = null;

        vm.resetClientGroupSelect = true;
        vm.resetClusterSelect = true;
        vm.resetSmartCampaignSelect = true;
        $timeout(function () {
            vm.resetClientGroupSelect = false;
            vm.resetClusterSelect = false;
            vm.resetSmartCampaignSelect = false;
        }, 0);
    }

    function onChangeSmartCampaign($el, event) {
        vm.model.selectedSmartCampaign = $el.select2('data');
        vm.model.selectedSmartCampaign.type = DataSourceType.SMART_CAMPAIGN;
        vm.model.selectedClientGroup = null;
        vm.model.selectedCluster = null;
        vm.model.selectedClient = null;

        vm.resetClientGroupSelect = true;
        vm.resetClusterSelect = true;
        vm.resetClientSelect = true;
        $timeout(function () {
            vm.resetClientGroupSelect = false;
            vm.resetClusterSelect = false;
            vm.resetClientSelect = false;
        }, 0);
    }

    function onChangeClientGroup($el, event) {
        vm.model.selectedClientGroup = $el.select2('data');
        vm.model.selectedClientGroup.type = DataSourceType.CLIENT_GROUP;
        vm.model.selectedClient = null;
        vm.model.selectedCluster = null;
        vm.model.selectedSmartCampaign = null;

        vm.resetClientSelect = true;
        vm.resetClusterSelect = true;
        vm.resetSmartCampaignSelect = true;
        $timeout(function () {
            vm.resetClientSelect = false;
            vm.resetClusterSelect = false;
            vm.resetSmartCampaignSelect = false;
        }, 0);
    }

    function onChangeCluster($el, event) {
        vm.model.selectedCluster = $el.select2('data');
        vm.model.selectedCluster.type = DataSourceType.CLUSTER;
        vm.model.selectedClient = null;
        vm.model.selectedClientGroup = null;
        vm.model.selectedSmartCampaign = null;

        vm.resetClientGroupSelect = true;
        vm.resetClientSelect = true;
        vm.resetSmartCampaignSelect = true;
        $timeout(function () {
            vm.resetClientGroupSelect = false;
            vm.resetClientSelect = false;
            vm.resetSmartCampaignSelect = false;
        }, 0);
    }

    function onChangeUsers($el) {
        vm.model.users = $el.select2('data');
    }

    /**
     *
     * @param {ReportStudioGalleryPageModel} report
     * @private
     */
    function _setUpSelectOptions(report) {
        vm.clientSelectOptions = ExportBuilderDashboardUIModelFactory.getPanelClientSelectOption();
        vm.clientGroupSelectOptions = ExportBuilderDashboardUIModelFactory.getPanelClientGroupSelectOption();
        vm.userOptions = ExportBuilderDashboardUIModelFactory.getPanelUsersSelectionOption();

        if (vm.canShowSmartCampaignOption) {
            vm.smartCampaignsOptions = ExportBuilderDashboardUIModelFactory.getPanelSmartCampaignSelectOption();
        }

        if (vm.canShowClusterOptions()) {
            vm.clusterSelectOptions = ExportBuilderDashboardUIModelFactory.getPanelClusterOption();
        }

        if (report.client) {
            vm.model.selectedClient = {...report.client, type: UserType.CLIENT};
        }
    }

    function toggleComparison() {
        var $datePicker = vm.$dateRangePicker.data('daterangepicker');
        var $comparisonDatePicker = vm.$comparisonDateRangePicker.data('daterangepicker');

        AppFactory.toggleComparisonDateRange(vm.isComparing);

        vm.dateOptions.startDate = $datePicker.startDate;
        vm.dateOptions.endDate = $datePicker.endDate;
        initDatePicker();

        var comparisonPeriod = DateRangeFactory.calculatePeriod(
            $datePicker.startDate,
            $datePicker.endDate,
            RelativeDateRange.DEFAULT,
            RelativeDateRange.PRIOR_PERIOD
        );

        vm.comparisonDateOptions.startDate = comparisonPeriod.start;
        vm.comparisonDateOptions.endDate = comparisonPeriod.end;

        DateRangeFactory.setComparisonDatePicker(
            vm.dateOptions,
            vm.comparisonDateOptions,
            vm.$dateRangePicker,
            vm.$comparisonDateRangePicker,
            setDateRange
        );

    }

    /**
     * @param options
     */
    function initDatePicker(options) {
        options = options || {};

        DateRangeFactory.setDatePicker(
            _.assign(vm.dateOptions, options),
            vm.comparisonDateOptions,
            vm.$dateRangePicker,
            vm.$comparisonDateRangePicker,
            setDateRange
        );
    }
    
    /**
     * @param options
     */
    function initComparisonDatePicker(options) {
        options = options || {};
        options.compareDateRangePickerId = ExportBuilderPanelConstants.EXPORT_BUILDER_COMPARE_DATE_RANGE_PICKER;

        DateRangeFactory.setComparisonDatePicker(
            vm.dateOptions,
            _.assign(vm.comparisonDateOptions, options || {}),
            vm.$dateRangePicker,
            vm.$comparisonDateRangePicker,
            setDateRange
        );
    }

    /**
     * Rebuilds all widgets and sets date on front end and back end
     * @param start
     * @param end
     * @param comparisonStart
     * @param comparisonEnd
     * @param relativeDateRange
     */
    function setDateRange(
        start,
        end,
        comparisonStart,
        comparisonEnd,
        relativeDateRange
    ) {
        if (!moment.unix(start).isValid()
            || !moment.unix(end).isValid()
            || (comparisonStart && !moment.unix(comparisonStart).isValid())
            || (comparisonEnd && !moment.unix(comparisonEnd).isValid())
        ) {
            console.error('must use unix timestamp for setDateRange');
            return;
        }
        // Refers to the shortcut ranges (7 Days, 30 Days, This Month, Last Month)
        vm.quickRelativeRange = relativeDateRange;

        // Set app date range
        AppFactory.setDateRange(start, end, relativeDateRange);
        AppFactory.setComparisonDateRange(comparisonStart, comparisonEnd, vm.isComparing);

        setDateRangeValues();
    }

    function onClose() {
        vm.isSendingMail = false;
        _close();
    }
    
    function canShowClusterOptions() {
        return AppFactory.getUser().type === UserType.SUPERADMIN
        || AppFactory.getUser().type === UserType.CLUSTERADMIN
    }

    function setDateRangeValues() {
        const dateRange = AppFactory.getDateRange();
        const comparisonDateRange = AppFactory.getComparisonDateRange();

        vm.dateRangeValue = dateRange.formatted.start + ' - ' + dateRange.formatted.end;
        vm.comparisonDateRangeValue = comparisonDateRange.formatted.start + ' - ' + comparisonDateRange.formatted.end;
    }

    function _close() {
        _resetState();
        UIFactory.hideModal('export-builder-quickexport-modal');
    }

    function _resetState() {
        vm.isActive = false;
        vm.isLoading = false;
        vm.model = ExportBuilderDashboardModelFactory.getPanelViewModel();
    }

    /**
     * @private
     */
    function _init() {
        _setUpSelectOptions(ExportBuilderAdminDataService.getQuickExportReport());
        vm.isActive = true;
        vm.brandMappings = AppFactory.getBrandMappings();

        $timeout(function () {

            vm.options = vm.options || {};
            vm.$dateRangePicker = $('input.current-range');
            vm.$comparisonDateRangePicker = $('input.comparison-range');

            var defaultOptions = DateRangeFactory.getDefaultOptions();
            defaultOptions.opens = 'left';
            defaultOptions.drops = 'down';

            var comparisonDefaultOptions = DateRangeFactory.getComparisonDefaultOptions();
            comparisonDefaultOptions.opens = 'right';
            comparisonDefaultOptions.drops = 'down';

            vm.dateOptions = _.assign(defaultOptions, vm.options);
            vm.comparisonDateOptions = _.assign(comparisonDefaultOptions, vm.options);

            initDatePicker();
            initComparisonDatePicker();
            vm.isComparing = AppFactory.getComparisonDateRange().enabled;

            setDateRangeValues();
        }, 500);
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderQuickExportModalEvents.OPEN, _init)
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderQuickExportModalEvents.OPEN, _init)
    }

    function resetSkipAttachment() {
        if (!vm.model.enable_ez_login) {
            vm.model.skip_attachment = false;
        }
    }
}