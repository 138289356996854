import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  addToUndoStack({}, mutation) {
    commits.undo.addToUndoStack(mutation);
  },
  addToDoStack({}, mutation) {
    commits.undo.addToDoStack(mutation);
  },
  clearUndoStack() {
    commits.undo.clearUndoStack();
  },
  clearDoStack() {
    commits.undo.clearDoStack();
  },
  popDoStack() {
    commits.undo.popDoStack();
  },
  popUndoStack() {
    commits.undo.popUndoStack();
  },
  updateCurrentOperationPromise({}, currentOperationPromise) {
    commits.undo.updateCurrentOperationPromise(currentOperationPromise);
  },
};
