'use strict';
import angular from 'angular';

angular.module('core.service.constants', [])

    .constant('DataViewName', {
        CAMPAIGN: 'cgn',
    })

    .constant('ServiceEntity', {
        SCORE_FIELD_NAME: 'score',
        LOW_SCORE_FIELD_NAME: 'low_score',
        MEDIUM_SCORE_FIELD_NAME: 'medium_score',
        HIGH_SCORE_FIELD_NAME: 'high_score',
        DATA_VIEW_COUNT_FIELD_NAME: 'data_view_count',
        TOTAL_DATA_VIEW_COUNT_FIELD_NAME: 'total_data_view_count',
        CLIENT_COUNT_FIELD_NAME: 'client_count',
        TOTAL_CLIENT_COUNT_FIELD_NAME: 'total_client_count'
    })

    .constant('OrderItemColumns', {
        ID: 'id',
        SERVICE_ID: 'service_id',
        SERVICE_NAME: 'service_name',
        CLIENT_ID: 'client_id',
        ITEM_ID: 'item_id',
        ITEM_LABEL: 'item_label',
        MAPPING_DATE: 'mapping_date'
    })

    .constant('CustomDataViewMessage', {
        IDS: ['33', '142']
    });