import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class UserAvatar extends BaseModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  display_name;

  /**
   * @var {string}
   */
  email;

  /**
   * @var {string}
   */
  status;

  /**
   * @var {string}
   */
  user_image_url;

  /**
   * @param model
   */
  constructor(model = {}) {
    super();
    this.id = model.id;
    this.display_name = model.display_name;
    this.email = model.email;
    this.status = model.status;
    this.user_image_url = model.user_image_url;
  }
}
