import ChartWidgetRenderService from '@/modules/ta/widget/services/types/ChartWidgetRenderService';
import { dataContext } from '@/modules/core/charts/events.helper';
import { PieChartDrawOption } from '@/modules/ta/widget/builder.constants';
import { color } from '@amcharts/amcharts5';
import GeoWidgetConfigDataModel from '@/modules/core/charts/am5/geo/models/GeoWidgetConfigDataModel';
import { USE_DATE_AXIS_AS_CATEGORY } from '@/modules/core/charts/am5/charts.constants';
import { BulletConfig } from '@/modules/core/charts/am5/base/models/BulletConfig';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export default class MapWidgetRenderService extends ChartWidgetRenderService {
  /**
   * @var {boolean}
   */
  isComparison;

  /**
   * @var {Object}
   */
  periodData;

  /**
   * @param index
   * @param metric
   * @param rawValue
   * @param rawMetric
   * @param dataFormat
   * @param mapconfig
   * @returns {GeoWidgetConfigDataModel}
   */
  getChartDefaultDatum(index, mapconfig, metric, rawValue, rawMetric, dataFormat) {
    return new GeoWidgetConfigDataModel({
      index,
      mapconfig,
      metric,
      rawValue,
      rawMetric,
      dataFormat,
    });
  }

  /**
   * @param datum
   * @returns {String}
   */
  getDataMetric(datum) {
    return this._isCurrentGroupedColumnDate()
      ? this._getVisibleDate(datum)
      : datum[this.getCurrentGroupByField()];
  }

  /**
   * @param datum
   * @param total
   * @returns {number}
   */
  getDatumPercent(datum, total) {
    return Number.parseFloat((datum.rawValue / total) * 100).toFixed(2);
  }

  /**
   * @param chartData
   * @param total
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getPercentUpdatedData(chartData, total) {
    const otherPercent = this.getDrawOptions().other_percent;
    return chartData.map((datum) => {
      datum.percent = this.getDatumPercent(datum, total);
      if (otherPercent) {
        datum.isOther = datum.percent <= otherPercent;
      }
      return datum;
    });
  }

  /**
   * @param chartData
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getNormalizedChartData(chartData) {
    return chartData.map((chartDatum) => {
      chartDatum.value = 1;
      return chartDatum;
    });
  }

  /**
   * @param chartData
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getUnNormalizedChartData(chartData) {
    return chartData.map((chartDatum) => {
      chartDatum.value = chartDatum.rawValue;
      return chartDatum;
    });
  }

  /**
   * @param datum
   * @param metricSuffix
   * @returns {string}
   */
  getDatumMetricLabel(datum, metricSuffix) {
    return metricSuffix
      ? `${this.getDataMetric(datum)} ${metricSuffix}`
      : this.getDataMetric(datum);
  }

  /**
   * @param field
   * @param metricSuffix
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getChartDataFromPeriodData(field, metricSuffix) {
    return this.periodData.map((datum, index) =>
      this.getChartDefaultDatum(
        index,
        {
          geocode0: datum.geocode0,
          geocode1: datum.geocode1,
          geo_longitude: datum.geo_longitude,
          geo_latitude: datum.geo_latitude,
        },
        this.getDatumMetricLabel(datum, metricSuffix),
        datum[field],
        this.getDataMetric(datum),
        this.nonGroupedColumns[0].format
      )
    );
  }

  /**
   * @param chartData
   * @param total
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getDrawOptionAppliedChartData(chartData, total) {
    const { is_normalized } = this.getDrawOptions();
    let newChartData = this.getPercentUpdatedData(chartData, total);

    newChartData = is_normalized
      ? this.getNormalizedChartData(newChartData)
      : this.getUnNormalizedChartData(newChartData);
    return newChartData.filter((datum) => !datum.isOther);
  }

  /**
   * @param field
   * @param total
   * @param metricSuffix
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getChartDataForSingleMetric(field, total, metricSuffix) {
    const chartData = this.getChartDataFromPeriodData(field, metricSuffix);
    return this.getDrawOptionAppliedChartData(chartData, total);
  }

  /**
   * @param index
   * @param column
   * @returns {Array<string|SliceWidgetConfigDataModel>}
   */
  getChartDefaultDatumEntry(index, column) {
    return [column.field, this.getChartDefaultDatum(index, column.label, 0, false, column.format)];
  }

  /**
   * @returns {{}}
   */
  getChartDataFromNonGroupedColumns() {
    const defaultDatumEntries = this.nonGroupedColumns.map((column, index) =>
      this.getChartDefaultDatumEntry(index, column)
    );
    return Object.fromEntries(defaultDatumEntries);
  }

  /**
   * @param chartDataByField
   * @param field
   * @param fieldIndex
   * @param total
   *

  /**
   * @param total
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getChartDataForMultipleMetrics(total) {
    const chartDataByField = this.getChartDataFromNonGroupedColumns();
    return this.getDrawOptionAppliedChartData(Object.values(chartDataByField), total);
  }

  /**
   * @returns {Number}
   */
  getChartDataTotal() {
    return this.periodData.reduce(
      (dataTotal, datum) =>
        dataTotal +
        this.nonGroupedColumns.reduce(
          (metricTotal, column) => metricTotal + Number(datum[column.field]),
          0
        ),
      0
    );
  }

  /**
   * @returns {{priorPeriodData: (*|{}), currentPeriodData: *}}
   */
  getChartData() {
    const currentPeriodData = this.setLegendColor(this.splitCurrentData(false));
    const priorPeriodData = this._hasComparisonData
      ? this.setLegendColor(this.splitCurrentData(true))
      : {};
    return { currentPeriodData, priorPeriodData };
  }

  setLegendColor(dataPrior) {
    return dataPrior;
  }

  splitCurrentData(comparisonStatus = false) {
    if (comparisonStatus) {
      this.periodData = this.priorPeriodData;
    }

    return this.periodData.map((data) => ({ id: data.geocode0?.toUpperCase(), ...data }));
  }

  /**
   * @returns {string}
   */
  getTooltipTextTemplate() {
    return PieChartDrawOption.SHOW_TOOLTIP;
  }

  /**
   * @returns {{any}}
   */
  getSeries(column, columnIndex, axisIndex, isComparison = false) {
    const drawOptions = this.getDrawOptions();

    let categoryAxisIndex = 0;
    if (this._isCurrentGroupedColumnDate() && isComparison && !USE_DATE_AXIS_AS_CATEGORY) {
      categoryAxisIndex = 1;
    }

    let category;
    if (!USE_DATE_AXIS_AS_CATEGORY) {
      category = isComparison
        ? this.appendComparisonSuffixToField(this.getCurrentGroupByField())
        : this.getCurrentGroupByField();
    } else {
      category = this.getCurrentGroupByField();
    }

    return {
      axisIndex,
      categoryAxisIndex,
      name: column.label,
      value: column.field,
      category,
      isCategoryDate: false,
      column,
      showMetricLabels: drawOptions.show_metric_labels,
      color: this.getChartPalette(columnIndex),
      opacity: isComparison ? 0.6 : 1,
      tooltipDisabled: !drawOptions.has_tooltip,
      dataItemFormat: column?.format,
      strokeColor: isComparison ? color(0xffffff) : null,
      seriesType: this.getSeriesType(),
      bullet: new BulletConfig({
        enabled: true,
        fill: this.getChartPalette(columnIndex),
        fillOpacity: isComparison ? 0.6 : 1,
        shape: drawOptions.bullets_shape,
        rotation: drawOptions.is_rotated ? 90 : 0,
      }),
    };
  }

  /**
   * @param hasComparison
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @returns {[]}
   */
  getAllSeriesForColumn(hasComparison, column, columnIndex, axisIndex) {
    const allSeries = [];
    allSeries.push(this.getSeries(column, columnIndex, axisIndex));
    return allSeries;
  }

  /**
   * @returns {{valueLabelTemplate: string}}
   */
  getChartLegend() {
    return {
      ...super.getChartLegend(),
      valueLabelTemplate: PieChartDrawOption.SHOW_RAW_VALUE,
    };
  }

  /**
   * @param isComparison
   */
  setIsComparison(isComparison) {
    this.isComparison = isComparison;
  }

  setPeriodData() {
    this.periodData = this.isComparison ? this.priorPeriodData : this.currentPeriodData;
  }

  setup(metadata) {
    super.setup();
    this.setIsComparison(metadata.isComparison);
    this.setPeriodData();
  }

  /**
   * @returns {null|Array}
   */
  getChartTitles() {
    return this.canUndoDrillDown() ? [this.getUndoDrillDownLabel()] : null;
  }

  /**
   * @returns {{}}
   */
  getChartConfigProperties() {
    const { depth, angle } = this.getDrawOptions();
    return {
      ...super.getChartConfigProperties(),
      labels: this.hasComparison() ? [this.getPeriodLabel()] : null,
      depth,
      angle,
      cursorOverStyle: this.getCursorOverStyle(),
      isInlineDrillDown: this.isInlineDrillDown(),
      widgetData: this._widget,
      comparisonEnabled: this.isComparison,
    };
  }

  isInlineDrillDown() {
    return this.isMultiGrouped() && this.getGroupedColumns().length > 1;
  }

  /**
   * @param isDrillDownNotApplied
   * @returns {boolean}
   */
  canDrillDown(isDrillDownNotApplied = true) {
    return (
      super.canDrillDown() &&
      (this.getGroupByIndexOnDrillDown() !== this.currentGroupByIndex || isDrillDownNotApplied)
    );
  }

  /**
   * @param event
   * @returns {DrillDownConfig}
   */
  drillDown(event) {
    const categoryIndex = dataContext(event)?.index;
    this.setCurrentGroupByIndex(this.getGroupByIndexOnDrillDown());
    const drillDownConfig = this.getDrillDownConfig(categoryIndex, this.periodData);
    // Below we push and return last element in drillDownConfigStack
    this.drillDownConfigStack.push(drillDownConfig);
    return drillDownConfig;
  }

  getAllSeries() {
    let allSeries = [];
    const hasComparison = this.hasComparison();
    const { is_normalized: isNormalized } = this.getDrawOptions();
    if (isNormalized) {
      this.nonGroupedColumns.forEach((column, index) => {
        allSeries = allSeries.concat(
          this.getAllSeriesForColumn(hasComparison, column, index, index)
        );
      });
    } else if (this.isContainDurationMetric()) {
      const durationColumnIndex = this.getDurationColumnIndex();
      const nonDurationColumnIndex = this.getNonDurationColumnIndex();
      const containsOnlySingleValueAxis =
        durationColumnIndex === -1 || nonDurationColumnIndex === -1;
      if (containsOnlySingleValueAxis) {
        this.nonGroupedColumns.forEach((column, index) => {
          allSeries = allSeries.concat(this.getAllSeriesForColumn(hasComparison, column, index, 0));
        });
      } else {
        const durationAxisIndex = durationColumnIndex > nonDurationColumnIndex ? 1 : 0;
        this.nonGroupedColumns.forEach((column, index) => {
          allSeries = allSeries.concat(
            this.getAllSeriesForColumn(
              hasComparison,
              column,
              index,
              column.isFormatTime() ? durationAxisIndex : !durationAxisIndex
            )
          );
        });
      }
    } else {
      this.nonGroupedColumns.forEach((column, index) => {
        allSeries = allSeries.concat(this.getAllSeriesForColumn(hasComparison, column, index, 0));
      });
    }

    return allSeries;
  }

  isContainDurationMetric() {
    return this.nonGroupedColumns.some((column) => column.format === ColumnFormat.FORMAT_TIME);
  }

  /**
   * @returns {[]}
   */
  getChartSeries() {
    return this.getAllSeries();
  }
}
