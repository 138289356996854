'use strict';
import angular from 'angular';
import $ from 'jquery';
import './scripts/filterset.ctrls';
import './scripts/filterset.directives';
import './scripts/filterset.services';

import filterSetListHtmlUrl from './views/filtersetlist.html';

angular.module('core.filterset', [
    'core.filterset.ctrls',
    'core.filterset.directives',
    'core.filterset.services'
])

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('filtersets', {
                parent: 'layout',
                url: '/filtersets',
                templateUrl: filterSetListHtmlUrl,
                controller: 'FilterSetListController'
            })
    }]);