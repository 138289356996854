'use strict';
import angular from 'angular';

angular.module('calculations.directives', [])

    .directive('myDirective', myDirective);

//
//  directive
//
myDirective.$inject = [];
function myDirective() {
    return {
        restrict: 'A',
        link: function(scope, el) {
        }
    }
}