'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

angular.module('widget.chart.ctrls', [])

    .controller('ChartWidgetController', ChartWidgetController);


/**
 * Chart controller for both preview and display
 * @ngInject
 */
function ChartWidgetController(
    $scope,
    $q,
    $WidgetEvents,
    ChartUtilFactory,
    $WidgetBuilderEvents,
    $AnnotationEvents,
    PubSub,
    $compile,
    ColumnFormat,
    MomentDateFormat,
    LoadingState,
    DataSourceType,
    ChartDataType,
    ChartPlotType,
    DrawOption,
    WidgetType,
    AppFactory,
    AnnotationFactory,
    DashboardContextService,
    WidgetFactory,
    WidgetUtilService,
    WidgetSortUtilService,
    WidgetCreateTypeFactory,
    WidgetCreateFactory,
    DrawOptionFactory,
    ChartAxisService,
    ChartFactory,
    WidgetBuilderUIService,
    WidgetBuilderService,
    GeoConfigurationType,
    WidgetBuilderConstants,
    ExportFactory,
    UserThemes,
    UIColor,
    UIFactory,
    ReportStudioTemplateDataService,
    DrawOptionPanelFactory
) {
    _registerEvents();

    var defaultMap = 'world';

    var requestId = 0;

    // INIT
    if (!$scope.$parent.isComparison) {
        defaultMap = $scope.widget.metadata.geo_code || defaultMap;
        setDefaults();
        setMetadataDefaults();
        buildChart();
    }

    /**
     * Set default values
     */
    function setDefaults() {
        $scope.chartData = [];
        $scope.dateRange = '';
        $scope.chartDataIndex = 0;
        $scope.canDrill = false;
        $scope.isRotated = false;
        $scope.isExporting = ExportFactory.getIsExporting();
        $scope.chartPalette = DashboardContextService.resolveChartPalette($scope.widget.metadata.chart_palette);
        $scope.isDashboard = DashboardContextService.resolvePageId() !== null;
        $scope.getChartId = getChartId;
        if ($scope.isExporting) {
            $scope.mapboxToken = ExportFactory.getMapboxToken();
        }
    }

    function getChartId() {
        const { widget } = $scope;
        return WidgetBuilderService.isCurrentWidgetInBuildMode(widget.id)
        && !WidgetBuilderService.getIsEditing() // isEditing means not creating a new widget.
            ? WidgetBuilderConstants.PREVIEW_CHART
            : widget.chartId || widget.id;
    }

    /**
     * Set default metadata values
     */
    function setMetadataDefaults() {
        $scope.$emit('chart:setMetadataDefaults', $scope.widget.metadata, $scope.widgetTypeId);
    }


    function setHelpState(options, columns) {
        $scope.state.helpNeeded = false;
        var checkGeoHelpNeeded = function() {
            var helpNeeded = false;
            if (options.plot_type == ChartPlotType.HEAT_MAP && (groupByColumn.field !== 'country' && options.map == 'world')) {
                helpNeeded = true;
                $scope.state.helpMessage = "For more accurate results, please do one of the following:";
                $scope.state.helpAction = ["change the plot type under draw options",
                    "select/click a country in order to group by " + groupByColumn.label];
            }
            return helpNeeded;
        };

        if ($scope.widgetTypeId == WidgetType.GEOCHART) {
            let metadata = $scope.widget.metadata;
            var groupByColumn = _.first(columns.grouped);

            metadata.sort_by = [_.first(metadata.data_columns.selected).field];
            metadata.sort_order = ['desc'];

            options.default_map = defaultMap;
            options.map_id = metadata.map_id || 'world' ;
            options.map = metadata.geo_code = metadata.geo_code ? metadata.geo_code : 'world';

            // set the zoom options to open the widget in the saved zoom state
            options.map_zoom = metadata.map_zoom;
            options.setMap = function(mapObj) {
                metadata.map_name = mapObj.title;
                metadata.map_id = mapObj.id ? mapObj.id.toLowerCase() : null;
                metadata.map_zoom = {};
                options.map = metadata.geo_code = mapObj.mapName;
                $scope.$evalAsync(function() {
                    $scope.state.helpNeeded = checkGeoHelpNeeded();
                });
            };

            options.setZoomOptions = function(zoomFactors) {
                metadata.map_zoom = zoomFactors;
            };
            $scope.$evalAsync(function() {
                $scope.state.helpNeeded = checkGeoHelpNeeded();
            });
        }
        else if (options[DrawOption.SHOW_EMPTY_DATES] && !options.dateFormats.canShowEmptyDates) {
            $scope.state.helpNeeded = true;
            $scope.state.helpMessage = "Draw Option Not Applied";
            $scope.state.helpAction = ["The current Time Grouping cannot display empty dates.",
                "The chart will be unaffected."];
        }
        else if (options.helpNeeded) {
            $scope.state.helpNeeded = options.helpNeeded.enabled;
            $scope.state.helpMessage = options.helpNeeded.helpMessage;
            $scope.state.helpAction = options.helpNeeded.helpAction;
        }
    }

    /**
     * Build CHART widget
     */
    function buildChart(redrawOptions) {
        var state = $scope.state;

        // Delete amcharts chart if delete function is available
        if (state.deleteChart) {
            state.deleteChart();
            delete state.deleteChart;
        }
        if ($scope.widget.has_live_integration && DashboardContextService.widgetHasGlobalFilter($scope.widget.metadata.data_source)) {
            state.loadingState = LoadingState.HAS_GLOBAL_FILTERS;
            return false;
        }

        if (state.isCreating && !state.canSave) {
            return false;
        }

        // Build table if can save widget or if not a preview (i.e. display), and not a comparison chart
        if (!$scope.$parent.isComparison) {
            var metadata = $scope.widget.metadata;

            state.loadingState = LoadingState.BUILDING;
            // Set all columns needed to build x-y axes
            var columns = {};
            columns.selected = metadata.data_columns.selected;
            columns.grouped = metadata.data_columns.grouped;
            columns.data_source = metadata.data_source;

            // Remove any non numeric columns that may have slipped in from other widget types
            columns.selected = _.filter(columns.selected, function(column) {
                return AppFactory.util.column.isNumeric(column.format);
            });

            // Set all config chart options
            var options = _.extend({}, metadata.draw_options);

            options.widgetId = $scope.widget.id;
            options.support_state = _.get(metadata.data_source, 'geo_columns.support_state');
            options.support_country = _.get(metadata.data_source, 'geo_columns.support_country');
            // Boolean to tell chart to execute addInitHandler empty data handler
            options.allEmptyData = false;
            options.isSample = WidgetFactory.useSampleData(metadata);

            // State settings
            options.isThumbPreview = state.isThumbPreview;
            options.isDrilling = state.isDrilling;

            if (DrawOptionFactory.resolveDrawOptionValue(DrawOption.IS_DARK_MODE, $scope.widget, metadata.builder)) {
                options.theme = 'dark';
            } else {
                options.theme = (_isReportStudioContext() || $scope.isExporting) ? 'light' : AppFactory.getUser().getThemeType();
            }
            options.redrawOptions = redrawOptions ? redrawOptions.selected : {};
            options.forceComparison = metadata.compare_to_prior_period;
            options.annotations = metadata.dynamic ? metadata.dynamic.annotations : [];
            options.chartPalette = DashboardContextService.resolveChartPalette(metadata.chart_palette);

            options.plot_type = metadata.draw_options[DrawOption.PLOT_TYPE];
            options.chartId = $scope.chartId;
            options.chartType = $scope.widgetTypeId;
            options.chartDataIndex = $scope.chartDataIndex = redrawOptions ?  redrawOptions.chartDataIndex : $scope.chartDataIndex;
            options.titles = redrawOptions ? redrawOptions.title : [];
            options.is_multi_grouped = metadata.is_multi_grouped = metadata.is_multi_grouped &&
                (options.chartType == WidgetType.BARCHART ||
                    options.chartType == WidgetType.LINECHART ||
                    options.chartType == WidgetType.PIECHART ||
                    options.chartType == WidgetType.COMBINATIONCHART);
            options.dateFormats = WidgetFactory.getDateFormats(metadata.time_grouping);
            options.time_grouping = metadata.time_grouping ? metadata.time_grouping.toLowerCase() : null;
            options.addChartListeners = addChartListeners;

            options.is_overriding_date_range = metadata.is_overriding_date_range;
            options.start_date_override = metadata.start_date_override;
            options.end_date_override = metadata.end_date_override;
            options.relative_date_range = metadata.relative_date_range;

            if (options.chartType === WidgetType.COMBINATIONCHART) {
                ChartFactory.setDefaultLineColumns(metadata, WidgetBuilderUIService.isActive());
            }
            options.line_columns = metadata.line_columns;

            if (WidgetUtilService.isSerialChart(options.chartType)) {
                ChartAxisService.setYAxisPositionForAllColumns(metadata);
            }
            options.move_y_axes = metadata.move_y_axes;

            // Set the default chart options
            // NOTE: options set above need to be set before default chart options since they are needed
            options = _.extend(options, ChartFactory.getOptions(options, columns));
            options.listeners = {};

            $scope.canDrill = (columns.grouped.length > $scope.chartDataIndex + 1) || options.type == ChartDataType.GEOCHART;
            $scope.isRotated = options.is_rotated;

            setHelpState(options, columns);

            state.loadingState = LoadingState.FETCHING;

            const currentRequestId = ++requestId;
            options.dataPromise = $q.resolve(ChartFactory.getChartData($scope.widget, options, $scope.chartDataIndex)).then(function(json) {
                state.loadingState = WidgetFactory.evaluateLoadingState(json.data, metadata, json.has_comparison_data);
                WidgetFactory.widgetLoaded($scope.widget);

                if (WidgetFactory.hasNoDataLoadingState(state.loadingState)) {
                    return null;
                }
                if (currentRequestId !== requestId) {
                    return; // Cancelled an outdated request
                }

                if ($scope.widget.metadata.draw_options.plot_type === ChartPlotType.MAPBOX) {
                    $scope.chartData = json;
                    state.loadingState = LoadingState.DONE;
                    return;
                }
                return json;
            }, function(error) {
                state.loadingState = WidgetFactory.evaluateLoadingState(error.data || [], metadata);
                WidgetFactory.widgetLoaded($scope.widget);
                return null;
            });

            options.renderCallback = function(chart) {
                // Once callback is ran, chart is done rendering
                // Need to clone the chart container element here and create the comparison div
                // This will be faster than having a comparison container on every chart widget, since it will not add extra listeners
                var $chartDiv = $('#' + chart.chartId);
                var comparisonId = chart.chartId + '-comparison';
                var $comparisonEl = $chartDiv.parent().find('#' + comparisonId);
                if ($comparisonEl) {
                    // Need to reset the comparison chart
                    $comparisonEl.remove();
                }

                if (!_.isEmpty($chartDiv) && chart && chart.comparisonData && chart.chartId && !options.isThumbPreview) {
                    var $comparisonChart;
                    if (!_.isEmpty($comparisonEl)) {
                        $comparisonChart = $comparisonEl;
                    }
                    else {
                        $comparisonChart = _.first($chartDiv).cloneNode();
                    }
                    $comparisonChart.id = comparisonId;
                    var $copyScope = $scope.$parent.$new();
                    $copyScope.widgetId = comparisonId;
                    $copyScope.chartId = comparisonId;
                    // compile the template, returns a callback
                    var linkFn = $compile($comparisonChart);
                    // link the compiled template with the scope.
                    // Compiling with current scope... May need to make a copy of parent scope and pass tt
                    linkFn($copyScope, function($cloneEl, scope) {
                        // Append the compiled element to the parent div
                        $chartDiv.parent().append($cloneEl);
                        scope.isComparison = true;
                    });

                }
                // Once we have all the data, evaluate if its all empty
                if (chart.dataProvider) {

                    if (_.isUndefined(chart.allEmptyData)) {

                        chart.allEmptyData = true;

                        if (chart.type === ChartDataType.PIE || chart.type === ChartDataType.FUNNEL) {
                            _.each(chart.dataProvider, function(datum) {
                                if (chart.allEmptyData && _.toNumber(datum.value) > 0) {
                                    chart.allEmptyData = false;
                                }
                            });
                        }

                        if (chart.type == ChartDataType.GEOCHART) {
                            var dataProvider = _.isEmpty(chart.dataProvider.images) ? chart.dataProvider.areas : chart.dataProvider.images;
                            _.each(dataProvider, function(datum) {
                                if (chart.allEmptyData && _.toNumber(datum.value) > 0) {
                                    chart.allEmptyData = false;
                                }
                            });
                        }

                        if (chart.type === ChartDataType.GAUGE) {
                            _.each(_.first(chart.axes).bands, function(datum) {
                                if (chart.allEmptyData && _.toNumber(datum.endValue) > 0) {
                                    chart.allEmptyData = false;
                                }
                            });
                        }

                        if (chart.type === ChartDataType.SERIAL) {
                            _.each(chart.dataProvider, function(datum) {
                                _.each(columns.selected, function (column) {
                                    var value = datum[column.field];
                                    if (chart.allEmptyData && _.toNumber(value) > 0) {
                                        chart.allEmptyData = false;
                                    }
                                });
                            });
                        }
                    }

                    if (chart.allEmptyData) {
                        // Since category has different ways of returning data
                        // We cannot conclude that all data is empty by all zero values
                        if (metadata.data_source.type === DataSourceType.CATEGORY_DATA
                            || metadata.data_source.type === DataSourceType.GOALS) {
                            state.loadingState = LoadingState.NO_DATA;
                        }
                        else {
                            state.loadingState = LoadingState.ALL_EMPTY_DATA;
                        }
                    }
                    else {
                        state.deleteChart = function() {
                            if (chart && (chart.chartCreated || chart.type === ChartDataType.GAUGE)) {
                                chart.clear();
                            }
                            chart = null;
                        };
                        state.loadingState = LoadingState.DONE;
                    }

                    if($scope.widget.metadata.type === WidgetType.GEOCHART) {
                        ChartUtilFactory.setMapColumnName($scope.widget);
                    }
                    if ($scope.widget.metadata.type === WidgetType.GEOCHART && parseInt($scope.widget.metadata.data_source.id) > 1000000) {
                        let type;
                        if ($scope.widget.metadata.data_columns.grouped && $scope.widget.metadata.data_columns.grouped[0]) {
                            const config = $scope.widget.metadata.data_columns.grouped[0].geo_config || {};
                            type = config.type;
                        }
                        if ($scope.widget.metadata.draw_options.plot_type === ChartPlotType.BUBBLE_MAP) {
                            const types = [GeoConfigurationType.COUNTRY, GeoConfigurationType.STATE, GeoConfigurationType.COUNTY];
                            if (type && types.indexOf(type) !== -1)
                            {
                                state.loadingState = LoadingState.GEO_PLOT_CHANGE_HEAT_MAP;
                            }
                        }
                        if ($scope.widget.metadata.draw_options.plot_type === ChartPlotType.HEAT_MAP) {
                            if (type && type === GeoConfigurationType.PIN)
                            {
                                state.loadingState = LoadingState.GEO_PLOT_CHANGE_BUBBLE_MAP;
                            }
                        }
                    }
                }
                if (!WidgetFactory.useSampleData(metadata)) {
                    options.addChartListeners(chart, options);
                }

                if (ExportFactory.chartReadyCounter.count === 0) {
                    ExportFactory.setChartsReady();
                }
            };

            options.drawCallback = function(chart) {
            };

            options.allLabels = redrawOptions ? redrawOptions.labels : [];

            options.drillDown = function(event, graph, itemClicked) {
                if (state.isAnnotating && state.isTimeGroupedWidget && state.isSeriesWidget) {
                    // Note: Moment does not handle monthly grouping correctly ('Jun 2016')
                    var dateFormat = options.show_empty_dates ? MomentDateFormat.DAYOFWEEK_MONTH_DAY_YEAR : options.dateFormats.momentFormat;
                    var categoryDate = moment.utc(itemClicked.category, dateFormat).format(MomentDateFormat.UNIX);

                    // Need to format the start and end date...
                    PubSub.emit($AnnotationEvents.WIDGET_DATE_CLICK, {
                        start: categoryDate,
                        end: options.getGuideDate(options.time_grouping, moment(itemClicked.category)).end
                    });
                    options.addChartGuide({start: categoryDate, end: categoryDate})
                } else if (!state.isEditing && graph.graph && graph.graph.isAnnotation) {
                    // Open annotation menu if clicking on an annotation graph item
                    AnnotationFactory.$init(state, {id: $scope.chartId, metadata: $scope.widget.metadata});
                } else if ($scope.canDrill && !state.loadingState && !options.is_multi_grouped) {
                    state.isDrilling = true;

                    //
                    // Cached chart object
                    //

                    // Grab current title to pass to cache
                    var currTitle = options.titles.length ? [{
                        text: options.titles[0].text,
                        size: 14,
                        id: 'drilldown-title',
                        bold: false
                    }] : [];

                    // Grab current config to store in cache
                    var currentConfigObj = {
                        labels: options.allLabels,
                        title: currTitle,
                        chartDataIndex: options.chartDataIndex,
                        selected: _.extend({}, options.redrawOptions)
                    };

                    // If there is already drill down data for the specific chart, add to it
                    if (amplify.lookupCache('drill_down_data-' + options.chartId)) {
                        // Dont want to store the selectedObj (that is next configuration.) Need to store the previous config...
                        var previousCharts = amplify.getFromCache('drill_down_data-' + options.chartId);
                        previousCharts.push(currentConfigObj);
                        amplify.safeStore('drill_down_data-' + options.chartId, previousCharts);
                    }
                    else {
                        amplify.safeStore('drill_down_data-' + options.chartId, [currentConfigObj]);
                    }

                    //
                    // New chart object
                    //

                    var selectedObj = {};
                    // Grab the field that the user clicked on
                    var selectedColumn = columns.grouped[$scope.chartDataIndex];
                    var selectedField = selectedColumn.groupby_name_field;
                    var drilldownColumn = columns.grouped[$scope.chartDataIndex + 1];

                    var redrawOptions;
                    // Always use log_date=formatted_log_date and send it to backend
                    if (selectedColumn.format === ColumnFormat.FORMAT_DATETIME) {
                        // If comparison is enabled, sending 'current date|prior date' (split by pipeline) to backend,
                        // like log_date=2018-04-01|2018-03-01
                        if (ChartFactory.isComparisonEnabled(options)) {
                            var currentDate;
                            var priorDate;
                            // For serial chart, we handle data differently (see widget.serialchart.services.js:403)
                            if (WidgetUtilService.isSerialChart(options.chartType)) {
                                currentDate = itemClicked.dataContext.formatted_log_date;
                                priorDate = itemClicked.dataContext.formatted_log_dateComparison;
                            } else {
                                currentDate = itemClicked.dataContext.current_period.formatted_log_date;
                                priorDate = itemClicked.dataContext.prior_period.formatted_log_date;
                            }
                            redrawOptions = currentDate + '|' + priorDate;
                        } else {
                            redrawOptions = itemClicked.dataContext.formatted_log_date;
                        }
                    } else {
                        redrawOptions = itemClicked.dataContext[selectedField];
                        if (_.isNil(redrawOptions)) {
                            selectedField = selectedColumn.groupby_name_field;
                            redrawOptions = itemClicked.dataContext[selectedField];
                        }
                    }
                    options.redrawOptions[selectedField] = redrawOptions;

                    var clickedItemTitle = itemClicked.category || itemClicked.title;

                    var specifier = selectedColumn.format === ColumnFormat.FORMAT_DATETIME ? ' on ' : ' for ';
                    selectedObj.title = [{
                        text: drilldownColumn.label.pluralize() + specifier + clickedItemTitle,
                        size: 12,
                        id: 'drilldown-title',
                        bold: true
                    }];
                    selectedObj.labels =  ChartFactory.getBackButtonConfig(options.chartId, $scope.widgetTypeId);

                    selectedObj.selected = options.redrawOptions;
                    selectedObj.chartDataIndex = ++$scope.chartDataIndex;

                    // Amcharts with the responsive option enabled need to be destroyed/cleared before building a new chart in its place.
                    if (options.responsive.enabled && !$scope.state.isThumbPreview) {
                        graph.destroy();
                    }
                    buildChart(selectedObj);
                }
            };

            amplify.subscribe('drillBack-' + options.chartId + '-' + $scope.widgetTypeId , function() {
                if (!state.loadingState && amplify.lookupCache('drill_down_data-' + options.chartId).length !== 0) {
                    state.isDrilling = false;
                    var prevCharts = amplify.getFromCache('drill_down_data-' + options.chartId);
                    var prevObj = prevCharts.pop();

                    amplify.safeStore('drill_down_data-' + options.chartId, prevCharts);

                    $scope.chartDataIndex = prevObj.chartDataIndex;

                    // Amcharts with the responsive option enabled need to be destroyed/cleared before building a new chart in its place.
                    _.find(AmCharts.charts, function(chart) {
                        return chart.chartId == options.chartId;
                    }).clear();

                    buildChart(prevObj);

                    // Amplify subscriber needs to return false in order to prevent any additional subscriptions from being invoked
                    return false;
                }
            });

            WidgetFactory.init(options, columns, $scope.widget);
        }
    }

    function addChartListeners(chart, chartOptions) {
        // Add guide for annotations with date annotations
        if ($scope.state.isTimeGroupedWidget && $scope.state.isSeriesWidget) {
            PubSub.on($AnnotationEvents.INIT_WIDGET + chart.chartId, function() {
                _initAnnotation(chart, chartOptions)
            });
        }
    }

    function _isReportStudioContext() {
        return  !_.isEmpty(ReportStudioTemplateDataService.getReport());
    }

    function _initAnnotation(chart, options) {
        _bindAnnotationEvents();

        options.addChartGuide = function(guideOptions) {
            if (!chart) {
                return;
            }

            var firstCategoryDate = _.first(chart.dataProvider).category;
            var lastCategoryDate = _.last(chart.dataProvider).category;
            var guideStart = moment.utc(moment.unix(guideOptions.start)).format(chart.dateFormats.momentFormat);
            var guideEnd = moment.utc(moment.unix(guideOptions.end)).format(chart.dateFormats.momentFormat);

            // Need to account for start and end dates being out of bounds
            if (moment(guideStart, chart.dateFormats.momentFormat).isBefore(firstCategoryDate)) {
                guideStart = firstCategoryDate;
            }
            if (moment(guideEnd, chart.dateFormats.momentFormat).isAfter(lastCategoryDate)) {
                guideEnd = lastCategoryDate;
            }


            var newGuide = {
                above: true,
                boldLabel: true,
                category: guideStart,
                color: chart.baseColor,
                dashLength: 2,
                expand: true,
                fillAlpha: .2,
                inside: false,
                label: 'Annotation',
                labelRotation: 0,
                lineAlpha: 1,
                position: 'top',
                toCategory: guideEnd,
            };

            // Only one guide should be displayed at a time
            // NOTE: Guides are currently not used for anything else
            chart.categoryAxis.guides = [];
            chart.categoryAxis.addGuide(newGuide);
            chart.validateData();
        };

        function _addGuide(guideOptions) {
            options.addChartGuide(guideOptions);
        }

        function _clearChartGuides() {
            chart.categoryAxis.guides = [];
            chart.validateData();
        }

        function _bindAnnotationEvents() {
            PubSub.on('toggleAnnotationState', _toggleAnnotationState);
            PubSub.on($AnnotationEvents.ADD_GUIDE, _addGuide);
            PubSub.on($AnnotationEvents.CLOSE_PANEL, _removeAnnotationEvents);
            PubSub.on($AnnotationEvents.ADD_ANNOTATION, _addAnnotation);
            PubSub.on($AnnotationEvents.DELETE_ANNOTATION, _deleteAnnotation);
        }



        function _removeAnnotationEvents() {
            PubSub.off('toggleAnnotationState', _toggleAnnotationState);
            PubSub.off($AnnotationEvents.ADD_GUIDE, _addGuide);
            PubSub.off($AnnotationEvents.CLOSE_PANEL, _removeAnnotationEvents);
            PubSub.off($AnnotationEvents.ADD_ANNOTATION, _addAnnotation);
            PubSub.off($AnnotationEvents.DELETE_ANNOTATION, _deleteAnnotation);
            _clearChartGuides();
        }

        function _addAnnotation(annotation) {
            var validate = false;
            var selectedColumns = $scope.widget.metadata.data_columns.selected;
            var annotationStart = moment.unix(annotation.start_date).utc().format(chart.dateFormats.momentFormat);
            var annotationEnd = moment.unix(annotation.end_date).utc().format(chart.dateFormats.momentFormat);

            _.each(chart.dataProvider, function(chartData, index) {
                // Search each category for a date fits with the new annotation date.
                var categoryDate = moment(chartData.category, chart.dateFormats.momentFormat);
                if (categoryDate.isBetween(annotationStart, annotationEnd)
                    || categoryDate.isSame(moment(annotationStart, chart.dateFormats.momentFormat))
                    || categoryDate.isSame(moment(annotationEnd, chart.dateFormats.momentFormat))) {

                    // Find the max value to display annotation above columns
                    var maxValue;
                    if (chart.is_multi_grouped) {
                        maxValue = _.max(_.map(chartData, function(datum) {
                            return _.toNumber(datum);
                        }));
                    }
                    else if (chart.is_normalized) {
                        // use the first selected metric column graph to display annotations
                        var firstMetricIndex = _.findIndex(selectedColumns, 'is_metric');
                        maxValue = _.toNumber(chartData[selectedColumns[firstMetricIndex].field]);
                    }
                    else {
                        maxValue = _.max(_.map(selectedColumns, function(column) {
                            if (column.is_metric) {
                                var key = column.field;
                                if (chartData.hasPriorDatum) {
                                    key += chart.is_multi_grouped ? '' : 'Comparison';
                                }
                                return _.toNumber(chartData[key]);
                            }
                            return 0;
                        }));
                    }
                    chart.dataProvider[index]['annotationValue'] = maxValue;

                    if (chart.dataProvider[index]['annotations'] && chart.dataProvider[index]['annotations'].length) {
                        chart.dataProvider[index]['annotations'].push(annotation)
                    }
                    else {
                        chart.dataProvider[index]['annotations'] = [annotation];
                    }
                    // Add annotations graph if first annotation
                    if (_.last(chart.graphs).valueField !== "annotationValue") {
                        chart.graphs.push(chart.annotationGraph);
                    }

                    validate = true;
                }
                // If annotation does not match date, and annotation is in dataprovider, it must be removed
                else if (chart.dataProvider[index]['annotations']) {
                    var removeAnnotation = _.find(chart.dataProvider[index]['annotations'], {'id': annotation.id});
                    if (removeAnnotation) {
                        // Delete annotation value, so that annotation bubble does not display on chart
                        _.remove(chartData.annotations, removeAnnotation);

                        // If no annotations to display
                        if (chartData.annotations.length < 1) {
                            delete chartData.annotationValue;
                            delete chartData.annotations;
                        }
                        validate = true;
                    }
                }

            });
            validate && chart.validateData();
        }

        function _deleteAnnotation(id) {
            var validate = false;
            _.each(chart.dataProvider, function(chartData) {
                if (chartData.annotations) {
                    var chartAnnotation = _.find(chartData.annotations, {'id': id});
                    if (chartAnnotation) {
                        _.remove(chartData.annotations, chartAnnotation);
                        // If no annotations to display
                        // Delete annotation value, so that annotation bubble does not display on chart
                        if (chartData.annotations.length < 1) {
                            delete chartData.annotationValue;
                            delete chartData.annotations;

                            // If no annotations, remove the annotation graph
                            if (chart.annotations && chart.annotations.length < 1 && _.last(chart.graphs).valueField === "annotationValue") {
                                chart.graphs.pop();
                            }
                        }
                        validate = true;
                    }
                }
            });
            if (validate) {
                chart.validateData();
            }
        }

        function _toggleAnnotationState() {
            if (chart.categoryAxis) {
                chart.categoryAxis.guides = [];
                chart.validateData();
            }
        }
    }


    /**
     * Build a fresh NEW chart
     */
    function buildNewChart() {
        $scope.chartDataIndex = 0;
        buildChart({
            selected: {},
            chartDataIndex: $scope.chartDataIndex,
            title: [],
            labels: []
        })
    }

    /**
     * Clears the current AmChart
     */
    function clearChart() {
        // Only clear chart if there is NO chart to show else simply build new one as above
        var clearChart = _.find(AmCharts.charts, {'chartId': $scope.chartId});
        if (clearChart) {
            clearChart.clear();
        }
        else {
            AmCharts.clear();
        }
    }

    /**
     * Handles if and how we should rebuild the chart widget
     */
    function handleRebuild() {
        var state = $scope.state;
        var metadata = $scope.widget.metadata;

        updateColumnOrder(metadata);

        // Widget type state needs to update if widget type has changed
        WidgetFactory.setStateForWidgetType(state, $scope.widgetTypeId, metadata);

        var hasSelectedColumns = _.size(metadata.data_columns.selected) > 0;
        var hasGroupedColumns = _.size(metadata.data_columns.grouped) > 0;
        var requiresGroupby = state.widgetType.requires_group_by;
        var hasOneColumn = _.size(metadata.data_columns.selected) === 1;

        // If a group by is selected, GAUGECHART needs to switch to a bar chart
        if (!hasSelectedColumns || (WidgetUtilService.isSerialChart(state.widgetType.id) && !hasGroupedColumns)) {
            // clearChart();
            // state.loadingState = LoadingState.INCOMPLETE;
        } else if (state.widgetType.id === WidgetType.GAUGECHART && hasOneColumn) {
            clearChart();
        } else if ((hasSelectedColumns && hasGroupedColumns) || (hasSelectedColumns && !requiresGroupby)) {
            buildNewChart();
        } else {
            clearChart();
        }
    }

    function updateColumnOrder(metadata) {
        var selectedObj = AppFactory.arrayToMemoizedObj(metadata.data_columns.selected, 'field');

        // Delete element from sort array if not in selected columns any more
        var index = _.findIndex(metadata.sort_by, function(field) {
            return !selectedObj[field];
        });
        WidgetSortUtilService.ensureColumnOrder(metadata, index);
    }

    var $widgetRebuildFn;
    function _registerEvents() {
        $widgetRebuildFn = $scope.$on($WidgetEvents.WIDGET_REBUILD, handleRebuild);
        $scope.$on($WidgetEvents.WIDGET_FETCH_STATUS, function (e, status) {
            $scope.state.loadingState = status;
        });
        $scope.$on('widget:setOrder', function(e, column, isMultiSorting) {
            ChartFactory.setOrder($scope.widget.metadata, column, isMultiSorting);
            handleRebuild();
        });

        $scope.$on('widget:setYAxisPosition', function(e, column) {
            ChartAxisService.setYAxisPosition($scope.widget.metadata, column);
            handleRebuild();
        });

        $scope.$on('$destroy', function() {
            ChartUtilFactory.resetAll();
            $widgetRebuildFn();
            PubSub.off($AnnotationEvents.INIT_WIDGET + $scope.chartId, _initAnnotation);
        });
    }

}
