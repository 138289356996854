import ChatGptSummariesResource from '@/modules/ta/chatgptsummaries/resources/ChatGptSummariesResource';
import { BaseListService } from '@/modules/core/list/services/BaseListService';

export class ChatGptSummariesListDataService extends BaseListService {
  constructor() {
    super(ChatGptSummariesResource);
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  async getData(params) {
    params = { ...params, is_deleted: false, is_gpt_widget_summary: false };

    return super.getData(params);
  }

  /**
   * @param summaryItem
   * @returns {Promise<AxiosPromise<*>>}
   */
  async saveItem(summaryItem) {
    return this.resource.saveItem(summaryItem, summaryItem.id);
  }

  /**
   * @param summaryItemId
   * @param params
   * @returns {Promise<ChatGptSummary>}
   */
  async getItem(summaryItemId, params = {}) {
    return super.getItem(summaryItemId, params);
  }

  /**
   * @param formData
   * @returns {Promise<AxiosResponse<*>>}
   */
  async generateChatGptSummary(formData = {}) {
    return ChatGptSummariesResource.generateChatGptSummary(formData);
  }

  /**
   * @param formData
   * @returns {Promise<AxiosResponse<*>>}
   */
  async generateDalleSummary(formData = {}) {
    return ChatGptSummariesResource.generateDalleSummary(formData);
  }

  /**
   * @param id
   * @returns {Promise<*>}
   */
  async deleteItem(id) {
    return ChatGptSummariesResource.deleteItem(id);
  }

  /**
   * @param formData
   * @returns {Promise<AxiosResponse<*>>}
   */
  async addExecutiveSummaryItem(formData = {}) {
    return ChatGptSummariesResource.addExecutiveSummaryItem(formData);
  }

  /**
   * @param id
   * @param formData
   * @returns {Promise<AxiosResponse<*>>}
   */
  async getLatestResponse(id, formData = {}) {
    return ChatGptSummariesResource.getLatestResponse(id, formData);
  }

  /**
   * @param id
   * @param formData
   * @returns {Promise<AxiosResponse<*>>}
   */
  async getLatestDalleResponse(id, formData = {}) {
    return ChatGptSummariesResource.getLatestDalleResponse(id, formData);
  }

  /**
   * @param summaryId
   * @returns {Promise<AxiosResponse<*>>}
   */
  async exportWidgetData(summaryId) {
    await ChatGptSummariesResource.exportWidgetData(summaryId);
  }

  /**
   * @param isNewThread
   * @param widgetId
   * @param userPrompt
   * @param summaryId
   *
   * @returns {Promise<axios.AxiosResponse<*>>}
   */
  async getChatGptWidgetSummary(
    isNewThread = false,
    widgetId = null,
    userPrompt = '',
    summaryId = null
  ) {
    let formData = { user_prompt: userPrompt };
    if (isNewThread) {
      formData = { ...formData, widget_id: widgetId, is_new_gpt_thread: isNewThread };
    }

    let summaryInfo;
    if (isNewThread) {
      summaryInfo = await this.generateChatGptSummary(formData);
    } else {
      summaryInfo = await this.getLatestResponse(summaryId, formData);
    }

    return summaryInfo;
  }

  /**
   * Fetches and returns executive summary pages of the passed reportId
   *
   * @param reportId
   * @returns {Promise<axios.AxiosResponse<*>>}
   */
  async getReportExecutiveSummaryPages(reportId = null) {
    return ChatGptSummariesResource.getReportExecutiveSummaryPages(reportId);
  }
}

export default new ChatGptSummariesListDataService();
