export class SessionRenderService {
  /**
   * @param key
   * @param returnExpirationDate
   * @returns {null|*}
   */
  static getValue(key, returnExpirationDate = false) {
    const stringValue = window.sessionStorage.getItem(key);
    if (stringValue !== null) {
      const sessionValue = JSON.parse(stringValue);
      const expirationDate = new Date(sessionValue.expirationDate);
      if (expirationDate > new Date()) {
        return returnExpirationDate ? sessionValue.expirationDate : sessionValue.value;
      }
      window.sessionStorage.removeItem(key);
    }
    return null;
  }

  /**
   * @param key
   * @param id
   * @param expirationInMin
   */
  static setValue(key, id, expirationInMin = 1) {
    const expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
    const newValue = {
      value: id,
      expirationDate: expirationDate.toISOString(),
    };
    window.sessionStorage.setItem(key, JSON.stringify(newValue));
  }
}
