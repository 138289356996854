import Enum from '@/modules/core/app/models/abstracts/Enum';

export const DISABLE = 'disable';

export const RoleReportingProfilePermission = {
  ONLY_ASSIGNED: 'only_assigned',
  ALL: 'all',
  SELECTION: 'selection',
};

export const RoleRoute = {
  ROLES: 'roles',
};

export const RolePathName = {
  ROLES: 'roles',
  ROLEFORM_NEW: 'roleform:new',
  ROLEFORM: 'roleform',
};

export const RoleTitles = ['Users', 'Roles'];

export const RoleUsageNames = ['user', 'role'];

export const RolePermissionValue = {
  NONE: 'none',
  READ_ONLY: 'read_only',
  ALL: 'all',
};

export const RestrictUserTypeRolePermissionValue = {
  NONE: 'none',
  BELOW: 'below',
  AT_AND_BELOW: 'at_and_below',
};

export const RolePermissionType = {
  ACCESS_OPTIONS: 'access_options',
  BOOLEAN: 'boolean',
};

export const RolePermissionGroup = {
  DATA_CONFIGURATION: 'data_configuration',
  AUTO_MAPPING: 'auto_mapping',
  DATA_TRANSFORMATION: 'data_transformation',
  DATA_EXPORT: 'data_export',
  DASHBOARDS: 'dashboards',
  REPORT_STUDIO: 'report_studio',
  REPORTING: 'reporting',
  PERFORMANCE_TRACKING: 'performance_tracking',
  LEADS_MANAGEMENT: 'leads_management',
  AUDIENCE: 'audience',
  ACCOUNTS: 'accounts',
  ORGANIZATION: 'organization',
  PLATFORM_ADMINISTRATION: 'platform_administration',
  ORDER_MANAGEMENT: 'order_management',
  WORKFLOW_MANAGEMENT: 'workflow_management',
};

export const RolePermissionGroupTitleMapping = {
  data_configuration: i18n.$t('Data Connection & Configuration'),
  auto_mapping: i18n.$t('Auto Assignment Rules'),
  data_transformation: i18n.$t('Data Transformation'),
  data_export: i18n.$t('Data Export'),
  dashboards: i18n.$t('Dashboards'),
  report_studio: i18n.$t('Report Studio'),
  reporting: i18n.$t('Reporting'),
  performance_tracking: i18n.$t('Performance Tracking'),
  leads_management: i18n.$t('Leads Management'),
  audience: i18n.$t('Audience'),
  accounts: i18n.$t('Your Business'),
  organization: i18n.$t('Access'),
  platform_administration: i18n.$t('Platform Management'),
  order_management: i18n.$t('Order Management'),
  workflow_management: i18n.$t('Workflow Management'),
};

export const RolePermissionKey = {
  CAN_READ_WRITE: 'can_read_write',
  CAN_ACCESS_ACORN: 'can_access_acorn',
  CAN_ACCESS_RIPLEY: 'can_access_ripley',
  CAN_ACCESS_ZEST: 'can_access_zest',
  CAN_ACCESS_SHEETS: 'can_access_sheets',
  CAN_ACCESS_GDS: 'can_access_gds',
  CAN_IMPERSONATE_OTHER_AGENTS: 'can_impersonate_other_agents',
  CAN_CLUSTER_ADMINS_CONNECT_SERVICES: 'can_cluster_admins_connect_services',
  CAN_PUBLISH_TO_LIBRARY: 'can_publish_to_library',
  CAN_EDIT_REPORTING_OPTIONS: 'can_edit_reporting_options',
  CAN_EDIT_DRAW_OPTIONS: 'can_edit_draw_options',
  CAN_READ_WRITE_MANUAL_UPLOAD: 'can_read_write_manual_upload',
  CAN_EDIT_WIDGET_ALERTS: 'can_edit_widget_alerts',
  CAN_EDIT_WIDGET_ANNOTATIONS: 'can_edit_widget_annotations',
  CAN_ACCESS_ROLES: 'can_access_roles',
  CAN_READ_WRITE_FILTER_SET: 'can_read_write_filter_set',
  CAN_READ_WRITE_CATALOGUE_SETTINGS: 'can_read_write_catalogue_settings',
  CAN_READ_WRITE_ORDER_CREATION: 'can_read_write_order_creation',
  CAN_READ_WRITE_ORDER_LIST: 'can_read_write_order_list',
  CAN_READ_WRITE_ORDER_DETAIL: 'can_read_write_order_detail',
  CAN_READ_WRITE_ORDER_QUICK_VIEW: 'can_read_write_order_quick_view',
  CAN_READ_WRITE_ORDER_STATUS: 'can_read_write_order_status',
  CAN_READ_WRITE_LINE_ITEM_CREATION: 'can_read_write_line_item_creation',
  CAN_READ_WRITE_LINE_ITEM_LEFT_NAV_LIST: 'can_read_write_line_item_left_nav_list',
  CAN_READ_WRITE_ORDER_DETAIL_LINE_ITEM_LIST: 'can_read_write_order_detail_line_item_list',
  CAN_READ_WRITE_LINE_ITEM_DETAIL: 'can_read_write_line_item_detail',
  CAN_READ_WRITE_LINE_ITEM_QUICK_VIEW: 'can_read_write_line_item_quick_view',
  CAN_READ_WRITE_LINE_ITEM_STATUS: 'can_read_write_line_item_status',
  CAN_READ_WRITE_CREATIVE_TAG: 'can_read_write_creative_tag',
  MANAGE_ACCESS_LISTS: 'manage_access_lists',
};

export class PredefinedRoleKey extends Enum {
  static DEFAULT_ROLE = 'default_role';

  static SALES = 'sales';

  static SALES_SUPPORT = 'sales_support';

  static AD_OPERATIONS = 'ad_operations';

  static SALES_MANAGER = 'sales_manager';

  static AD_OPERATIONS_MANAGER = 'ad_operations_manager';

  static VENDOR = 'vendor';

  static SUPERADMIN = 'superadmin';

  static VENDOR_RESTRICTED = 'vendor_restricted';

  static PARTNER = 'partner';

  static CLIENT = 'client';

  static FINANCE = 'finance';

  static VIEWER = 'viewer';

  static SALES_VIEWER = 'sales_viewer';

  static SUPER_ADMIN_ROLE = 'super_admin_role';

  static CLUSTER_ADMIN_ROLE = 'cluster_admin_role';

  static AGENT_ROLE = 'agent_role';

  static CLIENT_ROLE = 'client_role';

  static CLUSTER_ADMIN_WITH_MAPPINGS_ACCESS_ROLE = 'cluster_admin_with_mappings_access_role';

  /**
   *
   * @returns {Array<String>}
   */
  static getPredefinedUserTypeRoles() {
    return [
      PredefinedRoleKey.SUPER_ADMIN_ROLE,
      PredefinedRoleKey.CLUSTER_ADMIN_ROLE,
      PredefinedRoleKey.AGENT_ROLE,
      PredefinedRoleKey.CLIENT_ROLE,
      PredefinedRoleKey.CLUSTER_ADMIN_WITH_MAPPINGS_ACCESS_ROLE,
    ];
  }
}

export const DefaultRole = {
  ID: '1',
  PREDEFINED_ROLE_KEY: PredefinedRoleKey.DEFAULT_ROLE,
};

export const PredefinedRoleValues = {
  SUPER_ADMIN_ROLE: 'Super Admin Role',
  CLUSTER_ADMIN_ROLE: 'Business Unit Admin Role',
  AGENT_ROLE: 'Agent Role',
  CLIENT_ROLE: 'Client Role',
  CLUSTER_ADMIN_WITH_MAPPINGS_ACCESS_ROLE: 'Business Unit Admin With Assignments Access Role',
};
