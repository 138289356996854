'use strict';
import angular from 'angular';

class CategoryListOverviewController {

    /**
     * @ngInject
     */
    constructor(
        DesignFactory
    ) {
        this.DesignFactory = DesignFactory;
        this.DesignFactory.props.hasVariableHeightWidgets = true;
    }
}

angular.module('category.overview.ctrls', [])
    .controller('CategoryListOverviewController', CategoryListOverviewController);