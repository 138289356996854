import { ChartConfig } from '@/modules/core/charts/models/ChartConfig';
import { XYSeries } from '@/modules/core/charts/models/XYSeries';
import { Axis } from '@/modules/core/charts/models/Axis';

/**
 * @class XYChartConfig
 * @extends ChartConfig
 */
export class XYChartConfig extends ChartConfig {
  /**
   * @var {Array<XYSeries>}
   */
  series;

  /**
   * @var {Array<Axis>}
   */
  categoryAxis;

  /**
   * @var {Array<Axis>}
   */
  valueAxis;

  /**
   * If chart axis are exchanged
   * @var {Boolean}
   */
  isRotated;

  /**
   * If chart is full stacked chart
   * @var {Boolean}
   */
  isFullStacked;

  /**
   * If chart has axis value scroll bars
   * @var {Boolean}
   */
  hasValueScrollbar;

  /**
   * Enables Cursor for chart
   * @var {Boolean}
   * @default true
   */
  cursorEnabled;

  /**
   * Set true to show a default value axis when valueAxis not passed
   * @var {Boolean}
   * @default false
   */
  useDefaultValueAxis;

  /**
   * When true, tooltip is displayed on cursor and when false, tooltip is displayed on series.
   * @var {boolean}
   * @default true
   */
  isTooltipOnCursor;

  /**
   * Set true to show chart as a spark line
   * @var {Boolean}
   * @default false
   */
  isSparkLine;

  constructor(model = {}) {
    super(model);
    const {
      cursorEnabled = true,
      useDefaultValueAxis = false,
      isTooltipOnCursor = true,
      isSparkLine = false,
    } = model;
    this.series = model.series?.map((singleSeries) => new XYSeries(singleSeries));
    this.categoryAxis = model.categoryAxis?.map((axis) => new Axis(axis));
    this.valueAxis = useDefaultValueAxis
      ? [new Axis()]
      : model.valueAxis?.map((axis) => new Axis(axis));
    this.depth = model.depth;
    this.angle = model.angle;
    this.isRotated = model.isRotated;
    this.isFullStacked = model.isFullStacked;
    this.hasValueScrollbar = model.hasValueScrollbar;
    this.cursorEnabled = cursorEnabled;
    this.isTooltipOnCursor = isTooltipOnCursor;
    this.isSparkLine = isSparkLine;
  }
}
