import { isNil } from 'lodash';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { DataQuery } from '@/modules/core/app/constants/api.constants';
import { FilterType } from '@/modules/core/list/list.constants';

export class ListColumn {
  /**
   * @var {string}
   */
  field;

  /**
   * @var {string}
   */
  format;

  /**
   * @var {string}
   */
  label;

  /**
   * @var {number}
   */
  order;

  /**
   * @var {boolean}
   */
  visible;

  /**
   * @var {boolean}
   */
  sortable;

  /**
   * @var {boolean}
   */
  filterable;

  /**
   * @var {boolean}
   */
  numeric;

  /**
   * @var {Function}
   */
  customSort;

  /**
   *
   * @param  {Array}
   */
  values;

  /**
   * @var {Object}
   */
  foreign_mapping;

  /**
   * @var {Boolean}
   */
  is_hidden;

  /**
   * @var {Boolean}
   */
  is_filterable;

  /**
   * @var {Boolean}
   */
  is_sortable;

  /**
   * @var {boolean}
   */
  compare_as_value;

  /**
   * @var {boolean}
   */
  compare_inverted;

  /**
   * @var {Boolean}
   */
  is_primary_key;

  /**
   * @var {Boolean}
   */
  is_read_only;

  /**
   * @var {string}
   */
  filter_input_type;

  /**
   * @var {boolean}
   */
  is_required_in_datatable;

  /**
   * set custom formSelectOptions for the list filters.
   * @var {Object}
   */
  customFormSelectoptions;

  /**
   * @var {number}
   */
  width;

  /**
   * @var {boolean}
   */
  sticky;

  is_primary_name_field;

  constructor(column = {}) {
    this.field = column.field;
    this.format = column.format;
    this.label = column.label;
    this.order = column.order;
    this.values = column.values;
    this.visible = column.visible || false;
    this.sortable = column.sortable || false;
    this.filterable = column.filterable || false;
    this.numeric = column.numeric || false;
    this.customSort = column.customSort || undefined;
    this.foreign_mapping = column.foreign_mapping;
    this.is_hidden = column.is_hidden;
    this.is_filterable = column.is_filterable;
    this.is_sortable = column.is_sortable;
    this.compare_as_value = column.compare_as_value;
    this.compare_inverted = column.compare_inverted;
    this.is_primary_key = column.is_primary_key;
    this.is_read_only = column.is_read_only;
    this.filter_input_type = column.filter_input_type;
    this.is_required_in_datatable = column.is_required_in_datatable;
    this.customFormSelectoptions = column.customFormSelectoptions;
    this.width = column.width || undefined;
    this.sticky = column.sticky;
    this.is_primary_name_field = column.is_primary_name_field;
  }

  isFormatString() {
    if (this.hasForeignMapping()) {
      return false;
    }

    return (
      this.format === ColumnFormat.FORMAT_PHONE_NUMBER ||
      this.format === ColumnFormat.FORMAT_STRING ||
      this.format === ColumnFormat.FORMAT_ID
    );
  }

  isFormatInteger() {
    return (
      this.format === ColumnFormat.FORMAT_INTEGER ||
      this.format === ColumnFormat.FORMAT_DECIMAL ||
      this.format === ColumnFormat.FORMAT_PERCENT ||
      this.format === ColumnFormat.FORMAT_CURRENCY ||
      this.format === ColumnFormat.FORMAT_TIME
    );
  }

  isFormatNumerical() {
    return (
      this.format === ColumnFormat.FORMAT_INTEGER ||
      this.format === ColumnFormat.FORMAT_DECIMAL ||
      this.format === ColumnFormat.FORMAT_PERCENT ||
      this.format === ColumnFormat.FORMAT_CURRENCY
    );
  }

  isFormatTime() {
    return this.format === ColumnFormat.FORMAT_TIME;
  }

  isFormatEnum() {
    return this.format === ColumnFormat.FORMAT_ENUM;
  }

  isFormatTreeSelectArray() {
    return this.format === ColumnFormat.FORMAT_TREE_SELECT_ARRAY;
  }

  isFormatSet() {
    return this.format === ColumnFormat.FORMAT_SET;
  }

  isFormatDateTime() {
    return this.format === ColumnFormat.FORMAT_DATETIME;
  }

  isFormatDate() {
    return this.format === ColumnFormat.FORMAT_DATE;
  }

  isFormatBoolean() {
    return this.format === ColumnFormat.FORMAT_BOOLEAN;
  }

  isSingleSelectFilterInputType() {
    return this.filter_input_type === FilterType.SINGLE_SELECT;
  }

  isFormatLink() {
    return this.format === ColumnFormat.FORMAT_LINK;
  }

  isPrimaryKey() {
    return this.is_primary_key;
  }

  isFormatColor() {
    return this.format === ColumnFormat.FORMAT_COLOR;
  }

  hasForeignMapping() {
    return !isNil(this.foreign_mapping);
  }

  /**
   * @returns {boolean}
   */
  isFilterableForeignMapping() {
    if (!this.hasForeignMapping()) {
      return false;
    }

    if (!this.foreign_mapping.is_expression_mapping) {
      return true;
    }

    return !this.isMultiColumnQueryMapping();
  }

  /**
   * Check whether the query type involves multiple fields to fetch the data
   *
   * @returns {boolean}
   */
  isMultiColumnQueryMapping() {
    return [
      DataQuery.TYPE_JSON_CONCAT,
      DataQuery.TYPE_JSON_OBJ_AGGREGATE,
      DataQuery.TYPE_CONCAT_MULTI_COLUMN,
    ].includes(this.foreign_mapping.query_type);
  }

  isListFilterable() {
    return (
      !this.is_hidden &&
      this.is_filterable &&
      (this.isFormatString() ||
        this.isFormatInteger() ||
        this.isFormatEnum() ||
        this.isFilterableForeignMapping() ||
        this.isFormatBoolean())
    );
  }
}
