'use strict';
import angular from 'angular';
import './base/widget.datagrid.ctrls';
import './base/widget.datagrid.directives';
import './base/widget.datagrid.services';
import './base/widget.datagrid.models';
import './tagpicker/datagrid.tagpicker.module';

angular.module('widget.datagrid', [
    'widget.datagrid.ctrls',
    'widget.datagrid.directives',
    'widget.datagrid.services',
    'widget.datagrid.models',

    'datagrid.tagpicker'
]);