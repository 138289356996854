import ComboChartWidgetRenderService from '@/modules/ta/widget/services/types/ComboChartWidgetRenderService';

export class LegacyComboChartWidgetRenderService extends ComboChartWidgetRenderService {
  /**
   * @var Widget
   */
  _widget;

  /**
   * @var array
   */
  _chartPalette;

  /**
   * @var boolean
   */
  _hasComparisonData;

  /**
   * @var boolean
   */
  _isExporting;

  /**
   * @param widget
   * @param chartPalette {array}
   * @param hasComparisonData {boolean}
   * @param isExporting
   */
  constructor(widget, chartPalette = [], hasComparisonData = false, isExporting = false) {
    super(widget.id);
    this._widget = widget;
    this._chartPalette = chartPalette;
    this._hasComparisonData = hasComparisonData;
    this._isExporting = isExporting;
  }

  /**
   * @returns {*}
   */
  getWidget() {
    return this._widget;
  }

  /**
   * @override
   * @returns {boolean}
   */
  isExporting() {
    return this._isExporting;
  }

  /**
   * @returns {*}
   */
  getFullChartPalette() {
    return this._chartPalette;
  }

  /**
   * @param columnIndex
   * @returns {string}
   */
  getChartPalette(columnIndex) {
    return this._chartPalette[columnIndex % this._chartPalette.length];
  }

  /**
   * @returns {{}}
   */
  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
    };
  }

  /**
   * @returns {boolean}
   */
  hasComparison() {
    return this._hasComparisonData;
  }
}
