import moment from 'moment';
import { MomentDateFormat } from '@/modules/core/daterange/daterange.constants';
import * as momenttimezone from 'moment-timezone';

export class DateUtil {
  static formatDate(date, format = this.getLocaleDateFormat(), utc = true) {
    return (utc ? moment.utc(date) : moment(date)).format(format);
  }

  static formatDateFromUnixTimeStamp(timestamp, format = this.getLocaleDateFormat()) {
    return timestamp ? moment.unix(timestamp).utc().format(format) : null;
  }

  /**
   * Returns locale date related to current year (If date is within the current year then it will skip the year)
   * @param timestamp
   * @returns {string}
   */
  static getLocaleRelativeDate(timestamp) {
    if (!timestamp) {
      return null;
    }
    const date = moment.unix(timestamp);
    const isSameYearAsCurrent = date.toDate().getFullYear() === new Date().getFullYear();
    return date.format(this.getLocaleDateFormat(isSameYearAsCurrent));
  }

  /**
   * @param skipYear It can be used when we have to print date of current year
   * @returns {string}
   */
  static getLocaleDateFormat(skipYear = false) {
    return skipYear ? MomentDateFormat.MONTH_DAY : MomentDateFormat.MONTH_DAY_YEAR;
  }

  /**
   *
   * @param timestamp1
   * @param timestamp2
   * @param unit
   * @returns {string}
   */
  static getDurationBetweenTimeStamps(timestamp1, timestamp2, unit = 'seconds') {
    return moment.duration(timestamp1 - timestamp2, unit).humanize();
  }

  /**
   *
   * @param timestamp1
   * @param timestamp2
   * @param unit
   * @returns {number}
   */
  static getDifferenceBetweenTimeStamps(timestamp1, timestamp2, unit = 'days') {
    const date1 = moment.unix(timestamp1);
    const date2 = moment.unix(timestamp2);
    return date1.diff(date2, unit);
  }

  /**
   *
   * @param date
   * @returns {number}
   */
  static formatDateToUnixTimeStamp(date) {
    return moment(date).unix();
  }

  /**
   * Return timestamp value of end of the day
   * @param date
   * @returns {number}
   */
  static startOfDay(date) {
    return moment
      .utc(moment(new Date(date)).format(MomentDateFormat.LL), MomentDateFormat.LL)
      .startOf('day')
      .unix();
  }

  /**
   * Return timestamp value of start of the day
   * @param date
   * @returns {number}
   */
  static endOfDay(date) {
    return moment
      .utc(moment(new Date(date)).format(MomentDateFormat.LL), MomentDateFormat.LL)
      .endOf('day')
      .unix();
  }

  /**
   *
   * @returns {number}
   */
  static getCurrentDateInUnix() {
    return moment().unix();
  }

  /**
   *
   * @param date
   * @returns {Date}
   */
  static getDateFromUnixTimeStamp(date) {
    return moment.unix(date).toDate();
  }

  /**
   *
   * @param date
   * @returns {moment.Moment}
   */
  static getMomentDateFromUnix(date) {
    return moment.unix(date);
  }

  /**
   *
   * @param unit
   * @returns {moment.Moment}
   */
  static getStartOf(unit) {
    return moment().startOf(unit);
  }

  /**
   *
   * @param unit
   * @returns {moment.Moment}
   */
  static getEndOf(unit) {
    return moment().endOf(unit);
  }

  /**
   *
   * @param timestamp
   * @param format
   * @returns {string}
   */
  static formatDateFromUnix(timestamp, format = this.getLocaleDateFormat()) {
    return moment.unix(timestamp).format(format);
  }

  /**
   *
   * @param date
   * @returns {Date}
   */
  static getDate(date) {
    return moment(date).toDate();
  }

  /**
   *
   * @param timestamp
   * @param number
   * @param unit
   * @param isUnix
   * @returns {number|*|moment.Moment}
   */
  static addTimeToTimeStamp(timestamp, number, unit, isUnix = true) {
    const date = isUnix ? moment.unix(timestamp) : moment(timestamp);
    const result = moment(date.add(number, unit));
    return isUnix ? result.unix() : result;
  }

  /**
   *
   * @param timestamp
   * @param number
   * @param unit
   * @param isUnix
   * @returns {number|*|moment.Moment}
   */
  static subtractTimeFromTimeStamp(timestamp, number, unit, isUnix = true) {
    const date = isUnix ? moment.unix(timestamp) : moment(timestamp);
    const result = moment(date.subtract(number, unit));
    return isUnix ? result.unix() : result;
  }

  /**
   *
   * @param date
   * @returns {*|moment.Moment}
   */
  static getMomentDate(date) {
    return moment(date);
  }

  /**
   *
   * @param duration
   * @param unit
   * @returns {number}
   */
  static getDurationInSeconds(duration, unit = MomentDateFormat.TIME) {
    return moment.duration(duration, unit).asSeconds();
  }

  /**
   *
   * @returns {string}
   */
  static getCurrentYear() {
    return moment().format(MomentDateFormat.YEAR);
  }

  /**
   * @returns {boolean}
   */
  static isFirstOfMonth(timezone) {
    if (timezone === null || timezone === undefined) {
      return moment().date() === 1;
    }

    return momenttimezone.tz(timezone).date() === 1;
  }
}
