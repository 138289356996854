'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('industrybenchmarkleadtracking.resources', [])

    .factory('IndustryBenchmarkLeadTrackingResource', IndustryBenchmarkLeadTrackingResource);

/**
 * @ngInject
 */
function IndustryBenchmarkLeadTrackingResource(
    Restangular,
    ColumnValueType
) {
    var resource = Restangular.all('industrybenchmarkleadtracking/data');
    var values = resource.one('values');

    return {
        getResource: getResource,
        getData: getData,
        getColumns: getColumns,
        getFieldValues: getFieldValues
    };

    /**
     *
     * @param queryParams
     * @returns {angular.IPromise<T>|*|undefined}
     */
    function getColumns(queryParams) {
        return resource.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get values for all possible distinct specific field
     * @param fields
     */
    function getFieldValues(fields) {
        return values.one(ColumnValueType.DISTINCT).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    function getResource() {
        return resource;
    }

    /**
     *
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        return resource.getList(queryParams);
    }
}
