'use strict';
import angular from 'angular';
import './messenger.constants';
import './messenger.components';
import './messenger.services';
import './messenger.models';


angular.module('core.messenger', [
    'core.messenger.constants',
    'core.messenger.components',
    'core.messenger.services',
    'core.messenger.models'
]);