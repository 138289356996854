'use strict';
import angular from 'angular';
import './widget.goal.components';
import './widget.goal.models';
import './widget.goal.services';
import './widget.goal.resources';

angular.module('widget.goal', [
    'widget.goal.components',
    'widget.goal.models',
    'widget.goal.services',
    'widget.goal.resources'
]);