import { XYChartConfig } from '@/modules/core/charts/models/XYChartConfig';
import { AxisRange } from '@/modules/core/charts/models/AxisRange';
import { Hand } from '@/modules/core/charts/models/Hand';

/**
 * @class GaugeChartConfig
 * @extends XYChartConfig
 * @extends ChartConfig
 */
export class GaugeChartConfig extends XYChartConfig {
  /**
   * @var {AxisRange[]}
   */
  axisRanges;

  /**
   * A list of ClockHand items displayed on this Gauge chart.
   * @var {Hand[]}
   */
  hands;

  /**
   * Inner radius of the Radar face. Percent value is relative to radius.
   * @var {Number}
   */
  innerRadius;

  /**
   * Starting angle of the Radar face. (degrees). Our charts have a starting angle of 180
   * @var {Number}
   */
  startAngle;

  /**
   * Ending angle of the Radar face. (degrees). Our charts have a ending angle of 360 ( or 0)
   * @var {Number}
   */
  endAngle;

  constructor(model = {}) {
    super(model);
    this.axisRanges = model.axisRanges?.map((aRange) => new AxisRange(aRange));
    this.hands = model.hands?.map((aHand) => new Hand(aHand));
    this.innerRadius = model.innerRadius;
    this.startAngle = model.startAngle;
    this.endAngle = model.endAngle;
  }
}
