export class CustomError extends Error {
  /** @var {String} */
  status;

  constructor(message, status) {
    super();
    this.message = message;
    this.status = status;
  }
}
