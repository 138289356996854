import { getters as storeGetters } from '@/modules/core/app/helpers/store';

export const getters = {
  /**
   * @param state
   * @returns {DateRange}
   */
  getDateRange(state) {
    return state.dateRange;
  },
  /**
   * @param state
   * @returns {{}}
   */
  getCurrentDateRange(state) {
    if (!state.dateRange) {
      return { startDate: null, endDate: null };
    }

    return {
      startDate: state.dateRange.start_date,
      endDate: state.dateRange.end_date,
    };
  },
  /**
   * @param state
   * @returns {{}}
   */
  getComparisonDateRange(state) {
    if (!state.dateRange || !state.dateRange.is_comparison_active) {
      return { startDate: null, endDate: null };
    }

    return {
      startDate: state.dateRange.comparison_start_date,
      endDate: state.dateRange.comparison_end_date,
    };
  },
  /**
   * @param state
   * @returns {{}}
   */
  isComparisonDateRangeEnabled() {
    return !!storeGetters.daterange.getComparisonDateRange().startDate;
  },
  /**
   * @param state
   * @returns {Object.<string, RelativeDateRange>}
   */
  getRelativeDateRanges(state) {
    return state.relativeDateRanges;
  },
};
