/* eslint-disable max-classes-per-file */

import { BaseTrackEvent } from '@/modules/core/app/models/BaseTrackEvent';

export class UserLogout extends BaseTrackEvent {
  /**
   * @var {string}
   */
  static name = 'User Menu - Logout';

  /**
   * @override
   * @param model
   * @returns {UserLogout}
   */
  static build() {
    return new UserLogout();
  }
}

export class UserSettings extends BaseTrackEvent {
  /**
   * @var {string}
   */
  static name = 'User Menu - Settings';

  /**
   * @override
   * @param model
   * @returns {UserSettings}
   */
  static build() {
    return new UserSettings();
  }
}

export class UserLoginAsUser extends BaseTrackEvent {
  /**
   * @var {string}
   */
  static name = 'User Menu - Login as User';

  /**
   * @var {string}
   */
  static user_id;

  /**
   * @var {string}
   */
  static user_name;

  /**
   * @var {string}
   */
  static email;

  /**
   * @var {string}
   */
  static data_profile;

  /**
   * @override
   * @param model
   * @returns {UserLoginAsUser}
   */
  static build(model) {
    return new UserLoginAsUser({
      user_id: model?.id,
      user_name: model?.display_name,
      user_type: model?.user_type,
      email: model?.email,
      data_profile: model?.reporting_profile_name,
    });
  }
}

export class UserChangeUserImage extends BaseTrackEvent {
  /**
   * @var {string}
   */
  static name = 'User Menu - Change User Image';

  /**
   * @override
   * @param model
   * @returns {UserChangeUserImage}
   */
  static build() {
    return new UserChangeUserImage();
  }
}

export class UserReturnToClassicTapclicks extends BaseTrackEvent {
  /**
   * @var {string}
   */
  static name = 'User Menu - Return to Classic TapClicks';

  /**
   * @var {string}
   */
  static feedback_text;

  /**
   * @param model
   * @returns {UserReturnToClassicTapclicks}
   */
  static build(model) {
    return new UserReturnToClassicTapclicks({
      feedback_text: model.feedback_text,
    });
  }
}
