import SerialWidgetRenderService from '@/modules/ta/widget/services/types/SerialWidgetRenderService';
import { LineChartConfig } from '@/modules/core/charts/am5/line/models/LineChartConfig';
import { LineTypes, SeriesType } from '@/modules/core/charts/am5/charts.constants';

export default class LineChartWidgetRenderService extends SerialWidgetRenderService {
  /**
   * @param config
   * @returns {LineChartConfig}
   */
  initConfig(config) {
    return new LineChartConfig(config);
  }

  /**
   *
   * @returns {string}
   */
  getSeriesType() {
    const lineType = this.getLineType();

    switch (lineType) {
      case LineTypes.LINE:
        if (this.isSmoothedLine()) {
          if (this.isRotated()) {
            return SeriesType.SMOOTHEDLINE_XY;
          }
          return SeriesType.SMOOTHEDLINE_X;
        }
        return SeriesType.LINE;
      case LineTypes.STEP_LINE:
        return SeriesType.STEP_LINE;
      default:
        return SeriesType.LINE;
    }
  }

  /**
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @param isComparison
   * @returns {{}}
   */
  getSeries(column, columnIndex, axisIndex, isComparison = false) {
    return {
      ...super.getSeries(column, columnIndex, axisIndex, isComparison),
      ...super.getLineSeries(column, columnIndex, isComparison),
      seriesType: this.getSeriesType(),
    };
  }

  /**
   * @returns {*}
   */
  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
      ...super.getLineChartConfigProperties(),
    };
  }
}
