import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  /**
   * @param {DataCalendarModel} model
   * @returns {Promise<*>}
   */
  async addModel({}, model) {
    return commits.datacalendar.addModel(model);
  },
  /**
   * @param {DataCalendarModel} model
   * @param id
   * @param value
   * @returns {Promise<*>}
   */
  async updateModel({}, { id, model }) {
    commits.datacalendar.updateModel({ id, model });
  },
  /**
   * @param id
   * @returns {Promise<*>}
   */
  async removeModel({}, id) {
    commits.datacalendar.removeModel(id);
  },
};
