'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('serviceclientmapping.models', [])

    .factory('ServiceClientMappingModelFactory', ServiceClientMappingModelFactory);

/**
 * @ngInject
 */
function ServiceClientMappingModelFactory(
    MessengerModelFactory
) {

    return {
        getDefaultState: getDefaultState,
        getServiceClientMapping: getServiceClientMapping
    };

    /**
     *
     * @constructor
     */
    function DefaultState() {
        var self = this;

        self.isReady = false;
        self.isDownloading = false;
        self.mappingSelectReady = false;
        self.isResultsActive = false;
        self.isAdding = false;
        self.isSaving = false;
    }

    /**
     *
     * @param id
     * @param name
     * @param service_id
     * @param service_field
     * @param client_field
     * @param match_type
     * @param truncate
     * @param truncate_size
     * @param user_id
     * @param naming_convention_id
     * @param naming_convention_name
     * @constructor
     */
    function ServiceClientMapping(
        id,
        name,
        service_id,
        service_field,
        client_field,
        match_type,
        truncate,
        truncate_size,
        user_id,
        naming_convention_id,
        naming_convention_name
    ) {
        var self = this;

        self.id = _.isNil(id) ? null : id;
        self.name = _.isNil(name) ? '' : name;
        self.service_id = _.isNil(service_id) ? null : service_id;
        self.service_field = _.isNil(service_field) ? null : service_field;
        self.client_field = _.isNil(client_field) ? null : client_field;
        self.match_type = _.isNil(match_type) ? 'exact' : match_type;
        self.truncate = _.isNil(truncate) ? 'none' : truncate;
        self.truncate_size = _.isNil(truncate_size) ? 0 : truncate_size;
        self.user_id = _.isNil(user_id) ? null : user_id;
        self.naming_convention_id = _.isNil(naming_convention_id) ? null : naming_convention_id;
        self.naming_convention_name = _.isNil(naming_convention_name) ? null : naming_convention_name;
    }

    /**
     *
     * @returns {DefaultState}
     */
    function getDefaultState() {
        return new DefaultState();
    }

    /**
     *
     * @param id
     * @param name
     * @param service_id
     * @param service_field
     * @param client_field
     * @param match_type
     * @param truncate
     * @param truncate_size
     * @param user_id
     * @param naming_convention_id
     * @param naming_convention_name
     * @returns {ServiceClientMapping}
     */
    function getServiceClientMapping(
        id,
        name,
        service_id,
        service_field,
        client_field,
        match_type,
        truncate,
        truncate_size,
        user_id,
        naming_convention_id,
        naming_convention_name
    ) {
        return new ServiceClientMapping(
            id,
            name,
            service_id,
            service_field,
            client_field,
            match_type,
            truncate,
            truncate_size,
            user_id,
            naming_convention_id,
            naming_convention_name
        );
    }


}