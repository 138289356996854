'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('connect.directives', [])

    .directive('updateDashboard', updateDashboard);

//
// Generate dashboard directive in connect services
//
updateDashboard.$inject = ['ConnectService', 'UserFactory', '$templateCache'];
function updateDashboard(ConnectService, UserFactory, $templateCache) {
    return {
        restrict: 'A',
        link: function(scope, el) {


            //By default this button is disabled until at least one client has been mapped
            UserFactory.getOnboardingStep().success(function(json) {
                if (json.data > 3) {
                    el.disableElement();
                }
            });
            el.click(function(e){
                e.preventDefault();
                el.disableElement();
                $.core.main.showAjaxMainLoading({text: 'Fetching data'});
                ConnectService.updateDashboard().success(function(json) {
                    if (json.status == 'error') {
                        el.enableElement();
                    }
                });
            });
        }
    }
}