export const BasePropsMixin = {
  props: {
    config: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    disableErrorMessages: {
      type: Boolean,
      default: false,
    },
    widgetType: {
      type: String,
    },
  },
  watch: {
    config(value) {
      this.refresh(value);
    },
  },
};
