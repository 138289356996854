'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.context.services', [])
    .factory('DashboardContextService', DashboardContextService);

/**
 * @ngInject
 */
function DashboardContextService(
    DesignFactory,
    DataSourceType,
    ReportStudioTemplateDataService,
    ExportBuilderDashboardService,
    UserType,
    DataSourceFactory
) {
    return {
        resolveChartPalette: resolveChartPalette,
        resolveWidgetMetadataDrawOptions: resolveWidgetMetadataDrawOptions,
        resolveSelectedEntity: resolveSelectedEntity,
        resolveSelectedEntityIdforType: resolveSelectedEntityIdforType,
        pageHasActiveFilter: pageHasActiveFilter,
        pageHasActiveClientFilter: pageHasActiveClientFilter,
        pageHasActiveClientGroupFilter: pageHasActiveClientGroupFilter,
        pageHasActiveClusterFilter: pageHasActiveClusterFilter,
        resolvePageId: resolvePageId,
        resolvePageClient: resolvePageClient,
        resolveWidgetParentId: resolveWidgetParentId,
        resolveWidgetFromId: resolveWidgetFromId,
        resolveFontSize: resolveFontSize,
        resolveFontSizeValue: resolveFontSizeValue,
        isExportingReportStudio: isExportingReportStudio,
        resolveQueryParam: resolveQueryParam,
        resolvePageEntityQueryParam: resolvePageEntityQueryParam,
        isDemoModeEnabled: isDemoModeEnabled,
        resolveSkipDrawOptions: resolveSkipDrawOptions,
        resolveReportStudioClientFilter: resolveReportStudioClientFilter,
        widgetHasGlobalFilter: widgetHasGlobalFilter,
        resolveFontType: resolveFontType,
        dashFilterAlreadyExists: dashFilterAlreadyExists,
        isWidgetAffectedByFilter: isWidgetAffectedByFilter,

    };

    /**
     * Determines which color palette to use (preferences || page || widget)
     * @param palette
     * @returns {*}
     */
    function resolveChartPalette(palette) {
        if (_isDashboardContext()) {
            if (_.isNil(palette)) {
                palette = DesignFactory.getCurrentPage().metadata.chart_palette;
            }
        } else if (_isReportStudioContext()) {
            if (_.isNil(palette)) {
                palette = ReportStudioTemplateDataService.getReport().metadata.chart_palette;
            }
        }
        return angular.copy(palette);
    }

    /**
     * Determines which widet draw options to use
     * @returns {*}
     */
    function resolveWidgetMetadataDrawOptions() {
        if (_isDashboardContext()) {
            return DesignFactory.getCurrentLayout().metadata.draw_options;
        } else if (_isReportStudioContext()) {
            return ReportStudioTemplateDataService.getReport().metadata.draw_options;
        }
    }

    /**
     *
     * @param widgetDataSource
     * @returns {boolean}
     */
    function widgetHasGlobalFilter(widgetDataSource) {
        if (_isReportStudioContext()) {
            const report = ReportStudioTemplateDataService.getReport();
            if (!_.isEmpty(report.global_filters)) {
                const globalFiltersKeys = Object.keys(report.global_filters);
                const {type,id} = widgetDataSource;
                const filterKey = `${type}|${id}|cgn`;
                const globalFilters = globalFiltersKeys.filter(
                    key =>
                        key === filterKey &&
                        report.global_filters[key].values &&
                        report.global_filters[key].values.length > 0
                );
                if (globalFilters.length !== 0) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Determines which selected entity to use
     * @returns {*}
     */
    function resolveSelectedEntity() {
        if (_isDashboardContext()) {
            return DesignFactory.getPageEntity();
        } else if (_isReportStudioContext()) {
            return ReportStudioTemplateDataService.getEntity();
        }
    }
    
    /**
     * Determines id of selected entity returns 0 if null
     * @returns {number}
     */
    function resolveSelectedEntityIdforType(selectedType = DataSourceType.CLIENT) {
        let id = 0;
        let type = null;
        
        if (_isDashboardContext()) {
            const pageEntity = DesignFactory.getPageEntity();
            ({ type = null, id = 0 } = pageEntity || {});
        } else if (_isReportStudioContext() && selectedType === DataSourceType.CLIENT ) {
            id = ReportStudioTemplateDataService.getReport().client_id ?? 0;
            type = id ? selectedType : null;
        }
    
        if (type === selectedType && id) {
            return Number(id);
        }
    
        return 0;
    }
    

    /**
     * Determines if there are active filters
     * @returns {*}
     */
    function pageHasActiveFilter() {
        if (_isDashboardContext()) {
            if (_.isNil(DesignFactory.getDashboardFilters())) {
                return false;
            }
            return pageHasActiveClientFilter() || pageHasActiveClientGroupFilter() || pageHasActiveClusterFilter();
        } else if (_isReportStudioContext()) {
            return false;
        }
    }

    /**
     * Determines if there is an active client filter
     * @returns {*}
     */
    function pageHasActiveClientFilter() {
        const filters = DesignFactory.getDashboardFilters();
        return !_.isEmpty(filters) &&
          !_.isEmpty(filters.client) &&
          !_.isEmpty(filters.client.values)
    }

    /**
     * Determines if there is an active client group filter
     * @returns {*}
     */
    function pageHasActiveClientGroupFilter() {
        const filters = DesignFactory.getDashboardFilters();
        return !_.isEmpty(filters) &&
          !_.isEmpty(filters.client_group) &&
          !_.isEmpty(filters.client_group.values)
    }

    /**
     * Determines if there is an active cluster filter
     * @returns {*}
     */
    function pageHasActiveClusterFilter() {
        const filters = DesignFactory.getDashboardFilters();
        return !_.isEmpty(filters) &&
          !_.isEmpty(filters.cluster) &&
          !_.isEmpty(filters.cluster.values)
    }

    /**
     * Send id of the contextual parent entity
     * @returns {*}
     */
    function resolvePageId() {
        if (_isDashboardContext()) {
            return DesignFactory.getCurrentPage().id;
        }
        return null;
    }

    function resolvePageClient() {
        if (_isDashboardContext()) {
            const pageEntity = DesignFactory.getPageEntity();
            if (pageEntity !== null && pageEntity.type === 'client') {
                return pageEntity.id;
            }
        }
        return null;
    }

    /**
     * Sets correct parent id to appropriate widget property
     * @param widget
     */
    function resolveWidgetParentId(widget) {
        if (_isDashboardContext()) {
            widget.layout_id = DesignFactory.getCurrentLayout().id;
            widget.report_id = null;
        } else if (_isReportStudioContext()) {
            widget.layout_id = null;
            widget.report_id = ReportStudioTemplateDataService.getReport().id;
        }
    }

    /**
     *
     * @param widgetId
     * @returns {*}
     */
    function resolveWidgetFromId(widgetId) {
        var widget;

        if (_isDashboardContext()) {
            widget = DesignFactory.getWidget(widgetId);
        } else if (_isReportStudioContext()) {
            widget = ReportStudioTemplateDataService.getWidgetElementFromWidgetId(widgetId).widget;
        }

        return angular.copy(widget);
    }

    /**
     * Retrieves appropriate font size
     *
     * @returns {number}
     */
    function resolveFontSize() {
        let fontSize = 12;

        if (_isDashboardContext()) {
            fontSize = DesignFactory.getFontSize();
        } else if (_isReportStudioContext()) {
            fontSize = ReportStudioTemplateDataService.getFontSize();
        }

        return fontSize;
    }

    /**
     * Retrieves appropriate font size
     *
     * @returns {number}
     */
    function resolveFontSizeValue() {
        let fontSizeValue = 14;

        if (_isDashboardContext()) {
            fontSizeValue = DesignFactory.getFontSizeValue();
        } else if (_isReportStudioContext()) {
            fontSizeValue = ReportStudioTemplateDataService.getFontSizeValue();
        }

        return fontSizeValue;
    }

    function isExportingReportStudio() {
        return DesignFactory.getIsExportingPage()
            && ReportStudioTemplateDataService.getIsActive()
    }

    /**
     * Helper function to see we are in a dashboard context
     * @returns {boolean}
     * @private
     */
    function _isDashboardContext() {
        return !_.isEmpty(DesignFactory.getCurrentPage());
    }

    /**
     * Helper function to see are in report studio context
     * @returns {boolean}
     * @private
     */
    function _isReportStudioContext() {
        return !_.isEmpty(ReportStudioTemplateDataService.getReport());
    }

    function resolveReportStudioClientFilter() {
        if (_isReportStudioContext()) {
            if (!_.isEmpty(ReportStudioTemplateDataService.getEntity())) {
                const report = ReportStudioTemplateDataService.getReport();
                const {id, type} = ReportStudioTemplateDataService.getEntity();
                let compareId;
                if (report.client_id) {
                    compareId = report.client_id;
                } else if (report.client) {
                    compareId = report.client.id;
                }
                if (type === UserType.CLIENT && id === compareId )
                    return id;
                return null;
            }
        }

        return null;
    }

    /**
     * Helper function to get entity id
     * @param queryParams
     * @returns {*}
     */
    function resolveQueryParam(queryParams) {
        if (_isDashboardContext()) {
            queryParams['page_id'] = DesignFactory.getCurrentPage().id;
        } else if (_isReportStudioContext()) {
            queryParams['report_id'] = ReportStudioTemplateDataService.getReport().id;
        }
    }

    /**
     * Helper function to get entity/client/client_group or cluster id
     *
     * @returns {*}
     */
    function resolvePageEntityQueryParam() {
        if (_isDashboardContext()) {
            if (!_.isEmpty(DesignFactory.getPageEntity())) {
              return DesignFactory.getPageEntity().type === DataSourceType.CLIENT_GROUP ? { client_group_id: DesignFactory.getPageEntity().id } : { client_id: DesignFactory.getPageEntity().id };
            } else if (pageHasActiveClientFilter()) {
                return {client_id: DesignFactory.getDashboardFilters().client.values[0].id};
            } else if (pageHasActiveClientGroupFilter()) {
                return {client_group_id: DesignFactory.getDashboardFilters().client_group.values[0].id};
            } else if (pageHasActiveClusterFilter()) {
                return {cluster_id: DesignFactory.getDashboardFilters().cluster.values[0].id};
            }
        } else if (_isReportStudioContext()) {
            if (!_.isEmpty(ReportStudioTemplateDataService.getEntity())) {
                const {id, type} = ReportStudioTemplateDataService.getEntity();
                if (type !== DataSourceType.SMART_CAMPAIGN) {
                    return {[`${type}_id`]: id};
                }
            }
        }

        return {};
    }
    function isDemoModeEnabled() {
        if (_isDashboardContext()) {
            return DesignFactory.isDemoModeEnabled();
        } else if (_isReportStudioContext()) {
            return ReportStudioTemplateDataService.isDemoModeEnabled();
        }

        return false;
    }

    function resolveSkipDrawOptions(widgetType) {
        if (_isReportStudioContext()) {
            return ExportBuilderDashboardService.getSkipDrawOptions(widgetType);
        }
        return ExportBuilderDashboardService.getSkipDrawOptionsDashboard(widgetType);
    }

    /**
     * Determines which font type to use for report studio
     * @returns {*}
     */
    function resolveFontType() {
        if (_isReportStudioContext()) {
            return ReportStudioTemplateDataService.getReport().metadata?.font_type;
        }
        return 'Lato';
    }

    /**
     * Check if the dashboard filter already exists for the current filter of a widget
     * @param currentWidgetId
     * @param currentFilter
     * @returns {boolean}
     */
    function dashFilterAlreadyExists(currentWidgetId, currentFilter) {
        let alreadyExists = false;
        let currentWidget = resolveWidgetFromId(currentWidgetId);
        _.each(DesignFactory.getAllWidgets(), function(widget) {
            // If already found a match we can stop looking
            if (alreadyExists || (currentWidget.has_live_integration !== widget.has_live_integration)) {
                return;
            }
            // Ignore yourself
            if (currentWidgetId == widget.id && currentFilter.expose_as_dash_filter) {
                return false;
            }
            let filterDataSource = currentWidget.metadata.data_source;
            let widgetDataSource = widget.metadata.data_source;

            // For goals there is no defined datasource and id should be null
            if (DataSourceFactory.isGoalDataSourceType(filterDataSource.type) && !_.isNull(filterDataSource.id)) {
                filterDataSource.id = null;
            }

            if (_isFilterCompatibleWithWidgetDataSource(widgetDataSource, filterDataSource)) {
                alreadyExists =_.some(widget.metadata.dynamic.filters, {
                    expose_as_dash_filter: true,
                    field: currentFilter.field
                });
            }
        });
        return alreadyExists;
    }

    /**
     * Current filter and widget have matching data source info
     * @param widgetDataSource
     * @param filterDataSource
     * @returns {*|boolean}
     */
    function _isFilterCompatibleWithWidgetDataSource(widgetDataSource, filterDataSource) {
        return widgetDataSource && filterDataSource && ((filterDataSource.type === DataSourceType.GOAL_DATA && widgetDataSource.type === filterDataSource.type) ||
            (widgetDataSource.type === filterDataSource.type
            && widgetDataSource.id === filterDataSource.id
            && widgetDataSource.data_view === filterDataSource.data_view));
    }

    /**
     * A Live dashboard filter can only be applied to a Live widget but cannot be applied to a Stored widget.
     * Similarly, a Stored dashboard filter can only be applied to a Stored widget but cannot be applied to a Live widget.
     * @param isLiveFilter
     * @param widget
     * @returns {*|boolean}
     */
    function _isFilterCompatibleWithWidget(isLiveFilter, widget) {
        return (
            (isLiveFilter && widget.has_live_integration) || (!isLiveFilter && !widget.has_live_integration)
        );
    }

    /**
     * Check if the widget can be affected by a filter by comparing the data sources and widget type
     * @param filterDataSource
     * @param isLiveFilter
     * @param widget
     * @returns {*|boolean}
     */
    function isWidgetAffectedByFilter(widget, filterDataSource, isLiveFilter) {
        return (
            _isFilterCompatibleWithWidgetDataSource(widget.metadata.data_source, filterDataSource)
            && _isFilterCompatibleWithWidget(isLiveFilter, widget)
        );
    }
}


