import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { ListState } from './list.state';
import { getters } from './list.getters';
import { mutations } from './list.mutations';
import { actions } from './list.actions';

VueXHelper.setStoreModule(Module.LIST, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new ListState(),
  getters,
  mutations,
  actions,
};
