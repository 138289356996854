'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('core.smartcampaign.directives', [])
    .directive('smartCampaignDataGrid', smartCampaignDataGrid);

/**
 * @ngInject
 */
function smartCampaignDataGrid(
    $timeout,
    DataGridFactory
) {
    return {
        restrict: 'A',
        scope: {
            selectedCampaigns: '=',
            widgetId: '<',
            onRowClicked: '<'
        },
        link: function (scope) {
            $timeout(function () {
                var $table = DataGridFactory.getTable(scope.widgetId);
                $table.on('click', 'tbody tr', function () {
                    if ( $(this).hasClass('selected') ) {
                        $(this).removeClass('selected');
                    }
                    else {
                        $(this).addClass('selected');
                    }
                    var data = $table.row(this).data();

                    var itemFound = _findItem(scope.selectedCampaigns, data);
                    if (_.isNil(itemFound)) {
                        scope.selectedCampaigns.push(data);
                    } else {
                        _.remove(scope.selectedCampaigns, findItemIterator(data));
                    }

                    scope.$evalAsync(function () {
                        scope.onRowClicked && scope.onRowClicked();
                    })
                });

                // On first load
                (function () {
                    $table.rows().data().each(function (data, index) {
                        var row = $table.rows().nodes()[index];
                        var itemFound = _findItem(scope.selectedCampaigns, data);
                        if (_.isNil(itemFound)) {
                            $(row).removeClass('selected');
                        } else {
                            $(row).addClass('selected');
                        }
                    });
                }())
            }, 200);

            function _findItem(selectedCampaigns, data) {
                return _.find(selectedCampaigns, findItemIterator(data));
            }
        },
        controller: function () {
            var self = this;
            self.findItemIterator = findItemIterator;
        }
    };

    /**
     * High order function for re-usable iterator in order to find an item
     * @param data to be compared with the collection
     * @returns {function(*): boolean}
     * @private
     */
    function findItemIterator(data) {
        return function (item) {
            return item.name === data.name
                && item.customer_id === data.customer_id;
        }
    }
}