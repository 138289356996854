'use strict';
import angular from 'angular';

angular.module('core.service.services')

    .service('ServiceOverviewFactory', ServiceOverviewFactory);

function ServiceOverviewFactory() {

    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        return dtOptions;
    }

    return {
        getDTOptions: getDTOptions
    }
}