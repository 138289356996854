'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import SeleniumHelper from '../../../../../seleniumtest';
import { DataSourceType } from 'coreModules/shared/scripts/app.constants';

angular.module('widget.create.ctrls')

// Select
    .controller('SelectColumnsConfigController', SelectColumnsConfigController)
    .controller('SelectConnectionConfigController', SelectConnectionConfigController)
    .controller('WidgetConnectionSelectController', WidgetConnectionSelectController)
    .controller('ColumnsSelectController', ColumnsSelectController)
    .controller('WidgetRemoveSelectedColumnController', WidgetRemoveSelectedColumnController)
    // Groupby
    .controller('GroupbyColumnsConfigController', GroupbyColumnsConfigController)
    .controller('ColumnsGroupbyController', ColumnsGroupbyController)
    .controller('WidgetRemoveGroupedColumnController', WidgetRemoveGroupedColumnController)
    // Time grouping
    .controller('WidgetTimeGroupingsController', WidgetTimeGroupingsController);

/**
 * Select columns initialization point
 * @ngInject
 */
function SelectColumnsConfigController(
    $scope,
    WidgetType,
    DrawOption,
    AppFactory,
    WidgetCreateFactory,
    ChartFactory,
    ChartPlotType,
    DisabledColumnState,
    ChartAxisService,
    SlidePanelFactory,
    DashboardContextService
) {
    $scope.WidgetType = WidgetType;
    $scope.DisabledColumnState = DisabledColumnState;

    $scope.displayColumnColor = displayColumnColor;
    $scope.onColorChange = onColorChange;
    $scope.displaySortNum = displaySortNum;
    $scope.getSortNum = getSortNum;
    $scope.getDisabledState = getDisabledState;
    $scope.toggleLine = toggleLine;
    $scope.onSortCallback = onSortCallback;
    $scope.sortableOptions = {update: onSortCallback};
    $scope.applySortOrder = applySortOrder;
    $scope.displayComboChartButtons = displayComboChartButtons;
    $scope.displayYAxisButton = displayYAxisButton;
    $scope.applyYAxis = applyYAxis;
    $scope.currentWidget = WidgetCreateFactory.getCurrentWidget();
    $scope.getBgColor = getBgColor;

    if (!$scope.state.isEditing) {
        $scope.selectedColumns = [];
    }

    function displayColumnColor() {
        if ($scope.state.isChartWidget) {
            return _.isEmpty($scope.groupedColumns[1]) || !$scope.isMultiGrouped;
        }
        return false;
    }

    /**
     * @param newColor
     * @param column
     * @param index
     */
    function onColorChange(newColor, column, index) {
        var currentChartPalette = _getCurrentPalette();
        var currentColor = getBgColor(column, index);
        var paletteIndex = _.indexOf(currentChartPalette, currentColor);
        // Update the current palette
        $scope.chartPalette = currentChartPalette;
        $scope.chartPalette[paletteIndex] = newColor;
        WidgetCreateFactory.$rebuildWidget();
    }

    function getDisabledState(column, $index) {

        if ($scope.state.widgetType.id === WidgetType.GAUGECHART) {
            if ($index > 1) {
                return DisabledColumnState.UNUSED_METRIC;
            } else if ($index === 0) {
                return DisabledColumnState.FIRST_SELECTED_METRIC;
            }
        } else if ($scope.state.widgetType.id === WidgetType.GEOCHART) {
            // If plot type is heat map, adjust
            var metadata = $scope.currentWidget.metadata;
            if ($index > 0 && metadata.draw_options[DrawOption.PLOT_TYPE] == ChartPlotType.HEAT_MAP) {
                return DisabledColumnState.UNUSED_METRIC;
            }
        } else if (!($scope.state.widgetType.id === WidgetType.DATAGRID
            || $scope.state.widgetType.id === WidgetType.LINECHART
            || $scope.state.widgetType.id === WidgetType.BARCHART
            || $scope.state.widgetType.id === WidgetType.COMBINATIONCHART)
            && $scope.state.widgetType.id !== WidgetType.ACCOUNTMANAGER) {
            // Non numeric metrics can only be displayed in data grids
            if (!AppFactory.util.column.isNumeric(column.format)) {
                return DisabledColumnState.UNUSED_METRIC;
            }
        }

        // Disabled if selected column is currently being grouped by
        var groupbyNameColumn = AppFactory.arrayToMemoizedObj($scope.groupedColumns, 'field')[column.groupby_id_field];

        return $scope.state.groupByColumnSelected && !_.isUndefined(groupbyNameColumn) ? DisabledColumnState.GROUP_BY : false;
    }

    function getBgColor(column, index) {
        if ($scope.groupedColumns.length
            && $scope.state.widgetType.id === WidgetType.LINECHART
            || $scope.state.widgetType.id === WidgetType.BARCHART
            || $scope.state.widgetType.id === WidgetType.COMBINATIONCHART) {
            var selectedColumns = _.filter($scope.selectedColumns, {is_groupby_name_field: false});
            index = _.indexOf(selectedColumns, column);
        }
        return index < 0 ? null : ChartFactory.getPaletteColor(index, _getCurrentPalette());
    }

    function toggleLine(isLine, column) {
        if (isLine != $scope.lineColumns[column.field]) {
            if (isLine && !$scope.lineColumns[column.field]) {
                $scope.lineColumns[column.field] = 1;
            } else if ($scope.lineColumns[column.field]) {
                delete $scope.lineColumns[column.field];
            }
            WidgetCreateFactory.$rebuildWidget();
        }
    }

    function onSortCallback() {
        WidgetCreateFactory.$rebuildWidget();
    }

    /**
     * Helper function to apply sort order
     * @param column
     * @param event
     */
    function applySortOrder(column, event) {
        column.sorting = column.sorting == 'asc' ? 'desc' : 'asc';
        // Multiple sorting
        if (event.shiftKey) {
            WidgetCreateFactory.$applyMultiSortOrder(column);
        }
        // Single sorting
        else {
            WidgetCreateFactory.$applySortOrder(column);
        }
    }

    /**
     * Helper function to determine if we should display sort number
     * @param column
     * @param sortBy
     * @returns {boolean}
     */
    function displaySortNum(column, sortBy) {
        if (_.isArray(sortBy) && sortBy.length > 1) {
            return _.indexOf(sortBy, column.field) > -1;
        }
        return false;
    }

    /**
     * Helper function to get sort number
     * @param column
     * @param sortBy
     * @returns {number}
     */
    function getSortNum(column, sortBy) {
        return _.indexOf(sortBy, column.field) + 1;
    }

    function displayComboChartButtons(column) {
        return $scope.state.widgetType.id === WidgetType.COMBINATIONCHART
            && !_.filter($scope.groupedColumns, {label: column.label}).length;
    }

    /**
     * Helper function to determine if we should display y-axis button
     * @param column
     * @returns {boolean|*}
     */
    function displayYAxisButton(column) {
        return ChartAxisService.displayYAxisButton(column, $scope.groupedColumns, $scope.state.widgetType.id, $scope.currentWidget.metadata.draw_options);
    }

    /**
     * Helper function to apply y-axis position
     * @param column
     */
    function applyYAxis(column) {
        column.moving_y_axis = !column.moving_y_axis;
        WidgetCreateFactory.$applyYAxisPosition(column);
        SlidePanelFactory.closeAll();
    }

    function _getCurrentPalette() {
        return DashboardContextService.resolveChartPalette($scope.chartPalette);
    }
}

/**
 * Select connection initialization point
 * @ngInject
 */
function SelectConnectionConfigController(
    $scope
) {

}

/**
 * Select columns initialization point
 * @ngInject
 */
function WidgetConnectionSelectController(
    $scope,
    LoadingState,
    WidgetColumnFactory,
    WidgetCreateFactory,
    WidgetCreateTypeFactory,
    DataSourceFactory,
    ChartAxisService,
    WidgetSortUtilService,
    gettextCatalog
) {
    WidgetColumnFactory.$registerColumnSelectScope($scope);
    initSelect();
    var output = [];

    $scope.selectOptions = {
        placeholder: gettextCatalog.getString('Select a user type...'),
        width: '90%',
        multiple: false,
        allowClear: false,
        dropdownCssClass: 'connection-select-dropdown'
    };
    $scope.selectColumn = selectColumn;
    $scope.removeColumn = removeColumn;
    $scope.buildSelect = buildSelect;
    $scope.selectOnChange = selectOnChange;
    $scope.triggerRemoveColumn = triggerRemoveColumn;

    function initSelect() {
        var queryParams = {
            widget_request: true,
            is_foreign_key: true,
            fields: ['account_manager_user_id']
        };
        $scope.selectedOptions = $scope.selectedColumns;

        $scope.isLoadingColumns = LoadingState.FETCHING;
        // The widgetFactoryConfig will help specify what kind of datasourced data config we need
        DataSourceFactory.getColumns({type: DataSourceType.CLIENT}, queryParams).then(function (columns) {
            $scope.selectOptions.data = _.map(columns, function(column) {
                return {id: column.field, text: column.label}
            });
            $scope.isLoadingColumns = LoadingState.DONE;
        });
    }

    /**
     * Fn to handle when a column gets selected from the select2
     * @param data
     * @param results
     * @param triggerRebuild - Whether or not we should redraw the widget
     */
    function selectColumn(data, results, triggerRebuild) {
        // Modify the select2 data and disable the selected item
        var state = $scope.state;

        state.canSave = true;
        $scope.selectedColumns = data;
        $scope.state.currentInstruction = gettextCatalog.getString('Please select at least one column');

        if (triggerRebuild) {
            WidgetCreateFactory.$rebuildWidget();

            if (state.isAdding) {
                WidgetCreateFactory.$updateWidgetDimensions();
            }
        }
    }

    // Fn to handle when a column gets removed from the selected columns
    // and how the select2 should behave in that event
    function removeColumn(column, results) {
        var state = $scope.state;

        state.canSave = $scope.selectedColumns.length > 0;
        state.numSelectedColumns = $scope.selectedColumns.length;

        WidgetSortUtilService.updateColumnSorting(WidgetCreateFactory.getCurrentWidget().metadata);

        // Modify the select2 data and disable the selected item
        if (state.numSelectedColumns && !column.isGroupedColumn) {
            WidgetCreateFactory.$rebuildWidget();
        }
    }

    function buildSelect(columns) {
        SeleniumHelper.addSelectClasses($scope.selectOptions, 'column');

    }

    function selectOnChange($selectEl) {
        $scope.$evalAsync(function() {
            var data = $selectEl.select2('data');
            $scope.selectColumn(data, output, true);
        });
    }

    function triggerRemoveColumn(column) {
        $scope.removeColumn(column, output);
        WidgetCreateTypeFactory.$toggleTypeState();
    }

}

/**
 * Widget columns selection container
 * @ngInject
 */
function ColumnsSelectController(
    $scope,
    AppFactory,
    LoadingState,
    ChartFactory,
    ChartAxisService,
    WidgetColumnFactory,
    WidgetCreateFactory,
    WidgetCreateTypeFactory,
    DataSourceFactory,
    DataSourceColumnFactory,
    WidgetType,
    WidgetSortUtilService,
    gettextCatalog,
    DesignFactory,
    ReportStudioTemplateDataService,
    DashboardContextService
) {
    WidgetColumnFactory.$registerColumnSelectScope($scope);
    initSelect();
    var dataTypes = AppFactory.getDataTypes();
    var selectableColumns = [];
    var output = [];

    $scope.selectOptions = {
        placeholder: gettextCatalog.getString('Select a data column...'),
        width: '90%',
        multiple: false,
        allowClear: false,
        dropdownCssClass: 'column-select-dropdown',
        separator: '|||||', // we need to use a separator string that is very unlikely to be used in a filter name (TA-11993)
        formatResult: function (item) {
            if (!item.id) { return item.text; }
            var dataType = _.find(dataTypes, {key: item.format});

            var html = '<label class="label">';
            if (_.startsWith(dataType.icon, 'icon')) {
                html += '<span class="icon '+ dataType.icon +'"></span>';
            } else {
                html += '<span class="icon">'+ dataType.icon +'</span>';
            }
            html += '</label>';
            html += '<span>' + item.text + '</span>';

            return $(html);
        }
    };
    $scope.preProcessColumns = preProcessColumns;
    $scope.selectColumn = selectColumn;
    $scope.removeColumn = removeColumn;
    $scope.setColumnStates = setColumnStates;
    $scope.buildSelect = buildSelect;
    $scope.selectOnChange = selectOnChange;
    $scope.triggerRemoveColumn = triggerRemoveColumn;
    $scope.triggerSetColumnStates = triggerSetColumnStates;


    function initSelect() {
        var queryParams = {
            is_hidden: false,
            widget_request: true
        };

        DashboardContextService.resolveQueryParam(queryParams);

        $scope.isLoadingColumns = LoadingState.FETCHING;
        // The widgetFactoryConfig will help specify what kind of datasourced data config we need
        DataSourceFactory.getColumns($scope.datasource, queryParams).then(function (columns) {
            $scope.buildSelect(columns);
            $scope.isLoadingColumns = LoadingState.DONE;
        });
    }

    // Store a local copy of the seletable columns from the select2 dropdown
    function preProcessColumns(columns) {
        $scope.state.isBuilding = true;

        // selectedColumns will exist if you are editing a widget (rather than creating one)
        // the logic below extends the columns array with the predefined selected Columns information
        if (_.size($scope.selectedColumns)) {
            // new columns ($scope.selectedColumns) are reduced to only those that also exist in possible columns (columns)
            var newCols = _.intersectionBy($scope.selectedColumns, columns, 'field');
            // available values in 'columns' are diff'd by those that exist in new columns (diffFirst)
            var reducedCols = _.differenceBy(columns, newCols, 'field');
            // add reduced available columns to available new columns
            columns = _.chain(reducedCols.concat(newCols)).sortBy('label').value();
        }

        selectableColumns = columns;

        return columns;
    }

    /**
     * Fn to handle when a column gets selected from the select2
     * @param data
     * @param results
     * @param triggerRebuild - Whether or not we should redraw the widget
     */
    function selectColumn(data, results, triggerRebuild) {
        DataSourceColumnFactory.formatSelectColumn(data);

        // Modify the select2 data and disable the selected item
        var group = AppFactory.arrayToMemoizedObj(results, 'format')[data.format];

        if (group) {
            _.each(group.children, function (item) {
                if (item.id === data.id) {
                    item.disabled = true;
                }
            });
        }

        var state = $scope.state;

        state.canSave = true;
        $scope.state.currentInstruction = gettextCatalog.getString('Please select at least one column');

        // Extract the selected columns from selectable columns
        // since the select2 object does not have the required format for the backend
        var selectedColumn = AppFactory.arrayToMemoizedObj(selectableColumns, 'field')[data.id];

        // If selectedColumn is not already in scope.selectedColumns
        if (!_.find($scope.selectedColumns, function(column) {return column.field == selectedColumn.field})) {
            $scope.selectedColumns.push(selectedColumn);
        }

        // Update numSelectedColumns
        state.numSelectedColumns = $scope.selectedColumns.length;

        if (state.widgetType.requires_group_by) {
            // If group by required, can save if column and groupby is selected
            state.canSave = state.groupByColumnSelected;
            if (!state.canSave) {
                state.currentInstruction = gettextCatalog.getString('Please select at least one group by column');
            }
        }

        if (triggerRebuild) {
            WidgetCreateFactory.$rebuildWidget();

            if (state.isAdding) {
                WidgetCreateFactory.$updateWidgetDimensions();
            }
        }
    }

    // Fn to handle when a column gets removed from the selected columns
    // and how the select2 should behave in that event
    function removeColumn(column, results) {
        var state = $scope.state;
        var metadata = WidgetCreateFactory.getCurrentWidget().metadata;

        if ($scope.datasource.type === DataSourceType.CATEGORY_DATA && column.isGroupedColumn) {
            _.remove($scope.selectedColumns, ['groupby_id_field', column.groupby_id_field]);
        } else if (column.isGroupedColumn) {
            _.remove($scope.selectedColumns, ['groupby_id_field', column.groupby_id_field]);
        } else {
            _.remove($scope.selectedColumns, column);
        }

        WidgetSortUtilService.updateColumnSorting(metadata);

        ChartAxisService.removeYAxisPosition(metadata, column);

        state.canSave = $scope.selectedColumns.length > 0;
        state.numSelectedColumns = $scope.selectedColumns.length;

        // Modify the select2 data and disable the selected item
        var group = _.find(results, {format: column.format});
        if (!_.isUndefined(group)) {
            _.each(group.children, function (item) {
                if (item.id === column.field) {
                    item.disabled = false;
                }
            });
        }

        if (state.numSelectedColumns && !column.isGroupedColumn) {
            WidgetCreateFactory.$rebuildWidget();
        }
    }

    function setColumnStates(widgetType, results) {
        // Note: One non metric column will make a whole group a non metric column
        var nonMetricGroups = _.filter(results, {is_metric: false});

        const selectedColumnMetricMap = {};
        _.each($scope.selectedColumns, column => {
            selectedColumnMetricMap[column.id] = column.id;
        });

        var disabledColumns = {};
        _.each(nonMetricGroups, function (textGroup) {
            _.each(textGroup.children, function (item) {
                if (!item.is_metric) {
                    // TODO: WidgetType should be classified as 'can_display_text_field'?
                    item.disabled = !_.isNil(selectedColumnMetricMap[item.id])
                                    || (widgetType.id !== WidgetType.DATAGRID && widgetType.id !== WidgetType.ACCOUNTMANAGER);
                    disabledColumns[item.id] = item;
                }
            });
        });

        if (widgetType.id === WidgetType.DATAGRID
            || widgetType.id === WidgetType.LINECHART
            || widgetType.id === WidgetType.BARCHART
            || widgetType.id === WidgetType.COMBINATIONCHART
            || widgetType.id === WidgetType.ACCOUNTMANAGER) {
            // Add the group by name column to the selected fields
            var memoizedSelectableColumns = AppFactory.arrayToMemoizedObj(selectableColumns, 'field');
            var memoizedSelectedColumns = AppFactory.arrayToMemoizedObj($scope.selectedColumns, 'field');
            _.each($scope.groupedColumns, function(groupedColumn) {
                var groupbyNameColumn = memoizedSelectableColumns[groupedColumn.groupby_name_field];

                // If not already in the selected columns, add it in
                if (!memoizedSelectedColumns[groupbyNameColumn.field]) {
                    $scope.selectedColumns.unshift(groupbyNameColumn);
                    memoizedSelectedColumns = AppFactory.arrayToMemoizedObj($scope.selectedColumns, 'field');
                }
            });
        } else if (widgetType.id === WidgetType.GAUGECHART) {
            $scope.selectedColumns = _.filter($scope.selectedColumns, function(column) {
                return AppFactory.util.column.isNumeric(column.format);
            });
        } else {
            // We need to make sure to filter out any referenced group by name field
            // that may have been added as selected columns from data grids
            // And to remove any disabled items...
            $scope.selectedColumns = _.filter($scope.selectedColumns, function(column) {
                return !column.is_groupby_name_field && !disabledColumns[column.field];
            });
        }
        // Gauge Chart needs at least two selected columns in order to create chart
        if ($scope.state.widgetType.id == WidgetType.GAUGECHART) {
            $scope.state.canSave = $scope.selectedColumns.length > 1;
            $scope.state.currentInstruction = gettextCatalog.getString('Please select at least two columns');
        } else {
            $scope.state.canSave = $scope.selectedColumns.length > 0;
            $scope.state.numSelectedColumns = $scope.selectedColumns.length;
            $scope.state.currentInstruction = gettextCatalog.getString('Please select at least one column');
        }

    }


    function buildSelect(columns) {
        $scope.selectOptions.formatSelection = $scope.selectOptions.formatResult;
        SeleniumHelper.addSelectClasses($scope.selectOptions, 'column');

        columns = $scope.preProcessColumns(columns);

        var requiresGroupBy = $scope.state.widgetType.requires_group_by;
        
        if (!requiresGroupBy
            && $scope.datasource.type === DataSourceType.USER) {
            columns = _removeDateColumns(columns);
        }

        output = DataSourceColumnFactory.preProcessSelectColumns(columns, requiresGroupBy);

        _.each($scope.selectedColumns, function(data) {
            data.id = data.field;
            $scope.selectColumn(data, output, false);
        });

        $scope.selectOptions.data = output;

        $scope.setColumnStates($scope.state.widgetType, output);
    }

    function selectOnChange($selectEl) {
        $scope.$evalAsync(function() {
            var data = $selectEl.select2('data');
            $scope.selectColumn(data, output, true);
            WidgetCreateTypeFactory.$toggleTypeState();

            // Remove the selected in the select2 display
            $selectEl.select2('data', null);
        });
    }

    function triggerRemoveColumn(column) {
        $scope.removeColumn(column, output);
        WidgetCreateTypeFactory.$toggleTypeState();
    }

    function triggerSetColumnStates(widgetType) {
        $scope.setColumnStates(widgetType, output);
    }

    /**
     * Removes data format columns for account manager widget
     * 
     * @param {Array} columns 
     * return {Array} columns
     */
    function _removeDateColumns(columns) {
        return _.filter(columns, column => !AppFactory.util.column.isDate(column.format));
    }
}

/**
 *  Widget columns selection item
 * @constructor
 * @ngInject
 */
function WidgetRemoveSelectedColumnController(
    $scope,
    WidgetColumnFactory
) {
    $scope.removeColumn = function(column) {
        WidgetColumnFactory.$removeSelectedColumn(column);
    };
}

/**
 * Data columns initialization point
 * @constructor
 * @ngInject
 */
function GroupbyColumnsConfigController(
    $scope,
    WidgetType,
    WidgetCreateFactory
) {
    $scope.WidgetType = WidgetType;
    $scope.displayDrillOrGroup = function() {
        if ($scope.state.widgetType.id == WidgetType.BARCHART ||
            $scope.state.widgetType.id == WidgetType.LINECHART ||
            $scope.state.widgetType.id == WidgetType.COMBINATIONCHART) {
            return true;
        }
        return false;
    };

    if (!$scope.state.isEditing) {
        $scope.metadata.data_columns.groupedColumns = [];
        $scope.groupByColumn = null;
        $scope.drillDownColumn = null;
        $scope.replaceWithDrillDownColumn = false;
    }

    $scope.toggleGroupBy = function(isDrillDown) {
        if (isDrillDown !== $scope.metadata.is_multi_grouped) {
            $scope.metadata.is_multi_grouped = isDrillDown;
            WidgetCreateFactory.$rebuildWidget();
        }
    }
}

/**
 *  Widget columns groupby container
 * @ngInject
 */
function ColumnsGroupbyController(
    $scope,
    $WidgetColumnEvents,
    TimeGrouping,
    PubSub,
    AppFactory,
    WidgetColumnFactory,
    WidgetCreateFactory,
    WidgetCreateTypeFactory,
    DataSourceFactory,
    DataSourceColumnFactory,
    WidgetSortUtilService,
    gettextCatalog
) {
    WidgetColumnFactory.$registerColumnGroupScope($scope);
    initSelect();
    _registerEvents();
    var output = [];
    var dataTypes = AppFactory.getDataTypes();

    $scope.selectOptions = {
        placeholder: gettextCatalog.getString('Select a data column...'),
        multiple: false,
        width: '90%',
        allowClear: false,
        dropdownCssClass: 'column-select-dropdown',
        separator: '|||||', // we need to use a separator string that is very unlikely to be used in a filter name (TA-11993)
        formatResult: function (item) {
            if (!item.id) { return item.text; }

            var dataTypeKeys = AppFactory.arrayToMemoizedObj(dataTypes, 'key');
            var dataType = dataTypeKeys[item.groupby_field_format];

            var html = '<label class="label">';
            if (_.startsWith(dataType.icon, 'icon')) {
                html += '<span class="icon '+ dataType.icon +'"></span>';
            } else {
                html += '<span class="icon">'+ dataType.icon +'</span>';
            }
            html += '</label>';
            html += '<span>' + item.text + '</span>';

            return $(html);
        }
    };
    $scope.preProcessColumns = preProcessColumns;
    $scope.selectColumn = selectColumn;
    $scope.removeColumn = removeColumn;
    $scope.buildSelect = buildSelect;
    $scope.selectOnChange = selectOnChange;
    $scope.triggerRemoveColumn = triggerRemoveColumn;

    $scope.$on('$destroy', function() {
        _unregisterEvents();
    });

    // Store a local copy of the groupable columns from the select2 dropdown
    var groupableColumns = [];

    function initSelect() {

        var queryParams = {
            is_groupable: true,
            widget_request: true
        };

        // The widgetFactoryConfig will help specify what kind of datasourced data config we need
        DataSourceFactory.getColumns($scope.datasource, queryParams).then(function (columns) {
            $scope.buildSelect(columns, $scope.groupedColumns);
        });
    }

    /**
     *
     * @param columns
     * @returns {*}
     */
    function preProcessColumns(columns) {
        $scope.state.isBuilding = true;
        groupableColumns = columns;

        return columns;
    }

    /**
     * Fn to handle when a column gets selected from the select2
     * @param data
     * @param results
     * @param triggerRebuild - Whether or not we should redraw the widget
     */
    function selectColumn(data, results, triggerRebuild) {
        DataSourceColumnFactory.formatGroupColumn(data);

        // Modify the select2 data and disable the selected item
        // NOTE: the format being passed here in the data object is
        // actually the groupby_field_format from output
        var group = _.find(results, {groupby_field_format: data.groupby_field_format});
        if (group) {
            _.each(group.children, function (item) {
                if (item.id === data.id) {
                    item.disabled = true;
                }
            });
        }

        var state = $scope.state;

        state.groupByColumnSelected++;

        // Extract the groupable columns from groupableColumns columns
        // since the select2 object does not have the required format for the backend
        var groupedColumn = AppFactory.arrayToMemoizedObj(groupableColumns, 'field')[data.id];

        if (!_.find($scope.groupedColumns, function(column) {return column.field == groupedColumn.field})) {
            $scope.groupedColumns.push(groupedColumn);
        }

        // Set whether the added column is a groupby column or drilldown column
        if (!_.isUndefined($scope.groupedColumns[0]) && $scope.groupedColumns[0].field == groupedColumn.field) {
            $scope.groupByColumn = groupedColumn;

            if ($scope.groupByColumn.is_primary_date_field) {
                $scope.$parent.timeGrouping = $scope.$parent.timeGrouping || TimeGrouping.GROUPING_MONTHLY;
                state.isTimeGroupedWidget = true;
            } else {
                $scope.$parent.timeGrouping = null;
                state.isTimeGroupedWidget = false;
            }
        }
        if (!_.isUndefined($scope.groupedColumns[1]) && $scope.groupedColumns[1].field == groupedColumn.field) {
            $scope.drillDownColumn = groupedColumn;
            state.drillDownColumnSelected = true;
        }

        if (!state.isTimeGroupedWidget) {
            $scope.$parent.timeGrouping = null;
        }
        state.canSave = state.groupByColumnSelected;

        if (state.widgetType.requires_group_by) {
            // If group by required, can save if column and groupby is selected
            state.canSave = state.numSelectedColumns && state.groupByColumnSelected;
            if (!state.canSave) {
                state.currentInstruction = gettextCatalog.getString('Please select at least one group by column');
            }
        }

        if (triggerRebuild) {
            WidgetCreateTypeFactory.$toggleTypeState();
            WidgetCreateFactory.$rebuildWidget();
        }
    }

    // Fn to handle when a column gets removed from the selected columns
    // and how the select2 should behave in that event
    function removeColumn(column, results) {
        var state = $scope.state;

        var groupedColumns = $scope.groupedColumns;

        _.remove(groupedColumns, function(groupedColumn) {
            return groupedColumn.field == column.field;
        });

        state.groupByColumnSelected = groupedColumns.length;
        // Check if more than one column is being grouped (i.e. drilldown colum is set)
        state.drillDownColumnSelected = state.groupByColumnSelected > 1;
        // Set drill down column when removing column (for datagrids)
        if (state.drillDownColumnSelected && !_.find(groupedColumns, ['field', $scope.drillDownColumn.field])) {
            $scope.drillDownColumn = groupedColumns[1];
        }
        if (groupedColumns.length && groupedColumns[0].is_primary_date_field) {
            $scope.$parent.timeGrouping = $scope.$parent.timeGrouping || TimeGrouping.GROUPING_MONTHLY;
            state.isTimeGroupedWidget = true;
        } else {
            $scope.$parent.timeGrouping = null;
            state.isTimeGroupedWidget = false;
        }

        // Modify the select2 data and disable the selected item
        var group = _.find(results, {groupby_field_format: column.groupby_field_format});
        _.each(group.children, function (item) {
            if (item.id === column.field) {
                item.disabled = false;
            }
        });

        WidgetSortUtilService.updateColumnSorting(WidgetCreateFactory.getCurrentWidget().metadata);
        WidgetCreateTypeFactory.$toggleTypeState();
        WidgetCreateFactory.$rebuildWidget();
    }

    function buildSelect(columns, selectedGroupedColumns) {
        $scope.selectOptions.formatSelection = $scope.selectOptions.formatResult;
        SeleniumHelper.addSelectClasses($scope.selectOptions, 'group-by-column');

        columns = $scope.preProcessColumns(columns);

        output = DataSourceColumnFactory.preProcessGroupColumns(columns);

        _.each(selectedGroupedColumns, function(data) {
            // in the case of widgets selected columns not found on the backend (selectable column is removed from service)
            if (_.isObject(data)) {
                data.id = data.field;
                $scope.selectColumn(data, output, false);
            }
        });

        $scope.selectOptions.data = output;

    }


    function selectOnChange($selectEl) {
        var data = $selectEl.select2('data');
        $scope.selectColumn(data, output, true);
        $scope.handleDrillDownColumnToggle();
        WidgetColumnFactory.$setSelectColumnStates($scope.state.widgetType);

        // Remove the selected in the select2 display
        $selectEl.select2('data', null);
    }

    function triggerRemoveColumn(column) {
        $scope.removeColumn(column, output);
        $scope.handleDrillDownColumnToggle();
    }

    function _registerEvents() {
        $scope.$evalAsync(function () {
            PubSub.on($WidgetColumnEvents.TOGGLE_HANDLE_DRILL_DOWN_COLUMN, $scope.handleDrillDownColumnToggle);
        });
    }

    function _unregisterEvents() {
        PubSub.off($WidgetColumnEvents.TOGGLE_HANDLE_DRILL_DOWN_COLUMN, $scope.handleDrillDownColumnToggle);
    }
}

/**
 * Remove grouped column
 * @constructor
 * @ngInject
 */
function WidgetRemoveGroupedColumnController(
    $scope,
    $timeout,
    WidgetColumnFactory
) {
    $scope.removeColumn = function(column) {
        // Reset is_drill_down option
        $scope.isMultiGrouped = false;
        column.isGroupedColumn = true;

        // Check if more than one column is being grouped (i.e. drilldown colum is set)
        $scope.state.drillDownColumnSelected = false;
        $scope.state.isTimeGroupedWidget = false;

        WidgetColumnFactory.$removeGroupedColumn(column);

        // Need to remove the grouped column from the selected columns since it is automatically added when grouped by
        WidgetColumnFactory.$removeSelectedColumn(column);

        // If drilldown column is selected but we are removing the group by column
        // -> replace the group by column with the drilldown column
        if (column != $scope.drillDownColumn && !_.isEmpty($scope.drillDownColumn)) {
            $scope.column = $scope.drillDownColumn;
            $scope.state.isTimeGroupedWidget = $scope.column.is_primary_date_field;

            // This will allow for an animFadeInRight to execute to show a
            // transition of the drilldown column to a group by column
            $timeout(function() {
                $scope.drillDownColumn = null;
            }, 500, false);
        }
    };
}

/**
 *  Widget time grouping frequency when selecting a group by time
 * @ngInject
 */
function WidgetTimeGroupingsController(
    $scope,
    DateFactory,
    WidgetCreateFactory,
    TimeGrouping
) {
    $scope.timeGrouping = $scope.timeGrouping || TimeGrouping.GROUPING_MONTHLY;

    var currentWidget = WidgetCreateFactory.getCurrentWidget();

    DateFactory.timeGroupings.get({
        service_id: currentWidget.metadata.data_source ? currentWidget.metadata.data_source.id : ""
    }).then(function(data) {
        $scope.timeGroupingList = data.plain();
    });

    $scope.onChange = function() {
        WidgetCreateFactory.$rebuildWidget();
    };
}
