import { first } from 'lodash';
import { getters } from '@/modules/core/app/helpers/store';

export const mutations = {
  /**
   * @param state
   * @param {AppNavItem[]} items
   */
  setAppNavItems(state, items) {
    state.appNavItems = items;
  },
  /**
   *
   * @param state
   * @param value
   * @param {Object.<string, >} options
   */
  setShowFilterButton(state, { value, option }) {
    state.showFilterButton = value;
    state.filterContextNavOption = option;
  },

  /**
   * @param state
   * @param value
   */
  setShowCommentButton(state, value) {
    state.showCommentButton = value;
  },

  /**
   * @param state
   * @param value
   */
  setShowActivityLogButton(state, value) {
    state.showActivityLogButton = value;
  },

  /**
   * @param state
   * @param value
   */
  setShowFileButton(state, value) {
    state.showFileButton = value;
  },

  /**
   * @param state
   * @param value
   */
  setShowingFilesModal(state, value) {
    state.isShowingFilesModal = value;
  },

  /**
   * @param state
   * @param value
   */
  setIsShowingActivityOrCommentsOrFile(state, value) {
    state.isShowingActivityOrCommentsOrFilePanel = value;
  },

  setCurrentNavItemKey(state, key) {
    state.currentAppNavItemKey =
      key || (first(getters.nav.getAppNavItems()) && first(getters.nav.getAppNavItems()).key);
  },
};
