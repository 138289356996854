'use strict';
import angular from 'angular';
import _ from 'lodash';
import {DataSourceType} from "coreModules/shared/scripts/app.constants";

angular.module('core.service.ctrls')
    .controller('ServiceListOverviewController', ServiceListOverviewController)
    // Service/category specific displays
    .controller('CampaignPerformanceController', CampaignPerformanceController)
    .controller('ServiceEntityDisplayController', ServiceEntityDisplayController)
    .controller('ServiceScoreDisplayController', ServiceScoreDisplayController);

/**
 * @ngInject
 */
function ServiceListOverviewController (
    $scope,
    AppFactory,
    DesignFactory,
    WidgetDataSourceFactory
) {
    $scope.isAllDataLoaded = WidgetDataSourceFactory.isAllDataLoaded;
    DesignFactory.props.hasVariableHeightWidgets = true;

    function init() {
        var user = AppFactory.getUser();
        $scope.user = user;
        $scope.showScores = user.showScores;
        $scope.canManageServices = user.canManageServices;
    }

    init();
}

/**
 * Display for overall campaign performance
 * @ngInject
 */
function CampaignPerformanceController(
    $scope,
    AppFactory,
    DesignFactory
) {
    $scope.showScores = AppFactory.getUser().showScores;
    $scope.scores = {};
    $scope.brandMappings = AppFactory.getBrandMappings();

    var scoresBucket = {
        client_count: [],
        data_view_count: [],
        high_score: [],
        low_score: [],
        medium_score: [],
        score: [],
        total_client_count: [],
        total_data_view_count: []
    };

    function init() {
        var scoresBucket = {
            client_count: [],
            data_view_count: [],
            high_score: [],
            low_score: [],
            medium_score: [],
            score: [],
            total_client_count: [],
            total_data_view_count: []
        };

        var layout = DesignFactory.getCurrentLayout();

        //TODO: @dannyyassine still needed
        // for calculating averages
        _.forEach(layout.widgets, function (widget) {
            if (widget.metadata.dynamic && !_.isNull(widget.metadata.dynamic.predefined_data)) {
                var extraData = widget.metadata.dynamic.predefined_data.extra_data;
                _.forEach(extraData, function (value, key) {
                    if (!_.isUndefined(scoresBucket[key])) {
                        scoresBucket[key].push(value);
                    }
                });
            }
        });

        // for calculating averages
        _.forEach(layout.widgets, function (widget) {
            if (!_.isNull(widget.metadata.dynamic.predefined_data)) {
                var extraData = widget.metadata.dynamic.predefined_data.extra_data;
                _.forEach(extraData, function (value, key) {
                    if (!_.isUndefined(scoresBucket[key])) {
                        scoresBucket[key].push(value);
                    }
                });
            }
        });

        _.forEach(scoresBucket, function (value, key) {
            // value is the array of scores

            // this length is used in the case of 'score', which is the only value averaged instead of summed
            var length = value.length;

            $scope.scores[key] = _.reduce(value, function (sum, val) {
                // val is each item in the array of scores
                if (val == null) {
                    // val can be null in the case of score (0 clients/campaigns)
                    length--;
                    return sum;
                }
                return sum + parseInt(val);
            }, 0);

            if (key === 'score' && length > 0) {
                // calculates average for scores
                $scope.scores[key] = _.round($scope.scores[key] / length, 1);
            }
        });

        $scope.scores.totalScores = $scope.scores.high_score + $scope.scores.medium_score + $scope.scores.low_score;

        $scope.calcPercentage = function (num, denom) {
            return (num / denom * 100) + '%';
        };

        $scope.scoreColorClass = function (val) {
            if (val < 35) {
                return 'circle-red';
            }
            else if (val < 70) {
                return 'circle-yellow';
            }
            else {
                return 'circle-green';
            }
        };
    }
}


/**
 * Display service entity info if service/category datasource
 * @constructor
 */
ServiceEntityDisplayController.$inject = ['$scope', 'ServiceEntity', 'AppFactory'];
function ServiceEntityDisplayController($scope, ServiceEntity, AppFactory) {

    // Decide whether or not to show score service/category info
    $scope.showScoreInfo = AppFactory.getUser().showScores;
    // Service legacy links have the structure (/service/{service_id}) so teh data source
    // 'service_data' needs to be converted to fit the format
    $scope.dataSourceType = $scope.datasource.type.replace('_data', '');
    var brandMappings = AppFactory.getBrandMappings();

    $scope.getEntities = function() {
        $scope.entityInfoSet = false;
        $scope.showClientEntity = !AppFactory.getUser().isClient();
        var predefinedData = $scope.metadata.dynamic.predefined_data;
        if (predefinedData) {
            var extraData = !_.isNull(predefinedData.extra_data) ? predefinedData.extra_data : null;
            if (extraData) {
                $scope.entityInfoSet = true;

                // If no data view count at all then do not show anything
                if (parseInt(extraData[ServiceEntity.TOTAL_DATA_VIEW_COUNT_FIELD_NAME]) === 0) {
                    $scope.entityInfoSet = false;
                    return true;
                }

                $scope.dataViewEntity = {};
                $scope.dataViewEntity.label = $scope.metadata.data_source.data_view_name;
                $scope.dataViewEntity.active_count = extraData[ServiceEntity.DATA_VIEW_COUNT_FIELD_NAME];
                $scope.dataViewEntity.total_count = extraData[ServiceEntity.TOTAL_DATA_VIEW_COUNT_FIELD_NAME];

                $scope.clientEntity = {};
                $scope.clientEntity.label = brandMappings.client.pluralize();
                $scope.clientEntity.active_count = extraData[ServiceEntity.CLIENT_COUNT_FIELD_NAME];
                $scope.clientEntity.total_count = extraData[ServiceEntity.TOTAL_CLIENT_COUNT_FIELD_NAME];

                if (AppFactory.getUser().showScores) {
                    $scope.score = {};
                    $scope.score.val = extraData['score'] ? _.round(parseInt(extraData['score']), 0) : null;
                    $scope.score.colorClass = scoreColor($scope.score.val);
                }
                return true;
            }
        }
        else {
            // Need to hide the serviceEntityDisplay element, since it is just an empty div...
            return false;
        }
    }

    function scoreColor (val) {
        if (val == null) {
            // val is null when there are 0 campaigns
            return null;
        }
        if (val < 35) {
            return 'circle-red';
        }
        else if (val < 70) {
            return 'circle-yellow';
        }
        else {
            return 'circle-green';
        }
    }

    /**
     * @returns {boolean}
     */
    $scope.allowCampaignDrilldown = function() {
        return $scope.dataSourceType !== DataSourceType.CATEGORY;
    };

    $scope.$watch('metadata.dynamic.predefined_data', function (nV, oV) {
        if (AppFactory.invalidateWatch(nV, oV)) {
            return;
        }
        $scope.getEntities();
    });
}


/**
 * Display service entity info if service/category datasource
 * @constructor
 */
ServiceScoreDisplayController.$inject = ['$scope', 'ServiceEntity', 'AppFactory', 'gettextCatalog'];
function ServiceScoreDisplayController($scope, ServiceEntity, AppFactory, gettextCatalog) {
    var getEntities = function() {
        $scope.scoreInfoSet = false;
        var predefinedData = $scope.metadata.dynamic.predefined_data;
        if (predefinedData) {
            var extraData = !_.isNull(predefinedData.extra_data) ? predefinedData.extra_data : null;
            if (extraData) {
                $scope.scoreInfoSet = true;

                $scope.highScore = extraData[ServiceEntity.HIGH_SCORE_FIELD_NAME];
                $scope.mediumScore = extraData[ServiceEntity.MEDIUM_SCORE_FIELD_NAME];
                $scope.lowScore = extraData[ServiceEntity.LOW_SCORE_FIELD_NAME];

                var dataViewCount = parseInt(extraData[ServiceEntity.DATA_VIEW_COUNT_FIELD_NAME]);
                $scope.dataViewCount = dataViewCount;
                $scope.highScorePercentage = 100 * extraData[ServiceEntity.HIGH_SCORE_FIELD_NAME] / dataViewCount;
                $scope.mediumScorePercentage = 100 * extraData[ServiceEntity.MEDIUM_SCORE_FIELD_NAME] / dataViewCount;
                $scope.lowScorePercentage = 100 * extraData[ServiceEntity.LOW_SCORE_FIELD_NAME] / dataViewCount;

                $scope.score = {};
                $scope.score.val = extraData[ServiceEntity.SCORE_FIELD_NAME] ? _.round(parseInt(extraData[ServiceEntity.SCORE_FIELD_NAME]),0) : null;
                $scope.score.colorClass = scoreColor($scope.score.val);

            }
        }
    };

    function scoreColor (val) {
        if (val == null) {
            // val is null when there are 0 campaigns
            return null;
        }
        if (val < 35) {
            return 'circle-red';
        }
        else if (val < 70) {
            return 'circle-yellow';
        }
        else {
            return 'circle-green';
        }
    }

    $scope.$watch('metadata.dynamic.predefined_data', function (nV, oV) {
        if (AppFactory.invalidateWatch(nV, oV)) {
            return;
        }
        getEntities();
    });

    getEntities();

    var dataSourceName = $scope.datasource.id_name;
    var dataViewName = $scope.datasource.data_view_name
        ? $scope.datasource.data_view_name.toLowerCase()
        : '';

    $scope.performingWell = gettextCatalog.getString(
        'Click to see {{id_name}} {{dataview_name}} performing well',
        {
            id_name: dataSourceName,
            dataview_name: dataViewName
        });

    $scope.needingAttention = gettextCatalog.getString(
        'Click to see {{id_name}} {{dataview_name}} needing attention',
        {
            id_name: dataSourceName,
            dataview_name: dataViewName
        });

    $scope.performingPoorly = gettextCatalog.getString(
        'Click to see {{id_name}} {{dataview_name}} performing poorly',
        {
            id_name: dataSourceName,
            dataview_name: dataViewName
        });

}