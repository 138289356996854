'use strict';
import angular from 'angular';

angular.module('widget.library.constants', [])

    .constant('$WidgetLibraryEvents', {
        INIT_MODAL: '$WidgetLibraryEvents:INIT_MODAL',
        INIT_PUBLISH_MODAL: '$WidgetLibraryEvents:INIT_PUBLISH_MODAL',
        SET_ITEM_DETAILS: '$WidgetLibraryEvents:SET_ITEM_DETAILS',
    })

    .constant('WidgetLibraryColumns', {
        ID: 'id',
        TYPE: 'type',
        TITLE: 'title',
        DATA_SOURCE: 'data_source',
        METRICS: 'metrics',
        TAGS: 'tags',
        THUMBNAIL_METADATA: 'thumbnail_metadata',
        CREATED_AT: 'created_at',
        FORMATTED_CREATED_AT: 'formatted_created_at',
    });