'use strict';
import angular from 'angular';
import './widget.accountmanager.components';
import './widget.accountmanager.models';
import './widget.accountmanager.services';
import './widget.accountmanager.resources';

angular.module('widget.accountmanager', [
    'widget.accountmanager.components',
    'widget.accountmanager.models',
    'widget.accountmanager.services',
    'widget.accountmanager.resources'
]);