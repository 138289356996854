'use strict';
import angular from 'angular';
import $ from 'jquery';
import './scripts/client.ctrls';
import './scripts/client.directives';
import './scripts/client.services';
import dashClientsHtmlUrl from './views/dashclients.html';

angular.module('core.client', [
    'client.ctrls',
    'client.directives',
    'client.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        var dashfilters = {
            templateUrl: AppConfig.MAGE_URL + 'layout/dashfiltersnoviewas',
            controller: 'DashFiltersController'
        };

        $stateProvider
            .state('clients', {
                parent:'layout',
                url: '/clients',
                controller: 'ClientListController',
                templateUrl: AppConfig.MAGE_URL + 'client/manage_clients',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('clients')
                }
            })
            .state('clients.filter', {
                parent:'layout',
                url: '/clients/reporting_status/:reporting_status/iotool_status/:iotool_status',
                controller: 'ClientListController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'client/manage_clients?reporting_status=' + $stateParams.reporting_status + '&iotool_status=' + $stateParams.iotool_status;
                }
            })
            .state('clientDash', {
                parent:'layout',
                url: '/clients/dash/:id',
                resolve: {
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_CLIENT_DETAILS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: dashClientsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
            .state('clients.add', {
                parent:'layout',
                url: '/clients/add',
                data: { isNewEntity: true, primaryKey: 'client_id' },
                controller: 'ClientController',
                templateUrl: AppConfig.MAGE_URL + 'client/add_client'
            })
            .state('clients.detail', {
                parent:'layout',
                url: '/clients/detail/:id',
                controller: 'ClientController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'client/view_client/client_id/' + $stateParams.id + '?tmpl=' + (new Date().getTime());
                }
            })
    }]);