import { isEmpty } from 'lodash';
import EventBus from '@/modules/core/app/helpers/EventBus';

class DocumentUIService {
  /**
   * @type {Object.<string, number>}
   */
  listeners;

  constructor() {
    this.listeners = {};
    this._init();
  }

  /**
   * Sets up events
   */
  _init() {
    window.addEventListener('resize', this._onResize.bind(this));
  }

  reloadApp() {
    window.location.reload();
  }

  setTitle(title) {
    document.title = title;
  }

  /**
   * @returns {string}
   */
  getCurrentPath() {
    return document.location.hash.replace('#/', '');
  }

  redirectToOldApp() {
    window.location = '/';
  }

  navigateTo(url) {
    window.location = url;
  }

  /**
   * @param {string|DocumentEvent} documentEvent
   * @param cb
   */
  on(documentEvent, cb) {
    this._add(documentEvent);
    EventBus.listen(documentEvent, cb);
  }

  /**
   * @param {string|DocumentEvent} documentEvent
   * @param cb
   */
  off(documentEvent, cb) {
    this._remove(documentEvent);
    EventBus.destroy(documentEvent, cb);
  }

  /**
   * @returns {boolean}
   */
  hasListenersForType(eventName) {
    return !isEmpty(this.listeners[eventName]);
  }

  /**
   * @param {Event} event
   * @private
   */
  _onResize(event) {
    if (!this.hasListenersForType(event.type)) {
      return;
    }

    EventBus.signal(event.type);
  }

  /**
   * @private
   *
   * Increase listener count
   */
  _add(documentEvent) {
    if (!this.listeners[documentEvent]) {
      this.listeners[documentEvent] = 0;
    }
    this.listeners[documentEvent] += 1;
  }

  /**
   * @private
   *
   * Reduce listener count
   */
  _remove(documentEvent) {
    this.listeners[documentEvent] -= 1;
  }
}

export default new DocumentUIService();
