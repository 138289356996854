import BillingResource from '@/modules/core/billing/resources/BillingResource';

export class BillingService {
  /**
   *
   * @returns {*}
   */
  getBillingDetails() {
    return BillingResource.getBillingDetails();
  }

  /**
   *
   * @param planId
   * @param addonsData
   * @param frequency
   * @returns {*}
   */
  checkoutPlan(planId, addonsData, frequency) {
    return BillingResource.checkoutPlan(planId, addonsData, frequency);
  }

  /**
   *
   * @returns {*}
   */
  getPrimaryContactAndCardDetails() {
    return BillingResource.getPrimaryContactAndCardDetails();
  }

  /**
   *
   * @returns {*}
   */
  getCreditCardUrl() {
    return BillingResource.getCreditCardUrl();
  }

  /**
   *
   * @param billingAddress
   * @returns {*}
   */
  saveBillingAddress(billingAddress) {
    billingAddress.country = billingAddress?.country?.isoCode ?? billingAddress.country;
    return BillingResource.saveBillingAddress(billingAddress);
  }

  /**
   *
   * @returns {*}
   */
  cancelPlan() {
    return BillingResource.cancelPlan();
  }

  /**
   *
   * @returns {*}
   */
  getOverviewDetails() {
    return BillingResource.getOverviewDetails();
  }

  /**
   *
   * @returns {*}
   */
  reactivatePlan() {
    return BillingResource.reactivatePlan();
  }
}

export default new BillingService();
