import Vue from 'vue';

export class EventBus {
  /**
   * @var {Vue}
   */
  eventBus;

  constructor() {
    this.eventBus = new Vue();
  }

  /**
   * Trigger an event
   * @param {string} name
   * @param {array} args
   */
  signal(name, ...args) {
    this.eventBus.$emit(name, ...args);
  }

  /**
   * Listens for an event triggered by EventBus
   * All the arguments are passed on to the callback function
   * @param {string} name
   * @param {function} callback
   */
  listen(name, callback) {
    this.eventBus.$on(name, callback);
  }

  /**
   * Listen for an event, but only once.
   * The listener will be removed once it triggers for the first time.
   * @param {string} name
   * @param {function} callback
   */
  listenOnce(name, callback) {
    this.eventBus.$once(name, callback);
  }

  /**
   * Remove listener(s).
   * If no arguments are provided, remove all event listeners;
   * If only the event is provided, remove all listeners for that event;
   * If both event and callback are given, remove the listener for that specific callback only.
   * @param {string} [name]
   * @param {function} [callback]
   */
  destroy(name = null, callback = null) {
    this.eventBus.$off(name, callback);
  }
}

export default new EventBus();
