import numeral from 'numeral';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { getters } from '@/modules/core/app/helpers/store';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';

class NumeralService {
  /**
   * @param value
   * @param format
   * @param precision
   * @param isCompactNumber
   */
  formatValue(value, format, precision = 0, isCompactNumber = false, currencySymbol = null) {
    if (value < 1000 || format === ColumnFormat.FORMAT_PERCENT) {
      isCompactNumber = false;
    }

    const precisionFormat =
      value - parseInt(value, 10) > 0 || isCompactNumber
        ? this._getPrecisionFormat(isCompactNumber ? 2 : precision)
        : '';
    const compactNumberFormat = isCompactNumber ? 'a' : '';

    // Checking for negative values and changing its format to display
    const numeralFormatCheckNegativeValues = Math.sign(value) === -1 ? '0.00' : '0,0';
    let numeralFormat = `${numeralFormatCheckNegativeValues}.${precisionFormat}${compactNumberFormat}`;

    if (format === ColumnFormat.FORMAT_PERCENT) {
      numeralFormat = `${numeralFormat}%`;
      // Since NumeralService converts 2 to 200%.
      value = value ? Number(value) / 100 : value;
    } else if (format === ColumnFormat.FORMAT_TIME) {
      numeralFormat = '00:00:00';
    }

    numeral.nullFormat('-');

    const valueFormmated = numeral(value).format(numeralFormat).toUpperCase();
    if (format === ColumnFormat.FORMAT_CURRENCY) {
      const currency = currencySymbol ?? getters.session.getUserSettings()?.currencyCode;
      return `${currency}${valueFormmated}`;
    }
    return valueFormmated;
  }

  /**
   * @param value
   * @param {ApiColumn} column
   * @param isCompactNumber
   */
  formatColumnValue(value, column, isCompactNumber = false) {
    return this.formatValue(value, column.format, column.precision, isCompactNumber);
  }

  /**
   * @param precision
   * @returns {string|string}
   * @private
   */
  _getPrecisionFormat(precision) {
    let format = '';
    for (let i = 0; i < precision; i++) {
      format += '0';
    }
    return format;
  }

  /**
   * @param value
   * @param comparisonValue
   * @param compareAsValue {boolean}
   * @returns {number}
   */
  calculateDelta(
    value,
    comparisonValue,
    compareAsValue = false,
    format = ColumnFormat.FORMAT_INTEGER
  ) {
    if (format === ColumnFormat.FORMAT_TIME) {
      value = DateUtil.getDurationInSeconds(value);
      comparisonValue = DateUtil.getDurationInSeconds(comparisonValue);
    } else {
      value = Number(value);
      comparisonValue = Number(comparisonValue);
    }

    if (compareAsValue) {
      return value - comparisonValue;
    }

    if (!comparisonValue) return '-';

    return value !== 0 ? ((value - comparisonValue) / Math.abs(comparisonValue)) * 100 : 0;
  }

  /**
   * @param value
   * @returns {number}
   */
  getAbsoluteValue(value) {
    return Math.abs(value) || 0;
  }

  /**
   *
   * @param value
   * @param decimals
   * @returns {number}
   */
  getFixedDecimals(value, decimals = 2) {
    value = value.toString();
    // Don't slice integer number
    if (value.indexOf('.') !== -1) {
      value = value.slice(0, value.indexOf('.') + decimals + 1);
    }
    return Number(value);
  }

  /**
   *
   * @param value
   * @param decimals
   * @returns {number}
   */
  getPercentageOf(firstValue, secondValue, decimals = 2) {
    const percentage = (Number(firstValue) / Number(secondValue)) * 100;
    return this.getFixedDecimals(percentage, decimals);
  }
}

export default new NumeralService();
