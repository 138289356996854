'use strict';

import angular from "angular";
import './buysheet.services';
import './buysheetclientfield.services';
import './buysheetflightfield.services';
import './buysheetlineitemfield.services';
import './buysheetorderfield.services';
import './buysheetsignature.services'

angular.module('core.buysheet', [
    'core.buysheet.services'
]);