'use strict';
import angular from 'angular';
import widgetOptionsHtmlUrl from './widget.options.html';

angular.module('widget.option.directives', [])

    .directive('widgetOptions', widgetOptions);

/**
 * @ngInject
 */
function widgetOptions(
    LoadingState
) {
    return {
        restrict: 'E',
        templateUrl: widgetOptionsHtmlUrl,
        scope: {
            state: '=',
            widget: '<'
        },
        controller: 'WidgetOptionsController',
        link: function(scope) {
            scope.LoadingState = LoadingState;
        }
    }
}