import { Label, p50 } from '@amcharts/amcharts5';

export function useTitle(context) {
  const { root } = context();

  function addTitle(text, settings = {}) {
    const label = Label.new(root.value, {
      text,
      fontSize: 15,
      fontWeight: '400',
      centerX: p50,
      ...settings,
    });

    return label;
  }

  return {
    addTitle,
  };
}
