'use strict';
import angular from 'angular';
import './library.constants';
import './base/library.models';
import './base/library.services';
import './base/library.components';
import './base/library.publish.models';

angular.module('core.library', [
    'core.library.constants',
    'core.library.models',
    'core.library.services',
    'core.library.components'
]);