import { cloneDeep, isUndefined, find, isEmpty } from 'lodash';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import WidgetResource from '@/modules/ta/widget/resources/WidgetResource';
import { WidgetType } from '@/modules/ta/widget/models/WidgetType';
import WidgetContextFactory from '@/modules/ta/widget/models/context/WidgetContextFactory';
import {
  WidgetBuilderConstants,
  WidgetType as WidgetTypeConstant,
} from '@/modules/ta/widget/widget.constants';

export class WidgetService extends BaseService {
  constructor() {
    super();
    this.resource = WidgetResource;
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<*>}
   */
  async getItem(id, params = {}) {
    return this.resource.getItem(id, { ...params, all: true });
  }

  /**
   * @param {Widget} widget
   * @returns {*}
   */
  async copyItem(widget) {
    return this.resource.copyItem(widget.id);
  }

  /**
   * @returns {WidgetType[]}
   */
  async getWidgetDataTypes() {
    return [
      WidgetType.getBigNumberType(),
      WidgetType.getDataGridType(),
      WidgetType.getBarChartType(),
      WidgetType.getLineChartType(),
      WidgetType.getCombinationChartType(),
      WidgetType.getPieChartType(),
      WidgetType.getFunnelChartType(),
      WidgetType.getGaugeChartType(),
      WidgetType.getGeoChartType(),
    ];
  }

  /**
   * @param type
   * @returns {WidgetType}
   */
  getWidgetTypeByType(type) {
    switch (type) {
      case WidgetTypeConstant.TYPE_BIGNUMBER:
        return WidgetType.getBigNumberType();
      case WidgetTypeConstant.TYPE_DATAGRID:
        return WidgetType.getDataGridType();
      case WidgetTypeConstant.TYPE_BARCHART:
        return WidgetType.getBarChartType();
      case WidgetTypeConstant.TYPE_LINECHART:
        return WidgetType.getLineChartType();
      case WidgetTypeConstant.TYPE_COMBINATIONCHART:
        return WidgetType.getCombinationChartType();
      case WidgetTypeConstant.TYPE_PIECHART:
        return WidgetType.getPieChartType();
      case WidgetTypeConstant.TYPE_FUNNELCHART:
        return WidgetType.getFunnelChartType();
      case WidgetTypeConstant.TYPE_GAUGECHART:
        return WidgetType.getGaugeChartType();
      case WidgetTypeConstant.TYPE_GEOCHART:
        return WidgetType.getGeoChartType();
      case WidgetTypeConstant.TYPE_ACCOUNTMANAGER:
        return WidgetType.getAccountManagerType();
      case WidgetTypeConstant.TYPE_MEDIA:
        return WidgetType.getMediaType();
      default:
        Logger.log(
          'WidgetService.getWidgetTypeByType: Unsupported widget type',
          Logger.LEVEL_WARNING
        );
    }
  }

  /**
   * @param {Widget} widget
   * @returns {Promise<Widget>}
   */
  async saveItem(widget) {
    const widgetId = widget.id === WidgetBuilderConstants.NEW_WIDGET_ID ? null : widget.id;
    const model = this.trimWidget(widget);
    const id = await this.resource.saveItem(model, widgetId);
    return WidgetResource.getItem(id, { all: 1 });
  }

  /**
   * @param {Widget} widget
   * @returns {Promise<Widget>}
   */
  async saveBuilder({ widget, extra = null }) {
    const widgetId = widget.id === WidgetBuilderConstants.NEW_WIDGET_ID ? null : widget.id;
    const model = this.trimWidget(widget);
    const id = await this.resource.saveBuilder({ model, extra }, widgetId);
    return this.resource.getItem(id, { all: 1 });
  }

  /**
   * @param {Widget} widget
   * @returns {Promise<*>}
   */
  async deleteWidget(widget) {
    return this.resource.deleteItem(widget.id);
  }

  /**
   * @param widget
   * @returns {*}
   */
  static getChartPalette(widget) {
    return widget.metadata.chart_palette?.length
      ? widget.metadata.chart_palette
      : WidgetContextFactory.getContext().getChartPalette(widget);
  }

  /**
   * Trims down widget to its database structure
   * @param widget
   */
  trimWidget(widget) {
    // Create copy of model to avoid breaking widget caused by changes below and mutating store data
    const payload = cloneDeep(widget);
    payload.invalidateFilters();
    return this.trimWidgetMetadata(payload);
  }

  /**
   * @param {string} type
   * @returns {*}
   */
  getMetadataColumnValues(type) {
    return this.resource.getMetadataColumnValues(type, {
      fields: 'data_source',
    });
  }

  /**
   * Trims down widget metadata to its database structure
   *
   * @param {Widget} payload
   *
   * @returns {Widget} // backend structure (what is stored in the DB)
   */
  trimWidgetMetadata(payload) {
    // Never send back full column metadata, only keep field names
    if (!isUndefined(payload.metadata.data_columns)) {
      let selectedColumns = payload.metadata.data_columns.selected;
      const groupedColumns = payload.metadata.data_columns.grouped;

      if (!isUndefined(payload.metadata.time_grouping)) {
        // Only set time grouping if grouped field is primary date field, ALL other cases set to null.
        const primaryDateColumn = find(groupedColumns, { is_primary_date_field: true });
        if (isEmpty(groupedColumns) || isEmpty(primaryDateColumn)) {
          payload.metadata.time_grouping = null;
        }
      }

      if (payload.type === WidgetType.GAUGECHART && selectedColumns.length > 2) {
        selectedColumns = selectedColumns.slice(0, 2);
      }

      payload.metadata.data_columns.selected = selectedColumns.map((column) => column.field);
      payload.metadata.data_columns.grouped = groupedColumns.map((column) => column.field);
    }

    // Never send dynamic metadata
    delete payload.metadata.dynamic;

    return payload;
  }

  async getItems(params) {
    return this.resource.getItems(params);
  }

  async getDataComparisonValues() {
    return this.resource.getComparisonValues();
  }

  async getDataSparklineValues() {
    return this.resource.getSparklineValues();
  }

  async getMapboxAccessToken() {
    return this.resource.getMapboxAccessToken();
  }
}

export default new WidgetService();
