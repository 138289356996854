"use strict";
import $ from 'jquery';

var Core = $.core.dashboard.leads = {

    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {
        $.core.dashboard.initialize($.globals.dashboard.leads);
    },
    events: function () {
    }
};