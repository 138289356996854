'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('reportbuilder.ctrls', [])

    .controller('ReportBuilderController', ReportBuilderController);

//
// Report builder controller
//
ReportBuilderController.$inject = ['$scope', '$timeout', 'ReportBuilderService'];
function ReportBuilderController($scope, $timeout, ReportBuilderService) {
    $timeout(function(){
        $.core.main.initNotify();
        $.core.reporting.builder.initialize();
    }, 0, false);
}