import { StyleProperty } from '@/modules/core/charts/am5/base/models/StyleProperty';

/**
 * @class Label
 */
export class Label {
  /**
   * Element's absolute or relative X coordinate.
   * @var {Number}
   */
  xPosition;

  /**
   * Element's absolute or relative Y coordinate.
   * @var {Number}
   */
  yPosition;

  /**
   * Controls horizontal alignment of the element.
   * @var {String}
   */
  align;

  /**
   * An SVG text.
   * @var {String}
   */
  text;

  /**
   * Controls which part of the element to treat as a horizontal center.
   * @var {String}
   */
  horizontalCenter;

  /**
   * Controls which part of the element to treat as a vertical center.
   * @var {String}
   */
  verticalCenter;

  /**
   * Font size to be used for the text.
   * The size can either be numeric, in pixels, or other measurements.
   * @var {Number}
   */
  fontSize;

  /**
   * Font weight of the title
   * https://www.amcharts.com/docs/v4/reference/fontweight/
   *
   * @var {String}
   */
  fontWeight;

  /**
   * Hide text if does not fit into element's dimensions
   * @var {Boolean}
   */
  hideOversized;

  /**
   * Indicates if text lines need to be truncated if they do not fit,
   * using configurable ellipsis string.
   * @var {Boolean}
   */
  truncate;

  /**
   * Indicates if this element is resizable.
   * @var {Boolean}
   */
  resizable;

  /**
   * If truncate is enabled, should Label try to break only on full words (true),
   * or whenever needed, including middle of the word. (false)
   * @var {Boolean}
   */
  fullWords;

  /**
   * Element's fill color or pattern.
   * @var { String }
   */
  fill;

  /**
   * Top margin - absolute (px) or relative (Percent).
   * @var { Number }
   */
  marginTop;

  /**
   * Bottom margin - absolute (px) or relative (Percent).
   * @var { Number }
   */
  marginBottom;

  /**
   * Left Margin - absolute (px) or relative (Percent).
   * @var { Number}
   */
  marginLeft;

  /**
   * Right Margin - absolute (px) or relative (Percent).
   * @var { Number }
   */
  marginRight;

  /**
   * isMeasured
   * @var { Boolean }
   */
  isMeasured;

  /**
   * Enables or disables autowrapping of text.
   * @var { Boolean }
   */
  wrap;

  /**
   * Maximum allowed width for the element in pixels.
   * @var { Number }
   */
  maxWidth;

  /**
   * A shortcut to setting mouse cursor on hover.
   * @var {Array<StyleProperty>}
   */
  cursorOverStyle;

  constructor(model = {}) {
    this.xPosition = model.xPosition;
    this.yPosition = model.yPosition;
    this.align = model.align;
    this.text = model.text;
    this.fontSize = model.fontSize;
    this.fontWeight = model.fontWeight;
    this.hideOversized = model.hideOversized;
    this.horizontalCenter = model.horizontalCenter;
    this.verticalCenter = model.verticalCenter;
    this.truncate = model.truncate;
    this.resizable = model.resizable;
    this.fullWords = model.fullWords;
    this.fill = model.fill;
    this.marginTop = model.marginTop;
    this.marginBottom = model.marginBottom;
    this.marginLeft = model.marginLeft;
    this.marginRight = model.marginRight;
    this.isMeasured = model.isMeasured;
    this.maxWidth = model.maxWidth;
    this.fontWeight = model.fontWeight;
    this.cursorOverStyle = model.cursorOverStyle?.map((style) => new StyleProperty(style));
  }
}
