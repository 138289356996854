'use strict';
import angular from 'angular';

angular.module('scheduledreport.constants', [])

    .constant('ScheduledReportColumns', {
        ID: 'id',
        CLIENT_ID: 'client_id',
        NOTIFICATION_EMAILS: 'notification_emails',
        FREQUENCY: 'frequency',
        BI_WEEKLY_START_DATE: 'bi_weekly_start_date',
        SEND_ON_WEEKDAY: 'send_on_weekday',
        SEND_ON_DAY_OF_THE_MONTH: 'send_on_day_of_the_month',
        REPORT_FORMAT: 'report_format',
        STATUS: 'status',
        REPORT_CONTENT: 'report_content',
        REPORTING_PROFILE_ID: 'reporting_profile_id',
        REPORT_FILENAME: 'report_filename',
        COMPARE_TO_PREVIOUS_PERIOD: 'compare_to_previous_period',
        CLIENT_GROUP_ID: 'client_group_id',
        DATA_START_DATE: 'data_start_date',
        GROUP_BY_MONTH: 'group_by_month',
        INCLUDE_CHARTS_IF_AVAILABLE: 'include_charts_if_available',
        PAGE_ID: 'page_id',
        REPORTING_TEMPLATE_ID: 'reporting_template_id',
        SEND_UNTIL_DATE: 'send_until_date',
        LAST_SEND_DATE: 'last_send_date'
    });