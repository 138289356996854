'use strict';
import angular from 'angular';

angular.module('layout.library.constants', [])

    .constant('$LayoutLibraryEvents', {
        INIT_MODAL: '$LayoutLibraryEvents:INIT_MODAL',
        RELOAD: '$LayoutLibraryEvents:RELOAD',
        INIT_PUBLISH_MODAL: '$LayoutLibraryEvents:INIT_PUBLISH_MODAL',
        SET_ITEM_DETAILS: '$LayoutLibraryEvents:SET_ITEM_DETAILS',
        CLEAR_ALL_ITEMS: '$LayoutLibraryEvents:CLEAR_ALL_ITEMS',
    })
    .constant('LayoutLibraryColumns', {
        ID: 'id',
        PAGE: 'page',
        TAGS: 'tags',
        THUMBNAIL_METADATA: 'thumbnail_metadata',
        CREATED_AT: 'created_at',
        FORMATTED_CREATED_AT: 'formatted_created_at',
        DATA_SOURCES: 'data_sources',
    });