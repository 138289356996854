'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.tag.services', [])
    .factory('TagFactory', TagFactory);

/**
 * @ngInject
 */
function TagFactory(
    $q,
    ServiceFactory,
    TagResource,
    DetailsModalFactory,
    DataSourceType,
    TagUIResource,
    TagsModelFactory,
    DetailsModalSize,
    DetailsModalModelFactory
) {
    var props = TagsModelFactory.getServiceProps();

    return {
        getDisplayTitle: getDisplayTitle,
        setNewDetails: setNewDetails,
        setEditDetails: setEditDetails,
        getTags: getTags
    };

    /**
     * Retrieve tags
     * @param {Boolean} forceFetch Force a re-fetch to API to update tags in memory
     * @returns {*}
     */
    function getTags(forceFetch) {
        var deferred = $q.defer();

        if (!forceFetch && !_.isEmpty(props.data.tags)) {
            deferred.resolve(angular.copy(props.data.tags));
        } else {
            TagResource.getData().then(function (tags) {
                props.data.tags = tags;
                deferred.resolve(angular.copy(tags));
            });
        }

        return deferred.promise;
    }

    /**
     * Set ups detail modal as new data entity
     * @returns {*}
     */
    function setNewDetails() {
        DetailsModalFactory.setAsNew(true);
        return _setDetails([], 'New Tag', null, DetailsModalSize.SMALL);
    }

    /**
     * Sets up detail modal with data entity
     * @param data
     * @param row
     */
    function setEditDetails(data, row) {
        var promises = [
            TagResource.get(data.id)
        ];
        return _setDetails(promises, getDisplayTitle(data), row, DetailsModalSize.FULL);
    }

    /**
     * Creates title from data model
     * @param data
     * @returns {string}
     */
    function getDisplayTitle(data) {
        return TagUIResource.renderTagHeader(data);
    }

    /**
     * Sets up details
     * @param promises
     * @param title
     * @param row
     * @param modalSize
     * @private
     */
    function _setDetails(promises, title, row, modalSize) {
        return DetailsModalFactory.setDetails(DetailsModalModelFactory.getModalOptions({
            dataSourceType: DataSourceType.TAG,
            promises: promises,
            resource: TagResource.getResource(),
            display: {
                title: title,
                loadingMessage: 'Loading Tag',
                icon: 'icomoon-tag',
                hideTitleIcon: true
            },
            row: row,
            modalSize: modalSize
        }));
    }

}