'use strict';
import angular from 'angular';
import $ from 'jquery';
import './service.constants';
import './base/service.ctrls';
import './base/service.directives';
import './base/service.resources';
import './base/service.services';

import dashServiceListOverviewHtmlUrl from './overview/dashservicelistoverview.html';
import dashServiceDrilldownHtmlUrl from './drilldown/dashservicedrilldown.html';

angular.module('core.service', [
    'core.service.constants',
    'core.service.ctrls',
    'core.service.directives',
    'core.service.resources',
    'core.service.services'
])

    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {

        $stateProvider
            .state('overview', { // All Services
                parent:'layout',
                url: '/overview',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('overview'),
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_OVERVIEW;
                    },
                    vars: function() {
                        return null;
                    }
                },
                templateUrl: dashServiceListOverviewHtmlUrl,
                controller: 'DesignController'
            })
            .state('services', { // Overview of a service
                parent:'layout',
                url: '/services/:id',
                resolve: {
                    pageType: function(PageType) {
                        return PageType.TYPE_SERVICES;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_OVERVIEW;
                    },
                    vars: function() {
                        return null;
                    }
                },
                templateUrl: 'modules/service/overview/dashserviceoverview.html',
                controller: 'DesignController'
            })
            .state('services.drilldown', { // Drilldown of a service
                parent:'layout',
                url: '/services/drilldown/:id?data_view',
                resolve: {
                    pageType: function(PageType) {
                        return PageType.TYPE_SERVICES;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_DRILLDOWN;
                    },
                    vars: function() {
                        return null;
                    }
                },
                templateUrl: dashServiceDrilldownHtmlUrl,
                controller: 'DesignController'
            })
    }]);