'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('exportbuilder.helper.service', [])
    .service('ExportBuilderHelper', ExportBuilderHelper);

//
// Export Builder helper
//
ExportBuilderHelper.$inject = [
    'PageSpecifications'
];
function ExportBuilderHelper(
    PageSpecifications
) {

    var exports = {};

    //
    // Round a number to fit the grid
    // @param  A size in percentage
    // @return a rounded size in percentage
    //
    exports.getSizeForGrid = function(size, noMaxLimit) {
        var modulo = size % PageSpecifications.INNER_MARGINS;
        size = size - modulo + (modulo > PageSpecifications.INNER_MARGINS / 2 ? PageSpecifications.INNER_MARGINS : 0);
        //ensure the size is never more than 100%
        return !noMaxLimit && size > 100 ? 100 : size;
    };

    //
    // Round a pixel value
    //
    exports.getPixelSizeForGrid = function(size) {
        return exports.getSizeForGrid(size, true);
    };

    //
    // turn a pages object (which has page numbers as indexes for convenience)
    // into a sorted array
    // this function is necessary because we cannot ensure that the object
    // is in the right order (check ES' definitions if needed)
    //
    // @return an array of array of items
    //
    exports.getArrayFromRawPagesObject = function(pages) {
        var res = [];
        var biggestPage = parseInt(_.max(_.keys(pages).map(Number)));
        for(var i=0;i <= biggestPage; i++) {
            res.push(pages[i.toString()] || []);
        }
        return res;
    };

    return exports;
}