/* eslint-disable max-classes-per-file */

import Enum from '@/modules/core/app/models/abstracts/Enum';

export class DocumentEvent extends Enum {
  static RESIZE = 'resize';
}

export class KeyboardEvent extends Enum {
  static ENTER = 'enter';

  static SHIFT = 'shift';

  static ESCAPE = 'escape';

  static DELETE = 'delete';

  static DELETE_OVERRIDE = 'delete_override';

  static TAB = 'tab';

  static TAB_OVERRIDE = 'tab_override';

  static UP_ARROW = 'up_arrow';

  static DOWN_ARROW = 'down_arrow';

  static LEFT_ARROW = 'left_arrow';

  static RIGHT_ARROW = 'right_arrow';

  static UP_ARROW_OVERRIDE = 'up_arrow_override';

  static DOWN_ARROW_OVERRIDE = 'down_arrow_override';

  static LEFT_ARROW_OVERRIDE = 'left_arrow_override';

  static RIGHT_ARROW_OVERRIDE = 'right_arrow_override';

  static CMD_C = 'cmd_c';

  static CMD_X = 'cmd_x';

  static CMD_V = 'cmd_v';

  static CMD_Z = 'cmd_z';

  static CMD_Z_OVERRIDE = 'cmd_z_override';

  static CMD_A_OVERRIDE = 'cmd_a_override';

  static CMD_S = 'cmd_s';

  static CMD_S_OVERRIDE = 'cmd_s_override';

  static CMD_FORWARD_SLASH = 'cmd_forward_slash';

  static F = 'f';

  static W = 'w';

  static I = 'i';

  static T = 't';

  static E = 'e';

  static G = 'g';

  static M = 'm';

  static R = 'r';

  static B = 'b';

  static L = 'l';

  static O = 'o';

  static P = 'p';

  static CMD_LEFT_ARROW_OVERRIDE = 'cmd_left_arrow_override';

  static CMD_RIGHT_ARROW_OVERRIDE = 'cmd_right_arrow_override';

  static SHIFT_CMD_Z = 'shift_cmd_z';

  static _metadata = {
    ENTER: { name: 'enter', value: 13, combo: false },
    SHIFT: { name: 'shift', value: 16, combo: false },
    ESCAPE: { name: 'escape', value: 27, combo: false },
    DELETE: { name: 'delete', value: 8, combo: false },
    DELETE_OVERRIDE: { name: 'delete_override', value: 8, combo: false, override: true },
    TAB: { name: 'tab', value: 9, combo: false },
    TAB_OVERRIDE: { name: 'tab_override', value: 9, combo: false, override: true },
    UP_ARROW: { name: 'up_arrow', value: 38, combo: false },
    DOWN_ARROW: { name: 'down_arrow', value: 40, combo: false },
    LEFT_ARROW: { name: 'left_arrow', value: 37, combo: false },
    RIGHT_ARROW: { name: 'right_arrow', value: 39, combo: false },
    UP_ARROW_OVERRIDE: { name: 'up_arrow_override', value: 38, combo: false, override: true },
    DOWN_ARROW_OVERRIDE: { name: 'down_arrow_override', value: 40, combo: false, override: true },
    LEFT_ARROW_OVERRIDE: { name: 'left_arrow_override', value: 37, combo: false, override: true },
    RIGHT_ARROW_OVERRIDE: {
      name: 'right_arrow_override',
      value: 39,
      combo: false,
      override: true,
    },
    CMD_C: { name: 'cmd_c', value: 67, combo: true },
    CMD_X: { name: 'cmd_x', value: 88, combo: true },
    CMD_V: { name: 'cmd_v', value: 86, combo: true },
    CMD_Z: { name: 'cmd_z', value: 90, combo: true },
    CMD_Z_OVERRIDE: { name: 'cmd_z_override', value: 90, combo: true, override: true },
    CMD_A_OVERRIDE: { name: 'cmd_a_override', value: 65, combo: true, override: true },
    CMD_S: { name: 'cmd_s', value: 83, combo: true },
    CMD_S_OVERRIDE: { name: 'cmd_s_override', value: 83, combo: true, override: true },
    CMD_FORWARD_SLASH: { name: 'cmd_forward_slash', value: 191, combo: true },
    F: { name: 'f', value: 70 },
    W: { name: 'w', value: 87 },
    I: { name: 'i', value: 73 },
    T: { name: 't', value: 84 },
    E: { name: 'e', value: 69 },
    G: { name: 'g', value: 71 },
    M: { name: 'm', value: 77 },
    R: { name: 'r', value: 82 },
    B: { name: 'b', value: 66 },
    L: { name: 'l', value: 76 },
    O: { name: 'o', value: 79 },
    P: { name: 'p', value: 80 },
    CMD_LEFT_ARROW_OVERRIDE: {
      name: 'cmd_left_arrow_override',
      value: 37,
      combo: true,
      override: true,
    },
    CMD_RIGHT_ARROW_OVERRIDE: {
      name: 'cmd_right_arrow_override',
      value: 39,
      combo: true,
      override: true,
    },
    SHIFT_CMD_Z: { name: 'shift_cmd_z', value: 90, combo: true, needsShiftKey: true },
  };

  /**
   *
   * @param eventName
   * @returns {*}
   */
  static getEventsMetadata(eventName) {
    return this._metadata[eventName.toUpperCase()];
  }
}

export const TextEditorType = {
  CLASSIC: 'classic',
  BALLOON: 'balloon',
};
