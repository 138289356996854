'use strict';
import angular from 'angular';
import $ from 'jquery';
import $script from 'scriptjs';
import _ from 'lodash';

angular.module('widget.geochart.services', [])

    .factory('GeoChartFactory', GeoChartFactory);


/**
 * @ngInject
 */
function GeoChartFactory(
    ChartFactory,
    ChartUtilFactory,
    WidgetFactory,
    ChartDataType,
    DrawOption,
    LoadingState,
    ChartPlotType,
    CoreFactory,
    AppFactory,
    ColumnFormat,
    UserThemes,
    Country,
    gettextCatalog,
    GeoConfigurationType,
    DesignFactory,
    DataSourceType
) {

    /**
     * @param chartOptions
     * @param columns
     */
    var init = function(chartOptions, columns, widget) {
        // Function to parse and format data to comply with map dataProvider structure
        var selectedCountry;
        var usaCountyFolderName = 'usa';
        var provideData = function(json, createChart) {
            var setDataProvider = function() {
                options.allEmptyData = !options.isSample;
                var data = json.data;
                options.hasComparisonData = json.has_comparison_data;
                options.dataProvider =  {
                    map: options.map,
                    getAreasFromMap: true
                };

                if (options.hasComparisonData) {
                    options.comparisonData = {
                        dataProvider: _.assign({}, options.dataProvider)
                    };
                    options.titles = [{
                        text: gettextCatalog.getString('Current Period')
                    }];
                }

                if(options.map === options.default_map) {
                    if(options.map_zoom && options.map_zoom.zoomLatitude) {
                        options.dataProvider = Object.assign({}, options.dataProvider, options.map_zoom);
                    }
                }

                switch (chartOptions.plot_type) {
                    //
                    // HEAT MAP
                    //
                    case ChartPlotType.HEAT_MAP:

                        // Heat map can only display one metric at a time
                        options.areasSettings.colorSolid = options.getPaletteColor(0);
                        options.areasSettings.color = options.getPaletteColor(1);
                        options.areasSettings.selectedColor = options.getPaletteColor(3);
                        var geoCode = options.map === 'world' ? 'geocode0' : 'geocode1';
                        if (options.map.includes('Counties')) {
                            geoCode = 'geocode2';
                        }
                        options.dataProvider.areas = createMapAreas(options, _.cloneDeep(data), geoCode);
                        if (options.comparisonData) {
                            var copyOptions = angular.copy(options);
                            copyOptions.isComparison = true;
                            options.comparisonData.dataProvider.areas = createMapAreas(copyOptions, data, geoCode);
                            options.comparisonData.valueLegend = angular.copy(copyOptions.valueLegend);
                            options.comparisonData.maxValueHolder = copyOptions.maxValueHolder;
                        }

                        break;
                    //
                    // PLOT MAP
                    //
                    case ChartPlotType.BUBBLE_MAP:

                        options.imagesSettings = {
                            colorSolid: options.getPaletteColor(0),
                            color: options.getPaletteColor(1)
                        };
                        options.dataProvider.images = createMapImages(data);
                        if (options.comparisonData) {
                            options.isComparison = true;
                            options.comparisonData.dataProvider.images = createMapImages(data);
                        }

                        // Note: This can be used to create a gradient for colors, or adjust the size of data
                        // points to show a more descriptive point on a geochart
                        AmCharts.addInitHandler(function(map) {
                            // calculate minimum and maximum value
                            var minValue = null;
                            var maxValue = null;

                            // check if `colorSolid` is set for `imagesSettings`
                            if (_.isUndefined(map.imagesSettings.colorSolid)) {
                                return;
                            }
                            if (_.isUndefined(map.minValue) || _.isUndefined(map.maxValue)) {
                                _.forEach(map.dataProvider.images, function(image) {
                                    var imageValue = +image.value
                                    if (!_.isUndefined(imageValue)) {
                                        if (_.isNull(minValue) || (imageValue < minValue))
                                            minValue = imageValue;
                                        if (_.isNull(maxValue) || (imageValue > maxValue))
                                            maxValue = imageValue;
                                    }
                                })
                            }

                            // use map overrides if set
                            if (!_.isUndefined(map.minValue)) {
                                minValue = map.minValue;
                            }

                            if (!_.isUndefined(map.maxValue)) {
                                maxValue = map.maxValue;
                            }

                            // set opacity for each area
                            _.forEach(map.dataProvider.images, function(image) {
                                if (!_.isUndefined(image.value)) {
                                    var percent = Math.max(((image.value / maxValue) * 100), 0.4);
                                    image.alpha = percent;
                                    // image.percentWidth = percent;
                                }
                            });

                            translateCustomCoordinates();

                        }, ["map"]);

                        break;
                }
                // If no chart data, run the renderCallback in order to display the no data placeholder
                if (options.allEmptyData) {
                    // options.dataProvider is set for options.renderCallback.
                    // A truthy value for 'dataprovider' is needed to update loading state appropriately.
                    options.renderCallback(options);
                }
                else {
                    // options.dataProvider is set for options.renderCallback.
                    // A truthy value for 'dataprovider' is needed to update loading state appropriately.
                    createChart();
                }
            };
            if (options.map.includes('Counties')) {
                const countyName = options.map.substr(0, 2);
                loadMapCounty(usaCountyFolderName, countyName, setDataProvider);
            } else {
                loadMap(options.map, setDataProvider);
            }
        };

        var getSelectedCountryFolderName = function(country, mapObject) {
            if (country) {
                return country === 'usa2' ? usaCountyFolderName : null;
            }
            const [countryName] = mapObject.id.split('-');
            return countryName.toUpperCase() === 'US' ? usaCountyFolderName: null;
        }

        //
        // CUSTOM COORD TRANSLATION FOR AK AND HI
        //
        var translateCustomCoordinates = function() {
            if (options.dataProvider.map == "usa2") {
                _.each(options.dataProvider.images, function(datum) {

                    if (datum.geocode1 == "us-ak") { // Alaska
                        datum.worldLat = datum.worldLat || datum.latitude;
                        datum.worldLong = datum.worldLong || datum.longitude;

                        //anchorage
                        //normal coords
                        var ax1 = 61.2180556;
                        var ay1 = -149.90027780000003;

                        //ammap coords
                        var bx1 = 20.7413;
                        var by1 = -115.1221;

                        //juneau
                        //normal coords
                        var ax2 = 58.3019444;
                        var ay2 = -134.41972220000002;

                        //ammap coords
                        var bx2 = 18.9596;
                        var by2 = -109.7574;

                        //find the scale of Ammaps Alaska vs. actual lat/long coords
                        var latScale = (bx1-bx2)/(ax1-ax2);
                        var longScale = (by1-by2)/(ay1-ay2);

                        //get the new translated point by using the two existing points
                        datum.latitude = bx2 + latScale * (parseFloat(datum.worldLat) - ax2);
                        datum.longitude = by2 + longScale * (parseFloat(datum.worldLong) - ay2);
                    }

                    if (datum.geocode1 == "us-hi") { // Hawaii
                        datum.worldLat = datum.worldLat || datum.latitude;
                        datum.worldLong = datum.worldLong || datum.longitude;

                        //Hanalei
                        //normal coords
                        var ax1 = 22.2094845;
                        var ay1 = -159.5065227;

                        //ammap coords
                        var bx1 = 24.4007;
                        var by1 = -105.86;

                        //kalapana
                        //normal coords
                        var ax2 = 19.3548024;
                        var ay2 = -154.9882648;

                        //ammap coords
                        var bx2 = 20.907;
                        var by2 = -99.9497;

                        //find the scale of Ammaps Hawaii vs. actual lat/long coords
                        var latScale = (bx1-bx2)/(ax1-ax2);
                        var longScale = (by1-by2)/(ay1-ay2);

                        //get the new translated point by using the two existing points
                        datum.latitude = bx2 + latScale * (parseFloat(datum.worldLat) - ax2);
                        datum.longitude = by2 + longScale * (parseFloat(datum.worldLong) - ay2);
                    }

                });
            }
            else {
                _.each(options.dataProvider.images, function(datum) {
                    datum.latitude = datum.worldLat || datum.latitude;
                    datum.longitude = datum.worldLong || datum.longitude;
                });
            }

            const duplicatesObj = {};
            options.dataProvider.images.forEach(datum => {
                const key = `${datum.latitude}-${datum.longitude}`;
                duplicatesObj[key] = (duplicatesObj[key] || []).concat([datum]);
            });
            const translatedValues = Object.values(duplicatesObj).map(dupeArray => {
               for (let i = 0, add = 0; i < dupeArray.length; i++) {
                   dupeArray[i].longitude =
                       (parseFloat(dupeArray[i].longitude) + (add / (i%2 === 0 ? 20 : -20))).toString();
                   if (i%2 === 0) {
                       add++;
                   }
               }
               return dupeArray;
            });
            options.dataProvider.images = [].concat(...translatedValues);
        };

        // reduceMapData for heat map
        var reduceMapData = function(options, chartData, geoCode) {
            if (!_.isNull(options.valueLegend)) {
                delete options.valueLegend.maxValue;
                delete options.valueLegend.minValue;
                options.maxValueHolder = null;
            }
            var displayColumn = _.first(columns.selected);

            var reducedData =  _.reduce(chartData, (accumulator, val) => {
                if (options.hasComparisonData) {
                    val = options.isComparison ? val.prior_period : val.current_period;
                }
                if (val[geoCode]) {
                    var key = val[geoCode].toUpperCase();

                    // Display value is used for balloon value
                    // Value is used for color gradient
                    var displayValue = _.isNull(val[displayColumn.field]) ? 0 : _.toNumber(val[displayColumn.field]);
                    // Since Math.log(0) = -Infinity, we need to set 'value' to be 0 instead of Math.log(0)
                    // to avoid black area issue in heat map (TA-8593)

                    val.displayValue = displayValue;
                    val.value = displayValue;

                    if (accumulator[key]) {
                        accumulator[key].displayValue = displayValue = accumulator[key].displayValue + displayValue;
                        // Avoid Math.log(0) to have black area
                        accumulator[key].value = displayValue;
                        if (accumulator[key].subset) {
                            accumulator[key].subset.push(val);
                        }
                    }
                    else {
                        accumulator[key] = val;
                        const type = getGeoConfigurationType(columns.grouped);
                        if (options.map == 'world' || (options.map == 'usa2' && type === GeoConfigurationType.COUNTY)) {
                            accumulator[key].subset = [val];
                        }
                    }
                    if (!_.isNull(options.valueLegend)) {
                        if (!options.valueLegend.maxValue || displayValue > options.valueLegend.maxValue) {
                            options.maxValueHolder = displayValue;
                            options.valueLegend.maxValue = displayValue;
                        }
                        else if (_.isNull(options.valueLegend.minValue) || displayValue < options.valueLegend.minValue) {
                            options.valueLegend.minValue = displayValue;
                        }
                    }
                }

                return accumulator;
            }, {});


            // After widget has rendered, need to modify the legend max value

            options.listeners['rendered'] = function(e) {
                $('#' + e.chart.chartId).find('text.amcharts-value-legend-max-label').find('tspan').html(e.chart.maxValueHolder);
            };

            return reducedData;

        };

        var createMapAreas = function(options, chartData, geocode) {
            var displayColumn = _.first(columns.selected);
            var reducedData = reduceMapData(options, chartData, geocode);
            return _.map(reducedData, function (datum, key) {
                var value = datum.value;
                var displayValue = datum.displayValue;
                if (options.allEmptyData && (displayColumn.format === ColumnFormat.FORMAT_TIME || _.toNumber(displayValue) > 0)) {
                    options.allEmptyData = false;
                }
                var formatted =
                  displayColumn.format == ColumnFormat.FORMAT_TIME
                    ? datum[displayColumn.field]
                    : $.fn.formatNumber(
                        displayValue,
                        displayColumn.format,
                        displayColumn.precision,
                        false,
                        datum,
                        widget.metadata.currency_discrepancy,
                        widget.metadata.show_currency
                      );

                return _.assign({
                    id: key, // location tag
                    value: value,
                    displayValue: displayValue,
                    balloonText: '<text><strong>[[title]]</strong><span class="label" style="background-color:' + options.areasSettings.colorSolid + ';">' + displayColumn.label + ':</span> ' + formatted + '<br>',
                    selectable: value > 0, // Click event handler will only be thrown if selectable is true
                    rollOverColor: options.getPaletteColor(4)
                }, datum);
            });
        };

        var createMapImages = function(chartData) {
            var imageArray = [];
            var grouping = _.first(columns.grouped);
            _.each(columns.selected, function(column, index) {
                var metricColor = options.getPaletteColor(index);
                _.each(chartData, function (datum) {
                    var displayValue = datum.displayValue;
                    if (options.hasComparisonData) {
                        datum = options.isComparison ? datum.prior_period : datum.current_period;
                    }
                    if (datum.geo_longitude && datum.geo_latitude) {

                        var value = datum[column.field];
                        // TA-12836 Filter 0 value
                        if (_.isNull(value) || _.toNumber(value) === 0) {
                            return;
                        }

                        if (options.allEmptyData && (column.format === ColumnFormat.FORMAT_TIME || _.toNumber(value) > 0)) {
                            options.allEmptyData = false;
                        }
                        if (!_.isNull(options.valueLegend)) {
                            if (value > options.valueLegend.maxValue) {
                                options.valueLegend.maxValue = value;
                            }
                            else if (_.isNull(options.valueLegend.minValue) || value < options.valueLegend.minValue) {
                                options.valueLegend.minValue = value;
                            }
                        }
                        if (!_.isNull(options.valueLegend)) {
                            options.valueLegend = null;
                        }
                        // If lattitude longitude, push image
                        // If city name, add
                        var formatted =
                          column.format == ColumnFormat.FORMAT_TIME
                            ? displayValue
                            : $.fn.formatNumber(
                                datum[column.field],
                                column.format,
                                column.precision,
                                false,
                                datum,
                                widget.metadata.currency_discrepancy,
                                widget.metadata.show_currency
                              );

                        var groupedValue = datum[grouping.groupby_name_field];
                        if (grouping.groupby_field_format == ColumnFormat.FORMAT_DATETIME) {
                            groupedValue =new Date(groupedValue * 1000).toString('MMM dd, yyyy');
                        }
                        imageArray.push({
                            balloonText: '<text><strong>' + groupedValue + '</strong><span class="label" style="background-color:' + metricColor + ';">' + column.label + '</span>' + formatted + '<br>',
                            alpha: 0.5,
                            outlineAlpha: 1,
                            rollOverColor: options.getPaletteColor(4),
                            type: 'circle',
                            value: +value,
                            color: metricColor,
                            geocode0: datum.geocode0,
                            geocode1: datum.geocode1,
                            latitude: datum.geo_latitude,
                            longitude: datum.geo_longitude,
                        });
                    }
                });
            });
            return imageArray;
        };

        var loadMap = function(mapName, buildMap) {
            if (_.isEmpty(AmCharts.maps[mapName])) {
                var files = 'amcharts/maps/' + mapName + '.js';
                $script.ready(CoreFactory.lazyLoadJs(files), function(res, err) {
                    buildMap();
                });
            }
            else {
                buildMap();
            }
        };

        var loadMapCounty = function(country, county, buildMap) {
            const countyName = county + 'Counties';
            if (_.isEmpty(AmCharts.maps[countyName])) {
                var files = 'amcharts/maps/' + country + '/js/' + county + 'Counties.js';
                $script.ready(CoreFactory.lazyLoadJs(files), function(res, err) {
                    buildMap();
                });
            }
            else {
                buildMap();
            }
        };

        // default options for all parameters
        var defaultOptions = {
            type: ChartDataType.GEOCHART,
            mouseWheelZoomEnabled: false,
            colorSteps: 10,
            areasSettings: {
                outlineThickness: 0.2,
                selectable: true
            },
            tapToActivate: true,
            valueLegend: {
                right: 10,
                color: AppFactory.getUser().getThemeType == UserThemes.TYPE_LIGHT ? '#fff' : '#222',
                //Need to set these values
                minValue: null,
                maxValue: null,
            },
            provideData: provideData,
        };

        var options = _.extend(chartOptions, defaultOptions);

        // Event listener for drilling down
        var getMapName = function(locationAbbreviation) {
            if (locationAbbreviation === 'United States') {
                return 'usa2';
            }
            return _.camelCase(locationAbbreviation);
        };

        var deleteZoomFactors = function (event) {
            delete event.chart.dataProvider.zoomLevel;
            delete event.chart.dataProvider.zoomLatitude;
            delete event.chart.dataProvider.zoomLongitude;
            return event;
        };

        const getGeoConfigurationType = (groupedValues) => {
            return (groupedValues && groupedValues[0] && groupedValues[0].geo_config)
                ? groupedValues[0].geo_config.type : null;
        }

        options.listeners['clickMapObject'] = function(event) {
            let type = getGeoConfigurationType(columns.grouped);
            let id = event.mapObject.id;
            let areas = _.split(id, '-');
            let country = _.first(areas);
            if(areas.length > 1 && !options.support_state) {
                return;
            }
            if(areas.length < 2 && !options.support_country){
                return;
            }
            ChartUtilFactory.resetAll();
            if(![DataSourceType.SERVICE_DATA, DataSourceType.CATEGORY_DATA].includes(widget.data_type) && country === 'US') {
                let isReport = _.isEmpty(DesignFactory.getCurrentPage());
                WidgetFactory.$updateWidgetFetchStatus(options.widgetId, isReport, LoadingState.FETCHING);
                WidgetFactory.$rebuildWidget(options.widgetId, isReport);
            }

            if (!_.isNull(event.mapObject.value) &&
                !_.isUndefined(event.mapObject.title) &&
                event.chart.dataProvider.map === 'world' &&
                columns.grouped[0].groupby_name_field !== 'country' && type !== GeoConfigurationType.COUNTRY) {
                event.chart.selectObject(); // Un-select the clicked map object
                var currentMap = event.chart.dataProvider.map;
                var mapObject = event.mapObject;
                mapObject.mapName = getMapName(mapObject.title);
                selectedCountry = getMapName(mapObject.title);
                options.setMap(mapObject);
                options.setZoomOptions({});
                event.chart.addLabel(50, 15, '< back', 'center', 0, 0, 0, 0, 0, "javascript:"  + event.chart.dataProvider.map + "=" +  currentMap + ";" + event.chart.validateData() +";');void(0);");
                event.chart.dataProvider.getAreasFromMap = true;
                event = deleteZoomFactors(event);
                event.chart.tempData = _.filter(event.chart.dataProvider.areas, (area) => {
                    return !isNaN(area.value);
                });
                event.chart.tempMax = event.chart.maxValueHolder;
                event.chart.hasComparisonData = false;

                if (mapObject.subset) {
                    event.chart.dataProvider.areas = createMapAreas(event.chart, mapObject.subset, 'geocode1');
                }
                try {
                    loadMap(mapObject.mapName, function() {
                        event.chart.dataProvider.mapVar = AmCharts.maps[mapObject.mapName];
                        event.chart.dataProvider.map = AmCharts.maps[mapObject.mapName];
                        if (event.chart.plot_type === ChartPlotType.BUBBLE_MAP) {
                            translateCustomCoordinates();
                        }
                        event.chart.validateData();
                    });
                }
                catch(err) {
                    console.log(err);
                }
            } else {
                const selectedCountryFolderName = getSelectedCountryFolderName(selectedCountry, event.mapObject);
                if (selectedCountryFolderName && type && (type === GeoConfigurationType.COUNTY || (type === GeoConfigurationType.PIN && event.chart.plot_type === ChartPlotType.BUBBLE_MAP))) {
                    event.chart.selectObject();
                    event.chart.hasComparisonData = false;
                    var mapObject = event.mapObject;
                    let [, countyName] = mapObject.id.split('-');
                    if (countyName) {
                        countyName = countyName.toLowerCase();
                        mapObject.mapName = countyName + 'Counties';
                        options.setMap(mapObject);
                        options.setZoomOptions({});
                        event.chart.addLabel(50, 15, '< back', 'center', 0, 0, 0, 0, 0, "javascript:"  + event.chart.dataProvider.map + "=" +  currentMap + ";" + event.chart.validateData() +";');void(0);");
                        event.chart.dataProvider.areas = createMapAreas(event.chart, mapObject.subset, 'geocode2');
                        event.chart.dataProvider.getAreasFromMap = true;
                        event = deleteZoomFactors(event);
                        try {
                            loadMapCounty(selectedCountryFolderName, countyName, function () {
                                event.chart.dataProvider.mapVar = AmCharts.maps[mapObject.mapName];
                                event.chart.dataProvider.map = AmCharts.maps[mapObject.mapName];
                                if (event.chart.plot_type === ChartPlotType.BUBBLE_MAP) {
                                    translateCustomCoordinates();
                                }
                                event.chart.validateData();
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                }
            }
        };

        options.listeners['zoomCompleted'] = options.listeners['dragCompleted'] = function(event) {
            var zoomFactors = {
                zoomLatitude: event.chart.zoomLatitude(),
                zoomLongitude: event.chart.zoomLongitude(),
                zoomLevel: event.chart.zoomLevel()
            };
            options.setZoomOptions(zoomFactors);
        };

        options.listeners['homeButtonClicked'] = function(event) {
            if (ChartUtilFactory.isCountrySelected() || ChartUtilFactory.isStateSelected()) {
                ChartUtilFactory.resetAll();
                WidgetFactory.$rebuildWidget(options.widgetId, _.isEmpty(DesignFactory.getCurrentPage()));
            }
            var mapObj = {mapName:'world'};
            options.setMap(mapObj);
            // reset the zoom options
            options.setZoomOptions({});
            // Check if current map is not world map, and the maps original map is 'world'
            if (event.chart.dataProvider.map !== mapObj.mapName && options.map == mapObj.mapName) {
                event.chart.dataProvider.map = mapObj.mapName;
                event.chart.dataProvider.mapVar = AmCharts.maps[mapObj.mapName];
                event.chart.dataProvider.getAreasFromMap = true;
                // remove the zoom factors
                event = deleteZoomFactors(event);
                translateCustomCoordinates();
                if (options.plot_type == ChartPlotType.HEAT_MAP) {
                    if (event.chart.tempData) {
                        options.maxValueHolder = event.chart.tempMax;
                        event.chart.maxValueHolder = event.chart.tempMax;
                        event.chart.dataProvider.areas = _.cloneDeep(event.chart.tempData);
                        event.chart.validateNow();
                    }
                    else { // Editing widget that was drilled into a country
                        var geoCode = 'geocode0';
                        event.chart.dataProvider.getAreasFromMap = true;
                        event.chart.dataProvider.areas = createMapAreas(options, event.chart.dataProvider.areas, geoCode);
                        try {
                            loadMap(mapObj.mapName, function() {
                                event.chart.dataProvider.map = AmCharts.maps[mapObj.mapName];
                                event.chart.dataProvider.mapVar = AmCharts.maps[mapObj.mapName];
                                event.chart.validateNow();
                            });
                        }
                        catch(err) {
                            console.log(err);
                        }
                    }
                }
                else {
                    loadMap(mapObj.mapName, () => {
                        event.chart.validateNow();
                    });
                }
            }
            else {
                loadMap(mapObj.mapName, () => {
                    event = deleteZoomFactors(event);
                    event.chart.validateNow();
                });
            }

        };



        //
        // OPTION OVERRIDES
        //
        options.valueLegend = options[DrawOption.HAS_TOOLTIP] ? options.valueLegend : null;
        // Disabling tooltip/balloon when Enable Tooltips is disabled in UI. TA-32082
        options.balloon.enabled = options[DrawOption.HAS_TOOLTIP];
        ChartFactory.makeChart(options, columns);
    };

    /**
     * returns the country codes for which amcharts maps are available
     *
     * @returns array
     */
    var getGeoList = function() {
        // currently returning only country codes. continents we may add later once the amcharts continent maps are added
        return Country.CODES;
    };


    return {
        init: init,
        getGeoList: getGeoList
    }

}
