import { BaseDrawOption } from '@/modules/ta/widget/models/drawoptions/BaseDrawOption';
import { PlotType } from '@/modules/ta/widget/widget.constants';

export class GeoChartDrawOption extends BaseDrawOption {
  /**
   * @type {string}
   */
  plot_type;

  /**
   * @type {boolean}
   */
  has_tooltip;

  constructor(model = {}) {
    super(model);

    this.setValidPlotType(model);
  }

  setValidPlotType(model) {
    const validTypes = [PlotType.BUBBLE_MAP, PlotType.HEAT_MAP];

    if (!validTypes.includes(model.plot_type)) {
      this.plot_type = PlotType.BUBBLE_MAP;
    }
    this.plot_type = model.plot_type;
  }
}
