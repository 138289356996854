import { apiLegacyAxios } from '@/modules/core/app/helpers/AxiosHelper';

export class BaseLegacyResource {
  /**
   * Represents the base route of an API module endpoint
   *
   * @var {RouteGroup}
   */
  routeGroup;

  /**
   * @param {RouteGroup} routeGroup
   */
  constructor(routeGroup) {
    this.routeGroup = routeGroup;
  }

  /**
   * @param model
   * @param route
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  async getData(model, route, params) {
    let data = await apiLegacyAxios.get(this._buildRoute(route), { params });
    if (params.datatable) {
      data.aaData = new model(data.aaData);
    } else {
      data = new model(data);
    }
    return data;
  }

  /**
   * @param route
   * @returns {*}
   * @private
   */
  _buildRoute(route) {
    return route ? `${this.routeGroup}/${route}` : this.routeGroup;
  }
}
