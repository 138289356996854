import { Env } from '@/env';

export class BaseModel {
  static factory(model = {}) {
    const newModel = new this(model);

    if (Env.isDevelopment()) {
      const internalKeys = Object.keys(newModel);
      const modelKeys = Object.keys(model);

      modelKeys.forEach((key) => {
        if (!internalKeys.includes(key)) {
          Logger.log(
            `Property ${key} is missing in ${newModel.constructor.name}`,
            Logger.LEVEL_ERROR
          );
        }
      });
    }

    return newModel;
  }
}
