import angular from 'angular';
import $ from 'jquery';

import exportModalFormatsHtmlUrl from './export.modal.formats.html';
import { isUndefined, isArray, isEmpty, first } from "lodash";

angular.module('exportbuilder.dashboard.components')
    .component('reportStudioTemplateDownloadOptionsModal', {
        templateUrl: exportModalFormatsHtmlUrl,
        controllerAs: 'vm',
        controller: ReportStudioTemplateDownloadOptionsModal
    });

/**
 * @ngInject
 */
function ReportStudioTemplateDownloadOptionsModal(
    $timeout,
    PubSub,
    $ExportBuilderTemplateDownloadModalEvents,
    gettextCatalog,
    UIFactory,
    ExportBuilderFacadeUIService,
    ExportBuilderDashboardService,
    ExportReportFactory,
    $ExportBuilderDashboardEvents,
    ExportBuilderExportModalAnimatorUIService,
    ExportBuilderDashboardExportOptionsUIService
) {
    var vm = this;
    vm.state = {
        isLoading: false,
        forceSampleData: false,
        showInfo: false
    };

    /**
     * @type {*}
     */
    vm.serviceState = ExportBuilderDashboardExportOptionsUIService.getState();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.closeModal = closeModal;
    vm.onPDFClicked = onPDFClicked;
    vm.onPPTClicked = onPPTClicked;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents()
    }

    function _resetState() {
        vm.state = {
            isLoading: false,
            forceSampleData: false
        };
        ExportBuilderDashboardExportOptionsUIService.setIsActive(false);
    }

    function onPPTClicked() {
      downloadReport("ppt");
    }

    function onPDFClicked() {
      downloadReport("pdf");
    }

    function downloadReport(downloadType = "pdf") {
      ExportBuilderDashboardService.savePendingItems().then(function () {
        let exportUrl = "";
        if (downloadType === "pdf") {
          exportUrl = ExportReportFactory.exportBuilderToPdf(
            _getDownloadOptions()
          ).getRequestedUrl();
        } else {
          exportUrl = ExportReportFactory.exportBuilderToPpt(
            _getDownloadOptions()
          ).getRequestedUrl();
        }
        const report = ExportBuilderDashboardService.getReport();
        if (report && report.title) {
          UIFactory.notify.showSuccess(
            gettextCatalog.getString(
              `${
                ExportBuilderDashboardService.getReport().title
              } ${downloadType.toUpperCase()} will download when complete. You can navigate away from this page as needed.`
            )
          );
        }
        ExportBuilderFacadeUIService.setIsDownloadingReport(true);
        ExportBuilderExportModalAnimatorUIService.animateDownloadClicked(
          downloadType,
          function (done) {
            $.fileDownload(exportUrl, {
              successCallback: function () {
                UIFactory.notify.showSuccess("Download finished!");
                ExportBuilderFacadeUIService.setIsDownloadingReport(false);
                done();
              },
              failCallback: function (err) {
                const errObj = JSON.parse($(err).html());
                if (
                  !isUndefined(errObj.data) &&
                  !isEmpty(errObj.data) &&
                  isArray(errObj.data)
                ) {
                  UIFactory.notify.showError(first(errObj.data));
                } else {
                  UIFactory.notify.showError("Download failed.");
                }
                ExportBuilderFacadeUIService.setIsDownloadingReport(false);
                done();
              },
            });
          }
        );
      });
    }

    function _getDownloadOptions() {
        var sampleData = vm.state.forceSampleData ? vm.state.forceSampleData : ExportBuilderDashboardService.getReport().is_preview;
        return {
            pageId: ExportBuilderDashboardService.getBuilder().report.id,
            sample_data: sampleData
        }
    }

    function closeModal() {
        _resetState();
        UIFactory.hideModal('report-studio-export-options-modal');
    }

    function _init() {
        if (ExportBuilderDashboardExportOptionsUIService.getIsActive()) {
            return;
        }
        ExportBuilderDashboardExportOptionsUIService.setIsActive(true);
        vm.state.showInfo = _getDownloadOptions().sample_data;
        const report = ExportBuilderDashboardService.getReport();
        if (report.metadata.enable_demo_mode) {
            vm.state.showInfo = report.metadata.enable_demo_mode;
        }
        UIFactory.showModal('report-studio-export-options-modal');
    }

    function _isDownloadingReportEvent(isDownloading) {

    }

    function _registerEvents() {
        PubSub.on($ExportBuilderTemplateDownloadModalEvents.OPEN, _init);
        PubSub.on($ExportBuilderDashboardEvents.IS_DOWNLOADING, _isDownloadingReportEvent);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderTemplateDownloadModalEvents.OPEN, _init);
        PubSub.off($ExportBuilderDashboardEvents.IS_DOWNLOADING, _isDownloadingReportEvent);
    }
}