var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"is-relative",style:([
    _vm.fontColorStyles,
    _vm.containerStyles,
    _vm.calculatedSize,
    _vm.borderStyles,
    _vm.radiusStyles,
    _vm.radiantStyles,
  ]),attrs:{"data-cy":"rectangle-container"}},[(_vm.isLoadingValue)?_c('div',{staticClass:"value"},[_c('p',{staticClass:"subtitle",attrs:{"data-cy":"loader"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Loading...')))])])]):_c('div',[_c('div',{staticClass:"metric-label",class:_vm.showMetricName,style:(_vm.getFontSizeStyle(
          _vm.LabelFontMultiplier.MIN,
          _vm.LabelFontMultiplier.MAX,
          _vm.MaxFontSizes.METRIC_LABEL
        ))},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"value-container",attrs:{"data-cy":"value-container"}},[_c('div',{staticClass:"flex is-flex justify-center"},[_c('div',{staticClass:"value"},[_c('span',{style:([
              _vm.fontStyles,
              _vm.getFontSizeStyle(_vm.DataFontMultiplierSquare.MIN, _vm.DataFontMultiplierSquare.MAX),
            ]),attrs:{"data-cy":"value","title":_vm.emptyDataMessage}},[_c('b-tooltip',{attrs:{"active":!!_vm.isFormattedEllipsisActive(),"label":_vm.isFormattedEllipsisActive(),"type":"is-dark","size":"is-small"}},[_c('div',{ref:"formattedTooltip",staticClass:"overflow-value"},[_vm._v(" "+_vm._s(_vm.formattedValue)+" ")])])],1)])]),_c('p',{staticClass:"m-t--10 text-align-center",style:(_vm.getFontSizeStyle(_vm.DataLabelMultiplierSquare.MIN, _vm.DataLabelMultiplierSquare.MAX)),attrs:{"data-cy":"primary-label"}},[_vm._v(" "+_vm._s(_vm.primaryLabel)+" ")])]),_c('div',{staticClass:"flex is-flex comparison-container",style:(_vm.getFontSizeStyle(
          _vm.DataComaprisonMultiplierSquare.MIN,
          _vm.DataComaprisonMultiplierSquare.MAX,
          _vm.MaxFontSizes.COMPARISON_VALUE
        ))},[_c('div',{staticClass:"comparison-card text-align-center"},[_c('div',[_c('b-tooltip',{attrs:{"active":!!_vm.isComparisonEllipsisActive(),"label":_vm.isComparisonEllipsisActive(),"type":"is-dark","size":"is-small"}},[_c('div',{ref:"comparisonTooltip",staticClass:"overflow-value"},[_vm._v(" "+_vm._s(_vm.formattedComparisonValue)+" ")])])],1),_c('div',{staticClass:"overflow-value",style:(_vm.getFontSizeStyle(
              _vm.DeltaIconFontMultiplier.MIN,
              _vm.DeltaIconFontMultiplier.MAX,
              _vm.MaxFontSizes.COMPARISON_LABEL
            ))},[_vm._v(" "+_vm._s(_vm.secondaryLabel)+" ")])]),_c('div',{staticClass:"comparison-card text-align-center"},[_c('div',[_c('b-tooltip',{attrs:{"active":!!_vm.isDifferenceEllipsisActive(),"label":_vm.isDifferenceEllipsisActive(),"type":"is-dark","size":"is-small"}},[_c('div',{ref:"differenceTooltip",staticClass:"overflow-value"},[_vm._v(_vm._s(_vm.formattedDifference))])])],1),_c('div',{style:(_vm.getFontSizeStyle(
              _vm.DeltaIconFontMultiplier.MIN,
              _vm.DeltaIconFontMultiplier.MAX,
              _vm.MaxFontSizes.COMPARISON_LABEL
            ))},[_vm._v(" "+_vm._s(_vm.$t('Difference'))+" ")])]),_c('div',{staticClass:"comparison-card text-align-center"},[_c('div',{staticClass:"is-flex justify-center"},[_vm._v(" "+_vm._s(_vm.formattedDeltaValue)+"% "),(_vm.isExportingReportStudio)?_c('svg',{staticClass:"delta-value",class:[_vm.deltaIconClass, _vm.deltaValueClasses],style:(_vm.getIconSizeStyle(
                _vm.DataComaprisonMultiplierSquare.MIN,
                _vm.DataComaprisonMultiplierSquare.MAX
              ))},[_c('use',{attrs:{"xlink:href":"","href":`#${_vm.deltaIconClass}`}})]):_c('b-icon',{staticClass:"delta-value",class:_vm.deltaValueClasses,style:(_vm.getFontSizeStyle(
                _vm.DataComaprisonMultiplierSquare.MIN,
                _vm.DataComaprisonMultiplierSquare.MAX
              )),attrs:{"pack":"fa","custom-class":_vm.iconClass,"data-cy":"delta-icon"}})],1),_c('div',{style:(_vm.getFontSizeStyle(
              _vm.DeltaIconFontMultiplier.MIN,
              _vm.DeltaIconFontMultiplier.MAX,
              _vm.MaxFontSizes.COMPARISON_LABEL
            ))},[_vm._v(" "+_vm._s(_vm.$t('Comparison'))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }