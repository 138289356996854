import { ChartDrawOption } from '@/modules/ta/widget/models/drawoptions/ChartDrawOption';
import { isNil } from 'lodash';
import { BulletShapes } from '@/modules/core/charts/am5/charts.constants';

export class BubbleChartDrawOption extends ChartDrawOption {
  /**
   * @var {number}
   */
  width;

  /**
   * @var {number}
   */
  max_bubbles;

  /**
   * @var {number}
   */
  rounded_corners;

  /**
   * @var {string}
   */
  background_gradient;

  /**
   * @var {string}
   */
  gradient_color;

  /**
   * @var {string}
   */
  bubble_shape;

  /**
   * @var {boolean}
   */
  show_values_on_shapes;

  /**
   * @var {number}
   */
  shape_opacity;

  /**
   * @var {boolean}
   */
  show_shape_shadow;

  /**
   * @var {number}
   */
  shape_size;

  /**
   * @var {string}
   */
  font_color_picker;

  constructor(model = {}) {
    super(model);

    this.width = model.width ?? 80;
    this.max_bubbles = model.bubble_max_bubbles ?? 1;
    this.rounded_corners = model.rounded_corners ?? 0;
    this.background_gradient = isNil(model.background_gradient) ? null : model.background_gradient;
    this.gradient_color = isNil(model.gradient_color) ? null : model.gradient_color;
    this.bubble_shape = model.bubble_shape ?? BulletShapes.CIRCLE;
    this.show_values_on_shapes = model.show_values_on_shapes;
    this.shape_opacity = model.shape_opacity || 70;
    this.show_shape_shadow = model.show_shape_shadow;
    this.shape_size = model.shape_size;
    this.font_color_picker = model.font_color_picker;
  }
}
