import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';

class UserImageResource extends BaseResource {
  constructor() {
    super(RouteGroup.USERIMAGES);
  }

  async setUserImage(image, id = null) {
    const route = id === null ? this.routeGroup : `${this.routeGroup}/${id}`;

    const formData = new FormData();
    formData.append('user_image', image);

    return apiAxios.post(route, formData);
  }
}

export default new UserImageResource();
