'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

angular.module('core.messenger.models', [])
    .factory('MessengerModelFactory', MessengerModelFactory);

/**
 * @ngInject
 */
function MessengerModelFactory(
) {
    return {
        getProps: getProps,
        getOptions: getOptions,
        getPanelData: getPanelData,
        getMessageThread: getMessageThread,
    };

    /**
     *
     * @param model
     * @returns {Props}
     * @constructor
     */
    function Props(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.state = new DefaultState(model.state);
        self.data = new DataProps(model.data);
        self.options = new Options(model.options);

        return self;
    }

    /**
     *
     * @param model
     * @constructor
     */
    function MessageThread(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.id = _.isNull(model.id) ? null : model.id;
        self.isEditing = false;
        self.index = _.isNull(model.index) ? null : model.index;
        self.userImageUrl = model.userImageUrl || null; // get Current user image... Needs to be up to date
        self.hideAddButton = model.hideAddButton || false;
        self.content = model.content || '';
        self.author = model.author || _getAuthorName(model.user);
        self.timestamp = _getTimeStamp(model);
        self.permissions = new Permissions(model.permissions);
        self.entity = _.isEmpty(model.entity) ? {} : model.entity;

        self.initEdit = function() {
            self.isEditing = true;
            if (model.initEditCB) {
                model.initEditCB.call(self);
            }
        };

        self.closeEdit = function() {
            self.isEditing = false;
            if (model.closeEditCB) {
                model.closeEditCB.call(self);
            }
        };

        self.reset = function() {
            self.content = '';
            if (model.resetCB) {
                model.resetCB.call(self);
            }
        };

        return self;
    }

    /**
     * Most models contain updated_at and created_at so use that by default,
     * but can be overriden in respective thread override models
     * @param model
     * @returns {*}
     * @private
     */
    function _getTimeStamp(model) {
        if (!_.isNil(model.updated_at) && !_.isNil(model.created_at)) {
            return model.updated_at > 0 ? model.updated_at : model.created_at;
        } else {
            return moment.unix();
        }
    }

    /**
     * @param user
     * @returns {null|string}
     * @private
     */
    function _getAuthorName(user) {
        if (user && (user.first_name || user.last_name)) {
            return user.first_name || '' + ' ' + user.last_name || '';
        }

        return null;
    }

    /**
     *
     * @param model
     * @constructor
     */
    function Permissions(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.can_be_deleted = _.isUndefined(model.can_be_deleted) ? true : model.can_be_deleted;
        self.can_be_edited = _.isUndefined(model.can_be_edited) ? false : model.can_be_edited;
        self.display_user_image = _.isUndefined(model.display_user_image) ? true : model.display_user_image;
        self.display_timestamp = _.isUndefined(model.display_timestamp) ? true : model.display_timestamp;
    }

    /**
     *
     * @param model
     * @constructor
     */
    function DataProps(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.lead = model.lead || null;
        self.threads = model.threads || [];

        return self;
    }

    /**
     *
     * @param state
     * @constructor
     */
    function DefaultState(state) {
        state = _.isEmpty(state) ? {} : state;
        var self = this;

        self.isActive = state.isActive || false;
        self.isLoading = state.isLoading || false;
    }

    /**
     * @param model
     * @constructor
     */
    function Options(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.title = model.title || '';
        self.id = model.id || '';
    }

    /**
     * @param options
     * @constructor
     */
    function PanelData(options) {
        options = _.isEmpty(options) ? {} : options;
        var self = this;
        self.data = options.data || null;
        self.$sourceEl = options.$sourceEl || null;
        return self;
    }

    /**
     * @param props
     * @returns {Props}
     */
    function getProps(props) {
        return new Props(props);
    }

    /**
     * @param options
     * @returns {PanelData}
     */
    function getPanelData(options) {
        return new PanelData(options);
    }

    /**
     * @param options
     * @returns {Options}
     */
    function getOptions(options) {
        return new Options(options);
    }

    /**
     * @param model
     * @returns {MessageThread}
     */
    function getMessageThread(model) {
        return new MessageThread(model);
    }
}