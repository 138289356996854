import { Widget } from '@/modules/ta/widget/models/Widget';
import { User } from '@/modules/ta/user/models/User';

export class AccountManagerWidget extends Widget {
  /**
   * @type {User}
   */
  cached_data;

  /**
   * userId to download
   */
  accountManagerId;

  constructor(model) {
    super(model);

    if (this.metadata.dynamic.predefined_data?.data) {
      this.cached_data = new User(this.metadata.dynamic.predefined_data.data);
    }
  }
}
