import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { Tooltip } from '@amcharts/amcharts5';

export function useXYAxesTooltip(context) {
  const { root, chart } = context();

  function createAxesTooltip() {
    chart.value.xAxes.each(createAxisTooltip);
    chart.value.yAxes.each(createAxisTooltip);
  }

  function createAxisTooltip(axis, settings = {}) {
    const props = axis.get(Constant.USER_DATA);
    if (props.tooltipEnabled) {
      const tooltip = Tooltip.new(root.value, {
        ...settings,
      });
      const background = tooltip.get(Constant.BACKGROUND);
      tooltip.label.setAll({
        fontSize: 12,
        marginTop: 0,
        paddingTop: 0,
        lineHeight: 0.6,
      });
      background.setAll({
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      });
      axis.set(Constant.TOOLTIP, tooltip);
    }
  }

  return { createAxesTooltip, createAxisTooltip };
}
