'use strict';
import angular from 'angular';
import _ from 'lodash';
import widgetImageUrl from "coreModules/shared/images/widget.png";

angular.module('exportbuilder.managelibrary.resources', [])
    .factory('ReportStudioLibraryResourceFactory', ReportStudioLibraryResourceFactory)
    .factory('ReportStudioLibraryDataGridFactory', ReportStudioLibraryDataGridFactory);

/**
 * @ngInject
 */
function ReportStudioLibraryResourceFactory(
    $q,
    Restangular
) {
    const reportStudio = Restangular.all('reportstudio');
    const resource = reportStudio.all('library');
    
    return {
        getData: getData
    };
    
    /**
     * Get data for this resource
     */
    function getData(queryParams) {
        queryParams = _.assign({
            all: true
        }, queryParams);
        return resource.getList(queryParams);
    }
}

/**
 * @ngInject
 */
function ReportStudioLibraryDataGridFactory(
    DataGridRender,
    DataSourceFactory,
    WidgetLibraryColumns,
    WidgetFactory
) {
    return {
        getDTOptions: getDTOptions
    };
    
    /**
     * Return manage widgets datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        
        const dtOptions = {};
        dtOptions.customRenders = {};
        
        dtOptions.numActions = 1;
        dtOptions.customRenders['id'] = function (data, type, full) {
            var html = '';
            html += DataGridRender.getDeleteButton(
                full.can_be_deleted,
                'reportstudio/' + data,
                full['title'],
                full.can_be_deleted ? null : full.can_be_deleted_tooltip
            );
            return html;
        };
    
        dtOptions.customRenders['thumbnail'] = function (data, type, full) {
            var url = !_.isEmpty(full.metadata.thumbnail_metadata) ? full.metadata.thumbnail_metadata.secure_url : widgetImageUrl;
            return '<div class="dash-thumbnail-container"><div class="inner"><img src="' + url + '" /></div></div>'
        };

        dtOptions.customRenders['tags'] = function (data) {
            var html = '';
            _.each(data, function(item) {
                html += '<span class="label label-default mr5">' + item + ' </span>';
            });
            return html;
        };
        
        return dtOptions;
    }
}