export class LegacyAssetModel {
  /**
   * @var {string}
   */
  data;

  /**
   * @var {boolean}
   */
  success;

  constructor(model) {
    this.data = model.data;
    this.success = model.success;
  }
}
