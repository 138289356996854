'use strict';

angular.module('page.library.services', [])

    .factory('PageLibraryFactory', PageLibraryFactory)
    .factory('PageLibraryPublishFactory', PageLibraryPublishFactory);

/**
 * @ngInject
 */
function PageLibraryFactory(
    $q,
    LibraryFactory,
    gettextCatalog,
    PageLibraryResourceFactory
) {
    return {
        getLibraryPages: getLibraryPages,
        getSearchedPages: getSearchedPages,
        getSearchableValues: getSearchableValues,
        incrementDownloadCount: incrementDownloadCount,
        usePageAsTemplate: usePageAsTemplate
    };

    /**
     * @returns {*}
     */
    function getLibraryPages() {
        return LibraryFactory.getLibraryItems(PageLibraryResourceFactory.getPagesForDisplay);
    }

    /**
     * @returns {*}
     */
    function getSearchedPages(queryParams) {
        return PageLibraryResourceFactory.getPagesForDisplay(queryParams);
    }

    /**
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function getSearchableValues() {
        return PageLibraryResourceFactory.getSearchableValues().then(function(data) {
            data.tags = LibraryFactory.mapTagSearchableValues(data.tags);
            data.dataSources = LibraryFactory.mapDataSourceSearchableValues(data.dataSources);
            return _groupSearchableValues(data);
        });
    }

    /**
     * @param id
     * @returns {*}
     */
    function incrementDownloadCount(id) {
        return PageLibraryResourceFactory.incrementDownloadCount(id);
    }

    /**
     * @param pageId
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function usePageAsTemplate(pageId) {
        return PageLibraryResourceFactory.usePageAsTemplate(pageId);
    }

    /**
     * @param data
     * @returns {*[]}
     * @private
     */
    function _groupSearchableValues(data) {
        return [
            {text: gettextCatalog.getString('Data Categories'), children: data.dataSources},
            {text: gettextCatalog.getString('Dashboard Tags'), children: data.tags}
        ];
    }
}

/**
 * @ngInject
 */
function PageLibraryPublishFactory(
    PageLibraryResourceFactory,
    PageTagFactory
) {
    return {
        publish: publish,
        saveTags: saveTags
    };

    /**
     * Saves the widget and sends it to the Page Library
     * @params page object
     */
    function publish(page) {
        var model = _.assign(page, {
            is_predefined: false,
            is_in_library: true
        });
        delete page.metadata.title;

        return PageLibraryResourceFactory.publish(model);
    }

    /**
     * @param tags
     * @returns {*}
     */
    function saveTags(tags) {
        return PageTagFactory.saveTags(tags);
    }
}