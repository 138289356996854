import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { BrandMapping } from '@/modules/core/session/models/BrandMapping';

export class BrandMappingResource {
  /**
   * @returns {Promise<BrandMapping>}
   */
  async getData() {
    const data = await apiAxios.get('branding/mappings');
    return new BrandMapping(data);
  }
}

export default new BrandMappingResource();
