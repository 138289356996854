import angular from 'angular';
import _ from 'lodash';

angular.module('datagrid.tagpicker.services', [])
    .factory('TagPickerFactory', TagPickerFactory)
    .factory('UITagPickerFactory', UITagPickerFactory);

/**
 * @ngInject
 */
function TagPickerFactory(
    $q,
    TagPickerModelFactory,
    TagPickerResource,
    $DatagridTagPickerEvents,
    PubSub,
    TagFactory
) {

    return {
        setTag: setTag,
        updateTag: updateTag,
        updateTagWithText: updateTagWithText,
        getTags: getTags,
        removeTag: removeTag
    };

    /**
     *
     * @param {TagValueModel} tag
     * @param {Boolean} isEditing
     * @returns {*}
     */
    function updateTag(tag, isEditing) {
        var deferred = $q.defer();

        try {
            _validateTag(tag);
        } catch (e) {
            deferred.reject(e);
            return deferred.promise;
        }

        if (tag.id && isEditing) {
            _updateTag(deferred, tag);
        } else {
            _createTag(deferred, tag);
        }

        return deferred.promise;
    }

    /**
     *
     * @param {TagValueModel} tag
     * @param text
     * @param {Boolean} isEditing
     * @returns {*}
     */
    function updateTagWithText(tag, text, isEditing) {
        var deferred = $q.defer();

        tag.tag = text;

        try {
            _validateTag(tag);
        } catch (e) {
            deferred.reject(e);
            return deferred.promise;
        }

        if (tag.id && isEditing) {
            _updateTag(deferred, tag);
        } else {
            _createTag(deferred, tag)
        }

        return deferred.promise;
    }

    /**
     * Available tag has been selected
     * @param {TagValueModel} tag
     */
    function setTag(tag) {
        var deferred = $q.defer();

        try {
            _validateTag(tag);
        } catch (e) {
            deferred.reject(e);
            return deferred.promise;
        }

        if (tag.id) {
            TagPickerResource.update(tag).then(function (newTag) {
                tag.id = newTag.id;
                _onSuccess(deferred, tag);
            });
        } else {
            _createTag(deferred, tag);
        }

        return deferred.promise;
    }

    /**
     * Removes the tag value association
     * @param {TagValueModel} tag
     */
    function removeTag(tag) {
        return TagPickerResource.remove(tag).then(function (data) {
            if (data.id) {
                PubSub.emit($DatagridTagPickerEvents.TAG_CREATED);
            }
            return data;
        })
    }

    /**
     * Retrieves data tags specified from the metadata
     * Lazy loads the tags
     * @param {Boolean} [forceFetch] Flag to force fetch and updates data tags cache
     */
    function getTags(forceFetch) {
        var deferred = $q.defer();
        TagFactory.getTags(forceFetch).then(function (tags) {
            deferred.resolve(tags);
        });

        return deferred.promise;
    }

    /**
     *
     * PRIVATE METHODS
     *
     */

    /**
     *
     * @param deferred
     * @param {TagValueModel} tag
     * @private
     */
    function _createTag(deferred, tag) {
        TagPickerResource.create(tag).then(function (newTag) {
            if (newTag.id) {
                tag.id = newTag.id;
                _onSuccess(deferred, tag);
            } else {
                deferred.reject();
            }
        });
    }

    /**
     *
     * @param deferred
     * @param tag
     * @private
     */
    function _updateTag(deferred, tag) {
        TagPickerResource.remove(tag)
            .then(function (json) {
                if (json.id) {
                    tag.id = null;
                    tag.tag_id = null;
                    return TagPickerResource.create(tag)
                }
            })
            .then(function (data) {
                if (data.id) {
                    tag.id = data.id;
                    _onSuccess(deferred, tag);
                }
                return tag;
            });
    }

    /**
     * On Success handler
     * @private
     */
    function _onSuccess(deferred, tag) {
        PubSub.emit($DatagridTagPickerEvents.TAG_CREATED);
        deferred.resolve(tag);
        getTags(tag.data_source, true); // ignore the response here, but update tags in memory
    }

    /**
     * @param {TagValueModel} tagModel
     * @private
     * @throws
     */
    function _validateTag(tagModel) {
        if (_.isEmpty(tagModel.value)) {
            throw {msg: 'Missing tag value'};
        }
        if (_.isEmpty(tagModel.color)) {
            throw {msg: 'Missing a tag color'};
        }
        if (_.isEmpty(tagModel.data_source)) {
            throw {msg: 'Missing data_source'};
        }
        if (_.isEmpty(tagModel.column)) {
            throw {msg: 'Missing column'};
        }
        if (_.isEmpty(tagModel.tag)) {
            throw {msg: 'Missing tag text'};
        }
    }
}

/**
 * @ngInject
 */
function UITagPickerFactory(
    $DatagridTagPickerEvents,
    DataGridRender,
    PubSub
) {
    var _table;

    _init();

    return {
        setDatatable: setDatatable
    };

    /**
     * Sets current datable with tag editing
     * @param datatable
     */
    function setDatatable(datatable) {
        _table = datatable
    }

    /**
     * Refresh current datatable with tagging currently editing
     */
    function refreshDatatable() {
        if (!_table) return;

        DataGridRender.refreshDataTable(_table);
    }

    /**
     * Private Methods
     */

    /**
     * @private
     */
    function _onTagCreated() {
        refreshDatatable()
    }

    /**
     * Private initializer
     * @private
     */
    function _init() {
        PubSub.on($DatagridTagPickerEvents.TAG_CREATED, _onTagCreated);
    }
}