'use strict';
import angular from 'angular';
import layoutLibraryModalHtmlUrl from './layout.library.modal.html';
import layoutLibraryCarouselItemHtmlUrl from './layout.library.carousel.item.html';
import layoutLibraryDetailedItemHtmlUrl from './layout.library.detailed.item.html';
import layoutPublishModalHtmlUrl from './layout.publish.modal.html';
import widgetImageUrl from 'coreModules/shared/images/widget.png';
import _ from "lodash";

angular.module('layout.library.components', [])
    .component('layoutLibraryModal', {
        templateUrl: layoutLibraryModalHtmlUrl,
        controllerAs: 'vm',
        bindings: false,
        controller: LayoutLibraryModalController
    })
    .component('layoutLibraryCarouselItem', {
        templateUrl: layoutLibraryCarouselItemHtmlUrl,
        bindings: {
            item: '<'
        },
        controller: LayoutLibraryCarouselItemController,
        controllerAs: 'vm'
    })
    .component('layoutLibraryDetailedItem', {
        templateUrl: layoutLibraryDetailedItemHtmlUrl,
        controllerAs: 'vm',
        bindings: false,
        controller: LayoutLibraryDetailedItemController
    })
    .component('layoutPublishModal', {
        bindings: false,
        templateUrl: layoutPublishModalHtmlUrl,
        controller: LayoutPublishModalController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function LayoutLibraryModalController(
    $element,
    $timeout,
    PubSub,
    $LayoutLibraryEvents,
    ManageLayoutFactory,
    LibraryFactory,
    LibraryUIFactory,
    LibraryModelFactory,
    LayoutLibraryFactory,
    gettextCatalog,
    LibrarySearchGroup,
    AppFactory
) {
    var vm = this;
    vm.isNUI = window.isNUI;

    const connectedServicesIds = AppFactory.getConnectedServices().map(service => service.id);

    /**
     * @type {LibraryModalState}
     */
    vm.state = LibraryModelFactory.getLibraryModalState();

    /**
     * @type {LibraryModalData}
     */
    vm.data = LibraryModelFactory.getLibraryModalData();

    /**
     * @type {LibrarySearchBoxConfig}
     */
    vm.searchBox = LibraryModelFactory.getLibrarySearchBoxConfig();
    vm.searchBox.selectOptions.placeholder = gettextCatalog.getString('Filter by data source or tag...');
    vm.searchBox.selectOptions.dropdownCssClass += ' grouped-2-columns';
    vm.titleSearchBox = LibraryModelFactory.getLibraryTitleSearchBoxConfig();
    vm.shouldShowSearchResults = false;

    /**
     * @type {LibraryTitleSearchBoxConfig}
     */
    vm.titleSearchBox = LibraryModelFactory.getLibraryTitleSearchBoxConfig();

    /**
     * @type {LibraryCarouselOptions}
     */
    vm.owlCarouselOptions = LibraryModelFactory.getLibraryCarouselOptions();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.addNewSection = addNewSection;
    vm.isShowingItemDetails = isShowingItemDetails;
    vm.toggleSearchBox = toggleSearchBox;
    vm.triggerSearch = triggerSearch;
    vm.showSearchResults = showSearchResults;
    vm.canSearch = canSearch;
    vm.clearSearch = clearSearch;
    vm.canFilter = canFilter;
    vm.clearFilter = clearFilter;
    vm.onClose = _onClose;
    vm.clearSearchAction = clearSearchAction;

    function $onInit() {
        _registerEvents();
        LibraryUIFactory.bindModalAnimation($element);
        vm.itemComponent = '<layout-library-carousel-item data-item="item"></layout-library-carousel-item>';
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function addNewSection() {
        vm.state.isSaving = true;
        ManageLayoutFactory.addNewLayout().then(function() {
            _hideModal();
        });
    }

    /**
     * @returns {boolean}
     */
    function isShowingItemDetails() {
        return LibraryFactory.getIsShowingItemDetails();
    }

    function toggleSearchBox() {
        vm.state.isSearchBoxActive = !vm.state.isSearchBoxActive;
    }

    function triggerSearch() {
        $timeout(function() {
            if (!canSearch() && !canFilter()) {
                vm.data.searchedItems = [];
                vm.shouldShowSearchResults = false;
                return false;
            }
            vm.state.isSearching = true;
            vm.state.isLoading = true;
            vm.data.searchedItems = [];
            var queryParams = LibraryFactory.resolveSearchQueryFromSearchValues(vm.searchBox.selectedValues);
            if (!_.isEmpty(vm.titleSearchBox.value)) {
                queryParams[LibrarySearchGroup.TITLE] = vm.titleSearchBox.value;
            }
            LayoutLibraryFactory.getSearchedLayouts(queryParams).then(function (items) {
                vm.state.isSearching = false;
                vm.state.isLoading = false;
                vm.data.searchedItems = items;
                _setConnectedState(items);
                vm.shouldShowSearchResults = true;
            });
        }, 0);
    }

    /**
     * @returns {boolean}
     */
    function showSearchResults() {
        return vm.shouldShowSearchResults;
    }

    /**
     * @returns {boolean}
     * @private
     */
    function canSearch() {
        return vm.titleSearchBox.value.length > 1;
    }

    function clearSearch() {
        $timeout(function() {
            vm.titleSearchBox.value = '';
            _evaluateClear();
        }, 0);
    }

    /**
     * @returns {boolean}
     * @private
     */
    function canFilter() {
        return vm.searchBox.selectedValues.length > 0;
    }

    function clearFilter() {
        angular.element('#layout-library-modal div.library-header div.select2-container').select2('data', null);
        $timeout(function() {
            vm.searchBox.selectedValues = [];
            _evaluateClear();
        }, 0);
    }

    /**
     * Checks if we should show original results or re trigger a search
     * @private
     */
    function _evaluateClear() {
        if (canSearch() && canFilter()) {
            vm.data.searchedItems = [];
            vm.shouldShowSearchResults = false;
        } else {
            triggerSearch();
        }
    }

    function _init() {
        _reset();
        _showModal();
        vm.state.isActive = true;
        if (_.isEmpty(vm.data.mostUsedItems)) {
            vm.state.isLoading = true;
            LayoutLibraryFactory.getLibraryLayouts().then(function (data) {
                vm.state.isLoading = false;
                vm.data.mostUsedItems = data.mostUsedItems;
                vm.data.recentlyUploadedItems = data.recentlyUploadedItems;
                _setConnectedState(vm.data.mostUsedItems);
                _setConnectedState(vm.data.recentlyUploadedItems);

                LayoutLibraryFactory.getSearchableValues().then(function (values) {
                    vm.searchBox.values = values;
                });
            });
        }
    }

    function _reset() {
        clearSearch();
        vm.state = LibraryModelFactory.getLibraryModalState();
        vm.data.searchedItems = [];
        vm.shouldShowSearchResults = false;
    }

    function _clearAllItems() {
        vm.data.mostUsedItems = [];
        vm.data.recentlyUploadedItems = [];
    }

    function _reload() {
        _clearAllItems();
        _init();
    }

    /**
     * @param items
     * @private
     */
    function _setConnectedState(items) {
        _.each(items, function(item) {
            item.isConnected = _.isEmpty(item.disconnected_service_ids);
        });
    }

    function _showModal() {
        LibraryUIFactory.showModal('layout-library-modal');
    }

    function _hideModal() {
        LibraryUIFactory.hideModal('layout-library-modal');
    }

    function _onClose() {
        _reset();
    }

    /**
     * Used for Empty placeholder clear button
     */
    function clearSearchAction() {
        clearSearch();
        clearFilter();
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($LayoutLibraryEvents.INIT_MODAL, _init);
        PubSub.on($LayoutLibraryEvents.RELOAD, _reload);
        PubSub.on($LayoutLibraryEvents.CLEAR_ALL_ITEMS, _clearAllItems);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($LayoutLibraryEvents.INIT_MODAL, _init);
        PubSub.off($LayoutLibraryEvents.RELOAD, _reload);
        PubSub.off($LayoutLibraryEvents.CLEAR_ALL_ITEMS, _clearAllItems);
    }
}

/**
 * @ngInject
 */
function LayoutLibraryCarouselItemController(
    PubSub,
    UIFactory,
    LibraryFactory,
    SessionUtilService,
    PageUpdateThumbnailService,
    $LayoutLibraryEvents
) {
    var vm = this;
    vm.selectItem = selectItem;
    vm.hasThumbnail = hasThumbnail;
    vm.getEmptyThumbnailImage = getEmptyThumbnailImage;
    vm.updateThumbnail = updateThumbnail;

    function selectItem() {
        LibraryFactory.setIsShowingItemDetails(true);
        PubSub.emit($LayoutLibraryEvents.SET_ITEM_DETAILS, vm.item);
    }

    /**
     * @returns {boolean}
     */
    function hasThumbnail() {
        const hasThumbnail =  !_.isNull(vm.item.thumbnail_metadata);
        if(hasThumbnail) {
            SessionUtilService.hasValidUrl(vm.item.thumbnail_metadata?.secure_url).then((response) => {
                if(!response) {
                    updateThumbnail(vm.item);
                }
            })
            return true;
        }

        updateThumbnail(vm.item)
        return false;
    }

    /**
     * @param item
     */
    function updateThumbnail(item) {
        const hasSessionKey = SessionUtilService.getValue(`dashboard-layout-thumbnail-${item.id}`);

        if (!hasSessionKey) {
            try {
                SessionUtilService.setValue(`dashboard-layout-thumbnail-${item.id}`, item.id);
                PageUpdateThumbnailService.triggerSectionThumbnail(item.id).then(() => {
                    PubSub.emit($LayoutLibraryEvents.RELOAD);
                })
            } catch (e) {
            }
        }
    }

    /**
     * @returns {boolean}
     */
    function getEmptyThumbnailImage() {
        return widgetImageUrl;
    }
}

/**
 * @ngInject
 */
function LayoutLibraryDetailedItemController(
    $state,
    $timeout,
    PubSub,
    UIFactory,
    DataSourceFactory,
    DesignFactory,
    PageFactory,
    LayoutFactory,
    WidgetFactory,
    WidgetLibraryFactory,
    $LayoutLibraryEvents,
    LibraryFactory,
    LibraryUIFactory,
    LibraryModelFactory,
    LayoutLibraryFactory,
    gettextCatalog,
    AppFactory
) {
    var vm = this;

    /**
     * @type {LibraryDetailedItemState}
     */
    vm.state = LibraryModelFactory.getLibraryDetailedItemState();

    /**
     * @type {LibraryDetailedItemData}
     */
    vm.data = LibraryModelFactory.getLibraryDetailedItemData();

    const connectedServicesIds = AppFactory.getConnectedServices().map(service => service.id);


    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.goBack = goBack;
    vm.getConnectedBadgeTooltipText = getConnectedBadgeTooltipText;
    vm.getOrderedWidgets = getOrderedWidgets;
    vm.getWidgetDataSourceColor = getWidgetDataSourceColor;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getWidgetTypeIcon = getWidgetTypeIcon;
    vm.getWidgetTitle = getWidgetTitle;
    vm.getDownloadText = getDownloadText;
    vm.useLayoutAsTemplate = useLayoutAsTemplate;
    vm.goToConnectServices = goToConnectServices;
    vm.getWidgetPlainTitle = getWidgetPlainTitle;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function goBack() {
        _reset();
    }

    /**
     * @returns {string}
     */
    function getConnectedBadgeTooltipText() {
        return vm.data.isConnected
            ? gettextCatalog.getString('All widgets in this section have connected data sources')
            : gettextCatalog.getString('At least one widget in this section has an unconnected data source. Click "Connect Data Source" below to configure it.');
    }

    function getOrderedWidgets() {
        return _.orderBy(vm.data.selectedItem.widgets, 'display_order');
    }

    /**
     * @param metadata
     */
    function getWidgetDataSourceColor(metadata) {
        return DataSourceFactory.getDataSourceColor(metadata.data_source);
    }

    /**
     * @param metadata
     */
    function getDataSourceIcon(metadata) {
        return DataSourceFactory.getDataSourceIcon(metadata.data_source);
    }

    /**
     * @param widgetType
     */
    function getWidgetTypeIcon(widgetType) {
        return WidgetFactory.getWidgetType(widgetType).icon;
    }

        /**
         * @param widget
         */
        function getWidgetTitle(widget) {
            return DesignFactory.getWidgetTitle(widget);
        }

    /**
     * @returns {string}
     */
    function getDownloadText() {
        return gettextCatalog.getString('Use Template');
    }

    function useLayoutAsTemplate() {
        vm.state.isSaving = true;
        LayoutLibraryFactory.useLayoutAsTemplate(angular.copy(vm.data.selectedItem)).then(function(newLayout) {
            LayoutLibraryFactory.incrementDownloadCount(vm.data.selectedItem.id);
            DesignFactory.updateLayout(newLayout);
            PageFactory.updatePageLayouts();
            LayoutFactory.changeLayout(newLayout, true);
            UIFactory.notify.showSuccess(gettextCatalog.getString('Section successfully added'));
            _hideModal();
            _reset();
        });
    }

    function goToConnectServices() {
        if (AppFactory.getUser().canAccessNUIServices) {
            window.location.href = AppFactory.getMdsRoute() + '/' + vm.data.selectedItem.disconnected_service_ids[0];
        } else {
            $state.go('service.manage.open', {id: vm.data.selectedItem.disconnected_service_ids[0]}).then(function () {
                _hideModal();
            });
        }
    }

    /**
     * @private
     */
    function _init(item) {
        vm.data.selectedItem = item;
        vm.state.isLoading = true;
        vm.data.isConnected = item.isConnected;
        LayoutLibraryFactory.getLayoutWidgets(vm.data.selectedItem.id).then(function(widgets) {
            vm.state.isLoading = false;
            vm.data.selectedItem.widgets = widgets;
        });
    }

    /**
     * @private
     */
    function _reset() {
        LibraryFactory.setIsShowingItemDetails(false);
        vm.data = LibraryModelFactory.getLibraryDetailedItemData();
        vm.state = LibraryModelFactory.getLibraryDetailedItemState();
    }

    function _hideModal() {
        LibraryUIFactory.hideModal('layout-library-modal');
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($LayoutLibraryEvents.SET_ITEM_DETAILS, _init);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($LayoutLibraryEvents.SET_ITEM_DETAILS, _init);
    }

    /**
     * @param widget
     * @returns String
     */
    function getWidgetPlainTitle(widget) {
        if (_.isEmpty(widget.title)) {
            return gettextCatalog.getString('Untitled Widget');
        }
        return WidgetFactory.getWidgetPlainTitle(widget.title);
    }
}

/**
 * @ngInject
 */
function LayoutPublishModalController(
    PubSub,
    UIFactory,
    DesignFactory,
    LayoutTagFactory,
    $LayoutLibraryEvents,
    LayoutLibraryPublishFactory,
    LibraryPublishModelFactory,
    LibraryPublishFactory,
    TagPickerSelectConstant,
    gettextCatalog
) {
    var vm = this;

    /**
     * @type {LibraryPublishModalState}
     */
    vm.state = LibraryPublishModelFactory.getLibraryPublishModalState();

    /**
     * @type {LibraryPublishModalData}
     */
    vm.data = LibraryPublishModelFactory.getLibraryPublishModalData();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onCancel = onCancel;
    vm.publishLayout = publishLayout;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * @param model
     */
    function publishLayout(model) {
        vm.state.isSaving = true;
        LibraryPublishFactory.publish(LayoutLibraryPublishFactory, model, vm.data).then(function () {
            vm.state.isSaving = false;
            UIFactory.notify.showSuccess(gettextCatalog.getString('Section successfully published to library'));
            if (window.isNUI) {
                const LayoutToPublish = _.extend(vm.data, model);
                PubSub.emit('SegmentEvents', {
                    event: 'PublishToLibraryEvent',
                    payload: {item: LayoutToPublish, layout: DesignFactory.getCurrentLayout(), page: DesignFactory.getCurrentPage(), type: 'section'}
                });
            }
            PubSub.emit($LayoutLibraryEvents.CLEAR_ALL_ITEMS);
            _hideModal();
        }).catch(function (msg) {
            vm.state.isSaving = false;
            if (msg) {
                UIFactory.warn({text: msg});
            }
        });
    }

    /**
     * Stops publishing
     */
    function onCancel() {
        _hideModal();
        _reset();
    }

    /**
     * @param layoutToPublish
     * @private
     */
    function _init(layoutToPublish) {
        _reset();
        vm.state.isActive = true;
        vm.data.itemToPublish = angular.copy(layoutToPublish);
        _showModal();

        LayoutTagFactory.getTags().then(function(data) {
            vm.data.tags = data;
            vm.data.tags.unshift({id: TagPickerSelectConstant.PLACEHOLDER_ID, text: TagPickerSelectConstant.PLACEHOLDER_TAGS});
        });
    }

    function _reset() {
        vm.data = LibraryPublishModelFactory.getLibraryPublishModalData();
        vm.state = LibraryPublishModelFactory.getLibraryPublishModalState();
    }

    function _showModal() {
        UIFactory.showModal('layout-publish-modal');
    }

    function _hideModal() {
        UIFactory.hideModal('layout-publish-modal');
    }
    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($LayoutLibraryEvents.INIT_PUBLISH_MODAL, _init);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($LayoutLibraryEvents.INIT_PUBLISH_MODAL, _init);
    }
}