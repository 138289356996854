import { Bullet, Template, Circle } from '@amcharts/amcharts5';

export function useBullets(context) {
  const { root, chart } = context();

  function createBullets() {
    chart.value.series.each((series) => {
      const circleTemplate = Template.new({});

      series.bullets.push(() => {
        const graphics = Circle.new(
          root.value,
          {
            fill: series.get('fill'),
          },
          circleTemplate
        );
        return Bullet.new(root.value, {
          sprite: graphics,
        });
      });

      series.set('heatRules', [
        {
          target: circleTemplate,
          min: 1,
          max: 35,
          dataField: 'value',
          key: 'radius',
        },
      ]);
    });
  }

  function hideBulletsIfGoingOutsideContainer(sprite) {
    const x = sprite.x();
    const y = sprite.y();
    const containerWidth = chart.value.plotContainer.width();
    const containerHeight = chart.value.plotContainer.height();

    if (x < 0 || x > containerWidth || y < 0 || y > containerHeight) {
      sprite.hide(0);
    } else {
      sprite.show(0);
    }
  }

  function hidePlotContainerOutsideBullets(sprite) {
    sprite.on('x', (x, _sprite) => hideBulletsIfGoingOutsideContainer(_sprite));
    sprite.on('y', (y, _sprite) => hideBulletsIfGoingOutsideContainer(_sprite));
  }

  return {
    createBullets,
    hidePlotContainerOutsideBullets,
  };
}
