import { ServiceDataWidgetDataResource } from '@/modules/ta/widget/resources/ServiceDataWidgetDataResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { workerAxios } from '@/modules/core/app/helpers/WorkerManagerHelper';
import ServiceDataLiveDataResource from '@/modules/ta/service/resources/ServiceDataLiveDataResource';

export class ServiceDataLiveWidgetDataResource extends ServiceDataWidgetDataResource {
  constructor() {
    super(RouteGroup.SERVICES);
    this.apiHelper = workerAxios;
  }

  /**
   * @override
   * @param {Widget} widget
   * @param {Object} params
   * @returns {Promise<GenericDataModel[]>}
   */
  async getWidgetData(widget, params) {
    const { data_source_id, data_view_id } = widget;
    return ServiceDataLiveDataResource.getData(data_source_id, data_view_id, params);
  }
}

export default new ServiceDataLiveWidgetDataResource();
