import angular from 'angular';
import $ from 'jquery';

import dashboardGridHtmlUrl from './dashboard.grid.html';

angular.module('exportbuilder.dashboard.directives')
    .directive('exportBuilderDashboardGrid', exportBuilderDashboardGrid);

/**
 * @ngInject
 */
function exportBuilderDashboardGrid(
    $timeout,
    PageGridConstants,
    $ExportBuilderDashboardEvents,
    $ExportBuilderItemDirectiveEvents,
    PubSub
) {
    return {
        restrict: 'A',
        templateUrl: dashboardGridHtmlUrl,
        link: $postLink
    };

    function $postLink($scope, $el) {
        var _displayFirstTime = true;
        var _isGridModeOn = false;

        var $parent = $el.find('.export-builder-dashboard-grid');
        var $page = $el.parents('.page-content');

        _setGridMode(false);

        _registerEvents();
        $scope.$on('$destroy', function () {
            _unregisterEvents();
        });

        function _onRotatePage() {
            _resetGrid();
        }

        function _displayGrid() {
            var gridSizeX = $page.width()/PageGridConstants.Y;
            var gridSizeY = $page.height()/PageGridConstants.Y;

            var $grid = $el.find('pattern#grid');
            var $path = $grid.find('path');

            $grid.attr('width', gridSizeX);
            $grid.attr('height', gridSizeY);

            var d = 'M '+gridSizeX+' 0 L 0 0 0 '+gridSizeY;
            $path.attr('d', d);
        }

        function _resetGrid() {
            $timeout(function () {
                _displayGrid();
            }, 500, false);
        }

        function _onItemResizing(isResizing) {
            if (_isGridModeOn) {
                return;
            }
            _setGridMode(isResizing);
        }

        function _onGridMode(isGridModeOn) {
            _isGridModeOn = isGridModeOn;
            _setGridMode(isGridModeOn);
        }

        function _setGridMode(gridModeOn) {
            if (gridModeOn && _displayFirstTime) {
                _displayGrid();
                _displayFirstTime = false;
            }

            if (!gridModeOn) {
                _resetGrid();
            }

            $parent = $('div.export-builder-dashboard-grid');
            $parent.css('opacity', gridModeOn ? '1.0' : '0.0');
        }

        function _registerEvents() {
            PubSub.on($ExportBuilderDashboardEvents.ON_PAGE_ROTATE, _onRotatePage);
            PubSub.on($ExportBuilderItemDirectiveEvents.IS_RESIZING, _onItemResizing);
            PubSub.on($ExportBuilderDashboardEvents.IS_GRID_MODE, _onGridMode);
        }

        function _unregisterEvents() {
            PubSub.off($ExportBuilderDashboardEvents.ON_PAGE_ROTATE, _onRotatePage);
            PubSub.off($ExportBuilderItemDirectiveEvents.IS_RESIZING, _onItemResizing);
            PubSub.off($ExportBuilderDashboardEvents.IS_GRID_MODE, _onGridMode);
        }
    }

}