'use strict';
import angular from 'angular';

import goalsAdminHtmlUrl from './goals.admin.html';
import goalsLegendHtmlUrl from './goals.legend.html';

angular.module('goals.components', [])

    .component('goalsAdmin', {
        templateUrl: goalsAdminHtmlUrl,
        bindings: {
            currentModule: '<',
            state: '<',
            des: '<',
            services: '<',
            showDetailsModal: '<'
        },
        controllerAs: 'vm',
        controller: GoalsAdminController
    })
    .component('goalsLegend', {
        templateUrl: goalsLegendHtmlUrl,
        bindings: {
            currentModule: '<',
            design: '<'
        },
        controllerAs: 'vm',
        controller: GoalsLegendController
    });

/**
 * @ngInject
 */
function GoalsAdminController(
    PubSub,
    $timeout,
    $isoEvents,
    $GoalsEvents,
    $WidgetEvents,
    AppFactory,
    AppModule,
    GoalsService,
    GoalsResource,
    $LayoutEvents,
    GoalsModelFactory,
    SmartCampaignService,
    SmartCampaignResource,
    DesignFactory,
    GoalsUIService
) {
    let vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.initAddGoal = initAddGoal;
    vm.isEmptyGoalsDashboard = isEmptyGoalsDashboard;
    vm.isEmptyFilteredGoals = false;
    vm.clearFilters = clearFilters;
    vm.pageHasWidgets = DesignFactory.pageHasWidgets;

    function $onInit() {
        vm.pageName = 'Goals';
        GoalsService.setServices(vm.services);

        _registerEvents();
    }

    function _setDesign() {
        if (AppFactory.isUserModuleAvailable(AppModule.SMART_CAMPAIGNS)) {
            SmartCampaignResource.getAll().then(function (data) {
                SmartCampaignService.setSmartCampaigns(data);
                // Necessary for smart campaign title display
                vm.design = vm.des;
            });
        } else {
            $timeout(function() {
                vm.design = vm.des;
            })
        }
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * Initialize add/edit service client mapping modal
     */
    function initAddGoal() {
        vm.state.showAddServiceClientModal = true;
        PubSub.emit($GoalsEvents.IS_READY, false);
        GoalsUIService.initModal();
    }

    function isEmptyGoalsDashboard() {
        return vm.design && !vm.pageHasWidgets();
    }

    function clearFilters() {
        PubSub.emit($GoalsEvents.CLEAR_FILTERS);
    }

    /**
     *
     * @param loadingState
     * @private
     */
    function _setLoadingState(loadingState) {
        $timeout(function() {
            vm.state.loadingState = loadingState;
        });
    }

    function _rebuildLayout() {
        DesignFactory.$rebuildPage();
    }

    function _checkFilterState() {
        $timeout(function() {
            vm.isEmptyFilteredGoals = angular.element('#goals-dashboard').find('div.design-widget:visible').length === 0;
        }, 0);
    }

    /**
     *
     * @private
     */
    function _registerEvents() {
        PubSub.on($GoalsEvents.LOADING_STATE, _setLoadingState);
        PubSub.on($WidgetEvents.WIDGET_REBUILD, _rebuildLayout);
        PubSub.on($isoEvents.FILTER, _checkFilterState);
        PubSub.on('design:page:callback', _setDesign);
    }

    /**
     *
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($GoalsEvents.LOADING_STATE, _setLoadingState);
        PubSub.off($WidgetEvents.WIDGET_REBUILD, _rebuildLayout);
        PubSub.off($isoEvents.FILTER, _checkFilterState);
        PubSub.off('design:page:callback', _setDesign);
    }
}

/**
 *
 * @ngInject
 */
function GoalsLegendController(
    PubSub,
    $GoalsEvents,
    $isoEvents,
    $timeout
) {
    let vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.applyFilter = applyFilter;
    vm.applyView = applyView;
    vm.clickOnSearch = clickOnSearch;
    vm.makeSearchInactive = makeSearchInactive;
    vm.cancelSearch = cancelSearch;
    vm.viewDetails = false;
    vm.isTextSearchActive = false;
    vm.applySearchFilter = _.debounce(applySearchFilter, 200);
    vm.currentFilter = '*';
    vm.searchFilter = '';
    vm.isNUI = window.isNUI;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * Apply Isotope Filter
     * @param value
     */
    function applyFilter(value) {
        if (vm.currentFilter === value) {
          return;
        }
        vm.searchFilter = '';
        vm.currentFilter = value;
        PubSub.emit($isoEvents.FILTER, value);
    }

    function applySearchFilter() {
        PubSub.emit($isoEvents.SEARCH, vm.searchFilter);
    }

    function applyView(viewDetails) {
        vm.viewDetails = viewDetails;
        PubSub.emit($GoalsEvents.RESIZE, vm.viewDetails);
    }

    /**
     */
    function clickOnSearch($event) {
        angular.element($event.currentTarget).find('input').focus();
        vm.isTextSearchActive = true;
    }

    function makeSearchInactive() {
        if (_.isEmpty(vm.searchFilter)) {
            vm.isTextSearchActive = false;
        }
    }

    function cancelSearch() {
        vm.searchValue = null;
        $timeout(function() {
            vm.isTextSearchActive = false;
        }, 0);
    }

    function _clearFilters() {
        vm.currentFilter = '*';
        PubSub.emit($isoEvents.FILTER, '*');
    }

    /**
     *
     * @private
     */
    function _registerEvents() {
        PubSub.on($GoalsEvents.CLEAR_FILTERS, _clearFilters);
    }

    /**
     *
     * @private
     */
    function _unregisterEvents() {
        PubSub.on($GoalsEvents.CLEAR_FILTERS, _clearFilters);
    }
}