<template>
  <div class="shimmer shimmer-chart" :style="shimmerStyle"></div>
</template>

<script>
export default {
  props: {
    shimmerStyle: {
      type: Object,
      required: true,
    },
  },
};
</script>
