'use strict';
import angular from 'angular';

angular.module('core.alert.ctrls', [])

    .controller('AlertDashController', AlertDashController);


/**
 * @ngInject
 */
function AlertDashController (
    PubSub,
    $AlertEvents,
    $scope
) {
    $scope.initAddAlert = function() {
        PubSub.emit($AlertEvents.INIT_ALERT);
    };
}