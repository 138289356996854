import angular from "angular";
import exportBuilderPublishModalHtml from 'coreModules/exportbuilder/dashboard/publish/exportbuilder.publish.modal.html';

angular.module('exportbuilder.publish.components', [])
    .component('exportBuilderPublishModal', {
        bindings: {
        
        },
        templateUrl: exportBuilderPublishModalHtml,
        controller: ExportBuilderPublishModalController,
        controllerAs: 'vm'
    });

/**
 *  @ngInject
 */
function ExportBuilderPublishModalController(
    PubSub,
    ExportBuilderPublishConstants,
    TagPickerSelectConstant,
    $ExportBuilderTemplatePublishModalEvents,
    ExportBuilderPublishModelFactory,
    ExportBuilderTagService,
    ExportBuilderPublishService,
    ExportBuilderService,
    UIFactory
) {
    
    const vm = this;
    
    vm.reportToPublish = null;
    
    /**
     * @type {LibraryPublishTagPickerSelectOptions}
     */
    vm.selectOptions = ExportBuilderPublishModelFactory.getExportBuilderPublishTagPickerSelectOptions();
    
    /**
     * @type {LibraryPublishItemFormModel}
     */
    vm.model = ExportBuilderPublishModelFactory.getExportBuilderPublishItemFormModel(vm.model);
    
    vm.selectCB = selectCB;
    vm.onTextChangeCallback = onTextChangeCallback;
    vm.onPublishClicked = onPublishClicked;
    
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.closeModal = closeModal;
    
    function $onInit() {
        vm.isActive = false;
        _registerEvents();
    }
    
    function $onDestroy() {
        vm.isActive = false;
        _unregisterEvents()
    }
    
    function closeModal() {
        vm.model = {};
        UIFactory.hideModal(ExportBuilderPublishConstants.PUBLISH_MODAL_ID);
        vm.isActive = false;
        vm.reportToPublish = null;
    }
    
    async function onPublishClicked() {
        try {
            vm.isPublishing = true;
            const reportId = await ExportBuilderPublishService.publish(vm.model);
            UIFactory.notify.showSuccess(`${vm.reportToPublish.title} published`);
            ExportBuilderService.refreshThumbnail({id: reportId});
            closeModal();
        } catch (e) {
            UIFactory.notify.showError(e.message);
        } finally {
            vm.isPublishing = false;
        }
    }

    function onHiddenPublishClicked() {
      vm.isAutoClicked = true;
    }
    
    /**
     * Callback on select2 change
     */
    function selectCB($el, event) {
        const data = $el.select2('data');
        
        if (event.added) {
            if (event.added.id === TagPickerSelectConstant.PLACEHOLDER_ID && _.isEmpty(vm.model.searchText)) {
                // placeholder was pressed with no text entered
                _.remove(data, {id: TagPickerSelectConstant.PLACEHOLDER_ID });
                $el.select2('data', data);
                return;
            } else if (event.added.id === TagPickerSelectConstant.PLACEHOLDER_ID && !_.isEmpty(vm.model.searchText)) {
                // placeholder was selected with text entered
                _.remove(data, {id: TagPickerSelectConstant.PLACEHOLDER_ID });
                data.push({id: vm.model.searchText, text: vm.model.searchText});
                $el.select2('data', data);
                vm.model.tags.push(vm.model.searchText);
            } else {
                vm.model.tags.push(event.added.text);
            }
        } else if (event.removed) {
            _.remove(vm.model.tags, function (tag) { return tag === event.removed.text })
        }
        vm.selectOptions.didSelect();
    }
    
    /**
     * Callback when select2 text box changes
     * @param text
     */
    function onTextChangeCallback(text) {
        vm.model.searchText = text;
    }

    function _onOpen(report) {
      vm.isActive = true;
      vm.isAutoClicked = false;
      vm.reportToPublish = report;
      vm.model =
        ExportBuilderPublishModelFactory.getExportBuilderPublishItemFormModel(
          vm.model
        );
      vm.model.report_id = vm.reportToPublish.id;
      _fetchTags();
    }

    function _fetchTags() {
      ExportBuilderTagService.getTags().then(function (data) {
        vm.tags = data;
        vm.tags.unshift({
          id: TagPickerSelectConstant.PLACEHOLDER_ID,
          text: TagPickerSelectConstant.PLACEHOLDER_TAGS,
        });
          /**
           * Triggering click action on a hidden button
           * So that the tags values will be shown in the modal
           */
        $("#tags-publish-button").trigger("click");
      });
    }
    
    function _registerEvents() {
        PubSub.on($ExportBuilderTemplatePublishModalEvents.OPEN, _onOpen);
    }
    
    function _unregisterEvents() {
        PubSub.off($ExportBuilderTemplatePublishModalEvents.OPEN, _onOpen);
    }
    
}
