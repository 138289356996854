'use strict';
import angular from 'angular';
import EventEmitter from 'wolfy87-eventemitter';

angular.module('core.pubsub.services', [])
    .service('PubSub', PubSub);

/**
 * @class A Pub Sub pattern implementation using an EventEmitter like the one from Node.js env.
 *        https://github.com/Olical/EventEmitter/blob/master/docs/guide.md
 * @constructor
 * @ngInject
 */
function PubSub(
    $timeout
) {
    var eventEmitter = new EventEmitter();

    this.on = on;
    this.once = once;
    this.off = off;
    this.emit = emit;
    this.$emit = $emit;
    this.get = get;

    /**
     * Receive the event
     * @param {String} eventName Event you wish to register for changes
     * @param {Function} callback
     */
    function on(eventName, callback) {
        eventEmitter.addListener(eventName, callback);
    }

    /**
     * Receive the event only once
     * @param {String} eventName Event you wish to register only once for changes
     * @param {Function} callback The callback you wish to trigger when event occurs
     */
    function once(eventName, callback) {
        eventEmitter.addOnceListener(eventName, callback);
    }

    /**
     * Remove listener
     * @param {String} eventName Event you wish to un-register
     * @param {Function} callback The callback you wish to trigger when event occurs
     *
     * @throws PubSubException Throws if second paramater was omitted when calling this function
     */
    function off(eventName, callback) {
        if (!callback) {
            throw new PubSubException("Second parameter is required to remove listener for event: " + eventName)
        }
        eventEmitter.removeListener(eventName, callback);
    }

    /**
     * Get listeners based on eventName
     * @param {String} eventName
     * @return {*}
     */
    function get(eventName) {
        if (!eventName) {
            throw new PubSubException("eventName parameter is required");
        }
        return eventEmitter.getListeners(eventName);
    }

    /**
     * Broadcast the event to subscribers and triggering a digest
     * @param {String} eventName Event you wish to emit to subscribers
     * @param {anything} [data] Data object you wish to sent
     */
    function $emit(eventName, data) {
        emit(eventName, data, true);
    }

    /**
     * Broadcast the event to subscribers
     * @param {String} eventName Event you wish to emit to subscribers
     * @param {anything} [data] Data object you wish to sent
     * @param {Boolean} [forceDigest] Optional flag to trigger a $digest when emitting the event
     */
    function emit(eventName, data, forceDigest) {
        if (!get(eventName).length) { return; } // don't emit if no listeners

        forceDigest
            ? _emitWithDigest()
            : _emit();

        /**
         * Private scoped functions to this closure
         */
        function _emit() {
            eventEmitter.emitEvent(eventName, [data]);
        }
        function _emitWithDigest() {
            $timeout(function () {
                _emit();
            }, 0);
        }
    }

    /**
     * PubSubException
     * @param {String} message
     * @constructor
     */
    function PubSubException(message) {
        this.message = message;
        this.name = 'PubSubException';
    }

}