import { ChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/ChartWidgetMetadata';
import { BubbleChartDrawOption } from '@/modules/ta/widget/models/drawoptions/BubbleChartDrawOption';

export class BubbleChartWidgetMetadata extends ChartWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new BubbleChartDrawOption(model.draw_options);
  }
}
