import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { color, invertColor, isDateAxis } from '@/modules/core/charts/am5/charts.helper';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';
import { Tooltip } from '@amcharts/amcharts5';
import { useAxes } from '@/modules/core/charts/am5/base/composables/axis/useAxes';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export function useXYTooltip(context) {
  const { chart, isDarkTheme, config, root } = context();
  const { formatLabel } = useLabels(context);
  const { getCategoryAxis } = useAxes(context);

  function createTooltip() {
    const { isInlineDrillDown, isStacked } = config.value;
    if (isInlineDrillDown) {
      chart.value.series.each((series) => {
        const tooltip = series.get(Constant.TOOLTIP);
        tooltip.adapters.add(Constant.LABEL_HTML, () =>
          wrapTooltipContent(getSingleSeriesTooltipText(series, true))
        );
      });
    } else {
      chart.value.series.each((parentSeries) => {
        const tooltip = parentSeries.get(Constant.TOOLTIP);
        if (!tooltip) {
          return;
        }
        tooltip.adapters.add(Constant.LABEL_HTML, () => {
          let tooltipContent = '';
          const series = isStacked
            ? chart.value.series.values.slice().reverse()
            : chart.value.series.values;
          series.forEach((singleSeries) => {
            tooltipContent += getSingleSeriesTooltipText(singleSeries, tooltipContent === '');
          });
          return wrapTooltipContent(tooltipContent);
        });
      });
    }
  }

  function wrapTooltipContent(content) {
    // eslint-disable-next-line tap/no-raw-text-js
    const textColor = isDarkTheme.value ? '#ffffff' : '#000000';
    // eslint-disable-next-line tap/no-raw-text-js
    return `<div style="max-width: 500px; font-size: 12px; color: ${textColor}">${content}</div>`;
  }

  function getSingleSeriesTooltipText(series, attachHeader = false) {
    const props = series.get(Constant.USER_DATA);
    const tooltipDataItem = series.get(Constant.TOOLTIP_DATA_ITEM);
    const dataItem = tooltipDataItem?.dataContext;
    let labelHTML = '';
    if (dataItem && !props.tooltipDisabled && series.isVisible()) {
      if (attachHeader) {
        const categoryAxis = getCategoryAxis().get(Constant.USER_DATA);
        if (
          categoryAxis.dataItemFormat !== ColumnFormat.FORMAT_STRING &&
          isDateAxis(categoryAxis.dataItemFormat)
        ) {
          // eslint-disable-next-line tap/no-raw-text-js
          const currentDate = dataItem[`formatted_${categoryAxis.category}`];
          // eslint-disable-next-line tap/no-raw-text-js
          const comparisonDate = dataItem[`formatted_${categoryAxis.category}_comparison`];
          labelHTML += comparisonDate
            ? // eslint-disable-next-line tap/no-raw-text-js
              `<strong>${currentDate} vs ${comparisonDate}</strong><br/>`
            : // eslint-disable-next-line tap/no-raw-text-js
              `<strong>${currentDate}</strong><br/>`;
        } else {
          // eslint-disable-next-line tap/no-raw-text-js
          labelHTML += `<strong>${dataItem[props.category]}</strong><br/>`;
        }
      }
      const fill = !config.value.isSparkLine ? series.get(Constant.FILL) : color(props.labelFill);
      const name = series.get(Constant.NAME);
      const nameColor = invertColor(fill).toString();
      const nameBackground = fill.toCSS(props.opacity);
      const value = formatLabel('', dataItem[props.value], props.dataItemFormat, dataItem);
      // eslint-disable-next-line tap/no-raw-text-js
      labelHTML += `<p style="margin: 3px 0;"><span style="background-color: ${nameBackground}; padding: 1px 5px; border-radius: 3px; margin-right: 5px; color: ${nameColor}">${name}</span> ${value}</p>`;
    }
    return labelHTML;
  }

  function getNewDefaultTooltip() {
    const { isRotated, isSparkLine } = config.value;
    const tooltip = Tooltip.new(root.value, {
      getFillFromSprite: false,
      pointerOrientation: isRotated ? Constant.HORIZONTAL : Constant.VERTICAL,
      marginTop: 10,
      paddingTop: 8,
      paddingBottom: 3,
      paddingLeft: 8,
      paddingRight: 8,
    });
    tooltip.get(Constant.BACKGROUND).setAll({
      fill: isDarkTheme.value ? color(0x333333) : color(0xffffff),
      stroke: isDarkTheme.value ? color(0x333333) : color(0x00000),
      strokeWidth: 0,
      fillOpacity: isSparkLine ? 0.7 : 1,
    });
    return tooltip;
  }

  return {
    createTooltip,
    getNewDefaultTooltip,
  };
}
