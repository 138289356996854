"use strict";
import $ from 'jquery';

var Core = $.core.login = {
    value: null,
    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {
        setTimeout(function() {
            Core.setEmailTooltip($('#email'));
        }, 0);
    },
    events: function () {
        $('#email').trigger('focus');
    },
    setEmailTooltip: function ($elm) {
        if ($elm.attr('id') == 'email') {
            $elm.tooltip({
                container: 'body',
                placement: 'right',
                trigger: 'focus'
            });
        }
    }
};