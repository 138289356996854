'use strict';
import angular from 'angular';
import './datasource.ctrls';
import './datasource.directives';
import './datasource.services';

angular.module('core.datasource', [
    'datasource.ctrls',
    'datasource.directives',
    'datasource.services'
]);