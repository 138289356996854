import _, {
  uniqBy,
  last,
  first,
  forEach,
  map,
  intersection,
  invert,
  maxBy,
  isNull,
  isUndefined,
  reject,
  toNumber,
} from 'lodash';
import tinycolor from 'tinycolor2';
import {
  LAT_LONG,
  COUNTRY,
  STATE,
  CITY,
  COUNTY,
  ZIP_CODE,
  DMA,
  CONGRESSIONAL_DISTRICT,
  LAYERS_PRIORITY,
  THE_TRADE_DESK_ID,
  FACEBOOK_ADS_ID,
} from '@/modules/ta/widget/mapbox.constants';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import NumeralService from '@/modules/core/app/services/NumeralService';

export class MapboxRenderService extends BaseService {
  getUniqueId(name, id) {
    return `${name}-${id}`;
  }

  canShowLatLongLayer(widget) {
    return LAT_LONG === this.getMaxPriorityLayer(widget);
  }

  canShowZipCodeLayer(widget) {
    return ZIP_CODE === this.getMaxPriorityLayer(widget);
  }

  canShowCountryLayer(widget) {
    return COUNTRY === this.getMaxPriorityLayer(widget);
  }

  canShowStateLayer(widget) {
    return STATE === this.getMaxPriorityLayer(widget);
  }

  canShowCityLayer(widget) {
    return CITY === this.getMaxPriorityLayer(widget);
  }

  canShowCountyLayer(widget) {
    return COUNTY === this.getMaxPriorityLayer(widget);
  }

  canShowDmaLayer(widget) {
    return DMA === this.getMaxPriorityLayer(widget);
  }

  canShowCongressionalDistrictLayer(widget) {
    return CONGRESSIONAL_DISTRICT === this.getMaxPriorityLayer(widget);
  }

  /**
   * @param widget
   * @returns {{field: *, precision: *, format: *, label: *}[]}
   */
  getSelectedColumns(widget) {
    return map(widget.metadata.data_columns.selected, (column) => ({
      field: column.field,
      label: column.label,
      format: column.format,
      precision: column.precision,
      color: column.color,
    }));
  }

  getPopupHtml(property) {
    const grouped = JSON.parse(property.grouped);
    // eslint-disable-next-line tap/no-raw-text-js
    let html = `<b style='color: black;'>${grouped.field}</b>`;
    const columns = JSON.parse(property.columns);
    forEach(columns, (column) => {
      // eslint-disable-next-line tap/no-raw-text-js
      html += `<div style="color: black; margin-bottom: 5px"><span style='background-color: ${column.color}; padding: 4px; color: #ffffff'>${column.label}</span> ${column.field} </div>`;
    });
    return html;
  }

  transformData(widget, data, chartPalette) {
    const features = [];
    const dataColumns = this.getSelectedColumns(widget);
    const groupedColumn = first(widget.metadata.data_columns.grouped);

    forEach(data, (row) => {
      const properties = {};
      let groupedField = row[groupedColumn.groupby_name_field];
      if (groupedColumn.format === 'datetime') {
        groupedField = new Date(groupedField * 1000).toString('MMM dd, yyyy');
      } else if (!['id', 'string'].includes(groupedColumn.format)) {
        groupedField = NumeralService.formatValue(
          row[groupedColumn.groupby_name_field],
          groupedColumn.format,
          groupedColumn.precision,
          groupedColumn.isCompactNumber,
          groupedColumn.currencySymbol
        );
      }
      properties.grouped = {
        field: groupedField,
      };
      const columns = [];
      forEach(dataColumns, (column, index) => {
        columns.push({
          field: NumeralService.formatValue(
            row[column.field],
            column.format,
            column.precision,
            column.isCompactNumber,
            column.currencySymbol
          ),
          label: column.label,
          color:
            column.color ||
            this.getPaletteColor(index, widget.metadata.chart_palette || chartPalette),
        });
      });
      properties.columns = columns;
      features.push({
        type: 'Feature',
        properties,
        geometry: {
          type: 'Point',
          coordinates: [row.geo_longitude, row.geo_latitude, 4],
        },
      });
    });

    return {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features,
    };
  }

  static getSelectedColumn(widget) {
    const columns =
      widget.metadata.data_source.mapbox_config.layers[
        widget.data_view_id || widget.metadata.data_source.data_view
      ];
    const selectedColumns = widget.metadata.data_columns.grouped.map((column) => {
      if ([THE_TRADE_DESK_ID, FACEBOOK_ADS_ID].includes(widget.metadata.data_source.id)) {
        return column.groupby_name_field;
      }
      return column.field;
    });
    const commonColumns = intersection(Object.values(columns), selectedColumns);
    return maxBy(commonColumns, (layer) => LAYERS_PRIORITY[invert(columns)[layer]]);
  }

  /**
   * @param value
   * @returns {boolean}
   */
  static isValidGeoConfig(value) {
    return ![null, undefined, '00', '0', '', '-', '--', 'undefined', 'null'].includes(value);
  }

  /**
   * @param value
   * @returns {*}
   */
  static getValueFromGeoConfig(value) {
    if (!value) return null;
    const details = value.split('-');
    let count = details.length - 1;
    while (count >= 0) {
      if (this.isValidGeoConfig(details[count])) {
        return details[count].toUpperCase();
      }
      count -= 1;
    }
    return null;
  }

  /**
   * @param index
   * @param chartPalette
   * @returns {*}
   */
  getPaletteColor(index, chartPalette) {
    const colorDiversity = 10;
    const maxOffset = 100;

    // If index is beyond color palette, generate a color
    if (!chartPalette[index]) {
      const baseIndex = index > maxOffset ? index - 90 : index;

      let chartBaseColor = chartPalette[baseIndex - colorDiversity];

      // Avoid to generate same color it the color is greyscale
      const offset = index === 0 ? 0 : index / chartPalette.length;
      chartBaseColor = tinycolor(chartBaseColor).lighten(offset).desaturate(offset).toString();

      let newColor = uniqBy(
        tinycolor(chartBaseColor)
          .analogous(colorDiversity * 2, colorDiversity)
          .map((t) => t.toHexString())
      );
      newColor = last(newColor);
      chartPalette[index] = newColor;
    }
    return chartPalette[index];
  }

  /**
   * @param color
   * @param gradient_percentage
   * @returns {string}
   */
  static getGradientColor(color, gradient_percentage) {
    return `#${_(color.replace('#', ''))
      .chunk(2)
      .map((v) => parseInt(v.join(''), 16))
      .map((v) =>
        (0 | ((1 << 8) + v + ((256 - v) * gradient_percentage) / 100)).toString(16).substring(1)
      )
      .join('')}`;
  }

  /**
   * @param data
   * @param field
   * @returns {*}
   */
  static getColumnGradients(data, field) {
    let highestToLowest = data
      .map((item) => item[field])
      .sort((a, b) => this.convertTimeToNumber(b) - this.convertTimeToNumber(a));
    highestToLowest = highestToLowest.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    return highestToLowest;
  }

  /**
   * @param highestToLowest
   * @param value
   * @returns {string|number}
   */
  static getColumnGradientValue(highestToLowest, value) {
    let gradientValue = 10;
    const valueIndex = highestToLowest.indexOf(value?.value || value);
    gradientValue = (gradientValue / highestToLowest.length) * valueIndex * 10;
    return !Number.isNaN(gradientValue) ? gradientValue.toFixed() : 0;
  }

  /**
   * @param t
   * @returns {number|*}
   */
  static convertTimeToNumber(t) {
    if (isNull(t)) {
      return t;
    }
    t = typeof t !== 'string' ? t.toString() : t;
    return +t.replace(/:/g, '');
  }

  /**
   * @param data
   * @param widget
   * @param field
   * @returns {*|unknown[]|*[]}
   */
  getNonZeroFieldData(data, widget, field) {
    if (isNull(data) || isUndefined(data) || isNull(field) || isUndefined(field)) {
      return [];
    }
    if (widget.metadata.draw_options.hide_zero_value) {
      return reject(data, (row) => toNumber(row[field]) === 0);
    }
    return data;
  }

  /**
   * @param widget
   * @returns {{}}
   */
  getMaxPriorityLayer(widget) {
    const { layers } = widget.metadata.draw_options;
    return maxBy(layers, (layer) => LAYERS_PRIORITY[layer]);
  }
}
