'use strict';
import angular from 'angular';
import './media/widget.media.module';
import './chart/widget.chart.module';
import './admin/widget.admin.module';
import './datagrid/widget.datagrid.module';
import './bignumber/widget.bignumber.module';
import './chatgpt/widget.chatgpt.module';
import './executivesummary/widget.executivesummary.module';

angular.module('widget.type', [
    'widget.media',
    'widget.chart',
    'widget.admin',
    'widget.datagrid',
    'widget.bignumber',
    'widget.mapbox',
    'widget.chatgpt',
    'widget.executivesummary'
]);