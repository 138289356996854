import angular from 'angular';

angular.module('core.tag.values.ctrls', [])
    .controller('TagValuesController', TagValuesController);

/**
 * @ngInject
 */
function TagValuesController(
    TagValuesFactory
) {
    var vm = this;
    vm.addTagValueClicked = addTagValueClicked;

    function addTagValueClicked() {
        TagValuesFactory.setNewDetails()
    }
}