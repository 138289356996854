import { commits, dispatches, getters } from '@/modules/core/app/helpers/store';
import NavService from '@/modules/core/nav/services/NavService';

export const actions = {
  async getAppNavItems() {
    commits.nav.setAppNavItems(await NavService.getAppNavItems());
  },
  async refreshAppNavItems() {
    commits.nav.setAppNavItems(undefined);
    await dispatches.nav.getAppNavItems();
  },
  toggleShowFilterButton(state, { value, option }) {
    commits.nav.setShowFilterButton({ value, option });
  },
  toggleShowCommentButton(state, value) {
    commits.nav.setShowCommentButton(value);
  },
  toggleShowActivityLogButton(state, value) {
    commits.nav.setShowActivityLogButton(value);
  },
  /**
   * @param state
   * @param value
   */
  toggleFileButton({ state }, value) {
    const arr = [...state.showFileButton, ...value];
    const uniqueArr = [...new Set(arr)];
    commits.nav.setShowFileButton(uniqueArr);
  },
  toggleShowActivityOrCommentsOrFiles(state, value) {
    commits.nav.setIsShowingActivityOrCommentsOrFile(value);
  },
  setShowingFilesModalData(state, value) {
    commits.nav.setShowingFilesModal(value);
  },
  updateCurrentAppNavItem({}, { key = null, path }) {
    if (!key) {
      key = NavService.resolveActiveAppNavItem(getters.nav.getAppNavItems(), path);
    }
    commits.nav.setCurrentNavItemKey(key);
  },
};
