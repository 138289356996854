import { PieChart } from '@amcharts/amcharts5/percent';
import { percent } from '@amcharts/amcharts5';
import { usePieSeries } from '@/modules/core/charts/am5/pie/composables/usePieSeries';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { PieChartConstant } from '@/modules/core/charts/am5/charts.constants';

export function usePieChart(context) {
  const { root, chart, config } = context();

  const { createAllSeries } = usePieSeries(context);
  function initPieChart() {
    createPieChart();
    createAllSeries();
    root.value.numberFormatter.setAll({
      numberFormat: '#,###.##',
    });
  }
  function createPieChart() {
    const pieRadius = config.value.showLabels ? setPieRadius() : { radius: percent(100) };
    chart.value = root.value.container.children.push(
      PieChart.new(root.value, {
        pinchZoomX: true,
        pinchZoomY: true,
        layout: root.value.verticalLayout,
        ...defaultInnerRadius(),
        startAngle: config.value.startAngle,
        endAngle: config.value.endAngle,
        ...pieRadius,
      })
    );
  }
  function defaultInnerRadius() {
    const { width, height } = config.value.widgetData;
    if (
      config.value.plotType === PieChartConstant.PLOT_DONUT &&
      config.value.innerRadius < PieChartConstant.DEFAULT_INNER_RADIUS
    ) {
      return omitNils({
        innerRadius: PieChartConstant.DEFAULT_INNER_RADIUS,
      });
    }
    if (config.value.plotType === PieChartConstant.PLOT_V2_PIE) {
      return omitNils({
        innerRadius: PieChartConstant.ZERO,
      });
    }
    const { innerRadius } = config.value;
    const adjustedInnerRadius = (() => {
      if (width === 3 && innerRadius >= 50) return 50;
      if (width === 4 || width === 5) {
        if (innerRadius >= 60) return 60;
      }
      if (width === 6 && height <= 4 && innerRadius >= 70) return 70;
      return innerRadius;
    })();
    return omitNils({ innerRadius: adjustedInnerRadius });
  }

  /* Setting up radius of pie chart while we resize a pie chart widget */
  function setPieRadius() {
    const { width } = config.value.widgetData;
    const { pieSemiCircleAngle } = config.value;

    let radius = percent(config.value.radius);
    const piePercent = percent(80);
    switch (true) {
      case width === 3:
        radius = percent(40);
        break;
      case width > 3 && width <= 5:
        radius = pieSemiCircleAngle ? percent(25) : percent(47);
        break;
      case width >= 6 && width <= 7:
        radius = pieSemiCircleAngle ? percent(38) : percent(60);
        break;
      case width >= 8 && width <= 10:
        radius = pieSemiCircleAngle ? percent(60) : piePercent;
        break;
      case width >= 11 && width <= 12:
        radius = pieSemiCircleAngle ? percent(65) : piePercent;
        break;
      default:
        break;
    }
    return omitNils({ radius });
  }

  return {
    chart,
    initPieChart,
  };
}
