'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('servicedetails.directives', [])

    .directive('breakdownFilter', ['$state', function($state) {
        return {
            restrict: 'A',
            link: function(scope, el) {
                el.submit(function(e){
                    e.preventDefault();
                    var filters = '';
                    $(this).find('input:checked').each(function(){
                        filters += '&breakdown_filters[]=' +$(this).val();
                    });

                    $state.go('service.submetrics.filter',
                        {
                            service_id: $state.params.service_id,
                            data_view: $state.params.data_view,
                            reference_id: $state.params.reference_id,
                            filters: filters
                        });
                });
            }
        }
    }]);