import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BaseDataResource } from '@/modules/core/app/resources/BaseDataResource';

export class LineItemsWithTasksReportDataResource extends BaseDataResource {
  constructor() {
    super(RouteGroup.LINEITEMSWITHTASKSREPORTS);
  }
}

export default new LineItemsWithTasksReportDataResource();
