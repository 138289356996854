'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('importwizard.services', [])
    .factory('ImportWizardFactory', ImportWizardFactory);

/**
 * @ngInject
 */
function ImportWizardFactory(
    Restangular
) {
    let services = Restangular.service('importwizard');

    let getAuthTypes = function() {
        return services.one('get-values').getList('auth_type');
    };

    let getDateFormats = function() {
        return services.one('get-values').getList('date_format');
    };

    let getGenericInfo = function() {
        return services.one('get-values').getList('generic');
    };

    let getOauthUser = function(private_integration_id) {
        if (_.isEmpty(private_integration_id))
            return true;
        return services.one('oauthuser').getList(private_integration_id);
    };

    let getAWSBackupFiles = function(private_integration_id) {
        if (_.isEmpty(private_integration_id))
            return true;
        return services.one('getawsbackupfiles').getList(private_integration_id);
    };

    let getAvailableParentMapping = function(parent_private_integration_id) {
        return services.one('parents').getList(parent_private_integration_id);
    };

    let views = function(parent_private_integration_id) {
        return services.one('views').getList(parent_private_integration_id);
    };

    let getSCModules = function() {
        return services.one('modules').get();
    };

    return {
        services: services,
        getAuthTypes: getAuthTypes,
        getDateFormats: getDateFormats,
        getOauthUser: getOauthUser,
        getAWSBackupFiles: getAWSBackupFiles,
        getAvailableParentMapping: getAvailableParentMapping,
        views: views,
        getSCModules: getSCModules,
        getGenericInfo: getGenericInfo
    }
}
