'use strict';
import angular from 'angular';

angular.module('feedbacks.services', [])

    .service('FeedbacksFactory', FeedbacksFactory);

FeedbacksFactory.$inject = ['Restangular'];
function FeedbacksFactory(Restangular) {
    var feedbacks = Restangular.service('feedbacks');
    var save = function(model){
        return feedbacks.post(model);
    };

    return {
        feedbacks: feedbacks,
        save: save
    };
}