'use strict';
import angular from 'angular';
import _ from 'lodash';

import directiveGeoLocationListHtmlUrl from '../views/directive_geoLocationList.html';
import directiveLocationSearchListHtmlUrl from '../views/directive_locationSearchList.html';

angular.module('lineitems.directives', [])
    .directive('geoLocationList', function(FormService) {
        return {
            restrict: 'E',
            templateUrl: directiveGeoLocationListHtmlUrl,
            scope: {
                selectedGeoLocations: '=',
                clusterId: '=',
                showActions: '='
            },
            controller: function($scope, ClusterService, $stateParams) {
                $scope.orderId = $stateParams.orderId;
                $scope.rootLocation = { "id": 0, "children": "1", "criteria": "" };
                $scope.navLocations = [];
                $scope.previousLocationData = [];
                $scope.geolocSearch = "";
                $scope.breadcrumbs = [];
                $scope.collectedNodes = [0];
                $scope.includeOrExcludeLocation = null;
                var geoLocationsCount = 0;
                $scope.selectedListOfGeoLocations = [];

                $scope.showTabPanel = true;
                $scope.selectedInput = {
                    value: 'locations'                
                }                
                $scope.geoTextInputs = {   
                    inputValues: ''
                }                
                $scope.textInputOptions = ["US Postal Codes", "US States"];
                $scope.textInputObj = {                    
                    textInputType: "US Postal Codes"
               }                
               $scope.selectedCriteria = {
                    criteria: ""                
                };               
                $scope.criteria = ["include all", "exclude all"];
                $scope.showError = false;                                
                $scope.toggleComponent = function(selectedOption) {                    
                    $scope.selectedInput.value = selectedOption; 
               }
                $scope.$watch('geoTextInputs.inputValues', function() {
                    if($scope.geoTextInputs.inputValues.length == 0) {
                        $scope.showError = false;
                    }
                });

                $scope.handleChangeTextInput = function() {
                    $scope.geoTextInputs.inputValues = '';
                    $scope.showError = false;
                }

                $scope.$watchCollection('selectedGeoLocations.selLocationPickers', function (newCol, oldCol, scope) {
                    _.map(newCol, function (item) {
                        item.selectedInput = "locations";
                        $scope.selectedListOfGeoLocations.push(item)
                    })
                    $scope.selectedListOfGeoLocations = _.uniq($scope.selectedListOfGeoLocations);
                })

                $scope.$watchCollection('selectedGeoLocations.selTextInputs', function (newCol, oldCol, scope) {
                    _.map(newCol, function (item) { $scope.selectedListOfGeoLocations.push(item) })
                    $scope.selectedListOfGeoLocations = _.uniq($scope.selectedListOfGeoLocations);
                })

                /*
                * Handle validate postal codes
                */
                $scope.validateInputValues = function(){
                    if($scope.textInputObj.textInputType == "US Postal Codes") {
                        var geoValues = $scope.geoTextInputs.inputValues.split(/,| |\n/);
                        var filteredValues = geoValues.filter(function (el) {
                            return el != "";
                        });
                        FormService.validatePostalCodes(filteredValues).then(function(success) {
                            if(success) {
                                processTextInputs(success.data);
                           }                        
                        }, function(error) {
                            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
                        });
                    } else if($scope.textInputObj.textInputType == "US States") {
                        var geoValues = $scope.geoTextInputs.inputValues.split(/,|\n/);
                        var filteredValues = [];
                        _.each(geoValues, function(geoValue) {
                            if(geoValue !== "") {
                                var trimmedValue = geoValue.trim();
                                filteredValues.push(trimmedValue);
                            }
                        })
                        FormService.validateUSStates(filteredValues).then(function(success) {
                            if(success) {
                                processTextInputs(success.data);
                            }                        
                        }, function(error) {
                            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
                        });
                    }  
                }

                /*
                * Handle process GET response
                */
               var processTextInputs = function(response) {
                    if(response.valid_geo_values && response.valid_geo_values.length > 0) {
                        _.each(response.valid_geo_values, function(validGeoValue) {
                            validGeoValue.geoType = $scope.textInputObj.textInputType;
                            validGeoValue.criteria_link = 'included';
                            $scope.selectedGeoLocations.selTextInputs.push(validGeoValue);
                        })
                        $scope.geoTextInputs.inputValues = '';
                    } 
                    
                    if(response.invalid_geo_values && response.invalid_geo_values.length > 0){
                        $scope.showError = true;
                        $scope.geoTextInputs.inputValues = response.invalid_geo_values.toString();
                    }
                }

                $scope.getPostalCodesTooltips=function(selTextInputs){
                    var data = angular.copy(selTextInputs);
                        data = data.slice(5);
                    var html=angular.element("<ul/>");
                    angular.forEach(data,function(item){
                      html.append("<li style='list-style:none;'>"+item.value+"</li>");
                    });
                    return html.html();
                
                  };

                  $scope.getLocationTooltips=function(locations){
                    var data = angular.copy(locations);
                        data = data.slice(2);
                    var html=angular.element("<ul/>");
                    angular.forEach(data,function(item){
                      html.append("<li style='list-style:none;'>"+item.full_name+"</li>");
                    });
                    return html.html();
                
                  };

                /*
                * Handle remove selected text inputs
                */
                $scope.removeTextInputs = function(removeItem) {
                    var selIndex = _.findIndex($scope.selectedGeoLocations.selTextInputs, {external_id: removeItem.external_id});
                    if (selIndex >= 0) {
                        $scope.selectedGeoLocations.selTextInputs.splice(selIndex, 1);
                    }
                }
 
                /*
                * Handle Clear All Text Inputs
                */
                $scope.handleClearAllInputs = function() {
                    $scope.selectedGeoLocations.selTextInputs = [];
                }

                $scope.handleClearSelectedLocations = function() {
                    $scope.selectedGeoLocations.selLocationPickers = [];
                    $scope.selectedGeoLocations.selTextInputs = [];
                    $scope.selectedListOfGeoLocations = [];
                    $scope.selectedCriteria.criteria = 'none';
                    geoLocationsCount = 0;
                    $scope.includeOrExcludeLocation = null;
                    _.each($scope.navLocations, function(navLocation) {
                        navLocation.criteria = '';
                        navLocation.criteria_link = 'include';
                    });
                }

                $scope.handleChangeCriteria = function() {
                    $scope.selectedGeoLocations.selLocationPickers = [];
                    if($scope.selectedCriteria.criteria == 'include all') {
                        _.each($scope.navLocations, function(navLocation) {
                            navLocation.criteria = 'include';
                            navLocation.criteria_link = 'included';
                            navLocation.full_name = $scope.buildFullName(navLocation);
                            $scope.selectedGeoLocations.selLocationPickers.push(navLocation);
                        });
                    } else if($scope.selectedCriteria.criteria == 'exclude all') {
                        _.each($scope.navLocations, function(navLocation) {
                            navLocation.criteria = 'exclude';
                            navLocation.criteria_link = 'excluded';
                            navLocation.full_name = $scope.buildFullName(navLocation);
                            $scope.selectedGeoLocations.selLocationPickers.push(navLocation);
                        });
                    } else {
                        _.each($scope.navLocations, function(navLocation) {
                            navLocation.criteria = '';
                            navLocation.criteria_link = 'include';
                        });
                    }
                }

                $scope.getChildrenNodes = function(item) {
                    if (item.children > 0) {
                        if ($scope.collectedNodes.indexOf(item.id) < 0) {
                            FormService.getGeoLocationsById(item.id, ClusterService.clusterId, $scope.orderId).then(function(success) {
                                $scope.collectedNodes.push(item.id);
                                $scope.locations = $scope.locations.concat(success);
                                $scope.getLocationChilds(item);
                                $scope.geolocSearch = "";
                            }, function(error) {
                                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
                            });
                        } else {
                            $scope.getLocationChilds(item);
                        }
                    }
                };

                $scope.showtabPanel = function() {
                    $scope.showTabPanel = !$scope.showTabPanel;
                }

                $scope.getLocationChilds = function(item) {
                    if (item.children > 0) {
                        $scope.navLocations = [];
                        if ($scope.locations) {
                            for (var i = 0, len = $scope.locations.length; i < len; i++) {
                                if ($scope.locations[i].parent_id == item.id) {
                                    if ($scope.selectedGeoLocations.selLocationPickers.length > 0) {
                                        var selIndex = _.findIndex($scope.selectedGeoLocations.selLocationPickers,function(item) { return item.id == $scope.locations[i].id });
                                        if (selIndex !== -1) {
                                            $scope.locations[i].criteria = $scope.selectedGeoLocations.selLocationPickers[selIndex].criteria;
                                            $scope.includeOrExcludeLocation = $scope.selectedGeoLocations.selLocationPickers[selIndex].criteria + 'location';
                                        }
                                    } else{
                                        $scope.locations[i].criteria = "";
                                    }
                                    if ($scope.locations[i].criteria.length == 0 && item.criteria.length > 0) {
                                        $scope.locations[i].criteria = item.criteria;
                                    }
                                    $scope.locations[i].criteria_link = 'include';
                                    if ($scope.locations[i].criteria == "include") {
                                        $scope.locations[i].criteria_link = 'included';
                                    } else if ($scope.locations[i].criteria == "exclude") {
                                        $scope.locations[i].criteria_link = 'excluded';
                                    }
                                    $scope.navLocations.push($scope.locations[i]);
                                    $scope.checkingCriteria();
                                }
                            }
                            $scope.previousLocationData = angular.copy($scope.navLocations);
                        }
                        $scope.breadcrumbs = [];
                        $scope.buildBreadcrumbs(item);
                    }
                    if (item.criteria_link == 'included') {
                        $scope.includeOrExcludeLocation = null;
                    } else if(item.criteria_link == 'excluded'){
                        $scope.includeOrExcludeLocation = 'excludelocation';
                    }
                }

                $scope.changeIncludeExclude = function(item, criteria) {
                    var index = $scope.navLocations.indexOf(item);
                    if (index >= 0) {
                        if (criteria == "include") {
                            $scope.navLocations[index].criteria = 'include';
                            $scope.navLocations[index].criteria_link = 'included';
                        } else if (criteria == "exclude") {
                            $scope.navLocations[index].criteria = 'exclude';
                            $scope.navLocations[index].criteria_link = 'excluded';
                        } else {
                            $scope.navLocations[index].criteria = '';
                            $scope.navLocations[index].criteria_link = 'include';
                        }
                        if(geoLocationsCount < 1) {
                            $scope.includeOrExcludeLocation = criteria + 'location';
                        }
                        var selIndex = _.findIndex($scope.selectedGeoLocations.selLocationPickers, { id: $scope.navLocations[index].id });
                        $scope.navLocations[index].full_name = $scope.buildFullName($scope.navLocations[index])
                        if (selIndex >= 0) {
                            $scope.selectedGeoLocations.selLocationPickers[selIndex] = $scope.navLocations[index];
                        } else {
                            $scope.selectedGeoLocations.selLocationPickers.push($scope.navLocations[index]);
                        }
                        changeAllChildCriteria($scope.navLocations[index].id, $scope.navLocations[index].criteria);
                        for(var i=0; i<$scope.selectedGeoLocations.selLocationPickers.length; i++){
                            var parentId = parseInt($scope.selectedGeoLocations.selLocationPickers[i].parent_id);
                            var parentObj = _.find($scope.locations, {id: parentId});

                            if(parentObj && parentObj.criteria === $scope.selectedGeoLocations.selLocationPickers[i].criteria ){
                                $scope.selectedGeoLocations.selLocationPickers.splice(i, 1);
                            }
                        }
                        geoLocationsCount++;
                    }
                }

                $scope.removeLoc = function(item) {
                    if(item.selectedInput == 'locations'){
                        var selIndex = $scope.selectedGeoLocations.selLocationPickers.indexOf(item);
                        if (selIndex >= 0) {
                            $scope.selectedGeoLocations.selLocationPickers.splice(selIndex, 1);
                            $scope.selectedListOfGeoLocations.splice($scope.selectedListOfGeoLocations.indexOf(item),1);
                            if($scope.selectedGeoLocations.selLocationPickers.length == 0){
                                geoLocationsCount = 0;
                                $scope.includeOrExcludeLocation = null;
                            }
                        }
                        
                        var parentId = parseInt(item.parent_id);
                        var parentLocation = _.find($scope.locations, { id: parentId});
                        var locationObj = _.find($scope.locations, { id: item.id});
                        if (locationObj) {
                            if(parentLocation){
                                if(parentLocation.criteria == "include"){
                                    locationObj.criteria = 'include';
                                    locationObj.criteria_link = 'included';
                                }else if(parentLocation.criteria == "exclude"){
                                    locationObj.criteria = 'exclude';
                                    locationObj.criteria_link = 'excluded';
                                }else{
                                    locationObj.criteria = '';
                                    locationObj.criteria_link = 'include';
                                }
                            }else{
                                locationObj.criteria = '';
                                locationObj.criteria_link = 'include';
                                for(var k=0;k< $scope.navLocations.length;k++){
                                    if($scope.navLocations[k].name == item.name){
                                        $scope.navLocations[k].criteria = '';
                                        $scope.navLocations[k].criteria_link = 'include';
                                        break;
                                    }
                                }
                                $scope.previousLocationData = angular.copy($scope.navLocations);
                            }
                            changeAllChildCriteria(item.id, locationObj.criteria);
                        } else{
                            for(var k=0;k< $scope.navLocations.length;k++){
                                if($scope.navLocations[k].name == item.name){
                                    $scope.navLocations[k].criteria = '';
                                    $scope.navLocations[k].criteria_link = 'include';
                                    break;
                                }
                            }
                        }
                        for(var i=0; i<$scope.selectedGeoLocations.selLocationPickers.length; i++){
                            if($scope.selectedGeoLocations.selLocationPickers[i].parent_id == String(item.id)){
                                $scope.selectedGeoLocations.selLocationPickers.splice(i, 1);
                            }
                        }
                    } else{
                        var selIndex = _.findIndex($scope.selectedGeoLocations.selTextInputs, {external_id: item.external_id});
                        if (selIndex >= 0) {
                            $scope.selectedGeoLocations.selTextInputs.splice(selIndex, 1);
                            $scope.selectedListOfGeoLocations.splice($scope.selectedListOfGeoLocations.indexOf(item),1);
                        }
                    }
                }

                function changeAllChildCriteria(parentId, criteria = ""){
                    _.each($scope.locations, function(location){
                        if(location.parent_id==parentId){
                            if(criteria == "include"){
                                location.criteria = 'include';
                                location.criteria_link = 'included';
                            }else if(criteria == "exclude"){
                                location.criteria = 'exclude';
                                location.criteria_link = 'excluded';
                            }else{
                                location.criteria = '';
                                location.criteria_link = 'include';
                            }
                            changeAllChildCriteria(location.id, location.criteria);
                        }
                    });
                }

                //searching loacgtion by Searvices
                $scope.searchLocation = function (geolocSearch) {
                    if (event.keyCode == 13) {
                        if (geolocSearch.length >= 3) {
                            FormService.getGeoSearchloctions($scope.clusterId, geolocSearch).then(function (success) {
                                if (success && success.length > 0) {
                                    $scope.searchData = success;
                                    $scope.navLocations = [];
                                    _.each($scope.searchData, function (item, i) {
                                        var index = $scope.locations.findIndex(x => x.id == item.id);
                                        if (index > -1) {
                                            $scope.navLocations.push($scope.locations[index]);
                                        }
                                        else {
                                            $scope.navLocations[i] = item;
                                            $scope.navLocations[i].criteria = "";
                                            $scope.navLocations[i].criteria_link = 'include';
                                            $scope.navLocations[i].name = item.name;
                                            $scope.navLocations[i].parent_name = item.parent_name;
                                        }
                                    });
                                    $scope.checkingCriteria();
                                }
                            }, function (error) {

                            });
                        }
                    }
                    else if (event.keyCode == 8) {
                        if (geolocSearch.length == 0) {
                            $scope.searchData = [];
                            $scope.navLocations = [];
                            $scope.navLocations = angular.copy($scope.previousLocationData);
                            $scope.checkingCriteria();
                        }
                    }
                }
                $scope.checkingCriteria = function(){
                    for(var k=0;k<$scope.selectedGeoLocations.selLocationPickers.length;k++){
                        for(var j=0;j<$scope.navLocations.length;j++){
                            if($scope.selectedGeoLocations.selLocationPickers[k].name == $scope.navLocations[j].name){
                                if ($scope.selectedGeoLocations.selLocationPickers[k].criteria == "include") {
                                    $scope.navLocations[j].criteria = 'include';
                                    $scope.navLocations[j].criteria_link = 'included';
                                } else if ($scope.selectedGeoLocations.selLocationPickers[k].criteria == "exclude") {
                                    $scope.navLocations[j].criteria = 'exclude';
                                    $scope.navLocations[j].criteria_link = 'excluded';
                                }else{
                                    $scope.navLocations[j].criteria = '';
                                    $scope.navLocations[j].criteria_link = 'include';
                                }
                            }
                        }
                    }
                }

                $scope.buildBreadcrumbs = function(item) {
                    if (item.parent_id) {
                        $scope.breadcrumbs.unshift(item);
                        if (item.parent_id == 0) {
                            return false;
                        } else {
                            var obj = _.find($scope.locations, function(loc) {
                                return loc.id == item.parent_id
                            });
                            $scope.buildBreadcrumbs(obj);
                        }
                    }
                }

                $scope.buildFullName = function(item) {
                    if (item.parent_id == 0) {
                        return item.name + ".";
                    } else {
                        var obj = _.find($scope.locations, function(loc) {
                            return loc.id == item.parent_id
                        });
                        if(obj == undefined && item.parent_name){
                            return item.name + " , " + item.parent_name +"."
                        } else {
                            return item.name + ", " + $scope.buildFullName(obj);

                        }
                    }
                }

                var getLocations = function() {
                    FormService.getGeoLocationsById(0, ClusterService.clusterId, $scope.orderId).then(function(success) {
                        $scope.locations = success;
                        $scope.getLocationChilds($scope.rootLocation);
                    }, function(error) {
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
                    });
                };

                var initLocations = function() {
                    if($scope.selectedGeoLocations && !_.isEmpty($scope.selectedGeoLocations)){
                        $scope.selectedGeoLocations = JSON.parse($scope.selectedGeoLocations);
                        if($scope.showActions) {
                            $scope.editMode = true;
                            $scope.enableViewMode = false;
                            getLocations();
                            if($scope.selectedGeoLocations.selTextInputs && $scope.selectedGeoLocations.selTextInputs.length > 0){
                                $scope.textInputObj.textInputType = $scope.selectedGeoLocations.selTextInputs[0].geoType;
                                _.each($scope.selectedGeoLocations.selTextInputs, function(selTextInput) {
                                    var selIndex = _.findIndex($scope.selectedGeoLocations.selTextInputs, { external_id: selTextInput.external_id});
                                    if (selIndex == -1) {
                                        $scope.selectedGeoLocations.selTextInputs.push(selTextInput);
                                    }
                                })
                            } else {
                                $scope.selectedGeoLocations.selTextInputs = [];
                            }
                        } else {
                            $scope.editMode = false;
                            $scope.enableViewMode = true;
                        }
                    } else {
                        $scope.selectedGeoLocations = {
                            selLocationPickers: [],
                            selTextInputs: []
                        };
                        $scope.enableViewMode = false;
                        $scope.editMode = false;
                        getLocations();
                    }
                };

                initLocations();
            },
            link: function(scope, elem, attrs) {

            }
        }
    })
    .directive('locationSearchList', [function() {
        return {
            restrict: 'EA',
            scope: {
                selLocations: '=ngModel',
                clientId: '='
            },
            templateUrl: directiveLocationSearchListHtmlUrl,
            link: function(scope, element, attr) {

            },
            controller: function($scope, FormService, $ioAlert) {
                $scope.locations = [];
                $scope.geolocSearch = "";
                if (!$scope.selLocations) {
                    $scope.selLocations = [];
                } else if ($scope.selLocations && typeof $scope.selLocations === 'string') {
                    $scope.selLocations = JSON.parse($scope.selLocations);
                }

                // Select Location only if the id of location doesn't exist in the selected array
                $scope.selectLocation = function(item) {
                    var index = _.findIndex($scope.selLocations, function(location) {
                        return location.id == item.id;
                    });
                    if (index < 0) {
                        $scope.selLocations.push(item);
                    }
                };

                // Remove selected Location
                $scope.removeLoc = function(item) {
                    if (item.id) {
                        _.remove($scope.selLocations, function(location) {
                            return location.id == item.id;
                        });
                    }
                }

                // Search Locations with text input more than 2 characters
                $scope.changeLocation = function() {
                    $scope.loadingLocations = true;
                    if ($scope.clientId) {
                        FormService.searchLocations($scope.clientId, $scope.geolocSearch).then(function(response) {
                            if (response.errors) {
                                $ioAlert.show('Error', response.errors);
                                $scope.locations = [];
                            } else {
                                $scope.locations = response.locations;
                            }
                            $scope.loadingLocations = false;
                        }, function(error) {
                            $scope.loadingLocations = false;
                            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
                        });
                    } else {
                        $ioAlert.show('Error', "Client ID is mandatory");
                    }
                }
            }
        }
    }]);