'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import SeleniumHelper from '../../../../../seleniumtest';
import {$TimeoutValues} from "../widget.filter.constants";


angular.module('widget.filter.ctrls', [])

    .controller('FilterWidgetDataController', FilterWidgetDataController)
    .controller('FilterColumnsController', FilterColumnsController)
    .controller('WidgetRemoveFilteredColumnController', WidgetRemoveFilteredColumnController)
    .controller('FilterTogglerController', FilterTogglerController)
    .controller('FilterPanelController', FilterPanelController)
    .controller('FilterDropZoneController', FilterDropZoneController);

/**
 *  Add filter to widget data
 * @ngInject
 */
function FilterWidgetDataController(
    $scope,
    FilterParam,
    RelativeDateRange,
    UIFactory,
    WidgetFilterFactory,
    DashboardFilterPanelFactory,
    DrawOption,
    LoadingState,
    AppFactory,
    WidgetType,
    WidgetFactory,
    DesignFactory,
    DashboardContextService,
    $ExportBuilderDashboardEvents,
    gettextCatalog,
    $WidgetFilterEvents,
    PubSub
) {
    $scope.isNUI = window.isNUI;
    WidgetFilterFactory.$registerFilterWidgetScope($scope);
    $scope.FilterParam = FilterParam;
    $scope.getSelectedAttributeColumns = getSelectedAttributeColumns;
    $scope.getSelectedMetricColumns = getSelectedMetricColumns;

    $scope.isAdmin = isAdmin;
    $scope.initEdit = initEdit;
    $scope.saveFilterFn = saveFilterFn;
    $scope.resetFilterWidget = resetFilterWidget;

    function getSelectedAttributeColumns() {
        let columns = WidgetFilterFactory.getAttributeColumns($scope.selectedColumns || []);

        columns =  _.map(columns, (column) => {
            if(WidgetFilterFactory.isDateFilter(column)) {
                _.remove(column.relativeDateRanges, (dateRange) => dateRange.id === 'custom')
            }

            return column;
        });

        return getAssociateFilters(columns);
    }

    function getAssociateFilters(filters) {
        let values = _.groupBy(filters, column => column.field);
        let columns = [];
        _.forEach(values, (temp, key) => {
            let filter = temp[0];
            if(temp.length === 2) {
                filter['associate_filter'] = temp[1];
            }
            filter.client_id = temp[0].client_id ?? 0;
            columns.push(filter);
        });
        return columns;
    }

    function getSelectedMetricColumns() {
        const columns =  WidgetFilterFactory.getMetricColumns($scope.selectedColumns || []);
        return getAssociateFilters(columns);
    }

    function isAdmin() {
        return AppFactory.getUser().isAdmin();
    }

    function initEdit(state, widget) {

        // Note: this loadingState is local to the directive controlled by this controller, it should not be
        // confused with state.loadingState, which controls the loadingState of the entire widget
        $scope.loadingState = LoadingState.BUILDING;
        $scope.state = state;
        $scope.state.canSave = false;
        $scope.state.isFiltering = true;
        $scope.state.isDoneFiltering = false;
        $scope.widget = widget;
        $scope.state.showFilterPanel = false;
        $scope.state.currentInstruction = gettextCatalog.getString('Please select at least one column to filter by');

        $scope.originalWidget = widget;
        const metadata = widget.metadata;
        // Set filter set attributes
        $scope.model = {};
        $scope.model.widget_id = widget.id;
        $scope.model.has_live_integration = widget.has_live_integration;
        $scope.model.id = metadata.filter_set_id || null;
        $scope.model.name = metadata.dynamic.filter_set_name || '';
        $scope.model.data_source = metadata.data_source;
        // TA-14348 make sure we have an array for findIndex to work when selecting a column
        $scope.selectedColumns = angular.copy(_.toArray(metadata.dynamic.filters));
        $scope.originalExposedReportFilters = [];
        _.each($scope.selectedColumns, function(filter, i) {
            if (WidgetFilterFactory.isTextFilter(filter) && filter.expose_as_report_filter) {
                $scope.originalExposedReportFilters.push(filter);
            }
        });
        $scope.totalRowEnabled = widget.type === WidgetType.DATAGRID
            && widget.metadata.draw_options[DrawOption.SHOW_TOTAL_ROW];
        $scope.showFilterResultsWarningMessage = false;
    }

    function saveFilterFn() {
        var filters = [];
        var selectedValues = [];
        var changeAssociation = true;
        let filtersData = [];

        let values = _.groupBy($scope.selectedColumns, column => column.field);
        _.each(values, function (columns, key) {
            const column = columns[0];
            const associateFilter = column.associate_filter;
            const {hasAssociateFilter, disableAssociateFilter} = column;
            filtersData.push(column);
            if(hasAssociateFilter && !disableAssociateFilter && !_.isEmpty(associateFilter)) {
                let filter = {...column, values: associateFilter.values, is_hidden: true};
                filter.association = FilterParam.ASSOCIATION_AND;
                if (column.type === FilterParam.FILTER_IN) {
                    filter.type = FilterParam.FILTER_NOT_IN;
                } else if (column.type === FilterParam.FILTER_NOT_IN) {
                    filter.type = FilterParam.FILTER_IN;
                }
                filtersData.push(filter);
            }
        });
        // Need to massage data and validate values for non-empty filter values
        _.each(filtersData, function(filter, i) {
            let isExposed = false;
            if (filter.isTextFilter) {
                if (!_.isEmpty(DesignFactory.getCurrentPage())) {
                    isExposed = filter.expose_as_dash_filter;
                } else {
                    isExposed = filter.expose_as_report_filter;
                }
                if(filter.is_hidden) {
                    isExposed = false;
                    filter.expose_as_dash_filter= false;
                    filter.expose_as_report_filter= false;
                }
                if (_.isEmpty(filter.values) && !isExposed) {
                    return;
                }
                //the first filter should have 'and' association (TA-34136)
                if (changeAssociation && filter.association === "or") {
                    filter.association = "and";
                }
                changeAssociation = false;

                // TA-35786 SC may have non trimmed values
                if ($scope.model.data_source) {
                    _.each(filter.values, function(filterValue) {
                        if (filterValue.key && filterValue.text === filterValue.key.trim()) {
                            filterValue.text = filterValue.key;
                        }
                    });
                }

                selectedValues = filter.values;
            } else if (filter.isNumericFilter) {
                var min = filter.values.min;
                var max = filter.values.max;
                var isEmpty = AppFactory.util.isAbsolutelyEmpty;
                if ((isEmpty(min)) && (isEmpty(max))) {
                    return;
                }
                selectedValues = filter.values;
            } else if (filter.isDateFilter) {
                // If relative date range selected, ignore the date range filter
                selectedValues = filter.values;
            }

            const filterModel = {
                field: filter.field,
                groupby_id_field: filter.groupby_id_field,
                format: filter.format,
                label: filter.label,
                type: filter.type,
                // Order is important to store since when reloading the filters we cannot ensure order of the array
                // since some the columns are loaded asynchronously and this index allows us to preserve the order
                order: i,
                is_metric: filter.is_metric,
                association: filter.association,
                expose_as_dash_filter: false,
                expose_as_report_filter: false,
                limit_available_values: filter.limit_available_values,
                values: selectedValues,
                is_enum: filter.has_set_values,
                client_id: filter.client_id,
                is_hidden: filter.is_hidden
                // NOTE: selectableValues are never saved
            }

            if (filter.isTextFilter) {
                if (!_.isEmpty(DesignFactory.getCurrentPage())) {
                    filterModel.expose_as_dash_filter = isExposed;
                    filterModel.expose_as_report_filter = false;
                } else {
                    filterModel.expose_as_dash_filter = false;
                    filterModel.expose_as_report_filter = isExposed;
                }
            }

            filters.push(filterModel);
        });

        var model = $scope.model;
        // Default the filter set name to the data source
        model.name = model.name || gettextCatalog.getString('{{id_name}} {{data_view_name}} filter set', {
            id_name: model.data_source.id_name,
            data_view_name: model.data_source.data_view_name
        });
        model.filters = filters;
        const removedFilters = _.differenceWith($scope.originalExposedReportFilters, filters, (a,b) => {
            return a.field === b.field;
        });

        const toUpdateFilterOptions = filters.map((filter) => {
            return {
                key: `${model.data_source.type}|${model.data_source.id}|${model.data_source.data_view}|${filter.field}`,
                limit_available_values: filter.limit_available_values
            }
        })

        WidgetFilterFactory.updateFilterSet(model).then(function (json) {

            $scope.state.isDoneFiltering = true;

            const currentWidget = $scope.originalWidget;

            // If no filters were saved, reset the filter set
            if (_.isEmpty(filters)) {
                currentWidget.metadata.filter_set_id = null;
                currentWidget.metadata.dynamic.filter_set_name = null;
                currentWidget.metadata.dynamic.filters = [];
            }
            else {
                // Else, update widget metadata
                currentWidget.metadata.filter_set_id = json.id;
                currentWidget.metadata.dynamic.filter_set_name = model.name;
                currentWidget.metadata.dynamic.filters = model.filters;
            }
            let params = {
                currentWidget
            };
            params = { ...params, removedFilters };
            PubSub.emit($WidgetFilterEvents.UPDATE_GLOBAL_FILTERS, params);
            PubSub.emit($WidgetFilterEvents.WIDGET_FILTER_SAVE, currentWidget);
            PubSub.emit($ExportBuilderDashboardEvents.UPDATE_GLOBAL_FILTER_OPTIONS, toUpdateFilterOptions);
        });
    }

    /**
     * Variables to reset when leaving the filter process (i.e. exiting the modal)
     */
    function resetFilterWidget() {
        // As a safety measure force a scope digest here since closing the modal happens does not respond to ng-click
        $scope.$evalAsync(function() {
            // Resetting the state is essential since we want to avoid
            // any elements showing from a remnant state ons the next filter
            $scope.state = WidgetFactory.getDefaultState();
        });
    }
}

/**
 * @ngInject
 */
function FilterColumnsController(
    $q,
    $timeout,
    $scope,
    MomentDateFormat,
    AppModelFactory,
    WidgetDateRangeFactory,
    FilterParam,
    ColumnFormat,
    RelativeDateRange,
    DataSourceType,
    DashboardContextService,
    LoadingState,
    AppFactory,
    DesignFactory,
    ClientFactory,
    WidgetFilterFactory,
    DataSourceFactory,
    WidgetDataSourceFactory,
    WidgetBuilderConstants,
    gettextCatalog,
    ReportStudioTemplateDataService,
    $TimeoutValues
) {
    WidgetFilterFactory.$registerFilterColumnScope($scope);
    initSelect();

    var columnUtil = AppFactory.util.column;
    var dataTypes = AppFactory.getDataTypes();
    var output = [];
    var selectableColumns = [];
    // Keeps track of how many filters are still waiting for their values
    var filterValuesToLoad = 0;

    $scope.selectOptions = {
        width: '90%',
        placeholder: gettextCatalog.getString('Select a filter...'),
        multiple: false,
        allowClear: false,
        dropdownCssClass: 'column-select-dropdown',
        formatResult: function (item) {
            if (!item.id) { return item.text; }
            var dataType = _.find(dataTypes, {key: item.format});
            var html = '<label class="label">';
            if (_.startsWith(dataType.icon, 'icon')) {
                html += '<span class="icon '+ dataType.icon +'"></span>';
            }
            else {
                html += '<span class="icon">'+ dataType.icon +'</span>';
            }
            html += '</label>';
            html += '<span>' + item.text + '</span>';

            return $(html);
        }
    };
    $scope.preProcessColumns = preProcessColumns;
    $scope.selectColumn = selectColumn;
    $scope.removeColumn = removeColumn;
    $scope.selectOnChange = selectOnChange;
    $scope.triggerRemoveColumn = triggerRemoveColumn;

    function initSelect() {
        var queryParams = {
            is_filterable: true,
            widget_request: true,
            client_id: DashboardContextService.resolveSelectedEntityIdforType()
        };

        if ($scope.liveIntegration) {
            queryParams.has_live_integration = $scope.liveIntegration;
        }

        DashboardContextService.resolveQueryParam(queryParams);

        var buildSelect2 = function(columns, selectedColumns) {
            $scope.selectOptions.formatSelection = $scope.selectOptions.formatResult;
            SeleniumHelper.addSelectClasses($scope.selectOptions, 'widget-filter-by');

            columns = $scope.preProcessColumns(columns);

            // Massage data into groups
            _.each(columns, function (column) {
                if (column.format === ColumnFormat.FORMAT_ID) {
                    return;
                }

                // If dashboard is associated to a client, remove the client filter
                var selectedEntity = DashboardContextService.resolveSelectedEntity();
                if (!_.isNull(selectedEntity)
                    && selectedEntity.type === DataSourceType.CLIENT
                    && ClientFactory.isClientFilter(column)) {
                    return;
                }

                if (columnUtil.isCallback(column.format)) {
                    column.format = ColumnFormat.FORMAT_STRING;
                }

                var group = _.find(output, {format: column.format });

                // If group not found, create a new entry for it
                if (_.isUndefined(group)) {
                    var dataType = _.find(dataTypes, {key: column.format});
                    // TA-18676 some calculations do not have a format
                    if (dataType) {
                        group = {text: dataType.value, format: column.format, children: []};
                        output.push(group);
                    }
                }

                // TA-18676 some calculations do not have a format
                if (group) {
                    group.children.push({
                        id: column.field,
                        text: column.label,
                        format: column.format,
                        disabled: column.disabled
                    });
                }
            });

            output = _.orderBy(output, 'format');

            _.each(selectedColumns, function(data) {
                data.id = data.field;
                $scope.selectColumn(data, output);
            });

            $scope.selectOptions.data = output;
        };

        $scope.isLoadingColumns = LoadingState.FETCHING;
        // The widgetFactoryConfig will help specify what kind of datasourced data config we need
        DataSourceFactory.getColumns($scope.datasource, queryParams).then(function (columns) {
            buildSelect2(columns, $scope.selectedColumns);
            $scope.isLoadingColumns = LoadingState.DONE;
        });
    }

    function preProcessColumns(columns) {
        selectableColumns = columns;

        return columns;
    }

    // Fn to handle when a column gets selected from the select2
    function selectColumn(data, results) {

        // Extract the selected columns from selectable columns
        // since the select2 object does not have the required format for the backend
        var selectedColumn = AppFactory.arrayToMemoizedObj(selectableColumns, 'field')[data.id];

        // Modify the select2 data and disable the selected item
        var group = _.find(results, {format: selectedColumn.format});
        if (group) {
            _.each(group.children, function (item) {
                if (item.id === selectedColumn.field) {
                    item.disabled = true;
                }
            });
        }

        var state = $scope.state;
        state.canSave = true;
        $scope.state.currentInstruction = gettextCatalog.getString('Please select at least one column to filter by or click save to not apply any filters');

        // if enum format, remove all values else a 'ghost' selected column would be shown if a filter is created. If loaded, values are overloaded.
        if (columnUtil.isEnum(selectedColumn.format)) {
            selectedColumn.values = [];
        }

        processFilterColumn(extendFilterColumnProperties(selectedColumn));

        // Update numSelectedColumns
        state.numSelectedColumns = $scope.selectedColumns.length;

        // Show the correct tab based on column type selected
        var $attributeTab = $('a[data-target="#attribute-filters"]');
        var $metricTab = $('a[data-target="#metric-filters"]');

        if (selectedColumn.is_metric) {
            $metricTab.tab('show');
        } else {
            $attributeTab.tab('show');
        }
    }

    /**
     * Helper to augment a selected column with the filter specific properties
     * @param column
     */
    function processFilterColumn(column) {

        // ID/string/link filter (select2)
        if (columnUtil.isText(column.format)) {
            filterValuesToLoad++;
            column.selectableValues = [];
            $scope.loadingState = LoadingState.BUILDING;
            // We need to pass a widget_page_id to the backend to allow for pre-filtered dashboards

            var queryParams = {timeout: $TimeoutValues.TIMEOUT};

            if ($scope.liveIntegration) {
                queryParams.has_live_integration = $scope.liveIntegration;
            }

            if ($scope.datasource.data_view && $scope.datasource.data_view.startsWith(WidgetBuilderConstants.SITE_AUDITOR)) {
                const currentWidgetMetaData = $scope.widget.metadata;
                WidgetDataSourceFactory.setDateRangeQueryParam(queryParams, currentWidgetMetaData);
            }

            if (!_.isNil(DashboardContextService.resolveSelectedEntity()) && DashboardContextService.resolvePageId()) {
                queryParams.widget_page_id = DashboardContextService.resolvePageId();
            } else if (ReportStudioTemplateDataService.getReport()) {
                queryParams.widget_report_id = ReportStudioTemplateDataService.getReport().id;
            }
            $q.resolve(DataSourceFactory.getFieldValues($scope.datasource, column.field, queryParams)).then(function(item) {
                filterValuesToLoad--;
                column.hasMoreData = item.has_more_data;
                column.relaxMatch = item.relax_match || item.timeout;
                column.selectableValues = item.values;
                column.timeout = item.timeout;
                setSelectedColumn(column);
            });
        } else if (columnUtil.isNumeric(column.format)) { // Numeric range filter
            var preSelectedFilter = _.find($scope.selectedColumns, {field: column.field});
            if (!preSelectedFilter && _.isEmpty(column.values)) {
                column.values = {min: null, max: null};
            }
            setSelectedColumn(column);
        } else if (columnUtil.isDate(column.format)) {
            // Default Date range picker date range
            var currentDateRange = AppFactory.getDateRange();

            column.relativeDateRanges = _.map(WidgetDateRangeFactory.shared.relativeDateRanges, function(range) {
                return {id: range.value, text: range.text}
            });

            if (!preSelectedFilter && _.isEmpty(column.values)) {
                column.values = {
                    start: currentDateRange.start,
                    end: currentDateRange.end,
                    relative: RelativeDateRange.DEFAULT,
                    custom: false
                };
            }

            column.selectOptions = AppModelFactory.getSelectOptions({
                placeholder: gettextCatalog.getString('Click here to select a Relative Date'),
            });

            column.selectedValues = column.values.relative ? {text: column.values.relative, id: column.values.relative} : {text: RelativeDateRange.DEFAULT, id: RelativeDateRange.DEFAULT};

            column.onChangeCallback = function($el) {
                const data = $el.select2('data');
                if (data) {
                    column.values.relative = data.id;
                    column.values.custom = false;
                } else {
                    column.values.relative = data;
                    column.values.custom = true;
                }
            };

            // Custom date range
            column.dateOptions = AppModelFactory.getDateOptions({
                disableRanges: true,
                opens: 'right',
                startDate: column.values.start,
                endDate: column.values.end,
                dateChangeCB: function(start, end, label) {
                    $timeout(function() {
                        column.values.start = moment(start.format(MomentDateFormat.MONTH_DAY_YEAR)).unix();
                        column.values.end = moment(end.format(MomentDateFormat.MONTH_DAY_YEAR)).unix();
                        column.values.custom = true;
                        column.values.relative = RelativeDateRange.DEFAULT;
                        column.selectedValues = {text: RelativeDateRange.DEFAULT, id: RelativeDateRange.DEFAULT};
                    });
                }
            });
            /**
             * RP-3192
             *
             * If the filter is date or datetime format then
             * 1. we are first converting the epoch to date string in the local timezone
             * 2. then converting the date string to UTC epoch
             *
             * The reason behind this change is that for filters
             * 1. In the date picker, it's taking UTC timezone
             * 2. In the filter description, using the local timezone
             *
             * We are using this different timezones deliberately because before this change
             * by using only utc in 2 places, a day difference is occurring between date picker and description
             */
            if (
              [ColumnFormat.FORMAT_DATETIME, ColumnFormat.FORMAT_DATE].includes(
                column.format
              ) &&
              !column.use_local_timezone
            ) {
              const startTime = moment
                .unix(column.values.start)
                .format(MomentDateFormat.ISO);
              const endTime = moment
                .unix(column.values.end)
                .format(MomentDateFormat.ISO);

              column.dateOptions.startDate = moment.utc(startTime).unix();
              column.dateOptions.endDate = moment.utc(endTime).unix();
            }
            setSelectedColumn(column);
        }
        // boolean filter
        else if (columnUtil.isBoolean(column.format)) {
            column.values = []; // true or false controlled by filter type, not value
            setSelectedColumn(column);
        }
    }

    /**
     * Helper to map a processed filter column (a Column object is mapped)
     * @param column
     */
    function extendFilterColumnProperties(column) {

        // Add pre-selected values if any
        var preSelectedFilter = _.find($scope.selectedColumns, {field: column.field});
        if (!preSelectedFilter) {
            preSelectedFilter = {};
            preSelectedFilter.type = getDefaultFilterType(column.format);
            preSelectedFilter.order = $scope.selectedColumns.length;
            preSelectedFilter.association = FilterParam.ASSOCIATION_AND;
        }

        return _.extend(column, preSelectedFilter);
    }

    /**
     * Helper to set a processed filter column
     * @param column
     */
    function setSelectedColumn(column) {
        // If column already exists, we don't want to risk relying on an order index key,
        // so we replace the item directly into the array
        if (_.some($scope.selectedColumns, {id: column.id})) {
            var index = _.findIndex($scope.selectedColumns, {id: column.id});
            $scope.selectedColumns.splice(index, 1, column);
        }
        else {
            $scope.selectedColumns[column.order] = column;
        }
        if (filterValuesToLoad === 0) {
            $scope.loadingState = LoadingState.DONE;
            $scope.state.canSave = true;
        }
    }

    /**
     * Helper to return default filter type based on filter column format
     * @param format
     * @returns {*}
     */
    function getDefaultFilterType(format) {
        if (columnUtil.isText(format)) {
            return FilterParam.FILTER_IN;
        }
        return FilterParam.FILTER_EQUAL;
    }

    // Fn to handle when a column gets removed from the selected columns
    // and how the select2 should behave in that event
    function removeColumn(column, results) {
        // Modify the select2 data and disable the selected item
        var group = _.find(results, {format: column.format});
        _.each(group.children, function (item) {
            if (item.id === column.field) {
                item.disabled = false;
            }
        });

        if ($scope.selectedColumns.length == 0) {
            $scope.loadingState = LoadingState.BUILDING;
            $scope.state.canSave = false;
        }
    }

    function selectOnChange($selectEl) {
        var data = $selectEl.select2('data');
        $scope.selectColumn(data, output);

        // Remove the selected in the select2 display
        $selectEl.select2('data', null);
    }

    function triggerRemoveColumn(column) {
        $scope.removeColumn(column, output);
    }
}

/**
 *  Widget columns selection item
 * @ngInject
 */
function WidgetRemoveFilteredColumnController(
    $scope,
    WidgetFilterFactory
) {
    $scope.removeColumn = function(column) {
        _.remove($scope.selectedColumns, function(n) {
            return n.field == column.field || n.field == column.groupby_id_field;
        });

        WidgetFilterFactory.$removeFilteredColumn(column);
    };
}

/**
 * @ngInject
 */
function FilterTogglerController(
    $scope,
    UIFactory,
    DashboardContextService,
    SlidePanelFactory,
    DashboardFilterPanelFactory,
    WidgetFilterFactory,
    WidgetFactory,
    PageEntity,
    gettextCatalog
) {
    $scope.filterWidgetData = filterWidgetData;
    $scope.removeFilters = removeFilters;
    $scope.showFilterTogglerOnly = showFilterTogglerOnly;

    function showFilterTogglerOnly() {
        return DashboardContextService.resolveSelectedEntity() === PageEntity.PAGE_PREDEFINED_CATEGORY_DASHBOARD
            || !($scope.state.isDataSourcedWidget && $scope.widget.can_be_edited);
    }

    function filterWidgetData() {
        SlidePanelFactory.closeAll();
        WidgetFilterFactory.$init($scope.state, $scope.widget);
    }

    function removeFilters() {
        $scope.state.showFilterPanel = false;

        var model = {
            id: null,
            is_removing: true,
            widget_id: $scope.widget.id
        };

        WidgetFilterFactory.removeFilterSet(model).then(function () {
            var currentWidget = WidgetFactory.$getScope(model.widget_id).widget;
            currentWidget.metadata.dynamic.filters = [];
            currentWidget.metadata.dynamic.filter_set_name = null;
            currentWidget.metadata.filter_set_id = null;

            WidgetFactory.$rebuildWidget(model.widget_id);

            // Force dashboard filter panel to reset widget exposed filters
            DashboardFilterPanelFactory.resetWidgetFilterOptionList();

            UIFactory.notify.showSuccess(gettextCatalog.getString('Filter set successfully removed'));
        });
    }
}

/**
 * Panel for displaying current filters applied to widget
 * @ngInject
 */
function FilterPanelController(
    $scope,
    WidgetFilterFactory,
    DashboardFilterPanelFactory,
    DashboardFilterHeaderFactory,
    FilterParam
) {
    $scope.FilterParam = FilterParam;

    var overriddenWidgetFilters = [];
    var dashWidgetFilters = DashboardFilterHeaderFactory.shared.activeFilters.widgets;
    _.each(dashWidgetFilters, function(filter) {
        var allAffectedWidgetsIds = DashboardFilterPanelFactory.getAffectedWidgetIds(filter.dataSource);
        if (_.includes(allAffectedWidgetsIds, $scope.widgetId)) {
            overriddenWidgetFilters.push(filter);
        }
    });

    $scope.filteredColumns = !_.isEmpty(overriddenWidgetFilters)
        ? overriddenWidgetFilters
        : $scope.filters;

    $scope.attributeColumns = WidgetFilterFactory.getAttributeColumns($scope.filteredColumns || []);
    $scope.metricColumns = WidgetFilterFactory.getMetricColumns($scope.filteredColumns || []);
}

/**
 * Panel for displaying a dropzone when drag & dropping filters with same data source and data view, if any
 * @ngInject
 */
function FilterDropZoneController(
    $scope,
    DataSourceFactory,
    UIFactory,
    WidgetFactory,
    WidgetFilterFactory,
    $WidgetFilterEvents,
    gettextCatalog
) {
    WidgetFilterFactory.$registerFilterDropZoneScope($scope, $scope.widget.id);

    $scope.triggerDropZone = triggerDropZone;
    $scope.applyDroppedFilter = applyDroppedFilter;
    $scope.getBackgroundColor = getBackgroundColor;
    $scope.getDataSourceIcon = getDataSourceIcon;
    $scope.getDataSourceColor = getDataSourceColor;

    var draggedWidget = null;
    var draggedOverWidget = $scope.widget; //Refers to the current widget in scope (a.k.a. being hovered)
    var draggedOverDataSource = draggedOverWidget.metadata.data_source;
    var color = draggedOverDataSource ? draggedOverDataSource.color : null;

    // Expose datasource to directive
    $scope.datasource = draggedOverDataSource;
    $scope.dropZoneIsActive = false;

    registerEvents();

    var hasSameDataSources = function(ds1, ds2) {
        return ds1.id === ds2.id && ds1.data_view === ds2.data_view
    };

    function triggerDropZone() {
        var draggedDataSource = draggedWidget.metadata.data_source;
        if (hasSameDataSources(draggedDataSource, draggedOverDataSource)) {
            $scope.dropZoneIsActive = true;
        }
    }

    function applyDroppedFilter() {
        if (draggedWidget) {
            var draggedDataSource = draggedWidget.metadata.data_source;
            if (hasSameDataSources(draggedDataSource, draggedOverDataSource)) {
                // Not yourself and not drop active
                if ($scope.dropZoneIsActive && draggedWidget.id != draggedOverWidget.id) {

                    var metadata = draggedWidget.metadata;

                    var model = {
                        id: metadata.filter_set_id,
                        widget_id: draggedOverWidget.id
                    };

                    WidgetFilterFactory.copyFilterSet(model).then(function (json) {
                        var currentWidget = WidgetFactory.$getScope(model.widget_id).widget;
                        currentWidget.metadata.dynamic.filters = metadata.dynamic.filters;
                        currentWidget.metadata.dynamic.filter_set_name = metadata.dynamic.filter_set_name;
                        currentWidget.metadata.filter_set_id = json.id;

                        WidgetFactory.$rebuildWidget(model.widget_id);
                        UIFactory.notify.showSuccess(gettextCatalog.getString('Filter successfully copied and applied'));
                    });
                }
            }
        }
    }

    function getBackgroundColor() {
        var rgb = '50,50,50'; // Dark gray
        var alpha = 0.95;

        if (draggedWidget) {
            var draggedDataSource = draggedWidget.metadata.data_source;
            if (hasSameDataSources(draggedDataSource, draggedOverDataSource)) {
                alpha = $scope.dropZoneIsActive ? 1 : 0.6;
                if (color && color.hexToRgb) {
                    rgb = color.hexToRgb();
                }
            }
        }

        return 'rgba(' + rgb + ',' + alpha + ')';
    }

    /**
     * Determine which data source icon to display
     * @returns {string}
     */
    function getDataSourceIcon() {
        return DataSourceFactory.getDataSourceIcon(draggedOverDataSource);
    }

    /**
     * Determine which data source icon to display
     * @returns {string}
     */
    function getDataSourceColor() {
        return DataSourceFactory.getDataSourceColor(draggedOverDataSource);
    }

    function registerEvents() {
        $scope.$on($WidgetFilterEvents.TOGGLE_DROP_ZONES, function (e, widget) {
            // Filter widget curently being dragged
            draggedWidget = widget;
            $scope.showFilterDropZone = !$scope.showFilterDropZone;
            // Reset active dropzones
            $scope.dropZoneIsActive = false;
        });

        $scope.$on($WidgetFilterEvents.RESET_DROP_ZONES, function () {
            $scope.dropZoneIsActive = false;
        });
    }
}
