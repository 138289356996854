'use strict';
import angular from 'angular';
import '../nav/main/navmain.module';

// Legacy code
import './scripts/legacy/chart';
import './scripts/legacy/dashboard';
import './scripts/legacy/datatable';
import './scripts/legacy/login';

import './scripts/app.components';
import './scripts/core.services';
import './scripts/context.services';
import './scripts/app.constants';
import './scripts/app.filters';
import './scripts/app.services';
import './scripts/app.models';
import './scripts/app.components';
import './scripts/app.directives';
import './scripts/scope.services';
import './scripts/ui.directives';
import './scripts/ui.services';
import './scripts/ui.constants';
import './scripts/columns.services';
import './scripts/date.services';
import './scripts/form.directives';
import './scripts/form.models';
import './scripts/datatable.directives';
import './scripts/select2.directives';
import './scripts/owlcarousel.directives';
import './scripts/slidepanel.services';
import './scripts/pubsub.services';
import './scripts/router.services';
import './scripts/userintegration';
import './scripts/keyboard.service';
import './scripts/util.services';
import './scripts/loader.directives';
import './scripts/language.services';
import './scripts/liveintegration.services';

import '../alert/alert.module';
import '../abtest/abtest.module';
import '../category/category.module';
import '../datasource/base/datasource.module';
import '../datasource/column/datasource.column.directives';
import '../datasource/column/datasource.column.services';
import '../datasource/sample/datasource.sample.services';
import '../daterange/daterange.module';
import '../serviceclientmapping/serviceclientmapping.module';
import '../goals/goals.module';
import '../guides/guides.module';
import '../connectionstatus/connectionstatus.module';
import '../product/manageproducts.module';
import '../product/details/manageproducts.details.components';
import '../product/details/manageproducts.details.services';
import '../namingconvention/namingconvention.module';
import '../design/design.module';
import '../design/layout/manage/layout.manage.directives';
import '../design/layout/manage/layout.manage.services';
import '../design/layout/drawoptionpanel/item/drawoptionpanel.item.directives'
import '../design/widget/create/column/widget.create.column.ctrls';
import '../design/widget/create/column/widget.create.column.directives';
import '../design/widget/create/column/widget.create.column.services';
import '../design/widget/create/type/widget.create.type.ctrls';
import '../design/widget/create/type/widget.create.type.directives';
import '../design/widget/create/type/widget.create.type.services';
import '../design/widget/filter/item/widget.filter.item.ctrls';
import '../design/widget/filter/item/widget.filter.item.directives';
import '../export/export.module';
import '../export/base/export.widget.services';
import '../export/design/export.layout.directives';
import '../export/design/export.page.directives';
import '../export/header/export.header.directives';
import '../export/report/export.report.directives';
import '../export/report/export.report.services';
import '../export/options/export.options.directives';
import '../export/options/export.options.services';
import '../exportbuilder/exportbuilder.module';
import '../exportbuilder/admin/exportbuilder.admin.directives';
import '../exportbuilder/admin/quickexport/exportbuilder.quickexport.components';
import '../exportbuilder/admin/quickexport/exportbuilder.quickexport.services';
import '../exportbuilder/dashboard/base/exportbuilder.dashboard.ui.models';
import '../exportbuilder/dashboard/export/exportbuilder.dashboard.export.components';
import '../exportbuilder/dashboard/export/exportbuilder.dashboard.export.services';
import '../exportbuilder/dashboard/grid/dashboard.grid.directives';
import '../exportbuilder/dashboard/item/exportbuilder.item.components';
import '../exportbuilder/dashboard/item/exportbuilder.item.directives';
import '../exportbuilder/dashboard/item/macros/item.macros.components';
import '../exportbuilder/dashboard/item/macros/item.macros.services';
import '../exportbuilder/dashboard/item/iconmodal/exportbuilder.item.iconmodal.components';
import '../exportbuilder/dashboard/item/iconmodal/exportbuilder.item.iconmodal.services';
import '../exportbuilder/dashboard/shortcuts/exportbuilder.shortcuts.components';
import '../exportbuilder/dashboard/shortcuts/exportbuilder.shortcuts.services';
import '../exportbuilder/dashboard/pageassignmentmodal/exportbuilder.pageassignment.components';
import '../exportbuilder/dashboard/pageassignmentmodal/exportbuilder.pageassignment.services';
import '../exportbuilder/dashboard/format/exportbuilder.format.components';
import '../exportbuilder/dashboard/format/exportbuilder.format.services';
import '../exportbuilder/dashboard/menu/exportbuilder.menu.components';
import '../exportbuilder/dashboard/menu/exportbuilder.menu.directives';
import '../exportbuilder/dashboard/menu/exportbuilder.menu.services';
import '../filterset/filterset.module';
import '../iomaster/iomaster.module';
import '../custommade/ec/ec.module';
import '../leads/leads.module';
import '../leads/notes/leads.notes.components';
import '../leads/notes/leads.notes.services';
import '../endcustomer/endcustomer.module';
import '../endcustomer/details/endcustomer.details.components';
import '../endcustomer/details/endcustomer.details.services';
import '../lineitem/lineitem.module';
import '../login/login.module';
import '../order/order.module';
import '../reportingprofile/reportingprofile.module';
import '../service/service.module';
import '../service/overview/service.overview.ctrls';
import '../service/overview/service.overview.directives';
import '../service/overview/service.overview.services';
import '../service/drilldown/service.drilldown.ctrls';
import '../service/drilldown/service.drilldown.directives';
import '../service/drilldown/service.drilldown.services';
import '../task/task.module';
import '../user/user.module';
import '../user/legacy/user';
import '../client/client.module';
import '../client/legacy/client';
import '../clientgroup/clientgroup.module';
import '../clientgroup/legacy/client-group';
import '../reportingtemplate/reportingtemplate.module';
import '../reportingtemplate/legacy/reporting-templates';
import '../messenger/messenger.module';
import '../history/history.module';
import '../annotation/annotation.module';
import '../library/library.module';
import '../tag/tag.module';
import '../tag/details/tag.details.components';
import '../tag/details/tag.details.services';
import '../smartcampaign/smartcampaign.module';
import '../smartcampaign/details/smartcampaign.details.components';
import '../ui/ui.module';
import '../devtools/devtools.module';
import '../iohelper/iohelper.module';
import '../industrybenchmark/industrybenchmark.module';
import '../industrybenchmarkpaidmedia/industrybenchmarkpaidmedia.module';
import '../industrybenchmarkemail/industrybenchmarkemail.module';
import '../industrybenchmarkleadtracking/industrybenchmarkleadtracking.module';
import '../email/email.module';
import '../pusher/pusher.module';
import '../workermanager/workermanager.module';
import '../ioreporting/ioreporting.module';
import '../buysheet/buysheet.module';

angular.module('core.shared', [
    'navmain',
    'core.services',
    'core.context.services',
    'core.app.constants',
    'core.app.filters',
    'core.app.services',
    'core.app.models',
    'core.app.components',
    'core.app.directives',
    'core.scope.services',
    'core.ui.directives',
    'core.ui.services',
    'core.ui.constants',
    'core.columns.services',
    'core.date.services',
    'core.form.directives',
    'core.form.models',
    'core.datatable.directives',
    'core.select2.directives',
    'core.owlcarousel.directives',
    'core.slidepanel.services',
    'core.pubsub.services',
    'core.router.services',
    'core.user.integration',
    'core.keyboard.services',
    'core.util.services',
    'core.language.services',
	'core.liveintegration.services',
	//
    // // APP
    'core.alert',
    'core.abtest',
    'core.category',
    'core.datasource',
    'core.daterange',
    'core.serviceclientmapping',
    'core.goals',
    'core.guides',
    'core.connectionstatus',
    'core.namingconvention',
    'core.design',
    'core.export',
    'core.exportbuilder',
    'core.filterset',
    'core.iomaster',
    'core.custommade.ec',
    'core.leads',
    'core.endcustomer',
    'core.lineitems',
    'core.loader',
    'core.login',
    'core.orders',
    'core.pusher',
    'core.workermanager',
    'core.reportingprofile',
    'core.service',
    'core.tasks',
    'core.user',
    'core.client',
    'core.clientgroup',
    'core.reportingtemplate',
    'core.messenger',
    'core.history',
    'core.annotation',
    'core.library',
    'core.tag',
    'core.smartcampaign',
    'core.ui',
    'core.iohelper',
    'core.manageproducts',
    'core.industrybenchmark',
    'core.industrybenchmarkpaidmedia',
    'core.industrybenchmarkleadtracking',
    'core.industrybenchmarkemail',
    'core.email',
    'core.ioreporting',
    'core.buysheet',

    // DEV ONLY
    'core.devtools',
]);
