import Enum from '@/modules/core/app/models/abstracts/Enum';

export class MapPlotType extends Enum {
  static HEAT_MAP = 'heat';

  static BUBBLE_MAP = 'bubble';
}

export const GeoCodeType = {
  WORLD: 'world',
};

export const MapBaseUrl = { AM_MAPS: 'https://www.amcharts.com/lib/4/geodata/json/' };

export const Continent = {
  AQ: 'AQ',
};
export const Country = {
  US: 'US',
  CA: 'CA',
  MX: 'MX',
};

export const State = {
  US_AS: 'US-AS',
  US_PR: 'US-PR',
  US_VI: 'US-VI',
  US_MP: 'US-MP',
  US_GU: 'US-GU',
};

export const CountiesWithStatesData = [Country.US, Country.CA];

export const AlbersState = ['hi', 'ak'];
