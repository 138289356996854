'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

import logoLightImageUrl from '../../../images/logo-light.png';
import logoDarkImageUrl from '../../../images/logo-dark.png';

angular.module('preferences.ctrls', [])

    .controller('PreferencesController', PreferencesController);


/**
 * Preferences controller
 * @ngInject
 */
function PreferencesController(
    $scope,
    $timeout,
    $window,
    $compile,
    AppFactory,
    UIFactory,
    TapColorsService,
    AppService,
    DesignFactory,
    LoginFactory,
    DashboardService,
    CoreFactory,
    UIColor,
    ThemeUIService,
    PubSub,
    $TapColorsEvents,
    DashboardFilterPanelFactory
) {
    $scope.entity = {};
    $scope.entity.redrawServiceNavOnSave = true;
    $scope.entity.custom_icon = null;
    $scope.entity.curent_service = null;
    $scope.entity.updateIconCallBack = updateIconCallBack;
    $scope.setServiceColor = setServiceColor;
    $scope.isDifferentColor = isDifferentColor;
    $scope.onColorChange = onColorChange;
    $scope.refreshDashboardFilters = DashboardFilterPanelFactory.resetFilterOptionList();
    
    $scope.weekStartsOnMondayLabel = AppFactory.getUser().weekStartsOnMonday ? 'Monday to Sunday' : 'Sunday to Saturday';
    $scope.week_starts_on_monday = AppFactory.getUser().weekStartsOnMonday;
    
    $.core.main.dynamicForm($('#mainForm'));

    $scope.$on('$destroy', function () {
        $.core.main.hideFooterCallback = null;
    });

    //used for updating the document title dynamically
    var startingName = $('#application_name').val();
    $('#form-submit').on('click', function(e){

        var newApplicationName = $("#application_name").val();

        if (newApplicationName && newApplicationName !== startingName) {
          $window.document.title = newApplicationName;
        }
    });

    $scope.showServicesOverview = $('[name="show_services_overview"]:checked').val() !== 'no';
    $scope.showServiceMenu = $('[name="show_service_drilldowns_when_overview_is_disabled"]:checked').val() !== 'no';

    $scope.toggleServicesOverview = function(show) {
        $scope.showServicesOverview = show;
        var $serviceMenu = $('#service-menu');
        if (!show) {
            $scope.showServiceMenu ? $serviceMenu.show() : $serviceMenu.hide();
        }
        else {
            $serviceMenu.show();
        }
    };

    $scope.toggleServiceMenu = function(show) {
        $scope.showServiceMenu = show;
        var $serviceMenu = $('#service-menu');
        if (!$scope.showServicesOverview) {
            show ? $serviceMenu.show() : $serviceMenu.hide();
        }
    };

    //used for cancel action
    $scope.setLogicAction = function(action) {
        var setAction = $('#set_action_div');
        if(setAction) {
            action ? setAction.show() : setAction.hide();
        }
    }
    var initCustomCancelLogic = function() {
        var cancelLogicYes = $('#custom_cancel_logic_yes');
        var setAction = $('#set_action_div');
        if(cancelLogicYes && setAction) {
            cancelLogicYes.is(':checked') ? setAction.show() : setAction.hide();
        }
    }
    initCustomCancelLogic();

    $scope.includeNewAdvertiserWarning = function(action) {
        var setAction = $('#include_new_advertiser_warning_div');
        if(setAction) {
            if(action) {
                setAction.show()
                $('#include_new_advertiser_warning_yes').prop("checked", true);
            } else {
                setAction.hide()
                $('#include_new_advertiser_warning_no').prop("checked", true);
            }
        }
    }
    var initIncludeNewAdvertiserWarning = function() {
        var includeNewAdvertiserWarningYes = $('#enable_advertiser_creation_yes');
        var setAction = $('#include_new_advertiser_warning_div');
        if(includeNewAdvertiserWarningYes && setAction) {
            includeNewAdvertiserWarningYes.is(':checked') ? setAction.show() : setAction.hide();
        }
    }
    initIncludeNewAdvertiserWarning();

    $scope.includeNewAdvertiserWarningAdwords = function(action) {
        var setAction = $('#adwords_include_new_advertiser_warning_div');
        if(setAction) {
            if(action) {
                setAction.show()
                $('#adwords_include_new_advertiser_warning_yes').prop("checked", true);
            } else {
                setAction.hide()
                $('#adwords_include_new_advertiser_warning_no').prop("checked", true);
            }
        }
    }
    var initIncludeNewAdvertiserWarningAdwords = function() {
        var includeNewAdvertiserWarningYes = $('#adwords_enable_advertiser_creation_yes');
        var setAction = $('#adwords_include_new_advertiser_warning_div');
        if(includeNewAdvertiserWarningYes && setAction) {
            includeNewAdvertiserWarningYes.is(':checked') ? setAction.show() : setAction.hide();
        }
    }
    initIncludeNewAdvertiserWarningAdwords();

    $scope.hasCustomLightLogo = false;
    $scope.hasCustomDarkLogo = false;
    $scope.setLogo = function(assetName) {
        AppService.getAssetUrl(assetName).success(function (json) {
            if (json.status == 'success' && json.data != false) {
                if (assetName == 'light_logo') {
                    $scope.lightLogo = json.data;
                    $scope.hasCustomLightLogo = true;
                }
                else if (assetName == 'dark_logo') {
                    $scope.darkLogo = json.data;
                    $scope.hasCustomDarkLogo = true;
                }
            }
        });
    };
    $scope.setLogo('light_logo');
    $scope.setLogo('dark_logo');

    //ppt template
    $scope.hasCustomPptTemplate = false;
    AppService.getAssetUrl('ppt_template', 'potx').success(function (json) {
        if (json.status == 'success' && json.data != false) {
            $scope.pptTemplateLink = json.data;
            $scope.hasCustomPptTemplate = true;
        }
    });

    /**
     *
     * Custom Theme Builder
     *
     */

    $scope.primaryColor = null;
    $scope.tapColorPrimaryColorId = 'tap-color-primary-color';
    $scope.tapColorSecondaryColorId = 'tap-color-secondary-color';
    $scope.primaryColorIndex = null;
    $scope.secondaryColor = null;
    $scope.secondaryColorIndex = null;
    $scope.customThemeType = null;
    $scope.isLoadingCustomTheme = false;
    $scope.isCustomTheme = false;

    var user = AppFactory.getUser();
    $scope.themeName = user.theme;
    $scope.tapColorPrimaryColor = user.primaryThemeColor;
    $scope.tapColorSecondaryColor = user.secondaryThemeColor;
    $scope.showPrimaryColorPicker = true;
    $scope.showSecondaryColorPicker = true;
    $scope.primaryTapColors = null;
    $scope.secondaryTapColors = null;
    $scope.themeBuilderPreviewGenerated = false;
    $scope.themeLogoHref = null;
    $scope.didChangeThemeLogo = false;
    $scope.isPressingCancel = false;

    $scope.themeLogoClasses = themeLogoClasses;
    $scope.paletteItemClicked = paletteItemClicked;
    $scope.onPaletteDelete = onPaletteDelete;
    $scope.toggleBuildFromImageClicked = toggleBuildFromImageClicked;
    $scope.canCreateCustomTheme = canCreateCustomTheme;
    $scope.generateCustomThemeClicked = generateCustomThemeClicked;
    $scope.themeBuilderFileSelected = themeBuilderFileSelected;
    $scope.themeNameClicked = themeNameClicked;
    $scope.themeTypeClicked = themeTypeClicked;
    $scope.onPrimaryColorChange = onPrimaryColorChange;
    $scope.onSecondaryColorChange = onSecondaryColorChange;
    $scope.onWeekStartsOnMondayChanged = onWeekStartsOnMondayChanged;
    
    $.core.main.hideFooterCallback = hideFooterCallback;

    function themeBuilderFileSelected() {
        $scope.didChangeThemeLogo = true;
        _resetPaletteValues();

        // timeout needed to render DOM first then target imported file image
        // delay needed for animating the palettes
        $timeout(function () {
            var $logo = $('div.light_logo_image img.file-preview-image');
            extractColorsFromLogo($logo);
        }, 100);
    }

    function extractColorsFromLogo($logo) {
        var colors = UIColor.getPalette($logo.get(0), 8);
        var tempPaletteArray = [];
        _.each(colors, function(rgb) {
            var hex = rgb.rgbToHex();
            tempPaletteArray.push(hex);
        });

        $scope.paletteColors = tempPaletteArray;
    }

    /**
     * Retrieves current set logo for select theme type
     * @returns {*}
     * @private
     */
    function _getLogoFromForm() {
        var $logo = null;
        var isLightTheme = $('.custom_application_theme_type label.active input').val();
        if (isLightTheme === 'light') {
            var isDefaultLogoSelected = $('input[name="light_logo"]:checked').val();
            if (isDefaultLogoSelected === 'custom') {
                $logo = $('.user-logo.current-logo.light img')
            } else {
                $logo = $('.default-logo.current-logo.light img')
            }
        } else {
            var isDefaultLogoSelected = $('input[name="dark_logo"]:checked').val();
            if (isDefaultLogoSelected === 'custom') {
                $logo = $('.user-logo.current-logo.dark img')
            } else {
                $logo = $('.default-logo.current-logo.dark img')
            }
        }
        $scope.themeLogoHref = $logo.attr('src');
        return $logo;
    }
    
    function onWeekStartsOnMondayChanged() {
        const value = $('input[name="week_starts_on_monday"]:checked').val();
        $scope.weekStartsOnMondayLabel = value === 'yes' ? 'Monday to Sunday' : 'Sunday to Saturday';
    }

    function toggleBuildFromImageClicked($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.buildFromImage = !$scope.buildFromImage;
        if ($scope.buildFromImage) {
            $scope.paletteColors = [];
            $scope.primaryColorIndex = null;
            $scope.secondaryColorIndex = null;
            var $logo = _getLogoFromForm();
            if (!$logo.attr('src')) {
                $scope.buildFromImage = false;
                UIFactory.notify.showWarning('No image found');
                return;
            }
            extractColorsFromLogo($logo);
        } else {
            $scope.didChangeThemeLogo = false;
        }
    }

    function themeLogoClasses() {
        var type = $('.custom_application_theme_type label.active input').val();
        if (type === 'light') {
            return ['light'];
        } else {
            return ['dark']
        }
    }

    function paletteItemClicked($e, color, index) {

        if (!_.isNull($scope.primaryColorIndex)
            && !_.isNull($scope.secondaryColorIndex)) {
            return;
        }

        if ($scope.primaryColorIndex === null && $scope.secondaryColorIndex !== index) {
            $scope.primaryColor = color; // simply to keep track of selection order
            $scope.tapColorPrimaryColor = color;
            $scope.primaryColorIndex = index;
            $scope.primaryTapColors = null;
            $scope.primaryOverlayTextColor = UIColor.textColorWithBackgroundColor(color);
            _updatePrimaryTapColor(color);
        } else if ($scope.primaryColorIndex !== index && $scope.secondaryColorIndex === null) {
            $scope.secondaryColor = color; // simply to keep track of selection order
            $scope.tapColorSecondaryColor = color;
            $scope.secondaryColorIndex = index;
            $scope.secondaryTapColors = null;
            $scope.secondaryOverlayTextColor = UIColor.textColorWithBackgroundColor(color);
            _updateSecondaryTapColor(color);
        }
    }

    function onPaletteDelete($event, index) {
        $event.preventDefault();
        $event.stopPropagation();

        if (!_.isNil($scope.primaryColorIndex) && $scope.primaryColorIndex === index) {
            $scope.primaryColor = null;
            $scope.primaryColorIndex = null;
        } else if (!_.isNil($scope.secondaryColorIndex) && $scope.secondaryColorIndex === index) {
            $scope.secondaryColor = null;
            $scope.secondaryColorIndex = null;
        }
    }

    function _resetPaletteValues() {
        $scope.paletteColors = [];
        $scope.primaryColor = null;
        $scope.primaryColorIndex = null;
        $scope.secondaryColor = null;
        $scope.secondaryColorIndex = null;
    }

    function _updatePrimaryTapColor(color) {
        $('#tap-color-primary-color input').val(color);
        $('#tap-color-primary-color').css('background-color', color);
        $('.palette-selection tap-colors > div')
            .eq(0)
            .removeClass('pop-anim')
            .addClass('pop-anim');
        _setInputThemeGeneratedValue(true);
        PubSub.emit($TapColorsEvents.UPDATE_COLOR + $scope.tapColorPrimaryColorId, color);
    }

    function _updateSecondaryTapColor(color) {
        $('#tap-color-secondary-color input').val(color);
        $('#tap-color-secondary-color').css('background-color', color);
        $('.palette-selection tap-colors > div')
            .eq(1)
            .removeClass('pop-anim')
            .addClass('pop-anim');
        _setInputThemeGeneratedValue(true);
        PubSub.emit($TapColorsEvents.UPDATE_COLOR + $scope.tapColorSecondaryColorId, color);
    }

    function themeNameClicked($event, name) {
        if ($scope.themeName !== name && name === 'custom') {
            _resetColorToUserDefaults();
        }
        $scope.themeName = name;
        $scope.themeBuilderPreviewGenerated = false;
        if ($scope.isPressingCancel) {
            return;
        }
        var themeType = $('.custom_application_theme_type label.active input').val();
        _showPreview($scope.themeName, themeType);
    }

    function _resetColorToUserDefaults() {
        var user = AppFactory.getUser();
        $('#tap-color-primary-color input').val(user.primaryThemeColor);
        $('#tap-color-secondary-color input').val(user.secondaryThemeColor);
        $('#tap-color-primary-color').css('background-color', user.primaryThemeColor);
        $('#tap-color-secondary-color').css('background-color', user.secondaryThemeColor);
        $scope.primaryColor = user.primaryThemeColor;
        $scope.secondaryColor = user.secondaryThemeColor;
        $scope.tapColorPrimaryColor = user.primaryThemeColor;
        $scope.tapColorSecondaryColor = user.secondaryThemeColor;

        // needed due to a race condition, when cancel in the footer is pressed,
        // custom theme is resetted and theme type is still old value
        $('.custom_application_theme_type label.active').removeClass('active');
        var $inputs = $('.custom_application_theme_type label input');
        var $labels = $('.custom_application_theme_type label');
        switch (user.themeType) {
            case 'light':
                $inputs.eq(0).val(user.themeType);
                $labels.eq(0).addClass('active');
                break;
            case 'hybrid':
                $inputs.eq(1).val(user.themeType);
                $labels.eq(1).addClass('active');
                break;
            case 'dark':
                $inputs.eq(2).val(user.themeType);
                $labels.eq(2).addClass('active');
                break;
            default:
        }
    }

    /**
     * On change callback to handle dynamic form to show footer
     * @param newValue
     */
    function onPrimaryColorChange(newValue) {
        _setInputThemeGeneratedValue(true);
    }

    /**
     * On change callback to handle dynamic form to show footer
     * @param newValue
     */
    function onSecondaryColorChange(newValue) {
        _setInputThemeGeneratedValue(true);
    }

    /**
     * Helper function to set theme-builder-preview-need-to-generate input value
     * @param value
     * @private
     */
    function _setInputThemeGeneratedValue(value) {
        $('input#' + 'theme-builder-preview-need-to-generate')
            .prop('checked', value)
            .val(value)
            .trigger('change');
    }

    /**
     * Helper function to set theme-builder-preview-need-to-generate input value
     *  and trigger form change
     * @param value
     * @private
     */
    function _setThemeGeneratedValue(value) {
        $('input#' + 'theme-builder-preview-need-to-generate')
            .prop('checked', value)
            .trigger('change')
            .val(value);
    }

    /**
     * Callback function when cancel button has been performed
     */
    function hideFooterCallback() {
        $scope.isPressingCancel = true;
        _resetColorToUserDefaults();
        $scope.buildFromImage = false;
    }

    function themeTypeClicked($event, type) {
        $scope.themeType = type;
        var themeName = $('div.application_theme_buttons').find('label.active input:radio').val();
        if (!$scope.themeName.contains('custom') || $scope.isPressingCancel) {
            $scope.isPressingCancel = false;
            _showPreview(themeName, $scope.themeType);
        }
    }

    function generateCustomThemeClicked($e) {
        $e.preventDefault();
        $e.stopPropagation();

        $timeout(function () {
            var themeName = $('div.application_theme_buttons').find('label.active input:radio').val();
            var themeType = $('.custom_application_theme_type label.active input').val();
            _showPreview(themeName, themeType);
        }, 0);
    }

    function _showPreview(themeName, themeType) {
        if (themeName.contains('custom')) {
            $scope.primaryColor = $('#tap-color-primary-color input').val();
            $scope.secondaryColor = $('#tap-color-secondary-color input').val();
        }

        $timeout(function () {
            $scope.isLoadingCustomTheme = true;
        }, 0);
        var $link = CoreFactory.lazyLoadTheme(themeName, themeType, $scope.primaryColor, $scope.secondaryColor, true);
        $link.onload = function () {
            $scope.isLoadingCustomTheme = false;
            ThemeUIService.updateHtml(themeName + themeType);
            _updateLogo(themeType);
            $timeout(function () {
                $scope.isLoadingCustomTheme = false;
            }, 0);
        };
    }

    function _updateLogo(themeType) {
        var $img = $('div.header-logo img');
        //Only switch logo if its not a custom logo
        if (!$img.attr('src').contains('custom')) {
            //Light theme logo
            if (themeType === 'light') {
                $img.attr('src', logoLightImageUrl);
            }
            //Dark theme logo
            else if (themeType === 'hybrid' || themeType === 'dark') {
                $img.attr('src', logoDarkImageUrl);
            }
        }
    }

    function canCreateCustomTheme() {
        return !!$scope.primaryColor && !!$scope.secondaryColor && !!$scope.customThemeType
    }

    //used for updating nav bar on Data Source Overview selection
    var showOvChecked = $('#show_services_overview_yes').is(':checked');
    var showCategoriesOvChecked = $('#show_categories_overview_yes').is(':checked');
    $scope.multiCallback = function() {
        ThemeUIService.setForceReload(true);

        //Only handle this callback if files have been uploaded
        if ($scope.files != null && $scope.files.length) {
            angular.forEach($scope.files, function(file) {
                if (file.key == 'light_logo_image') {
                    $('#light_logo_image').fileinput('clear');
                    $scope.setLogo('light_logo');
                }
                else if (file.key == 'dark_logo_image') {
                    $('#dark_logo_image').fileinput('clear');
                    $scope.setLogo('dark_logo');
                }
            });
        }

        //Update user preferences
        LoginFactory.initUser()
            .then(function () {
                DashboardService.updateLogo();
            });

        // refresh Theme base colors in memory
        UIFactory.baseColors.get().then(function (baseColors) {
            baseColors = baseColors.plain();
            UIFactory.setThemeBaseColors(baseColors);
            TapColorsService.setThemeColors(baseColors)
        });

        /*
        * Need to update state with new theme colors
        */
        UIFactory.colors.get().then(function (colors) {
            UIFactory.setThemeColors(colors.plain());
        });

        DashboardService.updateLandingPage();

        // Refresh menu with fresh page list
        DesignFactory.getNavPageList().then(function() {
            DesignFactory.$refreshMenu();
            // Need to wait to do the following logic after we get nav page list
            $.get($.fn.actionUrl('layout/dashboardlistmenu'))
                .done(function(html) {
                    // Need to $compile the html, in order to add to angular scope
                    // parse HTML into DOM element
                    var template = angular.element(html);

                    // compile the template
                    var linkFn = $compile(template);
                    // link the compiled template with the scope.
                    var element = linkFn($scope);
                    $('#dashboard-submenu').html(element);
                });

        });
    };

    $scope.updateIcon = function(serviceId, icon, defaultIcon) {
        $scope.entity.custom_icon = icon;
        $scope.entity.current_service = serviceId;
        $scope.entity.service_default_icon = defaultIcon;
    };

    function updateIconCallBack(icon) {
        var iconClass = icon == $scope.entity.service_default_icon ? 'serviceicon-' + icon : 'serviceicon-custom-icon ' + icon;
        $('span#service-icon-' + $scope.entity.current_service).attr('class', 'icon ' + iconClass);
        $('input#service-icon-' + $scope.entity.current_service).attr('value', icon).trigger('change');
    }

    function setServiceColor(serviceId, color) {
        $('div#service-icon-' + serviceId).css('background-color', color);
        $('input#service-color-picker-' + serviceId).val(color).trigger('textchange');
        $('div#tap-color-picker-' + serviceId).css('background-color', color);

        _verifyOriginalColor(serviceId, color)
    }

    /**
     * Helper function to verify if need to show/hide reset color button
     * @private
     */
    function _verifyOriginalColor(serviceId, newColor) {
        var originalColor = $('div[data-original-color-' + serviceId + ']').attr('data-original-color-' + serviceId);
        if (originalColor) {
            var resetButton = $('div.reset-color-' + serviceId);
            originalColor.toLowerCase() === newColor.toLowerCase() ?
                resetButton.addClass('hidden') :
                resetButton.removeClass('hidden')
        }
    }

    /**
     * Compares the new selected color with the default data source color
     * @param defaultColor
     * @param serviceId
     * @returns {boolean}
     */
    function isDifferentColor(defaultColor, serviceId) {
        return defaultColor.toLowerCase() !== $('input#service-color-picker-' + serviceId).val().toLowerCase();
    }

    /**
     * TapColors directive callback
     * @param color
     * @param serviceId
     */
    function onColorChange (color, serviceId) {
        setServiceColor(serviceId, color);
    }
}