import angular from 'angular';

class PusherResource {
  static $inject = ['Restangular'];

  constructor(Restangular) {
    this.pubsub = Restangular.all('pubsub');
    this.pusherDetails = this.pubsub.one('clientdetails');
  }

  async getPusherDetails() {
    return await this.pusherDetails.get().then(json => json.plain());
  }
}

export default angular.module('pusher.resource', []).service('PusherResource', PusherResource);
