import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import CategoryDataResource from '@/modules/ta/category/resources/CategoryDataResource';
import ServiceDataResource from '@/modules/ta/service/resources/ServiceDataResource';
import GoalDataResource from '@/modules/ta/goal/resources/GoalDataResource';
import LeadDataResource from '@/modules/ta/lead/resources/LeadDataResource';
import UserLoginDataResource from '@/modules/ta/userlogin/resources/UserLoginDataResource';
import TaskDataResource from '@/modules/to/task/resources/TaskDataResource';
import OrdersReportDataResource from '@/modules/to/order/resources/OrdersReportDataResource';
import OrderDataResource from '@/modules/to/order/resources/OrderDataResource';
import LineItemDataResource from '@/modules/to/lineitem/resources/LineItemDataResource';
import LineItemTasksReportDataResource from '@/modules/to/lineitem/resources/LineItemTasksReportDataResource';
import LineItemsReportDataResource from '@/modules/to/lineitem/resources/LineItemsReportDataResource';
import IoMasterDataResource from '@/modules/to/iomaster/resources/IoMasterResource';
import OrderTasksReportDataResource from '@/modules/to/order/resources/OrderTasksReportDataResource';
import OrdersWithTasksReportDataResource from '@/modules/to/order/resources/OrdersWithTasksReportDataResource';
import LineItemsWithTasksReportDataResource from '@/modules/to/lineitem/resources/LineItemsWithTasksReportDataResource';

export class WidgetDataResourceFactory {
  /**
   * @param {string} dataType
   * @returns {BaseResource}
   */
  static getResource(dataType) {
    switch (dataType) {
      case DataSourceType.CATEGORY_DATA:
        return CategoryDataResource;
      case DataSourceType.SERVICE_DATA:
        return ServiceDataResource;
      case DataSourceType.GOAL_DATA:
        return GoalDataResource;
      case DataSourceType.LEADS:
        return LeadDataResource;
      case DataSourceType.USER_LOGINS:
        return UserLoginDataResource;
      case DataSourceType.TASK:
        return TaskDataResource;
      case DataSourceType.ORDER:
        return OrderDataResource;
      case DataSourceType.ORDERS_REPORT:
        return OrdersReportDataResource;
      case DataSourceType.ORDER_TASKS_REPORT:
        return OrderTasksReportDataResource;
      case DataSourceType.ORDERS_WITH_TASKS_REPORT:
        return OrdersWithTasksReportDataResource;
      case DataSourceType.LINE_ITEM:
        return LineItemDataResource;
      case DataSourceType.LINE_ITEMS_REPORT:
        return LineItemsReportDataResource;
      case DataSourceType.LINE_ITEM_TASKS_REPORT:
        return LineItemTasksReportDataResource;
      case DataSourceType.LINE_ITEMS_WITH_TASKS_REPORT:
        return LineItemsWithTasksReportDataResource;
      case DataSourceType.IO_MASTER:
        return IoMasterDataResource;
      default:
        return null;
    }
  }
}
