import { BulletShapes, Constant, LineTypes } from '@/modules/core/charts/am5/charts.constants';
import { BulletConfig } from '@/modules/core/charts/am5/base/models/BulletConfig';

export class BenchmarkSeries {
  /**
   * @var {string}
   */
  color;

  /**
   * @var {string}
   */
  name;

  /**
   * @var {string}
   */
  value;

  /**
   * @var {[]}
   */
  data;

  /**
   * @var {string}
   */
  dataItemFormat;

  /**
   * @var {string}
   */
  lineStyle;

  /**
   * @var {string}
   */
  category;

  /**
   * @var {boolean}
   */
  showBullets;

  /**
   * @var {BulletConfig}
   */
  bullet;

  /**
   * @type {number}
   */
  opacity = 1;

  /**
   * @var {string}
   */
  lineType;

  createBullet(shape) {
    return new BulletConfig({
      enabled: true,
      fill: this.color,
      fillOpacity: this.opacity,
      shape,
    });
  }

  constructor(model = {}) {
    this.category = model.category;
    this.color = model.color;
    this.name = model.name;
    this.value = model.value;
    this.data = model.data;
    this.lineStyle = model.lineStyle;
    this.showBullets = model.showBullets;
    this.opacity = model.opacity ?? 1;
    this.bullet = this.createBullet(model.bulletShape ?? BulletShapes.CIRCLE);
    this.prefix = Constant.BENCHMARK;
    this.dataItemFormat = model.dataItemFormat;
    this.lineType = model.lineType ?? LineTypes.LINE;
  }
}
