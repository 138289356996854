'use strict';
import angular from 'angular';

angular.module('core.filterset.ctrls', [])

    .controller('FilterSetListController', FilterSetListController)
    .controller('FilterSetController', FilterSetController);

FilterSetListController.$inject = [];
function FilterSetListController() {

}

FilterSetController.$inject = [];
function FilterSetController() {

}