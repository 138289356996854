import { useChart } from '@/modules/core/charts/am5/base/composables/useChart';
import { useXYSeries } from '@/modules/core/charts/am5/base/composables/series/useXYSeries';
import { useScrollbar } from '@/modules/core/charts/am5/base/composables/useScrollbar';
import { useBullets } from '@/modules/core/charts/am5/line/composables/useBullets';
import { useXYAxes } from '@/modules/core/charts/am5/base/composables/axis/useXYAxes';
import { useCustomZoomOutButton } from '@/modules/core/charts/am5/base/composables/useCustomZoomOutButton';
import { useMetricLabels } from '@/modules/core/charts/am5/line/composables/useMetricLabels';
import { useLegend } from '@/modules/core/charts/am5/line/composables/useLegend';
import { useSeries } from '@/modules/core/charts/am5/line/composables/useSeries';
import { useXYDrillDown } from '@/modules/core/charts/am5/base/composables/useXYDrillDown';
import { useXYTooltip } from '@/modules/core/charts/am5/base/composables/useXYTooltip';
import { useXYAxesTooltip } from '@/modules/core/charts/am5/base/composables/axis/useXYAxesTooltip';
import { useXYChart } from '@/modules/core/charts/am5/base/composables/useXYChart';
import { useRegressionLine } from '@/modules/core/charts/am5/base/composables/useRegressionLine';
import { LineTypes } from '@/modules/core/charts/am5/charts.constants';
import { useBenchmarkSeries } from '@/modules/core/charts/am5/base/composables/useBenchmarkSeries';

export function useLineChart(context) {
  const { chart, config } = context();

  const { configureChart } = useChart(context);
  const { createAxes } = useXYAxes(context);
  const { applyDataToAllSeries } = useXYSeries(context);
  const { createAllSeries } = useSeries(context);
  const { createScrollbar } = useScrollbar(context);
  const { createLegend } = useLegend(context);
  const { createBullets } = useBullets(context);
  const { changeZoomOutButton } = useCustomZoomOutButton(context);
  const { createTooltip } = useXYTooltip(context);
  const { createMetricLabels } = useMetricLabels(context);
  const { createDrillDownEffect } = useXYDrillDown(context);
  const { createAxesTooltip } = useXYAxesTooltip(context);
  const { createXYChart, createXYCursor } = useXYChart(context);
  const { addRegressionLine } = useRegressionLine(context);
  const { addBenchmarkSeries } = useBenchmarkSeries(context);

  function initLineChart() {
    createXYChart();
    configureChart();
    createAxes();
    createAllSeries();
    changeZoomOutButton();

    if (config.value.hasValueScrollbar) {
      createScrollbar();
    }

    if (config.value.legend.active) {
      createLegend();
    }

    if (config.value.lineType !== LineTypes.STEP_LINE) {
      if (config.value.shouldOverrideBullets) {
        config.value.overrideBullets();
      }

      createBullets();
    }

    createMetricLabels();

    applyDataToAllSeries();

    if (config.value.hasTooltip) {
      createTooltip();
      createAxesTooltip();
      createXYCursor();
    }
    if (!config.value.isSparkLine) {
      createDrillDownEffect();
    }

    if (config.value.hasRegressionLine) {
      addRegressionLine();
    }

    if (config.value.benchmarks?.enabled) {
      addBenchmarkSeries();
    }
  }

  return {
    chart,
    initLineChart,
  };
}
