import angular from 'angular';

import smartCampaignAdminHtmlUrl from './smartcampaign.admin.html';

angular.module('core.smartcampaign.components', [])
    .component('smartCampaignAdmin', {
        bindings: {
            design: '<',
            state: '<',
            services: '<',
            currentModule: '<',
            showDetailsModal: '<',
        },
        templateUrl: smartCampaignAdminHtmlUrl,
        controllerAs: 'vm',
        controller: SmartCampaignAdminController
    });

/**
 * @ngInject
 */
function SmartCampaignAdminController(
    PubSub,
    $SmartCampaignDetailModalEvents,
    $DetailsModalFactoryEvents,
    SmartCampaignDetailsModalService
) {
    var vm = this;
    vm.addSmartCampaignClicked = addSmartCampaignClicked;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function addSmartCampaignClicked() {
        SmartCampaignDetailsModalService.setNewDetails();
    }

    /**
     * Event to spawn smart campaign detail modal
     * @param data
     * @private
     */
    function _onDetailModalInit(data) {
        SmartCampaignDetailsModalService.setEditDetails(data.data, data.row);
    }

    function $onInit() {
        SmartCampaignDetailsModalService.setServices(vm.services.plain());

        PubSub.on($SmartCampaignDetailModalEvents.INIT, _onDetailModalInit);
    }

    function $onDestroy() {
        PubSub.off($SmartCampaignDetailModalEvents.INIT, _onDetailModalInit);
    }

}