'use strict';
import angular from 'angular';

angular.module('core.namingconvention.services', [])

    .factory('NamingConventionFactory', NamingConventionFactory);

/**
 * @ngInject
 */
function NamingConventionFactory(
    NamingConventionResource
) {
    return {
        getData: getData
    };

    function getData(options) {
        return  NamingConventionResource.getData(options);
    }
}