import { dispatches, getters } from '@/modules/core/app/helpers/store';
import SegmentService from '@/modules/core/app/services/SegmentService';
import SessionResource from '@/modules/core/session/resources/SessionResource';
import { UserLoginAsUser, UserLogout } from '@/modules/core/nav/usermenu.trackevents';
import {
  SessionVars,
  SessionResponseType,
  LoginMessageEnum,
  InstanceStatus,
  FeaturePackage,
} from '@/modules/core/session/session.constants';
import { RouteGroup, PseudoRoute } from '@/modules/core/app/constants/api.constants';
import { AppModule } from '@/modules/core/app/constants/app.constants';
import { WelcomePathName } from '@/modules/core/app/constants/router.constants';

class SessionService {
  /**
   * @param email
   * @param password
   * @param recaptcha
   * @param hipaa_checked
   * @param two_step_verification_code
   * @param resend_verification_code
   * @param remember_this_device
   * @returns {Promise<AxiosPromise<any>>}
   */
  async login(
    email,
    password,
    recaptcha,
    hipaa_checked,
    two_step_verification_code,
    resend_verification_code,
    remember_this_device
  ) {
    return SessionResource.login(
      email,
      password,
      recaptcha,
      hipaa_checked,
      two_step_verification_code,
      resend_verification_code,
      remember_this_device
    );
  }

  /**
   *
   * @param code
   * @param from_google_login_redirect_state
   * @param from_google_login_redirect_params
   * @returns {Promise<Promise<AxiosResponse<T>>>}
   */
  async googleLogin(code, from_google_login_redirect_state, from_google_login_redirect_params) {
    return SessionResource.googleLogin({
      code,
      from_google_login_redirect_state,
      from_google_login_redirect_params,
    });
  }

  /**
   * @returns {Promise<AxiosPromise<any>>}
   */
  async getUserSettings(params = {}) {
    return SessionResource.getUserSettings({ ...params, is_nui: true });
  }

  /**
   * @param userSettings
   * @returns {Promise<AxiosPromise<any>>}
   */
  async logout(userSettings = getters.session.getUserSettings()) {
    if (!userSettings.isInstanceSuspended()) {
      await dispatches.dashboard.resetFilters();
    }
    const result = await SessionResource.logout();
    SegmentService.track(UserLogout.build());
    return result;
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<AxiosPromise<any>>}
   */
  async impersonateUser(id) {
    dispatches.session.resetLoginRedirectPath();
    const result = await SessionResource.impersonateUser(id);
    SegmentService.track(UserLoginAsUser.build(result.user));
    return result;
  }

  /**
   *
   * @returns {Promise<AxiosPromise<any>>}
   */
  async unImpersonateUser() {
    dispatches.list.clearListParamsState();
    return SessionResource.unImpersonateUser();
  }

  /**
   *
   * @param userSettings
   * @returns {string|string|*}
   */
  getLoginRedirectPath(userSettings = getters.session.getUserSettings()) {
    if (
      userSettings.instanceStatus === InstanceStatus.SUSPENDED_UNPAID ||
      userSettings.instanceStatus === InstanceStatus.SUSPENDED_INACTIVE ||
      userSettings.isOnboarding
    ) {
      return this.getHomePagePath(userSettings);
    }
    const loginRedirectPath = getters.session.getLoginRedirectPath();
    const localStorageLoginRedirectPath = window.localStorage.getItem(
      SessionVars.LOGIN_REDIRECT_PATH
    );

    // should only be used once, otherwise can redirect new sessions to unintended places
    if (loginRedirectPath || localStorageLoginRedirectPath) {
      dispatches.session.resetLoginRedirectPath();
    }

    return loginRedirectPath || localStorageLoginRedirectPath || this.getHomePagePath(userSettings);
  }

  /**
   * @param userSettings
   * @returns {string}
   */
  getHomePagePath(userSettings = getters.session.getUserSettings()) {
    if (userSettings.featurePackageName === FeaturePackage.GDS_TAPFUSION) {
      return `/${RouteGroup.SERVICE_MANAGE}`;
    }
    if (
      userSettings.instanceStatus === InstanceStatus.SUSPENDED_UNPAID ||
      userSettings.instanceStatus === InstanceStatus.SUSPENDED_INACTIVE
    ) {
      return `/${RouteGroup.BILLING}`;
    }
    if (userSettings.isOnboarding && !userSettings.isClient()) {
      return `/${PseudoRoute.ONBOARDING}`;
    }
    if (Number.isNaN(Number(userSettings.homePage))) {
      const ioHomepages = ['orders', 'myTasks', 'myQueues', 'io_clientDash'];
      if (ioHomepages.includes(userSettings.homePage)) {
        if (
          userSettings.isIOAuthorized &&
          userSettings.isModuleAvailable(AppModule.IO_TOOL) &&
          (userSettings.homePage !== 'io_clientDash' ||
            userSettings.isModuleAvailable(AppModule.IO_CLIENT_PORTAL))
        ) {
          return `/client/iotool/dist/#/items/${userSettings.homePage}`;
        }
        return `/${WelcomePathName}`;
      }
      return `/${userSettings.homePage}`;
    }
    return `/${RouteGroup.DASH}/${userSettings.homePage}`;
  }

  /**
   *
   * @param email
   * @returns {Promise<AxiosResponse<T>>}
   */
  async sendResetPasswordLink(email) {
    return SessionResource.sendResetPasswordLink({ email, is_nui: true });
  }

  /**
   *
   * @param params
   * @returns {Promise<number>}
   */
  async processResetPassword(params) {
    const response = await SessionResource.processResetPassword(params);
    if (response.type === SessionResponseType.PASSWORD_LINK_EXPIRED) {
      return LoginMessageEnum.PASSWORD_RESET_LINK_EXPIRED;
    }
    if (response.type === SessionResponseType.PASSWORD_LINK_RESENT) {
      return LoginMessageEnum.PASSWORD_RESET_LINK_RESENT;
    }
    if (response[0] === SessionResponseType.PASSWORD_RESET_SUCCESS) {
      return LoginMessageEnum.PASSWORD_RESET_SUCCESSFULLY;
    }
  }

  /**
   * @param userSettings
   * @returns {boolean}
   */
  useNewApp(userSettings = getters.session.getUserSettings()) {
    return (
      userSettings.user?.isUsingNewApp() || userSettings.isModuleAvailable(AppModule.DISABLE_OLD_UI)
    );
  }

  /**
   *
   * @param file
   * @returns {Promise}
   */
  uploadLogo(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          resolve(event.target.result);
        };
      } catch (e) {
        reject();
      }
    });
  }
}

export default new SessionService();
