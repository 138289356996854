'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import {
    $ExportBuilderAdminNewReportModalEvents,
    ExportBuilderNewReportContext
} from "coreModules/exportbuilder/exportbuilder.constants";

angular.module('exportbuilder.directives', [])
    .directive('openExportBuilderFromDashboard', openExportBuilderFromDashboard)
    .directive('exportBuilderDirective', exportBuilderDirective);

//
//  directive export builder: show the main base page of the builder
//
/**
 * @ngInject
 */
function exportBuilderDirective(
    $rootScope,
    $timeout,
    $document,
    $state,
    PubSub,
    ExportBuilderDashboardService,
    DrawOptionPanelFactory,
    $ExportBuilderDashboardEvents,
    ExportBuilderFacadeUIService,
    $ExportBuilderItemDirectiveEvents,
    ReportStudioTemplateNetworkService
) {
    return {
        restrict: 'A',
        link: function(scope, el) {
            var $window = angular.element(window);

            _registerEvents();
            $rootScope.$on('$stateChangeStart', function () {
                PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE);
            });
            scope.$on('$destroy', function () {
                _unregisterEvents();
            });

            function _clickEvent(event) {
                // event.preventDefault();
                // event.stopPropagation();
            }

            //recalculate zoom based on the size of the window
            function _onWindowResize() {
                scope.$evalAsync(function () {
                    ExportBuilderDashboardService.calculateZoom();
                    if (!ExportBuilderFacadeUIService.getState().isResizing) {
                        ExportBuilderDashboardService.focusOnItem(null);
                    }
                });
            }

            function _onBeforeUnload(e) {
                var message = null;
                if (ReportStudioTemplateNetworkService.getCount() > 0) {
                    message = 'We are currently still saving your changes. Please press cancel and wait until the save indicator above disappears.';
                }
                if (message) {
                    (e || window.event).returnValue = message;
                    return message;
                }
                return;
            }

            function _onItemIsResizing(isResizing) {
                if (isResizing) {
                    el.addClass('is-resizing-item');
                } else {
                    el.removeClass('is-resizing-item');
                }
            }

            function _registerEvents() {
                $document.bind('contextmenu', _clickEvent);
                $window.bind('resize', _onWindowResize);
                window.addEventListener('beforeunload', _onBeforeUnload);

                PubSub.on($ExportBuilderItemDirectiveEvents.IS_RESIZING, _onItemIsResizing);
            }

            function _unregisterEvents() {
                $window.unbind('resize', _onWindowResize);
                $document.unbind('contextmenu', _clickEvent);
                window.removeEventListener('beforeunload', _onBeforeUnload);

                PubSub.off($ExportBuilderItemDirectiveEvents.IS_RESIZING, _onItemIsResizing);
            }
        }
    }
}

//
//  directive export builder create a new builder with all the widgets of a dashboard
//
/**
 * @ngInject
 */
function openExportBuilderFromDashboard(
    $state,
    ExportBuilderInitializerService,
    ExportBuilderRouterState,
    DesignFactory,
    PubSub,
    AppLoader
) {
    return {
        restrict: 'A',
        link: function(scope, el) {
            _registerEvents();
            scope.$on('$destroy', () => {
                _unregisterEvents();
            });

            el.on('click', function() {
                if (scope.design && scope.design.page && scope.activeLayout) {
                    PubSub.emit($ExportBuilderAdminNewReportModalEvents.INIT_MODAL, {context: ExportBuilderNewReportContext.DASHBOARD});
                }
            });

            function _onNewReportTemplateClicked(report) {
                let designTemplate = null;
                if (report) {
                    designTemplate = report;
                    PubSub.$emit($ExportBuilderAdminNewReportModalEvents.CLOSE_MODAL);
                }


                PubSub.$emit(AppLoader.SHOW, {icon: 'icomoon-report', title: 'Generating your report'});
                $('div.app-view').addClass('animated laydown');

                ExportBuilderInitializerService
                    .initWithPage(DesignFactory.getCurrentPage(), designTemplate)
                    .then(function (report) {

                        if (window.isNUI) {
                            PubSub.emit('SegmentEvents', {
                                event: 'ExportToReportStudioEvent',
                                payload: {report, page: DesignFactory.getCurrentPage(), designTemplate}
                            });
                        }

                        const params = {id: report.id};
                        if (report.client) {
                            params['sample_data'] = false;
                        }

                        $state.go(ExportBuilderRouterState.EXPORT_BUILDER_STUDIO, params);

                        PubSub.$emit($ExportBuilderAdminNewReportModalEvents.CLOSE_MODAL);
                    })
                    .catch(function () {
                        PubSub.$emit(AppLoader.HIDE);
                        $('div.app-view').removeClass('animated laydown');
                    });
            }

            function _registerEvents() {
                PubSub.on($ExportBuilderAdminNewReportModalEvents.DID_SELECT_PREDEFINED_TEMPLATE, _onNewReportTemplateClicked);
            }

            function _unregisterEvents() {
                PubSub.off($ExportBuilderAdminNewReportModalEvents.DID_SELECT_PREDEFINED_TEMPLATE, _onNewReportTemplateClicked);
            }
        }
    }
}