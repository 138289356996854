import angular from 'angular';

angular.module('design.layout.constants', [])
    .constant('$LayoutEvents', $LayoutEvents());

function $LayoutEvents() {
    return {
        IS_CHANGING_LAYOUT: 'layout:IS_CHANGING_LAYOUT',
        CHANGED: 'layout:CHANGED',
        DESTROY: 'layout:DESTROY'
    };
}