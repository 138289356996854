'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import {RouteGroup} from "../../../../../grok/src/modules/core/app/constants/api.constants";

angular.module('calculations.ctrls', [])

    .controller('CalculationsController', CalculationsController)
    .controller('CalculationsListController', CalculationsListController);

CalculationsListController.$inject = ['$rootScope', '$http', '$scope', 'data', 'metadata'];
function CalculationsListController($rs, $http, $scope, data, metadata) {

    $scope.dtOptions = {};
    $scope.dtOptions.data = data;
    $scope.dtOptions.columns = metadata.plain();
    $scope.dtOptions.entityName = 'calculations';
    $scope.dtOptions.itemName = 'calculation';
    $scope.dtOptions.iActions = 2;
    $scope.dtOptions.customRenders = {};
    $scope.dtOptions.customRenders['id'] = function (data, type, full) {

        let deleteBtn, editBtn;

        if (full.is_nui) {
            editBtn = '<a class="action has-tooltip disabled" title="" data-original-title="This calculation has been created or updated in advanced mode, and can\'t be edited here"><span class="icon icon-pencil"></span></a>';
        } else {
            editBtn = '<a class="action has-tooltip" title="" href="#/calculations/detail/'+full.id+'" data-original-title="Edit"><span class="icon icon-pencil"></span></a>';
        }

        if (full.can_be_deleted) {
            deleteBtn = '<a class="action action-red delete-action has-tooltip" title="" data-calculation="'+full.name+'" data-url="calculations/'+full.id+'" data-original-title="Delete"><span class="icon icon-trash"></span></a>';
        }
        else {
            deleteBtn = '<a class="action has-tooltip delete-modal" data-id="'+full.id+'" data-calculation="'+full.name+'" toggle-modal modal-id="calculation-calcinuse-modal" title="" data-original-title="'+full.can_be_deleted_tooltip+', click to see where"><span class="icon icon-trash"></span></a>';
        }

        return editBtn + deleteBtn;
    };
    $scope.dtOptions.customRenders['formula'] = function (data) {
        var result = '<div>';
        angular.forEach(data, function(term) {
            if(term) {
                result += '<div class="operand mr10 ' + (term.type === 'variable' ? 'badge badge-primary' : '') + '">' + term.value + '</div>';
            }
        });
        result += '</div>';
        return result;
    };
    $scope.dtOptions.customRenders['data_type'] = function (data, type, full) {
        return full.data_type_display;
    };

    //adding created row
    $scope.dtOptions.fnCreatedRow = function(row, data) {
        $(row).find('td.action-cell a').each(function () {
            if ($(this).hasClass('delete-action')) {
                $(this).click(function(e) {
                    var entityName = $(this).data('calculation');
                    var item = $(this).closest('tr');
                    var deleteUrl = $rs.util.apiUrl($(this).data('url'));
                    swal({
                            title: 'Are you sure?',
                            text: 'You will delete <b>' + entityName + '</b>',
                            html: true,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, delete it',
                            cancelButtonText: 'No',
                            closeOnConfirm: false,
                            closeOnCancel: true,
                            showLoaderOnConfirm: true
                        },
                        function(isConfirm) {
                            if (isConfirm) {
                                return $http.delete(deleteUrl).success(function (json) {
                                    swal('Deleted!', entityName + ' has been deleted', 'success');
                                    item.remove();
                                });
                            }
                    });
                });
            }
            else if ($(this).hasClass('delete-modal')) {
                $(this).click(function(e) {
                    $scope.showCalcInUseModal($(this));
                });
            }
        });
    };

    $scope.showCalcInUseModal = function(element) {
        var calculation_id = $(element).data('id'),
            calculation_name = $(element).data('calculation');

        $('#calculation-calcinuse-modal .modal-title .calcinuse-title').text(calculation_name);
        $('#calculation-calcinuse-modal .modal-body .calcinuse-content').html('<div class="loading-wheel"></div>');
        $('#calculation-calcinuse-modal').modal('show');

        $http.get(
            '/server/api/calculations/usage/'+calculation_id
        ).then(function successCallback(json) {
            var data = json.data.data,
                widgets = data.widget.data,
                widgets_total = data.widget.metadata.total,
                categories = data.category.data,
                categories_total = data.category.metadata.total,
                calculations = data.calculation.data,
                calculations_total = data.calculation.metadata.total,
                lookups = data.servicelookup.data,
                lookups_total = data.servicelookup.metadata.total,
                reportstudio = data.reportstudio.data,
                reportstudio_total = data.reportstudio.metadata.total,
                alerts = data.alert.data,
                alerts_total = data.alert.metadata.total,
                diff_total = 0,
                htmlContent = '';

            // Widgets :
            if (widgets && widgets_total > 0) {
                htmlContent += '<h6 class="mt10">';
                if (Object.keys(widgets).length > 1) {
                    htmlContent += 'Widgets';
                }
                else {
                    htmlContent += 'Widget';
                }
                htmlContent += ' that use this calculation:</h6><ul class="mt5 mb5">';

                angular.forEach(widgets, function (widget) {
                    if (widget.url) {
                        let widgetUrl = widget.url;
                        if (widget.name.startsWith('Dashboard Templates')) {
                            widgetUrl = 'managepagelibrary';//dashboard templates
                        }
                        else if (widget.name.startsWith('Section Templates')) {
                            widgetUrl = 'managelayoutlibrary';//section templates
                        }
                        else if (widget.name.startsWith('Widget Templates')) {
                            widgetUrl = 'managewidgetlibrary';//widget templates
                        }
                        htmlContent += '<li class="mb5"><a href="#/' + widgetUrl + '" target="_blank">' + widget.name + '</a></li>';
                    }
                    else {
                        htmlContent += '<li class="mb5">' + widget.name + ' (this widget has been removed from the dashboard, please remove it from the Library)</li>';
                    }
                });
                if (widgets_total > 3) {
                    diff_total = widgets_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Reportstudio :
            if (reportstudio && reportstudio_total > 0) {
                htmlContent += '<h6 class="mt10">';
                if (Object.keys(reportstudio).length > 1) {
                    htmlContent += 'Widgets';
                }
                else {
                    htmlContent += 'Report Studio';
                }
                htmlContent += ' that use this calculation:</h6><ul class="mt5 mb5">';

                angular.forEach(reportstudio, function (report) {
                    if (report.url) {
                        htmlContent += '<li class="mb5"><a href="#/' + report.url + '" target="_blank">' + report.name + '</a></li>';
                    }
                    else {
                        htmlContent += '<li class="mb5">' + report.name + ' (this Report has been removed from the dashboard, please remove it from the Library)</li>';
                    }
                });
                if (reportstudio_total > 3) {
                    diff_total = reportstudio_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Categories :
            if (categories && categories_total > 0) {
                htmlContent += '<h6 class="mt10">';
                if (Object.keys(categories).length > 1) {
                    htmlContent += 'Channels';
                }
                else {
                    htmlContent += 'Channel';
                }
                htmlContent += ' that use this calculation:</h6><ul class="mt5 mb5">';

                angular.forEach(categories, function (category) {
                    var categoryUrl = category.url.replace("categories/", "categories/detail/");
                    htmlContent += '<li class="mb5"><a href="#/' + categoryUrl + '">' + category.name + '</a></li>';
                });
                if (categories_total > 3) {
                    diff_total = categories_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Calculations :
            if (calculations && calculations_total > 0) {
                htmlContent += '<h6 class="mt10">Other ';
                if (Object.keys(calculations).length > 1) {
                    htmlContent += 'calculations';
                }
                else {
                    htmlContent += 'calculation';
                }
                htmlContent += ' that use this calculation:</h6><ul class="mt5 mb5">';

                angular.forEach(calculations, function (calculation) {
                    htmlContent += '<li class="mb5">' + calculation.name + '</li>';
                });
                if (calculations_total > 3) {
                    diff_total = calculations_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Lookups :
            if (lookups && lookups_total > 0) {
                htmlContent += '<h6 class="mt10">';
                if (Object.keys(lookups).length > 1) {
                    htmlContent += 'Data Lookups';
                }
                else {
                    htmlContent += 'Data Lookup';
                }
                htmlContent += ' that use this calculation:</h6><ul class="mt5 mb5">';

                angular.forEach(lookups, function (lookup) {
                    let lookupUrl = lookup.url || 'servicelookups/' + lookup.id;
                    htmlContent += '<li class="mb5"><a href="#/' + lookupUrl + '">' + lookup.name + '</a></li>';

                });
                if (lookups_total > 3) {
                    diff_total = lookups_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Alerts :
            if (alerts && alerts_total > 0) {
                htmlContent += '<h6 class="mt10">Other ';
                if (Object.keys(alerts).length > 1) {
                    htmlContent += 'alerts';
                }
                else {
                    htmlContent += 'alert';
                }
                htmlContent += ' that use this alert:</h6><ul class="mt5 mb5">';

                angular.forEach(alerts, function (alert) {
                    let alertUrl = alert.url || 'alerts/' + alert.id;
                    htmlContent += '<li class="mb5"><a href="#/' + alertUrl + '">' + alert.name + '</a></li>';
                });
                if (alerts_total > 3) {
                    diff_total = alerts_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            if (htmlContent == '') {
                htmlContent = '<p>Please contact support if you can\'t remove this calculation (msg: 1)</p>';
            };
            $('#calculation-calcinuse-modal .modal-body .calcinuse-content').html(htmlContent);

        }, function errorCallback(json) {
            $('#calculation-calcinuse-modal .modal-body .calcinuse-content').html('<p>Please contact support if you can\'t remove this calculation (msg: 2)</p>');
        });
    }
}

/**
 * @ngInject
 */
function CalculationsController(
    $scope,
    calculation,
    DataSourceType,
    AppFactory,
    CalculationFactory,
    ServiceDataFactory,
    CategoryDataViewFactory
) {
    var model = $.extend({}, calculation ? calculation.plain() : {});
    var isNewModel = !Object.size(model);
    if (!isNewModel) {
        model.data_source.text = model.data_source_name;
        model.data_view.text = model.data_view.text || model.data_view.name;
    }
    var calcResetting = false;
    var dataTypes = AppFactory.getSelectableDataTypes();
    var categories = AppFactory.getConnectedCategories();
    $scope.entity = {
        nameKey: 'name',
        idKey: 'id',
        pageTitle: '',
        action: 'calculations' + (isNewModel ? '' : '/' + model.id)
    };
    $scope.entity.pageTitle = isNewModel ? 'New Calculation' : 'Edit ' + model[$scope.entity.nameKey];
    $scope.message = 'Please select a Data Category and a Data View to begin defining the calculation.';
    $scope.model = $.extend(model, genModel());

    var connectedServices = AppFactory.getConnectedServices();
    $scope.dataSourcesSelectOptions = {data: genDataSources(categories, connectedServices), width: '100%', placeholder: 'Select a Data Category', formatResult: formatItem};

    $scope.dataViewSelectOptions = {data: [], width: '100%', placeholder: isNewModel ? 'Please Select a Data Category' : 'Select a Data View'};
    $scope.dataTypeSelectOptions = {data: [], width: '100%', placeholder: 'Select a Data Type'};
    $scope.prebuildModel = prebuildModel;

    //Setting to scope for testing purposes
    $scope.validateCalcActivation = validateCalcActivation;

    // this is true if the calculation is already in use by widgets
    $scope.calcInUse = (!isNewModel && !model.can_be_deleted);

    buildDataTypes();

    //Fetch data and redraw DataView select2 if needed
    if ($scope.calcInUse) {
        buildNewDataView();
    }

    fetchDataviews();

    //Watch for any changes in the data source model: update the select2 and wipe out dependent variables such as 'data_view', 'data_type', and 'forumla'
    $scope.$watch('model.data_source', function(nV, oV) {
        if(nV) {
            if(!_.isEqual(nV, oV)) {
                //Ensure that the current and original models are in sync, wipe out everything if they aren't
                if(JSON.stringify(nV) !== JSON.stringify($scope.originalModel.data_source)){
                    $scope.model.data_view = '';
                    $scope.model.formula = [];
                }
                //There has been a change to the data source so we either need to set the data view as a category type, or a service type select2
                fetchDataviews();
            }
            //Also make sure and fetch and set calc if other required vars are set
            if(validateCalcActivation('data_source')){
                fetchAndSetCalc();
            }
        }
        //In the case that the newVal is set to null or undefined, run the resetCal function
        if(!nV && !calcResetting) {
            resetCalc();
        }
        else {
            calcResetting = false;
        }
    })

    //Watch for any changes in the data view model: update the select2 and wipe out dependent variables such as 'formula'
    $scope.$watch('model.data_view', function(nV, oV) {
        //If there has been a change in value
        if(nV && !_.isEqual(nV, oV)) {
            //Ensure that the current and original models are in sync, wipe out everything if they aren't
            if(JSON.stringify(nV) !== JSON.stringify($scope.originalModel.data_view)) {
                $scope.model.formula = [];
            }
            //Also make sure and fetch and set calc if other required vars are set
            if(validateCalcActivation('data_view')){
                fetchAndSetCalc();
            }
        }
        //In the case that the newVal is set to null or undefined, run the resetCal function
        if(!nV & !calcResetting) {
            resetCalc();
        }
        else {
            calcResetting = false;
        }
    })

    function fetchDataviews()
    {
        if ($scope.model.data_source.id)
        {
            if ($scope.model.data_source.type === DataSourceType.SERVICE_DATA) {
                genDataView().then(function(data){
                    $scope.dataViewSelectOptions = {data: data, width: '100%', placeholder: 'Select a Data View'};
                });
            } else {
                genCategoryDataView().then(function(data){
                    $scope.dataViewSelectOptions = {data: data, width: '100%', placeholder: 'Select a Data View'};
                });
            }
        }
    }

    function buildDataTypes() {
        return CalculationFactory.getDataTypes().then(function(data) {
            let values = [];
            _.forEach(data.values, function(value) {
                if (value.key !== 'string') {
                    values.push({'id': value.key, 'text': value.value});
                }
            });
            $scope.dataTypeSelectOptions = {data: values, width: '100%', placeholder: 'Select a Data Type'};

            // convert data_type which is a single string to an object for Select2
            model.data_type = _.find(values, function(elem) {return elem.id === model.data_type});
        });
    }

    /**
     * Build out a new data view select2
     */
    function buildNewDataView() {
        genDataView().then(function(data){
            $scope.dataViewSelectOptions = {data: data, width: '100%', placeholder: 'Select a Data View'};
        });
    }

    //If all cases are met, fetch the calculator metric data and enable it
    function fetchAndSetCalc() {
        CalculationFactory.getMetrics($scope.model.data_source, $scope.model.data_view.id === 'main_columns' ? '' : $scope.model.data_view.id).then(function(data){
            $scope.calcMetrics = [];

            var current_calculation_name = 'calculation_' + $scope.model.id;

            // remove "row count" metric for calculation, as this metric isn't implemented in the legacy code
            // also remove current calculation, to prevent an infinite loop situation
            for (var i=0; i<data.length; i++)
            {
                if (data[i].field !== 'row_grouping_count' && data[i].field !== current_calculation_name)
                {
                    $scope.calcMetrics.push(data[i]);
                }
            }
            $scope.message = '';
        });
    }

    /**
     * Function for formatting the select2 options with the service icon
     * @param item
     * @returns {jQuery|HTMLElement}
     */
    function formatItem(item) {
        if (!item.id) { return item.text; }
        return $('<div><div class="service-square service-square-24" style="background-color:' + item.color + '"><div class="icon ' + item.service_icon + '"></div></div> ' + item.text + '</div>');
    }

    /**
     * Generate the data sources array for the data sources select2
     * @param categories
     * @param services
     * @returns {Array}
     */
    function genDataSources(categories, services) {
        var returnArray = [];
        returnArray.push({
            text: "Data Sources",
            children: $.map(services, function(service) {
                return {id: service.id, text: service.name, type: DataSourceType.SERVICE_DATA, color: service.color, service_icon: service.has_custom_icon ? service.icon : "serviceicon-" + service.icon};
            })
        });

        returnArray.push({
            text: "Channels",
            children: $.map(categories, function(category) {
                return {id: category.id, text: category.name, type: DataSourceType.CATEGORY_DATA, color: category.color, service_icon: category.icon || "icon-th-large"};
            })
        });

        return returnArray;
    }

    /**
     * Generate the data view array for the data view select2
     */
    function genDataView() {
        return ServiceDataFactory.dataViews($scope.model.data_source.id).get().then(function(data){
            return $.map(data, function(dataView) {
                return {id: dataView.id, text: dataView.name};
            });
        });
    }

    function genCategoryDataView() {
        return CategoryDataViewFactory.categoryDataView($scope.model.data_source.id).getList().then(function(data){
            return $.map(data, function(dataView) {
                return {id: dataView.id, text: dataView.name};
            });
        });
    }

    /**
     * Generate the model
     * @returns {{id: *, name: string, data_source: string, data_view: string, data_type: string, formula: Array}}
     */
    function genModel() {
        return {
            id: isNewModel ? null : model.id,
            name: isNewModel ? '' : model.name,
            data_source: isNewModel ? '' : model.data_source,
            data_view: isNewModel ? '' : model.data_view,
            data_type: isNewModel ? '' : model.data_type,
            formula: isNewModel ? [] : model.formula,
        }
    }

    /**
     * Set the model correctly during saving with the prebuild hook for the form directive
     * @param model
     */
    function prebuildModel(model) {
        if(model.data_type) {
            model.data_type = model.data_type.id;
        }
    }

    /**
     * Reset calculator data when needed
     */
    function resetCalc() {
        $scope.calcMetrics = [];
        $scope.model.data_view = '';
        $scope.dataViewSelectOptions = {data: [], width: '100%', placeholder: isNewModel ? 'Please Select a Data Category' : 'Select a Data View'};
        $scope.message = 'Please select a Data Category and a Data View to begin defining the calculation.';
        calcResetting = true;
    }

    /**
     * Validate that all needed user selections are selected before trying to fetch data
     * @param trigger
     * @returns {boolean}
     */
    function validateCalcActivation(trigger) {
        var watchers = ['data_view', 'data_source'];
        var isEverythingSet = true;
        for(var i = 0; i < watchers.length; i++) {
            if(watchers[i] !== trigger) {
                if(!$scope.model[watchers[i]]) {
                    isEverythingSet = false;
                    break;
                }
            }
        }
        return isEverythingSet;
    }

}
