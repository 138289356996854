"use strict";
import angular from "angular";

export const AppConstants = {
  APP_ENVIRONMENT: {
    PRODUCTION: "production",
    DEVELOPMENT: "development",
  },
};

export const AppConfig = {
  NUI_PATH: "/client/dist/",
  ROOT_URL: window.location.pathname /* /client/analytics/{ENV_MODE}/ */,
  CORE_URL: "/client/core/",
  MAGE_URL: "/app/dash/",
  API_URL: "/server/api/",
  IO_URL: "/client/iotool/dist/",
  IO_CLIENT_URL: "/client/ioclient/dist/",
  TA_URL: "/client/analytics/dist/",
  TA_PRE_URL: "/client/analytics/",
  IO_API_URL: "/app/iotool/",
  API_JSON_URL: "/client/iotool/app/data/",
  API_DEV_URL: "",
  ENV: "dev",
  MODE: "dist",
};

export const AppFeature = {
  IMPORT_WIZARD: "import_wizard",
  CATEGORIES: "categories",
  CATEGORY_MANAGEMENT: "category_management",
  CALCULATIONS: "custom_calculations",
  MANAGE_LEADS: "leads_management",
  EXPORT_BUILDER: "export_builder",
  DASHBOARD_MANAGEMENT: "dashboard_view_management",
};

export const AppModule = {
  PREDEFINED_CATEGORY_DASHBOARD: "predefined_category_dashboard",
  CLIENT_HOLD_STATUS: "client_hold_status",
  MANAGE_LEADS: "leads_management",
  HIPAA: "hipaa",
  IMPERSONATE_USERS: "impersonate_users",
  AUTO_MAPPING: "auto_mapping",
  BRAND_PACKAGE: "brand_package",
  IO_TOOL: "io_tool",
  AUTO_LEADS: "auto",
  SMART_CAMPAIGNS: "smart_campaigns",
  GOALS: "goals",
  REPORT_STUDIO: "report_studio",
  REPORT_STUDIO_AGENT_ACCESS: "report_studio_agent_access",
  NUI: "nui",
  DISABLE_OLD_UI: "disable_oui",
  DASHBOARD_FILTER_VALUES: "dashboard_filter_values",
  DASHBOARD_FILTERS_SUMMARY: "dashboard_filters_summary",
  DYNAMIC_LAYOUTS: "dynamic_layouts",
  REPORT_STUDIO_PAGE_ASSIGNMENT: "report_studio_page_assignment",
  CLIENT_CURRENCY: "client_currency",
  GEO_API_FILTER: "geo_api_filter",
  ADOBE_PPT_CONVERTER: "adobe_ppt_converter",
  CUSTOM_TRANSLATIONS: "custom_translations",
  CHATGPT_WIDGET: "chatgpt_widget",
  CHATGPT: "chatgpt",
  CUSTOM_FONTS: "custom_fonts",
  REPORT_STUDIO_EXECUTIVE_SUMMARIES: "report_studio_executive_summaries",
  BENCHMARKS: "benchmark",
  CLIENT_CALCULATIONS: 'client_calculations'
};

export const AppLoader = {
  SHOW: "AppLoader:SHOW",
  HIDE: "AppLoader:HIDE",
};

export const UserType = {
  SUPERADMIN: "superadmin",
  CLUSTERADMIN: "cluster_admin",
  AGENT: "agent",
  CLIENT: "client",
  SUPERADMIN_DISPLAY: "Super Admin",
  CLUSTER_ADMIN_DISPLAY: "Business Unit Admin",
  AGENT_DISPLAY: "Agent",
  CLIENT_DISPLAY: "Client",
};

export const UserThemes = {
  TYPE_HYBRID: "hybrid",
  TYPE_LIGHT: "light",
  TYPE_DARK: "dark",
  BRIGHT: "bright",
  MINT: "mint",
  VELVET: "velvet",
  HOTH: "hoth",
  CLASSIC: "classic",
  AZURE: "azure",
  LEATHER: "leather",
  MIDAS: "midas",
  EGGPLANT: "eggplant",
  TEAL: "teal",
  CUSTOM: "custom",
  CUSTOMLIGHT: "customlight",
  CUSTOMHYBRID: "customhybrid",
  CUSTOMDARK: "customdark",
};

export const SmartCampaignConstants = {
  CAMPAIGN_TYPE: "campaign_type",
  TYPE: {
    FILTERING: "filtering",
    GROUPING: "grouping",
  },
};

export const ColumnFormat = {
  FORMAT_STRING: "string",
  FORMAT_ID: "id",
  FORMAT_INTEGER: "integer",
  /* FORMAT_BIG_INTEGER: 'big_integer',*/
  FORMAT_PERCENT: "percent",
  FORMAT_CURRENCY: "currency",
  FORMAT_BOOLEAN: "bool",
  FORMAT_DECIMAL: "decimal",
  FORMAT_ARRAY: "array",
  FORMAT_DATE: "date",
  FORMAT_TIME: "time",
  FORMAT_DATETIME: "datetime",
  FORMAT_LINK: "link",
  FORMAT_URL: "url",
  FORMAT_IMAGE: "image",
  FORMAT_CALLBACK: "callback",
  FORMAT_COORDINATE: "coordinate",
  FORMAT_OBJECT: "object",
  FORMAT_PHONE_NUMBER: "phonenumber",
  FORMAT_ENUM: "enum",
  FORMAT_AUDIO: "audio",
  FORMAT_THUMBNAIL: "thumbnail",
  FORMAT_CLICK_TO_VIEW_LINK: "clicktoview_link",
};

export const PostProcessType = {
  POSTPROCESS_NONE: "none",
  POSTPROCESS_NUMERIC: "numeric",
  POSTPROCESS_DECIMAL: "decimal",
  POSTPROCESS_BOOLEAN: "bool",
  POSTPROCESS_JSON: "json",
  POSTPROCESS_COMMA_TO_ARRAY: "comma_delimited_to_array",
  POSTPROCESS_SERVICE_ID_STRING: "service_id_string",
  POSTPROCESS_DATE: "date",
  POSTPROCESS_DATETIME: "datetime",
  POSTPROCESS_TIME: "time",
  POSTPROCESS_PHONE_NUMBER: "phonenumber",
  POSTPROCESS_CAPITALIZE: "capitalize",
  POSTPROCESS_DATATYPE: "datatype",
  POSTPROCESS_CATEGORY_DATATYPE: "category_datatype",
  POSTPROCESS_HTML: "html",
  POSTPROCESS_IFRAME: "iframe",
  POSTPROCESS_YES_NO: "yes_no",
  POSTPROCESS_US_PHONE_NUMBER: "us_phone_number",
  POSTPROCESS_NL2BR: "nl2br",
  POSTPROCESS_URL: "url",
  POSTPROCESS_URL_IMAGE: "url_image",
  POSTPROCESS_AUDIO: "audio",
  POSTPROCESS_THUMBNAIL: "thumbnail",
  POSTPROCESS_CALLBACK: "callback",
  POSTPROCESS_CUSTOM: "custom",
  POSTPROCESS_CLICK_TO_VIEW_LINK: "clicktoview_link",
};

export const EditControlType = {
  TYPE_NONE: "none",
  TYPE_TEXT: "text",
  TYPE_NUMBER: "number",
  TYPE_PHONE_NUMBER: "phone_number",
  TYPE_EMAIL: "email",
  TYPE_TEXTAREA: "textarea",
  TYPE_SINGLE_SELECT: "single_select",
  TYPE_MULTI_SELECT: "multi_select",
  TYPE_CURRENCY: "currency",
  TYPE_CHECKBOX: "checkbox",
  TYPE_RADIO: "radio",
  TYPE_CUSTOM: "custom",
};

export const ColumnValueType = {
  SET: "set",
  DISTINCT: "distinct",
};

export const FilterParam = {
  FILTER_EQUAL: "eq",
  FILTER_NOT_EQUAL: "neq",
  FILTER_LIKE: "like",
  FILTER_NOT_LIKE: "nlike",
  FILTER_IN: "in",
  FILTER_NOT_IN: "nin",
  FILTER_IS: "is",
  FILTER_IS_NOT_NULL: "notnull",
  FILTER_IS_NULL: "null",
  FILTER_GREATER_THAN: "gt",
  FILTER_LESS_THAN: "lt",
  FILTER_GREATER_OR_EQUAL: "gteq",
  FILTER_LESS_OR_EQUAL: "lteq",
  FILTER_REG_EXP: "regexp",
  FILTER_FROM: "from",
  FILTER_TO: "to",
  FILTER_BETWEEN: "btw",
  ASSOCIATION_AND: "and",
  ASSOCIATION_OR: "or",
};

export const DataSourceType = {
  PAGE: "page",
  PAGE_LIBRARY: "page_library",
  PAGE_TAG: "page_tag",
  LAYOUT_LIBRARY: "layout_library",
  LAYOUT_TAG: "layout_tag",
  WIDGET: "widget",
  WIDGET_LIBRARY: "widget_library",
  REPORT_STUDIO_LIBRARY: "report_studio_library",
  WIDGET_TAG: "widget_tag",
  SERVICE: "service",
  SERVICE_DATA: "service_data",
  SCHEDULED_REPORTS: "scheduled_reports",
  SERVICE_ORDER_ITEM: "service_order_item",
  SERVICE_CLIENT_MAPPING: "service_client_mapping",
  SERVICE_CLIENT_MAPPING_LOG: "service_client_mapping_log",
  SERVICE_CLIENT_MAPPING_LOG_RESULT: "service_client_mapping_log_result",
  GOALS: "goals",
  GOAL_DATA: "goal_data",
  INDUSTRY_BENCHMARK_PAID_MEDIA: "industry_benchmark_paid_media",
  INDUSTRY_BENCHMARK_LEAD_TRACKING: "industry_benchmark_lead_tracking",
  INDUSTRY_BENCHMARK_EMAIL: "industry_benchmark_email",
  CONNECTION_STATUS: "connection_status",
  NAMING_CONVENTION: "naming_convention",
  TAG: "tag",
  TAG_VALUE: "tag_value",
  CATEGORY: "category",
  CATEGORY_DATA: "category_data",
  LEADS: "leads",
  END_CUSTOMER: "end_customer",
  CLIENT: "client",
  CLIENT_GROUP: "client_group",
  CLUSTER: "cluster",
  USER: "user",
  USER_LOGINS: "userlogins",
  LINE_ITEM: "lineitem",
  LINE_ITEMS_REPORT: "line_items_report",
  LINE_ITEM_TASKS_REPORT: "line_item_tasks_report",
  LINE_ITEMS_WITH_TASKS_REPORT: "line_items_with_tasks_report",
  FLIGHTS_REPORT: "flights_report",
  FLIGHT_TASKS_REPORT: "flight_tasks_report",
  FLIGHTS_WITH_TASKS_REPORT: "flights_with_tasks_report",
  BILLING_REPORT: "billing_report",
  TASK: "task",
  MASTER: "io_master",
  ORDER: "order",
  ORDERS_REPORT: "orders_report",
  ORDER_TASKS_REPORT: "order_tasks_report",
  ORDERS_WITH_TASKS_REPORT: "orders_with_tasks_report",
  IO_MASTER: "io_master",
  EC_TASKS_SUMMARY: "ec_tasks_summary",
  CUSTOMER: "customer",
  ALERT: "alert",
  EXTERNAL_API: "external_api",
  EXECUTIVE_SUMMARY: "executive_summary",
  EXECUTIVE_SUMMARY_ITEM: "executive_summary_item",
  TAP_DB_VIZ: "tap_db_viz",
  SMART_CAMPAIGN: "smart_campaign",
  PRODUCT: "product",
  EMAIL_DATA: "email_data",
  SEO_WEBSITES: "seo_websites",
  OTHER: "other",
  BUY_SHEET: "buy_sheet",
  BUY_SHEET_CLIENT_FIELD: "buy_sheet_client_field",
  BUY_SHEET_ORDER_FIELD: "buy_sheet_order_field",
  BUY_SHEET_LINE_ITEM_FIELD: "buy_sheet_line_item_field",
  BUY_SHEET_FLIGHT_FIELD: "buy_sheet_flight_field",
  BUY_SHEET_SIGNATURE: "buy_sheet_signature",
};

export const DataView = {
  CGN: "cgn",
};

export const CurrencyConstants = {
  AED: "د.إ",
  AFN: "Af",
  ALL: "Lek",
  AMD: "դր",
  ANG: "NAf",
  AOA: "Kz",
  ARA: "₳",
  ARL: "$L",
  ARM: "m$n",
  ARS: "AR$",
  AUD: "AU$",
  AWG: "Afl",
  AZN: "man",
  BAM: "KM",
  BBD: "Bds$",
  BDT: "Tk",
  BEF: "BF",
  BGN: "лв",
  BHD: "BD",
  BIF: "FBu",
  BMD: "BD$",
  BND: "BN$",
  BOB: "Bs",
  BOP: "$b",
  BRL: "R$",
  BSD: "BS$",
  BTN: "Nu",
  BWP: "BWP",
  BZD: "BZ$",
  CAD: "CA$",
  CDF: "CDF",
  CHF: "Fr",
  CLE: "Eº",
  CLP: "CL$",
  CNY: "CN¥",
  COP: "CO$",
  CRC: "₡",
  CUC: "CUC$",
  CUP: "CU$",
  CVE: "CV$",
  CYP: "CY£",
  CZK: "Kč",
  DEM: "DM",
  DJF: "Fdj",
  DKK: "Dkr",
  DOP: "RD$",
  DZD: "DA",
  EEK: "Ekr",
  EGP: "EG£",
  ERN: "Nfk",
  ESP: "Pts",
  ETB: "Br",
  EUR: "€",
  FIM: "mk",
  FJD: "FJ$",
  FKP: "FK£",
  FRF: "₣",
  GBP: "£",
  GEL: "ლ",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "GI£",
  GMD: "GMD",
  GNF: "FG",
  GRD: "₯",
  GTQ: "GTQ",
  GYD: "GY$",
  HKD: "HK$",
  HNL: "HNL",
  HRK: "kn",
  HTG: "HTG",
  HUF: "Ft",
  IDR: "Rp",
  IEP: "IR£",
  ILP: "I£",
  ILS: "₪",
  INR: "Rs",
  IQD: "عد",
  IRR: "﷼",
  ISK: "Ikr",
  ITL: "IT₤",
  JMD: "J$",
  JOD: "JD",
  JPY: "JP¥",
  KES: "Ksh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "KY$",
  KZT: "лв",
  LAK: "₭",
  LBP: "LB£",
  LKR: "SLRs",
  LRD: "L$",
  LSL: "LSL",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "DH",
  MDL: "L",
  MGA: "Ar",
  MKD: "ден",
  MMK: "MMK",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MTL: "Lm",
  MTP: "MT£",
  MUR: "MURs",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MXP: "MX$",
  MYR: "RM",
  MZM: "Mt",
  MZN: "MTn",
  NAD: "N$",
  NGN: "₦",
  NIO: "C$",
  NLG: "fl",
  NOK: "Nkr",
  NPR: "NPRs",
  NZD: "NZ$",
  OMR: "﷼",
  PAB: "B",
  PEI: "I",
  PEN: "S",
  PGK: "PGK",
  PHP: "₱",
  PKR: "PKRs",
  PLN: "zł",
  PTE: "Esc",
  PYG: "₲",
  QAR: "QR",
  RHD: "RH$",
  RON: "RON",
  RSD: "din",
  RUB: "₽",
  RWF: "FRw",
  SAR: "SR",
  SBD: "SI$",
  SCR: "SRe",
  SDD: "LSd",
  SDG: "SD",
  SEK: "Skr",
  SGD: "S$",
  SHP: "SH£",
  SKK: "Sk",
  SLL: "Le",
  SOS: "Ssh",
  SRD: "SR$",
  SRG: "Sf",
  SSP: "£",
  STD: "Db",
  SVC: "SV₡",
  SYP: "SY£",
  SZL: "SZL",
  THB: "฿",
  TJS: "ЅM",
  TMM: "TMM",
  TND: "DT",
  TOP: "T$",
  TRL: "TRL",
  TRY: "TL",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "US$",
  UYU: "$U",
  UZS: "лв",
  VEF: "Bs",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XCD: "EC$",
  XOF: "CFA",
  XPF: "CFPF",
  YER: "YR",
  ZAR: "R",
  ZMK: "ZK",
  ZRN: "NZ",
  ZRZ: "ZRZ",
  ZWD: "Z$",
};

export const HttpCodes = {
  SUCCESS: 200,
};

export const InstanceStatus = {
  SUSPENDED_UNPAID: "suspended_unpaid",
  SUSPENDED_INACTIVE: "suspended_inactive",
};

angular
  .module("core.app.constants", [])
  .constant("AppConfig", AppConfig)
  .constant("AppFeature", AppFeature)
  .constant("AppModule", AppModule)
  .constant("AppLoader", AppLoader)
  .constant("UserType", UserType)
  .constant("UserThemes", UserThemes)
  .constant("SmartCampaignConstants", SmartCampaignConstants)
  .constant("ColumnFormat", ColumnFormat)
  .constant("PostProcessType", PostProcessType)
  .constant("EditControlType", EditControlType)
  .constant("ColumnValueType", ColumnValueType)
  .constant("FilterParam", FilterParam)
  .constant("DataSourceType", DataSourceType)
  .constant("CurrencyConstants", CurrencyConstants)
  .constant("DataView", DataView)
  .constant("HttpCodes", HttpCodes)
  .constant("InstanceStatus", InstanceStatus);
