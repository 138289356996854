(function() {
	"use strict";

	//Safe store will check if storing busts localStorage limit then try In-Cache memory
	if (typeof amplify !== 'undefined') {
		amplify.safeStore = function (key, data, options) {
			if (options == null)
				options = {};

			options.expires = options.expires == null ? 1000 * 60 * 60 * 24 /* One day */ : options.expires;
			options.expires = !options.expires ? 1000 * 60 * 60 * 24 * 30 * 12 /* One year */ : options.expires;

			try {
				amplify.store(key, data, options);
			}
			catch (err) {
				console.log('localStorage is full');

				try {
					amplify.store.memory(key, data);
				}
				catch (err) {
					console.log('In-Memory cache is full');
				}
			}
		};

		//Check if data is in localStorage (5Mb) or In-Memory cache (>40Mb depending on machine memory, and data only lasts until page refresh)
		amplify.lookupCache = function (key) {
			return amplify.store(key) || amplify.store.memory(key);
		};

		//Try to fetch data from localStorage or In-Memory cache
		amplify.getFromCache = function (key) {
			if (amplify.store(key)) {
				return amplify.store(key);
			}
			else if (amplify.store.memory(key)) {
				return amplify.store.memory(key);
			}
			else {
				return false;
			}
		};

		//Try to remove key/value from localStorage or In-Memory cache
		amplify.removeFromCache = function (key) {
			if (amplify.store(key)) {
				amplify.store(key, null);
			}
			else if (amplify.store.memory(key)) {
				amplify.store.memory(key, null);
			}
		};
	}
})();