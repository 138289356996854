import { useBubbleMaps } from '@/modules/core/charts/am5/geo/composables/useBubbleMap';
import { useHeatMaps } from '@/modules/core/charts/am5/geo/composables/useHeatMap';
import {
  BUBBLE_V2,
  BUBBLE_WORLD_MAP,
  HEAT_V2,
  WORLD,
  WORLD_MAP,
} from '@/modules/core/charts/am5/geo/geo.charts.constants';

export function useGeoSeries(context) {
  const { config } = context();
  const { init, loadCountryMap } = useBubbleMaps(context);
  const { initHeatMap } = useHeatMaps(context);

  function createAllSeries() {
    const mapId = config.value.widgetData.metadata.map_id;
    if (config.value.plotType === BUBBLE_V2) {
      if (mapId && mapId !== WORLD) {
        loadCountryMap(mapId, BUBBLE_WORLD_MAP);
      } else {
        init(WORLD_MAP, true);
      }
    } else if (config.value.plotType === HEAT_V2) {
      initHeatMap(mapId, !mapId || mapId === WORLD);
    }
  }

  return {
    createAllSeries,
  };
}
