'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('scheduledreport.ctrls', [])

    .controller('ScheduledReportListController', ScheduledReportListController)
    .controller('ScheduledReportController', ScheduledReportController);

//
// Scheduled report grid controller
//
ScheduledReportListController.$inject = ['$scope', '$timeout', 'ScheduledReportFactory', 'AppFactory'];
function ScheduledReportListController($scope, $timeout, ScheduledReportFactory, AppFactory) {
    var options = $.core.datatable.defaultOptions('report');
    options.iActions = 1;
    $timeout(function () {
        $.core.datatable.build($('#report-table'), options);
    }, false);

    $.core.main.initDatePicker($('#scheduled_report_date'), {dateFormat: 'yy-mm-dd', firstDay: AppFactory.getUser().weekStartsOnMonday ? 1 : 0});
}

//
// Scheduled report controller
//
ScheduledReportController.$inject = ['$scope', 'ScheduledReportFactory', 'AppFactory'];
function ScheduledReportController($scope, ScheduledReportFactory, AppFactory) {
    const firstDayOfWeek = AppFactory.getUser().weekStartsOnMonday ? 1 : 0;
    $.core.reporting.scheduledReporting.initialize(firstDayOfWeek);
}