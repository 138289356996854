export const mutations = {
  /**
   * @param state
   * @param {boolean} value
   */
  togglePanel(state, value) {
    state.isShowingPanel = value;
  },
  /**
   * @param state
   */
  addQueryNotice(state) {
    state.numQueryNotices += 1;
  },

  /**
   *
   * @param state
   * @param value
   */
  addRequests(state, value) {
    state.requests.push(value);
  },

  /**
   *
   * @param state
   * @param value
   */
  addResponses(state, value) {
    state.responses.push(value);
  },

  /**
   *
   * @param state
   * @param value
   */
  addErrors(state, value) {
    state.errors.push(value);
  },

  /**
   * @param state
   */
  resetQueryNoticeCount(state) {
    state.numQueryNotices = 0;
  },
};
