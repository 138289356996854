'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.filter.services', [])
    .factory('WidgetFilterFactory', WidgetFilterFactory);

/**
 * @ngInject
 */
function WidgetFilterFactory(
    Restangular,
    $WidgetFilterEvents,
    ScopeFactory,
    LayoutFactory,
    WidgetType,
    AppFactory
) {
    var dash = Restangular.all('dash');
    var widgets = dash.all('widgets');

    function getAttributeColumns(columns) {
        return _.filter(columns, function(column) {
            return !column.is_metric;
        });
    }

    function getMetricColumns(columns) {
        return _.filter(columns, function(column) {
            return column.is_metric;
        });
    }

    /**
     **.html
     * @param model
     * @returns {*}
     */
    var updateFilterSet = function(model) {
        return widgets.one('updatefilterset').all(model.widget_id).post({filter_set: model});
    };

    /**
     *
     * @param model
     * @returns {*}
     */
    var removeFilterSet = function(model) {
        return widgets.one('removefilterset').all(model.widget_id).post();
    };

    /**
     *
     * @param model
     * @returns {*}
     */
    var copyFilterSet = function(model) {
        return widgets.one('copyfilterset').all(model.widget_id).post({filter_set_id: model.id});
    };

    var $filterWidgetScope;
    var $filterColumnScope;
    var $filterWidgetDropZoneScopes = {};

    function $registerFilterWidgetScope(scope) {
        $filterWidgetScope = ScopeFactory.$registerScope(scope, 'filter-widget-data-modal');
    }

    function $registerFilterColumnScope(scope) {
        $filterColumnScope = ScopeFactory.$registerScope(scope, 'widget-filterby-select');
    }

    function $registerFilterDropZoneScope(scope, id) {
        $filterWidgetDropZoneScopes[id] = ScopeFactory.$registerScope(scope, 'filter-drop-zone-' + id);
    }

    /**
     * Proxy for filtering a widget's data
     * @param state
     * @param widget
     */
    function $init(state, widget) {
        angular.element('#filter-widget-modal').modal('show');
        $filterWidgetScope.initEdit(state, widget);
    }

    /**
     * Proxy that handles removing a widget filter column
     * @param column
     */
    function $removeFilteredColumn(column) {
        $filterColumnScope.triggerRemoveColumn(column);
    }

    /**
     * Proxy for updating the filter description summary
     */
    function $updateFilterDescriptionTooltips() {
        $filterWidgetScope.$broadcast($WidgetFilterEvents.SET_TOOLTIP_VALUES);
    }

    /**
     * Proxy for toggle a widget's filter drop zone
     * @param widgetId
     */
    function $triggerDropZone(widgetId) {
        $filterWidgetDropZoneScopes[widgetId].triggerDropZone();
    }

    /**
     * Proxy for applying a dragged filter onto a widget
     * @param widgetId
     */
    function $applyDroppedFilter(widgetId) {
        $filterWidgetDropZoneScopes[widgetId].applyDroppedFilter();
    }

    /**
     * Proxy for toggling the filter drop zones based on which widget is being hovered over
     */
    function $toggleDropZones(widget) {
        LayoutFactory.$getScope().$broadcast($WidgetFilterEvents.TOGGLE_DROP_ZONES, widget);
    }

    /**
     * Proxy for resetting the filter dropzones
     */
    function $resetDropZones() {
        LayoutFactory.$getScope().$broadcast($WidgetFilterEvents.RESET_DROP_ZONES);
    }

    function isWidgetFilterable(widget) {
        return widget.type !== WidgetType.MEDIA && widget.type !== WidgetType.ACCOUNTMANAGER;
    }

    function isNumericFilter(item) {
        return  AppFactory.util.column.isNumeric(item.format)
    }

    function isDateFilter(item) {
        return  AppFactory.util.column.isDate(item.format)
    }

    function isTextFilter(item) {
        return  AppFactory.util.column.isText(item.format)
    }

    function isBooleanFilter(item) {
        return  AppFactory.util.column.isBoolean(item.format)
    }

    return {
        getAttributeColumns: getAttributeColumns,
        getMetricColumns: getMetricColumns,
        updateFilterSet: updateFilterSet,
        removeFilterSet: removeFilterSet,
        copyFilterSet: copyFilterSet,
        $registerFilterWidgetScope: $registerFilterWidgetScope,
        $registerFilterColumnScope: $registerFilterColumnScope,
        $registerFilterDropZoneScope: $registerFilterDropZoneScope,
        $init: $init,
        $removeFilteredColumn: $removeFilteredColumn,
        $updateFilterDescriptionTooltips: $updateFilterDescriptionTooltips,
        $triggerDropZone: $triggerDropZone,
        $applyDroppedFilter: $applyDroppedFilter,
        $toggleDropZones: $toggleDropZones,
        $resetDropZones: $resetDropZones,
        isWidgetFilterable: isWidgetFilterable,
        isNumericFilter: isNumericFilter,
        isDateFilter: isDateFilter,
        isTextFilter: isTextFilter,
        isBooleanFilter: isBooleanFilter
    }
}