'use strict';
import angular from 'angular';
import $ from 'jquery';
import initAngularTemplatedDirectives from './app.services';

angular.module('core.app.ctrls', [])

    .controller('AppController', AppController)
    .controller('NavController', NavController)
    .controller('OnboardingController', OnboardingController)
    .controller('MainModalsController', MainModalsController)
    .controller('DashFiltersController', DashFiltersController)
    .controller('RightFrameController', RightFrameController)
    .controller('FooterController', FooterController);

/**
 * @ngInject
 */
function AppController(
    $rootScope,
    $templateCache,
    $scope,
    $timeout,
    UIFactory,
    TapColorsService,
    NavMainFactory,
    AppFactory,
    DateRangeFactory,
    DashboardFilterPanelFactory,
    SlidePanelFactory,
    UserType,
    DesignFactory,

    // values below come from 'resolve' in router
    navItems,
    daterange,
    dataTypes,
    services,
    relativeDateRanges,
    themeColors,
    themeBaseColors,
    brandMappings,
    dataSourceTypes
) {
    $scope.showFeedback = showFeedback;

    //Legacy support
    $timeout(function(){
        $.core.main.initialize();
    }, 250, false);

    // Has content done loading onto a page
    $rootScope.contentLoaded = true;

    // Post form callback redirection option
    $rootScope.redirector = {};
    $rootScope.redirector.param = {};
    $rootScope.redirector.show = false;

    $scope.user = $rootScope.user;

    // Set the resolved items to be exposed to the rest of the app
    // NOTE: this is resolved at the app level so that it beats any other race condition of other promises
    NavMainFactory.setNavItems(navItems);
    AppFactory.setServices(services.plain());
    AppFactory.setDataTypes(dataTypes.plain());
    AppFactory.setDataSourceTypes(dataSourceTypes.plain());
    DateRangeFactory.setRelativeDateRanges(relativeDateRanges.plain());
    UIFactory.setThemeColors(themeColors.plain());
    UIFactory.setThemeBaseColors(themeBaseColors.plain());
    TapColorsService.setThemeColors(themeBaseColors.plain());

    AppFactory.setBrandMappings(brandMappings);
    UserType['AGENT_DISPLAY'] = brandMappings.agent;
    UserType['CLIENT_DISPLAY'] = brandMappings.client_type;

    // Set daterange from session backend
    AppFactory.setDateRange(daterange.start, daterange.end, daterange.relative_date_range);
    AppFactory.setComparisonDateRange(daterange.comparison_start, daterange.comparison_end, daterange.comparison_active);

    // Make sure all templated directives are loaded
    initAngularTemplatedDirectives($templateCache);

    // Reset any factory specific setting
    DashboardFilterPanelFactory.resetFilterOptionList();

    $scope.getLayoutClasses = function () {
        var classes = [];
        classes = classes.concat(_getSlidePanelClassesIfActive());
        return classes;
    };

    function _getSlidePanelClassesIfActive () {
        if (!SlidePanelFactory.getIsActive()) {
            return [];
        }
        var classes = ['slide-panel-active'];
        classes.push(SlidePanelFactory.getSpaceClassName());
        return classes;
    }

    function showFeedback() {
        return $rootScope.user.type === UserType.SUPERADMIN && $rootScope.user.feedbackActive
    }
}

/**
 * @ngInject
 */
function NavController(
    $scope,
    PubSub,
    UIFactory,
    LoginFactory,
    $PageLibraryEvents,
    UserIntegrationResource
) {
    $scope.triggerImpersonationModal = function() {
        UIFactory.showModal('user-impersonation-modal');
    };

    $scope.triggerImageUploadModal = function() {
        UIFactory.showModal('image-upload-modal');
    };

    $scope.triggerChangeLanguageModal = function() {
        UIFactory.showModal('change-language-modal');
    };

    $scope.triggerUnimpersonation = function() {
        LoginFactory.unimpersonateUser();
    };

    $scope.isUserIntegrated = function () {
        return UserIntegrationResource.isUserIntegrated();
    };

    $scope.logout = function() {
        LoginFactory.logout();
    };

    $scope.toggleDevToolsPanel = function() {
        var scope = angular.element('#devtools-panel').scope();
        scope.isShowing = true;
    };

    $scope.showPageLibraryModal = function() {
        PubSub.emit($PageLibraryEvents.INIT_MODAL);
    };

}

/**
 * Onboarding controller
 * @ngInject
 */
function OnboardingController(UserFactory) {
    $.core.main.initOnboarding();
    UserFactory.updateOnboardingStep();
}

/**
 * Main modals controller
 *
 * @ngInject
 */
function MainModalsController($scope) {
}

//
//
/**
 * Dash filters controller
 *
 * @ngInject
 */
function DashFiltersController($scope) {
    $.core.main.toggleDashFilters();
    $.core.main.tooltip($('#dash-filters'));
}

/**
 *
 */
function RightFrameController(
    $scope,
    PubSub,
    $timeout,
    $LayoutEvents
) {
    this.$onInit = $onInit;
    this.$onDestroy = $onDestroy;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        PubSub.off($LayoutEvents.CHANGED, _scrollTop);
    }

    function _registerEvents() {
        PubSub.on($LayoutEvents.CHANGED, _scrollTop);
    }

    function _scrollTop() {
        $scope.scrollTop();
    }
}


/**
 * Footer controller
 *
 * @ngInject
 */
function FooterController($rootScope, $timeout, gettextCatalog) {
    $rootScope.footer = {};
    $rootScope.footer.saveButtonText = gettextCatalog.getString('Save changes');
    $rootScope.footer.cancelButtonText = gettextCatalog.getString('Cancel');

    $timeout(function() {
        //Intercept any away navigation if user has started to fill out form
        $.core.main.formNavAway();
        //Sets up bindings for form submit events
        $.core.main.initFooter();
    });
}

