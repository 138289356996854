'use strict';
import angular from 'angular';

angular.module('core.endcustomer.constants', [])

    .constant('EndCustomerColumns', {
        ID: 'id',
        CLIENT_ID: 'client_id',
        CLIENT_NAME: 'client_name',
        CUSTOMER_NAME: 'customer_name',
        CUSTOMER_NUMBER: 'customer_number',
        CUSTOMER_EMAIL: 'customer_email',
        CUSTOMER_ADDRESS: 'customer_address',
        CUSTOMER_CITY: 'customer_city',
        CUSTOMER_STATE: 'customer_state',
        CUSTOMER_ZIP: 'customer_zip',
        CUSTOMER_COUNTRY: 'customer_country',
    });