var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle-container",attrs:{"data-cy":"circle-container"}},[_c('section',{class:{ 'is-comparing': _vm.hasComparison }},[(_vm.isLoadingValue)?_c('div',[_c('p',{staticClass:"subtitle",attrs:{"data-cy":"loader"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Loading...')))])])]):_c('div',[_c('div',{staticClass:"value-container",style:([_vm.containerStyles, _vm.calculatedSize, _vm.borderStyles, _vm.radiantStyles]),attrs:{"data-cy":"value-container","title":_vm.emptyDataMessage}},[_c('p',{staticClass:"title",style:({ 'max-height': _vm.maxHeight })},[_c('span',{style:([
              _vm.fontStyles,
              _vm.getFontSizeStyle(_vm.DataFontMultiplier.MIN, _vm.DataFontMultiplier.MAX),
            ]),attrs:{"data-cy":"value"}},[_c('b-tooltip',{attrs:{"active":!!_vm.isFormattedEllipsisActive(),"label":_vm.isFormattedEllipsisActive(),"type":"is-dark","size":"is-small"}},[_c('div',{ref:"formattedTooltip",staticClass:"overflow-value"},[_vm._v(" "+_vm._s(_vm.formattedValue)+" ")])])],1)]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasComparison),expression:"hasComparison"}],staticClass:"title",class:_vm.comparisonValueClasses,style:({ 'max-height': _vm.maxHeightForComparison })},[_c('span',{staticClass:"comparison-value",style:([
              _vm.fontColorStyles,
              _vm.getFontSizeStyle(_vm.DeltaFontMultiplier.MIN, _vm.DeltaFontMultiplier.MAX),
            ]),attrs:{"data-cy":"comparison-value"}},[_vm._v(" "+_vm._s(_vm.formattedComparisonValue)+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasComparison),expression:"hasComparison"}],staticClass:"comparison-container m-t-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasComparison && _vm.showComparisonArrows),expression:"hasComparison && showComparisonArrows"}],staticClass:"delta-value",class:_vm.deltaValueClasses,style:(_vm.getFontSizeStyle(_vm.DeltaIconFontMultiplier.MIN, _vm.DeltaIconFontMultiplier.MAX))},[(_vm.isExportingReportStudio)?_c('svg',{class:_vm.deltaIconClass,style:(_vm.getIconSizeStyle(_vm.DeltaIconFontMultiplier.MIN, _vm.DeltaIconFontMultiplier.MAX))},[_c('use',{attrs:{"xlink:href":"","href":`#${_vm.deltaIconClass}`}})]):_c('b-icon',{staticClass:"m-r-4",attrs:{"pack":"fa","custom-class":_vm.iconClass,"data-cy":"delta-icon"}}),_c('span',{attrs:{"data-cy":"delta-value"}},[_vm._v(_vm._s(_vm.formattedDeltaValue))]),(!_vm.compareAsValue)?_c('span',[_vm._v("%")]):_vm._e()],1)])])]),_c('p',{staticClass:"subtitle m-b-0",class:_vm.showMetricName,style:([
      _vm.getFontSizeStyle(_vm.LabelFontMultiplier.MIN, _vm.LabelFontMultiplier.MAX),
      _vm.wrapMetricNameStyle,
    ]),attrs:{"data-cy":"label"}},[_vm._v(" "+_vm._s(_vm.label)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }