'use strict';

angular.module('page.library.constants', [])

    .constant('$PageLibraryEvents', {
        INIT_MODAL: '$PageLibraryEvents:INIT_MODAL',
        INIT_PUBLISH_SELECTION_MODAL: '$PageLibraryEvents:INIT_PUBLISH_SELECTION_MODAL',
        INIT_PUBLISH_MODAL: '$PageLibraryEvents:INIT_PUBLISH_MODAL',
        SET_ITEM_DETAILS: '$PageLibraryEvents:SET_ITEM_DETAILS',
        CLEAR_ALL_ITEMS: '$PageLibraryEvents:CLEAR_ALL_ITEMS',
    })
    .constant('PageLibraryColumns', {
        ID: 'id',
        LAYOUTS: 'layouts',
        TAGS: 'tags',
        CREATED_AT: 'created_at',
        FORMATTED_CREATED_AT: 'formatted_created_at',
        DATA_SOURCES: 'data_sources'
    });