export const mutations = {
  /**
   * @param state
   * @param callbackRoute
   */
  pushCallbackRoute(state, callbackRoute) {
    state.callbackRoutes.push(callbackRoute);
    state.isCallbackEnabled = true;
  },

  /**
   * @param state
   * @param isCallbackEnabled
   */
  setIsCallbackEnabled(state, isCallbackEnabled) {
    state.isCallbackEnabled = isCallbackEnabled;
  },

  /**
   * @param state
   */
  resetCallbackRoutes(state) {
    state.callbackRoutes = [];
  },

  /**
   * @param state
   */
  popCallbackRoute(state) {
    state.callbackRoutes.pop();
  },
};
