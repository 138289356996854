import angular from 'angular';

export default angular.module('pusher.constants', []).constant('PusherConstants', {
  PUSHER_JOB_LOADING: 'loading',
  PUSHER_JOB_SUCCESS: 'success',
  PUSHER_JOB_ERROR: 'error',
  PUSHER_POLL_INTERVAL: 10000, // 10 secs
  PUSHER_POLL_INTERVAL_SHORT: 3000, // 3 secs
  PUSHER_MAX_POLL_COUNT: 10,
  PUSHER_SUBSCRIPTION_EVENT: 'pusher:subscription_succeeded',
  PUSHER_SUBSCRIPTION_TIMEOUT: 2000 // 2 seconds
});
