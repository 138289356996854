import { ChartWidgetDataService } from '@/modules/ta/widget/services/data/ChartWidgetDataService';

export class GaugeChartDataService extends ChartWidgetDataService {
  /**
   * @override
   *
   * @param widget
   * @param config
   */
  applySpecificQueryParams(widget, config) {
    widget.metadata.data_columns.grouped = [];
    config.params.aggregate = true;
  }
}
