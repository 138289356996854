'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('clientgroup.services', [])
    .factory('ClientGroupFactory', ClientGroupFactory);

ClientGroupFactory.$inject = ['Restangular', 'ColumnValueType'];
function ClientGroupFactory(Restangular, ColumnValueType) {
    var clientGroups = Restangular.all('clientgroups');
    var values = clientGroups.one('values');

    return {
        clientGroups: clientGroups,
        getFieldValues: getFieldValues
    };

    /**
     * Get values for all possible distinct specific field
     * @param fields
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, queryParams) {
        return values.one(ColumnValueType.DISTINCT)
            .get(_.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));

    }
}