import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class GenericDataModel extends BaseModel {
  /**
   * Always be defined
   * @var {Object}
   */
  current_period;

  /**
   * Optional property, only defined when comparing data
   * @var {Object}
   */
  prior_period;

  /**
   * Primary key field of the data set
   */
  key;

  constructor(model = {}) {
    super(model);

    if (model.current_period !== undefined) {
      this.current_period = model.current_period;
      this.prior_period = model.prior_period;
    } else {
      this.current_period = model;
      this.prior_period = {};
    }
  }

  /**
   * @override
   *
   * @param model
   * @returns {GenericDataModel}
   */
  static factory(model = {}) {
    return new this(model);
  }

  /**
   * Sets primary key/value field to data set
   * @param key
   */
  setPrimaryKey(key) {
    if (key in this.current_period) {
      this._setKeyValue(key, this.current_period[key]);
    } else if (this.prior_period && key in this.prior_period) {
      this._setKeyValue(key, this.prior_period[key]);
    }
  }

  _setKeyValue(key, value) {
    this.key = key;
    this[key] = value;
  }
}
