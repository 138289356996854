import Enum from '@/modules/core/app/models/abstracts/Enum';

export class ClientReportingStatus extends Enum {
  static PENDING_MAPPING = 'pending_mapping';

  static ACTIVE = 'active';

  static HOLD = 'hold';

  static TERMINATED = 'terminated';
}

export const ClientRoute = {
  CLIENTS: 'clients',
};

export const ClientPathName = {
  CLIENTS: 'clients',
  CLIENTFORM_NEW: 'clientform:new',
  CLIENTFORM: 'clientform',
  CLIENTS_TAB: 'clients.tabname',
  CLIENTOVERVIEW: 'clientoverview',
};

export const ReportingStatusField = 'reporting_status';
export const IOToolStatusField = 'iotool_status';

export const ClientFormTabs = {
  SETTINGS: 'settings',
  INFO: 'info',
  BRANDING: 'branding',
  USERS: 'users',
  CALCULATION: 'calculation',
};

export const ClientFields = {
  ID: 'id',
  NAME: 'name',
};

export const DemoClientCompanyName = 'Demo Advertiser Client';
