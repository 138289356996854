import BubbleChartWidgetRenderService from '@/modules/ta/widget/services/types/BubbleChartWidgetRenderService';

export class LegacyBubbleChartWidgetRenderService extends BubbleChartWidgetRenderService {
  /**
   * @var Widget
   */
  _widget;

  /**
   * @var array
   */
  _chartPalette;

  /**
   * @var boolean
   */
  _hasComparisonData;

  /**
   * @var string
   */
  _dateRange;

  /**
   * @var string
   */
  _comparisonDateRange;

  /**
   * @var string
   */
  _comparisonColor;

  /**
   * @var boolean
   */
  _isExporting;

  constructor(
    widget,
    chartPalette = [],
    hasComparisonData = false,
    comparisonBubbleColor = '',
    isExporting = false
  ) {
    super(widget.id);
    this._widget = widget;
    this._chartPalette = widget.metadata.chart_palette ?? chartPalette;
    this._hasComparisonData = hasComparisonData;
    this._comparisonColor = comparisonBubbleColor;
    this._isExporting = isExporting;
  }

  getWidget() {
    return this._widget;
  }

  isExporting() {
    return this._isExporting;
  }

  getFullChartPalette() {
    return this._chartPalette;
  }

  /**
   * @param columnIndex
   * @returns {string}
   */
  getChartPalette(columnIndex) {
    return this._chartPalette[columnIndex % this._chartPalette.length];
  }

  getComparisonBubbleColor() {
    return this._comparisonColor;
  }

  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
    };
  }

  hasComparison() {
    return this._hasComparisonData;
  }
}
