'use strict';
import angular from 'angular';
import './ordersreport.services';
import './lineitemsreport.services';
import './flightsreport.services';
import './billingreport.services';
import './ordertasksreport.services';
import './lineitemtasksreport.services';
import './flighttasksreport.services';
import './orderswithtasksreport.services';
import './lineitemswithtasksreport.services';
import './flightswithtasksreport.services';

angular.module('core.ioreporting', [
    'ioreporting.ordersreport.services',
    'ioreporting.lineitemsreport.services',
    'ioreporting.flightsreport.services',
    'ioreporting.billingreport.services',
    'ioreporting.ordertasksreport.services',
    'ioreporting.lineitemtasksreport.services',
    'ioreporting.flighttasksreport.services',
    'ioreporting.ordertswithasksreport.services',
    'ioreporting.lineitemswithtasksreport.services',
    'ioreporting.flightswithtasksreport.services'
]);
