import angular from 'angular';
import _ from 'lodash';

angular.module('core.smartcampaign.services', [])
    .factory('SmartCampaignDetailsModalService', SmartCampaignDetailsModalService)
    .factory('SmartCampaignService', SmartCampaignService);

/**
 * @ngInject
 */
function SmartCampaignDetailsModalService(
    $q,
    AppFactory,
    DetailsModalFactory,
    DataSourceType,
    DetailsModalModelFactory,
    SmartCampaignResource
) {
    let services;

    return {
        setNewDetails: setNewDetails,
        setEditDetails: setEditDetails,
        getDisplayTitle: getDisplayTitle,
        setServices: setServices,
        getServices: getServices
    };

    /**
     * Set ups detail modal as new data entity
     * @returns {*}
     */
    function setNewDetails() {
        DetailsModalFactory.setAsNew(true);
        return _setDetails([], 'New Smart Campaign');
    }

    /**
     * Sets up detail modal with data entity
     * @param data
     * @param row
     */
    function setEditDetails(data, row) {
        let promises = [
            SmartCampaignResource.get(data.id)
        ];
        return _setDetails(promises, getDisplayTitle(data), row);
    }

    /**
     * Creates title from data model
     * @param data
     * @returns {string}
     */
    function getDisplayTitle(data) {
        let html = '';
        html += '<div class="service-square service-square-24" style="background-color:'+ data.color +'">' +
            '<div class="icon '+ data.icon +'">' +
            '</div>' +
            '</div>' +
            '<span class="ml5">' + data.name + '</span>';

        let services = data.source_type === DataSourceType.SERVICE_DATA ? AppFactory.getConnectedServices() : AppFactory.getConnectedCategories();
        let service = _.find(services, function (service) {
            return service.id === data.data_source_id;
        });
        if (service) {
            html += ' - ' + service.name
        }
        return html;
    }

    /**
     *
     * @param model
     */
    function setServices(model) {
        services = model;
    }

    /**
     *
     */
    function getServices() {
        return services;
    }

    /**
     * Sets up details
     * @param promises
     * @param title
     * @param row
     * @private
     */
    function _setDetails(promises, title, row) {
        return DetailsModalFactory.setDetails(DetailsModalModelFactory.getModalOptions({
            dataSourceType: DataSourceType.SMART_CAMPAIGN,
            promises: promises,
            resource: SmartCampaignResource.getResource(),
            display: {
                title: title,
                loadingMessage: 'Loading Smart Campaign',
                icon: 'icomoon-data-widget',
                hideTitleIcon: true
            },
            row: row,
            hideRowButtons: true
        }));
    }
}

/**
 *
 * @ngInject
 */
function SmartCampaignService(
    $q,
    AppFactory,
    DesignFactory,
    DataSourceType,
    SmartCampaignResource
) {
    let smartCampaigns;

    return {
        setSmartCampaigns: setSmartCampaigns,
        setSmartCampaignsFromResource: setSmartCampaignsFromResource,
        getSmartCampaigns: getSmartCampaigns,
        getSmartCampaign: getSmartCampaign,
        retrievePreviewCampaignDataGridWidget: retrievePreviewCampaignDataGridWidget,
        assignModelValues: assignModelValues,
        refreshPrimaryName: refreshPrimaryName
    };

    /**
     * Sets the services let to be exposed externally
     * @param values
     * @returns {*}
     */
    function setSmartCampaigns(values) {
        smartCampaigns = AppFactory.arrayToMemoizedObj(values, 'id');
    }


    function setSmartCampaignsFromResource() {
        SmartCampaignResource.getAll().then(function(json) {
            setSmartCampaigns(json);
        });
    }

    /**
     * Allows read only access to the smartCampaigns
     * @returns {{}}
     */
    function getSmartCampaigns() {
        return _.assign({}, smartCampaigns);
    }

    /**
     * Get a specific service by id
     * @param id
     * @returns {T|*}
     */
    function getSmartCampaign(id) {
        return _.assign({}, smartCampaigns[id]);
    }

    /**
     * Retrieves a datagrid widget
     * @param smartCampaign
     * @returns {Null|Object}
     */
    function retrievePreviewCampaignDataGridWidget(smartCampaign) {
        let campaignParams = {
            datatable: '1',
            limit: '1000'
        };

        let datagridDataPromise = SmartCampaignResource.getCampaigns(smartCampaign, campaignParams);
        let datagridWidgetPromise = SmartCampaignResource.getPreviewDataGrid(smartCampaign);

        let promise = $q.all([
            datagridDataPromise,
            datagridWidgetPromise
        ])
            .then(function (data) {
                let datagridData = data[0];
                let datagridWidget = data[1];

                if (!datagridData.aaData.length) {
                    return null;
                }

                datagridWidget.id = 'campaign-preview-list';
                datagridWidget.metadata.dynamic.predefined_data = {data: datagridData};
                return datagridWidget;
            });

        return promise
    }

    /**
     *
     * @param model
     * @param smartCampaign
     * @throws
     */
    function assignModelValues(model, smartCampaign) {

        _.assign(
            model,
            _.omit(smartCampaign, ['name', 'icon', 'color'])
        ); // name , colors icon are handled by abstracted DetailsModals

        if (!model.dynamic_mapping && !model.mapping.length) {
            throw new Error('No campaigns selected');
            return;
        }

        if (_.isEmpty(model.icon)) {
            model.icon = 'icon-signal';
        }

        model.mapping = !smartCampaign.dynamic_mapping
            ? JSON.stringify(_.map(smartCampaign.mapping, function (item) {
                return _.pick(item, ['customer_id', 'name']);
            }))
            : JSON.stringify([]);

        return model;
    }

    /**
     * Retrieves the name primary name for smart campaign
     */
    function refreshPrimaryName(smartCampaign) {
        return SmartCampaignResource.getPrimaryName(smartCampaign)
            .then(function (primaryName) {
                return smartCampaign.primary_name = primaryName;
            });
    }
}