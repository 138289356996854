import { ChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/ChartWidgetMetadata';
import { LineChartDrawOption } from '@/modules/ta/widget/models/drawoptions/LineChartDrawOption';

export class LineChartWidgetMetadata extends ChartWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new LineChartDrawOption(model.draw_options);
  }
}
