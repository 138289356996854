'use strict';
import angular from 'angular';
import _ from "lodash";
import {WidgetType} from "../design.widget.constants";
import {DashboardEventType} from "../../../../../../grok/src/modules/ta/dashboard/dashboard.constants";
import {WidgetProductSliderPanelEvents} from "../../design.constants";

angular.module('product.widget.services', [])
    .factory('ProductWidgetFactory', ProductWidgetFactory)
    .factory('UIProductWidgetFactory', UIProductWidgetFactory);

/**
 * @ngInject
 */
function ProductWidgetFactory(
    PubSub,
    $timeout,
    UIFactory,
    SlidePanelFactory,
    UIProductWidgetFactory,
    DesignFactory,
    ManageProductsService,
    WidgetFactory,
    $PageGenerateThumbnailEvents,
    $WidgetBuilderEvents
) {
    let state = getDefaultState();

    let shared = {};
    let panel = {
        panelId: 'product-widget-panel',
        showOuterElements: true,
        isLargePanel: true
    };
    let widgetData = [];
    let originalWidgetData = [];

    function initPanel(state, widget) {
        setWidgetData(widget);

        SlidePanelFactory.show(panel);
        UIProductWidgetFactory.panelResize();
        PubSub.emit(WidgetProductSliderPanelEvents.OPENED, widget);
    }

    /**
     * Default Widget Product Side Panel State
     * @returns {{isShowing: boolean, isLoading: boolean}}
     */
    function getDefaultState() {
        return {
            isShowing: false,
            isLoading: false,
            isLoadingSelect: false
        }
    }

    /**
     * Hide slidePanel
     */
    function closePanel(widget) {
        widget = widgetData = angular.copy(originalWidgetData);
        SlidePanelFactory.hide(panel);

        UIProductWidgetFactory.panelResize();
    }

    /**
     * Check if more selectOptions need to be loaded
     */
    function getStateProperty(prop) {
        return prop ? state[prop] : Object.freeze(state);
    }

    /**
     * Check if more selectOptions need to be loaded
     */
    function setStateProperty(prop, value) {
        state[prop] = value;
    }

    function setWidgetData(widget) {
        widgetData = widget;
        originalWidgetData = angular.copy(widgetData);
    }

    function getWidgetData() {
        return widgetData;
    }

    function setProductsToWidget(widget, productIds) {
        if (!widget) return;

        if (_.isEmpty(productIds)) {
            widget.metadata.widget_products = {};
        }

        let widgetProducts = _.filter(ManageProductsService.getProducts(), product => {
            if (_.includes(productIds, product.id)) {
                product.id_name = product.name;
                return product
            }
        });
        widget.metadata.widget_products = angular.copy(widgetProducts);
    }

    function saveWidgetProducts(widget) {
        let refreshDashboard = false;
        WidgetFactory.save(angular.copy(widget))
            .then(widget => WidgetFactory.getWidget(widget.id, {all: true}))
            .then(async function(newWidget) {

                // If widget was added/edited, it is safe to assume that it can be modified by the same user
                newWidget.can_be_edited = true;
                newWidget.can_be_deleted = true;
                newWidget.can_be_copied = true;

                if (newWidget.layout_id) {
                    PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: newWidget.layout_id, page: DesignFactory.getCurrentPage() });
                }

                if (!_.isEmpty(newWidget.metadata.widget_products) && _.isEmpty(DesignFactory.getCurrentPage().metadata.requires_refresh_on_date_range_change)) {
                    let updatedPage = DesignFactory.getCurrentPage();
                    updatedPage.metadata.requires_refresh_on_date_range_change = true;
                    DesignFactory.setCurrentPage(updatedPage);
                }

                if (newWidget.metadata.dynamic.is_item_not_found || (newWidget.metadata.dynamic.is_inactive && !newWidget.metadata.product_widget_can_be_accessed)) {
                    refreshDashboard = true;
                } else {
                    PubSub.emit($WidgetBuilderEvents.DID_SAVE_WIDGET, newWidget);

                    if (window.isNUI) {
                        PubSub.emit("SegmentEvents", {
                            event: newWidget.type === WidgetType.ACCOUNTMANAGER ? DashboardEventType.EDIT_ADMIN_WIDGET : newWidget.type === WidgetType.MEDIA ? DashboardEventType.EDIT_MEDIA_WIDGET : DashboardEventType.EDIT_DATA_WIDGET,
                            payload: {widget: newWidget, page: DesignFactory.getCurrentPage()}
                        });
                    }
                }
            })
            .finally(function() {
                UIFactory.notify.showSuccess('Successfully updated widget products list');
                closePanel();
                if (refreshDashboard) {
                    DesignFactory.$rebuildPage();
                }
            });

        widget = [];
    }

    return {
        panel: panel,
        isShowing: false,
        initPanel: initPanel,
        closePanel: closePanel,
        getStateProperty: getStateProperty,
        setStateProperty: setStateProperty,
        setWidgetData: setWidgetData,
        getWidgetData: getWidgetData,
        setProductsToWidget: setProductsToWidget,
        saveWidgetProducts: saveWidgetProducts,
        widget: widgetData,
    };
}

function UIProductWidgetFactory(
    $timeout
) {
    return {
        panelResize: panelResize
    };

    function panelResize() {
        $timeout(function () {
            angular.element('.owl-carousel').trigger('refresh.owl.carousel');
        });
    }
}