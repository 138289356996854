'use strict';
import angular from 'angular';

angular.module('core.date.services', [])

    .service('DateFactory', DateFactory);

/**
 * @ngInject
 */
function DateFactory(Restangular) {
    var enums = Restangular.all('enums');
    var timeGroupings = enums.one('timegroupings');
    var weeklyStartDays = enums.one('weeklystartdays');

    return {
        timeGroupings: timeGroupings,
        weeklyStartDays: weeklyStartDays
    }
}
