'use strict';
import angular from 'angular';
import _ from 'lodash';
import  exportDashheaderHtmlUrl from './exportdashheader.html';

angular.module('core.export.directives')

    .directive('exportDashHeader', exportDashHeader);

/**
 * @ngInject
 */
function exportDashHeader(
    DesignFactory,
    ExportFactory,
    AppFactory
) {
    return {
        restrict: 'E',
        templateUrl: exportDashheaderHtmlUrl,
        controller: function($scope) {
            var comparison = AppFactory.getComparisonDateRange();
            $scope.isComparison = comparison.enabled;
            $scope.comparisonDateRange = comparison.formatted;

            if ($scope.layoutId) {
                var currentLayout = DesignFactory.getLayout($scope.layoutId);
                var showLayoutTitleOption = ExportFactory.getVars().showLayoutTitle;
                $scope.canShowTitle = showLayoutTitleOption ||
                    (_.size(DesignFactory.getCurrentPage().layouts) > 1 && showLayoutTitleOption);
                var pageNumberText = $scope.$index > -1 ? ' Page ' + ($scope.$index + 1) : 'Executive Summary';
                $scope.layoutTitle = !_.isEmpty(currentLayout.title)
                    ? currentLayout.title + ' - ' + pageNumberText
                    : pageNumberText;
            }
        }
    }
}
