'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import dashHtmlUrl from './dash.html';
import dashFiltersHtmlUrl from './dashfilters.html';
import dashdatePickerHtmlUrl from './dashdatepicker.html';
import legacyDashdatePickerHtmlUrl from './legacydashdatepicker.html';
import dashMenuOptionHtmlUrl from './dash.menuoption.html';
import { DashboardEventType } from "../../../../../grok/src/modules/ta/dashboard/dashboard.constants";
var Core = $.core.main;

angular.module('design.directives', [])

    .directive('dashboard', dashboard)
    .directive('dashFilters', dashFilters)
    .directive('dashDatePicker', dashDatePicker)
    .directive('dashMenuOption', dashMenuOption);

/**
 * Helper to include dash.html in other pages (ex: dashio.html)
 * @ngInject
 */
function dashboard() {
    return {
        restrict: 'E',
        templateUrl: dashHtmlUrl
    }
}

/**
 * @ngInject
 */
function dashFilters(
    PageEntity,
    AppFactory,
    WidgetBuilderUIService,
    DashboardFilterPanelFactory
) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            page: '<'
        },
        templateUrl: dashFiltersHtmlUrl,
        controller: function($scope) {
            $scope.toggleDashboardFilterPanel = DashboardFilterPanelFactory.init;
            $scope.excludeDashFilters = function() {
                var user = AppFactory.getUser();
                return $scope.page.entity === PageEntity.PAGE_CLIENT_DETAILS
                    || $scope.page.entity === PageEntity.PAGE_GOALS
                    || $scope.page.entity === PageEntity.PAGE_INDUSTRY_BENCHMARK
                    || $scope.page.entity === PageEntity.PAGE_EMAILS_OVERVIEW
                    || ($scope.page.is_predefined && user.isClient());
            };
            $scope.widgetIsInBuildMode = WidgetBuilderUIService.isActive;
            $scope.isWidgetProductPanelActive = WidgetBuilderUIService.isWidgetProductPanelActive;
        }
    }
}

/**
 * @ngInject
 */
function dashDatePicker(
    $http,
    $state,
    AppConfig,
    MomentDateFormat,
    RelativeDateRange,
    AppFactory,
    DateRangeFactory,
    DesignFactory,
    LayoutFactory,
    ExecutiveSummaryFactory,
    PubSub,
    $DateRangeEvents,
    PageEvents,
    $rootScope,
    AppModule,
) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: !window.isNUI ? legacyDashdatePickerHtmlUrl : dashdatePickerHtmlUrl,
        scope: {
            options: '<'
        },
        controller: function($scope) {
            $scope.RelativeDateRange = RelativeDateRange;
            $scope.isComparing = AppFactory.getComparisonDateRange().enabled;
            $scope.canCompare = !DesignFactory.getCurrentPage().metadata.disable_comparison;
            $scope.canBenchmark = $rootScope.util.isModuleAvailable(AppModule.BENCHMARKS);
            // Refers to the shortcut ranges (7 Days, 30 Days, This Month, Last Month)
            $scope.quickRelativeRange = AppFactory.getCurrentRelativeDateRange();

            $scope.dateRangePickerId = 'dashboard-date-range-picker';
            $scope.compareDateRangePickerId = 'dashboard-compare-date-range-picker';

            $scope.initDatePicker = initDatePicker;
            $scope.initComparisonDatePicker = initComparisonDatePicker;
            $scope.toggleComparison = toggleComparison;
            $scope.applyDateRange = applyDateRange;
            $scope.applyQuickDateRange = applyQuickDateRange;

            /**
             * @param options
             */
            function initDatePicker(options) {
                options = options || {};
                options.dateRangePickerId = $scope.dateRangePickerId;

                DateRangeFactory.setDatePicker(
                    _.assign($scope.dateOptions, options),
                    $scope.comparisonDateOptions,
                    $scope.$dateRangePicker,
                    $scope.$comparisonDateRangePicker,
                    setDateRange
                );
            }

            /**
             * @param options
             */
            function initComparisonDatePicker(options) {
                options = options || {};
                options.compareDateRangePickerId = $scope.compareDateRangePickerId;

                DateRangeFactory.setComparisonDatePicker(
                    $scope.dateOptions,
                    _.assign($scope.comparisonDateOptions, options || {}),
                    $scope.$dateRangePicker,
                    $scope.$comparisonDateRangePicker,
                    setDateRange
                );
            }

            function toggleComparison(e, isSwitch = false) {
                var $datePicker = $scope.$dateRangePicker.data('daterangepicker');
                var $comparisonDatePicker = $scope.$comparisonDateRangePicker.data('daterangepicker');
                if (!isSwitch) {
                    $scope.isComparing = !$scope.isComparing;
                }
                AppFactory.toggleComparisonDateRange($scope.isComparing);

                $scope.dateOptions.startDate = $datePicker.startDate;
                $scope.dateOptions.endDate = $datePicker.endDate;
                initDatePicker();

                var comparisonPeriod = DateRangeFactory.calculatePeriod(
                    $datePicker.startDate,
                    $datePicker.endDate,
                    RelativeDateRange.DEFAULT,
                    RelativeDateRange.PRIOR_PERIOD
                );

                $scope.comparisonDateOptions.startDate = comparisonPeriod.start;
                $scope.comparisonDateOptions.endDate = comparisonPeriod.end;

                DateRangeFactory.setComparisonDatePicker(
                    $scope.dateOptions,
                    $scope.comparisonDateOptions,
                    $scope.$dateRangePicker,
                    $scope.$comparisonDateRangePicker,
                    setDateRange
                );

                var currentDateFormatted = DateRangeFactory.normalizeDate(
                    $datePicker.startDate,
                    $datePicker.endDate
                );

                var comparisonDateFormatted = DateRangeFactory.normalizeDate(
                    $comparisonDatePicker.startDate,
                    $comparisonDatePicker.endDate
                );

                saveDateRange(
                    currentDateFormatted.start,
                    currentDateFormatted.end,
                    comparisonDateFormatted.start,
                    comparisonDateFormatted.end
                );

                AppFactory.setComparisonDateRange(comparisonPeriod.start, comparisonPeriod.end, $scope.isComparing);
                LayoutFactory.$rebuildAllWidgets();
            }

            /**
             * Rebuilds all widgets and sets date on front end and back end
             * @param start
             * @param end
             * @param comparisonStart
             * @param comparisonEnd
             * @param relativeDateRange
             */
            function setDateRange(
                start,
                end,
                comparisonStart,
                comparisonEnd,
                relativeDateRange
            ) {
                if (!moment.unix(start).isValid()
                    || !moment.unix(end).isValid()
                    || (comparisonStart && !moment.unix(comparisonStart).isValid())
                    || (comparisonEnd && !moment.unix(comparisonEnd).isValid())
                ) {
                    console.error('must use unix timestamp for setDateRange');
                    return;
                }
                // Refers to the shortcut ranges (7 Days, 30 Days, This Month, Last Month)
                $scope.quickRelativeRange = relativeDateRange;

                // Set app date range
                AppFactory.setDateRange(start, end, relativeDateRange);
                AppFactory.setComparisonDateRange(comparisonStart, comparisonEnd, $scope.isComparing);

                saveDateRange(start, end, comparisonStart, comparisonEnd, relativeDateRange).then(function() {
                    if (DesignFactory.getCurrentPage().metadata.requires_refresh_on_date_range_change || DesignFactory.widgetsMayBeHidden() || DesignFactory.hasMissingWidgets()) {
                        DesignFactory.$rebuildPage();
                    } else {
                        // Rebuild after date range has been set on the backend
                        LayoutFactory.$rebuildAllWidgets();
                        // TA-15709: Rebuild executive summary after the date range has been set on the backend
                        ExecutiveSummaryFactory.$rebuild(null, true);
                    }
                });

            }

            /**
             * Set session date variables in backend
             * @param start
             * @param end
             * @param comparisonStart
             * @param comparisonEnd
             * @param relativeDateRange
             */
            function saveDateRange(
                start,
                end,
                comparisonStart,
                comparisonEnd,
                relativeDateRange
            ) {
                const response = $http.post(AppConfig.MAGE_URL + 'index/timefilter', {
                    start_time: start.format(MomentDateFormat.ISO),
                    end_time: end.format(MomentDateFormat.ISO),
                    relative_date_range: relativeDateRange || $scope.quickRelativeRange,
                    comparison_start_time: comparisonStart.format(MomentDateFormat.ISO),
                    comparison_end_time: comparisonEnd.format(MomentDateFormat.ISO),
                    comparison_period: null,
                    comparison_active: $scope.isComparing
                });

                const dateRangePayload = {
                    start_date: start.format(MomentDateFormat.MONTH_DAY_YEAR),
                    end_date: end.format(MomentDateFormat.MONTH_DAY_YEAR),
                    is_comparison: $scope.isComparing,
                    previous_start_date: comparisonStart.format(
                        MomentDateFormat.MONTH_DAY_YEAR
                    ),
                    previous_end_date: comparisonEnd.format(
                        MomentDateFormat.MONTH_DAY_YEAR
                    ),
                    relative_date_range:
                        relativeDateRange || $scope.quickRelativeRange
                };

                PubSub.emit("SegmentEvents", {
                    event: DashboardEventType.EDIT_DATE_RANGE,
                    payload: dateRangePayload
                });
                PubSub.emit(PageEvents.PERFORMANCE_TRACKING, {
                    event: $DateRangeEvents.DATE_RANGE_CHANGE,
                    payload: dateRangePayload
                });

                return response;
            }

            /**
             * @param start
             * @param end
             * @param comparisonStart
             * @param comparisonEnd
             * @param relativeDateRange
             */
            function applyDateRange(
                start,
                end,
                comparisonStart,
                comparisonEnd,
                relativeDateRange
            ) {
                if (_.isNull(comparisonStart) || _.isNull(comparisonEnd)) {
                    var period = DateRangeFactory.calculatePeriod(start, end, relativeDateRange, RelativeDateRange.PRIOR_PERIOD);
                    comparisonStart = period.start;
                    comparisonEnd = period.end;
                }

                initDatePicker({startDate: start, endDate: end});
                initComparisonDatePicker({
                    startDate: comparisonStart,
                    endDate: comparisonEnd,
                    ranges: DateRangeFactory.getComparisonDatePickerRelativeRanges(start, end, relativeDateRange)
                });

                setDateRange(start, end, comparisonStart, comparisonEnd, relativeDateRange);
            }

            /**
             * @param quickRelativeRange
             */
            function applyQuickDateRange(quickRelativeRange) {
                $scope.quickRelativeRange = quickRelativeRange;
                var range = DateRangeFactory.getDateRangeFromRelativeRange(quickRelativeRange);
                applyDateRange(range.start, range.end, null, null, quickRelativeRange);
            }
        },
        link: function(scope, el) {
            scope.options = scope.options || {};
            scope.$dateRangePicker = el.find('input.current-range');
            scope.$comparisonDateRangePicker = el.find('input.comparison-range');

            var defaultOptions = DateRangeFactory.getDefaultOptions();
            var comparisonDefaultOptions = DateRangeFactory.getComparisonDefaultOptions();

            scope.dateOptions = _.assign(defaultOptions, scope.options);
            scope.comparisonDateOptions = _.assign(comparisonDefaultOptions, scope.options);

            if (!_.isEmpty($state.params.start_date) && !_.isEmpty($state.params.end_date)) {
                scope.dateOptions.startDate = moment($state.params.start_date);
                scope.dateOptions.endDate =  moment($state.params.end_date);
                scope.applyDateRange(
                    scope.dateOptions.startDate,
                    scope.dateOptions.endDate,
                    null,
                    null,
                    RelativeDateRange.CUSTOM
                );
            }
            scope.initDatePicker();
            scope.initComparisonDatePicker();

            scope.$on('dashDatePicker:applyDateRange', function(e, start, end, comparisonStart, comparisonEnd, relativeDateRange) {
                scope.applyDateRange(start, end, comparisonStart, comparisonEnd, relativeDateRange)
            });

            // Destroy date picker when changing route
            scope.$on('$destroy', function() {
                DateRangeFactory.destroyDatePicker(scope.$dateRangePicker);
                DateRangeFactory.destroyDatePicker(scope.$comparisonDateRangePicker);
            });
        }
    }
}

/**
 * @ngInject
 */
function dashMenuOption($state) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            label: '@',
            icon: '@',
            url: '@',
            sref: '@'
        },
        templateUrl: dashMenuOptionHtmlUrl,
        link: function(scope) {

            scope.resolveLink = function() {
                // Regular href link
                if (_.isUndefined(scope.sref)) {
                    return _.isUndefined(scope.url) ? '' : scope.url;
                }
                else { // UI-router state link
                    return $state.href(scope.sref, {/*Add params if needed*/});
                }
            };
        }
    }
}