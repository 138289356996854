import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BillingDetails } from '@/modules/core/billing/models/BillingDetails';
import { GenericModel } from '@/modules/core/app/models/GenericModel';
import { OverviewDetails } from '@/modules/core/billing/models/OverviewDetails';

export class BillingResource extends BaseResource {
  constructor() {
    super(RouteGroup.BILLING);
  }

  /**
   *
   * @returns {*}
   */
  getBillingDetails() {
    return this.getData(BillingDetails, 'plans');
  }

  /**
   *
   * @param planId
   * @param addonsData
   * @param frequency
   * @returns {*}
   */
  checkoutPlan(planId, addonsData, frequency) {
    return super.postData({ plan_id: planId, addons_data: addonsData, frequency }, 'checkout');
  }

  /**
   *
   * @returns {*}
   */
  getPrimaryContactAndCardDetails() {
    return this.getData(GenericModel, 'getprimarycontactandcarddetails');
  }

  /**
   *
   * @returns {*}
   */
  getCreditCardUrl() {
    return this.getData(GenericModel, 'getcreditcardurl');
  }

  /**
   *
   * @param billingAddress
   * @returns {*}
   */
  saveBillingAddress(billingAddress) {
    return super.postData({ billing_address: billingAddress }, 'savebillingaddress');
  }

  /**
   *
   * @returns {*}
   */
  cancelPlan() {
    return super.postData(null, 'cancelplan');
  }

  /**
   *
   * @returns {*}
   */
  getOverviewDetails() {
    return this.getData(OverviewDetails, 'overview');
  }

  /**
   *
   * @returns {*}
   */
  reactivatePlan() {
    return super.postData(null, 'removecancellation');
  }
}

export default new BillingResource();
