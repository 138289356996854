'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';

import { $WidgetBuilderEvents } from 'coreModules/design/widget/builder/widget.builder.constants';

angular.module('widget.probe.ctrls', [])

    .controller('ProbeDataController', ProbeDataController);

/**
 * @ngInject
 */
function ProbeDataController(
    $scope,
    MomentDateFormat,
    RelativeDateRange,
    UIFactory,
    DateRangeFactory,
    WidgetDataSourceFactory,
    AppFactory,
    DataSourceType,
    PubSub,
    $timeout,
    ReportStudioTemplateDataService
) {
    $scope.isProbingDate = false;
    $scope.initProbing = initProbing;
    $scope.applyDashDateRange = applyDashDateRange;
    $scope.cancelProbing = cancelProbing;

    function initProbing() {
        $scope.isProbingDate = true;
        $scope.prevOccurrence = null;

        getProbeDate();
    }

    function getProbeDate() {
        var currentDate = AppFactory.getDateRange();
        var date_difference = currentDate.end - currentDate.start;
        var primaryDateField = $scope.widget.metadata.data_source.primary_date_field;
        var dateObj = {
            start: 0
        };

        var fields;

        if ($scope.widget.metadata.data_source.type === DataSourceType.CATEGORY_DATA) {
            fields = _.map($scope.widget.metadata.data_columns.selected, 'field');
        }
        else {
            fields = primaryDateField;
        }

        WidgetDataSourceFactory.probeData(dateObj, fields, primaryDateField, $scope.widget.metadata.data_source, $scope.widget).then(function(data) {
            $scope.prevOccurrence = {};
            if (!_.isEmpty(_.first(data)) && _.first(data)[primaryDateField]) {

                var date = _.first(data)[primaryDateField];
                var unixEnd = date;
                var unixStart = date - date_difference;

                $scope.prevOccurrence.start = moment.unix(unixStart).utc().format(MomentDateFormat.ISO);
                $scope.prevOccurrence.end = moment.unix(unixEnd).utc().format(MomentDateFormat.ISO);
                $scope.prevOccurrence.displayDate =
                    moment.unix(unixStart).utc().format(MomentDateFormat.MONTH_DAY_YEAR) + ' - ' +
                    moment.unix(unixEnd).utc().format(MomentDateFormat.MONTH_DAY_YEAR);

                $scope.prevOccurrence.query = {
                    start: unixEnd,
                    end: unixStart
                }
            }
            else {
                $scope.prevOccurrence.noData = true;
            }
        }, function() {
            $scope.prevOccurrence = {};
            UIFactory.notify.showError('There was an error searching for a previous occurrence of data');
            $scope.prevOccurrence.noData = true;
        });
    }

    function isReportStudioContext() {
        return ReportStudioTemplateDataService.getReport();
    }

    // Used to change Dashboard Date
    function applyDashDateRange(e, dateObj) {
        $(e.currentTarget).tooltip('destroy');

        cancelProbing();

        var comparisonDates = DateRangeFactory.calculatePeriod(
            dateObj.start,
            dateObj.end,
            RelativeDateRange.DEFAULT,
            RelativeDateRange.PRIOR_PERIOD
        );
        emitDateRangeChangeEvent(
            moment(dateObj.start),
            moment(dateObj.end),
            comparisonDates.start,
            comparisonDates.end,
            RelativeDateRange.DEFAULT,
        );
    }

    // probe is used in the dashboard and RS too which require different triggers
    function emitDateRangeChangeEvent(start, end, comparisonStart, comparisonEnd, relativeDateRange) {
        if (isReportStudioContext()) {
            return emitRSDateRangeChange(start, end, comparisonStart, comparisonEnd, relativeDateRange);
        }

        return emitDashboardDateRangeChange(start, end, comparisonStart, comparisonEnd, relativeDateRange);
    }

    function emitDashboardDateRangeChange(start, end, comparisonStart, comparisonEnd, relativeDateRange) {
        $scope.$root.$broadcast(
            'dashDatePicker:applyDateRange',
            start,
            end,
            comparisonStart,
            comparisonEnd,
            relativeDateRange
        );
    }

    function emitRSDateRangeChange(start, end, comparisonStart, comparisonEnd, relativeDateRange) {
        const dateRangeSettings = {
            start, end, comparisonStart, comparisonEnd, relativeDateRange,
        };
        PubSub.$emit($WidgetBuilderEvents.RS_SWITCH_DATA_TAB, dateRangeSettings);
        $timeout(() => PubSub.$emit($WidgetBuilderEvents.SET_DATE_RANGE_FROM_PROBE, dateRangeSettings), 100, false);
    }

    function cancelProbing() {
        $scope.prevOccurrence = null;
        $scope.isProbingDate = false;
    }
}
