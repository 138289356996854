'use strict';
import angular from 'angular';

angular.module('annotation.constants', [])

    .constant('$AnnotationEvents', {
        INIT_WIDGET: '$AnnotationEvents:INIT_WIDGET:',
        INIT_PANEL: '$AnnotationEvents:INIT_PANEL:',
        CLOSE_PANEL: '$AnnotationEvents:CLOSE_PANEL:',
        ADD_ANNOTATION: '$AnnotationEvents:ADD',
        DELETE_ANNOTATION: '$AnnotationEvents:DELETE',
        WIDGET_DATE_CLICK: '$AnnotationEvents:WIDGET_DATE_CLICK',
        ADD_GUIDE: '$AnnotationEvents:ADD_GUIDE'
    });