import { uniqWith } from 'lodash';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import DashboardPageResource from '@/modules/ta/dashboard/resources/DashboardPageResource';
import DashboardResource from '@/modules/ta/dashboard/resources/DashboardResource';

export class DashboardService extends BaseService {
  /**
   * @param {string} id
   * @returns {Dashboard}
   */
  async getItem(id) {
    return DashboardResource.getItem(id, {
      all: true,
    });
  }

  /**
   * @param id
   * @param value
   * @returns {Promise<*>}
   */
  async toggleTemplate(id, value) {
    return DashboardPageResource.toggleTemplate(id, value);
  }

  /**
   * @param id
   * @param value
   * @returns {Promise<*>}
   */
  async updateIsFavorite(id, value) {
    return DashboardPageResource.updateIsFavorite(id, value);
  }

  /**
   * @param id
   * @param value
   * @returns {Promise<*>}
   */
  async setExecutiveSummaryEnabled(id, value) {
    return DashboardPageResource.setExecutiveSummaryEnabled(id, value);
  }

  /**
   * @param id
   * @returns {Promise<*>}
   */
  async updateThumbnail(id) {
    return DashboardPageResource.updateThumbnail(id);
  }

  /**
   * Processes the list of data sources of a dashboard/template
   * and returns an array containing connected and disconnected
   * services
   * @param {array} dataSources
   * @param {array} disconnectedDataSourceIds
   * @param {array} services
   * @param {array} categories
   *
   * @returns {array}
   */
  bifurcateDataSourcesOnConnectionStatus(
    dataSources,
    disconnectedDataSourceIds,
    services,
    categories
  ) {
    const connectedCellDataSources = [];
    if (!Array.isArray(disconnectedDataSourceIds)) {
      disconnectedDataSourceIds = Object.values(disconnectedDataSourceIds);
    }
    const disconnectedCellDataSources = [...disconnectedDataSourceIds];

    // flatten categories
    dataSources.forEach((dataSource) => {
      if (dataSource.data_type === DataSourceType.CATEGORY_DATA) {
        const category = categories.find((c) => c.id === dataSource.data_source_id);
        if (category) {
          Object.keys(category.service_ids).forEach((serviceId) => {
            // Check if the services are connected. Push them to disconnected list if they're not
            if (services.find((s) => s.id === serviceId && s.is_connected)) {
              connectedCellDataSources.push({ data_source_id: serviceId });
            } else {
              disconnectedCellDataSources.push(Number(serviceId));
            }
          });
        }
      } else {
        connectedCellDataSources.push(dataSource);
      }
    });

    // Remove data sources that are in disconnected_service_ids list
    const uniqueConnectedDataSources = uniqWith(
      connectedCellDataSources.filter(
        (dataSource) => !disconnectedDataSourceIds.includes(Number(dataSource.data_source_id))
      ),
      (a, b) => a.data_source_id === b.data_source_id
    );

    const connectedDataSources = [];
    const disconnectedDataSources = [];

    uniqueConnectedDataSources.forEach((dataSource) => {
      const service = services.find((s) => s.id === dataSource.data_source_id);
      if (service) {
        connectedDataSources.push({
          id: dataSource.data_source_id,
          is_connected: true,
        });
      }
    });

    // Remove duplicates before iterating
    [...new Set(disconnectedCellDataSources)].forEach((serviceId) => {
      const service = services.find((s) => s.id === String(serviceId));
      if (service) {
        disconnectedDataSources.push({
          id: `${serviceId}`,
          is_connected: false,
        });
      }
    });

    return [...connectedDataSources, ...disconnectedDataSources];
  }
}

export default new DashboardService();
