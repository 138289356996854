'use strict';
import angular from 'angular';
import _ from 'lodash';
import {
    WidgetBuilderConstants
} from 'coreModules/design/widget/builder/widget.builder.constants';
import {UserThemes} from "../../../../../shared/scripts/app.constants";
import {TimeGrouping} from "coreModules/design/widget/design.widget.constants";

angular.module('widget.datagrid.ctrls', [])

    .controller('DataGridWidgetWrapperController', DataGridWidgetWrapperController)
    .controller('DataGridWidgetController', DataGridWidgetController);

/**
 * @ngInject
 */
function DataGridWidgetWrapperController(
    $scope,
    ReportStudioTemplateDataService,
    DashboardContextService
){
    $scope.getFontType = getFontType;

    /**
     * Helper function to get the font type
     */
    function getFontType(){
        if (ReportStudioTemplateDataService.getIsActive()) {
            return {'font-family':DashboardContextService.resolveFontType()};
        }
        return {};
    }
}

/**
 * @ngInject
 */
function DataGridWidgetController(
    $scope,
    $timeout,
    $WidgetEvents,
    LoadingState,
    WidgetType,
    DrawOption,
    AppFactory,
    WidgetFactory,
    WidgetCreateTypeFactory,
    DashboardContextService,
    DesignFactory,
    DataGridFactory,
    DataSourceFactory,
    DataSourceType,
    WidgetSortUtilService,
    ChartPlotType,
    UIColor,
    WidgetBuilderService,
    UserThemes,
    WidgetUtilService,
    ReportStudioTemplateDataService,
    gettextCatalog,
    SparklineOptions,
) {
    // INIT
    registerEvents();
    setDefaults();
    setDrawOptions();
    buildTable();
    $scope.LoadingState = LoadingState;
    $scope.dataLoadedCallback = dataLoadedCallback;
    $scope.showClippedNotifier = showClippedNotifier;
    $scope.getFontType = getFontType;

    /**
     * Set default values
     */
    function setDefaults() {
        // Datatable options object
        $scope.dtOptions = {};
        $scope.DrawOption = DrawOption;

        // Metadata defaults
        var metadata = $scope.widget.metadata;
        if (_.isEmpty(metadata.sort_by)) {
            DataGridFactory.resetSortArray(metadata);
        }
    }

    /**
     * Set default draw options
     */
    function setDrawOptions() {
        // Use grey color for Report Studio as the report itself will only be light mode
        // Leave text_color unset by default for normal dashboards as the report can be both light and dark mode
        let textColor = ReportStudioTemplateDataService.getIsActive() ? '#737c84' : undefined;
        // Expose constant to scope
        $scope.DrawOption = DrawOption;

        var metadata = $scope.widget.metadata;
        // Set default draw options if none speficied
        var drawOptions = {};
        drawOptions[DrawOption.GRID_IS_RESPONSIVE] = false;
        drawOptions[DrawOption.GRID_COLLAPSE_IN_MODAL] = true;
        drawOptions[DrawOption.SHOW_TOTAL_ROW] = false;
        drawOptions[DrawOption.GRID_TOTAL_ROW_BOTTOM] = false;
        drawOptions[DrawOption.GRID_FULL_IMAGE_SIZE] = false;
        drawOptions[DrawOption.GRID_PREVIEW_AS_IFRAME] = false;
        drawOptions[DrawOption.GRID_MAX_HEIGHT_IMAGE] = 0;
        drawOptions[DrawOption.PIVOT_GRID] = false;
        drawOptions[DrawOption.DISPLAY_LENGTH] = 10;
        drawOptions[DrawOption.REPORT_DISPLAY_LENGTH] = null;
        drawOptions[DrawOption.PLOT_TYPE] = ChartPlotType.CLASSIC;
        drawOptions[DrawOption.GRID_FONT_SIZE] = DashboardContextService.resolveFontSizeValue();
        drawOptions[DrawOption.GRID_FONT_PROPERTIES] = {
            bold: false,
            italic: false,
            underline: false,
            text_color: textColor,
            strikethrough: false
        };
        drawOptions[DrawOption.WRAP_TEXT_NAME] = true;
        drawOptions[DrawOption.STYLIZED_EXCEL] = true;
        drawOptions[DrawOption.ENABLE_GRID_THEME] = false;
        drawOptions[DrawOption.EMBED_SPARKLINE_TYPE] = ChartPlotType.LINE;
        drawOptions[DrawOption.EMBED_SPARKLINE_DATE_RANGE] = SparklineOptions.LAST_3_MONTHS;


        // resetting draw options from other widget types if harmful to datagrid
        let resetDrawOptions = {};
        resetDrawOptions[DrawOption.SHOW_EMPTY_DATES] = false;
        
        if (metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.DEFAULT) {
            drawOptions[DrawOption.ENABLE_GRID_THEME] = true;
            resetDrawOptions[DrawOption.ENABLE_GRID_THEME] = true;
        }
        
        metadata.draw_options = _.assign(drawOptions, metadata.draw_options);
        metadata.draw_options = _.assign(metadata.draw_options, resetDrawOptions);

    }

    /**
     * Build DATAGRID widget
     */
    function buildTable() {
        var state = $scope.state;

        if ($scope.widget.has_live_integration && DashboardContextService.widgetHasGlobalFilter($scope.widget.metadata.data_source)) {
            $scope.state.loadingState = LoadingState.HAS_GLOBAL_FILTERS;
            return false;
        }
        if (state.isCreating && !state.canSave) {
            return false;
        }

        let metadata = $scope.widget.metadata;

        if (metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.EMBEDDED_SPARKLINES) {
            metadata.draw_options[DrawOption.SPLIT_IN_MULTIPLE_PAGES] = false;
        }
        
        $scope.$evalAsync(function() {
            $scope.displayTable = false;
        });

        // If no columns to show or warnings or errors, skip datagrid building
        if (metadata.data_columns.selected.length === 0
            && _.isEmpty(metadata.dynamic.warnings)
            && _.isEmpty(metadata.dynamic.errors)) {
            return false;
        }

        $timeout(function () {
            var options = _.extend({}, metadata.draw_options);
            options.isComparing = DataSourceFactory.dataSourceContainsServices(metadata.data_source.type)
                && (AppFactory.getComparisonDateRange().enabled || metadata.compare_to_prior_period);
            if (metadata.is_overriding_date_range) {
                options.isComparing = metadata.compare_to_prior_period ?? options.isComparing;
            }
            options.isThumbPreview = state.isThumbPreview;
            options.isSample = WidgetFactory.useSampleData(metadata);
            options.rebuildTable = function() {
                buildTable();
            };
            options.setIsQueryingDatagrid = function(isQuerying) {
                $scope.state.isQueryingDatagrid = isQuerying;
            };
            $scope.dtOptions = DataGridFactory.getDTOptions(options, $scope.widget);
            setHelpState();
            $scope.displayTable = true;
        });

        // Custom sorting function for day of the week column - Mon to Sun & Sun to Mon
        if (metadata.time_grouping === TimeGrouping.GROUPING_DAY_OF_WEEK ){
            let getStartDayOfWeek = _.capitalize(AppFactory.getUser().getDefaultWeeklyStartDay());
            $.core.datatable.customSorting(gettextCatalog.getString(getStartDayOfWeek));
        }
    }

    /**
     * Handles if and how we should rebuild the datagrid widget
     */
    function handleRebuild() {
        var metadata = $scope.widget.metadata;

        // When we switch between datagrid plot_types Default to Embed Sparkline we need to disable GRID_PAGINATE, GRID_IS_RESPONSIVE
        if (metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.EMBEDDED_SPARKLINES) {
            metadata.draw_options[DrawOption.GRID_PAGINATE] = false;
            metadata.draw_options[DrawOption.GRID_IS_RESPONSIVE] = false;
        }

        var hasSelectedColumns = _.size(metadata.data_columns.selected) > 0;

        if (hasSelectedColumns) {
            setSortArray(metadata);
            buildTable();
        }
        else {
            $scope.displayTable = false;
        }
    }

    /**
     * Helper function to set sort array in metadata
     * @param metadata
     */
    function setSortArray(metadata) {
        var selectedObj = AppFactory.arrayToMemoizedObj(metadata.data_columns.selected, 'field');

        // Delete element from sort array if not in selected columns any more
        var index = _.findIndex(metadata.sort_by, function(field) {
            return !selectedObj[field];
        });
        WidgetSortUtilService.ensureColumnOrder(metadata, index);

        if (_.isEmpty(metadata.sort_by)) {
            DataGridFactory.resetSortArray(metadata);
        }
    }

    var $widgetRebuildFn;
    function registerEvents() {
        $widgetRebuildFn = $scope.$on($WidgetEvents.WIDGET_REBUILD, handleRebuild);

        $scope.$on('widget:setOrder', function(e, column, isMultiSorting) {
            WidgetSortUtilService.updateColumnSort($scope.widget.metadata, column, isMultiSorting);
            handleRebuild();
        });

        $scope.$on('$destroy', function() {
            $widgetRebuildFn();
        });
    }

    /**
     * Evaluate data state after data is loaded to decide whether or not to show widget or warning message
     * @param json
     */
    function dataLoadedCallback(json) {
        $scope.$evalAsync(function() {

            var state = $scope.state;
            var metadata = $scope.widget.metadata;

            if (json.error) {
                state.loadingState = WidgetFactory.evaluateLoadingState(json || [], metadata);
            }
            else {
                state.loadingState = WidgetFactory.useSampleData(metadata)
                  ? LoadingState.BUILDING
                  : WidgetFactory.evaluateLoadingState(json.aaData, metadata, json.has_comparison_data);

                if (!$scope.widget.metadata.draw_options[DrawOption.PLOT_TYPE]) {
                    $scope.widget.metadata.draw_options[DrawOption.PLOT_TYPE] = $scope.widget.id === WidgetBuilderConstants.NEW_WIDGET_ID
                    || WidgetBuilderService.isCurrentWidgetInBuildMode($scope.widget.id) ? ChartPlotType.DEFAULT : ChartPlotType.CLASSIC;
                }
            }

            if (state.loadingState === LoadingState.BUILDING) {
                /*
                * we are using loadingLayout value to determine if loader should be shown or not
                * adding this below line so that when demo mode is enabled & show_widgets_with_no_data is disabled,
                * loadingLayout value will be updated
                * */
                WidgetFactory.evaluateLoadingState(json.aaData, metadata, json.has_comparison_data);

                if ($scope.widget.can_be_accessed || $scope.widget.can_be_edited) {
                    let identicalSelectedGrouped = false;
                    let nonEmptyGroupedColumn = false;
                    const isComparing = DataSourceFactory.dataSourceContainsServices(metadata.data_source.type)
                        && ((!metadata.is_overriding_date_range && AppFactory.getComparisonDateRange().enabled)
                            || (metadata.is_overriding_date_range && metadata.compare_to_prior_period));

                    let dataColumns = metadata.data_columns.selected.filter(selectedColumn => {
                        return !metadata.data_columns.grouped.find(groupedColumn => {
                            return selectedColumn.field === groupedColumn.field;
                        });
                    });

                    /**
                     * If all selected columns & grouped columns are same
                     * then consider grouped columns
                     */
                    if(!dataColumns.length) {
                        identicalSelectedGrouped = true;
                        dataColumns = metadata.data_columns.grouped;
                    }

                    metadata.draw_options.allEmptyData = true;
                    json.aaData.forEach(rowData => {
                        if (metadata.draw_options.allEmptyData) {
                            dataColumns.forEach(column => {
                                if (metadata.draw_options.allEmptyData) {
                                    const columnData = rowData[column.field];
                                    if (columnData !== null && columnData !== undefined) {
                                        if (isComparing && (_.toNumber(columnData.value) !== 0 || _.toNumber(columnData.comparisonValue) !== 0)) {
                                            metadata.draw_options.allEmptyData = false;
                                        } else if (!isComparing && _.toNumber(columnData) !== 0) {
                                            metadata.draw_options.allEmptyData = false;
                                        }
                                    }
                                }
                            });

                            /**
                             * Checking if atleast one grouped column contains
                             * non empty value
                             */
                            if(metadata.draw_options.allEmptyData && !identicalSelectedGrouped && !nonEmptyGroupedColumn) {
                                metadata.data_columns.grouped.forEach(gropedColumn => {
                                    if (!nonEmptyGroupedColumn) {
                                        const columnData = rowData[gropedColumn.field];
                                        if (columnData !== null && columnData !== undefined) {
                                            if (isComparing && (_.toNumber(columnData.value) !== 0 || _.toNumber(columnData.comparisonValue) !== 0)) {
                                                nonEmptyGroupedColumn = true;
                                            } else if (!isComparing && _.toNumber(columnData) !== 0) {
                                                nonEmptyGroupedColumn = true;
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    });

                    if(metadata.draw_options.allEmptyData && nonEmptyGroupedColumn) {
                        metadata.draw_options.allEmptyData = false;
                    }

                    if (metadata.draw_options.allEmptyData) {
                        state.loadingState = LoadingState.ALL_EMPTY_DATA;
                        $scope.displayTable = false;
                    } else {
                        state.loadingState = LoadingState.DONE;
                    }
                } else {
                    state.loadingState = LoadingState.DONE;
                }
            }
            WidgetFactory.widgetLoaded($scope.widget);
        });
    }

    /**
     * Helper function to tell if we need to show clipped notifier
     */
    function showClippedNotifier() {
        var drawOptions = $scope.widget.metadata.draw_options;
        return drawOptions[DrawOption.PIVOT_GRID]
            && drawOptions[DrawOption.GRID_PAGINATE]
            && $scope.dtOptions.totalDisplayRecordsNum > drawOptions[DrawOption.DISPLAY_LENGTH]
            && !DesignFactory.getIsExportingPage();
    }

    /**
     * Helper function to get the font type
     */
    function getFontType(){
        if (ReportStudioTemplateDataService.getIsActive()) {
            return {'font-family':DashboardContextService.resolveFontType()};
        }
        return {};
    }

    /**
     * Helper function to set help message
     */
    function setHelpState() {
        if (_.isEmpty($scope.state)) {
            return;
        }
        var options = $scope.dtOptions;
        var state = $scope.state;
        state.helpNeeded = false;
        state.helpMessage = 'Draw Option Not Applied';
        state.helpAction = [];

        if (options.hasMetricFilter && options[DrawOption.SHOW_TOTAL_ROW]) {
            state.helpAction = _.concat(state.helpAction, [
                'Totals Rows is not supported when Metric filter(s) are applied to the widget, and will be hidden in the data grid.'
            ]);
            state.helpNeeded = true;
        }

        if (options[DrawOption.PIVOT_GRID] && options[DrawOption.GRID_PAGINATE]) {
            state.helpAction = _.concat(state.helpAction, [
                'Paginate records is not supported when "Flip rows to columns" is enabled.'
            ]);
            state.helpNeeded = true;
        }

        if (options[DrawOption.PIVOT_GRID] && options[DrawOption.GRID_TOTAL_ROW_BOTTOM]) {
            state.helpAction = _.concat(state.helpAction, [
                'Display total at bottom is not supported when "Flip rows to columns" is enabled.'
            ]);
            state.helpNeeded = true;
        }

        if ($scope.widget.metadata.data_source.type === DataSourceType.LEADS
            && options[DrawOption.PIVOT_GRID]
            && _.isEmpty($scope.widget.metadata.data_columns.grouped)) {
            state.helpAction = _.concat(state.helpAction, [
                '\"Flip rows to columns\" requires at least one grouped data column. Please add a grouped data column in the left panel.'
            ]);
            state.helpNeeded = true;
        }
    }

    function _getBackgroundColor() {
        return $scope.widget.metadata.data_source.color;
    }

    /**
     * Watcher function to detect changes in font properties
     */
    $scope.$watch(function() { return $scope.widget.metadata.draw_options[DrawOption.GRID_FONT_PROPERTIES]; }, function (nV, oV) {
        for (let key in nV) {
            if (nV[key] !== oV[key]) {
                $scope.dtOptions[DrawOption.GRID_FONT_PROPERTIES] = nV;
                WidgetFactory.$rebuildWidget($scope.widget.id, _.isEmpty(DesignFactory.getCurrentPage()));
                break;
            }
        }
    }, true);

    /**
     * Watcher function to detect changes in plot type
     */
    $scope.$watch(function() { return $scope.widget.metadata.draw_options[DrawOption.PLOT_TYPE]; }, function (newValue, oldValue) {
        const requiredPlotTypes = [ChartPlotType.GROUPED_COLUMN, ChartPlotType.HEAT_MAP];
        if (requiredPlotTypes.includes(newValue)  && $scope.widget.metadata.draw_options[DrawOption.SHOW_TOTAL_ROW]) {
            WidgetUtilService.handleTotalAtBottomStatus($scope.widget.metadata.draw_options,true);
        } else if (requiredPlotTypes.includes(oldValue)) {
            WidgetUtilService.handleTotalAtBottomStatus($scope.widget.metadata.draw_options);
        }
    });

    /**
     * Function to watch for the change in status of Show Total row toggle
     */
    $scope.$watch(function() { return $scope.widget.metadata.draw_options[DrawOption.SHOW_TOTAL_ROW]; }, function (newValue, oldValue) {
        if (WidgetUtilService.isGroupedColumnPlotType($scope.widget.metadata.draw_options) || WidgetUtilService.isHeatMapPlotType($scope.widget.metadata.draw_options)) {
            const newValueChanged = newValue ? true :false;
            WidgetUtilService.handleTotalAtBottomStatus($scope.widget.metadata.draw_options, newValueChanged);
        }
    });
}
