'use strict';
import angular from 'angular';
import './scripts/reportingprofile.ctrls';
import './scripts/reportingprofile.directives';
import './scripts/reportingprofile.services';

angular.module('core.reportingprofile', [
    'reportingprofile.ctrls',
    'reportingprofile.directives',
    'reportingprofile.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('reportingprofiles', {
                parent:'layout',
                url: '/reportingprofiles',
                controller: 'ReportingProfileListController',
                templateUrl: AppConfig.MAGE_URL + 'reportingProfile/manage_reporting_profiles',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('reportingprofiles')
                }
            })
            .state('reportingprofiles.add', {
                parent:'layout',
                url: '/reportingprofiles/add',
                data: { isNewEntity: true, primaryKey: 'reporting_profile_id' },
                controller: 'ReportingProfileController',
                templateUrl: AppConfig.MAGE_URL + 'reportingProfile/add_reporting_profile'
            })
            .state('reportingprofiles.addwithallservices', {
                parent:'layout',
                url: '/reportingprofiles/add_show_all_services',
                data: { isNewEntity: true, primaryKey: 'reporting_profile_id' },
                controller: 'ReportingProfileController',
                templateUrl: AppConfig.MAGE_URL + 'reportingProfile/add_reporting_profile/show_all_services/1'
            })
            .state('reportingprofiles.detail', {
                parent:'layout',
                url: '/reportingprofiles/detail/:id',
                controller: 'ReportingProfileController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'reportingProfile/view_reporting_profile/reporting_profile_id/' + $stateParams.id;
                }
            })
            .state('reportingprofiles.detailwithallservices', {
                parent:'layout',
                url: '/reportingprofiles/detail_show_all_services/:id',
                controller: 'ReportingProfileController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'reportingProfile/view_reporting_profile/show_all_services/1/reporting_profile_id/' + $stateParams.id;
                }
            })
            .state('reportingprofiles.servicedetail', {
                parent:'layout',
                url: '/reportingprofiles/servicedetail/:id/:service_id',
                controller: 'ReportingProfileController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'reportingProfile/view_reporting_profile/reporting_profile_id/' + $stateParams.id + '?service=' + $stateParams.service_id;
                }
            })
            .state('reportingprofiles.categorydetail', {
                parent:'layout',
                url: '/reportingprofiles/categorydetail/:id/:category_id',
                controller: 'ReportingProfileController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'reportingProfile/view_reporting_profile/reporting_profile_id/' + $stateParams.id + '?category=' + $stateParams.category_id;
                }
            })
            .state('reportingprofiles.copy', {
                parent:'layout',
                url: '/reportingprofiles/copy/:id',
                data: { isNewEntity: true, primaryKey: 'reporting_profile_id' },
                controller: 'ReportingProfileController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'reportingProfile/view_reporting_profile/reporting_profile_id/' + $stateParams.id + '/create_copy/1';
                }
            })
    }])