'use strict';
import angular from 'angular';

angular.module('widget.option.services', [])

    .constant('$WidgetOptionMessageConstants', WidgetOptionMessageConstants())
    .factory('WidgetOptionFactory', WidgetOptionFactory);

function WidgetOptionMessageConstants() {
    return {
        PUBLISH_LIBRARY: 'WidgetOptionMessageConstants:PUBLISH_LIBRARY'
    }
}

WidgetOptionFactory.$inject = [
];
function WidgetOptionFactory(
) {

    return {
    }
}