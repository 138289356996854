'use strict';
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';
import { UserType } from 'coreModules/shared/scripts/app.constants';

angular.module('annotation.services', [])
    .factory('AnnotationFactory', AnnotationFactory)
    .factory('AnnotationUIFactory', AnnotationUIFactory);

/**
 *
 * @ngInject
 */
function AnnotationFactory(
    $q,
    $timeout,
    PubSub,
    $MessengerEvents,
    $AnnotationEvents,
    AnnotationResourceFactory,
    AnnotationModelFactory,
    AppModelFactory,
    AppFactory,
    DesignFactory,
    DashboardFilterPanelFactory,
    ClientGroupFactory,
    ClientFactory,
    ClusterService,
    MomentDateFormat,
    DataSourceType,
    LoadingState,
    ColumnFormat,
    ScopeFactory,
    MessengerFactory
) {

    var props = AnnotationModelFactory.getProps();
    var annotationsObject = AnnotationModelFactory.getAnnotationsPanelObject();

    // TODO: Move to props
    var _clientSelectOptionsToLoad = 0;
    var _brandMappings;
    var clientSelectOptions = {};

    return {
        getAnnotations: getAnnotations,
        getShowDate: getShowDate,
        getIsActive: getIsActive,
        setIsActive: setIsActive,
        getIsLoadingSelect: getIsLoadingSelect,
        addAnnotation: addAnnotation,
        getNewAnnotation: getNewAnnotation,
        getAnnotationThread: getAnnotationThread,
        deleteAnnotation: deleteAnnotation,
        getClientSelected: getClientSelected,
        // NOTE: only a singular clientSelectOptions will exist/be shared across items...
        clientSelectOptions: clientSelectOptions,
        $init: $init
    };

    /**
     * Getter
     * @returns {*|boolean}
     */
    function getIsActive() {
        return props.state.isActive;
    }

    /**
     * Setter
     * @returns {*|boolean}
     */
    function setIsActive(isActive) {
        props.state.isActive = isActive;
    }

    /**
     * getter
     * @returns {*|boolean}
     */
    function getIsLoadingSelect() {
        return props.state.isLoadingSelect;
    }

    /**
     *
     * @param annotation
     * @returns new {Annotation}
     */
    function getNewAnnotation(annotation) {
        var selectedClient = getClientSelected();
        annotation.widget_id = annotationsObject.widgetId;
        annotation.entityOptions = clientSelectOptions;
        if (annotation.showDate) {
            // Update the dateOptions with the new values in order to display the correct dates
            annotation.dateOptions.startDate = moment(annotation.dateOptions.start).unix();
            annotation.dateOptions.endDate = moment(annotation.dateOptions.end).unix();
        }
        // Reset all other selected values
        annotation.resetSelectedClient();
        var selectedClientValues = _.isArray(selectedClient.currentValues) ? selectedClient.currentValues[0] : selectedClient.currentValues;
        if (selectedClient && !_.isNull(selectedClientValues)) {
            annotation[selectedClient.key + '_id'] = selectedClientValues.id;
            annotation[selectedClient.key + '_name'] = selectedClientValues.text;
        }
        if (!annotation.id) {
            var currentUser = AppFactory.getUser();

            annotation.user = {
                first_name: currentUser.firstName,
                last_name: currentUser.lastName
            };
            annotation.user_id = currentUser.userId;
            annotation.user_image_metadata = currentUser.userImageMetadata;
        }

        return AnnotationModelFactory.getAnnotationThread(annotation);
    }

    /**
     *
     * @param annotation
     * @param index
     * @returns {Annotation}
     */
    function getAnnotationThread(annotation, index) {
        // entityOptions must be bound to the annotation object
        annotation.entityOptions = clientSelectOptions;
        if(AppFactory.getUser().type === UserType.CLIENT) {
            annotation.hideAddButton = true;
            annotation.permissions = {can_be_edited: false, can_be_deleted: false};
        }
        return AnnotationModelFactory.getAnnotationThread(annotation, index);
    }

    /**
     * Add annotation and messenger thread
     * @param annotation
     */
    function addAnnotation(annotation) {
        annotation.updated_at = null; // updated_at will be set to current time
        var newAnnotation = getAnnotationThread(annotation, annotationsObject.annotations.length);
        newAnnotation.plain();

        // If dashboard is assigned to client, automatically assign annotation to that client
        var pageEntity = DesignFactory.getPageEntity();
        if (!_.isEmpty(pageEntity)) {
            if(pageEntity.type === DataSourceType.CLIENT_GROUP){
                newAnnotation.client_group_id = pageEntity.id;
            } else {
                newAnnotation.client_id = pageEntity.id;
            }
        }

        return AnnotationResourceFactory.save(newAnnotation).then(function(json) {
            if (!newAnnotation.id) {
                newAnnotation.id = json;
                MessengerFactory.addThread(newAnnotation);
            }
            // Update annotation object...
            annotationsObject.addAnnotation(newAnnotation);

            // Reset threads in messenger
            _resetThreads();

            // Need to send event to widget
            PubSub.emit($AnnotationEvents.ADD_ANNOTATION, newAnnotation);
        });
    }

    /**
     * Delete annotation and messenger thread
     * @param id
     */
    function deleteAnnotation(id) {
        return AnnotationResourceFactory.remove(id).then(function() {
            MessengerFactory.removeThread(id);
            annotationsObject.removeAnnotation(id);

            // Reset threads in messenger
            _resetThreads();

            PubSub.emit($AnnotationEvents.DELETE_ANNOTATION, id);
        });
    }

    function _resetThreads() {
        var deferred = $q.defer();
        var annotationThreads = _.map(annotationsObject.annotations, function(annotation, index) {
            annotation.showDate = annotationsObject.showDate;
            return getAnnotationThread(annotation, index);
        });
        deferred.resolve(annotationThreads);
        MessengerFactory.setThreads(deferred.promise);
    }

    /**
     * Check if more clientSelectOptions need to be loaded
     */
    function checkIfDone(cb) {
        if (_clientSelectOptionsToLoad === 0) {
            // Timeout must be used in order to re-initialize select2 correctly
            return $timeout(function() {
                props.state.isLoadingSelect = LoadingState.DONE;
                cb();
            }, 200, true);
        }
    }

    function getClientSelected() {
        var clientSelected = false;

        _.each(clientSelectOptions, function(option) {
            if (!_.isEmpty(option.currentValues)) {
                clientSelected = option;
            }
        });

        return clientSelected;
    }

    /**
     * Reset a select2 and it's filter values that currently has a value
     */
    function _resetFilter(key, $el) {
        var option = _.find(clientSelectOptions, {key: key});
        option.currentValues = [];
        $el.select2('data', null);
    }

    /**
     * Get and set dashboard filters
     */
    // TODO: Need to get currently set dashboard filters
    function initEntitySelect(cb) {
        // setIsActive needs to be correctly set within the clientSelectOptions
        props.state.isLoadingSelect = LoadingState.LOADING;
        if (_.isEmpty(_brandMappings)) {
            _brandMappings = AppFactory.getBrandMappings();
        }

        if (!_.isEmpty(DashboardFilterPanelFactory.shared.dashFilterOptionList)) {
            // Keep annotation selection in sync with dashboard filters
            _.forEach(DashboardFilterPanelFactory.shared.dashFilterOptionList, function(option) {
                clientSelectOptions[option.key] = option;
            });
        }

        // Avoid refetching data
        if (!_.isEmpty(clientSelectOptions)) {
            _clientSelectOptionsToLoad = 0;
            // Need to update the selected values for the current entity
            checkIfDone(cb);
            return;
        }

        if (DesignFactory.getPageEntity()) {
            checkIfDone(cb);

            return;
        }

        if(AppFactory.getUser().type === UserType.CLIENT) {
            checkIfDone(cb);
            return;
        }

        return DashboardFilterPanelFactory.shared.getFilterValues().then(function (json) {
            var clientSelectValues = json.plain();
            _clientSelectOptionsToLoad = clientSelectValues.length; // # of possible filters

            _.forEach(clientSelectValues, function(option) {
                // Select2 inputs
                if (option.format === ColumnFormat.FORMAT_ID || option.format === ColumnFormat.FORMAT_STRING) {
                    // Set select2 default options
                    switch (option.key) {
                        case (DataSourceType.CLIENT):
                            option.label = _brandMappings.client;
                            break;
                        case (DataSourceType.CLIENT_GROUP):
                            option.label = _brandMappings.client_group;
                            break;
                    }

                    option.onChange = function(key, $el) {
                        // Only reset values if change did not come from the source select2 emitting the vent
                        if (option.key !== key) {
                            _resetFilter(option.key, $el);
                        }
                    };

                    option.onReset = function(e, $el) {
                        $el.select2('data', null);
                    };

                    // Set current values based on values already in session
                    option.currentValues = !_.isEmpty(DashboardFilterPanelFactory.shared.filters) && DashboardFilterPanelFactory.shared.filters[option.key].values.length
                        ? _.first(angular.copy(DashboardFilterPanelFactory.shared.filters[option.key].values))
                        : {};

                    option.listenToOtherSelect2 = true;

                    option.selectOptions = AppModelFactory.getSelectOptions(option);

                    switch (option.key) {
                        case (DataSourceType.CLIENT):
                            option.values = [];
                            ClientFactory.getFieldValues(option.field).then(function (json) {
                                _clientSelectOptionsToLoad--;
                                option.values = json.plain().values;
                                clientSelectOptions[option.key] = option;
                                return checkIfDone(cb);
                            });
                            break;

                        case (DataSourceType.CLIENT_GROUP):
                            option.values = [];
                            ClientGroupFactory.getFieldValues(option.field).then(function (json) {
                                _clientSelectOptionsToLoad--;
                                option.values = json.plain().values;
                                clientSelectOptions[option.key] = option;
                                return checkIfDone(cb);
                            });
                            break;

                        case (DataSourceType.CLUSTER):
                            option.values = [];
                            ClusterService.getFieldValues(option.field, {status: 'active'}).then(function (json) {
                                _clientSelectOptionsToLoad--;
                                option.values = json.plain().values;
                                clientSelectOptions[option.key] = option;
                                return checkIfDone(cb);
                            });
                            break;

                        default:
                            _clientSelectOptionsToLoad--;
                            return checkIfDone(cb);
                            break;
                    }
                }
            });
        });
    }

    /**
     * Getter
     */
    function getAnnotations() {
        return annotationsObject.annotations;
    }

    /**
     *
     * @returns {*}
     */
    function getShowDate() {
        return annotationsObject.showDate;
    }

    /**
     * Setter
     * @param widget
     */
    function setAnnotationsObject(widget, state) {
        annotationsObject = AnnotationModelFactory.getAnnotationsPanelObject({
            widgetId: widget.id,
            annotations: widget.metadata.dynamic.annotations,
            showDate: state.isTimeGroupedWidget && state.isSeriesWidget
        });
    }

    /**
     * @param widget
     * @param state
     */
    function $init(state, widget) {
        var deferred = $q.defer();
        props.state.isActive = true;

        setAnnotationsObject(widget, state);

        MessengerFactory.setThreads(deferred.promise);

        AnnotationResourceFactory.getAnnotationsForWidget(widget.id).then(function(threads) {

            annotationsObject.annotations = _.isNil(threads) ? [] : threads.plain();

            var annotationThreads = _.map(annotationsObject.annotations, function(annotation, index) {
                annotation.showDate = annotationsObject.showDate;
                return getAnnotationThread(annotation, index);
            });

            initEntitySelect(function() {
                deferred.resolve(annotationThreads);
                PubSub.emit($MessengerEvents.INIT_PANEL + 'annotation-panel', {widget: widget, state: state});
                PubSub.emit($AnnotationEvents.INIT_WIDGET + widget.id);
            });
        })

    }
}

/**
 * Annotation UI Service
 * @ngInject
 */
function AnnotationUIFactory(
    $window
) {
    return {
        updateDatePicker: updateDatePicker,
        updateDatePickerPosition: updateDatePickerPosition
    };

    /**
     *
     * @param {(jQuery|HTMLElement)} $element - annotation filters element
     * @param {AnnotationThread} thread
     */
    function updateDatePicker($element, thread) {
        var $dateRangePicker = $element.find('input.daterange');

        if ($dateRangePicker && $dateRangePicker.data('daterangepicker')) {
            $dateRangePicker.data('daterangepicker').setStartDate(moment.utc(moment.unix(thread.dateOptions.startDate)));
            $dateRangePicker.data('daterangepicker').setEndDate(moment.utc(moment.unix(thread.dateOptions.endDate)));

            // Pulse date range picker
            var $pulseDate = $dateRangePicker.closest('.date-range-picker');
            $pulseDate.addClass('animPulse').on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function() {
                $pulseDate.removeClass('animPulse');
            });
        }
    }

    /**
     * Ensures date picker is visible in the window
     * @param event
     * @param picker
     */
    function updateDatePickerPosition(picker) {
        var $$window = $($window);

        var $windowHeight = $$window.outerHeight();
        var pickerOffsetY = picker.container.offset().top;
        var pickerHeight = picker.container.outerHeight();

        if (($windowHeight - (pickerOffsetY + pickerHeight)) < 0) {
            var bottomPadding = '20';
            picker.container.css('top', pickerOffsetY - (pickerOffsetY + pickerHeight - $windowHeight) - bottomPadding);
            picker.container.addClass('no-arrow');
        }

        if (pickerOffsetY < 0) {
            var topPadding = '20px';
            picker.container.css('top', topPadding);
            picker.container.addClass('no-arrow');
        }
    }
}