"use strict";
import $ from 'jquery';

var Core = $.core.admin.campaigns = {

    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {

    },
    events: function () {
        this.deleteBindings();
    },
    deleteBindings: function () {
        $('#campaign-table').find('a.delete-action').click(function(e){
            //Set the url for row delete action
            $(this).data('url', $.fn.actionUrl('campaign/delete_campaign?service_id=' + $(this).data('serviceId') + '&campaign_id=' + $(this).data('campaignId')));

            $.core.datatable.deleteRow($(this), $(this).data('name'));
        });
    }
};