import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import tinycolor from 'tinycolor2';
var Main = $.core.main;

const ColorThief = require('@laverdet/lokesh-colorthief');

angular.module('core.ui.services', [])
    .service('UIFactory', UIFactory)
    .factory('UIColor', UIColor);

/**
 * @ngInject
 */
function UIFactory(
    $timeout,
    Restangular,
    AppModelFactory,
    NotifyType,
    $ModalEvents,
    PubSub,
    gettextCatalog
) {
    var enums = Restangular.all('enums');
    var themes = enums.one('themes');
    var baseColors = themes.one('base');
    var colors = themes.one('colors');

    //
    // THEME COLORS /////////////////////////////////////
    //
    var themeColors = [];
    var themeBaseColors = [];
    var originalThemeColorCount = 10;

    /**
     * Sets the themeColors var to be exposed externally
     * @param values
     * @returns {*}
     */
    function setThemeColors(values) {
        themeColors = values;
    }

    /**
     * Allows read only access to the themeColors
     * @returns {{}}
     */
    function getThemeColors() {
        // Must assign to an empty object to unbind any changes to the themeColors object
        return _.concat([], themeColors);
    }

    function getRandomThemeColor() {
        var colors = getThemeColors();
        var min = 0;
        var max = colors.length - 1;
        var index = Math.floor(Math.random() * (max - min + 1)) + min;
        return colors[index];
    }

    /**
     * Sets the themeColors var to be exposed externally
     * @param values
     * @returns {*}
     */
    function setThemeBaseColors(values) {
        themeBaseColors = values;
    }

    /**
     * Allows read only access to the themeColors
     * @returns {{}}
     */
    function getThemeBaseColors() {
        // Must assign to an empty object to unbind any changes to the themeColors object
        return _.concat([], themeBaseColors);
    }

    /**
     * Getter to retrieve the initial theme colors count
     * @returns {number}
     */
    function getOriginalThemeColorCount() {
        return originalThemeColorCount;
    }

    //
    // NOTIFY /////////////////////////////////////
    //
    var notify = {};

    notify.hideAll = function() {
        Main.hideAllNotifies();
    };

    notify.show = function(text, type, options) {
        Main.notify(text, type, options);
    };

    notify.showSuccess = function(data, options) {
        sequenceNotifies(data, NotifyType.SUCCESS, options);
    };

    notify.showWarning = function(data, options) {
        sequenceNotifies(data, NotifyType.WARNING, options);
    };

    notify.showError = function(data, options) {
        sequenceNotifies(data, NotifyType.ERROR, options);
    };

    /**
     * Function allows sequencing one or multiple notifies
     * @param data
     * @param type
     * @param options
     */
    function sequenceNotifies(data, type, options) {
        data = _.isString(data) ? [data] : data;
        _.each(data, function (val, i) {
            if (i === 0) {
                notify.show(val, type, options);
            }
            else {
                $timeout(function () {
                    notify.show(val, type, options);
                }, 300, false);
            }
        });
    }

    //
    // MODALS /////////////////////////////////////
    //
    function showModal(modalId, options) {
        var $modal = angular.element('#' + modalId);
        $modal.modal(_.assign(options, {
            show: true
        }), options);
        PubSub.emit($ModalEvents.SHOW_MODAL + modalId);
        return $modal;
    }

    /**
     * Hides modal based on modalId
     * If modalId is not passed, will attempt to close modal based on CSS .modal class
     * @param [modalId]
     */
    function hideModal(modalId) {
        var selector = modalId ? '#' + modalId : '.modal';
        var $modal = angular.element(selector);
        $modal.modal('hide');
        PubSub.emit($ModalEvents.HIDE_MODAL + modalId);
        return $modal;
    }

    //
    // SWEET ALERTS /////////////////////////////////////
    //
    /**
     * Helper for sweet alert generic success
     * @param options
     */
    function success(options) {
        var swalOptions = AppModelFactory.getSwalOptions({
            title: _.isUndefined(options.title) ? '' : options.title,
            text: options.text || 'Success',
            html: options.html || false,
            type: _.isUndefined(options.type) ? 'success' : options.type,
            imageUrl: options.imageUrl || null,
            confirmButtonText: options.confirmButtonText || 'Ok',
            showCancelButton: false,
            closeOnConfirm: true
        }, options);

        return swal(swalOptions);
    }

    /**
     * Helper for sweet alert generic warn
     * @param options
     */
    function warn(options) {

        var swalOptions = AppModelFactory.getSwalOptions({
            title: _.isUndefined(options.title) ? '' : options.title,
            text: options.text || 'Something is odd',
            html: options.html || false,
            type: _.isUndefined(options.type) ? 'warning' : options.type,
            imageUrl: options.imageUrl || null,
            confirmButtonText: options.confirmButtonText || 'Ok',
            showCancelButton: false,
            closeOnConfirm: true
        }, options);

        return swal(swalOptions);
    }

    /**
     * Helper for sweet alert generic confirm
     * @param options
     */
    function confirm(options) {
        var swalOptions = AppModelFactory.getSwalOptions(options);
        return swal(swalOptions, swalOptions.confirmFn);
    }

    /**
     * Helper for sweet alert generic confirm with text input
     * @param options
     */
    function confirmInput(options) {
        options = options || {};
        var callback = options.callback || function() {};
        options.type = 'input';
        options.confirmButtonText = options.confirmButtonText || 'Continue';
        options.cancelButtonText = options.cancelButtonText || 'Cancel';
        options.inputPlaceholder = options.inputPlaceholder || 'Input something...';
        options.confirmFn = function (inputValue) {
            if (inputValue === false) {
                return false;
            }
            if (_.isEmpty(inputValue)) {
                swal.showInputError(options.inputError || 'You need to write something!');
                return false
            }
            callback(inputValue);
        };

        return confirm(options);
    }

    /**
     * Helper for sweet alert delete confirm
     * @param options
     */
    function confirmDelete(options) {
        options = AppModelFactory.getSwalOptions(options);
        options.type = 'error';
        options.confirmButtonText = options.confirmButtonText || gettextCatalog.getString('Yes, delete');
        options.confirmButtonClass = 'btn-danger';

        return confirm(options);
    }

    /**
     * Generic function close a modal on the screen
     * @param [modalId] If provided, will close modal with that id, if not closes swal modal
     */
    function closeModal(modalId) {
        if (modalId) {
            return hideModal(modalId);
        }
        swal.close();
    }

    return {
        baseColors: baseColors,
        colors: colors,
        setThemeColors: setThemeColors,
        getThemeColors: getThemeColors,
        getRandomThemeColor: getRandomThemeColor,
        setThemeBaseColors: setThemeBaseColors,
        getThemeBaseColors: getThemeBaseColors,
        getOriginalThemeColorCount: getOriginalThemeColorCount,
        notify: notify,
        showModal: showModal,
        hideModal: hideModal,
        success: success,
        warn: warn,
        confirm: confirm,
        confirmInput: confirmInput,
        confirmDelete: confirmDelete,
        closeModal: closeModal
    };
}

/**
 * Helpers methods for manipulate color variables
 * @constructor
 */
function UIColor() {

    return {
        darkenHexColor: darkenHexColor,
        brigthenHexColor: brigthenHexColor,
        generateColorShades: generateColorShades,
        textColorWithBackgroundColor: textColorWithBackgroundColor,
        generateColorPalette: generateColorPalette,
        combineTwoColors: combineTwoColors,
        getComplementColor: getComplementColor,
        hexToRGBA: hexToRGBA,
        getPalette: getPalette,
        getGradientColor:getGradientColor
    };

    /**
     * Decreases the luminosity of the hex value
     * @param hexColor
     * @param luminosity
     * @requires hex value
     * @returns
     */
    function darkenHexColor(hexColor, luminosity) {
        luminosity = Math.abs(luminosity) || 0.5;
        return _colorLuminance(hexColor, -(luminosity))
    }

    /**
     * Increases the luminosity of the hex value
     * @param hexColor
     * @param luminosity
     * @returns
     */
    function brigthenHexColor(hexColor, luminosity) {
        luminosity = Math.abs(luminosity) || 0.5;
        return _colorLuminance(hexColor, luminosity)
    }

    /**
     * @param fromColor Color to generates shades from
     * @param numberOfShades number of shades to generate
     */
    function generateColorShades(fromColor, numberOfShades) {
        var shades = [];
        var increment = (1.0 / numberOfShades) * 2;
        var lightLuminosity = 1.0;
        var darkLuminosity = 1.0;

        for (var i = 1; i <= numberOfShades; ++i) {
            if (i % 2) {
                darkLuminosity -= increment;
                shades.push(darkenHexColor(darkLuminosity));
            } else {
                lightLuminosity -= increment;
                shades.push(brigthenHexColor(lightLuminosity));
            }
        }
        return shades;
    }

    /**
     * Returns the text color depending of constrast color for better readability
     * @param backgroundColor Hex color (#ffffff)
     * @returns black or white hex values
     */
    function textColorWithBackgroundColor(backgroundColor) {
        var rgb = _hexToRgb(backgroundColor);
        var lrgb = [];
        rgb.forEach(function(c) {
            c = c / 255.0;
            if (c <= 0.03928) {
                c = c / 12.92;
            } else {
                c = Math.pow((c + 0.055) / 1.055, 2.4);
            }
            lrgb.push(c);
        });
        var lum = 0.2126 * lrgb[0] + 0.7152 * lrgb[1] + 0.0722 * lrgb[2];
        return (lum > 0.179) ? '#000000' : '#ffffff';
    }

    /**
     * Combines two colors at 50% equal amount
     * @param color1
     * @param color2
     * @returns {String} hex string
     */
    function combineTwoColors(color1, color2) {
        return tinycolor.mix(color1, color2, 50).toHexString()
    }

    /**
     * Returns a new color that complements the input color
     * @param color As hex string
     * @returns {String} Hex value
     */
    function getComplementColor(color) {
        return tinycolor(color).complement().toHexString()
    }

    /**
     * Creates a readable color palette
     * @param primaryColor
     * @param secondaryColor
     * @returns {Array<String>}
     */
    function generateColorPalette(primaryColor, secondaryColor) {
        var newPalette = [primaryColor, secondaryColor];

        newPalette = newPalette
            .concat(_createTetraPaletteFromColor(primaryColor))
            .concat(_createTetraPaletteFromColor(secondaryColor))
            .concat(_createMixComplementColors(primaryColor, secondaryColor));

        return newPalette;
    }

    /**
     * re-usable function to generate 4 colors from inputColor
     * @param inputColor
     * @private
     */
    function _createTetraPaletteFromColor(inputColor) {
        var color = tinycolor(inputColor);
        return _extractHexColors(color.tetrad(inputColor));
    }

    /**
     * Blends both colors to create a 2 colors which complements the blend
     * @param primaryColor
     * @param secondaryColor
     * @returns {*}
     * @private
     */
    function _createMixComplementColors(primaryColor, secondaryColor) {
        var color = tinycolor.mix(primaryColor, secondaryColor, 50);
        return _extractHexColors(color.splitcomplement());
    }

    /**
     * Helper function to map tinyColor objects to hex string
     * @param palette
     * @returns {Array}
     * @private
     */
    function _extractHexColors(palette) {
        return _.map(palette, function (newColor) {
            return newColor.toHexString();
        }).filter(function (newColor, index) {
            return index !== 0;
        });
    }

    function _hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? [
                parseInt(result[1], 16),
                parseInt(result[2], 16),
                parseInt(result[3], 16)
            ]
            : [0, 0, 0];
    }

    /**
     * Helper function to convert HEX color to RGBA
     * @param hex
     * @param [alpha]
     * @returns {string}
     */
    function hexToRGBA(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }
    
    function getPalette(sourceImage, colorCount, quality) {
        return ColorThief.getPalette(sourceImage, colorCount, quality)
    }

    /**
     * ref @ https://www.sitepoint.com/javascript-generate-lighter-darker-color/
     */
    function _colorLuminance(hex, lum) {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i*2,2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00"+c).substr(c.length);
        }

        return rgb;
    }

    /**
     * Helper function to get gradient color for same color using gradient percentage
     * @param color
     * @param gradient_percentage
     * @returns {string}
     */
    function getGradientColor(color,gradient_percentage) {
        return '#' + _(color.replace('#', '')).chunk(2)
            .map(v => parseInt(v.join(''), 16))
            .map(v => ((0 | (1 << 8) + v + (256 - v) * gradient_percentage / 100).toString(16))
                .substring(1)).join('');
    }
}