import { ServiceDataResource } from '@/modules/ta/service/resources/ServiceDataResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { workerAxios } from '@/modules/core/app/helpers/WorkerManagerHelper';

export class ServiceDataLiveDataResource extends ServiceDataResource {
  constructor() {
    super(RouteGroup.SERVICES);
    this.apiHelper = workerAxios;
  }

  /**
   * @param result
   * @param model
   * @param params
   * @returns {*}
   */
  extractData(result, model, params) {
    // If sEcho is set then data being returned is for datatable
    const extractedData = result?.data?.sEcho ? [result.data] : Object.values(result.data);

    // Add comparison data if any
    if (result.has_comparison_data) {
      extractedData.has_comparison_data = !!result.has_comparison_data;
    }

    // Add total data if any
    if (result.total_data) {
      // Total data always only contains one item
      extractedData.total_data = result.total_data[0] || {};
    }

    extractedData.has_data_discrepancy = result.has_data_discrepancy || false;
    extractedData.show_currency = result.show_currency || false;
    extractedData.$$currency_discrepancy = result.currency_discrepancy || false;

    return super.extractData(extractedData, model, params);
  }
}

export default new ServiceDataLiveDataResource();
