import $ from 'jquery';

// node_modules
import 'matchmedia-polyfill/matchMedia.js'; // Match Media polyfill for IE9
import 'components-jqueryui/jquery-ui.js';
import 'bootstrap';
import 'bootstrap-daterangepicker';
import 'summernote';
import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout/dist/isotope.pkgd';
// make Isotope a jQuery plugin
jQueryBridget( 'isotope', Isotope, $ );

import 'select2';
//import 'switchery';
import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs';
import 'datatables.net-scroller';
import '@laverdet/lokesh-colorthief';
import 'tinycolor2';
import 'clipboard';
import 'owl.carousel';

// plugins
import './plugins';

// importing libs
import './libs/dev/less.min';
import './libs/js-ext';
import './libs/js-polyfill';
import './libs/jquery-ext';
import './libs/amplify-ext';

// customs
import './globals.js';
