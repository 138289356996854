'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('dashboard.services', [])

    .service('DashboardService', DashboardService);

//
// Dashboard service
//
DashboardService.$inject = ['$rootScope', '$http', 'AppFactory'];
function DashboardService($rs, $http, AppFactory) {
    return {
        updateLandingPage: function() {
            $http.get($rs.util.apiUrl('users/landing')).success(function (json) {
                if (!json.error) {
                    $('#header_logo_landing_page').attr("href", "#/"+json.data);
                }
            });
        },
        updateLogo: function() {
            var user = AppFactory.getUser();
            $('#header_logo_landing_page img').attr('src', user.currentLogoUrl);
        }
    }
}