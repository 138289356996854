'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('annotation.resources', [])

    .factory('AnnotationResourceFactory', AnnotationResourceFactory);

/**
 *
 * @ngInject
 */
function AnnotationResourceFactory(
    Restangular
) {
    var dash = Restangular.all('dash');
    var annotations = dash.all('annotation');

    return {
        annotations: annotations,
        getAnnotationsForWidget: getAnnotationsForWidget,
        update: update,
        remove: remove,
        save: save,
    };

    function getAnnotationsForWidget(widgetId) {
        return annotations.all('widget').one(widgetId).get();
    }

    /**
     *
     * @param model
     * @returns {*}
     */
    function update(model) {
        return annotations.all(model.id).post(model);
    }

    /**
     *
     * @param model
     * @returns {*}
     */
    function remove(id) {
        return annotations.all(id).remove();
    }

    /**
     * @param model
     * @returns {*}
     */
    function save(model) {
        return _.isUndefined(model.id) ? annotations.post(model) : annotations.all(model.id).post(model);
    }
}