'use strict';
import angular from 'angular';

angular.module('core.endcustomer.services', [])
    .factory('EndCustomerFactory',EndCustomerFactory);

/**
 * @ngInject
 */
function EndCustomerFactory(
    EndCustomerResource,
    DataSourceType,
    EndCustomerColumns,
    DetailsModalFactory,
    DetailsModalModelFactory
) {
    var defaultProps = {
        state: {
            isLoading: true
        },
        data: {
            endCustomers: []
        }
    };
    var props = angular.copy(defaultProps);

    return {
        setNewDetails: setNewDetails,
        setEditDetails: setEditDetails
    };

    /**
     * @param data
     * @param row
     */
    function setNewDetails() {
        DetailsModalFactory.setAsNew(true);
        return _setDetails([], 'New End Customer');
    }

    /**
     * @param data
     * @param row
     */
    function setEditDetails(data, row) {
        var promises = [
            EndCustomerResource.endCustomers.get(data[EndCustomerColumns.ID], {all: true})
        ];
        return _setDetails(promises, data[EndCustomerColumns.CUSTOMER_NAME], row);
    }

    /**
     * @param promises
     * @param title
     * @param [row]
     * @private
     */
    function _setDetails(promises, title, row) {
        return DetailsModalFactory.setDetails(DetailsModalModelFactory.getModalOptions({
            dataSourceType: DataSourceType.END_CUSTOMER,
            promises: promises,
            resource: EndCustomerResource.endCustomers,
            display: {
                title: title,
                loadingMessage: 'Loading End Customer',
                icon: 'icomoon-group'
            },
            row: row
        }));
    }
}