'use strict';
import angular from 'angular';

import widgetLibraryModalHtmlUrl from './widget.library.modal.html';
import widgetLibraryCarouselItemHtmlUrl from './widget.library.carousel.item.html';
import widgetLibraryDetailedItemHtmlUrl from './widget.library.detailed.item.html';
import widgetPublishModalHtmlUrl from './widget.publish.modal.html';

angular.module('widget.library.components', [])
    .component('widgetLibraryModal', {
        templateUrl: widgetLibraryModalHtmlUrl,
        controllerAs: 'vm',
        bindings: false,
        controller: WidgetLibraryModalController
    })
    .component('widgetLibraryCarouselItem', {
        templateUrl: widgetLibraryCarouselItemHtmlUrl,
        bindings: {
            item: '<'
        },
        controller: WidgetLibraryCarouselItemController,
        controllerAs: 'vm'
    })
    .component('widgetLibraryDetailedItem', {
        templateUrl: widgetLibraryDetailedItemHtmlUrl,
        controllerAs: 'vm',
        bindings: false,
        controller: WidgetLibraryDetailedItemController
    })
    .component('widgetPublishModal', {
        bindings: false,
        templateUrl: widgetPublishModalHtmlUrl,
        controller: WidgetPublishModalController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function WidgetLibraryModalController(
    $element,
    $timeout,
    PubSub,
    UIFactory,
    $WidgetLibraryEvents,
    LibraryFactory,
    LibraryUIFactory,
    LibraryModelFactory,
    WidgetCreateFactory,
    WidgetTypeGrouping,
    WidgetLibraryFactory,
    WidgetLibraryModelFactory,
    WidgetLibraryModalUIFactory,
    gettextCatalog,
    LibrarySearchGroup
) {
    var vm = this;
    vm.isNUI = window.isNUI;

    /**
     * @type {WidgetLibraryModalState}
     */
    vm.state = WidgetLibraryModelFactory.getWidgetLibraryModalState();

    /**
     * @type {LibraryModalData}
     */
    vm.data = LibraryModelFactory.getLibraryModalData();

    /**
     * @type {LibrarySearchBoxConfig}
     */
    vm.searchBox = LibraryModelFactory.getLibrarySearchBoxConfig();
    vm.searchBox.selectOptions.placeholder = gettextCatalog.getString('Filter by data source, widget type, or tag...');
    vm.searchBox.selectOptions.dropdownCssClass += ' grouped-3-columns';
    vm.shouldShowSearchResults = false;

    /**
     * @type {LibraryTitleSearchBoxConfig}
     */
    vm.titleSearchBox = LibraryModelFactory.getLibraryTitleSearchBoxConfig();

    /**
     * @type {LibraryCarouselOptions}
     */
    vm.owlCarouselOptions = LibraryModelFactory.getLibraryCarouselOptions();

    /**
     * @type {string}
     */
    vm.WidgetTypeGrouping = WidgetTypeGrouping;

    vm.serviceState = WidgetLibraryModalUIFactory.getState();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.initNewWidget = initNewWidget;
    vm.isShowingItemDetails = isShowingItemDetails;
    vm.toggleSearchBox = toggleSearchBox;
    vm.triggerSearch = triggerSearch;
    vm.showSearchResults = showSearchResults;
    vm.canSearch = canSearch;
    vm.clearSearch = clearSearch;
    vm.canFilter = canFilter;
    vm.clearFilter = clearFilter;
    vm.onClose = _onClose;
    vm.clearSearchAction = clearSearchAction;

    function $onInit() {
        _registerEvents();
        LibraryUIFactory.bindModalAnimation($element);
        vm.itemComponent = '<widget-library-carousel-item data-item="item"></widget-library-carousel-item>';
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function initNewWidget(typeGrouping) {
        _hideModal();
        WidgetCreateFactory.$initNew(typeGrouping);
    }

    /**
     * @returns {boolean}
     */
    function isShowingItemDetails() {
        return LibraryFactory.getIsShowingItemDetails();
    }

    function toggleSearchBox() {
        vm.state.isSearchBoxActive = !vm.state.isSearchBoxActive;
    }

    function triggerSearch() {
        $timeout(function() {
            if (!canSearch() && !canFilter()) {
                vm.data.searchedItems = [];
                vm.shouldShowSearchResults = false;
                return false;
            }
            vm.state.isSearching = true;
            vm.state.isLoading = true;
            vm.data.searchedItems = [];
            var queryParams = LibraryFactory.resolveSearchQueryFromSearchValues(vm.searchBox.selectedValues);
            if (!_.isEmpty(vm.titleSearchBox.value)) {
                queryParams[LibrarySearchGroup.TITLE] = vm.titleSearchBox.value;
            }
            WidgetLibraryFactory.getSearchedWidgets(queryParams).then(function (items) {
                vm.state.isSearching = false;
                vm.state.isLoading = false;
                vm.data.searchedItems = items;
                _setConnectedState(items);
                vm.shouldShowSearchResults = true;
            });
        }, 0);
    }

    /**
     * @returns {boolean}
     */
    function showSearchResults() {
        return vm.shouldShowSearchResults;
    }

    /**
     * @returns {boolean}
     * @private
     */
    function canSearch() {
        return vm.titleSearchBox.value.length > 1;
    }

    function clearSearch() {
        $timeout(function() {
            vm.titleSearchBox.value = '';
            _evaluateClear();
        }, 0);
    }

    /**
     * Used for Empty placeholder clear button
     */
    function clearSearchAction() {
        clearSearch();
        clearFilter();
    }

    /**
     * @returns {boolean}
     * @private
     */
    function canFilter() {
        return vm.searchBox.selectedValues.length > 0;
    }

    function clearFilter() {
        angular.element('#widget-library-modal div.library-header div.select2-container').select2('data', null);
        $timeout(function() {
            vm.searchBox.selectedValues = [];
            _evaluateClear();
        }, 0);
    }

    /**
     * Checks if we should show original results or re trigger a search
     * @private
     */
    function _evaluateClear() {
        if (canSearch() && canFilter()) {
            vm.data.searchedItems = [];
            vm.shouldShowSearchResults = false;
        } else {
            triggerSearch();
        }
    }

    function _init() {
        if (WidgetLibraryModalUIFactory.getIsActive()) {
            return;
        }

        _reset();
        _showModal();
        WidgetLibraryModalUIFactory.setIsActive(true);

        if (WidgetLibraryFactory.getLibraryNeedsRefresh()) {
            vm.data = LibraryModelFactory.getLibraryModalData();
            WidgetLibraryFactory.setLibraryNeedsRefresh(false);
        }

        if (_.isEmpty(vm.data.mostUsedItems)) {
            vm.state.isLoading = true;
            WidgetLibraryFactory.getLibraryWidgets().then(function (data) {
                vm.state.isLoading = false;
                vm.data.mostUsedItems = data.mostUsedItems;
                vm.data.recentlyUploadedItems = data.recentlyUploadedItems;
                _setConnectedState(vm.data.mostUsedItems);
                _setConnectedState(vm.data.recentlyUploadedItems);

                WidgetLibraryFactory.getSearchableValues().then(function (values) {
                    vm.searchBox.values = values;
                });
            });
        }
    }

    function _reset() {
        clearSearch();
        vm.state = WidgetLibraryModelFactory.getWidgetLibraryModalState();
        WidgetLibraryModalUIFactory.setIsActive(false);
        LibraryFactory.setIsShowingItemDetails(false);
        vm.data.searchedItems = [];
        vm.shouldShowSearchResults = false;
    }

    /**
     * Determines if widget is connected or not, individually
     * @param items
     * @private
     */
    function _setConnectedState(items) {
        _.each(items, function(item) {
            item.isConnected = WidgetLibraryFactory.getIsConnected(item);
        });
    }

    function _showModal() {
        LibraryUIFactory.showModal('widget-library-modal');
    }

    function _hideModal() {
        LibraryUIFactory.hideModal('widget-library-modal');
    }

    function _onClose() {
        _reset();
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetLibraryEvents.INIT_MODAL, _init);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetLibraryEvents.INIT_MODAL, _init);
    }
}

/**
 * @ngInject
 */
function WidgetLibraryCarouselItemController(
    PubSub,
    UIFactory,
    WidgetType,
    WidgetFactory,
    $WidgetLibraryEvents,
    LibraryFactory,
    WidgetTypeGrouping,
    WidgetLibraryFactory
) {
    var vm = this;
    vm.widgetState = WidgetFactory.getDefaultState();

    /**
     * @type {string}
     */
    vm.WidgetTypeGrouping = WidgetTypeGrouping;

    /**
     * @type {string}
     */
    vm.typeGrouping = WidgetFactory.getWidgetTypeGrouping(vm.item.type);

    vm.$onInit = $onInit;
    vm.selectItem = selectItem;

    function $onInit() {
        WidgetLibraryFactory.setDefaultWidgetState(vm.widgetState);
        WidgetLibraryFactory.setDefaultWidgetValues(vm.item);
    }

    function selectItem() {
        LibraryFactory.setIsShowingItemDetails(true);
        PubSub.emit($WidgetLibraryEvents.SET_ITEM_DETAILS, vm.item);
    }
}

/**
 * @ngInject
 */
function WidgetLibraryDetailedItemController(
    $state,
    $timeout,
    PubSub,
    $WidgetCreateEvents,
    UIFactory,
    AppFactory,
    LayoutFactory,
    WidgetFactory,
    $WidgetLibraryEvents,
    $WidgetEvents,
    WidgetCreateFactory,
    WidgetLibraryResourceFactory,
    LibraryFactory,
    LibraryUIFactory,
    LibraryModelFactory,
    WidgetLibraryFactory,
    DataSourceType,
    DrawOption,
    gettextCatalog
) {
    var vm = this;
    var _itemToSave = null;

    /**
     * @type {LibraryDetailedItemState}
     */
    vm.state = LibraryModelFactory.getLibraryDetailedItemState();

    /**
     * @type {LibraryDetailedItemData}
     */
    vm.data = LibraryModelFactory.getLibraryDetailedItemData();

    vm.widgetState = WidgetFactory.getDefaultState();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.goBack = goBack;
    vm.getConnectedBadgeTooltipText = getConnectedBadgeTooltipText;
    vm.getDownloadText = getDownloadText;
    vm.useWidgetAsTemplate = useWidgetAsTemplate;
    vm.goToConnectServices = goToConnectServices;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function goBack() {
        _reset();
    }

    /**
     * @returns {string}
     */
    function getConnectedBadgeTooltipText() {
        return vm.data.isConnected
            ? gettextCatalog.getString('Widget has a connected data source')
            : gettextCatalog.getString('Widget has an unconnected data source. Click "Connect Data Source" below to configure it.');
    }

    /**
     *
     * @returns {string}
     */
    function getDownloadText() {
        return WidgetCreateFactory.hasSwappedWidget() ? gettextCatalog.getString('Swap Widget') : gettextCatalog.getString('Use Template');
    }

    function useWidgetAsTemplate() {
        vm.state.isSaving = true;
        var libraryWidgetId = _itemToSave.id; // Need to store since it gets modified after saving
        WidgetLibraryFactory.useWidgetAsTemplate(_itemToSave).then(function(newWidget) {
            PubSub.emit($WidgetCreateEvents.CREATED_FROM_LIBRARY, newWidget);
            WidgetLibraryFactory.incrementDownloadCount(libraryWidgetId);
            UIFactory.notify.showSuccess(gettextCatalog.getString('Widget successfully saved'));
            _hideModal();
            _reset();
        });
    }

    function goToConnectServices() {
        var dataSource = vm.data.selectedItem.metadata.data_source;
        if (dataSource.type === DataSourceType.SERVICE_DATA) {
            $state.go('service.manage.open', {id: dataSource.id}).then(function () {
                _hideModal();
            });
        } else if (dataSource.type === DataSourceType.CATEGORY_DATA) {
            $state.go('categories').then(function () {
                _hideModal();
            });
        }
    }

    /**
     * @private
     */
    function _init(item) {
        // We use a temp _itemToSave object so that it does not interfere
        // with the selectedItem which is used as the model in the template
        _itemToSave = angular.copy(item);

        // a copy of 'item' must be made in order to not
        // interfere with the selected widget library object
        vm.data.selectedItem = angular.copy(item);
        vm.data.selectedItem.id = vm.data.selectedItem.id + '-detailed';
        vm.data.selectedItem.metadata.draw_options[DrawOption.SHOW_LABELS] = true;
        vm.data.isConnected = WidgetLibraryFactory.getIsConnected(vm.data.selectedItem);

        WidgetLibraryFactory.setDefaultWidgetState(vm.widgetState);
        WidgetLibraryFactory.setDefaultWidgetValues(vm.data.selectedItem);

        // Overrides
        vm.widgetState.isThumbPreview = false;
    }

    /**
     * @private
     */
    function _reset() {
        LibraryFactory.setIsShowingItemDetails(false);
        vm.data = LibraryModelFactory.getLibraryDetailedItemData();
        vm.state = LibraryModelFactory.getLibraryDetailedItemState();
    }

    function _hideModal() {
        LibraryUIFactory.hideModal('widget-library-modal');
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetLibraryEvents.SET_ITEM_DETAILS, _init);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetLibraryEvents.SET_ITEM_DETAILS, _init);
    }
}

/**
 * @ngInject
 */
function WidgetPublishModalController(
    PubSub,
    UIFactory,
    DesignFactory,
    WidgetFactory,
    WidgetTagFactory,
    $WidgetLibraryEvents,
    TagPickerSelectConstant,
    DataSourceFactory,
    WidgetLibraryFactory,
    WidgetLibraryPublishFactory,
    LibraryPublishModelFactory,
    LibraryPublishFactory,
    gettextCatalog
) {
    var vm = this;

    /**
     * @type {LibraryPublishModalState}
     */
    vm.state = LibraryPublishModelFactory.getLibraryPublishModalState();

    /**
     * @type {LibraryPublishModalData}
     */
    vm.data = LibraryPublishModelFactory.getLibraryPublishModalData();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onCancel = onCancel;
    vm.publishWidget = publishWidget;
    vm.getDataSourceColor = getDataSourceColor;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getWidgetTitle = getWidgetTitle;
    vm.showAssociationWarning = showAssociationWarning;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * @param model
     */
    function publishWidget(model) {
        vm.state.isSaving = true;
        LibraryPublishFactory.publish(WidgetLibraryPublishFactory, model, vm.data).then(function () {
            vm.state.isSaving = false;
            UIFactory.notify.showSuccess(gettextCatalog.getString('Widget successfully published to library'));
            if (window.isNUI) {
                const WidgetToPublish = _.extend(vm.data, model);
                PubSub.emit('SegmentEvents', {
                    event: 'PublishToLibraryEvent',
                    payload: {item: WidgetToPublish, layout: DesignFactory.getCurrentLayout(), page: DesignFactory.getCurrentPage(), type: 'widget'}
                });
            }
            WidgetLibraryFactory.setLibraryNeedsRefresh(true);
            _hideModal();
        }).catch(function (msg) {
            vm.state.isSaving = false;
            if (msg) {
                UIFactory.warn({text: msg});
            }
        });
    }

    /**
     * Determine which data source icon to display
     * @returns {string}
     */
    function getDataSourceIcon() {
        return DataSourceFactory.getDataSourceIcon(vm.data.itemToPublish.metadata.data_source);
    }

    /**
     * Determine which data source color
     * @returns {string}
     */
    function getDataSourceColor() {
        return DataSourceFactory.getDataSourceColor(vm.data.itemToPublish.metadata.data_source);
    }

        /**
         * @returns {string}
         */
        function getWidgetTitle() {
            var widget = vm.data.itemToPublish;
            return _.isEmpty(widget.title) ? gettextCatalog.getString('Untitled Widget') : widget.title;
        }

    /**
     * @returns {boolean}
     */
    function showAssociationWarning() {
        var dynamic = vm.data.itemToPublish.metadata.dynamic;
        return !_.isEmpty(dynamic.alerts)
            || !_.isEmpty(dynamic.annotations)
            || !_.isEmpty(dynamic.filters);
    }

    /**
     * Stops publishing
     */
    function onCancel() {
        _hideModal();
        _reset();
    }

    /**
     * @param widgetToPublish
     * @private
     */
    function _init(widgetToPublish) {
        _reset();
        vm.state.isActive = true;
        vm.state.popUp = widgetToPublish.popup;  // assigning popup value default to false
        vm.data.itemToPublish = angular.copy(widgetToPublish);
        _showModal();

        WidgetTagFactory.getTags().then(function(data) {
            vm.data.tags = data;
            vm.data.tags.unshift({id: TagPickerSelectConstant.PLACEHOLDER_ID, text: TagPickerSelectConstant.PLACEHOLDER_TAGS});
        });
    }

    function _reset() {
        vm.data = LibraryPublishModelFactory.getLibraryPublishModalData();
        vm.state = LibraryPublishModelFactory.getLibraryPublishModalState();
    }

    function _showModal() {
        UIFactory.showModal('widget-publish-modal');
    }

    function _hideModal() {
        UIFactory.hideModal('widget-publish-modal');
    }
    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetLibraryEvents.INIT_PUBLISH_MODAL, _init);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetLibraryEvents.INIT_PUBLISH_MODAL, _init);
    }
}