export const GoalRoute = {
  TESTGOALS: 'sup/goals',
  MINI_GOALS_CRITERIA: 'mini_goal_criteria_by_service',
};

export const GoalPathName = {
  TESTGOALS: 'testgoals',
  TESTGOALFORM_NEW: 'testgoalform:new',
  TESTGOALFORM: 'testgoalform',
  TESTGOALFORM_DUPLICATE: 'testgoalform:duplicate',
  GOALS: 'goals',
};

export const GoalAssociation = {
  CLIENT: 'client',
  CLIENT_GROUP: 'client_group',
  CLUSTER: 'cluster',
  INSTANCE_WIDE: 'instance_wide',
};

export const MetricTypes = {
  BUDGET: 'budget',
  PACING: 'pacing',
  CALCULATED: 'calculated',
};

export const MetricTypesUpperCase = {
  BUDGET: 'Budget',
  PACING: 'Pacing',
  CALCULATED: 'Calculated',
};

export const DataSourceType = {
  SERVICE_DATA: 'service_data',
  CATEGORY_DATA: 'category_data',
  SMART_CAMPAIGN: 'smart_campaign',
};

export const DataCategoryType = {
  SERVICES: 'Data Source',
  CATEGORIES: 'Channel',
  SMART_CAMPAIGN: 'Smart Campaign',
};

export const GoalFormSteps = {
  ASSOCIAION: 'Association',
  DATA_SOURCE: 'Data Category',
  GOAL_DEFINITION: 'Goal Definition',
  REVIEW_AND_SUBMIT: 'Review And Submit',
};

export const Ranges = {
  MAX_RANGE: 125,
  MIN_RANGE: -125,
  RANGE_BREAK: 120,
  CURRENT_MONTH_PERCENTAGE_BREAK: -50,
};

export const ThresholdPercentages = {
  SUCCESS_PERCENTAGE: 1,
};

export const Colors = {
  RED: '#f75769',
  GREEN: '#76bf76',
  YELLOW: '#f2a449',
  GREY: '#737c84',
  GREY_LIGHTEST: '#ebebeb',
  WHITE: '#FFFFFF',
  GREY_LIGHT: '#B7BFC6',
  BLACK: '#000000',
  BLUE: '#0090FF',
};

export const GoalEnds = {
  ON: 'on',
  AFTER: 'after',
  NEVER: 'never',
};

export const MAX_NUMBER = 9999;

export const DAYS_IN_A_YEAR = 365;

export const SECONDS_IN_A_WEEK = 604800; // 60 * 60 * 24 * 7

export const VOTE_LINK =
  'https://tapclicks.productboard.com/portal/1-tapclicks-feedback-portal/tabs/0fb5c77a-cf97-4c87-8352-86ec8eeb5fa8/features/5419772/portal/expanded';

export const GoalDelivery = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  BOTH: 'both',
};

export const GoalStatus = {
  LIVE: 'live',
  NOT_STARTED: 'not_started',
  COMPLETE: 'complete',
  LIVE_NO_DATA: 'live_no_data',
};

export const GoalCustomMessages = {
  ROLLOVER_TARGET_OVER_ACHIEVED:
    'The negative percentage value displayed here is due to the rollover goal target. It denotes the target value has been achieved prior to the current month.',
  GOAL_NAME_REQUIRED: 'Goal name is required',
};

export const GoalNotificationStatus = {
  ON: 'on',
  OFF: 'off',
};
export const WORKFLOW_ORDER_URL = '/client/iotool/dist/#/items/viewOrder';
export const WORKFLOW_LINE_ITEM_URL = '/client/iotool/dist/#/items/viewLineItem';

export const GoalSortOptions = {
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  ID: 'id',
};

export const GoalRepeatEveryUnit = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  QUARTERS: 'quarters',
};

export const GoalAlertServiceParams = {
  GOAL_NOT_NULL: '!null',
};

export const GoalMetricRoute = {
  METRIC_ROUTE: 'data/metrics/',
};

export const GoalDeleteNotifications = {
  GOAL_NOTIFICATION: 'Are you sure you want to delete this goal?',
  ALERT_NOTIFICATION: ' Alert associated to this goal also will be deleted.',
  LINE_ITEM_ASSOCIATION_NOTIFICATION: ' This goal is associated with a TapProjects Line Item.',
};

export const GoalCalculations = {
  CALCULATION: 'calculation',
};

export const GoalAggregations = {
  SUM: 'sum',
  AVERAGE: 'average',
};
export const GoalProgressBarOptions = {
  MIN: 'min',
  MAX: 'max',
  WIDTH: 'width',
  HEIGHT: 'height',
  GOAL: 'goal',
  GRID: 'grid',
  RENDERER: 'renderer',
  OPACITY: 'opacity',
  PACE: 'pace',
  PACE_CAP: 'Pace',
  COLUMN: 'column',
  POSITION_CHANGED: 'positionchanged',
  BOUNDS_CHANGED: 'boundschanged',
  LABEL: 'label',
  BULLET: 'bullet',
  Y: 'y',
  LINE: 'line',
  EXPECTED: 'Expected',
  PICTURE_CAP: 'Picture',
  STAR_CAP: 'Star',
  LABEL_CAP: 'Label',
  DIVISION: '%',
  ROLLOVER: 'rollover',
  ROLLOVER_CAP: 'Rollover',
  FILL_PATTERN: 'fillPattern',
};

export const GoalCategoryValues = {
  AGGREGATION: 'Aggregation',
  AGGREGATION_VALUES: 'aggregationvalues',
};

export const GoalDimensionValues = {
  CLIENT_NAICS_CODE: 'client_naics_code',
};
