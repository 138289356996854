import { commits, getters } from '@/modules/core/app/helpers/store';
import { shallowCopyKeyedObject } from '@/modules/core/helper';

export const mutations = {
  /**
   * @param state
   * @param {boolean} value
   */
  setNeedsReload(state, value) {
    state.needsReload = value;
  },
  /**
   * @param state
   * @param {boolean} value
   */
  setFiltersInitialized(state, value) {
    state.filtersInitialized = value;
  },
  /**
   * @param state
   * @param {boolean} value
   */
  setFilterOptions(state, options) {
    state.filterOptions = options;
  },
  /**
   * @param state
   * @param {boolean} value
   */
  setFilters(state, filters) {
    state.filters = filters;
  },
  /**
   * @param {DashboardState} state
   * @param {Dashboard} dashboard
   */
  setDashboard(state, { dashboard }) {
    commits.dashboardLayout.setLayoutsFromDashboard({ dashboard });
    dashboard.layouts = Object.values(dashboard.layouts).reduce((accum, layout) => {
      accum[layout.id] = null;
      return accum;
    }, {});
    state.dashboard = dashboard;
  },
  addLayout(state, { layout }) {
    state.dashboard.layouts[layout.id] = null;
    state.dashboard.layouts = shallowCopyKeyedObject(state.dashboard.layouts);
    commits.dashboardLayout.setLayout(layout);
  },
  removeLayout(state, layoutId) {
    delete state.dashboard.layouts[layoutId];
    state.dashboard.layouts = shallowCopyKeyedObject(state.dashboard.layouts);
  },
  setResizingWidget(state, widgetId = null) {
    state.isResizingWidgetId = widgetId;
  },
  setCurrentLayoutId(state, id) {
    state.currentLayoutId = id;
    commits.executivesummaries.setExecutiveSummaryFromLayout(getters.dashboardLayout.getLayout(id));
  },
  setExecutiveSummaryEnabled(state, value) {
    state.dashboard.executive_summary_enabled = value;
  },
};
