'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import exportOptionsModalHtmlUrl from './exportoptionsmodal.html';
import exportOptionItemHtmlUrl from './exportoptionitem.html';
var $fileDownload = $.fileDownload;

angular.module('core.export.directives')

    .component('exportOptionsModal', exportOptionsModal())
    .directive('exportPdfNavItem', exportPdfNavItem)
    .directive('exportPptNavItem', exportPptNavItem)
    .directive('exportCsvNavItem', exportCsvNavItem)
    .directive('exportPngNavItem', exportPngNavItem);

function exportOptionsModal() {
    return {
        templateUrl: exportOptionsModalHtmlUrl,
        bindings: false,
        controllerAs: 'vm',
        controller: ExportOptionsModalController
    }
}

/**
 * @ngInject
 */
function ExportOptionsModalController(
    PubSub,
    DesignFactory,
    ExportOptionsFactory,
    gettextCatalog,
    WidgetCurrencyDataService,
    DashboardContextService

) {
    var vm = this;
    vm.$onInit = $onInit;

    function $onInit() {
        if (!window.downloadedDashboards) {
          window.downloadedDashboards = {};
        }
        vm.shared = ExportOptionsFactory.shared;
        vm.getCurrentLayout = DesignFactory.getCurrentLayout;
        vm.isDemoModeEnabled = DashboardContextService.isDemoModeEnabled();

        vm.canShowDownloadAllSectionsTogglerTooltip = function() {
          return gettextCatalog.getString(
            "If turned off, only {{title}} will be included in your download.",
            {
              title: vm.getCurrentLayout().title
            }
          );
        };

        vm.canShowDownloadAllSectionsToggler = function() {
          return _.size(DesignFactory.getCurrentPage().layouts) > 1;
        };
        vm.hasClientDiscrepancy = function() {
          return WidgetCurrencyDataService.hasDiscrepancy();
        };
    }
}

/**
 * @ngInject
 */
function exportPdfNavItem(
    PubSub,
    LoadingState,
    UIFactory,
    DesignFactory,
    ExportOptionsFactory,
    ExportReportFactory,
    gettextCatalog
) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            label: '@',
            icon: '@'
        },
        templateUrl: exportOptionItemHtmlUrl,
        link: function(scope, el) {

            el.click(function() {
                ExportOptionsFactory.closeModal();

                scope.$evalAsync(function() {
                    UIFactory.notify.showSuccess(gettextCatalog.getString(`${DesignFactory.getCurrentPage().title} PDF will download when complete. You can navigate away from this page as needed.`));

                    var downloadAllLayouts = ExportOptionsFactory.shared.downloadAllLayouts;
                    var params = {
                        pageId: DesignFactory.getCurrentPage().id,
                        html: false,
                        show_layout_title: downloadAllLayouts,
                        use_report_options: true,
                        style_for_print: false
                    };
                    window.downloadedDashboards[params.pageId] = {title: DesignFactory.getCurrentPage().title};
                    if (!downloadAllLayouts) {
                        params.layoutId = DesignFactory.getCurrentLayout().id;
                    }

                    $fileDownload(ExportReportFactory.exportToPdf(params).getRequestedUrl(), {
                        successCallback: function (filePath) {
                            doneLoading();
                            let content = 'Requested dashboard PDF downloaded successfully.';
                            const pageId = parseFilePath(filePath);
                            if (!_.isEmpty(window.downloadedDashboards) && pageId  && window.downloadedDashboards[pageId] && !_.isEmpty(window.downloadedDashboards[pageId].title)) {
                                content = `${window.downloadedDashboards[parseFilePath(filePath)].title} dashboard PDF downloaded successfully.`;
                            }
                            UIFactory.notify.showSuccess(gettextCatalog.getString(content));
                        },
                        failCallback: function (err) {
                            var errObj = JSON.parse($(err).html());
                            doneLoading();
                            UIFactory.notify.showError(_.first(errObj.data));
                        }
                    });
                });
            });

            /**
             *
             * @param path
             * @returns {*}
             */
            function parseFilePath(path) {
                let formattedPath = path.split('&');
                let pageId;
                if (formattedPath.length !== 0) {
                    formattedPath = formattedPath.forEach(queryParam => {
                        if (queryParam) {
                            const formattedQueryParam = queryParam.split('=');
                            if (formattedQueryParam.length === 2 && formattedQueryParam[0] === 'pageId') {
                                pageId =   formattedQueryParam[1];
                            }
                        }
                    });
                }
                return pageId;
            }

            function doneLoading() {
                if (window.isNUI) {
                    PubSub.emit('SegmentEvents', {
                        event: 'QuickDownloadEvent',
                        payload: {page: DesignFactory.getCurrentPage(), download_format: 'pdf' }
                    });
                }
            }
        }
    }
}

/**
 * @ngInject
 */
function exportPptNavItem(
    PubSub,
    LoadingState,
    UIFactory,
    DesignFactory,
    ExportOptionsFactory,
    ExportReportFactory,
    gettextCatalog
) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            label: '@',
            icon: '@'
        },
        templateUrl: exportOptionItemHtmlUrl,
        link: function(scope, el) {

            el.click(function() {
                ExportOptionsFactory.closeModal();

                scope.$evalAsync(function() {
                    UIFactory.notify.showSuccess(gettextCatalog.getString(`${DesignFactory.getCurrentPage().title} PPT will download when complete. You can navigate away from this page as needed.`));
                    var params = {
                        pageId: DesignFactory.getCurrentPage().id
                    };
                    if (!ExportOptionsFactory.shared.downloadAllLayouts) {
                        params.layoutId = DesignFactory.getCurrentLayout().id;
                    }
                    window.downloadedDashboards[params.pageId] = {title: DesignFactory.getCurrentPage().title};
                    $fileDownload(ExportReportFactory.exportToPowerpoint(params).getRequestedUrl(), {
                        successCallback: function (filePath) {
                            doneLoading();
                            let content = 'Requested dashboard PPT downloaded successfully.';
                            const pageId = parseFilePath(filePath);
                            if (!_.isEmpty(window.downloadedDashboards) && pageId && window.downloadedDashboards[pageId] && !_.isEmpty(window.downloadedDashboards[pageId].title)) {
                                content = `${window.downloadedDashboards[parseFilePath(filePath)].title} dashboard PPT downloaded successfully.`;
                            }
                            UIFactory.notify.showSuccess(gettextCatalog.getString(content));
                        },
                        failCallback: function (err) {
                            var errObj = JSON.parse($(err).html());
                            doneLoading();
                            UIFactory.notify.showError(_.first(errObj.data));
                        }
                    });
                });
            });

            /**
             *
             * @param path
             * @returns {*}
             */
            function parseFilePath(path) {
                let formattedPath = path.split('&');
                let pageId;
                if (formattedPath.length !== 0) {
                    formattedPath = formattedPath.forEach(queryParam => {
                        if (queryParam) {
                            const formattedQueryParam = queryParam.split('=');
                            if (formattedQueryParam.length === 2 && formattedQueryParam[0] === 'pageId') {
                                pageId =   formattedQueryParam[1];
                            }
                        }
                    });
                }
                return pageId;
            }

            function doneLoading() {
                if (window.isNUI) {
                    PubSub.emit('SegmentEvents', {
                        event: 'QuickDownloadEvent',
                        payload: {page: DesignFactory.getCurrentPage(), download_format: 'ppt' }
                    });
                }
            }
        }
    }
}

/**
 * @ngInject
 */
function exportCsvNavItem(
    PubSub,
    LoadingState,
    UIFactory,
    DesignFactory,
    ExportOptionsFactory,
    ExportReportFactory,
    gettextCatalog
) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            label: '@',
            icon: '@'
        },
        templateUrl: exportOptionItemHtmlUrl,
        link: function(scope, el) {

            el.click(function() {
                ExportOptionsFactory.closeModal();

                scope.$evalAsync(function() {
                    UIFactory.notify.showSuccess(gettextCatalog.getString(`${DesignFactory.getCurrentPage().title} Excel will download when complete. You can navigate away from this page as needed.`));

                    var params = {
                        pageId: DesignFactory.getCurrentPage().id
                    };
                    if (!ExportOptionsFactory.shared.downloadAllLayouts) {
                        params.layoutId = DesignFactory.getCurrentLayout().id;
                    }
                    window.downloadedDashboards[params.pageId] = {title: DesignFactory.getCurrentPage().title};
                    $fileDownload(ExportReportFactory.exportToExcel(params).getRequestedUrl(), {
                        successCallback: function (filePath) {
                            doneLoading();
                            let content = 'Requested dashboard Excel downloaded successfully.';
                            const pageId = parseFilePath(filePath);
                            if (!_.isEmpty(window.downloadedDashboards) && pageId && window.downloadedDashboards[pageId] && !_.isEmpty(window.downloadedDashboards[pageId].title)) {
                                content = `${window.downloadedDashboards[parseFilePath(filePath)].title} dashboard Excel downloaded successfully.`;
                            }
                            UIFactory.notify.showSuccess(gettextCatalog.getString(content));
                        },
                        failCallback: function (err) {
                            var errObj = JSON.parse($(err).html());
                            doneLoading();
                            UIFactory.notify.showError(_.first(errObj.data));
                        }
                    });
                });
            });

            /**
             *
             * @param path
             * @returns {*}
             */
            function parseFilePath(path) {
                let formattedPath = path.split('&');
                let pageId;
                if (formattedPath.length !== 0) {
                    formattedPath = formattedPath.forEach(queryParam => {
                        if (queryParam) {
                            const formattedQueryParam = queryParam.split('=');
                            if (formattedQueryParam.length === 2 && formattedQueryParam[0] === 'pageId') {
                                pageId =   formattedQueryParam[1];
                            }
                        }
                    });
                }
                return pageId;
            }

            function doneLoading() {
                if (window.isNUI) {
                    PubSub.emit('SegmentEvents', {
                        event: 'QuickDownloadEvent',
                        payload: {page: DesignFactory.getCurrentPage(), download_format: 'excel' }
                    });
                }
            }
        }
    }
}

/**
 * @ngInject
 */
function exportPngNavItem(
    LoadingState,
    UIFactory,
    DesignFactory,
    ExportReportFactory,
    gettextCatalog
) {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            widgetId: '='
        },
        link: function(scope, el) {
            var state = DesignFactory.state;

            el.click(function() {

                scope.$evalAsync(function() {
                    state.exporting.loading = true;
                    state.exporting.text = gettextCatalog.getString('Exporting widget to image');
                    state.exporting.icon = 'icon-file-image-o';

                    var params = {
                        widgetId: scope.widgetId,
                        style_for_print: false
                    };
                    $fileDownload(ExportReportFactory.exportToPng(params).getRequestedUrl(), {
                        successCallback: function () {
                            doneLoading();
                        },
                        failCallback: function (err) {
                            var errObj = JSON.parse($(err).html());
                            doneLoading();
                            UIFactory.notify.showError(_.first(errObj.data));
                        }
                    });
                });
            });

            function doneLoading() {
                scope.$evalAsync(function() {
                    state.exporting.loading = LoadingState.DONE;
                });
            }
        }
    }
}