'use strict';
import angular from 'angular';

angular.module('preferences.services', [])

    .service('PreferencesService', PreferencesService);

//
// Preferences service
//
PreferencesService.$inject = ['$rootScope', '$http'];
function PreferencesService($rs, $http) {
    return {
    }
}