'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.leads.resources', [])
    .factory('LeadsResource', LeadsResource)
    .factory('LeadsDataGridFactory', LeadsDataGridFactory);

function LeadsResource(
    Restangular,
    ColumnValueType
) {
    var leads = Restangular.all('leads');
    var vars = leads.one('vars');
    var values = leads.one('values');

    /**
     * @param queryParams
     * @returns {*}
     */
    function getColumns(queryParams) {
        return leads.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @param dataSource
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        queryParams = _.extend({fields: fields}, queryParams || {});
        return values.one(ColumnValueType.DISTINCT).get(queryParams);
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return leads.getList(_.extend({
            all: true
        }, queryParams));
    }

    return {
        leads: leads,
        vars: vars,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData
    }
}

/**
 * @ngInject
 */
function LeadsDataGridFactory(
    $timeout,
    PubSub,
    DataGridRender,
    ServiceFactory,
    EndCustomerFactory,
    $MessengerEvents,
    $DatagridTagPickerEvents,
    MessengerModelFactory,
    LeadColumns,
    MessengerFactory,
    LeadsFactory,
    AppFactory
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.tagsEnabled = true; //Enable leadtags for all users [Fx for TA-44030]
        dtOptions.customRenders = {};
        dtOptions.numActions = 2;
        dtOptions.customRenders[LeadColumns.ID] = function (data, type, full) {
            var $html = DataGridRender.getEditDetailsButton(LeadColumns.ID, full.can_be_edited);
            $html += DataGridRender.getActionButton({
                className: 'history-action',
                disabled: !full.has_history,
                icon: 'icon-history',
                field: LeadColumns.HAS_HISTORY
            });
            return $html;
        };

        dtOptions.customRenders[LeadColumns.NOTE_COUNT] = function (data, type, full) {
            var noteCount = full[LeadColumns.NOTE_COUNT];
            var hasNotes = !_.isEmpty(noteCount);
            return DataGridRender.getActionLink({
                className: 'note-action',
                html: '<span>' + (noteCount || 0) + '</span>',
                icon: hasNotes ? 'icon-sticky-note' : 'icon-sticky-note-o',
                field: LeadColumns.NOTE_COUNT
            });
        };

        dtOptions.customRenders[LeadColumns.SERVICE_NAME] = function (data, type, full) {
            var html;
            if (full[LeadColumns.SERVICE_ID]) {
                html = ServiceFactory.getServiceNameDisplay(full[LeadColumns.SERVICE_ID]);
                if (_.isEmpty(html)) {
                    html = '<span class="label label-default">Not Connected</span>';
                }
            } else {
                html = data;
            }
            return html;
        };

        dtOptions.customRenders[LeadColumns.TYPE] = function (data) {
            return LeadsFactory.getTypeLabel(data);
        };

        dtOptions.customRenders[LeadColumns.QUALITY] = function (data, type, full, meta) {
            if (!_.isEmpty(data)) {
                var values = _.find(meta.settings.aoColumns, {data: LeadColumns.QUALITY}).values;
                var color = _.find(values, {key: data}).color;
                return LeadsFactory.getColorCodedLabel(data, color);
            }
        };

        dtOptions.customRenders[LeadColumns.STATUS] = function (data, type, full, meta) {
            if (!_.isEmpty(data)) {
                var values = _.find(meta.settings.aoColumns, {data: LeadColumns.STATUS}).values;
                var color = _.find(values, {key: data}).color;
                return LeadsFactory.getColorCodedLabel(data, color);
            }
        };

        dtOptions.customRenders[LeadColumns.CUSTOMER_NAME] = function (data, type, full) {
            data = data || full[LeadColumns.CALLER_NAME];

            var html = data;
            var endCustomerId = full[LeadColumns.END_CUSTOMER_ID];
            if (!_.isNull(endCustomerId)) {
                html = DataGridRender.getActionLink({html: data, field: LeadColumns.END_CUSTOMER_ID});
            }
            return html;
        };

        dtOptions.postCreatedRowCallback = function($link, row, data, index) {
            var field = $link.data('field');
            switch (field) {
                case LeadColumns.ID:
                    $link.click(function () {
                        LeadsFactory.setEditDetails(data, row, index);
                    });
                    break;

                case LeadColumns.END_CUSTOMER_ID:
                    $link.click(function () {
                        EndCustomerFactory.setEditDetails({
                            id: data[LeadColumns.END_CUSTOMER_ID],
                            customer_name: data[LeadColumns.CUSTOMER_NAME]
                        }, row);
                    });
                    break;

                case LeadColumns.NOTE_COUNT:
                    $link.click(function () {
                        PubSub.emit($MessengerEvents.INIT_PANEL + 'leads-notes-panel', MessengerModelFactory.getPanelData({
                            data: data,
                            $sourceEl: angular.element(this)
                        }));
                    });
                    break;

                case LeadColumns.HAS_HISTORY:
                    $link.click(function () {
                        PubSub.emit($MessengerEvents.INIT_PANEL + 'leads-history-panel', MessengerModelFactory.getPanelData({
                            data: data
                        }));
                    });
                    break;
            }
        };

        return dtOptions;
    }
}