'use strict';
import angular from 'angular';
import _ from 'lodash';

import alertItemHtmlUrl from './alert.item.html';
import alertWidgetItemHtmlUrl from './alert.widget.item.html';

angular.module('core.alert.item.components', [])

    .component('alertItem', {
        templateUrl: alertItemHtmlUrl,
        bindings: {
            options: '<',
            state: '<',
            datasource: '<',
            alert: '=alertData'
        },
        controller: AlertItemController,
        controllerAs: 'vm'
    })

    .component('alertWidgetItem', {
        templateUrl: alertWidgetItemHtmlUrl,
        bindings: {
            options: '<',
            state: '<',
            datasource: '<',
            alert: '=alertData',
            metrics: '<',
        },
        controller: AlertWidgetItemController,
        controllerAs: 'vm'
    });

/**
 * Individual alert list item
 * @ngInject
 */
function AlertItemController(
    $AlertEvents,
    $scope,
    PubSub,
    AppFactory,
    AppModelFactory,
    DesignFactory,
    AlertFactory,
    AlertTimeFrame,
    AlertFrequency,
    AlertModelFactory,
    ClientFactory,
    ClientGroupFactory,
    DataSourceType,
    DataSourceFactory,
    UIFactory,
    gettextCatalog
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getDataSourceColor = getDataSourceColor;
    vm.removeAlert = removeAlert;
    vm.addCondition = addCondition;
    vm.removeCondition = removeCondition;
    vm.addGroupby = addGroupby;
    vm.removeGroupby = removeGroupby;
    vm.evaluationTimeframeChange = evaluationTimeframeChange;
    vm.evaluationTimeframeFrequencyChange = evaluationTimeframeFrequencyChange;
    vm.updateOperator = updateOperator;
    vm.resetClientAndClientGroup = resetClientAndClientGroup;
    vm.resetClient = resetClient;
    vm.resetClientGroup = resetClientGroup;
    vm.AlertTimeFrame = AlertTimeFrame;
    vm.AlertFrequency = AlertFrequency;
    vm.showFrequencyEveryDay = AlertFactory.showFrequencyEveryDay;
    vm.showFrequencyEveryWeek = AlertFactory.showFrequencyEveryWeek;
    vm.showFrequencyEveryMonth = AlertFactory.showFrequencyEveryMonth;
    vm.showFrequencyEveryQuarter = AlertFactory.showFrequencyEveryQuarter;
    vm.showEveryDayOfWeek = AlertFactory.showEveryDayOfWeek;
    vm.showEveryDayOfMonth = AlertFactory.showEveryDayOfMonth;
    vm.showEveryDayOfQuarter = AlertFactory.showEveryDayOfQuarter;

    vm.daysOfWeek = AlertFactory.getDaysOfWeek();
    vm.daysOfMonth = AlertFactory.getDaysOfMonth();
    vm.daysOfQuarter = AlertFactory.getDaysOfQuarter();

    vm.updateDataSourceId = updateDataSourceId;
    vm.updateDataSourceDataView = updateDataSourceDataView;
    vm.updateGroupbyHasClient = updateGroupbyHasClient;

    vm.instructions = gettextCatalog.getString('You must first select a data category.');
    // Only shows service data for now as other types of data source are not implemented in alert yet
    vm.currentModule = DataSourceType.SERVICE_DATA;
    vm.brandMappings = AppFactory.getBrandMappings();

    function $onInit() {
        _registerEvents();

        updateConditionMetricPrecision();
        updateShowDeleteCondition();
        updateShowDeleteGrounpby();
        resetClientAndClientGroup();

        if (!vm.alert.isNew) {
            setMetricsColumns();
            setGroupbyColumns(function() {
                updateGroupbyHasClient();
            });
        }

        vm.services = AppFactory.getConnectedServices();
        vm.isLoadingColumns = false;
        vm.options.recipientSelectOptions = AlertModelFactory.getRecipientOptions(vm.options.recipientSelectOptions);
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _registerEvents() {
        PubSub.on($AlertEvents.UPDATE_DATASOURCE_ID, updateDataSourceId);
        PubSub.on($AlertEvents.UPDATE_DATASOURCE_DATA_VIEW, updateDataSourceDataView);
        PubSub.on($AlertEvents.UPDATE_GROUPBY, updateGroupbyHasClient);
    }

    function _unregisterEvents() {
        PubSub.off($AlertEvents.UPDATE_DATASOURCE_ID, updateDataSourceId);
        PubSub.off($AlertEvents.UPDATE_DATASOURCE_DATA_VIEW, updateDataSourceDataView);
        PubSub.off($AlertEvents.UPDATE_GROUPBY, updateGroupbyHasClient);
    }

    function evaluationTimeframeChange() {
        resetEvaluationFrequency();
        resetEvaluationFrequencyOffset();
    }

    function evaluationTimeframeFrequencyChange() {
        resetEvaluationFrequencyOffset();
    }

    function updateOperator(operator) {
        vm.alert.operator = vm.alert.operator !== operator ? operator : '';
    }

    function resetClientAndClientGroup() {
        $scope.$evalAsync(function() {
            resetClient();
            resetClientGroup();
        });
    }

    function updateDataSourceId() {
        resetGroupBy();
        resetCondition();
    }

    function updateDataSourceDataView() {
        setMetricsColumns();
        setGroupbyColumns();
        resetGroupBy();
        resetCondition();
    }

    function updateConditionMetricPrecision() {
        AlertFactory.updateMetricPrecision(vm.alert.metrics, vm.alert.conditions);
    }

    function setMetricsColumns() {
        vm.instructions = null;
        AlertFactory.setMetricsColumns(vm.alert, function() {
            updateConditionMetricPrecision();
        });
    }

    function setGroupbyColumns(callback) {
        AlertFactory.setGroupbyColumns(vm.alert, callback);
    }

    function resetClient() {
        // Initialize the client select2 selectbox
        $scope.$evalAsync(function () {
            var options = {
                placeholder: gettextCatalog.getString('Select {{client}}...', {client: vm.brandMappings.client}),
                width: '50%',
                loaded: false,
                formatData: function(json) {
                    var data = json.values;
                    var self = this;

                    // pre-select option
                    if (!_.isEmpty(vm.alert.client)) {
                        self.selectedValue = {
                            id: vm.alert.client.id,
                            text: vm.alert.client.text
                        };
                    }

                    self.values = _.map(data, function(client) {
                        return {
                            id: client.key,
                            text: client.value
                        };
                    });
                    self.loaded = true;

                    return self;
                },
                getDataCall: function(query) {
                    var params = {
                        reporting_status: 'active'
                    };
                    if (vm.alert.cluster && vm.alert.cluster.id) {
                        params.cluster_id = vm.alert.cluster.id;
                    }

                    if (query) {
                        params.q = query + '|company_name';
                    }

                    return ClientFactory.getFieldValues('id', params);
                },
                onChange: function($el) {
                    var val = $el.select2('data');
                    vm.alert.client = val;
                }
            };
            vm.options.clientSelectOptions = AppModelFactory.getSelectOptions(options);
        });
    }

    function resetClientGroup() {
        // Initialize the client group select2 selectbox
        $scope.$evalAsync(function() {
            var options = {
                placeholder: gettextCatalog.getString('Select {{client_group}}...', {client_group: vm.brandMappings.client_group}),
                width: '50%',
                loaded: false,
                formatData: function(json) {
                    var data = json.values;
                    var self = this;

                    // pre-select option
                    if (!_.isEmpty(vm.alert.client_group)) {
                        self.selectedValue = {
                            id: vm.alert.client_group.id,
                            text: vm.alert.client_group.text
                        };
                    }

                    self.values = _.map(data, function(clientGroup) {
                        return {
                            id: clientGroup.key,
                            text: clientGroup.value
                        };
                    });
                    self.loaded = true;

                    return self;
                },
                getDataCall: function(query) {
                    var params = {};
                    if (vm.alert.cluster && vm.alert.cluster.id) {
                        params.cluster_id = vm.alert.cluster.id;
                    }

                    if (query) {
                        params.q = query + '|name';
                    }

                    return ClientGroupFactory.getFieldValues('id', params);
                },
                onChange: function($el) {
                    var val = $el.select2('data');
                    vm.alert.client_group = val;
                }
            };
            vm.options.clientGroupSelectOptions = AppModelFactory.getSelectOptions(options);
        });
    }

    /**
     * Reset evaluation frequency
     */
    function resetEvaluationFrequency() {
        AlertFactory.resetEvaluationFrequency(vm.alert);
    }

    /**
     * Reset evaluation frequency offset
     */
    function resetEvaluationFrequencyOffset() {
        AlertFactory.resetEvaluationFrequencyOffset(vm.alert);
    }

    /**
     * Reset group by columns
     */
    function resetGroupBy() {
        vm.alert.groupbyColumns = [];
        vm.alert.grouping_fields = [];
    }

    /**
     * Reset condition
     */
    function resetCondition() {
        vm.alert.conditions = [];
        AlertFactory.addCondition(vm.alert.conditions);
        vm.alert.metrics = [];
    }

    /**
     * Determine which data source icon to display
     * @returns {string}
     */
    function getDataSourceIcon() {
        var currentDataSourceType = vm.alert.datasource.type;
        if (currentDataSourceType === 'service_data' || currentDataSourceType === 'category_data') {
            return DataSourceFactory.getDataSourceIcon(vm.alert.datasource);
        }
        // set default icon for alert
        return 'custom-icon icon-bell-o';
    }

    /**
     * Determine which data source color to display
     * @returns {string}
     */
    function getDataSourceColor() {
        return DataSourceFactory.getDataSourceColor(vm.alert.datasource);
    }

    /**
     * Helper function to remove alert
     * @param alert
     */
    function removeAlert(alert) {
        PubSub.emit($AlertEvents.REMOVE_WIDGET_ALERT, alert);
    }

    /**
     * Helper function to add condition
     */
    function addCondition() {
        if (_.isNil(vm.alert.conditions)) {
            vm.alert.conditions = [];
        }
        AlertFactory.addCondition(vm.alert.conditions);
        updateShowDeleteCondition();
        updateConditionMetricPrecision();
    }

    /**
     * Helper function to remove condition
     * @param condition
     */
    function removeCondition(condition) {
        AlertFactory.removeCondition(vm.alert.conditions, condition);
        updateShowDeleteCondition();
        updateConditionMetricPrecision();
    }

    /**
     * Helper function to update show delete condition button
     */
    function updateShowDeleteCondition() {
        vm.state.showDeleteCondition = vm.alert.conditions && vm.alert.conditions.length > 1;
    }

    /**
     * Helper function to add group by
     */
    function addGroupby() {
        if (_.isNil(vm.alert.grouping_fields)) {
            vm.alert.grouping_fields = [];
        }
        vm.alert.grouping_fields.push(createNewGroupby());
        updateShowDeleteGrounpby();
    }

    /**
     * Helper function to remove group by
     * @param groupby
     */
    function removeGroupby(groupby) {
        _.remove(vm.alert.grouping_fields, groupby);
        var values = {oldValue: groupby};
        PubSub.emit($AlertEvents.UPDATE_GROUPBY, values, true);
        updateShowDeleteGrounpby();
        updateGroupbyHasClient();
    }

    /**
     * Helper function to update show delete group by button
     */
    function updateShowDeleteGrounpby() {
        vm.state.showDeleteGroupby = !_.isEmpty(vm.alert.grouping_fields);
    }

    /**
     * Helper function to determine if client is selected in groupby columns
     */
    function updateGroupbyHasClient() {
        $scope.$evalAsync(function() {
            var originalValue = vm.state.hasClient;
            vm.state.hasClient = false;
            _.each(vm.alert.grouping_fields, function(groupingField) {
                if (groupingField.field === "customer_id") {
                    vm.state.hasClient = true;
                    return false; // break loop
                }
            });

            if (originalValue && !vm.state.hasClient && vm.alert.is_send_alert_to_client) {
                UIFactory.notify.showWarning(gettextCatalog.getString('Deleting the \'Evaluate Each\' Client option will disable the ability to email each client user. '));
            }

            if (!vm.state.hasClient) {
                vm.alert.is_send_alert_to_client = false;
            }
        });
    }

    /**
     * Helper function to create new groupby
     * @returns {{field: null, label: null}}
     */
    function createNewGroupby() {
        return {
            field: null,
            label: null
        };
    }
}

/**
 * Individual alert widget list item
 * @ngInject
 */
function AlertWidgetItemController(
    $scope,
    $AlertEvents,
    PubSub,
    DesignFactory,
    AlertFactory,
    AlertTimeFrame,
    AlertFrequency,
    AlertModelFactory,
    AppFactory,
    DataSourceFactory,
    WidgetFactory
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.canDisplayAlertHeader = canDisplayAlertHeader;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getDataSourceColor = getDataSourceColor;
    vm.removeAlert = removeAlert;
    vm.addCondition = addCondition;
    vm.removeCondition = removeCondition;
    vm.evaluationTimeframeChange = evaluationTimeframeChange;
    vm.evaluationTimeframeFrequencyChange = evaluationTimeframeFrequencyChange;

    vm.AlertTimeFrame = AlertTimeFrame;
    vm.AlertFrequency = AlertFrequency;
    vm.daysOfWeek = AlertFactory.getDaysOfWeek();
    vm.daysOfMonth = AlertFactory.getDaysOfMonth();
    vm.daysOfQuarter = AlertFactory.getDaysOfQuarter();
    vm.showFrequencyEveryDay = AlertFactory.showFrequencyEveryDay;
    vm.showFrequencyEveryWeek = AlertFactory.showFrequencyEveryWeek;
    vm.showFrequencyEveryMonth = AlertFactory.showFrequencyEveryMonth;
    vm.showFrequencyEveryQuarter = AlertFactory.showFrequencyEveryQuarter;
    vm.showEveryDayOfWeek = AlertFactory.showEveryDayOfWeek;
    vm.showEveryDayOfMonth = AlertFactory.showEveryDayOfMonth;
    vm.showEveryDayOfQuarter = AlertFactory.showEveryDayOfQuarter;

    function $onInit() {
        _registerEvents();

        updateShowDeleteCondition();
        updateConditionMetricPrecision();
        if (!vm.options.recipientSelectOptions) {
            vm.options.getUserData();
        }

        WidgetFactory.getWidget(vm.alert.widget_id, {}).then(function(data) {
            vm.alert.datasource = data.metadata.data_source;
            setMetricsColumns();
        });

        vm.options.recipientSelectOptions = AlertModelFactory.getRecipientOptions(vm.options.recipientSelectOptions);
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _registerEvents() {

    }

    function _unregisterEvents() {

    }

    function evaluationTimeframeChange() {
        resetEvaluationFrequency();
        resetEvaluationFrequencyOffset();
    }

    function evaluationTimeframeFrequencyChange() {
        resetEvaluationFrequencyOffset();
    }

    function setMetricsColumns() {
        vm.instructions = null;
        AlertFactory.setMetricsColumns(vm.alert, function() {
            updateConditionMetricPrecision();
        });
    }

    /**
     * Determines if alert header with datasource information can be displayed
     * @returns {boolean}
     */
    function canDisplayAlertHeader() {
        return vm.alert && !_.isNil(vm.alert.datasource);
    }

    /**
     * Determine which data source icon to display
     * @returns {string}
     */
    function getDataSourceIcon() {
        return DataSourceFactory.getDataSourceIcon(vm.alert.datasource);
    }

    /**
     * Determine which data source color to display
     * @returns {string}
     */
    function getDataSourceColor() {
        return DataSourceFactory.getDataSourceColor(vm.alert.datasource);
    }

    function removeAlert(alert) {
        PubSub.emit($AlertEvents.REMOVE_WIDGET_ALERT, alert);
    }

    /**
     * Helper function to add condition
     */
    function addCondition() {
        if (_.isNil(vm.alert.conditions)) {
            vm.alert.conditions = [];
        }
        AlertFactory.addCondition(vm.alert.conditions);
        updateShowDeleteCondition();
        updateConditionMetricPrecision();
    }

    /**
     * Helper function to remove condition
     * @param condition
     */
    function removeCondition(condition) {
        AlertFactory.removeCondition(vm.alert.conditions, condition);
        updateShowDeleteCondition();
        updateConditionMetricPrecision();
    }

    /**
     *  Helper function to update metric precision
     */
    function updateConditionMetricPrecision() {
        AlertFactory.updateMetricPrecision(vm.alert.metrics, vm.alert.conditions);
    }

    /**
     * Helper function to update show delete condition button
     */
    function updateShowDeleteCondition() {
        vm.state.showDeleteCondition = vm.alert.conditions && vm.alert.conditions.length > 1;
    }

    /**
     * Reset evaluation frequency
     */
    function resetEvaluationFrequency() {
        AlertFactory.resetEvaluationFrequency(vm.alert);
    }

    /**
     * Reset evaluation frequency offset
     */
    function resetEvaluationFrequencyOffset() {
        AlertFactory.resetEvaluationFrequencyOffset(vm.alert);
    }
}