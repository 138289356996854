import { ChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/ChartWidgetMetadata';
import { CombinationChartDrawOption } from '@/modules/ta/widget/models/drawoptions/CombinationChartDrawOption';

export class CombinationChartWidgetMetadata extends ChartWidgetMetadata {
  /**
   * @var {Array}
   */
  line_columns;

  constructor(model = {}) {
    super(model);

    this.line_columns = model.line_columns || {};
    this.draw_options = new CombinationChartDrawOption(model.draw_options);
  }
}
