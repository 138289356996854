export const AdvancedFilterType = {
  NUMERIC: 'numeric',
  STRING: 'string',
  MULTI_STRING: 'multi-string',
  DATE: 'date',
  DATE_RANGE: 'date_range',
  ENUM: 'enum',
  MULTI_SELECT: 'multi-select',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  TREE_SELECT: 'tree-select',
};

export const AdvancedFilterLimits = {
  ADVANCED_FILTERS_MAX_ROWS: 10000000,
  ADVANCED_FILTERS_MAX_CHIP_CHARS: 40,
  ADVANCED_FILTERS_MAX_KEYWORDS: 10,
};
