'use strict';
import angular from 'angular';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';


angular.module('widget.datagrid.models', [])

    .factory('DataGridModelFactory', DataGridModelFactory);

/**
 *
 * @ngInject
 */
function DataGridModelFactory(
    AppModelFactory,
    LayoutFactory,
    $http
) {
    return {
        getCopyOptions: getCopyOptions,
        getDeleteOptions: getDeleteOptions
    };

    /**
     *
     * @param model
     * @returns {SwalOptions}
     * @constructor
     */
    function CopyOptions(model) {
        model = model || {};

        model.text = 'You will copy <b>' + model.entityName + '</b>';
        model.html = true;
        model.showConfirmButton = true;
        model.showCancelButton = true;
        model.closeOnConfirm = false;
        model.closeOnCancel = false;
        model.callback = _.isUndefined(model.callback) ? _defaultCopyCallback : model.callback;

        var self = AppModelFactory.getSwalOptions.call(this, model);

        self.entityName = model.entityName || '';
        self.id = model.id || null;
        self.copyUrl = model.copyUrl || '';

        return self;
    }

    /**
     * @param shouldCopy
     * @private
     */
    function _defaultCopyCallback(shouldCopy) {
        if (!shouldCopy) {
            return;
        }
        var self = this;

        return $http.get(self.copyUrl).then(function (json) {
            if (!json.error) {
                // Default callback behavior
                LayoutFactory.$rebuildAllWidgets();
                swal('Copied!', self.entityName + ' has been copied', 'success');
            }
        }, function() {
            swal.enableButtons();
        });
    }

    /**
     *
     * @param model
     * @returns {CopyOptions}
     */
    function getCopyOptions(model) {
        return new CopyOptions(model);
    }

    /**
     *
     * @param model
     * @returns {SwalOptions}
     * @constructor
     */
    function DeleteOptions(model) {
        model = model || {};
        model.text = 'You will delete <b>' + model.entityName + '</b>';
        model.html = true;
        model.showConfirmButton = true;
        model.showCancelButton = true;
        model.closeOnConfirm = false;
        model.callback = _.isNil(model.callback) ? _defaultDeleteCallback : model.callback;

        var self = AppModelFactory.getSwalOptions.call(this, model);

        self.entityName = model.entityName || '';
        self.id = model.id || null;
        self.deleteUrl = model.deleteUrl || '';

        return self;
    }

    /**
     *
     * @param shouldDelete
     * @private
     */
    function _defaultDeleteCallback(shouldDelete) {
        if (!shouldDelete) {
            return;
        }
        var self = this;

        return $http.delete(self.deleteUrl).then(function (json) {
            if (!json.error) {
                LayoutFactory.$rebuildAllWidgets();
                swal('Deleted!', self.entityName + ' has been deleted', 'success');
            }
        }, function() {
            swal.enableButtons();
        });
    }

    /**
     *
     * @param model
     * @returns {DeleteOptions}
     */
    function getDeleteOptions(model) {
        return new DeleteOptions(model);
    }
}
