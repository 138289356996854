class VueXHelper {
  /**
   * @var {Vuex.Store}
   */
  store;

  /**
   * @var {{Object.<string, string[]>}}
   */
  actions;

  /**
   * @var {{Object.<string, string[]>}}
   */
  mutations;

  /**
   * @var {{Object.<string, string[]>}}
   */
  getters;

  constructor() {
    this.actions = {};
    this.mutations = {};
    this.getters = {};

    this.actionsMap = {};
    this.mutationsMap = {};
    this.gettersMap = {};
  }

  setStore(store) {
    this.store = store;
  }

  getStore() {
    return this.store;
  }

  setStoreModule(moduleName, store) {
    const { actions, mutations, getters } = store;

    if (actions) {
      this.setActions(moduleName, actions);
    }
    if (mutations) {
      this.setMutations(moduleName, mutations);
    }
    if (getters) {
      this.setGetters(moduleName, getters);
    }
  }

  setActions(moduleName, actions) {
    this.actions[moduleName] = actions;
    this.actionsMap[moduleName] = null;
  }

  setMutations(moduleName, mutations) {
    this.mutations[moduleName] = mutations;
    this.mutationsMap[moduleName] = null;
  }

  setGetters(moduleName, getters) {
    this.getters[moduleName] = getters;
    this.gettersMap[moduleName] = null;
  }

  moduleDispatches(module) {
    if (this.actionsMap[module]) {
      return this.actionsMap[module];
    }

    const self = this;
    const wrapper = {};
    Object.keys(this.actions[module] || {}).forEach((prop) => {
      wrapper[prop] = function (...args) {
        return self.store.dispatch(`${module}/${prop}`, ...args);
      };
    });
    this.actionsMap[module] = wrapper;

    return wrapper;
  }

  moduleCommits(module) {
    if (this.mutationsMap[module]) {
      return this.mutationsMap[module];
    }

    const self = this;
    const wrapper = {};
    Object.keys(this.mutations[module] || {}).forEach((prop) => {
      wrapper[prop] = function (...args) {
        return self.store.commit(`${module}/${prop}`, ...args);
      };
    });
    this.mutationsMap[module] = wrapper;

    return wrapper;
  }

  moduleGetters(module) {
    if (this.gettersMap[module]) {
      return this.gettersMap[module];
    }

    const self = this;
    const wrapper = {};
    Object.keys(this.getters[module] || {}).forEach((prop) => {
      const getter = this.getters[module][prop];
      wrapper[prop] = function (...args) {
        return getter(self.findModuleState(module), ...args);
      };
    });
    this.gettersMap[module] = wrapper;

    return wrapper;
  }

  /**
   * @param module
   * @returns {*}
   * @private
   */
  findModuleState(module) {
    if (module.includes('/')) {
      const moduleArray = module.split('/');
      let { state } = this.store;
      moduleArray.forEach((piece) => {
        state = state[piece];
      });
      return state;
    }
    return this.store.state[module];
  }
}

export default new VueXHelper();
