'use strict';
import angular from 'angular';
import './design.widget.constants';
import './design.widget.filters';
import './base/design.widget.ctrls';
import './base/design.widget.models';
import './base/design.widget.directives';
import './base/design.widget.components';
import './base/design.widget.services';
import './base/design.widget.data.services';
import './builder/widget.builder.module';
import './create/widget.create.module';
import './option/widget.option.module';
import './filter/widget.filter.module';
import './library/widget.library.module';
import './probe/widget.probe.module';
import './resize/widget.resize.module';
import './types/widget.type.module';
import '../widgetheader/widget.header.modal.services';
import '../widgetheader/widget.header.modal.directive';
import './products/product.widget.module';

angular.module('design.widget', [
    'design.widget.constants',
    'design.widget.ctrls',
    'design.widget.models',
    'design.widget.directives',
    'design.widget.components',
    'design.widget.services',
    'design.widget.data.services',
    'design.widget.filters',
    'widget.header.modal.services',
    'widget.header.modal.directive',

    'widget.create',
    'widget.option',
    'widget.filter',
    'widget.library',
    'widget.builder',
    'widget.probe',
    'widget.resize',
    'design.widget.product',
    // All the widget types' modules
    'widget.type'
]);