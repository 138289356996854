export const ChartType = {
  PIE_CHART: 'PieChart',
  LINE_CHART: 'LineChart',
  BAR_CHART: 'BarChart',
  BUBBLE_CHART: 'BubbleChart',
  FUNNEL_CHART: 'FunnelChart',
  GAUGE_CHART: 'GaugeChart',
  PYRAMID_CHART: 'PyramidChart',
  COMBO_CHART: 'ComboChart',
  XY_CHART: 'XYChart',
  SLICE_CHART: 'SliceChart',
  VENN_DIAGRAM: 'VennDiagram',
  PIE_TYPE: 'piechart',
};

export const Position = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
  ABSOLUTE: 'absolute',
  MIDDLE: 'middle',
  END: 'end',
};

export const SeriesType = {
  BAR: 'bar',
  BAR_3D: 'bar_3d',
  LINE: 'line',
  PYRAMID: 'pyramid',
  FUNNEL: 'funnel',
  PIE: 'pie',
  PIE_3D: 'pie_3d',
  VENN: 'venn',
  GEO: 'geo',
  COLUMN: 'column',
  PICTORIAL: 'pictorial(v2)',
};

export const ChartEvents = {
  SIZE_CHANGED: 'sizechanged',
  HIT: 'hit',
  READY: 'ready',
};

export const ChartAdapters = {
  FILL: 'fill',
  HIDDEN: 'hidden',
  TEXT: 'text',
  TOOLTIP_HTML: 'tooltipHTML',
  TOOLTIP_TEXT: 'getTooltipText',
  PIXEL_X: 'pixelX',
  VALUE: 'value',
};

export const FontStyle = {
  BOLD: 'bold',
  TEXT: 'text',
};

export const Patterns = {
  LINE_PATTERN: 'LinePattern',
};

export const ColorCodes = {
  WHITE_COLOR: '#FFFFFF',
  BLACK_COLOR: '#000000',
};
