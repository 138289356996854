'use strict';
import angular from 'angular';

angular.module('core.tag.values.resources', [])
    .factory('TagValuesResource', TagValuesResource)
    .factory('TagValuesDataGridFactory', TagValuesDataGridFactory);

/**
 * @ngInject
 */
function TagValuesResource(
    Restangular
) {
    var resource = Restangular.all('tagvalues');

    return {
        getResource: getResource,
        get: get,
        getData: getData
    };

    function get(tagId) {
        return resource.one(tagId).get({all: true});
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        queryParams.all = true;
        return resource.getList(queryParams);
    }

    /**
     * Getter for mainresource of class
     * @returns {*}
     */
    function getResource() {
        return resource;
    }
}

/**
 * @ngInject
 */
function TagValuesDataGridFactory(
    TagValuesColumns,
    TagValuesFactory,
    DataGridRender,
    TagUIResource
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};

        dtOptions.customRenders = {};
        dtOptions.numActions = 2;

        dtOptions.customRenders[TagValuesColumns.ID] = function (data, type, full) {
            var html = '';

            html = DataGridRender.getEditDetailsButton(TagValuesColumns.ID, full.can_be_edited);
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'tagvalues/' + data);

            return html;
        };

        dtOptions.customRenders[TagValuesColumns.TAG] = function (data, type, full) {
            return TagUIResource.renderTagHTML(data, full);
        };

        dtOptions.postCreatedRowCallback = function($link, row, data) {
            var field = $link.data('field');
            switch (field) {
                case TagValuesColumns.ID:
                    $link.click(function () {
                        TagValuesFactory.setEditDetails(data, row);
                    });
                    break;
            }
        };

        return dtOptions;
    }
}