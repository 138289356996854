export class UndoState {
  /**
   *
   * @type {*[]}
   */
  doStack = [];

  /**
   *
   * @type {null}
   */
  currentOperationPromise = null;

  /**
   *
   * @type {*[]}
   */
  undoStack = [];
}
