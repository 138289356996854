import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class DataSource extends BaseModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  name;

  /**
   * @var {string}
   */
  type;

  /**
   * @var {string}
   */
  color;

  /**
   * @var {string}
   */
  custom_color;

  /**
   * @var {string}
   */
  icon;

  /**
   * @var {string}
   */
  custom_icon;

  /**
   * @var {string}
   */
  service_icon;

  /**
   * @var {string}
   */
  data_view;

  /**
   * @var {string}
   */
  id_name;

  /**
   * @var {string}
   */
  data_view_name;

  /**
   * @var {boolean}
   */
  has_custom_icon;

  /**
   * @var {boolean}
   */
  requires_group_by;

  /**
   * @var {boolean}
   */
  requires_date_range;

  /**
   * @var {boolean}
   */
  is_of_type_service;

  /**
   * @var {boolean}
   */
  include_num_entities;

  /**
   * @var {boolean}
   */
  include_data_freshness_date;

  /**
   * @var {boolean}
   */
  is_custom_service;

  /**
   * @var {boolean}
   */
  has_live_integration;

  /**
   * @var {boolean}
   */
  active;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.name = model.name || model.text;
    this.type = model.type;
    this.color = model.color;
    this.custom_color = model.custom_color;
    this.icon = model.icon;
    this.custom_icon = model.custom_icon;
    this.data_view = model.data_view;
    this.id_name = model.id_name;
    this.data_view_name = model.data_view_name;
    this.icon = model.icon;
    this.service_icon = model.service_icon;
    this.has_custom_icon = model.has_custom_icon;
    this.requires_group_by = model.requires_group_by;
    this.requires_date_range = model.requires_date_range;
    this.is_of_type_service = model.is_of_type_service;
    this.include_num_entities = model.include_num_entities;
    this.include_data_freshness_date = model.include_data_freshness_date;
    this.is_custom_service = model.is_custom_service;
    this.has_live_integration = model.has_live_integration;
    this.geo_columns = model.geo_columns || {};
    this.active = model.active;
  }

  getIcon() {
    let icon = this.service_icon;

    if (this.custom_icon) {
      icon = this.custom_icon;
    } else if (this.icon) {
      if (this.icon.startsWith('icon-') || this.icon.startsWith('ti')) {
        return this.icon;
      }
      return `serviceicon-${this.icon}`;
    } else if (this.type === DataSourceType.CATEGORY_DATA) {
      return icon;
    }

    if (!this.service_icon?.startsWith('icon')) {
      icon = `serviceicon-${this.service_icon}`;
    }
    return icon;
  }

  /**
   * @returns {boolean}
   */
  isServiceData() {
    return this.type === DataSourceType.SERVICE_DATA;
  }

  /**
   * @returns {boolean}
   */
  isCategoryData() {
    return this.type === DataSourceType.CATEGORY_DATA;
  }

  /**
   * @returns {string}
   */
  getColor() {
    return this.type === DataSourceType.SERVICE_DATA && this.custom_color
      ? this.custom_color
      : this.color;
  }

  /**
   * @returns {string}
   */
  getName() {
    return this.id_name || this.name;
  }
}
