'use strict';
import angular from 'angular';

angular.module('serviceclientmapping.constants', [])

    .constant('ServiceClientMappingRouteParams', {
        SERVICE_CLIENT_MAPPING_RESULT: 'serviceclientmappingresult',
        SERVICE_CLIENT_MAPPING_LOG: 'serviceclientmappinglog'
    })

    .constant('ServiceClientMappingColumns', {
        ID: 'id',
        NAME: 'name',
        SERVICE_ID: 'service_id',
        DATA_SOURCE: 'data_source',
        SERVICE_FIELD: 'service_field',
        CLIENT_FIELD: 'client_field',
        TRUNCATE_SIZE: 'truncate_size'
    })

    .constant('ServiceClientMappingLogColumns', {
        ID: 'id',
        MAPPING_ID: 'mapping_id',
        MAPPING_NAME: 'mapping_name',
        START: 'start',
        END: 'end',
        RESULT: 'result',
        DETAILS: 'details',
        ACTIVE: 'active'
    })

    .constant('$ServiceClientMappingEvents', {
        IS_ADDING: '$ServiceClientMappingEvents:stateIsAdding',
        IS_RESULTS_ACTIVE: '$ServiceClientMappingEvents:stateIsResultsActive',
        IS_READY: '$ServiceClientMappingEvents:isReady',
        MAPPING_SELECT_READY: '$ServiceClientMappingEvents:mappingSelectReady',
        INIT_MODAL: '$ServiceClientMappingEvents:initModal',
        LOADING_STATE: '$ServiceClientMappingEvents:loadingState'
    });