'use strict';
import angular from 'angular';

export const $GoalsEvents = {
    IS_ADDING: '$GoalsEvents:stateIsAdding',
    IS_READY: '$GoalsEvents:isReady',
    INIT_MODAL: '$GoalsEvents:initModal',
    RESIZE: '$GoalsEvents:resize',
    LOADING_STATE: '$GoalsEvents:loadingState',
    CLEAR_FILTERS: '$GoalsEvents:clearFilters',
    CAMPAIGN_SELECTED: '$GoalsEvents:campaignSelected',
    DATA_SOURCE_SELECTED: '$GoalsEvents:dataSourceSelected'
};

export const GoalPace = {
    EXCELLENT: '100',
    GOOD: '90',
    AVERAGE: '70',
    POOR: '50',
    BAD: '0'
};

export const GoalsColumns = {
    ID: 'id',
    NAME: 'name',
    SERVICE_ID: 'service_id',
    CAMPAIGN_NAME: 'campaign_name',
    COLUMN: 'column',
    TARGET: 'target',
    START: 'start',
    END: 'end',
    COMPLETION: 'completion',
    PACE: 'pace'
};

export const GoalMetricType = {
    PACING: 'pacing',
    BUDGET: 'budget'
}

angular.module('goals.constants', [])
    .constant('GoalsColumns', GoalsColumns)
    .constant('GoalPace', GoalPace)
    .constant('$GoalsEvents', $GoalsEvents)
    .constant('GoalMetricType', GoalMetricType);
