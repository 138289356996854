'use strict';
import angular from 'angular';
import './dashboardfilterpanel.constants';
import './base/dashboardfilterpanel.directives';
import './base/dashboardfilterpanel.services';
import './base/dashboardfilterpanel.resource';
import './item/dashboardfilterpanel.item.directives';
import './item/dashboardfilterpanel.item.services';
import './header/dashboardfilterheader.directives';
import './header/dashboardfilterheader.services';

angular.module('page.dashboardfilterpanel', [
    'dashboardfilterpanel.constants',
    'dashboardfilterpanel.directives',
    'dashboardfilterpanel.services',
    'dashboardfilterpanel.resource',
    'dashboardfilteritem.directives',
    'dashboardfilteritem.services',
    'dashboardfilterheader.directives',
    'dashboardfilterheader.services'
]);