"use strict";
import $ from 'jquery';
import moment from 'moment';
import EventBus from '../../../../../grok/src/modules/core/app/helpers/EventBus';
import { OnboardingEvent } from '../../../../../grok/src/modules/core/onboarding/onboarding.constants';

var Core = $.core.reporting.builder = {
    loadingOptions: {},
    exportMenu: null,
    serializedSearchCriterias: null, //Used to see if any changes have been made to the search criterias and whether user needs to regen report
    initialize: function () {

        this.loadingOptions.top = 0;
        this.loadingOptions.left = 280;
        this.loadingOptions.timeout = 0;

        if ($.fn.isLocalHost()) {
            $('#view-report').show();
        }

        this.build();
        this.events();
    },
    build: function () {
        Core.initExportMenu();
        Core.initValidation();
    },
    events: function () {
        Core.updateReportBinding();
        Core.emailReportBindings();
        Core.exportBindings();
        Core.selectBindings();
        Core.initDateRangeBindings();
    },
    initExportMenu: function () {
        Core.exportMenu = $('#export-menu');
        Core.exportMenu.click(function() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).find('div').hide();
                Core.exportMenu.tooltip('enable');
            }
            else {
                $(this).addClass('active');
                $(this).find('div').show();
                Core.exportMenu.tooltip('disable');
            }
        });
    },
    initValidation: function () {

        //Validation
        var options = {};
        options.fields = {
            client_group_id: { //This validates client_id as well
                validators: {
                    callback: {
                        message: 'You need to select at least one of the 2 options above',
                        callback: function() {
                            return $('#client_id').val() != '' || $('#client_group_id').val() != '';
                        }
                    }
                }
            },
            client_report_from_date: {
                validators: {
                    callback: {
                        message: 'The date is not valid',
                        callback: function(value) {
                            if (value != null && value != '') {
                                var m = moment(value, 'MMM DD, YYYY', true);
                                if (!m.isValid()) {
                                    m = moment(value, 'MMM DD, YYYY', 'en');
                                    return m.isValid();
                                }
                            }
                            return true;
                        }
                    },
                    notEmpty: {
                        message: 'Start date is required'
                    }
                }
            },
            client_report_to_date: {
                validators: {
                    callback: {
                        message: 'The date is not valid',
                        callback: function(value) {
                            if (value != null && value != '') {
                                var m = moment(value, 'MMM DD, YYYY', true);
                                if (!m.isValid()) {
                                    m = moment(value, 'MMM DD, YYYY', 'en');
                                    return m.isValid();
                                }
                            }
                            return true;
                        }
                    },
                    notEmpty: {
                        message: 'End date is required'
                    }
                }
            },
            client_report_comparison_from_date: {
                validators: {
                    callback: {
                        message: 'The date is not valid',
                        callback: function(value) {
                            if (value != null && value != '') {
                                var m = moment(value, 'MMM DD, YYYY', true);
                                if (!m.isValid()) {
                                    m = moment(value, 'MMM DD, YYYY', 'en');
                                    return m.isValid();
                                }
                            }
                            return true;
                        }
                    }
                }
            },
            client_report_comparison_to_date: {
                validators: {
                    callback: {
                        message: 'The date is not valid',
                        callback: function(value) {
                            if (value != null && value != '') {
                                var m = moment(value, 'MMM DD, YYYY', true);
                                if (!m.isValid()) {
                                    m = moment(value, 'MMM DD, YYYY', 'en');
                                    return m.isValid();
                                }
                            }
                            return true;
                        }
                    }
                }
            }
        };
        $.core.main.initFormValidator($('#main-form'), options);
    },
    updateReportBinding: function () {
        $('#update-report').click(function() {
            var $this = $(this);
            var $form = $('#main-form');
            if ($.core.main.validateForm($form)) {

                if ($('#compare-to-checkbox').is(':checked')) {
                    $('#client_report_from_date').addClass('current');
                    $('#client_report_to_date').addClass('current');
                    $('#client_report_comparison_from_date').addClass('prior');
                    $('#client_report_comparison_to_date').addClass('prior');
                }
                else {
                    $('#client_report_from_date').removeClass('current');
                    $('#client_report_to_date').removeClass('current');
                    $('#client_report_comparison_from_date').removeClass('prior');
                    $('#client_report_comparison_to_date').removeClass('prior');
                }

                $this.disableElement();
                Core.loadingOptions.text = 'Generating report';
                $.core.main.showAjaxMainLoading(Core.loadingOptions);

                Core.serializedSearchCriterias = $form.serialize();

                $.post($.fn.actionUrl('reporting/update_report'), Core.serializedSearchCriterias + '&report_format=html', function (res) {

                    $this.enableElement();
                    var isNew = $this.html() === 'Generate report';
                    $this.html('Update report');
                    $.core.main.hideAjaxMainLoading();
                    var $reportPlaceholder = $('#report-placeholder');

                    // the string returned from the backend is the html of the report
                    if (typeof res === 'string') {
                        Core.exportMenu.show();
                        $reportPlaceholder.html(res);
                        $.core.reporting.html.initialize();
                        if(window.isNUI) {
                            EventBus.signal(OnboardingEvent.CREATE_REPORT);
                        }
                    }
                    // the data.html returned from the backend contains the message to export (too large)
                    else if (typeof res === 'object' && res.status == 'success') {
                        Core.exportMenu.hide();
                        $reportPlaceholder.html(res.data);
                        Core.exportBindings();
                        Core.emailReportBindings();
                        if(window.isNUI) {
                            EventBus.signal(OnboardingEvent.CREATE_REPORT);

                        }
                    }
                    if (window.isNUI) {
                        var form = $form.serializeObject();
                        form.client = !!form.client_id ? $('#select2-chosen-1').text() : '';
                        form.client_group = !!form.client_group_id ? $('#client_group_id option:selected').text() : '';
                        form.cover_back_page = $('#reporting_template_id option:selected').text();
                        form.data_profile = $('#reporting_profile_id option:selected').text();
                        if (isNew) {
                            EventBus.signal("QuickReportGenerateEvent", {quickreport: form});
                        } else {
                            EventBus.signal("QuickReportUpdateEvent", {quickreport: form});
                        }
                    }
                });
            }
        });


        $('#view-report').click(function() {
            var $form = $('#main-form');
            if ($.core.main.validateForm($form)) {
                window.open($.fn.actionUrl('reporting/export_report?') + $form.serialize() + '&view=1', '_blank');
            }
        });

    },
    exportBindings: function () {
        $('div.export').off('click').on('click', function() {
            var $form = $('#main-form');
            var type = $(this).data('type');
            if ($.core.main.validateForm($form)) {
                if (!Core.reportRequiresRefresh()) {
                    $('#update-report').disableElement();
                    Core.loadingOptions.text = 'Exporting to ' + type;
                    $.core.main.showAjaxMainLoading(Core.loadingOptions);
                    $.fileDownload($.fn.actionUrl('reporting/export_report'), {
                        data: $form.serialize() + '&report_format=' + type,
                        successCallback: function (url) {
                            $.core.main.hideAjaxMainLoading();
                            $('#update-report').enableElement();
                            if (window.isNUI) {
                                EventBus.signal("QuickReportExportEvent", {quickreport: {type}});
                            }
                        },
                        failCallback: function (html, url) {
                            $.core.main.hideAjaxMainLoading();
                            $('#update-report').enableElement();

                            alert('Your file download just failed for this URL:' + url + '\r\n' +
                                'Here was the resulting error HTML: \r\n' + html
                            );
                        }
                    });
                }
            }
        });
    },
    emailReportBindings: function () {
        var $modal = $('#email-report-modal');
        var $form = $('#main-form');
        var $emailForm = $('#email-report-form');
        var reportTitle = '';

        //Validation
        var options = {};
        options.fields = {
            email_report_address: {
                validators: {
                    emailAddress: {
                        multiple: true,
                        separator: /[,]\s*/
                    },
                    notEmpty: {
                        message: 'Email address is required'
                    }
                }
            },
            email_report_subject: {
                validators: {
                    notEmpty: {
                        message: 'Subject is required'
                    }
                }
            }
        };

        $.core.main.initFormValidator($emailForm, options);

        $('div.email').off('click').on('click', function() {
            if ($.core.main.validateForm($form)) {
                if (!Core.reportRequiresRefresh()) {
                    $.core.main.showModal($modal);

                    //Set subject from report title
                    reportTitle = $('#report-title').text().trim();
                    $('#email_report_subject').val(reportTitle);
                }
            }
        });

        $('#email-report-confirm').off('click').on('click',function() {
            if ($.core.main.validateForm($emailForm)) {
                var $this = $(this);
                $this.html('Sending...').disableElement();
                $.post($.fn.actionUrl('reporting/email_report'), $emailForm.serialize() + '&' + $form.serialize() + '&report_format=pdf', function (json) {
                    $this.html('Send').enableElement();
                    if (json.status == 'success') {
                        $.core.main.notify('Report was successfully sent', $.globals.notify.success, {title: reportTitle});
                        if (window.isNUI) {
                            EventBus.signal("QuickReportExportEvent", {quickreport: {type: 'email', ...$emailForm.serializeObject()}});
                        }
                    }
                    else {
                        $.core.main.formErrorNotify(json.error);
                    }
                });
            }
        });
    },
    selectBindings: function () {
        //If one is selected, unselect the other
        $('#client_id').on('change', function() {
            $('#client_group_id').val('');
        });

        $('#client_group_id').change(function() {
            $('#client_id').select2('val', '');
        });
    },
    initDateRangeBindings: function () {

        var $rangeMenu = $('#date-range-menu');
        var $start = $('#client_report_from_date');
        var $end = $('#client_report_to_date');

        var $compareCheckbox = $('#compare-to-checkbox');

        var $comparisonSelect = $('#compare-to-select');
        var $comparisonStart = $('#client_report_comparison_from_date');
        var $comparisonEnd = $('#client_report_comparison_to_date');

        var $groupByMonth = $('#group_by_month');

        var options = {};
        options.isDateRange = true;

        //Report start/end date
        options.onClose = function( selectedDate ) {
            $end.datepicker("option", "minDate", selectedDate);
            if ($end.val() == '') {
                $end.val(selectedDate);
            }

            //Update comparison period if needed
            updatePeriod(selectedDate);
        };
        $.core.main.initDatePicker($start, options);

        options.onClose = function( selectedDate ) {
            $start.datepicker( "option", "maxDate", selectedDate );

            //Update comparison period if needed
            updatePeriod(selectedDate);
        };
        $.core.main.initDatePicker($end, options);


        //Optional: Compare report start/end date
        options.beforeShowDay = disableCurrentRange;
        options.onClose = function( selectedDate ) {
            $comparisonEnd.datepicker("option", "minDate", selectedDate);
        };
        $.core.main.initDatePicker($comparisonStart, options);

        options.beforeShowDay = disableCurrentRange;
        options.onClose = function( selectedDate ) {
            $comparisonStart.datepicker( "option", "maxDate", selectedDate );
        };
        $.core.main.initDatePicker($comparisonEnd, options);


        //Disable current date range
        function disableCurrentRange(date) {
            var startDate = $start.val();
            if (date >= new Date(startDate)) {
                return [false, 'highlight'];
            }
            return [true, ''];
        }

        //Remove readonly state and reset custom date range on click
        $start.click(function() {
            resetState($rangeMenu, $start, $end);
        });

        $end.click(function() {
            resetState($rangeMenu, $start, $end);
        });

        $compareCheckbox.click(function() {
            if($(this).is(':checked')) {
                $comparisonSelect.enableElement();
                $comparisonStart.enableElement();
                $comparisonEnd.enableElement();
                $groupByMonth.disableElement();
                $groupByMonth.attr('checked', false);

                //Set the previous period by default
                calculatePriorPeriod($start.val());
            }
            else {
                $comparisonSelect.disableElement();
                $comparisonStart.disableElement();
                $comparisonEnd.disableElement();
                $groupByMonth.enableElement();

                //Reselect previous period by default
                $comparisonSelect.find('option[value="priorPeriod"]').prop('selected', true);
            }
        });

        //All disabled by default
        $comparisonSelect.disableElement();
        $comparisonStart.disableElement();
        $comparisonEnd.disableElement();

        $comparisonStart.click(function() {
            resetState($comparisonSelect, $comparisonStart, $comparisonEnd);
        });

        $comparisonEnd.click(function() {
            resetState($comparisonSelect, $comparisonStart, $comparisonEnd);
        });


        //Set date ranges to selected date range
        $rangeMenu.change(function() {
            updateRange($(this), $start, $end);
        });

        function updateRange($rangeMenu, $start, $end) {
            if ($rangeMenu.val() != 'custom') {
                var date = $rangeMenu.val().split(';');
                var startDate = date[0];
                var endDate = date[1];
                $start.val(startDate);
                $end.val(endDate);
                $start.attr('readonly', true);
                $end.attr('readonly', true);

                //Update comparison period if needed
                updatePeriod($start.val());
            }
            else {
                resetState($rangeMenu, $start, $end);
            }
        }

        //Make datepicker readable again
        function resetState($rangeMenu, $start, $end) {
            $start.attr('readonly', false);
            $end.attr('readonly', false);
            $rangeMenu.find('option[value="custom"]').prop('selected', true);
        }

        $comparisonSelect.change(function() {
            var value = $(this).val();
            if (value == 'custom') {
                $comparisonStart.attr('readonly', false);
                $comparisonEnd.attr('readonly', false);
            }
            else if (value == 'priorPeriod') {
                calculatePriorPeriod($start.val());
            }
            else if (value == 'priorYear') {
                calculatePriorYear();
            }
        });

        //Calculate and set prior period based on current date range
        function calculatePriorPeriod(startDate) {
            if ($rangeMenu.val() != 'custom')
            {
                var date = $rangeMenu.val().split(';');
                $comparisonEnd.val(date[3]);
                $comparisonEnd.attr('readonly', true);

                $comparisonStart.val(date[2]);
                $comparisonStart.attr('readonly', true);
            }
            else if (startDate != '') {
                var endDate = $end.val();
                var numDaysDiff = Math.round((Date.parse(endDate) - Date.parse(startDate)) / (1000 * 60 * 60 * 24));

                if ($comparisonStart != null && $comparisonEnd != null) {
                    $comparisonEnd.val(Globalize.format(Date.parse(startDate).addDays(-1), 'MMM dd, yyyy'));
                    $comparisonEnd.attr('readonly', true);

                    $comparisonStart.val(Globalize.format(Date.parse($comparisonEnd.val()).addDays(-(numDaysDiff == 0 ? 1 : numDaysDiff)), 'MMM dd, yyyy'));
                    $comparisonStart.attr('readonly', true);
                }
            }
        }

        //Calculate and set prior year based on current date range
        function calculatePriorYear() {
            var startDate = $start.val();
            var endDate = $end.val();

            if ($comparisonStart != null && $comparisonEnd != null) {
                $comparisonEnd.val(Globalize.format(Date.parse(endDate).addYears(-1), 'MMM dd, yyyy'));
                $comparisonEnd.attr('readonly', true);

                $comparisonStart.val(Globalize.format(Date.parse(startDate).addYears(-1), 'MMM dd, yyyy'));
                $comparisonStart.attr('readonly', true);
            }
        }

        function updatePeriod(startDate) {
            if($compareCheckbox.is(':checked')) {
                var value = $comparisonSelect.val();
                if (value == 'priorPeriod') {
                    calculatePriorPeriod(startDate);
                }
                else if (value == 'priorYear') {
                    calculatePriorYear();
                }
            }
        }
    },
    reportRequiresRefresh: function () {
        var currentSerializedForm = $('#main-form').serialize();
        var priorSerializedForm = Core.serializedSearchCriterias;

        var $modal = $('#changes-made-modal');
        //No changes have been made yet
        if (priorSerializedForm == null) {
            $modal.find('div.modal-body').html('Your report has not yet been generated. Please click the "Generate report" button to reflect your changes in the report before selecting an export option.');
            $.core.main.showModal($modal);
            return true;
        }
        //Changes were made
        else if (currentSerializedForm != priorSerializedForm) {
            $modal.find('div.modal-body').html('Changes were made to your search criteria. Please click the "Update report" button to reflect your changes in the report before selecting an export option.');
            $.core.main.showModal($modal);
            return true;
        }
        else {
            return false;
        }
    }
};