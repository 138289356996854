import { pickBy, includes, omitBy, isNil } from 'lodash';

/**
 * Returns only required properties from an object
 * @param obj
 * @param keys
 * @returns {Object}
 */
export function pickOnly(obj, keys) {
  return pickBy(obj, (value, key) => includes(keys, key));
}

/**
 * Remove properties with undefined/null value form an object
 * @param obj
 * @returns {any}
 */
export function omitNils(obj) {
  return omitBy(obj, isNil);
}
