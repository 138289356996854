'use strict';
import angular from 'angular';

angular.module('marginrule.services', [])

    .factory('MarginRuleService', MarginRuleService);

//
// Margin rule factory
//
MarginRuleService.$inject = ['$rootScope', '$http'];
function MarginRuleService($rs, $http) {
    function myFunction() {

    }

    return {
        myFunction: myFunction
    }
}