import { isNil } from 'lodash';
import {
  WidgetFilterAssociation,
  WidgetFilterConditionType,
} from '@/modules/ta/widget/widget.constants';

export class WidgetFilter {
  /**
   * @var {string}
   */
  field;

  /**
   * @var {string}
   */
  groupby_id_field;

  /**
   * @var {string}
   */
  format;

  /**
   * @var {string}
   */
  label;

  /**
   * @var {string}
   */
  type;

  /**
   * @var {number}
   */
  order;

  /**
   * @var {boolean}
   */
  is_metric;

  /**
   * @var {string}
   */
  association;

  /**
   * @var {boolean}
   */
  expose_as_dash_filter;

  /**
   * @var {boolean}
   */
  limit_available_values;

  /**
   * @var {object|array}
   */
  values;

  constructor(model = {}) {
    this.setField(model);
    this.type = model.type;
    this.order = model.order;
    this.association = model.association;
    this.expose_as_dash_filter = model.expose_as_dash_filter;
    this.limit_available_values = model.limit_available_values;
    this.values = model.values;
  }

  /**
   * @param order
   * @returns {WidgetFilter}
   */
  static getDefaultFilter(order) {
    return new WidgetFilter({
      order,
      association: WidgetFilterAssociation.AND,
    });
  }

  isAndAssociation() {
    return this.association === WidgetFilterAssociation.AND;
  }

  isOrAssociation() {
    return this.association === WidgetFilterAssociation.OR;
  }

  setDefaultValues() {
    if (isNil(this.is_metric) || isNil(this.type)) {
      this.values = null;
    } else if (this.is_metric) {
      this.values = { min: null, max: null };
    } else if (!Array.isArray(this.values)) {
      this.values = [];
    }
  }

  setField(fieldProperties = {}) {
    this.field = fieldProperties.field;
    this.groupby_id_field = fieldProperties.groupby_id_field;
    this.format = fieldProperties.format;
    this.label = fieldProperties.label;
    this.is_metric = fieldProperties.is_metric;
    this.values = null;
  }

  inferMetricConditionType() {
    const { min, max } = this.values;
    if (!isNil(min) && isNil(max)) {
      return WidgetFilterConditionType.GT;
    }
    if (isNil(min) && !isNil(max)) {
      return WidgetFilterConditionType.LT;
    }
    if (!isNil(min) && !isNil(this.values.max)) {
      return min === max ? WidgetFilterConditionType.EQ : WidgetFilterConditionType.BTW;
    }
  }

  inferAttributeConditionType() {
    if (this.type === WidgetFilterConditionType.IN) {
      return WidgetFilterConditionType.IN;
    }
    return WidgetFilterConditionType.NIN;
  }

  inferConditionType() {
    if (isNil(this.field) || isNil(this.type)) {
      return null;
    }
    return this.is_metric ? this.inferMetricConditionType() : this.inferAttributeConditionType();
  }

  /**
   * @returns {boolean}
   */
  isOfTypeEQ() {
    return this.inferConditionType() === WidgetFilterConditionType.EQ;
  }

  /**
   * @returns {boolean}
   */
  isOfTypeGT() {
    return this.inferConditionType() === WidgetFilterConditionType.GT;
  }

  /**
   * @returns {boolean}
   */
  isOfTypeLT() {
    return this.inferConditionType() === WidgetFilterConditionType.LT;
  }

  /**
   * @returns {boolean}
   */
  isOfTypeBTW() {
    return this.inferConditionType() === WidgetFilterConditionType.BTW;
  }

  /**
   * @returns {boolean}
   */
  isOfTypeIN() {
    return this.inferConditionType() === WidgetFilterConditionType.IN;
  }

  /**
   * @returns {boolean}
   */
  isOfTypeNIN() {
    return this.inferConditionType() === WidgetFilterConditionType.NIN;
  }

  /**
   * @returns {boolean}
   */
  isOfTypeInOrNin() {
    return this.isOfTypeIN() || this.isOfTypeNIN();
  }
}
