'use strict';
import angular from 'angular';
import $ from 'jquery';

import libraryConnectedBadgeHtmlUrl from './library.connected.badge.html';
import libraryDetailedLeftHtmlUrl from './library.detailed.left.html';
import libraryDetailedFooterHtmlUrl from './library.detailed.footer.html';
import libraryPublishFormHtmlUrl from './library.publish.form.html';
import libraryDetailedWidgetHtmlUrl from './library.detailed.widget.html';
import _ from "lodash";

angular.module('core.library.components', [])
    .component('libraryConnectedBadge', {
        bindings: {
            isConnected: '<',
            tooltipText: '<'
        },
        templateUrl: libraryConnectedBadgeHtmlUrl,
        controllerAs: 'vm',
        controller: LibraryConnectedBadgeController
    })
    .component('libraryDetailedLeft', {
        bindings: {
            goBack: '<',
            tags: '<'
        },
        templateUrl: libraryDetailedLeftHtmlUrl,
        controllerAs: 'vm'
    })
    .component('libraryDetailedFooter', {
        bindings: {
            isLoading: '<',
            isConnected: '<',
            getDownloadText: '<',
            useItemAsTemplate: '<',
            goToConnectServices: '<'
        },
        templateUrl: libraryDetailedFooterHtmlUrl,
        controllerAs: 'vm'
    })
    .component('libraryPublishFormBefore', {})
    .component('libraryPublishFormAfter', {})
    .component('libraryPublishForm', {
        bindings: {
            model: '<?',
            tags: '<',
            state: '<',
            onCancel: '<',
            onSave: '<'
        },
        transclude: {
            formBefore: '?libraryPublishFormBefore',
            formAfter: '?libraryPublishFormAfter',
        },
        templateUrl: libraryPublishFormHtmlUrl,
        controller: LibraryPublishFormController,
        controllerAs: 'vm'
    })
    .component('libraryDetailedWidget', {
        bindings: {
            widget: '<',
            state: '<?'
        },
        templateUrl: libraryDetailedWidgetHtmlUrl,
        controller: LibraryWidgetContainerController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function LibraryPublishFormController(
    LibraryPublishModelFactory,
    TagPickerSelectConstant,
    WidgetHeaderModalFactory,
    WidgetFactory,
    PubSub,
    $WidgetHeaderEvents
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    /**
     * @type {LibraryPublishTagPickerSelectOptions}
     */
    vm.selectOptions = LibraryPublishModelFactory.getLibraryPublishTagPickerSelectOptions();

    /**
     * @type {LibraryPublishItemFormModel}
     */
    vm.model = LibraryPublishModelFactory.getLibraryPublishItemFormModel(vm.model);

    vm.selectCB = selectCB;
    vm.onTextChangeCallback = onTextChangeCallback;
    vm.openWidgetHeaderModal = openWidgetHeaderModal;
    vm.getWidgetPlainTitle = WidgetFactory.getWidgetPlainTitle;

    /**
     * Callback on select2 change
     */
    function selectCB($el, event) {
        var data = $el.select2('data');

        if (event.added) {
            if (event.added.id === TagPickerSelectConstant.PLACEHOLDER_ID && _.isEmpty(vm.model.searchText)) {
                // placeholder was pressed with no text entered
                _.remove(data, {id: TagPickerSelectConstant.PLACEHOLDER_ID });
                $el.select2('data', data);
                return;
            } else if (event.added.id === TagPickerSelectConstant.PLACEHOLDER_ID && !_.isEmpty(vm.model.searchText)) {
                // placeholder was selected with text entered
                _.remove(data, {id: TagPickerSelectConstant.PLACEHOLDER_ID });
                data.push({id: vm.model.searchText, text: vm.model.searchText});
                $el.select2('data', data);
                vm.model.tags.push(vm.model.searchText);
            } else {
                vm.model.tags.push(event.added.text);
            }
        } else if (event.removed) {
            _.remove(vm.model.tags, function (tag) { return tag === event.removed.text })
        }
        vm.selectOptions.didSelect();
    }

    /**
     * Callback when select2 text box changes
     * @param text
     */
    function onTextChangeCallback(text) {
        vm.model.searchText = text;
    }

    function openWidgetHeaderModal() {
        WidgetHeaderModalFactory.initModal(vm.model, vm.model);
    }

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _registerEvents() {
        PubSub.on($WidgetHeaderEvents.REFRESH_TITLE, _updateLibraryWidgetTitle);
    }

    function _unregisterEvents() {
        PubSub.on($WidgetHeaderEvents.REFRESH_TITLE, _updateLibraryWidgetTitle);
    }

    function _updateLibraryWidgetTitle(newTitle) {
        if (!_.isNull(vm.model)) {
            vm.model.title = newTitle;
        }
    }

}

/**
 * @ngInject
 */
function LibraryWidgetContainerController(
    WidgetFactory,
    WidgetLibraryFactory
) {
    var vm = this;
    vm.$onInit = $onInit;

    function $onInit() {
        if (_.isUndefined(vm.state)) {
            vm.state = WidgetFactory.getDefaultState();
            WidgetLibraryFactory.setDefaultWidgetState(vm.state);
        }
    }
}

/**
 * @ngInject
 */
function LibraryConnectedBadgeController(
) {
    var vm = this;
    vm.getBadgeType = getBadgeType;

    function getBadgeType() {
        return vm.isConnected ? 'success' : 'warning';
    }
}