'use strict';
import angular from 'angular';

angular.module('widget.goal.resources', [])

    .factory('WidgetGoalResource', WidgetGoalResource);

/**
 * @ngInject
 */
function WidgetGoalResource(
    WidgetDataSourceFactory
) {
    return {
        getData: getData
    };

    /**
     * Returns data for Goal Widget
     * @param widget
     * @returns {{}}
     */
    function getData(widget) {
        return WidgetDataSourceFactory.getData(widget, {aggregate: true}, null);
    }
}