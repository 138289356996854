export class ExportState {
  /**
   *
   * @type {boolean}
   */
  isExporting = false;

  /**
   * Server side variables
   * @type {ExportServerConfig}
   */
  config;

  /**
   * backup of window.exportReady- useful for debugging
   * @type {boolean}
   */
  exportReady = false;
}
