import { commits } from '@/modules/core/app/helpers/store';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { WidgetEvent } from '@/modules/ta/widget/widget.constants';

export const actions = {
  setDrillDownState({}, drillDownState) {
    commits.dashboardDrilldown.setDrillDownState(drillDownState);
  },
  setWidgetConfig({}, widget) {
    commits.dashboardDrilldown.setWidget(widget);
  },
  setDrilldownConfig({}, config) {
    commits.dashboardDrilldown.updateWidgetConfig(config);
  },
  reloadWidget({}, id) {
    EventBus.signal(`${WidgetEvent.RELOAD_WIDGET}-${id}`);
  },
  reloadChart() {
    EventBus.signal(`${WidgetEvent.RELOAD_CHART}`);
  },
  undoDrilldown({}, id) {
    commits.dashboardDrilldown.undoDrilldown(id);
  },
  removeWidget({}, id) {
    commits.dashboardDrilldown.removeWidget(id);
  },
};
