'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';

angular.module('core.alert.resources', [])
    .factory('AlertResource', AlertResource)
    .factory('AlertDataGridFactory', AlertDataGridFactory)
    .factory('AlertEnumFactory', AlertEnumFactory);

/**
 * @ngInject
 */
function AlertResource(
    Restangular,
    ColumnValueType
) {
    var alerts = Restangular.all('alerts');
    var vars = alerts.one('vars');
    var values = alerts.one('values');
    var enums = Restangular.all('enums');

    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        return alerts.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getFieldValues(fields) {
        return values.one(ColumnValueType.DISTINCT).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        queryParams.all = true;
        return alerts.getList(queryParams);
    }

    /**
     *
     * @param model
     * @returns {*}
     */
    var save = function(alert) {
        return _.isNull(alert.id) ? alerts.post(alert) : alerts.all(alert.id).post(alert);
    };

    /**
     * Saves multiple alerts at once
     * @param list
     * @returns {*}
     */
    var saveList = function(list) {
        return alerts.all('savelist').post(list);
    }

    /**
     * @param id
     * @returns {*}
     */
    var remove = function(id) {
        return alerts.all(id).remove();
    };

    return {
        alerts: alerts,
        vars: vars,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData,
        save: save,
        saveList: saveList,
        remove: remove
    }
}

/**
 * @ngInject
 */
function AlertDataGridFactory(
    $AlertEvents,
    AlertFactory,
    AlertColumns,
    DataGridRender,
    PubSub
) {
    return {
        getDTOptions: getDTOptions,
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.numActions = 3;
        dtOptions.customRenders[AlertColumns.ID] = function (data, type, full) {
            var html = '';

            html += DataGridRender.getEditButton(full.can_be_edited, 'alerts/' + data, full['name'], false);
            html += DataGridRender.getCopyButton(full.can_be_copied, 'alerts/copy/' + data, full['name']);
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'alerts/' + data, full['name']);

            return html;
        };

        dtOptions.customRenders[AlertColumns.PAGE_TITLE] = function (data, type, full) {
            // WIDGET_METADATA can be empty if a widget was deleted but not the alert
            if (!full[AlertColumns.IS_WIDGET_ALERT] || !full[AlertColumns.WIDGET_METADATA]) {
                return ;
            }
            var html = '';

            html += '<a href="#/dash/' + full[AlertColumns.PAGE_ID] +'">' + full[AlertColumns.PAGE_TITLE] + '</a>'
                + ' > ' + full[AlertColumns.LAYOUT_TITLE] + ' > ' + full[AlertColumns.WIDGET_TITLE];

            return html;
        };

        dtOptions.customRenders[AlertColumns.CLUSTER_NAME] = function (data, type, full) {
            // Don't display business unit if there is client/client group
            if (full[AlertColumns.CLIENT_NAME] || full[AlertColumns.CLIENT_GROUP_NAME]) {
                return ;
            }
            return data;
        };

        //adding created row
        dtOptions.fnCreatedRow = function(row, data) {
            $(row).find('td.action-cell a.btn').each(function () {
                if ($(this).hasClass('delete-action')) {
                    DataGridRender.bindDeleteRowAction($(this));
                }
                else if ($(this).hasClass('copy-action')) {
                    DataGridRender.bindCopyRowAction($(this));
                }
                else if ($(this).hasClass('edit-action')) {
                    $(this).click(function() {
                        PubSub.emit($AlertEvents.INIT_ALERT, angular.copy(data), true);
                    });
                }
            });
        };

        return dtOptions;
    }
}

/**
 * @ngInject
 */
function AlertEnumFactory(
    Restangular
) {
    var enums = Restangular.all('enums');
    var evaluationTimeframeType = enums.one('evaluationtimeframetype');
    var alertAggregationType = enums.one('alertaggreationtype');
    var operatorType = enums.one('operatortype');
    var rowAlertCriteriaType = enums.one('rowalertcriteriatype');

    function getAlertAggregationType() {
        return alertAggregationType.get().then(function(json) {
            return json.plain();
        });
    }

    return {
        evaluationTimeframeType: evaluationTimeframeType,
        alertAggregationType: alertAggregationType,
        operatorType: operatorType,
        rowAlertCriteriaType: rowAlertCriteriaType,
        getAlertAggregationType: getAlertAggregationType,
    };
}
