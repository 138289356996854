import UserResource from '@/modules/ta/user/resources/UserResource';
import SegmentService from '@/modules/core/app/services/SegmentService';
import UserImageResource from '@/modules/ta/user/resources/UserImageResource';
import { BaseFormService } from '@/modules/core/form/services/BaseFormService';
import { UserChangeUserImage } from '@/modules/core/nav/usermenu.trackevents';
import DocumentUIService from '@/modules/core/app/services/DocumentUIService';
import { dispatches } from '@/modules/core/app/helpers/store';
import { clearI18nCache } from '@/i18n';

export class UserFormService extends BaseFormService {
  constructor() {
    super(UserResource);
  }

  /**
   * @param form {BaseForm}
   * @returns {Promise<*>}
   */
  async saveForm(form) {
    // This can be removed once OUI default role is removed from all users TA-34037
    if (form.data.role_id === '1') {
      form.data.role_name = null;
    }

    // use nui should not change while editing user unless new user
    if (!form.isNew() && form.data.id) {
      delete form.data.use_nui;
    }

    const result = await super.saveForm(form);
    dispatches.session.updateUserSettings();
    return result;
  }

  /**
   *
   * @returns {Promise<*>}
   */
  async getImpersonationUserList(queryParams) {
    return UserResource.getImpersonationUserList(queryParams);
  }

  async getReportLanguageList(queryParams) {
    return UserResource.getReportLanguageList(queryParams);
  }

  async setReportLanguage(language) {
    await clearI18nCache();
    const response = await UserResource.setCurrentUserReportLanguage({ report_language: language });
    if (response?.custom_redirect) {
      window.location.hash = response.custom_redirect;
    }
    DocumentUIService.reloadApp();
  }

  async changeUserImage(image) {
    const result = await UserImageResource.setUserImage(image);
    SegmentService.track(UserChangeUserImage.build());
    return result;
  }

  /**
   * edit settings requires a small subset of user form and is passed to a different route
   * @param form
   * @returns {Promise<*>}
   */
  async saveEditSettingsForm(form) {
    const formData = {
      current_password: form.data.current_password,
      password: form.data.password,
      repeat_password: form.data.repeat_password,
      default_home_page: form.data.default_home_page,
    };

    return UserResource.saveEditSettings(formData);
  }

  async updateLegacyAppPreference(feedback) {
    const preference = {
      use_nui: false,
    };

    if (feedback) {
      preference.nui_feedback = feedback;
    }
    return UserResource.saveLegacyAppPreference(preference);
  }

  /**
   *
   * @param params
   * @returns {Promise<axios.AxiosResponse<*>>}
   */
  async getUserRevokeGranularPermissionsMessage(params) {
    return UserResource.getUserRevokeGranularPermissionsMessage(params);
  }
}

export default new UserFormService();
