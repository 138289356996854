'use strict';
import angular from 'angular';

angular.module('exportbuilder.managelibrary.ctrls', [])
    
    .controller('ExportBuilderDashController', ExportBuilderDashController);


/**
 * @ngInject
 */
function ExportBuilderDashController (
) {
}