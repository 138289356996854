'use strict';
import angular from 'angular';
import $ from 'jquery';
import './base/industrybenchmark.ctrls';
import './base/industrybenchmark.resources';

import dashindustrybenchmarkHtmlUrl from './base/dashindustrybenchmark.html';

angular.module('core.industrybenchmark', [
    'core.industrybenchmark.ctrls',
    'core.industrybenchmark.resources'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        var dashfilters = {
            templateUrl: AppConfig.MAGE_URL + 'layout/dashfilters',
            controller: 'DashFiltersController'
        };

        $stateProvider
            .state('industrybenchmark', {
                parent:'layout',
                url: '/industrybenchmark',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('industrybenchmark'),
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_INDUSTRY_BENCHMARK;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: dashindustrybenchmarkHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);