import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { SectionTemplate } from '@/modules/ta/dashboard/models/templates/SectionTemplate';
import { GenericModel } from '@/modules/core/app/models/GenericModel';
import { Layout } from '@/modules/ta/dashboard/models/Layout';

export class SectionTemplateResource extends BaseEntityResource {
  constructor() {
    super(`${RouteGroup.DASH}/${RouteGroup.LAYOUTS}/${RouteGroup.LIBRARY}`, SectionTemplate);
  }

  /**
   * @param q
   * @returns {string}
   */
  addSearchParam(q = null) {
    return `${q}|title`;
  }

  /**
   *
   * @param model
   * @returns {Promise<Layout>}
   */
  async useTemplateAsLayout(model) {
    const layout = await super.postData(model, `template`);
    return new Layout(layout);
  }

  /**
   * Increment template download count.
   * @param id
   * @returns {*}
   */
  incrementDownloadCount(id) {
    return super.getData(GenericModel, `incrementdownload/${id}`);
  }

  /*
   * @param {Layout} section
   * @param {*} params
   */
  async publish(section, params = {}) {
    return super.postData(section, 'publish', params);
  }

  async getItems(params, config) {
    if (params.q && this.addSearchParam(params.q)) {
      params.q = this.addSearchParam(params.q);
    }
    return super.getData(this.model, 'display', params, config);
  }
}

export default new SectionTemplateResource();
