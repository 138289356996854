'use strict';
import angular from 'angular';
import './scripts/reportingtemplate.ctrls';
import './scripts/reportingtemplate.directives';
import './scripts/reportingtemplate.services';

angular.module('core.reportingtemplate', [
    'core.reportingtemplate.ctrls',
    'core.reportingtemplate.directives',
    'core.reportingtemplate.services'
])

    .config(['$stateProvider', 'AppConfig', function ($stateProvider, AppConfig) {

        $stateProvider
            .state('reportingtemplates', {
                parent:'layout',
                url: '/reportingtemplates',
                controller: 'ReportingTemplateListController',
                templateUrl: AppConfig.MAGE_URL + 'reportingTemplate/manage_reporting_templates'
            })
            .state('reportingtemplates.add', {
                parent:'layout',
                url: '/reportingtemplates/add',
                data: { isNewEntity: true, primaryKey: 'reporting_template_id' },
                controller: 'ReportingTemplateController',
                templateUrl: AppConfig.MAGE_URL + 'reportingTemplate/add_reporting_template'
            })
            .state('reportingtemplates.detail', {
                parent:'layout',
                url: '/reportingtemplates/detail/:id',
                controller: 'ReportingTemplateController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'reportingTemplate/view_reporting_template/reporting_template_id/' + $stateParams.id;
                }
            })
    }]);