import angular from 'angular';
import _ from 'lodash';
import { ColumnValueType } from 'coreModules/shared/scripts/app.constants';

angular.module('core.smartcampaign.resources', [])
    .factory('SmartCampaignResource', SmartCampaignResource)
    .factory('SmartCampaignDataGridService', SmartCampaignDataGridService);

/**
 * @ngInject
 */
function SmartCampaignResource(
    Restangular
) {
    var resource = Restangular.all('smartcampaigns');

    return {
        get: get,
        getAll: getAll,
        getData: getData,
        getServices: getServices,
        getDataViews: getDataViews,
        getResource: getResource,
        getCampaigns: getCampaigns,
        getPreviewDataGrid: getPreviewDataGrid,
        getPrimaryName: getPrimaryName,
        getFieldValues: getFieldValues
    };

    /**
     * Gets all Smart Campaigns with their associated dataViews
     * @returns {*}
     */
    function getAll() {
        return resource.getList().then(function (json) {
            return json.plain();
        })
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        queryParams = queryParams || {};
        queryParams.all = true;
        return resource.getList(queryParams);
    }

    /**
     * Retrieves single entity with id
     * @param smartCampaignId
     * @returns {*}
     */
    function get(smartCampaignId) {
        return resource.one(smartCampaignId).get({all: true});
    }

    /**
     * Getter for mainresource of class
     * @returns {*}
     */
    function getResource() {
        return resource;
    }

    /**
     *
     * @returns {*|angular.IPromise<T>}
     */
    function getServices() {
        return resource.one('services').getList();
    }

    /**
     *
     * @param serviceId
     * @returns {*}
     */
    function getDataViews(serviceId) {
        return resource.one('services').one(serviceId).one('dataviews').getList().then(function(json) {
            return json.plain();
        });
    }

    /**
     * Get values for all possible distinct specific field
     * @param fields
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, queryParams) {
        return resource.one('values').one(ColumnValueType.DISTINCT)
          .get(_.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));

    }

    /**
     *
     * @param smartCampaign
     */
    function getPreviewDataGrid(smartCampaign) {
        return resource
            .one('datagrid')
            .get()
            .then(function (json) {
                return json.plain()[0];
            });
    }

    /**
     *
     * @param smartCampaign
     */
    function getPrimaryName(smartCampaign) {
        return resource
            .one('primary')
            .one(smartCampaign.service_id)
            .get()
            .then(function (primaryName) {
                return primaryName;
            });
    }

    /**
     *
     * @param smartCampaign
     * @param params
     */
    function getCampaigns(smartCampaign, params) {
        params = params || {};
        var request = resource
            .one('preview')
            .one(smartCampaign.service_id)
            .one(smartCampaign.dataview);

        if (smartCampaign.customer_id) {
            request = request.one(smartCampaign.customer_id);
        }

        if (smartCampaign.search_pattern) {
            params['search_pattern'] = smartCampaign.search_pattern;
        }

        return request
            .get(params)
            .then(function (json) {
                return json.plain();
            });
    }

}

/**
 * @ngInject
 */
function SmartCampaignDataGridService(
    AppFactory,
    PubSub,
    $SmartCampaignDetailModalEvents,
    DataGridRender,
    SmartCampaignColumns
) {
    return {
        getDTOptions: getDTOptions
    };

    function getDTOptions() {

        var dtOptions = {};

        dtOptions.customRenders = {};
        dtOptions.numActions = 2;

        dtOptions.customRenders[SmartCampaignColumns.ID] = function (data, type, full) {
            var html = '';

            html = DataGridRender.getEditDetailsButton(SmartCampaignColumns.ID, full.can_be_edited);
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'smartcampaigns/' + data);

            return html;
        };

        dtOptions.customRenders[SmartCampaignColumns.ICON] = function (data, type, full) {
            var html = '';

            html += '<div>' +
                        '<div class="service-square service-square-16" style="background-color:'+ full.color +'">' +
                            '<div class="icon '+ full.icon +'">' +
                            '</div>' +
                        '</div>' +
                    '</div>';

            return html;
        };

        dtOptions.customRenders[SmartCampaignColumns.SOURCE_TYPE] = function (data, type, full) {
            var service = _getDataSource(full.source_type, full.service_id);

            var html = '<div>' +
                        '<div class="service-square service-square-16" style="background-color:'+ service.color +'">' +
                            '<div class="icon serviceicon-' + service.icon +'">' +
                            '</div>' +
                        '</div>' +
                        service.name +
                '</div>';

            return html;
        };

        dtOptions.postCreatedRowCallback = function($link, row, data) {
            var field = $link.data('field');
            switch (field) {
                case SmartCampaignColumns.ID:
                    $link.click(function () {
                        PubSub.emit($SmartCampaignDetailModalEvents.INIT, {data: data, row: row});
                    });
                    break;
            }
        };

        return dtOptions;
    }

    /**
     * @param data_source_type
     * @param service_id
     * @returns {*}
     * @private
     */
    function _getDataSource(data_source_type, service_id) {
        return _.find(_.concat(AppFactory.getConnectedServices()), function (service) {
            return service.id === service_id;
        })
    }
}