'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.library.resources', [])
    .factory('WidgetLibraryResourceFactory', WidgetLibraryResourceFactory)
    .factory('WidgetLibraryDataGridFactory', WidgetLibraryDataGridFactory);

/**
 * @ngInject
 */
function WidgetLibraryResourceFactory(
    $q,
    Restangular,
    WidgetTagFactory
) {
    var dash = Restangular.all('dash');
    var widgets = dash.all('widgets');
    var resource = widgets.all('library');

    return {
        getData: getData,
        getWidgetsForDisplay: getWidgetsForDisplay,
        getSearchableValues: getSearchableValues,
        incrementDownloadCount: incrementDownloadCount
    };

    /**
     * Get data for this resource
     */
    function getData(queryParams) {
        queryParams = _.assign({
            all: true
        }, queryParams);
        return resource.getList(queryParams);
    }

    /**
     * @param queryParams
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function getWidgetsForDisplay(queryParams) {
        queryParams = queryParams || {};
        return resource.all('display').getList(queryParams).then(function (json) {
            return json.plain();
        });
    }

    /**
     * @returns {*}
     */
    function getSearchableValues() {
        var deferred = $q.defer();

        var promises = [
            WidgetTagFactory.getTags(),
            _getDataSourcesInUse()
        ];

        $q.all(promises).then(function (data) {
            deferred.resolve({
                tags: data[0],
                dataSources: data[1]
            });
        });

        return deferred.promise;
    }

    /**
     * @param id
     * @returns {*}
     */
    function incrementDownloadCount(id) {
        return resource.one('incrementdownload', id).get({ignoreLoadingBar: true});
    }

    /**
     * Returns promise for resolving data sources used across all library widgets
     *
     * @returns {PromiseLike<T> | Promise<T> | *}
     * @private
     */
    function _getDataSourcesInUse() {
        var queryParams = {
            all: true,
            ignoreLoadingBar: true
        };
        return resource.all('datasources').getList(queryParams).then(function (json) {
            return json.plain();
        });
    }
}

/**
 * @ngInject
 */
function WidgetLibraryDataGridFactory(
    DataGridRender,
    DataSourceFactory,
    WidgetLibraryColumns,
    WidgetFactory
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return manage widgets datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};

        dtOptions.numActions = 1;
        dtOptions.customRenders[WidgetLibraryColumns.ID] = function (data, type, full) {
            var html = '';
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'dash/widgets/' + data, full['metadata']['title'], full.can_be_deleted_tooltip);
            return html;
        };


        dtOptions.customRenders[WidgetLibraryColumns.THUMBNAIL_METADATA] = function (data, type, full) {
            if (_.isEmpty(data)) {
                // return icon icomoon-big-number
                return `<div class="icon-body widget-icon-container">
                    <i class="icon icomoon-${WidgetFactory.getWidgetType(full.type).icon}"></i>
                </div>`;
            } else {
                return `<div class="widget-thumbnail-container">
                    <div class="inner widget-library-preview"><img src="${data.url}"/></div>
                </div>`;
            }
        };

        dtOptions.customRenders[WidgetLibraryColumns.TYPE] = function (data) {
            var widgetType = WidgetFactory.getWidgetType(data);
            return '<i class="icon icomoon-' + widgetType.icon + ' mr5"></i><span>' + widgetType.name + '</span>';
        };

        dtOptions.customRenders[WidgetLibraryColumns.DATA_SOURCE] = function (data, type, full) {
            if (!_.isEmpty(full.data_source_id)) {
                data = DataSourceFactory.getDataSourceDetails('service_data', full.data_source_id);
                if (data) {
                    var icon = DataSourceFactory.getDataSourceIcon(data);
                    var html = '<i class="icon ' + icon + ' mr5" style="color:' + data.color + '"></i>';
                    if (!_.isNull(data.name)) {
                        html += '<span>' + data.name + '</span>';
                        return html;
                    }
                }
            }
            return '';
        };

        dtOptions.customRenders[WidgetLibraryColumns.TAGS] = function (data) {
            var html = '';
            _.each(data, function(item) {
                html += '<span class="label label-default mr5">' + item + ' </span>';
            });
            return html;
        };

        dtOptions.customRenders[WidgetLibraryColumns.METRICS] = function (data) {
            var html = '';
            _.each(data, function(item) {
                html += '<span class="label label-default mr5">' + item + ' </span>';
            });
            return html;
        };

        dtOptions.customRenders[WidgetLibraryColumns.CREATED_AT] = function (data, full) {
            return full[WidgetLibraryColumns.FORMATTED_CREATED_AT];
        };

        return dtOptions;
    }
}