<template>
  <BubbleChart
    v-if="canShowChart"
    :config="chartConfig"
    width="100%"
    height="100%"
    @frame-ended="setIdle"
  />
</template>

<script>
import BubbleChart from '@/modules/core/charts/am5/bubble/components/BubbleChart.vue';
import { Widget } from '@/modules/ta/widget/models/Widget';
import { LegacyBubbleChartWidgetRenderService } from '@/modules/core/charts/am5/bubble/services/LegacyBubbleChartWidgetRenderService';
import { isEmpty } from 'lodash';
import { dispatches } from '@/modules/core/app/helpers/store';
import { ChartPlace } from '@/modules/core/charts/am5/charts.constants';

export default {
  name: 'LegacyBubbleChartWidget',
  components: { BubbleChart },
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
    widget: {
      type: Object,
      required: true,
    },
    isExporting: {
      type: Boolean,
      required: true,
    },
    chartPalette: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    isPredefined: {
      type: Boolean,
      default: false,
    },
    hasComparisonData: {
      type: Boolean,
      default: false,
    },
    comparisonBubbleColor: {
      type: String,
      required: true,
    },
    clientCurrencySymbol: {
      type: String,
      default: '',
    },
    elementMetadata: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartConfig: null,
    };
  },
  computed: {
    canShowChart() {
      return this.chartConfig && !isEmpty(this.data);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.data?.length) {
        dispatches.dashboardExport.setUnrenderedWidgetIds([this.widget.id]);
      }
      const widget = new Widget(this.widget);
      widget.cached_data = this.data;
      const renderer = new LegacyBubbleChartWidgetRenderService(
        widget,
        this.chartPalette,
        this.hasComparisonData,
        this.comparisonBubbleColor,
        this.isExporting
      );
      let chartTransparency = 1;
      if (widget.report_id && this.elementMetadata) {
        chartTransparency = this.elementMetadata?.base_options?.background_color ? 1 : 0;
      }
      const config = renderer.getChartConfig();
      config.setAll({
        chartPlace: widget.report_id ? ChartPlace.REPORT : ChartPlace.DASHBOARD,
        clientCurrencySymbol: this.clientCurrencySymbol,
        chartTransparency,
      });
      this.chartConfig = config;
    },
    async setIdle() {
      dispatches.dashboardExport.setWidgetAsRendered(this.widget.id);
    },
  },
  watch: {
    data: {
      handler() {
        this.init();
      },
      deep: true,
    },
    widget: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
};
</script>
