import _ from 'lodash';
import moment from 'moment';
import { ColumnFormat, PostProcessType } from '@/modules/core/app/constants/data.constants';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import { getters } from '@/modules/core/app/helpers/store';
import {
  MomentDateFormat,
  RelativeDateRangeKey,
} from '@/modules/core/daterange/daterange.constants';
import {
  DrawOption,
  WidgetBuilderConstants,
  TimeGrouping,
} from '@/modules/ta/widget/widget.constants';
import { GenericDataModel } from '@/modules/core/app/models/GenericDataModel';
import { deepClone } from '@/modules/core/helper';
import { User } from '@/modules/ta/user/models/User';
import BaseDateRangeConfig from '@/modules/core/daterange/models/BaseDateRangeConfig';

export class WidgetSampleDataResource {
  /**
   * All multidimensional array storing sample data for each widget
   * @type {Object}
   */
  sampleData;

  /**
   * All multidimensional array storing sample comparison data for each widget
   * @type {Object}
   */
  sampleComparisonData;

  /**
   * Max data to be returned when sampling
   * @type {number}
   */
  maxSampleSize;

  /**
   * @type {Array.<ApiColumn>}
   */
  columnsToAdd;

  /**
   *
   */
  dateValues;

  /**
   * @type {Array.<*>}
   */
  data;

  /**
   * @type {number}
   */
  sampleSize;

  /**
   * @type {number}
   */
  secondsInOneYear;

  constructor() {
    this.sampleData = {};
    this.sampleComparisonData = {};
    this.maxSampleSize = 40;
    this.columnsToAdd = [];
    this.dateValues = [];
    this.data = [{}];
    this.sampleSize = 0;
    this.secondsInOneYear = 60 * 60 * 24 * 365;
  }

  /**
   * @param {Widget} widget
   * @param {Object} params
   * @returns {Promise<Array|{}[]>}
   */
  async getWidgetData(widget, params) {
    try {
      return this.generateSampleData(widget, params);
    } catch (e) {
      this.cleanUp();
      return [{}];
    }
  }

  /**
   * Using the specified metadata we produce a widget sample data set
   * @param widget
   * @param params
   * @returns {Promise<Array>}
   */
  generateSampleData(widget, params) {
    if (widget.isAdminWidget()) {
      return new User();
    }

    const { metadata } = widget;
    const hasComparison =
      (metadata.compare_to_prior_period ||
        getters.daterange.isComparisonDateRangeEnabled() ||
        (metadata.is_overriding_date_range && metadata.compare_to_prior_period)) &&
      this._canDataSourceDisplayComparison(metadata.data_source);
    // Either fetch from cache or query backend

    return Promise.resolve().then(() => {
      // When creating/previewing widget, id is null
      const widgetId = widget.id || WidgetBuilderConstants.NEW_WIDGET_ID;

      // Create sample data entry if none
      if (_.isUndefined(this.sampleData[widgetId])) {
        this.sampleData[widgetId] = [];
      }
      // Create sample comparison data entry if none
      if (_.isUndefined(this.sampleComparisonData[widgetId])) {
        this.sampleComparisonData[widgetId] = [];
      }
      // Could be a media widget or other type of widget with no columns
      if (_.isUndefined(metadata.data_columns)) {
        return null;
      }

      if (widget.is_sparkline) {
        metadata.data_columns.grouped = [
          { field: 'log_date', is_primary_date_field: true, format: ColumnFormat.FORMAT_DATETIME },
        ];
      }
      const drawOptions = metadata.draw_options;
      const selectedColumns = metadata.data_columns.selected;
      const groupedColumns = metadata.data_columns.grouped;
      this.sampleSize = 10; // Default sample size
      const dataColumns = _.concat(selectedColumns, groupedColumns);
      const currentKeys = _.keys(_.first(this.sampleData[widgetId]));

      // Only add columns that are not already in the sample data set
      this.columnsToAdd = _.filter(
        dataColumns,
        (column) => !_.includes(currentKeys, column.field) || column.isFormatDateOrDateTime()
      );

      if (metadata.geo_code) {
        this.columnsToAdd.push({
          field: 'geo_longitude',
          format: ColumnFormat.FORMAT_COORDINATE,
        });
        this.columnsToAdd.push({
          field: 'geo_latitude',
          format: ColumnFormat.FORMAT_COORDINATE,
        });
        this.columnsToAdd.push({
          field: 'geocode0',
          format: ColumnFormat.FORMAT_STRING,
        });
        this.columnsToAdd.push({
          field: 'geocode1',
          format: ColumnFormat.FORMAT_STRING,
        });
      }

      // If sample size is set in params
      if (_.isObject(params) && !_.isEmpty(params) && params.sampleSize) {
        this.sampleSize = params.sampleSize;
      }

      // If we have selected a date field
      const primaryDateColumn = _.find(groupedColumns, { is_primary_date_field: true });
      if (primaryDateColumn) {
        let dateRange;
        if (metadata.is_overriding_date_range) {
          if (metadata.relative_date_range === RelativeDateRangeKey.CUSTOM) {
            dateRange = {
              start: moment(metadata.start_date_override).format(MomentDateFormat.UNIX),
              end: moment(metadata.end_date_override).format(MomentDateFormat.UNIX),
            };
          } else {
            const range = BaseDateRangeConfig.getDateRangeFromRelativeRange(
              metadata.relative_date_range
            );
            dateRange = {
              start: range.start.format(MomentDateFormat.UNIX),
              end: range.end.format(MomentDateFormat.UNIX),
            };
          }
        } else {
          dateRange = getters.daterange.getDateRange();
        }
        this.dateValues = this.getDateValues(
          metadata.time_grouping,
          dateRange.start_date,
          dateRange.end_date
        );

        this.sampleSize =
          Math.min(this.sampleSize, this.dateValues.length) === 0
            ? Math.max(this.sampleSize, this.dateValues.length)
            : Math.min(this.sampleSize, this.dateValues.length);

        // Always add and overwrite date field since it is static
        this.columnsToAdd.push(primaryDateColumn);
      } else if (metadata.geo_code) {
        this.sampleSize = 100;
      } else if (_.isEmpty(groupedColumns)) {
        this.sampleSize = 1;
      }

      this.generateWithSampleSize(widget);

      // Trim out data exceeding sample size
      let result = deepClone(this.trimData(widgetId, hasComparison));

      if (widget.isDataGridWidget()) {
        // Add total row if requested
        const hasTotalRow = this.includeShowTotalRow(drawOptions);
        if (hasTotalRow) {
          this.checkForTotalRow(result, dataColumns, drawOptions, hasComparison);
        }
        return this.formatDataGrid(result, hasTotalRow);
      }

      result = result.map((datum) => new GenericDataModel(datum));

      if (widget.isSerialChartWidget()) {
        this.checkForCumulativeData(result, widget, hasComparison);
      }

      return result;
    });
  }

  /**
   *
   * @param result
   * @param hasTotalRow
   */
  formatDataGrid(result, hasTotalRow) {
    const data = {
      sEcho: 'prod',
      iTotalRecords: result.length,
      iTotalDisplayRecords: result.length,
      aaData: result,
    };
    if (hasTotalRow) {
      return {
        data,
        total_data: [result.total_data],
      };
    }
    return data;
  }

  /**
   * @param {Widget} widget
   */
  generateWithSampleSize(widget) {
    const { id: widgetId, metadata } = widget;

    for (let i = 0; i < this.maxSampleSize; i++) {
      // Create entry if none
      if (_.isUndefined(this.sampleData[widgetId][i])) {
        this.sampleData[widgetId][i] = {};
      }
      // Create sample comparison entry entry if none
      if (_.isUndefined(this.sampleComparisonData[widgetId][i])) {
        this.sampleComparisonData[widgetId][i] = {};
      }

      _.each(this.columnsToAdd, (column) => {
        let datum = null;
        let comparisonDatum = null;

        let { field } = column;

        switch (column.format) {
          case ColumnFormat.FORMAT_INTEGER:
          case ColumnFormat.FORMAT_PERCENT:
          case ColumnFormat.FORMAT_DECIMAL:
          case ColumnFormat.FORMAT_CURRENCY:
            datum = this.generateValue(metadata, field, column.format);
            comparisonDatum = this.generateValue(metadata, field, column.format);
            break;

          case ColumnFormat.FORMAT_TIME:
            [datum, comparisonDatum] = this.calculateFormatTimeSampleData(field, column, metadata);
            break;

          case ColumnFormat.FORMAT_DATE:
          case ColumnFormat.FORMAT_DATETIME:
            datum = this.dateValues[i];
            // Set one year difference for comparison in DATAGRID preview
            comparisonDatum = this.dateValues[i] - this.secondsInOneYear;

            this.sampleData[widgetId][i][`formatted_${field}`] = moment
              .unix(datum)
              .format(MomentDateFormat.MONTH_DAY_YEAR);
            this.sampleComparisonData[widgetId][i][`formatted_${field}`] = moment
              .unix(comparisonDatum)
              .format(MomentDateFormat.MONTH_DAY_YEAR);
            break;

          case ColumnFormat.FORMAT_ID:
            datum = `${column.label} ${i + 1}`;
            comparisonDatum = datum;
            field = column.groupby_name_field;
            break;

          case ColumnFormat.FORMAT_LINK:
            datum = `<a href="javascript:void(0);"><span>View ${column.label} ${i + 1}</span></a>`;
            break;

          case ColumnFormat.FORMAT_STRING:
            if (column.postprocess_type === PostProcessType.POSTPROCESS_URL_IMAGE) {
              datum = '';
              comparisonDatum = datum;
              break;
            } else if (column.field === 'geocode0') {
              datum = this._getCountryISO();
            } else if (column.field === 'geocode1') {
              datum = this._getStatesISO(this.sampleData[widgetId][i].geocode0);
            } else {
              datum = `${column.label} ${i + 1}`;
              comparisonDatum = datum;
            }
            break;
          case ColumnFormat.FORMAT_CALLBACK:
            if (
              column.postprocess_type === PostProcessType.POSTPROCESS_CALLBACK ||
              column.postprocess_type === PostProcessType.POSTPROCESS_IFRAME
            ) {
              datum = `<div class="image-placeholder"><i class="icon icon-picture-o"></i><span>Preview ${
                i + 1
              }</span></div>`;
              comparisonDatum = datum;
            } else {
              datum = `${column.label} ${i + 1}`;
              comparisonDatum = datum;
            }
            break;

          case ColumnFormat.FORMAT_PHONE_NUMBER:
            datum = Math.floor(Math.random() * 10000000000).formatPhoneNumber();
            break;

          case ColumnFormat.FORMAT_COORDINATE:
            datum = this.generateValue(metadata, field, column.format, -180, 180);
            comparisonDatum = this.generateValue(metadata, field, column.format, -180, 180);
            break;
          default:
            datum = null;
            break;
        }

        this.sampleData[widgetId][i][field] = datum;
        this.sampleComparisonData[widgetId][i][field] = comparisonDatum;
      });
    }
  }

  /**
   * @param field
   * @param column
   * @param metadata
   * @returns {[string, string]}
   */
  calculateFormatTimeSampleData(field, column, metadata) {
    let hours = this.generateValue(metadata, field, column.format, 0, 24);
    hours = hours < 10 ? `0${hours}` : hours; // Prepend 0 if less than 10
    const minutes = this.generateValue(metadata, field, column.format, 10, 59);
    const seconds = this.generateValue(metadata, field, column.format, 10, 59);
    const datum = `${hours}:${minutes}:${seconds}`;

    const comparisonHours = this.generateValue(metadata, field, column.format, 0, 24);
    hours = hours < 10 ? `0${hours}` : hours; // Prepend 0 if less than 10
    const comparisonMinutes = this.generateValue(metadata, field, column.format, 10, 59);
    const comparisonSeconds = this.generateValue(metadata, field, column.format, 10, 59);
    const comparisonDatum = `${comparisonHours}:${comparisonMinutes}:${comparisonSeconds}`;

    return [datum, comparisonDatum];
  }

  /**
   * @param result
   * @param dataColumns
   * @param drawOptions
   * @param hasComparison
   */
  checkForTotalRow(result, dataColumns, drawOptions, hasComparison) {
    if (this.includeShowTotalRow(drawOptions)) {
      const total = {};
      _.each(dataColumns, (column) => {
        if (column.isFormatNumerical() && column.format !== ColumnFormat.FORMAT_TIME) {
          total[column.field] = _.sumBy(result, (sample) =>
            hasComparison ? sample.current_period[column.field] : sample[column.field]
          );
        } else {
          total[column.field] = '';
        }
      });
      result.total_data = total;
    }
  }

  checkForCumulativeData(result, widget, hasComparison) {
    const acc = [];
    widget.metadata.cumulative_columns?.forEach((column) => {
      result.forEach((datum) => {
        if (hasComparison) {
          acc[`currentPeriod${column}`] =
            (acc[`currentPeriod${column}`] || 0) + (datum.current_period?.[column] ?? 0);
          datum.current_period[column] = acc[`currentPeriod${column}`];
          acc[`priorPeriod${column}`] =
            (acc[`priorPeriod${column}`] || 0) + (datum.prior_period?.[column] ?? 0);
          datum.prior_period[column] = acc[`priorPeriod${column}`];
        } else {
          acc[column] = (acc[column] || 0) + (datum.current_period?.[column] ?? 0);
          datum.current_period[column] = acc[column];
        }
      });
    });
  }

  trimData(widgetId, hasComparison) {
    let result = [];

    const trimmedData = _.take(this.sampleData[widgetId], this.sampleSize);

    const trimmedComparisonData = _.take(this.sampleComparisonData[widgetId], this.sampleSize);

    if (hasComparison) {
      this.formatComparisonData(result, trimmedData, trimmedComparisonData);
    } else {
      result = trimmedData;
    }

    return result;
  }

  /**
   *
   * @param metadata
   * @param field
   * @param format
   * @param forcedMin
   * @param forcedMax
   * @returns {*}
   */
  generateValue(metadata, field, format, forcedMin, forcedMax) {
    const min = forcedMin || 0;
    let max = this.getDataConfidenceValue(
      this.data,
      metadata.data_source.type,
      metadata.data_source.id,
      metadata.data_source.data_view,
      field
    );

    // Force a max and skip resolving max
    if (forcedMax) {
      max = forcedMax;
    } else if (_.isUndefined(max) || _.isNull(max)) {
      // If no confidence value can be found use a default max
      max = this.getRandomMax(format);
    }

    return format === ColumnFormat.FORMAT_INTEGER || format === ColumnFormat.FORMAT_TIME
      ? this.getRandomInteger(min, max)
      : this.getRandomFloat(min, max);
  }

  /**
   * Helper function to tell if we need to inlcude show total row
   * @param drawOptions
   * @returns {*|boolean}
   */
  includeShowTotalRow(drawOptions) {
    return drawOptions[DrawOption.SHOW_TOTAL_ROW];
  }

  /**
   * Produces a max based on column format
   * @param format
   * @returns {number}
   */
  getRandomMax(format) {
    switch (format) {
      case ColumnFormat.FORMAT_DECIMAL:
        return 10;

      case ColumnFormat.FORMAT_PERCENT:
        return 100;

      case ColumnFormat.FORMAT_CURRENCY:
        return 1000;

      case ColumnFormat.FORMAT_INTEGER:
        return 10000;

      default:
        return 10000;
    }
  }

  getRandomFloat(min, max) {
    return Math.random() * (max - min) + min;
  }

  getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getDateValues(timeGrouping, start, end) {
    const values = [];
    let increment;

    let startDate = moment(start);
    let endDate = moment(end);
    timeGrouping = timeGrouping.toLowerCase();

    if (timeGrouping === TimeGrouping.GROUPING_DYNAMIC) {
      const duration = endDate.diff(startDate, 'days');
      if (duration <= 1) {
        timeGrouping = TimeGrouping.GROUPING_HOURLY;
      } else if (duration <= 31) {
        timeGrouping = TimeGrouping.GROUPING_DAILY;
      } else if (duration <= 70) {
        timeGrouping = TimeGrouping.GROUPING_WEEKLY;
      } else if (duration <= 365) {
        timeGrouping = TimeGrouping.GROUPING_MONTHLY;
      } else {
        timeGrouping = TimeGrouping.GROUPING_YEARLY;
      }
    }

    switch (timeGrouping) {
      case TimeGrouping.GROUPING_YEARLY:
        increment = 'y'; // Add 1 year
        break;

      case TimeGrouping.GROUPING_MONTH_OF_YEAR: {
        const monthlyEndDate = startDate.clone().add(12, 'M');
        endDate = endDate <= monthlyEndDate ? endDate : monthlyEndDate;
        increment = 'M'; // Add 1 month
        break;
      }

      case TimeGrouping.GROUPING_MONTHLY:
        increment = 'M'; // Add 1 month
        break;

      case TimeGrouping.GROUPING_DAILY:
        increment = 'd'; // Add 1 day
        break;

      case TimeGrouping.GROUPING_DAY_OF_MONTH: {
        const dailyEndDate = startDate.clone().add(1, 'M');
        endDate = endDate <= dailyEndDate ? endDate : dailyEndDate;
        increment = 'd'; // Add 1 day
        break;
      }

      case TimeGrouping.GROUPING_WEEKLY:
      case TimeGrouping.GROUPING_NON_ISO_WEEK:
        increment = 'w'; // Add 1 week
        break;

      case TimeGrouping.GROUPING_HOURLY:
      case TimeGrouping.GROUPING_HOURLY_ADVERTISER:
      case TimeGrouping.GROUPING_HOURLY_AUDIENCE:
        increment = 'h'; // Add 1 hour
        break;

      case TimeGrouping.GROUPING_HOUR_OF_DAY:
        startDate = moment().startOf('day');
        endDate = moment().endOf('day');
        increment = 'h'; // Add 1 hour
        break;

      case TimeGrouping.GROUPING_DAY_OF_WEEK: {
        const weeklyEndDate = startDate.clone().add(7, 'd');
        endDate = endDate <= weeklyEndDate ? endDate : weeklyEndDate;
        increment = 'd';
        break;
      }

      case TimeGrouping.GROUPING_QUARTERLY:
        startDate = startDate.startOf('quarter');
        endDate = endDate.startOf('quarter');
        while (endDate >= startDate) {
          values.push(startDate.unix());
          startDate = startDate.clone().add(1, 'quarter').startOf('quarter');
        }
        break;
      default:
        break;
    }

    if (increment) {
      while (endDate >= startDate) {
        values.push(startDate.unix());
        startDate.add(1, increment);
      }
    }

    return values;
  }

  /**
   * If available, returns a realistic max value for a metric
   * @param data
   * @param dataSourceType
   * @param dataSourceId
   * @param dataViewId
   * @param field
   * @returns {*}
   */
  getDataConfidenceValue(data, dataSourceType, dataSourceId, dataViewId, field) {
    const confidenceData = _.find(data, dataSourceType);

    if (
      !_.isUndefined(confidenceData) &&
      !_.isUndefined(confidenceData[dataSourceType]) &&
      !_.isUndefined(confidenceData[dataSourceType][dataSourceId]) &&
      !_.isUndefined(confidenceData[dataSourceType][dataSourceId][dataViewId]) &&
      !_.isUndefined(confidenceData[dataSourceType][dataSourceId][dataViewId][field])
    ) {
      return confidenceData[dataSourceType][dataSourceId][dataViewId][field];
    }
  }

  /**
   * Helper function to format comparison data
   * @param result
   * @param trimmedData
   * @param trimmedComparisonData
   */
  formatComparisonData(result, trimmedData, trimmedComparisonData) {
    result.has_comparison_data = true;

    if (trimmedData.length !== trimmedComparisonData.length) {
      Logger.log(
        i18n.$t('Something wrong! Current data and comparison data should have the same size.'),
        Logger.LEVEL_WARNING
      );
      return;
    }
    _.each(trimmedData, (currentDatum, index) => {
      const comparisonDatum = trimmedComparisonData[index];
      result[index] = {
        current_period: currentDatum,
        prior_period: comparisonDatum,
        index,
      };
    });
  }

  cleanUp() {
    this.sampleData = {};
    this.sampleComparisonData = {};
    this.maxSampleSize = 40;
    this.columnsToAdd = [];
    this.dateValues = [];
  }

  /**
   *
   * @param {Object} dataSource
   */
  _canDataSourceDisplayComparison(dataSource) {
    return (
      dataSource.type === DataSourceType.SERVICE_DATA ||
      dataSource.type === DataSourceType.CATEGORY_DATA
    );
  }

  _getCountryISO() {
    return _.sample(['US', 'CA', 'IN']);
  }

  _getStatesISO(countryISO) {
    if (countryISO === 'US') {
      return _.sample([
        'US-AL',
        'US-AK',
        'US-AZ',
        'US-AR',
        'US-CA',
        'US-CO',
        'US-CT',
        'US-DE',
        'US-DC',
        'US-FL',
        'US-GA',
        'US-HI',
        'US-ID',
        'US-IL',
        'US-IN',
        'US-IA',
        'US-KS',
        'US-KY',
        'US-LA',
        'US-ME',
        'US-MT',
        'US-NE',
        'US-NV',
        'US-NH',
        'US-NJ',
        'US-NM',
        'US-NY',
        'US-NC',
        'US-ND',
        'US-OH',
        'US-OK',
        'US-OR',
        'US-MD',
        'US-MA',
        'US-MI',
        'US-MN',
        'US-MS',
        'US-MO',
        'US-PA',
        'US-RI',
        'US-SC',
        'US-SD',
        'US-TN',
        'US-TX',
        'US-UT',
        'US-VT',
        'US-VA',
        'US-WA',
        'US-WV',
        'US-WI',
        'US-WY',
      ]);
    }

    if (countryISO === 'CA') {
      return _.sample([
        'CA-AB',
        'CA-BC',
        'CA-MB',
        'CA-NB',
        'CA-NL',
        'CA-NT',
        'CA-NS',
        'CA-NU',
        'CA-ON',
        'CA-PE',
        'CA-QC',
        'CA-SK',
        'CA-YT',
      ]);
    }

    if (countryISO === 'IN') {
      return _.sample([
        'IN-AP',
        'IN-AR',
        'IN-AS',
        'IN-BR',
        'IN-CT',
        'IN-GA',
        'IN-GJ',
        'IN-HR',
        'IN-HP',
        'IN-JH',
        'IN-KA',
        'IN-KL',
        'IN-MP',
        'IN-MH',
        'IN-MN',
        'IN-ML',
        'IN-MZ',
        'IN-NL',
        'IN-OR',
        'IN-PB',
        'IN-RJ',
        'IN-SK',
        'IN-TN',
        'IN-TG',
        'IN-TR',
        'IN-UT',
        'IN-UP',
        'IN-WB',
        'IN-AN',
        'IN-CH',
        'IN-DN',
        'IN-DD',
        'IN-DL',
        'IN-JK',
        'IN-LA',
        'IN-LD',
        'IN-PY',
      ]);
    }
  }
}
