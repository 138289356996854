'use strict';
import angular from 'angular';
import _ from 'lodash';
import exportLayoutHtmlUrl from './export.layout.html';

angular.module('core.export.directives')

    .directive('designLayoutExport', designLayoutExport);

/**
 * @ngInject
 */
function designLayoutExport() {
    return {
        restrict: 'A',
        templateUrl: exportLayoutHtmlUrl,
        scope: {
            layoutId: '<'
        },
        controller: ExportLayoutController,
    }
}

const MAX_PAGE_HEIGHT = 1100,
    RETAIN_WHITESPACE = 1,
    MOVE_TO_SUMMARY = 2,
    COPY_TO_SUMMARY = 3,
    APPEND_PAGE = 'page',
    APPEND_LAYOUT = 'layout',
    PAGINATED_WIDGETS = [];

/**
 * @ngInject
 */
function ExportLayoutController(
    $scope,
    $rootScope,
    WidgetType,
    AppFactory,
    DesignFactory,
    WidgetFactory,
    ExportFactory,
    WidgetUtilService,
    LayoutFactory,
    $isoEvents
) {

    DesignFactory.setCurrentLayoutId($scope.layoutId);
    var currentPage = DesignFactory.getCurrentPage();
    var currentLayout = DesignFactory.getCurrentLayout();
    var vars = ExportFactory.getVars();
    $scope.pageTitle = currentPage.title;
    $scope.layoutTitle = currentLayout.title;
    $scope.reportVars = vars.reportVars;
    $scope.dateRange = AppFactory.getFormattedDateRange();
    $scope.dateCreated = Globalize.format(new Date(), 'MMM dd, yyyy');

    var comparison = AppFactory.getComparisonDateRange();
    $scope.isComparison = comparison.enabled;
    $scope.comparisonDateRange = comparison.formatted;

    $scope.groupedWidgets = [];

    $scope.state = WidgetFactory.getDefaultState();

    $scope.getOrientation = getOrientation;
    $scope.showClippedNotifier = showClippedNotifier;
    $scope.appendWidgetsToLayout = appendWidgetsToLayout;
    $scope.getAppendedWidgetsOnLayout= getAppendedWidgetsOnLayout;
    $scope.layoutExecutiveSummaryCanDisplay = LayoutFactory.layoutExecutiveSummaryCanDisplay($scope.layoutId);
    $scope.summary = DesignFactory.getLayout($scope.layoutId).executive_summary;
    var showLayoutTitleOption = vars.showLayoutTitle;
    $scope.canShowTitle = showLayoutTitleOption ||
        (_.size(DesignFactory.getCurrentPage().layouts) > 1 && showLayoutTitleOption);

    //
    // Logic below is for separating widgets into their own isotope instances (per exported page)
    //
    var widgets = [];
    var appendedWidgets = [];

    // Make sure to order by widget display order
    const currentWidgets = _.sortBy(currentLayout.widgets, 'display_order');

    currentWidgets.forEach((widget) => {
        // This converts width/height constant dimensions to pixel values
        widget.computedWidth = WidgetUtilService.getWidgetWidth(widget.width);
        widget.computedHeight = WidgetUtilService.getWidgetHeight(widget.height);

        let appendToPage = ExportFactory.isAppendedWidgetToPage(widget);

        if (appendToPage || ExportFactory.isAppendedWidgetToLayout(widget)) {
            widget.paginationFlag = shouldLeaveBehindWhitespace(widget) ? RETAIN_WHITESPACE : MOVE_TO_SUMMARY;
            widget.appendFlag = appendToPage ? APPEND_PAGE : APPEND_LAYOUT;
            PAGINATED_WIDGETS[widget.id] = widget.appendFlag;
        }

        widgets.push(widget);
    });

    $scope.canShowBreakPageNotices = _.size(currentPage.layouts) > 1
        && _.size(widgets) > 0
        && vars.isHtml;

    let groupedWidgets = ExportFactory.groupWidgets(widgets, getOrientation());
    const headersForWidgets = [];

    // organise each page into rows so we can get a visual instead of just display order
    const widgetRows = splitGroupedWidgetsIntoRows(groupedWidgets);

    // first work out where all the headers are
    groupedWidgets.forEach((page) => {
        const widgetsRequiringHeaders = getWidgetsRequiringHeaders(page);

        // iterate over the widgets requiring headers and grab the corresponding header:
        // a) full width widgets the header is just the preceding media header
        // b) anything not full-width is usually set up in a grid, so we need to grab the header from
        //      the preceding row
        widgetsRequiringHeaders.forEach((requiresHeader) => {
            let foundHeader;

            // full width widgets then we just count backwards from this widget until we get the next media widget
            if (requiresHeader.width === 12) {
                foundHeader = getPreviousFullWidthHeader(page, requiresHeader);
                if (foundHeader && foundHeader.header) {
                    // whether we move it or not
                    foundHeader.header.paginationFlag = foundHeader.widgetCount > 0 ? COPY_TO_SUMMARY : MOVE_TO_SUMMARY;
                    foundHeader.header.appendFlag = PAGINATED_WIDGETS[requiresHeader.id];

                    headersForWidgets[requiresHeader.id] = foundHeader.header;
                }
            } else {
                // consult the rows to try and get the header that way
                foundHeader = getHeaderFromPreviousRow(widgetRows, requiresHeader);
                if (foundHeader) {
                    // always move these to summary
                    foundHeader.paginationFlag = MOVE_TO_SUMMARY;
                    foundHeader.appendFlag = PAGINATED_WIDGETS[requiresHeader.id];

                    headersForWidgets[requiresHeader.id] = foundHeader;
                }
            }
        });
    });

    const alreadyMovedHeaders = [];

    groupedWidgets.forEach((page, pageIndex) => {
        // now we're dealing with headers and widgets alike so we can just move them / copy them as needed
        page.forEach((widget, widgetIndex) => {
            if (widget.appendFlag) {
                let paginationFlag = widget.paginationFlag ? widget.paginationFlag : RETAIN_WHITESPACE;

                switch (paginationFlag) {
                    // leave it in place but also move it into the append section
                    case COPY_TO_SUMMARY:
                        break;

                    // needs removing from the page entirely
                    case MOVE_TO_SUMMARY:
                        // only fully move if it's not full width
                        if (widget.width !== 12) {
                            widget.paginatedWidgetFiller = true;
                        } else {
                            delete groupedWidgets[pageIndex][widgetIndex];
                        }
                        break;

                    // set to true so it can be white-spaced out in the export
                    case RETAIN_WHITESPACE:
                        widget.paginatedWidgetFiller = true;
                        break;
                }

                // is there a header for this widget?
                if (widget.id in headersForWidgets && !(widget.id in alreadyMovedHeaders)) {
                    const headerForWidget = headersForWidgets[widget.id];
                    if (!(headerForWidget.id in alreadyMovedHeaders)) {
                        alreadyMovedHeaders[headerForWidget.id] = headerForWidget;
                        appendWidget(headersForWidgets[widget.id], widget.appendFlag);
                    }
                }

                // we always want the widget in the summary grids section as that's the whole point
                // unless it's the header which we've already dealt with
                if (!isMediaWidgetHeader(widget)) {
                    appendWidget(widget, widget.appendFlag);
                }
            }

            delete widget.paginationFlag;
        });
    });

    // FINALLY filter out any widgets that are full null so we don't break anything in the ng-repeat
    // this also re-indexes to prevent the ng-repeat getting confused
    let validPages = [];
    groupedWidgets.forEach((page, pageIndex) => {
        const filteredPage = page.filter(Boolean);

        // only keep non-blank pages
        if (filteredPage.length) {
            validPages.push(filteredPage);
        }
    });

    $scope.groupedWidgets = validPages;

    $rootScope.$on('media-widget-in-dom', function() {
        // rp-1760 - media widgets are no longer set to a default height
        _fillEmptySpace();
    });

    /**
     * At this point groupedWidgets contains all the widgets that should fit on a page
     * However, they're just in display order and don't contain information about
     * where on the y axis they would appear.  This creates an expanded array
     * containing the page, rows and then the widget
     * @param groupedWidgets
     * @returns {*[]}
     */
    function splitGroupedWidgetsIntoRows(groupedWidgets) {
        const widgetRows = [];
        groupedWidgets.forEach((outerPage) => {
            let runningTotal = 0;
            let pageRows = [];
            let pageGroup = [];

            outerPage.forEach((widget) => {
                let rowTotal = runningTotal + widget.width;

                if (rowTotal > 12) {
                    pageGroup.push(pageRows);

                    pageRows = [];
                    runningTotal = widget.width;
                } else {
                    runningTotal += widget.width;
                }

                pageRows.push(widget);
            });

            if (pageRows.length) {
                pageGroup.push(pageRows);
            }

            widgetRows.push(pageGroup);
        });

        return widgetRows;
    }

    /**
     * For headers that aren't full-width we assume that they've been arranged in a grid
     * HHH HHH HHH
     * WWW WWW WWW
     * HHHHH HHHHH
     * WWWWW WWWWW
     * (header H / widget W)
     * This was why we split the grouped widgets into rows so we could just go up a row and target the same cell
     * @param widgetRows
     * @param widgetRequiringHeader
     * @returns {*|null}
     */
    function getHeaderFromPreviousRow(widgetRows, widgetRequiringHeader) {
        let foundPage, foundRow, foundWidget;

        // find where the widget is in the rows
        widgetRows.forEach((page, pageIndex) => {
            page.forEach((row, rowIndex) => {
                row.forEach((widget, widgetIndex) => {
                    if (!foundWidget && widget === widgetRequiringHeader) {
                        foundWidget = widgetIndex;
                        foundRow = rowIndex;
                        foundPage = pageIndex;
                    }
                });
            });
        });

        // get the previous row
        foundRow--;

        let possibleHeader;
        // check it exists and return the matching one
        if (Object.hasOwn(widgetRows[foundPage], foundRow) && Object.hasOwn(widgetRows[foundPage][foundRow], foundWidget)) {
            possibleHeader = widgetRows[foundPage][foundRow][foundWidget];
            if (isMediaWidgetHeader(possibleHeader)) {
                return possibleHeader;
            }
        }

        // if it hasn't been found then check the last row on the previous page
        foundPage--;
        if (foundPage >= 0) {
            const finalRow = widgetRows[foundPage].length - 1;
            if (Object.hasOwn(widgetRows[foundPage], finalRow) && Object.hasOwn(widgetRows[foundPage][finalRow], foundWidget)) {
                possibleHeader = widgetRows[foundPage][finalRow][foundWidget];
                if (isMediaWidgetHeader(possibleHeader)) {
                    return possibleHeader;
                }
            }
        }

        return null;
    }

    /**
     * For full-width widgets we just grab the previous header
     * Take the index position of the widget in the rows and then
     * go backwards until we hit a header
     * Returns an object to denote whether there's more than one widget
     * after a header so we know whether to copy it or move it
     * @param widgetsInPage
     * @param widgetRequiringHeader
     * @returns {{widgetCount: number, header: null}|null}
     */
    function getPreviousFullWidthHeader(widgetsInPage, widgetRequiringHeader) {
        const widgetPosition = widgetsInPage.lastIndexOf(widgetRequiringHeader);
        const retVal = {header: null, widgetCount: 0};

        if (widgetPosition === -1) {
            return null;
        }

        // first count forwards until we hit another header to see if there's any other elements
        for (let j = widgetPosition; j < widgetsInPage.length; j++) {
            const testWidget = widgetsInPage[j];
            if (isMediaWidgetHeader(testWidget)) {
                break;
            }

            // but we only increase this count if the widget itself won't be paginated
            if (!widgetWillBePaginated(testWidget.id)) {
                retVal.widgetCount++;
            }
        }

        // then count backwards from the position until we hit the previous header
        // take a note of any widgets between the one we're looking for and the header
        for (let i = widgetPosition; i >= 0; i--) {
            const testWidget = widgetsInPage[i];
            if (isMediaWidgetHeader(testWidget)) {
                retVal.header = testWidget;
                return retVal;
            }

            if (!widgetWillBePaginated(testWidget.id)) {
                retVal.widgetCount++;
            }
        }

        return retVal;
    }

    /**
     * Any widget that's not full width we will be leaving behind whitespace for
     * Otherwise it gets removed from display
     * @param widget
     * @returns {boolean}
     */
    function shouldLeaveBehindWhitespace(widget) {
        return widget.width !== 12;
    }

    /**
     * Widgets are appended differently depending on what's set in the reporting options
     * @param widget
     * @param howToAppend
     */
    function appendWidget(widget, howToAppend) {
        howToAppend === APPEND_LAYOUT ? appendedWidgets.push(widget) : ExportFactory.addAppendedWidgetToPage(widget);
    }

    /**
     * Quickly loop through a page and pull out any widget that could possibly require a header.
     * Basically any paginated widget that isn't already a header
     * @param page
     * @returns {*[]}
     */
    function getWidgetsRequiringHeaders(page) {
        // returns either the widget or null to denote that the position should be ignored
        let widgets = [];
        page.forEach((widget) => {
            if (!isMediaWidgetHeader(widget) && widgetWillBePaginated(widget.id)) {
                widgets.push(widget);
            }
        });

        return widgets;
    }

    /**
     * Whether the widget represented by this ID will be paginated
     * @param widgetId
     * @returns {boolean}
     */
    function widgetWillBePaginated(widgetId) {
        return Object.hasOwn(PAGINATED_WIDGETS, widgetId);
    }

    /**
     * Try and fill the empty space once we've made media widgets the size they should be
     * Should take into account widgets that shouldn't move and tries to stick to MAX_PAGE_HEIGHT which
     * is arbitrary but works
     * @private
     */
    function _fillEmptySpace() {
        let pageHeightMap = generatePageHeightMap();

        angular.forEach(pageHeightMap, (page, pageIndex) => {
            if (canFitExtraWidget(page)) {
                let nextPage = parseInt(pageIndex, 10) + 1;
                if (!nextPageHasWidgets(pageHeightMap, nextPage)) {
                    // delete the empty page, re-index the keys and start again
                    delete $scope.groupedWidgets[nextPage];
                    $scope.groupedWidgets = Object.values($scope.groupedWidgets);

                    // if this is the last page then we should try and call it a day
                    if (nextPage >= Object.keys($scope.groupedWidgets).length) {
                        return;
                    }

                    _fillEmptySpace();
                }

                if (canPullWidgetFromPage(pageHeightMap, nextPage, page.total)) {
                    moveFirstWidgetToPage(pageHeightMap, pageIndex, nextPage);

                    // we've moved a widget so drag everything else up
                    _fillEmptySpace();
                }
            }
        });

        // once we've sorted everything out we can $apply to make sure angular redraws and then get isotope to work
        $scope.$apply();
        $rootScope.$broadcast($isoEvents.LAYOUT);

        updatePageNumbers();
    }

    /**
     * For each of the "pages" present we need to strip off the page number generated before
     * we rearranged the widgets and replace it with the actual index
     *
     * Skips "Executive Summary"
     */
    function updatePageNumbers() {
        const layoutInner = document.getElementsByClassName('layout-inner');
        const pages = layoutInner[0].querySelectorAll("div.pdf-page");

        angular.forEach(pages, function(pageDiv, index) {
            const title = pageDiv.querySelector('p.title');

            if (!title) {
                return;
            }

            const pageTitle = title.innerText;
            const titleParts = pageTitle.split("-");
            if (!titleParts.length) {
                return;
            }

            const pageString = titleParts[titleParts.length - 1];
            const pageParts = pageString.split(" ");
            const pageNumber = pageParts[pageParts.length - 1];
            const pageNumberLength = pageNumber.toString().length;

            if (Number.isInteger(pageNumber)) {
                title.innerText = pageTitle.substring(0, (pageTitle.length - pageNumberLength)) + (index + 1);
            }
        });
    }

    /**
     * If the next page (specified in the param) has widgets or not
     * @param pageHeightMap
     * @param pageIndex
     * @returns {boolean}
     */
    function nextPageHasWidgets(pageHeightMap, pageIndex) {
        return typeof pageHeightMap[pageIndex] !== "undefined" && Boolean(Object.values(pageHeightMap[pageIndex].widgets).length);
    }

    /**
     * Pop a widget from one page to another
     * @param pageHeightMap
     * @param toPageIndex
     * @param fromPageIndex
     */
    function moveFirstWidgetToPage(pageHeightMap, toPageIndex, fromPageIndex) {
        const widgetToMove = pageHeightMap[fromPageIndex].widgets[0];

        $scope.groupedWidgets[toPageIndex].push(widgetToMove);
        $scope.groupedWidgets[fromPageIndex] = $scope.groupedWidgets[fromPageIndex].slice(1);
    }

    /**
     * Whether the page specified can fit another widget (of any size)
     * @param page
     * @returns {boolean}
     */
    function canFitExtraWidget(page) {
        return page.total <= MAX_PAGE_HEIGHT;
    }

    /**
     * Whether a widget can be pulled from where it's sat
     * @param pageHeightMap
     * @param fromPage
     * @param currentHeight
     * @returns {boolean}
     */
    function canPullWidgetFromPage(pageHeightMap, fromPage, currentHeight) {
        if (typeof pageHeightMap[fromPage] === "undefined") {
            return false;
        }
        if (typeof pageHeightMap[fromPage].widgets[0] === "undefined") {
            return false;
        }

        const testWidget = pageHeightMap[fromPage].widgets[0];
        const widgetElement = pageHeightMap[fromPage].elements[0];
        if (!canMoveWidget(testWidget, widgetElement)) {
            return false;
        }

        const heightPlus = currentHeight + pageHeightMap[fromPage].widgetHeights[0];
        return heightPlus <= MAX_PAGE_HEIGHT;
    }

    /**
     * Generates the page height map that we use (and update every time a widget is moved)
     * that we use to keep track of how high pages are.
     * Includes all widgets on that page and their heights
     * @returns {{}}
     */
    function generatePageHeightMap() {
        let pageHeightMap = {};

        angular.forEach($scope.groupedWidgets, function(group, key) {
            pageHeightMap[key] = {
                elements: {},
                total: 0,
                widgetHeights: {},
                widgets: {},
            };
            angular.forEach(group, function(widget, widgetKey) {
                const widgetElement = angular.element('#widget-' + widget.id);
                const widgetHeight = widgetElement.height();
                pageHeightMap[key].total += widgetHeight;
                pageHeightMap[key].widgetHeights[widgetKey] = widgetHeight;
                pageHeightMap[key].widgets[widgetKey] = widget;
                pageHeightMap[key].elements[widgetKey] = widgetElement;
            });
        });

        return pageHeightMap;
    }

    /**
     * Media widgets set as headers should never be moved
     * @param widget (the widget object as from DB)
     * @returns {boolean}
     */
    function isMediaWidgetHeader(widget) {
        if (typeof widget.type === "undefined") {
            return false;
        }

        if (typeof widget.metadata.is_header === "undefined") {
            return false;
        }

        return widget.type === WidgetType.MEDIA && Boolean(widget.metadata.is_header);
    }

    /**
     * Whether the widget is 100% width
     * @param widgetElement DOM object as currently exists
     * @returns {boolean}
     */
    function isNotFullWidth(widgetElement) {
        const widgetWidth = (typeof widgetElement[0].style.width === "undefined") ?
            "100%" : widgetElement[0].style.width;
        return widgetWidth !== "100%";
    }

    /**
     * Media widgets set as headers should never be moved
     * @param widget widget with metadata from DB
     * @param widgetElement widget element as exists in DOM
     * @returns {boolean}
     */
    function canMoveWidget(widget, widgetElement) {
        if (isMediaWidgetHeader(widget)) {
            return false;
        }

        if (isNotFullWidth(widgetElement)) {
            return false;
        }

        return true;
    }

    /**
     * Returns page orientation
     * @returns {string}
     */
    function getOrientation() {
        return vars.orientation;
    }

    function showClippedNotifier(widget) {
        return widget.type === WidgetType.DATAGRID
            && gridHasOverflow(widget);
    }

    /**
     * Does our data grid have a scrollbar
     * @param widget
     * @returns {boolean}
     */
    function gridHasOverflow(widget) {
        var hasData = !_.isEmpty(widget.metadata.dynamic.predefined_data);
        var el = WidgetFactory.$getElement(widget.id).find('div.datagrid-widget-container');
        if (el.length && hasData) {
            // Use <div class="flex-full"> instead of <datagrid-widget> as the grid container
            // because wkhtmltopdf returns 0 of offsetHeight if the container is <datagrid-widget>
            var $gridContainer = el.parent().parent().get(0);
            var $gridContent = el.get(0);
            // Hide total row at bottom if grid has overflow
            if ($gridContainer.offsetHeight < $gridContent.scrollHeight) {
                el.find('tfoot.total-row').hide();
            }
            return $gridContainer.offsetHeight < $gridContent.scrollHeight;
        }
        return false;
    }

    /**
     * Tell us if we append widgets to section
     * @returns {boolean}
     */
    function appendWidgetsToLayout() {
        return ExportFactory.appendWidgetsToLayout() && !_.isEmpty(appendedWidgets);
    }

    /**
     * Helper function to get appended widgets on section
     * @returns {Array}
     */
    function getAppendedWidgetsOnLayout() {
        return appendedWidgets;
    }
}
