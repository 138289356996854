'use strict';
import angular from 'angular';

angular.module('datagrid.tagpicker.constants', [])
    .constant('TagPickerSize', {
        WIDTH: 282,
        HEIGHT: 85
    })
    .constant('$DatagridTagPickerEvents', {
        CELL_CLICKED: '$DatagridTagPickerEvents:CELL_CLICKED',
        TAG_CREATED: '$DatagridTagPickerEvents:TAG_CREATED',
        TAG_UPDATED: '$DatagridTagPickerEvents:TAG_UPDATED'
    });