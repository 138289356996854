'use strict';
import angular from 'angular';
import './base/widget.create.ctrls';
import './base/widget.create.directives';
import './base/widget.create.services';
import './widget.create.constants';

angular.module('widget.create', [
    'widget.create.constants',
    'widget.create.ctrls',
    'widget.create.directives',
    'widget.create.services'
]);



