import { Widget } from '@/modules/ta/widget/models/Widget';
import { dispatches, getters } from '@/modules/core/app/helpers/store';
import { isEmpty } from 'lodash';
import { DashboardWidgetContext } from '@/modules/ta/dashboard/models/DashboardWidgetContext';
import WidgetContextFactory from '@/modules/ta/widget/models/context/WidgetContextFactory';
import moment from 'moment';
import { DateRange } from '@/modules/core/daterange/models/DateRange';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';
import { MomentDateFormat } from '@/modules/core/daterange/daterange.constants';
import { WidgetDataServiceFactory } from '@/modules/ta/widget/factories/WidgetDataServiceFactory';
import { WidgetDataConfig } from '@/modules/ta/widget/models/WidgetDataConfig';
import { WidgetEvent } from '@/modules/ta/widget/widget.constants';
import EventBus from '@/modules/core/app/helpers/EventBus';

export default {
  data() {
    return {
      disableErrorMessages: false,
    };
  },
  props: {
    dashboardId: {
      type: String,
      default: '',
    },
    dateRange: {
      type: Object,
      default: () => ({}),
    },
    isDemoMode: { type: Boolean, default: undefined },
  },
  methods: {
    async setDrillDownOptions() {
      if (this.state.isBuilding) {
        this.widget.metadata.draw_options.force_sample_data =
          this.widget.metadata.draw_options.show_sample_data;
      }
      const widget = new Widget(this.widget);
      if (this.isExporting) {
        widget.cached_data = this.data;
        return widget;
      }
      const widgetConfig = getters.dashboardDrilldown.getWidgetConfig(this.widget.id);
      dispatches.dashboardDrilldown.setDrillDownState({
        id: this.dashboardId,
        demoMode: this.isDemoMode,
      });
      if (isEmpty(widgetConfig)) {
        dispatches.dashboardDrilldown.setWidgetConfig({
          widget: this.widget,
          dateRange: this.dateRange,
        });
      } else {
        const isValid = getters.dashboardDrilldown.validateDrilldownConfig({
          widget: this.widget,
          dateRange: this.dateRange,
        });
        if (!isValid) {
          dispatches.dashboardDrilldown.setWidgetConfig({
            widget: this.widget,
            dateRange: this.dateRange,
          });
          widget.cached_data = this.data;
          return widget;
        }
        if (widgetConfig.drilldownConfigStack.length === 0) {
          widget.cached_data = this.data;
        } else {
          const stackLength = widgetConfig.drilldownConfigStack.length;
          const drilldownConfig = widgetConfig.drilldownConfigStack[stackLength - 1];
          if (drilldownConfig.isSubSlice === false) {
            widget.cached_data = this.data;
          }
          if (drilldownConfig.isSubSlice === true || drilldownConfig.isSubSlice === undefined) {
            const dashboardWidgetContext = new DashboardWidgetContext();
            dashboardWidgetContext.applyContext(this.widget, {
              params: {
                widget_page_id: this.dashboardId,
              },
            });
            WidgetContextFactory.setContext(dashboardWidgetContext);
            const dateRangeParams = {
              start_date: moment.unix(this.dateRange.start_date).toISOString(),
              end_date: moment.unix(this.dateRange.end_date).toISOString(),
            };
            if (this.dateRange.comparison_start_date) {
              dateRangeParams.is_comparison_active = true;
              dateRangeParams.comparison_start_date = moment
                .unix(this.dateRange.comparison_start_date)
                .toISOString();
              dateRangeParams.comparison_end_date = moment
                .unix(this.dateRange.comparison_end_date)
                .toISOString();
            }
            const dateRange = new DateRange(dateRangeParams);

            await dispatches.daterange.updateCurrentAndPriorDateRange({
              startDate: DateUtil.formatDateFromUnixTimeStamp(
                this.dateRange.start_date,
                MomentDateFormat.ISO
              ),
              endDate: DateUtil.formatDateFromUnixTimeStamp(
                this.dateRange.end_date,
                MomentDateFormat.ISO
              ),
              comparisonStartDate: DateUtil.formatDateFromUnixTimeStamp(
                this.dateRange.comparison_start_date,
                MomentDateFormat.ISO
              ),
              comparisonEndDate: DateUtil.formatDateFromUnixTimeStamp(
                this.dateRange.comparison_end_date,
                MomentDateFormat.ISO
              ),
            });
            const relativeRanges = getters.daterange.getRelativeDateRanges();
            const context = WidgetContextFactory.getContext();

            const dataService = WidgetDataServiceFactory.build(widget);
            dataService?.setDrillDownConfig(drilldownConfig);

            const config = new WidgetDataConfig({
              dateRange,
              relativeRanges,
              context,
            });
            widget.metadata = dataService.setComparisonMetadata(widget.metadata, config);

            widget.cached_data = await dataService?.getData(widget, config);
            this.disableErrorMessages = isEmpty(widget.cached_data);
          }
        }
      }
      return widget;
    },
  },
  mounted() {
    EventBus.listen(`${WidgetEvent.RELOAD_WIDGET}-${this.widget.id}`, () => {
      this.init();
    });
  },
  beforeDestroy() {
    EventBus.destroy(`${WidgetEvent.RELOAD_WIDGET}-${this.widget.id}`);
    dispatches.dashboardDrilldown.removeWidget(this.widget.id);
  },
};
