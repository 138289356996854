'use strict';
import angular from 'angular';

angular.module('core.endcustomer.components', [])
    .controller('EndCustomerDashController', EndCustomerDashController);

/**
 * @ngInject
 */
function EndCustomerDashController(
    $scope,
    EndCustomerFactory
) {
    $scope.addEndCustomer = addEndCustomer;

    function addEndCustomer() {
        return EndCustomerFactory.setNewDetails();
    }
}