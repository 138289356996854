/* eslint-disable import/no-import-module-exports */
import { isNull, pickBy, startsWith, isString, includes, endsWith, map } from 'lodash';
import moment from 'moment';
import { Env } from '@/env';
import { dispatches, getters } from '@/modules/core/app/helpers/store';

const querystring = require('querystring');

const DevTools = {
  requests: [],
  responses: [],
  errors: [],
  discards: [],
  widgets: {},
  queryCountLevel: {
    WARNING: 10,
    DANGER: 50,
  },
  totalRuntimeLevel: {
    WARNING: 1,
    DANGER: 3,
  },
  queryRuntimeLevel: {
    WARNING: 0.5,
    DANGER: 1,
  },
};

// PRIVATE VARS
// const apiRoot = 'https://tapclicks.atlassian.net/rest/api/2/';
// const tapClicksRoot = 'https://tapclicks.atlassian.net/secure/';
// const createIssueUrl = 'CreateIssueDetails!init.jspa';

const constants = {
  errorTypes: {
    api: 'api',
    slim: 'slim',
    php: 'php',
    sql: 'sql',
    js: 'js',
  },
};

/**
 * Information about development environment
 * @param devServer
 */
const isEnabled = Env.isDevelopment();

DevTools.isEnabled = function () {
  const userSettings = getters?.session?.getUserSettings?.();
  return isEnabled || (userSettings && userSettings.hasDevTools);
};

/**
 * Restart a fresh profiling
 */
DevTools.resetProfiling = function () {
  this.requests = [];
  this.responses = [];
  this.errors = [];
  this.discards = [];
  this.widgets = {};
};

/**
 * Log and store info about requests
 * @param request
 */
DevTools.logRequest = function (request) {
  request.url = getTrimmedUrl(request);

  const requestLog = {
    ...request,
    datetime: moment(),
    fullUrl: getFullUrl(request),
  };
  dispatches.devtools.addRequests(requestLog);
  this.requests.push(requestLog);

  // const { params } = request;
  //
  // if ('widget_request' in params && !('sample' in params)) {
  //   // Only when making the getData call of a widget does it get set to the widget id
  //   if (isNull(params.widget_request) || params.widget_request === 'null') return;
  //
  //   const widgetId = params.widget_request.toString();
  //   this.widgets[widgetId] = this.widgets[widgetId] || {};
  //   const widget = this.widgets[widgetId];
  //   widget.request = getFullUrl(request);
  // }
};

/**
 * Log and store info about payload responses
 * @param response
 * @param payload
 */
DevTools.logResponse = function (response, payload = {}) {
  const devTools = payload.dev_tools;
  const queries = devTools ? devTools.queries : null;
  const times = devTools ? devTools.times : null;

  response.url = getTrimmedUrl(response);

  if (queries && times) {
    if (queries.profiled_count > this.queryCountLevel.DANGER) {
      dispatches.devtools.addQueryNotice();
    }
    const responseLog = {
      ...response,
      datetime: moment(),
      fullUrl: getFullUrl(response),
      totalRuntime: times.total,
      queryRuntime: times.sql,
      numQueries: queries.profiled_count,
    };
    dispatches.devtools.addResponses(responseLog);
    this.responses.push(responseLog);
    // dispatches.list.setNeedsRefresh({ listId: 'DevToolsResponseList', value: true });
  } else {
    this.discards.push({
      ...response,
      datetime: moment(),
    });
  }

  const { params } = response;
  if ('widget_request' in params && !('sample' in params)) {
    // Only when making the getData call of a widget does it get set to the widget id
    if (isNull(params.widget_request) || params.widget_request === true) {
      return;
    }

    const widgetId = params.widget_request.toString();
    this.widgets[widgetId] = this.widgets[widgetId] || {};
    const widget = this.widgets[widgetId];

    widget.datasql = devTools.widget_sql;
    widget.fullUrl = getFullUrl(response);

    widget.timeProfile = {
      appInit: times.api,
      apiCall: times.code,
      totalRuntime: times.total,
      queryRuntime: times.sql,
      numQueries: queries.profiled_count,
    };
  }
};

/**
 * Log and store info about errors
 * @param response
 * @param payload
 */
DevTools.logError = function (response, payload = null) {
  const errorMsg = Array.isArray(payload) ? payload[0] : payload;
  const { errorTypes } = constants;
  let errorType = errorTypes.api;

  if (!isNull(errorMsg)) {
    // Try to infer error type using error content
    if (this.isSQLError(errorMsg)) {
      errorType = errorTypes.sql;
    } else if (this.isSlimError(errorMsg)) {
      errorType = errorTypes.slim;
    } else if (this.isPHPError(errorMsg)) {
      errorType = errorTypes.php;
    }
  }

  // const { url } = response;
  // const { params } = response;
  // if ('widget_request' in params && !('sample' in params)) {
  //   // Only when making the getData call of a widget does it get set to the widget id
  //   if (isNull(params.widget_request) || params.widget_request === true) return;
  //
  //   const widgetId = params.widget_request.toString();
  //   this.widgets[widgetId] = this.widgets[widgetId] || {};
  //   const widget = this.widgets[widgetId];
  //   widget.bug = this.logBug(errorMsg, errorType, url, getQueryString(params));
  // } else {
  //   bug = this.logBug(errorMsg, errorType, url, getQueryString(params));
  // }

  const { url } = response;
  const { params } = response;
  const bug = this.logBug(errorMsg, errorType, url, this.buildQueryString(params));
  response.url = getTrimmedUrl(response);
  const errorLog = {
    ...response,
    datetime: moment(),
    fullUrl: getFullUrl(response),
    bug,
  };
  dispatches.devtools.addErrors(errorLog);
  this.errors.push(errorLog);
};
/**
 * Returns a list of all the requested URLs
 */
DevTools.getRequestList = function () {
  return map(this.requests, (request) => request.fullUrl);
};

/**
 * Returns the url for a specific widget data
 */
DevTools.getDataUrlForWidget = function (widgetId) {
  return this.widgets[widgetId].fullUrl;
};

/**
 * Returns the SQL Data Source request for a specific widget data
 */
DevTools.getSQLDataSource = function (widgetId) {
  return this.widgets[widgetId].datasql;
};

/**
 * Returns the Time Profile for a specific widget data
 */
DevTools.getAPITimeProfile = function (widgetId) {
  return this.widgets[widgetId].timeProfile;
};

/**
 * Returns a list of all the responses URLs
 */
DevTools.getResponseList = function () {
  return map(this.responses, (request) => request.fullUrl);
};

/**
 * Sets a unique bug in the devtools store
 * @param errorMsg
 * @param errorTypes
 * @param url
 * @param queryString
 */
DevTools.logBug = function (errorMsg, errorTypes, url, queryString) {
  const bug = {};
  bug.errorMsg = errorMsg;
  bug.errorTypes = errorTypes;
  bug.url = url || null;
  bug.originUrl = window.location.href;
  bug.queryString = queryString || null;

  let fullUrl = null;
  if (!isNull(url)) {
    fullUrl = url + queryString;
    if (!includes(fullUrl, window.location.origin)) {
      fullUrl = window.location.origin + fullUrl;
    }
  }
  bug.fullUrl = fullUrl;
  bug.hash = !isNull(fullUrl) ? hashCode(fullUrl + errorMsg) : hashCode(errorMsg);
  bug.isLogged = true;

  return bug;
};

/**
 * We need to parse the response string to find out whether or not we actually have an error
 * NOTE: this is used for responses that don't use the proper http status code of 500, etc..
 * and don't provide an error property set to true (i.e. 200 with error inside of it)
 * @param string
 */
DevTools.isErrorString = function (string) {
  return this.isPHPError(string) || this.isSQLError(string) || this.isSlimError(string);
};

/**
 * Parses string to try and detect Slim specific patterns
 * @param string
 */
DevTools.isSlimError = function (string) {
  return isString(string) && includes(string, i18n.$t('Slim Application Error'));
};

/**
 * Parses string to try and detect PHP specific patterns
 * @param string
 */
DevTools.isPHPError = function (string) {
  return isString(string) && startsWith(string, '<pre>') && endsWith(string, '</pre>');
};

/**
 * Parses string to try and detect SQL specific patterns
 * @param string
 */
DevTools.isSQLError = function (string) {
  return isString(string) && includes(string, 'SQLSTATE');
};

/**
 * @param widgetId
 */
// DevTools.createJiraTicket = function(widgetId) {
//   const bug = this.widgets[widgetId].bug;
//   const url = tapClicksRoot + createIssueUrl;
//   const params = {};
//   params.summary = 'Widget ' + widgetId + ' - ' + bug.errorTypes.toUpperCase() + ' error';
//   params.pid = 10300;
//   // BUG issue type
//   params.issuetype = 10302;
//   //params.reporter = $scope.currentUser.name;
//   // Reported by 'Internal'
//   params.customfield_10500 = 10501;
//   // Environment
//   params.customfield_10501 = Core.userSettings.isStagingServer ? 10503 : 10504;
//   // Error type
//   params.customfield_11900 = this.getJiraErrorTypeCode(bug.errorTypes);
//   params.components = 'NWA';
//   params.description = getErrorDescription(bug);
//
//   // Change widget bug status to logged
//   const amplifyKey = getAmplifyKey(widgetId);
//   bug.isLogged = true;
//   amplify.safeStore(amplifyKey, bug);
//
//   httpOpen('POST', url, params, '_blank');
// };

/**
 * @param bug
 */
// DevTools.postToSlack = function(bug) {
//   const root = 'https://slack.com/api/chat.postMessage';
//   const params = {};
//   params.token = slackToken;
//   params.channel = '#buglogger';
//   params.text = getErrorDescription(bug);
//   params.as_user = false;
//   params.username = 'TapAnalytics';
//   params.icon_url =
//     'https://media.glassdoor.com/sqll/1010430/tapclicks-squarelogo-1445540238367.png';
//
//   const url = `${root}?${  $.param(params)}`;
//
//   $.get(url, function() {
//     return swal({
//       title:
//         '<img src="http://www.freeiconspng.com/uploads/slack-icon-10.png" width="48" height="48" class="mr5" />Message posted to Slack!',
//       type: 'success',
//       html: true
//     });
//   });
// };

/**
 * Parses string to try and detect SQL specific patterns
 *
 * @param type
 * @returns {number}
 */
DevTools.getJiraErrorTypeCode = (type) => {
  const { errorTypes } = constants;
  switch (type) {
    case errorTypes.slim:
      return 13200;
    case errorTypes.api:
      return 13201;
    case errorTypes.sql:
      return 13202;
    case errorTypes.js:
      return 13203;
    case errorTypes.php:
      return 13204;
    default:
      break;
  }
};

/**
 * Returns a query string using on a param object
 * @param params
 * @returns {string}
 */
DevTools.buildQueryString = (params) =>
  // pickBy() excludes null values
  params ? decodeURIComponent(querystring.stringify(pickBy(params))) : '';

// PRIVATE METHODS
/**
 * Allows to open in new window or tab with an HTTP request at the same time
 * @param verb - 'GET'|'POST'
 * @param url
 * @param data
 * @param target - an optional opening target (a name, or "_blank"), defaults to "_self"
 */
// function httpOpen(verb, url, data, target) {
//   const form = document.createElement('form');
//   form.action = url;
//   form.method = verb;
//   form.target = target || '_self';
//   if (data) {
//     for (const key in data) {
//       const input = document.createElement('textarea');
//       input.name = key;
//       input.value = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
//       form.appendChild(input);
//     }
//   }
//   form.style.display = 'none';
//   document.body.appendChild(form);
//   form.submit();
// }

/**
 * Returns a full error description
 * @param bug
 * @returns {string}
 */
// eslint-disable-next-line no-unused-vars
function getErrorDescription(bug) {
  let description = `*Origin URL:* \n\n${bug.originUrl}\n\n`;
  if (bug.fullUrl) {
    description += `*API Call URL:* \n\n${bug.fullUrl}\n\n`;
  }
  description += `*Error:* \n\n${bug.errorMsg}`;
  return description;
}

/**
 * Returns a full url with root + url + ?query params
 * @param response
 * @returns {*}
 */
function getTrimmedUrl(response) {
  if (!response.url) {
    return '';
  }
  let url = response.url.replace(response.baseURL, '');
  if (!startsWith(url, '/')) {
    url = `/${url}`;
  }
  return url;
}

/**
 * Returns a unique hashcode given a string
 * @param str
 * @returns {*}
 */
function hashCode(str) {
  let hash = 0;
  let i;
  let chr;
  let len;
  if (str.length === 0) return null;
  for (i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash).toString();
}

/**
 * Returns a full url with root + url + ?query params
 * @param response
 * @returns {*}
 */
function getFullUrl(response) {
  const queryString = DevTools.buildQueryString(response.params);
  return `${response.baseURL}${response.url}?${queryString}`;
}

if (typeof define === 'function' && typeof define.amd === 'object' && define.amd) {
  // AMD. Register as an anonymous module.
  define(() => DevTools);
} else if (typeof module !== 'undefined' && module.exports) {
  module.exports.DevTools = DevTools;
} else {
  window.DevTools = DevTools;
}

export default DevTools;
