"use strict";
import angular from "angular";
import Vue from "vue";
import "ngVue";
import "ngVue/build/plugins";

import Buefy from "buefy";
import VueXHelper from "@/modules/core/app/helpers/VueXHelper";

import { createStore } from "@/store";

import { StaticOptionPlugin } from "@/modules/core/app/plugins/StaticOptionPlugin";
import { LocalOptionPlugin } from "@/modules/core/app/plugins/LocalOptionPlugin";
import { VueI18nCustomPlugin } from "@/modules/core/app/plugins/VueI18nCustomPlugin";
import { commits } from "@/modules/core/app/helpers/store";
import { LoadingState } from "coreModules/design/widget/design.widget.constants";
import { i18n } from "@/i18n";

const store = createStore();
Vue.use(Buefy, {
  defaultIconPack: "fa"
});
Vue.use(StaticOptionPlugin);
Vue.use(LocalOptionPlugin);
Vue.use(VueI18nCustomPlugin);

import vueMapboxWidget from "grokModules/src/modules/ta/widget/components/types/MapboxAngularPage";
import $ from "jquery";

import widgetMapboxHtmlUrl from "./widget.mapbox.chart.html";
import _ from "lodash";

angular
  .module("widget.mapbox.directives", ["ngVue", "ngVue.plugins"])
  .config($ngVueProvider => {
    $ngVueProvider.setRootVueInstanceProps({
      store: store,
      i18n: i18n
    });
  })
  .directive("vueMapboxWidget", VueMapboxWidgetFunction)
  .directive("mapboxWidget", MapboxWidget);

function VueMapboxWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(Vue.component("vueMapboxWidget", vueMapboxWidget));
}

function MapboxWidget(DesignFactory, ExportFactory, AppFactory, $timeout) {
  return {
    restrict: "E",
    templateUrl: widgetMapboxHtmlUrl,
    scope: {
      state: "=",
      widget: "<",
      widgetTypeId: "<",
      chartId: "<",
      canDrill: "=",
      isRotated: "=",
      isDashboard: '<'
    },
    controller: "ChartWidgetController",
    link: function(scope, el) {
      if (DesignFactory.getIsExportingPage()) {
        scope.$watch("state.loadingState", function(nV) {
          $timeout(function() {
            if (!_.isNull(nV)) {
              switch (nV) {
                case LoadingState.HAS_ERROR:
                case LoadingState.NO_RECORDS:
                case LoadingState.NO_DATA:
                case LoadingState.NO_DATA_ACTIONABLE:
                case LoadingState.ALL_EMPTY_DATA:
                case LoadingState.NO_COLUMNS:
                case LoadingState.TOO_MUCH_DATA:
                case LoadingState.INACTIVE:
                case LoadingState.ERROR:
                  $timeout(function() {
                    ExportFactory.chartReadyCounter.decRef();
                  }, 0);
                  break;
                default:
                  break;
              }
            }
          }, 0);
        });
      }
    }
  };
}
function _setUserSettings(AppFactory) {
  if(!AppFactory.getUser()?.user?.use_nui) {
    VueXHelper.setStore(store);
  }
  commits.session.setClientCurrency($.globals.utils.currencySymbol);
  commits.session.setUserSettings(AppFactory.getUser());
}
