import { NavItem } from '@/modules/core/nav/models/NavItem';
import { NavItemGroupKey } from '@/modules/ta/dashboard/dashboard.constants';

export default class DashboardNavItem extends NavItem {
  /**
   * @var {string}
   */
  thumbnail;

  /**
   * @var {SelectedEntity}
   */
  selected_entity;

  /**
   * @var {boolean}
   */
  is_dash_nav_item;

  /**
   * @param id
   * @returns {string}
   */
  static buildLink(id) {
    return `dash/${id}`;
  }

  /**
   * @param dashboard
   * @returns {string}
   */
  static getGroupKey(dashboard) {
    const selectedEntity = dashboard.metadata && dashboard.metadata.selected_entity;
    return selectedEntity ? NavItemGroupKey.CLIENT_DASHBOARDS : NavItemGroupKey.SHARED_DASHBOARDS;
  }

  constructor(model) {
    super(model);

    this.link = model.link || DashboardNavItem.buildLink(model.id);
    this.group_key = model.group_key || DashboardNavItem.getGroupKey(model);
    this.is_expandable = false;
    this.is_group_child = true;
    this.thumbnail = model.thumbnail;
    this.selected_entity = model.selected_entity;
    this.is_dash_nav_item = true;
  }
}
