'use strict';
import angular from 'angular';
import _ from 'lodash';

import flickityCarouselHtmlUrl from './flickity.carousel.html';

angular.module('core.flickity.components', [])
    .component('flickityAfter', {})
    .directive('flickityCarousel', flickityCarousel)
    .directive('flickityCarouselItem', flickityCarouselItem);

/**
 * @ngInject
 */
function flickityCarousel() {
    return {
        templateUrl: flickityCarouselHtmlUrl,
        bindToController: true,
        scope: {
            options: '<',
            items: '<',
            itemComponent: '<',
        },
        controllerAs: 'vm',
        controller: FlickityCarouselController
    };
}

/**
 * @ngInject
 */
function flickityCarouselItem() {
    return {
        bindToController: true,
        replace: true,
        scope: {
            item: '<',
            component: '<'
        },
        controllerAs: 'vm',
        controller: FlickityCarouselItemController
    };
}

/**
 * @ngInject
 */
function FlickityCarouselController(
    $element,
    $timeout
) {
    var vm = this;
    var defaultOptions = {
        freeScroll: true,
        draggable: true
    };

    vm.$flickityCarouselEl = $element.find('div.flickity-carousel');
    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onChanges = $onChanges;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        vm.isReady = false;
        vm.options = _.extend(defaultOptions, vm.options || defaultOptions);
        vm.$flickityCarouselEl = $element.find('div.flickity-carousel');
        _initFlickityCarousel();
    }

    function $postLink() {
    }

    function $onChanges() {
        _initFlickityCarousel();
    }

    function $onDestroy() {
        _destroyFlickityCarousel();
    }

    function _initFlickityCarousel() {
        $timeout(function() { // Timeout must be used in order to correctly instantiate flickity carousel on angular bound elements
            vm.$flickityCarouselEl.flickity(vm.options);
            vm.isReady = true;
            // if (vm.options.onChange) {
            //     vm.$flickityCarouselEl.on('changed.flickity.carousel', function(e) {
            //         vm.options.onChanged(e);
            //     });
            // }
        }, 0);
    }

    function _destroyFlickityCarousel() {
        // vm.$flickityCarouselEl.trigger('destroy.flickity.carousel').removeClass('flickity-carousel flickity-loaded');
        // vm.$flickityCarouselEl.find('.flickity-stage-outer').children().unwrap();
    }
}

/**
 * @ngInject
 */
function FlickityCarouselItemController(
    $scope,
    $element,
    $compile
) {
    var vm = this;
    vm.$postLink = $postLink;

    function $postLink() {
        var $newScope = $scope.$new(true);
        $newScope.item = vm.item;
        var component = $compile(vm.component)($newScope);
        $element.append(component);
    }
}