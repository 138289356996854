import { WidgetFilter } from '@/modules/ta/widget/models/metadata/dynamic/filters/WidgetFilter';

export class WidgetMetadataDynamic {
  /**
   * @var {Boolean}
   */
  is_inactive;

  /**
   * @var {Array<WidgetFilter>}
   */
  filters;

  /**
   * @var {String}
   */
  filter_set_name;

  /**
   * @var {Array}
   */
  annotations;

  /**
   * @var {Array}
   */
  alerts;

  /**
   * @var {Object}
   */
  predefined_data;

  /**
   * @var {Object}
   */
  raw_data;

  /**
   * @var {Array}
   */
  predefined_filters;

  /**
   * @var {Array}
   */
  warnings;

  /**
   * @var {Array}
   */
  errors;

  constructor(model = {}) {
    this.is_inactive = model.is_inactive;
    this.filter_set_name = model.filter_set_name;
    this.annotations = model.annotations;
    this.alerts = model.alerts;
    this.predefined_data = model.predefined_data;
    this.raw_data = model.raw_data;
    this.predefined_filters = model.predefined_filters;
    this.warnings = model.warnings;
    this.errors = model.errors;
    this.filters = model.filters?.map((filter) => new WidgetFilter(filter)) || [];
  }
}
