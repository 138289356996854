import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { dispatches, getters } from '@/modules/core/app/helpers/store';
import { isEmpty } from 'lodash';

export function useDrillDownEvents() {
  /**
   * Based on a click event use the target to apply that selected drill-down
   * @param ev click event
   * @param id
   */
  function changeChartDrillDown(ev, id) {
    const { dataItem } = ev.target;
    // slice series uses the formatted log date as a label so it's preformatted
    // comparison is also a separate chart so not applicable
    const preFormatted = dataItem.dataContext?.categoryField === Constant.LOG_DATE;
    const isSubSlice = dataItem.dataContext?.isSubSlice;
    let category = isSubSlice ? dataItem.dataContext?.rawMetric : dataItem.get(Constant.CATEGORY);
    if (isSubSlice) {
      const widgetConfig = getters.dashboardDrilldown.getWidgetConfig(id);
      if (!isEmpty(widgetConfig) && widgetConfig.drilldownConfigStack.length !== 0) {
        const lastConfig =
          widgetConfig.drilldownConfigStack[widgetConfig.drilldownConfigStack.length - 1];
        const groupbyLength = widgetConfig.widget.metadata.data_columns?.grouped.length ?? 1;
        if (lastConfig.groupByIndex >= groupbyLength - 1) {
          return;
        }
      }
    }

    const categoryField = dataItem.dataContext?.categoryField
      ? dataItem.dataContext.categoryField
      : dataItem.component.get(Constant.CATEGORY_FIELD);
    let title = '';
    if (categoryField === Constant.LOG_DATE && !isSubSlice) {
      const formattedLogDate = preFormatted
        ? dataItem.dataContext?.category
        : dataItem.get(Constant.FORMATTED + Constant.LOG_DATE);
      const formattedLogDateComparison = dataItem.get(
        Constant.FORMATTED + Constant.LOG_DATE + Constant.COMPARISON
      );
      category = `${formattedLogDate}${
        formattedLogDateComparison ? `|${formattedLogDateComparison}` : ''
      }`;
      if (formattedLogDate) {
        title += `${formattedLogDate}${
          formattedLogDateComparison ? ` ${Constant.VS} ${formattedLogDateComparison}` : ''
        }`;
      } else {
        title += formattedLogDateComparison;
      }
    }
    dispatches.dashboardDrilldown.setDrilldownConfig({
      id,
      category: { [categoryField]: category },
      title,
      isSubSlice,
    });
    dispatches.dashboardDrilldown.reloadWidget(id);
  }

  return {
    changeChartDrillDown,
  };
}
