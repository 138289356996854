import angular from 'angular';

angular.module('core.user.integration', [])
    .service('UserIntegrationResource', UserIntegrationResource);

/**
 * User integration with 3rd parties
 * @ngInject
 */
function UserIntegrationResource() {
    var userIntegrationModeString = 'user-integration-mode';
    var userIntegrationHeaderIdentifier = 'user-integration-header';
    var tapVendastaScriptIdentifier = 'tap_vendasta_script_id';
    var vandestaIntegrated = false;

    this.integrateVendasta = integrateVendasta;
    this.isUserIntegrated = isUserIntegrated;

    /**
     * Integration with Vendasta, all required params are passed from the server
     * Dynamically add the require Vendasta script. <user-integration-header> component
     *  serves as the container for their integration
     * @param vendastaAppId
     * @param vendastaAccountId
     * @param vendastaNavbarDataUrl
     */
    function integrateVendasta(vendastaAppId, vendastaAccountId, vendastaNavbarDataUrl) {
        // if vandesta script is already there, don't re-add integration
        if (document.querySelector('#' + tapVendastaScriptIdentifier)) {
            return;
        }

        var vendasta_script = document.createElement('script');

        vendasta_script.id = tapVendastaScriptIdentifier;
        vendasta_script.src = '//www.cdnstyles.com/static/product_navbar/v1/product_navbar.js';
        vendasta_script.setAttribute('data-url', vendastaNavbarDataUrl);
        vendasta_script.setAttribute('data-account-id', vendastaAccountId);
        vendasta_script.setAttribute('data-app-id', vendastaAppId);

        var body = document.querySelector('body');
        body.classList.add(userIntegrationModeString);

        //this allows you to target the navbar to be appended after a specific element if needed
        vendasta_script.setAttribute('target-element-class', 'user-integration-header-inner');

        body.append(vendasta_script);

        vandestaIntegrated = true;
    }

    /**
     * Getter to return if a user is integrated with a 3rd party.
     * @return {boolean}
     */
    function isUserIntegrated() {
        return vandestaIntegrated;
    }
}