'use strict';
import angular from 'angular';

angular.module('widget.create.services')

    .constant('$WidgetColumnEvents', $EventConstants())
    .factory('WidgetColumnFactory', WidgetColumnFactory);

function $EventConstants() {
    return {
        TOGGLE_HANDLE_DRILL_DOWN_COLUMN: 'widget:create:column:TOGGLE_HANDLE_DRILL_DOWN_COLUMN'
    };
}

/**
 * @ngInject
 */
function WidgetColumnFactory(ScopeFactory) {

    var $columnSelectScope;
    var $columnGroupScope;

    function $registerColumnSelectScope(scope) {
        $columnSelectScope = ScopeFactory.$registerScope(scope, 'widget-columns-select');
    }

    function $registerColumnGroupScope(scope) {
        $columnGroupScope = ScopeFactory.$registerScope(scope, 'widget-groupby-select');
    }

    /**
     * Proxy for handling widget selected column removal
     * @param column
     */
    function $removeSelectedColumn(column) {
        $columnSelectScope.triggerRemoveColumn(column);
    }

    /**
     * Proxy for setting the selected column states
     * @param type
     */
    function $setSelectColumnStates(type) {
        $columnSelectScope.triggerSetColumnStates(type);
    }

    /**
     * Proxy for handling widget grouped column removal
     * @param column
     */
    function $removeGroupedColumn(column) {
        $columnGroupScope.triggerRemoveColumn(column);
    }

    return {
        $registerColumnSelectScope: $registerColumnSelectScope,
        $registerColumnGroupScope: $registerColumnGroupScope,
        $removeSelectedColumn: $removeSelectedColumn,
        $setSelectColumnStates: $setSelectColumnStates,
        $removeGroupedColumn: $removeGroupedColumn
    }
}