import { Constant, PieChartConstant } from '@/modules/core/charts/am5/charts.constants';
import { Label, percent, NumberFormatter } from '@amcharts/amcharts5';
import { useDrillDown } from '@/modules/core/charts/am5/pie/composables/useDrillDown';

export function useSliceLegendEvents(context) {
  const { root, chart, config } = context();
  const { setActualData } = useDrillDown(context);
  /**
   * The slice charts (funnel / pyramid) use slices instead of series
   * so to grey these out we need to target slices instead of the "strokes" in X/Y
   * @param legend
   */
  function addEvents(legend) {
    legend.itemContainers.each((itemContainer) => {
      itemContainer.events.on(Constant.POINTER_OVER, (event) => {
        const { target } = event;
        const { dataItem } = target;
        if (dataItem) {
          const seriesDataItem = dataItem.dataContext;
          if (seriesDataItem) {
            const slice = seriesDataItem.get(Constant.SLICE);
            if (slice && slice.set) {
              greyScaleAllBut(slice);
            }
          }
        }
      });
      itemContainer.events.on(Constant.POINTER_OUT, () => {
        restoreOpacity();
      });
    });
  }

  /**
   * Target every slice in every series and set the opacity for
   * everything but the selected one
   * @param selectedSlice
   */
  function greyScaleAllBut(selectedSlice) {
    chart.value.series.each((series) => {
      series.slices.each((slice) => {
        slice.set(Constant.OPACITY, selectedSlice !== slice ? 0.3 : 1);
      });
    });
  }

  /**
   * Restore the opacity for every slice in every series
   */
  function restoreOpacity() {
    chart.value.series.each((series) => {
      series.slices.each((slice) => {
        const opacity = config.value.comparisonEnabled
          ? config.value.fillOpacityPrior
          : config.value.fillOpacityCurrent;
        slice.set(Constant.OPACITY, opacity);
      });
    });
  }

  /**
   * Logic for handling total count while data is normalized
   */
  function normalizedSum(series, legend) {
    const { data_columns } = config.value.widgetData.metadata;

    const dataToSet = setActualData();
    if (
      config.value.plotType === PieChartConstant.PLOT_DONUT &&
      data_columns.selected.length === 1
    ) {
      let sum = 0;
      dataToSet.forEach((currentValue) => {
        sum += Math.round(currentValue.rawValue);
      });

      const centeryPercent = config.value.pieSemiCircleAngle ? 75 : 50;
      const label = series.children.push(
        Label.new(root.value, {
          text: NumberFormatter.new(root.value, {
            numberFormat: PieChartConstant.COMPACT_FORMAT,
          }).format(sum),
          fontSize: 22,
          centerX: percent(50),
          centerY: percent(centeryPercent),
          populateText: true,
          oversizedBehavior: PieChartConstant.OVERSIZED_DONUT_SUM,
        })
      );
      normalizedLegendClickEvents(legend, label, sum);

      return series;
    }
  }

  /**
   * Handle click events for legend while normalized
   */
  function normalizedLegendClickEvents(legend, label, sum) {
    legend.itemContainers.each((itemContainer) => {
      itemContainer.events.on(Constant.CLICK, (event) => {
        const { dataItem } = event.target;
        const { dataContext } = dataItem.dataContext;
        const slice = dataItem.dataContext.get(Constant.SLICE);

        if (slice && slice.set) {
          const dataInsert = dataContext.rawValue;
          if (slice.get(PieChartConstant.ACTIVE)) {
            sum = handleClick(dataInsert, sum, true);
            slice.set(PieChartConstant.ACTIVE, false);
          } else {
            sum = handleClick(dataInsert, sum, false);
            slice.set(PieChartConstant.ACTIVE, true);
          }
        }

        label.set(
          Constant.TEXT,
          NumberFormatter.new(root.value, {
            numberFormat: PieChartConstant.COMPACT_FORMAT,
          }).format(Math.round(sum))
        );
      });
    });
  }

  /**
   * Logic for adding or subtracting while we're clicking a legend
   */
  function handleClick(currentData, sumSecond, status) {
    if (currentData >= sumSecond) {
      return status ? currentData + sumSecond : currentData - sumSecond;
    }
    return status ? sumSecond + currentData : sumSecond - currentData;
  }

  return {
    addEvents,
    normalizedSum,
  };
}
