import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { CallbackRouteState } from '@/modules/core/callbackroute/store/callbackroute.state';
import { getters } from '@/modules/core/callbackroute/store/callbackroute.getters';
import { mutations } from '@/modules/core/callbackroute/store/callbackroute.mutations';
import { actions } from '@/modules/core/callbackroute/store/callbackroute.actions';

VueXHelper.setStoreModule(Module.CALLBACKROUTE, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new CallbackRouteState(),
  getters,
  mutations,
  actions,
};
