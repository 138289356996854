'use strict';
import angular from 'angular';
import { UndoRedoState } from './exportbuilder.undo.models';

angular.module('exportbuilder.dashboard.services')
    .factory('UndoRedoService', UndoRedoService);

/**
 * @constructor
 * @ngInject
 */
function UndoRedoService(
    $q,
    PubSub,
    $ExportBuilderUndoRedoEvents
) {
    const state = new UndoRedoState();

    return {
        addMutation: addMutation,
        undo: undo,
        redo: redo,
        hasRedos: hasRedos,
        hasUndos: hasUndos,
        getIsPerformingAsyncMutation: getIsPerformingAsyncMutation,
        clearRedos: clearRedos,
        clearUndos: clearUndos,
        clear: clear
    };

    /**
     * @param {MutationModel} mutation
     */
    function addMutation(mutation) {
        const defer = $q.defer();

        if (!mutation.getSingleRun()) {
            state.undoStack.push(mutation);
        }

        state.doStack.length = 0;

        // add to the stack but only triggered when undo-ing
        if (mutation.getDelayed()) {
            return;
        }

        if (mutation.getIsAsync()) {
            state.currentMutationPromise = $q.all(mutation.performRedo())
                .then(() => {
                    defer.resolve();
                    state.currentMutationPromise = null;
                });
        } else {
            mutation.performRedo();
        }
        PubSub.emit($ExportBuilderUndoRedoEvents.REDO);

        return defer.promise;
    }

    /**
     * Perform an undo
     * @returns {Promise}
     */
    function undo() {
        if (state.currentMutationPromise || !hasUndos()) {return}
        const defer = $q.defer();

        let mutation = state.undoStack.pop();

        if (mutation.getIsAsync()) {
            state.currentMutationPromise = $q.all(mutation.performUndo())
                .then(() => {
                    defer.resolve();
                    state.currentMutationPromise = null;
                });
        } else {
            mutation.performUndo()
        }
        PubSub.emit($ExportBuilderUndoRedoEvents.UNDO);

        state.doStack.push(mutation);

        return defer.promise;
    }

    /**
     * Performs a do or redo
     * a DO is the operation to perform when adding the mutation
     * a REDO is simply a synonym
     * @returns {*}
     */
    function redo() {
        if (state.currentMutationPromise || !hasRedos()) {return}
        const defer = $q.defer();

        var mutation = state.doStack.pop();

        if (mutation.getIsAsync()) {
            state.currentMutationPromise = $q.all(mutation.performRedo())
                .then(() => {
                    defer.resolve();
                    state.currentMutationPromise = null;
                });
        } else {
            mutation.performRedo()
        }
        PubSub.emit($ExportBuilderUndoRedoEvents.REDO);

        state.undoStack.push(mutation);

        return defer.promise;
    }

    /**
     * Getter
     * @returns {boolean}
     */
    function hasRedos() {
        return state.doStack.length > 0;
    }

    /**
     * Getter
     * @returns {boolean}
     */
    function hasUndos() {
        return state.undoStack.length > 0;
    }

    /**
     * Getter
     * @returns {boolean}
     */
    function getIsPerformingAsyncMutation() {
        return !!state.currentMutationPromise
    }

    /**
     * Clears all redos
     */
    function clearRedos() {
        state.doStack.clear();
    }

    /**
     * Clears all undos
     */
    function clearUndos() {
        state.undoStack.clear();
    }

    /**
     * Empty undos and redos
     */
    function clear() {
        clearRedos();
        clearUndos();
    }
}
