import { RadarChart, RadarCursor } from '@amcharts/amcharts5/radar';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { getChartConfig } from '@/modules/core/charts/am5/bar/config/chartconfig';

export function useRadarChart(context) {
  const { root, chart, config } = context();

  function createRadarChart() {
    const { radarProps } = getChartConfig(config.value);

    chart.value = root.value.container.children.push(
      RadarChart.new(root.value, {
        layout: root.value.verticalLayout,
        panX: false,
        panY: false,
        wheelX: Constant.NONE,
        wheelY: Constant.NONE,
        ...radarProps,
        maxTooltipDistance: -1,
      })
    );
  }

  function createRadarCursor() {
    const cursor = chart.value.set(
      Constant.CURSOR,
      RadarCursor.new(root.value, {
        behavior: Constant.ZOOM_X,
      })
    );
    cursor.lineY.set(Constant.FORCE_HIDDEN, true);
  }

  return {
    createRadarChart,
    createRadarCursor,
  };
}
