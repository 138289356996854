'use strict';
import angular from 'angular';

angular.module('overview.directives', [])

    .directive('myDirective', [function() {
        return {
            restrict: 'E',
            link: function(scope, el) {

            }
        }
    }])