'use strict';
import angular from 'angular';
import _ from "lodash";
import {
    WidgetBuilderConstants,
    $WidgetBuilderEvents
} from "coreModules/design/widget/builder/widget.builder.constants";
import {WidgetType} from "../widget/design.widget.constants";
import {DashboardEventType} from "../../../../../grok/src/modules/ta/dashboard/dashboard.constants";
import {WidgetHeader, SanitizeHtmlOptions} from "../design.constants";
import sanitizeHtml from 'sanitize-html';

angular.module('widget.header.modal.services', [])
    .factory('WidgetHeaderModalFactory', WidgetHeaderModalFactory);

/**
 * @ngInject
 */
function WidgetHeaderModalFactory(
    PubSub,
    $timeout,
    UIFactory,
    WidgetBuilderService,
    WidgetFactory,
    $PageGenerateThumbnailEvents,
    DesignFactory,
    $WidgetHeaderEvents
) {
    var activeWidgetId = null;
    var modalId = 'widget-header-modal';
    var widgetData = [];
    var passedWidgetData = [];
    var canShowSaveButton = false;

    var shared = {
        closeModal: closeModal,
        onModalClose: onModalClose,
        saveWidgetHeader: saveWidgetHeader,
        showSaveButton: showSaveButton
    }

    return {
        shared,
        initModal: initModal,
        setActiveWidgetId: setActiveWidgetId,
        getActiveWidgetId: getActiveWidgetId,
        setWidgetHeader: setWidgetHeader,
        getWidgetHeader: getWidgetHeader,
        getWidgetData: getWidgetData
    };

    function initModal(widget, data) {
        setWidgetData(widget);
        setActiveWidgetId(widget.id);
        setWidgetHeader(widget.title);
        passedWidgetData = data;
        if (!data) {
            canShowSaveButton = true;
        }
        UIFactory.showModal(modalId);
    }

    function onModalClose() {
    }

    function closeModal(newTitle) {
        if (!canShowSaveButton && (!_.isNull(newTitle) && !_.isUndefined(newTitle))) {
            newTitle = sanitizeTitle(newTitle);
            passedWidgetData.title = newTitle;
            PubSub.emit($WidgetHeaderEvents.REFRESH_TITLE, newTitle);
        }
        canShowSaveButton = false;
        UIFactory.hideModal(modalId);
        $timeout(function() {
        }, 1000, false);
    }

    function setWidgetData(data) {
        widgetData = data;
    }

    function getWidgetData() {
        return widgetData;
    }

    function setActiveWidgetId(id) {
        activeWidgetId = id;
    }

    function getActiveWidgetId() {
        return activeWidgetId;
    }

    function setWidgetHeader(title) {
        widgetData.title = title;
    }

    function getWidgetHeader() {
        return widgetData.title;
    }

    function showSaveButton() {
        return canShowSaveButton;
    }

    function updateWidgetDataHeader(newTitle) {
        widgetData.title = newTitle;
    }

    async function saveWidgetHeader(widgetHeader, id) {
        widgetHeader = sanitizeTitle(widgetHeader);
        widgetHeader = widgetHeader.replace(/\\n/g, "");
        updateWidgetDataHeader(widgetHeader);
        if (passedWidgetData) {
            passedWidgetData.title = widgetHeader;
        } else {
            passedWidgetData = getWidgetData();
        }

        WidgetFactory.save(angular.copy(passedWidgetData))
            .then(widget => WidgetFactory.getWidget(widget.id, {all: true}))
            .then(async function(newWidget) {

                // If widget was added/edited, it is safe to assume that it can be modified by the same user
                newWidget.can_be_edited = true;
                newWidget.can_be_deleted = true;
                newWidget.can_be_copied = true;

                if (newWidget.layout_id) {
                    PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: newWidget.layout_id, page: DesignFactory.getCurrentPage() });
                }

                PubSub.emit($WidgetBuilderEvents.DID_SAVE_WIDGET, newWidget);
                if (passedWidgetData) {
                    PubSub.emit($WidgetHeaderEvents.REFRESH_TITLE, newWidget.title);
                }
                if (window.isNUI) {
                    PubSub.emit("SegmentEvents", {
                        event: newWidget.type === WidgetType.ACCOUNTMANAGER ? DashboardEventType.EDIT_ADMIN_WIDGET : newWidget.type === WidgetType.MEDIA ? DashboardEventType.EDIT_MEDIA_WIDGET : DashboardEventType.EDIT_DATA_WIDGET,
                        payload: {widget: newWidget, page: DesignFactory.getCurrentPage()}
                    });
                }
            })
            .finally(function() {
                closeModal();
            });
    }

    /**
     * Sanitizes (strips) not allowed html tags from the title
     *
     * @param title
     * @returns {string|*}
     */
    function sanitizeTitle(title) {
        if (title) {
            let sanitizedTitle = sanitizeHtml(title, SanitizeHtmlOptions);

            return sanitizedTitle ? sanitizedTitle : 'Untitled Widget';
        }

        return widgetData.type === WidgetType.MEDIA ? title : 'Untitled Widget';
    }
}