'use strict';
import angular from 'angular';

angular.module('calculations.services', [])

    .service('CalculationFactory', CalculationFactory);

CalculationFactory.$inject = ['Restangular', 'ServiceDataFactory', 'CategoryDataFactory', 'DataSourceType', 'ColumnValueType'];
function CalculationFactory(Restangular, ServiceDataFactory, CategoryDataFactory, DataSourceType, ColumnValueType) {
    var calculations = Restangular.service('calculations');

    var getMetrics = function(dataSource, dataView) {
        dataSource.data_view = dataView;
        //determine which set of metrics to fetch dependant on the existence of 'category' in the dataSource param
        return dataSource.type === DataSourceType.CATEGORY_DATA
            ? CategoryDataFactory.getColumns({is_metric: true, ignore_reporting_profile: true}, dataSource)
            : ServiceDataFactory.getColumns({is_metric: true, ignore_reporting_profile: true}, dataSource);
    };

    let getDataTypes = function() {
        let values = calculations.one('values');
        return values.one(ColumnValueType.DISTINCT).get({fields: 'data_type'});
    };

    return {
        calculations: calculations,
        getMetrics: getMetrics,
        getDataTypes: getDataTypes
    }
}
