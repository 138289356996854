'use strict';
import angular from 'angular';

angular.module('core.service.ctrls', [])

    .controller('DataFreshnessDateDisplayController', DataFreshnessDateDisplayController);

/**
 * Display data freshness date for services
 * @ngInject
 */
function DataFreshnessDateDisplayController(
    $scope,
    AppFactory
) {
    // Applies to service/ category specific widgets
    $scope.showDataFreshnessDate = AppFactory.getUser().showDataFreshnessDate
        && $scope.datasource
        && $scope.datasource.include_data_freshness_date;

    function setData() {
        if ($scope.showDataFreshnessDate && $scope.predefinedData) {
            $scope.dataFreshnessDate = $scope.predefinedData.extra_data.data_freshness_date;
        }
    }

    $scope.$watch('predefinedData', function (nV, oV) {
        if (AppFactory.invalidateWatch(nV, oV)) {
            return;
        }
        setData();
    });
}