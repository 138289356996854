'use strict';
import angular from 'angular';
import {ManageProductsColumns} from "../manageproducts.constants";

class ManageProductsService {

    /**
     * @ngInject
     */
    constructor(
        ManageProductsResource
    ) {
        this.ManageProductsResource = ManageProductsResource;
        this.products = [];
    }

    /**
     * @param products
     */
    setProducts(products) {
        this.products = products;
    }

    /**
     * @returns {Array}
     */
    getProducts() {
        return this.products;
    }

    /**
     * @returns {*}
     */
    getAllProducts() {
        return this.ManageProductsResource.getProducts().then(data => {
            this.products = data;
            return this.products;
        });
    }
}

class ManageProductsFactory {
    defaultProps = {
        state: {
            isLoading: true
        },
        data: {
            products: []
        }
    };

    /**
     * @ngInject
     */
    constructor(
        DetailsModalFactory,
        DetailsModalModelFactory,
        DetailsModalSize,
        ManageProductsResource,
        DataSourceType,
        gettextCatalog
    ) {
        this.DetailsModalFactory = DetailsModalFactory;
        this.DetailsModalModelFactory = DetailsModalModelFactory;
        this.DetailsModalSize = DetailsModalSize;
        this.ManageProductsResource = ManageProductsResource;
        this.DataSourceType = DataSourceType;
        this.gettextCatalog = gettextCatalog;

        this.props = angular.copy(this.defaultProps);
    }

    /**
     * @param data
     * @param row
     */
    setNewDetails() {
        this.DetailsModalFactory.setAsNew(true);
        return this._setDetails([], this.gettextCatalog.getString('New Product'));
    }

    /**
     * @param data
     * @param row
     */
    setEditDetails(data, row) {
        let promises = [
            this.ManageProductsResource.products.get(data[ManageProductsColumns.ID], {all: true})
        ];
        return this._setDetails(promises, data[ManageProductsColumns.NAME], row);
    }

    /**
     * @param promises
     * @param title
     * @param [row]
     * @private
     */
    _setDetails(promises, title, row) {
        return this.DetailsModalFactory.setDetails(this.DetailsModalModelFactory.getModalOptions({
            dataSourceType: this.DataSourceType.PRODUCT,
            promises: promises,
            resource: this.ManageProductsResource.products,
            modalSize: this.DetailsModalSize.SMALL,
            display: {
                title: title,
                loadingMessage: this.gettextCatalog.getString('Loading Product'),
                icon: 'icomoon-tag',
            },
            row: row
        }));
    }
}

angular.module('core.manageproducts.services', [])
    .service('ManageProductsService', ManageProductsService);

angular.module('core.manageproducts.factory', [])
    .service('ManageProductsFactory', ManageProductsFactory);