import { reject } from 'lodash';

export const mutations = {
  addLoader(state, loader) {
    state.loaders.push(loader);
  },
  removeLoader(state, loader) {
    state.loaders = reject(state.loaders, (one) => one === loader);
  },
  resetLoaders(state) {
    state.loaders = [];
  },
  addAutoPlayGuide(state, guideName) {
    state.autoPlayGuides.push(guideName);
  },
  removeAutoPlayGuide(state, guideName) {
    state.autoPlayGuides = reject(state.autoPlayGuides, (one) => one === guideName);
  },
};
