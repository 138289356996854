'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('datasource.directives')

    .directive('datasourceColumnsSelect', datasourceColumnsSelect);

datasourceColumnsSelect.$inject = [
    'LoadingState',
    'AppFactory',
    'DataSourceColumnFactory',
    'DataSourceFactory',
    'gettextCatalog'
];
function datasourceColumnsSelect(
    LoadingState,
    AppFactory,
    DataSourceColumnFactory,
    DataSourceFactory,
    gettextCatalog
) {
    return {
        restrict: 'A',
        scope: {
            datasource: '=',
            selectedColumns: '=',
            state: '='
        },
        link: function(scope, el) {
            var dataTypes = AppFactory.getDataTypes();
            var output = [];

            initSelect();

            function initSelect() {

                var queryParams = {
                    is_hidden: false
                };

                var buildSelect2 = function(columns, selectedColumns) {
                    var options = {
                        placeholder: gettextCatalog.getString('Select a data column...'),
                        multiple: false,
                        allowClear: false,
                        dropdownCssClass: 'column-select-dropdown',
                        formatResult: function (item) {
                            if (!item.id) { return item.text; }
                            var dataType = _.find(dataTypes, {key: item.format});

                            var html = '<label class="label">';
                            if (_.startsWith(dataType.icon, 'icon')) {
                                html += '<span class="icon '+ dataType.icon +'"></span>';
                            }
                            else {
                                html += '<span class="icon">'+ dataType.icon +'</span>';
                            }
                            html += '</label>';
                            html += '<span>' + item.text + '</span>';

                            return $(html);
                        }
                    };
                    options.formatSelection = options.formatResult;

                    output = DataSourceColumnFactory.preProcessSelectColumns(columns);

                    _.each(selectedColumns, function(data) {
                        data.id = data.field;
                        scope.selectColumn(data, output, false);
                    });

                    options.data = function() {
                        return {results: output};
                    };

                    el.select2(options);
                };

                scope.isLoadingColumns = LoadingState.FETCHING;
                // The widgetFactoryConfig will help specify what kind of datasourced data config we need
                DataSourceFactory.getColumns(scope.datasource, queryParams).then(function (columns) {
                    buildSelect2(columns);
                    scope.isLoadingColumns = LoadingState.DONE;
                });
            }

            $(el).on('change', function() {
                scope.$evalAsync(function() {
                    var data = el.select2('data');
                    scope.selectColumn(data, output, true);

                    // Remove the selected in the select2 display
                    el.select2('data', null);
                });
            });
        }
    }
}