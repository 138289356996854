import { NavItem } from '@/modules/core/nav/models/NavItem';

export class DataSourceNavItem extends NavItem {
  /**
   * @var {string}
   */
  icon;

  /**
   * @var {NavMenuLink}
   */
  color;

  constructor(model = {}) {
    super(model);

    this.icon = model.icon;
    this.color = model.color;
  }
}
