import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';

export class ExecutiveSummaryEntity extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  data_view_id;

  /**
   * @var {string}
   */
  name;

  /**
   * @var {string}
   */
  type;

  /**
   * @var {string}
   */
  type_name;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.data_view_id = model.data_view_id;
    this.name = model.name;
    this.type = model.type;
    this.type_name = model.type_name;
  }

  getEntityRoute() {
    return `/${this.type}s/${this.id}`;
  }
}
