'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('datasource.ctrls', [])

    .controller('SelectDataSourceController', SelectDataSourceController)
    .controller('DataSourceSelectorController', DataSourceSelectorController)
    .controller('DataViewSelectController', DataViewSelectController);

/**
 * Data source type selector
 * @constructor
 * @ngInject
 */
function SelectDataSourceController(
    $scope
) {
    $scope.updateDataSourceSelect = function (data) {
        // data is null when deselecting a data source
        if (data) {
            var dataSource = $scope.datasource;
            dataSource.type = data['id'];
            dataSource.name = data['text'];
            dataSource.icon = data['icon'];
            dataSource.id = data.is_of_type_service ? dataSource.id || null : 0;
            dataSource.data_view = data.is_of_type_service ? dataSource.data_view || null : 0;

            dataSource.requires_group_by = data.requires_group_by;
            dataSource.is_of_type_service = data.is_of_type_service;
            dataSource.primary_date_field = data.primary_date_field;
            dataSource.requires_date_range = data.requires_date_range;

            if (!data.is_of_type_service) {
                $scope.state.isBuilding = true;
                $scope.state.currentInstruction = 'Please select at least one column';
            }
        }
    };
}

/**
 * selected data source selector
 * @param DataSourceType
 * @constructor
 * @ngInject
 */
function DataSourceSelectorController(
    $scope,
    DataSourceType
) {
    $scope.updateSelect = function (data) {
        if (!_.isNil(data)) {
            var dataSource = $scope.datasource;
            dataSource.data_view = dataSource.is_of_type_service ? dataSource.data_view || null : 0;
            dataSource.id = data['id'];
            dataSource.id_name = data['text'];
            dataSource.icon = data['icon'];
            dataSource.color = data['color'];
            dataSource.has_custom_icon = data['has_custom_icon'];
            dataSource.is_custom_service = data.type === DataSourceType.SERVICE_DATA ? data['is_custom_service'] : null;
        }
        else {
            var dataSource = $scope.datasource;
            dataSource.data_view = dataSource.is_of_type_service ? dataSource.data_view || null : 0;
            dataSource.id = null;
            dataSource.id_name = null;
            dataSource.icon = null;
            dataSource.color = null;
            dataSource.has_custom_icon = null;
            dataSource.is_custom_service = null;
        }
    };
}

/**
 * Data view data source selector
 * @constructor
 * @ngInject
 */
function DataViewSelectController(
    $scope
) {
    $scope.updateDataViewSelect = function (data) {
        var dataSource = $scope.datasource;

        if (data == null) {
            debugger;
        }

        dataSource.data_view = data['id'];
        dataSource.data_view_name = data['name'];
        dataSource.data_view_is_geo = data['is_geo'];

        if ($scope.state) {
            $scope.state.isBuilding = true;
            $scope.state.currentInstruction = 'Please select at least one column';
        }
    };
}