import { BaseLegacyResource } from '@/modules/core/app/resources/BaseLegacyResource';
import { LegacyRouteGroup } from '@/modules/core/app/constants/api.constants';
import { LegacyAssetModel } from '@/modules/legacy/ui/models/LegacyAssetModel';

export class LegacyAssetResource extends BaseLegacyResource {
  constructor() {
    super(LegacyRouteGroup.GETLOGOURL);
  }

  async getAssetUrl(assetName, ext = 'png') {
    return this.getData(LegacyAssetModel, '', { asset_name: assetName, ext: ext });
  }
}

export default new LegacyAssetResource();
