import { BaseForm } from '@/modules/core/app/models/abstracts/BaseForm';

export class CustomTranslationForm extends BaseForm {
  /**
   * @returns {string}
   */
  getSuccessMessage() {
    return i18n.$t('Custom Translation successfully saved.');
  }
}
