'use strict';
import angular from 'angular';
import $ from 'jquery';
import importCsvHtmlUrl from '../views/directives/importCsv.html';

angular.module('core.datatable.directives', [])
    .directive('legacyDatatable', legacyDatatable)
    .directive('deleteRow', deleteRow)
    .directive('terminateRow', terminateRow)
    .directive('downloadCsv', downloadCsv)
    .directive('importCsv', importCsv)
    .directive('uploadCsvInput', uploadCsvInput);

//
// Directive for creating datatables
//
legacyDatatable.$inject = [];
function legacyDatatable() {
    return {
        restrict: 'A',
        scope: {
            dtOptions: '='
        },
        link: function (scope, el) {
            var dtOptions = scope.dtOptions;
            var options = $.core.datatable.defaultOptions(dtOptions.itemName);

            if(dtOptions.hasOwnProperty('hasFixedHeader')){
                options.hasFixedHeader = dtOptions.hasFixedHeader;
            }

            //scroller overwrite block for io functionality
            if(dtOptions.hasOwnProperty('scroller')){
                options.dom = 'ft';
                options.deferRender = true;
                options.scrollY = '70vh';
                options.scrollCollapse = true;
                options.scroller = true;
            }

            if(dtOptions.hasOwnProperty('inner')){
                options.dom = 'ft';
                options.responsive = {
                    details: {
                        type: 'column',
                        target: 0
                    }
                };
                options.columnDefs = [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -2 }
                ];
            }

            if(dtOptions.hasOwnProperty('modal')){
                options.lengthMenu = [10,25,50];
                options.iDisplayLength = 10;
            }

            if(dtOptions.hasOwnProperty('fnInitComplete')) {
                options.fnInitComplete = dtOptions.fnInitComplete;
            }

            options.actionCallbacks = dtOptions.actionCallbacks || {};
            options.iActions = dtOptions.iActions;
            options.data = dtOptions.data;
            options.fnCreatedRow = dtOptions.fnCreatedRow;
            options.columns = [];
            options.columnDefs = [];

            angular.forEach(dtOptions.columns, function (column) {
                if (!column.is_hidden && !column.show_on_group_by) {
                    var columnOptions = {};
                    columnOptions.orderable = column.sortable;
                    columnOptions.type = column.typeOverride;
                    if (column.is_primary_key) {
                        columnOptions.data = column.field;
                        columnOptions.className = 'action-cell';
                        columnOptions.title = 'Actions';
                        columnOptions.orderable = false;
                        //Allow overriding primary key column (edit, delete buttons, etc.)
                        if (dtOptions.customRenders && dtOptions.customRenders[column.field]) {
                            columnOptions.render = dtOptions.customRenders[column.field];
                        }
                        else {
                            columnOptions.render = function (data, type, full) {
                                var html = $.core.datatable.displayEditRowButton(data, dtOptions, full);
                                html += $.core.datatable.displayCopyRowButton(data, dtOptions, full);
                                html += $.core.datatable.displayDeleteRowButton(data, dtOptions, full);
                                return html;
                            }
                        }
                    }
                    else {

                        if (dtOptions.customRenders && dtOptions.customRenders[column.field]) {
                            columnOptions.render = dtOptions.customRenders[column.field];
                        }
                        else if (column.format == 'bool') {
                            columnOptions.render = function (data) {
                                return data ? '<span class="icon icon-check"></span>' : '';
                            }
                        }
                        else if (column.format == 'object') {
                            columnOptions.render = function (data) {
                                return data.value;
                            }
                        }
                        else if (dtOptions.customRenders && dtOptions.customRenders[column.field] !== undefined) {
                            columnOptions.render = dtOptions.customRenders[column.field];
                        }
                        columnOptions.data = column.field;
                        columnOptions.title = column.label;
                        columnOptions.className = column.className || '';

                    }
                    options.columns.push(columnOptions);
                }
            });
            $.core.datatable.build(el, options);
        }
    }
}

//
// Directive for delete rows on datatables
//
deleteRow.$inject = ['AppService'];
function deleteRow(AppService) {
    return {
        restrict: 'A',
        link: function (scope, el) {
            el.click(function(e) {
                var obj = $(this).data('obj');
                var $row = obj.closest('tr');
                var callback = $(this).data('callback');

                AppService.deleteRow($(this).data('obj'), $row, $('#delete-row-confirm-modal'), callback);
            });
        }
    }
}


//
// Directive for terminate rows on datatables
//
terminateRow.$inject = ['AppService'];
function terminateRow(AppService) {
    return {
        restrict: 'A',
        link: function (scope, el) {
            el.click(function(e) {
                var obj = $(this).data('obj');
                var $row = obj.closest('tr');

                AppService.deleteRow($(this).data('obj'), $row, $('#terminate-row-confirm-modal'));
            });
        }
    }
}

//
// Directive for downloading csv from datatables
//
downloadCsv.$inject = ['AppService'];
function downloadCsv(AppService) {
    return {
        restrict: 'A',
        scope: {
            downloadUrl: '=',
            params: '='
        },
        link: function (scope, el) {
            el.click(function(e) {
                $.core.main.showAjaxMainLoading({text: 'Exporting to csv'});
                var params = scope.params || $(this).data('params');
                AppService.downloadCsv(scope.$root.util.mageUrl(scope.downloadUrl), params);
            });
        }
    }
}

//
// Directive for importing CSV entity lists
//
importCsv.$inject = ['AppFactory'];
function importCsv(AppFactory) {
    return {
        restrict: 'E',
        scope: {
            datatableId: '=',
            downloadUrl: '=',
            uploadUrl: '=',
            summaryDownloadUrl: '=',
            uploadEntity: '=',
            summaryTableName: '=',
            isHipaaInstance: '=',

        },
        templateUrl: importCsvHtmlUrl,
        controller: function($scope, AppFactory) {
            $scope.brandMappings = AppFactory.getBrandMappings();
            $scope.importCsvModal = {};
            $scope.importCsvModal.datatableId = $scope.datatableId;
            $scope.importCsvModal.downloadUrl = $scope.downloadUrl;
            $scope.importCsvModal.uploadUrl = $scope.uploadUrl;
            $scope.importCsvModal.uploadEntity = $scope.uploadEntity;
            $scope.importCsvModal.isHipaaInstance = AppFactory.getUser().isHipaaInstance;
            $scope.importSummary = {};
            $scope.importSummary.downloadUrl = $scope.summaryDownloadUrl;
            $scope.importSummary.tableName = $scope.summaryTableName;
        }
    }
}

//
// Directive for the file input on CSV uploads
//
uploadCsvInput.$inject = ['$state'];
function uploadCsvInput() {
    return {
        restrict: 'A',
        scope: {
            modalId: '@',
            datatableId: '=',
            uploadUrl: '=',
            importSummary: '='
        },
        link: function(scope, el) {
            el.fileinput({
                uploadAsync: false,
                showPreview: false,
                showRemove: false,
                showUpload: true,
                dropZoneEnabled: false,
                uploadUrl: scope.uploadUrl,
                browseLabel: 'Select CSV file',
                browseIcon: '<i class="icon icon-file-excel-o"></i>',
                removeClass: "btn btn-danger",
                removeIcon: '<i class="icon icon-trash"></i>',
                uploadClass: "btn btn-info",
                uploadIcon: '<i class="icon icon-upload"></i>',
                cancelIcon: '<i class="icon icomoon-cross"></i>'
            }).on('filebatchuploadsuccess', function(event, data, previewId, index) {
                el.fileinput('clear');
                $.core.main.notify('User import was successful', $.globals.notify.success);
                $.core.main.hideModal($('#' + scope.modalId));
                $.core.datatable.oTables[scope.datatableId].oTable.api().draw();
            }).on('filebatchuploaderror', function(event, data) {
                el.fileinput('clear');
                var report = data.response.error;
                if (typeof report != 'undefined') {
                    if (typeof report == 'string') { // one error: as a string
                        $.core.main.formErrorNotify({'data':report});
                    }
                    else {
                        var rows = report.rows; //This includes both error & success rows
                        //If array of errors, display error summary table
                        if ($.isArray(rows)) {
                            scope.$evalAsync(function() {
                                scope.importSummary.successRowCount = report.success_row_count;
                                scope.importSummary.errorRowCount = report.rows.length - report.success_row_count;
                            });
                            var $modal = $('#upload-summary-modal');
                            $.core.main.showModal($modal);
                            $modal.find('div.modal-footer a').data('params', {error: rows});

                            var $table = $('#import-summary-table');
                            var options = $.core.datatable.defaultOptions('summary row');
                            options.hasFixedHeader = false;

                            //Convert status into html color coded labels
                            options.rowCallback = function (row, data) {
                                var statusField = data[data.length-2];
                                var $statusCell = $(row).find('td:eq('+(data.length-2)+')');
                                var statusType = statusField == 'FAILED' ? 'danger' : 'success';
                                $statusCell.html('<label class="label label-' + statusType + '">' + statusField + '</label>');
                            };

                            options.data = rows;

                            if (!$.fn.DataTable.isDataTable('#' + $table.attr('id'))) {
                                $.core.datatable.build($table, options);
                            }
                            else {
                                $table.DataTable().destroy();
                                $.core.datatable.build($table, options);
                            }
                        }
                        else {
                            $.core.main.formErrorNotify(report);
                        }
                    }
                }
                else {
                    $.core.main.formErrorNotify('There was an error with the upload of your file.');
                }
            });
        }
    }
}