import { cloneDeep } from 'lodash';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import { WidgetContextService } from '@/modules/ta/widget/services/WidgetContextService';
import TimeGroupingResource from '@/modules/core/app/resources/TimeGroupingResource';
import { WidgetBuilderConstants, WidgetSize } from '@/modules/ta/widget/widget.constants';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import ServiceDataWidgetDataResource from '@/modules/ta/widget/resources/ServiceDataWidgetDataResource';
import WidgetService from '@/modules/ta/widget/services/WidgetService';
import WidgetContextFactory from '@/modules/ta/widget/models/context/WidgetContextFactory';
import UserFormService from '@/modules/ta/user/services/UserFormService';
import { DataSource } from '@/modules/core/datasource/models/DataSource';
import { WidgetFactory } from '@/modules/ta/widget/factories/WidgetFactory';
import { WidgetDataResourceFactory } from '@/modules/ta/widget/factories/builder/WidgetDataResourceFactory';
import { MapPlotType } from '@/modules/core/maps/maps.constants';

export class WidgetBuilderFormService extends BaseService {
  /**
   * @param {WidgetType} widgetType
   */
  buildDefaultWidget(widgetType) {
    const widget = WidgetFactory.build({
      id: WidgetBuilderConstants.NEW_WIDGET_ID,
      type: widgetType.id,
      title: i18n.$t('Untitled widget'),
      height: WidgetSize.DEFAULT_HEIGHT,
      width: WidgetSize.MAX_WIDTH,
    });

    if (widget.isAdminWidget()) {
      widget.data_type = DataSourceType.USER;
      widget.metadata.data_source = new DataSource({
        type: DataSourceType.USER,
      });
    }

    if (widget.isMediaWidget()) {
      widget.cached_data = '';
    }

    if (widget.isGeoChartWidget()) {
      widget.metadata.draw_options.plot_type = MapPlotType.BUBBLE_MAP;
    }

    return widget;
  }

  /**
   * @param {WidgetTemplate} template
   */
  async buildFromTemplate(template) {
    const widget = await WidgetService.getItem(template.id);

    widget.id = WidgetBuilderConstants.NEW_WIDGET_ID;
    widget.height = WidgetSize.DEFAULT_HEIGHT;
    widget.width = WidgetSize.MAX_WIDTH;
    widget.is_in_library = false;
    widget.is_predefined = false;
    WidgetContextFactory.getContext().setParentProperties(widget);

    return widget;
  }

  /**
   * @param {Widget} oldWidget
   * @param {Widget} newWidget
   * @returns {*}
   */
  swapWidget(oldWidget, newWidget) {
    let copiedWidget = cloneDeep(oldWidget);

    copiedWidget.type = newWidget.type;

    if (newWidget.is_in_library) {
      newWidget.height = copiedWidget.height;
      newWidget.width = copiedWidget.width;
      newWidget.id = copiedWidget.id;
      newWidget.display_order = copiedWidget.display_order;
      copiedWidget = cloneDeep(newWidget);
    }

    return copiedWidget;
  }

  /**
   * @param {Widget} widget
   * @returns {ApiColumn[]}
   */
  async retrieveColumns(widget) {
    const params = {
      ignore_reporting_profile: true,
      sort: 'label',
      widget_request: true,
      page_id: WidgetContextService.getParentId(),
    };
    if (widget.isAdminWidget()) {
      params.is_selectable = true;
      params.is_hidden = false;
      return UserFormService.getColumns(null, params);
    }
    const { data_source_id, data_view_id, data_type } = widget;

    return this.getColumns(data_source_id, data_view_id, data_type, params);
  }

  /**
   * @param {Widget} widget
   * @returns {{key, value}}
   */
  async retrieveTimeGroupings(widget) {
    const params = {
      has_live_integration: widget.has_live_integration,
      service_id: widget.data_source_id,
    };
    // TODO: create service
    return TimeGroupingResource.getGroupings(params);
  }

  /**
   * @returns {*}
   */
  saveForm(form, extraWidgetMap) {
    form.preSave();
    form.validate();
    return WidgetService.saveBuilder({ widget: form.getFormSavableData(), extra: extraWidgetMap });
  }

  /**
   * @param data_source_id
   * @param data_view_id
   * @param data_type
   * @param params
   * @returns {Promise}
   */
  async getColumns(data_source_id, data_view_id, data_type, params) {
    return WidgetDataResourceFactory.getResource(data_type)?.getColumns(
      data_source_id,
      data_view_id,
      params
    );
  }

  /**
   * @param data_source_id
   * @param data_view_id
   * @param field
   * @returns {Promise<*>}
   */
  getValuesDistinct(data_source_id, data_view_id, field) {
    return ServiceDataWidgetDataResource.getValuesDistinct(data_source_id, data_view_id, field);
  }
}

export default new WidgetBuilderFormService();
