<template>
  <section
    :class="{ 'is-comparing': hasComparison }"
    :style="[
      fontColorStyles,
      containerStyles,
      calculatedSize,
      borderStyles,
      radiusStyles,
      radiantStyles,
    ]"
    data-cy="rectangle-container"
  >
    <div class="value" v-if="isLoadingValue">
      <p class="subtitle" data-cy="loader">
        <span>{{ $t('Loading...') }}</span>
      </p>
    </div>
    <div v-else>
      <p
        class="card-label text-align-center"
        :style="
          getFontSizeStyle(
            DataLabelMultiplierSquare.MIN,
            DataLabelMultiplierSquare.MAX,
            MaxFontSizes.METRIC_LABEL
          )
        "
        data-cy="label"
        :class="showMetricName"
      >
        {{ label }}
      </p>
      <div class="value-container">
        <div class="value">
          <span
            :style="[
              fontStyles,
              getFontSizeStyle(DataFontMultiplierSquare.MIN, DataFontMultiplierSquare.MAX),
            ]"
            data-cy="value"
            :title="emptyDataMessage"
          >
            <b-tooltip
              :active="!!isFormattedEllipsisActive()"
              :label="isFormattedEllipsisActive()"
              type="is-dark"
              size="is-small"
            >
              <div class="overflow-value" ref="formattedTooltip">
                {{ formattedValue }}
              </div>
            </b-tooltip>
          </span>
        </div>
        <div v-if="hasComparison && showComparisonArrows">
          <div
            :style="
              getFontSizeStyle(
                DataComaprisonMultiplierSquare.MIN,
                DataComaprisonMultiplierSquare.MAX
              )
            "
          >
            <span data-cy="delta-value">{{ formattedDeltaValue }}</span>
            <span v-if="!compareAsValue">%</span>
            <svg
              v-if="isExportingReportStudio"
              class="delta-value"
              :class="[deltaIconClass, deltaValueClasses]"
              :style="
                getIconSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                )
              "
            >
              <use xlink:href="" :href="`#${deltaIconClass}`"></use>
            </svg>
            <b-icon
              v-else
              pack="fa"
              class="delta-value"
              :class="deltaValueClasses"
              :custom-class="iconClass"
              :style="
                getFontSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                )
              "
              data-cy="delta-icon"
            ></b-icon>
          </div>
          <span
            class="subtitle"
            :style="[
              getFontSizeStyle(
                DataComaprisonFontMultiplierSquare.MIN,
                DataComaprisonFontMultiplierSquare.MAX
              ),
            ]"
            data-cy="delta-subtitle"
          >
            {{ $t('vs') }}
            <span>{{ formattedComparisonValue }}</span>
            {{ $t('prev.') }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ScoreCardMixin } from '@/modules/core/scorecard/mixins/ScoreCardMixin';

export default {
  name: 'RectangleCard',
  mixins: [ScoreCardMixin],
  props: {
    cornerRadius: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    calculatedSize() {
      const width = this.wrapSize * 3;
      const height = this.wrapSize * 1.6;
      return { width: `${width}px`, height: `${height}px` };
    },
    radiusStyles() {
      return { 'border-radius': `${this.cornerRadius}px` };
    },
  },
};
</script>

<style scoped lang="scss">
section {
  position: relative;
  background-color: $background-add-contrast;
  padding: rem(10px) rem(15px);
  border-radius: $radius-small;
}

.value {
  min-width: 50%;
}

.value-container {
  top: 35%;
  position: absolute;
  left: 0;
  right: 0;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include align-content(center);
}

.delta-value {
  &.positive-delta {
    color: $green;
  }

  &.negative-delta {
    color: $red;
  }
}
.text-align-center {
  text-align: center;
}

.b-tooltip {
  display: block;
}

.card-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.overflow-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
