/**
 * @class StyleProperty
 */
export class StyleProperty {
  /**
   * Example 'cursor'
   * @var {String}
   */
  property;

  /**
   * Example 'pointer'
   * @var {Any}
   */
  value;

  constructor(model) {
    this.property = model.property;
    this.value = model.value;
  }
}
