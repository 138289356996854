import moment from 'moment';
import { isFunction } from 'lodash';

export class CacheHelper {
  /**
   * Storage engine
   * @typedef {Object} StorageEngine
   * @property {function(key: string): string} getItem Retrieves value by key
   * @property {function(key: string, value: string)} setItem Stores value with specified key
   * @property {function(key: string)} removeItem Removes key and value associated with it
   */

  /** @var {StorageEngine} */
  storageEngine;

  /**
   * @param {StorageEngine} storageEngine
   */
  constructor(storageEngine = window.localStorage) {
    const missingFunctions = ['getItem', 'setItem', 'removeItem'].filter(
      (func) => !isFunction(storageEngine[func])
    );
    if (missingFunctions.length) {
      const message = `StorageEngine object missing required functions: [${missingFunctions.join(
        ', '
      )}]`;
      Logger.log(message, Logger.LEVEL_ERROR);
    }
    this.storageEngine = storageEngine;
  }

  /**
   * Sets an item into browser's storageEngine with given expiry
   * @param {string} key
   * @param {any} value
   * @param {number} expireAt
   */
  set(key, value, expireAt) {
    const item = {
      value,
      expireAt,
    };
    try {
      this.storageEngine.setItem(key, JSON.stringify(item));
    } catch (e) {
      // return false in case of error like storageEngine is full
      return false;
    }
    return true;
  }

  /**
   * Returns item if exist and not expired
   * @param {string} key
   */
  get(key) {
    const itemStr = this.storageEngine.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    try {
      const item = JSON.parse(itemStr);
      // If the item is expired, remove the item from storage and return null
      if (this._isExpired(item)) {
        this.remove(key);
        return null;
      }
      return item.value;
    } catch (e) {
      // return null in case of any error
      return null;
    }
  }

  /**
   * Removes previously set item
   * @param {string} key
   */
  remove(key) {
    this.storageEngine.removeItem(key);
  }

  /**
   * Compare the expireAt time of the item with the current time
   * @private
   * @param {object} item
   * @return {bool} isExpired
   */
  _isExpired(item) {
    return item.expireAt && moment().isAfter(moment.unix(item.expireAt));
  }
}

export default new CacheHelper();
