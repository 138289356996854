import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { DrawOptionLocation } from '@/modules/ta/widget/widget.constants';
import { Layout } from '@/modules/ta/dashboard/models/Layout';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';

export class LayoutResource extends BaseEntityResource {
  constructor() {
    super(RouteGroup.DASH_LAYOUTS, Layout);
  }

  async saveDrawOptions(id, drawOptions, location = DrawOptionLocation.DASHBOARD) {
    return this.apiHelper.post(`/${this.routeGroup}/${id}/${location}/drawoptions`, {
      draw_options: drawOptions,
    });
  }

  /**
   * Add new layout.
   * @param model
   * @returns {Promise<*>}
   */
  async addNewLayout(model) {
    const layout = await super.postData(model);
    return new Layout(layout);
  }
}

export default new LayoutResource();
