<template>
  <div v-if="help" class="warning-notification m-r-5">
    <b-notification
      type="is-warning"
      has-icon
      aria-close-label="Close notification"
      role="alert"
      position="is-top-right"
    >
      <b>{{ helpMessage }}</b>
      <li v-for="(action, index) in helpAction" :key="index">
        {{ action }}
      </li>
    </b-notification>
  </div>
</template>
<script>
export default {
  name: 'HelpMessage',
  props: {
    help: {
      type: [Object, String],
      default: null,
    },
  },
  computed: {
    helpMessage() {
      return this.help?.message;
    },
    helpAction() {
      return this.help?.action;
    },
  },
};
</script>
<style scoped>
.warning-notification {
  position: absolute;
  width: rem(400px) !important;
  right: 0;
  z-index: 1;
}
</style>
