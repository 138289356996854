import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { DataView } from '@/modules/core/datasource/models/DataView';
import { GenericDataModel } from '@/modules/core/app/models/GenericDataModel';
import { GenericModel } from '@/modules/core/app/models/GenericModel';
import ApiColumn from '@/modules/core/app/models/api/ApiColumn';

export class ServiceDataResource extends BaseResource {
  constructor() {
    super(RouteGroup.SERVICES);
  }

  /**
   * @param {string} serviceId
   * @param params
   * @returns {Promise<DataView[]>}
   * */
  async getDataViews(serviceId, params = {}) {
    return super.getData(DataView, `${serviceId}/data/data_views`, params);
  }

  /**
   * @param serviceId
   * @param dataViewId
   * @param params
   * @returns {Promise<GenericDataModel[]>}
   * */
  async getData(serviceId, dataViewId, params = {}) {
    return super.getData(GenericDataModel, `${serviceId}/data/${dataViewId}`, params);
  }

  /**
   *
   * @param data_source_id
   * @param data_view_id
   * @param params
   * @returns {Promise<ApiColumn>}
   */
  async getColumns(data_source_id, data_view_id, params = {}) {
    params = { ...params, metadata: true };
    return super.getData(ApiColumn, `${data_source_id}/data/${data_view_id}`, params);
  }

  /**
   *
   * @param serviceId
   * @param dataViewId
   * @param fields
   * @param params
   * @returns {Promise<*>}
   */
  async getValuesDistinct(serviceId, dataViewId, fields, params = {}) {
    params = { ...params, fields };
    return super.getData(GenericModel, `${serviceId}/data/${dataViewId}/values/distinct`, params);
  }
}

export default new ServiceDataResource();
