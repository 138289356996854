'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import EventBus from "grokModules/src/modules/core/app/helpers/EventBus";
import {UPDATE_NAV_ITEM} from "grokModules/src/modules/core/nav/nav.constants";

angular.module('reportingprofile.ctrls', [])

    .controller('ReportingProfileListController', ReportingProfileListController)
    .controller('ReportingProfileController', ReportingProfileController);

//
// Data profile grid controller
//
ReportingProfileListController.$inject = ['$scope', 'ReportingProfileFactory'];
function ReportingProfileListController($scope, ReportingProfileFactory) {
}

//
// Data profile controller
//
ReportingProfileController.$inject = [
    '$scope',
    'DesignFactory',
    'DashboardService',
    '$compile',
    '$location',
    '$anchorScroll',
    '$templateCache',
    '$state',
    '$window'
];
function ReportingProfileController(
    $scope,
    DesignFactory,
    DashboardService,
    $compile,
    $location,
    $anchorScroll,
    $templateCache,
    $state,
    $window
) {
    if (!_.isEmpty($state.params.id)) {
        $templateCache.remove($state.current.templateUrl($state.params));
    }
    $scope.entity = {};
    $scope.entity.redrawServiceNavOnSave = true;
    $scope.reEnableInputFields = function() {
        $('#main-form').find('select, input[type=text]').each(function(i) {
            $(this).enableElement();
        });
        $.core.main.rebuildReportingProfileSelect();
    };
    $scope.updateNuiNav = function() {
        if(window.isNUI) {
            EventBus.signal(UPDATE_NAV_ITEM);
        }
    };

    /**
     * Using ng-click instead of uisref to trigger change through browser events as this is used in grok.
     */
    $scope.onBackPressed = function () {
        $window.location.hash = '#/reportingprofiles';
    };

    var show_services_overview = $('#show_services_overview').val();
    var show_categories_overview = $('#show_categories_overview').val();
    $scope.overviewCallback = function() {

        DashboardService.updateLandingPage();

        // Refresh menu with fresh page list
        DesignFactory.getNavPageList().then(function() {
            DesignFactory.$refreshMenu();
        });

        $.get($.fn.actionUrl('layout/dashboardlistmenu'))
            .done(function(html) {
                // Need to $compile the html, in order to add to angular scope
                // parse HTML into DOM element
                var template = angular.element(html);
                // compile the template
                var linkFn = $compile(template);
                // link the compiled template with the scope.
                var element = linkFn($scope);
                $('#dashboard-submenu').html(element);
            })
    };

    $scope.setVars = function(category) {
        $scope.currCategory = category.id;
        $scope.metadata.data_source = {
            id: category.id,
            type: category.type,
            is_data_view_required: false,
            requires_group_by: false
        };
        // $scope.state.needsRefresh = true;


        // Scroll to top of the page when click service (data source) in reporting profiles page
        $location.hash('content');
        $anchorScroll();

        //	Need to add selected metrics?
    };

    $scope.init = function() {
        $scope.currCategory = 0;
        $scope.state = {};
        $scope.state.canSave = true;

        $scope.metadata = {
            data_source: {
                // type: 'service'
            },
            data: [
                {
                    field: "sample",
                    format: "decimal",
                    label: "Sample",
                    value: "26785.474081"
                }
            ],
            data_columns: {
                selected: [],
                grouped: [],
                selectable: [],
                groupable: []
            },
            draw_options: {
                font_size: 32,
                reduce_number: true,
                center_numbers: false
            },
            min_font_size: 14,
            max_font_size: 150
        }

    }

    $scope.init();

}