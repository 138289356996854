'use strict';
import angular from 'angular';

import guideButtonHtmlUrl from '../views/guide.button.html';
import EventBus from '../../../../../grok/src/modules/core/app/helpers/EventBus';
import { GuideEventType } from '../../../../../grok/src/modules/core/guide/guide.constants';

angular.module('guides.components', [])

    .component('guideButton', {
        templateUrl: guideButtonHtmlUrl,
        bindings: {
            guideName: '@',
            icon: '@'
        },
        controllerAs: 'vm',
        controller: GuideButtonController
    });

/**
 * @ngInject
 */
function GuideButtonController(AppFactory) {
    let vm = this;
    vm.$onInit = $onInit;
    vm.startGuideByName = startGuideByName;
    vm.isNui = AppFactory.getUser() ? AppFactory.getUser().canAccessNUIFromDropDown() : false;

    function $onInit() {
        // Setting defaults
        vm.iconClasses = vm.icon || 'fa fa-question-circle';
    }

    function startGuideByName() {
        EventBus.signal(GuideEventType.START_GUIDE_BY_NAME, this.guideName);
    }
}