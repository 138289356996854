import { LegendSettings } from '@/modules/core/charts/models/LegendSettings';

/**
 * @class Series
 */
export class Series {
  /**
   * Visible name for the given series
   * @var {String}
   */
  name;

  /**
   * Key holding categories name in data
   * @var {String}
   */
  category;

  /**
   * Key holding categories value in data
   * @var {String|Number}
   */
  value;

  /**
   * if tooltip is disabled
   * @var {Boolean}
   * @default false
   */
  tooltipDisabled;

  /**
   * Value should be from enum SeriesType
   *@var {String}
   */
  seriesType;

  /**
   * if should show series label
   * @var {Boolean}
   * @default true
   */
  showLabels;

  /**
   * Text/Template to be used for label text
   * @var {String}
   */
  labelText;

  /**
   * Field from datum to use to fill color for series
   * @var {String}
   */
  fill;

  /**
   * Control opacity of background color
   * @var {String}
   */
  fillOpacity;

  /**
   * Settings related to legend
   * @var {LegendSettings}
   */
  legendSettings;

  /**
   * Enable animations when chart renders
   * @var {boolean}
   */
  showOnInit;

  /**
   * Triggered only for first series before every value is rendered inside tooltip to customize prefix and suffix
   * @var {Function}
   */
  formatTooltipValue;

  constructor(model = {}) {
    const { showLabels = true, tooltipDisabled = false } = model;
    this.name = model.name;
    this.category = model.category;
    this.value = model.value;
    this.tooltipDisabled = tooltipDisabled;
    this.seriesType = model.seriesType;
    this.showLabels = showLabels;
    this.labelText = model.labelText;
    this.fill = model.fill;
    this.fillOpacity = model.fillOpacity;
    this.legendSettings = new LegendSettings(model.legendSettings);
    this.showOnInit = false;
    this.formatTooltipValue = model.formatTooltipValue;
  }
}
