import { Legend } from '@/modules/core/charts/models/Legend';
import { Label } from '@/modules/core/charts/models/Label';
import { StyleProperty } from '@/modules/core/charts/models/StyleProperty';

/**
 * @class ChartConfig
 */
export class ChartConfig {
  /**
   * Carries data for which graph has to created
   * @var {Array}
   */
  data;

  /**
   * holds configurations related to legend
   * @var {Legend}
   */
  legend;

  /**
   * A list of label elements for the chart.
   * @var {Array<Label>}
   */
  labels;

  /**
   * A list of titles elements on top of the chart.
   * @var {Array<Label>}
   */
  titles;

  /**
   * Increase depth of chart (3D effect)
   * @var {depth}
   */
  depth;

  /**
   * An angle of a "point of view" in degrees. Possible range 0 - 90.
   * @var {Number}
   */
  angle;

  /**
   * Time of animation when chart renders. Setting to 0 disables animation
   * @var {number}
   * @default 0
   */
  startDuration;

  /**
   * If should support 3D appearance
   * @var {Boolean}
   * @default false
   */
  is3D;

  /**
   * A shortcut to setting mouse cursor on hover.
   * @var {Array<StyleProperty>}
   */
  cursorOverStyle;

  constructor(model = {}) {
    const { is3D = false, startDuration = 0 } = model;
    this.data = model.data;
    this.legend = new Legend(model.legend);
    this.labels = model.labels?.map((label) => new Label(label));
    this.depth = model.depth;
    this.angle = model.angle;
    this.startDuration = startDuration;
    this.is3D = is3D;
    this.titles = model.titles?.map((title) => new Label(title));
    this.cursorOverStyle = model.cursorOverStyle?.map((style) => new StyleProperty(style));
  }
}
