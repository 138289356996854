'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('core.library.services', [])
    .factory('LibraryFactory', LibraryFactory)
    .factory('LibraryPublishFactory', LibraryPublishFactory)
    .factory('LibraryUIFactory', LibraryUIFactory);

/**
 * @ngInject
 */
function LibraryFactory(
    PubSub,
    $q,
    LibrarySearchGroup
) {
    /**
     * @type {boolean}
     * @private
     */
    var _isShowingItemDetails = false;

    return {
        getLibraryItems: getLibraryItems,
        resolveSearchQueryFromSearchValues: resolveSearchQueryFromSearchValues,
        mapDataSourceSearchableValues: mapDataSourceSearchableValues,
        mapTagSearchableValues: mapTagSearchableValues,
        mapWidgetTypeSearchableValues: mapWidgetTypeSearchableValues,
        setIsShowingItemDetails: setIsShowingItemDetails,
        getIsShowingItemDetails: getIsShowingItemDetails
    };

    /**
     * @param resourceFn
     * @param queryParams
     * @returns {*}
     */
    function getLibraryItems(resourceFn, queryParams) {
        var deferred = $q.defer();

        var promises = [
            resourceFn(_.assign({
                is_predefined: true,
                sort: '-library_download_count',
                limit: 20
            }, queryParams)),
            resourceFn(_.assign({
                is_predefined: false,
                sort: '-created_at',
                limit: 20
            }, queryParams))
        ];

        $q.all(promises).then(function (data) {
            deferred.resolve({
                mostUsedItems: data[0],
                recentlyUploadedItems: data[1]
            });
        });

        return deferred.promise;
    }

    /**
     * @param values
     */
    function resolveSearchQueryFromSearchValues(values) {
        var queryParams = {};
        _.each(values, function(searchValue) {
            if (!queryParams[searchValue.group]) {
                queryParams[searchValue.group] = [];
            }
            queryParams[searchValue.group].push(searchValue.id);
        });

        if (queryParams[LibrarySearchGroup.DATA_SOURCES]) {
            queryParams[LibrarySearchGroup.DATA_SOURCES] = queryParams[LibrarySearchGroup.DATA_SOURCES].join(',');
        }
        if (queryParams[LibrarySearchGroup.TAGS]) {
            queryParams[LibrarySearchGroup.TAGS] = queryParams[LibrarySearchGroup.TAGS].join(',');
        }
        if (queryParams[LibrarySearchGroup.WIDGET_TYPES]) {
            queryParams[LibrarySearchGroup.WIDGET_TYPES] = queryParams[LibrarySearchGroup.WIDGET_TYPES].join(',');
        }

        return queryParams;
    }

    /**
     * @param values
     */
    function mapDataSourceSearchableValues(values) {
        return _.orderBy(_.map(values, function(datum) {
            return _.extend(datum, {group: LibrarySearchGroup.DATA_SOURCES});
        }), 'text');
    }

    /**
     * @param values
     */
    function mapTagSearchableValues(values) {
        return _.map(values, function(datum) {
            return _.extend(datum, {group:
                LibrarySearchGroup.TAGS});
        });
    }

    /**
     * @param values
     */
    function mapWidgetTypeSearchableValues(values) {
        return _.orderBy(_.map(values, function(type) {
            return { id: type.id, text: type.name, icon: type.icon, group: LibrarySearchGroup.WIDGET_TYPES };
        }), 'text');
    }

    /**
     * @param value
     */
    function setIsShowingItemDetails(value) {
        _isShowingItemDetails = value;
    }

    /**
     * @returns {boolean}
     */
    function getIsShowingItemDetails() {
        return _isShowingItemDetails;
    }
}

/**
 * @ngInject
 */
function LibraryPublishFactory(
    PubSub,
    $q
) {
    return {
        publish: publish
    };

    /**
     * Saves the page/layout/widget and make it a template in its respective library
     *
     * @param factory
     * @param model {LibraryPublishItemFormModel}
     * @param modalData {LibraryPublishModalData}
     * @returns {*}
     */
    function publish(factory, model, modalData) {
        var deferred = $q.defer();

        if (_hasBadWidgetTitle(model.title)) {
            deferred.reject('Please make sure to enter a meaningful title.');
        } else if (_hasBadDescription(model.description)) {
            deferred.reject('Please enter a description.');
        } else {
            var itemToPublish = modalData.itemToPublish;
            // For pages
            itemToPublish.title = model.title;
            // For layouts and widgets
            itemToPublish.metadata.title = model.title;
            itemToPublish.metadata.description = model.description;
            itemToPublish.metadata.tags = model.tags;

            factory.publish(itemToPublish).then(function() {
                deferred.resolve();
            }, function() {
                deferred.reject();
            });
        }
        return deferred.promise;
    }

    /**
     * Validates the title for the widget
     * @param title
     * @private
     * @returns {boolean}
     */
    function _hasBadWidgetTitle(title) {
        return _.isEmpty(title) ||  _.startsWith(title, 'Untitled');
    }

    /**
     * Validates the description for the widget
     * @param description
     * @returns {boolean}
     */
    function _hasBadDescription(description) {
        return _.isEmpty(description);
    }
}
/**
 * @ngInject
 */
function LibraryUIFactory(
    PubSub,
    $timeout,
    UIFactory,
    SlidePanelFactory
) {
    return {
        showModal: showModal,
        hideModal: hideModal,
        bindModalAnimation: bindModalAnimation,
    };

    /**
     * @param modalId
     */
    function showModal(modalId) {
        SlidePanelFactory.closeAll();
        UIFactory.showModal(modalId);
    }

    /**
     * @param modalId
     */
    function hideModal(modalId) {
        UIFactory.hideModal(modalId);
    }

    /**
     */
    function bindModalAnimation($el) {
        var $modal = $el.find('div.modal');

        $modal.on('show', function () {
            $('div.app-view').addClass('animated laydown');
        });

        $modal.on('hidden', function () {
            $('div.app-view').removeClass('animated');
            $timeout(function () {
                $('div.app-view').removeClass('laydown');
            }, 600, false);
        });
    }
}
