'use strict';
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { UserType } from 'coreModules/shared/scripts/app.constants';

angular.module('annotation.models', [])

    .factory('AnnotationModelFactory', AnnotationModelFactory);

/**
 * @ngInject
 */
function AnnotationModelFactory(
    $timeout,
    $AnnotationEvents,
    AppFactory,
    AppModelFactory,
    AnnotationUIFactory,
    MessengerModelFactory,
    DesignFactory,
    PubSub,
    MomentDateFormat
) {
    return {
        getProps: getProps,
        getAnnotationThread: getAnnotationThread,
        getAnnotationsPanelObject: getAnnotationsPanelObject
    };


    /**
     * @param model
     * @constructor
     */
    function StateProps(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.isActive = model.isActive || false;
        self.isLoadingSelect = false;

        return self;
    }

    /**
     * @param model
     * @constructor
     */
    function Props(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.state = new StateProps(model.state);
    }

    /**
     * Extends MessengerThread
     * @param model
     * @param index
     * @constructor
     */
    function AnnotationThread(model, index) {
        model = _.isEmpty(model) ? {} : model;

        model.showEntity = true;
        model.index = index;
        if (_.isEmpty(model.userImageUrl)) {
            model.userImageUrl = model.user_image_metadata ? model.user_image_metadata.url : null;
        }

        model.entity = (function getEntity() {
            if (!_.isNil(model.client_id)) {
                return {
                    type: 'client',
                    id: model.client_id,
                    name: model.client_name,
                    text: model.client_name
                }
            } else if (!_.isNil(model.client_group_id)) {
                return {
                    type: 'client_group',
                    id: model.client_group_id,
                    name: model.client_group_name,
                    text: model.client_group_name
                }
            } else if (!_.isNil(model.cluster_id)) {
                return {
                    type: 'cluster',
                    id: model.cluster_id,
                    name: model.cluster_name,
                    text: model.cluster_name
                }
            } else if (!_.isNil(model.entity)) {
                // This is to prevent binding to the same model.entity object
                return _.extend({}, model.entity);
            }
        })();

        // NOTE: Function will be called with the messageThread object bound to this
        // Set the entity select options to the current selected values
        model.initEditCB = function() {
            var self = this;
            _.each(self.entityOptions, function(option) {
                if (self[option.key + '_id']) {
                    var currentValue = _.find(option.values, {key: self[option.key + '_id']});
                    option.currentValues = {id: currentValue.key, text: currentValue.value};
                }
                else {
                    option.currentValues = [];
                }
            });
        };

        // Reset entity select options
        model.closeEditCB = function() {
            var self = this;
            _.each(self.entityOptions, function(option) {
                option.currentValues = [];
            });
            self.filtersAreVisible = false;
        };

        model.resetCB = function() {
            var self = this;
            self.showEntity = !DesignFactory.getPageEntity();
            self.entity = {};
            self.resetSelectedClient();
        };

        var self = MessengerModelFactory.getMessageThread.call(this, model);

        self.permissions.can_be_edited = AppFactory.getUser().type !== UserType.CLIENT;
        self.user_id = model.user_id; // annotation created by
        self.isAnnotationThread = true;
        self.filtersAreVisible = false;
        self.showEntity = !DesignFactory.getPageEntity();
        self.entityOptions = model.entityOptions || false;

        self.dateOptions = model.showDate
            ? new DateOptions({
                startDate: model.start_date || AppFactory.getDateRange().start,
                endDate: model.end_date || AppFactory.getDateRange().end,
                opens: 'left',
                dateChangeCB: function(start, end) {
                    var startUnix = moment(start.format(MomentDateFormat.MONTH_DAY_YEAR)).unix();
                    var endUnix = moment(end.format(MomentDateFormat.MONTH_DAY_YEAR)).unix();
                    $timeout(function() {
                        _updateAnnotationDates(startUnix, endUnix);
                        _updateDatePickerDates(startUnix, endUnix);
                    });
                    PubSub.emit($AnnotationEvents.ADD_GUIDE, {start: startUnix, end: endUnix});
                }
            }, self.index)
            : false;

        self.updateDateOptionsDrop = function (index) {
            if (self.dateOptions) {
                var threadIndex = self.index || index; // if self.index is undefined, this is the add-filter-container
                self.dateOptions.updateDrop(threadIndex);
            }
        };

        /**
         * Used to change dates from outside of annotation datePicker
         * @param {(number|text)} start - start unix time stamp
         * @param {(number|text)} end - end unix time stamp
         * @param {(jQuery|HTMLElement)} $element - annotation filters element
         */
        self.updateDate = function(start, end, $element) {
            self.filtersAreVisible = true;
            $timeout(function() {
                _updateAnnotationDates(start, end);
                _updateDatePickerDates(start, end);
                AnnotationUIFactory.updateDatePicker($element, self);
            });
        };

        self.getEntityLabel = function() {
            if (_.isEmpty(self.entity)) {
                return false;
            } else {
                return self.entity.name || self.entity.type + ' ' + self.entity.id;
            }
        };

        self.getAdvancedEditLabel = function() {
            if (self.dateOptions && self.showEntity) {
                return 'Show for Who ?  Show When ?'
            } else if (self.dateOptions && !self.showEntity) {
                return 'Show When ?'
            } else if (self.showEntity) {
                return 'Show for Who ?'
            } else {
                return false;
            }
        };

        self.resetSelectedClient = function() {
            self.client_id = null;
            self.client_group_id = null;
            self.cluster_id = null;
        };

        self.permissions.can_be_edited = AppFactory.getUser().type !== UserType.CLIENT;
        self.showDate = model.showDate || false;
        self.user_id = model.user_id; // annotation created by

        if (!_.isEmpty(self.entity)) {
            self[self.entity.type + '_id'] = self.entity.id;
            self[self.entity.type + '_name'] = self.entity.name;
        }

        self.start_date = self.dateOptions.startDate;
        self.end_date = self.dateOptions.endDate;
        self.widget_id = model.widget_id;
        self.client_id = model.client_id || null;
        self.client_group_id = model.client_group_id || null;
        self.cluster_id = model.cluster_id || null;

        self.plain = function() {
            delete self.entityOptions;
        };


        /**
         * Correctly set dates for annotation object
         * @param {number} start - unix timestamp
         * @param {number} end - unix timestamp
         * @private
         */
        function _updateAnnotationDates(start, end) {
            self.start_date = start;
            self.end_date = end;
        }

        /**
         * Correctly set dates for dateOptions object on annotation
         * @param {number} start - unix timestamp
         * @param {number} end - unix timestamp
         * @private
         */
        function _updateDatePickerDates(start, end) {
            self.dateOptions.start = moment.unix(start).format(MomentDateFormat.MONTH_DAY_YEAR);
            self.dateOptions.end = moment.unix(end).format(MomentDateFormat.MONTH_DAY_YEAR);
            self.dateOptions.startDate = start;
            self.dateOptions.endDate = end;
        }

        return self;
    }

    /**
     *
     * @param model
     * @constructor
     */
    function AnnotationsPanelObject(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.widgetId = model.widgetId;
        self.showDate = model.showDate || false;
        self.annotations = model.annotations || [];

        self.addAnnotation = function(annotation) {
            var self = this;

            var annotationIndex = _.findIndex(self.annotations, {id: annotation.id});
            if (annotationIndex > -1) {
                self.annotations[annotationIndex] = annotation;
            } else {
                self.annotations.push(annotation);
            }
        };

        self.removeAnnotation = function(annotationId) {
            var self = this;
            _.remove(self.annotations, function(annotation) {
                return annotation.id === annotationId;
            });
            // Reindex
            _.each(self.annotations, function(annotation, index) {
                annotation.index = index;
            });
        };
    }

    /**
     * @param dateOptions
     * @param index
     * @returns {DateOptions}
     * @constructor
     */
    function DateOptions(dateOptions, index) {
        dateOptions = _.isEmpty(dateOptions) ? {} : dateOptions;
        var self = AppModelFactory.getDateOptions.call(this, dateOptions);

        self.updateDrop = _updateDrop;
        self.opens = 'left';
        self.onShow = function(event, picker) {
            AnnotationUIFactory.updateDatePickerPosition(picker);
        };
        self.updateDrop(index);
        self.dateChangeCB = dateOptions.dateChangeCB;
        function _updateDrop(count) {
            // if note number is greater than four
            self.drops = count > 4 ? 'up' : 'down';
        }

        return self;
    }

    /**
     *
     * @param model
     * @returns {AnnotationsPanelObject}
     */
    function getAnnotationsPanelObject(model) {
        return new AnnotationsPanelObject(model)
    }

    /**
     *
     * @param model
     * @returns {Props}
     */
    function getProps(model) {
        return new Props(model);
    }

    /**
     * Getter
     * @param model
     * @param index
     * @returns {AnnotationThread}
     */
    function getAnnotationThread(model, index) {
        return new AnnotationThread(model, index);
    }
}