'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

angular.module('executivesummary.resources', [])

    .factory('ExecutiveSummaryResourceFactory', ExecutiveSummaryResourceFactory);

/**
 * @ngInject
 */
function ExecutiveSummaryResourceFactory(
    Restangular,
    MomentDateFormat,
    AppFactory,
    DataSourceType,
    ExecutiveSummaryColumns,
    DataGridRender,
    DateRangeFactory,
    gettextCatalog,
    LanguageService
) {

    var executiveSummaryData = Restangular.all('dash').all('executivesummaries');
    var executiveSummaryItems = executiveSummaryData.all('items');
    let reportExecutiveSummaryData = Restangular.all('reportstudio').all('executivesummaries');
    let reportExecutiveSummaryItems = reportExecutiveSummaryData.all('items');

    return {
        executiveSummaryData: executiveSummaryData,
        getSummaryData: getSummaryData,
        getSummaryDataForPageOrLayouts: getSummaryDataForPageOrLayouts,
        getData: getData,
        save: save,
        saveItem: saveItem,
        removeItem: removeItem,
        getDTOptions: getDTOptions
    };

    function getSummaryData(id, queryParams) {
        return executiveSummaryData.get(id, queryParams);
    }

    function getSummaryDataForPageOrLayouts(layoutIds = [], reportIds = [], reportPageIds = []) {
        if (!_.isEmpty(reportIds) && !_.isEmpty(reportIds[0]) && !_.isEmpty(reportPageIds) && !_.isEmpty(reportPageIds[0])) {
            return executiveSummaryData.getList({ report_id: reportIds.join(','), report_page_id: reportPageIds.join(',') });
        }

        return executiveSummaryData.getList({ layout_id: layoutIds.join(',') });
    }

    function getData(queryParams) {
        queryParams.all = true;
        return executiveSummaryItems.getList(queryParams);
    }

    function save(payload) {
      /**
       * Using a separate route for dashboard and report studio executive summaries
       */
      return isReportSummary(payload)
        ? saveReportSummary(payload)
        : saveDashboardSummary(payload);
    }

    function saveDashboardSummary(payload) {
      return _.isNil(payload.id)
        ? executiveSummaryData.post(payload)
        : executiveSummaryData.all(payload.id).post(payload);
    }

    function saveReportSummary(payload) {
      return _.isNil(payload.id)
        ? reportExecutiveSummaryData.post(payload)
        : reportExecutiveSummaryData.all(payload.id).post(payload);
    }

    function saveItem(payload) {
        return _.isNil(payload.id)
            ? executiveSummaryItems.post(payload)
            : executiveSummaryItems.all(payload.id).post(payload);
    }

    function removeItem(executiveSummaryId, itemId) {
        return executiveSummaryItems.all(itemId).remove();
    }

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.numActions = 1;
        var brandMappings = AppFactory.getBrandMappings();
        dtOptions.customRenders[ExecutiveSummaryColumns.ID] = function (data, type, full) {
            return DataGridRender.getDeleteButton(full.can_be_deleted, 'dash/executivesummaries/items/' + data, full.title);
        };

        dtOptions.customRenders[ExecutiveSummaryColumns.CONTENT] = function (data) {
            return '<div class="content-wrapper"><div class="inner">' + data + '</div></div>';
        };

        dtOptions.customRenders[ExecutiveSummaryColumns.PAGE_TITLE] = function (data, type, full) {
            return '<a href="#/dash/' + full[ExecutiveSummaryColumns.PAGE_ID] +'">' + data + '</a>';
        };

        dtOptions.customRenders[ExecutiveSummaryColumns.START_DATE] = function (data) {
            // Need to format start date
            return formatSummaryDate(data) || '-';
        };

        dtOptions.customRenders[ExecutiveSummaryColumns.END_DATE] = function (data) {
            // Need to fromat end date
            return formatSummaryDate(data) || '-';
        };

        dtOptions.customRenders[ExecutiveSummaryColumns.ENTITY] = function (data) {
            var html = '';
            if (!_.isEmpty(data)) {
                var typeLabel;
                switch (data.type) {
                    case (DataSourceType.CLIENT):
                        typeLabel = brandMappings.client;
                        break;
                    case (DataSourceType.CLIENT_GROUP):
                        typeLabel = brandMappings.client_group;
                        break;
                    default:
                        typeLabel = data.type_name;
                }
                var badge = '<label class="label label-default mr5">' + typeLabel + '</label>';
                html += badge + data.name;
            } else {
                html += gettextCatalog.getString('Not Assigned');
            }

            return html;
        };

        return dtOptions;
    }

    /**
     * Format summary date for display
     * @returns {Array}
     */
    function formatSummaryDate(date) {
        return date ? moment.utc(moment.unix(date)).format(LanguageService.getDisplayDateFormat()) : null;
    }

    /**
     * Returns true if is report summary
     * @param payload
     * @returns {false}
     */
    function isReportSummary(payload) {
      return (
        !_.isNull(payload.report_id) &&
        !_.isUndefined(payload.report_id) &&
        payload.report_id
      );
    }
}