import { getters } from '@/modules/core/app/helpers/store';
import { OnboardingStep } from '@/modules/core/onboarding/onboarding.constants';

export class OnboardingService {
  /**
   * @param item
   * @returns {*|boolean}
   */
  hasOnboardingStepCompleted(item) {
    return (
      item.isOnboradingItem() &&
      getters.session.getUserSettings().completedOnboardingSteps.includes(item.key)
    );
  }

  /**
   * @param item
   * @returns {string|*}
   */
  getOnboardingStepIcon(item) {
    if (getters.session.getUserSettings().completedOnboardingSteps.includes(item.key)) {
      return 'check-circle';
    }

    return item.icon;
  }

  /**
   * @param item
   * @returns {boolean}
   */
  isOnboardingNavItemDisabled(item) {
    if (!item.isOnboradingItem() || item.key === OnboardingStep.CONNECT_DATA) {
      return false;
    }

    return !getters.session
      .getUserSettings()
      .completedOnboardingSteps.includes(OnboardingStep.CONNECT_DATA);
  }
}

export default new OnboardingService();
