'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('goals.models', [])

    .factory('GoalsModelFactory', GoalsModelFactory);

/**
 * @ngInject
 */
function GoalsModelFactory(
    $q,
    $GoalsEvents,
    AppModelFactory,
    DataSourceType,
    gettextCatalog
) {

    return {
        getDefaultState: getDefaultState,
        getGoal: getGoal,
        getGoalModalViewBindings: getGoalModalViewBindings,
        getGoalServiceSelectOptions: getGoalServiceSelectOptions,
        getGoalDataSourceSelectOptions: getGoalDataSourceSelectOptions,
        getSmartCampaignSelectOptions: getSmartCampaignSelectOptions,
        getGoalCampaignSelectOptions: getGoalCampaignSelectOptions,
        getClientSelectOptions: getClientSelectOptions,
        getSliderOptions: getSliderOptions,
        getCategorySelectOptions: getCategorySelectOptions,
        getDataViewSelectOptions: getDataViewSelectOptions,
        getMetricSelectOptions: getMetricSelectOptions
    };

    /**
     *
     * @constructor
     */
    function DefaultState() {
        let self = this;

        self.isReady = false;
        self.isDownloading = false;
        self.isAdding = false;
        self.isSaving = false;
    }


    /**
     *
     * @param model
     * @param model.id
     * @param model.name
     * @param model.source_type
     * @param model.service_id
     * @param model.smart_campaign_id
     * @param model.campaign_name
     * @param model.customer_id
     * @param model.column
     * @param model.target
     * @param model.start
     * @param model.end
     * @param model.timeframe
     * @param model.completion
     * @param model.pace
     * @param model.threshold_1
     * @param model.threshold_2
     * @constructor
     */
    function Goal(model = {}) {
        let self = this;

        self.id = _.isNil(model.id) ? null : model.id;
        self.name = _.isNil(model.name) ? '' : model.name;
        self.source_type = _.isNil(model.source_type) ? null : model.source_type; // Temporary default to service data
        self.service_id = _.isNil(model.service_id) ? null : model.service_id;
        self.smart_campaign_id = _.isNil(model.smart_campaign_id) ? null : model.smart_campaign_id;
        self.campaign_name = _.isNil(model.campaign_name) ? null : model.campaign_name;
        self.customer_id = _.isNil(model.customer_id) ? null : model.customer_id;
        self.column = model.column || {};
        self.target = parseInt(model.target) || null;
        self.start = model.start || null;
        self.end = model.end || null;
        self.completion = model.completion || {};
        self.pace = model.pace || null;
        self.threshold_1 = parseInt(model.threshold_1) || parseInt(20);
        self.threshold_2 = parseInt(model.threshold_2) || parseInt(50);
        self.category_id =  _.isNil(model.category_id) ? null : model.category_id;
        self.dataview_id =  _.isNil(model.dataview_id) ? null : model.dataview_id;
        self.metric =  _.isNil(model.metric) ? null : model.metric;
        self.isServiceDataType = isServiceDataType;
        self.isSmartCampaignDataType = isSmartCampaignDataType;
        self.isCategoryDataType = isCategoryDataType;
    }

    function isServiceDataType() {
        let self = this;

        return self.source_type && self.source_type === DataSourceType.SERVICE_DATA;
    }

    function isSmartCampaignDataType() {
        let self = this;

        return self.source_type && self.source_type === DataSourceType.SMART_CAMPAIGN;
    }

    /**
     *
     * @returns {boolean}
     */
    function isCategoryDataType() {
        let self = this;

        return (
            self.source_type && self.source_type === DataSourceType.CATEGORY_DATA
        );
    }

    /**
     *
     * @param model
     * @constructor
     */
    function GoalModalViewBindings(model = {}) {
        let self = this;

        self.source_type = _.isNil(model.source_type) ? null : model.source_type; // Temporary default to service data
        self.data_source = {};
        self.data_view = {};
        self.resetSelects = false;
        self.target = _.isNil(model.target) ? null : parseInt(model.target);
        self.threshold_1 = _.isNil(model.threshold_1) ? parseInt(20) : parseInt(model.threshold_1);
        self.threshold_2 = _.isNil(model.threshold_2) ? parseInt(50) : parseInt(model.threshold_2);
        self.service = {};
        self.category = {};
    }

    /**
     * @param options
     * @constructor
     */
    function GoalServiceSelectOptions(options = {}) {
        let self = AppModelFactory.getSelectOptions.call(this, options);
        self.placeholder = gettextCatalog.getString('Select a DataSource...');
        self.autoOpen = false;
        self.loaded = false;
        self.closeOnSelect = true;
        self.showAll = true;
        self.allowClear = false;
        return self;
    }

    /**
     * @param options
     * @constructor
     */
    function GoalDataSourceSelectOptions(options = {}) {
        let self = AppModelFactory.getSelectOptions.call(this, options);

        self.autoOpen = false;
        self.placeholder = gettextCatalog.getString('Select a Data Category...');
        self.closeOnSelect = true;
        self.showAll = true;
        self.allowClear = false;

        return self;
    }

    /**
     * @param options
     * @constructor
     */
    function SmartCampaignSelectOptions(options = {}) {
        options.placeholder = gettextCatalog.getString('Select a Smart Campaign...');
        options.multiple = false;
        options.width = '100%';

        let self = AppModelFactory.getSelectOptions.call(this, options);

        self.autoOpen = false;
        self.closeOnSelect = true;

        return self;
    }

    /**
     * @param options
     * @constructor
     */
    function CategorySelectOptions(options = {}) {
        options.placeholder = gettextCatalog.getString('Select a Category...');
        options.multiple = false;
        options.width = '100%';
        options.allowClear = false;

        let self = AppModelFactory.getSelectOptions.call(this, options);

        self.autoOpen = false;
        self.closeOnSelect = true;

        return self;
    }

    /**
     * @param options
     * @constructor
     */
    function DataViewSelectOptions(options = {}) {
        options.placeholder = gettextCatalog.getString('Select a Dataview...');
        options.multiple = false;
        options.width = '100%';
        options.allowClear = false;

        let self = AppModelFactory.getSelectOptions.call(this, options);

        self.autoOpen = false;
        self.closeOnSelect = true;

        return self;
    }

    /**
     * @param options
     * @constructor
     */
    function MetricSelectOptions(options = {}) {
        options.placeholder = gettextCatalog.getString('Select an Evaluate By Field...');
        options.multiple = false;
        options.width = '100%';
        options.allowClear = false;

        let self = AppModelFactory.getSelectOptions.call(this, options);

        self.autoOpen = false;
        self.closeOnSelect = true;

        return self;
    }

    /**
     *
     * @param options
     * @constructor
     */
    function GoalCampaignSelectOptions(options) {
        options = options || {};

        options.placeholder = gettextCatalog.getString('Select');
        options.multiple = false;
        options.width = '100%';
        options.values = [];
        options.selectedValues = [];
        options.allowClear = false;
        options.multiple = true;

        let self = AppModelFactory.getSelectOptions.call(this, options);

        self.alwaysShow = true;
        self.autoOpen = false;
        self.closeOnSelect = true;

        return self;
    }

    /**
     *
     * @param options
     * @constructor
     */
    function ClientSelectOptions(options) {
        options = options || {};

        options.placeholder = gettextCatalog.getString('Select a Client...');
        options.multiple = false;
        options.width = '100%';
        options.values = [];
        options.selectedValues = [];

        let self = AppModelFactory.getSelectOptions.call(this, options);

        self.alwaysShow = true;
        self.autoOpen = false;
        self.closeOnSelect = true;

        return self;
    }

    function SliderOptions(options = {}) {
        let self = this;

        self.floor = options.floor || 0;
        self.ceil = options.ceil || 100;
        self.vertical = options.vertical || false;
        self.showSelectionBar = options.showSelectionBar || true;
        self.translate = options.translate || function(value) {
            return value + '%';
        };

        self.onChange = options.onChange || function () {
        };

        return self;
    }

    /**
     *
     * @returns {DefaultState}
     */
    function getDefaultState() {
        return new DefaultState();
    }

    /**
     *
     * @param model
     * @returns {Goal}
     */
    function getGoal(model) {
        return new Goal(model);
    }

    /**
     *
     * @param model
     * @returns {GoalModalViewBindings}
     */
    function getGoalModalViewBindings(model) {
        return new GoalModalViewBindings(model);
    }

    /**
     * @param options
     * @returns {GoalCampaignSelectOptions}
     */
    function getGoalCampaignSelectOptions(options) {
        return new GoalCampaignSelectOptions(options);
    }

    /**
     *
     * @param model
     * @returns {GoalServiceSelectOptions}
     */
    function getGoalServiceSelectOptions(model) {
        return new GoalServiceSelectOptions(model);
    }

    /**
     *
     * @param model
     * @returns {GoalDataSourceSelectOptions}
     */
    function getGoalDataSourceSelectOptions(model) {
        return new GoalDataSourceSelectOptions(model);
    }

    /**
     *
     * @param options
     * @returns {SmartCampaignSelectOptions}
     */
    function getSmartCampaignSelectOptions(options) {
        return new SmartCampaignSelectOptions(options);
    }

    /**
     *
     * @param options
     * @returns {CategorySelectOptions}
     */
    function getCategorySelectOptions(options) {
        return new CategorySelectOptions(options);
    }

    /**
     *
     * @param options
     * @returns {*}
     */
    function getMetricSelectOptions(options) {
        return new MetricSelectOptions(options);
    }

    /**
     * 
     * @param options
     * @returns {*}
     */
    function getDataViewSelectOptions(options) {
        return new DataViewSelectOptions(options);
    }

    /**
     *
     * @param options
     * @returns {ClientSelectOptions}
     */
    function getClientSelectOptions(options) {
        return new ClientSelectOptions(options);
    }

    /**
     *
     * @param options
     */
    function getSliderOptions(options) {
        return new SliderOptions(options);
    }


}