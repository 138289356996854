/**
 * Applying the wrapper function designed to support Custom translations in vue components
 *
 * @param Vue
 */
export const VueI18nCustomPlugin = (Vue) => {
  Vue.mixin({
    beforeCreate() {
      // Vendor issue: Translation methods could be undefined when used in angular.
      // We need to override this.$t to support Custom Translations
      if (this.$t === undefined || typeof this.$t === 'function') {
        this.$t = i18n.$t;
      }

      if (this.$tc === undefined) {
        this.$tc = i18n.$tc;
      }
    },
  });
};
