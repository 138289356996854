import { DatasourcedWidgetMetadata } from '@/modules/ta/widget/models/metadata/DataSourcedWidgetMetadata';
import { AccountManagerDrawOption } from '@/modules/ta/widget/models/drawoptions/AccountManagerDrawOption';

export class AccountManagerWidgetMetadata extends DatasourcedWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new AccountManagerDrawOption(model.draw_options);
  }
}
