export const mutations = {
  /**
   *
   * @param state
   * @param {Array.<number>} clusterIds
   */
  setSelectedClusters(state, clusterIds) {
    state.selectedClusters = clusterIds;
  },
};
