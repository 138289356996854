import { ChartConfig } from '@/modules/core/charts/am5/base/models/ChartConfig';
import { isNil } from 'lodash';
import { BackgroundGradientType } from '@/modules/core/charts/am5/charts.constants';

/**
 * @class PieChartConfig
 * @extends SliceChartConfig
 * @extends ChartConfig
 */
export class GeoChartConfig extends ChartConfig {
  /**
   * Default Color Palette contains list of available colors
   * @var {Array}
   */
  colorPalette;

  /**
   * Opacity value for prior comparison
   * @var {Number}
   */
  fillOpacityPrior;

  /**
   * Opacity value for current comparison
   * @var {Number}
   */
  fillOpacityCurrent;

  /**
   * Actual comparison status
   * @var {Boolean}
   */
  hasComparisonStatus;

  /**
   * Hiding label percentage
   * @var {Number}
   */
  labelPct;

  /**
   * Other label percentage
   * @var {Number}
   */
  otherPct;

  /**
   * Plot Type identifier
   * @var {String}
   */
  plotType;

  /**
   * Plot Type identifier
   * @var {String}
   */
  gradientType;

  /**
   * Plot Type identifier
   * @var {String}
   */
  gradientColor;

  /**
   * @var {boolean}
   */
  showGrainyPattern;

  /**
   * @var {number}
   */
  grainyPatternSize;

  /**
   * @var {boolean}
   */
  propsComparisonEnabled;

  /**
   * @var {number}
   */
  grainyPatternDensity;

  /**
   * @var {boolean}
   */
  showBorders;

  /**
   * @var {boolean}
   */
  multipleGroupby;

  /**
   * @var {Array<widgetData>}
   */
  widgetData;

  /**
   * @var {boolean}
   */
  isNormalized;

  constructor(model = {}) {
    super(model);

    const {
      plot_type,
      is_normalized,
      gradientType,
      gradientColor,
      showGrainyPattern,
      grainyPatternSize,
      grainyPatternDensity,
      showBorders,
    } = model.drawOptions;
    this.series = model.series;
    this.radius = model.radius;
    this.startAngle = model.startAngle;
    this.endAngle = model.endAngle;
    this.innerRadius = model.innerRadius;
    this.colorPalette = model.colorPalette;
    this.fillOpacityPrior = model.fillOpacityPrior;
    this.fillOpacityCurrent = model.fillOpacityCurrent;
    this.hasComparisonStatus = model.hasComparisonStatus;
    this.labelPct = model.labelPct;
    this.otherPct = model.otherPct;
    this.plotType = plot_type;
    this.gradientType = isNil(gradientType) ? BackgroundGradientType.SOLID : gradientType;
    this.gradientColor = gradientColor;
    this.showGrainyPattern = showGrainyPattern;
    this.grainyPatternSize = grainyPatternSize;
    this.propsComparisonEnabled = model.propsComparisonEnabled;
    this.isNormalized = is_normalized;
    this.grainyPatternDensity = grainyPatternDensity;
    this.showBorders = showBorders;
    this.multipleGroupby = model.multipleGroupBy;
    this.widgetData = model.widgetData;
  }
}
