import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  async togglePanel({}, value) {
    return commits.platformsearch.togglePanel(value);
  },

  /**
   * storing group name(module name: clients, bundles etc..),
   * It can be use for list views as b-tabs with same route
   * @param value
   * @return {Promise<void|*>}
   */
  setGroupName({}, value) {
    return commits.platformsearch.setGroupName(value);
  },
};
