'use strict';
import angular from 'angular';

angular.module('core.tag.services')
    .factory('TagDetailsService', TagDetailsService);

/**
 * @ngInject
 */
function TagDetailsService() {

    return {
    };
}