import { CustomTranslationForm } from '@/modules/core/customtranslation/models/CustomTranslationForm';

export const mutations = {
  /**
   * @param state
   * @param data
   * @param columns
   */
  setCustomTranslationForm(state, { data, columns }) {
    state.customTranslationForm = new CustomTranslationForm({ data, columns });
  },
  /**
   * @param state
   */
  resetCustomTranslationForm(state) {
    state.customTranslationForm = undefined;
  },
};
