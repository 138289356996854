'use strict';
import angular from 'angular';

angular.module('core.connectionstatus.services', [])

    .factory('ConnectionStatusFactory', ConnectionStatusFactory);

/**
 * @ngInject
 */
function ConnectionStatusFactory(
) {

}