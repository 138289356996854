'use strict';
import angular from 'angular';

angular.module('core.service.services')

    .service('ServiceDrilldownFactory', ServiceDrilldownFactory);

ServiceDrilldownFactory.$inject = [
];
function ServiceDrilldownFactory(
) {
    /**
     * Return manage dashboards specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.customRenders['score'] = function (data) {
            return '<div class="'+ getScoreClass(data) +'"><h4>' + data + '</h4></div>';
        };
        dtOptions.customPrimaryNameFieldRender = function (data, type, full) {
            return  '<span class="link">' + data + '</span>';
        };

        return dtOptions;
    }

    /**
     * Returns score display render
     * @param val
     * @returns {*}
     */
    function getScoreClass(val) {
        if (val < 35) {
            return 'entry-red-head';
        }
        else if (val < 70) {
            return 'entry-yellow-head';
        }
        else {
            return 'entry-green-head';
        }
    }

    return {
        getDTOptions: getDTOptions
    }
}