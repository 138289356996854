import { Label, percent } from '@amcharts/amcharts5';
import { invertColor } from '@/modules/core/charts/am5/charts.helper';
import {
  Constant,
  GaugeBigNumberLengthToFontSizeMap,
} from '@/modules/core/charts/am5/charts.constants';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';
import { useToolTips } from '@/modules/core/charts/am5/pie/composables/useTooltips';

export function useBigNumberTextLabels(context) {
  const { root, config, isDarkTheme } = context();
  const { formatNumber } = useNumberFormatter(context);

  function createLabelWithSettings(chartValue, gaugeHand, settings = {}) {
    const roundedNumber = formatNumber(gaugeHand.bigNumber.value, gaugeHand.bigNumber.format, true);
    const { assignThemeColor } = useToolTips(context);
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);

    chartValue.radarContainer.children.push(
      Label.new(root.value, {
        fill: invertColor(gaugeHand.bigNumber.fill, false),
        centerX: percent(50),
        textAlign: Constant.CENTER,
        centerY: percent(50),
        fontSize: getFontSizeForLabel(roundedNumber),
        text: `[${fontColorPicker}]${roundedNumber}`,
        ...settings,
      })
    );
  }

  function getFontSizeForLabel(number) {
    const stringNumber = number.toString();
    let fontSize = 0.8;
    if (GaugeBigNumberLengthToFontSizeMap[stringNumber.length]) {
      fontSize = GaugeBigNumberLengthToFontSizeMap[stringNumber.length];
    }

    return `${fontSize}em`;
  }

  return {
    createLabelWithSettings,
  };
}
