import CacheHelper from '@/modules/core/app/helpers/CacheHelper';
import { dispatches } from '@/modules/core/app/helpers/store';
import { OAUTH_PARAMS_KEY } from '@/modules/core/oauth/oauth.constants';

export const getters = {
  /**
   * @param {OAuthState} state
   * @returns {Object|null}
   */
  getOAuthParams(state) {
    if (!state.oAuthParams) {
      const storedParams = CacheHelper.get(OAUTH_PARAMS_KEY);
      if (storedParams) {
        dispatches.oauth.setOAuthParams(storedParams);
        CacheHelper.remove(OAUTH_PARAMS_KEY);
      }
    }
    return state.oAuthParams || null;
  },
};
