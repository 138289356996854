import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class BrandMapping extends BaseModel {
  /**
   * @type {string}
   */
  client;

  /**
   * @type {string}
   */
  client_group;

  /**
   * @type {string}
   */
  agent_client_group;

  /**
   * @type {string}
   */
  campaign;

  /**
   * @type {string}
   */
  agent;

  /**
   * @type {string}
   */
  client_user;

  /**
   * @type {string}
   */
  client_type;

  /**
   * @type {string}
   */
  cluster;

  /**
   * @type {string}
   */
  cluster_group;

  /**
   * @type {string}
   */
  client_group_plural;

  /**
   * @type {string}
   */
  group_of_clients;

  /**
   * @type {string}
   */
  groups_of_clients;

  constructor(model = {}) {
    super();

    this.client = model.client;
    this.client_group = model.client_group;
    this.agent_client_group = model.agent_client_group;
    this.campaign = model.campaign;
    this.agent = model.agent;
    this.client_user = model.client_user;
    this.client_type = model.client_type;
    this.cluster = model.cluster;
    this.cluster_group = model.cluster_group;
    this.client_group_plural = model.client_group_plural;
    this.group_of_clients = model.group_of_clients;
    this.groups_of_clients = model.groups_of_clients;
  }
}
