"use strict";
import $ from 'jquery';

var Core = $.core.admin.clientGroup = {

    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {

    },
    events: function () {
        Core.clientGroupSelectorBinding();
        Core.clientGroupTypeBinding();
        Core.toggleFields($('#group_type').val());
    },
    clientGroupSelectorBinding: function() {
        var $select = $('#clientgroup_client_id');
        var val = $select.val(); //This is a hidden input
        $select.val(val).trigger('change'); //Trigger change on a hidden input workaround
        $select.change(function() {
            setCount();
        });

        //Set count by default
        setCount();

        function setCount() {
            $('#client-count').html($('#s2id_clientgroup_client_id').find('li.select2-search-choice').length);
        }
    },
    clientGroupTypeBinding: function() {
        $('#group_type').change(function() {
            Core.toggleFields($(this).val());
        });
    },
    toggleFields: function(val) {
        var $onlyClients = $('.only-clients');
        var $onlyGroups = $('.only-client-groups');
        $onlyClients.hide();
        $onlyGroups.hide();
        if (val == 'group_of_clients') {
            $onlyClients.show();
        } else if (val == 'group_of_client_groups') {
            $onlyGroups.show();
        }
    }
};