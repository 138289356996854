import { SliceChartConfig } from '@/modules/core/charts/am5/base/models/SliceChartConfig';
import { SeriesType } from '@/modules/core/charts/chart.constants';

/**
 * @class FunnelChartConfig
 * @extends SliceChartConfig
 */
export class PictorialChartConfig extends SliceChartConfig {
  constructor(model = {}) {
    super(model);
  }

  getSeriesType() {
    return SeriesType.PICTORIAL;
  }

  isPictorialSeries() {
    return true;
  }
}
