<template>
  <ChartWrapper v-bind="$props" :is-data-empty="isDataEmpty" :context="context" />
</template>

<script>
import ChartWrapper from '@/modules/core/charts/am5/base/components/ChartWrapper.vue';
import { createChartContext } from '@/modules/core/charts/am5/base/composables/createChartContext';
import { isEmpty, isNil } from 'lodash';
import { useBarChart } from '@/modules/core/charts/am5/bar/composables/useBarChart';
import { WidgetEvents } from '@/modules/core/charts/am5/charts.constants';
import { WidgetConfig } from '@/modules/ta/widget/widget.constants';
import { BasePropsMixin } from '@/modules/core/charts/am5/base/mixins/BasePropsMixin';

export default {
  name: 'BarChart',
  mixins: [BasePropsMixin],
  components: {
    ChartWrapper,
  },
  setup(props, componentContext) {
    const context = createChartContext(props.config);
    const { refresh, onBind, onFrameEnded } = context();
    const { initBarChart } = useBarChart(context);

    onBind(() => {
      initBarChart();
    });

    onFrameEnded(() => {
      componentContext.emit(WidgetEvents.FRAME_ENDED);
    });

    return {
      context,
      refresh,
    };
  },
  computed: {
    isDataEmpty() {
      if (this.config.isSparkLine) {
        return isNil(this.config.data) || isEmpty(this.config.data);
      }
      const { cached_data } = this.config.get(WidgetConfig.WIDGET);
      return isNil(cached_data) || isEmpty(cached_data);
    },
  },
};
</script>
