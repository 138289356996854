import CustomTranslationResource from '@/modules/core/customtranslation/resources/CustomTranslationResource';
import { BaseListService } from '@/modules/core/list/services/BaseListService';

export class CustomTranslationListDataService extends BaseListService {
  constructor() {
    super(CustomTranslationResource);
  }

  async getDataAndColumns(params, columns) {
    if (params.locale) {
      return super.getDataAndColumns(params, columns);
    }
    return [{ aaData: [] }, await super.getColumns(columns, params)];
  }

  /**
   *
   * @returns {Promise<axios.AxiosResponse<*>>}
   */
  logFileExport() {
    return CustomTranslationResource.logFileExport();
  }
}

export default new CustomTranslationListDataService();
