/**
 * @class LegendSettings
 */
export class LegendSettings {
  /**
   * Value text format can be specified.
   * which is empty by default on an XY Chart, but is set to '{value}' on a Pie chart
   *
   * @var {String}
   */
  valueText;

  /**
   * Label text format can be specified.
   *
   * @var {String}
   */
  labelText;

  constructor(model = {}) {
    this.valueText = model.valueText;
    this.labelText = model.labelText;
  }
}
