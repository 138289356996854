import { commits, dispatches } from '@/modules/core/app/helpers/store';
import DashboardFilterService from '@/modules/core/dashboard/services/DashboardFilterService';
import DashboardService from '@/modules/ta/dashboard/services/DashboardService';
import WidgetContextFactory from '@/modules/ta/widget/models/context/WidgetContextFactory';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { DashboardEventType } from '@/modules/ta/dashboard/dashboard.constants';

export const actions = {
  /**
   * @param value
   */
  setNeedsReload({}, value) {
    commits.dashboard.setNeedsReload(value);
  },
  async saveFilters({}, filters) {
    await DashboardFilterService.saveFilters(filters);
    return dispatches.dashboard.setFilters();
  },
  /**
   * @param value
   */
  setFiltersInitialized({}, value) {
    commits.dashboard.setFiltersInitialized(value);
  },
  /**
   * @returns {Promise<*|void>}
   */
  async setFilterOptions() {
    return commits.dashboard.setFilterOptions(await DashboardFilterService.getFilterOptions());
  },
  /**
   * @returns {Promise<*>}
   */
  async setFilters({}, shouldUpdateOldUI = true) {
    if (shouldUpdateOldUI) {
      EventBus.signal(DashboardEventType.DASHBOARD_FILTER_PANEL_OLD_UI_RESET);
    }
    return commits.dashboard.setFilters(await DashboardFilterService.getFilters());
  },
  /**
   * @returns {Promise<void>}
   */
  async resetFilters() {
    commits.dashboard.setFilters(null);
    EventBus.signal(DashboardEventType.DASHBOARD_FILTER_PANEL_OLD_UI_RESET);
    await DashboardFilterService.resetFilters();
  },
  /**
   * @param {string} pageId
   */
  async getDashboard({ state }, pageId) {
    const dashboard = await DashboardService.getItem(pageId);
    commits.dashboard.setDashboard({ dashboard });
    return state.dashboard;
  },
  async getWidgetData({}, { widget, dataService }) {
    const context = WidgetContextFactory.getContext();
    return dispatches.widget.getData({ widget, dataService, context });
  },
  async addLayout({}) {
    // missing layout
    // await dispatches.dashboardLayout.addLayout({ layout: newLayout });
    // commits.dashboard.addLayout({ layout: newLayout });
  },
  setResizingWidget({}, widgetId = null) {
    commits.dashboard.setResizingWidget(widgetId);
  },
  setCurrentLayoutId({}, id) {
    commits.dashboard.setCurrentLayoutId(id);
  },
  setDashboard({}, dashboard) {
    return commits.dashboard.setDashboard({ dashboard });
  },
  async setExecutiveSummaryEnabled({}, { dashboardId, value }) {
    await DashboardService.setExecutiveSummaryEnabled(dashboardId, value);
    commits.dashboard.setExecutiveSummaryEnabled(value);
  },
};
