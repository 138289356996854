import angular from 'angular';

import {
    WidgetType,
    WidgetTypeGrouping
} from 'coreModules/design/widget/design.widget.constants';

angular.module('widget.builder.models', [])
    .factory('WidgetBuilderModelFactory', WidgetBuilderModelFactory);

/**
 * @ngInject
 */
function WidgetBuilderModelFactory(
    WidgetFactory
) {

    return {
        getWidgetTypeModel: getWidgetTypeModel,
        getWidgetBuilderPanelData: getWidgetBuilderPanelData,
        getWidgetBuilderAdminPanelData: getWidgetBuilderAdminPanelData,
        getWidgetBuilderPanelState: getWidgetBuilderPanelState
    };

    /**
     * @param widgetType
     * @constructor
     */
    function WidgetBuilderPanelData(widgetType = null) {
        let self = this;

        let excludedGroupings = [WidgetTypeGrouping.ADMIN, WidgetTypeGrouping.PREMADE];
        self.widgetTypes = WidgetFactory.getWidgetTypes(WidgetTypeGrouping.DATASOURCED, excludedGroupings);
        self.selectedWidgetType = new WidgetTypeModel(WidgetFactory.getWidgetType(widgetType || WidgetType.BARCHART));
    }

    /**
     * @param widgetType
     * @constructor
     */
    function WidgetBuilderAdminPanelData(widgetType = null) {
        let self = this;

        self.widgetTypes = [WidgetType.ACCOUNTMANAGER];
        self.selectedWidgetType = new WidgetTypeModel(WidgetFactory.getWidgetType(widgetType || WidgetType.ACCOUNTMANAGER));
    }

    /**
     * @constructor
     */
    function WidgetBuilderPanelState() {
        let self = this;

        self.isDataWidget = false;
        self.hasChanges = false;
        self.isActive = false;
        self.isFirstCreate = true; // Only true the first time a new widget is created
        self.isNewWidget = true;
        self.isEditing = false;
        self.canSave = true;
        self.isSaving = false;
    }

    /**
     * @constructor
     */
    function WidgetTypeModel(model = {}) {
        let self = this;

        self.id = model.id || null;
        self.name = model.name || null;
        self.icon = model.icon || null;
        self.type_groupings = model.type_groupings || [];
        self.can_group_by = model.can_group_by || false;
        self.requires_group_by = model.requires_group_by || false;
        self.is_disabled = model.is_disabled || false;
    }

    /**
     * @param widgetType
     * @returns {WidgetTypeModel}
     */
    function getWidgetTypeModel(widgetType) {
        return new WidgetTypeModel(widgetType);
    }

    /**
     * @param widgetType
     * @returns {WidgetBuilderPanelData}
     */
    function getWidgetBuilderPanelData(widgetType) {
        return new WidgetBuilderPanelData(widgetType);
    }

    /**
     * @param widgetType
     * @returns {WidgetBuilderAdminPanelData}
     */
    function getWidgetBuilderAdminPanelData(widgetType) {
        return new WidgetBuilderAdminPanelData(widgetType);
    }

    /**
     * @param model
     * @returns {WidgetBuilderPanelState}
     */
    function getWidgetBuilderPanelState() {
        return new WidgetBuilderPanelState();
    }
}