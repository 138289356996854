'use strict';
import angular from 'angular';
import './order.constants';
import './scripts/orders.ctrls';
import './scripts/order.services';

import ordersHtmlUrl from '../../../../iotool/app/modules/orders/views/orders.html';
import detailedOrdersHtmlUrl from '../../../../iotool/app/modules/orders/views/detailedOrders.html';
import detailedOrdersCardHtmlUrl from '../../../../iotool/app/modules/orders/views/detailedOrders_card.html';
import orderFormHtmlUrl from '../../../../iotool/app/modules/orders/views/orderForm.html';
import orderViewHtmlUrl from '../../../../iotool/app/modules/orders/views/orderView.html';
import cancellationReasonHtmlUrl from '../../../../iotool/app/modules/orders/views/cancellationReason.html';

angular.module('core.orders', [
    'orders.ctrls',
    'orders.services',
    'orders.constants'
])
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('orders', {
                parent: 'layout',
                url: '/items/orders?reload',
                templateUrl: ordersHtmlUrl,
                controller:'OrderController',
                data: {
                    primary: true
                }
            })
            .state('detailedOrders', {
                parent: 'layout',
                url: '/items/detailedOrders',
                templateUrl: detailedOrdersHtmlUrl,
                controller: 'DetailedOrdersController'
            })
            .state('detailedOrders_card', {
                parent: 'layout',
                url: '/items/detailedOrders_card',
                templateUrl: detailedOrdersCardHtmlUrl,
                controller: 'DetailedOrdersController'
            })
            .state('addOrder', {
                parent: 'layout',
                url: '/items/addOrder?audienceId&clientId',
                templateUrl: orderFormHtmlUrl,
                controller:'OrderFormController'
            })
            .state('editOrder', {
                parent: 'layout',
                url: '/items/editOrder/:id',
                templateUrl: orderFormHtmlUrl,
                controller:'OrderFormController',
                params: {
                    message: null
                }
            })
            .state('viewOrder', {
                parent: 'layout',
                url: '/items/viewOrder/:id?detailedOrder',
                templateUrl: orderViewHtmlUrl,
                controller:'OrderViewController',
                params: {
                    order: null,
                    lineItems: null,
                    message: null,
                }
            })
            .state('myTasksOrder', {
                parent: 'layout',
                url: '/items/myTasks/:taskId/order/:id?taskDetail',
                templateUrl: orderViewHtmlUrl,
                controller: 'OrderViewController'
            })
            .state('myQueuesOrder', {
                parent: 'layout',
                url: '/items/myQueues/:taskId/order/:id?myQueues',
                templateUrl: orderViewHtmlUrl,
                controller:'OrderViewController'
            })
            .state('cancelReasons', {
                parent: 'layout',
                url: '/admin/cancelReasons',
                templateUrl: cancellationReasonHtmlUrl,
                controller:'CancelReasonController'
            })

    }]);