import angular from 'angular';

class ManageProductsDetailsService {
    defaultProps = {
        state: {
            isLoading: true
        },
        data: {
            endCustomers: []
        }
    };

    /**
     * @ngInject
     */
    constructor() {
        this.props = angular.copy(this.defaultProps);
    }
}

angular.module('core.manageproducts.services')
    .service('ManageProductsDetailsService', ManageProductsDetailsService);