import angular from 'angular';

class WorkerManagerResource {
  static $inject = ['Restangular'];

  constructor(Restangular) {
    this.Restangular = Restangular;
  }

  /**
   * @param url
   * @param params
   * @param headers
   * @returns {Promise}
   */
  async get(url, params = {}, headers = {}) {
    return await this.Restangular.one(url)
      .customGET('', { ...params }, { ...headers })
      .then(json => json.plain());
  }

  /**
   * @param url
   * @param params
   * @param body
   * @param headers
   * @returns {Promise}
   */
  async put(url, params = {}, body = {}, headers = {}) {
    return await this.Restangular.one(url)
      .customPUT({ ...body }, '', { ...params }, { ...headers })
      .then(json => json.plain());
  }

  /**
   * @param url
   * @param params
   * @param body
   * @param headers
   * @returns {Promise}
   */
  async post(url, params = {}, body = {}, headers = {}) {
    return await this.Restangular.one(url)
      .customPOST({ ...body }, '', { ...params }, { ...headers })
      .then(json => json.plain());
  }

  /**
   * @param url
   * @param params
   * @param headers
   * @returns {Promise}
   */
  async delete(url, params = {}, headers = {}) {
    return await this.Restangular.one(url)
      .customDELETE('', { ...params }, { ...headers })
      .then(json => json.plain());
  }
}

export default angular
  .module('worker.resource', [])
  .service('WorkerManagerResource', WorkerManagerResource);
