import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class MediaWidgetData extends BaseModel {
  constructor(data) {
    super();
    this.data = data;
  }

  /**
   * @override
   *
   * @param model
   * @returns {MediaWidgetData}
   */
  static factory(model = {}) {
    return new this(model);
  }
}
