'use strict';
import angular from 'angular';

import widgetExecutiveSummaryHtmlUrl from './widget.executivesummary.html';

angular.module('widget.executivesummary.directives', [])
    .component('executiveSummaryWidget', {
        templateUrl: widgetExecutiveSummaryHtmlUrl,
        bindings: {
            state: '=',
            widget: '=',
            widgetIsPredefined: '<'
        },
        controllerAs: 'vm',
        controller: ExecutiveSummaryWidgetController,
    });

/**
 * Create executive summary widget
 * @ngInject
 */
function ExecutiveSummaryWidgetController(
    $scope,
    PubSub,
    $q,
    $WidgetEvents,
    ExecutiveSummaryWidgetFactory,
    LoadingState,
    WidgetFactory,
    ExportFactory,
    ExportBuilderDashboardService,
    ReportEmptyExecutiveSummary
) {

    var vm = this;
    vm.getMarginStyles = getMarginStyles;
    init();
    buildExecutiveSummaryWidget();

    function init() {
        $scope.$on('$destroy', function () {
            destroy();
        });
        registerEvents();
    }

    function buildExecutiveSummaryWidget() {
        if (!ExportFactory.getIsExporting()) {
            vm.emptyContent = false;
            vm.state.loadingState = LoadingState.FETCHING;

            $q.resolve(ExecutiveSummaryWidgetFactory.getData(vm.widget)).then(function (json) {
                if (!_.isNull(json) && !_.isEmpty(json)) {
                    vm.widget.metadata.content = json?.content ? json.content : '';
                    vm.widget.title = json?.title ? json.title : 'Executive Summary Widget';
                } else {
                    vm.widget.metadata.content = '';
                    vm.widget.title = 'Executive Summary Widget';
                }
                if (_.isEmpty(vm.widget.metadata.content)) {
                    vm.widget.metadata.content = ReportEmptyExecutiveSummary.EMPTY_MESSAGE;
                    vm.emptyContent = true;
                }

                $scope.$evalAsync(function() {
                    const builder = ExportBuilderDashboardService.getBuilder();
                    vm.state.isEditing = false;
                    vm.state.loadingState = LoadingState.DONE;
                    WidgetFactory.widgetLoaded(vm.widget);
                    if (vm.emptyContent) {
                        if (builder.isPresentationMode) {
                            if (builder.currentPageIndex > builder.previousPageIndex) {
                                ExportBuilderDashboardService.goToNextPage();
                            } else {
                                ExportBuilderDashboardService.goToPreviousPage();
                            }
                        }
                    }
                });

            }, function () {
                $scope.$evalAsync(function() {
                    vm.state.isEditing = false;
                    vm.state.loadingState = LoadingState.HAS_ERROR;
                    console.error('Something went wrong when loading executive summary widget');
                    WidgetFactory.widgetLoaded(vm.widget);
                });
            });
        }
    }

    function registerEvents() {
        PubSub.on($WidgetEvents.WIDGET_REBUILD, buildExecutiveSummaryWidget);
    }

    function destroy() {
        _unregisterEvents();
    }

    function _unregisterEvents() {
        PubSub.off($WidgetEvents.WIDGET_REBUILD, buildExecutiveSummaryWidget);
    }
    function getMarginStyles() {
        if (vm.widget.metadata?.data_source?.id && vm.widget.height > 1 && vm.widget.title === '') {
            return {'padding-left': '3.4rem','padding-top': '0.7rem'};
        }
        return {'margin-left': '0px !important'};
    }
}
