export class DashboardState {
  /**
   * @var {boolean}
   */
  needsReload = false;

  /**
   * Have the filters been loaded from the backend yet
   * @var {boolean}
   */
  filtersInitialized = false;

  /**
   * @var {Array.<DashboardFilterOption>}
   */
  filterOptions = null;

  /**
   * @var {DashboardFilters}
   */
  filters = null;

  /**
   * @var {Dashboard?}
   */
  dashboard = null;

  /**
   * If widget is resizing
   */
  isResizingWidgetId;

  /**
   * @var {Number}
   */
  currentLayoutId = null;
}
