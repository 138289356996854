'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.service.resources', [])
    .service('ServiceResource', ServiceResource);

/**
 * @ngInject
 */
function ServiceResource(
    Restangular
) {
    var services = Restangular.all('services');

    return {
        services: services,
        getServices: getServices,
        getServiceOrders: getServiceOrders
    };

    function getServices(queryParams) {
        queryParams = _.assign({
            all: true,
            sort: 'name'
        }, queryParams);
        return services.getList(queryParams);
    }

    function getServiceOrders() {
        return services.all('orders').getList().then(function(orders) {
            return orders.plain();
        });
    }
}