'use strict';
import angular from 'angular';

angular.module('core.endcustomer.services')
    .factory('EndCustomerDetailsService', EndCustomerDetailsService);

/**
 * @ngInject
 */
function EndCustomerDetailsService() {

    var defaultProps = {
        state: {
            isLoading: true
        },
        data: {
            endCustomers: []
        }
    };

    var props = angular.copy(defaultProps);

    return {
    };
}