'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('core.namingconvention.resources', [])

    .factory('NamingConventionResource', NamingConventionResource)
    .factory('NamingConventionDataGridFactory', NamingConventionDataGridFactory);

/**
 * @ngInject
 */
function NamingConventionResource(
    Restangular
) {
    var resource = Restangular.all('namingconventions');

    return {
        getData: getData,
    };

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        queryParams.all = true;
        return resource.getList(queryParams);
    }
}
/**
 * @ngInject
 */
function NamingConventionDataGridFactory(
    $rootScope,
    NamingConventionColumns,
    DataGridRender,
    UINamingConventionModalFactory
) {
    return {
        getDTOptions: getDTOptions,
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};

        dtOptions.customRenders = {};
        dtOptions.customRenders[NamingConventionColumns.ID] = function (data, type, full) {
            var html = '';

            html += DataGridRender.getEditButton(full.can_be_edited, 'namingconventions/edit' + data, full.name);
            html += DataGridRender.getCopyButton(full.can_be_copied, 'namingconventions/copy/' + data, full.name);
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'namingconventions/' + data, full.name);

            return html;
        };

        dtOptions.customRenders[NamingConventionColumns.RULE] = function (data, type, full) {
            var html = '';

            var object = JSON.parse(data);
            _.forEach(object, function (ruleItem) {
                html += '<span class="naming-convention-preview-item '+ ruleItem.type +'">'+ ruleItem.label +'</span>'
            });

            return html;
        };

        // column widths
        dtOptions.columnWidths = {
            'status': 50
        };

        //adding created row
        dtOptions.fnCreatedRow = function(row, data) {
            $(row).find('td.action-cell a.btn').each(function () {
                if ($(this).hasClass('delete-action')) {
                    DataGridRender.bindDeleteRowAction($(this));
                }
                else if ($(this).hasClass('copy-action')) {
                    DataGridRender.bindCopyRowAction($(this));
                }
                else if ($(this).hasClass('edit-action')) {
                    $(this).click(function() {
                        //TODO: @dannyyassine $rootscope?
                        $rootScope.$evalAsync(function () {
                            UINamingConventionModalFactory.setNamingConventionId(data.id);
                        });
                    });
                }
            });
        };

        return dtOptions;
    }
}