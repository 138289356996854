'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.accountmanager.services', [])

    .factory('AccountManagerFactory', AccountManagerFactory);

/**
 * @ngInject
 */
function AccountManagerFactory(
    DashboardContextService,
    AccountManagerResource,
    $q,
    $timeout,
    AppFactory,
    LoadingState,
    UserType,
    DashboardFilterHeaderFactory,
    DesignFactory,
    ExportFactory,
    WidgetCreateFactory
) {

    var brandMappings = AppFactory.getBrandMappings();

    return {
        brandMappings: brandMappings,
        getWarningMessage: getWarningMessage,
        hasNoData: hasNoData,
        getManagerData: getManagerData
    };

    /**
     *
     * @param userId
     * @param metadata
     * @returns {*}
     */
    function getWarningMessage(userId, metadata) {
        // Widget creation selected columns are objects, created widget selected columns are strings
        // Display no data for client with no account manager

        if (DesignFactory.getIsExportingPage()) {
            if (ExportFactory.isScheduledReport()) {
                // how do I know viewing as client in scheduled report?
                if (!_isViewingAsClient()) {
                    return {
                        title: 'This report is for more than one ' + brandMappings.client_type + '.',
                        text: 'Please set up a report for a single ' + brandMappings.client_type + ' to see Account Manager information'
                    };
                } else if (_.isEmpty(userId)) {
                    return {
                        title: 'No Account Manager assigned' + (AppFactory.getUser().isClient() ? '' : ' to the ' + brandMappings.client_type) + ' in the system',
                        text: ''
                    };
                } else {
                    return {
                        title: 'Unable To Display Account Manager Information',
                        text: ''
                    };
                }
            } else {
                if (_canAssignAccountManager() && !_isViewingAsClient()) {
                    return {
                        title: 'This report is for more than one ' + brandMappings.client_type + '.',
                        text: 'Please select a ' + brandMappings.client_type + ' in the dashboard and export to see Account Manager information'
                    };
                } else if (_canAssignAccountManager() && _isViewingAsClient()) {
                    return {
                        title: 'No Account Manager assigned to the current ' + brandMappings.client_type.toLowerCase(),
                        text: 'Please assign an Account Manager to the current ' + brandMappings.client_type
                    };
                } else if (_.isEmpty(userId)) {
                    return {
                        title: 'No Account Manager assigned' + (AppFactory.getUser().isClient() ? '' : ' to the ' + brandMappings.client_type) + ' in the system',
                        text: ''
                    };
                } else {
                    return {
                        title: 'Unable To Display Account Manager Information',
                        text: ''
                    };
                }
            }
        } else {
            if (_canAssignAccountManager() && !_isViewingAsClient()) {
                return {
                    title: 'Please select a ' + brandMappings.client_type + ' to see Account Manager information',
                    text: 'No user information will be displayed'
                };
            } else if (_canAssignAccountManager() && _isViewingAsClient) {
                return {
                    title: 'No Account Manager assigned to the current ' + brandMappings.client_type.toLowerCase(),
                    text: WidgetCreateFactory.isActive() ? '' : 'Click here to assign an account manager',
                    link: WidgetCreateFactory.isActive()
                        ? ''
                        : (function() {
                        return window.isNUI ? '#/clients/' : '#/clients/detail/';
                    })() + _getClientFilterId()
                };
            } else if (_.isEmpty(userId)) {
                return {
                    title: 'No Account Manager assigned' + (AppFactory.getUser().isClient() ? '' : ' to the ' + brandMappings.client_type) + ' in the system',
                    text: ''
                };
            } else {
                return {
                    title: 'Unable To Display Account Manager Information',
                    text: ''
                };
            }
        }
    }

    /**
     *
     * @param loadingState
     * @returns {boolean}
     */
    function hasNoData(loadingState) {
        return loadingState === LoadingState.NO_DATA_ACTIONABLE;
    }

    /**
     *
     * @param selectedColumns
     * @returns {{}}
     */
    function getManagerData(selectedColumns) {
        var fields = _.map(selectedColumns, function(column) {
            return column.field;
        });

        fields.push('user_image_metadata');
        var currentUser = AppFactory.getUser();

        if (currentUser.isClient()) {
            return _getManagerDataForId(currentUser.account_manager_user_id, {fields: fields.join(',')});
        } else {
            return _getManagerId().then(function(managerId) {
                if (managerId) {
                    return _getManagerDataForId(managerId, {fields: fields.join(','), account_manager_of: _getClientFilterId()});
                } else {
                    var deferred = $q.defer();
                    deferred.resolve({});
                    return deferred.promise;
                }
            });
        }
    }

    /**
     *
     * @param managerId
     * @param params
     * @returns {{}}
     * @private
     */
    function _getManagerDataForId(managerId, params) {
        if (managerId) {
            return AccountManagerResource.getOwner(managerId, params).then(function(json) {
                return json;
            });
        } else {
            var deferred = $q.defer();
            deferred.resolve({});
            return deferred.promise;
        }
    }

    /**
     *
     * @returns {null}
     * @private
     */
    function _getManagerId() {
        if (_isViewingAsClient()) {
            return AccountManagerResource.getClient(_getClientFilterId()).then(function(json) {
                return json.account_manager_user_id;
            });
        } else {
            var deferred = $q.defer();
            deferred.resolve();
            return deferred.promise;
        }
    }

    /**
     * Returns true only if applying filter as a client, or client assigned dashboard
     * @returns {*|boolean}
     * @private
     */
    function _isViewingAsClient() {
        if (!_.isEmpty(DashboardContextService.resolveSelectedEntity()) && DashboardContextService.resolveSelectedEntity().type === UserType.CLIENT) {
            return true;
        } else if (DesignFactory.getIsExportingPage()) {
            return !_.isNil(ExportFactory.getVars().reportVars.report_descriptor) || !_.isEmpty(DashboardContextService.resolveSelectedEntity());
        } else {
            var sharedDashFilters = DashboardFilterHeaderFactory.shared;
            if (!sharedDashFilters.isActive()) {
                return false;
            }
            const filter = _.first(sharedDashFilters.activeFilters.dash);
            return filter && filter.label === "Client";
        }
    }

    /**
     *
     * @private
     */
    function _getClientFilterId() {
        var sharedDashFilters = DashboardFilterHeaderFactory.shared;
        return sharedDashFilters.isActive()
            ? _.first(_.first(sharedDashFilters.activeFilters.dash).values).id
            : DashboardContextService.resolveSelectedEntity().id;
    }

    /**
     * Only admins can assign an account manager to a user
     * @returns {*}
     * @private
     */
    function _canAssignAccountManager() {
        return !AppFactory.getUser().isClient();
    }

}