import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { DrilldownState } from './drilldown.state';
import { getters } from './drilldown.getters';
import { mutations } from './drilldown.mutations';
import { actions } from './drilldown.actions';

VueXHelper.setStoreModule(Module.DASHBOARD_DRILLDOWN, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new DrilldownState(),
  getters,
  mutations,
  actions,
};
