'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('goals.resources', [])

    .factory('GoalsResource', GoalsResource)
    .factory('GoalsDataResource', GoalsDataResource);

/**
 * @ngInject
 */
function GoalsResource(
    Restangular
) {
    var resource = Restangular.all('goals');


    return {
        getResource: getResource,
        getData: getData,
        getServices: getServices,
        get: get,
        remove: remove,
        save: save,
    };

    function data(goalId) {
        return Restangular.service('data', Restangular.one('goals', goalId));
    }

    function getResource() {
        return resource;
    }

    /**
     *
     * @param queryParams
     * @param dataSource
     * @returns {*}
     */
    function getData(queryParams, dataSource) {
        queryParams.all = true;

        return data(dataSource.id)
            .getList(queryParams);
    }

    /**
     *
     * @param params
     * @returns {*}
     */
    function getServices(params = {}) {
        return resource.one('services').get(params);
    }

    /**
     *
     * @param id
     * @returns {*}
     */
    function get(id) {
        return resource.one(id).get({all: true}).then(function (data) {
            return data.plain();
        });
    }

    /**
     * Save Goal
     * @param payload
     * @returns {*|{method, isArray}|{method}}
     */
    function save(payload) {
        return _.isNil(payload.id) ? resource.post(payload) : resource.all(payload.id).post(payload);
    }

    /**
     * Remove Goal
     * @param payload
     * @returns {*|{method, isArray}|{method}}
     */
    function remove(goalId) {
        return resource.all(goalId).remove();
    }
}

/**
 * @ngInject
 */
function GoalsDataResource(
    Restangular,
    ColumnValueType
) {
    var resource = Restangular.all('goals/data');
    var values = resource.one('values');

    return {
        getResource: getResource,
        getData: getData,
        getColumns: getColumns,
        getFieldValues: getFieldValues
    };

    /**
     *
     * @param queryParams
     * @returns {angular.IPromise<T>|*|undefined}
     */
    function getColumns(queryParams) {
        return resource.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get values for all possible distinct specific field
     * @param fields
     * @param dataSource
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        return values.one(ColumnValueType.DISTINCT).get(_.isUndefined(fields) ? '' : {fields: fields, ...queryParams});
    }

    function getResource() {
        return resource;
    }

    /**
     *
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        return resource.getList(queryParams);
    }
}
