'use strict';
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import dashExportHtmlUrl from './dashexport.html';
import EventBus from "../../../../../grok/src/modules/core/app/helpers/EventBus";

angular.module('core.export.directives', [])

    .component('exportDashContainer', {
        templateUrl: dashExportHtmlUrl,
        controllerAs: 'vm',
        bindings: false,
        controller: ExportDashContainerController
    })
    .component('exportDashThumbnail', {
        templateUrl: dashExportHtmlUrl,
        controllerAs: 'vm',
        bindings: false,
        controller: ExportDashContainerController
    });

function ExportDashContainerController(
    AppFactory,
    LoginFactory,
    DesignFactory,
    WidgetFactory,
    ExportFactory,
    DateRangeFactory,
    ExecutiveSummaryFactory,
    UIFactory,
    PHTMLVARS,
    ImageExportLoadingFactory,
    $timeout
) {
    function init() {

        // User
        LoginFactory.setDefaultUserVars(PHTMLVARS.user);

        // Theme
        UIFactory.setThemeBaseColors(PHTMLVARS.themeBaseColors);

        // Relative Date Ranges
        DateRangeFactory.setRelativeDateRanges(PHTMLVARS.relativeDateRanges);

        // Widget types
        WidgetFactory.setWidgetTypes(PHTMLVARS.widgetTypes);

        // Set brandMappings
        AppFactory.setBrandMappings(PHTMLVARS.brandMappings);

        // Services
        AppFactory.setServices(PHTMLVARS.services);

        // Set dates
        var dates = PHTMLVARS.dates;
        AppFactory.setDateRange(dates.start, dates.end);
        if (dates.comparison_start && dates.comparison_end) {
            AppFactory.setComparisonDateRange(moment.unix(dates.comparison_start).utc(), moment.unix(dates.comparison_end).utc(), true);
        }

        // Set design variables
        var design = PHTMLVARS.design;
        var page = design.page;
        DesignFactory.setCurrentPage(page);
        DesignFactory.setIsExportingPage(true);
        DesignFactory.setCurrentLayoutId(Object.keys(page.layouts)[0]);
        ExecutiveSummaryFactory.setExecutiveSummary(DesignFactory.getCurrentLayout().executive_summary);

        /* Set client currency code if any present */
        AppFactory.setCurrentClientCurrency(page.client_currency_code);

        addBodyClasses();

        // Design is a heavy object and not needed and is already stored in the DesignFactory
        delete PHTMLVARS.design;

        // Store all export variables
        ExportFactory.setVars(PHTMLVARS);

        var allWidgets = DesignFactory.getAllWidgets();

        // We need to check if any charts (without backend errors) exist
        // If there's no chart, then we can use the ExportFactory to tell wkhtmltopdf that we are ready for render.
        if (!ExportFactory.chartExists(allWidgets)) {
            ExportFactory.setChartsReady();
        }
        ExportFactory.widgetReadyCounter.setNumWidgets(_.size(allWidgets));

        // listen for any executive summaries being created
        EventBus.listen('export-carousel-loaded', extractExecutiveSummary);
    }

    init();

    /**
     * The executive summary carousel causes a bunch of issues when left in-situ during export.
     * It automatically sizes itself to be the height of the active element which creates an element
     * with a fixed height which we've found causes issues when spanning over a page as we can't get back
     * the extra space caused by the page break.  Thus we're left with a widget size that is too small
     * by the amount of pages it spanned and where in the element it breaks the page.
     *
     * This seemed like the cleanest way of still displaying the Executive Summary -- we strip out all
     * the navigation elements, drag handles and admin interface and just leave behind the content in an element
     * that is dynamically sized and will break for pages and so span correctly
     *
     * This leaves us with a single element per executive summary defined in the report:
     * <div class="owl-carousel owl-theme owl-loaded">
     *      [...]
     *      <div>
     *          <h3 class="title-container center mt0" />
     *          <div class="owl-carousel-content" />
     *          <div class="info-container flex-full flex-row" />
     *      </div>
     *      [...]
     * </div>
     *
     * We have to recreate it instead of pull it out of the page
     * as we can't guarantee that everything is inside the DOM at this point
     */
    function extractExecutiveSummary(el, list) {
        ExportFactory.widgetReadyCounter.incRef();

        const listArray = Object.values(list);
        if (!listArray.length) {
            return;
        }

        const parentElement = el.parents('div#executive-summary-export').first();
        const headerElement = parentElement.find('div.main-content-title').first();
        if (headerElement.hasClass('es-extracted')) {
            ExportFactory.widgetReadyCounter.decRef();
            return;
        }
        headerElement.addClass('es-extracted');

        const owlElement = listArray.pop();
        const newOwlContainer = $('<div />').addClass('owl-carousel owl-theme owl-loaded');
        const divWrapper = $('<div />');
        const owlContent = $('<div />').addClass('owl-carousel-content');
        const infoContainer = $('<div />').addClass('info-container flex-full flex-row');

        // whether a title exists
        if (owlElement.title) {
            const title = $('<h3 />').addClass('title-container center mt0');
            title.text(owlElement.title);
            title.appendTo(divWrapper);
        }

        // actual content. pull out any trailing empty paragraphs whilst we have the chance here
        const elementContent = $('<div>' + owlElement.content + '</div>');
        const paragraphs = elementContent.children();
        if (paragraphs.length) {
            const finalParagraph = paragraphs[paragraphs.length - 1];
            if (isEmptyElement(finalParagraph)) {
                const finalParagraphElement = $(finalParagraph);
                // include <p> and </p>
                const finalParagraphLength = finalParagraphElement[0].innerHTML.length + 7;
                const elementLength = owlElement.content.length - finalParagraphLength;
                // strip out the final empty paragraph
                owlElement.content = owlElement.content.substring(0, elementLength);
            }
        }

        // this is processed in angular with a blind trustHTML so it's safe to use here
        owlContent.html(owlElement.content);
        owlContent.appendTo(divWrapper);

        // date element on right
        if (owlElement.hasDate()) {
            const infoSpan = $('<span />')
                .addClass('date-display margin-left-auto')
                .text(owlElement.getDateText());

            infoSpan.appendTo(infoContainer);
        }

        // stitch everything together and place it in the parent element
        infoContainer.appendTo(divWrapper);
        divWrapper.appendTo(newOwlContainer);

        const parentEl = el.parent();
        newOwlContainer.appendTo(parentEl);

        // remove the existing carousel element
        el.remove();

        // $timeout to ensure everything is in the DOM, so we can measure elements
        $timeout(function() {
            // to get the ES to update in the DOM if it needs to span across multiple pages in the NUI
            // then we need to add a paragraph to trick things into happening.  otherwise the PDF generates
            // too quickly without the changes.  this is, unfortunately, a load-bearing span now.
            const junkPdfSpan = $('<span />')
                .addClass('pdf-page-span-trick')
                .text('...');
            parentEl.append(junkPdfSpan);
            // now check whether the ES spans multiple pages (once any images included in it are loaded)
            let images = owlContent[0].querySelectorAll("img");
            ImageExportLoadingFactory.imagesAreLoaded(ImageExportLoadingFactory.hashString(owlContent.html()), images).then(() => {
                checkForESPageSpan(newOwlContainer);
            });
        }, 0);
    }
    /**
     * Check whether the ES will span over onto another page BUT
     * only when the date element spans onto the second page, so we don't
     * leave it lonely on its own page
     * If this happens then we drop a page break into the content so there's
     * at least something on the page with the date
     * @param esElement jQuery HTML element containing executive summary
     */
    function checkForESPageSpan(esElement)
    {
        // usable height on a landscape PDF in px. anything over this value will need to be pushed to the next page
        let PAGE_HEIGHT_1 = 1230;
        // buffer / padding between bottom of content and the date box
        const BUFFER = 25;
        // space between the footer (date element) and the bottom of the content
        const FOOTER_MARGIN_BOTTOM = 35;
        const PAGE_HEIGHT_1_BUFFER = PAGE_HEIGHT_1 + BUFFER;
        // usable height of a page that isn't the first page
        const PAGE_HEIGHT_PER = 1214;
        const parentElement = esElement.parents('div#executive-summary-export').first();
        const footerElement = esElement.find('div.info-container').first();
        const titleElement = esElement.find('h3.title-container').first();

        let esHeight = parentElement.outerHeight(true),
            footerHeight = footerElement.outerHeight(true),
            addPageBreak = false
        ;

        // es isn't measurable so early out
        if (!esHeight) {
            removePDFPageSpanTrick(esElement);
            ExportFactory.widgetReadyCounter.decRef();
            return;
        }

        // if no title is present in the header then the amount of space we're working with on a page is lessened
        if (!titleElement.length) {
            PAGE_HEIGHT_1 -= BUFFER;
        }

        // smaller than a page so we don't need to do anything
        if (esHeight < PAGE_HEIGHT_1) {
            removePDFPageSpanTrick(esElement);
            ExportFactory.widgetReadyCounter.decRef();
            return;
        }

        // spans across a single page onto a second and ends with the date on the final page
        const onePageCheck = esHeight - PAGE_HEIGHT_1_BUFFER - footerHeight - FOOTER_MARGIN_BOTTOM;

        if (esHeight >= PAGE_HEIGHT_1 && onePageCheck <= BUFFER) {
            addPageBreak = true;
        } else {
            // spans over more than one page with the date on the final page
            if (esHeight > PAGE_HEIGHT_1_BUFFER) {
                esHeight -= PAGE_HEIGHT_1_BUFFER;
                let remainingHeight = esHeight % PAGE_HEIGHT_PER,
                    pageCount = Math.ceil(esHeight / PAGE_HEIGHT_PER);
                let pageBuffer = pageCount * BUFFER;
                pageBuffer += footerHeight;
                if (remainingHeight - pageBuffer - FOOTER_MARGIN_BOTTOM <= BUFFER) {
                    addPageBreak = true;
                }
            }
        }

        // ES editor wraps everything either inside a p, ul, ol, table
        if (addPageBreak) {
            let elements = esElement.find('div.owl-carousel-content p, div.owl-carousel-content ul, div.owl-carousel-content ol, div.owl-carousel-content table');
            let element = findNonEmptyElement(elements);
            if (element) {
                // if it's already a p we can just add the class
                if (element.tagName === 'P') {
                    element.classList.add('es-break-before');
                } else {
                    // otherwise we need to wrap it (table / ul / ol, etc) and then apply the break to the wrapper
                    const breakWrapper = document.createElement('div');
                    breakWrapper.classList.add('es-break-before');
                    element.parentNode.insertBefore(breakWrapper, element);
                    breakWrapper.appendChild(element);
                }
            }
            ExportFactory.widgetReadyCounter.decRef();
        } else {
            removePDFPageSpanTrick(esElement);
            ExportFactory.widgetReadyCounter.decRef();
        }
    }

    /**
     * Remove the page break-causing spans that we don't need
     * @param el
     */
    function removePDFPageSpanTrick(el) {
        el.parent().find('.pdf-page-span-trick').remove();
    }

    /**
     * We don't want to add a page break to an empty element so
     * scroll back until we find one that's not empty
     * @param nodeList
     * @returns {*}
     */
    function findNonEmptyElement(nodeList) {
        let nodeLen = nodeList.length;
        while (--nodeLen >= 0) {
            let element = nodeList.get(nodeLen);
            if (isEmptyElement(element)) {
                continue;
            }
            return element;
        }
    }

    const allowedTags = ['IMG', 'UL', 'OL', 'TABLE'];

    /**
     * Whether an element is "empty" or not
     * @param element
     * @returns {boolean}
     */
    function isEmptyElement(element) {
        if (elementIsAllowedTag(element)) {
            return false;
        }
        // iterate through the children and see if there's data there
        if (elementContainsValidChildren(element)) {
            return false
        }
        // entirely empty text
        const jElement = $(element);
        return !jElement.text().length;
    }

    /**
     * Returns whether the current element matches one that we know isn't blank
     * @param element DOM element
     * @returns {boolean}
     */
    function elementIsAllowedTag(element) {
        return allowedTags.includes(element.tagName);
    }

    /**
     * Recursive function that checks a parent element's children to find blank
     * Works from the bottom-up to make it as quick as possible as we've seen this:
     * <span><span><img /></span></span>
     * @param element
     * @returns {*|boolean|boolean}
     */
    function elementContainsValidChildren(element) {
        const children = element.children;
        for (let index = children.length; index > 0; ) {
            index--;
            const child = children[index];
            if (child.children.length) {
                return elementContainsValidChildren(child);
            }
            if (elementIsAllowedTag(child)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Add appropriate export body classes
     */
    function addBodyClasses() {
        angular.element('body').addClass('exporting');
        angular.element('body').addClass(PHTMLVARS.orientation);

        if (PHTMLVARS.isExportingSingleWidget) {
            angular.element('body').addClass('exporting-widget');
        }

        if (PHTMLVARS.styleForPrint) {
            angular.element('body').addClass('print');
        }

        if (PHTMLVARS.isHtml) {
            setupHtmlView();
        }
    }

    /**
     * HTML ONLY configurations
     */
    function setupHtmlView() {
        angular.element('body').addClass('exporting-html');
    }

    return this;
}