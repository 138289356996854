'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import $script from 'scriptjs';
import { AppConstants } from "coreModules/shared/scripts/app.constants";

angular.module('core.services', [])
    .factory('CoreFactory', CoreFactory)
    .factory('ThemeUIService', ThemeUIService);

/**
 * Reserved for high level core JS operations
 * @ngInject
 */
function CoreFactory(
    AppConfig,
    AppFactory,
    DesignFactory,
    AppModule) {
    var _cssId = 'theme-css';

    return {
        getCorePath: getCorePath,
        getAnalyticsPath: getAnalyticsPath,
        getAssetPath: getAssetPath,
        lazyLoadTheme: lazyLoadTheme,
        lazyLoadJs: lazyLoadJs,
        getNUIPath: getNUIPath
    };

    function getNUIPath(path) {
        return AppConfig.NUI_PATH + (path || '');
    }

    function getCorePath(path) {
        return AppConfig.CORE_URL + _getEnvironmentMode() + '/' + (path || '');
    }

    function getAnalyticsPath(path) {
        return AppConfig.TA_PRE_URL + _getEnvironmentMode() + '/' + (path || '');
    }

    function getAssetPath(path) {
        const assetPath = window.isNUI
            ? getNUIPath(path)
            : getAnalyticsPath(path);
        return ASSET_DOMAIN + assetPath;
    }

    /**
     *
     * @param theme
     * @param type
     * @param [primaryColor] Needed if custom theme
     * @param [secondaryColor] Needed if custom theme
     * @param [replace] Replace css
     * @param [context] Needed for reports
     * @returns {HTMLLinkElement}
     */
    function lazyLoadTheme(theme, type, primaryColor, secondaryColor, replace, context) {
        context = context || document;
        var link  = context.createElement('link');
        link.id = _cssId;

        if (DesignFactory.getIsExportingPage()) {
            return link;
        }

        link.media = 'all';
        link.type = 'text/css';
        link.rel = 'stylesheet';
        var href = '/customtheme.php?theme=' + theme + '&type=' + type;
        // var href = AppConfig.API_URL + 'themebuilder/' + theme + '/' + type;

        if (primaryColor && secondaryColor) {
            primaryColor = primaryColor.replace('#', '');
            secondaryColor = secondaryColor.replace('#', '');

            href = href + '&primary=' + primaryColor + '&secondary=' + secondaryColor;
            // href = href + '/' + primaryColor + '/' + secondaryColor;
        }

        link.href = href;

        if (!$('#' + _cssId).length) { // first load
            $('head').append(link);
            link.className = 'current-theme';
        } else if (replace) {
            if (!link.addEventListener) {
                $('#'+_cssId+'.current-theme').removeClass('current-theme');
                link.className = 'current-theme';
                $('head').append(link);
            } else {
                $('head').append(link);
                link.addEventListener('load', function () {
                    if ($('head link[href^="/customtheme"]').length === 1) { // if only one, must not replace, it will remove it
                        return;
                    }
                    $('head link[href^="/customtheme"].current-theme').removeClass('current-theme');
                    link.className = 'current-theme';
                    $('head link[href^="/customtheme"]:not(.current-theme)').remove();
                });
            }
        }

        return link;
    }

    function lazyLoadJs(files, path) {
        const shouldUseNui = AppFactory.getUser().shouldUseNui() ||
            AppFactory.getUser().isModuleAvailable(AppModule.DISABLE_OLD_UI);
        const appUrl = shouldUseNui ? getNUIPath() : `${AppConfig.TA_PRE_URL}${AppConfig.MODE}/`;
        path = _.isUndefined(path) ? `../../..${appUrl}async/` : path;
        let scriptPath = getCorePath(path);
        if(!_.isEmpty(ASSET_DOMAIN)) {
            scriptPath = ASSET_DOMAIN + scriptPath
        }
        $script.path(scriptPath);
        var processName = (new Date().getTime()).toString(36);
        $script(files, processName);
        return processName;
    }

    /**
     * Produces a random number
     * @returns {string}
     * @private
     */
    function _randomNumber() {
        return Math.random().toString().replace('0.', '');
    }

    /**
     * Returns if app is currently is development or production mode
     * @returns {string}
     * @private
     */
    function _getEnvironmentMode() {
        return AppConfig.MODE;
    }
}

/**
 * @ngInject
 */
function ThemeUIService(
    CoreFactory
) {
    var _forceReload = false;

    return {
        setForceReload: setForceReload,
        getForceReload: getForceReload,
        update: update,
        updateHtml: updateHtml
    };

    function setForceReload(value) {
        _forceReload = value
    }

    function getForceReload() {
        return _forceReload;
    }

    function update(theme, type, primaryColor, secondaryColor, replace, context) {
        var $link = CoreFactory.lazyLoadTheme(theme, type, primaryColor, secondaryColor, replace, context);
        $link.onload = function () {
            updateHtml(theme + type);
        };
        return $link;
    }

    function updateHtml(theme) {
        _switchCssClass(theme);
    }

    function _switchCssClass(theme) {
        var $html = $('html');
        var currentBodyClass = $html.attr('class');
        $html.removeClass(currentBodyClass).addClass(theme);
    }
}