import { Label } from '@/modules/core/charts/models/Label';
import { Series } from '@/modules/core/charts/models/Series';

/**
 * @class SliceSeries
 * @extends Series
 */
export class SliceSeries extends Series {
  /**
   * @var {String}
   */
  category;

  /**
   * @var {String|Number}
   */
  value;

  /**
   * A list of label elements for the series.
   * @var {Array<Label>}
   */
  labels;

  /**
   * If should show ticks on chart
   * @var {Boolean}
   * @default true
   */
  showTicks;

  /**
   * Background colors for each slice
   * @var {Array<String>}
   */
  colors;

  /**
   * Pyramid/Funnel top width percentage string
   * @var {Number}
   */
  topWidth;

  /**
   * Pyramid/Funnel bottom width percentage string;
   * @var {Number}
   */
  bottomWidth;

  /**
   * Pyramid/Funnel align all labels at right side
   * @var {Boolean}
   */
  alignLabels;

  /**
   * Pyramid height
   * @var {Number}
   */
  pyramidHeight;

  /**
   * Key holding hiddenInLegend field in data
   * @var {String}
   */
  hiddenInLegendDataKey;

  /**
   * Key holding id field in data
   * @var {String}
   */
  idDataKey;

  /**
   * Function to trigger when a slice is clicked
   * @var {Function}
   */
  onSliceClick;

  /**
   * Function triggered for each data point to decide if label is hidden
   * @var {Function}
   */
  isLabelHidden;

  /**
   * Text/Template to be used for tooltip text
   * @var {String}
   */
  tooltipText;

  constructor(model = {}) {
    super(model);
    const { showTicks = true } = model;
    this.category = model.category;
    this.value = model.value;
    this.labels = model.labels?.map((label) => new Label(label));
    this.showTicks = showTicks;
    this.colors = model.colors;
    this.fill = model.fill;
    this.fillOpacity = model.fillOpacity;
    this.topWidth = model.topWidth;
    this.bottomWidth = model.bottomWidth;
    this.alignLabels = model.alignLabels;
    this.pyramidHeight = model.pyramidHeight;
    this.hiddenInLegendDataKey = model.hiddenInLegendDataKey;
    this.idDataKey = model.idDataKey;
    this.onSliceClick = model.onSliceClick;
    this.isLabelHidden = model.isLabelHidden;
    this.tooltipText = model.tooltipText;
  }
}
