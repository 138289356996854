import { SerialChartDrawOption } from '@/modules/ta/widget/models/drawoptions/SerialChartDrawOption';
import { PlotType } from '@/modules/ta/widget/widget.constants';

export class LineChartDrawOption extends SerialChartDrawOption {
  setValidPlotType(model) {
    const validTypes = [
      PlotType.LINE,
      PlotType.LINE_V2,
      PlotType.AREA,
      PlotType.AREA_V2,
      PlotType.STACKED,
      PlotType.STACKED_V2,
      PlotType.FULL_STACKED,
      PlotType.FULL_STACKED_V2,
    ];

    if (!validTypes.includes(model.plot_type)) {
      this.plot_type = PlotType.LINE;
    }
  }
}
