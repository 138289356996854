export class BillingState {
  /**
   * instance billing details
   */
  billingDetails;

  /**
   * Current selected billing period unit by user(monthly or yearly)
   */
  billingPeriodUnit;

  /**
   * Current selected product
   */
  currentProduct;

  /**
   * overview tab data
   */
  overviewDetails;

  /**
   * selected plan for checkout
   */
  currentSelectedPlan;

  /**
   * selected addons
   */
  selectedAddons = {};

  /**
   *Addon which needs to be added to selectedAddons
   */
  selectedAddonCode;
}
