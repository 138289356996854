'use strict';
import angular from 'angular';
import $ from 'jquery';

import serviceEntityDisplayHtmlUrl from './serviceentitydisplay.html';
import serviceScoreDisplayHtmlUrl from './servicescoredisplay.html';
import campaignPerformanceHtmlUrl from './campaignperformance.html';

angular.module('core.service.directives')

    .directive('serviceEntityDisplay', serviceEntityDisplay)
    .directive('serviceScoreDisplay', serviceScoreDisplay)
    .directive('campaignPerformance', campaignPerformance);

/**
 * Display service entity info if service/category datasource
 */
function serviceEntityDisplay() {
    return {
        restrict: 'E',
        controller: 'ServiceEntityDisplayController',
        templateUrl: serviceEntityDisplayHtmlUrl,
        scope: {
            datasource: '<',
            metadata: '<'
        }
    }
}


/**
 * Display score info if service/category datasource
 */
function serviceScoreDisplay() {
    return {
        restrict: 'E',
        controller: 'ServiceScoreDisplayController',
        templateUrl: serviceScoreDisplayHtmlUrl,
        scope: {
            datasource: '<',
            metadata: '<'
        }
    }
}

/**
 * Show overall campaign performance
 */
function campaignPerformance () {
    return {
        restrict: 'E',
        scope: {
            metadata: '<'
        },
        controller: 'CampaignPerformanceController',
        templateUrl: campaignPerformanceHtmlUrl
    }
}