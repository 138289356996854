import angular from 'angular';
import pusherService from './base/pusher.service';
import pusherResource from './base/pusher.resource';
import pusherConstants from './pusher.constant';

export default angular.module('core.pusher', [
  pusherService.name,
  pusherResource.name,
  pusherConstants.name
]);
