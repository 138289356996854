'use strict';
import angular from 'angular';
import './scripts/campaign.ctrls';
import './scripts/campaign.directives';
import './scripts/campaign.services';

angular.module('campaign', [
    'campaign.ctrls',
    'campaign.directives',
    'campaign.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('campaigns', {
                parent:'layout',
                url: '/campaigns',
                controller: 'CampaignListController',
                templateUrl: AppConfig.MAGE_URL + 'campaign/manage_campaigns',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('campaigns')
                }
            })
            .state('campaigns.filter', {
                parent:'layout',
                url: '/campaigns/service/:service_id',
                controller: 'CampaignListController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'campaign/manage_campaigns?service_id=' + $stateParams.service_id;
                }
            })
    }]);