'use strict';
import angular from 'angular';
import './scripts/role.ctrls';
import './scripts/role.directives';
import './scripts/role.services';

angular.module('role', [
    'role.ctrls',
    'role.directives',
    'role.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('roles', {
                parent:'layout',
                url: '/roles',
                controller: 'RoleListController',
                templateUrl: AppConfig.MAGE_URL + 'role/manage_roles',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('roles')
                }				})
            .state('roles.add', {
                parent:'layout',
                url: '/roles/add',
                data: { isNewEntity: true, primaryKey: 'role_id' },
                controller: 'RoleController',
                templateUrl: AppConfig.MAGE_URL + 'role/add_role'
            })
            .state('roles.detail', {
                parent:'layout',
                url: '/roles/detail/:id',
                controller: 'RoleController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'role/view_role/role_id/' + $stateParams.id;
                }
            })
    }]);