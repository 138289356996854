'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.loader', [])
    .directive('loader', loader);

//
//  Loader directive
//  Creates a spinner with the primary color and the setted size
//  if inverse is set to true (or 1), the "inverse color" will be used
//
//  @params: size: [Optional] height and width of the spinner (Default: 26px)
//  @params: inverse: [Optional] use the inverse-color instead of the primary-color (Default: false)
//
function loader() {
    return {
        restrict: 'E',
        scope: {
            size: '<?',
            inverse: '<?'
        },
        transclude: {
            'number': '?number',
            'icon': '?icon'
        },
        controller: function($scope) {

            //define default size to 26 if necessary
            $scope.size = $scope.size || 26;

            // create style function
            $scope.containerStyle = _.memoize(function() {
                return {
                    'width': $scope.size,
                    'height': $scope.size
                };
            });

            // create style function
            $scope.style = _.memoize(function() {
                return {
                    'border-width': $scope.size/10,
                    'width': $scope.size,
                    'height': $scope.size
                };
            });

            // create classes function
            $scope.classes = _.memoize(function() {
                return {
                    'spin-loader': true,
                    'inverse': !!$scope.inverse
                };
            });

        },
        template: '<div class="loader-container">' +
            '<div class="flex flex-center loader-inner" ng-style="::containerStyle()">' +
            '<div ng-class="::classes()" ng-style="::style()">' +
            '<div ng-transclude="number"></div>' +
            '<div ng-transclude="icon"></div>' +
            '</div>' +
            '</div>' +
            '</div>'
    }
}