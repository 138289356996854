import SessionService from '@/modules/core/session/services/SessionService';
import { commits, dispatches, getters } from '@/modules/core/app/helpers/store';
import ThemeUIService from '@/modules/core/app/services/ThemeUIService';
import SegmentService from '@/modules/core/app/services/SegmentService';
import { Env } from '@/env';
import BrandMappingService from '@/modules/core/session/services/BrandMappingService';
import CacheHelper from '@/modules/core/app/helpers/CacheHelper';
import { CurrentThemeTypeCacheKey } from '@/modules/ta/preference/preference.constants';
import {
  ThemeType,
  HIPAA_ACK_REQUIRED,
  TWO_STEP_VERIFICATION_REQUIRED,
} from '@/modules/core/app/constants/app.constants';
import { SessionResponseType, SessionVars } from '@/modules/core/session/session.constants';
import { CustomError } from '@/modules/core/app/models/CustomError';
import NotificationService from '@/modules/core/ui/services/NotificationService';
import { resetI18n } from '@/i18n';

export const actions = {
  async login(
    {},
    {
      email,
      password,
      recaptcha,
      hipaa_checked,
      two_step_verification_code,
      resend_verification_code,
      remember_this_device,
    }
  ) {
    commits.session.isLoggingIn(true);
    try {
      const response = await SessionService.login(
        email,
        password,
        recaptcha,
        hipaa_checked,
        two_step_verification_code,
        resend_verification_code,
        remember_this_device
      );
      if (response.status === 'success') {
        commits.session.setUserSettings(response.data.settings);
        if (getters.session.getUserSettings()) {
          await dispatches.session.updateUserSettings(getters.session.getUserSettings());
        }
      } else if (response.status === SessionResponseType.EXPIRED_PASSWORD) {
        throw new CustomError(
          i18n.$t('Password Expired. Please reset your password'),
          SessionResponseType.EXPIRED_PASSWORD
        );
      } else if (response.status === TWO_STEP_VERIFICATION_REQUIRED) {
        if (response.message) {
          NotificationService.show(response.message);
        }
        const settings = getters.session.getUserSettings();
        settings.isTwoStepVerificationEnabledForUser = true;
        if (response.use_recaptcha) {
          settings.useTwoStepRecaptcha = true;
        }
        commits.session.setUserSettings(settings);
      } else {
        NotificationService.showPersistent(response.data);
        if (response.status === HIPAA_ACK_REQUIRED) {
          const settings = getters.session.getUserSettings();
          settings.needsHipaaAcknowledgement = true;
          commits.session.setUserSettings(settings);
        }
      }
    } catch (e) {
      if (e.message.use_recaptcha) {
        NotificationService.showPersistent(e.message.message);
        throw e;
      } else if (e instanceof CustomError) {
        throw e;
      }
      NotificationService.showPersistent(e.message);
    } finally {
      commits.session.isLoggingIn(false);
      window.localStorage.removeItem(SessionVars.PERSIST_ADVANCE_FILTERS);
    }
  },
  async updateUserSettings({}, currentSettings = null) {
    try {
      if (!currentSettings) {
        const themeType = CacheHelper.get(CurrentThemeTypeCacheKey);
        if (themeType && themeType !== ThemeType.LIGHT) {
          ThemeUIService.updateTheme(null, themeType);
        }
      }
      const { settings } = currentSettings
        ? { settings: currentSettings }
        : await SessionService.getUserSettings();

      const themeType = Env.isProduction()
        ? settings.themeType
        : ThemeUIService.getUserTheme(settings.user?.id) ?? settings.themeType;

      // TODO: @dannyyassine to be removed when merged to master.
      // This simulates when frontend html is delivered to the browser
      //  with already set CSS vars in index.html
      if (settings.currentThemeColor) {
        ThemeUIService.updateTheme(settings.currentThemeColor, themeType);
        CacheHelper.set(CurrentThemeTypeCacheKey, themeType);
      }
      commits.session.setUserSettings(settings);

      // Set the locale based on the latest user settings
      resetI18n(settings.locale);

      if (!Env.isUnitTesting() && getters.session.isLoggedIn()) {
        SegmentService.initialize(settings);
      }
    } catch (e) {
      NotificationService.showPersistent(e.message);
    }
  },

  async impersonateUser({}, userId) {
    try {
      dispatches.list.clearListParamsState();
      const impersonatedUser = await SessionService.impersonateUser(userId);
      commits.session.setUserSettings(impersonatedUser);
    } catch (e) {
      NotificationService.showPersistent(e.message);
    }
  },

  async logout() {
    dispatches.list.clearListParamsState();
    commits.session.isLoggingOut(true);
    commits.session.resetLoginRedirectPath();
    try {
      commits.session.setLoggedOut();
      await SessionService.logout();
    } catch (e) {
      NotificationService.showPersistent(e.message);
    } finally {
      commits.session.isLoggingOut(false);
      window.localStorage.removeItem(SessionVars.PERSIST_ADVANCE_FILTERS);
    }
  },

  setLoginRedirectPath(state, path) {
    commits.session.setLoginRedirectPath(path);
  },

  resetLoginRedirectPath() {
    commits.session.resetLoginRedirectPath();
  },

  setShowWelcomeModal({}, value) {
    commits.session.setShowWelcomeModal(value);
  },
  /**
   * @param {SessionState} state
   * @param mapping
   * @returns {Promise<void>}
   */
  async setBrandMapping({ state }, mapping) {
    if (state.brandMapping) {
      return;
    }
    commits.session.setBrandMapping(mapping ?? (await BrandMappingService.getMapping()));
  },
};
