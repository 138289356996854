import angular from 'angular';

angular.module('core.smartcampaign.constants', [])
    .constant('SmartCampaignRouteParams', {
        STATE_NAME: 'smartcampaigns',
        URL: '/smartcampaigns'
    })
    .constant('$SmartCampaignDetailModalEvents', {
        INIT: '$SmartCampaignDetailModalEvents:INIT'
    })
    .constant('SmartCampaignColumns', {
        ID: 'id',
        NAME: 'name',
        COLOR: 'color',
        ICON: 'icon',
        SOURCE_TYPE: 'source_type',
        DATA_SOURCE: 'data_source',
        DATA_SOURCE_ID: 'data_source_id',
        DYNAMIC_MAPPING: 'dynamic_mapping',
        DATAVIEW: 'dataview',
        DATAVIEW_DISPLAY: 'dataview_display',
    });