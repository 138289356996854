import { BenchmarkLineStyle, DateFormatType, PlotType } from '@/modules/ta/widget/widget.constants';
import { ChartDrawOption } from '@/modules/ta/widget/models/drawoptions/ChartDrawOption';
import { LineTypes } from '@/modules/core/charts/am5/charts.constants';

export class SerialChartDrawOption extends ChartDrawOption {
  /**
   * @var {boolean}
   */
  is_smoothed_line;

  /**
   * @var {boolean}
   */
  has_bullets;

  /**
   * @var {string}
   */
  bullets_shape;

  /**
   * @var {string}
   */
  line_type;

  /**
   * @var {Boolean}
   */
  step_line_risers;

  /**
   * @var {Boolean}
   */
  is_log_scaled;

  /**
   * @var {Boolean}
   */
  has_regression_line;

  /**
   * @var {Boolean}
   */
  has_metric_lines_visible;

  /**
   * @var {Object}
   */
  bullets_per_column;

  /**
   * @var {Object}
   */
  bullets_per_benchmark;

  /**
   * @var {String}
   */
  benchmark_line_style;

  /**
   * @var {Boolean}
   */
  benchmark_bullets;

  /**
   * @var {String}
   */
  font_color_picker;

  constructor(model = {}) {
    super(model);

    this.setValidPlotType(model);

    const defaultBenchmarkLineType = this.defaultToStepLine()
      ? LineTypes.STEP_LINE
      : LineTypes.LINE;

    this.is_rotated = model.is_rotated || false;
    this.has_value_scroller = model.has_value_scroller || false;
    this.show_labels = model.show_labels !== undefined ? model.show_labels : true;
    this.show_metric_labels = model.show_metric_labels || false;
    this.is_y_axis_moved = model.is_y_axis_moved || false;
    this.is_normalized = model.is_normalized || false;
    this.is_zero_scaled = model.is_zero_scaled !== undefined ? model.is_zero_scaled : true;
    this.hide_grid_lines = model.hide_grid_lines !== undefined ? model.hide_grid_lines : true;
    this.show_empty_dates = model.show_empty_dates || false;
    this.date_format_type = model.date_format_type || DateFormatType.DEFAULT;
    this.is_smoothed_line = model.is_smoothed_line || false;
    this.has_bullets = model.has_bullets !== undefined ? model.has_bullets : true;
    this.bullets_shape = model.bullets_shape;
    this.line_type = model.line_type;
    this.step_line_risers = model.step_line_risers;
    this.is_log_scaled = model.is_log_scaled ?? false;
    this.has_regression_line = model.has_regression_line ?? false;
    this.has_metric_lines_visible = model.has_metric_lines_visible;
    this.bullets_per_column = model.bullets_per_column ?? {};
    this.bullets_per_benchmark = model.bullets_per_benchmark ?? {};
    this.benchmark_line_style = model.benchmark_line_style || BenchmarkLineStyle.DASHED;
    this.benchmark_line_type = model.benchmark_line_type || defaultBenchmarkLineType;
    this.benchmark_bullets = model.benchmark_bullets ?? false;
    this.font_color_picker = model.font_color_picker;
  }

  defaultToStepLine() {
    return [
      PlotType.CLUSTERED_V2,
      PlotType.STACKED_V2,
      PlotType.FULL_STACKED_V2,
      PlotType.LAYERED_V2,
      PlotType.LOLLIPOP_V2,
      PlotType.RADIAL_HISTOGRAM_V2,
      PlotType.RADIAL_BAR_V2,
    ].includes(this.plot_type);
  }

  setValidPlotType() {}
}
