'use strict'

import angular from 'angular'

angular.module('core.buysheetorderfield.services', [])
    .factory('BuySheetOrderFieldFactory', BuySheetOrderFieldFactory)

/**
 * @ngInject
 */
function BuySheetOrderFieldFactory(
    Restangular,
    ColumnValueType
) {
    var buySheetOrderFields = Restangular.all('buysheetorderfields')
    var vars = buySheetOrderFields.one('vars')
    var values = buySheetOrderFields.one('values')

    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        return buySheetOrderFields.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @dataSource unused
     * @queryParams any additional query params
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        return values.one(ColumnValueType.DISTINCT).get(
            _.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return buySheetOrderFields.getList(queryParams);
    }

    return {
        ecTasksSummary: buySheetOrderFields,
        vars: vars,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData
    }
}