import angular from 'angular';
import _ from 'lodash';

angular.module('core.smartcampaign.models', [])
    .factory('SmartCampaignModelFactory', SmartCampaignModelFactory);

/**
 * @ngInject
 */
function SmartCampaignModelFactory(
    TapColorsService,
    DataSourceType,
    AppFactory,
    AppModelFactory
) {
    return {
        getSmartCampaignModalState: getSmartCampaignModalState,
        getSmartCampaignModalViewBindings: getSmartCampaignModalViewBindings,
        getSmartCampaignDataSourceSelectOptions: getSmartCampaignDataSourceSelectOptions,
        getSmartCampaignDataViewSelectOptions: getSmartCampaignDataViewSelectOptions,
        getSmartCampaignClientSelectOptions: getSmartCampaignClientSelectOptions,
        getSmartCampaign: getSmartCampaign
    };

    /**
     * @param onChange
     * @constructor
     */
    function SmartCampaignDataSourceSelectOptions(options) {
        options = options || {};
        var self = this;

        self.autoOpen = false;
        self.loaded = false;
        self.closeOnSelect = true;
        self.showAll = true;
        self.onChange = options.onChange;
        self.disabled = options.disabled;
    }

    /**
     * @param options
     * @constructor
     */
    function SmartCampaignDataViewSelectOptions(options) {
        options = options || {};

        options.placeholder = 'Select a Data View...';
        options.multiple = false;
        options.width = '100%';
        options.values = [];
        options.selectedValues = [];

        var self = AppModelFactory.getSelectOptions.call(this, options);

        self.alwaysShow = true;
        self.autoOpen = false;
        self.closeOnSelect = true;

        return self;
    }

    /**
     * @param options
     * @constructor
     */
    function SmartCampaignClientSelectOptions(options) {
        options = options || {};

        options.placeholder = AppFactory.getUser().isSuperAdmin() ? 'Optional: Select a Client...' : 'Select a Client...';
        options.multiple = false;
        options.width = '100%';
        options.values = [];
        options.selectedValues = [];

        var self = AppModelFactory.getSelectOptions.call(this, options);

        self.alwaysShow = true;
        self.autoOpen = false;
        self.closeOnSelect = true;

        return self;
    }

    /**
     * @constructor
     */
    function SmartCampaignModalViewBindings() {
        var self = this;

        self.serviceDataGrid = null;
        self.type = DataSourceType.SERVICE_DATA; // Default to SERVICE_DATA
        self.data_source = {};
        self.dataviewName = 'Data View';
        self.resetSelects = false;

        // Array of campaign names
        self.datagridWidget = null;
    }

    /**
     * @constructor
     */
    function SmartCampaignModalState() {
        var self = this;

        self.isLoading = false;
        self.showDataSource = false;
        self.showDataView = false;
        self.showSearchInput = false;
    }

    /**
     *
     * @constructor
     */
    function SmartCampaign(model) {
        model = model || {};
        var self = this;

        self.id = model.id || null;
        self.name = model.name || null;
        self.color = model.color || TapColorsService.getRandomColor();
        self.icon = model.icon || null;
        self.service_id = model.service_id || null;
        self.customer_id = model.customer_id || null;
        self.dynamic_mapping = _.isUndefined(model.dynamic_mapping) ? true : model.dynamic_mapping;
        self.mapping = _.isUndefined(model.mapping)
            ? []
            : JSON.parse(model.mapping);

        if (model.data_source && model.data_source.id) {
            self.data_source = model.data_source;
        } else if (model.service_id) {
            self.data_source = {id: model.service_id}
        }
        self.primary_name = model.primary_name || 'Data';
        self.dataview = model.dataview || null;
        self.search_pattern = model.search_pattern || '';
        self.source_type = model.source_type || model.data_source && model.data_source.type || DataSourceType.SERVICE_DATA; // Default to SERVICE_DATA

        if (model.id) {
            self.data_source_disabled = !model.can_data_source_be_edited;
            self.data_view_disabled = !model.can_data_view_be_edited;
            self.data_source_tooltip = model.data_source_edition_tooltip;
            self.data_view_tooltip = model.data_view_edition_tooltip;
        } else {
            self.data_source_disabled = false;
            self.data_view_disabled = false;
            self.data_source_tooltip = '';
            self.data_view_tooltip = '';
        }
    }

    /**
     * @returns {SmartCampaignModalState}
     */
    function getSmartCampaignModalState() {
        return new SmartCampaignModalState();
    }

    /**
     * @returns {SmartCampaignModalViewBindings}
     */
    function getSmartCampaignModalViewBindings() {
        return new SmartCampaignModalViewBindings();
    }

    /**
     * @param model
     * @returns {SmartCampaign}
     */
    function getSmartCampaign(model) {
        return new SmartCampaign(model || {})
    }

    /**
     *
     * @param options
     * @returns {SmartCampaignDataSourceSelectOptions}
     */
    function getSmartCampaignDataSourceSelectOptions(options) {
        return new SmartCampaignDataSourceSelectOptions(options);
    }

    /**
     *
     * @param options
     * @returns {SmartCampaignDataViewSelectOptions}
     */
    function getSmartCampaignDataViewSelectOptions(options) {
        return new SmartCampaignDataViewSelectOptions(options);
    }

    /**
     *
     * @param options
     * @returns {SmartCampaignClientSelectOptions}
     */
    function getSmartCampaignClientSelectOptions(options) {
        return new SmartCampaignClientSelectOptions(options);
    }

}