import angular from 'angular';
import {ChatGptSummariesRoute} from "../../../../../../grok/src/modules/ta/chatgptsummaries/chatgptsummaries.constants";

angular.module('exportbuilder.dashboard.services')
    .factory('ExportBuilderMenuService', ExportBuilderMenuService);

/**
 * @ngInject
 */
function ExportBuilderMenuService(
  ExportBuilderDashboardService,
  DateRangeFactory,
  RelativeDateRange,
  MomentDateFormat
) {
  var state = {
    position: {},
  };

  return {
    setPosition: setPosition,
    getPosition: getPosition,
    sendWidgetDataToChatGpt: sendWidgetDataToChatGpt,
  };

  function setPosition(position) {
    state.position = position;
  }

  function getPosition() {
    return state.position;
  }

  function sendWidgetDataToChatGpt(widget) {
    const report = ExportBuilderDashboardService.getBuilder().report;
    const currentPage = ExportBuilderDashboardService.getBuilder().currentPage;
    const reportId = report.id;
    const reportStudioPageId = currentPage.id;
    const range = DateRangeFactory.getDateRangeFromRelativeRange(
      widget.metadata.relative_date_range
    );
    let startDate = range.start.format(MomentDateFormat.ISO);
    let endDate = range.end.format(MomentDateFormat.ISO);

    if (widget.metadata.relative_date_range === RelativeDateRange.CUSTOM) {
      startDate = widget.metadata.start_date_override;
      endDate = widget.metadata.end_date_override;
    }

    const queryParams = {
      reportId: encodeURI(reportId),
      pageId: encodeURI(reportStudioPageId),
      widgetId: encodeURI(widget.id),
      dateRange: `${startDate}|${endDate}`,
      type: encodeURI("report_studio"),
    };

    const params = new URLSearchParams(queryParams);
    window.location.href = `#/${
      ChatGptSummariesRoute.CHATGPTSUMMARIES
    }/?${params.toString()}`;
  }
}