import { v4 as uuid } from 'uuid';
import { Env } from '@/env';
import * as Segment from '@/libs/segment';
import { getters } from '@/modules/core/app/helpers/store';
import { AppModule, Product } from '@/modules/core/app/constants/app.constants';
import { PreferenceLoginMessage } from '@/modules/ta/preference/preference.constants';
import { FeaturePackage, PlatformSource } from '@/modules/core/session/session.constants';

class SegmentService {
  /**
   * @var object
   */
  userSettings;

  /**
   * @var string
   */
  groupId;

  /**
   * @var string
   */
  userId;

  constructor() {
    this.userSettings = {};
  }

  /**
   * @returns {{}}
   */
  getSegmentVars() {
    return { ...this._getIdentifyTraits() };
  }

  /**
   * Visitor metadata
   * @returns {{[p: string]: *}}
   * @private
   */
  _getIdentifyTraits() {
    return {
      id: this.userSettings.id,
      userId: this.userId,
      userType: this.userSettings.user.user_type,
      roleId: this.userSettings.role.id,
      roleName: this.userSettings.role.name,
      rolePredefinedKey: this.userSettings.role.predefined_role_key,
      firstName: this.userSettings.user.first_name,
      lastName: this.userSettings.user.last_name,
      ...this._getNormalizedPermissionSchema(),
      clientName: this.userSettings.clientName,
      useNui: this.userSettings.user.use_nui,
      canAccessNui: this.userSettings.user.can_access_nui,
      enableIoToolModule: this.userSettings.user.enable_io_tool_module,
      legacydashboard: window._tapWalkMe?.page?.id,
      href: window.location.href,
      app: this._getAvailableApps(),
    };
  }

  /**
   * @returns {{[p: string]: *}}
   * @private
   */
  _getTrackProperties() {
    return {
      groupId: this.groupId,
      href: window.location.href,
      app: getters.nav.getActiveNavItemKey(),
    };
  }

  /**
   * Account Metadata
   * @returns {{href: string, traits: *}}
   * @private
   */
  _getGroupTraits() {
    const isInTrial =
      this.userSettings.featurePackageName === FeaturePackage.STANDARD &&
      this.userSettings.platformSource === PlatformSource.TAPREPORTSFREEMIUM
        ? false
        : this.userSettings.isFreeTrial;

    const groupTraitObject = {
      availableModules: this.userSettings.availableModules.join(', '),
      availableFeatures: this.userSettings.availableFeatures.join(', '),
      isHippaInstance: this.userSettings.isHipaaInstance,
      isInTrial,
      featurePackageName: this.userSettings.featurePackageName,
      resellerBilling: this.userSettings.resellerBilling,
      resellerName: this.userSettings.resellerName,
      loginPageCustomization: this._determineLoginMessage(),
      sheetsSelfServePlanId: this.userSettings.sheetsSelfServePlanId,
      platformSource: this.userSettings.platformSource,
    };

    // Add extra properties if call is from ZEST enabled instance
    if (getters.session.getUserSettings()?.isModuleAvailable(AppModule.SEO)) {
      Object.assign(groupTraitObject, {
        app: getters.nav.getActiveNavItemKey(),
        is_internal_instance:
          this.userSettings.isLocalInstance || this.userSettings.isStagingServer,
        is_whitelabel_instance: this.userSettings.shouldHideTapClicksBranding,
        instance_server: this.userSettings.instanceURL,
        // TODO: @hitesh update raven plan related properties
      });
    }

    return groupTraitObject;
  }

  /**
   * @param path
   * @param referrer
   * @returns {{[p: string]: *}}
   * @private
   */
  _getPageProperties(path, referrer) {
    return {
      path,
      url: path,
      title: document.title,
      referrer,
      app: getters.nav.getActiveNavItemKey(),
    };
  }

  /**
   * @private
   *
   * Flattens the role permissions schema
   *
   * @returns {{}}
   */
  _getNormalizedPermissionSchema() {
    try {
      const { role_permissions } = this.userSettings.role;

      return Object.keys(role_permissions).reduce((accum, role_permission_key) => {
        /* eslint-disable no-restricted-syntax */
        for (const key of Object.keys(role_permissions[role_permission_key])) {
          accum[`permission_${role_permission_key}_${key}`] =
            role_permissions[role_permission_key][key];
        }

        return accum;
      }, {});
    } catch (_) {
      return {};
    }
  }

  /**
   * @param {BaseTrackEvent} event
   */
  track(event) {
    if (this._canTriggerSegment()) {
      const mergedProperties = { ...this._getTrackProperties(), ...event.getProperties() };
      window.analytics?.track(event.getName(), mergedProperties);
    }
  }

  /**
   *
   * @param name
   * @param path
   * @param referrer
   * @param properties
   * @param options
   */
  page(name, path, referrer, properties = {}, options = {}) {
    if (this._canTriggerSegment()) {
      const mergedProperties = { ...this._getPageProperties(path, referrer), ...properties };
      window.analytics?.page(name, mergedProperties, options);
    }
  }

  /**
   * @param userSettings
   */
  initialize(userSettings) {
    if (!window.analytics && this._canTriggerSegment()) {
      const segmentApiKey =
        Env.getEnvironmentName(userSettings) === Env.PRODUCTION
          ? process.env.VUE_APP_SEGMENT_API_KEY_PRODUCTION
          : process.env.VUE_APP_SEGMENT_API_KEY_DEVELOPMENT;
      Segment.init(segmentApiKey);
    }
    this.userSettings = userSettings;
    this.identify();
  }

  /**
   * @param traits
   * @param options
   */
  identify(traits, options) {
    if (!this.userSettings.isLoggedIn) {
      const anonymousUserId = this._getAnonymousUserId();
      window.analytics?.identify(anonymousUserId);
    } else if (this._canTriggerSegment()) {
      this.groupId = this.userSettings.instanceURL;
      this.userId = `${this.userSettings.userEmail}${
        this.userSettings.isRemoteLogin ? '-DCP' : ''
      }`;

      const mergedTraits = { ...this._getIdentifyTraits(), ...traits };

      const mergedGroupTraits = { ...this._getGroupTraits(), ...traits };
      window._segmentGroupData = { groupId: this.groupId, mergedGroupTraits };

      window.analytics?.identify(this.userId, mergedTraits, options, () => {
        window.analytics?.group(
          window._segmentGroupData.groupId,
          window._segmentGroupData.mergedGroupTraits
        );
      });
    }
  }

  /**
   * @returns {*}
   * @private
   */
  _getAnonymousUserId() {
    if (!window.localStorage) {
      return uuid();
    }
    let anonymousUserId = window.localStorage.getItem('SegmentAnonymousUserId');
    if (anonymousUserId) {
      return anonymousUserId;
    }
    anonymousUserId = uuid();
    try {
      window.localStorage.setItem('SegmentAnonymousUserId', anonymousUserId);
    } catch (e) {
      Logger.error(i18n.$t('Error while storing segment anonymous id in local storage'), e);
    }
    return anonymousUserId;
  }

  /**
   * @returns {string}
   * @private
   */
  _getAvailableApps() {
    const apps = [Product.RIPLEY];
    if (getters.session.getUserSettings()?.isModuleAvailable(AppModule.SEO)) {
      apps.push(Product.ZEST);
    }
    return apps.join(',');
  }

  /**
   * @returns {boolean}
   * @private
   */
  _canTriggerSegment() {
    return !getters.session.getUserSettings()?.isImpersonationMode;
  }

  /**
   *
   * @returns {string}
   * @private
   */
  _determineLoginMessage() {
    const loginPageMessage = this.userSettings.nuiLoginPageMessage;
    let isCustomized = 'customized_empty';

    if (loginPageMessage) {
      if (loginPageMessage === PreferenceLoginMessage.DEFAULT_LOGIN_MESSAGE) {
        isCustomized = 'not_customized';
      } else if (loginPageMessage.includes('<figure class="media')) {
        isCustomized = 'customized_with_video';
      } else if (loginPageMessage.includes('<figure class="image')) {
        isCustomized = 'customized_with_image';
      } else {
        isCustomized = 'customized_with_text';
      }
    }
    return isCustomized;
  }
}

export default new SegmentService();
