'use strict';
import angular from 'angular';
import './widget.serialchart.ctrls';
import './widget.serialchart.directives';
import './widget.serialchart.services';

angular.module('widget.serialchart', [
    'widget.serialchart.ctrls',
    'widget.serialchart.directives',
    'widget.serialchart.services',
]);


