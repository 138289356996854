import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { BadgeState } from './badge.state';
import { getters } from './badge.getters';
import { mutations } from './badge.mutations';
import { actions } from './badge.actions';

VueXHelper.setStoreModule(Module.BADGE, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new BadgeState(),
  getters,
  mutations,
  actions,
};
