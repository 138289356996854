export class DownloadUtil {
  /**
   * Download a URL in browser
   * @param responseUrl
   * @param fileName
   */
  static download(responseUrl, fileName) {
    const link = document.createElement('a');
    link.href = responseUrl;
    if (fileName) {
      link.setAttribute('download', fileName);
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
