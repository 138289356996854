import CustomTranslationResource from '@/modules/core/customtranslation/resources/CustomTranslationResource';
import { BaseFormService } from '@/modules/core/form/services/BaseFormService';

export class CustomTranslationFormService extends BaseFormService {
  constructor() {
    super(CustomTranslationResource);
  }
}

export default new CustomTranslationFormService();
