import angular from 'angular';

angular.module('namingconventionrule.resources', [])
    .factory('NamingConventionsRuleResource', NamingConventionsRuleResource);

/**
 * @ngInject
 */
function NamingConventionsRuleResource(
    Restangular
) {
    var namingConventionRules = Restangular.all('namingconventions');

    return {
        getNamingConvention: getNamingConvention,
        getRuleItems: getRuleItems,
        saveCustomDelimiter: saveCustomDelimiter,
        deleteCustomDelimiter: deleteCustomDelimiter,
        create: create,
        update: update
    };

    function getNamingConvention(namingConventionId) {
        return namingConventionRules.one(namingConventionId).get().then(function (json) {
            return json.plain()
        })
    }

    /**
     * Retrieve
     * @param namingConventionId
     */
    function getRuleItems(namingConventionId) {
        var promise = namingConventionRules;
        if (namingConventionId) {
            promise = namingConventionRules.one(namingConventionId)
        }
        return promise.all('ruleitems').getList().then(function (json) {
            return json.plain()
        })
    }

    function create(namingConvention) {
        var model = {
            name: namingConvention.name,
            preview: namingConvention.preview,
            rule: JSON.stringify(namingConvention.getRule())
        };
        return namingConventionRules.post(model).then(function (json) {
            return json.plain();
        })
    }

    function update(namingConvention) {
        var model = {
            id: namingConvention.id,
            name: namingConvention.name,
            preview: namingConvention.preview,
            rule: JSON.stringify(namingConvention.getRule())
        };
        return namingConventionRules.all(model.id).post(model).then(function (json) {
            return json.plain();
        })
    }

    function saveCustomDelimiter(customDelimiter) {
        return namingConventionRules.all('custom').post(customDelimiter).then(function (json) {
            return json.plain();
        });
    }

    function deleteCustomDelimiter(customDelimiter) {
        return namingConventionRules.all('custom').one(customDelimiter.id).remove().then(function (json) {
            return json.plain();
        });
    }
}