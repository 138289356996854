import { FontStyle } from '@/modules/core/charts/chart.constants';

/**
 * @class Axis
 */
export class Axis {
  /**
   * Key holding categories name in data
   * @var {String}
   */
  category;

  /**
   * Indicates if the scale of the axis should be flipped.
   * @var {Boolean}
   */
  inversed;

  /**
   * Indicates whether Axis should be drawn on the opposite side of the plot area
   * @var {Boolean}
   */
  opposite;

  /**
   * Should be set true if data is about duration
   * @var {Boolean}
   */
  isValueDuration;

  /**
   * Should be set true if data is about date
   * @var {Boolean}
   */
  isValueDate;

  /**
   * Predefined start position for chart
   * @var {Number}
   */
  min;

  /**
   * Predefined end position for chart
   * @var {Number}
   */
  max;

  /**
   * If to follow min max irrespective of data passed
   * @var {boolean}
   */
  strictMinMax;

  /**
   * If tooltip is enabled
   * @var {Boolean}
   */
  tooltipEnabled;

  /**
   * A template to be used to populate Tooltip contents.
   * @var {String}
   */
  tooltipText;

  /**
   * An HTML template to be used to populate Tooltip contents.
   * @var {String}
   */
  tooltipHTML;

  /**
   * If should show axis labels
   * @var {Boolean}
   */
  showLabels;

  /**
   * If should show grid lines
   * @var {Boolean}
   */
  hideGridLines;

  /**
   * Format in which date should be shown, valid when isValueDate is set true
   * @var {Boolean}
   */
  dateFormat;

  /**
   * Amount of ration for label
   * @var {String}
   */
  labelRotation;

  /**
   * font size for the label
   * @var {Number}
   */
  labelFontSize;

  /**
   * Set it random number greater than max to show only first and last labels on Gauge Chart
   * @var {Number}
   */
  minGridDistance;

  /**
   * Max value as defined by user's code, not auto-calculated.
   * @var {Number}
   */
  maxDefined;

  /**
   * Used to customize the axis label
   * @var {Function}
   */
  labelAdapter;

  /**
   * Indicates if text lines need to be truncated if they do not fit,
   * using configurable ellipsis string.
   *
   * @var {Boolean}
   */
  labelTruncate;

  /**
   * Maximum allowed width for the element in pixels.
   * when enableResponsiveAutoRotate is set to true,
   * @default 120
   *
   * @var {Number}
   */
  labelMaxWidth;

  /**
   * A text template to be used to populate Tooltip's contents.
   *
   * @var {String}
   */
  labelToolTipText;

  /**
   * When set to true, it rotates labels by 'responsiveAutoRotateAngle' degrees
   * when they do not fit in available space(labelMaxWidth)
   * labelMaxWidth if not provided it is defaulted to 120px.
   *
   * @var {Boolean}
   */
  enableResponsiveAutoRotate;

  /**
   * By how much angle the labels are rotated.
   * when enableResponsiveAutoRotate is set to true,
   * @default -45
   *
   * @var {Number}
   */
  responsiveAutoRotateAngle;

  /**
   * An SVG text.
   * https://www.amcharts.com/docs/v4/reference/label/#text_property
   *
   * @var {String}
   */
  titleText;

  /**
   * Color for the visible title
   * https://www.amcharts.com/docs/v4/reference/label/#fill_property
   *
   * @var {String}
   */
  titleColor;

  /**
   * Font weight of the title
   * https://www.amcharts.com/docs/v4/reference/fontweight/
   * @default 'bold'
   *
   * @var {String}
   */
  titleFontWeight;

  /**
   * Axis line element's stroke (outline) color or pattern.
   *
   * @var {String}
   */
  axisLineStroke;

  /**
   * Stroke (outline) thickness in pixels.
   *
   * @var {Number}
   */
  axisLineStrokeWidth;

  /**
   *  Stroke (outline) opacity.
   *  The values may range from 0 (fully transparent) to 1 (fully opaque).
   *
   *  @var {Number}
   */
  axisLineStrokeOpacity;

  /**
   *  Extra max value to make sure chart doesn't touch the top edge
   *  @var {Number}
   *  @default 0
   */
  extraMax;

  /**
   * If labels on axis are disabled
   * @var {boolean}
   */
  labelDisabled;

  /**
   * If axis has breaks
   * @var {boolean}
   */
  hasAxisBreaks;

  /**
   * Predefined axis breaks array
   * @var {Array}
   */
  axisBreaks;

  /**
   * axisRanges for the axis
   * @var {Array}
   */
  axisRanges;

  /**
   * If axis has ticks
   * @var {boolean}
   */
  displayTicks;

  /**
   * Color for the tick
   * @var {String}
   */
  tickColor;

  /**
   * Color for the label
   * @var {String}
   */
  labelColor;

  constructor(model = {}) {
    const { responsiveAutoRotateAngle = -45, labelMaxWidth = 120, extraMax = 0 } = model;
    this.category = model.category;
    this.inversed = model.inversed;
    this.opposite = model.opposite;
    this.isValueDate = model.isValueDate;
    this.isValueDuration = model.isValueDuration;
    this.tooltipEnabled = model.tooltipEnabled;
    this.tooltipText = model.tooltipText;
    this.tooltipHTML = model.tooltipHTML;
    this.min = model.min;
    this.max = model.max;
    this.strictMinMax = model.strictMinMax;
    this.showLabels = model.showLabels;
    this.hideGridLines = model.hideGridLines;
    this.dateFormat = model.dateFormat;
    this.labelRotation = model.labelRotation;
    this.labelFontSize = model.labelFontSize;
    this.minGridDistance = model.minGridDistance;
    this.maxDefined = model.maxDefined;
    this.labelAdapter = model.labelAdapter;
    this.labelTruncate = model.labelTruncate;
    this.labelMaxWidth = model.enableResponsiveAutoRotate ? labelMaxWidth : model.labelMaxWidth;
    this.labelToolTipText = model.labelToolTipText;
    this.enableResponsiveAutoRotate = model.enableResponsiveAutoRotate;
    this.responsiveAutoRotateAngle = responsiveAutoRotateAngle;
    this.titleText = model.titleText;
    this.titleColor = model.titleColor;
    this.titleFontWeight = model.titleFontWeight ?? FontStyle.BOLD;
    this.axisLineStroke = model.axisLineStroke;
    this.axisLineStrokeWidth = model.axisLineStrokeWidth;
    this.axisLineStrokeOpacity = model.axisLineStrokeOpacity;
    this.extraMax = extraMax;
    this.labelDisabled = model.labelDisabled;
    this.hasAxisBreaks = model.hasAxisBreaks;
    this.axisBreaks = model.axisBreaks;
    this.axisRanges = model.axisRanges;
    this.displayTicks = model.displayTicks;
    this.tickColor = model.tickColor;
    this.labelColor = model.labelColor;
  }
}
