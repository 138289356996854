'use strict';
import angular from 'angular';
import './base/widget.option.ctrls';
import './base/widget.option.directives';
import './base/widget.option.services';

angular.module('widget.option', [
    'widget.option.ctrls',
    'widget.option.directives',
    'widget.option.services',
]);