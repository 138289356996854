'use strict';
import angular from 'angular';
import './scripts/servicedetails.ctrls';
import './scripts/servicedetails.directives';
import './scripts/servicedetails.services';

angular.module('servicedetails', [
    'servicedetails.ctrls',
    'servicedetails.directives',
    'servicedetails.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        var dashfilters = {
            templateUrl: AppConfig.MAGE_URL + 'layout/dashfilters',
            controller: 'DashFiltersController'
        };

        $stateProvider
            .state('service', {
                parent:'layout',
                url: '/service/details/:id',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'index/details/service/' + $stateParams.id;
                        }
                    },
                    'dashfilters@layout': dashfilters
                },
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('service')
                }
            })
            .state('service.cgn', {
                parent:'layout',
                url: '/service/cgn/:id',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'index/details/service/' + $stateParams.id + '/force_campaigns/true';
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })
            .state('service.steprep', {
                parent:'layout',
                url: '/index/details/service/steprep/itemkey/:itemkey',
                controller: 'ServiceDetailController',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'serviceDetails/steprep/itemkey/' + $stateParams.itemkey;
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })

            .state('service.metrics', {
                parent:'layout',
                url: '/service/details/:service_id/customer_id/:customer_id',
                controller: 'ServiceDetailController',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'metricgrid/view/service/' + $stateParams.service_id + '/customer_id/' + $stateParams.customer_id;
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })
            .state('service.metrics.score', {
                parent:'layout',
                url: '/service/details/:service_id/customer_id/:customer_id/score_level/:score_level',
                controller: 'ServiceDetailController',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'metricgrid/view/service/' + $stateParams.service_id + '/customer_id/' + $stateParams.customer_id + '/score_level/' + $stateParams.score_level;
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })
            .state('service.submetrics', {
                parent:'layout',
                url: '/service/details/:service_id/:data_view/:reference_id',
                data: {menu: ':service_id'},
                controller: 'ServiceDetailController',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'submetricgrid/view/service/' + $stateParams.service_id + '/data_view/'  + $stateParams.data_view + '/reference_id/' + $stateParams.reference_id + '/';
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })
            .state('service.submetrics.filter', {
                parent:'layout',
                url: '/service/details/:service_id/:data_view/:reference_id/breakdown_by/:filters',
                controller: 'ServiceDetailController',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'submetricgrid/view/service/' + $stateParams.service_id + '/data_view/'  + $stateParams.data_view + '/reference_id/' + $stateParams.reference_id + '/?from_breakdown=yes' + $stateParams.filters;
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })
    }]);