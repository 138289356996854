import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';

export class DashboardFilterItem extends BaseModel {
  /**
   * @var {string}
   */
  field;

  /**
   * @var {string}
   */
  format;

  /**
   * @var {string}
   */
  label;

  /**
   * @var {string}
   */
  association;

  /**
   * @var {string}
   */
  type;

  /**
   * @var {array}
   */
  values;

  constructor(model = {}) {
    super();

    this.field = model.field || 'id';
    this.format = model.format || 'id';
    this.label = model.label || null;
    this.association = model.association || 'and';
    this.type = model.type || 'in';
    this.values = model.values || [];
  }

  /**
   * @returns {boolean}
   */
  isClient() {
    return this.label === DataSourceType.CLIENT;
  }

  /**
   * @returns {boolean}
   */
  isClientGroup() {
    return this.label === DataSourceType.CLIENT_GROUP;
  }

  /**
   * @returns {boolean}
   */
  isCluster() {
    return this.label === DataSourceType.CLUSTER;
  }

  /**
   * @returns {boolean}
   */
  isSmartCampaign() {
    return this.label === DataSourceType.SMART_CAMPAIGN;
  }
}
