export class NavState {
  /**
   * @var {AppNavItem[]}
   */
  appNavItems;

  /**
   * @var {boolean}
   */
  currentRouteNeedsExactMatch = false;

  /**
   *
   * @type {Object}
   */
  allSubItems = {};

  /**
   * @type {{}}
   */
  contextNavOptions = {};

  /**
   * Context Nav
   *
   * @var {boolean}
   */
  showFilterButton = false;

  /**
   * @type {Object}
   */
  filterContextNavOptions = {};

  /**
   * @type {?string}
   */
  currentAppNavItemKey = null;

  /**
   * Context Nav
   *
   * @var {boolean}
   */
  showCommentButton = false;

  /**
   * Context Nav
   *
   * @var {boolean}
   */
  showActivityLogButton = false;

  /**
   * Context Nav
   *
   * @var {array}
   */
  showFileButton = [];

  /**
   * Context Nav
   *
   * @type {object}
   */
  isShowingFilesModal = {};

  /**
   * @type {?string}
   */
  isShowingActivityOrCommentsOrFilePanel = null;
}
