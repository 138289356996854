import { UserSettings } from '@/modules/core/app/models/UserSettings';
import { SessionVars } from '@/modules/core/session/session.constants';

export const mutations = {
  /**
   * @param {SessionState} state
   * @param {{}} userSettings
   */
  setUserSettings(state, userSettings) {
    state.userSettings = new UserSettings(userSettings);
  },
  /**
   * @param {SessionState} state
   * @param {boolean} value
   */
  isLoggingIn(state, value) {
    state.status.isLoggingIn = value;
  },
  /**
   * @param {SessionState} state
   * @param {boolean} value
   */
  isLoggingOut(state, value) {
    state.status.isLoggingOut = value;
  },
  /**
   * @param {SessionState} state
   */
  setLoggedOut(state) {
    state.userSettings.isLoggedIn = false;
  },
  /**
   * @param {SessionState} state
   * @param {boolean} value
   */
  setLoginRedirectPath(state, value) {
    state.loginRedirectPath = value;
    window.localStorage.setItem(SessionVars.LOGIN_REDIRECT_PATH, value);
  },
  /**
   * @param {SessionState} state
   */
  resetLoginRedirectPath(state) {
    state.loginRedirectPath = null;
    window.localStorage.removeItem(SessionVars.LOGIN_REDIRECT_PATH);
  },

  /**
   * @param {SessionState} state
   * @param {boolean} value
   */
  setShowWelcomeModal(state, value) {
    state.showWelcomeModal = value;
  },
  /**
   * @param {SessionState} state
   * @param {BrandMapping} mapping
   */
  setBrandMapping(state, mapping) {
    state.brandMapping = mapping;
  },

  /**
   *
   * @param state
   * @param value
   */
  setClientCurrency(state, value) {
    state.clientCurrency = value;
  },
};
