export const getters = {
  getBillingDetails(state) {
    return state.billingDetails;
  },
  getPeriod(state) {
    return state.billingPeriodUnit;
  },
  getCurrentProduct(state) {
    return state.currentProduct;
  },
  getOverviewDetails(state) {
    return state.overviewDetails;
  },
  getCurrentSelectedPlan(state) {
    return state.currentSelectedPlan;
  },
  getCurrentSelectedAddons(state) {
    return state.selectedAddons;
  },
};
