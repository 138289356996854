'use strict';
import angular from 'angular';
import './annotation.components';
import './annotation.services';
import './annotation.models';
import './annotation.constants';
import './annotation.resources';

angular.module('core.annotation', [
    'annotation.components',
    'annotation.services',
    'annotation.models',
    'annotation.constants',
    'annotation.resources'
]);