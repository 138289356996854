'use strict';
import angular from 'angular';
import $ from 'jquery';
import './layout.library.constants';
import './layout.library.services';
import './layout.library.resources';
import './layout.library.components';
import './tags/layout.tag.services';
import './tags/layout.tag.resources';
import manageLayoutLibraryHtmlUrl from './managelayoutlibrary.html';
import manageLayoutTagsHtmlUrl from './tags/managelayouttags.html';

angular.module('layout.library', [
    'layout.library.constants',
    'layout.library.services',
    'layout.library.resources',
    'layout.library.components',
    'layout.tag.services',
    'layout.tag.resources'
])
    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {

        $stateProvider.state('managelayoutlibrary', {
            parent:'layout',
            url: '/managelayoutlibrary',
            resolve: {
                isTARoute: SwitchAppServiceProvider.switchAppFn('managelayoutlibrary'),
                pageType: function(PageType) {
                    return PageType.TYPE_ADMIN;
                },
                pageEntity: function(PageEntity) {
                    return PageEntity.PAGE_MANAGE_LAYOUT_LIBRARY;
                },
                vars: function() {
                    return null;
                }
            },
            views: {
                '': {
                    templateUrl: manageLayoutLibraryHtmlUrl,
                    controller: 'DesignController'
                }
            }
        })
            .state('managelayouttags', {
                parent:'layout',
                url: '/managelayouttags',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('managelayouttags'),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_MANAGE_LAYOUT_TAGS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: manageLayoutTagsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);