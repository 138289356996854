'use strict';
import angular from 'angular';
import widgetImageUrl from 'coreModules/shared/images/widget.png';
import _ from "lodash";


angular.module('layout.library.resources', [])

    .factory('LayoutLibraryResourceFactory', LayoutLibraryResourceFactory)
    .factory('LayoutLibraryDataGridFactory', LayoutLibraryDataGridFactory);

/**
 *
 * @ngInject
 */
function LayoutLibraryResourceFactory(
    $q,
    Restangular,
    LayoutTagFactory
) {
    var dash = Restangular.all('dash');
    var layouts = dash.all('layouts');
    var resource = layouts.all('library');

    return {
        getData: getData,
        getLayoutsForDisplay: getLayoutsForDisplay,
        getSearchableValues: getSearchableValues,
        useLayoutAsTemplate: useLayoutAsTemplate,
        incrementDownloadCount: incrementDownloadCount,
        publish: publish
    };

    /**
     * Get data for this resource
     */
    function getData(queryParams) {
        queryParams = _.assign({
            all: true
        }, queryParams);
        return resource.getList(queryParams).then(function (json) {
            return json.plain();
        });
    }

    /**
     * @param queryParams
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function getLayoutsForDisplay(queryParams) {
        return resource.all('display').getList(queryParams).then(function (json) {
            return json.plain();
        });
    }

    /**
     * @returns {*}
     */
    function getSearchableValues() {
        var deferred = $q.defer();

        var promises = [
            LayoutTagFactory.getTags(),
            _getDataSourcesInUse()
        ];

        $q.all(promises).then(function (data) {
            deferred.resolve({
                tags: data[0],
                dataSources: data[1]
            });
        });

        return deferred.promise;
    }

    /**
     * @param layout
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function useLayoutAsTemplate(layout) {
        return resource.all('template').post(layout).then(function(newLayout) {
            return newLayout.plain();
        });
    }

    /**
     * @param id
     * @returns {*}
     */
    function incrementDownloadCount(id) {
        return resource.one('incrementdownload', id).get({ignoreLoadingBar: true});
    }

    /**
     * @param model
     */
    function publish(model) {
        return resource.all('publish').post(model);
    }

    /**
     * Returns promise for resolving data sources used across all widgets in library layouts
     *
     * @returns {PromiseLike<T> | Promise<T> | *}
     * @private
     */
    function _getDataSourcesInUse() {
        var queryParams = {
            all: true,
            ignoreLoadingBar: true
        };
        return resource.all('datasources').getList(queryParams).then(function (json) {
            return json.plain();
        });
    }
}

/**
 * @ngInject
 */
function LayoutLibraryDataGridFactory(
    DataGridRender,
    DesignFactory,
    LayoutLibraryColumns,
    DataSourceFactory,
    DataSourceType
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return manage widgets datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};

        dtOptions.numActions = 1;
        dtOptions.customRenders[LayoutLibraryColumns.ID] = function (data, type, full) {
            var html = '';
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'dash/layouts/' + data, full['title']);
            return html;
        };

        dtOptions.customRenders[LayoutLibraryColumns.THUMBNAIL_METADATA] = function (data, type, full) {
            var url = !_.isEmpty(data) ? data.secure_url : widgetImageUrl;
            return '<div class="dash-thumbnail-container"><div class="inner"><img src="' + url + '" /></div></div>'
        };

        dtOptions.customRenders[LayoutLibraryColumns.TAGS] = function (data) {
            var html = '';
            _.each(data, function(item) {
                html += '<span class="label label-default mr5">' + item + ' </span>';
            });
            return html;
        };

        dtOptions.customRenders[LayoutLibraryColumns.CREATED_AT] = function (data, type, full) {
            return full[LayoutLibraryColumns.FORMATTED_CREATED_AT];
        };

        dtOptions.customRenders[LayoutLibraryColumns.DATA_SOURCES] = function(
            data,
            type,
            full
        ) {
            var result = "";
            var data_sources = [];

            data = _.uniqWith(
                data,
                dataSource => `${dataSource.data_source_id}-${dataSource.data_type}`
            );

            _.each(data, function(data_source) {
                if (
                    data_source.data_type === DataSourceType.SERVICE_DATA ||
                    data_source.data_type === DataSourceType.CATEGORY_DATA
                ) {
                    data_sources.push(
                        DataSourceFactory.getDataSourceDetails(
                            data_source.data_type,
                            data_source.data_source_id
                        )
                    );
                }
            });

            // build the list of data sources
            _.each(data_sources, function(data_source) {
                if (data_source) {
                    var className = "";
                    var title = data_source.name;
                    var color = data_source.color;
                    var id = data_source.id;
                    if (data_source.is_connected) {
                        if (data_source.type === "service_data") {
                            result += `<a href="#/service/details/${id}" class="no-link-formatting">`;
                        } else {
                            result += `<a href="#/category/details/${id}" class="no-link-formatting">`;
                        }
                    } else {
                        className = "disconnected";
                        title += " (not connected)";
                    }

                    const icon =
                        data_source.has_custom_icon ||
                        data_source.type === DataSourceType.CATEGORY_DATA
                            ? data_source.icon
                            : "serviceicon-" + data_source.icon;

                    result += `<div class="has-tooltip service-square service-square-32 
                         ${className}" title="${title}" style="background-color: ${color}
                         ">
                       <div class="icon ${icon}
                        "></div>
                        </div>`;

                    if (data_source.is_connected) {
                        result += "</a>";
                    }
                }
            });
            return result;
        };



        // column widths
        dtOptions.columnWidths = {};
        dtOptions.columnWidths[LayoutLibraryColumns.LAYOUTS] = 110;

        return dtOptions;
    }
}