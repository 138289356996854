/**
 * @class CircleBullet
 */
export class CircleBullet {
  /**
   * Element's fill color or pattern.
   *
   * @var {String}
   */
  fill;

  /**
   * Element's stroke (outline) color or pattern.
   *
   * @var {String}
   */
  stroke;

  /**
   * Stroke (outline) thickness in pixels.
   *
   * @var {Number}
   */
  strokeWidth;

  /**
   * Scale of the element.
   * The scale is set from 0 (element reduced to nothing) to 1 (default size).
   * 2 will mean element is increased twice.
   * 0.5 - reduced by 50%.
   *
   * @var {Number}
   */
  scale;

  /**
   * Control opacity of background color
   * @var {String}
   */
  fillOpacity;

  constructor(model = {}) {
    this.fill = model.fill;
    this.stroke = model.stroke;
    this.strokeWidth = model.strokeWidth;
    this.scale = model.scale;
    this.fillOpacity = model.fillOpacity;
  }
}
