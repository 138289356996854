import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { DevToolsState } from './devtools.state';
import { getters } from './devtools.getters';
import { mutations } from './devtools.mutations';
import { actions } from './devtools.actions';

VueXHelper.setStoreModule(Module.DEVTOOLS, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new DevToolsState(),
  getters,
  mutations,
  actions,
};
