'use strict';
import angular from 'angular';

angular.module('design.page.constants', [])

    .constant('PageColumns', {
        ID: 'id',
        TITLE: 'title',
        REPORTING_PROFILES: 'reporting_profiles',
        IS_TEMPLATE: 'is_template',
        CLUSTER: 'cluster'
    })
    .constant('PageCopyOptions', {
        OPTION_COPY_ALERTS: 'copy_alerts',
        OPTION_COPY_ANNOTATIONS: 'copy_annotations',
        OPTION_COPY_EXEC_SUMMARIES: 'copy_exec_summaries'
    })
    .constant('$PageGenerateThumbnailEvents', {
        ENQUEUE: '$PageGenerateThumbnailEvents:ENQUEUE'
    })
    .constant('$PageNuiEvents', {
        UPDATE_DASHBOARD_NAV_ITEM: '$PageNuiEvents:UPDATE_DASHBOARD_NAV_ITEM'
    })
    .constant('ViewAuditLogsEvent', {
        VIEW_DASHBOARD_SECTION_AUDIT_LOGS: 'view_dashboard_section_audit_logs'
    });