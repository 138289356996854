import { NumberFormatter } from '@amcharts/amcharts5';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';

export function useChart(context) {
  const { root, chart, config } = context();

  function configureChart() {
    chart.value.setAll(
      omitNils({
        numberFormatter: NumberFormatter.new(root.value, { numberFormat: '#.##a' }),
        cursorOverStyle: config.value.cursorOverStyle,
      })
    );

    if (config.value.isSparkLine) {
      chart.value.setAll({
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      });
    }
  }

  function disposeChart() {
    chart.value?.dispose();
  }

  return {
    configureChart,
    disposeChart,
  };
}
