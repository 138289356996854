'use strict';
import angular from 'angular';

angular.module('dashboardfilteritem.services', [])

    .factory('DashboardFilterPanelItemFactory', DashboardFilterPanelItemFactory);

/**
 * @ngInject
 */
function DashboardFilterPanelItemFactory() {
    /**
     * Exposed view model object for controller
     * @type {{}}
     */
    var shared = {
    };

    return {
        shared: shared
    };
}