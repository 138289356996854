import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class DataSourceType extends BaseModel {
  /**
   * @var {string}
   */
  type;

  /**
   * @var {number}
   */
  id;

  /**
   * @var {number}
   */
  data_view;

  /**
   * @var {string}
   */
  data_view_name;

  /**
   * @var {}
   */
  name;

  /**
   * @var {boolean}
   */
  requires_group_by;

  /**
   * @var {boolean}
   */
  requires_date_range;

  /**
   * @var {boolean}
   */
  is_of_type_service;

  /**
   * @var {string}
   */
  icon;

  /**
   * @var {string}
   */
  color;

  /**
   * @var {string}
   */
  has_custom_icon;

  /**
   * @var {boolean}
   */
  is_custom_service;

  /**
   * @var {boolean}
   */
  include_num_entities;

  /**
   * @var {boolean}
   */
  include_data_freshness_date;

  /**
   * @var {string}
   */
  primary_date_field;

  constructor(model) {
    super();
    this.type = model.type;
    this.id = model.id;
    this.data_view = model.data_view;
    this.data_view_name = model.data_view_name;
    this.name = model.name;
    this.requires_group_by = model.requires_group_by;
    this.requires_date_range = model.requires_date_range;
    this.is_of_type_service = model.is_of_type_service;
    this.icon = model.icon;
    this.color = model.color;
    this.primary_date_field = model.primary_date_field;
  }

  /**
   * @returns {string}
   */
  getIcon() {
    return this.icon;
  }

  /**
   * @returns {string}
   */
  getColor() {
    return this.color;
  }
}
