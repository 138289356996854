'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.media.services', [])

    .factory('MediaWidgetFactory', MediaWidgetFactory);

/**
 * @ngInject
 */
function MediaWidgetFactory(
    WidgetFactory,
    WidgetDataSourceFactory
) {

    return {
        getData: getData
    };

    /**
     * Returns data/content for media widgets
     * @param metadata
     * @param params
     * @returns {{}}
     */
    function getData(widget, params) {
        if (WidgetFactory.useSampleData(widget.metadata) && _.isNil(widget.metadata.content)) {
            return WidgetDataSourceFactory.getData(widget, params); // we need the content first if we dont have it.
        }

        return WidgetFactory.useSampleData(widget.metadata)
            ? widget.metadata.content // We already have the content when sampling
            : WidgetDataSourceFactory.getData(widget, params);
    }
}
