'use strict';

angular.module('widget.tag.services', [])
    .factory('WidgetTagFactory', WidgetTagFactory);

/**
 * @ngInject
 */
function WidgetTagFactory(
    WidgetTagsResource
) {
    return {
        getTags: getTags,
        saveTags: saveTags
    };

    /**
     * Return list of tags available to widgets
     * @returns {*}
     */
    function getTags() {
        return WidgetTagsResource.getData({sort: 'text', all: false});
    }

    /**
     * @param tags
     * @returns {Promise<any>|Array}
     */
    function saveTags(tags) {
        return WidgetTagsResource.save(tags);
    }
}