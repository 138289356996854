'use strict';
import angular from 'angular';
import _ from 'lodash';

import userImageModalHtmlUrl from './user.image.modal.html';
import userAvatarBubbleHtmlUrl from './useravatarbubble.html';

angular.module('user.image.directives', [])
    .component('userImageUploadModal', {
        templateUrl: userImageModalHtmlUrl,
        bindings: false,
        controllerAs: 'vm',
        controller: UserImageUploadModalController
    })
    .component('userAvatarBubble', {
        templateUrl: userAvatarBubbleHtmlUrl,
        bindings: {
            user: '<',
            imageMetadata: '<'
        },
        controllerAs: 'vm',
        controller: UserAvatarBubbleController
    });

/**
 * @ngInject
 */
function UserImageUploadModalController(
    $timeout,
    $scope,
    AppFactory,
    UserFactory,
    UIFactory,
    PubSub,
    $UserImageUploadEvents
) {
    var vm = this;
    var currentUser = AppFactory.getUser();
    vm.upload = upload;
    vm.setFile = setFile;
    vm.uploadUrl = UserFactory.getUploadUrl();
    vm.deleteImage = deleteImage;
    vm.clearFileInput = false;

    init();

    function init() {
        _setImage(currentUser);
        vm.isUploading = false;
    }

    function setFile(event) {
        var file = _.first(event.currentTarget.files);
        var reader = new FileReader();
        reader.onload = function (event) {
            $scope.$evalAsync(function(){
                vm.userImage = event.target.result;
            });
        };
        reader.readAsDataURL(file);
    }

    // NOTE: input must be watched in order to bind image file
    angular.element(document.querySelector('#user-image-input')).on('change',setFile);



    function upload() {
        vm.isUploading = true;
        var uploadFile = AppFactory.dataURItoBlob(vm.croppedImage);
        UserFactory.uploadImage(uploadFile, uploadSuccess, uploadError);
    }

    function deleteImage() {
        vm.isUploading = true;
        UserFactory.deleteImage(vm.imageId).then(function(json) {
            vm.isUploading = false;
            if (!json.error) {
                $scope.$evalAsync(function() {
                    UIFactory.hideModal('image-upload-modal');
                    UIFactory.notify.showSuccess('Image Successfuly Deleted');
                    vm.imageId = '';
                    vm.croppedImage  = '';
                    updateUserImage(null, null);

                    // clear input
                    vm.clearFileInput = true;
                    // clear image variables
                    _setImage(AppFactory.getUser());
                    PubSub.emit($UserImageUploadEvents.IMAGE_CHANGE, AppFactory.getUser());
                });
            }
        })
    }

    function uploadSuccess(data) {
        vm.isUploading = false;

        // Close modal
        UIFactory.hideModal('image-upload-modal');
        // Show success
        UIFactory.notify.showSuccess('Image Successfuly Uploaded');

        $scope.$evalAsync(function() {
            updateUserImage(data.id, vm.croppedImage);
            PubSub.emit($UserImageUploadEvents.IMAGE_CHANGE, AppFactory.getUser());
        });
    }

    function uploadError(err) {
        vm.isUploading = false;

        UIFactory.notify.showError('Image Upload Failure: ' + err);
    }

    function updateUserImage(imageId, image) {

        vm.imageId = imageId;
        currentUser.userImageId = imageId;
        if (currentUser.userImageMetadata) {
            currentUser.userImageMetadata.secure_url = image;
        } else {
            currentUser.userImageMetadata = {
                secure_url: image
            }
        }

        AppFactory.setUser(currentUser);

        // Update root user object to keep dashleftpanel in sync
        $scope.$root.user.userImageId = imageId;
        if ($scope.$root.user.userImageMetadata) {
            $scope.$root.user.userImageMetadata.secure_url = image;
        } else {
            $scope.$root.user.userImageMetadata = {
                secure_url: image
            }
        }
    }

    /**
     * Sets image variables for template based on user
     * @param user
     * @private
     */
    function _setImage(user) {
        vm.imageId = user.userImageId;
        vm.croppedImage  = vm.imageId && user.userImageMetadata.secure_url ? user.userImageMetadata.secure_url : '';
        vm.userImage = vm.croppedImage || '';
    }
}

/**
 * @ngInject
 */
function UserAvatarBubbleController() {
    var vm = this;
}