'use strict';
import angular from 'angular';
import $ from 'jquery';
import DevTools from '../../../devtools';
import _ from 'lodash';
import appLoaderHtmlUrl from '../views/directives/apploader.html';
import appWideLoaderHtmlUrl from '../views/directives/appwideloader.html';
import deviceNavTogglerHtmlUrl from '../views/directives/devicenavtoggler.html';
import userImpersonationModalHtmlUrl from '../views/directives/userimpersonationmodal.html';
import appHeaderContainerHtmlUrl from '../views/directives/appheadercontainer.html';
import userImpersonationHeaderHtmlUrl from '../views/directives/userimpersonationheader.html';
import userIntegrationHeaderHtmlUrl from '../views/directives/userintegrationheader.html';
import dateRangePickerHtmlUrl from '../views/directives/daterangepicker.html';
import bounceLoaderHtmlUrl from '../views/directives/bounceloader.html';

angular.module('core.app.directives', [])
    .directive('appLoader', appLoader)
    .directive('appWideLoader', appWideLoader)
    .directive('deviceNavToggler', deviceNavToggler)
    .directive('appHeaderContainer', appHeaderContainer)
    .directive('userImpersonationModal', userImpersonationModal)
    .directive('userImpersonationHeader', userImpersonationHeader)
    .directive('userIntegrationHeader', userIntegrationHeader)
    .directive('dateRangePicker', dateRangePicker)
    .directive('customBranding', customBranding)
    .directive('bounceLoader', bounceLoader)
    .directive('preventDefault', preventDefault)
    .directive('iframeOnload', iframeOnload)
    .directive('clickOff', clickOff)
    .directive('stopPropagation', stopPropagation)
    .directive('stopPropagationRightClick', stopPropagationRightClick)
    .directive('rightFrame', rightFrame);

/**
 * @ngInject
 */
function appLoader() {
    return {
        restrict: 'E',
        scope: false,
        replace: true,
        templateUrl: appLoaderHtmlUrl
    }
}

/**
 * @ngInject
 */
function appWideLoader(
    PubSub,
    AppLoader
) {
    return {
        restrict: 'E',
        templateUrl: appWideLoaderHtmlUrl,
        link: function($scope, $el) {
            _init();

            function _init() {
                $scope.isActive = false;
                $scope.styles = {'width':'100vw','height':'100vh'};

                PubSub.on(AppLoader.SHOW, _showAppLoader);
                PubSub.on(AppLoader.HIDE, _hideAppLoader);

                $scope.$on('$destroy', function () {
                    PubSub.off(AppLoader.SHOW, _showAppLoader);
                    PubSub.off(AppLoader.HIDE, _hideAppLoader);
                    $scope.isActive = false;
                });
            }

            function _showAppLoader(options) {
                options = options || {};
                $scope.isActive = true;

                $scope.title = options.title || 'Loading';
                $scope.icon = options.icon || 'icomoon-barchart';
            }

            function _hideAppLoader() {
                $scope.isActive = false;
            }
        }
    }
}

/**
 * @ngInject
 */
function ngLazyShow($animate) {
    return {
        multiElement: true,
        transclude: 'element',
        priority: 600,
        terminal: true,
        restrict: 'A',
        link: function ($scope, $element, $attr, $ctrl, $transclude) {
            var loaded;
            $scope.$watch($attr.ngLazyShow, function ngLazyShowWatchAction(value) {
                if (loaded) {
                    $animate[value ? 'removeClass' : 'addClass']($element, 'ng-hide');
                }
                else if (value) {
                    loaded = true;
                    $transclude(function (clone) {
                        clone[clone.length++] = document.createComment(' end ngLazyShow: ' + $attr.ngLazyShow + ' ');
                        $animate.enter(clone, $element.parent(), $element);
                        $element = clone;
                    });
                }
            });
        }
    };
}

/**
 * Nav menu trigger on small devices (tablet, mobile)
 * @ngInject
 */
function deviceNavToggler() {
    return {
        restrict: 'E',
        templateUrl: deviceNavTogglerHtmlUrl,
        scope: false,
        link: function() {
            var $leftFrame = $('#left-frame');
            var $body = $('body');
            var $deviceNavToggler = $('#device-nav-toggler');
            var $deviceNavOverlay = $('#device-nav-overlay');

            $deviceNavToggler.click(function() {
                $leftFrame.toggleClass('force-show');
                $body.removeClass('responsive');
                $deviceNavOverlay.toggleClass('active');
            });

            $deviceNavOverlay.click(function() {
                $leftFrame.removeClass('force-show');
                $body.removeClass('responsive');
                $deviceNavOverlay.removeClass('active');
            });
        }
    }
}

/**
 * @ngInject
 */
function userImpersonationModal(
    UserType,
    PubSub,
    $ModalEvents,
    AppFactory,
    LoginFactory,
    UserFactory,
    UIFactory,
    AppModelFactory,
    $timeout,
    $q,
    gettextCatalog
) {
    return {
        restrict: 'E',
        templateUrl: userImpersonationModalHtmlUrl,
        scope: false,
        controller: function($scope) {
            var user = AppFactory.getUser();
            $scope.displayName = user.firstName + ' ' + user.lastName + ' ' + user.userEmail;
            $scope.isImpersonationMode = user.isImpersonationMode;
            $scope.model = {};
            $scope.model.users = [];
            $scope.model.selectedUser = null;
            $scope.state = {
                isLoading: true,
                isApplyingUser: false
            };

            function formatUsers(data) {
                return _.map(_.filter(data, function(user) {
                    return currentUser.userId !== user.id;
                }), function (user) {
                    var displayName = '';
                    if (!_.isEmpty(user.first_name) || !_.isEmpty(user.first_name)) {
                        displayName = user.first_name + ' ' + user.last_name + ' - ';
                    }
                    var clientName = '';
                    if (user.user_type === UserType.CLIENT && user.client_name) {
                        clientName = user.client_name + ' - ';
                    }
                    var userTypeDisplay = UserType[user.user_type.toUpperCase() + '_DISPLAY'];
                    var text = clientName + displayName + user.email;
                    return {id: user.id, text: text, userType: userTypeDisplay, details: user};
                });
            }

            function _updateSelectOptions() {
                if (!$scope.selectOptions) {
                    return;
                }

                $scope.selectOptions.getDataCall().then(function(json) {
                    $scope.selectOptions.formatData(json.plain());
                });
            }

            function _setSelectOptions() {
                if ($scope.selectOptions) {
                    return;
                }

                $scope.selectOptions = AppModelFactory.getSelectOptions({
                    placeholder: gettextCatalog.getString('Select user to log in as...'),
                    multiple: false,
                    width: '100%',
                    formatResult: function (item) {
                        if (!item.id) {
                            return item.text;
                        }
                        return $('<label class="label label-default mr10">' + item.userType + '</label><span style="font-size: 13px;">' + item.text + '</span>');
                    },
                    getDataCall: function(query) {
                        $scope.state.isLoading = true;
                        let currentUser = AppFactory.getUser();
                        const queryParams = {
                            sort: 'user_type,email',
                            status: 'active'
                        };

                        if (query) {
                            queryParams.q = query;
                        }

                        return UserFactory.getImpersonationList(queryParams);
                    },
                    formatData: function(json) {
                        let data = json.values;
                        $scope.model.users = formatUsers(data);
                        $scope.state.isLoading = false;

                    },
                    tooMuchDataCallback: function(query) {
                        let self = this;
                        // In order to enable tags, the query must be the first item in results
                        let selectedValues = {results: Array.isArray(self.data) ? self.data : self.data().results};
                        // Query length must be checked here, or else the selectable values will never be set
                        if (query.term.length >= 2 && self.getDataCall) {
                            $q.resolve(self.getDataCall(query.term)).then(function (item) {
                                self.loaded = true;
                                if (item.values.length) {
                                    // Only show the query if wildcards are a selectable value
                                    selectedValues.results = (formatUsers(item.values));
                                }
                                query.callback(selectedValues);
                                $scope.state.isLoading = false;
                            }, function (error) {
                                console.error("Error getting data", error);
                            });
                        } else {
                            query.callback(selectedValues);
                            $scope.state.isLoading = false;
                        }
                    }
                });
            }

            $scope.impersonateUser = function() {
                $scope.state.isApplyingUser = true;
                LoginFactory.impersonateUser($scope.model.selectedUser);
            };

            $scope.onUserSelect = function($element, event) {
                $scope.model.selectedUser = event.added ? event.added.details : null;
            };

            PubSub.on($ModalEvents.SHOW_MODAL + 'user-impersonation-modal', _setSelectOptions);
            PubSub.on('nav:refreshImpersonationList', _updateSelectOptions);

        }
    };
}

/**
 * @nInject
 */
function appHeaderContainer(
    UserIntegrationResource
) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: appHeaderContainerHtmlUrl,
        controller: function ($scope) {
            $scope.isUserIntegrated = UserIntegrationResource.isUserIntegrated();
        }
    }
}

/**
 * @ngInject
 */
function userImpersonationHeader(
    UserType,
    AppFactory,
    LoginFactory
) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: userImpersonationHeaderHtmlUrl,
        controller: function($scope) {
            var user = AppFactory.getUser();
            var displayName = '';
            if (user.type === UserType.CLIENT && user.clientName) {
                displayName = user.clientName + ' - ';
            }
            else if (!_.isEmpty(user.firstName) || !_.isEmpty(user.firstName)) {
                displayName = user.firstName + ' ' + user.lastName + ' - ';
            }
            $scope.userDisplayName = displayName + user.userEmail;
            $scope.userReportingProfile = user.reportingProfileName;
            $scope.isImpersonationMode = user.isImpersonationMode;
            $scope.unimpersonateUser = LoginFactory.unimpersonateUser;
        }
    }
}

/**
 * @nInject
 */
function userIntegrationHeader(
) {
    return {
        restrict: 'E',
        templateUrl: userIntegrationHeaderHtmlUrl,
        controller: function($scope, $element) {
            $element.prependTo($('body'));
        }
    }
}

/**
 * @ngInject
 */
function dateRangePicker(
    $rootScope,
    RelativeDateRange,
    AppFactory,
    AppModelFactory,
    DateRangeFactory,
    LanguageService
) {
    return {
        restrict: 'E',
        templateUrl: dateRangePickerHtmlUrl,
        scope: {
            options: '<',
            disabled: '<', // Disabled and read-only
            readOnly: '=' // Only read-only
        },
        link: function(scope, el) {
            var options = scope.options || {};
            // var dateOptions = _.isEmpty(options) ? defaultOptions : _.extend(defaultOptions, options);
            options.applyClass = 'btn-primary';

            var dateOptions = AppModelFactory.getDateOptions(options);
            dateOptions.startDate = _.isUndefined(dateOptions.startDate) ? DateRangeFactory.getRelativeDateRange(RelativeDateRange.TODAY) : dateOptions.getFormattedDate(dateOptions.startDate, LanguageService.getDisplayDateFormat());
            dateOptions.endDate = _.isUndefined(dateOptions.endDate) ? DateRangeFactory.getRelativeDateRange(RelativeDateRange.TODAY) : dateOptions.getFormattedDate(dateOptions.endDate, LanguageService.getDisplayDateFormat());

            if (!dateOptions.disableRanges) {
                dateOptions.locale = DateRangeFactory.getDateRangePickerLocale();

                dateOptions.ranges = DateRangeFactory.getDatePickerRelativeRanges();
            }

            var $dateRangePicker = el.find('input.daterange');
            var $closeButton = el.find('i.clear-button');

            if (scope.disabled) {
                $dateRangePicker.disableElement();
            }


            $dateRangePicker.daterangepicker(dateOptions,  function dateRangeCallback(start, end, label) {
                if (!_.isEmpty(options)) {
                    // Date format is Unix timestamp, since that is what will be sent to backend.
                    options.start =  start.format('X');
                    options.end =  end.format('X');
                    options.label =  label;

                    // Allow other directives to listen to the dateRangePicker onchange event
                    if (dateOptions.callback) {
                        dateOptions.callback(start, end, label);
                    }
                    $rootScope.$broadcast('dateRangePicker:change', options);
                }
            });
            $closeButton.on('click', function() {
                options.start = null;
                options.end = null;
                options.label = null;
                $dateRangePicker.val(dateOptions.placeholder);
                if (dateOptions.callback) {
                    dateOptions.callback(null, null, null);
                }
            });

            $dateRangePicker.on('show.daterangepicker', function (event, picker) {
                dateOptions.onShow && dateOptions.onShow(event, picker);
            });
            $dateRangePicker.on('hide.daterangepicker', function (event, picker) {
                picker.container.removeClass('no-arrow');
            });

            // Option to display nothing when date is null
            if (dateOptions.emptyOnNull) {
                // Set placeholder if dates are empty when creating daterangePicker
                if (_.isEmpty(dateOptions.startDate) && _.isEmpty(dateOptions.endDate)) {
                    $dateRangePicker.val(dateOptions.placeholder);
                }
                // Set empty value on close
                $dateRangePicker.on('hide.daterangepicker', function(e, picker) {
                    if (_.isEmpty(options.start) && _.isEmpty(options.end)) {
                        $dateRangePicker.val(dateOptions.placeholder);
                    }
                });
            }
        }
    }
}

/**
 * @ngInject
 */
function customBranding(
    AppService,
    PubSub
) {
    return {
        restrict: 'A',
        //scope:true,
        controller: function($scope) {
            $scope.customLogo = []; //Logo url
            $scope.hasCustomLogo = [];
            $scope.logoAssetName = []; //Logo file name (ex: {assetName}.png)

            $scope.setLogo = function(type, assetName) {
                AppService.getAssetUrl(assetName).success(function(json) {
                    if (json.status == 'success' && json.data != false) {
                        $scope.customLogo[type] = json.data;
                        $scope.hasCustomLogo[type] = true;
                    }
                    $scope.logoAssetName[type] = assetName;
                });
            };

            $scope.customPptTemplateLink = ''; //PPT template URL
            $scope.hasCustomPptTemplate = false;
            $scope.templateAssetName = ''; //PPT template file name

            $scope.setPptTemplate = function(assetName) {
                AppService.getAssetUrl(assetName, 'potx').success(function(json) {
                    if (json.status == 'success' && json.data != false) {
                        $scope.customPptTemplateLink = json.data;
                        $scope.hasCustomPptTemplate = true;
                    }
                    $scope.templateAssetName = assetName;
                });
            };

        },
        link: function(scope) {

            scope.uploadLogoCallback = function(json, model) {
                //Only handle this loop if files have been uploaded
                if (scope.files != null && scope.files.length) {
                    scope.$evalAsync(function() {
                        angular.forEach(scope.files, function(file) {
                            if (file.key == 'light_logo_image') {
                                $('#light_logo_image').fileinput('clear');
                                //New entity was just added so append new entity id to logo asset name
                                if (json.data.is_new_entity) {
                                    scope.logoAssetName['light'] += json.data.id;
                                }
                                scope.setLogo('light', scope.logoAssetName['light']);
                            }
                            else if (file.key == 'dark_logo_image') {
                                $('#dark_logo_image').fileinput('clear');
                                //New entity was just added so append new entity id to logo asset name
                                if (json.data.is_new_entity) {
                                    scope.logoAssetName['dark'] += json.data.id;
                                }
                                scope.setLogo('dark', scope.logoAssetName['dark']);
                            }
                            else if (file.key == 'ppt_template_image') {
                                $('#ppt_template_image').fileinput('clear');
                                //New entity was just added so append new entity id to template asset name
                                if (json.data.is_new_entity) {
                                    scope.templateAssetName += json.data.id;
                                }
                                scope.setPptTemplate(scope.templateAssetName);
                            }
                        });
                    });
                }
                //If removing uncheck and set back to empty logo
                if (model['remove_logo_light'] == 'yes') {
                    $('input:checkbox[name="remove_logo_light"]').prop('checked', false);
                    scope.hasCustomLogo['light'] = false;
                }
                if (model['remove_logo_dark'] == 'yes') {
                    $('input:checkbox[name="remove_logo_dark"]').prop('checked', false);
                    scope.hasCustomLogo['dark'] = false;
                }
                if (model['remove_ppt_template'] == 'yes') {
                    $('input:checkbox[name="remove_ppt_template"]').prop('checked', false);
                    scope.hasCustomPptTemplate = false;
                }

                if (json.data.is_new_entity) {
                    PubSub.emit('customBranding:new_entity');
                }
            };
        }
    }
}

/**
 * @ngInject
 */
function bounceLoader() {
    return {
        restrict: 'E',
        scope: {
            size: '<'
        },
        templateUrl: bounceLoaderHtmlUrl,
        controller: function($scope) {
            $scope.size = $scope.size || 30;
        }
    }
}

/**
 * @ngInject
 */
function preventDefault() {
    return {
        restrict: 'A',
        link: function(scope, el) {
            el.click(function(evt) {
                if (evt && evt.preventDefault) {
                    evt.preventDefault();
                }
            })
        }
    }
}

/**
 * @ngInject
 */
function iframeOnload() {
    return {
        scope: {
            callBack: '&iframeOnload'
        },
        link: function(scope, el){
            el.on('load', function(){
                return scope.callBack();
            });
        }
    }
}

/**
 * Toggle off event click listeners
 * @ngInject
 */
function clickOff(
    $parse,
    $document
) {
    return {
        compile: function($element, attr) {
            // Parse the expression to be executed
            // whenever someone clicks _off_ this element.
            var fn = $parse(attr["clickOff"]);
            return function(scope, element) {
                // add a click handler to the element that
                // stops the event propagation.
                element.bind("click", function(event) {
                    event.stopPropagation();
                });
                angular.element($document[0].body).bind("click", function(event) {
                    scope.$apply(function() {
                        fn(scope, {$event:event});
                    });
                });
            }
        }
    }
}

/**
 * @ngInject
 */
function stopPropagation() {

    return {
        restrict: 'A',
        link: function(scope, el) {
            el.click(function(evt) {
                if (evt && evt.stopPropagation) {
                    evt.stopPropagation();
                }
            })
        }
    }
}

/**
 * @ngInject
 */
function stopPropagationRightClick() {

    return {
        restrict: 'A',
        link: function(scope, el) {
            el.bind('contextmenu', _clickEvent);
            scope.$on('$destroy', function () {
                el.unbind('contextmenu', _clickEvent);
            });

            function _clickEvent(event) {
                if (event && event.stopPropagation) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }
    }
}

/**
 *
 * @ngInject
 */
function rightFrame() {
    return {
        restrict: 'A',
        link: function(scope, el) {
            scope.scrollTop = scrollTop;

            function scrollTop() {
                $(el).animate({scrollTop: 0}, 0);
            }
        },
        controller: 'RightFrameController'
    }
}
