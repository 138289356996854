export const FORM_INPUT_ENTER_KEY_EVENT = 'FormInputEnterKeyEvent';

export const FORM_INPUT_TYPE = {
  DISABLED: 'disabled',
};

// Use Snake Case for Key and kebab-case for value.
// Use Alphabetical order.
export const EVENT = {
  ON_CANCEL: 'on-cancel',
  ON_NEXT: 'on-next',
  ON_SAVE: 'on-save',
};

export const SCROLL_BEHAVIOUR = {
  SMOOTH: 'smooth',
};

export const SCROLL_BLOCK = {
  CENTER: 'center',
  END: 'end',
};

export const FORM_VALIDATION_CLASSES = {
  VALIDATION_ERRORS: '.validation-errors',
};

export const UNDEFINED_CONSTANT = 'undefined';
