import { SliceChartConfig } from '@/modules/core/charts/am5/base/models/SliceChartConfig';
import { SeriesType } from '@/modules/core/charts/chart.constants';

/**
 * @class PyramidChartConfig
 * @extends SliceChartConfig
 */
export class PyramidChartConfig extends SliceChartConfig {
  constructor(model = {}) {
    super(model);
  }

  getSeriesType() {
    return SeriesType.PYRAMID;
  }

  isPyramidSeries() {
    return true;
  }
}
