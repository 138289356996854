import { DrawOption } from '@/modules/ta/widget/widget.constants';
import { DrawOptionItem } from '@/modules/ta/widget/models/drawoptions/DrawOptionItem';

export class LayoutDrawOption {
  /**
   * @var {DrawOptionItem}
   */
  show_shadow;

  /**
   * @var {DrawOptionItem}
   */
  show_background;

  /**
   * @var {DrawOptionItem}
   */
  show_borders;

  /**
   * @var {DrawOptionItem}
   */
  show_title;

  /**
   * @var {DrawOptionItem}
   */
  show_data_source;

  /**
   * @var {DrawOptionItem}
   */
  show_widgets_with_no_data;

  /**
   * @param model
   */
  constructor(model = {}) {
    this.show_shadow = new DrawOptionItem(model.show_shadow || { value: false });
    this.show_shadow.buildDrawOptionDefaultMetadata(DrawOption.SHOW_SHADOW);
    this.show_background = new DrawOptionItem(model.show_background || { value: false });
    this.show_background.buildDrawOptionDefaultMetadata(DrawOption.SHOW_BACKGROUND);
    this.show_borders = new DrawOptionItem(model.show_borders || { value: true });
    this.show_borders.buildDrawOptionDefaultMetadata(DrawOption.SHOW_BORDERS);
    this.show_title = new DrawOptionItem(model.show_title || { value: true });
    this.show_title.buildDrawOptionDefaultMetadata(DrawOption.SHOW_TITLE);
    this.show_data_source = new DrawOptionItem(model.show_data_source || { value: true });
    this.show_data_source.buildDrawOptionDefaultMetadata(
      DrawOption.SHOW_DATA_SOURCE || { value: true }
    );
    this.show_widgets_with_no_data = new DrawOptionItem(model.show_widgets_with_no_data);
    this.show_widgets_with_no_data.buildDrawOptionDefaultMetadata(
      DrawOption.SHOW_WIDGETS_WITH_NO_DATA
    );
  }
}
