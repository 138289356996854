'use strict';
import angular from 'angular';
import './widget.bignumber.components';
import './widget.bignumber.services';
import './widget.bignumber.directives';

angular.module('widget.bignumber', [
    'widget.bignumber.components',
    'widget.bignumber.services',
    'widget.bignumber.directives'
]);