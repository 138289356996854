'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

import { $ExportBuilderDashboardEvents, $ExportBuilderDashboardModelEvents } from 'coreModules/exportbuilder/exportbuilder.constants';
import { $WidgetBuilderEvents } from 'coreModules/design/widget/builder/widget.builder.constants';

import exportBuilderPanelHtml from './exportbuilder.panel.html';
import exportBuilderPanelStylesHtml from './exportbuilder.panel.styles.html';
import exportBuilderPanelSettingsHtml from './exportbuilder.panel.settings.html';
import exportBuilderPanelDataHtml from './exportbuilder.panel.data.html';
import exportBuilderMultiPanelStylesHtml from './exportbuilder.multi.panel.styles.html';
import exportBuilderPanelFiltersHtml from './exportbuilder.panel.filters.html';

angular.module('exportbuilder.panel.components', [])
    .component('exportBuilderPanel', {
        bindings: {

        },
        templateUrl: exportBuilderPanelHtml,
        controller: ExportBuilderPanelController,
        controllerAs: 'vm'
    })
    .component('exportBuilderPanelStylesComponent', {
        bindings: {
            reportDrawOptionsProperties: '<',
            widgetDrawOptionsProperties: '<',
        },
        templateUrl: exportBuilderPanelStylesHtml,
        controller: ExportBuilderPanelStylesController,
        controllerAs: 'vm'
    })
    .component('exportBuilderPanelSettingsComponent', {
        bindings: {
            reportDrawOptionsProperties: '<',
            widgetDrawOptionsProperties: '<',
        },
        templateUrl: exportBuilderPanelSettingsHtml,
        controller: ExportBuilderPanelSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderPanelDataComponent', {
        bindings: {
            item: '<'
        },
        templateUrl: exportBuilderPanelDataHtml,
        controller: ExportBuilderPanelDataController,
        controllerAs: 'vm'
    })
    .component('exportBuilderMultiPanelStylesComponent', {
        bindings: {
            element: '<'
        },
        templateUrl: exportBuilderMultiPanelStylesHtml,
        controller: ExportBuilderMultiPanelStylesComponent,
        controllerAs: 'vm'
    })
    .component('exportBuilderPanelFiltersComponent', {
        bindings: {
            element: '<'
        },
        templateUrl: exportBuilderPanelFiltersHtml,
        controller: ExportBuilderPanelFiltersComponent,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderPanelController(
    WidgetFactory,
    DrawOption,
    ExportBuilderDashboardService,
    PubSub,
    $WidgetBuilderEvents
) {
    var vm = this;
    vm.selectedTabIndex = 0;
    vm.reportDrawOptionsProperties = [];
    vm.widgetDrawOptionsProperties = [];

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onToggleTab = onToggleTab;

    function $onInit() {
        vm.builder = ExportBuilderDashboardService.getBuilder();
        if (!vm.builder.report.can_be_edited) {
            vm.selectedTabIndex = 2;
            vm.loadedDataTab = true;
        }
        _registerEvents();
    }

    function onToggleTab(tabIndex) {
        vm.selectedTabIndex = tabIndex;
        if (tabIndex === 2) {
            vm.loadedDataTab = true;
        }
        if (tabIndex === 3) {
            vm.loadedFiltersTab = true;
        }
    }

    function onToggleDataTab() {
        onToggleTab(2);
    }

    function _registerEvents() {
        PubSub.on($WidgetBuilderEvents.RS_SWITCH_DATA_TAB, onToggleDataTab);
    }


    function $onDestroy() {
        _unregisterEvents()
    }

    function _unregisterEvents() {
        PubSub.off($WidgetBuilderEvents.RS_SWITCH_DATA_TAB, onToggleDataTab);
    }


}

/**
 * @ngInject
 */
function ExportBuilderPanelSettingsController(
    PubSub,
    ReportElementTypes,
    ExportBuilderDashboardService
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.ReportElementTypes = ReportElementTypes;

    function $onInit() {
        _registerEvents();
        vm.builder = ExportBuilderDashboardService.getBuilder();
        if (vm.builder.currentItem) {
            vm.item = vm.builder.currentItem;
        }
    }

    function $onDestroy() {
        _unregisterEvents()
    }

    function _onItemFocus(item) {
        vm.item = item;
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, _onItemFocus);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, _onItemFocus);
    }
}

/**
 * @ngInject
 */
function ExportBuilderPanelStylesController(
    $timeout,
    PubSub,
    ReportElementTypes,
    ExportBuilderDashboardService
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.ReportElementTypes = ReportElementTypes;

    function $onInit() {
        _registerEvents();
        vm.builder = ExportBuilderDashboardService.getBuilder();
        if (vm.builder.currentItem) {
            vm.item = vm.builder.currentItem;
        }
    }

    function $onDestroy() {
        _unregisterEvents()
    }

    function _onItemFocus(item) {
        vm.item = item;
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, _onItemFocus);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, _onItemFocus);
    }
}

/**
 * @ngInject
 */
function ExportBuilderPanelDataController(
    $timeout,
    $element,
    ExportBuilderDashboardService,
    ExportBuilderFacadeUIService,
    AppFactory,
    ExportBuilderDashboardModelFactory,
    ExportBuilderDashboardUIModelFactory,
    DateRangeFactory,
    gettextCatalog,
    PubSub,
    RelativeDateRange,
    ExportBuilderPanelConstants,
    ExportBuilderFilterService,
    ReportStudioTemplateDataService,
    UserType,
    AppModule,
    DataSourceType,
    DashboardContextService
) {
    var vm = this;

    /**
     * @type {PanelClusterOption}
     */
    vm.clusterOption;

    /**
     * @type {PanelClientSelectOption}
     */
    vm.clientSelectOptions;

    /**
     * @type {PanelClientGroupSelectOption}
     */
    vm.clientGroupSelectOptions;

    /**
     * @type {PanelViewModel}
     */
    vm.model = ExportBuilderDashboardModelFactory.getPanelViewModel();

    /**
     * @type {PanelViewState}
     */
    vm.state = ExportBuilderDashboardModelFactory.getPanelViewState();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onChangeSmartCampaign = onChangeSmartCampaign;
    vm.onChangeCluster = onChangeCluster;
    vm.onChangeClient = onChangeClient;
    vm.onChangeClientGroup = onChangeClientGroup;
    vm.showSampleDataToggle = showSampleDataToggle;
    vm.onPreviewChange = onPreviewChange;
    vm.canShowBenchmarkToggle = canShowBenchmarkToggle;
    vm.onBenchmarkChange = onBenchmarkChange;
    vm.getPreviewTooltipText = getPreviewTooltipText;
    vm.onRefreshPage = onRefreshPage;
    vm.isPreview = isPreview;
    vm.toggleComparison = toggleComparison;
    vm.showDataPlaceholderOverlay = showDataPlaceholderOverlay;
    vm.getInnerStyles = getInnerStyles;
    vm.setDateRangePicker = setDateRangePicker;
    vm.canShowClusterOptions = canShowClusterOptions;
    vm.canShowAssignmentOption = false;
    vm.canShowSmartCampaignOption = false;

    function $onInit() {
        vm.isComparing = AppFactory.getComparisonDateRange().enabled;
        vm.brandMappings = AppFactory.getBrandMappings();
        vm.builder = ExportBuilderDashboardService.getBuilder();

        vm.canShowAssignmentOption = AppFactory.getUser().isModuleAvailable(AppModule.REPORT_STUDIO_PAGE_ASSIGNMENT)
            && ExportBuilderDashboardService.canEditReport();

        vm.canShowSmartCampaignOption = AppFactory.isUserModuleAvailable(AppModule.SMART_CAMPAIGNS) && AppFactory.getUser().smartCampaignsEnabled;
        vm.state.isLoadingDataPanel = true;
        ExportBuilderDashboardService.getFilter().then(function (filter) {
            vm.model.selectedFilter = filter;

            //client_id ensures that this is client centric report
            if(vm.builder.report.client_id){
                vm.model.selectedFilter = {
                    id: vm.builder.report.client_id,
                    text: vm.builder.report.client_name,
                    type: "client"
                };
            }

            _setUpSelectOptions();
            vm.state.isLoadingDataPanel = false;
        });
        vm.model.is_preview = vm.builder.report.is_preview;

        vm.options = vm.options || {};
        vm.$dateRangePicker = $element.find('input.current-range');
        vm.$comparisonDateRangePicker = $element.find('input.comparison-range');

        var defaultOptions = DateRangeFactory.getDefaultOptions();
        defaultOptions.opens = 'left';
        defaultOptions.drops = 'down';

        var comparisonDefaultOptions = DateRangeFactory.getComparisonDefaultOptions();
        comparisonDefaultOptions.opens = 'left';
        comparisonDefaultOptions.drops = 'down';

        vm.dateOptions = _.assign(defaultOptions, vm.options);
        vm.comparisonDateOptions = _.assign(comparisonDefaultOptions, vm.options);

        initDatePicker();
        initComparisonDatePicker({
            ranges: DateRangeFactory.getComparisonDatePickerRelativeRanges()
        });
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function getSelectedEntityText(selectedType) {
        const { text: selectedText } = _.pick(selectedType, ['text']);
        if (selectedText) {
            return selectedText;
        }
    }

    function onChangeClient($el, event) {
        const oldFilter = vm.model.selectedClient;
        vm.model.selectedClient = $el.select2('data');
        if (vm.model.selectedClient) {
            //TODO: @dannyyassine to be fixed after release: must format data for inside tooMuchDataCallback in `app.models`
            vm.model.selectedClient.type = vm.clientSelectOptions.selectorType;
            ExportBuilderDashboardService.setSelectedFilter(vm.model.selectedClient)
                .then(function () {
                    _sendEvent();
                    _onSetFilter(vm.model.selectedClient);
                    vm.canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT, getSelectedEntityText(vm.model.selectedClient));
                });
        } else {
            ExportBuilderDashboardService.unsetFilter()
                .then(function () {
                    _sendEvent();
                    _onClearFilter(oldFilter);
                    vm.canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT);
                });
        }
        vm.model.selectedClientGroup = null;
        vm.model.selectedCluster = null;
        vm.model.selectedSmartCampaign = null;

        vm.resetClientGroupSelect = true;
        vm.resetClusterSelect = true;
        vm.resetSmartCampaignSelect = true;

        $timeout(function () {
            vm.resetClientGroupSelect = false;
            vm.resetClusterSelect = false;
            vm.resetSmartCampaignSelect = false;
        }, 0);
    }

    function onChangeClientGroup($el, event) {
        const oldFilter = vm.model.selectedClientGroup;
        vm.model.selectedClientGroup = $el.select2('data');
        if (vm.model.selectedClientGroup) {
            //TODO: @dannyyassine to be fixed after release: must format data for inside tooMuchDataCallback in `app.models`
            vm.model.selectedClientGroup.type = vm.clientGroupSelectOptions.selectorType;
            ExportBuilderDashboardService.setSelectedFilter(vm.model.selectedClientGroup)
                .then(function () {
                    _sendEvent();
                    _onSetFilter(vm.model.selectedClientGroup);
                    vm.canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT, getSelectedEntityText(vm.model.selectedClientGroup));
                });
        } else {
            ExportBuilderDashboardService.unsetFilter()
                .then(function () {
                    _sendEvent();
                    _onClearFilter(oldFilter);
                    vm.canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT);
                });
        }
        vm.model.selectedClient = null;
        vm.model.selectedCluster = null;
        vm.model.selectedSmartCampaign = null;

        vm.resetClientSelect = true;
        vm.resetClusterSelect = true;
        vm.resetSmartCampaignSelect = true;

        $timeout(function () {
            vm.resetClientSelect = false;
            vm.resetClusterSelect = false;
            vm.resetSmartCampaignSelect = false;
        }, 0);
    }

    function showSampleDataToggle() {
        return !DashboardContextService.isDemoModeEnabled();
    }

    function canShowBenchmarkToggle() {
        return AppFactory.getUser().isModuleAvailable(AppModule.BENCHMARKS);
    }

    function onBenchmarkChange() {
        onPreviewChange();
    }

    function onChangeCluster($el, event) {
        const oldFilter = vm.model.selectedCluster;
        vm.model.selectedCluster = $el.select2('data');
        if (vm.model.selectedCluster) {
            //TODO: @dannyyassine to be fixed after release: must format data for inside tooMuchDataCallback in `app.models`
            vm.model.selectedCluster.type = vm.clusterSelectOptions.selectorType;
            ExportBuilderDashboardService.setSelectedFilter(vm.model.selectedCluster)
                .then(function () {
                    _sendEvent();
                    _onSetFilter(vm.model.selectedCluster);
                    vm.canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT, getSelectedEntityText(vm.model.selectedCluster));
                });
        } else {
            ExportBuilderDashboardService.unsetFilter()
                .then(function () {
                    _sendEvent();
                    _onClearFilter(oldFilter);
                    vm.canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT);
                });
        }
        vm.model.selectedClient = null;
        vm.model.selectedClientGroup = null;
        vm.model.selectedSmartCampaign = null;

        vm.resetClientGroupSelect = true;
        vm.resetClientSelect = true;
        vm.resetSmartCampaignSelect = true;

        $timeout(function () {
            vm.resetClientGroupSelect = false;
            vm.resetClientSelect = false;
            vm.resetSmartCampaignSelect = false;
        }, 0);
    }

    function onChangeSmartCampaign($el, event) {
        const oldFilter = vm.model.selectedSmartCampaign;
        vm.model.selectedSmartCampaign = $el.select2('data');
        if (vm.model.selectedSmartCampaign) {
            vm.model.selectedSmartCampaign.type = vm.smartCampaignsOptions.selectorType;
            ExportBuilderDashboardService.setSelectedFilter(vm.model.selectedSmartCampaign)
                .then(function () {
                    _sendEvent();
                    _onSetFilter(vm.model.selectedSmartCampaign);
                    vm.canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT, getSelectedEntityText(vm.model.selectedSmartCampaign));
                });
        } else {
            ExportBuilderDashboardService.unsetFilter()
                .then(function () {
                    _sendEvent();
                    _onClearFilter(oldFilter);
                    vm.canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT);
                });
        }
        vm.model.selectedClient = null;
        vm.model.selectedClientGroup = null;
        vm.model.selectedCluster = null;

        vm.resetClientGroupSelect = true;
        vm.resetClientSelect = true;
        vm.resetClusterSelect = true;

        $timeout(function () {
            vm.resetClientGroupSelect = false;
            vm.resetClientSelect = false;
            vm.resetClusterSelect = false;
        }, 0);

    }

    function _onSetFilter(filter) {
        if (window.isNUI) {
            const { type, id } = filter;
            PubSub.emit('SegmentEvents', { event: 'ReportStudioSetFilterEvent', payload: { type, id } });
        }
    }

    function _onClearFilter(filter) {
        if (window.isNUI) {
            const { type, id } = filter;
            PubSub.emit('SegmentEvents', { event: 'ReportStudioClearFilterEvent', payload: { type, id } });
        }
    }

    function onPreviewChange() {
        ExportBuilderDashboardService.setReportDataFields(vm.model);
        _sendEvent();
    }

    function getPreviewTooltipText() {
        return vm.model.is_preview
            ? gettextCatalog.getString('Turn off, "Sample Data" above to use data options.')
            : null;
    }

    function onRefreshPage() {
        ExportBuilderDashboardService.emptyCurrentPageWidgetCache();
        _sendEvent();
    }

    function isPreview() {
        if (DashboardContextService.isDemoModeEnabled()) {
            return false;
        }
        return vm.model.is_preview
    }

    function _setUpSelectOptions() {
        vm.clientGroupSelectOptions = ExportBuilderDashboardUIModelFactory.getPanelClientGroupSelectOption();
        vm.clientSelectOptions = ExportBuilderDashboardUIModelFactory.getPanelClientSelectOption();
        if (vm.canShowSmartCampaignOption) {
            vm.smartCampaignsOptions = ExportBuilderDashboardUIModelFactory.getPanelSmartCampaignSelectOption();
        }

        if (vm.canShowClusterOptions()) {
            vm.clusterSelectOptions = ExportBuilderDashboardUIModelFactory.getPanelClusterOption();
        }

        if (!vm.model.selectedFilter) {
            return;
        }

        switch (vm.model.selectedFilter.type) {
            case 'client':
                vm.clientSelectOptions.dataCallPromise.then(function () {
                    vm.model.selectedClient = _.find(vm.clientSelectOptions.values, {id: vm.model.selectedFilter.id});
                });
                break;
            case 'client_group':
                vm.clientGroupSelectOptions.dataCallPromise.then(function () {
                    vm.model.selectedClientGroup = _.find(vm.clientGroupSelectOptions.values, {id: vm.model.selectedFilter.id});
                });
                break;
            case 'cluster':
                vm.clusterSelectOptions.dataCallPromise.then(function () {
                    vm.model.selectedCluster = _.find(vm.clusterSelectOptions.values, {id: vm.model.selectedFilter.id});
                });
                break;
            case DataSourceType.SMART_CAMPAIGN:
                vm.smartCampaignsOptions.dataCallPromise.then(function () {
                    vm.model.selectedSmartCampaign = _.find(vm.smartCampaignsOptions.values, {id: vm.model.selectedFilter.id});
                });
                break;
        }
    }

    function toggleComparison() {
        var $datePicker = vm.$dateRangePicker.data('daterangepicker');
        AppFactory.toggleComparisonDateRange(vm.isComparing);

        vm.dateOptions.startDate = $datePicker.startDate;
        vm.dateOptions.endDate = $datePicker.endDate;
        initDatePicker();

        var comparisonPeriod = DateRangeFactory.calculatePeriod(
            $datePicker.startDate,
            $datePicker.endDate,
            RelativeDateRange.DEFAULT,
            RelativeDateRange.PRIOR_PERIOD
        );

        vm.comparisonDateOptions.startDate = comparisonPeriod.start;
        vm.comparisonDateOptions.endDate = comparisonPeriod.end;

        DateRangeFactory.setComparisonDatePicker(
            vm.dateOptions,
            vm.comparisonDateOptions,
            vm.$dateRangePicker,
            vm.$comparisonDateRangePicker,
            setDateRange
        );
        ExportBuilderDashboardService.saveDateRange().success(() => {
            _sendEvent();
            _onToggleComparisonDateRange();
        });
    }

    function getInnerStyles() {
        const styles = {};

        if (ReportStudioTemplateDataService.getReport().is_preview) {
            styles['overflow-y'] = 'hidden';
            styles['padding-right'] = '55px'
        } else {
            styles['overflow-y'] = 'scroll';
        }
        return styles;
    }

    function showDataPlaceholderOverlay() {
        const report = ReportStudioTemplateDataService.getReport();
        if (AppFactory.getUser().isAgent()) {
            return !_.isEmpty(report.client);
        }
        return !_.isEmpty(report.client) || !_.isEmpty(report.client_group);
    }

    /**
     * @param options
     */
    function initDatePicker(options) {
        options = options || {};

        DateRangeFactory.setDatePicker(
            _.assign(vm.dateOptions, options),
            vm.comparisonDateOptions,
            vm.$dateRangePicker,
            vm.$comparisonDateRangePicker,
            setDateRange
        );
    }

    function setDateRangePicker(){
        vm.$dateRangePicker = $element.find('input.current-range');
        initDatePicker();
    }

    function canShowClusterOptions() {
        return AppFactory.getUser().isFeatureAvailable('clusters') && AppFactory.getUser().isAdmin;
    }

    /**
     * @param options
     */
    function initComparisonDatePicker(options) {
        options = options || {};
        options.compareDateRangePickerId = ExportBuilderPanelConstants.EXPORT_BUILDER_COMPARE_DATE_RANGE_PICKER;

        DateRangeFactory.setComparisonDatePicker(
            vm.dateOptions,
            _.assign(vm.comparisonDateOptions, options || {}),
            vm.$dateRangePicker,
            vm.$comparisonDateRangePicker,
            setDateRange
        );
    }

    function _onEntityChanged() {
        vm.state.refreshFilters = true;
        $timeout(() => {
            vm.state.refreshFilters = false;
        });
    }

    function _onSetClient(client) {
        vm.model.is_preview = ReportStudioTemplateDataService.getReport().is_preview;

        if (client !== ReportStudioTemplateDataService.getEntity()) {
            _onEntityChanged();
        }
    }

    function _onDateChangeFromProbe(settings) {
        const { start, end, comparisonStart, comparisonEnd, relativeDateRange } = settings;

        setDateRange(start, end, comparisonStart, comparisonEnd, relativeDateRange);

        var $datePicker = vm.$dateRangePicker.data('daterangepicker');
        vm.dateOptions.startDate = $datePicker.startDate = start;
        vm.dateOptions.endDate = $datePicker.endDate = end;

        $datePicker.setStartDate(start);
        $datePicker.setEndDate(end);

        if (comparisonStart && comparisonEnd) {
            var comparisonPeriod = DateRangeFactory.calculatePeriod(
                $datePicker.startDate,
                $datePicker.endDate,
                relativeDateRange,
                RelativeDateRange.PRIOR_PERIOD
            );

            const $comparisonDateRangePicker = vm.$comparisonDateRangePicker.data('daterangepicker');

            vm.comparisonDateOptions.startDate = $comparisonDateRangePicker.startDate = comparisonPeriod.start;
            vm.comparisonDateOptions.endDate = $comparisonDateRangePicker.endDate = comparisonPeriod.end;

            $comparisonDateRangePicker.setStartDate(vm.comparisonDateOptions.startDate);
            $comparisonDateRangePicker.setEndDate(vm.comparisonDateOptions.endDate);
        }
    }

    function _registerEvents() {
        PubSub.on($WidgetBuilderEvents.SET_DATE_RANGE_FROM_PROBE, _onDateChangeFromProbe);
        PubSub.on($ExportBuilderDashboardEvents.SELECTED_ENTITY_CHANGED, _onEntityChanged);
        PubSub.on($ExportBuilderDashboardModelEvents.DID_SET_CLIENT, _onSetClient);
    }

    function _unregisterEvents() {
        PubSub.off($WidgetBuilderEvents.SET_DATE_RANGE_FROM_PROBE, _onDateChangeFromProbe);
        PubSub.off($ExportBuilderDashboardEvents.SELECTED_ENTITY_CHANGED, _onEntityChanged);
        PubSub.off($ExportBuilderDashboardModelEvents.DID_SET_CLIENT, _onSetClient);
    }

    function _sendEvent() {
        ExportBuilderFacadeUIService.refreshWidgets();
        // This below emit is required to refresh executive summaries on side panel
        PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_CHANGED);
    }

    /**
     * Rebuilds all widgets and sets date on front end and back end
     * @param start
     * @param end
     * @param comparisonStart
     * @param comparisonEnd
     * @param relativeDateRange
     */
    function setDateRange(
        start,
        end,
        comparisonStart,
        comparisonEnd,
        relativeDateRange
    ) {
        if (!moment.unix(start).isValid()
            || !moment.unix(end).isValid()
            || (comparisonStart && !moment.unix(comparisonStart).isValid())
            || (comparisonEnd && !moment.unix(comparisonEnd).isValid())
        ) {
            console.error('must use unix timestamp for setDateRange');
            return;
        }
        // Refers to the shortcut ranges (7 Days, 30 Days, This Month, Last Month)
        vm.quickRelativeRange = relativeDateRange;

        // Set app date range
        AppFactory.setDateRange(start, end, relativeDateRange);
        AppFactory.setComparisonDateRange(comparisonStart, comparisonEnd, vm.isComparing);

        ExportBuilderDashboardService.saveDateRange().success(() => {
            _sendEvent();
            _setDateFilterEvent(start, end, relativeDateRange);
        });
    }

    function _setDateFilterEvent(startDate, endDate, presetDateRange) {
        PubSub.emit('SegmentEvents', { event: 'ReportStudioSetDateFilterEvent',
            payload: { startDate, endDate, presetDateRange }});
    }

    function _onToggleComparisonDateRange() {
        PubSub.emit('SegmentEvents', { event: 'ReportStudioToggleComparisonDataEvent', payload: { compareData: vm.isComparing } });
    }
}

/**
 * @ngInject
 */
function ExportBuilderMultiPanelStylesComponent(
    PubSub,
    ExportBuilderDashboardService,
    ReportElementTypes
) {
    var vm = this;
    vm.ReportElementTypes = ReportElementTypes;

    /**
     * @type {ReportElementModel}
     */
    vm.element;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        _registerEvents();
        vm.element = ExportBuilderDashboardService.getBuilder().currentItem;
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _onItemFocus() {
        vm.element = ExportBuilderDashboardService.getBuilder().currentItem;
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, _onItemFocus);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, _onItemFocus);
    }
}

/**
 * @ngInject
 */
function ExportBuilderPanelFiltersComponent(
    $q,
    $timeout,
    gettextCatalog,
    AppModelFactory,
    ExportBuilderFilterService,
    ExportBuilderFacadeUIService,
    ReportStudioTemplateDataService,
    ExportBuilderFilterPanelModelFactory,
    FilterParam,
    FilterModeType,
    AppFactory,
    DataSourceFactory,
    UIFactory,
    PubSub,
) {
    const vm = this;

    /**
     * @type {FilterPanelState}
     */
    vm.state = ExportBuilderFilterPanelModelFactory.getFilterPanelState();

    /**
     * @type {Array<SelectOptions>}
     */
    vm.filtersSelectOptions = [];

    vm.FilterParam = FilterParam;

    vm.globalFiltersWarning = gettextCatalog.getString("You must add widgets to use global filters");

    vm.widgetFiltersWarning = gettextCatalog.getString("No widget filters available");

    vm.report = ReportStudioTemplateDataService.getReport();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getDataSourceColor = getDataSourceColor;
    vm.getDataSourceName = getDataSourceName;
    vm.getDataViewName = getDataViewName;
    vm.applyFiltersClicked = applyFiltersClicked;
    vm.resetFilterClicked = resetFilterClicked;
    vm.isDisableGlobalFilters = isDisableGlobalFilters;
    vm.reloadFiltersOptions = reloadFiltersOptions;

    function $onInit() {
        _registerEvents();
        loadFilters();
    }

    /**
     * load filters based on filter mode selected at report level
     */
    function loadFilters() {
        const report = ReportStudioTemplateDataService.getReport();
        const filters = ReportStudioTemplateDataService.getGlobalFilters();
        if (report.metadata.filter_mode === FilterModeType.WIDGET_FILTERS) {
            const widgetFilters = {}
            Object.keys(filters).forEach(filterKey => {
                const keyLength = filterKey.split('|').length
                if (keyLength > 3 || filters[filterKey].is_predefined === false) {
                    widgetFilters[filterKey] = filters[filterKey]
                }
            })
            vm.filtersSelectOptions = ExportBuilderFilterPanelModelFactory.getFilterOptions(widgetFilters);
        } else {
            const globalFilters = {}
            Object.keys(filters).forEach(filterKey => {
                const keyLength = filterKey.split('|').length
                if (keyLength === 3 && filters[filterKey].is_predefined === undefined) {
                    globalFilters[filterKey] = filters[filterKey]
                }
            })
            vm.filtersSelectOptions = ExportBuilderFilterPanelModelFactory.getFilterOptions(globalFilters);
        }
    }

    function _onReportFilterModeUpdate() {
        loadFilters();
        _resetFilters()
    }

    function isDisableGlobalFilters() {
        const report = ReportStudioTemplateDataService.getReport();
        let count = 0;
        let liveWidgetsCount = 0;
        report.pages.forEach(page => {
            page.elements.forEach(element => {
                if (element.widget) {
                    count += 1;
                    if (element.widget.has_live_integration) {
                        liveWidgetsCount += 1;
                    }
                }
            })
        })
        return count && count === liveWidgetsCount;
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function getDataSourceIcon(data_source) {
        return DataSourceFactory.getDataSourceIcon(data_source);
    }

    function getDataSourceColor(data_source) {
        return DataSourceFactory.getDataSourceColor(data_source);
    }

    function getDataSourceName(filter) {
        let name = (filter.data_source.id_name || filter.data_source.name)
        if (filter.filterLabel)
            name += ( ' - '+ filter.filterLabel)
        return name;
    }

    function getDataViewName(filter) {
        return filter.data_source.data_view_name || '';
    }

    function reloadFiltersOptions(filterOptions = []) {
        const optionsMap = {}
        vm.filtersSelectOptions.forEach((filter , i) => {
            optionsMap[filter.key] = i;
        });
        filterOptions.forEach((filterOption) => {
            if (vm.filtersSelectOptions && vm.filtersSelectOptions[optionsMap[filterOption.key]]) {
                vm.filtersSelectOptions[optionsMap[filterOption.key]].loaded = false;
                vm.filtersSelectOptions[optionsMap[filterOption.key]].selectOptions.getDataCall(filterOption.limit_available_values);
            }
        })
    }


    function applyFiltersClicked() {
        const dataSourceKeys = ReportStudioTemplateDataService.getWidgetDataSources(true);
        let notifyUser = false;
        vm.filtersSelectOptions && vm.filtersSelectOptions.forEach(filter => {
            if (dataSourceKeys[filter.key] && filter.currentValues && filter.currentValues.length !== 0) {
                notifyUser = true;
            }
        });
        if (notifyUser) {
            UIFactory.notify.showWarning('Global Filters don\'t work for On Demand Widgets');
        }
        vm.state.isLoadingPanel = true;
        ExportBuilderFilterService.updateGlobalFilters(vm.filtersSelectOptions)
            .finally(() => {
                vm.state.isLoadingPanel = false;
            });
    }

    function resetFilterClicked() {
        if (!_hasFilters()) {
            return;
        }

        UIFactory.confirmDelete({
            text: gettextCatalog.getString('<b>You will not be able to undo this action!</b>'),
            html: true,
            confirmFn: isConfirm => {
                isConfirm && _resetFilters();
            }
        })
    }

    function _hasFilters() {
        let hasFilters = false;
        _.each(vm.filtersSelectOptions, filterOption => {
            if (!_.isEmpty(filterOption.currentValues)) {
                hasFilters = true;
                return false;
            }
        });
        return hasFilters;
    }

    function _resetFilters() {
        vm.state.isLoadingPanel = true;

        ExportBuilderFilterService.resetGlobalFilters(angular.copy(vm.filtersSelectOptions))
            .finally(() => {
                vm.state.isLoadingPanel = false;
            });

        _.each(vm.filtersSelectOptions, filterOption => {
            filterOption.currentValues = [];
        });

        vm.state.resetFilters = true;
        $timeout(() => {
            vm.state.resetFilters = false;
        }, 0)
    }

    function _onNewGlobalFilter(globalFilter) {
        const filterOption = ExportBuilderFilterPanelModelFactory.getFilterOption(globalFilter);
        const report = ReportStudioTemplateDataService.getReport();
        const keyLength = filterOption.key.split('|').length
        if (report && ((report.metadata.filter_mode === FilterModeType.GLOBAL_FILTERS && keyLength === 3) || (report.metadata.filter_mode === FilterModeType.WIDGET_FILTERS && keyLength > 3))) {
            vm.filtersSelectOptions.push(filterOption);
        }
    }

    function _onGlobalFilterRemove(filterKey) {
        vm.state.resetFilters = true;
        vm.filtersSelectOptions = vm.filtersSelectOptions.filter(filterSelectOption => filterSelectOption.key !== filterKey)
        $timeout(() => {
            vm.state.resetFilters = false;
        }, 0);
    }

    function _onGlobalFilterApply() {
        applyFiltersClicked();
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.ON_NEW_GLOBAL_FILTER_ADDED, _onNewGlobalFilter);
        PubSub.on($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_REMOVED, _onGlobalFilterRemove)
        PubSub.on($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_APPLY, _onGlobalFilterApply);
        PubSub.on($ExportBuilderDashboardEvents.ON_REMOVE_ALL_FILTERS, _onReportFilterModeUpdate);
        PubSub.on($ExportBuilderDashboardEvents.UPDATE_GLOBAL_FILTER_OPTIONS, reloadFiltersOptions);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.ON_NEW_GLOBAL_FILTER_ADDED, _onNewGlobalFilter);
        PubSub.off($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_REMOVED, _onGlobalFilterRemove);
        PubSub.off($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_APPLY, _onGlobalFilterApply);
        PubSub.off($ExportBuilderDashboardEvents.ON_REMOVE_ALL_FILTERS, _onReportFilterModeUpdate);
        PubSub.off($ExportBuilderDashboardEvents.UPDATE_GLOBAL_FILTER_OPTIONS, reloadFiltersOptions);
    }
}
