import { color } from '@/modules/core/charts/am5/charts.helper';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { useLegend as useGenericLegend } from '@/modules/core/charts/am5/base/composables/useLegend';

export function useLegend(context) {
  const { config, chart, isDarkTheme } = context();
  const { generateInitialLegend } = useGenericLegend(context);

  function createLegend() {
    const { isStacked } = config.value;
    const legend = generateInitialLegend({}, true);
    addLegendEvents(legend);
    legend.data.setAll(
      isStacked ? chart.value.series.values.slice().reverse() : chart.value.series.values
    );
  }

  function addLegendEvents(
    legend,
    restoreAllSeries = _restoreAllSeries,
    applyGreyOutOptions = _applyGreyOutOptions
  ) {
    // When legend item container is hovered, dim all the series except the hovered one
    legend.itemContainers.template.events.on(Constant.POINTER_OVER, (e) => {
      const itemContainer = e.target;
      const series = itemContainer.dataItem.dataContext;
      if (series.isVisible()) {
        highlightSeries(series, applyGreyOutOptions);
      }
    });

    legend.itemContainers.template.events.on(Constant.POINTER_OUT, (e) => {
      const itemContainer = e.target;
      const series = itemContainer.dataItem.dataContext;
      if (series.isVisible()) {
        restoreAllSeries();
      }
    });

    legend.itemContainers.template.events.on(Constant.CLICK, (e) => {
      const itemContainer = e.target;
      const series = itemContainer.dataItem.dataContext;
      if (series.isVisible()) {
        restoreAllSeries();
      }
    });
  }

  function highlightSeries(series, applyGreyOutOptions) {
    chart.value.series.each((chartSeries) => {
      if (chartSeries !== series) {
        applyGreyOutOptions(chartSeries);
      }
    });
  }

  function _applyGreyOutOptions(series) {
    const { fill } = series.get(Constant.USER_DATA);
    series.strokes.template.setAll({
      stroke: isDarkTheme.value ? color(0xffffff) : color(0x000000),
      strokeWidth: 1,
      strokeOpacity: 0.3,
    });
    if (fill) {
      series.fills.template.setAll({
        fillOpacity: 0,
      });
    }
    series.bulletsContainer.children.each((bullet) => {
      bullet.hide();
    });
  }

  function _restoreAllSeries() {
    chart.value.series.each((series) => {
      const {
        fill,
        fillOpacity,
        strokeWidth,
        strokeOpacity,
        color: seriesColor,
      } = series.get(Constant.USER_DATA);

      series.strokes.template.setAll({
        stroke: color(seriesColor),
        strokeWidth,
        strokeOpacity,
      });
      if (fill) {
        series.fills.template.setAll({
          fillOpacity,
        });
      }
      series.bulletsContainer.children.each((bullet) => {
        bullet.show();
      });
    });
  }

  return {
    createLegend,
    addLegendEvents,
  };
}
