"use strict";
import $ from 'jquery';

var Core = $.core.reporting.profiles = {
    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {
    },
    events: function () {
        this.deleteBindings();
    },
    deleteBindings: function () {
        $('#report-table a.delete-action').click(function(e){
            //Set the url for row delete action
            $(this).data('url', '/app/dash/reportingProfile/delete_reporting_profile/reporting_profile_id/' + $(this).data('profileId') + '/');

            $.core.datatable.deleteRow($(this), $(this).data('name'));
        });
    }
};