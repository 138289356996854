import {
  LineSeries,
  FunnelSeries,
  PyramidSeries,
  PieSeries,
  PieSeries3D,
  ColumnSeries,
  ColumnSeries3D,
} from '@amcharts/amcharts4/charts';
import { VennSeries } from '@amcharts/amcharts4/.internal/plugins/venn/VennSeries';
import { color } from '@amcharts/amcharts4/core';
import { SeriesType } from '@/modules/core/charts/chart.constants';
import { NumberFormatter, DurationFormatter } from '@amcharts/amcharts5';
import {
  LABEL_DURATION_FORMAT,
  TOOLTIP_DURATION_FORMAT,
  TOOLTIP_NUMBER_FORMAT,
  ValueFormatters,
  ValueSymbol,
} from '@/modules/core/charts/am5/charts.constants';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export const getSeriesType = (type) => {
  switch (type) {
    case SeriesType.BAR:
      return ColumnSeries;
    case SeriesType.BAR_3D:
      return ColumnSeries3D;
    case SeriesType.LINE:
      return LineSeries;
    case SeriesType.PYRAMID:
      return PyramidSeries;
    case SeriesType.FUNNEL:
      return FunnelSeries;
    case SeriesType.PIE:
      return PieSeries;
    case SeriesType.PIE_3D:
      return PieSeries3D;
    case SeriesType.VENN:
      return VennSeries;
    default:
      return null;
  }
};

export function getFormattedValue(root, value, columnType, tooltipFormat = false) {
  if (columnType === ColumnFormat.FORMAT_TIME) {
    const durationFormatter = DurationFormatter.new(root.value, {
      durationFormat: tooltipFormat ? TOOLTIP_DURATION_FORMAT : LABEL_DURATION_FORMAT,
    });
    value = value ? durationFormatter.format(value) : '00:00';
    return value;
  }
  const numberFormat = tooltipFormat ? TOOLTIP_NUMBER_FORMAT : getNumberFormat(value);
  const suffix = columnType === ColumnFormat.FORMAT_PERCENT ? ValueSymbol[columnType] : '';
  const numberFormatter = NumberFormatter.new(root.value, {
    numberFormat,
  });
  value = value ? numberFormatter.format(value) : '0';
  return `${value}${suffix}`;
}

export function getNumberFormat(value, tooltipFormat = false) {
  if (value < 0.0001) {
    return ValueFormatters.TOO_SMALL_NUMBER;
  }
  if (value < 0.1) {
    return ValueFormatters.SMALL_NUMBER;
  }
  if (value < 1000) {
    return ValueFormatters.MULTIPLE_PRECISION;
  }
  return tooltipFormat ? TOOLTIP_NUMBER_FORMAT : ValueFormatters.SINGLE_PRECISION;
}

/**
 * Wrapper for amcharts color function.
 *
 * @param value  Input value
 * @param alpha  Alpha (0-1)
 * @return Color object
 */
export function applyColor(value, alpha) {
  try {
    return color(value, alpha);
  } catch (e) {
    Logger.log(e, Logger.LEVEL_ERROR);
  }
}
