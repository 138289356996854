'use strict';
import angular from 'angular';
import $ from 'jquery';
import moment from 'moment';
import _ from 'lodash';

angular.module('widget.slicechart.services', [])

    .factory('SliceChartFactory', SliceChartFactory);

/**
 * @ngInject
 */
function SliceChartFactory(
    $q,
    AppFactory,
    ChartFactory,
    ChartDataType,
    WidgetFactory,
    ColumnFormat,
    MomentDateFormat,
    TimeGrouping,
    WidgetType,
    DrawOption,
    gettextCatalog,
    AppModelFactory
) {

    /**
     * @param chartOptions
     * @param columns
     * @param widget
     */
    var init = function(chartOptions, columns, widget) {
        let metadata = widget.metadata;
        /**
         * Returns the appropriate value based if normalized or not
         * @param item
         */
        var getValue = function(item) {
            var dataContext = item.dataContext;
            var value = chartOptions.is_normalized ? dataContext.rawValue : item.value;
            if (item.dataContext.format === ColumnFormat.FORMAT_TIME) {
                return item.dataContext.dataContext[item.dataContext.valueField] ?? AppModelFactory.formatUnixTimeStamp(value);
            }
            return ChartFactory.formatLegendValue(value, dataContext.precision, dataContext.format, dataContext);
        };

        // default options for all parameters
        var defaultOptions = {
            alphaField: 'alpha',
            autoMargins: false,

            balloonFunction: function(item) {
                // Balloon should display full value
                var val = chartOptions.is_normalized ? item.dataContext.rawValue : item.value;
                var percent = $.fn.formatNumber(item.percents, 'percent');
                percent = chartOptions.is_normalized ? '' : ' (' + percent + ')';
                if (item.dataContext.format === ColumnFormat.FORMAT_TIME) {
                    const value = item.dataContext.dataContext[item.dataContext.valueField];
                    val = value ? value : AppModelFactory.formatUnixTimeStamp(val);
                    val = val + percent
                }
                else {
                    if (_.isEmpty(item.dataContext)) {

                        var firstColumn = _.first(columns.selected);
                        var allSameColumns = _.every(columns.selected, ['format', firstColumn.format]);
                        if (allSameColumns) { // Check if the column format/type is the same across all selected columns
                            val = $.fn.formatNumber(val, firstColumn.format , firstColumn.precision, false, firstColumn, metadata.currency_discrepancy, metadata.show_currency);
                        }
                        else { // Otherwise, display the percentage of the slice (no use in formatting potentially mixed metrics)
                            val = percent;
                        }

                    }
                    else {
                        val = $.fn.formatNumber(val, item.dataContext.format, item.dataContext.precision, false, item.dataContext.dataContext, metadata.currency_discrepancy, metadata.show_currency) + percent;
                    }
                }
                return '<span class="label" style="background-color:' + item.color + ';">' + item.title +  '</span> ' + val + ' <br>';
            },
            labelFunction: function(item) {
                // Account for dates.
                var val = chartOptions.is_normalized ? item.dataContext.rawValue : item.value;
                var percent = $.fn.formatNumber(item.percents, 'percent');
                percent = chartOptions.is_normalized ? '' : percent;
                if (item.dataContext.format === ColumnFormat.FORMAT_CURRENCY) {
                  /**
                   * Some strange behavior is happening when we select different dateranges
                   * and compare prior is enabled,
                   * for a few dateranges (past), current_period object has no data, only prior_period has data
                   * for a few other dateranges (future), it is vice-versa
                   */
                  if (item.dataContext.dataContext.current_period) {
                    if (
                      "client_currency" in
                      item.dataContext.dataContext.current_period
                    ) {
                      item.dataContext.dataContext.client_currency =
                        item.dataContext.dataContext.current_period.client_currency;
                    } else if (
                      "client_currency" in
                      item.dataContext.dataContext.prior_period
                    ) {
                      item.dataContext.dataContext.client_currency =
                        item.dataContext.dataContext.prior_period.client_currency;
                    }
                  }
                }
                if (item.dataContext.format === ColumnFormat.FORMAT_TIME) {
                    val = item.dataContext.dataContext[item.dataContext.valueField] ?? AppModelFactory.formatUnixTimeStamp(val);
                }
                else {
                    if (_.isEmpty(item.dataContext)) {

                        var firstColumn = _.first(columns.selected);
                        var allSameColumns = _.every(columns.selected, ['format', firstColumn.format]);
                        if (allSameColumns) { // Check if the column format/type is the same across all selected columns
                            val = $.fn.formatNumber(val, firstColumn.format , firstColumn.precision);
                            percent = percent;
                        }
                        else { // Otherwise, display the percentage of the slice (no use in formatting potentially mixed metrics)
                            val = '';
                        }

                    }
                    else {
                        val = $.fn.formatNumber(val, item.dataContext.format, item.dataContext.precision, false, item.dataContext.dataContext, metadata.currency_discrepancy, metadata.show_currency);
                    }
                }
                var name = item.dataContext.title || gettextCatalog.getString('Other');
                return formatLabel(chartOptions, widget, name, val, percent);
            },
            clickType: 'pullInSlice',
            groupedTitle: gettextCatalog.getString('Other'),
            hideLabelsPercent: 0, // Draw option candidate. Can fix problems with too many labels.
            labelPosition: chartOptions.chartType === WidgetType.FUNNELCHART ? 'right' : '',
            labelsEnabled: true,
            // Added labelRadius styling inorder label getting collided with the pie chart, it will resize dynamically, default set to 50
            labelRadius: labelRadius(metadata),
            linkedListeners: ['rollOverSlice', 'rollOutSlice', 'clickSlice'],
            marginTop: 20,
            marginBottom: 20,
            marginLeft: chartOptions.chartType === WidgetType.FUNNELCHART ? 10 : 20,
            marginRight: chartOptions.chartType === WidgetType.FUNNELCHART ? 150 : 20,
            maxLabelWidth: chartOptions.chartType === WidgetType.FUNNELCHART ? 140 : 200,
            neckHeight: '30%',
            neckWidth: '40%',
            outlineThickness: 0,
            pullDistance: 0,
            pulledField: 'pulled',
            pullOutRadius: 0,
            sequencedAnimation: false,
            startDuration: 0,
            type: chartOptions.chartType === WidgetType.FUNNELCHART ? ChartDataType.FUNNEL : ChartDataType.PIE,
        };

        var options = _.extend(chartOptions, defaultOptions);

        // Handle pull out function for when we expand the functionality of slice charts
        options.listeners['pullOutSlice'] = function(event) {
            var chart = event.chart;
            var dataContext = event.dataItem.dataContext;
            var subsetArr = dataContext.subset;
            var selected = dataContext.id;
            var isSubset = dataContext.isSubset;

            // If isSubset is false, we need to send a request to get grouped-by data
            // Otherwise it means we got grouped-by data and therefore no need to send
            // duplicate requests
            if (options.isAutoDrill) {
                formatSlice(options);
            }
            else {
                if (!isSubset) {
                    var chartIndex = -1; // set -1 to request grouped-by data for slice chart
                    $q.resolve(ChartFactory.getChartData(widget, options, chartIndex)).then(function(json) {
                        subsetArr = json.data;
                        formatSlice(options);
                    });
                }
            }

            function formatSlice(options) {
                if (!_.isEmpty(subsetArr) && subsetArr.length && !_.isEmpty(columns.grouped)) {
                    var chartData = [];
                    // Placeholder for drilling out of a chart
                    chart.oldDataProvider = chart.dataProvider;

                    // Chart will break dashboard if it tries to display too many slices.
                    if (subsetArr.length > 100 && (chart.groupPercent < 0.4)) {
                        chart.groupPercent = 0.4;
                    }

                    _.forEach(chart.dataProvider, function(dataItem, i) {
                        if (!_.isUndefined(dataItem.id) && dataItem.id == selected) {
                            subsetArr = options.isAutoDrill ? dataItem.subset : subsetArr;
                            _.forEach(subsetArr, function(subset, x) {
                                var title;
                                var groupedNameField = columns.grouped[chart.chartDataIndex].groupby_name_field;
                                var currentSubset = options.isAutoDrill ? dataItem.subset[x] : subset;
                                let grouped_column = columns.grouped[chart.chartDataIndex];
                                if (grouped_column.groupby_field_format === ColumnFormat.FORMAT_DATETIME) {
                                    if (!WidgetFactory.useSampleData(metadata) && (options.time_grouping === TimeGrouping.GROUPING_YEARLY
                                            || options.time_grouping === TimeGrouping.GROUPING_DYNAMIC
                                            || options.time_grouping === TimeGrouping.GROUPING_MONTHLY
                                            || options.time_grouping === TimeGrouping.GROUPING_HOURLY
                                            || options.time_grouping === TimeGrouping.GROUPING_HOURLY_ADVERTISER
                                            || options.time_grouping === TimeGrouping.GROUPING_HOURLY_AUDIENCE)) {
                                        title = currentSubset['formatted_' + groupedNameField];
                                    } else {
                                        if (WidgetFactory.useSampleData(metadata) && options.time_grouping === TimeGrouping.GROUPING_DYNAMIC) {
                                            options.dateFormats.momentFormat = MomentDateFormat.ISO;
                                        }
                                        title = moment.unix(currentSubset[groupedNameField]).isValid()
                                            ? moment.unix(currentSubset[groupedNameField]).utc().format(options.dateFormats.momentFormat)
                                            : currentSubset[groupedNameField];
                                    }
                                }
                                else if (grouped_column.format === ColumnFormat.FORMAT_ENUM) {
                                    title = currentSubset[groupedNameField + '_display'];
                                } else {
                                    title = currentSubset[groupedNameField] || gettextCatalog.getString('Unspecified');
                                }
                                var subsetValue = dataContext.format == ColumnFormat.FORMAT_TIME ?
                                    moment.duration(subset[dataContext.valueField]).asSeconds() :
                                    _.toNumber(subset[dataContext.valueField]);

                                var drillSlice = {
                                    title: title + (options.isAutoDrill ? '' : ' ' + dataItem.title),
                                    value: subsetValue,
                                    valueField: dataContext.valueField,
                                    color: subset.color || options.getPaletteColor(i + x + 1),
                                    pulled: true,
                                    precision: dataContext.precision,
                                    format: dataContext.format,
                                    dataContext: options.isAutoDrill ? dataItem.dataContext[x] : subset, // replace subset
                                    isSubset: true // set true to prevent sending duplicate requests
                                };

                                if (chart.is_normalized) {
                                    drillSlice.rawValue = drillSlice.value;
                                    drillSlice.value = 1;
                                }
                                chartData.push(drillSlice);
                            });
                        }
                        else {
                            chartData.push(dataItem);
                        }
                    });
                    if (chart.chartType === WidgetType.FUNNELCHART) {
                        chartData.sort(function (a, b) {
                            return b.value - a.value;
                        });
                    }
                    var selectedColumnsObj = options.isAutoDrill
                        ? null : AppFactory.arrayToMemoizedObj(columns.selected, 'field');
                    var indexCount = ChartFactory.updateColorPalette(chartData, options, selectedColumnsObj);
                    chart.groupedColor = options.getPaletteColor(indexCount + 1);
                    chart.dataProvider = chartData;

                    // Cached chart object for back button
                    if (!options.isAutoDrill) {
                        // Grab current title to pass to cache
                        var currTitle = options.titles.length ? [{
                            text: options.titles[0].text,
                            size: 14,
                            id: 'drilldown-title',
                            bold: false
                        }] : [];
                        // Grab current config to store in cache
                        var currentConfigObj = {
                            labels: options.allLabels,
                            title: currTitle,
                            chartDataIndex: options.chartDataIndex,
                            selected: _.extend({}, options.redrawOptions)
                        };
                        // If there is already drill down data for the specific chart, add to it
                        if (amplify.lookupCache('drill_down_data-' + options.chartId)) {
                            // Don't want to store the selectedObj (that is next configuration.) Need to store the previous config...
                            let previousCharts = amplify.getFromCache('drill_down_data-' + options.chartId);
                            previousCharts.push(currentConfigObj);
                            amplify.safeStore('drill_down_data-' + options.chartId, previousCharts);
                        }
                        else {
                            amplify.safeStore('drill_down_data-' + options.chartId, [currentConfigObj]);
                        }
                        chart.allLabels = ChartFactory.getBackButtonConfig(options.chartId, widget.type);
                    }
                    chart.validateData();
                }
            }

        };

        /* Undo pull out, and reduce number of slices
        * This functionality is buggy. Click slice, and pull out slice listeners are still thrown after, which will drill back into chart.
        * Need a different way to drill back in a slice chart
        */

        // options.listeners['rightClickSlice'] = function(event) {
        //     if (event.dataItem.pulled) {
        //         event.event.preventDefault();
        //         var chart = event.chart;
        //         _.each(chart.oldDataProvider, function(data) {
        //             data.pulled = false;
        //         });
        //         chart.dataProvider = chart.oldDataProvider;
        //         chart.validateData();
        //     }
        // };

        //
        // OPTION OVERRIDES
        //

        options.angle = options[DrawOption.ANGLE] || 0;
        options.balloon.enabled = options[DrawOption.HAS_TOOLTIP] ? true : false;
        // Divide depth by 2. Slice chart can enter limbo if it goes too deep.
        options.depth3D = options[DrawOption.DEPTH ]/ 2 || 0;
        options.groupPercent = _.isUndefined(options[DrawOption.OTHER_PERCENT]) ? 2 : options[DrawOption.OTHER_PERCENT];
        options.innerRadius = options[DrawOption.INNER_RADIUS ]+ '%' || 0;
        options.labelsEnabled = options[DrawOption.SHOW_LABELS];
        options.legend = options[DrawOption.HAS_LEGEND] ? options.legend : null;
        options.neckHeight = options[DrawOption.NECK_HEIGHT] || 0;
        options.neckWidth = options[DrawOption.NECK_WIDTH] || 0;
        options.startAngle = options[DrawOption.START_ANGLE] || 90;
        options.groupPercent = options[DrawOption.IS_NORMALIZED] ? 0 : options.groupPercent;
        if (options[DrawOption.IS_NORMALIZED]) {
            options.hideLabelsPercent = 0;
        }
        else {
            options.hideLabelsPercent = _.isUndefined(options[DrawOption.LABEL_PERCENT]) ? 0 : options[DrawOption.LABEL_PERCENT];
        }
        if (!_.isNull(options.legend)) {
            options.legend.valueFunction = function (item) {
                return getValue(item);
            };
        }

        // Dynamically calculate required value label maximum width
        // ref: https://www.amcharts.com/kbase/automatic-legend-value-width-pie-chart/
        AmCharts.addInitHandler(function(chart) {
            // find out the biggest value
            if (chart.legend) {
                var maxValue = 0;
                for (var x in chart.dataProvider) {
                    if (!chart.dataProvider.hasOwnProperty(x)) {
                        continue;
                    }

                    let item = chart.dataProvider[x];
                    let value;
                    if (item.dataContext.format === ColumnFormat.FORMAT_TIME) {
                        value = item.dataContext.dataContext[item.dataContext.valueField];
                    } else {
                        value = ChartFactory.formatLegendValue(item[chart.valueField], item.precision, item.format);
                    }
                    value = value.toString().length;
                    if (value > maxValue) {
                        maxValue = value;
                    }
                }

                // set valueWidth according to the biggest value
                if (12 <= maxValue) {
                    chart.legend.valueWidth = 300;
                } else if (6 <= maxValue) {
                    chart.legend.valueWidth = 250;
                }
            }
        }, ['pie']);

        ChartFactory.makeChart(options, columns);
    };

    /**
     * Helper function to format label
     * @param chartOptions
     * @param widget
     * @param name
     * @param val
     * @param percent
     * @returns {string}
     */
    function formatLabel(chartOptions, widget, name, val, percent) {
        var drawOptions = widget.metadata.draw_options;

        if (drawOptions[DrawOption.SHOW_LABEL_NAMES]) {
            // Truncate name if feasible
            if (name.length > 25) {
                name = name.substring(0, 20) + '...';
            }

            // Add ": " after name if feasible
            if (drawOptions[DrawOption.SHOW_LABEL_VALUES]
                || drawOptions[DrawOption.SHOW_LABEL_PERCENT]) {
                name += ': ';
            }
        }
        // Add parenthesis for percent if feasible
        if (!chartOptions.is_normalized
            && drawOptions[DrawOption.SHOW_LABEL_VALUES]
            && drawOptions[DrawOption.SHOW_LABEL_PERCENT]) {
            percent = ' (' + percent + ')';
        }

        var label = '';
        if (drawOptions[DrawOption.SHOW_LABEL_NAMES]) {
            label += name;
        }
        if (drawOptions[DrawOption.SHOW_LABEL_VALUES]) {
            label += val;
        }
        if (drawOptions[DrawOption.SHOW_LABEL_PERCENT]) {
            label += percent;
        }

        return label;
    }
    /* Adjusting Label Radius for Pie chart based upon the 3d effects like Depth and Angle */
    function labelRadius(metadata){
        let drawOptions = metadata.draw_options;
        if(drawOptions[DrawOption.DEPTH] >= 70 && drawOptions[DrawOption.ANGLE] >= 30){
            return 150;
        }else if(drawOptions[DrawOption.DEPTH] >= 70){
            return drawOptions[DrawOption.DEPTH]-30;
        }else{
            return 50;
        }
    }

    return {
        init: init,
        formatLabel: formatLabel,
        labelRadius: labelRadius
    }
}