export class ListState {
  /**
   * @var {object <ListDataSet>}
   */
  dataSets = {};

  /**
   * @var {object <ListParamsState>}
   */
  paramsState = {};

  /**
   * @var {object <UserListSettings>}
   */
  userListSettings = {};
}
