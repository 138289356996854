'use strict';
import angular from 'angular';
import './scripts/marginrule.ctrls';
import './scripts/marginrule.directives';
import './scripts/marginrule.services';

angular.module('marginrule', [
    'marginrule.ctrls',
    'marginrule.directives',
    'marginrule.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('marginrules', {
                parent:'layout',
                url: '/marginrules',
                controller: 'MarginRuleListController',
                templateUrl: AppConfig.MAGE_URL + 'margin/manage_margin_rules',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('marginrules')
                }
            })
            .state('marginrules.add', {
                parent:'layout',
                url: '/marginrules/add',
                data: { isNewEntity: true, primaryKey: 'margin_rule_id' },
                controller: 'MarginRuleController',
                templateUrl: AppConfig.MAGE_URL + 'margin/add_margin_rule'
            })
            .state('marginrules.detail', {
                parent:'layout',
                url: '/marginrules/detail/:id',
                controller: 'MarginRuleController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'margin/view_margin_rule/margin_rule_id/' + $stateParams.id;
                }
            })
    }]);