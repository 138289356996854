'use strict';
import angular from 'angular';
import $ from 'jquery';

import widgetResizeGridHtmlUrl from './widget.resizegrid.html';

angular.module('widget.resize.directives', [])
    .component('resizeWidgetGrid', {
        bindings: {
            widget: '<'
        },
        templateUrl: widgetResizeGridHtmlUrl,
        controller: ResizeWidgetGridController,
        controllerAs: 'vm'
    });

/**
 * Resize widget grid (note: this occurs at the layout level since
 * there is only one grid per layout and is positioned based on widget coordinates)
 * @ngInject
 */
function ResizeWidgetGridController(
    LayoutFactory,
    WidgetResizeService
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.isInitialized = false;
    vm.onBoxHoverIn = onBoxHoverIn;
    vm.onBoxHoverOut = onBoxHoverOut;
    vm.onBoxMouseUp = onBoxMouseUp;
    vm.getDimensionBoxes = getDimensionBoxes;
    vm.getPosition = getPosition;

    /**
     * Life cycle events
     */
    function $onInit() {
        WidgetResizeService.init(vm.widget);
        WidgetResizeService.onWidgetResize(onResize);
    }

    function $onDestroy() {
        WidgetResizeService.offWidgetResize(onResize);
    }

    /**
     * Event to receive when widget resize changes
     * @param isActive
     */
    function onResize(isActive) {
        vm.isInitialized = isActive;
    }

    function getPosition() {
        return WidgetResizeService.getResizeWidgetPosition();
    }

    function getDimensionBoxes() {
        return WidgetResizeService.getDimensionBoxes();
    }

    function onBoxHoverIn(dimensionBox) {
        WidgetResizeService.onHover(dimensionBox);
    }

    function onBoxHoverOut(dimensionBox) {
        WidgetResizeService.onHoverOut(dimensionBox);
    }

    function onBoxMouseUp(width, height) {
        WidgetResizeService.setResize(width, height);
    }
}