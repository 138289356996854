'use strict';
import angular from 'angular';
import $ from 'jquery';

import calculatorHtmlUrl from '../views/calculator.html';

angular.module('calculator.directives', [])
    .directive('calculator', calculator);

calculator.$inject = [];
function calculator() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            calcDatatype: '=',
            calcFormula: '=',
            calcMetrics: '=',
            message: '='
        },
        templateUrl: calculatorHtmlUrl,
        controller: function($scope) {
            $scope.activeMetric = true;
            $scope.buttons = [
                {value:'(', layout: 'col-xs-3', class: 'btn-primary', type: 'symbol', func: buttonSymbol},
                {value:')', layout: 'col-xs-3', class: 'btn-primary', type: 'symbol', func: buttonSymbol},
                {value:'CE', layout: 'col-xs-3', class: 'btn-primary', type: 'exe', func: buttonExecute},
                {value:'C', layout: 'col-xs-3', class: 'btn-primary', type: 'exe', func: buttonExecute},
                {value:'7', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'8', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'9', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'/', layout: 'col-xs-3', class: 'btn-primary', type: 'operand', func: buttonOperation},
                {value:'4', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'5', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'6', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'*', layout: 'col-xs-3', class: 'btn-primary', type: 'operand', func: buttonOperation},
                {value:'1', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'2', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'3', layout: 'col-xs-3', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'-', layout: 'col-xs-3', class: 'btn-primary', type: 'operand', func: buttonOperation},
                {value:'0', layout: 'col-xs-6', class: 'btn-default', type: 'number', func: buttonNumber},
                {value:'.', layout: 'col-xs-3', class: 'btn-default disabled', type: 'exe', func: buttonExecute},
                {value:'+', layout: 'col-xs-3', class: 'btn-primary', type: 'operand', func: buttonOperation}
            ]
            $scope.insertMetric = insertMetric;
            $scope.metricSelectOptions = {data: genMetrics($scope.calcMetrics), width: '100%', placeholder: 'Select a Data Category and View'};
            var parenCount = 0;
            var parenDeleteFlag = false;

            //Watch for changes to the length of the formula: enable or disable buttons based on formula state
            $scope.$watch('calcFormula.length', function(nV, oV) {
                var currentValue = $scope.calcFormula[nV - 1];
                var previousValue = $scope.calcFormula[oV - 1];
                if(nV !== oV || nV === 0){
                    if(currentValue && currentValue.type === 'symbol') {
                        updateButtonState('symbol', true);
                        if(currentValue.value === '(') {
                            updateButtonState('operand', false);
                            if(!parenDeleteFlag) {
                                updateParenCount(1);
                            }
                        }
                        if(currentValue.value === ')') {
                            if(!parenDeleteFlag) {
                                updateParenCount(-1);
                            }
                            updateButtonState('operand', true);
                            if(!checkParenCount()){
                                updateButtonState('symbol', false, ')');
                            }
                        }
                    }
                    else if(currentValue && currentValue.type === 'number') {
                        $scope.activeMetric = false;
                        if(!currentValue.value.contains('.')) {
                            updateButtonState('exe', true, '.');
                            updateButtonState('symbol', false, '(');
                            updateButtonState('operand', true);
                            if(currentValue.value === '0' && previousValue && previousValue.type !== 'number') {
                                updateButtonState('number', false);
                            }
                            if(checkParenCount()){
                                updateButtonState('symbol', true, ')');
                            }
                        }
                        if(previousValue && previousValue.value.contains('.') && !currentValue.value.contains('.')) {
                            previousValue.value += currentValue.value;
                            $scope.calcFormula.pop();
                            updateButtonState('exe', false, '.');
                            updateButtonState('symbol', false, '(');
                            updateButtonState('operand', true);
                            updateButtonState('number', true);
                            if(checkParenCount()){
                                updateButtonState('symbol', true, ')');
                            }
                        }
                        if(previousValue && currentValue.value === '0') {
                            if(!previousValue.value.contains('.')) {
                                if(previousValue.type === 'number'){
                                    previousValue.value += currentValue.value;
                                    $scope.calcFormula.pop();
                                }
                                updateButtonState('exe', true, '.');
                                updateButtonState('symbol', false);
                            }
                        }
                        if(previousValue && parseInt(currentValue.value) > 0) {
                            if(previousValue.type === 'number' && !previousValue.value.contains('.')) {
                                previousValue.value += currentValue.value;
                                $scope.calcFormula.pop();
                                updateButtonState('number', true);
                                updateButtonState('exe', false, '.');
                                updateButtonState('symbol', false, '(');
                                updateButtonState('operand', true);
                                if(checkParenCount()){
                                    updateButtonState('symbol', true, ')');
                                }
                            }
                        }
                    }
                    else if(currentValue && currentValue.type === 'operand') {
                        $scope.activeMetric = true;
                        updateButtonState('operand', false);
                        updateButtonState('number', true);
                        updateButtonState('exe', false, '.');
                        updateButtonState('symbol', true, '(');
                        updateButtonState('symbol', false, ')');
                    }
                    else if(currentValue && currentValue.type === 'variable') {
                        $scope.activeMetric = false;
                        updateButtonState('operand', true);
                        updateButtonState('number', false);
                        updateButtonState('exe', false, '.');
                        updateButtonState('symbol', false, '(');
                        if(checkParenCount()){
                            updateButtonState('symbol', true, ')');
                        }
                    }
                    else if(currentValue && currentValue.type === 'exe') {
                        if(currentValue.value === '.' && previousValue && !previousValue.value.contains('.')) {
                            previousValue.value += currentValue.value;
                            $scope.calcFormula.pop();
                            $scope.activeMetric = false;
                            updateButtonState('operand', false);
                            updateButtonState('number', true);
                            updateButtonState('exe', false, currentValue.value);
                            updateButtonState('symbol', false);
                        }
                    }
                    else {
                        $scope.activeMetric = true;
                        updateButtonState('operand', false);
                        updateButtonState('number', true);
                        updateButtonState('exe', false, '.');
                        updateButtonState('symbol', true, '(');
                        updateButtonState('symbol', false, ')');
                    }
                }
                parenDeleteFlag = false;
            })

            //Watch for changes in the metrics object: generate a new metrics select2
            $scope.$watch('calcMetrics', function(nV, oV){
                if(nV && nV !== oV){
                    $scope.metricVal = '';
                    $scope.metricSelectOptions = {data: genMetrics($scope.calcMetrics), width: '100%', placeholder: $scope.calcMetrics.length ? 'Select a Metric' : 'No Metrics Found'};
                }
            })

            //Button functions used to handle the execution of ng-clicks on the dom
            function buttonExecute(val) {
                if(val === 'C') {
                    $scope.activeMetric = true;
                    parenCount = 0;
                    $scope.calcFormula = [];
                }
                else if(val === 'CE') {
                    if($scope.calcFormula.length) {
                        if($scope.calcFormula[$scope.calcFormula.length-1].value === ')'){
                            updateParenCount(1);
                            parenDeleteFlag = true;
                        }
                        if($scope.calcFormula[$scope.calcFormula.length-1].value === '(') {
                            updateParenCount(-1);
                            parenDeleteFlag = true;
                        }
                    }
                    $scope.calcFormula.pop();
                }
                else {
                    $scope.calcFormula.push({type: "exe", value: val});
                }
            }

            function buttonNumber(val) {
                $scope.calcFormula.push({type: "number", value: val});
            }

            function buttonOperation(val) {
                $scope.calcFormula.push({type: "operand", value: val});
            }

            function buttonSymbol(val) {
                $scope.calcFormula.push({type: "symbol", value: val});
            }

            function checkParenCount() {
                return parenCount;
            }

            //Function used to generate the metrics object used by select2: filtered by formatMatch()
            function genMetrics(metrics) {
                if(metrics){
                    return $.map(metrics, function(metric) {
                        //Service metrics have the 'format' property
                        //Category metrics have the 'data_type' property
                        //Another place where consolidation of the property name space may have merit
                        return metric.hasOwnProperty('format') ? {id: metric.field, text: metric.label} : {id: metric.category_metric_id, text: metric.name};
                    })
                }
                else {
                    return [];
                }
            }

            //Used to insert the metric into the formula
            function insertMetric(val) {
                updateButtonState('symbol', false, '(');
                $scope.calcFormula.push({type: "variable", value: val.text, id: val.id})
            }

            //Function used to update the class property on the button objects in $scope.buttons by adding or removing 'disabled'
            function updateButtonState(type, active, buttons) {
                angular.forEach($scope.buttons, function(button, key){
                    //If no buttons were passed as params with the call, apply the 'active' state to all buttons of a particular 'type'
                    if(!buttons){
                        if(button.type === type) {
                            button.class = button.class.contains('disabled') ? (active ? button.class.slice(0, button.class.indexOf('disabled')) : (button.class) ) : (active ? button.class : button.class + ' disabled');
                        }
                    }
                    else{
                        angular.forEach(buttons, function(targetedButton){
                            if(button.value === targetedButton){
                                button.class = button.class.contains('disabled') ? (active ? button.class.slice(0, button.class.indexOf('disabled')) : (button.class) ) : (active ? button.class : button.class + ' disabled');
                            }
                        });
                    }
                })
            }

            function updateParenCount(update){
                parenCount += update;
            }

        }
    }
}