import angular from 'angular';
import workermanagerResource from './base/workermanager.resource';
import WorkerManagerService from './base/workermanager.services';
import WorkerManagerConstants from './workermanager.constants';

export default angular.module('core.workermanager', [
  workermanagerResource.name,
  WorkerManagerService.name,
  WorkerManagerConstants.name
]);
