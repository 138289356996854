import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { Dashboard } from '@/modules/ta/dashboard/models/Dashboard';

export class DashboardResource extends BaseResource {
  constructor() {
    super(RouteGroup.DASH);
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<Dashboard>}
   */
  async getItem(id, params = {}) {
    return super.getData(Dashboard, `${id}`, params);
  }

  /**
   * Construct the route for dashboard export.
   * @param downloadType
   * @param params
   * @returns {string}
   */
  buildExportUrl(downloadType, params) {
    let url = downloadType;
    if (params.pageId) {
      url += `/${params.pageId}`;
      if (params.layoutId) {
        url += `/${params.layoutId}`;
      }
    }
    return url;
  }
}

export default new DashboardResource();
