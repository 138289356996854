<template>
  <div class="circle-container" data-cy="circle-container">
    <section :class="{ 'is-comparing': hasComparison }">
      <div v-if="isLoadingValue">
        <p class="subtitle" data-cy="loader">
          <span>{{ $t('Loading...') }}</span>
        </p>
      </div>
      <div v-else>
        <div
          class="value-container"
          :style="[containerStyles, calculatedSize, borderStyles, radiantStyles]"
          data-cy="value-container"
          :title="emptyDataMessage"
        >
          <p class="title" :style="{ 'max-height': maxHeight }">
            <span
              :style="[
                fontStyles,
                getFontSizeStyle(DataFontMultiplier.MIN, DataFontMultiplier.MAX),
              ]"
              data-cy="value"
            >
              <b-tooltip
                :active="!!isFormattedEllipsisActive()"
                :label="isFormattedEllipsisActive()"
                type="is-dark"
                size="is-small"
              >
                <div class="overflow-value" ref="formattedTooltip">
                  {{ formattedValue }}
                </div>
              </b-tooltip>
            </span>
          </p>
          <p
            v-show="hasComparison"
            class="title"
            :class="comparisonValueClasses"
            :style="{ 'max-height': maxHeightForComparison }"
          >
            <span
              class="comparison-value"
              :style="[
                fontColorStyles,
                getFontSizeStyle(DeltaFontMultiplier.MIN, DeltaFontMultiplier.MAX),
              ]"
              data-cy="comparison-value"
            >
              {{ formattedComparisonValue }}
            </span>
          </p>
        </div>
        <div v-show="hasComparison" class="comparison-container m-t-2">
          <div
            v-show="hasComparison && showComparisonArrows"
            class="delta-value"
            :class="deltaValueClasses"
            :style="getFontSizeStyle(DeltaIconFontMultiplier.MIN, DeltaIconFontMultiplier.MAX)"
          >
            <svg
              v-if="isExportingReportStudio"
              :class="deltaIconClass"
              :style="getIconSizeStyle(DeltaIconFontMultiplier.MIN, DeltaIconFontMultiplier.MAX)"
            >
              <use xlink:href="" :href="`#${deltaIconClass}`"></use>
            </svg>
            <b-icon
              v-else
              pack="fa"
              :custom-class="iconClass"
              class="m-r-4"
              data-cy="delta-icon"
            ></b-icon>
            <span data-cy="delta-value">{{ formattedDeltaValue }}</span>
            <span v-if="!compareAsValue">%</span>
          </div>
        </div>
      </div>
    </section>
    <p
      class="subtitle m-b-0"
      :style="[
        getFontSizeStyle(LabelFontMultiplier.MIN, LabelFontMultiplier.MAX),
        wrapMetricNameStyle,
      ]"
      data-cy="label"
      :class="showMetricName"
    >
      {{ label }}
    </p>
  </div>
</template>

<script>
import { ScoreCardMixin } from '@/modules/core/scorecard/mixins/ScoreCardMixin';
import {
  DataFontMultiplier,
  DeltaFontMultiplier,
} from '@/modules/core/scorecard/scorecard.constants';

export default {
  name: 'CircleCard',
  mixins: [ScoreCardMixin],
  computed: {
    calculatedSize() {
      return { width: `${this.wrapSize}px`, height: `${this.wrapSize}px` };
    },
    maxHeight() {
      return this.getFontSizeStyle(DataFontMultiplier.MIN, DataFontMultiplier.MAX).fontSize;
    },
    maxHeightForComparison() {
      return this.getFontSizeStyle(DeltaFontMultiplier.MIN, DeltaFontMultiplier.MAX).fontSize;
    },
    wrapMetricNameStyle() {
      const style = {};
      if (this.wrapMetricName) {
        if (this.fontSize <= 30) {
          style['max-width'] = '130px';
        } else if (this.fontSize < 50) {
          style['max-width'] = '230px';
        } else if (this.fontSize < 80) {
          style['max-width'] = '330px';
        } else if (this.fontSize < 120) {
          style['max-width'] = '450px';
        } else {
          style['max-width'] = '600px';
        }
      }
      return style;
    },
  },
};
</script>

<style scoped lang="scss">
.circle-container {
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);
  @include align-content(center);

  p.title {
    font-weight: 500;
  }
}

.value-container {
  @include border-radius(50%);
  text-align: center;
  justify-content: center;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.is-comparing {
  .subtitle {
    font-size: 14px;
  }

  .delta-value {
    font-size: 12px;
    margin-left: 10px;

    &.neutral-delta {
      color: $grey;
    }

    &.positive-delta {
      color: $green;
    }

    &.negative-delta {
      color: $red;
    }
  }
}

.comparison-value {
  opacity: 0.7;
}

.b-tooltip {
  display: block;
}

.overflow-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
