"use strict";
import angular from "angular";
import $ from "jquery";

angular
  .module("cluster.ctrls", [])

  .controller("ClusterListController", ClusterListController)
  .controller("ClusterController", ClusterController)
  .controller("ClusterServicesController", ClusterServicesController);

//
// Cluster grid controller
//
ClusterListController.$inject = ["$scope", "ClusterService"];
function ClusterListController($scope, ClusterService) {
  $scope.$evalAsync(function() {
    var options = $.core.datatable.defaultOptions("business unit");
    options.iActions = 1;
    $.core.datatable.build($("#cluster-table"), options);
  });
}

//
// Cluster controller
//
ClusterController.$inject = ["$scope", "ClusterService", "$timeout"];
function ClusterController($scope, ClusterService, $timeout) {
  $timeout(
    function() {
      var options = $.core.datatable.defaultOptions(
        "data source connection access"
      );
      options.iActions = 1;
      $.core.datatable.build($("#connection-table"), options);
    },
    0,
    false
  );

  $("#type").change(function() {
    var showMemberClusters = $(this).val() == "group";

    if (showMemberClusters) {
      $("#clusters-list").show();
      $("#whitelabel-div").hide();
      $("#logos-section-disable").hide();
      $("#inherited_whitelabel_cluster_id ").prop("selectedIndex", 0);
    } else {
      $("#clusters-list").hide();
      $("#whitelabel-div").show();
    }
  });
}

//
// Cluster services controller
//
ClusterServicesController.$inject = ["$scope", "ClusterService"];
function ClusterServicesController($scope, ClusterService) {}
