import { DataSource } from '@/modules/core/datasource/models/DataSource';
import { SelectedEntity } from '@/modules/ta/dashboard/models/SelectedEntity';
import { LayoutDrawOption } from '@/modules/ta/dashboard/models/LayoutDrawOption';

export class LayoutMetadata {
  /**
   * @var {DataSource|DataSource[]}
   */
  data_source;

  /**
   * @var {string}
   */
  description;

  /**
   * @var {object}
   */
  draw_options = {};

  /**
   * @var {boolean}
   */
  is_packed;

  /**
   * @var {SelectedEntity}
   */
  selected_entity;

  /**
   * @var {array}
   */
  tags;

  /**
   * @var {object}
   */
  thumbnail_metadata;

  /**
   * @param model
   */
  constructor(model = {}) {
    this.data_source = model.data_source;
    if (model.data_source instanceof Array) {
      this.data_source = model.data_source.map((dataSource) => new DataSource(dataSource));
    } else if (model.data_source instanceof Object) {
      this.data_source = new DataSource(model.data_source);
    }
    this.description = model.description;
    this.draw_options = new LayoutDrawOption(model.draw_options);
    this.is_packed = model.is_packed;
    this.selected_entity = new SelectedEntity(model.selected_entity || {});
    this.tags = model.tags;
    this.thumbnail_metadata = model.thumbnail_metadata;
  }
}
