import { render, staticRenderFns } from "./RectangleCard.vue?vue&type=template&id=9e49aac8&scoped=true&"
import script from "./RectangleCard.vue?vue&type=script&lang=js&"
export * from "./RectangleCard.vue?vue&type=script&lang=js&"
import style0 from "./RectangleCard.vue?vue&type=style&index=0&id=9e49aac8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e49aac8",
  null
  
)

export default component.exports