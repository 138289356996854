import { assign } from 'lodash';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class GenericModel extends BaseModel {
  constructor(model = {}) {
    super(model);

    assign(this, model);
  }
}
