'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('client.ctrls', [])

    .controller('ClientListController', ClientListController)
    .controller('ClientController', ClientController)
    .controller('ClientDetailsDashController', ClientDetailsDashController);

//
// Client grid controller
//
/**
 * @ngInject
 */
function ClientListController(ClusterService, $rootScope, $injector) {
    if ($rootScope.user.isIORoute) {
        var UserService = $injector.get('UserService');
        var init = function() {
            ClusterService.initCluster($rootScope.userRole, 'hidden');
        };

        if ($rootScope.userRole && $rootScope.userRole.id) {
            init();
        }
        else {
            UserService.getCurrentUserRole().then(function () {
                $rootScope.userRole = UserService.userRole;
                init();
            }, function () {
            });
        }
    }
}

//
// Client controller
//
function ClientController() {
    $.core.admin.client.initialize();

    $('#reporting_status').change(function() {
        $.core.main.needsConfirmation = $('#old_reporting_status').val() != '' && $('#old_reporting_status').val() != $('#reporting_status').val() && $('#reporting_status').val() == $('#terminated_reporting_status').val();
    });
}

//
// Client dash controller
//
ClientDetailsDashController.$inject = [];

function ClientDetailsDashController() {
    function init() {
        // $scope.$on('design:page:callback', function() {
        // UNCOMMENT TO INSERT LOGIC AFTER DESIGN IS RENDERED
        // });

        // Hide view as client in dash filters since we are viewing an actual client
        $('#dash-view-as-client-filter').hide();
        $('#specific-client-info').hide();
    }

    init();
}