'use strict';
import angular from 'angular';
import _ from 'lodash';
import { LoadingState} from 'coreModules/design/widget/design.widget.constants';

angular.module('widget.chart.directives', [])

    .directive('chartWidget', chartWidget);

/**
 * @ngInject
 */
function chartWidget(
  DesignFactory,
  ExportFactory,
  AppFactory,
  $timeout
) {
    return {
        restrict: 'A',
        scope: {
            state: '=',
            widget: '<',
            widgetTypeId: '<',
            chartId: '<',
            canDrill: '=',
            isRotated: '='
        },
        controller: 'ChartWidgetController',
        link: function(scope, el) {
            if (DesignFactory.getIsExportingPage()) {
                scope.$watch('state.loadingState', function (nV) {
                    $timeout(function (){
                        if (!_.isNull(nV)) {
                            switch (nV) {
                                case LoadingState.HAS_ERROR:
                                case LoadingState.NO_RECORDS:
                                case LoadingState.NO_DATA:
                                case LoadingState.NO_DATA_ACTIONABLE:
                                case LoadingState.ALL_EMPTY_DATA:
                                case LoadingState.NO_COLUMNS:
                                case LoadingState.TOO_MUCH_DATA:
                                case LoadingState.INACTIVE:
                                case LoadingState.ERROR:
                                case LoadingState.BUBBLE_CHART_REQUIRED_METRICS:
                                    $timeout(function (){
                                        ExportFactory.chartReadyCounter.decRef();
                                    }, 0);
                                    break;
                                default :
                                    break;
                            }
                        }
                    }, 0);
                });
            }
        }
    }
}