import angular from 'angular';
import $ from 'jquery';
import { AppConstants } from 'coreModules/shared/scripts/app.constants';

angular.module('core.util.services', [])
    .constant('$FullScreenEvents', {
        ENTER_FULL_SCREEN: '$FullScreenEvents:ENTER_FULL_SCREEN',
        EXIT_FULL_SCREEN: '$FullScreenEvents:EXIT_FULL_SCREEN'
    })
    .factory('WindowUtilUIService', WindowUtilUIService)
    .factory('SessionUtilService', SessionUtilService)
    .factory('FullScreenUtilUIService', FullScreenUtilUIService);

function _$WindowUtilUIServiceEvents() {
    return {
        RESIZE: '$WindowUtilUIService:resize',
        VISIBILITY_CHANGE: '$WindowUtilUIService:VISIBILITY_CHANGE'
    }
}

/**
 * Utility window wrapper for AngularJS
 * @ngInject
 */
function WindowUtilUIService(
    $window,
    $document,
    PubSub,
    AppConfig
) {
    var scrollExceptions = null;
    var arrowKeyCodes = {37: 'LEFT_ARROW', 38: 'UP_ARROW', 39: 'RIGHT_ARROW', 40: 'DOWN_ARROW'};

    _init();

    return {
        disableScroll: disableScroll,
        enableScroll: enableScroll,
        onWindowResize: onWindowResize,
        offWindowResize: offWindowResize,
        onVisibilityChanged: onVisibilityChanged,
        offVisibilityChanged: offVisibilityChanged,
        navigateToNewApp: navigateToNewApp
    };

    function _init() {
        _listenForVisibility()
    }

    /**
     * Disables browser scroll
     */
    function disableScroll(exceptions) {
        scrollExceptions = exceptions;

        $window.addEventListener('DOMMouseScroll', _preventScrollDefault, false);
        $window.onwheel = _preventScrollDefault; // modern standard
        $window.onmousewheel = $document.onmousewheel = _preventScrollDefault; // older browsers, IE
        $window.ontouchmove  = _preventDefault; // mobile
        $document.bind('keydown', _preventDefaultForScrollKeys);
    }

    /**
     * Re-enables browser scroll
     */
    function enableScroll() {
        $window.removeEventListener('DOMMouseScroll', _preventScrollDefault, false);
        $window.onmousewheel = $document.onmousewheel = null;
        $window.onwheel = null;
        $window.ontouchmove = null;
        $document.onkeydown = null;
        $document.unbind('keydown', _preventDefaultForScrollKeys);
        scrollExceptions = null;
    }

    /**
     * Know when brower resizes
     * @param cb
     */
    function onWindowResize(cb) {
        if (!PubSub.get(_$WindowUtilUIServiceEvents().RESIZE).length) {
            $($window).bind('resize', _resizeHandler);
        }
        PubSub.on(_$WindowUtilUIServiceEvents().RESIZE, cb);
    }

    /**
     * Remove callback listener
     * @param cb
     */
    function offWindowResize(cb) {
        PubSub.off(_$WindowUtilUIServiceEvents().RESIZE, cb);
        if (!PubSub.get(_$WindowUtilUIServiceEvents().RESIZE).length) {
            $($window).unbind('resize', _resizeHandler);
        }
    }

    function onVisibilityChanged(cb) {
        PubSub.on(_$WindowUtilUIServiceEvents().VISIBILITY_CHANGE, cb);
    }

    function offVisibilityChanged(cb) {
        PubSub.off(_$WindowUtilUIServiceEvents().VISIBILITY_CHANGE, cb);
    }

    function navigateToNewApp(path) {
      window.location = path ? AppConfig.NUI_PATH + "#/" + path : AppConfig.NUI_PATH;
    }

    /**
     * @private
     */
    function _resizeHandler() {
        PubSub.emit(_$WindowUtilUIServiceEvents().RESIZE, null, true);
    }

    /**
     * @private
     */
    function _visibilityHandler(visible) {
        PubSub.$emit(_$WindowUtilUIServiceEvents().VISIBILITY_CHANGE, visible);
    }

    function _listenForVisibility() {
        let visible = null;

        function focused() {
            if (visible === null || visible === false) {
                _visibilityHandler(visible = true);
            }
        }

        function unfocused() {
            if (visible === null || visible === true) {
                _visibilityHandler(visible = false);
            }
        }

        // Standards:
        if ('hidden' in document) {
            document.addEventListener('visibilitychange',
                function() {(document.hidden ? unfocused : focused)()});
        } else if ('mozHidden' in document) {
            document.addEventListener('mozvisibilitychange',
                function() {(document.mozHidden ? unfocused : focused)()});
        } else if ('webkitHidden' in document) {
            document.addEventListener('webkitvisibilitychange',
                function() {(document.webkitHidden ? unfocused : focused)()});
        } else if ('msHidden' in document) {
            document.addEventListener('msvisibilitychange',
                function() {(document.msHidden ? unfocused : focused)()});
        } else if ('onfocusin' in document) {
            // IE 9 and lower:
            document.onfocusin = focused;
            document.onfocusout = unfocused;
        }
        window.onpageshow = window.onfocus = focused;
        window.onpagehide = window.onblur = unfocused;
    }

    /**
     * @param {Event} e
     * @private
     */
    function _preventScrollDefault(e) {
        e = e || $window.event;
        if (_canScroll(e)) {
            return;
        } else if (e.preventDefault) {
            e.preventDefault();
            e.returnValue = false;
        }

    }

    function _preventDefault(e) {
        e = e || $window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;
    }

    /**
     * @param {Event} e
     * @returns {boolean}
     * @private
     */
    function _preventDefaultForScrollKeys(e) {
        if (arrowKeyCodes[e.keyCode]) {
            _preventDefault(e);
            return false;
        }
    }

    function _canScroll(e) {
        var $element = $(e.target);
        return _containsClass($element);

        function _containsClass($element) {
            var canScroll = false;
            scrollExceptions.forEach(function (exception) {
                if ($element[0].className.contains(exception)) {
                    canScroll = true;
                }
            });
            return canScroll;
        }
    }
}

/**
 * Utility Service in order to go full screen
 * Checks browser compatibility
 * @ngInject
 */
function FullScreenUtilUIService(
    $FullScreenEvents,
    PubSub
) {

    var _element = document.documentElement;

    init();
    return {
        isFullScreen: isFullScreen,
        goFullScreen: goFullScreen,
        exitFullScreen: exitFullScreen,
        toggleFullScreen: toggleFullScreen
    };

    function init() {
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);

        function exitHandler() {
            if (document.webkitIsFullScreen === false) {
                return PubSub.$emit($FullScreenEvents.EXIT_FULL_SCREEN);
            }
            else if (document.mozFullScreen === false) {
                return PubSub.$emit($FullScreenEvents.EXIT_FULL_SCREEN);
            }
            else if (document.msFullscreenElement === false) {
                return PubSub.$emit($FullScreenEvents.EXIT_FULL_SCREEN);
            }

            if (document.webkitIsFullScreen === true) {
                return PubSub.$emit($FullScreenEvents.ENTER_FULL_SCREEN);
            }
            else if (document.mozFullScreen === true) {
                return PubSub.$emit($FullScreenEvents.ENTER_FULL_SCREEN);
            }
            else if (document.msFullscreenElement === true) {
                return PubSub.$emit($FullScreenEvents.ENTER_FULL_SCREEN);
            }
        }
    }

    /**
     * Helper function knowing if document is in full screen mode
     * @returns {boolean}
     */
    function isFullScreen() {
        return window.innerHeight === screen.height
    }

    /**
     * Request entire document to go to into full screen
     */
    function goFullScreen() {
        _openFullScreen();
    }

    /**
     * Request entire document to return to normal viewing mode
     */
    function exitFullScreen() {
        _closeFullScreen();
    }

    /**
     * Helper function in order to switch to or back from full screen
     */
    function toggleFullScreen() {
        if (isFullScreen()) {
            _closeFullScreen();
        } else {
            _openFullScreen();
        }
    }

    /**
     * Check browsers compatibility for entering full screen mode
     * @private
     */
    function _openFullScreen() {
        if (isFullScreen()) return;

        if (_element.requestFullscreen) {
            _element.requestFullscreen();
        } else if (_element.mozRequestFullScreen) { /* Firefox */
            _element.mozRequestFullScreen();
        } else if (_element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            _element.webkitRequestFullscreen();
        } else if (_element.msRequestFullscreen) { /* IE/Edge */
            _element.msRequestFullscreen();
        }
    }

    /**
     * Check browsers compatibility for exiting full screen mode
     * @private
     */
    function _closeFullScreen() {
        if (!isFullScreen()) return;

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }
    }
}

function SessionUtilService() {
    function getValue(key, returnExpirationDate = false) {
        const stringValue = window.sessionStorage.getItem(key);
        if (stringValue !== null) {
            const sessionValue = JSON.parse(stringValue);
            const expirationDate = new Date(sessionValue.expirationDate);
            if (expirationDate > new Date()) {
                return returnExpirationDate ? sessionValue.expirationDate : sessionValue.value;
            }
            window.sessionStorage.removeItem(key);
        }
        return null;
    }

    function setValue(key, id, expirationInMin = 1) {
        const expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
        const newValue = {
            value: id,
            expirationDate: expirationDate.toISOString(),
        };
        window.sessionStorage.setItem(key, JSON.stringify(newValue));
    }

    function hasValidUrl(url) {
        const img = new Image();
        img.src = url;
        return new Promise((resolve) => {
            img.onerror = () => resolve(false);
            img.onload = () => resolve(true);
        });
    }

    return {
        getValue: getValue,
        setValue: setValue,
        hasValidUrl: hasValidUrl,
    }
}