import { map } from 'lodash';
import { NavItem } from '@/modules/core/nav/models/NavItem';
import { AppNavKey } from '@/modules/core/app/constants/app.constants';

export class AppNavItem {
  /**
   * @var {string}
   */
  key;

  /**
   * @var {int}
   */
  index;

  /**
   * @var {string}
   */
  title;

  /**
   * @var {string}
   */
  subtitle;

  /**
   * @var {string}
   */
  icon;

  /**
   * @var {AppNavItem[]}
   */
  items;

  /**
   * @var {boolean}
   */
  disabled;

  constructor(model = {}) {
    this.key = model.key;
    this.index = model.index;
    this.title = model.title;
    this.subtitle = model.subtitle;
    this.icon = model.icon;
    this.items = map(model.items, (item) => new NavItem(item));
    this.disabled = model.disabled;
  }

  /**
   * @returns {""|boolean}
   */
  isSkipped() {
    return this.key && [AppNavKey.CHECKLIST].includes(this.key);
  }
}
