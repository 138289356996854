'use strict';
import angular from 'angular';
import './scripts/dashboard.ctrls';
import './scripts/dashboard.directives';
import './scripts/dashboard.services';

import { Locale } from 'coreModules/shared/scripts/constants/language.constants';
import layoutHtmlUrl from './views/layout.html';
import Status404HtmlUrl from './views/404.html';

angular.module('analytics.shared', [
    'dashboard.ctrls',
    'dashboard.directives',
    'dashboard.services'
])
    
    .config(function($sceDelegateProvider) { 

        $sceDelegateProvider.resourceUrlWhitelist([
        // Allow same origin resource loads.
        'self',
        // Allow loading from our assets domain. **
        'https://cdnstaticassets.com/**'
        ]) ;
    })

    .config(['$stateProvider', 'AppConfig', function ($stateProvider, AppConfig) {

        $stateProvider
            .state('layout',{
                url: '',
                abstract: true,
                views: {
                    '': {
                        templateUrl: layoutHtmlUrl,
                        controller: 'AppController'
                    },
                    'nav@layout': {
                        templateUrl: AppConfig.MAGE_URL + 'layout/dashleftmenu',
                        controller: 'NavController'
                    },
                    'onboarding@layout': {
                        templateUrl: AppConfig.MAGE_URL + 'layout/onboarding',
                        controller: 'OnboardingController'
                    },
                    'mainmodals@layout': {
                        templateUrl: AppConfig.MAGE_URL + 'layout/mainmodals',
                        controller: 'MainModalsController'
                    },
                    'footer@layout': {
                        templateUrl: AppConfig.MAGE_URL + 'layout/footer',
                        controller: 'FooterController'
                    },
                    'globals@layout': {
                        templateUrl: AppConfig.MAGE_URL + 'session/globals'
                    }
                },
                resolve: {
                    navItems: function(NavMainResource) {
                        return NavMainResource.getMenuItems();
                    },
                    daterange: function(Restangular) {
                        return Restangular.one('daterange').one('legacy').get();
                    },
                    pages: function(DesignFactory) {
                        // Make sure to load design pages before so that they appear immediately in the nav menu
                        return DesignFactory.getNavPageList();
                    },
                    services: function(ServiceResource) {
                        // Since services are used app-wide, we resolve them at the app level
                        return ServiceResource.getServices({include_exclusive_live_services: 1});
                    },
                    dataTypes: function(ColumnFactory) {
                        // Since services are used app-wide, we resolve them at the app level
                        return ColumnFactory.dataTypes.get();
                    },
                    relativeDateRanges: function(DateRangeFactory) {
                        // Since relative date ranges are used app-wide, we resolve them at the app level
                        return DateRangeFactory.resource.get();
                    },
                    themeColors: function(UIFactory) {
                        // Since theme colors are used app-wide, we resolve them at the app level
                        return UIFactory.colors.get();
                    },
                    themeBaseColors: function(UIFactory) {
                        // Since theme colors are used app-wide, we resolve them at the app level
                        return UIFactory.baseColors.get();
                    },
                    brandMappings: function(Restangular) {
                        return Restangular.all('branding').one('mappings').get();
                    },
                    dataSourceTypes: function (WidgetFactory, WidgetTypeGrouping) {
                        return WidgetFactory.getMetadataColumnValues(WidgetTypeGrouping.DATASOURCED, null, {fields: 'data_source'})
                    },
                    _language: function (LoginFactory, UserFactory, LanguageService, gettextCatalog, AppFactory) {
                        let language = AppFactory.getUser().reportLanguage;
                        let userReportLanguage = _.isNil(language) || language === Locale.PLATFORM_DEFAULT ? Locale.EN : language.substring(0,2);
                        if (LoginFactory.isLoggedIn() && gettextCatalog.getCurrentLanguage() != userReportLanguage) {
                            LanguageService.setApplicationLanguage({locale: userReportLanguage});
                        }
                    }
                }
            })
            .state('home', {
                url: '/',
                onEnter: function($state, UserFactory, DesignFactory, UserLoginFactory, AppFactory, LoginFactory) {
                    // If we are exporting, we do not want there to be any extra calls or state changes
                    if (!DesignFactory.getIsExportingPage()) {
                        //Need to use UserFactory to get onBoarding state since $scope.user is not present at this point
                        UserFactory.isOnboarding().then(async function (json) {
                            if (json.data && json.data.data) {
                                $state.go('service.manage');
                            } else {
                                if (_.isEmpty(AppFactory.getUser())) {
                                    await LoginFactory.initUser();
                                }
                                var route = UserLoginFactory.getDefaultHomeRoute(AppFactory.getUser(), true);
                                if (route.state === 'home') {
                                    $state.go('overview');
                                } else {
                                    $state.go(route.state, route.params, route.options)
                                }
                            }
                        });
                    }
                }
            })
            .state('remote_login', {
                url: '/remote_login',
                onEnter: function ($state, LoginFactory) {
                    LoginFactory.remoteLogin();
                }
            })
            .state('supportrequest', {
                parent:'layout',
                url: '/supportrequest',
                templateUrl: AppConfig.MAGE_URL + 'session/initial_setup_support_request'
            })
            .state('404', {
                templateUrl: Status404HtmlUrl,
            })
    }]);