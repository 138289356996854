import ApiColumn from '@/modules/core/app/models/api/ApiColumn';
import { ListColumn } from '@/modules/core/list/models/ListColumn';
import { AdvancedFilterItem } from '@/modules/core/advancedfilter/models/AdvancedFilterItem';
import { FORM_INPUT_ENTER_KEY_EVENT } from '@/modules/core/form/form.constants';
import EventBus from '@/modules/core/app/helpers/EventBus';

export const FormInputMixin = {
  props: {
    column: { type: [ApiColumn, ListColumn, AdvancedFilterItem] },
    value: { type: [Object, String, Number, Boolean, Array] },
    horizontal: { type: Boolean, default: false },
    showAsInputOnFocusOnly: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    readOnlyTooltip: { type: String, default: null },
    readOnlyTooltipPosition: { type: String, default: 'is-right' },
    showLabel: { type: Boolean, default: true },
    placeholder: { type: String },
    label: { type: String },
    showTooltip: { type: Boolean, default: false },
    showSelectedCount: { type: Boolean, default: false },
    isRequiredOverride: { type: Boolean, default: null },
    name: { type: String },
    rules: { type: [String, Object] },
    ruleMode: {
      type: String,
      default: 'aggressive',
      validator: (mode) => ['aggressive', 'lazy', 'passive', 'eager'].includes(mode),
    },
    // Specifies the debounce for validation provider
    validateAfter: {
      type: Number,
      default: 0,
    },
    // Specifies the minimum number of characters allowed in the text area
    minLength: { type: Number, default: null },
    // Specifies the maximum number of characters allowed in the text area
    maxLength: { type: Number, default: null },
    // Tooltip to show for field, this will override tooltip in column
    tooltip: { type: String, default: null },
    // handle to number of digits to show after the decimal
    precision: { type: [Number, String], default: null },
    // the field should be validated immediately after render (initially).
    validateImmediately: { type: Boolean, default: false },
  },
  data() {
    return { newValue: this.value };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        if (value !== this.computedValue) {
          this.newValue = value;
          this.$emit('input', value);
        }
      },
    },
    formattedLabel() {
      return this.label || this.column?.label;
    },
    formattedTooltip() {
      return this.tooltip || this.column?.tooltip;
    },
    showRequiredAsterisk() {
      if (this.isRequiredOverride != null) {
        return this.isRequiredOverride;
      }
      return this.column?.is_required;
    },
    computedName() {
      return this.name || (this.column ? this.column.field : this.formattedLabel);
    },
    hasTooltip() {
      return !!this.formattedTooltip;
    },
    isTooltipActive() {
      return this.showTooltip && this.hasTooltip;
    },
    tooltipMessage() {
      return this.showTooltip && this.column ? this.column.possible_discrepancy_warning : '';
    },
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set internal value.
     */
    value(value) {
      if (this.type === 'number' && this.precision) {
        value = +Number(value).toFixed(Number(this.precision));
      }
      this.newValue = value;
    },
  },
  methods: {
    getClasses({ errors = [] }) {
      return errors.length ? ['has-border-danger'] : [];
    },
    hasValidationErrors({ errors = [] }) {
      return !!errors.length;
    },
    onEnter(...args) {
      this.$emit('on-enter', ...args);
      EventBus.signal(FORM_INPUT_ENTER_KEY_EVENT);
    },
    onKeyUp(...args) {
      this.$emit('on-key-up', ...args);
    },
    onBlur(...args) {
      this.$emit('on-blur', ...args);
    },
  },
};
