'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import layoutHtmlUrl from './layout.html';
import {$WidgetBuilderEvents} from "coreModules/design/widget/builder/widget.builder.constants";

angular.module('design.layout.directives', [])

    .component('designLayout', {
        templateUrl: layoutHtmlUrl,
        bindings: false,
        controllerAs: 'vm',
        replace: true,
        controller: LayoutController
    })
    .directive('resetDelayedAnimation', resetDelayedAnimation);

/**
 * @ngInject
 */
function LayoutController(
    PubSub,
    $LayoutEvents,
    DesignFactory,
    LayoutFactory,
    EditLayoutFactory,
    SlidePanelFactory,
    DashboardFilterPanelFactory,
    ExportBuilderDashboardService,
    WidgetResizeService,
    WidgetType,
    PageEntity,
    WidgetGoalModelFactory
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onDestroy = $onDestroy;
    vm.layoutIsPacked = layoutIsPacked;
    vm.layoutIsPredefined = layoutIsPredefined;
    vm.toggleResizeGrid = toggleResizeGrid;
    vm.triggerResize = triggerResize;
    vm.getWidgetClass = getWidgetClass;
    vm.canResizeWidgets = () => !_.includes([PageEntity.PAGE_OVERVIEW, PageEntity.PAGE_CATEGORIES_OVERVIEW], DesignFactory.getCurrentPage().entity);

    vm.resizingWidget = null;

    function $onInit() {
        _registerEvents();
        vm.layout = LayoutFactory;
        vm.editLayout = EditLayoutFactory.shared;
        vm.exportBuilder = ExportBuilderDashboardService.getBuilder();
        vm.isPredefined = DesignFactory.getCurrentLayout().is_predefined;
        // Run this once widgets have been loaded inside layout
        LayoutFactory.updateLayoutWidgets();
        WidgetResizeService.onWidgetResize(_onWidgetResize);
    }

    function $postLink() {

    }

    function $onDestroy() {
        _unregisterEvents();
        SlidePanelFactory.closeAll();
        DashboardFilterPanelFactory.resetWidgetFilterOptionList();
        WidgetResizeService.offWidgetResize(_onWidgetResize);
    }

    function layoutIsPacked() {
        return DesignFactory.getCurrentLayout().metadata.is_packed;
    }

    function layoutIsPredefined() {
        return DesignFactory.getCurrentLayout().is_predefined;
    }

    function triggerResize(widget) {
        vm.resizingWidget = widget;
    }

    function getWidgetClass(widget) {
        var isotopeClassList = [widget.type + '-type-widget', (widget.metadata.data_source ? widget.metadata.data_source.type : null)];

        if (widget.type === WidgetType.GOAL && widget.metadata.dynamic.predefined_data) {
            isotopeClassList.push(WidgetGoalModelFactory.getGoalWidget(widget.metadata.dynamic.predefined_data.data[0]).getIsotopeClass());
        }

        return isotopeClassList.join(' ');
    }

    function toggleResizeGrid() {
        return vm.resizingWidget && WidgetResizeService.getIsActive();
    }

    /**
     * Receive reactive state changes from WidgetResizeService
     * @param active
     * @private
     */
    function _onWidgetResize(active) {
        if (!active && vm.resizingWidget) { // went resizing to not resizing
            vm.resizingWidget = null;
        }
    }

    /**
     * @param widget
     * @private
     */
    function _updateBuilderWidget(widget) {
        LayoutFactory.updateWidget(widget);
    }

    /**
     * @param widget
     * @private
     */
    function _addBuilderWidget(widget) {
        LayoutFactory.addWidget(widget);
    }

    /**
     * @param params
     * @private
     */
    function _swapBuilderWidget(params) {
        LayoutFactory.swapWidget(params.id, params.model);
    }

    /**
     * @param widgetId
     * @private
     */
    function _removeBuilderWidget(widgetId) {
        LayoutFactory.removeWidgetById(widgetId);
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetBuilderEvents.UPDATE_WIDGET, _updateBuilderWidget);
        PubSub.on($WidgetBuilderEvents.DID_SAVE_WIDGET, _updateBuilderWidget);
        PubSub.on($WidgetBuilderEvents.ADD_WIDGET, _addBuilderWidget);
        PubSub.on($WidgetBuilderEvents.SWAP_WIDGET, _swapBuilderWidget);
        PubSub.on($WidgetBuilderEvents.REMOVE_WIDGET, _removeBuilderWidget);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetBuilderEvents.UPDATE_WIDGET, _updateBuilderWidget);
        PubSub.off($WidgetBuilderEvents.DID_SAVE_WIDGET, _updateBuilderWidget);
        PubSub.off($WidgetBuilderEvents.ADD_WIDGET, _addBuilderWidget);
        PubSub.off($WidgetBuilderEvents.SWAP_WIDGET, _swapBuilderWidget);
        PubSub.off($WidgetBuilderEvents.REMOVE_WIDGET, _removeBuilderWidget);
    }
}

/**
 * @ngInject
 */
function resetDelayedAnimation(
    $timeout,
    PubSub,
    $LayoutEvents
) {
    return {
        restrict: 'A',
        scope: false,
        link: ($scope, $el) => {
            if ($($el).has('.layout-freeflow').length) {
                return;
            }
            $scope.$on('$destroy', function () {
                PubSub.off($LayoutEvents.IS_CHANGING_LAYOUT, layoutChanged);
                PubSub.off($LayoutEvents.CHANGED, layoutChanged);
            });

            let timeoutPromise = null;

            reset();

            PubSub.on($LayoutEvents.IS_CHANGING_LAYOUT, layoutChanged);
            PubSub.on($LayoutEvents.CHANGED, layoutChanged);

            function layoutChanged() {
                $el.css('animation-fill-mode', ''); // remove inline style
                $el.css('transform', ''); // remove inline style
                $el.css('opacity', 0);
                reset();
            }

            /**
             * Stops execution of the requested timeout function
             */
            function cancel() {
                $timeout.cancel(timeoutPromise);
            }

            /**
             * Re-triggers reset css
             */
            function reset() {
                cancel();
                timeoutPromise = $timeout(() => {
                    $el.css('opacity', 1);
                    $el.css('animation-fill-mode', 'initial');
                    $el.css('transform', 'initial');
                }, 1000, false);
            }
        }
    }
}