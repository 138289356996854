/**
 * Checks if percentage of target data point is smaller to given threshold
 * @param target
 * @param threshold
 * @returns {boolean}
 */
export const isSmallPercentageInSeries = (target, threshold) =>
  target.dataItem.values.value.percent < threshold;

/**
 * This helps to append % as suffix for a label
 * @param text
 * @returns {string}
 */
export const appendPercentageInLabel = (text) => `${text}%`;
