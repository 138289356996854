import { map } from 'lodash';
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { AppNavItem } from '@/modules/core/nav/models/AppNavItem';
import { NavItem } from '@/modules/core/nav/models/NavItem';
import { DataSourceNavItem } from '@/modules/core/datasource/models/DataSourceNavItem';
import DashboardNavItem from '@/modules/ta/dashboard/models/DashboardNavItem';
import { NavGroup } from '@/modules/core/nav/nav.constants';

class NavResource {
  /**
   * @returns {Promise<Array>}
   */
  async getAppNavItems() {
    const items = await apiAxios.get('/nav/app');
    return map(items, (item) => new AppNavItem(item));
  }

  /**
   * @param groupKey
   * @returns {Promise<Array>}
   */
  async getSubItems(groupKey) {
    const items = await apiAxios.get(`/nav/list/${groupKey}`);
    return map(items, (item) => {
      if (item.group_key === NavGroup.DRILLDOWNS) {
        return new DataSourceNavItem(item);
      }
      if (item.group_key === NavGroup.DASHBOARDS) {
        return new DashboardNavItem(item);
      }
      return new NavItem(item);
    });
  }
}

export default new NavResource();
