/**
 * @class Hand
 */
export class Hand {
  /**
   * @var {String/Number}
   */
  value;

  /**
   * Disables the pin on hand
   * @var {Boolean}
   * @default false
   */
  pinDisabled;

  /**
   * Color of the hand
   * @var  {Color}
   */
  fill;

  /**
   * Color of the stroke
   * @var {Color}
   */
  stroke;

  /**
   * Radius size
   * @var {Number}
   */
  innerRadius;

  /**
   * Radius of the hand on gauge
   * @var {Number}
   */
  radius;

  constructor(model = {}) {
    const { pinDisabled = false } = model;
    this.value = model.value;
    this.fill = model.fill;
    this.stroke = model.stroke;
    this.innerRadius = model.innerRadius;
    this.pinDisabled = pinDisabled;
    this.radius = model.radius;
  }
}
