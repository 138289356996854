import { WidgetDataResource } from '@/modules/ta/widget/resources/WidgetDataResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { GenericModel } from '@/modules/core/app/models/GenericModel';

export class WidgetDataWidgetDataResource extends WidgetDataResource {
  constructor(model = GenericModel) {
    super(RouteGroup.DASH_WIDGETS);
    this.model = model;
  }

  /**
   * @param {Widget} widget
   * @param {*} params
   * @returns {Promise<void>}
   */
  async getWidgetData(widget, params) {
    return super.getData(this.model, `${widget.id}/data`, params);
  }
}
