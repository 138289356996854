import { DataView } from '@/modules/core/datasource/models/DataView';

export class CategoryDataView extends DataView {
  /**
   * @var {string}
   */
  category_id;

  /**
   * @var {string}
   */
  parent;

  /**
   * @var {string}
   */
  unique_id;

  /**
   * @var {string}
   */
  services;

  /**
   * @var {string}
   */
  category_metrics;

  /**
   * @var {string}
   */
  parent_name;

  constructor(model = {}) {
    super(model);

    this.category_id = model.category_id;
    this.parent = model.parent;
    this.unique_id = model.unique_id;
    this.services = model.services;
    this.category_metrics = model.category_metrics;
    this.parent_name = model.parent_name;
  }
}
