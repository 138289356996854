export const StaticOptionPlugin = (Vue) => {
  Vue.mixin({
    beforeCreate() {
      const { static: staticOption } = this.$options;
      if (staticOption && typeof staticOption === 'object') {
        Object.assign(this, staticOption);
      } else if (staticOption) {
        Logger.log(i18n.$t('Expected option static to be only an object'), Logger.LEVEL_ERROR);
      }
    },
  });
};
