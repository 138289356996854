import moment from 'moment';
import {
  MomentDateFormat,
  RelativeDateRangeKey,
} from '@/modules/core/daterange/daterange.constants';
import { DateRangeConfig } from '@/modules/core/daterange/models/DateRangeConfig';
import { dispatches, getters, subscribeMutation } from '@/modules/core/app/helpers/store';

export class CurrentDateRangeConfig extends DateRangeConfig {
  constructor(config = {}) {
    const dateRange = getters.daterange.getDateRange();
    super({
      ...config,
      startDate: config.startDate ?? dateRange.start_date,
      endDate: config.endDate ?? dateRange.end_date,
      showDropdowns: true,
    });
    this.ranges = super.getRanges();
    this.update = this.onUpdate;
    this.update({
      startDate: this.startDate,
      endDate: this.endDate,
    });

    this.updateCurrentDateRange = subscribeMutation.daterange.updateDateRange(() => {
      this.updateRanges();
    });
  }

  /**
   * @override
   */
  destructor() {
    super.destructor();
    this.updateCurrentDateRange();
  }

  /**
   * @param data
   * @returns {DateRange}
   */
  onUpdate(data) {
    const dateRange = getters.daterange.getDateRange();
    const startDate = moment(data.startDate, MomentDateFormat.ISO);
    const endDate = moment(data.endDate, MomentDateFormat.ISO);
    const relativeDateRange = super.getRelativeDateRangeFromDateRange(startDate, endDate);
    const relativeDateRangeKey = relativeDateRange
      ? relativeDateRange.key
      : RelativeDateRangeKey.CUSTOM;

    /**
     * Update comparison dates if comparison is not enabled
     * or a relative date range is selected
     * or if we've exceeded the comparison date range upper bound
     */
    const [comparisonStartDate, comparisonEndDate] = super.calculatePeriod(
      startDate,
      endDate,
      relativeDateRangeKey,
      RelativeDateRangeKey.PRIOR_PERIOD
    );

    if (dateRange.relative_date_range_key !== relativeDateRangeKey) {
      dispatches.daterange.updateRelativeDateRangeKey(relativeDateRangeKey);
    }

    // Update comparison date ranges and values
    const ranges = super.getRanges();
    const comparisonRanges = super.getComparisonRanges(startDate, endDate, relativeDateRangeKey);
    dispatches.daterange.updateComparisonRelativeDateRanges({ ...ranges, ...comparisonRanges });

    const formattedStartDate = startDate.format(MomentDateFormat.ISO);
    const formattedEndDate = endDate.format(MomentDateFormat.ISO);
    const formattedComparisonStartDate = comparisonStartDate.format(MomentDateFormat.ISO);
    const formattedComparisonEndDate = comparisonEndDate.format(MomentDateFormat.ISO);

    if (
      this.startDate !== formattedStartDate ||
      this.endDate !== formattedEndDate ||
      dateRange.comparison_start_date !== formattedComparisonStartDate ||
      dateRange.comparison_end_date !== formattedComparisonEndDate
    ) {
      dispatches.daterange.updateCurrentAndPriorDateRange({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        comparisonStartDate: formattedComparisonStartDate,
        comparisonEndDate: formattedComparisonEndDate,
      });
    }

    return dateRange;
  }

  updateRanges() {
    const { startDate, endDate } = getters.daterange.getCurrentDateRange();
    this.startDate = startDate;
    this.endDate = endDate;

    super.updateModel();
  }
}
