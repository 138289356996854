import { isUndefined, last, uniqBy } from 'lodash';
import { Colors } from '@/modules/ta/goal/goal.constants';
import tinycolor from 'tinycolor2';

export function colorGenerator() {
  /**
   * Taken from existing amcharts4-related code
   * @param chartPalette
   * @param index point at which the element is
   * @returns {*}
   */
  function generateColorWithPaletteOverflow(chartPalette, index) {
    const colorDiversity = 10;
    const maxOffset = 100;

    // If index is beyond color palette, generate a color
    if (!chartPalette[index]) {
      const baseIndex = index > maxOffset ? index - 90 : index;
      let chartBaseColor = chartPalette[baseIndex - colorDiversity];

      if (isUndefined(chartBaseColor) || chartBaseColor === Colors.WHITE.toLowerCase()) {
        chartBaseColor = chartPalette[Math.floor(Math.random() * 10)];
      }
      // Avoid to generate same color it the color is greyscale
      const offset = index === 0 ? 0 : index / chartPalette.length;
      chartBaseColor = tinycolor(chartBaseColor).lighten(offset).desaturate(offset).toString();

      let newColor = uniqBy(
        tinycolor(chartBaseColor)
          .analogous(colorDiversity * 2, colorDiversity)
          .map((t) => t.toHexString())
      );
      newColor = last(newColor);
      chartPalette[index] = newColor;
    }

    return chartPalette[index];
  }

  function lighten(color, amount = 20) {
    return tinycolor(color).lighten(amount).toString();
  }

  function darken(color, amount = 20) {
    return tinycolor(color).darken(amount).toString();
  }

  return {
    generateColorWithPaletteOverflow,
    lighten,
    darken,
  };
}
