'use strict';
import angular from 'angular';
import _ from 'lodash';
import dashboardFilterPanelItemHtmlUrl from './dashboardfilterpanel.item.html';
import dashboardFilterPanelItemLableHtmlUrl from './dashboardfilterpanel.item.label.html';

angular.module('dashboardfilteritem.directives', [])

    .component('dashboardFilterPanelItem', {
        templateUrl: dashboardFilterPanelItemHtmlUrl,
        bindings: {
            option: '<',
            isFirst: '<'
        },
        controllerAs: 'vm',
        controller: DashboardFilterPanelItemController
    })
    .component('dashboardFilterPanelItemLabel', {
        templateUrl: dashboardFilterPanelItemLableHtmlUrl,
        bindings: {
            option: '<'
        },
        controllerAs: 'vm',
        controller: DashboardFilterPanelItemLabelController
    });

/**
 * @ngInject
 */
function DashboardFilterPanelItemController(
    DashboardFilterPanelFactory,
    DashboardFilterPanelItemFactory,
    DesignFactory,
    WidgetFactory,
    FilterParam,
    gettextCatalog
) {
    var vm = this;
    vm.FilterParam = FilterParam;

    vm.$onInit = function() {
        vm.shared = DashboardFilterPanelItemFactory.shared;
    };

    vm.$postLink = function() {
        vm.option.isShowingAffectedWidgets = false;

        vm.getIncludeTooltip = function() {
            return vm.option.limit_available_values
                ? gettextCatalog.getString("Since 'Limit Available Values' is enabled, values can only be included.")
                : gettextCatalog.getString('Include');
        };

        var $getAllWidgetElements = DesignFactory.$getAllWidgetElements;

        var resetAllOtherActiveTogglers = function() {
            _.each(DashboardFilterPanelFactory.shared.widgetFilterOptionList, function(option) {
                if (option.key !== vm.option.key) {
                    option.isShowingAffectedWidgets = false;
                }
            });
        };

        vm.showAffectedWidgets = function() {
            resetAllOtherActiveTogglers();
            $getAllWidgetElements().addClass('faded');
            vm.option.isShowingAffectedWidgets = !vm.option.isShowingAffectedWidgets;
            if (vm.option.isShowingAffectedWidgets) {
                _.each(vm.option.affectedWidgetIds, function (id) {
                    WidgetFactory.$getElement(id)
                        .find('div.widget-inner')
                        .removeClass('faded')
                        .addClass('glow')
                        .css({outlineColor: vm.option.data_source.color});
                });
            }
            else {
                $getAllWidgetElements().removeClass('glow').removeClass('faded');
            }
        };
    };
}

/**
 * @ngInject
 */
function DashboardFilterPanelItemLabelController(
    DataSourceFactory,
    UIColor
) {
    var vm = this;

    vm.$onInit = function() {
        vm.getDataSourceIcon = function() {
            return DataSourceFactory.getDataSourceIcon(vm.option.data_source);
        };
        vm.getDataSourceColor = function() {
            return DataSourceFactory.getDataSourceColor(vm.option.data_source);
        };
        vm.getTextColorWithBackgroundColor = function (backgroundColor) {
            return UIColor.textColorWithBackgroundColor(backgroundColor);
        }
    };
}