import angular from 'angular';
import moment from 'moment';

import itemMacrosFactoryHtmlUrl from './item.macros.factory.html';
import itemMacrosDaterangeHtmlUrl from './item.macros.daterange.html';
import itemMacrosPagetitleHtmlUrl from './item.macros.pagetitle.html';
import itemMacrosPagenumberHtmlUrl from './item.macros.pagenumber.html';
import itemMacrosCreatedonHtmlUrl from './item.macros.createdon.html';
import itemMacrosRecipientnameHtmlUrl from './item.macros.recipientname.html';
import itemMacrosCompanylogoHtmlUrl from './item.macros.companylogo.html';

import { $ExportBuilderDashboardEvents, $ExportBuilderDashboardModelEvents } from 'coreModules/exportbuilder/exportbuilder.constants';

angular.module('exportbuilder.components')
    .component('exportBuilderItemMacroFactory', {
        bindings: {
            element: '<',
            pageIndex: '<',
            pageId: '<'
        },
        templateUrl: itemMacrosFactoryHtmlUrl,
        controller: ExportBuilderItemMacroFactoryController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemMacroDateRange', {
        bindings: {
            element: '<'
        },
        templateUrl: itemMacrosDaterangeHtmlUrl,
        controller: ExportBuilderItemMacroDateRangeController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemMacroPageTitle', {
        bindings: {
            element: '<',
            pageIndex: '<',
            pageId: '<'
        },
        templateUrl: itemMacrosPagetitleHtmlUrl,
        controller: ExportBuilderItemMacroPageTitleController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemMacroPageNumber', {
        bindings: {
            element: '<',
            pageIndex: '<'
        },
        templateUrl: itemMacrosPagenumberHtmlUrl,
        controller: ExportBuilderItemMacroPageNumberController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemMacroCreatedOn', {
        bindings: {
            element: '<'
        },
        templateUrl: itemMacrosCreatedonHtmlUrl,
        controller: ExportBuilderItemMacroCreatedOnController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemMacroRecipientName', {
        bindings: {
            element: '<'
        },
        templateUrl: itemMacrosRecipientnameHtmlUrl,
        controller: ExportBuilderItemMacroRecipientNameController,
        controllerAs: 'vm'
    })
    .component('exportBuilderItemMacroCompanyLogo', {
        bindings: {
            element: '<'
        },
        templateUrl: itemMacrosCompanylogoHtmlUrl,
        controller: ExportBuilderItemMacroCompanyLogoController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderItemMacroFactoryController(
    $scope,
    $compile,
    $element,
    ReportElementMacroTypes
) {
    var vm = this;
    vm.$onInit = $onInit;

    function $onInit() {
        var component = null;
        switch (vm.element.metadata.macro_type) {
            case ReportElementMacroTypes.DATE_RANGE:
                component = '<export-builder-item-macro-date-range element="vm.element"></export-builder-item-macro-date-range>';
                break;
            case ReportElementMacroTypes.CREATED_ON:
                component = '<export-builder-item-macro-created-on element="vm.element"></export-builder-item-macro-created-on>';
                break;
            case ReportElementMacroTypes.RECIPIENT_NAME:
                component = '<export-builder-item-macro-recipient-name element="vm.element"></export-builder-item-macro-recipient-name>';
                break;
            case ReportElementMacroTypes.PAGE_TITLE:
                component = '<export-builder-item-macro-page-title element="vm.element" page-index="vm.pageIndex" page-id="vm.pageId"></export-builder-item-macro-page-title>';
                break;
            case ReportElementMacroTypes.PAGE_NUMBER:
                component = '<export-builder-item-macro-page-number element="vm.element" page-index="vm.pageIndex"></export-builder-item-macro-page-number>';
                break;
            case ReportElementMacroTypes.COMPANY_LOGO:
                component = '<export-builder-item-macro-company-logo element="vm.element"></export-builder-item-macro-company-logo>';
                break;
            default:
                return;
        }
        component = $compile(component)($scope);
        $element.append(component);
    }
}

/**
 * @ngInject
 */
function ExportBuilderItemMacroDateRangeController(
    $scope,
    AppFactory,
    PubSub,
    $DateRangeEvents,
    ExportFactory,
    ReportElementStylesUIFactory
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.textStyles = textStyles;
    vm.textClasses = textClasses;

    function $onInit() {
        _updateDateRange();
        _updateComparisonDateRange();
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _updateText() {
        var text = '';
        if (vm.compareDateRange && vm.compareDateRange.enabled) {
            text += ('<b>From</b> ' + vm.compareDateRange.formatted.start + ' - ' + vm.compareDateRange.formatted.end);
            text += '<br>';
            text += ('<b>to</b> ' + vm.dateRange.formatted.start + ' - ' + vm.dateRange.formatted.end);
        } else {
            text += ('<b>From</b> ' + vm.dateRange.formatted.start + ' <b>to</b> ' + vm.dateRange.formatted.end);
        }
        vm.text = text;
    }

    function _updateDateRange() {
        vm.dateRange = AppFactory.getDateRange();
        _updateText();
    }

    function _updateComparisonDateRange() {
        vm.compareDateRange = AppFactory.getComparisonDateRange();
        _updateText();
    }

    function _registerEvents() {
        PubSub.on($DateRangeEvents.DATE_RANGE_CHANGE, _updateDateRange);
        PubSub.on($DateRangeEvents.COMPARISON_DATE_RANGE_CHANGE, _updateComparisonDateRange);
    }

    function _unregisterEvents() {
        PubSub.off($DateRangeEvents.DATE_RANGE_CHANGE, _updateDateRange);
        PubSub.off($DateRangeEvents.COMPARISON_DATE_RANGE_CHANGE, _updateComparisonDateRange);
    }

    function textStyles() {
        return ReportElementStylesUIFactory.getElementTextStyles(vm.element);
    }

    function textClasses() {
        return ReportElementStylesUIFactory.getElementTextClasses(vm.element);
    }
}

/**
 * @ngInject
 */
function ExportBuilderItemMacroPageTitleController(
    ReportElementStylesUIFactory,
    ExportBuilderDashboardPageService
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.$onDestroy = $onDestroy;
    vm.textStyles = textStyles;
    vm.textClasses = textClasses;

    function $onInit() {
        _updateText();
    }

    function $onChanges() {
        _updateText();
    }

    function $onDestroy() {
    }

    function textStyles() {
        return ReportElementStylesUIFactory.getElementTextStyles(vm.element);
    }

    function textClasses() {
        return ReportElementStylesUIFactory.getElementTextClasses(vm.element);
    }

    function _updateText() {
        var page = ExportBuilderDashboardPageService.getPageFromId(vm.pageId);
        var text = '';

        text += page.title;

        vm.text = text;
    }
}

/**
 * @ngInject
 */
function ExportBuilderItemMacroPageNumberController(
    ReportElementStylesUIFactory
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.$onDestroy = $onDestroy;
    vm.textStyles = textStyles;
    vm.textClasses = textClasses;

    function $onInit() {
        _updateText();
    }

    function $onChanges() {
        _updateText();
    }

    function $onDestroy() {
    }

    function textStyles() {
        return ReportElementStylesUIFactory.getElementTextStyles(vm.element);
    }

    function textClasses() {
        return ReportElementStylesUIFactory.getElementTextClasses(vm.element);
    }

    function _updateText() {
        var text = '';
        text += (parseInt(vm.pageIndex) + 1);
        vm.text = text;
    }
}

/**
 * @ngInject
 */
function ExportBuilderItemMacroCreatedOnController(
    ReportElementStylesUIFactory
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.textStyles = textStyles;
    vm.textClasses = textClasses;

    function $onInit() {
        _updateText();
    }

    function $onDestroy() {
    }

    function textStyles() {
        return ReportElementStylesUIFactory.getElementTextStyles(vm.element);
    }

    function textClasses() {
        return ReportElementStylesUIFactory.getElementTextClasses(vm.element);
    }

    function _updateText() {
        var text = '';
        text += '<b>Created On</b> ' + moment().format('MMM D, YYYY');
        vm.text = text;
    }

}

/**
 * @ngInject
 */
function ExportBuilderItemMacroRecipientNameController(
    PubSub,
    $ExportBuilderDashboardEvents,
    ReportElementStylesUIFactory,
    ExportBuilderDashboardService,
    ExportFactory
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.$onDestroy = $onDestroy;

    vm.textStyles = textStyles;
    vm.textClasses = textClasses;

    function $onInit() {
        _updateText();
        _registerEvents();
    }

    function $onChanges() {
        _updateText();
        _unregisterEvents();
    }

    function $onDestroy() {
    }

    function textStyles() {
        return ReportElementStylesUIFactory.getElementTextStyles(vm.element);
    }

    function textClasses() {
        return ReportElementStylesUIFactory.getElementTextClasses(vm.element);
    }

    function _updateText() {
        vm.text = ExportFactory.getIsExporting() ? ExportFactory.getVars().filter.text : ExportBuilderDashboardService.getEntity().text || '- None selected -';
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.SELECTED_ENTITY_CHANGED, _updateText);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.SELECTED_ENTITY_CHANGED, _updateText);
    }
}

/**
 * @ngInject
 */
function ExportBuilderItemMacroCompanyLogoController(
    $timeout,
    ReportElementStylesUIFactory,
    AppFactory,
    ExportFactory,
    PubSub,
    ReportStudioTemplateDataService
) {
    var vm = this;
    vm.showImage = true;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getClasses = getClasses;

    function $onInit() {
        _registerEvents();
        _setImage();
    }
    
    function $onDestroy() {
        _unregisterEvents();
    }

    function getClasses() {
        return ReportElementStylesUIFactory.getElementImageClasses(vm.element);
    }

    function _refresh() {
        PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED+vm.element.id);
    }

    function _setImage() {
        const report = ReportStudioTemplateDataService.getReport();
        const entity = ReportStudioTemplateDataService.getEntity();

        if (report && report.client_logo_url) {
            vm.image_url = report.client_logo_url;
        } else if (entity && entity.logo) {
            vm.image_url = entity.logo;
        } else {
            vm.image_url = _getDefaultCompanyLogo();
        }
    }

    function _getDefaultCompanyLogo() {
        return ExportFactory.getIsExporting() ? ExportFactory.getVars().reportVars.logo_url : AppFactory.getUser().lightLogoUrl
    }

    function _onSelectedEntityChanged() {
        _setImage();
        _refresh();
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.SELECTED_ENTITY_CHANGED, _onSelectedEntityChanged);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.SELECTED_ENTITY_CHANGED, _onSelectedEntityChanged);
    }
}