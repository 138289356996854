'use strict';
import angular from 'angular';
import _ from 'lodash';
import executiveSummaryDisplayHtmlUrl from './executivesummary.display.html';
import executiveSummaryCarouselItemHtmlUrl from './executivesummary.carousel.item.html';

angular.module('executivesummary.components', [])

    .component('executiveSummaryDisplay', {
        templateUrl: executiveSummaryDisplayHtmlUrl,
        bindings: {
            summary: '<'
        },
        controller: ExecutiveSummaryDisplayController,
        controllerAs: 'vm'
    })
    .component('executiveSummaryCarouselItem', {
        templateUrl: executiveSummaryCarouselItemHtmlUrl,
        bindings: {
            item: '<'
        },
        controller: ExecutiveSummaryItemController,
        controllerAs: 'vm'
    });


/**
 *
 * @ngInject
 */
function ExecutiveSummaryDisplayController(
    AppFactory,
    DesignFactory,
    ExecutiveSummaryFactory,
    UIExecutiveSummaryModalFactory,
    UIExecutiveSummaryPanelFactory
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.userIsClient = userIsClient;
    vm.canUserEdit = canUserEdit;
    vm.hasEmptySummary = hasEmptySummary;
    vm.getIsLoading = getIsLoading;
    vm.isExporting = isExporting;
    vm.userCanEditExecSummaries = userCanEditExecSummaries;

    function $onInit() {
        if (vm.summary) {
            ExecutiveSummaryFactory.setExecutiveSummary(vm.summary);
        }
        vm.executiveSummaryDisplay = ExecutiveSummaryFactory.getExecutiveSummaryDisplay();
        vm.initPanel = UIExecutiveSummaryPanelFactory.initPanel;
        vm.createSummary = UIExecutiveSummaryModalFactory.initModal;
        vm.itemComponent = '<executive-summary-carousel-item data-item="item"></executive-summary-carousel-item>';

        vm.owlCarouselOptions = {
            items: 1,
            margin: 700,
            startPosition: vm.executiveSummaryDisplay.length - 1,
            dots: !isExporting(),
        };
    }

    function $onDestroy() {
    }

    function userIsClient() {
        return AppFactory.getUser().isClient();
    }

    function canUserEdit() {
        return Permission.hasPermissionToWrite(
            Permission.moduleName.DASHBOARDEXECUTIVESUMMARIES,
            !userIsClient()
        ) && !UIExecutiveSummaryPanelFactory.panel.isShowing;
    }

    function userCanEditExecSummaries() {
        return Permission.hasPermissionToWrite(
            Permission.moduleName.DASHBOARDEXECUTIVESUMMARIES,
            !AppFactory.getUser().isClient()
        );
    }

    function hasEmptySummary() {
        return _.isEmpty(vm.executiveSummaryDisplay);
    }

    function getIsLoading() {
        return ExecutiveSummaryFactory.getStateProperty('isLoading');
    }

    function isExporting() {
        return DesignFactory.getIsExportingPage();
    }

}

function ExecutiveSummaryItemController(
    $scope,
    AppFactory,
    DesignFactory
) {
    var vm = this;
    vm.canDisplayInfo = canDisplayInfo;

    function canDisplayInfo() {
        return !AppFactory.getUser().isClient()
            && !DesignFactory.getIsExportingPage();
    }
}
