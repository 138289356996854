'use strict';
import angular from 'angular';

export const ApiDataFields = {
    ROW_GROUPING_COUNT: 'row_grouping_count'
};

export const PageType = {
    TYPE_DASH: 'dash',
    TYPE_SERVICES: 'services',
    TYPE_CATEGORIES: 'categories',
    TYPE_ADMIN: 'admin',
    TYPE_IO: 'io',
};

export const PageEntity = {
    PAGE_MANAGE_ALERTS: 'managealerts',
    PAGE_OVERVIEW: 'overview',
    PAGE_DRILLDOWN: 'drilldown',
    PAGE_LEADS: 'leads',
    PAGE_MANAGE_LEADS: 'manageleads',
    PAGE_MANAGE_END_CUSTOMERS: 'manageendcustomers',
    PAGE_CLIENT_DETAILS: 'clientdetails',
    PAGE_MANAGE_DASHBOARDS: 'managedashboards',
    PAGE_MANAGE_PAGE_LIBRARY: 'managepagelibrary',
    PAGE_MANAGE_LAYOUT_LIBRARY: 'managelayoutlibrary',
    PAGE_MANAGE_WIDGET_LIBRARY: 'managewidgetlibrary',
    PAGE_MANAGE_REPORT_STUDIO_LIBRARY: 'managereportstudiolibrary',
    PAGE_SERVICE_CLIENT_MAPPING: 'serviceclientmapping',
    PAGE_SERVICE_CLIENT_MAPPING_LOG: 'serviceclientmappinglog',
    PAGE_SERVICE_CLIENT_MAPPING_LOG_RESULT: 'serviceclientmappinglogresults',
    PAGE_GOALS: 'goals',
    PAGE_EXECUTIVE_SUMMARY: 'executivesummary',
    PAGE_MANAGE_PAGE_TAGS: 'managepagetags',
    PAGE_MANAGE_LAYOUT_TAGS: 'managelayouttags',
    PAGE_MANAGE_WIDGET_TAGS: 'managewidgettags',
    PAGE_PREDEFINED_CATEGORY_DASHBOARD: 'predefinedcategorydashboard',
    PAGE_CONNECTION_STATUS: 'connectionstatus',
    PAGE_DATA_LOAD_STATUS: 'dataloadstatus',
    PAGE_NAMING_CONVENTIONS: 'namingconventions',
    PAGE_TAGS: 'tags',
    PAGE_TAG_VALUES: 'tagvalues',
    PAGE_SMART_CAMPAIGNS: 'smartcampaigns',
    PAGE_MANAGE_PRODUCTS: 'manageproducts',
    PAGE_CATEGORIES_OVERVIEW: 'categoriesoverview',
    PAGE_INDUSTRY_BENCHMARK: 'industrybenchmark',
    PAGE_EMAILS_OVERVIEW: 'emailsoverview',
    PAGE_DATA_EXPORTER: 'dataexporter',
    PAGE_SERVICE_LOOKUP: 'servicelookup',
};

export const PageEvents = {
    PERFORMANCE_TRACKING: 'PageEvents:PerformanceTracking',
}

export const FontSize = {
    SIZE_SMALL: 'small',
    SIZE_MEDIUM: 'medium',
    SIZE_LARGE: 'large',
};

// Widget report options
export const ReportOption = {
    DISPLAY_LENGTH: 'display_length',
    SORT_ORDER: 'sort_order',
    SORT_BY: 'sort_by',
    COMPARISON_OVERWRITE: 'comparison_overwrite',
    USER_GROUPED_COLUMNS: 'user_grouped_columns'
};

export const Country = {
    CODES: [
        {
            "id": "world",
            "value": "World",
            "map": "world"
        },
        {
            "id": "AE",
            "value": "United Arab Emirates",
            "map": "unitedArabEmirates"
        },
        {
            "id": "AF",
            "value": "Afghanistan",
            "map": "afghanistan"
        },
        {
            "id": "AL",
            "value": "Albania",
            "map": "albania"
        },
        {
            "id": "AM",
            "value": "Armenia",
            "map": "armenia"
        },
        {
            "id": "AO",
            "value": "Angola",
            "map": "angola"
        },
        {
            "id": "AR",
            "value": "Argentina",
            "map": "argentina"
        },
        {
            "id": "AT",
            "value": "Austria",
            "map": "austria"
        },
        {
            "id": "AU",
            "value": "Australia",
            "map": "australia"
        },
        {
            "id": "BD",
            "value": "Bangladesh",
            "map": "bangladesh"
        },
        {
            "id": "BE",
            "value": "Belgium",
            "map": "belgium"
        },
        {
            "id": "BF",
            "value": "Burkina Faso",
            "map": "burkinaFaso"
        },
        {
            "id": "BG",
            "value": "Bulgaria",
            "map": "bulgaria"
        },
        {
            "id": "BI",
            "value": "Burundi",
            "map": "burundi"
        },
        {
            "id": "BJ",
            "value": "Benin",
            "map": "benin"
        },
        {
            "id": "BN",
            "value": "Brunei Darussalam",
            "map": "bruneiDarussalam"
        },
        {
            "id": "BO",
            "value": "Bolivia",
            "map": "bolivia"
        },
        {
            "id": "BR",
            "value": "Brazil",
            "map": "brazil"
        },
        {
            "id": "BS",
            "value": "Bahamas",
            "map": "bahamas"
        },
        {
            "id": "BT",
            "value": "Bhutan",
            "map": "bhutan"
        },
        {
            "id": "BW",
            "value": "Botswana",
            "map": "botswana"
        },
        {
            "id": "BY",
            "value": "Belarus",
            "map": "belarus"
        },
        {
            "id": "BZ",
            "value": "Belize",
            "map": "belize"
        },
        {
            "id": "CA",
            "value": "Canada",
            "map": "canada"
        },
        {
            "id": "CF",
            "value": "Central African Republic",
            "map": "centralAfricanRepublic"
        },
        {
            "id": "CH",
            "value": "Switzerland",
            "map": "switzerland"
        },
        {
            "id": "CL",
            "value": "Chile",
            "map": "chile"
        },
        {
            "id": "CM",
            "value": "Cameroon",
            "map": "cameroon"
        },
        {
            "id": "CN",
            "value": "China",
            "map": "china"
        },
        {
            "id": "CO",
            "value": "Colombia",
            "map": "colombia"
        },
        {
            "id": "CR",
            "value": "Costa Rica",
            "map": "costaRica"
        },
        {
            "id": "CU",
            "value": "Cuba",
            "map": "cuba"
        },
        {
            "id": "CY",
            "value": "Cyprus",
            "map": "cyprus"
        },
        {
            "id": "CZ",
            "value": "Czech Republic",
            "map": "czechRepublic"
        },
        {
            "id": "DE",
            "value": "Germany",
            "map": "germany"
        },
        {
            "id": "DJ",
            "value": "Djibouti",
            "map": "djibouti"
        },
        {
            "id": "DK",
            "value": "Denmark",
            "map": "denmark"
        },
        {
            "id": "DO",
            "value": "Dominican Republic",
            "map": "dominicanRepublic"
        },
        {
            "id": "DZ",
            "value": "Algeria",
            "map": "algeria"
        },
        {
            "id": "EC",
            "value": "Ecuador",
            "map": "ecuador"
        },
        {
            "id": "EE",
            "value": "Estonia",
            "map": "estonia"
        },
        {
            "id": "EG",
            "value": "Egypt",
            "map": "egypt"
        },
        {
            "id": "EH",
            "value": "Western Sahara",
            "map": "moroccoWesternSahara"
        },
        {
            "id": "ER",
            "value": "Eritrea",
            "map": "eritrea"
        },
        {
            "id": "ES",
            "value": "Spain",
            "map": "spain"
        },
        {
            "id": "ET",
            "value": "Ethiopia",
            "map": "ethiopia"
        },
        {
            "id": "FK",
            "value": "Falkland Islands",
            "map": "falklandIslands"
        },
        {
            "id": "FI",
            "value": "Finland",
            "map": "finland"
        },
        {
            "id": "FR",
            "value": "France",
            "map": "france"
        },
        {
            "id": "GA",
            "value": "Gabon",
            "map": "gabon"
        },
        {
            "id": "GB",
            "value": "United Kingdom",
            "map": "unitedKingdom"
        },
        {
            "id": "GE",
            "value": "Georgia",
            "map": "georgia"
        },
        {
            "id": "GF",
            "value": "French Guiana",
            "map": "frenchGuiana"
        },
        {
            "id": "GH",
            "value": "Ghana",
            "map": "ghana"
        },
        {
            "id": "GL",
            "value": "Greenland",
            "map": "greenland"
        },
        {
            "id": "GM",
            "value": "Gambia",
            "map": "gambia"
        },
        {
            "id": "GN",
            "value": "Guinea",
            "map": "guinea"
        },
        {
            "id": "GQ",
            "value": "Equatorial Guinea",
            "map": "equatorialGuinea"
        },
        {
            "id": "GR",
            "value": "Greece",
            "map": "greece"
        },
        {
            "id": "GT",
            "value": "Guatemala",
            "map": "guatemala"
        },
        {
            "id": "GY",
            "value": "Guyana",
            "map": "guyana"
        },
        {
            "id": "HN",
            "value": "Honduras",
            "map": "honduras"
        },
        {
            "id": "HR",
            "value": "Croatia",
            "map": "croatia"
        },
        {
            "id": "HT",
            "value": "Haiti",
            "map": "haiti"
        },
        {
            "id": "HU",
            "value": "Hungary",
            "map": "hungary"
        },
        {
            "id": "ID",
            "value": "Indonesia",
            "map": "indonesia"
        },
        {
            "id": "IE",
            "value": "Ireland",
            "map": "ireland"
        },
        {
            "id": "IL",
            "value": "Israel",
            "map": "israel"
        },
        {
            "id": "IN",
            "value": "India",
            "map": "india"
        },
        {
            "id": "IQ",
            "value": "Iraq",
            "map": "iraq"
        },
        {
            "id": "IR",
            "value": "Iran",
            "map": "iran"
        },
        {
            "id": "IS",
            "value": "Iceland",
            "map": "iceland"
        },
        {
            "id": "IT",
            "value": "Italy",
            "map": "italy"
        },
        {
            "id": "JM",
            "value": "Jamaica",
            "map": "jamaica"
        },
        {
            "id": "JO",
            "value": "Jordan",
            "map": "jordan"
        },
        {
            "id": "JP",
            "value": "Japan",
            "map": "japan"
        },
        {
            "id": "KE",
            "value": "Kenya",
            "map": "kenya"
        },
        {
            "id": "KG",
            "value": "Kyrgyzstan",
            "map": "kyrgyzstan"
        },
        {
            "id": "KH",
            "value": "Cambodia",
            "map": "cambodia"
        },
        {
            "id": "KP",
            "value": "North Korea",
            "map": "northKorea"
        },
        {
            "id": "KR",
            "value": "South Korea",
            "map": "southKorea"
        },
        {
            "id": "XK",
            "value": "Kosovo",
            "map": "kosovo"
        },
        {
            "id": "KW",
            "value": "Kuwait",
            "map": "kuwait"
        },
        {
            "id": "KZ",
            "value": "Kazakhstan",
            "map": "kazakhstan"
        },
        {
            "id": "LB",
            "value": "Lebanon",
            "map": "lebanon"
        },
        {
            "id": "LK",
            "value": "Sri Lanka",
            "map": "sriLanka"
        },
        {
            "id": "LR",
            "value": "Liberia",
            "map": "liberia"
        },
        {
            "id": "LS",
            "value": "Lesotho",
            "map": "lesotho"
        },
        {
            "id": "LT",
            "value": "Lithuania",
            "map": "lithuania"
        },
        {
            "id": "LY",
            "value": "Libya",
            "map": "libya"
        },
        {
            "id": "MA",
            "value": "Morocco",
            "map": "morocco"
        },
        {
            "id": "MD",
            "value": "Moldova",
            "map": "moldova"
        },
        {
            "id": "ME",
            "value": "Montenegro",
            "map": "montenegro"
        },
        {
            "id": "MG",
            "value": "Madagascar",
            "map": "madagascar"
        },
        {
            "id": "MK",
            "value": "Macedonia",
            "map": "macedonia"
        },
        {
            "id": "ML",
            "value": "Mali",
            "map": "mali"
        },
        {
            "id": "MM",
            "value": "Myanmar",
            "map": "myanmar"
        },
        {
            "id": "MN",
            "value": "Mongolia",
            "map": "mongolia"
        },
        {
            "id": "MR",
            "value": "Mauritania",
            "map": "mauritania"
        },
        {
            "id": "MW",
            "value": "Malawi",
            "map": "malawi"
        },
        {
            "id": "MX",
            "value": "Mexico",
            "map": "mexico"
        },
        {
            "id": "MY",
            "value": "Malaysia",
            "map": "malaysia"
        },
        {
            "id": "MZ",
            "value": "Mozambique",
            "map": "mozambique"
        },
        {
            "id": "NA",
            "value": "Namibia",
            "map": "namibia"
        },
        {
            "id": "NC",
            "value": "New Caledonia",
            "map": "newCaledonia"
        },
        {
            "id": "NE",
            "value": "Niger",
            "map": "niger"
        },
        {
            "id": "NI",
            "value": "Nicaragua",
            "map": "nicaragua"
        },
        {
            "id": "NL",
            "value": "Netherlands",
            "map": "netherlands"
        },
        {
            "id": "NO",
            "value": "Norway",
            "map": "norway"
        },
        {
            "id": "NP",
            "value": "Nepal",
            "map": "nepal"
        },
        {
            "id": "NZ",
            "value": "New Zealand",
            "map": "newZealand"
        },
        {
            "id": "OM",
            "value": "Oman",
            "map": "oman"
        },
        {
            "id": "PA",
            "value": "Panama",
            "map": "panama"
        },
        {
            "id": "PH",
            "value": "Philippines",
            "map": "philippines"
        },
        {
            "id": "PL",
            "value": "Poland",
            "map": "poland"
        },
        {
            "id": "PK",
            "value": "Pakistan",
            "map": "pakistan"
        },
        {
            "id": "PR",
            "value": "Puerto Rico",
            "map": "puertoRico"
        },
        {
            "id": "PT",
            "value": "Portugal",
            "map": "portugal"
        },
        {
            "id": "PY",
            "value": "Paraguay",
            "map": "paraguay"
        },
        {
            "id": "QA",
            "value": "Qatar",
            "map": "qatar"
        },
        {
            "id": "RO",
            "value": "Romania",
            "map": "romania"
        },
        {
            "id": "RS",
            "value": "Serbia",
            "map": "serbia"
        },
        {
            "id": "RU",
            "value": "Russia",
            "map": "russia"
        },
        {
            "id": "RW",
            "value": "Rwanda",
            "map": "rwanda"
        },
        {
            "id": "SA",
            "value": "Saudi Arabia",
            "map": "saudiArabia"
        },
        {
            "id": "SD",
            "value": "Sudan",
            "map": "sudan"
        },
        {
            "id": "SE",
            "value": "Sweden",
            "map": "sweden"
        },
        {
            "id": "SI",
            "value": "Slovenia",
            "map": "slovenia"
        },
        {
            "id": "SK",
            "value": "Slovakia",
            "map": "slovakia"
        },
        {
            "id": "SO",
            "value": "Somalia",
            "map": "somalia"
        },
        {
            "id": "SR",
            "value": "Suriname",
            "map": "suriname"
        },
        {
            "id": "SV",
            "value": "El Salvador",
            "map": "elSalvador"
        },
        {
            "id": "SY",
            "value": "Syria",
            "map": "syria"
        },
        {
            "id": "SZ",
            "value": "Swaziland",
            "map": "swaziland"
        },
        {
            "id": "TD",
            "value": "Chad",
            "map": "chad"
        },
        {
            "id": "TH",
            "value": "Thailand",
            "map": "thailand"
        },
        {
            "id": "TJ",
            "value": "Tajikistan",
            "map": "tajikistan"
        },
        {
            "id": "TN",
            "value": "Tunisia",
            "map": "tunisia"
        },
        {
            "id": "TR",
            "value": "Turkey",
            "map": "turkey"
        },
        {
            "id": "TW",
            "value": "Taiwan",
            "map": "taiwan"
        },
        {
            "id": "UA",
            "value": "Ukraine",
            "map": "ukraine"
        },
        {
            "id": "UG",
            "value": "Uganda",
            "map": "uganda"
        },
        {
            "id": "US",
            "value": "United States",
            "map": "usa2"
        },
        {
            "id": "UY",
            "value": "Uruguay",
            "map": "uruguay"
        },
        {
            "id": "VE",
            "value": "Venezuela",
            "map": "venezuela"
        },
        {
            "id": "VN",
            "value": "Vietnam",
            "map": "vietnam"
        },
        {
            "id": "YE",
            "value": "Yemen",
            "map": "yemen"
        },
        {
            "id": "ZA",
            "value": "South Africa",
            "map": "southAfrica"
        },
        {
            "id": "ZM",
            "value": "Zambia",
            "map": "zambia"
        }
    ]
};

export const WidgetHeader = {
    CHAR_LENGTH: 600,
    HEADER_REGULAR_HEIGHT: 45
}

export const SanitizeHtmlOptions = {
    allowedTags: ['b', 'i', 'em', 'strong', 'u', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'span'],
    selfClosing: [],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
        div: ['style'],
        h1: ['style'],
        h2: ['style'],
        h3: ['style'],
        h4: ['style'],
        h5: ['style'],
        h6: ['style'],
        p: ['style'],
        span: ['style'],
        i: ['style'],
        em: ['style'],
        b: ['style'],
        strong: ['style'],
        u: ['style']
    },
    allowedSchemes: [],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: [],
    allowProtocolRelative: true,
    enforceHtmlBoundary: true // default false
}

export const WidgetProductSliderPanelEvents = {
    OPENED: 'WidgetProductSliderPanelEvents:Opened',
}

angular.module('design.constants', [])
    .constant('ApiDataFields', ApiDataFields)
    .constant('PageType', PageType)
    .constant('PageEntity', PageEntity)
    .constant('FontSize', FontSize)
    .constant('ReportOption', ReportOption)
    .constant('Country', Country)
    .constant('PageEvents', PageEvents)
    .constant('WidgetHeader', WidgetHeader)
    .constant('SanitizeHtmlOptions', SanitizeHtmlOptions)
    .constant('WidgetProductSliderPanelEvents', WidgetProductSliderPanelEvents);