'use strict';
import angular from 'angular';
import moment from 'moment';
import swal from 'bootstrap-sweetalert';
import _ from 'lodash';

angular.module('executivesummary.services', [])
    .constant('$ExecutiveSummaryEvents', $EventConstants())
    .factory('ExecutiveSummaryFactory', ExecutiveSummaryFactory)
    .factory('UIExecutiveSummaryPanelFactory', UIExecutiveSummaryPanelFactory)
    .factory('UIExecutiveSummaryModalFactory', UIExecutiveSummaryModalFactory)
    .factory('UIExecutiveSummaryFactory', UIExecutiveSummaryFactory);


function $EventConstants() {
    return {
        UPDATE_ITEMS: 'executiveSummaryItems:UPDATE'
    };
}

/**
 * @ngInject
 */
function ExecutiveSummaryFactory(
    DesignFactory,
    AppFactory,
    FormModelFactory,
    ExecutiveSummaryModelFactory,
    PubSub,
    ExecutiveSummaryResourceFactory,
    ClientFactory,
    ClientGroupFactory,
    DashboardFilterPanelFactory,
    DataSourceFactory,
    MomentDateFormat,
    ColumnFormat,
    LoadingState,
    DataSourceType,
    $timeout,
    $LayoutEvents,
    ExportBuilderDashboardService,
    $WidgetEvents
) {
    var currentEditingId = null;
    var defaultTextOptions = FormModelFactory.getTextOptions();
    var executiveSummary = ExecutiveSummaryModelFactory.getExecutiveSummary();
    var selectOptions = {};
    var state = getDefaultState();
    var selectOptionsToLoad = 0;
    var brandMappings;

    _registerEvents();

    return {
        // gets & sets
        getStateProperty: getStateProperty,
        setStateProperty: setStateProperty,
        getSelectOptions: getSelectOptions,
        setSelectOptions: setSelectOptions,
        getExecutiveSummary: getExecutiveSummary,
        setExecutiveSummary: setExecutiveSummary,
        getExecutiveSummaryDisplay: getExecutiveSummaryDisplay,
        formatSummaryDate: formatSummaryDate,
        getItems: getItems,
        getExecutiveSummaryItemById: getExecutiveSummaryItemById,
        getDefaultDateOptions: getDefaultDateOptions,
        getDefaultTextOptions: getDefaultTextOptions,
        getActiveSummaryId: getActiveSummaryId,
        setActiveSummaryId: setActiveSummaryId,

        // could be placed in directive
        initClientSelect: initClientSelect,
        resetClientSelect: resetClientSelect,

        // use cases
        save: save,

        // use cases with mixing concerns to UI, should be seperated
        saveItem: saveItem,
        deleteItems: deleteItems,

        // Emits new available data.
        $rebuild: $rebuild,
        updateItems: updateItems,

        getData: getData,
        fetchExecutiveSummary: fetchExecutiveSummary,
        onReportPageChanged: onReportPageChanged,
    };


    /**
     * Check if more selectOptions need to be loaded
     */
    function checkIfDone() {
        if (selectOptionsToLoad === 0) {
            // Timeout must be used in order to re-initialize select2 correctly
            $timeout(function() {
                state.isLoadingSelect = LoadingState.DONE;
            }, 200, true);
        }
    }

    /**
     * Check if more selectOptions need to be loaded
     */
    function getStateProperty(prop) {
        return prop ? state[prop] : Object.freeze(state);
    }

    /**
     * Check if more selectOptions need to be loaded
     */
    function setStateProperty(prop, value) {
        state[prop] = value;
    }

    /**
     * Check if more selectOptions need to be loaded
     */
    function getSelectOptions() {
        return selectOptions;
    }

    /**
     *
     * @param model
     */
    function setSelectOptions(model) {
        selectOptions = model;
    }

    /**
     * Set current values based on values already in on summary
     * @param option
     * @param entity
     */
    function setSelectedValues(option, entity) {

        // Plug in dashboard filters
        if (getExecutiveSummaryItemById(currentEditingId).is_new
            && !_.isEmpty(DashboardFilterPanelFactory.shared.filters[option.key])
            && DashboardFilterPanelFactory.shared.filters[option.key].values.length
        ) {
            option.currentValues = _.first(angular.copy(DashboardFilterPanelFactory.shared.filters[option.key].values));
            entity.type = option.key;
            entity.id = option.currentValues.id;
            entity.name = option.currentValues.text;
            entity.label = option.label;
        } else {
            option.currentValues = entity.type == option.key
                ? {text: entity.name, id: entity.id}
                : {};
        }
    }

    /**
     * Get and set dashboard filters
     */
    function initClientSelect(summary) {
        if (_.isEmpty(brandMappings)) {
            brandMappings = AppFactory.getBrandMappings();
        }

        var entity = summary.entity;

        // Avoid refetching data
        if (!_.isEmpty(selectOptions)) {
            selectOptionsToLoad = 0;
            // Need to update the selected values for the current entity
            _.forEach(selectOptions, function(option) {
                option.selectOptions.summary = summary;
                setSelectedValues(option, entity);
            });
            checkIfDone();

            return;
        }

        DashboardFilterPanelFactory.shared.getFilterValues().then(function (json) {
            var clientSelectValues = json.plain();
            selectOptionsToLoad = clientSelectValues.length; // # of possible filters

            _.forEach(clientSelectValues, function(option) {
                // Select2 inputs
                if (option.format === ColumnFormat.FORMAT_ID || option.format === ColumnFormat.FORMAT_STRING) {
                    // Set select2 default options
                    switch (option.key) {
                        case (DataSourceType.CLIENT):
                            option.label = brandMappings.client;
                            break;
                        case (DataSourceType.CLIENT_GROUP):
                            option.label = brandMappings.client_group;
                            break;
                    }
                    setSelectedValues(option, entity);
                    switch (option.key) {
                        case (DataSourceType.CLIENT):
                            option.values = [];

                            option.getDataCall = function(query) {
                                var params = {};
                                if (query) {
                                    params.q = query + '|company_name';
                                }
                                return ClientFactory.getFieldValues(option.field, params);
                            };

                            option.formatData = function(data) {
                                selectOptionsToLoad--;
                                option.values = data.values;
                                selectOptions[option.key] = option;
                                checkIfDone();
                            };

                            break;

                        case (DataSourceType.CLIENT_GROUP):
                            option.values = [];

                            option.getDataCall = function(query) {
                                var params = {};
                                if (query) {
                                    params.q = query + '|name';
                                }

                                return ClientGroupFactory.getFieldValues(option.field, params);
                            };

                            option.formatData = function(data) {
                                selectOptionsToLoad--;
                                option.values = data.values;
                                selectOptions[option.key] = option;
                                checkIfDone();
                            };
                            break;

                        default:
                            selectOptionsToLoad--;
                            checkIfDone();
                            break;
                    }

                    option.selectOptions = ExecutiveSummaryModelFactory.getDefaultSelectOptions(option, summary);

                }
            });
            // Set default client group values and set it as disabled when there are no client groups.
            if (!selectOptions.client_group) {
                selectOptions.client_group = _getDefaultClientGroupOptions(brandMappings.client_group, summary);
            }
        });
    }

    function _getDefaultClientSelectValues() {
        return {
            "key": "client_group",
            "field": "id",
            "label": "Client Group",
            "order": 1,
            "format": "id",
            "type": "in",
            "is_predefined": true,
            "data_source": null,
            "limit_available_values": false,
            "source_filter_set_id": null
        };
    }

    /**
     * Return default disabled client group select options.
     * @param clientGroupBrandMapping
     * @private
     */
    function _getDefaultClientGroupOptions(clientGroupBrandMapping, summary) {
        const clientGroup = _getDefaultClientSelectValues();
        clientGroup.label = brandMappings.client_group;
        clientGroup.values = [];
        clientGroup.selectOptions = ExecutiveSummaryModelFactory.getDefaultSelectOptions(clientGroup, summary);
        clientGroup.selectOptions.loaded = true;
        clientGroup.selectOptions.enable = false;
        return clientGroup;
    }

    function resetClientSelect() {
        selectOptions = {};
    }


    function setActiveSummaryId(id) {
        currentEditingId = id;
    }

    function getActiveSummaryId() {
        return currentEditingId;
    }

    /**
     * Initialize modal with new summary
     */
    function createSummary() {
        currentEditingId = executiveSummary.items.length;
        UIExecutiveSummaryModalFactory.initModal(currentEditingId);
    }

    /**
     * Getter
     * @param dateOptions
     * @returns {SummaryDateOptions}
     */
    function getDefaultDateOptions(dateOptions) {
        return ExecutiveSummaryModelFactory.getSummaryDateOptions(dateOptions);
    }

    /**
     * Getter
     * @returns {{minHeight: string, focus: boolean, airMode: boolean, dialogsFade: boolean, dialogsInBody: boolean, disableResizeEditor: boolean, placeholder: string, toolbar: *[]}}
     */
    function getDefaultTextOptions() {
        return defaultTextOptions;
    }

    /**
     * Default Executive Summary State
     * @returns {{isShowing: boolean, isLoading: boolean}}
     */
    function getDefaultState() {
        return {
            isShowing: false,
            isLoading: false,
            isLoadingSelect: false
        }
    }

    /**
     * Setter
     * Set Executive Summary Object
     * @param model
     */
    function setExecutiveSummary(model) {
        executiveSummary = ExecutiveSummaryModelFactory.getExecutiveSummary(model);
    }

    /**
     * executiveSummary object will not be bound
     * @returns {ExecutiveSummary}
     */
    function getExecutiveSummary() {
        return ExecutiveSummaryModelFactory.getExecutiveSummary(executiveSummary);
    }

    function fetchExecutiveSummary(layoutId, reportId, reportPageId) {
        state.isLoading = true;
        const currentLayout = DesignFactory.getCurrentLayout();
        if (currentLayout.executive_summary) {
            $timeout(() => {
                setExecutiveSummary(currentLayout.executive_summary);
                state.isLoading = false;
            })
        } else {
            ExecutiveSummaryResourceFactory.getSummaryDataForPageOrLayouts([layoutId],[reportId],[reportPageId]).then(function (json) {
                const summaries = json.plain();
                for(let i=1;i<summaries.length;i++){
                    if(!summaries[0].items){
                        summaries[0].items = [];
                    }
                    if(!summaries[i].items){
                        summaries[i].items = [];
                    }
                    summaries[0].items = summaries[i].items.concat(summaries[0].items);
                }
                setExecutiveSummary(summaries[0]);
                currentLayout.executive_summary = getExecutiveSummary();
                state.isLoading = false;
            });
        }
    }

    /**
     * Return only active summary items
     * @returns {Array}
     */
    function getExecutiveSummaryDisplay() {
        if (_.isEmpty(brandMappings)) {
            brandMappings = AppFactory.getBrandMappings();
        }

        var displaySummaries = [];
        _.each(getExecutiveSummary().items, function(item, id) {
            if (!_.isUndefined(item) && item.entity) {
                switch (item.entity.type) {
                    case (DataSourceType.CLIENT):
                        item.entity.type_name = brandMappings.client;
                        break;
                    case (DataSourceType.CLIENT_GROUP):
                        item.entity.type_name = brandMappings.client_group;
                        break;
                }
            }
            displaySummaries.push(getExecutiveSummaryItemById(id));
        }); // TODO: @chris need to return the appropriate executive summary order
        // Hey @chris
        return displaySummaries;
    }

    /**
     * Format summary date for display
     * @returns {Array}
     */
    function formatSummaryDate(date) {
        return date ? moment.utc(moment.unix(date)).format(MomentDateFormat.MONTH_DAY_YEAR) : undefined;
    }

    /**
     * Get whole executive summary items array
     * @returns {Array}
     */
    function getItems() {
        return executiveSummary.items; // TODO: @chris need a better way to return an immutable object
    }

    /**
     * Return a new executive summary items object
     * @param index
     * @returns {ExecutiveItem}
     */
    function getExecutiveSummaryItemById(id) {
        // Return a new executive items object, so we don't overwrite the original object
        var item = _.isNull(id) || _.isNull(executiveSummary.items[id]) ? {} : executiveSummary.items[id];
        return ExecutiveSummaryModelFactory.getExecutiveSummaryItem(item);
    }

    /**
     * Rebuild/refetch executive summary data
     * @param index
     * @param all
     * @param isReport
     */
    function $rebuild(index, all = false, isReport = false) {
        if (_.isNull(executiveSummary.id) && !all) {
            return;
        }
        let currentLayout;
        let report;
        let reportPage;
        let layoutId = null;
        let reportId = null;
        let reportPageId = null;

        if (!isReport) {
            currentLayout = DesignFactory.getCurrentLayout();
            layoutId = currentLayout.id;
            state.isLoading = true;
            if (all) {
                const layouts = DesignFactory.getLayouts();
                Object.entries(layouts).forEach(([layoutId, layout]) => {
                    layout.executive_summary = null;
                });
            }
        } else {
            report = ExportBuilderDashboardService.getBuilder().report;
            reportPage = ExportBuilderDashboardService.getBuilder().currentPage;

            reportId = report.id;
            reportPageId = reportPage.id;
        }
        ExecutiveSummaryResourceFactory.getSummaryDataForPageOrLayouts([layoutId], [reportId], [reportPageId]).then(function (json) {
            const summaries = json.plain();
            for (let i = 1; i < summaries.length; i++) {
                if(!summaries[0].items){
                    summaries[0].items = [];
                }
                if(!summaries[i].items){
                    summaries[i].items = [];
                }
                summaries[0].items = summaries[i].items.concat(summaries[0].items);
            }
            setExecutiveSummary(summaries[0]);
            if (!isReport) {
                currentLayout.executive_summary = getExecutiveSummary();
            }
            state.isLoading = false;
            updateItems(index);
            if (isReport) {
                PubSub.emit($WidgetEvents.WIDGET_REBUILD);
            }
        });
    }

    function updateItems(index) {
        PubSub.emit($EventConstants().UPDATE_ITEMS + index);
    }


    /**
     * Save entire executive summary object
     * @param model
     */
    function save(model) {
        state.isLoading = true;
        return ExecutiveSummaryResourceFactory.save(model).then(function(json) {
            model.id = json.id;
            const currentLayout = DesignFactory.getCurrentLayout();
            setExecutiveSummary(model);
            if (!currentLayout.executive_summary) {
                currentLayout.executive_summary = executiveSummary;
            }
            state.isLoading = false;
            return getExecutiveSummary();
        });
    }

    /**
     * Sweet Alert for delete executive summary items
     * Save items for executive summary
     * @param summary
     * @returns {*}
     */
    async function saveItem(item) {
        let currentSummary = getExecutiveSummary();
        if (!currentSummary.id) {
            const { id } = await save(currentSummary);
            currentSummary.id = id;
            setExecutiveSummary(currentSummary);
        }
        item.summary_id = currentSummary.id;
        var model = ExecutiveSummaryModelFactory.getExecutiveSummaryItem(item);
        state.isLoading = true;
        return ExecutiveSummaryResourceFactory.saveItem(model).then(function() {
            executiveSummary.addSummaryItem(model);
            state.isLoading = false;
            return getExecutiveSummary();
        });
    }

    /**
     * Call to delete items at index, and save executive summary object
     * @param index
     * @returns {*}
     */
    function deleteItems(index) {
        executiveSummary.items.splice(index, 1);
        return save(executiveSummary);
    }

    /**
     * Get data for executive summary page
     * @param queryParams
     */
    function getData(queryParams) {
        return ExecutiveSummaryResourceFactory.getData(queryParams);
    }

    function _onLayoutChanged(layout) {
        fetchExecutiveSummary(layout.id);
    }

    function onReportPageChanged(reportId, reportPageId) {
        /**
         * In extreme case, sometimes, due to some delay, reportPageId is not present
         * Hence, added this check for reportId too for safe side
         */
        if (_.isEmpty(reportId) || _.isNull(reportId) || _.isUndefined(reportId)) {
            const report = ExportBuilderDashboardService.getReport();
            reportId = report.id;
        }
        /**
         * In extreme case, sometimes, due to some delay, report page id is not present
         * Hence, getting current page in case, pageId is not there
         */
        if (_.isEmpty(reportPageId) || _.isNull(reportPageId) || _.isUndefined(reportPageId)) {
            const page = ExportBuilderDashboardService.getBuilder().currentPage;
            reportPageId = page.id;
        }
        fetchExecutiveSummary(null, reportId, reportPageId);
    }

    function _registerEvents() {
        PubSub.on($LayoutEvents.CHANGED, _onLayoutChanged);
    }
}

function UIExecutiveSummaryPanelFactory(
    ExecutiveSummaryFactory,
    UIExecutiveSummaryModalFactory,
    SlidePanelFactory,
    UIExecutiveSummaryFactory
) {

    var panel = {
        panelId: 'executive-summary-panel',
        showOuterElements: true,
        isLargePanel: true
    };

    return {
        panel: panel,
        isShowing: false,
        initPanel: initPanel,
        closePanel: closePanel
    };

    /**
     * Initialize Summary Panel
     * @param isCreating used if the summary has no items
     */
    function initPanel(isCreating) {
        if (isCreating) {
            var currentEditingId = ExecutiveSummaryFactory.getItems().length;
            ExecutiveSummaryFactory.setActiveSummaryId(currentEditingId );
            UIExecutiveSummaryModalFactory.initModal(currentEditingId);
        }
        SlidePanelFactory.show(panel);
        UIExecutiveSummaryFactory.panelResize();
    }

    /**
     * Hide slidePanel
     */
    function closePanel() {
        SlidePanelFactory.hide(panel);

        UIExecutiveSummaryFactory.panelResize();
    }
}

/**
 * @ngInject
 */
function UIExecutiveSummaryModalFactory(
    ExecutiveSummaryFactory,
    ExecutiveSummaryResourceFactory,
    UIFactory,
    $timeout,
    gettextCatalog,
    PubSub,
    $WidgetEvents
) {
    return {
        initModal: initModal,
        cancelModal: cancelModal,
        deleteSummaryConfirm: deleteSummaryConfirm
    };


    /**
     * Initialize edit summary modal
     * @param executiveSummaryIndex
     */
    function initModal(executiveSummaryId) {

        ExecutiveSummaryFactory.setActiveSummaryId(executiveSummaryId);
        UIFactory.showModal('create-executivesummary-modal');
        angular.element('#create-executivesummary-modal')
            .on('hidden.bs.modal', cancelModal); // Must be bound in order to handle 'esc'
    }


    /**
     * Close modal summary and set editing state
     */
    function cancelModal() {
        $timeout(function() { // Function must be wrapped in a timeout in order to work with 'esc' button
            ExecutiveSummaryFactory.setActiveSummaryId(null);
            ExecutiveSummaryFactory.setStateProperty('isEditing', false);
            UIFactory.hideModal('create-executivesummary-modal');
        })
    }

    /**
     * Sweet Alert for delete executive summary items
     * @param summaryItem
     */
    function deleteSummaryConfirm(summaryItem) {
        var options = {};
        var title = summaryItem.title || 'Untitled Summary';
        options.text = gettextCatalog.getString('You will delete') + ' <b>' + title + '</b>';
        options.confirmButtonText = gettextCatalog.getString('Yes');
        options.cancelButtonText = gettextCatalog.getString('No');
        options.html = true;
        options.callback = function() {
            ExecutiveSummaryFactory.setStateProperty('isLoading', true);
            var executiveSummary = ExecutiveSummaryFactory.getExecutiveSummary();
            return ExecutiveSummaryResourceFactory.removeItem(executiveSummary.id, summaryItem.id).then(function (json) {
                executiveSummary.removeSummaryItem(summaryItem.id);
                ExecutiveSummaryFactory.setStateProperty('isLoading', false);
                ExecutiveSummaryFactory.updateItems();
                if (!json.error) {
                    UIFactory.notify.showSuccess(summaryItem.title + ' ' + gettextCatalog.getString('successfully deleted'));
                }
                PubSub.emit($WidgetEvents.WIDGET_REBUILD);
                swal.close();
            });
        };

        UIFactory.confirmDelete(options);
    }
}

function UIExecutiveSummaryFactory(
    $timeout
) {
    return {
        panelResize: panelResize
    };

    function panelResize() {
        $timeout(function () {
            angular.element('.owl-carousel').trigger('refresh.owl.carousel');
        });
    }
}
