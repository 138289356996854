import { each, assign } from 'lodash';
import { NotificationProgrammatic as Notification } from 'buefy';
import { BaseUIService } from '@/modules/core/ui/services/BaseUIService';

/**
 * @typedef NotificationOptions
 * @type {string} type
 * @type {string} message
 * @type {boolean} active
 * @type {boolean} indefinite
 * @type {boolean} queue
 * @type {string} iconPack
 * @type {string} position
 */
export class NotificationService extends BaseUIService {
  /**
   * @param {array|string} messages
   * @param {NotificationOptions} options
   */
  show(messages, options = {}) {
    options.type = options.type || 'is-dark';
    if (!(messages instanceof Array)) {
      messages = [messages];
      options.duration = options.duration || 7000;
    } else {
      options.duration = options.duration || messages.length * 200000;
      options.queue = false;
    }
    each(messages, (message) => {
      Notification.open(assign(options, { message }));
    });
  }

  /**
   * @param {array|string} messages
   * @param {NotificationOptions} options
   */
  showPersistent(messages, options = {}) {
    options.duration = 600000;
    this.show(messages, options);
  }
}

export default new NotificationService();
