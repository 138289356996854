'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from '../../../../../node_modules/jquery/dist/jquery';
const Main = $.core.main;

angular.module('core.service.services', [])
    .service('ServiceFactory', ServiceFactory)
    .service('ServiceDataFactory', ServiceDataFactory)
    .service('WorkerManagerServiceDataFactory', WorkerManagerServiceDataFactory)
    .service('ServiceOrderItemFactory', ServiceOrderItemFactory)
    .service('MappingCandidateHistoryFactory', MappingCandidateHistoryFactory);;

/**
 * @ngInject
 */
function ServiceFactory(
    AppFactory,
    ServiceResource
) {
    let entityServices = [];

    return {
        getServiceOrders: getServiceOrders,
        getServiceNameDisplay: getServiceNameDisplay,
        getServices: getServices,
        setEntityServices: setEntityServices,
        getEntityServices: getEntityServices
    };

    function getServiceOrders() {
        return ServiceResource.getServiceOrders();
    }

    /**
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getServices(queryParams) {
        return ServiceResource.getServices(queryParams);
    }

    /**
     *
     * @param services
     * @returns {*}
     */
    function setEntityServices(services) {
        entityServices = services;
    }

    /**
     * @returns {[]}
     */
    function getEntityServices() {
        return entityServices;
    }

    /**
     * This renders service name along with icon and color
     * @param serviceId
     * @param size
     * @returns {string}
     */
    function getServiceNameDisplay(serviceId, size) {
        var service = AppFactory.getService(serviceId);
        size = size || 16;
        var html = '';
        if (service) {
            html += '<div class="service-square service-square-' + size + ' mr5" style="background-color:' + service.color + '">';
            html += '<div class="icon serviceicon-' + service.icon + '"></div>';
            html += '</div>';
            html += '<span class="ml5">' + service.name + '</span>';
        }
        return html;
    }
}

/**
 *
 * @ngInject
 */
function ServiceDataFactory(
    Restangular,
    DataViewName,
    ColumnValueType,
    DesignFactoryDelegate,
    LiveIntegrationFactory,
    WorkerManagerServiceDataFactory,
    AppFactory,
    ReportStudioTemplateDataService
) {
    /**
     * The page entity allows for custom service factory to be used
     * @type {null|string}
     */
    var pageEntity = null; // Default

    function setPageEntity(value) {
        pageEntity = value;
    }

    function data(serviceId) {
        return Restangular.service('data', Restangular.one('services', serviceId));
    }

    function dataViews(serviceId) {
        return data(serviceId).one('data_views');

    }

    // Function must be called getColumns and have this order of params since
    // there are many other factories that need to share the same signature
    function getColumns(queryParams, dataSource) {
        if (!_.isEmpty(ReportStudioTemplateDataService.getReport())) {
            _.assign(queryParams, {lang: ReportStudioTemplateDataService.getReport().report_language})
        }

        return data(dataSource.id).one().getList(dataSource.data_view || DataViewName.CAMPAIGN, _.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @param dataSource
     * @returns {*}
     */
    function getColumnValues(fields, dataSource) {
        return data(dataSource.id)
            .one(dataSource.data_view || DataViewName.CAMPAIGN)
            .one('values')
            .one(ColumnValueType.SET)
            .get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @param dataSource
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        return data(dataSource.id)
            .one(dataSource.data_view || DataViewName.CAMPAIGN)
            .one('values')
            .one(ColumnValueType.DISTINCT)
            .get(_.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));
    }

    /**
     * Return service specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        return !_.isNull(pageEntity)
            ? DesignFactoryDelegate.getFactory(pageEntity).getDTOptions()
            : {};
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @param dataSource
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams, dataSource) {
        if (LiveIntegrationFactory.shouldUseLIWorkerManagerService(queryParams.has_live_integration)) {
            return WorkerManagerServiceDataFactory.getData(queryParams, dataSource);
        } else {
            return data(dataSource.id)
                .one()
                .getList(dataSource.data_view || DataViewName.CAMPAIGN, queryParams);
        }
    }

    return {
        setPageEntity: setPageEntity,
        data: data,
        dataViews: dataViews,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getDTOptions: getDTOptions,
        getData: getData
    };
}

/**
 * @ngInject
 */
function WorkerManagerServiceDataFactory(
    DataViewName,
    PusherService,
    WorkerManagerService,
    PusherConstants,
    ColumnValueType,
    HttpCodes
)
{
    async function getData(queryParams, dataSource) {
        const channel = await PusherService.getPusherUserChannel();
        const datasourceId = dataSource.id;
        const dataView = dataSource.data_view || DataViewName.CAMPAIGN;
        const url = `/services/${datasourceId}/data/${dataView}`;
        try {
            const result = await WorkerManagerService.work(
                url, channel, { params: queryParams },
                PusherConstants.PUSHER_POLL_INTERVAL, // 10 secs
                31, // Backend max time out is 5 mins + 10 secs buffer
            );
            if (result.http_code !== HttpCodes.SUCCESS) {
                throw(result);
            }

            // If sEcho is set then data being returned is for datatable
            let extractedData = !_.isUndefined(result.data.sEcho) ? [result.data] : _.values(result.data);

            // Add comparison data if any
            if (result.has_comparison_data) {
                extractedData.has_comparison_data = !!result.has_comparison_data;
            }

            // Add total data if any
            if (result.total_data) {
                // Total data always only contains one item
                extractedData.total_data = _.first(result.total_data);
            }
            extractedData.has_data_discrepancy = result.has_data_discrepancy || false;
            extractedData.show_currency = result.show_currency || false;
            extractedData.$$currency_discrepancy = result.currency_discrepancy || false;

            return extractedData;
        } catch (e) {
            if (e.message) {
                throw {data: {data: [e.message], error: true}};
            } else {
                throw e;
            }
        }
    }

    async function getFieldValues(fields, dataSource, queryParams) {
        const channel = await PusherService.getPusherUserChannel();
        const datasourceId = dataSource.id;
        const dataView = dataSource.data_view || DataViewName.CAMPAIGN;
        queryParams['fields'] = _.isUndefined(fields) ? {} : fields;
        const url = `/services/${datasourceId}/data/${dataView}/values/${ColumnValueType.DISTINCT}`;
        try {
            return await WorkerManagerService.work(url, channel, { params: queryParams }, PusherConstants.PUSHER_POLL_INTERVAL);
        } catch (e) {
            if (e.message) {
                Main.formErrorNotify(e.message);
                throw {data: {data: [e.message], error: true}};
            } else {
                throw e;
            }
        }
    }

    return {
        getData: getData,
        getFieldValues: getFieldValues
    };
}
/**
 * @ngInject
 */
function ServiceOrderItemFactory(Restangular) {

    var serviceorderitems = Restangular.all('services/orderitems');

    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        return serviceorderitems.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Return service specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        return dtOptions;
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @param dataSource
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return serviceorderitems.getList(queryParams);
    }

    return {
        serviceorderitems : serviceorderitems,
        getDTOptions: getDTOptions,
        getColumns: getColumns,
        getData: getData
    };
}
/**
 * @ngInject
 */
function MappingCandidateHistoryFactory(Restangular) {

    var mappingcandidatehistory = Restangular.all('mappingcandidatehistory');

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return mappingcandidatehistory.getList(queryParams);
    }

    return {
        getData: getData
    };
}