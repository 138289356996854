import { ChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/ChartWidgetMetadata';
import { GaugeChartDrawOption } from '@/modules/ta/widget/models/drawoptions/GaugeChartDrawOption';

export class GaugeChartWidgetMetadata extends ChartWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new GaugeChartDrawOption(model.draw_options);
  }
}
