import { ChartWidgetDataService } from '@/modules/ta/widget/services/data/ChartWidgetDataService';

export class SerialChartWidgetDataService extends ChartWidgetDataService {
  /**
   * @param {Widget} widget
   * @returns {*[]|(ApiColumn|*)[]}
   */
  getGroupByColumnsWhenNotDrillingDown(widget) {
    const groupedColumns = widget.getGroupedColumns();
    if (widget.isMultiGrouped() && groupedColumns.length > 1) {
      return [groupedColumns[0], groupedColumns[1]];
    }
    return super.getGroupByColumnsWhenNotDrillingDown(widget);
  }
}
