export const ChatGptSummariesRoute = {
  CHATGPTSUMMARIES: 'chatgptsummaries',
};

export const ChatGptSummariesPathName = {
  CHATGPTSUMMARIES: 'chatgptsummaries',
};

export const ChatGptUserRoles = {
  USER: 'user',
  SYSTEM: 'system',
  ASSISTANT: 'assistant',
};

export const ChatGptUserNames = {
  USER: 'USER',
  SYSTEM: 'SYSTEM',
  ASSISTANT: 'AI ASSISTANT',
};

export const ChatGptDisclaimerText =
  'Note:  ChatGPT™ and AI are emerging technologies and the results should be verified before being used with customers or to make decisions regarding campaigns. TapClicks makes no claims to the accuracy or appropriateness of the information presented.';

export const HumanCuratedCheckMessage =
  'Mark summary as human-curated to send to Executive Summary';

export const TimerSessionKey = 'chatgpt-summary-timer';
export const TimerSessionValues = {
  SHOW: 'show',
  HIDE: 'hide',
};

export const GptWidget = {
  NEW_THREAD_ID: 'NEW_GPT_THREAD_ID',
};

export const ListTypes = {
  DASHBOARD: 'dashboard',
  REPORT_STUDIO: 'report_studio',
};

export const ChatGptSummariesEvents = {
  RESET_ROUTE_DATA: 'reset-route-data',
  CLOSE_PARENT_MODAL: 'close-parent-modal',
};
