import { isNil } from 'lodash';
import ApiSummaryColumn from '@/modules/core/app/models/api/ApiSummaryColumn';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { SortOrder } from '@/modules/core/app/constants/app.constants';

export class CustomApiSummaryColumn extends ApiSummaryColumn {
  constructor(model = {}) {
    super(model);

    if (!model.field) {
      Logger.log('"field" is missing for a CustomApiSummaryColumn', Logger.LEVEL_ERROR);
    }
    this.field = model.field;
    this.label = model.label || model.field;
    this.format = model.format || ColumnFormat.FORMAT_STRING;
    this.default_sort = model.default_sort || SortOrder.DESC;
    this.is_sortable = isNil(model.is_sortable) ? true : model.is_sortable;
    this.values = model.values || [];
    this.has_set_values = model.has_set_values || false;
    this.is_primary_key = model.is_primary_key || false;
    this.is_primary_name_field = model.is_primary_name_field || false;
    this.is_custom_cell_render = model.is_custom_cell_render || false;
    this.is_filterable = model.is_filterable || false;
  }
}
