'use strict';
import angular from 'angular';

angular.module('core.export.services')

    .factory('ExportOptionsFactory', ExportOptionsFactory);

/**
 * @ngInject
 */
function ExportOptionsFactory(
    UIFactory
) {
    var getDefaultState = function() {
        return {
            isShowing: false,
            isExporting: false
        };
    };

    var shared = {
        state: getDefaultState(),
        downloadAllLayouts: true,
        closeModal: closeModal
    };

    return {
        shared: shared,
        init: init,
        closeModal: closeModal
    };

    function resetState() {
        shared.state = getDefaultState();
    }

    function closeModal() {
        resetState();
        UIFactory.hideModal('export-options-modal');
    }

    function init() {
        resetState();
        UIFactory.showModal('export-options-modal', {
            backdrop: true,
            keyboard: true
        });
        shared.state.isShowing = true;
    }
}