export const SupportLinkTypes = {
  EMAIL: 'email',
  URL: 'url',
};

export const PreferenceRoute = {
  PREFERENCES: 'preferences',
};

export const PreferencePathName = {
  PREFERENCES: 'preferences',
};

export const ModalMessages = {
  CONFIRM_ENABLE_GOALS_FOR_AGENTS: `
    This is an experimental feature. Please read the impacts of activating this setting below prior to activating.
    <ul style="list-style: unset; padding: unset;" class="p-l-20 m-t-20 m-b-20">
        <li>ALL Agent type users will be able to see goals for clients and client groups they have access to within the Goals module</li>
        <li>If you give clients, external users, etc. Agent user types and access to the platform, they will be able to see all of the Goals you've created on their behalf</li>
        <li>This is an <i>experimental feature</i> and might be deprecated or activated in different ways in future releases.</li>
        <li>We will communicate all changes to this feature with sufficient time for you to make changes</li>
    </ul>
    Do you still wish to activate this feature?
  `,
};

export const PreferenceLoginMessage = {
  DEFAULT_LOGIN_MESSAGE: 'Let the data visualization begin',
};

export const PreferenceWelcomeMessage = {
  DEFAULT_WELCOME_MESSAGE: 'Welcome!',
};

export const CurrentThemeTypeCacheKey = 'application_theme_type';

export const ApplicationName = 'application_name';
export const ChatGptIntegration = {
  DEFAULT_INITIAL_PROMPT:
    'Generate optimization recommendations in executive summary format for campaigns based on the attached marketing campaign data written like a campaign manager describing to an advertiser who understands marketing. Be concise, highlight recent campaign performance.',
  DEFAULT_API_KEY_TYPE: 'tapclicks_chatgpt_key',
  DEFAULT_MODEL_VERSION: 'gpt_4_o',
};

export const DefaultLogoColors = [
  '#FFFFFF',
  '#00294f',
  '#9e9b9b',
  '#4eaaff',
  '#00ffb5',
  '#009630',
  '#00B19B',
  '#1247A6',
  '#6850C3',
  '#B13BA9',
  '#C91814',
  '#F56021',
  '#F5CB5C',
];

export const TwoStepVerificationModalTitle = {
  ENABLE_TITLE: 'Enable Two Step Verification for your Company?',
  DISABLE_TITLE: 'Disable Two Step Verification for your Company?',
};

export const TwoStepVerificationModalDescription = {
  ENABLE_DESCRIPTION:
    'When you enable this, we’ll prompt all the users to enter the code they receive via email. We won’t prompt for two step verification if the user logs in with Google or advanced SSO. Access to 2FA for specific roles can be managed from the Roles page.',
  DISABLE_DESCRIPTION:
    "All the users in your team will be able to login without confirming their identity with a two step verification. This won't log your users out.",
};
