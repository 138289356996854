import { assign } from 'lodash';
import ApiColumn from '@/modules/core/app/models/api/ApiColumn';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { CategoryDataView } from '@/modules/core/datasource/models/CategoryDataView';
import { GenericDataModel } from '@/modules/core/app/models/GenericDataModel';
import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { GenericModel } from '@/modules/core/app/models/GenericModel';

export class CategoryDataResource extends BaseResource {
  constructor() {
    super(RouteGroup.CATEGORIES);
  }

  /**
   * @param {string} categoryId
   * @param params
   * @returns {Promise<CategoryDataView[]>}
   * */
  async getDataViews(categoryId, params = {}) {
    return super.getData(CategoryDataView, `${categoryId}/dataviews`, params);
  }

  /**
   * @param categoryId
   * @param dataViewId
   * @param params
   * @returns {Promise<GenericDataModel[]>}
   * */
  async getData(categoryId, dataViewId, params = {}) {
    return super.getData(GenericDataModel, `${categoryId}/data/${dataViewId}`, params);
  }

  /**
   * @param categoryId
   * @param dataViewId
   * @param params
   * @returns {Promise<ApiColumn[]>}
   * */
  async getColumns(categoryId, dataViewId, params = {}) {
    params = assign({ metadata: true }, params);
    return super.getData(ApiColumn, `${categoryId}/data/${dataViewId}`, params);
  }

  /**
   *
   * @param categoryId
   * @param dataViewId
   * @param fields
   * @param params
   * @returns {Promise<*>}
   */
  async getValuesDistinct(categoryId, dataViewId, fields, params = {}) {
    params = { ...params, fields };
    return super.getData(GenericModel, `${categoryId}/data/${dataViewId}/values/distinct`, params);
  }
}

export default new CategoryDataResource();
