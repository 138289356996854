'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.leads.models', [])
    .factory('LeadsNotesModelsFactory', LeadsNotesModelsFactory);

/**
 * @ngInject
 */
function LeadsNotesModelsFactory(
    MessengerModelFactory
) {

    return {
        getProps: getProps,
        getLeadNote: getLeadNote,
    };

    /**
     *
     * @param model
     * @constructor
     */
    function StateProps(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.isActive = model.isActive || false;

        /**
         *
         * @returns {*|boolean}
         */
        self.getIsActive = function() {
            return self.isActive;
        };


        self.setInactive = function() {
            self.isActive = false;
        };

        return self;
    }


    /**
     *
     * @param model
     * @constructor
     */
    function DataProps(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.lead = model.lead || null;

        return self;
    }

    /**
     *
     * @param model
     * @constructor
     */
    function Props(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.state = new StateProps(model.state);
        self.data = new DataProps(model.data);
    }

    /**
     *
     * @param note
     * @returns {LeadNote}
     * @constructor
     */
    function LeadNote(model) {
        model = _.isEmpty(model) ? {} : model;

        var self = MessengerModelFactory.getMessageThread.call(this, model);

        self.id = model.id || null;
        self.lead_id = model.lead_id || null;
        self.content = model.content || '';
        self.is_active = model.is_active || true;

        return self;
    }

    /**
     *
     * @param model
     * @returns {Props}
     */
    function getProps(model) {
        var props = new Props(model);
        return props;
    }

    /**
     *
     * @param model
     * @returns {LeadNote}
     */
    function getLeadNote(model) {
        var leadNote = new LeadNote(model);
        return leadNote;
    }

}