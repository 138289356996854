'use strict';
import angular from 'angular';

angular.module('exportbuilder.admin.services', [])
    .factory('ExportBuilderNewReportAdminService', ExportBuilderNewReportAdminService)
    .factory('ExportBuilderAdminDataService', ExportBuilderAdminDataService);

/**
 * @ngInject
 */
function ExportBuilderNewReportAdminService() {

    var selectedLanguage;

    return {
        setSelectedLanguage: setSelectedLanguage,
        getSelectedLanguage: getSelectedLanguage
    };

    function setSelectedLanguage(language) {
        selectedLanguage = language;
    }

    function getSelectedLanguage() {
        return selectedLanguage;
    }
}
/**
 * @ngInject
 */
function ExportBuilderAdminDataService() {

    var selectedReport;

    return {
        setQuickExportReport: setQuickExportReport,
        getQuickExportReport: getQuickExportReport
    };

    function setQuickExportReport(report) {
        selectedReport = report;
    }

    function getQuickExportReport() {
        return selectedReport;
    }
}