/**
 * @class BigNumber
 */
export class BigNumber {
  /**
   * @var {String/Number}
   */
  value;

  /**
   * Background color of the circle
   * @var  {string}
   */
  fill;

  /**
   * position of the number
   * @var {string}
   */
  position;

  /**
   * interior position of the number when it's not on the hand
   * @var {string}
   */
  interiorPosition;

  /**
   * Format of the field
   * @var {string}
   */
  format;

  /**
   * opacity
   * @var {number}
   */
  opacity;

  constructor(model = {}) {
    this.value = model.value;
    this.fill = model.fill;
    this.position = model.position;
    this.format = model.format;
    this.interiorPosition = model.interiorPosition;
    this.opacity = model.isComparison ? 0.6 : 1;
  }
}
