'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('role.ctrls', [])

    .controller('RoleListController', RoleListController)
    .controller('RoleController', RoleController);

//
// Role grid controller
//
RoleListController.$inject = ['$scope', 'RoleService'];
function RoleListController($scope, RoleService) {
    var options = $.core.datatable.defaultOptions('role');
    options.iActions = 1;
    $.core.datatable.build($('#role-table'), options);
}

//
// Role controller
//
RoleController.$inject = ['$scope', '$timeout', 'RoleService', 'AppFactory', 'gettextCatalog', 'UserType', 'AppModule'];
function RoleController($scope, $timeout, RoleService, AppFactory, gettextCatalog, UserType, AppModule) {
    $scope.init = function(canEdit) {
        if (!canEdit.bool()) {
            $('#main-form').find('select, input').disableElement();
        } else {
            $('#role_association').change(function() {
                $timeout(function () {
                    $scope.$evalAsync();
                });
            });
        }
        
    };
    
    $scope.showReportStudioManagement = function () {
        const selectedAssociation = getRoleAssociationValue();
        
        if (selectedAssociation === UserType.AGENT) {
            return AppFactory.getUser().isModuleAvailable(AppModule.REPORT_STUDIO)
              && AppFactory.getUser().isModuleAvailable(AppModule.REPORT_STUDIO_AGENT_ACCESS);
        }
        
        if (selectedAssociation === UserType.CLUSTERADMIN) {
            return AppFactory.getUser().isModuleAvailable(AppModule.REPORT_STUDIO)
        }
    
        return false;
    };
    
    $scope.getAssociationText = function () {
        const selectedAssociation = getRoleAssociationValue();
        let text = gettextCatalog.getString('Users');
        
        if (selectedAssociation === UserType.AGENT) {
            return gettextCatalog.getString('Agents');
        }
        
        if (selectedAssociation === UserType.CLUSTERADMIN) {
            return gettextCatalog.getString('Business unit admins');
        }
        
        return text;
    };
    
    function getRoleAssociationValue() {
        const selectedAssociation = $('select#role_association').children('option:selected').val();
        if (selectedAssociation) {
            return selectedAssociation
        }
        
        return $('input#role_association').attr('value');
    }

    $.core.admin.role.initialize();
}