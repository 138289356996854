'use strict';
import angular from 'angular';
import swal from 'bootstrap-sweetalert';

angular.module('core.tag.resources', [])
    .factory('TagResource', TagResource)
    .factory('TagDataGridFactory', TagDataGridFactory)
    .factory('TagUIResource', TagUIResource);

/**
 * @ngInject
 */
function TagResource(
    Restangular
) {
    var resource = Restangular.all('tags');

    return {
        getTagValuesGrid: getTagValuesGrid,
        getResource: getResource,
        get: get,
        getData: getData
    };

    /**
     * Returns leads history for an end customer
     *
     * @param endCustomerId
     * @returns {*|restangular.ICollectionPromise<any>}
     */
    function getTagValuesGrid(tagId) {
        return resource.all(tagId).all('tagvaluesgrid').getList();
    }

    function get(tagId) {
        return resource.one(tagId).get({all: true});
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        queryParams = queryParams || {};
        queryParams.all = true;
        return resource.getList(queryParams);
    }

    /**
     * Getter for mainresource of class
     * @returns {*}
     */
    function getResource() {
        return resource;
    }
}
/**
 * @ngInject
 */
function TagDataGridFactory(
    TagColumns,
    TagFactory,
    DataGridRender,
    TagUIResource,
    LayoutFactory
) {
    return {
        getDTOptions: getDTOptions,
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};

        dtOptions.customRenders = {};
        dtOptions.numActions = 2;
        dtOptions.customRenders[TagColumns.ID] = function (data, type, full) {
            var html = '';

            html = DataGridRender.getEditDetailsButton(TagColumns.ID, full.can_be_edited);
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'tags/' + data, 'this tag and all associated tag values');

            return html;
        };

        dtOptions.customRenders[TagColumns.TAG] = function (data, type, full) {
            return TagUIResource.renderTagHTML(data, full);
        };

        dtOptions.customRenders[TagColumns.ASSOCIATED_TAG_VALUES] = function (data, type, full) {
            return '<span>' + (data ? data.length : 0) + '</span>';
        };

        dtOptions.postCreatedRowCallback = function($link, row, data) {
            var field = $link.data('field');
            switch (field) {
                case TagColumns.ID:
                    $link.click(function () {
                        TagFactory.setEditDetails(data, row);
                    });
                    break;
            }
        };

        dtOptions.actionCallbacks = {};
        dtOptions.actionCallbacks.delete = _refreshTags;

        return dtOptions;
    }

    function _refreshTags() {
        TagFactory.getTags(true);
        LayoutFactory.$rebuildAllWidgets();
        swal('Deleted!', 'Tag has been deleted', 'success');
    }
}

/**
 * @ngInject
 */
function TagUIResource(
    UIColor
) {
    return {
        renderTagHTML: renderTagHTML,
        renderTagHeader: renderTagHeader
    };

    /**
     * Renders Tag html for datagrid cell
     * @returns {string}
     */
    function renderTagHTML(data, full) {
        var textColor = UIColor.textColorWithBackgroundColor(full.color);
        var style = 'style="background-color:'+ full.color + '; border-color:' + full.color + '; color:' + textColor + ';"';
        return '<span class="badge tag-badge no-click"'+ style +'><i class="icon icon-tag" style="color:'+ textColor +'"></i>' + data + '</span>';

    }

    /**
     * Renders Tag html for datagrid cell
     * @returns {string}
     */
    function renderTagHeader(data) {
        return renderTagHTML(data.tag, data)
    }
}