import angular from 'angular';
import _ from 'lodash';

import datagridTagPickerHtmlUrl from './datagrid.tagpicker.html';

angular.module('datagrid.tagpicker.components', [])
    .component('datagridTagPicker', {
        templateUrl: datagridTagPickerHtmlUrl,
        bindings: {
            widgetId: '<'
        },
        controllerAs: 'vm',
        controller: DatagridTagPickerController
    });

/**
 * @ngInject
 * @constructor
 */
function DatagridTagPickerController(
    $element,
    $timeout,
    $DatagridTagPickerEvents,
    $TapColorsEvents,
    $UISelect2Events,
    $KeyboardServiceEvents,
    UIColor,
    PubSub,
    TagPickerModelFactory,
    TagPickerFactory,
    KeyboardAdapterService,
    UIFactory,
    TagPickerSelectConstant,
    TagPickerSize,
    WindowUtilUIService
) {
    var vm = this;
    vm.state = TagPickerModelFactory.getTagPickerState();
    vm.data = null;
    vm.tapColorId = 'TagPicker-TapColor-Id';
    vm.tagPickerWidth = 0;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onDestroy = $onDestroy;

    vm.showPicker = showPicker;
    vm.onChangeCallback = onChangeCallback;
    vm.onColorChange = onColorChange;
    vm.onColorPickerOpen = onColorPickerOpen;
    vm.onColorPickerClose = onColorPickerClose;
    vm.onTextChangeCallback = onTextChangeCallback;
    vm.onMouseClickedOutside = onMouseClickedOutside;
    vm.onSelect2Open = onSelect2Open;
    vm.onSelect2Close = onSelect2Close;

    function $onInit() {
        _registerEvents();
    }

    function $postLink() {
        $element.appendTo(document.body);
    }

    function $onDestroy() {
        _unregisterEvents();
        $element.remove();
    }

    function showPicker() {
        return vm.state.isActive;
    }

    function onChangeCallback($el) {
        if (vm.state.isLoading || vm.state.isSaving) return;

        var data = $el.select2('data');
        if (!data) {
            _removeTag();
            return;
        }
        if (data.id === TagPickerSelectConstant.PLACEHOLDER_ID && !_.isEmpty(vm.data.searchText)) {
            _updateTagWithText(vm.data.searchText);
        } else if (data.id === TagPickerSelectConstant.PLACEHOLDER_ID && _.isEmpty(vm.data.searchText)) {
            _closePicker();
        } else {
            vm.data.currentTag.color = data.color;
            vm.data.currentTag.tag = data.text;
            vm.data.currentTag.tag_id = data.id;
            _saveCurrentTagSelection();
        }
    }

    function onColorPickerOpen() {
        $timeout(function () {
            vm.state.isPickerOpen = true;
        }, 0);
        KeyboardAdapterService.off($KeyboardServiceEvents.ENTER, _enterKeyPressed);
    }

    function onColorPickerClose() {
        $timeout(function () { // race condition if TapColors uses `enter` key to enter a custom color
            vm.state.isPickerOpen = false;
            KeyboardAdapterService.on($KeyboardServiceEvents.ENTER, _enterKeyPressed);
        }, 0, false);
    }

    function onColorChange(newColor) {
        vm.selectOptions = TagPickerModelFactory.getTagSelectOptions(vm.data.availableTags);
        vm.data.setNewColor(newColor);
        KeyboardAdapterService.on($KeyboardServiceEvents.ENTER, _enterKeyPressed);
    }

    function onTextChangeCallback(text) {
        vm.data.searchText = text;
    }

    function onMouseClickedOutside() {
        if (!vm.state.isActive) {
            return;
        }

        if (vm.state.isPickerOpen) {
            vm.state.isPickerOpen = false;
        } else {
            _closePicker();
        }
    }

    function onSelect2Open() {
        PubSub.emit($TapColorsEvents.FORCE_CLOSE + vm.tapColorId);
        KeyboardAdapterService.off($KeyboardServiceEvents.ENTER, _enterKeyPressed);
    }

    function onSelect2Close() {
        KeyboardAdapterService.on($KeyboardServiceEvents.ENTER, _enterKeyPressed);
    }

    /**
     * Private Methods
     */

    /**
     * @param {TagPickerEventModel} event
     * @private
     */
    function _initPicker(event) {
        $timeout(function () {
            vm.state.isLoading = true;
            vm.tagPickerPosition = event.cellPosition;
            WindowUtilUIService.disableScroll(['select2']);
            WindowUtilUIService.onWindowResize(_onWindowResize);
            $timeout(function () {
                vm.selectOptions = TagPickerModelFactory.getTagSelectOptions();

                // Reset when already active to refresh select2
                if (!vm.state.isActive) {
                    KeyboardAdapterService.on($KeyboardServiceEvents.ESCAPE, _escapeKeyPressed);
                    KeyboardAdapterService.on($KeyboardServiceEvents.ENTER, _enterKeyPressed);
                }
                vm.state.isActive = true;
                TagPickerFactory.getTags()
                    .then(function (tags) {
                        vm.selectOptions = TagPickerModelFactory.getTagSelectOptions();
                        vm.selectOptions.rebuild = true;
                        vm.state.isEditing = !!event.tag;
                        vm.data = TagPickerModelFactory.getTagPickerModel(event.dataSourceType, event.value, event.rowData, tags, event.tag);
                    })
                    .catch(function (e) {
                        e && UIFactory.notify.showError(e.msg);
                        vm.state.isActive = false;
                    })
                    .finally(function () {
                        vm.state.isLoading = false;
                        vm.state.doneLoading = true;
                        $timeout(function () {
                            vm.tagPickerPosition = {
                                x: event.position.x,
                                y: event.position.y,
                                width: TagPickerSize.WIDTH,
                                height: TagPickerSize.HEIGHT
                            };
                        }, 0);
                    });
            }, 100);
        }, 0, false);
    }

    /**
     * Escape key Callback
     * @private
     */
    function _escapeKeyPressed() {
        _closePicker();
    }

    /**
     * Enter Key Callback
     * @private
     */
    function _enterKeyPressed() {
        if (vm.state.isLoading || vm.state.isSaving) return;

        $timeout(function () {
            vm.selectOptions = TagPickerModelFactory.getTagSelectOptions(vm.data.availableTags);
        }, 0);

        if (!_.isEmpty(vm.data.searchText) && vm.state.isEditing) {
            vm.state.isSaving = true;
            TagPickerFactory.updateTagWithText(vm.data.currentTag, vm.data.searchText, vm.state.isEditing)
                .then(function (tag) {
                    UIFactory.notify.showSuccess('Tag ' + tag.value + ' updated.');
                    _closePicker();
                })
                .catch(function (e) {
                    UIFactory.notify.showError(e.msg)
                })
                .finally(function () {
                    vm.state.isSaving = false;
                });
        } else if (vm.state.isEditing) {
            vm.state.isSaving = true;
            TagPickerFactory.updateTag(vm.data.currentTag, vm.state.isEditing)
                .then(function (tag) {
                    UIFactory.notify.showSuccess('Tag ' + vm.data.currentTag.value + ' updated.');
                    _closePicker();
                })
                .catch(function (e) {
                    UIFactory.notify.showError(e.msg)
                })
                .finally(function () {
                    vm.state.isSaving = false;
                });
        } else {
            _saveCurrentTagSelection();
        }
    }

    /**
     *
     * @private
     */
    function _removeTag() {
        vm.state.isSaving = true;
        TagPickerFactory.removeTag(vm.data.currentTag)
            .then(function (tag) {
                UIFactory.notify.showSuccess('Tag value' + vm.data.currentTag.value + ' removed.');
                _closePicker();
            })
            .catch(function (e) {
                UIFactory.notify.showError(e.msg)
            })
            .finally(function () {
                vm.state.isSaving = false;
            });
    }

    /**
     *
     * @param searchText
     * @private
     */
    function _updateTagWithText(searchText) {
        vm.state.isSaving = true;
        vm.data.currentTag.tag = searchText;
        TagPickerFactory.updateTagWithText(vm.data.currentTag, vm.data.searchText, vm.state.isEditing)
            .then(function (tag) {
                _closePicker();
                UIFactory.notify.showSuccess('Tag ' + tag.value + ' updated.');
            })
            .catch(function (e) {
                e && UIFactory.notify.showError(e.msg);
            })
            .finally(function () {
                vm.state.isSaving = false;
            });
    }

    /**
     * Saves tag model
     * @private
     */
    function _saveCurrentTagSelection() {
        vm.state.isSaving = true;
        TagPickerFactory.setTag(vm.data.currentTag, vm.state.isEditing)
            .then(function (tag) {
                UIFactory.notify.showSuccess('Tag ' + vm.data.currentTag.value + ' updated.');
                _closePicker();
            })
            .catch(function (e) {
                UIFactory.notify.showError(e.msg)
            })
            .finally(function () {
                vm.state.isSaving = false;
            });
    }

    function _closePicker() {
        vm.state.isPickerOpen = false;
        vm.state.isActive = false;
        PubSub.emit($UISelect2Events.CLOSE);
        _unregisterKeyboardEvents();
        _unregisterWindowEvents();
    }

    function _onWindowResize() {
        _closePicker();
    }

    function _registerEvents() {
        PubSub.on($DatagridTagPickerEvents.CELL_CLICKED + vm.widgetId, _initPicker);
    }

    function _unregisterKeyboardEvents() {
        KeyboardAdapterService.off($KeyboardServiceEvents.ESCAPE, _escapeKeyPressed);
        KeyboardAdapterService.off($KeyboardServiceEvents.ENTER, _enterKeyPressed);
    }

    function _unregisterWindowEvents() {
        WindowUtilUIService.enableScroll();
        WindowUtilUIService.offWindowResize(_onWindowResize);
    }

    function _unregisterEvents() {
        PubSub.off($DatagridTagPickerEvents.CELL_CLICKED + vm.widgetId, _initPicker);
        _unregisterKeyboardEvents();
        _unregisterWindowEvents();
    }
}