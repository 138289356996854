'use strict';
import angular from 'angular';
import $ from 'jquery';
import './endcustomer.constants';
import './base/endcustomer.components';
import './base/endcustomer.services';
import './base/endcustomer.resources';

import manageEndCustomersHtmlUrl from './base/manageendcustomers.html';

angular.module('core.endcustomer', [
    'core.endcustomer.constants',
    'core.endcustomer.components',
    'core.endcustomer.services',
    'core.endcustomer.resources'
])

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('manageendcustomers', {
                parent:'layout',
                url: '/manageendcustomers',
                resolve: {
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_MANAGE_END_CUSTOMERS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: manageEndCustomersHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);