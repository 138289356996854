'use strict';
import angular from 'angular';
import $ from 'jquery';
import widgetFilterModalHtmlUrl from './widget.filter.modal.html';
import widgetFilterColumnsHtmlUrl from './widget.filter.columns.html';
import widgetColumnSelectHtmlUrl from '../../base/widget.column.select.html';
import widgetFilterTogglerHtmlUrl from './widget.filter.toggler.html';
import widgetFilterSummaryHtmlUrl from './widget.filter.summary.html';
import widgetFilterDropzoneHtmlUrl from './widget.filter.dropzone.html';

angular.module('widget.filter.directives', [])

    .directive('filterWidgetDataModal', filterWidgetDataModal)
    .directive('filterColumnsConfig', filterColumnsConfig)
    .directive('widgetFilterbySelect', widgetFilterbySelect)
    .directive('widgetRemoveFilteredColumn', widgetRemoveFilteredColumn)
    .directive('filterPanel', filterPanel)
    .directive('filterDropZone', filterDropZone)
    .directive('filterToggler', filterToggler);

/**
 * @ngInject
 */
function filterWidgetDataModal() {
    return {
        restrict: 'E',
        templateUrl: widgetFilterModalHtmlUrl,
        controller: 'FilterWidgetDataController',
        scope: {},
        link: function(scope, el) {
            var $modal = el.find('div.modal');

            // Reset variables when closing widget create modal
            $modal.on('hidden', function () {
                scope.resetFilterWidget();
            });

            // Trigger when widget creation is done
            scope.$watch('state.isDoneFiltering', function(nV) {
                if (nV === true) {
                    $modal.modal('hide');
                }
            });
        }
    }
}

/**
 * Data columns selection panel
 * @returns {{restrict: string, controller: string, templateUrl, scope: {currentModule: string, metadata: string, state: string}}}
 */
function filterColumnsConfig() {
    return {
        restrict: 'E',
        templateUrl: widgetFilterColumnsHtmlUrl,
        scope: {
            datasource: '<',
            selectedColumns: '=',
            liveIntegration: '=',
            loadingState: '=',
            state: '=',
            widget: '<'
        },
        controller: function($scope) {
            $scope.filterDuplicate = filterDuplicate
            function filterDuplicate(item) {
                return item.is_hidden !== true;
            }
        }
    }
}

/**
 * Selectable filter columns select2
 * @type {string[]}
 */
function widgetFilterbySelect() {
    return {
        restrict: 'A',
        controller: 'FilterColumnsController',
        templateUrl: widgetColumnSelectHtmlUrl,
        scope: {
            datasource: '=',
            selectedColumns: '=',
            isLoadingColumns: '=',
            liveIntegration: '=',
            loadingState: '=',
            state: '=',
            widget: '<'
        }
    }
}

/**
 * Remove grouped column
 * @ngInject
 */
function widgetRemoveFilteredColumn() {
    return {
        restrict: 'A',
        controller: 'WidgetRemoveFilteredColumnController',
        scope: {
            column: '=',
            selectedColumns: '=',
            state: '='
        },
        link: function(scope, el) {
            el.click(function() {
                scope.$evalAsync(function() {
                    if (!$(el).hasClass('disabled')) {
                        scope.removeColumn(scope.column);
                    }
                });
            });
        }
    }
}

/**
 * @ngInject
 */
function filterToggler() {
    return {
        restrict: 'E',
        templateUrl: widgetFilterTogglerHtmlUrl,
        scope: {
            state: '=',
            widget: '='
        },
        controller: 'FilterTogglerController',
        link: function(scope, el) {
            var $toggler = el.find('div.filter-toggler');

            scope.triggerFilterPanel = function() {
                $toggler.tooltip('hide');
                scope.state.showFilterPanel = !scope.state.showFilterPanel;
            };
        }
    }
}

/**
 * Widget filter summary panel
 * @ngInject
 */
function filterPanel() {
    return {
        restrict: 'E',
        templateUrl: widgetFilterSummaryHtmlUrl,
        scope: {
            showFilterPanel: '=',
            filters: '<',
            columnColor: '<',
            widgetId: '<'
        },
        controller: 'FilterPanelController'
    }
}

/**
 * Widget filter dropzone
 * @ngInject
 */
function filterDropZone() {
    return {
        restrict: 'E',
        templateUrl: widgetFilterDropzoneHtmlUrl,
        scope: {
            showFilterDropZone: '=',
            state: '=',
            widget: '='
        },
        controller: 'FilterDropZoneController'
    }
}
