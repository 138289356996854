import { DataProcessor } from '@amcharts/amcharts5';
import { Constant, SeriesType } from '@/modules/core/charts/am5/charts.constants';
import {
  LineSeries,
  SmoothedXLineSeries,
  SmoothedXYLineSeries,
  SmoothedYLineSeries,
  StepLineSeries,
} from '@amcharts/amcharts5/xy';
import { useXYAxes } from '@/modules/core/charts/am5/base/composables/axis/useXYAxes';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { useXYTooltip } from '@/modules/core/charts/am5/base/composables/useXYTooltip';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';

export function useSeries(context) {
  const { root, chart, config } = context();
  const { getXAxis, getYAxis, getSeriesAxesProps } = useXYAxes(context);
  const { getNewDefaultTooltip } = useXYTooltip(context);
  const { getLegendPlaceholderString } = useNumberFormatter(context);

  function createAllSeries() {
    const { isStacked } = config.value;
    const series = isStacked ? config.value.series.slice().reverse() : config.value.series;
    series.forEach((props) => createSeries(props));
  }

  function createLineSeries() {
    const { isStacked } = config.value;
    const series = isStacked ? config.value.series.slice().reverse() : config.value.series;
    const lineSeriesTypes = [
      SeriesType.LINE,
      SeriesType.SMOOTHEDLINE_X,
      SeriesType.SMOOTHEDLINE_Y,
      SeriesType.SMOOTHEDLINE_XY,
    ];
    series.forEach((props) => {
      if (lineSeriesTypes.includes(props.seriesType)) {
        createSeries(props);
      }
    });
  }

  function createSeries(props) {
    const SeriesClass = getSeriesClass(props.seriesType);
    const xAxis = getXAxis(config.value.isRotated ? props.axisIndex : props.categoryAxisIndex);
    const yAxis = getYAxis(config.value.isRotated ? props.categoryAxisIndex : props.axisIndex);
    const series = chart.value.series.push(
      SeriesClass.new(root.value, {
        xAxis,
        yAxis,
        ...getGenericSeriesProps(props),
      })
    );
    series.set(Constant.USER_DATA, props);
    if (props.fill) {
      series.fills.template.setAll({
        visible: true,
        fillOpacity: props.fillOpacity,
      });
    }

    series.strokes?.template.setAll(
      omitNils({
        strokeWidth: props.strokeWidth,
        strokeOpacity: props.strokeOpacity,
      })
    );

    series.data.processor = DataProcessor.new(root.value, {
      numericFields: [props.value],
    });
    return series;
  }

  function getGenericSeriesProps(props) {
    const { isRotated, isFullStacked } = config.value;
    const seriesAxesProps = getSeriesAxesProps(props);

    return omitNils({
      name: props.name,
      fill: color(props.color),
      fillOpacity: color(props.fillOpacity),
      legendLabelText: props.name,
      legendValueText: getLegendPlaceholderString(props.value, props.dataItemFormat),
      // empty value so it doesn't display a currency symbol after mouseout event
      legendRangeValueText: '',
      stroke: color(props.color),
      connect: false,
      stacked: props.stacked,
      stackToNegative: false,
      maskBullets: false,
      valueYShow: isFullStacked && !isRotated ? Constant.VALUE_Y_TOTAL_PERCENT : null,
      valueXShow: isFullStacked && isRotated ? Constant.VALUE_X_TOTAL_PERCENT : null,
      tooltip: getNewDefaultTooltip(),
      ...seriesAxesProps,
      noRisers: props.noRisers,
    });
  }

  function getSeriesClass(type) {
    switch (type) {
      case SeriesType.LINE:
        return LineSeries;
      case SeriesType.SMOOTHEDLINE_X:
        return SmoothedXLineSeries;
      case SeriesType.SMOOTHEDLINE_Y:
        return SmoothedYLineSeries;
      case SeriesType.SMOOTHEDLINE_XY:
        return SmoothedXYLineSeries;
      case SeriesType.COLUMN:
      case SeriesType.STEP_LINE:
        return StepLineSeries;

      default:
        // eslint-disable-next-line tap/no-raw-text-js
        Logger.log(`SeriesType ${type} is not supported`, Logger.LEVEL_ERROR);
    }
  }

  return {
    createLineSeries,
    createAllSeries,
    createSeries,
    getSeriesClass,
  };
}
