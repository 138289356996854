'use strict';
import angular from 'angular';
import $ from 'jquery';
import DevTools from '../../devtools';
let Core = $.core;
import hljs from 'highlight.js';
//import 'owl.carousel';


angular.module('core.devtools.ctrls', [])
    .controller('DevToolsWidgetOptionsController', DevToolsWidgetOptionsController)
    .controller('DevToolsWidgetSqlModalController', DevToolsWidgetSqlModalController)
    .controller('DevToolsWidgetTimeProfileModalController', DevToolsWidgetTimeProfileModalController)
    .controller('DevToolsWidgetBugModalController', DevToolsWidgetBugModalController);

DevToolsWidgetOptionsController.$inject = [
    '$scope',
    'AppConfig',
    'WidgetFactory',
];
function DevToolsWidgetOptionsController(
    $scope,
    AppConfig,
    WidgetFactory
) {

    var widgetId = $scope.widget.id;
    $scope.devWidget = DevTools.widgets[widgetId];

    /**
     *  Call data source API endpoint
     */
    $scope.refreshWidget = function () {
        WidgetFactory.$rebuildWidget(widgetId, false);
    };

    /**
     *  Call data source API endpoint
     */
    $scope.makeDataApiCall = function () {
        window.open($scope.devWidget.request, '_blank');
    };

    /**
     * Call design widget API endpoint
     */
    $scope.makeWidgetApiCall = function () {
        window.open(AppConfig.API_URL + 'dash/widgets/' + widgetId + '?all=1&extra=1', '_blank');
    };

    /**
     * Trigger SQL call modal
     */
    $scope.showSqlModal = function () {
        $scope.$root.$broadcast('devTools:showSqlModal', $scope.devWidget.datasql);
    };

    /**
     * Trigger Api time profile modal
     */
    $scope.showApiTimeProfileModal = function () {
        $scope.$root.$broadcast('devTools:showApiTimeProfileModal', $scope.devWidget.timeProfile);
    };

    /**
     * Trigger widget error modal
     */
    $scope.showBugModal = function () {
        $scope.$root.$broadcast('devTools:showBugModal', widgetId, $scope.devWidget.bug);
    };
}

/**
 * @ngInject
 */
function DevToolsWidgetSqlModalController($scope, $timeout) {
    $scope.sqlStatement = null;
    $scope.$on('devTools:showSqlModal', function(e, sql) {
        $scope.sqlStatement = sql;

        // Angular binding doesn't play well with hljs
        // Need to do it directly using DOM manipulation
        $timeout(function() {
            var $code = $('#widget-sql-modal').find('code');
            $code.html(sql);
            $code.each(function (i, block) {
                hljs.highlightBlock(block);
            });
        }, 0);
    });
}

DevToolsWidgetTimeProfileModalController.$inject = ['$scope'];
function DevToolsWidgetTimeProfileModalController($scope) {
    $scope.$on('devTools:showApiTimeProfileModal', function(e, timeProfile) {
        $scope.timeProfile = timeProfile;
    });
}

DevToolsWidgetBugModalController.$inject = ['$scope', '$timeout'];
function DevToolsWidgetBugModalController($scope, $timeout) {
    $scope.widgetId = null;
    $scope.bug = null;
    $scope.$on('devTools:showBugModal', function(e, widgetId, bug) {
        $scope.widgetId = widgetId;
        $scope.bug = bug;

        // Angular binding doesn't play well with hljs
        // Need to do it directly using DOM manipulation
        $timeout(function() {
            var $code = $('#widget-bug-modal').find('code');
            $code.html(bug.errorMsg);
            $code.each(function (i, block) {
                hljs.highlightBlock(block);
            });
        }, 0);
    });

    /**
     * Returns the appropriate bug image based on error type
     */
    $scope.getBugImage = function () {
        return Core.getAnalyticsAssetPath('images/' + $scope.bug.errorType + '-bug.png');
    };

    /**
     * Create JIRA ticket for a widget
     */
    $scope.createJiraTicket = function () {
        return DevTools.createJiraTicket($scope.widgetId);
    };

    /**
     * Post widget error to slack #buglogger channel
     */
    $scope.postToSlack = function () {
        return DevTools.postToSlack($scope.bug);
    };
}