import { ConditionalType, ConditionalTypeRuleValues } from 'coreModules/design/widget/design.widget.constants';
import _ from "lodash";
/**
 * Re-usable utility functions for conditional plot type
 */
export class WidgetBuilderConditionalPlotService {
    /**
     * Helper function get the initial condition rule
     * @param condition
     * @param color
     * @returns {{condition, min: number, color, max: number}}
     */
    getConditionalRule(condition, color){
        return {
          condition: condition,
          min: 0,
          max: 0,
          color: color,
        };
   }

    /**
     * Helper function to remove already selected dropdown selected values
     * @param appliedRules
     * @param dropDownValues
     * @returns {*}
     */
   removeSelectedDropdownValue(appliedRules, dropDownValues) {
       if (appliedRules.includes(ConditionalType.GREATER_THAN)) {
           dropDownValues = _.filter(dropDownValues, (data) => data.id !== ConditionalType.GREATER_THAN);
       }
       if (appliedRules.includes(ConditionalType.LESS_THAN)) {
           dropDownValues = _.filter(dropDownValues, (data) =>  data.id !== ConditionalType.LESS_THAN);
       }
       return dropDownValues;
   }

    /**
     * Helper function to validate the rule
     * @param rules
     * @param currentRule
     * @returns {string}
     */
    validateConditionalRule(rules, currentRule, index){
       let message ='';
        const remainingRules = _.filter(rules, (value, i) => i !== index);
        switch (currentRule['condition']) {
            case ConditionalType.LESS_THAN:
                message = this.validateConditionalRuleLessthan(currentRule, remainingRules);
                break;
            case ConditionalType.BETWEEN:
                message = this.validateConditionalRuleBetween(currentRule, remainingRules);
                break;
            default:
                message = this.validateConditionalRuleGreaterthan(currentRule, remainingRules);
        }

        return message;
    }

    /**
     * Helper function to validate greater than rule
     * @param currentRule
     * @param remainingRules
     * @returns {string}
     */
    validateConditionalRuleGreaterthan(currentRule, remainingRules) {
        for(let i = 0; i < remainingRules.length; i++) {
            const subRule = remainingRules[i];
            if (this.isLessThan(subRule['condition']) && currentRule[ConditionalTypeRuleValues.MIN] < subRule[ConditionalTypeRuleValues.MIN]) {
                return "GREATER THAN and LESS THAN";
            }
            if (this.isBetween(subRule['condition']) && ( subRule[ConditionalTypeRuleValues.MIN] > subRule[ConditionalTypeRuleValues.MAX] ||
                currentRule[ConditionalTypeRuleValues.MIN] < subRule[ConditionalTypeRuleValues.MIN] ||
                currentRule[ConditionalTypeRuleValues.MIN] < subRule[ConditionalTypeRuleValues.MAX]) ) {
                 return  "GREATER THAN and BETWEEN";
            }
        }
        return '';
    }

    /**
     * Helper function to validate less than rule
     * @param currentRule
     * @param remainingRules
     * @returns {string}
     */
   validateConditionalRuleLessthan(currentRule, remainingRules) {
        for(let i = 0; i < remainingRules.length; i++) {
            const subRule = remainingRules[i];
            if (this.isGreaterThan(subRule['condition']) && currentRule['min'] > subRule['min']) {
                return  "LESS THAN and GREATER THAN";

            }
            if (this.isBetween(subRule['condition']) &&
               (subRule['min'] > subRule['max'] || currentRule['min'] > subRule['min'] || currentRule['min'] > subRule['max']) ) {
                 return  "LESS THAN and BETWEEN";
            }
       }
       return '';
   }

    /**
     * Helper function to validate between rule
     * @param currentRule
     * @param remainingRules
     * @returns {string}
     */
    validateConditionalRuleBetween(currentRule, remainingRules) {
        if (currentRule[ConditionalTypeRuleValues.MIN] > currentRule[ConditionalTypeRuleValues.MAX]) {
            return  'Min value cannot be greater than max value';
        }

        for(let i = 0; i < remainingRules.length; i++) {
            const subRule = remainingRules[i];
            if (this.isGreaterThan(subRule['condition']) && currentRule['min'] > subRule['min'] && subRule['min'] < currentRule['max']) {
                return "BETWEEN and GREATER THAN";
            }
            if (this.isLessThan(subRule['condition']) && currentRule['min'] < subRule['min'] && subRule['min'] > currentRule['max']) {
                return "BETWEEN and LESS THAN";
            }
            if (this.isBetween(subRule['condition'])) {
                if (subRule['min'] > subRule['max'] || (currentRule['max'] > subRule['min'] &&
                    currentRule['max'] < subRule['max']) || (currentRule['min'] > subRule['min'] && currentRule['min'] < subRule['max'])) {
                    return "BETWEEN and BETWEEN";
                }
            }
        }
        return '';
    }

    /**
     * @param condition
     * @returns {boolean}
     */
    isGreaterThan(condition = '') {
        return condition === ConditionalType.GREATER_THAN;
    }

    /**
     * @param condition
     * @returns {boolean}
     */
    isLessThan(condition = '') {
        return condition === ConditionalType.LESS_THAN;
    }

    /**
     * @param condition
     * @returns {boolean}
     */
    isBetween(condition = '') {
        return condition === ConditionalType.BETWEEN;
    }
}
export default new WidgetBuilderConditionalPlotService();