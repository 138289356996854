export class DateRangeState {
  /**
   * @var {DateRange}
   */
  dateRange;

  /**
   * @var {Array.<RelativeDateRange>}
   */
  relativeDateRanges;
}
