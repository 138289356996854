'use strict';
import angular from 'angular';

angular.module('widget.create.services')

    .constant('$WidgetTypeEvents', $EventConstants())
    .factory('WidgetCreateTypeFactory', WidgetCreateTypeFactory);

function $EventConstants() {
    return {
        TOGGLE_TYPE_STATE: 'widget:create:TOGGLE_TYPE_STATE',
    };
}

/**
 * @ngInject
 */
function WidgetCreateTypeFactory(
    $WidgetTypeEvents,
    ScopeFactory,
    WidgetCreateFactory,
    WidgetFactory
) {
    var $widgetTypeListScope;

    /**
     * Local implementation of $registerScope
     * @param scope
     */
    function $registerScope(scope) {
        $widgetTypeListScope = ScopeFactory.$registerScope(scope, 'widget-type-selection-list');
    }

    /**
     * Proxy for changing a widget's type
     * @param typeId
     */
    function $changeType(typeId) {
        $toggleTypeState();
        $widgetTypeListScope.changeWidgetType(WidgetFactory.getWidgetType(typeId));
    }

    /**
     * Sets the different widgets types to either enabled or disabled and their appropriate tooltips
     * NOTE: we use broadcast when we have multiple child scopes to target
     */
    function $toggleTypeState() {
        WidgetCreateFactory.$getScope().$broadcast($WidgetTypeEvents.TOGGLE_TYPE_STATE);
    }

    return {
        $registerScope: $registerScope,
        $changeType: $changeType,
        $toggleTypeState: $toggleTypeState
    }
}