'use strict';
import angular from 'angular';
import _ from 'lodash';
import exportPageHtmlUrl from './export.page.html';

angular.module('core.export.directives')

    .directive('designPageExport', designPageExport);

/**
 * @ngInject
 */
function designPageExport(
    AppFactory,
    ExportFactory,
    DesignFactory
) {
    return {
        restrict: 'A',
        scope: {},
        templateUrl: exportPageHtmlUrl,
        controller: function($scope) {
            $scope.reportVars = ExportFactory.getVars().reportVars;
            $scope.dateRange = AppFactory.getFormattedDateRange();
            $scope.dateCreated = Globalize.format(new Date(), 'MMM dd, yyyy');
            $scope.page = DesignFactory.getCurrentPage();
            $scope.pageLayouts = _.sortBy($scope.page.layouts, 'display_order');
            $scope.pageTitle = $scope.page.title;
            $scope.getAppendedWidgetsOnPage = ExportFactory.getAppendedWidgetsOnPage;
            $scope.hasAppendedWidgetsOnPage = ExportFactory.hasAppendedWidgetsOnPage;
            $scope.pageIsEmpty = ExportFactory.pageIsEmpty;
            $scope.layoutHasWidgets = DesignFactory.layoutHasWidgets;
            $scope.pageHasWidgets = DesignFactory.pageHasWidgets;
            var showLayoutTitleOption = ExportFactory.getVars().showLayoutTitle;
            $scope.canShowTitle = showLayoutTitleOption ||
                (_.size(DesignFactory.getCurrentPage().layouts) > 1 && showLayoutTitleOption);
        }
    }
}
