'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';

angular.module('exportbuilder.element.services', [])
    .factory('ReportElementStylesUIFactory', ReportElementStylesUIFactory)
    .factory('ExportBuilderDashboardItemService', ExportBuilderDashboardItemService)
    .factory('ReportElementMetadataOptionsService', ReportElementMetadataOptionsService);



/**
 * @ngInject
 */
function ReportElementStylesUIFactory(
    ReportElementTextDesignOptions,
    ReportElementImageDesignOptions,
    ReportElementImageSupportedFillModes
) {

    return {
        getElementTextStyles: getElementTextStyles,
        getElementTextClasses: getElementTextClasses,
        getElementImageClasses: getElementImageClasses,
        getElementShapeStyles: getElementShapeStyles
    };

    function getElementTextStyles(element) {
        const styles = {};

        if (element.metadata.design_options[ReportElementTextDesignOptions.TEXT_ALIGNMENT]) {
            styles['text-align'] = element.metadata.design_options[ReportElementTextDesignOptions.TEXT_ALIGNMENT];
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.VERTICAL_ALIGNMENT]) {
            styles['align-self'] = element.metadata.design_options[ReportElementTextDesignOptions.VERTICAL_ALIGNMENT];
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.FONT_SIZE]) {
            styles['font-size'] = element.metadata.design_options[ReportElementTextDesignOptions.FONT_SIZE] + 'px';
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.TEXT_COLOR]) {
            styles['color'] = element.metadata.design_options[ReportElementTextDesignOptions.TEXT_COLOR];
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.TEXT_OPACITY] >= 0) {
            styles['opacity'] = element.metadata.design_options[ReportElementTextDesignOptions.TEXT_OPACITY] / 100;
        }

        if (!_.isNil(element.metadata.design_options[ReportElementTextDesignOptions.TEXT_SHADOW_X])
            && !_.isNil(element.metadata.design_options[ReportElementTextDesignOptions.TEXT_SHADOW_Y])
            && !_.isNil(element.metadata.design_options[ReportElementTextDesignOptions.TEXT_SHADOW_BLUR])
            && !_.isNil(element.metadata.design_options[ReportElementTextDesignOptions.TEXT_SHADOW_COLOR])) {
            const options = element.metadata.design_options;

            styles['text-shadow'] = options[ReportElementTextDesignOptions.TEXT_SHADOW_X]+'px '
                + options[ReportElementTextDesignOptions.TEXT_SHADOW_Y]+'px '
                + options[ReportElementTextDesignOptions.TEXT_SHADOW_BLUR]+'px '
                + options[ReportElementTextDesignOptions.TEXT_SHADOW_COLOR]+'';
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.TEXT_BOLD]) {
            styles['font-weight'] = 'bold';
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.TEXT_ITALIC]) {
            styles['font-style'] = 'italic';
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.TEXT_UNDERLINE]) {
            styles['text-decoration'] = 'underline';
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.TEXT_STRIKETHROUGH]) {
            styles['text-decoration'] = styles['text-decoration']
                ? styles['text-decoration'] + ' line-through'
                : 'line-through';
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.TEXT_PADDING]) {
            styles['padding'] = element.metadata.design_options[ReportElementTextDesignOptions.TEXT_PADDING];
        }

        if (element.metadata.design_options[ReportElementTextDesignOptions.FONT_FAMILY]) {
            styles['font-family'] = element.metadata.design_options[ReportElementTextDesignOptions.FONT_FAMILY];
        }

        return styles;
    }

    function getElementTextClasses() {
        const classes = [];

        return classes;
    }

    function getElementImageClasses(element) {
        let classes = [];

        if (element.metadata.design_options[ReportElementImageDesignOptions.FILL_MODE]) {
            switch (element.metadata.design_options[ReportElementImageDesignOptions.FILL_MODE]) {
                case ReportElementImageSupportedFillModes.ASPECT_FIT:
                    classes.push('aspect-fit');
                    break;
                case ReportElementImageSupportedFillModes.ASPECT_FILL:
                    classes.push('aspect-fill');
                    break;
                case ReportElementImageSupportedFillModes.STRETCH:
                    classes.push('stretch');
                    break;
                default:
                    classes.push('aspect-fit');
                    break;
            }
        }

        return classes;
    }

    function getElementShapeStyles() {
        //TODO: @dannyyassine
    }
}

/**
 * @ngInject
 */
function ExportBuilderDashboardItemService(
    $timeout,
    ZIndexProps,
    AppFactory,
    WidgetFactory,
    ReportStudioTemplateDataService,
    ReportElementTextDesignOptions,
    ReportElementIconDesignOptions,
    ReportElementShapeDesignOptions,
    ReportElementDesignOptionConstants,
    ReportElementBaseOptions,
    ExportBuilderDashboardService,
    ExportBuilderDashboardUtilService,
    ReportElementImageSupportedFillModes,
    $ExportBuilderDashboardEvents,
    ExportBuilderDashboardPageService,
    ExportBuilderResource,
    $ExportBuilderDashboardModelEvents,
    DrawOption,
    PubSub,
    WidgetFilterFactory,
    ExportBuilderFilterService,
    $WidgetFilterEvents
) {
    let service = {
        moveY: moveY,
        moveX: moveX,

        removeWidgetFilter: removeWidgetFilter,
        updateWidgetFilter: updateWidgetFilter,
        toggleWidgetDrawOption: toggleWidgetDrawOption,
        setCoordinates: setCoordinates,

        setMirrorXElement: setMirrorXElement,
        setMirrorYElement: setMirrorYElement,

        setTextTitle: setTextTitle,
        setTextAlignment: setTextAlignment,
        setTextVerticalAlignment: setTextVerticalAlignment,
        setTextSize: setTextSize,
        setTextColor: setTextColor,
        setTextOpacity: setTextOpacity,
        setTextShadowX: setTextShadowX,
        setTextShadowY: setTextShadowY,
        setTextShadowBlur: setTextShadowBlur,
        setTextShadowColor: setTextShadowColor,
        toggleTextBold: toggleTextBold,
        toggleTextItalic: toggleTextItalic,
        toggleTextUnderline: toggleTextUnderline,
        toggleTextStrikethrough: toggleTextStrikethrough,
        setPadding: setPadding,
        setFontFamily: setFontFamily,

        setIconColor: setIconColor,
        setIcon: setIcon,

        setImageFillMode: setImageFillMode,
        setImage: setImage,

        validateElementPositionInReport: validateElementPositionInReport,

        makeItemOnEveryPage: makeItemOnEveryPage,
        setSnapToGrid: setSnapToGrid,
        setToBackground: setToBackground,
        setBackgroundColor: setBackgroundColor,
        setBorderRadius: setBorderRadius,
        setOpacity: setOpacity,
        setShadowX: setShadowX,
        setShadowY: setShadowY,
        setShadowBlur: setShadowBlur,
        setShadowColor: setShadowColor,
        setBorderColor: setBorderColor,
        setBorderWidth: setBorderWidth,
        setBorderType: setBorderType,
        setBorderOpacity: setBorderOpacity,

        setShapeBackgroundColor: setShapeBackgroundColor,

        sizeToPage: sizeToPage,
        forceDimensions: forceDimensions,

        setZIndexValueForElement: setZIndexValueForElement,
        sendToBack: sendToBack,
        sendToFront: sendToFront,
    };
    return service;

    function moveY(item, direction, multiplier) {
        if (multiplier) {
            direction *= multiplier;
        }

        if (item.snap_to_grid) {
            let dimensionUnit = item.y_position / ExportBuilderDashboardUtilService.getGridUnitPercent();
            let floorDimensionUnit = Math.floor(dimensionUnit);

            item.y_position = (floorDimensionUnit + direction) * ExportBuilderDashboardUtilService.getGridUnitPercent();
        } else {
            item.y_position += direction;
        }

        service.validateElementPositionInReport(item, ExportBuilderDashboardService.getReport());

        ExportBuilderDashboardService.delayUpdateItem(item);

        PubSub.emit($ExportBuilderDashboardModelEvents.ON_COORDINATE_CHANGE+item.id, item);
    }

    function moveX(item, direction, multiplier) {
        if (multiplier) {
            direction *= multiplier;
        }

        if (item.snap_to_grid) {
            let dimensionUnit = item.x_position / ExportBuilderDashboardUtilService.getGridUnitPercent();
            let floorDimensionUnit = Math.floor(dimensionUnit);

            item.x_position = (floorDimensionUnit + direction) * ExportBuilderDashboardUtilService.getGridUnitPercent();
        } else {
            item.x_position += direction;
        }

        service.validateElementPositionInReport(item, ExportBuilderDashboardService.getReport());
        ExportBuilderDashboardService.delayUpdateItem(item);

        PubSub.emit($ExportBuilderDashboardModelEvents.ON_COORDINATE_CHANGE+item.id, item);
    }

    function removeWidgetFilter(element) {
        const model = {
            id: null,
            is_removing: true,
            widget_id: element.widget.id
        };
        if (element.widget.metadata.dynamic && element.widget.metadata.dynamic.filters) {
            element.widget.metadata.dynamic.filters.forEach(filter => {
                ExportBuilderFilterService.removeExposedFilter(element.widget, filter);
            });
        }
        ExportBuilderDashboardService.emptyWidgetCache(element.widget);
        return WidgetFilterFactory.removeFilterSet(model)
            .then(() => {
                element.widget.metadata.dynamic.filters = [];
                element.widget.metadata.dynamic.filter_set_name = null;
                element.widget.metadata.filter_set_id = null;
                ExportBuilderDashboardService.delayUpdateWidgetItem(element);
            });
    }

    function updateWidgetFilter(element, updateGlobalFilters) {

        const model = {
            data_source: element.widget.metadata.data_source,
            filters: element.widget.metadata.dynamic.filters,
            id: element.widget.metadata.filter_set_id,
            name: element.widget.metadata.dynamic.filter_set_name,
            widget_id: element.widget.id
        };

        ExportBuilderDashboardService.emptyWidgetCache(element.widget);
        return WidgetFilterFactory.updateFilterSet(model)
            .then((json) => {
                const filters = element.widget.metadata.dynamic && element.widget.metadata.dynamic.filters;
                if (_.isEmpty(filters)) {
                    element.widget.metadata.filter_set_id = null;
                    element.widget.metadata.dynamic.filter_set_name = null;
                    element.widget.metadata.dynamic.filters = [];
                } else {
                    element.widget.metadata.filter_set_id = json.id;
                    element.widget.metadata.dynamic.filter_set_name = model.name;
                    element.widget.metadata.dynamic.filters = filters;
                }
                if (updateGlobalFilters) {
                    PubSub.emit($WidgetFilterEvents.UPDATE_GLOBAL_FILTERS, {currentWidget: element.widget});
                }
                ExportBuilderDashboardService.delayUpdateWidgetItem(element);
            });
    }



    function toggleWidgetDrawOption(item, drawOptionKey, value) {
        item.widget.metadata.draw_options[drawOptionKey].value = value;
        item.widget.metadata.draw_options[drawOptionKey].overridden = !item.widget.metadata.draw_options[drawOptionKey].value;
        ExportBuilderDashboardService.delayUpdateWidgetItem(item);
    }

    function setCoordinates(item, data) {
        const report = ExportBuilderDashboardService.getReport();

        const finalPosition = {
            left: item.x_position + data.left,
            top: item.y_position + data.top
        };

        if (!_.isUndefined(data.width)) {
            finalPosition.width = item.width + data.width;
        }

        if (!_.isUndefined(data.height)) {
            finalPosition.height = item.height + data.height;
        }

        item.x_position = finalPosition.left;
        item.y_position = finalPosition.top;

        if (!_.isUndefined(finalPosition.width)) {
            item.width = finalPosition.width;
        }

        if (!_.isUndefined(finalPosition.height)) {
            item.height = finalPosition.height;
        }

        service.validateElementPositionInReport(item, report);

        item.x_position = parseFloat(item.x_position.toFixed(4));
        item.y_position = parseFloat(item.y_position.toFixed(4));
        item.width = parseFloat(item.width.toFixed(4));
        item.height = parseFloat(item.height.toFixed(4));

        ExportBuilderDashboardService.delayUpdateItem(item);

        PubSub.emit($ExportBuilderDashboardModelEvents.ON_COORDINATE_CHANGE+item.id, item);
    }

    /**
     * @param item
     * @param {Boolean} [value]
     */
    function setMirrorXElement(item, value) {
        item.metadata.base_options[ReportElementBaseOptions.MIRROR_X] = _.isUndefined(value) ? !item.metadata.base_options[ReportElementBaseOptions.MIRROR_X] : value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    /**
     * @param item
     * @param {Boolean} [value]
     */
    function setMirrorYElement(item, value) {
        item.metadata.base_options[ReportElementBaseOptions.MIRROR_Y] = _.isUndefined(value) ? !item.metadata.base_options[ReportElementBaseOptions.MIRROR_Y] : value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setTextTitle(item, title) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_TITLE] = title;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setTextAlignment(item, alignment) {
        switch (alignment) {
            case ReportElementDesignOptionConstants.TEXT_ALIGNMENT.LEFT:
            case ReportElementDesignOptionConstants.TEXT_ALIGNMENT.RIGHT:
            case ReportElementDesignOptionConstants.TEXT_ALIGNMENT.CENTER:
            case ReportElementDesignOptionConstants.TEXT_ALIGNMENT.JUSTIFY:
                item.metadata.design_options[ReportElementTextDesignOptions.TEXT_ALIGNMENT] = alignment;
                break;
            default:
                item.metadata.design_options[ReportElementTextDesignOptions.TEXT_ALIGNMENT] = ReportElementDesignOptionConstants.TEXT_ALIGNMENT.CENTER;
                break;
        }
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setTextVerticalAlignment(item, alignment) {
        switch (alignment) {
            case ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.TOP:
            case ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.CENTER:
            case ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.BOTTOM:
                item.metadata.design_options[ReportElementTextDesignOptions.VERTICAL_ALIGNMENT] = alignment;
                break;
            default:
                item.metadata.design_options[ReportElementTextDesignOptions.VERTICAL_ALIGNMENT] = ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.CENTER;
                break;
        }
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setTextSize(item, fontSize) {
        item.metadata.design_options[ReportElementTextDesignOptions.FONT_SIZE] = fontSize;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setTextColor(item, hexColor) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_COLOR] = hexColor;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setTextOpacity(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_OPACITY] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setTextShadowX(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_SHADOW_X] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setTextShadowY(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_SHADOW_Y] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setTextShadowBlur(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_SHADOW_BLUR] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setTextShadowColor(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_SHADOW_COLOR] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function toggleTextBold(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_BOLD] = _.isUndefined(value) ? !item.metadata.design_options[ReportElementTextDesignOptions.TEXT_BOLD] : value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function toggleTextItalic(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_ITALIC] = _.isUndefined(value) ? !item.metadata.design_options[ReportElementTextDesignOptions.TEXT_ITALIC] : value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function toggleTextUnderline(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_UNDERLINE] = _.isUndefined(value) ? !item.metadata.design_options[ReportElementTextDesignOptions.TEXT_UNDERLINE] : value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function toggleTextStrikethrough(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_STRIKETHROUGH] = _.isUndefined(value) ? !item.metadata.design_options[ReportElementTextDesignOptions.TEXT_STRIKETHROUGH] : value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setPadding(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.TEXT_PADDING] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setFontFamily(item, value) {
        item.metadata.design_options[ReportElementTextDesignOptions.FONT_FAMILY] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setIconColor(item, newColor) {
        item.metadata.design_options[ReportElementIconDesignOptions.ICON_COLOR] = newColor;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setIcon(item, newIcon) {
        item.metadata.icon = newIcon;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }

    function setImageFillMode(element, fillMode) {
        switch (fillMode) {
            case ReportElementImageSupportedFillModes.ASPECT_FIT:
            case ReportElementImageSupportedFillModes.ASPECT_FILL:
            case ReportElementImageSupportedFillModes.STRETCH:
                element.metadata.design_options.fill_mode = fillMode;
                break;
            default:
                element.metadata.design_options.fill_mode = ReportElementImageSupportedFillModes.ASPECT_FIT;
                break;
        }
        ExportBuilderDashboardService.delayUpdateItem(element);
    }

    function setImage(element, url) {
        element = ExportBuilderDashboardService.getItemFromId(element.id);
        if (!element) {return;}

        if (element.isTypeImage()) {
            element.metadata.image_url = url;
        }

        ExportBuilderDashboardService.updateItem(element);
    }

    function makeItemOnEveryPage(item, showOnEveryPage) {
        let builder = ExportBuilderDashboardService.getBuilder();

        let unsetFocus = false;
        if (showOnEveryPage) {
            _.each(builder.report.pages, page => {

                if (page.is_cover_page || page.is_back_page) {
                    _.remove(page.elements, {id: item.id});
                    if (page.id == builder.currentPage.id) {
                        unsetFocus = true;
                    }
                    return;
                }

                if (page.id == builder.currentPage.id) {
                    return;
                }

                page.elements.push(item);
            });
            builder.report.elements.push(item);
            item.page_id = null;
            item.report_id = builder.report.id;
            item.show_on_every_page = true;
        } else {
            _.each(builder.report.pages, page => {

                // must add it to this page
                if (builder.currentPage.id === page.id
                    && (page.is_cover_page || page.is_back_page)) {
                    page.elements.push(item);
                    return;
                }

                // do not remove on current page
                if (builder.currentPage.id === page.id) {
                    return;
                }
                _.remove(page.elements, {id: item.id});
            });
            _.remove(builder.report.elements, {id: item.id});
            item.page_id = builder.currentPage.id;
            item.report_id = null;
            item.show_on_every_page = false;
        }
        ExportBuilderDashboardService.delayUpdateItem(item);

        unsetFocus && ExportBuilderDashboardService.focusOnItem(null);
    }

    /**
     *
     * @param {ReportElementModel} element
     * @param {ReportTemplateModel} report
     */
    function validateElementPositionInReport(element, report) {

        if (element.snap_to_grid) {
            if (element.x_position + element.width < 0) {
                element.x_position = -element.width;
            }
            if (element.y_position + element.height < 0) {
                element.y_position = -element.height;
            }

            if (element.x_position > 100) {
                element.x_position = 100;
            }
            if (element.y_position > 100) {
                element.y_position = 100;
            }
        } else {
            if (element.x_position + element.width < 0) {
                element.x_position = -element.width;
            }
            if (element.y_position + element.height < 0) {
                element.y_position = -element.height;
            }

            if (element.x_position > report.getPageWidth()) {
                element.x_position = report.getPageWidth();
            }
            if (element.y_position > report.getPageHeight()) {
                element.y_position = report.getPageHeight();
            }
        }

        return element;
    }

    /**
     * Enforces images element to never pass the page top and bottom boundaries
     * Export issues due to chromium
     * @param {ReportElementModel} element
     * @param {ReportTemplateModel} report
     * @private
     */
    function _snapImageElementInPage(element, report) {
        if (!element.isTypeImage()) {
            return;
        }

        if (element.snap_to_grid) {
            if (element.y_position <= 0) {
                element.y_position = 0;
            }

            if (element.y_position + element.height >= 100) {
                element.y_position = 100 - element.height;
            }
        } else {
            if (element.y_position <= 0) {
                element.y_position = 0;
            }

            if (element.y_position + element.height > report.getPageHeight()) {
                element.y_position = report.getPageHeight() - element.height;
            }
        }
    }

    /**
     * @param item {ReportElementModel}
     * @param value {Boolean}
     */
    function setSnapToGrid(item, value) {
        if (item.snap_to_grid === value) {
            return;
        }

        let report = ExportBuilderDashboardService.getReport();
        item.snap_to_grid = value;

        if (item.snap_to_grid) {
            item.x_position = item.x_position / report.getPageWidth() * 100;
            item.y_position = item.y_position / report.getPageHeight() * 100;
            item.width = item.width / report.getPageWidth() * 100;
            item.height = item.height / report.getPageHeight() * 100;
        } else {
            item.x_position = report.getPageWidth() * (item.x_position / 100);
            item.y_position = report.getPageHeight() * (item.y_position / 100);
            item.width = report.getPageWidth() * (item.width / 100);
            item.height = report.getPageHeight() * (item.height / 100);
        }

        ExportBuilderDashboardService.delayUpdateItem(item);

        PubSub.emit($ExportBuilderDashboardEvents.ON_ITEM_SNAP_TO_GRID_CHANGE, item);
    }

    function setToBackground(element, value) {
        element.metadata.set_as_background = value;
        ExportBuilderDashboardService.delayUpdateItem(element);
    }

    function setBackgroundColor(item, hexColor) {
        item.metadata.base_options[ReportElementBaseOptions.BACKGROUND_COLOR] = hexColor;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setBorderRadius(item, newValue) {
        item.metadata.base_options[ReportElementBaseOptions.BORDER_RADIUS] = newValue;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setOpacity(item, value) {
        item.metadata.base_options[ReportElementBaseOptions.OPACITY] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setShadowX(item, value) {
        item.metadata.base_options[ReportElementBaseOptions.SHADOW_X] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setShadowY(item, value) {
        item.metadata.base_options[ReportElementBaseOptions.SHADOW_Y] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setShadowBlur(item, value) {
        item.metadata.base_options[ReportElementBaseOptions.SHADOW_BLUR] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setShadowColor(item, value) {
        item.metadata.base_options[ReportElementBaseOptions.SHADOW_COLOR] = value;
        ExportBuilderDashboardService.delayUpdateItem(item);
    }
    function setBorderColor(element, value) {
        element.metadata.base_options[ReportElementBaseOptions.BORDER_COLOR] = value;
        ExportBuilderDashboardService.delayUpdateItem(element);
    }
    function setBorderWidth(element, value) {
        element.metadata.base_options[ReportElementBaseOptions.BORDER_WIDTH] = value;
        ExportBuilderDashboardService.delayUpdateItem(element);
    }
    function setBorderType(element, value) {
        switch (value) {
            case ReportElementDesignOptionConstants.BORDER_TYPE.SOLID:
            case ReportElementDesignOptionConstants.BORDER_TYPE.DASHED:
            case ReportElementDesignOptionConstants.BORDER_TYPE.DOTTED:
            case ReportElementDesignOptionConstants.BORDER_TYPE.DOUBLE:
            case ReportElementDesignOptionConstants.BORDER_TYPE.GROOVE:
            case ReportElementDesignOptionConstants.BORDER_TYPE.RIDGE:
            case ReportElementDesignOptionConstants.BORDER_TYPE.INSET:
            case ReportElementDesignOptionConstants.BORDER_TYPE.OUTSET:
                element.metadata.base_options[ReportElementBaseOptions.BORDER_TYPE] = value;
                break;
            default:
                element.metadata.base_options[ReportElementBaseOptions.BORDER_TYPE] = null;
                break;
        }
        ExportBuilderDashboardService.delayUpdateItem(element);
    }
    function setBorderOpacity(element, value) {
        element.metadata.base_options[ReportElementBaseOptions.BORDER_OPACITY] = value;
        ExportBuilderDashboardService.delayUpdateItem(element);
    }

    // SHAPE
    function setShapeBackgroundColor(element, hexColor) {
        element.metadata.design_options[ReportElementShapeDesignOptions.BACKGROUND_COLOR] = hexColor;
        ExportBuilderDashboardService.delayUpdateItem(element);
    }

    function sizeToPage(element) {
        element.x_position = 0;
        element.y_position = 0;
        element.height = 100;
        element.width =  100;
        if (!element.snap_to_grid) {
            let report = ExportBuilderDashboardService.getReport();
            element.width = report.getPageWidth();
            element.height = report.getPageHeight();
        }
        ExportBuilderDashboardService.delayUpdateItem(element);
    }

    function forceDimensions(element, dimensions) {
        element.x_position = dimensions.x_position;
        element.y_position = dimensions.y_position;
        element.height = dimensions.height;
        element.width =  dimensions.width;
        ExportBuilderDashboardService.delayUpdateItem(element);
    }

    function sendToFront(page_id, element) {
        const page = ExportBuilderDashboardPageService.getPageFromId(page_id);
        if (!page) {return}

        const removedElement = _.remove(page.elements, {id: element.id});
        const zIndex = removedElement[0].z_index;
        page.elements = _.orderBy(page.elements, 'z_index', 'asc');
        let tempZindex  = _.clone(zIndex);
        for (let i = 0; i<page.elements.length ; i++ ) {
            if (page.elements[i].z_index > zIndex) {
                page.elements[i].z_index = tempZindex;
                tempZindex++;
            }
        }

        removedElement[0].z_index = tempZindex;
        page.elements.push(removedElement[0]);
        page.elements = _.orderBy(page.elements, 'z_index', 'desc');

        ExportBuilderDashboardService.delayUpdateElements(page.elements);
    }

    function sendToBack(page_id, element) {
        let page = ExportBuilderDashboardPageService.getPageFromId(page_id);
        if (!page) {return}

        let removedElement = _.remove(page.elements, {id: element.id});
        const zIndex = removedElement[0].z_index;
        page.elements = _.orderBy(page.elements, 'z_index', 'desc');
        let tempZindex  = _.clone(zIndex);
        for (let i = 0; i<page.elements.length ; i++ ) {
            if (page.elements[i].z_index < zIndex) {
                page.elements[i].z_index = tempZindex;
                tempZindex--;
            }
        }

        removedElement[0].z_index = tempZindex;
        page.elements.unshift(removedElement[0]);

        ExportBuilderDashboardService.delayUpdateElements(page.elements);
    }

    function setZIndexValueForElement(page_id, element, z_index) {
        let page = ExportBuilderDashboardPageService.getPageFromId(page_id);
        if (!page) {return}

        let nextBackItem = _.find(page.elements, { z_index: z_index});
        if (nextBackItem) {
            nextBackItem.z_index = element.z_index;
        }
        element.z_index = z_index;

        page.elements = _.orderBy(page.elements, 'z_index', 'desc');
        let index = page.elements.length - 1;
        page.elements = _.map(page.elements, function(item) {
            item.z_index = index--;
            return item;
        });

        ExportBuilderDashboardService.delayUpdateElements(page.elements);
    }

    function _sortPageElements(page) {
        page.elements = _.map(page.elements, function(item, i) {
            item.z_index = i;
            return item;
        });
        page.elements = _.orderBy(page.elements, 'z_index', 'desc');
    }
}

/**
 * @ngInject
 */
function ReportElementMetadataOptionsService(
    ReportElementDesignOptions,
    ReportStudioTemplateDataService,
    ReportElementTypes,
    ExportBuilderPrefixes,
    ReportElementTextDesignOptions,
    ReportElementBaseOptions,
    ReportElementIconDesignOptions,
    ReportElementDesignOptionConstants,
    ReportElementImageDesignOptions,
    ReportElementImageSupportedFillModes,
    UIColor,
    DrawOption,
    UserThemes,
    ReportElementShapeTypes,
    ReportElementShapeDesignOptions,
    UIFactory
) {
    return {
        getDefaultElementWidgetDesignOptions: getDefaultElementWidgetDesignOptions,
        getDefaultElementBaseOptions: getDefaultElementBaseOptions,
        getDefaultImageDesignOptions: getDefaultImageDesignOptions,
        getDefaultTextDesignOptions: getDefaultTextDesignOptions,
        getDefaultShapeDesignOptions: getDefaultShapeDesignOptions,
        getDefaultIconDesignOptions: getDefaultIconDesignOptions
    };

    function getDefaultElementWidgetDesignOptions() {
        let options = {};

        return options;
    }

    function getDefaultElementBaseOptions(metadata = null) {
        let options = {};

        options[ReportElementBaseOptions.MIRROR_X] = false;
        options[ReportElementBaseOptions.MIRROR_Y] = false;
        options[ReportElementBaseOptions.OPACITY] = 100;
        options[ReportElementBaseOptions.BACKGROUND_COLOR] = null;
        options[ReportElementBaseOptions.BORDER_RADIUS] = 5;

        options[ReportElementBaseOptions.SHADOW_X] = 0;
        options[ReportElementBaseOptions.SHADOW_Y] = 0;
        options[ReportElementBaseOptions.SHADOW_BLUR] = 10;
        options[ReportElementBaseOptions.SHADOW_COLOR] = null;

        options[ReportElementBaseOptions.BORDER_COLOR] = null;
        options[ReportElementBaseOptions.BORDER_WIDTH] = 2;
        options[ReportElementBaseOptions.BORDER_TYPE] = ReportElementDesignOptionConstants.BORDER_TYPE.SOLID;
        options[ReportElementBaseOptions.BORDER_OPACITY] = 100;

        if (metadata !== null) {
            options[ReportElementBaseOptions.BACKGROUND_COLOR] = metadata?.draw_options?.show_background?.value ? "#fff" : null;
            options[ReportElementBaseOptions.SHADOW_COLOR] = metadata?.draw_options?.show_shadow?.value ? "#00000014" : null;
            options[ReportElementBaseOptions.BORDER_COLOR] = metadata?.draw_options?.show_borders?.value ? "#ebebeb" : null;
        }

        return options;
    }

    function getDefaultImageDesignOptions() {
        let options = {};

        options[ReportElementDesignOptions.SHOW_ON_EVER_PAGE] = true;
        options[ReportElementImageDesignOptions.FILL_MODE] = ReportElementImageSupportedFillModes.ASPECT_FIT;

        return options;
    }

    function getDefaultShapeDesignOptions(shapeType, color) {
        let options = {};

        options[ReportElementDesignOptions.SHOW_ON_EVER_PAGE] = true;

        switch (shapeType) {
            case ReportElementShapeTypes.SQUARE:
                options[ReportElementShapeDesignOptions.BORDER_RADIUS] = '0%';
                break;
            case ReportElementShapeTypes.CIRCLE:
                options[ReportElementShapeDesignOptions.BORDER_RADIUS] = '50%';
                break;
        }

        options[ReportElementShapeDesignOptions.BACKGROUND_COLOR] = color || UIFactory.getRandomThemeColor();

        return options;
    }

    function getDefaultIconDesignOptions() {
        let options = {};

        options[ReportElementIconDesignOptions.ICON_COLOR] = '#000000';

        options[ReportElementDesignOptions.SHOW_ON_EVER_PAGE] = true;

        return options;
    }

    function getDefaultTextDesignOptions() {
        let options = {};

        options[ReportElementTextDesignOptions.TEXT_TITLE] = ReportElementTextDesignOptions.TEXT_EMPTY_PLACEHOLDER;
        options[ReportElementTextDesignOptions.TEXT_COLOR] = '#000000';
        options[ReportElementTextDesignOptions.TEXT_OPACITY] = 100;
        options[ReportElementTextDesignOptions.TEXT_BOLD] = false;
        options[ReportElementTextDesignOptions.TEXT_ITALIC] = false;
        options[ReportElementTextDesignOptions.TEXT_ALIGNMENT] = ReportElementDesignOptionConstants.TEXT_ALIGNMENT.CENTER;
        options[ReportElementTextDesignOptions.VERTICAL_ALIGNMENT] = ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.CENTER;
        options[ReportElementTextDesignOptions.FONT_SIZE] = 30;
        options[ReportElementTextDesignOptions.FONT_FAMILY] = 'Helvetica';

        options[ReportElementTextDesignOptions.TEXT_SHADOW_X] = 0;
        options[ReportElementTextDesignOptions.TEXT_SHADOW_Y] = 0;
        options[ReportElementTextDesignOptions.TEXT_SHADOW_BLUR] = 5;
        options[ReportElementTextDesignOptions.TEXT_SHADOW_COLOR] = null;

        options[ReportElementDesignOptions.SHOW_ON_EVER_PAGE] = true;

        return options;
    }
}