export const DefaultChartPalette = [
  '#0665a2',
  '#ffc930',
  '#e96455',
  '#e11272',
  '#57df7f',
  '#820970',
  '#7f2ccb',
  '#52ffee',
  '#4adf62',
  '#345511',
];

export const DashboardPathName = {
  DASHBOARDS: 'dashboards',
  DASHBOARDS_TEST: 'dashboards:test',
  DASHBOARDTEMPLATES: 'dashboardtemplates',
};

export const DashboardFontSize = [
  { label: 'small', value: 'small' },
  { label: 'medium', value: 'medium' },
  { label: 'large', value: 'large' },
];

export const NavItemGroupKey = {
  CLIENT_DASHBOARDS: 'client_dashboards',
  SHARED_DASHBOARDS: 'shared_dashboards',
};

export const PageEntity = {
  PAGE_LEADS: 'leads',
  PAGE_INDUSTRY_BENCHMARK: 'industrybenchmark',
  PAGE_EMAILS_OVERVIEW: 'emailsoverview',
};

export const LayoutSortOptions = [
  { label: 'Most Used', value: 'library_download_count' },
  { label: 'Recently Uploaded', value: 'created_at' },
];

export const DashboardRoute = {
  DASHBOARDS: 'dashboards',
};

export const DashboardEventType = {
  DASHBOARD_FILTER_PANEL_OLD_UI_RESET: 'DashboardFilterPanelOldUIReset',
  DASHBOARD_FILTER_NEW_UI_RELOAD: 'DashboardFilterNewUIReload',
  ADD_DATA_WIDGET: 'AddDataWidgetEvent',
  EDIT_DATA_WIDGET: 'EditDataWidgetEvent',
  EDIT_DATE_RANGE: 'DateRangeEdit',
  ADD_WIDGET: 'AddWidgetEvent',
  ADD_ADMIN_WIDGET: 'AddAdminWidgetEvent',
  EDIT_ADMIN_WIDGET: 'EditAdminWidgetEvent',
  QUICK_DOWNLOAD: 'QuickDownloadEvent',
  ADD_MEDIA_WIDGET: 'AddMediaWidgetEvent',
  EDIT_MEDIA_WIDGET: 'EditMediaWidgetEvent',
  EDIT_DASHBOARD: 'EditDashboard',
  MANAGE_SECTIONS: 'ManageSections',
  EDIT_SECTION_DRAW_OPTIONS: 'EditSectionDrawOptions',
  PUBLISH_TO_LIBRARY: 'PublishToLibraryEvent',
  RESET_WIDGET_COLOR_PALETTE: 'ResetWidgetColorPalette',
  BUILD_PDF_REPORT_START: 'BuildPDFReportStartEvent',
  BUILD_PDF_REPORT_DOWNLOAD: 'BuildPDFReportDownloadEvent',
  EXPORT_TO_REPORT_STUDIO: 'ExportToReportStudioEvent',
  EDIT_REPORTING_OPTIONS: 'EditReportingOptionsEvent',
  DASHBOARD_TOGGLE_FAVORITE_EVENT: 'DashboardToggleFavoriteEvent',
};

export const DashboardElements = {
  WIDGET: 'widget',
  DASHBOARD: 'dashboard',
  LAYOUT: 'section',
};

export const DashboardCreatedFrom = {
  FREEHAND: 'freehand',
  TEMPLATE: 'template',
};

export const DASHBOARD = 'dashboard';
export const REPORT_STUDIO = 'report_studio';

export const LayoutModals = {
  LAYOUT_ASSIGN_MODAL: 'LayoutAssignModal',
  PUBLISH_MODAL: 'PublishModal',
  LAYOUT_CUSTOM_DATE_RANGE_MODAL: 'LayoutCustomDateRangeModal',
  LAYOUT_DELETE_MODAL: 'LayoutDeleteModal',
};
