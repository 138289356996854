import { assign, isEmpty, difference, isPlainObject, isString, isFunction } from 'lodash';
import { CustomApiSummaryColumn } from '@/modules/core/app/models/api/CustomApiSummaryColumn';
import { getBaseUrl } from '@/modules/core/app/helpers/AxiosHelper';

/**
 * @param {array} data
 * @param {CustomColumnsConfig} customColumnsConfig
 * @returns {array}
 */
export function buildColumnsFromData(data, customColumnsConfig) {
  const columns = [];

  if (!data.length) {
    return columns;
  }

  const dataColumns = Object.keys(data[0]);

  if (customColumnsConfig.all) {
    if (!isEmpty(customColumnsConfig.columns)) {
      const fieldsInConfig = getCustomColumnsFields(customColumnsConfig.columns);
      const extraFields = difference(dataColumns, fieldsInConfig);
      customColumnsConfig.columns = customColumnsConfig.columns.concat(extraFields);
    } else {
      customColumnsConfig.columns = dataColumns;
    }
  }

  customColumnsConfig.columns.forEach((column) => {
    // String column names
    if (isString(column)) {
      if (dataColumns.includes(column) && customColumnsConfig.visibleColumnsSelector(column)) {
        columns.push(
          new CustomApiSummaryColumn(
            assign(
              {
                field: column,
                label: customColumnsConfig.labelSerializer(column),
              },
              customColumnsConfig.configSelector(column)
            )
          )
        );
      }
    } else if (isPlainObject(column) && column.field) {
      if (dataColumns.find((columnName) => columnName === column.field)) {
        columns.push(
          new CustomApiSummaryColumn(
            assign(
              {
                label: customColumnsConfig.labelSerializer(column.field),
              },
              column
            )
          )
        );
      }
    } else if (column instanceof CustomApiSummaryColumn) {
      if (dataColumns.find((columnName) => columnName === column.field)) {
        columns.push(column);
      }
    }
  });

  if (isFunction(customColumnsConfig.orderConfig)) {
    return customColumnsConfig.orderConfig(columns);
  }

  return columns;
}

/**
 * @param columns
 * @returns {*}
 */
function getCustomColumnsFields(columns) {
  // Find columns for which no config is present
  return columns
    .map((column) => {
      if ((isPlainObject(column) && column.field) || column instanceof CustomApiSummaryColumn) {
        return column.field;
      }

      return column;
    })
    .filter((field) => !!field);
}

/**
 *
 * @param name
 * @param names
 * @param maxCharLength
 * @param maxPostFixIdLength
 * @returns {*|string}
 */
export function getFixedLengthUniqueName(name, names, maxCharLength, maxPostFixIdLength) {
  // Truncate name to maxCharLength
  let fixedLengthName = name.substring(0, maxCharLength);
  // Update duplicate entries by appending 5 digit random id
  names.forEach((nameInArray) => {
    fixedLengthName =
      nameInArray === fixedLengthName
        ? fixedLengthName
            .substring(0, maxCharLength - (maxPostFixIdLength + 1))
            .concat(
              `_${Math.floor(
                Math.random() * (10 ** maxPostFixIdLength - 10 ** (maxPostFixIdLength - 1))
              )}`
            )
        : fixedLengthName;
  });
  return fixedLengthName;
}

/**
 * Returns the full Api Url for the given route and params
 * @param route
 * @param params
 * @returns {string}
 */
export function getApiUrl(route, params = {}) {
  const query = [];

  Object.keys(params).forEach((key) => {
    query.push(`${key}=${params[key]}`);
  });

  let search = query.join('&');
  if (search) {
    search = `?${search}`;
  }

  return `${getBaseUrl()}/${route}${search}`;
}
