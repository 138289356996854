import { LinearGradient, percent, RadialGradient, GrainPattern } from '@amcharts/amcharts5';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { isEmpty } from 'lodash';
import { getPieChartConfig } from '@/modules/core/charts/am5/pie/config/pieConfig';
import { GradientTargets } from '@/modules/ta/widget/widget.constants';

export function useGradient(context) {
  const { root, config } = context();
  function createLinearGradient(colors, rotation = 90) {
    return LinearGradient.new(root.value, {
      stops: colors.map((colorStr) => (isEmpty(colorStr) ? {} : { color: color(colorStr) })),
      rotation,
    });
  }
  function createPictorialGradient(gradientColor, rotation = 90) {
    return LinearGradient.new(root.value, {
      stops: [
        {},
        {
          color: color(gradientColor),
        },
      ],
      rotation,
    });
  }
  /**
   * @param colors {array<string>}
   * @param settings
   * @returns {InstanceType<typeof Entity>}
   */
  function createRadialGradient(colors, settings = {}) {
    return RadialGradient.new(root.value, {
      stops: colors.map((colorStr) => (isEmpty(colorStr) ? {} : { color: color(colorStr) })),
      x: percent(20),
      y: percent(-20),
      ...settings,
    });
  }
  function createGrainPattern() {
    const { grainyPatternOptions } = getPieChartConfig(config.value);
    return GrainPattern.new(root.value, grainyPatternOptions);
  }

  function createPieRadialGradient() {
    const { radialProps } = getPieChartConfig(config.value);
    return RadialGradient.new(root.value, radialProps);
  }
  function createPieLinearGradient() {
    const { linearProps } = getPieChartConfig(config.value);
    return LinearGradient.new(root.value, linearProps);
  }

  function shouldApplyGradient(element, selectedValue) {
    let elementArray = [];

    switch (element) {
      case GradientTargets.METRIC:
        elementArray = [
          GradientTargets.METRIC,
          GradientTargets.METRIC_BAND,
          GradientTargets.HAND_METRIC,
          GradientTargets.EVERYTHING,
        ];
        break;

      case GradientTargets.HAND:
        elementArray = [
          GradientTargets.HAND,
          GradientTargets.HAND_BAND,
          GradientTargets.HAND_METRIC,
          GradientTargets.EVERYTHING,
        ];
        break;

      case GradientTargets.BAND:
        elementArray = [
          GradientTargets.BAND,
          GradientTargets.METRIC_BAND,
          GradientTargets.HAND_BAND,
          GradientTargets.EVERYTHING,
        ];
        break;

      default:
        elementArray = [];
        break;
    }

    return elementArray.includes(selectedValue);
  }

  return {
    createLinearGradient,
    createRadialGradient,
    createGrainPattern,
    createPieRadialGradient,
    createPieLinearGradient,
    shouldApplyGradient,
    createPictorialGradient,
  };
}
