import { DataSourceType } from '@/modules/core/datasource/models/DataSourceType';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class DataSourceTypeCollection extends BaseModel {
  constructor(model = {}) {
    super();

    Object.keys(model).forEach((key) => {
      this[key] = new DataSourceType(model[key]);
    });
  }
}
