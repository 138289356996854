"use strict";
import $ from 'jquery';

var Core = $.core.reporting.templates = {
    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {
    },
    events: function () {
        this.deleteBindings();
    },
    deleteBindings: function () {
        $('#report-table a.delete-action').click(function(e){
            //Set the url for row delete action
            $(this).data('url', '/app/dash/reportingTemplate/delete_reporting_template/reporting_template_id/' + $(this).data('templateId') + '/');

            $.core.datatable.deleteRow($(this), $(this).data('name'));
        });
    }
};