import angular from 'angular';
import Pusher from 'pusher-js';

class PusherService {
  static $inject = ['PusherResource', 'PusherConstants'];
  /**
   * Promise for a Pusher instance
   * @type {Promise}
   */
  pusherPromise = null;
  /**
   * Pusher Channel for an individual user
   * @type {Channel}
   */
  pusherUserChannel = null;
  /**
   * Pusher Channel subscription promise for an individual user
   * @type {Promise}
   */
  pusherUserChannelSubscription = null;
  /**
   * Pusher Channel for an instance
   * @type {Channel}
   */
  pusherInstanceChannel = null;
  /**
   * Pusher Channel subscription promise for for an instance
   * @type {Promise}
   */
  pusherInstanceChannelSubscription = null;

  constructor(PusherResource, PusherConstants) {
    this.PusherResource = PusherResource;
    this.PusherConstants = PusherConstants;
  }

  /**
   * Returns a promise that resolves with an instance of Pusher
   * @returns {Promise<Channel>}
   */
  async getPusherUserChannel() {
    if (!this.pusherUserChannelSubscription) {
      this.pusherUserChannelSubscription = new Promise(async resolve => {
        if (!this.pusherUserChannel) {
          const { pusher, pusherDetails } = await this.getPusher();
          const channel = pusher.subscribe(pusherDetails.pubsub_user_channel);
          const resolvePromise = () => {
            if (timer) {
              clearTimeout(timer);
            }
            channel.unbind(this.PusherConstants.PUSHER_SUBSCRIPTION_EVENT, resolvePromise);
            this.pusherUserChannel = channel;
            resolve(this.pusherUserChannel);
          };
          channel.bind(this.PusherConstants.PUSHER_SUBSCRIPTION_EVENT, resolvePromise);
          const timer = setTimeout(
            resolvePromise,
            this.PusherConstants.PUSHER_SUBSCRIPTION_TIMEOUT
          );
        } else {
          resolve(this.pusherUserChannel);
        }
      });
    }
    return this.pusherUserChannelSubscription;
  }

  /**
   * Returns a promise that resolves with an instance of Pusher
   * @returns {Promise<Channel>}
   */
  async getPusherInstanceChannel() {
    if (!this.pusherInstanceChannelSubscription) {
      this.pusherInstanceChannelSubscription = new Promise(async resolve => {
        if (!this.pusherInstanceChannel) {
          const { pusher, pusherDetails } = await this.getPusher();
          const channel = pusher.subscribe(pusherDetails.pubsub_instance_channel);
          const resolvePromise = () => {
            if (timer) {
              clearTimeout(timer);
            }
            channel.unbind(this.PusherConstants.PUSHER_SUBSCRIPTION_EVENT, resolvePromise);
            this.pusherInstanceChannel = channel;
            resolve(this.pusherInstanceChannel);
          };
          channel.bind(this.PusherConstants.PUSHER_SUBSCRIPTION_EVENT, resolvePromise);
          const timer = setTimeout(
            resolvePromise,
            this.PusherConstants.PUSHER_SUBSCRIPTION_TIMEOUT
          );
        } else {
          resolve(this.pusherInstanceChannel);
        }
      });
    }
    return this.pusherInstanceChannelSubscription;
  }

  /**
   * Waits for a pusher event with a given timeout
   * @param channel
   * @param event
   * @param callback (if callback returns true, cleanup will be carried out and method will unbind)
   * @param timeoutCallback
   * @param timeout
   */
  waitForPusherEvent(
    channel,
    event,
    callback,
    timeoutCallback,
    timeout = this.PusherConstants.PUSHER_POLL_INTERVAL
  ) {
    let timerId = null;
    const cleanUp = () => {
      if (timerId) {
        clearTimeout(timerId);
        timerId = null;
      }
      channel.unbind(event, eventHandler);
    };
    const timeoutHandler = () => {
      cleanUp();
      timeoutCallback();
    };
    const eventHandler = response => {
      if (callback(response)) {
        cleanUp();
      }
    };
    channel.bind(event, eventHandler);
    timerId = setTimeout(timeoutHandler, timeout);
  }

  /**
   * Get Pusher instance
   */
  getPusher() {
    if (this.pusherPromise) {
      return this.pusherPromise;
    }
    this.pusherPromise = this.PusherResource.getPusherDetails().then(pusherDetails => {
      const pusher = new Pusher(pusherDetails.pubsub_api_key, {
        cluster: pusherDetails.pubsub_cluster,
        authEndpoint: `${pusherDetails.pubsub_auth_endpoint}`
      });
      return { pusher, pusherDetails };
    });
    return this.pusherPromise;
  }
}

export default angular.module('pusher.services', []).service('PusherService', PusherService);
