export class LayoutState {
  /**
   * @var {{}}
   */
  layouts;

  /**
   *  Cloned widgets in current layout in builder mode
   */
  originalLayoutWidgets;

  constructor() {
    this.layouts = {};
    this.originalLayoutWidgets = {};
  }
}
