import {
  GradientTargets,
  HandTypes,
  WidgetBackgroundGradientColorOptions,
} from '@/modules/ta/widget/widget.constants';
import { color, GrainPattern, percent, Modal } from '@amcharts/amcharts5';
import { AxisBullet } from '@amcharts/amcharts5/xy';
import { ClockHand } from '@amcharts/amcharts5/radar';
import {
  Constant,
  GaugeBigNumberLengthToRadiusMap,
  GaugeBigNumberPosition,
  HAND_MISSING_WARNING,
} from '@/modules/core/charts/am5/charts.constants';
import { useGradient } from '@/modules/core/charts/am5/base/composables/fills/useGradient';
import { GaugeHand } from '@/modules/core/charts/am5/gauge/models/GaugeHand';
import { useBigNumberTextLabels } from '@/modules/core/charts/am5/gauge/composables/useBigNumberTextLabels';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';

export function useRadarHand(context) {
  const { root, config } = context();
  const { createLinearGradient, shouldApplyGradient } = useGradient(context);
  const { createLabelWithSettings } = useBigNumberTextLabels(context);
  const { formatNumber } = useNumberFormatter(context);

  function createRadarHand(chartValue, axis, gaugeHand = new GaugeHand()) {
    const userData = chartValue.get(Constant.USER_DATA, {});
    if (userData.MODAL) {
      userData.MODAL.close();
      userData.MODAL.dispose();
    }

    // if we're not showing the hand and we don't have a big number we early out
    if (config.value.gaugeHandType === HandTypes.NONE) {
      if (!gaugeHand.bigNumber) {
        return;
      }

      if (!gaugeHand.bigNumber.position) {
        return;
      }

      if (
        [
          GaugeBigNumberPosition.NONE,
          GaugeBigNumberPosition.INSIDE,
          GaugeBigNumberPosition.BELOW,
        ].includes(gaugeHand.bigNumber.position)
      ) {
        return;
      }
    }

    if (gaugeHand.handIsInvalid() && !config.value.isMultiAxis()) {
      userData.MODAL = Modal.new(root.value, {
        // eslint-disable-next-line tap/no-raw-text-js
        content: `<h3>${HAND_MISSING_WARNING.title}</h3><p>${HAND_MISSING_WARNING.content}</p>`,
      });

      userData.MODAL.open();

      chartValue.set(Constant.USER_DATA, userData);
      return;
    }

    const handDataItem = axis.makeDataItem({
      value: gaugeHand.value,
    });

    const hand = handDataItem.set(
      Constant.BULLET,
      AxisBullet.new(root.value, {
        sprite: ClockHand.new(root.value, {
          ...getHandSettings(gaugeHand),
        }),
      })
    );

    setColors(hand, gaugeHand);

    // remove the label and tick from where the hand is pointing
    const range = axis.createAxisRange(handDataItem);
    range.get(Constant.LABEL).setAll({
      forceHidden: true,
    });
    range.get(Constant.TICK).setAll({
      forceHidden: true,
    });

    // remove the hand entirely if it's disabled
    if (config.value.gaugeHandType === HandTypes.NONE) {
      hand.get(Constant.SPRITE).hand.setAll({
        fillOpacity: 0,
      });
    }

    includeBigNumber(chartValue, hand, gaugeHand);
  }

  function includeBigNumber(chartValue, hand, gaugeHand) {
    if (!gaugeHand.bigNumber) {
      return;
    }

    if (!isBigNumberOnPin(gaugeHand)) {
      return;
    }

    const pinSettings = {
      fill: gaugeHand.bigNumber.fill,
    };

    if (gaugeHand.bigNumber.position === GaugeBigNumberPosition.HAND_TRANSPARENT) {
      pinSettings.stroke = gaugeHand.fill;
      pinSettings.strokeWidth = 1;
    }

    hand.get(Constant.SPRITE).pin.setAll({
      ...pinSettings,
    });

    createLabelWithSettings(chartValue, gaugeHand);
  }

  /**
   *
   * @param hand
   * @param gaugeHand {GaugeHand}
   */
  function setColors(hand, gaugeHand) {
    hand.get(Constant.SPRITE).pin.setAll({
      ...getHandColor(gaugeHand, true),
      ...getPinSettings(),
      ...config.value.getShadowSettings(),
    });

    hand.get(Constant.SPRITE).hand.setAll({
      ...getHandColor(gaugeHand),
      ...config.value.getShadowSettings(),
    });
  }

  function getPinSettings() {
    if (config.value.gaugeHandType === HandTypes.TRIANGLE) {
      return {
        fillOpacity: 0,
      };
    }

    return {};
  }

  /**
   *
   * @param gaugeHand {GaugeHand}
   * @param forPin
   * @returns {{fillOpacity: (number), fill: Color}}
   */
  function getHandColor(gaugeHand, forPin = false) {
    const handColor = gaugeHand.fill;
    const colorSettings = {};
    if (config.value.grainDensity) {
      colorSettings.fillPattern = GrainPattern.new(root.value, {
        density: config.value.grainDensity / 100,
      });
    }

    if (
      !forPin &&
      config.value.fillType !== WidgetBackgroundGradientColorOptions.SOLID &&
      shouldApplyGradient(GradientTargets.HAND, config.value.gaugeGradientTarget)
    ) {
      const gradientStops = [handColor, config.value.gradientColor];

      // repeated fillColor for horizontal gradient so the chosen color is in the middle
      if (config.value.fillType === WidgetBackgroundGradientColorOptions.LINEAR) {
        gradientStops.push(handColor);
      }

      colorSettings.fillGradient = createLinearGradient(
        gradientStops,
        config.value.fillType === WidgetBackgroundGradientColorOptions.LINEAR_Y ? 90 : 0
      );
    }

    return {
      fill: color(handColor),
      fillOpacity: gaugeHand.opacity,
      ...colorSettings,
    };
  }

  function isBigNumberOnPin(gaugeHand) {
    return (
      gaugeHand.bigNumber &&
      (gaugeHand.bigNumber.position === GaugeBigNumberPosition.HAND ||
        gaugeHand.bigNumber.position === GaugeBigNumberPosition.HAND_TRANSPARENT)
    );
  }

  function getPinRadiusForBigNumber(gaugeHand) {
    const roundedNumber = formatNumber(gaugeHand.bigNumber.value, gaugeHand.bigNumber.format);
    const strlen = roundedNumber.toString().length;

    if (GaugeBigNumberLengthToRadiusMap[strlen]) {
      return GaugeBigNumberLengthToRadiusMap[strlen];
    }

    return 25;
  }

  /**
   * @param gaugeHand {GaugeHand}
   */
  function getHandSettings(gaugeHand) {
    const baseSettings = {
      radius: gaugeHand.inside ? -10 : percent(100),
    };
    let insideRadius = gaugeHand.isSingleAxis ? -3 : -11;
    let insideInnerRadius = gaugeHand.isSingleAxis ? -15 : -15;

    if (config.value.gaugeThickness > 1) {
      insideRadius -= config.value.getGaugeMultiAxisThickness();
      insideInnerRadius -= config.value.getGaugeMultiAxisThickness();
    }

    if (isBigNumberOnPin(gaugeHand)) {
      baseSettings.pinRadius = getPinRadiusForBigNumber(gaugeHand);
    }

    switch (config.value.gaugeHandType) {
      case HandTypes.NEEDLE:
        return {
          ...baseSettings,
        };

      case HandTypes.LINE:
        return {
          topWidth: 1,
          bottomWidth: 1,
          ...baseSettings,
        };

      case HandTypes.POINTER:
        return {
          topWidth: 1,
          bottomWidth: isBigNumberOnPin(gaugeHand) ? 50 : 20,
          ...baseSettings,
        };

      case HandTypes.TRIANGLE:
        return {
          ...baseSettings,
          topWidth: gaugeHand.inside ? 1 : 20,
          bottomWidth: gaugeHand.inside ? 20 : 1,
          radius: gaugeHand.inside ? insideRadius : percent(110),
          innerRadius: gaugeHand.inside ? insideInnerRadius : percent(102),
        };

      default:
        return baseSettings;
    }
  }

  return {
    createRadarHand,
  };
}
