import angular from 'angular';

angular.module('design.widget.models', [])
    .factory('WidgetModelFactory', WidgetModelFactory);

/**
 * @ngInject
 */
function WidgetModelFactory(

) {
    return {
    };
}