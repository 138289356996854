export class WidgetContext {
  /**
   * @abstract
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  /* eslint-disable no-unused-vars */
  applyContext(widget, config) {}

  /**
   * @abstract
   *
   * @param {Widget} widget
   * @param drawOption
   */
  evaluateDrawOption(widget, drawOption) {}

  /**
   * @abstract
   *
   * @param drawOption
   */
  getDrawOption(drawOption) {}

  /**
   * @abstract
   * @param {Widget} widget
   * @returns {array}
   */
  getChartPalette(widget) {}

  /**
   * @abstract
   * @param {Widget} widget
   */
  setParentProperties(widget) {}

  /**
   * @abstract
   * @param widgetId
   */
  setWidgetIdAsRendered(widgetId) {}

  /**
   * @abstract
   * @returns {boolean}
   */
  isDemoModeEnabled() {}
}
