import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import {ExportBuilderAdminNewReportModalConstants} from "coreModules/exportbuilder/exportbuilder.constants";

angular.module('exportbuilder.models', [])
    .factory('ExportBuilderModelFactory', ExportBuilderModelFactory);

/**
 * @ngInject
 */
function ExportBuilderModelFactory(
    DataSourceFactory,
    gettextCatalog,
    PageFormatConstants
) {
    return {
        getReportStudioGalleryPageModel: getReportStudioGalleryPageModel,
        getReportStudioGalleryData: getReportStudioGalleryData,
        getReportStudioGalleryState: getReportStudioGalleryState,
        getReportStudioGalleryIsotopeOptions: getReportStudioGalleryIsotopeOptions,
        getReportStudioNewReportState: getReportStudioNewReportState,
        getReportStudioNewReportData: getReportStudioNewReportData,
    };

    /**
     * @constructor
     */
    function ReportStudioGalleryPageModel(model) {
        var self = this;

        self.id = model.id;
        self.title = model.title;
        self.is_private = model.is_private;
        self.is_predefined = model.is_predefined;
        self.is_preview = model.is_preview;
        self.type = model.type;
        self.metadata = model.metadata;
        self.orientation = model.orientation;
        self.format = model.format || PageFormatConstants.STANDARD;
        self.header_content = model.header_content;
        self.footer_content = model.footer_content;
        self.number_of_pages = model.number_of_pages;
        self.user_id = model.user_id;
        self.user = model.user;
        self.user_image_metadata = model.user_image_metadata;
        self.client = model.client || null;
        self.client_group = model.client_group || null;
        self.shared_users = model.shared_users;
        self.data_sources = _.reduce(model.data_sources, function(acc, dataSource) {
            if (dataSource) {
                dataSource = DataSourceFactory.getDataSourceDetails(dataSource.data_type, dataSource.data_source_id);
                dataSource && acc.push(dataSource);
            }
            return acc;
        }, []);
        self.created_at = model.created_at;
        self.updated_at = model.updated_at;
        self.can_be_deleted = model.can_be_deleted;
        self.can_be_copied = model.can_be_copied;
        self.can_be_edited = model.can_be_edited;
        self.is_in_library = model.is_in_library;
        self.tags = model.tags;
        self.report_language = model.report_language;

        self.formatted_created_at = moment.unix(self.updated_at).format('MMM Do YYYY');

        self.is_inactive = model.is_inactive || false;
    }

    /**
     * @constructor
     */
    function ReportStudioGalleryState() {
        var self = this;

        self.isTextSearchActive = false;
    }

    /**
     * @constructor
     */
    function ReportStudioGalleryData() {
        var self = this;

        self.sortOptions = [
            {id: 'title', name:  gettextCatalog.getString('Title')},
            {id: 'created_at', name: gettextCatalog.getString('Created On')},
            {id: 'updated_at', name: gettextCatalog.getString('Updated On')},
        ];
        self.orderOptions = [
            {id: 'asc', value: true, name: gettextCatalog.getString('Ascending')},
            {id: 'desc', value: false, name: gettextCatalog.getString('Descending')},
        ];
        self.noFilterString = '*';
        self.numberOfResultsOptions = [
            { id: 10, value: 10, name: 'Number of results: 10' },
            { id: 25, value: 25, name: 'Number of results: 25' },
            { id: 50, value: 50, name: 'Number of results: 50' },
            { id: 100, value: 100, name: 'Number of results: 100' },
            { id: 'all', value: 'all', name: 'Number of results: All' }
        ];
    }

    /**
     * @param model
     * @constructor
     */
    function ReportStudioGalleryIsotopeOptions(model) {
        var self = this;
        model = model || {};

        self.items = [];
        self.itemSelector = '.grid-item';
        self.layoutMode = 'masonry';
        self.masonry = {
            columnWidth: 370,
            gutter: 40,
            fitWidth: true
        };
        self.transitionDuration = '0.3s';
        self.horizontalOrder = true;
        self.filter = '*';
        self.currentFilter = self.filter;
        self.sortBy = model.sortBy || 'updatedAt';
        self.searchValue = null;
        self.sortAscending = model.sortAscending || false;
        
        // dynamic
        self.$element = null;
        self.bindTo = function ($el) {
            self.$element = $el;
        };

        /**
         * @returns {number}
         */
        self.getFilteredCount = function () {
            if (self.$element) {
                return self.$element.isotope('getFilteredItemElements').length
            }
            return 0;
        };
    }

    /**
     * @constructor
     */
    function ReportStudioNewReportState() {
        var self = this;

        self.isNUI = window.isNUI;
        self.isActive = false;
        self.loadingTemplates = true;
        self.hideBackButton = false;
        self.isCreatingBlankReport = false;
        self.isTextSearchActive = false;
        self.isSelectingDesignTemplate = false;
        self.isSelectingReportTemplate = false;
        self.isSelectingLibraryTemplate = false;
        self.showBlankTemplateOption = true;
        self.showDesignTemplateOption = true;
        self.showSkipButton = false;
        self.showReportTemplateOption = true;
        self.customTemplatesTitle = '';
        self.context = null;
    }

    /**
     * @constructor
     */
    function ReportStudioNewReportData() {
        var self = this;

        self.modalId = ExportBuilderAdminNewReportModalConstants.MODAL_ID;
    }

    function getReportStudioGalleryPageModel(model) {
        return new ReportStudioGalleryPageModel(model);
    }

    function getReportStudioGalleryState() {
        return new ReportStudioGalleryState();
    }

    function getReportStudioGalleryData() {
        return new ReportStudioGalleryData();
    }

    function getReportStudioGalleryIsotopeOptions(model) {
        return new ReportStudioGalleryIsotopeOptions(model);
    }

    function getReportStudioNewReportState() {
        return new ReportStudioNewReportState();
    }

    function getReportStudioNewReportData() {
        return new ReportStudioNewReportData();
    }
}