'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.connectionstatus.resources', [])

    .factory('ConnectionStatusResource', ConnectionStatusResource)
    .factory('ConnectionStatusDataGridFactory', ConnectionStatusDataGridFactory);

/**
 * @ngInject
 */
function ConnectionStatusResource(
    Restangular
) {
    var resource = Restangular.all('connectionstatus');

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        queryParams.all = true;
        return resource.getList(queryParams);
    }

    return {
        getData: getData
    };
}

/**
 * @ngInject
 */
function ConnectionStatusDataGridFactory(
    ConnectionStatus,
    ConnectionsStatusIconStyles
) {
    return {
        getDTOptions: getDTOptions,
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};

        // custom renders
        dtOptions.customRenders = {
            'service_id': function (data) {
                var result;
                if (!_.isEmpty(data)) {
                    var service = data[Object.keys(data)[0]];
                    result =
                        '<div class="service-square service-square-16 mr5" style="background-color:' + service.color + '">' +
                        '<div class="icon serviceicon-' + service.icon + '"></div>' +
                        '</div>' + service.name;
                }
                else {
                    result = '';
                }

                return result;
            },
            'status': function (data, type, full) {
                var html = '';
                var iconStyle;
                switch (data) {
                    case ConnectionStatus.SUCCESS:
                        html = '<span class="hidden">0</span>';
                        iconStyle = ConnectionsStatusIconStyles.SUCCESS;
                        break;
                    case ConnectionStatus.IN_PROGRESS:
                        html = '<span class="hidden">3</span>';
                        iconStyle = ConnectionsStatusIconStyles.IN_PROGRESS;
                        break;
                    case ConnectionStatus.CREDENTIALS:
                        html = '<span class="hidden">2</span>';
                        iconStyle = ConnectionsStatusIconStyles.ERROR;
                        break;
                    default:
                        html = '<span class="hidden">1</span>';
                        iconStyle = ConnectionsStatusIconStyles.WARNING;
                        break;
                }

                if (iconStyle === ConnectionsStatusIconStyles.SUCCESS) {
                    html += '<div class="icon ' + iconStyle + '"></div>';
                    return html;
                }

                var tooltip = full.error_message ? ' has-tooltip" title="' + full.error_message.replace(/"/g, '&quot;') : '';

                var serviceId = Object.keys(full.service_id)[0];
                html += '<a href="#/service/manage/connections/'+ serviceId +'" class="go-datasource-action" data-service-id="' + serviceId +'"><span class="icon ' + iconStyle + tooltip + '"</span></a>';
                return html;
            },
            'lookback': function (data) {
                return '<div class="icon icon-calendar has-tooltip" title="' + 'Initial data load: ' + data['historical'] + "\n" + 'Regular data load: ' + data['regular'] + '"></div>';
            }
        };

        // column widths
        dtOptions.columnWidths = {
            'status': 50
        };

        return dtOptions;
    }
}