'use strict';
import angular from 'angular';
import './goals.constants';
import './details/goals.details.components';
import './base/goals.components';
import './base/goals.models';
import './base/goals.services';
import './base/goals.resources';

import goalsHtmlUrl from './base/goals.html';

angular.module('core.goals', [
    'goals.constants',
    'goals.details.components',
    'goals.components',
    'goals.models',
    'goals.services',
    'goals.resources'
])
    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('goals', {
                parent:'layout',
                url: '/goals?start_date&end_date',
                resolve: {
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_GOALS;
                    },
                    vars: function(GoalsResource) {
                        return GoalsResource.getServices();
                    }
                },
                views: {
                    '': {
                        templateUrl: goalsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);