import {
  Constant,
  LABEL_CUTOFFS,
  LABEL_PLACEHOLDERS,
  PieChartConstant,
} from '@/modules/core/charts/am5/charts.constants';
import { PieChartDrawOption } from '@/modules/ta/widget/builder.constants';
import { Rectangle, Tooltip } from '@amcharts/amcharts5';
import { color, invertColor } from '@/modules/core/charts/am5/charts.helper';
// eslint-disable-next-line import/no-cycle
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export function useToolTips(context) {
  const { root, config, isDarkTheme } = context();
  const { getFormatterForType } = useLabels(context);

  /* Apply label and tooltip formats with currency symbol to pie chart */
  function applyLabelFormatWithCurrency(series) {
    series.labels.template.adapters.add(Constant.TEXT, (text, target) => {
      const { dataItem } = target;
      if (dataItem) {
        text = applyLabelBasedUponSettings(dataItem.dataContext);
      }
      return text;
    });
    if (!config.value.series[0].tooltipDisabled) {
      series.slices.template.adapters.add(Constant.TOOLTIP_TEXT, (tooltipText, target) => {
        const { dataItem } = target;
        if (dataItem) {
          tooltipText = applyToolTipText(dataItem.dataContext);
        }
        return tooltipText;
      });
    }
  }

  /* Format Labels with currency */
  function applyLabelBasedUponSettings({ dataFormat, metric }) {
    let text = '';
    const { showLabels, showLabelNames, showLabelValues, showLabelPercent } = config.value;
    const textColor = assignThemeColor(isDarkTheme, config.value);
    metric = calculateMetricSize(
      config.value.widgetData.width,
      config.value.hasComparisonStatus,
      metric
    );

    if (!showLabels) {
      return text;
    }
    if (showLabelPercent) {
      text += PieChartDrawOption.SHOW_LABEL_PERCENT;
    }
    if (showLabelValues) {
      const labelValue = getPieSpecificFormatter(dataFormat);
      text = text !== '' ? `${labelValue} (${text})` : labelValue;
    }
    if (showLabelNames) {
      text = text !== '' ? `${metric}: ${text}` : metric;
    }
    text = `[${textColor}]${text}`;

    return text;
  }

  /**
   * The pie chart value will always add up to 100% because that's how pie charts work
   * If we have a percentage metric we need to make sure that the value we're showing
   * is the raw value (the value from the API)
   * These won't add up to 100% but that doesn't matter for labels / tooltips
   * @param dataFormat
   * @returns {string|string}
   */
  function getPieSpecificFormatter(dataFormat) {
    return dataFormat === ColumnFormat.FORMAT_PERCENT
      ? LABEL_PLACEHOLDERS.PIE_PERCENT
      : getFormatterForType(dataFormat);
  }

  /** Calculate label size based on widget height and label size,
   * if label size is greater than 25 an ellipsis is added
   * */
  function calculateMetricSize(widgetWidth, hasComparisonStatus, metric) {
    let prefixLength = 0;

    switch (true) {
      case widgetWidth === 3:
        prefixLength = 5;
        break;
      case widgetWidth > 3 && widgetWidth <= 5: {
        const setMinLength = metric.length > 25 ? 13 : 8;
        prefixLength = hasComparisonStatus ? 0 : setMinLength;
        break;
      }
      case widgetWidth > 5 && widgetWidth <= 7:
        prefixLength = hasComparisonStatus ? 0 : 20;
        break;
      case widgetWidth > 7 && widgetWidth <= 9:
        prefixLength = hasComparisonStatus ? 5 : 20;
        break;
      case widgetWidth > 9 && widgetWidth <= 12:
        prefixLength = hasComparisonStatus ? 10 : 25;
        break;
      default:
        break;
    }

    const ellipsis = metric.length > prefixLength ? '...' : '';
    return metric.substring(0, prefixLength) + ellipsis;
  }

  function applyToolTipText({ dataFormat, legendData, fill }) {
    let tooltipText = '';
    const { showToolTip, hasTooltip } = config.value;
    const tooltipColorBackground = showToolTip ? legendData.fill : fill;
    const textColor = invertColor(tooltipColorBackground, true);

    if (!showToolTip && !hasTooltip) {
      return tooltipText;
    }

    tooltipText += PieChartDrawOption.SHOW_LABEL_PERCENT;
    const labelValue = getPieSpecificFormatter(dataFormat);
    tooltipText = ` ${labelValue} (${tooltipText})`;
    tooltipText =
      tooltipText !== ''
        ? `${PieChartDrawOption.SHOW_CATEGORY}:${tooltipText}`
        : PieChartDrawOption.SHOW_CATEGORY;

    tooltipText = `[${textColor}]${tooltipText}`;

    return tooltipText;
  }

  /* While hovering on label show tooltips */
  function labelHover(series, { labelText }) {
    const { showToolTip, hasTooltip } = config.value;
    if (showToolTip || hasTooltip) {
      series.labels.template.set(Constant.TOOLTIP_TEXT, labelText);
      series.labels.template.setup = (target) => {
        const tooltip = Tooltip.new(root.value, {
          getFillFromSprite: false,
          labelText,
        });

        series.labels.template.set(Constant.TOOLTIP, tooltip);

        /* Creating a rectangle shape to view the tooltip in the target element */
        target.set(
          Constant.BACKGROUND,
          Rectangle.new(root.value, {
            fill: color(0x000000),
            fillOpacity: 0,
          })
        );
      };
      /* Pie chart label hover event this can be initiated only after setting up templates */
      series.labels.template.events.on(Constant.POINTER_OVER, (ev) => {
        const currentTooltip = ev.target.get(Constant.TOOLTIP);
        const tooltipBackground = currentTooltip.get(Constant.BACKGROUND);
        const { dataItem } = ev.target;

        if (dataItem) {
          const { legendData, fill } = dataItem.dataContext;
          const tooltipColorBackground = showToolTip ? legendData.fill : fill;
          currentTooltip.set(LABEL_CUTOFFS.LABEL_TEXT, applyToolTipText(dataItem.dataContext));
          tooltipBackground.set(Constant.FILL, tooltipColorBackground);
        }
      });
    }
  }

  /**
   * Selects font base color based upon theme
   * @returns {string|string}
   * @param checkTheme
   * @param fontColorPicker
   */
  function assignThemeColor(checkTheme, { fontColorPicker }) {
    const textColor = checkTheme.value
      ? PieChartConstant.WHITE_TEXT_COLOR
      : PieChartConstant.BLACK_TEXT_COLOR;
    return fontColorPicker || textColor;
  }
  return {
    applyLabelFormatWithCurrency,
    labelHover,
    assignThemeColor,
  };
}
