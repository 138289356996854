'use strict';
import angular from 'angular';

import widgetDaterangeFilterModalHtmlUrl from './widget.daterangefilter.modal.html';
import widgetDaterangeFilterTogglerHtmlUrl from './widget.daterangefilter.toggler.html';
import widgetDaterangeFilterPanelHtmlUrl from './widget.daterangefilter.panel.html';

angular.module('widget.daterange.directives', [])

    .directive('dateRangeFilterModal', dateRangeFilterModal)
    .directive('dateRangeFilterToggler', dateRangeFilterToggler)
    .directive('dateRangeFilterPanel', dateRangeFilterPanel);

/**
 * @ngInject
 */
function dateRangeFilterModal(
    RelativeDateRange,
    WidgetDateRangeFactory
) {
    return {
        restrict: 'E',
        templateUrl: widgetDaterangeFilterModalHtmlUrl,
        scope: {
            metadata: '<'
        },
        controllerAs: 'vm',
        controller: function ($scope) {
            var vm = this;
            vm.RelativeDateRange = RelativeDateRange;
            vm.disableComparison = $scope.metadata.disable_comparison;
            vm.shared = WidgetDateRangeFactory.shared;
        }
    };
}

/**
 * @ngInject
 */
function dateRangeFilterToggler(
    WidgetDateRangeFactory
) {
    return {
        restrict: 'E',
        templateUrl: widgetDaterangeFilterTogglerHtmlUrl,
        scope: {
            state: '=',
            widget: '='
        },
        controller: function($scope) {
            $scope.canInteract = $scope.widget.can_be_edited;

            $scope.updateDateRangeOverride = updateDateRangeOverride;
            $scope.clearDateRangeOverride = clearDateRangeOverride;

            function updateDateRangeOverride() {
                $scope.state.showDateRangeFilterPanel = false;
                WidgetDateRangeFactory.initModal($scope.widget);
            }

            function clearDateRangeOverride() {
                $scope.state.showDateRangeFilterPanel = false;
                WidgetDateRangeFactory.shared.clearDateRangeOverride($scope.widget);
            }
        },
        link: function(scope, el) {

            var $toggler = el.find('div.date-range-filter-toggler');
            scope.triggerDateRangeFilterPanel = function() {
                $toggler.tooltip('hide');
                scope.state.showDateRangeFilterPanel = !scope.state.showDateRangeFilterPanel;
            };
        }
    }
}

/**
 * @ngInject
 */
function dateRangeFilterPanel(
    RelativeDateRange,
    DateRangeFactory
) {
    return {
        restrict: 'E',
        templateUrl: widgetDaterangeFilterPanelHtmlUrl,
        scope: {
            showDateRangeFilterPanel: '=',
            metadata: '<'
        },
        controller: function ($scope) {
            $scope.RelativeDateRange = RelativeDateRange;
            $scope.getRangePrefix = getRangePrefix;
            $scope.getRelativeDateRange = getRelativeDateRange;

            function getRangePrefix(value) {
                if (value.contains('last')) {
                    return 'is in the';
                }
                else {
                    return 'is';
                }
            }

            function getRelativeDateRange(value) {
                if (value && value !== RelativeDateRange.CUSTOM) {
                    return DateRangeFactory.getRelativeDateRange(value).label;
                }
            }
        }
    }
}