import { extend } from 'lodash';
import RoleListDataService from '@/modules/ta/role/services/RoleListDataService';
import { PredefinedRoleKey } from '@/modules/ta/role/role.constants';
import { UserType } from '@/modules/ta/user/user.constants';
import { FormSelectOption } from '@/modules/core/form/models/FormSelectOption';

export class UserRoleFormSelectOption extends FormSelectOption {
  /**
   * @var {UserType|string}
   */
  userType;

  roleType;

  show_classic = true;

  roleId;

  constructor() {
    super({
      valueIsObject: false,
    });
  }

  setUserType(value) {
    this.userType = value;
  }

  setShowClassic(value) {
    this.show_classic = value;
  }

  setRoleType(value) {
    this.roleType = value;
  }

  setRoleId(value) {
    this.roleId = value;
  }

  /**
   * @override
   *
   * @param params
   * @returns {Promise<{values: *}>}
   */
  async getData(params) {
    const roleData = await RoleListDataService.getData(
      extend(params, {
        fields: 'name,predefined_role_key,is_default,is_selectable,user_type',
        sort: 'name',
        user_type: this.userType,
        role_type: this.roleType,
      })
    );

    const nonSelectableCurrentRoleData = roleData.find(
      (role) => !role.is_selectable && role.id === this.roleId
    );

    const values = roleData
      .filter(this.filterOutPredefinedUserTypeRoles.bind(this))
      .filter(this.filterOutNotSelectable.bind(this))
      .map(({ id, name, predefined_role_key, is_default, is_selectable, user_type }) => ({
        key: id,
        value: name,
        predefined_role_key,
        is_default,
        is_selectable,
        user_type,
      }));

    if (nonSelectableCurrentRoleData) {
      const { id, name, predefined_role_key, is_default, is_selectable, user_type } =
        nonSelectableCurrentRoleData;
      values.push({
        key: id,
        value: name,
        predefined_role_key,
        is_default,
        is_selectable,
        user_type,
      });
    }
    return {
      values,
    };
  }

  /**
   * @param {Role} role
   * @returns {boolean}
   */
  filterOutNotSelectable(role) {
    return role.is_selectable;
  }

  /**
   * @param {Role} role
   * @returns {boolean}
   */
  filterOutPredefinedUserTypeRoles(role) {
    switch (this.userType) {
      case UserType.SUPERADMIN:
        return ![
          PredefinedRoleKey.CLUSTER_ADMIN_ROLE,
          PredefinedRoleKey.AGENT_ROLE,
          PredefinedRoleKey.CLIENT_ROLE,
        ].includes(role.predefined_role_key);
      case UserType.CLUSTERADMIN:
        return ![
          PredefinedRoleKey.SUPER_ADMIN_ROLE,
          PredefinedRoleKey.AGENT_ROLE,
          PredefinedRoleKey.CLIENT_ROLE,
        ].includes(role.predefined_role_key);
      case UserType.AGENT:
        if (this.show_classic) {
          return ![
            PredefinedRoleKey.SUPER_ADMIN_ROLE,
            PredefinedRoleKey.CLUSTER_ADMIN_ROLE,
            PredefinedRoleKey.CLIENT_ROLE,
          ].includes(role.predefined_role_key);
        }
        return ![
          PredefinedRoleKey.SUPER_ADMIN_ROLE,
          PredefinedRoleKey.DEFAULT_ROLE,
          PredefinedRoleKey.CLUSTER_ADMIN_ROLE,
          PredefinedRoleKey.CLIENT_ROLE,
        ].includes(role.predefined_role_key);
      case UserType.CLIENT:
        return ![
          PredefinedRoleKey.SUPER_ADMIN_ROLE,
          PredefinedRoleKey.CLUSTER_ADMIN_ROLE,
          PredefinedRoleKey.AGENT_ROLE,
        ].includes(role.predefined_role_key);
      default:
        return true;
    }
  }
}
