'use strict';
import angular from 'angular';
import $ from 'jquery';

import clientSelectorHtmlUrl from '../views/client.selector.html';

angular.module('client.directives', [])

    .directive('clientSelector', clientSelector);

//
//  directive
//
clientSelector.$inject = ['ClientFactory'];
function clientSelector(ClientFactory) {
    return {
        restrict: 'E',
        scope: {
            clientId: '=',
            defaultLabel: '@'
        },
        templateUrl: clientSelectorHtmlUrl,
        link: function(scope, el, attrs) {
            scope.defaultLabel = scope.defaultLabel || 'Select a client';
            scope.clientId = scope.clientId || null;

            ClientFactory.clients.getList().then(function(data){
                scope.clients = data.plain();
            });
        }
    }

}