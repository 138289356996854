"use strict";
import $ from 'jquery';

var Core = $.core.dashboard.services = {
    orderKey: 'service_dashboard_order',
    initialize: function () {

        this.build();
        this.events();

        this.orderServices();
        this.reorderServicesBindings();
    },
    build: function () {
    },
    events: function () {
    },
    orderServices: function () {
        //We already have an ordering
        if (amplify.lookupCache(Core.orderKey)) {
            Core.applySortOrder();
        }
        else {
            Core.saveSortOrder();
        }
    },
    applySortOrder: function () {
        var $dashboard = $('#services-dashboard');
        var $items = $dashboard.find('div.panel-wrapper');

        var cachedData = amplify.getFromCache(Core.orderKey);
        $.each(cachedData, function(i, val) {
            $dashboard.append($items.filter('[data-service-id='+val+']'));
        });
    },
    saveSortOrder: function () {
        var $items = $('#services-dashboard').find('div.panel-wrapper');

        var orderArray = [];
        $items.each(function() {
            orderArray.push($(this).data('serviceId'));
        });
        amplify.safeStore(Core.orderKey, orderArray, {expires: false});
    },
    reorderServicesBindings: function () {
        var $dashboard = $('#services-dashboard');
        if(!$dashboard.hasClass('ui-sortable')) {
            $dashboard.sortable({
                handle: '.sort-handle'
            });
        }
        $('#sort-services').click(function() {
            Core.updateSortState(true);
        });

        $('#sort-save').click(function() {
            Core.saveSortOrder();
            Core.updateSortState(false);
            $.core.main.notify('Service ordering was successfully saved', $.globals.notify.success);
        });

        $('#sort-cancel').click(function() {
            //This will reset the order since it will apply the sort array without saving, which is the original ordering
            Core.applySortOrder();
            Core.updateSortState(false);
        });
    },
    updateSortState: function (setToActive) {
        var $buttons = $('#sort-buttons');
        var $sortButton = $('#sort-services');
        var $dashboard = $('#services-dashboard');

        //Set back to inital state
        if(!setToActive) {
            $sortButton.removeClass('active');
            $buttons.hide();
            $dashboard.removeClass('reorder-active');
            $dashboard.find('div.panel').css({ marginLeft: '15px'});

            $dashboard.find('div.sort-handle-container').hide();
            $dashboard.find('div.panel-body').show();
            $dashboard.find('div.panel-heading div.date-info').show();

            $dashboard.enableSelection();
        }
        //Set to reorder state
        else {
            $sortButton.addClass('active');
            $buttons.show();
            $dashboard.addClass('reorder-active');
            $dashboard.find('div.panel').css({ marginLeft: '100px'});

            $dashboard.find('div.sort-handle-container').show();
            $dashboard.find('div.panel-body').hide();
            $dashboard.find('div.panel-heading div.date-info').hide();

            $dashboard.disableSelection();
        }
    }
};