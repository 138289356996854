'use strict';
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import swal from 'bootstrap-sweetalert';

angular.module('core.app.models', [])

    .service('AppModelFactory', AppModelFactory);

/**
 * App factory (exposes app wide variables externally)
 * @ngInject
 */
function AppModelFactory(
    MomentDateFormat,
    $q,
    gettextCatalog,
    LanguageService
) {
    return {
        getDateOptions: getDateOptions,
        getSelectOptions: getSelectOptions,
        getSwalOptions: getSwalOptions,
        formatUnixTimeStamp: formatUnixTimeStamp,
        getDurationToSeconds: getDurationToSeconds
    };

    /**
     * DateRangePicker constructor
     * @param dateOptions
     * @returns {DateOptions}
     * @constructor
     */
    function DateOptions(dateOptions) {
        dateOptions = _.isEmpty(dateOptions) ? {} : dateOptions;
        var self = this;
        // Note: start and end date need to be set to undefined, so that the date options _.extend function can
        // replace the value with default dates. Null will crash the dateRangePicker
        self.startDate = dateOptions.startDate ? _getUnixTimestamp(dateOptions.startDate) : null;
        self.endDate = dateOptions.endDate ? _getUnixTimestamp(dateOptions.endDate) : null;
        self.emptyOnNull = _.isNull(dateOptions.emptyOnNull) ? true : dateOptions.emptyOnNull;
        self.disableRanges = _.isNull(dateOptions.disableRanges) ? true : dateOptions.disableRanges;
        self.showClearButton = _.isNull(dateOptions.showClearButton) ? true : dateOptions.showClearButton;
        self.drops = dateOptions.drops || 'down';
        self.linkedCalendars = _.isNull(dateOptions.linkedCalendars) ? false : dateOptions.linkedCalendars;
        self.locale = _.isEmpty(dateOptions.locale) ? {
            format: LanguageService.getDisplayDateFormat(),
            applyLabel: gettextCatalog.getString('Apply'),
            cancelLabel: gettextCatalog.getString('Cancel'),
            customRangeLabel: gettextCatalog.getString('Custom Range')
        } : dateOptions.locale;
        self.opens = dateOptions.opens || 'down';
        self.alwaysShowCalendars = dateOptions.alwaysShowCalendars || true;
        self.showCustomRangeLabel = dateOptions.showCustomRangeLabel || true;
        self.autoApply = dateOptions.autoApply || true;
        self.applyClass = dateOptions.applyClass || 'btn-primary';
        self.placeholder = dateOptions.placeholder || gettextCatalog.getString('No Date Selected');
        self.parentEl = dateOptions.parentEl || 'body';
        self.noDatePlaceholder = gettextCatalog.getString("No Date");
        self.dateChangeCB = dateOptions.dateChangeCB || null;
        self.onShow = dateOptions.onShow || null;
        self.singleDatePicker = _.isNil(dateOptions.singleDatePicker) ? false : dateOptions.singleDatePicker;

        self.callback = function (start, end, label) {
            self.startDate = start.format('X');
            self.endDate = end.format('X');
            dateOptions.dateChangeCB && dateOptions.dateChangeCB(start, end, label);
        };

        self.getFormattedDate = function(date, format) {
            return moment.unix(date).isValid() ? _formatUnixDate(date, format) : moment(date).format(format);
        };

        self.getDateDisplay = function() {
            if (!self.startDate && !self.endDate) {
                return self.noDatePlaceholder;
            }

            return _formatUnixDate(self.startDate) + ' - ' + _formatUnixDate(self.endDate);
        };

        return self;
    }

    function _getUnixTimestamp(date) {
        return moment.unix(date).isValid() ? parseInt(date) : moment(date).unix();
    }

    /**
     * Format date for display
     * @returns {Array}
     */
    function _formatUnixDate(date, format) {
        format = format || MomentDateFormat.MONTH_DAY_YEAR;
        return date ? moment.utc(moment.unix(date)).format(format) : undefined;
    }

    /**
     * Select2 constructor
     * @param model
     * @constructor
     */
    function SelectOptions(model = {}) {
        var self = this;

        self.$elSelector = model.$elSelector || null;
        self.key = model.key || 'select2-' + Math.random();
        self.width = model.width || '100%';
        self.separator = model.separator || '||||||||'; // we need to use a separator string that is very unlikely to be used in a filter name (TA-11993)
        self.acceptWildcard = model.acceptWildcard || null;
        self.selectorType = model.selectorType;
        self.multiple = model.multiple || false;
        self.disabled = model.disabled || false;
        self.allowClear = !_.isUndefined(model.allowClear) ? model.allowClear : true;
        self.formatResult = model.formatResult;
        self.formatSelection = model.formatSelection;
        self.formatNoMatches = model.formatNoMatches;
        self.matcher = model.matcher;
        self.onClear = model.onClear;
        self.formatData = model.formatData;
        self.autoOpen = model.autoOpen || false;
        self.key = model.key || '';
        self.label = model.label || '...';
        self.placeholder = !_.isUndefined(model.placeholder)
            ? model.placeholder
            : gettextCatalog.getString('Select a {{label}} ...', {label: self.label});
        self.listenToOtherSelect2 = model.listenToOtherSelect2 || false; // Option to listen to changes on other select2 components
        self.tags = model.tags || undefined; // tags need to be unset, select2 just checks if this value is set
        self.loaded = model.loaded;
        self.values = model.values || (self.multiple ? [] : {});
        self.selectedValues = model.selectedValues;
        self.dropdownCssClass = model.dropdownCssClass || null;
        self.hasMoreData = model.hasMoreData || false;
        self.autofocus = model.autofocus || false;
        self.rebuild = model.rebuild || false;
        self.isGrouped = model.isGrouped || false;
        self.isSortable = model.isSortable || false;
        self.onSortStart = model.onSortStart || undefined;
        self.onSortEnd = model.onSortEnd || undefined;

        self.dataCallPromise = null;

        /**
         * IMPORTANT: getData call should be able to handle a query, and return a promise
         * EX:
         * function getDataExample(query) {
         *   var params = {status: 'active'};
         *   var field = 'id';
         *   if (query) {
         *     params.q = query + '|name';
         *   }
         *
         *   return service.getFieldValues(field, params);
         * }
         *
         * @type {*|getDataCall}
         */
        self.getDataCall = model.getDataCall;

        self.queryCallback = model.queryCallback;

        self.onChange = _.isNil(model.onChange) ? function(key, $el) {
            // Only reset values if change did not come from the source select2 emitting the vent
            $el.select2('data');
        } : model.onChange;

        self.onReset = _.isNil(model.onReset) ? function(e, $el) {
            $el.select2('data', null);
        } : model.onReset;

        self.updateSelectValues = function(data) {
            if (self.$elSelector === null) {
                console.log('You must set the $el property');
            }
            angular.element(self.$elSelector).select2('data', data);
        };

        self.resetSelectValues = function() {
            if (self.$el === null) {
                console.log('You must set the $el property');
            }
            angular.element(self.$elSelector).select2('data', null);
        };

        // Warn developer if no values, and no data call are supplied
        // Select2 should handle the data request if values are not
        // ready at the time of select2 initialization
        if (!self.getDataCall && _.isEmpty(self.values)) {
            console.warn('getSelectOptions Warning: Value call should be supplied through getDataCall', model);
        }

        // Call backend if dataCall is provided
        if (self.getDataCall) {
            self.dataCallPromise = $q.resolve(self.getDataCall()).then(function(json) {
                self.callFormatData(json);
            });
        }

        self.callFormatData = function(json) {
            if (json) {
                self.hasMoreData = json.has_more_data || (json.data && json.data.has_more_data);

                // NOTE: Not every endpoint returns data formatted for select2.
                // Use the formatData callback if that is the case
                if (self.formatData) {
                    self.formatData(json.plain ? json.plain() : json);
                } else {
                    self.values = _.map(json.data, function (data) {
                        return {id: data.id, text: data.text};
                    });
                }
            } else {
                self.hasMoreData = false;
                self.values = [];
            }
        };

        self.tooMuchDataCallback = model.tooMuchDataCallback || function(query) {
            // In order to enable tags, the query must be the first item in results
            var values = Array.isArray(this.data) ? this.data : this.data().results;

            var valuesWithTag = query.term.length > 1 && model.acceptWildcard ?
                [{id: query.term, text: query.term}].concat(values) :
                values;

            var selectedValues = {results: valuesWithTag};
            // Query length must be checked here, or else the selectable values will never be set
            if (query.term.length >= 2 && self.getDataCall) {
                $q.resolve(self.getDataCall(query.term)).then(function(item) {
                    self.loaded = true;
                    if (item.values.length) {
                        // Only show the query if wildcards are a selectable value
                        selectedValues.results = model.acceptWildcard ? [{id: query.term, text: query.term}] : [];
                        _.each(item.values, function (val) {
                            selectedValues.results.push({id: val.key, text: val.value});
                        });
                    }
                    self.queryCallback && self.queryCallback(item.values, selectedValues);
                    query.callback(selectedValues);
                }, function (error) {
                    console.error("Error getting data", error);
                });
            }
            else {
                query.callback(selectedValues);
            }
        };
    }



    /**
     *
     * @param model
     * @returns {SwalOptions}
     * @constructor
     */
    function SwalOptions(model) {
        model = model || {};
        var self = this;

        self.title = _.isUndefined(model.title) ? gettextCatalog.getString('Are you sure?') : model.title;
        self.text = model.text || '';
        self.html = model.html || false;
        self.type = _.isUndefined(model.type) ? 'warning' : model.type;
        self.imageUrl = model.imageUrl || null;
        self.showCancelButton = _.isUndefined(model.showCancelButton) ? true : model.showCancelButton;
        self.showConfirmButton = _.isUndefined(model.showConfirmButton) ? true : model.showConfirmButton;
        self.confirmButtonText = model.confirmButtonText || 'Yes';
        self.cancelButtonText = model.cancelButtonText || 'No';
        self.confirmButtonClass = model.confirmButtonClass || 'btn-primary';
        self.cancelButtonClass = model.cancelButtonClass || 'btn-default';
        self.closeOnConfirm = _.isUndefined(model.closeOnConfirm) ? true : model.closeOnConfirm;
        self.closeOnCancel = _.isUndefined(model.closeOnCancel) ? true : model.closeOnCancel;
        self.showLoaderOnConfirm = _.isUndefined(model.showLoaderOnConfirm) ? true : model.showLoaderOnConfirm;
        self.callback = model.callback || null;
        self.cancelFn = model.cancelFn || null;
        self.confirmFn = _.isNil(model.confirmFn) ? function(isConfirm) {
            if (isConfirm && model.callback) {
                model.callback(isConfirm);
            } else if (!isConfirm && model.cancelFn) {
                model.cancelFn();
            } else {
                swal.close();
            }
        } : model.confirmFn;
        return self;
    }

    /**
     *
     * @param model
     * @returns {DateOptions}
     */
    function getDateOptions(model) {
        return new DateOptions(model);
    }

    /**
     * @param model
     * @returns {SelectOptions}
     */
    function getSelectOptions(model) {
        return new SelectOptions(model);
    }

    /**
     *
     * @param model
     * @returns {SwalOptions}
     */
    function getSwalOptions(model) {
        return new SwalOptions(model);
    }

    /**
     *
     * @param value
     * @param format
     * @returns {*}
     */
    function formatUnixTimeStamp(value, format = MomentDateFormat.TIME) {
        return moment.unix(value).utc().format(format);
    }

    /**
     *
     * @param value
     * @returns {*}
     */
    function getDurationToSeconds(value) {
        return moment.duration(value).asSeconds();
    }
}
