import { Dashboard } from '@/modules/ta/dashboard/models/Dashboard';
import { ExportServerConfig } from '@/modules/core/export/models/ExportServerConfig';

export class DashboardExportConfig extends ExportServerConfig {
  /**
   * @type {{}}
   */
  design;

  constructor(model = {}) {
    super(model);

    this.design = model.design || {};
    this.design.page = this.design.page ? new Dashboard(this.design.page) : null;
  }
}
