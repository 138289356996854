'use strict';
import angular from 'angular';
import _ from 'lodash';
import owlCarouselHtmlUrl from '../views/directives/owlcarousel.html';
import EventBus from "../../../../../grok/src/modules/core/app/helpers/EventBus";

angular.module('core.owlcarousel.directives', [])
    .component('carouselAfter', {})
    .directive('owlCarousel', owlCarousel)
    .directive('owlCarouselItem', owlCarouselItem);

/**
 * @ngInject
 */
function owlCarousel() {
    return {
        templateUrl: owlCarouselHtmlUrl,
        bindToController: true,
        scope: {
            options: '<',
            items: '<',
            itemComponent: '<',
        },
        controllerAs: 'vm',
        controller: OwlCarouselController
    };
}

/**
 * @ngInject
 */
function owlCarouselItem() {
    return {
        bindToController: true,
        replace: true,
        scope: {
            item: '<',
            component: '<'
        },
        controllerAs: 'vm',
        controller: OwlCarouselItemController
    };
}

/**
 * @ngInject
 */
function OwlCarouselController(
    $element,
    $timeout
) {
    var vm = this;
    var defaultOptions = {
        autoHeight: true,
        margin: 10,
        items: 3,
        dots: true,
        loop: true,
        nav: false,
        responsiveClass: true
    };

    vm.initOwlCarousel = initOwlCarousel;
    vm.$owlCarouselEl = $element.find('div.owl-carousel');
    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onChanges = $onChanges;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        vm.isReady = false;
        vm.options = _.extend(defaultOptions, vm.options || defaultOptions);
        vm.$owlCarouselEl = $element.find('div.owl-carousel');
        initOwlCarousel();

        // when we're exporting a dashboard we need to notify the export.directives that the carousel exists
        if (window.dashExport) {
            EventBus.signal('export-carousel-loaded', vm.$owlCarouselEl, vm.items);
        }
    }

    function $postLink() {
    }

    function $onChanges() {
        initOwlCarousel();
    }

    function $onDestroy() {
        destroyOwlCarousel();
    }

    function initOwlCarousel() {
        $timeout(function() { // Timeout must be used in order to correctly instantiate owl carousel on angular bound elements
            vm.$owlCarouselEl.owlCarousel(vm.options);
            vm.isReady = true;

            if (vm.items.length) {
                var resizeTimer = setInterval(function() {
                    if (vm.$owlCarouselEl.height() > 30) {
                        clearInterval(resizeTimer);
                    }
                    vm.$owlCarouselEl.trigger('refresh.owl.carousel', [100]);
                }, 300);
            }
          
            if (vm.options.onChange) {
                vm.$owlCarouselEl.on('changed.owl.carousel', function(e) {
                    vm.options.onChanged(e);
                });
            }
        }, 0);
    }

    function destroyOwlCarousel() {
        vm.$owlCarouselEl.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
        vm.$owlCarouselEl.find('.owl-stage-outer').children().unwrap();
    }
}

/**
 * @ngInject
 */
function OwlCarouselItemController(
    $scope,
    $element,
    $compile
) {
    var vm = this;
    vm.$postLink = $postLink;

    function $postLink() {
        var $newScope = $scope.$new(true);
        $newScope.item = vm.item;
        var component = $compile(vm.component)($newScope);
        $element.append(component);
    }
}