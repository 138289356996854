'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import { Locale } from 'coreModules/shared/scripts/constants/language.constants';
import { LocaleMap } from "./constants/language.constants";

angular.module('core.language.services', [])
    .factory('LanguageService', LanguageService);

/**
 * @constructor
 * @ngInject
 */
function LanguageService(
    $q,
    gettextCatalog,
    MomentDateFormat,
    Restangular,
    $http,
    $rootScope,
    AppModule
) {
    return {
        setApplicationLanguage: setApplicationLanguage,
        getDisplayDateFormat: getDisplayDateFormat
    };

    function formatCustomTranslations(data) {
        const result = {};
        if (Array.isArray(data)) {
            data.forEach(translation => {
                result[translation.key] = translation.value;
            })
        }
        return result;
    }

    /**
     *
     * @param settings
     */
    function setApplicationLanguage(settings) {
        var defer = $q.defer();
        var lang = settings.locale || Locale.EN;

        gettextCatalog.setCurrentLanguage(lang);

        window.appLocale = lang;
        window.gettextCatalog = gettextCatalog;


        // Developer i18n options (should always false for production)
        gettextCatalog.debug = false;                   // will identify missing translations for a language
        gettextCatalog.showTranslatedMarkers = false;   // wraps translated text with square brackets
        if (Locale.EN !== lang) {
            if (!$rootScope.util.isModuleAvailable(AppModule.CUSTOM_TRANSLATIONS)) {
                gettextCatalog.loadRemote('/client/analytics/app/i18n/' + lang + '.json').then(function () {
                    _setMomentLocaleStrings(lang);
                    defer.resolve();
                });
            } else {
                const predefinedTranslations = $http.get('/client/analytics/app/i18n/' + lang + '.json');
                const customTranslations = Restangular.one('customtranslations').get({locale: LocaleMap[lang], is_oui: true});
                Promise.all([predefinedTranslations, customTranslations]).then((allResponses) => {
                    const data = {...allResponses[0].data[lang], ...formatCustomTranslations(allResponses[1].plain())};
                    gettextCatalog.setStrings(lang, data);
                    _setMomentLocaleStrings(lang);
                    defer.resolve();
                })
            }

            require ('select2/select2_locale_'+lang+'.js');
            require ('components-jqueryui/ui/i18n/datepicker-'+lang+'.js');
        } else {
            _setMomentLocaleStrings(lang);
            defer.resolve();
        }
        return defer.promise;
    }

    /**
     * @return MomentDateFormat
     */
    function getDisplayDateFormat() {
        return MomentDateFormat.MONTH_DAY_YEAR;
    }

    /**
     *
     * @private
     */
    function _setMomentLocaleStrings(lang) {
        var weekdays = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        var weekdaysMin = _.map(weekdays, function(str) {
            return str.substring(0, 2);
        });
        var weekdaysShort = _.map(weekdays, function(str) {
            return str.substring(0, 3);
        });
        var monthsMin = _.map(months, function(str) {
            return str.substring(0, 2);
        });
        var monthsShort = _.map(months, function(str) {
            return str.substring(0, 3);
        });

        moment.locale(Locale.EN, {
            weekdays: weekdays,
            months: months,
            weekdaysMin: weekdaysMin,
            weekdaysShort: weekdaysShort,
            monthsMin: monthsMin,
            monthsShort: monthsShort
        });

        weekdays = [
            gettextCatalog.getString("Sunday"),
            gettextCatalog.getString("Monday"),
            gettextCatalog.getString("Tuesday"),
            gettextCatalog.getString("Wednesday"),
            gettextCatalog.getString("Thursday"),
            gettextCatalog.getString("Friday"),
            gettextCatalog.getString("Saturday")
        ];
        months = [
            gettextCatalog.getString("January"),
            gettextCatalog.getString("February"),
            gettextCatalog.getString("March"),
            gettextCatalog.getString("April"),
            gettextCatalog.getString("May"),
            gettextCatalog.getString("June"),
            gettextCatalog.getString("July"),
            gettextCatalog.getString("August"),
            gettextCatalog.getString("September"),
            gettextCatalog.getString("October"),
            gettextCatalog.getString("November"),
            gettextCatalog.getString("December")
        ];

        var weekdaysMin = _.map(weekdays, function(str) {
            return str.substring(0, 2);
        });
        var weekdaysShort = _.map(weekdays, function(str) {
            return str.substring(0, 3);
        });
        var monthsMin = _.map(months, function(str) {
            return str.substring(0, 2);
        });
        var monthsShort = _.map(months, function(str) {
            return str.substring(0, 3);
        });

        moment.locale(lang, {
            weekdays: weekdays,
            months: months,
            weekdaysMin: weekdaysMin,
            weekdaysShort: weekdaysShort,
            monthsMin: monthsMin,
            monthsShort: monthsShort
        });
    }
}
