import { Position } from '@/modules/core/charts/chart.constants';

/**
 * @class Legend
 */
export class Legend {
  /**
   * Position for the legend
   * @var {String}
   * @default bottom
   */
  position;

  /**
   * Position for the legend content
   * @var {String}
   * @default center
   */
  contentAlign;

  /**
   * If legend is active
   * @var {Boolean}
   * @default true
   */
  active;

  /**
   * legend maker width
   * @var {Number}
   * @default 10
   */
  markerWidth;

  /**
   * legend marker  height
   * @var {Number}
   * @default 10
   */
  markerHeight;

  /**
   * Use the dafault marker shape instead of the series style
   * @var {Boolean}
   * @default false
   */
  useDefaultMarker;

  /**
   * Template string using variables/keys available in each datum
   * @var {String}
   */
  valueLabelTemplate;

  /**
   * align indicates label position
   *
   * @var {String}
   *
   * @default left
   */
  valueLabelAlign;

  /**
   * textAlign alignment of text within the label itself.
   *
   * @var {String}
   *
   * @default end
   */
  valueLabelTextAlign;

  /**
   * Give opacity to background
   * @var {Number}
   */
  fillOpacity;

  /**
   * Indicates if text lines need to be truncated if they do not fit,
   * using configurable ellipsis string.
   *
   * @var {Boolean}
   */
  labelTruncate;

  /**
   * Maximum allowed width for the element in pixels.
   *
   * @var {Number}
   */
  labelMaxWidth;

  /**
   * Controls vertical alignment of the element.
   *
   * @var {String}
   */
  valign;

  /**
   * Font size to be used for the text.
   * The size can either be numeric, in pixels, or other measurements.
   *
   * @var {Number}
   */
  fontSize;

  /**
   * maxHeight limits the height of the legend.
   * maxHeight can either be absolute, set in numeric pixels, or relative, set in Percent.
   *
   * @var {Number}
   */
  maxHeight;

  /**
   * Scrollable if legend height is more than maxHeight specified.
   *
   * @var {Boolean}
   */
  scrollable;

  constructor(model = {}) {
    const {
      position = Position.BOTTOM,
      contentAlign = Position.CENTER,
      active = true,
      markerWidth = 10,
      markerHeight = 10,
      useDefaultMarker = false,
      valueLabelAlign = Position.LEFT,
      valueLabelTextAlign = Position.END,
      maxHeight = 80,
      scrollable = true,
    } = model;

    this.position = position;
    this.contentAlign = contentAlign;
    this.active = active;
    this.markerWidth = markerWidth;
    this.markerHeight = markerHeight;
    this.useDefaultMarker = useDefaultMarker;
    this.valueLabelAlign = valueLabelAlign;
    this.valueLabelTextAlign = valueLabelTextAlign;
    this.maxHeight = maxHeight;
    this.scrollable = scrollable;
    this.valueLabelTemplate = model.valueLabelTemplate;
    this.fillOpacity = model.fillOpacity;
    this.labelMaxWidth = model.labelMaxWidth;
    this.labelTruncate = model.labelTruncate;
    this.valign = model.valign;
    this.fontSize = model.fontSize;
  }
}
