'use strict';
import angular from 'angular';

angular.module('dashboardfilterpanel.resource', [])

    .factory('DashboardFilterPanelResource', DashboardFilterPanelResource);

/**
 * @ngInject
 */
function DashboardFilterPanelResource(
    Restangular
) {
    var resource = Restangular.all('dash').all('dashboardfilters');

    return {
        saveFilters: saveFilters,
        getList: getList,
        getWidgetFilters: getWidgetFilters
    };


    /**
     *
     * @param model
     */
    function saveFilters(model) {
        return resource.all('savefilters').post(model);
    }

    /**
     *
     * @returns {*}
     */
    function getList() {
        return resource.getList();
    }


    /**
     * Get and widget exposed filters
     *
     * @param pageId
     * @returns {*}
     */
    function getWidgetFilters(pageId) {
        return resource.all(pageId).getList();
    }

}