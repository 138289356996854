'use strict';
import angular from 'angular';
import './managelibrary/exportbuilder.managelibrary.module';
import './exportbuilder.admin.services';
import './exportbuilder.admin.components';

angular.module('exportbuilder.admin', [
    'exportbuilder.admin.components',
    'exportbuilder.admin.services',
    
    'exportbuilder.managelibrary'
]);