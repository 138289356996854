'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.category.services', [])
    .service('CategoryFactory', CategoryFactory)
    .service('CategoryDataViewFactory', CategoryDataViewFactory)
    .service('CategoryMetricFactory', CategoryMetricFactory)
    .service('CategoryDataFactory', CategoryDataFactory);

/**
 * @ngInject
 */
function CategoryFactory(
    Restangular
) {
    var categories = Restangular.service('categories');

    /**
     *
     * @returns {*}
     */
    function getCategories() {
        return categories.getList({sort: 'name', all: true});
    }

    /**
     *
     * @returns {*}
     */
    function getCategoriesForClients(params) {
        return categories.one('clients').get(params);
    }

    return {
        categories: categories,
        getCategories: getCategories,
        getCategoriesForClients: getCategoriesForClients
    }
}

/**
 * @ngInject
 */
function CategoryDataViewFactory(
    Restangular
) {
    /**
     *
     * @param categoryId
     */
    function categoryDataView(categoryId) {
        return Restangular.service('dataviews', Restangular.one('categories', categoryId));
    }

    /**
     *
     * @param categoryIds
     */
    function categoryDataViews(categoryIds) {
        return Restangular.service('services?service_id=' + categoryIds + '&extra=1');
    }

    /**
     *
     * @param categoryId
     * @param dataviewId
     */
    function columns(categoryId, dataviewId) {
        return Restangular.service('columns', Restangular.one('categories', categoryId).one('dataviews', dataviewId));
    }

    return {
        categoryDataView: categoryDataView,
        categoryDataViews: categoryDataViews,
        columns: columns
    }
}

/**
 * @ngInject
 */
function CategoryMetricFactory(
    Restangular
) {
    /**
     *
     * @param categoryId
     */
    function metrics(categoryId) {
        return Restangular.service('metrics', Restangular.one('categories', categoryId));
    }

    function dataTypes() {
        return Restangular.service('categories').one('metrics').one('types');
    }

    return {
        metrics: metrics,
        dataTypes: dataTypes
    }
}

/**
 * @ngInject
 */
function CategoryDataFactory(
    Restangular,
    DataViewName,
    ColumnValueType
) {
    /**
     *
     * @param categoryId
     */
    function data(categoryId) {
        return Restangular.service('data', Restangular.one('categories', categoryId));
    }

    /**
     * Function must be called getColumns and have this order of params since
     * there are many other factories that need to share the same signature
     *
     * @param queryParams
     * @param dataSource
     * @returns {*}
     */
    function getColumns(queryParams, dataSource) {
        var params = _.extend({
            metadata: true,
            sort: 'label'
        }, queryParams);

        return dataSource.data_view ?
            data(dataSource.id).one().getList(dataSource.data_view, params) :
            data(dataSource.id).getList(params);
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @param dataSource
     * @returns {*}
     */
    function getColumnValues(fields, dataSource) {
        return data(dataSource.id)
            .one(dataSource.data_view || DataViewName.CAMPAIGN)
            .one('values')
            .one(ColumnValueType.SET)
            .get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @param dataSource
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        return data(dataSource.id)
            .one(dataSource.data_view || DataViewName.CAMPAIGN)
            .one('values')
            .one(ColumnValueType.DISTINCT)
            .get(_.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @param dataSource
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams, dataSource) {
        return data(dataSource.id)
            .one()
            .getList(dataSource.data_view || DataViewName.CAMPAIGN, _.extend({
                aggregate: true
            }, queryParams));
    }

    /**
     * Return service specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        return dtOptions;
    }

    return {
        data: data,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getDTOptions: getDTOptions,
        getData: getData
    }
}
