import moment from 'moment';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import {
  AdvancedFilterType,
  AdvancedFilterLimits,
} from '@/modules/core/advancedfilter/advancedfilter.constants';
import NumeralService from '@/modules/core/app/services/NumeralService';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { MomentDateFormat } from '@/modules/core/daterange/daterange.constants';

export class AdvancedFilterItem extends BaseModel {
  /**
   * Descriptive title of the filter
   * @var {string}
   */
  title;

  /**
   * Internal field name for the API
   * @var {string}
   */
  field;

  /**
   * @var {AdvancedFilterType}
   */
  type;

  /**
   * @var {string}
   */
  placeholder;

  /**
   * Filter value. For numerical filters it is the lower value
   * @var {object}
   */
  value;

  /**
   * Upper value for numerical filters
   * @var {object}
   */
  value_upper;

  /**
   * For numerical filters, if true the value will be displayed as currency
   * @var {boolean}
   */
  value_is_currency;

  /**
   * For numerical filters, it specifies the decimal count for the filter. Defaults to zero
   * @var {int}
   */
  precision;

  /**
   * For date filters, if true the date is considered to be a lower limit, otherwise an upper limit
   * @var {boolean}
   */
  date_value_is_start;

  date_range_config;

  /**
   * defualt value
   * @var {string}
   */
  default;

  /**
   * @var bool
   */
  ignore_title;

  /**
   * @var {string}
   */
  tooltip;

  /**
   * @var bool
   */
  is_vertical;

  /**
   * @var bool
   */
  is_hidden;

  constructor(model = {}) {
    super(model);

    this.title = model.title;
    this.field = model.field;
    this.type = model.type;
    this.placeholder = model.placeholder ?? model.title;
    this.value = model.value;
    this.value_upper = model.value_upper;
    this.value_is_currency = model.value_is_currency ?? false;
    this.precision = model.precision || 0;
    this.date_value_is_start = model.date_value_is_start;
    this.values = model.values;
    this.customFormSelectoptions = model.customFormSelectoptions;
    this.formatQueryString = model.formatQueryString ?? true;
    this.date_range_config = model.date_range_config;
    this.default = model.default ?? undefined;
    this.ignore_title = model.ignore_title;
    this.tooltip = model.tooltip;
    this.is_vertical = model.is_vertical;
    this.is_hidden = model.is_hidden;
  }

  /**
   * Returns the formatted text for the filter (ex. "CPC >= $1.05)
   * returns string
   */
  getFilterText() {
    // If there is no filter value return ''
    if (!(this.value || this.value_upper)) return '';
    let val = '';
    switch (this.type) {
      case AdvancedFilterType.NUMERIC: {
        // Formatted lower value, if any
        const lower = this.value
          ? `${this.value_is_currency ? '$' : ''}${this.getFormattedNumericalValue(
              this.value,
              this.precision
            )}`
          : undefined;
        // Formatted upper value, if any
        const upper = this.value_upper
          ? `${this.value_is_currency ? '$' : ''}${this.getFormattedNumericalValue(
              this.value_upper,
              this.precision
            )}`
          : undefined;
        // If both lower and upper limits exist, then it's an interval
        if (lower && upper) {
          val = `${lower} TO ${upper}`;
        } else if (lower) {
          val = `>= ${lower}`;
        } else {
          val = `<= ${upper}`;
        }
        break;
      }
      case AdvancedFilterType.MULTI_STRING: {
        const keywords = this.getKeywordsFromValue();
        const maxLength = AdvancedFilterLimits.ADVANCED_FILTERS_MAX_CHIP_CHARS;
        if (!keywords.length) {
          break;
        }
        // When the result text is too long, trim it (ex.: Include keywords: "web", "hosting" & 5 more)
        let visibleKeywordsCount = 0;
        for (let i = 0; i < keywords.length; i++) {
          if (maxLength - val.length - keywords[i].length > 0) {
            val = `${val}${i > 0 ? ', ' : ''}"${keywords[i]}"`;
            visibleKeywordsCount += 1;
          } else {
            break;
          }
        }
        if (visibleKeywordsCount === 0) {
          val = `${keywords[0].substring(0, maxLength)}...`;
          visibleKeywordsCount = 1;
        }
        const hiddenCount = keywords.length - visibleKeywordsCount;
        if (hiddenCount) {
          val = `${val} & ${hiddenCount} other${hiddenCount > 1 ? 's' : ''}`;
        }
        break;
      }
      case AdvancedFilterType.STRING:
        val = `"${this.value}"`;
        break;
      case AdvancedFilterType.DATE:
        // Check if the date value is a lower or upper boundary and set the text accordingly
        val = `${this.date_value_is_start ? '>=' : '<='} ${moment(this.value).format(
          MomentDateFormat.ISO
        )}`;
        break;
      case AdvancedFilterType.ENUM:
        val = this.value.value ?? this.value.name;
        break;
      case AdvancedFilterType.MULTI_SELECT:
        val = this.value.map((value) => value.value ?? value.name);
        break;
      case AdvancedFilterType.TREE_SELECT:
        val = this.value.map((value) => value.value ?? value.name);
        break;
      case AdvancedFilterType.RADIO: {
        const value = this.values.find((item) => item.key === this.value);
        val = value?.value ?? this.val;
        break;
      }
      default:
        val = this.value;
        break;
    }
    return this.ignore_title ? `${val}` : `${this.title} ${val}`;
  }

  /**
   * Returns the filter value in the format required by the API
   * @returns string
   */
  getQueryStringValue() {
    if (!(this.value || this.value_upper)) return '';
    switch (this.type) {
      case AdvancedFilterType.NUMERIC:
        if (this.value && this.value_upper) {
          return `btw|${Number(this.value) - 1},${Number(this.value_upper) + 1}`;
        }
        if (this.value) {
          return `gteq|${Number(this.value)}`;
        }
        return `lteq|${Number(this.value_upper)}`;
      case AdvancedFilterType.MULTI_STRING:
        return this.getKeywordsFromValue().join('\n');
      case AdvancedFilterType.DATE:
        return `${this.date_value_is_start ? 'g' : 'l'}t|${moment(this.value).format(
          MomentDateFormat.ISO
        )}`;
      case AdvancedFilterType.ENUM:
        return this.value.key ?? this.value.id;
      case AdvancedFilterType.MULTI_SELECT:
        return this.value.map((value) => value.key ?? value.id);
      default:
        return this.value;
    }
  }

  /**
   * Returns the cleaned up list of keywords for AdvancedFilterType.MULTI_STRING cases
   * @returns []
   */
  getKeywordsFromValue() {
    return this.value
      ? this.value
          .split(/[\n,]/)
          .filter((x) => x.length)
          .map((x) => x.trim())
      : [];
  }

  /**
   * Checks for the keyword count to be within the limit
   * @returns {boolean}
   */
  isInputValid() {
    return (
      this.type !== AdvancedFilterType.MULTI_STRING ||
      this.getKeywordsFromValue().length <= AdvancedFilterLimits.ADVANCED_FILTERS_MAX_KEYWORDS
    );
  }

  /**
   *
   * @param value
   * @param precision
   * @returns {string}
   */
  getFormattedNumericalValue(value, precision = 0) {
    return NumeralService.formatValue(parseFloat(value), ColumnFormat.FORMAT_DECIMAL, precision);
  }
}
