import DrillDownMixin from '@/modules/core/charts/am5/base/mixins/DrillDownMixin';
import { isEmpty } from 'lodash';

export const BaseChartWidgetPropsMixin = {
  mixins: [DrillDownMixin],
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
    widget: {
      type: Object,
      required: true,
    },
    isExporting: {
      type: Boolean,
      required: true,
    },
    chartPalette: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    isPredefined: {
      type: Boolean,
      default: false,
    },
    hasComparisonData: {
      type: Boolean,
      default: false,
    },
    dashboardId: {
      type: String,
      default: '',
    },
    dateRange: {
      type: Object,
      default: () => ({}),
    },
    isDemoMode: {
      type: Boolean,
    },
    elementMetadata: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartConfig: null,
    };
  },
  computed: {
    canShowChart() {
      return this.chartConfig && !isEmpty(this.data);
    },
    widgetType() {
      return this.widget.type;
    },
  },
  watch: {
    data: {
      handler() {
        this.init();
      },
      deep: true,
    },
    widget: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
};
