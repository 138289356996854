import { ChartDrawOption } from '@/modules/ta/widget/models/drawoptions/ChartDrawOption';
import { isNil } from 'lodash';
import {
  PlotType,
  WidgetBackgroundGradientColorOptions,
} from '@/modules/ta/widget/widget.constants';

export class FunnelChartDrawOption extends ChartDrawOption {
  /**
   * @var {string}
   */
  plot_type;

  /**
   * @var {boolean}
   */
  show_labels;

  /**
   * @var {boolean}
   */
  show_label_names;

  /**
   * @var {boolean}
   */
  show_label_percent;

  /**
   * @var {boolean}
   */
  is_normalized;

  /**
   * @var {boolean}
   */
  has_tooltip;

  /**
   * @var {boolean}
   */
  other_percent;

  /**
   * @var {boolean}
   */
  label_percent;

  /**
   * @var {boolean}
   */
  inner_radius;

  /**
   * @var {boolean}
   */
  neck_height;

  /**
   * @var {boolean}
   */
  neck_width;

  /**
   * @var {boolean}
   */
  fill_type;

  /**
   * @var {string}
   */
  gradient_color;

  /**
   * @var {boolean}
   */
  is_smoothed_line;

  /**
   * @var {integer}
   */
  grain_density;

  /**
   * @var {boolean}
   */
  invert_metrics;

  /**
   * @var {boolean}
   */
  order_metrics;

  /**
   * @var {string}
   */
  v2_pictorial_options;

  /**
   * @var {string}
   */
  fontColorPicker;

  /**
   * @var {string}
   */
  showColumnShadows;

  constructor(model = {}) {
    super(model);

    this.plot_type = isNil(model.plot_type) ? PlotType.CLASSIC : model.plot_type;
    this.gradient_color = isNil(model.gradient_color) ? null : model.gradient_color;
    this.show_labels = isNil(model.show_labels) ? true : model.show_labels;
    this.show_label_names = isNil(model.show_label_names) ? true : model.show_label_names;
    this.show_label_values = isNil(model.show_label_values) ? true : model.show_label_values;
    this.show_label_percent =
      model.show_label_percent !== undefined ? model.show_label_percent : true;
    this.is_normalized = model.is_normalized || false;
    this.other_percent = model.other_percent ?? 0;
    this.label_percent = model.label_percent ?? 0;
    this.inner_radius = model.inner_radius ?? 0;
    this.depth = model.depth ?? 0;
    this.angle = model.angle ?? 0;
    this.has_tooltip = model.has_tooltip ?? true;
    this.neck_height = model.neck_height;
    this.neck_width = model.neck_width;
    this.is_rotated = model.is_rotated || false;
    this.fill_type = model.fill_type || WidgetBackgroundGradientColorOptions.SOLID;
    this.is_smoothed_line = model.is_smoothed_line || false;
    this.grain_density = model.grain_density ?? 0;
    this.invert_metrics = model.invert_metrics || false;
    this.order_metrics = model.order_metrics || false;
    this.v2_pictorial_options = model.v2_pictorial_options;
    this.fontColorPicker = model.font_color_picker;
    this.showColumnShadows = model.show_column_shadows;
  }
}
