'use strict';
import angular from 'angular';

angular.module('billing.services', [])

    .service('BillingService', BillingService);

//
// Billing service
//
BillingService.$inject = ['$rootScope', '$http'];
function BillingService($rs, $http) {
    return {
        myFunction: function() {

        }
    }
}