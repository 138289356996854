'use strict';
import angular from 'angular';

angular.module('marketplace.services', [])

    .service('MarketplaceService', MarketplaceService);

//
// Marketplace service
//
MarketplaceService.$inject = ['$rootScope', '$http'];
function MarketplaceService($rs, $http) {

    function getDataViews(id) {
        return $http.get($rs.util.apiUrl('services/' + id + '/data?metadata=true'));
    }

    function getService(id) {
        return $http.get($rs.util.apiUrl('services/' + id));
    }

    function getServiceInfo(id) {
        return $http.get($rs.util.apiUrl('services/info/' + id ));
    }

    return {
        getDataViews: getDataViews,
        getService: getService,
        getServiceInfo: getServiceInfo
    }
}