'use strict';
import angular from 'angular';
import _ from 'lodash';
import detailsModalHtmlUrl from './details.modal.html';
import detailsModalItemHtmlUrl from './details.modal.item.html';
import detailsModalGroupHtmlUrl from './details.modal.group.html';


angular.module('design.details.components', [])

    .component('detailsHeader', {})
    .component('detailsBodyBefore', {})
    .component('detailsBodyAfter', {
        controllerAs: 'vm',
        controller: DetailsBodyAfterController
    })
    .component('detailsSectionBefore', {})
    .component('detailsSectionAfter', {})
    .component('detailsGroupAfter', {
        controllerAs: 'vm',
        bindings: {
            group: '='
        },
        controller: DetailsGroupAfterController
    })
    .component('detailsFooter', {})
    .component('customEditControl', {})
    .component('detailsModal', {
        templateUrl: detailsModalHtmlUrl,
        controllerAs: 'vm',
        transclude: {
            header: '?detailsHeader',
            sectionBefore: '?detailsSectionBefore',
            bodyBefore: '?detailsBodyBefore',
            bodyAfter: '?detailsBodyAfter',
            sectionAfter: '?detailsSectionAfter',
            footer: '?detailsFooter'
        },
        controller: DetailsModalController
    })
    .component('detailsModalGroup', {
        templateUrl: detailsModalGroupHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            columns: '<',
            model: '=',
            title: '=',
            active: '='
        },
        transclude: {
            groupAfter: '?detailsGroupAfter'
        },
        controller: DetailsModalGroupController
    })
    .component('detailsModalItem', {
        templateUrl: detailsModalItemHtmlUrl,
        controllerAs: 'vm',
        replace: true,
        bindings: {
            column: '<',
            model: '='
        },
        transclude: {
            customEditControlTitle: '?customEditControlTitle',
            customEditControl: '?customEditControl'
        },
        controller: DetailsModalItemController
    });
 
/**
 * @ngInject
 */
function DetailsBodyAfterController(
    $scope,
    $element,
    $compile,
    DetailsModalFactory,
    DetailsModalFactoryDelegate
) {
    var vm = this;
    vm.$postLink = $postLink;

    function $postLink() {
        // Dynamically injects the edit details component based on data source type
        var dataSourceType = DetailsModalFactory.getDataSourceType();
        var component = $compile(DetailsModalFactoryDelegate.getComponent(dataSourceType))($scope);
        $element.append(component);
    }
}

/**
 * @ngInject
 */
function DetailsGroupAfterController(
    $scope,
    $element,
    $compile,
    DetailsModalFactory,
    DetailsModalFactoryDelegate
) {
    var vm = this;
    vm.$postLink = $postLink;

    function $postLink() {
        // Dynamically injects the edit details component based on data source type
        const dataSourceType = DetailsModalFactory.getDataSourceType();
        const group = this.group.columns[0].edit_detail_group_id;
        const component = $compile(DetailsModalFactoryDelegate.getGroupAfterComponent(dataSourceType, group))($scope);
        $element.append(component);
    }
}

function DetailsModalController(
    PubSub,
    $timeout,
    EditControlType,
    $DetailsModalFactoryEvents,
    DetailsModalFactory
) {
    var vm = this;
    vm.model = null;
    vm.metadata = null;
    vm.viewableColumns = null;
    vm.EditControlType = EditControlType;
    vm.isLoading = isLoading;
    vm.saveDetails = saveDetails;
    vm.onCancel = onCancel;
    vm.showGetRowButtons = showGetRowButtons;
    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        vm.display = DetailsModalFactory.getDisplay();
        vm.modalSize = DetailsModalFactory.getModalSize();
        vm.saveActive = DetailsModalFactory.getIsSaveButtonActive();

        _loadModel();
        _registerEvents();
    }

    function _loadModel() {
        DetailsModalFactory.loadModel().then(function (response) {
            vm.model = response.model;
            vm.metadata = response.metadata;
            vm.is_grouped = !_.isEmpty(response.groupings);
            if (vm.is_grouped) {
                vm.detailColumnGroups = _.map(_.groupBy(DetailsModalFactory.getDetailColumns(vm.metadata), 'edit_detail_group_id'),
                    group => {
                        const metadata_group = _.find(response.groupings, {id: group[0].edit_detail_group_id});
                        return {
                            columns: group,
                            title: metadata_group.title,
                            openOnInit: metadata_group.open_on_init
                        };
                    });
            } else {
                vm.detailColumns = DetailsModalFactory.getDetailColumns(vm.metadata);
            }

            if (!vm.isNew) {
                vm.currentIndex = response.currentIndex;

                vm.hasPrevRow = !_.isEmpty(response.prevRow)
                    || response.hasPreviousPage;

                vm.hasNextRow = !_.isEmpty(response.nextRow)
                    || response.hasNextPage;

                vm.getRow = function(index) {
                    DetailsModalFactory.openRow(index, response.dataTable.table, response);
                };
            }

            $timeout(function() {
                _setEnterKeyBinding();
            }, 100, false);
        });
    }


    function $postLink() {
    }

    function $onDestroy() {
        _unsetEnterKeyBinding();
        _unregisterEvents()
    }

    function isLoading() {
        return (_.isEmpty(vm.model) && _.isEmpty(vm.metadata))
            || DetailsModalFactory.getIsLoading();
    }

    function saveDetails() {
        if (!vm.saveActive) {
            return;
        }

        PubSub.emit($DetailsModalFactoryEvents.PRE_SAVE, DetailsModalFactory.getModel());
        return DetailsModalFactory.saveDetails()
            .then(function (json) {
                if (json) {
                    if (!json.error) {
                        PubSub.emit($DetailsModalFactoryEvents.POST_SAVE, json);
                    } else {
                        PubSub.emit($DetailsModalFactoryEvents.POST_ERROR, json.error);
                    }
                }
            });
    }

    function onCancel() {
        PubSub.emit($DetailsModalFactoryEvents.CLOSED);
        return DetailsModalFactory.unsetDetails();
    }

    function showGetRowButtons() {
        return !(DetailsModalFactory.getIsNewState()
            || DetailsModalFactory.getHideRowButtons());
    }

    var _enterFnHandler = function (event) {
        if (event.which === 13) { //Enter is pressed
            event.preventDefault();
            saveDetails();
        }
    };

    function _setEnterKeyBinding() {
        _getValidInputs().bind('keydown keypress', _enterFnHandler);
    }

    function _unsetEnterKeyBinding() {
        _getValidInputs().unbind('keydown keypress', _enterFnHandler);
    }

    function _getValidInputs() {
        return DetailsModalFactory.$getElement()
            .find('div.form-field')
            .find('input.form-control, select.form-control');
    }

    function _renderSave(event) {
        vm.saveActive = event.active;
        vm.saveInactiveMessage = event.message;
    }

    function _registerEvents() {
        PubSub.on($DetailsModalFactoryEvents.UPDATE_DATA, _loadModel);
        PubSub.on($DetailsModalFactoryEvents.RENDER_SAVE, _renderSave);
    }

    function _unregisterEvents() {
        PubSub.off($DetailsModalFactoryEvents.UPDATE_DATA, _loadModel);
        PubSub.off($DetailsModalFactoryEvents.RENDER_SAVE, _renderSave);
    }
}

/**
 * @ngInject
 */
function DetailsModalItemController(
    $transclude,
    AppFactory,
    DetailsModalFactory,
    EditControlType
) {
    var vm = this;
    var columnUtil = AppFactory.util.column;

    vm.EditControlType = EditControlType;
    vm.isNewDetails = DetailsModalFactory.getIsNewState();
    vm.canIncludeInput = canIncludeInput;
    vm.transcludeIncluded = transcludeIncluded;
    vm.isTextInput = isTextInput;
    vm.isDropDownInput = isDropDownInput;
    if (vm.isDropDownInput(vm.column)) {
        vm.selectOptions = getSelectOptions();
        vm.selectedValues = getSelectedValues();
        vm.selectableValues = [];
        setSelectableValues();
    }
    vm.getTextInputType = getTextInputType;
    vm.getReadOnlyTextValue = getReadOnlyTextValue;

    /**
     * @param column
     */
    function canIncludeInput(column) {
        return !column.is_read_only
            && !column.is_custom_edit_control;
    }

    /**
     * Helper function to know if transcluded slot was given
     * @param {String} slotName
     */
    function transcludeIncluded(slotName) {
        return $transclude.isSlotFilled(slotName);
    }

    /**
     * @param editControlType
     */
    function isTextInput(editControlType) {
        return [
            EditControlType.TYPE_TEXT,
            EditControlType.TYPE_EMAIL,
            EditControlType.TYPE_NUMBER,
            EditControlType.TYPE_PHONE_NUMBER
        ].contains(editControlType);
    }

    /**
     * @param column
     * @returns {string}
     */
    function getTextInputType(column) {
        switch (column.edit_control_type) {
            case EditControlType.TYPE_NUMBER:
                return 'number';
                break;

            case EditControlType.TYPE_PHONE_NUMBER:
                return 'tel';
                break;
        }

        return 'text';
    }

    /**
     * @param column
     */
    function isDropDownInput(column) {
        return column.edit_control_type === EditControlType.TYPE_SINGLE_SELECT
            || column.edit_control_type === EditControlType.TYPE_MULTI_SELECT;
    }

    /**
     * @return {{}}
     */
    function getSelectOptions() {
        return DetailsModalFactory.getDefaultSelectOptions(vm.column, vm.model);
    }

    /**
     * @returns {any[] | boolean[]}
     */
    function setSelectableValues() {
        if (_.isEmpty(vm.column.values)) {
            DetailsModalFactory.setAjaxSelectableValues(vm.column).then(function(values) {
                vm.selectableValues = values;
            });
        } else {
            vm.selectableValues = DetailsModalFactory.setSelectableValues(vm.column.values);
        }
    }

    /**
     * @returns {*}
     */
    function getSelectedValues() {
        return DetailsModalFactory.getSelectedValues(vm.column, vm.model);
    }

    /**
     * @param column
     * @returns {string}
     */
    function getReadOnlyTextValue(column) {
        var value = vm.model[column.field];

        if (columnUtil.isDate(column.format)) {
            value = vm.model['formatted_' + column.field];
        }

        if (column.edit_control_type === EditControlType.TYPE_CHECKBOX) {
            value = value === false ? 'No' : 'Yes';
        }

        return _.isEmpty(value) ? '-' : value;
    }
}

function DetailsModalGroupController() {
    var vm = this;
}
