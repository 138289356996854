'use strict';
import angular from 'angular';

angular.module('campaign.services', [])

    .service('CampaignService', CampaignService);

//
// Campaign service
//
CampaignService.$inject = ['$rootScope', '$http'];
function CampaignService($rs, $http) {
    return {
        myFunction: function() {

        }
    }
}