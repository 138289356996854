import { p50, percent } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { getShadowSettings } from '@/modules/core/charts/am5/charts.helper';

export function getChartConfig({
  isRotated,
  isStacked,
  isFullStacked,
  isNormalizedData,
  showColumnShadows,
  roundedCorners,
  isLayered,
  isZeroScaled,
  isLogScaled,
  isRadialHistogram,
  isRadialBar,
  radialInnerRadius,
  isExporting,
  legend,
  hasValueScrollbar,
}) {
  let labelProps = {
    centerX: percent(50),
    centerY: percent(100),
    text: Constant.VALUE_Y,
    fontSize: 12,
  };
  let bulletProps = {
    locationX: 0.5,
    locationY: 1,
  };

  if (isRotated) {
    delete labelProps.centerX;
    labelProps.centerY = p50;
    labelProps.text = Constant.VALUE_X;

    bulletProps.locationX = 1;
    bulletProps.locationY = 0.5;
  }

  if (isStacked || isFullStacked) {
    labelProps = { ...labelProps, centerX: p50, centerY: p50 };
    bulletProps.locationX = 0.5;
    bulletProps.locationY = 0.5;
  }

  if (isRadialHistogram) {
    labelProps.centerX = p50;
    labelProps.centerY = percent(70);
    bulletProps.locationY = 1;
  }

  if (isRadialBar) {
    labelProps = {
      centerX: p50,
      centerY: p50,
    };
    bulletProps = {
      locationX: 0.5,
      locationY: 0.5,
    };
  }

  let axisOptions = {};
  if (isFullStacked) {
    axisOptions = {
      max: 100,
      strictMinMax: true,
      calculateTotals: true,
    };
  }

  if (isZeroScaled) {
    axisOptions = {
      ...axisOptions,
      min: 0,
    };
  }

  if (isLogScaled && !isFullStacked && !isNormalizedData) {
    axisOptions = {
      logarithmic: true,
      treatZeroAs: 0.00001,
      min: 0.1,
    };
  }

  if (isLogScaled && isRadialHistogram) {
    axisOptions.extraMax = 50;
  }

  const seriesOptions = {};

  if (isStacked || isFullStacked) {
    if (!isNormalizedData) {
      seriesOptions.stacked = true;
    }

    if (isFullStacked) {
      seriesOptions[isRotated ? Constant.VALUE_X_SHOW : Constant.VALUE_Y_SHOW] = isRotated
        ? Constant.VALUE_X_TOTAL_PERCENT
        : Constant.VALUE_Y_TOTAL_PERCENT;
    }
  }

  if (isRadialBar) {
    seriesOptions.stacked = true;
  }

  let shadowProps = {};
  if (showColumnShadows) {
    shadowProps = getShadowSettings(isExporting);
  }
  let cornerProps = {};
  if (isRadialHistogram) {
    cornerProps = {
      cornerRadius: roundedCorners,
    };
  } else if (!isStacked && !isFullStacked) {
    const cornerPropName = isRotated ? Constant.CORNER_RADIUS_BR : Constant.CORNER_RADIUS_TL;
    cornerProps = {
      cornerRadiusTR: roundedCorners,
      [cornerPropName]: roundedCorners,
    };
  }

  let layeredProps = {};
  if (isLayered) {
    layeredProps = {
      clustered: false,
    };
  }

  let radarProps = {};

  const isReduceRadius = (legend && legend.value) || hasValueScrollbar;

  if (isRadialHistogram) {
    radarProps = {
      startAngle: -84,
      endAngle: 264,
      innerRadius: percent(radialInnerRadius),
    };
    if (isReduceRadius) {
      radarProps.radius = percent(60);
    }
  } else if (isRadialBar) {
    radarProps = {
      innerRadius: percent(radialInnerRadius),
    };
    if (isReduceRadius) {
      radarProps.radius = percent(65);
    }
  }

  return {
    labelProps,
    bulletProps,
    axisOptions,
    seriesOptions,
    shadowProps,
    cornerProps,
    layeredProps,
    radarProps,
  };
}
