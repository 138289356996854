export default class Enum {
  static getValues() {
    return Object.values(this).filter((value) => !value.startsWith('_'));
  }

  static getKeys() {
    return Object.keys(this).filter((key) => !key.startsWith('_'));
  }

  static getKeyValues() {
    return Object.entries(this).map(([key, value]) => ({ key, value }));
  }
}
