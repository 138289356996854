import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { p100, p50, color } from '@amcharts/amcharts5';
import { AxisRendererX, AxisRendererY } from '@amcharts/amcharts5/xy';
import { useAxesLabels } from '@/modules/core/charts/am5/base/composables/axis/useAxesLabels';
import { useAxes } from '@/modules/core/charts/am5/base/composables/axis/useAxes';
import { useToolTips } from '@/modules/core/charts/am5/pie/composables/useTooltips';

export function useXYAxes(context) {
  const { root, chart, config, isDarkTheme } = context();
  const { createAxesLabels } = useAxesLabels(context);
  const { getAxis, configureAxis, configureRenderer } = useAxes(context);
  const { assignThemeColor } = useToolTips(context);
  const MAX_AXIS_LABEL_WIDTH = 120;

  function getXAxes() {
    return chart.value.xAxes;
  }

  function getYAxes() {
    return chart.value.yAxes;
  }

  function getXAxis(index = 0) {
    return getXAxes().getIndex(index);
  }

  function getYAxis(index = 0) {
    return getYAxes().getIndex(index);
  }

  function getSeriesAxisPropPrefix(dataItemFormat) {
    switch (dataItemFormat) {
      case ColumnFormat.FORMAT_DATE:
      case ColumnFormat.FORMAT_DATETIME:
        return Constant.VALUE;
      case ColumnFormat.FORMAT_STRING:
      case ColumnFormat.FORMAT_CALLBACK:
      case ColumnFormat.FORMAT_URL:
        return Constant.CATEGORY;
      case ColumnFormat.FORMAT_INTEGER:
      case ColumnFormat.FORMAT_CURRENCY:
      case ColumnFormat.FORMAT_DECIMAL:
      case ColumnFormat.FORMAT_PERCENT:
        return Constant.VALUE;
      default:
        return Constant.CATEGORY;
    }
  }

  function getSeriesAxesProps({ axisIndex, categoryAxisIndex, category, value }) {
    const { isRotated, isRadialBar } = config.value;
    const xAxis = getXAxis(isRotated || isRadialBar ? axisIndex : categoryAxisIndex);
    const yAxis = getYAxis(isRotated || isRadialBar ? categoryAxisIndex : axisIndex);

    const { dataItemFormat: xAxisDataType } = xAxis.get(Constant.USER_DATA);
    const { dataItemFormat: yAxisDataType } = yAxis.get(Constant.USER_DATA);

    const xAxisFieldName = isRotated || isRadialBar ? value : category;
    const yAxisFieldName = isRotated || isRadialBar ? category : value;

    return {
      // eslint-disable-next-line tap/no-raw-text-js
      [`${getSeriesAxisPropPrefix(xAxisDataType)}XField`]: xAxisFieldName,
      // eslint-disable-next-line tap/no-raw-text-js
      [`${getSeriesAxisPropPrefix(yAxisDataType)}YField`]: yAxisFieldName,
    };
  }

  function enableAutoRotateAxesLabels(axis, renderer) {
    axis.onPrivate(Constant.CELL_WIDTH, () => {
      let shouldLabelRotated = false;
      const startIndex = axis.getPrivate(Constant.START_INDEX);
      const endIndex = axis.getPrivate(Constant.END_INDEX);
      const totalCellsInViewPort = endIndex - startIndex;
      const columnWidth = axis.width() / totalCellsInViewPort;
      if (columnWidth < MAX_AXIS_LABEL_WIDTH) {
        shouldLabelRotated = true;
      }
      renderer.labels.template.setAll({
        rotation: shouldLabelRotated ? -45 : 0,
        centerX: shouldLabelRotated ? p100 : p50,
      });
    });
  }

  function createAxes() {
    const { categoryAxis, valueAxis } = config.value;

    createXAxes(config.value.isRotated ? [...valueAxis] : [...categoryAxis]);
    createYAxes(config.value.isRotated ? [...categoryAxis] : [...valueAxis]);

    createAxesLabels();
  }

  function createXAxes(axes) {
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);
    axes.forEach((axisProps) => {
      const axis = createXAxis(axisProps);
      // Give fontcolor for value axis labels
      axis.get(Constant.RENDERER).labels.template.set(Constant.FILL, color(fontColorPicker));
      chart.value.xAxes.push(axis);
    });
  }

  function createYAxes(axes) {
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);
    axes.forEach((axisProps) => {
      const axis = createYAxis(axisProps);
      // Give fontcolor for value axis labels
      axis.get(Constant.RENDERER).labels.template.set(Constant.FILL, color(fontColorPicker));
      chart.value.yAxes.push(axis);
    });
  }

  function createXAxis(axisProps) {
    const renderer = AxisRendererX.new(
      root.value,
      omitNils({
        cellStartLocation: axisProps.cellStartLocation,
        cellEndLocation: axisProps.cellEndLocation,
        minGridDistance: 40,
        opposite: axisProps.opposite,
        minorGridEnabled: true,
      })
    );
    const axis = getAxis(axisProps, renderer);
    axis.set(Constant.USER_DATA, axisProps);
    configureRenderer(renderer, axisProps);
    enableAutoRotateAxesLabels(axis, renderer);
    configureAxis(axis, axisProps);
    fixAxisLabelOverlappingIssues(axis);
    return axis;
  }

  function fixAxisLabelOverlappingIssues(axis) {
    const renderer = axis.get(Constant.RENDERER);
    renderer.labels.template.adapters.add(Constant.TEXT, (text, target) => {
      if (target.dataItem) {
        const fontSize = renderer.labels.template.get(Constant.FONT_SIZE);
        const requiredCellWidth = fontSize * axis.get(Constant.USER_DATA).axisLabelLineHeight;
        const startIndex = axis.getPrivate(Constant.START_INDEX);
        const endIndex = axis.getPrivate(Constant.END_INDEX);
        const totalCellsInViewPort = endIndex - startIndex;
        const columnWidth = axis.width() / totalCellsInViewPort;
        if (requiredCellWidth > columnWidth) {
          if (target.dataItem.dataContext) {
            return text;
          }
          return '';
        }
        return text;
      }
      return '';
    });
  }

  function createYAxis(axisProps) {
    const renderer = AxisRendererY.new(
      root.value,
      omitNils({
        cellStartLocation: axisProps.cellStartLocation,
        cellEndLocation: axisProps.cellEndLocation,
        minGridDistance: 30,
        opposite: axisProps.opposite,
        inversed: config.value.isRotated,
        minorGridEnabled: true,
      })
    );
    const axis = getAxis(axisProps, renderer);
    axis.set(Constant.USER_DATA, axisProps);
    configureRenderer(renderer, axisProps);
    configureAxis(axis, axisProps);
    fixAxisLabelOverlappingIssues(axis);
    return axis;
  }

  return {
    getXAxes,
    getYAxes,
    getXAxis,
    getYAxis,
    configureAxis,
    getAxis,
    getSeriesAxesProps,
    enableAutoRotateAxesLabels,
    createAxes,
    createXAxes,
    createYAxes,
    createXAxis,
    createYAxis,
  };
}
