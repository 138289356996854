import { flatten } from 'lodash';

/**
 * Returns related index on xAxes when chart clicked
 * @param event
 * @returns {Number}
 */
export const xAxesIndexOnChartClicked = (event) =>
  event.target.xAxes.getIndex(0)?.positionToIndex(event.target.cursor.xPosition);

/**
 * Returns related index on yAxes when chart clicked
 * @param event
 * @returns {Number}
 */
export const yAxesIndexOnChartClicked = (event) =>
  event.target.yAxes.getIndex(0)?.positionToIndex(event.target.cursor.yPosition);

/**
 * Returns related category index on xy chart when chart clicked
 * @param event
 * @returns {Number}
 */
export const categoryAxesIndexOnChartClicked = (event, isRotated) =>
  isRotated ? yAxesIndexOnChartClicked(event) : xAxesIndexOnChartClicked(event);

/**
 * Returns tag name at triggered event target
 * @param event
 * @returns {String}
 */
export const targetTagName = ({ event }) => event.target.tagName;

/**
 * Return text content of triggered event target
 * @param event
 * @returns {String}
 */
export const targetTextContent = ({ event }) => event.target.textContent;

/**
 * Return related datum/dataContext for an event
 * @param event
 * @returns {*}
 */
export const dataContext = (event) => event.target.dataItem.dataContext;

/**
 * Below code helps to achieves out like https://www.amcharts.com/demos/pie-chart-broken-slices/
 * Will explode a slice into multiple slices if more data is assigned to `subs` property in a given datum(slice) object
 * @param event
 * @param chart
 */
export const explodeSliceOnSliceClick = (event, chart) => {
  const slice = dataContext(event);
  const selectedIndex = slice?.index;
  const currentChartData = chart.data;
  const chartData = currentChartData.map((currentDatum) =>
    currentDatum.index === selectedIndex ? currentDatum.subs : currentDatum
  );
  chart.data = flatten(chartData, true);
};
