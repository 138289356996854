import { DateAxis } from '@amcharts/amcharts5/xy';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function useDateAxis(context) {
  const { root, config } = context();

  function createDateAxis(props, renderer) {
    const { isSparkLine } = config.value;
    const axis = DateAxis.new(root.value, {
      baseInterval: { timeUnit: Constant.DAY, count: 1 },
      gridIntervals: [
        { timeUnit: Constant.DAY, count: 1 },
        { timeUnit: Constant.MONTH, count: 1 },
      ],
      renderer,
    });
    axis.data.setAll(config.value.data);
    if (isSparkLine) {
      renderer.grid.template.set(Constant.VISIBLE, false);
      renderer.labels.template.set(Constant.VISIBLE, false);
      axis.cursorTooltipEnabled = false;
    }
    return axis;
  }

  return {
    createDateAxis,
  };
}
