'use strict';
import angular from 'angular';
import _ from 'lodash';
import widgetBigNumberHtmlUrl from './widget.bignumber.html';
import wrappedBigNumberHtmlUrl from './wrapped.bignumber.html';
import classicWidgetBigNumberHtmlUrl from './classic.bignumber.html';
import multimetricWidgetBigNumberHtmlUrl from './multimetric.bignumber.html';
import comparisonWidgetBigNumberHtmlUrl from './comparison.bignumber.html';
import sparklineWidgetBigNumberHtmlUrl from './sparkline.bignumber.html';
import {
    WidgetBuilderConstants
} from 'coreModules/design/widget/builder/widget.builder.constants';

angular.module('widget.bignumber.components', [])
    .component('bigNumberWidget', {
        templateUrl: widgetBigNumberHtmlUrl,
        controller: BigNumberWidgetParentController,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            state: '=',
            widgetIsPredefined: '<'
        }
    })
    .component('wrappedBigNumberWidget', {
        templateUrl: wrappedBigNumberHtmlUrl,
        controller: BigNumberWidgetController,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            state: '=',
            widgetIsPredefined: '<'
        }
    })
    .component('classicBigNumberWidget', {
        templateUrl: classicWidgetBigNumberHtmlUrl ,
        controller: BigNumberWidgetController,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            state: '=',
            widgetIsPredefined: '<'
        }
    })
    .component('multimetricBigNumberWidget', {
        templateUrl: multimetricWidgetBigNumberHtmlUrl,
        controller: BigNumberWidgetController,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            state: '=',
            widgetIsPredefined: '<'
        }
    })
    .component('comparisonBigNumberWidget', {
        templateUrl: comparisonWidgetBigNumberHtmlUrl,
        controller: BigNumberWidgetController,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            state: '=',
            widgetIsPredefined: '<'
        }
    })
    .component('sparklineBigNumberWidget', {
        templateUrl: sparklineWidgetBigNumberHtmlUrl,
        controller: BigNumberWidgetController,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            state: '=',
            widgetIsPredefined: '<'
        }
    });

/**
 *
 * @param DrawOption
 * @param BigNumberUIService
 * @param ReportStudioTemplateDataService
 * @param DashboardContextService
 * @param WidgetBuilderService
 * @param WidgetBuilderConstants
 * @param ChartPlotType
 * @constructor
 */
function BigNumberWidgetParentController(
    DrawOption,
    BigNumberUIService,
    ReportStudioTemplateDataService,
    DashboardContextService,
    WidgetBuilderService,
    WidgetBuilderConstants,
    ChartPlotType

) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.getFontType = getFontType;
    /**
     * Lifecycle method for initial loading of component.
     *
     * @returns {none}
     */
    function $onInit() {
        vm.DrawOption = DrawOption;
        setDrawOptions();
    }

    function setDrawOptions() {
        const drawOptions = BigNumberUIService.getDefaultDrawOptions(vm.widget);
        vm.widget.metadata.draw_options = _.assign(drawOptions, vm.widget.metadata.draw_options);
        if (!vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE]) {
            vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE] = (vm.widget.id === WidgetBuilderConstants.NEW_WIDGET_ID
            || WidgetBuilderService.isCurrentWidgetInBuildMode(vm.widget.id)) ? ChartPlotType.DEFAULT : ChartPlotType.CLASSIC;
        }
    }
    /**
     * @returns {boolean|*}
     */
    function getFontType() {
        if (ReportStudioTemplateDataService.getIsActive()) {
            return {'font-family':DashboardContextService.resolveFontType()};
        }
        return {};
    }
}

/**
 * @ngInject
 */
function BigNumberWidgetController(
    $scope,
    $q,
    UIColor,
    $WidgetEvents,
    LoadingState,
    ColumnFormat,
    DataSourceType,
    WidgetType,
    DrawOption,
    AppFactory,
    WidgetFactory,
    WidgetCreateTypeFactory,
    DashboardContextService,
    DrawOptionFactory,
    BigNumberFactory,
    BigNumberUIService,
    TimeGrouping,
    DesignFactory,
    ReportStudioTemplateDataService,
    WrapFormat,
    CenterNumbers,
    BackgroundColor,
    GradientTypes,
    ChartPlotType,
    ComparisonType,
    WidgetBuilderService
) {
    var vm = this;
    var _textColor = '#ffffff';

    var requestId = 0;
    vm.$onInit = $onInit;
    vm.getEmptyDataMessage = getEmptyDataMessage;
    vm.getNumberStyle = getNumberStyle;
    vm.getNumberValueStyle = getNumberValueStyle;
    vm.getComparisonNumberValueStyle = getComparisonNumberValueStyle;
    vm.getLabelStyle = getLabelStyle;
    vm.showMetricName = showMetricName;
    vm.deltaContainerStyles = deltaContainerStyles;
    vm.isExportingReportStudio = isExportingReportStudio;
    vm.getBackgroundColorForType = getBackoundColorForType;
    vm.resolveDrawOptionValue = resolveDrawOptionValue;
    vm.getFontType = getFontType;
    vm.getStyles = getStyles;

    /**
     * Lifecycle method for initial loading of component.
     *
     * @returns {none}
     */
    function $onInit() {
        vm.bigNumbers = [];
        vm.DrawOption = DrawOption;
        _registerEvents();
        setDrawOptions();
        buildBigNumber();
    }

    /**
     * setting width for default plot type so that widgets align with columns
     * @returns {{margin: string}}
     */
    function getStyles() {
        const { draw_options: {plot_type, spacing, spacing_toggle, circle_number, wrap_number}, data_columns } = vm.widget.metadata;
        if (data_columns && data_columns.selected && data_columns.selected.length <= 1) {
            return '';
        }
        const types = [ChartPlotType.COMPARISON, ChartPlotType.SPARKLINE];
        let styles = {};
        styles['margin'] = `${spacing}px`;
        if (spacing_toggle && types.includes(plot_type)) {
            return styles;
        }

        if (spacing_toggle && plot_type === ChartPlotType.DEFAULT) {
            if (circle_number === 'square') {
                return styles;
            }
            styles = {};
            styles['width'] = `${spacing}%`;
            if (!wrap_number && spacing < 15) {
                styles['min-width'] = "80px";
            }
            return styles;
        }
        
        return '';
    }

    /**
     * Set default draw options
     */
    function setDrawOptions() {
        const drawOptions = BigNumberUIService.getDefaultDrawOptions(vm.widget);
        vm.widget.metadata.draw_options = _.assign(drawOptions, vm.widget.metadata.draw_options);
        vm.widget.metadata.min_font_size = 14;
        vm.widget.metadata.max_font_size = 150;
    }

    function getComparisonNumberValueStyle() {
        var style = {};
        if (vm.widget.metadata.draw_options[DrawOption.WRAP_NUMBER]) {
            if(AppFactory.getUser().bigNumberWidgetsUseWhiteFontColor) {
                style.color = 'rgb(202, 200, 206)';
            } else {
                const color = UIColor.textColorWithBackgroundColor(_getBackgroundColor());
                style.color = color === '#ffffff' ? 'rgb(202, 200, 206)' : 'rgb(67, 65, 65)';
            }
        }

        return style;
    }

    /**
     * Build BIGNUMBER widget
     */
    function buildBigNumber() {
        if (vm.state.isCreating && !vm.state.canSave) {
            return false;
        }

        if (vm.widget.has_live_integration && DashboardContextService.widgetHasGlobalFilter(vm.widget.metadata.data_source)) {
            vm.state.loadingState = LoadingState.HAS_GLOBAL_FILTERS;
            return false;
        }

        vm.state.loadingState = LoadingState.FETCHING;
        vm.state.loadingStateNoData = false;
        if (!vm.state.loadingCount) {
          vm.state.loadingCount = 0;
        }
        vm.state.loadingCount+=1;
        const currentRequestId = ++requestId;
        $q.resolve(BigNumberFactory.getData(vm.widget)).then(function(json) {
            if (currentRequestId !== requestId) {
                return; // Cancelled an outdated request
            }
            if (!json) return;

            // NOTE: bignumbers are returned as objects since they only have one level of data
            var hasComparisonData = json.has_comparison_data;
            var data = json.data;

            vm.state.loadingState = WidgetFactory.useSampleData(vm.widget.metadata)
                ? LoadingState.BUILDING
                : WidgetFactory.evaluateLoadingState(data, vm.widget.metadata, hasComparisonData);

            if (vm.widget.metadata?.dynamic?.predefined_data !== null) {
                if ('currency_discrepancy' in vm.widget.metadata.dynamic.predefined_data) {
                    vm.widget.metadata.currency_discrepancy = vm.widget.metadata.dynamic.predefined_data.currency_discrepancy;
                }

                if ('show_currency' in vm.widget.metadata.dynamic.predefined_data) {
                    vm.widget.metadata.show_currency = vm.widget.metadata.dynamic.predefined_data.show_currency;
                }
            }

            if (WidgetFactory.hasNoDataLoadingState(vm.state.loadingState)) {
                WidgetFactory.widgetLoaded(vm.widget);
                vm.state.loadingCount = 0;
                if (vm.state.loadingState !== LoadingState.NO_DATA) {
                    return null;
                }
                vm.state.loadingStateNoData = true;
            }

            // Build big numbers by matching returned values to selectable columns to get column labels and more
            vm.bigNumbers = [];
            var allEmptyData = true;
            var selectedColumnColumnsFields = AppFactory.arrayToMemoizedObj(vm.widget.metadata.data_columns.selected, 'field');
            _.each(data, function(datum, index) {
                var currDatum = hasComparisonData ? datum.current_period : datum;
                _.each(vm.widget.metadata.data_columns.selected, function(column) {

                    // Find currDatum in selectable columns and make sure it is selected
                    if (selectedColumnColumnsFields[column.field] && AppFactory.util.column.isNumeric(column.format)) {
                        var bigNum = {
                            field: column.field,
                            label: column.label,
                            format: column.format,
                            value: currDatum[column.field],
                            precision: column.precision,
                            comparison: false,
                        };

                        /*
                        * adding client_currency value so that it can be used
                        * for formatting the value.
                        *
                        * If client_currency is not passed, default (instance) currency is being used
                        * */
                        if(bigNum.format === ColumnFormat.FORMAT_CURRENCY) {
                            bigNum.client_currency = currDatum['client_currency'];
                        }
                        if (hasComparisonData) {
                            var comparisonData = datum.prior_period;

                            bigNum.comparisonValue = comparisonData[column.field] ? comparisonData[column.field] : '-';
                            bigNum.noComparisonValue = (bigNum.value == 0 ||
                                _.isNull(bigNum.value)) ||
                                (bigNum.comparisonValue == 0 ||
                                    bigNum.comparisonValue === '-');

                            var delta = WidgetFactory.getComparisonDeltaValue(column, bigNum);

                            if (Math.abs(delta) && !bigNum.noComparisonValue) {
                                bigNum.deltaDisplay = Math.round(Math.abs(delta));
                                if (!column.compare_as_value) {
                                    bigNum.deltaDisplay += '%';
                                }
                            } else {
                                bigNum.deltaDisplay = '-';
                            }
                            bigNum.compareInverted = column.compare_inverted;
                            bigNum.isComparisonOverridden = vm.widget.metadata.comparison_overwrite.includes(column.field);

                            if (delta > 0 && !bigNum.noComparisonValue) {
                                bigNum.caretClass = 'icon-caret-up';
                                bigNum.plusOrMinus = getPlusOrMinus(bigNum.compareInverted, 'up', bigNum.isComparisonOverridden);
                            }
                            else if (delta < 0 && !bigNum.noComparisonValue) {
                                bigNum.caretClass = 'icon-caret-down';
                                bigNum.plusOrMinus = getPlusOrMinus(bigNum.compareInverted, 'down', bigNum.isComparisonOverridden);
                            }
                            else {
                                bigNum.caretClass = 'icon-caret-down';
                                bigNum.plusOrMinus = ChartPlotType.NEUTRAL;
                            }
                            if (allEmptyData && bigNum.comparisonValue !== '-' && _.toNumber(bigNum.comparisonValue) !== 0) {
                                allEmptyData = false;
                            }
                        }
                        if(vm.widget.metadata.currency_discrepancy !== true || (vm.widget.metadata.currency_discrepancy === true && vm.widget.metadata.show_currency === true)) {
                            bigNum.currencySymbol = (bigNum.client_currency || (bigNum.client_currency ?? $.globals.utils.clientCurrencySymbol) || ((bigNum.client_currency ?? $.globals.utils.currencySymbol) || ''));
                        }else {
                            bigNum.currencySymbol = '';
                        }

                        setRoundedMetric(bigNum, column, hasComparisonData);

                        vm.bigNumbers.push(bigNum);

                        //Check if widget has at least one value to display
                        const nullOrZeroValueStrings = ['null', 'undefined', '0', '00', '0.00', '00:00', '00:00:0', '0:00:00', '00:00:00'];
                        var hasValidTimeValue = column.format === ColumnFormat.FORMAT_TIME && !nullOrZeroValueStrings.includes(String(bigNum.value));
                        if (
                          allEmptyData &&
                          ((column.format !== ColumnFormat.FORMAT_TIME &&
                            _.toNumber(bigNum.value) !== 0) ||
                            hasValidTimeValue)
                        ) {
                          allEmptyData = false;
                        }

                        // Only return one big number for thumb preview
                        if (vm.state.isThumbPreview) {
                            WidgetFactory.widgetLoaded(vm.widget);
                            return;
                        }
                    }
                });

            });

            if (vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.SPARKLINE) {
                setSparkLineData();
            }

            if (vm.widget.metadata.draw_options[DrawOption.CIRCLE_NUMBER] == true) {
                vm.widget.metadata.draw_options[DrawOption.WRAP_NUMBER] = true;
                vm.widget.metadata.draw_options[DrawOption.CIRCLE_NUMBER] = WrapFormat.CIRCLE;
            }
            if (!vm.widget.metadata.draw_options[DrawOption.CIRCLE_NUMBER]) {
                vm.widget.metadata.draw_options[DrawOption.CIRCLE_NUMBER] = WrapFormat.CIRCLE;
            }

            if (!vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE]) {
                vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE] = vm.widget.id === WidgetBuilderConstants.NEW_WIDGET_ID
                || WidgetBuilderService.isCurrentWidgetInBuildMode(vm.widget.id) ? ChartPlotType.DEFAULT : ChartPlotType.CLASSIC;
            }
            if (vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE] !== ChartPlotType.DEFAULT
                && vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE] !== ChartPlotType.CLASSIC) {
                vm.widget.metadata.draw_options[DrawOption.WRAP_NUMBER] = true;
                vm.widget.metadata.draw_options[DrawOption.CIRCLE_NUMBER] = WrapFormat.SQUARE;
            }

            if (vm.widget.metadata.draw_options[DrawOption.CENTER_NUMBERS] == true) {
                vm.widget.metadata.draw_options[DrawOption.CENTER_NUMBERS] = CenterNumbers.CENTER;
            } else if (vm.widget.metadata.draw_options[DrawOption.CENTER_NUMBERS] == false) {
                vm.widget.metadata.draw_options[DrawOption.CENTER_NUMBERS] = CenterNumbers.LEFT;
            }

            if (!vm.widget.metadata.draw_options[DrawOption.BACKGROUND_GRADIENT]) {
                vm.widget.metadata.draw_options[DrawOption.BACKGROUND_GRADIENT] = 'solid';
            }

            vm.widget.metadata.draw_options['allEmptyData'] = allEmptyData;
            // If all data is null or 0s
            if (allEmptyData) {
                if (vm.widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA]) {
                    vm.state.loadingState = LoadingState.DONE;
                } else if (vm.widget.metadata.data_source.type === DataSourceType.CATEGORY_DATA) {

                    // Since category has different ways of returning data
                    // We cannot conclude that all data is empty by all zero values
                    if (!data.length) {
                        vm.state.loadingState = LoadingState.NO_DATA;
                    } else {
                        vm.state.loadingStateNoData = true;
                        if (ReportStudioTemplateDataService.getIsActive() || DesignFactory.getIsExportingPage()) {
                            vm.state.loadingState = LoadingState.DONE;
                        } else {
                            vm.state.loadingState = resolveDrawOptionValue(DrawOption.SHOW_WIDGETS_WITH_NO_DATA) ? LoadingState.DONE :  LoadingState.NO_DATA;
                        }
                    }

                } else {
                    // We cannot conclude that all data is empty by all zero values or null, we may get empty data = []
                    if (ReportStudioTemplateDataService.getIsActive() || DesignFactory.getIsExportingPage()) {
                        vm.state.loadingState = (!data.length || WidgetFactory.useSampleData(vm.widget.metadata))
                                                ? LoadingState.NO_DATA
                                                : LoadingState.DONE;
                    } else {
                        vm.state.loadingState = (!data.length || WidgetFactory.useSampleData(vm.widget.metadata))
                                                ? LoadingState.NO_DATA
                                                : resolveDrawOptionValue(DrawOption.SHOW_WIDGETS_WITH_NO_DATA) ? LoadingState.DONE :  LoadingState.ALL_EMPTY_DATA;
                    }


                }
            } else {
                /*
                * we are using loadingLayout value to determine if loader should be shown or not
                * adding this below line so that when demo mode is enabled & show_widgets_with_no_data is disabled,
                * loadingLayout value will be updated
                * */
                WidgetFactory.evaluateLoadingState(data, vm.widget.metadata, hasComparisonData);
                if (vm.state.loadingCount <= 1) {
                  vm.state.loadingState = LoadingState.DONE;
                }
            }

            if($scope.$parent && $scope.$parent.state && $scope.$parent.state.helpNeeded) {
                $scope.$parent.state.helpNeeded = false;
            }

            WidgetFactory.widgetLoaded(vm.widget);
        }, function(error) {
            vm.state.loadingState = WidgetFactory.evaluateLoadingState(error.data || [], vm.widget.metadata);
            WidgetFactory.widgetLoaded(vm.widget);
            if (WidgetFactory.hasNoDataLoadingState(vm.state.loadingState)) {
                vm.state.loadingCount = 0;
                return null;
            }
        }).finally(() => {
            if (vm.state.loadingCount) {
                vm.state.loadingCount -= 1;
            }
        });
    }

    function setSparkLineData() {
        var sparkLineData = [];
        $q.resolve(BigNumberFactory.getSparkLineData(vm.widget)).then(async function (json) {
            sparkLineData = json.sparkline_data ? json.sparkline_data : json.data;
            vm.bigNumbers.forEach((bigNum) => {
                    bigNum.sparkline = sparkLineData;
                    bigNum.primaryDateField = 'formatted_log_date';
                }
            );
        });
    }

    function setRoundedMetric(bigNumber, column, hasComparisonData) {
        if (column.format === ColumnFormat.FORMAT_PERCENT && vm.widget.metadata.rounded_metrics.includes(column.field)) {
            bigNumber.is_rounded = true;
            bigNumber.value = `${Math.trunc(Math.round(bigNumber.value))}`;

            if (hasComparisonData) {
                bigNumber.comparisonValue = `${Math.trunc(Math.round(bigNumber.comparisonValue))}`;
            }
        }
    }

    function getPlusOrMinus(compareInverted, caretDirection, comparisonOverridden) {
        var plusOrMinus;
        if (caretDirection === 'up') {
            plusOrMinus = compareInverted ? ComparisonType.NEGATIVE : ComparisonType.POSITIVE;
        } else {
            plusOrMinus = compareInverted ? ComparisonType.POSITIVE : ComparisonType.NEGATIVE;
        }
        if (comparisonOverridden) {
            plusOrMinus = plusOrMinus === ComparisonType.NEGATIVE ? ComparisonType.POSITIVE : ComparisonType.NEGATIVE;
        }

        return plusOrMinus;
    }

    function getEmptyDataMessage() {
        if (vm.state.loadingStateNoData) {
            return 'NO DATA: No data found for the dashboard date range';
        }
        if (vm.widget.metadata.draw_options['allEmptyData']) {
            return 'ALL EMPTY DATA: There is data for the selected date range but all values are 0 or null';
        }

        return '';
    }

    /**
     * @param isComparison
     * @param item
     * @returns {{}}
     */
    function getNumberStyle(isComparison, item) {
        var style = {};
        var drawOptions = vm.widget.metadata.draw_options;
        item.formattedValue = BigNumberFactory.getFormattedValue(
            item.value,
            item.format,
            item.precision,
            drawOptions.reduce_number,
            item,
            vm.widget.metadata.currency_discrepancy
        );
        if (isComparison) {

            //value, format, precision, reduceNumber
            item.formattedComparisonValue = BigNumberFactory.getFormattedValue(
                item.comparisonValue,
                item.format,
                item.precision,
                drawOptions.reduce_number,
                item,
                vm.widget.metadata.currency_discrepancy
            );
        }

        if (!drawOptions[DrawOption.WRAP_NUMBER]) {
            if (resolveDrawOptionValue(DrawOption.IS_DARK_MODE)) {
                style.color = _textColor;
            }
            style.fontSize = drawOptions[DrawOption.FONT_SIZE];
            if (isComparison) {
                style.fontSize *= 0.7;
            }
        }
        else {
            const defaultStyle = '100px';
            style.width = defaultStyle;
            style.height = defaultStyle;
            let defaultFontSize = 22;
            if (isComparison) {
                defaultFontSize *= 0.7
                defaultFontSize = parseFloat(defaultFontSize.toFixed(2));
            }
            if (!drawOptions[DrawOption.CIRCLE_FONT_SIZE]) {
                drawOptions[DrawOption.CIRCLE_FONT_SIZE] = defaultFontSize;
            } else if (drawOptions[DrawOption.CIRCLE_FONT_SIZE] === 15.40 && !isComparison) {
                drawOptions[DrawOption.CIRCLE_FONT_SIZE] = defaultFontSize;
            } else if (drawOptions[DrawOption.CIRCLE_FONT_SIZE] === 22 && isComparison) {
                drawOptions[DrawOption.CIRCLE_FONT_SIZE] = defaultFontSize;
            } else {
                style.fontSize = drawOptions[DrawOption.CIRCLE_FONT_SIZE];
            }
            const difference = Math.abs(style.fontSize - defaultFontSize);
            if (defaultFontSize > style.fontSize) {
                style.height = style.width = `${100 - (difference * 4)}px`;
            } else {
                style.height = style.width = `${100 + (difference * 5)}px`;
            }
            if (isComparison) {
                const lineHeightDiff = Math.abs(style.fontSize - 15) / 8;
                if (style.fontSize < 50) {
                    style.lineHeight = `${(0.2 * lineHeightDiff)}`;
                } else if (style.fontSize < 100) {
                    style.lineHeight = `${(0.15 * lineHeightDiff)}`;
                } else {
                    style.lineHeight = `${(0.1 * lineHeightDiff)}`;
                }
            }
            const fontSize = getFontSize(drawOptions[DrawOption.CIRCLE_FONT_SIZE], item.formattedValue, isComparison)
            style.fontSize = `${fontSize}px`;
        }
        if (resolveDrawOptionValue(DrawOption.DEFAULT_COMPARISON_ARROW_COLOR)) {
            item.deltaClass = item.plusOrMinus;
        }
        else {
            item.deltaClass = 'generic-comparison';
        }
        item.showComparisonArrows = resolveDrawOptionValue(DrawOption.SHOW_COMPARISON_ARROWS);
        if (vm.widget.metadata.draw_options[DrawOption.WRAP_NUMBER]) {
            style.backgroundColor = _getBackgroundColor();
        }

        if (item.is_rounded) {
            item.formattedValue = BigNumberFactory.getFormattedValue(
              item.value,
              item.format,
              0,
              drawOptions.reduce_number,
              item,
              vm.widget.metadata.currency_discrepancy
            );
            item.formattedComparisonValue = BigNumberFactory.getFormattedValue(
              item.comparisonValue,
              item.format,
              0,
              drawOptions.reduce_number,
              item,
              vm.widget.metadata.currency_discrepancy
            );
        }
        return style;
    }

    /**
     * @returns {boolean|*}
     */
    function isExportingReportStudio() {
        return DesignFactory.getIsExportingPage()
            && ReportStudioTemplateDataService.getIsActive();
    }

    /**
     * @returns {boolean|*}
     */
    function getFontType() {
        if (ReportStudioTemplateDataService.getIsActive()) {
            return {'font-family':DashboardContextService.resolveFontType()};
        }
        return {};
    }

    /**
     * Text in big number circle
     */
    function getNumberValueStyle() {
        var style = {};
        if (vm.widget.metadata.draw_options[DrawOption.WRAP_NUMBER]) {
            if(AppFactory.getUser().bigNumberWidgetsUseWhiteFontColor) {
                style.color = _textColor;
            } else {
                style.color = UIColor.textColorWithBackgroundColor(_getBackgroundColor());
            }
        }

        return style;
    }

    function deltaContainerStyles() {
        var style = {};
        style.fontSize = 14;
        if (vm.widget.metadata.draw_options[DrawOption.WRAP_NUMBER]) {
            if (vm.widget.metadata.draw_options[DrawOption.CIRCLE_FONT_SIZE]) {
                const fontSize = vm.widget.metadata.draw_options[DrawOption.CIRCLE_FONT_SIZE] * 0.4;
                style.fontSize =  fontSize > 14 ?  fontSize : style.fontSize;
            }
        }
        return style;
    }

    /**
     * @returns {{}}
     */
    function getLabelStyle() {
        var style = {};
        var drawOptions = vm.widget.metadata.draw_options;
        let fontSize;
        if (!drawOptions[DrawOption.WRAP_NUMBER]) {
            if (resolveDrawOptionValue(DrawOption.IS_DARK_MODE)) {
                style.color = _textColor;
            }
            style.fontSize = vm.widget.metadata.draw_options[DrawOption.FONT_SIZE] * 0.4;
            style.display = 'block';
            fontSize = vm.widget.metadata.draw_options[DrawOption.FONT_SIZE];
        } else {
            const defaultFontStyle = 12;
            fontSize = vm.widget.metadata.draw_options[DrawOption.CIRCLE_FONT_SIZE] || 12;
            style.fontSize = fontSize * 0.4;
            style.fontSize =  style.fontSize > defaultFontStyle ? style.fontSize : defaultFontStyle;
        }
        if (vm.widget.metadata.draw_options[DrawOption.WRAP_METRIC_NAME] && !vm.widget.metadata.draw_options[DrawOption.SPACING_TOGGLE]) {
            if (fontSize < 30) {
                style.width = '150px';
            } else if (fontSize < 70) {
                style.width = '200px';
            } else if (fontSize < 100) {
                style.width = '230px';
            } else {
                style.width = '270px';
            }
            style.whiteSpace = 'pre-wrap';
            style.margin = 'auto';
        }

        return style;
    }

    /**
     *
     * @returns {string}
     */
    function showMetricName() {
      var classNames = [];
      var getShowMetricNameStatus = resolveDrawOptionValue(DrawOption.SHOW_METRIC_NAME);
      if (!getShowMetricNameStatus) {
        classNames.push("hidden");
      }
      return classNames.join(" ");
    }

    /**
     * @param circleFontSize
     * @param val
     * @param isComparison
     * @returns {number}
     */
    function getFontSize(circleFontSize, val, isComparison) {
        let fontSize = circleFontSize;
        const len = !_.isNil(val) ? val.length : 0;

        if (!isComparison) {
            if (len >= 12) {
                fontSize -= 10;
            }
            else if (len >= 8) {
                fontSize -= 7;
            }
        }

        return fontSize;
    }

    function _getBackgroundColor() {
        if (AppFactory.getUser().bigNumberWidgetsUseThemeColor) {
            return DashboardContextService.resolveChartPalette(null)[0];
        } else {
            return vm.widget.metadata.data_source.color;
        }
    }

    function getBackoundColorForType(type) {
        if (type === BackgroundColor.THEME) {
            return DashboardContextService.resolveChartPalette(null)[0];
        } else if (type === BackgroundColor.DATASOURCE) {
            return vm.widget.metadata.data_source.color;
        } else {
            return vm.widget.metadata.draw_options[DrawOption.BACKGROUND_COLOR];
        }
    }

    /**
     * Will resolve the appropriate draw options based on the layout/widget inheritance tree
     * @returns {*}
     */
    function resolveDrawOptionValue(key) {
        return DrawOptionFactory.resolveDrawOptionValue(key, vm.widget, vm.widget.is_export);
    }

    /**
     * Handles if and how we should rebuild the big number widget
     */
    function handleRebuild() {
        var groupedColumns = vm.widget.metadata.data_columns.grouped;
        var hasGroupedColumns = _.size(groupedColumns) > 0;

        if (hasGroupedColumns) {

            // Set time grouping if first group by is a date field
            vm.widget.metadata.time_grouping = vm.widget.metadata.time_grouping || TimeGrouping.GROUPING_MONTHLY;
        }

        buildBigNumber();
    }

    var $widgetRebuildFn;
    function _registerEvents() {
        $widgetRebuildFn = $scope.$on($WidgetEvents.WIDGET_REBUILD, handleRebuild);

        $scope.$on('$destroy', function () {
            $widgetRebuildFn();
        });
    }
}
