<template>
  <div class="full-widget">
    <MapboxWidget
      v-if="showMapbox && accessToken"
      :token="accessToken"
      :class="{ 'padding-right': isComparison }"
      :id="id"
      :state="state"
      :widget="widget"
      :is-exporting="isExporting"
      :chart-palette="chartPalette"
      :is-dashboard="isDashboard"
      :data="currentData"
    ></MapboxWidget>
    <MapboxWidget
      v-if="accessToken && showMapbox && isComparison"
      :token="accessToken"
      :id="`comparison-${id}`"
      :state="state"
      :widget="widget"
      :is-exporting="isExporting"
      :chart-palette="chartPalette"
      :is-dashboard="isDashboard"
      :data="comparisonData"
    ></MapboxWidget>
  </div>
</template>

<script>
import { map } from 'lodash';
import MapboxWidget from '@/modules/ta/widget/components/types/MapboxWidget';
import { MapboxRenderService } from '@/modules/ta/widget/services/types/MapboxRenderService';
import { SessionRenderService } from '@/modules/core/session/services/SessionRenderService';
import WidgetService from '@/modules/ta/widget/services/WidgetService';

export default {
  name: 'MapboxAngularPage',
  components: { MapboxWidget },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    data: {
      type: [Object, Array],
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
    isExporting: {
      type: Boolean,
      required: true,
    },
    chartPalette: {
      type: Array,
      required: true,
    },
    mapboxToken: {
      type: String,
      default: '',
    },
    isDashboard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      accessToken: null,
    };
  },
  static: {
    renderService: new MapboxRenderService(),
  },
  mounted() {
    this.getAccessToken();
  },
  computed: {
    showMapbox() {
      return !!this.data?.data?.length && !this.state.loadingState;
    },
    isComparison() {
      return this.data?.has_comparison_data;
    },
    comparisonData() {
      return map(this.data?.data, 'prior_period');
    },
    currentData() {
      if (this.isComparison) {
        return map(this.data?.data, 'current_period');
      }
      return this.data?.data;
    },
  },
  methods: {
    async getAccessToken() {
      const key = 'mapbox-access-token';
      let accessToken = this.mapboxToken || SessionRenderService.getValue(key);
      if (!accessToken) {
        accessToken = await WidgetService.getMapboxAccessToken();
        accessToken = accessToken.token || accessToken.data.data.token;
        SessionRenderService.setValue(key, accessToken, 1);
      }
      this.accessToken = accessToken;
    },
  },
};
</script>

<style scoped lang="scss">
:deep() {
  .mapboxgl-map {
    width: 100%;
    height: 100%;
  }
  .mapboxgl-canvas {
    width: 100% !important;
  }
  .full-widget {
    width: 100%;
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
  .mapboxgl-ctrl-attrib-inner {
    display: none;
  }
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
}
.padding-right {
  padding-right: rem(5px);
}
</style>
