export class SelectedEntity {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  type;

  /**
   * @var {string}
   */
  name;

  /**
   * @var {string}
   */
  data_view_id;

  /**
   * @var {string}
   */
  type_name;

  constructor(model = {}) {
    this.id = model.id;
    this.type = model.type;
    this.name = model.name;
    this.data_view_id = model.data_view_id;
    this.type_name = model.type_name;
  }
}
