'use strict';
import angular from 'angular';
import './base/manageproducts.resources';
import './base/manageproducts.services';
import './base/manageproducts.components';
import './details/manageproducts.details.components';

import manageProductsHtmlUrl from './base/manageproducts.html';
import $ from "jquery";

angular.module('core.manageproducts',
    [
        'core.manageproducts.resources',
        'core.manageproducts.services',
        'core.manageproducts.constants',
        'core.manageproducts.components',
        'core.manageproducts.factory'
    ])
    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {
        $stateProvider
            .state('manageproducts', {
                parent:'layout',
                url: '/manageproducts',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('manageproducts'),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_MANAGE_PRODUCTS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: manageProductsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);
