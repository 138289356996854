'use strict';
import angular from 'angular';
import $ from 'jquery';
import './design.page.constants';
import './base/design.page.services';
import './base/design.page.resources';
import './base/design.page.ctrls';
import './base/design.page.directives';
import './dashboardfilterpanel/dashboardfilterpanel.module';
import './executivesummary/executivesummary.module';
import manageDashboardHtmlUrl from './base/managedashboards.html';

angular.module('design.page', [
    'design.page.constants',
    'design.page.services',
    'design.page.resources',
    'design.page.ctrls',
    'design.page.directives',
    'page.dashboardfilterpanel',
    'page.executivesummary'
])
    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {

        $stateProvider
            .state('managedashboards', {
                parent:'layout',
                url: '/managedashboards',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('managedashboards'),
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_MANAGE_DASHBOARDS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                templateUrl: manageDashboardHtmlUrl,
                controller: 'DesignController'
            })
    }]);