import { DateRange } from '@/modules/core/daterange/models/DateRange';

export class WidgetDataConfig {
  /**
   * @type {DateRange}
   */
  dateRange;

  /**
   * @type {Object.<string, RelativeDateRange>}
   */
  relativeRanges;

  /**
   * Query params
   * @type {{}}
   */
  params;

  /**
   * When need to pass extra properties
   * in the context where the widget is loading data from
   * @type {WidgetContext}
   */
  context;

  constructor(model = {}) {
    this.dateRange = model.dateRange || new DateRange();
    this.relativeRanges = model.relativeRanges || null;
    this.params = model.params || {};
    this.context = model.context || {};
  }
}
