import { ChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/ChartWidgetMetadata';
import { BarChartDrawOption } from '@/modules/ta/widget/models/drawoptions/BarChartDrawOption';

export class BarChartWidgetMetadata extends ChartWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new BarChartDrawOption(model.draw_options);
  }
}
