'use strict';
import angular from 'angular';
import _ from 'lodash';
import EventBus from "../../../../../grok/src/modules/core/app/helpers/EventBus";
import { UPDATE_NAV_ITEM } from "../../../../../grok/src/modules/core/nav/nav.constants";

angular.module('exportbuilder.service', [])
    .factory('ReportStudioTemplateDataService', ReportStudioTemplateDataService)
    .factory('ExportBuilderTagService', ExportBuilderTagService)
    .factory('ExportBuilderPublishService', ExportBuilderPublishService)
    .factory('ExportBuilderService', ExportBuilderService);

/**
 * @ngInject
 */
function ReportStudioTemplateDataService(
    FontSize
) {
    var isActive = false;

    var data = {
        report: null,
        entity: null,
        active: false,
        readOnly: false,
        pageId: null //regular dashboard page ID
    };

    return {
        setGlobalFilters: setGlobalFilters,
        getGlobalFilters: getGlobalFilters,
        getReport: getReport,
        getReportLanguage: getReportLanguage,
        setIsActive: setIsActive,
        setReport: setReport,
        getIsActive: getIsActive,
        setEntity: setEntity,
        getEntity: getEntity,
        getWidgetElements: getWidgetElements,
        getWidgetElementFromWidgetId: getWidgetElementFromWidgetId,
        getElementInPage: getElementInPage,
        getElementsByIds: getElementsByIds,
        getElementById: getElementById,
        getPageById: getPageById,
        getFontSize: getFontSize,
        updateElementFilterId: updateElementFilterId,
        getFontSizeValue: getFontSizeValue,
        isDemoModeEnabled: isDemoModeEnabled,
        getWidgetDataSources: getWidgetDataSources,
        isGlobalFilterEnabled: isGlobalFilterEnabled
    };

    function isGlobalFilterEnabled() {
            if (
                data.report &&
                data.report.metadata &&
                data.report.metadata.filter_mode === "widget_filters"
            ) {
                return false;
            }
        return true;
    }

    function setGlobalFilters(filters) {
        data.filters = filters;
    }

    function getGlobalFilters() {
        return data.filters;
    }

    function setIsActive(value) {
        isActive = value
    }

    function getIsActive() {
        return isActive;
    }

    function setReport(value) {
        data.report = value;
    }

    function getReport() {
        return data.report;
    }

    function getReportLanguage(){
        return data.report.report_language;
    }

    function setEntity(value) {
        data.entity = value;
    }

    function getEntity() {
        return data.entity || {};
    }

    /**
     * Getter
     * @returns {Array}
     */
    function getWidgetElements() {
        var elements = [];
        if (data.report) {
            _.each(data.report.pages, function (page) {
                elements = elements.concat(_.filter(page.elements, function (element) {
                    return !_.isNil(element.widget);
                }));
            });
        }
        return elements;
    }

    /**
     *
     * @param isLiveWidgets
     * @returns {{}}
     */
    function getWidgetDataSources(isLiveWidgets) {
        const elements = getWidgetElements();
        const dataSourceKeys = {};
        _.each(elements, (element) => {
            if (element && element.widget) {
                if (isLiveWidgets && element.widget.has_live_integration)  {
                    const {id, type} = element.widget.metadata.data_source;
                    id && type && (dataSourceKeys[`${type}|${id}|cgn`] = true);
                } else if (!isLiveWidgets){
                    const {id, type} = element.widget.metadata.data_source;
                    id && type && (dataSourceKeys[`${type}|${id}|cgn`] = true);
                }
            }
        });
        return dataSourceKeys;
    }

    /**
     * Getter
     * @param widgetId
     */
    function getWidgetElementFromWidgetId(widgetId) {
        return _.find(getWidgetElements(), {widget_id: widgetId});
    }

    /**
     * Getter
     * @param ids
     * @returns {Array<ReportElementModel>}
     */
    function getElementsByIds(ids) {
        var elements = [];
        _.each(data.report.pages, function (page) {
            elements = elements.concat(_.filter(page.elements, function (element) {
                return _.includes(ids, element.id);
            }));
        });
        return elements;
    }

    /**
     * @param elementId
     * @param page
     * @returns {*}
     */
    function getElementInPage(elementId, page) {
        return _.find(page.elements, {'id': elementId});
    }

    /**
     * Getter
     * @param id
     * @returns {ReportElementModel}
     */
    function getElementById(id) {
        var elements = getElementsByIds([id]);
        return _.isEmpty(elements) ? null : elements[0];
    }

    /**
     * @param elementId
     * @param filterId
     */
    function updateElementFilterId(elementId, filterId) {
        _.each(data.report.pages, function (page) {
            _.each(page.elements, function (element) {
                if (!element) return;
                if (element.id === elementId) {
                    element.widget.metadata.filter_set_id = filterId;
                }
            });
        });
    }

    /**
     * @param {Number} pageId
     * @returns {*}
     */
    function getPageById(pageId) {
        return _.find(data.report.pages, function (page) {
            return page.id == pageId; // double equal is wanted here.
        })
    }

    /**
     * @returns {*}
     */
    function getFontSize() {
        return data.report.metadata && data.report.metadata.font_size ? data.report.metadata.font_size : FontSize.SIZE_SMALL;
    }

    /**
     * Returns a pixel value for font size based on relative font size scale
     * @returns {number}
     */
    function getFontSizeValue() {
        switch (getFontSize()) {
            case FontSize.SIZE_SMALL:
                return 12;
            case FontSize.SIZE_MEDIUM:
                return 16;
            case FontSize.SIZE_LARGE:
                return 20;
            default:
                return 14;
        }
    }

    function isDemoModeEnabled() {
        return data.report.metadata.enable_demo_mode;
    }
}

/**
 * @ngInject
 */
function ExportBuilderTagService(
    ExportBuilderTagResource
) {

    return {
        getTags: getTags
    };

    function getTags() {
        return ExportBuilderTagResource.getData();
    }
}

/**
 * @ngInject
 */
function ExportBuilderPublishService(
    ReportStudioTemplateDataService,
    ExportBuilderResource,
    ReportTemplateType,
    gettextCatalog
) {

    return {
        publish: publish
    };

    function publish(model) {
        const { description, tags, title, report_id, type } = model;
        const publishModel = {
            description,
            tags,
            title,
            report_id,
            type
        };

        publishModel.tags = publishModel.tags.map(tag => {
            return { text: tag }
        });

        _validatePublishModel(publishModel);
        return ExportBuilderResource.publish(publishModel);
    }

    function _validatePublishModel(model) {
        if (_.isEmpty(model.title)) {
            throw new Error(gettextCatalog.getString('Missing Title'));
        }

        if (_.isEmpty(model.description)) {
            throw new Error(gettextCatalog.getString('Missing Description'));
        }

        if (_.isEmpty(model.type)) {
            throw new Error(gettextCatalog.getString('Missing Template Type'));
        }

        if (model.type === ReportTemplateType.TYPE_DESIGN) {
            const report = ReportStudioTemplateDataService.getReport();

            _checkMaximumPages(report);
            _checkDesignTemplateStructure(report);
            _checkForNoWidgets(report);
        }
    }

    function _checkMaximumPages(report) {
        if (report.pages.length > 4) {
            throw new Error(gettextCatalog.getString(`Design Templates can only contain a maximum of 3 pages.`));
        }
    }

    function _checkDesignTemplateStructure(report) {
        const pages = _.filter(report.pages, page => {
            return !page.is_cover_page && !page.is_back_page
        });

        if (pages.length > 1) {
            throw new Error(gettextCatalog.getString(`Design Templates can only have one intermediary page`));
        }
    }

    function _checkForNoWidgets(report) {
        _.each(report.pages, function (page) {
            _.each(page.elements, function (element) {
                if (element.isTypeWidget()) {
                    const page_index = page.page_index + 1;
                    throw new Error(gettextCatalog.getString(`Design Templates cannot contain widgets. There is a widget on page`) + ` ${page_index}`);
                }
            });
        });
    }
}

/**
 * @ngInject
 */
function ExportBuilderService(
    AppFactory,
    DataSourceFactory,
    ReportTemplateType,
    ExportBuilderModelFactory,
    ExportBuilderResource,
    FontSize,
    ExportBuilderNewReportAdminService,
    PubSub
) {
    return {
        getReports: getReports,
        getExportDesignTemplates: getExportDesignTemplates,
        getExportLibraryTemplates: getExportLibraryTemplates,
        copyReport: copyReport,
        copyPredefinedReportTemplate: copyPredefinedReportTemplate,
        useLibraryTemplate: useLibraryTemplate,
        deleteReport: deleteReport,
        refreshThumbnail: refreshThumbnail,
        clearSoftDeletedElements: clearSoftDeletedElements,
        exportReportAsTemplate: exportReportAsTemplate,
        setIsFavorite: setIsFavorite
    };

    /**
     * @param queryParams
     * @returns {angular.IPromise<T>}
     */
    function getReports(queryParams) {
        return ExportBuilderResource.getList(queryParams).then(function (reports) {
            return _.reduce(reports, function (acc, report) {
                // Ensure there are data sources involved in the report
                if (report.data_sources.length && !report.reporting_profiles) {
                    report.data_sources = _trimUnavailableDataSources(report.data_sources);
                    // Check if there are data sources left after trimming
                    if (!report.data_sources.length) {
                        return acc;
                    }
                }
                acc.push(ExportBuilderModelFactory.getReportStudioGalleryPageModel(report));
                return acc;
            }, []);
        });
    }

    /**
     * @param queryParams
     * @returns {angular.IPromise<T>}
     */
    function getExportDesignTemplates() {
        return ExportBuilderResource.getDesignTemplates().then(function (data) {
            return {design: _trim(data.design), custom: _trim(data.custom)}
        });
    }

    /**
     * @param queryParams
     * @returns {angular.IPromise<T>}
     */
    function getExportLibraryTemplates() {
        return ExportBuilderResource.getLibraryTemplates().then(function (data) {
            return {library: _trim(data.library), predefined: _trim(data.predefined)}
        });
    }

    function _trim(reports) {
        return _.reduce(reports, function (acc, report) {
            // Ensure there are data sources involved in the report
            if (report.data_sources && report.data_sources.length) {
                report.data_sources = _trimUnavailableDataSources(report.data_sources);
                // Check if there are data sources left after trimming
                if (!report.data_sources.length) {
                    return acc;
                }
            }
            acc.push(ExportBuilderModelFactory.getReportStudioGalleryPageModel(report));
            return acc;
        }, []);
    }

    /**
     * @param {ReportStudioGalleryPageModel} report
     * @param overrides
     * @returns {*}
     */
    function copyReport(report, overrides) {
        overrides = _.extend({
            is_predefined: false,
            type: ReportTemplateType.TYPE_CUSTOM,
            user_id: AppFactory.getUser().userId
        }, overrides);
        return ExportBuilderResource.copy(report, overrides).then(function (newReport) {
            return ExportBuilderModelFactory.getReportStudioGalleryPageModel(newReport)
        });
    }

    /**
     * Logic when using copying predefined report template to use as template
     * @param {ReportStudioGalleryPageModel} report
     * @returns {*}
     */
    function copyPredefinedReportTemplate(report, language = null) {
        return copyReport(report, { create_new_name: false, is_template: false, font_size: FontSize.SIZE_MEDIUM, report_language: language });
    }

    /**
     * Logic when using copying predefined report template to use as template
     * @param {ReportStudioGalleryPageModel} report
     * @returns {*}
     */
    function useLibraryTemplate(report, language = null ) {
        return copyReport(report, {
            is_in_library: false,
            create_new_name: false,
            is_template: false,
            enable_demo_mode: false,
            report_language: language
        });
    }

    /**
     * @param {ReportStudioGalleryPageModel} report
     * @returns {*}
     */
    function deleteReport(report) {
        return ExportBuilderResource.remove(report, {});
    }

    /**
     * @param {ReportStudioGalleryPageModel} report
     * @returns {*}
     */
    function refreshThumbnail(report) {
        if(report) {
            return ExportBuilderResource.updateThumbnail(report)
                .then(function (updatedReport) {
                    return updatedReport;
                });
        }
    }

    /**
     * @param {ReportStudioGalleryPageModel} report
     * @returns {*}
     */
    function clearSoftDeletedElements(report) {
        if(report && report.can_be_deleted) {
            return ExportBuilderResource.deleteSoftDeletedElement(report)
                .then(function (updatedReport) {
                    return updatedReport;
                });
        }
    }

    /**
     * @param dataSources
     * @returns {boolean}
     * @private
     */
    function _trimUnavailableDataSources(dataSources) {
        var allServices = AppFactory.arrayToMemoizedObj(AppFactory.getAllServices(), 'id');
        var allCategories = AppFactory.arrayToMemoizedObj(AppFactory.getAllCategories(), 'id');
        return _.filter(dataSources, function(dataSource) {
            if (
                (DataSourceFactory.isServiceDataSourceType(dataSource.data_type) &&
                    allServices[dataSource.data_source_id]) ||
                (DataSourceFactory.isCategoryDataSourceType(dataSource.data_type) &&
                    allCategories[dataSource.data_source_id]) ||
                DataSourceFactory.isUserDataSourceType(dataSource.data_type) ||
                DataSourceFactory.isGoalDataSourceType(dataSource.data_type) ||
                DataSourceFactory.isLeadsDataSourceType(dataSource.data_type)
            ) {
                return dataSource;
            }
        });
    }

    /**
     * @param {ReportStudioGalleryPageModel} report
     * @returns {*}
     */
    function exportReportAsTemplate(report) {
        return ExportBuilderResource.exportReportAsTemplate(report)
            .then(function (reportTemplate) {
                return reportTemplate;
            });
    }

    /**
     * @param report
     * @param {boolean} value
     */
    function setIsFavorite(report, value) {
        ExportBuilderResource.setIsFavorite(report.id, value).then(function() {
            report.is_favorite = value;
            if (window.isNUI) {
                PubSub.emit('SegmentEvents', {
                    event: 'ReportStudioToggleFavoriteEvent',
                    payload: { ...report, type: report.getReportType()}
                });
            }
            EventBus.signal(UPDATE_NAV_ITEM);
        });
    }
}
