'use strict';
import angular from 'angular';
import $ from 'jquery';
import './tag.constants';
import './base/tag.components';
import './base/tag.services';
import './base/tag.resources';
import './base/tag.models';
import './values/tag.values.module';

import tagHtmlUrl from './base/tag.html';

angular.module('core.tag', [
    'core.tag.constants',
    'core.tag.components',
    'core.tag.services',
    'core.tag.resources',
    'core.tag.models',

    'core.tag.values'
])
    .config(['$stateProvider', 'AppConfig', 'TagRouteParams', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, TagRouteParams, SwitchAppServiceProvider) {
        $stateProvider
            .state(TagRouteParams.STATE_NAME, {
                parent:'layout',
                url: TagRouteParams.URL,
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn(TagRouteParams.STATE_NAME),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_TAGS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: tagHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            });
    }]);