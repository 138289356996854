import { cloneDeep } from 'lodash';
import { commits, getters } from '@/modules/core/app/helpers/store';
import { shallowCopyKeyedObject } from '@/modules/core/helper';
import { WidgetState } from '@/modules/ta/widget/widget.constants';

export const mutations = {
  setWidgetInLayout(state, { layoutId, widget }) {
    state.layouts[layoutId].widgets[widget.id] = true;
    commits.widget.setWidget({ widget });
    state.layouts = shallowCopyKeyedObject(state.layouts);
  },
  removeWidgetInLayout(state, { layoutId, widget }) {
    delete state.layouts[layoutId].widgets[widget.id];
    commits.widget.removeWidget({ widget });
    state.layouts = shallowCopyKeyedObject(state.layouts);
  },
  setLayoutsFromDashboard(state, { dashboard }) {
    commits.widget.setWidgetsFromDashboard({ dashboard });

    state.layouts = {};
    Object.values(dashboard.layouts).forEach((layout) => {
      layout.widgets = Object.values(layout.widgets).reduce((accum, widget) => {
        accum[widget.id] = null;
        return accum;
      }, {});
      state.layouts[layout.id] = layout;
    });
  },
  saveList(state, layouts) {
    layouts.forEach((layout) => {
      layout.widgets = getters.dashboardLayout
        .getWidgetsInLayout(layout.id)
        .reduce((accum, { id }) => {
          accum[id] = null;
          return accum;
        }, {});
      state.layouts[layout.id] = layout;
    });
    state.layouts = shallowCopyKeyedObject(state.layouts);
  },
  setLayout(state, layout) {
    layout.widgets = Object.keys(layout.widgets || []).reduce((accum, widgetId) => {
      accum[widgetId] = null;
      return accum;
    }, {});
    state.layouts[layout.id] = layout;
    state.layouts = shallowCopyKeyedObject(state.layouts);
  },
  addGhostWidget(layoutState, { layoutId, widget, state = WidgetState.STATE_DONE }) {
    layoutState.layouts[layoutId].widgets[widget.id] = true;

    commits.widget.setWidget({ widget, state });
    commits.widgetBuilder.setBuilderForm({ widget });

    // state.layouts = shallowCopyKeyedObject(state.layouts);
    layoutState.layouts[layoutId].widgets = shallowCopyKeyedObject(
      layoutState.layouts[layoutId].widgets
    );
  },
  addWidget(layoutState, { layoutId, widget, state = WidgetState.STATE_DONE }) {
    layoutState.layouts[layoutId].widgets[widget.id] = true;
    commits.widget.setWidget({ widget, state });
    layoutState.layouts[layoutId].widgets = shallowCopyKeyedObject(
      layoutState.layouts[layoutId].widgets
    );
  },
  /**
   * @param {LayoutState} state
   * @param map
   */
  setOriginalLayoutWidgets(state, widgets) {
    state.originalLayoutWidgets = cloneDeep(widgets);
  },

  removeLayout(state, layoutId) {
    const layout = state.layouts[layoutId];
    const widgetIds = Object.keys(layout.widgets);

    commits.widget.removeWidgetsFromId({ widgetIds });
    delete state.layouts[layoutId];
    state.layouts = shallowCopyKeyedObject(state.layouts);
  },
  setExecutiveSummaryOnLayout(state, executiveSummary) {
    state.layouts[executiveSummary.layout_id].executive_summary = executiveSummary;
  },
};
