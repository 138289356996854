'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import jQuery from 'jquery';
import elementPlaceholderHtmlUrl from '../views/directives/elementplaceholder.html';

angular.module('core.ui.directives', [])
    .directive('visible', visible)
    .directive('tooltip', tooltip)
    .directive('draggable', draggable)
    .directive('droppable', droppable)
    .directive('elementOverlay', elementOverlay)
    .directive('elementPlaceholder', elementPlaceholder)
    .directive('toggleModal', toggleModal)
    .directive('modalAutoShow', modalAutoShow)
    .directive('modalOnHidden', modalOnHidden)
    .directive('onPressEnter', onPressEnter)
    .directive('autofocus', autofocus)
    .directive('toggleStateClickedOutside', toggleStateClickedOutside)
    .directive('tapIsotope', tapIsotope)
    .directive('tapRightClick', tapRightClick)
    .directive('tapHide', tapHide)
    .directive('noFocus', noFocus)
    .directive('allNoFocus', allNoFocus);

/**
 * @ngInject
 */
function visible() {
    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            scope.$watch(attributes.visible, function (value) {
                element.css('opacity', value ? 1 : 0);
                element.css('pointer-events', value ? 'auto' : 'none');
            });
        }
    }
}

/**
 * @ngInject
 */
function tooltip() {
    return {
        restrict: 'A',
        scope: {
            container: '<',
            label: '@',
            position: '@',
            dynamicLabel: '<',
            html: '@',
            trigger: '@',
            autoShow: '@'
        },
        link: function(scope, el) {
            $.core.main.tooltip(el, {
                container: _.isNil(scope.container) ? null : scope.container,
                trigger: scope.trigger || null,
                title: scope.dynamicLabel || scope.label || '',
                placement: scope.position || 'bottom',
                html: scope.html || false
            });

            if (scope.autoShow) {
                $(el).tooltip('show');
            }
        }
    }
}

/**
 * @ngInject
 */
function draggable() {
    return {
        restrict: 'A',
        scope: {
            options: '<'
        },
        link: function(scope, el) {
            $(el).draggable(_.assign({
                revert: true,
                revertDuration: 300
            }, scope.options));
        }
    }
}

/**
 * @ngInject
 */
function droppable() {
    return {
        restrict: 'A',
        scope: {
            options: '<'
        },
        link: function(scope, el) {
            $(el).droppable(_.assign({}, scope.options));
        }
    }
}

/**
 * @ngInject
 */
function elementOverlay() {
    return {
        restrict: 'A',
        scope: {
            placeholderText: '='
        },
        link: function(scope, el) {
            var elementOverlay = angular.element('<div class="element-overlay"></div><div class="placeholder-overlay"><label class="label label-default">' + scope.placeholderText + '</label></div>');
            scope.$watch('placeholderText', function() {
                if (scope.placeholderText) {
                    el.addClass('element-overlay-container');
                    el.prepend(elementOverlay);
                }
                else {
                    el.removeClass('element-overlay-container');
                    elementOverlay.remove();
                }
            });
        }
    }
}

/**
 * @ngInject
 */
function elementPlaceholder() {
    return {
        restrict: 'E',
        scope: {
            heading: '@',
            label: '@',
            hideAction: '<',
            actionText: '@',
            actionFn: '&',
            image: '@',
            icon: '@',
            hoverIcon: '@', // Is the icon when hoverring the action button
            position: '@',
            border: '<',
            top: '<',
            right: '<',
            bottom: '<',
            left: '<'
        },
        templateUrl: elementPlaceholderHtmlUrl,
        link: function(scope, el) {
            el.addClass('flex-full relative');
            var $el = el.find('div.element-placeholder');
            $el.css('border-width', scope.border || 0);
            $el.css('border-style', 'dashed');
            $el.css('top', _.isUndefined(scope.top) ? 10 : scope.top);
            $el.css('right', _.isUndefined(scope.right) ? 10 : scope.right);
            $el.css('bottom', _.isUndefined(scope.bottom) ? 10 : scope.bottom);
            $el.css('left', _.isUndefined(scope.left) ? 10 : scope.left);
            $el.css('position', scope.position || 'absolute');

            scope.isHovering = false;
        }
    }
}

/**
 * @ngInject
 */
function toggleModal() {
    return {
        restrict: 'A',
        link: function (scope, el, attrs) {
            el.click(function() {
                var $modal = $('#' + attrs.modalId);
                if ($modal.is(':hidden')) {
                    $.core.main.showModal($modal, {animation: attrs.animation || null});
                }
                else {
                    $.core.main.hideModal($modal);
                }
            });
        }
    }
}

/**
 * Auto show bootstrap modal when template is interpreted by angular
 */
function modalAutoShow() {
    return {
        link: function (scope, el) {
            $(el).modal('show');
        }
    }
}

/**
 * Catch the event when modal is dismissed
 */
function modalOnHidden() {
    return {
        scope: {
            onClose: '&modalOnHidden'
        },
        link: function (scope, el) {
            $(el).on('hidden', function () {
                scope.$evalAsync(function () {
                    scope.onClose();
                })
            });
        }
    }
}

/**
 * Respond when enter key on keyboard is pressed
 */
function onPressEnter() {
    return {
        scope: {
            onPressEnter: '=onPressEnter'
        },
        link: function (scope, el) {
            $(el).keypress(function(e) {
                if(e.which == 13) {
                    scope.$evalAsync(function () {
                        scope.onPressEnter(e.target.value);
                    })
                }
            });
        }
    }
}

/**
 * AngularJS friendly autofocus
 */
function autofocus($timeout) {
    return {
        restrict: 'A',
        link : function($scope, $element) {
            $timeout(function() {
                $element[0].focus();
            });
        }
    }
}

/**
 * Toggles the state variable
 * @example If clicked outside of a particular component/div (i.e. $element),
 * 			toggles  the `toggleState`, which could be mapped to `isActive` property
 * 			needed to present or dismiss the $element
 * @ngInject
 */
function toggleStateClickedOutside(
    $document
) {
    return {
        scope: {
            toggleState: '=toggleStateClickedOutside'
        },
        link: function ($scope, $el) {
            $document.bind('click', _clickEvent);
            $scope.$on('$destroy', function () {
                $document.unbind('click', _clickEvent);
            });

            /**
             * @param event
             * @private
             */
            function _clickEvent(event) {
                if (!jQuery.contains($el[0], event.target)) {
                    $scope.$evalAsync(function () {
                        if (_.isFunction($scope.toggleState)) {
                            $scope.toggleState();
                        } else {
                            $scope.toggleState = !$scope.toggleState;
                        }
                    })
                }
            }
        }
    }
}

/**
 * Allow to override right click
 * @ngInject
 */
function tapRightClick(
) {
    return {
        scope: {
            tapRightClick: '<tapRightClick'
        },
        link: function ($scope, $el) {
            $el.bind('contextmenu', _clickEvent);
            $scope.$on('$destroy', function () {
                $el.unbind('contextmenu', _clickEvent);
            });

            /**
             * @param event
             * @private
             */
            function _clickEvent(event) {
                event.preventDefault();
                event.stopPropagation();

                if (!$scope.tapRightClick) {
                    return;
                }

                $scope.$evalAsync(function () {
                    var position = {x: event.clientX, y: event.clientY};
                    $scope.tapRightClick(position, event);
                });
            }
        }
    }
}

/**
 * Changes opacity of element
 */
function tapHide() {
    return {
        scope: {
            variable: '=tapHide'
        },
        link: function ($scope, $el) {
            $scope.$watch('variable', function (nV) {
                if (nV) {
                    $el.css('opacity', 0);
                } else {
                    $el.css('opacity', 1);
                }
            });
        }
    }
}

function noFocus() {
    return {
        link: function ($scope, $el) {
            $el.mouseup(function () {
                $el.blur();
            });
        }
    }
}

function allNoFocus() {
    return {
        link: function ($scope, $el) {
            $el.mouseup(function () {
                angular.element(':focus').blur();
            });
        }
    }
}

/**
 * @ngInject
 */
function tapIsotope(
) {
    return {
        scope: {
            options: '=tapIsotope'
        },
        link: function ($scope, $el) {
            $scope.$watch('options', function (nV, oV) {
                var options = $scope.options;
                if (nV != oV) {
                    if (nV.items.length != oV.items.length) {
                        $el.isotope('reloadItems').isotope({sortBy: options.sortBy, sortAscending: options.sortAscending});
                    }
                    if (options.sortBy) {
                        $el.isotope('updateSortData')
                            .isotope(options);
                    } else {
                        $el.isotope(options);
                    }
                } else {
                    $el.isotope(options);
                }
            }, true);
            $scope.options.bindTo($el)
        }
    }
}

