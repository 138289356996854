'use strict';
import angular from 'angular';

import mappingFieldRowHtmlUrl from '../views/directives/mappingfieldrow.html';
import mappingParentFieldRowHtmlUrl from '../views/directives/mappingparentfieldrow.html';
import mappingGeoDataFieldRowHtmlUrl from '../views/directives/mappinggeodatafieldrow.html';

import importwizardTitleHtml from '../views/importwizard/title.html';
import importwizardBreadcrumbHtml from '../views/importwizard/breadcrumb.html';
import importwizardSettingHtml from '../views/importwizard/setting.html';
import importwizardConnectionHtml from '../views/importwizard/connection.html';
import importwizardLiveconnectorHtml from '../views/importwizard/liveconnector.html';
import importwizardTapdirectHtml from '../views/importwizard/tapdirect.html';
import importwizardModulesHtml from '../views/importwizard/modules.html';
import importwizardJsonHtml from '../views/importwizard/json.html';
import importwizardTapapiHtml from '../views/importwizard/tapapi.html';
import importwizardFiledeliveryHtml from '../views/importwizard/file-delivery.html';
import importwizardGooglesheetHtml from '../views/importwizard/google-sheet.html';
import importwizardDatafieldsHtml from '../views/importwizard/data-fields.html';
import importwizardAlertHtml from '../views/importwizard/alert.html';
import importwizardEmailHtml from '../views/importwizard/email.html';
import importwizardSublevelHtml from '../views/importwizard/sublevel.html';
import importwizardAdvancedFetchSettingsHtml from '../views/importwizard/advanced-fetch-settings.html';
import importwizardManuallyUploadedFilesHtml from '../views/importwizard/manually-uploaded-files.html';
import importwizardMappingHtml from '../views/importwizard/mapping.html';
import importwizardTabparsingHtml from '../views/importwizard/tabparsing.html';
import importwizardPreprocessFunctionsHtml from '../views/importwizard/preprocess-functions.html';
import importwizardRegexSupportedHtml from '../views/importwizard/regexsupported.html';
import importwizardAdvancedDisplayCampaignNameHtml from '../views/importwizard/advanceddisplaycampaignname.html';

import tapdirectHeadersHtmlUrl from '../views/directives/tapdirectheaders.html';
import tapdirectParamsHtmlUrl from '../views/directives/tapdirectparams.html';
import tapdirectBodyFormDataHtmlUrl from '../views/directives/tapdirectbodyformdata.html';
import tapdirectBodyFormUrlEncodedHtmlUrl from '../views/directives/tapdirectbodyformurlencoded.html';
import tapdirectEnvironmentVariablesHtmlUrl from '../views/directives/tapdirectenvironmentvariables.html';
import tapdirectEnvironmentVariablesHouseHtmlUrl from '../views/directives/tapdirectenvironmentvariableshouse.html';
import tapdirectEnvironmentVariablesGlobalHtmlUrl from '../views/directives/tapdirectenvironmentvariablesglobal.html';
import tapdirectEnvironmentVariablesGlobalTmpHtmlUrl from '../views/directives/tapdirectenvironmentvariablesglobaltmp.html';

import $ from 'jquery';

angular.module('importwizard.directives', [])
    .directive('stringToNumber', stringToNumber)
    .directive('mappingFieldRow', mappingFieldRow)
    .directive('mappingParentFieldRow', mappingParentFieldRow)
    .directive('mappingGeoDataFieldRow', mappingGeoDataFieldRow)
    .directive('importwizardTitle', importwizardTitle)
    .directive('importwizardBreadcrumb', importwizardBreadcrumb)
    .directive('importwizardSetting', importwizardSetting)
    .directive('importwizardConnection', importwizardConnection)
    .directive('importwizardLiveconnector', importwizardLiveconnector)
    .directive('importwizardTapdirect', importwizardTapdirect)
    .directive('importwizardModules', importwizardModules)
    .directive('importwizardJson', importwizardJson)
    .directive('importwizardTapapi', importwizardTapapi)
    .directive('importwizardFiledelivery', importwizardFiledelivery)
    .directive('importwizardGooglesheet', importwizardGooglesheet)
    .directive('importwizardDatafields', importwizardDatafields)
    .directive('importwizardAlert', importwizardAlert)
    .directive('importwizardEmail', importwizardEmail)
    .directive('importwizardSublevel', importwizardSublevel)
    .directive('importwizardAdvancedFetchSettings', importwizardAdvancedFetchSettings)
    .directive('importwizardManuallyUploadedFiles', importwizardManuallyUploadedFiles)
    .directive('importwizardMapping', importwizardMapping)
    .directive('importwizardTabparsing', importwizardTabparsing)
    .directive('importwizardPreprocessFunctions', importwizardPreprocessFunctions)
    .directive('importwizardCodeSamples', importwizardCodeSamples)
    .directive('tapdirectHeaders', tapdirectHeaders)
    .directive('tapdirectParams', tapdirectParams)
    .directive('tapdirectBodyFormData', tapdirectBodyFormData)
    .directive('tapdirectBodyFormUrlEncoded', tapdirectBodyFormUrlEncoded)
    .directive('tapdirectEnvironmentVariables', tapdirectEnvironmentVariables)
    .directive('tapdirectEnvironmentVariablesGlobal', tapdirectEnvironmentVariablesGlobal)
    .directive('tapdirectEnvironmentVariablesGlobalTmp', tapdirectEnvironmentVariablesGlobalTmp)
    .directive('tapdirectEnvironmentVariablesHouse', tapdirectEnvironmentVariablesHouse)
    .directive('importwizardRegexSupported', importwizardRegexSupported)
    .directive('importwizardAdvancedDisplayCampaignName', importwizardAdvancedDisplayCampaignName)
    .directive('input', function($compile){
        // Runs during compile
        return {
            link(scope, iElement, iAttrs) {
                // Do not add auto-trim to text fields from tapdirect
                if (iElement.attr('type') === 'text' && iAttrs.ngModel && iAttrs.ngModel.startsWith('model.tapdirect')) {
                    iAttrs.$set('ngTrim', "false");
                }
                // Do not add auto-trim to password
                else if (iElement.attr('type') === 'password') {
                    iAttrs.$set('ngTrim', "false");
                }
            }
        };
    });

function importwizardSublevel() {
    return {
        restrict: 'A',
        templateUrl: importwizardSublevelHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardRegexSupported() {
    return {
        restrict: 'A',
        templateUrl: importwizardRegexSupportedHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardAdvancedDisplayCampaignName() {
    return {
        restrict: 'A',
        templateUrl: importwizardAdvancedDisplayCampaignNameHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardAlert() {
    return {
        restrict: 'A',
        templateUrl: importwizardAlertHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardEmail() {
    return {
        restrict: 'A',
        templateUrl: importwizardEmailHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardDatafields() {
    return {
        restrict: 'A',
        templateUrl: importwizardDatafieldsHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardFiledelivery() {
    return {
        restrict: 'A',
        templateUrl: importwizardFiledeliveryHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardGooglesheet() {
    return {
        restrict: 'A',
        templateUrl: importwizardGooglesheetHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardTabparsing() {
    return {
        restrict: 'A',
        templateUrl: importwizardTabparsingHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardPreprocessFunctions() {
    return {
        restrict: 'A',
        templateUrl: importwizardPreprocessFunctionsHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardCodeSamples() {
    return {
        restrict: 'A',
        templateUrl: importwizardCodeSamplesHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardMapping() {
    return {
        restrict: 'A',
        templateUrl: importwizardMappingHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardJson() {
    return {
        restrict: 'A',
        templateUrl: importwizardJsonHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardTapapi() {
    return {
        restrict: 'A',
        templateUrl: importwizardTapapiHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardConnection() {
    return {
        restrict: 'A',
        templateUrl: importwizardConnectionHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardLiveconnector() {
    return {
        restrict: 'A',
        templateUrl: importwizardLiveconnectorHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardModules() {
    return {
        restrict: 'A',
        templateUrl: importwizardModulesHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardSetting() {
    return {
        restrict: 'A',
        templateUrl: importwizardSettingHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardTapdirect() {
    return {
        restrict: 'A',
        templateUrl: importwizardTapdirectHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardTitle() {
    return {
        restrict: 'A',
        templateUrl: importwizardTitleHtml,
        link: function(scope, el) {
            scope.onBack = function() {
                window.location.hash = '#/importwizard';
            }
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardBreadcrumb() {
    return {
        restrict: 'A',
        templateUrl: importwizardBreadcrumbHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function importwizardAdvancedFetchSettings() {
    return {
        restrict: 'A',
        templateUrl: importwizardAdvancedFetchSettingsHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in upload/edit data fields
 */
function mappingFieldRow() {
    return {
        restrict: 'A',
        templateUrl: mappingFieldRowHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in upload/edit parent data fields
 */
function mappingParentFieldRow() {
    return {
        restrict: 'A',
        templateUrl: mappingParentFieldRowHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in upload/edit geo data fields
 */
function mappingGeoDataFieldRow() {
    return {
        restrict: 'A',
        templateUrl: mappingGeoDataFieldRowHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in tapdirect headers fields
 */
function tapdirectHeaders() {
    return {
        restrict: 'A',
        templateUrl: tapdirectHeadersHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in tapdirect params fields
 */
function tapdirectParams() {
    return {
        restrict: 'A',
        templateUrl: tapdirectParamsHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in tapdirect body form data fields
 */
function tapdirectBodyFormData() {
    return {
        restrict: 'A',
        templateUrl: tapdirectBodyFormDataHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in tapdirect body form url encoded fields
 */
function tapdirectBodyFormUrlEncoded() {
    return {
        restrict: 'A',
        templateUrl: tapdirectBodyFormUrlEncodedHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in tapdirect environment variables fields
 */
function tapdirectEnvironmentVariables() {
    return {
        restrict: 'A',
        templateUrl: tapdirectEnvironmentVariablesHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in tapdirect environment variables global fields
 */
function tapdirectEnvironmentVariablesGlobal() {
    return {
        restrict: 'A',
        templateUrl: tapdirectEnvironmentVariablesGlobalHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in tapdirect environment variables global fields
 */
function tapdirectEnvironmentVariablesGlobalTmp() {
    return {
        restrict: 'A',
        templateUrl: tapdirectEnvironmentVariablesGlobalTmpHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to add a data field row in tapdirect environment variables house fields
 */
function tapdirectEnvironmentVariablesHouse() {
    return {
        restrict: 'A',
        templateUrl: tapdirectEnvironmentVariablesHouseHtmlUrl,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

/**
 * Directive to display manually uploaded files
 */
function importwizardManuallyUploadedFiles() {
    return {
        restrict: 'A',
        templateUrl: importwizardManuallyUploadedFilesHtml,
        link: function(scope, el) {
            setTimeout(() => {
                $.core.main.tooltip(el);
            })
        }
    }
}

function convertToNumber() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(val) {
        return val != null ? parseInt(val, 10) : null;
      });
      ngModel.$formatters.push(function(val) {
        return val != null ? '' + val : null;
      });
    }
  };
};

/**
 * Directive to convert a string to a number (for input type="number" fields)
 *
 * @source https://docs.angularjs.org/error/ngModel/numfmt?p0=3
 * @date 2016 Jul 06
 */
function stringToNumber() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (value) {
                return '' + value;
            });
            ngModel.$formatters.push(function (value) {
                return parseFloat(value);
            });
        }
    }
}