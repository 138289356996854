export class DrillDownConfig {
  /**
   * @type {Object}
   */
  queryParams;

  /**
   * @type {Number}
   */
  groupByIndex;

  /**
   * @type {string}
   */
  title;

  /**
   * @type {Boolean}
   */
  isSubSlice;

  constructor(model = {}) {
    this.queryParams = model.queryParams || {};
    this.groupByIndex = model.groupByIndex || 0;
    this.title = model.title ?? '';
    this.isSubSlice = model.isSubSlice;
  }
}
