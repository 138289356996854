import angular from 'angular';

angular.module('widget.library.models', [])
    .factory('WidgetLibraryModelFactory', WidgetLibraryModelFactory);

/**
 * @ngInject
 */
function WidgetLibraryModelFactory(

) {
    return {
        getWidgetLibraryModalState: getWidgetLibraryModalState,
        getWidgetLibraryModalServiceState: getWidgetLibraryModalServiceState
    };

    /**
     * @constructor
     */
    function WidgetLibraryModalState() {
        var self = this;

        self.isLoading = false;
        self.isSearchBoxActive = false;
        self.isSearching = false;
    }

    /**
     * @constructor
     */
    function WidgetLibraryModalServiceState() {
        var self = this;

        self.isActive = false;
    }

    /**
     * @returns {WidgetLibraryModalState}
     */
    function getWidgetLibraryModalState() {
        return new WidgetLibraryModalState();
    }

    /**
     * @returns {WidgetLibraryModalServiceState}
     */
    function getWidgetLibraryModalServiceState() {
        return new WidgetLibraryModalServiceState();
    }
}