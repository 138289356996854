import { MapPolygonSeries } from '@amcharts/amcharts5/map';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import {
  Countries,
  DRILLDOWN,
  HEAT_RULES,
  HOVER,
  VALUE,
  FILL,
  CLICK,
  AM5_COUNTRY_DRILLDOWN,
  YELLOW,
  DARK_YELLOW,
  STATE,
  HORIZONTAL,
  VALUE_LOW,
  VALUE_HIGH,
  START_COLOR,
  END_COLOR,
  DATAVALIDATED,
  START_VALUE,
  END_VALUE,
  POINT_OVER,
  GeoConfigurationType,
} from '@/modules/core/charts/am5/geo/geo.charts.constants';
import { HeatLegend, DataProcessor } from '@amcharts/amcharts5';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { useGeoMapBase } from '@/modules/core/charts/am5/geo/composables/useGeoMapBase';

export function useHeatMaps(context) {
  const { root, chart, config } = context();
  const { hideAllSeries, loadIdMap, loadCountryAssociation, setActualData, setZoomToCountry } =
    useGeoMapBase(context);

  function initHeatMap(mapId, visible = true) {
    loadCountryAssociation();
    config.value.series.forEach((props) => {
      const { heatMapSeries } = loadMap(props, visible);
      const { countrySeries } = loadCountryMap(props, heatMapSeries, !visible, setCountryData);
      if (!visible) {
        loadIdMap(mapId.toUpperCase()).then((response) => {
          countrySeries.setAll({
            geoJSON: response,
          });
          setCountryData(countrySeries, mapId.toUpperCase());
          setZoomToCountry(
            mapId,
            heatMapSeries,
            heatMapSeries.getDataItemById(mapId.toUpperCase())
          );
        });
      }
    });
  }

  function loadMap(props, visible) {
    const heatMapSeries = chart.value.series.push(
      MapPolygonSeries.new(root.value, {
        name: `${props.name}`,
        geoJSON: am5geodata_worldLow,
        valueField: props.column.field,
        useGeodata: true,
        calculateAggregates: true,
        visible,
        exclude: [Countries.AQ],
      })
    );
    heatMapSeries.mapPolygons.template.setAll({
      interactive: true,
      fill: color(YELLOW),
      ...getToolTipTextProps(props),
    });
    heatMapSeries.data.processor = DataProcessor.new(root.value, {
      numericFields: [props.column.field],
    });
    heatMapSeries.set(HEAT_RULES, [
      {
        target: heatMapSeries.mapPolygons.template,
        dataField: VALUE,
        min: color(DARK_YELLOW),
        max: color(props.color),
        key: FILL,
      },
    ]);
    heatMapSeries.mapPolygons.template.states.create(HOVER, {
      fill: color(0x677935),
    });
    return {
      heatMapSeries,
    };
  }
  function loadCountryMap(props, heatMapSeries, visible, callback) {
    const countrySeries = chart.value.series.push(
      MapPolygonSeries.new(root.value, {
        name: `${props.name} ${DRILLDOWN}`,
        valueField: props.column.field,
        useGeodata: true,
        calculateAggregates: true,
        visible,
      })
    );
    countrySeries.set(HEAT_RULES, [
      {
        target: countrySeries.mapPolygons.template,
        dataField: VALUE,
        min: color(DARK_YELLOW),
        max: color(props.color),
        key: FILL,
      },
    ]);
    countrySeries.mapPolygons.template.setAll({
      interactive: true,
      fill: color(YELLOW),
      ...getToolTipTextProps(props),
    });
    countrySeries.mapPolygons.template.states.create(HOVER, {
      fill: color(0xaaaaaa),
    });
    heatMapSeries.mapPolygons.template.events.on(CLICK, (ev) => {
      const { metadata } = config.value.widgetData;
      const { geo_columns } = metadata.data_source;
      if (
        (geo_columns && !geo_columns.support_state) ||
        metadata.data_columns.grouped[0].groupby_name_field === GeoConfigurationType.COUNTRY
      ) {
        return;
      }

      const { dataItem } = ev.target;
      const { dataContext } = dataItem;
      const zoomAnimation = setZoomToCountry(dataContext.id, heatMapSeries, dataItem);
      Promise.all([zoomAnimation.waitForStop(), loadIdMap(dataContext.id)]).then((results) => {
        hideAllSeries();
        countrySeries.setAll({
          geoJSON: results[1],
        });
        const state = config.value.get(STATE);
        if (state?.isBuilding) {
          EventBus.signal(AM5_COUNTRY_DRILLDOWN, {
            map_id: dataContext.id.toLowerCase(),
          });
        }
        if (callback) {
          callback(countrySeries, dataContext.id);
        }
      });
    });
    heatMapSeries.data.setAll(setActualData());
    loadHeatLegend(heatMapSeries, props);
    return {
      countrySeries,
    };
  }

  function setCountryData(countrySeries, mapId) {
    const dataToSet = setActualData();
    let countryData = dataToSet.filter((row) => row.id === mapId);
    countryData = countryData.map((data) => {
      delete data.geometry;
      delete data.geometryType;
      delete data.name;
      return { ...data, id: data.geocode1?.toUpperCase() };
    });
    countrySeries.data.setAll(countryData);
    countrySeries.show();
  }

  /* eslint-disable tap/no-raw-text-js */
  function getToolTipTextProps({ name }) {
    return omitNils({
      tooltipText: `[bold]{name}[/]\n${name}: {value}`,
    });
  }

  function loadHeatLegend(heatMapSeries, props) {
    const heatLegend = chart.value.children.push(
      HeatLegend.new(root.value, {
        orientation: HORIZONTAL,
        startColor: color(DARK_YELLOW),
        endColor: color(props.color),
        startText: heatMapSeries.getPrivate(VALUE_LOW),
        endText: heatMapSeries.getPrivate(VALUE_HIGH),
        maxWidth: 200,
        stepCount: 5,
        paddingLeft: 2,
        paddingBottom: 5,
      })
    );
    heatLegend.startLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get(START_COLOR),
    });
    heatLegend.endLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get(END_COLOR),
    });
    heatMapSeries.events.on(DATAVALIDATED, () => {
      heatLegend.set(START_VALUE, heatMapSeries.getPrivate(VALUE_LOW));
      heatLegend.set(END_VALUE, heatMapSeries.getPrivate(VALUE_HIGH));
    });
    heatMapSeries.mapPolygons.template.events.on(POINT_OVER, (ev) => {
      heatLegend.showValue(ev.target.dataItem.get(VALUE));
    });
  }

  return {
    initHeatMap,
  };
}
