import { BaseUIService } from '@/modules/core/ui/services/BaseUIService';
import {
  ChatGptUserNames,
  ChatGptUserRoles,
} from '@/modules/ta/chatgptsummaries/chatgptsummaries.constants';

export class ChatGptSummaryRenderService extends BaseUIService {
  /**
   * @param role
   * @returns {boolean}
   */
  isUserQuery(role) {
    return role === ChatGptUserRoles.USER;
  }

  /**
   * @param role
   * @returns {boolean}
   */
  isAssistantQuery(role) {
    return role === ChatGptUserRoles.ASSISTANT;
  }

  /**
   * @param role
   * @param userId
   * @param userDetails
   * @returns {string}
   */
  getUserName(role = '', userId = null, userDetails = []) {
    let userName = ChatGptUserNames.USER;
    if (this.isUserQuery(role)) {
      if (userId && userDetails) {
        userName = `${userDetails[userId].display_name.toUpperCase()}`;
      }
    } else if (this.isAssistantQuery(role)) {
      userName = ChatGptUserNames.ASSISTANT;
    }

    return userName;
  }

  /**
   * @param index
   * @returns {boolean}
   */
  isFirstElement(index) {
    return index === 0;
  }
}

export default new ChatGptSummaryRenderService();
