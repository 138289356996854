import { WidgetType } from '@/modules/ta/widget/widget.constants';
import { LegacyBarChartWidgetRenderService } from '@/modules/core/charts/am5/bar/services/LegacyBarChartWidgetRenderService';
import { LegacyLineChartWidgetRenderService } from '@/modules/core/charts/am5/line/services/LegacyLineChartWidgetRenderService';
import { LegacyComboChartWidgetRenderService } from '@/modules/core/charts/am5/combo/services/LegacyComboChartWidgetRenderService';
import { LegacyGaugeChartWidgetRenderService } from '@/modules/core/charts/am5/gauge/services/LegacyGaugeChartWidgetRenderService';

export class WidgetRendererServiceFactory {
  static build(widget, palette, hasComparisonData, isExporting) {
    switch (widget.type) {
      case WidgetType.TYPE_BARCHART:
        return new LegacyBarChartWidgetRenderService(
          widget,
          palette,
          hasComparisonData,
          isExporting
        );
      case WidgetType.TYPE_LINECHART:
        return new LegacyLineChartWidgetRenderService(
          widget,
          palette,
          hasComparisonData,
          isExporting
        );
      case WidgetType.TYPE_COMBINATIONCHART:
        return new LegacyComboChartWidgetRenderService(
          widget,
          palette,
          hasComparisonData,
          isExporting
        );
      case WidgetType.TYPE_GAUGECHART:
        return new LegacyGaugeChartWidgetRenderService(
          widget,
          palette,
          hasComparisonData,
          isExporting
        );
      default:
        // eslint-disable-next-line tap/no-raw-text-js
        Logger.log('Unsupported widget type', Logger.LEVEL_ERROR);
    }
  }
}
