'use strict';
import angular from 'angular';

import './base/daterange.constants';
import './base/daterange.services';
import './widget/widget.daterange.ctrls';
import './widget/widget.daterange.services';
import './widget/widget.daterange.directives';

angular.module('core.daterange', [
    'daterange.constants',
    'daterange.services',
    'widget.daterange.services',
    'widget.daterange.ctrls',
    'widget.daterange.directives'
]);