'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

import exportBuilderPresentationCanvasHtmlUrl from './exportbuilder.presentationcanvas.html';

angular.module('exportbuilder.dashboard.directives', [])
    .directive('exportBuilderDashboardDirective', exportBuilderDashboardDirective)
    .directive('exportBuilderPresentationCanvasDirective', exportBuilderPresentationCanvasDirective);

function exportBuilderDashboardDirective(
    $document,
    $timeout,
    PageLayerPanel,
    ExportBuilderDashboardService,
    $ExportBuilderDashboardEvents,
    $ExportBuilderItemDirectiveEvents,
    ExportBuilderFacadeUIService,
    WindowUtilUIService,
    PubSub
) {
    return {
        link: function ($scope, $el) {
            var isMultiSelectActive = false;
            var _canTriggerMultiSelect = false;
            var _originalEvent = null;
            var _mouseDown = false;
            var _selection;
            var _currentQuadrant;
            var $multiSelect = $('#multi-select-elements');

            _registerEvents();

            $scope.$on('$destroy', function () {
                _unregisterEvents();
            });

            function _onShiftClicked(event) {
                if (event.keyCode === 16) {
                    ExportBuilderFacadeUIService.setIsPressingShift(event.type === 'keydown');
                }
                ExportBuilderFacadeUIService.setIsPressingCMD((event.ctrlKey || event.metaKey) && event.type === 'keydown');
            }

            function _onVisibilityChange(visible) {
                ExportBuilderFacadeUIService.setIsPressingCMD(false);
            }

            //deselect current item when clicking somewhere else
            function _onReportStudioAreaClicked(event) {
                if (ExportBuilderFacadeUIService.getState().isResizing
                    || !ExportBuilderDashboardService.getBuilder().elements.length
                    || isMultiSelectActive) {
                    return;
                }

                _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
                    var $element = $('#export-builder-element-' + element.id);
                    var elementPosition = $element.position();

                    var position = {
                        x: elementPosition.left,
                        y: elementPosition.top,
                        width: $element.width(),
                        height: $element.height()
                    };

                    // check intersection
                    if (!(event.offsetX > position.x
                        && event.offsetX <= (position.x + position.width)
                        && event.offsetY > position.y
                        && event.offsetY <= (position.y + position.height))) {
                        ExportBuilderDashboardService.focusOnItem(null);
                        return false;
                    }
                })
            }

            function _registerEvents() {
                PubSub.on($ExportBuilderItemDirectiveEvents.IS_RESIZING, _onItemResize);
                PubSub.on($ExportBuilderDashboardEvents.ZOOM_CALCULATED, _onZoomCalculated);
                $el.bind('click', _onReportStudioAreaClicked);
                $el.bind('mousedown', _setUpMultiSelect);
                $('export-builder-dashboard').bind('mousemove', _setMouseMove);
                $('export-builder-dashboard').bind('mouseup', _cleanUpMultiSelect);
                $document.bind('keyup keydown', _onShiftClicked);
                WindowUtilUIService.onVisibilityChanged(_onVisibilityChange);
            }

            function _unregisterEvents() {
                PubSub.off($ExportBuilderItemDirectiveEvents.IS_RESIZING, _onItemResize);
                PubSub.off($ExportBuilderDashboardEvents.ZOOM_CALCULATED, _onZoomCalculated);
                $el.unbind('click', _onReportStudioAreaClicked);
                $el.unbind('mousedown', _setUpMultiSelect);
                $('export-builder-dashboard').unbind('mousemove', _setMouseMove);
                $('export-builder-dashboard').unbind('mouseup', _cleanUpMultiSelect);
                $document.bind('keyup keydown', _onShiftClicked);
                WindowUtilUIService.offVisibilityChanged(_onVisibilityChange);
            }

            function _onItemResize(isResizing) {
                // by setting an empty string, this removes the inline style
                $('export-builder-dashboard').css('overflow', isResizing ? 'hidden' : '');
            }

            function _onZoomCalculated() {
                var builder = ExportBuilderDashboardService.getBuilder();

                var $dashboard = $('div.exportbuilder-dashboard');
                $dashboard.css('margin-top', builder.marginTop+'px');
                $dashboard.css('margin-bottom', builder.marginTop+'px');

                var zoom = builder.zoom / 100;
                $dashboard.find('.page').css('transform', 'scale('+zoom+')');

                if (builder.fitToPage) {
                    $dashboard.css('overflow', 'initial');
                } else {
                    $dashboard.css('overflow', 'hidden');
                }
            }

            function _setUpMultiSelect(event) {
                if (event.which !== 1) {return} // left mouse click
                if (isMultiSelectActive) { return; }
                _originalEvent = event;
                _mouseDown = true;
            }

            function _setMouseMove(event) {
                if (_mouseDown && !_canTriggerMultiSelect) {
                    if (_calculateMinimumTolerance(event)) {
                        _canTriggerMultiSelect = true;
                    }
                }

                if (_canTriggerMultiSelect && !isMultiSelectActive) {
                    isMultiSelectActive = true;
                    $('body').css('cursor', 'crosshair');
                    _selection = {x: event.clientX, y: event.clientY - 84};

                    $multiSelect.css('display', 'initial');

                    $multiSelect.css({
                        'left': _selection.x,
                        'top': _selection.y,
                        'width': 0,
                        'height': 0
                    });
                } else if (!isMultiSelectActive) {
                    return;
                }

                _selection.width = event.clientX - _selection.x;
                _selection.height = event.clientY - _selection.y - 84;

                /**
                 * Quadrants
                 *           |
                 *      Q1   |    Q2
                 *           |
                 * __________|__________
                 *           |
                 *      Q3   |    Q4
                 *           |
                 */

                // Q4
                if (_selection.width >= 0 &&  _selection.height >= 0) {
                    _validatePositionForQuadrant('q4');
                    $multiSelect.css({
                        'width': _selection.width,
                        'height': _selection.height
                    });
                    // Q2
                } else if (_selection.width >= 0 && _selection.height <= 0) {
                    _validatePositionForQuadrant('q2');
                    $multiSelect.css({
                        'width': _selection.width,
                        'top': _selection.y - Math.abs(_selection.height),
                        'height': Math.abs(_selection.height)
                    });
                    // Q3
                } else if (_selection.width <= 0 && _selection.height >= 0) {
                    _validatePositionForQuadrant('q3');
                    $multiSelect.css({
                        'height': _selection.height,
                        'left': _selection.x - Math.abs(_selection.width),
                        'width': Math.abs(_selection.width)
                    })

                    // Q1
                } else if (_selection.width <= 0 && _selection.height <= 0) {
                    _validatePositionForQuadrant('q1');
                    $multiSelect.css({
                        'height': Math.abs(_selection.height),
                        'top': _selection.y - Math.abs(_selection.height),
                        'left': _selection.x - Math.abs(_selection.width),
                        'width': Math.abs(_selection.width)
                    })
                }

                function _validatePositionForQuadrant(quadrant) {
                    if (_currentQuadrant !== quadrant) {
                        $multiSelect.css({
                            'left': _selection.x,
                            'top': _selection.y
                        })
                    }
                    _currentQuadrant = quadrant;
                }
            }

            function _cleanUpMultiSelect(event) {
                _canTriggerMultiSelect = false;
                _mouseDown = false;
                if (!isMultiSelectActive) {return}
                $('body').css('cursor', '');

                event.preventDefault();
                event.stopPropagation();

                var multiSelectRect = $multiSelect[0].getBoundingClientRect();
                _selection.y = multiSelectRect.y;
                _selection.x = multiSelectRect.x;
                _selection.width = multiSelectRect.width;
                _selection.height = multiSelectRect.height;

                $multiSelect.css('display', 'none');

                _insertsectElements();

                $timeout(function () {
                    _canTriggerMultiSelect = false;
                    isMultiSelectActive = false;
                    _mouseDown = false;
                }, 0);
            }

            function _calculateMinimumTolerance(event) {
                return Math.sqrt(Math.pow(_originalEvent.clientX - event.clientX, 2) + Math.pow(_originalEvent.clientY - event.clientY, 2)) > 10;
            }

            function _insertsectElements() {
                var intersectedElements = [];
                _.each(ExportBuilderDashboardService.getBuilder().currentPage.elements, function (element) {

                    if (element.metadata.set_as_background) {
                        return;
                    }
                    var $element = document.querySelector('#export-builder-element-' + element.id);
                    var elementClientRect = $element.getBoundingClientRect();

                    if (_selection.x < elementClientRect.x + elementClientRect.width
                        && _selection.x + _selection.width > elementClientRect.x
                        && _selection.y < elementClientRect.y + elementClientRect.height
                        && _selection.y + _selection.height > elementClientRect.y) {
                        intersectedElements.push(element);
                    }
                });

                if (intersectedElements.length) {
                    $timeout(function () {
                        ExportBuilderFacadeUIService.setFocusToElements(intersectedElements);
                    });
                }
            }
        }
    }
}

/**
 * @ngInject
 */
function exportBuilderPresentationCanvasDirective(
    UIFactory,
    ExportBuilderDashboardService,
    UIColor
) {
    return {
        templateUrl: exportBuilderPresentationCanvasHtmlUrl,
        link: function () {
            var isPainting = false;
            var canvas = document.querySelector('#presentation-canvas');
            var canvasContext = canvas.getContext('2d');

            var sketch = document.querySelector('#sketch');

            canvas.width = screen.width;
            canvas.height = screen.height;

            // Creating a tmp canvas
            var canvasTemp = document.createElement('canvas');
            var contextTemp = canvasTemp.getContext('2d');
            canvasTemp.id = 'tmp_canvas';
            canvasTemp.width = canvas.width;
            canvasTemp.height = canvas.height;

            sketch.appendChild(canvasTemp);

            var mouse = {x: 0, y: 0};
            var last_mouse = {x: 0, y: 0};

            var pencilPoints = [];

            canvasTemp.addEventListener('mousemove', function (e) {
                mouse.x = typeof e.offsetX !== 'undefined' ? e.offsetX : e.layerX;
                mouse.y = typeof e.offsetY !== 'undefined' ? e.offsetY : e.layerY;
                _adjustMousePosition(mouse);
            }, false);

            var color1 = ExportBuilderDashboardService.getReport().metadata.chart_palette[0];

            var shadowColor = UIColor.hexToRGBA(UIColor.getComplementColor(color1));
            var color = UIColor.hexToRGBA(UIColor.getComplementColor(color1), 0.8);

            contextTemp.lineWidth = 10;
            contextTemp.lineJoin = 'round';
            contextTemp.lineCap = 'round';
            contextTemp.strokeStyle = color;
            contextTemp.fillStyle = color;
            contextTemp.shadowOffsetX = 0;
            contextTemp.shadowOffsetY = 0;
            contextTemp.shadowBlur = 2;
            contextTemp.shadowColor = shadowColor;

            canvasTemp.addEventListener('mousedown', function (e) {
                canvasTemp.addEventListener('mousemove', onPaint, false);

                mouse.x = typeof e.offsetX !== 'undefined' ? e.offsetX : e.layerX;
                mouse.y = typeof e.offsetY !== 'undefined' ? e.offsetY : e.layerY;

                _adjustMousePosition(mouse);

                pencilPoints.push({x: mouse.x, y: mouse.y});

                onPaint();
            }, false);

            canvasTemp.addEventListener('mouseup', function () {
                isPainting = false;
                canvasTemp.removeEventListener('mousemove', onPaint, false);

                // Writing down to real canvas now
                canvasContext.drawImage(canvasTemp, 0, 0);
                // Clearing tmp canvas
                contextTemp.clearRect(0, 0, canvasTemp.width, canvasTemp.height);

                // Emptying up Pencil Points
                pencilPoints = [];

                clearCanvas();
            }, false);

            var onPaint = function () {
                isPainting = true;

                pencilPoints.push({x: mouse.x, y: mouse.y});

                if (pencilPoints.length < 3) {
                    var b = pencilPoints[0];
                    contextTemp.beginPath();
                    contextTemp.arc(b.x, b.y, contextTemp.lineWidth / 2, 0, Math.PI * 2, !0);
                    contextTemp.fill();
                    contextTemp.closePath();

                    return;
                }

                // Tmp canvas is always cleared up before drawing.
                contextTemp.clearRect(0, 0, canvasTemp.width, canvasTemp.height);

                contextTemp.beginPath();
                contextTemp.moveTo(pencilPoints[0].x, pencilPoints[0].y);

                for (var i = 1; i < pencilPoints.length - 2; i++) {
                    var c = (pencilPoints[i].x + pencilPoints[i + 1].x) / 2;
                    var d = (pencilPoints[i].y + pencilPoints[i + 1].y) / 2;

                    contextTemp.quadraticCurveTo(pencilPoints[i].x, pencilPoints[i].y, c, d);
                }

                // For the last 2 points
                contextTemp.quadraticCurveTo(
                    pencilPoints[i].x,
                    pencilPoints[i].y,
                    pencilPoints[i + 1].x,
                    pencilPoints[i + 1].y
                );
                contextTemp.stroke();
            };

            function fadeOut() {
                if (isPainting) return;

                var tmp_fade_away_canvas = document.createElement('canvas');
                var tmp_fade_away_canvas_ctx = tmp_fade_away_canvas.getContext('2d');
                tmp_fade_away_canvas.id = 'tmp_fade_away_canvas';
                tmp_fade_away_canvas.width = canvas.width;
                tmp_fade_away_canvas.height = canvas.height;

                sketch.insertBefore(tmp_fade_away_canvas, canvas);

                tmp_fade_away_canvas_ctx.drawImage(canvas, 0, 0);
                canvasContext.clearRect(0, 0, canvas.width, canvas.height);

                $(tmp_fade_away_canvas).animate({
                    opacity: 0
                }, 1000, function () {
                    $(tmp_fade_away_canvas).remove();
                })
            }

            var clearDebounce;

            function clearCanvas() {
                if (!clearDebounce) {
                    clearDebounce = _.debounce(fadeOut, 1000);
                }
                clearDebounce();
            }

            function _adjustMousePosition(mouse) {
                mouse.y += 38; // pen image height is 40 pixels;
                mouse.x += 2; // offset
            }
        }
    }
}