'use strict';
import angular from 'angular';
import createExecutiveSummaryHtmlUrl from './create.executivesummary.html';
import _ from 'lodash';

angular.module('create.executivesummary.components', [])

    .component('createExecutiveSummary', {
        templateUrl: createExecutiveSummaryHtmlUrl,
        bindings: {
            itemId: '<'
        },
        controller: CreateExecutiveSummaryController,
        controllerAs: 'vm'
    });


/*
 *
 * @ngInit
 */
function CreateExecutiveSummaryController(
    ExecutiveSummaryFactory,
    UIExecutiveSummaryModalFactory,
    $ExecutiveSummaryEvents,
    $scope,
    PubSub,
    AppFactory,
    DataSourceType
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.hasEntity = hasEntity;
    vm.initModal = initModal;
    registerEvents();

    function $onInit() {
        vm.summary = ExecutiveSummaryFactory.getExecutiveSummaryItemById(vm.itemId);
        vm.startDateDisplay = ExecutiveSummaryFactory.formatSummaryDate(vm.summary.start_date);
        vm.endDateDisplay = ExecutiveSummaryFactory.formatSummaryDate(vm.summary.end_date);

        if (hasEntity() && vm.summary.entity.type) {
            var brandMappings = AppFactory.getBrandMappings();
            switch (vm.summary.entity.type) {
                case (DataSourceType.CLIENT):
                    vm.summary.entity.type_name = brandMappings.client;
                    break;
                case (DataSourceType.CLIENT_GROUP):
                    vm.summary.entity.type_name = brandMappings.client_group;
                    break;
            }
        }
    }

    function $reInit() {
        $scope.$evalAsync(function() {
            $onInit();
        });
    }

    function $onDestroy() {
        PubSub.off($ExecutiveSummaryEvents.UPDATE_ITEMS + vm.itemId, $reInit);
        PubSub.off($ExecutiveSummaryEvents.UPDATE_ITEMS, $reInit);
    }

    function hasEntity() {
        return !_.isEmpty(vm.summary.entity);
    }

    function initModal(id) {
        return UIExecutiveSummaryModalFactory.initModal(id);
    }


    function registerEvents() {
        PubSub.on($ExecutiveSummaryEvents.UPDATE_ITEMS + vm.itemId, $reInit);
        PubSub.on($ExecutiveSummaryEvents.UPDATE_ITEMS, $reInit);
    }

}