'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import widgetGoalHtmlUrl from './widget.goal.html';
import goalWidgetOptionsHtml from './goal.widget.options.html';
import { ColumnFormat } from "coreModules/shared/scripts/app.constants";

angular.module('widget.goal.components', [])
    .component('goalWidget', {
        templateUrl: widgetGoalHtmlUrl,
        bindings: {
            widget: '<',
            state: '=',
            chartId: '<',
            widgetTypeId: '<',
            widgetIsPredefined: '<'
        },
        controllerAs: 'vm',
        controller: GoalWidgetController
    })
    .component('goalChartWidget', {
        bindings: {
            metadata: '<',
            state: '=',
            chartId: '<',
            widgetTypeId: '<',
            widgetIsPredefined: '<'
        },
        controller: GoalChartWidgetController
    })
    .component('goalWidgetOptions', {
        templateUrl: goalWidgetOptionsHtml,
        bindings: {
            state: '=',
            widget: '<'
        },
        controllerAs: 'vm',
        controller: GoalWidgetOptionsController
    });

/**
 * @ngInject
 */
function GoalWidgetController(
    $scope,
    $timeout,
    PubSub,
    $GoalsEvents,
    $WidgetEvents,
    $WidgetHeaderEvents,
    GoalsService,
    LoadingState,
    WidgetGoalModelFactory,
    DashboardContextService,
    WidgetResizeService
) {
    let vm = this;
    const collapsedHeight = 3.2;
    const expandedHeight = 8;
    let widget = vm.widget;

    /**
     *
     * @type {GoalWidget}
     */
    vm.goalWidget = WidgetGoalModelFactory.getGoalWidget(widget.metadata.dynamic.predefined_data.data[0]);

    /**
     * @type {GoalHistoryWidget}
     */
    vm.historyWidget = WidgetGoalModelFactory.getGoalHistoryWidget(widget);
    vm.widgetState = angular.copy(vm.state);

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getDisplayColumns = getDisplayColumns;
    vm.getColumnValue = getColumnValue;
    vm.setDisplayDetailView = setDisplayDetailView;
    vm.displayDetailView = false;
    vm.displayColumns = getDisplayColumns();
    vm.getTooltip = getTooltip;

    function getDisplayColumns() {
        let displayColumnsArr = ['campaign_name', 'column_label', 'target', 'cycle_start', 'cycle_end', 'pace'];

        if (vm.goalWidget.client_name) {
            displayColumnsArr.push('client_name');
        } else if (vm.goalWidget.client_group_name) {
            displayColumnsArr.push('client_group_name');
        } else if (vm.goalWidget.cluster_name) {
            displayColumnsArr.push('cluster_name');
        }

        displayColumnsArr = _.filter(widget.metadata.data_columns.selected, function(column) {
            if (column.field === 'campaign_name') {
                return vm.goalWidget.campaign_name;
            }
            return displayColumnsArr.indexOf(column.field) > -1;
        });

        let tap_orders_entity_column = vm.goalWidget.getTapOrdersEntityColumn();

        if (tap_orders_entity_column) {
            displayColumnsArr.push(tap_orders_entity_column);
        }

        return displayColumnsArr;
    }

    function getColumnValue(column) {
        if (column.field === 'campaign_name' && _.isArray(vm.goalWidget[column.field])) {
            if (vm.goalWidget[column.field].length > 1) {
                return "Multiple Campaigns";
            } else {
                return vm.goalWidget[column.field][0].name;
            }
        }
        if (column.field === 'cycle_start' || column.field === 'cycle_end') {
            column.format = ColumnFormat.FORMAT_DATETIME;
        }
        return vm.goalWidget.formatColumn(column);
    }

    /**
     *
     * @param column
     * @returns {null|*}
     */
    function getTooltip(column) {
        if (column.field === 'campaign_name' && _.isArray(vm.goalWidget[column.field]) && vm.goalWidget[column.field].length > 1) {
            return vm.goalWidget[column.field].map(value => value.name).join(" , ");
        }
        return null;
    }


    /**
     *
     * @param displaySize
     */
    function setDisplayDetailView(displaySize = null) {
        vm.displayDetailView = displaySize === null ? !vm.displayDetailView : displaySize;
        if (vm.displayDetailView) {
            WidgetResizeService.resizeWidget(vm.widget.id, 12, expandedHeight);
        } else if (!vm.displayDetailView) {
            WidgetResizeService.resizeWidget(vm.widget.id, 12, collapsedHeight);
        }
    }

    /**
     * Build GOAL widget
     */
    function buildGoal(isRebuild) {
        let isPreview = vm.state && vm.state.isPreview;
        if (!_.isEmpty(widget.metadata.dynamic) && !_.isEmpty(widget.metadata.dynamic.predefined_data)) {

            if (isRebuild || widget.metadata.is_new) {
                vm.goalWidget = WidgetGoalModelFactory.getGoalWidget(widget.metadata.dynamic.predefined_data.data[0]);
                vm.historyWidget = WidgetGoalModelFactory.getGoalHistoryWidget(widget);
            } else {
                vm.widget.title = GoalsService.getTitle(vm.goalWidget);
            }
            PubSub.emit($WidgetHeaderEvents.REFRESH_TITLE + vm.chartId);
            vm.widget.name = vm.goalWidget.name;
            if ((vm.state && vm.state.canSave) || !isPreview) {
                vm.state.loadingState = LoadingState.DONE;
            }
            setDisplayDetailView(false);
        }
    }

    /**
     * Handles if and how we should rebuild the goal widget
     */
    function handleRebuild() {
        let currentWidget = DashboardContextService.resolveWidgetFromId(vm.chartId);
        _.assign(widget, currentWidget);
        vm.historyWidget = WidgetGoalModelFactory.getGoalHistoryWidget(widget);
        vm.state.loadingState = LoadingState.DONE;

        buildGoal(true);
    }

    function $onInit() {
        // INIT
        registerEvents();
        buildGoal();
    }

    var $widgetRebuildFn;
    function registerEvents() {
        $widgetRebuildFn = $scope.$on($WidgetEvents.WIDGET_REBUILD, handleRebuild);
        PubSub.on($GoalsEvents.RESIZE, setDisplayDetailView);
    }

    function $onDestroy() {
        $widgetRebuildFn();
        PubSub.off($GoalsEvents.RESIZE, setDisplayDetailView);
    }
}

/**
 * @ngInject
 */
function GoalChartWidgetController() {

}

/**
 * @ngInject
 */
function GoalWidgetOptionsController(
    $timeout,
    PubSub,
    GoalsService,
    GoalsUIService,
    LoadingState,
    LayoutFactory,
    WidgetFactory,
    WidgetCreateFactory,
    UIFactory
) {
    let vm = this;

    vm.LoadingState = LoadingState;

    vm.canIncludeEditOption = canIncludeEditOption;
    vm.editWidget = editWidget;
    vm.canIncludeDeleteOption = canIncludeDeleteOption;
    vm.confirmWidgetDelete = confirmWidgetDelete;

    
    /**
     * Resolves ng-if for edit-widget
     * @returns {boolean}
     */
    function canIncludeEditOption() {
        return vm.widget.can_be_edited;
    }

    function editWidget() {
        GoalsUIService.initModal(_.first(vm.widget.metadata.dynamic.predefined_data.data));
    }

    /**
     * @returns {boolean}
     */
    function canIncludeDeleteOption() {
        return vm.widget.can_be_edited;
    }

    function confirmWidgetDelete() {
        let options = {};
        options.text = 'This will remove the goal for all users.';
        options.callback = function() {
            let widget = vm.widget;
            GoalsService.remove(widget.id).then(function() {
                swal.close();
                UIFactory.notify.showSuccess('Goal successfully deleted');
                if (window.isNUI) {
                    PubSub.emit("SegmentEvents", {
                        event: "GoalDeleteEvent",
                        payload: {goal: vm.widget}
                    });
                }
                $timeout(function() {
                    LayoutFactory.removeWidget(widget);
                }, 600);
            }, function() {
                swal.enableButtons();
            });
        };

        UIFactory.confirmDelete(options);
    }

}