import { DateRange } from '@/modules/core/daterange/models/DateRange';
import DateRangeResource from '@/modules/core/daterange/resources/DateRangeResource';
import { getters } from '@/modules/core/app/helpers/store';
import { MomentDateFormat } from '@/modules/core/daterange/daterange.constants';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';
import { DashboardEventType } from '@/modules/ta/dashboard/dashboard.constants';

export class DateRangeService {
  /**
   * @returns {Promise<*>}
   */
  async getDateRange() {
    return DateRangeResource.getData(DateRange);
  }

  /**
   * @param {DateRange} dateRange
   * @returns {Promise<*>}
   */
  async setDateRange(dateRange) {
    if (window.$angularEvent)
      try {
        window.$angularEvent(DashboardEventType.EDIT_DATE_RANGE, dateRange);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    return DateRangeResource.postData(dateRange);
  }

  /**
   * @returns {Promise<*>}
   */
  async getRelativeDateRanges() {
    return DateRangeResource.getRelativeDateRanges();
  }

  /**
   * Builds a query string for datarange query param in ISO (ex: ?daterange=2014-01-01|2015-12-12)
   * @param {DateRange} dateRange
   * @returns {string}
   */
  buildDateQueryParam(dateRange = null) {
    dateRange = dateRange || getters.daterange.getDateRange();

    const startDate = this._formatDateRange(dateRange.start_date);
    const endDate = this._formatDateRange(dateRange.end_date);

    let dateQuery = `${startDate}|${endDate}`;

    if (dateRange.is_comparison_active) {
      const comparisonStartDate = this._formatDateRange(dateRange.comparison_start_date);
      const comparisonEndDate = this._formatDateRange(dateRange.comparison_end_date);
      dateQuery += `,${comparisonStartDate}|${comparisonEndDate}`;
    }

    return dateQuery;
  }

  /**
   * @param value
   * @returns {string}
   * @private
   */
  _formatDateRange(value) {
    return DateUtil.formatDate(value, MomentDateFormat.ISO);
  }
}

export default new DateRangeService();
