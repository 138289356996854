"use strict";
import $ from 'jquery';
import _ from 'lodash';

var Core = $.core.reporting.scheduledReporting = {
    initialize: function (firstDayOfWeek = 0) {
        this.build();
        this.events(firstDayOfWeek);
    },
    build: function () {
        $.core.main.dynamicForm($('#main-form'), false, true);

        $('#view-short-code-details')
            .popover({
                container: 'body',
                placement: 'auto top',
                html: true,
                trigger: 'click'
            })
            .click(function(e) {
                e.stopPropagation();
            });
    },
    events: function (firstDayOfWeek) {
        $('#submitForm').click(function(e) {
            $('#mainForm').submit();

        });

        var datePickerOptions = {onSelect: function(dateText, inst){
                update_upcoming_scheduled_report_sends();
                $(this).trigger('textchange');
            }};

        datePickerOptions.dateFormat = "yy-mm-dd";
        datePickerOptions.firstDay = firstDayOfWeek;
        $.core.main.initDatePicker($('#bi_weekly_start_date'), datePickerOptions);
        $.core.main.initDatePicker($('#send_on_date'), datePickerOptions);
        $.core.main.initDatePicker($('#send_until_date'), datePickerOptions);
        $.core.main.initDatePicker($('#data_start_date'), datePickerOptions);
        $.core.main.initDatePicker($('#data_start_date_custom_date'), datePickerOptions);
        $.core.main.initDatePicker($('#data_end_date_custom_date'), datePickerOptions);

        var $original_data_date_range_option = undefined;
        var $original_data_date_range_preset = '';

        /*
        @GG TODO: prevent the double submit TA-2189
        $('#main-form').keypress(function(e) {
            var code = e.keyCode || e.which;
            if (code  == 13) {
                e.preventDefault();
                return false;
            }
        });
        */

        $('#compare_to_previous_period').change(function(){
            if($(this).is(':checked')) {
                $('#group_by_month').disableElement();
                $('#data_start_date').disableElement();
            }
            else {
                $('#group_by_month').enableElement();
                $('#data_start_date').enableElement();
            }
        });


        $('#compare_to_previous_period').change();

        $('input[name=data_date_range]').change(function(){
            $('#custom_data_date_range').toggle(($('input[name=data_date_range]:checked').val() == 'custom'));
        });

        $('input[name=frequency]').change(function(){

            update_custom_data_date_range_display();

            //only update the data range data if this is a new scheduled report and the user has not changed the date range to something other than the default value for his report frequency
            if ($original_data_date_range_option == $('input[name=data_date_range]:checked').val() && $original_data_date_range_preset == $('#data_date_range_preset').val() && $('input[name=scheduled_report_id]').val() == '')
            {
                var current_frequency =  $('input[name=frequency]:checked').val();

                if (current_frequency == 'daily')
                {
                    $('#data_date_range_option_preset').prop('checked', true);
                    $('#data_date_range_preset').val('yesterday');
                }
                else if (current_frequency == 'weekly')
                {
                    $('#data_date_range_option_preset').prop('checked', true);
                    $('#data_date_range_preset').val('last7days');
                }
                else if (current_frequency == 'bi_weekly')
                {
                    $('#data_date_range_option_preset').prop('checked', true);
                    $('#data_date_range_preset').val('last14days');
                }
                else if (current_frequency == 'monthly')
                {
                    $('#data_date_range_option_preset').prop('checked', true);
                    $('#data_date_range_preset').val('lastmonth');
                }
                else if (current_frequency == 'quarterly')
                {
                    $('#data_date_range_option_preset').prop('checked', true);
                    $('#data_date_range_preset').val('lastquarter');
                }

                $original_data_date_range_option = $('input[name=data_date_range]:checked').val();
                $original_data_date_range_preset = $('#data_date_range_preset').val();
            }

        });

        $('#data_date_range_option_custom').trigger('change');

        function update_upcoming_scheduled_report_sends()
        {
            $('#upcomingScheduledReportSends').html('<em>Updating...</em>');
            $.post('/app/dash/reporting/get_next_send_dates',
                $('#main-form').serialize(),
                function(json)
                {
                    var data = JSON.parse(json);
                    var $table = $('#upcoming-scheduled-table');
                    var $same_day_warning = $('#upcoming-scheduled-same-day-warning');
                    var $warning = $('#upcoming-scheduled-warning');

                    if (!_.isEmpty(data.datatable)) {
                        $table.show();
                        $table.find('tbody').html(data.datatable);
                    }
                    else
                    {
                        $table.hide();
                    }

                    if (_.isNull(data.same_day_warning)) {
                        $same_day_warning.hide();
                    }
                    else {
                        $same_day_warning.show();
                        $same_day_warning.html(data.same_day_warning);
                    }

                    if (_.isNull(data.warning)) {
                        $warning.hide();
                    }
                    else {
                        $warning.show();
                        $warning.html(data.warning);
                    }

                });
        }

        function update_custom_data_date_range_display()
        {
            var is_semi_monthly_report = ($('input[name=frequency]:checked').val() == 'semi_monthly');
            $('#date_range_config_options').toggle(!is_semi_monthly_report);
            $('#date_range_config_semi_monthly').toggle(is_semi_monthly_report);
        }

        update_custom_data_date_range_display();

        $('.update-send-dates').change(update_upcoming_scheduled_report_sends);
        update_upcoming_scheduled_report_sends();

    }
};