export class SettingsObject {
  /**
   * @private
   */
  _settings;

  /**
   * @private
   */
  _watchers;

  constructor() {
    this._settings = {};
    this._watchers = {};
  }

  /**
   * @param key {string}
   * @param newValue {any}
   */
  set(key, newValue) {
    const oldValue = this._settings[key];
    this._settings[key] = newValue;
    if (this._watchers[key]) {
      this._watchers[key].forEach((callback) => callback(newValue, oldValue));
    }
  }

  /**
   * @param props {object}
   */
  setAll(props) {
    Object.keys(props).forEach((key) => {
      this.set(key, props[key]);
    });
  }

  /**
   * @param key {string}
   * @returns {*}
   */
  get(key) {
    return this._settings[key];
  }

  /**
   * @param key {string}
   * @param callback {function}
   * @returns {{stopWatch(): void}}
   */
  watch(key, callback) {
    if (!Array.isArray(this._watchers[key])) {
      this._watchers[key] = [];
    }
    this._watchers[key].push(callback);
    return () => {
      const index = this._watchers.findIndex(callback);
      if (index === -1) {
        return;
      }
      this._watchers[key].splice(index, 1);
    };
  }
}
