import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('namingconventionrule.model.services', [])
    .factory('NamingConventionsModelFactory', NamingConventionsModelFactory);

angular.module('namingconventionrule.services', [
    'namingconventionrule.model.services'
])
    .constant('NamingConventions', NamingConventions())
    .constant('UINamingConventionModalEvents', UINamingConventionModalEvents())
    .factory('UINamingConventionModalFactory', UINamingConventionModalFactory)
    .factory('NamingConventionRuleFactory', NamingConventionRuleFactory);

function NamingConventions() {
    return {
        TYPES: {
            IDENTIFIER: 'identifier',
            DELIMITER: 'delimiter',
            SPECIAL: 'special',
            CUSTOM_DELIMITER: 'custom_delimiter'
        }
    }
}

function UINamingConventionModalEvents() {
    return {
        IS_ACTIVE: 'UINamingConventionModalFactory:isActive',
        RULE_ITEMS_CHANGE: 'UINamingConventionModalFactory:ruleItems'
    }
}
/**
 * Handles the create/edit naming convention modal
 * @ngInject
 */
function UINamingConventionModalFactory(
    UINamingConventionModalEvents,
    PubSub
) {
    var defaultProps = {
        namingConvention: null,
        state: {
            isActive: false
        }
    };
    var props = angular.copy(defaultProps);

    return {
        setNamingConventionId: setNamingConventionId,
        getNamingConventionId: getNamingConventionId,
        setIsActive: setIsActive,
        getIsActive: getIsActive,

        onIsActive: onIsActive,
        onRuleItemsChange: onRuleItemsChange,
        didChangeNamingConventionParserRuleItems: didChangeNamingConventionParserRuleItems,
        off: off
    };

    /**
     * Set this service active by assigning a naming convention data.
     * Emits isActive event in order to presentation layer to display correct component UI
     * @param namingConvention
     */
    function setNamingConventionId(namingConventionId) {
        props.namingConventionId = namingConventionId;
        setIsActive(true);
    }

    function getNamingConventionId() {
        return props.namingConventionId;
    }

    /**
     * Setter for isActive prop
     * @param val {Boolean}
     */
    function setIsActive(val) {
        props.state.isActive = val;
        if (!props.state.isActive) {
            props.namingConventionId = null;
            //TODO: @dannyyassine is there a better way not to have jquery here?
            $('.modal').modal('hide');
        }
        PubSub.emit(UINamingConventionModalEvents.IS_ACTIVE, props.state.isActive);
    }

    function getIsActive() {
        return props.state.isActive
    }

    function onIsActive(cb) {
        PubSub.on(UINamingConventionModalEvents.IS_ACTIVE, cb);
    }
    function onRuleItemsChange(cb) {
        PubSub.on(UINamingConventionModalEvents.RULE_ITEMS_CHANGE, cb);
    }
    function didChangeNamingConventionParserRuleItems(ruleItems) {
        PubSub.emit(UINamingConventionModalEvents.RULE_ITEMS_CHANGE, ruleItems);
    }

    function off(cb) {
        _.forEach(UINamingConventionModalEvents, function (event) {
            PubSub.off(event[0], cb);
        });
    }
}

/**
 * @ngInject
 */
function NamingConventionRuleFactory(
    $q,
    NamingConventionsRuleResource
) {
    var defaultProps = {
        state: {},
        data: {}
    };
    var props = angular.copy(defaultProps);

    return {
        // use cases
        retrieveNamingConvention: retrieveNamingConvention,
        retrieveRuleItems: retrieveRuleItems,
        createDelimiterRule: createDelimiterRule,
        removeCustomDelimiter: removeCustomDelimiter,
        saveRule: saveRule,
        clearAllRuleItems: clearAllRuleItems
    };

    /**
     * Retrieves the available rule items to be used to create/update a naming convention
     * @return {Promise} RuleItemsCollection
     */
    function retrieveRuleItems(namingConventionId) {
        return NamingConventionsRuleResource.getRuleItems(namingConventionId).then(function (ruleItems) {
            ruleItems = _.map(ruleItems, function (ruleItem) {
                return new RuleItem(ruleItem);
            });
            return new RuleItemsCollection(ruleItems);
        });
    }

    /**
     * Creates a new delimiter rule
     * @param {String} text String to use for title & character props
     * @param {NamingConvention} namingConvention
     * @return {RuleItem} ruleItem
     */
    function createDelimiterRule(text, namingConvention) {
        var deferred = $q.defer();

        try {
            _validateDelimiterRuleName(text);
        } catch (error) {
            deferred.reject(error.msg);
            return deferred.promise;
        }

        var ruleItem = new RuleItem();
        ruleItem.type = NamingConventions().TYPES.CUSTOM_DELIMITER;
        ruleItem.label = 'Custom: ' + text;
        ruleItem.text = text;

        if (namingConvention.id) {
            ruleItem.naming_convention_id = namingConvention.id;
            NamingConventionsRuleResource.saveCustomDelimiter(ruleItem).then(function (response) {
                ruleItem.id = response.id;
                namingConvention.addCustomDelimiters.push(ruleItem);
                deferred.resolve(ruleItem);
            });
        } else {
            namingConvention.addCustomDelimiters.push(ruleItem);
            deferred.resolve(ruleItem);
        }

        return deferred.promise;
    }

    /**
     * Removes a custom delimiter from the available rule items of this naming convention
     * @param delimiter
     */
    function removeCustomDelimiter(delimiter) {
        if (delimiter.id) {
            NamingConventionsRuleResource.deleteCustomDelimiter(delimiter);
        }
    }

    /**
     * Creates or updates a naming convention if id property is present
     * @param namingConvention
     * @returns {Promise} (NamingConvention, Error)
     */
    function saveRule(namingConvention) {
        var deferred = $q.defer();

        try {
            _validateName(namingConvention);
            _validateSampleText(namingConvention);
            _validateRuleItems(namingConvention)
        } catch (error) {
            deferred.reject(error.msg);
            return deferred.promise;
        }

        if (namingConvention.id) {
            NamingConventionsRuleResource.update(namingConvention).then(function (namingConventionId) {
                deferred.resolve(namingConventionId);
            })
        } else {
            NamingConventionsRuleResource.create(namingConvention).then(function (response) {

                _.forEach(namingConvention.addCustomDelimiters, function (delimiter) {
                    delimiter.naming_convention_id = response.id;
                    NamingConventionsRuleResource.saveCustomDelimiter(delimiter);
                });

                deferred.resolve(response);
            });
        }
        return deferred.promise;
    }

    /**
     * Removes all rule items from naming convention
     * @param {NamingConvention} namingConvention
     */
    function clearAllRuleItems(namingConvention) {
        namingConvention.ruleItems.splice(0, namingConvention.ruleItems.length);
    }

    /**
     * Returns a namingConvention
     * @param {String} namingConventionId namingConvention identifier
     * @returns {Promise} NamingConvention
     */
    function retrieveNamingConvention(namingConventionId) {
        if (!namingConventionId) {
            var namingConvention = new NamingConvention();
            var defered = $q.defer();
            defered.resolve(namingConvention);
            return defered.promise;
        }
        return NamingConventionsRuleResource.getNamingConvention(namingConventionId).then(function (data) {
            return new NamingConvention(data);
        });
    }

    /**
     * @param name
     * @throws
     * @private
     */
    function _validateDelimiterRuleName(name) {
        if (_.isEmpty(name)) {
            throw {msg: 'Please make sure to enter characters for your custom delimiter.'}
        }
    }

    /**
     * @param namingConvention
     * @throws
     * @private
     */
    function _validateName(namingConvention) {
        if (_.isEmpty(namingConvention.name)) {
            throw {msg: 'Please make sure to enter a name.'}
        }
    }

    /**
     * @param namingConvention
     * @throws
     * @private
     */
    function _validateSampleText(namingConvention) {
        if (_.isEmpty(namingConvention.preview)) {
            throw {msg: 'Please make sure to enter a sample text.'}
        }
    }

    /**
     * @param namingConvention
     * @throws
     * @private
     */
    function _validateRuleItems(namingConvention) {
        if (_.isEmpty(namingConvention.ruleItems)) {
            throw {msg: 'Please make sure to include rule items'}
        }
    }

    /**
     *  GETTERS & SETTERS
     */

}

/**
 * Factory which exposes constructors of model module
 */
function NamingConventionsModelFactory() {
    return {
        RuleItemsCollection: RuleItemsCollection,
        NamingConvention: NamingConvention,
        RuleItem: RuleItem
    }
}

function RuleItem(ruleItem) {
    var self = this;
    _.extend(self, ruleItem);

    this.isDelimiter = isDelimiter;
    this.isCustomDelimiter = isCustomDelimiter;

    /**
     * Helper function to determine if object is a delimiter type
     * @returns {boolean}
     */
    function isDelimiter() {
        return self.type === NamingConventions().TYPES.DELIMITER
            || self.type === NamingConventions().TYPES.CUSTOM_DELIMITER
    }

    /**
     * Helper function to determine if object is of custom_delimiter type
     * @returns {boolean}
     */
    function isCustomDelimiter() {
        return self.type === NamingConventions().TYPES.CUSTOM_DELIMITER;
    }
}

/**
 * Rule items Model
 * @param rulesItems
 * @constructor
 */
function RuleItemsCollection(rulesItems) {
    this.identifiers = {
        name: 'Identifiers',
        items: rulesItems.filter(function (item) {
            return item.type === NamingConventions().TYPES.IDENTIFIER;
        })
    };
    this.delimiters = {
        name: 'Delimiters',
        items: rulesItems.filter(function (item) {
            return item.type === NamingConventions().TYPES.DELIMITER
                || item.type === NamingConventions().TYPES.CUSTOM_DELIMITER;
        })
    };
    this.specials = {
        name: 'Specials',
        items: rulesItems.filter(function (item) {
            return item.type === NamingConventions().TYPES.SPECIAL;
        })
    };

}

function NamingConvention(data) {
    var self = this;

    this.id = null;
    this.name = '';
    this.preview = '';
    this.ruleItems = [];
    this.rule = '';

    this.addCustomDelimiters = [];

    if (data) {
        _.extend(self, data);
        var ruleArray = JSON.parse(data.rule);
        this.ruleItems = _.map(ruleArray, function (ruleItem) {
            return new RuleItem(ruleItem);
        });
    }

    this.getRule = getRule;

    function getRule() {
        return _.map(self.ruleItems, function (rule) {
            return {
                id: rule.id,
                text: rule.text,
                type: rule.type,
                label: rule.label
            }
        })
    }
}