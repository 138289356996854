'use strict';
import angular from 'angular';
import $ from 'jquery';

import widgetChartHtmlUrl from '../widget.chart.html';

angular.module('widget.gaugechart.directives', [])

    .directive('gaugechartWidget', gaugechartWidget);

/**
 *
 * @ngInject
 */
function gaugechartWidget() {
    return {
        restrict: 'E',
        templateUrl: widgetChartHtmlUrl,
        controller: 'GaugeChartWidgetController',
        scope: {
            state: '=',
            widget: '=',
            chartId: '<',
            widgetTypeId: '<',
            widgetIsPredefined: '<'
        }
    }
}