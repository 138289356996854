'use strict';
import angular from 'angular';

import './navmain.resources';
import './navmain.services';
import './navmain.components';

angular.module('navmain', [
    'navmain.resources',
    'navmain.services',
    'navmain.components'
]);