import { SimpleLinearRegression } from 'ml-regression-simple-linear';
import { color, Line } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function useRegressionLine(context) {
  const { chart, root, config, exporter } = context();
  let timeout;

  function addRegressionLine() {
    // if we're exporting we won't have the end of animation frames to announce readiness
    // so on export we trigger on export started instead
    if (config.value.isExporting) {
      exporter.value.events.on(Constant.EXPORT_STARTED, () => {
        calculateRegressionLine();
      });
    } else {
      root.value.events.on(Constant.FRAME_ENDED, chartFinished);
    }
  }

  function chartFinished() {
    // debounce the frame-ended so we only hit the function once instead of at the end of every
    // animation frame
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      root.value.events.off(Constant.FRAME_ENDED, chartFinished);
      calculateRegressionLine();
    }, 25);
  }

  function calculateRegressionLine() {
    chart.value.series.values.forEach((series, seriesIndex) => {
      // benchmarks count as a series but are not supported for regression lines
      if (!config.value.series[seriesIndex]) {
        return;
      }

      const points = [];
      series.dataItems.forEach((dataItem) => {
        points.push(dataItem.get(Constant.POINT));
      });

      if (!points.length) {
        return;
      }

      const xs = [];
      const ys = [];
      points.forEach((point) => {
        // eslint-disable-next-line tap/no-raw-text-js
        if (typeof point !== 'undefined') {
          const { x, y } = point;
          xs.push(config.value.isRotated ? y : x);
          ys.push(config.value.isRotated ? x : y);
        }
      });

      if (!xs.length) {
        return;
      }

      if (!config.value.hasMetricLinesVisible) {
        series.strokes.template.setAll({
          forceHidden: true,
        });
      }

      const regression = new SimpleLinearRegression(xs, ys);

      // once regression has worked out the formula use the predict function to get the first / last points of our line
      const firstX = xs[0];
      const firstY = regression.predict(firstX);

      const finalX = xs[xs.length - 1];
      const finalY = regression.predict(finalX);

      const strokeColor = config.value.series[seriesIndex].color;
      const { opacity } = config.value.series[seriesIndex];

      chart.value.plotContainer.children.push(
        Line.new(root.value, {
          stroke: color(strokeColor),
          strokeWidth: 2,
          strokeDasharray: [4],
          opacity,
          draw: (display) => {
            if (config.value.isRotated) {
              display.moveTo(firstY, firstX);
              display.lineTo(finalY, finalX);
            } else {
              display.moveTo(firstX, firstY);
              display.lineTo(finalX, finalY);
            }
          },
        })
      );
    });
  }

  return {
    addRegressionLine,
  };
}
