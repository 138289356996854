import { Axis } from '@/modules/core/charts/am5/base/models/Axis';
import { ChartConfig } from '@/modules/core/charts/am5/base/models/ChartConfig';
import { Bubble } from '@/modules/core/charts/am5/bubble/models/Bubble';

/**
 * @class BubbleChartConfig
 * @extends ChartConfig
 */
export class BubbleChartConfig extends ChartConfig {
  /**
   * @var {Array<XYSeries>}
   */
  series;

  /**
   * @var {Axis}
   */
  xAxis;

  /**
   * @var {Axis}
   */
  yAxis;

  /**
   * If chart axis are exchanged
   * @var {Boolean}
   */
  isRotated;

  /**
   * If chart is full stacked chart
   * @var {Boolean}
   */
  isFullStacked;

  /**
   * If chart has axis value scroll bars
   * @var {Boolean}
   */
  hasValueScrollbar;

  /**
   * Enables Cursor for chart
   * @var {Boolean}
   * @default true
   */
  cursorEnabled;

  /**
   * When true, tooltip is displayed on cursor and when false, tooltip is displayed on series.
   * @var {boolean}
   * @default true
   */
  isTooltipOnCursor;

  /**
   * Set true to show chart as a spark line
   * @var {Boolean}
   * @default false
   */
  isSparkLine;

  /**
   * @var {string}
   */
  groupByFieldName;

  /**
   * @var {string}
   */
  backgroundGradient;

  /**
   * @var {string}
   */
  gradientColor;

  /**
   * @var {Boolean}
   */
  fontColorPicker;

  constructor(model = {}) {
    super(model);
    const { cursorEnabled = true, isTooltipOnCursor = true, isSparkLine = false } = model;

    this.series = model.series?.map((bubble) => new Bubble(bubble));
    this.xAxis = new Axis(model.xAxis);
    this.yAxis = new Axis(model.yAxis);
    this.depth = model.depth;
    this.angle = model.angle;
    this.isRotated = model.isRotated;
    this.isFullStacked = model.isFullStacked;
    this.hasValueScrollbar = model.hasValueScrollbar;
    this.cursorEnabled = cursorEnabled;
    this.isTooltipOnCursor = isTooltipOnCursor;
    this.isSparkLine = isSparkLine;
    this.groupByFieldName = model.groupByFieldName;
    this.backgroundGradient = model.backgroundGradient;
    this.gradientColor = model.gradientColor;
    this.fontColorPicker = model.fontColorPicker;
  }

  setReportChartDefaults() {
    super.setReportChartDefaults();
    this.hasValueScrollbar = false;
  }
}
