'use strict';
import angular from 'angular';
import './namingconventionrule.directives';
import './namingconventionrule.services';
import './namingconventionrule.resources';

angular.module('core.namingconventionrule', [
    'namingconventionrule.directives',
    'namingconventionrule.services',
    'namingconventionrule.resources'
]);