import angular from 'angular';

import {
    DataSourceType,
} from 'coreModules/shared/scripts/app.constants';

angular.module('widget.builder.models')
    .factory('WidgetBuilderLibraryModelFactory', WidgetBuilderLibraryModelFactory);

/**
 * @ngInject
 */
function WidgetBuilderLibraryModelFactory(
    AppModelFactory,
    gettextCatalog,
    LibrarySearchGroup
) {

    return {
        getTabState: getTabState,
        getTabData: getTabData,
        getFilterSelectConfig: getFilterSelectConfig,
        getSearchBoxConfig: getSearchBoxConfig,
    };

    /**
     * @constructor
     */
    function WidgetBuilderLibraryFilterSelectConfig() {
        var self = this;

        self.options = AppModelFactory.getSelectOptions(new _LibrarySearchBoxSelectOptions());
        self.values = [];
        self.selectedValues = [];
    }

    /**
     * @constructor
     */
    function WidgetBuilderLibrarySearchBoxConfig() {
        var self = this;

        self.value = '';
    }

    /**
     * @constructor
     */
    function WidgetBuilderLibraryTabState() {
        let self = this;

        self.isLoading = false;
        self.isSwapping = false;
        self.isSearchBoxActive = false;
        self.isSearching = false;
    }

    /**
     * @constructor
     */
    function WidgetBuilderLibraryTabData() {
        let self = this;

        self.templateItems = [];
        self.searchedItems = [];
        self.searchableValues = new _SearchableTemplateValues();
    }

    /**
     * @constructor
     * @private
     */
    function _SearchableTemplateValues() {
        let self = this;

        self.dataSources = [];
        self.widgetTypes = [];
        self.tags = [];
    }

    /**
     * @constructor
     * @private
     */
    function _LibrarySearchBoxSelectOptions() {
        var self = this;
        var _placeholder = gettextCatalog.getString('Filter by data source, widget type, or tag...');
        var _noMatch = function (value) {
            return '<span>No search filters match: <i>' + value + '</i>';
        };

        self.$elSelector = '#widget-builder-panel div.library-tab-content div.select2-container';
        self.multiple = true;
        self.allowClear = true;
        self.dropdownCssClass = 'select2-template-search-dropdown';
        self.width =  '370px';
        self.placeholder =  _placeholder;
        self.formatResult = _formatDisplay;
        self.formatSelection = _formatDisplay;
        self.formatNoMatches = _noMatch;
        self.matcher = _matchCustom;

        /**
         * Select2 override to custom match search string
         * @param value
         * @param data
         * @param opt
         * @returns {*}
         */
        function _matchCustom(value, data, opt) {
            if (!opt.group) {
                return null;
            }
            if (data.toLowerCase().contains(value.toLowerCase())) {
                return data;
            }
            return null;
        }

        /**
         * Select2 override How to show selected value in dropdown or as a selectable value
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatDisplay(item) {
            var result = null;
            if (item.group === LibrarySearchGroup.DATA_SOURCES) {
                var icon = item.icon;
                if (item.type === DataSourceType.SERVICE_DATA) {
                    icon = item.has_custom_icon ? 'custom-icon ' + item.icon : 'serviceicon-' + item.icon;
                } else {
                    icon = item.has_custom_icon ? 'custom-icon ' + item.icon : item.icon;
                }
                result = '<div><div class="service-square service-square-24" style="background-color:'+ item.color +'"><div class="icon ' + icon + '"></div></div> ' + item.text + '</div>'
            } else if (item.group === LibrarySearchGroup.TAGS) {
                result = '<i class="icon icon-tag"></i>' + item.text;
            } else if (item.group === LibrarySearchGroup.WIDGET_TYPES) {
                result = '<i class="icon icomoon-'+ item.icon +'"></i>' + item.text;
            } else {
                result = item.text;
            }
            return result;
        }
    }

    /**
     * @returns {WidgetBuilderLibraryTabState}
     */
    function getTabState() {
        return new WidgetBuilderLibraryTabState();
    }

    /**
     * @returns {WidgetBuilderLibraryTabData}
     */
    function getTabData() {
        return new WidgetBuilderLibraryTabData();
    }

    /**
     * @returns {WidgetBuilderLibraryFilterSelectConfig}
     */
    function getFilterSelectConfig() {
        return new WidgetBuilderLibraryFilterSelectConfig();
    }

    /**
     * @returns {WidgetBuilderLibrarySearchBoxConfig}
     */
    function getSearchBoxConfig() {
        return new WidgetBuilderLibrarySearchBoxConfig();
    }
}