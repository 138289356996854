'use strict';
import angular from 'angular';

import './login.constants';
import './scripts/login.components';
import './scripts/login.services';

import layoutHtmlUrl from './views/layout.html';
import {LoginState} from './login.constants';

angular.module('core.login', [
    'core.login.components',
    'core.login.services'
]).config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', 'ExportBuilderRouterState', function ($stateProvider, AppConfig, SwitchAppServiceProvider, ExportBuilderRouterState) {
    $stateProvider
        .state('loginlayout', {
            url: '',
            abstract: true,
            views: {
                '': {
                    templateUrl: layoutHtmlUrl,
                },
                'globals@loginlayout': {
                    templateUrl: AppConfig.MAGE_URL + 'session/globals'
                }
            }
        })
        .state('login', {
            parent: 'loginlayout',
            url: '/login?remote_login_key&is_direct_signup&redirect_url',
            template: '<login-component settings="settings"></login-component>',
            controller: ['$scope', 'settings', function ($scope, settings) {
                $scope.settings = settings;
            }],
            params: {
                message_id: null,
                remote_login_key: null,
                is_direct_signup: null
            },
            onEnter: ['$state', '$stateParams', 'LoginFactory', 'AppFactory', function ($state, $stateParams, LoginFactory, AppFactory) {
                if ($stateParams.remote_login_key) {
                    LoginFactory.isRemoteLogin().success(function (response) {
                        var data = response.data;
                        if (data && data.is_redirect) {
                            $state.go(data.redirect_state);
                        }
                    });
                } else {
                    const user = AppFactory.getUser();
                    if (user && user.isLoggedIn) {
                        LoginFactory.postLoginReroute(user);
                    }
                }
            }],
            resolve: {
                isTARoute: SwitchAppServiceProvider.switchAppFn('login'),
                settings: ['LoginFactory', function (LoginFactory) {
                    return LoginFactory.initUser().then(function (response) {
                        return response.data ? response.data.settings : [];
                    });
                }]
            }
        })
        .state('forgotpassword', {
            parent: 'loginlayout',
            url: '/forgotpassword',
            controller: ['$scope', 'settings', function ($scope, settings) {
                $scope.settings = settings;
            }],
            template: '<forgot-password settings="settings"></forgot-password>',
            params: {
                message_id: null
            },
            resolve: {
                settings: ['LoginFactory', function (LoginFactory) {
                    return LoginFactory.initUser().then(function (response) {
                        return response.data ? response.data.settings : [];
                    });
                }]
            },
            onEnter: ['AppFactory', 'LoginFactory', function (AppFactory, LoginFactory) {
                const user = AppFactory.getUser();
                if (user && user.isLoggedIn) {
                    LoginFactory.postLoginReroute(user);
                }
            }]
        })
        .state('resetpassword', {
            parent: 'loginlayout',
            url: '/resetpassword/:request_id/:verification_code/:user_id',
            controller: ['$scope', 'settings', function ($scope, settings) {
                $scope.settings = settings;
            }],
            params: {
                request_id: {squash: true},
                verification_code: {squash: true},
                user_id: {squash: true}
            },
            template: '<reset-password settings="settings"></reset-password>',
            resolve: {
                settings: ['LoginFactory', function (LoginFactory) {
                    return LoginFactory.initUser().then(function (response) {
                        return response.data ? response.data.settings : [];
                    });
                }]
            }
        })
        .state(LoginState.EZLOGIN_REDIRECT, {
            url: '/ez_login/i/:id/v/:verification_code/:is_logged_out',
            params: {
                id: ':id',
                verification_code: ':verification_code',
                is_logged_out: null
            },
            template: '<app-loader></app-loader>',
            onEnter: ['$timeout', '$state', '$stateParams', 'LoginFactory', 'UserLoginFactory', 'AppFactory', 'WindowUtilUIService', function ($timeout, $state, $stateParams, LoginFactory, UserLoginFactory, AppFactory, WindowUtilUIService) {
                LoginFactory.ezLogin($stateParams.id, $stateParams.verification_code, $stateParams.is_logged_out).success(function (response) {
                    if (response && response.data) {
                        var data = response.data;
                        if (data.isLoggedIn) {
                            $state.go(LoginState.EZLOGIN_REDIRECT, {
                                'id': $stateParams.id,
                                'verification_code': $stateParams.verification_code,
                                'is_logged_out': 1
                            });
                        } else {
                            if (data.message_id) {
                                // Setting the default state as the existing link is expired and that state is reachable.
                                UserLoginFactory.setIntendedState(null, null);
                                $state.go('login', {'message_id': data.message_id});
                            } else if (data.redirect_state) {
                                let params = data.id ? {'id': data.id} : {};
                                let state = data.redirect_state;
                                LoginFactory.initUser().then((json) => {
                                    if (AppFactory.mustGoToNewApp(json.data.settings)) {
                                        WindowUtilUIService.navigateToNewApp(data.redirect_state + (params.id ? '/' + params.id : ''));
                                        return;
                                    }
                                    if(data.redirect_state == 'reportstudio'){
                                        state = ExportBuilderRouterState.EXPORT_BUILDER_STUDIO;
                                    }
                                    $state.go(state, params);
                                });
                            } else {
                                // Redirecting to ez_login state with message id stating  invalid link in-case of wrong response.
                                $state.go('login', {'message_id': 3});
                            }
                        }
                    } else {
                        // Redirecting to login state with message id stating  invalid link in-case of wrong response.
                        $state.go('login', {'message_id': 3});
                    }
                }).error(function () {
                    // Redirecting to login page when there is any server side error.
                    $state.go('login');
                });
            }],
        })
        // Will be removed once all new links point to the ez_login_redirect.
        .state('ez_login', {
            parent: 'loginlayout',
            url: '/ez_login/:message_id',
            params: {
                message_id: ':message_id'
            },
            controller: ['$scope', 'settings', function ($scope, settings) {
                $scope.settings = settings;
            }],
            template: '<login-component settings="settings"></login-component>',
            resolve: {
                settings: ['LoginFactory', function (LoginFactory) {
                    return LoginFactory.initUser().then(function (response) {
                        return response.data ? response.data.settings : [];
                    });
                }]
            }
        })
        .state('google_login', {
            url: '/google_login/c/:code/s/:from_google_login_redirect_state/p/:from_google_login_redirect_params',
            params: {
                code: ':code',
                from_google_login_redirect_state: ':from_google_login_redirect_state',
                from_google_login_redirect_params: ':from_google_login_redirect_params'
            },
            template: '<app-loader></app-loader>',
            onEnter: ['$timeout', '$state', '$stateParams', 'LoginFactory', 'UserLoginFactory', 'AppFactory', 'WindowUtilUIService', function ($timeout, $state, $stateParams, LoginFactory, UserLoginFactory, AppFactory, WindowUtilUIService) {
                LoginFactory.googleLogin($stateParams.code, $stateParams.from_google_login_redirect_state, $stateParams.from_google_login_redirect_params).success(function (response) {
                    if (response && response.data) {
                        var data = response.data;
                        if (data.isLoggedIn) {
                            $state.go('google_login_redirect', {
                                'id': $stateParams.code,
                                'from_google_login_redirect_state': $stateParams.from_google_login_redirect_state,
                                'from_google_login_redirect_params': $stateParams.from_google_login_redirect_params
                            });
                        } else {
                            if (data.message_id) {
                                // Setting the default state as the existing link is expired and that state is reachable.
                                UserLoginFactory.setIntendedState(null, null);
                                $state.go('login', {'message_id': data.message_id});
                            } else if (data.redirect_state) {
                                LoginFactory.initUser().then((json) => {
                                    if (AppFactory.mustGoToNewApp(json.data.settings)) {
                                        let path = data.redirect_state === 'home' ? null : data.redirect_state + (data.id ? '/' + data.id : '');
                                        if (path === null && json.data.settings.isIOHomePage) {
                                            LoginFactory.goToDefaultHome(json.data.settings);
                                        } else {
                                            WindowUtilUIService.navigateToNewApp(path);
                                        }
                                    } else {
                                        $state.go(
                                            data.redirect_state === 'reportstudio' ? ExportBuilderRouterState.EXPORT_BUILDER_STUDIO : data.redirect_state,
                                            data.id ? {'id': data.id} : {}
                                        );
                                    }
                                });
                            } else {
                               // Redirecting to login state with message id stating server side error.
                                $state.go('login', {'message_id': 8});
                            }
                        }
                    } else {
                        // Redirecting to login state with message id stating server side error.
                       $state.go('login', {'message_id': 8});
                    }
                }).error(function () {
                    // Redirecting to login page when there is any server side error.
                    $state.go('login', {'message_id': 8});
                });
            }],
        })
}]);