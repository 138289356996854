'use strict';
import angular from 'angular';
import $ from 'jquery';

// import exportBuilderPanelHtmlUrl from './exportbuilder.panel.html';
import exportBuilderPanelDatePickerHtmlUrl from './exportbuilder.panel.datepicker.html';


angular.module('exportbuilder.panel.directives', [])
    .directive('exportBuilderPanelDatePicker', exportBuilderPanelDatePicker);
// .directive('exportBuilderPanel', exportBuilderPanel)
// .directive('exportBuilderPanelDragList', exportBuilderPanelDragList);


//
//  directive export builder panel
//
exportBuilderPanel.$inject = ['ExportBuilderResource', 'ExportBuilderDashboardService'];
function exportBuilderPanel(
    ExportBuilderResource,
    ExportBuilderDashboardService
) {
    return {
        restrict: 'E',
        replace: true,
        controller: 'ExportBuilderPanelController',
        templateUrl: exportBuilderPanelHtmlUrl,
        link: function(scope, el) {
            //trigger a screenshot when we destroy the panel
            // We use the panel here because all the other components are used in the screenshot process
            // itself and we don't want to retrigger the screenshot
            scope.$on('$destroy', function() {
                ExportBuilderResource.createThumbnail(ExportBuilderDashboardService.getBuilder().report.id)
                    .then(function() {
                        //when the thumbnail has been created, trigger a data refresh of the item if
                        //the user is in the list, to reflect the changes that had just been made
                        //TODO
                    });
            });
        }
    }
}

//
//  directive export builder panel drag list
//
exportBuilderPanelDragList.$inject = [];
function exportBuilderPanelDragList(
) {
    return {
        restrict: 'C',
        link: function(scope, el) {
            var cursor = 'pointer';
            el.sortable({
                connectWith: ".export-builder-panel-drag-list",
                start: function(evt, ui) {
                    //save cursor style
                    cursor = ui.item.css('cursor');
                    //change cursor to move
                    ui.item.css('cursor', 'move');
                },
                update: function(evt, ui) {
                    // ui.sender is defined only if an item is dropped from
                    // another list
                    if (ui.sender) {
                        //get the target list object
                        var target = ui.item.parents('.export-builder-panel-drag-list');
                        //change the item's page
                        scope.moveToNewPage(ui.item.data('item-id'), target.data('page-index'));
                    }

                    // move the item to it's proper z-index position
                    scope.moveZ(ui.item.data('item-id'), ui.item.index());
                },
                stop: function(evt, ui) {
                    ///restore cursor
                    ui.item.css('cursor', cursor);
                }
            });
            el.disableSelection();
        }
    }
}

//
//  directive export builder panel date picker
//
exportBuilderPanelDatePicker.$inject = [];

function exportBuilderPanelDatePicker() {
    return {
        replace: true,
        scope: {
            setDateRangePicker: '&'
        },
        templateUrl: exportBuilderPanelDatePickerHtmlUrl,
        link: function ($scope, $el, $attr) {
            $scope.setDateRangePicker();
            $el.on('click', () => {
                let el = angular.element(document.querySelectorAll("#" + $attr.labelId))[0];
                let datePickerInputLocation = el.offsetTop;
                $('.data-panel .inner').animate({
                    scrollTop: datePickerInputLocation
                }, 0);
                $('.daterangepicker').animate({
                    top: $('#date-range-input').offset().top + 35
                }, 0);
            })
        }
    }
}