import { ChartConfig } from '@/modules/core/charts/am5/base/models/ChartConfig';
import { AxisRange } from '@/modules/core/charts/models/AxisRange';
import { Hand } from '@/modules/core/charts/models/Hand';
import { getShadowSettings } from '@/modules/core/charts/am5/charts.helper';
import {
  PlotType,
  WidgetBackgroundGradientColorOptions,
} from '@/modules/ta/widget/widget.constants';
import {
  Constant,
  GaugeBigNumberInteriorPosition,
  GaugeBigNumberPosition,
} from '@/modules/core/charts/am5/charts.constants';
import { BigNumber } from '@/modules/core/charts/am5/gauge/models/BigNumber';

const defaultStartAngle = -180;
const defaultEndAngle = 0;

export class GaugeChartConfig extends ChartConfig {
  /**
   * @var {AxisRange[]}
   */
  axisRanges;

  /**
   * A list of ClockHand items displayed on this Gauge chart.
   * @var {Hand[]}
   */
  hands;

  /**
   * Inner radius of the Radar face. Percent value is relative to radius.
   * @var {Number}
   */
  innerRadius;

  /**
   * Starting angle of the Radar face. (degrees). Our charts have a starting angle of 180
   * @var {Number}
   */
  startAngle;

  /**
   * Ending angle of the Radar face. (degrees). Our charts have a ending angle of 360 ( or 0)
   * @var {Number}
   */
  endAngle;

  /**
   * @var {String}
   */
  fillType;

  /**
   * @var {String}
   */
  gradientColor;

  /**
   * @var {Boolean}
   */
  showLabel;

  /**
   * @var {Boolean}
   */
  gaugeShowValue;

  /**
   * @var {Boolean}
   */
  useTooltip;

  /**
   * @var {Number}
   */
  gaugeThickness;

  /**
   * @var {Array}
   */
  labels;

  /**
   * @var {Boolean}
   */
  showTicks;

  /**
   * @var {string}
   */
  handColor;

  /**
   * @var {Number}
   */
  gaugeRange;

  /**
   * @var {Number}
   */
  grainDensity;

  /**
   * @var {string}
   */
  gaugeHandType;

  /**
   * @var {string}
   */
  gaugeGradientTo;

  /**
   * @var {boolean}
   */
  gaugeHandCustomColor;

  /**
   * @var {string}
   */
  backgroundColor;

  /**
   * @var {string}
   */
  gaugeGradientTarget;

  /**
   * @var {string}
   */
  showShapeShadow;

  /**
   * @var {boolean}
   */
  showBigNumber;

  /**
   * @var {String}
   */
  bigNumberPosition;

  /**
   * @var {string}
   */
  fontColorPicker;

  getGaugeThickness() {
    return -Math.abs(this.gaugeThickness);
  }

  getGaugeMultiAxisThickness() {
    return this.gaugeThickness / 8;
  }

  /**
   * Start / end angle correspond to points on a compass
   *      -90
   * -180      0
   *     -270
   */
  getStartAngle(gaugeRange) {
    // eslint-disable-next-line valid-typeof
    if (typeof gaugeRange === Constant.UNDEFINED || gaugeRange === 180) {
      return defaultStartAngle;
    }

    // if the range is over 180 then we need to take away until we're at the max of 270
    if (gaugeRange > 180) {
      return defaultStartAngle - (gaugeRange - 180) / 2;
    }

    // start at -90
    return -90 - gaugeRange / 2;
  }

  getEndAngle(gaugeRange) {
    // eslint-disable-next-line valid-typeof
    if (typeof gaugeRange === Constant.UNDEFINED || gaugeRange === 180) {
      return defaultEndAngle;
    }

    // if the range is over 180 then we need to add until we're at the max of 270
    if (gaugeRange > 180) {
      return defaultEndAngle + (gaugeRange - 180) / 2;
    }

    return -90 + gaugeRange / 2;
  }

  getShadowSettings() {
    if (!this.showShapeShadow) {
      return {};
    }

    return getShadowSettings(this.isExporting);
  }

  attachBigNumbersToAxisRanges() {
    // none position means no big numbers
    if (this.bigNumberPosition === GaugeBigNumberPosition.NONE) {
      return;
    }

    if (this.axisRanges && this.axisRanges[0] !== undefined) {
      // if the chosen position is the centre / hand then we only use the first value
      if (
        this.bigNumberPosition === GaugeBigNumberPosition.HAND ||
        this.bigNumberPosition === GaugeBigNumberPosition.HAND_TRANSPARENT
      ) {
        this.axisRanges[0].bigNumber = new BigNumber(
          this.getBigNumberDataFromAxis(this.axisRanges[0], this.bigNumberPosition)
        );
      } else {
        // not the hand so we need a big number per range
        const amountOfRanges = this.axisRanges && this.axisRanges[1] !== undefined ? 2 : 1;

        this.axisRanges[0].bigNumber = new BigNumber(
          this.getBigNumberDataFromAxis(
            this.axisRanges[0],
            this.bigNumberPosition,
            amountOfRanges === 1
              ? GaugeBigNumberInteriorPosition.CENTER
              : GaugeBigNumberInteriorPosition.LEFT
          )
        );
        if (amountOfRanges > 1) {
          this.axisRanges[1].bigNumber = new BigNumber(
            this.getBigNumberDataFromAxis(
              this.axisRanges[1],
              this.bigNumberPosition,
              GaugeBigNumberInteriorPosition.RIGHT
            )
          );
        }
      }
    }
  }

  getBigNumberDataFromAxis(axis, position, interiorPosition) {
    return {
      value: axis.endValue ? axis.endValue : axis.value,
      fill: position === GaugeBigNumberPosition.HAND_TRANSPARENT ? Constant.WHITE_TEXT : axis.fill,
      position,
      interiorPosition,
      isComparison: this.comparisonEnabled,
      format: axis.format,
    };
  }

  canShowGaugeValue() {
    if (
      this.bigNumberPosition === GaugeBigNumberPosition.INSIDE ||
      this.bigNumberPosition === GaugeBigNumberPosition.NONE
    ) {
      return this.gaugeShowValue;
    }

    return false;
  }

  isMultiAxis() {
    return this.plotType === PlotType.MULTI_AXIS_GAUGE;
  }

  bigNumberIsInsidePointer() {
    return (
      this.showBigNumber &&
      [GaugeBigNumberPosition.HAND, GaugeBigNumberPosition.HAND_TRANSPARENT].includes(
        this.bigNumberPosition
      )
    );
  }

  bigNumberIsBelowGauge() {
    return this.showBigNumber && this.bigNumberPosition === GaugeBigNumberPosition.BELOW;
  }

  constructor(model = {}) {
    super(model);

    const startAngle = this.getStartAngle(model.gaugeRange);
    const endAngle = this.getEndAngle(model.gaugeRange);

    this.comparisonEnabled = model.comparisonEnabled;
    this.axisRanges = model.axisRanges?.map((aRange) => new AxisRange(aRange));
    this.hands = model.hands?.map((aHand) => new Hand(aHand));
    this.gaugeThickness = model.gaugeThickness;
    this.startAngle = model.startAngle;
    this.endAngle = model.endAngle;
    this.useTooltip = model.hasTooltip ?? true;
    this.fillType = model.fillType ?? WidgetBackgroundGradientColorOptions.SOLID;
    this.gradientColor = model.gradientColor;
    this.showLabel = model.showLabel ?? true;
    this.gaugeShowValue = model.gaugeShowValue ?? true;
    this.labels = model.labels;
    this.showTicks = model.showTicks;
    this.handColor = model.handColor;
    this.gaugeRange = model.gaugeRange;
    this.grainDensity = model.grainDensity;
    this.gaugeHandType = model.gaugeHandType;
    this.startAngle = startAngle;
    this.endAngle = endAngle;
    this.gaugeGradientTo = model.gaugeGradientTo;
    this.gaugeHandCustomColor = model.gaugeHandCustomColor;
    this.plotType = model.plotType;
    this.backgroundColor = model.backgroundColor;
    this.gaugeGradientTarget = model.gaugeGradientTarget;
    this.showShapeShadow = model.showShapeShadow;
    this.bigNumberPosition = model.bigNumberPosition ?? GaugeBigNumberPosition.NONE;
    this.showBigNumber = this.bigNumberPosition !== GaugeBigNumberPosition.NONE;
    this.hasLegend = model.hasLegend;
    this.fontColorPicker = model.fontColorPicker;

    this.attachBigNumbersToAxisRanges();
  }
}
