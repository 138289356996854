'use strict';
import angular from 'angular';

angular.module('core.ui.constants', [])

	.constant('NotifyType', {
		BASE: 'base',
		SUCCESS: 'success',
		WARNING: 'warning',
		ERROR: 'error'
	})

	.constant('$ModalEvents', {
		SHOW_MODAL: 'showModal:',
		HIDE_MODAL: 'hideModal:'
	});