import GaugeChartWidgetRenderService from '@/modules/ta/widget/services/types/GaugeChartWidgetRenderService';
import { GaugeChartConfig } from '@/modules/core/charts/am5/gauge/models/GaugeChartConfig';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { PlotType } from '@/modules/ta/widget/widget.constants';

export class LegacyGaugeChartWidgetRenderService extends GaugeChartWidgetRenderService {
  /**
   * @var Widget
   */
  _widget;

  /**
   * @var array
   */
  _chartPalette;

  /**
   * @var boolean
   */
  _hasComparisonData;

  /**
   * @var boolean
   */
  _isExporting;

  constructor(widget, chartPalette = [], hasComparisonData = false, isExporting = false) {
    super(widget.id);
    this._widget = widget;
    this._chartPalette = widget.metadata.chart_palette ?? chartPalette;
    this._hasComparisonData = hasComparisonData;
    this._isExporting = isExporting;
  }

  /**
   * @returns {*[]}
   */
  getMultiAxisLabels() {
    const labels = [this.getFirstColumnLabel()];

    if (this.nonGroupedColumns.length > 1) {
      labels.push(this.getSecondColumnLabel());
    }

    // Label that show if only there is a comparison between two charts
    if (this.hasComparison()) {
      labels.push(this.getPeriodLabel());
    }

    return labels;
  }

  getMultiAxisRanges() {
    const axisRanges = [];

    this.nonGroupedColumns.forEach((column, columnIndex) => {
      axisRanges.push({
        value: this.getColumnValueFromIndex(columnIndex),
        fillOpacity: this.isComparison ? 0.6 : 1,
        fill: this.getChartPalette(columnIndex),
        labelText: this.getLabelTextFromIndex(columnIndex),
        format: column.format,
      });
    });

    return axisRanges;
  }

  getColumnValueFromIndex(index) {
    if (index === 0) {
      return this.getColumnValue(this.firstNonGroupedColumn);
    }

    return this.getColumnValue(this.secondNonGroupedColumn);
  }

  getLabelTextFromIndex(index) {
    let labelText = this.getFirstColumnLabelText();

    if (index === 1) {
      labelText = this.getSecondColumnLabelText();
    }

    return labelText;
  }

  /**
   * @returns {string}
   */
  getFirstColumnLabelText() {
    return this.isComparison
      ? `${Constant.COMPARISON_TEXT} ${this.firstNonGroupedColumn.label}`
      : `${this.firstNonGroupedColumn.label}`;
  }

  /**
   * @returns {string}
   */
  getSecondColumnLabelText() {
    return this.isComparison
      ? `${Constant.COMPARISON_TEXT} ${this.secondNonGroupedColumn.label}`
      : `${this.secondNonGroupedColumn.label}`;
  }

  isMultiAxis() {
    const { plot_type } = this.getWidget().metadata.draw_options;
    return plot_type === PlotType.MULTI_AXIS_GAUGE;
  }

  /**
   * @param config
   * @returns {GaugeChartConfig}
   */
  initConfig(config) {
    return new GaugeChartConfig(config);
  }

  getWidget() {
    return this._widget;
  }

  /**
   * @returns {boolean}
   */
  isExporting() {
    return this._isExporting;
  }

  getFullChartPalette() {
    return this._chartPalette;
  }

  /**
   * @param columnIndex
   * @returns {string}
   */
  getChartPalette(columnIndex) {
    return this._chartPalette[columnIndex % this._chartPalette.length];
  }

  /**
   * @returns {{}}
   */
  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
      hasComparison: this.hasComparison(),
    };
  }

  hasComparison() {
    return this._hasComparisonData;
  }
}
