export class GuideState {
  /**
   * @var {Array}
   */
  loaders = [];

  /**
   * @var {Array}
   */
  autoPlayGuides = [];
}
