import angular from 'angular';
import _ from 'lodash';

angular.module('core.form.models', [])
    .service('FormModelFactory', FormModelFactory);

/**
* @ngInject
*/
function FormModelFactory(
    gettextCatalog,
    UIFactory
) {
    return {
        getTextOptions: getTextOptions
    };

    /**
    *
    * @param model
    * @returns {TextOptions}
    */
    function getTextOptions(model) {
        return new TextOptions(model);
    }

    /**
    *
    * @param model
    * @returns {TextOptions}
    * @constructor
    */
    function TextOptions(model) {
        model = model || {};
        var self = this;

        self.minHeight = _.isUndefined(model.minHeight) ? '100%' : model.minHeight;
        self.focus = _.isUndefined(model.focus) ? true : model.focus;
        self.airMode = _.isUndefined(model.airMode) ? false : model.airMode;
        self.dialogsFade = _.isUndefined(model.dialogsFade) ? true : model.dialogsFade;
        self.dialogsInBody = _.isUndefined(model.dialogsInBody) ? true : model.dialogsInBody;
        self.disableResizeEditor = _.isUndefined(model.disableResizeEditor) ? true : model.disableResizeEditor;
        self.placeholder = _.isUndefined(model.placeholder) ? model.placeholder : gettextCatalog.getString('Please enter text for your Executive Summary');
        self.maximumImageFileSize = 5242880;
        self.callbacks = {
            onImageUploadError: function () {
                UIFactory.notify.showError(gettextCatalog.getString('Image is too large'));
            }
        };
        self.toolbar = _.isUndefined(model.toolbar)
        ? [
            ['headline', ['style']],
            ['fontface', ['fontname']],
            ['textsize', ['fontsize']],
            ['fontclr', ['color']],
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
            ['height', ['height']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['fullscreen', 'codeview']],
            ['edit', ['undo', 'redo']],
            ['help', ['help']]
        ] : model.toolbar;
        self.fontNames = _.isUndefined(model.fontNames)
        ? ["Arial","Arial Black","Comic Sans MS","Courier New","Helvetica Neue","Helvetica","Impact","Lato", "Lucida Grande", "Tahoma","Times New Roman","Verdana"]
        : model.fontNames;

        return self;
    }
}
