'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

angular.module('widget.filter.ctrls')

    .controller('FilterItemController', FilterItemController)
    .controller('FilterItemDescriptionController', FilterItemDescriptionController)
    .filter('formattedFilterNumber', formattedFilterNumber);


/**
 * @ngInject
 */
function FilterItemController(
    $scope,
    $timeout,
    FilterParam,
    DesignFactory,
    ClientFactory,
    DataSourceFactory,
    DashboardContextService,
    ReportStudioTemplateDataService,
    DataSourceType,
    AppFactory,
    gettextCatalog,
    PubSub,
    $WidgetFilterEvents,
    LiveIntegrationFactory,
) {
    $scope.FilterParam = FilterParam;
    var filter = $scope.filter;
    var columnUtil = AppFactory.util.column;

    filter.isNumericFilter = columnUtil.isNumeric(filter.format);
    filter.isDateFilter = columnUtil.isDate(filter.format);
    filter.isTextFilter = columnUtil.isText(filter.format);
    filter.isBooleanFilter = columnUtil.isBoolean(filter.format);
    filter.hasAssociateFilter = !_.isEmpty(filter.associate_filter);
    $scope.isEmptyTextFilter = isEmptyTextFilter;
    $scope.canExposeAsDashFilter = canExposeAsDashFilter;
    $scope.canExposeReportFilter = canExposeReportFilter;
    $scope.dashFilterAlreadyExists = dashFilterAlreadyExists;
    $scope.getWildcardSearchTips = getWildcardSearchTips;
    $scope.reportFilterAlreadyExists = reportFilterAlreadyExists;
    $scope.getAssociateFilterText = getAssociateFilterText;
    $scope.toggleAssociateFilter = toggleAssociateFilter;
    $scope.$watch('filter.selectableValues', function(nV, oV) {
        filter.associate_filter.selectableValues = nV;
        PubSub.emit($WidgetFilterEvents.UPDATE_ASSOCIATE_FILTER, {targetId: filter.field+'associate',selectedValues: filter.values});
    });
    $scope.$watch('filter.hasMoreData', function(nV, oV) {
        filter.associate_filter.hasMoreData = nV;
    });

    function toggleAssociateFilter(hasAssociateFilter)
    {
        if (hasAssociateFilter) {
            PubSub.emit($WidgetFilterEvents.UPDATE_ASSOCIATE_FILTER, {targetId: filter.field+'associate',selectedValues: filter.values});
        } else {
            filter.associate_filter = angular.copy(filter);
            filter.associate_filter.values = [];
            PubSub.emit($WidgetFilterEvents.UPDATE_ASSOCIATE_FILTER, {targetId: filter.field,selectedValues: []});
        }
    }
    toggleAssociateFilter(filter.hasAssociateFilter);
    $scope.exposeDashFilterTooltip = gettextCatalog.getString(
        'If enabled, {{label}} filter will be interactive at the dashboard level.',
        {
            label: $scope.filter.label
        });
    $scope.limitValuesTooltip = gettextCatalog.getString(
        'If enabled and \'Include\' option is set, user can ONLY filter by selected {{label}}s. If \'Exclude\', user can filter by {{label}}s not selected.',
        {
            label: $scope.filter.label
        });
    $scope.dashFilterAlreadyExistsTooltip = gettextCatalog.getString(
        'Option is currently disabled since the {{label}} filter was already added through another widget.',
        {
            label: $scope.filter.label
        });
    /**
     * @returns {*|Array|boolean}
     */
    function isEmptyTextFilter() {
        var filter = $scope.filter;
        const { hasAssociateFilter, associate_filter } = filter.hasAssociateFilter;
        const isEmptyAssociateFilter = !(hasAssociateFilter && !(_.isUndefined(associate_filter.selectableValues) || _.isEmpty(associate_filter.selectableValues)));
        return isEmptyAssociateFilter && !filter.hasMoreData && filter.isTextFilter && (_.isUndefined(filter.selectableValues) || _.isEmpty(filter.selectableValues)) && !filter.timeout;
    }

    function getAssociateFilterText() {
        if ($scope.filter.type === FilterParam.FILTER_IN) {
            return 'Exclude filters';
        }
        return 'Include filters';
    }

    $scope.getAssociateFilterToolTip = "If enabled and the ‘include’ option is set, the user will have the option to add ‘exclude’ filter value(s). If the ‘exclude’ option is set, the user will have the option to add ‘include’ filter value(s)";

    /**
     * @returns {String}
     */
    function getWildcardSearchTips() {
        return "<p style=\\'text-align: left;\\'>You can use the * character to denote a wildcard match on any character, either in the front / back / or middle of a string. <br><br>Usage Examples: <br>1) blog* = matches any word that STARTS with blog <br>2) *blog = matches any word that ENDS with blog <br>3) *blog* = matches any word that has blog in it</p>"
    }

    /**
     * @returns {boolean}
     */
    function canExposeAsDashFilter() {
        if (ReportStudioTemplateDataService.getIsActive() || DesignFactory.getCurrentPage().is_predefined) {
            return false;
        }
        return checkFilterType();
    }

    function canExposeReportFilter() {
        if (ReportStudioTemplateDataService.getIsActive() && !ReportStudioTemplateDataService.isGlobalFilterEnabled()) {
            return checkFilterType();
        }
        return false;
    }

    function checkFilterType() {
        var currentWidget = DashboardContextService.resolveWidgetFromId($scope.widgetId);
        var dataSourceType = currentWidget.metadata.data_source.type;
        return !ClientFactory.isClientFilter($scope.filter)
            && !DataSourceFactory.isIODataSourceType(dataSourceType)
            && dataSourceType !== DataSourceType.LEADS;
    }

    /**
     * Check if filter can be exposed as dashboard filter based on if it is already used in another widget
     * @returns {boolean}
     */
    function dashFilterAlreadyExists() {
        return DashboardContextService.dashFilterAlreadyExists($scope.widgetId, filter);
    }

    function reportFilterAlreadyExists() {
        var alreadyExists = false;

        var currentWidget = DashboardContextService.resolveWidgetFromId($scope.widgetId);
        const globalFilters = ReportStudioTemplateDataService.getGlobalFilters() || {};

        _.each(ReportStudioTemplateDataService.getWidgetElements(), function(element) {
            // If already found a match we can stop looking
            const widget = element.widget;
            if (alreadyExists) {
                return;
            }
            // Ignore yourself
            if ($scope.widgetId == widget.id && filter.expose_as_report_filter) {
                return false;
            }
            var filterDataSource = currentWidget.metadata.data_source;
            var widgetDataSource = widget.metadata.data_source;
            // Current filter and widget have matchin data source info
            if (widgetDataSource
                && widgetDataSource.type === filterDataSource.type
                && widgetDataSource.id === filterDataSource.id
                && widgetDataSource.data_view === filterDataSource.data_view
            ) {
                alreadyExists =_.some(widget.metadata.dynamic.filters, {
                    expose_as_report_filter: true,
                    field: filter.field
                });
            }
        });
        return alreadyExists;
    }
}

/**
 * Individual filter list item description
 * @ngInject
 */
function FilterItemDescriptionController(
    $scope,
    $timeout,
    FilterParam,
    RelativeDateRange,
    AppFactory,
    DateRangeFactory,
    PubSub,
    $WidgetFilterEvents
) {
    $scope.FilterParam = FilterParam;
    $scope.renderTooltip = true;

    $scope.showFilterDescription = showFilterDescription;
    $scope.setTooltipValues = setTooltipValues;
    $scope.getRangePrefix = getRangePrefix;
    $scope.getRelativeDateRange = getRelativeDateRange;
    $scope.setAssociateFilterTooltipValues = setAssociateFilterTooltipValues;

    var filter = $scope.filter;
    var columnUtil = AppFactory.util.column;

    filter.isNumericFilter = columnUtil.isNumeric(filter.format);
    filter.isDateFilter = columnUtil.isDate(filter.format);
    filter.isTextFilter = columnUtil.isText(filter.format);
    filter.isBooleanFilter = columnUtil.isBoolean(filter.format);

    // Defaults
    filter.association = filter.association || FilterParam.ASSOCIATION_AND;
    filter.expose_as_dash_filter = !_.isNil(filter.expose_as_dash_filter)
        ? filter.expose_as_dash_filter
        : false;
    filter.limit_available_values = !_.isNil(filter.limit_available_values)
        ? filter.limit_available_values
        : false;

    if (filter.isTextFilter) {
        setTooltipValues();
    }

    function showFilterDescription() {
        if (filter.isTextFilter) {
            if (_.isEmpty(filter.values)) {
                return false;
            }
        } else if (filter.isNumericFilter) {
            if (_.isNull(filter.values.min) && _.isNull(filter.values.max)) {
                return false;
            }
        } else if (filter.isDateFilter) {
            if (filter.values.custom && (_.isNil(filter.values.start) && _.isNil(filter.values.end))) {
                return false;
            } else if (!filter.values.custom  && (_.isNil(filter.values.relative) || filter.values.relative === RelativeDateRange.DEFAULT)) {
                return false;
            }
        }

        return true;
    }

    function setTooltipValues() {
        $scope.tooltipValues = _.map($scope.filter.values, 'text').join(', ');
    }

    function setAssociateFilterTooltipValues() {
        $scope.associateFilterTooltipValues = _.map($scope.filter.associate_filter?.values, 'text').join(', ');
    }

    function getRangePrefix(value) {
        if (value.contains('last')) {
            return 'is in the';
        }
        else {
            return 'is';
        }
    }

    function getRelativeDateRange(value) {
        if (value && value !== RelativeDateRange.CUSTOM) {
            return DateRangeFactory.getRelativeDateRange(value).label;
        }
    }

    $scope.$on($WidgetFilterEvents.SET_TOOLTIP_VALUES, function() {
        $scope.renderTooltip = false;
        $timeout(function() {
            $scope.renderTooltip = true;
            setTooltipValues();
        });
    });

    PubSub.on($WidgetFilterEvents.UPDATE_ASSOCIATE_FILTER, function(payload) {
        setAssociateFilterTooltipValues();
    });
}

/**
 * @ngInject
 */
function formattedFilterNumber(
    ColumnFormat,
    AppModelFactory,
    MomentDateFormat
) {
    return function(val, options) {
        if (options.format === ColumnFormat.FORMAT_TIME) {
            return val;
        } else if (options.format === ColumnFormat.FORMAT_DATE || options.format === ColumnFormat.FORMAT_DATETIME) {
            return moment.unix(val).format(MomentDateFormat.MONTH_DAY_YEAR);
        } else if (_.isNull(val) || isNaN(val)) {
            return 'N/A';
        }

        var decimals = options.format == ColumnFormat.FORMAT_PERCENT
        || options.format == ColumnFormat.FORMAT_CURRENCY
        || options.format == ColumnFormat.FORMAT_DECIMAL
            ? options.precision : 0;

        return $.fn.formatNumber(val, options.format, decimals);
    };
}