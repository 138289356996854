'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import initAngularTemplatedDirectives from './app.services';
import formRedirectorHtmlUrl from '../views/directives/formredirector.html';
import formSubmitConfirmModalHtmlUrl from '../views/directives/formsubmitconfirmmodal.html';
import textToInputHtmlUrl from '../views/directives/texttoinput.html';
import 'bootstrap-fileinput/js/locales/fr';

angular.module('core.form.directives', [])
    .directive('inputLoading', inputLoading)
    .directive('dynamicForm', dynamicForm)
    .directive('showErrors', showErrors)
    .directive('formRedirector', formRedirector)
    .directive('formSubmitConfirmModal', formSubmitConfirmModal)
    .directive('textToInput', textToInput)
    .directive('uploadFileInput', uploadFileInput);

/**
 * Loading wheel directive
 * @returns {{restrict: string, template: string}}
 */
function inputLoading() {
    return {
        restrict: 'E',
        template: '<div class="input-loading"><div class="loading-wheel"></div><span>Loading...</span></div>'
    }
}

/**
 * FORMS - Dynamic form directive
 * @type {string[]}
 * @ngInject
 */
function dynamicForm(
    $timeout,
    $http,
    $state,
    $templateCache,
    AppService,
    AppFactory,
    $location,
    gettextCatalog
) {
    return {
        restrict: 'A',
        controller: function($scope) {
            //an array of files selected
            $scope.files = [];

            //listen for the file selected event
            $scope.$on('fileSelected', function (event, args) {
                $scope.$apply(function () {
                    //add the file object to the scope's files collection
                    $scope.files.push(args.file);
                });
            });
        },
        link: function (scope, el, attrs) {
            scope.$root.footer = scope.$root.footer || {};
            scope.$root.footer.saveButtonText = attrs.saveButtonText || gettextCatalog.getString('Save changes');
            scope.$root.footer.cancelButtonText = attrs.cancelButtonText || gettextCatalog.getString('Cancel');
            scope.$root.footer.confirmMessage = attrs.confirmMessage || gettextCatalog.getString('Are you sure you want to continue?');

            var isAngularForm = scope.model != null;
            //Set a timeout to let angular set the form values
            //@todo: set this to be triggered when fields are full rendered (i.e. only show form once all this is done)
            $timeout(function() {
                if (isAngularForm) {
                    scope.originalModel = {};
                    $.extend(true, scope.originalModel, scope.model);

                    scope.$watch('model', function() {
                        JSON.stringify(scope.originalModel) != JSON.stringify(scope.model) ? $.core.main.showFooter() : $.core.main.hideFooter();
                    }, true);
                }
                //Ignore form compare if is angular form
                //(i.e. don't use initial state data stored in input to restore, instead use data model and originalModel objects)
                $.core.main.dynamicForm(el,
                    isAngularForm,
                    _.isUndefined(attrs.ignoreFormChange) ? null : attrs.ignoreFormChange.bool(),
                    _.isUndefined(attrs.forceRefreshOnCancel) ? null : attrs.forceRefreshOnCancel.bool(),
                    _.isUndefined(attrs.needsConfirmation) ? null : attrs.needsConfirmation.bool()
                );

                if (attrs.autoShowFooter && attrs.autoShowFooter.bool()) {
                    $timeout(function () {
                        $.core.main.showFooter();
                    }, 0);
                }
            }, 1000);

            //In angular we need to restore the model based on a saved original model
            if (isAngularForm) {
                $.core.main.restoreFormCallback = function() {
                    scope.$apply(function() {
                        scope.model = {};
                        $.extend(true, scope.model, scope.originalModel);
                    });
                };
            }

            el.submit(function(e) {
                function callCallbacks(json, model) {
                    if (attrs.callback != null) {
                        var callbacks = attrs.callback.split(' ');
                        for (var i=0; i<callbacks.length; i++){
                            scope[callbacks[i]](json, model);
                        }
                    }
                }

                var $form = $(this);
                if (!attrs.customSubmit) {
                    e.preventDefault();
                    var $footer = $('footer');
                    var $buttons = $footer.find('div.btn');
                    $buttons.disableElement();

                    var url = isAngularForm ? scope.$root.util.apiUrl(($form.attr('action'))) : $form.attr('action');
                    var model = isAngularForm ? scope.model : $form.serializeObject();
                    var files = scope.files;

                    //Execute a pre submit callback scope fn
                    if (attrs.preSubmit) {
                        scope[attrs.preSubmit](model);
                    }

                    AppService.submitForm(url, model, files).success(function(json) {
                        $buttons.enableElement();
                        if (json.status == 'error') {
                            $.core.main.formErrorNotify(json);
                            callCallbacks(json,model);
                        } else if (typeof json == 'string') {
                            // PHP returned a dev legible error so display a user friendly one instead but log it for our use
                            console.log(json);
                            $.core.main.formErrorNotify(gettextCatalog.getString('An error occurred while trying to update this resource.'));
                        } else if (json.status == 'redirect') {
                            return false; //Redirect is handled in httpinterceptor in app.js
                        } else {
                            if (!isAngularForm) {
                                //If new entity, update the hidden field so that update now treats as edit
                                //NOTE: in angular form using model not form object, this is handled during callback in form.directives.js
                                if ($state.current.data != null && $state.current.data.isNewEntity) {
                                    $('input[name='+ $state.current.data.primaryKey +']').val(json.data.id);
                                }

                                //For now, to ensure consistent data we clear the cache when saving an entity
                                //This will be fixed when porting views to angular 2 way binding methodology
                                $templateCache.removeAll();
                                initAngularTemplatedDirectives($templateCache);
                            }

                            if (json.status == 'error') {
                                //Execute a post submit callback scope fn on error as well
                                callCallbacks(json,model);
                            } else {
                                $.core.main.hideAllNotifies();
                                $.core.main.hideFooter();

                                //Reset for form restore capabilities
                                $.core.main.initForm($form);
                                $.core.main.formHasChanged = false;

                                //Execute a post submit callback scope fn
                                callCallbacks(json,model);

                                if (isAngularForm) {
                                    //Update page title with new entity name
                                    if (scope.entity.nameKey) {
                                        scope.entity.pageTitle = (scope.entity.parentTitle ? scope.entity.parentTitle + ': Edit ' : 'Edit ') + model[scope.entity.nameKey];
                                        AppFactory.setPageTitle(scope.entity.pageTitle);
                                    }
                                    //Update save action with new entity id (only happens on add)
                                    if (scope.model[scope.entity.idKey] == null) {
                                        // add a slash if not present
                                        scope.entity.action += (scope.entity.action.substr(-1) != '/' ? '/' : '') + json.data.id;
                                    }
                                    //Update id so that subsequent saves are edits not add
                                    if (scope.entity.idKey) {
                                        scope.model[scope.entity.idKey] = json.data.id;

                                        if (scope.entity.redirection_on_new) {
                                            var new_path = scope.entity.redirection_on_new.replace(':id', json.data.id);
                                            $location.path(new_path);
                                        }
                                    }

                                    //Re-initialize the original model
                                    scope.originalModel = {};
                                    $.extend(true, scope.originalModel, scope.model);
                                }

                                //Show success message
                                if (json.data != null && json.data.message != null) {
                                    $.core.main.notify(json.data.message, $.globals.notify.success);
                                }
                                else if (scope.entity && model[scope.entity.nameKey]) {
                                    $.core.main.notify('successfully saved', $.globals.notify.success, {title: model[scope.entity.nameKey]});
                                }
                                else {
                                    $.core.main.notify('Save successful', $.globals.notify.success);
                                }

                                if (scope.is_smart_connector && scope.isEditing) {
                                    scope.reloadImportWizardSublistListController();
                                    AppFactory.refreshServices();
                                }

                                if (attrs.showRedirector != null && attrs.showRedirector.bool()) {
                                    scope.$root.redirector.show = true;

                                    // We might want to add a param to the redirect
                                    // NOTE: make sure not to re-add redirect param in subsequent updates
                                    var redirectParam = json.data[scope.$root.redirector.param.key];
                                    if (redirectParam != null && !scope.$root.redirector.link.contains(redirectParam)) {
                                        scope.$root.redirector.link += '/' + redirectParam;
                                    }

                                }
                                else {
                                    scope.$root.redirector.show = false;
                                }

                                //Reset the file scope for subsequent file upploads
                                scope.files = [];

                                //Temp solution to allow refresh nav menu for .phtml file
                                if (scope.entity && scope.entity.redrawServiceNavOnSave) {
                                    $http.get(scope.$root.util.mageUrl('layout/servicelistmenu')).success(function(html) {
                                        $('#datasources-submenu').html(html);
                                    });

                                    AppFactory.refreshServices();

                                    if (scope.$root.util.isFeatureAvailable('categories')) {
                                        AppFactory.refreshCategories();
                                    }
                                }
                            }
                        }
                    }).error(function(json) {
                        $buttons.enableElement();

                        //Execute a post submit callback scope fn on error as well
                        callCallbacks(json,model);
                    });
                }
            });
        }
    }
}

/**
 * Show errors for forms
 * @returns {{restrict: string, require: string, link: link}}
 */
function showErrors() {
    return {
        restrict: 'A',
        require:  '^form',
        link: function (scope, el, attrs, formCtrl) {
            // find the text box element, which has the 'name' attribute
            var inputEl   = el[0].querySelector("[name]");
            // convert the native text box element to an angular element
            var inputNgEl = angular.element(inputEl);
            // get the name on the text box so we know the property to check
            // on the form controller
            var inputName = inputNgEl.attr('name');

            // only apply the has-error class after the user leaves the text box
            scope.$on('show-errors-check-validity', function() {
                el.toggleClass('has-error', formCtrl[inputName].$invalid);
            });

            inputNgEl.bind('blur', function() {
                if (!formCtrl[inputName].$invalid) {
                    el.toggleClass('has-error', formCtrl[inputName].$invalid);
                }
            });
        }
    }
}

/**
 * Directive for post form callback redirection
 * @returns {{restrict: string, templateUrl: *}}
 */
function formRedirector() {
    return {
        restrict: 'E',
        templateUrl: formRedirectorHtmlUrl
    }
}

/**
 * Directive for pre submit confirm modal (continue with submit or not?)
 * @returns {{restrict: string, scope: {message: string}, templateUrl}}
 * @ngInject
 */
function formSubmitConfirmModal(
    $timeout
) {
    return {
        restrict: 'E',
        scope: {
            message: '<'
        },
        templateUrl: formSubmitConfirmModalHtmlUrl,
        link: function(scope, el) {
            var $modal = el.find('div.modal');

            $modal.on('show', function () {
                // Timeout allows to not execute the enter submit right
                // away if the event that lead here was another enter key
                $timeout(function() {
                    setEnterKeyBinding();
                }, 100);
            });

            $modal.on('hidden', function () {
                unsetEnterKeyBinding();
            });

            var enterFnHandler = function (event) {
                event.preventDefault();
                if(event.which === 13) { //Enter is pressed
                    $modal.modal('hide');
                    $.core.main.submitForm();
                }
            };

            function setEnterKeyBinding() {
                angular.element(document).bind('keydown keypress', enterFnHandler);
            }

            function unsetEnterKeyBinding() {
                angular.element(document).unbind('keydown keypress', enterFnHandler);
            }
        },
        controller: function ($scope) {
            $scope.submitForm = function() {
                $.core.main.submitForm();
            }
        }
    }
}

/**
 * Converts a text field into an edit input field
 * @returns {{}}
 */
function textToInput() {
    return {
        restrict: 'A',
        scope: {
            property: '@', // Property of object to update
            label: '<',
            disabled: '<',
            options: '<'
        },
        templateUrl: textToInputHtmlUrl,
        controller: function($scope) {
            $scope.model = {};
            $scope.model.label = $scope.label;
            $scope.model.value = $scope.label;

            $scope.save = function() {
                var value = $scope.model.value;
                $scope.$root.$broadcast('textToInput:saveEvent:' + $scope.property, value);
                // Update label
                if ($scope.resetOnSave) {
                    $scope.model.label = null;
                    $scope.model.value = null;
                }
                else {
                    $scope.model.label = value;
                }
            };
            $scope.remove = function(value) {
                $scope.$root.$broadcast('textToInput:deleteEvent:' + $scope.property, value);
                $scope.model.label = null;
                $scope.model.value = null;
            };

            $scope.cancel = function() {
                $scope.model.value = $scope.model.label;
            }
        },
        link: function (scope, el) {

            var $container = el.find('div.text-to-input');

            /**
             * Click anywhere else to dismiss
             */
            function setWindowClickBinding() {
                angular.element(document).bind('click', scope.cancelHelper);
            }

            function unsetWindowClickBinding() {
                angular.element(document).unbind('click', scope.cancelHelper);
            }

            scope.setActive = function() {
                if (!scope.disabled) {
                    if (scope.options) {
                        scope.options.isActive = true;
                    }
                    $container.addClass('active');
                    el.find(scope.type).focus();
                    setWindowClickBinding();
                } else {
                    scope.cancelHelper();
                }
            }

            // Save helper
            scope.saveHelper = function() {
                if (!scope.options.forceActive) {
                    $container.removeClass('active');
                }
                scope.save();
            }

            // Cancel helper
            scope.cancelHelper = function() {
                if (!scope.options.forceActive) {
                    $container.removeClass('active');
                }
                scope.$evalAsync(function () {
                    scope.model.value = scope.model.label;
                    if (scope.options) {
                        scope.options.isActive = false;
                    }
                });
                unsetWindowClickBinding();
                scope.cancel();
            }

            if (scope.options.forceActive) {
                scope.setActive();
            }
            scope.model = {};
            scope.model.label = scope.label;
            scope.model.value = scope.label;
            scope.canDelete = false;
            if (scope.options) {
                scope.isTextarea = scope.options.type && scope.options.type === 'textarea';
                scope.model.placeholder = scope.options.placeholder;
                scope.resetOnSave = scope.options.resetOnSave;
                scope.canDelete = scope.options.canDelete;
                scope.hideCancel = scope.options.hideCancel;

                scope.$on('textToInput:setActive', function(e, isActive) {
                    if (isActive) {
                        scope.setActive();
                    } else {
                        scope.cancelHelper();
                    }
                });
            }

            // Press enter while inputting
            el.find(scope.type).keyup(function(e) {
                var code = e.keyCode || e.which;
                if (code === 13) {
                    scope.saveHelper();
                } else if (code === 27) {
                    scope.cancelHelper();
                }
            });

            scope.$on('$destroy', function() {
                unsetWindowClickBinding();
            })
        }
    }
}

/**
 * Directive for bootstrap file input
 * @returns {{restrict: string, link: link}}
 * @ngInject
 */
function uploadFileInput(gettextCatalog) {
    return {
        restrict: 'A',
        link: function(scope, el, attrs) {
            var controllerScope = scope.vm ? scope.vm : scope;

            if (!scope.util) {
                scope.util = scope.$root.util;
            }

            attrs.previewFileType = attrs.previewFileType == null ? 'image' : attrs.previewFileType;
            attrs.showPreview = attrs.showPreview == null ? true : attrs.showPreview.bool();
            attrs.browseLabel = attrs.browseLabel == null ? 'Select image' : attrs.browseLabel;
            attrs.showUpload = attrs.showUpload == null ? false : attrs.showUpload.bool();
            attrs.showRemove = attrs.showRemove == null ? true : attrs.showRemove.bool();
            attrs.uploadUrl = attrs.uploadUrl == null ? null : attrs.uploadUrl;
            attrs.isMageUrl = attrs.isMageUrl == null ? true : attrs.isMageUrl.bool();
            attrs.autoUpload = attrs.autoUpload === "true";

            var options = {
                dropZoneEnabled: false,
                uploadAsync: false,
                showPreview: attrs.showPreview,
                showRemove: attrs.showRemove,
                showUpload: attrs.showUpload,
                showCaption: !attrs.showPreview,
                previewFileType: attrs.previewFileType,
                previewSettings: {
                    object: {width: '325px', height: '160px'}
                },
                browseLabel: attrs.browseLabel,
                browseIcon: attrs.icon == null ? '<i class="icon icomoon-image"></i>' : '<i class="icon '+attrs.icon+'"></i>',
                removeClass: "btn btn-danger",
                removeIcon: '<i class="icon icon-trash"></i>',
                uploadClass: "btn btn-info",
                uploadIcon: '<i class="icon icon-upload"></i>',
                showAjaxErrorDetails: false,
                language: window.appLocale
            };

            if (_.isObject(controllerScope.fileInputOptions)) {
                angular.extend(options, controllerScope.fileInputOptions);
            }

            if (attrs.uploadUrl != null) {
                options.uploadUrl = attrs.isMageUrl ? scope.util.mageUrl(attrs.uploadUrl) : scope.util.apiUrl(attrs.uploadUrl);

                //If including extra data, we need to wait for angular to render the value before initializing the file input
                if (attrs.uploadExtraData != null) {
                    attrs.$observe('uploadExtraData', function (value) {
                        options.uploadExtraData = {'id': value};
                        if (value != '') {
                            initUpload();
                        }
                    });
                } else {
                    initUpload();
                }
            } else {
                el.on('fileloaded', function(event, file) {
                    file['key'] = $(this).attr('name');
                    scope.$emit('fileSelected', { file: file, element: $(this) });
                    if (attrs.onFileSelected != null) {
                        controllerScope[attrs.onFileSelected]();
                    }
                });
                initUpload();
            }

            function initUpload() {
                let success = function(event, json) {
                    el.fileinput('clear');
                    $.core.main.notify('Upload successful', $.globals.notify.success);

                    //Execute a post submit callback scope fn
                    if (attrs.uploadCallback != null) {
                        controllerScope[attrs.uploadCallback](json.response.data ? json.response.data : json.response);
                    }
                };

                el.fileinput(options).on('filebatchuploadsuccess', success).on('filebatchuploaderror', function(event, json) {
                    el.fileinput('clear');
                    // From API error returned, we could have nothing in response, but a response from json.jqXHR.responseJSON
                    var error = json.response.error;
                    if (!error && json.jqXHR.responseJSON) {
                        error = json.jqXHR.responseJSON.error;
                    }
                    if (error) {
                        //formErrorNotify gets error, "cannot read property 'data' of undefined", since error.data does
                        //not exist... Should be inputting json instead
                        if (!json.jqXHR.responseJSON && !json.jqXHR.responseJSON.data) {
                            $.core.main.formErrorNotify(gettextCatalog.getString('Error: failed to upload data'));
                        } else {
                            $.core.main.formErrorNotify(gettextCatalog.getString(json.jqXHR.responseJSON.data[0]));
                        }
                        //Execute a post submit error callback scope fn
                        if (attrs.errorCallback != null) {
                            controllerScope[attrs.errorCallback](json);
                        }
                    } else {
                        success(event, json);
                    }
                });

                if (attrs.autoUpload) {
                    el.on("filebatchselected", function() {
                        // trigger upload method immediately after files are selected
                        el.fileinput("upload");
                    });
                }

                if (attrs.change) {
                    // ng-change is not triggered
                    // Use this to bind a function to change from angular
                    el.on("fileloaded", function(e, file, previewId, index, reader) {
                        // trigger upload method immediately after files are selected
                        controllerScope[attrs.change](file, index);
                    });

                    el.on("fileclear", function(e) {
                        // trigger upload method immediately after files are selected
                        controllerScope[attrs.change]();
                    });
                }
            }

            if (attrs.id && controllerScope.clearFileInput != null) {
                scope.$watch(function () {
                    return scope.vm.clearFileInput
                }, function (nV, oV) {
                    if (nV) {
                        $('#' + attrs.id).fileinput('clear');
                        scope.vm.clearFileInput = false;
                    }
                });
            }
        }
    }
}