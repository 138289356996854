'use strict';
import angular from 'angular';
import './history.constants';
import './history.components';
import './history.services';

angular.module('core.history', [
    'core.history.constants',
    'core.history.components',
    'core.history.services'
]);