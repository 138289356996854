'use strict';
import angular from 'angular';
import moment from 'moment';
import $ from 'jquery';

angular.module('core.app.filters', [])

    .filter('iif', iif)
    .filter('widgetNumber', widgetNumber)
    .filter('capitalize', capitalize)
    .filter('trustHtml', trustHtml)
    .filter('trustUrl', trustUrl)
    .filter('formatDate', formatDate)
    .filter('osSpecificCommandButton', osSpecificCommandButton);


//
// Ternary operator filter
//
function iif() {
    return function(input, trueValue, falseValue) {
        return input ? trueValue : falseValue;
    };
}

//
// Formatting for widget numbers
//
function widgetNumber() {
    return function(val, format) {
        var decimals = format == 'percent' || format == 'currency' || format == 'decimal'? 2 : 0;
        var digit_reduce = format == 'decimal' ? null : 2;
        return $.fn.formatNumber(val, format, decimals, digit_reduce);
    };
}

//
// Capitalize first letter of word
//
function capitalize() {
    return function(input) {
        return (!!input) ? input.replace(/([^\W_]+[^\s-]*) */g, function(txt){
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }) : '';
    };
}

/**
 * If from a trusted source, allow unsafe html. Ex: <iframe/>
 * @ngInject
 */
function trustHtml($sce) {
    return function(html) {
        return $sce.trustAsHtml(html);
    };
}

/**
 * If from a trusted source, allow unsafe src. Ex: <iframe/>
 * @ngInject
 */
function trustUrl($sce) {
    return function(url) {
        return $sce.trustAsResourceUrl(url);
    };
}

/**
 * Returns a pretty formatted date
 * @ngInject
 */
function formatDate(LanguageService) {
    return function(date) {
        return moment.unix(date).isValid()
            ? moment.unix(date).utc().format(LanguageService.getDisplayDateFormat())
            : moment(date).format(LanguageService.getDisplayDateFormat());
    };
}

function osSpecificCommandButton() {
    return function(inputString) {
        var isMacOS = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        return inputString.replace(new RegExp('{OS_SPECIFIC_COMMAND}', 'g'), isMacOS ? 'CMD' : 'CTRL');
    };
}