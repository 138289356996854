import { mapValues } from 'lodash';
import { Layout } from '@/modules/ta/dashboard/models/Layout';
import { DashboardPage } from '@/modules/ta/dashboard/models/DashboardPage';

export class Dashboard extends DashboardPage {
  /**
   * @var {Object}
   */
  layouts = {};

  constructor(model = {}) {
    super(model);

    this.layouts = mapValues(model.layouts, (layout) => new Layout(layout));
  }

  /**
   * @override
   * @param {Object} model
   * @returns {Dashboard}
   */
  static factory(model) {
    model = model.page || model;
    return super.factory(model);
  }
}
