'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.tapcolors.services', [])
    .provider('TapColorsService', TapColorsServiceProvider);

/**
 * TapColorsService
 * @param colors
 * @param size
 * @param defaultColor
 * @param dismissOnSelection
 * @param dismissIfOutside Boolean determines if the color picker is dismiss if we click outside of it
 * @param animationSpeed
 * @param autoShow
 * @constructor
 */
function TapColorsService (colors, size, defaultColor, dismissOnSelection, dismissIfOutside, animationSpeed, autoShow) {
    var self = this;
    self.originalColors = colors;
    self.colors = angular.copy(self.originalColors);
    self.size = size;
    self.defaultColor = defaultColor;
    self.dismissOnSelection = dismissOnSelection;
    self.dismissIfOutside = dismissIfOutside;
    self.animationSpeed = animationSpeed;
    self.autoShow = autoShow;
    self.isActive = false;

    self.getRandomColor = getRandomColor;
    self.setThemeColors = setThemeColors;

    /**
     * Returns a randomly selected color from the predefined colors passed in constructor above
     * @returns {*} color
     */
    function getRandomColor() {
        var min = 0;
        var max = self.colors.length - 1;
        var index = Math.floor(Math.random() * (max - min + 1)) + min;
        return self.colors[index];
    }

    /**
     * Sets the primary and secondary colors inside the color picker
     * @param baseColors
     */
    function setThemeColors(baseColors) {
        self.colors = angular.copy(self.originalColors);
        self.colors = self.colors.concat(baseColors);
        self.colors = _.uniqBy(self.colors);
    }
}

/**
 * TapColors Service Provider
 * During the angular config phase, new colors can be injected to override the default ones
 */
function TapColorsServiceProvider () {
    /**
     * Default colors
     * @type {[*]}
     */
    var colors = [
        '#FFFFFF',  // white
        '#FFDA1D', // yellow
        '#009630', // green
        '#00B19B', // persian
        '#1247A6', // blue
        '#6850C3', // violet
        '#B13BA9', // rose
        '#C91814', // red,
        '#F56021', // orange
        '#F8F8F8', // off white
        '#303030' // light black shade
    ];
    /**
     * Default Color picker size
     * @type {number}
     */
    var size = 300;

    /**
     * When the color picker has a new color selected, boolean determines if a dismiss animation is automatic
     * @type {boolean}
     */
    var dismissOn = false;

    /**
     * Initial color to appear in the middle of the color picker
     * @type {undefined}
     */
    var defaultColor; // intentionally undefined

    /**
     * Dismiss color picker if click event outside of its boundary
     * @type {boolean}
     */
    var dismissIfOutside = true;

    /**
     * Animation speed when color picker appears
     * @type {number}
     */
    var animationSpeed = 100;

    /**
     * Show when appears on screen
     * @type {boolean}
     */
    var autoShow = false;

    /**
     * Setter to override default colors
     * @param newColors
     */
    this.setColors = function (newColors) {
        colors = newColors;
        return this;
    };

    /**
     * Setter to override default size
     * @param newSize
     */
    this.setSize = function (newSize) {
        if (newSize > 0) {
            size = newSize;
        }
        return this;
    };

    /**
     * Auto dismiss if clicked inside
     * @param dismissOnSelection
     * @returns {TapColorsServiceProvider}
     */
    this.setDismissOnSelection = function (dismissOnSelection) {
        dismissOn = dismissOnSelection;
        return this;
    };

    /**
     * Auto dismiss if clicked outside
     * @param dismissIfClickedOutside
     */
    this.setDismissIfClickedOutside = function (dismissIfClickedOutside) {
        dismissIfOutside = dismissIfClickedOutside;
        return this;
    };

    /**
     * Default color in the middle of the pie
     * @param color
     */
    this.setDefaultColor = function (color) {
        defaultColor = color;
        return this;
    };

    /**
     * Sets Animation speed in milliseconds how fast the color picker will appear
     * @param speed
     */
    this.setAnimationSpeed = function (speed) {
        animationSpeed = speed;
        return this;
    };

    /**
     * Control if we show the picker when on screen right away
     * @param show
     */
    this.setAutoShow = function (show) {
        autoShow = show;
        return this;
    };

    /**
     * Provider interface
     * @type {[*]}
     */
    this.$get = [function tapColorsServiceFactory () {
        return new TapColorsService(colors, size, defaultColor, dismissOn, dismissIfOutside, animationSpeed, autoShow);
    }];
}