"use strict";
import angular from "angular";

// Widget data draw options
export const DrawOption = {
  // Type the draw options applies to
  TYPE_ALL: "widget_all", // Applies to look and feel of all widgets
  TYPE_WIDGET: "widget_type", // Applies to how data is displayed for that widget type
  TYPE_REPORT: "report", // Applies to how report widgets and other elements are displayed in export builder
  // Draw option location
  LOCATION_DASHBOARD: "dashboard",
  LOCATION_EXPORT: "export",
  // All widget options
  SHOW_BACKGROUND: "show_background",
  SHOW_BORDERS: "show_borders",
  SHOW_TITLE: "show_title",
  SHOW_HEADER: "show_header",
  SHOW_SHADOW: "show_shadow",
  SHOW_DATA_SOURCE: "show_data_source",
  SHOW_METRIC_NAME: "show_metric_name",
  SHOW_PAGE_HEADER: "show_page_header",
  SHOW_WIDGETS_WITH_NO_DATA: "show_widgets_with_no_data",
  SHOW_PAGE_FOOTER: "show_page_footer",
  IS_DARK_MODE: "is_dark_mode",
  SHOW_COMPARISON_ARROWS: "show_comparison_arrows",
  DEFAULT_COMPARISON_ARROW_COLOR: "default_comparison_arrow_color",
  // Widget type options
  FONT_SIZE: "font_size",
  REDUCE_NUMBER: "reduce_number",
  CENTER_NUMBERS: "center_numbers",
  CIRCLE_NUMBER: "circle_number",
  BACKGROUND_COLOR_TYPE: "background_color_type",
  BACKGROUND_COLOR: "background_color",
  BORDER_COLOR: "border_color",
  BORDER_WIDTH: "border_width",
  MAP_TYPE: "map_type",
  CORNER_RADIUS: "corner_radius",
  BACKGROUND_GRADIENT: "background_gradient",
  GRADIENT_COLOR: "gradient_color",
  PIE_COLOR_PICKER: "pie_color_picker",
  OPTION_FONT_COLOR_PICKER: "font_color_picker",
  WRAP_NUMBER: "wrap_number",
  FONT_PROPERTIES: "font_properties",
  CIRCLE_FONT_SIZE: "circle_font_size",
  SPACING: "spacing",
  SPACING_TOGGLE: "spacing_toggle",
  WRAPPED_FONT_SIZE: "wrapped_font_size",
  CIRCLE_SIZE: "circle_size",
  WRAP_METRIC_NAME: "wrap_metric_name",
  LAYERS: "layers",
  PLOT_TYPE: "plot_type",
  HAS_LEGEND: "has_legend",
  IS_ROTATED: "is_rotated",
  HAS_VALUE_SCROLLER: "has_value_scroller",
  IS_HAND_DRAWN: "is_hand_drawn",
  IS_Y_AXIS_MOVED: "is_y_axis_moved",
  IS_NORMALIZED: "is_normalized",
  IS_ZERO_SCALED: "is_zero_scaled",
  IS_LOG_SCALED: "is_log_scaled",
  SHOW_LABELS: "show_labels",
  SHOW_LABEL_NAMES: "show_label_names",
  SHOW_LABEL_VALUES: "show_label_values",
  SHOW_LABEL_PERCENT: "show_label_percent",
  SHOW_METRIC_LABELS: "show_metric_labels",
  SHOW_VALUES_ON_SHAPES: "show_values_on_shapes",
  SHOW_SHAPE_SHADOW: "show_shape_shadow",
  SHAPE_OPACITY: "shape_opacity",
  GEO_LAYER_OPACITY: "geo_layer_opacity",
  OPTION_GEO_LIMIT: "geo_limit",
  MAP_GEOGRAPHIC_FEATURES: "map_geographic_features",
  HIDE_ZERO_VALUE: "hide_zero_value",
  DATE_FORMAT_TYPE: "date_format_type",
  NECK_HEIGHT: "neck_height",
  NECK_WIDTH: "neck_width",
  ROUNDED_CORNERS: "rounded_corners",
  CURVED_COLUMNS: "curved_columns",
  RADIAL_INNER_RADIUS: "radial_inner_radius",
  HAS_REGRESSION_LINE: "has_regression_line",
  HAS_METRIC_LINES_VISIBLE: "has_metric_lines_visible",
  BENCHMARK_LINE_STYLE: "benchmark_line_style",
  BENCHMARK_LINE_TYPE: "benchmark_line_type",
  BENCHMARK_SHOW_BULLETS: "benchmark_bullets",
  SHOW_COLUMN_SHADOWS: "show_column_shadows",
  DEPTH: "depth",
  ANGLE: "angle",
  HIDE_GRID_LINES: "hide_grid_lines",
  OTHER_PERCENT: "other_percent",
  LABEL_PERCENT: "label_percent",
  START_ANGLE: "start_angle",
  INNER_RADIUS: "inner_radius",
  HAS_BULLETS: "has_bullets",
  BULLETS_SHAPE: "bullets_shape",
  LINE_TYPE: "line_type",
  SPARKLINE_TYPE: "sparkline_type",
  HAS_TOOLTIP: "has_tooltip",
  IS_SMOOTHED_LINE: "is_smoothed_line",
  STEP_LINE_RISERS: "step_line_risers",
  SHOW_SAMPLE_DATA: "show_sample_data",
  FORCE_SAMPLE_DATA: "force_sample_data",
  SHOW_EMPTY_DATES: "show_empty_dates",
  IS_SOLID_GAUGE: "is_solid_gauge",
  BUBBLE_MAX_BUBBLES: "bubble_max_bubbles",
  BUBBLE_SHAPE: "bubble_shape",
  SHAPE_SIZE: "shape_size",
  GRAIN_DENSITY: "grain_density",
  INVERT_METRICS: "invert_metrics",
  ORDER_METRICS: "order_metrics",
  PIE_GRAIN_DENSITY: "pie_grain_density",
  PIE_BORDER_STROKE: "pie_border_stroke",
  SHOW_DONUT_SUM: "pie_donut_sum",
  GAUGE_HAND_TYPE: "gauge_hand_type",
  GAUGE_THICKNESS: "gauge_thickness",
  GAUGE_MULTI_THICKNESS: "gauge_multi_thickness",
  SHOW_TICKS: "show_ticks",
  HAND_COLOR: "hand_color",
  GAUGE_RANGE: "gauge_range",
  GAUGE_GRADIENT_TO: "gauge_gradient_to",
  GAUGE_HAND_CUSTOM_COLOR: "gauge_hand_custom_color",
  GAUGE_GRADIENT_TARGET: "gauge_gradient_target",
  GAUGE_BIG_NUMBER_POSITION: "gauge_big_number_position",
  GAUGE_SHOW_VALUE: "gauge_show_value",
  // Datagrid
  DISPLAY_LENGTH: "display_length",
  REPORT_DISPLAY_LENGTH: "report_display_length",
  SPLIT_IN_MULTIPLE_PAGES: "split_in_multiple_pages",
  ROWS_PER_PAGE: "rows_per_page",
  NUMBER_OF_PAGE: "number_of_page",
  REPEAT_FULL_LAYOUT: "repeat_full_layout",
  GRID_PAGINATE: "grid_paginate",
  GRID_IS_RESPONSIVE: "grid_is_responsive",
  GRID_ALTERNATING_ROW_COLOR: "grid_alternating_row_color",
  GRID_COLLAPSE_IN_MODAL: "grid_collapse_in_modal",
  SHOW_TOTAL_ROW: "show_total_row",
  GRID_TOTAL_ROW_BOTTOM: "grid_total_row_bottom",
  GRID_MAX_HEIGHT_IMAGE: "grid_max_height_image",
  GRID_FULL_IMAGE_SIZE: "grid_full_image_size",
  GRID_PREVIEW_AS_IFRAME: "grid_preview_as_iframe",
  GRID_IFRAME_CAPTURE_DELAY: "grid_iframe_capture_delay",
  PIVOT_GRID: "pivot_grid",
  PERCENT_OF_TOTAL: "percent_of_total",
  ENABLE_GRID_THEME: "grid_theme",
  GRID_FONT_SIZE: "grid_font_size",
  GRID_FONT_PROPERTIES: "grid_font_properties",
  WRAP_TEXT_NAME: "wrap_text_name",
  STYLIZED_EXCEL: "stylized_excel",
  PIE_SEMICIRCLE_ANGLE: "pie_semicircle_angle",
  SHOW_GRAINY_PATTERN: "show_grainy_pattern",
  FILL_TYPE: "fill_type",
  PIE_ROTATION_ANGLE: "pie_rotation_angle",
  EMBED_SPARKLINE_TYPE: "embed_sparklines_type",
  EMBED_SPARKLINE_DATE_RANGE: "embed_sparkline_date_range",

  // Toggle Types
  TOGGLE_TYPE_SHOW: "show",
  TOGGLE_TYPE_HIDE: "hide",
  TOGGLE_TYPE_ENABLE: "enable",
  TOGGLE_TYPE_DISABLE: "disable",

  // Problematic Metric Cards Types
  POSITION_RELATIVE: "relative",
  POSITION_ABSOLUTE: "absolute",
  CARD_TEXT_DISPLAY_SHOW: "Show",
  CARD_TEXT_DISPLAY_HIDE: "Hide",
  PIE_GRADIENT_OPTIONS: "pie_gradient_options",
  V2_INNER_RADIUS: "v2_inner_radius",
  PIE_VARIABLE_RADIUS: "pie_variable_radius",
  CLASSIC_DRILL_DOWN_BEHAVIOUR: "classic_drill_down_behaviour",
  PICTORIAL_OPTIONS: "v2_pictorial_options",
  OPTION_ALIGN_LABELS: "align_labels",

  //tapmap
  CLUSTER: 'cluster',
  BOUNDARY: 'boundary',
  LATLONG: 'latlong'
};

angular
  .module("drawoptionpanel.constants", [])
  .constant("DrawOption", DrawOption);
