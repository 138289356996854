'use strict';
import angular from 'angular';

angular.module('navmain.resources', [])
    .factory('NavMainResource', NavMainResource);

/**
 * @ngInject
 */
function NavMainResource(
    Restangular
) {
    var resource = Restangular.all('nav');

    return {
        getMenuItems: getMenuItems
    };

    function getMenuItems() {
        return resource.all('menu').getList().then(function(items) {
            return items.plain();
        });
    }
}