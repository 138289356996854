'use strict';
import angular from 'angular';

angular.module('core.alert.constants', [])

    .constant('AlertColumns', {
        ID: 'id',
        NAME: 'name',
        CLIENT_ID: 'client_id',
        CLIENT_NAME: 'client_name',
        CLIENT_GROUP_ID: 'client_group_id',
        CLIENT_GROUP_NAME: 'client_group_name',
        CLUSTER_ID: 'cluster_id',
        CLUSTER_NAME: 'cluster_name',
        OPERATOR: 'operator',
        RECIPIENT_IDS: 'recipient_ids',
        IS_ACTIVE: 'is_active',
        IS_DELETED: 'is_deleted',
        WIDGET_ID: 'widget_id',
        SERVICE_ID: 'service_id',
        DATA_VIEW: 'data_view',
        EVALUATION_TIMEFRAME: 'evaluation_timeframe',
        ROW_ALERT_CRITERIA: 'row_alert_criteria',
        AGGREGATION_TYPE: 'aggregation_type',
        ALERT_THRESHOLD: 'alert_threshold',
        PREV_ALERT_ID: 'prev_alert_id',
        CREATED_BY: 'created_by',
        CREATED_DATE: 'created_date',
        IS_WIDGET_ALERT: 'is_widget_alert',
        IS_GOAL_ALERT: 'is_goal_alert',
        PAGE_ID: 'page_id',
        LAYOUT_ID: 'layout_id',
        PAGE_TITLE: 'page_title',
        LAYOUT_TITLE: 'layout_title',
        WIDGET_TITLE: 'widget_title',
        WIDGET_METADATA: 'widget_metadata',
    })

    .constant('AlertTimeFrame', {
        TODAY: 'today',
        YESTERDAY: 'yesterday',
        THIS_WEEK: 'this_week',
        LAST_WEEK: 'last_week',
        LAST_7_DAYS: 'last_7',
        LAST_14_DAYS: 'last_14',
        LAST_30_DAYS: 'last_30',
        LAST_90_DAYS: 'last_90',
        THIS_MONTH: 'this_month',
        LAST_MONTH: 'last_month',
        LAST_3_MONTHS: 'last_3',
        LAST_6_MONTHS: 'last_6',
        LAST_12_MONTHS: 'last_12',
        LAST_13_MONTHS: 'last_13',
        THIS_QUARTER: 'this_quarter',
        LAST_QUARTER: 'last_quarter'
    })

    .constant('AlertFrequency', {
        EVERY_DAY: 'every_day',
        EVERY_WEEK_ON_DAY: 'every_week_on_day',
        EVERY_MONTH_ON_DAY: 'every_month_on_day',
        EVERY_QUARTER_ON_DAY: 'every_quarter_on_day'
    })

    .constant('$AlertEvents', {
        INIT_ALERT: '$AlertEvents:INIT_ALERT',
        REMOVE_ALERT: '$AlertEvents:REMOVE_ALERT',
        INIT_WIDGET_ALERT: '$AlertEvents:INIT_WIDGET_ALERT',
        REMOVE_WIDGET_ALERT: '$AlertEvents:REMOVE_WIDGET_ALERT',
        UPDATE_GROUPBY: '$AlertEvents:UPDATE_GROUPBYT',
        UPDATE_CONDITION: '$AlertEvents:UPDATE_CONDITION',
        UPDATE_DATASOURCE_ID: '$AlertEvents:UPDATE_DATASOURCE_ID',
        UPDATE_DATASOURCE_DATA_VIEW: '$AlertEvents:UPDATE_DATASOURCE_DATA_VIEW'
    });
