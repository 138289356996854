import { apiAxios, apiLegacyAxios } from '@/modules/core/app/helpers/AxiosHelper';

class SessionResource {
  /**
   * @param email
   * @param password
   * @param recaptcha
   * @param hipaa_acknowledgement
   * @param two_step_verification_code
   * @param resend_verification_code
   * @param remember_this_device
   * @returns {Promise<AxiosPromise<any>>}
   */
  async login(
    email,
    password,
    recaptcha,
    hipaa_acknowledgement,
    two_step_verification_code,
    resend_verification_code,
    remember_this_device
  ) {
    return apiLegacyAxios.post('/session/login', {
      email,
      password,
      recaptcha,
      hipaa_acknowledgement: hipaa_acknowledgement ? 'yes' : 'no',
      two_step_verification_code,
      resend_verification_code,
      remember_this_device,
    });
  }

  /**
   *
   * @param params
   * @returns {Promise<Promise<AxiosResponse<T>>>}
   */
  async googleLogin(params) {
    return apiAxios.get('session/googleLogin', { params });
  }

  /**
   * @returns {Promise<AxiosPromise<any>>}
   */
  async getUserSettings(params) {
    return apiAxios.get('/session/initUser', { params });
  }

  /**
   * @returns {Promise<AxiosPromise<any>>}
   */
  async logout() {
    return apiAxios.get('/session/logout');
  }

  /**
   * @param {number} id
   * @returns {Promise<AxiosPromise<any>>}
   */
  async impersonateUser(id) {
    return apiAxios.get(`/session/impersonate/${id}`);
  }

  /**
   * @param {number} id
   * @returns {Promise<AxiosPromise<any>>}
   */
  async unImpersonateUser() {
    return apiAxios.get('/session/unimpersonate');
  }

  /**
   *
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  async sendResetPasswordLink(data) {
    return apiAxios.post('/session/resetPasswordLink', data);
  }

  /**
   *
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  async processResetPassword(params) {
    return apiAxios.post('/session/processResetPassword', params);
  }
}

export default new SessionResource();
