<template>
  <GaugeChart v-if="canShowChart" :config="chartConfig" @frame-ended="setIdle" />
</template>

<script>
import GaugeChart from '@/modules/core/charts/am5/gauge/components/GaugeChart.vue';
import { LegacyGaugeChartWidgetRenderService } from '@/modules/core/charts/am5/gauge/services/LegacyGaugeChartWidgetRenderService';
import { dispatches } from '@/modules/core/app/helpers/store';
import { ChartPlace } from '@/modules/core/charts/am5/charts.constants';
import { BaseChartWidgetPropsMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetPropsMixin';
import { BaseChartWidgetMethodMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetMethodMixin';

export default {
  name: 'LegacyGaugeChartWidget',
  components: { GaugeChart },
  mixins: [BaseChartWidgetPropsMixin, BaseChartWidgetMethodMixin],
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      dispatches.dashboardExport.setUnrenderedWidgetIds([this.widget.id]);
      const widget = await this.setDrillDownOptions();

      const renderer = new LegacyGaugeChartWidgetRenderService(
        widget,
        this.chartPalette,
        false,
        this.isExporting
      );
      let chartTransparency = 1;
      if (widget.report_id && this.elementMetadata) {
        chartTransparency = this.elementMetadata?.base_options?.background_color ? 1 : 0;
      }

      const config = renderer.getChartConfig({ isComparison: false });
      config.setAll({
        chartPlace: widget.report_id ? ChartPlace.REPORT : ChartPlace.DASHBOARD,
        clientCurrencySymbol: this.clientCurrencySymbol,
        widget,
        chartTransparency,
      });
      this.chartConfig = config;

      if (this.hasComparisonData) {
        const priorRenderer = new LegacyGaugeChartWidgetRenderService(
          widget,
          this.chartPalette,
          this.hasComparisonData,
          this.isExporting
        );

        const comparisonConfig = priorRenderer.getChartConfig({
          isComparison: this.hasComparisonData,
        });
        comparisonConfig.setAll({
          chartPlace: widget.report_id ? ChartPlace.REPORT : ChartPlace.DASHBOARD,
          clientCurrencySymbol: this.clientCurrencySymbol,
          widget,
          chartTransparency,
        });
        this.chartConfig.comparisonAxisRanges = comparisonConfig.axisRanges;
        this.chartConfig.comparisonLabels = comparisonConfig.labels;
        this.chartConfig.comparisonEnabled = true;
      }
    },
    async setIdle() {
      dispatches.dashboardExport.setWidgetAsRendered(this.widget.id);
    },
  },
};
</script>
