"use strict";
import $ from 'jquery';

var Core = $.core.dashboard = {
    //Scoped global variables
    chartObj: null,
    dualBarCarousel: null,
    currentMockData: null,
    currentDateText: null, //The date set on the page from the filter

    initialize: function (type) {

        //Reset & init variables
        Core.type = type;
        Core.chartObj = null;
        Core.dualBarCarousel = null;
        Core.currentMockData = null;

        this.build();
        this.events();
    },
    build: function () {
        //Render dashboard top template
        this.dashboardTop();

        $('#period-legend').affix({offset: { top : $('#main-content-top').outerHeight() + 2}, target: $('#right-frame')});
    },
    events: function () {

    },
    owlCarousel: function ($elm, update) {
        if (update && $elm.data('owlCarousel')) {
            $elm.data('owlCarousel').reinit();
        }
        else {
            var owl = $elm.owlCarousel({
                navigation: true,
                navigationText: [
                    "<i class='icon-chevron-left icon-white'></i>",
                    "<i class='icon-chevron-right icon-white'></i>"
                ],
                items: 5, //5 items above 1800px browser width
                itemsDesktop: [1800, 4], //5 items between 1800px and 1501px
                itemsDesktopSmall: [1500, 3], // betweem 1500px and 1201px
                itemsTablet: [1200, 2], //2 items between 1200 and 0
                itemsMobile: false // itemsMobile disabled - inherit from itemsTablet option
            });
        }

        if ($elm.hasClass('top-pagination')) {
            //Set pagination at top
            $elm.prepend($elm.find('div.owl-controls'));
        }

        return owl;
    },

    //
    // Event binding
    //
    dashboardTopBindings: function () {
        //Binding get dashboard middle - dashboard top
        $('#dashboard-top').find('div.main-widget.has-breakdown').click(function () {
            var $button = $(this).find('div.selection-button');
            if (!$button.hasClass('active')) {
                var $dashboardTop = $('#dashboard-top');

                //Highlight title selection
                $dashboardTop.find('p.main-widget-title').removeClass('active');
                $(this).find('p.main-widget-title').addClass('active');

                //Highlight selection button
                $dashboardTop.find('div.selection-button').removeClass('active');
                $button.addClass('active');

                //Make main widget active for css
                $dashboardTop.find('div.main-widget').removeClass('active');
                $(this).addClass('active');

                //Hide help text
                $dashboardTop.find('div.main-widget p.temp-text').addClass('invisible');

                //Temp
                Core.currentMockData = $(this).find('div.selection-button').data();

                Core.dashboardMiddle($button);
            }
        });
    },
    dashboardMiddleBindings: function() {

        //Binding refresh chart with selected data
        $('#dashboard-middle').find('div.dual-bar-container').click(function () {
            var $dashboardMiddle = $('#dashboard-middle');
            var $button = $(this).find('div.selection-button');

            if (!$button.hasClass('active')) {

                //Highlight title selection
                $dashboardMiddle.find('p.dual-bar-title').removeClass('active');
                $(this).find('p.dual-bar-title').addClass('active');

                //Highlight selection button
                $dashboardMiddle.find('div.selection-button').removeClass('active');
                $button.addClass('active');

                //Make bar container active for css
                $dashboardMiddle.find('div.dual-bar-container').removeClass('active');
                $(this).addClass('active');

                Core.dashboardBottom($button);
            }
        });


        $('#dashboard-middle').find('div.selection-button.all').click(function () {
            var $dashboardMiddle = $('#dashboard-middle');
            var $button = $(this);

            if (!$button.hasClass('active')) {

                $dashboardMiddle.find('div.selection-button').removeClass('active');
                $dashboardMiddle.find('p.dual-bar-title').removeClass('active');
                $dashboardMiddle.find('div.dual-bar-container').removeClass('active');
                $button.addClass('active');

                Core.dashboardBottom($button);
            }
        });
    },

    //$TODO: Move chart code to chart.js
    //
    // Charting
    //
    gauge: function (data) {
        $.each(data, function (i, val) {
            var $widget = $('#dashboard-top').find('div.main-widget:eq(' + i + ')');

            //Temp
            $widget.find('div.selection-button').data(val); //So that data can be-reused on widget click
            $widget.find('div.selection-button').data('id', i); //So that we assign an id for later use

            var $container = $widget.find('div.gauge-container');

            if (val.isPositive) {
                $container.find('p.gauge-plus').addClass('plus-color');
            }
            else {
                $container.find('p.gauge-minus').addClass('minus-color');
            }

            if (val.isTotal) {
                $widget.find('p.main-widget-title').css({ fontWeight: 'bold' });
                $container.find('label').css({ fontSize: '32px', marginTop: '-34px' });
            }

            $container.dxBarGauge({
                baseValue: 0,
                startValue: -100,
                endValue: 100,
                values: [val.isPositive ? -val.percentageAmount : val.percentageAmount],
                palette: [val.isPositive ? '#1abc9b' : '#f26c63'],
                label: { visible: false },
                barSpacing: 2,
                relativeInnerRadius: 0.75,
                size: {
                    width: 100,
                    height: 190
                },
                geometry: {
                    startAngle: 90,
                    endAngle: 270
                }
            });

        });
    },
    dualBar: function ($elm, data) {
        $.core.chart.legacy.buildDualBarChart($elm, data, {titleVisible:false});
    },
    mockChartData: function () {
        return [
            { date: 'Apr 1, 2014', val: parseInt(Core.currentMockData.previousValue * Math.random() * 2) },
            { date: 'Apr 4, 2014', val: parseInt(Core.currentMockData.previousValue * Math.random() * 2) },
            { date: 'Apr 7, 2014', val: parseInt(Core.currentMockData.previousValue * Math.random() * 2) },
            { date: 'Apr 10, 2014', val: parseInt(Core.currentMockData.currentValue * Math.random() * 2) },
            { date: 'Apr 13, 2014', val: parseInt(Core.currentMockData.currentValue * Math.random() * 2) },
            { date: 'Apr 16, 2014', val: parseInt(Core.currentMockData.previousValue * Math.random() * 2) },
            { date: 'Apr 19, 2014', val: parseInt(Core.currentMockData.previousValue * Math.random() * 2) },
            { date: 'Apr 22, 2014', val: Core.currentMockData.previousValue },
            { date: 'Apr 25, 2014', val: Core.currentMockData.currentValue },
            { date: 'Apr 28, 2014', val: parseInt(Core.currentMockData.currentValue * Math.random() * 2) }
        ];
    },
    mainWidgetChart: function ($chart, data) {

        //Go through data to format dates in a more user-friendly format
        $.each(data.dataArray, function(i, val) {
            var temp = val.date.split(' - ');
            var start = Globalize.format(Date.parse(temp[0]), 'MMM d, yyyy');
            var end = Globalize.format(Date.parse(temp[1]), 'MMM d, yyyy');
            val.date = start + ' - ' + end;
        });

        var paletteColor = $.core.main.getPeriodColor('current');

        if (Core.chartObj == null) {
            Core.chartObj = $chart.dxChart({
                dataSource: data.dataArray,
                size: {
                    height: 300
                },
                margin: {
                    top: 5
                },
                palette: [paletteColor],
                commonSeriesSettings: {
                    hoverMode: 'none',
                    label: {
                        visible: true,
                        connector: {
                            visible: true
                        }
                    },
                    type: 'line',
                    argumentField: 'date',
                    point: {
                        hoverMode: 'none',
                        color: 'white',
                        border: {
                            visible: true,
                            color: paletteColor,
                            width: 2
                        }
                    }
                },
                commonAxisSettings: {
                    grid: {
                        color: '#f2f2f2',
                        visible: true
                    }
                },
                series: [
                    { valueField: "val", name: data.title }
                ],
                legend: {
                    visible: false
                },
                customizeLabel: function () {
                    var obj = {};
                    obj.backgroundColor = paletteColor;
                    obj.connector = { color: paletteColor };
                    obj.customizeText = function (n) {
                        return Globalize.format(n.value, 'n0');
                    };
                    return obj;
                }
            }).dxChart('instance');
        }
        else {
            //Update data source
            Core.chartObj.option('dataSource', data.dataArray);
        }
    },
    //
    // Templating
    //
    dashboardTop: function () {
        //@todo: make sure this works in the client area as well
        $.post($.fn.actionUrl('dashboard/top_section'), { type: Core.type }, function (json) {
            var data = JSON.parse(json);
            var $dashboardTop = $('#dashboard-top');

            $dashboardTop.find('div.owl-carousel').html($('#main-widget-gauge-template').render(data));

            Core.owlCarousel($dashboardTop.find('div.owl-carousel'), false);
            Core.gauge(data);

            //Set bindings
            Core.dashboardTopBindings();

            //Display only after carousel is loaded or else it appears out of place at first
            $dashboardTop.find('hr.selection-line').show();
        });
    },
    dashboardMiddle: function () {
        var $dashboardMiddle = $('#dashboard-middle');

        $.post($.fn.actionUrl('dashboard/middle_section'), {
            type: Core.type,
            id: $('#dashboard-top').find('div.selection-button.active').data('id'),
            category: $('#dashboard-top').find('div.selection-button.active').data('category')
        }, function (json) {
            $dashboardMiddle.show();

            var data = JSON.parse(json);

            //Dashboard middle ///////

            var $breakdownContainer  = $dashboardMiddle.find('div.breakdown-container');
            $breakdownContainer.html($('#breakdown-container-template').render(data));

            //Set header
            $dashboardMiddle.find('p.loaded-header').empty();
            $dashboardMiddle.find('p.loaded-header').html('<label>' + Core.currentMockData.title + '</label>' + ' breakdown');

            //Owl carousel
            if (Core.dualBarCarousel == null) {
                Core.dualBarCarousel = Core.owlCarousel($breakdownContainer, false);
            }
            else {
                Core.owlCarousel(Core.dualBarCarousel, true);
            }

            $.each(data, function (i, val) {
                var $currentContainer = $breakdownContainer.find('div.dual-bar-container:eq(' + i + ')');
                Core.dualBar($currentContainer, val);

                //Set the selection button data
                $currentContainer.find('div.selection-button').data('id', i + 1); //So that we assign an id for later use
                $currentContainer.find('div.selection-button').data('category', val.category);
                $currentContainer.find('div.selection-button').data('metricKey', val.metricKey);
            });

            //Move titles at bottom of dual bar charts
            $.each($breakdownContainer.find('div.dual-bar-container'), function(i, val) {
                $(val).append($(val).find('div.dual-bar-info'));
            });

            //Add the select all button
            $breakdownContainer.append($('#selection-button-all-template').render());

            //Set the selection button data for the all button
            $breakdownContainer.find('div.selection-button.all').data('id', 0);
            $breakdownContainer.find('div.selection-button.all').data('category', $('#dashboard-top').find('div.selection-button.active').data('category'));
            $breakdownContainer.find('div.selection-button.all').data('metricKey', 'all');

            //Dashboard bottom ///////
            var $dashboardBottom = $('#dashboard-bottom');

            //Initialize chart
            var data = {};
            data.title = 'Click one of the graph buttons above to chart data';
            data.dataArray = [];
            Core.mainWidgetChart($dashboardBottom.find('div.chart-container div.chart'), data);

            //Set header
            $dashboardBottom.find('p.loaded-header').empty();
            $dashboardBottom.find('p.loaded-header').html('<label>' + data.title + '</label>');

            //Set bindings
            Core.dashboardMiddleBindings();
        });

    },
    dashboardBottom: function () {
        var $dashboardBottom = $('#dashboard-bottom');

        $.post($.fn.actionUrl('dashboard/bottom_section'), {
            type: Core.type,
            id: $('#dashboard-middle').find('div.selection-button.active').data('id'),
            category: $('#dashboard-middle').find('div.selection-button.active').data('category'),
            metricKey: $('#dashboard-middle').find('div.selection-button.active').data('metricKey')
        }, function (json) {
            $dashboardBottom.show();

            var data = JSON.parse(json);

            //Set header
            $dashboardBottom.find('p.loaded-header').empty();
            $dashboardBottom.find('p.loaded-header').html('<label>' + data.title + '</label>');

            //Initialize chart
            Core.mainWidgetChart($dashboardBottom.find('div.chart-container div.chart'), data);
        });

    },
    showSelectionButtonLoad: function($elm) {
        $elm.find('span.icon').removeClass('icomoon-bar-chart').addClass('icomoon-refresh');
    },
    hideSelectionButtonLoad: function($elm) {
        $elm.find('span.icon').removeClass('icomoon-refresh').addClass('icomoon-bar-chart');
    }
};