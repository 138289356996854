'use strict';
import angular from 'angular';
import $ from 'jquery';
import DevTools from '../../devtools';
import swal from 'bootstrap-sweetalert';
import Clipboard from 'clipboard';
import devtoolsWidgetModalsHtmlUrl from './devtools.widget.modals.html';
import devtoolsWidgetOptionsHtmlUrl from './devtools.widget.options.html';
import devtoolsWidgetSqlModalHtmlUrl from './devtools.widget.sql.modal.html';
import devtoolsWidgetTimeprofileModalHtmlUrl from './devtools.widget.timeprofile.modal.html';
import devtoolsWidgetBugModalHtmlUrl from './devtools.widget.bug.modal.html';
var Core = $.core;

angular.module('core.devtools.directives', [])

    .directive('devToolsWidgetOptions', devToolsWidgetOptions)
    .directive('devToolsWidgetModals', devToolsWidgetModals)
    .directive('devToolsWidgetSqlModal', devToolsWidgetSqlModal)
    .directive('devToolsWidgetTimeProfileModal', devToolsWidgetTimeProfileModal)
    .directive('devToolsWidgetBugModal', devToolsWidgetBugModal);

function devToolsWidgetModals() {
    return {
        restrict: 'E',
        templateUrl: devtoolsWidgetModalsHtmlUrl,
        controller: function($scope) {
            $scope.hasDevTools = DevTools.isEnabled();
        }
    }
}

/**
 * Gives useful dev info about widget
 * @returns {{restrict: string, templateUrl, scope: {showDevToolsWidgetOptions: string, widget: string}, link: link}}
 */
function devToolsWidgetOptions() {
    return {
        restrict: 'E',
        templateUrl: devtoolsWidgetOptionsHtmlUrl,
        controller: 'DevToolsWidgetOptionsController',
        scope: {
            showDevToolsWidgetOptions: '=',
            widget: '<'
        }
    }
}

function devToolsWidgetSqlModal() {
    return {
        restrict: 'E',
        templateUrl: devtoolsWidgetSqlModalHtmlUrl,
        controller: 'DevToolsWidgetSqlModalController',
        link: function() {
            var clipboard = new Clipboard('.copy-btn');
            clipboard.on('success', function() {
                swal('Copied!', 'Have fun debugging!', 'success');
            });
        }
    }
}

function devToolsWidgetTimeProfileModal() {
    return {
        restrict: 'E',
        templateUrl: devtoolsWidgetTimeprofileModalHtmlUrl,
        controller: 'DevToolsWidgetTimeProfileModalController'
    }
}

function devToolsWidgetBugModal() {
    return {
        restrict: 'E',
        templateUrl: devtoolsWidgetBugModalHtmlUrl,
        controller: 'DevToolsWidgetBugModalController',
        link: function() {
            var clipboard = new Clipboard('.copy-btn');
            clipboard.on('success', function() {
                swal('Copied!', 'Have fun debugging!', 'success');
            });
        }
    }
}