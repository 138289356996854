﻿$.views.helpers({
    format: function (val, format, param) {
        switch (format) {
            case "plusOrMinus":
                return val ? '+' : ''; //Minus is already shown by default
            case "percentage":
                return val + '%';
            case "absolute":
                return Math.abs(val);
            case "lowerCase":
                return val.toLowerCase();
            case "escapeHtml":
                return val.escapeHtml();
            case "reduceNumber":
                return parseFloat(val).reduceNumber(2, param);
            case "numeric":
                return Globalize.format(parseFloat(val), 'n0');
            case "currency":
                return Globalize.format(parseFloat(val), 'c' + param);
            case "decimal":
                return Globalize.format(parseFloat(val), 'n' + param);
            case "decimalTimes":
                return Globalize.format(parseFloat(val), 'n' + param) + 'X';
            default:
                return Globalize.format(parseFloat(val), 'n0');
        }
    }
});