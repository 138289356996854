'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('goals.services', [])

    .factory('GoalsService', GoalsService)
    .factory('GoalsDataService', GoalsDataService)
    .factory('GoalsUIService', GoalsUIService);

/**
 * @ngInject
 */
function GoalsService(
    $timeout,
    PubSub,
    GoalsModelFactory,
    $GoalsEvents,
    GoalsResource,
    DataSourceType,
    WidgetType,
    SmartCampaignService,
    DetailsModalFactory,
    LayoutFactory,
    WidgetCreateFactory,
    AppFactory
) {

    return {
        setServices: setServices,
        setServicesFromResource: setServicesFromResource,
        getServices: getServices,
        addGoal: addGoal,
        remove: remove,
        assignModelValues: assignModelValues,
        getTitle: getTitle
    };

    let services;

    /**
     *
     * @param model
     */
    function setServices(model) {
        services = model;
    }

    function setServicesFromResource() {
        GoalsResource.getServices().then(function(json) {
            setServices(json.plain());
        });
    }

    /**
     *
     * @returns {*}
     */
    function getServices() {
        return services;
    }

    function addGoal(goal) {
        let widgetModel = WidgetCreateFactory.setBaseWidgetModel({}, WidgetType.GOAL);
        let model = DetailsModalFactory.getModel();
        let isNew = DetailsModalFactory.getIsNewState();
        widgetModel.id = goal.id;

        widgetModel.metadata.data_source.type = DataSourceType.GOALS;
        widgetModel.metadata.draw_options = {};
        widgetModel.metadata.dynamic.predefined_data = {
            data: [model]
        };

        widgetModel.metadata.data_columns = {
            selected: DetailsModalFactory.getMetadata(),
            grouped: []
        };

        widgetModel.can_be_edited = true;

        GoalsResource.get(goal.id).then(function(data) {
            widgetModel.metadata.dynamic.predefined_data = {
                data: [data]
            };
            widgetModel.title = getTitle(data);

            if (isNew) {
                widgetModel.metadata.is_new = true;
                widgetModel.metadata.data_source = model.data_source;
                widgetModel.metadata.data_source.type = DataSourceType.GOALS;

                WidgetCreateFactory.setCurrentWidget(widgetModel);
                WidgetCreateFactory.$updateWidgetDimensions();

                $timeout(function() {
                    LayoutFactory.addWidget(angular.copy(widgetModel));
                    WidgetCreateFactory.resetCurrentWidget();
                });

            } else {
                $timeout(function() {
                    LayoutFactory.updateWidget(angular.copy(widgetModel));
                })
            }
        });
    }

    /**
     *
     * @param goalId
     */
    function remove(goalId) {
        return GoalsResource.remove(goalId);
    }

    /**
     *
     * @param model
     * @param goal
     * @throws
     */
    function assignModelValues(model, goal) {

        _.assign(
            model,
            _.omit(goal, ['name', 'target', 'start', 'end', 'column', 'timeframe', 'threshold_1', 'threshold_2'])
        );

        return model;
    }

    /**
     * Return a formatted widget title for a given goal
     * @param goal
     * @returns {*}
     */
    function getTitle(goal) {
        let dataSource;
        if (goal.smart_campaign_id) {
            dataSource = SmartCampaignService.getSmartCampaign(goal.smart_campaign_id);
        } else if (goal.category_id) {
            dataSource = AppFactory.getCategory(goal.category_id);
        } else {
            dataSource = AppFactory.getService(goal.service_id);
        }

        let title = '<span class="title">' + goal.name + '</span>';
        let subtitle = '<small>' + dataSource.name + '</small>';
        let icon = '<i class="icon ' + (goal.service_id && !dataSource.is_custom_service ?'serviceicon-' + dataSource.icon : dataSource.icon ) + '" style="color:' + dataSource.color + '"></i>';

        return '<span class="data-source">' + icon + '<div class="data-source-display">' + title + subtitle + '</div></span>';
    }
}

/**
 * @ngInject
 */
function GoalsDataService() {
    /**
     * Used for current state params goals id, and goals log id
     * @type {{}}
     */
    let stateParams = {};

    return {
        setStateParams: setStateParams,
        getStateParams: getStateParams
    };

    /**
     *
     * @param {Object} params
     */
    function setStateParams(params) {
        stateParams = params;
    }

    /**
     *
     * @returns {{}}
     */
    function getStateParams() {
        return stateParams;
    }

}

/**
 * @ngInject
 */
function GoalsUIService(
    PubSub,
    $GoalsEvents,
    GoalsModelFactory,
    GoalsResource,
    UIFactory,
    DetailsModalFactory,
    DetailsModalModelFactory,
    DetailsModalSize,
    DataSourceType
) {

    return {
        initModal: initModal
    };


    /**
     * Proxy for initiating service client goals edit
     * @param goal
     */
    function initModal(goal) {
        goal = goal || {};

        let model = GoalsModelFactory.getGoal(goal);
        let isNew = !model.id;

        let promises = goal.id ?
            [GoalsResource.get(goal.id)] :
            [];

        if (!isNew) {
            PubSub.emit($GoalsEvents.IS_ADDING, false);
            DetailsModalFactory.setAsNew(false);
        } else {
            PubSub.emit($GoalsEvents.IS_ADDING, true);
            DetailsModalFactory.setAsNew(true);
        }

        PubSub.emit($GoalsEvents.IS_READY, true);
        PubSub.emit($GoalsEvents.INIT_MODAL, model);
        _setDetails(promises, isNew);
        UIFactory.showModal('add-goals-modal');
    }

    /**
     * Sets up details
     * @param promises
     * @param isNew
     * @private
     */
    function _setDetails(promises, isNew) {
        return DetailsModalFactory.setDetails(DetailsModalModelFactory.getModalOptions({
            dataSourceType: DataSourceType.GOALS,
            promises: promises,
            resource: GoalsResource.getResource(),
            modalSize: DetailsModalSize.LARGE,
            display: {
                title: (isNew ? 'Add' : 'Update') + ' Goal',
                loadingMessage: (isNew ? 'New' : 'Loading') + ' Goal',
                icon: 'icomoon-location-target'
            },
            hideRowButtons: true
        }));
    }
}