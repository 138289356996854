'use strict';
import angular from 'angular';

angular.module('reportbuilder.services', [])

    .service('ReportBuilderService', ReportBuilderService);

//
// Report builder service
//
ReportBuilderService.$inject = ['$rootScope', '$http'];
function ReportBuilderService($rs, $http) {
    return {
        myFunction: function() {

        }
    }
}