'use strict';
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

import goalsDetailsModalHtmlUrl from './goals.details.modal.html';
import goalsDetailsHtmlUrl from './goals.details.html';
import { GoalMetricType } from "../goals.constants";

angular.module('goals.details.components', [])
    .component('goalsDetailsModal', {
        templateUrl: goalsDetailsModalHtmlUrl,
        controllerAs: 'vm',
        transclude: true,
        controller: GoalsDetailsModalController
    })
    .component('goalsDetails', {
        templateUrl: goalsDetailsHtmlUrl,
        controllerAs: 'vm',
        controller: GoalsDetailsController
    });

/**
 * @ngInject
 */
function GoalsDetailsModalController(
    $q,
    $GoalsEvents,
    $timeout,
    $DetailsModalFactoryEvents,
    ColumnValueType,
    DataViewName,
    PubSub,
    AppFactory,
    AppModule,
    LayoutFactory,
    UIFactory,
    AppModelFactory,
    GoalsModelFactory,
    GoalsService,
    SmartCampaignService,
    DetailsModalFactory,
    DataSourceType,
    DataSourceResourceDelegate,
    SmartCampaignResource,
    ClientFactory,
    CategoryFactory,
    GoalsResource,
    AppFeature
) {
    let vm = this;

    vm.setDataSource = setDataSource;
    vm.isNUI = window.isNUI;
    vm.reset = reset;
    vm.close = closeModal;

    vm.isSmartCampaignsAvailable = AppFactory.isUserModuleAvailable(AppModule.SMART_CAMPAIGNS);
    vm.isCategoryAvailable = AppFactory.isUserFeatureAvailable(AppFeature.CATEGORIES);
    vm.isClientRequired = !AppFactory.getUser().isSuperAdmin();
    vm.showDetails = false;
    vm.showCampaignSelect = showCampaignSelect;
    vm.showServiceSelect = showServiceSelect;
    vm.showSmartCampaignSelect = showSmartCampaignSelect;
    vm.showCategorySelect = showCategorySelect;
    vm.isCampaignSelectDisabled = isCampaignSelectDisabled;
    vm.isServiceSelectDisabled = isServiceSelectDisabled;
    vm.isSmartCampaignSelectDisabled = isSmartCampaignSelectDisabled;
    vm.isCategorySelectDisabled = isCategorySelectDisabled;
    vm.showDataViewSelect = showDataViewSelect;
    vm.showMetricSelect = showMetricSelect;

    /** Life Cycle */
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.dataCategoryFilterOptions = [DataSourceType.SERVICE_DATA, DataSourceType.SMART_CAMPAIGN, DataSourceType.CATEGORY_DATA];

    /**
     *
     * @param dataSource
     */
    function setDataSource(dataSource) {
        PubSub.emit($GoalsEvents.MAPPING_SELECT_READY, false);
    }

    function dataSourceOnChange($el) {
        let dataSource = $el.select2('data');
        vm.showDetails = (dataSource);

        if (dataSource) {
            resetDataSource();
            vm.viewBindings.data_source = dataSource;
            vm.goal.source_type = vm.viewBindings.source_type = dataSource.id;
            if (dataSource.id === DataSourceType.SERVICE_DATA) {
                vm.serviceSelectOptions.getDataCall().then(function (json) {
                    vm.serviceSelectOptions.callFormatData(json);
                });
            } else if (dataSource.id === DataSourceType.CATEGORY_DATA) {
                vm.categorySelectOptions.getDataCall().then(function (json) {
                    vm.categorySelectOptions.callFormatData(json);
                });
            } else if (dataSource.id === DataSourceType.SMART_CAMPAIGN) {
                vm.smartCampaignSelectOptions.getDataCall().then(function (json) {
                    vm.smartCampaignSelectOptions.callFormatData(json);
                });
            }
            PubSub.emit($GoalsEvents.DATA_SOURCE_SELECTED);
        } else {
            resetDataCategory();
        }
    }

    function clientOnChange($el) {
        let customer = $el.select2('data');
        if (customer) {
            vm.viewBindings.customerId = customer.id;
            vm.viewBindings.clientName = customer.text;
            vm.goal.customer_id = customer.id;
        } else {
            vm.viewBindings.customerId = null;
            vm.goal.customer_id = null;
        }
        resetDataCategory();
    }

    function resetDataCategory(){
        angular
            .element(
                "#details-modal #data-category-select div.select2-container"
            )
            .select2("data", null);
        resetDataSource();
        vm.goal.source_type = vm.viewBindings.source_type = null;
        vm.viewBindings.data_source = null;
        vm.showDetails = false;
        PubSub.emit($GoalsEvents.DATA_SOURCE_SELECTED);
    }

    function resetDataSource(){
        if (!isServiceSelectDisabled()) {
            angular.element(
                "#details-modal #service-data-source-select div.select2-container"
            )
                .select2("data", null);
            vm.goal.service_id = null;
            vm.viewBindings.service = {};
        } else if (!isCategorySelectDisabled()) {
            angular.element(
                "#details-modal #category-select div.select2-container"
            )
                .select2("data", null);
            vm.goal.category_id = null;
            vm.viewBindings.category = {};
        } else {
            angular.element(
                "#details-modal #smart-campaign-select div.select2-container"
            )
                .select2("data", null);
            vm.goal.smart_campaign_id = null;
        }
    }

    function campaignOnChange($el) {
        let campaigns = $el.select2('data');
        if (campaigns) {
            let campaign_names = campaigns.map(campaign => {
                return {name: campaign.text}
            });
            vm.viewBindings.campaign_name = campaign_names;
            vm.goal.campaign_name = vm.goal.data_source.campaign_name = campaign_names;
        } else {
            vm.viewBindings.campaign_name = null;
            vm.goal.campaign_name = vm.goal.data_source.campaign_name = null;
        }

        PubSub.emit($GoalsEvents.CAMPAIGN_SELECTED, vm.goal.data_source);
    }

    function serviceOnChange($el) {
        let service = $el.select2('data');
        if (!service) {
            return;
        }

        vm.goal.source_type = DataSourceType.SERVICE_DATA;
        if (vm.goal && (vm.goal.service_id !== service.id)) {
            PubSub.emit($GoalsEvents.DATA_SOURCE_SELECTED);
            _resetSelectedDataView();
            _resetSelectedMetric();
        }
        vm.goal.service_id = service.id;
        vm.goal.smart_campaign_id = null;
        vm.goal.category_id = null;
        vm.goal.data_source = service;
        vm.goal.data_source.type = DataSourceType.SERVICE_DATA;
        vm.goal.data_source.data_view = vm.goal.dataview_id;
        vm.goal.primary_name = service.primary_name || vm.goal.primary_name;
        vm.goal.mapping = [];

        vm.dataViewSelectOptions.getDataCall().then(function(json) {
            vm.dataViewSelectOptions.callFormatData(json);
        });
    }

    function smartCampaignOnChange($el) {
        let smartCampaign = $el.select2('data');
        vm.goal.source_type = smartCampaign.type = DataSourceType.SMART_CAMPAIGN;
        if (vm.goal.smart_campaign_id !== smartCampaign.id) {
            PubSub.emit($GoalsEvents.DATA_SOURCE_SELECTED);
        }

        vm.goal.service_id = null;
        vm.goal.category_id = null;
        vm.goal.smart_campaign_id = smartCampaign.id;
        smartCampaign.campaign_name = smartCampaign.name;
        vm.goal.data_source = smartCampaign;
        vm.goal.mapping = [];

        PubSub.emit($GoalsEvents.CAMPAIGN_SELECTED, vm.goal.data_source);
    }

    /**
     *
     * @param category
     */
    function categoryOnChange($el) {
        let category = $el.select2('data');
        if (!category) {
            return;
        }

        if (vm.goal && (vm.goal.category_id !== category.id)) {
            PubSub.emit($GoalsEvents.DATA_SOURCE_SELECTED);
            _resetSelectedDataView();
            _resetSelectedMetric();
        }
        vm.goal.source_type = category.type = DataSourceType.CATEGORY_DATA;
        if (vm.goal && vm.goal.category_id !== category.id) {
            PubSub.emit($GoalsEvents.DATA_SOURCE_SELECTED);
        }

        vm.goal.service_id = null;
        vm.goal.smart_campaign_id = null;
        vm.goal.category_id = category.id;
        vm.goal.data_source = category;
        vm.goal.data_source.data_view = vm.goal.dataview_id;
        vm.goal.mapping = [];

        vm.dataViewSelectOptions.getDataCall().then(function (json) {
            vm.dataViewSelectOptions.callFormatData(json);
        });
    }

    /**
     *
     */
    function dataViewOnChange($el) {
        let dataview = $el.select2('data');
        vm.goal.dataview_id = dataview.id;
        vm.goal.data_source.data_view = dataview.id;

        _resetSelectedMetric();
        PubSub.emit($GoalsEvents.DATA_SOURCE_SELECTED);

        vm.metricSelectOptions.getDataCall().then(function (json) {
            vm.metricSelectOptions.callFormatData(json);
        });
    }

    /**
     *
     */
    function metricOnChange($el) {
        let metric = $el.select2('data');
        vm.goal.metric = metric.field;

        _resetSelectedCampaign();
        PubSub.emit($GoalsEvents.DATA_SOURCE_SELECTED);

        vm.campaignSelectOptions.getDataCall().then(function (json) {
            vm.campaignSelectOptions.callFormatData(json);
        });
    }

    function isCampaignSelectDisabled() {
        return vm.goal.isSmartCampaignDataType();
    }

    function isServiceSelectDisabled() {
        return !vm.goal.isServiceDataType();
    }

    function isSmartCampaignSelectDisabled() {
        return !vm.goal.isSmartCampaignDataType();
    }

    /**
     *
     * @returns {boolean}
     */
    function isCategorySelectDisabled() {
        return !vm.goal.isCategoryDataType();
    }

    /**
     *
     * @returns {boolean|boolean|*|boolean}
     */
    function showCampaignSelect() {
      return (
        vm.campaignSelectOptions &&
        !_.isNull(vm.campaignSelectOptions.values) &&
        (vm.campaignSelectOptions.canShow || !vm.goal.id) &&
        (vm.goal.service_id || vm.goal.category_id) && vm.goal.metric
      );
    }

    /**
     *
     * @returns {boolean}
     */
    function showServiceSelect() {
        return vm.serviceSelectOptions && !_.isNull(vm.serviceSelectOptions.values);
    }

    function showSmartCampaignSelect() {
        return vm.isSmartCampaignsAvailable && vm.smartCampaignSelectOptions && !_.isNull(vm.smartCampaignSelectOptions.values);
    }

    /**
     *
     * @returns {*|boolean}
     */
    function showCategorySelect() {
        return vm.isCategoryAvailable && vm.categorySelectOptions && !_.isNull(vm.categorySelectOptions.values);
    }

    /**
     *
     * @returns {boolean}
     */
    function showDataViewSelect() {
        return vm.dataViewSelectOptions && !_.isEmpty(vm.dataViewSelectOptions.values) && (vm.goal.category_id || vm.goal.service_id);
    }

    /**
     *
     * @returns {boolean|string|null|*}
     */
    function showMetricSelect() {
        return vm.metricSelectOptions && !_.isEmpty(vm.metricSelectOptions.values) && vm.goal.dataview_id && (vm.goal.category_id || vm.goal.service_id);
    }

    function reset() {
        vm.goal = {};
        vm.state = GoalsModelFactory.getDefaultState();
    }

    /**
     * Close modal, and reset modal state
     */
    function closeModal() {
        reset();
        UIFactory.hideModal('add-goals-modal')
    }

    /**
     * Initialize widgetPublishModal
     */
    function $onInit() {
        /**
         *
         * @type {*|{}|DefaultState}
         */
        vm.state = GoalsModelFactory.getDefaultState();

        /**
         * @type {GoalModalViewBindings}
         */
        vm.viewBindings = GoalsModelFactory.getGoalModalViewBindings();

        DetailsModalFactory.loadModel().then(function () {
            /**
             *
             * @type {{}}
             */
            vm.viewBindings.data_source = {};

            vm.metadata = DetailsModalFactory.getMetadata();
            vm.goal = GoalsModelFactory.getGoal(DetailsModalFactory.getModel());
            vm.goal.is_old = true;

            _setOptions();

            vm.showDetails = (vm.goal.id);

            vm.viewBindings = GoalsModelFactory.getGoalModalViewBindings(vm.goal);
            if (vm.goal.smart_campaign_id) {
                vm.dataSourceSelectOptions.selectedValues = _.find(vm.dataSourceSelectOptions.values, {id: DataSourceType.SMART_CAMPAIGN});
                vm.viewBindings.type = DataSourceType.SMART_CAMPAIGN;
                vm.goal.data_source = SmartCampaignService.getSmartCampaign(vm.goal.smart_campaign_id);
                vm.goal.data_source.type = DataSourceType.SMART_CAMPAIGN;
                vm.viewBindings.campaign_name = vm.goal.campaign_name;
                vm.goal.source_type = DataSourceType.SMART_CAMPAIGN;
                vm.goal.dataview_id = DataViewName.CAMPAIGN;
                vm.goal.data_source.data_view = vm.goal.dataview_id;
                vm.goal.data_source.campaign_name = vm.goal.data_source.name;
            } else if (vm.goal.service_id) {
                vm.dataSourceSelectOptions.selectedValues = _.find(vm.dataSourceSelectOptions.values, {id: DataSourceType.SERVICE_DATA});
                _.assign(vm.viewBindings.service, AppFactory.getService(vm.goal.service_id));
                // need to set data source select options
                vm.viewBindings.type = DataSourceType.SERVICE_DATA;
                vm.viewBindings.campaign_name = vm.goal.campaign_name;
                vm.goal.source_type = DataSourceType.SERVICE_DATA;
                vm.goal.dataview_id = _.isNil(vm.goal.dataview_id) ? DataViewName.CAMPAIGN : vm.goal.dataview_id;
                vm.goal.data_source = vm.viewBindings.service;
                vm.goal.data_source.data_view = vm.goal.dataview_id;
                vm.goal.data_source.campaign_name = vm.goal.campaign_name;
            } else if (vm.goal.category_id) {
                vm.dataSourceSelectOptions.selectedValues = _.find(vm.dataSourceSelectOptions.values, {id: DataSourceType.CATEGORY_DATA});
                _.assign(vm.viewBindings.category, AppFactory.getCategory(vm.goal.category_id));
                // need to set data source select options
                vm.viewBindings.type = DataSourceType.CATEGORY_DATA;
                vm.viewBindings.campaign_name = vm.goal.campaign_name;
                vm.goal.source_type = DataSourceType.CATEGORY_DATA;
                vm.goal.data_source = vm.viewBindings.category;
                vm.goal.data_source.data_view = vm.goal.dataview_id;
                vm.goal.data_source.campaign_name = vm.goal.campaign_name;
            }
        });

        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * Set goal modal select options
     * @private
     */
    function _setOptions() {
        vm.dataSourceSelectOptions = GoalsModelFactory.getGoalDataSourceSelectOptions({
            loaded: true,
            onChange: dataSourceOnChange,
            values: [
                {
                    text: 'Data Sources',
                    name: 'Data Sources',
                    id: DataSourceType.SERVICE_DATA
                }
            ],
            selectedValues: []
        });

        if (vm.isSmartCampaignsAvailable) {
            vm.dataSourceSelectOptions.values.push({
                text: 'Smart Campaigns',
                name: 'Smart Campaigns',
                id: DataSourceType.SMART_CAMPAIGN
            })
        }

        if(vm.isCategoryAvailable) {
            vm.dataSourceSelectOptions.values.push({
                text: 'Channels',
                name: 'Channels',
                id: DataSourceType.CATEGORY_DATA
            })
        }

        /**
         * @type {GoalDataSourceSelectOptions}
         */
        vm.serviceSelectOptions = GoalsModelFactory.getGoalServiceSelectOptions({
            onChange: serviceOnChange,
            loaded: true,
            values: null,
            getDataCall: function () {
                let self = this;
                self.loaded = false;
                const params = {};
                if (vm.goal.customer_id) {
                    params.customer_id = vm.goal.customer_id;
                }
                return GoalsResource.getServices(params);
            },
            formatData: function (data) {
                let self = this;
                self.values = _.reduce(data, function (result, service) {
                    let appService = AppFactory.getService(service.id);
                    if (appService && appService.is_connected) {
                        result.push({
                            id: service.id,
                            text: service.name,
                            icon: appService.icon,
                            color: appService.color,
                            primary_name: service.primary_name,
                            is_custom_service: appService.is_custom_service,
                            has_custom_icon: appService.has_custom_icon,
                            type: appService.type
                        });
                    }
                    if (vm.goal && vm.goal.service_id === service.id) {
                        vm.goal.primary_name = service.primary_name;
                        if (!vm.goal.metric) {
                            vm.goal.metric = vm.goal.primary_name;
                        }
                    }
                    return result;
                }, []);
                if (vm.goal && vm.goal.service_id) {
                    self.selectedValues = _.find(self.values, {id: vm.goal.service_id});
                    vm.dataViewSelectOptions.getDataCall().then(function (json) {
                        vm.dataViewSelectOptions.callFormatData(json);
                    });
                }
                self.loaded = true;
            }
        });

        /**
         *
         * @type {SmartCampaignSelectOptions}
         */
        if (vm.isSmartCampaignsAvailable) {
            vm.smartCampaignSelectOptions = GoalsModelFactory.getSmartCampaignSelectOptions({
                onChange: smartCampaignOnChange,
                loaded: true,
                values: null,
                getDataCall: function() {
                    const params = {};
                    if (vm.goal.customer_id) {
                        params.customer_id = `${vm.goal.customer_id},null`;
                    }
                    let self = this;
                    self.loaded = false;
                    return SmartCampaignResource.getData(params);
                },
                formatData: function(data) {
                    let self = this;
                    self.values = _.map(data, function(smartCampaign) {
                        smartCampaign.text = smartCampaign.name;
                        return smartCampaign;
                    });
                    if (vm.goal && vm.goal.smart_campaign_id) {
                        self.selectedValues = _.find(self.values, {id: vm.goal.smart_campaign_id});
                        PubSub.emit($GoalsEvents.CAMPAIGN_SELECTED, vm.goal.data_source);
                    }
                    self.loaded = true;
                }
            });
        }

        if (vm.isCategoryAvailable) {
            vm.categorySelectOptions = GoalsModelFactory.getCategorySelectOptions({
                onChange: categoryOnChange,
                loaded: true,
                values: null,
                getDataCall: function () {
                    let self = this;
                    self.loaded = false;
                    const params = {sort: 'name', all: true};
                    if (vm.goal.customer_id) {
                        params.client_ids = vm.goal.customer_id;
                    }
                    return CategoryFactory.getCategoriesForClients(params);
                },
                formatData: function (data) {
                    let self = this;
                    self.values = _.map(data, function (category) {
                        category.text = category.name;
                        return category;
                    });
                    if (vm.goal && vm.goal.category_id) {
                        self.selectedValues = _.find(self.values, {id: vm.goal.category_id});
                        vm.dataViewSelectOptions.getDataCall().then(function (json) {
                            vm.dataViewSelectOptions.callFormatData(json);
                        });
                    }
                    self.loaded = true;
                }
            });
        }

        /**
         *
         * @type {ClientSelectOptions}
         */
        vm.clientSelectOptions = GoalsModelFactory.getClientSelectOptions({
            loaded: false,
            onChange: clientOnChange,
            getDataCall: function (query, param = {}) {
                let self = this;
                self.loaded = false;
                let params = {
                    reporting_status: 'active'
                };

                if (param) {
                    params = {...params, ...param}
                }

                if (query) {
                    params.q = query;
                }

                return ClientFactory.getFieldValues('id', params);
            },
            formatData: async function (data) {
                let self = this;
                if (
                    vm.goal.id &&
                    data.has_more_data &&
                    !_.find(data.values, {key: vm.goal.customer_id})
                ) {
                    const moreData = await this.getDataCall("", {
                        id: vm.goal.customer_id
                    });
                    data.values.push(_.first(moreData.values));
                }
                self.values = _.map(data.values, function (client) {
                    [client.text, client.id] = [client.value, client.key];

                    if (vm.goal && (client.id === vm.goal.customer_id)) {
                        self.selectedValues = client;
                        vm.viewBindings.clientName = client.text;
                    }
                    return client
                });
                self.loaded = true;
            }
        });

        /**
         * @type {GoalCampaignSelectOptions}
         */
        vm.campaignSelectOptions = GoalsModelFactory.getGoalCampaignSelectOptions({
            goal: vm.goal,
            loaded: true,
            onChange: campaignOnChange,
            values: null,
            canShow: false,
            formatData: async function(json) {
                let self = this;
                self.values = _.reduce(json.values, function(accum, column) {
                    if (column.key && column.value) {
                        accum.push({
                            id: column.key,
                            text: column.value
                        });
                    }
                    return accum;
                }, []);

                if (vm.goal && vm.goal.campaign_name) {
                    let selectedCampaign;
                    if (_.isArray(vm.goal.campaign_name)) {
                        if(json.has_more_data) {
                            vm.goal.campaign_name.forEach(campaign => {
                                if (!_.find(json.values, {text: campaign.name})) {
                                    self.values.push({
                                        id: campaign.name,
                                        text: campaign.name
                                    });
                                }
                            });
                        }
                        selectedCampaign = _.filter(self.values, (value) => {
                            return _.find(vm.goal.campaign_name, {name: value.text})
                        });
                    } else {
                        selectedCampaign = _.find(self.values, {text: vm.goal.campaign_name});
                    }
                    if (!_.isNil(selectedCampaign)) {
                        if (_.isEmpty(self.selectedValues)) {
                            self.selectedValues = selectedCampaign;
                            angular.element('#details-modal #goal-campaign-select div.select2-container').select2('data', self.selectedValues);
                        }

                        vm.goal.data_source.campaign_name = vm.goal.campaign_name;

                        PubSub.emit($GoalsEvents.CAMPAIGN_SELECTED, vm.goal.data_source);
                    }
                } else {
                    self.selectedValues = [];
                    self.$el && self.$el.select2('val', null);
                }

                self.loaded = true;

                if(!_.isEmpty(json)){
                    self.canShow = true;
                }
                self.hasMoreData = true;
            },
            getDataCall: function(query) {
                let self = this;
                self.loaded = false;

                if (vm.goal && vm.goal.source_type && vm.goal.service_id) {
                    self.loaded = false;
                    let params = {
                        group_by_id: true,
                        fields: vm.goal.metric
                    };
                    if (vm.goal.customer_id) {
                        params.customer_id = vm.goal.customer_id;
                    }

                    if (query) {
                        params[vm.goal.metric] = `*${query}*`;
                    }

                    return DataSourceResourceDelegate
                        .getFactory(vm.goal.source_type)
                        .data(vm.goal.service_id)
                        .one(vm.goal.dataview_id)
                        .one('values')
                        .one(ColumnValueType.DISTINCT).get(params);
                } else if (vm.goal && vm.goal.source_type && vm.goal.category_id) {
                    let params = {
                        fields: vm.goal.metric
                    };
                    if (vm.goal.customer_id) {
                        params.customer_id = vm.goal.customer_id;
                    }
                    return DataSourceResourceDelegate
                        .getFactory(vm.goal.source_type)
                        .data(vm.goal.category_id)
                        .one(vm.goal.dataview_id)
                        .one('values')
                        .one(ColumnValueType.DISTINCT).get(params);
                } else {
                    let deferred = $q.defer();

                    $timeout(function () {
                        deferred.resolve([]);
                    });

                    return deferred.promise;
                }
            }
        });

        vm.dataViewSelectOptions = GoalsModelFactory.getDataViewSelectOptions({
            goal: vm.goal,
            loaded: true,
            onChange: dataViewOnChange,
            values: null,
            formatData: function (data) {
                let self = this;
                self.values = _.map(data, function (dataview) {
                    dataview.text = dataview.name;
                    return dataview;
                });
                if (vm.goal && vm.goal.dataview_id) {
                    self.selectedValues = _.find(self.values, {id: vm.goal.dataview_id});
                    angular.element('#details-modal #goal-dataview-select div.select2-container').select2('data', self.selectedValues);
                    vm.metricSelectOptions.getDataCall().then(function (json) {
                        vm.metricSelectOptions.callFormatData(json);
                    });
                }
                self.loaded = true;
            },
            getDataCall: function (query) {
                let self = this;
                self.loaded = false;


                if (vm.goal && vm.goal.source_type && vm.goal.category_id) {
                    return DataSourceResourceDelegate
                        .getFactory(vm.goal.source_type)
                        .data(vm.goal.category_id)
                        .one('data_views')
                        .get();
                } else if (vm.goal && vm.goal.source_type == DataSourceType.SERVICE_DATA && vm.goal.service_id) {
                    return DataSourceResourceDelegate
                        .getFactory(vm.goal.source_type)
                        .data(vm.goal.service_id)
                        .one('data_views')
                        .get();
                } else {
                    let deferred = $q.defer();

                    $timeout(function () {
                        deferred.resolve([]);
                    });

                    return deferred.promise;
                }
            }
        });

        vm.metricSelectOptions = GoalsModelFactory.getMetricSelectOptions({
            goal: vm.goal,
            loaded: true,
            onChange: metricOnChange,
            values: null,
            formatData: function (data) {
                let self = this;
                self.values = _.map(data, function (metric) {
                    metric.text = metric.label;
                    metric.id = metric.field;
                    return metric;
                });
                if (vm.goal && vm.goal.metric) {
                    self.selectedValues = _.find(self.values, {field: vm.goal.metric});
                    angular.element('#details-modal #goal-metric-select div.select2-container').select2('data', self.selectedValues);
                    vm.campaignSelectOptions.getDataCall().then(function (json) {
                        vm.campaignSelectOptions.callFormatData(json);
                    });
                }
                self.loaded = true;
            },
            getDataCall: function (query) {
                let self = this;
                self.loaded = false;
                if (vm.goal && vm.goal.source_type && vm.goal.category_id && vm.goal.dataview_id) {
                    return DataSourceResourceDelegate
                        .getFactory(vm.goal.source_type)
                        .data(vm.goal.category_id)
                        .one(vm.goal.dataview_id)
                        .one('columns')
                        .get();
                } else if(vm.goal && vm.goal.source_type && vm.goal.service_id && vm.goal.dataview_id) {
                    let params = {
                        sort: 'label',
                        metadata: true,
                        is_metric: false,
                        format: 'string',
                        is_filterable: true
                    };

                    return DataSourceResourceDelegate
                        .getFactory(vm.goal.source_type)
                        .data(vm.goal.service_id)
                        .one(vm.goal.dataview_id)
                        .get(params);
                } else {
                    let deferred = $q.defer();

                    $timeout(function () {
                        deferred.resolve([]);
                    });

                    return deferred.promise;
                }
            }
        });
    }

    /**
     *
     * @param isAdding
     * @private
     */
    function _setAdding(isAdding) {
        vm.state.isAdding = isAdding
    }

    /**
     *
     * @param isReady
     * @private
     */
    function _setIsReady(isReady) {
        vm.state.isReady = isReady
    }

    /**
     *
     * @private
     */
    function _resetSelectedCampaign() {
        if (vm.goal) {
            vm.goal.campaign_name = null;
        }
        vm.viewBindings.campaign_name = null;
        angular.element('#details-modal #goal-campaign-select div.select2-container').select2('data', null);
    }

    /**
     *
     * @private
     */
    function _resetSelectedDataView() {
        if (vm.goal) {
            vm.goal.dataview_id = null;
        }
        angular.element('#details-modal #goal-dataview-select div.select2-container').select2('data', null);
    }

    /**
     *
     * @private
     */
    function _resetSelectedMetric() {
        if (vm.goal) {
            vm.goal.metric = null;
        }
        angular.element('#details-modal #goal-metric-select div.select2-container').select2('data', null);
    }
    
    /**
     * Pre-save hook before DetailModalService saves the details
     * @private
     */
    function _preSaveModel(model) {
        vm.state.isLoading = true;

        try {
            GoalsService.assignModelValues(model, vm.goal);
        } catch (error) {
            DetailsModalFactory.cancelSave(error.message);
        }
    }

    /**
     * Post save event from Detail model pipeline
     * @private
     */
    function _postSaveModel(data) {
        const isNewGoal = !vm.goal.id;

        vm.goal.id = data.id;

        GoalsService.addGoal(vm.goal);

        vm.state.isLoading = false;

        const goal = DetailsModalFactory.getModel();
        goal.client_name = vm.viewBindings.clientName;

        if (window.isNUI) {
          PubSub.emit("SegmentEvents", {
            event: "GoalSaveEvent",

            payload: { isCreated: isNewGoal, goal: goal }
          });
        }
    }

    function _errorSaveModel() {
        vm.state.isLoading = false;
        const service = vm.viewBindings.service;
        vm.viewBindings = GoalsModelFactory.getGoalModalViewBindings(vm.goal);
        if (vm.goal.service_id) {
            vm.viewBindings.service = service;
        }
        _setOptions();
        vm.dataSourceSelectOptions.selectedValues = _.find(
            vm.dataSourceSelectOptions.values,
            {id: vm.goal.source_type}
        );
    }

    /**
     *
     * @private
     */
    function _registerEvents() {
        PubSub.on($GoalsEvents.IS_ADDING, _setAdding);
        PubSub.on($GoalsEvents.IS_READY, _setIsReady);
        PubSub.on($GoalsEvents.DATA_SOURCE_SELECTED, _resetSelectedCampaign);
        PubSub.on($DetailsModalFactoryEvents.PRE_SAVE, _preSaveModel);
        PubSub.on($DetailsModalFactoryEvents.POST_SAVE, _postSaveModel);
        PubSub.on($DetailsModalFactoryEvents.POST_ERROR, _errorSaveModel);
    }

    function _unregisterEvents() {
        PubSub.off($GoalsEvents.IS_ADDING, _setAdding);
        PubSub.off($GoalsEvents.IS_READY, _setIsReady);
        PubSub.off($DetailsModalFactoryEvents.PRE_SAVE, _preSaveModel);
        PubSub.off($DetailsModalFactoryEvents.POST_SAVE, _postSaveModel);
        PubSub.off($DetailsModalFactoryEvents.POST_ERROR, _errorSaveModel);
    }
}

/**
 * @ngInject
 */
function GoalsDetailsController(
    $q,
    $GoalsEvents,
    GoalsModelFactory,
    PubSub,
    DataSourceType,
    WidgetFactory,
    DetailsModalFactory,
    AppModelFactory,
    DataSourceFactory,
    AppFactory,
    ColumnFormat
) {
    let vm = this;
    vm.isNUI = window.isNUI;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getColumn = getColumn;
    vm.isNew = isNew;
    vm.updateTitle = _.debounce(updateTitle, 200);
    vm.dataSource = null;
    vm.formatThreshold = formatThreshold;
    vm.setThresholds = setThresholds;

    function _setOptions() {
        vm.dateOptions = AppModelFactory.getDateOptions({
            startDate: vm.model.cycle_start,
            endDate: vm.model.cycle_end,
            dateChangeCB: function(start, end) {
                let utcOffset = moment().utcOffset()*60;
                vm.model.cycle_start = start.unix() + utcOffset;
                vm.model.cycle_end = end.unix() + utcOffset;
                vm.model.start = start.unix() + utcOffset;
                vm.model.end = end.unix() + utcOffset;
            }
        });

        vm.needADataSource = isNew() ? 'Select a data source and campaign' : null;
        vm.sliderOptionsThreshold1 = GoalsModelFactory.getSliderOptions({
            onChange: function() {
                vm.model.threshold_2 = Math.max(vm.model.threshold_2, vm.model.threshold_1);
                vm.sliderOptionsThreshold2.floor = vm.model.threshold_1
            }
        });
        vm.sliderOptionsThreshold2 = GoalsModelFactory.getSliderOptions({
            floor: vm.model.threshold_1
        });

        vm.columnSelectOptions = AppModelFactory.getSelectOptions({
            placeholder: 'Select a Column...',
            width: '100%',
            loaded: true,
            selectedValue: [],
            values: null,
            formatData: function(columns) {
                let self = this;

                self.values = _.map(columns, function(column) {
                    return {
                        id: column.field,
                        text: column.label,
                        format: column.format
                    };
                });

                // pre-select option
                if (!_.isEmpty(vm.model.column)) {
                    self.selectedValue = _.find(self.values, {id: vm.model.column});
                }

                self.loaded = true;
                self.canShow = true;

                return self;
            },
            getDataCall: function(query) {
                let self = this;


                let params = {
                    is_metric: 'true',
                    is_average: 'false',
                    format: '!percent',
                    ignore_reporting_profile: true
                };

                if (query) {
                    params.q = query + '|';
                }

                if (vm.dataSource) {
                    self.loaded = false;
                    let dataSource = _.assign({}, vm.dataSource);
                    if (vm.dataSource.type === DataSourceType.SMART_CAMPAIGN) {
                        dataSource.id = dataSource.service_id;
                        dataSource.data_view = dataSource.dataview;
                        dataSource.type = DataSourceType.SERVICE_DATA
                    }
                    return DataSourceFactory.getColumns(dataSource, params);
                } else {
                    let deferred = $q.defer();

                    return deferred.resolve([]);
                }
            },
            onChange: function($el) {
                if ($el && $el.val()) {
                    let val = $el.select2('data');
                    vm.model.column = val.id;
                    vm.model.column_label = val.text;
                    vm.model.column_format = val.format;
                    if(val.format == ColumnFormat.FORMAT_CURRENCY){
                        vm.model.metric_type = GoalMetricType.BUDGET;
                    } else {
                        vm.model.metric_type = GoalMetricType.PACING;
                    }
                } else {
                    vm.model.column = null;
                }
            }
        });
    }

    function $onInit() {
        vm.model = DetailsModalFactory.getModel();

        // input bug, target must be an int
        vm.model.target = parseInt(vm.model.target);
        vm.model.threshold_1 = parseInt(vm.model.threshold_1) || 20;
        vm.model.threshold_2 = parseInt(vm.model.threshold_2) || 50;

        vm.metadata = DetailsModalFactory.getMetadata();

        if (!vm.model.cycle_start && !vm.model.cycle_end) {
            vm.model.start = AppFactory.getDateRange().start;
            vm.model.end = AppFactory.getDateRange().end;
            vm.model.cycle_start = AppFactory.getDateRange().start;
            vm.model.cycle_end = AppFactory.getDateRange().end;
        }

        _setOptions();
        _registerEvents();
    }

    function $onDestroy() {
        PubSub.off($GoalsEvents.DATA_SOURCE_SELECTED, _resetSelectedColumn);
        PubSub.off($GoalsEvents.CAMPAIGN_SELECTED, _setColumnSelectOptions)
    }

    function isNew() {
        return DetailsModalFactory.getIsNewState();
    }

    function formatThreshold(key) {
        vm.model[key] = Math.min(parseInt(vm.model[key]) || 0, 100);
    }

    /**
     * @param field
     * @returns {any}
     */
    function getColumn(field) {
        return _.find(vm.metadata, {field: field});
    }

    /**
     * Update details modal title
     */
    function updateTitle() {
        DetailsModalFactory.updateTitle(vm.model.name);
    }

    function _resetSelectedColumn() {
        vm.columnSelectOptions.selectedValue = [];
        vm.columnSelectOptions.values = [];
        vm.model.column = null;
        angular.element('#details-modal #goal-column-select div.select2-container').select2('data', null);
    }

    /**
     * @param dataSource
     * @private
     */
    function _setColumnSelectOptions(dataSource) {
        if (vm.dataSource
            && (dataSource.id !== vm.dataSource.id
                || dataSource.campaign_name !== vm.dataSource.campaign_name)) {
            _resetSelectedColumn();
        }

        vm.dataSource = _.assign({}, dataSource);
        vm.needADataSource = null;
        vm.columnSelectOptions.selectedValue = [];

        if (!_.isEmpty(dataSource.campaign_name)) {
            vm.columnSelectOptions.getDataCall().then(function (json) {
                vm.columnSelectOptions.formatData(json);
            });

        }
    }

    function _registerEvents() {
        PubSub.on($GoalsEvents.DATA_SOURCE_SELECTED, _resetSelectedColumn);
        PubSub.on($GoalsEvents.CAMPAIGN_SELECTED, _setColumnSelectOptions);
    }

    function setThresholds() {
        vm.model.lower_threshold = vm.model.target * 0.9;
        vm.model.upper_threshold = vm.model.target * 1.1;
    }
}

