<template>
  <section data-cy="rectangle-container">
    <div class="line-chart" :style="{ bottom: hasComparison }">
      <component :is="chartComponent" v-show="sparklineData.length" :config="getChartConfig()" />
    </div>
  </section>
</template>

<script>
import { ScoreCardMixin } from '@/modules/core/scorecard/mixins/ScoreCardMixin';
import LineChart from '@/modules/core/charts/am5/line/components/LineChart.vue';
import { LineChartConfig } from '@/modules/core/charts/am5/line/models/LineChartConfig';
import BarChart from '@/modules/core/charts/am5/bar/components/BarChart.vue';
import { BarChartConfig } from '@/modules/core/charts/am5/bar/models/BarChartConfig';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import {
  SeriesType,
  ChartType,
  ChartAdapters,
  ColorCodes,
} from '@/modules/core/charts/chart.constants';

export default {
  name: 'EmbedSparkline',
  components: { BarChart, LineChart },
  mixins: [ScoreCardMixin],
  props: {
    field: {
      type: String,
      required: true,
    },
    sparklineData: {
      type: [Array, Object],
      default: () => [],
    },
    categoryField: {
      type: String,
      default: 'formatted_log_date',
    },
    widgetId: {
      type: [Number, String],
      default: 0,
    },
    sparklineType: {
      type: String,
      default: SeriesType.LINE,
    },
    isComparisonEnabled: {
      type: Boolean,
    },
    sparklineColor: {
      type: String,
      default: ColorCodes.BLACK_COLOR,
    },
  },
  computed: {
    // Dynamically compute the chart component based on sparklineType
    chartComponent() {
      return this.sparklineType === SeriesType.COLUMN ? ChartType.BAR_CHART : ChartType.LINE_CHART;
    },
    hasComparison() {
      return this.isComparisonEnabled ? `${1}rem` : `${-1}rem`;
    },
  },
  methods: {
    getChartConfig() {
      const commonConfig = {
        series: [
          {
            fill: this.sparklineColor,
            labelFill: ColorCodes.WHITE_COLOR,
            fillOpacity: this.sparklineType === SeriesType.COLUMN ? 0.5 : 0.3,
            opacity: 1,
            strokeOpacity: 0.5,
            tension: 0.9,
            isCircularBullets: false,
            value: this.field,
            category: this.categoryField,
            tooltipText: '',
            name: this.label,
            color: this.sparklineColor,
            dataItemFormat:
              this.format === ColumnFormat.FORMAT_TIME
                ? ColumnFormat.FORMAT_TIME
                : ColumnFormat.FORMAT_INTEGER,
          },
        ],
        categoryAxis: [
          {
            category: this.categoryField,
            showLabels: false,
            hideGridLines: true,
            minGridDistance: 30,
            dataItemFormat: ColumnFormat.FORMAT_STRING,
          },
        ],
        valueAxis: [
          {
            axisType: ChartAdapters.VALUE,
            hideGridLines: true,
            showLabels: true,
            dataItemFormat: ColumnFormat.FORMAT_INTEGER,
          },
        ],
        legend: {
          active: false,
        },
        data: this.sparklineData,
        isSparkLine: true,
        hasTooltip: true,
      };

      if (this.sparklineType === SeriesType.COLUMN) {
        return new BarChartConfig({
          ...commonConfig,
          series: [{ ...commonConfig.series[0], seriesType: SeriesType.COLUMN }],
        });
      }
      return new LineChartConfig({
        ...commonConfig,
        series: [{ ...commonConfig.series[0], seriesType: SeriesType.LINE }],
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* Apply styles to the first <section> element */
section {
  position: relative;
  padding: rem(0px) rem(5px);
  border-radius: $radius-small;
}

.line-chart {
  display: inline-flex;
  flex-wrap: wrap;
  width: 70%;
  height: 30px;
  position: absolute;
  left: 0;
  right: 0;

  :deep(section) {
    width: 100%;
    padding: 0;
  }
}

.b-tooltip {
  display: block;
}
</style>
