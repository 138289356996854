'use strict';
import angular from 'angular';

angular.module('core.library.models', [])

    .factory('LibraryModelFactory', LibraryModelFactory);

/**
 *
 * @ngInject
 */
function LibraryModelFactory(
    DataSourceType,
    LibrarySearchGroup,
    gettextCatalog
) {
    return {
        getLibraryModalData: getLibraryModalData,
        getLibraryModalState: getLibraryModalState,
        getLibraryDetailedItemData: getLibraryDetailedItemData,
        getLibraryDetailedItemState: getLibraryDetailedItemState,
        getLibraryCarouselOptions: getLibraryCarouselOptions,
        getLibrarySearchBoxConfig: getLibrarySearchBoxConfig,
        getLibraryTitleSearchBoxConfig: getLibraryTitleSearchBoxConfig
    };

    /**
     * @constructor
     */
    function LibraryModalData() {
        var self = this;

        self.mostUsedItems = [];
        self.recentlyUploadedItems = [];
        self.searchedItems = [];
        self.searchableValues = new _LibrarySearchableValues();
    }

    /**
     * @constructor
     */
    function LibraryModalState() {
        var self = this;

        self.isActive = false;
        self.isLoading = false;
        self.isSearchBoxActive = false;
        self.isSearching = false;
    }

    /**
     * @constructor
     */
    function LibraryDetailedItemData() {
        var self = this;

        self.isConnected = false;
        self.selectedItem = null;
        self.currentLayout = null;
    }

    /**
     * @constructor
     */
    function LibraryDetailedItemState() {
        var self = this;

        self.isLoading = false;
        self.isSaving = false;
    }

    /**
     * @constructor
     * @private
     */
    function _LibrarySearchableValues() {
        var self = this;

        self.dataSources = [];
        self.widgetTypes = [];
        self.tags = [];
    }

    /**
     * @constructor
     */
    function LibrarySearchBoxConfig() {
        var self = this;

        self.selectOptions = new _LibrarySearchBoxSelectOptions();
        self.values = [];
        self.selectedValues = [];
        self.showResults = false;
    }

    /**
     * @constructor
     */
    function LibraryTitleSearchBoxConfig() {
        var self = this;

        self.value = '';
    }

    /**
     * @constructor
     */
    function LibraryCarouselOptions() {
        var self = this;

        self.stagePadding = 50;
        self.items = 8;
        self.margin = 10;
        self.startPosition = 0;
        self.dots = true;
        self.loop = false;
        self.responsive = {
            0: {
                items: 1
            },
            500: {
                items: 2
            },
            700: {
                items: 3
            },
            1000: {
                items: 4
            },
            1200: {
                items: 4
            },
            1600: {
                items: 5
            }
        };
    }

    /**
     * @constructor
     * @private
     */
    function _LibrarySearchBoxSelectOptions() {
        var self = this;
        var _placeholder = gettextCatalog.getString('Filter by data source or tag...');
        var _noMatch = function (value) {
            return '<span>No search filters match: <i>' + value + '</i>';
        };

        self.multiple = true;
        self.allowClear = true;
        self.dropdownCssClass = 'select2-template-search-dropdown';
        self.width =  '100%';
        self.placeholder =  _placeholder;
        self.formatResult = _formatDisplay;
        self.formatSelection = function(item) {
            return _formatDisplay(item);
        };
        self.formatNoMatches = function (value) {
            return _noMatch(value);
        };
        self.matcher = _matchCustom;

        /**
         * Select2 override to custom match search string
         * @param value
         * @param data
         * @param opt
         * @returns {*}
         */
        function _matchCustom(value, data, opt) {
            if (!opt.group) {
                return null;
            }
            if (data.toLowerCase().contains(value.toLowerCase())) {
                return data;
            }
            return null;
        }

        /**
         * Select2 override How to show selected value in dropdown or as a selectable value
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatDisplay(item) {
            var result = null;
            if (item.group === LibrarySearchGroup.DATA_SOURCES) {
                var icon = item.icon;
                if (item.type === DataSourceType.SERVICE_DATA) {
                    icon = item.has_custom_icon ? 'custom-icon ' + item.icon : 'serviceicon-' + item.icon;
                } else {
                    icon = item.has_custom_icon ? 'custom-icon ' + item.icon : item.icon;
                }
                result = '<div><div class="service-square service-square-24" style="background-color:'+ item.color +'"><div class="icon ' + icon + '"></div></div> ' + item.text + '</div>'
            } else if (item.group === LibrarySearchGroup.TAGS) {
                result = '<i class="icon icon-tag"></i>' + item.text;
            } else if (item.group === LibrarySearchGroup.WIDGET_TYPES) {
                result = '<i class="icon icomoon-'+ item.icon +'"></i>' + item.text;
            } else {
                result = item.text;
            }
            return result;
        }
    }

    /**
     * @returns {LibraryModalData}
     */
    function getLibraryModalData() {
        return new LibraryModalData();
    }

    /**
     * @returns {LibraryModalState}
     */
    function getLibraryModalState() {
        return new LibraryModalState();
    }

    /**
     * @returns {LibraryDetailedItemData}
     */
    function getLibraryDetailedItemData() {
        return new LibraryDetailedItemData();
    }

    /**
     * @returns {LibraryDetailedItemState}
     */
    function getLibraryDetailedItemState() {
        return new LibraryDetailedItemState();
    }

    /**
     * Return owl carousel Options
     * @returns {LibraryCarouselOptions}
     */
    function getLibraryCarouselOptions() {
        return new LibraryCarouselOptions();
    }

    /**
     * @returns {LibrarySearchBoxConfig}
     */
    function getLibrarySearchBoxConfig() {
        return new LibrarySearchBoxConfig();
    }

    function getLibraryTitleSearchBoxConfig() {
        return new LibraryTitleSearchBoxConfig();
    }
}