import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class OverviewDetails extends BaseModel {
  /**
   * @var {object}
   */
  overview_details;

  constructor(model = {}) {
    super();
    this.overview_details = model.overview_details ?? {};
  }
}
