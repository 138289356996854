import {
  DateFormatType,
  TimeGrouping,
  WidgetDatePeriodFormat,
} from '@/modules/ta/widget/widget.constants';

export const getDateFormatFromTimeGrouping = (timeGrouping, dateFormatType) => {
  const isYearExcluded = dateFormatType === DateFormatType.NO_YEAR;
  switch (timeGrouping?.toLowerCase()) {
    case TimeGrouping.GROUPING_YEARLY:
      return WidgetDatePeriodFormat.YEAR;
    case TimeGrouping.GROUPING_MONTHLY:
      return isYearExcluded
        ? WidgetDatePeriodFormat.MONTH_WITHOUT_YEAR
        : WidgetDatePeriodFormat.MONTH;
    case TimeGrouping.GROUPING_WEEKLY:
    case TimeGrouping.GROUPING_DAILY:
      return isYearExcluded
        ? WidgetDatePeriodFormat.DATE_WITHOUT_YEAR
        : WidgetDatePeriodFormat.DATE;
    case TimeGrouping.GROUPING_HOURLY:
      return isYearExcluded
        ? WidgetDatePeriodFormat.HOUR_WITHOUT_YEAR
        : WidgetDatePeriodFormat.HOUR;
    case TimeGrouping.GROUPING_MONTH_OF_YEAR:
    case TimeGrouping.GROUPING_QUARTERLY:
      return WidgetDatePeriodFormat.MONTH_OF_YEAR;
    case TimeGrouping.GROUPING_DAY_OF_MONTH:
      return WidgetDatePeriodFormat.DAY_OF_MONTH;
    case TimeGrouping.GROUPING_DAY_OF_WEEK:
      return WidgetDatePeriodFormat.DAY_OF_WEEK;
    case TimeGrouping.GROUPING_HOUR_OF_DAY:
      return WidgetDatePeriodFormat.HOUR_OF_DAY;
    default:
      return WidgetDatePeriodFormat.DATE;
  }
};
