<template>
  <section>
    <PieChart
      v-if="canShowChart"
      :config="chartConfig"
      width="100%"
      height="100%"
      @frame-ended="setIdle"
    />
    <PieChart
      v-if="canShowComparisonChart"
      :config="comparisonChartConfig"
      width="100%"
      height="100%"
      is-comparison
      @frame-ended="setIdle"
    />
  </section>
</template>

<script>
import PieChart from '@/modules/core/charts/am5/pie/components/PieChart.vue';
import { LegacyPieChartWidgetRenderService } from '@/modules/core/charts/am5/pie/services/LegacyPieChartWidgetRenderService';
import { isEmpty } from 'lodash';
import { dispatches } from '@/modules/core/app/helpers/store';
import { WidgetEvent } from '@/modules/ta/widget/widget.constants';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { BaseChartWidgetPropsMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetPropsMixin';
import { ChartPlace } from '@/modules/core/charts/am5/charts.constants';

export default {
  name: 'LegacyPieChartWidget',
  mixins: [BaseChartWidgetPropsMixin],
  components: { PieChart },
  data() {
    return {
      comparisonChartConfig: null,
    };
  },
  computed: {
    canShowComparisonChart() {
      return this.hasComparisonData && this.comparisonChartConfig && !isEmpty(this.data);
    },
    getWidth() {
      return this.hasComparisonData ? '50%' : '100%';
    },
  },
  mounted() {
    EventBus.listen(`${WidgetEvent.RELOAD_WIDGET}-${this.widget.id}`, () => {
      this.init();
    });
    this.init();
  },
  methods: {
    async init() {
      dispatches.dashboardExport.setUnrenderedWidgetIds([this.widget.id]);
      const widget = await this.setDrillDownOptions();
      const palette = widget.metadata.chart_palette ?? this.chartPalette;
      const renderer = new LegacyPieChartWidgetRenderService(
        widget,
        palette,
        this.hasComparisonData,
        this.isExporting
      );
      this.chartConfig = renderer.getChartConfig({ isComparison: false });
      let chartTransparency = 1;
      if (widget.report_id && this.elementMetadata) {
        chartTransparency = this.elementMetadata?.base_options?.background_color ? 1 : 0;
      }
      this.chartConfig.setAll({
        chartTransparency,
        widget,
        chartPlace: widget.report_id ? ChartPlace.REPORT : ChartPlace.DASHBOARD,
        clientCurrencySymbol: this.clientCurrencySymbol,
      });

      if (this.hasComparisonData) {
        this.comparisonChartConfig = renderer.getChartConfig({
          isComparison: this.hasComparisonData,
        });
        this.comparisonChartConfig.setAll({
          chartTransparency,
          widget,
          clientCurrencySymbol: this.clientCurrencySymbol,
        });
      }
    },
    async setIdle() {
      dispatches.dashboardExport.setWidgetAsRendered(this.widget.id);
    },
  },
};
</script>

<style scoped lang="scss">
section {
  width: 100%;
}
</style>
