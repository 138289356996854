'use strict';
import angular from 'angular';
import {ManageProductsColumns} from '../manageproducts.constants';
import {PageEntity} from '../../design/design.constants';
import _ from "lodash";

/**
 * @ngInject
 * @constructor
 */
class ManageProductsResource {

    /**
     * @ngInject
     */
    constructor(
        Restangular
    ) {
        this.Restangular = Restangular;
        this.products = Restangular.all('products');
    }

    /**
     * @param queryParams
     * @returns {*|angular.IPromise<T>}
     */
    getData(queryParams) {
        return this.getProducts(_.extend({
            all: true
        }, queryParams || {}));
    }

    /**
     * @param queryParams
     * @returns {*|angular.IPromise<T>}
     */
    getColumns(queryParams) {
        return this.getProducts(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * @returns {undefined|*|angular.IPromise<T>}
     */
    getProducts(queryParams) {
        return this.products.getList(queryParams).then(json => json.plain());
    }
}

/**
 * @ngInject
 * @constructor
 */
class ProductDataGridFactory {

    /**
     * @ngInject
     */
    constructor(
        DataGridRender,
        ManageProductsFactory,
        DesignFactory
    ) {
        this.DataGridRender = DataGridRender;
        this.ManageProductsFactory = ManageProductsFactory;
        this.DesignFactory = DesignFactory;
    }

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    getDTOptions() {
        const dtOptions = {};
        dtOptions.customRenders = {};
        const showActions = this.DesignFactory.getCurrentPage().entity === PageEntity.PAGE_MANAGE_PRODUCTS;
        dtOptions.numActions = showActions ? 2 : 0;

        dtOptions.customRenders[ManageProductsColumns.ID] = (data, type, full) => {
            if (!showActions) {
                return '';
            }
            let html = this.DataGridRender.getEditDetailsButton(ManageProductsColumns.ID, full.can_be_edited);
            html += this.DataGridRender.getDeleteButton(
                full.can_be_deleted,
                'products/' + data,
                full.name,
                full.can_be_deleted ? '' : full.can_be_deleted_tooltip
            );
            return html;
        };

        dtOptions.postCreatedRowCallback = ($link, row, data) => {
            if ($link.data('field') === ManageProductsColumns.ID) {
                $link.click(() => this.ManageProductsFactory.setEditDetails(data, row));
            }
        };

        return dtOptions;
    }

}

angular.module('core.manageproducts.resources', [])
    .service('ManageProductsResource', ManageProductsResource)
    .service('ProductDataGridFactory', ProductDataGridFactory);

