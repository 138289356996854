import { BigNumberMetadata } from '@/modules/ta/widget/models/metadata/BigNumberMetadata';
import { WidgetType } from '@/modules/ta/widget/widget.constants';
import { DataGridWidgetMetadata } from '@/modules/ta/widget/models/metadata/DataGridWidgetMetadata';
import { TopPerformerWidgetMetadata } from '@/modules/ta/widget/models/metadata/TopPerformerWidgetMetadata';
import { BarChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/BarChartWidgetMetadata';
import { CombinationChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/CombinationChartWidgetMetadata';
import { LineChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/LineChartWidgetMetadata';
import { PieChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/PieChartWidgetMetadata';
import { GeoChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/GeoChartWidgetMetadata';
import { GaugeChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/GaugeChartWidgetMetadata';
import { FunnelChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/FunnelChartWidgetMetadata';
import { MediaWidgetMetadata } from '@/modules/ta/widget/models/metadata/MediaWidgetMetadata';
import { AccountManagerWidgetMetadata } from '@/modules/ta/widget/models/metadata/AccountManagerWidgetMetadata';
import { GoalWidgetMetadata } from '@/modules/ta/widget/models/metadata/GoalWidgetMetadata';
import { BaseWidgetMetadata } from '@/modules/ta/widget/models/metadata/BaseWidgetMetadata';
import { BubbleChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/BubbleChartWidgetMetadata';

export class WidgetMetadataFactory {
  /**
   * @param {Widget} widget
   * @returns {BaseWidgetMetadata}
   */
  static build(widget) {
    switch (widget?.type) {
      case WidgetType.TYPE_BIGNUMBER:
        return new BigNumberMetadata(widget.metadata);
      case WidgetType.TYPE_DATAGRID:
        return new DataGridWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_TOPPERFORMER:
        return new TopPerformerWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_BARCHART:
        return new BarChartWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_BUBBLECHART:
        return new BubbleChartWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_COMBINATIONCHART:
        return new CombinationChartWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_LINECHART:
        return new LineChartWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_PIECHART:
        return new PieChartWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_GEOCHART:
        return new GeoChartWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_GAUGECHART:
        return new GaugeChartWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_FUNNELCHART:
        return new FunnelChartWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_MEDIA:
        return new MediaWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_ACCOUNTMANAGER:
        return new AccountManagerWidgetMetadata(widget.metadata);
      case WidgetType.TYPE_GOAL:
        return new GoalWidgetMetadata(widget.metadata);
      default:
        Logger.log(i18n.$t('Unsupported widget type'), Logger.LEVEL_ERROR);
        return new BaseWidgetMetadata(); // needed for mocking in unit tests
    }
  }
}
