'use strict';
import angular from 'angular';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';

import alertModalHtmlUrl from '../base/alert.modal.html';
import alertWidgetTogglerHtmlUrl from './alert.widget.toggler.html';

angular.module('core.alert.widget.components', [])

    .component('alertWidgetModal', {
        templateUrl: alertModalHtmlUrl,
        bindings: {
            isAdminPage: '<' // Tells if we are in the alert admin page
        },
        controller: AlertWidgetController,
        controllerAs: 'vm'
    })

    .component('alertToggler', {
        templateUrl: alertWidgetTogglerHtmlUrl,
        bindings: {
            widget: '<'
        },
        controller: AlertToggleController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function AlertWidgetController(
    $AlertEvents,
    $scope,
    $timeout,
    PubSub,
    DataSourceType,
    WidgetFactory,
    AlertFactory,
    AlertItemFactory,
    AlertModelFactory,
    UIFactory,
    AppFactory,
    UserFactory
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.isNUI = window.isNUI;

    // Option object inherited by all child alert items
    var options = vm.options = {};

    vm.state = AlertModelFactory.getDefaultState();

    vm.initAlert = initAlert;
    vm.saveAlert = saveAlert;
    vm.removeAlert = removeAlert;
    vm.addAlert = addAlert;
    vm.reset = reset;
    vm.options.getUserData = getUserData;

    function $onInit() {
        _registerEvents();
    }

    function getUserData() {
        //Intialize the list of users
        options.recipientSelectOptions = AlertModelFactory.getRecipientOptions(options.recipientSelectOptions);
        var currentUser = AppFactory.getUser();
        if (currentUser.isAdmin()) {
            UserFactory.getData({
                user_type: AlertFactory.getUserTypeParams(true, false),
                status: 'active',
                fields: 'first_name,last_name,email'
            }).then(function(data){
                var users = data.plain();
                options.recipientSelectOptions.data = AlertFactory.genUserSelectData(users);
                options.recipientSelectOptions.loaded = true;
            });
        }
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _registerEvents() {
        PubSub.on($AlertEvents.INIT_WIDGET_ALERT, initAlert);
        PubSub.on($AlertEvents.REMOVE_WIDGET_ALERT, removeAlert);
    }

    function _unregisterEvents() {
        PubSub.off($AlertEvents.INIT_WIDGET_ALERT, initAlert);
        PubSub.off($AlertEvents.REMOVE_WIDGET_ALERT, removeAlert);
    }

    /**
     * @param widget
     */
    function initAlert(widget) {
        UIFactory.showModal('edit-alert-modal');
        var metadata = angular.copy(widget.metadata);
        // Needed for creating new widget alert objects
        vm.metadata = metadata;
        // Set alert model to be saved
        vm.model = {};
        vm.model.alerts = metadata.dynamic.alerts || [];
        vm.model.widget_id = widget.id;

        // Alerts that already here on init aren't new
        _.each(metadata.dynamic.alerts, function (alert) {
            alert.isNew = false;
        });

        if (_.isEmpty(metadata.dynamic.alerts)) {
            addAlert();
        }
        else {
            _.each(vm.model.alerts, function(alert) {
                alert.datasource = metadata.data_source;
            });
            vm.state.showDelete = true;
        }

        // Send list of possible metrics to each alert item child
        vm.metrics = metadata.data_columns.selected;
    }

    function saveAlert() {
        AlertFactory.saveWidgetAlert(vm.model, vm.state);
    }

    function addAlert() {
        vm.model.alerts.push(createAlertObject());
        vm.state.showDelete = vm.model.alerts.length > 1;
    }

    function removeAlert(alertToRemove) {
        AlertFactory.removeAlert(vm.model, vm.state, alertToRemove, function() {
            swal.close();
        });
    }

    //Intialize the alert object
    function createAlertObject() {
        return AlertModelFactory.getNewWidgetAlert(vm.model, vm.metadata);
    }

    /**
     * Variables to reset when leaving the alert process (i.e. exiting the modal)
     */
    function reset() {
        // As a safety measure force a scope digest here since closing the modal happens does not respond to ng-click
        $scope.$evalAsync(function() {
            vm.model = {};
            vm.state = AlertModelFactory.getDefaultState();
        });
    }
}

/**
 * @ngInject
 */
function AlertToggleController(
    PubSub,
    $AlertEvents
) {
    var vm = this;

    vm.editWidgetAlert = editWidgetAlert;

    function editWidgetAlert() {
        PubSub.emit($AlertEvents.INIT_WIDGET_ALERT, vm.widget, true);
    }
}