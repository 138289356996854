'use strict';
import angular from 'angular';

angular.module('role.services', [])

    .factory('RoleService', RoleService);

//
// Role factory
//
/**
 * @ngInject
 */
function RoleService(Restangular) {
    var roles = Restangular.service('roles');
    var currentRole = {};

    function getData(queryParams) {
        return roles.getList(queryParams);
    }

    function setCurrentRole(roleObj) {
        currentRole = roleObj;
    }

    function getCurrentRole() {
        return currentRole;
    }

    return {
        roles: roles,
        getData: getData,
        setCurrentRole: setCurrentRole,
        getCurrentRole: getCurrentRole,
    }
}