'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.geochart.ctrls', [])

    .controller('GeoChartWidgetController', GeoChartWidgetController);

GeoChartWidgetController.$inject = [
    '$scope',
    'DrawOption',
    'ChartPlotType',
    'MapTypes'
];
function GeoChartWidgetController(
    $scope,
    DrawOption,
    WidgetType,
    MapTypes,
    ChartFactory
) {
    $scope.$on('chart:setMetadataDefaults', function(e, metadata) {
        setMetadataDefaults(metadata);
    });

    function setMetadataDefaults(metadata) {
        var drawOptions = {};
        drawOptions[DrawOption.HAS_TOOLTIP] = true;
        drawOptions[DrawOption.PLOT_TYPE] = metadata.draw_options[DrawOption.PLOT_TYPE] || ChartFactory.getDefaultPlotType(WidgetType.GEOCHART);
        drawOptions[DrawOption.MAP_TYPE] = metadata.draw_options[DrawOption.MAP_TYPE] || MapTypes.STREET;
        metadata.draw_options = _.assign(drawOptions, metadata.draw_options);
    }
}