'use strict';
import angular from 'angular';

angular.module('core.connectionstatus.constants', [])

    .constant('ConnectionStatus', {
        SUCCESS: 'success',
        IN_PROGRESS: 'in_progress',
        CREDENTIALS: 'credentials',
        USER_CONFIGURATION: 'user_configuration',
        OTHER: 'other'
    })

    .constant('ConnectionsStatusIconStyles', {
        SUCCESS: 'icon-check connectionstatus-success',
        IN_PROGRESS: 'icon-refresh connectionstatus-in-progress',
        WARNING: 'icon-exclamation-triangle connectionstatus-warning',
        ERROR: 'icon-exclamation-circle connectionstatus-error'
    });