import angular from 'angular';
import $ from 'jquery';
import DevTools from '../../../../devtools';
import Clipboard from 'clipboard';

import exportBuilderMenuDevToolsHtmlUrl from './exportbuilder.menu.devtools.html';

angular.module('exportbuilder.dashboard.directives')
    .directive('exportBuilderRightClickMenuDirective', exportBuilderRightClickMenuDirective)
    .directive('exportBuilderRightClickSubMenuDirective', exportBuilderRightClickSubMenuDirective)
    .directive('exportBuilderDevToolsMenu', exportBuilderDevToolsMenu);

/**
 * @ngInject
 */
function exportBuilderRightClickMenuDirective(
    $timeout,
    ExportBuilderMenuService
) {
    return {
        restrict: 'A',
        link: function (scope, el) {
            $timeout(function () {
                var $$window = $(window);
                var windowHeight = $$window.height();
                var windowWidth = $$window.width();
                var $parent = el.parents('div.export-builder-menu');
                var offset = ExportBuilderMenuService.getPosition();
                var dropdown = $(el);

                if (offset.y + dropdown.outerHeight() >= windowHeight) {
                    var extraPadding = 20;
                    $parent.css('top', windowHeight - dropdown.outerHeight() - extraPadding)
                } else {
                    $parent.css('top', offset.y);
                }

                if (offset.x + dropdown.width() >= windowWidth) {
                    var extraPadding = 20;
                    $parent.css('left', windowWidth - dropdown.width() - extraPadding);
                } else {
                    $parent.css('left', offset.x);
                }

                $parent.css('opacity', 1);
            }, 100, false);
        }
    }
}

/**
 * @ngInject
 */
function exportBuilderRightClickSubMenuDirective(
    $timeout,
    ExportBuilderMenuService
) {
    return {
        restrict: 'A',
        link: function (scope, el) {
            $timeout(function () {
                var $$window = $(window);
                var windowHeight = $$window.height();

                var offset = el.parent().offset();

                if (offset.top + el.outerHeight() >= windowHeight) {
                    el.css('top', Math.min((windowHeight - el.outerHeight() - offset.top), 0))
                }
            }, 300, false)
        }
    }
}


/**
 * @ngInject
 */
function exportBuilderDevToolsMenu(
    UIFactory
) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            element: '<'
        },
        templateUrl: exportBuilderMenuDevToolsHtmlUrl,
        controller: ExportBuilderMenuDevTools,
        controllerAs: 'vm',
        link: function () {
            const elementClipboard = new Clipboard('.element-copy-btn');
            elementClipboard.on('success', _success);
            const widgetClipboard = new Clipboard('.widget-copy-btn');
            widgetClipboard.on('success', _success);

            function _success() {
                UIFactory.notify.showSuccess('Copied!');
            }
        }
    }
}

/**
 * @ngInject
 */
function ExportBuilderMenuDevTools(
    $rootScope,
    $scope,
    AppConfig
) {
    let vm = this;
    vm.element = $scope.element;

    vm.devWidget = DevTools.widgets[vm.element.widget.id];

    vm.makeDataApiCall = makeDataApiCall;
    vm.makeWidgetApiCall = makeWidgetApiCall;
    vm.showSqlModal = showSqlModal;
    vm.showApiTimeProfileModal = showApiTimeProfileModal;
    vm.showBugModal = showBugModal;

    /**
     *  Call data source API endpoint
     */
    function makeDataApiCall() {
        window.open(vm.devWidget.request, '_blank');
    }

    /**
     * Call design widget API endpoint
     */
    function makeWidgetApiCall() {
        window.open(AppConfig.API_URL + 'dash/widgets/' + vm.element.widget.id + '?all=1&extra=1', '_blank');
    }

    /**
     * Trigger SQL call modal
     */
    function showSqlModal() {
        $rootScope.$broadcast('devTools:showSqlModal', vm.devWidget.datasql);
    }

    /**
     * Trigger Api time profile modal
     */
    function showApiTimeProfileModal() {
        $rootScope.$broadcast('devTools:showApiTimeProfileModal', vm.devWidget.timeProfile);
    }

    /**
     * Trigger widget error modal
     */
    function showBugModal() {
        $rootScope.$broadcast('devTools:showBugModal', vm.element.widget.id, vm.devWidget.bug);
    }
}