import { percent, Tooltip } from '@amcharts/amcharts5';
import { useChart } from '@/modules/core/charts/am5/base/composables/useChart';
import { useScrollbar } from '@/modules/core/charts/am5/base/composables/useScrollbar';
import { XYChart, XYCursor } from '@amcharts/amcharts5/xy';
import { useBubbleAxes } from '@/modules/core/charts/am5/bubble/composables/useBubbleAxes';
import { useBubbles } from '@/modules/core/charts/am5/bubble/composables/useBubbles';
import { useLegend } from '@/modules/core/charts/am5/bubble/composables/useLegend';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';
import { useCustomZoomOutButton } from '@/modules/core/charts/am5/base/composables/useCustomZoomOutButton';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { invertColor } from '@/modules/core/charts/am5/charts.helper';

export function useBubbleChart(context) {
  const { root, chart, config } = context();

  const { configureChart } = useChart(context);
  const { createBubbleAxes } = useBubbleAxes(context);
  const { createScrollbar } = useScrollbar(context);
  const { createBubbles } = useBubbles(context);
  const { createLegend } = useLegend(context);
  const { formatLabel } = useLabels(context);
  const { changeZoomOutButton } = useCustomZoomOutButton(context);

  function initBubbleChart() {
    chart.value = root.value.container.children.push(
      XYChart.new(root.value, {
        panX: true,
        panY: true,
        wheelY: Constant.ZOOM_XY,
        pinchZoomX: true,
        pinchZoomY: true,
        layout: root.value.verticalLayout,
        height: percent(100),
        width: percent(100),
      })
    );

    configureChart();
    createBubbleAxes();
    createBubbles(Constant.VALUE);
    createTooltip();
    createCursor();
    changeZoomOutButton();

    if (config.value.hasValueScrollbar) {
      createScrollbar();
    }

    if (config.value.legend.active) {
      createLegend();
    }
  }

  function createCursor() {
    chart.value.set(
      Constant.CURSOR,
      XYCursor.new(root.value, {
        xAxis: chart.value.xAxes.getIndex(0),
        yAxis: chart.value.yAxes.getIndex(0),
        snapToSeries: chart.value.series.values,
      })
    );
  }

  function createTooltip() {
    chart.value.series.each((series) => {
      const singleSeries = series.get(Constant.USER_DATA);

      if (singleSeries.tooltipDisabled) {
        return;
      }

      const { xAxis: xAxisProps, yAxis: yAxisProps } = config.value;

      const tooltip = Tooltip.new(root.value, {
        pointerOrientation: Constant.HORIZONTAL,
      });

      tooltip.label.set(Constant.FILL, invertColor(series.get(Constant.FILL)));

      tooltip.adapters.add(Constant.LABEL_TEXT, (labelText, target) => {
        const dataItem = target.dataItem?.dataContext;
        if (!dataItem) {
          return '';
        }

        const xValue = dataItem[singleSeries.valueX];
        const yValue = dataItem[singleSeries.valueY];
        const bubbleValue = dataItem[singleSeries.value];

        return `${Constant.BOLD}${dataItem[singleSeries.category]}[/]
${formatLabel(xAxisProps.titleText, xValue, xAxisProps.dataItemFormat, dataItem)}
${formatLabel(yAxisProps.titleText, yValue, yAxisProps.dataItemFormat, dataItem)}
${formatLabel(singleSeries.name, bubbleValue, singleSeries.dataItemFormat, dataItem)}`;
      });

      series.set(Constant.TOOLTIP, tooltip);
    });
  }

  return {
    initBubbleChart,
  };
}
