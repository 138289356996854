'use strict';
import angular from 'angular';
import $ from 'jquery';
import initAngularTemplatedDirectives from '../../shared/scripts/app.services';

angular.module('connect.ctrls', [])
    .controller('ConnectController', ConnectController);

//
// Connect controller
//
ConnectController.$inject = ['$scope', '$templateCache', '$state', '$stateParams', 'MarketplaceService'];
function ConnectController($scope, $templateCache, $state, $stateParams, MarketplaceService) {
    $.core.main.templateCache = function(){
        $templateCache.removeAll();
        initAngularTemplatedDirectives($templateCache);
    };

    if ($state.current.name === 'service.manage.showconnection' && $stateParams.id) {
        $.core.connectservices.showServiceConnection($stateParams.id)
    }

    //There should be a cleaner way
    /*
    $('#manage-services').click(function () {
        $scope.hiddenServices = false;
    });
    */
}