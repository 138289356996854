'use strict';
import  angular from 'angular';
import $ from 'jquery';
import './page.library.constants';
import './page.library.services';
import './page.library.resources';
import './page.library.components';
import './tags/page.tag.services';
import './tags/page.tag.resources';
import managePageLibraryHtmlUrl from './managepagelibrary.html';
import managePageTagsHtmlUrl from './tags/managepagetags.html';

angular.module('page.library', [
    'page.library.constants',
    'page.library.services',
    'page.library.resources',
    'page.library.components',
    'page.tag.services',
    'page.tag.resources'
])
    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {

        $stateProvider.state('managepagelibrary', {
            parent:'layout',
            url: '/managepagelibrary',
            resolve: {
                isTARoute: SwitchAppServiceProvider.switchAppFn('managepagelibrary'),
                pageType: function(PageType) {
                    return PageType.TYPE_ADMIN;
                },
                pageEntity: function(PageEntity) {
                    return PageEntity.PAGE_MANAGE_PAGE_LIBRARY;
                },
                vars: function() {
                    return null;
                }
            },
            views: {
                '': {
                    templateUrl: managePageLibraryHtmlUrl,
                    controller: 'DesignController'
                }
            }
        })
            .state('managepagetags', {
                parent:'layout',
                url: '/managepagetags',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('managepagetags'),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_MANAGE_PAGE_TAGS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: managePageTagsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);