import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class BillingDetails extends BaseModel {
  /**
   * {object}
   */
  billing_details;

  constructor(model = {}) {
    super();

    this.billing_details = model.billing_details ?? {};
  }
}
