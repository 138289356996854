'use strict';
import angular from 'angular';
import $ from 'jquery';
import './leads.constants';
import './base/leads.ctrls';
import './base/leads.components';
import './base/leads.services';
import './base/leads.resources';
import './notes/leads.notes.models';

import dashleadsHtmlUrl from './base/dashleads.html';
import manageLeadsHtmlUrl from './base/manageleads.html';

angular.module('core.leads', [
    'core.leads.constants',
    'core.leads.ctrls',
    'core.leads.components',
    'core.leads.models',
    'core.leads.services',
    'core.leads.resources'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('leads', {
                parent:'layout',
                url: '/leads',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('leads'),
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_LEADS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: dashleadsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
            .state('manageleads', {
                parent:'layout',
                url: '/manageleads',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('manageleads'),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_MANAGE_LEADS;
                    },
                    vars: function(LeadsResource) {
                        return LeadsResource.vars.get();
                    }
                },
                views: {
                    '': {
                        templateUrl: manageLeadsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);