export const ExportType = {
  TYPE_EXPORT_PNG: 'export_png',
  TYPE_EXPORT_WIDGET_PNG: 'export_widget_png',
  TYPE_EXPORT_PDF: 'export_pdf',
  TYPE_EXPORT_PPT: 'export_ppt',
  TYPE_EXPORT_XLS: 'export_xls',
  TYPE_EXPORT_BUILDER_PDF: 'export_builder_pdf',
  TYPE_SCHEDULED_REPORT_PDF: 'scheduled_report_pdf',
};

export const PageOrientation = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};

const shortSide = 1224;
export const PageDimensions = {
  SHORT_SIDE: shortSide,
  LONG_SIDE: (11 / 8.5) * shortSide,
};
