import { isEmpty } from 'lodash';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class BaseEntityModel extends BaseModel {
  /**
   * @var {boolean}
   */
  can_be_accessed;

  /**
   * @var {boolean}
   */
  can_be_edited;

  /**
   * @var {boolean}
   */
  can_be_deleted;

  /**
   * @var {boolean}
   */
  can_be_copied;

  /**
   * @var {string}
   */
  can_be_deleted_tooltip;

  constructor(model = {}) {
    super();

    if (isEmpty(model)) {
      this.initializeDefaults(model);
    }

    this.can_be_accessed = model.can_be_accessed;
    this.can_be_edited = model.can_be_edited;
    this.can_be_deleted = model.can_be_deleted;
    this.can_be_copied = model.can_be_copied;
    this.can_be_deleted_tooltip = model.can_be_deleted_tooltip || null;
  }

  /**
   * This method is suppose to be overridden
   * This method is called when an entity is newly created.
   */
  // eslint-disable-next-line no-unused-vars
  initializeDefaults(model) {
    // Please override this method and initialize defaults
  }
}
