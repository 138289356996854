'use strict';
import angular from 'angular';
import Vue from "vue";
import "ngVue";
import "ngVue/build/plugins";
import { i18n } from "@/i18n";

import Buefy from "buefy";
import VueXHelper from "@/modules/core/app/helpers/VueXHelper";

import { createStore } from "@/store";

import "font-awesome/css/font-awesome.css";
import "grokModules/src/libs/tapcolors/tapcolors.css";
import "grokModules/src/assets/fonts/tapicons/scss/nucleo.scss";
import "grokModules/src/assets/fonts/serviceicons/css/style.css";
import "grokModules/src/assets/fonts/alphabet/css/style.css";
import "grokModules/src/styles/helpers.scss";
import "grokModules/node_modules/buefy/src/scss/components/_tooltip.scss";

import { StaticOptionPlugin } from "@/modules/core/app/plugins/StaticOptionPlugin";
import { LocalOptionPlugin } from "@/modules/core/app/plugins/LocalOptionPlugin";
import { VueI18nCustomPlugin } from "@/modules/core/app/plugins/VueI18nCustomPlugin";
import { commits } from "@/modules/core/app/helpers/store";

const store = createStore();
Vue.use(Buefy, {
    defaultIconPack: "fa"
});
Vue.use(StaticOptionPlugin);
Vue.use(LocalOptionPlugin);
Vue.use(VueI18nCustomPlugin);

import $ from "jquery";

import widgetChatGptHtmlUrl from './widget.chatgpt.html';
import chatGptWidget from "../../../../../../../grok/src/modules/ta/widget/components/types/ChatGptWidget.vue";

angular.module('widget.chatgpt.directives', ["ngVue", "ngVue.plugins"])
    .config($ngVueProvider => {
        $ngVueProvider.setRootVueInstanceProps({
            store: store,
            i18n: i18n
        });
    })
    .directive('chatGptWidget', chatGptWidgetFunction);


function chatGptWidgetFunction(createVueComponent, AppFactory) {
    _setUserSettings(AppFactory);
    return createVueComponent(Vue.component("chatGptWidget", chatGptWidget))
}

function _setUserSettings(AppFactory) {
    if(!AppFactory.getUser()?.user?.use_nui) {
        VueXHelper.setStore(store);
    }
    commits.session.setClientCurrency($.globals.utils.currencySymbol);
    commits.session.setUserSettings(AppFactory.getUser());
}