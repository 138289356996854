export class ExportDashboardState {
  /**
   * @var {set}
   */
  unrenderedWidgets;

  constructor() {
    this.unrenderedWidgets = new Set();
  }
}
