export class DateRangeDescription {
  /**
   * @type {string}
   */
  fromTitle;

  /**
   * @type {string}
   */
  fromRange;

  /**
   * @type {string}
   */
  toTitle;

  /**
   * @type {string}
   */
  toRange;

  constructor(model = {}) {
    this.fromTitle = model.fromTitle;
    this.fromRange = model.fromRange;
    this.toTitle = model.toTitle;
    this.toRange = model.toRange;
  }
}
