/* eslint-disable tap/no-raw-text-js */
export class Env {
  static env = process.env.NODE_ENV;

  static PRODUCTION = 'production';

  static STAGING = 'staging';

  static QA = 'QA';

  static DEVELOPMENT = 'development';

  /**
   * @returns {boolean}
   */
  static isDevelopment() {
    return this.env === this.DEVELOPMENT;
  }

  /**
   * @returns {boolean}
   */
  static isProduction() {
    return this.env === this.PRODUCTION;
  }

  /**
   * @returns {boolean}
   */
  static isStaging() {
    return this.env === this.STAGING;
  }

  /**
   * @returns {boolean}
   */
  static isQA() {
    return Env.isUnitTesting() ? false : window.location.hostname.indexOf('tapdemo.com') > -1;
  }

  /**
   * Flag to know if the process is running unit tests
   * @returns {boolean}
   */
  static isUnitTesting() {
    return process.env.JEST_WORKER_ID !== undefined;
  }

  /**
   * @param settings
   * @returns {string}
   */
  static getEnvironmentName(settings) {
    if (settings?.isStagingServer) {
      return Env.STAGING;
    }
    if (settings?.isDevelopmentServer || settings?.isLocalInstance) {
      return Env.DEVELOPMENT;
    }
    if (settings?.isProductionServer) {
      return Env.PRODUCTION;
    }
    return Env.QA;
  }
}
