import { memoize as _memoize, reduce } from 'lodash';

/**
 * @param arr
 * @param key
 * @returns {object}
 */
export function memoize(arr, key) {
  return _memoize(() =>
    reduce(
      arr,
      (accumulatedKeys, result, idx) => {
        result.index = idx;
        accumulatedKeys[result[key]] = result;
        return accumulatedKeys;
      },
      {}
    )
  )();
}
