import angular from 'angular';
import _ from 'lodash';

angular.module('exportbuilder.dashboard.util.services', [])
    .factory('ExportBuilderDashboardUtilService', ExportBuilderDashboardUtilService);

/**
 * @ngInject
 */
function ExportBuilderDashboardUtilService(
    $rootScope,
    PageGridConstants,
    $WidgetEvents
) {
    var _gridUnitPercent = parseFloat((100 / PageGridConstants.Y));

    return {
        getGridUnitPercent: getGridUnitPercent,
        getMaxHeight: getMaxHeight,
        getMaxWidth: getMaxWidth,

        snapItemsToGrid: snapItemsToGrid,
        snapItemToGrid: snapItemToGrid,

        reRenderWidgets: reRenderWidgets,
    };

    function getGridUnitPercent() {
        return _gridUnitPercent;
    }

    function getMaxHeight() {
        return PageGridConstants.Y;
    }

    function getMaxWidth() {
        return PageGridConstants.Y;
    }

    /**
     * Snap all items to grid
     * @param items
     * @private
     */
    function snapItemsToGrid(items) {
        _.each(items, snapItemToGrid);
    }

    /**
     * Calculates posistion and size for report grid
     * @param item
     * @private
     */
    function snapItemToGrid(item) {
        if (!item.snap_to_grid) {
            return;
        }

        item.x_position = calculatePosition(item.x_position, 'x_position');
        item.y_position = calculatePosition(item.y_position, 'y_position');
        item.width = calculatePosition(item.width, 'width');
        item.height = calculatePosition(item.height, 'height');

        function calculatePosition(dimension, property) {
            dimension = parseFloat(dimension);

            var dimensionUnit = dimension / _gridUnitPercent;
            var floorDimensionUnit = Math.floor(dimensionUnit);

            var diff = dimensionUnit - floorDimensionUnit;

            if (diff <= 0.5) {
                dimension = floorDimensionUnit * _gridUnitPercent;
            } else if (diff > 0.5) {
                dimension = Math.ceil(dimension / _gridUnitPercent) * _gridUnitPercent;
            }

            return dimension;
        }

    }

    function reRenderWidgets() {
        $rootScope.$broadcast($WidgetEvents.WIDGET_REBUILD);
    }
}
