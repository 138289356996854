'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.gaugechart.ctrls', [])

    .controller('GaugeChartWidgetController', GaugeChartWidgetController);

GaugeChartWidgetController.$inject = [
    '$scope',
    'DrawOption'
];
function GaugeChartWidgetController(
    $scope,
    DrawOption
) {
    $scope.$on('chart:setMetadataDefaults', function(e, metadata) {
        setMetadataDefaults(metadata);
    });

    function setMetadataDefaults(metadata) {
        var drawOptions = {};
        // Draw option for bands start and end....?
        // drawOptions[DrawOption.IS_SOLID_GAUGE] = false;
        drawOptions[DrawOption.HAS_TOOLTIP] = true;
        drawOptions[DrawOption.SHOW_LABELS] = true;
        drawOptions[DrawOption.GAUGE_SHOW_VALUE] = true;
        drawOptions[DrawOption.IS_HAND_DRAWN] = false;
        drawOptions[DrawOption.PLOT_TYPE] = 'classic';
        drawOptions[DrawOption.FILL_TYPE] = 'solid';
        drawOptions[DrawOption.GAUGE_HAND_TYPE] = 'none';
        drawOptions[DrawOption.GAUGE_THICKNESS] = 46;
        drawOptions[DrawOption.SHOW_TICKS] = false;
        drawOptions[DrawOption.HAND_COLOR] = '#C4C2C2';
        drawOptions[DrawOption.GAUGE_RANGE] = 180;
        drawOptions[DrawOption.GRAIN_DENSITY] = 0;
        drawOptions[DrawOption.GAUGE_GRADIENT_TARGET] = 'metric';
        drawOptions[DrawOption.SHOW_SHAPE_SHADOW] = false;
        drawOptions[DrawOption.GAUGE_BIG_NUMBER_POSITION] = 'none';
        drawOptions[DrawOption.GAUGE_HAND_CUSTOM_COLOR] = false;

        metadata.draw_options = _.assign(drawOptions, metadata.draw_options);
    }
}