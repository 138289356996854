'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

angular.module('executivesummary.models', [])
    .factory('ExecutiveSummaryModelFactory', ExecutiveSummaryModelFactory);


/**
 * @ngInject
 */
function ExecutiveSummaryModelFactory(
    AppModelFactory,
    MomentDateFormat,
    AppFactory,
    DesignFactory,
    DataSourceFactory,
    $timeout,
    gettextCatalog,
    LanguageService,
    ExportBuilderDashboardService
) {
    return {
        getExecutiveSummary: getExecutiveSummary,
        getExecutiveSummaryItem: getExecutiveSummaryItem,
        getDefaultSelectOptions: getDefaultSelectOptions,
        getSummaryDateOptions: getSummaryDateOptions
    };

    /**
     * @param model
     * @constructor
     */
    function ExecutiveSummary(model) {
        model = model || {};
        var self = this;
        var _display_items_size;

        self.id = _.isUndefined(model.id) ? null : model.id;
        if (DesignFactory.getCurrentLayout()) {
            self.layout_id = model?.layout_id ? model.layout_id : DesignFactory.getCurrentLayout().id;
            self.report_id = null;
            self.report_page_id = null;
        } else {
            self.layout_id = null;
            self.report_id = model?.report_id ? mode.report_id : ExportBuilderDashboardService.getBuilder().report.id;
            self.report_page_id = model?.report_page_id ? mode.report_page_id : ExportBuilderDashboardService.getBuilder().currentPage.id;
        }
        self.is_enabled = _.isUndefined(model.is_enabled) ? true : model.is_enabled;
        self.items = _.isEmpty(model.items) ? {} : model.items;
        _display_items_size = Object.keys(self.items).length;

        self.addSummaryItem =  function addSummaryItem(item) {
            var self = this;
            var summaryPosition = item.id;
            if (_.isNil(summaryPosition)) {
                summaryPosition = _display_items_size++;
                while (self.items[summaryPosition]) {
                    summaryPosition++;
                }
            }
            self.items[summaryPosition] = item;
        };

        self.removeSummaryItem = function removeSummaryItem(summaryId) {
            var self = this;
            _.remove(self.items, {id: summaryId});
            _display_items_size--;
        };
    }

    /**
     * Executive Items Constructor
     * @param model
     * @constructor
     */
    function ExecutiveItem(model) {
        model = _.isEmpty(model) ? {} : model;
        var self = this;

        self.id = _.isNil(model.id) ? null : model.id;
        self.summary_id = !_.isNil(model.summary_id) ? model.summary_id : null;
        self.is_new = _.isEmpty(model);
        self.content = _.isUndefined(model.content) ? '' : model.content ;
        self.entity = _.isUndefined(model.entity) ? {} : _.assign({}, model.entity) ;
        self.start_date = _.isUndefined(model.start_date) ? null : model.start_date;
        self.end_date = _.isUndefined(model.end_date) ? null : model.end_date;
        self.title = _.isUndefined(model.title) ? '' : model.title;
        self.is_active = _.isUndefined(model.is_active) ? true : model.is_active;
        if (self.is_new) {
            var dateRange = AppFactory.getDateRange();
            self.start_date = dateRange.start;
            self.end_date = AppFactory.convertUnixTimestampToLastSecondOfDayInUTC(dateRange.end);
        }
        self.getDateText = getDateText;
        self.hasEntity = hasEntity;
        self.hasDate = hasDate;
    }

    function getDateText() {
        var self = this;
        if (!moment(self.start_date).isValid() || !moment(self.end_date).isValid()) {
            return;
        }
        return formatSummaryDate(self.start_date, LanguageService.getDisplayDateFormat()) + ' - ' + formatSummaryDate(self.end_date, LanguageService.getDisplayDateFormat());
    };


    function hasEntity() {
        var self = this;
        return !_.isEmpty(self.entity);
    };

    function hasDate() {
        var self = this;
        return (!_.isNull(self.start_date) && moment(self.start_date).isValid()) && (!_.isNull(self.end_date) && moment(self.end_date).isValid());
    };


    /**
     * Format summary date for display
     * @returns {Array}
     */
    function formatSummaryDate(date, format) {
        return date ? moment.utc(moment.unix(date)).format(format) : undefined;
    }

    /**
     * Date Options constructor
     * @param dateOptions
     * @constructor
     */
    function SummaryDateOptions(dateOptions) {
        // Note: start and end date need to be set to undefined, so that the date options _.extend function can
        // replace the value with default dates. Null will crash the dateRangePicker
        AppModelFactory.getDateOptions.call(this, dateOptions);
        var self = AppModelFactory.getDateOptions(dateOptions);
        self.dateChangeCB = function(start, end) {
            if (dateOptions && dateOptions.summary) {
                $timeout(function() {
                    dateOptions.summary.start_date = moment(start).isValid() ? moment.utc(start.format(MomentDateFormat.MONTH_DAY_YEAR)).unix().toString() : null;
                    dateOptions.summary.end_date = moment(end).isValid() ? moment.utc(end.format(MomentDateFormat.MONTH_DAY_YEAR)).unix().toString() : null;
                }, 0);
            }
        };

        return self;
    }

    /**
     * Select2 Options Constructor
     * @param options
     * @param summary
     * @constructor
     */
    function DefaultSelectOptions(options, summary) {
        options = _.isEmpty(options) ? {} : options;
        options.listenToOtherSelect2 = true;
        options.placeholder = options && !_.isUndefined(options.label) ? gettextCatalog.getString('Assign to a {{entity}}...', {entity: options.label.toLowerCase()}) : '';
        options.allowClear = true;
        var self = AppModelFactory.getSelectOptions.call(this, options);

        self.summary = summary || null;
        self.onChange = function(key, $el) {
            var val = $el.select2('data');
            // Only reset values if change did not come from the source select2 emitting the vent
            if (this.options.key !== key) {
                $el.select2('data', null);
            } else {
                if (!_.isEmpty(val)) {
                    this.options.summary.entity.type = key;
                    this.options.summary.entity.id = val.id;
                    this.options.summary.entity.name = val.text;
                    this.options.summary.entity.label = this.options.label;
                } else {
                    this.options.summary.entity = {};
                }
            }
        };
        self.onReset = function(e, $el) {
            $el.select2('data', null);
        };
        return self;
    }
    /**
     * executiveSummary object will not be bound
     * @returns {ExecutiveSummary}
     */
    function getExecutiveSummary(executiveSummary) {
        return new ExecutiveSummary(executiveSummary);
    }

    /**
     * executiveSummary object will not be bound
     * @returns {ExecutiveSummary}
     */
    function getExecutiveSummaryItem(item) {
        return new ExecutiveItem(item);
    }

    /**
     * executiveSummary object will not be bound
     * @returns {ExecutiveSummary}
     */
    function getDefaultSelectOptions(options, summary) {
        return new DefaultSelectOptions(options, summary);
    }


    /**
     * Getter
     * @param dateOptions
     * @returns {SummaryDateOptions}
     */
    function getSummaryDateOptions(dateOptions) {
        return new SummaryDateOptions(dateOptions);
    }
}