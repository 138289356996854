import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { TabsState } from './tabs.state';
import { getters } from './tabs.getters';
import { mutations } from './tabs.mutations';
import { actions } from './tabs.actions';

VueXHelper.setStoreModule(Module.TABS, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new TabsState(),
  getters,
  mutations,
  actions,
};
