<template>
  <section class="container-gap">
    <HelpMessage v-if="hasWarning || !canShowChart" :help="help" />
    <GeoChart
      v-if="!hasWarning && canShowChart"
      :config="chartConfig"
      :help="help"
      width="100%"
      height="100%"
      @frame-ended="setIdle"
    />
    <GeoChart
      v-if="!hasWarning && canShowComparisonChart"
      :config="comparisonChartConfig"
      :help="help"
      width="100%"
      height="100%"
      is-comparison
      @frame-ended="setIdle"
    />
  </section>
</template>

<script>
import GeoChart from '@/modules/core/charts/am5/geo/components/GeoChart.vue';
import HelpMessage from '@/modules/core/charts/am5/base/components/HelpMessage.vue';
import { LegacyGeoChartWidgetRenderService } from '@/modules/core/charts/am5/geo/services/LegacyGeoChartWidgetRenderService';
import { isEmpty } from 'lodash';
import { dispatches } from '@/modules/core/app/helpers/store';
import { BaseChartWidgetPropsMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetPropsMixin';
import { WidgetConfig, WidgetEvent } from '@/modules/ta/widget/widget.constants';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { BaseChartWidgetMethodMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetMethodMixin';
import {
  COUNTRY,
  HEAT_V2,
  WORLD,
  GeoConfigurationType,
  BUBBLE_V2,
  LoadingState,
} from '@/modules/core/charts/am5/geo/geo.charts.constants';

export default {
  name: 'LegacyGeoChartWidget',
  mixins: [BaseChartWidgetPropsMixin, BaseChartWidgetMethodMixin],
  components: { GeoChart, HelpMessage },
  data() {
    return {
      comparisonChartConfig: null,
      help: null,
      hasWarning: false,
    };
  },
  computed: {
    canShowComparisonChart() {
      return this.hasComparisonData && this.comparisonChartConfig && !isEmpty(this.data);
    },
    getWidth() {
      return this.hasComparisonData ? '50%' : '100%';
    },
  },
  mounted() {
    EventBus.listen(`${WidgetEvent.RELOAD_WIDGET}-${this.widget.id}`, () => {
      this.init();
    });
    this.init();
  },
  watch: {
    /* eslint-disable tap/no-raw-text-js */
    'state.loadingState': {
      handler(newValue) {
        this.updateHelp(newValue);
      },
    },
  },
  methods: {
    /* eslint-disable tap/no-raw-text-js */
    async init() {
      if (this.updateHelp()) {
        return;
      }
      dispatches.dashboardExport.setUnrenderedWidgetIds([this.widget.id]);
      const widget = await this.setDrillDownOptions();
      const palette = widget.metadata.chart_palette ?? this.chartPalette;
      widget.cached_data = this.data;
      const renderer = new LegacyGeoChartWidgetRenderService(
        widget,
        palette,
        this.hasComparisonData
      );
      this.chartConfig = renderer.getChartConfig({ isComparison: false });
      this.chartConfig.set(WidgetConfig.WIDGET, widget);
      this.chartConfig.set(WidgetConfig.STATE, this.state);
      if (this.hasComparisonData) {
        this.comparisonChartConfig = renderer.getChartConfig({
          isComparison: this.hasComparisonData,
        });
        this.comparisonChartConfig.set(WidgetConfig.WIDGET, widget);
      }
    },
    async setIdle() {
      dispatches.dashboardExport.setWidgetAsRendered(this.widget.id);
    },
    updateHelp(newValue = '') {
      const groupByColumn = this.widget.metadata.data_columns.grouped[0] || {};
      if (
        this.widget.metadata.draw_options.plot_type === HEAT_V2 &&
        groupByColumn.field !== COUNTRY &&
        this.widget.metadata.geo_code === WORLD &&
        this.state.isBuilding
      ) {
        this.help = {
          message: 'For more accurate results, please do one of the following:',
          action: [
            'change the plot type under draw options',
            `select/click a country in order to group by ${groupByColumn.label}`,
          ],
        };
      } else {
        this.help = null;
      }
      if (newValue === LoadingState.DONE && Number(this.widget.metadata.data_source.id) > 1000000) {
        this.hasWarning = false;
        let type;
        if (groupByColumn) {
          const config = groupByColumn.geo_config || {};
          type = config.type;
        }
        if (this.widget.metadata.draw_options.plot_type === BUBBLE_V2) {
          const types = [
            GeoConfigurationType.COUNTRY,
            GeoConfigurationType.STATE,
            GeoConfigurationType.COUNTY,
          ];
          if (type && types.indexOf(type) !== -1) {
            this.state.loadingState = LoadingState.GEO_PLOT_CHANGE_HEAT_MAP;
            this.hasWarning = true;
            return true;
          }
        }
        if (this.widget.metadata.draw_options.plot_type === HEAT_V2) {
          if (type && type === GeoConfigurationType.PIN) {
            this.state.loadingState = LoadingState.GEO_PLOT_CHANGE_BUBBLE_MAP;
            this.hasWarning = true;
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>
<style scoped>
.container-gap {
  display: -webkit-box;
  display: block;
  width: 100%;
}
.warning-notification {
  position: absolute;
  width: rem(400px) !important;
  right: 0;
  z-index: 1;
}
</style>
