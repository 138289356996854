import { MapPointSeries, MapPolygonSeries } from '@amcharts/amcharts5/map';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import {
  AM5_COUNTRY_DRILLDOWN,
  CLICK,
  Countries,
  DRILLDOWN,
  FILL_OPACITY,
  GEO_LATITUDE,
  GEO_LONGITUDE,
  STATE,
  WORLD_MAP,
} from '@/modules/core/charts/am5/geo/geo.charts.constants';
import { Bullet, Circle } from '@amcharts/amcharts5';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { deepClone } from '@/modules/core/helper';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { useGeoMapBase } from '@/modules/core/charts/am5/geo/composables/useGeoMapBase';

export function useBubbleMaps(context) {
  const { root, chart, config } = context();
  const {
    hideAllSeries,
    loadIdMap,
    loadCountryAssociation,
    setActualData,
    findMaxValue,
    setZoomToCountry,
  } = useGeoMapBase(context);

  function init(name = WORLD_MAP, visible = true) {
    loadCountryAssociation();
    loadMap(name, visible, setBubbleSeries);
    setBubbleSeries();
  }

  function loadCountryMap(country, name) {
    loadCountryAssociation();
    const { countrySeries, worldMapSeries } = loadMap(name, false, setBubbleSeries);
    loadIdMap(country.toUpperCase()).then((response) => {
      countrySeries.setAll({
        geoJSON: response,
      });
      setBubbleSeries(country.toUpperCase());
      setZoomToCountry(
        country,
        worldMapSeries,
        worldMapSeries.getDataItemById(country.toUpperCase())
      );
    });
  }

  function loadMap(name, visible, callback) {
    const worldMapSeries = chart.value.series.push(
      MapPolygonSeries.new(root.value, {
        name,
        geoJSON: am5geodata_worldLow,
        visible,
        exclude: [Countries.AQ],
      })
    );

    const countrySeries = chart.value.series.push(
      MapPolygonSeries.new(root.value, {
        name: `${name} ${DRILLDOWN}`,
        visible: !visible,
      })
    );
    worldMapSeries.mapPolygons.template.events.on(CLICK, (ev) => {
      const { dataItem } = ev.target;
      const { dataContext } = dataItem;
      const zoomAnimation = setZoomToCountry(dataContext.id, worldMapSeries, dataItem);
      Promise.all([zoomAnimation.waitForStop(), loadIdMap(dataContext.id)]).then((results) => {
        hideAllSeries();
        countrySeries.setAll({
          geoJSON: results[1],
        });
        const state = config.value.get(STATE);
        if (state?.isBuilding) {
          EventBus.signal(AM5_COUNTRY_DRILLDOWN, {
            map_id: dataContext.id.toLowerCase(),
          });
        }
        countrySeries.show();
        if (callback) {
          callback(dataContext.id);
        }
      });
    });

    /* eslint-disable tap/no-raw-text-js */
    worldMapSeries.mapPolygons.template.setAll({
      tooltipText: '{name}',
    });

    return {
      countrySeries,
      worldMapSeries,
    };
  }
  function setBubbleSeries(mapId = null) {
    const dataToSet = setActualData();
    let countryData = deepClone(dataToSet);
    const isSmartConnector = Number(config.value.widgetData.metadata.data_source.id) >= 1000000;
    if (!isSmartConnector && mapId) {
      countryData = dataToSet.filter((row) => row.id === mapId);
    }
    config.value.series.forEach((props) => {
      createBubbleSeries(props, countryData);
    });
  }

  function createBubbleSeries(props, dataToSet) {
    const pointSeries = chart.value.series.push(
      MapPointSeries.new(root.value, {
        name: props.name,
        latitudeField: GEO_LATITUDE,
        longitudeField: GEO_LONGITUDE,
      })
    );
    pointSeries.bullets.push(() => {
      const circle = Circle.new(root.value, {
        radius: 5,
        fill: color(props.color),
        ...getBubbleToolTipTextProps(props),
      });
      circle.adapters.add(FILL_OPACITY, (opacity, target) => {
        const targetValue = target?.dataItem?.dataContext[props.value];
        const maxValue = findMaxValue(props.value);
        if (
          targetValue === undefined ||
          targetValue === null ||
          !maxValue ||
          targetValue >= maxValue / 4
        )
          return 1;
        if (targetValue >= maxValue / 6) {
          return 0.8;
        }
        if (targetValue >= maxValue / 8) {
          return 0.7;
        }
        return 0.6;
      });
      return Bullet.new(root.value, {
        sprite: circle,
      });
    });
    pointSeries.data.setAll(dataToSet);
  }

  /* eslint-disable tap/no-raw-text-js */
  function getBubbleToolTipTextProps({ category, name, value }) {
    return omitNils({
      tooltipText: `[bold]{${category}}[/]\n${name}: {${value}}`,
    });
  }

  return {
    init,
    loadCountryMap,
  };
}
