'use strict';
import angular from 'angular';

import connectionStatusHtmlUrl from './connectionstatus.html';

angular.module('core.connectionstatus.directives', [])

    .directive('connectionStatus', connectionStatus)

function connectionStatus() {
    return {
        restrict: 'E',
        templateUrl: connectionStatusHtmlUrl,
        scope: {
            state: '='
        },
        controller: 'ConnectionStatusController'
    }
}