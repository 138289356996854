<template>
  <section
    :class="{ 'is-comparing': hasComparison }"
    :style="[fontColorStyles, containerStyles, borderStyles, radiusStyles, radiantStyles]"
    data-cy="rectangle-container"
  >
    <div class="value" v-if="isLoadingValue" :style="[calculatedSize]">
      <p class="subtitle" data-cy="loader">
        <span>{{ $t('Loading...') }}</span>
      </p>
    </div>
    <div v-else class="big-number-container" :style="[calculatedSize]">
      <p
        class="card-label"
        :style="
          getFontSizeStyle(
            DataLabelMultiplierSquare.MIN,
            DataLabelMultiplierSquare.MAX,
            MaxFontSizes.METRIC_LABEL
          )
        "
        data-cy="label"
        :class="showMetricName"
      >
        {{ label }}
      </p>
      <div class="value-container">
        <div class="value">
          <span
            :style="[
              fontStyles,
              getFontSizeStyle(DataFontMultiplierSquare.MIN, DataFontMultiplierSquare.MAX),
            ]"
            data-cy="value"
            :title="emptyDataMessage"
          >
            <b-tooltip
              :active="!!isFormattedEllipsisActive()"
              :label="isFormattedEllipsisActive()"
              type="is-dark"
              size="is-small"
            >
              <div class="overflow-value" ref="formattedTooltip">
                {{ formattedValue }}
              </div>
            </b-tooltip>
          </span>
        </div>
        <div v-if="hasComparison && showComparisonArrows">
          <div
            :style="
              getFontSizeStyle(
                DataComaprisonMultiplierSquare.MIN,
                DataComaprisonMultiplierSquare.MAX
              )
            "
          >
            <span data-cy="delta-value">{{ formattedDeltaValue }}</span>
            <span v-if="!compareAsValue">%</span>
            <svg
              v-if="isExportingReportStudio"
              class="delta-value"
              :class="[deltaIconClass, deltaValueClasses]"
              :style="
                getIconSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                )
              "
            >
              <use xlink:href="" :href="`#${deltaIconClass}`"></use>
            </svg>
            <b-icon
              v-else
              pack="fa"
              class="delta-value"
              :class="deltaValueClasses"
              :custom-class="iconClass"
              :style="
                getFontSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                )
              "
              data-cy="delta-icon"
            ></b-icon>
          </div>
          <span
            class="subtitle"
            :style="[
              getFontSizeStyle(
                DataComaprisonFontMultiplierSquare.MIN,
                DataComaprisonFontMultiplierSquare.MAX
              ),
            ]"
            data-cy="delta-subtitle"
          >
            {{ $t('vs') }}
            <span>{{ formattedComparisonValue }}</span>
            {{ $t('prev.') }}
          </span>
        </div>
      </div>
      <div class="line-chart" :style="{ height: chartHeight }">
        <component :is="chartComponent" v-show="sparklineData.length" :config="getChartConfig()" />
      </div>
    </div>
  </section>
</template>

<script>
import { ScoreCardMixin } from '@/modules/core/scorecard/mixins/ScoreCardMixin';
import LineChart from '@/modules/core/charts/am5/line/components/LineChart.vue';
import { LineChartConfig } from '@/modules/core/charts/am5/line/models/LineChartConfig';
import BarChart from '@/modules/core/charts/am5/bar/components/BarChart.vue';
import { BarChartConfig } from '@/modules/core/charts/am5/bar/models/BarChartConfig';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { SeriesType, ChartType, ChartAdapters } from '@/modules/core/charts/chart.constants';

export default {
  name: 'SparkLineCard',
  mixins: [ScoreCardMixin],
  components: { BarChart, LineChart },
  props: {
    sparklineData: {
      type: [Array, Object],
      default: () => [],
    },
    field: {
      type: String,
      required: true,
    },
    categoryField: {
      type: String,
      default: 'log_date',
    },
    widgetId: {
      type: [Number, String],
      default: 0,
    },
    sparklineType: {
      type: String,
      default: SeriesType.LINE,
    },
  },
  computed: {
    calculatedSize() {
      const width = `${this.wrapSize * 3}px`;
      const height = `${this.wrapSize * 1.6}px`;
      return { width, height };
    },
    radiusStyles() {
      return { 'border-radius': `${this.cornerRadius}px` };
    },
    chartHeight() {
      return `${this.wrapSize / 3}px`;
    },
    // Dynamically compute the chart component based on sparklineType
    chartComponent() {
      return this.sparklineType === SeriesType.COLUMN ? ChartType.BAR_CHART : ChartType.LINE_CHART;
    },
  },
  methods: {
    getChartConfig() {
      const commonConfig = {
        series: [
          {
            fill: this.fontProperties.text_color,
            labelFill: this.containerStyles.background,
            fillOpacity: this.sparklineType === SeriesType.COLUMN ? 0.5 : 0.3,
            opacity: 1,
            strokeOpacity: 0.5,
            tension: 0.9,
            isCircularBullets: false,
            value: this.field,
            category: this.categoryField,
            tooltipText: '',
            name: this.label,
            color: this.fontProperties.text_color,
            dataItemFormat: ColumnFormat.FORMAT_INTEGER,
          },
        ],
        categoryAxis: [
          {
            category: this.categoryField,
            showLabels: false,
            hideGridLines: true,
            minGridDistance: 30,
            dataItemFormat: ColumnFormat.FORMAT_STRING,
          },
        ],
        valueAxis: [
          {
            axisType: ChartAdapters.VALUE,
            hideGridLines: true,
            showLabels: true,
            dataItemFormat: ColumnFormat.FORMAT_INTEGER,
          },
        ],
        legend: {
          active: false,
        },
        data: this.sparklineData,
        isSparkLine: true,
        hasTooltip: true,
      };

      if (this.sparklineType === SeriesType.COLUMN) {
        return new BarChartConfig({
          ...commonConfig,
          series: [{ ...commonConfig.series[0], seriesType: SeriesType.COLUMN }],
        });
      }
      return new LineChartConfig({
        ...commonConfig,
        series: [{ ...commonConfig.series[0], seriesType: SeriesType.LINE }],
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* Apply styles to the first <section> element */
section {
  position: relative;
  padding: 0 rem(15px);
  border-radius: $radius-small;
}

.value {
  min-width: 50%;
}

.big-number-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .value-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
  }

  .card-label {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .line-chart {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(10px);

    :deep(section) {
      width: 100%;
      padding: 0;
    }
  }
}

.delta-value {
  &.positive-delta {
    color: $green;
  }

  &.negative-delta {
    color: $red;
  }
}

.b-tooltip {
  display: block;
}

.overflow-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
