'use strict';
import angular from 'angular';

angular.module('ioreporting.lineitemswithtasksreport.services', ['core.iohelper'])
    .factory('LineItemsWithTasksReportFactory', LineItemsWithTasksReportFactory)
    .factory('LineItemsWithTasksReportDataGridFactory', LineItemsWithTasksReportDataGridFactory);

/**
 * @ngInject
 */
function LineItemsWithTasksReportFactory(
    Restangular,
    ColumnValueType
) {
    var report = Restangular.all('lineitemswithtasksreports')
    var vars = report.one('vars')
    var values = report.one('values')

    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        return report.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @dataSource unused
     * @queryParams any additional query params
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        return values.one(ColumnValueType.DISTINCT).get(
            _.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return report.getList(queryParams);
    }

    return {
        ecTasksSummary: report,
        vars: vars,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData
    }
}

/**
 * @ngInject
 */
function LineItemsWithTasksReportDataGridFactory(
    IODefaultLinkColumns,
    IODefaultLinkColumnsRenderer
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return service specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.customRenders[IODefaultLinkColumns.ORDER_ID] = IODefaultLinkColumnsRenderer.renderIoOrderIdLinkColumn
        dtOptions.customRenders[IODefaultLinkColumns.LINE_ITEM_ID] = IODefaultLinkColumnsRenderer.renderIoLineItemLinkColumn
        dtOptions.customRenders[IODefaultLinkColumns.TASK_ID] = IODefaultLinkColumnsRenderer.renderIoTaskLinkColumn;
        return dtOptions;
    }

}