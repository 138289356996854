import { GenericDataModel } from '@/modules/core/app/models/GenericDataModel';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { GenericModel } from '@/modules/core/app/models/GenericModel';

export class DataGridData extends BaseModel {
  /**
   * @type {string}
   */
  sEcho;

  /**
   * @type {number}
   */
  iTotalRecords;

  /**
   * @type {number}
   */
  iTotalDisplayRecords;

  /**
   * @type {GenericDataModel[]}
   */
  aaData;

  /**
   * @type {?GenericModel}
   */
  total_data;

  constructor(model) {
    super();

    let data = model;
    if (!('sEcho' in model)) {
      this.total_data = new GenericModel(model.total_data?.[0]);
      data = model.data;
    }

    this.sEcho = data.sEcho;
    this.iTotalRecords = data.iTotalRecords;
    this.iTotalDisplayRecords = data.iTotalDisplayRecords;
    this.aaData = data.aaData?.map((datum) => new GenericDataModel(datum)) || [];
  }

  /**
   * @override
   *
   * @param model
   * @returns {DataGridData}
   */
  static factory(model = {}) {
    return new this(model);
  }
}
