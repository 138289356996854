import { assign, cloneDeep } from 'lodash';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';

export class BaseFormService extends BaseService {
  /**
   * @var {BaseEntityResource}
   */
  resource;

  constructor(resource) {
    super();
    this.resource = resource;
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<*>}
   */
  async getForm(id, params = {}) {
    params = assign({ all: true }, params);
    return this.resource.getForm(id, params);
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<*>}
   */
  async getItem(id, params = {}) {
    params = assign({ all: true }, params);
    return this.resource.getItem(id, params);
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  async getItems(params = {}) {
    params = assign({ all: true }, params);
    return this.resource.getItems(params);
  }

  /**
   * Override this method to track form create
   * @param  {BaseForm} form
   */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-empty-function */
  async trackCreate(form) {}

  /**
   * Override this method to track form edit
   * @param  {BaseForm} form
   */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-empty-function */
  async trackEdit(form) {}

  /**
   * @param form {BaseForm}
   * @returns {Promise<*>}
   */
  async saveForm(form) {
    const primaryKeyValue = form.getPrimaryKeyValue();
    const response = await this.resource.saveItem(this._sanitizeFormData(form), primaryKeyValue);
    primaryKeyValue ? this.trackEdit(form) : this.trackCreate(form);
    return response;
  }

  /**
   * @param data
   * @param id
   * @param config
   * @returns {Promise<AxiosPromise<*>>}
   */
  async saveItem(data, id, config = {}) {
    return this.resource.saveItem(data, id, config);
  }

  /**
   * @param {Array} list
   * @returns {Promise<AxiosResponse<T>>}
   */
  async saveList(list) {
    return this.resource.saveList(list);
  }

  /**
   * @param form
   * @private
   */
  _sanitizeFormData(form) {
    // We want to remove these properties from the data object sent
    // to the backend but not from the frontend object
    form = cloneDeep(form);
    delete form.data.can_be_accessed;
    delete form.data.can_be_edited;
    delete form.data.can_be_deleted;
    delete form.data.can_be_copied;
    delete form.data.can_be_deleted_tooltip;
    return form.getFormData();
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  async getValues(params = {}) {
    return this.resource.getValues(params);
  }

  /**
   * @param {ApiColumn[]} columns
   * @param {Object} params
   * @returns {Promise<any[]>}
   */
  async getColumns(columns = [], params = {}) {
    return columns?.length
      ? columns
      : this.resource.getColumns(null, { summary: false, ...params });
  }

  /**
   * @returns {Promise<any[]>}
   */
  async getPrimaryColumn() {
    const params = { summary: false, is_primary_key: true };
    const columns = await this.resource.getColumns(null, params);
    return columns[0];
  }
}
