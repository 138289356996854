'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.leads.services')
    .factory('LeadsNotesFactory', LeadsNotesFactory);

/**
 * @ngInject
 */
function LeadsNotesFactory(
    $q,
    LeadsResource,
    LeadsNotesModelsFactory,
    ServiceFactory,
    MessengerFactory
) {
    var props = LeadsNotesModelsFactory.getProps();

    return {
        getLeadId: getLeadId,
        getIsActive: getIsActive,
        setInactive: setInactive,
        initLeadNotes: initLeadNotes,
        getPanelTitle: getPanelTitle,
        addLeadNote: addLeadNote,
        deleteLeadNote: deleteLeadNote
    };

    function getLeadId() {
        return props.data.lead.lead_id;
    }

    /**
     *
     * @returns {*|boolean}
     */
    function getIsActive() {
        return props.state.isActive;
    }

    /**
     * Set active state to inactive
     */
    function setInactive() {
        props.state.isActive = false;
    }

    /**
     * @param lead
     */
    function initLeadNotes(lead) {
        props.state.isActive = true;
        props.data.lead = lead;
        var deferred = $q.defer();

        // If lead details have not yet been created we need to add it before creating notes for it
        if (_.isEmpty(lead.id)) {
            LeadsResource.leads.all(null).post(lead).then(function(json) {
                props.data.lead.id = json.id;
                deferred.resolve([]);
            });
        } else {
            _getLeadNotes(lead.id).then(function(json) {
                var messageThreads = _.map(json.plain(), function(note) {
                    note.userImageUrl = note.user_image_metadata && note.user_image_metadata.secure_url;
                    note.permissions = {can_be_edited: note.can_be_edited, can_be_deleted: note.can_be_deleted};
                    return LeadsNotesModelsFactory.getLeadNote(note);
                });
                deferred.resolve(messageThreads);
            });
        }

        MessengerFactory.setThreads(deferred.promise);
    }

    /**
     * Return panel title based on lead data
     * @returns {string}
     */
    function getPanelTitle() {
        var title = 'Notes for ';
        var titleClass = '';
        var lead = props.data.lead;
        if (lead.first_name || lead.last_name) {
            title += lead.first_name || '' + ' ' + lead.last_name || '';
        } else {
            titleClass = 'ml35';
            title += ServiceFactory.getServiceNameDisplay(lead.service_id, 24) + ' Lead';
        }
        return '<span class="title-inner '+titleClass+'">' + title + '</span>';
    }

    /**
     * @param content
     * @returns {angular.IPromise<any>}
     */
    function addLeadNote(thread) {
        var model = LeadsNotesModelsFactory.getLeadNote({
            id: thread.id,
            lead_id: props.data.lead.id,
            content: thread.content,
            is_active: false,
        });

        return _addLeadNoteResource(model).then(function(json) {
            json.userImageUrl = json.user_image_metadata && json.user_image_metadata.secure_url;
            json.permissions = {can_be_edited: json.can_be_edited, can_be_deleted: json.can_be_deleted};

            var newThread = LeadsNotesModelsFactory.getLeadNote(json);
            MessengerFactory.addThread(newThread);
        });
    }

    /**
     * @param id
     * @returns {angular.IPromise<any>}
     */
    function deleteLeadNote(id) {
        return _deleteLeadNoteResource(id, props.data.lead.id).then(function() {
            MessengerFactory.removeThread(id);
        });
    }

    /**
     * @param model
     * @returns {angular.IHttpPromise<any>}
     * @private
     */
    function _addLeadNoteResource(model) {
        return LeadsResource.leads.all(model.lead_id).all('notes').post(model);
    }

    /**
     * @param id
     * @param leadId
     * @returns {angular.IHttpPromise<any>}
     * @private
     */
    function _deleteLeadNoteResource(id, leadId) {
        return LeadsResource.leads.all(leadId).all('notes').all(id).remove();
    }

    /**
     * @param leadId
     * @returns {angular.IHttpPromise<any>}
     * @private
     */
    function _getLeadNotes(leadId) {
        return LeadsResource.leads.all(leadId).all('notes').getList({
            all: true,
            is_active: true,
            sort: 'created_at'
        });
    }
}