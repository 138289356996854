'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

angular.module('core.export.services')

    .service('WidgetExportFactory', WidgetExportFactory);

/**
 * @ngInject
 */
function WidgetExportFactory(
    $q,
    LoadingState,
    WidgetType,
    DrawOption,
    AppFactory,
    WidgetFactory,
    DataGridFactory,
    ColumnFormat,
    DashboardContextService,
    Restangular
) {
    var util = AppFactory.util;

    /**
     * File name of exprted file
     * @type {string}
     */
    var fileName = '';

    /**
     * Columns to use in exported data set
     * @type {Array}
     */
    var allColumns = [];

    /**
     * Fields to use in the exported data set. i.e. keys to use to reduce the payload
     * @type {Array}
     */
    var fieldsToReturn = [];

    /**
     * Column metadata is used to infer field formats, etc...
     * @type {Array}
     */
    var columnMetadata = {};

    /**
     * Are we dealing with a data grid widget?
     * @type {boolean}
     */
    var isDataGrid = false;

    /**
     * Whether or not we have a date comparison
     * @type {boolean}
     */
    var hasComparison = false;

    /**
     * Date format used for date fields (based on time grouping)
     * @type {null}
     */
    var dateFormat = null;

    /**
     * Metadata is used to handle percent_metric columns if any in datagrid
     * @type {{}}
     */
    var metadata = {};

    var exports = Restangular.all('export');

    /**
     * Setup private vars
     * @param widget
     */
    function init(widget) {
        metadata = widget.metadata;
        hasComparison = AppFactory.getComparisonDateRange().enabled || metadata.compare_to_prior_period;
        isDataGrid = widget.type === WidgetType.DATAGRID;

        var selected = metadata.data_columns.selected || [];
        var grouped = metadata.data_columns.grouped || [];
        allColumns = isDataGrid ? selected : _.concat(grouped, selected);

        fieldsToReturn = _.map(allColumns, 'groupby_name_field');
        columnMetadata = AppFactory.arrayToMemoizedObj(allColumns, 'groupby_name_field');

        dateFormat = WidgetFactory.getDateFormats(metadata.time_grouping).momentFormat;
        fileName = widget.title || "My widget's data";
    }

    /**
     * Resolves the promise or raw data and returns normalized json payload
     * @param widget
     * @param state
     * @param exportType
     * @param callback
     * @returns {Array}
     */
    function resolvePayload(widget, state, exportType, callback) {
        var initialState = state.loadingState;

        state.loadingState = LoadingState.LOADING;
        var metadata = widget.metadata;
        var requiresXhr = isDataGrid && metadata.draw_options[DrawOption.GRID_PAGINATE]
            && !metadata.draw_options[DrawOption.PIVOT_GRID];
        var promise = requiresXhr
            ? $q.resolve(DataGridFactory.getAllData(widget))
            : $q.resolve(metadata.dynamic.raw_data);

        return promise.then(function(json) {
            if (isDataGrid && !DashboardContextService.isDemoModeEnabled()) {
                callback(
                  exportType,
                  {
                    data: json.data[0].aaData,
                    has_comparison_data: json.has_comparison_data
                  },
                  widget
                );
            }
            else {
                callback(exportType, json, widget);
            }
            state.loadingState = _.isUndefined(initialState) ? LoadingState.DONE : initialState;
        });
    }

    /**
     * Since the data contains more data than we need, we trim out
     * the unwanted parts reducing it to only the values of the fields required
     * @param data
     * @param hasComparisonData
     * @param isPriorPeriod
     * @param widget
     * @returns {Array}
     */
    function reduceData(data, hasComparisonData, isPriorPeriod, widget) {
        var containsCurrentOrPriorPeriod = false;
        if (data.length > 0 && !_.isUndefined(data[0].prior_period) && !_.isUndefined(data[0].prior_period)) {
            containsCurrentOrPriorPeriod = true;
        }
        return _.map(data, function(datum) {
            var item = {};
            _.each(fieldsToReturn, function(field) {
                var column = columnMetadata[field];
                var value;

                // If we receive data in different format {current_period: datum, prior_period: datum},
                // a data massage is needed
                if (containsCurrentOrPriorPeriod) {
                    value = extractValue(datum, field, hasComparisonData, isPriorPeriod);
                }
                else {
                    // Handle speical case for TA-12270
                    if (field === 'TAP_SERVICE_NAME_TAP') {
                        value = _.isObject(datum['TAP_SERVICE_NAME_NAME_TAP'])
                            ? datum['TAP_SERVICE_NAME_NAME_TAP'].value
                            : datum['TAP_SERVICE_NAME_NAME_TAP'];
                    }
                    else {
                        value = _.isObject(datum[field]) ? datum[field].value : datum[field];
                    }
                }

                value = _.isNull(value) || _.isNaN(value) ? 0 : value; // Default to 0 if null or NaN
                if (util.column.isDate(column.format)) {
                    if (datum["formatted_" + field]) {
                      value = column.is_primary_date_field
                        ? moment(datum["formatted_" + field]).format(dateFormat)
                        : datum["formatted_" + field];
                    } else {
                      value = formatDate(value);
                    }
                }
                else if (util.column.isTime(column.format)) {
                    value = _.isInteger(value)
                        ? moment.unix(value).utc().format(dateFormat)
                        : value;
                }
                else if (util.column.isNumeric(column.format)) {
                    if(isDataGrid && metadata.draw_options[DrawOption.PERCENT_OF_TOTAL] && column.format === ColumnFormat.FORMAT_INTEGER && metadata.dynamic.raw_data.total_data) {
                        const columnTotalValue = DataGridFactory.getColumnTotalValue(metadata.dynamic.raw_data.total_data, column.field, widget.has_live_integration, isPriorPeriod);
                        value = $.fn.formatNumber(value, column.format, column.precision) + ' - ' + DataGridFactory.getPercentOfTotal(value, columnTotalValue);
                    } else {
                        if(widget.type === WidgetType.BIGNUMBER && widget.metadata.currency_discrepancy) {
                            widget.metadata.show_currency = false;
                        }
                        value = $.fn.formatNumber(value, column.format, column.precision, false, datum, widget.metadata.currency_discrepancy, widget.metadata.show_currency);
                    }
                }
                item[column.label] = value;
            });
            return item;
        });
    }

    /**
     * Returns formatted date based on time grouping date format
     * @param date
     * @returns {*}
     */
    function formatDate(date) {
        return moment.unix(date).utc().format(dateFormat);
    }

    /**
     * Generate a download link from the file contents
     * @param fileExt
     * @param fileContent
     */
    function generateLink(fileExt, fileContent) {
        var encodedUri = encodeURI(fileContent);

        // Manually encode '#' sign
        // to prevent Firefox from dropping the following statements
        // For reference: https://bugzilla.mozilla.org/show_bug.cgi?id=826562
        encodedUri = encodedUri.replace('#', '%23');

        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName + '.' + fileExt);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.json".

        // Clean the link from the DOM
        link.remove();
    }

    /**
     * Export widget data to JSON
     * @param type
     * @param payload
     * @param widget
     */
    function exportData(type, payload, widget) {
        var json = {};
        var output = 'data:text/' + type + ';charset=utf-8,';

        var hasComparisonData = payload.has_comparison_data;
        if (hasComparisonData) {
            json.data = reduceData(payload.data, hasComparisonData, false, widget);
            json.comparison_data = reduceData(payload.data, hasComparisonData, true, widget);
        } else {
            json.data = reduceData(payload.data, hasComparisonData, false, widget);
        }

        if (type === 'json') {
            output += JSON.stringify(json, null, 2);
        }

        generateLink(type, output);
    }

    /**
     * Helper function to extract value based off different data format
     * @param datum
     * @param field
     * @param hasComparisonData
     * @param isPriorPeriod
     * @returns {*}
     */
    function extractValue(datum, field, hasComparisonData, isPriorPeriod) {
        var value;
        if (hasComparisonData) {
            if (isPriorPeriod) {
                value = datum.prior_period[field];
            }
            else {
                value = datum.current_period[field];
            }
        }
        else {
            value = datum[field];
        }
        return value;
    }

    /**
     * Export widget data to JSON
     * @param widget
     * @param state
     */
    function exportDataToJson(widget, state) {
        widget.title = widget.title.replace(/<[^>]*>/g, '');
        init(widget);
        exports.all('json/logfileexport').post({widget_id: widget.id});
        return resolvePayload(widget, state, 'json', exportData);
    }

    return {
        exportDataToJson: exportDataToJson
    }
}
