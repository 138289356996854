'use strict';
import angular from 'angular';

angular.module('core.abtest.ctrls', [])

    .controller('AbTestController', AbTestController);

AbTestController.$inject = [
    '$scope',
    '$timeout',
];
function AbTestController(
    $scope,
    $timeout
) {

}