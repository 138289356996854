import { startsWith, find } from 'lodash';
import NavResource from '@/modules/core/nav/resources/NavResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { UPDATE_NAV_ITEM } from '@/modules/core/nav/nav.constants';
import EventBus from '@/modules/core/app/helpers/EventBus';
import OnboardingService from '@/modules/core/onboarding/services/OnboardingService';

class NavService {
  /**
   * @returns {Promise<array>}
   */
  async getAppNavItems() {
    return NavResource.getAppNavItems();
  }

  /**
   * @param {AppNavItem[]} navs
   * @param routePath
   * @returns {*}
   */
  resolveActiveAppNavItem(navs, routePath) {
    const activeNav = find(navs, (nav) => this.resolveActiveNavItem(nav.items, routePath));
    return activeNav && activeNav.key;
  }

  /**
   *
   * @param items
   * @param routePath
   */
  resolveActiveNavItem(items, routePath) {
    return find(items, (item) => this.isActiveNavItem(item, routePath));
  }

  /**
   *
   * @param item
   * @param routePath
   * @returns {boolean}
   */
  isActiveNavItem(item, routePath) {
    if (!item.link) {
      return false;
    }

    if (routePath.startsWith('/fav/')) {
      return `/${item.link}` === routePath;
    }

    const isResearchRoute = routePath.startsWith(RouteGroup.RESEARCH);

    if (isResearchRoute) {
      return startsWith(routePath, `/${item.link}`);
    }

    const isDrilldownRoute =
      routePath.includes(RouteGroup.SERVICES_DRILLDOWN) ||
      routePath.includes(RouteGroup.CATEGORIES_DRILLDOWN);

    const isDashRoute = routePath.includes(RouteGroup.DASH);

    const isTemplateRoute = routePath.endsWith('/templates');

    const isManageServiceRoute =
      routePath.startsWith(`/${RouteGroup.SERVICE_MANAGE}`) ||
      routePath.startsWith(`/${RouteGroup.SERVICE_MAPPINGS}`);

    if (isTemplateRoute) {
      return routePath === `/${item.link}`;
    }
    if (isDrilldownRoute) {
      return item.link === RouteGroup.DRILLDOWNS;
    }
    if (isDashRoute) {
      return item.link === RouteGroup.DASHBOARDS;
    }
    if (isManageServiceRoute) {
      return item.link === RouteGroup.SERVICE_MANAGE;
    }

    return routePath === `/${item.link}` || startsWith(routePath, `/${item.link}/`);
  }

  /**
   * @param item
   * @returns {boolean}
   */
  isNavItemDisabled(item) {
    return !!OnboardingService.isOnboardingNavItemDisabled(item);
  }

  /**
   * @param item
   * @returns {string}
   */
  getNavItemIcon(item) {
    let { icon } = item;
    if (item.isOnboradingItem()) {
      icon = OnboardingService.getOnboardingStepIcon(item);
    }

    return `ti-${icon}`;
  }

  /**
   * @param item
   * @returns {string|null}
   */
  getIconClass(item) {
    if (OnboardingService.hasOnboardingStepCompleted(item)) {
      return 'is-primary';
    }

    return null;
  }

  /**
   * update the nav items.
   */
  updateNavItems() {
    EventBus.signal(UPDATE_NAV_ITEM);
  }
}

export default new NavService();
