import angular from 'angular';
import $ from 'jquery';

angular.module('exportbuilder.dashboard.services')
    .factory('ExportBuilderDashboardExportOptionsUIService', ExportBuilderDashboardExportOptionsUIService)
    .service('ExportBuilderExportModalAnimatorUIService', ExportBuilderExportModalAnimatorUIService);

/**
 * @ngInject
 */
function ExportBuilderDashboardExportOptionsUIService() {
    var uiState = {
        isActive: false
    };

    return {
        getState: getState,
        setIsActive: setIsActive,
        getIsActive: getIsActive
    };

    function getState() {
        return uiState
    }

    function setIsActive(value) {
        uiState.isActive = value;
    }

    function getIsActive() {
        return uiState.isActive
    }
}

/**
 * @ngInject
 */
function ExportBuilderExportModalAnimatorUIService(
    $timeout,
    UIFactory
) {
    return {
        animateDownloadClicked: animateDownloadClicked
    };

    /**
     * Method to handle custom animation when cliking on download in report studio dashboard
     * @param fileType supported file types in the modal [pdf, ppt]
     * @param callback
     */
    function animateDownloadClicked(fileType, callback) {
        var $clickedButton = $('#report-studio-export-options-modal .' + fileType + '-animator');
        var $cloneElement = $clickedButton.clone();
        var $downloadAnimatorIcon = $('.download-animator');

        var initialPosition = $clickedButton.offset();

        $cloneElement.css('top', initialPosition.top);
        $cloneElement.css('left', initialPosition.left);

        $cloneElement.addClass('is-animating');

        $cloneElement.appendTo(document.body);

        var finalPosition = $downloadAnimatorIcon.offset();

        $cloneElement.css('top', finalPosition.top - 10);
        $cloneElement.css('left', finalPosition.left);

        $('#report-studio-export-options-modal').fadeOut(300);
        $('.modal-backdrop').animate({
            opacity: 0
        }, 300);
        $downloadAnimatorIcon.css('opacity', 0);

        $timeout(function () {
            $cloneElement.css('transform', 'scale(0.5)');
            $cloneElement.css('opacity', 0.8);
            $cloneElement.addClass('final-position');
            $timeout(function () {
                UIFactory.hideModal('report-studio-export-options-modal');
                callback(function () {
                    $cloneElement.remove();
                    $downloadAnimatorIcon.css('opacity', 1);
                });
            }, 1000);
        }, 800, false);
    }
}