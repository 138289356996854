'use strict';
import angular from 'angular';
import './base/widget.probe.ctrls';
import './base/widget.probe.directives';
import './base/widget.probe.services';

angular.module('widget.probe', [
    'widget.probe.ctrls',
    'widget.probe.directives',
    'widget.probe.services',
]);