import { isEmpty } from 'lodash';
import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';
import { ExecutiveSummaryEntity } from '@/modules/ta/executivesummaries/models/ExecutiveSummaryEntity';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';

export class ExecutiveSummaryItem extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  summary_id;

  /**
   * @var {string}
   */
  page_id;

  /**
   * @var {boolean}
   */
  is_enabled;

  /**
   * @var {string}
   */
  page_title;

  /**
   * @var {string}
   */
  title;

  /**
   * @var {string}
   */
  start_date;

  /**
   * @var {string}
   */
  end_date;

  /**
   * @var {ExecutiveSummaryEntity}
   */
  entity;

  /**
   * @var {string}
   */
  content;

  /**
   * @var {string}
   */
  formatted_start_date;

  /**
   * @var {string}
   */
  formatted_end_date;

  /**
   * @var {boolean}
   */
  is_active;

  /**
   * @var {boolean}
   */
  is_new;

  /**
   * @var {string}
   */
  layout_title;

  /**
   * @var {string}
   */
  report_id;

  /**
   * @var {string}
   */
  report_page_id;

  /**
   * @var {string}
   */
  report_title;

  /**
   * @var {string}
   */
  report_page_title;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.summary_id = model.summary_id;
    this.page_id = model.page_id;
    this.is_enabled = model.is_enabled;
    this.page_title = model.page_title;
    this.title = model.title;
    this.start_date = model.start_date;
    this.end_date = model.end_date;
    this.entity = !isEmpty(model.entity) ? new ExecutiveSummaryEntity(model.entity) : null;
    this.content = model.content;
    this.formatted_start_date = DateUtil.formatDateFromUnixTimeStamp(model.start_date);
    this.formatted_end_date = DateUtil.formatDateFromUnixTimeStamp(model.end_date);
    this.is_active = model.is_active;
    this.is_new = model.is_new;
    this.layout_title = model.layout_title;
    this.report_id = model.report_id;
    this.report_page_id = model.report_page_id;
    this.report_title = model.report_title;
    this.report_page_title = model.report_page_title;
  }
}
