import { BulletShapes } from '@/modules/core/charts/am5/charts.constants';
import { Circle, percent, Star, Triangle } from '@amcharts/amcharts5';
import { color } from '@/modules/core/charts/am5/charts.helper';

export function useBulletShape(context) {
  const { root } = context();

  function createBulletShape(series, bullet) {
    const { fill, fillOpacity, shape, rotation } = bullet;

    const commonOptions = {
      fill: color(fill),
      fillOpacity,
      rotation,
    };

    switch (shape) {
      case BulletShapes.STAR:
      case BulletShapes.DIAMOND:
        return Star.new(root.value, {
          ...commonOptions,
          radius: 5,
          innerRadius: shape === BulletShapes.STAR ? percent(55) : percent(70),
          spikes: shape === BulletShapes.STAR ? 5 : 4,
        });
      case BulletShapes.TRIANGLE_DOWN:
      case BulletShapes.TRIANGLE_LEFT:
      case BulletShapes.TRIANGLE_RIGHT:
      case BulletShapes.TRIANGLE:
        return Triangle.new(root.value, {
          ...commonOptions,
          height: 10,
          width: 10,
          rotation: getTriangleRotation(shape),
        });
      case BulletShapes.SQUARE:
        return Star.new(root.value, {
          ...commonOptions,
          height: 10,
          width: 10,
          spikes: 4,
          rotation: 45,
          radius: 5,
          innerRadius: percent(70),
        });
      default:
        return Circle.new(root.value, {
          ...commonOptions,
          radius: 4,
        });
    }
  }

  function getTriangleRotation(shape) {
    switch (shape) {
      case BulletShapes.TRIANGLE_DOWN:
        return 180;
      case BulletShapes.TRIANGLE_LEFT:
        return 270;
      case BulletShapes.TRIANGLE_RIGHT:
        return 90;
      default:
        return 0;
    }
  }

  return {
    createBulletShape,
  };
}
