import angular from 'angular';

angular.module('exportbuilder.sync.services', [])
    .factory('ExportBuilderDashboardSyncService', ExportBuilderDashboardSyncService);

/**
 * Background sync to verify if any pending elements needs to be pushed to the backend
 * @ngInject
 */
function ExportBuilderDashboardSyncService(
    ReportStudioTemplateDataService,
    ExportBuilderResource,
    WidgetUtilService,
    $ExportBuilderDashboardEvents,
    PubSub
) {
    var MILLISECONDS_THRESHOLD = 1000;
    var state = {
        timer: null,
        pendingElements: {}
    };

    return {
        start: start,
        stop: stop,
        setElement: setElement,
        setReport: setReport,
        trigger: trigger,
        getTimer: getTimer,
        hasPendingElements: hasPendingElements
    };

    /**
     * Start background timer
     */
    function start() {
        state.timer = setTimeout(function () {
            if (!state.timer) {return}
            try {
                _saveElementsIfNeeded();
                _saveReportIfNeeded();
            } catch (e) {
                console.error(e);
            }
            start();
        }, 500)
    }

    /**
     * Stop background timer
     */
    function stop() {
        clearTimeout(state.timer);
        state.timer = null;
        state.pendingElements = {};
    }

    /**
     * Immediately checks pending items to send
     */
    function trigger() {
        clearTimeout(state.timer);
        state.timer = null;
        _saveElementsIfNeeded();
        start();
    }

    /**
     * Getter
     * @returns {null}
     */
    function getTimer() {
        return state.timer;
    }

    /**
     * Getter
     * @returns {*}
     */
    function hasPendingElements() {
        return !_.isEmpty(state.pendingElements);
    }

    /**
     * Add an element to pending state
     * @param element
     */
    function setElement(element) {
        state.pendingElements[element.id] = {
            id: element.id,
            time: new Date().getTime()
        }
    }

    /**
     * Set the report to pending state
     * @param report
     */
    function setReport(report) {
        state.pendingReport = {
            id: report.id,
            time: new Date().getTime()
        }
    }

    /**
     * Verify an pending elements to be saved to the backend
     * @private
     */
    function _saveElementsIfNeeded() {
        if (_.isEmpty(state.pendingElements)) {return}

        var currentTime = new Date().getTime();
        var elementIds = _.reduce(state.pendingElements, function (accum, data) {
            if (currentTime - data.time >= MILLISECONDS_THRESHOLD) {
                accum.push(data.id);
            }
            return accum;
        }, []);

        _.each(elementIds, function (id) {
            delete state.pendingElements[id];
        });

        if (elementIds.length) {

            const elementsToUpdate = ReportStudioTemplateDataService.getElementsByIds(elementIds);
            const elementsToSend = angular.copy(elementsToUpdate);

            _.each(elementsToSend, element => {
                if (element.isTypeWidget()) {
                    element.widget = WidgetUtilService.trimWidgetMetadata(element.widget);
                }
            });

            ExportBuilderResource.batchUpdateElements(
                ReportStudioTemplateDataService.getReport(),
                elementsToSend
            ).then(() => {
                elementsToSend && elementsToSend.forEach(element => {
                    if (element.isTypeWidget() && element.widget.library_widget_clicked) {
                        PubSub.emit($ExportBuilderDashboardEvents.UPDATE_WIDGET_BUILDER_ACTIONS, false);
                        ExportBuilderResource.getWidgetFilterSetId(element.widget.id).then((data) => {
                            ReportStudioTemplateDataService.updateElementFilterId(element.id, data.id);
                            PubSub.emit($ExportBuilderDashboardEvents.UPDATE_WIDGET_BUILDER_ACTIONS, true);
                        });
                    }
                    if (element.emit_event) {
                        PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + element.id, element);
                    }
                })
            });

            _.each(elementsToUpdate, element => {
                if (element.isTypeWidget()) {
                    delete element.widget.library_widget_clicked;
                }
                delete element.widget_needs_update
                delete element.emit_event;
            })
        }
    }
    
    /**
     * Checks if the report needs to be saved
     * @private
     */
    function _saveReportIfNeeded() {
        if (_.isEmpty(state.pendingReport)) {return}
    
        // validate threshold
        const currentTime = new Date().getTime();
        if (currentTime - state.pendingReport.time < MILLISECONDS_THRESHOLD) {
            return;
        }
        
        delete state.pendingReport;
        
        const report = ReportStudioTemplateDataService.getReport();
        report.number_of_pages = report.pages.length;
        
        return ExportBuilderResource.save(report);
    }
}
