import angular from 'angular';
import _ from 'lodash';

import manageProductsDetailsHtmlUrl from './manageproducts.details.html';

class ManageProductsDetailsController {

    /**
     * @ngInject
     */
    constructor(
        DetailsModalFactory,
        ManageProductsResource,
    ) {
        this.DetailsModalFactory = DetailsModalFactory;
        this.ManageProductsResource = ManageProductsResource;
        this.vm = this;

        this.vm.$onInit = this.$onInit;
        this.vm.getColumn = this.getColumn;
    }

    $onInit() {
        this.vm.model = this.DetailsModalFactory.getModel();
        this.vm.metadata = this.DetailsModalFactory.getMetadata();
    }

    /**
     * @param field
     * @returns {any}
     */
    getColumn(field) {
        return _.find(this.vm.metadata, {field: field});
    }
}

angular.module('core.manageproducts.components')
    .component('manageProductsDetails', {
        templateUrl: manageProductsDetailsHtmlUrl,
        controllerAs: 'vm',
        controller: ManageProductsDetailsController
    });