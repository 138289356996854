import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';
import {
  RolePermissionValue,
  RoleReportingProfilePermission,
} from '@/modules/ta/role/role.constants';

export class Role extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  name;

  /**
   * @var {string}
   */
  predefined_role_key;

  /**
   * @var {string}
   */
  predefined_role_key_display;

  /**
   * @var {string}
   */
  role_type;

  /**
   * @var {string}
   */
  role_type_display;

  /**
   * @var {string}
   */
  role_association;

  /**
   * @var {string}
   */
  role_association_display;

  /**
   * @var {?string}
   */
  cluster_id;

  /**
   * @var {?string}
   */
  cluster_name;

  /**
   * @var {?array}
   */
  reporting_template_ids;

  /**
   * @var {string}
   */
  reporting_profile_permission;

  /**
   * @var {string}
   */
  reporting_profile_permission_display;

  /**
   * @var {array}
   */
  reporting_profile_ids;

  /**
   * @var {string}
   */
  scheduled_report_permission;

  /**
   * @var {string}
   */
  scheduled_report_permission_display;

  /**
   * @var {boolean}
   */
  manage_dashboards;

  /**
   * @var {boolean}
   */
  manage_report_studio;

  /**
   * @var {boolean}
   */
  can_manage_alerts;

  /**
   * @var {boolean}
   */
  can_impersonate_other_agents;

  /**
   * @var {array}
   */
  number_of_users;

  /**
   * @var {object}
   */
  role_permissions;

  /**
   * @var {string}
   */
  user_type;

  /**
   * @var {string}
   */
  user_type_display;

  /**
   * @var {boolean}
   */
  is_default;

  /**
   * @var {boolean}
   */
  is_selectable;

  /**
   * @var {boolean}
   */
  two_step_verification_enabled;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.name = model.name;
    this.predefined_role_key = model.predefined_role_key || null;
    this.predefined_role_key_display = model.predefined_role_key_display || null;
    this.role_type = model.role_type;
    this.role_type_display = model.role_type_display;
    this.role_association = model.role_association;
    this.role_association_display = model.role_association_display;
    this.cluster_id = model.cluster_id;
    this.cluster_name = model.cluster_name;
    this.reporting_template_ids = model.reporting_template_ids;
    this.reporting_profile_permission =
      model.reporting_profile_permission || RoleReportingProfilePermission.SELECTION;
    this.reporting_profile_permission_display = model.reporting_profile_permission_display;
    this.reporting_profile_ids = model.reporting_profile_ids || [];
    this.scheduled_report_permission =
      model.scheduled_report_permission || RolePermissionValue.NONE;
    this.scheduled_report_permission_display = model.scheduled_report_permission_display;
    this.manage_dashboards = model.manage_dashboards || false;
    this.manage_report_studio = model.manage_report_studio || false;
    this.can_manage_alerts = model.can_manage_alerts || false;
    this.can_impersonate_other_agents = model.can_impersonate_other_agents || false;
    this.number_of_users = model.number_of_users;
    this.role_permissions = model.role_permissions || null;
    this.user_type = model.user_type;
    this.user_type_display = model.user_type_display;
    this.is_default = model.is_default || false;
    this.is_selectable = model.is_selectable;
    this.two_step_verification_enabled = model.two_step_verification_enabled;
  }
}
