'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('widget.gaugechart.services', [])

    .factory('GaugeChartFactory', GaugeChartFactory);

/**
 *
 * @param ChartFactory
 * @param ChartDataType
 * @param ColumnFormat
 * @param DrawOption
 * @returns {{init: init}}
 * @constructor
 * @ngInject
 */
function GaugeChartFactory(
    ChartFactory,
    ChartDataType,
    ColumnFormat,
    DrawOption,
    gettextCatalog
) {

    /**
     * @param chartOptions
     * @param columns
     */
    var init = function(chartOptions, columns, widget) {

        var provideData = function(json, buildChart) {
            var selected = columns.selected.slice();
            var hasComparisonData = json.has_comparison_data;
            const validGauge = selected.length === 2;
            if (selected.length === 0) {
                return;
            }
            var firstSelected = selected.shift();
            var gaugeData = hasComparisonData ? _.first(json.data).current_period : _.first(json.data);
            var comparisonData = hasComparisonData  ? _.first(json.data).prior_period : false;
            var endValue = gaugeData[firstSelected.groupby_name_field];
            var comparisonEndValue = comparisonData ? comparisonData[firstSelected.groupby_name_field] : null;
            var chartHasData = endValue > 0;
            var labelFunc = function(value) {
                if (selected.length === 0) {
                    return value;
                }
                var displayColumn = _.first(selected);

                return $.fn.formatNumber(
                  value,
                  displayColumn.groupby_field_format,
                  displayColumn.precision,
                  false,
                  gaugeData,
                  widget.metadata.currency_discrepancy,
                  widget.metadata.show_currency
                );
            };

            //Should not make chart if one column format is duration and the other is not
            if (validGauge && ((columns.selected[0].format === ColumnFormat.FORMAT_TIME && columns.selected[1].format !== ColumnFormat.FORMAT_TIME) ||
                (columns.selected[1].format === ColumnFormat.FORMAT_TIME && columns.selected[0].format !== ColumnFormat.FORMAT_TIME))) {
                //Avoid building Gauge Chart, widget will stay in loading state
                return;
            }

            // Basic logic for solid gauge chart creation. Radius and inner radius need to be calculated.
            options.axes = [{
                axisAlpha: 0,
                tickAlpha: 0,
                // TODO: Add draw option for rotation
                startAngle: -90,
                endAngle: 90 ,
                startValue: 0,
                bands: [],
                // Label Options
                showFirstLabel: options[DrawOption.SHOW_LABELS],
                showLastLabel: options[DrawOption.SHOW_LABELS],
                labelFrequency: 5,
                inside: false, // Option to display label inside axis
                labelFunction: labelFunc
            }];

            // Only set endValue when it is greater than 0, in order to avoid using 0 as divisor
            // and cause console error of NaN (TA-16124)
            if (endValue > 0) {
                _.first(options.axes).endValue = endValue;
            }

            options.comparisonData = comparisonData ? {
                axes: [{
                    axisAlpha: 0,
                    bandAlpha: 0.4,
                    tickAlpha: 0,
                    startAngle: -90,
                    endAngle: 90 ,
                    startValue: 0,
                    bands: [],
                    // Label Options
                    showFirstLabel: options[DrawOption.SHOW_LABELS],
                    showLastLabel: options[DrawOption.SHOW_LABELS],
                    labelFrequency: 5,
                    inside: false, // Option to display label inside axis
                    labelFunction: labelFunc
                }],
                allLabels: []
            } : false;

            // Only set endValue when it is greater than 0, in order to avoid using 0 as divisor
            // and cause console error of NaN (TA-16124)
            if (comparisonData && comparisonEndValue > 0) {
                _.first(options.comparisonData.axes).endValue = comparisonEndValue;
            }

            buildData(options.axes, options.allLabels, gaugeData);
            if (comparisonData) {
                options.titles = [{
                    text: 'Current Period'
                }];
                comparisonData.isComparison = true;
                buildData(options.comparisonData.axes, options.comparisonData.allLabels, comparisonData);
            }


            function buildData (axesArray, labels, chartData) {
                var i = 0;
                var column = selected[i];
                const columnFormat = !column ? ColumnFormat.FORMAT_INTEGER : column.format;
                const columnPrecision = !column ? 1 : column.precision;
                const columnLabel = !column ? gettextCatalog.getString('Please select another metric to display the gauge chart') : column.label;
                const columnGroupByNameField = !column ? '' : column.groupby_name_field;
                const columnGroupByFieldFormat = !column ? ColumnFormat.FORMAT_INTEGER : column.groupby_field_format;
                var outerRadius = 100 - (100 / selected.length) * i;
                var innerRadius = 75;
                var axes = _.first(axesArray);
                var backgroundAxisValue = chartData.isComparison ? comparisonEndValue : endValue;
                var valueAxisValue = !column ? axes.endValue : chartData[column.groupby_name_field];

                var backgroundAxis = {
                    color: "#eee",
                    endValue: backgroundAxisValue < 0 ? 0.1 : backgroundAxisValue,
                    startValue: 0,
                    radius: outerRadius + '%',
                    innerRadius: innerRadius + '%',
                };
                var formatted =
                    columnFormat === ColumnFormat.FORMAT_TIME
                    ? columnGroupByNameField
                    : $.fn.formatNumber(
                        valueAxisValue,
                        columnFormat,
                        columnPrecision,
                        false,
                        chartData,
                        widget.metadata.currency_discrepancy,
                        widget.metadata.show_currency
                      );
                var balloonText = chartData.isComparison ?
                    '<span class="label" style="background-color:' + options.getPaletteColor(i + 1) + ';">' + gettextCatalog.getString('Comparison') + ' ' + columnLabel + '</span> ' + formatted + ' <br>'
                    : '<span class="label" style="background-color:' + options.getPaletteColor(i + 1) + ';">' + columnLabel + '</span> ' + formatted + ' <br>';

                var selectedEndValue = Math.min(valueAxisValue, (chartData.isComparison ? comparisonEndValue : endValue));
                var valueAxis = {
                    color: options.getPaletteColor(i + 1), // Need to skip first color to account for max/first selected column
                    radius: outerRadius + '%',
                    innerRadius: innerRadius + '%',
                    balloonText: balloonText,
                    startValue: 0,
                    endValue: selectedEndValue < 0 ? 0 : selectedEndValue,
                };
                if (!chartHasData && valueAxis.endValue > 0) {
                    chartHasData = true;
                }
                axes.bands.push(backgroundAxis, valueAxis);

                var metricLabelText = chartData.isComparison
                    ? gettextCatalog.getString('Comparison') + ' ' + columnLabel : columnLabel;
                var metricLabelValue = $.fn.formatNumber(
                  valueAxisValue,
                  columnGroupByFieldFormat,
                  columnPrecision,
                  false,
                  chartData,
                  widget.metadata.currency_discrepancy,
                  widget.metadata.show_currency
                );
                var metricLabel = {
                    text: metricLabelText,
                    x: '50%',
                    y: comparisonData ? '35': '5%',
                    align: 'middle',
                    color: options.getPaletteColor(i + 1),
                    size: 16
                };
                labels.push(metricLabel);
                if (options[DrawOption.SHOW_LABELS]) {
                    var metricValue = {
                        text: metricLabelValue,
                        x: '50%',
                        y: '65%',
                        align: 'middle',
                        color: options.getPaletteColor(i + 1),
                        size: 16
                    };
                    labels.push(metricValue);
                }

                var totalText = chartData.isComparison
                    ? gettextCatalog.getString('Per Comparison') +' \n' + firstSelected.label
                    : gettextCatalog.getString('Per ') + firstSelected.label;

                var totalLabelText = {
                    text: totalText,
                    x: '50%',
                    y: '75%',
                    align: 'middle',
                    size: 16
                };
                labels.push(totalLabelText);
            }


            // If no chart data, run the renderCallback in order to display the no data placeholder
            if (!chartHasData) {
                options.allEmptyData = true;
                // options.dataProvider is set for options.renderCallback.
                // A truthy value for 'dataprovider' is needed to update loading state appropriately.
                options.dataProvider = [];
                options.renderCallback(options);
            }
            else {
                // options.dataProvider is set for options.renderCallback.
                // A truthy value for 'dataprovider' is needed to update loading state appropriately.
                options.dataProvider = [];
                buildChart();
            }

        };

        // default options for all parameters
        var defaultOptions = {
            type: ChartDataType.GAUGE,
            sequencedAnimation: false,
            marginTop: 20,
            marginBottom: 50,
            // Conditionally set these values based on font size...
            marginLeft: chartOptions.fontSizeSpacing,
            marginRight: chartOptions.fontSizeSpacing,
            // Note: Linked listeners don't work the same with gauge charts.
            // linkedListeners: ['rollOverBand', 'rollOutBand', 'clickBand'],
            balloonFunction: function(graphDataItem) {
                return '<span class="label" style="background-color:' + graphDataItem.color + ';">' + graphDataItem.title +  '</span> ' + graphDataItem.value + ' <br>';
            },
            provideData: provideData,
        };

        var options = _.extend(chartOptions, defaultOptions);


        //
        // OPTION OVERRIDES
        //
        options.labelsEnabled = options[DrawOption.SHOW_LABELS];
        // Todo: Add start angle or rotation.
        // options.startAngle = options[DrawOption.START_ANGLE] || 0;
        options.balloon.enabled = options[DrawOption.HAS_TOOLTIP] ? true : false;

        ChartFactory.makeChart(options, columns);
    };

    return {
        init: init,
    }
}