'use strict';
import angular from 'angular';
import './scripts/calculations.ctrls';
import './scripts/calculations.directives';
import './scripts/calculations.services';
import './scripts/calculator.directives';
import './scripts/calculator.services';

import calculationsListHtmlUrl from './views/calculationslist.html';
import calculationsHtmlUrl from './views/calculations.html';

angular.module('calculations', [
    'calculations.ctrls',
    'calculations.directives',
    'calculations.services',
    'calculator.directives',
    'calculator.services',
])

    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {

        $stateProvider
            .state('calculations', {
                parent: 'layout',
                url: '/calculations',
                controller: 'CalculationsListController',
                templateUrl: calculationsListHtmlUrl,
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('calculations'),
                    data: function(CalculationFactory){
                        return CalculationFactory.calculations.getList({summary: true, all: true});
                    },
                    metadata: function(CalculationFactory){
                        return CalculationFactory.calculations.getList({summary: true, metadata: true});
                    }
                }
            })
            .state('calculations.detail', {
                parent: 'layout',
                url: '/calculations/detail/:id',
                resolve: {
                    calculation: function($stateParams, CalculationFactory) {
                        return $stateParams.id ? CalculationFactory.calculations.one($stateParams.id).get({all: true}) : null;
                    }
                },
                controller: 'CalculationsController',
                templateUrl: calculationsHtmlUrl
            })
    }]);
