import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  setEntityDetails({}, entityDetails) {
    commits.auditlog.setEntityDetails(entityDetails);
  },
  resetEntityDetails({}) {
    commits.auditlog.resetEntityDetails();
  },
};
