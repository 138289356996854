import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { DashboardPage } from '@/modules/ta/dashboard/models/DashboardPage';

export class DashboardPageResource extends BaseEntityResource {
  constructor() {
    super(RouteGroup.DASH_PAGES, DashboardPage);
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<*>}
   */
  async getForm(id, params = {}) {
    return Promise.all([id ? this.getItem(id, params) : new this.model(), this.getColumns()]);
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<*>}
   */
  async copyItem(id, params = {}) {
    return super.postData(params, `copy/${id}`);
  }

  /**
   * @param templateId
   * @param overrides
   * @returns {Promise<*>}
   */
  async createTemplate(templateId, overrides = null) {
    return overrides
      ? apiAxios.post(this._buildRoute(`${RouteGroup.LIBRARY_TEMPLATE}/${templateId}`), overrides)
      : apiAxios.get(this._buildRoute(`${RouteGroup.LIBRARY_TEMPLATE}/${templateId}`));
  }

  /**
   * @param templateId
   * @param value
   * @returns {Promise<*>}
   */
  async toggleTemplate(templateId, value) {
    return apiAxios.get(this._buildRoute(`${templateId}/updateistemplate/${value}`));
  }

  /**
   * @param templateId
   * @param value
   * @returns {Promise<*>}
   */
  async updateIsFavorite(templateId, value) {
    return apiAxios.put(this._buildRoute(`${templateId}/updateisfavorite/${value}`));
  }

  /**
   * @param templateId
   * @param value
   * @returns {Promise<*>}
   */
  async setExecutiveSummaryEnabled(templateId, value) {
    return apiAxios.post(this._buildRoute(`enablesummary/${templateId}`), {
      executive_summary_enabled: value,
    });
  }

  /**
   * @param templateId
   * @returns {Promise<*>}
   */
  async updateThumbnail(templateId) {
    return apiAxios.get(this._buildRoute(`${RouteGroup.LIBRARY}/updatethumbnails/${templateId}`));
  }

  /**
   * @param q
   * @returns {string}
   */
  addSearchParam(q = null) {
    return `${q}|title`;
  }
}

export default new DashboardPageResource();
