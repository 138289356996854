import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  /**
   *
   * @param tabId string
   * @param activeTabIndex int
   */
  setActiveTabIndex({}, { tabId, activeTabIndex }) {
    commits.tabs.setActiveTabIndex({ tabId, activeTabIndex });
  },
};
