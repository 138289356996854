'use strict';
import angular from 'angular';
import _ from 'lodash';
import widgetHtmlUrl from './widget.html';
import widgetDataDisplayHtmlUrl from './widget.data.display.html';
import widgetAdminDisplayHtmlUrl from './widget.admin.display.html';
import widgetLoadedHtmlUrl from './widget.loaded.html';
import widgetLoaderHtmlUrl from './widget.loader.html';
import {WidgetType} from "../../../../../../grok/src/modules/ta/widget/widget.constants";

angular.module('design.widget.directives', [])

// Display
    .directive('designWidget', designWidget)
    .directive('widgetDataDisplay', widgetDataDisplay)
    .directive('widgetAdminDisplay', widgetAdminDisplay)
    .directive('widgetLoaded', widgetLoaded)
    .directive('widgetLoader', widgetLoader);

/**
 * @ngInject
 */
function designWidget(
    $timeout,
    LoadingState,
    DesignFactory,
    AppFactory,
    ExportFactory,
    DrawOption
) {
    return {
        restrict: 'A',
        scope: {
            widget: '<',
            state: '<',
            isFromExportBuilder: '<',
            elementMetadata: '<'
        },
        controller: 'WidgetController',
        templateUrl: widgetHtmlUrl,
        link: function(scope, el) {
            if (DesignFactory.getIsExportingPage()) {
                scope.$watch('state.loadingState', function (nV, oV) {
                    $timeout(function (){
                        if (!AppFactory.invalidateWatch(nV, oV) && !_.isNull(nV)) {
                            switch (nV) {
                                case LoadingState.HAS_ERROR:
                                case LoadingState.NO_RECORDS:
                                case LoadingState.NO_DATA:
                                case LoadingState.NO_DATA_ACTIONABLE:
                                case LoadingState.ALL_EMPTY_DATA:
                                case LoadingState.NO_COLUMNS:
                                case LoadingState.TOO_MUCH_DATA:
                                case LoadingState.INACTIVE:
                                case LoadingState.ERROR:
                                case LoadingState.DONE:
                                case LoadingState.BUBBLE_CHART_REQUIRED_METRICS:
                                    $timeout(function (){
                                        // paginated datagrids need to take into account the space
                                        // taken up by a page break in their height
                                        if (scope.widget.type === WidgetType.TYPE_DATAGRID &&
                                            scope.widget.metadata.draw_options[DrawOption.GRID_PAGINATE]) {
                                            ExportFactory.paginateOnceReady(el);
                                        } else {
                                            ExportFactory.widgetReadyCounter.decRef();
                                        }
                                    }, 0);
                                    break;
                                default :
                                    break;
                            }
                        }
                    },0);
                });

                // this window variable is set only in cases when wkhtmltopdf is generating the page through a .phtml
                if (window.dashExport) {
                    $timeout(function(){
                        if (scope.widget.type === WidgetType.TYPE_DATAGRID &&
                            scope.widget.metadata.draw_options[DrawOption.GRID_PAGINATE]) {
                        } else {
                            var widgetContent = el.find('.widget-content');
                            widgetContent.height(widgetContent.height() - 40);
                        }
                    });
                }
            }
        }
    }
}

/**
 *
 * @returns {{restrict: string, scope: {widget: string, state: string}, replace: boolean, templateUrl, controller: string}}
 */
function widgetDataDisplay() {
    return {
        restrict: 'E',
        scope: {
            widget: '<',
            state: '<',
            elementMetadata: '<'
        },
        replace: true,
        templateUrl: widgetDataDisplayHtmlUrl,
        controller: 'WidgetDataDisplayController'
    }
}

/**
 *
 * @returns {{restrict: string, scope: {widget: string, state: string}, replace: boolean, templateUrl, controller: string}}
 */
function widgetAdminDisplay() {
    return {
        restrict: 'E',
        scope: {
            widget: '<',
            state: '<'
        },
        replace: true,
        templateUrl: widgetAdminDisplayHtmlUrl,
        controller: 'WidgetDataDisplayController'
    }
}

/**
 * Loading directive for widget content
 * @type {string[]}
 */
function widgetLoaded() {
    return {
        restrict: 'E',
        scope: {
            widget: '<',
            loadingState: '<',
            widgetTypeIcon: '=',
            isFromExportBuilder: '<',
        },
        templateUrl: widgetLoadedHtmlUrl,
        controller: 'WidgetLoadedController'
    }
}

/**
 * Directive for the loader showing widget type
 * @param LoadingState
 * @returns {{restrict: string, scope: {loadingState: string, loadingText: string, inverseColor: string, threshold: string, showOverlay: string, size: string, icon: string, staticIcon: string, position: string, top: string, left: string}, templateUrl, controller: controller}}
 * @ngInject
 */
function widgetLoader(
    $timeout,
    LoadingState
) {
    return {
        restrict: 'E',
        scope: {
            loadingState: '<',
            loadingText: '@',
            inverseColor: '@',
            threshold: '@',
            showOverlay: '@',
            isResponsive: '@',
            size: '<',
            icon: '<',
            staticIcon: '<', // Specifies if the icon can change dynamically or not
            position: '@',
            addClasses: '<',
            // bg position coordinates
            top: '@',
            right: '@',
            bottom: '@',
            left: '@'
        },
        templateUrl: widgetLoaderHtmlUrl,
        controller: 'WidgetLoaderController'
    }
}