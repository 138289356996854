'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';

angular.module('core.alert.models', [])
    .factory('AlertModelFactory', AlertModelFactory);


/**
 * @ngInject
 */
function AlertModelFactory(
    AppFactory,
    DataSourceType,
    gettextCatalog
) {
    return {
        getDefaultAlertModel: getDefaultAlertModel,
        getDefaultState: getDefaultState,
        getNewItemAlert: getNewItemAlert,
        getNewWidgetAlert: getNewWidgetAlert,
        getRecipientOptions: getRecipientOptions,
        getGroupbySelectOptions: getGroupbySelectOptions,
        getConditionSelectOptions: getConditionSelectOptions,
        getRecipientSelectOptions: getRecipientSelectOptions,
        getNewCondition: getNewCondition
    };

    /**
     * Recipient Options constructor
     * @param options
     * @constructor
     */
    function RecipientOptions(options) {
        options = _.isEmpty(options) ? {} : options;
        var self = this;

        self.placeholder = !_.isEmpty(options) ? options.placeholder : gettextCatalog.getString('Select Recipients...');
        self.multiple = !_.isEmpty(options) ? options.multiple : true;
        self.allowClear = !_.isEmpty(options) ? options.allowClear : true;
        self.width = !_.isEmpty(options) ? options.width : '50%';
        self.loaded = !_.isEmpty(options) ? options.loaded : false;
        self.data = !_.isEmpty(options) ? options.data : null;

        if (self.data) {
            self.data = _.sortBy(self.data, 'userType');
        }

        self.matcher = (params, data, option) => {
            // If there are no search terms, return all of the data
            if (!params || _.trim(params) === '') {
                return data;
            }
            // Do not display the item if there data
            if (_.isUndefined(typeof data)) {
                return null;
            }

            const regQuery = new RegExp(params, 'i');
            if (option.text.match(regQuery) || option.email.match(regQuery)) {
             return data;
            }
            return null;
        }

        self.formatResult = function (item) {
            if (!item.id) {
                return item.text;
            }
            return $('<label class="label label-default mr10">' + item.userType + '</label><span style="font-size: 13px;">' + item.text + ' - ' + item.email + '</span>');
        };
    }

    /**
     * Groupby select options constructor
     * @param options
     * @constructor
     */
    function GroupbySelectOptions(options) {
        options = _.isEmpty(options) ? {} : options;
        var self = this;

        self.placeholder = !_.isEmpty(options) ? options.placeholder : 'Select an attribute...';
        self.multiple = !_.isEmpty(options) ? options.multiple : false;
        self.allowClear = !_.isEmpty(options) ? options.allowClear : false;
        self.width = !_.isEmpty(options) ? options.width : '50%';
        self.loaded = !_.isEmpty(options) ? options.loaded : false;
        self.dropdownCssClass = !_.isEmpty(options) ? options.dropdownCssClass : 'column-select-dropdown';
        self.separator = !_.isEmpty(options) ? options.separator : '|||||';

        self.formatResult = function (item) {
            if (!item.id) { return item.text; }

            var dataTypeKeys = AppFactory.arrayToMemoizedObj(AppFactory.getDataTypes(), 'key');
            var dataType = dataTypeKeys[item.groupby_field_format];

            var html = '<label class="label">';
            if (_.startsWith(dataType.icon, 'icon')) {
                html += '<span class="icon '+ dataType.icon +'"></span>';
            } else {
                html += '<span class="icon">'+ dataType.icon +'</span>';
            }
            html += '</label>';
            html += '<span>' + item.text + '</span>';

            return $(html);
        };
    }

    /**
     * Groupby select options constructor
     * @param options
     * @constructor
     */
    function ConditionSelectOptions(options) {
        options = _.isEmpty(options) ? {} : options;
        var self = this;

        self.placeholder = !_.isEmpty(options) ? options.placeholder : gettextCatalog.getString('Select a metric...');
        self.multiple = !_.isEmpty(options) ? options.multiple : false;
        self.allowClear = !_.isEmpty(options) ? options.allowClear : false;
        self.width = !_.isEmpty(options) ? options.width : '50%';
        self.loaded = !_.isEmpty(options) ? options.loaded : false;
        self.dropdownCssClass = !_.isEmpty(options) ? options.dropdownCssClass : 'column-select-dropdown';
        self.separator = !_.isEmpty(options) ? options.separator : '|||||';

        self.formatResult = function (item) {
            if (!item.id) { return item.text; }

            var dataTypeKeys = AppFactory.arrayToMemoizedObj(AppFactory.getDataTypes(), 'key');
            var dataType = dataTypeKeys[item.groupby_field_format];

            var html = '<label class="label">';
            if (_.startsWith(dataType.icon, 'icon')) {
                html += '<span class="icon '+ dataType.icon +'"></span>';
            } else {
                html += '<span class="icon">'+ dataType.icon +'</span>';
            }
            html += '</label>';
            html += '<span>' + item.text + '</span>';

            return $(html);
        };
    }

    /**
     * @param options
     * @constructor
     */
    function RecipientSelectOptions(options) {
        options = _.isEmpty(options) ? {} : options;
        var self = this;
        self.placeholder = !_.isEmpty(options) ? options.placeholder : gettextCatalog.getString('Select Users');
        self.multiple = !_.isEmpty(options) ? options.multiple : true;
        self.selectorType = !_.isEmpty(options) ? options.selectorType : 'users';
        self.allowClear = !_.isEmpty(options) ? options.allowClear : true;
        self.width = !_.isEmpty(options) ? options.width : '530px';
        self.loaded = !_.isEmpty(options) ? options.loaded : false;
    }

    /**
     * Getter
     * @param options
     * @returns {RecipientOptions}
     */
    function getRecipientOptions(options) {
        return new RecipientOptions(options);
    }

    /**
     * Getter
     * @param options
     * @returns {GroupbySelectOptions}
     */
    function getGroupbySelectOptions(options) {
        return new GroupbySelectOptions(options);
    }

    /**
     * Getter
     * @param options
     * @returns {ConditionSelectOptions}
     */
    function getConditionSelectOptions(options) {
        return new ConditionSelectOptions(options);
    }

    /**
     * Getter
     * @param options
     * @returns {RecipientSelectOptions}
     */
    function getRecipientSelectOptions(options) {
        return new RecipientSelectOptions(options);
    }

    /**
     * Getter
     * @returns {{}}
     */
    function getDefaultState() {
        var state = {};
        state.showDelete = false;
        state.isBuilding = true;
        state.hasClient = false;
        return state;
    }

    /**
     * Getter
     * @param alert
     * @returns {{}}
     */
    function getDefaultAlertModel(alert) {
        var model = {};
        var isNew = _.isNil(alert);
        // Set alert model to be saved
        model.alerts = isNew ? [] : [alert];
        return model;
    }

    /**
     * Getter
     */
    function getNewItemAlert() {
        var clientId = !_.isNull(AppFactory.getCurrentClient()) ? AppFactory.getCurrentClient().id : null;
        var clientGroupId = !_.isNull(AppFactory.getCurrentClientGroup()) ? AppFactory.getCurrentClientGroup().id : null;
        var operator = 'all_clients';

        if (!_.isNull(clientId)) {
            operator = 'client_id';
        }
        else if (!_.isNull(clientGroupId)) {
            operator = 'client_group_id';
        }

        var newAlert = {
            id: null,
            cluster_id: null,
            client_id: clientId,
            client_group_id: clientGroupId,
            operator: operator, //enum('all_clients', 'client_id', 'client_group_id')
            widget_id: null, //needs to be null for alert-item directive to work
            datasource_id: null,
            datasource_type: DataSourceType.SERVICE_DATA,
            data_view: null,
            conditions: [getNewCondition()],
            grouping_fields: null,
            evaluation_timeframe: null,
            evaluation_frequency: null,
            evaluation_frequency_offset: null,
            cluster: {},
            isNew: true, // Not saved
        };

        return newAlert;
    }

    /**
     * Getter
     */
    function getNewWidgetAlert(model, metadata) {
        var clientId = AppFactory.getCurrentClient().id; // Will set to null if no client
        var clientGroupId = AppFactory.getCurrentClientGroup().id; // Will set to null if no client group
        var operator = 'all_clients';

        if (!_.isNull(clientId)) {
            operator = 'client_id';
        }
        else if (!_.isNull(clientGroupId)) {
            operator = 'client_group_id';
        }

        var newAlert = {
            id: null,
            client_id: clientId,
            client_group_id: clientGroupId,
            operator: operator, //enum('all_clients', 'client_id', 'client_group_id')
            widget_id: model.widget_id,
            conditions: [getNewCondition()],
            datasource: metadata.data_source,
            datasource_id: metadata.data_source.id,
            datasource_type: metadata.data_source.type,  //enum('service_data', 'category_data')
            data_view: metadata.data_source.data_view,
            json_metadata: null,
            isNew: true, // Not saved
        };
        return newAlert;
    }

    /**
     * Getter
     */
    function getNewCondition() {
        return {
            metric_column: null,
            row_alert_criteria: 'gt', // default operand would be operand
            alert_threshold: null,
            metricPrecision: 1
        };
    }
}