import Enum from '@/modules/core/app/models/abstracts/Enum';

export const BUSINESS_UNIT_ADMIN = 'business_unit_admin';

export class UserType extends Enum {
  static SUPERADMIN = 'superadmin';

  static CLUSTERADMIN = 'cluster_admin';

  static AGENT = 'agent';

  static CLIENT = 'client';

  static CLIENT_GROUP = 'client_group';
}

export const FeaturePackageName = {
  STANDARD: 'Standard',
  PRO: 'Pro',
  PRO_2: 'Pro 2',
  ELITE: 'Elite',
  BASIC: 'Basic',
};

export const NamingConventionForUserType = {
  [UserType.SUPERADMIN]: UserType.SUPERADMIN,
  [UserType.CLUSTERADMIN]: BUSINESS_UNIT_ADMIN,
  [UserType.AGENT]: UserType.AGENT,
  [UserType.CLIENT]: UserType.CLIENT,
};

export const UserStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const UserThemeType = {
  TYPE_HYBRID: 'hybrid',
  TYPE_LIGHT: 'light',
  TYPE_DARK: 'dark',
};

export const UserTheme = {
  BRIGHT: 'bright',
  MINT: 'mint',
  VELVET: 'velvet',
  HOTH: 'hoth',
  CLASSIC: 'classic',
  AZURE: 'azure',
  LEATHER: 'leather',
  MIDAS: 'midas',
  EGGPLANT: 'eggplant',
  TEAL: 'teal',
  CUSTOM: 'custom',
  CUSTOMLIGHT: 'customlight',
  CUSTOMHYBRID: 'customhybrid',
  CUSTOMDARK: 'customdark',
};

export const OverviewDisplay = {
  DEFAULT: 'default',
  YES: 'yes',
  NO: 'no',
};

export const DataProfile = {
  KEY: '1',
  VALUE: 'Default Profile',
};

export const OverviewDisplayOptions = [
  { key: OverviewDisplay.DEFAULT, value: '... (Inherited from data profile)' },
  { key: OverviewDisplay.YES, value: 'Yes' },
  { key: OverviewDisplay.NO, value: 'No' },
];

export const LeadNotificationFrequency = {
  REAL_TIME: 'real_time',
  NEVER: 'never',
};

export const IOToolNotificationFrequency = {
  REAL_TIME: 'real_time',
  NEVER: 'never',
};

export const IOToolNotificationOwnActivity = {
  NO: 'false',
  YES: 'true',
};

export const SupportLinks = {
  SUPERADMIN_SUPPORT_LINK: 'https://support.tapclicks.com/hc/en-us',
};

export const UserRoute = {
  USERS: 'users',
  EDITSETTINGS: 'editsettings',
};

export const UserPathName = {
  USERS: 'users',
  USERFORM: 'userform',
  USERFORM_NEW: 'userform:new',
  EDITSETTINGS: 'editsettings',
};

export const UserHomepages = {
  TASKS: 'myTasks',
  QUEUES: 'myQueues',
  CLIENTDASH: 'io_clientDash',
  ORDERS: 'orders',
  CONNECTIONSTATUS: 'connectionstatus',
  DATASOURCEOVERVIEW: 'overview',
  CATEGORYOVERVIEW: 'categoryoverview',
  WEBSITES: 'raven/websites',
  STELLAR_ORDERS: 'entity/orders',
  STELLAR_LINE_ITEMS: 'entity/lineitems',
  STELLAR_CATALOGUE: 'cataloguepage',
  STELLAR_TASKS: 'tasks',
  STELLAR_FILES: 'files',
};

export const UsersEvent = {
  SHOW_USERS_UPGRADE_BANNER: 'showusersupgradebanner',
  ATTRIBUTE: 'user',
};

export const Browser = {
  CHROME: 'Chrome',
  SAFARI: 'Safari',
};

export const USER_FORM_CANCEL_EVENT = 'UserFormCancelEvent';

export const USER_LANGUAGE = {
  PLATFORM_DEFAULT: 'p_def',
};
