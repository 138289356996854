'use strict';
import angular from 'angular';
import _ from 'lodash';
import createExecutiveSummaryModalHtmlUrl from './create.executivesummary.modal.html';


angular.module('create.executivesummary.modal.directives', [])
    .component('createExecutiveSummaryModal', {
        templateUrl: createExecutiveSummaryModalHtmlUrl,
        controller: CreateExecutiveSummaryModalController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function CreateExecutiveSummaryModalController(
    $element,
    $scope,
    ExecutiveSummaryFactory,
    UIExecutiveSummaryModalFactory,
    LoadingState,
    DataSourceType,
    AppFactory,
    DesignFactory,
    PubSub,
    ExportBuilderDashboardService
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.fnCanSave = fnCanSave;
    vm.getIsLoadingSelect = getIsLoadingSelect;
    vm.deleteSummaryConfirm = deleteSummaryConfirm;
    vm.selectOptions = ExecutiveSummaryFactory.getSelectOptions();
    vm.brandMappings = AppFactory.getBrandMappings();
    vm.getCurrentPageOrLayoutTitle = getCurrentPageOrLayoutTitle;

    vm.isReport = false;

    registerEvents();

    function $onInit() {
        vm.textOptions = ExecutiveSummaryFactory.getDefaultTextOptions({});
        vm.DataSourceType = DataSourceType;
        vm.saveItem = saveItem;
        let page = DesignFactory.getCurrentPage();
        vm.isReport = _.isEmpty(page);

        if (vm.isReport) {
            vm.report = ExportBuilderDashboardService.getBuilder().report;
            vm.reportPage = ExportBuilderDashboardService.getBuilder().currentPage;
        }
    }

    function onModalShow() {
        $scope.$evalAsync(function() {
            ExecutiveSummaryFactory.setStateProperty('isEditing', true);
            ExecutiveSummaryFactory.setStateProperty('isLoadingSelect', LoadingState.FETCHING);
            vm.itemId = ExecutiveSummaryFactory.getActiveSummaryId();
            vm.summary = ExecutiveSummaryFactory.getExecutiveSummaryItemById(vm.itemId);
            ExecutiveSummaryFactory.initClientSelect(vm.summary);
            vm.dateOptions = ExecutiveSummaryFactory.getDefaultDateOptions({
                startDate: vm.summary.start_date,
                endDate: vm.summary.end_date,
                summary: vm.summary,
                parentEl: 'dashboard-executive-summary-panel'
            });
        });
    }

    function fnCanSave() {
        return !_.isEmpty(vm.summary)
            && !_.isEmpty(vm.summary.content)
            && vm.summary.content !== '<p><br></p>';
    }

    function getIsLoadingSelect() {
        return ExecutiveSummaryFactory.getStateProperty('isLoadingSelect');
    }

    function deleteSummaryConfirm(summary) {
        if (window.isNUI) {
            const page = DesignFactory.getCurrentPage();

            PubSub.emit('SegmentEvents', {
                event: 'ExecutiveSummaryDeleteSummaryLegacyEvent',
                payload: {
                    dashboard_id: page.id,
                    dashboard_name: page.title
                }
            });
        }
        return UIExecutiveSummaryModalFactory.deleteSummaryConfirm(summary);
    }

    function saveItem(summary, id) {
        if (window.isNUI) {
            if (!vm.isReport) {
                const page = DesignFactory.getCurrentPage();

                PubSub.emit('SegmentEvents', {
                    event: 'ExecutiveSummaryAddOrEditSummaryLegacyEvent',
                    payload: {
                        dashboard_id: page.id,
                        dashboard_name: page.title,
                        summaryItem: vm.summary
                    }
                });
            } else {
                const report = ExportBuilderDashboardService.getBuilder().report;
                const reportPage = ExportBuilderDashboardService.getBuilder().currentPage;

                PubSub.emit('SegmentEvents', {
                    event: 'ExecutiveSummaryAddOrEditSummaryLegacyEvent',
                    payload: {
                        report_id: report.id,
                        report_name: report.title,
                        report_page_id: reportPage.id,
                        report_page_name: reportPage.title,
                        summaryItem: vm.summary
                    }
                });
            }
        }
        ExecutiveSummaryFactory.saveItem(summary).then(function () {
            ExecutiveSummaryFactory.$rebuild(id, false, vm.isReport);
        });
    }

    function onModalClose() {
        vm.summary = null;
        vm.dateOptions = null;
    }

    function registerEvents() {
        $element.on('show.bs.modal', onModalShow);
        $element.on('hide.bs.modal', onModalClose);
    }

    /**
     * Returns the title of page or layout
     * depending on if it's report studio or dashboard
     *
     * @returns {*}
     */
    function getCurrentPageOrLayoutTitle() {
        if (vm.isReport) {
            vm.reportPage = ExportBuilderDashboardService.getBuilder().currentPage;
            return vm.reportPage.title;
        }
        return DesignFactory.getCurrentLayout().title;
    }
}