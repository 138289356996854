import angular from 'angular';

angular.module('core.tag.models', [])
    .factory('TagsModelFactory', TagsModelFactory);

function TagsModelFactory() {

    return {
        getServiceProps: getServiceProps
    };

    function getServiceProps() {
        return new Props();
    }

    /**
     * Service Props
     * @constructor
     */
    function Props() {
        var self = this;
        self.data = new DataProps();
    }

    /**
     * Data object of Service Props
     * @constructor
     */
    function DataProps() {
        var self = this;
        self.tags = [];
    }
}