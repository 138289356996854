'use strict';
import angular from 'angular';
import './scripts/overview.ctrls';
import './scripts/overview.directives';
import './scripts/overview.services';

angular.module('overview', [
    'overview.ctrls',
    'overview.directives',
    'overview.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        var dashfilters = {
            templateUrl: AppConfig.MAGE_URL + 'layout/dashfilters',
            controller: 'DashFiltersController'
        };

        $stateProvider
            .state('old_overview', {
                parent:'layout',
                url: '/old_overview',
                views: {
                    '': {
                        templateUrl: AppConfig.MAGE_URL + 'index/overview',
                        controller: 'OverviewController'
                    },
                    'dashfilters@layout': dashfilters
                }
            })
            .state('welcome', {
                parent:'layout',
                url: '/welcome',
                views: {
                    '': {
                        templateUrl: AppConfig.MAGE_URL + 'index/welcome',
                    },
                    'dashfilters@layout': dashfilters
                },
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('welcome')
                }                })
    }]);