'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

angular.module('core.alert.services', [])
    .factory('AlertFactory', AlertFactory)
    .factory('AlertItemFactory', AlertItemFactory);

/**
 * @ngInject
 */
function AlertFactory(
    $timeout,
    $AlertEvents,
    ScopeFactory,
    PubSub,
    AppFactory,
    AlertFrequency,
    AlertModelFactory,
    AlertTimeFrame,
    AlertResource,
    AlertItemFactory,
    DataSourceFactory,
    LayoutFactory,
    UIFactory,
    WidgetFactory,
    UserType,
    DashboardContextService,
) {

    return {
        initAlerts: initAlerts,
        saveItemAlert: saveItemAlert,
        saveWidgetAlert: saveWidgetAlert,
        removeAlert: removeAlert,
        addCondition: addCondition,
        removeCondition: removeCondition,
        updateMetricPrecision: updateMetricPrecision,
        setMetricsColumns: setMetricsColumns,
        setGroupbyColumns: setGroupbyColumns,
        genUserSelectData: genUserSelectData,
        getUserTypeParams: getUserTypeParams,
        getDaysOfWeek: getDaysOfWeek,
        getDaysOfMonth: getDaysOfMonth,
        getDaysOfQuarter: getDaysOfQuarter,
        showFrequencyEveryDay: showFrequencyEveryDay,
        showFrequencyEveryWeek: showFrequencyEveryWeek,
        showFrequencyEveryMonth: showFrequencyEveryMonth,
        showFrequencyEveryQuarter: showFrequencyEveryQuarter,
        showEveryDayOfWeek: showEveryDayOfWeek,
        showEveryDayOfMonth: showEveryDayOfMonth,
        showEveryDayOfQuarter: showEveryDayOfQuarter,
        resetEvaluationFrequency: resetEvaluationFrequency,
        resetEvaluationFrequencyOffset: resetEvaluationFrequencyOffset,
    };

    /**
     * Helper function to init alerts
     * @param model
     * @param isNew
     */
    function initAlerts(model, isNew) {
        var alerts = model.alerts;
        _.each(alerts, function (alert) {
            if (isNew) {
                alert.datasource = {
                    type: alert.datasource_type,
                    icon: 'custom-icon icon-bell-o',
                    color: '#3594d2',
                    id: null,
                    data_view: null
                };
                alert.isNew = true;
            } else {
                //need to set selectValue as an object for client or clientgroup selector
                if (alert.operator === 'client_id') {
                    alert.client = alert.client_id ? {id: alert.client_id, text: alert.client_name} : {};
                } else if (alert.operator === 'client_group_id') {
                    alert.client_group = alert.client_group_id  ? {id: alert.client_group_id || -1, text: alert.client_group_name} : {};
                } else {
                    alert.client = {};
                    alert.client_group = {};
                }
                alert.cluster = {id: alert.cluster_id};

                alert.datasource = {
                    type: alert.datasource_type,
                    id: alert.datasource_id,
                    data_view: alert.data_view
                };
                // set default icon and color for alert
                if (alert.datasource.type !== 'service_data' && alert.datasource.type !== 'category_data') {
                    alert.datasource.icon = 'custom-icon icon-bell-o';
                    alert.datasource.color = '#3594d2';
                }
                alert.isNew = false;
            }

            alert.datasource.is_of_type_service = DataSourceFactory.dataSourceContainsServices(alert.datasource.type);
        });
        model.alerts = alerts;
    }

    /**
     * Helper function to save item alert
     * @param model
     * @param state
     * @param callback
     */
    function saveItemAlert(model, state, callback) {
        var alerts = model.alerts;
        var isSingleAlert = alerts.length === 1;
        var alertsToBeSaved = [];

        // Some data massaging
        _.each(alerts, function(alert) {
            // We create copies to extract only the objects we need without affecting the alert item dropwdowns
            var alertCopy = {};
            angular.copy(alert, alertCopy);

            //need to get id from the select2 selected object to set client_id or clientgroup_id
            if (alertCopy.operator === 'client_id') {
                alertCopy.client_group_id = null;

                if(_.isNil(alert.client) || _.isNil(alert.client.id)) {
                    alertCopy.client_id = null;
                    alertCopy.operator = 'all_clients';
                }
                else {
                    alertCopy.client_id = alert.client.id;
                }
            }
            else if (alert.operator === 'client_group_id') {
                alertCopy.client_id = null;

                if(_.isNil(alert.client_group) || _.isNil(alert.client_group.id)) {
                    alertCopy.client_group_id = null;
                    alertCopy.operator = 'all_clients';
                }
                else {
                    alertCopy.client_group_id = alertCopy.client_group.id;
                }
            }
            else { //all clients
                alertCopy.client_id = null;
                alertCopy.client_group_id = null;
            }

            alertCopy.cluster_id = alertCopy.cluster ? alertCopy.cluster.id : null;
            alertCopy.datasource_type = alert.datasource ? alert.datasource.type : null;
            alertCopy.datasource_id = alert.datasource ? alert.datasource.id : null;
            alertCopy.data_view = alert.datasource ? alert.datasource.data_view : null;

            alertCopy.conditions = [];
            _.each(alert.conditions, function(condition) {
                var copiedCondition = {
                    metric_column: condition.metric_column,
                    alert_threshold: condition.alert_threshold,
                    row_alert_criteria: condition.row_alert_criteria
                };
                alertCopy.conditions.push(copiedCondition);
            });

            alertCopy.recipient_ids = [];
            _.each(alert.recipient_ids, function(recipient_id) {
                if (_.isObject(recipient_id)) {
                    alertCopy.recipient_ids.push(recipient_id.id);
                } else {
                    alertCopy.recipient_ids.push(recipient_id);
                }
            });

            // We do not want to send these in the post payload
            delete alertCopy.metrics;
            delete alertCopy.datasource;
            delete alertCopy.groupbyColumns;
            delete alertCopy.client;
            delete alertCopy.client_group;
            delete alertCopy.cluster;
            alertsToBeSaved.push(alertCopy);
        });

        AlertResource.saveList(alertsToBeSaved).then(function () {
            LayoutFactory.$rebuildAllWidgets();
            if (state) {
                state.showDelete = model.alerts.length > 1;
            }
            callback && callback();
            UIFactory.hideModal();
            UIFactory.notify.showSuccess((isSingleAlert ? 'Alert' : 'Alerts') + ' successfully saved');
        });

        return alertsToBeSaved;
    }

    function saveWidgetAlert(model, state, callback) {
        var alerts = model.alerts;
        var isSingleAlert = alerts.length === 1;
        var alertsToBeSaved = [];

        // Some data massaging
        _.each(alerts, function(alert) {
            // We create copies to extract only the objects we need without affecting the alert item dropwdowns
            var alertCopy = {};
            angular.copy(alert, alertCopy);
            alertCopy.datasource_type = alert.datasource ? alert.datasource.type : null;
            alertCopy.datasource_id = alert.datasource ? alert.datasource.id : null;
            alertCopy.data_view = alert.datasource ? alert.datasource.data_view : null;

            alertCopy.conditions = [];
            _.each(alert.conditions, function(condition) {
                var copiedCondition = {
                    metric_column: condition.metric_column,
                    alert_threshold: condition.alert_threshold,
                    row_alert_criteria: condition.row_alert_criteria
                };
                alertCopy.conditions.push(copiedCondition);
            });

            alertCopy.recipient_ids = [];
            _.each(alert.recipient_ids, function(recipient_id) {
                if (_.isObject(recipient_id)) {
                    alertCopy.recipient_ids.push(recipient_id.id);
                } else {
                    alertCopy.recipient_ids.push(recipient_id);
                }
            });

            // We do not want to send these in the post payload
            delete alertCopy.metrics;
            delete alertCopy.datasource;
            delete alertCopy.groupbyColumns;
            alertsToBeSaved.push(alertCopy);
        });

        AlertResource.saveList(alertsToBeSaved).then(function (json) {
            var savedAlerts = json.plain();

            // Update widget metadata
            var currentWidget = WidgetFactory.$getScope(model.widget_id).widget;
            currentWidget.metadata.dynamic.alerts = savedAlerts;
            if (state) {
                state.showDelete = model.alerts.length > 1;
            }
            callback && callback();
            UIFactory.hideModal();
            UIFactory.notify.showSuccess((isSingleAlert ? 'Alert' : 'Alerts') + ' successfully saved');
        });

        return alertsToBeSaved;
    }

    /**
     * Helper function to remove alert
     * @param model
     * @param state
     * @param alertToRemove
     */
    function removeAlert(model, state, alertToRemove, callback) {
        var alerts = model.alerts;

        if (!alertToRemove.isNew) {
            var options = {};
            options.text = 'Removing this alert will stop all notifications to the selected recipients!';
            options.callback = function () {
                AlertResource.remove(alertToRemove.id).then(function () {
                    var id = alertToRemove.id;
                    AlertItemFactory.$getElement(id).addClass('zoomOut magictime');
                    $timeout(function () {
                        _.remove(alerts, alertToRemove);

                        var currentWidget = WidgetFactory.$getScope(model.widget_id).widget;
                        _.remove(currentWidget.metadata.dynamic.alerts, {id: id});

                    }, 1200);
                    callback && callback();
                });
            };

            UIFactory.confirmDelete(options);
        } else {
            _.remove(alerts, alertToRemove);
            state.showDelete = model.alerts.length > 1;
        }
    }

    /**
     * Helper function to modify user data to pass to Select2 in format it accepts
     */
    function genUserSelectData(users) {
        return _.map(users, function(user) {
            return {
                id: user.id,
                email: user.email,
                userType: user.user_type_display,
                text: (user.first_name + ' ' + user.last_name)
            };
        });
    }

    /**
     * Helper function to set metrics columns
     * @param alert
     * @param callback
     */
    function setMetricsColumns(alert, callback) {
        var alertDataSource = alert.datasource;
        if (_.isEmpty(alertDataSource)) {
            return;
        }

        var dataSource = {
            type: alertDataSource.type,
            id: alertDataSource.id,
            data_view: alertDataSource.data_view
        };

        var queryParams = {
            is_metric: true,
            ignore_reporting_profile: !AppFactory.getUser().isAgent()
        };
        
        const dashboardClientId = DashboardContextService.resolveSelectedEntityIdforType();
        DataSourceFactory.getColumns(dataSource, queryParams).then(function(data) {            
            for (let index = 0; index < data.length; index++) {
                if (!isMetricClientCompatible(dashboardClientId, data[index])) {
                    data.splice(index, 1);
                }
            }
            alert.metrics = data;
            callback && callback();
        });
    }

    /**
     * @param {dashboardClientId} int
     * @param {metric} object
     * @returns {boolean}
     * @private
     */
    function isMetricClientCompatible(dashboardClientId, metric) {
        if (!metric.field.includes('calculation_') || !metric.client_id) {
            return true;
        }
        return dashboardClientId === metric.client_id;
    }

    /**
     * Helper function to set group by columns
     * @param alert
     * @param callback
     */
    function setGroupbyColumns(alert, callback) {
        var alertDataSource = alert.datasource;
        var dataSource = {
            type: alertDataSource.type,
            id: alertDataSource.id,
            data_view: alertDataSource.data_view
        };
        var queryParams = {
            is_groupable: true,
            is_primary_date_field: false
        };

        // The widgetFactoryConfig will help specify what kind of datasourced data config we need
        DataSourceFactory.getColumns(dataSource, queryParams).then(function (columns) {
            alert.groupbyColumns = columns;
            callback && callback();
        });
    }
    /**
     * Helper function to get user type parameters
     * @param includeAgent
     * @param includeClient
     * @returns {string}
     */
    function getUserTypeParams(includeAgent, includeClient){
        var params = UserType.SUPERADMIN + ',' + UserType.CLUSTERADMIN;
        if (includeAgent) {
            params += ',' + UserType.AGENT;
        }
        if (includeClient) {
            params += ',' + UserType.CLIENT;
        }
        return params;
    }

    /**
     * Helper function to update metric precision
     */
    function updateMetricPrecision(metrics, conditions, curCondition) {
        if (_.isEmpty(metrics) || _.isEmpty(conditions)) {
            return;
        }

        var columnsObj = AppFactory.arrayToMemoizedObj(metrics, 'field');

        if (_.isEmpty(curCondition)) {
            _.each(conditions, function(condition) {
                var column = columnsObj[condition.metric_column];
                condition.metricPrecision = column && column.precision ? Math.pow(0.1, column.precision) : 1;
                condition.alert_threshold = column && column.precision ? _.round(condition.alert_threshold, column.precision) : _.round(condition.alert_threshold);
            });
        } else {
            var column = columnsObj[curCondition.metric_column];
            curCondition.metricPrecision = column && column.precision ? Math.pow(0.1, column.precision) : 1;
            curCondition.alert_threshold = column && column.precision ? _.round(curCondition.alert_threshold, column.precision) : _.round(curCondition.alert_threshold);
        }
    }

    /**
     * Helper function to add condition
     * @param conditions
     */
    function addCondition(conditions) {
        if (_.isNil(conditions)) {
            return;
        }
        conditions.push(AlertModelFactory.getNewCondition());
    }

    /**
     * Helper function to remove condition
     * @param conditions
     * @param conditionToBeRemoved
     */
    function removeCondition(conditions, conditionToBeRemoved) {
        _.remove(conditions, conditionToBeRemoved);
        var values = {oldValue: conditionToBeRemoved};
        PubSub.emit($AlertEvents.UPDATE_CONDITION, values, true);
    }

    /**
     * Helper function to show frequency every day
     * @param timeFrame
     * @returns {boolean}
     */
    function showFrequencyEveryDay(timeFrame) {
        switch (timeFrame) {
            case AlertTimeFrame.TODAY:
            case AlertTimeFrame.YESTERDAY:
            case AlertTimeFrame.LAST_7_DAYS:
            case AlertTimeFrame.LAST_14_DAYS:
            case AlertTimeFrame.LAST_30_DAYS:
            case AlertTimeFrame.LAST_90_DAYS:
            case AlertTimeFrame.LAST_3_MONTHS:
            case AlertTimeFrame.LAST_6_MONTHS:
            case AlertTimeFrame.LAST_12_MONTHS:
            case AlertTimeFrame.LAST_13_MONTHS:
                return true;
            default:
                return false;
        }
    }

    /**
     * Helper function to show frequency every week
     * @param timeFrame
     * @returns {boolean}
     */
    function showFrequencyEveryWeek(timeFrame) {
        switch (timeFrame) {
            case AlertTimeFrame.THIS_WEEK:
            case AlertTimeFrame.LAST_WEEK:
            case AlertTimeFrame.LAST_7_DAYS:
            case AlertTimeFrame.LAST_14_DAYS:
            case AlertTimeFrame.LAST_30_DAYS:
            case AlertTimeFrame.LAST_90_DAYS:
            case AlertTimeFrame.LAST_3_MONTHS:
            case AlertTimeFrame.LAST_6_MONTHS:
            case AlertTimeFrame.LAST_12_MONTHS:
            case AlertTimeFrame.LAST_13_MONTHS:
                return true;
            default:
                return false;
        }
    }

    /**
     * Helper function to show frequency every month
     * @param timeFrame
     * @returns {boolean}
     */
    function showFrequencyEveryMonth(timeFrame) {
        switch (timeFrame) {
            case AlertTimeFrame.LAST_14_DAYS:
            case AlertTimeFrame.LAST_30_DAYS:
            case AlertTimeFrame.LAST_90_DAYS:
            case AlertTimeFrame.THIS_MONTH:
            case AlertTimeFrame.LAST_MONTH:
            case AlertTimeFrame.LAST_3_MONTHS:
            case AlertTimeFrame.LAST_6_MONTHS:
            case AlertTimeFrame.LAST_12_MONTHS:
            case AlertTimeFrame.LAST_13_MONTHS:
                return true;
            default:
                return false;
        }
    }

    /**
     * Helper function to show frequency every quarter
     * @param timeFrame
     * @returns {boolean}
     */
    function showFrequencyEveryQuarter(timeFrame) {
        switch (timeFrame) {
            case AlertTimeFrame.LAST_90_DAYS:
            case AlertTimeFrame.LAST_3_MONTHS:
            case AlertTimeFrame.LAST_6_MONTHS:
            case AlertTimeFrame.LAST_12_MONTHS:
            case AlertTimeFrame.LAST_13_MONTHS:
            case AlertTimeFrame.THIS_QUARTER:
            case AlertTimeFrame.LAST_QUARTER:
                return true;
            default:
                return false;
        }
    }

    /**
     * Helper function to show every day of week
     * @param evaluationFrequency
     * @returns {boolean}
     */
    function showEveryDayOfWeek(evaluationFrequency) {
        return evaluationFrequency === AlertFrequency.EVERY_WEEK_ON_DAY;
    }

    /**
     * Helper function to show every day of month
     * @param evaluationFrequency
     * @returns {boolean}
     */
    function showEveryDayOfMonth(evaluationFrequency) {
        return evaluationFrequency === AlertFrequency.EVERY_MONTH_ON_DAY;
    }

    /**
     * Helper function to show every day of quarter
     * @param evaluationFrequency
     * @returns {boolean}
     */
    function showEveryDayOfQuarter(evaluationFrequency) {
        return evaluationFrequency === AlertFrequency.EVERY_QUARTER_ON_DAY;
    }

    /**
     * Helper function to get days of week
     */
    function getDaysOfWeek() {
        return moment.weekdays();
    }

    /**
     * Helper function to get days of month
     */
    function getDaysOfMonth() {
        return getFormattedDays(28);
    }

    /**
     * Helper function to get days of quarter
     */
    function getDaysOfQuarter() {
        return getFormattedDays(90);
    }

    /**
     * Helper function to get formatted days
     * @param num
     * @returns {Array}
     */
    function getFormattedDays(num) {
        var days = [];
        for (var i = 1 ; i <= num; i++) {
            days.push(moment(i, 'DDD').format('DDDo'));
        }
        days.push('last');
        return days;
    }

    /**
     * Reset evaluation frequency
     */
    function resetEvaluationFrequency(alert) {
        alert.evaluation_frequency = null;
    }

    /**
     * Reset evaluation frequency offset
     */
    function resetEvaluationFrequencyOffset(alert) {
        alert.evaluation_frequency_offset = null;
    }
}

function AlertItemFactory() {

    function $getElement(id) {
        return angular.element('#alert-item-' + id);
    }

    return {
        $getElement: $getElement
    }
}