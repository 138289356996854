'use strict';
import angular from 'angular';
import _ from 'lodash';

import smartCampaignDetailsModalHtmlUrl from './smartcampaign.details.modal.html';
import smartCampaignDetailsHtmlUrl from './smartcampaign.details.html';
import smartCampaignDetailsPreviewHtmlUrl from './smartcampaign.details.preview.html';

angular.module('core.smartcampaign.components')
    .component('smartCampaignDetailsModal', {
        templateUrl: smartCampaignDetailsModalHtmlUrl,
        controllerAs: 'vm',
        transclude: true,
        controller: SmartCampaignDetailsModalController
    })
    .component('smartCampaignDetails', {
        templateUrl: smartCampaignDetailsHtmlUrl,
        controllerAs: 'vm',
        controller: SmartCampaignDetailsController
    })
    .component('smartCampaignPreviewList', {
        bindings: {
            widget: '<',
            smartCampaign: '=',
            onRowClicked: '<'
        },
        templateUrl: smartCampaignDetailsPreviewHtmlUrl,
        controllerAs: 'vm',
        controller: SmartCampaignDetailsPreviewController
    });

/**
 * @ngInject
 */
function SmartCampaignDetailsModalController(
    $q,
    $timeout,
    PubSub,
    $DetailsModalFactoryEvents,
    AppFactory,
    ClientFactory,
    DataSourceType,
    DetailsModalFactory,
    SmartCampaignModelFactory,
    SmartCampaignDetailsModalService,
    SmartCampaignService,
    SmartCampaignResource,
    DesignFactory,
    UIFactory,
    DataSourceFactory
) {
    let vm = this;

    /** Life cycle */
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    /** Events */
    vm.onRowClicked = onRowClicked;
    vm.onSearchPatternChange = _.debounce(_searchCampaignList, 500);

    /** Bindings */
    vm.isClientRequired = !AppFactory.getUser().isSuperAdmin();
    vm.getDataSourceName = getDataSourceName;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getDataSourceColor = getDataSourceColor;
    vm.getCampaignSelectedText = getCampaignSelectedText;
    vm.canSelectClient = canSelectClient;
    vm.checkCanSave = checkCanSave;


    function _setOptions() {
        /**
         * @type {SmartCampaignDataSourceSelectOptions}
         */
        vm.dataSourcesSelectOptions = SmartCampaignModelFactory.getSmartCampaignDataSourceSelectOptions({
            disabled: vm.smartCampaign.data_source_disabled,
            onChange: dataSourceOnChange
        });

        /**
         * @type {SmartCampaignDataViewSelectOptions}
         */
        vm.dataViewSelectOptions = SmartCampaignModelFactory.getSmartCampaignDataViewSelectOptions({
            loaded: true,
            disabled: vm.smartCampaign.data_view_disabled,
            onChange: dataViewOnChange,
            getDataCall: function() {
                let self = this;
                if (vm.smartCampaign && vm.smartCampaign.service_id) {
                    self.loaded = false;
                    return SmartCampaignResource.getDataViews(vm.smartCampaign.service_id);

                } else {
                    let deferred = $q.defer();

                    return deferred.resolve([]);
                }
            },
            formatData: function(data) {
                let self = this;
                self.values = _.map(data, function(dataView) {
                    let dataview =  {
                        id: dataView.id,
                        text: dataView.name,
                        primary_name: dataView.primary_name
                    };

                    if (vm.smartCampaign && (dataview.id === vm.smartCampaign.dataview)) {
                        self.selectedValues = dataview;
                        vm.viewBindings.dataviewName = dataview.text;
                    }
                    return dataview
                });
                self.loaded = true;
            }
        });

        /**
         * @type {SmartCampaignDataViewSelectOptions}
         */
        vm.clientSelectOptions = SmartCampaignModelFactory.getSmartCampaignClientSelectOptions({
            loaded: false,
            onChange: clientOnChange,
            getDataCall: function(query) {
                let self = this;
                self.loaded = false;
                let params = {
                    reporting_status: 'active'
                };

                if (query) {
                    params.q = query;
                }

                return ClientFactory.getFieldValues('id', params);
            },
            formatData: function(data) {
                let self = this;
                self.values = _.map(data.values, function(client) {
                    client.text = client.value;
                    client.id = client.key;

                    if (vm.smartCampaign && (client.id === vm.smartCampaign.customer_id)) {
                        self.selectedValues = client;
                        vm.viewBindings.clientName = client.text;
                    }
                    return client
                });
                self.loaded = true;
            }
        });
        checkCanSave();
    }



    /**
     * @type {SmartCampaignModalState}
     */
    vm.state = SmartCampaignModelFactory.getSmartCampaignModalState();

    /**
     * @type {SmartCampaignModalViewBindings}
     */
    vm.viewBindings = SmartCampaignModelFactory.getSmartCampaignModalViewBindings();

    /**
     * Life cycle
     */

    function $onInit() {
        vm.state.isLoading = true;
        _registerEvents();
        DetailsModalFactory.loadModel().then(function () {
            vm.smartCampaign = SmartCampaignModelFactory.getSmartCampaign(DetailsModalFactory.getModel());
            _setSelectedValues(vm.smartCampaign);
            if (!DetailsModalFactory.getIsNewState()) {
                _updateCampaignList();
            } else {
                vm.state.isLoading = false;
            }

            _setOptions();
        });
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function dataViewOnChange($el) {
        let val = $el ? $el.select2('data') : null;
        if (val) {
            vm.smartCampaign.dataview = val.id;
            vm.viewBindings.dataviewName = val.text;
            vm.state.showSearchInput = true;
        } else {
            vm.smartCampaign.dataview = null;
            vm.viewBindings.dataviewName = 'Data View';
            vm.state.showSearchInput = false;
        }

        vm.viewBindings.datagridWidget = null;
        vm.smartCampaign.mapping = [];

        _updateCampaignListIfNeeded();
        checkCanSave();

    }

    function clientOnChange($el) {
        let val = $el ? $el.select2('data') : null;
        if (val) {
            vm.smartCampaign.customer_id = val.id;
            vm.viewBindings.clientName = val.text;
        } else {
            vm.smartCampaign.customer_id = null;
            vm.viewBindings.clientName = null;
        }

        vm.viewBindings.datagridWidget = null;
        vm.smartCampaign.mapping = [];

        _updateCampaignListIfNeeded();
        checkCanSave();
    }

    function dataSourceOnChange(dataSource) {
        if (vm.state.isLoading
            || vm.smartCampaign.service_id === dataSource.id) {
            return;
        }
        vm.smartCampaign.service_id = dataSource.id;
        vm.smartCampaign.data_source = dataSource;
        vm.smartCampaign.mapping = [];
        vm.viewBindings.type = dataSource.type;

        vm.viewBindings.datagridWidget = null;

        vm.smartCampaign.dataview = null;
        vm.viewBindings.dataviewName = 'Data View';
        vm.state.showSearchInput = false;
        // AngularJs problems.
        angular.element(".smart-campaign-details-modal #smart-campaign-dataview-select div.select2-container").select2('data', null);

        _updateCampaignListIfNeeded();
        checkCanSave();

        vm.dataViewSelectOptions.getDataCall().then(function(data) {
            vm.dataViewSelectOptions.callFormatData(data);
        });
    }

    function onRowClicked() {
        vm.smartCampaign.dynamic_mapping = !vm.smartCampaign.mapping.length
        checkCanSave();
    }

    function getDataSourceName(datasource) {
        let name = DataSourceFactory.getDataSourceName(datasource);
        return _.isEmpty(name) ? 'New Configuration' : name;
    }

    function getDataSourceIcon(datasource) {
        return DesignFactory.getDataSourceIcon(datasource);
    }

    function getDataSourceColor(datasource) {
        return DesignFactory.getDataSourceColor(datasource);
    }

    function getCampaignSelectedText() {
        let selectedText;
        let length;
        if (vm.smartCampaign.dynamic_mapping) {
            selectedText = ' auto selected';
            length =  vm.viewBindings.datagridWidget.metadata.dynamic.predefined_data.data.aaData.length;
        } else {
            selectedText = ' selected';
            length = vm.smartCampaign.mapping.length;
        }
        let text = length === 1 ? length + ' Campaign' : length + ' Campaigns';
        return text + selectedText;
    }

    function canSelectClient() {
        return !AppFactory.getUser().isSuperAdmin();
    }

    function checkCanSave() {
        let allOptionsSelected = true;
        let columnUtil = AppFactory.util.column;

        const metadata = DetailsModalFactory.getMetadata();
        const model = DetailsModalFactory.getModel();
        
        metadata.forEach((column) => {
            if (allOptionsSelected && !columnUtil.isBoolean(column.format)) {
                let columnIsRequired = (column.is_required && (column.field !== 'id' && column.field !== 'icon'))
                    || (column.field === 'customer_id' && vm.isClientRequired);

                if (columnIsRequired && (!vm.smartCampaign[column.field] && !model[column.field])) {
                    if (column.field === 'search_pattern') {
                        allOptionsSelected = !_.isEmpty(vm.smartCampaign.mapping);
                    } else {
                        allOptionsSelected = false;
                    }
                }
            }
        });
        if (allOptionsSelected) {
            DetailsModalFactory.setSaveActive();
        } else if(DetailsModalFactory.getIsSaveButtonActive()) {
            DetailsModalFactory.setSaveInactive();
        }
    }

    /**
     * @private
     */
    function _setSelectedValues(smartCampaign) {
        vm.viewBindings.type = null;
        vm.viewBindings.data_source = {};
        vm.state.showSearchInput = false;

        if (smartCampaign && smartCampaign.id) {
            let services = AppFactory.getConnectedServices();
            let selectedService = _.find(services, {id: smartCampaign.data_source.id});

            if (selectedService) {
                selectedService = angular.copy(selectedService);
                smartCampaign.data_source = selectedService;
                vm.viewBindings.type = DataSourceType.SERVICE_DATA;
                vm.viewBindings.data_source = smartCampaign.data_source;
                vm.state.showSearchInput = true;
            }
        }

        _resetSelects();
    }

    function _resetSelects() {
        vm.viewBindings.resetSelects = true;
        $timeout(function () {
            vm.state.isLoading = false;
            vm.viewBindings.resetSelects = false;
        }, 0);
    }

    function _searchCampaignList() {
        vm.smartCampaign.mapping = [];
        _updateCampaignListIfNeeded();
        checkCanSave();
    }

    function _updateCampaignListIfNeeded() {
        if (!_.isNil(vm.viewBindings.type)
            && !_.isNil(vm.viewBindings.data_source)
            && !_.isNil(vm.smartCampaign.dataview)
            && (vm.smartCampaign.search_pattern.length || vm.smartCampaign.customer_id)) {
            _updateCampaignList();
        } else {
            // due to lodash debounce
            $timeout(function () {
                vm.viewBindings.datagridWidget = null;
            }, 0)
        }
    }

    /**
     * @private
     */
    function _updateCampaignList() {
        vm.viewBindings.datagridWidget = null;
        vm.state.isLoadingCampaignsPreview = true;

        SmartCampaignService.retrievePreviewCampaignDataGridWidget(vm.smartCampaign)
            .then(function (datagridWidget) {
                if (!datagridWidget) {
                    UIFactory.notify.showWarning('No ' + vm.viewBindings.dataviewName + ' found.');
                    return;
                }
                vm.viewBindings.datagridWidget = datagridWidget;
            })
            .finally(function () {
                vm.state.isLoadingCampaignsPreview = false;
            })
    }

    /**
     * Pre-save hook before DetailModalService saves the details
     * @private
     */
    function _preSaveModel(model) {
        vm.state.isLoading = true;

        try {
            model = SmartCampaignService.assignModelValues(model, vm.smartCampaign);
        } catch (error) {
            DetailsModalFactory.cancelSave(error.message);
        }
    }

    /**
     * Post save event from Detail model pipeline
     * @private
     */
    function _postSaveModel() {
        vm.state.isLoading = false;
    }

    function _errorSaveModel() {
        vm.state.isLoading = false;
    }

    function _registerEvents() {
        PubSub.on($DetailsModalFactoryEvents.PRE_SAVE, _preSaveModel);
        PubSub.on($DetailsModalFactoryEvents.POST_SAVE, _postSaveModel);
        PubSub.on($DetailsModalFactoryEvents.POST_ERROR, _errorSaveModel);
    }

    function _unregisterEvents() {
        PubSub.off($DetailsModalFactoryEvents.PRE_SAVE, _preSaveModel);
        PubSub.off($DetailsModalFactoryEvents.POST_SAVE, _postSaveModel);
        PubSub.off($DetailsModalFactoryEvents.POST_ERROR, _errorSaveModel);
    }
}

/**
 * @ngInject
 */
function SmartCampaignDetailsController(
    WidgetFactory,
    DetailsModalFactory,
    TapColorsService
) {
    let vm = this;
    vm.$onInit = $onInit;
    vm.getColumn = getColumn;

    vm.isNew = isNew;
    vm.onColorChange = onColorChange;

    function $onInit() {
        vm.model = DetailsModalFactory.getModel();
        vm.metadata = DetailsModalFactory.getMetadata();

        if (_.isNil(vm.model.color) && DetailsModalFactory.getIsNewState()) {
            vm.model.color = TapColorsService.getRandomColor();
        }
    }

    function onColorChange(newValue) {
        vm.model.color = newValue;
    }

    function isNew() {
        return DetailsModalFactory.getIsNewState();
    }

    /**
     * @param field
     * @returns {any}
     */
    function getColumn(field) {
        return _.find(vm.metadata, {field: field});
    }
}

/**
 * @ngInject
 */
function SmartCampaignDetailsPreviewController(
    $timeout,
    WidgetFactory
) {
    let vm = this;

    /**
     * Default widget state for datagrid
     */
    vm.state = WidgetFactory.getDefaultState();
}

