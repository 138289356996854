import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import DashboardFilterResource from '@/modules/core/dashboard/resources/DashboardFilterResource';

class DashboardFilterService extends BaseService {
  /**
   * @returns {Promise<void>}
   */
  async getFilterOptions() {
    return DashboardFilterResource.getFilterOptions();
  }

  /**
   * @returns {Promise<void>}
   */
  async getFilters() {
    return DashboardFilterResource.getFilters();
  }

  /**
   * @param {DashboardFilters} model
   * @returns {Promise<void>}
   */
  async saveFilters(model) {
    return DashboardFilterResource.saveFilters(model);
  }

  /**
   * @returns {Promise<void>}
   */
  async resetFilters() {
    return DashboardFilterResource.resetFilters();
  }
}

export default new DashboardFilterService();
