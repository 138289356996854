import angular from 'angular';

angular.module('exportbuilder.dashboard.services')
    .factory('ExportBuilderShortcutsModalUIService', ExportBuilderShortcutsModalUIService);

/**
 * @ngInject
 */
function ExportBuilderShortcutsModalUIService(
) {

    var uiState = {
        isActive: false
    };

    return {
        getState: getState,
        setIsActive: setIsActive,
        getIsActive: getIsActive
    };

    function getState() {
        return uiState;
    }

    function setIsActive(value) {
        uiState.isActive = value;
    }

    function getIsActive() {
        return uiState.isActive
    }
}