import { Container, p100 } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function useNonAxesTitleContainer(context) {
  const { chart, root } = context();

  /**
   * Slice charts don't have the topAxesContainer that we can drop elements into.
   * Instead, we need to drop these into a container and then shift that
   * into the first child position
   * @param elements array of elements to add
   */
  function createContainerForNonAxesAndInsert(elements) {
    const userData = chart.value.get(Constant.USER_DATA, {});
    if (userData.TITLE_ADDED) {
      return;
    }

    if (!Array.isArray(elements)) {
      elements = [elements];
    }

    const container = Container.new(root.value, {
      layout: root.value.horizontalLayout,
      width: p100,
    });

    container.children.pushAll(elements);

    chart.value.children.unshift(container);

    // prevent adding the title multiple times
    userData.TITLE_ADDED = true;
    chart.value.set(Constant.USER_DATA, userData);
  }

  function createContainerForPie(title, button) {
    const container = Container.new(root.value, {
      layout: root.value.horizontalLayout,
      width: p100,
    });

    container.children.pushAll([title, button]);

    chart.value.children.unshift(container);
  }

  return {
    createContainerForNonAxesAndInsert,
    createContainerForPie,
  };
}
