'use strict';
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

angular.module('widget.goal.services', [])

    .factory('GoalFactory', GoalFactory);

/**
 *
 * @returns {{init: init}}
 * @constructor
 * @ngInject
 */
function GoalFactory(
    ChartFactory,
    WidgetGoalModelFactory,
) {
    /**
     * @param chartOptions
     * @param columns
     * @param metadata
     */
    let init = function(chartOptions, columns, widget) {

        // Function to parse and format data to comply with series dataProvider structure
        function provideData(json, createChart) {
            let data = _.first(json.data);

            // Setting default values for New Goals
            if (!data.is_old) {
                data.threshold_1 = 20;
                data.threshold_2 = 50;
            }

            const maxVal = data.expected_value * 2;
            const minVal = 0;

            options.valueAxes = [{
                maximum: maxVal,
                minimum: minVal,
                stackType: 'regular',
                axisAlpha: 0,
                gridAlpha: 0,
                strictMinMax: true
            }];


            options.dataProvider = [{
                category: 'Evaluation',
                columnLabel: data.column_label,
                excellent: data.expected_value * ((100 + parseInt(data.threshold_1)) / 100),
                excellentStart: Math.max(Math.min(data.expected_value * ((100 - parseInt(data.threshold_1)) / 100), data.target), 0),
                excellentClass: 'success',
                warning: data.expected_value * ((100 + parseInt(data.threshold_2)) / 100),
                warningStart: Math.max(Math.min(data.expected_value * ((100 - parseInt(data.threshold_2)) / 100), data.target), 0),
                warningClass: 'warning',
                poor: maxVal,
                value: Math.min(parseInt(data.value), maxVal),
                actual_value: data.value,
                expected_value: parseInt(data.expected_value),
                percentage: ((Math.abs(data.value - data.expected_value) / data.expected_value) * 100).toFixed(2),
                target: data.expected_value,
                poorClass: 'danger',
            }];

            options.allEmptyData = false;
            if(data.cycle_start >  moment().unix()) {
                options.allEmptyData = true;
            } else if (_.isEmpty(data.pace_history)) {
                options.hasError = true;
            }

            createChart();
        }

        chartOptions.provideData = provideData;
        // Default options for all parameters
        let options = WidgetGoalModelFactory.getGoalChart(chartOptions);


        ChartFactory.makeChart(options, columns);
    };


    return {
        init: init
    }
}