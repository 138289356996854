'use strict';
import angular from 'angular';
import './scripts/preferences.ctrls';
import './scripts/preferences.directives';
import './scripts/preferences.services';

angular.module('preferences', [
    'preferences.ctrls',
    'preferences.directives',
    'preferences.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('preferences', {
                parent:'layout',
                url: '/preferences',
                controller: 'PreferencesController',
                templateUrl: AppConfig.MAGE_URL + 'preferences/manage_preferences',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('preferences')
                }				})
    }]);