export class DrilldownStoreModel {
  widget;

  drilldownConfigStack;

  currentGroupByIndex;

  groupedColumns;

  constructor(widget) {
    this.widget = widget;
    this.currentGroupByIndex = 0;
    this.drilldownConfigStack = [];
    this.groupedColumns = [];
  }
}
