'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('core.reportingtemplate.ctrls', [])

    .controller('ReportingTemplateListController', ReportingTemplateListController)
    .controller('ReportingTemplateController', ReportingTemplateController);

/**
 * @ngInject
 */
function ReportingTemplateListController($scope, ReportingTemplateFactory) {
}

/**
 * @ngInject
 */
function ReportingTemplateController($scope) {
    $scope.init = function(canEdit) {
        if (!canEdit.bool()) {
            $('#main-form').find('select, input').disableElement();
        }
    };
}