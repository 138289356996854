import { getters, commits, dispatches } from '@/modules/core/app/helpers/store';

export const actions = {
  setWidgetAsRendered({}, widgetId) {
    commits.dashboardExport.setWidgetAsRendered({ widgetId });
    if (getters.dashboardExport.getWidgetsRemaining() === 0) {
      dispatches.export.setExportReady();
    }
  },
  setUnrenderedWidgetIds({}, widgetIds) {
    if (getters.dashboardExport.getWidgetsRemaining() === 0) {
      dispatches.export.setVueBasedChartRender();
    }
    commits.dashboardExport.setUnrenderedWidgetIds({ widgetIds });
  },
};
