import NewGeoChartWidgetRenderService from '@/modules/core/charts/am5/geo/services/GeoChartWidgetRenderService';

export class LegacyGeoChartWidgetRenderService extends NewGeoChartWidgetRenderService {
  /**
   * @var Widget
   */
  _widget;

  /**
   * @var array
   */
  _chartPalette;

  /**
   * @var boolean
   */
  _hasComparisonData;

  constructor(widget, chartPalette = [], hasComparisonData = false, isExporting = false) {
    super(widget.id);
    this._widget = widget;
    this._chartPalette = chartPalette;
    this._hasComparisonData = hasComparisonData;
    this._isExporting = isExporting;
  }

  getWidget() {
    return this._widget;
  }

  getFullChartPalette() {
    return this._chartPalette;
  }

  /**
   * @param columnIndex
   * @returns {Array}
   */
  getChartPalette(columnIndex = null) {
    if (columnIndex !== null) {
      return this._chartPalette[columnIndex % this._chartPalette.length];
    }

    return this._chartPalette;
  }

  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
      hoverColor: this.getChartPalette(0),
    };
  }

  hasComparison() {
    return this._hasComparisonData;
  }

  /**
   * @override
   * @returns {boolean}
   */
  isExporting() {
    return this._isExporting;
  }
}
