import angular from 'angular';

angular.module('design.details.constants', [])
    .constant('DetailsModalSize', DetailsModalSize());

function DetailsModalSize() {
    return {
        SMALL: 'modal-sm',
        MEDIUM: 'modal-md',
        LARGE: 'modal-lg',
        FULL: 'modal-full'
    }
}