export const DataSourceType = {
  ALERT: 'alert',
  BILLING_REPORT: 'billing_report',
  BUY_SHEET: 'buy_sheet',
  BUY_SHEET_CLIENT_FIELD: 'buy_sheet_client_field',
  BUY_SHEET_FLIGHT_FIELD: 'buy_sheet_flight_field',
  BUY_SHEET_LINE_ITEM_FIELD: 'buy_sheet_line_item_field',
  BUY_SHEET_ORDER_FIELD: 'buy_sheet_order_field',
  BUY_SHEET_SIGNATURE: 'buy_sheet_signature',
  CATEGORY_DATA: 'category_data',
  CATEGORY: 'category',
  CLIENT_GROUP: 'client_group',
  CLIENT: 'client',
  CLUSTER: 'cluster',
  CONNECTION_STATUS: 'connection_status',
  CUSTOMER: 'customer',
  EC_TASKS_SUMMARY: 'ec_tasks_summary',
  END_CUSTOMER: 'end_customer',
  EXECUTIVE_SUMMARY_ITEM: 'executive_summary_item',
  EXECUTIVE_SUMMARY: 'executive_summary',
  EXTERNAL_API: 'external_api',
  FLIGHTS_REPORT: 'flights_report',
  FLIGHTS_WITH_TASKS_REPORT: 'flights_with_tasks_report',
  FLIGHT_TASKS_REPORT: 'flight_tasks_report',
  GOAL_DATA: 'goal_data',
  IO_MASTER: 'io_master',
  LAYOUT_LIBRARY: 'layout_library',
  LAYOUT_TAG: 'layout_tag',
  LEADS: 'leads',
  LINE_ITEM: 'lineitem',
  LINE_ITEMS_REPORT: 'line_items_report',
  LINE_ITEMS_WITH_TASKS_REPORT: 'line_items_with_tasks_report',
  LINE_ITEM_TASKS_REPORT: 'line_item_tasks_report',
  MASTER: 'io_master',
  NAMING_CONVENTION: 'naming_convention',
  ORDER: 'order',
  ORDERS_REPORT: 'orders_report',
  ORDERS_WITH_TASKS_REPORT: 'orders_with_tasks_report',
  ORDER_TASKS_REPORT: 'order_tasks_report',
  OTHER: 'other',
  PAGE_LIBRARY: 'page_library',
  PAGE_TAG: 'page_tag',
  PAGE: 'page',
  PRODUCT: 'product',
  SCHEDULED_REPORTS: 'scheduled_reports',
  SEO_WEBSITES: 'seo_websites',
  SERVICE_CLIENT_MAPPING_LOG_RESULT: 'service_client_mapping_log_result',
  SERVICE_CLIENT_MAPPING_LOG: 'service_client_mapping_log',
  SERVICE_CLIENT_MAPPING: 'service_client_mapping',
  SERVICE_DATA: 'service_data',
  SERVICE_ORDER_ITEM: 'service_order_item',
  SERVICE: 'service',
  SMART_CAMPAIGN: 'smart_campaign',
  TAG_VALUE: 'tag_value',
  TAG: 'tag',
  TAP_DB_VIZ: 'tap_db_viz',
  TASK: 'task',
  USER_LOGINS: 'userlogins',
  USER: 'user',
  WIDGET_LIBRARY: 'widget_library',
  WIDGET_TAG: 'widget_tag',
  WIDGET: 'widget',
};
