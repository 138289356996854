"use strict";
import $ from 'jquery';
import _ from 'lodash';
import angular from 'angular';
import EventBus from '../../../../../grok/src/modules/core/app/helpers/EventBus';
import { OnboardingEvent } from '../../../../../grok/src/modules/core/onboarding/onboarding.constants';
import { RefreshLegacyMdsEvent } from "../../../../../grok/src/modules/ta/service/service.constants";

var Core = $.core.connectservices = {
    assignClientModal: null,
    isDashboardAlreadyGenerated: false,
    currentItemName: '', //Used to keep track and re-display service item name in different loading containers
    forceAccountRefresh: false, //After adding a service, we need to ensure an account list refresh eventhough we have items in cache
    // ex: when adding a new account to a service we need to force the new items since the items that are in cache dont contain the new items
    //TODO: needs to be refined because you can add one but delete another and notification wont show up
    newClientAssigned: 0, //True if at least one client has been assigned
    //Caching variables
    currentCacheKey: '', //This is used to access the item list, if present, in the localStorage or In-Cache memory
    refreshListTimeout: null, //This is only used for updating the time ago text while you have modal open
    //Large dataset variables
    isTrimmedItemList: false, //If account list has more than 500 items, we show a trimmed list and display accordingly
    trimmedListChanged: false, //Used to avoid useless rerendering of of account items
    itemLimit: 500, //This decides how many items to show at a time in large datasets
    doNotUseCache: false,
    selectedItems: [], //Stores the selected items
    mainItemName: '',
    brandMappings: {},
    showServiceConnection: function (serviceId) {
        var $button = $(".disconnect.oauth[data-service-id='" + serviceId + "']");
        if (!$button.length) {
            $button = $(".disconnect.form-auth[data-service-id='" + serviceId + "']")
        }
        $button.trigger("click");
    },
    initialize: function (isDashboardAlreadyGenerated, serviceId) {
        Core.isDashboardAlreadyGenerated = isDashboardAlreadyGenerated;
        this.build();
        this.events();

        // load brand mappings
        $.getJSON($.fn.apiUrl('branding/mappings'), '', function(result) {
            Core.brandMappings = result.data;
            //If serviceId is set, it means that that service assign client modal should be open by default
            if (serviceId > 0) {
                var $serviceBox = $('div.service-square[data-service-id=' + serviceId + ']');
                var $assignClientModal = $('a.assign-clients[data-service-id=' + serviceId + ']');
                var serviceIsConnected = $assignClientModal.length;
                if ($serviceBox.length) {
                    var topOffset = $serviceBox.offset().top - (window.isNUI ? 75 : 0);
                    $('#right-frame').animate({
                        scrollTop: topOffset
                    }, (serviceIsConnected ? 10 : 800), function() {
                        if (serviceIsConnected) {
                            $assignClientModal.click();
                        }
                        else {
                            $serviceBox.addClass('animated tada');
                            $.core.main.notify(
                                'Click the service square to start mapping ' + Core.brandMappings.client.pluralize() + '. ',
                                $.globals.notify.success,
                                {element: $serviceBox, elementPosition: 'bottom center'}
                            );
                        }
                    });
                }
            }
        });

    },
    redirectToLogin: function (json) {
        $.core.main.hideAllNotifies();
        $.core.main.hideModal($('.modal'));
        $.core.main.formErrorNotify(json.message);
        window.location = $.fn.appUrl('#/' + json.state);
    },
    refreshServiceListMenu: function (json) {
        $.get($.fn.actionUrl('layout/servicelistmenu'), function(html) {
            $('#datasources-submenu').html(html);
        });
    },
    build: function () {
    },
    events: function () {
        this.isotopeBindings(); //Bindings for service square filtering
        this.updateDashboardBinding(); //Binding for big update dashboard button
        this.disconnectServiceBindings(); //Bindings for disconnect service circle on service square
        this.assignClientModalBindings(); //Bindings to trigger the assign client modal
        this.addAccountBinding(); //Binding for add account button in add account form
        this.editAccountBinding(); //Binding for edit account button in edit account form
        this.addClientBinding(); //Binding for add client button in new client form
        this.unassignConfirmBinding(); //Binding for unassign confirm button
        this.addSmartConnectorMarketplace();
        this.setupNuiEvents();
    },
    buildRows: function() {
    },
    isotopeBindings: function() {
        var $container = $('div.connect-container');
        var $imprt = $('div.i-container');
        var $imprtHeader = $('#i-header');
        var $instantOn = $('div.io-container');
        var $instantOnHeader = $('div.io-header');
        var $importWizardMarket = $('div.iwmk-container');
        var $importWizardMarketHeader = $('#iwmk-header');

        $container.isotope({
            itemSelector: '.service-square',
            percentPosition: true,
            getSortData: {
                name: '[data-service-name-filter]'
            }
        });

        $importWizardMarket.on('arrangeComplete', function(event, filteredItems) {
            if (filteredItems.length) {
                if ($importWizardMarket.find('.no-serv-notify-iwmk').length) {
                    $('.no-serv-notify-iwmk').remove();
                }
            } else {
                if (!($importWizardMarket.find('.no-serv-notify-iwmk').length)) {
                    $importWizardMarket.append("<p class='no-serv-notify-iwmk' style='margin-left:20px; margin-top:8px'>No Results Found</p>");
                }
            }
        });

        $importWizardMarketHeader.on('click', function() {
            $importWizardMarket.toggle('fast');
            if ($importWizardMarketHeader.find('.expand-services').css('display') === 'none') {
                $importWizardMarket.css({'min-height': '0'});
                $importWizardMarketHeader.find('.expand-services').css({'display': 'block'});
                $importWizardMarketHeader.find('.collapse-services').css({'display': 'none'});
            } else {
                $importWizardMarket.css({'min-height': '55px'});
                $importWizardMarketHeader.find('.expand-services').css({'display': 'none'});
                $importWizardMarketHeader.find('.collapse-services').css({'display': 'block'});
            }
        });

        $imprt.on( 'arrangeComplete', function( event, filteredItems){
            if( filteredItems.length){
                if( $imprt.find('.no-serv-notify-i').length){
                    $('.no-serv-notify-i').remove();
                }
            } else {
                if( !($imprt.find('.no-serv-notify-i')).length){
                    $imprt.append("<p class='no-serv-notify-i' style='margin-left:20px; margin-top:8px'> No Results Found </p>");
                }
            }
        });

        $imprtHeader.on('click', function(){
            $imprt.toggle('fast');
            if($imprtHeader.find('.expand-services').css('display') === 'none'){
                $imprt.css({'min-height': '0'});
                $imprtHeader.find('.expand-services').css({'display': 'block'});
                $imprtHeader.find('.collapse-services').css({'display': 'none'});
            } else {
                $imprt.css({'min-height': '55px'});
                $imprtHeader.find('.expand-services').css({'display': 'none'});
                $imprtHeader.find('.collapse-services').css({'display': 'block'});
            }
        })

        $instantOn.on( 'arrangeComplete', function( event, filteredItems){
            if( filteredItems.length){
                if( $instantOn.find('.no-serv-notify-io').length){
                    $('.no-serv-notify-io').remove();
                }
            } else {
                if( !($instantOn.find('.no-serv-notify-io')).length){
                    $instantOn.append("<p class='no-serv-notify-io' style='margin-left:20px; margin-top:8px'> No Results Found </p>");
                }
            }
        });

        $instantOnHeader.on('click', function(){
            $instantOn.toggle('fast');
            if($instantOnHeader.find('.expand-services').css('display') === 'none'){
                $instantOn.css({'min-height': '0'});
                $instantOnHeader.find('.expand-services').css({'display': 'block'});
                $instantOnHeader.find('.collapse-services').css({'display': 'none'});
            } else {
                $instantOn.css({'min-height': '55px'});
                $instantOnHeader.find('.expand-services').css({'display': 'none'});
                $instantOnHeader.find('.collapse-services').css({'display': 'block'});
            }
        })

        var $sortServices = $('#service-sort');
        $sortServices.find('button').click(function() {
            $sortServices.find('.btn.active').removeClass('active');
            var sortOptions = {};
            if ($(this).data('sortReset') != null) {
                sortOptions.sortBy = 'original-order';
                sortOptions.sortAscending = true;
            }
            else {
                sortOptions.sortBy = 'name';
                sortOptions.sortAscending = $(this).data('sortAscending');
                $(this).addClass('active');
            }
            $container.isotope('updateSortData').isotope(sortOptions);
        });
        var $serviceFilters = $('#service-filters');
        $serviceFilters.find('button').click(function() {
            $serviceFilters.find('.btn.active').removeClass('active');
            $(this).addClass('active');
            $container.isotope({ filter: filterFn['serviceName'] });
        });
        var $serviceSearch = $('#service-search');
        const debounceTextChange = _.debounce(() => {
            $container.isotope({ filter: filterFn['serviceName']})
        }, 500);
        $serviceSearch.bind('textchange', function() {
            debounceTextChange();
        });
        // filter functions
        var filterFn = {
            serviceName: function(element) {
                var $serviceTile = $(element);
                var searchText = $serviceSearch.val().classify();
                var searchFilter = $serviceFilters.find('button.active').data('filter');
                var serviceName = $serviceTile.data('serviceNameFilter').toString();
                var isSearching = searchText.length > 2;
                //Showing only connected
                if (searchFilter == 'active') {
                    $importWizardMarket.hide();
                    $importWizardMarketHeader.hide();

                    $importWizardMarketHeader.find('.expand-services').css({'display': 'none'});
                    $importWizardMarketHeader.find('.collapse-services').css({'display': 'block'});

                    $imprt.hide();
                    $imprt.show();

                    $imprtHeader.find('.expand-services').css({'display': 'none'});
                    $imprtHeader.find('.collapse-services').css({'display': 'block'});

                    $instantOn.hide();
                    $instantOn.show();

                    $instantOnHeader.find('.expand-services').css({'display': 'none'});
                    $instantOnHeader.find('.collapse-services').css({'display': 'block'});

                    return isSearching
                        ? serviceName.contains(searchText) && $serviceTile.hasClass(searchFilter)
                        : $serviceTile.hasClass(searchFilter);
                }
                //Showing all services
                else {
                    $importWizardMarket.show();
                    $importWizardMarketHeader.show();

                    $importWizardMarketHeader.find('.expand-services').css({'display': 'none'});
                    $importWizardMarketHeader.find('.collapse-services').css({'display': 'block'});

                    $imprt.hide();
                    $imprt.show();

                    $imprtHeader.find('.expand-services').css({'display': 'none'});
                    $imprtHeader.find('.collapse-services').css({'display': 'block'});

                    $instantOn.hide();
                    $instantOn.show();

                    $instantOnHeader.find('.expand-services').css({'display': 'none'});
                    $instantOnHeader.find('.collapse-services').css({'display': 'block'});

                    if (serviceName != null) {
                        return isSearching
                            ? serviceName.contains(searchText)
                            : true;
                    }
                }
            }
        };
    },
    updateDashboardBinding: function() {
        //This has been moved to manageservices.directives.js
    },
    disconnectServiceBindings: function() {
        $('a.disconnect').hover(function() {
            $(this).find('span.icon').removeClass('icomoon-plug').addClass('icomoon-settings');
        }, function() {
            $(this).find('span.icon').removeClass('icomoon-settings').addClass('icomoon-plug');
        });
        //Bring up manage connections modal
        $('a.disconnect.oauth, a.disconnect.form-auth').click(function() {
            var $modal = $('#manage-connections-modal');
            $modal.find('.modal-title').html('<div class="service-square service-square-32 ' + $(this).data('serviceIcon') + '"><div class="icon serviceicon-' + $(this).data('serviceIcon') +' "></div></div>Manage connections');
            $modal.find('div.modal-body').html($('#manage-connections-template').render());
            setTimeout(function () {
                $modal.modal('show');
            }, 0);
            var serviceId = $(this).data('serviceId');
            var serviceName = $(this).data('serviceName');
            var customerId = $(this).data('customerId');
            var oauthUrl = $(this).data('oauthUrl');
            var authType = $(this).data('serviceAuthType');

            if (authType != 'TapAPI' && authType != 'Google BigQuery') {
                $modal.find('#disconnect-all-button').prop('disabled', false);
                $modal.find('#disconnect-button').prop('disabled', false);
            } else {
                $modal.find('#disconnect-all-button').prop('disabled', true);
                $modal.find('#disconnect-button').prop('disabled', true);
            }

            var editButton = $modal.find('#edit-button');
            editButton.prop('disabled', true);
            if (oauthUrl) {
                editButton.text('Re-authenticate');
            } else {
                editButton.text('Edit');
            }

            $.get($.fn.actionUrl('setup/get_connections'), {
                service_id: serviceId
            }, function (json) {
                editButton.prop('disabled', false);
                if (json.status == 'success') {
                    var connections = json.data;
                    if (connections.length) {
                        //Remove loading text
                        $('select#connection-list').empty();
                        $.each(connections, function (i, connection) {
                            var plural = connection.mapped_items == 1 ? '' : 's';
                            let isPaused = connection.is_fetch_enabled == 1 ? '' : ' (paused data flow)';
                            $('select#connection-list').append('<option value="' + connection.id + '">' + connection.name + isPaused + ' (' + connection.mapped_items + ' assigned client' + plural + ')' + '</option>');
                        });
                    }
                }
                else if (json.status == 'redirect') {
                    Core.redirectToLogin(json);
                }
                else {
                    $.core.main.formErrorNotify(json);
                }
            });
            //Pass serviceId to confirm buttons at time of disconnect service confirmation
            $('#disconnect-confirm').data('serviceId', serviceId);
            $('#disconnect-all-button').data('serviceName', serviceName);
            $('#disconnect-all-confirm').data('serviceId', serviceId);

            $('#edit-button').off('click').on('click', function() {
                if (oauthUrl) {
                    window.location = oauthUrl + '?from_connection_id=' + $('select#connection-list option:selected').val();
                    return;
                }
                $.get($.fn.actionUrl('setup/masterform'), {
                    service_id: serviceId,
                    customer_id: customerId,
                    connection_id: $('select#connection-list option:selected').val(),
                    edit: true
                }, function(data) {
                    var $editAccountModal = $('#edit-account-modal');
                    $editAccountModal.find('div.modal-body').html(data);
                    $editAccountModal.find('#account-name').html($('select#connection-list option:selected').text());

                    $.core.main.showModal($editAccountModal);
                    $.core.main.tooltip($editAccountModal);
                });
            });
        });
        //Binding for disconnect button once user has made choice on account
        $('#disconnect-button').click(function() {
            var $modal = $('#disconnect-confirm-modal');
            $modal.modal('show');
            $modal.find('b.account-name-placeholder').html($('select#connection-list').find('option:selected').text());
        });
        //Disconnect account confirmation button
        $('#disconnect-confirm').click(function() {
            var $this = $(this);
            $this.disableElement();
            var serviceId =  $(this).data('serviceId');
            var $connectionList = $('select#connection-list');
            var $selected = $connectionList.find('option:selected');
            $.post($.fn.actionUrl('setup/delete_individual_connection'), {
                connection_id: $connectionList.val()
            }, function (json) {
                $this.enableElement();
                if (json.status == 'success') {
                    //Remove service's account list from cache
                    amplify.removeFromCache('account_list_' + serviceId);
                    $this.enableElement();
                    $selected.remove();
                    if ($connectionList.find('option').length == 0) {
                        disconnectAllDone(serviceId);
                    }
                    else {
                        $.core.main.notify(' was successfully disconnected', $.globals.notify.success, {title: $selected.text()});
                    }
                    //Refresh service list menu
                    Core.refreshServiceListMenu();
                }
                else if (json.status == 'redirect') {
                    Core.redirectToLogin(json);
                }
                else {
                    $.core.main.notify(' could not be deleted', $.globals.notify.error, {title: $('select#connection-list option:selected').text()});
                }
            });
        });
        //Binding for disconnect all accounts for a service
        $('#disconnect-all-button').click(function() {
            var $modal = $('#disconnect-all-confirm-modal');
            $modal.modal('show');
            $modal.find('b.service-name-placeholder').html($(this).data('serviceName'));
        });
        //Disconnect all accounts confirmation button
        $('#disconnect-all-confirm').click(function() {
            var $this = $(this);
            $this.disableElement();
            var serviceId =  $(this).data('serviceId');
            $.post($.fn.actionUrl('setup/delete_all_connections'), {
                service_id: serviceId
            }, function (json) {
                $this.enableElement();
                if (json) {
                    //Remove service's account list from cache
                    amplify.removeFromCache('account_list_' + serviceId);
                    disconnectAllDone(serviceId);
                    //Refresh service list menu
                    Core.refreshServiceListMenu();
                }
                else {
                    $.core.main.notify('Accounts could not be deleted', $.globals.notify.error);
                }
            });
        });
        function disconnectAllDone(serviceId) {
            $('#disconnect-service-confirm').modal('hide');
            $('#manage-connections-modal').modal('hide');
            $.core.main.notify('You have disconnected all accounts for this data source', $.globals.notify.success);
            Core.disableServiceSquareState($('div.service-square[data-service-id="' + serviceId + '"]'));
        }
    },
    disableServiceSquareState: function($elm) {
        $elm.removeClass('active').addClass('inactive');
        $elm.find('a.is-connected-badge').remove();
        var connectionType = $elm.data('connectionType');
        var clientId = $elm.data('clientId');
        var serviceId = $elm.data('serviceId');
        //Setup the connection method (oauth, simple ftp, etc...)
        switch (connectionType) {
            case $.globals.service.oauth:
                $elm.find('a.option').replaceWith($('#oauth-connect-option-template').render({url: $.fn.actionUrl('oauth/connectservice/client_id/' + clientId + '/service_id/' + serviceId)}));
                break;
            case $.globals.service.simple:
                $elm.find('a.option').replaceWith($('#simple-connect-option-template').render({url: $.fn.actionUrl('setup/masterform/client_id/' + clientId + '/service_id/' + serviceId)}));
                //Assign binding to newly created simple connect option
                $elm.find('a.option.simple').click(function() {
                    $(this).data('isOauth', false);
                    $(this).data('serviceId', serviceId);
                    $(this).data('clientId', clientId);
                    Core.connectNewAccountSetup($(this));
                });
                break;
        }
    },
    assignClientModalBindings: function() {
        $('a.assign-clients').click(function(e) {
            Core.forceAccountRefresh = !e.hasOwnProperty('originalEvent');
            var serviceId = $(this).data('serviceId');
            var clientId = $(this).data('clientId');
            var showSubAccounts = $(this).data('showSubAccounts').bool();
            Core.serviceName = $(this).data('serviceName');
            var service = {};
            service.serviceId = serviceId;
            service.showSubAccounts = $(this).data('showSubAccounts');
            service.serviceIcon = $(this).data('serviceIcon');
            service.serviceName = $(this).data('serviceName');
            service.serviceColor = $(this).data('serviceColor');
            service.serviceItemGroupName = $(this).data('serviceItemGroupName');
            service.serviceItemGroupNamePlural = $(this).data('serviceItemGroupNamePlural');
            service.serviceItemPrefix = $(this).data('serviceItemPrefix');
            service.brandMappings = Core.brandMappings;
            service.serviceItemGroupName = service.serviceItemGroupName === 'client'
                ? service.brandMappings.client
                : service.serviceItemGroupName;
            //Show the modal right away with top level info to avoid delay when clicking assign clients
            //Then subsequently load the account list
            var $modal = $('#assign-client-modal');
            $modal.html($('#assign-client-template').render(service));
            $.core.main.showModal($modal);
            Core.assignClientModal = $modal;
            //Store some data for later use
            Core.assignClientModal.data('mapperType', 'master');
            Core.assignClientModal.data('serviceId', serviceId);
            //Used to display warning notification for updating dashboard when closing modal
            Core.assignClientCloseBinding($modal);
            Core.assignClientBinding(); //Binding for assign client button
            $.get($.fn.actionUrl('setup/masterservicemapper'), {
                service_id: serviceId,
                client_id: clientId
            }, function(data) {
                Core.doNotUseCache = false;
                $('#account-list-container').html(data);
                $.core.main.tooltip($('#account-list-header'), {placement: 'top'});
                Core.connectNewAccountBinding(); //Binding for connect another account button
                Core.refreshAccountListBinding();
                Core.getAccountList(serviceId, service.serviceItemGroupNamePlural, showSubAccounts,false);
            });
        });
        $('a.assign-clients-individual').click(function() {
            var serviceId = $(this).data('serviceId');
            var clientId = $(this).data('clientId');
            var service = {};
            service.serviceId = serviceId;
            service.serviceIcon = $(this).data('serviceIcon');
            service.serviceName = $(this).data('serviceName');
            service.serviceColor = $(this).data('serviceColor');
            service.serviceItemPrefix = $(this).data('serviceItemPrefix');
            service.serviceItemGroupName = Core.brandMappings.client;
            service.serviceItemGroupNamePlural = 'clients';
            service.brandMappings = Core.brandMappings;
            //Show the modal right away with top level info to avoid delay when clicking assign clients
            //Then subsequently load the account list
            var $modal = $('#assign-client-modal');
            $modal.html($('#assign-client-individual-template').render(service));
            $.core.main.showModal($modal);
            Core.assignClientModal = $modal;
            //Store some data for later use
            Core.assignClientModal.data('mapperType', 'individual');
            Core.assignClientModal.data('serviceId', serviceId);
            Core.addUrlBinding(); //Binding for add url input button
            Core.submitUrlBinding(); //Binding for submit url button
            //Used to display warning notification for updating dashboard when closing modal
            Core.assignClientCloseBinding($modal);
            Core.currentCacheKey = 'account_list_' + serviceId;
            $.get($.fn.actionUrl('setup/individualservicemapper'), {
                service_id: serviceId,
                client_id: clientId
            }, function(data) {
                Core.doNotUseCache = false;
                $('#account-list-container').html(data).css('height', 'auto');
                $('#account-list-header').showV();
                $('#account-list-footer').showV();
                Core.refreshAccountListBinding();
                Core.getAccountList(serviceId, service.serviceItemGroupNamePlural, false,false);

                //Create new client binding
                Core.createNewClientBinding();
                //
                //Filter options
                //
                $('#account-list-search').bind('textchange', Core.updateAccountList);
            });
        });
        $('a.connect.simple').click(function() {
            Core.connectNewAccountSetup($(this));
        });
        $('a.connect').click(function() {
           const cannotConnectMessage = $(this).data('cannotConnectMessage');
           if (cannotConnectMessage) {
               $.core.main.notify(cannotConnectMessage);
               return false;
           }
        });
    },
    connectNewAccountBinding: function() {
        Core.assignClientModal.find('a.add-account').click(function() {
            const cannotConnectMessage = $(this).data('cannotConnectMessage');
            if (cannotConnectMessage) {
                $.core.main.notify(cannotConnectMessage);
                return false;
            }
            Core.connectNewAccountSetup($(this));
        });
    },
    connectNewAccountSetup: function($elm) {
        //Oauth redirect
        if ($elm.data('isOauth')) {
            window.location = $elm.data('url');
        }
        else {
            var serviceId = $elm.data('serviceId');
            var clientId = $elm.data('clientId');
            var notificationOnly = $('div[data-service-id="'+ serviceId +'"]').attr('data-notification-only');
            $.get($.fn.actionUrl('setup/masterform'), {
                service_id: serviceId,
                client_id: clientId
            }, function(data) {
                var $addAccountModal = notificationOnly ? $('#notification-modal') : $('#add-account-modal');
                $addAccountModal.find('div.modal-body').html(data);
                $.core.main.showModal($addAccountModal);
                $.core.main.tooltip($addAccountModal);
            });
        }
    },
    addAccountBinding: function() {
        $('#add-account').click(function() {
            var $this = $(this);
            $this.disableElement();
            var $form = $('#add-account-form');
            var serviceId = $form.find('input[name=service_id]').val();
            $.post($.fn.actionUrl('setupaction/masterform'), $form.serializeObject(), function(json){
                $this.enableElement();
                if (json.status == 'success') {
                    $.core.main.updateOnboarding(2);
                    //Check if url is already being used for the current service, if so, simply reload
                    if ($.url('#') == '/service/manage/' + serviceId) {
                        window.location.reload();
                    }
                    else {
                        $.core.main.hideModal($('#assign-client-modal'));
                        $.core.main.hideModal($('#add-account-modal'));
                        $.core.main.hideAllNotifies();
                        window.location = $.fn.appUrl('#/service/manage/' + serviceId);
                    }
                    let angularAppInjector = angular.element(document.body).injector();
                    if (!angularAppInjector) {
                        angularAppInjector = angular.element('#legacy-app').injector();
                    }
                    const AppFactory = angularAppInjector.get('AppFactory');
                    AppFactory.refreshServices()
                }
                else if (json.status == 'redirect') {
                    Core.redirectToLogin(json);
                }
                else {
                    $.core.main.formErrorNotify(json);
                }
            });
        });
    },
    editAccountBinding: function() {
        $('#edit-account').on('click', function () {
            var $modal = $('#edit-confirm-modal');
            var $form = $('#edit-account-form');
            var selected = $('select#connection-list option:selected');

            $form.find('input[name=connection_id]').val(selected.val());
            $modal.find('b.account-name-placeholder').html(selected.text());
            $modal.modal('show');
        });

        $('#edit-confirm').click(function () {
            var $this = $(this);
            $this.disableElement();
            var $modal = $('#edit-confirm-modal');
            var $form = $('#edit-account-form');
            var serviceId = $form.find('input[name=service_id]').val();
            $.post($.fn.actionUrl('setup/edit_connection'), $form.serializeObject(), function(json){
                $this.enableElement();
                $modal.modal('hide');
                if (json.status == 'success') {
                    //Check if url is already being used for the current service, if so, simply reload
                    if ($.url('#') == '/service/manage/' + serviceId) {
                        window.location.reload();
                    }
                    else {
                        $.core.main.hideModal($('#edit-account-modal'));
                        $.core.main.hideAllNotifies();
                        window.location = $.fn.appUrl('#/service/manage/' + serviceId);
                    }
                }
                else if (json.status == 'redirect') {
                    Core.redirectToLogin(json);
                }
                else {
                    $.core.main.formErrorNotify(json);
                }
            });
        });
    },
    createNewClientBinding: function() {
        Core.assignClientModal.find('a.create-client').click(function() {
            $.get($.fn.actionUrl('setup/createadvertiser'), {
                service_id: $(this).data('serviceId')
            }, function(data){
                var $newClientModal = $('#create-client-modal');
                $newClientModal.find('div.modal-body').html(data);
                $.core.main.showModal($newClientModal);
            });
        });
    },
    addClientBinding: function() {
        $('#add-client').click(function() {
            var $this = $(this);
            $this.disableElement();
            var $form = $('#create-client-form');
            var $newClientModal = $('#create-client-modal');
            $.get($.fn.actionUrl('setupaction/createadvertiser'), $form.serializeObject(), function(json){
                $this.enableElement();
                if (json.status == 'success') {
                    $.core.main.hideAllNotifies();
                    $.core.main.hideModal($newClientModal);
                    $.core.main.notify('was successfully added', $.globals.notify.success, {title: $form.find('input[name=company_name]').val()});
                    var fromService = $form.find('input[name=from_service]').val();
                    if (fromService > 0) {
                        $('a.assign-clients-individual[data-service-id=' + fromService + ']').click();
                    }
                }
                else if (json.status == 'redirect') {
                    Core.redirectToLogin(json);
                }
                else {
                    $.core.main.formErrorNotify(json);
                }
            });
        });
    },
    refreshAccountListBinding: function() {
        $('#refresh-account-list').click(function() {
            $(this).disableElement();
            amplify.removeFromCache('account_list_' + $(this).data('serviceId'));
            //If at step 2, go back to step 1
            var $modalBody = Core.assignClientModal.find('div.modal-body');
            var $step1 = $modalBody.find('div.step-1');
            var $step2 = $modalBody.find('div.step-2');
            if ($step2.is(':visible')) {
                Core.setupStep1($step1, $step2);
            }
            //If anything is inputted clear the search filter
            $('#account-list-search').val('');
            $('#connection-select').prop('selectedIndex',0);
            Core.clearAllSelected(null);
            Core.getAccountList($(this).data('serviceId'), $(this).data('serviceItemGroupNamePlural'), $(this).data('showSubAccounts').bool(),true);
        });
        //Update the time ago dynamically while working on modal
        Core.refreshListTimeout = setInterval(function() {
            //If somehow user closes modal without using the data-dismiss function (i.e. pressing ESC), this will ensure to clear the interval
            if (Core.assignClientModal.is(':hidden')) {
                clearInterval(Core.refreshListTimeout);
            }
            else {
                Core.refreshTimeAgoText();
            }
        }, 65000); //Will refresh text every minute and 5 seconds to make sure we passed the minute
    },
    refreshTimeAgoText: function() {
        var cachedData = amplify.getFromCache(Core.currentCacheKey);
        var text = cachedData
            ? Core.currentItemName + ' were last updated: <b>' + $.timeago(cachedData.last_update) + '</b>'
            : Core.currentItemName + ' are currently being updated';
        $('#refresh-account-list-container span.placeholder').html(text);
    },
    loadMoreItemsBinding: function() {
        $('#load-more-items').click(function() {
            var mappedFilter = $('#toggle-mapped-clients').find('input:radio[value="unmapped"]').is(':checked') ? 'unmapped' : ($('#toggle-mapped-clients').find('input:radio[value="mapped"]').is(':checked') ? 'mapped' : null);
            var newFilter = $('#toggle-new-clients').find('input:radio[value="not_new"]').is(':checked') ? 'notnew' : ($('#toggle-new-clients').find('input:radio[value="new"]').is(':checked') ? 'new' : null);

            var $accountList = $('#account-list');
            var numItems = $accountList.find('div.list-group.item-list > a.list-group-item').length;
            var cachedData = amplify.getFromCache(Core.currentCacheKey);
            var connectionSelectValue = $('#connection-select option:selected').val();
            if (connectionSelectValue != null && connectionSelectValue != -1) {
                cachedData.account_list = cachedData.account_list.filter(
                    account => account.connection_id.contains(`|${connectionSelectValue}|`));
            }
            var accountListSearchValue = $('#account-list-search').val().toLowerCase();
            if (accountListSearchValue.length > 2 && !Core.doNotUseCache) {
                cachedData.account_list = cachedData.account_list.filter(function(item) { return item.label != null && item.label.toLowerCase().contains(accountListSearchValue)});
            }
            if (mappedFilter) {
                cachedData.account_list = cachedData.account_list.filter(function(item) { return item.selected === (mappedFilter === 'mapped') });
            }
            if (newFilter) {
                cachedData.account_list = cachedData.account_list.filter(function(item) { return item.new === (newFilter === 'new') });
            }
            if (numItems < cachedData.account_list.length) {
                cachedData.account_list = cachedData.account_list.slice(numItems, numItems + Core.itemLimit);
                var showSubAccounts = cachedData.service.mapper_shows_sub_accounts;
                var subAccountName = cachedData.service.mapper_sub_account_name;
                //Bindings will be applied to all elements after the previous last element
                var $lastItem = $accountList.find('a.list-group-item:last');
                var accountListItemTemplate = '#account-list-item-' + (Core.assignClientModal.data('mapperType') === 'individual' ? 'individual-' : '') + 'template';
                $.each(cachedData.account_list, function(i, val) {
                    $accountList.find('div.list-group.item-list').append($(accountListItemTemplate).render(val, {
                        show_sub_accounts: showSubAccounts,
                        sub_account_name: subAccountName
                    }));
                });
                //Bind the newly appended items
                Core.itemBindings($lastItem.nextAll(), showSubAccounts);
            }
            else {
                //No more items to load
                $(this).hide();
            }
        });
    },
    //
    // Modal shows up and assigning begins
    //
    getAccountList: function(serviceId, itemName, showSubAccounts, isRefreshing) {
        var $accountList = $('#account-list');
        //Insert loading template
        $accountList.html($('#account-list-loading-template').render({itemName: itemName}));
        //If more than 10 seconds to load show delay message
        var delay = setTimeout(function() {
            $accountList.find('div.delay-text').show();
            $accountList.find('div.delay-icon').addClass('wobble');
        }, 10000);
        var cacheKey = Core.currentCacheKey = 'account_list_' + serviceId;
        Core.currentItemName = itemName;
        //If localStorage or In-Memory cache contains the data
        if (amplify.lookupCache(cacheKey) && !Core.forceAccountRefresh) {
            showAccountList(amplify.getFromCache(cacheKey), serviceId, showSubAccounts);
        }
        else {
            let actionUrl = 'setup/' + (Core.assignClientModal.data('mapperType') === 'individual' ? 'individual' : 'master') + 'servicemapper_account_list';
            $.get($.fn.actionUrl(actionUrl), {
                service_id: serviceId,
                client_id: 0,
                refreshing: isRefreshing ? 1 : 0
            }, function(json) {
                if (json.status == 'success') {
                    var data = json.data;
                    data.last_update = new Date(); //Store time to keep track of cache age
                    amplify.safeStore(cacheKey, data);
                    showAccountList(data, serviceId, showSubAccounts);
                }
                else if (json.status == 'redirect') {
                    Core.redirectToLogin(json);
                }
                else {
                    $.core.main.formErrorNotify(json);
                }
            });
        }
        function showAccountList(data, serviceId, showSubAccounts) {
            //Make sure account list returned is for the service currently being shown
            //(Sometimes if another service is still fetching in the background we dont want it to squash what were currently looking at)
            if (serviceId == Core.assignClientModal.data().serviceId) {
                $('#refresh-account-list').enableElement();
                $('#refresh-account-list-container').showV();
                Core.refreshTimeAgoText();
                $accountList.empty();
                clearTimeout(delay);
                //Found in the modal panel-body
                $('#account-list-header').showV();
                $('#account-list-footer').showV();
                Core.isTrimmedItemList = data.account_list.length > Core.itemLimit;
                if (Core.isTrimmedItemList) {
                    $('#account-list-search').addClass('has-tooltip persist');
                    $('#account-list-search').attr('title', 'Due to the large number of items, you need to input at least 3 letters to start searching');
                    $.core.main.tooltip($('#account-list-search').parent(), {placement: 'top', trigger: 'focus'});
                    Core.displayFirstAccountItems();
                }
                else {
                    var accountListTemplate = '#account-list-' + (Core.assignClientModal.data('mapperType') === 'individual' ? 'individual-' : '') + 'template';
                    $accountList.html($(accountListTemplate).render(data));
                    //This may also include the sub-item list
                    var $itemList = $accountList.find('a.list-group-item');
                    Core.itemBindings($itemList, showSubAccounts);
                }
                //Show errors
                if (data.connection_errors.length > 0) {
                    var connectionErrorArray = $.map(data.connection_errors, function (val) {
                        return 'Error fetching data for connection #' + val.connection_id + ' (' + val.username + '): ' + val.error;
                    });
                    $.core.main.formErrorNotify({data: connectionErrorArray});
                }
                //For the unassign buttons tooltip
                $.core.main.tooltip($accountList, {placement: 'top'});
            }
            EventBus.signal("DataSourceViewEvent", {datasource: {...data, name: Core.serviceName, id: serviceId}});
        }
        //
        //Filter options
        //
        $('#connection-select').change(Core.updateAccountList);
        $('#account-list-search').bind('textchange', Core.updateAccountList);
        $('#toggle-mapped-clients').find('input[type=radio]').change(Core.updateAccountList);
        $('#toggle-new-clients').find('input[type=radio]').change(Core.updateAccountList);
    },
    displayFirstAccountItems: function() {
        var trimmedData = amplify.getFromCache(Core.currentCacheKey);
        trimmedData.account_list = trimmedData.account_list.slice(0, Core.itemLimit);
        var $accountList = $('#account-list');
        var accountListTemplate = '#account-list-' + (Core.assignClientModal.data('mapperType') === 'individual' ? 'individual-' : '') + 'template';
        $accountList.html($.parseHTML($(accountListTemplate).render(trimmedData)));
        Core.itemBindings($accountList.find('a.list-group-item'), trimmedData.service.mapper_shows_sub_accounts);
        $('<div id="load-more-items" class="list-group center"><div class="list-group-item"><h4>Click to load more ' + Core.currentItemName + '</h4><div/></div>').insertAfter($accountList.find('div.item-list'));
        Core.loadMoreItemsBinding();
    },
    updateAccountList: function() {
        var connectionSelectValue = $('#connection-select option:selected').val();
        var accountListSearchValue = $('#account-list-search').val();
        var matchingItems = [];
        var $accountList = $('#account-list');
        var $loadMoreItems = $('#load-more-items');
        var $noResult = $accountList.find('div.no-result');
        var $itemList = $accountList.find('div.list-group.item-list');
        var $items = $itemList.find('> a.list-group-item');
        var $subItemLists = $itemList.find('div.list-group.sub-item-list.active');
        var cachedData = amplify.getFromCache(Core.currentCacheKey);
        //Reset (Start by hiding everything)
        $loadMoreItems.show();
        $itemList.show();
        $noResult.hide();
        $items.hide();
        $subItemLists.hide();

        var mappedFilter = $('#toggle-mapped-clients').find('input:radio[value="unmapped"]').is(':checked') ? 'unmapped' : ($('#toggle-mapped-clients').find('input:radio[value="mapped"]').is(':checked') ? 'mapped' : null);
        var newFilter = $('#toggle-new-clients').find('input:radio[value="not_new"]').is(':checked') ? 'not_new' : ($('#toggle-new-clients').find('input:radio[value="new"]').is(':checked') ? 'new' : null);

        //If we have trimmed we should only show after a few letters are typed to avoid showing too many items
        var minInputLength = Core.isTrimmedItemList ? 2 : 1;
        var isConnectionSelected = connectionSelectValue != null && connectionSelectValue != -1;
        var hasFilter = isConnectionSelected || mappedFilter || newFilter;

        if (Core.isTrimmedItemList) {
            var shouldRenderNewList = false;
            //If we have a trimmed list and we have a connection filter first filter by connection
            if (isConnectionSelected) {
                matchingItems = cachedData.account_list.filter(function (item) {
                    return item.connection_id.contains(connectionSelectValue)
                });
                shouldRenderNewList = true;
            }

            if (mappedFilter) {
                matchingItems = (shouldRenderNewList ? matchingItems : cachedData.account_list).filter(function(item) { return item.selected === (mappedFilter === 'mapped') });
                shouldRenderNewList = true;
            }

            if (newFilter) {
                matchingItems = (shouldRenderNewList ? matchingItems : cachedData.account_list).filter(function(item) { return item.new === (newFilter === 'new') });
                shouldRenderNewList = true;
            }

            //If we need to search filter by search value
            if (accountListSearchValue.length > minInputLength && !Core.doNotUseCache) {
                var searchValue = accountListSearchValue.toLowerCase();
                matchingItems = (shouldRenderNewList ? matchingItems : cachedData.account_list).filter(function(item) { return item.label != null && item.label.toLowerCase().contains(searchValue)});
                shouldRenderNewList = true;
            }

            if (shouldRenderNewList) {
                //We need to create a new list since the items may have been not loaded yet because of the trimmedList
                $itemList.empty();
                //Check if account being selected has less than the itemLimit, if so, show all accounts
                if (matchingItems.length < Core.itemLimit) {
                    $loadMoreItems.hide();
                    $items = renderNewList(matchingItems, cachedData);
                }
                else {
                    //Create new list with only first itemLimit items
                    $items = renderNewList(matchingItems.slice(0, Core.itemLimit), cachedData);
                    //Need to filter only parent items since in trimmed lists the subitems need to be included or else they are lost by the connection filter
                    //anyway the visiblity logic for them is handled at the bottom
                    $items = $items.filter('a.list-group-item');
                }
            }
        } else {
            if (hasFilter) {
                $items.hide();
            }
            if (mappedFilter === 'unmapped') {
                $items = $items.not('.list-group-item-success');
            }
            else if (mappedFilter === 'mapped') {
                $items = $items.filter('.list-group-item-success');
            }
            if (newFilter === 'not_new') {
                $items = $items.not('.list-group-item-new');
            }
            else if (newFilter === 'new') {
                $items = $items.filter('.list-group-item-new');
            }
            if (isConnectionSelected) {
                $items = $items.filter('[data-connection-id*="|' + connectionSelectValue + '|"]');
            }
            if (hasFilter) {
                $items.css({display: 'block'}); //Cant use .show() because it puts the items as display:inline for some reason
            }
        }

        //Account search filter - Show matching items (note: because a connection filter may have been used, we need to rehide whats left to filter and show only matching items)
        if (accountListSearchValue.length > minInputLength) {
            if (Core.isTrimmedItemList) {
                $('#account-list-search').tooltip('hide');
                $accountList.scrollTop(0);
            }
            else {
                $items.hide();
                $items.filter('[data-search*="' + accountListSearchValue.toLowerCase() + '"]').show();
            }
        }
        //Nothing is inputted
        if (accountListSearchValue.length < minInputLength + 1 && !hasFilter) {
            if (Core.isTrimmedItemList && Core.trimmedListChanged) {
                Core.displayFirstAccountItems();
                $loadMoreItems.show();
                Core.trimmedListChanged = false;
            }
            else {
                $items.show();
                $subItemLists.show();
            }
        }
        //No results
        var $visibleItems = $items.filter(':visible');
        if ($visibleItems.length == 0) {
            $noResult.show();
            $itemList.hide();
            if (Core.isTrimmedItemList) {
                $loadMoreItems.hide();
            }
        }
        else {
            //Show the sub-item lists, if any, for the remaining visible items after filtering
            $visibleItems.each(function() {
                var $matchingList = $subItemLists.filter('[data-for="' + $(this).data('id') + '"]');
                //Show subitem list if it was previously active (i.e. shown)
                var $toggler = $(this).find('div.toggler');
                if ($toggler.hasClass('active') || $toggler.hasClass('pre-disabled')) {
                    $matchingList.show();
                }
            });
        }

        // update active flag from selected items
        $.each($accountList.find('div.list-group.item-list a.list-group-item'), function() {
            var $item = $(this);
            var item_info = Core.getItemInfo($item);
            if (Core.selectedItems[item_info.item_hash] !== undefined) {
                $item.addClass('active');
            }
        });

        //This creates a new list based on the matching items with all bindings
        function renderNewList(matchingItems, cachedData) {
            var showSubAccounts = cachedData.service.mapper_shows_sub_accounts;
            var subAccountName = cachedData.service.mapper_sub_account_name;
            var $templateItems = $($('#account-list-item-template').render(matchingItems, {
                show_sub_accounts: showSubAccounts,
                sub_account_name: subAccountName
            }));
            //Since this is a new list, by default there are no subitem lists
            //but we still need to have the placeholder to load them when the user clicks the toggler and merge the $subItemLists to $items.
            var $newItems = $templateItems.filter('a.list-group-item');
            Core.itemBindings($newItems, showSubAccounts);
            var $newSubItemLists = $templateItems.filter('div.list-group.sub-item-list');
            //Some subitem placeholders may already contain sub items so we need to bind them (ex: pre-expanded item with children)
            Core.itemBindings($newSubItemLists.find('a.list-group-item'), showSubAccounts);
            //The merge needs to be after the $items binding though to avoid binding to the $subItemLists placeholders
            $newItems = $newItems.add($newSubItemLists);
            Core.trimmedListChanged = true;
            $itemList.html($newItems);
            if (Core.isTrimmedItemList && accountListSearchValue.length > minInputLength && !Core.doNotUseCache) {
                // $itemList would be empty for a TrimmedItemList, so we need perform bindings after the $itemList is populated.
                Core.itemBindings($newSubItemLists.find('a.list-group-item'), showSubAccounts);
            }
            return $newItems;
        }
    },
    subAccountSearchBindings: function($subItemList) {
        $subItemList.find('input.subaccount-list-search').bind('textchange', function(e, previousText) {
            var $items = $subItemList.find('a.list-group-item');

            if ($('#toggle-mapped-clients').find('input:radio[value="unmapped"]').is(':checked')) {
                $items = $items.not('.list-group-item-success');
            }
            else if ($('#toggle-mapped-clients').find('input:radio[value="mapped"]').is(':checked')) {
                $items = $items.filter('.list-group-item-success');
            }
            if ($('#toggle-new-clients').find('input:radio[value="not_new"]').is(':checked')) {
                $items = $items.not('.list-group-item-new');
            }
            else if ($('#toggle-new-clients').find('input:radio[value="new"]').is(':checked')) {
                $items = $items.filter('.list-group-item-new');
            }

            if ($(this).val().length > 1) {
                $items.hide();
                $items.filter('[data-search*="' + $(this).val().toLowerCase() + '"]').show();
            }
            else {
                $items.show();
            }
        });
    },

    resetItem: function(currentSelection, previousSelectItem, evt) {
        var $accountList = $('#account-list');

        var $modalBody = Core.assignClientModal.find('div.modal-body');
        var $itemList = $accountList.find('div.list-group.item-list');
        var serviceItemName = $itemList.data('serviceItemName');
        var serviceSubItemName = $itemList.data('serviceSubItemName');
        var isSubItem = currentSelection.hasClass('sub-item');
        var itemId = currentSelection.data('id');
        var accountName = currentSelection.data('label');
        var clientName = currentSelection.find('span.badge').text();
        //If subitem, get the connectionId from the parent account
        var connectionId = isSubItem ? $('a.list-group-item[data-id="' + currentSelection.closest('div.sub-item-list').data('for') + '"]').data('connectionId') : currentSelection.data('connectionId');
        //Data used later when assigning client (used in: Core.setupAssignClientForm)
        Core.assignClientModal.data('itemId', itemId);
        Core.assignClientModal.data('accountName', accountName);
        Core.assignClientModal.data('connectionId', connectionId);
        var $items = $accountList.find('div.list-group.item-list a.list-group-item');
        var $step1 = $modalBody.find('div.step-1');
        var $step2 = $modalBody.find('div.step-2');

        var shift_down = evt !== null && evt.shiftKey;

        var multi_select_mode = $('#activate-multi-select').is(':checked');

        // prevent from selecting a sub item if an item is already selected (and vice versa)
        if (multi_select_mode)
        {
            for (var id in Core.selectedItems) {
                if (!Core.selectedItems.hasOwnProperty(id)) {
                    continue;
                }

                var item = Core.selectedItems[id];
                if (item.sub_item !== isSubItem) {
                    return;
                }
            }
        }

        if (currentSelection.hasClass('pre-disabled')) {
            var $subItemList = $accountList.find('div.list-group.sub-item-list[data-for="' + itemId +'"]');
            if ($subItemList.find('div.already-assigned-child-warning').length === 0) {
                $subItemList.prepend('<div class="alert alert-warning already-assigned-child-warning">There is at least one ' + serviceSubItemName + ' already assigned. In order to assign a ' + Core.brandMappings.client + ' to <b>' + accountName + '</b>, you need to unassign all of its ' + serviceSubItemName + 's first.</div>');
                $subItemList.find('div.already-assigned-child-warning').fadeIn();
            }
        }
        else if (!currentSelection.hasClass('pre-disabled') && !currentSelection.hasClass('disabled')) {
            $items.find('span.check.selected').removeClass('selected');

            if (multi_select_mode && previousSelectItem !== undefined && shift_down) {
                var index_start = $items.index(previousSelectItem);
                var index_end = $items.index(currentSelection);

                if (index_start > index_end) {
                    var tmp = index_start;
                    index_start = index_end;
                    index_end = tmp;
                }

                $.each($items.slice(index_start, index_end+1), function() {
                    if (!$(this).hidden && $(this).is(':visible')) {
                        Core.itemSelected($(this), true);
                    }
                });
            }
            //Back to step 1
            else if (currentSelection.hasClass('active')) {
                if (multi_select_mode) {
                    Core.itemSelected(currentSelection, false);
                }
                else {
                    // clear all selected items
                    Core.clearAllSelected($items);

                    // add current selected
                    Core.itemSelected(currentSelection, true);
                }
            }
            //Go to step 2
            else {
                if (!multi_select_mode) {
                    // clear all selected items
                    Core.selectedItems = [];
                    $items.removeClass('active');
                }
                Core.itemSelected(currentSelection, true);
                currentSelection.find('span.check').addClass('selected');
                Core.setupStep2($step1, $step2);
            }

            var wasPreSelected;
            var active_number = $items.filter('.active').length;
            if (active_number === 1) {
                var active_item = $items.filter('.active').first();
                accountName = active_item.data('label');
                wasPreSelected = active_item.hasClass('list-group-item-success');
            }
            else {
                wasPreSelected = false;
            }

            //Master service mapper
            if (Core.assignClientModal.data('mapperType') === 'master') {
                //Set step 2 text
                Core.mainItemName = isSubItem ? serviceSubItemName : serviceItemName;

                if (multi_select_mode) {
                    if (Core.getNbOfSelected() > 1) {
                        Core.mainItemName += 's';
                    }

                    $step2.find('small.placeholder').html('Review Selected ' + Core.mainItemName + ' and Assign to Client');
                }
                else if (wasPreSelected) {
                    $step2.find('small.placeholder').html('This ' + Core.mainItemName + ' is already assigned to <b>' + clientName + '</b>. You can assign it to a different ' + Core.brandMappings.client + ' below.');
                }
                else {
                    $step2.find('small.placeholder').html('Assign the ' + Core.mainItemName + ' <b>' + accountName + '</b> to a ' + Core.brandMappings.client + '. &nbsp;<span class="icon icomoon-help has-tooltip" title="Assigning this ' + Core.mainItemName + ' to a ' + Core.brandMappings.client + ' will allow you to see all your agency ' + Core.brandMappings.client.pluralize() + ' in the same dashboard."></span>');
                    $.core.main.tooltip($step2.find('small.placeholder'), { placement: 'top'} );
                }
                //Step 2 - Get assign client forms
                $.get($.fn.actionUrl('setup/masterservicemapperitem'), {
                    account_type: (isSubItem ? 'sub' : 'main'),
                    service_id: Core.assignClientModal.data('serviceId'),
                    client_id: 0,
                    item_id: itemId,
                    connection_id: connectionId,
                    item_label: encodeURIComponent(accountName)
                }, function(html) {
                    $('#assign-client-form-container').html(html);
                    Core.setupAssignClientForm(clientName, wasPreSelected);
                });
            }
            //Individual service mapper
            else if (Core.assignClientModal.data('mapperType') === 'individual') {
                //Set step 2 text
                $step2.find('small.placeholder').html('Enter the URLs you want to track for <b>' + accountName + '</b>. Each URL should be on its own line.');
                $.get($.fn.actionUrl('setup/individualservicemapperitem'), {
                    service_id: Core.assignClientModal.data('serviceId'),
                    client_id: itemId
                }, function(html) {
                    $('#assign-client-form-container').html(html);
                    Core.assignClientModal.find('div.modal-footer').show();
                    $.core.main.initFormValidator($('#individual-client-form'));
                });
            }

        }
        Core.updateSelectedList();
        Core.hideRightPanelOnNoSelection($step1, $step2);
    },


    //
    // Step 1
    //
    itemBindings: function($items, showSubAccounts) {
        var $accountList = $('#account-list');
        //Unassign bindings
        Core.unassignClientBindings($items);
        //Sub account toggler bindings
        if (showSubAccounts) {
            Core.togglerBindings($items.find('div.toggler'));
            //Go through each shown sub item list (i.e. has at least one selected sub item) and disable parent item
            $accountList.find('a.list-group-item.sub-item.list-group-item-success').each(function(i, selectedSubItem) {
                var $parentItem = $accountList.find('a.list-group-item[data-id="' + $(selectedSubItem).data('id').split('-')[0] +'"]');
                $parentItem.addClass('pre-disabled');
                $parentItem.find('div.toggler').addClass('pre-disabled');
            });
            //Search subaccounts bindings
            Core.subAccountSearchBindings($accountList.find('div.list-group.sub-item-list.active'));
        }
        //var used for saving the currently selected campaign itme
        var previousSelectItem;
        var selectedItem;
        $items.click(function(evt){
            selectedItem = $(this);
            Core.resetItem(selectedItem, previousSelectItem, evt);
            previousSelectItem = selectedItem;
            evt.stopPropagation();
        });
        $('#cancel-client').click(function(){Core.resetItem(selectedItem, null, null);});
        $('#activate-multi-select').click(function(){
            $('#selected-campaigns').toggle();

            if (!$(this).is(':checked')) {
                Core.clearAllSelected($items);
                Core.hideRightPanelOnNoSelection(null, null);
            } else {
                EventBus.signal("DataSourceActivateMultiSelectEvent", {
                    datasource: {
                        name: Core.serviceName,
                        id: Core.assignClientModal.data('serviceId')
                    }
                });
            }
        });
    },
    //
    // Unassign client
    //
    unassignClientBindings: function($items) {
        $items.find('.unassign-client').click(function(e) {
            var $itemList = $(this).closest('div.list-group.item-list');
            var serviceItemName = $itemList.data('serviceItemName');
            var serviceSubItemName = $itemList.data('serviceSubItemName');
            //Depending on whether $(this) is the unassing button or link, item will be reached differently
            var $item = $(this).closest('a.list-group-item').length > 0
                ? $(this).closest('a.list-group-item')
                : $(this).closest('div.list-group.sub-item-list').prev('a.list-group-item');
            var isSubItem = $item.hasClass('sub-item');
            var id = $item.data('id');
            var clientName = $item.find('span.badge').text() == '' ? 'the currently assigned client' : '<b>' + $item.find('span.badge').text() + '</b>';
            $('#unassign-client-confirm').find('div.modal-body').html('Are you sure you want to unassign ' + clientName + ' from this ' + (isSubItem ? serviceSubItemName : serviceItemName) + '?');
            $.core.main.showModal($('#unassign-client-confirm'));
            //Pass some data to the button
            $('#unassign-confirm').data('accountId', id);
            $('#unassign-confirm').data('clientName', clientName);
            e.stopPropagation();
        });
    },
    unassignConfirmBinding: function() {
        //Bind unassign client confirm
        $('#unassign-confirm').click(function() {
            var $this = $(this);
            $this.disableElement();
            var $accountList = $('#account-list');
            var serviceId = Core.assignClientModal.data('serviceId');
            var accountId = $this.data('accountId');
            var clientName = $this.data('clientName');
            $.post($.fn.actionUrl('setupaction/unassignaccount'), {
                service_id: serviceId,
                account_id: accountId
            }, function(json) {
                $this.enableElement();
                if (json.status == 'error') {
                    $.core.main.notify(json.data, $.globals.notify.error, {title: clientName});
                } else {
                    var $unassignedItem = $accountList.find('a.list-group-item[data-id="' + accountId +'"]');
                    $unassignedItem.removeClass('list-group-item-success');
                    //In case item was just added before
                    $unassignedItem.find('div.pre-selected-info').empty();
                    //If unassigned item had sub items, re-enable them and hide the warning
                    var $subItemList = $unassignedItem.next('div.list-group.sub-item-list');
                    var $subItems = $subItemList.find('a.list-group-item.sub-item');
                    if ($subItems.length > 0) {
                        $subItemList.find('div.already-assigned-parent-warning').remove();
                        $subItems.removeClass('disabled');
                    }
                    //If unassigned item is a sub item and was the last selected sub item, re-enable the parent element
                    else if ($unassignedItem.hasClass('sub-item')) {
                        $subItemList = $unassignedItem.closest('div.sub-item-list');
                        //Check if any other sub items are selected
                        if ($subItemList.find('a.sub-item.list-group-item-success').length == 0) {
                            var $parentItem = $subItemList.prev('a.list-group-item');
                            $parentItem.removeClass('pre-disabled');
                            $parentItem.find('div.toggler').removeClass('pre-disabled');
                            //If assigned chikld warning is shown, remove it
                            $subItemList.find('div.already-assigned-child-warning').remove();
                        }
                    }
                    $.core.main.hideModal($('#unassign-client-confirm'));
                    $.core.main.notify('was successfully unassigned', $.globals.notify.success, {title: clientName});
                    //When clients are unassigned we need to persist the changes to the cached data
                    Core.updateAccountListCache($unassignedItem, clientName, false);
                    Core.newClientAssigned--;
                    if (!window.isNUI) {
                        //Refresh service list menu
                        Core.refreshServiceListMenu();
                    }
                }
            });
        });
    },
    addSmartConnectorMarketplace: function() {
        $('a.connect.sc-marketplace').click(function() {
            Core.connectSmartConnectorMarketplaceSetup($(this));
        });
        $('#smart-connector-marketplace-confirm').click(function() {
            var marketplace_id = $('#create-smart-connector-marketplace-confirm-modal').data('service-id');
            var url = $.fn.apiUrl('importwizard/marketplace/import/' + marketplace_id);
            $.get(url,
                function(json) {
                    if (json.data.id) {
                        window.location.href = '#/importwizard/detail/' + json.data.id + '?import=1';
                    } else {
                        $.core.main.formErrorNotify(json);
                    }
                }
            ).fail(function(json) {
                $.core.main.formErrorNotify('The Marketplace Smart Connector cannot be installed. Please contact support if the problem persists.');
            });
        });
    },
    connectSmartConnectorMarketplaceSetup: function($elm) {
        var $modal = $('#create-smart-connector-marketplace-confirm-modal');
        $modal.data('service-id', $elm.data('service-id'));
        $modal.modal('show');
        $modal.find('b.smart-connector-marketplace-name-placeholder').html($elm.data('service-name'));
    },
    setupNuiEvents: function() {
        if (window.isNUI) {
            EventBus.listen(RefreshLegacyMdsEvent, () => {
                $.core.main.templateCache();
            });
        }
    },
    togglerBindings: function($togglers) {
        $togglers.click( function(e) {
            if (!$(this).hasClass('disabled') && !$(this).hasClass('pre-disabled')) {
                var $accountList = $('#account-list');
                var $parentItem = $(this).closest('a.list-group-item');
                var id = $parentItem.data('id');
                var connectionId = $parentItem.data('connectionId');
                var $subItemList = $accountList.find('div.list-group.sub-item-list[data-for="' + id +'"]');
                if ($subItemList.is(':hidden')) {
                    $(this).addClass('active');
                    $subItemList.addClass('active');
                    $subItemList.show();
                    $(this).find('span').attr('class', 'icon icon-chevron-down');
                    if ($subItemList.children().length == 0) {
                        Core.loadCampaigns($subItemList, id, connectionId, $parentItem);
                    }
                }
                else {
                    $(this).removeClass('active');
                    $subItemList.removeClass('active');
                    $subItemList.hide();
                    $(this).find('span').attr('class', 'icon icon-chevron-right');
                }
            }
            e.stopPropagation();
        });
    },
    loadCampaigns: function($subItemList, accountId, connectionId, $parentItem) {
        var $itemList = $('#account-list').find('div.list-group.item-list');
        var serviceItemName = $itemList.data('serviceItemName');
        var serviceSubItemName = $itemList.data('serviceSubItemName');
        var accountName = $parentItem.data('label');
        $subItemList.html('<div class="sub-item-loading">Loading ' + serviceSubItemName +'s for <b>' + accountName + '</b>...</div>');
        $.get($.fn.actionUrl('setup/masterservicemapper_sub_account_list'), {
            service_id: Core.assignClientModal.data('serviceId'),
            account_id: accountId,
            connection_id: connectionId
        }, function(json) {
            if (json.status == 'success') {
                var data = json.data;
                $subItemList.find('div.sub-item-loading').remove();
                //No subitems
                if (data.widget_data.trim().length == 0) {
                    $subItemList.append('<div class="alert alert-info"><i class="icon icomoon-warning"></i><span>'+$itemList.data('serviceNoSubItemMessage')+'</span></div>');                    }
                else {
                    $subItemList.append(data.widget_data);
                    //Allow sub account search capabilities
                    Core.subAccountSearchBindings($subItemList);
                }
                //If parent is already selected, we cannot select child elements
                var parentIsSelected = $parentItem.hasClass('list-group-item-success');
                if (parentIsSelected) {
                    var assignedClient = $parentItem.find('span.badge').text();
                    $subItemList.prepend('<div class="alert alert-warning already-assigned-parent-warning">You cannot assign ' + Core.brandMappings.client.pluralize() + ' to these ' + serviceSubItemName + 's because their ' + serviceItemName + ' is already assigned to <b>' + assignedClient + '</b>. <a class="unassign-client">Click here if you wish to unassign it.</a></div>');
                    $subItemList.find('a.list-group-item.sub-item').addClass('disabled');
                    //For the unassign link in the warning
                    Core.unassignClientBindings($subItemList.find('div.already-assigned-parent-warning'));
                }
                Core.itemBindings($subItemList.find('a.list-group-item.sub-item'), false);
            }
            else if (json.status == 'redirect') {
                Core.redirectToLogin(json);
            }
            else {
                $.core.main.formErrorNotify(json);
            }
        });
    },
    //
    // Step 2 (Master form)
    //
    setupAssignClientForm: function(clientName, wasPreSelected) {
        //clientName param passed in function is only used to compare previously selected client
        //value sent to saveSuccess is the value inputed in either input
        var $modalFooter = Core.assignClientModal.find('div.modal-footer');
        var $editForm = $('#edit-form');
        var $assignClientForm = $('#assign-new-client');
        var $emailOptionForm = $('#email-options-form');
        var $existingClientInput = $('#servicemapper_client_id');
        var $editButton = $('#edit-client');
        $modalFooter.hide();
        var form = {};
        //reset client selector
        $existingClientInput.val('').trigger('change');
        var $newClientInput = $('#new-client-name');
        //For older browsers that dont support html5 placeholders
        $('#assign-client-form-container input[type=text]').placeholder();
        //Get and set the previously assigned client, if any
        if (wasPreSelected) {
            var selectedOptionValue = $existingClientInput.find('option').filter(function() {
                if ($(this).text() == clientName)
                    return $(this).val();
            }).val();
            $existingClientInput.val(selectedOptionValue).trigger('change');
        }
        $existingClientInput.change(function() {
            if ($(this).val() > 0) {
                $.get($.fn.actionUrl('setup/masterservicemapperitemeditform'), {
                    client_id: $(this).val()
                }, function(html) {
                    $editForm.html(html);
                    form = Core.saveForm(form);
                    $newClientInput.val('').trigger('change');
                    $('#edit-client').text('Edit ' + Core.brandMappings.client);
                    $('#assign-client').text('Assign ' + Core.brandMappings.client);
                    $('#edit-client').removeClass('btn-danger');
                    $('#edit-client').addClass('btn-primary');
                    $('#edit-client').off();
                    Core.editForm(false);
                    Core.editButton(true);
                    Core.formEdit(true);
                    if(!Permission.hasPermissionToWrite(Permission.moduleName.CLIENT)) {
                        $('#edit-client').hide();
                    }
                    clientName = $(this).find('option:selected').text();
                    Core.editForm(true);
                    $modalFooter.show();
                    $('#additional-info-link').click(function(e) {
                        Core.advancedOpt(!$('#advanced-panel').is(":visible"));
                    });
                    $('#general-options-link').click(function(e) {
                        Core.generalOpt(!$('#general-panel').is(":visible"));
                    });
                    $('#primary-contact-info-link').click(function(e) {
                        Core.contactOpt(!$('#primary-contact-panel').is(":visible"));
                    });

                    $('#advanced-panel').toggle((Core.panelCookie('advanced') === 'true'));
                    $('#general-panel').toggle((Core.panelCookie('general') === 'true'));
                    $('#primary-contact-panel').toggle((Core.panelCookie('contact') === 'true'));

                    $editButton.on('click', function(){
                        if($editButton.text() === 'Cancel Edit'){
                            Core.restoreForm(form);
                        }
                        Core.editButtonMode($editButton.text() === 'Edit ' + Core.brandMappings.client);
                    })
                });
            }
            else {
                $modalFooter.hide();
                $('#edit-client').text('Edit ' + Core.brandMappings.client);
                $('#assign-client').text('Assign ' + Core.brandMappings.client);
                $('#edit-client').removeClass('btn-danger');
                $('#edit-client').addClass('btn-primary');
                $('#edit-client').off();
                Core.editForm(false);
            }
        });
        $newClientInput.bind('textchange',function(e, previousText) {
            var $formGroup = $newClientInput.closest('div.form-group');
            //Input hasn't changed or is empty
            if ($(this).val() == '' || $(this).val() == previousText) {
                $modalFooter.hide();
                $('#edit-client').text('Edit ' + Core.brandMappings.client);
                $('#assign-client').text('Assign ' + Core.brandMappings.client);
                $('#edit-client').removeClass('btn-danger');
                $('#edit-client').addClass('btn-primary');
                Core.editForm(false);
            }
            else {
                if($existingClientInput.val() || previousText === ''){
                    $existingClientInput.val('').trigger('change');
                    $.get($.fn.actionUrl('setup/masterservicemapperitemeditform'), {}, function(html) {
                        $editForm.html(html);
                        Core.editForm(true);
                        Core.editButton(false);
                        $modalFooter.show();
                        $('#additional-info-link').click(function(e) {
                            Core.advancedOpt(!$('#advanced-panel').is(":visible"));
                        });
                        $('#general-options-link').click(function(e) {
                            Core.generalOpt(!$('#general-panel').is(":visible"));
                        });
                        $('#primary-contact-info-link').click(function(e) {
                            Core.contactOpt(!$('#primary-contact-panel').is(":visible"));
                        });

                        $('#advanced-panel').toggle((Core.panelCookie('advanced') === 'true'));
                        $('#general-panel').toggle((Core.panelCookie('general') === 'true'));
                        $('#primary-contact-panel').toggle((Core.panelCookie('contact') === 'true'));
                    });
                    clientName = $(this).val();
                }
            }
        });
        //Assign client on enter (existing client select)
        $existingClientInput.next('div.select2-container').bind('keyup',function(e) {
            if ($modalFooter.is(':visible')) {
                if((e.keyCode ? e.keyCode : e.which) == 13) { //Enter is pressed
                    $('#assign-client').click();
                }
            }
        });
        $assignClientForm.keypress(function(e) {
            if ($modalFooter.is(':visible')) {
                if((e.keyCode ? e.keyCode : e.which) == 13) { //Enter is pressed
                    $('#assign-client').click();
                }
            }
        });
        $emailOptionForm.keypress(function(e) {
            if ($modalFooter.is(':visible')) {
                if((e.keyCode ? e.keyCode : e.which) == 13) { //Enter is pressed
                    $('#assign-client').click();
                }
            }
        });
        //Need to prevent default behavior for new client text input
        $assignClientForm.submit(function(e) {
            e.preventDefault();
        });
    },
    assignClientBinding: function() {
        let currentAngularAppInjector = angular.element(document.body).injector();
        currentAngularAppInjector = currentAngularAppInjector || angular.element($('#legacy-app')).injector();
        const appFactory = currentAngularAppInjector.get('AppFactory');
        $('#assign-client').click(function() {
            var $this = $(this);
            $this.disableElement();
            var url = null;
            var formData = null;
            var isNewClient = !$('#servicemapper_client_id').val();
            var sendWelcomeEmail = false;
            //Assigning an existing client
            if ($('#servicemapper_client_id').val() > 0 && $('#edit-client').text() === 'Edit ' + Core.brandMappings.client) {
                url = $.fn.actionUrl('setupaction/assignexistingcustomer');
                formData = {};

                formData.service_id = Core.assignClientModal.data('serviceId');
                formData.client_id = $('#servicemapper_client_id').val();
            }
            else {
                url = $.fn.actionUrl('setupaction/assignnewcustomer');
                formData = $.extend( $('#assign-new-client').serializeObject(), $('#general-options-form').serializeObject(), $('#email-options-form').serializeObject(), $('#advanced-options-form').serializeObject(), $('#primary-contact-options-form').serializeObject());
                formData.company_name = $('#servicemapper_client_id').val() > 0 ? $('#servicemapper_client_id').select2('data').text : $('#new-client-name').val();
                formData.user_id = $('#servicemapper_client_id').val() > 0 ? $('#servicemapper_client_id').val() : 0;
                sendWelcomeEmail = !!formData.sendnotif;
            }

            formData.item_labels = [];
            formData.item_ids = [];
            formData.connection_ids = [];

            for (var id in Core.selectedItems) {
                if (!Core.selectedItems.hasOwnProperty(id)) {
                    continue;
                }

                var item = Core.selectedItems[id];

                formData.item_labels.push(encodeURIComponent(item.label));
                formData.item_ids.push(item.id);
                formData.connection_ids.push(item.connection_id);
            }

            $.post(url, formData, function(json){
                appFactory.refreshServices();
                $this.enableElement();
                if (json.status === 'success') {
                    var clientName = json.data.company_name;
                    Core.saveSuccess(clientName);
                    if(window.isNUI) {
                        EventBus.signal(OnboardingEvent.CONNECT_DATA);
                        EventBus.signal('DataSourceAssignClientEvent', {
                            datasource: {
                                client_name: clientName,
                                create_new_client: isNewClient,
                                sent_welcome_email: sendWelcomeEmail
                            }
                        })
                    } else {
                        //Refresh service list menu
                        Core.refreshServiceListMenu();
                    }
                }
                else if (json.status === 'redirect') {
                    Core.redirectToLogin(json);
                }
                else {
                    $.core.main.formErrorNotify(json);
                }
            });
        });
    },
    //
    // Step 2 (Individual form)
    //
    addUrlBinding: function() {
        $('#add-url').click(function() {
            var $form = $('#individual-client-form');
            var $newInputHolder = $($form.find('div.form-group.full')[0]).clone(true);
            var $newInput = $newInputHolder.find('input[type="url"]');
            $newInput.val('');
            $newInput.placeholder();
            $form.append($newInputHolder);
            //Add to validator
            $.core.main.addFormValidatorField($form, $newInput);
        });
    },
    submitUrlBinding: function() {
        $('#submit-url-list').click(function() {
            var $form = $('#individual-client-form');
            var $urlInputs = $form.find('input[type=url]');
            //This returns whether there is at least one url field filled out
            var $result = $.map($urlInputs, function(elm) { if($(elm).val() != '') return elm;});
            //Prepend http:// if not already present
            $.each($result, function(i, elm) {
                $(elm).val($(elm).val().addhttp());
            });
            if ($.core.main.validateForm($form)) {
                $.post($.fn.actionUrl('setupaction/individualform'), $form.serializeObject(), function(json) {
                    if (json.status == 'success') {
                        Core.saveSuccess();
                    }
                    else if (json.status == 'redirect') {
                        Core.redirectToLogin(json);
                    }
                    else {
                        $.core.main.formErrorNotify(json);
                    }
                });
            }
        });
    },
    saveSuccess: function(clientName) {
        //Update onboarding step
        $.core.main.updateOnboarding(3);
        $.core.main.templateCache();
        $('#update-dashboard').enableElement();
        $.core.main.hideAllNotifies();
        var $accountList = $('#account-list');
        var $modalBody = Core.assignClientModal.find('div.modal-body');
        var $selectedItem = $accountList.find('div.list-group.item-list a.list-group-item.active');
        //Reset sections (i.e. back to step 1)
        Core.setupStep1($modalBody.find('div.step-1'), $modalBody.find('div.step-2'));
        //Highlight newly selected item
        $selectedItem.removeClass('active');
        $selectedItem.addClass('list-group-item-success');
        Core.newClientAssigned++;
        //Master service mapper
        if (Core.assignClientModal.data('mapperType') == 'master') {
            $selectedItem.find('div.toggler').removeClass('disabled');
            //Unassign client binding and setup
            $selectedItem.find('div.pre-selected-info').html('<div class="unassign-client has-tooltip" title="Unassign client"><span class="icon icomoon-logout"></span></div><span class="badge">' + clientName + '</span>');
            $.core.main.tooltip($selectedItem, { placement: 'top'});
            Core.unassignClientBindings($selectedItem);
            $.core.main.notify('was successfully assigned', $.globals.notify.success, {title: clientName});
            //Reset client selectors
            var $existingClientInput = $('#servicemapper_client_id');
            $existingClientInput.val('').trigger('change');
            $('#new-client-name').val('');
            //If assigned item had sub-items showing, then we need to disable them and show the warning
            var $subItemList = $selectedItem.next('div.list-group.sub-item-list');
            var $subItems = $subItemList.find('a.list-group-item.sub-item');
            if ($subItems.length > 0) {
                $subItems.addClass('disabled');
                $subItemList.prepend('<div class="alert alert-warning already-assigned-parent-warning">You cannot assign ' + Core.brandMappings.client.pluralize() + ' to this account since it is already assigned to <b>' + clientName + '</b>. <a class="unassign-client">Click here if you wish to unassign it.</a></div>');
                //For the unassign link in the warning
                Core.unassignClientBindings($subItemList.find('div.already-assigned-parent-warning'));
            }
            //If selected item is a sub item and is the first selected sub item, disable the parent element
            else if ($selectedItem.hasClass('sub-item')) {
                var $subItemList = $selectedItem.closest('div.sub-item-list');
                //Check if any other sub items are selected
                if ($subItemList.find('a.sub-item.list-group-item-success').length > 0) {
                    var $parentItem = $subItemList.prev('a.list-group-item');
                    $parentItem.addClass('pre-disabled');
                    $parentItem.find('div.toggler').addClass('pre-disabled');
                }
            }
            //When new clients are assigned we need to persist the changes to the cached data
            Core.updateAccountListCache($selectedItem, clientName, true);
        }
        //Individual service mapper
        else if (Core.assignClientModal.data('mapperType') == 'individual') {
            $.core.main.notify('Saved successfully', $.globals.notify.success);
        }
        Core.clearAllSelected(null);
    },
    updateAccountListCache: function($currentItem_, clientName, isAdding) {
        if (amplify.lookupCache(Core.currentCacheKey)) {
            var cachedData = amplify.getFromCache(Core.currentCacheKey);

            $currentItem_.each(function() {
                var $currentItem = $(this);

                //Item is a parent item
                if (!$currentItem.hasClass('sub-item')) {
                    var modifiedItem = cachedData.account_list.filter(function(val) {
                        if (val.id == $currentItem.data('id'))
                            return val;
                    })[0];
                    modifiedItem.selected = isAdding;
                    modifiedItem.company_name = isAdding ? clientName : null;
                }
                //If item is a sub item then we need to add/remove the sub item list to cache to redisplay on next cache load
                else {
                    var $subItemList = $currentItem.closest('div.list-group.sub-item-list');
                    var parentId = $currentItem.data('id').split('-')[0];
                    var subItemId = $currentItem.data('id').split('-')[1];
                    var cachedParentItem = cachedData.account_list.filter(function(val) {
                        if (val.id == parentId)
                            return val;
                    })[0];
                    //If contains subitems then item is a subitem already in cache
                    if (cachedParentItem.sub_items.length > 0) {
                        var modifiedItem = cachedParentItem.sub_items.filter(function(val) {
                            if (val.id == subItemId)
                                return val;
                        })[0];
                        modifiedItem.selected = isAdding;
                        modifiedItem.company_name = isAdding ? clientName : null;
                        if (!isAdding) {
                            //If last item is being removed then we have to remove the sub item list from cache to hide next time list is shown
                            if ($subItemList.find('a.list-group-item.sub-item.list-group-item-success').length == 0) {
                                cachedParentItem.sub_items = [];
                            }
                        }
                    }
                    //Item is a new subitem, so we need to add the list of subitems to the parent item cache set to display sub list next time
                    else {
                        $subItemList.find('a.list-group-item.sub-item').each(function(i, elm) {
                            var currentSubItemId = $(elm).data('id').split('-')[1];
                            var isSelectedSubItem = currentSubItemId == subItemId;
                            cachedParentItem.sub_items.push({
                                company_name: isSelectedSubItem ? clientName : null,
                                id: currentSubItemId,
                                name: $(elm).data('label'),
                                selected: isSelectedSubItem
                            });
                        });
                    }
                }
            });

            amplify.safeStore(Core.currentCacheKey, cachedData);
        }
    },
    setupStep1: function($step1, $step2) {
        $step1.removeClass('col-md-6').addClass('col-md-12');
        $step1.find('h3 span.label').addClass('active');
        $('#toggle-mapped-clients').parent().show();
        $step2.find('h3 span.label').removeClass('active');
        Core.editButtonMode(false);
        $step2.hide();
        Core.assignClientModal.find('div.modal-footer').hide();
    },
    setupStep2: function($step1, $step2) {
        $step1.find('h3 span.label').removeClass('active');
        $step2.find('h3 span.label').addClass('active');
        $('#toggle-mapped-clients').parent().hide();
        $step1.removeClass('col-md-12').addClass('col-md-6');
        $step2.show();
        Core.selectedBindings();
    },
    assignClientCloseBinding: function($modal) {
        $('#cancel-client').click(function() {
            $.core.main.hideAllNotifies();
            if (Core.newClientAssigned > 0) {
                if (Core.isDashboardAlreadyGenerated) {
                    $.core.main.notify('Looks like you\'ve modified the mapping of at least one of your ' + Core.brandMappings.campaign.pluralize() + '. Click the button below to have the changes reflected on your dashboard', $.globals.notify.success, {element: $('#update-dashboard')});
                }
                else {
                    $.core.main.notify('Looks like you\'ve mapped at least one ' + Core.brandMappings.campaign.pluralize() + ' to a ' + Core.brandMappings.client + ' - congrats! Click the button below to have the changes reflected on your dashboard', $.globals.notify.success, {element: $('#update-dashboard')});
                }
            }
            //Clear interval timeout for refreshing the time ago text
            clearInterval(Core.refreshListTimeout);
        });
    },
    //
    // Optional
    //
    editButtonMode: function(modeState) {
        var button = $('#edit-client');
        var assign = $('#assign-client');
        if(modeState === true) {
            button.text('Cancel Edit')
            assign.text('Save & Assign');
            button.removeClass('btn-primary');
            button.addClass('btn-danger');
            Core.formEdit(false);
        }
        else {
            button.text('Edit ' + Core.brandMappings.client)
            assign.text('Assign ' + Core.brandMappings.client);
            button.removeClass('btn-danger');
            button.addClass('btn-primary');
            Core.formEdit(true);
        }
    },
    saveForm: function(form) {
        form.cluster = $('#cluster_id').val();
        form.rp = $('#default_reporting_profile_id').val();
        form.groupIds = $('#client_group_ids').val();
        form.email = $('#email').val();
        form.password = $('#password').val();
        form.firstName = $('#first_name').val();
        form.lastName = $('#last_name').val();
        form.add1 = $('#address_1').val();
        form.add2 = $('#address_2').val();
        form.city = $('#city').val();
        form.state = $('#state').val();
        form.zip = $('#zip').val();
        form.country = $('#country').val();
        form.phone = $('#phone').val();
        form.crmId = $('#crm_id').val();
        form.billingId = $('#billing_id').val();
        form.deet1 = $('#additional_details_1').val();
        form.deet2 = $('#additional_details_2').val();
        form.deet3 = $('#additional_details_3').val();
        form.clientId = $('#client_category_id').val();
        form.leadManagement = $('#enable_lead_management_module').prop('checked');
        form.tapLeads = $('#activate_tapleads').prop('checked');
        form.servicesOverview = $('#show_services_overview').val();
        form.categoriesOverview = $('#show_categories_overview').val();
        return form;
    },
    restoreForm: function(form) {
        $('#cluster_id').val(form.cluster);
        $('#default_reporting_profile_id').val(form.rp);
        $('#client_group_ids').select2('val', form.groupIds.split(','));
        $('#email').val(form.email);
        $('#password').val(form.password);
        $('#sendnotif').val(form.sendnote);
        $('#first_name').val(form.firstName);
        $('#last_name').val(form.lastName);
        $('#address_1').val(form.add1);
        $('#address_2').val(form.add2);
        $('#city').val(form.city);
        $('#state').val(form.state);
        $('#zip').val(form.zip);
        $('#country').val(form.country);
        $('#phone').val(form.phone);
        $('#crm_id').val(form.crmId);
        $('#billing_id').val(form.billingId);
        $('#additional_details_1').val(form.deet1);
        $('#additional_details_2').val(form.deet2);
        $('#additional_details_3').val(form.deet3);
        $('#client_category_id').val(form.clientId);
        $('#enable_lead_management_module').prop('checked', form.leadManagement);
        $('#enable_lead_management_module').val(form.leadManagement ? 'yes' : 'no');
        $('#activate_tapleads').prop('checked', form.tapLeads);
        $('#activate_tapleads').val(form.tapLeads ? 'yes' : 'no');
        $('#show_services_overview').val(form.servicesOverview);
        $('#show_categories_overview').val(form.categoriesOverview);
    },
    formEdit: function(fieldsDisabled) {
        if (fieldsDisabled) {
            $('#cluster_id').attr('disabled', 'disabled');
            $('#default_reporting_profile_id').attr('disabled', 'disabled');
            $('#client_group_ids').attr('disabled', 'disabled');
            $('#report_language').attr('disabled', 'disabled');
            $('#email').attr('disabled', 'disabled');
            $('#password').attr('disabled', 'disabled');
            $('#sendnotif').attr('disabled', 'disabled');
            $('#first_name').attr('disabled', 'disabled');
            $('#last_name').attr('disabled', 'disabled');
            $('#address_1').attr('disabled', 'disabled');
            $('#address_2').attr('disabled', 'disabled');
            $('#city').attr('disabled', 'disabled');
            $('#state').attr('disabled', 'disabled');
            $('#zip').attr('disabled', 'disabled');
            $('#country').attr('disabled', 'disabled');
            $('#phone').attr('disabled', 'disabled');
            $('#crm_id').attr('disabled', 'disabled');
            $('#billing_id').attr('disabled', 'disabled');
            $('#additional_details_1').attr('disabled', 'disabled');
            $('#additional_details_2').attr('disabled', 'disabled');
            $('#additional_details_3').attr('disabled', 'disabled');
            $('#client_category_id').attr('disabled', 'disabled');
            $('#enable_lead_management_module').attr('disabled', 'disabled');
            $('#activate_tapleads').attr('disabled', 'disabled');
            $('#show_services_overview').attr('disabled', 'disabled');
            $('#show_categories_overview').attr('disabled', 'disabled');
        }
        else {
            $('#cluster_id').removeAttr('disabled');
            $('#default_reporting_profile_id').removeAttr('disabled');
            $('#client_group_ids').removeAttr('disabled');
            $('#report_language').removeAttr('disabled');
            $('#email').removeAttr('disabled');
            $('#password').removeAttr('disabled');
            $('#sendnotif').removeAttr('disabled');
            $('#first_name').removeAttr('disabled');
            $('#last_name').removeAttr('disabled');
            $('#address_1').removeAttr('disabled');
            $('#address_2').removeAttr('disabled');
            $('#city').removeAttr('disabled');
            $('#state').removeAttr('disabled');
            $('#zip').removeAttr('disabled');
            $('#country').removeAttr('disabled');
            $('#phone').removeAttr('disabled');
            $('#crm_id').removeAttr('disabled');
            $('#billing_id').removeAttr('disabled');
            $('#additional_details_1').removeAttr('disabled');
            $('#additional_details_2').removeAttr('disabled');
            $('#additional_details_3').removeAttr('disabled');
            $('#client_category_id').removeAttr('disabled');
            $('#enable_lead_management_module').removeAttr('disabled');
            $('#activate_tapleads').removeAttr('disabled');
            $('#show_services_overview').removeAttr('disabled');
            $('#show_categories_overview').removeAttr('disabled');
        }
    },
    editButton: function(buttonState) {
        if (buttonState) {
            $('#edit-client').show();
        }
        else {
            $('#edit-client').hide();
        }
    },
    editForm: function(showOption) {
        if (showOption) {
            $('#edit-form').show();
        }
        else {
            $('#edit-form').hide();
        }
    },
    advancedOpt: function(showOption) {
        if (showOption) {
            $('#advanced-panel').show();
            document.cookie = "advancedPanel=true;"
        }
        else {
            $('#advanced-panel').hide();
            document.cookie = "advancedPanel=false;"
        }
    },
    contactOpt: function(showOption) {
        if (showOption) {
            $('#primary-contact-panel').show();
            document.cookie = "contactPanel=true;"
        }
        else {
            $('#primary-contact-panel').hide();
            document.cookie = "contactPanel=false;"
        }
    },
    generalOpt: function(showOption) {
        if (showOption) {
            $('#general-panel').show();
            document.cookie = "generalPanel=true;"
        }
        else {
            $('#general-panel').hide();
            document.cookie = "generalPanel=false;"
        }
    },
    panelCookie: function(panel) {
        var name = panel + 'Panel=';
        var cookies = document.cookie.split(';');
        for(var i=0; i<cookies.length; i++) {
            var cookie = cookies[i];
            while (cookie.charAt(0)==' ') cookie = cookie.substring(1);
            if (cookie.indexOf(name) == 0) return cookie.substring(name.length,cookie.length);
        }
        return '';
    },

    selectedBindings: function () {
        var $toggler = $('#selected-toggler');
        $toggler.unbind('click');

        $toggler.click( function() {
            var $list = $('#selected-list');
            if ($list.is(':hidden')) {
                $(this).find('span').attr('class', 'icon icon-chevron-down');
                $list.show();
                Core.buttonRemoveBinding();
            }
            else {
                $(this).find('span').attr('class', 'icon icon-chevron-right');
                $list.hide();
            }
            return false;
        });

        var $selected_campaigns = $('#selected-campaigns');
        $('#activate-multi-select').is(':checked') ? $selected_campaigns.show() : $selected_campaigns.hide();
    },

    buttonRemoveBinding: function() {
        var $buttons = $(".button-remove");
        $buttons.unbind('click');

        $buttons.click(function() {
            var $parent = $(this).parent();
            var item_linked_id = $parent.data('linked-id');
            var $item = $('[data-id="' + item_linked_id + '"]');

            if ($item.length > 0) {
                // linked item is present on the left panel, simulate a click on it
                Core.resetItem($item, null, null);
            }
            else {
                // simply remove the list item and the main selected array item
                var item_id = $parent[0].id;
                delete Core.selectedItems[item_id];

                $parent.remove();
                Core.updateNumberOfSelectedCampaigns();
                Core.hideRightPanelOnNoSelection(null, null);
            }
        });
    },

    getItemInfo: function($item) {
        var isSubItem = $item.hasClass('sub-item');
        var itemId = $item.data('id');
        var connectionId = isSubItem ? $('a.list-group-item[data-id="' + $item.closest('div.sub-item-list').data('for') + '"]').data('connectionId') : $item.data('connectionId');
        var accountName = $item.data('label');
        var item_hash = 'selected-item-list-' + itemId;

        return {id:itemId, connection_id: connectionId, label: accountName, sub_item: isSubItem, item_hash: item_hash};
    },

    itemSelected: function($item, is_selected) {

        var item_info = Core.getItemInfo($item);

        if (is_selected) {
            $item.addClass('active');

            if (Core.selectedItems[item_info.item_hash] === undefined) {
                Core.selectedItems[item_info.item_hash] = item_info;
            }
        }
        else {
            $item.removeClass('active');
            delete Core.selectedItems[item_info.item_hash];
        }
    },

    // update the selected list on the right panel
    updateSelectedList: function() {
        var $list = $('#selected-list');

        // Remove what's not in the main list anymore
        $.each($list.children(), function() {
            var id = $(this).id;
            if (Core.selectedItems[id] === undefined) {
                $(this).remove();
            }
        });

        // add new entries
        for (var id in Core.selectedItems) {
            if (!Core.selectedItems.hasOwnProperty(id)) continue;
            if ($list.find('#' + Core.escapeSelector(id)).length === 0) {
                var item = Core.selectedItems[id];
                $list.append(
                    '<div id="' + id + '" class="selected-item" data-linked-id="' + item.id +
                    '">' + item.label +
                    '<a class="button-remove">'+ '<span class="icon icon-trash"></span>'+
                    '</a>'
                );
            }
        }

        Core.updateNumberOfSelectedCampaigns();

        Core.buttonRemoveBinding();
    },

    escapeSelector: function(selector) {
        return selector.replace(/([ #;?%&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    },

    // get the number of selected items
    getNbOfSelected: function() {
        var nb = 0;
        for (var id in Core.selectedItems) {
            if (!Core.selectedItems.hasOwnProperty(id)) continue;
            nb++;
        }
        return nb;
    },

    // update the number of selected items on the right panel
    updateNumberOfSelectedCampaigns: function() {
        $('#selected-campaigns-title').text(' Selected ' + Core.mainItemName + ' (' + Core.getNbOfSelected() + ' selected)');
    },

    // hide the right panel if there's no selected items
    hideRightPanelOnNoSelection: function($step1, $step2) {
        if (Core.getNbOfSelected() === 0) {
            Core.editButtonMode(false);
            $('#edit-client').text('Edit ' + Core.brandMappings.client);
            $('#assign-client').text('Assign ' + Core.brandMappings.client);
            $('#edit-client').removeClass('btn-danger');
            $('#edit-client').addClass('btn-primary');
            Core.editForm(false);

            if ($step1 === null || $step2 === null) {
                var $modalBody = Core.assignClientModal.find('div.modal-body');
                $step1 = $modalBody.find('div.step-1');
                $step2 = $modalBody.find('div.step-2');
            }

            Core.setupStep1($step1, $step2);
        }

    },

    // clear all selected items
    clearAllSelected: function($items) {
        if ($items === null)
        {
            var $accountList = $('#account-list');
            $items = $accountList.find('div.list-group.item-list a.list-group-item');
        }

        Core.selectedItems = [];
        $items.removeClass('active');
    }
};
