import angular from 'angular';
import './smartcampaign.constants';
import './base/smartcampaign.services';
import './base/smartcampaign.models';
import './base/smartcampaign.resources';
import './base/smartcampaign.components';
import './details/smartcampaign.details.directives';

import smartCampaignHtmlUrl from './base/smartcampaign.html';

angular.module('core.smartcampaign', [
    'core.smartcampaign.constants',
    'core.smartcampaign.services',
    'core.smartcampaign.models',
    'core.smartcampaign.resources',
    'core.smartcampaign.components',
    'core.smartcampaign.directives'
])
    .config([
        '$stateProvider',
        'AppConfig',
        'SmartCampaignRouteParams',
        'SwitchAppServiceProvider',
        function (
            $stateProvider,
            AppConfig,
            SmartCampaignRouteParams,
            SwitchAppServiceProvider
        ) {
            $stateProvider
                .state(SmartCampaignRouteParams.STATE_NAME, {
                    parent:'layout',
                    url: SmartCampaignRouteParams.URL,
                    resolve: {
                        isTARoute: SwitchAppServiceProvider.switchAppFn(SmartCampaignRouteParams.STATE_NAME),
                        pageType: function(PageType) {
                            return PageType.TYPE_ADMIN;
                        },
                        pageEntity: function(PageEntity) {
                            return PageEntity.PAGE_SMART_CAMPAIGNS;
                        },
                        vars: function(SmartCampaignResource) {
                            return SmartCampaignResource.getServices();
                        }
                    },
                    views: {
                        '': {
                            templateUrl: smartCampaignHtmlUrl,
                            controller: 'DesignController'
                        }
                    }
                });
        }]);