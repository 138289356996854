import moment from 'moment';
import {
  MomentDateFormat,
  RelativeDateRangeKey,
} from '@/modules/core/daterange/daterange.constants';
import { BaseDateRangeConfig } from '@/modules/core/daterange/models/BaseDateRangeConfig';
import { getters } from '@/modules/core/app/helpers/store';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';

export class DateRangeConfig extends BaseDateRangeConfig {
  /**
   * @var {string} - ISO Format
   */
  startDate;

  /**
   * @var {string} - ISO Format
   */
  endDate;

  /**
   * @var {string}
   */
  opens;

  /**
   * @var {boolean}
   */
  autoApply;

  /**
   * @var {string}
   */
  buttonClasses;

  /**
   * @var {string}
   */
  applyClass;

  /**
   * @var {string}
   */
  alwaysShowCalendars;

  /**
   * @var {string}
   */
  showCustomRangeLabel;

  /**
   * @var {string}
   */
  linkedCalendars;

  /**
   * @var {boolean}
   */
  showDropdowns;

  /**
   * @var {Date}
   */
  minDate;

  /**
   * @var {Date}
   */
  maxDate;

  /**
   * @var {object}
   */
  locale;

  /**
   * @var {string}
   */
  ranges;

  /**
   * Assign this to ranges prop of date-range-pick to hide range selection
   * @var {boolean}
   */
  showRanges;

  constructor(config = {}) {
    super(config);
    this.autoApply = config.autoApply === undefined ? this._shouldAutoApply() : false;
    this.buttonClasses = config.buttonClasses;
    this.applyClass = config.applyClass;
    this.alwaysShowCalendars = config.alwaysShowCalendars;
    this.showCustomRangeLabel = config.showCustomRangeLabel;
    this.linkedCalendars = config.linkedCalendars || false;
    this.showDropdowns = config.showDropdowns || false;
    this.minDate = config.minDate || moment().subtract(15, 'years').toDate();
    this.maxDate = config.maxDate;
    this.locale = this._getDateRangePickerLocale();
    this.ranges = config.ranges;
    this.update = config.onUpdate;
    this.showRanges = config.showRanges === undefined || config.showRanges;

    // Ensure that start and end dates fall between min and max dates
    this.startDate = this._getValidDate(config.startDate, this.minDate);
    this.endDate = this._getValidDate(config.endDate, this.maxDate);
    this.model = {
      startDate: this.startDate ? moment(this.startDate) : null,
      endDate: this.endDate ? moment(this.endDate) : null,
    };

    if (config.getOpenDirection) {
      this.getOpenDirection = config.getOpenDirection;
    }
    this.opens = config.opens || this.getOpenDirection();
  }

  /**
   * Called when date range is destroyed
   */
  destructor() {}

  /**
   * Get a specific relative date range by id
   * @param key
   * @returns {T|*}
   */
  getRelativeDateRange(key) {
    return this.relativeRanges[key]; // || this.relativeRanges[RelativeDateRangeKey.DEFAULT];
  }

  /**
   *  @returns {*}
   */
  getRanges() {
    const ranges = {};
    ranges[RelativeDateRangeKey.TODAY] = {
      key: RelativeDateRangeKey.TODAY,
      label: this.relativeRanges[RelativeDateRangeKey.TODAY].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.TODAY),
    };
    ranges[RelativeDateRangeKey.YESTERDAY] = {
      key: RelativeDateRangeKey.YESTERDAY,
      label: this.relativeRanges[RelativeDateRangeKey.YESTERDAY].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.YESTERDAY),
    };
    ranges[RelativeDateRangeKey.TOMORROW] = {
      key: RelativeDateRangeKey.TOMORROW,
      label: this.relativeRanges[RelativeDateRangeKey.TOMORROW].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.TOMORROW),
    };
    ranges[RelativeDateRangeKey.LAST_7_DAYS] = {
      key: RelativeDateRangeKey.LAST_7_DAYS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_7_DAYS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_7_DAYS),
    };
    ranges[RelativeDateRangeKey.LAST_14_DAYS] = {
      key: RelativeDateRangeKey.LAST_14_DAYS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_14_DAYS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_14_DAYS),
    };
    ranges[RelativeDateRangeKey.LAST_30_DAYS] = {
      key: RelativeDateRangeKey.LAST_30_DAYS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_30_DAYS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_30_DAYS),
    };
    ranges[RelativeDateRangeKey.LAST_90_DAYS] = {
      key: RelativeDateRangeKey.LAST_90_DAYS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_90_DAYS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_90_DAYS),
    };
    ranges[RelativeDateRangeKey.LAST_180_DAYS] = {
      key: RelativeDateRangeKey.LAST_180_DAYS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_180_DAYS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_180_DAYS),
    };
    ranges[RelativeDateRangeKey.LAST_WEEK] = {
      key: RelativeDateRangeKey.LAST_WEEK,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_WEEK].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_WEEK),
    };
    ranges[RelativeDateRangeKey.LAST_MONTH] = {
      key: RelativeDateRangeKey.LAST_MONTH,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_MONTH].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_MONTH),
    };
    ranges[RelativeDateRangeKey.LAST_3_MONTHS] = {
      key: RelativeDateRangeKey.LAST_3_MONTHS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_3_MONTHS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_3_MONTHS),
    };
    ranges[RelativeDateRangeKey.LAST_QUARTER] = {
      key: RelativeDateRangeKey.LAST_QUARTER,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_QUARTER].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_QUARTER),
    };
    ranges[RelativeDateRangeKey.LAST_6_MONTHS] = {
      key: RelativeDateRangeKey.LAST_6_MONTHS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_6_MONTHS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_6_MONTHS),
    };
    ranges[RelativeDateRangeKey.LAST_12_MONTHS] = {
      key: RelativeDateRangeKey.LAST_12_MONTHS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_12_MONTHS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_12_MONTHS),
    };
    ranges[RelativeDateRangeKey.LAST_YEAR] = {
      key: RelativeDateRangeKey.LAST_YEAR,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_YEAR].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_YEAR),
    };
    ranges[RelativeDateRangeKey.LAST_13_MONTHS] = {
      key: RelativeDateRangeKey.LAST_13_MONTHS,
      label: this.relativeRanges[RelativeDateRangeKey.LAST_13_MONTHS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.LAST_13_MONTHS),
    };
    ranges[RelativeDateRangeKey.YEAR_TO_DATE] = {
      key: RelativeDateRangeKey.YEAR_TO_DATE,
      label: this.relativeRanges[RelativeDateRangeKey.YEAR_TO_DATE].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.YEAR_TO_DATE),
    };
    ranges[RelativeDateRangeKey.THIS_WEEK] = {
      key: RelativeDateRangeKey.THIS_WEEK,
      label: this.relativeRanges[RelativeDateRangeKey.THIS_WEEK].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.THIS_WEEK),
    };
    ranges[RelativeDateRangeKey.THIS_MONTH] = {
      key: RelativeDateRangeKey.THIS_MONTH,
      label: this.relativeRanges[RelativeDateRangeKey.THIS_MONTH].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.THIS_MONTH),
    };
    if (!DateUtil.isFirstOfMonth(getters.session.getInstanceTimezone())) {
      ranges[RelativeDateRangeKey.THIS_MONTH_EXCLUDE_TODAY] = {
        key: RelativeDateRangeKey.THIS_MONTH_EXCLUDE_TODAY,
        label: this.relativeRanges[RelativeDateRangeKey.THIS_MONTH_EXCLUDE_TODAY].label,
        ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.THIS_MONTH_EXCLUDE_TODAY),
      };
    }
    ranges[RelativeDateRangeKey.THIS_QUARTER] = {
      key: RelativeDateRangeKey.THIS_QUARTER,
      label: this.relativeRanges[RelativeDateRangeKey.THIS_QUARTER].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.THIS_QUARTER),
    };
    ranges[RelativeDateRangeKey.THIS_YEAR] = {
      key: RelativeDateRangeKey.THIS_YEAR,
      label: this.relativeRanges[RelativeDateRangeKey.THIS_YEAR].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.THIS_YEAR),
    };
    ranges[RelativeDateRangeKey.NEXT_7_DAYS] = {
      key: RelativeDateRangeKey.NEXT_7_DAYS,
      label: this.relativeRanges[RelativeDateRangeKey.NEXT_7_DAYS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.NEXT_7_DAYS),
    };
    ranges[RelativeDateRangeKey.NEXT_30_DAYS] = {
      key: RelativeDateRangeKey.NEXT_30_DAYS,
      label: this.relativeRanges[RelativeDateRangeKey.NEXT_30_DAYS].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.NEXT_30_DAYS),
    };
    ranges[RelativeDateRangeKey.NEXT_WEEK] = {
      key: RelativeDateRangeKey.NEXT_WEEK,
      label: this.relativeRanges[RelativeDateRangeKey.NEXT_WEEK].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.NEXT_WEEK),
    };
    ranges[RelativeDateRangeKey.NEXT_MONTH] = {
      key: RelativeDateRangeKey.NEXT_MONTH,
      label: this.relativeRanges[RelativeDateRangeKey.NEXT_MONTH].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.NEXT_MONTH),
    };
    ranges[RelativeDateRangeKey.NEXT_YEAR] = {
      key: RelativeDateRangeKey.NEXT_YEAR,
      label: this.relativeRanges[RelativeDateRangeKey.NEXT_YEAR].label,
      ...this.getDateRangeFromRelativeRange(RelativeDateRangeKey.NEXT_YEAR),
    };

    if (this.minDate) {
      const keys = Object.keys(ranges);
      keys.forEach((rangeKey) => {
        if (this.minDate.getTime() > new Date(ranges[rangeKey].start).getTime()) {
          ranges[rangeKey].isDisabled = true;
        }
      });
    }

    if (this.maxDate) {
      const keys = Object.keys(ranges);
      keys.forEach((rangeKey) => {
        if (this.maxDate.getTime() < new Date(ranges[rangeKey].end).getTime()) {
          ranges[rangeKey].isDisabled = true;
        }
      });
    }
    return ranges;
  }

  updateModel() {
    this.model = { startDate: moment(this.startDate), endDate: moment(this.endDate) };
  }

  /**
   *  Sets priorPeriod and priorYear date ranges.
   *  If these date ranges are beyond the comparison date
   *  range limits, they will not display in the date picker
   * @param startDate
   * @param endDate
   * @param relativeDateRangeKey
   * @returns {*}
   */
  getComparisonRanges(startDate, endDate, relativeDateRangeKey) {
    const [priorPeriodStart, priorPeriodEnd] = this.calculatePeriod(
      startDate,
      endDate,
      relativeDateRangeKey,
      RelativeDateRangeKey.PRIOR_PERIOD
    );

    const [priorYearStart, priorYearEnd] = this.calculatePeriod(
      startDate,
      endDate,
      relativeDateRangeKey,
      RelativeDateRangeKey.PRIOR_YEAR
    );

    // These get overriden on the fly
    const priorPeriodRange = this.relativeRanges[RelativeDateRangeKey.PRIOR_PERIOD];
    priorPeriodRange.start = priorPeriodStart;
    priorPeriodRange.end = priorPeriodEnd;

    const priorYearRange = this.relativeRanges[RelativeDateRangeKey.PRIOR_YEAR];
    priorYearRange.start = priorYearStart;
    priorYearRange.end = priorYearEnd;

    return {
      [priorPeriodRange.key]: {
        key: priorPeriodRange.key,
        label: priorPeriodRange.label,
        ...priorPeriodRange,
      },
      [priorYearRange.key]: {
        key: priorYearRange.key,
        label: priorYearRange.label,
        ...priorYearRange,
      },
    };
  }

  /**
   * Calculates a comparison period based on the length of a given date range
   * @param {moment.Moment} startDate
   * @param {moment.Moment} endDate
   * @param relativeDateRangeKey
   * @param comparisonRelativeRangeKey
   * @returns {moment.Moment[]}
   */
  calculatePeriod(startDate, endDate, relativeDateRangeKey, comparisonRelativeRangeKey) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    let range = {};
    let numDaysDiff = 0;

    switch (comparisonRelativeRangeKey) {
      case RelativeDateRangeKey.PRIOR_PERIOD:
        if (relativeDateRangeKey === RelativeDateRangeKey.CUSTOM) {
          numDaysDiff = endDate.diff(startDate, 'days');
          range.end = moment(startDate).subtract(1, 'days');
          range.start = moment(startDate).subtract(numDaysDiff + 1, 'days');
        } else {
          range = this._getPriorDateRangeFromRelativeRange(relativeDateRangeKey);
        }
        break;

      case RelativeDateRangeKey.FUTURE_PERIOD:
        numDaysDiff = endDate.diff(startDate, 'days');
        range.start = endDate.add(1, 'day');
        range.end = endDate.add(numDaysDiff + 1, 'days');
        break;

      case RelativeDateRangeKey.PRIOR_YEAR:
        range.start = moment(startDate.subtract(1, 'years').toDate());
        range.end = moment(endDate.subtract(1, 'years').toDate());
        break;

      default:
        Logger.log(`Unsupported daterange key: ${comparisonRelativeRangeKey}`, Logger.LEVEL_ERROR);
        break;
    }

    return [range.start, range.end];
  }

  /**
   * Determines if the selected date range is custom or matches a relative date range
   * Returns a relative date range object if valid
   * If not, the date range is considered as custom.
   *
   * @param {moment.Moment} startDate
   * @param {moment.Moment} endDate
   * @returns {RelativeDateRange|null}
   */
  getRelativeDateRangeFromDateRange(startDate, endDate) {
    return (
      Object.values(this.ranges).find(
        (range) => range.start?.isSame(startDate, 'date') && range.end?.isSame(endDate, 'date')
      ) || null
    );
  }

  /**
   * @param key
   * @returns {{start: moment.Moment, end: moment.Moment}}
   * @private
   */
  _getPriorDateRangeFromRelativeRange(key) {
    let comparisonStartDate = moment(this.relativeRanges[key].comparison_start);
    let comparisonEndDate = moment(this.relativeRanges[key].comparison_end);

    // If DEFAULT, make sure to use current dashbaord date (since it may have been updated in the frontend)
    if (key === RelativeDateRangeKey.DEFAULT) {
      // Need to calculate default comparison start and end date
      const currentDateRange = this.getDateRangeFromRelativeRange(key);

      if (this._isMonthDateRange(currentDateRange.start, currentDateRange.end)) {
        const priorPeriod = this._getMonthPriorPeriod(currentDateRange.start, currentDateRange.end);
        comparisonStartDate = priorPeriod.start;
        comparisonEndDate = priorPeriod.end;
      } else {
        const diff = currentDateRange.end.diff(currentDateRange.start, 'days') + 1;
        comparisonStartDate = currentDateRange.start.subtract(diff, 'days');
        comparisonEndDate = currentDateRange.end.subtract(diff, 'days');
      }
    }

    return {
      start: comparisonStartDate,
      end: comparisonEndDate,
    };
  }

  /**
   * Check if current date range is a month long
   * @param start
   * @param end
   * @returns {boolean}
   * @private
   */
  _isMonthDateRange(start, end) {
    const startDate = moment(start);
    const endDate = moment(end);
    return (
      startDate.format('M') === endDate.format('M') && // same month
      startDate.format('D') === '1' && // first day
      endDate.format('D') === endDate.endOf('month').format('D')
    ); // end of month
  }

  /**
   * Get month from dates
   * @param end
   * @returns {{start: moment.Moment, end: moment.Moment}}
   * @private
   */
  _getMonthPriorPeriod(end) {
    const previousMonth = end.subtract(1, 'months');
    const firtDayOfMonth = previousMonth.startOf('month');
    const lastDayOfMonth = moment(firtDayOfMonth).endOf('month');

    return {
      start: firtDayOfMonth,
      end: lastDayOfMonth,
    };
  }

  /**
   *
   * @returns {{fromLabel: string, toLabel: string, cancelLabel: string, firstDay: number, applyLabel: string, format: string, customRangeLabel: string, monthNames: string[]}}
   * @private
   */
  _getDateRangePickerLocale() {
    /*
      vue2-daterange-picker uses Javascript Date Format that is quite not same as what moment does.
      So, changing the format to lower case to make it work with vue2-daterange-picker.
      https://innologica.github.io/vue2-daterange-picker/#props
    */
    return {
      format: MomentDateFormat.MONTH_DAY_YEAR.toLowerCase(),
      applyLabel: i18n.$t('Apply'),
      cancelLabel: i18n.$t('Cancel'),
      customRangeLabel: i18n.$t('Custom Range'),
      fromLabel: i18n.$t('From'),
      toLabel: i18n.$t('To'),
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.months(),
      firstDay: this._isDefaultWeeklyStartDayMonday(),
    };
  }

  /**
   * If the first day of the week is monday, return 1; otherwise, return 0 for sunday.
   * @returns {number}
   * @private
   */
  _isDefaultWeeklyStartDayMonday() {
    return getters.session.getUserSettings().weekStartsOnMonday ? 1 : 0;
  }

  /**
   * Open in different directions based on window size
   * @returns {*}
   */
  getOpenDirection() {
    if (window.innerWidth < 768) {
      return 'center';
    }
    if (window.innerWidth < 1024) {
      return 'right';
    }
    return 'left';
  }

  updateOpens() {
    this.opens = this.getOpenDirection();
  }

  /**
   * In small screens, always auto apply date selection
   * @returns {boolean}
   * @private
   */
  _shouldAutoApply() {
    return window.innerWidth < 768;
  }

  /**
   * @param date {string}
   * @param defaultDate {Date}
   * @private
   */
  _getValidDate(date, defaultDate) {
    const momentDate = moment(date);
    const minMomentDate = moment(this.minDate);
    const maxMomentDate = moment(this.maxDate);
    defaultDate = moment(defaultDate);

    if (momentDate.isBefore(minMomentDate) || (this.maxDate && momentDate.isAfter(maxMomentDate))) {
      return defaultDate.format(MomentDateFormat.ISO);
    }

    return date;
  }
}
