'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import { WidgetTypeGrouping } from 'coreModules/design/widget/design.widget.constants';

angular.module('design.layout.services', [])

    .factory('LayoutFactory', LayoutFactory);


/**
 * @ngInject
 */
function LayoutFactory(
    $rootScope,
    $timeout,
    $interval,
    $isoEvents,
    $WidgetEvents,
    Restangular,
    $LayoutEvents,
    DesignFactory,
    WidgetFactory,
    WidgetType,
    $DrawOptionPanelEvents,
    $PageGenerateThumbnailEvents,
    PubSub,
    WidgetUtilService,
    DrawOption,
    PageEvents
) {
    var resource = Restangular.all('dash').all('layouts');

    var state = _getDefaultState();

    function _getDefaultState() {
        return {
            // isChangingLayout: true,
            // layoutHasChanged: false
        };
    }

    function resetState() {
        state = _getDefaultState();
    }

    var data = {
        sortedLayouts: [],
        resizeWidgetPosition: {x: 0, y: 0}
    };

    var getData = function() {
        return data;
    };

    registerEvents();

    return {
        state: state,
        getData: getData,
        updateLayoutWidgets: updateLayoutWidgets,
        userCanEdit: userCanEdit,
        resetState: resetState,
        setActiveLayout: setActiveLayout,
        changeLayout: changeLayout,
        addWidget: addWidget,
        updateWidget: updateWidget,
        swapWidget: swapWidget,
        removeWidget: removeWidget,
        removeWidgetById: removeWidgetById,
        updateWidgetSize: updateWidgetSize,
        save: save,
        saveList: saveList,
        saveWidgets: saveWidgets,
        remove: remove,
        updateDrawOptions: updateDrawOptions,
        $rebuildAllWidgets: $rebuildAllWidgets,
        $rebuildChartWidgets: $rebuildChartWidgets,
        $rebuildDataGridWidgets: $rebuildDataGridWidgets,
        getDataSourcedWidgetsCount: getDataSourcedWidgetsCount,
        layoutExecutiveSummaryCanDisplay: layoutExecutiveSummaryCanDisplay
    };

    function updateLayoutWidgets() {
        data.layoutWidgets = _.map(DesignFactory.getCurrentWidgets(), function (widget) {
            // This converts width/height constant dimensions to pixel values
            widget.computedWidth = WidgetUtilService.getWidgetWidth(widget.width);
            widget.computedHeight = DesignFactory.props.hasVariableHeightWidgets
                ? 'auto'
                : WidgetUtilService.getWidgetHeight(widget.height);

            return widget;
        });

        data.layoutWidgets = _.filter(data.layoutWidgets, widget => !widget.metadata.dynamic.shouldHide);

        // Normal layouts will already have been sorted by isotope
        if (DesignFactory.props.hasVariableHeightWidgets) {
            data.layoutWidgets = _.sortBy(data.layoutWidgets, 'display_order');
        }
    }

    /**
     * Can user edit layout
     * @returns {boolean}
     */
    function userCanEdit() {
        return DesignFactory.getCurrentPage().can_be_edited;
    }

    /**
     * Add widget to layout widget array
     * @param widget
     */
    function addWidget(widget) {
        const layoutToAddTo = DesignFactory.getLayout(widget.layout_id);

        // Make sure we have the most up to date display_order
        widget.display_order = DesignFactory.getNextWidgetDisplayOrder(layoutToAddTo.widgets);

        let elementExistsCheck;

        // Leave time for widget create modal animation exit before adding widget
        $timeout(function() {
            // When there are no widgets on a layout, 'widgets' is returned as an array from the backend.
            if (_.isArray(layoutToAddTo.widgets)) {
                layoutToAddTo.widgets = {};
            }
            layoutToAddTo.widgets[widget.id] = widget;

            // Check if we are currently looking at the layout
            if (DesignFactory.getCurrentLayout().id === widget.layout_id) {
                updateLayoutWidgets();

                if (angular.isDefined(elementExistsCheck)) {
                    return;
                }

                // every 100 ms we test up to 20 times whether the widget has made its way to the DOM yet
                // once it's there we can scroll down to it using the offsetTop
                elementExistsCheck = $interval(() => {
                    const $newWidgetElement = WidgetFactory.$getElement(widget.id);
                    if ($newWidgetElement.length) {
                        stopChecking();

                        if (!$newWidgetElement.isInViewport()) {
                            $('#right-frame').animate({scrollTop: $newWidgetElement[0].offsetTop}, 800, function() {
                                $newWidgetElement.addClass('animPulse');
                                $timeout(function() {
                                    $newWidgetElement.removeClass('animPulse');
                                }, 800, false);
                            });
                        }
                    }
                }, 100, 20);

                const stopChecking = () => {
                    if (angular.isDefined(elementExistsCheck)) {
                        $interval.cancel(elementExistsCheck);
                        elementExistsCheck = undefined;
                    }
                };
            }

            PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: widget.layout_id, page: DesignFactory.getCurrentPage() });
        }, 600, false);
    }

    /**
     * Update widget model from edit
     * @param e
     * @param widget
     */
    function updateWidget(widget) {
        var widgets = DesignFactory.getCurrentWidgets();
        widgets[widget.id] = widget;
        updateWidgetSize(widget);
        updateLayoutWidgets();
        WidgetFactory.$rebuildWidget(widget.id, false);

        PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: widget.layout_id, page: DesignFactory.getCurrentPage() });
    }

    /**
     * @param oldWidgetId
     * @param newWidget
     */
    function swapWidget(oldWidgetId, newWidget) {
        var widgets = DesignFactory.getCurrentWidgets();
        delete widgets[oldWidgetId];
        widgets[newWidget.id] = newWidget;
        updateWidgetSize(newWidget);
        updateLayoutWidgets();
    }

    /**
     * Update widget height and width in layout
     * @param widget
     */
    function updateWidgetSize(widget) {
        var widgets = DesignFactory.getCurrentWidgets();
        var newWidth = widget.width;
        var newHeight = widget.height;
        var prevWidth = widgets[widget.id].width;
        var prevHeight = widgets[widget.id].height;

        widgets[widget.id].width = newWidth;
        widgets[widget.id].height = newHeight;

        var $widget = WidgetFactory.$getElement(widget.id);
        $widget.css({
            'width': WidgetUtilService.getWidgetWidth(newWidth),
            'height': WidgetUtilService.getWidgetHeight(newHeight)
        });

        if (newWidth !== prevWidth || newHeight !== prevHeight) {
            $rootScope.$broadcast($isoEvents.RESIZE);
        }
    }

    /**
     * Remove widget from the layout the widget is from
     * @param widget
     */
    function removeWidget(widget) {
        var layoutToRemoveFrom = DesignFactory.getLayout(widget.layout_id);
        delete layoutToRemoveFrom.widgets[widget.id];

        // Reset widget array if we are currently looking at the layout
        if (DesignFactory.getCurrentLayout().id == widget.layout_id) {
            updateLayoutWidgets();
        }
    }

    /**
     * @param widgetId
     */
    function removeWidgetById(widgetId) {
        removeWidget(DesignFactory.getWidget(widgetId));
    }

    /**
     * Changing tabs sets a new current layout
     * @param layout
     * @returns {Boolean} didChangeLayout Returned value if new layout has been changed
     */
    function setActiveLayout(layout) {
        var currentLayout = DesignFactory.getCurrentLayout();
        if (currentLayout && layout.id === currentLayout.id) {
            return false;
        }
        PubSub.emit($LayoutEvents.IS_CHANGING_LAYOUT);

        DesignFactory.setCurrentLayoutId(layout.id);
        updateLayoutWidgets();

        PubSub.emit($LayoutEvents.CHANGED, DesignFactory.getCurrentLayout(), true);
        PubSub.emit(PageEvents.PERFORMANCE_TRACKING, {
            event: $LayoutEvents.CHANGED,
            payload: DesignFactory.getCurrentLayout()
        });

        return true;
    }

    /**
     * Sets all required states and configs to change layout
     * @param layout
     * @param setAsNew
     */
    function changeLayout(layout, setAsNew) {
        var newClass = setAsNew ? ' new' : '';

        // Set the layout to be shown
        setActiveLayout(layout);

        // Update layout tab style
        $timeout(function() {
            angular.element('div.page-inner li').removeClass('active');
            $getLayoutTab(layout.id).addClass('active' + newClass);
        }, 0, false);
    }

    //
    // RESTANGULAR
    //
    function save(model) {
        return _.isNull(model.id) ? resource.post(model) : resource.all(model.id).post(model);
    }

    function saveList(modelList, displayOrderSave = false) {
        return resource.all('savelist').post({model: modelList, 'display_order_save': displayOrderSave});
    }

    function saveWidgets(model) {
        return resource.all(model.id).customPOST(model, 'widgets');
    }

    function remove(id) {
        return resource.all(id).remove();
    }

    /**
     * Updates a layout's draw options
     * @param model
     * @param location
     * @returns {IPromise<any>}
     */
    /**
     * TODO: RESTANGULAR to be moved into a Ressource module
     */
    function updateDrawOptions(model, location) {
        model.ignoreLoadingBar = true;
        return resource.all(model.id).all(location).customPOST(model, 'drawoptions');
    }

    /**
     * Streamline broadcast event for rebuilding all widgets in a layout
     */
    function $rebuildAllWidgets() {
        // NOTE: this rebuilds ALL widgets in a layout by triggering each $on event in each widget
        $rootScope.$broadcast($WidgetEvents.WIDGET_REBUILD);
        PubSub.emit($WidgetEvents.WIDGET_REBUILD);
    }

    /**
     * Streamline broadcast event for rebuilding only chart widgets in a layout
     */
    function $rebuildChartWidgets() {
        _.each(DesignFactory.getCurrentWidgets(), function(widget) {
            if (WidgetUtilService.isChartWidget(widget.type)) {
                WidgetFactory.$rebuildWidget(widget.id);
            }
        });
    }

    /**
     * Streamline broadcast event for rebuilding only datagrid widgets in a layout
     */
    function $rebuildDataGridWidgets() {
        _.each(DesignFactory.getCurrentWidgets(), function(widget) {
            if (widget.type === WidgetType.DATAGRID) {
                WidgetFactory.$rebuildWidget(widget.id);
            }
        });
    }

    /**
     * Returns the DOM element of a specified layout tab
     * @param id
     */
    function $getLayoutTab(id) {
        return angular.element('#layout-tab-' + id);
    }

    /**
     * Set new draw options to layout
     * @param layoutId
     * @param drawOptions
     * @param drawLocation
     * @param {Boolean} [autoPersist]
     */
    function updateLayoutDrawOptions(layoutId, drawOptions, drawLocation, autoPersist) {
        DesignFactory.getLayout(layoutId).metadata.draw_options = drawOptions;
        if (autoPersist) {
            _persistDrawOptionChanges(layoutId, drawOptions, drawLocation);
        }
        if (drawOptions[DrawOption.SHOW_WIDGETS_WITH_NO_DATA].value) {
            const layout = DesignFactory.getCurrentLayout();
            layout.widgets = _.mapValues(layout.widgets, widget => {
                widget.metadata.dynamic.shouldHide = false;
                return widget
            })
        } else if (!drawOptions[DrawOption.SHOW_WIDGETS_WITH_NO_DATA].value) {
            const layout = DesignFactory.getCurrentLayout();
            layout.widgets = _.mapValues(layout.widgets, widget => {
                if (widget.type === WidgetType.BIGNUMBER && widget.metadata.draw_options.allEmptyData) {
                    widget.metadata.dynamic.shouldHide = true;
                }
                return widget
            })
        }
        updateLayoutWidgets();
    }

    /**
     * Set new draw options to layout
     * @param layoutId
     * @param drawOptions
     * @param drawLocation
     * @param {Boolean} [autoPersist]
     */
    function updateExportDrawOptions(layoutId, drawOptions, drawLocation, autoPersist) {
        DesignFactory.getLayout(layoutId).metadata.export_options.draw_options = drawOptions;
        if (autoPersist) {
            _persistDrawOptionChanges(layoutId, drawOptions, drawLocation);
        }
    }

    /**
     *
     * @param layout
     * @returns {*}
     */
    function getDataSourcedWidgetsCount(layout) {
        return _.filter(layout.widgets,
            widget => widget.type && WidgetFactory.getWidgetTypeGrouping(widget.type) === WidgetTypeGrouping.DATASOURCED)
            .length;
    }

    /**
     * Save Draw options changes base on location of panel
     * @private
     */
    function _persistDrawOptionChanges(layoutId, drawOptions, drawLocation) {
        var model = {};
        model.id = layoutId;
        model.draw_options = drawOptions;
        updateDrawOptions(model, drawLocation);
    }

    /**
     * Register PubSub Events
     */
    function registerEvents() {
        PubSub.on($DrawOptionPanelEvents.LAYOUT_DRAW_OPTIONS, function (data) {
            updateLayoutDrawOptions(data.layoutId, data.drawOptions, data.drawLocation, data.autoPersist);
            if (window.isNUI) {
                const currentPage = DesignFactory.getCurrentPage();
                PubSub.emit("SegmentEvents", {
                    event: "EditSectionDrawOptions",
                    payload: {
                        dashboard_id: currentPage.id,
                        dashboard_name: currentPage.title,
                        ...data.drawOptions
                    }
                });
            }
        });
        PubSub.on($DrawOptionPanelEvents.EXPORT_DRAW_OPTIONS, function (data) {
            updateExportDrawOptions(data.layoutId, data.drawOptions, data.drawLocation, data.autoPersist);
        });
    }

    function layoutExecutiveSummaryCanDisplay(id = null) {
        const layoutId = id || DesignFactory.getCurrentLayout().id;
        const summary = DesignFactory.getLayout(layoutId).executive_summary;
        return summary && !_.isEmpty(summary.items);
    }
}
