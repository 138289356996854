import { isEmpty } from 'lodash';
import { WidgetMetadataFactory } from '@/modules/ta/widget/factories/WidgetMetadataFactory';
import { GenericDataModel } from '@/modules/core/app/models/GenericDataModel';
import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';
import { DrawOption, WidgetType } from '@/modules/ta/widget/widget.constants';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import { WidgetFilterSet } from '@/modules/ta/widget/models/metadata/dynamic/filters/WidgetFilterSet';
import { DataGridData } from '@/modules/ta/widget/models/DataGridData';

export class Widget extends BaseEntityModel {
  /**
   * @var {number}
   */
  id;

  /**
   * @var {string}
   */
  unique_id;

  /**
   * @var {?string}
   */
  layout_id;

  /**
   * @var {?string}
   */
  report_id;

  /**
   * @var {?string}
   */
  type;

  /**
   * @var {number}
   */
  width;

  /**
   * @var {number}
   */
  height;

  /**
   * @var {number}
   */
  display_order;

  /**
   * @var {?string}
   */
  title;

  /**
   * @var {boolean}
   */
  is_predefined;

  /**
   * @var {boolean}
   */
  is_in_library;

  /**
   * @var {boolean}
   */
  created_from_library;

  /**
   * @var {BaseWidgetMetadata}
   */
  metadata;

  /**
   * @var {datetime}
   */
  created_at;

  /**
   * @var {datetime}
   */
  updated_at;

  /**
   * @var {number}
   */
  library_download_count;

  /**
   * @var {?string}
   */
  data_type;

  /**
   * @var {?string}
   */
  data_source_id;

  /**
   * @var {?string}
   */
  data_view_id;

  /**
   * @var {string}
   */
  type_display;

  /**
   * @var {string}
   */
  formatted_created_at;

  /**
   * @var {string}
   */
  formatted_updated_at;

  /**
   * @var {boolean}
   */
  has_live_integration;

  /**
   * @var {GenericDataModel[]|DataGridData}
   */
  cached_data;

  /**
   * This field only exist at front-end and mainly used to edit filters in widget builder
   * @type {WidgetFilterSet}
   */
  filter_set;

  /**
   * @var {string}
   */
  assignments;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.unique_id = model.unique_id;
    this.layout_id = model.layout_id;
    this.report_id = model.report_id;
    this.type = model.type;
    this.width = model.width;
    this.height = model.height;
    this.display_order = model.display_order;
    this.title = model.title;
    this.is_predefined = model.is_predefined;
    this.is_in_library = model.is_in_library;
    this.created_from_library = model.created_from_library;
    this.metadata = WidgetMetadataFactory.build(model);
    this.created_at = model.created_at;
    this.updated_at = model.updated_at;
    this.library_download_count = model.library_download_count;
    this.data_type = model.data_type ?? model.metadata?.data_source?.type;
    this.data_source_id = model.data_source_id ?? model.metadata?.data_source?.id;
    this.data_view_id = model.data_view_id ?? model.metadata?.data_source?.data_view;
    this.type_display = model.type_display;
    this.formatted_created_at = model.formatted_created_at;
    this.formatted_updated_at = model.formatted_updated_at;
    this.has_live_integration = model.has_live_integration;
    this.assignments = model.assignments;
    this.setFilterSetFromDataSource();
    this.buildCachedData(model.cached_data);
  }

  buildCachedData(data) {
    if (data) {
      if (data.aaData) {
        this.cached_data = new DataGridData(data);
      } else {
        this.cached_data = Array.isArray(data)
          ? data.map((datum) => new GenericDataModel(datum))
          : new GenericDataModel(data);
      }
    } else {
      this.cached_data = null;
    }
  }

  /**
   * @returns {boolean}
   */
  isChartWidget() {
    return (
      this.isSliceChartWidget() ||
      this.isSerialChartWidget() ||
      this.isGeoChartWidget() ||
      this.isGaugeChartWidget()
    );
  }

  /**
   * Helper function to tell us if a chart is a slice chart
   * @returns {boolean}
   */
  isSliceChartWidget() {
    return this.type === WidgetType.TYPE_PIECHART || this.type === WidgetType.TYPE_FUNNELCHART;
  }

  /**
   * Helper function to tell us if a chart is a slice chart
   * @returns {boolean}
   */
  isFunnelChart() {
    return this.type === WidgetType.TYPE_FUNNELCHART;
  }

  /**
   * Helper function to tell us if a chart is a pie chart
   * @returns {boolean}
   */
  isPieChart() {
    return this.type === WidgetType.TYPE_PIECHART;
  }

  /**
   * Helper function to tell us if a chart is a serial chart
   * @returns {boolean}
   */
  isSerialChartWidget() {
    return (
      this.type === WidgetType.TYPE_BARCHART ||
      this.type === WidgetType.TYPE_BUBBLECHART ||
      this.type === WidgetType.TYPE_LINECHART ||
      this.type === WidgetType.TYPE_COMBINATIONCHART
    );
  }

  /**
   * @returns {boolean}
   */
  isGaugeChartWidget() {
    return this.type === WidgetType.TYPE_GAUGECHART;
  }

  /**
   * @returns {boolean}
   */
  isDataGridWidget() {
    return this.type === WidgetType.TYPE_DATAGRID;
  }

  /**
   * @returns {boolean}
   */
  isGeoChartWidget() {
    return this.type === WidgetType.TYPE_GEOCHART;
  }

  /**
   * @returns {boolean}
   */
  isBigNumberWidget() {
    return this.type === WidgetType.TYPE_BIGNUMBER;
  }

  /**
   * @returns {boolean}
   */
  isAdminWidget() {
    return this.type === WidgetType.TYPE_ACCOUNTMANAGER;
  }

  isDataSourcedWidget() {
    return (
      this.isBigNumberWidget() ||
      this.isDataGridWidget() ||
      this.isChartWidget() ||
      this.isGeoChartWidget()
    );
  }

  /**
   * @returns {boolean}
   */
  hasDataSource() {
    return this.isDataSourcedWidget() || (this.isMediaWidget() && this.data_source_id);
  }

  /**
   * @returns {boolean}
   */
  isUsingSampleData() {
    return (
      this.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA] ||
      this.metadata.draw_options[DrawOption.SHOW_SAMPLE_DATA]
    );
  }

  canFilterData() {
    return !this.has_live_integration && this.isDataSourcedWidget() && !this.isAdminWidget();
  }

  /**
   * @returns {boolean}
   */
  isMediaWidget() {
    return this.type === WidgetType.TYPE_MEDIA;
  }

  /**
   * @returns {boolean}
   */
  hasFilters() {
    return !!this.metadata.filter_set_id;
  }

  /**
   * @returns {Array|null}
   */
  getFilters() {
    return this.hasFilters() ? this.metadata.dynamic.filters : null;
  }

  /**
   * @returns {Array.<Alert>}
   */
  getAlerts() {
    return this.metadata?.dynamic?.alerts;
  }

  /**
   * @returns {ApiColumn[]}
   */
  getSelectedColumns() {
    return this.metadata.data_columns.selected;
  }

  /**
   * @returns {ApiColumn[]}
   */
  getGroupedColumns() {
    return this.metadata.data_columns.grouped;
  }

  /**
   * @returns {*}
   */
  isMultiGrouped() {
    return this.metadata.is_multi_grouped;
  }

  /**
   * @returns {boolean}
   */
  isDrillDownEnabled() {
    return !!(this.getGroupedColumns()?.length > 1 && !this.isMultiGrouped());
  }

  /**
   * @returns {boolean}
   */
  hasDateRangeOverride() {
    return this.metadata.is_overriding_date_range;
  }

  /**
   * @param {ApiColumn} column
   * @returns {boolean}
   */
  hasRoundedMetric(column) {
    return !!this.metadata.rounded_metrics?.includes(column.field);
  }

  /**
   * @returns {boolean}
   */
  canEdit() {
    return this.can_be_edited;
  }

  getDrawOptions() {
    return this.metadata.draw_options;
  }

  /**
   * @returns {string}
   */
  getTimeGrouping() {
    return this.metadata.time_grouping;
  }

  /**
   * @returns {boolean}
   */
  canProbeData() {
    return (
      !this.metadata.is_overriding_date_range &&
      !this.has_live_integration &&
      (this.data_type === DataSourceType.SERVICE_DATA ||
        this.data_type === DataSourceType.CATEGORY_DATA ||
        this.data_type === DataSourceType.LEADS ||
        this.data_type === DataSourceType.GOAL_DATA ||
        this.data_type === DataSourceType.INDUSTRY_BENCHMARK_PAID_MEDIA ||
        this.data_type === DataSourceType.INDUSTRY_BENCHMARK_EMAIL ||
        this.data_type === DataSourceType.INDUSTRY_BENCHMARK_LEAD_TRACKING ||
        this.data_type === DataSourceType.SEO_WEBSITES)
    );
  }

  /**
   * @param metadata
   * @returns {*|boolean}
   */
  hasMetadataErrors() {
    return (
      this.metadata.dynamic &&
      (!isEmpty(this.metadata.dynamic.warnings) || !isEmpty(this.metadata.dynamic.errors))
    );
  }

  /**
   * Has the required properties to start getting data from the data source
   * @returns {""|DataSource}
   */
  hasDataConfiguration() {
    return !!(
      this.data_source_id &&
      this.data_type &&
      this.data_view_id &&
      this.metadata.data_source
    );
  }

  /**
   * Returns if comparison is enabled at widget level.
   * @returns {*}
   */
  hasComparison() {
    return this.metadata.compare_to_prior_period;
  }

  setFilterSetFromDataSource() {
    const { data_source, dynamic, filter_set_id } = this.metadata;
    if (!data_source) {
      return null;
    }
    this.filter_set = new WidgetFilterSet({
      id: filter_set_id,
      data_source,
      filters: dynamic?.filters,
      name: `${data_source.id_name} ${data_source.data_view_name} filter set`,
    });
  }

  invalidateFilters() {
    this.filter_set?.clearInvalidFilters();
  }

  /**
   * Returns if the widget can support sorting.
   * @returns {boolean}
   */
  canSort() {
    return this.isDataGridWidget() || this.isSerialChartWidget();
  }
}
