'use strict';
import angular from 'angular';
import $ from 'jquery';
import './namingconvention.constants';
import './base/namingconvention.ctrls';
import './base/namingconvention.services';
import './base/namingconvention.resources';
import './rule/namingconventionrule.module';

import namingConventionHtmlUrl from './base/namingconvention.html';

angular.module('core.namingconvention', [
    'core.namingconvention.constants',
    'core.namingconvention.ctrls',
    'core.namingconvention.services',
    'core.namingconvention.resources',

    'core.namingconventionrule'
])
    .config(['$stateProvider', 'AppConfig', 'NamingConventionRouteParams', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, NamingConventionRouteParams, SwitchAppServiceProvider) {
        $stateProvider
            .state(NamingConventionRouteParams.STATE_NAME, {
                parent:'layout',
                url: NamingConventionRouteParams.URL,
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn(NamingConventionRouteParams.STATE_NAME),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_NAMING_CONVENTIONS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: namingConventionHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            });
    }]);