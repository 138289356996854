import bignumber from '@/assets/bignumber.svg';
import datagrid from '@/assets/datagrid.svg';
import barchart from '@/assets/barchart.svg';
import linechart from '@/assets/linechart.svg';
import combochart from '@/assets/combochart.svg';
import piechart from '@/assets/piechart.svg';
import funnelchart from '@/assets/funnelchart.svg';
import gaugechart from '@/assets/gaugechart.svg';
import geochart from '@/assets/geochart.svg';
import picture from '@/assets/picture-sun.svg';
import gears from '@/assets/gears.svg';
import paragraph from '@/assets/paragraph-bullets.svg';
import largeBigNumber from '@/assets/big-number-dummy-140.svg';
import largeDatagrid from '@/assets/datagrid-dummy-140.svg';
import largeBarchart from '@/assets/bar-chart-dummy-140.svg';
import largeLinechart from '@/assets/line-chart-dummy-140.svg';
import largeCombochart from '@/assets/combo-chart-dummy-140.svg';
import largePiechart from '@/assets/pie-chart-dummy-140.svg';
import largeFunnelchart from '@/assets/funnel-chart-dummy-140.svg';
import largeGaugechart from '@/assets/gauge-chart-dummy-140.svg';
import largeGeochart from '@/assets/geo-chart-dummy-140.svg';
import largeMedia from '@/assets/media-dummy-140.svg';
import largeAccountManager from '@/assets/account-manager-dummy-140.svg';
import { WidgetType as WidgetTypeConstant } from '@/modules/ta/widget/widget.constants';

export class WidgetType {
  /**
   * @type {boolean}
   */
  can_group_by;

  /**
   * @type {boolean}
   */
  can_drilldown;

  /**
   * @type {boolean}
   */
  requires_group_by;

  /**
   * @type {string}
   */
  id;

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  image;

  /**
   * @type {string}
   */
  largeImage;

  /**
   * @type {string[]}
   */
  type_groupings;

  constructor(model = {}) {
    this.can_group_by = model.can_group_by;
    this.can_drilldown = model.can_drilldown;
    this.requires_group_by = model.requires_group_by;
    this.id = model.id;
    this.name = model.name;
    this.image = model.image;
    this.largeImage = model.largeImage;
    this.type_groupings = model.type_groupings;
  }

  /**
   * @returns {boolean}
   */
  supportsCumulativeData() {
    switch (this.type) {
      case WidgetTypeConstant.TYPE_BARCHART:
      case WidgetTypeConstant.TYPE_LINECHART:
      case WidgetTypeConstant.TYPE_COMBINATIONCHART:
        return true;
      default:
        return false;
    }
  }

  /**
   * @returns {boolean}
   */
  isDataSourcedWidgetType() {
    return this.type_groupings.includes('datasourced');
  }

  /**
   * @returns {boolean}
   */
  isAdminWidgetType() {
    return this.type_groupings.includes('admin');
  }

  /**
   * @returns {boolean}
   */
  isDataWidgetType() {
    switch (this.id) {
      case WidgetTypeConstant.TYPE_ACCOUNTMANAGER:
      case WidgetTypeConstant.TYPE_MEDIA:
      case WidgetTypeConstant.TYPE_GOAL:
      case WidgetTypeConstant.TYPE_EXECUTIVESUMMARY:
        return false;
      default:
        return true;
    }
  }

  /**
   * @returns {boolean}
   */
  isDisplayWidgetType() {
    return this.id === WidgetTypeConstant.TYPE_MEDIA;
  }

  /**
   * @returns {boolean}
   */
  isChatGptWidgetType() {
    return this.id === WidgetTypeConstant.TYPE_CHATGPT;
  }

  /**
   * @returns {boolean}
   */
  isExecSummaryWidgetType() {
    return this.id === WidgetTypeConstant.TYPE_EXECUTIVESUMMARY;
  }

  /**
   * @returns {boolean}
   */
  isDrilldownWidgetType() {
    switch (this.id) {
      case WidgetTypeConstant.TYPE_BARCHART:
      case WidgetTypeConstant.TYPE_LINECHART:
      case WidgetTypeConstant.TYPE_COMBINATIONCHART:
      case WidgetTypeConstant.TYPE_PIECHART:
        return true;
      default:
        return false;
    }
  }

  /**
   * @returns {boolean}
   */
  isSeriesWidgetType() {
    return this.type_groupings.includes('series');
  }

  getWidgetType(widgetTypeId) {
    switch (widgetTypeId) {
      case WidgetTypeConstant.TYPE_BIGNUMBER:
        return WidgetType.getBigNumberType();
      case WidgetTypeConstant.TYPE_DATAGRID:
        return WidgetType.getDataGridType();
      case WidgetTypeConstant.TYPE_BARCHART:
        return WidgetType.getBarChartType();
      case WidgetTypeConstant.TYPE_LINECHART:
        return WidgetType.getLineChartType();
      case WidgetTypeConstant.TYPE_COMBINATIONCHART:
        return WidgetType.getCombinationChartType();
      case WidgetTypeConstant.TYPE_PIECHART:
        return WidgetType.getPieChartType();
      case WidgetTypeConstant.TYPE_FUNNELCHART:
        return WidgetType.getFunnelChartType();
      case WidgetTypeConstant.TYPE_GEOCHART:
        return WidgetType.getGeoChartType();
      case WidgetTypeConstant.TYPE_GAUGECHART:
        return WidgetType.getGaugeChartType();
      case WidgetTypeConstant.TYPE_MEDIA:
        return WidgetType.getMediaType();
      case WidgetTypeConstant.TYPE_ACCOUNTMANAGER:
        return WidgetType.getAccountManagerType();
      case WidgetTypeConstant.TYPE_GOAL:
        return WidgetType.getGoalType();
      case WidgetTypeConstant.TYPE_GOALCHART:
        return WidgetType.getGoalChartType();
      default:
        Logger.log(i18n.$t('Unsupported widget type'), Logger.LEVEL_ERROR);
        break;
    }
  }

  /**
   * @returns {WidgetType}
   */
  static getBigNumberType() {
    return new WidgetType({
      can_group_by: false,
      can_drilldown: false,
      requires_group_by: false,
      id: 'bignumber',
      name: 'Big Number',
      image: bignumber,
      largeImage: largeBigNumber,
      type_groupings: ['datasourced'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getDataGridType() {
    return new WidgetType({
      can_group_by: true,
      can_drilldown: false,
      requires_group_by: false,
      id: 'datagrid',
      name: 'Data Grid',
      image: datagrid,
      largeImage: largeDatagrid,
      type_groupings: ['datasourced'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getBarChartType() {
    return new WidgetType({
      can_group_by: true,
      can_drilldown: true,
      requires_group_by: true,
      id: 'barchart',
      name: 'Bar Chart',
      image: barchart,
      largeImage: largeBarchart,
      type_groupings: ['datasourced', 'chart', 'series'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getLineChartType() {
    return new WidgetType({
      can_group_by: true,
      can_drilldown: true,
      requires_group_by: true,
      id: 'linechart',
      name: 'Line Chart',
      image: linechart,
      largeImage: largeLinechart,
      type_groupings: ['datasourced', 'chart', 'series'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getCombinationChartType() {
    return new WidgetType({
      can_group_by: true,
      can_drilldown: true,
      requires_group_by: true,
      id: 'combinationchart',
      name: 'Combo Chart',
      image: combochart,
      largeImage: largeCombochart,
      type_groupings: ['datasourced', 'chart', 'series'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getPieChartType() {
    return new WidgetType({
      can_group_by: true,
      can_drilldown: true,
      requires_group_by: false,
      id: 'piechart',
      name: 'Pie Chart',
      image: piechart,
      largeImage: largePiechart,
      type_groupings: ['datasourced', 'chart'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getFunnelChartType() {
    return new WidgetType({
      can_group_by: true,
      can_drilldown: true,
      requires_group_by: false,
      id: 'funnelchart',
      name: 'Funnel Chart',
      image: funnelchart,
      largeImage: largeFunnelchart,
      type_groupings: ['datasourced', 'chart'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getGaugeChartType() {
    return new WidgetType({
      can_group_by: false,
      can_drilldown: false,
      requires_group_by: false,
      id: 'gaugechart',
      name: 'Gauge Chart',
      image: gaugechart,
      largeImage: largeGaugechart,
      type_groupings: ['datasourced', 'chart'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getGeoChartType() {
    return new WidgetType({
      can_group_by: true,
      can_drilldown: true,
      requires_group_by: true,
      id: 'geochart',
      name: 'Geo Chart',
      image: geochart,
      largeImage: largeGeochart,
      type_groupings: ['datasourced', 'chart'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getMediaType() {
    return new WidgetType({
      can_group_by: false,
      can_drilldown: false,
      requires_group_by: false,
      id: 'media',
      name: 'Media',
      image: picture,
      largeImage: largeMedia,
      type_groupings: ['display'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getAccountManagerType() {
    return new WidgetType({
      can_group_by: false,
      can_drilldown: false,
      requires_group_by: false,
      id: 'accountmanager',
      name: 'Account Manager',
      image: gears,
      largeImage: largeAccountManager,
      type_groupings: ['datasourced', 'admin'],
    });
  }

  /**
   * @returns {WidgetType}
   */
  static getExecutiveSummaryType() {
    return new WidgetType({
      can_group_by: false,
      can_drilldown: false,
      requires_group_by: false,
      id: 'executivesummary',
      name: 'Executive Summary',
      image: paragraph,
      type_groupings: [],
    });
  }
}
