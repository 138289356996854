import angular from 'angular';
import dashheaderHtmlUrl from '../views/components/dashheader.html';
import dashheaderItemHtmlUrl from '../views/components/dashheaderitem.html';

/**
 * The following components in `core.app.components.placeholders` should remain empty
 */
angular.module('core.app.components.placeholders', [])
    .component('dashHeaderItems', {});

/**
 * App Wide Components
 */
angular.module('core.app.components', ['core.app.components.placeholders'])
    .component('dashHeader', {
        bindings: {
            title: '@',
            state: '<?'
        },
        transclude: {
            dashHeaderItems: '?dashHeaderItems'
        },
        templateUrl: dashheaderHtmlUrl,
        controllerAs: 'vm',
        controller: DashHeaderController
    })
    .component('dashHeaderItem', {
        bindings: {
            title: '@',
            icon: '@',
            actionClicked: '<action'
        },
        templateUrl: dashheaderItemHtmlUrl,
        controllerAs: 'vm',
        controller: DashHeaderItemController
    });

/**
 * @ngInject
 */
function DashHeaderController() {
    var vm = this;
    vm.state = vm.state || {isLoading: false};
}

/**
 * @ngInject
 */
function DashHeaderItemController() {

}