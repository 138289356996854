'use strict';
import angular from 'angular';

angular.module('calculator.services', [])

    .service('CalculatorService', CalculatorService);

//
// Calculations service
//
CalculatorService.$inject = ['$rootScope', '$http'];
function CalculatorService($rs, $http) {
    function getList() {
        return $http.get($rs.util.apiUrl('calculations'));
    }

    function getItem(id) {
        return $http.get($rs.util.apiUrl('calculations/' + id));
    }

    return {
        getList: getList,
        getItem: getItem
    }
}