import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class DashboardFilterOption extends BaseModel {
  /**
   * @var {string}
   */
  key;

  /**
   * @var {string}
   */
  format;

  /**
   * @var {string}
   */
  field;

  /**
   * @var {string}
   */
  type;

  /**
   * @var {string}
   */
  label;

  /**
   * @var {object}
   */
  data_source;

  /**
   * @var {boolean}
   */
  is_predefined;

  /**
   * @var {boolean}
   */
  limit_available_values;

  /**
   * @var {int}
   */
  order;

  /**
   * @var {string}
   */
  source_filter_set_id;

  constructor(model = {}) {
    super();

    this.key = model.key || null;
    this.format = model.format || 'id';
    this.field = model.field || 'id';
    this.type = model.type || null;
    this.label = model.label || null;
    this.data_source = model.data_source || null;
    this.is_predefined = model.is_predefined || false;
    this.limit_available_values = model.limit_available_values || false;
    this.order = model.order || 0;
    this.source_filter_set_id = model.source_filter_set_id || null;
  }
}
