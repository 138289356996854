import { isNil } from 'lodash';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { RelativeDateRangeKey } from '@/modules/core/daterange/daterange.constants';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';

export class DateRange extends BaseModel {
  /**
   * @var {string} - ISO Format
   */
  start_date;

  /**
   * @var {string} - ISO Format
   */
  end_date;

  /**
   * @var {string}
   */
  formatted_start_date;

  /**
   * @var {string}
   */
  formatted_end_date;

  /**
   * @var {string}
   */
  relative_date_range_key;

  /**
   * @var {string} - ISO Format
   */
  comparison_start_date;

  /**
   * @var {string} - ISO Format
   */
  comparison_end_date;

  /**
   * @var {string}
   */
  formatted_comparison_start_date;

  /**
   * @var {string}
   */
  formatted_comparison_end_date;

  /**
   * @var {boolean}
   */
  is_comparison_active;

  /**
   * @var {number}
   */
  comparison_period;

  constructor(model = {}) {
    super();

    this.start_date = model.start_date;
    this.end_date = model.end_date;
    this.formatted_start_date =
      model.formatted_start_date || (model.start_date && DateUtil.formatDate(model.start_date));
    this.formatted_end_date =
      model.formatted_end_date || (model.end_date && DateUtil.formatDate(model.end_date));
    this.relative_date_range_key = isNil(model.relative_date_range_key)
      ? RelativeDateRangeKey.CUSTOM
      : model.relative_date_range_key;
    this.comparison_start_date = model.comparison_start_date;
    this.comparison_end_date = model.comparison_end_date;
    this.formatted_comparison_start_date =
      model.formatted_comparison_start_date ||
      (model.comparison_start_date && DateUtil.formatDate(model.comparison_start_date));
    this.formatted_comparison_end_date =
      model.formatted_comparison_end_date ||
      (model.comparison_end_date && DateUtil.formatDate(model.comparison_end_date));
    this.is_comparison_active = !!model.is_comparison_active;
    this.comparison_period = isNil(model.comparison_period)
      ? RelativeDateRangeKey.PRIOR_PERIOD
      : model.comparison_period;
  }
}
