import { isNil } from 'lodash';
import { Color, color as chartColor } from '@amcharts/amcharts5';
import { DefaultShadowSettings, ValueSymbol } from '@/modules/core/charts/am5/charts.constants';
import { getters } from '@/modules/core/app/helpers/store';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export function color(value) {
  try {
    return !isNil(value) ? chartColor(value) : null;
  } catch (e) {
    Logger.log(e, Logger.LEVEL_ERROR);
  }
}

export function invertColor(value, alreadyAColor = false) {
  const colorValue = alreadyAColor ? value : color(value);
  return Color.alternative(colorValue, color(0x000000), color(0xffffff));
}

export function valueSymbol(type) {
  if (type === ColumnFormat.FORMAT_CURRENCY) {
    return getters.session.getUserSettings()?.currencyCode || '';
  }
  return ValueSymbol[type] || '';
}

export function isDateAxis(dataItemFormat) {
  return [ColumnFormat.FORMAT_DATE, ColumnFormat.FORMAT_DATETIME].includes(dataItemFormat);
}

export function getShadowSettings(isExporting) {
  const shadowSettings = DefaultShadowSettings;

  // an exported shadow needs to be crisper as the export to png can become blurry with
  // the dithered edges of a shadow
  if (isExporting) {
    shadowSettings.shadowBlur = 2;
    shadowSettings.shadowOffsetX = 2;
    shadowSettings.shadowOffsetY = 2;
  }

  return {
    shadowColor: color(0x000000),
    ...shadowSettings,
  };
}
