
import angular from 'angular';

angular.module('exportbuilder.panel.models', [])
    .factory('ExportBuilderFilterPanelModelFactory', ExportBuilderFilterPanelModelFactory);

/**
 * @ngInject
 */
function ExportBuilderFilterPanelModelFactory(
    $q,
    gettextCatalog,
    AppModelFactory,
    DataSourceFactory,
    FilterParam,
    ReportStudioTemplateDataService,
    DataSourceType
) {
    return {
        getFilterOptions: getFilterOptions,
        getFilterOption: getFilterOption,
        getFilterPanelState: getFilterPanelState
    };

    function getFilterOptions(filterOptions) {
        return _buildSelect2Options(filterOptions);
    }

    function getFilterOption(filterOptions) {
        return getFilterOptions(filterOptions)[0];
    }

    function _buildSelect2Options(filtersOptions) {
        return _.map(filtersOptions, function (filterOption, key) {
            var _initialValues = [];
            const option = {};
            option.key = key;
            option.filterLabel = filterOption.label
            option.data_source = filterOption.data_source;
            option.affectedWidgetIds = [];
            option.isShowingAffectedWidgets = false;
            option.acceptWildcard = true;
            option.type = filterOption.type || FilterParam.FILTER_IN;

            // Select2 inputs
            option.tooMuchDataCallback = function(query) {
                // In order to enable tags, the query must be the first item in results
                var valuesWithTag = query.term.length > 1 ?
                    [{id: query.term, text: query.term}].concat(option.selectOptions.tags) :
                    option.selectOptions.tags;

                var selectedValues = {results: valuesWithTag};
                // Query length must be checked here, or else the selectable values will never be set
                if (query.term.length >= 2) {
                    var params = {
                        q: query.term + '|' + filterOption.field,
                    };

                    DataSourceFactory.getFieldValues(filterOption.data_source, filterOption.field, params).then(function(item) {
                        if (item.values.length) {
                            selectedValues.results = [{id: query.term, text: query.term}];
                            _.each(item.values, function (item) {
                                selectedValues.results.push({id: item.key, text: item.value});
                            });
                        }
                        query.callback(selectedValues);
                    }, function (error) {
                        console.error("Error getting data", error);
                    });
                }
                else {
                    query.callback(selectedValues);
                }
            };

            var queryParams = {};
            option.getDataCall =  (updated_limit_available_values) => {
                let entity = ReportStudioTemplateDataService.getEntity();
                const report = ReportStudioTemplateDataService.getReport();
                if (report && filterOption.source_filter_set_id) {
                    if (updated_limit_available_values !== undefined && updated_limit_available_values !== false) {
                        queryParams.filter_set_id = filterOption.source_filter_set_id;
                    } else if (filterOption.limit_available_values) {
                        queryParams.filter_set_id = filterOption.source_filter_set_id;
                    } else {
                        delete queryParams.filter_set_id;
                    }
                }
                if (updated_limit_available_values === false) {
                    delete queryParams.filter_set_id;
                }

                if (!_.isEmpty(report.client)) {
                    queryParams[filterOption.client_id_column_name] = report.client.id;
                } else if (!_.isEmpty(entity) && !_.isEmpty(entity.client_ids)) {
                    queryParams[filterOption.client_id_column_name] = entity.id;
                    if (entity.type !== DataSourceType.CLIENT) {
                        queryParams.selected_entity = entity.type;
                    }
                } else if (!_.isEmpty(entity)) {
                    if (entity.type === DataSourceType.CLIENT_GROUP) {
                        queryParams.selected_entity = entity.type;
                    }
                }
                if (!_.isEmpty(report.global_filters)) {
                    const filter = report.global_filters[option.key];
                    if (filter && !_.isEmpty(filter.values)) {
                        option.currentValues = filter.values;
                    }
                }
                return $q.resolve(DataSourceFactory.getFieldValues(filterOption.data_source, filterOption.field, queryParams)).then(function (json) {
                    option.loaded = true;
                    option.values = json.values;
                    _initialValues = angular.copy(option.values);
                    option.selectOptions.hasMoreData = json.has_more_data;
                    return json;
                });
            };

            // Set select2 default options
            _setSelect2Options(option);

            return option;
        });

    }

    /**
     *
     * @param option
     */
    function _setSelect2Options(option) {
        var defaultOptions = {
            key: option.key,
            width: '100%',
            listenToOtherSelect2: option.is_predefined,
            placeholder: gettextCatalog.getString('Select campaigns'),
            tooMuchDataCallback: option.tooMuchDataCallback,
            label: option.label,
            formatData: option.formatData || function(json) {
                this.loaded = true;
                this.values = json.values;
            },
            formatResult: function (item) {
                return item.text
            },
            formatSelection: function (item) {
                return item.text
            },
            getDataCall: option.getDataCall,
            queryCallback: option.queryCallback
        };
        if(option.filterLabel) {
            defaultOptions.placeholder = gettextCatalog.getString('Select '+option.filterLabel);
        }
        if (!option.is_predefined) {
            defaultOptions.multiple = true;
            if (!option.limit_available_values) {
                defaultOptions.tags = true;
            }
        }

        option.selectOptions = AppModelFactory.getSelectOptions(defaultOptions);
    }
    
    function FilterPanelState() {
        this.isLoadingPanel = false;
    }
    
    function getFilterPanelState() {
        return new FilterPanelState()
    }
}