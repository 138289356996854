'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.history.services', [])
    .factory('HistoryFactory', HistoryFactory);

/**
 * @ngInject
 */
function HistoryFactory(
    $q,
    ServiceFactory,
    MessengerFactory,
    HistoryTypes,
    MessengerModelFactory,
    Restangular
) {
    var defaultProps = {
        state: {
            isActive: false
        },
        data: {
            lead: null,
            threadOptions: {
                hideAddButton: true
            }
        }
    };
    var props = angular.copy(defaultProps);

    return {
        getIsActive: getIsActive,
        setInactive: setInactive,
        getThreadOptions: getThreadOptions,
        initHistory: initHistory,
        getPanelTitle: getPanelTitle
    };

    function getIsActive() {
        return props.state.isActive;
    }

    function setInactive() {
        props.state.isActive = false;
    }

    function getThreadOptions() {
        return props.data.threadOptions;
    }

    /**
     * @param module
     * @param data
     */
    function initHistory(module, data) {
        props.state.isActive = true;
        props.data.data = data;
        var deferred = $q.defer();

        _getHistory(module, data.id).then(function(json) {
            var messageThreads = _.map(json.plain(), function(log) {
                log.userImageUrl = log.user_image_metadata && log.user_image_metadata.secure_url;
                log.permissions = {can_be_edited: false, can_be_deleted: false};
                log.content = _getLogText(log);
                log.timestamp = log.date;
                log.isEditing = false;
                return MessengerModelFactory.getMessageThread(log);
            });
            deferred.resolve(messageThreads);
        });

        MessengerFactory.setThreads(deferred.promise);
    }

    function getPanelTitle() {
        return '<span><i class="icon icon-history mr5"></i>History</span>';
    }

    /**
     * @param log
     * @returns {string}
     * @private
     */
    function _getLogText(log) {
        var html;
        switch (log.type) {
            case HistoryTypes.UPDATE:
                var original = log.original ? log.original : 'empty';
                var edited = log.edited ? log.edited : 'empty';
                html = '<label class="label label-default mr2">' + log.name + '</label> changed from <b class="mr2 ml2">' + original + '</b> to <b class="mr2 ml2">' + edited + '</b>';
                break;

            case HistoryTypes.CREATE:
                html = 'Created a new <b>' + log.name + '</b>';
                break;

            case HistoryTypes.DELETE:
                html = 'Deleted a <b>' + log.name + '</b>';
                break;

            default:
                html = '';
                break;
        }
        return html;
    }

    /**
     * @param module
     * @param id
     * @returns {angular.IHttpPromise<any>}
     * @private
     */
    function _getHistory(module,id) {
        return Restangular.all('history').all(module).all(id).getList({
            all: true,
            sort: '-date'
        });
    }
}