'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('core.slidepanel.services', [])
    .constant('$SlidePanelEvents', $EventConstants())
    .service('SlidePanelFactory', SlidePanelFactory);

function $EventConstants() {
    return {
        CLOSE: 'slidePanel:CLOSE',
        CLOSE_ALL: 'slidePanel:CLOSE_ALL'
    };
}

/**
 * @ngInject
 */
function SlidePanelFactory(
    PubSub,
    $timeout,
    $DataGridEvents
) {

    var currentSlidePanel;

    /**
     * Global flag to know if a generic side panel is showing
     * @type {boolean}
     */
    var isActive = false;

    /**
     * Getter Global hierarchy value whether the slide panel is showing or not.
     */
    function getIsActive() {
        return isActive;
    }

    return {
        show: show,
        hide: hide,
        closeAll: closeAll,
        toggleTab: toggleTab,
        getIsActive: getIsActive,
        getSpaceClassName: getSpaceClassName
    };

    /**
     * Show slide panel
     * @param panel
     */
    function show(panel) {
        currentSlidePanel = panel;
        closeAll(panel.panelId);
        if (panel.isLayout || panel.showOuterElements) {
            isActive = true;

            $timeout(function() {
                PubSub.emit($DataGridEvents.UPDATE_ALL_SCROLL_BODY_HEIGHT);
            }, 0, false);
        }
        panel.isShowing = true;
    }

    /**
     * Hide slide panel
     * @param panel
     */
    function hide(panel) {
        panel.isShowing = false;
        if (!currentSlidePanel || panel.panelId === currentSlidePanel.panelId) {
            isActive = false;

            $timeout(function() {
                PubSub.emit($DataGridEvents.UPDATE_ALL_SCROLL_BODY_HEIGHT);
            }, 0, false);
        }
        close(panel.panelId);
    }

    /**
     *  Used to allow slide panel to take up a custom size
     * @param panel
     * @returns {*}
     */
    function getSpaceClassName() {
        if (currentSlidePanel.isLargePanel) {
            return 'large';
        }
        else if (currentSlidePanel.isSmallPanel) {
            return 'small';
        }
        else {
            return '';
        }
    }

    /**
     * Toggle tab inside slide panel
     * @param index
     */
    function toggleTab(index) {
        var $panel = angular.element('div.slide-panel.active');
        // Set the right tab to active
        var $tabs = $panel.find('div.panel-tab');
        $tabs.removeClass('active');
        $($tabs.get(index)).addClass('active');
        // Set the right tab's content to active
        var $contents = $panel.find('div.panel-tab-content');
        $contents.removeClass('active');
        $($contents.get(index)).addClass('active');
    }

    /**
     * Event to broadcast all slide panels to close
     * @param sourcePanelId - Avoid closing the panel being opened
     */
    function close(sourcePanelId) {
        PubSub.emit($EventConstants().CLOSE, sourcePanelId);
    }

    /**
     * Event to broadcast all slide panels to close
     * @param sourcePanelId - Avoid closing the panel being opened
     */
    function closeAll(sourcePanelId) {
        PubSub.emit($EventConstants().CLOSE_ALL, sourcePanelId);
    }

}