import angular from 'angular';

angular.module('core.user.models', [])
    .service('UserModelFactory', UserModelFactory);

/**
 * User Models for 'core.user'
 * @ngInject
 */
function UserModelFactory() {

    return {
        getRouteProps: getRouteProps,
        getHomeRoute: getHomeRoute
    };

    function getRouteProps(model) {
        return new RouteProps(model)
    }

    function getHomeRoute(model) {
        return new HomeRoute(model);
    }
}

/**
 * Default data type for redirecting state route
 * @param [model]
 * @constructor
 */
function RouteProps(model) {
    var self = this;

    self.state = model && model.state || '';
    self.params = model && model.params || {};
    self.options = model && model.options || {reload: true};
}

/**
 * Home state route
 * @extends {RouteProps}
 * @constructor
 */
HomeRoute.prototype = Object.create(RouteProps.prototype);
HomeRoute.prototype.constructor = HomeRoute;
function HomeRoute(model) {
    var self = this;
    RouteProps.call(self, model);
    self.state = 'home';
}