export const getters = {
  /**
   * @param state
   * @returns {Array}
   */
  getCallbackRoutes(state) {
    return state.callbackRoutes;
  },

  /**
   * @param state
   * @returns {*}
   */
  getIsCallbackEnabled(state) {
    return state.isCallbackEnabled;
  },
};
