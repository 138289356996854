'use strict';
import angular from 'angular';
import './widget.slicechart.ctrls';
import './widget.slicechart.directives';
import './widget.slicechart.services';

angular.module('widget.slicechart', [
    'widget.slicechart.ctrls',
    'widget.slicechart.directives',
    'widget.slicechart.services',
]);