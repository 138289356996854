'use strict';

angular.module('widget.tag.resources', [])
    .factory('WidgetTagsResource', WidgetTagsResource)
    .factory('WidgetTagsDataGridFactory', WidgetTagsDataGridFactory);

/**
 * @ngInject
 */
function WidgetTagsResource(
    Restangular
) {
    var resource = Restangular.all('widgettags');

    return {
        save: save,
        getData: getData
    };

    /**
     *
     * Save list of new tags
     * @param tags
     * @returns {*|{method}|{method, isArray}}
     */
    function save(tags) {
        var payload = _.map(tags, function (tag) {
            return {text: tag};
        });
        return resource.all('tagList').post(payload).then(function (json) {
            return json.plain();
        });
    }

    /**
     * @param queryParams
     * @returns {angular.IPromise<any>}
     */
    function getData(queryParams) {
        queryParams = _.assign({
            all: true
        }, queryParams);
        return resource.getList(queryParams).then(function (json) {
            return json.plain();
        });
    }
}

/**
 * @ngInject
 */
function WidgetTagsDataGridFactory(
    LibraryTagColumns,
    DataGridRender
) {
    return {
        getDTOptions: getDTOptions,
    };

    /**
     * Return manage widgets datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.numActions = 1;
        dtOptions.customRenders[LibraryTagColumns.ID] = function (data, type, full) {
            return DataGridRender.getDeleteButton(full.can_be_deleted, 'widgettags/' + data, full['text']);
        };

        return dtOptions;
    }
}