import { Env } from '@/env';
import { difference, remove } from 'lodash';

export class BaseTrackEvent {
  /**
   * @abstract
   *
   * To be implemented by child event class
   *
   * @var {string}
   */
  static name;

  constructor(properties) {
    if (properties) {
      this._validate(properties);

      Object.keys(properties).forEach((prop) => {
        this[prop] = properties[prop];
      });
    }
  }

  /**
   * @private
   *
   * @returns {BaseEvent}
   */
  static _getStatic() {
    return this;
  }

  /**
   * @param {Object.<string, *>} properties
   * @returns {BaseEvent}
   */
  static factory(properties) {
    return new this(properties);
  }

  /**
   * @param model
   * @returns {BaseTrackEvent}
   */
  static build(model) {
    return new this({ ...model });
  }

  /**
   * @returns {string}
   */
  getName() {
    return this.constructor._getStatic().name;
  }

  /**
   * @returns {Object.<string, *>}
   */
  getProperties() {
    const props = { ...this };
    delete props.name;
    return props;
  }

  areAllPropertiesRequired() {
    return true;
  }

  /**
   * @private
   *
   * @param {Object.<string, *>} properties
   * @throws Error
   */
  _validate(properties) {
    if (Env.isDevelopment() || Env.isUnitTesting()) {
      const propertyKeys = Object.keys(properties);
      const modelKeys = Object.keys(this.constructor._getStatic());
      const [nameKey] = remove(modelKeys, (key) => key === 'name');

      if (nameKey !== 'name' || !this.constructor._getStatic().name) {
        throw new Error(`Missing required name for class: ${this.constructor.name}`);
      }
      if (this.areAllPropertiesRequired()) {
        let error = false;
        const differenceKeys = difference(modelKeys, propertyKeys);
        differenceKeys.forEach((key) => {
          Logger.log(`Property ${key} is missing in ${this.constructor.name}`, Logger.LEVEL_ERROR);
          error = true;
        });

        if (error) {
          throw new Error(`Missing event information for class: ${this.constructor.name}`);
        }
      }
    }
  }
}
