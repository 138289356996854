'use strict';
import angular from 'angular';
import './scripts/scheduledreport.ctrls';
import './scripts/scheduledreport.directives';
import './scripts/scheduledreport.services';
import './scheduledreport.constants';

angular.module('scheduledreport', [
    'scheduledreport.ctrls',
    'scheduledreport.directives',
    'scheduledreport.services',
    'scheduledreport.constants'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('scheduledreports', {
                parent:'layout',
                url: '/scheduledreports',
                controller: 'ScheduledReportListController',
                templateUrl: AppConfig.MAGE_URL + 'reporting/manage_scheduled_reports',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('scheduledreports')
                }
            })
            .state('scheduledreports.filter', {
                parent:'layout',
                url: '/scheduledreports/status/:status',
                controller: 'ScheduledReportListController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'reporting/manage_scheduled_reports?status=' + $stateParams.status;
                }
            })
            .state('scheduledreports.add', {
                parent:'layout',
                url: '/scheduledreports/add',
                data: { isNewEntity: true, primaryKey: 'scheduled_report_id' },
                controller: 'ScheduledReportController',
                templateUrl: AppConfig.MAGE_URL + 'reporting/add_scheduled_report'
            })
            .state('scheduledreports.detail', {
                parent:'layout',
                url: '/scheduledreports/detail/:id',
                controller: 'ScheduledReportController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'reporting/view_scheduled_report/scheduled_report_id/' + $stateParams.id;
                }
            })
    }]);