import angular from 'angular';
import $ from 'jquery';
import moment from 'moment';

import exportBuilderFormatModalHtmlUrl from './exportbuilder.format.modal.html';

angular.module('exportbuilder.dashboard.components')
    .component('exportBuilderFormatModal', {
        bindings: {
        },
        templateUrl: exportBuilderFormatModalHtmlUrl,
        controller: ExportBuilderFormatModal,
        controllerAs: 'vm'
    });



function ExportBuilderFormatModal(
    PubSub,
    UIFactory,
    ExportBuilderFormatModalUIService,
    ReportStudioTemplateDataService,
    $ExportBuilderFormatChangeModalEvents,
    ExportBuilderElementActionService,
    $ExportBuilderDashboardEvents,
    PageFormatConstants
) {
    var vm = this;

    vm.serviceState = ExportBuilderFormatModalUIService.getState();
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onClose = onClose;
    vm.onChangeFormat = onChangeFormat;
    vm.format = null;
    vm.el = null;
    vm.event = null;


    function $onInit() {
        vm.report = ReportStudioTemplateDataService.getReport();
        if (!vm.report.format) {
            vm.report.format = PageFormatConstants.STANDARD;
        }
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function onClose(value) {
        if (value === 'cancelled') {
            PubSub.emit($ExportBuilderDashboardEvents.RESET_FORMAT_DROPDOWN, {el: vm.el, event: vm.event});
        }
        ExportBuilderFormatModalUIService.setIsActive(false);
    }

    function _onOpen() {
        ExportBuilderFormatModalUIService.setIsActive(true);
    }

    function onChangeFormat() {
        ExportBuilderElementActionService.setAspectRatio(vm.format);
        ExportBuilderFormatModalUIService.setIsActive(false);
    }

    function _setValue(value) {
        vm.format = value.new_format;
        vm.el = value.el;
        vm.event = value.event;
        vm.newRatio = vm.event.added.text;
        vm.oldRatio = vm.event.removed.text;
    }


    function _registerEvents() {
        PubSub.on($ExportBuilderFormatChangeModalEvents.OPEN, _onOpen);
        PubSub.on($ExportBuilderFormatChangeModalEvents.SET_VALUE, _setValue);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderFormatChangeModalEvents.OPEN, _onOpen);
        PubSub.off($ExportBuilderFormatChangeModalEvents.SET_VALUE, _setValue);
    }
}