export const RelativeDateRangeKey = {
  CUSTOM: 'custom',
  DEFAULT: 'default',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  TOMORROW: 'tomorrow',
  LAST_7_DAYS: 'last7days',
  LAST_14_DAYS: 'last14days',
  LAST_30_DAYS: 'last30days',
  LAST_90_DAYS: 'last90days',
  LAST_180_DAYS: 'last180days',
  LAST_WEEK: 'lastweek',
  LAST_MONTH: 'lastmonth',
  LAST_3_MONTHS: 'last3months',
  LAST_QUARTER: 'lastquarter',
  LAST_6_MONTHS: 'last6months',
  LAST_12_MONTHS: 'last12months',
  LAST_YEAR: 'lastyear',
  LAST_13_MONTHS: 'last13months',
  YEAR_TO_DATE: 'yeartodate',
  THIS_WEEK: 'thisweek',
  THIS_MONTH: 'thismonth',
  THIS_MONTH_EXCLUDE_TODAY: 'thismonthexcludetoday',
  THIS_QUARTER: 'thisquarter',
  THIS_YEAR: 'thisyear',
  NEXT_7_DAYS: 'next7days',
  NEXT_30_DAYS: 'next30days',
  NEXT_WEEK: 'nextweek',
  NEXT_MONTH: 'nextmonth',
  NEXT_YEAR: 'nextyear',
  ALL_TIME: 'alltime',
  PRIOR_PERIOD: 'priorperiod',
  FUTURE_PERIOD: 'futureperiod',
  PRIOR_MONTH: 'priormonth',
  PRIOR_YEAR: 'prioryear',
};

export const MomentDateFormat = {
  ISO: 'YYYY-MM-DD',
  ISO_TIME: 'YYYY-MM-DD HH:mm:ss',
  ISO_TIME_WITH_TIMEZONE: 'YYYY-MM-DDTHH:mm:ssZ',
  MONTH_DAY_YEAR: 'MMM DD, YYYY',
  DAY_MONTH_YEAR: 'DD MMM YYYY',
  TIME: 'HH:mm:ss',
  DAYOFWEEK_MONTH_DAY_YEAR: 'ddd MMM DD YYYY',
  DAYOFWEEK_DAY_MONTH_YEAR_WITH_TIMEZONE: 'ddd, DD MMM YYYY HH:mm:ss ZZ',
  MONTH_DAY_YEAR_TWELVE_HOUR: 'MMM DD, YYYY hh:mm A',
  MONTH_DAY_WITH_ORDINAL_TWELVE_HOUR: 'MMM Do YYYY, hh:mm A',
  MONTH_DAY_YEAR_WITH_ORDINAL: 'MMM Do YYYY',
  MONTH_YEAR: 'MMM YYYY',
  YEAR_MONTH_NO_SPACE: 'YYYYMM',
  DAYOFMONTH_MONTH_YEAR_WITH_ORDINAL: 'Do MMM YYYY',
  DAY_MONTH_YEAR_HYPHEN: 'DD-MM-YYYY',
  MONTH_DAY_YEAR_HYPHEN: 'MM-DD-YYYY',
  DAY_MONTH_YEAR_HYPHEN_TIME: 'DD-MMM-YYYY, HH:MM A',
  YEAR: 'YYYY',
  MONTH_DAY_YEAR_OBLIQUE: 'MM/DD/YYYY',
  LL: 'LL', // April 1, 2021
  DAY_MONTH: 'DD MMM',
  MONTH_DAY: 'MMM DD',
};

export const UnitOfTime = {
  MONTH: 'month',
};
