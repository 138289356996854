import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { BaseDrawOption } from '@/modules/ta/widget/models/drawoptions/BaseDrawOption';
import { DataSource } from '@/modules/core/datasource/models/DataSource';
import { WidgetMetadataDynamic } from '@/modules/ta/widget/models/metadata/dynamic/WidgetMetadataDynamic';
import {
  WidgetComparisonOptions,
  WidgetSparklineOptions,
} from '@/modules/ta/widget/widget.constants';

export class BaseWidgetMetadata extends BaseModel {
  /**
   * @var {string}
   */
  description;

  /**
   * @var {string}
   */
  tags;

  /**
   * @var {DataSource}
   */
  data_source;

  /**
   * @var {string}
   */
  export_options;

  /**
   * @var {string}
   */
  thumbnail_metadata;

  /**
   * @var {string}
   */
  data_source_display;

  /**
   * @var {{}}
   */
  dynamic;

  /**
   * @var {BaseDrawOption}
   */
  draw_options;

  /**
   * @var {string}
   */
  filter_set_id;

  /**
   * @var {string}
   */
  comparison_option;

  /**
   * @var {string}
   */
  sparkline_option;

  constructor(model = {}) {
    super();

    this.description = model.description;
    this.tags = model.tags;
    this.data_source = model.data_source && new DataSource(model.data_source);
    this.export_options = model.export_options;
    this.thumbnail_metadata = model.thumbnail_metadata;
    this.data_source_display = model.data_source_display;
    this.dynamic = new WidgetMetadataDynamic(model.dynamic);
    this.draw_options = new BaseDrawOption(model.draw_options);
    this.filter_set_id = model.filter_set_id;
    this.comparison_option = model.comparison_option ?? WidgetComparisonOptions.MONTH_OVER_MONTH;
    this.sparkline_option = model.sparkline_option ?? WidgetSparklineOptions.LAST_3_MONTHS;
  }
}
