import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { GenericModel } from '@/modules/core/app/models/GenericModel';
import { DownloadUtil } from '@/modules/core/app/utils/DownloadUtil';
import { getApiUrl } from '@/modules/core/app/utils/ApiUtil';

export class BaseEntityResource extends BaseResource {
  /**
   * @var {BaseModel}
   */
  model;

  /**
   * @param routeGroup
   * @param {BaseModel} model
   * @param apiHelper
   */
  constructor(routeGroup, model, apiHelper = apiAxios) {
    super(routeGroup, apiHelper);
    this.model = model;

    if (!this.model) {
      throw Error(`Model must be set for "${this.routeGroup}" resource`);
    }
  }

  /**
   * @param params
   * @param config
   * @returns {Promise<*>}
   */
  async getItems(params = {}, config = {}) {
    if (params.q && this.addSearchParam(params.q)) {
      params.q = this.addSearchParam(params.q);
    }
    return super.getData(this.model, null, params, config);
  }

  /**
   * @param id
   * @param params
   * @param config
   * @returns {Promise<*>}
   */
  async getItem(id, params = {}, config = {}) {
    return super.getData(this.model, `${id}`, params, config);
  }

  /**
   * @param params
   * @param config
   * @returns {Promise<*>}
   */
  async getValues(params = {}, config = {}) {
    return super.getData(GenericModel, 'values/distinct', params, config);
  }

  /**
   * @param id
   * @param params
   * @param config
   * @returns {Promise<*>}
   */
  async getForm(id, params = {}, config = {}) {
    return Promise.all([
      id ? this.getItem(id, params, config) : new this.model(),
      this.getColumns(null, params, config),
    ]);
  }

  /**
   * @param data
   * @param id
   * @param config
   * @returns {Promise<AxiosPromise<any>>}
   */
  async saveItem(data, id, config = {}) {
    return id ? super.putData(data, `${id}`, config) : super.postData(data, null, config);
  }

  /**
   * @param {Array} data
   * @returns {Promise<AxiosResponse<T>>}
   */
  async saveList(data) {
    return this.apiHelper.post(`${this.routeGroup}/savelist`, data);
  }

  /**
   * @param id
   * @param config
   * @returns {Promise<AxiosPromise<any>>}
   */
  async copyItem(id, config = {}) {
    return super.getData(this.model, `copy/${id}`, config);
  }

  /**
   * @param id
   * @param config
   * @returns {Promise<AxiosPromise<any>>}
   */
  async deleteItem(id, config = {}) {
    return super.deleteData(`${id}`, config);
  }

  /**
   * @returns {Promise<void>}
   */
  exportList(params = {}) {
    return this.download('export/csv', params);
  }

  /**
   * @param route
   * @param params
   * @returns {Promise<void>}
   */
  async download(route = 'export', params = {}) {
    DownloadUtil.download(getApiUrl(this._buildRoute(route), params));
  }

  /**
   * @param model
   * @param route
   * @param params
   * @param config
   * @returns {Promise<void>}
   */
  async getBlobData(model = GenericModel, route = 'export', params = {}, config = {}) {
    const response = await super.getData(model, route, params, { responseType: 'blob', ...config });
    this.showDownload(response);
  }

  /**
   * @param q
   * @returns {string}
   */
  addSearchParam(q) {
    return q;
  }
}
