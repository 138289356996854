'use strict';
import angular from 'angular';
import './design.constants';
import './base/design.services';
import './base/design.resources';
import './base/design.ctrls';
import './base/design.directives';
import './page/design.page.module';
import './layout/design.layout.module';
import './widget/design.widget.module';
import './details/details.modal.module';
import './page/executivesummary/executivesummary.module';
import './page/library/page.library.module';
import './layout/library/layout.library.module';
import '../daterange/base/daterange.constants';
import '../daterange/base/daterange.services';

import dashHtmlUrl from './base/dash.html';

angular.module('core.design', [
    'design.constants',
    'design.services',
    'design.resources',
    'design.ctrls',
    'design.directives',
    'design.page',
    'design.layout',
    'design.widget',
    'design.details',
    'page.executivesummary',
    'page.library',
    'layout.library',
    'daterange.constants',
    'daterange.services',
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('dash', {
                parent:'layout',
                url: '/dash/:id',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('dash'),
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function() {
                        return null;
                    },
                    vars: function() {
                        return null;
                    },
                    relativeDateRanges: function(DateRangeFactory) {
                        // Since relative date ranges are used app-wide, we resolve them at the app level
                        return DateRangeFactory.resource.get().then(function (relativeDateRanges) {
                            DateRangeFactory.setRelativeDateRanges(relativeDateRanges.plain());
                        });
                    },
                },
                templateUrl: dashHtmlUrl,
                controller: 'DesignController',
              onEnter: function(WidgetCurrencyDataService) {
                WidgetCurrencyDataService.clearAll();
              },
            })
            .state('fav-dash', {
                parent:'layout',
                url: '/fav/dash/:id',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('fav-dash'),
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function() {
                        return null;
                    },
                    vars: function() {
                        return null;
                    }
                },
                params: {
                    isFavorite: true
                },
                templateUrl: dashHtmlUrl,
                controller: 'DesignController',
                onEnter: function(WidgetCurrencyDataService) {
                    WidgetCurrencyDataService.clearAll();
                },
            })
    }])