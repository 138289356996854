'use strict';
import angular from 'angular';

angular.module('core.iohelper', [])
    .constant('IODefaultLinkColumns', {
        ORDER_ID: 'order_id',
        LINE_ITEM_ID: 'line_item_id',
        FLIGHT_ID: 'flight_id',
        TASK_ID: 'task_id'
    })
    .factory('IODefaultLinkColumnsRenderer', ['renderIoLinkColumn', function (renderIoLinkColumn) {
        return {
            renderIoOrderIdLinkColumn: function (data) {
                return renderIoLinkColumn('viewOrder', data);
            },
            renderIoLineItemLinkColumn: function (data) {
                return renderIoLinkColumn('viewLineItem', data);
            },
            renderIoFlightLinkColumn: function (data) {
                return renderIoLinkColumn('viewFlight', data);
            },
            renderIoTaskLinkColumn: function (data) {
                return renderIoLinkColumn('myTasks', data);
            }
        };
    }])
    .factory('renderIoLinkColumn', ['AppConfig', 'ExportFactory', function (AppConfig, ExportFactory) {
        return function renderIoLinkColumn(specificPath, data) {
            let label = data, hostname, href;

            hostname = ExportFactory.getIsExporting() ? ExportFactory.getVars().hostname : ''

            href = hostname + AppConfig.IO_URL + '#/items/' + specificPath + '/' + label;

            return '<a href="' + href + '">' + label + '</a>';
        }
    }]);
