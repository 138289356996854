import {
  darken,
  lighten,
  hexToHSL,
  hexToRGBA,
  findDarkInvert,
  findLightInvert,
} from '@/modules/core/app/helpers/ColorHelper';
import { ThemeType } from '@/modules/core/app/constants/app.constants';
import CacheHelper from '@/modules/core/app/helpers/CacheHelper';
import { getters } from '@/modules/core/app/helpers/store';
import { applyThemeOnAmCharts } from '../../charts/amChartThemeHelper';

class ThemeUIService {
  /**
   *
   * @param color
   * @param themeType
   */
  updateTheme(color, themeType = ThemeType.LIGHT) {
    const { style, classList } = document.documentElement;

    if (themeType === ThemeType.DARK) {
      classList.add('dark-theme');
    } else {
      classList.remove('dark-theme');
    }
    window.textColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--text-color')
      .trim();
    applyThemeOnAmCharts(themeType);

    if (!color) {
      return;
    }

    const { h, s, l } = hexToHSL(color);
    const { r, g, b } = hexToRGBA(color);

    if (l < 30 && themeType === ThemeType.DARK) {
      // eslint-disable-next-line tap/no-raw-text-js
      classList.add('low-contrast-primary');
    } else {
      // eslint-disable-next-line tap/no-raw-text-js
      classList.remove('low-contrast-primary');
    }

    style.setProperty('--primary-hue', h);
    style.setProperty('--primary-saturation', `${s}%`);
    style.setProperty('--primary-luminance', `${l}%`);

    style.setProperty('--primary-red', r);
    style.setProperty('--primary-green', g);
    style.setProperty('--primary-blue', b);

    style.setProperty('--primary-dark-invert', findDarkInvert(color));
    style.setProperty('--primary-dark-invert', findDarkInvert(color));

    style.setProperty('--primary-dark-invert-dark-5', darken(findDarkInvert(color), 5));
    style.setProperty('--primary-dark-invert-light-5', lighten(findDarkInvert(color), 5));

    style.setProperty('--primary-light-invert', findLightInvert(color));
    style.setProperty('--primary-light-invert-5', lighten(findLightInvert(color), 95));

    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
      classList.add('OSX');
    }
  }

  setUserTheme(themeType) {
    // eslint-disable-next-line tap/no-raw-text-js
    const userThemeKey = `userTheme_${getters.session.getUser().id}`;
    // eslint-disable-next-line tap/no-raw-text-js
    if (themeType === 'null') {
      CacheHelper.remove(userThemeKey);
    } else if (Object.values(ThemeType).includes(themeType)) {
      CacheHelper.set(userThemeKey, themeType);
      this.updateTheme(null, themeType);
    }
  }

  getUserTheme(userId) {
    // eslint-disable-next-line tap/no-raw-text-js
    const userThemeKey = `userTheme_${userId ?? getters.session.getUser()?.id}`;
    return CacheHelper.get(userThemeKey);
  }
}

export default new ThemeUIService();
