export class CallbackRouteState {
  /**
   *@var {Array}
   */
  callbackRoutes = [];

  /**
   * @var {Boolean}
   */
  isCallbackEnabled;
}
