import angular from 'angular';
import './exportbuilder.panel.components';
import './exportbuilder.panel.directives';
import './styles/exportbuilder.panel.styles.components';
import './settings/exportbuilder.panel.settings.components';
import './exportbuilder.panel.ui.models';

angular.module('exportbuilder.panel', [
    'exportbuilder.panel.components',
    'exportbuilder.panel.directives',
    'exportbuilder.panel.models'
]);