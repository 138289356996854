'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('cluster.services', [])

    .factory('ClusterService', ClusterService);

/**
 * @ngInject
 */
function ClusterService(Restangular, ColumnValueType) {
    var cluster = Restangular.service('clusters');
    var values = cluster.one('values');

    /**
     * Get values for all possible distinct specific field
     * @param fields
     * @param filters
     * @returns {*}
     */
    function getFieldValues(fields, filters) {
        var params = _.isUndefined(filters) ? {} : filters;
        if (!_.isUndefined(fields)) {
            params.fields = fields;
        }
        return values.one(ColumnValueType.DISTINCT).get(params);
    }

    return {
        cluster: cluster,
        values: values,
        getFieldValues: getFieldValues
    }
}