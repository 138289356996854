'use strict';
import angular from 'angular';
import './scripts/cluster.ctrls';
import './scripts/cluster.directives';
import './scripts/cluster.services';

angular.module('cluster', [
    'cluster.ctrls',
    'cluster.directives',
    'cluster.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('clusters', {
                parent:'layout',
                url: '/clusters',
                controller: 'ClusterListController',
                templateUrl: AppConfig.MAGE_URL + 'cluster/manage_clusters',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('clusters')
                }
            })
            .state('clusters.filter', {
                parent:'layout',
                url: '/clusters/status/:status',
                controller: 'ClusterListController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'cluster/manage_clusters?status=' + $stateParams.status;
                }
            })
            .state('clusters.add', {
                parent:'layout',
                url: '/clusters/add',
                data: { isNewEntity: true, primaryKey: 'cluster_id' },
                controller: 'ClusterController',
                templateUrl: AppConfig.MAGE_URL + 'cluster/add_cluster'
            })
            .state('clusters.detail', {
                parent:'layout',
                url: '/clusters/detail/:id',
                controller: 'ClusterController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'cluster/view_cluster/cluster_id/' + $stateParams.id;
                }
            })
            .state('clusters.services', {
                parent:'layout',
                url: '/clusters/detail/:id/services',
                controller: 'ClusterServicesController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'cluster/view_cluster_service_connection/cluster_id/' + $stateParams.id;
                }
            })
    }]);