/**
 * Helper function to convert HEX color to RGBA
 *
 * @param hex
 * @param [alpha]
 * @param [string]
 * @returns {string}
 */
import { rangeRandom } from '@/modules/core/helper';

export const hexToRGBA = function (hex, alpha = null, string = false) {
  let c;
  c = hex.substring(1).split('');
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = `0x${c.join('')}`;

  const r = (c >> 16) & 255;
  const g = (c >> 8) & 255;
  const b = c & 255;

  // return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
  if (alpha) {
    return string
      ? `rgba(${r}, ${g}, ${b}, ${alpha})`
      : {
          r,
          g,
          b,
          a: alpha,
        };
  }
  return string
    ? `rgb(${r}, ${g}, ${b})`
    : {
        r,
        g,
        b,
      };
};

/**
 * Converts an array of rgb values to hex string
 *
 * @param {array} rgbArray [255, 255, 255]
 * @returns {string} #ffffff
 */
export function rgbArrayToHex(rgbArray) {
  const r = rgbArray[0];
  const g = rgbArray[1];
  const b = rgbArray[2];

  const bin = (r << 16) | (g << 8) | b;
  const hex = (function (h) {
    return new Array(7 - h.length).join('0') + h;
  })(bin.toString(16).toUpperCase());

  return `#${hex}`;
}

/**
 * Converts an array of rgb values to hex string
 *
 * @param {array} rgbArray [255, 255, 255]
 * @returns {string} #ffffff
 */
export function rgbToHex(rgb) {
  const { r, g, b } = rgb;
  return rgbArrayToHex([r, g, b]);
}

/**
 * Helper function to convert HEX color to HSL
 *
 * @param {string} hex e.g. #ffffff
 * @returns {string}
 */
export function hexToHSL(hex) {
  // Convert hex to RGB first
  let r = 0;
  let g = 0;
  let b = 0;

  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {
    h,
    s,
    l,
  };
}

/**
 * Converts hsl to rgb
 *
 * @param hsl
 * @returns {{r: *, b: *, g: *}}
 */
export function hslToRgb(hsl) {
  const { h, s, l } = hsl;
  let r;
  let g;
  let b;

  if (s === 0) {
    r = l;
    g = l;
    b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = _hue2rgb(p, q, h + 1 / 3);
    g = _hue2rgb(p, q, h);
    b = _hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.max(0, Math.min(Math.round(r * 255), 255)),
    g: Math.max(0, Math.min(Math.round(g * 255), 255)),
    b: Math.max(0, Math.min(Math.round(b * 255), 255)),
  };
}

/**
 * @param p
 * @param q
 * @param t
 * @returns {*}
 * @private
 */
function _hue2rgb(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

/**
 * Converts hsl to hex
 *
 * @param hsl
 * @returns {string}
 */
export function hslToHex(hsl) {
  const { h } = hsl;
  let { s, l } = hsl;
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (h >= 300 && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;

  return `#${r}${g}${b}`;
}

/**
 *
 * @param color
 */
export function findDarkInvert(color) {
  let { l } = hexToHSL(color);
  l /= 100;
  let luminosity = 0.1;
  if (l > 0.45) {
    luminosity = 0.4;
  } else if (l > 0.3) {
    luminosity = 0.2;
  }
  return darken(color, luminosity);
}

export function findLightInvert(color) {
  let { l } = hexToHSL(color);
  l /= 100;
  let luminosity = 0.1;
  if (l > 0.45) {
    luminosity = 0.4;
  } else if (l > 0.3) {
    luminosity = 0.2;
  }
  return lighten(color, luminosity);
}

/**
 * Increases the luminosity of the hex value
 *
 * @param hexColor
 * @param luminosity
 * @returns
 */
export function lighten(hexColor, luminosity) {
  return _colorLuminance(hexColor, luminosity);
}

/**
 * Decreases the luminosity of the hex value
 *
 * @param hexColor
 * @param luminosity
 * @requires hex value
 * @returns
 */
export function darken(hexColor, luminosity) {
  return _colorLuminance(hexColor, -luminosity);
}

/**
 * @param hex #ffffff
 * @param lum Values between 1.00 and 0.00
 * @returns {string} hex value #ffffff
 * @private
 */
function _colorLuminance(hex, lum) {
  // validate hex string
  const hsl = hexToHSL(hex);
  hsl.l /= 100;
  hsl.l += lum;

  if (hsl.l < 0) {
    hsl.l = 0;
  } else if (hsl.l > 100) {
    hsl.l = 100;
  }

  return hslToHex(hsl);
}

/**
 * Generate random hex color
 * @returns {string}
 */
export function randomHexColor() {
  return rgbArrayToHex([rangeRandom(50, 230), rangeRandom(50, 230), rangeRandom(50, 230)]);
}

/**
 * Returns the text color based on background color provided
 */
export const textColorForHex = (hex) => {
  const { r, g, b } = hexToRGBA(hex);
  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    return '#000000';
  }
  return '#ffffff';
};
