'use strict';
import angular from 'angular';

angular.module('servicedetails.services', [])

    .factory('ServiceDetailFactory', ServiceDetailFactory);

//
// Service detail service
//
ServiceDetailFactory.$inject = ['$rootScope', '$http'];
function ServiceDetailFactory($rs, $http) {
    return {
        myFunction: function() {

        }
    }
}