'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

angular.module('core.messenger.services', [])
    .factory('MessengerFactory', MessengerFactory);

/**
 * @ngInject
 */
function MessengerFactory(
    AppFactory,
    MessengerModelFactory
) {
    var props = MessengerModelFactory.getProps();

    return {
        setThreads: setThreads,
        addThread: addThread,
        removeThread: removeThread,
        getThreads: getThreads,
        getThreadCount: getThreadCount,
        hasThreads: hasThreads,
        setOptions: setOptions,
        getMessengerOptions: getMessengerOptions,
        clearThreads: clearThreads,
        getIsLoading: getIsLoading,
        getCurrentUserImage: getCurrentUserImage,
        getAuthorName: getAuthorName,
        getPrettyTime: getPrettyTime,
    };

    /**
     * @param promise
     */
    function setThreads(promise) {
        props.state.isLoading = true;
        promise.then(function(threads) {
            props.state.isLoading = false;
            props.data.threads = threads;
        }, function(err) {
            props.state.isActive = false;
            props.state.isLoading = false;
        });
    }

    /**
     *
     * @param options
     */
    function setOptions(options) {
        props.options = MessengerModelFactory.getOptions(options);
    }

    /**
     *
     */
    function getMessengerOptions() {
        return props.options;
    }

    /**
     *
     * @param thread
     */
    function addThread(thread) {
        props.data.threads.push(thread);
    }

    /**
     *
     * @param id
     */
    function removeThread(id) {
        _.remove(props.data.threads, {id: id});
    }

    /**
     *
     * @returns {*|{}}
     */
    function getThreads() {
        return props.data.threads;
    }

    /**
     *
     * @returns {*}
     */
    function getThreadCount() {
        return props.data.threads.length;
    }

    /**
     *
     * @returns {*}
     */
    function hasThreads() {
        return props.data.threads.length > 0;
    }

    /**
     *
     */
    function clearThreads() {
        props.data.threads = [];
    }

    /**
     *
     * @returns {boolean|*}
     */
    function getIsLoading() {
        return props.state.isLoading;
    }


    /**
     * @returns {*}
     */
    function getCurrentUserImage() {
        var userImageMetadata = AppFactory.getUser().userImageMetadata;
        return userImageMetadata ? userImageMetadata.secure_url : null;
    }

    /**
     * @param author
     */
    function getAuthorName(author) {
        return _.isNil(author) ? 'User' : author;
    }

    /**
     * @param timestamp
     */
    function getPrettyTime(timestamp) {
        return (_.isNaN(timestamp) || timestamp <= 0) ? '' : moment.unix(timestamp).fromNow();
    }
}