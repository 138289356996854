import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BaseDataResource } from '@/modules/core/app/resources/BaseDataResource';

export class LineItemsReportDataResource extends BaseDataResource {
  constructor() {
    super(RouteGroup.LINEITEMSREPORTS);
  }
}

export default new LineItemsReportDataResource();
