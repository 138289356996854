'use strict';
import angular from 'angular';
import './scripts/connect.ctrls';
import './scripts/connect.directives';
import './scripts/connect.services';
import './scripts/marketplace.directives';
import './scripts/marketplace.services';

angular.module('connect', [
    'connect.ctrls',
    'connect.directives',
    'connect.services',
    'marketplace.directives',
    'marketplace.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('service.manage', {
                parent:'layout',
                url: '/service/manage',
                controller: 'ConnectController',
                templateUrl: AppConfig.MAGE_URL + 'setup/appdrawer',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('service.manage')
                }
            })
            .state('service.manage.open', {
                parent:'layout',
                url: '/service/manage/:id',
                controller: 'ConnectController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'setup/appdrawer/open/' + $stateParams.id;
                }
            })
            .state('service.manage.showconnection', {
                parent:'layout',
                url: '/service/manage/connections/:id',
                controller: 'ConnectController',
                templateUrl: AppConfig.MAGE_URL + 'setup/appdrawer',
            })
            .state('service.mappings', {
                parent:'layout',
                url: '/service/mappings',
                templateUrl: AppConfig.MAGE_URL + 'mapping/index'
            })
            .state('service.mappings.error', {
                parent:'layout',
                url: '/service/mappings/:error',
                templateUrl: AppConfig.MAGE_URL + 'mapping/index',
                controller: function($stateParams) {
                    alert($stateParams.error);
                }
            });
    }]);