'use strict';
import angular from 'angular';
import swal from 'bootstrap-sweetalert'
import _ from 'lodash';

angular.module('design.layout.services')

    .factory('EditLayoutFactory', EditLayoutFactory)
    .factory('ManageLayoutFactory', ManageLayoutFactory);

/**
 * @ngInject
 */
function EditLayoutFactory(
    $rootScope,
    $isoEvents,
    SlidePanelFactory,
    DataSourceFactory,
    DesignFactory,
    LayoutFactory,
    WidgetFactory,
    PubSub,
    $PageGenerateThumbnailEvents
) {
    /**
     * Widget specs before any reordering or resizing
     * @type {Array}
     */
    var originalWidgets = [];

    var getDefaultState = function() {
        return {
            panelId: 'edit-layout-panel',
            isShowing: false,
            isEditing: false,
            isLayout: true,
            isReordering: false,
            isResizing: false
        };
    };

    var shared = {
        state: getDefaultState(),
        layoutWidgets: [],
        sortedLayoutWidgets: [],
        sortableOptions: {
            containment: '.edit-layout-panel',
            forcePlaceholderSize: true,
            placeholder: 'ui-state-highlight',
            handle: '.tiny-widget.orderable',
            axis: 'y',
            sort: _onSort,
            stop: _onSortStop
        },
        closePanel: closePanel,
        getWidgetDataSourceColor: getWidgetDataSourceColor,
        getDataSourceIcon: getDataSourceIcon,
        getWidgetTypeIcon: getWidgetTypeIcon,
        getWidgetTitle: getWidgetTitle,
        getWidgetPlainTitle: WidgetFactory.getWidgetPlainTitle
    };

    return {
        init: init,
        shared: shared,
        resetState: resetState,
        getIsEditPanelOpen: getIsEditPanelOpen
    };

    function getIsEditPanelOpen() {
        return shared.state.isShowing;
    }
    /**
     * Inits move widgets to layouts panels and fetches all widgets in all layouts (does not load data for them)
     * @param widgetId
     */
    function init(widgetId) {
        resetState();
        originalWidgets = angular.copy(DesignFactory.getCurrentWidgets());
        shared.sortedLayoutWidgets = _.sortBy(_.toArray(originalWidgets), 'display_order');
        // Make sure we set display orders correctly (TA-15520)
        _onSortStop();
        SlidePanelFactory.show(shared.state);
        shared.state.isEditing = true;

        if (DesignFactory.props.hasVariableHeightWidgets) {
            _initIsotopeLayout();
        }
    }

    function _initIsotopeLayout() {
        $rootScope.$broadcast($isoEvents.INIT);
    }

    function _disableIsotopeLayout() {
        $rootScope.$broadcast($isoEvents.DESTROY);
    }

    function _updateIsotopeLayout() {
        $rootScope.$broadcast($isoEvents.LAYOUT);
    }

    /**
     * @private
     */
    function _getModelWidgets() {
        var widgets = {};
        _.each(shared.sortedLayoutWidgets, function(widget) {
            widgets[widget.id] = _.pick(widget, ['id', 'display_order'])
        });
        return widgets;
    }

    function _onSort() {
        shared.state.isReordering = true;
    }

    function _onSortStop() {
        shared.state.isReordering = false;
        _updateWidgetDisplayOrders();
        _updateCurrentWidgetDisplayOrders();
        _saveWidgetsLayout(true);
    }

    /**
     *
     * @private
     */
    function _saveWidgetsLayout(displayOrderSave = false) {
        var model = {
            widgets: _getModelWidgets(),
            id: DesignFactory.getCurrentLayout().id,
            display_order_save: displayOrderSave
        };

        LayoutFactory.saveWidgets(model).then(() => {
            PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: model.id, page: DesignFactory.getCurrentPage() });
        });
    }

    /**
     * Sets the widget display order property
     */
    function _updateWidgetDisplayOrders() {
        _.each(shared.sortedLayoutWidgets, function(widget, i) {
            widget.display_order = i + 1;
        });
    }

    /**
     * Sets the widget display order property of the widgets on the current layout (isotope)
     */
    function _updateCurrentWidgetDisplayOrders() {
        var widgets = DesignFactory.getCurrentWidgets();
        _.each(shared.sortedLayoutWidgets, function(sortedWidget, i) {
            widgets[sortedWidget.id].display_order = i + 1;
        });
        _updateIsotopeLayout();
    }

    function closePanel() {
        shared.state.isEditing = false;
        SlidePanelFactory.hide(shared.state);
        if (DesignFactory.props.hasVariableHeightWidgets) {
            _disableIsotopeLayout();
            LayoutFactory.updateLayoutWidgets();
        }
    }

    /**
     *
     * @param metadata
     */
    function getWidgetDataSourceColor(metadata) {
        return DesignFactory.getDataSourceColor(metadata.data_source);
    }

    /**
     *
     * @param metadata
     */
    function getDataSourceIcon(metadata) {
        return DataSourceFactory.getDataSourceIcon(metadata.data_source);
    }

    /**
     *
     * @param widgetType
     */
    function getWidgetTypeIcon(widgetType) {
        return WidgetFactory.getWidgetType(widgetType).icon;
    }

        /**
         *
         * @param widget
         */
        function getWidgetTitle(widget) {
            return DesignFactory.getWidgetTitle(widget);
        }

    function resetState() {
        shared.state = getDefaultState();
    }
}

/**
 * @ngInject
 */
function ManageLayoutFactory(
    UIFactory,
    SlidePanelFactory,
    DesignFactory,
    PageFactory,
    LayoutFactory,
    EditLayoutFactory,
    WidgetFactory,
    ExecutiveSummaryFactory,
    AppFactory,
    PubSub,
    $LayoutEvents,
    ManageProductsService,
    DataSourceType,
    $PageGenerateThumbnailEvents
) {
    var getDefaultState = function() {
        return {
            panelId: 'manage-layouts-panel',
            isShowing: false,
            isLoading: false,
            isEditing: false,
            isSaving: false,
            isLayout: true,
            isReordering: false,
            movedWidget: null,
        };
    };

    var shared = {
        state: getDefaultState(),
        layoutsToManage: [],
        originalLayouts: [],
        isShowingDataSourceSelectForLayout: null,
        isShowingProductSelectForLayout: null,
        triggerDashboardRefresh: false,
        sortableOptions: {
            containment: '.manage-layouts-panel',
            forcePlaceholderSize: true,
            placeholder: 'ui-state-highlight',
            handle: '.layout-drag-handle',
            zIndex: 100000,
            axis: 'y',
            sort: _onSort,
            stop: _onSortStop
        },
        closePanel: closePanel,
        triggerEditLayouts: triggerEditLayouts,
        editLayoutTitle: editLayoutTitle,
        saveEditLayouts: saveEditLayouts,
        cancelEditLayouts: cancelEditLayouts,
        deleteLayoutConfirm: deleteLayoutConfirm,
        updateLayoutIndexes: updateLayoutIndexes,
        getWidgetDataSourceColor: getWidgetDataSourceColor,
        getDataSourceIcon: getDataSourceIcon,
        getWidgetTypeIcon: getWidgetTypeIcon,
        setDataSourceToLayout: setDataSourceToLayout,
        setCategoryToLayout: setCategoryToLayout,
        setProductsToLayout: setProductsToLayout,
        canShowDeleteSectionOption: canShowDeleteSectionOption
    };

    return {
        init: init,
        shared: shared,
        resetState: resetState,
        addNewLayout: addNewLayout,
        addWidgetToLayout: addWidgetToLayout
    };

    /**
     * Inits move widgets to layouts panels and fetches all widgets in all layouts (does not load data for them)
     * @param widgetId
     */
    function init(widgetId) {
        resetState();
        SlidePanelFactory.show(shared.state);
        var allLayouts = DesignFactory.getCurrentPage().layouts;

        shared.layoutsToManage = angular.copy(_.sortBy(_.toArray(allLayouts), 'display_order'));
        _.each(shared.layoutsToManage, function(layout) {
            if (_.isNil(layout.metadata.data_source)) {
                layout.metadata.data_source = {};
            }
            layout.widgets = _.sortBy(_.toArray(layout.widgets), 'display_order');
        });
        setUpEdit();
    }

    function closePanel() {
        SlidePanelFactory.hide(shared.state);
    }

    function triggerEditLayouts() {
        shared.state.isEditing = !shared.state.isEditing;
    }

    function updateLayoutIndexes() {
        shared.isShowingDataSourceSelectForLayout = null;
        shared.isShowingProductSelectForLayout = null;
    }

    function editLayoutTitle(index, title) {
        shared.layoutsToManage[index].title = title;
    }

    function validateLayoutTitles() {
        let layoutTitlesAreInvalid = _.some(shared.layoutsToManage, (layout) => _.isEmpty(_.trim(layout.title)));
        if (layoutTitlesAreInvalid) {
            UIFactory.notify.showError("Section title cannot be empty.");
            return false
        }
        return true;
    }

    function saveEditLayouts() {
        if (!validateLayoutTitles()) {
            return;
        }

        let sectionDataSourceAssignedId = null;
        let isAnyTitleRenamed = false;
        const currentPage = DesignFactory.getCurrentPage();
        var allLayouts = currentPage.layouts;
        // Update layout titles
        _.each(shared.layoutsToManage, function(layout) {
            var item = allLayouts[layout.id];

            if (item.title !== layout.title) {
                isAnyTitleRenamed = true;
            }
            item.title = layout.title;

            if (_dashboardRefreshRequired(layout, item)) {
                shared.triggerDashboardRefresh = true;
            }

            if ((item.metadata.data_source && item.metadata.data_source.id) !== (layout.metadata.data_source && layout.metadata.data_source.id)) {
                sectionDataSourceAssignedId = (layout.metadata.data_source && layout.metadata.data_source.id);
            }
            item.metadata.data_source = layout.metadata.data_source;
        });

        _saveLayouts(allLayouts).then(() => {
            if (window.isNUI) {
                PubSub.emit("SegmentEvents", {
                    event: 'ManageSections',
                    payload: {
                        dashboard_id: currentPage.id,
                        dashboard_name: currentPage.title,
                        section_rename: isAnyTitleRenamed,
                        section_assign_data_source: !!sectionDataSourceAssignedId,
                        section_data_source_id: sectionDataSourceAssignedId,
                        section_reorder: false
                    }
                })
            }
        });
    }

    function setUpEdit() {
        var originalLayouts = _.map(shared.layoutsToManage, function(layout) {
            return {data_source: layout.metadata.data_source, title: layout.title, display_order: layout.display_order, id: layout.id};
        });
        updateLayoutIndexes();
        shared.originalLayouts = _.cloneDeep(originalLayouts);
    }

    /**
     * Validates if a full dashboard refresh is required
     * - Adding a data_source to a layout for the first time
     * - Changing a data_source from a layout with another data_source
     * @param editingLayout
     * @param layout
     * @private
     */
    function _dashboardRefreshRequired(editingLayout, layout) {
        if (shared.triggerDashboardRefresh) {
            return false;
        }

        // for first time
        if (editingLayout.metadata.data_source !== layout.metadata.data_source) {
            shared.triggerDashboardRefresh = true;
        }

        // changing existing data_source
        if (editingLayout.metadata.data_source
            && layout.metadata.data_source
            && editingLayout.metadata.data_source.id !== layout.metadata.data_source.id) {
            return true;
        }
    }

    /**
     * Assigns the service to layout's metadata.
     * @param dataSourceId
     */
    function setDataSourceToLayout(dataSourceId) {
        var layout = _getEditingDataSourceLayout();
        if (!layout) return;

        if (!dataSourceId) {
            layout.metadata.data_source = {};
            return;
        }

        var dataSource = _.find(AppFactory.getConnectedServices(), {id: dataSourceId});
        layout.metadata.data_source = angular.copy(dataSource);
    }

    /**
     * Assigns the category to layout's metadata.
     * @param categoryId
     */
    function setCategoryToLayout(categoryId) {
        var layout = _getEditingDataSourceLayout();
        if (!layout) return;

        if (!categoryId) {
            layout.metadata.data_source = {};
            return;
        }

        var dataSource = _.find(AppFactory.getConnectedCategories(), {id: categoryId});
        layout.metadata.data_source = angular.copy(dataSource);
    }

    function setProductsToLayout(productIds) {
        let layout = _getEditingDataSourceLayout();
        if (!layout) return;

        if (_.isEmpty(productIds)) {
            layout.metadata.data_source = {};
        }

        let dataSources = _.filter(ManageProductsService.getProducts(), product => _.includes(productIds, product.id));
        layout.metadata.data_source = angular.copy(dataSources);
    }

    function _getEditingDataSourceLayout() {
        return _.find(shared.layoutsToManage,
            layout => shared.isShowingDataSourceSelectForLayout === layout.id || shared.isShowingProductSelectForLayout === layout.id);
    }

    /**
     *
     * @param layouts
     * @param displayOrderSave
     * @private
     */
    function _saveLayouts(layouts, displayOrderSave = false) {
        updateLayoutIndexes();

        // Make sure not to save every widget in the layouts
        var layoutsToSave =_.map(angular.copy(layouts), function(layout) {
            delete layout.widgets;
            if (!_isValidProductDataSourceId(layout.metadata.data_source)
                && !_isValidDataSourceId(layout.metadata.data_source)) { // checks if data source
                delete layout.metadata.data_source;
            }
            return layout;
        });
        const newFirstLayout = _.find(layoutsToSave, layout => layout.display_order === 1);
        const originalFirstLayout = _.find(shared.originalLayouts, layout => layout.display_order === 1);

        shared.state.isSaving = true;
        return LayoutFactory.saveList(layoutsToSave, displayOrderSave).then(function() {
            shared.state.isSaving = false;
            shared.state.isEditing = false;
            UIFactory.notify.showSuccess('Sections successfully edited');
            PubSub.emit($LayoutEvents.CHANGED, DesignFactory.getCurrentLayout());

            if (newFirstLayout.id !== originalFirstLayout.id) {
                PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: newFirstLayout.id, page: DesignFactory.getCurrentPage() });
            }

            if (shared.triggerDashboardRefresh) {
                DesignFactory.$rebuildPage();
                shared.triggerDashboardRefresh = false;
            }
        });
    }

    function _isValidProductDataSourceId(data_source) {
        return (_.isArray(data_source) // checks if array of products
            && !_.isEmpty(data_source)
            && data_source[0].type === DataSourceType.PRODUCT
            && !_.isNil(data_source[0].id));
    }

    function _isValidDataSourceId(data_source) {
        return !_.isEmpty(data_source)
            && !_.isArray(data_source)
            && !_.isNil(data_source.id)
            && (data_source.type === DataSourceType.SERVICE_DATA || data_source.type === DataSourceType.CATEGORY_DATA);
    }

    function cancelEditLayouts() {
        shared.triggerDashboardRefresh = false;
        shared.state.isEditing = false;
        updateLayoutIndexes();
        restoreLayouts();
    }

    function restoreLayouts() {
        _.each(shared.layoutsToManage, function(layout, index) {
            var original = shared.originalLayouts[index];
            layout.title = original.title;
            layout.metadata.data_source = original.data_source;
        });
    }

    function deleteLayoutConfirm(layout) {
        var options = {};
        options.text = 'You will delete <b>' + layout.title + '</b> along with <b>ALL</b> its widgets';
        options.html = true;
        options.callback = function() {
            shared.state.isLoading = true;
            return LayoutFactory.remove(layout.id).then(function (json) {
                shared.state.isLoading = false;
                if (!json.error) {
                    var currentPage = DesignFactory.getCurrentPage();
                    const currentLayoutId = DesignFactory.getCurrentLayout().id;

                    delete currentPage.layouts[layout.id];
                    _.remove(shared.layoutsToManage, layout);

                    _updateLayoutDisplayOrders();
                    _updateCurrentLayoutDisplayOrders();
                    _saveLayoutDisplayOrders();

                    DesignFactory.setCurrentPage(currentPage);


                    // If deleted layout is the one currently selected, move to the first one
                    if (currentLayoutId == layout.id) {
                        var firstLayout = _.first(_.toArray(currentPage.layouts));
                        LayoutFactory.changeLayout(firstLayout, true);
                    }

                    UIFactory.notify.showSuccess(layout.title + ' successfully deleted');
                }
                swal.close();
            }, function() {
                swal.enableButtons();
            });
        };

        UIFactory.confirmDelete(options);
    }

    /**
     *
     * @param metadata
     */
    function getWidgetDataSourceColor(metadata) {
        return EditLayoutFactory.shared.getWidgetDataSourceColor(metadata);
    }

    /**
     *
     * @param metadata
     */
    function getDataSourceIcon(metadata) {
        return EditLayoutFactory.shared.getDataSourceIcon(metadata);
    }

    /**
     *
     * @param widgetType
     */
    function getWidgetTypeIcon(widgetType) {
        return EditLayoutFactory.shared.getWidgetTypeIcon(widgetType);
    }

    function resetState() {
        shared.state = getDefaultState();
        shared.layoutsToManage = [];
    }

    /**
     * @returns {{}}
     * @private
     */
    function addNewLayout() {
        return _addLayout(_getNewLayout(), true);
    }

    /**
     * @param layoutIdToAddTo
     * @param movedWidgetId
     */
    function addWidgetToLayout(layoutIdToAddTo, movedWidgetId) {
        var layoutToAddTo;
        var addToNewLayout = _.isNull(layoutIdToAddTo);
        var currentLayout = DesignFactory.getCurrentLayout();
        var movedWidget = angular.copy(DesignFactory.getWidget(movedWidgetId));
        shared.state.isLoading = true;

        // Move to new page
        if (addToNewLayout) {
            var newLayout = _getNewLayout();
            // Create new layout first
            _addLayout(newLayout).then(function(createdLayout) {
                createdLayout.widgets = [movedWidget];
                shared.layoutsToManage.push(createdLayout);
                layoutToAddTo = createdLayout;
                updateWidget();
            });
        }
        else {
            layoutToAddTo = DesignFactory.getLayout(layoutIdToAddTo);
            updateWidget();
        }

        function updateWidget() {
            // Copy widget to remove before it gets overriden
            var widgetToRemove = angular.copy(movedWidget);

            movedWidget.layout_id = layoutToAddTo.id;
            movedWidget.display_order = DesignFactory.getNextWidgetDisplayOrder(layoutToAddTo.widgets);

            _.each(shared.layoutsToManage, function(layout) {
                // Add widget to layout widget was moved to
                if (layoutToAddTo.id == layout.id && !addToNewLayout) {
                    layout.widgets.push(movedWidget);
                    // Sort after adding
                    layout.widgets = _.sortBy(layout.widgets, 'display_order');
                }
                // Remove widget from layout widget came from
                else if (currentLayout.id == layout.id) {
                    _.remove(layout.widgets, function(widget) {
                        return widget.id === movedWidget.id;
                    });
                }
            });

            WidgetFactory.$getElement(movedWidget.id).addClass('magictime zoomOut');

            return WidgetFactory.save(movedWidget).then(function () {
                shared.state.isLoading = false;
                UIFactory.notify.showSuccess('Widget successfully moved');
                LayoutFactory.removeWidget(widgetToRemove);
                LayoutFactory.addWidget(movedWidget);
                if (window.isNUI) {
                    const currentPage = DesignFactory.getCurrentPage();
                    PubSub.emit("SegmentEvents", {
                        event: 'ManageSections',
                        payload: {
                            dashboard_id: currentPage.id,
                            dashboard_name: currentPage.title,
                            section_rename: false,
                            section_assign_data_source: false,
                            section_data_source_id: false,
                            section_reorder: true
                        }
                    })
                }
            });
        }
    }

    /**
     * Adds a new layout to a page
     *
     * @param layout
     * @param goToLayout
     */
    function _addLayout(layout, goToLayout) {
        var page = DesignFactory.getCurrentPage();
        var maxDisplayOrder = DesignFactory.getNextLayoutDisplayOrder(page.layouts);
        shared.state.isSaving = true;

        var model = _.assign(layout, {
            id: null,
            page_id: page.id,
            title: _getLayoutTitle(layout.title, maxDisplayOrder),
            display_order: maxDisplayOrder
        });

        return LayoutFactory.save(model).then(function(json) {
            var newLayout = json.plain();

            // Update design page and layout
            DesignFactory.updateLayout(newLayout);
            PageFactory.updatePageLayouts();

            if (goToLayout || false) {
                UIFactory.notify.showSuccess('Section successfully added');
                LayoutFactory.changeLayout(newLayout, true);
                UIFactory.hideModal('add-layout-modal');
            }

            shared.state.isSaving = false;

            return newLayout;
        });
    }

    /**
     * @returns {{}}
     * @private
     */
    function _getNewLayout() {
        var page = DesignFactory.getCurrentPage();
        var maxDisplayOrder = DesignFactory.getNextLayoutDisplayOrder(page.layouts);
        return {
            id: null,
            page_id: page.id,
            title: _getLayoutTitle(null, maxDisplayOrder),
            display_order: maxDisplayOrder,
            is_predefined: false,
            widgets: {}
        };
    }

    /**
     * @private
     * @param title
     * @param index
     * @returns {string}
     */
    function _getLayoutTitle(title, index) {
        return _.isNull(title) || /Section \d/.test(title) ? 'Section ' + index : title;
    }

    function _onSort() {
        shared.state.isReordering = true;
    }

    function _onSortStop() {
        shared.state.isReordering = false;
        _updateLayoutDisplayOrders();
        _updateCurrentLayoutDisplayOrders();
        _saveLayoutDisplayOrders();
    }

    function _updateLayoutDisplayOrders() {
        _.each(shared.layoutsToManage, function(layout, i) {
            layout.display_order = i + 1;
        });
    }

    function _updateCurrentLayoutDisplayOrders() {
        var layouts = DesignFactory.getCurrentPage().layouts;
        _.each(shared.layoutsToManage, function(sortedLayout, i) {
            layouts[sortedLayout.id].display_order = i + 1;
        });
        PageFactory.updatePageLayouts();
    }

    /**
     * @private
     */
    function _saveLayoutDisplayOrders() {
        _saveLayouts(DesignFactory.getCurrentPage().layouts, true);
    }

    /**
     * Function returns if Delete Section option can be shown or not
     * @param isPredefinedLayout
     * @returns {boolean}
     */
    function canShowDeleteSectionOption(isPredefinedLayout = false) {
        return shared.layoutsToManage.length > 1 && !shared.state.isEditing && !isPredefinedLayout
    }
}