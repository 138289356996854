"use strict";
import angular from "angular";
import _, { isEmpty } from "lodash";

angular
  .module("widget.executivesummary.services", [])

  .factory("ExecutiveSummaryWidgetFactory", ExecutiveSummaryWidgetFactory);

/**
 * @ngInject
 */
function ExecutiveSummaryWidgetFactory(
    WidgetFactory,
    WidgetDataSourceFactory,
    ExportBuilderDashboardService,
    ExecutiveSummaryResourceFactory
) {

    let widgetTitle = 'Executive Summary Widget';

    return {
        getData: getData,
        getWidgetTitle: getWidgetTitle,
    };

    /**
     * Returns data/content for executive summary widgets
     * @param widget
     * @param params
     * @returns {Promise<{content: string}|Promise<*>>}
     */
    async function getData(widget, params) {
      return getExecutiveSummaryItem();
    }

    /**
     * Returns the message as content when sample data toggle is enabled
     * @returns {{content: string}}
     */
    function getSampleContent() {
      return {
        content:
          "The executive summary will not be visible when the sample data toggle is enabled",
      };
    }

    function getWidgetTitle() {
        return widgetTitle;
    }

    /**
     * Returns the executive summary item
     * @returns {Promise<*>}
     */
    async function getExecutiveSummaryItem(isTitle = false) {
      let pageId = ExportBuilderDashboardService.getBuilder().currentPage.id;
      let reportId = ExportBuilderDashboardService.getBuilder().report.id;
      let executiveSummary = [];

      return await ExecutiveSummaryResourceFactory.getSummaryDataForPageOrLayouts(
        [],
        [reportId],
        [pageId]
      ).then(function (json) {
        const summaries = json.plain();
        if (!isEmpty(summaries)) {
            executiveSummary = summaries[0];
            if (!isEmpty(executiveSummary) && !isEmpty(executiveSummary.items)) {
                const summaryItem = executiveSummary.items[executiveSummary.items.length - 1]
                widgetTitle = summaryItem.title;

                if (isTitle) {
                    return widgetTitle;
                }
                return summaryItem;
            }

            return {};
        }
      });
    }
}
