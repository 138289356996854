'use strict';
import angular from 'angular';

angular.module('core.industrybenchmark.ctrls', [])
    .controller('IndustryBenchmarkDashController', IndustryBenchmarkDashController);

/**
 * @ngInject
 */
function IndustryBenchmarkDashController(
    $scope,
    DataSourceType,
    AppFactory
) {
    var resetDateRange;

    function init() {
        $scope.$on('design:page:callback', function() {
            var compareDateRange = AppFactory.getComparisonDateRange();
            if (compareDateRange && compareDateRange.enabled) {
                AppFactory.toggleComparisonDateRange(false);
                resetDateRange = true;
            }        
        });

        $scope.$on('$destroy', function() {
            resetDateRange && AppFactory.toggleComparisonDateRange(true);
        });
    }

    init();
}

