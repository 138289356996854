'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import marketPlaceHtmlUrl from '../views/marketplace.html';

import logoDarkImageUrl from '../../../images/logo-dark.png';

angular.module('marketplace.directives', [])
    .directive('marketplace', marketplace);

marketplace.$inject = ['MarketplaceService', '$compile'];
function marketplace(MarketplaceService, $compile) {
    return {
        restrict: 'E',
        transclude: true,
        scope: {},
        templateUrl: marketPlaceHtmlUrl,
        controller: function($scope) {
            $scope.message = "These metrics are for elite customers."
        },
        link: function(scope, elem, attrs, ctrl, transcludeFn) {
            scope.marketplaceData = {};
            scope.marketplaceActive = false;
            scope.showMarketplace = showMarketplace;
            scope.hideMarketplace = hideMarketplace;
            var $element = angular.element(elem);
            var $container = angular.element('div.connect-container');
            var $tile = $element.find('div.service-square');


            // console.log($element.find('is-connected-badge'));
            /*
            if(!$element.find('a.is-connected-badge').length) {
                $tile.hover(function(){
                    $tile.fadeTo('slow', 0.3);
                },function(){
                    $tile.fadeTo('slow', 1);
                });
            }
            */

            //bind the local directive scope to the transcluded element rather than the parent scope
            transcludeFn(scope, function(clonedTransclude){
                elem.prepend(clonedTransclude);
                $.core.connectservices.assignClients(elem);
                $.core.connectservices.assignClientsIndividual(elem);
                $.core.connectservices.connectSimple(elem);
                $.core.connectservices.disconnectHover(elem);
                $.core.connectservices.disconnectService(elem);
            });

            //call the isotope show functionality
            function showMarketplace(serviceId, sName){
                scope.marketplaceActive = true;
                //elem.find('div.body').show();
                elem.addClass('marketplace-active');
                elem.removeClass('marketplace-inactive');
                angular.element('#right-frame').scrollTop(0);

                var $square = angular.element('div[data-service-id="' + serviceId + '"]');
                if(!$square.parent('div.header').length){
                    $square.wrap('<div class="row header"></div>');
                }

                //Set the one off listener for isotope
                $container.isotope('once', 'layoutComplete', function() {
                    setTimeout(function () {
                        if(_.isEmpty(scope.marketplaceData)) {

                            var marketplaceData = {}
                            $.core.main.showAjaxMainLoading({text: 'Loading Service...'});
                            MarketplaceService.getService(serviceId).then(function (data) {
                                marketplaceData.service = data.data.data;
                                MarketplaceService.getDataViews(serviceId).then(function (data) {
                                    marketplaceData.columns = data.data.metadata.columns;
                                    MarketplaceService.getServiceInfo(serviceId).then(function (data) {
                                        marketplaceData.info = data.data.data;
                                        console.log(marketplaceData.info);
                                        if(marketplaceData.info && marketplaceData.info.links){
                                            marketplaceData.info.links = JSON.parse(marketplaceData.info.links);
                                        }
                                        scope.marketplaceData = marketplaceData;
                                        var summary = angular.element('<div class="about"><p><h3>About {{marketplaceData.service.original_name}}:</h3></p><p>{{marketplaceData.info.summary}}</p></div>');
                                        var keywords = angular.element('<div class="keywords"><p><h3>KeyWords</h3></p><p>{{marketplaceData.info.keywords}}</p></div>');
                                        if(scope.marketplaceData.service.preferred === "1") {
                                            var preferred = angular.element(`<div class="preferred"><img src='${logoDarkImageUrl}'><h3>Premier Partner</h3></div>`);
                                        }
                                        $square.parent('div.header').append(summary);
                                        $square.parent('div.header').append(keywords);
                                        $square.parent('div.header').append(preferred);
                                        $compile(summary)(scope);
                                        $compile(keywords)(scope);
                                        $compile(preferred)(scope);
                                        $.core.main.hideAjaxMainLoading();
                                    });
                                });
                            })
                        }
                    },100);
                });

                //filter the isotope elements by the selected name
                //there may be a better way to target serviceNameFilter -- seems brittle currently
                $container.isotope({
                    filter: function(){
                        return $(this)['context'].dataset.serviceNameFilter === sName ? true : false;
                    }
                });
            }

            //close out the marketplace and return to the service position
            function hideMarketplace() {

                //console.log(scope);

                scope.marketplaceActive = false;
                //elem.find('div.body').hide();
                elem.removeClass('marketplace-active');
                elem.addClass('marketplace-inactive');

                //remove filter of isotope elements
                $container.isotope({
                    filter: function(){
                        return true;
                    }
                });
            }
        }
    }
}