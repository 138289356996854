'use strict';
import angular from 'angular';
import $ from 'jquery';
import widgetCreateColumnSelectedHtmlUrl from './widget.create.column.selected.html';
import widgetCreateColumnAdminSourceHtmlUrl from './widget.create.column.adminsource.html'
import widgetColumnSelectHtmlUrl from '../../base/widget.column.select.html';
import widgetCreateColumnGroupedHtmlUrl from './widget.create.column.grouped.html';
import widgetCreateColumnItemHtmlUrl from './widget.create.column.item.html';
import widgetCreateColumnTimeGroupingsHtmlUrl from './widget.create.column.timegroupings.html';

angular.module('widget.create.directives')

// Select
    .directive('selectColumnsConfig', selectColumnsConfig)
    .directive('selectConnectionConfig', selectConnectionConfig)
    .directive('widgetColumnsSelect', widgetColumnsSelect)
    .directive('widgetConnectionSelect', widgetConnectionSelect)
    .directive('widgetRemoveSelectedColumn', widgetRemoveSelectedColumn)
    // Groupby
    .directive('groupbyColumnsConfig', groupbyColumnsConfig)
    .directive('widgetGroupbySelect', widgetGroupbySelect)
    .directive('widgetRemoveGroupedColumn', widgetRemoveGroupedColumn)
    .directive('widgetColumnItem', widgetColumnItem)
    // Time grouping
    .directive('widgetTimeGroupingList', widgetTimeGroupingList);

/**
 * Data columns selection panel
 * @returns {{restrict: string, controller: string, templateUrl, scope: {currentModule: string, metadata: string, state: string}}}
 */
function selectColumnsConfig() {
    return {
        restrict: 'E',
        controller: 'SelectColumnsConfigController',
        templateUrl: widgetCreateColumnSelectedHtmlUrl,
        scope: {
            datasource: '=',
            selectedColumns: '=',
            groupedColumns: '<',
            chartPalette: '=',
            lineColumns: '=',
            sortBy: '=',
            state: '=',
            isMultiGroup: '<'
        }
    }
}

/**
 * Data columns selection panel
 * @returns {{restrict: string, controller: string, templateUrl, scope: {currentModule: string, metadata: string, state: string}}}
 */
function selectConnectionConfig() {
    return {
        restrict: 'E',
        controller: 'SelectConnectionConfigController',
        templateUrl: widgetCreateColumnAdminSourceHtmlUrl,
        scope: {
            datasource: '=',
            selectedColumns: '=',
            state: '='
        }
    }
}

//
// Columns selection container
//
function widgetColumnsSelect() {
    return {
        restrict: 'A',
        controller: 'ColumnsSelectController',
        templateUrl: widgetColumnSelectHtmlUrl,
        scope: {
            datasource: '=',
            selectedColumns: '=',
            groupedColumns: '<', // Is used to add grouped column automatically to selected columns
            isLoadingColumns: '=',
            state: '='
        }
    }
}

/**
 * Admin Connection selection panel
 * @returns {{restrict: string, controller: string, templateUrl, scope: {selectedColumns: string, chartPalette: string, lineColumns: string, state: string}}}
 */
function widgetConnectionSelect() {
    return {
        restrict: 'A',
        controller: 'WidgetConnectionSelectController',
        templateUrl: widgetColumnSelectHtmlUrl,
        scope: {
            datasource: '=',
            selectedColumns: '=',
            isLoadingColumns: '=',
            state: '='
        }
    }
}

//
// Remove selected column
//
function widgetRemoveSelectedColumn() {
    return {
        restrict: 'A',
        controller: 'WidgetRemoveSelectedColumnController',
        scope: {
            column: '='
        },
        link: function(scope, el) {
            el.click(function() {
                scope.$evalAsync(function() {
                    if (!$(el).hasClass('disabled')) {
                        scope.removeColumn(scope.column);
                    }
                });
            });
        }
    }
}

/**
 * Data columns selection panel
 * @returns {{restrict: string, controller: string, templateUrl, scope: {currentModule: string, metadata: string, state: string}}}
 */
function groupbyColumnsConfig() {
    return {
        restrict: 'E',
        controller: 'GroupbyColumnsConfigController',
        templateUrl: widgetCreateColumnGroupedHtmlUrl,
        scope: {
            metadata: '=',
            state: '='
        }
    }
}

/**
 * Columns group by select 2
 * @returns {{restrict: string, controller: string, scope: {datasource: string, time_grouping: string, groupableColumns: string, groupedColumns: string, state: string}}}
 * @ngInject
 */
function widgetGroupbySelect(
    WidgetType
) {
    return {
        restrict: 'A',
        controller: 'ColumnsGroupbyController',
        templateUrl: widgetColumnSelectHtmlUrl,
        scope: {
            datasource: '=',
            groupedColumns: '=',
            groupByColumn: '=',
            drillDownColumn: '=',
            state: '='
        },
        link: function(scope, el) {

            /**
             * Decides whether or not to enable/disable the select 2 based on groupby columns selected
             */
            scope.handleDrillDownColumnToggle = function() {

                var $container = el.select2('container');
                var $selectEl = el.find('ui-select2');
                if (scope.state.drillDownColumnSelected && scope.state.widgetType.id !== WidgetType.DATAGRID) {
                    $selectEl.select2('enable', false);
                    $container.attr('title', 'You can only group by 2 dimensions. Remove at least one group by column to modify anew.');
                    $container.tooltip('enable');
                } else {
                    $selectEl.select2('enable', true);
                    $container.tooltip('destroy');
                }
            }
        }
    }
}

//
// Remove grouped column
//
function widgetRemoveGroupedColumn() {
    return {
        restrict: 'A',
        controller: 'WidgetRemoveGroupedColumnController',
        scope: {
            column: '=',
            drillDownColumn: '=',
            isMultiGrouped: '=',
            state: '='
        },
        link: function(scope, el) {
            el.click(function() {
                scope.$evalAsync(function() {
                    if (!$(el).hasClass('disabled')) {
                        scope.removeColumn(scope.column);
                    }
                });
            });
        }
    }
}

//
// Column list item
//
function widgetColumnItem() {
    return {
        restrict: 'E',
        templateUrl: widgetCreateColumnItemHtmlUrl,
        scope: {
            label: '<',
            format: '<',
            isMetric: '<',
            showAxisLabel: '<',
            showColumnType: '<'
        }
    }
}

//
// Date range frequency selector
//
function widgetTimeGroupingList() {
    return {
        restrict: 'E',
        controller: 'WidgetTimeGroupingsController',
        templateUrl: widgetCreateColumnTimeGroupingsHtmlUrl,
        scope: {
            widgetTypeId: '<',
            timeGrouping: '=',
            groupedColumn: '='
        }
    }
}