'use strict';
import angular from 'angular';

angular.module('widget.library.services', [])
    .factory('WidgetLibraryModalUIFactory', WidgetLibraryModalUIFactory)
    .factory('WidgetLibraryFactory', WidgetLibraryFactory)
    .factory('WidgetLibraryPublishFactory', WidgetLibraryPublishFactory);


function WidgetLibraryModalUIFactory() {
    return {
        getState: getState
    };

    function getState() {
        return true;
    }
}

/**
 * @ngInject
 */
function WidgetLibraryFactory(
    $q,
    gettextCatalog,
    AppFactory,
    DataSourceFactory,
    WidgetFactory,
    WidgetTypeGrouping,
    WidgetTagFactory,
    LibraryFactory,
    LibraryModelFactory,
    WidgetLibraryResourceFactory,
    DesignFactory,
    WidgetCreateFactory,
    DrawOption,
    WidgetSize,
    DashboardContextService,
    ReportStudioTemplateDataService
) {
    /**
     * @type {boolean}
     * @private
     */
    var _libraryNeedsRefresh = false;

    return {
        getLibraryWidgets: getLibraryWidgets,
        getLibraryNeedsRefresh: getLibraryNeedsRefresh,
        setLibraryNeedsRefresh: setLibraryNeedsRefresh,
        getIsConnected: getIsConnected,
        getSearchedWidgets: getSearchedWidgets,
        getSearchableValues: getSearchableValues,
        setDefaultWidgetState: setDefaultWidgetState,
        setDefaultWidgetValues: setDefaultWidgetValues,
        incrementDownloadCount: incrementDownloadCount,
        useWidgetAsTemplate: useWidgetAsTemplate,
    };

    /**
     * @returns {*}
     */
    function getLibraryWidgets(queryParams) {
        var deferred = $q.defer();

        let reportLanguage = '';
        var promises = [
            WidgetLibraryResourceFactory.getWidgetsForDisplay(_.assign({
                sort: '-library_download_count,-is_predefined',
                limit: 100,
            }, queryParams))
        ];

        if (!_.isEmpty(ReportStudioTemplateDataService.getReport())) {
            reportLanguage = ReportStudioTemplateDataService.getReportLanguage();
             promises = [
                WidgetLibraryResourceFactory.getWidgetsForDisplay(_.assign({
                    lang: reportLanguage
                }, queryParams))
            ];
        }
        $q.all(promises).then(function (data) {
            deferred.resolve({
                templateItems: data[0]
            });
        });

        return deferred.promise;
    }

    /**
     * @returns {boolean}
     */
    function getLibraryNeedsRefresh() {
        return _libraryNeedsRefresh;
    }

    /**
     * @param value
     */
    function setLibraryNeedsRefresh(value) {
        _libraryNeedsRefresh = value;
    }

    /**
     * @returns {boolean}
     */
    function getIsConnected(item) {
        var connectedDataSources = _.concat(AppFactory.getConnectedServices(true), AppFactory.getConnectedCategories());
        if (_.isEmpty(item.data_source_id) || !DataSourceFactory.dataSourceContainsServices(item.data_type)) {
            return true;
        }
        var connectedDataSource = _.find(connectedDataSources, {id: item.data_source_id});
        return !_.isEmpty(connectedDataSource);
    }

    /**
     * @returns {*}
     */
    function getSearchedWidgets(queryParams) {
        return WidgetLibraryResourceFactory.getWidgetsForDisplay(queryParams);
    }

    /**
     * @returns {*}
     */
    function getSearchableValues() {
        return WidgetLibraryResourceFactory.getSearchableValues().then(function(data) {
            data.tags = LibraryFactory.mapTagSearchableValues(data.tags);
            data.widgetTypes = LibraryFactory.mapWidgetTypeSearchableValues(WidgetFactory.getWidgetTypes(WidgetTypeGrouping.DATASOURCED));
            data.dataSources = LibraryFactory.mapDataSourceSearchableValues(data.dataSources);
            return _groupSearchableValues(data);
        });
    }

    /**
     * @param state
     */
    function setDefaultWidgetState(state) {
        state.isPreview = true;
        state.isThumbPreview = true;
        state.isBuilding = false;
        state.canSave = true;
    }

    /**
     * @param widget
     */
    function setDefaultWidgetValues(widget) {
        widget.chartId = 'preview-' + _.random(Number.MAX_SAFE_INTEGER);
        widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA] = true;
        widget.metadata.draw_options[DrawOption.SHOW_LABELS] = false;
        widget.metadata.draw_options[DrawOption.GRID_IS_RESPONSIVE] = false;
        widget.metadata.draw_options[DrawOption.GRID_COLLAPSE_IN_MODAL] = false;

        widget.widget_type = WidgetFactory.getWidgetType(widget.type);
        if (widget.data_source) {
            widget.data_source.icon = DataSourceFactory.getDataSourceIcon(widget.metadata.data_source);
        }
    }

    /**
     * @param id
     * @returns {*}
     */
    function incrementDownloadCount(id) {
        return WidgetLibraryResourceFactory.incrementDownloadCount(id);
    }

    /**
     * @param widget
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function useWidgetAsTemplate(widget) {
        _setLibrarySpecificProperties(widget);
        _trimLibrarySpecificProperties(widget);

        return WidgetFactory.save(widget)
            .then(newWidget => {
                return WidgetFactory.getWidget(newWidget.id, {all: true});
            })
            .then(json => json.plain());
    }

    /**
     * @param widget
     * @private
     */
    function _setLibrarySpecificProperties(widget) {
        widget.id = null;
        DashboardContextService.resolveWidgetParentId(widget);
        widget.is_predefined = false;
        widget.is_in_library = false;
        widget.created_from_library = true;
        widget.width = WidgetSize.MAX_WIDTH;
        widget.height = 3;
        widget.metadata.tags = [];
        widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA] = false;
        widget.metadata.draw_options[DrawOption.SHOW_LABELS] = true;
    }

    /**
     * Certain properties are returned when hitting library endpoints,
     * they are unwanted when creating a new widget from a library template
     *
     * @param widget
     * @private
     */
    function _trimLibrarySpecificProperties(widget) {
        delete widget.chartId;
        delete widget.widget_type;
        delete widget.data_source;
        delete widget.tags;
        delete widget.description;
    }

    /**
     * @param data
     * @returns {*[]}
     * @private
     */
    function _groupSearchableValues(data) {
        return [
            {text: gettextCatalog.getString('Data Categories'), children: data.dataSources},
            {text: gettextCatalog.getString('Widget Types'), children: data.widgetTypes},
            {text: gettextCatalog.getString('Widget Tags'), children: data.tags}
        ];
    }
}

/**
 * @ngInject
 */
function WidgetLibraryPublishFactory(
    WidgetFactory,
    WidgetTagFactory
) {
    return {
        publish: publish,
        saveTags: saveTags
    };

        /**
         * Saves the widget and sends it to the Widget Library
         * @params widget object
         */
        function publish(widget) {
            var model = {
                title: widget.title,
                description: widget.metadata.description,
                tags: widget.metadata.tags
            };
            delete widget.created_at;
            return WidgetFactory.publish(widget.id, model);
        }

    /**
     * @param tags
     * @returns {*}
     */
    function saveTags(tags) {
        return WidgetTagFactory.saveTags(tags);
    }
}