import angular from 'angular';

angular.module('exportbuilder.dashboard.services')
    .factory('ExportBuilderElementIconModalUIService', ExportBuilderElementIconModalUIService);

/**
 * @ngInject
 */
function ExportBuilderElementIconModalUIService(
    PubSub,
    $ExportBuilderIconModalEvents
) {
    var uiState = {
        isActive: false
    };

    return {
        getState: getState,
        setIsActive: setIsActive,
        getIsActive: getIsActive,
        initNewIcon: initNewIcon,
        initEditIcon: initEditIcon
    };

    function getState() {
        return uiState
    }

    function setIsActive(value) {
        uiState.isActive = value;
    }

    function getIsActive() {
        return uiState.isActive
    }

    function initNewIcon() {
        setIsActive(true);
    }

    function initEditIcon() {
        setIsActive(true);
    }
}