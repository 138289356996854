'use strict';
import angular from 'angular';

angular.module('core.leads.constants', [])

    .constant('LeadColumns', {
        ID: 'id',
        LEAD_ID: 'lead_id',
        SERVICE_LEAD_ID: 'service_lead_id',
        SERVICE_ID: 'service_id',
        SERVICE_NAME: 'service_name',
        CAMPAIGN_ID: 'campaign_id',
        CAMPAIGN_NAME: 'campaign_name',
        FIRST_NAME: 'first_name',
        LAST_NAME: 'last_name',
        CLIENT_ID: 'client_id',
        CLIENT_NAME: 'client_name',
        END_CUSTOMER_ID: 'end_customer_id',
        CUSTOMER_NAME: 'customer_name',
        CUSTOMER_NUMBER: 'customer_number',
        CUSTOMER_EMAIL: 'customer_email',
        CALL_STATUS: 'call_status',
        CALLER_NAME: 'caller_name',
        ANSWER_COUNT: 'answer_count',
        BUSY_COUNT: 'busy_count',
        CANCEL_COUNT: 'cancel_count',
        NOFORWARDS_COUNT: 'noforwards_count',
        VOICEMAIL_COUNT: 'voicemail_count',
        TIMESTAMP: 'timestamp',
        FORMATTED_TIMESTAMP: 'formatted_timestamp',
        STATUS: 'status',
        TYPE: 'type',
        QUALITY: 'quality',
        CALL_WAS_RECORDED: 'call_was_recorded',
        DURATION: 'duration',
        ADDRESS_1: 'address_1',
        NOTES: 'notes',
        NOTE_COUNT: 'note_count',
        CITY: 'city',
        STATE: 'state',
        ZIP: 'zip',
        HAS_HISTORY: 'has_history',
        TAGS: 'tags'
    });