import { GeoChartConfig } from '@/modules/core/charts/am5/geo/models/GeoChartConfig';
import WidgetContextFactory from '@/modules/ta/widget/models/context/WidgetContextFactory';
import { isNull } from 'lodash';
import MapWidgetRenderService from '@/modules/core/charts/am5/geo/services/MapWidgetRenderService';

export default class NewGeoChartWidgetRenderService extends MapWidgetRenderService {
  /**
   * @param config
   * @returns {GeoChartConfig}
   */
  initConfig(config) {
    return new GeoChartConfig(config);
  }

  /**
   * @returns {*}
   */
  getSeriesType() {
    return this._widget.metadata.draw_options.plot_type;
  }

  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
      colorPalette: this.getChartPalettePie() || [''],
      fillOpacityPrior: this._hasComparisonData ? 0.6 : 1,
      fillOpacityCurrent: 1,
      hasComparisonStatus: this._hasComparisonData,
      labelPct: this._widget.metadata.draw_options.label_percent,
      otherPct: this._widget.metadata.draw_options.other_percent,
      drawOptions: this._widget.metadata.draw_options,
    };
  }

  getChartPalettePie(columnIndex = null) {
    const widget = this.getWidget();
    if (!isNull(columnIndex)) {
      const chartPalette = WidgetContextFactory.getContext()?.getChartPalette(widget);
      return columnIndex ? chartPalette[columnIndex % chartPalette.length] : chartPalette;
    }
  }
}
