'use strict';
import angular from 'angular';
import './widget.builder.constants';
import './base/widget.builder.components';
import './base/widget.builder.services';
import './base/widget.builder.resources';
import './base/widget.builder.models';
import './tabs/widget.builder.data.models';
import './tabs/widget.builder.library.models';
import './tabs/widget.builder.styles.models';
import './tabs/widget.builder.tabs.components';

angular.module('widget.builder', [
    'widget.builder.constants',
    'widget.builder.models',
    'widget.builder.components',
    'widget.builder.services',
    'widget.builder.resources'
]);