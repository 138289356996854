export const getters = {
  /**
   * @param {NavState} state
   * @returns {AppNavItem[]}
   */
  getAppNavItems(state) {
    return state.appNavItems;
  },

  /**
   * @param {NavState} state
   * @returns {Object}
   */
  getAllSubItems(state) {
    return state.allSubItems;
  },

  /**
   * @param {NavState} state
   * @param subItemGroupKey
   * @returns {*}
   */
  getSubItems(state, subItemGroupKey) {
    return state.allSubItems[subItemGroupKey] || [];
  },

  /**
   * @param {NavState} state
   * @returns {string}
   */
  getActiveNavItemKey(state) {
    return state.currentAppNavItemKey;
  },

  /**
   * @param state
   * @returns {Array|(function(*): *)|*}
   */
  getFileButtonFiles(state) {
    return state.showFileButton;
  },
  /**
   * @param {NavState} state
   * @returns {*}
   */
  getIsShowingActivityOrCommentsOrFile(state) {
    return state.isShowingActivityOrCommentsOrFilePanel;
  },
};
