import { isNil, isUndefined, map, max, isFunction, isEqual, isObject, transform } from 'lodash';
import vars from '@/styles/exported_vars.module.scss';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

/**
 * Performant way to shallow copy a keyed object (no methods)
 *
 * https://hackernoon.com/3-javascript-performance-mistakes-you-should-stop-doing-ebf84b9de951
 *
 * ex: { 1 : "", 2: ""} or { 'key1': "", 'key2' }
 *
 * @param {*} object
 * @returns {{}}
 */
export const shallowCopyKeyedObject = (object) => {
  const newObject = {};
  /* eslint-disable no-restricted-syntax */
  /* eslint-disable guard-for-in */
  for (const key of Object.keys(object)) {
    newObject[key] = object[key];
  }
  return newObject;
};

export const removeUndefinedKeyValues = (object) => {
  /* eslint-disable no-restricted-syntax */
  /* eslint-disable guard-for-in */
  for (const key in object) {
    if (object[key] === undefined) {
      delete object[key];
    }
  }
  return object;
};

/**
 * This file is to manager all generic custom helper methods
 *
 * @param object
 * @returns {*}
 */
export const deepClone = (object) => {
  if (isNil(object)) {
    return object;
  }
  if (typeof object === 'string') {
    return object;
  }
  if (typeof object === 'number') {
    return object;
  }
  // eslint-disable-next-line no-proto
  if (Array.isArray(object)) {
    return map(object, (anObject) => deepClone(anObject));
  }
  const objectClone = JSON.parse(JSON.stringify(object));
  // eslint-disable-next-line no-proto
  return new object.__proto__.constructor(objectClone);
};

/**
 * This methods helps to build options to select day in month/quarter etc, mostly used in FormSelect
 * @param days
 * @param keyName
 * @param valueName
 * @returns {[]}
 */
export const buildDaySelectOptions = (days, keyName = 'key', valueName = 'value') => {
  const options = [];
  for (let day = 1; day <= days; day++) {
    const option = { [keyName]: `${day - 1}` };
    if (day === days) {
      option[valueName] = `last`;
    } else {
      option[valueName] = getDayWithSuffix(day);
    }
    options.push(option);
  }
  return options;
};

/**
 * @param day
 * @returns {string}
 */
export const getDayWithSuffix = (day) => {
  const dayLastDigit = day >= 11 && day <= 13 ? 0 : day % 10;
  switch (dayLastDigit) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

/**
 * This method helps to build options to select week day, mostly used in FormSelect
 * @param keyName
 * @param valueName
 * @returns {*[]}
 */
export const buildWeekDaySelectOption = (keyName = 'key', valueName = 'value') => [
  { [keyName]: '0', [valueName]: 'Sunday' },
  { [keyName]: '1', [valueName]: 'Monday' },
  { [keyName]: '2', [valueName]: 'Tuesday' },
  { [keyName]: '3', [valueName]: 'Wednesday' },
  { [keyName]: '4', [valueName]: 'Thursday' },
  { [keyName]: '5', [valueName]: 'Friday' },
  { [keyName]: '6', [valueName]: 'Saturday' },
];

/**
 *  This method return text symbol for metric columns formats
 * @param format
 * @returns {string}
 */
export const getSymbolForMetricColumnFormat = (format) => {
  switch (format) {
    case ColumnFormat.FORMAT_DATE:
    case ColumnFormat.FORMAT_DATETIME:
    case ColumnFormat.FORMAT_TIME:
      return '◷';
    case ColumnFormat.FORMAT_INTEGER:
      return '#';
    case ColumnFormat.FORMAT_CURRENCY:
      return '$';
    case ColumnFormat.FORMAT_ID:
    case ColumnFormat.FORMAT_STRING:
    case 'text':
      return 'T';
    case ColumnFormat.FORMAT_PHONE_NUMBER:
      return '☎';
    case ColumnFormat.FORMAT_PERCENT:
      return '%';
    case ColumnFormat.FORMAT_DECIMAL:
      return '.00';
    case ColumnFormat.FORMAT_LINK:
      return '☍';
    default:
      return '-';
  }
};

/**
 * Can be used with async/await semantics
 * Ex: await delay(500);
 *
 * @param time In milliseconds
 * @returns {Promise<unknown>}
 */
export function delay(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });
}

/**
 * Generate random number for given min and max range
 * @returns {number}
 * @param minimum
 * @param maximum
 */
export function rangeRandom(minimum, maximum) {
  const range = maximum - minimum;
  return ((Math.random() * (range + 1)) << 0) + minimum;
}

/**
 * @param size
 * @returns {string}
 */
export function rem(size) {
  const fontSize = vars.fontSize?.split('px')[0];
  return `${size / fontSize}rem`;
}

/**
 * input - { 'a.b.c': 'abc', 'a.b.d': 'abd', 'a.e': 'ae' }
 * result - { a: { b: { c: 'abc', d: 'abd' }, e: 'ae' } }
 *
 * @param object
 * @returns {{}}
 */
export function parseDottedKeysObject(object) {
  const result = {};
  let tempResult;
  let keys;
  let firstKey;
  Object.keys(object).forEach((objectKey) => {
    tempResult = result;
    keys = objectKey.split('.');
    const lastKey = keys.pop();
    while (keys.length) {
      firstKey = keys.shift();
      tempResult[firstKey] = tempResult[firstKey] || {};
      tempResult = tempResult[firstKey];
    }
    tempResult[lastKey] = object[objectKey];
  });

  return result;
}

/**
 * @param url
 * @returns {boolean} for url
 */
export const isValidURL = (url) => {
  const validWebsiteRegex =
    /^(?:http(s)?:\/\/)?[\w-]+\.[\w]+[\w.-]*(?:\.[\w.-]+)*[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
  return !!url.trim().match(validWebsiteRegex);
};

/**
 * @param str to check it is a url without path
 * @returns {boolean}
 */
export const isValidTopLevelDomain = (str) => {
  const validWebsiteRegex = /^(?:http(s)?:\/\/)?[\w-]+\.[\w]+[\w.-]*$/;
  return !!str.trim().match(validWebsiteRegex);
};

/**
 * Returns the next available index for the matching inputs, if there is no matching input returns 0
 * e.g. getNextIndex(['User, 'User 1','ABC','User 5', 'XYZ'], 'User')
 * will return 6
 * @param inputs {string[]}
 * @param defaultValue {string}
 */
export const getNextIndex = (inputs, defaultValue) => {
  const matchedInputs = inputs.filter((input) => input.includes(defaultValue));
  if (matchedInputs?.length) {
    return max(inputs.map((input) => Number(input.replace(defaultValue, '')))) + 1;
  }
  return 0;
};

/**
 * Returns oldVal if newVal is undefined and newVal otherwise
 *
 * @param newVal
 * @param oldVal
 * @param onValid
 * @return object
 */
export const validate = (newVal, oldVal, onValid) => {
  if (isUndefined(newVal)) {
    return oldVal;
  }
  if (isFunction(onValid)) {
    return onValid(newVal);
  }
  return newVal;
};

/**
 * Returns the difference between two objects
 * @param changedObject
 * @param baseObject
 * @returns object
 */
export const difference = (changedObject, baseObject) => {
  function changes(object, base) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(changedObject, baseObject);
};

/**
 * Returns true if ads are allowed, false if ad blocking software is detected
 * @returns {boolean}
 */
export const isAdFriendly = () => {
  const detect = document.createElement('div');
  document.body.appendChild(detect);
  const adClasses = [
    'ad',
    'ads',
    'adsbox',
    'doubleclick',
    'ad-placement',
    'ad-placeholder',
    'adbadge',
    'BannerAd',
  ];
  adClasses.forEach((item) => {
    detect.classList.add(item);
  });
  const returnValue = window.getComputedStyle(detect).getPropertyValue('display') !== 'none';
  detect.remove();
  return returnValue;
};
