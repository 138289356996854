import angular from 'angular';

angular.module('datagrid.tagpicker.resources', [])
    .factory('TagPickerResource', TagPickerResource);

/**
 * @ngInject
 */
function TagPickerResource(
    Restangular
) {
    var tags = Restangular.all('tags');
    var tagValues = Restangular.all('tagvalues');

    return {
        getData: getData,
        create: create,
        update: update,
        remove: remove
    };

    /**
     * HTTP request for list of Tags
     * @returns [TagModel]
     */
    function getData() {
        return tags.getList().then(function (json) {
            return json.plain()
        })
    }

    /**
     * Available tag has been selected
     * @param {TagValueModel} tag
     */
    function create(tag) {
        return tagValues.post(tag)
            .then(function (json) {
                return json.plain()
            })
            .catch(function (json) {
                return json;
            });
    }

    /**
     * Available tag has been selected
     * @param {TagValueModel} tag
     */
    function update(tag) {
        return tagValues.all(tag.id).post(tag).then(function (json) {
            return json.plain()
        });
    }

    /**
     * Deletes the tag value
     * @param {TagValueModel} tag
     */
    function remove(tag) {
        return tagValues.all(tag.id).remove().then(function (json) {
            return json.plain();
        });
    }
}