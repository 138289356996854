import { Container, p100, percent } from '@amcharts/amcharts5';
import { useChart } from '@/modules/core/charts/am5/base/composables/useChart';
import { useLegend } from '@/modules/core/charts/am5/base/composables/useLegend';
import { useSliceSeries } from '@/modules/core/charts/am5/base/composables/series/useSliceSeries';
import { useTooltip } from '@/modules/core/charts/am5/base/composables/useTooltip';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';
import { useSliceLegendEvents } from '@/modules/core/charts/am5/funnel/events/useSliceLegendEvents';
import { SlicedChart } from '@amcharts/amcharts5/percent';
import {
  Constant,
  LABEL_PLACEHOLDERS,
  WidgetEvents,
} from '@/modules/core/charts/am5/charts.constants';
import { useButton } from '@/modules/core/charts/am5/base/composables/useButton';
import { useTitle } from '@/modules/core/charts/am5/bar/composables/useTitle';
import { useNonAxesTitleContainer } from '@/modules/core/charts/am5/base/composables/useNonAxesTitleContainer';
import { WidgetConfig } from '@/modules/ta/widget/widget.constants';
import { invertColor } from '@/modules/core/charts/am5/charts.helper';

export function useFunnelChart(context) {
  const { root, chart, config } = context();

  const { configureChart } = useChart(context);
  const { createLegend, formatLegendItems, restrictNumberOfLegendItems } = useLegend(context);
  const { addEvents } = useSliceLegendEvents(context);
  const { createSliceSeries, handleClickEvents, insertSeriesData } = useSliceSeries(context);
  const { createTooltip } = useTooltip(context);
  const { generateToolTipText } = useLabels(context);
  const { applyDrillDownTitleAndButton } = useButton(context);
  const { addTitle } = useTitle(context);
  const { createContainerForNonAxesAndInsert } = useNonAxesTitleContainer(context);
  let legends;

  function initFunnelChart() {
    legends = [];

    const { isExporting } = config.value;

    chart.value = root.value.container.children.push(
      SlicedChart.new(root.value, {
        layout: root.value.verticalLayout,
        height: percent(100),
        width: percent(100),
      })
    );

    configureChart();
    addComparisonTitles();

    // generate the series via the slices.  data is imported into the series later
    const series = createSliceSeries();
    let comparisonSeries;
    if (config.value.hasComparison()) {
      comparisonSeries = createSliceSeries(true);
    }

    // actual series data is handled after label templates are set
    insertSeriesData(series);
    if (comparisonSeries) {
      insertSeriesData(comparisonSeries, true);
    }

    // push the legend using the series data and then format it
    if (config.value.legend.active) {
      let legendSettings = {};

      let pushLegend = true;
      if (comparisonSeries) {
        pushLegend = false;
        legendSettings = {
          ...legendSettings,
          width: p100,
        };
      }

      chart.value.series.each((chartSeries) => {
        legends.push(createLegend(chartSeries.dataItems, legendSettings, pushLegend));
      });

      // if we're working with a comparison series legends need to be side by side
      if (!pushLegend) {
        const legendContainer = Container.new(root.value, {
          layout: root.value.horizontalLayout,
          // fixed height otherwise amcharts shrinks the chart to fit larger legends
          width: p100,
        });

        legends.forEach((legend) => {
          const singleLegendContainer = Container.new(root.value, {
            layout: root.value.verticalLayout,
            width: p100,
          });
          const legendInstance = singleLegendContainer.children.push(legend);
          if (isExporting) {
            restrictNumberOfLegendItems(
              legendInstance,
              singleLegendContainer,
              (isWarningLabelVisible) => {
                singleLegendContainer.set(
                  Constant.HEIGHT,
                  legend.height() + (isWarningLabelVisible ? 30 : 10)
                );
              }
            );
          } else {
            legend.events.on(WidgetEvents.BOUNDSCHANGED, () => {
              singleLegendContainer.set(Constant.HEIGHT, legend.height() + 20);
            });
          }
          legendContainer.children.push(singleLegendContainer);
        });

        chart.value.children.push(legendContainer);
      }

      legends.forEach((legend, legendIndex) => {
        legend.valueLabels.template.set(Constant.FORCE_HIDDEN, false);

        legend.markers.template.setAll({
          opacity: legendIndex ? 0.6 : 1,
        });

        formatLegendItems(legend);

        // add rollover events
        addEvents(legend);
      });
    }

    const { id } = config.value.get(WidgetConfig.WIDGET);

    applyDrillDownTitleAndButton(id);

    if (!config.value.isInlineDrillDown) {
      handleClickEvents(id);
    }

    if (config.value.hasTooltip) {
      // set a generic template which we can then access through the adapter to properly format it
      createTooltip((chartSeries) => {
        chartSeries.slices.template.set(Constant.TOOLTIP_TEXT, LABEL_PLACEHOLDERS.VALUE);
      });

      // apply tooltip to the series
      applyTooltipAdapter(series);
      if (comparisonSeries) {
        applyTooltipAdapter(comparisonSeries);
      }
    }
  }

  function applyTooltipAdapter(series) {
    series.get(Constant.TOOLTIP).label.adapters.add(Constant.TEXT, (text, target) => {
      const { dataItem } = target;
      if (dataItem) {
        if (dataItem.dataContext.value) {
          target.parent.set(Constant.OPACITY, 1);
          target.set(Constant.DISABLED, false);

          // if the "other" slice is enabled it doesn't have a fill colour set
          // so we need to pull it from the slice
          let targetFill = target.get(Constant.FILL);
          if (!targetFill) {
            targetFill = dataItem.get(Constant.SLICE)?.get(Constant.FILL);
          }

          if (targetFill) {
            // using the alternative method works better than the auto text color for some reason
            const alternativeColor = invertColor(targetFill, true);
            target.set(Constant.FILL, alternativeColor);
          }
          return generateToolTipText(dataItem.dataContext);
        }

        // if the value is 0 then remove the tooltip
        target.parent.set(Constant.OPACITY, 0);
        target.set(Constant.DISABLED, true);
      }

      return text;
    });
  }

  /**
   * The titles appear at the top of the charts when comparison is enabled
   * When we have labels they don't look centered even though they're centered to the
   * width of the widget.  They need to be centered to the chart always
   * Once the chart has rendered fully we can grab width of the labels, half that
   * and then adjust the x pos based on that
   */
  function addComparisonTitles() {
    if (config.value.hasComparison()) {
      const currentTitle = addTitle(Constant.CURRENT_PERIOD, {
        width: p100,
        textAlign: Constant.CENTER,
      });
      const priorTitle = addTitle(Constant.PRIOR_PERIOD, {
        width: p100,
        textAlign: Constant.CENTER,
      });
      createContainerForNonAxesAndInsert([currentTitle, priorTitle]);
    }
  }

  return {
    initFunnelChart,
  };
}
