import { ChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/ChartWidgetMetadata';
import { PieChartDrawOption } from '@/modules/ta/widget/models/drawoptions/PieChartDrawOption';

export class PieChartWidgetMetadata extends ChartWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new PieChartDrawOption(model.draw_options);
  }
}
