/**
 * @class AxisRange
 */
export class AxisRange {
  /**
   * Start value for the axis
   * @var {String/Number}
   */
  value;

  /**
   * End value for the axis
   * @var {String/Number}
   */
  endValue;

  /**
   * Opacity for filled color
   * @var {Number}
   */
  fillOpacity;

  /**
   * Color to fill with
   * @var {Color}
   */
  fill;

  /**
   * Part ensures that the fill goes behind the grid
   * @var {Number}
   */
  zIndex;

  /**
   *
   * @var {String}
   */
  labelText;

  /**
   * if tooltip on axis range is disabled
   * @var {Boolean}
   */
  tooltipDisabled;

  /**
   * Content for the tooltip
   * @var {String}
   */
  tooltipContent;

  /**
   * Format of the column this refers to
   * @var {String}
   */
  format;

  /**
   * Big number that goes along with this range
   * @var {BigNumber}
   */
  bigNumber;

  constructor(model = {}) {
    this.value = model.value;
    this.endValue = model.endValue;
    this.fillOpacity = model.fillOpacity;
    this.fill = model.fill;
    this.zIndex = model.zIndex;
    this.labelText = model.labelText;
    this.tooltipDisabled = model.tooltipDisabled;
    this.tooltipContent = model.tooltipContent;
    this.format = model.format;
  }
}
