import Pusher from 'pusher-js';
import { getters } from '@/modules/core/app/helpers/store';
import { TimeoutError } from '@/modules/core/app/models/TimeoutError';
import { pusherSubscription } from '@/modules/core/app/constants/pusher.constants';

export class PusherService {
  /** @var Pusher */
  pusherClient;

  /** @var Channel[] */
  channels = [];

  _initRequest = null;

  /**
   * This initialises pusherClient with the configurations stored in user settings.
   * It also subscribes for the user and the instance channel to listen to an event.
   * @returns {Promise<void>}
   */
  init() {
    if (!this._initRequest) {
      this._initRequest = this._initInternal();
    }
    return this._initRequest;
  }

  async _initInternal() {
    const {
      pubSubDetails: {
        pubsub_api_key: pubsubApiKey,
        pubsub_cluster: pubsubCluster,
        pubsub_auth_endpoint: pubsubAuthEndpoint,
        pubsub_user_channel: pubsubUserChannel,
        pubsub_instance_channel: pubsubInstanceChannel,
      },
    } = getters.session.getUserSettings();

    this.pusherClient = new Pusher(pubsubApiKey, {
      cluster: pubsubCluster,
      authEndpoint: `${pubsubAuthEndpoint}`,
    });

    this.channels = await Promise.all([
      this._subscribeChannel(pubsubUserChannel),
      this._subscribeChannel(pubsubInstanceChannel),
    ]);
  }

  /**
   * This will listen for an event on all the available channels with the given callback and context
   * It can be used for the background event which user is not actively waiting to occur ( like keyword's update )
   * @param event
   * @param callback
   * @param context
   * @returns {Promise<void>}
   */
  async listen(event, callback, context) {
    // make sure initialization is done before we listen to events
    await this.init();

    this.channels.forEach((channel) => {
      channel.bind(event, callback, context);
    });
  }

  /**
   * This will remove the event callback from the event listeners
   * @param event
   * @param callback
   * @param context
   * @returns {Promise<void>}
   */
  async removeListener(event, callback, context) {
    this.channels.forEach((channel) => {
      channel.unbind(event, callback, context);
    });
  }

  /**
   * This will wait for an event for the specified timeout
   * It can be used for an event, user is actively waiting to occur ( like website creation )
   * @param event
   * @param timeout
   * @param eventValidator
   * @returns {Promise<*>}
   */
  async waitForEvent(event, timeout, eventValidator) {
    return new Promise((resolve, reject) => {
      const callback = (data) => {
        if (!eventValidator || eventValidator(data)) {
          cleanUp();
          resolve(data);
        }
      };

      const cleanUp = () => {
        if (timer) {
          clearTimeout(timer);
        }
        this.removeListener(event, callback);
      };

      this.listen(event, callback);

      const timer = setTimeout(() => {
        cleanUp();
        reject(new TimeoutError());
      }, timeout);
    });
  }

  _subscribeChannel(channelName) {
    return new Promise((resolve) => {
      const channel = this.pusherClient.subscribe(channelName);

      const callback = () => {
        if (timer) {
          clearTimeout(timer);
        }
        channel.unbind(pusherSubscription.EVENT, callback);
        resolve(channel);
      };

      channel.bind(pusherSubscription.EVENT, callback);

      const timer = setTimeout(callback, pusherSubscription.TIMEOUT);
    });
  }
}

export default new PusherService();
