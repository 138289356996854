'use strict';
import angular from 'angular';
import $ from 'jquery';
import swal from 'bootstrap-sweetalert';

angular.module('core.user.ctrls', [])
    .controller('UserListController', UserListController)
    .controller('UserController', UserController);

/**
 * UserListController
 * @param UserFactory
 * @constructor
 *
 * @ngInject
 */
function UserListController($scope, UserFactory) {
}

/**
 * UserController
 * @param DesignFactory
 * @param DashboardService
 * @param Restangular
 * @param UIFactory
 * @constructor
 *
 * @ngInject
 */
function UserController(
    $scope,
    $compile,
    $location,
    DesignFactory,
    DashboardService,
    Restangular,
    UIFactory,
    AppFactory,
    LoginFactory,
    $timeout,
    PubSub,
    $UserImageUploadEvents
) {
    $.core.admin.user.initialize();

    $scope.revokeOauthClient = revokeOauthClient;
    $scope.overviewCallback = overviewCallback;

    function revokeOauthClient(clientId, clientName) {
        var options = {};
        options.text = 'You are about to revoke the authorization of ' + clientName;
        options.callback = function() {
            var oauthClient = Restangular.one('oauth').one('authorizationusers').one('clients', clientId);
            oauthClient.remove().then(function() {
                swal.close();
                UIFactory.notify.showSuccess('Authorization successfully revoked');
                $.core.datatable.oTables['oauth-clients-table'].oTable.api().row('#oauth-clients-table-'+clientId).remove().draw();
            });
        };
        UIFactory.confirm(options);
    }

    function overviewCallback(json) {
        DashboardService.updateLandingPage();

        // Refresh menu with fresh page list
        DesignFactory.getNavPageList().then(function() {
            DesignFactory.$refreshMenu();
        });

        $.get($.fn.actionUrl('layout/dashboardlistmenu'))
            .done(function(html) {
                // Need to $compile the html, in order to add to angular scope
                // parse HTML into DOM element
                var template = angular.element(html);
                // compile the template
                var linkFn = $compile(template);
                // link the compiled template with the scope.
                var element = linkFn($scope);
                $('#dashboard-submenu').html(element);
            });

        if (json.status === "success") {
            var userId = json.data.id;
            
            if (AppFactory.getUser().userId === userId) {
              LoginFactory.initUser();
            }
            
            $.get($.fn.actionUrl('user/view_user/user_id/' + userId + '?tmpl=' + (new Date().getTime())))
                .done(function (html) {
                    if (html) {
                        // replace #content html, since the phtml has all the logic for displaying
                        var template = angular.element(html);
                        var linkFn = $compile(template);
                        var element = linkFn($scope);
                        $('#content').html(element);

                        // Re-inits events and bindings to form
                        $.core.admin.user.initialize();

                        PubSub.emit('nav:refreshImpersonationList');
                    }
                });
        }

    }

    PubSub.on($UserImageUploadEvents.IMAGE_CHANGE, function (user) {
        $timeout(function () {
            var localUrl = $location.url();
            var userId = localUrl.substr(localUrl.lastIndexOf('/') + 1);

            if (userId !== user.userId) {
                return;
            }

            var $image = $('.add-client-user-image');
            var $logo = $image.find('i.icomoon-user-logo');
            var $preview = $image.find('img.user-image-preview');

            $logo && $logo.remove();
            $preview && $preview.remove();

            if (user.userImageId) {
                $image.append('<img class="user-image-preview" src=' + user.userImageMetadata.secure_url + ' />');
            } else {
                $image.append('<i class="icon icomoon-user-logo"></i>');
            }
        }, 100);
    });
}