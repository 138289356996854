import { isEmpty, find } from 'lodash';
import { WidgetState } from '@/modules/ta/widget/widget.constants';
import { getters as storeGetters } from '@/modules/core/app/helpers/store';

export const getters = {
  getLayout(state, layoutId) {
    return state.layouts[layoutId];
  },
  getLayoutsFromIds(state, layoutIds = [storeGetters.dashboard.getCurrentLayoutId()]) {
    if (isEmpty(state.layouts)) {
      return [];
    }
    return layoutIds.map((layoutId) => state.layouts[layoutId]);
  },
  /**
   * @param state
   * @param layoutId
   * @returns {Array<Widget>}
   */
  getWidgetsInLayout(state, layoutId) {
    const widgetIds = Object.keys(state.layouts[layoutId].widgets);
    return storeGetters.widget.getWidgetsFromIds(widgetIds);
  },
  /**
   *
   * @param state
   * @param layoutId
   * @returns {boolean}
   */
  areWidgetsInLayoutLoading({}, layoutId) {
    return !!find(Object.values(storeGetters.dashboardLayout.getWidgetsInLayout(layoutId)), {
      state: WidgetState.STATE_LOADING,
    });
  },
  /**
   * @param state
   * @param layoutId
   * @returns {*}
   */
  getDrawOptions(state, layoutId = storeGetters.dashboard.getCurrentLayoutId()) {
    return state.layouts[layoutId]?.metadata?.draw_options;
  },
};
