<template>
  <section
    class="is-relative"
    :style="[
      fontColorStyles,
      containerStyles,
      calculatedSize,
      borderStyles,
      radiusStyles,
      radiantStyles,
    ]"
    data-cy="rectangle-container"
  >
    <div class="value" v-if="isLoadingValue">
      <p class="subtitle" data-cy="loader">
        <span>{{ $t('Loading...') }}</span>
      </p>
    </div>
    <div v-else>
      <div
        class="metric-label"
        :style="
          getFontSizeStyle(
            LabelFontMultiplier.MIN,
            LabelFontMultiplier.MAX,
            MaxFontSizes.METRIC_LABEL
          )
        "
        :class="showMetricName"
      >
        {{ label }}
      </div>
      <div class="value-container" data-cy="value-container">
        <div class="flex is-flex justify-center">
          <div class="value">
            <span
              :style="[
                fontStyles,
                getFontSizeStyle(DataFontMultiplierSquare.MIN, DataFontMultiplierSquare.MAX),
              ]"
              data-cy="value"
              :title="emptyDataMessage"
            >
              <b-tooltip
                :active="!!isFormattedEllipsisActive()"
                :label="isFormattedEllipsisActive()"
                type="is-dark"
                size="is-small"
              >
                <div class="overflow-value" ref="formattedTooltip">
                  {{ formattedValue }}
                </div>
              </b-tooltip>
            </span>
          </div>
        </div>
        <p
          class="m-t--10 text-align-center"
          :style="getFontSizeStyle(DataLabelMultiplierSquare.MIN, DataLabelMultiplierSquare.MAX)"
          data-cy="primary-label"
        >
          {{ primaryLabel }}
        </p>
      </div>
      <div
        class="flex is-flex comparison-container"
        :style="
          getFontSizeStyle(
            DataComaprisonMultiplierSquare.MIN,
            DataComaprisonMultiplierSquare.MAX,
            MaxFontSizes.COMPARISON_VALUE
          )
        "
      >
        <div class="comparison-card text-align-center">
          <div>
            <b-tooltip
              :active="!!isComparisonEllipsisActive()"
              :label="isComparisonEllipsisActive()"
              type="is-dark"
              size="is-small"
            >
              <div class="overflow-value" ref="comparisonTooltip">
                {{ formattedComparisonValue }}
              </div>
            </b-tooltip>
          </div>
          <div
            class="overflow-value"
            :style="
              getFontSizeStyle(
                DeltaIconFontMultiplier.MIN,
                DeltaIconFontMultiplier.MAX,
                MaxFontSizes.COMPARISON_LABEL
              )
            "
          >
            {{ secondaryLabel }}
          </div>
        </div>
        <div class="comparison-card text-align-center">
          <div>
            <b-tooltip
              :active="!!isDifferenceEllipsisActive()"
              :label="isDifferenceEllipsisActive()"
              type="is-dark"
              size="is-small"
            >
              <div class="overflow-value" ref="differenceTooltip">{{ formattedDifference }}</div>
            </b-tooltip>
          </div>
          <div
            :style="
              getFontSizeStyle(
                DeltaIconFontMultiplier.MIN,
                DeltaIconFontMultiplier.MAX,
                MaxFontSizes.COMPARISON_LABEL
              )
            "
          >
            {{ $t('Difference') }}
          </div>
        </div>
        <div class="comparison-card text-align-center">
          <div class="is-flex justify-center">
            {{ formattedDeltaValue }}%
            <svg
              v-if="isExportingReportStudio"
              class="delta-value"
              :class="[deltaIconClass, deltaValueClasses]"
              :style="
                getIconSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                )
              "
            >
              <use xlink:href="" :href="`#${deltaIconClass}`"></use>
            </svg>
            <b-icon
              v-else
              pack="fa"
              class="delta-value"
              :class="deltaValueClasses"
              :custom-class="iconClass"
              :style="
                getFontSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                )
              "
              data-cy="delta-icon"
            ></b-icon>
          </div>
          <div
            :style="
              getFontSizeStyle(
                DeltaIconFontMultiplier.MIN,
                DeltaIconFontMultiplier.MAX,
                MaxFontSizes.COMPARISON_LABEL
              )
            "
          >
            {{ $t('Comparison') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ScoreCardMixin } from '@/modules/core/scorecard/mixins/ScoreCardMixin';
import NumeralService from '@/modules/core/app/services/NumeralService';
import {
  ComparisonOptions,
  ComparisonWrapThresholds,
} from '@/modules/core/scorecard/scorecard.constants';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export default {
  name: 'ComparisonCard',
  mixins: [ScoreCardMixin],
  props: {
    comparisonOption: {
      type: String,
      default: ComparisonOptions.MONTH_OVER_MONTH,
    },
  },
  computed: {
    calculatedSize() {
      const width = `${this.wrapSize * 4}px`;
      const height = this.calculateWrapHeight;
      return { width, height };
    },
    calculateWrapHeight() {
      let height = this.wrapSize * 2;
      if (
        this.wrapSize < ComparisonWrapThresholds.WRAP_SIZE &&
        this.fontSize > ComparisonWrapThresholds.FONT_SIZE
      ) {
        height +=
          ComparisonWrapThresholds.HEIGHT_START +
          (this.fontSize - ComparisonWrapThresholds.FONT_SIZE) *
            ComparisonWrapThresholds.HEIGHT_MULTIPLIER;
      }
      return `${height}px`;
    },
    formattedDifference() {
      return NumeralService.formatValue(
        Math.abs(this.value - this.comparisonValue),
        this.format,
        this.precision,
        this.isCompactNumber,
        this.currencySymbol
      );
    },
    formattedDifferenceTooltip() {
      return NumeralService.formatValue(
        Math.abs(this.value - this.comparisonValue),
        this.format,
        this.precision,
        false
      );
    },
    primaryLabel() {
      let label = i18n.$t('Last Month');
      if (this.comparisonOption === ComparisonOptions.QUARTER_OVER_QUARTER) {
        label = i18n.$t('Last Quarter');
      }
      if (this.comparisonOption === ComparisonOptions.YEAR_OVER_YEAR) {
        label = i18n.$t('This Year');
      }
      return label;
    },
    secondaryLabel() {
      let label = i18n.$t('The month before');
      if (this.comparisonOption === ComparisonOptions.QUARTER_OVER_QUARTER) {
        label = i18n.$t('The quarter before');
      }
      if (this.comparisonOption === ComparisonOptions.YEAR_OVER_YEAR) {
        label = i18n.$t('Last year');
      }
      return label;
    },
  },
  methods: {
    isComparisonEllipsisActive() {
      if (
        ((this.isCompactNumber && this.format !== ColumnFormat.FORMAT_PERCENT) ||
          this.$refs.comparisonTooltip?.offsetWidth < this.$refs.comparisonTooltip?.scrollWidth) &&
        this.comparisonValue > 1000
      ) {
        return this.comparisonValueTooltip;
      }
      return '';
    },
    isDifferenceEllipsisActive() {
      if (
        ((this.isCompactNumber && this.format !== ColumnFormat.FORMAT_PERCENT) ||
          this.$refs.differenceTooltip?.offsetWidth < this.$refs.differenceTooltip?.scrollWidth) &&
        Math.abs(this.value - this.comparisonValue) > 1000
      ) {
        return this.formattedDifferenceTooltip;
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.value-container {
  top: 15%;
  position: absolute;
  left: 0;
  right: 0;
  height: 55%;
  @include justify-content(center);
  @include align-items(center);
  @include align-content(center);
}

.comparison-card {
  width: 33.3%;
  padding: rem(5px);
  &:not(:last-child) {
    border-right: rem(1px) solid $white-light;
  }
}

.comparison-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: rem(10px);
  height: 30%;
}

.is-relative {
  position: relative;
}

.justify-center {
  justify-content: center;
}

.m-t--10 {
  margin-top: rem(-10px);
}

.delta-value {
  &.positive-delta {
    color: $green;
  }

  &.negative-delta {
    color: $red;
  }
}

.metric-label {
  text-align: left;
  padding: rem(10px);
  margin-left: rem(10px);
  height: 15%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.b-tooltip {
  display: block;
}
.overflow-value {
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-align-center {
  text-align: center;
}
</style>
