export const getters = {
  /**
   *
   * @param state
   * @returns {boolean}
   */
  hasRedos(state) {
    return state.doStack.length > 0;
  },
  /**
   *
   * @param state
   * @returns {boolean}
   */
  hasUndos(state) {
    return state.undoStack.length > 0;
  },
  /**
   *
   * @param state
   * @returns {boolean}
   */
  getIsPerformingAsyncOperation(state) {
    return !!state.currentOperationPromise;
  },
  /**
   *
   * @param state
   * @returns {null}
   */
  getCurrentOperationPromise(state) {
    return state.currentOperationPromise;
  },
  /**
   *
   * @param state
   * @returns {*[]}
   */
  getUndoStack(state) {
    return state.undoStack;
  },
  /**
   *
   * @param state
   * @returns {*[]}
   */
  getDoStack(state) {
    return state.doStack;
  },
};
