import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { DashboardFilterOption } from '@/modules/core/dashboard/models/DashboardFilterOption';
import { DashboardFilters } from '@/modules/core/dashboard/models/DashboardFilters';

class DashboardFilterResource extends BaseResource {
  constructor() {
    super(RouteGroup.DASH_DASHBOARDFILTERS);
  }

  /**
   * @param model
   * @returns {Promise<*>}
   */
  async getFilterOptions() {
    return super.getData(DashboardFilterOption);
  }

  /**
   * @param model
   * @returns {Promise<*>}
   */
  async getFilters() {
    return super.getData(DashboardFilters, 'view');
  }

  /**
   * @param model
   * @returns {Promise<*>}
   */
  async saveFilters(model) {
    return super.postData(model, 'savefilters');
  }

  /**
   * @returns {Promise<*>}
   */
  async resetFilters() {
    return apiAxios.get(`${this.routeGroup}/reset`);
  }
}
export default new DashboardFilterResource();
