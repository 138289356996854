'use strict';
import angular from 'angular';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';

import productWidgetPanelHtml from './product.widget.html';
import {WidgetProductSliderPanelEvents} from "../../design.constants";

angular.module('product.widget.components', [])
    .component('productWidgetPanel', {
        templateUrl: productWidgetPanelHtml,
        controller: productWidgetPanelController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function productWidgetPanelController(
    $element,
    $scope,
    AppFactory,
    AppModelFactory,
    SlidePanelFactory,
    $MessengerEvents,
    MessengerModelFactory,
    ProductWidgetFactory,
    DesignFactory,
    PubSub,
    $SlidePanelEvents,
    ManageProductsService,
    gettextCatalog,
    UIFactory
) {
    let vm = this;
    vm.isDynamicLayoutsEnabled = AppFactory.getUser().isModuleAvailable('dynamic_layouts');
    vm.isSaveButtonDisabled = false;
    vm.saveButtonName = 'Save Changes';
    let productOptions = {};
    const currentUser = AppFactory.getUser();
    if (vm.isDynamicLayoutsEnabled) {
        productOptions = {
            multiple: true,
            label: gettextCatalog.getString('Product'),
            placeholder: gettextCatalog.getString('Select Products...'),
            width: '100%',
            values: [],
            selectedValues: [],
            loaded: false,
            formatResult: function (item) {
                return item.text;
            },
            formatSelection: function (item) {
                item = this.values.find(value => value.id === item.id);
                return item.text;
            },
            getDataCall: function() {
                return ManageProductsService.getAllProducts();
            },
            formatData: function(data) {
                this.values = _.map(data, datum => {
                    return {
                        id: datum.id,
                        text: datum.name + (datum.active ? '' : '  (inactive)'),
                        active: datum.active
                    }
                });
                this.loaded = true;
            }
        };
    }
    vm.productSelectOptions = {};

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getPanelIsShowing = getPanelIsShowing;
    vm.closePanel = closePanel;
    vm.getIsLoading = getIsLoading;
    vm.canUserEdit = canUserEdit;
    vm.getWidget = getWidget;
    vm.productOnChange = productOnChange;
    vm.saveWidgetProducts = saveWidgetProducts;

    function $onInit() {
        vm.panel = ProductWidgetFactory;
        vm.widget = angular.copy(ProductWidgetFactory.getWidgetData());
        vm.originalWidgetData = angular.copy(vm.widget);
        vm.productSelectOptions = AppModelFactory.getSelectOptions(productOptions);
        registerEvents();
    }

    function $onDestroy() {
        PubSub.off($SlidePanelEvents.CLOSE_ALL, _onSlidePanelClose);
        PubSub.off(WidgetProductSliderPanelEvents.OPENED, _filterInactiveProducts);
        vm.panel.closePanel();
        $element.remove();
    }

    registerEvents();

    function registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _onSlidePanelClose);
        PubSub.on(WidgetProductSliderPanelEvents.OPENED, _filterInactiveProducts);
    }

    /**
     * Function to filter inactive products from the dropdown
     * @param widget
     * @private
     */
    function _filterInactiveProducts(widget) {
        if (!currentUser.disableInactiveProductsFiltering) {
            const existingInactiveProductIds = [];
            if (!_.isEmpty(widget.metadata.widget_products)) {
                widget.metadata.widget_products.forEach((product) => {
                    existingInactiveProductIds.push(product.id);
                })
            }
            vm.productSelectOptions.values = vm.productSelectOptions.values.filter(
                (value) => value.active || existingInactiveProductIds.includes(value.id)
            )
        }
    }

    /**
     * Callback from close all event of SlidePanel
     * @param sourcePanel
     * @private
     */
    function _onSlidePanelClose(sourcePanel) {
        if (sourcePanel !== vm.panel.panelId) {
            vm.panel.closePanel();
        }
    }

    function getPanelIsShowing() {
        const isPanelShowing = ProductWidgetFactory.panel.isShowing;

        vm.widget = [];
        if (isPanelShowing && _.isEmpty(vm.widget)) {
            vm.widget = ProductWidgetFactory.getWidgetData();
            vm.originalWidgetData = angular.copy(vm.widget);
        }

        return isPanelShowing;
    }

    function closePanel() {
        vm.widget.metadata.widget_products = angular.copy(vm.originalWidgetData.metadata.widget_products);
        const panelCloseStatus = ProductWidgetFactory.closePanel(vm.widget);
        vm.widget = [];
        return panelCloseStatus;
    }

    function canUserEdit() {
        return AppFactory.getUser().canManageDashboards;
    }

    function getIsLoading() {
        return ProductWidgetFactory.getStateProperty('isLoading');
    }

    function getWidget() {
        return ProductWidgetFactory.getWidgetData();
    }

    function productOnChange($el) {
        const val = $el ? $el.select2('data') : null;

        if (!_.isNull(val)) {
            const ids = _.map(val, 'id');
            ProductWidgetFactory.setProductsToWidget(vm.widget, ids);
        }
    }

    async function saveWidgetProducts() {
      vm.saveButtonName = "Saving...";
      vm.isSaveButtonDisabled = true;
      try {
        await ProductWidgetFactory.saveWidgetProducts(vm.widget);
      } catch (e) {
        UIFactory.notify.showWarning(e.message);
      } finally {
        vm.saveButtonName = "Save Changes";
        vm.isSaveButtonDisabled = false;
        PubSub.emit(WidgetProductSliderPanelEvents.OPENED, vm.widget);
      }
    }
}