'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import editLayoutPanelHtmlUrl from './editlayoutpanel.html';
import manageLayoutsPanelHtmlUrl from './managelayoutspanel.html';

angular.module('design.layout.directives')

    .component('editLayoutPanel', {
        templateUrl: editLayoutPanelHtmlUrl,
        bindings: false,
        controllerAs: 'vm',
        controller: EditLayoutPanelController
    })
    .component('manageLayoutsPanel', {
        templateUrl: manageLayoutsPanelHtmlUrl,
        bindings: false,
        controllerAs: 'vm',
        controller: ManageLayoutsPanelController
    });

/**
 * @ngInject
 */
function EditLayoutPanelController(
    $LayoutEvents,
    $SlidePanelEvents,
    $scope,
    $element,
    DesignFactory,
    WidgetFactory,
    EditLayoutFactory,
    PubSub
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onDestroy = $onDestroy;


    function $onInit() {
        vm.shared = EditLayoutFactory.shared;
        registerEvents();
    }

    function $postLink() {
        var $getAllWidgetElements = DesignFactory.$getAllWidgetElements;
        vm.hoverIn = function(widget) {
            if (!vm.shared.state.isReordering) {
                WidgetFactory.$getElement(widget.id)
                    .find('div.widget-inner')
                    .addClass('glow');
            }
        };
        vm.hoverOut = function() {
            $getAllWidgetElements().removeClass('glow');
        };

        $element.appendTo(document.body);
    }

    function _unregisterEvents() {
        PubSub.off($LayoutEvents.CHANGED, _closePanel);
        PubSub.off($SlidePanelEvents.CLOSE_ALL, _closeAll);
    }

    function $onDestroy() {
        vm.shared.closePanel();
        $element.remove();
        _unregisterEvents();
    }

    function _closePanel() {
        vm.shared.closePanel();
    }

    function _closeAll(sourcePanel) {
        if (sourcePanel !== EditLayoutFactory.shared.state.panelId) {
            vm.shared.closePanel();
        }
    }

    function registerEvents() {
        PubSub.on($LayoutEvents.CHANGED, _closePanel);
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _closeAll);
    }
}

/**
 * @ngInject
 */
function ManageLayoutsPanelController(
    $SlidePanelEvents,
    $element,
    AppFactory,
    LayoutFactory,
    ManageLayoutFactory,
    DesignFactory,
    PubSub,
    DataSourceType,
    DataSourceFactory,
    AppModelFactory,
    ManageProductsService,
    gettextCatalog,
    WidgetFactory
) {
    var vm = this;
    vm.isDragging = false;
    vm.dragOptions = getDragOptions();
    vm.dropOptions = getDropOptions();

    vm.selectedModule = null;
    vm.showServiceSelect = showServiceSelect;
    vm.showCategorySelect = showCategorySelect;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onDestroy = $onDestroy;
    vm.isSourceLayout = isSourceLayout;
    vm.getLayoutPlaceholderClass = getLayoutPlaceholderClass;
    vm.getNumWidgets = getNumWidgets;
    vm.selectLayout = selectLayout;

    vm.styleForLayout = styleForLayout;
    vm.toogleDataSourceSelect = toogleDataSourceSelect;
    vm.toggleProductSelect = toggleProductSelect;
    vm.dataSourceTypeOnChange = dataSourceTypeOnChange;
    vm.dataSourceOnChange = dataSourceOnChange;
    vm.categoryOnChange = categoryOnChange;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getDataSourceColor = getDataSourceColor;
    vm.showDataSourceIcon = showDataSourceIcon;
    vm.getDataSourceName = getDataSourceName;
    vm.canShowAssignDataSource = canShowAssignDataSource;
    vm.hasDataCategoryAssigned = hasDataCategoryAssigned;
    vm.hasProductAssigned = hasProductAssigned;
    vm.productOnChange = productOnChange;
    vm.getWidgetPlainTitle = WidgetFactory.getWidgetPlainTitle;

    vm.dataCategoryFilterOptions = [DataSourceType.SERVICE_DATA, DataSourceType.CATEGORY_DATA];
    vm.isDynamicLayoutsEnabled = AppFactory.getUser().isModuleAvailable('dynamic_layouts');
    vm.canSelectProduct = vm.isDynamicLayoutsEnabled && AppFactory.getUser().isSuperAdmin();
    if (vm.canSelectProduct) {
        vm.dataCategoryFilterOptions.push(DataSourceType.PRODUCT);
        let options = {
            multiple: true,
            label: gettextCatalog.getString('Product'),
            placeholder: gettextCatalog.getString('Select Products...'),
            width: '100%',
            values: [],
            selectedValues: [],
            loaded: false,
            formatResult: function (item) {
                return item.text;
            },
            formatSelection: function (item) {
                return item.id_name || item.text;
            },
            getDataCall: function() {
                return ManageProductsService.getAllProducts();
            },
            formatData: function(data) {
                this.values = _.map(data, datum => {
                    return {
                        id: datum.id,
                        text: datum.name
                    }
                });
                this.loaded = true;
            }
        };
        vm.productSelectOptions = AppModelFactory.getSelectOptions(options);
    }

    vm.dataSourcesSelectOptions = {
        autoOpen: true,
        loaded: false,
        allowClear: true,
        closeOnSelect: true,
        showAll: true,
        onChange: vm.dataSourceTypeOnChange
    };

    registerEvents();

    function $onInit() {
        vm.shared = ManageLayoutFactory.shared;
        vm.getCurrentLayout = DesignFactory.getCurrentLayout;
    }

    function $postLink() {
        $element.appendTo(document.body);
    }

    function $onDestroy() {
        vm.shared.closePanel();
        $element.remove();
    }

    function registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE_ALL, function (sourcePanel) {
            if (sourcePanel !== ManageLayoutFactory.shared.state.panelId) {
                vm.shared.closePanel();
            }
        });
    }

    function styleForLayout(layout, $index) {
        return {'z-index': 500 - $index}
    }

    /**
     * Is the layout widget's will be moved from
     * @param layoutId
     * @returns {boolean}
     */
    function isSourceLayout(layoutId) {
        return vm.getCurrentLayout().id === layoutId;
    }

    /**
     * @param layoutId
     * @returns {string}
     */
    function getLayoutPlaceholderClass(layoutId) {
        return isSourceLayout(layoutId) ? 'active' : 'dropzone idle';
    }

    /**
     * @param layout
     * @returns {int}
     */
    function getNumWidgets(layout) {
        return _.size(layout.widgets);
    }

    /**
     * @param layout
     */
    function selectLayout($event, layout) {
        if (!isSourceLayout(layout.id)) {
            ManageLayoutFactory.shared.updateLayoutIndexes();
            $('manage-layouts-panel').find('div.tiny-layout').addClass('idle').removeClass('active');
            $($event.currentTarget).closest('div.tiny-layout').addClass('active');
            LayoutFactory.changeLayout(angular.copy(layout));
        }
    }

    function toogleDataSourceSelect(layout) {
        vm.shared.isShowingProductSelectForLayout = false;
        vm.shared.isShowingDataSourceSelectForLayout = layout.id;
        vm.currentModule = null;
    }

    function toggleProductSelect(layout) {
        vm.shared.isShowingDataSourceSelectForLayout = false;
        vm.shared.isShowingProductSelectForLayout = layout.id;
        vm.currentModule = null;
    }

    function showServiceSelect(layout) {
        return layout.metadata.data_source && layout.metadata.data_source.type === DataSourceType.SERVICE_DATA;
    }

    function showCategorySelect(layout) {
        return layout.metadata.data_source && layout.metadata.data_source.type === DataSourceType.CATEGORY_DATA;
    }

    function hasDataCategoryAssigned(layout) {
        return !_.isEmpty(layout.metadata.data_source)
            && !_.isArray(layout.metadata.data_source) && layout.metadata.data_source.type !== DataSourceType.PRODUCT;
    }

    function hasProductAssigned(layout) {
        return !_.isEmpty(layout.metadata.data_source)
            && _.isArray(layout.metadata.data_source) && layout.metadata.data_source[0].type === DataSourceType.PRODUCT;
    }

    function dataSourceTypeOnChange (data) {
        vm.currentModule = data ? data.id : null;
    }

    function dataSourceOnChange($el, event) {
        if ($el) {
            vm.shared.setDataSourceToLayout($el.id);
        } else {
            vm.shared.setDataSourceToLayout(null);
            vm.currentModule = null;
        }
    }

    function categoryOnChange($el, event) {
        if ($el) {
            vm.shared.setCategoryToLayout($el.id);
        } else {
            vm.shared.setCategoryToLayout(null);
            vm.currentModule = null;
        }
    }

    function productOnChange($el) {
        let val = $el ? $el.select2('data') : null;

        if (!_.isNull(val)) {
            let ids = _.map(val, 'id');
            vm.shared.setProductsToLayout(ids);
        }
    }

    function showDataSourceIcon(layout) {
        return layout.metadata.data_source;
    }

    function getDataSourceColor(metadata) {
        return DataSourceFactory.getDataSourceColor(metadata.data_source);
    }

    function getDataSourceIcon(metadata) {
        return DataSourceFactory.getDataSourceIcon(metadata.data_source);
    }

    function getDataSourceName(metadata) {
        return DataSourceFactory.getDataSourceName(metadata.data_source);
    }

    function canShowAssignDataSource() {
        return AppFactory.getUser().canManageDashboards;
    }

    /**
     * Get drag options for widgets that can be moved
     * @returns {boolean}
     */
    function getDragOptions() {
        return {
            zIndex: 10000,
            appendTo: 'manage-layouts-panel div.slide-panel-body',
            helper: 'clone',
            revert: 'invalid',
            drag: function(e, ui) {
                // var $scrollPane = $('manage-layouts-panel div.slide-panel-body');
                // var scrollPanePosition = $(e.currentTarget).scrollTop();
                // var y = (e.pageY - $scrollPane.offset().top) + $(e.currentTarget).scrollTop();
                // if (y > 100) {
                //     $scrollPane.scrollTop($scrollPane.prop('scrollHeight'));
                // }
                // console.log(y);
                if ($(this).hasClass('dropzone')) {
                }
            },
            start: function(e, ui) {
                if (!$(this).parent().hasClass('dropzone')) {
                    $(this).css({visibility: 'hidden'});
                    $(ui.helper).addClass('dragged');
                    $('#new-tiny-layout').show();
                }
                else {
                    return false;
                }
            },
            stop: function(e, ui) {
                if (!$(ui.helper).hasClass('landed')) {
                    $(this).css({visibility: 'visible'});
                    $(ui.helper).removeClass('dragged');
                }
                $('#new-tiny-layout').hide();
            }
        };
    }

    /**
     * Get drop options for the layout dropzones
     * @returns {boolean}
     */
    function getDropOptions() {
        var $directive = $('manage-layouts-panel');
        return {
            activate: function(e, ui) {
                $(this).addClass('active');
                $directive.find('div.dropzone').addClass('dropzone-active');
            },
            over: function(e, ui) {
                if (!ManageLayoutFactory.shared.state.isReordering && $(this).hasClass('dropzone')) {
                    $(this).addClass('dropzone-hover');
                }
            },
            out: function(e, ui) {
                $(this).removeClass('dropzone-hover');
            },
            drop: function(e, ui) {
                if (!ManageLayoutFactory.shared.state.isReordering && $(this).hasClass('dropzone')) {
                    $(this).removeClass('dropzone-hover');
                    ui.helper.addClass('landed');
                    ui.helper.hide();
                    ManageLayoutFactory.addWidgetToLayout(
                        $(this).data('layoutId'),
                        ui.draggable.data('widgetId')
                    );
                }
            },
            deactivate: function(e, ui) {
                $(this).removeClass('active');
                $directive.find('div.dropzone').removeClass('dropzone-active');
            }
        };
    }
}