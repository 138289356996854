import angular from 'angular';

angular.module('core.namingconvention.ctrls', [])
    .controller('NamingConventionController', NamingConventionController);

/**
 * @ngInject
 */
function NamingConventionController(
    UINamingConventionModalFactory,
    LayoutFactory,
    UIFactory
) {
    var vm = this;
    vm.showNamingConventionModal = false;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.addNamingConventionClicked = addNamingConventionClicked;
    vm.namingConventionAdded = namingConventionAdded;

    function $onInit() {
        UINamingConventionModalFactory.onIsActive(_namingConventionModalIsActive)
    }
    function $onDestroy() {
        UINamingConventionModalFactory.off(_namingConventionModalIsActive)
    }

    function addNamingConventionClicked() {
        UINamingConventionModalFactory.setIsActive(true);
    }

    /**
     * Naming Convention Parser component bindings callback
     */
    function namingConventionAdded() {
        vm.showNamingConventionModal = false;
        LayoutFactory.$rebuildAllWidgets();
        UIFactory.notify.showSuccess('Naming Convention successfully saved');
    }

    function _namingConventionModalIsActive(value) {
        vm.showNamingConventionModal = value;
        if (value && UINamingConventionModalFactory.getNamingConventionId()) {
            vm.selectedNamingConventionId = UINamingConventionModalFactory.getNamingConventionId();
        } else {
            vm.selectedNamingConventionId = null;
        }
    }

}