'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('widget.bignumber.services', [])

    .factory('BigNumberFactory', BigNumberFactory)
    .factory('BigNumberUIService', BigNumberUIService);

/**
 * @ngInject
 */
function BigNumberFactory(
    WidgetDataSourceFactory,
    ColumnFormat,
    WidgetValue
) {

    /**
     * Returns data for big numbers
     * @param widget
     * @returns {{}}
     */
    function getData(widget) {
        return WidgetDataSourceFactory.getData(widget, {aggregate: true}, null);
    }

    function getSparkLineData(widget) {
        return WidgetDataSourceFactory.getData(widget, {}, null, true);
    }

    /**
     * @param value
     * @param format
     * @param precision
     * @param reduceNumber
     * @param row
     * @param currency_discrepancy
     * @returns {*}
     */
    function getFormattedValue(value, format, precision, reduceNumber, row, currency_discrepancy) {
        // Defaults
        format = format || ColumnFormat.FORMAT_INTEGER;
        precision = precision || 0;
        reduceNumber = _.isUndefined(reduceNumber) ? false : reduceNumber;

        if (_.isNull(value)
            || _.isNaN(value)  // builtin isNaN() will return true for ANYTHING not a number, e.g. time formats
            || value === WidgetValue.NO_VALUE) {
            return '-';
        }

        if (format === ColumnFormat.FORMAT_TIME) {
            return value;
        }

        var decimals = format == ColumnFormat.FORMAT_PERCENT
        || format == ColumnFormat.FORMAT_CURRENCY
        || format == ColumnFormat.FORMAT_DECIMAL
            ? precision : 0;

        // Do not reduce percentage number
        if (reduceNumber && format !== ColumnFormat.FORMAT_PERCENT) {
            return parseFloat(value).reduceNumber(2, decimals, format, row, currency_discrepancy);
        }
        else {
            return $.fn.formatNumber(value, format, decimals, false, row, currency_discrepancy);
        }
    }

    return {
        getData: getData,
        getFormattedValue: getFormattedValue,
        getSparkLineData: getSparkLineData
    }
}

/**
 * @ngInject
 */
function BigNumberUIService(
    AppFactory,
    DesignFactory,
    DrawOption,
    CenterNumbers,
    WrapFormat,
    UIColor,
    BackgroundColor,
    DashboardContextService,
    ChartPlotType,
    ExportBuilderDashboardService,
    ReportStudioTemplateDataService
) {

    return {
        getBackgroundColor: getBackgroundColor,
        getDefaultDrawOptions: getDefaultDrawOptions
    };

    function getDefaultDrawOptions(widget) {
        const drawOptions = {};
        drawOptions[DrawOption.FONT_SIZE] = 32;
        drawOptions[DrawOption.REDUCE_NUMBER] = true;
        drawOptions[DrawOption.CENTER_NUMBERS] = CenterNumbers.CENTER;
        drawOptions[DrawOption.CIRCLE_NUMBER] = WrapFormat.CIRCLE;
        drawOptions[DrawOption.CIRCLE_FONT_SIZE] =  22;
        drawOptions[DrawOption.WRAPPED_FONT_SIZE] = 30;
        drawOptions[DrawOption.CIRCLE_SIZE] = 100;
        drawOptions[DrawOption.WRAP_METRIC_NAME] = false;
        drawOptions[DrawOption.WRAP_NUMBER] = false;
        drawOptions[DrawOption.FONT_PROPERTIES] = {
            bold: false,
            italic: false,
            underline: false,
            strikethrough: false,
            text_color: UIColor.textColorWithBackgroundColor(getBackgroundColor(widget.metadata))
        };
        drawOptions[DrawOption.SHOW_METRIC_NAME] = {
            value: true,
            overridden: false
        };
        drawOptions[DrawOption.BACKGROUND_COLOR_TYPE] = AppFactory.getUser().bigNumberWidgetsUseThemeColor
            ? BackgroundColor.THEME : BackgroundColor.DATASOURCE;
        drawOptions[DrawOption.BACKGROUND_COLOR] = getBackgroundColor(widget.metadata);
        drawOptions[DrawOption.BORDER_COLOR] = DashboardContextService.resolveChartPalette(null)[2];
        drawOptions[DrawOption.BORDER_WIDTH] = 0;
        drawOptions[DrawOption.CORNER_RADIUS] = 0;
        drawOptions[DrawOption.BACKGROUND_GRADIENT] = 'solid';
        drawOptions[DrawOption.SPACING] = 15;
        drawOptions[DrawOption.SPACING_TOGGLE] = false;
        drawOptions[DrawOption.GRADIENT_COLOR] = DashboardContextService.resolveChartPalette(null)[1];
        drawOptions[DrawOption.PLOT_TYPE] = ChartPlotType.DEFAULT;
        drawOptions[DrawOption.SPARKLINE_TYPE] = ChartPlotType.LINE;


        return drawOptions;
    }


    function getBackgroundColor(metadata) {
        if (AppFactory.getUser().bigNumberWidgetsUseThemeColor) {
            if (ReportStudioTemplateDataService.getIsActive()) {
                return ExportBuilderDashboardService.getBuilder().report.metadata.chart_palette[0];
            } else {
                return DesignFactory.getCurrentPage().metadata.chart_palette[0];
            }
        } else {
            return metadata.data_source.color;
        }
    }
}