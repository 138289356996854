'use strict';
import angular from 'angular';

angular.module('core.namingconvention.constants', [])

    .constant('NamingConventionColumns', {
        ID: 'id',
        NAME: 'name',
        RULE: 'rule'
    })
    .constant('NamingConventionRouteParams', {
        STATE_NAME: 'namingconventions',
        URL: '/namingconventions'
    });