import { isNil } from 'lodash';
import { WidgetDataService } from '@/modules/ta/widget/services/data/WidgetDataService';
import { WidgetState } from '@/modules/ta/widget/widget.constants';

export class MediaDataService extends WidgetDataService {
  /**
   * @override
   *
   * @param widget
   * @param config
   */
  /* eslint-disable no-unused-vars */
  prepareRequest(widget, config) {
    this.applySpecificQueryParams(widget, config);
  }

  /**
   * @override
   *
   * @param widget
   * @param config
   */
  applySpecificQueryParams(widget, config) {
    this.setWidgetRequestQueryParam(widget, config);
  }

  /**
   * @override
   *
   * @param widget
   * @returns {boolean}
   */
  isDataCached(widget) {
    return !isNil(widget.cached_data);
  }

  /**
   * @override
   *
   * @param widget
   * @returns {boolean}
   */
  useSampleData(widget) {
    return !isNil(widget.cached_data);
  }

  /* eslint-disable no-unused-vars */
  postProcessData(json, widget) {
    return json;
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @param data
   */
  /* eslint-disable no-unused-vars */
  evaluateLoadingState(widget, config, data) {
    if (isNil(data)) {
      return WidgetState.STATE_NO_DATA;
    }
    return WidgetState.STATE_DONE;
  }
}
