import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { RelativeDateRange } from '@/modules/core/daterange/models/RelativeDateRange';

export class DateRangeResource extends BaseResource {
  constructor() {
    super(RouteGroup.DATERANGE);
  }

  getRelativeDateRanges() {
    return super.getData(RelativeDateRange, 'relativeranges');
  }
}

export default new DateRangeResource();
