'use strict';
import angular from 'angular';
import './scripts/clientgroup.ctrls';
import './scripts/clientgroup.directives';
import './scripts/clientgroup.services';

angular.module('core.clientgroup', [
    'clientgroup.ctrls',
    'clientgroup.directives',
    'clientgroup.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('clientgroups', {
                parent:'layout',
                url: '/clientgroups',
                controller: 'ClientGroupListController',
                templateUrl: AppConfig.MAGE_URL + 'clientGroup/manage_client_groups',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('clientgroups')
                }
            })
            .state('clientgroups.add', {
                parent:'layout',
                url: '/clientgroups/add',
                data: { isNewEntity: true, primaryKey: 'client_group_id' },
                controller: 'ClientGroupController',
                templateUrl: AppConfig.MAGE_URL + 'clientGroup/add_client_group'
            })
            .state('clientgroups.detail', {
                parent:'layout',
                url: '/clientgroups/detail/:id',
                controller: 'ClientGroupController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'clientGroup/view_client_group/client_group_id/' + $stateParams.id;
                }
            })
    }]);