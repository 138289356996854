'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('datasource.services')

    .factory('DataSourceColumnFactory', DataSourceColumnFactory);

/**
 * @ngInject
 */
function DataSourceColumnFactory(
    AppFactory,
    ColumnFormat
) {
    var columnUtil = AppFactory.util.column;
    var dataTypes = AppFactory.getDataTypes();

    /**
     * Pre-process selectable columns for select2 input
     * @param columns
     * @returns {T[]}
     */
    function preProcessSelectColumns(columns, requiresGroupBy) {
        var output = [];
        var dataTypeKeys = AppFactory.arrayToMemoizedObj(dataTypes, 'key');

        _.each(columns, function (column) {
            formatSelectColumn(column);

            // If data source or widget type requires a group by then we can't offer text fields as a column
            // And ID fields are only considered when selecting a group by
            if (columnUtil.isID(column.format)) {
                return;
            } else if (columnUtil.isString(column.format)) {
                // The referenced name field of a groupable field should not be selectable.
                // It gets selected when selecting it as a group by field.
                // But if a groupy by is not required then we do want to be able to
                // select those fields
                if (column.is_groupby_name_field && requiresGroupBy) {
                    return;
                } else {
                    column.disabled = true;
                }
            }
            var outputObj = AppFactory.arrayToMemoizedObj(output, 'format');

            var group = outputObj[column.format];

            // If group not found, create a new entry for it
            if (_.isUndefined(group) && dataTypeKeys[column.format]) {
                var dataType = dataTypeKeys[column.format];
                group = {text: dataType.value, format: column.format, is_metric: column.is_metric, children: []};
                output.push(group);
            }
            if (group) {

                if (!column.is_metric) {
                    // Tag whole group as non metric group if one column is !is_metric
                    // This will be processed in setColumnStates
                    group.is_metric = column.is_metric;
                }

                group.children.push({
                    id: column.field,
                    text: column.label,
                    format: column.format,
                    is_metric: column.is_metric,
                    disabled: column.disabled || false
                });
            }
        });

        return _.orderBy(output, 'format');
    }

    /**
     * Pre-process groupable columns for select2 input
     * @param columns
     * @returns {T[]}
     */
    function preProcessGroupColumns(columns) {
        var output = [];
        var dataTypeKeys = AppFactory.arrayToMemoizedObj(dataTypes, 'key');

        // Massage data into groups
        _.each(columns, function (column) {
            formatGroupColumn(column);

            var outputObj = AppFactory.arrayToMemoizedObj(output, 'groupby_field_format');
            var group = outputObj[column.groupby_field_format];

            // If group not found, create a new entry for it
            if (_.isUndefined(group) && dataTypeKeys[column.groupby_field_format]) {
                var dataType = dataTypeKeys[column.groupby_field_format];
                group = {text: dataType.value, format: column.format, groupby_field_format: column.groupby_field_format, children: []};
                output.push(group);
            }
            if (group) {
                group.children.push({
                    id: column.field,
                    text: column.label,
                    format: column.format,
                    groupby_field_format: column.groupby_field_format,
                    disabled: column.disabled
                });
            }
        });

        return _.orderBy(output, 'format');
    }

    /**
     * @param column
     * Correctly set format for callback columns, and iframe columns
     */
    function formatSelectColumn(column) {
        if (columnUtil.isCallback(column.format)) {
            if (column.postprocess_type === 'iframe') {
                column.format = ColumnFormat.FORMAT_IMAGE;
            }
            else {
                column.format = ColumnFormat.FORMAT_STRING;
            }
        }
    }

    /**
     * @param column
     * Correctly set groupby field format for callback columns
     */
    function formatGroupColumn(column) {
        if (columnUtil.isCallback(column.groupby_field_format)) {
            column.format = ColumnFormat.FORMAT_ID;
            column.groupby_field_format = ColumnFormat.FORMAT_STRING;
        }
    }

    return {
        preProcessSelectColumns: preProcessSelectColumns,
        preProcessGroupColumns: preProcessGroupColumns,
        formatSelectColumn: formatSelectColumn,
        formatGroupColumn: formatGroupColumn
    }
}