"use strict";
import angular from "angular";
import Vue, { defineComponent } from "vue";
import "ngVue";
import "ngVue/build/plugins";
import { i18n } from "@/i18n";

import Buefy from "buefy";
import VueXHelper from "@/modules/core/app/helpers/VueXHelper";

import { createStore } from "@/store";

import "font-awesome/css/font-awesome.css";
import "grokModules/src/libs/tapcolors/tapcolors.css";
import "grokModules/src/assets/fonts/tapicons/scss/nucleo.scss";
import "grokModules/src/assets/fonts/serviceicons/css/style.css";
import "grokModules/src/assets/fonts/alphabet/css/style.css";
import "grokModules/src/styles/helpers.scss";
import "grokModules/node_modules/buefy/src/scss/components/_tooltip.scss";

import { StaticOptionPlugin } from "@/modules/core/app/plugins/StaticOptionPlugin";
import { LocalOptionPlugin } from "@/modules/core/app/plugins/LocalOptionPlugin";
import { VueI18nCustomPlugin } from "@/modules/core/app/plugins/VueI18nCustomPlugin";
import { commits } from "@/modules/core/app/helpers/store";
import widgetAm5Chart from "./widget.am5.html";
import $ from "jquery";

import BubbleChartWidget from "grokModules/src/modules/core/charts/am5/bubble/components/LegacyBubbleChartWidget";
import BarChartWidget from "grokModules/src/modules/core/charts/am5/bar/components/LegacyBarChartWidget";
import PieChartWidget from "grokModules/src/modules/core/charts/am5/pie/components/LegacyPieChartWidget";
import LineChartWidget from "grokModules/src/modules/core/charts/am5/line/components/LegacyLineChartWidget";
import FunnelChartWidget from "grokModules/src/modules/core/charts/am5/funnel/components/LegacyFunnelChartWidget";
import GeoChartWidget from "grokModules/src/modules/core/charts/am5/geo/components/LegacyGeoChartWidget";
import ComboChartWidget from "grokModules/src/modules/core/charts/am5/combo/components/LegacyComboChartWidget";
import GaugeChartWidget from "grokModules/src/modules/core/charts/am5/gauge/components/LegacyGaugeChartWidget";

const store = createStore();
Vue.use(Buefy, {
  defaultIconPack: "fa",
});
Vue.use(StaticOptionPlugin);
Vue.use(LocalOptionPlugin);
Vue.use(VueI18nCustomPlugin);

angular
  .module("widget.am5.directives", ["ngVue", "ngVue.plugins"])
  .config(($ngVueProvider) => {
    $ngVueProvider.setRootVueInstanceProps({
      store: store,
      i18n: i18n,
    });
  })
  .directive("am5Widget", Am5ChartWidgetDirective)
  .directive("vueComboChartWidget", vueComboChartWidgetFunction)
  .directive("vueBubbleChartWidget", vueBubbleChartWidgetFunction)
  .directive("vueBarChartWidget", vueBarChartWidgetFunction)
  .directive("vuePieChartWidget", vuePieChartWidgetFunction)
  .directive("vueLineChartWidget", vueLineChartWidgetFunction)
  .directive("vueGeoChartWidget", vueGeoChartWidgetFunction)
  .directive("vueFunnelChartWidget", vueFunnelChartWidgetFunction)
  .directive("vueGaugeChartWidget", vueGaugeChartWidgetFunction);

function vueBubbleChartWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(defineComponent(BubbleChartWidget));
}

function vueComboChartWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(defineComponent(ComboChartWidget));
}

function vueBarChartWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(defineComponent(BarChartWidget));
}

function vuePieChartWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(defineComponent(PieChartWidget));
}
function vueFunnelChartWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(defineComponent(FunnelChartWidget));
}
function vueGaugeChartWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(defineComponent(GaugeChartWidget));
}
function vueLineChartWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(defineComponent(LineChartWidget));
}
function vueGeoChartWidgetFunction(createVueComponent, AppFactory) {
  _setUserSettings(AppFactory);
  return createVueComponent(defineComponent(GeoChartWidget));
}

function _setUserSettings(AppFactory) {
  if (!AppFactory.getUser()?.user?.use_nui) {
    VueXHelper.setStore(store);
  }
  commits.session.setClientCurrency($.globals.utils.currencySymbol);
  commits.session.setUserSettings(AppFactory.getUser());
}

function Am5ChartWidgetDirective() {
  return {
    restrict: "E",
    templateUrl: widgetAm5Chart,
    scope: {
      state: "=",
      widget: "<",
      widgetTypeId: "<",
      chartId: "<",
      elementMetadata: "<",
    },
    controller: "Am5ChartWidgetController",
  };
}
