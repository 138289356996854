'use strict';
import angular from 'angular';
import './drawoptionpanel.constants';
import './base/drawoptionpanel.directives';
import './base/drawoptionpanel.services';

angular.module('layout.drawoptionpanel', [
    'drawoptionpanel.constants',
    'drawoptionpanel.directives',
    'drawoptionpanel.services'
]);