'use strict';
import angular from 'angular';
import './widget.filter.constants';
import './base/widget.filter.ctrls';
import './base/widget.filter.directives';
import './base/widget.filter.services';

import { $WidgetFilterEvents, $WidgetCreateEvents, $TimeoutValues } from './widget.filter.constants';

angular.module('widget.filter.constants', [])
    .constant('$WidgetFilterEvents', $WidgetFilterEvents)
    .constant('$WidgetCreateEvents', $WidgetCreateEvents)
    .constant('$TimeoutValues', $TimeoutValues);

angular.module('widget.filter', [
    'widget.filter.constants',
    'widget.filter.ctrls',
    'widget.filter.directives',
    'widget.filter.services'
]);