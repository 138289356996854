import { commits } from '@/modules/core/app/helpers/store';
import CustomTranslationFormService from '@/modules/core/customtranslation/services/CustomTranslationFormService';

export const actions = {
  /**
   * @param state
   * @param id
   * @returns {Promise<CustomTranslationForm>}
   */
  async getCustomTranslationForm({ state }, id) {
    const [data, columns] = await CustomTranslationFormService.getForm(id);
    commits.customtranslation.setCustomTranslationForm({ data, columns });
    return state.customTranslationForm;
  },
  async saveCustomTranslationForm({}, form) {
    const { id } = await CustomTranslationFormService.saveForm(form);
    const data = await CustomTranslationFormService.getItem(id);
    const { columns } = form;
    commits.customtranslation.setCustomTranslationForm({ data, columns });
  },
  async resetCustomTranslationForm() {
    return commits.customtranslation.resetCustomTranslationForm();
  },
};
