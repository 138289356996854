/* eslint import/no-cycle: [0, { maxDepth: 1 }] */
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { join, isEmpty, remove } from 'lodash';

export default class UuidService {
  /**
   * @type {array}
   */
  static queryIds = [];

  /**
   * @type {boolean}
   */
  static isLoading = false;

  static add(id) {
    UuidService.queryIds.push(id);
  }

  static remove(id) {
    if (UuidService.queryIds.includes(id)) {
      UuidService.queryIds = remove(UuidService.queryIds, id);
    }
  }

  static kill() {
    if (UuidService.isLoading || isEmpty(UuidService.queryIds)) {
      return;
    }
    UuidService.isLoading = true;
    apiAxios.post('core/kill', { query_ids: join(UuidService.queryIds, ',') }).then(() => {
      UuidService.queryIds = [];
      UuidService.isLoading = false;
    });
  }
}
