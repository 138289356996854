'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.endcustomer.resources', [])
    .factory('EndCustomerResource', EndCustomerResource)
    .factory('EndCustomerDataGridFactory', EndCustomerDataGridFactory);

function EndCustomerResource(
    Restangular,
    ColumnValueType
) {
    var endCustomers = Restangular.all('endcustomers');
    var values = endCustomers.one('values');

    /**
     * @param queryParams
     * @returns {*}
     */
    function getColumns(queryParams) {
        return endCustomers.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, queryParams) {
        queryParams = _.extend({fields: fields}, queryParams || {});
        return values.one(ColumnValueType.DISTINCT).get(queryParams);
    }

    /**
     * Get data for this resource
     *
     * @param queryParams
     * @returns {*|restangular.ICollectionPromise<any>}
     */
    function getData(queryParams) {
        return endCustomers.getList(_.extend({
            all: true
        }, queryParams));
    }

    /**
     * Returns leads history for an end customer
     *
     * @param endCustomerId
     * @returns {*|restangular.ICollectionPromise<any>}
     */
    function getLeadsGrid(endCustomerId) {
        return endCustomers.all(endCustomerId).all('leadsgrid').getList();
    }

    return {
        endCustomers: endCustomers,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData,
        getLeadsGrid: getLeadsGrid,
    }
}

/**
 * @ngInject
 */
function EndCustomerDataGridFactory(
    DataGridRender,
    EndCustomerFactory,
    EndCustomerColumns
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.numActions = 2;
        dtOptions.customRenders[EndCustomerColumns.ID] = function (data, type, full) {
            var html = '';
            html += DataGridRender.getEditDetailsButton(EndCustomerColumns.ID, full.can_be_edited);
            html += DataGridRender.getDeleteButton(
                full.can_be_deleted,
                'endcustomers/' + data,
                full['customer_name'],
                full.can_be_deleted ? 'Delete' : 'Cannot be deleted since end customer is associated to at least one lead.');
            return html;
        };

        dtOptions.postCreatedRowCallback = function($link, row, data) {
            if ($link.data('field') === EndCustomerColumns.ID) {
                $link.click(function () {
                    EndCustomerFactory.setEditDetails(data, row);
                });
            }
        };

        return dtOptions;
    }
}