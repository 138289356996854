import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BaseDataResource } from '@/modules/core/app/resources/BaseDataResource';

export class IoMasterDataResource extends BaseDataResource {
  constructor() {
    super(RouteGroup.IOMASTER);
  }
}

export default new IoMasterDataResource();
