'use strict';
import angular from 'angular';

angular.module('core.tag.values.constants', [])
    .constant('TagValuesColumns', {
        ID: 'id',
        TAG: 'tag',
        COLOR: 'color',
        VALUE: 'value',
        DATA_SOURCE: 'data_source',
        COLUMN: 'column'
    })
    .constant('TagValuesRouteParams', {
        STATE_NAME: 'tagvalues',
        URL: '/tags/values'
    });