import { isEmpty } from 'lodash';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { ChartType } from '@/modules/core/charts/chart.constants';

export const getters = {
  getWidgetConfig(state, id) {
    return state.widgets[id];
  },
  getWidgetTitle(state, id) {
    const widgetConfig = state.widgets[id];

    const stackLength = widgetConfig.drilldownConfigStack.length;
    const config = widgetConfig.drilldownConfigStack[stackLength - 1];
    const isSubSliceTitle = config.isSubSlice === false;

    const getGroupby = () => {
      let drilldownConf;
      let currentGroupedColumn;
      let previousGroupedColumn;

      if (config.isSubSlice === true || config.isSubSlice === false) {
        const currentGroupbyIndex = config.groupByIndex;

        drilldownConf = widgetConfig.drilldownConfigStack[stackLength - 1];
        currentGroupedColumn =
          widgetConfig.widget.metadata.data_columns.grouped[currentGroupbyIndex];
        previousGroupedColumn =
          widgetConfig.widget.metadata.data_columns.grouped[currentGroupbyIndex - 1];
      } else {
        drilldownConf = widgetConfig.drilldownConfigStack[widgetConfig.currentGroupByIndex - 1];
        currentGroupedColumn =
          widgetConfig.widget.metadata.data_columns.grouped[widgetConfig.currentGroupByIndex];
        previousGroupedColumn =
          widgetConfig.widget.metadata.data_columns.grouped[widgetConfig.currentGroupByIndex - 1];
      }

      return {
        currentGroupBy: currentGroupedColumn,
        previousGroupBy: previousGroupedColumn,
        drilldownConfig: drilldownConf,
      };
    };

    if (isSubSliceTitle) {
      return '';
    }
    const { currentGroupBy, previousGroupBy, drilldownConfig } = getGroupby();

    const { label } = currentGroupBy;

    // eslint-disable-next-line tap/no-raw-text-js
    const text = previousGroupBy.is_primary_date_field ? 'on' : 'for';
    const groupByFieldName = previousGroupBy.groupby_name_field ?? previousGroupBy.groupby_id_field;

    let title =
      groupByFieldName === Constant.LOG_DATE
        ? drilldownConfig.title
        : drilldownConfig.queryParams[groupByFieldName];

    if (config.isSubSlice === true || config.isSubSlice === false) {
      // eslint-disable-next-line tap/no-raw-text-js
      title = drilldownConfig.queryParams[groupByFieldName] ?? 'Unspecified';
    }

    return `${label}s ${text} ${title}`;
  },
  canDrillDown(state, id) {
    const widgetConfig = state.widgets[id];

    if (widgetConfig) {
      const groupbyLength = widgetConfig.widget.metadata.data_columns?.grouped.length ?? 1;
      return widgetConfig.widget.type === ChartType.PIE_TYPE
        ? widgetConfig.currentGroupByIndex < groupbyLength
        : widgetConfig.currentGroupByIndex < groupbyLength - 1;
    }
    return false;
  },
  getDashboardId(state) {
    return state.dashboardId;
  },
  getIsDemoMode(state) {
    return state.demoMode;
  },
  validateDrilldownConfig(state, { widget, dateRange }) {
    const { widget: oldWidget } = state.widgets[widget.id];
    const oldDateRange = state.dateRanges[widget.id];

    if (
      oldWidget.metadata.draw_options.show_sample_data !==
      widget.metadata.draw_options.show_sample_data
    ) {
      return false;
    }

    if (oldDateRange && dateRange) {
      const isStartDateChanged = oldDateRange.start_date !== dateRange.start_date;
      const isEndDateChanged = oldDateRange.end_date !== dateRange.end_date;
      const isComparisonStartDateChanged =
        oldDateRange.comparison_start_date !== dateRange.comparison_start_date;
      const isComparisonEndDateChanged =
        oldDateRange.comparison_end_date !== dateRange.comparison_end_date;

      if (
        isStartDateChanged ||
        isEndDateChanged ||
        isComparisonStartDateChanged ||
        isComparisonEndDateChanged
      ) {
        return false;
      }
    }
    if (!isEmpty(oldWidget)) {
      const oldGroupedMetrics = oldWidget.metadata.data_columns.grouped;
      const groupedMetrics = widget.metadata.data_columns.grouped;

      if (oldGroupedMetrics.length === groupedMetrics.length) {
        const metricNames = [];
        let sameMetrics = true;
        oldGroupedMetrics.forEach(({ groupby_id_field }) => {
          metricNames.push(groupby_id_field);
        });
        for (let i = 0; i < groupedMetrics.length; i++) {
          if (metricNames[i] !== groupedMetrics[i].groupby_id_field) {
            sameMetrics = false;
            break;
          }
        }
        if (!sameMetrics) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  },
};
