'use strict';
import angular from 'angular';
import $ from 'jquery';
import moment from 'moment';

angular.module('exportbuilder.components')
    .controller('ExportBuilderStandaloneController', ExportBuilderStandaloneController);

/**
 * Export Builder controller
 * @ngInject
 */
export function ExportBuilderStandaloneController(
    $scope,
    ExportBuilderDashboardService,
    DesignFactory,
    WidgetFactory,
    LoginFactory,
    ExportFactory,
    ExecutiveSummaryFactory,
    ReportStudioTemplateDataService,
    ExportBuilderDashboardModelFactory,
    DateRangeFactory,
    AppFactory,
    UIFactory,
    ReportElementTypes,
    UIColor,
    PHTMLVARS
) {
    ExportFactory.setVars(PHTMLVARS);
    ReportStudioTemplateDataService.setIsActive(true);
    $scope.pageBackgroundStyles = pageBackgroundStyles;

    //
    // init environment
    //
    // User
    LoginFactory.setDefaultUserVars(PHTMLVARS.user);

    // Theme
    UIFactory.setThemeBaseColors(PHTMLVARS.themeBaseColors);

    // Relative Date Ranges
    DateRangeFactory.setRelativeDateRanges(PHTMLVARS.relativeDateRanges);

    // Widget types
    WidgetFactory.setWidgetTypes(PHTMLVARS.widgetTypes);

    // Set brandMappings
    AppFactory.setBrandMappings(PHTMLVARS.brandMappings);

    // Services
    AppFactory.setServices(PHTMLVARS.services);

    // Set dates
    var dates = PHTMLVARS.dates;
    AppFactory.setDateRange(dates.start, dates.end);
    if (dates.comparison_start && dates.comparison_end) {
        AppFactory.setComparisonDateRange(moment.unix(dates.comparison_start).utc(), moment.unix(dates.comparison_end).utc(), true);
    }

    //register the builder for this view
    ExportBuilderDashboardService.setReadonly();
    ExportBuilderDashboardService.setLocalData(PHTMLVARS);

    $scope.builder = ExportBuilderDashboardService.getBuilder();

    var allWidgets = _.reduce(PHTMLVARS.report.pages, function (accum, page) {
        _.each(page.elements, function (element) {
            if (element.type === ReportElementTypes.WIDGET) {
                accum.push(element.widget);
            }
        });
        return accum;
    }, []);

    // We need to check if any charts (without backend errors) exist
    // If there's no chart, then we can use the ExportFactory to tell wkhtmltopdf that we are ready for render.
    if (!ExportFactory.chartExists(allWidgets)) {
        ExportFactory.setChartsReady();
    }
    ExportFactory.widgetReadyCounter.setNumWidgets(_.size(allWidgets));

    // Store all export variables
    DesignFactory.setIsExportingPage(true);

    //calculate first zoom status
    ExportBuilderDashboardService.calculateZoom();

    addBodyClasses();

    function addBodyClasses() {
        // Class to apply export specific css
        angular.element('body').addClass('exporting-builder');
        let format = ExportBuilderDashboardService.getBuilder().report.format;
        let orientation = ExportBuilderDashboardService.getBuilder().report.orientation;
        angular.element('body').addClass(`${orientation}-${format}`);

        if (PHTMLVARS.isThumbnail) {
            angular.element('body').addClass('is-thumbnail');
        }

        if (PHTMLVARS.styleForPrint) {
            angular.element('body').addClass('print');
        }

        if (PHTMLVARS.exportType) {
            angular.element('body').addClass(PHTMLVARS.exportType);
        }
        console.log(PHTMLVARS);

        if (PHTMLVARS.isHtml) {
            setupHtmlView();
        }
    }

    function pageBackgroundStyles(page) {
        var styles = {};
        if (page.metadata.design_options && page.metadata.design_options.background_color) {
            styles['background-color'] = UIColor.hexToRGBA(page.metadata.design_options.background_color, page.metadata.design_options.opacity);
        } else {
            styles['background-color'] = '#fff';
        }
        return styles;
    }

    /**
     * HTML ONLY configurations
     */
    function setupHtmlView() {
        angular.element('body').addClass('exporting-html');
    }
}