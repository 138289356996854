'use strict';
import angular from 'angular';

angular.module('overview.services', [])

    .service('OverviewService', [
        '$rootScope',
        '$http',  function($rs, $http) {

            return {
                myFunction: function() {

                }
            }
        }]);