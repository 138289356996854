import { ChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/ChartWidgetMetadata';
import { FunnelChartDrawOption } from '@/modules/ta/widget/models/drawoptions/FunnelChartDrawOption';

export class FunnelChartWidgetMetadata extends ChartWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new FunnelChartDrawOption(model.draw_options);
  }
}
