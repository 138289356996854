import { some, isArray } from 'lodash';
import { WidgetDataService } from '@/modules/ta/widget/services/data/WidgetDataService';
import { DrawOption } from '@/modules/ta/widget/widget.constants';
import { DataGridData } from '@/modules/ta/widget/models/DataGridData';
import { OrderBy } from '@/modules/core/app/constants/data.constants';
import { SortOrder } from '@/modules/core/app/constants/app.constants';

export class DataGridDataService extends WidgetDataService {
  /**
   * Set page offset.
   * @type {number}
   */
  pageOffset = 0;

  /**
   * Set q param.
   * @type {string}
   */
  searchTerm = '';

  /**
   * Set sort params
   * @type {SortConfig|null|Array}
   */
  sortConfig = null;

  /**
   * @override
   * @param widget
   * @param config
   */
  applySpecificQueryParams(widget, config) {
    config.params.aggregate = false;
    config.params.datatable = true;

    this.setPaginateParam(widget, config);
    this.setSearchParam(config);
    if (this.sortConfig) {
      this.setSort(config);
    } else {
      this.setSortQueryParam(widget, config);
    }
    this.setGroupByQueryParam(widget, config);
    this.setTotalQueryParam(widget, config);
  }

  /**
   * Set sort params.
   * @param config
   */
  setSort(config) {
    if (isArray(this.sortConfig)) {
      config.params.sort = this.sortConfig
        .map((sort) => (sort.order === SortOrder.DESC ? `-${sort.field}` : sort.field))
        .join(',');
    } else {
      config.params.sort = `${this.sortConfig.order === OrderBy.ORDER_DESC ? '-' : ''}${
        this.sortConfig.field
      }`;
    }
  }

  /**
   * Setter for sort param
   * @param sortConfig
   */
  setSortConfig(sortConfig) {
    this.sortConfig = sortConfig;
  }

  /**
   * Setter for page offset.
   * @param pageOffset
   */
  setPageOffset(pageOffset) {
    this.pageOffset = pageOffset;
  }

  /**
   * Setter for search term.
   * @param searchTerm
   */
  setSearchTerm(searchTerm) {
    this.searchTerm = searchTerm;
  }

  /**
   * Return the search term.
   * @returns {string}
   */
  getSearchTerm() {
    return this.searchTerm;
  }

  /**
   * Reset page offset.
   */
  resetPageOffset() {
    this.pageOffset = 0;
  }

  /**
   * Set search request param.
   * @param {WidgetDataConfig} config
   */
  setSearchParam(config) {
    if (this.searchTerm) {
      config.params.q = this.searchTerm;
    }
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setPaginateParam(widget, config) {
    if (widget.getDrawOptions().grid_paginate) {
      config.params.page = `${this.pageOffset},${widget.getDrawOptions().display_length}`;
    } else {
      config.params.limit = widget.getDrawOptions().display_length;
    }
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setTotalQueryParam(widget, config) {
    if (
      some(widget.getFilters(), { is_metric: true }) ||
      !widget.metadata.draw_options[DrawOption.SHOW_TOTAL_ROW]
    ) {
      return;
    }

    config.params.total = true;
  }

  /**
   * @param {GenericDataModel[]} response
   * @returns {DataGridData}
   */
  postProcessData(response) {
    return new DataGridData(response);
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @param data
   */
  evaluateLoadingState(widget, config, data) {
    return super.evaluateLoadingState(widget, config, data.aaData);
  }
}
