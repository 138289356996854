import { Constant, LABEL_PLACEHOLDERS } from '@/modules/core/charts/am5/charts.constants';
import { RadarCursor } from '@amcharts/amcharts5/radar';
import { color, Tooltip } from '@amcharts/amcharts5';
import { invertColor } from '@/modules/core/charts/am5/charts.helper';

export function useAxisLessTooltip(context) {
  const { root } = context();

  function addCursor(chartValue) {
    const cursor = chartValue.set(
      Constant.CURSOR,
      RadarCursor.new(root.value, {
        behavior: Constant.NONE,
        tooltipText: LABEL_PLACEHOLDERS.VALUE,
      })
    );

    cursor.lineY.set(Constant.VISIBLE, false);
    cursor.lineX.set(Constant.VISIBLE, false);
  }

  /**
   *
   * @param tooltipData {{color: *, tooltipText: string, isComparison: boolean, dy: Number}}
   * @returns {InstanceType<typeof Entity>}
   */
  function createTooltip(tooltipData) {
    const settings = {};
    if (tooltipData.dy) {
      settings.dy = tooltipData.dy;
    }

    const tooltip = Tooltip.new(root.value, settings);

    tooltip.get(Constant.BACKGROUND).setAll({
      fill: color(tooltipData.color),
      fillOpacity: tooltipData.isComparison ? 0.6 : 1,
      strokeWidth: 0,
    });

    tooltip.label.setAll({
      fill: invertColor(tooltipData.color, false),
      dataItem: tooltipData,
    });

    tooltip.label.adapters.add(Constant.TEXT, (text) =>
      formatTooltipText(tooltipData.tooltipText, text)
    );

    return tooltip;
  }

  function formatTooltipText(string, text) {
    return string.replace(LABEL_PLACEHOLDERS.TOOLTIP_VALUE, text);
  }

  return {
    addCursor,
    createTooltip,
  };
}
