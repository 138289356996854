export const mutations = {
  setBillingDetails(state, billingDetails) {
    state.billingDetails = billingDetails;
  },
  setBillingPeriodUnit(state, billingPeriodUnit) {
    state.billingPeriodUnit = billingPeriodUnit;
  },
  setCurrentProduct(state, product) {
    state.currentProduct = product;
  },
  setOverviewDetails(state, overviewDetails) {
    state.overviewDetails = overviewDetails;
  },
  setCurrentSelectedPlan(state, selectedPlan) {
    state.currentSelectedPlan = selectedPlan;
  },
  updateSelectedAddons(state, updatedSelectedAddons) {
    state.selectedAddons = updatedSelectedAddons;
  },
  resetSelectedAddons(state) {
    state.selectedAddons = {};
  },
  setSelectedAddonCode(state, addonCode) {
    state.selectedAddonCode = addonCode;
  },
};
