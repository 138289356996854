'use strict';
import angular from 'angular';
import $ from 'jquery';
import swal from 'bootstrap-sweetalert';

import annotationPanelHtmlUrl from './annotation.panel.html';
import annotationWidgetTogglerHtmlUrl from './annotation.widget.toggler.html';
import annotationThreadFiltersHtmlUrl from './annotation.thread.filters.html';
import annotationThreadDisplayHtmlUrl from './annotation.thread.display.html';

angular.module('annotation.components', [])
    .component('annotationPanel', {
        templateUrl: annotationPanelHtmlUrl,
        controllerAs: 'vm',
        bindings: {
        },
        controller: annotationPanelController
    })
    .component('annotationToggler', {
        controllerAs: 'vm',
        templateUrl: annotationWidgetTogglerHtmlUrl,
        bindings: {
            widget: '<',
            state: '<'
        },
        controller: annotationTogglerController
    })
    .component('annotationThreadFilters', {
        controllerAs: 'vm',
        templateUrl: annotationThreadFiltersHtmlUrl,
        bindings: {
            thread: '<'
        },
        controller: annotationThreadFiltersController
    })
    .component('annotationThreadDisplay', {
        controllerAs: 'vm',
        templateUrl: annotationThreadDisplayHtmlUrl,
        bindings: {
            thread: '<'
        },
        controller: annotationThreadDisplayController
    });

/**
 * @ngInject
 */
function annotationPanelController(
    $scope,
    $element,
    $timeout,
    PubSub,
    $SlidePanelEvents,
    $MessengerEvents,
    $AnnotationEvents,
    AppFactory,
    AnnotationFactory,
    AnnotationModelFactory,
    MessengerModelFactory,
    MessengerFactory,
    UIFactory,
    DataSourceType,
    SlidePanelFactory
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.hasAnnotations = hasAnnotations;
    vm.isActive = AnnotationFactory.getIsActive;

    function $onInit() {
        vm.currentUser = AppFactory.getUser();

        vm.messengerOptions = MessengerModelFactory.getOptions({
            id: 'annotation-panel',
            title: 'Annotation Panel'
        });

        PubSub.on($MessengerEvents.INIT_PANEL + vm.messengerOptions.id, _initPanel);
        $element.appendTo(document.body);
    }

    function $onDestroy() {
        $element.remove();
        PubSub.off($MessengerEvents.INIT_PANEL + vm.messengerOptions.id, _initPanel);
        _closePanel();
    }

    function hasAnnotations() {
        return MessengerFactory.hasThreads();
    }

    function _initPanel(model) {
        SlidePanelFactory.closeAll(vm.messengerOptions.id);
        // Badge should be similar to widget title
        vm.messengerOptions.title = (model.widget.title || 'Untitled Widget') + ' Annotations';
        _registerEvents();
        vm.clientSelectOptions = AnnotationFactory.clientSelectOptions;

        var selectedClient = AnnotationFactory.getClientSelected();
        vm.threadOptions = AnnotationFactory.getAnnotationThread({
            showDate: AnnotationFactory.getShowDate(),
            entity: selectedClient ? {
                type: selectedClient.key,
                id: selectedClient.currentValues.id,
                name: selectedClient.currentValues.text
            } : {},
        }, AnnotationFactory.getAnnotations().length);
    }

    /**
     * @param thread
     * @private
     */
    function _onAddAnnotation(thread) {
        var newThread = AnnotationFactory.getNewAnnotation(thread);
        return AnnotationFactory.addAnnotation(newThread).then(function() {
            thread.closeEdit();
            // Date options must be reinitialized in order to bind to the new thread options correctly,
            newThread.dateOptions = false;
            // reset all client select options
            vm.clientSelectOptions = AnnotationFactory.clientSelectOptions;
            PubSub.emit($MessengerEvents.SAVE_THREAD_CALLBACK, newThread);
        })
    }

    /**
     *
     * @param id
     * @private
     */
    function _onDeleteAnnotation(id) {
        return AnnotationFactory.deleteAnnotation(id).then(function() {
            PubSub.emit($MessengerEvents.DELETE_THREAD_CALLBACK);
        }, function() {
            swal.enableButtons();
        });
    }

    /**
     * Close and restore panel
     */
    function _closePanel() {
        AnnotationFactory.setIsActive(false);
        PubSub.emit($AnnotationEvents.CLOSE_PANEL);
        _unregisterEvents();
    }

    /**
     *
     * @param sourcePanel
     * @private
     */
    function _closeAll(sourcePanel) {
        if (sourcePanel !== vm.messengerOptions.id) {
            _closePanel();
        }
    }

    /**
     *
     * @private
     */
    function _registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _closeAll);
        PubSub.on($MessengerEvents.SAVE_THREAD, _onAddAnnotation);
        PubSub.on($MessengerEvents.DELETE_THREAD, _onDeleteAnnotation);
    }

    /**
     *
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($SlidePanelEvents.CLOSE_ALL, _closeAll);
        PubSub.off($MessengerEvents.SAVE_THREAD, _onAddAnnotation);
        PubSub.off($MessengerEvents.DELETE_THREAD, _onDeleteAnnotation);
    }
}

/**
 * Toggle annotations
 * @ngInject
 */
function annotationTogglerController(
    AnnotationFactory
) {
    var vm = this;
    vm.initAnnotations = function() {
        AnnotationFactory.$init(vm.state, vm.widget);
    };
}

/**
 * @ngInject
 */
function annotationThreadFiltersController(
    $element,
    $AnnotationEvents,
    PubSub,
    AnnotationFactory,
    $MessengerEvents
) {
    var vm = this;
    vm.advancedOptionsTooltip = 'Assign to who ' + (vm.thread.dateOptions ? 'and when ' : '') + 'annotations will be displayed for';

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.onAdvancedOptionsClicked = onAdvancedOptionsClicked;

    function $onInit() {
        _registerEvents();

    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function onAdvancedOptionsClicked() {
        vm.thread.filtersAreVisible = !vm.thread.filtersAreVisible;
        vm.thread.updateDateOptionsDrop(AnnotationFactory.getAnnotations().length);
    }

    function _onDeleteAnnotation() {
        _resetThread();
    }

    function _onAddAnnotation() {
        _resetThread();
    }

    function _resetThread() {
        vm.thread.filtersAreVisible = false;
        vm.thread.updateDateOptionsDrop(AnnotationFactory.getAnnotations().length);
    }

    function _registerEvents() {
        PubSub.on($AnnotationEvents.WIDGET_DATE_CLICK, _changeAnnotationDate);
        PubSub.on($MessengerEvents.DELETE_THREAD, _onDeleteAnnotation);
        PubSub.on($MessengerEvents.SAVE_THREAD, _onAddAnnotation);
    }

    function _unregisterEvents() {
        PubSub.off($AnnotationEvents.WIDGET_DATE_CLICK, _changeAnnotationDate);
        PubSub.off($MessengerEvents.DELETE_THREAD, _onDeleteAnnotation);
        PubSub.off($MessengerEvents.SAVE_THREAD, _onAddAnnotation);
    }

    function _changeAnnotationDate(dateOptions) {
        vm.thread.updateDate(dateOptions.start, dateOptions.end, $element);
    }
}

/**
 * @ngInject
 */
function annotationThreadDisplayController() {
    var vm = this;
    vm.entityLabelTooltip = 'Assigned to ' + vm.thread.getEntityLabel();
}