'use strict';

angular.module('layout.tag.services', [])
    .factory('LayoutTagFactory', LayoutTagFactory);

/**
 * @ngInject
 */
function LayoutTagFactory(
    LayoutTagsResource
) {
    var tags = [];

    return {
        getTags: getTags,
        setTags: setTags,
        saveTags: saveTags
    };

    /**
     * Return list of tags available to layouts
     * @returns {*}
     */
    function getTags() {
        return LayoutTagsResource.getData();
    }

    function setTags(newtags) {
        if (_.isArray(newtags)) {
            tags = newtags;
        }
        else {
            console.error('Error: Trying to set layout tags as a non array');
        }
    }

    /**
     * Save list of new tags
     * @param payload
     * @returns {Promise<Data>}
     */
    function saveTags(payload) {
        return LayoutTagsResource.save(payload).then(function () {
            return LayoutTagsResource.getData();
        }).then(function (data) {
            setTags(data);
        });
    }
}