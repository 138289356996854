'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import Vue from "vue";

import "ngVue";
import "ngVue/build/plugins";
import { i18n } from "@/i18n";

import Buefy from "buefy";
import VueXHelper from "@/modules/core/app/helpers/VueXHelper";

import { createStore } from "@/store";

import "font-awesome/css/font-awesome.css";
import "grokModules/src/libs/tapcolors/tapcolors.css";
import "grokModules/src/assets/fonts/tapicons/scss/nucleo.scss";
import "grokModules/src/assets/fonts/serviceicons/css/style.css";
import "grokModules/src/assets/fonts/alphabet/css/style.css";
import "grokModules/src/styles/helpers.scss";
import "grokModules/node_modules/buefy/src/scss/components/_tooltip.scss";

import { StaticOptionPlugin } from "@/modules/core/app/plugins/StaticOptionPlugin";
import { LocalOptionPlugin } from "@/modules/core/app/plugins/LocalOptionPlugin";
import { VueI18nCustomPlugin } from "@/modules/core/app/plugins/VueI18nCustomPlugin";
import { commits } from "@/modules/core/app/helpers/store";

const store = createStore();
Vue.use(Buefy, {
    defaultIconPack: "fa"
});
Vue.use(StaticOptionPlugin);
Vue.use(LocalOptionPlugin);
Vue.use(VueI18nCustomPlugin);


import widgetDataGridHtmlUrl from './widget.datagrid.html';
import classicWidgetDataGridHtmlUrl from "./classic.datagrid.html";
import defaultWidgetDataGridHtmlUrl from "./default.datagrid.html";
import groupedColumnWidgetDataGridHtmlUrl from "./groupedcolumn.datagrid.html";
import embedSparkline from "grokModules/src/modules/core/datatable/components/EmbedSparkline";

import {ChartPlotType} from "../../../design.widget.constants";

angular.module('widget.datagrid.directives', ["ngVue", "ngVue.plugins"])
    .config($ngVueProvider => {
        $ngVueProvider.setRootVueInstanceProps({
            store: store,
            i18n: i18n
        });
    })
    .directive('datagridWidget', datagridWidget)
    .directive('classicDatagridWidget', classicDatagridWidget)
    .directive('defaultDatagridWidget', defaultDatagridWidget)
    .directive('groupedColumnDatagridWidget', groupedColumnDatagridWidget)
    .directive('datatable', datatable)
    .directive("embedSparkline", embedSparkLineFunction);


function embedSparkLineFunction(createVueComponent, AppFactory) {
    _setUserSettings(AppFactory);
    return createVueComponent(Vue.component("embedSparkline", embedSparkline));
}

function _setUserSettings(AppFactory) {
    if(!AppFactory.getUser()?.user?.use_nui) {
        VueXHelper.setStore(store);
    }
    commits.session.setClientCurrency($.globals.utils.currencySymbol);
    commits.session.setUserSettings(AppFactory.getUser());
}

/**
 * Display of datagrid widget
 * @ngInject
 */
function datagridWidget() {
    return {
        restrict: 'E',
        templateUrl: widgetDataGridHtmlUrl,
        controller: 'DataGridWidgetWrapperController',
        scope: {
            widget: '<',
            state: '='
        }
    }
}


/**
 * Display of classic datagrid widget
 * @ngInject
 */
function classicDatagridWidget() {
    return {
        restrict: 'E',
        templateUrl: classicWidgetDataGridHtmlUrl,
        controller: 'DataGridWidgetController',
        scope: {
            widget: '<',
            state: '='
        }
    }
}

/**
 * Display of default datagrid widget
 * @ngInject
 */
function defaultDatagridWidget() {
    return {
        restrict: 'E',
        templateUrl: defaultWidgetDataGridHtmlUrl,
        controller: 'DataGridWidgetController',
        scope: {
            widget: '<',
            state: '='
        }
    }
}

/**
 * Display of grouped column datagrid widget
 * @ngInject
 */
function groupedColumnDatagridWidget() {
    return {
        restrict: 'E',
        templateUrl: groupedColumnWidgetDataGridHtmlUrl,
        controller: 'DataGridWidgetController',
        scope: {
            widget: '<',
            state: '='
        }
    }
}

/**
 * Display of datagrid widget
 * @ngInject
 */
function datatable(
    $q,
    $timeout,
    ColumnFormat,
    DrawOption,
    AppFactory,
    LoadingState,
    DesignFactory,
    DataGridFactory,
    DataSourceType,
    DashboardContextService,
    ReportStudioTemplateDataService,
    ExportBuilderDashboardService,
    ChartPlotType,
    UIColor
) {
    return {
        restrict: 'A',
        scope: {
            dtOptions: '<',
            state: '=',
            widget: '='
        },
        link: function(scope, el) {
            // Since datatable is re-displayed everytime using ng-if displayTable hack
            // , we want to show it's fetching state everytime
            scope.state.loadingState = LoadingState.FETCHING;

            var dataLoadedCallback = scope.$parent.$parent.dataLoadedCallback;
            var metadata = scope.widget.metadata;

            var options = scope.dtOptions;
            hideDataTable(el);

            if (options.serverSide) {
                // Datatable can be created before we have data, if we are linking the datatable to the backend
                scope.state.loadingState = LoadingState.FETCHING;
                enableFixedHeader(scope.widget, options);

                initTable(function(table) {
                    //Set a filter delay for server side data when filtering
                    if (options.bServerSide) {
                        $(el).dataTable().fnSetFilteringDelay(700);
                    }

                    // Callback for when data has been before xhr is fired
                    table.on('preXhr.dt', function () {
                        scope.state.loadingState = LoadingState.FETCHING;
                    });

                    // Callback for when data has been returned
                    table.on('xhr.dt', function (e, settings, json) {
                        if (json.iTotalRecords > 0) {
                            showDataTable(el);
                        }
                        // Calls DataGridWidgetController.dataLoadedCallback()

                        dataLoadedCallback(json);
                        if (options[DrawOption.PIVOT_GRID]) {
                            pivotGrid(options, json, scope.widget);
                            pivotGridPostProcess(options);
                        }
                    });
                });
            }
            else {
                // Data must be present to create table, if we are not linking datatable to the backend
                $q.resolve(options.data).then(function(data) {
                    if (options[DrawOption.PIVOT_GRID] && [ChartPlotType.DEFAULT, ChartPlotType.CLASSIC].includes(options[DrawOption.PLOT_TYPE])) {
                        options.processData(data, function (tableData) {
                            if (tableData.aaData && tableData.aaData.length > 0) {
                                showDataTable(el);
                            }
                            dataLoadedCallback(tableData);
                            enableFixedHeader(scope.widget, options, tableData.aaData);
                            pivotGrid(options, tableData, scope.widget);
                            pivotGridPostProcess(options);
                        });
                    }
                    else {
                        if (options[DrawOption.PIVOT_GRID]) {
                            scope.state.helpNeeded = false;
                            options[DrawOption.PIVOT_GRID] = false;
                            options[DrawOption.GRID_PAGINATE] = false;
                            metadata.draw_options[DrawOption.PIVOT_GRID] = false;
                            metadata.draw_options[DrawOption.GRID_PAGINATE] = false;
                        }
                        options.processData(data, function (tableData) {
                            if (tableData.aaData && tableData.aaData.length > 0) {
                                if (metadata.needs_pagination && tableData.aaData.length === metadata.draw_options.rows_per_page) {
                                    DataGridFactory.showOverflowTruncateMessage();
                                }
                                showDataTable(el);
                            } else {
                                if (metadata.needs_pagination) {
                                    DataGridFactory.removeEmptyPages();
                                }
                            }
                            dataLoadedCallback(tableData);
                            enableFixedHeader(scope.widget, options, tableData.aaData);
                            options.data = tableData.aaData;
                            initTable();
                            $timeout(function() {
                                if (scope.state.loadingState === LoadingState.NO_DATA) {
                                    destroyTable();
                                }
                            }, 0, false);
                        });
                    }
                });
            }

            /**
             * Helper function to initialize table
             * @param callBack
             * @returns {jQuery}
             */
            function initTable(callBack) {
                var $el = $(el);
                $el.addClass('table table-striped');

                var $datagridWidget = $el.closest('.datagrid-display');

                if (options.responsive !== false) {
                    $el.addClass('responsive');
                    ReportStudioTemplateDataService.getIsActive() ? $datagridWidget.removeClass('show-overflow-reportstudio') : $datagridWidget.removeClass('show-overflow');
                } else {
                    ReportStudioTemplateDataService.getIsActive() ? $datagridWidget.addClass('show-overflow-reportstudio') : $datagridWidget.addClass('show-overflow');
                }

                // Provides inner scroll and fixed header for datagrid
                if (options.enableFixedHeader) {
                    $datagridWidget.addClass('has-fixed-header');
                    options.scrollY = $datagridWidget.height();
                    options.originalBodyHeight = options.scrollY;
                    options.scrollX = true;
                }

                if (!DataGridFactory.isClassicPlotType(options) && !_.isNull(options.grid_theme) && options.grid_theme === true) {
                    $el.addClass('show-gridlines');
                } else {
                    $el.removeClass('show-gridlines');
                }

                var table = $el.DataTable(options);

                // Set font size for all widget plottypes
                var cssObject = {
                    fontSize: DashboardContextService.resolveFontSizeValue()
                }

                if (options.plot_type === ChartPlotType.DEFAULT || options.plot_type === ChartPlotType.EMBEDDED_SPARKLINES) {

                    if (options.plot_type === ChartPlotType.DEFAULT) {
                        $el.addClass('datagrid-default-type');
                    } else {
                        $el.removeClass('datagrid-default-type');
                    }

                    cssObject.fontSize = options[DrawOption.GRID_FONT_SIZE];

                    if (options[DrawOption.GRID_FONT_PROPERTIES].bold) {
                        cssObject.fontWeight = '900';
                    }
                    if (options[DrawOption.GRID_FONT_PROPERTIES].italic) {
                        cssObject.fontStyle = 'italic';
                    }
                    if (options[DrawOption.GRID_FONT_PROPERTIES].underline) {
                        cssObject.textDecoration = 'underline';
                    }
                    if (options[DrawOption.GRID_FONT_PROPERTIES].strikethrough) {
                        cssObject.textDecoration += ' line-through';
                    }
                    if (options[DrawOption.GRID_FONT_PROPERTIES].text_color) {
                        cssObject.color = options[DrawOption.GRID_FONT_PROPERTIES].text_color;
                    }
                } else {
                    $el.removeClass('datagrid-default-type');
                }

                // Set font size
                $el.css(cssObject);

                if (callBack) {
                    callBack(table);
                }

                // Hide total row if loading state is no_data
                $timeout(function() {
                    if (scope.state.loadingState === LoadingState.NO_DATA) {
                        angular.element($datagridWidget.find('.total-row')).css('display', 'none');
                    }
                }, 0, false);

                return table;
            }

            /**
             *
             * @returns {string}
             */
            function getFontClassName() {
                let fontSizeValue;
                if (!_.isEmpty(DesignFactory.getCurrentPage())) {
                   fontSizeValue = DesignFactory.getFontSizeValue();
                } else {
                    fontSizeValue = ReportStudioTemplateDataService.getFontSizeValue();
                }
                return  ` fontsize-${fontSizeValue} `;
            }

            /**
             * Helper function to pivot grid after receiving the data
             * @param options
             * @param json
             * @param widget
             */
            function pivotGrid(options, json, widget) {
                // If data is empty, no need to pivot grid
                var data = json.aaData;
                options.totalDisplayRecordsNum = json.iTotalDisplayRecords;
                options.originalColumns = angular.copy(options.columns);
                if (_.isEmpty(data)) {
                    return;
                }

                var columns = options.columns;
                if (options.responsive !== false) {
                    columns.pop();
                }
                var columnsObj = AppFactory.arrayToMemoizedObj(columns, 'data');
                var pivotRow = findPivotRow(columnsObj, widget.metadata);
                if (_.isEmpty(pivotRow)) {
                    return;
                }
                var indexCounter = 1;
                var newColumns = [];
                var newData = [];

                var totalData = options.total_data;
                var showTotal = options[DrawOption.SHOW_TOTAL_ROW];
                var totalColumnIndex = -1;
                if (showTotal && !_.isEmpty(totalData)) {
                    var totalColumn = {
                        sTitle: 'Total',
                        sClass: 'text-right total-column ',
                        bSortable: true,
                        index: indexCounter
                    };
                    totalColumnIndex = indexCounter;
                    newColumns.push(totalColumn);
                    indexCounter++;
                    if (!DataGridFactory.isDataGridWithFixedHeaderSize()) {
                        totalColumn.sClass += getFontClassName();
                    } else {
                        totalColumn.sClass += ' datagrid_old ';
                    }
                }
                var restOfColumns = findRestOfColumns(columns, pivotRow);

                _.each(data, function(datum) {
                    var currentColumn = angular.copy(pivotRow);

                    if (pivotRow.format === ColumnFormat.FORMAT_DATETIME) {
                        if (!_.isEmpty(datum['formatted_' + currentColumn.data])) {
                            if(options.dateFormat.momentFormat === 'dddd' || options.dateFormat.momentFormat === 'MMMM' || options.dateFormat.momentFormat === 'hhA') {
                                currentColumn.title = datum['formatted_' + currentColumn.data];
                            } else if(options.dateFormat.momentFormat === 'YYYY' || options.dateFormat.momentFormat === 'YYYY-MM-DD HH:mm:ss'){
                                currentColumn.title = moment(datum['formatted_' + currentColumn.data]).utc()
                                    .format(options.dateFormat.momentFormat);
                            } else {
                                currentColumn.title = moment(datum['formatted_' + currentColumn.data])
                                    .format(options.dateFormat.momentFormat);
                            }
                        }
                        else {
                            // Use current date as column header
                            var dateValue = _.isObject(datum[currentColumn.data])
                                ? datum[currentColumn.data].value : datum[currentColumn.data];
                            currentColumn.title = formatDate(options, dateValue);
                        }
                    } else {
                        currentColumn.title = datum[currentColumn.data];
                    }
                    currentColumn.sTitle = currentColumn.title;

                    delete currentColumn.data;
                    delete currentColumn.mData;
                    delete currentColumn.render;

                    currentColumn.index = indexCounter;

                    // Since now grid is pivoted and each column will not have the same format,
                    // therefore set column type to be string for sorting column
                    currentColumn.sType = 'string';

                    if (!_.isEmpty(restOfColumns)) {
                        currentColumn.sClass = 'text-right image-preview image-preview--flip ';
                        if (!DataGridFactory.isDataGridWithFixedHeaderSize()) {
                            currentColumn.sClass += getFontClassName();
                        } else {
                            currentColumn.sClass += ' fontsize-12 ';
                        }
                    }
                    newColumns.push(currentColumn);
                    indexCounter++;
                });

                delete pivotRow.data;
                delete pivotRow.mData;
                delete pivotRow.title;
                delete pivotRow.sTitle;

                newColumns.unshift(pivotRow);

                _.each(restOfColumns, function(column) {
                    // var currentDatum = {};
                    var currentDatum = [];
                    for (var i = 0; i < indexCounter; i++) {
                        if (i === 0) {
                            currentDatum.push(column.title);
                            continue;
                        }
                        var formattedValue;

                        if (i === totalColumnIndex) {
                            // For LiveIntegrations, totalData also has current_data and prior_data keys. So it needs to be formatted.
                            if (totalData.prior_data) {
                                // Format totalData incase of comparison. (prior_data will be a key in LiveIntegrations only)
                                var formattedTotalData = {
                                    value: totalData.current_data[column.data],
                                    comparisonValue: totalData.prior_data[column.data]
                                }
                                totalData[column.data] = formattedTotalData;
                            }
                            else {
                                // Consider current_data as totalData if present. (current_data will be a key in LiveIntegrations only)
                                totalData = totalData.current_data ? totalData.current_data : totalData;
                            }
                            if (
                              column.format === ColumnFormat.FORMAT_INTEGER ||
                              column.format === ColumnFormat.FORMAT_DECIMAL ||
                              column.format === ColumnFormat.FORMAT_PERCENT ||
                              column.format === ColumnFormat.FORMAT_CURRENCY
                            ) {
                              formattedValue = getFormattedValue(
                                column,
                                totalData[column.data],
                                widget.metadata
                              );
                              currentDatum.push(formattedValue);
                            } else {
                              currentDatum.push(null);
                            }
                            continue;
                        }

                        var indexOffset = totalColumnIndex === 1 ? 2 : 1;
                        formattedValue = getFormattedValue(
                          column,
                          data[i - indexOffset][column.data],
                          widget.metadata
                        );

                        currentDatum.push(formattedValue);
                    }
                    if (options.responsive !== false) {
                        // Add empty value for responsive
                        currentDatum.push(null);
                    }
                    newData.push(currentDatum);
                });

                if (options.responsive !== false) {
                    // Add responsive row toggler column
                    newColumns.push({
                        title: 'Show More',
                        className: 'control',
                        orderable: false
                    });
                }

                options.columns = newColumns;
                options.aoColumns = newColumns;
                options.data = newData;
                options.aaData = newData;
                options.iDisplayLength = newData.length;
                options.display_length = newData.length;
            }

            /**
             * Helper function to handle post process of pivot grid
             * @param options
             */
            function pivotGridPostProcess(options) {
                destroyTable();

                // Set serverside to be false when pivot grid is enabled, because pivot grid only happens
                // when we have data
                options.serverSide = false;
                options.bServerSide = false;

                // Reset default order when pivot grid is enabled
                options.order = [0, 'asc'];
                reInitTable(true);
            }

            /**
             * Destroy old table if feasible
             */
            function destroyTable() {
                if ($.fn.dataTable.isDataTable(el)) {
                    $(el).DataTable().destroy();
                    $(el).empty();
                }
            }

            function showDataTable(el) {
                $(el).closest('.datagrid-widget-container').show();
                $(el).closest('div.dataTables_filter').show();
            }

            function hideDataTable(el) {
                $(el).closest('.datagrid-widget-container').hide();
                $(el).closest('div.dataTables_filter').hide();
            }
            /**
             * Helper function to re-initialize table
             * @param disableAjax
             */
            function reInitTable(disableAjax) {
                var table = initTable();
                if (disableAjax) {
                    table.off('xhr.dt');
                }
                if (table.draw) {
                    table.draw();
                }
            }

            /**
             * Helper function to find pivot row
             * @param columnsObj
             * @param metadata
             * @returns {*}
             */
            function findPivotRow(columnsObj, metadata) {
                if (_.isEmpty(metadata.data_columns) || _.isEmpty(metadata.data_columns.grouped)) {
                    if (metadata.data_source.type !== DataSourceType.LEADS) {
                        console.error("Cannot find grouped column to pivot.");
                    }
                    return null;
                }
                var pivotRow = _.first(metadata.data_columns.grouped);
                return columnsObj[pivotRow.groupby_name_field];
            }

            /**
             * Helper function to find columns that are not pivot row
             * @param columns
             * @param pivotRow
             * @returns {Array}
             */
            function findRestOfColumns(columns, pivotRow) {
                var restOfColumns = [];
                _.each(columns, function(column) {
                    if (column.data !== pivotRow.data) {
                        restOfColumns.push(column);
                    }
                });

                return restOfColumns;
            }


            /**
             * Helper function to format Date
             * @param options
             * @param value
             */
            function formatDate(options, value) {
                return _.isInteger(value)
                    ? moment.unix(value).utc().format(options.dateFormat.momentFormat)
                    : value;
            }

            /**
             * Helper function to enable fixed header
             * @param widget
             * @param options
             * @param data
             */
            function enableFixedHeader(widget, options, data) {
                if (DataGridFactory.setFixedHeader(widget, data)) {
                    options.enableFixedHeader = true;
                }
            }

            /**
             * Helper function to get formatted value
             * @param column
             * @param data
             * @returns {*}
             */
            function getFormattedValue(column, data, widgetMetadata) {
                var formattedValue;
                var callback;
                switch(column.format) {
                    case ColumnFormat.FORMAT_BOOLEAN:
                        callback = function(unformattedData) {
                            return unformattedData ? '<span class="icon icon-check"></span>' : '';
                        };
                        formattedValue  = DataGridFactory.formatComparison(
                            data, column, options, scope.widget, callback
                        );
                        break;
                    case ColumnFormat.FORMAT_INTEGER:
                    case ColumnFormat.FORMAT_DECIMAL:
                    case ColumnFormat.FORMAT_PERCENT:
                    case ColumnFormat.FORMAT_CURRENCY:
                        callback = (unformattedData, isPriorPeriod) => {
                            if(unformattedData === '-') {
                                return unformattedData;
                            } else {
                                if(DataGridFactory.shouldAddPercentMetric(scope.widget.metadata, column, scope.widget, options)) {
                                    const columnTotalValue = DataGridFactory.getColumnTotalValue(options.total_data, column.data, scope.widget.has_live_integration, isPriorPeriod);
                                    return (
                                      $.fn.formatNumber(
                                        unformattedData,
                                        column.format,
                                        column.precision,
                                        false,
                                        column,
                                        widgetMetadata.currency_discrepancy,
                                        widgetMetadata.show_currency
                                      ) +
                                      " - " +
                                      DataGridFactory.getPercentOfTotal(
                                        unformattedData,
                                        columnTotalValue
                                      )
                                    );
                                } else {
                                    return $.fn.formatNumber(
                                      unformattedData,
                                      column.format,
                                      column.precision,
                                      false,
                                      column,
                                      widgetMetadata.currency_discrepancy,
                                      widgetMetadata.show_currency
                                    );
                                }
                            }
                        };
                        formattedValue = DataGridFactory.formatComparison(
                            data, column,  options, scope.widget, callback
                        );
                        break;
                    case ColumnFormat.FORMAT_TIME:
                        callback = function(unformattedData) {
                            return unformattedData;
                        };
                        formattedValue = DataGridFactory.formatComparison(
                            data, column, options, scope.widget, callback
                        );
                        break;
                    case ColumnFormat.FORMAT_DATETIME:
                        callback = function(unformattedData) {
                            return moment.unix(unformattedData).utc().format(options.dateFormat.momentFormat);
                        };
                        formattedValue = DataGridFactory.formatComparison(
                            data, column, options, scope.widget, callback
                        );
                        break;
                    case ColumnFormat.FORMAT_OBJECT:
                        callback = function(unformattedData) {
                            return unformattedData.value;
                        };
                        formattedValue = DataGridFactory.formatComparison(
                            data, column, options, scope.widget, callback
                        );
                        break;
                    case ColumnFormat.FORMAT_STRING:
                            return data ? '<span title="'+data+'">'+data+'</span>' : '';
                        break;
                    default:
                        formattedValue = data;
                }

                return formattedValue;
            }
        }
    }
}
