import { isNil } from 'lodash';
import { BaseDrawOption } from '@/modules/ta/widget/models/drawoptions/BaseDrawOption';

export class BigNumberDrawOption extends BaseDrawOption {
  /**
   * @var {number}
   */
  font_size;

  /**
   * @var {number}
   */
  reduce_number;

  /**
   * @var {boolean}
   */
  center_numbers;

  /**
   * @var {boolean}
   */
  circle_number;

  /**
   * @var {number}
   */
  circle_size;

  /**
   * @var {boolean}
   */
  wrap_metric_name;

  /**
   * @var {boolean}
   */
  wrap_number;

  /**
   * @var {boolean}
   */
  border_width;

  /**
   * @var {string}
   */
  border_color;

  /**
   * @var {string}
   */
  font_properties;

  /**
   * @var {string}
   */
  background_color_type;

  /**
   * @var {string}
   */
  background_gradient;

  /**
   * @var {string}
   */
  gradient_color;

  /**
   * @var {string}
   */
  background_color;

  /**
   * @var {number}
   */
  corner_radius;

  /**
   * @var {string}
   */
  plot_type;

  /**
   * @var {string}
   */
  sparkline_type;

  constructor(model = {}) {
    super(model);

    this.font_size = model.font_size || 12;
    this.reduce_number = isNil(model.reduce_number) ? false : model.reduce_number;
    this.center_numbers = isNil(model.center_numbers) ? true : model.center_numbers;
    this.circle_number = isNil(model.circle_number) ? true : model.circle_number;
    this.circle_size = isNil(model.circle_size) ? 100 : model.circle_size;
    this.wrap_metric_name = isNil(model.wrap_metric_name) ? false : model.wrap_metric_name;
    this.wrap_number = isNil(model.wrap_number) ? false : model.wrap_number;
    this.border_width = isNil(model.border_width) ? 0 : model.border_width;
    this.border_color = isNil(model.border_color) ? null : model.border_color;
    this.font_properties = isNil(model.font_properties) ? {} : model.font_properties;
    this.background_color_type = isNil(model.background_color_type)
      ? null
      : model.background_color_type;
    this.background_gradient = isNil(model.background_gradient) ? null : model.background_gradient;
    this.gradient_color = isNil(model.gradient_color) ? null : model.gradient_color;
    this.background_color = isNil(model.background_color) ? null : model.background_color;
    this.corner_radius = isNil(model.corner_radius) ? 0 : model.corner_radius;
    this.plot_type = isNil(model.plot_type) ? null : model.plot_type;
    this.sparkline_type = isNil(model.sparkline_type) ? null : model.sparkline_type;
  }
}
