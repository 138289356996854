'use strict';
import angular from 'angular';
import $ from 'jquery';

import widgetChartHtmlUrl from '../widget.chart.html';

angular.module('widget.geochart.directives', [])

    .directive('geochartWidget', geochartWidget);

geochartWidget.$inject = [];
function geochartWidget() {
    return {
        restrict: 'E',
        templateUrl: widgetChartHtmlUrl,
        controller: 'GeoChartWidgetController',
        scope: {
            state: '=',
            widget: '=',
            chartId: '<',
            widgetTypeId: '<',
            widgetIsPredefined: '<'
        }
    }
}