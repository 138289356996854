'use strict';
import angular from 'angular';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';
import widgetCreatePlaceholderHtmlUrl from './widget.create.placeholder.html';
import widgetCreateActionPlaceholderHtmlUrl from './widget.create.action.placeholder.html';
import widgetCreateTitleHtmlUrl from './widget.create.title.html';
import widgetCreateMediaHtmlUrl from './widget.create.media.html';
import widgetCreateModalHtmlUrl from './widget.create.modal.html';

angular.module('widget.create.directives', [])

    .directive('createWidgetModal', createWidgetModal)
    .component('creationPlaceholder', {
        templateUrl: widgetCreatePlaceholderHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            instruction: '=',
            icon: '@',
            title: '@'
        }
    })
    .component('actionMessagePlaceholder', {
        templateUrl: widgetCreateActionPlaceholderHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            actionMessage: '=',
            actionSteps: '='
        }
    })
    .component('widgetTitle', {
        templateUrl: widgetCreateTitleHtmlUrl,
        controllerAs: 'vm',
        controller: WidgetCreateTitleController,
        bindings: {
            title: '=',
            model: '='
        }
    })
    .component('createMediaWidget', {
        templateUrl: widgetCreateMediaHtmlUrl,
        controllerAs: 'vm',
        controller: CreateMediaWidgetController,
        bindings: {
            state: '=',
            model: '='
        }
    });

/**
 * Add/edit widget modal
 * @type {string[]}
 * @ngInject
 */
function createWidgetModal(
    $timeout,
    SlidePanelFactory
) {
    return {
        restrict: 'E',
        templateUrl: widgetCreateModalHtmlUrl,
        // controllerAs: 'vm',
        controller: 'CreateWidgetController',
        scope: {
            currentModule: '<'
        },
        link: function(scope, el) {
            var $modal = el.find('div.modal');
            // Reset variables when closing widget create modal
            $modal.on('hidden', function () {
                // Close slide option panel if wasn't closed
                SlidePanelFactory.closeAll();

                let list = document.getElementsByClassName("note-popover");
                if(list.length > 0){
                    $(".note-popover").attr("style", "display:none");
                }
            });
            // Trigger when widget creation is done
            scope.$watch('state.isDoneCreating', function(nV) {
                if (nV === true) {
                    $modal.modal('hide');
                }
            });
        }
    }
}

/**
 *
 * Create media widget
 * @ngInject
 */
function CreateMediaWidgetController(
    $scope,
    LoadingState,
    MomentDateFormat,
    DataSourceType,
    gettextCatalog,
    UIFactory
) {
    var vm = this;

    var options = {
        minHeight: '100%',
        focus: true,
        dialogsFade: true,
        dialogsInBody: true,
        disableResizeEditor: true,
        placeholder: gettextCatalog.getString('Please enter text in order to save Media Widget'),
        toolbar: [
            ['headline', ['style']],
            ['fontface', ['fontname']],
            ['textsize', ['fontsize']],
            ['fontclr', ['color']],
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
            ['height', ['height']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['fullscreen', 'codeview']],
            ['edit', ['undo', 'redo']],
            ['help', ['help']]
        ],
        maximumImageFileSize: 5242880,
        callbacks: {
            onImageUploadError: function () {
                UIFactory.notify.showError(gettextCatalog.getString('Image is too large'));
            }
        },
        fontNames: ["Arial","Arial Black","Comic Sans MS","Courier New","Helvetica Neue","Helvetica","Impact","Lato","Lucida Grande","Tahoma","Times New Roman","Verdana"]
    };

    // hack to prevent angular-summernote from updating its internal model in O(n) instead of O(1)
    // where n == number of model updates since instantiation - TA-22207
    vm.onChangeCallback = function() {};

    var defaultSelectOptions = {
        allowClear: true,
        autoOpen: false
    };

    var datasourceSelectOptions = _.assign({
        onChange: dataSourceOnChange
    }, defaultSelectOptions);

    vm.state.loadingState = LoadingState.DONE;
    vm.options = options;
    vm.metadata = vm.model.metadata;
    vm.datasourceSelectOptions = datasourceSelectOptions;
    vm.defaultSelectOptions = defaultSelectOptions;
    vm.toggleDataSource = toggleDataSource;
    vm.currentModule = DataSourceType.SERVICE_DATA;
    vm.hasDataSource = !_.isNil(vm.metadata.data_source.id);
    vm.hasDataSourceSelector = vm.hasDataSource;
    vm.DataSourceType = DataSourceType;
    vm.dataSourceOnChange = dataSourceOnChange;
    vm.isHeader = vm.metadata.is_header;
    vm.toggleIsHeader = toggleIsHeader;
    vm.summernoteLanguage = gettextCatalog.getCurrentLanguage();

    function fnCanSave() {
        return (!vm.hasDataSourceSelector || !_.isNil(vm.metadata.data_source.id))
            && !_.isNil(vm.metadata.content)
            && !_.isEmpty(vm.metadata.content)
            && vm.metadata.content !== '<p><br></p>';
    }

    vm.state.fnCanSave = fnCanSave;

    function dataSourceOnChange(data) {
        if (_.isEmpty(data)) {
            toggleDataSource();
        }
    }

    $scope.$on('dateRangePicker:change', function(e, options) {
        if (e.defaultPrevented) {
            return;
        }
        e.defaultPrevented = true;
        // Date picker will automatically add 23 hours 59 minutes and 59 seconds to date selection.
        // ONLY the date matters, if we use hour and minute, it will mess up the date format
        vm.metadata.start_date = moment.utc(moment.unix(options.start).format(MomentDateFormat.ISO)).unix().toString();
        vm.metadata.end_date = moment.utc(moment.unix(options.end).format(MomentDateFormat.ISO)).unix().toString();
    });

    function toggleDataSource() {
        $scope.$evalAsync(function() {
            var dataSource = vm.metadata.entity = vm.metadata.data_source = {};
            dataSource.id = null;

            dataSource.type = vm.hasDataSourceSelector ? "service_data" : null;
            dataSource.name = vm.hasDataSourceSelector ? gettextCatalog.getString("Data Sources") : null;
            dataSource.requires_group_by = false;
            dataSource.requires_date_range = false;
            dataSource.is_of_type_service = vm.hasDataSourceSelector;
            dataSource.primary_date_field = vm.hasDataSourceSelector ? 'log_date' : 'timestamp'
            dataSource.include_num_entities = false;
            dataSource.include_data_freshness_date = false;
            dataSource.name = null;
            dataSource.id_name = null;
            dataSource.icon = 'icomoon-media-widget';
            dataSource.color = '#3498db';
            dataSource.data_view_name = null;

        });
    }

    function toggleIsHeader() {
        vm.isHeader = vm.metadata.is_header = !vm.metadata.is_header;
    }
}

/**
 *
 * Create media widget title
 * @ngInject
 */
function WidgetCreateTitleController(
    WidgetFactory,
    WidgetHeaderModalFactory
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.getWidgetPlainTitle = WidgetFactory.getWidgetPlainTitle;
    vm.openWidgetHeaderModal = openWidgetHeaderModal;

    function $onInit() {
    }

    function openWidgetHeaderModal() {
        WidgetHeaderModalFactory.initModal(vm.model, vm.model);
    }
}
