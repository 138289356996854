import { Bullet } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { useBulletShape } from '@/modules/core/charts/am5/line/composables/useBulletShape';

export function useBullets(context) {
  const { root, chart, config } = context();
  const { createBulletShape } = useBulletShape(context);

  function createBullets() {
    chart.value.series.each((series) => {
      const seriesProps = series.get(Constant.USER_DATA);
      const locationProp = config.value.isRotated ? Constant.LOCATION_X : Constant.LOCATION_Y;
      if (seriesProps.bullet.enabled) {
        series.bullets.push(() => {
          const bulletShape = createBulletShape(series, seriesProps.bullet);
          return Bullet.new(root.value, {
            [locationProp]: 1,
            sprite: bulletShape,
          });
        });
      }
    });
  }

  return {
    createBullets,
  };
}
