import angular from 'angular';

angular.module('exportbuilder.dashboard.services')
    .factory('ExportBuilderPageAssignmentModalUIService', ExportBuilderPageAssignmentModalUIService)

/**
 * @ngInject
 */
function ExportBuilderPageAssignmentModalUIService(
    UIFactory
) {

    let uiState = {
        isActive: false
    };

    return {
        getState: getState,
        setIsActive: setIsActive,
        getIsActive: getIsActive,
    };

    function getState() {
        return uiState;
    }

    function setIsActive(value) {
        uiState.isActive = value;
        if (!value) {
            UIFactory.hideModal('export-builder-assigndatasource-modal');
        }
    }

    function getIsActive() {
        return uiState.isActive
    }
}