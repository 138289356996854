// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontSize": "14px",
	"blue": "#0d45a4",
	"grey": "#737c84",
	"greyLight": "#ccc",
	"black": "#000",
	"green": "#76bf76",
	"yellow": "#f2a449",
	"red": "#f75769",
	"primary": "var(--primary)",
	"info": "#3e8ed0",
	"white": "#fff",
	"orange": "#f56021"
};
export default ___CSS_LOADER_EXPORT___;
