import { Bullet, color, Label, p50 } from '@amcharts/amcharts5';
import { Constant, SeriesType } from '@/modules/core/charts/am5/charts.constants';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { useBullets as useGenericBullets } from '@/modules/core/charts/am5/base/composables/useBullets';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';
import { XYSeries } from '@amcharts/amcharts5/xy';
import { useToolTips } from '@/modules/core/charts/am5/pie/composables/useTooltips';

export function useMetricLabels(context) {
  const { root, chart, config, isDarkTheme } = context();

  const { hidePlotContainerOutsideBullets } = useGenericBullets(context);
  const { formatNumber } = useNumberFormatter(context);
  const { assignThemeColor } = useToolTips(context);

  function createMetricLabels(createBenchmarkLabel) {
    const { isRotated } = config.value;
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);

    chart.value.series.each((series) => {
      const userData = series.get(Constant.USER_DATA);
      const { showMetricLabels, dataItemFormat, lineType, seriesType } = userData;

      // ensure we're only adding the metric value bullet once - if it's coming in from
      // the benchmark it won't be a full XYSeries
      if (userData.constructor.name === XYSeries.name && createBenchmarkLabel) {
        return;
      }

      if (showMetricLabels && seriesType !== SeriesType.COLUMN) {
        const suffix = dataItemFormat === ColumnFormat.FORMAT_PERCENT ? '%' : '';
        series.bullets.push(() => {
          const label = Label.new(root.value, {
            centerY: 26,
            centerX: p50,
            // eslint-disable-next-line tap/no-raw-text-js
            text: `{${isRotated ? 'valueX' : 'valueY'}.formatNumber()}${suffix}`,
            fill: color(lineType === Constant.BENCHMARK ? userData.labelFill : fontColorPicker),
            fontSize: 12,
            populateText: true,
          });

          hidePlotContainerOutsideBullets(label);

          const labelBullet = Bullet.new(root.value, {
            sprite: label,
          });

          labelBullet.get(Constant.SPRITE).text.adapters.add(Constant.TEXT, (text, target) => {
            const { dataItem } = target;
            const field = dataItem.component.get(
              isRotated ? Constant.VALUE_X_FIELD : Constant.VALUE_Y_FIELD
            );
            const value = dataItem.dataContext[field];
            const seriesUserData = dataItem.component.get(Constant.USER_DATA);

            return formatNumber(value, seriesUserData.dataItemFormat);
          });

          return labelBullet;
        });
      }
    });
  }

  return {
    createMetricLabels,
  };
}
