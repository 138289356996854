'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('datasource.services', [])

    .factory('DataSourceFactory', DataSourceFactory)
    .factory('DataSourceDataGridFactoryDelegate', DataSourceDataGridFactoryDelegate)
    .factory('DataSourceResourceDelegate', DataSourceResourceDelegate);

/**
 * @ngInject
 */
function DataSourceFactory(
    AppFactory,
    DataSourceResourceDelegate,
    DataSourceType
) {
    /**
     * @delegate method
     * Get columns needed to create a widget
     * @param dataSource
     * @param queryParams
     * @returns {*|IPromise<TResult>|JQueryPromise<U>|JQueryPromise<void>|JQueryPromise<R>}
     */
    var getColumns = function(dataSource, queryParams) {
        // Set defaults
        var params = _.extend(queryParams, {
        });

        // NOTE: respective factory, may or may not use the passed data source (ex: leads WONT, services WILL)
        return DataSourceResourceDelegate.getFactory(dataSource.type).getColumns(params, dataSource).then(function(json) {
            return json.plain();
        });
    };

    /**
     * @delegate method
     * Return specific field values
     * @param dataSource
     * @param fields
     * @returns {*|{}}
     */
    var getColumnValues = function(dataSource, fields) {
        return DataSourceResourceDelegate.getFactory(dataSource.type).getColumnValues(fields).then(function (json) {
            return json.plain();
        });
    };

    /**
     * @delegate method
     * Return specific field values
     * @param dataSource
     * @param fields
     * @param queryParams
     * @returns {*|{}}
     */
    var getFieldValues = function(dataSource, fields, queryParams) {
        return DataSourceResourceDelegate.getFactory(dataSource.type).getFieldValues(fields, dataSource, queryParams).then(function (json) {
            // A WorkerManager Resource will directly give json
            if(_.isFunction(json.plain)) {
                return json.plain();
            } else {
                return json;
            }
        });
    };

    /**
     * Returns whether or not widget has an IO data source type
     * @param type
     * @returns {boolean}
     */
    var isIODataSourceType = function(type) {
        return type == DataSourceType.ORDER
            || type == DataSourceType.ORDERS_REPORT
            || type == DataSourceType.ORDER_TASKS_REPORT
            || type == DataSourceType.ORDERS_WITH_TASKS_REPORT
            || type == DataSourceType.LINE_ITEM
            || type == DataSourceType.LINE_ITEMS_REPORT
            || type == DataSourceType.LINE_ITEM_TASKS_REPORT
            || type == DataSourceType.LINE_ITEMS_WITH_TASKS_REPORT
            || type == DataSourceType.FLIGHTS_REPORT
            || type == DataSourceType.FLIGHT_TASKS_REPORT
            || type == DataSourceType.FLIGHTS_WITH_TASKS_REPORT
            || type == DataSourceType.BILLING_REPORT
            || type == DataSourceType.TASK
            || type == DataSourceType.MASTER
            || type == DataSourceType.EC_TASKS_SUMMARY
            || type == DataSourceType.BUY_SHEET;
    };

    /**
     * @param type
     * @returns {boolean}
     */
    var isServiceDataSourceType = function(type) {
        return type == DataSourceType.SERVICE_DATA;
    };

    /**
     * @param type
     * @returns {boolean}
     */
    var isCategoryDataSourceType = function(type) {
        return type == DataSourceType.CATEGORY_DATA;
    };

    /**
     *
     * @param type
     * @returns {boolean}
     */
    const isSeoWebsiteDataSourceType = function(type) {
        return type === DataSourceType.SEO_WEBSITES;
    };

    /**
     * @param type
     * @returns {boolean}
     */
    let isGoalDataSourceType = function(type) {
        return type === DataSourceType.GOAL_DATA;
    };

    /**
     * @param type
     * @returns {boolean}
     */
    let isIndustryBenchmarkDataSourceType = function(type) {
        return type === DataSourceType.INDUSTRY_BENCHMARK_PAID_MEDIA
            || type === DataSourceType.INDUSTRY_BENCHMARK_EMAIL
            || type === DataSourceType.INDUSTRY_BENCHMARK_LEAD_TRACKING;
    };

    /**
     * @param type
     * @returns {boolean}
     */
    const isUserDataSourceType = function(type) {
        return type === DataSourceType.USER;
    };

    const isLeadsDataSourceType = function(type) {
        return type === DataSourceType.LEADS;
    };

    /**
     * Returns whether or not widget a has a service or category data source type
     * @param type
     * @returns {boolean}
     */
    var dataSourceContainsServices = function(type) {
        return type == DataSourceType.SERVICE_DATA
            || type == DataSourceType.CATEGORY_DATA
            || type == DataSourceType.SEO_WEBSITES;
    };

    /**
     * Returns whether or not data source type is used in custom dashboards
     * @param type
     * @returns {boolean}
     */
    var isDashboardDataSourceType = function(type) {
        return isIODataSourceType(type)
            || dataSourceContainsServices(type)
            || isIndustryBenchmarkDataSourceType(type)
            || type == DataSourceType.LEADS
            || type == DataSourceType.GOAL_DATA
            || type == DataSourceType.EMAIL_DATA
            || type == DataSourceType.USER_LOGINS;
    };

    /**
     *
     * @param type
     * @param id
     */
    function getDataSourceDetails(type, id) {
        switch (type) {
            case DataSourceType.SERVICE_DATA:
                return _.find(AppFactory.getAllServices(), {id: id});

            case DataSourceType.CATEGORY_DATA:
                return _.find(AppFactory.getAllCategories(), {id: id});
        }
    }

    /**
     * @param dataSource
     * @returns {string}
     */
    function getDataSourceIcon(dataSource) {
        if (!_.isEmpty(dataSource)) {
            let newDataSource = {};
            if (_.isArray(dataSource) && dataSource[0].type === DataSourceType.PRODUCT) {
                newDataSource = angular.copy(dataSource[0]);
            } else {
                newDataSource = angular.copy(dataSource);
            }

            var icon = angular.copy(newDataSource.icon);
            if (newDataSource.type === DataSourceType.SERVICE_DATA) {
                return newDataSource.has_custom_icon
                    ? 'custom-icon ' + icon
                    : 'serviceicon-' + icon;
            }
            else {
                return newDataSource.has_custom_icon
                    ? 'custom-icon ' + icon
                    : icon;
            }
        }
    }

    /**
     * @param dataSource
     * @returns {*}
     */
    function getDataSourceColor(dataSource) {
        if (!_.isEmpty(dataSource)) {
            var color = angular.copy(dataSource.color);
            if (dataSource.type == DataSourceType.SERVICE_DATA && dataSource.custom_color) {
                color = dataSource.custom_color;
            }
            return color;
        }
        return null;
    }

    /**
     * Retrieves Service/Channel name from data_source object
     * @param dataSource
     */
    function getDataSourceName(dataSource) {
        if (_.isEmpty(dataSource)) {
            return '';
        }

        if (_.isArray(dataSource)) {
            return _.map(dataSource, getDataSourceName).join(', ');
        }

        if (dataSource.type === DataSourceType.PRODUCT) {
            return dataSource.id_name || dataSource.name;
        }

        let services = dataSource.type === DataSourceType.SERVICE_DATA
            ? AppFactory.getConnectedServices()
            : AppFactory.getConnectedCategories();

        let service = _.find(services, service => service.id === dataSource.id );

        return service ? service.name : '';
    }

    return {
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        isServiceDataSourceType: isServiceDataSourceType,
        isCategoryDataSourceType: isCategoryDataSourceType,
        isSeoWebsiteDataSourceType: isSeoWebsiteDataSourceType,
        isGoalDataSourceType: isGoalDataSourceType,
        isIODataSourceType: isIODataSourceType,
        dataSourceContainsServices: dataSourceContainsServices,
        isDashboardDataSourceType: isDashboardDataSourceType,
        getDataSourceDetails: getDataSourceDetails,
        getDataSourceIcon: getDataSourceIcon,
        getDataSourceColor: getDataSourceColor,
        getDataSourceName: getDataSourceName,
        isIndustryBenchmarkDataSourceType: isIndustryBenchmarkDataSourceType,
        isUserDataSourceType: isUserDataSourceType,
        isLeadsDataSourceType: isLeadsDataSourceType
    }
}

/**
 * Allows to get child factory based on data source type
 * @param DataSourceType
 * @returns {{getFactory: getFactory}}
 * @constructor
 * @ngInject
 */
function DataSourceDataGridFactoryDelegate(
    $injector,
    DataSourceType
) {
    /**
     * WARNING: Any delegate function used from a returned factory must be implemented
     * Every factory returnable should have the same function signatures
     * Ex: ServiceDataFactory.getColumns() ==> LeadsFactory.getColumns()
     * Current functions: getColumns(), getSampleData(), getData()
     *
     * @param dataSourceType
     * @returns {*}
     */
    var getFactory = function(dataSourceType) {
        switch (dataSourceType) {
            // ANALYTICS
            case DataSourceType.PAGE:
                return $injector.get('PageDataGridFactory');

            case DataSourceType.PAGE_LIBRARY:
                return $injector.get('PageLibraryDataGridFactory');

            case DataSourceType.PAGE_TAG:
                return $injector.get('PageTagsDataGridFactory');

            case DataSourceType.LAYOUT_LIBRARY:
                return $injector.get('LayoutLibraryDataGridFactory');

            case DataSourceType.LAYOUT_TAG:
                return $injector.get('LayoutTagsDataGridFactory');

            case DataSourceType.WIDGET_LIBRARY:
                return $injector.get('WidgetLibraryDataGridFactory');

            case DataSourceType.REPORT_STUDIO_LIBRARY:
                return $injector.get('ReportStudioLibraryDataGridFactory');

            case DataSourceType.WIDGET_TAG:
                return $injector.get('WidgetTagsDataGridFactory');

            case DataSourceType.SERVICE_CLIENT_MAPPING:
                return $injector.get('ServiceClientMappingDataGridFactory');

            case DataSourceType.SERVICE_CLIENT_MAPPING_LOG:
                return $injector.get('ServiceClientMappingLogDataGridFactory');

            case DataSourceType.SERVICE_CLIENT_MAPPING_LOG_RESULT:
                return $injector.get('ServiceClientMappingLogResultsDataGridFactory');

            case DataSourceType.CONNECTION_STATUS:
                return $injector.get('ConnectionStatusDataGridFactory');

            case DataSourceType.NAMING_CONVENTION:
                return $injector.get('NamingConventionDataGridFactory');

            case DataSourceType.TAG:
                return $injector.get('TagDataGridFactory');

            case DataSourceType.TAG_VALUE:
                return $injector.get('TagValuesDataGridFactory');

            case DataSourceType.SMART_CAMPAIGN:
                return $injector.get('SmartCampaignDataGridService');

            case DataSourceType.LEADS:
                return $injector.get('LeadsDataGridFactory');

            case DataSourceType.LINE_ITEM:
                return $injector.get('LineItemDataGridFactory');

            case DataSourceType.LINE_ITEMS_REPORT:
                return $injector.get('LineItemsReportDataGridFactory');

            case DataSourceType.LINE_ITEM_TASKS_REPORT:
                return $injector.get('LineItemTasksReportDataGridFactory');

            case DataSourceType.LINE_ITEMS_WITH_TASKS_REPORT:
                return $injector.get('LineItemsWithTasksReportDataGridFactory');

            case DataSourceType.FLIGHTS_REPORT:
                return $injector.get('FlightsReportDataGridFactory');

            case DataSourceType.FLIGHT_TASKS_REPORT:
                return $injector.get('FlightTasksReportDataGridFactory');

            case DataSourceType.FLIGHTS_WITH_TASKS_REPORT:
                return $injector.get('FlightsWithTasksReportDataGridFactory');

            case DataSourceType.BILLING_REPORT:
                return $injector.get('BillingReportDataGridFactory');

            case DataSourceType.TASK:
                return $injector.get('TaskDataGridFactory');

            case DataSourceType.ORDER:
                return $injector.get('OrdersDataGridFactory');

            case DataSourceType.ORDERS_REPORT:
                return $injector.get('OrdersReportDataGridFactory');

            case DataSourceType.ORDER_TASKS_REPORT:
                return $injector.get('OrderTasksReportDataGridFactory');

            case DataSourceType.ORDERS_WITH_TASKS_REPORT:
                return $injector.get('OrdersWithTasksReportDataGridFactory');

            case DataSourceType.IO_MASTER:
                return $injector.get('IOMasterDataGridFactory');

            case DataSourceType.EC_TASKS_SUMMARY:
                return $injector.get('ECTasksSummaryDataGridFactory');

            case DataSourceType.END_CUSTOMER:
                return $injector.get('EndCustomerDataGridFactory');

            case DataSourceType.ALERT:
                return $injector.get('AlertDataGridFactory');

            case DataSourceType.EXECUTIVE_SUMMARY:
            case DataSourceType.EXECUTIVE_SUMMARY_ITEM:
                return $injector.get('ExecutiveSummaryResourceFactory');

            case DataSourceType.PRODUCT:
                return $injector.get('ProductDataGridFactory');

            /**
             * @TODO: convert to data grid factories
             */
            case DataSourceType.USER:
                return $injector.get('UserFactory');

            case DataSourceType.SCHEDULED_REPORTS:
                return $injector.get('ScheduledReportFactory');

            default:
                return null;
        }
    };

    return {
        getFactory: getFactory
    }
}

/**
 * Allows to get child resource factory based on data source type
 * @param DataSourceType
 * @returns {{getFactory: getFactory}}
 * @constructor
 * @ngInject
 */
function DataSourceResourceDelegate(
    $injector,
    DataSourceType
) {
    /**
     * WARNING: Any delegate function used from a returned factory must be implemented
     * Every factory returnable should have the same function signatures
     * Ex: ServiceDataFactory.getColumns() ==> LeadsFactory.getColumns()
     * Current functions: getColumns(), getSampleData(), getData()
     *
     * @param dataSourceType
     * @returns {*}
     */
    var getFactory = function(dataSourceType) {
        switch (dataSourceType) {
            // ANALYTICS
            case DataSourceType.PAGE:
                return $injector.get('PageResource');

            case DataSourceType.PAGE_LIBRARY:
                return $injector.get('PageLibraryResourceFactory');

            case DataSourceType.PAGE_TAG:
                return $injector.get('PageTagsResource');

            case DataSourceType.LAYOUT_LIBRARY:
                return $injector.get('LayoutLibraryResourceFactory');

            case DataSourceType.LAYOUT_TAG:
                return $injector.get('LayoutTagsResource');

            case DataSourceType.WIDGET:
                return $injector.get('WidgetFactory');

            case DataSourceType.WIDGET_LIBRARY:
                return $injector.get('WidgetLibraryResourceFactory');

            case DataSourceType.REPORT_STUDIO_LIBRARY:
                return $injector.get('ReportStudioLibraryResourceFactory');

            case DataSourceType.WIDGET_TAG:
                return $injector.get('WidgetTagsResource');

            case DataSourceType.SERVICE_DATA:
                return $injector.get('ServiceDataFactory');

            case DataSourceType.SERVICE_CLIENT_MAPPING:
                return $injector.get('ServiceClientMappingResource');

            case DataSourceType.SERVICE_CLIENT_MAPPING_LOG:
                return $injector.get('ServiceClientMappingLogResource');

            case DataSourceType.SERVICE_CLIENT_MAPPING_LOG_RESULT:
                return $injector.get('ServiceClientMappingLogResultResource');

            case DataSourceType.GOALS:
                return $injector.get('GoalsResource');

            case DataSourceType.GOAL_DATA:
                return $injector.get('GoalsDataResource');

            case DataSourceType.INDUSTRY_BENCHMARK_PAID_MEDIA:
                return $injector.get('IndustryBenchmarkPaidMediaResource');

            case DataSourceType.INDUSTRY_BENCHMARK_EMAIL:
                return $injector.get('IndustryBenchmarkEmailResource');

            case DataSourceType.INDUSTRY_BENCHMARK_LEAD_TRACKING:
                return $injector.get('IndustryBenchmarkLeadTrackingResource');

            case DataSourceType.CONNECTION_STATUS:
                return $injector.get('ConnectionStatusResource');

            case DataSourceType.NAMING_CONVENTION:
                return $injector.get('NamingConventionResource');

            case DataSourceType.TAG:
                return $injector.get('TagResource');

            case DataSourceType.TAG_VALUE:
                return $injector.get('TagValuesResource');

            case DataSourceType.SMART_CAMPAIGN:
                return $injector.get('SmartCampaignResource');

            case DataSourceType.LEADS:
                return $injector.get('LeadsResource');

            case DataSourceType.END_CUSTOMER:
                return $injector.get('EndCustomerResource');

            case DataSourceType.SERVICE_ORDER_ITEM:
                return $injector.get('ServiceOrderItemFactory');

            case DataSourceType.SCHEDULED_REPORTS:
                return $injector.get('ScheduledReportFactory');

            case DataSourceType.CATEGORY_DATA:
                return $injector.get('CategoryDataFactory');

            case DataSourceType.USER:
                return $injector.get('UserFactory');

            case DataSourceType.USER_LOGINS:
                return $injector.get('UserLoginsResource');

            case DataSourceType.CLIENT:
                return $injector.get('ClientFactory');

            case DataSourceType.ALERT:
                return $injector.get('AlertResource');

            case DataSourceType.EMAIL_DATA:
                return $injector.get('EmailDataResource');

            case DataSourceType.EXECUTIVE_SUMMARY:
            case DataSourceType.EXECUTIVE_SUMMARY_ITEM:
                return $injector.get('ExecutiveSummaryResourceFactory');

            case DataSourceType.PRODUCT:
                return $injector.get('ManageProductsResource');

            // IO
            case DataSourceType.ORDER:
                return $injector.get('OrderFactory');

            case DataSourceType.ORDERS_REPORT:
                return $injector.get('OrdersReportFactory')

            case DataSourceType.ORDER_TASKS_REPORT:
                return $injector.get('OrderTasksReportFactory')

            case DataSourceType.ORDERS_WITH_TASKS_REPORT:
                return $injector.get('OrdersWithTasksReportFactory')

            case DataSourceType.LINE_ITEM:
                return $injector.get('LineItemFactory');

            case DataSourceType.LINE_ITEMS_REPORT:
                return $injector.get('LineItemsReportFactory');

            case DataSourceType.LINE_ITEM_TASKS_REPORT:
                return $injector.get('LineItemTasksReportFactory');

            case DataSourceType.LINE_ITEMS_WITH_TASKS_REPORT:
                return $injector.get('LineItemsWithTasksReportFactory');

            case DataSourceType.FLIGHTS_REPORT:
                return $injector.get('FlightsReportFactory');

            case DataSourceType.FLIGHT_TASKS_REPORT:
                return $injector.get('FlightTasksReportFactory');

            case DataSourceType.FLIGHTS_WITH_TASKS_REPORT:
                return $injector.get('FlightsWithTasksReportFactory');

            case DataSourceType.BILLING_REPORT:
                return $injector.get('BillingReportFactory');

            case DataSourceType.TASK:
                return $injector.get('TasksFactory');

            case DataSourceType.MASTER:
                return $injector.get('IOMasterFactory');

            case DataSourceType.EC_TASKS_SUMMARY:
                return $injector.get('ECTasksSummaryFactory');

            case DataSourceType.BUY_SHEET:
                return $injector.get('BuySheetFactory');

            // LIVE API SEO
            case DataSourceType.SEO_WEBSITES:
                return $injector.get('SeoWebsitesFactory');

            default:
                return null;
        }
    };

    return {
        getFactory: getFactory
    }
}
