export class NoDataAction {
  /**
   * @type {string}
   */
  title;

  /**
   * @type {string}
   */
  text;

  /**
   * @type {?string}
   */
  link;

  /**
   * @type {?Function}
   */
  click;

  constructor(model = {}) {
    this.title = model.title;
    this.text = model.text;
    this.link = model.link;
    this.click = model.click;
  }

  /**
   * @returns {NoDataAction}
   */
  static initDefault() {
    return new NoDataAction({
      title: i18n.$t('No Data actionable'),
    });
  }

  /**
   * @returns {boolean}
   */
  hasTextOnly() {
    return !this.click && !this.link;
  }

  /**
   * @returns {boolean}
   */
  hasLink() {
    return !!this.link;
  }

  /**
   * @returns {boolean}
   */
  hasClick() {
    return !!this.click;
  }
}
