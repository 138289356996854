import { commits, dispatches } from '@/modules/core/app/helpers/store';
import BadgeService from '@/modules/core/badge/services/BadgeService';

export const actions = {
  async getSentRequestStatuses({ state }) {
    if (state.sentRequestStatuses) {
      return state.sentRequestStatuses;
    }
    return dispatches.badge.updateStatuses();
  },
  async updateStatuses() {
    const statuses = BadgeService.getSentRequestsStatuses();
    commits.badge.setSentRequestStatuses(statuses);
    return statuses;
  },
};
