export const mutations = {
  /**
   * @param state
   * @param {boolean} value
   */
  togglePanel(state, value) {
    state.isShowingPanel = value;
  },

  /**
   * @param state
   * @param value
   */
  setGroupName(state, value) {
    state.groupName = value;
  },
};
