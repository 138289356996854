'use strict';

angular.module('layout.library.services', [])

    .factory('LayoutLibraryFactory', LayoutLibraryFactory)
    .factory('LayoutLibraryPublishFactory', LayoutLibraryPublishFactory);

/**
 * @ngInject
 */
function LayoutLibraryFactory(
    gettextCatalog,
    DesignFactory,
    WidgetFactory,
    WidgetLibraryFactory,
    LibraryFactory,
    LibraryModelFactory,
    LayoutLibraryResourceFactory,
    DrawOption,
    WidgetType,
    WidgetUtilService
) {
    return {
        getLibraryLayouts: getLibraryLayouts,
        getLayoutWidgets: getLayoutWidgets,
        getSearchedLayouts: getSearchedLayouts,
        getSearchableValues: getSearchableValues,
        incrementDownloadCount: incrementDownloadCount,
        useLayoutAsTemplate: useLayoutAsTemplate
    };

    /**
     * @returns {*}
     */
    function getLibraryLayouts() {
        return LibraryFactory.getLibraryItems(LayoutLibraryResourceFactory.getLayoutsForDisplay);
    }

    /**
     * @returns {*}
     */
    function getLayoutWidgets(layoutId) {
        return WidgetFactory.getLayoutWidgets(layoutId).then(function(widgets) {
            return _.map(widgets, function (widget) {
                widget.computedWidth = WidgetUtilService.getWidgetWidth(widget.width);
                widget.computedHeight = WidgetUtilService.getWidgetHeight(widget.height);
                WidgetLibraryFactory.setDefaultWidgetValues(widget);
                if (widget.type === WidgetType.MEDIA) {
                    widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA] = false;
                }
                return widget;
            });
        });
    }

    /**
     * @returns {*}
     */
    function getSearchedLayouts(queryParams) {
        return LayoutLibraryResourceFactory.getLayoutsForDisplay(queryParams);
    }

    /**
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function getSearchableValues() {
        return LayoutLibraryResourceFactory.getSearchableValues().then(function(data) {
            data.tags = LibraryFactory.mapTagSearchableValues(data.tags);
            data.dataSources = LibraryFactory.mapDataSourceSearchableValues(data.dataSources);
            return _groupSearchableValues(data);
        });
    }

    /**
     * @param id
     * @returns {*}
     */
    function incrementDownloadCount(id) {
        return LayoutLibraryResourceFactory.incrementDownloadCount(id);
    }

    /**
     * @param layout
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function useLayoutAsTemplate(layout) {
        _setLibrarySpecificProperties(layout);
        _trimLibrarySpecificProperties(layout);

        return LayoutLibraryResourceFactory.useLayoutAsTemplate(layout);
    }

    /**
     * @param layout
     * @private
     */
    function _setLibrarySpecificProperties(layout) {
        var page = DesignFactory.getCurrentPage();
        layout.page_id = DesignFactory.getCurrentPage().id;
        layout.display_order = DesignFactory.getNextLayoutDisplayOrder(page.layouts);
        layout.is_predefined = false;
        layout.is_in_library = false;
    }

    /**
     * Certain properties are returned when hitting library endpoints,
     * they are unwanted when creating a new layout from a library template
     *
     * @param layout
     * @private
     */
    function _trimLibrarySpecificProperties(layout) {
        delete layout.page;
        delete layout.thumbnail_metadata;
        delete layout.disconnected_service_ids;
    }

    /**
     * @param data
     * @returns {*[]}
     * @private
     */
    function _groupSearchableValues(data) {
        return [
            {text: gettextCatalog.getString('Data Categories'), children: data.dataSources},
            {text: gettextCatalog.getString('Section Tags'), children: data.tags}
        ];
    }
}

/**
 * @ngInject
 */
function LayoutLibraryPublishFactory(
    LayoutLibraryResourceFactory,
    LayoutTagFactory
) {
    return {
        publish: publish,
        saveTags: saveTags
    };

    /**
     * Saves the layout and sends it to the Layout Library
     * @params layout object
     */
    function publish(layout) {
        var model = _.assign(layout, {
            is_predefined: false,
            is_in_library: true
        });

        return LayoutLibraryResourceFactory.publish(model);
    }

    /**
     * @param tags
     * @returns {*}
     */
    function saveTags(tags) {
        return LayoutTagFactory.saveTags(tags);
    }
}