import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';

export class CustomTranslation extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  key;

  /**
   * @var {string}
   */
  default_translation;

  /**
   * @var {string}
   */
  locale_translation;

  /**
   * @var {Array}
   */
  value;

  /**
   * @var {string}
   */
  value_string;

  /**
   * @var {string}
   */
  locale;

  /**
   * @var {string}
   */
  locale_display;

  /**
   * @var {boolean}
   */
  is_predefined;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.key = model.key;
    this.default_translation = model.default_translation;
    this.locale_translation = model.locale_translation;
    this.value = model.value || [];
    this.value_string = this.value.join(',');
    this.locale = model.locale;
    this.locale_display = model.locale_display;
    this.is_predefined = !!model.is_predefined;
  }
}
