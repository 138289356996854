export const LAT_LONG = 'latlong';
export const COUNTRY = 'country';
export const STATE = 'state';
export const CITY = 'city';
export const COUNTY = 'county';
export const DMA = 'dma';
export const CONGRESSIONAL_DISTRICT = 'congressional_district';
export const ZIP_CODE = 'zipcode';
export const THE_TRADE_DESK_ID = '61';
export const FACEBOOK_ADS_ID = '39';
export const TOP_LEFT = 'top-left';
export const BLUE = 'blue';
export const SYMBOL = 'symbol';
export const MOVEEND = 'moveend';
export const CLICK = 'click';

export const WEBKITFULLSCREENCHANGE = 'webkitfullscreenchange';
export const FULLSCREENCHANGE = 'fullscreenchange';
export const MOZFULLSCREENCHANGE = 'mozfullscreenchange';
export const $MapBoxEvents = {
  LAYER_UPDATE: '$MapBoxEvents:LAYER_UPDATE',
};
export const LAYERS_PRIORITY = {
  [LAT_LONG]: 0,
  [COUNTRY]: 1,
  [STATE]: 2,
  [COUNTY]: 3,
  [CITY]: 4,
  [ZIP_CODE]: 5,
  [DMA]: 6,
  [CONGRESSIONAL_DISTRICT]: 7,
};
