import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

import namingConventionRuleModalHtmlUrl from './namingconventionrule.modal.html';
import namingConventionRuleContentHtmlUrl from './namingconventionrule.content.html';
import namingConventionPreviewHtmlUrl from './namingconventionpreview.html';

angular.module('namingconventionrule.directives', [])
    .component('namingConventionBuilderModal', {
        bindings: {
            onAddNamingConvention: '<',
            namingConventionId: '<?'
        },
        templateUrl: namingConventionRuleModalHtmlUrl,
        controller: NamingConventionParserController,
        controllerAs: 'vm'
    })
    .component('namingConventionRuleContent', {
        bindings: {
            customDelimiterState: '=',
            namingConvention: '=',
            ruleItemsCollection: '=',

            onClearAll: '<',
            onAddDelimiter: '<',
            onRuleItemDelete: '<',
            onDeleteDelimiterClicked: '<',
            onSortStart: '<',
            onSortStop: '<',
        },
        templateUrl: namingConventionRuleContentHtmlUrl,
        controller: NamingConventionRuleModalContent,
        controllerAs: 'vm'
    })
    .component('namingConventionPreview', {
        bindings: {
            preview: '<',
            ruleItems: '<'
        },
        templateUrl: namingConventionPreviewHtmlUrl,
        controller: NamingConventionPreviewController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function NamingConventionParserController(
    NamingConventionRuleFactory,
    UINamingConventionModalFactory,
    NamingConventionsModelFactory,
    UIFactory
) {
    var vm = this;
    vm.state = {
        customDelimiterState: {
            isCreating: false,
        },
        isSaving: false,
        isLoadingRuleItems: true
    };

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.onClose = onClose;
    vm.onSaveClicked = onSaveClicked;
    vm.isSaving = isSaving;
    vm.isLoading = isLoading;

    vm.onClearAllClicked = onClearAllClicked;
    vm.onRuleItemClicked = onRuleItemClicked;
    vm.onDeleteDelimiterClicked = onDeleteDelimiterClicked;
    vm.onAddDelimiter = onAddDelimiter;
    vm.onSortStart = onSortStart;
    vm.onSortStop = onSortStop;

    /**
     * Life Cycles
     */

    function $onInit() {
        NamingConventionRuleFactory.retrieveRuleItems(vm.namingConventionId).then(function (ruleItemsCollection) {
            vm.ruleItemsCollection = ruleItemsCollection;
            _reOrderItems();
        });
        NamingConventionRuleFactory.retrieveNamingConvention(vm.namingConventionId).then(function (namingConvention) {
            vm.namingConvention = namingConvention;
        });
    }

    function $onDestroy() {

    }

    /**
     * Instance methods
     */

    function onClearAllClicked() {
        NamingConventionRuleFactory.clearAllRuleItems(vm.namingConvention);
        UINamingConventionModalFactory.didChangeNamingConventionParserRuleItems(vm.namingConvention.ruleItems);
    }

    /**
     * Respond when clicking on delete rule item button at an index
     * @param {Number} index
     */
    function onRuleItemClicked(index) {
        vm.namingConvention.ruleItems.splice(index, 1);
        UINamingConventionModalFactory.didChangeNamingConventionParserRuleItems(vm.namingConvention.ruleItems);
    }

    function onDeleteDelimiterClicked(index, delimiter) {
        vm.ruleItemsCollection.delimiters.items.splice(index, 1);
        NamingConventionRuleFactory.removeCustomDelimiter(delimiter);
    }

    function onAddDelimiter(text) {
        vm.state.customDelimiterState.isCreating = true;
        NamingConventionRuleFactory.createDelimiterRule(text, vm.namingConvention)
            .then(function (delimiter) {
                vm.ruleItemsCollection.delimiters.items.push(delimiter);
                _reOrderItems();
                vm.state.customDelimiterState.isCreating = false;
            })
            .catch(function (msg) {
                vm.state.customDelimiterState.isCreating = false;
                UIFactory.notify.showError(msg);
            });
    }

    function onClose() {
        UINamingConventionModalFactory.setIsActive(false);
    }

    function onSaveClicked() {
        vm.state.isSaving = true;
        NamingConventionRuleFactory.saveRule(vm.namingConvention)
            .then(function (namingConvention, error) {
                vm.state.isSaving = false;
                if (!error) {
                    UINamingConventionModalFactory.setIsActive(false);
                    vm.onAddNamingConvention();
                }
            })
            .catch(function (msg) {
                vm.state.isSaving = false;
                UIFactory.notify.showError(msg);
            });
    }

    /**
     * Getters
     */
    function isSaving() {
        return vm.state.isSaving;
    }

    /**
     * Finishes loading only when loaded rule items and resolve a naming convention
     */
    function isLoading() {
        return !vm.ruleItemsCollection || !vm.namingConvention;
    }

    /**
     * Private methods
     */

    function onSortStart() {

    }

    function onSortStop() {
        _reOrderItems();
        UINamingConventionModalFactory.didChangeNamingConventionParserRuleItems(vm.namingConvention.ruleItems);
    }

    /**
     * Keeps rule item sections ordering the same if dragged by user
     * @private
     */
    function _reOrderItems() {
        vm.ruleItemsCollection.identifiers.items = _.orderBy(vm.ruleItemsCollection.identifiers.items, 'id', true);
        vm.ruleItemsCollection.delimiters.items = _.orderBy(vm.ruleItemsCollection.delimiters.items, 'id', true);
        vm.ruleItemsCollection.specials.items = _.orderBy(vm.ruleItemsCollection.specials.items, 'id', true);
    }

}

function NamingConventionRuleModalContent(
    $scope
) {
    var vm = this;
    vm.model = {};
    vm.state = {
        isDragging: false,
        showCustomDelimiter: false
    };
    var namingConventionsHelper = UINamingConventionsHelper();

    vm.$onInit = $onInit;
    vm.toggleCustomDelimiter = toggleCustomDelimiter;
    vm.submitCustomDelimiter = submitCustomDelimiter;

    vm._sortStart = sortStart; // for testing
    vm._sortStop = sortStop;   // for testing

    vm.sortableTagsOptions = namingConventionsHelper.getSortableOptions(sortStart, sortStop);
    vm.parserSortableTagsOptions = namingConventionsHelper.getParserSortableOptions(sortStart, sortStop);

    function $onInit() {
    }

    function toggleCustomDelimiter() {
        vm.state.showCustomDelimiter = true;
    }

    function submitCustomDelimiter(text) {
        vm.onAddDelimiter && vm.onAddDelimiter(text);
        vm.state.showCustomDelimiter = false;
        vm.model.customDelimiter = '';
    }

    function sortStart() {
        vm.onSortStart && vm.onSortStart();
        vm.state.isDragging = true;
        $scope.$digest();
    }

    function sortStop() {
        vm.onSortStop && vm.onSortStop();
        vm.state.isDragging = false;
        $scope.$digest();
    }
}

function NamingConventionPreviewController(
    UINamingConventionModalFactory
) {
    var vm = this;
    vm.spans = [];

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.$onDestroy = $onDestroy;

    vm._updatePreview = _update;

    function $onInit() {
        vm.spans = updatePreview(vm.preview, vm.ruleItems);
        UINamingConventionModalFactory.onRuleItemsChange(_update);
    }

    function $onChanges() {
        vm.spans = updatePreview(vm.preview, vm.ruleItems);
    }

    function $onDestroy() {
        UINamingConventionModalFactory.off(_update);
    }

    /**
     * Callback triggered when ruleItems changed notification
     * @private
     */
    function _update() {
        vm.spans = updatePreview(vm.preview, vm.ruleItems);
    }

    /**
     * Rebuilds span objects with respective color from rule items and preview string
     * @param preview string to be parsed
     * @param spans
     * @param ruleItems
     */
    function updatePreview(preview, ruleItems) {
        var spans = [];

        if (preview.length === 0) {
            spans.push(new Span(preview, ''));
            return spans;
        }
        if (ruleItems && !ruleItems.length) {
            spans.push(new Span(preview, ''));
            return spans;
        }

        var currentString = preview;
        for (var i = 0; i < ruleItems.length; i++) {
            var currentRule = ruleItems[i];

            var nextRule = ruleItems[i + 1];

            // if first rule is a delimiter
            if (i === 0 && currentRule.isDelimiter()) {
                var delimiterIndex = currentString.indexOf(currentRule.text);
                if (delimiterIndex >= 0) { // extract only if delimiter is in string
                    var spanString = currentString.substring(0, delimiterIndex + currentRule.text.length);
                    spans.push(new Span(spanString, currentRule));
                    currentString = currentString.substring(delimiterIndex + currentRule.text.length, currentString.length);
                }
            }

            // we have reached the end
            if (!nextRule) {
                currentString.length && spans.push(new Span(currentString, currentRule));
                return spans;
            }

            // Must find the next following rule item that is not a delimiter to parse next string
            if (!nextRule.isDelimiter()) {
                continue;
            }

            var delimiterIndex = currentString.indexOf(nextRule.text);

            if (delimiterIndex < 0) {
                spans.push(new Span(currentString, currentRule));
                return spans;
            }

            // extract the info and update entire string
            var spanString = currentString.substring(0, delimiterIndex);
            if (spanString.length) { // use case for consecutive delimiters in string
                spans.push(new Span(spanString, currentRule));
            }
            spanString = currentString.substring(delimiterIndex, delimiterIndex + nextRule.text.length);
            spans.push(new Span(spanString, nextRule));

            // update string for next iteration
            currentString = currentString.substring(delimiterIndex + nextRule.text.length, currentString.length);
        }

        return spans;
    }

    /**
     * Viewmodel for preview string
     * @param string
     * @param ruleItem
     */
    function Span(string, ruleItem) {
        this.string = string;
        this.ruleType = ruleItem.type;
        this.value = ruleItem.label;
    }
}

function UINamingConventionsHelper() {

    /**
     * Get drag options for widgets that can be moved
     * @returns {boolean}
     */
    return {
        getParserSortableOptions: getParserSortableOptions,
        getSortableOptions: getSortableOptions
    };

    function getParserSortableOptions(onStart, onStop) {
        var object = getSortableOptions(onStart, onStop);
        object.axis = 'x';
        object.forcePlaceholderSize = true;
        return object;
    }
    function getSortableOptions(onStart, onStop) {
        const object = {
            containment: '#naming-convention-rule-modal',
            'ui-floating': true,
            handle: '.rule-item.orderable',
            connectWith: ".connected-rule-items",
            onStart: onStart,
            onStop: onStop,
        };

        object.start = (e, ui) => {
            object.sourceModelClone = ui.item.sortable.sourceModel.slice();
            object.onStart && object.onStart();
        };

        object.stop = (e, ui) => {
            if (
                ui.item.sortable.droptarget
                && e.target != ui.item.sortable.droptarget[0]
            ) {
                ui.item.sortable.sourceModel.length = 0;
                // clone the original model to restore the removed item
                Array.prototype.push.apply(
                    ui.item.sortable.sourceModel,
                    object.sourceModelClone
                );
                object.sourceModelClone = null;
            }
            object.onStop && object.onStop();
        };

        return object;
    }
}