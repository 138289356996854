'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
var $fileDownload = $.fileDownload;

angular.module('serviceclientmapping.services', [])

    .factory('ServiceClientMappingFactory', ServiceClientMappingFactory)
    .factory('ServiceClientMappingDataFactory', ServiceClientMappingDataFactory)
    .factory('ServiceClientMappingUIFactory', ServiceClientMappingUIFactory);

/**
 * @ngInject
 */
function ServiceClientMappingFactory(
    PubSub,
    ServiceClientMappingModelFactory,
    $ServiceClientMappingEvents,
    ServiceClientMappingResource,
    MessengerFactory,
    LoadingState,
    ExportReportFactory
) {

    return {
        applyMapping: applyMapping,
        downloadMappingHistory: downloadMappingHistory,
        getMappings: getMappings,
        saveMapping: saveMapping,
        closeResults: closeResults,
    };

    /**
     *
     * @param ruleId
     */
    function applyMapping(ruleId) {
        return ServiceClientMappingResource.applyMappings(ruleId);
    }

    /**
     *
     * @param entity
     * @param params
     * @returns {*}
     */
    function downloadMappingHistory(entity, params) {
        PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, {
            state: LoadingState.LOADING,
            text: 'Downloading Assignment',
            icon: 'icomoon-download'
        });

        return $fileDownload(ExportReportFactory.exportToExcel(_.extend({pageEntity: entity}, params)).getRequestedUrl(), {
            successCallback: function (json) {
                PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, LoadingState.DONE);
                return json;
            },
            failCallback: function (err) {
                PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, LoadingState.DONE);
                var errObj = JSON.parse($(err).html());
                throw(errObj);
            }
        });
    }

    /**
     *
     * @param id
     * @returns {*}
     */
    function getMappings(id) {
        return ServiceClientMappingResource.getMappings(id);
    }

    /**
     *
     * @param mapping
     */
    function saveMapping(mapping) {
        return ServiceClientMappingResource.save(mapping);
    }

    /**
     *
     */
    function closeResults() {
        PubSub.emit($ServiceClientMappingEvents.IS_RESULTS_ACTIVE, false);
    }
}

/**
 * @ngInject
 */
function ServiceClientMappingDataFactory() {
    /**
     * Used for current state params mapping id, and mapping log id
     * @type {{}}
     */
    var stateParams = {};

    return {
        setStateParams: setStateParams,
        getStateParams: getStateParams
    };

    /**
     *
     * @param {Object} params
     * @param {number|string} params.mapping_id id of mapping, can alternately be string 'test'
     * @param {number} params.id Specific log id
     * @param
     */
    function setStateParams(params) {
        stateParams = params;
    }

    /**
     *
     * @returns {{}}
     */
    function getStateParams() {
        return stateParams;
    }

}

/**
 * @ngInject
 */
function ServiceClientMappingUIFactory(
    PubSub,
    $ServiceClientMappingEvents,
    ServiceClientMappingModelFactory,
    UIFactory
) {

    return {
        initModal: initModal
    };


    /**
     * Proxy for initiating service client mapping edit
     * @param serviceClientMapping
     */
    function initModal(serviceClientMapping) {
        serviceClientMapping = serviceClientMapping || {};

        var model = ServiceClientMappingModelFactory.getServiceClientMapping(
            serviceClientMapping.id,
            serviceClientMapping.name,
            serviceClientMapping.service_id,
            serviceClientMapping.service_field,
            serviceClientMapping.client_field,
            serviceClientMapping.match_type,
            serviceClientMapping.truncate,
            serviceClientMapping.truncate_size,
            serviceClientMapping.user_id,
            serviceClientMapping.naming_convention_id,
            serviceClientMapping.naming_convention_name
        );

        if (model.client_field) {
            model.client_field = model.client_field.field || model.client_field;
        }

        if (model.service_field) {
            model.service_field = model.service_field.field;
        }

        PubSub.emit($ServiceClientMappingEvents.MAPPING_SELECT_READY, model.service_id);

        if (model.service_id) {
            PubSub.emit($ServiceClientMappingEvents.IS_ADDING, false);
        } else {
            PubSub.emit($ServiceClientMappingEvents.IS_ADDING, true);
        }

        PubSub.emit($ServiceClientMappingEvents.IS_READY, true);
        PubSub.emit($ServiceClientMappingEvents.INIT_MODAL, model);
        UIFactory.showModal('service-client-mapping-modal');
    }
}