'use strict';
import angular from 'angular';
import $ from 'jquery';
import EventBus from '../../../../../grok/src/modules/core/app/helpers/EventBus';
import { OnboardingEvent } from '../../../../../grok/src/modules/core/onboarding/onboarding.constants';

angular.module('billing.ctrls', [])

    .controller('BillingController', BillingController)
    .controller('BillingAddressController', BillingAddressController)
    .controller('BillingCreditCardController', BillingCreditCardController);

//
// Billing controller
//
BillingController.$inject = ['$scope', 'BillingService'];
function BillingController($scope, BillingService) {
}
//
// Billing address controller
//
BillingAddressController.$inject = ['$scope', 'BillingService'];
function BillingAddressController($scope, BillingService) {
}

//
// Billing credit card controller
//
BillingCreditCardController.$inject = ['$scope', '$state', '$cacheFactory', '$templateCache', 'BillingService', 'AppService'];
function BillingCreditCardController($scope, $state, $cacheFactory, $templateCache, BillingService, AppService) {

    $scope.billingState = function(){
        $cacheFactory.get('templates').removeAll();
        $state.go('billing');
    };

    $scope.redirectTo = function(url){
        window.location.href = url;
    };

    $scope.init = function(alreadyAgreedToTerms, stripeApiKey) {
        $scope.alreadyAgreedToTerms = alreadyAgreedToTerms.bool();
        Stripe.setPublishableKey(stripeApiKey);
    };

    $('#payment-form').submit(function(e) {
        if (!$scope.alreadyAgreedToTerms) {
            if (!$('#agree_to_terms').is(':checked'))
            {
                $.core.main.formErrorNotify('You need to agree to our Terms and Condition before submitting your credit card information.');
                e.preventDefault();
                return;
            }
        }
        Stripe.card.createToken($(this), $scope.stripeResponseHandler);
        // Prevent the form from submitting with the default action
        return false;
    });

    $scope.stripeResponseHandler = function(status, response) {
        var $form = $('#stripe-form');
        var $footer = $('footer');
        var $buttons = $footer.find('div.btn');
        if (response.error) {
            $.core.main.formErrorNotify(response.error.message);
        } else {
            $buttons.disableElement();
            // token contains id, last4, and card type
            var token = response.id;
            // Insert the token into the form so it gets submitted to the server
            $('#stripe_token').val(token);
            $('#expiration_month').val(response.card.exp_month);
            $('#expiration_year').val(response.card.exp_year);
            $('#last_4_digits').val(response.card.last4);
            $('#card_type').val(response.card.type);

            var url = $form.attr('action');
            var model = $form.serializeObject();
            AppService.submitForm(url, model).success(function(json) {
                $buttons.enableElement();
                if (json.status == 'success') {
                    $.core.main.hideFooter();
                    //Reset for form restore capabilities
                    $.core.main.initForm($form);
                    $.core.main.formHasChanged = false;

                    //Added a re-direct here to return users to the billing page after saving credit info
                    //the cached template for the billing route is removed so that the page refreshes with up to date info
                    $cacheFactory.get('templates').removeAll();
                    $state.go('billing');
                    $.core.main.notify(json.data.message, $.globals.notify.success);

                    if (window.isNUI) {
                        EventBus.signal(OnboardingEvent.ADDED_CREDIT_CARD);
                    }
                }
                else {
                    $.core.main.formErrorNotify(json);
                }
            });
        }
    };
}