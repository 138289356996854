'use strict';
import angular from 'angular';
import $ from 'jquery';
import initAngularTemplatedDirectives from '../../shared/scripts/app.services';

var LandingPageStateToNUIMapper = {
    welcome: '/welcome',
    overview: '/overview',
    leads: '/leads',
    connectionstatus: '/connectionstatus'
};

angular.module('connect.services', [])
    .factory('ConnectService', ConnectService);

// Connect Service
ConnectService.$inject = ['$rootScope', '$http', '$state', '$templateCache', 'AppFactory', 'PubSub'];
function ConnectService($rs, $http, $state, $templateCache, AppFactory, PubSub) {
    return {
        updateDashboard: function() {
            return $http.get($rs.util.mageUrl('setup/check_mappings')).success(function(json) {
                if (json.status == 'success') {
                    $http.get($rs.util.mageUrl('setup/generatedashboard')).success(function(json) {
                        if (json.status == 'success') {
                            var data = json.data;
                            const user = window.isNUI ? AppFactory.getUser() : $rs.user;
                            if (!user.isOnboarding) {
                                $.core.main.notify('We\'re currently fetching your data and will send you an email when it\'s ready.', $.globals.notify.success);
                            }
                            else {
                                user.isOnboarding = false;
                                user.justOnboarded = true; // Used to reinitialize the nav menu bindings when switching from onboarding to nav
                                if (window.isNUI) {
                                    AppFactory.setUser(user);
                                } else {
                                    $rs.user = user;
                                }
                                $.core.main.notify('We\'re currently fetching your data and will send you an email when it\'s ready.', $.globals.notify.success);
                            }

                            if (window.isNUI) {
                                PubSub.emit('OnboardingEvent:dashboard_generated');
                            }
                            $templateCache.removeAll();
                            initAngularTemplatedDirectives($templateCache);
                            if (window.isNUI && user.justOnboarded) {
                                $.core.main.hideAjaxMainLoading();
                                window.location.hash = '#/onboarding';
                            } else if (data.services_overview_shown) {
                                if (window.isNUI) {
                                    // @TODO: Sunil - Handle landing_page for NUI when it's implemented.
                                    $.core.main.hideAjaxMainLoading();
                                    window.location.hash = '#/overview';
                                } else {
                                    $state.go('overview');
                                }
                            } else if(data.default_landing_page) {
                                if(window.isNUI) {
                                    $.core.main.hideAjaxMainLoading();
                                }
                                if(!isNaN(data.default_landing_page)) {
                                    $state.go('dash',{id : data.default_landing_page});
                                } else {
                                    window.location.hash = window.isNUI ? LandingPageStateToNUIMapper[data.default_landing_page] || data.default_landing_page : data.default_landing_page;
                                }
                            } else {
                                if(window.isNUI) {
                                    $.core.main.hideAjaxMainLoading();
                                    window.location.hash = LandingPageStateToNUIMapper[data.landing_page];
                                } else if(!isNaN(data.landing_page)) {
                                    $state.go('dash',{id : data.landing_page});
                                } else {
                                    $state.go(data.landing_page);
                                }
                            }
                        }
                        else {
                            $.core.main.hideAjaxMainLoading();
                            $.core.main.notify('Couldn\'t fetch data', $.globals.notify.warning);
                        }
                    });
                }
                else {
                    $.core.main.hideAjaxMainLoading();
                    $.core.main.formErrorNotify(json, $.globals.notify.warning);
                }
            });
        }
    }
}
