import ClusterResource from '@/modules/ta/cluster/resources/ClusterResource';
import { BaseFormService } from '@/modules/core/form/services/BaseFormService';
import LegacyAssetResource from '@/modules/legacy/ui/resources/LegacyAssetResource';
import { dispatches } from '@/modules/core/app/helpers/store';

export class ClusterFormService extends BaseFormService {
  constructor() {
    super(ClusterResource);
  }

  /**
   * @param form {BaseForm}
   * @returns {Promise<*>}
   */
  async saveForm(form) {
    const result = await super.saveForm(form);
    dispatches.cluster.refreshClusterValues();
    dispatches.dashboard.setFilterOptions();
    return result;
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<*>}
   */
  async getForm(id, params = {}) {
    const [form, lightImageUrl, template, isInheritedDisabled] = await Promise.all([
      super.getForm(id, params),
      this.getLogoImage(id),
      this.getPptTemplate(id),
      typeof id !== 'undefined' && !Number.isNaN(id)
        ? this.isDisabledInheritedFormSelect(id)
        : false,
    ]);

    form[0].light_logo_image_url = lightImageUrl.data || null;
    form[0].original_light_logo_image_url = form[0].light_logo_image_url;

    if (form[0].inherited_whitelabel_cluster_id) {
      const whiteLabelImageUrl = await this.getLogoImage(form[0].inherited_whitelabel_cluster_id);
      form[0].light_logo_image_url = whiteLabelImageUrl.data || null;
    }
    form[0].ppt_template_url = template.data || null;
    form[0].is_inherited_whitelabel_disabled = isInheritedDisabled;
    return form;
  }

  async getSiblingWhiteLabels(params = {}) {
    return this.resource.getSiblingWhiteLabels(params);
  }

  async getClusterIdWhiteLabel(id) {
    return this.resource.getClusterIdWhiteLabel(id);
  }

  async isDisabledInheritedFormSelect(id) {
    const whiteLabelInfo = await this.resource.getClusterIdWhiteLabel(id);
    return whiteLabelInfo.cluster_id != null;
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<*>}
   */
  async getItem(id, params = {}) {
    const [data, lightImageUrl, template] = await Promise.all([
      super.getItem(id, params),
      this.getLogoImage(id),
      this.getPptTemplate(id),
    ]);

    const isInheritedDisabled = await this.isDisabledInheritedFormSelect(id);
    data.light_logo_image_url = lightImageUrl.data || null;
    data.ppt_template_url = template.data || null;
    data.is_inherited_whitelabel_disabled = isInheritedDisabled;
    return data;
  }

  async getLogoImage(id) {
    return LegacyAssetResource.getAssetUrl(`light_logo_cluster-${id}`);
  }

  async getPptTemplate(id) {
    return LegacyAssetResource.getAssetUrl(`ppt_template_cluster-${id}`, 'potx');
  }

  /**
   *
   * @param clusterId
   * @returns {Promise<*>}
   */
  async getNotificationProfilesByClusterId(clusterId = null) {
    return this.resource.getNotificationProfilesByClusterId(clusterId);
  }
}

export default new ClusterFormService();
