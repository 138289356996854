'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.email.resources', [])
    .factory('EmailResource', EmailResource)
    .factory('EmailDataResource', EmailDataResource);

function EmailResource(
    Restangular,
    ColumnValueType
) {
    var emailsoverview = Restangular.all('emailsoverview');
    var vars = data.one('vars');
    var values = data.one('values');

    /**
     * @param queryParams
     * @returns {*}
     */
    function getColumns(queryParams) {
        return emailsoverview.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @param dataSource
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        queryParams = _.extend({fields: fields}, queryParams || {});
        return values.one(ColumnValueType.DISTINCT).get(queryParams);
    }

    /**
     * Get emailsoverview for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return emailsoverview.getList(_.extend({
            all: true
        }, queryParams));
    }

    return {
        emailsoverview: emailsoverview,
        vars: vars,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData
    }
}

/**
 * @ngInject
 */
function EmailDataResource(
    Restangular,
    ColumnValueType
) {
    var resource = Restangular.all('email/data');
    var values = resource.one('values');

    return {
        getResource: getResource,
        getData: getData,
        getColumns: getColumns,
        getFieldValues: getFieldValues
    };

    /**
     *
     * @param queryParams
     * @returns {angular.IPromise<T>|*|undefined}
     */
    function getColumns(queryParams) {
        return resource.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get values for all possible distinct specific field
     * @param fields
     */
    function getFieldValues(fields) {
        return values.one(ColumnValueType.DISTINCT).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    function getResource() {
        return resource;
    }

    /**
     *
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        return resource.getList(queryParams);
    }
}
