'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

import dataSourceSelectPanelHtmlUrl from './datasource.select.panel.html';
import dataSourceDisplayHtmlUrl from './datasource.display.html';

angular.module('datasource.directives', [])

    .directive('datasourceSelect', datasourceSelect)
    .directive('serviceDataSourceSelect', serviceDataSourceSelect)
    .directive('categoryDataSourceSelect', categoryDataSourceSelect)
    .directive('dataviewDataSourceSelect', dataviewDataSourceSelect)
    .directive('categoryDataviewDataSourceSelect', categoryDataviewDataSourceSelect)
    .directive('columnSelect', columnSelect)
    .component('datasourceSelectPanel', {
        templateUrl: dataSourceSelectPanelHtmlUrl,
        bindings: {
            currentModule : '<',
            showTitles : '@',
            datasource: '=',
            metadata: '=',
            isAlert: '<',
            state: '='
        },
        controller: datasourceSelectPanelController,
        controllerAs: 'vm'
    })
    .component('datasourceDisplay', {
        templateUrl: dataSourceDisplayHtmlUrl,
        bindings: {
            datasource: '<',
            isEditing: '<'
        },
        controller: dataSourceDisplayController,
        controllerAs: 'vm'
    });

/**
 * Data source selection panel
 * @ngInject
 */
function datasourceSelectPanelController(
    $scope,
    $timeout,
    DataSourceType,
    CustomDataViewMessage
) {
    var vm = this;
    vm.getCustomDataViewMessageText = getCustomDataViewMessageText;
    vm.onChangeCallback = onChangeCallback;
    vm.onDataSourceSelectsContinueClicked = onDataSourceSelectsContinueClicked;

    $scope.$evalAsync(function() {
        vm.DataSourceType = DataSourceType;
        vm.showTitles = vm.showTitles ? vm.showTitles.bool() : true;
    });

    function getCustomDataViewMessageText() {
        return _.includes(CustomDataViewMessage.IDS, vm.datasource.id) && !vm.isAlert ?
            "Can't find the data set you need? Contact us so we can build a custom <b>" + vm.datasource.id_name + "</b> data view for you."
            : "";
    }

    function onChangeCallback() {
        // force reload of dataview select2
        var id = vm.datasource.id;
        vm.datasource.id = null;
        $timeout(function () {
            vm.datasource.id = id;
        }, 0);
    }

    /**
     * Continue button in the datasource selects creation page
     * Move on to next section of widget creation
     */
    function onDataSourceSelectsContinueClicked() {
        vm.state.isBuilding = true;
    }
}

/**
 * Selected data source display
 * @ngInject
 */
function dataSourceDisplayController(
    DataSourceFactory
) {
    var vm = this;
    vm.getIcon = function() {
        return DataSourceFactory.getDataSourceIcon(vm.datasource);
    };
    vm.getColor = function() {
        return DataSourceFactory.getDataSourceColor(vm.datasource);
    }
}

/**
 * Build select2 for data source selection
 * (note: datasource cannot be named dataSource because "data" prefixed directives are reserved for angular)
 * @ngInject
 */
function datasourceSelect(
    $timeout,
    $interval,
    AppFactory,
    WidgetFactory,
    gettextCatalog
) {
    return {
        restrict: 'A',
        controller: 'SelectDataSourceController',
        scope: {
            currentModule : '<',
            datasource: '=',
            state: '=',
            optionsOverride: '<',
            filterOptions: '<'
        },
        link: function(scope, el) {
            var options = _.assign({
                allowClear: false,
                autoOpen: true,
                placeholder: gettextCatalog.getString('Select a data category...'),
                width: '100%',
                showAll: false
            }, scope.optionsOverride);

            var animationFinished = false;
            var readyToOpen  = false;

            $timeout(function() {
                animationFinished = true;
            }, 600, false);

            var openSelect = (function() {
                if (options.autoOpen) {
                    return $interval(function() {
                        if (animationFinished && readyToOpen) {
                            $(el).select2('open');
                            if (options.autoOpen) {
                                $interval.cancel(openSelect);
                            }
                        }
                    }, 0, 0, false);
                } else {
                    return false;
                }
            })();

            // Need data_source values
            var dataSources = AppFactory.getDataSourceTypes();
            // Need to massage values to fit object property structure required by select2
            var moduleType = scope.currentModule || null;

            function formatData (value) {
                return {
                    id: value.type,
                    text: value.name,
                    icon: value.icon,
                    requires_group_by: value.requires_group_by,
                    requires_date_range: value.requires_date_range,
                    is_of_type_service: value.is_of_type_service,
                    type: value.type
                };
            }

            // Will provide all possible data sources IF the moduleType is null
            if (_.isNull(moduleType) || options.showAll) {
                if (scope.filterOptions) {
                    dataSources = _.filter(dataSources, function (service) {
                        return scope.filterOptions.contains(service.type);
                    });
                }
                options.data = _.map(dataSources, formatData);
            }
            else {
                var tempDataSources =  _.filter(dataSources, function(value) {
                    return value.type == moduleType;
                });
                options.data = _.map(tempDataSources, formatData);
            }

            el.select2(options);

            $(el).on('change', function() {
                scope.$evalAsync(function() {
                    scope.datasource.id = null;
                    scope.datasource.data_view = null;
                    scope.updateDataSourceSelect(el.select2('data'));
                    if (options.onChange) {
                        options.onChange(el.select2('data'));
                    }
                });
            });

            // Pre-selected values
            var selectedId = null;
            if (!_.isNull(moduleType)) {
                selectedId = moduleType;
            }
            else if (scope.datasource) {
                selectedId = scope.datasource.type;
            }
            if (!_.isNull(selectedId)) {
                // Clear open select interval
                if (options.autoOpen) {
                    $interval.cancel(openSelect);
                }
                var selectedValueObj = _.find(options.data, {id: selectedId});
                el.select2('data', selectedValueObj);
                scope.updateDataSourceSelect(el.select2('data'));
            }
            else {
                readyToOpen = true;
            }
        }
    }
}

/**
 * Build select2 for service data source selection
 * @ngInject
 */
function serviceDataSourceSelect(
    $AlertEvents,
    PubSub,
    $timeout,
    $interval,
    AppFactory,
    DashboardContextService,
    gettextCatalog
) {
    return {
        restrict: 'A',
        controller: 'DataSourceSelectorController',
        scope: {
            datasource: '=',
            optionsOverride: '<',
            onChangeCallback: '<',
            isAlert: '<',
            state: '=',
            getAllServices: '<'
        },
        link: function(scope, el) {
            var options = _.assign({
                allowClear: false,
                autoOpen: true,
                formatResult: function (item) {
                    if (!item.id) { return item.text; }
                    var icon = item.has_custom_icon ? 'custom-icon ' + item.icon : 'serviceicon-' + item.icon;
                    return $(
                        '<div><div class="service-square service-square-24" style="background-color:'+ item.color +'"><div class="icon ' + icon + '"></div></div> ' + item.text + '</div>'
                    );
                },
                placeholder: gettextCatalog.getString('Select a data source...'),
                width: '100%',
                excludeServiceChild: false
            }, scope.optionsOverride);

            var animationFinished = false;
            var readyToOpen = false;

            $timeout(function() {
                animationFinished = true;
            }, 600, false);

            var openSelect = (function() {
                if (options.autoOpen) {
                    return $interval(function() {
                        if (animationFinished && readyToOpen) {
                            $(el).select2('open');
                            if (options.autoOpen) {
                                $interval.cancel(openSelect);
                            }
                        }
                    }, 0, 0, false);
                } else {
                    return false;
                }
            })();

            options.formatSelection = options.formatResult;

            //Need to massage values to fit object property structure required by select2
            if (!options.data) {
                const services = DashboardContextService.isDemoModeEnabled()
                    ? AppFactory.getAllServices()
                    : AppFactory.getConnectedServices(scope.getAllServices);

                options.data = _.without(_.map(services, function (service) {
                    if (!options.excludeServiceChild || !service.child_of_service_id) {
                        return {
                          id: service.id,
                          text: service.name,
                          icon: service.icon,
                          color: service.color,
                          is_custom_service: service.is_custom_service,
                          has_custom_icon: service.has_custom_icon,
                          type: service.type
                        };
                    }
                }), undefined);
            }

            el.select2(options);
            el.select2('enable', !options.disabled);

            $(el).on('change', function() {
                scope.$evalAsync(function() {
                    scope.datasource.data_view = null;
                    scope.updateSelect(el.select2('data'));
                    scope.onChangeCallback && scope.onChangeCallback(el.select2('data'));
                    if (scope.isAlert) {
                        PubSub.emit($AlertEvents.UPDATE_DATASOURCE_ID);
                    }
                });
            });

            // Pre-selected values
            var selectedId = scope.datasource.id || null;

            if (!_.isNull(selectedId)) {
                // Clear open select interval
                if (options.autoOpen) {
                    $interval.cancel(openSelect);
                }
                var selectedValueObj = _.find(options.data, {id: selectedId});
                el.select2('data', selectedValueObj);
                scope.updateSelect(el.select2('data'));
                scope.onChangeCallback && scope.onChangeCallback(el.select2('data'));
            } else {
                // Wait for animation to finish before opening
                readyToOpen = true;
            }
        }
    }
}

/**
 * Build select2 for category data source selection
 * @ngInject
 */
function categoryDataSourceSelect(
    $timeout,
    $interval,
    AppFactory,
    gettextCatalog
) {
    return {
        restrict: 'A',
        controller: 'DataSourceSelectorController',
        scope: {
            datasource: '=',
            optionsOverride: '<',
            state: '=',
            onChangeCallback: '<'
        },
        link: function(scope, el) {
            var animationFinished = false;
            var readyToOpen = false;

            var options = _.assign({
                allowClear: false,
                autoOpen: true,
                formatResult: function (item) {
                    if (!item.id) { return item.text; }
                    return $(
                        '<div><div class="service-square service-square-24" style="background-color:'+ item.color +'"><div class="icon ' + item.icon + '" style="font-size:16px;"></div></div> ' + item.text + '</div>'
                    );
                },
                placeholder: gettextCatalog.getString('Select a channel...'),
                width: '100%'
            }, scope.optionsOverride);

            $timeout(function() {
                animationFinished = true;
            }, 600, false);

            var openSelect = (function() {
                if (options.autoOpen) {
                    return $interval(function() {
                        if (animationFinished && readyToOpen) {
                            $(el).select2('open');
                            if (options.autoOpen) {
                                $interval.cancel(openSelect);
                            }
                        }
                    }, 0, 0, false);
                } else {
                    return false;
                }
            })();

            options.formatSelection = options.formatResult;

            //Need to massage values to fit object property structure required by select2
            options.data = _.map(AppFactory.getAllCategories(), function (category) {
                return {
                    id: category.id,
                    text: category.name,
                    icon: category.icon,
                    color: category.color,
                    has_custom_icon: category.has_custom_icon
                };
            });

            el.select2(options);

            $(el).on('change', function() {
                scope.$evalAsync(function() {
                    scope.datasource.data_view = null;
                    scope.updateSelect(el.select2('data'));
                    scope.onChangeCallback && scope.onChangeCallback(el.select2('data'));
                });
            });

            // Pre-selected values
            var selectedId = scope.datasource.id || null;
            if (!_.isNull(selectedId)) {
                // Clear open select interval
                if (options.autoOpen) {
                    $interval.cancel(openSelect);
                }
                var selectedValueObj = _.find(options.data, {id: selectedId});
                el.select2('data', selectedValueObj);
                scope.updateSelect(el.select2('data'));
                scope.onChangeCallback && scope.onChangeCallback(el.select2('data'));
            }
            else {
                // Wait for animation to finish before opening
                readyToOpen = true;
            }
        }
    }
}

/**
 * Build select2 for service data view selection
 * @ngInject
 */
function dataviewDataSourceSelect(
    $AlertEvents,
    PubSub,
    $timeout,
    $interval,
    AppFactory,
    ServiceDataFactory,
    gettextCatalog
) {
    var defaultOptions = {
        allowClear: false,
        alwaysShow: false,
        autoOpen: true,
        data: {results: [], text: 'name'},
        formatResult: function (item) {
            if (!item.id) { return item.text; }
            var $html = $('<div>' + item.name + '</div>');
            if (item.is_geo) {
                $html.append('<div class="right"><div class="icon icomoon-geochart mt2"></div></div>');
            }
            return $html;
        },
        multiple: false,
        placeholder: gettextCatalog.getString('Select a data view...')
    };

    return {
        restrict: 'A',
        controller: 'DataViewSelectController',
        scope: {
            datasource: '=',
            state: '=',
            optionsOverride: '<',
            onChangeCallback: '<',
            isAlert: '<'
        },
        link: function(scope, el) {
            function initSelect() {
                var animationFinished = false;
                var readyToOpen = false;

                $timeout(function() {
                    animationFinished = true;
                }, 600, false);

                if (_.isNil(scope.datasource.id)) {
                    if (scope.optionsOverride && scope.optionsOverride.alwaysShow) {
                        el.select2(defaultOptions);
                    }
                    return;
                }

                ServiceDataFactory.dataViews(scope.datasource.id).get().then(function(dataViews) {
                    defaultOptions.data = {results: dataViews, text: 'name'};
                    var options = _.assign(defaultOptions, scope.optionsOverride);
                    options = $.core.main.getSelect2DefaultOptions(options);

                    el.select2(options);

                    var openSelect = (function() {
                        if (options.autoOpen) {
                            return $interval(function() {
                                if (animationFinished && readyToOpen) {
                                    $(el).select2('open');
                                    if (options.autoOpen) {
                                        $interval.cancel(openSelect);
                                    }
                                }
                            }, 0, 0, false);
                        } else {
                            return false;
                        }
                    })();

                    // Pre-selected values
                    var selectedId = scope.datasource.data_view || null;
                    if (!_.isNull(selectedId)) {
                        // Clear open select interval
                        if (options.autoOpen) {
                            $interval.cancel(openSelect);
                        }
                        var selectedValueObj = _.find(options.data.results, {id: selectedId});
                        el.select2('data', selectedValueObj);
                        scope.updateDataViewSelect(el.select2('data'));
                    }
                    else {
                        // Wait for animation to finish before opening
                        readyToOpen = true;
                    }
                });
            }

            $(el).on('change', function() {
                scope.$evalAsync(function() {
                    scope.updateDataViewSelect(el.select2('data'));
                    scope.onChangeCallback && scope.onChangeCallback(el.select2('data'));
                    if (scope.isAlert) {
                        PubSub.emit($AlertEvents.UPDATE_DATASOURCE_DATA_VIEW);
                    }
                });
            });

            // Reset dropdown and fetch new data when data source id changes
            scope.$watch('datasource.id', function(nV, oV) {
                if (AppFactory.invalidateWatch(nV, oV)) {
                    return;
                }
                el.select2('data', null);
                initSelect();
            });

            initSelect();
        }
    }
}

/**
 * Build select2 for category data view selection
 * @ngInject
 */
function categoryDataviewDataSourceSelect(
    $timeout,
    $interval,
    AppFactory,
    CategoryDataViewFactory,
    gettextCatalog
) {
    return {
        restrict: 'A',
        controller: 'DataViewSelectController',
        scope: {
            datasource: '=',
            state: '=',
            optionsOverride: '<',
            onChangeCallback: '<'
        },
        link: function(scope, el) {
            var options = _.assign({
                allowClear: false,
                autoOpen: true,
                data: {results: [], text: 'name'},
                formatResult: function (item) {
                    if (!item.id) { return item.text; }
                    return $('<div>' + item.name + '</div>');
                },
                multiple: false,
                placeholder: gettextCatalog.getString('Select a data view...')
            }, scope.optionsOverride);
            options = $.core.main.getSelect2DefaultOptions(options);

            function initSelect() {
                if (_.isUndefined(scope.datasource.id)) {
                    return;
                }

                var animationFinished = false;
                var readyToOpen = false;

                $timeout(function() {
                    animationFinished = true;
                }, 600, false);

                if (_.isNil(scope.datasource.id)) {
                    if (scope.optionsOverride && scope.optionsOverride.alwaysShow) {
                        el.select2(options);
                    }
                    return;
                }

                CategoryDataViewFactory.categoryDataView(scope.datasource.id).getList().then(function(dataViews) {
                    options = _.assign(options, {
                        data: {results: dataViews, text: 'name'},
                        formatResult: function (item) {
                            if (!item.id) { return item.text; }
                            return $('<div>' + item.name + '</div>');
                        },
                        multiple: false,
                        placeholder: gettextCatalog.getString('Select a data view...')
                    });
                    el.select2(options);

                    var openSelect = (function() {
                        if (options.autoOpen) {
                            return $interval(function() {
                                if (animationFinished && readyToOpen) {
                                    $(el).select2('open');
                                    if (options.autoOpen) {
                                        $interval.cancel(openSelect);
                                    }
                                }
                            }, 0, 0, false);
                        } else {
                            return false;
                        }
                    })();

                    // Pre-selected values
                    var selectedId = scope.datasource.data_view || null;
                    if (!_.isNull(selectedId)) {
                        if (options.autoOpen) {
                            $interval.cancel(openSelect);
                        }
                        var selectedValueObj = _.find(options.data.results, {id: selectedId});
                        el.select2('data', selectedValueObj);
                        scope.updateDataViewSelect(el.select2('data'));
                    }
                    else {
                        readyToOpen = true;
                    }
                });
            }

            $(el).on('change', function() {
                scope.$evalAsync(function() {
                    scope.updateDataViewSelect(el.select2('data'));
                    scope.onChangeCallback && scope.onChangeCallback(el.select2('data'));
                });
            });

            // Reset dropdown and fetch new data when data source id changes
            scope.$watch('datasource.id', function(nV, oV) {
                if (AppFactory.invalidateWatch(nV, oV)) {
                    return;
                }
                el.select2('data', null);
                initSelect();
            });

            initSelect();
        }
    }
}

/**
 *
 * @returns {{restrict: string, scope: {datasource: string, placeholder: string, selectedField: string, selectableColumns: string, optionsOverride: string}, link: link}}
 * @ngInject
 */
function columnSelect(
    LoadingState,
    AppFactory,
    DataSourceFactory,
    DataSourceColumnFactory,
    gettextCatalog
) {
    return {
        restrict: 'A',
        scope: {
            datasource: '<',
            placeholder: '@',
            selectedField: '=',
            selectableColumns: '<?',
            optionsOverride: '<'
        },
        link: function(scope, el) {
            var dataTypes = AppFactory.getDataTypes();
            var output = [];

            initSelect();

            function initSelect() {

                var queryParams = {
                    is_hidden: false
                };

                var buildSelect2 = function(columns, selectedColumns) {
                    var options = _.assign({
                        placeholder: scope.placeholder || gettextCatalog.getString('Select a metric...'),
                        multiple: false,
                        allowClear: false,
                        dropdownCssClass: 'column-select-dropdown',
                        formatResult: function (item) {
                            if (!item.id) { return item.text; }
                            var dataType = _.find(dataTypes, {key: item.format});

                            var html = '<label class="label">';
                            if (_.startsWith(dataType.icon, 'icon')) {
                                html += '<span class="icon '+ dataType.icon +'"></span>';
                            }
                            else {
                                html += '<span class="icon">'+ dataType.icon +'</span>';
                            }
                            html += '</label>';
                            html += '<span>' + item.text + '</span>';

                            return $(html);
                        }
                    }, scope.optionsOverride);
                    options.formatSelection = options.formatResult;

                    output = DataSourceColumnFactory.preProcessSelectColumns(columns, true);

                    _.each(selectedColumns, function(data) {
                        data.id = data.field;
                    });

                    options.data = output;

                    el.select2(options);

                    // Pre-selected values
                    var selectedId = scope.selectedField || null;
                    if (!_.isNull(selectedId)) {
                        // Since columns are broken down into gorups we need to flatten and merge the different groups
                        var mergedResults = _.flatten(_.map(output, function(item) {
                            return item.children;
                        }));
                        var selectedValueObj = _.find(mergedResults, {id: selectedId});
                        el.select2('data', selectedValueObj);
                    }
                };

                // selectableColumns already contains all the columns we need, no need to make any request
                if (_.size(scope.selectableColumns)) {
                    // Note: buildSelect2 expects selectableColumns (the first parameter) to be an array.
                    var selectableColumns = _.map(scope.selectableColumns);
                    buildSelect2(selectableColumns, scope.selectableColumns);
                }
                else {
                    scope.isLoadingColumns = LoadingState.FETCHING;
                    // The widgetFactoryConfig will help specify what kind of datasourced data config we need
                    DataSourceFactory.getColumns(scope.datasource, queryParams).then(function (columns) {
                        buildSelect2(columns);
                        scope.isLoadingColumns = LoadingState.DONE;
                    });
                }
            }

            $(el).on('change', function() {
                scope.$evalAsync(function() {
                    scope.selectedField = el.select2('data').id;
                });
            });
        }
    }
}
