import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import { WidgetType } from 'coreModules/design/widget/design.widget.constants';

angular.module('exportbuilder.dashboard.models', [])
    .factory('ExportBuilderDashboardModelFactory', ExportBuilderDashboardModelFactory);

/**
 * Exception when building report template models
 */
export class ReportModelError extends Error {}

/**
 * @ngInject
 */
function ExportBuilderDashboardModelFactory(
    PageOrientation,
    ReportElementTypes,
    ReportElementShapeTypes,
    ReportPageDesignOptionsFactory,
    PageSpecifications,
    PageAspectRatio,
    PageFormatConstants,
    DrawOption
) {

    var object = {
        getExportReportTemplateModel: getExportReportTemplateModel,
        getReportTemplateModel: getReportTemplateModel,
        getReportElementModel: getReportElementModel,
        getReportPageModel: getReportPageModel,
        getPanelViewModel: getPanelViewModel,
        getPanelViewState: getPanelViewState
    };

    /**
     * @constructor
     */
    function ReportTemplateModel(model) {
        var self = this;

        self.id = model.id || null;
        self.title = model.title || '';
        self.is_preview = !_.isUndefined(model.is_preview) ? model.is_preview : null;
        self.is_private = !_.isUndefined(model.is_private) ? model.is_private : null;
        self.is_favorite = !_.isUndefined(model.is_favorite) ? model.is_favorite : null;
        self.can_favorite = !_.isUndefined(model.can_favorite) ? model.can_favorite : false;
        self.metadata = model.metadata || {};
        self.orientation = model.orientation || PageOrientation.TYPE_LANDSCAPE;
        self.format = model.format || PageFormatConstants.STANDARD;
        self.number_of_pages = model.number_of_pages || 0;
        self.created_at = model.created_at || null;
        self.updated_at = model.updated_at || null;
        self.report_language = model.report_language || null;
        self.client_logo_url = model.client_logo_url || null;
        self.is_template = !_.isUndefined(model.is_template) ? model.is_template : false;

        self.tags = model.tags || null;
        self.user_id = model.user_id || null;
        self.user = model.user || null;
        self.user_image_metadata = model.user_image_metadata || null;
        self.shared_users = model.shared_users || null;
        self.cluster = model.cluster || null;
        self.client = model.client || null;

        self.client_id = model.client_id || null;
        self.client_name = model.client_name || null;
    
        self.client_group = model.client_group || null;
    
        self.client_group_id = model.client_group_id || null;
        self.client_group_name = model.client_group_name || null;
        
        self.can_be_copied = !_.isUndefined(model.can_be_copied) ? model.can_be_copied : false;
        self.can_be_deleted = !_.isUndefined(model.can_be_deleted) ? model.can_be_deleted : false;
        self.can_be_edited = !_.isUndefined(model.can_be_edited) ? model.can_be_edited : false;
        self.has_granular_permissions = model.has_granular_permissions;
        self.granular_access_levels = model.granular_access_levels || {};
        self.global_filters = model.global_filters || {};
        let format = PageAspectRatio.STANDARD;
        if(model && model.format) {
            format = model.format;
        }
        let aspectRatio =  format === PageFormatConstants.WIDESCREEN ? PageAspectRatio.WIDESCREEN : PageAspectRatio.STANDARD;


        /**
         * @type {ReportPageModel[]}
         */
        self.pages = model.pages || [];

        /**
         * show_on_every_page elements
         * @type {ReportElementModel[]}
         */
        self.elements = model.elements || [];

        // Dynamic properties
        self.formattedCreatedAt = moment.unix(self.updated_at).format('MMM Do YYYY');

        self.getReportType = function() {
            return model.type;
        }

        self.isLandscape = function () {
            return self.orientation === PageOrientation.TYPE_LANDSCAPE;
        };

        self.getPageHeight = function () {
            return self.isLandscape()
                ? PageSpecifications.WIDTH / aspectRatio
                : PageSpecifications.WIDTH;
        };

        self.getPageWidth = function () {
            return self.isLandscape()
                ? PageSpecifications.WIDTH
                : PageSpecifications.WIDTH / aspectRatio
        };


        self.getRandomThemeColor = function () {
            var min = 0;
            var max = self.metadata.chart_palette.length - 1;
            var index = Math.floor(Math.random() * (max - min + 1)) + min;
            return self.metadata.chart_palette[index];
        };

        self.hasElements = function () {
            if (self.elements.length) {
                return true;
            }

            var getPages = _.memoize(function () {
                return self.pages;
            });

            var hasElement = false;
            _.each(getPages(), function (page) {
                if (page.elements.length && !page.metadata.isHidden) {
                    hasElement = true;
                    return false;
                }
            });

            return hasElement;
        };

        self.isAllPagesHidden = function () {
            return self.pages.every((page) => page.metadata.isHidden)
        }

        self.lastPageIndex = function () {
            return self.pages.length - 1;
        };

        self.getLastPage = function () {
            return self.pages[self.pages.length - 1];
        }
    }

    /**
     * @constructor
     */
    function ReportElementModel(model) {
        var self = this;

        self.id = model.id || null;
        self.page_id = model.page_id || null;
        self.report_id = model.report_id || null;
        self.type = model.type || null;
        self.widget_id = model.widget_id || null;
        self.x_position = model.x_position || 0;
        self.y_position = model.y_position || 0;
        self.width = model.width || 0;
        self.height = model.height || 0;
        self.z_index = _.isUndefined(model.z_index) ? 0 : model.z_index;
        self.show_on_every_page = model.show_on_every_page || false;
        self.snap_to_grid = model.snap_to_grid || false;
        self.metadata = model.metadata || {};

        // for unit testing
        self.is_deleted = !_.isUndefined(model.is_deleted) ? model.is_deleted : false;

        // Dynamic properties
        self.isEditing = model.isEditing || false;
        self.widget = model.widget || null;

        if (self.widget) {
            self.widget.is_export = true;
        }

        self.getWidth = function () {
            return self.width
        };

        self.getHeight = function () {
            return self.height
        };

        self.getFormat = function () {
            return self.snap_to_grid ? '%' : 'px';
        };

        self.isTypeWidget = function() {
            return self.type === ReportElementTypes.WIDGET
        };

        self.isWidgetUsingSampleData = function() {
            return self.isTypeWidget()
                && self.widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA]
        };

        self.isTypeImage = function() {
            return self.type === ReportElementTypes.IMAGE;
        };

        self.isTypeShape = function() {
            return self.type === ReportElementTypes.SHAPE;
        };

        self.isTypeIcon = function() {
            return self.type === ReportElementTypes.ICON;
        };

        self.isTypeText = function() {
            return self.type === ReportElementTypes.TEXT;
        };

        self.isShapeSquare = function () {
            return self.metadata.shape_type && self.metadata.shape_type === ReportElementShapeTypes.SQUARE;
        };

        self.isShapeCircle = function () {
            return self.metadata.shape_type && self.metadata.shape_type === ReportElementShapeTypes.CIRCLE;
        };
        
        self.isGeoWidget = function () {
            return self.widget && self.widget.type === WidgetType.GEOCHART;
        };

        self.hasWidgetFilters = () => {
            return self.isTypeWidget() 
            && self.widget.metadata.dynamic
            && !_.isEmpty(self.widget.metadata.dynamic.filters);
        };
    }

    ReportElementModel.prototype.setFocus = function() {
        this.focused = true;
        this.isEditing = true;
    };
    ReportElementModel.prototype.setUnfocus = function() {
        this.focused = false;
        this.isEditing = false;
    };

    /**
     * @constructor
     */
    function ReportPageModel(model) {
        var self = this;

        if (_.isNil(model.report_id) || _.isNil(model.page_index)) {
            throw new ReportModelError('report_id/page_index is mandatory');
        }

        self.id = model.id || null;
        self.title = model.title || '';
        self.elements = model.elements || [];
        self.report_id = model.report_id || null;
        self.page_index = model.page_index || 0;
        self.is_cover_page = !_.isUndefined(model.is_cover_page) ? model.is_cover_page : false;
        self.is_back_page = !_.isUndefined(model.is_back_page) ? model.is_back_page : false;
        self.metadata = _.isEmpty(model.metadata)
            ? ReportPageDesignOptionsFactory.getDefaultPageMetadata()
            : model.metadata;
        
        if (_.isEmpty(self.metadata.design_options)) {
            self.metadata.design_options = ReportPageDesignOptionsFactory.getDefaultPageDesignOptions();
        }
        self.is_executive_summary_page = !_.isUndefined(model.is_executive_summary_page) ? model.is_executive_summary_page : false;
        self.executive_summary_enabled = !_.isUndefined(model.executive_summary_enabled) ? model.executive_summary_enabled : false;

        // for unit testing
        self.is_deleted = !_.isUndefined(model.is_deleted) ? model.is_deleted : false;
    }

    function PanelViewModel() {
        var self = this;

        self.selectedCluster = null;
        self.selectedClient = null;
        self.selectedClientGroup = null;
        self.selectedSmartCampaign = null;
    }

    function PanelViewState() {
        var self = this;

        self.isLoadingDataPanel = false;
        self.refreshFilters = false
    }

    /**
     * Used to parse report to a report model for export
     * @param report
     * @returns {ReportTemplateModel}
     */
    function getExportReportTemplateModel(report) {
        report.pages = _.map(report.pages, function (page) {
            page = new ReportPageModel(page);
            page.elements = _.map(page.elements, function (element) {
                element = new ReportElementModel(element);
                if (element.widget) {
                    element.widget.is_export = true;

                    if (!element.widget.metadata.chart_palette) {
                        element.widget.metadata.chart_palette = report.metadata.chart_palette
                    }

                }
                return element;
            });
            return page;
        });
        return new ReportTemplateModel(report);
    }

    function getReportTemplateModel(model) {
        return new ReportTemplateModel(model);
    }

    function getReportElementModel(model) {
        return new ReportElementModel(model);
    }

    function getReportPageModel(model) {
        return new ReportPageModel(model);
    }

    function getPanelViewModel() {
        return new PanelViewModel()
    }

    function getPanelViewState() {
        return new PanelViewState()
    }

    return object;
}