'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('lineitems.services', ['core.iohelper'])
    .factory('LineItemFactory', LineItemFactory)
    .factory('LineItemDataGridFactory', LineItemDataGridFactory);

/**
 * @ngInject
 */
function LineItemFactory(
    Restangular,
    ColumnValueType
) {
    var lineitems = Restangular.all('lineitems');
    var vars = lineitems.one('vars');
    var values = lineitems.one('values');


    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        return lineitems.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @dataSource unused
     * @queryParams any additional query params
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        return values.one(ColumnValueType.DISTINCT).get(
            _.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));
    }


    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return lineitems.getList(queryParams);
    }

    return {
        lineitems: lineitems,
        vars: vars,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData
    }
}

/**
 * @ngInject
 */
function LineItemDataGridFactory(
    IODefaultLinkColumns,
    IODefaultLinkColumnsRenderer,
    LineItemColumns
) {
    return {
        getDTOptions: getDTOptions
    };


    /**
     * Return service specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.customRenders[LineItemColumns.ID] = IODefaultLinkColumnsRenderer.renderIoLineItemLinkColumn;
        dtOptions.customRenders[IODefaultLinkColumns.ORDER_ID] = IODefaultLinkColumnsRenderer.renderIoOrderIdLinkColumn;
        return dtOptions;
    }

}