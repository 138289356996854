import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import { DashboardFilterItem } from '@/modules/core/dashboard/models/DashboardFilterItem';

export class DashboardFilters extends BaseModel {
  /**
   * @var {DashboardFilterItem}
   */
  client;

  /**
   * @var {DashboardFilterItem}
   */
  client_group;

  /**
   * @var {DashboardFilterItem}
   */
  cluster;

  /**
   * @var {DashboardFilterItem}
   */
  smart_campaign;

  constructor(model = {}) {
    super();

    this.client = DashboardFilterItem.factory(model[DataSourceType.CLIENT]);
    this.client_group = DashboardFilterItem.factory(model[DataSourceType.CLIENT_GROUP]);
    this.cluster = DashboardFilterItem.factory(model[DataSourceType.CLUSTER]);
    this.smart_campaign = DashboardFilterItem.factory(model[DataSourceType.SMART_CAMPAIGN]);
  }

  /**
   * @returns {boolean}
   */
  hasFilters() {
    return !!(
      this.client.values.length ||
      this.client_group.values.length ||
      this.cluster.values.length ||
      this.smart_campaign.values.length
    );
  }

  /**
   * @returns {boolean}
   */
  isClientFilter() {
    return !!this.client.values.length;
  }

  /**
   * @returns {boolean}
   */
  isClientGroupFilter() {
    return !!this.client_group.values.length;
  }

  /**
   * @returns {boolean}
   */
  isClusterFilter() {
    return !!this.cluster.values.length;
  }

  /**
   * @returns {boolean}
   */
  isSmartCampaignFilter() {
    return !!this.smart_campaign.values.length;
  }

  /**
   * @returns {*}
   */
  getActiveFilter() {
    let activeFilter = null;
    if (this.client.values.length) {
      activeFilter = this.client;
    } else if (this.client_group.values.length) {
      activeFilter = this.client_group;
    } else if (this.cluster.values.length) {
      activeFilter = this.cluster;
    } else if (this.smart_campaign.values.length) {
      activeFilter = this.smart_campaign;
    }
    return activeFilter ? activeFilter.values[0] : null;
  }

  getFilters() {
    const filters = {};
    filters[DataSourceType.CLIENT] = this.client;
    filters[DataSourceType.CLIENT_GROUP] = this.client_group;
    filters[DataSourceType.CLUSTER] = this.cluster;
    filters[DataSourceType.SMART_CAMPAIGN] = this.smart_campaign;
    return filters;
  }
}
