import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import BrandMappingResource from '@/modules/core/session/resources/BrandMappingResource';

export class BrandMappingService extends BaseService {
  getMapping() {
    return BrandMappingResource.getData();
  }
}

export default new BrandMappingService();
