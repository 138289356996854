'use strict';
import angular from 'angular';
import './details.modal.constants';
import './details.modal.models';
import './details.modal.services';
import './details.modal.components';

angular.module('design.details', [
    'design.details.constants',
    'design.details.models',
    'design.details.services',
    'design.details.components'
]);