import { isNil, isEmpty } from 'lodash';
import { WidgetContext } from '@/modules/ta/widget/models/context/WidgetContext';
import { getters, dispatches } from '@/modules/core/app/helpers/store';

export class DashboardWidgetContext extends WidgetContext {
  /**
   * @override
   *
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  applyContext(widget, config) {
    config.params.widget_page_id = getters.dashboardDrilldown.getDashboardId();
  }

  /**
   * @param {Widget} widget
   * @param drawOption
   */
  evaluateDrawOption(widget, drawOption) {
    const parentDrawOption = this.getDrawOption(drawOption, widget.layout_id);
    if (widget.metadata.draw_options[drawOption].overridden || isNil(parentDrawOption)) {
      return widget.metadata.draw_options[drawOption];
    }

    return parentDrawOption;
  }

  /**
   *
   * @param drawOption
   * @param layoutId
   * @returns {*}
   */
  getDrawOption(drawOption, layoutId = null) {
    return getters.dashboardLayout.getDrawOptions(layoutId)?.[drawOption];
  }

  /**
   *
   * @param widget
   * @returns {array}
   */
  getChartPalette(widget) {
    if (!isEmpty(widget?.metadata.chart_palette)) {
      return widget.metadata.chart_palette;
    }

    const { metadata } = getters.dashboard.getDashboard();

    return metadata.chart_palette;
  }

  /**
   * @param {Widget} widget
   */
  setParentProperties(widget) {
    widget.layout_id = getters.dashboard.getCurrentLayoutId();
  }

  /**
   * @param widgetId
   */
  setWidgetIdAsRendered(widgetId) {
    dispatches.dashboardExport.setWidgetAsRendered(widgetId);
  }

  /**
   * @returns {boolean}
   */
  isDemoModeEnabled() {
    return getters.dashboardDrilldown.getIsDemoMode();
  }
}
