import { map, first, isEmpty, find, isNil, union } from 'lodash';
import moment from 'moment';
import { DateRange } from '@/modules/core/daterange/models/DateRange';
import DateRangeService from '@/modules/core/daterange/services/DateRangeService';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import { MomentDateFormat } from '@/modules/core/daterange/daterange.constants';
import { WidgetType } from '@/modules/ta/widget/widget.constants';

export class WidgetProbeDataService {
  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setDateRangeQueryParam(widget, config) {
    const dateObj = new DateRange({
      start_date: 0,
      end_date: config.dateRange.end_date,
    });

    config.params.daterange = DateRangeService.buildDateQueryParam(dateObj);
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setSortQueryParam(widget, config) {
    config.params.sort = `-${this._getPrimaryDateField(widget)}`;
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setFieldsQueryParam(widget, config) {
    let fields;
    if (widget.metadata.data_source.type === DataSourceType.CATEGORY_DATA) {
      fields = map(widget.metadata.data_columns.selected, 'field');
    } else {
      fields = [this._getPrimaryDateField(widget)];
    }

    const filterSetId = widget.metadata.filter_set_id;
    if (!isNil(filterSetId)) {
      fields = union(fields, map(widget.metadata.dynamic.filters, 'field'));
    }

    config.params.fields = fields.join();
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setGroupByQueryParam(widget, config) {
    if (!isEmpty(widget.metadata.data_columns.grouped)) {
      config.params.groupby = map(this._getActiveGroupedColumns(widget), 'field').join();
    } else {
      config.params.aggregate = true;
    }
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setTimeGroupingQueryParam(widget, config) {
    const primaryDateColumn = find(this._getActiveGroupedColumns(widget), 'is_primary_date_field');

    if (!isEmpty(primaryDateColumn)) {
      config.params.timegrouping = widget.metadata.time_grouping;
    }
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @param {GenericDataModel[]} data
   * @returns {DateRange|boolean}
   */
  formatResponse(widget, config, data) {
    const dateDifference =
      moment(config.dateRange.end_date).unix() - moment(config.dateRange.start_date).unix();
    const primaryDateField = this._getPrimaryDateField(widget);
    if (!isEmpty(first(data)) && first(data).current_period[primaryDateField]) {
      const date = first(data).current_period[primaryDateField];
      const unixEnd = date;
      const unixStart = date - dateDifference;

      return new DateRange({
        start_date: moment.unix(unixStart).utc().format(MomentDateFormat.ISO),
        end_date: moment.unix(unixEnd).utc().format(MomentDateFormat.ISO),
      });
    }

    return false;
  }

  _getPrimaryDateField(widget) {
    return widget.metadata.data_source.is_of_type_service ? 'log_date' : 'timestamp';
  }

  /**
   * Return an array of active grouped columns for a widget
   *  NOTE: not all grouped columns are always active
   *
   * @param {Widget} widget
   * @returns {Array}
   */
  _getActiveGroupedColumns(widget) {
    const activeGroupedColumns = [];
    if (!widget.metadata.data_columns.grouped) {
      return [activeGroupedColumns];
    }

    switch (widget.type) {
      case WidgetType.TYPE_DATAGRID:
        return widget.metadata.data_columns.grouped;

      case WidgetType.TYPE_BARCHART:
      case WidgetType.TYPE_LINECHART:
      case WidgetType.TYPE_COMBINATIONCHART:
        activeGroupedColumns.push(widget.metadata.data_columns.grouped[0]);
        if (widget.metadata.is_multi_grouped) {
          activeGroupedColumns.push(widget.metadata.data_columns.grouped[1]);
        }

        break;

      default:
        activeGroupedColumns.push(first(widget.metadata.data_columns.grouped));
        break;
    }
    return activeGroupedColumns;
  }
}
