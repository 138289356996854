<template>
  <ChartWrapper
    v-bind="$props"
    :is-data-empty="isDataEmpty"
    :context="context"
    class="widget-display"
    width="100%"
  />
</template>

<script>
import { useGaugeChart } from '@/modules/core/charts/am5/gauge/composables/useGaugeChart';
import ChartWrapper from '@/modules/core/charts/am5/base/components/ChartWrapper.vue';
import { createChartContext } from '@/modules/core/charts/am5/base/composables/createChartContext';
import { isEmpty, isNil } from 'lodash';
import { WidgetEvents } from '@/modules/ta/widget/widget.constants';
import { BasePropsMixin } from '@/modules/core/charts/am5/base/mixins/BasePropsMixin';

export default {
  name: 'GaugeChart',
  mixins: [BasePropsMixin],
  components: {
    ChartWrapper,
  },
  setup(props, componentContext) {
    const context = createChartContext(props.config);
    const { refresh, onBind, onFrameEnded } = context();
    const { initGaugeChart } = useGaugeChart(context);

    onBind(() => {
      initGaugeChart();
    });

    onFrameEnded(() => {
      componentContext.emit(WidgetEvents.FRAME_ENDED);
    });

    return {
      context,
      refresh,
      initGaugeChart,
    };
  },
  computed: {
    isDataEmpty() {
      return isNil(this.config.data) || isEmpty(this.config.data);
    },
  },
  watch: {
    config(value) {
      this.refresh(value);
    },
  },
};
</script>
