import { commits } from '@/modules/core/app/helpers/store';
import { ExportConfigFactory } from '@/modules/core/export/services/ExportConfigFactory';

export const actions = {
  setVueBasedChartRender({}, render = true) {
    commits.export.setVueBasedChartRender(render);
  },
  setConfig({}, config) {
    commits.export.setConfig({ config: ExportConfigFactory.build(config) });

    commits.export.setIsExporting({});
  },
  setExportReady({}, exportReady = true) {
    commits.export.setExportReady(exportReady);
  },
};
