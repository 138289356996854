import { Bullet } from '@amcharts/amcharts5';
import { Constant, SeriesType } from '@/modules/core/charts/am5/charts.constants';
import { useBullets as useGenericBullets } from '@/modules/core/charts/am5/base/composables/useBullets';
import { useBulletShape } from '@/modules/core/charts/am5/line/composables/useBulletShape';

export function useBullets(context) {
  const { root, chart } = context();
  const { hidePlotContainerOutsideBullets } = useGenericBullets(context);
  const { createBulletShape } = useBulletShape(context);

  function createBullets() {
    chart.value.series.each((series) => {
      const seriesProps = series.get(Constant.USER_DATA);
      if (seriesProps.bullet.enabled && seriesProps.seriesType !== SeriesType.COLUMN) {
        series.bullets.push(() => {
          const bulletShape = createBulletShape(series, seriesProps.bullet);
          hidePlotContainerOutsideBullets(bulletShape);
          return Bullet.new(root.value, {
            sprite: bulletShape,
          });
        });
      }
    });
  }

  return {
    createBullets,
  };
}
