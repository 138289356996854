import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { useCategoryAxis } from '@/modules/core/charts/am5/base/composables/axis/useCategoryAxis';
import { useValueAxis } from '@/modules/core/charts/am5/base/composables/axis/useValueAxis';
import { useDurationAxis } from '@/modules/core/charts/am5/base/composables/axis/useDurationAxis';
import { useDateAxis } from '@/modules/core/charts/am5/base/composables/axis/useDateAxis';

export function useAxes(context) {
  const { chart, config } = context();

  const { createCategoryAxis } = useCategoryAxis(context);
  const { createValueAxis } = useValueAxis(context);
  const { createDurationAxis } = useDurationAxis(context);
  const { createDateAxis } = useDateAxis(context);

  const MAX_AXIS_LABEL_WIDTH = 120;

  function getValueAxis(index = 0) {
    return chart.value[isRotated() ? Constant.X_AXES : Constant.Y_AXES].getIndex(index);
  }

  function getCategoryAxis(index = 0) {
    return chart.value[isRotated() ? Constant.Y_AXES : Constant.X_AXES].getIndex(index);
  }

  function isRotated() {
    return config.value.isRadialBar || config.value.isRotated;
  }

  function getATagToUrlString(tagString) {
    const anchorMatch = tagString.match(/<a[^>]+href="([^"]+)"[^>]*>(.*?)<\/a>/i);
    if (anchorMatch) {
      return getDomainNameFromUrl(anchorMatch);
    }
    return tagString;
  }

  function getDomainNameFromUrl(anchorString) {
    // Create a URL object using the extracted URL
    const url = new URL(anchorString[1]);
    // Return the hostname (domain name)
    return url.hostname;
  }

  function configureAxis(axis, props) {
    const renderer = axis.get(Constant.RENDERER);
    const { isLogScaled } = config.value;
    renderer.opposite = props.opposite ?? renderer.opposite;
    renderer.inversed = props.inversed ?? renderer.inversed;
    if (!isLogScaled) {
      axis.set(Constant.MIN, props.min ?? axis.min);
      axis.set(Constant.MAX, props.max ?? axis.max);
    }
  }

  function configureRenderer(renderer, props) {
    renderer.labels.template.setAll({
      fontSize: 12,
      oversizedBehavior: Constant.TRUNCATE,
      maxWidth: MAX_AXIS_LABEL_WIDTH,
      ellipsis: '...',
    });

    const isHtmlATagString = [ColumnFormat.FORMAT_URL, ColumnFormat.FORMAT_CALLBACK].includes(
      props.dataItemFormat
    );

    if (isHtmlATagString) {
      renderer.labels.template.adapters.add(Constant.TEXT, (text, target) => {
        const { dataItem } = target;
        const { dataContext } = dataItem || {};
        if (dataContext && dataContext[props.category]) {
          return getATagToUrlString(dataContext[props.category]);
        }
        return text;
      });
    }

    if (props.displayTicks) {
      renderer.ticks.template.setAll(
        omitNils({
          visible: true,
          strokeOpacity: 1,
          stroke: color(props.tickColor),
          strokeWidth: 1,
        })
      );
    } else {
      renderer.ticks.template.set(Constant.VISIBLE, false);
    }

    renderer.grid.template.setAll(
      omitNils({
        visible: !props.hideGridLines,
      })
    );

    renderer.setAll(
      omitNils({
        strokeOpacity: props.axisLineStrokeOpacity,
        strokeWidth: props.axisLineStrokeWidth,
        stroke: props.axisLineStroke,
        cellStartLocation: props.cellStartLocation,
      })
    );

    renderer.labels.template.setAll(
      omitNils({
        fontSize: props.labelFontSize,
        rotation: props.labelRotation,
        maxWidth: props.labelMaxWidth,
        tooltipText: props.labelToolTipText,
        forceHidden: !props.showLabels,
        fill: color(props.labelColor),
      })
    );
  }

  function getAxis(props, renderer) {
    const { dataItemFormat } = props;
    switch (dataItemFormat) {
      case ColumnFormat.FORMAT_DATETIME:
      case ColumnFormat.FORMAT_DATE:
        return createDateAxis(props, renderer);
      case ColumnFormat.FORMAT_STRING:
      case ColumnFormat.FORMAT_CALLBACK:
      case ColumnFormat.FORMAT_URL:
        return createCategoryAxis(props, renderer);
      case ColumnFormat.FORMAT_TIME:
        return createDurationAxis(props, renderer);
      case ColumnFormat.FORMAT_INTEGER:
      case ColumnFormat.FORMAT_CURRENCY:
      case ColumnFormat.FORMAT_DECIMAL:
      case ColumnFormat.FORMAT_PERCENT:
        return createValueAxis(props, renderer);
      default:
        return createCategoryAxis(props, renderer);
    }
  }
  return {
    getValueAxis,
    getCategoryAxis,
    getAxis,
    configureRenderer,
    configureAxis,
    getATagToUrlString,
  };
}
