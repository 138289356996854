import angular from 'angular';
import $ from 'jquery';

import exportBuilderShortcutsModalHtmlUrl from './exportbuilder.shortcuts.modal.html';

angular.module('exportbuilder.dashboard.components')
    .component('exportBuilderShortcutsModal', {
        bindings: {
        },
        templateUrl: exportBuilderShortcutsModalHtmlUrl,
        controller: ExportBuilderShortcutsModal,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderShortcutsModal(
    PubSub,
    UIFactory,
    ExportBuilderShortcutsModalUIService,
    $ExportBuilderShortcutsModalEvents,
    ReportStudioTemplateDataService
) {
    var vm = this;

    vm.serviceState = ExportBuilderShortcutsModalUIService.getState();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onClose = onClose;

    function $onInit() {
        vm.report = ReportStudioTemplateDataService.getReport();
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function onClose() {
        ExportBuilderShortcutsModalUIService.setIsActive(false);
        UIFactory.hideModal('export-builder-shortcuts-modal');
    }

    function _onOpen() {
        ExportBuilderShortcutsModalUIService.setIsActive(true);
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderShortcutsModalEvents.OPEN, _onOpen)
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderShortcutsModalEvents.OPEN, _onOpen)
    }

}