import {
  GaugeBigNumberInteriorPosition,
  GaugeBigNumberBelowPositionYPosMap,
  GaugeBigNumberLengthToWidthMap,
  GaugeBigNumberPosition,
  GaugeBigNumberInteriorPositionYPosMap,
} from '@/modules/core/charts/am5/charts.constants';
import { RoundedRectangle } from '@amcharts/amcharts5';
import { useBigNumberTextLabels } from '@/modules/core/charts/am5/gauge/composables/useBigNumberTextLabels';
import { GaugeHand } from '@/modules/core/charts/am5/gauge/models/GaugeHand';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';

export function useBigNumber(context) {
  const { root, config } = context();
  const { createLabelWithSettings } = useBigNumberTextLabels(context);
  const { formatNumber } = useNumberFormatter(context);

  function createInteriorBigNumbers(isComparison = false) {
    const axisRange = isComparison ? config.value.comparisonAxisRanges : config.value.axisRanges;

    if (!axisRange) {
      return;
    }

    const chartIndex = isComparison ? 1 : 0;
    const chartValue = root.value.container.children._values[chartIndex];

    axisRange.forEach((range) => {
      if (
        !range.bigNumber ||
        ![GaugeBigNumberPosition.INSIDE, GaugeBigNumberPosition.BELOW].includes(
          range.bigNumber.position
        )
      ) {
        return;
      }

      // ensure we use the rounded number for the width as it'll be adding commas, etc
      const roundedNumber = formatNumber(range.bigNumber.value, range.bigNumber.format);

      const bigNumberSettings = {
        x: getXPos(range.bigNumber.interiorPosition),
        y: getYPos(range.bigNumber),
        width: getWidth(roundedNumber),
        background: RoundedRectangle.new(root.value, {
          fill: range.fill,
        }),
      };

      createLabelWithSettings(
        chartValue,
        new GaugeHand({
          value: range.value,
          fill: range.fill,
          bigNumber: range.bigNumber,
        }),
        bigNumberSettings
      );
    });
  }

  /**
   * ensure the big number when it's "interior" is in a position that's less likely
   * to be covered by the pointer
   */
  function getYPos(bigNumber) {
    const { gaugeRange } = config.value;
    const { interiorPosition, position } = bigNumber;

    // inside we just drag up a little
    if (position === GaugeBigNumberPosition.INSIDE) {
      return gaugeRange >= 240 && GaugeBigNumberInteriorPositionYPosMap[gaugeRange]
        ? GaugeBigNumberInteriorPositionYPosMap[gaugeRange]
        : -55;
    }

    // "center" should be a little above the centre spot
    if (
      position === GaugeBigNumberPosition.INSIDE &&
      interiorPosition === GaugeBigNumberInteriorPosition.CENTER
    ) {
      return -75;
    }

    if (gaugeRange > 180 && GaugeBigNumberBelowPositionYPosMap[gaugeRange]) {
      return GaugeBigNumberBelowPositionYPosMap[gaugeRange];
    }

    // otherwise keep just below the centre line
    return interiorPosition === GaugeBigNumberInteriorPosition.CENTER ? 30 : 10;
  }

  function getXPos(interiorPosition) {
    const { gaugeRange } = config.value;

    if (interiorPosition === GaugeBigNumberInteriorPosition.LEFT) {
      return gaugeRange >= 210 ? -40 : -70;
    }

    if (interiorPosition === GaugeBigNumberInteriorPosition.RIGHT) {
      return gaugeRange >= 210 ? 40 : 70;
    }

    return 0;
  }

  function getWidth(value) {
    const string = value.toString();
    if (GaugeBigNumberLengthToWidthMap[string.length]) {
      return GaugeBigNumberLengthToWidthMap[string.length];
    }

    return 80;
  }

  return {
    createInteriorBigNumbers,
  };
}
