import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('exportbuilder.init', [])
    .service('ExportBuilderInitializerService', ExportBuilderInitializerService);

/**
 * @ngInject
 */
function ExportBuilderInitializerService(
    DesignFactory,
    PageOrientation,
    ExportBuilderResource,
    ExportBuilderDashboardService,
    ExportBuilderHelper,
    ExportElementResource,
    ReportElementTypes,
    ExportBuilderDashboardUtilService,
    ReportPageDesignOptionsFactory,
    WidgetSize,
    ReportTemplateType,
    DataSourceType,
    PageFormatConstants
) {

    return {
        initWithPage: initWithPage
    };

    /**
     * Builds and set ups platform for importing widgets from a dashboard settings to Report Studio
     * @param page
     * @param {string} [designTemplate] Design Template Id to merge into new report
     * @returns {*}
     */
    function initWithPage(page, designTemplate = null) {
        var sections = _.map(page.layouts, function (layout) {
            return layout;
        });
        var title = page.title || "Report";

        var metadata = angular.copy(page.metadata);
        metadata.draw_options = angular.copy(sections[0].metadata.draw_options);
        const format = designTemplate && designTemplate.format ? designTemplate.format : PageFormatConstants.STANDARD;
        //do save, to get the builder id
        var report = {
            format,
            title: title,
            type: ReportTemplateType.TYPE_CUSTOM,
            orientation: PageOrientation.TYPE_LANDSCAPE,
            metadata: metadata,
            is_preview: true,
        };

        if (page.client_id) {
            report.client_id = page.client_id;
        } else if (page.client_group_id) {
            report.client_group_id = page.client_group_id;
        }

        if (page.cluster) {
            report.cluster_id = page.cluster.id;
        }

        if (!report.client_id && page.metadata.selected_entity?.type === DataSourceType.CLIENT) {
            report.client_id = page.metadata.selected_entity.id;
        } else if (!report.client_group_id && page.metadata.selected_entity?.type === DataSourceType.CLIENT_GROUP) {
            report.client_group_id = page.metadata.selected_entity.id;
        }

        if (report.client_id || report.client_group_id || report.cluster_id) {
            report.is_preview = false;
        }

        sections = _.sortBy(sections, ['display_order']);

        return ExportBuilderResource.save(report).then(function (newReport) {
            ExportBuilderDashboardService.resetData(newReport);

            return importWidgetsToReportWithSections(newReport, sections)
                .then(function () {
                    if (designTemplate && designTemplate.is_predefined) {
                        return mergeDesignTemplate(newReport.id, designTemplate.id)
                            .then(() => {
                                return newReport;
                            });
                    } else if (designTemplate && !designTemplate.is_predefined) {
                        return mergeCustomDesignTemplate(newReport.id, designTemplate.id)
                            .then(() => {
                                return newReport;
                            });
                    }
                    return newReport;
                });
        });
    }

    function mergeDesignTemplate(reportId, designTemplateId) {
        return ExportBuilderResource.mergeDesignTemplate(reportId, designTemplateId);
    }

    function mergeCustomDesignTemplate(reportId, designTemplateId) {
        return ExportBuilderResource.mergeCustomDesignTemplate(reportId, designTemplateId);
    }

    /**
     * Imports an array of widgets into the grid
     * @param {*} report
     * @param {*} sections
     */
    function importWidgetsToReportWithSections(report, sections) {
        var pages = [];
        _.each(sections, function (section) {
            var newPages = importWidgets(section.widgets);
            _.each(newPages, function (page) {
                page.report_id = report.id;
                page.title = section.title;
                page.page_index = 0; // needs to be set first
                page.page_index += pages.length;
                page.metadata = ReportPageDesignOptionsFactory.getDefaultPageMetadata();
                _.each(page.elements, function (item) {
                    _moveY(item);
                    _moveX(item);
                });
                pages = pages.concat(page);
            });
        });
        return ExportElementResource.batchCreate(report, pages)
    }

    function _moveY(item) {
        let direction = 1;
        const yPositionBuffer = 6;
        const heightRemoveBuffer = 4;
        const dimensionUnit = item.y_position / ExportBuilderDashboardUtilService.getGridUnitPercent();
        const heightDimension = Math.floor(item.height / ExportBuilderDashboardUtilService.getGridUnitPercent());
        const floorDimensionUnit = Math.floor(dimensionUnit);

        item.y_position = (floorDimensionUnit + yPositionBuffer) * ExportBuilderDashboardUtilService.getGridUnitPercent();

        // if element arrives at the page border or passed, must remove the diff in grid units
        if ((floorDimensionUnit + yPositionBuffer + heightDimension) >= ExportBuilderDashboardUtilService.getMaxHeight()) {
            direction = (floorDimensionUnit + yPositionBuffer + heightDimension) - ExportBuilderDashboardUtilService.getMaxHeight();
            direction = Math.max(direction, 2);
            direction += heightRemoveBuffer;
        }

        item.height = (heightDimension - direction) * ExportBuilderDashboardUtilService.getGridUnitPercent();
    }

    function _moveX(item) {
        let direction = 1;
        const dimensionUnit = item.x_position / ExportBuilderDashboardUtilService.getGridUnitPercent();
        const widthDimension = Math.floor(item.width / ExportBuilderDashboardUtilService.getGridUnitPercent());
        const floorDimensionUnit = Math.floor(dimensionUnit);

        item.x_position = (floorDimensionUnit + direction) * ExportBuilderDashboardUtilService.getGridUnitPercent();

        // if element arrives at the page border, must remove another grid unit
        if (widthDimension + floorDimensionUnit === ExportBuilderDashboardUtilService.getMaxWidth()) {
            direction = 2;
        }

        item.width = (widthDimension - direction) * ExportBuilderDashboardUtilService.getGridUnitPercent();
    }

    function importWidgets(widgets) {
        var pages = {};

        var unaffectedWidgets = [];

        //read widgets to place them
        _.each(widgets, function(widget) {
            var metadata = widget.metadata;

            // remove cached data to remove circular json error
            metadata.dynamic.predefined_data = null;
            metadata.dynamic.raw_data = null;

            if (!_.isObject(metadata.styles_options) || !_.size(metadata.styles_options)) {
                metadata.styles_options = {};
            }

            if (!_.isObject(metadata.design_options) || !_.size(metadata.design_options)) {
                metadata.design_options = {};
            }

            //get predefined data from the stored raw_data
            if (!_.isUndefined(metadata.dynamic) && !_.isNull(metadata.dynamic.raw_data)) {
                metadata.dynamic.predefined_data = metadata.dynamic.raw_data;
            }

            //prepare the chart id based on the actual id
            widget.chartId = 'export-chart-' + widget.id;

            unaffectedWidgets.push(widget);
        });

        //the dashboard is considered new if no widget has a page_index property yet
        var touched = !unaffectedWidgets.length;

        var topOffset = 0; // Offset vale from top in px
        var prevTopOffset = 0; // Previous offset vale from top in px
        var prevTop = 0; // Previous widget top in px
        var prevLeft = 0; // Previous widget left in px

        //order unaffected widgets by display_order
        unaffectedWidgets = _.sortBy(unaffectedWidgets, ['display_order']);

        var $container = $('<div id="temp-widget-export-container" style="width: 12px;"></div>');
        $container.appendTo(document.body);

        _.each(unaffectedWidgets, function (widget) {
            $container.append('<div id="temp-widget-'+widget.id+'" class="grid-item" data-display-order='+widget.display_order+' style="width:'+ widget.width +'px; height:'+ widget.height +'px"></div>');
        });

        $container.isotope({
            masonry: {
                columnWidth: 1
            }
        });

        _.each(unaffectedWidgets, function(widget) {
            var $dashboardWidget = $container.find('#temp-widget-' + widget.id);
            var position = $dashboardWidget.position();
            var parentWidth = $dashboardWidget.parent().width();

            var widgetHeight = widget.height > WidgetSize.MAX_HEIGHT ? WidgetSize.MAX_HEIGHT : widget.height;

            // If current widget top equals to previous widget top, it means they are in the same row
            // and we should assign prevTopOffset to currentOffset, otherwise assign topOffset
            var currentOffset = prevTop == position.top ? prevTopOffset : topOffset;
            // Top for the current widget
            var currentTop = (((position.top + currentOffset)) * 100 / WidgetSize.MAX_HEIGHT); //%

            if (currentTop > 1) {
                currentTop -= 1; // edge case when widget is breaking the page at 100.122 %;
            }

            var endOfPagePercentage = (parseInt(currentTop / 100) + 1) * 100; //%
            var widgetHeightPercentage = widget.height * 100 / WidgetSize.MAX_HEIGHT; //%

            // If the bottom of current widget exceeds the current page, we need to push it to the next page
            // and update currentTop
            if (currentTop + widgetHeightPercentage > endOfPagePercentage) {
                // Only update prevTopOffset and topOffset if there's a new row
                if (prevLeft >= position.left) {
                    topOffset += (endOfPagePercentage - currentTop) / 100 * WidgetSize.MAX_HEIGHT ;
                    prevTopOffset = topOffset;
                }
                currentTop = endOfPagePercentage;
            }
            prevLeft = position.left;
            prevTop = position.top;

            //save props directly into the widget to avoid having to
            // refind them later
            widget.y_position = currentTop; //%
            widget.x_position = position.left * 100 / parentWidth; //%
            widget.widthPercent = ExportBuilderHelper.getSizeForGrid(widget.width * 100 / WidgetSize.MAX_WIDTH); //%
            widget.heightPercent = ExportBuilderHelper.getSizeForGrid(widgetHeight * 100 / WidgetSize.MAX_HEIGHT); //%
        });

        var shiftTopPerPage = {}; //shift when jumping between pages
        var lastShiftedTop = -1; //will hold the current top value for the shifted widget
        // Calculate the vertical space between widgets
        var verticalSpacing = 2; // Adjust this value as needed

        //read unaffected widgets and try and place them
        _.each(unaffectedWidgets, function(widget) {

            var params = {
                width: widget.widthPercent,
                height: widget.heightPercent
            };

            var pageIndex;

            // first opening of the builder:
            // try to place widgets just like they are on the dashboard
            if (!touched) {

                // left
                params.x_position = ExportBuilderHelper.getSizeForGrid(widget.x_position);

                // top

                //calculate current page based on top position
                pageIndex = parseInt(widget.y_position / 100);
                //get shift from object if necessary
                var shift = shiftTopPerPage[pageIndex] || 0;
                //expected top is the original top and all the shifts for pages
                var widgetTop = widget.y_position - ((pageIndex * 100)  - shift);

                if (_.isUndefined(shiftTopPerPage[pageIndex])) {

                    //try to find a widget in that row that is too big for the current page
                    var sameRowWidgets = _.filter(unaffectedWidgets, {y_position: widget.y_position});
                    var breakerWidget = _.find(sameRowWidgets, function (sameRowWidget) {
                        return sameRowWidget.y_position + shift + widget.heightPercent > (pageIndex + 1) * 100;
                    });

                    if (breakerWidget) {
                        //save shift for the current page (add sum of other shifts to avoid recalculating it every time)
                        var sumOfAllOtherShifts = _.sum(_.values(shiftTopPerPage)) || 0;
                        shiftTopPerPage[pageIndex] = (((pageIndex + 1) * 100) - breakerWidget.y_position) + sumOfAllOtherShifts;

                        //add shift to expected top
                        widgetTop = widget.y_position - (((pageIndex + 1) * 100)  - shiftTopPerPage[pageIndex]);

                        //go to next page
                        pageIndex++;

                        //save last shifted top to apply it to other widgets on the same row
                        lastShiftedTop = widget.y_position;
                    }
                }
                else if (lastShiftedTop === widget.y_position) {
                    //add shift to expected top
                    widgetTop = widget.y_position - (((pageIndex + 1) * 100)  - shiftTopPerPage[pageIndex]);

                    //go to next page
                    pageIndex++
                }
                var verticalSpacingMul = (_.isUndefined(pages[pageIndex]) || (sameRowWidgets && sameRowWidgets.length > 1 && widget.top === 0)) ? 0 : 1;

                params.y_position =  ExportBuilderHelper.getSizeForGrid(widgetTop + (verticalSpacing * verticalSpacingMul));

            }
            // if there's an unaffected widget, it means a widget has been added after pdf builder was used:
            // try to find the first available position for the  widget
            else {
                var position = ExportBuilderDashboardService.getFirstAvailablePosition(pages, widget);

                pageIndex = position.page_index;

                params.y_position = ExportBuilderHelper.getSizeForGrid(position.y_position);
                params.x_position = ExportBuilderHelper.getSizeForGrid(position.x_position);
            }

            //ensure the page already exists
            if (_.isUndefined(pages[pageIndex])) {
                pages[pageIndex] = [];
            }

            var item = ExportBuilderDashboardService.initNewItem(ReportElementTypes.WIDGET, params, widget, ExportBuilderDashboardService.nextAvailableZIndexForItems(pages[pageIndex]));

            pages[pageIndex].push(item);
        });

        // // actually add the pages to the main page array
        var toBeSavedPages = [];
        _.each(ExportBuilderHelper.getArrayFromRawPagesObject(pages), function (items) {
            //assign current page to all items
            _.each(items, function(item) {
                item.page_index = toBeSavedPages.length
            });

            toBeSavedPages.push({
                elements: items
            });
        });

        // remove temp DOM element
        $container.remove();

        return toBeSavedPages;
    }
}
