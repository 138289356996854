'use strict';
import angular from 'angular';
import pageLibraryModalHtmlUrl from './page.library.modal.html';
import pageLibraryCarouselItemHtmlUrl from './page.library.carousel.item.html';
import pageLibraryDetailedItemHtmlUrl from './page.library.detailed.item.html';
import publishSelectionModalHtmlUrl from './publish.selection.modal.html';
import pagePublishModalHtmlUrl from './page.publish.modal.html';
import pencilImageUrl from 'coreModules/shared/images/pencil.png';
import widgetImageUrl from 'coreModules/shared/images/widget.png';

angular.module('page.library.components', [])
    .component('pageLibraryModal', {
        templateUrl: pageLibraryModalHtmlUrl,
        controllerAs: 'vm',
        bindings: false,
        controller: PageLibraryModalController
    })
    .component('pageLibraryCarouselItem', {
        templateUrl: pageLibraryCarouselItemHtmlUrl,
        bindings: {
            item: '<'
        },
        controller: PageLibraryCarouselItemController,
        controllerAs: 'vm'
    })
    .component('pageLibraryDetailedItem', {
        templateUrl: pageLibraryDetailedItemHtmlUrl,
        controllerAs: 'vm',
        bindings: false,
        controller: PageLibraryDetailedItemController
    })
    .component('publishSelectionModal', {
        bindings: false,
        templateUrl: publishSelectionModalHtmlUrl,
        controller: PublishSelectionModalController,
        controllerAs: 'vm'
    })
    .component('pagePublishModal', {
        bindings: false,
        templateUrl: pagePublishModalHtmlUrl,
        controller: PagePublishModalController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function PageLibraryModalController(
    $element,
    $timeout,
    PubSub,
    UIFactory,
    $PageLibraryEvents,
    PageFactory,
    LibraryFactory,
    LibraryUIFactory,
    LibraryModelFactory,
    PageLibraryFactory,
    gettextCatalog,
    LibrarySearchGroup
) {
    var vm = this;
    vm.isNUI = window.isNUI;

    /**
     * @type {LibraryModalState}
     */
    vm.state = LibraryModelFactory.getLibraryModalState();

    /**
     * @type {LibraryModalData}
     */
    vm.data = LibraryModelFactory.getLibraryModalData();

    /**
     * @type {LibrarySearchBoxConfig}
     */
    vm.searchBox = LibraryModelFactory.getLibrarySearchBoxConfig();
    vm.searchBox.selectOptions.dropdownCssClass += ' grouped-2-columns';
    vm.searchBox.selectOptions.placeholder = gettextCatalog.getString('Filter by data source or tag...');
    vm.titleSearchBox = LibraryModelFactory.getLibraryTitleSearchBoxConfig();
    vm.shouldShowSearchResults = false;

    /**
     * @type {LibraryCarouselOptions}
     */
    vm.owlCarouselOptions = LibraryModelFactory.getLibraryCarouselOptions();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.addNewDashboard = addNewDashboard;
    vm.isShowingItemDetails = isShowingItemDetails;
    vm.toggleSearchBox = toggleSearchBox;
    vm.triggerSearch = triggerSearch;
    vm.showSearchResults = showSearchResults;
    vm.canSearch = canSearch;
    vm.clearSearch = clearSearch;
    vm.canFilter = canFilter;
    vm.clearFilter = clearFilter;
    vm.onClose = _onClose;
    vm.clearSearchAction = clearSearchAction;

    function $onInit() {
        _registerEvents();
        LibraryUIFactory.bindModalAnimation($element);
        vm.itemComponent = '<page-library-carousel-item data-item="item"></page-library-carousel-item>';
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function addNewDashboard() {
        PageFactory.$newPage();
    }

    /**
     * @returns {boolean}
     */
    function isShowingItemDetails() {
        return LibraryFactory.getIsShowingItemDetails();
    }

    function toggleSearchBox() {
        vm.state.isSearchBoxActive = !vm.state.isSearchBoxActive;
    }

    function triggerSearch() {
        $timeout(function() {
            if (!canSearch() && !canFilter()) {
                vm.data.searchedItems = [];
                vm.shouldShowSearchResults = false;
                return false;
            }
            vm.state.isSearching = true;
            vm.state.isLoading = true;
            vm.data.searchedItems = [];
            var queryParams = LibraryFactory.resolveSearchQueryFromSearchValues(vm.searchBox.selectedValues);
            if (!_.isEmpty(vm.titleSearchBox.value)) {
                queryParams[LibrarySearchGroup.TITLE] = vm.titleSearchBox.value;
            }
            PageLibraryFactory.getSearchedPages(queryParams).then(function (items) {
                vm.state.isSearching = false;
                vm.state.isLoading = false;
                vm.data.searchedItems = items;
                _setConnectedState(items);
                vm.shouldShowSearchResults = true;
            });
        }, 0);
    }

    /**
     * @returns {boolean}
     */
    function showSearchResults() {
        return vm.shouldShowSearchResults;
    }

    /**
     * @returns {boolean}
     * @private
     */
    function canSearch() {
        return vm.titleSearchBox.value.length > 1;
    }

    function clearSearch() {
        $timeout(function() {
            vm.titleSearchBox.value = '';
            _evaluateClear();
        }, 0);
    }

    /**
     * @returns {boolean}
     * @private
     */
    function canFilter() {
        return vm.searchBox.selectedValues.length > 0;
    }

    function clearFilter() {
        angular.element('#page-library-modal div.library-header div.select2-container').select2('data', null);
        $timeout(function() {
            vm.searchBox.selectedValues = [];
            _evaluateClear();
        }, 0);
    }

    /**
     * Checks if we should show original results or re trigger a search
     * @private
     */
    function _evaluateClear() {
        if (canSearch() && canFilter()) {
            vm.data.searchedItems = [];
            vm.shouldShowSearchResults = false;
        } else {
            triggerSearch();
        }
    }

    function _init() {
        _reset();
        _showModal();
        vm.state.isActive = true;

        if (_.isEmpty(vm.data.mostUsedItems)) {
            vm.state.isLoading = true;
            PageLibraryFactory.getLibraryPages().then(function (data) {
                vm.state.isLoading = false;
                vm.data.mostUsedItems = data.mostUsedItems;
                vm.data.recentlyUploadedItems = data.recentlyUploadedItems;
                _setConnectedState(vm.data.mostUsedItems);
                _setConnectedState(vm.data.recentlyUploadedItems);

                PageLibraryFactory.getSearchableValues().then(function (values) {
                    vm.searchBox.values = values;
                });
            });
        }
    }

    function _reset() {
        clearSearch();
        vm.state = LibraryModelFactory.getLibraryModalState();
        vm.data.searchedItems = [];
        vm.shouldShowSearchResults = false;
        LibraryFactory.setIsShowingItemDetails(false);
    }

    function _clearAllItems() {
        vm.data.mostUsedItems = [];
        vm.data.recentlyUploadedItems = [];
    }

    /**
     * @param items
     * @private
     */
    function _setConnectedState(items) {
        _.each(items, function(item) {
            item.isConnected = _.isEmpty(item.disconnected_service_ids);
        });
    }

    function _showModal() {
        LibraryUIFactory.showModal('page-library-modal');
    }

    function _onClose() {
        _reset();
    }

    /**
     * Used for Empty placeholder clear button
     */
    function clearSearchAction() {
        clearSearch();
        clearFilter();
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($PageLibraryEvents.INIT_MODAL, _init);
        PubSub.on($PageLibraryEvents.CLEAR_ALL_ITEMS, _clearAllItems);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($PageLibraryEvents.INIT_MODAL, _init);
        PubSub.off($PageLibraryEvents.CLEAR_ALL_ITEMS, _clearAllItems);
    }
}

/**
 * @ngInject
 */
function PageLibraryCarouselItemController(
    PubSub,
    LibraryFactory,
    $PageLibraryEvents
) {
    var vm = this;
    vm.selectItem = selectItem;
    vm.hasThumbnail = hasThumbnail;
    vm.getEmptyThumbnailImage = getEmptyThumbnailImage;

    function selectItem() {
        LibraryFactory.setIsShowingItemDetails(true);
        PubSub.emit($PageLibraryEvents.SET_ITEM_DETAILS, vm.item);
    }

    /**
     * @returns {boolean}
     */
    function hasThumbnail() {
        return !_.isNull(vm.item.layouts[0].thumbnail_metadata);
    }

    /**
     * @returns {boolean}
     */
    function getEmptyThumbnailImage() {
        return widgetImageUrl;
    }
}

/**
 * @ngInject
 */
function PageLibraryDetailedItemController(
    $state,
    PubSub,
    UIFactory,
    DataSourceFactory,
    DesignFactory,
    WidgetFactory,
    $PageLibraryEvents,
    LibraryFactory,
    LibraryUIFactory,
    LibraryModelFactory,
    LayoutLibraryFactory,
    PageLibraryFactory,
    gettextCatalog
) {
    var vm = this;

    /**
     * @type {LibraryDetailedItemState}
     */
    vm.state = LibraryModelFactory.getLibraryDetailedItemState();

    /**
     * @type {LibraryDetailedItemData}
     */
    vm.data = LibraryModelFactory.getLibraryDetailedItemData();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.goBack = goBack;
    vm.getConnectedBadgeTooltipText = getConnectedBadgeTooltipText;
    vm.getOrderedWidgets = getOrderedWidgets;
    vm.getWidgetDataSourceColor = getWidgetDataSourceColor;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getWidgetTypeIcon = getWidgetTypeIcon;
    vm.getWidgetTitle = getWidgetTitle;
    vm.getDownloadText = getDownloadText;
    vm.changeLayout = changeLayout;
    vm.usePageAsTemplate = usePageAsTemplate;
    vm.goToConnectServices = goToConnectServices;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function goBack() {
        _reset();
    }

    /**
     * @returns {string}
     */
    function getConnectedBadgeTooltipText() {
        return vm.data.isConnected
            ? gettextCatalog.getString('All widgets in this dashboard have connected data sources')
            : gettextCatalog.getString('At least one widget in this dashboard has an unconnected data source. Click "Connect Data Source" below to configure it.');
    }

    function getOrderedWidgets() {
        return vm.data.currentLayout
            ? _.orderBy(vm.data.currentLayout.widgets, 'display_order')
            : [];
    }

    /**
     * @param metadata
     */
    function getWidgetDataSourceColor(metadata) {
        return DataSourceFactory.getDataSourceColor(metadata.data_source);
    }

    /**
     * @param metadata
     */
    function getDataSourceIcon(metadata) {
        return DataSourceFactory.getDataSourceIcon(metadata.data_source);
    }

    /**
     * @param widgetType
     */
    function getWidgetTypeIcon(widgetType) {
        return WidgetFactory.getWidgetType(widgetType).icon;
    }

        /**
         * @param widget
         */
        function getWidgetTitle(widget) {
            return DesignFactory.getWidgetTitle(widget);
        }

    /**
     * @returns {string}
     */
    function getDownloadText() {
        return gettextCatalog.getString('Use Template');
    }

    /**
     * @param index
     */
    function changeLayout(index) {
        _setCurrentLayoutWidgets(vm.data.selectedItem.layouts[index]);
    }

    function usePageAsTemplate() {
        vm.state.isSaving = true;
        PageLibraryFactory.usePageAsTemplate(vm.data.selectedItem.id).then(function(newPageId) {
            _hideModal();
            $state.go('dash', {id: newPageId});
            DesignFactory.getNavPageList().then(function() { DesignFactory.$refreshMenu(); });
            _reset();
        });
    }

    function goToConnectServices() {
        $state.go('service.manage.open', {id: vm.data.selectedItem.disconnected_service_ids[0]}).then(function() {
            _hideModal();
        });
    }

    /**
     * @param item
     * @private
     */
    function _init(item) {
        vm.data.selectedItem = item;
        vm.data.isConnected = _.isEmpty(item.disconnected_service_ids);
        _setCurrentLayoutWidgets(_.first(vm.data.selectedItem.layouts));
    }

    /**
     * @param layout
     * @private
     */
    function _setCurrentLayoutWidgets(layout) {
        vm.state.isLoading = true;
        LayoutLibraryFactory.getLayoutWidgets(layout.id).then(function(widgets) {
            vm.state.isLoading = false;
            layout.widgets = widgets;
            vm.data.currentLayout = layout;

            DesignFactory.props.hasVariableHeightWidgets = false;
        });
    }

    /**
     * @private
     */
    function _reset() {
        LibraryFactory.setIsShowingItemDetails(false);
        vm.data = LibraryModelFactory.getLibraryDetailedItemData();
        vm.state = LibraryModelFactory.getLibraryDetailedItemState();
    }

    function _hideModal() {
        LibraryUIFactory.hideModal('page-library-modal');
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($PageLibraryEvents.SET_ITEM_DETAILS, _init);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($PageLibraryEvents.SET_ITEM_DETAILS, _init);
    }
}

/**
 * @ngInject
 */
function PublishSelectionModalController(
    PubSub,
    UIFactory,
    LibraryPublishModelFactory,
    $LayoutLibraryEvents,
    $PageLibraryEvents
) {
    var vm = this;

    /**
     * @type {LibraryPublishSelectionModalState}
     */
    vm.state = LibraryPublishModelFactory.getLibraryPublishSelectionModalState();

    /**
     * @type {LibraryPublishSelectionModalData}
     */
    vm.data = LibraryPublishModelFactory.getLibraryPublishSelectionModalData();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.setPageForPublishing = setPageForPublishing;
    vm.setLayoutForPublishing = setLayoutForPublishing;
    vm.closeModal = closeModal;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _initSelection(data) {
        _reset();
        vm.data.currentPage = data.page;
        vm.data.currentLayout = data.layout;
        vm.state.isActive = true;

        UIFactory.showModal('publish-selection-modal', {
            backdrop: true,
            keyboard: true
        });
    }

    function setPageForPublishing() {
        closeModal();
        PubSub.emit($PageLibraryEvents.INIT_PUBLISH_MODAL, vm.data.currentPage);
    }

    function setLayoutForPublishing() {
        closeModal();
        PubSub.emit($LayoutLibraryEvents.INIT_PUBLISH_MODAL, vm.data.currentLayout);
    }

    function closeModal() {
        UIFactory.hideModal('publish-selection-modal');
    }

    /**
     * @private
     */
    function _reset() {
        vm.data = LibraryPublishModelFactory.getLibraryPublishSelectionModalData();
        vm.state = LibraryPublishModelFactory.getLibraryPublishSelectionModalState();
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($PageLibraryEvents.INIT_PUBLISH_SELECTION_MODAL, _initSelection);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($PageLibraryEvents.INIT_PUBLISH_SELECTION_MODAL, _initSelection);
    }
}

/**
 * @ngInject
 */
function PagePublishModalController(
    PubSub,
    UIFactory,
    DesignFactory,
    PageTagFactory,
    $PageLibraryEvents,
    PageLibraryPublishFactory,
    PageCopyOptions,
    LibraryPublishModelFactory,
    LibraryPublishFactory,
    TagPickerSelectConstant,
    gettextCatalog
) {
    var vm = this;
    vm.PageCopyOptions = PageCopyOptions;

    /**
     * @type {LibraryPublishModalState}
     */
    vm.state = LibraryPublishModelFactory.getLibraryPublishModalState();

    /**
     * @type {LibraryPublishModalData}
     */
    vm.data = LibraryPublishModelFactory.getLibraryPublishModalData();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.publishPage = publishPage;
    vm.showCopyOptions = showCopyOptions;
    vm.onCancel = onCancel;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * @param model
     */
    function publishPage(model) {
        vm.state.isSaving = true;
        LibraryPublishFactory.publish(PageLibraryPublishFactory, model, vm.data).then(function () {
            vm.state.isSaving = false;
            UIFactory.notify.showSuccess(gettextCatalog.getString('Dashboard successfully published to library'));
            if (window.isNUI) {
                const PageToPublish = _.extend(vm.data, model);
                PubSub.emit('SegmentEvents', {
                    event: 'PublishToLibraryEvent',
                    payload: {item: PageToPublish, layout: DesignFactory.getCurrentLayout(), page: DesignFactory.getCurrentPage(), type: 'dashboard'}
                });
            }
            PubSub.emit($PageLibraryEvents.CLEAR_ALL_ITEMS);
            _hideModal();
        }).catch(function (msg) {
            vm.state.isSaving = false;
            if (msg) {
                UIFactory.warn({text: msg});
            }
        });
    }

    /**
     * @returns {boolean}
     */
    function showCopyOptions() {
        var item = vm.data.itemToPublish;
        return item.executive_summary_enabled;
    }

    /**
     * Stops publishing
     */
    function onCancel() {
        _hideModal();
        _reset();
    }

    /**
     * @param pageToPublish
     * @private
     */
    function _init(pageToPublish) {
        _reset();
        vm.state.isActive = true;
        vm.data.itemToPublish = angular.copy(pageToPublish);
        // Set copy options
        vm.data.itemToPublish.copy_options = {};
        vm.data.itemToPublish.copy_options[PageCopyOptions.OPTION_COPY_EXEC_SUMMARIES] = false;
        _showModal();

        PageTagFactory.getTags().then(function(data) {
            vm.data.tags = data;
            vm.data.tags.unshift({id: TagPickerSelectConstant.PLACEHOLDER_ID, text: TagPickerSelectConstant.PLACEHOLDER_TAGS});
        });
    }

    function _reset() {
        vm.data = LibraryPublishModelFactory.getLibraryPublishModalData();
        vm.state = LibraryPublishModelFactory.getLibraryPublishModalState();
    }

    function _showModal() {
        UIFactory.showModal('page-publish-modal');
    }

    function _hideModal() {
        UIFactory.hideModal('page-publish-modal');
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($PageLibraryEvents.INIT_PUBLISH_MODAL, _init);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($PageLibraryEvents.INIT_PUBLISH_MODAL, _init);
    }
}