import { ExportType } from '@/modules/core/export/export.constants';
import { DashboardExportConfig } from '@/modules/ta/dashboard/export/models/DashboardExportConfig';
import { ExportServerConfig } from '@/modules/core/export/models/ExportServerConfig';

export class ExportConfigFactory {
  /**
   *
   * @param exportVars
   * @returns {ExportServerConfig}
   */
  static build(exportVars) {
    switch (exportVars.exportType) {
      case ExportType.TYPE_EXPORT_PDF:
      case ExportType.TYPE_EXPORT_WIDGET_PNG:
        return new DashboardExportConfig(exportVars);
      default:
        return new ExportServerConfig(exportVars);
    }
  }
}
