'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.export.services')

    .service('ExportReportFactory', ExportReportFactory);

/**
 * @ngInject
 */
function ExportReportFactory(
    Restangular,
    PubSub,
    DesignFactory,
    ScopeFactory,
    DateRangeFactory
) {
    var exports = Restangular.all('export');

    var $customReportModalScope;

    return {
        $registerCustomReportModalScope: $registerCustomReportModalScope,
        $initPdfOptions: $initPdfOptions,
        exportToPdf: exportToPdf,
        exportBuilderToPdf: exportBuilderToPdf,
        exportBuilderToPpt: exportBuilderToPpt,
        exportToPowerpoint: exportToPowerpoint,
        exportToExcel: exportToExcel,
        exportWidgetToExcel: exportWidgetToExcel,
        exportToPng: exportToPng,
        sendMail: sendMail,
        exportWidgetToCsv: exportWidgetToCsv
    };

    function $registerCustomReportModalScope(scope) {
        $customReportModalScope = ScopeFactory.$registerScope(scope, 'export-custom-report-modal');
    }

    /**
     * Proxy for initiating the pdf options modal
     */
    function $initPdfOptions() {
        angular.element('#export-custom-report-modal').modal('show');
        $customReportModalScope.init();
    }

    /**
     *
     * @param params.pageId
     * @param params.layoutId
     * @param params.options
     * @returns {*}
     */
    function exportToPdf(params) {
        if (window.isNUI && !params.html) {
            PubSub.emit('SegmentEvents', {
                event: 'BuildPDFReportDownloadEvent',
                payload: {page: DesignFactory.getCurrentPage(), options: params }
            });
        }
        return exportEntity('pdf', params);
    }

    /**
     *
     * @param params.pageId
     * @param params.layoutId
     * @param params
     * @returns {*}
     */
    function exportBuilderToPdf(params) {
        return exportEntity('builder', params);
    }

    /**
     *
     * @param params
     * @returns {*}
     */
    function exportBuilderToPpt(params) {
        return exportEntity('builder/powerpoint', params);
    }

    /**
     *
     * @param params.pageId
     * @param params.layoutId
     * @returns {*}
     */
    function exportToPowerpoint(params) {
        return exportEntity('powerpoint', params);
    }

    /**
     *
     * @param params.pageId
     * @param params.layoutId
     * @returns {*}
     */
    function exportToExcel(params) {
        return exportEntity('excel', params);
    }

    /**
     *
     * @param params.pageId
     * @param params.layoutId
     * @returns {*}
     */
    function exportWidgetToExcel(params) {
        return exportEntity('excel/widget', params);
    }

    /**
     *
     * @param params.pageId
     * @param params.layoutId
     * @returns {*}
     */
    function exportWidgetToCsv(params) {
        return exportEntity('csv/widget', params);
    }

    /**
     *
     * @param id
     * @returns {*}
     */
    function exportToPng(params) {
        return exportEntity('png', params);
    }

    /**
     * Helper function build export entity request
     * @param type
     * @param params
     * @param params.pageId
     * @param params.layoutId
     * @param params.pageEntity
     * @param params.widgetId
     * @returns {*}
     */
    function exportEntity(type, params) {
        var request;

        if ('pageId' in params) {
            if ('layoutId' in params) {
                request = exports.one(type, params.pageId).all(params.layoutId);
            }
            else {
                request = exports.one(type, params.pageId);
            }
        } else if ('pageEntity' in params) {
            request = exports.all(type).all('dynamic').one(params.pageEntity);
            delete params['pageEntity'];
        }

        if ('widgetId' in params) {
            request = exports.one(type, params.widgetId);
        }

        // We need to build the request here manually, because we do not
        // want to trigger the request using a .get()
        // the $.fileDownload() will make the request
        request.reqParams = _.assign({daterange: DateRangeFactory.buildDateQueryParam()}, params);
        return request;
    }

    /**
     * @param params
     * @returns {*}
     */
    function sendMail(params) {
        return exports.one('builder').one('sendmail').post(params.pageId, params);
    }
}