'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('marginrule.ctrls', [])

    .controller('MarginRuleListController', MarginRuleListController)
    .controller('MarginRuleController', MarginRuleController);

//
// MarginRule grid controller
//
MarginRuleListController.$inject = ['$scope', 'MarginRuleService'];
function MarginRuleListController($scope, MarginRuleService) {
    $scope.showMarginRuleDetails = false;
}

//
// MarginRule controller
//
MarginRuleController.$inject = ['$scope', 'MarginRuleService'];
function MarginRuleController($scope, MarginRuleService) {

    $scope.init = function(canEdit) {
        if (!canEdit.bool()) {
            $('#main-form').find('select, input').disableElement();
        }
    };

    var datePickerOptions = {};
    datePickerOptions.dateFormat = "yy-mm-dd";
    $.core.main.initDatePicker($('#start_date'), datePickerOptions);
    $.core.main.initDatePicker($('#end_date'), datePickerOptions);

    $('input[name=calculation_type]').change(updateMarginDisplay);
    $('#markup_percent').bind('textchange', updateMarginDisplay);
    $('#margin_percent').bind('textchange', updateMarginDisplay);
    updateMarginDisplay();

    function updateMarginDisplay()
    {
        var use_val = null;
        var calculation_type = $('input[name=calculation_type]:checked').val();

        $('.calculation-details').hide();
        $('.calculation-example').hide();
        $('.calculation-details-'+calculation_type).show();

        var calculation_result = null;

        if (calculation_type == 'margin' && $('#margin_percent').val() != '')
        {
            use_val = $('#margin_percent').val()*1;
            calculation_result = 100 * 100 / (100 - use_val);
        }
        else if (calculation_type == 'markup' && $('#markup_percent').val() != '')
        {
            use_val = $('#markup_percent').val()*1;
            calculation_result = 100 + use_val;
        }

        if (calculation_result != null)
        {
            $('.calculation-example').show();
            $('#marginExampleAmount').html('<b>' + Globalize.format(calculation_result, 'n2') + '</b>');
        }
    }
}