import { isEmpty, map, max } from 'lodash';
import { getters as storeGetters } from '@/modules/core/app/helpers/store';

export const getters = {
  /**
   * @param state
   * @returns {boolean}
   */
  needsReload(state) {
    return state.needsReload;
  },
  /**
   * @param state
   * @returns {boolean}
   */
  areFiltersInitialized(state) {
    return state.filtersInitialized;
  },
  /**
   * @param state
   * @returns {Array<DashboardFilterOption>}
   */
  getFilterOptions(state) {
    return state.filterOptions;
  },
  /**
   * @param state
   * @returns {DashboardFilters}
   */
  getFilters(state) {
    if (!state.filters) {
      return null;
    }

    return state.filters.getFilters();
  },
  /**
   * @param state
   * @returns {DashboardFilterItem}
   */
  getCurrentActiveFilter(state) {
    if (!state.filters) {
      return null;
    }

    return state.filters.getActiveFilter();
  },
  /**
   * @param state
   * @returns {boolean}
   */
  isClientFilter(state) {
    if (!state.filters) {
      return null;
    }
    return state.filters.isClientFilter();
  },
  /**
   * @param state
   * @returns {boolean}
   */
  hasFilters(state) {
    // Before any filter is set, empty filters have this structure: {data: null}
    if (!state.filters) {
      return false;
    }
    // And afterwards it can be empty this way: {data: {client: {..., values: []}}}
    return state.filters.hasFilters();
  },
  getDashboard(state) {
    return state.dashboard;
  },
  getLayoutsInDashboard(state) {
    if (!state.dashboard) {
      return [];
    }
    const layoutIds = Object.keys(state.dashboard.layouts);
    return storeGetters.dashboardLayout.getLayoutsFromIds(layoutIds);
  },
  /**
   * @param state
   * @returns {SelectedEntity|null}
   */
  getSelectedEntity(state) {
    return state.dashboard?.metadata.selected_entity;
  },
  getCurrentLayoutId(state) {
    return state.currentLayoutId;
  },

  /**
   * Returns the next layout display order
   * @returns {number}
   */
  getNextLayoutDisplayOrder() {
    const layouts = storeGetters.dashboard.getLayoutsInDashboard();
    return !isEmpty(layouts) ? max(map(layouts, 'display_order')) + 1 : 1;
  },
};
