'use strict';
import angular from 'angular';

angular.module('core.columns.services', [])

    .service('ColumnFactory', ColumnFactory);

ColumnFactory.$inject = ['Restangular'];
function ColumnFactory(Restangular) {
    var columns = Restangular.all('columns');
    var dataTypes = columns.one('datatypes');

    return {
        columns: columns,
        dataTypes: dataTypes
    }
}