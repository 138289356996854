'use strict';
import angular from 'angular';
import {WidgetType} from 'coreModules/design/widget/design.widget.constants';
import panelStylesReportHtmlUrl from './panel.styles.report.html';
import panelStylesPageHtmlUrl from './panel.styles.page.html';
import panelStylesBaseHtmlUrl from './panel.styles.base.html';
import panelStylesWidgetHtmlUrl from './panel.styles.widget.html';
import panelStylesImageHtmlUrl from './panel.styles.image.html';
import panelStylesShapeHtmlUrl from './panel.styles.shape.html';
import panelStylesIconHtmlUrl from './panel.styles.icon.html';
import panelStylesTextHtmlUrl from './panel.styles.text.html';

angular.module('exportbuilder.panel.components')
    .component('exportBuilderReportStyles', {
        bindings: {
            element: '<'
        },
        templateUrl: panelStylesReportHtmlUrl,
        controller: ExportBuilderReportStylesController,
        controllerAs: 'vm'
    })
    .component('exportBuilderPageStyles', {
        bindings: {
            page: '<'
        },
        templateUrl: panelStylesPageHtmlUrl,
        controller: ExportBuilderReportPageStylesController,
        controllerAs: 'vm'
    })
    .component('exportBuilderBaseElementStyles', {
        bindings: {
            element: '<'
        },
        templateUrl: panelStylesBaseHtmlUrl,
        controller: ExportBuilderBaseElementStylesController,
        controllerAs: 'vm'
    })
    .component('exportBuilderWidgetElementStyles', {
        bindings: {
            element: '<'
        },
        templateUrl: panelStylesWidgetHtmlUrl,
        controller: ExportBuilderWidgetElementStylesController,
        controllerAs: 'vm'
    })
    .component('exportBuilderImageElementStyles', {
        bindings: {
            element: '<'
        },
        templateUrl: panelStylesImageHtmlUrl,
        controller: ExportBuilderImageElementStylesController,
        controllerAs: 'vm'
    })
    .component('exportBuilderShapeElementStyles', {
        bindings: {
            element: '<'
        },
        templateUrl: panelStylesShapeHtmlUrl,
        controller: ExportBuilderShapeElementStylesController,
        controllerAs: 'vm'
    })
    .component('exportBuilderIconElementStyles', {
        bindings: {
            element: '<'
        },
        templateUrl: panelStylesIconHtmlUrl,
        controller: ExportBuilderIconElementStylesController,
        controllerAs: 'vm'
    })
    .component('exportBuilderTextElementStyles', {
        bindings: {
            element: '<'
        },
        templateUrl: panelStylesTextHtmlUrl,
        controller: ExportBuilderTextElementStylesController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderReportStylesController(
    ExportBuilderDashboardService,
    ExportBuilderDashboardUtilService,
    ExportBuilderElementActionService,
    UIFactory,
    PubSub,
    $ExportBuilderDashboardEvents,
    FontSize
) {
    var vm = this;
    vm.FontSize = FontSize;
    vm.model = {};
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.toggleDrawOption = toggleDrawOption;
    vm.onColorChange = onColorChange;
    vm.onColorPaletteApply = onColorPaletteApply;
    vm.onColorPaletteCancel = onColorPaletteCancel;
    
    vm.onFontSizeChange = onFontSizeChange;
    vm.isFontSizeSmall = isFontSizeSmall;
    vm.isFontSizeMedium = isFontSizeMedium;
    vm.isFontSizeLarge = isFontSizeLarge;

    
    function $onInit() {
        vm.report = ExportBuilderDashboardService.getReport();
        vm.model.originalThemeColorCount = UIFactory.getOriginalThemeColorCount();
        vm.model.chart_palette = angular.copy(vm.report.metadata.chart_palette);
        vm.model.draw_options = angular.copy(vm.report.metadata.draw_options);
        _registerEvents();
    }

    function $onDestroy() {
        vm.model.chart_palette = null;
        _unregisterEvents();
    }

    function toggleDrawOption(drawOptionKey) {
        ExportBuilderElementActionService.toggleReportWidgetDrawOption(drawOptionKey, vm.model.draw_options[drawOptionKey].value);
    }

    function onColorChange(newValue, index) {
        vm.model.chart_palette[index] = newValue;
        vm.model.showSave = true;
        vm.model.showCancel = true;
    }

    function onColorPaletteApply() {
        ExportBuilderElementActionService.setColorPalette(angular.copy(vm.model.chart_palette));
        vm.model.showSave = false;
        vm.model.showCancel = false;
    }

    function onColorPaletteCancel() {
        vm.model.chart_palette = angular.copy(vm.report.metadata.chart_palette);
        vm.model.showSave = false;
        vm.model.showCancel = false;
    }
    
    function onFontSizeChange(newValue) {
        ExportBuilderElementActionService.setFontSize(newValue);
    }
    
    function isFontSizeSmall() {
        return vm.report.metadata.font_size === FontSize.SIZE_SMALL;
    }
    
    function isFontSizeMedium() {
        return vm.report.metadata.font_size === FontSize.SIZE_MEDIUM;
    }
    
    function isFontSizeLarge() {
        return vm.report.metadata.font_size === FontSize.SIZE_LARGE;
    }

    function _updateChartPalette(chartPalette) {
        vm.model.chart_palette = chartPalette;
    }

    function _updateDrawOptions(drawOptions) {
        vm.model.draw_options = drawOptions;
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.CHART_PALETTE, _updateChartPalette);
        PubSub.on($ExportBuilderDashboardEvents.REPORT_DRAW_OPTIONS, _updateDrawOptions);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.CHART_PALETTE, _updateChartPalette);
        PubSub.off($ExportBuilderDashboardEvents.REPORT_DRAW_OPTIONS, _updateDrawOptions);
    }
}

/**
 * @ngInject
 */
function ExportBuilderReportPageStylesController(
    ExportBuilderDashboardPageService,
    ExportBuilderDashboardService,
    ExportBuilderElementActionService
) {
    var vm = this;
    vm.onPageBackgroundColorChange = onPageBackgroundColorChange;
    vm.onPageOpacityChange = onPageOpacityChange;

    function onPageBackgroundColorChange(newValue) {
        ExportBuilderElementActionService.setPageBackgroundColor(ExportBuilderDashboardService.getBuilder().currentPage, newValue);
    }

    function onPageOpacityChange() {
        ExportBuilderDashboardPageService.setOpacity(vm.page.metadata.design_options.opacity);
    }
}

/**
 * @ngInject
 */
function ExportBuilderBaseElementStylesController(
    ExportBuilderDashboardItemService,
    ExportBuilderDashboardUIModelFactory,
    ReportElementBaseOptions,
    ExportBuilderElementActionService
) {
    var vm = this;

    vm.ReportElementBaseOptions = ReportElementBaseOptions;

    /**
     * @type {{}}
     */
    vm.panelSliderOptions = {};

    vm.$onInit = $onInit;
    vm.onMirrorXClicked = onMirrorXClicked;
    vm.onMirrorYClicked = onMirrorYClicked;
    vm.onBackgroundColorChange = onBackgroundColorChange;
    vm.onOpacityChange = onOpacityChange;
    vm.onBorderRadiusChange = onBorderRadiusChange;
    vm.onShadowXChange = onShadowXChange;
    vm.onShadowYChange = onShadowYChange;
    vm.onShadowBlurChange = onShadowBlurChange;
    vm.onShadowColorChange = onShadowColorChange;
    vm.onBorderColorChange = onBorderColorChange;
    vm.onBorderWidthChange = onBorderWidthChange;
    vm.onBorderTypeChange = onBorderTypeChange;
    vm.onBorderOpacityChange = onBorderOpacityChange;

    function $onInit() {
        _setSliderOptions();
    }

    function onMirrorXClicked() {
        var value = _.isNil(vm.element.metadata.base_options.mirror_x) ? true : !vm.element.metadata.base_options.mirror_x;
        ExportBuilderElementActionService.setMirrorXElement(value);
    }

    function onMirrorYClicked() {
        var value = _.isNil(vm.element.metadata.base_options.mirror_y) ? true : !vm.element.metadata.base_options.mirror_y;
        ExportBuilderElementActionService.setMirrorYElement(value);
    }

    function onBackgroundColorChange(newValue) {
        ExportBuilderElementActionService.setBackgroundColor(newValue);
    }

    function onOpacityChange(value) {
        ExportBuilderElementActionService.setOpacity(value);
    }

    function onBorderRadiusChange() {
        ExportBuilderElementActionService.setBorderRadius(vm.element.metadata.base_options.border_radius);
    }

    function onShadowXChange() {
        ExportBuilderElementActionService.setShadowX(vm.element.metadata.base_options.shadow_x);
    }

    function onShadowYChange() {
        ExportBuilderElementActionService.setShadowY(vm.element.metadata.base_options.shadow_y);
    }

    function onShadowBlurChange() {
        ExportBuilderElementActionService.setShadowBlur(vm.element.metadata.base_options.shadow_blur);
    }


    function onBorderColorChange(newValue) {
        ExportBuilderElementActionService.setBorderColor(newValue);
    }

    function onBorderWidthChange() {
        ExportBuilderElementActionService.setBorderWidth(vm.element.metadata.base_options.border_width);
    }

    function onBorderTypeChange() {
        ExportBuilderElementActionService.setBorderType(vm.element.metadata.base_options.border_type);
    }

    function onBorderOpacityChange() {
        ExportBuilderElementActionService.setBorderOpacity(vm.element.metadata.base_options.border_opacity);
    }


    function onShadowColorChange(newValue) {
        ExportBuilderElementActionService.setShadowColor(newValue);
    }

    function _setSliderOptions() {
        var key = ReportElementBaseOptions.OPACITY;
        vm.panelSliderOptions[key] = ExportBuilderDashboardUIModelFactory.getDesignPanelSliderOption({
            id: key,
            value: vm.element.metadata.base_options.opacity,
            onEnd: function (key, value) {
                onOpacityChange(value);
            }
        });
        vm.panelSliderOptions[ReportElementBaseOptions.BORDER_OPACITY] = ExportBuilderDashboardUIModelFactory.getDesignPanelSliderOption({
            id: ReportElementBaseOptions.BORDER_OPACITY,
            value: vm.element.metadata.base_options[ReportElementBaseOptions.BORDER_OPACITY],
            onEnd: function (key, value) {
                onBorderOpacityChange(value);
            }
        });
        vm.panelSliderOptions[ReportElementBaseOptions.BORDER_WIDTH] = ExportBuilderDashboardUIModelFactory.getDesignPanelSliderOption({
            id: ReportElementBaseOptions.BORDER_WIDTH,
            value: vm.element.metadata.design_options[ReportElementBaseOptions.BORDER_WIDTH],
            floor: 1,
            ceil: 20,
            step: 1,
            onEnd: function (key, value) {
                onBorderWidthChange(value);
            }
        });
        vm.panelSliderOptions[ReportElementBaseOptions.SHADOW_BLUR] = ExportBuilderDashboardUIModelFactory.getDesignPanelSliderOption({
            id: ReportElementBaseOptions.SHADOW_BLUR,
            value: vm.element.metadata.design_options[ReportElementBaseOptions.SHADOW_BLUR],
            floor: 1,
            ceil: 80,
            step: 1,
            onEnd: function (key, value) {
                onShadowBlurChange(value);
            }
        });
        vm.panelSliderOptions[ReportElementBaseOptions.BORDER_RADIUS] = ExportBuilderDashboardUIModelFactory.getDesignPanelSliderOption({
            id: ReportElementBaseOptions.BORDER_RADIUS,
            value: vm.element.metadata.design_options[ReportElementBaseOptions.BORDER_RADIUS],
            floor: 1,
            ceil: 80,
            step: 1,
            onEnd: function (key, value) {
                onBorderRadiusChange(value);
            }
        });
    }
}

/**
 * @ngInject
 */
function ExportBuilderWidgetElementStylesController(
    ExportBuilderElementActionService
) {
    var vm = this;
    vm.toggleDrawOption = toggleDrawOption;
    vm.metricNameForBigNumber = metricNameForBigNumber;
    vm.canDisableWidgetStyles = canDisableWidgetStyles;

    function toggleDrawOption(drawOptionKey) {
        ExportBuilderElementActionService.toggleWidgetDrawOption(drawOptionKey, vm.element.widget.metadata.draw_options[drawOptionKey].value);
    }

    /**
     *
     * @returns {boolean}
     */
    function metricNameForBigNumber() {
      return vm.element.widget.type === WidgetType.BIGNUMBER;
    }

    /**
     * Returns true for executive summary widgets
     *
     * @returns {boolean}
     */
    function canDisableWidgetStyles() {
        return vm.element.widget.type === WidgetType.EXECUTIVESUMMARY;
    }
}

/**
 * @ngInject
 */
function ExportBuilderImageElementStylesController(
    ReportElementImageSupportedFillModes,
    ExportBuilderElementActionService
) {
    var vm = this;
    vm.ReportElementImageSupportedFillModes = ReportElementImageSupportedFillModes;
    vm.onFillModeClicked = onFillModeClicked;

    function onFillModeClicked() {
        ExportBuilderElementActionService.setImageFillMode(vm.element.metadata.design_options.fill_mode);
    }
}

/**
 * @ngInject
 */
function ExportBuilderShapeElementStylesController(
    ExportBuilderElementActionService
) {
    var vm = this;
    vm.onBackgroundColorChange = onBackgroundColorChange;

    function onBackgroundColorChange(newValue) {
        ExportBuilderElementActionService.setShapeBackgroundColor(newValue);
    }
}

/**
 * @ngInject
 */
function ExportBuilderIconElementStylesController(
    ExportBuilderElementActionService
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onIconColorChange = onIconColorChange;

    function $onInit() {
    }

    function $onDestroy() {
    }

    function onIconColorChange(newValue) {
        ExportBuilderElementActionService.setIconColor(newValue);
    }
}

/**
 * @ngInject
 */
function ExportBuilderTextElementStylesController(
    $timeout,
    $scope,
    ReportElementTypes,
    ReportElementTextDesignOptions,
    ExportBuilderDashboardItemService,
    ExportBuilderElementActionService,
    ExportBuilderDashboardService,
    ReportElementDesignOptionConstants,
    ExportBuilderDashboardUIModelFactory
) {
    var vm = this;
    vm.ReportElementTypes = ReportElementTypes;
    vm.ReportElementTextDesignOptions = ReportElementTextDesignOptions;

    /**
     * @type {DesignPanelSelectOption}
     */
    vm.panelSelectOptions = ExportBuilderDashboardUIModelFactory.getDesignPanelSelectOption();

    vm.fontSizeSelectOptions = ExportBuilderDashboardUIModelFactory.getFontSizeSelectOption();

    /**
     * @type {{}}
     */
    vm.panelSliderOptions = {};

    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;
    vm.$onDestroy = $onDestroy;

    vm.onTextOpacityChange = onTextOpacityChange;
    vm.onTextAlignmentLeftClicked = onTextAlignmentLeftClicked;
    vm.onTextAlignmentRightClicked = onTextAlignmentRightClicked;
    vm.onTextAlignmentCenterClicked = onTextAlignmentCenterClicked;
    vm.onTextAlignmentJustifyClicked = onTextAlignmentJustifyClicked;
    vm.isTextLeftAligned = isTextLeftAligned;
    vm.isTextJustify = isTextJustify;
    vm.isTextCenterAligned = isTextCenterAligned;
    vm.isTextRightAligned = isTextRightAligned;
    vm.onTopVerticalAlignmentClicked = onTopVerticalAlignmentClicked;
    vm.onCenterVerticalAlignmentClicked = onCenterVerticalAlignmentClicked;
    vm.onBottomVerticalAlignmentClicked = onBottomVerticalAlignmentClicked;
    vm.isTextTopVerticalAlign = isTextTopVerticalAlign;
    vm.isTextCenterVerticalAlign = isTextCenterVerticalAlign;
    vm.isTextBottomVerticalAlign = isTextBottomVerticalAlign;
    vm.onTextShadowXChange = onTextShadowXChange;
    vm.onTextShadowYChange = onTextShadowYChange;
    vm.onTextShadowBlurChange = onTextShadowBlurChange;
    vm.onTextShadowColorChange = onTextShadowColorChange;
    vm.toggleBold = toggleBold;
    vm.toggleItalic = toggleItalic;
    vm.toggleUnderline = toggleUnderline;
    vm.toggleStrikethrough = toggleStrikethrough;
    vm.isTextBold = isTextBold;
    vm.isTextItalic = isTextItalic;
    vm.isTextUnderline = isTextUnderline;
    vm.isTextStrikethrough = isTextStrikethrough;
    vm.onTextPaddingChange = onTextPaddingChange;
    vm.onFontFamilyChange = onFontFamilyChange;
    vm.onFontSizeChange = onFontSizeChange;
    vm.onTextSizeChange = onTextSizeChange;
    vm.onTextColorChange = onTextColorChange;

    function $onInit() {
        _setSelect2Values();
        _setFontSizeSelectValues();
        _setTextOpacitySliderOptions();
        //_forceRefreshSliders();
    }

    function $onChanges() {
        _setSelect2Values();
        _setFontSizeSelectValues();
    }

    function $onDestroy() {
    }

    function onTextOpacityChange(value) {
        ExportBuilderElementActionService.setTextOpacity(value);
    }

    //
    // TEXT ALIGNMENT
    //
    function onTextAlignmentLeftClicked() {
        ExportBuilderElementActionService.setTextAlignment(ReportElementDesignOptionConstants.TEXT_ALIGNMENT.LEFT);
    }

    function onTextAlignmentRightClicked() {
        ExportBuilderElementActionService.setTextAlignment(ReportElementDesignOptionConstants.TEXT_ALIGNMENT.RIGHT);
    }

    function onTextAlignmentCenterClicked() {
        ExportBuilderElementActionService.setTextAlignment(ReportElementDesignOptionConstants.TEXT_ALIGNMENT.CENTER);
    }

    function onTextAlignmentJustifyClicked() {
        ExportBuilderElementActionService.setTextAlignment(ReportElementDesignOptionConstants.TEXT_ALIGNMENT.JUSTIFY);
    }

    function isTextLeftAligned() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.TEXT_ALIGNMENT] === ReportElementDesignOptionConstants.TEXT_ALIGNMENT.LEFT;
    }

    function isTextJustify() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.TEXT_ALIGNMENT] === ReportElementDesignOptionConstants.TEXT_ALIGNMENT.JUSTIFY;
    }

    function isTextCenterAligned() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.TEXT_ALIGNMENT] === ReportElementDesignOptionConstants.TEXT_ALIGNMENT.CENTER;
    }

    function isTextRightAligned() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.TEXT_ALIGNMENT] === ReportElementDesignOptionConstants.TEXT_ALIGNMENT.RIGHT;
    }

    //
    // TEXT VERTICAL POSITION
    //
    function onTopVerticalAlignmentClicked() {
        ExportBuilderElementActionService.setTextVerticalAlignment(ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.TOP);
    }

    function onCenterVerticalAlignmentClicked() {
        ExportBuilderElementActionService.setTextVerticalAlignment(ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.CENTER);
    }

    function onBottomVerticalAlignmentClicked() {
        ExportBuilderElementActionService.setTextVerticalAlignment(ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.BOTTOM);
    }

    function isTextTopVerticalAlign() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.VERTICAL_ALIGNMENT] === ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.TOP;
    }

    function isTextCenterVerticalAlign() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.VERTICAL_ALIGNMENT] === ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.CENTER;
    }

    function isTextBottomVerticalAlign() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.VERTICAL_ALIGNMENT] === ReportElementDesignOptionConstants.VERTICAL_ALIGNMENT.BOTTOM;
    }

    function onTextSizeChange(value) {
        ExportBuilderElementActionService.setTextSize(value);
    }

    function onTextColorChange(newValue) {
        ExportBuilderElementActionService.setTextColor(newValue);
    }

    function onTextShadowXChange() {
        ExportBuilderElementActionService.setTextShadowX(vm.element.metadata.design_options.text_shadow_x);
    }

    function onTextShadowYChange() {
        ExportBuilderElementActionService.setTextShadowY(vm.element.metadata.design_options.text_shadow_y);
    }

    function onTextShadowBlurChange() {
        ExportBuilderElementActionService.setTextShadowBlur(vm.element.metadata.design_options.text_shadow_blur);
    }

    function onTextShadowColorChange(newValue) {
        ExportBuilderElementActionService.setTextShadowColor(newValue);
    }

    function toggleBold() {
        var value = _.isNil(vm.element.metadata.design_options.text_bold) ? true : !vm.element.metadata.design_options.text_bold;
        ExportBuilderElementActionService.toggleTextBold(value);
    }

    function toggleItalic() {
        var value = _.isNil(vm.element.metadata.design_options.text_italic) ? true : !vm.element.metadata.design_options.text_italic;
        ExportBuilderElementActionService.toggleTextItalic(value);
    }

    function toggleUnderline() {
        var value = _.isNil(vm.element.metadata.design_options.text_underline) ? true : !vm.element.metadata.design_options.text_underline;
        ExportBuilderElementActionService.toggleTextUnderline(value);
    }

    function toggleStrikethrough() {
        var value = _.isNil(vm.element.metadata.design_options.text_strikethrough) ? true : !vm.element.metadata.design_options.text_strikethrough;
        ExportBuilderElementActionService.toggleTextStrikethrough(value);
    }

    function isTextBold() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.TEXT_BOLD];
    }

    function isTextItalic() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.TEXT_ITALIC];
    }

    function isTextUnderline() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.TEXT_UNDERLINE];
    }

    function isTextStrikethrough() {
        return vm.element.metadata.design_options[ReportElementTextDesignOptions.TEXT_STRIKETHROUGH];
    }

    function onTextPaddingChange() {
        ExportBuilderElementActionService.setPadding(vm.element.metadata.design_options.padding);
    }

    function onFontFamilyChange($el) {
        var value = $el.select2('data').text;
        ExportBuilderElementActionService.setFontFamily(value);
    }

    function onFontSizeChange($el) {
        const value = $el.select2('data').id;
        onTextSizeChange(value);
    }

    function _setFontSizeSelectValues() {
        vm.fontSizeSelectOptions.selectedValue = {
            id: vm.element.metadata.design_options.font_size,
            text: `${vm.element.metadata.design_options.font_size} px`
        }
    }

    function _setSelect2Values() {
        vm.panelSelectOptions.selectedValue = {
            id: vm.element.metadata.design_options.font_family,
            text: vm.element.metadata.design_options.font_family
        }
    }

    function _setTextOpacitySliderOptions() {
        var key = ReportElementTextDesignOptions.TEXT_OPACITY;
        vm.panelSliderOptions[key] = ExportBuilderDashboardUIModelFactory.getDesignPanelSliderOption({
            id: key,
            value: vm.element.metadata.design_options.text_opacity,
            onEnd: function (key, value) {
                onTextOpacityChange(value);
            }
        });
    }
}