import { SliceChartConfig } from '@/modules/core/charts/am5/base/models/SliceChartConfig';
import { SeriesType } from '@/modules/core/charts/chart.constants';

/**
 * @class FunnelChartConfig
 * @extends SliceChartConfig
 */
export class FunnelChartConfig extends SliceChartConfig {
  constructor(model = {}) {
    super(model);
  }

  getSeriesType() {
    return SeriesType.FUNNEL;
  }

  isFunnelSeries() {
    return true;
  }
}
