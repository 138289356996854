<template>
  <BarChart
    v-if="canShowChart"
    :config="chartConfig"
    width="100%"
    height="100%"
    :widget-type="widgetType"
    :disable-error-messages="disableErrorMessages"
    @frame-ended="setIdle"
  />
</template>

<script>
import BarChart from '@/modules/core/charts/am5/bar/components/BarChart.vue';
import { BaseChartWidgetPropsMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetPropsMixin';
import { BaseChartWidgetMethodMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetMethodMixin';

export default {
  name: 'LegacyBarChartWidget',
  mixins: [BaseChartWidgetPropsMixin, BaseChartWidgetMethodMixin],
  components: { BarChart },
};
</script>
