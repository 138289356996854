'use strict';
import angular from 'angular';
import _ from 'lodash';

import widgetFilterItemHtmlUrl from './widget.filter.item.html';
import widgetFilterItemDescriptionHtmlUrl from './widget.filter.item.description.html';
import widgetColumnSelectHtmlUrl from '../../base/widget.column.select.html';
import {SanitizeHtmlOptionsAllEmpty, $TimeoutValues} from "../widget.filter.constants";
import sanitizeHtml from 'sanitize-html';


angular.module('widget.filter.directives')

    .directive('filterItem', filterItem)
    .directive('filterItemDescription', filterItemDescription)
    .directive('filterValueSelect', filterValueSelect)
    .directive('filterNumberRangeSelect', filterNumberRangeSelect);

/**
 * Individual widget filter list item
 * @type {Array}
 */
function filterItem() {
    return {
        restrict: 'E',
        controller: 'FilterItemController',
        templateUrl: widgetFilterItemHtmlUrl,
        scope: {
            filter: '=',
            datasource: '=',
            liveIntegration: '=',
            widgetId: '<',
            isFirst: '<',
            showFilterResultsWarningMessage: '='
        }
    }
}

/**
 * Individual widget filter list item
 * @type {Array}
 */
function filterItemDescription() {
    return {
        restrict: 'E',
        controller: 'FilterItemDescriptionController',
        templateUrl: widgetFilterItemDescriptionHtmlUrl,
        scope: {
            filter: '<',
            columnColor: '<',
            isFirst: '<',
        }
    }
}

/**
 *
 * Select2 for widget filter values
 * @ngInject
 * */
function filterValueSelect(
    WidgetFilterFactory,
    DataSourceFactory,
    WidgetQueryParamService,
    DashboardContextService,
    DesignFactory,
    PubSub,
    $TimeoutValues,
    $WidgetFilterEvents,
    $timeout
) {
    return {
        restrict: 'A',
        scope: {
            id: '<',
            targetId: '<',
            targetFilter: '<',
            filter: '=',
            liveIntegration: '=',
            datasource: '='
        },
        templateUrl: widgetColumnSelectHtmlUrl,
        controller: function($scope) {
            var initialSelectableValues = angular.copy($scope.filter.selectableValues);
            var filteredSelectableValues = angular.copy(initialSelectableValues);
            var queryString = '';
            var isContinuingSearch = false;
            let stopBackendCalls = false;
            let select2ElementId = '';

            $scope.selectOptions = {
                placeholder: 'Filter by a ' + $scope.filter.label.toLowerCase() + ' ...',
                allowClear: true,
                width: '100%',
                multiple: true,
                acceptWildcard: true,
                label: $scope.filter.label.toLowerCase(),
                minimumInputLength: getMinimumInputLength(),
                hasMoreData: $scope.filter.hasMoreData,
                relaxMatch: $scope.filter.relaxMatch,
                separator: '!@#$%^&*', // Required, to avoid selecting same filter value (containing commas) multiple times TA-33481
                formatResult: function (item) {
                    return item.text;
                },
                formatSelection: function (item) {
                    return item.text;
                },
                // NOTE: These params are needed for polling the backend for large amounts of data
                // query property will not be set unless too much data is returned
                tooMuchDataCallback: function(query, skipTimeOut = false) {
                    let queryElement =  [{id: query.term, text: query.term}];
                    queryString = query;
                    // In order to enable tags, the query must be the first item in results
                    var valuesWithTag = query.term.length > 1 ?
                        angular.copy(queryElement).concat($scope.selectOptions.tags) :
                        $scope.selectOptions.tags;
                    var selectedValues = {results: valuesWithTag};

                    // Query length must be checked here, or else the selectable values will never be set
                    if (query.term.length >= 2) {
                        var params = {
                            q: WidgetQueryParamService.sanitizeSearchString(query.term) + '|' + $scope.filter.field
                        };

                        if ($scope.liveIntegration) {
                            params.has_live_integration = $scope.liveIntegration;
                        }

                        // We need to pass a widget_page_id to the backend to allow for pre-filtered dashboards
                        // only for dashboards
                        if (DashboardContextService.resolvePageId()
                            && DashboardContextService.resolveSelectedEntity()) {
                            params.widget_page_id = DesignFactory.getCurrentPage().id;
                        }

                        if (!skipTimeOut) {
                            if (isContinuingSearch) {
                                isContinuingSearch = false;
                            } else {
                                params.timeout = $TimeoutValues.TIMEOUT;
                            }
                        }

                        if (!stopBackendCalls) {
                            DataSourceFactory.getFieldValues($scope.datasource, $scope.filter.field, params).then(function(item) {
                                if (item.values.length) {
                                    query.term = _.unescape(sanitizeHtml(query.term, SanitizeHtmlOptionsAllEmpty));
                                    selectedValues.results = [{id: query.term, text: query.term}];
                                    let filterValues = [];
                                    _.each(item.values, function (item) {
                                        let selectedTargetFilters = [];
                                        if ($scope.targetFilter) {
                                            selectedTargetFilters = _.map($scope.targetFilter.values, (value) => {
                                                if(!_.isUndefined(value.key)) {
                                                    return value.key;
                                                }
                                                return value.id;
                                            });
                                        }
                                        if (!selectedTargetFilters.includes(item.key)) {
                                            selectedValues.results.push({id: item.key, text: item.value});
                                            filterValues.push(item);
                                        }
                                    });
                                    // must update initial select2 values that was fetched and trigger digest
                                    $scope.selectOptions.tags = filterValues;
                                } else {
                                    selectedValues = {results: queryElement};
                                }
                                if (item.timeout) {
                                    $scope.showFilterResultsWarningMessage = true;
                                    $scope.selectOptions.tags = [{id: '', text: '', key: '', value: ''}];
                                    $scope.filter.selectableValues = $scope.selectOptions.tags;
                                    select2ElementId = query.element[0].parentElement.firstElementChild.id;
                                    $("#"+select2ElementId).select2('close');
                                }
                                query.callback(selectedValues);
                            }, function (error) {
                                $scope.showFilterResultsWarningMessage = true;
                                select2ElementId = query.element[0].parentElement.firstElementChild.id;
                                $("#"+select2ElementId).select2('close');
                                console.error("Error getting data", error);
                            });
                        } else {
                            query.callback(selectedValues);
                        }
                    }
                    else {
                        query.callback(selectedValues);
                    }
                }
            };
            if ($scope.filter.timeout) {
                $scope.showFilterResultsWarningMessage = true;
                $scope.selectOptions.tags = [{id: '', text: '', key: '', value: ''}];
                $scope.filter.selectableValues = $scope.selectOptions.tags;
            }
            $scope.selectFilterValue = selectFilterValue;
            $scope.selectOnChange = selectOnChange;
            $scope.onInputFocus = onInputFocus;
            $scope.widgetFilterMessageUserAction = widgetFilterMessageUserAction;
            PubSub.on($WidgetFilterEvents.UPDATE_ASSOCIATE_FILTER, _updateSelectableOptions);
            PubSub.emit($WidgetFilterEvents.UPDATE_ASSOCIATE_FILTER, {targetId: $scope.targetId,selectedValues: $scope.filter.values});
            function selectFilterValue(data) {
                var selectedValues = [];
                _.each(data, function (item) {
                    selectedValues.push({id: item.id, text: item.text, key: item.key});
                });
                $scope.filter.values = selectedValues;

                WidgetFilterFactory.$updateFilterDescriptionTooltips();
                PubSub.emit($WidgetFilterEvents.UPDATE_ASSOCIATE_FILTER, {targetId: $scope.targetId,selectedValues: selectedValues});
            }

            function selectOnChange($selectEl, event) {
                $scope.selectFilterValue($selectEl.select2('data'), $scope.filter);
            }

            function onInputFocus() {
                setSelectOptionTags(filteredSelectableValues);
            }

            setSelectOptionTags($scope.filter.selectableValues);

            if (!_.isEmpty($scope.filter.values)) {
                $scope.selectedOptions = $scope.filter.values;
                $scope.selectFilterValue($scope.filter.values, $scope.filter);
            }

            function setSelectOptionTags(selectableValues) {
                // Need to massage values to fit object property structure required by select2
                // Using reducer to skip null values, map would leave an empty slot
                $scope.selectOptions.tags = _.reduce(selectableValues, function (acc, item) {
                    if (!_.isNull(item.key) && !_.isNull(item.value)) {
                        let key = _.unescape(sanitizeHtml(item.key.toString(), SanitizeHtmlOptionsAllEmpty));
                        let value = _.unescape(sanitizeHtml(item.value.toString().trim(), SanitizeHtmlOptionsAllEmpty)); // TA-34967
                        // Use value as both id & text
                        // WILL_BE_DEPRECATED once group by id/name association is removed
                        acc.push({ id: $scope.filter.has_set_values ? key : value, text: value, key: key });
                    }
                    return acc;
                }, []);
            }

            function widgetFilterMessageUserAction(continueFiltering = false) {
                $scope.showFilterResultsWarningMessage = false;
                stopBackendCalls = !continueFiltering;

                if (continueFiltering) {
                    isContinuingSearch = true;
                    $("#"+select2ElementId).select2('open');
                    $("#"+select2ElementId).select2('search', queryString.term);
                }
            }

            function getMinimumInputLength() {
                return (!isContinuingSearch && $scope.filter.selectableValues.length > 500) ? 2 : null;
            }

            function _updateSelectableOptions(payload) {
                const {targetId, selectedValues} = payload;
                if ($scope.id === targetId) {
                    const values = _.map(selectedValues, (value) => {
                        if(!_.isUndefined(value.key)) {
                            return value.key;
                        }
                        return value.id;
                    });
                    filteredSelectableValues = _.filter(initialSelectableValues,filter => !values.includes(filter.key));
                    if(filteredSelectableValues.length === 0) {
                        $scope.selectOptions.tags = $scope.filter.values;
                    } else {
                        $scope.filter.selectableValues = filteredSelectableValues;
                        setSelectOptionTags($scope.filter.selectableValues);
                    }
                    $scope.selectOptions.rebuild = true;
                }
            }
        }
    }
}

/**
 * Handle number range selection for filters
 */
function filterNumberRangeSelect() {
    return {
        restrict: 'A',
        scope: {
            filter: '='
        }
    }
}
