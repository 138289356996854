<template>
  <section>
    <HelpMessage :help="help" />
    <section v-if="isInfoMode">
      <ChartShimmer v-if="isLoading" :shimmer-style="chartStyle" />
      <article v-else-if="errorMessage" class="message is-warning">
        <div class="message-header">
          <p>{{ $t('Warning') }}</p>
          <button class="delete" type="button" aria-label="delete"></button>
        </div>
        <div class="message-body">
          <span>{{ errorMessage }}</span>
        </div>
      </article>
      <article
        v-else-if="isDataEmpty"
        class="is-flex is-align-items-center is-justify-content-center widget-display is-width-100"
      >
        <div class="message-body is-flex loaded-panel-drill">
          <i class="icon pr-5 is-size-4" :class="widgetTypeIcon"></i>
          <p>{{ $t('No data found for the dashboard date range') }}</p>
        </div>
      </article>
    </section>
    <ChartContext v-else :context="context"></ChartContext>
  </section>
</template>

<script>
import ChartShimmer from '@/modules/core/charts/components/ChartShimmer';
import ChartContext from '@/modules/core/charts/am5/base/components/ChartContext.vue';
import HelpMessage from '@/modules/core/charts/am5/base/components/HelpMessage.vue';
import { WidgetType } from '@/modules/ta/widget/widget.constants';

export default {
  name: 'ChartWrapper',
  components: {
    ChartContext,
    ChartShimmer,
    HelpMessage,
  },
  props: {
    errorMessage: { type: String, required: false, default: undefined },
    isDataEmpty: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    context: {
      type: Function,
      required: true,
    },
    help: {
      type: [Object, String],
      default: null,
    },
    disableErrorMessages: {
      type: Boolean,
      default: false,
    },
    widgetType: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    widgetTypeIcon() {
      const widgetIcons = {
        [WidgetType.TYPE_BARCHART]: 'icomoon-barchart',
        [WidgetType.TYPE_LINECHART]: 'icomoon-linechart',
        [WidgetType.TYPE_COMBINATIONCHART]: 'icomoon-combinationchart',
        [WidgetType.TYPE_FUNNELCHART]: 'icomoon-funnelchart',
        [WidgetType.TYPE_PIECHART]: 'icomoon-piechart',
      };
      return this.widgetType ? widgetIcons[this.widgetType] : '';
    },
    isInfoMode() {
      return this.disableErrorMessages && (this.isLoading || this.errorMessage || this.isDataEmpty);
    },
  },
};
</script>
<style scoped lang="scss">
.loaded-panel-drill {
  background-color: $panel-background-color;
}
section {
  width: 100%;
  height: 100%;
}
</style>
