import { BaseResource } from '@/modules/core/app/resources/BaseResource';

export class WidgetDataResource extends BaseResource {
  /**
   * @abstract
   */
  /* eslint-disable no-unused-vars */
  async getWidgetData(widget, params) {
    Logger.log(i18n.$t('Not implemented'), Logger.LEVEL_ERROR);
  }
}
