'use strict';
import angular from 'angular';
import './base/widget.resize.resource';
import './base/widget.resize.directives';
import './base/widget.resize.services';

angular.module('widget.resize', [
    'widget.resize.resources',
    'widget.resize.directives',
    'widget.resize.services'
]);