'use strict';
import angular from 'angular';
import './widget.geochart.ctrls';
import './widget.geochart.directives';
import './widget.geochart.services';

angular.module('widget.geochart', [
    'widget.geochart.ctrls',
    'widget.geochart.directives',
    'widget.geochart.services',
]);