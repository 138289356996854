import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { GenericModel } from '@/modules/core/app/models/GenericModel';

export class TimeGroupingResource extends BaseResource {
  constructor() {
    super(`enums/${RouteGroup.TIMEGROUPINGS}`);
  }

  /**
   * @param params
   * @returns {*}
   */
  getGroupings(params) {
    return this.getData(GenericModel, '', params);
  }
}

export default new TimeGroupingResource();
