'use strict';
import angular from 'angular';

angular.module('core.tag.constants', [])
    .constant('TagColumns', {
        ID: 'id',
        TAG: 'tag',
        ASSOCIATED_TAG_VALUES: 'associated_tag_values',
        COLOR: 'color'
    })
    .constant('TagRouteParams', {
        STATE_NAME: 'tags',
        URL: '/tags'
    })
    .constant('TagPickerSelectConstant', {
        PLACEHOLDER_ID: 'TagPickerSelectConstant:placeholder',
        PLACEHOLDER_TAGS: 'Click or start typing to add a tag',
        PLACEHOLDER_NO_TAGS: 'Start typing to create a tag...'
    });