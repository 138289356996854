'use strict';
import angular from 'angular';

angular.module('widget.builder.resources', [])
    .factory('WidgetBuilderResourceFactory', WidgetBuilderResourceFactory);

/**
 * @ngInject
 */
function WidgetBuilderResourceFactory(
    $q,
    Restangular,
    LibraryFactory,
    WidgetLibraryResourceFactory
) {
    var dash = Restangular.all('dash');
    var widgets = dash.all('widgets');
    var library = widgets.all('library');

    return {
        getLibraryWidget: getLibraryWidgets
    };

    function getLibraryWidgets(queryParams) {
        return LibraryFactory.getLibraryItems(WidgetLibraryResourceFactory.getWidgetsForDisplay, queryParams);
    }
}