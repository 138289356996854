'use strict';
import angular from 'angular';
import _ from 'lodash';

import endCustomerDetailsHtmlUrl from './endcustomer.details.html';

angular.module('core.endcustomer.components')
    .component('endCustomerDetails', {
        templateUrl: endCustomerDetailsHtmlUrl,
        controllerAs: 'vm',
        controller: EndCustomerDetailsController
    });

function EndCustomerDetailsController(
    WidgetFactory,
    DetailsModalFactory,
    EndCustomerResource,
    EndCustomerColumns
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.getColumn = getColumn;
    vm.leadsDatagridState = WidgetFactory.getDefaultState();
    vm.leadsDatagrid = null;
    vm.EndCustomerColumns = EndCustomerColumns;

    function $onInit() {
        vm.model = DetailsModalFactory.getModel();
        vm.metadata = DetailsModalFactory.getMetadata();

        EndCustomerResource.getLeadsGrid(vm.model.id).then(function (json) {
            vm.leadsDatagrid = json.plain()[0];
        });
    }

    /**
     * @param field
     * @returns {any}
     */
    function getColumn(field) {
        return _.find(vm.metadata, {field: field});
    }
}