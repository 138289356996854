import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  async addLoader({}, loader) {
    commits.guide.addLoader(loader);
  },
  async removeLoader({}, loader) {
    commits.guide.removeLoader(loader);
  },
  resetLoaders() {
    commits.guide.resetLoaders();
  },
  async addAutoPlayGuide({}, guideName) {
    commits.guide.addAutoPlayGuide(guideName);
  },
  async removeAutoPlayGuide({}, guideName) {
    commits.guide.removeAutoPlayGuide(guideName);
  },
};
