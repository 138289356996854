'use strict';
import angular from 'angular';
import $ from 'jquery';
import './connectionstatus.constants';
import './base/connectionstatus.resources';
import './base/connectionstatus.directives';
import './base/connectionstatus.services';

import connectionStatusHtmlUrl from './base/connectionstatus.html';

angular.module('core.connectionstatus', [
    'core.connectionstatus.constants',
    'core.connectionstatus.resources',
    'core.connectionstatus.directives',
    'core.connectionstatus.services'
])
    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {

        $stateProvider
            .state('connectionstatus', {
                parent:'layout',
                url: '/connectionstatus',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('connectionstatus'),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_CONNECTION_STATUS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: connectionStatusHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);