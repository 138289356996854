import { Series } from '@/modules/core/charts/models/Series';
import { Axis } from '@/modules/core/charts/models/Axis';
import { CircleBullet } from '@/modules/core/charts/models/CircleBullet';

/**
 * @class XYSeries
 * @extends Series
 */
export class XYSeries extends Series {
  /**
   * Set true if series category is date
   * @var {Boolean}
   */
  isCategoryDate;

  /**
   * Set true if series is stacked
   * @var {Boolean}
   */
  stacked;

  /**
   * Assign series specific axis
   * @var {Axis}
   */
  axis;

  /**
   * Index of the axis among all value axis
   * Index starts from zero
   * @var {Number}
   */
  axisIndex;

  /**
   * Set true if to show labels for each data points
   * @var {Boolean}
   */
  showMetricLabels;

  /**
   * Give specific color to series
   * @var {String}
   */
  color;

  /**
   * Set true if circular bullets required in series data point
   * @var {Boolean}
   * @default true
   */
  isCircularBullets;

  /**
   * Creates a bullet with a textual label.
   *
   * @var {CircleBullet}
   */
  circleBullet;

  /**
   * Array of colors to be assigned to the individual data points in the series. Assignment is done by rotation.
   * @var {Array}
   */
  colorSet;

  /**
   * Elements relative width. Value is treated as a percent.
   *
   * @var {Number}
   */
  columnWidth;

  /**
   * The value will range from 0 to 1
   * This is set to manage transparent in line stroke
   * @var {Number}
   */
  strokeOpacity;

  /**
   * Stroke width for line and bar charts
   * @var {Number}
   */
  strokeWidth;

  /**
   * Horizontal tension setting of the line (0-1).
   * Can be used to create smoothed lines.
   * @var {Number}
   */
  tension;

  /**
   * Item's open numeric value
   * @var {Number}
   */
  openValue;

  /**
   * Setting to false will make series overlap with other series
   * @var {Boolean}
   */
  isClustered;

  /**
   * Control opacity of element itself
   * @var {String}
   */
  opacity;

  /**
   * Column radius for top left.
   * @var {number}
   */
  columnCornerRadiusTopLeft;

  /**
   * Column radius for top right.
   * @var {number}
   */
  columnCornerRadiusTopRight;

  /**
   * Column radius for bottom left.
   * @var {number}
   */
  columnCornerRadiusBottomLeft;

  /**
   * Column radius for bottom right.
   * @var {number}
   */
  columnCornerRadiusBottomRight;

  /**
   * Give label to series
   * @var {String}
   */
  valueLabel;

  /**
   * Item value label's dx
   * @var {Number}
   */
  valueDx;

  /**
   * Item value label's color
   * @var {String}
   */
  labelFill;

  /**
   * Set true if to show circular bullets at ceratin data target
   * @var {Boolean}
   */
  showCircularBullet;

  /**
   * Item value target for circular bullet
   * @var {Number}
   */
  circularBulletTarget;

  /**
   * Color for the bullet
   * @var {String}
   */
  bulletColor;

  /**
   * Set true if to show image bullets at ceratin data target
   * @var {Boolean}
   */

  showImageBullets;

  /**
   * Item value target for image bullets
   * @var {Number}
   */
  imageBulletTarget;

  /**
   * width for the image bullet
   * @var {Number}
   */
  imageBulletWidth;

  /**
   * height for the image bullet
   * @var {Number}
   */
  imageBulletHeight;

  /**
   * dY for the image bullet
   * @var {Number}
   */
  imageBulletDy;

  /**
   * tooltip for the circular bullet
   * @var {Number}
   */
  circleBulletTooltip;

  /**
   * Item value target for rectangle bullets
   * @var {Number}
   */
  rectangleBulletTarget;

  /**
   * Color for the rectangular bullet
   * @var {String}
   */
  RectangleBulletColor;

  /**
   * tooltip for the rectangular bullet
   * @var {Number}
   */
  rectangleBulletTooltip;

  /**
   * Color for the rectangular bullet stroke.
   * @var {String}
   */
  RectangleBulletStrokeColor;

  constructor(model = {}) {
    super(model);
    const { isCircularBullets = true } = model;
    this.date = model.date;
    this.stacked = model.stacked;
    this.axis = model.axis ? new Axis(model.axis) : null;
    this.isCategoryDate = model.isCategoryDate;
    this.showMetricLabels = model.showMetricLabels;
    this.color = model.color;
    this.colorSet = model.colorSet;
    this.opacity = model.opacity;
    this.isCircularBullets = isCircularBullets;
    this.circleBullet = model.circleBullet ? new CircleBullet(model.circleBullet) : null;
    this.columnWidth = model.columnWidth;
    this.strokeOpacity = model.strokeOpacity;
    this.strokeWidth = model.strokeWidth;
    this.tension = model.tension;
    this.openValue = model.openValue;
    this.isClustered = model.isClustered;
    this.axisIndex = model.axisIndex;
    this.columnCornerRadiusTopLeft = model.columnCornerRadiusTopLeft;
    this.columnCornerRadiusTopRight = model.columnCornerRadiusTopRight;
    this.columnCornerRadiusBottomLeft = model.columnCornerRadiusBottomLeft;
    this.columnCornerRadiusBottomRight = model.columnCornerRadiusBottomRight;
    this.patternSet = model.patternSet;
    this.valueLabel = model.valueLabel;
    this.valueDx = model.valueDx;
    this.labelFill = model.labelFill;
    this.showCircularBullet = model.showCircularBullet;
    this.circularBulletTarget = model.circularBulletTarget;
    this.bulletColor = model.bulletColor;
    this.showImageBullets = model.showImageBullets;
    this.imageBulletTarget = model.imageBulletTarget;
    this.imageBulletWidth = model.imageBulletWidth;
    this.imageBulletHeight = model.imageBulletHeight;
    this.imageBulletDy = model.imageBulletDy;
    this.circleBulletTooltip = model.circleBulletTooltip;
    this.showRectangleBullet = model.showRectangleBullet;
    this.rectangleBulletTarget = model.rectangleBulletTarget;
    this.RectangleBulletColor = model.RectangleBulletColor;
    this.rectangleBulletTooltip = model.rectangleBulletTooltip;
    this.RectangleBulletStrokeColor = model.RectangleBulletStrokeColor;
  }
}
