"use strict";
import $ from 'jquery';
import _ from 'lodash';

var Core = $.core.admin.user = {

    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {

    },
    events: function () {
        Core.userTypeBinding();
        Core.overridePagesFromReportingProfileBinding();
        Core.welcomeEmailBinding();
        Core.restoreSelect2();
        Core.toggleFields($('#user_type').val());
        Core.toggleOverridePagesFromReportingProfile();
        Core.reportingProfiles();
        Core.userType();

        var datePickerOptions = {};
        datePickerOptions.dateFormat = "yy-mm-dd";
        $.core.main.initDatePicker($('#bi_weekly_start_date'), datePickerOptions);
        $.core.main.initDatePicker($('#send_until_date'), datePickerOptions);
        $.core.main.initDatePicker($('#data_start_date'), datePickerOptions);
    },
    reportingProfiles: function() {
        $('#cluster_id').change(function(e){
            $.post($.fn.actionUrl('reportingProfile/get_user_creation_cluster_filtered_reporting_profiles'),{cluster_id: $(e.currentTarget).val()}, function(json){
                var reportingProfiles = _.isObject(json.data) ? json.data.items : null;
                $.core.main.buildSelect2($('#reporting_profile_id'), {valueName: 'id', textName: 'name', width: '50%', multiple: false, placeholder: 'Select a data profile...', allowClear: true}, reportingProfiles, []);
                $('#reporting_profile_id').val(null).trigger('change');
            });
        });
    },
    userType: function() {
        $('#user_type').change(function(e){
            $.post($.fn.actionUrl('role/get_user_type_filtered_roles'),{user_type: $(e.currentTarget).val()}, function(json){
                let roles = json.data.roles;
                if (roles && roles.length < 1) {
                    $('#role_id').val('');
                }
                $.core.main.buildSelect2($('#role_id'), {valueName: 'id', textName: 'name', width: '50%', multiple: false, placeholder: 'Select a role...', allowClear: true}, roles, []);
                $('#role_id').select2('container').css('display', 'inline-block');
            });
            if($(e.currentTarget).val() === 'client'){
                $("select#io_tool_role_id option").each(function() { this.selected = (this.text === 'Client'); });
                $('#io_tool_role_id_disable').attr("disabled", false);
                $('#io_tool_role_id_disable').val($("#io_tool_role_id").val());
                $('#io_tool_role_id').attr("readonly", true);
                $('#io_tool_role_id').attr("disabled", true);
            }else{
                $('#io_tool_role_id').attr("readonly", false);
                $('#io_tool_role_id').attr("disabled", false);
                $('#io_tool_role_id_disable').attr("disabled", true);
            }
        });
        if($('#user_type').val() === 'client'){
            $("select#io_tool_role_id option").each(function() { this.selected = (this.text === 'Client'); });
            $('#io_tool_role_id_disable').attr("disabled", false);
            $('#io_tool_role_id_disable').val($("#io_tool_role_id").val());
            $('#io_tool_role_id').attr("readonly", true);
            $('#io_tool_role_id').attr("disabled", true);
        }else{
            $('#io_tool_role_id').attr("readonly", false);
            $('#io_tool_role_id').attr("disabled", false);
            $('#io_tool_role_id_disable').attr("disabled", true);
        }
    },
    userTypeBinding: function() {
        $('#user_type').change(function() {
            Core.toggleFields($(this).val());
        });
    },
    overridePagesFromReportingProfileBinding: function() {
        $('#override_dashboard_pages_from_reporting_profile').change(function() {
            Core.toggleOverridePagesFromReportingProfile();
        });
    },
    restoreSelect2: function() {
        var $original_override_dashboard_page_ids = $('#override_dashboard_page_ids').val();
        var $original_reporting_profile_id = $('#reporting_profile_id').val();
        var $original_role_id = $('#role_id').val();
        var $original_cluster_id = $('#cluster_id').val();
        var $original_default_home_page = $('#default_home_page').val();
        var $original_io_tool_queue_ids = $('#io_tool_queue_ids').val();

        $('#form-cancel').on('click', function(e){

            //some multiselect select2s may or may not exist on the page depending on the instance config
            //only try to reset them if they exist (length > 0)
            if ($('#override_dashboard_page_ids').length)
            {
                $('#override_dashboard_page_ids').select2('val', $original_override_dashboard_page_ids.split(','));
            }

            $('#reporting_profile_id').select2('val', $original_reporting_profile_id);
            $('#role_id').select2('val', $original_role_id);
            $('#cluster_id').select2('val', $original_cluster_id);
            $('#default_home_page').select2('val', $original_default_home_page);

            if ($('#io_tool_queue_ids').length)
            {
                $('#io_tool_queue_ids').select2('val', $original_io_tool_queue_ids.split(','));
            }
        });
    },
    toggleOverridePagesFromReportingProfile: function() {
        var $onlyDashboardOverride = $('.only-dashboard-override');

        $onlyDashboardOverride.hide();

        if ($('#override_dashboard_pages_from_reporting_profile').val() == 'yes')
        {
            $('#override_dashboard_page_ids').select2('container').css('display', 'inline-block');
            $onlyDashboardOverride.show();
        }

    },
    toggleFields: function(val) {
        let $onlyClients = $('.only-clients');
        let $onlyAgents = $('.only-agents');
        let $notSuperadmins = $('.not-superadmins');
        let $notClients = $('.not-clients');
        let $onlyClusterAdmins = $('.only-cluster-admins');
        let $onlyNui = $('.only-nui');

        $onlyClients.hide();
        $onlyAgents.hide();
        $notSuperadmins.hide();
        $notClients.hide();
        $onlyClusterAdmins.hide();
        $onlyNui.show();

        if (val == 'client') {
            $onlyClients.show();
        }
        else if (val == 'agent') {
            $onlyAgents.show();
        }
        else if (val == 'cluster_admin') {
            $onlyClusterAdmins.show();
        }

        if (val != '' && val != 'superadmin') {
            $notSuperadmins.show();
        }
        if (val != '' && val != 'client') {
            $notClients.show();
        }
    },
    welcomeEmailBinding: function() {
        $('#send-welcome-email').hide();

        $('#pwd').bind('textchange', function() {
            if ($(this).val() == '') {
                $('#send-welcome-email').hide();
            }
            else {
                $('#send-welcome-email').show();
            }
        });
    }
};