import FunnelChartWidgetRenderService from '@/modules/ta/widget/services/types/FunnelChartWidgetRenderService';

export class LegacyFunnelChartWidgetRenderService extends FunnelChartWidgetRenderService {
  /**
   * @var Widget
   */
  _widget;

  /**
   * @var array
   */
  _chartPalette;

  /**
   * @var boolean
   */
  _hasComparisonData;

  /**
   * @var boolean
   */
  _isExporting;

  constructor(widget, chartPalette = [], hasComparisonData = false, isExporting = false) {
    super(widget.id);
    this._widget = widget;
    this._chartPalette = widget.metadata.chart_palette ?? chartPalette;
    this._hasComparisonData = hasComparisonData;
    this._isExporting = isExporting;
  }

  getWidget() {
    return this._widget;
  }

  /**
   * @returns {boolean}
   */
  isExporting() {
    return this._isExporting;
  }

  getFullChartPalette() {
    return this._chartPalette;
  }

  /**
   * @param columnIndex
   * @returns {string}
   */
  getChartPalette(columnIndex) {
    return this._chartPalette[columnIndex % this._chartPalette.length];
  }

  /**
   * @returns {{}}
   */
  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
      labels: this.hasComparison() ? [this.getPeriodLabel()] : null,
      cursorOverStyle: this.getCursorOverStyle(),
      hasComparison: this.hasComparison(),
    };
  }

  hasComparison() {
    return this._hasComparisonData;
  }
}
