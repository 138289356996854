import { isEmpty, orderBy } from 'lodash';
import { PlotType } from '@/modules/ta/widget/widget.constants';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import ChartWidgetRenderService from '@/modules/ta/widget/services/types/ChartWidgetRenderService';
import { BubbleChartConfig } from '@/modules/core/charts/am5/bubble/models/BubbleChartConfig';
import { deepClone } from '@/modules/core/helper';

export default class BubbleChartWidgetRenderService extends ChartWidgetRenderService {
  /**
   * @param config
   * @returns {BubbleChartConfig}
   */
  initConfig(config) {
    return new BubbleChartConfig(config);
  }

  /**
   * @param datum
   * @returns {any}
   * @private
   */
  _addComparisonSuffixToKeys(datum) {
    return Object.fromEntries(
      Object.entries(datum).map(([key, value]) => [this.appendComparisonSuffixToField(key), value])
    );
  }

  /**
   * @param datum
   * @param isPriorPeriod
   * @returns {{log_date}|*}
   * @private
   */
  _preProcessDatum(datum, isPriorPeriod = false) {
    if (this._isCurrentGroupedColumnDate()) {
      datum.log_date = this._getVisibleDate(datum);
    }

    if (isPriorPeriod) {
      datum = this._addComparisonSuffixToKeys(datum);
    }
    return datum;
  }

  /**
   * @param data
   * @param newNonGroupedColumnsMap
   * @param isPriorPeriod
   * @returns {({log_date}|*)[]}
   */
  preProcessData(data, newNonGroupedColumnsMap, isPriorPeriod = false) {
    return data.map((datum) => this._preProcessDatum(datum, isPriorPeriod));
  }

  removeBubblesWithNullValues(data, fields, isComparisonData) {
    return data.filter((dataItem) =>
      fields.some(
        (field) => dataItem[isComparisonData ? this.appendComparisonSuffixToField(field) : field]
      )
    );
  }

  applyMaxBubblesProperty(data, maxBubbles) {
    const clonedData = deepClone(data);
    return clonedData.slice(0, maxBubbles);
  }

  /**
   * @param currentPeriodData
   * @param priorPeriodData
   * @returns {*}
   * @private
   */
  _getComparisonChartData(currentPeriodData, priorPeriodData) {
    const maxIndex =
      currentPeriodData.length >= priorPeriodData.length
        ? currentPeriodData.length
        : priorPeriodData.length;
    const comparisonChartData = [];
    for (let index = 0; index < maxIndex; index++) {
      const currentDatum = currentPeriodData[index];
      const priorDatum = priorPeriodData[index];
      const categoryValue = currentDatum
        ? currentDatum[this.getCurrentGroupByField()]
        : Constant.NA;
      comparisonChartData.push({
        ...currentDatum,
        ...priorDatum,
        [this.getCurrentGroupByField()]: categoryValue,
      });
    }
    return comparisonChartData;
  }

  filterData(data, metadata, isComparisonData = false) {
    const { max_bubbles } = this.getDrawOptions();
    const bubbleFields = this.getBubbleColumns().map((column) => column.field);

    const sortByField = isComparisonData
      ? this.appendComparisonSuffixToField(metadata.sort_by)
      : metadata.sort_by;
    const sortedData = data ? orderBy(data, sortByField, metadata.sort_order) : null;

    const nonNullBubbles = this.removeBubblesWithNullValues(
      sortedData,
      bubbleFields,
      isComparisonData
    );

    return this.applyMaxBubblesProperty(nonNullBubbles, max_bubbles);
  }

  /**
   * @returns {*}
   */
  getChartData() {
    const { metadata } = this.getWidget();

    const newNonGroupedColumnsMap = {};
    if (!isEmpty(newNonGroupedColumnsMap)) {
      this.setNonGroupedColumns(Object.values(newNonGroupedColumnsMap));
    }

    let currentChartData = this.filterData(
      this.preProcessData(this.currentPeriodData, newNonGroupedColumnsMap),
      metadata
    );

    let priorPeriodData = null;

    if (this.hasComparison()) {
      priorPeriodData = this.filterData(
        this.preProcessData(this.priorPeriodData, newNonGroupedColumnsMap, true),
        metadata,
        true
      );
      currentChartData = this._getComparisonChartData(currentChartData, priorPeriodData);
    }

    return currentChartData;
  }

  /**
   * @param field
   * @returns {string}
   */
  appendComparisonSuffixToField(field) {
    return `${field}_comparison`;
  }

  /**
   * @param field
   * @returns {*}
   */
  getColumnFromField(field) {
    return this.nonGroupedColumns.find((column) =>
      [column.field, this.appendComparisonSuffixToField(column.field)].includes(field)
    );
  }

  /**
   * @param label
   * @returns {string}
   */
  appendComparisonSuffixToLabel(label) {
    return `${label} Comparison`;
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {{showLabels: *, tooltipEnabled: *, hideGridLines: boolean}}
   */
  // eslint-disable-next-line no-unused-vars
  getCommonAxisProperties(column, columnIndex) {
    const drawOptions = this.getDrawOptions();

    return {
      showLabels: drawOptions.show_labels,
      hideGridLines: drawOptions.hide_grid_lines,
      tooltipEnabled: drawOptions.has_tooltip,
      dataItemFormat: column.format,
    };
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {{titleText, inversed: boolean}}
   */
  // eslint-disable-next-line no-unused-vars
  getXAxisProperties(column, columnIndex) {
    const drawOptions = this.getDrawOptions();
    return {
      inversed: drawOptions.is_rotated,
      titleText: column?.label,
      extraMax: 0.1,
      extraMin: 0.1,
    };
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {{}}
   */
  getYAxisProperties(column, columnIndex) {
    return {
      opposite: this.isYAxisMoved(),
      titleText: column?.label,
      titleColor: this.getChartPalette(columnIndex),
      extraMax: 0.1,
      extraMin: 0.1,
    };
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {*}
   */
  getXAxis(column, columnIndex) {
    return {
      ...this.getCommonAxisProperties(column, columnIndex),
      ...this.getXAxisProperties(column, columnIndex),
    };
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {{min: (number|null), showLabels: *, tooltipEnabled: *, hideGridLines: boolean}}
   */
  getYAxis(column, columnIndex) {
    return {
      ...this.getCommonAxisProperties(column, columnIndex),
      ...this.getYAxisProperties(column, columnIndex),
    };
  }

  /**
   * @returns {{}[]}
   */
  getChartXAxis() {
    return this.getXAxis(this.nonGroupedColumns[0], 0);
  }

  /**
   * @returns {*}
   */
  getChartYAxis() {
    return this.getYAxis(this.nonGroupedColumns[1], 1);
  }

  /**
   * @returns {boolean}
   */
  isStacked() {
    return [PlotType.STACKED, PlotType.FULL_STACKED].includes(this.getDrawOptions().plot_type);
  }

  /**
   *
   * @returns {boolean}
   */
  isDeepStacked() {
    return [PlotType.DEEP_STACKED, PlotType.AREA].includes(this.getDrawOptions().plot_type);
  }

  /**
   * @returns {boolean}
   */
  isFullStacked() {
    return [PlotType.FULL_STACKED].includes(this.getDrawOptions().plot_type);
  }

  /**
   * @returns {boolean}
   */
  isSmoothedLine() {
    return this.getDrawOptions().is_smoothed_line;
  }

  /**
   * @returns {boolean}
   */
  isRotated() {
    return this.getDrawOptions().is_rotated;
  }

  /**
   * @returns {boolean}
   */
  isYAxisMoved() {
    const { is_y_axis_moved, is_normalized } = this.getDrawOptions();
    return is_y_axis_moved && !is_normalized;
  }

  /**
   * @returns {boolean}
   */
  isLineFillOpacityRequired() {
    return this.isStacked() || this.isDeepStacked();
  }

  /**
   *
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @param isComparison
   * @returns {{}}
   */
  getSingleSeries(column, columnIndex, axisIndex, isComparison = false) {
    const drawOptions = this.getDrawOptions();
    const xField = this.getXAxisColumn().field;
    const yField = this.getYAxisColumn().field;
    return {
      name: isComparison ? this.appendComparisonSuffixToLabel(column.label) : column.label,
      dataItemFormat: column.format,
      value: isComparison ? this.appendComparisonSuffixToField(column.field) : column.field,
      valueX: isComparison ? this.appendComparisonSuffixToField(xField) : xField,
      valueY: isComparison ? this.appendComparisonSuffixToField(yField) : yField,
      category: isComparison
        ? this.appendComparisonSuffixToField(this.getCurrentGroupByField())
        : this.getCurrentGroupByField(),
      shape: drawOptions.bubble_shape,
      fill: isComparison ? this.getComparisonBubbleColor() : this.getChartPalette(columnIndex),
      fillOpacity: drawOptions.shape_opacity / 100,
      tooltipDisabled: !drawOptions.has_tooltip,
      showValuesOnShapes: drawOptions.show_values_on_shapes,
      maxBubbles: drawOptions.max_bubbles,
      showShapeShadow: drawOptions.show_shape_shadow,
      shapeSize: drawOptions.shape_size,
    };
  }

  /**
   * @returns {*}
   */
  getComparisonBubbleColor() {
    const widget = this.getWidget();
    return widget?.metadata?.chartPalette[1];
  }

  /**
   * @param hasComparison
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @returns {[]}
   */
  getSeriesForColumn(hasComparison, column, columnIndex, axisIndex) {
    const columnSeries = [];
    if (hasComparison) {
      columnSeries.push(this.getSingleSeries(column, columnIndex, axisIndex, true));
    }
    columnSeries.push(this.getSingleSeries(column, columnIndex, axisIndex));
    return columnSeries;
  }

  getXAxisColumn() {
    return this.nonGroupedColumns[0];
  }

  getYAxisColumn() {
    return this.nonGroupedColumns[1];
  }

  getBubbleColumns() {
    /*
        We are using slice(2, 3) here. Because first and second nonGroupedColumns are used for x and y axes,
        and we are accepting only one metric for plotting bubbles and rest we are ignoring
     */
    return this.nonGroupedColumns.slice(2, 3);
  }

  /**
   * @returns {[]}
   */
  getAllSeries() {
    let allSeries = [];
    const hasComparison = this.hasComparison();
    this.getBubbleColumns().forEach((column, index) => {
      allSeries = allSeries.concat(this.getSeriesForColumn(hasComparison, column, index, index));
    });
    return allSeries;
  }

  /**
   * @returns {{fontSize: number, text: string, align: string, fontWeight: number}}
   */
  getDrillDownInfoLabel() {
    const text = `${this.getCurrentGroupByColumn().label}s for ${
      this.getPreviousGroupByColumn().label
    }`;
    return {
      text,
      align: Constant.CENTER,
      fontWeight: 600,
      fontSize: 15,
    };
  }

  /**
   * @returns {null|Array}
   */
  getChartTitles() {
    return this.canUndoDrillDown()
      ? [this.getUndoDrillDownLabel(), this.getDrillDownInfoLabel()]
      : null;
  }

  /**
   * @returns {{data: *}}
   */
  getChartConfigProperties() {
    if (this.nonGroupedColumns.length < 3) {
      return null;
    }
    const drawOptions = this.getDrawOptions();
    const series = this.getAllSeries();
    return {
      ...super.getChartConfigProperties(),
      xAxis: this.getChartXAxis(),
      yAxis: this.getChartYAxis(),
      series,
      backgroundGradient: drawOptions.background_gradient,
      gradientColor: drawOptions.gradient_color,
      hasValueScrollbar: drawOptions.has_value_scroller,
      groupByFieldName: this.getGroupedColumns()[0].label,
      fontColorPicker: drawOptions.font_color_picker,
    };
  }

  getChartSeries() {
    return [];
  }
}
