import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { Cluster } from '@/modules/ta/cluster/models/Cluster';
import { ClusterWhiteLabels } from '@/modules/ta/cluster/models/ClusterWhiteLabels';
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { Module } from '@/modules/core/app/constants/app.constants';

export class ClusterResource extends BaseEntityResource {
  constructor() {
    super(RouteGroup.CLUSTERS, Cluster);
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  async getSiblingWhiteLabels() {
    return this.getData(ClusterWhiteLabels, 'whitelabels');
  }

  /**
   *
   * @param id
   * @returns {Promise<*>}
   */
  async getClusterIdWhiteLabel(id) {
    return this.getData(ClusterWhiteLabels, `whitelabels/${id}`);
  }

  /**
   *
   * @param clusterId
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getNotificationProfilesByClusterId(clusterId = null) {
    const route =
      clusterId === null
        ? `${this.routeGroup}/notificationprofiles`
        : `${this.routeGroup}/notificationprofiles/${clusterId}`;

    return apiAxios.get(route);
  }

  /* saves selected column preferences
   * @param data
   * @returns {Promise<*>}
   */
  async saveColumnPreferences(data) {
    return apiAxios.post(`/savecolumnpreferences/${Module.CLUSTER}`, data);
  }

  /**
   *
   * @param clusterId
   * @returns {Promise<{string}>}
   */

  async getUsages(clusterId) {
    return apiAxios.get(`/${this.routeGroup}/usage/${clusterId}`);
  }
}

export default new ClusterResource();
