import ClientResource from '@/modules/ta/client/resources/ClientResource';
import { BaseFormService } from '@/modules/core/form/services/BaseFormService';
import { dispatches } from '@/modules/core/app/helpers/store';

export class ClientFormService extends BaseFormService {
  constructor() {
    super(ClientResource);
  }

  /**
   * @param form {BaseForm}
   * @returns {Promise<*>}
   */
  async saveForm(form) {
    const result = await super.saveForm(form);
    dispatches.client.refreshClientValues();
    dispatches.dashboard.setFilterOptions();
    return result;
  }

  /**
   * @param clusterId
   * @param accountManagerUserId
   * @returns {Promise<*>}
   */
  async getAccountManagerOptions(clusterId, accountManagerUserId) {
    return this.resource.getAccountManagerOptions({
      cluster_id: clusterId,
      account_manager_user_id: accountManagerUserId,
    });
  }

  /**
   * @param clusterId
   * @returns {Promise<*>}
   */
  async getIotoolStatusByClusterId(clusterId) {
    return this.resource.getIotoolStatusByClusterId({ cluster_id: clusterId });
  }

  /**
   *
   * @param website
   * @param name
   * @returns {*}
   */
  async getLogo(website, name) {
    return this.resource.getLogo(website, name);
  }

  /**
   *
   * @returns {*}
   */
  async canActivateTapLeads() {
    return this.resource.canActivateTapLeads();
  }

  /**
   *
   * @param input
   * @returns {Promise<*>}
   */
  async getCompanyNameSuggestions(input) {
    return this.resource.getCompanyNameSuggestions(input);
  }

  /**
   *
   * @param placeId
   * @returns {Promise<*>}
   */
  async getPlaceDetails(placeId) {
    return this.resource.getPlaceDetails(placeId);
  }
}

export default new ClientFormService();
