'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import {AppConfig} from 'coreModules/shared/scripts/app.constants';
import {ColumnFormat} from 'coreModules/shared/scripts/app.constants';
import {MomentDateFormat} from 'coreModules/daterange/base/daterange.constants'
import {WidgetType} from 'coreModules/design/widget/design.widget.constants'

angular.module('widget.goal.models', [])
    .factory('WidgetGoalModelFactory', WidgetGoalModelFactory);

/**
 * @ngInject
 */
function WidgetGoalModelFactory() {
    return {
        getGoalWidget: getGoalWidget,
        getGoalHistoryWidget: getGoalHistoryWidget,
        getGoalChart: getGoalChart
    };
}


/**
 *
 * @constructor
 */
class GoalChart {

    getBalloonText() {
        return `<text>
                    <strong>Goal Progress</strong>
                    <span class="label [[performanceClass]]">Pace:</span> [[performance]]</br>
                    <span>Total [[track]]</span>
                </text>`;
    }

    balloonFunction = (item, graph) => {
        let result = graph.balloonText;
        let currentPerformance;

        if (item.values.actual_value < item.dataContext.excellent && item.values.actual_value > item.dataContext.excellentStart) {
            currentPerformance = {
                class: 'success',
                text: 'Excellent',
            };
        } else if (item.values.actual_value < item.dataContext.warning && item.values.actual_value > item.dataContext.warningStart) {
            currentPerformance = {
                class: 'warning',
                text: 'Warning',
            };
        } else {
            currentPerformance = {
                class: 'danger',
                text: 'Poor',
            };
        }

        currentPerformance.onTrack =  `${item.dataContext.columnLabel}: ${item.dataContext.actual_value.toFixed(0)} of ${item.dataContext.target.toFixed(0)}`;

        result = result.replace('[[performanceClass]]', currentPerformance.class);
        result = result.replace('[[performance]]', `${item.dataContext.percentage} ${(item.dataContext.actual_value > item.dataContext.target ? '% over your target' : '% under your target')}`);
        result = result.replace('[[track]]', currentPerformance.onTrack);

        return result;
    }

    labelFunction = (item, valueText) => {
        return `${valueText}${item.dataContext.target.toFixed(0)}  ${item.dataContext.columnLabel} `;
    }

    /**
     *
     * @param options
     */
    constructor(options = {}) {
        _.assign(this, options);

        this.addClassNames = true;
        this.autoMargins = false;
        this.balloon = options.balloon || {};
        this.balloon.borderAlpha = 0;
        this.balloon.borderThickness = 0;
        this.categoryField = 'category';
        this.chartCursor = options.chartCursor || {};
        this.chartCursor.cursorAlpha = 0;
        this.chartId = options.chartId + '-goal';
        this.columnWidth = 1;
        // this.depth3D = 10;
        this.marginBottom = 30;
        this.marginTop = 10;
        this.rotate = true;
        this.theme = 'none';
        this.type = 'serial';

        this.categoryAxis = {
            axisAlpha: 0,
            gridAlpha: 0,
            gridPosition: 'start',
            position: 'left',
        };
        

        this.graphs = [{
            classNameField: 'poorClass',
            clustered: false,
            columnWidth: 1,
            fillAlphas: 1,
            showBalloon: false,
            stackable: false,
            type: 'column',
            valueField: 'poor', 
            index: 0
        }, {
            classNameField: 'warningClass',
            clustered: false,
            columnWidth: 1,
            fillAlphas: 1,
            openField: 'warningStart',
            showBalloon: false,
            stackable: false,
            type: 'column',
            valueField: 'warning', 
            index: 1
        }, {
            classNameField: 'excellentClass',
            clustered: false,
            columnWidth: 1,
            fillAlphas: 1,
            openField: 'excellentStart',
            showBalloon: false,
            stackable: false,
            type: 'column',
            valueField: 'excellent', 
            index: 2
        }, {
            columnWidth: 3,
            labelFunction: this.labelFunction,
            labelOffset: 10,
            labelPosition: 'left',
            labelText: 'Expected Value: ',
            lineColor: '#ffffff',
            lineThickness: 4,
            noStepRisers: true,
            showBalloon: false,
            stackable: true,
            type: 'step',
            valueField: 'expected_value', 
            index: 3
        }, {
            balloonFunction: this.balloonFunction,
            balloonText: this.getBalloonText(),
            bullet: 'round',
            bulletSize: 50,
            labelPosition: 'inside',
            labelText: '[[value]]',
            labelFunction: (item, val) => item.dataContext.actual_value,
            lineColor: '#ffffff',
            noStepRisers: true,
            showBalloon: true,
            stackable: true,
            type: 'line',
            valueField: 'value', 
            index: 4
        }];
    }
}

/**
 *
 * @constructor
 */
class GoalWidget {
    /**
     *
     * @param model
     */
    constructor(model = {}) {
        this.id = model.id;
        this.campaign_id = model.campaign_id;
        this.campaign_name = model.campaign_name;
        this.client_name = model.client_name;
        this.column = model.column;
        this.column_label = model.column_label;
        this.completion = model.completion;
        this.customer_id = model.customer_id;
        this.end = model.end;
        this.expected_value = model.expected_value;
        this.name = model.name;
        this.pace = model.pace;
        this.service_id = model.service_id;
        this.smart_campaign_id = model.smart_campaign_id;
        this.start = model.start;
        this.target = model.target;
        this.threshold_1 = model.threshold_1;
        this.threshold_2 = model.threshold_2;
        this.timeframe = model.timeframe;
        this.value = model.value;
        this.line_item_id = model.line_item_id;
        this.order_id = model.order_id;
        this.flight_id = model.flight_id;
        this.category_id = model.category_id;
        this.cycle_start = model.cycle_start;
        this.cycle_end = model.cycle_end;
        this.client_group_name = model.client_group_name;
        this.cluster_name = model.cluster_name;
        this.is_old = model.is_old;
    }


    formatColumn(column) {

        switch(column.format) {

            case ColumnFormat.FORMAT_INTEGER:
            case ColumnFormat.FORMAT_DECIMAL:
            case ColumnFormat.FORMAT_PERCENT:
            case ColumnFormat.FORMAT_CURRENCY:
                return $.fn.formatNumber(this[column.field], column.format, column.precision);

            case ColumnFormat.FORMAT_DATETIME:
                return moment.unix(this[column.field]).utc().format(MomentDateFormat.MONTH_DAY_YEAR);

            case ColumnFormat.FORMAT_STRING:
                return _.truncate(this[column.field]);

            case ColumnFormat.FORMAT_LINK:
                if (column.url) {
                    return column.url;
                }

            default:
                return this[column.field];
        }
    }

    /**
     *
     * @param progress
     * @returns {string}
     */
    getIsotopeClass() {
        if (this.value >= this.expected_value) {
            return 'excellent';
        }

        const PERCENT_OFF = parseInt(((Math.abs(this.value - this.expected_value) / this.expected_value) * 100));

        if (PERCENT_OFF < parseInt(this.threshold_1)) {
            return 'excellent';
        } else if (PERCENT_OFF < parseInt(this.threshold_2)) {
            return 'average';
        } else {
            return 'bad';
        }
    }

    getProgress() {
        return this.pace;
    }

    getTapOrdersEntityColumn() {
        let entity_column = {};

        if (this.line_item_id) {
            entity_column.format = ColumnFormat.FORMAT_LINK;
            entity_column.field = 'line_item_id';
            entity_column.label = "Line Item";
            entity_column.url = '<a href="'+AppConfig.IO_URL+'#/items/viewLineItem/'+this.line_item_id+'">'+this.line_item_id+'</a>';
        } else if (this.order_id) {
            entity_column.format = ColumnFormat.FORMAT_LINK;
            entity_column.field = 'order_id';
            entity_column.label = "Order";
            entity_column.url = '<a href="'+AppConfig.IO_URL+'#/items/viewOrder/'+this.order_id+'">'+this.order_id+'</a>';
        } else if (this.flight_id) {
            entity_column.format = ColumnFormat.FORMAT_LINK;
            entity_column.field = 'flight_id';
            entity_column.label = "Flight";
            entity_column.url = '<a href="'+AppConfig.IO_URL+'#/items/viewFlight/'+this.flight_id+'">'+this.flight_id+'</a>';
        } else {
            entity_column = null;
        }
        return entity_column;
    }
}

/**
 *
 * @param metadata
 * @constructor
 */
class GoalHistoryWidget {

    /**
     *
     * @param widget
     */
    constructor(widget = {}) {
        angular.copy(widget, this);

        this.type = WidgetType.LINECHART;
        this.metadata.draw_options = {};

        this.metadata.data_columns.selected = [
            {
                field: 'expected_value',
                label: 'Expected Value',
                format: 'integer',
                precision: 0
            },
            {
                field: 'value',
                label: 'Actual Value',
                format: 'integer',
                precision: 0
            }
        ];

        this.metadata.data_columns.grouped = [
            {
                field: 'log_date',
                label: 'Date',
                format: 'datetime',
                groupby_id_field: "log_date",
                groupby_name_field: "log_date",
                groupby_field_format: "datetime",
            }
        ];

        this.metadata.dynamic.predefined_data.data = this.metadata.dynamic.predefined_data.data[0].pace_history;
    }
}

/**
 *
 * @param model
 * @returns {GoalWidget}
 */
function getGoalWidget(model) {
    return new GoalWidget(model);
}

/**
 *
 * @param widget
 * @returns {GoalHistoryWidget}
 */
function getGoalHistoryWidget(widget) {
    return new GoalHistoryWidget(widget);
}

/**
 *
 * @param model
 * @returns {GoalChart}
 */
function getGoalChart(model) {
    return new GoalChart(model);
}