(function() {
	"use strict";

	$.fn.center = function () {
		this.css("position", "absolute");
		this.css("top", ((jQuery(window).height() - this.outerHeight()) / 2) + jQuery(window).scrollTop() + "px");
		this.css("left", ((jQuery(window).width() - this.outerWidth()) / 2) + jQuery(window).scrollLeft() + "px");
		return this;
	};

	$.fn.centerHorizontal = function () {
		this.css("position", "absolute");
		this.css("left", ((jQuery(window).width() - this.outerWidth()) / 2) + jQuery(window).scrollLeft() + "px");
		return this;
	};

	$.fn.centerVertical = function () {
		this.css("position", "absolute");
		this.css("top", ((jQuery(window).height() - this.outerHeight()) / 2) + jQuery(window).scrollTop() + "px");
		return this;
	};

	$.fn.relativeCenter = function (elm) {
		this.css("position", "absolute");
		this.css("left", (elm.width() - this.outerWidth()) / 2 + elm.scrollLeft() + "px");
		this.css("top", (elm.height() - this.outerHeight()) / 2 + elm.scrollTop() + "px");
		return this;
	};

	$.fn.relativeCenterVertical = function (elm) {
		this.css("position", "absolute");
		this.css("top", (elm.height() - this.outerHeight()) / 2 + elm.scrollTop() + "px");
		return this;
	};

	$.fn.relativeCenterHorizontal = function (elm) {
		this.css("position", "absolute");
		this.css("left", (elm.width() - this.outerWidth()) / 2 + elm.scrollLeft() + "px");
		return this;
	};

	$.fn.enableElement = function () {
		this.removeClass("disabled");
		this.attr("disabled", false);
		this.attr("readonly", false);
	};

	$.fn.disableElement = function () {
		this.addClass("disabled");
		this.attr("disabled", true);
		this.attr("readonly", true);
	};

//Adds a class temporarly, useful for animation classes
	$.fn.safeAddClass = function (className) {
		var $this = this;
		$this.addClass(className);
		setTimeout(function () {
			$this.removeClass(className);
		}, 1000);
	};

    $.fn.isInViewport = function() {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = this.offset().top;
        var elemBottom = elemTop + this.height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

	$.fn.showV = function () {
		return this.css('visibility', 'visible');
	};

	$.fn.hideV = function () {
		return this.css('visibility', 'hidden');
	};

	$.fn.htmlEncode = function (value) {
		return $('<div/>').text(value).html();
	};

	$.fn.htmlDecode = function (value) {
		return $('<div/>').html(value).text();
	};

    $.fn.hasScrollBar = function() {
        return this.get(0).scrollHeight > this.height();
    };

	//TODO: Should be converted to helper functions ($.helpers), they are not $.fn jquery ext

	//Function used to extract the inputs in a form and convert the $(form) to an object array
	$.fn.serializeObject = function () {
		var o = {};
		var a = this.serializeArray();
		$.each(a, function () {
			if (o[this.name]) {
				if (!o[this.name].push) {
					o[this.name] = [o[this.name]];
				}
				o[this.name].push(this.value || '');
			} else {
				o[this.name] = this.value || '';
			}
		});
		return o;
	};

	$.fn.getDeltaPercentage = function (val1, val2) {
		//
		if (val2 == 0 && val1 > 0) {
			return 100;
		}
		else if (val2 == 0 && val1 == 0) {
			return 0;
		}
		else {
			return Math.round(Math.abs(val1 - val2) / val2 * 100, 0);
		}
	};

	$.fn.objectArraySort = function (property) { //Function is case insensitive
		var sortOrder = 1;
		if (property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}
		return function (a, b) {
			var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
			return result * sortOrder;
		}
	};

	$.fn.isLocalHost = function () {
		return document.location.hostname == "localhost"
		|| document.location.hostname == "plsoucy.tapdemo.com"
		|| document.location.hostname == "tapsoucy.tapdemo.com"
		|| document.location.hostname == "pbriss.tapdemo.com";
	};

	//This ensure that parsing does not return NaN
	$.fn.safeParseFloat = function (value) {
		return value == null ? null : parseFloat(value);
	};

	$.fn.formatNumber = function (value, format, decimals, numDigitsReduce, row, currency_discrepancy, show_currency) {
		decimals = decimals == null ? 2 : decimals;
		var showFullNumber = numDigitsReduce == null || numDigitsReduce == false;
		value = $.fn.safeParseFloat(value);
		if (value != null) {
			var negative = value < 0 ? true : false;
			value = Math.abs(value);
			switch (format) {
				case "plusOrMinus":
					return negative ? '' : '+'; //Minus is already shown by default
				case "percent":
				case "percentage":
					// Trim out any exponent notation (ex: 5.467e-7, if not the operation below will break)
					var exponent = decimals < 2 ? 2 : decimals;
					value = value.toFixed(20);
					value = Globalize.format(+(Math.round(value + 'e+' + exponent)  + 'e-' + exponent), 'n' + decimals) + '%';
					return negative ? '(' + value + ')' : value;
				case "time":
					// TODO: Use moment
					var date = (new Date).clearTime().addSeconds(value);
					var days = Math.floor(value/86400);
					var hours = date.getHours();

					if (days > 0) {
						hours += days * 24;
					}

					return ((hours > 0) ? hours + ':' : '') + date.toString('mm:ss');
				case "timestamp_to_datetime":
					return new Date(value * 1000).toString('MMM dd, yyyy | H:mm:ss');
				case "absolute":
					return Math.abs(value);
				case "currency":
					value = showFullNumber ? Globalize.format(value, 'n' + decimals) : value.reduceNumber(numDigitsReduce, 2);

					if(typeof row !== 'undefined' && (currency_discrepancy !== true || (currency_discrepancy === true && show_currency === true))) {
						value = (row && row.client_currency || (row.client_currency ? row.client_currency : $.globals.utils.clientCurrencySymbol) || ((row.client_currency ? row.client_currency : $.globals.utils.currencySymbol) || '')) + value;
					}
					return negative ? '(' + value + ')' : value;
				//numeric, decimal
				default:
					value = showFullNumber
					? Globalize.format(value, 'n' + (value < 1 && value != 0 && decimals == 0 ? 2 : decimals))
					: value.reduceNumber(numDigitsReduce, 0);
					return negative ? '(' + value + ')' : value;
			}
		}
		else {
			return '-';
		}
	};

	$.fn.getChartDateFormat = function(value, format, isTooltip) {
		var l10n = {
			'en': {
				'day-tt': '%b %e, %Y',
				'day': '%b %e',
				'month': '%b %Y',
				'year': '%Y'
			},
			'fr': {
				'day-tt': '%e %b, %Y',
				'day': '%e %b',
				'month': '%b %Y',
				'year': '%Y'
			},
			'id': {
				'day-tt': '%e %b, %Y',
				'day': '%e %b',
				'month': '%b %Y',
				'year': '%Y'
			}
		};

		var lang = window.appLocale || 'en';
		if (!l10n[lang]) {
			lang = 'en';
		}

		if (isTooltip && 'day' == format) {
			return Highcharts.dateFormat(l10n[lang]['day-tt'], Date.parse(value));
		} else {
			return Highcharts.dateFormat(l10n[lang][format], Date.parse(value))
		}
	};

	$.fn.actionUrl = function (action) {
		action = action === undefined ? '' : action;
		return $.globals.utils.rootUrl + action;
	};

	$.fn.appUrl = function (action) {
		action = action === undefined ? '' : action;
		return $.globals.utils.appUrl + action;
	};

	$.fn.taUrl = function (action) {
		action = action === undefined ? '' : action;
		return $.globals.utils.taUrl + action;
	};

    $.fn.ioUrl = function (action) {
        action = action === undefined ? '' : action;
        return $.globals.utils.ioUrl + action;
    };

    $.fn.apiUrl = function (action) {
        action = action === undefined ? '' : action;
        return $.globals.utils.apiUrl + action;
    };

	$.fn.safeEval = function (code) {
		var fn = new Function(code);
		fn();
		return false;
	};
})();
