'use strict';
import angular from 'angular';
import _ from 'lodash';
import clusterSelectorHtmlUrl from '../views/cluster.selector.html';

angular.module('cluster.directives', [])

    .directive('clusterSelector', clusterSelector);

//
//  directive
//
clusterSelector.$inject = ['ClusterService', 'AppFactory'];
function clusterSelector(ClusterService, AppFactory) {
    return {
        restrict: 'E',
        scope: {
            cluster: '=',
            changeCallback: '<',
            defaultLabel: '@'
        },
        templateUrl: clusterSelectorHtmlUrl,
        link: function(scope, el, attrs) {
            scope.defaultLabel = scope.defaultLabel || 'Select a business unit';
            if (AppFactory.getUser().isAdmin()) {
                ClusterService.cluster.getList().then(function(data){
                    scope.clusters = data.plain();
                    scope.hasClusters = !_.isEmpty(scope.clusters);
                });
            } else {
                scope.clusters = [];
                scope.hasClusters = false;
            }
        }
    }

}
