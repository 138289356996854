import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BaseDataResource } from '@/modules/core/app/resources/BaseDataResource';

export class GoalDataResource extends BaseDataResource {
  constructor() {
    super(RouteGroup.GOALS);
  }

  /**
   * @override
   *
   * @param serviceId
   * @param dataViewId
   * @param params
   * @returns {Promise<ApiColumn[]>}
   * */
  async getColumns(serviceId, dataViewId, params = {}) {
    params = { ...params, is_hidden: false };
    return super.getColumns(`data`, params);
  }
}

export default new GoalDataResource();
