import { assign, reject, isEmpty, find } from 'lodash';
import { BaseForm } from '@/modules/core/app/models/abstracts/BaseForm';
import { AppModule, AppFeature } from '@/modules/core/app/constants/app.constants';
import {
  DataProfile,
  UserStatus,
  UserType,
  LeadNotificationFrequency,
  IOToolNotificationFrequency,
  IOToolNotificationOwnActivity,
  USER_LANGUAGE,
} from '@/modules/ta/user/user.constants';
import { getters } from '@/modules/core/app/helpers/store';
import { FormSelectOption } from '@/modules/core/form/models/FormSelectOption';
import ClusterFormService from '@/modules/ta/cluster/services/ClusterFormService';
import { ClusterStatus } from '@/modules/ta/cluster/cluster.constants';
import { RoleRestrictedUserTypeSelectOption } from '@/modules/ta/user/models/RoleRestrictedUserTypeSelectOption';
import { UserRoleFormSelectOption } from '@/modules/ta/user/models/UserRoleFormSelectOption';
import { ClientSelectOption } from '@/modules/ta/client/models/ClientSelectOption';

export class UserForm extends BaseForm {
  /**
   * @var {UserRoleFormSelectOption}
   */
  roleFormSelectOptions;

  /**
   * @var {RoleRestrictedUserTypeSelectOption}
   */
  userTypeSelectOption;

  /**
   * @var {ClientSelectOption}
   */
  clientFormSelectOptions;

  /**
   * Adding default values to a User Form
   */
  constructor({ data = {}, columns = {} }) {
    super({ data, columns });
    const userSettings = getters.session.getUserSettings();
    if (super.isNew()) {
      this.data = assign(this.data, {
        status: UserStatus.ACTIVE,
        reporting_profile_id: DataProfile.KEY,
        can_access_nui: true,
        lead_notification_frequency: LeadNotificationFrequency.REAL_TIME,
        report_language: USER_LANGUAGE.PLATFORM_DEFAULT,
      });

      if (userSettings?.isModuleAvailable(AppModule.IO_TOOL)) {
        this.data = assign(this.data, {
          io_tool_notification_frequency: IOToolNotificationFrequency.NEVER,
          io_tool_notification_own_activity: IOToolNotificationOwnActivity.NO,
        });
      }
    }

    if (!userSettings?.isSuperAdmin()) {
      this.columns.user_type.values = reject(this.columns.user_type.values, {
        key: UserType.SUPERADMIN,
      });
    }

    const canSelectUsersAtAndBelow = this.data?.id === getters.session.getUser()?.id ? true : null;
    this.userTypeSelectOption = new RoleRestrictedUserTypeSelectOption({
      canSelectUsersAtAndBelow,
    });

    if (!userSettings?.isFeatureAvailable(AppFeature.CLUSTERS)) {
      this.columns.user_type.values = reject(this.columns.user_type.values, {
        key: UserType.CLUSTERADMIN,
      });
    }

    if (
      (userSettings?.isOnSupBilling && userSettings?.isModuleAvailable(AppModule.SHEETS)) ||
      userSettings?.hasTapReportsFreemiumPlatformSourceLimitation
    ) {
      this.columns.user_type.values = reject(this.columns.user_type.values, {
        key: UserType.CLIENT,
      });
      this.columns.user_type.values = reject(this.columns.user_type.values, {
        key: UserType.AGENT,
      });
    }

    this.userTypeSelectOption.setUserTypes(this.columns.user_type.values);

    const roleType = userSettings?.isModuleAvailable(AppModule.STELLAR)
      ? 'analytics,stellar'
      : 'analytics';

    this.roleFormSelectOptions = new UserRoleFormSelectOption();
    this.roleFormSelectOptions.setRoleType(roleType);
    this.roleFormSelectOptions.setUserType(this.data.user_type);
    if (!super.isNew()) {
      this.roleFormSelectOptions.setRoleId(this.data.role_id);
    }

    this.clientFormSelectOptions = new ClientSelectOption({
      valueIsObject: false,
    });
    if (!super.isNew() && this.data.client_id) {
      this.clientFormSelectOptions.setClientId(this.data.client_id);
    }

    if (
      this.columns?.timezone?.values &&
      !find(this.columns.timezone.values, { key: this.data.timezone })
    ) {
      this.columns.timezone.values.push({
        key: this.data.timezone,
        value: `${this.data.timezone} (deprecated)`,
      });
    }
  }

  getFormData() {
    const formData = new FormData();

    if (super.isNew()) {
      this.data.use_nui = this.data.can_access_nui;
    }

    if (this.data.new_image) {
      formData.append('user_image', this.data.new_image);
    }

    formData.append('model', JSON.stringify(this.data));
    return formData;
  }

  /**
   * @returns {FormSelectOption}
   */
  getClusterFormSelectOptions() {
    const params = {
      fields: 'name',
      status: ClusterStatus.ACTIVE,
    };

    if (this.data.user_type === UserType.CLIENT) {
      params.is_cluster_group = false;
    }

    return new FormSelectOption({
      valueIsObject: false,
      service: ClusterFormService,
      getData: async () => ClusterFormService.getValues(assign({}, params)),
    });
  }

  onUserTypeSelect() {
    this.unsetRole();
    this.roleFormSelectOptions.setUserType(this.data.user_type);
  }

  unsetRole() {
    this.data.role_id = null;
    this.data.role_name = null;
  }

  /**
   * @param userType
   * @param values
   */
  setPredefinedUserTypeRole(userType, values = null) {
    if (this.data.role_id) {
      return;
    }

    const defaultRole = this.getDefaultRoleBasedOnUserType(userType, values);

    if (defaultRole) {
      const { key, value } = defaultRole;
      this.data.role_id = key;
      this.data.role_name = value;
    }
  }

  /**
   * @param userType
   * @param values
   * @returns {null|*}
   */
  getDefaultRoleBasedOnUserType(userType = null, values = null) {
    const defaultRole = (values || this.roleFormSelectOptions.values).filter(
      (val) => val.user_type === userType && val.is_default
    );

    if (!isEmpty(defaultRole)) {
      return defaultRole[0];
    }

    return null;
  }
}
