"use strict";
import './init';
import angular from 'angular';
import $ from 'jquery';
import FastClick from 'fastclick';
import _ from 'lodash';
import moment from 'moment';
import { UserType } from "coreModules/shared/scripts/app.constants";

import { addLicense } from "grokModules/node_modules/@amcharts/amcharts4/core";
import { stripHtmlTags } from "../../grok/src/modules/core/app/helpers/StringHelper";

addLicense($.globals.amchartslicense);

var Core = $.core.main = {
    userSettings: {},
    currentOnboardingStep: 0,
    ajaxLoaderTimeout:null,
    formHasChanged: false, //If set to true, nav away will prompt a confirrmation
    restoreFormCallback: null, //Can be used to add extra logic to restore a custom form
    ignoreFormChange: false, //If set to true, nav away from a changed form won't promopt a confirmation
    mainForm: {}, //Used to manage the main form on the page
    originalSerializedForm: false, //Keeps a snapshot of a form before it has been modified
    navAwayUrl: '',
    dashClientSelect: $('#dash_client_filter_client_id'),
    activeNotifies: [],
    currentClient: {
        id: null,
        name: null
    },
    currentClientGroup: {
        id: null,
        name: null,
    },
    modalObj: {}, // Used for deleting a dashboard
    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {
        var $body = $('body');

        //Overall functions
        this.initNotify();
        this.tooltip($body);

        //This will ensure that clicking anywhere else then the tooltip will close tooltips
        $body.click(function (e) {
            //only buttons
            if ($(e.target).closest('div.tooltip').length === 0) {
                $('.has-tooltip:not(.persist)').tooltip('hide');
                $("div[role=tooltip]").remove();
                if ($body.hasClass('responsive')) {
                    $('div.nav-item').find('div.nav-submenu').hide();
                }
            }
        });

        // FastClick - Removes click delays on devices
        $(function() {
            FastClick.attach(document.body);
        });
    },
    events: function () {
        audiojs.events.ready(function() {var as = audiojs.createAll();});
    },
    showAjaxMainLoading: function(options) {
        if (options == null) {
            options = {};
        }

        options.timeout = options.timeout == null ? 1000 : options.timeout;
        options.text = options.text == null ? 'Loading' : options.text;
        options.top = options.top == null ? 0 : options.top;
        options.left = options.left == null ? 225 : options.left;
        options.left = $('body').hasClass('responsive') ? 80 : options.left;
        options.$rootElement =  options.$rootElement || $('body');

        if ($('#ajax_main_loading').length == 0) {
            options.$rootElement.prepend('\
                    <div id="ajax_main_loading" style="top:'+options.top+'px;left:'+options.left+'px;"></div>\
                    <div id="ajax_main_loading_container" style="left:'+options.left+'px;">\
                        <div class="sk-three-bounce">\
                            <div class="sk-child sk-bounce1"></div>\
                            <div class="sk-child sk-bounce2"></div>\
                            <div class="sk-child sk-bounce3"></div>\
                        </div>\
                        <span class="ajax_loading_text">' + options.text + '</span>\
                    </div>'
            );
        }
    },
    hideAjaxMainLoading: function() {
        $('#ajax_main_loading').remove();
        $('#ajax_main_loading_container').remove();
    },
    initOnboarding: function () {
        $('#onboarding-generate-dash a').click(function(e) {
            var $container = $(this).closest('p');
            if ($container.hasClass('disabled')) {
                e.preventDefault();
            }
        });
    },
    updateOnboarding: function (latestStep) {
        if (Core.currentOnboardingStep <= latestStep) {
            Core.currentOnboardingStep = latestStep;
            var $container = $('#onboarding-steps');
            var $textBlock = $container.find('p');

            //Reset steps
            $textBlock.addClass('disabled');
            $textBlock.filter('.current').removeClass('current');
            $textBlock.find('span.label-success').remove();

            $textBlock.filter('.has-tooltip').tooltip('disable');

            $textBlock.each(function(i) {
                //Previous step
                if (latestStep > i) {
                    $(this).append('<span class="label label-success"><span class="icon icomoon-check"></span></span>');
                    $(this).removeClass('disabled');
                    $(this).filter('.has-tooltip').tooltip('enable');
                }
                //Current step
                else if (latestStep == i) {
                    $(this).addClass('current');
                    $(this).removeClass('disabled');
                    $(this).filter('.has-tooltip').tooltip('enable');
                }
            });
        }
    },
    getSelect2DefaultOptions: function (options) {
        if (options == null)
            options = {};

        options.dropdownCssClass = 'bigdrop';
        options.width = options.width == null ? '100%' : options.width;
        options.placeholder = options.placeholder == null ? 'Select...' : options.placeholder;
        options.multiple = options.multiple == null ? true : options.multiple;
        options.valueName = options.valueName == null ? 'id' : options.valueName;
        options.textName = options.textName == null ? 'text' : options.textName;
        options.showActiveOnly = options.showActiveOnly == null ? false : options.showActiveOnly;
        options.excludeHoldStatus = options.excludeHoldStatus == null ? false : options.excludeHoldStatus;
        options.excludePendingMappingStatus = options.excludePendingMappingStatus == null ? false : options.excludePendingMappingStatus;

        return options;
    },
    buildSelect2: function ($select, options, values, preselectedValues) {
        options = Core.getSelect2DefaultOptions(options);
        values = values || [];

        //Client side data (if there is no options.url this cannot possibly be an ajax request)
        if (values.length || !options.url) {
            //Need to massage values to fit object property structure required by select2 using the keyName and valueName
            //Note: options.data could already be specified if a different object structure is required liek ServiceMultiSelect
            options.data = options.data == null ? $.map(values, function (val) {
                return {id: val[options.valueName], text: val[options.textName]};
            }) : options.data;
        }
        else { //Server side data
            options.minimumInputLength = 3;
            options.allowClear = true;
            options.ajax = // instead of writing the function to execute the request we use Select2's convenient helper
                {
                    url: $.fn.actionUrl(options.url),
                    dataType: 'json',
                    type: 'POST',
                    data: function (term) {
                        return {
                            term: term, // search term
                            limit: 10,
                            preSelectedIds: $(this).val(),
                            showActiveOnly: options.showActiveOnly,
                            excludeHoldStatus: options.excludeHoldStatus,
                            excludePendingMappingStatus: options.excludePendingMappingStatus
                        };
                    },
                    results: function (data) { // parse the results into the format expected by Select2.
                        // since we are using custom formatting functions we do not need to alter remote JSON data
                        return {results: data.results};
                    }
                };
        }

        $select.select2(options);

        //If pre selected values are not already specified in the hidden input value. Usually the case for angular
        if (preselectedValues != null && preselectedValues.length > 0) {
            var selectedValues = $.map(values, function (val) {
                if (preselectedValues.contains(val[options.valueName]))
                    return {id: val[options.valueName], text: val[options.textName]};
            });
            $select.select2('data', selectedValues);
        }
    },
    //Select2 helper function for generating service selector with service icons
    buildServiceSelect: function (options, values) {
        if (options == null)
            options = {};

        var formatItem = function (item) {
            if (!item.id) { return item.text; }
            return $(
                '<div><div class="service-square service-square-24" style="background-color:'+ item.color
                    + '"><div class="icon serviceicon-' + item.icon + ' ' + item.icon
                    + '"></div></div> ' + item.text + '</div>'
            );
        };

        //Need to massage values to fit object property structure required by select2
        options.data = $.map(values, function(val) {
            return {id: val.id, text: val.name, isConnected: val.isConnected, icon: val.icon, color: val.color, service_id: val.id.replace('service_', '')};
        });

        options.width = options.width == null ? '50%' : options.width;
        options.placeholder = options.placeholder == null ? 'Select data source...' : options.placeholder;
        options.formatResult = formatItem;
        options.multiple = options.multiple != null; //false by default

        //Element can be different based on isMultiSelect boolean
        //Also, no need to pass values, since it is specified inside options.data
        Core.buildSelect2((options.multiple ? $('#service_ids') : $('#service_id')), options, []);
    },
    initDatePicker: function($elm, options) {
        if (options == null)
            options = {};

        options.dateFormat = options.dateFormat == null ? 'M dd, yy' : options.dateFormat;
        options.firstDay = options.firstDay == null ? 0 : options.firstDay;
        options.changeMonth = options.changeMonth == null ? true : options.changeMonth;
        options.changeYear = options.changeYear == null ? true : options.changeYear;

        if (options.isDateRange) {
            options.numberOfMonths = options.numberOfMonths == null ? 1 : options.numberOfMonths;
        }

        options.onSelect = options.onSelect == null
            ? function() { $(this).trigger('textchange');}
            : options.onSelect;

        $elm.datepicker(options);
    },
    //
    // Viewing data for a specific client or client group
    //
    setViewAsMode: function (activatedServices) {
        var $dashFilters = $('#dash-filters');
        $dashFilters.addClass('has-view-specific-client');

        var $viewAsClientBar = $('#specific-client-view');
        var $viewAsClientEyeIcon = $('#dash-view-as-client-filter').find('span.icon');
        $viewAsClientBar.show();

        Core.disableServiceNavItems(activatedServices);

        //Make eye active and change tooltip
        $viewAsClientEyeIcon.addClass('active');

        Core.changeTooltipText($viewAsClientEyeIcon, 'You are now viewing data as ' + $viewAsClientBar.find('span b').html());
    },
    setCurrentClient: function (id, name) {
        this.currentClient.id = id;
        this.currentClient.name = name;
    },
    getCurrentClient: function () {
        return this.currentClient;
    },
    setCurrentClientGroup: function (id, name) {
        this.currentClientGroup.id = id;
        this.currentClientGroup.name = name;
    },
    getCurrentClientGroup: function () {
        return this.currentClientGroup;
    },
    setGlobalUser: function(userObj) {
        // Not to be used for tapclicks functionality, only for external use (walkme, etc)
        if (userObj !== window.currentUser) {
            // Used by pendo to exclude DCP logged in users
            userObj.visitorId = userObj.isRemoteLogin ? `${userObj.userEmail}-DCP` : userObj.userEmail;
            window.currentUser = userObj;
            window._tapWalkMe.currentUser = userObj;
        }
        // To allow access to user settings across legacy application
        if (userObj !== Core.userSettings) {
            Core.userSettings = userObj;
        }
    },
    disableServiceNavItems: function(activatedServices) {
        var $serviceSubmenu = $('#datasources-submenu');

        //Disable disactivated services
        if (Core.userSettings.hideInactiveServicesLeftNav) {
            $serviceSubmenu.find('li.service-item').hide();
        }
        else {
            $serviceSubmenu.find('li.service-item').addClass('disabled');
            if (Core.userSettings.type !== UserType.SUPERADMIN) {
                $serviceSubmenu.find('li.service-item').find('a').attr('href', 'javascript:void(0)');
            }
        }
        //This can be removed once the activated service list is sent sorted from the backend
        if (_.isArray(activatedServices)) {
            var sortedServiceList = $.map(activatedServices, function (service) {
                return {
                    name: $serviceSubmenu.find('li[data-service-id=' + service.service_id + ']').text().trim(),
                    id: service.service_id
                };
            }).sort($.fn.objectArraySort('-name'));

            //Only enable activated services from activatedServices list
            $.each(sortedServiceList, function (i, service) {
                var $item = $serviceSubmenu.find('li[data-service-id=' + service.id + ']');
                if (Core.userSettings.hideInactiveServicesLeftNav) {
                    $item.show();
                }
                else {
                    $item.removeClass('disabled');
                    if (Core.userSettings.type !== UserType.SUPERADMIN) {
                        $item.find('a').attr('href', $.core.setAppPath(`#/service/details/${service.id}`));
                    }
                }
                //Reorder active items to top
                $item.insertAfter($serviceSubmenu.find('li:first'));
            });
        }
    },
    reEnableServiceNavItems: function() { //Reorder the service nav list to its original sort order
        $.get($.fn.actionUrl('layout/servicelistmenu'))
            .done(function(html) {
                $('#datasources-submenu').html(html);
                enableItems();
            })
            .fail(function(html){
                enableItems();
            });

        function enableItems() {
            var $serviceSubmenu = $('#datasources-submenu');

            var sortedServiceList = $.map($serviceSubmenu.find('li'), function(elm) {
                return {name: $(elm).find('a').text().trim(), id: $(elm).data('serviceId') };
            }).sort($.fn.objectArraySort('-name'));

            $.each(sortedServiceList, function(i, service) {
                var $item = $serviceSubmenu.find('li[data-service-id=' + service.id + ']');
                $item.insertBefore($serviceSubmenu.find('li:first'));
            });

            // Always place manage service nav item first
            $serviceSubmenu.prepend($('#manage-service-nav-item'));
        }
    },
    rebuildReportingProfileSelect: function () {
        $.post($.fn.actionUrl('reportingProfile/get_cluster_filtered_reporting_profiles'), function(json){
            var reportingProfiles = json.data.items;
            $.core.main.buildSelect2($('#dash_add_reporting_profile_ids'), {valueName: 'id', textName: 'name'}, reportingProfiles, []);
        });
    },
    navMenu: function () {

        var viewportMinWidth = 1170;
        var previousWidth;
        var $body = $('body');
        var $leftFrame = $('#left-frame');
        var $showHideToggler = $('a#show-hide-nav');
        //
        ////If the connect services nav item is selected, we need to unhighlight any other selected nav item
        //$('nav#main-nav div.nav-item.add-service').click(function (e) {
        //    $('nav#main-nav div.nav-item').removeClass('selected');
        //});

        $('nav#main-nav div.nav-item-wrapper').click(function (e) {

            if (!$body.hasClass('responsive')) {
                var $menu = $(this).parent();
                var $subMenu = $menu.find('div.nav-submenu');
                var $caret = $menu.find('div.nav-item-wrapper i.icon');

                //If already shown, close the submenu
                if ($menu.hasClass('active')) {
                    $subMenu.hide();
                    $menu.removeClass('active');
                    $caret.removeClass('icomoon-caret-down').addClass('icomoon-caret-right');
                }
                else {
                    var $navItems = $('div.nav-item');
                    $navItems.removeClass('active');
                    $navItems.find('div.nav-item-wrapper i.icon').removeClass('icomoon-caret-down').addClass('icomoon-caret-right');
                    $('div.nav-submenu').hide();

                    if ($subMenu.length) {
                        if ($subMenu.is(':hidden')) {
                            $menu.addClass('active');
                            $subMenu.show();
                            $caret.removeClass('icomoon-caret-right').addClass('icomoon-caret-down');
                        }
                        else {
                            $menu.removeClass('active');
                            $subMenu.hide();
                        }
                    }
                }
            }
        });

        //Binding for highlighting current selected submenu item
        var $subMenuItems = $('nav#main-nav li.nav-submenu-item a');
        $subMenuItems.click(function (e) {
            $subMenuItems.removeClass('selected');
            $(this).addClass('selected');

            //Remove parent items selected state
            var $currentNavItem = $(this).closest('div.nav-item');
            if (!$currentNavItem.hasClass('selected')) {
                $('nav#main-nav').find('div.nav-item.selected').removeClass('selected');
                $currentNavItem.addClass('selected');
            }

            if ($leftFrame.hasClass('force-show')) {
                $leftFrame.removeClass('force-show');
                $('#device-nav-overlay').removeClass('active');
            }
        });

        /**
         * Set style rule to nav-submenu if necessary
         */
        function setNavSubMenu() {
            $('div.nav-item').on('mouseenter mouseleave', function () {
                var $elem = $(this).find('div.nav-submenu');
                if ($elem.length) {
                    var elemTop = $elem.offset().top;
                    var elemHeight = $elem.height();
                    var bodyHeight = $body.height();

                    var isEntirelyVisible = (elemTop + elemHeight <= bodyHeight);

                    if (!isEntirelyVisible) {
                        $elem.addClass('nav-submenu-bottom');
                    } else {
                        $elem.removeClass('nav-submenu-bottom');
                    }
                }
            });
        }

        /**
         * Remove handler for setting nav-submenu style
         */
        function removeNavSubMenu() {
            $('div.nav-item').off('mouseenter mouseleave');
        }

        //Set proper responsive mode and menus on resize
        //Set timeout in case other files have their own resize functions (avoids being overriden)
        setTimeout(function() {
            $(window).resize(function(e) {
                //If service manage
                if(window.location.hash === '#/service/manage'){
                    if ($(window).width() > viewportMinWidth){
                        resetServiceSections();
                    } else {
                        resetServiceSections();
                    }
                }

                if ($(window).width() < viewportMinWidth) { //Show collapsed menu
                    if (!$body.hasClass('responsive')) {
                        setCollapsedMenu();
                    }
                    $showHideToggler.hide();
                }
                else if ($(window).width() > viewportMinWidth) {
                    if (!$showHideToggler.hasClass('selected') && !amplify.getFromCache('collapseMenu')) {
                        $body.removeClass('responsive');
                        removeNavSubMenu();
                    }
                    $showHideToggler.show();
                }
                $leftFrame.removeClass('force-show');
                previousWidth = $(window).width();
                //If the fixed header is present, re-adjust it
                $.core.datatable.updateFixedHeaders();
                angular.element(document.body).injector().get('DataGridFactory').updateAllScrollBodyHeight();
            });
        }, 100);

        //Show collapsed menu if window less than 992px
        if ($(window).width() < viewportMinWidth) {
            setCollapsedMenu();
            $showHideToggler.hide();
        }

        if (amplify.getFromCache('collapseMenu')) {
            setCollapsedMenu();
        }

        //This is the current solution for preventing interface breakage, isotope cannot set the hieght properly when the container is hidden and therefor wont expand properly after a screen resize
        //This is a temporary solution
        function resetServiceSections() {
            var $imprt = $('div.i-container');
            var $imprtHeader = $('div.i-header');
            var $instantOn = $('div.io-container');
            var $instantOnHeader = $('div.io-header');
            var $importWizardMarket = $('div.iwmk-container');
            var $importWizardMarketHeader = $('div.iwmk-header');

            if($imprt.css('display') === 'none'){
                $imprt.hide();
                $imprt.show();
                $imprtHeader.find('.expand-services').css({'display': 'block'});
                $imprtHeader.find('.collapse-services').css({'display': 'none'});
            }
            if($instantOn.css('display') === 'none'){
                $instantOn.hide();
                $instantOn.show();
                $instantOnHeader.find('.expand-services').css({'display': 'block'});
                $instantOnHeader.find('.collapse-services').css({'display': 'none'});
            }
            if($importWizardMarket.css('display') === 'none'){
                $importWizardMarket.hide();
                $importWizardMarket.show();
                $importWizardMarketHeader.find('.expand-services').css({'display': 'block'});
                $importWizardMarketHeader.find('.collapse-services').css({'display': 'none'});
            }
        }

        function setCollapsedMenu() {
            $body.addClass('responsive');
            setNavSubMenu();
            $('div.nav-submenu').hide();

            //If the fixed header is present, re-adjust it
            $.core.datatable.updateFixedHeaders();
            angular.element(document.body).injector().get('DataGridFactory').updateAllScrollBodyHeight();
        }

        $showHideToggler.click(function() {
            $('div.nav-submenu').hide();

            //Show full menu
            if (($(this).hasClass('selected') && $body.hasClass('responsive')) || amplify.getFromCache('collapseMenu')) {
                $(this).removeClass('selected');
                $body.removeClass('responsive');
                removeNavSubMenu();
                amplify.safeStore('collapseMenu', false);
            }
            else { //Show collapsed menu
                $(this).addClass('selected');
                $body.addClass('responsive');
                setNavSubMenu();
                amplify.safeStore('collapseMenu', true);
            }

            //If the fixed header is present, re-adjust it
            $.core.datatable.updateFixedHeaders();
            angular.element(document.body).injector().get('DataGridFactory').updateAllScrollBodyHeight();
        });
    },
    setUpShowHideToggler: function () {
        /**
         * Set style rule to nav-submenu if necessary
         */
        function setNavSubMenu() {
            $('div.nav-item').on('mouseenter mouseleave', function () {
                var $elem = $(this).find('div.nav-submenu');
                if ($elem.length) {
                    var elemTop = $elem.offset().top;
                    var elemHeight = $elem.height();
                    var bodyHeight = $('body').height();

                    var isEntirelyVisible = (elemTop + elemHeight <= bodyHeight);

                    if (!isEntirelyVisible) {
                        $elem.addClass('nav-submenu-bottom');
                    } else {
                        $elem.removeClass('nav-submenu-bottom');
                    }
                }
            });
        }

        /**
         * Remove handler for setting nav-submenu style
         */
        function removeNavSubMenu() {
            $('div.nav-item').off('mouseenter mouseleave');
        }

        $('a#show-hide-nav').click(function() {
            $('div.nav-submenu').hide();

            //Show full menu
            if (($(this).hasClass('selected') && $('body').hasClass('responsive')) || amplify.getFromCache('collapseMenu')) {
                $(this).removeClass('selected');
                $('body').removeClass('responsive');
                removeNavSubMenu();
                amplify.safeStore('collapseMenu', false);
            }
            else { //Show collapsed menu
                $(this).addClass('selected');
                $('body').addClass('responsive');
                setNavSubMenu();
                amplify.safeStore('collapseMenu', true);
            }

            //If the fixed header is present, re-adjust it
            $.core.datatable.updateFixedHeaders();
            angular.element(document.body).injector().get('DataGridFactory').updateAllScrollBodyHeight();
        });
    },
    highlightCurrentNavMenu: function (viewName, viewId) {
        var $selectedSubMenu = null;
        var $subMenus = $('nav#main-nav li.nav-submenu-item');
        if (viewName != null) {
            viewId = (viewId != null ? '/' + viewId : '');
            if (viewName.contains('.')) {
                // Try to match pattern #/{view} and remove whats after '.'
                $selectedSubMenu = $subMenus.find('a[href="#/' + viewName.split('.')[0] + '"]');

                if ($selectedSubMenu.length === 0) {
                    $selectedSubMenu = $subMenus.find('a[href="#/' + viewName.split('.')[0] + '/' + viewName.split('.')[1] + (viewId) + '"]');
                }
            }
            else {
                // Try to match pattern #/{view}/{:id}
                $selectedSubMenu = $subMenus.find('a[href="#/' + viewName + (viewId) + '"]');

                //If url still cant be resolved then try ui-sref
                if ($selectedSubMenu.length == 0) {
                    $selectedSubMenu = $subMenus.find('a[ui-sref="' + viewName  + '"]');
                }
            }

            //If url still cant be resolved then perhaps we are in a service
            if ($selectedSubMenu.length == 0) {
                $selectedSubMenu = $subMenus.filter('[data-service-id=' + $('#current-service-id').val() + ']').find('a');
            }

            Core.setSubMenuActive($selectedSubMenu);
        }
    },
    highlightCurrentMainNavMenu: function (sectionItemTarget) {
        var $selectedSubMenu = $('nav#main-nav [data-target="' + sectionItemTarget + '"]');
        Core.setSubMenuActive($selectedSubMenu);
    },
    setSubMenuActive: function ($selectedSubMenu) {
        //Reset state menus & submenus
        $('nav#main-nav li.nav-submenu-item a').removeClass('selected');
        $('nav#main-nav div.nav-submenu').hide();
        $('nav#main-nav div.nav-item').removeClass('selected').removeClass('active');

        $selectedSubMenu.addClass('selected');
        if (!$('body').hasClass('responsive')) {
            $selectedSubMenu.closest('div.nav-submenu').show();
        }
        $selectedSubMenu.closest('div.nav-item').addClass('selected active');
        $selectedSubMenu.closest('div.nav-item').find('div.nav-item-wrapper i.icon').removeClass('icomoon-caret-right').addClass('icomoon-caret-down');
    },
    toggleDashFilters: function () {
        var $rightFrame = $('#right-frame');
        var $dashFilters = $('#dash-filters');
        //Make space for dash filters fixed bar (this may include the specific client view bar when in viewAsMode)
        $rightFrame.css({top: $dashFilters.is(':visible') ? $dashFilters.outerHeight() : 0});
    },
    initDashFilters: function (comparisonActive, comparisonPeriod) {
        Core.dateRangePicker(comparisonActive, comparisonPeriod);
    },
    tooltip: function ($container, options) {
        if (options == null)
            options = {};

        options.animation = false;
        options.container = options.container == null ? 'body' : options.container;
        options.placement = options.placement == null ? 'bottom' : options.placement;
        options.html = options.html == null ? false : options.html;
        options.trigger = options.trigger == null ? 'hover focus' : options.trigger;

        var defaultPlacement = options.placement;
        var defaultHtml = options.html;

        // If container contains title, then it is the tooltip element
        if (!_.isUndefined($container.attr('title')) || !_.isUndefined(options.title)) {
            setTooltip($container);
        }
        else { // Else, look for the tooltip elements that have the 'has-tooltip' class
            $container.find('.has-tooltip').each(function () {
                setTooltip($(this));
            });
        }

        //override the default tooltip placement function
        var tooltipPrototype = $.fn.tooltip.Constructor.prototype;
        if (!tooltipPrototype.originalGetPosition) {
            tooltipPrototype.originalGetPosition = tooltipPrototype.getPosition;
            tooltipPrototype.getPosition = function ($element) {
                $element   = $element || this.$element;

                var offset = tooltipPrototype.originalGetPosition($element);

                //look if a parent has a zoom property
                //to speed this up, please use the 'zoomed-element' css class
                //on the zoomed element
                var $zoomedEl = this.$element.parents('.zoomed-element');
                if ($zoomedEl.length) {
                    var zoom = $zoomedEl.css('zoom');
                    if (!_.isUndefined(zoom) && zoom < 1) {
                        offset['top'] = offset['top'] * zoom;
                        //remove half of the element's width to allow the tooltip to be aligned with it
                        offset['left'] = (offset['left'] * zoom) - $element.width()/2;
                    }
                }

                // get the value from the original applyPlacement function
                return offset;
            }
        }

        function setTooltip($el) {
            //We need to reset the defaults before overriding them because if
            //the first element overrides an option the following ones will also
            options.placement = defaultPlacement;
            options.html = defaultHtml;

            //The data attributes can override the options
            options.placement = $el.data('placement') != null ? $el.data('placement') : options.placement;
            options.html = $el.data('html') != null ? $el.data('html') : options.html;

            $el.tooltip(options);
        }
    },
    changeTooltipText: function (el, newText) {
        $(el).attr('data-original-title', newText);
    },
    initNotify: function () {
        var baseStyle = {
            'min-width': '200px',
            'line-height': '18px',
            'color': '#fff',
            'border': '1px solid',
            'padding': '20px 15px',
            '-webkit-border-radius': '4px',
            '-moz-border-radius': '4px',
            'border-radius': '4px',
            '-webkit-box-shadow': '0 2px 5px rgba(0,0,0,0.2)',
            'box-shadow': '0 2px 5px rgba(0,0,0,0.2)'
        };

        function setNotifyHtml(type) {
            var icon = '';
            if (type == $.globals.notify.success)
                icon = 'icomoon-check';
            else if (type == $.globals.notify.warning)
                icon = 'icomoon-warning';
            else if (type == $.globals.notify.info)
                icon = 'icomoon-help';
            else if (type == $.globals.notify.error)
                icon = 'icomoon-close-full';

            var prefixHtml = type == null ? '' : '<span class="icon ' + type + ' ' + icon + '"></span>';
            return '<div>\n' + prefixHtml + '<span class="' + type + '" data-notify-html="title"/><span data-notify-html="text"/>\n</div>';
        }

        $.notify.addStyle($.globals.notify.base, {
            html: setNotifyHtml($.globals.notify.base),
            classes: {
                base: baseStyle
            }
        });

        $.notify.addStyle($.globals.notify.success, {
            html: setNotifyHtml($.globals.notify.success),
            classes: {
                base: baseStyle
            }
        });

        $.notify.addStyle($.globals.notify.warning, {
            html: setNotifyHtml($.globals.notify.warning),
            classes: {
                base: baseStyle
            }
        });

        $.notify.addStyle($.globals.notify.info, {
            html: setNotifyHtml($.globals.notify.info),
            classes: {
                base: baseStyle
            }
        });

        $.notify.addStyle($.globals.notify.error, {
            html: setNotifyHtml($.globals.notify.error),
            classes: {
                base: baseStyle
            }
        });
    },
    notify: function (text, style, options) {
        if (options == null)
            options = {};

        options.autoHide = options.autoHide == null ? true : options.autoHide;
        options.autoHideDelay = options.autoHideDelay == null ? 5000 : options.autoHideDelay;
        options.showAnimation = options.showAnimation == null ? 'slideDown' : options.showAnimation;
        options.hideAnimation = options.hideAnimation == null ? 'fadeOut' : options.hideAnimation;
        options.title = options.title == null ? '' : options.title + ' ';
        options.globalPosition = options.globalPosition == null ? 'top right' : options.globalPosition;
        options.elementPosition = options.elementPosition == null ? 'top right' : options.elementPosition;
        options.style = style == null ? $.globals.notify.base : style;

        var content = {title: stripHtmlTags(options.title), text: text};

        //Make sure notify is unique, if not, don't show again, or until show delay is over
        if (Core.activeNotifies.contains(text)) {
            return;
        }
        Core.activeNotifies.push(text);

        setTimeout(function() {
            Core.activeNotifies = [];
        }, options.autoHideDelay || 0);

        //It is possible we are refreshing the page or some other operation and we need to init notify
        if ($.notify.getStyle('base') == null) {
            Core.initNotify();
        }

        //This allows you to place a notify on an element
        if (options.element != null) {
            options.autoHide = false;
            $.notify(options.element, content, options);
        }
        else {
            $.notify(content, options);
        }

        var $wrapper = $('div.notifyjs-corner');
        options.element == null
            ? $wrapper.find('div.notifyjs-container') //Global container
            : options.element.prev().find('div.notifyjs-container'); //Relative container

    },
    notifyMultiple: function (data, type, options) {
        data = _.isString(data) ? [data] : data;
        _.each(data, function (val, i) {
            if (i === 0) {
                Core.notify(val, type, options);
            }
            else {
                setTimeout(function () {
                    Core.notify(val, type, options);
                }, 300);
            }
        });
    },
    hideAllNotifies: function () {
        //This will remove all error notifies (success notifies timeout anyway)
        if ($('.notifyjs-corner .notifyjs-container').length) {
            $('.notifyjs-error-base').closest('.notifyjs-corner').empty();
        }
    },
    //Notifies for form errors
    formErrorNotify: function (json, type) {
        Core.hideAllNotifies();
        var options = {};
        options.autoHide = false;
        type = type == null ? $.globals.notify.error : type;

        if (json.data == null) {
            if (json.message){
                Core.notify(json.message, type, options);
            }
            else {
                Core.notify(json, type, options);
            }
        }
        else {
            if (Array.isArray(json.data)) {
                // if the status is a 401, it only needs one notification (setTimeout will cause multiple to show)
                if (json.status == 401) {
                    Core.notify(json.data[0], type, options);
                    return;
                }
                $.each(json.data, function (i, val) {
                    setTimeout(function () {
                        Core.notify(val, type, options);
                    }, 300);
                });
            }
            else {
                Core.notify(json.data, type, options);
            }
        }
    },
    showModal: function ($elm, options) {
        $elm.modal('show');

        var $form = $elm.find('form');
        if ($form.length && !$form.data('is-binded')) {
            $form.data('is-binded', true);
            //Allow user to press enter to trigger main action in modal footer
            $form.find('input[type="text"], input[type="email"], input[type="password"]').keypress(function (e) {
                if ((e.keyCode ? e.keyCode : e.which) == 13) { //Enter is pressed
                    $elm.find('div.modal-footer .btn-primary').click();
                }
            });
        }
    },
    hideModal: function ($elm) {
        $elm.modal('hide');
    },
    initFormValidator: function ($form, options) {
        if (options == null)
            options = {};

        options.feedbackIcons = {
            valid: 'icomoon-check',
            invalid: 'icomoon-cross',
            validating: 'icomoon-refresh'
        };
        options.feedbackIcons = false;
        options.live = options.live == null ? 'submitted' : options.live;
        options.trigger = options.trigger == null ? 'blur' : options.trigger;

        $form.bootstrapValidator(options);
    },
    addFormValidatorField: function ($form, $field) {
        $form.bootstrapValidator('addField', $field);
    },
    validateForm: function ($form) {
        var bv = $form.data('bootstrapValidator');
        $.each(bv._cacheFields, function(i, val) {
            $form.bootstrapValidator('revalidateField', val);
        });
        //bv.validate();
        return bv.isValid();
    },
    formNavAway: function () {
        $('#left-frame').find('a').click(function() {
            if ($(this).attr('href') != null
                && $(this).attr('href') != '#'
                && $(this).attr('target') != '_blank') {
                if ($('#right-frame').find('form').length && Core.formHasChanged && !Core.ignoreFormChange) {
                    Core.navAwayUrl = $(this).attr('href');
                    Core.showModal($('#form-nav-away-confirm-modal'));
                    return false;
                }
            }
        });

        $('#leave-page').click(function() {
            window.location = Core.navAwayUrl;
            //Reset values
            Core.hideFooter();
            Core.formHasChanged = false;
            Core.navAwayUrl = '';
        });
    },
    dynamicForm: function ($form, ignoreFormCompare, ignoreFormChange, forceRefreshOnCancel, needsConfirmation) {
        Core.ignoreFormCompare = ignoreFormCompare || false;
        Core.ignoreFormChange = ignoreFormChange || false;
        Core.forceRefreshOnCancel = forceRefreshOnCancel || false;
        Core.needsConfirmation = needsConfirmation || false;

        Core.initForm($form);
        Core.enableFormSubmitOnEnter($form);
        Core.bindElementsToCompareFormsMethod();
    },
    bindElementsToCompareFormsMethod: function () {
        if (!Core.ignoreFormCompare) {
            Core.mainForm.find('select, input, textarea').each(function () {
                //If anything has changed in the form, show footer options
                if ($(this).is('input:text')
                    || $(this).is('input:password')
                    || $(this).is('textarea')
                    || $(this).attr('type') == 'color'
                    || $(this).attr('type') == 'email') {
                    $(this).bind('textchange', function () {
                        compareForms(Core.originalSerializedForm, Core.mainForm) ? Core.showFooter() : Core.hideFooter();
                    });
                }
                else {
                    $(this).on('change', function () {
                        compareForms(Core.originalSerializedForm, Core.mainForm) ? Core.showFooter() : Core.hideFooter();
                    });
                }
            });
        }

        function compareForms(originalSerializedForm, $currentForm) {
            var serializedForm = $currentForm.serialize();
            //File inputs are not serialized so we need to compare their current value if present in the form
            if ($currentForm.find('input:file').length > 0) {
                $currentForm.find('input:file').each(function() {
                    if ($(this).val() != '') {
                        serializedForm += '&' + $(this).attr('name') + '=' + $(this).val();
                    }
                });
            }
            originalSerializedForm == serializedForm ? Core.formHasChanged = false : Core.formHasChanged = true;
            return Core.formHasChanged;
        }
    },
    setOriginalForm: function(force = false) {
        if (force || Core.mainForm != {}) {
            Core.originalForm = Core.mainForm;
            Core.originalSerializedForm = Core.mainForm.serialize();
        }
    },
    initForm: function($form) {
        Core.mainForm = $form;
        //Take snapshot of original form values
        Core.setOriginalForm(true);

        //Make first input focused by default
        $form.find('input:first').focus();

        $form.find(':input, select, textarea').each(function () {
            if ($(this).is('input:checkbox') || $(this).is('input:radio')) {
                $(this).data('initialState', $(this).prop('checked'));
            }
            else if ($(this).is('select')
                || $(this).is('input:password')
                || $(this).is('input:text')
                || $(this).is('input:file')
                || $(this).is('input:hidden')
                || $(this).is('textarea')
                || $(this).attr('type') == 'color'
                || $(this).attr('type') == 'email') {
                $(this).data('initialState', $(this).val());
            }
        });
    },
    restoreForm: function($form) {

        if (Core.forceRefreshOnCancel) {
            location.reload();
        }
        else {
            if (!Core.ignoreFormCompare) {
                //Reset all radios and checkboxes and re-enable them as we go
                $form.find('input:checkbox, input:radio').prop('checked', false);

                $form.find(':input, select').each(function () {
                    var events = $._data(this, 'events');
                    if ($(this).hasClass('is-select2') && $(this).is(':hidden')) {
                        $(this).val($(this).data('initialState')).trigger('change');
                    }
                    else if ($(this).is('select')
                        || $(this).is('input:text')
                        || $(this).is('input:password')
                        || $(this).is('input:file')
                        || $(this).attr('type') == 'color'
                        || $(this).attr('type') == 'email') {
                        $(this).val($(this).data('initialState'));

                        // Only trigger change event if element has any bound to it
                        if (events && !_.isEmpty(events['change'])) {
                            $(this).trigger('change');
                        }
                    }
                    else if ($(this).is('input:checkbox') || $(this).is('input:radio')) {
                        //We have a button group
                        if ($(this).closest('.btn-group').length > 0) {
                            var $button = $(this).parent('.btn');
                            $button.removeClass('active');
                            if ($(this).data('initialState') == true) {
                                //Trigger click instead of toggle in case there was bindings originally
                                $button.click();
                            }
                        }
                        else {
                            $(this).prop('checked', $(this).data('initialState'));

                            // Only trigger change event if element has any bound to it
                            if (events && !_.isEmpty(events['change'])) {
                                $(this).trigger('change');
                            }
                        }
                    }
                    else if ($(this).is('input:file')) {
                        $(this).val('');

                        // Only trigger change event if element has any bound to it
                        if (events && !_.isEmpty(events['change'])) {
                            $(this).trigger('change');
                        }
                    }
                });

                //Reset all form validation warnings, if any
                if ($form.data('bootstrapValidator') != null) {
                    $form.data('bootstrapValidator').resetForm(true);
                }
            }

            Core.formHasChanged = false;

            if (Core.restoreFormCallback != null) {
                $.fn.safeEval(Core.restoreFormCallback());
            }
        }
    },
    submitForm: function() {
        let customSubmit = Core.mainForm.find('#custom_submit');
        if (customSubmit.length) {
            customSubmit.click();
        } else if (Core.mainForm.data('bootstrapValidator') == null) {
            Core.mainForm.submit();
        } else if (Core.validateForm(Core.mainForm)) {
            Core.mainForm.data('bootstrapValidator').defaultSubmit();
        }
    },
    showConfirmSubmitModal: function() {
        Core.showModal($('#submit-confirm-modal'));
    },
    initFooter: function() {
        $('footer #form-submit').click(function () {
            if (Core.needsConfirmation) {
                Core.showConfirmSubmitModal();
            } else {
                Core.submitForm();
            }
        });

        $('footer #form-cancel').click(function () {
            Core.hideFooterCallback && Core.hideFooterCallback();
            Core.restoreForm(Core.mainForm);
            Core.hideFooter();
        });
    },
    showFooter: function() {
        var $footer = $('footer');
        if ($footer.css('max-height') == '0px') {
            $footer.css({ 'max-height': 60 });
            $('#right-frame').css({bottom: 60});
        }
    },
    hideFooter: function() {
        var $footer = $('footer');
        var $buttons = $footer.find('div.btn');
        $buttons.enableElement();
        $footer.css({ 'max-height': 0 });
        $('#right-frame').css({bottom: 0});
    },
    enableFormSubmitOnEnter: function($form) {
        //Allow user to press enter to submit form
        $form.find('input[type="text"], input[type="email"], input[type="password"]').keypress(function (e) {
            if((e.keyCode ? e.keyCode : e.which) == 13) { //Enter is pressed
                e.preventDefault();
                if (Core.needsConfirmation) {
                    Core.showConfirmSubmitModal();
                } else {
                    Core.submitForm();
                }
            }
        });
    },
    getPeriodColor: function(type) {
        //This technique is quite unsafe since it relies on an elements background style to determine color
        //But this period element is usually present when showing this type of chart
        return $('.' + type).css('backgroundColor');
    },
    dateRangePicker: function(comparisonActive, comparisonPeriod) {

        var $dateRangeFilter = $('#dash-date-range-filter');
        var $dateTextContainer = $dateRangeFilter.find('span.date-text-container');
        var $dateTextIcon = $dateTextContainer.find('i.icon');

        var $dateRangeButtonGroup = $dateRangeFilter.find('div.btn-group');
        var $dateRangeButtons = $dateRangeButtonGroup.find('button.btn');
        var $dateRangeContainer = $('#date-range-filter-container');

        var $dateRangeCustomSelect = $('#date-range-custom-select');

        var $startTimePicker = $('#start_time');
        var $endTimePicker = $('#end_time');

        var $compareDateRangeContainer = $('#compare_date_range_container');
        var $compareToCheckbox = $('#compare_to_checkbox');
        var $compareToSelect = $('#compare_to_select');
        var $comparisonStartTimePicker = $('#comparison_start_time');
        var $comparisonEndTimePicker = $('#comparison_end_time');

        var $dateApplyButton = $('#timefilterbutton');
        var $dateResetButton = $('#reset_time_filter');
        var $dateCloseButton = $('#close_time_filter');

        var prettyDateFormat = 'MMM dd, yyyy';

        Core.dateRangePicker.timeFilter = {
            setDays : function(numDays) {
                var yesterday = Date.parse('yesterday');
                yesterday.setHours(11); //set to noon to prevent DST issues
                var start_date = new Date(yesterday - 86400 * (numDays-1) * 1000); //times are in milliseconds

                Core.dateRangePicker.timeFilter.setDateFilter(start_date, yesterday, numDays);
            },
            setPeriod : function(period, start, end) {
                var startDate = new Date(start*1000); //times are in milliseconds
                var endDate = new Date(end*1000); //times are in milliseconds

                Core.dateRangePicker.timeFilter.setDateFilter(startDate, endDate, period);
            },
            setDateFilter : function(start, end, period) {
                $startTimePicker.datepicker('setDate', start);
                $endTimePicker.datepicker('setDate', end);

                // set period as active in date range custom select drop down
                var selectPeriod;
                var dateRange;

                switch (period) {
                    case 7:
                        dateRange = 'last7days';
                        break;
                    case 30:
                        dateRange = 'last30days';
                        break;
                    case 'current_month':
                        dateRange = 'thismonth';
                        break;
                    case 'last_month':
                        dateRange = 'lastmonth';
                        break;
                }

                if (dateRange) {
                    const option = $('#date-range-custom-select option[key="' + dateRange + '"]');
                    dateRange = option.text();
                    selectPeriod = option.val();
                }

                $dateRangeCustomSelect.val(selectPeriod);

                $dateRangeButtons.removeClass('active');
                $('.t_' + period).addClass('active');

                //When clicking a set amount of days, reset the compare to filter
                $compareToCheckbox.prop('checked', false);
                $compareToSelect.find('option[value="priorPeriod"]').prop('selected', true);

                $dateApplyButton.trigger('click', {
                    wasTriggered: true,
                    dateRange: dateRange
                });
            },
            showHideFilter : function() {
                if ($dateRangeContainer.is(':visible')) {
                    $dateRangeContainer.hide();
                    $dateTextIcon.removeClass('icon-chevron-up').addClass('icon-chevron-down');
                }
                else {
                    $dateRangeContainer.show();
                    $dateTextIcon.removeClass('icon-chevron-down').addClass('icon-chevron-up');
                }
            }
        };

        $dateRangeCustomSelect.change(function() {
            if ($(this).val() != 'custom') {
                var split_date = $(this).val().split(';');

                $startTimePicker.val(split_date[0]);
                $endTimePicker.val(split_date[1]);

                $startTimePicker.attr('readonly', true);
                $endTimePicker.attr('readonly', true);

                //Update comparison period if needed
                Core.comparisonDate.updatePeriod($startTimePicker.val());
            }
            else {
                $comparisonStartTimePicker.attr('readonly', false);
                $comparisonEndTimePicker.attr('readonly', false);
            }
        });

        $startTimePicker.datepicker({
            dateFormat: 'M dd, yy',
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 3,
            onClose: function( selectedDate ) {
                $endTimePicker.datepicker( "option", "minDate", selectedDate );

                //Update comparison period if needed
                Core.comparisonDate.updatePeriod(selectedDate);
            }
        });
        var initStartDate = $startTimePicker.val();

        $endTimePicker.datepicker({
            dateFormat: 'M dd, yy',
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 3,
            onClose: function( selectedDate ) {
                $startTimePicker.datepicker( "option", "maxDate", selectedDate );

                //Update comparison period if needed
                Core.comparisonDate.updatePeriod(selectedDate);
            }
        });
        var initEndDate = $endTimePicker.val();

        $('#start_time, #end_time').click(function() {
            $startTimePicker.attr('readonly', false);
            $endTimePicker.attr('readonly', false);
            $dateRangeCustomSelect.find('option[value="custom"]').prop('selected', true);
        });

        Core.comparisonDate = {
            calculatePriorPeriod: function(startDateValue, endDateValue) {
                var startDate = moment(startDateValue, 'MMM DD, YYYY').format();
                endDateValue = endDateValue || $endTimePicker.val();
                var endDate = moment(endDateValue, 'MMM DD, YYYY').format();
                var comparisonDate = {};

                var getComparisonDateByDays = function() {
                    var numDaysDiff = moment(endDate).diff(startDate, 'days');
                    comparisonDate.start = moment(startDate).subtract(numDaysDiff + 1, 'days').format('MMM DD, YYYY');
                    $comparisonStartTimePicker.val(comparisonDate.start);

                    comparisonDate.end = moment(startDate).subtract(1, 'day').format('MMM DD, YYYY');
                    $comparisonEndTimePicker.val(comparisonDate.end);

                    $comparisonStartTimePicker.attr('readonly', true);
                    $comparisonEndTimePicker.attr('readonly', true);
                    return comparisonDate;
                }


                if ($dateRangeCustomSelect.val() === 'custom') { // Calculate prior period based on length of days
                    return getComparisonDateByDays();
                }
                else { // Calculate the prior period based on the predefined date range selection
                    // TODO: Need to account for predefined date ranges
                    // $dateRangeCustomSelect will get the value of the drop down
                    // Note: This value is reset on page refresh...
                    // The thing is that the dash date filter is still selected...
                    // This does not work when the dash date filter is selected,

                    var $selectedValue = $('#date-range-custom-select option:selected').text(); // the selected value

                    switch ($selectedValue) {
                        case 'yesterday':
                        case 'Last 7 days':
                        case 'Last 14 days':
                        case 'Last 30 days':
                        case 'Last 90 days':
                            getComparisonDateByDays();
                            break;

                        case 'This week':
                        case 'Last week':
                            comparisonDate.start = moment($startTimePicker.val()).subtract(1, 'week').format('MMM DD, YYYY');
                            $comparisonStartTimePicker.val(comparisonDate.start);
                            comparisonDate.end = moment($endTimePicker.val()).subtract(1, 'week').format('MMM DD, YYYY');
                            $comparisonEndTimePicker.val(comparisonDate.end);
                            break;

                        case 'This month':
                        case 'Last month':
                            comparisonDate.start = moment($startTimePicker.val()).subtract(1, 'month').format('MMM DD, YYYY');
                            $comparisonStartTimePicker.val(comparisonDate.start);
                            comparisonDate.end = moment($endTimePicker.val()).subtract(1, 'month').format('MMM DD, YYYY');
                            $comparisonEndTimePicker.val(comparisonDate.end);
                            break;

                        case 'Last 3 months':
                            comparisonDate.start = moment($startTimePicker.val()).subtract(3, 'month').format('MMM DD, YYYY');
                            $comparisonStartTimePicker.val(comparisonDate.start);
                            comparisonDate.end = moment($endTimePicker.val()).subtract(3, 'month').format('MMM DD, YYYY');
                            $comparisonEndTimePicker.val(comparisonDate.end);
                            break;

                        case 'Last 6 months':
                            comparisonDate.start = moment($startTimePicker.val()).subtract(6, 'month').format('MMM DD, YYYY');
                            $comparisonStartTimePicker.val(comparisonDate.start);
                            comparisonDate.end = moment($endTimePicker.val()).subtract(6, 'month').format('MMM DD, YYYY');
                            $comparisonEndTimePicker.val(comparisonDate.end);
                            break;

                        case 'Last 12 months':
                            comparisonDate.start = moment($startTimePicker.val()).subtract(12, 'month').format('MMM DD, YYYY');
                            $comparisonStartTimePicker.val(comparisonDate.start);
                            comparisonDate.end = moment($endTimePicker.val()).subtract(12, 'month').format('MMM DD, YYYY');
                            $comparisonEndTimePicker.val(comparisonDate.end);
                            break;

                        case 'This quarter':
                        case 'Last quarter':
                        case 'This year':
                        case 'Last year':
                            comparisonDate.start = moment($startTimePicker.val()).subtract(1, 'year').format('MMM DD, YYYY');
                            $comparisonStartTimePicker.val(comparisonDate.start);
                            comparisonDate.end = moment($endTimePicker.val()).subtract(1, 'year').format('MMM DD, YYYY');
                            $comparisonEndTimePicker.val(comparisonDate.end);
                            break;
                    }
                    return comparisonDate;

                }
            },

            calculatePriorYear: function(start, end) {
                var startDate = start || $startTimePicker.val();
                var endDate = end || $endTimePicker.val();
                var comparisonDate = {
                    start: moment(startDate).subtract(1, 'year').format('MMM DD, YYYY'),
                    end: moment(endDate).subtract(1, 'year').format('MMM DD, YYYY'),
                };
                $comparisonStartTimePicker.val(comparisonDate.start);
                $comparisonEndTimePicker.val(comparisonDate.end);

                $comparisonStartTimePicker.attr('readonly', true);
                $comparisonEndTimePicker.attr('readonly', true);
                return comparisonDate;
            },
            updatePeriod: function(startDateValue) {
                if($compareToCheckbox.is(':checked')) {
                    if ($compareToSelect.find('option:selected').val() == 'priorPeriod') {
                        return Core.comparisonDate.calculatePriorPeriod(startDateValue);
                    }
                    else if ($compareToSelect.find('option:selected').val() == 'priorYear') {
                        return Core.comparisonDate.calculatePriorYear();
                    }
                }
            },
        };

        $compareToCheckbox.click(function() {
            if($(this).is(':checked')) {
                $compareToSelect.enableElement();
                $compareDateRangeContainer.show();

                //Set the previous period by default
                Core.comparisonDate.calculatePriorPeriod($startTimePicker.val());
            }
            else {
                $compareToSelect.disableElement();
                $compareDateRangeContainer.hide();

                //Reselect previous period by default
                $compareToSelect.find('option[value="priorPeriod"]').prop('selected', true);
            }
        });
        $compareToCheckbox.prop('checked', comparisonActive);

        //Disable compare to select by default
        if (!comparisonActive) {
            $compareToSelect.disableElement();
        }
        else {
            $compareDateRangeContainer.show();
            $comparisonStartTimePicker.attr('readonly', true);
            $comparisonEndTimePicker.attr('readonly', true);

            if (comparisonPeriod == 'custom') {
                $comparisonStartTimePicker.attr('readonly', false);
                $comparisonEndTimePicker.attr('readonly', false);
            }
        }

        $compareToSelect.change(function() {
            if ($(this).val() == 'custom') {
                $comparisonStartTimePicker.attr('readonly', false);
                $comparisonEndTimePicker.attr('readonly', false);
            }
            else if ($(this).val() == 'priorPeriod') {
                Core.comparisonDate.calculatePriorPeriod($startTimePicker.val());
            }
            else if ($(this).val() == 'priorYear') {
                Core.comparisonDate.calculatePriorYear();
            }
        });

        if (comparisonPeriod != '') {
            $compareToSelect.val(comparisonPeriod);
        }

        //Disable current date range
        function disableCurrentRange(date) {
            var startDate = $startTimePicker.val();
            if (date >= new Date(startDate)) {
                return [false, 'highlight'];
            }
            return [true, ''];
        }

        $comparisonStartTimePicker.datepicker({
            dateFormat: 'M dd, yy',
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 3,
            onClose: function( selectedDate ) {
                $comparisonEndTimePicker.datepicker( "option", "minDate", selectedDate );
            },
            beforeShowDay: disableCurrentRange
        });

        $comparisonEndTimePicker.datepicker({
            dateFormat: 'M dd, yy',
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 3,
            onClose: function( selectedDate ) {
                //$( "#comparison_start_time" ).datepicker( "option", "maxDate", selectedDate );
            },
            beforeShowDay: disableCurrentRange
        });

        $('#comparison_start_time, #comparison_end_time').click(function() {
            $comparisonStartTimePicker.attr('readonly', false);
            $comparisonEndTimePicker.attr('readonly', false);
            $compareToSelect.find('option[value="custom"]').prop('selected', true);
        });


        //
        //$dateApply binding is done in dashboard.directives
        //



        $dateResetButton.click(function() {
            $startTimePicker.val(initStartDate);
            $startTimePicker.attr('readonly', false);
            $endTimePicker.val(initEndDate);
            $endTimePicker.attr('readonly', false);
            $dateRangeCustomSelect.find('option[value="custom"]').prop('selected', true);

            $compareDateRangeContainer.hide();
            $compareToCheckbox.prop('checked', false);
            $compareToSelect.find('option[value="priorPeriod"]').prop('selected', true);
            $compareToSelect.disableElement();

        });

        $dateCloseButton.click(function() {
            $dateRangeContainer.hide();
            $dateTextIcon.removeClass('icon-chevron-up').addClass('icon-chevron-down');
        });

        //Ensures that when you click anywhere else on the screen the time filter pup it closes the popup
        $(document).mouseup(function (e) {
            if (!$dateRangeContainer.is(e.target) // if the target of the click isn't the container...
                && $dateRangeContainer.has(e.target).length === 0 // ... nor a descendant of the container
                && $(e.target).closest('.ui-datepicker').length == 0) // ... nor the datepicker
            {
                $dateRangeContainer.hide();
                $dateTextIcon.removeClass('icon-chevron-up').addClass('icon-chevron-down');
            }
        });
    }
};

export default Core;
