"use strict";
import angular from 'angular';
import $ from 'jquery';

/**
 * Helper function to set some of the l10n options for Highcharts.
 *
 * @param {object} Highcharts
 *
 * @see http://api.highcharts.com/highcharts#lang
 */
var setL10nOptions = function(Highcharts) {

    // ensure our required global variable exists
    if (!window || !window.appLocale) return;

    var lang = window.appLocale;

    // no need to do anything if the language is English, the app is already in English
    if ('en' === lang) return;

    var months = [];
    var shortMonths = [];
    var decimalPoint = '.';
    var thousandsSep = ',';

    // data source: https://code.angularjs.org/1.5.0/i18n/
    if ('fr' === lang) {
        months = ['janvier', 'f\u00e9vrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'ao\u00fbt', 'septembre', 'octobre', 'novembre', 'd\u00e9cembre'];
        shortMonths = ['janv', 'f\u00e9vr', 'mars', 'avr', 'mai', 'juin', 'juil', 'ao\u00fbt', 'sept', 'oct', 'nov', 'd\u00e9c'];
        decimalPoint = ',';
        thousandsSep = '\u00a0'
    } else if ('id' === lang) {
        months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        shortMonths = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agt", "Sep", "Okt", "Nov", "Des"];
        decimalPoint = ',';
        thousandsSep = '.'

    }

    Highcharts.setOptions(
        {
            lang: {
                months: months,
                shortMonths: shortMonths,
                decimalPoint: decimalPoint,
                thousandsSep: thousandsSep
            }
        }
    );
};

var Core = $.core.chart = {
    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {
    },
    events: function () {
    },
    //Function enables us to migrate data from deprecated fusion chart data format to chartjs format
    extractSeriesData: function (options, data) {

        var chartData = data.chartData;
        var series = []; //The lines

        var categories = chartData.categories.category;
        var xAxis = {
            type: 'datetime',
            labels: {
                step: categories.length > 10 ? Math.floor(categories.length/10) : 1,
                formatter: function() {
                    return $.fn.getChartDateFormat(this.value, data.dataUnit, true);
                }
            },
            categories: $.map(categories, function (val) {return val.label})
        };

        //If exporting, show all y-axes instead of consolidating them
        var yAxis = options.isMultiAxis ? [] : { title: { text: null }, min: 0 };

        var seriesIndex = 0;
        $.each(chartData.dataset, function(i, dataSerie) {
            var seriesData = [];
            //Iterate the data in the data series for further formatting and insure float values since the backend returns string ints
            $.each(dataSerie.data, function (i, item) {
                seriesData.push({y: $.fn.safeParseFloat(item.value), format: dataSerie.format});
            });

            series.push({
                data: seriesData,
                name: dataSerie.seriesname,
                type: options.chartType,
                yAxis: options.isMultiAxis ? seriesIndex : 0
            });

            //If exporting, show all y-axes instead of consolidating them
            if (options.isMultiAxis) {
                yAxis.push({
                    title: {
                        text: dataSerie.seriesname,
                        style: {
                            fontSize:12,
                            color: Highcharts.getOptions().colors[seriesIndex]
                        }
                    },
                    labels: {
                        formatter: function() {
                            return this.value > 0 && this.value < 1 ? $.fn.formatNumber(this.value, dataSerie.format, 2, true) : $.fn.formatNumber(this.value, dataSerie.format, 0, true);
                        }
                    },
                    min: 0
                });
            }
            seriesIndex++;
        });

        return {series: series, xAxis: xAxis, yAxis: yAxis};
    },
    buildSeriesChart: function ($chart, data, options) {
        if (options == null)
            options = {};

        options.chartType = options.chartType == null ? 'line' : options.chartType;
        options.width = options.width == null ? 1000 : options.width;
        options.animation = options.animation == null ? true : options.animation;
        options.isMultiAxis = options.isMultiAxis == null ? false : options.isMultiAxis;

        setL10nOptions(Highcharts);
        Highcharts.setOptions(
            {
                //Color palettes
                colors: $.globals.chart.palettes.flatUI.simpleSet,
                //Turn off useUTC so that dates are not incorrectly converted
                global: {useUTC: false}
            }
        );

        if (navigator && navigator.language) {
            var lang = navigator.language.substr(0, 2);
        }

        var extractedData = Core.extractSeriesData(options, data);
        var series = extractedData.series; //The lines
        var xAxis = extractedData.xAxis; //X-axis
        var yAxis = extractedData.yAxis; //Y-axis

        new Highcharts.Chart({
            title: null,
            animation: false,
            chart: {
                backgroundColor: null,
                zoomType: 'x',
                height: 200,
                width: options.width,
                renderTo: $chart.attr('id')
            },
            plotOptions: {
                series: {
                    animation: options.animation
                }
            },
            series: series,
            xAxis: xAxis,
            yAxis: yAxis,
            tooltip: {
                hideDelay: 0,
                animation: false,
                shared: true,
                useHTML: true,
                formatter: function () {
                    var html = '<b>'+ $.fn.getChartDateFormat(this.x, 'day', true)+'</b>';
                    angular.forEach(this.points, function(val) {
                        var point = val.point;
                        html += '<div class="mt5 mb5"><span class="label label-default" style="background-color:'+point.color+'">'+point.series.name+'</span><span class="ml5">'+$.fn.formatNumber(point.y, point.format, point.format == 'percent' ? 2 : 0)+'</span></div>';
                    });
                    return html;
                }
            },
            credits : {
                enabled : false
            }
        });

    },
    buildPieChart: function ($chart, data, options) {
        if (options == null)
            options = {};

        options.width = options.width == null ? $chart.width() : options.width;
        options.height = options.height == null ? 300 : options.height;
        options.animation = options.animation == null ? true : options.animation;

        setL10nOptions(Highcharts);

        //Color palettes
        Highcharts.setOptions({colors: $.globals.chart.palettes.flatUI.simpleSet});

        var chartData = data.chartData;

        var seriesData = [];
        $.each(chartData.data, function (i, item) {
            var value = $.fn.safeParseFloat(item.value);
            seriesData.push({y: value, name: item.label});
        });

        var series = [{
            data: seriesData,
            type: 'pie'
        }];

        new Highcharts.Chart({
            title: {
                text: chartData.caption,
                style: {
                    fontSize: 16
                }
            },
            chart: {
                backgroundColor: null,
                marginTop: 20,
                height: options.height,
                width: options.width,
                renderTo: $chart.attr('id')
            },
            plotOptions: {
                series: {
                    animation: options.animation
                }
            },
            series: series,
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function() {
                    return this.key + ': ' + $.fn.formatNumber(this.percentage, 'percentage') + ' (' + $.fn.formatNumber(this.y, 'numeric', 0) + ')';
                }
            }
        });
    },
    buildDualBarChart: function ($chart, data, options) {
        if (options == null)
            options = {};

        options.width = options.width == null ? $chart.width() : options.width;
        options.height = options.height == null ? 240 : options.height;
        options.rotated = options.rotated == null ? false : options.rotated;
        options.isMultiAxis = options.isMultiAxis == null ? false : options.isMultiAxis;
        options.precision = 0;

        //Determine precision based on the current and prior value. Check how many decimals they contain, if any
        if (data.currentValue % 1 != 0 || data.previousValue % 1 != 0) {
            if (data.currentValue > 0) {
                options.precision = data.currentValue.numberOfDecimals();
            }
            else if (data.previousValue > 0) {
                options.precision = data.previousValue.numberOfDecimals();
            }
        }

        setL10nOptions(Highcharts);

        Highcharts.setOptions({colors: [$.core.main.getPeriodColor('prior'), $.core.main.getPeriodColor('current')]});

        new Highcharts.Chart({
            title: null,
            chart: {
                spacing: 0,
                type: options.rotated ? 'bar' : 'column',
                backgroundColor: null,
                height: options.height,
                width: options.width,
                renderTo: $chart.attr('id')
            },
            xAxis: {
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                },
                gridLineWidth: 0
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: 14,
                            textShadow: false
                        }
                    }
                }
            },
            series: [{
                index: 1,
                data: [data.previousValue]
            },{
                index: 0,
                data: [data.currentValue]
            }],
            credits : {
                enabled : false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                enabled: false
            }
        });
    },
    updateSubRowChart: function (elm, graphType, serviceId, campaignId, chartId) {

        var $filters = $(elm).closest('div.chart-filters');
        $filters.find('button.chart-filter').removeClass('active');
        $(elm).addClass('active');

        $.get($.fn.actionUrl('serviceDetails/graph/service_id/' + serviceId + '/id/' + campaignId + '/chart/' + graphType + '/chart_div/' + chartId), function(json) {
            $filters.showV();

            var data = $.parseJSON(json);
            var $chart = $('#chart-' + chartId);

            if (data.chartType == $.globals.chart.type.pie) {
                Core.buildPieChart($chart, data);
            }
            else if (data.chartType == $.globals.chart.type.dualbar) {
                Core.buildDualBarChart($chart, data);
            }
            else if (data.chartType == $.globals.chart.type.bar) {
                Core.buildSeriesChart($chart, data, {chartType: $.globals.chart.type.bar, width: $chart.closest('div.dataTables_wrapper').width() - 40, isMultiAxis: true});
            }
            else if (data.chartType == $.globals.chart.type.line) {
                //options.chartType defaults to line chart
                Core.buildSeriesChart($chart, data, {width: $chart.closest('div.dataTables_wrapper').width() - 40, isMultiAxis: true});
            }
            else {
                alert('Invalid chart type');
            }
        });
    }
};