import { BaseTheme } from '@/modules/core/charts/am5/themes/BaseTheme';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { ThemeRules } from '@/modules/core/charts/am5/charts.constants';

/**
 * @ignore
 */
export class LightTheme extends BaseTheme {
  setupDefaultRules() {
    super.setupDefaultRules();

    this.rule(ThemeRules.GRID).setAll({
      stroke: color(0x999999),
      strokeWidth: 1,
    });
  }
}
