'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('client.services', [])
    .factory('ClientFactory', ClientFactory);

/**
 * Client factory
 * @ngInject
 */
function ClientFactory(
    Restangular,
    ColumnValueType
) {
    var clients = Restangular.all('clients');
    var values = clients.one('values');

    /**
     * Get values for all possible distinct specific field
     * @param fields
     * @param queryParams
     */
    function getFieldValues(fields, queryParams) {
        return values.one(ColumnValueType.DISTINCT)
            .get(_.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));
    }

    /**
     * Get Client columns
     * @param queryParams
     * @returns {*|restangular.ICollectionPromise<any>}
     */
    function getColumns(queryParams) {
        return clients.getList(_.extend({metadata: true}, queryParams));
    }

    /**
     * Get values for all possible distinct specific field
     * @param fields
     * @returns {*}
     */
    function getData(queryParams) {
        return clients.getList(queryParams);
    }

    /**
     * Helper to try and determine if a filter is a client filter
     * @returns {boolean}
     */
    function isClientFilter(filter) {
        return filter.label === 'Client'
            || filter.label === 'Client ID'
            || filter.label === 'Client Billing ID'
            || filter.field === 'client_id'
            || filter.field === 'customer_id';
    }

    function getSingleClient(clientId, queryParams) {
        return Restangular.one('clients', clientId).get(queryParams);
    }

    return {
        clients: clients,
        getFieldValues: getFieldValues,
        getColumns: getColumns,
        getData: getData,
        isClientFilter: isClientFilter,
        getSingleClient: getSingleClient
    }
}