'use strict';
import angular from 'angular';
import _ from 'lodash';

import tagValuesDetailsHtmlUrl from './tag.values.details.html';

angular.module('tag.values.details.components', [])
    .component('tagValueDetails', {
        templateUrl: tagValuesDetailsHtmlUrl,
        controllerAs: 'vm',
        controller: TagValuesDetailController
    });

function TagValuesDetailController(
    WidgetFactory,
    DetailsModalFactory
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.getColumn = getColumn;

    function $onInit() {
        vm.model = DetailsModalFactory.getModel();
        vm.metadata = DetailsModalFactory.getMetadata();
    }

    /**
     * @param field
     * @returns {any}
     */
    function getColumn(field) {
        return _.find(vm.metadata, {field: field});
    }
}