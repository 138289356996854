import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  async togglePanel({}, value) {
    return commits.devtools.togglePanel(value);
  },
  async addQueryNotice() {
    return commits.devtools.addQueryNotice();
  },
  async addRequests({}, value) {
    return commits.devtools.addRequests(value);
  },
  async addResponses({}, value) {
    return commits.devtools.addResponses(value);
  },
  async addErrors({}, value) {
    return commits.devtools.addErrors(value);
  },
  async resetQueryNoticeCount() {
    return commits.devtools.resetQueryNoticeCount();
  },
};
