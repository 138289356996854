'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('design.resources', [])
    .factory('DesignResource', DesignResource);

function DesignResource(
    Restangular
) {
    var dash = Restangular.all('dash');
    var pages = dash.all('pages');
    var layouts = dash.all('layouts');

    return {
        getPages: getPages,
        getResource: getResource,
        setIsFavorite: setIsFavorite
    };

    /**
     * Returns the appropriate Restangular resource
     * @param pageType
     */
    function getResource(pageType) {
        return Restangular.all(pageType);
    }

    function getPages(request) {
        request = _.extend({
            sort: 'display_order',
            all: true
        }, request);

        return pages.getList(request).then(function(pages) {
            return pages.plain();
        });
    }

    function setIsFavorite(pageId, value) {
        return pages.one(pageId).one('updateisfavorite').one(value).put();
    }
}