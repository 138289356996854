'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import Core from './main';
import swal from 'bootstrap-sweetalert';

var DevTools = {
    requests: [],
    responses: [],
    widgets: {},
};

// PRIVATE VARS
var apiRoot = 'https://tapclicks.atlassian.net/rest/api/2/';
var tapClicksRoot = 'https://tapclicks.atlassian.net/secure/';
var createIssueUrl = 'CreateIssueDetails!init.jspa';

var constants = {
    errortype: {
        api: 'api',
        slim: 'slim',
        php: 'php',
        sql: 'sql',
        js: 'js'
    }
};

/**
 * Information about development environment
 * @param devServer
 */
var isEnabled = false;

DevTools.setAsEnabled = function(value) {
    isEnabled = value;
};

DevTools.isEnabled = function() {
    return isEnabled;
};

/**
 * Restart a fresh profiling
 */
DevTools.resetProfiling = function() {
    this.requests = [];
    this.responses = [];
    this.widgets = {};
};

/**
 * Log and store info about requests
 * @param url
 * @param params
 */
DevTools.logRequest = function(url, params) {
    url = url || null;
    params = params || {};

    this.requests.push({
        url: url,
        params: params,
        fullUrl: getFullUrl(url, params)
    });

    if ('widget_request' in params && !('sample' in params)) {
        // Only when making the getData call of a widget does it get set to the widget id
        if (_.isNull(params.widget_request) || params.widget_request == 'null') return;

        var widgetId = params.widget_request.toString();
        this.widgets[widgetId] = this.widgets[widgetId] || {};
        var widget = this.widgets[widgetId];
        widget.request = getFullUrl(url, params);
    }
};

/**
 * Log and store info about payload responses
 * @param url
 * @param params
 * @param json
 */
DevTools.logResponse = function(url, params, json) {
    url = url || null;
    params = params || {};
    json = json || {};
    var devTools = json.dev_tools;
    var queries = 'N/A';
    var times = 'N/A';

    if (devTools) {
        queries = devTools['queries'];
        times = devTools['times'];
    }

    this.responses.push({
        url: url,
        params: params,
        queries: queries,
        times: times,
        fullUrl: getFullUrl(url, params)
    });

    if ('widget_request' in params && !('sample' in params)) {
        // Only when making the getData call of a widget does it get set to the widget id
        if (_.isNull(params.widget_request) || params.widget_request == true) return;

        var widgetId = params.widget_request.toString();
        this.widgets[widgetId] = this.widgets[widgetId] || {};
        var widget = this.widgets[widgetId];

        widget.datasql = devTools.widget_sql;

        widget.timeProfile = {
            appInit: times.api,
            apiCall: times.code,
            totalRuntime: times.total,
            queryRuntime: times.sql,
            numQueries: queries.profiled_count,
        };
    }
};

/**
 * Log and store info about errors
 * @param url
 * @param params
 * @param json
 */
DevTools.logError = function(url, params, json) {

    url = url || null;
    params = params || {};
    var errorMsg = (Array.isArray(json) ? json[0] :json) || null;

    // @todo: log all error types (only doing widget currently)

    if ('widget_request' in params && !('sample' in params)) {
        // Only when making the getData call of a widget does it get set to the widget id
        if (_.isNull(params.widget_request) || params.widget_request == true) return;

        var widgetId = params.widget_request.toString();
        this.widgets[widgetId] = this.widgets[widgetId] || {};
        var widget = this.widgets[widgetId];

        if (!_.isNull(errorMsg)) {
            var errorTypes = constants.errortype;
            // Default errorType is API error
            var errorType = errorTypes.api;
            // Try to infer error type using error content
            if (this.isSQLError(errorMsg)) {
                errorType = errorTypes.sql;
            }
            else if (this.isSlimError(errorMsg)) {
                errorType = errorTypes.slim;
            }
            else if (this.isPHPError(errorMsg)) {
                errorType = errorTypes.php;
            }

            widget.bug = this.logBug(widgetId, errorMsg, errorType, url, getQueryString(params));
        }
    }
};
/**
 * Returns a list of all the requested URLs
 */
DevTools.getRequestList = function() {
    return _.map(this.requests, function(request) {
        return request.fullUrl;
    });
};

/**
 * Returns a list of all the responses URLs
 */
DevTools.getResponseList = function() {
    return _.map(this.responses, function(request) {
        return request.fullUrl;
    });
};


/**
 * Sets a unique bug in the devtools store
 * @param widgetId
 * @param error
 * @param errorType
 * @param url
 * @param queryString
 */
DevTools.logBug = function (widgetId, errorMsg, errorType, url, queryString) {
    var bug = {};
    bug.errorMsg = errorMsg;
    bug.errorType = errorType;
    bug.url = url || null;
    bug.originUrl = window.location.href;
    bug.queryString = queryString || null;

    var fullUrl = null;
    if (!_.isNull(url) ) {
        fullUrl = url + queryString;
        if (!_.includes(fullUrl, window.location.origin)) {
            fullUrl= window.location.origin + fullUrl;
        }
    }
    bug.fullUrl = fullUrl;
    var hash = !_.isNull(fullUrl) ? hashCode(fullUrl + errorMsg) : hashCode(errorMsg);
    bug.hash = hash;

    var amplifyKey = getAmplifyKey(widgetId);
    var cachedWidgetBug = amplify.getFromCache(amplifyKey);
    if (cachedWidgetBug) {
        // Check if we have a new error for the same widget
        if (cachedWidgetBug.error != bug.error) {
            bug.isLogged = false;
            amplify.safeStore(amplifyKey, bug);
        }
        // If already in cache check if we've logged it already
        else {
            bug.isLogged = cachedWidgetBug.isLogged;
        }
    }
    // Store bug for that widget if not exist
    else {
        bug.isLogged = false;
        amplify.safeStore(amplifyKey, bug);
    }

    return bug;
};

/**
 * We need to parse the response string to find out whether or not we actually have an error
 * NOTE: this is used for responses that don't use the proper http status code of 500, etc..
 * and don't provide an error property set to true (i.e. 200 with error inside of it)
 * @param string
 */
DevTools.isErrorString = function (string) {
    return this.isPHPError(string) || this.isSQLError(string) || this.isSlimError(string);
};

/**
 * Parses string to try and detect Slim specific patterns
 * @param string
 */
DevTools.isSlimError = function (string) {
    return _.isString(string) && _.includes(string, 'Slim Application Error');
};

/**
 * Parses string to try and detect PHP specific patterns
 * @param string
 */
DevTools.isPHPError = function (string) {
    return _.isString(string) && _.startsWith(string, '<pre>') && _.endsWith(string, '</pre>');
};

/**
 * Parses string to try and detect SQL specific patterns
 * @param string
 */
DevTools.isSQLError = function (string) {
    return _.isString(string) && _.includes(string, 'SQLSTATE');
};

/**
 * @param widgetId
 */
DevTools.createJiraTicket = function (widgetId) {
    var bug = this.widgets[widgetId].bug;
    var url = tapClicksRoot + createIssueUrl;
    var params = {};
    params.summary = 'Widget ' + widgetId + ' - ' + bug.errorType.toUpperCase() + ' error';
    params.pid = 10300;
    // BUG issue type
    params.issuetype = 10302;
    //params.reporter = $scope.currentUser.name;
    // Reported by 'Internal'
    params.customfield_10500 = 10501;
    // Environment
    params.customfield_10501 = Core.userSettings.isStagingServer ? 10503 : 10504;
    // Error type
    params.customfield_11900 = this.getJiraErrorTypeCode(bug.errorType);
    params.components = 'NWA';
    params.description = getErrorDescription(bug);

    // Change widget bug status to logged
    var amplifyKey = getAmplifyKey(widgetId);
    bug.isLogged = true;
    amplify.safeStore(amplifyKey, bug);

    httpOpen('POST', url, params, '_blank');
};

/**
 * @param bug
 */
DevTools.postToSlack = function (bug) {
    var root = 'https://slack.com/api/chat.postMessage';
    var params = {};
    // params.token = slackToken;
    params.channel = '#buglogger';
    params.text = getErrorDescription(bug);
    params.as_user = false;
    params.username = 'TapAnalytics';
    params.icon_url = 'https://media.glassdoor.com/sqll/1010430/tapclicks-squarelogo-1445540238367.png';

    var url = root + '?' + $.param(params);

    $.get(url, function() {
        return swal({
            title: '<img src="http://www.freeiconspng.com/uploads/slack-icon-10.png" width="48" height="48" class="mr5" />Message posted to Slack!',
            type: 'success',
            html: true
        });
    });
};


/**
 * Parses string to try and detect SQL specific patterns
 * @param string
 */
DevTools.getJiraErrorTypeCode = function (type) {
    var errortype = constants.errortype;
    switch (type) {
        case errortype.slim:
            return 13200;
        case errortype.api:
            return 13201;
        case errortype.sql:
            return 13202;
        case errortype.js:
            return 13203;
        case errortype.php:
            return 13204;
    }
};

/**
 * Allows to retrieve an angular Service/Factory directly from the console
 * @param factoryName
 */
DevTools.getFactory = function(factoryName) {
    return angular.element(document.body).injector().get(factoryName)
};

/**
 * Allows to change theme dynamically
 * @param themeName
 * @param themeType
 * @param primaryColor
 * @param secondaryColor
 */
DevTools.updateTheme = function(themeName, themeType, primaryColor, secondaryColor) {
    var themeUIService = DevTools.getFactory('ThemeUIService');
    themeUIService.setForceReload(true);
    themeUIService.update(themeName, themeType, primaryColor, secondaryColor, true, document);
};

/**
 * Annotates elements with scope count, and watcher count (scope,watchers)
 */
DevTools.superWatcher = function () {
    function annotateWatchers(elem) {
        elem = angular.element(elem);
        var selfWatchers = countWatchers(elem),
            totalWatchers = selfWatchers;
        angular.forEach(elem.children(), function(child) {
            totalWatchers += annotateWatchers(child);
        });
        elem.attr('data-watchers', selfWatchers + ',' + totalWatchers);
        return totalWatchers;
    }

    function countWatchers(elem) {
        if (!elem || !elem.data) {
            return 0;
        }
        return countScopeWatchers(elem.data().$scope) + countScopeWatchers(elem.data().$isolateScope);
    }

    function countScopeWatchers(scope) {
        if (!scope || !scope.$$watchers) {
            return 0;
        }
        return scope.$$watchers.length;
    }

    annotateWatchers(document.documentElement);
}

// PRIVATE METHODS
/**
 * Allows to open in new window or tab with an HTTP request at the same time
 * @param verb - 'GET'|'POST'
 * @param url
 * @param data
 * @param target - an optional opening target (a name, or "_blank"), defaults to "_self"
 */
function httpOpen(verb, url, data, target) {
    var form = document.createElement('form');
    form.action = url;
    form.method = verb;
    form.target = target || '_self';
    if (data) {
        for (var key in data) {
            var input = document.createElement('textarea');
            input.name = key;
            input.value = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
            form.appendChild(input);
        }
    }
    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
};

/**
 * Returns a query string using on a param object
 * @param params
 * @returns {string}
 */
function getQueryString(params) {
    // _.pickBy() excludes null values
    return params ? '?' + $.param(_.pickBy(params)) : '';
}

/**
 * Returns a key to use in the amplify cache store
 * @param widgetId
 * @returns {string}
 */
function getAmplifyKey(widgetId) {
    return 'bug_' + widgetId;
};

/**
 * Returns a full error description
 * @param bug
 * @returns {string}
 */
function getErrorDescription(bug) {
    var description = '*Origin URL:* \n\n' + bug.originUrl + '\n\n';
    if (bug.fullUrl) {
        description += '*API Call URL:* \n\n' + bug.fullUrl + '\n\n';
    }
    description += '*Error:* \n\n' + bug.errorMsg;
    return description;
}

/**
 * Returns a unique hashcode given a string
 * @param str
 * @returns {*}
 */
function hashCode(str) {
    var hash = 0, i, chr, len;
    if (str.length === 0) return null;
    for (i = 0, len = str.length; i < len; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString();
}

/**
 * Returns a full url with root + url + ?query params
 * @param url
 * @param params
 * @returns {*}
 */
function getFullUrl(url, params) {
    var queryString = getQueryString(params);
    return window.location.origin + url + queryString;
}

if (typeof define === 'function' && typeof define.amd === 'object' && define.amd) {

    // AMD. Register as an anonymous module.
    define(function() {
        return DevTools;
    });
} else if (typeof module !== 'undefined' && module.exports) {
    module.exports = DevTools.attach;
    module.exports.DevTools = DevTools;
} else {
    window.DevTools = DevTools;
}

export default DevTools;