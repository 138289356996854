'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import exportCustomReportModalHtmlUrl from './export.customreport.modal.html';
var $fileDownload = $.fileDownload;

angular.module('core.export.directives')

    .directive('exportCustomReportModal', exportCustomReportModal);

/**
 * @ngInject
 */
function exportCustomReportModal(
    SlidePanelFactory
) {
    return {
        restrict: 'E',
        scope: {
        },
        templateUrl: exportCustomReportModalHtmlUrl,
        controller: ExportCustomReportModalController,
        link: function(scope, el) {
            var $modal = el.find('div.modal');

            $modal.on('show', function () {
                SlidePanelFactory.closeAll();
            });

            $modal.on('hidden', function () {
                scope.resetState();
            });
        }
    }
}

/**
 * @ngInject
 */
function ExportCustomReportModalController(
    $timeout,
    $scope,
    LoadingState,
    UserThemes,
    PageOrientation,
    CoreFactory,
    AppFactory,
    SlidePanelFactory,
    DesignFactory,
    UIFactory,
    ReportingTemplateFactory,
    ExportReportFactory,
    ThemeUIService
) {
    ExportReportFactory.$registerCustomReportModalScope($scope);
    var currentPage = DesignFactory.getCurrentPage();
    var currentFileDownload = null;

    $scope.init = init;
    $scope.resetState = resetState;
    $scope.toggleTab = toggleTab;
    $scope.generatePreviewLink = generatePreviewLink;
    $scope.iframeLoadedCallBack = iframeLoadedCallBack;
    $scope.toggleStyleForPrint = toggleStyleForPrint;
    $scope.exportToPdf = exportToPdf;
    $scope.onModalClose = onModalClose;
    $scope.canIncludeReportingTemplates = canIncludeReportingTemplates;

    $scope.userCanEdit = AppFactory.getUser().isAdmin();
    $scope.state = getDefaultState();
    $scope.model = getDefaultModel();
    $scope.isNUI = window.isNUI;

    function getDefaultModel() {
        return {
            useLastGeneratedReport: false,
            useReportOptions: true,
            styleForPrint: false,
            isHighResolution: false,
            showLayoutTitle: true,
            reportingTemplates: [],
            selectedTemplateId: null,
            orientation: PageOrientation.TYPE_LANDSCAPE,
            // Inherited from the current page reporting options
            append_exported_datagrids: currentPage.metadata.append_exported_datagrids,
            append_exported_datagrids_to_layout: currentPage.metadata.append_exported_datagrids_to_layout,
            hide_empty_exported_widgets: currentPage.metadata.hide_empty_exported_widgets
        };
    }

    function getDefaultState() {
        return {
            isShowing: false,
            exporting: false,
            isLoadingPreview: false
        };
    }

    function getViewportWidth() {
        return $(window).width() - 300;
    }

    function getViewportHeight() {
        return $(window).height() - 3 /* window padding */;
    }

    function getIframePageWidth() {
        var letterPageRatio = 110/85;
        var pageWidth = 1592;
        // for wkhtmltopdf, makes 1584 pixels (1592 width - 8 padding), which is divisible by 12 (for isotope 12x14)
        return $scope.model.orientation === PageOrientation.TYPE_LANDSCAPE
            ? pageWidth
            : pageWidth / letterPageRatio;
    }

    function getScaleRatio() {
        return (getViewportWidth() - 35 - (window.isNUI ? 100 : 0) /* margin */) / getIframePageWidth();
    }

    function resetState() {
        $scope.state = getDefaultState();
    }

    function toggleTab(index) {
        return SlidePanelFactory.toggleTab(index);
    }

    function canIncludeReportingTemplates() {
        return $scope.model.reportingTemplates.length && !AppFactory.getUser().isClient();
    }

    function hasReportingTemplatesPermission() {
        return Permission.hasPermissionToRead(Permission.moduleName.REPORTINGTEMPLATE);
    }

    function init() {
        // Always use generate a fresh report the first time initializing
        $scope.model = getDefaultModel();
        generatePreviewLink();
        $timeout(function() {toggleTab(0);}, 0, false);

        $scope.model.useLastGeneratedReport = true;
        $scope.state.isShowing = true;
        $scope.state.isLoadingPreview = true;

        if (hasReportingTemplatesPermission()) {
            ReportingTemplateFactory.getData({all: true}).then(function (json) {
                $scope.model.reportingTemplates = json.plain();
            });
        }

        $scope.zoomSlider = {
            ready: false,
            value: 100,
            options: {
                floor: 25,
                ceil: 100,
                vertical: false,
                showSelectionBar: true,
                translate: function(value) {
                    return value + '%';
                },
                onChange: function () {
                    var value = $scope.zoomSlider.value;
                    $('#export-preview-frame')
                        .contents()
                        .find('div.design-page')
                        .css('transform', 'scale(' + (value/100) * getScaleRatio() + ')');
                }
            }
        };
    }

    /**
     * @param isHtml
     * @returns {{}}
     */
    function getExportParams(isHtml) {
        return {
            pageId: currentPage.id,
            html: isHtml,
            use_last_generated_report: $scope.model.useLastGeneratedReport,
            use_report_options: $scope.model.useReportOptions,
            style_for_print: $scope.model.styleForPrint,
            orientation: $scope.model.orientation,
            show_layout_title: $scope.model.showLayoutTitle,
            reporting_template_id: $scope.model.selectedTemplateId
        };
    }

    function generatePreviewLink() {
        $scope.state.isLoadingPreview = true;
        $scope.previewLink = ExportReportFactory.exportToPdf(getExportParams(true)).getRequestedUrl();
    }

    function iframeLoadedCallBack() {
        $scope.$evalAsync(function() {
            $scope.state.isLoadingPreview = false;
            $scope.zoomSlider.value = 100;

            $timeout(function() {
                var $iframe = $('#export-preview-frame');
                $iframe.attr('width', getViewportWidth());
                $iframe.attr('height', getViewportHeight());
                $iframe.css('visibility', 'visible');
                $iframe
                    .contents()
                    .find('div.design-page')
                    .css('transform', 'scale(' + getScaleRatio() + ')');
            }, window.isNUI ? 300 : 0, false);
        });
    }

    function toggleStyleForPrint() {
        var $iframe = $('#export-preview-frame').contents();
        var $html = $iframe.find('html');
        var $body = $iframe.find('body');
        if ($scope.model.styleForPrint) {
            var themeName = UserThemes.BRIGHT;
            var themeType = UserThemes.TYPE_LIGHT;
            ThemeUIService.update(themeName, themeType, null, null, true, $iframe.get(0));
            $html.removeClass();
            $html.addClass(themeName);
            $body.addClass('print');
        }
        else {
            var themeName = UserThemes.CUSTOMDARK;
            var themeType = UserThemes.TYPE_LIGHT;
            ThemeUIService.update(themeName, themeType, null, null, true, $iframe.get(0));
            $html.removeClass();
            $html.addClass(themeName);
            $body.removeClass('print');
        }
    }

    /**
     * Export to pdf for dashboard or report and preview or not
     */
    function exportToPdf() {
        $scope.$evalAsync(function() {
            $scope.state.exporting = true;
            currentFileDownload = $fileDownload(ExportReportFactory.exportToPdf(getExportParams(false)).getRequestedUrl(), {
                successCallback: function () {
                    doneLoading();
                },
                failCallback: function (err) {
                    // $fileDownload returns err as an html string wrapped in '<pre>'
                    if (err && err.length) {
                        var errObj = JSON.parse($(err).html());
                        UIFactory.notify.showWarning(_.first(errObj.data));
                    }
                    doneLoading();
                }
            });
        });
    }

    function doneLoading() {
        currentFileDownload = null;
        $scope.$evalAsync(function() {
            $scope.state.exporting = LoadingState.DONE;
        });
    }

    /**
     * If user closes/cancels the modal while downloading, we should cancel the download.
     */
    function onModalClose() {
        currentFileDownload && currentFileDownload.abort();
    }
}