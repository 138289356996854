'use strict';
import angular from 'angular';

import widgetMediaHtmlUrl from './widget.media.html';

angular.module('widget.media.directives', [])
    .component('mediaWidget', {
        templateUrl: widgetMediaHtmlUrl,
        bindings: {
            state: '=',
            widget: '=',
            widgetIsPredefined: '<'
        },
        controllerAs: 'vm',
        controller: MediaWidgetController,
    })
    .directive('bindHtmlCompile', bindHtmlCompileHandler);

const MAX_MEDIA_WIDGET_HEIGHT = 560;

bindHtmlCompileHandler.$inject = [
    '$compile',
    '$rootScope',
    '$isoEvents',
    '$sce',
    '$parse',
    'ImageExportLoadingFactory',
];

/**
 * Uses the source from angular 1.5 ng-bind-html as a base with a switch for dashExport for when we're PDF-ing
 *
 * As soon as the element has been added into the DOM (through $compile) we have access to its actual height
 * rather than the computed / assumed height of 560px, so we can trigger an isotope layout
 * @param $compile
 * @param $rootScope
 * @param $isoEvents
 * @param $sce
 * @param $parse
 * @returns {{link: link, restrict: string, priority: number}}
 */
function bindHtmlCompileHandler(
    $compile,
    $rootScope,
    $isoEvents,
    $sce,
    $parse,
    ImageExportLoadingFactory
) {
    return {
        restrict: 'A',
        priority: 0,
        compile: function ngBindHtmlCompile(tElement, tAttrs) {
            const ngBindHtmlGetter = $parse(tAttrs.bindHtmlCompile);
            const ngBindHtmlWatch = $parse(tAttrs.bindHtmlCompile, function sceValueOf(val) {
                // Unwrap the value to compare the actual inner safe value, not the wrapper object.
                return $sce.valueOf(val);
            });
            $compile.$$addBindingClass(tElement);

            return function ngBindHtmlLink(scope, element, attr) {
                $compile.$$addBindingInfo(element, attr.bindHtmlCompile);

                scope.$watch(ngBindHtmlWatch, function ngBindHtmlWatchAction() {
                    // The watched value is the unwrapped value. To avoid re-escaping, use the direct getter.
                    const value = ngBindHtmlGetter(scope);
                    element.html($sce.getTrustedHtml(value) || '');

                    const parentElement = element.parent();
                    const containerElement = parentElement.parent().parent();
                    const widgetElement = containerElement.parent().parent().parent();

                    // we set the widget height if we should and if any images present in the widget are loaded
                    if (shouldResizeWidget(widgetElement)) {
                        const imagesFromValue = pullImagesFromHTML(value);
                        const valueHash = ImageExportLoadingFactory.hashString(value);

                        ImageExportLoadingFactory.imagesAreLoaded(valueHash, imagesFromValue).then(() => {
                            // 65 seems to work out as the widget header height and then some padding
                            const setHeightTo = (65 + parentElement[0].clientHeight);

                            // don't bother making media widgets any taller than they are allowed to be
                            if (setHeightTo > MAX_MEDIA_WIDGET_HEIGHT) {
                                return;
                            }

                            widgetElement.height(setHeightTo + "px");

                            // trigger isotope re-layout to drag anything below this further up the page
                            // $rootScope.$broadcast($isoEvents.LAYOUT);
                            $rootScope.$emit('media-widget-in-dom', {});
                        });
                    }
                });
            };
        }
    }
}

/**
 * Use the query selector to look for images so we don't have to mess about with regex when the browser
 * is already doing it for us
 * @param value
 * @returns {NodeListOf<HTMLElementTagNameMap[string]>}
 */
function pullImagesFromHTML(value) {
    const div = document.createElement("div");
    div.innerHTML = value;

    return div.querySelectorAll("img");
}

/**
 * Whether the widget as displayed on screen should resize itself to match its true height
 *
 * We only trigger automatic resize if it hasn't been resized in the dashbaord
 * 100% width as defined by the widget.computedWidth
 * 560px height as defined by widget.computedHeight
 *
 * @param widgetElement jQuery / angluar wrapped element containing the widget
 * @returns {boolean}
 */
function shouldResizeWidget(widgetElement) {
    if (!window.dashExport) {
        return false;
    }

    const widgetWidth = (typeof widgetElement[0].style.width === "undefined") ?
        "100%" : widgetElement[0].style.width;

    const widgetHeight = (typeof widgetElement[0].style.height === "undefined") ?
        "100%" : widgetElement[0].style.height;

    if (widgetWidth === "100%") {
        return widgetHeight === "560px";
    }

    return false;
}

/**
 * Create media widget
 * @ngInject
 */
function MediaWidgetController(
    $scope,
    $q,
    $WidgetEvents,
    MediaWidgetFactory,
    LoadingState,
    WidgetFactory,
) {

    var vm = this;
    vm.getMarginStyles = getMarginStyles;
    registerEvents();
    buildMediaWidget();

    function buildMediaWidget() {
        vm.state.loadingState = LoadingState.FETCHING;
        $q.resolve(MediaWidgetFactory.getData(vm.widget)).then(function (json) {
            vm.widget.metadata.content = json.data ? json.data : json;
            $scope.$evalAsync(function() {
                vm.state.isEditing = false;
                vm.state.loadingState = LoadingState.DONE;
                WidgetFactory.widgetLoaded(vm.widget);
            });

        }, function () {
            $scope.$evalAsync(function() {
                vm.state.isEditing = false;
                vm.state.loadingState = LoadingState.HAS_ERROR;
                console.error('Something went wrong when loading media widget');
                WidgetFactory.widgetLoaded(vm.widget);
            });
        });
    }

    var $widgetRebuildFn;
    function registerEvents() {
        $widgetRebuildFn = $scope.$on($WidgetEvents.WIDGET_REBUILD, buildMediaWidget);

        $scope.$on('$destroy', function() {
            $widgetRebuildFn();
        });
    }
    function getMarginStyles() {
        if (vm.widget.metadata?.data_source?.id && vm.widget.height > 1 && vm.widget.title === '') {
            return {'padding-left': '3.4rem','padding-top': '0.7rem'};
        }
        return {'margin-left': '0px !important'};
    }
}
