import angular from 'angular';
import _ from 'lodash';

angular.module('datagrid.tagpicker.models', [])
    .factory('TagPickerModelFactory', TagPickerModelFactory);

/**
 * @ngInject
 */
function TagPickerModelFactory(
    UIColor,
    TapColorsService,
    TagPickerSelectConstant
) {
    return {
        getTagPickerEventModel: getTagPickerEventModel,
        getTagSelectOptions: getTagSelectOptions,
        getTagPickerModel: getTagPickerModel,
        getTagPickerState: getTagPickerState,
        getTagValueModel: getTagValueModel,
        getTagModel: getTagModel
    };

    function getTagPickerEventModel(model) {
        return new TagPickerEventModel(model);
    }

    /**
     * Return select2 Options
     * @returns {TagPickerSelectOptions}
     */
    function getTagSelectOptions() {
        return new TagPickerSelectOptions();
    }

    /**
     * Builds data structure TagPickerModel
     * @param dataSourceType
     * @param value
     * @param rowData
     * @param [availableTags]
     * @param [selectedTag]
     * @returns {TagPickerModel}
     */
    function getTagPickerModel(dataSourceType, value, rowData, availableTags, selectedTag) {
        return new TagPickerModel(dataSourceType, value, rowData, availableTags, selectedTag);
    }

    /**
     * Builds a TagPickerState
     * @returns {TagPickerState}
     */
    function getTagPickerState() {
        return new TagPickerState();
    }

    /**
     * Builds a TagValueModel
     * @returns {TagValueModel}
     */
    function getTagValueModel(model) {
        return new TagValueModel(model);
    }

    /**
     * Builds a TagModel
     * @param model
     * @returns {TagModel}
     */
    function getTagModel(model) {
        return new TagModel(model);
    }


    /**
     * Represents the select2 Options for TagPicker
     * @constructor
     */
    function TagPickerSelectOptions() {
        var self = this;
        var _currentText = '';

        self.allowClear = true;
        self.multiple = false;
        self.placeholder = 'Create or select a tag ...';
        self.formatResult = _formatDisplay;
        self.formatSelection = function(item) {
            return _formatDisplay(item);
        };
        self.formatNoMatches = _formatNoMatches;
        self.matcher = matchCustom;
        self.onTextChange = onTextChange;

        /**
         * Select2 override How to show selected value in dropdown or as a selectable value
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatDisplay(item) {
            var result = null;
            // If present, add color coding
            if (item.color && item.text) {
                var textColor = UIColor.textColorWithBackgroundColor(item.color);
                result = '<span class="badge tag-badge" style="background-color:'+item.color+';color:'+ textColor +';padding-left:5px"><span class="icon icon-tag mr2" style="color:'+ textColor +'"></span>' + item.text + '</span>';
            } else if (item.id === TagPickerSelectConstant.PLACEHOLDER_ID) {
                return _.isEmpty(_currentText) ?  item.text : '<span>Press <b>Enter</b> to add <u><i>'+ _currentText +'</i></u> as a new tag</span>';
            } else if (item.text) {
                result = item.text;
            }
            return result;
        }

        /**
         * @param params
         * @returns {string}
         * @private
         */
        function _formatNoMatches(params) {
            return '<span>Press <b>Enter</b> to add <u><i>'+params+'</i></u> as a new tag</span>';
        }

        /**
         * Select2 override to custom match search string
         * @param params
         * @param data
         * @returns {*}
         */
        function matchCustom(params, data) {
            _currentText = params;
            if (data === TagPickerSelectConstant.PLACEHOLDER_TAGS || data === TagPickerSelectConstant.PLACEHOLDER_NO_TAGS) {
                return _formatNoMatches(params);
            }
            if (data.toLowerCase().contains(params.toLowerCase())) {
                return data;
            }
            return null;
        }

        /**
         * UI-Select2 function implementation callback
         * @param data
         * @param text
         */
        function onTextChange($el, data, text) {
            if (_.isEmpty(text) && !_.isEmpty(_currentText)) {
                _currentText = text; // must be set before select2 events
                $el.trigger('change.select2');
                $el.select2('open');
            }
            // this line is here in case above condition is not met yet.
            _currentText = text;
        }
    }

    /**
     * Represents the model for TagPicker UI representation
     * @param dataSourceType
     * @param {Object} value Model Entity
     * @param rowData
     * @param {Array} availableTags Arrays of Tags
     * @param [currentTag] if not passed, assumed to have not selected a tag
     * @constructor
     */
    function TagPickerModel(dataSourceType, value, rowData, availableTags, currentTag) {
        var self = this;

        self.updateCurrentValue = updateCurrentValue;
        self.updateAvailableValues = updateAvailableValues;
        self.setNewColor = setNewColor;

        self.searchText = null;
        self.currentTag = currentTag ? new TagValueModel(currentTag) : new TagValueModel({data_source: dataSourceType, value: rowData[value.field], column: value.field});
        self.currentTag.data_source = dataSourceType;
        self.availableTags = availableTags
            ? _.map(availableTags, function (tag) {
                return new TagValueModel(tag)
            })
            : [];

        var pladeholderText = self.availableTags.length
            ? TagPickerSelectConstant.PLACEHOLDER_TAGS
            : TagPickerSelectConstant.PLACEHOLDER_NO_TAGS;
        var placeHolderTag = {id: TagPickerSelectConstant.PLACEHOLDER_ID, tag: pladeholderText, color: null};
        self.availableTags.unshift(placeHolderTag); // always places placeholder at the beginning

        /**
         * Sets the new color to model and updates select2 require value option
         * @param newColor
         */
        function setNewColor(newColor) {
            self.currentTag.color = newColor;
            self.updateCurrentValue();
        }

        /**
         * Updates select2 available value option
         */
        function updateAvailableValues() {
            self.availableValues = _.map(self.availableTags, function (tag) {
                return {id: tag.id, text: tag.tag, color: tag.color || null}
            });
        }

        /**
         * Updates select2 current value option
         */
        function updateCurrentValue() {
            if (!self.currentTag.tag) {
                self.currentValue = null;
                return;
            }
            self.currentValue = {id: self.currentTag.id, text: self.currentTag.tag, color: self.currentTag.color}
        }

        // For select2 tracking
        self.updateCurrentValue();
        self.updateAvailableValues();

        if (!self.currentTag.color) {
            setNewColor(TapColorsService.getRandomColor());
        }
    }

    /**
     * @param [model]
     * @constructor
     */
    function TagValueModel(model) {
        var self = this;

        self.id = model && model.id || null;
        self.value = model && model.value || null;
        self.tag_id = model && model.tag_id || null;
        self.column = model && model.column || null;
        self.color = model && model.color || null;
        self.tag = model && model.tag;
        self.data_source = model && model.data_source;

        self.isEmpty = function () {
            return !(self.tag && self.color);
        };
    }

    function TagModel(model) {
        var self = this;
        self.id = model.id;
        self.color = model.color;
    }

    /**
     * Represents the Visual state of Model
     * @constructor
     */
    function TagPickerState() {
        this.isActive = false;
        this.isPickerOpen = false;
        this.isSaving = false;
        this.isLoading = false;
    }

    /**
     * Required properties to emit event of TagPicker
     * @param model
     * @constructor
     */
    function TagPickerEventModel(model) {
        var self = this;

        self.cellPosition = model.cellPosition;
        self.position = model.position;
        self.dataSourceType = model.dataSourceType;
        self.rowData = model.rowData;
        self.value = model.value;
        self.tag = model.tag;
    }

}