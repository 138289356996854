'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.industrybenchmark.resources', [])
    .factory('IndustryBenchmarkResource', IndustryBenchmarkResource);

function IndustryBenchmarkResource(
    Restangular,
    ColumnValueType
) {
    var industrybenchmark = Restangular.all('industrybenchmark');
    var vars = data.one('vars');
    var values = data.one('values');

    /**
     * @param queryParams
     * @returns {*}
     */
    function getColumns(queryParams) {
        return industrybenchmark.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @param dataSource
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        queryParams = _.extend({fields: fields}, queryParams || {});
        return values.one(ColumnValueType.DISTINCT).get(queryParams);
    }

    /**
     * Get industrybenchmark for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return industrybenchmark.getList(_.extend({
            all: true
        }, queryParams));
    }

    return {
        industrybenchmark: industrybenchmark,
        vars: vars,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData
    }
}
