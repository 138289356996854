import { DrawOption } from '@/modules/ta/widget/widget.constants';

export class DrawOptionItem {
  /**
   * @type {*}
   */
  value;

  /**
   * type {boolean}
   */
  overridden;

  /**
   * @type {Object}
   */
  metadata;

  constructor(model = {}) {
    this.value = model.value;
    this.overridden = model.overridden;
    this.metadata = model.metadata || {};
  }

  /**
   *
   * @param key
   */
  buildDrawOptionDefaultMetadata(key) {
    switch (key) {
      case DrawOption.SHOW_BACKGROUND:
        this.metadata = {
          color: this.metadata.color ?? '#FFFFFF',
          alpha: this.metadata.alpha ?? 1,
        };
        break;
      case DrawOption.SHOW_SHADOW:
        this.metadata = {
          color: this.metadata.color ?? '#000000',
          x: this.metadata.x ?? 0,
          y: this.metadata.y ?? 3,
          blur: this.metadata.blur ?? 6,
        };
        break;
      case DrawOption.SHOW_BORDERS:
        this.metadata = {
          color: this.metadata.color ?? '#EBEBEB',
          alpha: this.metadata.alpha ?? 1,
          size: this.metadata.size ?? 1,
        };
        break;
      default:
        break;
    }
  }
}
