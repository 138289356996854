import { uniqWith } from 'lodash';
import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';

export class SectionTemplate extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  unique_id;

  /**
   * @var {string}
   */
  title;

  /**
   * @var {string}
   */
  page_title;

  /**
   * @var {string}
   */
  tags;

  /**
   * @var {string}
   */
  description;

  /**
   * @var {boolean}
   */ is_in_library;

  /**
   * @var {boolean}
   */ is_predefined;

  /**
   * @var {string}
   */
  disconnected_service_ids;

  /**
   * @var {string}
   */
  thumbnail_metadata;

  /**
   * @var {number}
   */
  library_download_count;

  /**
   * @var {string}
   */ created_at;

  /**
   * @var {string}
   */
  formatted_created_at;

  /**
   * @var {string}
   */
  metadata;

  /**
   * @var {array}
   */
  data_sources;

  constructor(model) {
    super(model);

    this.id = model.id;
    this.unique_id = model.unique_id;
    this.title = model.title;
    this.page_title = model.page_title;
    this.tags = model.tags;
    this.description = model.description;
    this.is_in_library = model.is_in_library;
    this.is_predefined = model.is_predefined;
    this.disconnected_service_ids = model.disconnected_service_ids;
    this.thumbnail_metadata = model.thumbnail_metadata;
    this.library_download_count = model.library_download_count;
    this.created_at = model.created_at;
    this.formatted_created_at = model.formatted_created_at;
    this.metadata = model.metadata;
    this.data_sources = model.data_sources;
  }

  /**
   * Returns if there are any disconnected services.
   * @returns {boolean}
   */
  hasDisconnectedServices() {
    return !!this.disconnected_service_ids.length;
  }

  /**
   * Returns unique data sources.
   * @returns {Array}
   */
  getUniqueDataSources() {
    return uniqWith(this.data_sources, (dataSource) => this.getDataSourceKey(dataSource));
  }

  /**
   * Returns the unique string to identify a data source.
   * @param dataSource
   * @returns {string}
   */
  getDataSourceKey(dataSource) {
    return `${dataSource.data_source_id}-${dataSource.data_type}-${dataSource.data_view_id}`;
  }

  /**
   * Returns if a data source is service data or not.
   * @param dataSource
   * @returns {boolean}
   */
  isService(dataSource) {
    return dataSource.data_type === DataSourceType.SERVICE_DATA;
  }
}
