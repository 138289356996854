export const LocalOptionPlugin = (Vue) => {
  Vue.mixin({
    beforeCreate() {
      const { local: localOption } = this.$options;
      if (localOption && typeof localOption === 'function') {
        Object.assign(this, localOption.apply(this));
      } else if (localOption) {
        Logger.log(
          i18n.$t('Expected option local to be only a function that returns an object'),
          Logger.LEVEL_ERROR
        );
      }
    },
  });
};
