import { WidgetDataResource } from '@/modules/ta/widget/resources/WidgetDataResource';
import UserResource from '@/modules/ta/user/resources/UserResource';

/**
 * NOT IMPLEMENTED CORRECTLY
 */
export class UserDataWidgetDataResource extends WidgetDataResource {
  /**
   * @override
   * @param {Widget} widget
   * @param {Object} params
   * @returns {Promise<*>}
   */
  async getWidgetData(widget, params) {
    return UserResource.getItem(widget.cached_data.id, params);
  }

  /**
   * @override
   * @param {Widget} widget
   * @param {Object} params
   * @returns {Promise<*>}
   */
  async getColumns(widget, params) {
    /**
     * NOT IMPLEMENTED CORRECTLY
     */
    return UserResource.getColumns(widget, params);
  }
}
