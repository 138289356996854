'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('design.details.services', [])
    .constant('$DetailsModalFactoryEvents', DetailsModalFactoryEvents())
    .factory('DetailsModalFactory', DetailsModalFactory)
    .factory('DetailsModalFactoryDelegate', DetailsModalFactoryDelegate)
    .factory('DetailsModalEnumResource', DetailsModalEnumResource);

function DetailsModalFactoryEvents() {
    return {
        PRE_SAVE: '$DetailsModalFactoryEvents:PRE_SAVE',
        POST_SAVE: '$DetailsModalFactoryEvents:POST_SAVE',
        POST_ERROR: '$DetailsModalFactoryEvents:POST_ERROR',
        IS_ACTIVE: '$DetailsModalFactoryEvents:IS_ACTIVE',
        UPDATE_DATA: '$DetailsModalFactoryEvents:UPDATE_DATA',
        CLOSED: '$DetailsModalFactoryEvents:CLOSED',
        RENDER_SAVE: '$DetailsModalFactoryEvents:RENDER_SAVE'
    }
}

/**
 * @ngInject
 */
function DetailsModalFactory(
    PubSub,
    $q,
    $timeout,
    AppModelFactory,
    UIFactory,
    DataGridRender,
    DataSourceResourceDelegate,
    $DetailsModalFactoryEvents,
    DetailsModalEnumResource
) {
    var defaultProps = {
        state: {
            isActive: false,
            isNew: false,
            isLoading: false,
            isSaving: false,
            isSaveButtonActive: true
        },
        data: {
            modalId: 'details-modal',
            dataTable: null, // Datagrid info
            dataSourceType: null,
            resource: null,
            model: {},
            primaryKeyField: 'id',
            promises: [],
            prev: null,
            next: null,
            display: {
                title: '',
                loadingMessage: null,
                icon: 'icomoon-files'
            },
            modelPromise: null,
            cancel: false,
            cancelErrors: []
        }
    };

    // TODO: @chris move defaultProps to model factory

    var props = angular.copy(defaultProps);

    var _originalModel;

    var _getRow;

    return {
        setDetails: setDetails,
        unsetDetails: unsetDetails,
        saveDetails: saveDetails,
        cancelSave: cancelSave,
        shouldCancelSave: shouldCancelSave,
        getSaveErrors: getSaveErrors,
        loadModel: loadModel,
        updateHeader: updateHeader,
        updateTitle: updateTitle,
        getDataSourceType: getDataSourceType,
        getModel: getModel,
        getMetadata: getMetadata,
        getDisplay: getDisplay,
        getSwalOptions: getSwalOptions,
        getModalSize: getModalSize,
        getIsNewState: getIsNewState,
        getIsLoading: getIsLoading,
        getHideRowButtons: getHideRowButtons,
        setAsNew: setAsNew,
        getDefaultSelectOptions: getDefaultSelectOptions,
        getSelectedValues: getSelectedValues,
        setSelectableValues: setSelectableValues,
        setAjaxSelectableValues: setAjaxSelectableValues,
        setSaveInactive: setSaveInactive,
        setSaveActive: setSaveActive,
        getIsSaveButtonActive: getIsSaveButtonActive,
        getDetailColumns: getDetailColumns,
        openRow: openRow,
        offIsActive: offIsActive,
        onIsActive: onIsActive,
        $getElement: $getElement,
    };

    /**
     * @param {DetailsModalOptions} options
     */
    function setDetails(options) {
        _setDataSourceType(options.dataSourceType);
        _setPromises(options.promises, options.resource);
        _setResource(options.resource);
        _setDisplay(options.display);
        _setDataTable(options.row);
        _setModalSize(options.modalSize);
        _setOtherRows(options.getRow);
        _setHideRowButtons(options.hideRowButtons);
        _setIsActive();
    }

    function unsetDetails() {
        _setIsInactive();
        _reset();
    }

    /**
     * Save details of the current model in creation/editing
     * @param cb
     */
    function saveDetails(cb) {
        if (props.data.cancelErrors.length) {
            _.each(props.data.cancelErrors, function (error) {
                UIFactory.notify.showWarning(error.message);
            });
            props.data.cancelErrors = [];

            var defer = $q.defer();
            defer.reject();
            return defer.promise;
        }

        var primaryKeyField = props.data.primaryKeyField;
        var model = props.data.model;
        var resource = props.data.resource;
        props.state.isSaving = true;
        resource = props.state.isNew ? resource : resource.all(model[primaryKeyField]);
        return resource.post(model).then(function(json) {
            props.state.isSaving = false;
            model[primaryKeyField] = json.id;
            props.state.isNew ? _refreshDataTable() : _updateDataTableRow();
            _showSuccess(json, cb);
            return json.plain();
        }).catch(function (err) {
            props.state.isSaving = false;
            return err.data;
        });
    }

    /**
     * Will cancel the save operation
     * Should be called during the `PRE_SAVE` PubSub notification,
     *      before the saveDetails is later called
     * @param message Warning message to display
     */
    function cancelSave(message) {
        props.data.cancelErrors.push({message: message});
    }

    /**
     * Getter to see if save operation is cancellable
     * @returns {number}
     */
    function shouldCancelSave() {
        return props.data.cancelErrors.length;
    }

    /**
     * @param {Boolean} forceLoad Forces to refetch the model from the API
     * @returns {angular.IPromise<T>}
     */
    function loadModel(forceLoad) {
        if (!forceLoad && props.data.modelPromise) {
            return props.data.modelPromise;
        }

        var deferred = $q.defer();

        $q.all(props.data.promises).then(function (data) {
            var responseMetadata = data[0].plain ? data[0].plain() : data[0];

            props.state.isLoading = false;
            props.data.metadata = responseMetadata;

            let responseGroupings = data[1].plain ? data[1].plain() : data[1];

            if (!props.state.isNew && data[2]) {
                var responseModel = data[2].plain ? data[2].plain() : data[2];
                props.data.model = props.state.isNew ? {} : responseModel;
            }

            _setOriginalData(props.data.model);

            var resolveObject = {
                metadata: props.data.metadata,
                model: props.data.model,
                groupings: responseGroupings
            };

            if (!props.state.isNew && props.data.dataTable) {
                resolveObject = _.assign(resolveObject, {
                    currentIndex: props.data.dataTable.currentIndex,
                    dataTable: props.data.dataTable,
                    nextRow: props.data.dataTable.nextRow.data(),
                    prevRow: props.data.dataTable.prevRow.data(),
                    hasNextPage: props.data.dataTable.hasNextPage,
                    hasPreviousPage: props.data.dataTable.hasPreviousPage,
                    getRow: function(table, index) {
                        _getRow(table, index);
                    }
                })
            }

            deferred.resolve(resolveObject);

            _focusOnFirstFormInput();
            props.data.modelPromise = null;
        });

        props.data.modelPromise = deferred.promise;
        return deferred.promise;
    }

    /**
     * Public API interface to update the detail modal header
     * @param {DisplayModel} display
     */
    function updateHeader(display) {
        _setDisplay(display);
    }

    /**
     * Public API interface to update the title
     * @param title
     */
    function updateTitle(title) {
        props.data.display.title = title;
    }

    /**
     * @returns {null}
     */
    function getDataSourceType() {
        return props.data.dataSourceType;
    }

    /**
     * @returns {Array}
     */
    function getSaveErrors() {
        return props.data.cancelErrors;
    }

    /**
     * @returns {model|{}}
     */
    function getModel() {
        return props.data.model;
    }

    function getMetadata() {
        return props.data.metadata;
    }

    /**
     * @returns {display|{title, loadingMessage, icon}}
     */
    function getDisplay() {
        return props.data.display;
    }

    /**
     *
     * @param options
     * @returns {*|SwalOptions}
     */
    function getSwalOptions(options) {
        return AppModelFactory.getSwalOptions({
            title: 'You have made changes to this row. Do you want to save your changes and continue?',
            confirmButtonText: 'Save & Go',
            confirmFn: options.confirmFn
        });
    }

    function getModalSize() {
        return props.data.modalSize;
    }

    /**
     * @returns {boolean}
     */
    function getIsNewState() {
        return props.state.isNew;
    }

    /**
     * @returns {boolean}
     */
    function getIsLoading() {
        return props.state.isLoading || props.state.isSaving;
    }

    function getHideRowButtons() {
        return props.data.hideRowButtons;
    }

    function setAsNew(value) {
        value = _.isUndefined(value) ? false : value;
        props.state.isNew = value;
    }

    /**
     * @param column
     * @param model
     * @returns {{}}
     */
    function getDefaultSelectOptions(column, model) {
        // TODO:@chris use the app model factory to get select options
        return {
            key: column.field,
            multiple: false,
            allowClear: true,
            width: '100%',
            placeholder: column.placeholder || 'Select a ' + column.label,
            formatSelection: function(item) {
                if (column.groupby_id_field !== column.groupby_name_field) {
                    model[column.groupby_id_field] = item.id;
                    model[column.groupby_name_field] = item.text;
                } else {
                    model[column.field] = item.id;
                }
                if (column.has_set_values) {
                    model[column.groupby_id_field + '_display'] = item.text;
                }
                return _formatDisplay(item);
            },
            formatResult: _formatDisplay,
            onClear: function() {
                model[column.groupby_id_field] = null;
            },
        };

        /**
         * How to show selected value in dropdown or as a selectable value
         * @param item
         * @returns {*}
         * @private
         */
        function _formatDisplay(item) {
            var result = null;
            // If present, add color coding
            if (item.color) {
                result = '<span class="label" style="background-color:'+item.color+'">' + item.text + '</span>';
            } else {
                result = item.text;
            }
            return result;
        }
    }

    /**
     * @param column
     * @param model
     * @returns {*}
     */
    function getSelectedValues(column, model) {
        var idValue = model[column.groupby_id_field];
        if (column.has_set_values && idValue) {
            var item = _.find(column.values, {key: idValue});
            return _buildSelectValue(item);
        } else {
            var textValue = model[column.field];
            return _.isNil(textValue) ? null : {id: idValue, text: textValue};
        }
    }

    /**
     * @param values
     * @returns {any[] | boolean[]}
     */
    function setSelectableValues(values) {
        return _.map(values, function (item) {
            return _buildSelectValue(item);
        });
    }

    /**
     * We always want key & value but all other properties that maybe
     * be present should also be appended to the result object
     * @param item
     * @returns {{} | {id, text}}
     * @private
     */
    function _buildSelectValue(item) {
        var result = {id: item.key, text: item.value};
        return _.extend(result, _.omit(item, ['key', 'value']));
    }

    /**
     * @param column
     * @param queryParams
     */
    function setAjaxSelectableValues(column, queryParams) {
        var factory = DataSourceResourceDelegate.getFactory(column.value_data_source_type);
        var foreignClause = _.first(column.foreign_mapping.clause_conditions);
        return factory.getFieldValues(foreignClause.foreign_field, queryParams).then(function(json) {
            return setSelectableValues(json.plain().values);
        });
    }

    /**
     * Sets the save button active
     */
    function setSaveActive() {
        props.state.isSaveButtonActive = true;
        _emitRenderSaveEvent();
    }

    /**
     * Getter for isSaveButtonActive data property
     * @returns {boolean}
     */
    function getIsSaveButtonActive() {
        return props.state.isSaveButtonActive
    }

    /**
     * Will set the save button inactive
     * @param reason Tooltip message to inform the user
     */
    function setSaveInactive(reason) {
        props.state.isSaveButtonActive = false;
        _emitRenderSaveEvent(reason);
    }

    /**
     * Emit RENDER_SAVE event
     * @param [message]
     * @private
     */
    function _emitRenderSaveEvent(message) {
        var event = {
            active: props.state.isSaveButtonActive,
            message: message
        };
        PubSub.emit($DetailsModalFactoryEvents.RENDER_SAVE, event);
    }

    /**
     * @param metadata
     * @returns {any[]}
     */
    function getDetailColumns(metadata) {
        var columns = _.filter(metadata, function(column) {
            return !column.is_hidden_in_details
                && !column.is_primary_key
                && !column.is_metric;
        });

        return _.orderBy(columns, ['is_read_only', 'order'], ['desc', 'asc']);
    }

    /**
     *
     * @param index
     * @param table
     * @param response
     */
    function _openRowAction(index, table, response) {
        props.state.isLoading = true;
        $timeout(function() {
            if (table.row(index).data()) {
                return response.getRow(table, index);
            } else {

                return index > response.currentIndex ?
                    openNext(table, response) :
                    openPrev(table, response);
            }
        });
    }

    // Open first row of next page
    function openNext(table, response) {
        table.one( 'draw.dt', function () {
            response.getRow(table, 0);
        });

        table.page('next').draw('page');
    }

    // Open last row of previous page
    function openPrev(table, response) {
        table.one( 'draw.dt', function () {
            response.getRow(table, table.page.len() - 1);
        });

        table.page('previous').draw('page');
    }


    /**
     * Open details view for a row on $table
     * @param index
     * @param response
     */
    function openRow(index, $table, response) {
        if (_modelHasChanged()) {
            // SWAL For changes
            var swalOptions = getSwalOptions({
                confirmFn: function() {
                    // Save, and open next row
                    props.state.isLoading = true;
                    saveDetails(function() {
                        _openRowAction(index, $table, response);
                    });
                }
            });

            UIFactory.confirm(swalOptions);
        } else {
            _openRowAction(index, $table, response);
        }
    }

    /**
     * Subscribes to isActive state changes
     * @param cb callback
     */
    function onIsActive(cb) {
        PubSub.on($DetailsModalFactoryEvents.IS_ACTIVE, cb);
    }
    /**
     * Unsubscribes to isActive state changes
     * @param cb callback
     */
    function offIsActive(cb) {
        PubSub.off($DetailsModalFactoryEvents.IS_ACTIVE, cb);
    }

    function $getElement() {
        return angular.element('#' + props.data.modalId);
    }

    function _setModalSize(size) {
        props.data.modalSize = size;
    }

    function _setOtherRows(getRowFunc) {
        _getRow = getRowFunc;
    }

    function _setHideRowButtons(value) {
        props.data.hideRowButtons = value;
    }

    /**
     * Sets the table that will be updated in the datagrid after the resource is saved
     * @private
     * @param row
     */
    function _setDataTable(row) {
        // It is possible we are viewing the details of another entity without being in its data table
        // (ex: view reporting profile from user datatable)
        if (_.isNull(row)) {
            return false;
        }
        var $row = angular.element(row);
        var rowIndex = $row.index();
        var table = $row.closest('table').DataTable();
        var currentPage = table.page.info();
        props.data.dataTable = {
            currentIndex: rowIndex,
            table: table,
            prevRow: table.row(rowIndex - 1) || null,
            currentRow: table.row(rowIndex),
            nextRow: table.row(rowIndex + 1) || null,
            currentPage: currentPage,
            hasNextPage: currentPage ? currentPage.page < currentPage.pages - 1 : null,
            hasPreviousPage: currentPage ? currentPage.page > 0 : null
        };
    }

    /**
     * @private
     */
    function _refreshDataTable() {
        DataGridRender.refreshDataTable(props.data.dataTable);
    }

    /**
     * @private
     */
    function _updateDataTableRow() {
        var dataTable = props.data.dataTable;
        if (dataTable) {
            DataGridRender.updateDataTableRow(
                props.data.model,
                dataTable.table,
                dataTable.currentRow,
                props.data.primaryKeyField
            );
        }
    }

    /**
     *
     * @param json
     * @param callBack
     * @private
     */
    function _showSuccess(json, callBack) {
        if (callBack) {
            callBack();
        } else {
            UIFactory.hideModal(props.data.modalId);
        }
        UIFactory.notify.showSuccess(props.data.display.title + ' successfully ' + (props.state.isNew ? 'added' : 'updated'));
    }

    /**
     * @private
     */
    function _focusOnFirstFormInput() {
        $timeout(function() {
            angular.element('#' + props.data.modalId)
                .find('form')
                .find('input[type=text],textarea,select')
                .filter(':visible:first')
                .focus();
        }, 0, false);
    }

    /**
     * Resets state and data to default
     * @private
     */
    function _reset() {
        $timeout(function() {
            props = angular.copy(defaultProps);
        });
    }

    /**
     * @private
     */
    function _setDataSourceType(type) {
        props.data.dataSourceType = type;
    }

    /**
     * Sets the resource that will be used to save the details
     * @private
     * @param resource
     */
    function _setResource(resource) {
        props.data.resource = resource;
    }

    /**
     * Sets the promise to fetch both the model and metadata
     * @private
     * @param promises
     */
    function _setPromises(promises, resource) {
        props.data.promises = [
            resource.getList({metadata: true}),
            DetailsModalEnumResource.getGroups(props.data.dataSourceType)
        ].concat(promises);
    }

    /**
     * @private
     * @param display
     */
    function _setDisplay(display) {
        props.data.display.icon = display.icon;
        props.data.display.title = display.title;
        props.data.display.loadingMessage = display.loadingMessage;
        props.data.display.hideTitleIcon = display.hideTitleIcon;
    }

    /**
     * @private
     */
    function _setIsActive() {
        if (!props.data.isActive) {
            props.data.isActive = true;
            PubSub.emit($DetailsModalFactoryEvents.IS_ACTIVE, props.data.isActive);
        } else {
            PubSub.emit($DetailsModalFactoryEvents.UPDATE_DATA, props.data.isActive);
        }
    }

    function _setOriginalData(model) {
        _originalModel = _.extend({}, model);
    }

    /**
     * @private
     */
    function _setIsInactive() {
        props.data.isActive = false;
        PubSub.emit($DetailsModalFactoryEvents.IS_ACTIVE, props.data.isActive);
    }


    /**
     * Checks if changes have been made to original model
     * @returns {boolean}
     * @private
     */
    function _modelHasChanged() {
        var isEqual = false;
        _.forEach(props.data.model, function(prop, key) {
            if (prop !== _originalModel[key]) {
                return isEqual = true;
            }
        });
        return isEqual;
    }
}

/**
 * @ngInject
 */
function DetailsModalFactoryDelegate(DataSourceType) {
    /**
     * @param dataSourceType
     * @returns {*}
     */
    var getComponent = function(dataSourceType) {
        switch (dataSourceType) {

            case DataSourceType.END_CUSTOMER:
                return '<end-customer-details></end-customer-details>';

            case DataSourceType.TAG:
                return '<tag-details></tag-details>';

            case DataSourceType.TAG_VALUE:
                return '<tag-value-details></tag-value-details>';

            case DataSourceType.SMART_CAMPAIGN:
                return '<smart-campaign-details></smart-campaign-details>';

            case DataSourceType.GOALS:
                return '<goals-details></goals-details>';

            case DataSourceType.PRODUCT:
                return '<manage-products-details></manage-products-details>';
        }
    };

    let getGroupAfterComponent = function (dataSourceType, group) {
        switch (dataSourceType + group) {
            case DataSourceType.LEADS + 2:
                return '<leads-details></leads-details>';

            default:
                return '';
        }
    };

    return {
        getComponent: getComponent,
        getGroupAfterComponent: getGroupAfterComponent
    }
}

/**
 * @ngInject
 */
function DetailsModalEnumResource(
    Restangular
) {
    let columns = Restangular.all('columns');
    let groups = columns.all('detailsgroups');

    /**
     * @param dataSourceType
     * @returns {*}
     */
    let getGroups = function(dataSourceType) {
        return groups.get(dataSourceType);
    };

    return {
        groups: groups,
        getGroups: getGroups
    };
}