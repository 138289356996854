import { ChartWidgetMetadata } from '@/modules/ta/widget/models/metadata/ChartWidgetMetadata';
import { DataGridDrawOption } from '@/modules/ta/widget/models/drawoptions/DataGridDrawOption';

export class DataGridWidgetMetadata extends ChartWidgetMetadata {
  constructor(model = {}) {
    super(model);

    this.draw_options = new DataGridDrawOption(model.draw_options);
  }
}
