/**
 * PHTML variables sent from the server
 */
export class ExportServerConfig {
  /**
   * @type {boolean}
   */
  isHtml;

  /**
   * @type {boolean}
   */
  styleForPrint;

  /**
   * @type {string}
   */
  orientation;

  /**
   * @type {boolean}
   */
  showLayoutTitle;

  /**
   * @type {boolean}
   */
  useReportOptions;

  /**
   * @type {{}}
   */
  dates;

  /**
   * @type {{}}
   */
  relativeDateRanges;

  /**
   * @type {WidgetType[]}
   */
  widgetTypes;

  /**
   * @type {{}}
   */
  reportVars;

  /**
   * @type {ExportType|string}
   */
  exportType;

  /**
   * @type {{}}
   */
  services;

  /**
   * @type {{}}
   */
  brandMappings;

  /**
   * @type {string}
   */
  hostname;

  /**
   * @type {User|{}}
   */
  user;

  /**
   * @type {{}}
   */
  themeBaseColors;

  /**
   * @type {boolean}
   */
  isBuilder;

  /**
   * @type {boolean}
   */
  isThumbnail;

  /**
   * @type
   */
  isExportingSingleWidget;

  /**
   * @type {?}
   */
  filter;

  constructor(model = {}) {
    this.isHtml = model.isHtml;
    this.styleForPrint = model.styleForPrint;
    this.orientation = model.orientation;
    this.showLayoutTitle = model.showLayoutTitle;
    this.useReportOptions = model.useReportOptions;
    this.dates = model.dates;
    this.relativeDateRanges = model.relativeDateRanges;
    this.widgetTypes = model.widgetTypes;
    this.reportVars = model.reportVars;
    this.exportType = model.exportType;
    this.services = model.services;
    this.brandMappings = model.brandMappings;
    this.hostname = model.hostname;
    this.user = model.user;
    this.themeBaseColors = model.themeBaseColors;
    this.isBuilder = model.isBuilder;
    this.isThumbnail = model.isThumbnail;
    this.isExportingSingleWidget = model.isExportingSingleWidget;
    this.filter = model.filter;
  }
}
