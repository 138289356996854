'use strict';
import angular from 'angular';
import './scripts/billing.ctrls';
import './scripts/billing.directives';
import './scripts/billing.services';

angular.module('billing', [
    'billing.ctrls',
    'billing.directives',
    'billing.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('billing', {
                parent:'layout',
                url: '/billing',
                controller: 'BillingController',
                templateUrl: AppConfig.MAGE_URL + 'billing/manage_billing_info',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('billing')
                }
            })
            .state('billing.address', {
                parent:'layout',
                url: '/billing/address',
                controller: 'BillingAddressController',
                templateUrl: AppConfig.MAGE_URL + 'billing/manage_billing_address'
            })
            .state('billing.creditcard', {
                parent:'layout',
                url: '/billing/creditcard',
                controller: 'BillingCreditCardController',
                templateUrl: AppConfig.MAGE_URL + 'billing/manage_credit_card'
            })
    }]);