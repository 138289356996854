'use strict';
import angular from 'angular';
import _ from 'lodash';

import serviceClientMappingAdminHtmlUrl from './serviceclientmapping.admin.html';
import serviceClientMappingLogPageHtmlUrl from './serviceclientmapping.log.page.html';
import serviceClientMappingLogResultsPageHtmlUrl from './serviceclientmapping.log.results.page.html';
import serviceClientMappingModalHtmlUrl from './serviceclientmapping.modal.html';

angular.module('serviceclientmapping.components', [])

    .component('serviceClientMappingAdmin', {
        templateUrl: serviceClientMappingAdminHtmlUrl,
        bindings: {
            currentModule: '<',
            state: '<',
            design: '<',
            showDetailsModal: '<'
        },
        controllerAs: 'vm',
        controller: ServiceClientMappingAdmin
    })
    .component('serviceClientMappingLog', {
        templateUrl: serviceClientMappingLogPageHtmlUrl,
        bindings: {
            currentModule: '<',
            state: '<',
            design: '<',
            showDetailsModal: '<'
        },
        controllerAs: 'vm',
        controller: ServiceClientMappingLog
    })
    .component('serviceClientMappingLogResults', {
        templateUrl: serviceClientMappingLogResultsPageHtmlUrl,
        bindings: {
            currentModule: '<',
            state: '<',
            design: '<',
            vars: '<',
            showDetailsModal: '<'
        },
        controllerAs: 'vm',
        controller: ServiceClientMappingLogResults
    })
    .component('addServiceClientMappingModal', {
        templateUrl: serviceClientMappingModalHtmlUrl,
        controllerAs: 'vm',
        controller: AddServiceClientMappingModal
    });

/**
 * @ngInject
 */
function ServiceClientMappingAdmin(
    PubSub,
    $timeout,
    $ServiceClientMappingEvents,
    ServiceClientMappingFactory,
    ServiceClientMappingModelFactory,
    ServiceClientMappingUIFactory,
    AppFactory,
    LoadingState,
    DesignFactory,
    UIFactory,
    gettextCatalog
) {
    var vm = this;

    vm.initAddServiceClientMapping = initAddServiceClientMapping;
    vm.applyClientMappings = applyClientMappings;
    vm.downloadCsv = downloadCsv;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    /**
     * Initialize add/edit service client mapping modal
     */
    function initAddServiceClientMapping() {
        vm.state.showAddServiceClientModal = true;
        PubSub.emit($ServiceClientMappingEvents.IS_READY, false);
        ServiceClientMappingUIFactory.initModal();
    }

    /**
     * Run mapping endpoint to apply mappings
     */
    function applyClientMappings() {
        ServiceClientMappingFactory.applyMapping().then(function(data) {
            UIFactory.notify.showSuccess(gettextCatalog.getString("Assignments ran successfully"))
        }, function() {
            UIFactory.notify.showWarning(gettextCatalog.getString("There was a problem applying assignments"));
        });
    }

    function downloadCsv() {
        var currPage = DesignFactory.getCurrentPage();
        ServiceClientMappingFactory.downloadMappingHistory(currPage.entity);
    }

    function $onInit() {
        vm.state = ServiceClientMappingModelFactory.getDefaultState();
        vm.brandMappings = AppFactory.getBrandMappings();
        vm.pageName = gettextCatalog.getString('Data Source {{client}} Assignments', {client: vm.brandMappings.client});
        vm.historyLink = '#/serviceclientmappinglog';
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     *
     * @param loadingState
     * @private
     */
    function _setLoadingState(loadingState) {
        $timeout(function() {
            vm.state.loadingState = loadingState;
        });
    }

    /**
     *
     * @private
     */
    function _registerEvents() {
        PubSub.on($ServiceClientMappingEvents.LOADING_STATE, _setLoadingState);
    }

    /**
     *
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($ServiceClientMappingEvents.LOADING_STATE, _setLoadingState);
    }
}

/**
 * @ngInject
 */
function ServiceClientMappingLog(
    $timeout,
    AppFactory,
    PubSub,
    $ServiceClientMappingEvents,
    ServiceClientMappingModelFactory,
    ServiceClientMappingFactory,
    DesignFactory,
    gettextCatalog
) {
    var vm = this;

    vm.serviceClientMappingLink = '#serviceclientmapping';
    vm.downloadCsv = downloadCsv;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    /**
     * Download datagrid data to Csv
     */
    function downloadCsv(params) {
        params = _.isNil(params) ? {} : params;
        var currPage = DesignFactory.getCurrentPage();
        ServiceClientMappingFactory.downloadMappingHistory(currPage.entity, params);
    }

    function $onInit() {
        vm.brandMappings = AppFactory.getBrandMappings();
        vm.state = ServiceClientMappingModelFactory.getDefaultState();
        vm.pageName = gettextCatalog.getString('Data Source {{client}} History', {client: vm.brandMappings.client});
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     *
     * @param loadingState
     * @private
     */
    function _setLoadingState(loadingState) {
        $timeout(function() {
            vm.state.loadingState = loadingState;
        });
    }

    /**
     *
     * @private
     */
    function _registerEvents() {
        PubSub.on($ServiceClientMappingEvents.LOADING_STATE, _setLoadingState);
    }

    /**
     *
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($ServiceClientMappingEvents.LOADING_STATE, _setLoadingState);
    }
}

/**
 * @ngInject
 */
function ServiceClientMappingLogResults(
    $timeout,
    AppFactory,
    PubSub,
    $ServiceClientMappingEvents,
    ServiceClientMappingDataFactory,
    ServiceClientMappingModelFactory,
    gettextCatalog
) {
    var vm = this;

    vm.serviceClientMappingLink = '#serviceclientmapping';
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        vm.brandMappings = AppFactory.getBrandMappings();
        vm.state = ServiceClientMappingModelFactory.getDefaultState();
        vm.pageName = gettextCatalog.getString('Data Source {{client}} Results History', {client: vm.brandMappings.client});
        ServiceClientMappingDataFactory.setStateParams(vm.vars);
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     *
     * @param loadingState
     * @private
     */
    function _setLoadingState(loadingState) {
        $timeout(function() {
            vm.state.loadingState = loadingState;
        });
    }

    /**
     *
     * @private
     */
    function _registerEvents() {
        PubSub.on($ServiceClientMappingEvents.LOADING_STATE, _setLoadingState);
    }

    /**
     *
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($ServiceClientMappingEvents.LOADING_STATE, _setLoadingState);
    }
}

/**
 * @ngInject
 */
function AddServiceClientMappingModal(
    $ServiceClientMappingEvents,
    PubSub,
    AppFactory,
    LayoutFactory,
    ClientFactory,
    UIFactory,
    AppModelFactory,
    NamingConventionFactory,
    ServiceClientMappingModelFactory,
    ServiceClientMappingFactory,
    gettextCatalog
) {
    var vm = this;

    vm.setDataSource = setDataSource;
    vm.setServiceMatch = setServiceMatch;
    vm.setClientField = setClientField;
    vm.setNamingConvention = setNamingConvention;
    vm.setTruncate = setTruncate;
    vm.setMatchType = setMatchType;
    vm.addMapping = addMapping;
    vm.isNamingConventionDisabled = isNamingConventionDisabled;
    vm.isTruncateDisabled = isTruncateDisabled;
    vm.reset = reset;
    vm.close = closeModal;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    /**
     *
     * @param dataSource
     */
    function setDataSource(dataSource) {
        PubSub.emit($ServiceClientMappingEvents.MAPPING_SELECT_READY, false);
        _setServiceClientSelect(dataSource);
    }

    /**
     * Callback on matchTypeSelect select2 change
     */
    function setServiceMatch(el, event) {
        vm.model.service_field = event && event.val !== '' ? event.val : null;
    }

    /**
     * Callback on clientFieldSelect select2 change
     */
    function setClientField(el, event) {
        vm.model.client_field = event && event.val !== '' ? event.val : null;
    }

    /**
     *
     * @param el
     * @param event
     */
    function setNamingConvention(el, event) {
        vm.model.naming_convention_id = event && event.val !== '' ? event.val : null;
    }

    /**
     * Callback on truncateOptions select2 change
     */
    function setTruncate(el, event) {
        vm.model.truncate = event ? event.val : null;
    }

    /**
     * Callback on matchTypeSelect select2 change
     */
    function setMatchType(el, event) {
        vm.model.match_type = event && event.val !== '' ? event.val : null;
    }

    /**
     * Updates title and tags and creates a copy of the widget, and adds it to the widget library
     */
    function addMapping() {
        vm.model.service_id = vm.dataSource.id;
        ServiceClientMappingFactory.saveMapping(vm.model).then(function () {
            // Refresh the datagrid
            LayoutFactory.$rebuildAllWidgets();
            vm.close();
            UIFactory.notify.showSuccess(gettextCatalog.getString('Mapping successfully saved'));
        });
    }

    /**
     * Returns whether naming convention is not selected, or truncate is selected
     * Both truncate and naming convention can not be selected
     * Used to determine if naming convention options are selectable
     *
     * @returns {boolean}
     */
    function isNamingConventionDisabled() {
        return !_.isEmpty(vm.model.truncate) && _.isEmpty(vm.model.naming_convention_id);
    }

    /**
     * Returns wether or not naming convention is selected/empty
     * Used to determine if truncate options are selectable
     *
     * @returns {boolean}
     */
    function isTruncateDisabled() {
        return !_.isEmpty(vm.model.naming_convention_id);
    }

    function reset() {
        vm.model = {};
        vm.modalState = ServiceClientMappingModelFactory.getDefaultState();
    }

    /**
     * Close modal, and reset modal state
     */
    function closeModal() {
        reset();
        UIFactory.hideModal('service-client-mapping-modal')
    }

    /**
     * Initialize widgetPublishModal
     */
    function $onInit() {
        /**
         * Brand Mappings object
         */
        vm.brandMappings = AppFactory.getBrandMappings();

        /**
         *
         * @type {string}
         */
        vm.pageName = gettextCatalog.getString('Data Source {{client}} Assignments', {client: vm.brandMappings.client});

        /**
         *
         * @type {*|{}|DefaultState}
         */
        vm.modalState = ServiceClientMappingModelFactory.getDefaultState();

        /**
         *
         * @type {{}}
         */
        vm.dataSource = {};

        /**
         *
         * @type {SelectOptions|*}
         */
        vm.clientFieldSelectOptions = {};

        /**
         *
         * @type {SelectOptions|*}
         */
        vm.namingConventionSelectOptions = {};

        /**
         *
         * @type {SelectOptions|*}
         */
        vm.matchTypeSelectOptions = {};

        /**
         *
         * @type {SelectOptions|*}
         */
        vm.truncateOptions = {};

        /**
         *
         * @type {SelectOptions|*}
         */
        vm.serviceMatchTypeSelectOptions = {};

        /**
         *
         * @type {*|{}|DefaultState}
         */
        vm.modalState = ServiceClientMappingModelFactory.getDefaultState();
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * Initialize select2 options, and other modal properties
     * @param serviceClientMapping
     * @private
     */
    function _init(serviceClientMapping) {
        vm.model = serviceClientMapping;
        _.assign(vm.dataSource, AppFactory.getService(vm.model.service_id));

        vm.clientFieldSelectOptions = AppModelFactory.getSelectOptions({
            width: '100%',
            placeholder: gettextCatalog.getString('Select {{client}} Record Match Field', {client: vm.brandMappings.client}),
            allowClear: false,
            getDataCall: function() {
                return ClientFactory.getColumns();
            },
            formatData: function(data) {
                var self = this;
                var values = _.reduce(data, function (acc, obj) {
                    if (obj.label && _.indexOf(obj.extra_parameters, 'service_mapping') !== -1) {
                        acc.push({id: obj.field, text: obj.label});
                    }
                    return acc;
                }, []);

                self.selectedValues = _.find(values, {id: vm.model.client_field});

                self.values = values;
            }
        });


        vm.namingConventionSelectOptions = AppModelFactory.getSelectOptions({
            width: '100%',
            placeholder: gettextCatalog.getString('Select Naming Convention'),
            getDataCall: function() {
                return  NamingConventionFactory.getData({});
            },
            formatData: function(data) {
                var self = this;

                self.values = _.map(data, function (namingConvention) {
                    return {id: namingConvention.id, text: namingConvention.name};
                });

                if (_.isEmpty(self.values)) {
                    self.placeholder = gettextCatalog.getString('No Naming Conventions To Select')
                }

                self.selectedValues = _.find(self.values, {id: vm.model.naming_convention_id}) || {};
            }
        });

        var matchTypeSelectValues = [
            {id: 'exact', text: 'exact'},
            {id: 'disregard', text: 'disregard'}
        ];

        vm.matchTypeSelectOptions = AppModelFactory.getSelectOptions({
            width: '100%',
            placeholder: gettextCatalog.getString('Select Match Type...'),
            allowClear: false,
            values: matchTypeSelectValues,
            selectedValues: _.find(matchTypeSelectValues, {id: vm.model.match_type})
        });


        var truncateValues = [
            {id: 'none', text: gettextCatalog.getString('None')},
            {id: 'service', text: gettextCatalog.getString('Data Source')},
            {id: 'client', text: vm.brandMappings.client}
        ];

        vm.truncateOptions = AppModelFactory.getSelectOptions({
            width: '100%',
            placeholder: gettextCatalog.getString('Select Truncate Options...'),
            allowClear: true,
            values: truncateValues,
            selectedValues: _.find(truncateValues, {id: vm.model.truncate})
        });

        vm.dataSourcesSelectOptions = {
          excludeServiceChild: true
        };

        vm.modalState.isSaving = false;
        vm.modalState.isReady = true;
    }

    function _setServiceClientSelect(dataSource) {
        vm.serviceMatchTypeSelectOptions = AppModelFactory.getSelectOptions({
            width: '100%',
            getDataCall: function() {
                PubSub.emit($ServiceClientMappingEvents.MAPPING_SELECT_READY, false);
                return ServiceClientMappingFactory.getMappings(dataSource.id);
            },
            formatData: function(data) {
                var self = this;
                var mappings = _.map(data, function(mapping) {
                    return {id: mapping.field, text: mapping.label};
                });

                self.selectedValues = _.find(mappings, {id: vm.model.service_field});
                PubSub.emit($ServiceClientMappingEvents.MAPPING_SELECT_READY, true);
                self.values = mappings;
            },
            selectedValues: [],
            placeholder: gettextCatalog.getString('Select Match Type...'),
            allowClear: false
        });
    }

    /**
     *
     * @param isAdding
     * @private
     */
    function _setAdding(isAdding) {
        vm.modalState.isAdding = isAdding
    }

    /**
     *
     * @param isReady
     * @private
     */
    function _setIsReady(isReady) {
        vm.modalState.isReady = isReady
    }

    /**
     *
     * @param mappingSelectReady
     * @private
     */
    function _setMappingSelectReady(mappingSelectReady) {
        vm.modalState.mappingSelectReady = mappingSelectReady
    }

    /**
     *
     * @private
     */
    function _registerEvents() {
        PubSub.on($ServiceClientMappingEvents.IS_ADDING, _setAdding);
        PubSub.on($ServiceClientMappingEvents.IS_READY, _setIsReady);
        PubSub.on($ServiceClientMappingEvents.MAPPING_SELECT_READY, _setMappingSelectReady);
        PubSub.on($ServiceClientMappingEvents.INIT_MODAL, _init);
    }

    function _unregisterEvents() {
        PubSub.off($ServiceClientMappingEvents.IS_ADDING, _setAdding);
        PubSub.off($ServiceClientMappingEvents.IS_READY, _setIsReady);
        PubSub.off($ServiceClientMappingEvents.MAPPING_SELECT_READY, _setMappingSelectReady);
        PubSub.off($ServiceClientMappingEvents.INIT_MODAL, _init);
    }
}
