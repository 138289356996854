import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export class ApiSummaryColumn extends BaseModel {
  /**
   * @var {string}
   */
  field;

  /**
   * @var {string}
   */
  format;

  /**
   * @var {string}
   */
  label;

  /**
   * @var {number}
   */
  order;

  /**
   * @var {number}
   */
  summary_order;

  /**
   * @var {string}
   */
  postprocess_type;

  /**
   * @var {number}
   */
  precision;

  /**
   * @var {boolean}
   */
  is_sortable;

  /**
   * @var {boolean}
   */
  is_foreign_field;

  /**
   * @var {boolean}
   */
  is_foreign_key;

  /**
   * @var {Object}
   */
  foreign_mapping;

  /**
   * @var {string[]}
   */
  custom_sort;

  /**
   * @var {[string, string]}
   */
  default_sort;

  /**
   * @var {boolean}
   */
  is_filterable;

  /**
   * @var {boolean}
   */
  is_hidden;

  /**
   * @var {boolean}
   */
  is_pseudo;

  /**
   * @var {boolean}
   */
  is_metric;

  /**
   * @var {boolean}
   */
  is_primary_key;

  /**
   * @var {boolean}
   */
  is_primary_name_field;

  /**
   * @var {boolean}
   */
  is_custom_cell_render;

  /**
   * @var {boolean}
   */
  is_taggable;

  /**
   * @var {boolean}
   */
  is_groupable;

  /**
   * @var {string}
   */
  tooltip;

  /**
   * @var {string}
   */
  header_class;

  /**
   * @var {string}
   */
  cell_class;

  /**
   * @var {string[]}
   */
  values;

  /**
   * @var {string[]}
   */
  scope;

  /**
   * Width of the column
   *
   * @var {number}
   */
  width;

  /**
   * is column centered
   *
   * @var {Boolean}
   */
  centered;

  /**
   * @var {Boolean}
   */
  is_read_only;

  /**
   * @var {string}
   */
  filter_input_type;

  /**
   * @var {boolean}
   */
  is_required_in_datatable;

  /**
   * @var {boolean}
   */
  sticky;

  /**
   * @var {boolean}
   */
  is_lookup;

  constructor(model = {}) {
    super();

    this.field = model.field;
    this.label = model.label;
    this.order = model.order;
    this.summary_order = model.summary_order;
    this.custom_sort = model.custom_sort;
    this.precision = model.precision;
    this.is_sortable = model.is_sortable;
    this.tooltip = model.tooltip;
    this.postprocess_type = model.postprocess_type;
    this.values = model.values;
    this.foreign_mapping = model.foreign_mapping;
    this.is_foreign_key = model.is_foreign_key;
    this.is_foreign_field = model.is_foreign_field;
    this.format = model.format;
    this.default_sort = model.default_sort;
    this.is_filterable = model.is_filterable;
    this.is_hidden = model.is_hidden;
    this.is_pseudo = model.is_pseudo;
    this.is_metric = model.is_metric;
    this.is_primary_key = model.is_primary_key;
    this.is_primary_name_field = model.is_primary_name_field;
    this.is_custom_cell_render = model.is_custom_cell_render;
    this.is_taggable = model.is_taggable;
    this.is_groupable = model.is_groupable;
    this.scope = model.scope;
    this.width = model.width;
    this.centered = model.centered;
    this.header_class = model.header_class;
    this.cell_class = model.cell_class;
    this.is_read_only = model.is_read_only;
    this.filter_input_type = model.filter_input_type;
    this.is_required_in_datatable = model.is_required_in_datatable;
    this.sticky = model.sticky;
    this.is_lookup = model.is_lookup;
  }

  /**
   * Default data for column when actually data from backend is null or undefined
   * @returns {0 || ''}
   */
  defaultData() {
    return this.format === ColumnFormat.FORMAT_INTEGER ? 0 : '';
  }

  /**
   * @returns {boolean}
   */
  isFormatBoolean() {
    return this.format === ColumnFormat.FORMAT_BOOLEAN;
  }

  /**
   * @returns {boolean}
   */
  isFormatEnum() {
    return this.format === ColumnFormat.FORMAT_ENUM;
  }

  /**
   * @returns {boolean}
   */
  isFormatInteger() {
    return (
      this.format === ColumnFormat.FORMAT_INTEGER ||
      this.format === ColumnFormat.FORMAT_DECIMAL ||
      this.format === ColumnFormat.FORMAT_PERCENT ||
      this.format === ColumnFormat.FORMAT_CURRENCY ||
      this.format === ColumnFormat.FORMAT_TIME
    );
  }

  isFormatJson() {
    return this.format === ColumnFormat.FORMAT_STRING && this.values instanceof Object;
  }
}

export default ApiSummaryColumn;
