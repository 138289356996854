'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('design.page.services', [])

    .factory('PageFactory', PageFactory)
    .factory('PageUpdateThumbnailService', PageUpdateThumbnailService);

/**
 * @ngInject
 */
function PageFactory(
    $timeout,
    PubSub,
    ScopeFactory,
    DesignFactory,
    $LayoutEvents,
    PageResource
) {
    var $editPageScope;
    var $editPageReportOptionsScope;

    var getDefaultState = function() {
        return {
            canDisplayWidgets: true
        };
    };

    var shared = {
        state: getDefaultState(),
        pageLayouts: []
    };

    return {
        shared: shared,
        updatePageLayouts: updatePageLayouts,
        toggleWidgetDisplay: toggleWidgetDisplay,
        resetWidgetChartPalettes: resetWidgetChartPalettes,
        resetState: resetState,
        canDelete: canDelete,
        $registerScope: $registerScope,
        $registerReportOptionsScope: $registerReportOptionsScope,
        $newPage: $newPage,
        $editPage: $editPage,
        $editPageReportOptions: $editPageReportOptions,
        setEnableSummary: setEnableSummary,
    };

    function updatePageLayouts() {
        shared.pageLayouts = _.sortBy(DesignFactory.getCurrentPage().layouts, 'display_order');
    }

    /**
     * Allows to give a tiny buffer when reshowing widgets in a layout to delay isotope placing and jankiness
     * @private
     */
    function toggleWidgetDisplay() {
        shared.state.canDisplayWidgets = false;
        PubSub.once($LayoutEvents.CHANGED, function() {
            shared.state.canDisplayWidgets = true;
        });
    }

    /**
     * @param pageId
     * @param value
     * @returns {*}
     */
    function resetWidgetChartPalettes(pageId) {
        return PageResource.resetWidgetChartPalettes(pageId);
    }

    function resetState() {
        shared.state = getDefaultState();
    }

    /**
     * Able to delete a page
     * @param page
     * @returns {boolean|*}
     */
    function canDelete(page) {
        return !page.is_predefined && !page.is_dynamic;
    }

    /**
     * Local implementation of $registerScope
     * @param scope
     */
    function $registerScope(scope) {
        $editPageScope = ScopeFactory.$registerScope(scope, 'edit-page-modal');
    }

    /**
     * Local implementation of $registerScope
     * @param scope
     */
    function $registerReportOptionsScope(scope) {
        $editPageReportOptionsScope = ScopeFactory.$registerScope(scope, 'edit-page-report-options-modal');
    }

    /**
     * Returns design-page angular element
     */
    function $newPage() {
        $('#edit-page-modal').modal('show');
        $editPageScope.initNew();
    }

    /**
     * Returns design-page angular element
     */
    function $editPage() {
        $('#edit-page-modal').modal('show');
        $editPageScope.initEdit();
    }

    /**
     * Returns design-page angular element
     */
    function $editPageReportOptions() {
        $('#edit-page-report-options-modal').modal('show');
        $editPageReportOptionsScope.initEdit();
    }

    /**
     * @param pageId
     * @param value
     * @returns {*}
     */
    async function setEnableSummary(pageId, value) {
        const promise = await PageResource.setEnableSummary(pageId, { executive_summary_enabled: value });
        const currentPage = DesignFactory.getCurrentPage();
        if (currentPage.id === pageId) {
            currentPage.executive_summary_enabled = value;
        }
        return promise;
    }
}

function PageUpdateThumbnailService(
    PageLibraryResourceFactory,
    DesignFactory,
    PubSub,
    $PageGenerateThumbnailEvents
) {
    const queuedPages = new Set();
    _init();

    /**
     * Dequeue the list of pages that need thumbnail update
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function triggerUpdateThumbnails(page_id) {
        if ([...queuedPages].contains(page_id)) {
            queuedPages.delete(page_id);
            return _updateThumbnails(page_id);
        }
    }

    /**
     * Generate all library page thumbnails
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function refreshLibraryThumbnails() {
        return _updateThumbnails();
    }

    /**
     * @param data
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function _enqueueThumbnailUpdate(data) {
        const layout = DesignFactory.getLayout(data.layoutId);
        // Thumbnails are just the first layout of the dashboard. So we don't need to update the thumbnail
        // if the change is not related to first layout
        if (!_.find(data.page.layouts, layoutItem => layoutItem.display_order < layout.display_order)) {
            queuedPages.add(layout.page_id);
        }
    }

    /**
     * Add the thumbnail update
     * @param pageId
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function _updateThumbnails(pageId = null) {
        return PageLibraryResourceFactory.updateThumbnails(pageId);
    }

    /**
     * Add thumbnail for section
     * @param sectionId
     * @returns {*}
     */
    function triggerSectionThumbnail(sectionId) {
        return PageLibraryResourceFactory.updateSectionThumbnails(sectionId);
    }

    /**
     * Private initializer
     * @private
     */
    function _init() {
        PubSub.on($PageGenerateThumbnailEvents.ENQUEUE, _enqueueThumbnailUpdate);
    }

    return {
        triggerUpdateThumbnails: triggerUpdateThumbnails,
        refreshLibraryThumbnails: refreshLibraryThumbnails,
        triggerSectionThumbnail: triggerSectionThumbnail,
    };
}
