import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import { ServiceDataWidgetDataResource } from '@/modules/ta/widget/resources/ServiceDataWidgetDataResource';
import { CategoryDataWidgetDataResource } from '@/modules/ta/widget/resources/CategoryDataWidgetDataResource';
import { WidgetDataWidgetDataResource } from '@/modules/ta/widget/resources/WidgetDataWidgetDataResource';
import { UserDataWidgetDataResource } from '@/modules/ta/widget/resources/UserDataWidgetDataResource';
import { MediaWidgetData } from '@/modules/ta/widget/models/MediaWidgetData';
import { ServiceDataLiveWidgetDataResource } from '@/modules/ta/widget/resources/ServiceDataLiveWidgetDataResource';

export class WidgetDataResourceFactory {
  /**
   * @param widget
   * @returns {WidgetDataResource}
   */
  static build(widget) {
    if (widget.isMediaWidget()) {
      return new WidgetDataWidgetDataResource(MediaWidgetData);
    }

    if (widget.isAdminWidget()) {
      return new UserDataWidgetDataResource();
    }

    switch (widget.metadata.data_source.type) {
      case DataSourceType.SERVICE_DATA:
        if (widget.has_live_integration) {
          return new ServiceDataLiveWidgetDataResource();
        }
        return new ServiceDataWidgetDataResource();
      case DataSourceType.CATEGORY_DATA:
        return new CategoryDataWidgetDataResource();
      case DataSourceType.USER:
        return new UserDataWidgetDataResource();
      default:
        Logger.log(i18n.$t('Unsupported data source type'), Logger.LEVEL_ERROR);
    }
  }
}
