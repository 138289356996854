import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class RelativeDateRange extends BaseModel {
  /**
   * @var {string}
   */
  key;

  /**
   * @var {string}
   */
  label;

  /**
   * @var {string} - ISO Format
   */
  start;

  /**
   * @var {string} - ISO Format
   */
  end;

  /**
   * @var {string} - ISO Format
   */
  comparison_start;

  /**
   * @var {string} - ISO Format
   */
  comparison_end;

  constructor(model = {}) {
    super();

    this.key = model.key;
    this.label = model.label;
    this.start = model.start;
    this.end = model.end;
    this.comparison_start = model.comparison_start;
    this.comparison_end = model.comparison_end;
  }
}
