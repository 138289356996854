import { toNumber, find } from 'lodash';
import { deepClone } from '@/modules/core/helper';
import {
  WidgetState,
  WidgetType,
  TimeGrouping,
  WidgetSparklineOptions,
} from '@/modules/ta/widget/widget.constants';
import { WidgetDataService } from '@/modules/ta/widget/services/data/WidgetDataService';
import { DataSourceType } from '@/modules/core/app/constants/datasource.constants';
import { WidgetDataConfig } from '@/modules/ta/widget/models/WidgetDataConfig';
import WidgetContextFactory from '@/modules/ta/widget/models/context/WidgetContextFactory';
import { DateRange } from '@/modules/core/daterange/models/DateRange';
import { getters } from '@/modules/core/app/helpers/store';
import ServiceDataResource from '@/modules/ta/service/resources/ServiceDataResource';
import WidgetBuilderFormService from '@/modules/ta/widget/services/builder/WidgetBuilderFormService';

export class BigNumberDataService extends WidgetDataService {
  /**
   * @override
   *
   * @param widget
   * @param config
   */
  applySpecificQueryParams(widget, config) {
    widget.metadata.data_columns.grouped = [];
    config.params.aggregate = true;
  }

  /**
   * @param widget
   * @param {WidgetDataConfig} config
   * @param data
   * @returns {boolean|string}
   */
  evaluateLoadingState(widget, config, data) {
    const loadingState = super.evaluateLoadingState(widget, config, data);

    if (
      loadingState === WidgetState.STATE_DONE &&
      !this.useSampleData(widget) &&
      widget.data_type !== DataSourceType.CATEGORY_DATA
    ) {
      const [{ current_period, prior_period }] = data;
      if (
        !find(
          widget.metadata.data_columns.selected.map((column) => column.field),
          (field) =>
            !!(
              toNumber(current_period[field]) !== 0 ||
              (prior_period && toNumber(prior_period[field]) !== 0)
            )
        )
      ) {
        return WidgetState.STATE_ALL_EMPTY_DATA;
      }
    }

    return loadingState;
  }

  /**
   * @param widget
   * @returns *
   */
  async getSparkLineData(widget) {
    const params = {};
    const context = WidgetContextFactory.getContext();
    const dateRange = new DateRange();
    const relativeRanges = getters.daterange.getRelativeDateRanges();

    /** Assign date range values */
    dateRange.start_date = relativeRanges.last3months.start;
    dateRange.end_date = relativeRanges.last3months.end;

    if (widget.metadata.sparkline_option === WidgetSparklineOptions.LAST_6_MONTHS) {
      dateRange.start_date = relativeRanges.last6months.start;
      dateRange.end_date = relativeRanges.last6months.end;
    }

    if (widget.metadata.sparkline_option === WidgetSparklineOptions.YEAR_TO_DATE) {
      dateRange.start_date = relativeRanges.yeartodate.start;
      dateRange.end_date = relativeRanges.yeartodate.end;
    }

    const columnParams = {
      ignore_reporting_profile: true,
      sort: 'label',
      widget_request: true,
    };
    const { data_source_id, data_view_id } = widget;
    const serviceMetadata = await ServiceDataResource.getColumns(
      data_source_id,
      data_view_id,
      columnParams
    );
    const timegroups = await WidgetBuilderFormService.retrieveTimeGroupings(widget);

    /** Assign parameters */
    const primaryDateColumn = serviceMetadata.find(
      (column) => column.is_primary_date_field === true
    );
    params.groupby = primaryDateColumn.field;
    params.sort = primaryDateColumn.field;

    if (timegroups.find((timegroup) => timegroup.key === TimeGrouping.GROUPING_WEEKLY)) {
      params.timegrouping = TimeGrouping.GROUPING_WEEKLY;
    } else if (timegroups.find((timegroup) => timegroup.key === TimeGrouping.GROUPING_MONTHLY)) {
      params.timegrouping = TimeGrouping.GROUPING_MONTHLY;
    } else {
      params.timegrouping = timegroups[0].key;
    }

    const config = new WidgetDataConfig({
      dateRange,
      relativeRanges,
      params,
      context,
    });

    const tempWidget = deepClone(widget);
    tempWidget.type = WidgetType.TYPE_LINECHART;
    tempWidget.metadata.time_grouping = TimeGrouping.GROUPING_WEEKLY;
    tempWidget.cached_data = false;
    tempWidget.is_sparkline = true;

    const thisSparkLineData = await this.getData(tempWidget, config);
    return thisSparkLineData;
  }
}
