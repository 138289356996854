import angular from 'angular';

angular.module('core.tapcolors.models', [])
    .factory('TapColorsModelFactory', TapColorsModelFactory);

/**
 * @ngInject
 */
function TapColorsModelFactory() {

    return {
        getTapColorsModel: getTapColorsModel
    };

    /**
     * Getter for TapColors object
     * @param element Element tag
     * @param options Set of options to override to manipulate TapColors.js
     * @returns {*}
     */
    function getTapColorsModel(element, options) {
        return new TapColors(element, options);
    }
}