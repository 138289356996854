'use strict';
import angular from "angular";

import exportBuilderDashboardHtmlUrl from "./exportbuilder.dashboard.html";
import { $WidgetBuilderEvents } from "coreModules/design/widget/builder/widget.builder.constants";
import { DrawOption } from "coreModules/design/layout/drawoptionpanel/drawoptionpanel.constants";

angular.module('exportbuilder.dashboard.components', [])
    .component('exportBuilderDashboard', {
        bindings: {},
        templateUrl: exportBuilderDashboardHtmlUrl,
        controller: ExportBuilderDashboardController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderDashboardController(
    $timeout,
    PubSub,
    $ExportBuilderTemplateDownloadModalEvents,
    $ExportBuilderPageAssignmentModalEvents,
    $WidgetLibraryEvents,
    $WidgetCreateEvents,
    ReportElementTypes,
    ExportBuilderFacadeUIService,
    ExportBuilderFilterService,
    ExportBuilderElementActionService,
    ExportBuilderDashboardService,
    ExportBuilderDashboardItemService,
    PageSpecifications,
    PageAspectRatio,
    PageFormatConstants,
    KeyboardAdapterService,
    $KeyboardServiceEvents,
    UIFactory,
    $ExportBuilderIconModalEvents,
    ExportBuilderDashboardUIModelFactory,
    $ExportBuilderDashboardEvents,
    $ExportBuilderUndoRedoEvents,
    WidgetFactory,
    WidgetBuilderService,
    WidgetBuilderUIService,
    WidgetUtilService,
    $FullScreenEvents,
    $DateRangeEvents,
    $WidgetFilterEvents,
    UIColor,
    gettextCatalog,
    ExportBuilderDashboardSyncService
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onDestroy = $onDestroy;

    vm.pageBackgroundStyle = pageBackgroundStyle;
    vm.pageBackgroundOverlayStyle = pageBackgroundOverlayStyle;
    vm.pageContentStyle = pageContentStyle;
    vm.pageStyle = pageStyle;
    vm.isLandscape = isLandscape;
    vm.onPageRightClick = onPageRightClick;
    vm.isAllPagesHidden = isAllPagesHidden;
    vm.onModifyAssignment = onModifyAssignment;


    function onModifyAssignment() {
       PubSub.emit($ExportBuilderPageAssignmentModalEvents.OPEN);
    }

    function isAllPagesHidden() {
        return vm.builder.report.pages.every((page) => page.metadata.isHidden)
    }

    function getAspectRatio() {
        switch (vm.builder.report.format) {
            case PageFormatConstants.WIDESCREEN:
                return PageAspectRatio.WIDESCREEN;
            default:
                return PageAspectRatio.STANDARD;
        }
    }

    function pageBackgroundStyle(page) {
        let aspectRatio = getAspectRatio();
        var pageWidth = PageSpecifications.WIDTH;
        var pageHeight = PageSpecifications.WIDTH / aspectRatio;

        var styles = {
            'height': pageHeight + 'px',
            'width': pageWidth + 'px'
        };

        if (page.metadata.design_options && page.metadata.design_options.background_color) {
            styles['background-color'] = UIColor.hexToRGBA(page.metadata.design_options.background_color, page.metadata.design_options.opacity);
        }

        if (!isLandscape()) {
            styles['transform'] = 'rotate(90deg)';
        }

        return styles;
    }

    function pageBackgroundOverlayStyle() {
        let aspectRatio = getAspectRatio();
        var pageWidth = PageSpecifications.WIDTH;
        var pageHeight = PageSpecifications.WIDTH / aspectRatio;

        var styles = {
            'height': pageHeight + 'px',
            'width': pageWidth + 'px'
        };

        if (!isLandscape()) {
            styles['transform'] = 'rotate(90deg)';
        }

        return styles;
    }

    function pageContentStyle() {
        let aspectRatio = getAspectRatio();
        var pageWidth = PageSpecifications.WIDTH;
        var pageHeight = PageSpecifications.WIDTH / aspectRatio;

        var styles = {

        };

        if (isLandscape()) {
            styles['height'] = pageHeight + 'px';
            styles['width'] = pageWidth + 'px';
        } else {
            styles['height'] = pageWidth + 'px';
            styles['width'] = pageHeight + 'px';
        }

        if (ExportBuilderFacadeUIService.getIsOverflow()) {
            styles['overflow'] = 'hidden';
        }

        return styles;
    }

    function pageStyle() {
        let aspectRatio = getAspectRatio();
        var pageWidth = PageSpecifications.WIDTH;
        var pageHeight = PageSpecifications.WIDTH / aspectRatio;

        var styles = {

        };

        if (isLandscape()) {
            styles['height'] = pageHeight + 'px';
            styles['width'] = pageWidth + 'px';
        } else {
            styles['height'] = pageWidth + 'px';
            styles['width'] = pageHeight + 'px';
        }

        return styles;
    }

    function $onInit() {
        // available for templating
        vm.builder = ExportBuilderDashboardService.getBuilder();

        _registerEvents();
        _registerPermanentEvents();

        if (vm.builder.report.can_be_edited) {
            _registerEventsForEditing();
            _registerSyncService();
        }
    
        const $appLoader = document.getElementById('report-studio-loader');
        if ($appLoader) {
            $appLoader.remove();
        }
    }

    function $postLink() {
        // needed for delay in order to properly calculate scale and snap items on page on first load
        $timeout(function () {
            ExportBuilderDashboardService.calculateZoom();
            ExportBuilderDashboardService.goToPageIndex(ExportBuilderDashboardService.getFirstDesiredPageIndex());
        }, 150);
    }

    function $onDestroy() {
        _unregisterEvents();
        _unregisterPermanentEvents();
        _unregisterSyncService();
        _unregisterEventsForEditing();
    }

    function isLandscape() {
        return ExportBuilderDashboardService.getIsLandscape();
    }

    function onPageRightClick(position) {
        PubSub.emit($ExportBuilderDashboardEvents.RIGHT_CLICK_MENU, {
            position: position,
            type: 'page',
            model: vm.page
        });
    }

    function onDeletePressed() {
        if (ExportBuilderDashboardService.getBuilder().currentItem && !angular.element(':focus').length) {
            ExportBuilderFacadeUIService.removeItem(ExportBuilderDashboardService.getBuilder().currentItem);
        }
    }

    function onWidgetAdded(widget) {
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.WIDGET, {}, widget);
        _fetchNewFilter(widget);
    }

    function onWidgetCreation(data) {
        const { widget, isAdding } = data;

        if (isAdding) {
            ExportBuilderElementActionService.addNewItem(ReportElementTypes.WIDGET, {}, widget);
            _fetchNewFilter(widget);
        } else {
            ExportBuilderElementActionService.setEditWidget(widget);
        }
    }

    function _fetchNewFilter(widget, filter) {
        ExportBuilderFacadeUIService.fetchNewGlobalFilter(widget, filter);
    }

    function _onNewPageAdded() {
        vm.builder = ExportBuilderDashboardService.getBuilder();
    }

    function _onSave(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.setIsSavingEntireReport(true);
        ExportBuilderDashboardService.saveEntireReport()
            .then(function () {
            })
            .finally(function () {
                ExportBuilderFacadeUIService.setIsSavingEntireReport(false);
            })
    }

    function _onCopy(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.copyToClipboard(ExportBuilderDashboardService.getBuilder().currentItem);
    }

    function _onCut(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.cutToClipboard(ExportBuilderDashboardService.getBuilder().currentItem);
    }

    function _onTab(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.focusOnNextItem();
    }

    function _onPaste(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.paste();
    }

    function _onUndo() {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.performUndoRedo();
    }

    function _selectAll() {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.selectAll();
    }

    function _onZoomCalculated() {
        vm.builder = ExportBuilderDashboardService.getBuilder();
    }

    function _onDidSetWidgetDateRange(widget) {
        ExportBuilderElementActionService.setWidgetDateRange(widget);
    }

    function _onWidgetFilterSaved(widget) {
        ExportBuilderElementActionService.setWidgetFilters(widget);
        UIFactory.notify.showSuccess(gettextCatalog.getString('Filter set successfully saved'))
    }

    /**
     * @param {ReportElementModel} element
     * @private
     */
    function _onElementFocus(element) {
        _shouldCloseWidgetBuilder(element);
    }
    
    function _onWidgetElementSpecialEditNeedsUpdate(element) {
        ExportBuilderElementActionService.saveEditWidgetFromSpecialEdit(element.widget);
    }

    function _shouldCloseWidgetBuilder(element) {
        if (!WidgetBuilderUIService.isActive()) {
            return;
        }

        if (_isLeavingWidgetBuildMode(element)
            || !_isWidgetInBuildMode(element)) {
            WidgetBuilderUIService.hidePanel();
            WidgetBuilderService.saveWidget();
        }

        function _isLeavingWidgetBuildMode(element) {
            return !element;
        }

        function _isWidgetInBuildMode(element) {
            return element
                && element.isTypeWidget()
                && WidgetBuilderService.getWidgetModel().id === element.widget.id;
        }
    }

    function _upArrow(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.moveCurrentItemUp();
    }

    function _downArrow(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.moveCurrentItemDown();
    }

    function _leftArrow(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.moveCurrentItemLeft();
    }

    function _rightArrow(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.moveCurrentItemRight();
    }

    function _goToNextPage() {
        if (angular.element(':focus').length) {return}
        ExportBuilderDashboardService.goToNextPage();
    }

    function _goToPreviousPage() {
        if (angular.element(':focus').length) {return}
        ExportBuilderDashboardService.goToPreviousPage();
    }

    function _onIconEvent() {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.showNewIconModal();
    }

    function _onImageEvent(event) {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.showImageDialogFromKeyboard(event);
    }

    function _onShowElementLinesEvent() {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.toggleOutlineActive();
    }

    function _onFitToPageEvent() {
        if (angular.element(':focus').length) {return}
        ExportBuilderDashboardService.toggleFitToPage();
    }

    function _onPresentationEvent() {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.togglePresentationMode();
    }

    function _onEscapeEvent() {
        if (ExportBuilderFacadeUIService.getPresentationMode()) {
            ExportBuilderFacadeUIService.togglePresentationMode();
        } else {
            ExportBuilderDashboardService.clearSelection();
        }
    }

    function _onTextEvent() {
        if (angular.element(':focus').length) {return}
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT);
    }

    function _onExportEvent() {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.showExportOptionsModal();
    }

    function _onRotateEvent() {
        if (angular.element(':focus').length) {return}
        ExportBuilderDashboardService.toggleLandscape();
        ExportBuilderDashboardService.focusOnItem(null);
    }

    function _onGridEvent() {
        if (angular.element(':focus').length) {return}
        ExportBuilderFacadeUIService.toggleGridMode()
    }

    function _onForwardSlashEvent() {
        ExportBuilderFacadeUIService.showKeyboardShortCutsModal()
    }

    function _onIconAdded(newIcon) {
        var params = {
            icon: newIcon
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.ICON, params);
    }

    function _onExitFullScreen() {
        if (ExportBuilderFacadeUIService.getPresentationMode()) {
            ExportBuilderDashboardService.removePagesFromReport();
            ExportBuilderDashboardService.updatePageIndex();
            ExportBuilderFacadeUIService.setPresentationMode(false);
            _onPresentationMode();
        }
        $timeout(function () {
            ExportBuilderDashboardService.calculateZoom();
        }, 500);
    }

    function _onEnterFullScreen() {
        ExportBuilderDashboardService.addPagesToReport();
        ExportBuilderDashboardService.updatePageIndex();
        ExportBuilderDashboardService.calculateZoom();
    }

    function _onPresentationArrowOverride() {
        // explicitly do nothing here, needed to override event
    }

    function _registerEventsForPresentationMode() {
        _unregisterEvents();
        KeyboardAdapterService.on($KeyboardServiceEvents.P, _onPresentationEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.ESCAPE, _onEscapeEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.LEFT_ARROW, _goToPreviousPage);
        KeyboardAdapterService.on($KeyboardServiceEvents.RIGHT_ARROW, _goToNextPage);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_LEFT_ARROW_OVERRIDE, _onPresentationArrowOverride);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_RIGHT_ARROW_OVERRIDE, _onPresentationArrowOverride);
    }

    function _unregisterEventsForPresentationMode() {
        KeyboardAdapterService.off($KeyboardServiceEvents.P, _onPresentationEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.ESCAPE, _onEscapeEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.LEFT_ARROW, _goToPreviousPage);
        KeyboardAdapterService.off($KeyboardServiceEvents.RIGHT_ARROW, _goToNextPage);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_LEFT_ARROW_OVERRIDE, _onPresentationArrowOverride);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_RIGHT_ARROW_OVERRIDE, _onPresentationArrowOverride);
        _registerEvents();
    }

    function _onPresentationMode() {
        if (ExportBuilderFacadeUIService.getPresentationMode()) {
            _registerEventsForPresentationMode();
        } else {
            _unregisterEventsForPresentationMode()
        }
    }

    function _onUndoEvent() {

    }

    function _onRedoEvent() {

    }

    /**
     *
     * @param params
     * @private
     */
    function _updateGlobalFilters(params) {
        const widget = params.currentWidget;
        const removedFilters = params.removedFilters;
        if(removedFilters && removedFilters.length) {
            removedFilters.forEach(filter => {
                ExportBuilderFilterService.removeFilter(widget, filter, true);
            });
        }
        widget.metadata.dynamic.filters.forEach(filter => {
            if (removedFilters && (removedFilters.length === 0 || removedFilters.length > 0)) {
                filter.expose_as_report_filter && _fetchNewFilter(widget, filter);
            } else {
                if (filter.expose_as_report_filter !== undefined) {
                    _fetchNewFilter(widget, filter);
                }
            }
        });

        $timeout(() => {
            PubSub.emit($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_APPLY);
        },0);
    }

    /**
     * @param widget
     * @private
     */
    function _updateBuilderWidget(widget) {
        widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA] = widget.metadata.draw_options[DrawOption.SHOW_SAMPLE_DATA];
        ExportBuilderElementActionService.setEditWidgetFromBuildMode(widget);
    }

    /**
     * @private
     */
    function _saveBuilderWidget(widget) {
        ExportBuilderElementActionService.saveEditWidgetFromBuildMode(widget);
        _fetchNewFilter(widget);
    }

    /**
     * @param widget
     * @private
     */
    function _addBuilderWidget(widget) {
        if (!WidgetBuilderUIService.isActive()) {
            return;
        }

        widget.metadata.draw_options[DrawOption.SHOW_BACKGROUND].value = false;
        widget.metadata.draw_options[DrawOption.SHOW_BORDERS].value = false;

        widget.id = null;
        WidgetFactory.save(angular.copy(widget)).then(function(newWidget) {
            WidgetBuilderService.storeOriginalWidgetModel(newWidget);
            WidgetBuilderService.setWidgetModel(newWidget);
            WidgetBuilderService.setIsEditing(true);
            ExportBuilderElementActionService.addNewItem(ReportElementTypes.WIDGET, {}, newWidget, null);
        });
    }

    /**
     * @param widgetId
     * @private
     */
    function _removeBuilderWidget(widgetId) {
        ExportBuilderElementActionService.setDelete();
    }

    function _registerSyncService() {
        ExportBuilderDashboardSyncService.start();
    }

    function _unregisterSyncService() {
        ExportBuilderDashboardSyncService.stop();
    }

    /**
     * @param data
     * @param data.elementId    Editing element's ID
     * @param data.newIcon      Selected Icon to change
     * @private
     */
    function _onIconSelected(data) {
        var element = ExportBuilderDashboardService.getItemFromId(data.elementId);
        ExportBuilderDashboardItemService.setIcon(element, data.newIcon);
    }

    function _registerPermanentEvents() {
        PubSub.on($ExportBuilderDashboardEvents.ON_PAGE_CHANGED, _onNewPageAdded);
        PubSub.on($ExportBuilderDashboardEvents.ZOOM_CALCULATED, _onZoomCalculated);
        PubSub.on($ExportBuilderDashboardEvents.ON_PRESENTATION_MODE, _onPresentationMode);
        PubSub.on($FullScreenEvents.EXIT_FULL_SCREEN, _onExitFullScreen);
        PubSub.on($FullScreenEvents.ENTER_FULL_SCREEN, _onEnterFullScreen);
        PubSub.on($WidgetFilterEvents.UPDATE_GLOBAL_FILTERS, _updateGlobalFilters);
    }

    function _unregisterPermanentEvents() {
        PubSub.off($ExportBuilderDashboardEvents.ON_PAGE_CHANGED, _onNewPageAdded);
        PubSub.off($ExportBuilderDashboardEvents.ZOOM_CALCULATED, _onZoomCalculated);
        PubSub.off($ExportBuilderDashboardEvents.ON_PRESENTATION_MODE, _onPresentationMode);
        PubSub.off($FullScreenEvents.EXIT_FULL_SCREEN, _onExitFullScreen);
        PubSub.off($FullScreenEvents.ENTER_FULL_SCREEN, _onEnterFullScreen);
        PubSub.off($WidgetFilterEvents.UPDATE_GLOBAL_FILTERS, _updateGlobalFilters);
    }
    
    function _registerEventsForEditing() {
        KeyboardAdapterService.on($KeyboardServiceEvents.DELETE_OVERRIDE, onDeletePressed);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_S_OVERRIDE, _onSave);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_C, _onCopy);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_V, _onPaste);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_Z_OVERRIDE, _onUndo);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_A_OVERRIDE, _selectAll);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_X, _onCut);
        KeyboardAdapterService.on($KeyboardServiceEvents.I, _onIconEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.T, _onTextEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.R, _onRotateEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.G, _onGridEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.M, _onImageEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.O, _onShowElementLinesEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.L, _onFitToPageEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.TAB_OVERRIDE, _onTab);
        KeyboardAdapterService.on($KeyboardServiceEvents.UP_ARROW, _upArrow);
        KeyboardAdapterService.on($KeyboardServiceEvents.DOWN_ARROW, _downArrow);
        KeyboardAdapterService.on($KeyboardServiceEvents.LEFT_ARROW, _leftArrow);
        KeyboardAdapterService.on($KeyboardServiceEvents.RIGHT_ARROW, _rightArrow);

        PubSub.on($ExportBuilderUndoRedoEvents.UNDO, _onUndoEvent);
        PubSub.on($ExportBuilderUndoRedoEvents.REDO, _onRedoEvent);
        PubSub.on($ExportBuilderIconModalEvents.SET_ICON, _onIconSelected);
        PubSub.on($WidgetCreateEvents.CREATED_FROM_LIBRARY, onWidgetAdded);
        PubSub.on($WidgetCreateEvents.CREATED, onWidgetCreation);
        PubSub.on($ExportBuilderIconModalEvents.SET_NEW_ICON, _onIconAdded);
        PubSub.on($ExportBuilderDashboardEvents.ON_PAGE_ADDED, _onNewPageAdded);
        PubSub.on($DateRangeEvents.DID_SET_WIDGET_DATE_RANGE, _onDidSetWidgetDateRange);
        PubSub.on($WidgetFilterEvents.WIDGET_FILTER_SAVE, _onWidgetFilterSaved);
        PubSub.on($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, _onElementFocus);
        PubSub.on($ExportBuilderDashboardEvents.WIDGET_ELEMENT_SPECIAL_EDIT_NEEDS_UPDATE, _onWidgetElementSpecialEditNeedsUpdate);

        PubSub.on($WidgetBuilderEvents.UPDATE_WIDGET, _updateBuilderWidget);
        PubSub.on($WidgetBuilderEvents.DID_SAVE_WIDGET, _saveBuilderWidget);
        PubSub.on($WidgetBuilderEvents.ADD_WIDGET, _addBuilderWidget);
        PubSub.on($WidgetBuilderEvents.REMOVE_WIDGET, _removeBuilderWidget);
    }

    function _unregisterEventsForEditing() {
        KeyboardAdapterService.off($KeyboardServiceEvents.DELETE_OVERRIDE, onDeletePressed);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_S_OVERRIDE, _onSave);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_C, _onCopy);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_V, _onPaste);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_Z_OVERRIDE, _onUndo);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_A_OVERRIDE, _selectAll);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_X, _onCut);
        KeyboardAdapterService.off($KeyboardServiceEvents.I, _onImageEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.T, _onTextEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.R, _onRotateEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.G, _onGridEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.M, _onImageEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.O, _onShowElementLinesEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.L, _onFitToPageEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.TAB_OVERRIDE, _onTab);
        KeyboardAdapterService.off($KeyboardServiceEvents.UP_ARROW, _upArrow);
        KeyboardAdapterService.off($KeyboardServiceEvents.DOWN_ARROW, _downArrow);
        KeyboardAdapterService.off($KeyboardServiceEvents.LEFT_ARROW, _leftArrow);
        KeyboardAdapterService.off($KeyboardServiceEvents.RIGHT_ARROW, _rightArrow);

        PubSub.off($ExportBuilderUndoRedoEvents.UNDO, _onUndoEvent);
        PubSub.off($ExportBuilderUndoRedoEvents.REDO, _onRedoEvent);
        PubSub.off($ExportBuilderIconModalEvents.SET_ICON, _onIconSelected);
        PubSub.off($WidgetCreateEvents.CREATED_FROM_LIBRARY, onWidgetAdded);
        PubSub.off($ExportBuilderIconModalEvents.SET_NEW_ICON, _onIconAdded);
        PubSub.off($WidgetCreateEvents.CREATED, onWidgetCreation);
        PubSub.off($ExportBuilderDashboardEvents.ON_PAGE_ADDED, _onNewPageAdded);
        PubSub.off($DateRangeEvents.DID_SET_WIDGET_DATE_RANGE, _onDidSetWidgetDateRange);
        PubSub.off($WidgetFilterEvents.WIDGET_FILTER_SAVE, _onWidgetFilterSaved);
        PubSub.off($ExportBuilderDashboardEvents.ON_ITEM_FOCUS, _onElementFocus);
        PubSub.off($ExportBuilderDashboardEvents.WIDGET_ELEMENT_SPECIAL_EDIT_NEEDS_UPDATE, _onWidgetElementSpecialEditNeedsUpdate);
        
        PubSub.off($WidgetBuilderEvents.UPDATE_WIDGET, _updateBuilderWidget);
        PubSub.off($WidgetBuilderEvents.DID_SAVE_WIDGET, _saveBuilderWidget);
        PubSub.off($WidgetBuilderEvents.ADD_WIDGET, _addBuilderWidget);
        PubSub.off($WidgetBuilderEvents.REMOVE_WIDGET, _removeBuilderWidget);
    }

    function _registerEvents() {
        KeyboardAdapterService.on($KeyboardServiceEvents.E, _onExportEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.P, _onPresentationEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.ESCAPE, _onEscapeEvent);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_LEFT_ARROW_OVERRIDE, _goToPreviousPage);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_RIGHT_ARROW_OVERRIDE, _goToNextPage);
        KeyboardAdapterService.on($KeyboardServiceEvents.CMD_FORWARD_SLASH, _onForwardSlashEvent);
    }

    function _unregisterEvents() {
        KeyboardAdapterService.off($KeyboardServiceEvents.E, _onExportEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.P, _onPresentationEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.ESCAPE, _onEscapeEvent);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_LEFT_ARROW_OVERRIDE, _goToPreviousPage);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_RIGHT_ARROW_OVERRIDE, _goToNextPage);
        KeyboardAdapterService.off($KeyboardServiceEvents.CMD_FORWARD_SLASH, _onForwardSlashEvent);
    }
}
