'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';

angular.module('serviceclientmapping.resources', [])

    .factory('ServiceClientMappingResource', ServiceClientMappingResource)
    .factory('ServiceClientMappingLogResource', ServiceClientMappingLogResource)
    .factory('ServiceClientMappingLogResultResource', ServiceClientMappingLogResultResource)
    .factory('ServiceClientMappingDataGridFactory', ServiceClientMappingDataGridFactory)
    .factory('ServiceClientMappingLogDataGridFactory', ServiceClientMappingLogDataGridFactory)
    .factory('ServiceClientMappingLogResultsDataGridFactory', ServiceClientMappingLogResultsDataGridFactory);

/**
 * @ngInject
 */
function ServiceClientMappingResource(
    Restangular
) {
    var resource = Restangular.all('serviceclientmapping');

    return {
        getData: getData,
        getMappings: getMappings,
        applyMappings: applyMappings,
        test: test,
        log: log,
        save: save
    };

    /**
     *
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        queryParams.all = true;
        return resource.getList(queryParams);
    }

    /**
     *
     * @param serviceId
     * @returns {*}
     */
    function getMappings(serviceId) {
        return resource.one('service').getList(serviceId);
    }

    /**
     * Call the backend to apply all or one mapping
     * @param ruleId
     */
    function applyMappings(ruleId) {
        return resource.one('apply', ruleId).get().then(function (json) {
            return json.plain();
        });
    }

    /**
     * test service client mapping by id
     * @param id
     */
    function test(id) {
        return resource.one('test', id).get().then(function(json) {
            return json.plain();
        });
    }

    /**
     * Get history of all or one service client mapping
     * @param id
     * @param params
     */
    function log(id, params) {
        return _.isNil(id) ? resource.one('log').get(params) : resource.all('log').get(id, params);
    }


    /**
     * Save Service Client Mapping
     * @param payload
     * @returns {*|{method, isArray}|{method}}
     */
    function save(payload) {
        return _.isNil(payload.id) ? resource.post(payload) : resource.all(payload.id).post(payload);
    }
}

/**
 * @ngInject
 */
function ServiceClientMappingLogResource(
    Restangular
) {
    var resource = Restangular.all('serviceclientmapping').all('log');

    return {
        getData: getData
    };

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        queryParams.all = true;
        return resource.getList(queryParams);
    }
}

/**
 * @ngInject
 */
function ServiceClientMappingLogResultResource(
    Restangular,
    ServiceClientMappingDataFactory
) {
    var resource = Restangular.all('serviceclientmapping');
    var log = resource.all('log').all('detail');

    return {
        getData: getData
    };

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        queryParams = _.isNil(queryParams) ? {} : queryParams;

        var currentVars = ServiceClientMappingDataFactory.getStateParams();
        var mappingLogId = currentVars.id;
        var mappingId = currentVars.mapping_id;

        if (mappingId) {
            queryParams.mapping_id = mappingId;
        }
        if (mappingLogId) {
            queryParams.id = mappingLogId;
        }

        queryParams.all = true;
        if (mappingId === 'test') {
            return resource.one('test', mappingLogId).get(queryParams)
        }
        return log.getList(queryParams);
    }
}

/**
 * @ngInject
 */
function ServiceClientMappingDataGridFactory(
    $state,
    ServiceClientMappingFactory,
    ServiceClientMappingUIFactory,
    ServiceClientMappingColumns,
    ServiceClientMappingRouteParams,
    $ServiceClientMappingEvents,
    MessengerModelFactory,
    LoadingState,
    $MessengerEvents,
    AppFactory,
    PubSub,
    DataSourceFactory,
    DataGridRender,
    UIFactory
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.numActions = 7;
        dtOptions.customRenders[ServiceClientMappingColumns.ID] = function(data, type, full) {
            var html = '';

            html += DataGridRender.getActionButton({
                disabled: false,
                icon: 'icomoon-paper-check',
                id: full['id'],
                className: 'test-action',
                tooltip: 'View Test Assignments'
            });

            html += DataGridRender.getActionButton({
                disabled: false,
                icon: 'icon-eye',
                id: full['id'],
                className: 'view-action',
                tooltip: 'View Assignment Results'
            });

            html += DataGridRender.getActionButton({
                disabled: false,
                icon: 'icomoon-download',
                id: full['id'],
                className: 'download-action',
                tooltip: 'Download Assignment History'
            });

            html += DataGridRender.getActionButton({
                disabled: false,
                icon: 'icon-play',
                id: full['id'],
                className: 'execute-action',
                tooltip: 'Execute Assignment'
            });

            html += DataGridRender.getEditButton(full.can_be_edited, 'serviceclientmapping/edit' + data, full['name']);
            html += DataGridRender.getCopyButton(full.can_be_copied, 'serviceclientmapping/copy/' + data, full['name']);
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'serviceclientmapping/' + data, full['name']);

            return html;
        };
        dtOptions.customRenders[ServiceClientMappingColumns.DATA_SOURCE] = function(data) {
            var service = AppFactory.getService(data);
            var icon = DataSourceFactory.getDataSourceIcon(service);
            var html = '<i class="icon ' + icon + ' mr5" style="color:' + service.color + '"></i>';
            if (!_.isNull(service.name)) {
                html += '<span>' + service.name + '</span>';
            }
            return html;
        };
        dtOptions.customRenders[ServiceClientMappingColumns.SERVICE_FIELD] = function(data) {
            return data ? '<span class="mr5">' + data.label + ' </span>' : '';
        };
        dtOptions.customRenders[ServiceClientMappingColumns.CLIENT_FIELD] = function(data) {
            return data ? '<span class="mr5">' + data.label + ' </span>' : '';
        };

        //adding created row
        dtOptions.fnCreatedRow = function(row, data) {
            $(row).find('td.action-cell a.btn').each(function() {
                if ($(this).hasClass('delete-action')) {
                    DataGridRender.bindDeleteRowAction($(this));
                } else if ($(this).hasClass('copy-action')) {
                    DataGridRender.bindCopyRowAction($(this));
                } else if ($(this).hasClass('edit-action')) {
                    $(this).on('click', function() {
                        _initModal(data);
                    });
                } else  if ($(this).hasClass('view-action')) {
                    // Display results
                    $(this).on('click', function() {
                        // Link to view results page
                        $state.go(ServiceClientMappingRouteParams.SERVICE_CLIENT_MAPPING_RESULT, {mapping_id: data.id});
                    });
                } else if ($(this).hasClass('execute-action')) {
                    $(this).on('click', function() {
                        _applyMapping(data.id, data.service_id);
                    });
                } else if ($(this).hasClass('test-action')) {
                    $(this).on('click', function() {
                        _testMapping(data.id, data);
                    });
                } else if ($(this).hasClass('download-action')) {
                    $(this).on('click', function() {
                        _downloadMappingHistory(data.id)
                    });
                }
            });
        };

        return dtOptions;
    }

    /**
     *
     * @param data
     * @private
     */
    function _initModal(data) {
        ServiceClientMappingUIFactory.initModal(data);
    }

    /**
     * Run individual mapping
     * @param id
     * @param service_id
     * @private
     */
    function _applyMapping(id, service_id) {
        ServiceClientMappingFactory.applyMapping(id).then(function(data) {
            amplify.removeFromCache('account_list_' + service_id);
            data.length
                ? UIFactory.notify.showSuccess("You have successfully assigned " + data.length + " new item(s)")
                : UIFactory.notify.showWarning("No new items have been assigned");
        }, function() {
            UIFactory.notify.showWarning("There was a problem with your assignment");
        });
    }

    /**
     * Download a specific mapping history to CSV
     * @param mapping_id {Number} mapping id
     * @private
     */
    function _downloadMappingHistory(mapping_id) {
        PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, {
            state: LoadingState.LOADING,
            text: 'Downloading Assignment History',
            icon: 'icomoon-download'
        });

        ServiceClientMappingFactory.downloadMappingHistory(ServiceClientMappingRouteParams.SERVICE_CLIENT_MAPPING_LOG, {mapping_id: mapping_id}).then(function(json) {
            UIFactory.notify.showSuccess("Successfully downloaded");
            PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, LoadingState.DONE);
        }, function() {
            UIFactory.notify.showWarning("Failed to download history");
            PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, LoadingState.DONE);
        });
    }

    /**
     * Test mapping, and display results that would map in a panel
     * @param id
     * @param mapping
     * @private
     */
    function _testMapping(id, mapping) {
        $state.go(ServiceClientMappingRouteParams.SERVICE_CLIENT_MAPPING_RESULT, {id: id, mapping_id: 'test'});
    }

}

/**
 * @ngInject
 */
function ServiceClientMappingLogDataGridFactory(
    $state,
    ServiceClientMappingLogColumns,
    ServiceClientMappingFactory,
    $ServiceClientMappingEvents,
    ServiceClientMappingRouteParams,
    UIFactory,
    LoadingState,
    PubSub,
    $MessengerEvents,
    MessengerModelFactory,
    DataGridRender,
    MomentDateFormat
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};

        dtOptions.numActions = 2;

        dtOptions.customRenders[ServiceClientMappingLogColumns.ID] = function(data, type, full) {
            var html = '';

            html += DataGridRender.getActionButton({
                disabled: false,
                icon: 'icon-eye',
                id: full['id'],
                className: 'view-action',
                tooltip: 'View Assignment Log Results'
            });

            html += DataGridRender.getActionButton({
                disabled: false,
                icon: 'icomoon-download',
                id: full['id'],
                className: 'download-action',
                tooltip: 'Download Assignment Log History'
            });

            return html;
        };


        dtOptions.customRenders[ServiceClientMappingLogColumns.START] = function(data) {
            return moment.utc(moment.unix(data)).format(MomentDateFormat.MONTH_DAY_YEAR_TWELVE_HOUR);
        };

        dtOptions.customRenders[ServiceClientMappingLogColumns.END] = function(data) {
            return moment.utc(moment.unix(data)).format(MomentDateFormat.MONTH_DAY_YEAR_TWELVE_HOUR);
        };

        dtOptions.customRenders[ServiceClientMappingLogColumns.RESULT] = function(data) {
            if (data.length) {
                return data.length + " New Assignment" + (data.length > 1 ? "s" : "");
            } else {
                return "No New Assignments";
            }
        };

        dtOptions.fnCreatedRow = function(row, data) {
            $(row).find('td.action-cell a.btn').each(function () {
                if ($(this).hasClass('view-action')) {
                    // Display results
                    $(this).on('click', function() {
                        // Link to view results page
                        $state.go(ServiceClientMappingRouteParams.SERVICE_CLIENT_MAPPING_RESULT, {mapping_id: data.mapping_id, id: data.id});
                    });
                } else if ($(this).hasClass('download-action')) {
                    $(this).on('click', function() {
                        _downloadMappingHistory(data.id)
                    });
                }
            });
        };

        return dtOptions;
    }



    /**
     * Download a specific mapping history to CSV
     * @param id {Number} individual mapping log id
     * @private
     */
    function _downloadMappingHistory(id) {

        PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, {
            state: LoadingState.LOADING,
            text: 'Downloading Assignment',
            icon: 'icomoon-download',
        });

        ServiceClientMappingFactory.downloadMappingHistory(ServiceClientMappingRouteParams.SERVICE_CLIENT_MAPPING_LOG, {id: id}).then(function(json) {
            UIFactory.notify.showSuccess("Successfully downloaded");
            PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, LoadingState.DONE);
        }, function() {
            UIFactory.notify.showWarning("Failed to download history");
            PubSub.emit($ServiceClientMappingEvents.LOADING_STATE, LoadingState.DONE);
        });
    }
}

/**
 * @ngInject
 */
function ServiceClientMappingLogResultsDataGridFactory(
    $state,
    ServiceClientMappingLogColumns,
    ServiceClientMappingFactory,
    $ServiceClientMappingEvents,
    UIFactory,
    LoadingState,
    PubSub,
    $MessengerEvents,
    MessengerModelFactory,
    DataGridRender,
    MomentDateFormat
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return lead specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};

        dtOptions.numActions = 0;

        dtOptions.customRenders[ServiceClientMappingLogColumns.ID] = function(data, type, full) {
            var html = '';

            return html;
        };

        dtOptions.customRenders[ServiceClientMappingLogColumns.START] = function(data) {
            return moment.utc(moment.unix(data)).format(MomentDateFormat.MONTH_DAY_YEAR_TWELVE_HOUR);
        };

        dtOptions.customRenders[ServiceClientMappingLogColumns.END] = function(data) {
            return moment.utc(moment.unix(data)).format(MomentDateFormat.MONTH_DAY_YEAR_TWELVE_HOUR);
        };

        dtOptions.customRenders[ServiceClientMappingLogColumns.RESULT] = function(data) {
            return data;
        };

        return dtOptions;
    }

}