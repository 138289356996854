import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { turnCamelCaseToSlashCase } from './StringHelper';

/**
 * Proxy object to dynamically map first level properties to store dispatches
 * @type {object}
 */
export const dispatches = new Proxy(VueXHelper, {
  get(target, name) {
    return VueXHelper.moduleDispatches(turnCamelCaseToSlashCase(name));
  },
  set() {
    Logger.log(i18n.$t('Cannot set dynamic setters'), Logger.LEVEL_ERROR);
  },
});

/**
 * Proxy object to dynamically map first level properties to store commits
 * @type {object}
 */
export const commits = new Proxy(VueXHelper, {
  get(target, name) {
    return VueXHelper.moduleCommits(turnCamelCaseToSlashCase(name));
  },
  set() {
    Logger.log(i18n.$t('Cannot set dynamic setters'), Logger.LEVEL_ERROR);
  },
});

/**
 * Proxy object to dynamically map first level properties to store getters
 * @type {object}
 */
export const getters = new Proxy(VueXHelper, {
  get(target, name) {
    return VueXHelper.moduleGetters(turnCamelCaseToSlashCase(name));
  },
  set() {
    Logger.log(i18n.$t('Cannot set dynamic setters'), Logger.LEVEL_ERROR);
  },
});

/**
 * Proxy object to dynamically map first level properties to store subscribe mutation events
 * @type {object}
 */
export const subscribeMutation = new Proxy(VueXHelper, {
  get(target, name) {
    const slashCaseName = turnCamelCaseToSlashCase(name);
    const events = {};
    const moduleCommits = VueXHelper.moduleCommits(slashCaseName);
    Object.keys(moduleCommits).forEach((key) => {
      events[key] = function (cb, once) {
        const fnArguments = [`${slashCaseName}/${key}`, cb];
        return once ? _onceMutation(...fnArguments) : _onMutation(...fnArguments);
      };
    });
    return events;
  },
  set() {
    Logger.log(i18n.$t('Cannot set dynamic setters'), Logger.LEVEL_ERROR);
  },
});

/**
 * Proxy object to dynamically map first level properties to store subscribe action events
 * @type {object}
 */
export const subscribeAction = new Proxy(VueXHelper, {
  get(target, name) {
    const slashCaseName = turnCamelCaseToSlashCase(name);
    const events = {};
    const moduleDispatches = VueXHelper.moduleDispatches(slashCaseName);
    Object.keys(moduleDispatches).forEach((key) => {
      events[key] = function (cb, once) {
        const fnArguments = [`${slashCaseName}/${key}`, cb];
        return once ? _onceAction(...fnArguments) : _onAction(...fnArguments);
      };
    });
    return events;
  },
  set() {
    Logger.log(i18n.$t('Cannot set dynamic setters'), Logger.LEVEL_ERROR);
  },
});

/**
 * Subscribe to a specific mutation type
 * @private
 * @param mutationType
 * @param {function} callback
 * @returns {function}
 */
function _onMutation(mutationType, callback) {
  return VueXHelper.getStore().subscribe((mutation, state) => {
    if (mutation.type === mutationType) {
      callback(mutation.payload, state);
    }
  });
}

/**
 * Subscribes only once to a specific mutation type
 * @private
 * @param mutationType
 * @param {function} callback
 * @returns {function}
 */
function _onceMutation(mutationType, callback) {
  const unregister = _onMutation(mutationType, (mutation, state) => {
    callback(mutation.payload, state);
    unregister();
  });
}

/**
 * Subscribe to a specific action type
 * @private
 * @param {string} actionType
 * @param {function} callback
 * @returns {function}
 */
function _onAction(actionType, callback) {
  return VueXHelper.getStore().subscribeAction((action, state) => {
    if (action.type === actionType) {
      callback(action.payload, state);
    }
  });
}

/**
 * Subscribes only once to a specific action type
 * @private
 * @param {string} actionType
 * @param {function} callback
 * @returns {function}
 */
function _onceAction(actionType, callback) {
  const unregister = _onAction(actionType, (mutation, state) => {
    callback(mutation.payload, state);
    unregister();
  });
}

export const useStore = () => VueXHelper.store;
