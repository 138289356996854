import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function usePieSliceHover() {
  /**
   * The slice charts (funnel / pyramid) use slices instead of series
   * so to grey these out we need to target slices instead of the "strokes" in X/Y
   * @param series
   * @param isComparison
   * @param legend
   */
  function addSliceEvents(series, { itemContainers }) {
    series.slices.each((sliceContainer) => {
      sliceContainer.events.on(Constant.POINTER_OVER, () => {
        greyScaleAllBut(sliceContainer, itemContainers);
      });
      sliceContainer.events.on(Constant.POINTER_OUT, () => {
        restoreOpacity(itemContainers);
      });
    });
  }

  /**
   * Target every slice in every series and set the opacity for
   * everything but the selected one
   * @param hoveredSlice
   * @param itemContainers
   */
  function greyScaleAllBut(hoveredSlice, itemContainers) {
    itemContainers.each((dataItem) => {
      const { slice } = dataItem.dataItem.dataContext._settings;
      if (hoveredSlice !== slice) {
        dataItem.set(Constant.OPACITY, 0.3);
      }
    });
  }

  /**
   * Restore the opacity for every slice in every series
   */
  function restoreOpacity(itemContainers) {
    itemContainers.each((dataItem) => {
      dataItem.set(Constant.OPACITY, 1);
    });
  }

  return {
    addSliceEvents,
  };
}
