export const mutations = {
  setWidgetAsRendered(state, { widgetId }) {
    const copy = new Set([...state.unrenderedWidgets.values()]);
    copy.delete(Number(widgetId));
    state.unrenderedWidgets = copy;
  },
  setUnrenderedWidgetIds(state, { widgetIds }) {
    state.unrenderedWidgets = new Set([
      ...state.unrenderedWidgets.values(),
      ...widgetIds.map((widgetId) => Number(widgetId)),
    ]);
  },
};
