'use strict';
import angular from 'angular';

angular.module('legacydashboards', [])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        var dashfilters = {
            templateUrl: AppConfig.MAGE_URL + 'layout/dashfilters',
            controller: 'DashFiltersController'
        };

        $stateProvider
            .state('autoleads', {
                parent:'layout',
                url: '/autoleads',
                views: {
                    '': {
                        templateUrl: AppConfig.MAGE_URL + 'index/leads'
                    },
                    'dashfilters@layout': dashfilters
                },
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('autoleads')
                }
            })
    }]);