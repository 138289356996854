import { NavGroup } from '@/modules/core/nav/nav.constants';

export class NavItem {
  /**
   * @var {string}
   */
  title;

  /**
   * @var {string}
   */
  subtitle;

  /**
   * @var {string}
   */
  icon;

  /**
   * @var {string}
   */
  key;

  /**
   * @var {NavMenuLink}
   */
  link;

  /**
   * @var {int}
   */
  index;

  /**
   * @var {boolean}
   */
  is_group_child;

  /**
   * @var {NavMenuGroup}
   */
  group_key;

  /**
   * @var {string}
   */
  tooltip;

  /**
   * @var {boolean}
   */
  disabled;

  /**
   * @var {boolean}
   */
  is_new;

  /**
   * @var {boolean}
   */
  is_beta;

  /**
   * @var {boolean}
   */
  is_coming_soon;

  /**
   * @var {boolean}
   */
  is_empty_app_nav_item;

  constructor(model = {}) {
    this.title = model.title;
    this.subtitle = model.subtitle;
    this.icon = model.icon;
    this.key = model.key;
    this.link = model.link;
    this.index = model.index;
    this.is_group_child = model.is_group_child;
    this.group_key = model.group_key;
    this.tooltip = model.tooltip;
    this.disabled = model.disabled;
    this.is_new = model.is_new;
    this.is_beta = model.is_beta;
    this.is_coming_soon = model.is_coming_soon;
    this.is_empty_app_nav_item = model.is_empty_app_nav_item;
  }

  /**
   * @returns {boolean|boolean}
   */
  isGroupParent() {
    return this.group_key !== null && !this.is_group_child;
  }

  /**
   * @returns {boolean|boolean}
   */
  isOnboradingItem() {
    return this.group_key === NavGroup.ONBOARDING;
  }
}
