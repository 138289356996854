'use strict';
import angular from 'angular';

export const LibraryTagColumns = {
    ID: 'id',
    TEXT: 'text'
};

export const LibrarySearchGroup = {
    DATA_SOURCES: 'data_sources',
    TAGS: 'tags',
    WIDGET_TYPES: 'widget_types',
    TITLE: 'title'
};

angular.module('core.library.constants', [])
    .constant('LibraryTagColumns', LibraryTagColumns)
    .constant('LibrarySearchGroup', LibrarySearchGroup);