export const PlanText = {
  CURRENT_PLAN: 'CURRENT PLAN',
  SELECT_PLAN: 'SELECT PLAN',
  CONTACT_US: 'CONTACT US',
  UNAVAILABLE: 'UNAVAILABLE',
  CUSTOM: 'Custom',
  CUSTOMIZE_PLAN: 'CUSTOMIZE PLAN',
};

export const Currency = {
  USD: '$',
};

export const DisabledReason = {
  CURRENT_PLAN: 'current_plan',
  LIMITS_CROSSED: 'limits_crossed',
};

export const BillingPeriodUnit = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const Product = {
  ZEST: 'zest',
  SHEETS: 'sheets',
};

export const DisabledReasonTooltipMapper = {
  limits_crossed:
    'Your usage exceeds the limits of this plan. Reduce your usage in order to choose this plan.',
  no_annual_to_monthly: "Yearly customers can't downgrade to monthly. Please contact support.",
  no_annual_downgrade: 'Cannot downgrade. Please contact your customer success executive.',
};

export const SubscriptionStatus = {
  ACTIVE: 'active',
  IN_TRIAL: 'in_trial',
  CANCELLED: 'cancelled',
  PAUSED: 'paused',
  NON_RENEWING: 'non_renewing',
  FUTURE: 'future',
};

export const RedirectUrl = {
  zest: 'https://info.raventools.com/demo',
  sheets: 'mailto:sales@tapclicks.com',
};

export const FreePlanCostText = {
  FREE: 'FREE',
};

export const ReactivationText = {
  CONTACT_US: 'please CONTACT US.',
  MANAGE_PLAN: 'click on MANAGE PLAN to select a new plan.',
  REACTIVATE_PLAN: 'click on REACTIVATE PLAN.',
};

export const CheckoutStep = {
  PLAN_SELECTION: 'Plan Selection',
  CUSTOMIZE: 'Customize',
  CHECKOUT: 'Checkout',
};

export const BillingParameterMap = {
  keyword: { shortValue: 'kw', longValue: 'Keywords' },
  website: { shortValue: 'website', longValue: 'Websites' },
  datasource: { shortValue: 'data source', longValue: 'Data Sources' },
  accounts_per_datasource: {
    shortValue: 'account per datasource',
    longValue: 'Accounts Per Data Source',
  },
  user: { shortValue: 'user', longValue: 'Users' },
};

export const PaymentType = {
  CREDIT_CARD: 'credit_card',
};

export const PurchaseText = {
  zest: 'Purchase as many websites or keywords as you need with our easy add-ons.',
  sheets: 'Purchase Additional Features and Licenses as you need with our easy add-ons.',
};

export const AdditionalText = {
  zest: 'Need additional websites or keywords?',
  sheets: '',
};

export const RequireBillingParameterRedirect = {
  keyword: false,
  website: false,
  datasource: true,
  accounts_per_datasource: true,
  user: true,
};

export const BillingParameterRedirectionUrl = {
  datasource: 'sup/service/manage',
  accounts_per_datasource: 'sup/service/manage',
  user: 'users',
};

export const ProductCodeAndNameMapper = {
  zest: 'Raven',
  sheets: 'Sheets',
};
