import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import BadgeResource from '@/modules/core/badge/resources/BadgeResource';

export class BadgeService extends BaseService {
  /**
   * @var {BadgeResource}
   */
  resource;

  constructor() {
    super();
    this.resource = BadgeResource;
  }

  /**
   * @param key
   * @returns {Promise<*>}
   */
  async upsell(key) {
    return this.resource.upsell(key);
  }

  /**
   * @returns {Promise<AxiosResponse<*>>}
   */
  async getSentRequestsStatuses() {
    return this.resource.getSentRequestsStatuses();
  }
}

export default new BadgeService();
