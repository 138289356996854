export const GuideItemType = {
  TOOLTIP: 'tooltip',
  FLOATING: 'floating',
};

export const GuidePositionType = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  CENTER: 'center',
};

export const GuideEventType = {
  START_GUIDE_BY_NAME: 'start-guide-by-name',
  SHOWING_GUIDE: 'showing-guide',
};
