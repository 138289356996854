'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('exportbuilder.directives')
    .constant('$ExportBuilderItemDirectiveEvents', {
        ON_RESIZE: '$ExportBuilderItemDirectiveEvents:ON_RESIZE',
        ON_SIZE_CHANGE: '$ExportBuilderItemDirectiveEvents:ON_SIZE_CHANGE',
        ON_POSITION_CHANGE: '$ExportBuilderItemDirectiveEvents:ON_POSITION_CHANGE',
        ON_COORDINATE_CHANGE: '$ExportBuilderItemDirectiveEvents:ON_COORDINATE_CHANGE',
        IS_RESIZING: '$ExportBuilderItemDirectiveEvents:IS_RESIZING'
    })
    .directive('exportBuilderItemDirective', exportBuilderItemDirective)
    .directive('exportBuilderItemIconDirective', exportBuilderItemIconDirective)
    .directive('exportBuilderItemImageDirective', exportBuilderItemImageDirective);

//
//  directive export builder items: show the items components of the builder (Images and colored rectangle)
//
/**
 * @ngInject
 */
function exportBuilderItemDirective(
    $timeout,
    PubSub,
    $ExportBuilderItemDirectiveEvents,
    ExportBuilderDashboardService,
    PageSpecifications,
    PageGridConstants,
    ReportElementTypes,
    ExportBuilderHelper,
    $ExportBuilderDashboardEvents,
    $ExportBuilderDashboardModelEvents,
    ExportBuilderFacadeUIService,
    $document
) {
    return {
        restrict: 'A',
        scope: {
            element: '<exportBuilderItemDirective'
        },
        link: function(scope, el) {
            let $page = el.parents('.page-content');
            let _isPressingShift = false;
            let _originalDimensions = null;

            if (ExportBuilderDashboardService.canEditReport()) {
                _registerEvents();
                scope.$on('$destroy', function () {
                    _unregisterEvents();
                });

                onItemFocusChange();
            }

            function _resetItemFocus() {
                let zoom = ExportBuilderDashboardService.getBuilder().zoom / 100;
                let report = ExportBuilderDashboardService.getReport();

                let grid = scope.element.snap_to_grid
                    ? [ report.getPageWidth()/PageGridConstants.Y * zoom, report.getPageHeight()/PageGridConstants.Y * zoom]
                    : null;

                el.draggable({
                    grid: grid
                });

                let resizeGrid = scope.element.snap_to_grid
                    ? [ report.getPageWidth()/PageGridConstants.Y * zoom, report.getPageHeight()/PageGridConstants.Y * zoom]
                    : null;

                el.resizable({
                    grid: resizeGrid
                });
            }

            function _onShiftClicked(event) {
                if (event.keyCode === 16) {
                    _isPressingShift = event.type === 'keydown';
                }
            }

            function onItemFocusChange() {
                let zoom; //will hold the current scale of the builder
                let pageShift; //will hold any change of current page
                let bottomMargin; //will hold the size of the page's bottom margin
                let pageHeight; //will hold the page height
                                // it's faster to read this value from the DOM than calculate it from orientation
                                // and zoom and constants everytime.
                //page current zoom
                zoom = ExportBuilderDashboardService.getPercentZoom();

                let grid = scope.element.snap_to_grid
                    ? [ $page.width()/PageGridConstants.Y * zoom, $page.height()/PageGridConstants.Y * zoom]
                    : null;

                //start move feature
                el.draggable({
                    grid: grid,
                    start: function(event, ui) {
                        PubSub.emit($ExportBuilderItemDirectiveEvents.IS_RESIZING, true);

                        ExportBuilderFacadeUIService.setIsResizing(true);
                        //init the page shift let
                        pageShift = 0;

                        //size of the page's bottom margin
                        bottomMargin = (PageSpecifications.BOTTOM_MARGIN + 5) * zoom;

                        //height of each pages
                        pageHeight = $page.height();

                        _originalDimensions = {
                            left: (ui.position.left / ExportBuilderDashboardService.getPercentZoom()) * 100 / $page.width(),
                            top: (ui.position.top/ExportBuilderDashboardService.getPercentZoom()) * 100 / $page.height()
                        }
                    },
                    //drag will be called every time the user drags the element
                    drag: function(event, ui) {
                        ui.position.top /= ExportBuilderDashboardService.getPercentZoom();
                        ui.position.left /= ExportBuilderDashboardService.getPercentZoom();

                        let top = ui.position.top * 100 / $page.height();
                        let left = ui.position.left * 100 / $page.width();

                        let deltaDimensions = {
                            left: left - _originalDimensions.left,
                            top: top - _originalDimensions.top
                        };

                        _calculateElementNewPositions(deltaDimensions);
                    },
                    stop: function(event, ui) {
                        //take the page shift into account
                        if (pageShift !== 0) {
                            ui.position.top = ui.position.top - (pageShift * ($page.height() + bottomMargin));
                        }

                        // Turn coordinates into percentage to save them
                        // and ensure the page is "portraitable"
                        let top = ui.position.top * 100 / $page.height();
                        let left = ui.position.left * 100 / $page.width();

                        let deltaDimensions = {
                            left: left - _originalDimensions.left,
                            top: top - _originalDimensions.top
                        };

                        if (!isNaN(top) && !isNaN(left)) {
                            //update element
                            if (scope.element.snap_to_grid) {
                                ui.helper.css({
                                    top: top + '%',
                                    left: left + '%'
                                });
                            } else {
                                let pixelYPosition = $page.height() * (top / 100);
                                let pixelXPosition = $page.width() * (left / 100);
                                ui.helper.css({
                                    top: pixelYPosition + 'px',
                                    left: pixelXPosition + 'px'
                                });
                                let originalYPosition = $page.height() * (_originalDimensions.top / 100);
                                let originalXPosition = $page.width() * (_originalDimensions.left / 100);
                                deltaDimensions = {
                                    left: pixelXPosition - originalXPosition,
                                    top: pixelYPosition - originalYPosition,
                                };
                            }

                            PubSub.emit($ExportBuilderItemDirectiveEvents.ON_POSITION_CHANGE+scope.element.id, {
                                top: deltaDimensions.top,
                                left: deltaDimensions.left,
                                is_percent: scope.element.snap_to_grid
                            });
                        }

                        $timeout(() => {
                            ExportBuilderFacadeUIService.setIsResizing(false);
                        }, 0);

                        PubSub.emit($ExportBuilderItemDirectiveEvents.IS_RESIZING, false);
                    }
                });

                let resizeGrid = [ $page.width()/PageGridConstants.Y * zoom, $page.height()/PageGridConstants.Y * zoom];

                //start resize feature2s
                el.resizable({
                    handles: 'all',
                    grid: scope.element.snap_to_grid ? resizeGrid : null,
                    // containment ensures that the elements never overflow the page
                    create: function( event, ui ) {
                    },
                    start: function(event, ui) {
                        ExportBuilderFacadeUIService.setIsResizing(true);
                        PubSub.emit($ExportBuilderItemDirectiveEvents.IS_RESIZING, true);

                        _originalDimensions = {
                            left: (ui.position.left) * 100 / $page.width(),
                            top: (ui.position.top) * 100 / $page.height(),
                            width: (ui.size.width) * 100 / $page.width(),
                            height: (ui.size.height) * 100 / $page.height()
                        }

                    },
                    //resize will be called every time the user resizes the element
                    resize: function(event, ui) {
                        //current zoom
                        let zoom = ExportBuilderDashboardService.getBuilder().zoom / 100;

                        // maintain aspect for images
                        let zoomScale = zoom;
                        let changeWidth = ui.size.width - ui.originalSize.width;
                        let newWidth = ui.originalSize.width + changeWidth / zoomScale;

                        let changeHeight = ui.size.height - ui.originalSize.height;
                        let newHeight = ui.originalSize.height + changeHeight / zoomScale;

                        ui.size.width = newWidth;
                        ui.size.height = newHeight;

                        let position = ui.originalPosition;
                        if (position.left !== ui.position.left) {
                            ui.position.left = position.left - changeWidth / zoomScale;
                        }
                        if (position.top !== ui.position.top) {
                            ui.position.top = position.top - changeHeight / zoomScale;
                        }

                        let aspectRatio = ui.originalSize.height / ui.originalSize.width;
                        let maintainAspectRatio = _isPressingShift && !scope.element.snap_to_grid;
                        if (maintainAspectRatio) {
                            ui.size.height = ui.size.width * aspectRatio;
                        }

                        //minimum size requirement
                        let gridHeight = resizeGrid[0] / zoom;
                        let gridWidth = resizeGrid[1] / zoom;
                        if (scope.element.snap_to_grid) {
                            if (ui.size.height <= gridHeight) {
                                ui.size.height = gridHeight;
                            }
                            if (ui.size.width <= gridWidth) {
                                ui.size.width = gridWidth;
                            }
                        } else {
                            if (ui.size.height <= PageGridConstants.MINIMUM_PIXEL_HEIGHT) {
                                ui.size.height = PageGridConstants.MINIMUM_PIXEL_HEIGHT;
                            }
                            if (ui.size.width <= PageGridConstants.MINIMUM_PIXEL_WIDTH) {
                                ui.size.width = PageGridConstants.MINIMUM_PIXEL_WIDTH;
                            }
                        }

                        PubSub.emit($ExportBuilderItemDirectiveEvents.ON_RESIZE+scope.element.id, {width: ui.size.width, height: ui.size.height, element: scope.element});
                    },

                    stop: function(event, ui) {
                        event.stopPropagation();
                        event.preventDefault();

                        // Turn coordinates into percentage to save them
                        // and ensure the page is "portraitable"
                        let left = ui.position.left * 100 / $page.width();
                        let top = ui.position.top * 100 / $page.height();
                        let height = ui.size.height * 100 / $page.height();
                        let width = ui.size.width * 100 / $page.width();

                        if (!isNaN(height) && !isNaN(width)) {

                            if (!scope.element.snap_to_grid) {
                                let pixelHeight = $page.height() * (height / 100);
                                let pixelWidth = $page.width() * (width / 100);
                                let pixelXPosition = $page.width() * (left / 100);
                                let pixelYPosition = $page.height() * (top / 100);

                                ui.helper.css({
                                    left: pixelXPosition + 'px',
                                    top: pixelYPosition + 'px',
                                    height: pixelHeight + 'px',
                                    width: pixelWidth + 'px'
                                });
                            } else {
                                ui.helper.css({
                                    left: left + '%',
                                    top: top + '%',
                                    height: height + '%',
                                    width: width + '%'
                                });
                            }

                            //send update to controller
                            PubSub.emit($ExportBuilderItemDirectiveEvents.ON_COORDINATE_CHANGE+scope.element.id, {
                                top: top - _originalDimensions.top,
                                left: left - _originalDimensions.left,
                                height: height - _originalDimensions.height,
                                width: width - _originalDimensions.width,
                                is_percent: scope.element.snap_to_grid
                            });
                        }

                        //we need a timeout to prevent the default for the click event
                        //because neither event.stopPropagation() or event.preventDefault() apply here
                        $timeout(function() {
                            ExportBuilderFacadeUIService.setIsResizing(false);
                        }, 0);

                        PubSub.emit($ExportBuilderItemDirectiveEvents.IS_RESIZING, false);
                    }
                });
            }

            function _onNewCreatedItem() {
                _unregisterEvents();
                _registerEvents();
            }

            function _calculateElementNewPositions(data) {
                _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
                    let newPosition = null;
                    if (element.snap_to_grid) {
                        newPosition = {
                            x_position: element.x_position + data.left,
                            y_position: element.y_position + data.top,
                        };
                    } else {
                        let pixelYPosition = $page.height() * (data.top / 100);
                        let pixelXPosition = $page.width() * (data.left / 100);
                        newPosition = {
                            x_position: element.x_position + pixelXPosition,
                            y_position: element.y_position + pixelYPosition,
                        };
                    }
                    _updateElementPosition(element, newPosition);
                })
            }

            function _updateElementPosition(element, data) {
                $('#export-builder-element-'+element.id).css({
                    'left': data.x_position+element.getFormat(),
                    'top': data.y_position+element.getFormat()
                })
            }

            function _updateCoordinates(element) {
                el.css({
                    'left': element.x_position+element.getFormat(),
                    'top': element.y_position+element.getFormat(),
                    'width': element.width+element.getFormat(),
                    'height': element.height+element.getFormat()
                })
            }

            function _updateDraggable(isInSpecialEditMode) {
                el.draggable(isInSpecialEditMode ? 'disable' : 'enable');
            }
            
            function _registerEvents() {
                el.bind('mousedown', _resetItemFocus);
                PubSub.on($ExportBuilderDashboardEvents.ON_ITEM_SNAP_TO_GRID_CHANGE+scope.element.id, _resetItemFocus);
                PubSub.on($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED+scope.element.id, _onNewCreatedItem);
                PubSub.on($ExportBuilderDashboardModelEvents.ON_COORDINATE_CHANGE+scope.element.id, _updateCoordinates);
                PubSub.on($ExportBuilderDashboardEvents.ON_SPECIAL_EDIT_MODE+scope.element.id, _updateDraggable);
                $document.bind('keyup keydown', _onShiftClicked);
            }

            function _unregisterEvents() {
                el.unbind('mousedown', _resetItemFocus);
                PubSub.off($ExportBuilderDashboardEvents.ON_ITEM_SNAP_TO_GRID_CHANGE+scope.element.id, _resetItemFocus);
                PubSub.off($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED+scope.element.id, _onNewCreatedItem);
                PubSub.off($ExportBuilderDashboardModelEvents.ON_COORDINATE_CHANGE+scope.element.id, _updateCoordinates);
                PubSub.off($ExportBuilderDashboardEvents.ON_SPECIAL_EDIT_MODE+scope.element.id, _updateDraggable);
                $document.unbind('keyup keydown', _onShiftClicked);
            }
        }
    }
}

/**
 * @ngInject
 */
function exportBuilderItemIconDirective(
    PubSub,
    ExportBuilderDashboardService,
    $ExportBuilderItemDirectiveEvents,
    $ExportBuilderDashboardModelEvents
) {
    return {
        scope: {
            element: '=exportBuilderItemIconDirective'
        },
        link: function (scope, el) {

            _registerEvents();
            scope.$on('$destroy', function () {
                _unregisterEvents();
            });

            /**
             * When item is created for the first time,
             *  must listen to PubSub event since the item.id changed
             * @private
             */
            function _onNewCreatedItem() {
                _unregisterEvents();
                _registerEvents();
            }

            function _onResizeItem(data) {
                el.css('font-size', Math.min(data.height, data.width) + 'px');
            }

            function _registerEvents() {
                PubSub.on($ExportBuilderItemDirectiveEvents.ON_RESIZE+scope.element.id, _onResizeItem);
                PubSub.on($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED+scope.element.id, _onNewCreatedItem);
            }

            function _unregisterEvents() {
                PubSub.off($ExportBuilderItemDirectiveEvents.ON_RESIZE+scope.element.id, _onResizeItem);
                PubSub.off($ExportBuilderDashboardModelEvents.ON_NEW_ITEM_CREATED+scope.element.id, _onNewCreatedItem);
            }
        }
    }
}

/**
 * @ngInject
 */
function exportBuilderItemImageDirective(
) {
    return {
        scope: {
            element: '=exportBuilderItemImageDirective'
        },
        link: function (scope) {
            _registerEvents();
            scope.$on('$destroy', function () {
                _unregisterEvents();
            });

            function _registerEvents() {
            }

            function _unregisterEvents() {
            }
        }
    }
}