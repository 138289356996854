import SerialWidgetRenderService from '@/modules/ta/widget/services/types/SerialWidgetRenderService';
import { BarChartConfig } from '@/modules/core/charts/am5/bar/models/BarChartConfig';
import { SeriesType } from '@/modules/core/charts/am5/charts.constants';

export default class BarChartWidgetRenderService extends SerialWidgetRenderService {
  /**
   * @param config
   * @returns {BarChartConfig}
   */
  initConfig(config) {
    return new BarChartConfig(config);
  }

  /**
   * @param column
   * @returns {string}
   */
  // eslint-disable-next-line no-unused-vars
  getSeriesType(column) {
    return SeriesType.COLUMN;
  }

  /**
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @param isComparison
   * @returns {{}}
   */
  getSeries(column, columnIndex, axisIndex, isComparison = false) {
    return {
      ...super.getSeries(column, columnIndex, axisIndex, isComparison),
      ...super.getBarSeries(column, columnIndex, isComparison),
      seriesType: this.getSeriesType(column),
    };
  }

  /**
   * @returns {*}
   */
  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
      ...super.getBarChartConfigProperties(),
    };
  }
}
