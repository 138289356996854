import { BaseResource } from '@/modules/core/app/resources/BaseResource';
import { GenericDataModel } from '@/modules/core/app/models/GenericDataModel';

/**
 * @abstract
 */
export class BaseDataResource extends BaseResource {
  /**
   * @param serviceId
   * @param dataViewId
   * @param params
   * @returns {Promise<GenericDataModel[]>}
   * */
  async getData(serviceId, dataViewId, params = {}) {
    return super.getData(GenericDataModel, `data`, params);
  }

  /**
   * @param serviceId
   * @param dataViewId
   * @param params
   * @returns {Promise<ApiColumn[]>}
   * */
  async getColumns(serviceId, dataViewId, params = {}) {
    params = { ...params, is_hidden: false };
    return super.getColumns(``, params);
  }
}
