export class DrilldownState {
  widgets;

  dashboardId;

  demoMode;

  dateRanges;

  constructor() {
    this.widgets = {};
    this.dashboardId = '';
    this.demoMode = false;
    this.dateRanges = {};
  }
}
