import LayoutResource from '@/modules/ta/dashboard/resources/LayoutResource';
import SectionTemplateResource from '@/modules/ta/dashboard/resources/templates/SectionTemplateResource';
import { deepClone, shallowCopyKeyedObject } from '@/modules/core/helper';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import { Layout } from '@/modules/ta/dashboard/models/Layout';

export class LayoutService extends BaseService {
  /**
   * @param {Layout[]} layouts
   */
  async saveList(layouts) {
    const saveLayouts = [];
    layouts.forEach((layout, index) => {
      const newLayout = shallowCopyKeyedObject(layout);
      newLayout.display_order = index;
      layout.display_order = index;
      newLayout.widgets = null;
      saveLayouts.push(newLayout);
    });

    const newLayouts = await LayoutResource.saveList(saveLayouts);
    return newLayouts.map((layout) => new Layout(layout));
  }

  /**
   *
   * @param pageId
   * @param displayOrder
   * @returns {Promise<*|{}|void>}
   */
  async addNewLayout(pageId, displayOrder) {
    const title = `Section ${displayOrder}`;
    const model = this._getLayoutModel({}, title, pageId, displayOrder);
    return LayoutResource.addNewLayout(model);
  }

  /**
   *
   * @param sectionTemplate {SectionTemplate}
   * @param title {String}
   * @param pageId
   * @param displayOrder {Number}
   * @returns {Promise<*|void>}
   */
  async useTemplateAsLayout(sectionTemplate, title, pageId, displayOrder) {
    const sectionTitle = title || `Section ${displayOrder}`;
    const model = this._getLayoutModel(
      deepClone(sectionTemplate),
      sectionTitle,
      pageId,
      displayOrder
    );
    return SectionTemplateResource.useTemplateAsLayout(model);
  }

  /**
   *
   * @param id
   * @returns {Promise<any>}
   */
  deleteItem(id) {
    return LayoutResource.deleteItem(id);
  }

  /**
   * To increment download count of template.
   * @param layoutId
   * @returns {*}
   */
  incrementDownloadCount(layoutId) {
    return SectionTemplateResource.incrementDownloadCount(layoutId);
  }

  saveDrawOptions(id, drawOptions) {
    return LayoutResource.saveDrawOptions(id, drawOptions);
  }

  /**
   *
   * @param sectionTemplate {SectionTemplate}
   * @param title {String}
   * @param pageId
   * @param displayOrder {Number}
   * @returns {Layout}
   * @private
   */
  _getLayoutModel(sectionTemplate, title, pageId, displayOrder) {
    const layout = {
      ...sectionTemplate,
      ...{
        id: sectionTemplate.id || null,
        page_id: pageId,
        title,
        widgets: {},
        display_order: displayOrder,
        is_in_library: false,
        is_predefined: false,
      },
    };
    return new Layout(layout);
  }
}

export default new LayoutService();
