'use strict';
import angular from 'angular';
import './lineitem.constants';
import './scripts/lineitems.ctrls';
import './scripts/lineitem.services';
import './scripts/lineitems.directives';

import addLineItemHtmlUrl from '../../../../iotool/app/modules/lineitems/views/addLineItem.html';
import editLineItemHtmlUrl from '../../../../iotool/app/modules/lineitems/views/editLineItem.html';
import viewLineItemHtmlUrl from '../../../../iotool/app/modules/lineitems/views/viewLineItem.html';
import auditHistoryHtmlUrl from '../../../../iotool/app/modules/lineitems/views/auditHistory.html';
import workflowLogHistoryHtmlUrl from '../../../../iotool/app/modules/lineitems/views/workflowLogHistory.html';

angular.module('core.lineitems', [
    'lineitems.constants',
    'lineitems.ctrls',
    'lineitems.services',
    'lineitems.directives'
])

    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('addLineItem', {
                parent: 'layout',
                url: '/items/addLineItem/:orderId',
                templateUrl: addLineItemHtmlUrl,
                controller: 'AddLineItemController',
                params: {
                    order: null
                }
            })
            .state('editLineItem', {
                parent: 'layout',
                url: '/item/order/:orderId/editLineItem/:lineItemId',
                templateUrl: editLineItemHtmlUrl,
                controller: 'AddLineItemController',
                params: {
                    comeFrom: null,
                    formattedEOAPreviews: null
                }
            })
            .state('viewLineItem', {
                parent: 'layout',
                url: '/items/viewLineItem/:lineItemId?flightId?taskId',
                templateUrl: viewLineItemHtmlUrl,
                controller: 'ViewLineItemController',
                params: {
                    formattedEOAPreviews: null
                }
            })
            .state('viewFlight', {
                parent: 'layout',
                url: '/items/viewFlight/:flightId',
                templateUrl: viewLineItemHtmlUrl,
                controller: 'ViewFlightController',
            })
            .state('myTasksOrderLineItem', {
                parent: 'layout',
                url: '/items/myTasks/:taskId/order/:orderId/lineItem/:lineItemId',
                templateUrl: viewLineItemHtmlUrl,
                controller: 'ViewLineItemController',
            })
            .state('myTasksLineitem', {
                parent: 'layout',
                url: '/items/myTasks/:taskId/lineItem/:lineItemId',
                templateUrl: viewLineItemHtmlUrl,
                controller: 'ViewLineItemController',
            })
            .state('myTasksFlight', {
                parent: 'layout',
                url: '/items/myTasks/:taskId/lineItem/:lineItemId/flight/:flightId',
                templateUrl: viewLineItemHtmlUrl,
                controller: 'ViewLineItemController',
            })
            .state('myQueuesOrderLineItem', {
                parent: 'layout',
                url: '/items/myQueues/:taskId/order/:orderId/lineItem/:lineItemId?myQueues',
                templateUrl: viewLineItemHtmlUrl,
                controller: 'ViewLineItemController',
            })
            .state('auditHistory', {
                parent: 'layout',
                url: '/items/viewLineItem/auditHistory/:type/:id/:lineItemId',
                templateUrl: auditHistoryHtmlUrl,
                controller: 'AuditHistoryController',
                params: {
                    activityLog: null
                }
            })
            .state('workflowLogHistory', {
                parent: 'layout',
                url: '/items/viewLineItem/workflowLogHistory/:type/:id/:lineItemId',
                templateUrl: workflowLogHistoryHtmlUrl,
                controller: 'WorkflowLogHistoryController',
            })
    }]);
