<template>
  <ChartWrapper v-bind="$props" :is-data-empty="isDataEmpty" :context="context" />
</template>

<script>
import { useBubbleChart } from '@/modules/core/charts/am5/bubble/composables/useBubbleChart';
import ChartWrapper from '@/modules/core/charts/am5/base/components/ChartWrapper.vue';
import { createChartContext } from '@/modules/core/charts/am5/base/composables/createChartContext';
import { isEmpty, isNil } from 'lodash';
import { WidgetEvents } from '@/modules/core/charts/am5/charts.constants';

export default {
  name: 'BubbleChart',
  components: {
    ChartWrapper,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String], // Number for Pixels or String for percentage or auto
      default: 800,
    },
    height: {
      type: [Number, String], // Number for Pixels or String for percentage or auto
      default: 400,
    },
    errorMessage: { type: String, required: false, default: undefined },
    disableErrorMessages: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, componentContext) {
    const context = createChartContext(props.config);
    const { refresh, onBind, onFrameEnded } = context();
    const { initBubbleChart } = useBubbleChart(context);

    onBind(() => {
      initBubbleChart();
    });

    onFrameEnded(() => {
      componentContext.emit(WidgetEvents.FRAME_ENDED);
    });

    return {
      context,
      refresh,
      initBubbleChart,
    };
  },
  computed: {
    isDataEmpty() {
      return isNil(this.config.data) || isEmpty(this.config.data);
    },
  },
  watch: {
    config(value) {
      this.refresh(value);
    },
  },
};
</script>
