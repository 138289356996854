import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function useSliceLegendEvents(context) {
  const { chart } = context();

  /**
   * The slice charts (funnel / pyramid) use slices instead of series
   * so to grey these out we need to target slices instead of the "strokes" in X/Y
   * @param legend
   */
  function addEvents(legend) {
    legend.itemContainers.each((itemContainer) => {
      itemContainer.events.on(Constant.POINTER_OVER, (event) => {
        const { target } = event;
        const { dataItem } = target;
        if (dataItem) {
          const seriesDataItem = dataItem.dataContext;
          if (seriesDataItem) {
            const slice = seriesDataItem.get(Constant.SLICE);
            if (seriesDataItem.dataContext.value && slice && slice.set) {
              greyScaleAllBut(slice);
            } else {
              // remove hover and click events if value is 0
              itemContainer.events.disable();
            }
          }
        }
      });
      itemContainer.events.on(Constant.POINTER_OUT, () => {
        restoreOpacity();
      });
      // add an extra click event to make sure we can't toggle all legend items off
      itemContainer.events.on(Constant.CLICK, (event) => {
        const { target } = event;
        const { dataItem } = target;
        if (dataItem) {
          const seriesDataItem = dataItem.dataContext;
          if (seriesDataItem) {
            if (!seriesDataItem.get(Constant.VISIBLE)) {
              disableLegendEvents(legend);
            } else {
              enableAllLegendEvents(legend);
            }
          }
        }
      });
    });
  }

  /**
   * Loop through and re-enable click on each of the legend item containers
   * @param legend
   */
  function enableAllLegendEvents(legend) {
    legend.itemContainers.each((itemContainer) => {
      itemContainer.events.enableType(Constant.CLICK);
    });
  }

  /**
   * If we've got X legend items and X-1 legend items are currently disabled
   * then the currently enabled legend needs the click event disabling
   * It'll be re-enabled if one of the other item containers is clicked
   * @param legend
   */
  function disableLegendEvents(legend) {
    const legendLength = legend.itemContainers.length;
    const disabledLegends = [];
    const enabledLegends = [];

    legend.itemContainers.each((itemContainer) => {
      if (itemContainer.get(Constant.DISABLED)) {
        disabledLegends.push(itemContainer);
      } else {
        enabledLegends.push(itemContainer);
      }
    });

    if (legendLength - 1 === disabledLegends.length) {
      enabledLegends.forEach((itemContainer) => {
        itemContainer.events.disableType(Constant.CLICK);
      });
    }
  }

  /**
   * Target every slice in every series and set the opacity for
   * everything but the selected one
   * @param selectedSlice
   */
  function greyScaleAllBut(selectedSlice) {
    chart.value.series.each((series) => {
      series.slices.each((slice) => {
        if (selectedSlice !== slice) {
          slice.states.applyAnimate(Constant.DISABLED, 300);
        }
      });
    });
  }

  /**
   * Restore the opacity for every slice in every series
   */
  function restoreOpacity() {
    chart.value.series.each((series) => {
      series.slices.each((slice) => {
        slice.states.applyAnimate(Constant.DEFAULT, 300);
      });
    });
  }

  return {
    addEvents,
  };
}
