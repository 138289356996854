import angular from 'angular';
import _ from 'lodash';

angular.module('core.router.services', [])
    .constant('RouteEvents', RouteEvents())
    .provider('SwitchAppService', SwitchAppServiceProvider);

function RouteEvents() {
    return {
        CHANGEROUTE: 'changeroute',
    }
}

function SwitchAppServiceProvider () {
    var self = this;

    this.switchAppFn = function(stateName, preferNui = false) {
        /* @ngInject */
        var f = function ($state, $stateParams, $location, AppConfig, AppFactory, UserLoginFactory) {
            const useNui = preferNui && AppFactory.getUser()?.user?.use_nui;
            if (!useNui) {
                if ($location.absUrl().contains(AppConfig.TA_PRE_URL)) {
                    return; // don't intercept, continue with current route
                }
            }

            var envMode = AppConfig.ROOT_URL.contains('/app/') ? 'app' : 'dist';
            // in IO env.

            // if state name was passed, force redirect to state
            var state = stateName ? $state.get(stateName) : null;
            if (state) {
                var url = state.url;
                _.forEach($stateParams, function (value, key) {
                    url = url.replace(':' + key, value);
                });

                if (useNui) {
                    window.location = AppConfig.NUI_PATH + '#' + url;
                    return;
                }

                if (UserLoginFactory.getUrl()) {
                    url += ('?redirectUrl=' + UserLoginFactory.getUrl());
                    UserLoginFactory.setUrl(null);
                }

                _redirect(url);
                return;
            }

            // fallback redirect switch app with same url
            _redirect($location.url());

            function _redirect(url) {
                window.location = AppConfig.TA_PRE_URL + envMode + '/' + '#' + url;
            }
        }
        return f;
    };

    this.$get = [function () {
        return self;
    }]
}