import {ColumnFormat} from "coreModules/shared/scripts/app.constants";

/**
 * Re-usable utility functions
 */
export class WidgetBuilderUtilService {

    constructor() {
    }

    /**
     * From a groupeSelectOptions, finds the best candiate for a grouped column
     * @param {GroupedColumnsSelect} groupedColumnSelect
     * @returns {any}
     */
    findGroupedColumnCandidateFromGroupedSelect(groupedColumnSelect) {
        const groupedColumns = _.flatMap(groupedColumnSelect.values, value => {
            return value.children;
        });
        return this.findGroupedColumnCandidate(groupedColumns)
    }

    /**
     * @param {Array} columns
     * @returns {any}
     */
    findGroupedColumnCandidate(columns) {
        return _.find(columns, {is_default_groupby: true}) // for live integrations
            || _.find(columns, {is_primary_date_field: true})
            || _.find(columns, {format: ColumnFormat.FORMAT_DATETIME})
            || _.first(columns);
    }

}
export default new WidgetBuilderUtilService();