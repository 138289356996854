export class DevToolsState {
  /**
   * @var {boolean}
   */
  isShowingPanel = false;

  /**
   * @var {number}
   */
  numQueryNotices = 0;

  /**
   * Intercepted requests from api calls.
   * @type {Array}
   */
  requests = [];

  /**
   * Intercepted responses from api calls.
   * @type {Array}
   */
  responses = [];

  /**
   * Intercepted errors from api calls.
   * @type {Array}
   */
  errors = [];
}
