import { mapValues } from 'lodash';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { LayoutMetadata } from '@/modules/ta/dashboard/models/LayoutMetadata';
import { WidgetFactory } from '@/modules/ta/widget/factories/WidgetFactory';
import { ExecutiveSummary } from '@/modules/ta/executivesummaries/models/ExecutiveSummary';

export class Layout extends BaseModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  unique_id;

  /**
   * @var {?string}
   */
  page_id;

  /**
   * @var {number}
   */
  display_order;

  /**
   * @var {boolean}
   */
  is_predefined;

  /**
   * @var {boolean}
   */
  is_in_library;

  /**
   * @var {string}
   */
  title;

  /**
   * @var {LayoutMetadata}
   */
  metadata;

  /**
   * @var {datetime}
   */
  created_at;

  /**
   * @var {datetime}
   */
  updated_at;

  /**
   * @var {string}
   */
  formatted_created_at;

  /**
   * @var {string}
   */
  formatted_updated_at;

  /**
   * @var {Object}
   */
  widgets = {};

  /**
   * @var {ExecutiveSummary}
   */
  executive_summary;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.unique_id = model.unique_id;
    this.page_id = model.page_id;
    this.display_order = model.display_order;
    this.is_predefined = model.is_predefined;
    this.is_in_library = model.is_in_library;
    this.title = model.title;
    this.metadata = new LayoutMetadata(model.metadata);
    this.created_at = model.created_at;
    this.updated_at = model.updated_at;
    this.formatted_created_at = model.formatted_created_at;
    this.formatted_updated_at = model.formatted_updated_at;
    this.widgets = mapValues(model.widgets, (widget) =>
      widget ? WidgetFactory.build(widget) : widget
    );
    this.executive_summary = new ExecutiveSummary(model.executive_summary ?? {});
  }
}
