'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('clientgroup.ctrls', [])

    .controller('ClientGroupListController', ClientGroupListController)
    .controller('ClientGroupController', ClientGroupController);

//
// Client group grid controller
//
ClientGroupListController.$inject = ['$scope', '$timeout'];
function ClientGroupListController($scope, $timeout) {
    var brandMappings = {};
    $.getJSON($.fn.apiUrl('branding/mappings'), '', function(result) {
        brandMappings = result.data;
        var options = $.core.datatable.defaultOptions(brandMappings.client_group);
        options.iActions = 1;
        $timeout(function () {
            $.core.datatable.build($('#client-group-table'), options);
        }, false);
    });

}

//
// Client group controller
//
ClientGroupController.$inject = ['$scope'];
function ClientGroupController($scope) {
    $.core.admin.clientGroup.initialize();
}