import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  /**
   * @param state
   * @param isCallbackEnabled
   * @returns {Promise<*>}
   */
  setIsCallbackEnabled({ state }, isCallbackEnabled) {
    commits.callbackroute.setIsCallbackEnabled(isCallbackEnabled);
    return state.isCallbackEnabled;
  },

  /**
   * @param state
   * @param callbackRoute
   * @returns {Promise<Array>}
   */
  pushCallbackRoute({ state }, callbackRoute) {
    commits.callbackroute.pushCallbackRoute(callbackRoute);
    return state.callbackRoutes;
  },

  /**
   * @param state
   * @returns {Promise<*>}
   */
  popCallbackRoute({ state }) {
    const callbackRoute = state.callbackRoutes[state.callbackRoutes.length - 1];
    commits.callbackroute.popCallbackRoute();
    return callbackRoute;
  },

  /**
   * @returns {Promise<void>}
   */
  resetCallbackRoutes() {
    commits.callbackroute.resetCallbackRoutes();
  },
};
