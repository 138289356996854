export const mutations = {
  setVueBasedChartRender(state, { ready = true }) {
    window.vueBasedChartRender = ready;
  },
  setConfig(state, { config }) {
    state.config = config;
  },
  setIsExporting(state, { isExporting = true }) {
    state.isExporting = isExporting;
  },
  setExportReady(state, { ready = true }) {
    window.exportReady = ready;
    window.vueBasedChartRender = false;
    state.exportReady = ready;
  },
};
