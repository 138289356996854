'use strict';
import angular from 'angular';
import _ from 'lodash';
import {WidgetType} from '../../design.widget.constants';
import { DashboardEventType } from "../../../../../../../grok/src/modules/ta/dashboard/dashboard.constants";

angular.module('widget.create.ctrls', [])

    .controller('CreateWidgetController', CreateWidgetController);

/**
 *
 * @constructor
 * @ngInject
 */
function CreateWidgetController(
    PubSub,
    $WidgetCreateEvents,
    $scope,
    $timeout,
    $WidgetEvents,
    DesignFactory,
    LayoutFactory,
    DrawOption,
    WidgetTypeGrouping,
    WidgetType,
    DataSourceType,
    UIFactory,
    WidgetFactory,
    WidgetCreateFactory,
    WidgetCreateTypeFactory,
    SlidePanelFactory,
    DrawOptionPanelFactory,
    DrawOptionFactory,
    RelativeDateRange,
    $WidgetLibraryEvents,
    $SlidePanelEvents,
    WidgetUtilService,
    gettextCatalog,
    WidgetBuilderStylesModelFactory
) {
    var currentPage = DesignFactory.getCurrentPage();
    var widgetCreateDrawOptionPanelId = 'widget-draw-options';
    WidgetCreateFactory.$registerScope($scope);

    // Expose constant to scope
    $scope.WidgetType = WidgetType;
    $scope.WidgetTypeGrouping = WidgetTypeGrouping;
    $scope.DrawOption = DrawOption;

    // Exposed functions
    $scope.initPreCreation = initPreCreation;
    $scope.stepBack = stepBack;
    $scope.initNew = initNew;
    $scope.initEdit = initEdit;
    $scope.saveWidget = saveWidget;
    $scope.cancelWidget = cancelWidget;
    $scope.resetWidget = resetWidget;
    $scope.canShowGroupByColumns = canShowGroupByColumns;
    $scope.toggleDrawOptionsPanel = toggleDrawOptionsPanel;
    $scope.drawOptionsPanelIsShowing = drawOptionsPanelIsShowing;
    $scope.showWidgetTitle = showWidgetTitle;
    $scope.showDrawOptionPanel = showDrawOptionPanel;

    registerEvents();

    /**
     * Default first step
     */
    function initPreCreation() {
        _resetToNewState();
        UIFactory.hideModal('create-widget-modal');
        PubSub.emit($WidgetLibraryEvents.INIT_MODAL);
    }

    /**
     * Step back to data source select
     */
    function stepBack() {
        // Need to step back one step

        _resetToNewState();

        // Clear out datasource selection
        if ($scope.model.metadata.data_source.data_view) {
            $scope.model.metadata.data_source.data_view = null;
            $scope.model.metadata.data_source.data_view_name = null;
            $scope.model.metadata.data_source.data_view_is_geo = false;
            // Reset selected columns
            $scope.model.metadata.data_columns = {};
            $scope.model.metadata.data_columns.selected = [];
            $scope.model.metadata.data_columns.grouped = [];
        } else {
            initPreCreation();
        }
    }

    /**
     * Initialize new widget creation
     * @param typeGrouping
     */
    function initNew(typeGrouping) {
        $scope.typeGrouping = typeGrouping;
        init();
        setInitVars();
    }

    function _resetToNewState() {
        $scope.state = WidgetFactory.getDefaultState();
        $scope.state.isCreating = true;
        $scope.state.isBuilding = false;
        $scope.state.isAdding = true;
    }

    /**
     * Variables to set for a new widget
     */
    function setInitVars() {
        $scope.model = WidgetCreateFactory.setBaseWidgetModel({}, $scope.state.widgetType.id);
        var model = $scope.model;
        var metadata = model.metadata;
        var dataSource = metadata.data_source;
        var currentModule = $scope.currentModule || currentPage.module;

        // for drawoptions in builder
        model.metadata.dynamic.is_creating = true;

        WidgetCreateFactory.setCurrentWidget(model);

        var state = $scope.state;

        // Determines default height/width for new widget
        if ($scope.state.isAdding) {
            WidgetCreateFactory.$updateWidgetDimensions();
        }

        // Set proper widget type states
        WidgetCreateTypeFactory.$toggleTypeState();

        //
        // NEW DATASOURCED WIDGET
        // NOTE: it is preferable to put metadata intialization here since it is re-inited at every new add/edit
        //

        if ($scope.typeGrouping == WidgetTypeGrouping.DATASOURCED) {
            dataSource.type = null;
            dataSource.id = null;
            dataSource.data_view = null;

            dataSource.include_data_freshness_date = false;

            dataSource.icon = 'icomoon-bar-chart';
            dataSource.data_view_name = null;
            //
            // DEFAULT metadata for a big number
            //

            // These are the selected columns
            metadata.data_columns = {};
            metadata.data_columns.selected = [];
            metadata.data_columns.grouped = [];

            metadata.filter_set_id = null;

            // Need default values for these even though they are for chart widgets
            metadata.time_grouping = null;
            metadata.is_overriding_date_range = false;
            metadata.start_date_override = null;
            metadata.end_date_override = null;
            metadata.relative_date_range = RelativeDateRange.DEFAULT;
            metadata.comparison_start_date_override = null;
            metadata.comparison_end_date_override = null;
            metadata.comparison_relative_date_range = RelativeDateRange.PRIOR_PERIOD;
            metadata.ignore_date_range = false;

            // If current module is already know we may skip the first step (datasource config step)
            if (!_.isNil(currentModule)) {
                $timeout(function() {
                    metadata.data_source.type = currentModule;
                }, 100, false);
            }

            state.isCreating = true;
        } else if ($scope.typeGrouping == WidgetTypeGrouping.ADMIN) {
            $scope.$evalAsync(function() {

                dataSource.type = DataSourceType.USER;

                dataSource.name = gettextCatalog.getString('Admin Widget');
                dataSource.icon = 'icomoon-admin-widget';
                //
                // DEFAULT metadata
                //
                // Default select Account Manager until other fields are supported
                metadata.admin_source = {
                    id:"account_manager_user_id",
                    text:gettextCatalog.getString("Account Manager")
                };

                // These are the selected columns
                metadata.data_columns = {};
                metadata.data_columns.selected = [];
                metadata.data_columns.grouped = [];

                state.isBuilding = true;
            })
        } else {
            dataSource.icon = 'icomoon-media-widget';
            metadata.entity = dataSource;

            metadata.content = '';
            metadata.draw_options = WidgetBuilderStylesModelFactory.getDrawOptionsModel($scope.model.metadata.draw_options);

            state.isBuilding = true;
            if ($scope.model.type === WidgetType.MEDIA) {
                // Fix an internal bug of summernote that content doesn't get updated if it is in code view
                $timeout(function () {
                    var $editable = angular.element('.summernote-editable');
                    angular.element('.note-codable').on('blur', function() {
                        if ($editable.summernote('codeview.isActivated')) {
                            // Sync metadata.content with content in code view
                            metadata.content = $editable.summernote('code') === '<p><br></p>'
                                ? '' : $editable.summernote('code');
                            // Store the content in contentBuffer in order to fix race condition issue (TA-14013)
                            $scope.contentBuffer = metadata.content;
                        }
                    });
                }, 0, false);
            }
        }

        if ($scope.typeGrouping === WidgetTypeGrouping.DATASOURCED) {
            // Always sample data by default
            metadata.draw_options[DrawOption.SHOW_SAMPLE_DATA] = true;
        }
    }

    /**
     * Initialize add/edit process (common to both actions)
     */
    function init() {
        // Reset to default state when setting vars, otherwise previous widget state will persist.
        $scope.state = WidgetFactory.getDefaultState();
        $scope.state.isAdding = !WidgetCreateFactory.hasSwappedWidget();
        $scope.buttonClicked = false;
        $scope.contentBuffer = null;

        // Determine widget type groupings to exclude
        var excludedGroupings = null;
        if ($scope.typeGrouping == WidgetTypeGrouping.DATASOURCED) {
            excludedGroupings = [WidgetTypeGrouping.ADMIN, WidgetTypeGrouping.PREMADE];
        }

        // Set default widget type
        // Need to filter out
        $scope.widgetTypes = WidgetFactory.getWidgetTypes($scope.typeGrouping, excludedGroupings);

        // Set which type grouping the widget belongs to
        if ($scope.typeGrouping == WidgetTypeGrouping.DATASOURCED) {
            // BIGNUMBER default for datasourced widget type grouping
            $scope.state.widgetType = _.find($scope.widgetTypes, {id: WidgetType.BIGNUMBER});
        } else if ($scope.typeGrouping == WidgetTypeGrouping.DISPLAY) {
            // MEDIA default for display widget type grouping
            $scope.state.widgetType = _.find($scope.widgetTypes, {id: WidgetType.MEDIA});
        } else if ($scope.typeGrouping == WidgetTypeGrouping.ADMIN) {
            // TEMPORARY default account manager widget for admin widget type grouping
            $scope.state.widgetType = _.find($scope.widgetTypes, {id: WidgetType.ACCOUNTMANAGER});
        } else if ($scope.typeGrouping == WidgetTypeGrouping.CHATGPT) {
            // ChatGPT default for chatgpt widget type grouping
            $scope.state.widgetType = _.find($scope.widgetTypes, {id: WidgetType.CHATGPT});
        } else if ($cope.typeGrouping == WidgetTypeGrouping.EXECUTIVESUMMARY) {
            $scope.state.widgetType = _.find($scope.widgetTypes, {id:WidgetType.EXECUTIVESUMMARY});
        }

        // Make sure all panels are closed before stating any edit/add
        SlidePanelFactory.closeAll();
    }

    /**
     * Initialize edit variables
     * @param state
     * @param widget
     */
    function initEdit(state, widget) {
        $scope.typeGrouping = WidgetFactory.getWidgetTypeGrouping(widget.type);
        init();

        $scope.model = {};
        angular.copy(state, $scope.state);
        angular.copy(widget, $scope.model);

        WidgetCreateFactory.setCurrentWidget($scope.model);

        // This converts width/height constant dimensions to pixel values
        widget.computedWidth = WidgetUtilService.getWidgetWidth(widget.width);
        widget.computedHeight = WidgetUtilService.getWidgetHeight(widget.height);

        var newState = $scope.state;

        newState.canSave = false; // canSave state is not always true, create logic will determine that for us.
        newState.isBuilding = true;
        newState.isCreating = true;
        newState.isEditing = true;
        newState.isAdding = false;

        if (newState.deleteChart) {
            delete newState.deleteChart;
        }

        var selectedColumnCount;
        if (newState.isAdminWidget) {
            selectedColumnCount = widget.metadata.data_columns.selected.length;
            newState.numSelectedColumns = selectedColumnCount;
            newState.canSave = true;
        } else if (newState.isDataSourcedWidget) {
            selectedColumnCount = widget.metadata.data_columns.selected.length;
            var groupByColumnCount = widget.metadata.data_columns.grouped.length;
            newState.numSelectedColumns = selectedColumnCount;
            newState.groupByColumnSelected = groupByColumnCount;
            newState.drillDownColumnSelected = groupByColumnCount > 1;
        } else {
            // Set state to building, so that media charts will reinitialize title container
            newState.isEditing = true;
            $scope.cancelCallBack = function() {
                newState.isEditing = false;
            };
            $scope.model.metadata.data_source = widget.metadata.data_source || {};
        }
    }

    /**
     * ng-show evaluation for groupby-columns-config
     * @returns {boolean}
     */
    function canShowGroupByColumns() {
        var state = $scope.state;
        var model = $scope.model;
        return (state.numSelectedColumns || state.groupByColumnSelected) && !model.is_predefined;
    }

    /**
     * Toggle widget draw options panel fn
     * @param drawOptions
     * @param widgetTypeId
     */
    function toggleDrawOptionsPanel(drawOptions, widgetTypeId, widgetDataSource, selectedColumns) {
        var drawOptionObj = {
            panelId: widgetCreateDrawOptionPanelId,
            headerTitle: gettextCatalog.getString('Widget Draw Options'),
            drawOptions: drawOptions,
            widgetTypeId: widgetTypeId,
            datasource: widgetDataSource
        };

        if (widgetTypeId === WidgetType.DATAGRID) {
            drawOptionObj.selectedColumns = selectedColumns;
        }
        $scope.drawOptionsPanelOptions = DrawOptionPanelFactory.initializeWidgetCreateOptions(drawOptionObj);
    }

    function showDrawOptionPanel() {
        return DrawOptionPanelFactory.getIsShowing() 
            && DrawOptionPanelFactory.getPanelId() === widgetCreateDrawOptionPanelId;
    }

    /**
     * Tell us if draw options panel is showing
     */
    function drawOptionsPanelIsShowing() {
        return showDrawOptionPanel();
    }

    /**
     * Save the currently created widget
     */
    function saveWidget() {
        $scope.buttonClicked = true;

        if ($scope.state.isAdding) {
            // If title empty and NOT media widget, give a default title
            if (_.isEmpty($scope.model.title)
                && !_.includes($scope.state.widgetType.type_groupings, WidgetTypeGrouping.DISPLAY)) {
                $scope.model.title = gettextCatalog.getString('Untitled {{typeName}}',
                    {typeName: $scope.state.widgetType.name});
            }
        }

        // Delete datasource object, if no data_source type is selected
        if ($scope.model.metadata.data_source && !$scope.model.metadata.data_source.type) {
            delete $scope.model.metadata.data_source;
        }

        // Check if we are swapping a widget, if so, we need to replace it in place
        if (WidgetCreateFactory.hasSwappedWidget()) {
            WidgetCreateFactory.mapSwappedWidgetData($scope.model);
        }
        $timeout(function() {
            WidgetFactory.save($scope.model).then(function(widget) {
                UIFactory.notify.showSuccess(gettextCatalog.getString('Widget successfully saved'));

                // need to emit swapped widget event
                if (WidgetCreateFactory.hasSwappedWidget()) {
                    PubSub.emit($WidgetEvents.SWAP_WIDGET + widget.id, angular.copy(widget));
                }

                // When saving a Media Widget, there is a race condition of updating metadata.content by summernote,
                // therefore using contentBuffer to restore the content
                if ($scope.contentBuffer) {
                    $scope.model.metadata.content = $scope.contentBuffer;
                }

                $scope.state.isDoneCreating = true;

                $timeout(function() {
                    $scope.state = WidgetFactory.getDefaultState();
                }, 0, false);

                PubSub.emit($WidgetCreateEvents.CREATED, {widget: widget, isAdding: $scope.state.isAdding});
                if (window.isNUI && widget.type === WidgetType.MEDIA) {
                    if ($scope.state.isAdding) {
                        PubSub.emit('SegmentEvents', {
                            event: DashboardEventType.ADD_MEDIA_WIDGET,
                            payload: {widget: widget, page: DesignFactory.getCurrentPage()}
                        });
                    } else {
                        PubSub.emit('SegmentEvents', {
                            event: DashboardEventType.EDIT_MEDIA_WIDGET,
                            payload: {widget: widget, page: DesignFactory.getCurrentPage()}
                        });
                    }
                }


            }, function() {
                $scope.buttonClicked = false;
                $scope.state.canSave = true;
            });
        });
    }

    /**
     * Delete any charts created in widget create/edit process
     */
    function cancelWidget() {
        if ($scope.state.deleteChart) {
            $scope.state.deleteChart();
        }
        if ($scope.cancelCallBack) {
            $scope.cancelCallBack();
        }
        _resetToNewState();
        resetWidget();
    }

    /**
     * Variables to reset when leaving the add/edit process (i.e. exiting the modal)
     */
    function resetWidget() {
        // As a safety measure force a scope digest here since closing the modal happens does not respond to ng-click
        $scope.$evalAsync(function() {
            // Resetting the state is essential since we want to avoid
            // any elements showing from a remnant state on the next add/edit init
            $scope.model = {};
            $scope.state = WidgetFactory.getDefaultState();
            WidgetCreateFactory.resetCurrentWidget();
        });
    }

    /**
     * @private
     * Will resolve the appropriate draw options based on the layout/widget inheritance tree
     * @returns {*}
     */
    function resolveDrawOptionValue(key) {
        var widget = $scope.widget;
        return DrawOptionFactory.resolveDrawOptionValue(key, widget, widget.is_export);
    }

    /**
     * Determines whether or not to show the title of a widget
     * @returns {boolean}
     */
    function showWidgetTitle() {
        return resolveDrawOptionValue(DrawOption.SHOW_TITLE);
    }

    function registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE, onPanelClose);
        PubSub.on($SlidePanelEvents.CLOSE_ALL, onPanelCloseAll);

        $scope.$on('$destroy', function () {
            PubSub.off($SlidePanelEvents.CLOSE, onPanelClose);
            PubSub.off($SlidePanelEvents.CLOSE_ALL, onPanelCloseAll);
        });
    }

    function onPanelClose(sourcePanel) {
        if (sourcePanel === widgetCreateDrawOptionPanelId) {
            $scope.drawOptionsPanelOptions = null;
        }
    }

    function onPanelCloseAll(sourcePanel) {
        if (sourcePanel !== widgetCreateDrawOptionPanelId) {
            $scope.drawOptionsPanelOptions = null;
        }
    }
}