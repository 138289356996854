<template>
  <ComboChart
    v-if="canShowChart"
    :config="chartConfig"
    width="100%"
    height="100%"
    :widget-type="widgetType"
    :disable-error-messages="disableErrorMessages"
    @frame-ended="setIdle"
  />
</template>

<script>
import ComboChart from '@/modules/core/charts/am5/combo/components/ComboChart.vue';
import { BaseChartWidgetPropsMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetPropsMixin';
import { BaseChartWidgetMethodMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetMethodMixin';

export default {
  name: 'LegacyComboChartWidget',
  mixins: [BaseChartWidgetPropsMixin, BaseChartWidgetMethodMixin],
  components: { ComboChart },
};
</script>
