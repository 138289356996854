import { WidgetType } from '@/modules/ta/widget/widget.constants';
import { BigNumberDataService } from '@/modules/ta/widget/services/data/BigNumberDataService';
import { WidgetDataResourceFactory } from '@/modules/ta/widget/factories/WidgetDataResourceFactory';
import { DataGridDataService } from '@/modules/ta/widget/services/data/DataGridDataService';
import { TopPerformerDataService } from '@/modules/ta/widget/services/data/TopPerformerDataService';
import { CombinationChartDataService } from '@/modules/ta/widget/services/data/CombinationChartDataService';
import { LineChartDataService } from '@/modules/ta/widget/services/data/LineChartDataService';
import { PieChartDataService } from '@/modules/ta/widget/services/data/PieChartDataService';
import { BarChartDataService } from '@/modules/ta/widget/services/data/BarChartDataService';
import { GeoChartDataService } from '@/modules/ta/widget/services/data/GeoChartDataService';
import { GaugeChartDataService } from '@/modules/ta/widget/services/data/GaugeChartDataService';
import { FunnelChartDataService } from '@/modules/ta/widget/services/data/FunnelChartDataService';
import { MediaDataService } from '@/modules/ta/widget/services/data/MediaDataService';
import { AccountManagerDataService } from '@/modules/ta/widget/services/data/AccountManagerDataService';
import { GoalDataService } from '@/modules/ta/widget/services/data/GoalDataService';
import { BubbleChartDataService } from '@/modules/ta/widget/services/data/BubbleChartDataService';

export class WidgetDataServiceFactory {
  /**
   * @param {Widget} widget
   * @returns {WidgetDataService}
   */
  static build(widget) {
    if (
      !widget.isAdminWidget() &&
      !widget.isMediaWidget() &&
      (!widget.data_view_id || !widget.data_type || !widget.data_source_id)
    ) {
      return null;
    }

    switch (widget.type) {
      case WidgetType.TYPE_BIGNUMBER:
        return new BigNumberDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_DATAGRID:
        return new DataGridDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_TOPPERFORMER:
        return new TopPerformerDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_BARCHART:
        return new BarChartDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_BUBBLECHART:
        return new BubbleChartDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_COMBINATIONCHART:
        return new CombinationChartDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_LINECHART:
        return new LineChartDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_PIECHART:
        return new PieChartDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_GEOCHART:
        return new GeoChartDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_GAUGECHART:
        return new GaugeChartDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_FUNNELCHART:
        return new FunnelChartDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_MEDIA:
        return new MediaDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_ACCOUNTMANAGER:
        return new AccountManagerDataService(WidgetDataResourceFactory.build(widget));
      case WidgetType.TYPE_GOAL:
        return new GoalDataService(WidgetDataResourceFactory.build(widget));
      default:
        Logger.log(i18n.$t('Unsupported widget type'), Logger.LEVEL_ERROR);
    }
  }
}
