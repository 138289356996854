'use strict';
import angular from 'angular';
import _ from 'lodash';
import DevTools from './../../../../devtools';
import moment from "moment";
import {
    $WidgetClientDiscrepancy,
    TimeGrouping,
    WeeklyStartDay,
    WidgetDataViews
} from "coreModules/design/widget/design.widget.constants";
import {
    RelativeDateRangeKey
} from '@/modules/core/daterange/daterange.constants';
angular.module('design.widget.data.services', [])
    .factory('WidgetDataSourceFactory', WidgetDataSourceFactory)
    .factory('WidgetCurrencyDataService', WidgetCurrencyDataService);


/**
 * @ngInject
 */
function WidgetDataSourceFactory(
    $q,
    AppFactory,
    ChartUtilFactory,
    DataSourceType,
    DateRangeFactory,
    DesignFactory,
    WidgetFactory,
    WidgetUtilService,
    RelativeDateRange,
    MomentDateFormat,
    DrawOption,
    ExportFactory,
    HttpCodes,
    DataSourceFactory,
    DataSourceSampleFactory,
    DataSourceDataGridFactoryDelegate,
    DataSourceResourceDelegate,
    ReportStudioTemplateDataService,
    DashboardContextService,
    WidgetCurrencyDataService,
    ChartPlotType,
    ComparisonOptions,
    SparklineOptions,
    WidgetType,
    ColumnFormat,
    TimeGrouping,
    DateFactory
) {
    /**
     * Internal counter to know how many widgets are left to fetch data
     * @type {number}
     * @private
     */
    var _widgetDataToFetch = -1;

    /**
     * @delegate method
     * Get sample data set for display when creating widget
     * @param widget
     * @param params
     * @param drillDownConfig
     * @param isSparkLine
     * @returns {*|IPromise<TResult>|JQueryPromise<U>|JQueryPromise<void>|JQueryPromise<R>}
     */
    async function getData(widget, params, drillDownConfig, isSparkLine = false) {
        WidgetCurrencyDataService.clearDiscrepancy(widget);

        const isDemoMode = DashboardContextService.isDemoModeEnabled();

        let metadata = widget.metadata;
        if (isDemoMode) {
            metadata.dynamic.warnings = [];
            metadata.dynamic.errors = [];
        }

        if (metadata.data_source && DataSourceFactory.isGoalDataSourceType(metadata.data_source.type)) {
            widget.metadata.draw_options[DrawOption.SHOW_TOTAL_ROW] = false;
        }

        const report = ReportStudioTemplateDataService.getReport();

        if (WidgetUtilService.isBigNumber(widget.type)) {
            metadata.data_columns.grouped = [];
        }

        // In the case of media widgets, the content is loaded via a widget data content endpoint
        // 'api/dash/widgets/{widget_id}/data'
        var hasContentData = 'content' in metadata;

        // By setting to -1 we avoid isAllDataLoaded to be true on init (which is what we want)
        if (_widgetDataToFetch === -1) {
            _widgetDataToFetch = 0;
        }
        _widgetDataToFetch++;

        // skip Media widgets
        if (isDemoMode && !hasContentData
          || (WidgetFactory.useSampleData(metadata)
            && !hasContentData)) {
            var tempWidget = _.cloneDeep(widget);
            if(isSparkLine){
                tempWidget.metadata.type = WidgetType.LINECHART;
                tempWidget.metadata.data_columns.grouped = [{field: 'log_date', is_primary_date_field: true, format: ColumnFormat.FORMAT_DATETIME}];
                tempWidget.metadata.time_grouping =  TimeGrouping.GROUPING_WEEKLY;
                params.groupby = 'log_date';
                params.sort = 'log_date';
                params.timegrouping =  TimeGrouping.GROUPING_WEEKLY;
            }
            return $q.resolve(DataSourceSampleFactory.generateSampleData(tempWidget, params, isSparkLine)).then(function (data) {
                return handleDataCallback(data, metadata);
            });
        }

        // If widget is inactive, skip it's data fetch
        if (metadata.dynamic) {
            const isReport = _.isEmpty(DesignFactory.getCurrentPage());
            // for reportstudio api will throwing error(api) and for dashboard it is update in metadata.
            if (!isReport) {
                if (metadata.dynamic.is_inactive) {
                    return metadata;
                }
                // For OnDemand calls, we can send API call to backend in case of errors
                // such that errors like date range error will be refreshed after changing dashboard date range
                if (!widget.has_live_integration && !_.isEmpty(metadata.dynamic.errors)) {
                    return metadata;
                }
            }
            if (ExportFactory.getIsExporting() && widget.report_id && widget.has_live_integration) {
                predefinedData = metadata.dynamic.predefined_data
                if (predefinedData && predefinedData.http_code !== HttpCodes.SUCCESS) {
                    metadata.dynamic.predefined_data = {
                        data: {...predefinedData, error: true, data: [predefinedData.message]}
                    }
                }
            }

            // If the data is already pre-loaded, use it instead
            if (!_.isNull(metadata.dynamic.predefined_data) && metadata.dynamic.predefined_data.data && (!isReport || ExportFactory.getIsExporting())) {
                var deferred = $q.defer();
                var predefinedData = metadata.dynamic.predefined_data;
                if (predefinedData.total_data) {
                    // Total data always only contains one item
                    predefinedData.total_data = Array.isArray(predefinedData.total_data)
                      ? _.first(predefinedData.total_data)
                      : predefinedData.total_data
                }
                deferred.resolve(predefinedData);

                return deferred.promise;

            }
            if (ExportFactory.getIsExporting() && (!_.isEmpty(metadata.dynamic.warnings) || !_.isEmpty(metadata.dynamic.errors))) {
                //Don't make the call to the backend if there is warning in metadata.
                return metadata;
            }
        }
        var queryParams = _.extend({}, params);
        var dataSource = {};

        // Fields that we can add to the requested field list based on some groupby and other logic
        var extraRequestFields = [];

        if (!hasContentData) {
            if (metadata.data_columns.grouped.length) {
                if (_.isNull(drillDownConfig) || !drillDownConfig.skipGroupedBy) {
                    setGroupByQueryParam(queryParams, metadata, drillDownConfig, extraRequestFields);
                    setTimeGroupingQueryParam(queryParams, metadata, drillDownConfig);
                } else {
                    queryParams.aggregate = true;
                }
            }

            setFieldsQueryParam(queryParams, metadata, extraRequestFields);
            setSortQueryParam(queryParams, metadata);
            setFilterQueryParam(queryParams, metadata);
            setShowEmptyDatesQueryParam(queryParams, metadata);

            if(report && report.id) {
                queryParams.widget_report_id = report.id;
            }

            if (DataSourceFactory.isDashboardDataSourceType(metadata.data_source.type)) {
                setWidgetRequestQueryParam(queryParams, widget);
                setTotalQueryParam(queryParams, widget);
                if (!metadata.ignore_date_range) {
                    setDateRangeQueryParam(queryParams, metadata, isSparkLine);
                }
            }

            if (DataSourceFactory.dataSourceContainsServices(metadata.data_source.type)) {
                setServiceTypeQueryParams(queryParams, metadata);
            }

            dataSource = metadata.data_source;
        } else {
            if (!_.isNull(metadata.content)) {
                if (metadata.dynamic) {
                    // ready for export
                    metadata.dynamic.raw_data = metadata.content;
                }
                // We already have the content on the front end. No need to make a call
                return {data: metadata.content};
            }
            setWidgetRequestQueryParam(queryParams, widget);
            dataSource.type = DataSourceType.WIDGET;
        }
        if(isSparkLine){
            queryParams.widget_type = WidgetType.LINECHART;
            let columnParams = {
                is_groupable: true,
                widget_request: true,
                has_live_integration: widget.has_live_integration,
            };
            var columns = await DataSourceFactory.getColumns(metadata.data_source, columnParams);

            let primaryDateColumn = _.find(columns, {is_primary_date_field: true});

            if (!WidgetUtilService.isEmbeddedSparklinesPlotType(metadata.draw_options)) {
                queryParams.groupby = primaryDateColumn.field;
                queryParams.sort = primaryDateColumn.field;
            } else {
                queryParams.sort = !_.isNil(queryParams.sort) ? queryParams.sort+','+primaryDateColumn.field : primaryDateColumn.field;
                queryParams.limit = 1000;
            }

            metadata.primaryDate = primaryDateColumn.field;

            var timegroups = await DateFactory.timeGroupings.get({
                service_id: metadata.data_source.id,
                has_live_integration: widget.has_live_integration
            });

            if (WidgetUtilService.isEmbeddedSparklinesPlotType(metadata.draw_options)) {
                queryParams.timegrouping = metadata.draw_options.embed_sparkline_date_range === SparklineOptions.LAST_3_MONTHS ? TimeGrouping.GROUPING_WEEKLY : TimeGrouping.GROUPING_MONTHLY;
            } else {
                queryParams.timegrouping = timegroups[0].key;
                if (timegroups.find((timegroup) => timegroup.key === TimeGrouping.GROUPING_WEEKLY)) {
                    queryParams.timegrouping = TimeGrouping.GROUPING_WEEKLY;
                    setWeeklyStartDayQueryParam(queryParams, metadata);
                } else if (timegroups.find((timegroup) => timegroup.key === TimeGrouping.GROUPING_MONTHLY)) {
                    queryParams.timegrouping = TimeGrouping.GROUPING_MONTHLY;
                }
            }

            queryParams.fields = queryParams.fields ? queryParams.fields + `,${primaryDateColumn.field}` : `${primaryDateColumn.field}`;
        }

        return DataSourceResourceDelegate.getFactory(dataSource.type).getData(queryParams, dataSource).then(function (json) {
            //TODO: @dannyyassine maybe this ingo via metadata? augmented a property to know if this widget is a report widget
            if (!metadata.dynamic.is_creating && ReportStudioTemplateDataService.getIsActive()) {
                metadata.dynamic.predefined_data = {data: json?.plain ? json.plain() : json, has_comparison_data: !!json.has_comparison_data};

                if (json.total_data) {
                  metadata.dynamic.predefined_data.total_data = json.total_data;
                }

            }
            _widgetDataToFetch--;
            return handleDataCallback(json, metadata, widget);
        }, function(error) {
            widget.metadata.dynamic.warnings = [];
            widget.metadata.dynamic.errors = [];
            delete widget.metadata.dynamic.is_inactive;
            _widgetDataToFetch--;
            return error;
        });
    }

    /**
     * Applies some data massaging to fill other needed properties ({and} data chaing, if any)
     * @param json
     * @param metadata
     * @param widget
     */
    function handleDataCallback(json, metadata, widget) {
        var data = json.plain ? json.plain() : json;

        // The object check is for media widget's data, as it returns a string.
        if (_.isObject(json) && '$$currency_discrepancy' in json) {
            const currency_discrepancy = json.$$currency_discrepancy;
            WidgetCurrencyDataService.setDiscrepancy(widget, currency_discrepancy);
            metadata.currency_discrepancy = currency_discrepancy;
            metadata.show_currency = json.show_currency;
            delete json.$$currency_discrepancy;
        }

        var jsonData = {
            data: data
        };

        // Extract has_comparison_data bool
        if (json.has_comparison_data) {
            jsonData.has_comparison_data = json.has_comparison_data;
        }
        // Extract total if any
        if (json.total_data) {
            jsonData.total_data = json.total_data;
        }

        if (metadata.dynamic) {
            // Store data in object
            metadata.dynamic.raw_data = jsonData;
            metadata.dynamic.warnings = [];
            metadata.dynamic.errors = [];
            delete metadata.dynamic.is_inactive;
            // Extract extra_data if any
            if (json.extra_data) {
                // These variables allow a temporary store of returned entity data to be accessed by other parts of the UI
                metadata.dynamic.predefined_data = {};
                metadata.dynamic.predefined_data.extra_data = json.extra_data;
            }
        }

        return jsonData;
    }

    /**
     * Sets the {fields} query param, if any
     * @param queryParams
     * @param metadata
     * @param extraRequestFields
     */
    function setFieldsQueryParam(queryParams, metadata, extraRequestFields) {
        var selectedColumns = metadata.data_columns.selected;
        if (selectedColumns.length) {
            queryParams.fields = _.map(selectedColumns, 'field').join(',');
            if(ChartUtilFactory.canApplyCountryFilter()) {
                queryParams.fields += ',' + ChartUtilFactory.getCountryColumnName();
            }
            if(ChartUtilFactory.canApplyStateFilter()) {
                queryParams.fields += ',' + ChartUtilFactory.getStateColumnName();
            }
            if (extraRequestFields.length) {
                queryParams.fields += ',' + extraRequestFields.join(',');
            }
        }
    }

    /**
     * Sets the {daterange} query param, if any
     * @param queryParams
     * @param metadata
     */
    function setDateRangeQueryParam(queryParams, metadata, isSparkLine) {
        var dateRangeOverride;
        var comparisonDateRangeOverride;

        var forceComparison = metadata.compare_to_prior_period;
        if (isSparkLine) {
            forceComparison = false;
            var relativeRange = RelativeDateRange.LAST_3_MONTHS;
            if (!WidgetUtilService.isEmbeddedSparklinesPlotType(metadata.draw_options)) {
                if(metadata.sparkline_option === SparklineOptions.LAST_6_MONTHS){
                    relativeRange = RelativeDateRange.LAST_6_MONTHS;
                } else if(metadata.sparkline_option === SparklineOptions.YEAR_TO_DATE){
                    relativeRange = RelativeDateRange.YEAR_TO_DATE;
                }
            } else if(metadata.draw_options.embed_sparkline_date_range === SparklineOptions.LAST_6_MONTHS){
                    relativeRange = RelativeDateRange.LAST_6_MONTHS;
            }

            var range = DateRangeFactory.getDateRangeFromRelativeRange(relativeRange);
            dateRangeOverride = {
                start: range.start.format(MomentDateFormat.ISO),
                end: range.end.format(MomentDateFormat.ISO)
            };
            comparisonDateRangeOverride = {enabled: false};
        }
        else if (WidgetUtilService.isBigNumber(metadata.type) && metadata.draw_options.plot_type === ChartPlotType.COMPARISON) {
           forceComparison = true;
            var relativeRange = RelativeDateRange.LAST_MONTH;
            var relativeDateRangeKey =  RelativeDateRangeKey.PRIOR_PERIOD;
            if(metadata.comparison_option == ComparisonOptions.QUARTER_OVER_QUARTER){
                relativeRange = RelativeDateRange.LAST_QUARTER;
            }
            if(metadata.comparison_option == ComparisonOptions.YEAR_OVER_YEAR){
                relativeRange = RelativeDateRange.YEAR_TO_DATE;
                relativeDateRangeKey = RelativeDateRangeKey.PRIOR_YEAR;
            }
            var range = DateRangeFactory.getDateRangeFromRelativeRange(relativeRange);
            dateRangeOverride = {
                start: range.start.format(MomentDateFormat.ISO),
                end: range.end.format(MomentDateFormat.ISO)
            };
            var period = DateRangeFactory.calculatePeriod(
                dateRangeOverride.start,
                dateRangeOverride.end,
                relativeRange,
               relativeDateRangeKey
            );
            comparisonDateRangeOverride = {
                start: moment.unix(period.start.unix()).format(MomentDateFormat.ISO),
                end: moment.unix(period.end.unix()).format(MomentDateFormat.ISO)
            };
        }
        // Apply any widget date overrides, if any
        else if (metadata.is_overriding_date_range) {
            if (metadata.relative_date_range === RelativeDateRange.CUSTOM) {
                dateRangeOverride = {
                    start: metadata.start_date_override,
                    end: metadata.end_date_override
                };
            }
            else {
                var range = DateRangeFactory.getDateRangeFromRelativeRange(metadata.relative_date_range);
                dateRangeOverride = {
                    start: range.start.format(MomentDateFormat.ISO),
                    end: range.end.format(MomentDateFormat.ISO)
                };
            }

            if (metadata.compare_to_prior_period) {
                if (metadata.comparison_relative_date_range === RelativeDateRange.CUSTOM) {
                    comparisonDateRangeOverride = {
                        start: metadata.comparison_start_date_override,
                        end: metadata.comparison_end_date_override
                    };
                }
                else {
                    var period = DateRangeFactory.calculatePeriod(
                        dateRangeOverride.start,
                        dateRangeOverride.end,
                        metadata.relative_date_range,
                        metadata.comparison_relative_date_range
                    );
                    comparisonDateRangeOverride = {
                        start: moment.unix(period.start.unix()).format(MomentDateFormat.ISO),
                        end: moment.unix(period.end.unix()).format(MomentDateFormat.ISO)
                    };
                }
            }
        }


        var dateRangeQuery = DateRangeFactory.buildDateQueryParam(
            dateRangeOverride,
            comparisonDateRangeOverride,
            forceComparison,
            metadata.is_overriding_date_range
        );
        if (!_.isNull(dateRangeQuery)) {
            queryParams.daterange = dateRangeQuery;
        }
    }

    /**
     * Sets the {filter_set_id} query param, if any
     * NOTE: Filter logic may override queryParams.daterange if a date range filter is present
     * @param queryParams
     * @param metadata
     */
    function setFilterQueryParam(queryParams, metadata) {
        if (metadata.dynamic && !_.isNull(metadata.dynamic.predefined_filters)) {
            var predefinedFilters = metadata.dynamic.predefined_filters;
            // Predefined filters only support key/value pairs (ex: [{'client_id': 5}] => &client_id=5)
            _.each(predefinedFilters, function(value, key) {
                // We cast to string to ensure we can support null as a
                // query param ex: client_id=null since Restangular removes null by default
                queryParams[key] = String(value);
            });
        }
        var filterSetId = metadata.filter_set_id;
        if (!_.isNil(filterSetId)) {
            queryParams.filter_set_id = filterSetId;
        }
    }

    /**
     * Sets the {groupby} query param, if any
     * @param queryParams
     * @param metadata
     * @param drillDownConfig
     * @param extraRequestFields
     */
    function setGroupByQueryParam(queryParams, metadata, drillDownConfig, extraRequestFields) {
        // Needs to be set to null by default since it is used for caching and cannot be undefined
        queryParams.groupby = null;
        var groupedColumns = metadata.data_columns.grouped;
        // Set default drillDownConfig
        drillDownConfig = drillDownConfig || null;
        var isDrillingDown = !_.isNull(drillDownConfig) && drillDownConfig.groupbyIndex > 0;
        var groupedColumnsToProcess = [];
        queryParams.groupby = '';

        //
        // DRILLDOWN LOGIC
        //
        if (isDrillingDown) {
            groupedColumnsToProcess.push(groupedColumns[drillDownConfig.groupbyIndex]);

            // Add selected drill down options to queryParams e.g. {service_id: 52}
            // NOTE: drillDownConfig can also contain queryParams, not to be confused with params passed in the fn signature,
            // allows to conditionally set specific queryParams when drilling
            _.extend(queryParams, drillDownConfig.queryParams);
        }
        else if (!_.isNull(drillDownConfig)) { // Its a chart but we are not drilling (i.e. we are on the first level)
            if (!_.isEmpty(groupedColumns[0])) {
                groupedColumnsToProcess.push(groupedColumns[0]);
            }
        }
        else { // Means this is not a chart and we group by all grouped columns selected (i.e. datagrid)
            groupedColumnsToProcess = groupedColumns;
        }

        _.each(groupedColumnsToProcess, function(groupbyColumn, i) {
            if (!_.isEmpty(groupbyColumn)) {
                if (i > 0) queryParams.groupby += ',';

                if (WidgetUtilService.isGroupByNameMetric(groupbyColumn, metadata)) {
                  queryParams.groupby += groupbyColumn.groupby_name_field;
                } else {
                  queryParams.groupby += groupbyColumn.field;
                }

                // Add associated group by name field to request field list if different from id field
                if (groupbyColumn.groupby_name_field != groupbyColumn.groupby_id_field) {
                    extraRequestFields.push(groupbyColumn.groupby_name_field);
                }
            }
        });
    }

    /**
     * Sets the {timegrouping} query param, if any
     * @param queryParams
     * @param metadata
     * @param drillDownConfig
     */
    function setTimeGroupingQueryParam(queryParams, metadata, drillDownConfig) {
        // Needs to be set to null by default since it is used for caching and cannot be undefined
        queryParams.timegrouping = null;
        var groupedColumns = metadata.data_columns.grouped;
        // Set default drillDownConfig
        drillDownConfig = drillDownConfig || null;
        var timeGroupingObj = _.find(groupedColumns, {is_primary_date_field: true});
        if (!_.isEmpty(timeGroupingObj)) {
            var dateField = timeGroupingObj.field;
            // If we are grouping by primary date field, add the time grouping param (i.e. timegrouping='monthly')
            // Or if we are drilling by the primary date field, do the same
            if (queryParams.groupby.includes(dateField) || (!_.isNull(drillDownConfig) && dateField in drillDownConfig.queryParams)) {
                queryParams.timegrouping = metadata.time_grouping;
                setWeeklyStartDayQueryParam(queryParams, metadata);
            }
        }
    }

    /**
     * @param queryParams
     * @param metadata
     */
    function setWeeklyStartDayQueryParam(queryParams, metadata) {
        if (queryParams.timegrouping === TimeGrouping.GROUPING_WEEKLY
            && ((metadata.weekly_start_day || AppFactory.getUser().getDefaultWeeklyStartDay()) === WeeklyStartDay.MONDAY)) {
            queryParams.week_starts_on_monday = true;
        }
    }

    /**
     * Sets the {sort} query param, if any
     * Allows backend to know API data source call is widget specific
     * @param queryParams
     * @param widget
     */
    function setWidgetRequestQueryParam(queryParams, widget) {
        // Must use the string 'null' since null as a value will be ignored
        // by Restangular and not sent in the API request
        queryParams.widget_request = widget.id || 'null';
        queryParams.widget_page_id = DashboardContextService.resolvePageId();
        queryParams.widget_type = widget.type || 'null';

        if (widget.has_live_integration) {
            queryParams.has_live_integration = widget.has_live_integration;
            if (widget.assignments) {
                queryParams.widget_assignments = widget.assignments;
            }
            if (!_.isEmpty(widget.metadata.live_formula_fields) && !_.isEmpty(widget.metadata.live_formulas)) {
                queryParams.live_formula_fields = widget.metadata.live_formula_fields.join(',');
                queryParams.live_formulas = widget.metadata.live_formulas.join(',');
            }
        }
    }

    /**
     * Sets the {total} query param, if any
     * Returns a total dataset roll up for the given daterange (used for datagrid total row)
     * @param queryParams
     * @param widget
     */
    function setTotalQueryParam(queryParams, widget) {
        var includeTotal = widget.metadata.draw_options[DrawOption.SHOW_TOTAL_ROW] || widget.metadata.draw_options[DrawOption.PERCENT_OF_TOTAL];
        var filters = widget.metadata.dynamic && !_.isEmpty(widget.metadata.dynamic.filters) ? widget.metadata.dynamic.filters : null;
        // Don't include total in queryParams if there is metric filter(s) in data grid
        if (_.some(filters, {is_metric: true})) {
            includeTotal = false;
        }
        if (includeTotal) {
            queryParams.total = includeTotal;
        }
    }

    /**
     * Sets the {sort} query param, if any
     * @param queryParams
     * @param metadata
     */
    function setSortQueryParam(queryParams, metadata) {
        var sortBy = metadata.sort_by;
        var sortOrder = metadata.sort_order;
        const {grouped: groupedColumns, selected: selectedColumns} = metadata.data_columns;
        var timeGroupingObj = _.find(groupedColumns, {is_primary_date_field: true});
        if (!_.isEmpty(sortBy)) {
            if (_.isArray(sortBy) && _.isArray(sortOrder)) {
                var sortArr = [];
                _.each(sortBy, function(field, index) {
                    var order = sortOrder[index];
                    const fieldMetadata = _.find(groupedColumns, {field: field}) || _.find(selectedColumns, {field: field});
                    if(fieldMetadata?.is_sortable) {
                        sortArr.push((order.toLowerCase() === 'desc' ? '-' : '') + field);
                    }
                });
                if(!_.isEmpty(sortArr)){
                    queryParams.sort = sortArr.join(',');
                }
            }
            else {
                console.error('sort_by/order should always be array');
            }
        } else if (!_.isEmpty(timeGroupingObj) && timeGroupingObj.is_sortable) {
            queryParams.sort = timeGroupingObj.field;
        }
    }

    /**
     * Sets the service {type} query params, if any
     * @param queryParams
     * @param metadata
     */
    function setServiceTypeQueryParams(queryParams, metadata) {
        var dataSource = metadata.data_source;
        // Add num entitiy count in extra data set
        if (dataSource.is_of_type_service && dataSource.include_num_entities) {
            queryParams.num_entities = true;
        }
        // Add data freshness date in extra data set
        if (dataSource.is_of_type_service && dataSource.include_data_freshness_date) {
            queryParams.data_freshness_date = true;
        }
    }

    /**
     * Add the query param 'Show Empty Dates' if present in the widget metadata
     * @param queryParams
     * @param metadata
     */
    function setShowEmptyDatesQueryParam(queryParams, metadata) {
        var drawOptions = metadata.draw_options;
        if (drawOptions.show_empty_dates) {
            queryParams.show_empty_dates = true;
        }
    }

    /**
     * Allows to search a point in time (past or future) where data is present
     * @param dateObj
     * @param fields
     * @param sortField
     * @param dataSource
     * @param widget
     */
    function probeData(dateObj, fields, sortField, dataSource, widget) {
        let metadata = widget.metadata;
        let widgetType = widget.type;
        var daterange = AppFactory.getDateRange();
        var sort = sortField;

        // start = 0 ==> end = currentEndDate
        if (_.isUndefined(dateObj.end)) {
            dateObj.end = daterange.end;
            sort = '-' + sort;
        }
        // end = infinite ==> start = currentStartDate
        else if (_.isUndefined(dateObj.start)) {
            dateObj.start = daterange.start;
        }

        // Build query string for probing
        var queryParams = {
            sample: 1,
            fields: fields,
            sort: sort,
            daterange: DateRangeFactory.buildDateQueryParam(dateObj, {enabled: false})
        };

        // Apply filters if any to probe query
        setFilterQueryParam(queryParams, metadata);
        var filterSetId = metadata.filter_set_id;
        if (!_.isNil(filterSetId)) {
            _.each(metadata.dynamic.filters, function(filterColumn) {
                var field = filterColumn.field;
                queryParams.fields += ',' + field;
            });

            if (!_.isEmpty(metadata.data_columns.grouped)) {
                // Need to get active grouped columns for a given widget type
                var groupedColumns = WidgetFactory.getActiveGroupedColumns(metadata, widgetType);

                queryParams.groupby = _.map(groupedColumns, function(column) {
                    return column.field;
                }).join(',');

                // if grouping by time, include timegrouping
                if (_.some(groupedColumns, 'is_primary_date_field')) {
                    queryParams.timegrouping = metadata.time_grouping;
                }
            }
            else {
                queryParams.aggregate = true;
            }
        }

        setWidgetRequestQueryParam(queryParams, widget);

        return DataSourceResourceDelegate.getFactory(dataSource.type).getData(queryParams, dataSource).then(function (json) {
            return json.plain();
        });

    }

    /**
     * Return datasource specific datatable options (ex: Leads will render some datatable fields differently)
     * @param dataSource
     * @returns {*|{}}
     */
    function getDTOptions(dataSource) {
        var factory = DataSourceDataGridFactoryDelegate.getFactory(dataSource.type);
        return _.isNull(factory) ? {} : factory.getDTOptions();
    }

    /**
     * Tells us if ALL widgets data requests have returned
     * @returns {boolean}
     */
    function isAllDataLoaded() {
        return _widgetDataToFetch === 0;
    }

    return {
        getData: getData,
        probeData: probeData,
        getDTOptions: getDTOptions,
        isAllDataLoaded: isAllDataLoaded,
        setTotalQueryParam: setTotalQueryParam,
        setDateRangeQueryParam: setDateRangeQueryParam
    };
}

/**
 * @ngInject
 */
function WidgetCurrencyDataService() {

    /**
     * @type {Object.<string, boolean>}
     */
    let widgetMap = {};

    return {
        setDiscrepancy,
        getDiscrepancy,
        clearDiscrepancy,
        hasDiscrepancy,
        clearAll
    };

    /**
     * @param {Object} widget
     * @param {boolean} discrepancy
     */
    function setDiscrepancy(widget, discrepancy) {
        widgetMap[widget.id] = discrepancy
    }

    function getDiscrepancy(widget) {
        return !!widgetMap[widget.id];
    }

    function clearDiscrepancy(widget) {
        delete widgetMap[widget.id];
    }

    function hasDiscrepancy() {
        return !_.isEmpty(widgetMap);
    }

    function clearAll() {
        widgetMap = {};
    }
}
