import { Scrollbar } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function useScrollbar(context) {
  const { chart, root } = context();

  function createScrollbar() {
    createScrollbarX();
    createScrollbarY();
  }

  function createScrollbarX() {
    const scrollbarX = Scrollbar.new(root.value, {
      orientation: Constant.HORIZONTAL,
    });
    chart.value.set(Constant.SCROLLBAR_X, scrollbarX);
    chart.value.bottomAxesContainer.children.push(scrollbarX);
  }

  function createScrollbarY() {
    const scrollbarY = Scrollbar.new(root.value, {
      orientation: Constant.VERTICAL,
    });
    chart.value.set(Constant.SCROLLBAR_Y, scrollbarY);
    chart.value.rightAxesContainer.children.push(scrollbarY);
  }

  return {
    createScrollbar,
    createScrollbarX,
  };
}
