'use strict';
import angular from 'angular';
import _ from 'lodash';
import './scripts/category.ctrls';
import './scripts/category.directives';
import './scripts/category.services';

import './overview/category.overview.ctrls';

import categoryListHtmlUrl from './views/categorylist.html';
import categoryHtmlUrl from './views/category.html';
import categoryDataViewHtmlUrl from './views/categorydataview.html';
import categoryMetricHtmlUrl from './views/categorymetric.html';
import categoriesoverviewHtmlUrl from './overview/categoriesoverview.html';
import $ from 'jquery';

angular.module('core.category', [
    'core.category.ctrls',
    'core.category.directives',
    'core.category.services',
    'category.overview.ctrls'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        var dashfilters = {
            templateUrl: AppConfig.MAGE_URL + 'layout/dashfilters',
            controller: 'DashFiltersController'
        };

        $stateProvider
            .state('category', {
                parent: 'layout',
                url: '/category/details/:id',
                controller: 'ServiceDetailController',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'index/details/category/' + $stateParams.id;
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })
            .state('category.metrics', {
                parent: 'layout',
                url: '/category/details/:category_id/customer_id/:customer_id',
                controller: 'ServiceDetailController',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'metricgrid/view/category/' + $stateParams.category_id + '/customer_id/' + $stateParams.customer_id;
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })
            .state('category.dataview', {
                parent: 'layout',
                url: '/category/details/:category_id/customer_id/:customer_id/dataview/:dataview_id/campaign/:campaign_id/service/:service_id',
                controller: 'ServiceDetailController',
                views: {
                    '': {
                        templateUrl: function ($stateParams) {
                            return AppConfig.MAGE_URL + 'metricgrid/view/category/' + $stateParams.category_id + '/customer_id/' + $stateParams.customer_id +
                                '/dataview/' + $stateParams.dataview_id + '/campaign/' + $stateParams.campaign_id + '/service/' + $stateParams.service_id;
                        }
                    },
                    'dashfilters@layout': dashfilters
                }
            })
            .state('categoriesoverview', {
                parent: 'layout',
                url: '/categoriesoverview',
                controller: 'DesignController',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('categoriesoverview'),
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_CATEGORIES_OVERVIEW;
                    },
                    vars: function() {
                        return null;
                    }
                },
                templateUrl: categoriesoverviewHtmlUrl,
            })
            .state('categories', {
                parent: 'layout',
                url: '/categories',
                controller: 'CategoryListController',
                templateUrl: categoryListHtmlUrl,
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('categories'),
                    data: function(CategoryFactory){
                        return CategoryFactory.categories.getList({summary: true, all: true, extra: true});
                    },
                    metadata: function(CategoryFactory){
                        return CategoryFactory.categories.getList({summary: true, metadata: true});
                    }
                }
            })
            .state('categories.detail', {
                parent: 'layout',
                url: '/categories/detail/:id',
                controller: 'CategoryController',
                templateUrl: categoryHtmlUrl,
                resolve: {
                    category: function($stateParams, CategoryFactory) {
                        return $stateParams.id ? CategoryFactory.categories.one($stateParams.id).get({all: true}) : null;
                    },
                    metrics: function($stateParams, CategoryMetricFactory) {
                        return CategoryMetricFactory.metrics(($stateParams.id || 0)).getList({all: true, summary: true, extra: true});
                    },
                    metricMetadata: function($stateParams, CategoryMetricFactory) {
                        return CategoryMetricFactory.metrics(($stateParams.id || 0)).getList({summary: true, metadata: true});
                    },
                    dataViews: function($stateParams, CategoryDataViewFactory) {
                        return CategoryDataViewFactory.categoryDataView(($stateParams.id || 0)).getList({all: true, summary: true, extra: true});
                    },
                    dataViewMetadata: function($stateParams, CategoryDataViewFactory) {
                        return CategoryDataViewFactory.categoryDataView(($stateParams.id || 0)).getList({summary: true, metadata: true});
                    }
                },
            })
            .state('categories.dataviews', {
                parent: 'layout',
                url: '/categories/detail/:categoryId/dataviews/:dataviewId',
                controller: 'CategoryDataViewController',
                templateUrl: categoryDataViewHtmlUrl,
                resolve: {
                    category: function($stateParams, CategoryFactory){
                        return CategoryFactory.categories.one($stateParams.categoryId).get({all: true})
                    },
                    categoryDataView: function($stateParams, CategoryDataViewFactory){
                        return $stateParams.dataviewId ? CategoryDataViewFactory.categoryDataView($stateParams.categoryId).one($stateParams.dataviewId).get({all: true}) : null;
                    },
                    categoryDataViews: function($stateParams, category, CategoryDataViewFactory){
                        return CategoryDataViewFactory.categoryDataViews(_.map(category.service_ids, function(val,key){return key;})).getList();
                    }
                }
            })
            .state('categories.metric', {
                parent: 'layout',
                url: '/categories/detail/:categoryId/metrics/:metricId?dataview',
                controller: 'CategoryMetricController',
                templateUrl: categoryMetricHtmlUrl,
                resolve: {
                    category: function($stateParams, CategoryFactory){
                        return CategoryFactory.categories.one($stateParams.categoryId).get({all: true})
                    },
                    categoryDataView: function($stateParams, CategoryDataViewFactory){
                        return $stateParams.dataview ? CategoryDataViewFactory.categoryDataView($stateParams.categoryId).one($stateParams.dataview).get({all: true}) : null;
                    },
                    metric: function($stateParams, CategoryMetricFactory){
                        return $stateParams.metricId ? CategoryMetricFactory.metrics($stateParams.categoryId).one($stateParams.metricId).get({all: true, extra: true}) : null;
                    },
                    metricColumns: function($stateParams, CategoryDataViewFactory) {
                        return CategoryDataViewFactory.columns($stateParams.categoryId, $stateParams.dataview).one().get({is_numeric_metric: true});
                    },
                    attributeColumns: function($stateParams, CategoryDataViewFactory) {
                        return CategoryDataViewFactory.columns($stateParams.categoryId, $stateParams.dataview).one().get({is_numeric_metric: false});
                    },
                    dataTypes: function($stateParams, CategoryMetricFactory) {
                        return CategoryMetricFactory.dataTypes().get();
                    },
                }
            })
    }])
