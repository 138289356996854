'use strict';
import $ from 'jquery';
import DevTools from './devtools';
var Core = $.core;

var SeleniumHelper = {
    addSelectClasses: addSelectClasses
}

// Helper function to add class names to select2 directive.
function addSelectClasses(selectOptions, className) {
    if (DevTools.isEnabled()) {
        selectOptions.containerCssClass = className + '-select';
        selectOptions.dropwdownCssClass = className + '-select-dropdown';
        selectOptions.formatResultCssClass = function(obj) {
            return className + '-select-item-' + (obj.id || obj.text);
        }
    }
}

export default SeleniumHelper;