import angular from 'angular';
import './exportbuilder.dashboard.models';
import './exportbuilder.dashboard.services';
import './exportbuilder.dashboard.components';
import './exportbuilder.dashboard.directives';
import './exportbuilder.dashboard.util.services';
import '../item/exportbuilder.item.services';
import '../../dashboard/page/exportbuilder.dashboard.page.services';

angular.module('exportbuilder.dashboard', [
    'exportbuilder.dashboard.models',
    'exportbuilder.dashboard.services',
    'exportbuilder.dashboard.components',
    'exportbuilder.dashboard.directives',

    'exportbuilder.dashboard.util.services',
    'exportbuilder.element.services',
    'exportbuilder.page.services',
    'exportbuilder.sync.services'
]);

import '../item/exportbuilder.item.actions.services';
import '../undo/exportbuilder.undo.services';
import '../undo/exportbuilder.undo.models';
import './exportbuilder.dashboard.sync.services';