import { assign } from 'lodash';
import RoleResource from '@/modules/ta/role/resources/RoleResource';
import { BaseListService } from '@/modules/core/list/services/BaseListService';

export class RoleListDataService extends BaseListService {
  constructor() {
    super(RoleResource);
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  async getData(params) {
    params = assign({ extra: true }, params);

    return super.getData(params);
  }

  /**
   * @returns {Promise<*>}
   */
  async getUserTypeModules() {
    return RoleResource.getUserTypeModules();
  }

  /**
   * @returns {Promise<*>}
   */
  async setIsSelectable(id, value) {
    return RoleResource.setIsSelectable(id, value);
  }

  /**
   * @param roleId
   * @returns {Promise<*>}
   */
  async getUsages(roleId) {
    return this.resource.getUsages(roleId);
  }

  /**
   * @returns {Promise<*>}
   */
  async updateTwoStepVerification(id, value) {
    return RoleResource.updateTwoStepVerification(id, value);
  }
}

export default new RoleListDataService();
