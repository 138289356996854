'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import widgetCreateTypesHtmlUrl from './widget.create.types.html';

angular.module('widget.create.directives')

    .directive('widgetTypeSelectionList', widgetTypeSelectionList)
    .directive('widgetTypeSelectionItem', widgetTypeSelectionItem);

function widgetTypeSelectionList() {
    return {
        restrict: 'E',
        controller: 'WidgetTypeSelectionListController',
        templateUrl: widgetCreateTypesHtmlUrl,
        scope: {
            type: '=', //model.type
            drawOptions: '=', //model.metadata.draw_options
            datasource: '<',
            widgetTypes: '<',
            state: '='
        }
    }
}

/**
 * Widget type item selection
 * @ngInject
 */
function widgetTypeSelectionItem(
    $timeout,
    WidgetTypeGrouping,
    WidgetType,
    gettextCatalog
) {
    return {
        restrict: 'A',
        controller: 'WidgetTypeSelectionItemController',
        scope: {
            widgetType: '=', // Current widget type in the list of widgets
            state: '=',
            datasource: '<',
        },
        link: function(scope, el) {

            var typeGroupings = scope.widgetType.type_groupings;
            var isChartWidget =  _.includes(typeGroupings, WidgetTypeGrouping.CHART);

            scope.setTooltip = function() {
                var title = scope.widgetType.name;

                // If widget type is disabled because of current state of
                // grouped by and selected columns we have to change instructions
                if (scope.widgetType.is_disabled) {
                    if (!scope.widgetType.can_group_by) {
                        if (scope.widgetType.id === WidgetType.GAUGECHART && scope.state.numSelectedColumns < 2) {
                            if (scope.state.groupByColumnSelected === 0) {
                                title = gettextCatalog.getString('To enable the {{name}}, you need to select two data columns.', {name: scope.widgetType.name});
                            } else {
                                title = gettextCatalog.getString('{{name}}s cannot have grouped data. Please remove the grouped by column and select two data columns to re-enable it.', {name: scope.widgetType.name});
                            }
                        } else {
                            title = gettextCatalog.getString('{{name}}s cannot have grouped data. Please remove the grouped by column to re-enable it.', {name: scope.widgetType.name});
                        }
                    } else if (scope.state.groupByColumnSelected > 2) {
                        title = gettextCatalog.getString('{{name}}s cannot have more than 2 grouped columns. Please remove grouped columns to re-enable it.', {name: scope.widgetType.name});
                    } else if (isChartWidget) {
                        title = gettextCatalog.getString('To enable the {{name}}, you need to select a column to group data by, which is located on the bottom left.', {name: scope.widgetType.name});
                    } else if (scope.widgetType.id === WidgetType.DATAGRID) {
                        if (scope.datasource.requires_group_by) {
                            title = gettextCatalog.getString('To enable the {{name}}, you need to select a column to group data by, which is located on the bottom left.', {name: scope.widgetType.name});
                        }
                    }
                }

                if ($(el).attr('data-original-title')) {
                    $(el).tooltip('destroy');
                }

                $(el).attr('title', title);

                $timeout(function () {
                    $(el).tooltip();
                }, 250, false);
            };
        }
    }
}