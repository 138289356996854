'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';

// seperate to load this module alone for unit testing
angular.module('core.select2.constants', [])
    .constant('$UISelect2Events', $EventConstants());

angular.module('core.select2.directives', [
    'core.select2.constants'
])
    .component('uiSelect2', uiSelect2())
    .directive('select2', select2);

function $EventConstants() {
    return {
        OTHER_CHANGE_CALLBACK: 'uiSelect2:OTHER_CHANGE_CALLBACK',
        CLEAR: 'uiSelect2:CLEAR',
        RESET: 'uiSelect2:RESET',
        CLOSE: 'uiSelect2:CLOSE'
    };
}

function uiSelect2() {
    return {
        template: '<input disabled="disabled" class="form-control" ng-class="vm.getClasses()" placeholder="{{vm.getPlaceholder()}}" />',
        bindings: {
            values: '<',
            options: '<',
            onTextChangeCallback: '<',
            onChangeCallback: '<',
            onSelected: '<',
            onOpen: '<',
            onClose: '<',
            resetFnOverride: '<',
            selectedValues: '=',
            onInputFocus: '<'
        },
        replace: true,
        controllerAs: 'vm',
        controller: UISelect2Controller
    };
}

/**
 *
 * @ngInject
 */
function UISelect2Controller(
    $scope,
    $timeout,
    $element,
    $document,
    UIFactory,
    PubSub,
    gettextCatalog
) {
    var vm = this;
    var _data = [];
    var _isInitialized = false;
    var _multiSelectKeys = false;
    var _query = null;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onChanges = $onChanges;
    vm.$onDestroy = $onDestroy;
    vm.getClasses = getClasses;
    vm.getPlaceholder = getPlaceholder;

    function $onInit() {
        /**
         * @type {SelectOptions}
         */
        vm.values = vm.values || [];
        vm.onChangeCallback = vm.onChangeCallback || null;
    }

    function $postLink() {
        $element.on('change', function(event) {
            _triggerChange(event);
        });

        $element.on('select2-focus', function () {
            vm.onInputFocus && vm.onInputFocus();
        });

        _autoFocusIfNeeded();
    }

    function $onChanges(changes) {
        if (changes.values || vm.options.tooMuchDataCallback) {
            var values = changes.values.currentValue;
            _data = _.map(values, function (item) {
                // Data contains option groups
                if ('children' in item) {
                    vm.options.isGrouped = true;
                    return item;
                }
                // If object is a tuple then we assume first prop is id and second is text
                else if (_.isObject(item) && _.keys(item).length === 2) {
                    // 'key' is needed for LiveIntegrations.
                    return {id: item[_.keys(item)[0]], text: item[_.keys(item)[1]], key: item[_.keys(item)[0]]};
                }
                else {
                    return item;
                }
            });

            if (vm.options.rebuild) {
                _isInitialized = false;
                $element.select2('data', null); // to clear current selection if any
                vm.options.rebuild = false;
            }
            // select2 must have selectable values to select in order to initialize
            if (values && values.length) {
                // Need to massage values to fit object property structure required by select2
                if (vm.options.hasMoreData && vm.options.tooMuchDataCallback) {
                    // IMPORTANT: tooMuchDataCallback MUST check for minimum query.length input
                    _query = _.debounce(vm.options.tooMuchDataCallback, 400);
                }

                if (!_isInitialized) {
                    // Only initialize select2 once data is returned
                    _isInitialized = true;

                    _init();
                }
            }
        }
        // select2 without any selectable values would get stuck on loading
        if (changes.values) {
            if (!_isInitialized && _.isEmpty(changes.values.currentValue) && vm.options.loaded) {
                _isInitialized = true;
                _init();
            }
        }
        if (vm.options.rebuild) {
            $element.select2('data', null); // to clear current selection if any
            $element.select2('destroy');
            vm.options.rebuild = false;

            _init();
        }

        if (changes.options) {
            if (changes.options.currentValue && changes.options.currentValue.loaded) {
                $element.addClass('ui-select2-loaded');
            }
        }
        _autoFocusIfNeeded();
    }

    function getClasses() {
        const classes = [];

        if (!vm.options.loaded) {
            classes.push('select2-loading');
        }

        return classes;
    }

    function getPlaceholder() {
        return vm.options.loaded
            ? gettextCatalog.getString('No values found')
            : gettextCatalog.getString('Loading values')
    }

    function _init() {
        var dataOptions = {
            minimumInputLength: _data.length > 500 ? 2 : null
        };

        if (_query) {
            dataOptions.query = _query;
        }

        if (vm.options.tags === true || !_.isEmpty(vm.options.tags)) {
            vm.options.tags = _data;
        } else {
            dataOptions.data = function () {
                return {results: _data};
            }
        }

        dataOptions = _.assign(dataOptions, vm.options);
        _.assign(vm.options, dataOptions);

        $element.select2(dataOptions);

        $element.select2('enable', !vm.options.disabled);

        // Allow reordering of selected values
        if (vm.options.multiple && vm.options.isSortable) {
            _setAsSortable();
        }

        // Multiple selection should be passed as an array, single value should be passed as an object
        if (!_.isEmpty(vm.selectedValues)) {
            $element.select2('data', vm.selectedValues);
        }

        $element.on('select2-removed', function() {
            if (_.isEmpty($element.select2('data')) && !vm.options.multiple) {
                $scope.$emit($EventConstants().CLEAR);

                // If onClear function is specified in options, execute it
                if (vm.options.onClear) {
                    vm.options.onClear();
                }
            }
        });

        $element.on('select2-selecting', function(event) {
            vm.onSelected && vm.onSelected(event);

            if (vm.options.acceptWildcard && !vm.options.relaxMatch) {
                var matched = false;
                _.each(_data, function(item) {
                    if (_.isObject(item)) {
                        if (event.choice && item.text.toLowerCase() === event.choice.text.toLowerCase()) {
                            matched = true;
                            return false; // break loop
                        }
                    }
                });

                var showWarning = false;
                // Reject entry if text is not matched and doesn't contain '*'
                if (!matched && event.choice && event.choice.text.indexOf('*') < 0) {
                    showWarning = true;
                }

                if (showWarning) {
                    UIFactory.notify.showWarning(gettextCatalog.getString('No exact match for {{label}} \'{{text}}\'. Please use wildcard search to filter more results.',
                        {label: vm.options.label, text: event.choice.text}));
                    event.preventDefault();
                    return;
                }
            }

            // only allow add-all and multi-select when multiple values are allowed
            if (vm.options.multiple) {
                if (_multiSelectKeys) {
                    _multiSelect(event);
                }
            }
        });

        PubSub.on($EventConstants().CLOSE, function () {
            $element.select2('close');
        });

        if (vm.onTextChangeCallback || vm.options.onTextChange) {
            if (vm.options.multiple) {
                $('input.select2-input').on('change paste keyup', _onTextChangeEvent);
            } else {
                $('.select2-search input').on('change paste keyup', _onTextChangeEvent);
            }
        }

        /**
         * Private handler when input text changes
         * @param event
         * @private
         */
        function _onTextChangeEvent(event) {
            vm.options.onTextChange && vm.options.onTextChange($element, _data, event.currentTarget.value, vm.options);
            vm.onTextChangeCallback && vm.onTextChangeCallback(event.currentTarget.value);
        }

        $element.on('select2-open', function () {
            vm.onOpen && vm.onOpen();
            _registerEvents();
        });

        $element.on('select2-close', function () {
            vm.onClose && vm.onClose();
            _unregisterEvents();
        });
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _autoFocusIfNeeded() {
        if (vm.options.autofocus) {
            $timeout(function () {
                $element.select2('open');
                $element.select2('positionDropdown');
            }, 0);
        }
    }

    function _setAsSortable() {
        $element.select2('container').find('ul.select2-choices').sortable({
            containment: 'parent',
            start: function () {
                $element.select2('onSortStart');
                vm.options.onSortStart && vm.options.onSortStart();
            },
            update: function () {
                $element.select2('onSortEnd');
                vm.options.onSortEnd && vm.options.onSortEnd();
            }
        });
    }

    /**
     * Override to manually update and manipulate select2 to achieve multiselect
     * on holding SHIFT-CMD
     * @private
     */
    var _multiSelect = function (event) {
        event.preventDefault();

        // get data
        var data = $element.select2('data');
        if (!data) {
            data = [];
        }

        data.push(event.object);

        // Edge case: keep track of search string in case user decides
        // to do a SelectAll while select2 is still open
        var searchText = $element.data('select2').search.val();

        // manually update the data
        $element.select2('data', data);

        $element.data('select2').search.val(searchText);

        // manually update the position on the dropdown
        $element.select2('positionDropdown');

        // trigger change callback
        // override event.val to be an array of selected values
        event.val = _.map(data, function (item) {
            return item.id
        });
        _triggerChange(event);
    };

    /**
     * Override to manually select all elements in dropdown,
     *   update and manipulate select2 to achieve while holding down SHIFT+CMD|CTRL+A && click
     * @param event
     * @private
     */
    var _applySelectAll = function (event) {
        event.preventDefault();

        let data = [];

        // If data is grouped we need to extract the children
        if (vm.options.isGrouped) {
            data = _.reduce(_data, function(acc, value) {
                acc = acc.concat(value.children);
                return acc;
            }, []);
        } else {
            data = angular.copy(_data);
        }

        var search = $element.data('select2').search.val();

        data = _.filter(data, function (object) {
            return object.text ? object.text.contains(search) : object.contains(search);
        });

        // Useful property to have
        _.each(data, function (object, i) {
            object.index = i;
        });

        // manually update the data
        $element.select2('data', data);

        // force close when select all
        $element.select2('close');

        // trigger change callback
        // override event.val to be an array of seleceted values
        event.val = _.map(data, function (item) {
            return item.id
        });
        _triggerChange(event);
    };

    /**
     * @param event
     * @private
     */
    function _triggerChange(event) {
        $scope.$evalAsync(function() {

            vm.selectedValues = $element.select2('data');

            if (!_.isNull(vm.onChangeCallback)) {
                // If you listen to other select2s than you will also listen
                // to yourself hence no need to call vm.onChangeCallback (would be redundant)
                if (vm.options.listenToOtherSelect2) {
                    $scope.$root.$broadcast($EventConstants().OTHER_CHANGE_CALLBACK, vm.options.key);
                }
                else {
                    vm.onChangeCallback($element, event);
                }
            }
        });
    }

    /**
     * Detect if SHIFT+CMD/CTRL are pressed in order to enable multi-select when clicking
     * @param event Browser keyboard event
     * @private
     */
    function _keyUpKeyDownHandler(event) {
        if (!vm.options.multiple) {
            return;
        }

        _multiSelectKeys  = event.ctrlKey || event.metaKey;
        var addAllKeys = _multiSelectKeys && event.keyCode === 65;

        if (_multiSelectKeys && addAllKeys) {
            event.preventDefault(); // to override any browser special combos like Safari
            _applySelectAll(event);
        }
    }

    // Option to register to other select2 components' on change events
    if (!_.isNull(vm.onChangeCallback)
        && vm.options
        && vm.options.listenToOtherSelect2) {
        $scope.$on($EventConstants().OTHER_CHANGE_CALLBACK, function (e, key) {
            vm.onChangeCallback(key, $element);
        });
    }

    $scope.$on($EventConstants().RESET, function (e) {
        if (vm.resetFnOverride) {
            vm.resetFnOverride(e, $element);
        }
        else {
            $element.select2('data', null);
        }
    });

    function _registerEvents() {
        $document.bind('keyup keydown', _keyUpKeyDownHandler);
    }

    function _unregisterEvents() {
        $document.unbind('keyup keydown', _keyUpKeyDownHandler);
    }

}

/**
 * Apply select2 to element with ng-model data
 * @ngInject
 */
function select2(AppFactory, gettextCatalog) {
    return {
        restrict: 'A',
        scope: {
            options: '=select2',
            selectedValues: '=', // Pre selected values for select2, should be an array for the select2 to work
            updateSelectedValues: '&' // pass as: updateSelectedValues="callback(selectedValues)"
        },
        link: function(scope, el, attrs) {

            // Default options
            scope.options = scope.options || {};
            scope.options.placeholder = scope.options.placeholder || gettextCatalog.getString('Select...');

            if (scope.options) {
                var selectedValues = [];
                var addFormatResult = function() {
                    if (scope.options.includeServiceIcons) {
                        scope.options.formatResult = function (item) {
                            if (!item.id) { return item.text; }

                            return $('<div><div class="service-square service-square-24" style="background-color:' + item.color + '"><div class="icon ' + item.icon + '"></div></div> ' + item.text + '</div>');
                        };
                    }
                };

                addFormatResult();

                el.select2(scope.options);
                var multipleSelect = scope.options.multiple;

                // NOTE: Select 2 can handle an array of id's, or an array of objects with only id's passed for the selected value
                // Ex: [1,2,3] OR [{id: 1},{id: 2},{id: 3}]
                if (attrs.selectedValues) {
                    var checkFormattedSelect = _.some(scope.selectedValues, function(select) {
                        return select.id == undefined || select.text == undefined
                    });

                    if (checkFormattedSelect) {
                        var selectValues = scope.options.data;
                        var mappedSelectValues = AppFactory.arrayToMemoizedObj(selectValues, 'id');
                        if (multipleSelect) {
                            selectedValues = _.map(scope.selectedValues, function(selectValue) {
                                var selectedId = _.isUndefined(selectValue.id) ? selectValue : selectValue.id;
                                return mappedSelectValues[selectedId];
                            });
                        }
                        else {
                            var selectedId = _.isUndefined(scope.selectedValues.id) ? scope.selectedValues : scope.selectedValues.id;
                            selectedValues = mappedSelectValues[selectedId];
                        }
                    }
                    else {
                        selectedValues = scope.selectedValues;
                    }
                }
                else {
                    selectedValues = multipleSelect ? [] : {};
                }

                el.select2('data', selectedValues);
                var originalVal = selectedValues;

                scope.$watch('selectedValues', function(nV, oV){
                    if (nV && !_.isEmpty(nV)) {
                        if(oV && (typeof nV === typeof oV)) {
                            if(!nV.length || ((nV instanceof Array && originalVal instanceof Array) && nV.equals(originalVal))) {
                                el.select2('data', nV);
                            }
                        }
                        if (!oV && !originalVal) {
                            el.select2('data', nV);
                        }
                    }
                    else {
                        el.select2('data', '');
                    }
                });

                el.on('change', function(){
                    if (attrs.updateSelectedValues) {
                        scope.updateSelectedValues({selectedValues: el.select2('data')});
                    }
                    else {
                        // Note: This will not update parent scope.
                        // It is highly recommended to pass a updateSelectedValues variable in the select2directive as:
                        // updateSelectedValues="callback(selectedValues)"
                        scope.selectedValues = el.select2('data');
                    }
                    scope.$apply();
                });

                scope.$watch('options', function(nV, oV){
                    if (JSON.stringify(nV) !== JSON.stringify(oV)) {
                        addFormatResult();
                        el.select2(scope.options);
                        if (scope.selectedValues){
                            el.select2('data', scope.selectedValues);
                            originalVal = scope.selectedValues;
                        }
                    }
                });
            }
        }
    }
}
