import { map, assign, isObject } from 'lodash';
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { DownloadUtil } from '@/modules/core/app/utils/DownloadUtil';

export class BaseResource {
  /**
   * Represents the base route of an API module endpoint
   *
   * @type {RouteGroup}
   */
  routeGroup;

  /**
   * Represents axiosInstance for the API
   *
   * @type {AxiosInstance}
   */
  apiHelper;

  /**
   * @param routeGroup
   * @param apiHelper
   */
  constructor(routeGroup, apiHelper = apiAxios) {
    this.routeGroup = routeGroup;
    this.apiHelper = apiHelper;
  }

  /**
   * @param model
   * @param route
   * @param params
   * @param config
   * @returns {*}
   */
  async getData(model, route, params = {}, config = {}) {
    const data = await this.apiHelper.get(this._buildRoute(route), { params, ...config });
    return this.extractData(data, model, params);
  }

  /**
   * @param data
   * @param model
   * @param params
   * @returns {*}
   */
  extractData(data, model, params) {
    if (data === null) {
      return data;
    }
    if (params.datatable && data?.aaData) {
      data.aaData = this._mapModel(model, data.aaData);
    } else if (params.datatable && data?.data?.aaData) {
      data.aaData = this._mapModel(model, data.data.aaData);
    } else if (Array.isArray(data.values)) {
      data.values = map(data.values, (value) => this._mapModel(model, value));
    } else if (isObject(data)) {
      data = this._mapModel(model, data);
    }
    return data;
  }

  /**
   * @param data
   * @param route
   * @param config
   * @returns {*}
   */
  async postData(data, route, config = {}) {
    return this.apiHelper.post(this._buildRoute(route), data, config);
  }

  /**
   * @param data
   * @param route
   * @param config
   * @returns {*}
   */
  async putData(data, route, config = {}) {
    return this.apiHelper.put(this._buildRoute(route), data, config);
  }

  /**
   * @param route
   * @param config
   * @returns {*}
   */
  async deleteData(route, config = {}) {
    return this.apiHelper.delete(this._buildRoute(route), config);
  }

  /**
   * @param route
   * @param params
   * @param config
   * @returns {*}
   */
  async getColumns(route, params = {}, config = {}) {
    params = assign({ metadata: true }, params);
    return this.apiHelper.get(this._buildRoute(route), { params, ...config });
  }

  /**
   * @protected
   *
   * Show downloaded file in browser
   * @param response
   */
  showDownload(response) {
    const responseUrl = window.URL.createObjectURL(
      new Blob([response.data], {
        type: response.data?.type,
      })
    );
    const contentDisposition = response.headers['content-disposition'];
    const fileName = contentDisposition ? contentDisposition.match(/=["]*([^"]+)["]*/)[1] : 'List';
    DownloadUtil.download(responseUrl, fileName);
  }

  /**
   * Download Json content as a file.
   * @param response
   * @param fileName
   */
  downloadJson(response, fileName) {
    const output = `data:text/' + type + ';charset=utf-8, ${JSON.stringify(response, null, 2)}`;
    let encodedUri = encodeURI(output);
    encodedUri = encodedUri.replace('#', '%23');
    fileName += `.json`;
    DownloadUtil.download(encodedUri, fileName);
  }

  /**
   * @param route
   * @returns {*}
   * @protected
   */
  _buildRoute(route) {
    return route ? `${this.routeGroup}/${route}` : this.routeGroup;
  }

  /**
   * @param model
   * @param data
   * @returns {Array}
   * @private
   */
  _mapModel(model, data) {
    if (data instanceof Array) {
      return map(data, (datum) => model.factory(datum));
    }
    return model.factory(data);
  }
}
