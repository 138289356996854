import $ from 'jquery';
// Expose a global object for use in guidance tools like WalkMe
window._tapWalkMe = {};

//Carries all the view data objects (ex: $.core.dashboard)
$.core = {};

$.core.setAppPath = function (path) {
    var envMode = window.location.pathname.contains('/app/') ? 'app/' : 'dist/';
    return '/client/analytics/' + envMode + path;
};

$.core.setCorePath = function (path) {
    var envMode = window.location.pathname.contains('/app/') ? 'app/' : 'dist/';
    return '/client/core/' + envMode + path;
};

$.core.getAnalyticsAssetPath = function (path) {
    let url = '/client/analytics/dist/' + path;
    return _.isEmpty(ASSET_DOMAIN) ? url : ASSET_DOMAIN + url;
}

//Initialize all high level core objects
$.core.dashboard = {};
$.core.services = {};
$.core.reporting = {};
$.core.admin = {};
$.core.chart = {};

//globals javascript variables
$.globals = {};

//Dashboard types
$.globals.dashboard = {};
$.globals.dashboard.overview = 'overview';
$.globals.dashboard.leads = 'leads';

//Service connection types
$.globals.service = {};
$.globals.service.oauth = 1;
$.globals.service.simple = 2;


//Notify style types
$.globals.notify = {};
$.globals.notify.base = 'base';
$.globals.notify.success = 'success';
$.globals.notify.warning = 'warning';
$.globals.notify.info = 'info';
$.globals.notify.error = 'error';

//Util Mage variables (note: they are set in the dashfoot.phtml file)
$.globals.utils = {};

$.globals.user = {};
$.globals.user.type = {};
$.globals.user.type.superadmin = 'superadmin';
$.globals.user.type.clusteradmin = 'cluster_admin';
$.globals.user.type.agent = 'agent';
$.globals.user.type.client = 'client';


$.globals.themes = {};
$.globals.themes.light = ['bright', 'mint', 'velvet', 'hoth', 'customlight'];
$.globals.themes.dark = ['azure', 'midas', 'leather', 'eggplant', 'rivendell', 'teal', 'customdark'];
$.globals.themes.hybrid = ['classic', 'customhybrid'];

//Custom widget variables
$.globals.widget = {};
$.globals.widget.type = {};
$.globals.widget.type.paragraph = 'paragraph';
$.globals.widget.type.bignumber = 'bignumber';
$.globals.widget.type.linechart = 'linechart';
$.globals.widget.type.barchart = 'barchart';
$.globals.widget.type.bubblechart = 'bubblechart';
$.globals.widget.type.gaugechart = 'gaugechart';
$.globals.widget.type.piechart = 'piechart';
$.globals.widget.type.topperformers = 'topperformers';
$.globals.widget.type.geochart = 'geochart';
$.globals.widget.type.funnelchart = 'funnelchart';
$.globals.widget.type.normalizedfunnelchart = 'normalizedfunnelchart';
$.globals.widget.type.areachart = 'areachart';
$.globals.widget.type.combinationchart = 'combinationchart';
$.globals.widget.type.stackedbarchart = 'stackedbarchart';
$.globals.widget.type.gaugechart = 'gaugechart';


$.globals.widget.timegrouping = {};
$.globals.widget.timegrouping.day = 'day';
$.globals.widget.timegrouping.month = 'month';
$.globals.widget.timegrouping.year = 'year';

//Custom chart variables
$.globals.chart = {};
$.globals.chart.type = {};
$.globals.chart.type.pie = 'pie';
$.globals.chart.type.bar = 'bar';
$.globals.chart.type.dualbar = 'dualbar';
$.globals.chart.type.line = 'line';
$.globals.chart.palettes = {};
$.globals.chart.palettes.flatUI = {
    simpleSet: ['#16a085', '#2980b9', '#f39c12', '#8e44ad', '#e74c3c', '#34495e']
};

$.globals.amchartslicense = 'CH90235686';