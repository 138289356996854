import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { WidgetDataResource } from '@/modules/ta/widget/resources/WidgetDataResource';
import CategoryDataResource from '@/modules/ta/category/resources/CategoryDataResource';

export class CategoryDataWidgetDataResource extends WidgetDataResource {
  constructor() {
    super(RouteGroup.CATEGORIES);
  }

  /**
   * @override
   * @param {Widget} widget
   * @param {Object} params
   * @returns {Promise<*>}
   */
  async getWidgetData(widget, params) {
    const { data_source_id, data_view_id } = widget;
    return CategoryDataResource.getData(data_source_id, data_view_id, params);
  }

  /**
   * @override
   * @param {Widget} widget
   * @param {Object} params
   * @returns {Promise<*>}
   */
  async getColumns(widget, params) {
    const { data_source_id, data_view_id } = widget;
    return CategoryDataResource.getColumns(data_source_id, data_view_id, params);
  }
}

export default new CategoryDataWidgetDataResource();
