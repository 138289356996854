'use strict';
import angular from 'angular';
import $ from 'jquery';
import moment from 'moment';
import $script from 'scriptjs';
import _ from 'lodash';

import initAngularTemplatedDirectives from '../../../../../core/app/modules/shared/scripts/app.services';

angular.module('dashboard.directives', [])

    .directive('highchartsReady', highchartsReady)
    .directive('applyDashDateRange', applyDashDateRange)
    .directive('viewAsClientSelect', viewAsClientSelect)
    .directive('viewAsClientGroupSelect', viewAsClientGroupSelect)
    .directive('viewAsClientSelectClose', viewAsClientSelectClose);

/**
 * @ngInject
 */
function highchartsReady() {
    return {
        restrict: 'A',
        controller: function($scope, CoreFactory) {
            $scope.highchartsIsReady = !!window.Highcharts;
            if (!$scope.highchartsIsReady) {
                var files = ['highcharts.min.js'];
                // This will ensure loading highcharts only once and allow dashboard to process only once that is done
                $script.ready(CoreFactory.lazyLoadJs(files), function() {
                    $scope.highchartsIsReady = true;
                    $scope.$apply();
                });
            }
        }
    }
}

/**
 * @ngInject
 */
function applyDashDateRange(
    $http,
    $state,
    $templateCache,
    AppFactory,
    DateRangeFactory,
    MomentDateFormat
) {
    return {
        restrict: 'A',
        link: function(scope, el) {
            var $dateRangeFilter = $('#dash-date-range-filter');
            var $dateTextContainer = $dateRangeFilter.find('span.date-text-container');
            var $dateText = $dateTextContainer.find('span.date-text');
            var $dateTextIcon = $dateTextContainer.find('i.icon');

            var $dateRangeButtonGroup = $dateRangeFilter.find('div.btn-group');
            var $dateRangeButtons = $dateRangeButtonGroup.find('button.btn');
            var $dateRangeContainer = $('#date-range-filter-container');

            var $startTimePicker = $('#start_time');
            var $endTimePicker = $('#end_time');

            var $compareToCheckbox = $('#compare_to_checkbox');
            var $compareDateRangeContainer = $('#compare_date_range_container');
            var $compareToSelect = $('#compare_to_select');
            var $comparisonStartTimePicker = $('#comparison_start_time');
            var $comparisonEndTimePicker = $('#comparison_end_time');

            var prettyDateFormat = 'MMM DD, YYYY';

            var applyDateRange = function(dateObj, params) {

                //wasTriggered by a quick set button date
                var wasTriggered;
                // dateRange is the key for getting relativeDateRange if set in quick set button date
                var dateRange;
                if (params) {
                    wasTriggered = params.wasTriggered;
                    dateRange = params.dateRange;
                }
                if (!wasTriggered) {
                    $dateRangeButtons.removeClass('active');
                }

                if (!moment(dateObj.startDate).isValid()) {
                    alert('Invalid start date: ' + dateObj.startDate);
                    return;
                }

                if (!moment(dateObj.endDate).isValid()) {
                    alert('Invalid end date: ' + dateObj.endDate);
                    return;
                }

                //This in theory should never happen since the date range picker does not allow it
                if (moment(dateObj.startDate).isAfter(dateObj.endDate)) {
                    alert('Your end date must be after your start date');
                    return;
                }

                if($compareToCheckbox.is(':checked')) {
                    if (!moment(dateObj.comparisonStartDate).isValid()) {
                        alert('Invalid start date: ' + dateObj.comparisonStartDate);
                        return;
                    }

                    if (!moment(dateObj.comparisonEndDate).isValid()) {
                        alert('Invalid end date: ' + dateObj.comparisonEndDate);
                        return;
                    }

                    //This in theory should never happen since the date range picker does not allow it
                    if (moment(dateObj.comparisonEndDate).isAfter(dateObj.startDate)) {
                        alert('Your comparison end date must be before your start date');
                        return;
                    }

                    //This in theory should never happen since the date range picker does not allow it
                    if (moment(dateObj.comparisonStartDate).isAfter(dateObj.comparisonEndDate)) {
                        alert('Your comparison end date must be after your comparison start date');
                        return;
                    }
                }

                var startTime = moment.utc(dateObj.startDate.format(MomentDateFormat.ISO));
                var endTime = moment.utc(dateObj.endDate.format(MomentDateFormat.ISO));
                var comparisonStartTime = null;
                var comparisonEndTime = null;
                //This is different from comparisonActive since the latter is set when the page is loaded
                var isComparing = $compareToCheckbox.is(':checked');


                var dateText = '';
                var startDatePrint = startTime.format(prettyDateFormat);
                var endDatePrint = endTime.format(prettyDateFormat);

                //Append compare to date range, if any
                if (isComparing) {
                    comparisonStartTime = moment.utc(dateObj.comparisonStartDate.format(MomentDateFormat.ISO));
                    comparisonEndTime = moment.utc(dateObj.comparisonEndDate.format(MomentDateFormat.ISO));

                    if ($dateRangeFilter.data('showCompare')) {
                        AppFactory.setComparisonDateRange(comparisonStartTime, comparisonEndTime, true);
                        var comparisonStartDatePrint = comparisonStartTime.format(prettyDateFormat);
                        var comparisonEndDatePrint = comparisonEndTime.format(prettyDateFormat);

                        dateText = '<label class="label label-default mr5">Prior period</label>' + comparisonStartDatePrint + ' - ' + comparisonEndDatePrint;
                        dateText += '<label class="label label-default mr5 ml15">Current period</label>' + startDatePrint + ' - ' + endDatePrint;

                        $dateRangeButtonGroup.hide();
                    }
                }
                else {
                    AppFactory.toggleComparisonDateRange(false);
                    dateText = startDatePrint + ' - ' + endDatePrint;
                    $dateRangeButtonGroup.show();
                }

                //Set the display date text
                $dateText.html(dateText);
                $dateTextIcon.removeClass('icon-chevron-up').addClass('icon-chevron-down');

                // We are on a design page
                $.core.main.showAjaxMainLoading({text: 'Fetching data between ' + startDatePrint + ' and ' + endDatePrint});

                var relativeDateRange = DateRangeFactory.getRelativeDateRangeByTextDisplay(dateRange || $('#date-range-custom-select').find('option:selected').text());
                AppFactory.setDateRange(startTime, endTime, relativeDateRange);
                AppFactory.setComparisonDateRange(comparisonStartTime, comparisonEndTime, isComparing);

                $http.post(scope.util.mageUrl('index/timefilter'), {
                    start_time: startTime.format(MomentDateFormat.ISO),
                    end_time: endTime.format(MomentDateFormat.ISO),
                    relative_date_range: relativeDateRange,
                    comparison_start_time: comparisonStartTime ? comparisonStartTime.format(MomentDateFormat.ISO) : null,
                    comparison_end_time: comparisonEndTime ? comparisonEndTime.format(MomentDateFormat.ISO) : null,
                    comparison_period: $compareToSelect.find('option:selected').val() || null,
                    comparison_active: isComparing
                }).then(function (json) {
                    // Only to this for NON design pages
                    if (json.status) {
                        $templateCache.removeAll();
                        initAngularTemplatedDirectives($templateCache);
                        $state.reload($state.current);
                    }
                });

            };

            scope.$on('dash:applyDateRange', function(e, newDateObj) {
                $startTimePicker.val(moment(newDateObj.startDate).format('MMM DD, YYYY'));
                $endTimePicker.val(moment(newDateObj.endDate).format('MMM DD, YYYY'));
                applyDateRange(newDateObj);
            });

            // Set default date range as part of AppFactory
            // If we already have a date range in AppFactory memory, then apply it to the UI
            var formattedDateRange = AppFactory.getFormattedDateRange();
            var comparisonDateRange = AppFactory.getComparisonDateRange();
            if (!_.isUndefined(formattedDateRange)) {
                $startTimePicker.val(formattedDateRange.start);
                $endTimePicker.val(formattedDateRange.end);

                var hasComparisonOption = $('#compare_to_select').length > 0;
                if (comparisonDateRange.enabled && hasComparisonOption) {
                    var formattedComparisonDateRange = comparisonDateRange.formatted;
                    $comparisonStartTimePicker.val(formattedComparisonDateRange.start);
                    $comparisonEndTimePicker.val(formattedComparisonDateRange.end);
                    $compareToCheckbox.prop('checked', true);
                    $compareDateRangeContainer.show();

                    var comparisonText = '<span class="date-text">'
                        + '<label class="label label-default mr5">Prior period</label> ' + formattedComparisonDateRange.start + '  - ' + formattedComparisonDateRange.end
                        + '<label class="label label-default mr5 ml15">Current period</label> ' + formattedDateRange.start + ' - ' + formattedDateRange.end
                        + '</span>';

                    $dateText.html(comparisonText);
                }
                else {
                    $dateText.html(formattedDateRange.start + ' - ' + formattedDateRange.end);
                }
            }
            else {
                // First time on page or page refresh
                AppFactory.setDateRange(moment($startTimePicker.val()).unix(), moment($endTimePicker.val()).utc().endOf('day').unix());
                AppFactory.setComparisonDateRange(moment($comparisonStartTimePicker.val()).utc().unix(), moment($comparisonEndTimePicker.val()).utc().endOf('day').unix(), $compareToCheckbox.is(':checked'));
            }

            el.click(function(e, params) {

                var dateObj = {
                    startDate: moment($startTimePicker.val()),
                    endDate: moment($endTimePicker.val()),
                    comparisonStartDate: moment($comparisonStartTimePicker.val()),
                    comparisonEndDate: moment($comparisonEndTimePicker.val()),
                    comparisonPeriod: $compareToSelect.find('option:selected').val(),
                };

                $dateRangeContainer.hide();
                applyDateRange(dateObj, params);
            });
        }
    }
}

/**
 * FILTERS - View data as client directive
 * @ngInject
 * @returns {{restrict: string, link: link}}
 */
function viewAsClientSelect(
    $http,
    $state,
    $templateCache,
    AppFactory,
    DashboardFilterPanelFactory,
    ExecutiveSummaryFactory
) {
    return {
        restrict: 'A',
        link: function(scope, el) {
            var self = el;

            var currentClient = $.core.main.currentClient;
            if (!_.isEmpty(currentClient.id)) {
                AppFactory.setCurrentClient(currentClient.id || null, currentClient.name || null);
            }

            self.change(function(){

                var selectedValue = $(this).val();

                var clientName = !_.isNull($(this).select2('data')) ? $(this).select2('data').text : '';

                if (selectedValue > 0) {
                    AppFactory.setCurrentClient(selectedValue, clientName);
                    $.core.main.setViewAsMode(clientName);


                    $.core.main.showAjaxMainLoading({ timeout: 0, text: 'Loading ' + clientName + ' data' });
                    $http.post(scope.util.mageUrl('index/changeuser'), {
                        iduser : selectedValue
                    }).success(function (json) {
                        if (json.status == 'success') {
                            AppFactory.refreshServices();
                            $('#specific-client-info').html(json.data.userhtml);
                            self.val('').trigger('change');
                            $templateCache.removeAll();
                            initAngularTemplatedDirectives($templateCache);
                            $state.reload($state.current);
                            DashboardFilterPanelFactory.setFilters({
                                client: {
                                    values: [{'id': selectedValue, 'text': clientName}]
                                },
                                client_group: {
                                    values: []
                                },
                                cluster: {
                                    values: []
                                }
                            });
                            DashboardFilterPanelFactory.resetFilterOptionList();

                            ExecutiveSummaryFactory.resetClientSelect();
                        }
                    });
                }
            });
        }
    }
}

/**
 * FILTERS - View data as client directive
 * @ngInject
 * @returns {{restrict: string, link: link}}
 */
function viewAsClientGroupSelect(
    $http,
    $state,
    $templateCache,
    AppFactory,
    DashboardFilterPanelFactory,
    ExecutiveSummaryFactory
) {
    return {
        restrict: 'A',
        link: function(scope, el) {
            var self = el;

            var currentClientGroup = $.core.main.currentClientGroup;
            if (!_.isEmpty(currentClientGroup.id)) {
                AppFactory.setCurrentClientGroup(currentClientGroup.id || null, currentClientGroup.name || null);
            }

            self.change(function(){
                var selectedValue = $(this).val();
                var clientName = $(this).find('option:selected').text();
                if (selectedValue > 0) {
                    AppFactory.setCurrentClientGroup(selectedValue, clientName);
                    $.core.main.showAjaxMainLoading({ timeout: 0, text: 'Loading ' + clientName + ' data' });
                    $http.post(scope.util.mageUrl('index/changeuser'), {
                        client_group_id : selectedValue
                    }).success(function (json) {
                        if (json.status == 'success') {
                            AppFactory.refreshServices();
                            $('#specific-client-info').html(json.data.userhtml);
                            self.val('').trigger('change');
                            $templateCache.removeAll();
                            initAngularTemplatedDirectives($templateCache);
                            $state.reload($state.current);
                            DashboardFilterPanelFactory.setFilters({
                                client: {
                                    values: []
                                },
                                client_group: {
                                    values: [{'id': selectedValue, 'text': clientName}]
                                },
                                cluster: {
                                    values: []
                                }
                            });
                            DashboardFilterPanelFactory.resetFilterOptionList();
                            ExecutiveSummaryFactory.resetClientSelect();
                        }
                    });
                }
            });
        }
    }
}


//
//  FILTERS - View data as client close directive
//
/**
 * @ngInject
 */
function viewAsClientSelectClose(
    $http,
    LoginFactory,
    AppFactory
) {
    return {
        restrict: 'A',
        link: function(scope, el) {
            el.click(function(){
                var $rightFrame = $('#right-frame');
                var $dashFilters = $('#dash-filters');
                var $viewAsClientBar = $('#specific-client-view');
                var $viewAsClientEyeIcon = $('#dash-view-as-client-filter').find('span.icon');

                $viewAsClientBar.hide();
                $viewAsClientEyeIcon.removeClass('active');
                $.core.main.changeTooltipText($viewAsClientEyeIcon, 'Use the two dropdowns to the right to view as a client or client group of your choice');

                AppFactory.setCurrentClient(null, null);

                $rightFrame.css({top: $dashFilters.outerHeight()});

                $('#datasources-submenu').find('li').removeClass('disabled');

                $.core.main.showAjaxMainLoading({ timeout: 0, text: 'Loading all client data' });
                $http.get(scope.util.mageUrl('index/canceluser')).success(function(json){
                    if (json.status == 'success') {
                        LoginFactory.refreshLegacyView();
                    }
                });
            });
        }
    }
}