import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';

export class ClusterWhiteLabels extends BaseEntityModel {
  /**
   * @type {string}
   */
  cluster_id;

  /**
   * @type {string}
   */
  cluster_name;

  /**
   * @type {?string}
   */
  whitelabel_url;

  /**
   * @type {?string}
   */
  report_sender;

  /**
   * @type {?string}
   */
  email_sender_name;

  /**
   * @type {?string}
   */
  email_sender_email;

  constructor(model = {}) {
    super(model);

    this.cluster_id = model.cluster_id;
    this.cluster_name = model.cluster_name;
    this.whitelabel_url = model.whitelabel_url;
    this.report_sender = model.report_sender;
    this.email_sender_name = model.email_sender_name;
    this.email_sender_email = model.email_sender_email;
  }
}
