<template>
  <div class="chat-gpt-widget-container" ref="chatGptWidgetContainer">
    <b-loading v-if="isLoading" :active="isLoading" :is-full-page="false" :can-cancel="false" />
    <div v-else class="chat-gpt-content has-text-centered">
      <div v-if="isEmptySummaryContent" class="chat-gpt-help-text">
        <img
          v-if="isDarkTheme"
          src="@/assets/openai-white-logomark.png"
          alt="Logo"
          class="openai-logo mb-5"
        />
        <img v-else src="@/assets/openai-logomark.png" alt="Logo" class="openai-logo mb-5" />
        <h1 class="mb-3 chat-gpt-heading">{{ $t('TapClicks ChatGPT') }}</h1>
        <div class="mb-6 chat-gpt-disclaimer-message">
          {{
            $t(
              'For Beta usage only! TapClicks has no control over the availability or response of this tool and reserves the right to limit the number of inquiries performed using this tool. It is for exploration purposes and any results should be verified before being used with customers or to manage or make decisions regarding campaigns.'
            )
          }}
        </div>
        <div class="columns is-multiline has-text-centered mb-3">
          <div
            class="column is-4 example-column"
            v-for="(column, exampleIndex) in promptExampleHeadings"
            :key="exampleIndex"
          >
            <h2 class="mb-5 example-column-heading">
              {{ $t(column) }}
            </h2>
            <div
              class="mb-5 is-clickable example-column-text-outline"
              v-for="(example, exampleTextIndex) in promptExampleText[exampleIndex]"
              :key="exampleTextIndex"
              @click="onExampleClick(exampleIndex, exampleTextIndex)"
            >
              <div class="example-column-text break-words">{{ $t(example) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <ul
          class="summary-content-lists"
          :style="[isEmptySummaryContent || isLoading ? {} : { height: elementHeight + 'px' }]"
          :class="listFlexDirection"
        >
          <li v-for="(item, index) in summaryContent" :key="index">
            <div class="mb-5">
              <div v-if="isAssistantQuery(item.role)" class="assistant-content">
                <div class="is-flex">
                  <div class="assistant-logo">
                    <img alt="" v-if="isDarkTheme" src="@/assets/openai-white-logomark.png" />
                    <img alt="" v-else src="@/assets/openai-logomark.png" />
                  </div>
                  <div class="assistant-content-text">
                    <div v-html="item.content" class="content-list-item has-text-left"></div>
                  </div>
                </div>
                <div class="is-flex summary-copy-button has-text-right">
                  <span @click="copyContent(index)">
                    <b-tooltip type="is-dark" :label="$t('Copy')" position="is-right">
                      <b-icon
                        pack="ti"
                        custom-class="ti-copy"
                        size="is-large"
                        class="icon-button"
                      />
                    </b-tooltip>
                  </span>
                </div>
              </div>
              <div v-else class="is-flex user-content">
                <div class="user-avatar has-text-centered">
                  <b-icon pack="ti" custom-class="ti-user-circle" size="is-large" />
                </div>
                <div class="user-content-text">
                  <div v-html="item.content" class="content-list-item has-text-left"></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <div class="columns chat-gpt-prompt">
        <div class="column is-11 chat-gpt-prompt-input">
          <FormInput
            :key="`user-prompt-` + summaryContent.length"
            v-model="userPrompt"
            :placeholder="$t('Ask a Question...')"
          />
        </div>
        <div class="column is-1 chat-gpt-prompt-send-button has-text-centered">
          <span @click="getChatGptResponse">
            <b-tooltip type="is-dark" :label="$t('Click to send')" position="is-top">
              <b-icon
                pack="ti"
                custom-class="ti-flip-vertical"
                size="is-large"
                class="icon-button full"
              />
            </b-tooltip>
          </span>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4"></div>
        <div class="column is-4 copyright has-text-centered">
          {{ $t('TapClicks © 2024. All rights reserved.') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getters } from '@/modules/core/app/helpers/store';
import { RolePermissionKey } from '@/modules/ta/role/role.constants';
import { AppModule, ThemeType } from '@/modules/core/app/constants/app.constants';
import FormInput from '@/modules/core/form/components/FormInput';
import ChatGptSummariesListDataService from '@/modules/ta/chatgptsummaries/services/ChatGptSummariesListDataService';
import ChatGptSummaryRenderService from '@/modules/ta/chatgptsummaries/services/ChatGptSummaryRenderService';
import KeyboardAdapter from '@/modules/core/ui/services/KeyboardService';
import { KeyboardEvent } from '@/modules/core/ui/ui.constants';
import NotificationService from '@/modules/core/ui/services/NotificationService';

export default {
  name: 'ChatGptWidget',
  mixins: [],
  components: {
    FormInput,
  },
  props: {
    widgetId: {
      type: String,
      required: true,
    },
  },
  static: {
    RolePermissionKey,
    promptExampleHeadings: [i18n.$t('Examples'), i18n.$t('Capabilities'), i18n.$t('Limitations')],
    promptExampleText: [
      [
        i18n.$t('Describe Programmatic Advertising for a new marketer.'),
        i18n.$t('What are some good keywords to try for a hair salon?'),
        i18n.$t(
          'Write me some sample text ad for a bicycle shop that does repairs called Joe’s Bikes.'
        ),
      ],
      [
        i18n.$t('Remembers what user said earlier in the conversation'),
        i18n.$t('Trained to decline inappropriate requests.'),
        i18n.$t('Allows user to provide follow-up corrections and refinement.'),
      ],
      [
        i18n.$t('Limited knowledge of world and events after 2021.'),
        i18n.$t('May occasionally generate incorrect information.'),
        i18n.$t('May occasionally produce harmful instructions or biased content.'),
      ],
    ],
  },
  data() {
    return {
      isLoading: false,
      originalElementHeight: '',
      userPrompt: '',
      summaryInfo: [],
      summaryContent: [],
    };
  },
  created() {
    KeyboardAdapter.on(KeyboardEvent.ENTER, this.getChatGptResponse);
  },
  beforeDestroy() {
    KeyboardAdapter.off(KeyboardEvent.ENTER, this.getChatGptResponse);
  },
  computed: {
    elementHeight() {
      // eslint-disable-next-line tap/no-raw-text-js
      const widgetElement = document.getElementById(`widget-${this.widgetId}`);
      if (widgetElement.clientHeight < 800) {
        return (widgetElement.clientHeight / 100) * 60;
      }
      return (widgetElement.clientHeight / 100) * 80;
    },
    computedUserPrompt() {
      return this.userPrompt;
    },
    isChatGptWidgetEnabled() {
      return getters.session.getUserSettings().isModuleAvailable(AppModule.CHATGPT_WIDGET);
    },
    listFlexDirection() {
      // eslint-disable-next-line tap/no-raw-text-js
      return this.summaryContent.length > 2 ? 'is-flex is-flex-direction-column-reverse' : '';
    },
    isEmptySummaryContent() {
      return this.summaryContent.length === 0;
    },
    isDarkTheme() {
      return getters.session.getUserSettings().themeType === ThemeType.DARK;
    },
  },
  methods: {
    buttonClicked() {
      if (this.userPrompt) {
        this.getChatGptResponse();
      }
    },
    onExampleClick(exampleIndex, exampleTextIndex) {
      this.userPrompt = this.promptExampleText[exampleIndex][exampleTextIndex];
    },
    getElementHeight() {
      return this.$refs.chatGptWidgetContainer.clientHeight;
    },
    onEnterPressed() {
      if (this.userPrompt) {
        this.getChatGptResponse();
      }
    },
    async getChatGptResponse() {
      if (this.isLoading || !this.userPrompt) return;
      try {
        this.isLoading = true;
        let isNewThread = false;
        if (this.isEmptySummaryContent) {
          isNewThread = true;
        }

        let latestResponse = '';

        if (isNewThread) {
          this.summaryInfo = await ChatGptSummariesListDataService.getChatGptWidgetSummary(
            isNewThread,
            this.widgetId,
            this.userPrompt
          );
          this.summaryContent = this.summaryInfo.content;
          this.summaryId = this.summaryInfo.id;
          isNewThread = false;
        } else {
          // eslint-disable-next-line no-unused-vars
          latestResponse = await ChatGptSummariesListDataService.getChatGptWidgetSummary(
            isNewThread,
            this.widgetId,
            this.userPrompt,
            this.summaryId
          );
          this.summaryInfo = await ChatGptSummariesListDataService.getItem(this.summaryId);
          this.summaryContent = this.summaryInfo.content.reverse();
        }
        this.userPrompt = '';
      } catch (e) {
        NotificationService.show(e.message);
      } finally {
        this.isLoading = false;
        this.elementHeight =
          this.getElementHeight() < this.originalElementHeight
            ? `${this.originalElementHeight - 56}px`
            : `${this.getElementHeight() - 56}px`;
      }
    },
    isAssistantQuery(role) {
      return ChatGptSummaryRenderService.isAssistantQuery(role);
    },
    copyContent(index) {
      const { content } = this.summaryContent[index];
      navigator.clipboard.writeText(content);
      NotificationService.show(i18n.$t('Copied successfully!'));
    },
  },
};
</script>

<style scoped lang="scss">
.chat-gpt-widget-container {
  overflow: scroll;
  scrollbar-width: none;

  .chat-gpt-content {
    .chat-gpt-help-text {
      padding: rem(20px) rem(100px);
    }

    .summary-content-lists {
      padding: rem(20px);
      overflow-y: scroll;

      .content-list-item {
        white-space: pre-line;
      }

      .summary-copy-button {
        flex-direction: row-reverse;
      }
    }

    font-family: 'Mulish', serif;

    .openai-logo {
      height: rem(45px);
    }

    .chat-gpt-heading {
      font-size: rem(32px);
      font-weight: 500;
    }

    .chat-gpt-disclaimer-message {
      font-size: rem(14px);
    }

    .example-column {
      color: rgb(19, 26, 53);
      font-size: rem(16px);

      .example-column-heading {
        color: rgb(19, 26, 53);
        font-size: rem(16px);
        font-weight: 100;
      }

      .example-column-text {
        color: rgb(19, 26, 53);
        background-color: rgb(247, 247, 248);
        font-size: rem(12px);
        border-end-start-radius: rem(12px);
        padding: rem(15px);
        border-radius: rem(20px);
      }
    }

    .user-content {
      flex-direction: row-reverse;
      max-width: 500px;
      margin-left: auto;

      .user-avatar {
        font-size: rem(25px);
        height: rem(40px);
        width: rem(40px);
      }

      .user-content-text {
        background-color: $primary;
        color: hsl(0, 0%, 100%);
        font-size: rem(14px);
        padding: rem(10px);
        border-radius: rem(20px);
      }
    }

    .assistant-content {
      justify-content: flex-start;
      max-width: 50%;

      .assistant-logo {
        position: relative;
        width: rem(100px);
        height: rem(40px);
        z-index: 2;
        display: flex;
        align-self: center;
      }

      .assistant-content-text {
        background-color: rgb(247, 247, 248);
        color: rgb(39, 39, 40);
        font-size: rem(14px);
        padding: rem(10px);
        border-radius: rem(20px);
      }
    }
  }

  .chat-gpt-prompt {
    padding: rem(10px) rem(60px) rem(10px) rem(10px);

    .chat-gpt-prompt-input {
      padding-left: rem(100px);
    }

    .chat-gpt-prompt-send-button {
      padding: rem(40px);
    }
  }
}
</style>
