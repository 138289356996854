"use strict";
import $ from 'jquery';
import _ from 'lodash';

var Core = $.core.admin.client = {

    $originalCustomFields: [],
    $currentClusterId: null,
    $clientCustomFields: null,
    $originalAgencyLinkHelpText: null,

    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {
        Core.populateAccountManagers();
        Core.initCustomFields();
        Core.initIotoolStatus(true);
    },
    events: function () {
        Core.restoreSelect2();
        Core.clusterChanges();
    },
    clusterChanges: function() {
        $('#cluster_id').change(function(e){
            $.post($.fn.actionUrl('reportingProfile/get_user_creation_cluster_filtered_reporting_profiles'),{cluster_id: $(e.currentTarget).val()}, function(json){
                var reportingProfiles = _.isObject(json.data) ? json.data.items : null;
                $.core.main.buildSelect2($('#default_reporting_profile_id'), {valueName: 'id', textName: 'name', width: '50%', multiple: false, placeholder: 'Select a default data profile...', allowClear: true}, reportingProfiles, []);
                $('#default_reporting_profile_id').select2('container').css('display', 'inline-block');
            });

            Core.populateAccountManagers();
            Core.populateCustomFields(false);
            Core.initIotoolStatus();
        });
    },
    populateAccountManagers: function() {
        $.post($.fn.actionUrl('client/get_possible_account_managers'),{cluster_id: $('#cluster_id').val()}, function(json){
            var possibleAccountManagers = _.isObject(json.data) ? json.data.items : null;
            $.core.main.buildSelect2($('#account_manager_user_id'), {valueName: 'id', textName: 'name', width: '50%', multiple: false, placeholder: 'Select an account manager...', allowClear: true}, possibleAccountManagers, []);
            $('#account_manager_user_id').select2('container').css('display', 'inline-block');
        });
    },
    /*
     * Submit the form won't get and send the value of the select field,
     * when the select field is disabled. So we won't disable it anymore
     * for old UI, disabling the select options instead.
     * If option = true, we enable selected option only
     * @parameter option enable all the options when it is false according to
     *            the function name
     */
    enableSelectedIotoolStatusOnly: function(option = true) {
        if (option) {
            $("#iotool_status option").attr('disabled','disabled');
            $("#iotool_status option:selected").removeAttr('disabled');
        } else {
            $("#iotool_status option").removeAttr('disabled','disabled');
        }
    },
    initIotoolStatus: function(initialize = false) {
        // Called by changing the cluster

        // If the cluster_id is empty, for example, remove a cluster
        // we will set the status to presale as default,
        // and the field is editable
        const clusterId = $('#cluster_id').val();
        if (!clusterId) {
            $('#iotool_status').val('presale').change();
            Core.enableSelectedIotoolStatusOnly(false);
            return;
        }

        const iotool_status = $('#iotool_status').val();
        let status = iotool_status;

        // If a cluster has a workflow client status, then use it
        // Otherwise use presale as default
        $.post($.fn.actionUrl('client/getIotoolStatusByClusterId'),{cluster_id: clusterId}, function(json){
            if (json && json.status == 'success') {
                if (json.data.status_name.length > 0) {
                    // Use current client workflow status when edit a client
                    // Use workflow initial status when change BU
                    status = initialize ? iotool_status : json.data.status_name;
                    $('#iotool_status').val(status).change();
                    Core.enableSelectedIotoolStatusOnly();
                }
            } else {
                Core.enableSelectedIotoolStatusOnly(false);
            }
        });
    },
    initCustomFields: function() {
        if (!document.getElementById('custom_form_fields')) {
            // The 'iotool' module is disabled because that element is only present when 'iotool' is enabled.
            return;
        }
        const $clientIdElements = document.getElementsByName('client_id');
        let $clientId = 0;
        if ($clientIdElements != null && $clientIdElements.length > 0) {
            $clientId = $clientIdElements.item(0).value;
        }
        if ($clientId > 0) {
            $.post($.fn.actionUrl('client/getCustomFields'), {client_id: $clientId}, function (json) {
                Core.$clientCustomFields = _.isObject(json.data) ? json.data.attrs : null;
                // Columns are 'field_name' and 'attr_value'.
                Core.populateCustomFields(true);
            });
        } else {
            Core.$clientCustomFields = null; // Need to clear in case an old value from a previous invocation was remaining.
        }
    },
    populateCustomFields: function(atInit) {
        Core.$currentClusterId = $('#cluster_id').val();
        let $customFields = document.getElementById('custom_form_fields');
        if (!$customFields) {
            // The 'iotool' module is disabled.
            return;
        }
        // The backend is only providing us with active groups and fields, so no need to check the active flag.
        let $newHtml = '<div class="col-md-12"><p><em translate>There are no custom fields associated with the selected Business unit</em></p></div>';
        Core.$originalCustomFields = [];
        let $addedFields = [];
        $.post($.fn.actionUrl('client/clientFormFieldGroups'),{cluster_id: Core.$currentClusterId}, function(json){
            let $clientFormFieldGroups = _.isObject(json.data) ? json.data.formFieldGroups : null;
            if ($clientFormFieldGroups != null && $clientFormFieldGroups.length > 0) {
                $.post($.fn.actionUrl('client/clientFormFields'), {cluster_id: Core.$currentClusterId}, function (json) {

                    let $newContentPresent = false;
                    let $clientFormFields = _.isObject(json.data) ? json.data.formFields : null;
                    if ($clientFormFields != null && $clientFormFields.length > 0) {
                        $clientFormFieldGroups = _.sortBy($clientFormFieldGroups, 'group_display_order');
                        $clientFormFields.sort(function(a,b){
                            a['display_order'] - b['display_order'];
                        });
                        _.each($clientFormFieldGroups, function(clientFormFieldGroup) {
                            const $fieldGroupId = clientFormFieldGroup.id;
                            let $groupLabelShown = false;
                            _.each($clientFormFields, function(clientFormField) {
                                if (clientFormField.group_id == $fieldGroupId) {
                                    if (!$newContentPresent) {
                                        $newHtml = '';  // Ok, we have at least one valid field, get rid of default text.
                                        $newContentPresent = true;
                                    }
                                    if (!$groupLabelShown) {
                                        $newHtml += Core.buildFieldGroupLabelHtml(clientFormFieldGroup.group_name);
                                        $groupLabelShown = true;
                                    }
                                    $newHtml += Core.buildFieldHtml(clientFormField);
                                    // We need to invoke buildSelect2 after the HTML, so that the elements to be updated
                                    // actually are in the form.  We also need to save the original values, in case the
                                    // cancel button is pressed.
                                    $addedFields.push(clientFormField);
                                }
                            });
                        });
                        // Update the form's HTML now.
                        $customFields.innerHTML = $newHtml;

                        // Now some post-processing that requires the HTML to be built.  This would normally be done
                        // in the javascript portion of add_client.phtml if it weren't for the fact that it is
                        // customizable whenever the cluster_id changes!
                        _.each($addedFields, function(clientFormField) {
                            switch (clientFormField.input_type) {
                                case 'select':
                                case 'multiselect':
                                    Core.buildFieldSelect(clientFormField, clientFormField.input_type == 'multiselect');
                                    // Save original values in case the cancel button is pressed
                                    let $originalField = {};
                                    $originalField.name = clientFormField.field_name;
                                    $originalField.value = $('#' + $originalField.name).val();  // Might be default value, or configured client value.
                                    $originalField.type = clientFormField.input_type;
                                    $originalField.required = clientFormField.required;
                                    $originalField.lookup_content = clientFormField.lookup_content;
                                    Core.$originalCustomFields.push($originalField);
                                    $('#' + clientFormField.field_name).change(function() {
                                        let value = $('#' + clientFormField.field_name).val();
                                        Core.resetLookupContents(clientFormField.field_name);
                                        $('#' + clientFormField.field_name + '_hidden').val(value);
                                    });
                                    $('#' + clientFormField.field_name + '_hidden').val($originalField.value);
                                    break;
                                case 'checkbox':
                                    // Set data so that main.js' restoreForm method will restore the data.
                                    $('#' + clientFormField.field_name).data('initialState', $('#' + clientFormField.field_name).prop('checked'));
                                    break;
                                case 'text':
                                    $('#' + clientFormField.field_name).data('initialState', $('#' + clientFormField.field_name).val());
                                    $('#' + clientFormField.field_name).keyup(function() {
                                        Core.validateField(clientFormField.field_name, clientFormField.data_type, this);
                                    });
                                    break;
                                default:
                                    // Set data so that main.js' restoreForm method will restore the data.
                                    $('#' + clientFormField.field_name).data('initialState', $('#' + clientFormField.field_name).val());
                                    break;
                            }
                            const $children = Core.getChildrenForParent(clientFormField, $addedFields);
                            if ($children.length > 0) {
                                // If this field has children, show/hide them according to the parent's value.
                                Core.showOrHideChildren(clientFormField, $children);
                                // Register for changes to the parent's value.
                                // By passing the children as a parameter, they don't have to be recomputed when the
                                // parent actually changes and the on-change function is invoked.
                                $('#' + clientFormField.field_name).change(function() {
                                    Core.showOrHideChildren(clientFormField, $children);
                                });
                            }
                            if (clientFormField.field_name == 'client_agency') {
                                const $helpText = clientFormField.help_text ? clientFormField.help_text : '';
                                $('#client_agency').change(function (e) {
                                    let $helpOrLinkElement = document.getElementById('__HELP_TEXT_OR_CLIENT_AGENCY_LINK__client_agency');
                                    $helpOrLinkElement.innerHTML = Core.computeHelpTextOrClientAgencyLink(clientFormField, $(e.currentTarget).val(), $helpText);
                                });
                            }
                            if (clientFormField.field_name == 'invoice_account') {
                                // Put the link under agency_name field if present, else put it under the invoice_account field,
                                // which exists for sure since we're processing it now.
                                let $helpOrLinkElement = document.getElementById('__HELP_TEXT_OR_CLIENT_AGENCY_LINK__agency_name');
                                if (!$helpOrLinkElement) {
                                    $helpOrLinkElement = document.getElementById('__HELP_TEXT_OR_CLIENT_AGENCY_LINK__invoice_account');
                                }
                                // Initial link
                                Core.addClientAgencyByCrmLink($helpOrLinkElement, $('#' + clientFormField.field_name).val());
                                // Register for changes
                                $('#invoice_account').change(function (e) {
                                    Core.addClientAgencyByCrmLink($helpOrLinkElement, $(e.currentTarget).val());
                                });
                            }
                        });
                        Core.resetLookupContents();
                        if (atInit) {
                            // Because we might finish here after the $.core.main dynamicForm has been initialized, we
                            // must reset the original form to the newly updated one.
                            $.core.main.setOriginalForm();
                        }
                        // We need to bind new elements if the business unit has been modified since the custom form
                        // fields can change and the whole form is not reinitialized then.
                        $.core.main.bindElementsToCompareFormsMethod();

                    } else {
                        $customFields.innerHTML = $newHtml;
                    }
                });
            } else {
                $customFields.innerHTML = $newHtml;
            }
        });
    },
    getChildrenForParent(parentClientFormField, customClientFormFields) {
        const childrenIds = [];
        _.each(customClientFormFields, function (childClientFormField) {
            if (childClientFormField.parent_id == parentClientFormField.id) {
                childrenIds.push(childClientFormField);
            }
        });
        return childrenIds;
    },
    showOrHideChildren(parentField, children) {
        let $parentValue;
        if (parentField.input_type == 'checkbox') {
            $parentValue = $('#' + parentField.field_name).prop('checked') ? '1' : '0';
        } else {
            $parentValue = $('#' + parentField.field_name).val();
        }
        _.each(children, function(child) {
            let $found = false;
            const $parentTriggerIds = child.parent_trigger_id;
            // The parentTriggerIds are always an array, even if the parent is only a checkbox or a select, where there
            // will only be one value.  For multiselect, of course, there can be more than one value.
            // Note that for multiselect, the matching rules is that if there is at least one value selected among
            // the parentTriggerIds, then we consider we have a match.
            _.each($parentTriggerIds, function(parentTriggerId) {
                // If the parent is a multiselect field, its value might contain several comma-separated values.
                // Note that we do not need to check for its type, since other types are either an id (for select) or
                // a 0 or 1 (for checkbox), which never contain a comma.  So we can split them away without consequences.
                const $parentValueArr = $parentValue.split(',');
                _.each($parentValueArr, function(singleParentValue) {
                   if (singleParentValue == parentTriggerId) {
                       $found = true;
                   }
                });
            });
            const $childElementId = '__CUSTOM_FIELD_ID__' + child.field_name;
            const required = child.required == 1 ? 'required' : '';
            if ($found) {
                $('#' + $childElementId).show();
                switch (child.input_type) {
                    case "text":
                    case 'checkbox':
                    case 'rollup-target':
                        $('#' + child.field_name).prop(required,true);
                        break;
                    case 'select':
                    case 'multiselect':
                        $('#' + child.field_name + '_hidden').prop(required,true);
                        break;
                }
            } else {
                $('#' + $childElementId).hide();
                switch (child.input_type) {
                    case "text":
                    case 'rollup-target':
                        $('#' + child.field_name).removeAttr('required');
                        $('#' + child.field_name).val('');
                        break;
                    case 'checkbox':
                        $('#' + child.field_name).removeAttr('required');
                        $('#' + child.field_name).prop('checked', false);
                        break;
                    case 'select':
                        $('#' + child.field_name + '_hidden').removeAttr('required');
                        $('#' + child.field_name).select2('val', '');
                        break;
                    case 'multiselect':
                        $('#' + child.field_name + '_hidden').removeAttr('required');
                        $('#' + child.field_name).select2('val', []);
                        break;
                }
            }
            $('#' + child.field_name).trigger("change");
        });
    },
    buildFieldGroupLabelHtml(fieldGroupLabel) {
        return '<div class="panel-title col-md-12">\n' +
               '    <label class="form-group col-md-12" translate>' + fieldGroupLabel + '</label>\n' +
               '</div>';
    },
    buildFieldHtml(clientFormField) {
        // The $formGroupId is used to show/hide child fields when a parent value change enables/disables it.
        const $formGroupId = '__CUSTOM_FIELD_ID__' + clientFormField.field_name;
        switch(clientFormField.input_type) {
            case 'text':
            case 'rollup-target':
                return Core.buildTextFieldHtml(clientFormField, $formGroupId);
            case 'select':
                return Core.buildSelectFieldHtml(clientFormField, $formGroupId);
            case 'multiselect':
                return Core.buildSelectFieldHtml(clientFormField, $formGroupId);
            case 'checkbox':
                return Core.buildCheckboxFieldHtml(clientFormField, $formGroupId);
            default:
                // Ignore unsupported input types.  Front-end for configuring client forms should prevent these anyway.
        }
    },
    buildTextFieldHtml: function(clientFormField, $formGroupId) {
        const $fieldName = clientFormField.field_name;
        const $fieldDisplayName = clientFormField.display_name;
        const $helpText = clientFormField.help_text ? clientFormField.help_text : '';
        const $errorText = clientFormField.display_name + ' must be a valid number';
        const $requiredString = (clientFormField.required == 1) ? ' required' : '';
        const $readonlyString = (clientFormField.input_type == 'rollup-target') ? ' readonly' : '';
        let $initialValue = '';
        const $fieldValue = Core.getClientCustomFieldValue($fieldName);
        if ($fieldValue != null && $fieldValue != '') {
            $initialValue = 'value="' + $fieldValue + '"';
        } else if (clientFormField.default_value != null && clientFormField.default_value != '') {
            $initialValue = 'value="' + clientFormField.default_value + '"';
        }
        let $fieldId = '__HELP_TEXT_OR_CLIENT_AGENCY_LINK__' + $fieldName;
        return '<div class="form-group" id="' + $formGroupId + '">\n' +
               '  <label class="col-sm-2 control-label' + $requiredString + '" for="' + $fieldName + '" translate>' + $fieldDisplayName + '</label>\n' +
               '  <div class="col-sm-10">\n' +
               '    <input class="form-control" name="' + $fieldName + '" type="text" id="'+$fieldName+'" ' + $initialValue + $requiredString + $readonlyString + ' />\n' +
               '    <p style="color:red;" id="' + $fieldName + '_error" hidden="true">' + $errorText + '</p>' +
               '    <p id="' + $fieldId + '">' + $helpText + '</p>' +
               '  </div>\n' +
               '</div>';
    },
    buildCheckboxFieldHtml: function(clientFormField, $formGroupId) {
        const $fieldName = clientFormField.field_name;
        const $fieldDisplayName = clientFormField.display_name;
        const $helpText = clientFormField.help_text ? clientFormField.help_text : '';
        const $requiredString = (clientFormField.required == 1) ? ' required' : '';
        let $fieldValue = Core.getClientCustomFieldValue($fieldName);
        if ($fieldValue != null) {
            $fieldValue = ($fieldValue == '1' || $fieldValue == 'yes' || $fieldValue == true) ? 'checked' : '';
        } else {
            if (clientFormField.default_value == null) {
                $fieldValue = '';
            } else {
                $fieldValue = (clientFormField.default_value == '1' || clientFormField.default_value == 'yes' || clientFormField.default_value == true) ? 'checked' : '';
            }
        }
        return '<div class="form-group" id="' + $formGroupId + '">\n' +
               '  <label class="col-sm-2 control-label' + $requiredString + '" for="' + $fieldName + '" translate>' + $fieldDisplayName + '</label>\n' +
               '  <div class="col-sm-10">\n' +
               '    <div class="checkbox">\n' +
               '      <label>' +
               '        <input type="checkbox" name="' + $fieldName + '" id="' + $fieldName + '" value="1" ' + $fieldValue + $requiredString + '>' +
               '        <p>' + $helpText + '</p>' +
               '      </label>\n' +
               '    </div>\n' +
               '  </div>\n' +
               '</div>';
    },
    buildSelectFieldHtml: function(clientFormField, $formGroupId) {
        const $fieldName = clientFormField.field_name;
        const $fieldDisplayName = clientFormField.display_name;
        const $helpText = clientFormField.help_text ? clientFormField.help_text : '';
        const $requiredString = (clientFormField.required == 1) ? ' required' : '';
        let $helpTextOrClientAgencyLink = $helpText;
        let $fieldId = '__HELP_TEXT_OR_CLIENT_AGENCY_LINK__' + $fieldName;
        if ($fieldName == 'client_agency') {
            // It would be more robust to also verify that the lookup type's content key is client, but we don't
            // have this information and would need to fetch it from the backend... probably not worth it.
            $helpTextOrClientAgencyLink = Core.computeHelpTextOrClientAgencyLink(clientFormField, Core.getClientCustomFieldValue($fieldName), $helpText);
            // We also register to client_agency field changes elsewhere (after the HTML is built), to modify this as appropriate.
        }
        return '<div class="form-group" id="' + $formGroupId + '">\n' +
               '    <label class="col-sm-2 control-label' + $requiredString + '" for="' + $fieldName + '" translate>' + $fieldDisplayName + '</label>\n' +
               '    <div class="col-sm-10">\n' +
               '        <input type="hidden" name="' + $fieldName + '" id="' + $fieldName + '" />\n' +
               '        <input type="text" style="height:0;width:0;opacity: 0;padding:0;margin:0;" id="' + $fieldName + '_hidden"' +
                             $requiredString + '/>' +
               '        <p id="' + $fieldId + '">' + $helpTextOrClientAgencyLink + '</p>' +
               '    </div>\n' +
               '</div>';
    },
    computeHelpTextOrClientAgencyLink: function(clientFormField, fieldValue, helpText) {
        if (fieldValue) {
            const $clientIdElements = document.getElementsByName('client_id');
            let $clientId = 0;
            if ($clientIdElements != null && $clientIdElements.length > 0) {
                $clientId = $clientIdElements.item(0).value;
            }
            // Don't link to own page if client_agency is the same as this client.
            if ($clientId != fieldValue) {
                const $clientAgencyUrl = $.fn.appUrl('#/clients/detail/' + fieldValue);
                const $clientEntry = clientFormField.lookup_content.find(x => x.id == fieldValue);
                return '<a href="' + $clientAgencyUrl + '">Go to agency record ' + $clientEntry.lookup_value + '</a>';
            }
        }
        return helpText;
    },
    addClientAgencyByCrmLink: function(elementToModify, crmId) {
        if (Core.$originalAgencyLinkHelpText == null) {
            // Set once and then never modify.
            Core.$originalAgencyLinkHelpText = elementToModify.innerHTML;
        }
        let helpTextOrLinkToUse = Core.$originalAgencyLinkHelpText;
        if (crmId) {
            $.post($.fn.actionUrl('client/getAgencyClientByCrmIdAndClusterId'), {crm_id: crmId, cluster_id: $('#cluster_id').val()}, function (json) {
                let agencyClientId = _.isObject(json.data.client) ? json.data.client.id : null;
                if (agencyClientId) {
                    const $clientIdElements = document.getElementsByName('client_id');
                    let $clientId = 0;
                    if ($clientIdElements != null && $clientIdElements.length > 0) {
                        $clientId = $clientIdElements.item(0).value;
                    }
                    // Don't link to own page if agency is the same as this client.
                    if ($clientId != agencyClientId) {
                        const $clientAgencyUrl = $.fn.appUrl('#/clients/detail/' + agencyClientId);
                        helpTextOrLinkToUse = '<a href="' + $clientAgencyUrl + '">Go to agency record ' + json.data.client.company_name + '</a>';
                    }
                }
                elementToModify.innerHTML = helpTextOrLinkToUse;
            });
        } else {
            elementToModify.innerHTML = helpTextOrLinkToUse;
        }
    },
    buildFieldSelect: function(clientFormField, multiselect) {
        const $fieldName = clientFormField.field_name;
        $.core.main.buildSelect2(
            $('#'+$fieldName),
            {valueName: 'id', textName: 'lookup_value', width: '50%', multiple: multiselect, allowClear: true},
            clientFormField.lookup_content
        );

        let $fieldValue = Core.getClientCustomFieldValue($fieldName, multiselect);
        if ($fieldValue == null) {
            // If we can't get the actual configured value (if only because it's a new client), see if there's a
            // default value.
            if (clientFormField.default_value != null && clientFormField.default_value != '') {
                if (multiselect) {
                    // Because of the way default values are stored in the database (single field), if it's an array,
                    // it's stored as JSON and needs to be decoded separately since now it looks like a string.
                    const $defaultValue = JSON.parse(clientFormField.default_value);
                    $fieldValue = [];
                    _.each($defaultValue, function(value) {
                        const $contentEntry = clientFormField.lookup_content.find(x => x.lookup_value == value);
                        if ($contentEntry) {
                            $fieldValue.push($contentEntry.id);
                        }
                    });
                    if ($fieldValue.length === 0) {
                        $fieldValue = null;  // revert to null so we don't set the select2 'val' below.
                    }
                } else {
                    const $contentEntry = clientFormField.lookup_content.find(x => x.lookup_value == clientFormField.default_value);
                    if ($contentEntry) {
                        $fieldValue = ($contentEntry.id);
                    }
                }
            }
        }

        if ($fieldValue != null) {
            // $fieldValue will be an array for multiselect.
            $('#' + $fieldName).select2('val', $fieldValue);
        }
    },
    getClientCustomFieldValue: function(fieldName, multiselect) {
        if (Core.$clientCustomFields != null) {
            if (multiselect) {
                const $customFieldArray = Core.$clientCustomFields.filter(x => x.field_name == fieldName);
                if ($customFieldArray && $customFieldArray.length > 0) {
                    // Return array of attr_value, instead of array of fields such as {field_name: 'somename', attr_value: 'val'}.
                    return $customFieldArray.map(x => x.attr_value);
                } else {
                    return null;  // Don't return an empty array since caller depends on null to use default value.
                }
            } else {
                const $customField = Core.$clientCustomFields.find(x => x.field_name == fieldName);
                if ($customField) {
                    return $customField.attr_value;
                }
            }
        }
        return null;
    },
    clientType: function() {},
    clientTypeBinding: function() {},
    restoreSelect2: function() {
        Core.$original_client_group_ids = $('#client_group_ids').val();
        Core.$original_default_reporting_profile_id = $('#default_reporting_profile_id').val();
        Core.$original_cluster_id = $('#cluster_id').val();

        $('#form-cancel').on('click', function(e) {

            // Some multiselect select2s may or may not exist on the page depending on the instance config
            // only try to reset them if they exist (length > 0)
            if ($('#client_group_ids').length)
            {
                $('#client_group_ids').select2('val', Core.$original_client_group_ids.split(','));
            }

            $('#default_reporting_profile_id').select2('val', Core.$original_default_reporting_profile_id);

            if (Core.$originalCustomFields.length) {
                // Restore custom fields, even if the cluster_id changes...
                _.each(Core.$originalCustomFields, function(customField) {
                    switch (customField.type) {
                        case 'multiselect':
                            $('#' + customField.name).select2('val', customField.value.split(','));
                            // Setting the field with select2 as above does not trigger the change event on the element,
                            // so it will fail to update its children field if we don't trigger it ourselves.
                            $('#' + customField.name).change();
                            break;
                        case 'select':
                            $('#' + customField.name).select2('val', customField.value);
                            // Again trigger change event so children get updated, or client_agency link/help text (only for client_agency field).
                            $('#' + customField.name).change();
                            break;
                        default:
                            // Done in main.js's restoreForm method using initialState
                            break;
                    }
                });
            }

            const $previousClusterId = Core.$currentClusterId;

            $('#cluster_id').select2('val', Core.$original_cluster_id);

            if ($previousClusterId != Core.$original_cluster_id) {
                // Reset caused the cluster_id to change, so repopulate the custom fields according to the new one.
                Core.populateCustomFields(false);
            }
        });

        $('#form-submit').on('click', function(e) {
            // Save values again now that submit has been pressed, so that if we cancel, we don't go back to pre-cancel values.
            Core.$original_client_group_ids = $('#client_group_ids').val();
            Core.$original_default_reporting_profile_id = $('#default_reporting_profile_id').val();
            Core.$original_cluster_id = $('#cluster_id').val();
            if (Core.$originalCustomFields.length) {
                _.each(Core.$originalCustomFields, function(customField) {
                    customField.value = $('#' + customField.name).val();
                });
            }
        });

    },
    resetLookupContents: function(selected_field_name) {
        _.each(Core.$originalCustomFields, function(customField) {
            const $fieldName = customField.name;
            if ($fieldName == selected_field_name) {
                return;
            }
            let lookup_content = Core.filterLookupContent(customField.lookup_content);
            let multiselect = customField.type == 'multiselect';
            $.core.main.buildSelect2(
                $('#'+$fieldName),
                {valueName: 'id', textName: 'lookup_value', width: '50%', multiple: multiselect, allowClear: true},
                lookup_content
            );
        });
    },
    filterLookupContent: function(lookup_contents) {
        let filterLookupContent = [];
        _.each(lookup_contents, function(lookup_content) {
            if (Core.lookupContentIsSelected(lookup_content.parent_content_key_source_id)) {
                filterLookupContent.push(lookup_content);
            }
        });
        return filterLookupContent.length > 0 ? filterLookupContent : lookup_contents;
    },
    lookupContentIsSelected: function(lookup_content_id) {
        let selected = false;
        const int_lookup_content_id = parseInt(lookup_content_id, 10);
        if (int_lookup_content_id) {
            const str_lookup_content_id = String(lookup_content_id);
            _.each(Core.$originalCustomFields, function (customField) {
                let value = $('#' + customField.name).val();
                // Change string value to array, it will change string value to
                // one element array when there is no comma. In fact the value
                // of multi-select is still string with comma, while it is an array
                // in Vue js in nui.
                if (typeof value == 'string') {
                    value = value.split(',');
                }
                if (value.indexOf(str_lookup_content_id) > -1) {
                    selected = true;
                    return;
                }
            });
        }
        return selected;
    },
    validateField: function(fieldName, fieldDataType, element) {
        let res = Core.validateValue(fieldDataType, element);
        let error = $('#' + fieldName + '_error');
        error ? error.attr("hidden",res) : '';
        !res ? $.core.main.hideFooter() : '';
    },
    validateValue: function(data_type, element) {
        let result = true;
        const value = element.value;
        switch (data_type) {
            case 'integer':
                if (value && isNaN(value.replace(/,/g, ''))) {
                    element.value = value.replace(/[^\d.-]/g, '');
                    result = false;
                }
                break;
        }

        return result;
    }
};
