'use strict';
import angular from 'angular';

angular.module('core.reportingtemplate.services', [])

    .service('ReportingTemplateFactory', ReportingTemplateFactory);

/**
 * @ngInject
 */
function ReportingTemplateFactory(Restangular) {
    var reportingTemplates = Restangular.all('reportingtemplates');

    function getData(queryParams) {
        return reportingTemplates.getList(queryParams);
    }

    return {
        reportingTemplates: reportingTemplates,
        getData: getData
    }
}