import BillingService from '@/modules/core/billing/services/BillingService';
import { commits, dispatches } from '@/modules/core/app/helpers/store';
import { BillingPeriodUnit } from '@/modules/core/billing/billing.constants';
import { deepClone } from '@/modules/core/helper';

export const actions = {
  async getBillingDetails({ state }) {
    const response = await BillingService.getBillingDetails();
    const billingDetails = response.billing_details;
    // set the current product
    const currentPlan = billingDetails.plans.find(
      (plan) => plan.id === billingDetails.current_plan_details[0].plan_id
    );
    dispatches.billing.setCurrentProduct(currentPlan.products[0]);

    if (!state.billingPeriodUnit) {
      const billingPeriodUnit = billingDetails.products.find(
        (product) => product.id === state.currentProduct
      ).is_trialing
        ? BillingPeriodUnit.YEARLY
        : billingDetails.current_plan_details[0].frequency;
      commits.billing.setBillingPeriodUnit(billingPeriodUnit);
    }
    // selecting addon from overview page case
    if (state.selectedAddonCode) {
      dispatches.billing.addAddon(
        billingDetails.addons.find((addon) => addon.id === state.selectedAddonCode)
      );
      commits.billing.setSelectedAddonCode(null);
    }

    commits.billing.setBillingDetails(billingDetails);
  },
  setBillingPeriodUnit({}, billingPeriodUnit) {
    commits.billing.setBillingPeriodUnit(billingPeriodUnit);
  },
  setCurrentProduct({}, product) {
    commits.billing.setCurrentProduct(product);
  },
  async getOverviewDetails({}) {
    const response = await BillingService.getOverviewDetails();
    const overviewDetails = response.overview_details;
    commits.billing.setOverviewDetails(overviewDetails);
  },
  async cancelPlan({}) {
    return BillingService.cancelPlan();
  },
  setCurrentSelectedPlan({}, selectedPlan) {
    commits.billing.setCurrentSelectedPlan(selectedPlan);
  },
  addAddon({ state }, addon) {
    if (!(addon.id in state.selectedAddons)) {
      if (addon.quantity) {
        state.selectedAddons[addon.id] = { data: addon, quantity: addon.quantity };
      } else {
        state.selectedAddons[addon.id] = { data: addon, quantity: 1 };
      }
    } else {
      state.selectedAddons[addon.id].quantity += 1;
    }
    commits.billing.updateSelectedAddons(deepClone(state.selectedAddons));
  },
  removeAddon({ state }, addon) {
    if (
      state.selectedAddons[addon.id].quantity > 1 ||
      (state.selectedAddons[addon.id].quantity === 1 &&
        state.selectedAddons[addon.id].data.isExistingAddon)
    ) {
      state.selectedAddons[addon.id].quantity -= 1;
    } else {
      delete state.selectedAddons[addon.id];
    }
    commits.billing.updateSelectedAddons(deepClone(state.selectedAddons));
  },
  resetSelectedAddons({}) {
    commits.billing.resetSelectedAddons();
  },
  setSelectedAddonCode({}, addonCode) {
    commits.billing.setSelectedAddonCode(addonCode);
  },
};
