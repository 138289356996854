import SliceWidgetRenderServicePie from '@/modules/core/charts/am5/pie/services/SliceWidgetRenderServicePie';
import { SeriesType } from '@/modules/core/charts/chart.constants';
import { PieChartConfig } from '@/modules/core/charts/am5/pie/models/PieChartConfig';
import { PieChartDrawOption } from '@/modules/ta/widget/builder.constants';
import WidgetContextFactory from '@/modules/ta/widget/models/context/WidgetContextFactory';
import { isNull } from 'lodash';

export default class PieChartWidgetRenderService extends SliceWidgetRenderServicePie {
  /**
   * @param config
   * @returns {PieChartConfig}
   */
  initConfig(config) {
    return new PieChartConfig(config);
  }

  /**
   * @param column
   * @returns {string}
   */
  getSeriesType() {
    return this.is3D() ? SeriesType.PIE_3D : SeriesType.PIE;
  }

  /**
   * @returns {{}}
   */
  getChartConfigProperties() {
    let chartConfigProperties = {
      ...super.getChartConfigProperties(),
      radius: PieChartDrawOption.MAX_RADIUS,
      innerRadius: this.getDrawOptions().inner_radius,
      colorPalette: this.getChartPalettePie() || [''],
      fillOpacityPrior: this._hasComparisonData ? 0.6 : 1,
      fillOpacityCurrent: 1,
      hasComparisonStatus: this._hasComparisonData,
      labelPct: this._widget.metadata.draw_options.label_percent,
      otherPct: this._widget.metadata.draw_options.other_percent,
      drawOptions: this._widget.metadata.draw_options,
    };
    const { pie_semicircle_angle, pie_rotation_angle } = this.getDrawOptions();

    if (!pie_semicircle_angle) {
      const startAngle =
        pie_rotation_angle > 0 ? pie_rotation_angle : PieChartDrawOption.DEFAULT_START_ANGLE;
      chartConfigProperties = {
        ...chartConfigProperties,
        startAngle,
        endAngle: 360 + startAngle,
      };
    } else {
      chartConfigProperties = {
        ...chartConfigProperties,
        startAngle: 180,
        endAngle: 360,
      };
    }
    return chartConfigProperties;
  }

  getChartPalettePie(columnIndex = null) {
    const widget = this.getWidget();
    if (!isNull(columnIndex)) {
      const chartPalette = WidgetContextFactory.getContext()?.getChartPalette(widget);
      return columnIndex ? chartPalette[columnIndex % chartPalette.length] : chartPalette;
    }
  }
}
