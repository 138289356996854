'use strict';
import angular from 'angular';

angular.module('executivesummary.constants', [])

    .constant('ExecutiveSummaryColumns', {
        SUMMARY_ID: 'summary_id',
        ID: 'id',
        EXECUTIVE_SUMMARY_ID: 'executive_summary_id',
        TITLE: 'title',
        THUMBNAIL: 'thumbnail',
        IS_ACTIVE: 'is_active',
        CONTENT: 'content',
        PAGE_ID: 'page_id',
        PAGE_TITLE: 'page_title',
        START_DATE: 'start_date',
        END_DATE: 'end_date',
        ENTITY: 'entity'
    });