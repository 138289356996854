import { Label, p50, color } from '@amcharts/amcharts5';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export function useAxesLabels(context) {
  const { root, chart, config } = context();
  function createAxesLabels() {
    if (config.value.isNormalizedData) {
      if (config.value.isRotated) {
        createXAxesLabels();
      } else {
        createYAxesLabels();
      }
    }
  }

  function createXAxesLabels() {
    chart.value.xAxes.each((axis) => {
      addLabelToXAxis(axis);
    });
  }

  function createYAxesLabels() {
    chart.value.yAxes.each((axis) => {
      addLabelToYAxis(axis);
    });
  }

  function addLabelToXAxis(axis) {
    const props = axis.get(Constant.USER_DATA);
    if (!props.showLabels) {
      return;
    }

    const labelProps = {
      // eslint-disable-next-line tap/no-raw-text-js
      text: `[bold]${props.titleText}`,
      centerX: p50,
      x: p50,
      paddingTop: 0,
      paddingBottom: 0,
      fill: color(props.titleColor),
    };

    addLabelToAxis(axis, labelProps, props.opposite ? 0 : axis.children.length - 1);
  }

  function addLabelToYAxis(axis) {
    const props = axis.get(Constant.USER_DATA);
    if (!props.showLabels) {
      return;
    }

    const labelProps = {
      // eslint-disable-next-line tap/no-raw-text-js
      text: `[bold]${props.titleText}`,
      centerX: p50,
      y: p50,
      paddingLeft: 0,
      paddingRight: 0,
      rotation: -90,
      fill: color(props.titleColor),
    };

    addLabelToAxis(axis, labelProps, props.opposite ? axis.children.length - 1 : 0);
  }

  function addLabelToAxis(axis, labelProps, position) {
    axis.children.moveValue(Label.new(root.value, labelProps), position);
  }

  /**
   * From a given axis return the text label (which will be a child element)
   * @param axis
   * @returns {*}
   */
  function getTextLabelFromAxis(axis) {
    let label;

    axis.children.each((child) => {
      if (child.constructor.name === Label.name) {
        label = child;
      }
    });

    return label;
  }

  return {
    addLabelToXAxis,
    addLabelToYAxis,
    createAxesLabels,
    getTextLabelFromAxis,
  };
}
