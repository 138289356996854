'use strict';
import angular from 'angular';

angular.module('core.user.constants', [])

    .constant('UserColumns', {
        ID: 'id',
        CLIENT_ID: 'client_id',
        USER_TYPE: 'user_type',
        COMPANY_NAME: 'company_name',
        FIRST_NAME: 'first_name',
        LAST_NAME: 'last_name',
        EMAIL: 'email',
        CLUSTER_ID: 'cluster_id',
        ROLE_ID: 'role_id',
        ROLE_NAME: 'role_name',
        REPORTING_PROFILE_ID: 'reporting_profile_id',
        REPORTING_PROFILE_NAME: 'reporting_profile_name',
        DEFAULT_HOME_PAGE: 'default_home_page',
        IO_TOOL_NOTIFICATION_FREQUENCY: 'io_tool_notification_frequency',
        COUNTRY: 'country',
        TIMEZONE: 'timezone'
    })
    .constant('$UserImageUploadEvents', {
        IMAGE_CHANGE: 'UserImageUploadEvents:IMAGE_CHANGE'
    });