import { isEmpty } from 'lodash';
import { WidgetFilter } from '@/modules/ta/widget/models/metadata/dynamic/filters/WidgetFilter';
import { DataSource } from '@/modules/core/datasource/models/DataSource';

export class WidgetFilterSet {
  /**
   * @var {String}
   */
  id;

  /**
   * @var {String}
   */
  name;

  /**
   * @var {Object}
   */
  data_source;

  /**
   * @var {Array<WidgetFilter>}
   */
  filters;

  /**
   * @var {String}
   */
  unique_id;

  constructor(model = {}) {
    this.id = model.id;
    this.name = model.name;
    this.data_source = new DataSource(model.data_source);
    this.unique_id = model.unique_id;
    this.setFilters(model.filters);
  }

  /**
   * @param filters
   */
  setFilters(filters) {
    this.filters = filters?.map((filter) => new WidgetFilter(filter));
    if (!this.filters.length) {
      this.filters = [WidgetFilter.getDefaultFilter(1)];
    }
  }

  clearInvalidFilters() {
    this.filters = this.filters.filter((filter) => {
      if (!(filter.field && filter.type && filter.values)) {
        return false;
      }
      if (Array.isArray(filter.values)) {
        return filter.values.length;
      }
      return !isEmpty(filter.values.min) || !isEmpty(filter.values.max);
    });
  }

  /**
   * @param value
   */
  setId(value) {
    this.id = value;
  }
}
