import { getters } from '@/modules/core/app/helpers/store';
import { BaseWidgetMetadata } from '@/modules/ta/widget/models/metadata/BaseWidgetMetadata';
import { ApiColumn } from '@/modules/core/app/models/api/ApiColumn';
import { TimeGrouping, WeeklyStartDay } from '@/modules/ta/widget/widget.constants';

export class DatasourcedWidgetMetadata extends BaseWidgetMetadata {
  /**
   * @var {{}}
   */
  data_columns;

  /**
   * @var {string}
   */
  filter_set_id;

  /**
   * @var {boolean}
   */
  is_overriding_date_range;

  /**
   * @var {string}
   */
  start_date_override;

  /**
   * @var {string}
   */
  end_date_override;

  /**
   * @var {string}
   */
  relative_date_range;

  /**
   * @var {boolean}
   */
  compare_to_prior_period;

  /**
   * @var {string}
   */
  comparison_start_date_override;

  /**
   * @var {string}
   */
  comparison_end_date_override;

  /**
   * @var {string}
   */
  comparison_relative_date_range;

  /**
   * @var {string}
   */
  ignore_date_range;

  /**
   * @var {?string}
   */
  time_grouping;

  /**
   * @var {string}
   */
  weekly_start_day;

  /**
   * @var {string}
   */
  chart_palette;

  /**
   * @var {string}
   */
  time_grouping_display;

  constructor(model = {}) {
    super(model);

    try {
      this.data_columns = { selected: [], grouped: [], benchmarks: [] };
      this.data_columns.selected = model.data_columns
        ? model.data_columns.selected?.map((column) => new ApiColumn(column))
        : [];
      this.data_columns.grouped = model.data_columns
        ? model.data_columns.grouped?.map((column) => new ApiColumn(column))
        : [];
      this.data_columns.benchmarks = model.data_columns
        ? model.data_columns.benchmarks?.map((column) => new ApiColumn(column))
        : [];
      this.filter_set_id = model.filter_set_id;
      this.is_overriding_date_range = model.is_overriding_date_range;
      this.start_date_override = model.start_date_override;
      this.end_date_override = model.end_date_override;
      this.relative_date_range = model.relative_date_range;
      this.compare_to_prior_period = model.compare_to_prior_period;
      this.comparison_start_date_override = model.comparison_start_date_override;
      this.comparison_end_date_override = model.comparison_end_date_override;
      this.comparison_relative_date_range = model.comparison_relative_date_range;
      this.ignore_date_range = model.ignore_date_range;
      this.time_grouping = model.time_grouping || TimeGrouping.GROUPING_DAILY;
      this.weekly_start_day =
        model.weekly_start_day ||
        getters.session.getUserSettings()?.getDefaultWeeklyStartDay() ||
        WeeklyStartDay.SUNDAY;
      this.chart_palette = model.chart_palette;
      this.time_grouping_display = model.time_grouping_display;
    } catch (e) {
      Logger.log(e);
    }
  }
}
