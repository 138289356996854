import { WidgetType } from '@/modules/ta/widget/widget.constants';
import { AccountManagerWidget } from '@/modules/ta/widget/models/AccountManagerWidget';
import { Widget } from '@/modules/ta/widget/models/Widget';
import { MediaWidget } from '@/modules/ta/widget/models/MediaWidget';

export class WidgetFactory {
  /**
   * @param {Widget|{}} widget
   * @returns {Widget}
   */
  static build(widget) {
    switch (widget.type) {
      case WidgetType.TYPE_ACCOUNTMANAGER:
        return new AccountManagerWidget(widget);
      case WidgetType.TYPE_MEDIA:
        return new MediaWidget(widget);
      default:
        if (!(widget instanceof Widget)) {
          return new Widget(widget);
        }
    }
    return widget;
  }
}
