/* eslint-disable tap/no-raw-text-js */
import Vue from 'vue';
import Vuex from 'vuex';
import { AppModuleGroup } from './modules/core/app/constants/app.constants';

Vue.use(Vuex);

export const createStore = () => {
  const modules = {};
  // By default, add core store.
  const requireModules = require.context('./modules', true, /\/(core)\/[a-z]+\/store\/index\.js$/);
  requireModules.keys().forEach((modulePath) => {
    const moduleName = modulePath.split('/')[2];
    const moduleConfig = requireModules(modulePath);
    modules[moduleName] = (moduleConfig.default || moduleConfig).store;
  });

  return new Vuex.Store({
    // strict: Env.isDevelopment(),
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules,
  });
};

const ADDED_MODULES = [];
/**
 *
 * @param {Vuex.Store} vuexStore
 * @param {String} project
 * @returns
 */
export const addStoreModules = async (vuexStore, project = AppModuleGroup.TA) => {
  if (ADDED_MODULES.includes(project) || !Object.values(AppModuleGroup).includes(project)) {
    return;
  }

  let loadModules = null;
  switch (project) {
    case AppModuleGroup.TA:
      loadModules = require.context(
        './modules',
        true,
        /\/ta\/[a-z]+\/store\/index\.js$/,
        'lazy-once'
      );
      break;
    case AppModuleGroup.SEO:
      loadModules = require.context(
        './modules',
        true,
        /\/seo\/[a-z]+\/store\/index\.js$/,
        'lazy-once'
      );
      break;
    case AppModuleGroup.TO:
      loadModules = require.context(
        './modules',
        true,
        /\/to\/[a-z]+\/store\/index\.js$/,
        'lazy-once'
      );
      break;
    case AppModuleGroup.AUDIENCE:
      loadModules = require.context(
        './modules',
        true,
        /\/audience\/[a-z]+\/store\/index\.js$/,
        'lazy-once'
      );
      break;
    default:
      loadModules = null;
  }

  if (loadModules) {
    const paths = loadModules.keys();
    for (let iter = 0; iter < paths.length; iter++) {
      const moduleName = paths[iter].split('/')[2];
      const moduleConfig = await loadModules(paths[iter]); // eslint-disable-line no-await-in-loop
      const moduleStore = (moduleConfig.default || moduleConfig).store;
      vuexStore.registerModule(moduleName, moduleStore);
    }
    ADDED_MODULES.push(project);
  }
};
