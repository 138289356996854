'use strict';
import angular from 'angular';
import _ from 'lodash';

import tagDetailsHtmlUrl from './tag.details.html';

angular.module('core.tag.components')
    .component('tagDetails', {
        templateUrl: tagDetailsHtmlUrl,
        controllerAs: 'vm',
        controller: TagDetailsController
    });

function TagDetailsController(
    WidgetFactory,
    DetailsModalFactory,
    TagFactory,
    TagResource,
    TapColorsService
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.getColumn = getColumn;
    vm.tagsDatagridState = WidgetFactory.getDefaultState();
    vm.tagsDatagrid = null;

    vm.onColorChange = onColorChange;
    vm.isNew = isNew;

    function $onInit() {
        vm.model = DetailsModalFactory.getModel();
        vm.metadata = DetailsModalFactory.getMetadata();

        if (vm.model.id) {
            TagResource.getTagValuesGrid(vm.model.id).then(function (json) {
                vm.tagsDatagrid = json.plain()[0];
            });
        } else {
            vm.model.color = TapColorsService.getRandomColor();
        }
    }

    function isNew() {
        return !vm.model.id;
    }

    /**
     * @param field
     * @returns {any}
     */
    function getColumn(field) {
        return _.find(vm.metadata, {field: field});
    }

    function onColorChange(newValue) {
        vm.model.color = newValue;
        DetailsModalFactory.updateTitle(TagFactory.getDisplayTitle(vm.model));
    }
}