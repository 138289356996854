'use strict';
import angular from 'angular';
import './user.constants';
import './scripts/user.ctrls';
import './scripts/user.directives';
import './scripts/user.services';
import './scripts/user.models';
import './image/user.image.directives';
import './language/user.language.components';
import './scripts/log/userlogins.resources';

angular.module('core.user', [
    'core.user.ctrls',
    'core.user.directives',
    'core.user.services',
    'core.user.models',
    'core.user.constants',
    'user.image.directives',
    'user.language.components',
    'core.userlogins.resources'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {
        $stateProvider
            .state('users', {
                parent:'layout',
                url: '/users',
                controller: 'UserListController',
                templateUrl: AppConfig.MAGE_URL + 'user/manage_users',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('users', true)
                }
            })
            .state('users.filter', {
                parent:'layout',
                url: '/users/type/:user_type/status/:status',
                controller: 'UserListController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'user/manage_users?user_type=' + $stateParams.user_type + '&status=' + $stateParams.status;
                }
            })
            .state('users.add', {
                parent:'layout',
                url: '/users/add',
                data: { isNewEntity: true, primaryKey: 'user_id' },
                controller: 'UserController',
                templateUrl: AppConfig.MAGE_URL + 'user/add_user'
            })
            .state('users.addfromclient', {
                parent:'layout',
                url: '/users/addfromclient/client_id/:client_id',
                data: { isNewEntity: true, primaryKey: 'user_id' },
                controller: 'UserController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'user/add_user?client_id=' + $stateParams.client_id;
                }
            })
            .state('users.detail', {
                parent:'layout',
                url: '/users/detail/:id',
                controller: 'UserController',
                templateUrl: function ($stateParams) {
                    return AppConfig.MAGE_URL + 'user/view_user/user_id/' + $stateParams.id + '?tmpl=' + (new Date().getTime());
                },
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('users.detail')
                }
            })
            .state('users.changepassword', {
                parent:'layout',
                url: '/changepassword',
                controller: 'UserController',
                templateUrl: AppConfig.MAGE_URL + 'user/change_password'
            })
            .state('users.editsettings', {
                parent:'layout',
                url: '/editsettings',
                controller: 'UserController',
                templateUrl: AppConfig.MAGE_URL + 'user/edit_settings',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('users.editsettings')
                }
            })
    }]);