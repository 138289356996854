export class SessionState {
  /**
   * @var UserSettings
   */
  userSettings;

  /**
   * @var {Object}
   */
  status = {
    isLoggingIn: false,
    isLoggingOut: false,
  };

  /**
   * @var {BrandMapping}
   */
  brandMapping;

  /**
   * @type {string}
   */
  loginRedirectPath;

  /**
   * @type {boolean}
   */
  showWelcomeModal;

  /**
   * @type {string}
   */
  clientCurrency;
}
