import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { CustomTranslation } from '@/modules/core/customtranslation/models/CustomTranslation';
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';

export class CustomTranslationResource extends BaseEntityResource {
  constructor() {
    super(RouteGroup.CUSTOM_TRANSLATIONS, CustomTranslation);
  }

  /**
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  async logFileExport() {
    return apiAxios.post(`/${this.routeGroup}/logfileexport`);
  }
}

export default new CustomTranslationResource();
