'use strict';
import angular from 'angular';

import panelSettingsReportHtmlUrl from './panel.settings.report.html';
import panelSettingsPageHtmlUrl from './panel.settings.page.html';
import panelSettingsBaseHtmlUrl from './panel.settings.base.html';
import panelSettingsWidgetHtmlUrl from './panel.settings.widget.html';
import panelSettingsImageHtmlUrl from './panel.settings.image.html';
import panelSettingsShapeHtmlUrl from './panel.settings.shape.html';
import panelSettingsIconHtmlUrl from './panel.settings.icon.html';
import panelSettingsTextHtmlUrl from './panel.settings.text.html'
import { CurrencyConstants } from 'coreModules/shared/scripts/app.constants';
import $ from 'jquery';

angular.module('exportbuilder.panel.components')
    .component('exportBuilderReportSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsReportHtmlUrl,
        controller: ExportBuilderReportSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderPageSettings', {
        bindings: {
            page: '<'
        },
        templateUrl: panelSettingsPageHtmlUrl,
        controller: ExportBuilderReportPageSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderBaseElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsBaseHtmlUrl,
        controller: ExportBuilderBaseElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderWidgetElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsWidgetHtmlUrl,
        controller: ExportBuilderWidgetElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderImageElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsImageHtmlUrl,
        controller: ExportBuilderImageElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderShapeElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsShapeHtmlUrl,
        controller: ExportBuilderShapeElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderIconElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsIconHtmlUrl,
        controller: ExportBuilderIconElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderTextElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsTextHtmlUrl,
        controller: ExportBuilderTextElementSettingsController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderReportSettingsController(
    ExportBuilderDashboardService
) {
    var vm = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.report = ExportBuilderDashboardService.getReport();
    }
}

/**
 * @ngInject
 */
function ExportBuilderReportPageSettingsController(
    ExportBuilderElementActionService,
    ExportBuilderDashboardService
) {
    var vm = this;
    vm.onPageTitleChange = onPageTitleChange;
    vm.setEnableExecutiveSummary = setEnableExecutiveSummary;

    function onPageTitleChange() {
        ExportBuilderElementActionService.setPageTitle(ExportBuilderDashboardService.getBuilder().currentPage, vm.page.title);
    }

    function setEnableExecutiveSummary() {
        vm.page.executive_summary_enabled
            ? ExportBuilderElementActionService.setEnableExecutiveSummary(vm.page)
            : ExportBuilderElementActionService.unsetEnableExecutiveSummary(vm.page);
    }
}

/**
 * @ngInject
 */
function ExportBuilderBaseElementSettingsController(
    ExportBuilderElementActionService
) {
    var vm = this;
    vm.onShowEveryPageChange = onShowEveryPageChange;
    vm.onSnapToGridChange = onSnapToGridChange;
    vm.onSetBackgroundChange = onSetBackgroundChange;

    function onShowEveryPageChange() {
        ExportBuilderElementActionService.makeItemOnEveryPage(vm.element.show_on_every_page);
    }

    function onSnapToGridChange() {
        ExportBuilderElementActionService.setSnapToGrid(vm.element.snap_to_grid);
    }

    function onSetBackgroundChange() {
        ExportBuilderElementActionService.setToBackground(vm.element.metadata.set_as_background);
    }
}

/**
 * @ngInject
 */
function ExportBuilderWidgetElementSettingsController(
    ExportBuilderDashboardItemService
) {
    var vm = this;
    vm.toggleDrawOption = toggleDrawOption;

    function toggleDrawOption(drawOptionKey) {
        ExportBuilderDashboardItemService.toggleWidgetDrawOption(vm.element, drawOptionKey, vm.element.widget.metadata.draw_options[drawOptionKey].value);
    }
}

/**
 * @ngInject
 */
function ExportBuilderImageElementSettingsController(
    ExportBuilderDashboardItemService,
    ReportElementImageSupportedFillModes
) {
    var vm = this;
    vm.ReportElementImageSupportedFillModes = ReportElementImageSupportedFillModes;
    vm.onFillModeClicked = onFillModeClicked;

    function onFillModeClicked(fillMode) {
        ExportBuilderDashboardItemService.setImageFillMode(vm.element, fillMode);
    }
}

/**
 * @ngInject
 */
function ExportBuilderShapeElementSettingsController(
    ExportBuilderDashboardItemService
) {
    var vm = this;
}

/**
 * @ngInject
 */
function ExportBuilderIconElementSettingsController(
    PubSub,
    $ExportBuilderIconModalEvents
) {
    var vm = this;
    vm.onIconClicked = onIconClicked;

    function onIconClicked() {
        PubSub.emit($ExportBuilderIconModalEvents.OPEN_EDIT, vm.element.id);
    }

}

/**
 * @ngInject
 */
function ExportBuilderTextElementSettingsController(
    ExportBuilderElementActionService
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.onTextTitleChange = onTextTitleChange;

    function $onInit() {
    }

    function $onDestroy() {
    }

    function onTextTitleChange() {
        ExportBuilderElementActionService.setTextTitle(vm.element.metadata.design_options.text);
    }
}