export const ColumnFormat = {
  FORMAT_STRING: 'string',
  FORMAT_ID: 'id',
  FORMAT_INTEGER: 'integer',
  FORMAT_PERCENT: 'percent',
  FORMAT_CURRENCY: 'currency',
  FORMAT_BOOLEAN: 'bool',
  FORMAT_DECIMAL: 'decimal',
  FORMAT_ARRAY: 'array',
  FORMAT_DATE: 'date',
  FORMAT_TIME: 'time',
  FORMAT_DATETIME: 'datetime',
  FORMAT_LINK: 'link',
  FORMAT_URL: 'url',
  FORMAT_IMAGE: 'image',
  FORMAT_CALLBACK: 'callback',
  FORMAT_COORDINATE: 'coordinate',
  FORMAT_OBJECT: 'object',
  FORMAT_PHONE_NUMBER: 'phonenumber',
  FORMAT_ENUM: 'enum',
  FORMAT_SET: 'set',
  FORMAT_AVATAR: 'avatar', // This format is not from backend
  FORMAT_CLICK_TO_VIEW_LINK: 'clicktoview_link',
  FORMAT_COLOR: 'color',
  FORMAT_TREE_SELECT_ARRAY: 'tree_select_array', // This format is using to populate tree-select dropdown in inline edit cell
};

export const PostProcessType = {
  POSTPROCESS_NONE: 'none',
  POSTPROCESS_NUMERIC: 'numeric',
  POSTPROCESS_DECIMAL: 'decimal',
  POSTPROCESS_BOOLEAN: 'bool',
  POSTPROCESS_JSON: 'json',
  POSTPROCESS_COMMA_TO_ARRAY: 'comma_delimited_to_array',
  POSTPROCESS_SERVICE_ID_STRING: 'service_id_string',
  POSTPROCESS_DATE: 'date',
  POSTPROCESS_DATETIME: 'datetime',
  POSTPROCESS_TIME: 'time',
  POSTPROCESS_PHONE_NUMBER: 'phonenumber',
  POSTPROCESS_CAPITALIZE: 'capitalize',
  POSTPROCESS_DATATYPE: 'datatype',
  POSTPROCESS_CATEGORY_DATATYPE: 'category_datatype',
  POSTPROCESS_HTML: 'html',
  POSTPROCESS_IFRAME: 'iframe',
  POSTPROCESS_YES_NO: 'yes_no',
  POSTPROCESS_US_PHONE_NUMBER: 'us_phone_number',
  POSTPROCESS_NL2BR: 'nl2br',
  POSTPROCESS_URL: 'url',
  POSTPROCESS_URL_IMAGE: 'url_image',
  POSTPROCESS_CALLBACK: 'callback',
  POSTPROCESS_CUSTOM: 'custom',
};

export const EditControlType = {
  TYPE_NONE: 'none',
  TYPE_TEXT: 'text',
  TYPE_NUMBER: 'number',
  TYPE_PHONE_NUMBER: 'phone_number',
  TYPE_EMAIL: 'email',
  TYPE_TEXTAREA: 'textarea',
  TYPE_SINGLE_SELECT: 'single_select',
  TYPE_MULTI_SELECT: 'multi_select',
  TYPE_CURRENCY: 'currency',
  TYPE_CHECKBOX: 'checkbox',
  TYPE_RADIO: 'radio',
  TYPE_CUSTOM: 'custom',
};

export const OrderBy = {
  ORDER_ASC: 'asc',
  ORDER_DESC: 'desc',
};

export const DisplayMetricsFormat = {
  FORMAT_TEXT: 'text',
  FORMAT_NUMBER: 'number',
};

export const MetricsPrefix = {
  CALCULATION: 'calculation_',
  CATEGORY: 'category_metrics',
};
