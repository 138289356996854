'use strict';
import angular from 'angular';

angular.module('core.abtest.directives', [])

    .directive('abTest', abTest);

abTest.$inject = ['$timeout'];
function abTest($timeout) {
    return {
        restrict: 'E',
        // templateUrl: $.core.setHostPath($.core.setCorePath('modules/alert/base/alert.modal.html')),
        controller: 'AbTestController',
        scope: {

        },
        link: function(scope, el) {

        }
    }
}