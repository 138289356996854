<template>
  <FunnelChart
    v-if="canShowChart"
    :config="chartConfig"
    :widget-type="widgetType"
    :disable-error-messages="disableErrorMessages"
    @frame-ended="setIdle"
  />
</template>

<script>
import FunnelChart from '@/modules/core/charts/am5/funnel/components/FunnelChart.vue';
import { LegacyFunnelChartWidgetRenderService } from '@/modules/core/charts/am5/funnel/services/LegacyFunnelChartWidgetRenderService';
import { isEmpty } from 'lodash';
import { dispatches } from '@/modules/core/app/helpers/store';
import { ChartPlace } from '@/modules/core/charts/am5/charts.constants';
import { WidgetEvent } from '@/modules/ta/widget/widget.constants';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { BaseChartWidgetPropsMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetPropsMixin';

export default {
  name: 'LegacyFunnelChartWidget',
  mixins: [BaseChartWidgetPropsMixin],
  components: { FunnelChart },
  data() {
    return {
      comparisonChartConfig: null,
    };
  },
  computed: {
    canShowComparisonChart() {
      return this.hasComparisonData && this.comparisonChartConfig && !isEmpty(this.data);
    },
    shouldBeHalfSized() {
      return this.hasComparisonData && !window.dashExport;
    },
    getWidth() {
      return this.shouldBeHalfSized ? '50%' : '100%';
    },
  },
  mounted() {
    EventBus.listen(`${WidgetEvent.RELOAD_WIDGET}-${this.widget.id}`, () => {
      this.init();
    });
    this.init();
  },
  methods: {
    async init() {
      dispatches.dashboardExport.setUnrenderedWidgetIds([this.widget.id]);
      const widget = await this.setDrillDownOptions();

      const renderer = new LegacyFunnelChartWidgetRenderService(
        widget,
        this.chartPalette,
        false,
        this.isExporting
      );

      let chartTransparency = 1;
      if (widget.report_id && this.elementMetadata) {
        chartTransparency = this.elementMetadata?.base_options?.background_color ? 1 : 0;
      }

      const config = renderer.getChartConfig({ isComparison: false });
      config.setAll({
        chartPlace: widget.report_id ? ChartPlace.REPORT : ChartPlace.DASHBOARD,
        clientCurrencySymbol: this.clientCurrencySymbol,
        chartTransparency,
        widget,
      });
      this.chartConfig = config;

      if (this.hasComparisonData) {
        const priorRenderer = new LegacyFunnelChartWidgetRenderService(
          widget,
          this.chartPalette,
          this.hasComparisonData,
          this.isExporting
        );

        const comparisonConfig = priorRenderer.getChartConfig({
          isComparison: this.hasComparisonData,
        });
        this.chartConfig.comparisonData = comparisonConfig.data;
        this.chartConfig.comparisonDataFormats = comparisonConfig.dataFormats;
      }
    },
    async setIdle() {
      dispatches.dashboardExport.setWidgetAsRendered(this.widget.id);
    },
  },
};
</script>
