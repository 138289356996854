'use strict';
import angular from 'angular';

angular.module('core.service.ctrls')

    .controller('ServiceDrilldownController', ServiceDrilldownController);

/**
 * @ngInject
 */
function ServiceDrilldownController (
    PageEntity,
    ServiceDataFactory
){
    function init() {
        // $scope.$on('design:page:callback', function() {
        // UNCOMMENT TO INSERT LOGIC AFTER DESIGN IS RENDERED
        // });

        ServiceDataFactory.setPageEntity(PageEntity.PAGE_DRILLDOWN);
    }

    init();
}