'use strict';
import angular from 'angular';
import './base/executivesummary.constants';
import './base/executivesummary.components';
import './base/executivesummary.services';
import './base/executivesummary.models';
import './base/executivesummary.resources';
import './panel/executivesummarypanel.components';
import './create/create.executivesummary.components';
import './create/create.executivesummary.modal.directives';
import exectiveSummariesHtmlUrl from './base/executivesummaries.html';

angular.module('page.executivesummary', [
    'executivesummary.constants',
    'executivesummary.components',
    'executivesummary.services',
    'executivesummary.models',
    'executivesummary.resources',
    'executivesummarypanel.components',
    'create.executivesummary.components',
    'create.executivesummary.modal.directives'
])
    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {
        $stateProvider
            .state('executivesummary', {
                parent: 'layout',
                url: '/executivesummary',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('executivesummary'),
                    pageType: function(PageType) {
                        // Note: Will this be available to non admins?
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_EXECUTIVE_SUMMARY;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: exectiveSummariesHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);