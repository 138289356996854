<template>
  <b-loading :active="true" class="absolute">
    <div class="loader-container">
      <div class="flex flex-center loader-inner">
        <div class="spin-loader spin-loader-size"></div>
        <i class="icon icomoon-geochart absolute xx-large-font"></i>
        <span class="text">{{ $t('Fetching Data...') }}</span>
      </div>
    </div>
  </b-loading>
</template>

<script>
export default {
  name: 'MapboxLoadingState',
};
</script>

<style scoped lang="scss">
.absolute {
  position: absolute;
}
.spin-loader-size {
  border-width: rem(7px) !important;
  width: rem(70px);
  height: rem(70px);
}
.text {
  position: absolute;
  margin-bottom: rem(-118px);
  background: white;
  border-radius: rem(10px) rem(10px) rem(10px) rem(10px);
  padding: rem(5px);
  font-size: smaller;
}
.xx-large-font {
  font-size: xx-large !important;
}
</style>
