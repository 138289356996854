import { isEmpty, isNil } from 'lodash';
import { getters } from '@/modules/core/app/helpers/store';

export class WidgetContextService {
  /**
   * @returns {boolean}
   */
  static isDashboardContext() {
    return !!getters.dashboard.getDashboard();
  }

  /**
   * @returns {boolean}
   */
  static isReportStudioContext() {
    return !!getters.reportstudio.getReport();
  }

  /**
   * @returns {SelectedEntity|null}
   */
  static getSelectedEntity() {
    return this.isDashboardContext() ? getters.dashboard.getSelectedEntity() : null;
  }

  /**
   * @returns {DashboardFilterItem}
   */
  static getCurrentActiveFilter() {
    return this.isDashboardContext() ? getters.dashboard.getCurrentActiveFilter() : null;
  }

  static getClientFilter() {
    if (getters.dashboard.isClientFilter()) {
      return this.getCurrentActiveFilter();
    }
    return this.getSelectedEntity();
  }

  /**
   * @returns {boolean|}
   */
  static isViewingAsClient() {
    if (!isEmpty(this.getSelectedEntity())) {
      return true;
    }
    if (this.isDashboardContext() && getters.export.isExporting()) {
      return (
        !isNil(getters.export.getConfig().reportVars.report_descriptor) ||
        !isEmpty(this.getSelectedEntity())
      );
    }
    return getters.dashboard.isClientFilter();
  }

  static getParentId() {
    return getters.dashboard.getDashboard()?.id || getters.reportstudio.getReport()?.id;
  }

  /**
   * @returns {boolean|*}
   */
  static isDemoModeEnabled() {
    return this.isDashboardContext() && getters.dashboard.getDashboard().isDemoModeEnabled();
  }
}
