'use strict';
import angular from 'angular';
import './widget.chart.ctrls';
import './widget.chart.directives';
import './widget.chart.services';
import './serial/widget.serialchart.module';
import './am5/widget.am5.module';
import './slice/widget.slicechart.module';
import './gauge/widget.gaugechart.module';
import './geo/widget.geochart.module';
import './mapbox/widget.mapbox.module';

angular.module("widget.chart", [
  "widget.chart.ctrls",
  "widget.chart.directives",
  "widget.chart.services",
  "widget.serialchart",
  "widget.slicechart",
  "widget.gaugechart",
  "widget.geochart",
  "widget.mapbox",
  "widget.am5",
]);
