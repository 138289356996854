'use strict';
import _ from 'lodash';
import angular from 'angular';
import changeLayoutFooterHtmlUrl from './changelayoutfooter.html';
import copyPageModalHtmlUrl from './copy.page.modal.html';
import pageHtmlUrl from './page.html';
import editPageModalHtmlUrl from './edit.page.modal.html';
import editPageModalWarningHtmlUrl from './edit.page.modal.warning.html';
import editPageReportOptionsModalHtmlUrl from './edit.page.reportoptions.modal.html';
import viewDashboardSectionAuditLogsModalHtmlUrl from "./view.dashboard.section.audit.logs.modal.html";
import {AppConfig} from "../../../../../../grok/src/modules/core/app/constants/app.constants";
import {AuditLogsRouteNames} from "../../../../../../grok/src/modules/core/auditlog/auditlog.constants";


angular.module('design.page.directives', [])
    .directive('designPage', designPage)
    .directive('editPageModal', editPageModal)
    .directive('editPageReportOptionsModal', editPageReportOptionsModal)
    .component('changeLayoutFooter', {
        templateUrl: changeLayoutFooterHtmlUrl,
        bindings: false,
        controllerAs: 'vm',
        controller: ChangeLayoutFooterController
    })
    .component('copyPageModal', {
        templateUrl: copyPageModalHtmlUrl,
        bindings: false,
        controllerAs: 'vm',
        controller: copyPageModalController
    })
    .component('viewDashboardSectionAuditLogsModal', {
        bindings: false,
        templateUrl: viewDashboardSectionAuditLogsModalHtmlUrl,
        controller: ViewDashboardSectionAuditLogsModalController,
        controllerAs: 'vm'
    })

    function designPage() {
        return {
            restrict: 'A',
            scope: {
                page: '='
            },
            controller: 'PageController',
            templateUrl: pageHtmlUrl,
        }
    }

    function editPageModal() {
        return {
            restrict: 'E',
            controller: 'EditPageController',
            templateUrl: editPageModalHtmlUrl,
            scope: {},
            link: function(scope, el) {
                scope.editPageModalWarningHtmlUrl = editPageModalWarningHtmlUrl;
                var $modal = el.find('div.modal');

                scope.hideModal = function() {
                    $modal.modal('hide');
                }
            }
        }
    }

    function editPageReportOptionsModal() {
        return {
            restrict: 'E',
            controller: 'EditPageReportOptionsController',
            templateUrl: editPageReportOptionsModalHtmlUrl,
            scope: {
                page: '='
            },
            link: function(scope, el) {
                var $modal = el.find('div.modal');

                scope.hideModal = function() {
                    $modal.modal('hide');
                }
            }
        }
    }

    /**
     * @ngInject
     */
    function ChangeLayoutFooterController(
        $timeout,
        $LayoutEvents,
        DesignFactory,
        LayoutFactory,
        PubSub
    ) {
        var vm = this;
        var layouts;
        var currentLayout;

        vm.$onInit = $onInit;
        vm.$onDestroy = $onDestroy;
        vm.$postLink = $postLink;
        vm.canShowFooter = canShowFooter;
        vm.changeLayout = LayoutFactory.changeLayout;

        function init() {
            vm.isActive = false;

            $timeout(function() {
                vm.isActive = true;
                layouts = _.toArray(DesignFactory.getCurrentPage().layouts).sort(function(layoutA,layoutB) {
                    return layoutA.display_order - layoutB.display_order;
                });
                currentLayout = DesignFactory.getCurrentLayout();
                var currentIndex = _.findIndex(layouts, { id: currentLayout.id });

                vm.prevLayout = layouts[currentIndex - 1] || layouts[layouts.length - 1];
                vm.nextLayout = layouts[currentIndex + 1] || layouts[0];

            }, 500);
        }

        function $onInit() {
            init();
            registerEvents();
        }

        function $onDestroy() {
            _unregisterEvents();
        }

        function $postLink() {
        }

        function canShowFooter() {
            return _.size(layouts) > 1;
        }

        function registerEvents() {
            PubSub.on($LayoutEvents.CHANGED, init);
        }

        function _unregisterEvents() {
            PubSub.off($LayoutEvents.CHANGED, init);
        }
    }

    /**
     * @ngInject
     */
    function copyPageModalController(
        $state,
        DesignFactory,
        PageDataGridFactory,
        PageCopyOptions,
        UIFactory
    ) {
        var vm = this;
        vm.PageCopyOptions = PageCopyOptions;

        vm.$onInit = $onInit;
        vm.copyPage = copyPage;
        vm.closeModal = closeModal;

        function $onInit() {
            vm.options = PageDataGridFactory.getCloneOptions();
            vm.shared = PageDataGridFactory.shared;
        }

        function copyPage() {
            vm.shared.state.isLoading = true;
            PageDataGridFactory.copyPage(vm.shared.pageId, vm.options).then(function(json) {
                //TODO: Verify the returned data from the backend
                var pageId = json;
                DesignFactory.getNavPageList().then(function() {
                    vm.shared.state.isLoading = false;
                    closeModal();
                    UIFactory.notify.showSuccess('Dashboard successfully cloned');
                    DesignFactory.$refreshMenu();
                    $state.go('dash', {id: pageId});
                });
            }, function(err) {
                UIFactory.notify.showError('There was an error cloning your dashboard');
                vm.shared.state.isLoading = false;

            });
        }

        function closeModal() {
            PageDataGridFactory.closeModal();
        }
    
    }

    /**
     * @ngInject
     */
    function ViewDashboardSectionAuditLogsModalController(
        PubSub,
        UIFactory,
        DesignFactory,
        ViewAuditLogsEvent
    ) {
        var vm = this;

        vm.$onInit = $onInit;
        vm.$onDestroy = $onDestroy;
        vm.closeModal = closeModal;
        vm.viewDashboardAuditLogs = viewDashboardAuditLogs;
        vm.viewSectionAuditLogs = viewSectionAuditLogs;

        function $onInit() {
            _registerEvents();
        }

        function $onDestroy() {
            _unregisterEvents();
        }

        function _viewAuditLogs(data) {
            vm.currentPage = data.page;
            vm.currentLayout = data.layout;
            UIFactory.showModal('view-dashboard-section-audit-logs-modal', {
                backdrop: true,
                keyboard: true
            });
        }

        function closeModal() {
            UIFactory.hideModal('view-dashboard-section-audit-logs-modal');
        }

        /**
         * @private
         */
        function _registerEvents() {
            PubSub.on(ViewAuditLogsEvent.VIEW_DASHBOARD_SECTION_AUDIT_LOGS, _viewAuditLogs);
        }

        /**
         * @private
         */
        function _unregisterEvents() {
            PubSub.off(ViewAuditLogsEvent.VIEW_DASHBOARD_SECTION_AUDIT_LOGS, _viewAuditLogs);
        }

        function viewDashboardAuditLogs() {
            window.open(
                `${AppConfig.NUI_PATH}/${AuditLogsRouteNames.AUDIT_LOGS}/dash/${vm.currentPage.id}`,
                '_blank'
            );
        }

        function viewSectionAuditLogs() {
            window.open(
                `${AppConfig.NUI_PATH}/${AuditLogsRouteNames.AUDIT_LOGS}/sections/${vm.currentLayout.id}`,
                '_blank'
            );
        }
    }