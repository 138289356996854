'use strict';
import angular from 'angular';
import _ from 'lodash';
import { Locale } from 'coreModules/shared/scripts/constants/language.constants';

import exportBuilderHtmlUrl from './exportbuilder.html';

angular.module('exportbuilder.components', [])
    .component('exportBuilder', {
        bindings: {},
        templateUrl: exportBuilderHtmlUrl,
        controller: ExportBuilderController,
        controllerAs: 'vm'
    });

/**
 * Export Builder controller
 * @ngInject
 */
function ExportBuilderController(
    $scope,
    $window,
    $stateParams,
    $state,
    ExportBuilderRouterState,
    ExportBuilderDashboardService,
    ExportBuilderFacadeUIService,
    WidgetBuilderUIService,
    DrawOptionPanelFactory,
    ReportElementTypes,
    $DrawOptionPanelEvents,
    LanguageService,
    ReportStudioTemplateDataService,
    PubSub,
    $SlidePanelEvents,
    SlidePanelFactory,
    $ExportBuilderDashboardEvents,
    $ExportBuilderDashboardUIEvents,
    FullScreenUtilUIService,
    UndoRedoService,
    ExportBuilderService,
    gettextCatalog,
    ExecutiveSummaryFactory
) {
    var vm = this;
    vm.loaderClasses = ['delayed-show-rl'];
    vm.loaderTitle = gettextCatalog.getString('Loading Report Studio');
    vm.loaderIcon = 'icomoon-brush-pen';
    vm.canEditReport = false;

    var exportDrawOptionsPanelId = 'export-draw-options';

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.isLoading = isLoading;
    vm.isPresentationMode = isPresentationMode;
    vm.getExportBuilderClasses = getExportBuilderClasses;

    vm.ReportElementTypes = ReportElementTypes;

    function $onInit() {
        _registerEvents();

        vm.builder = ExportBuilderDashboardService.getBuilder();

        const { sample_data } = $stateParams;

        const options = {};
        if (!_.isUndefined(sample_data)) {
            options['sample_data'] = sample_data;
        }

        vm.loading = true;
        ExportBuilderDashboardService.initWithReportId($stateParams.id, options)
            .then(function (report) {
                if (!_.isEmpty(ReportStudioTemplateDataService.getReport())) {
                    let language = ReportStudioTemplateDataService.getReportLanguage();
                    if (!_.isNil(language)) {
                        language = language.substring(0,2);
                        LanguageService.setApplicationLanguage({locale: language});
                    }
                    else {
                        //put to english if report has no languages set
                        LanguageService.setApplicationLanguage({locale: Locale.EN});
                    }
                }
                vm.canEditReport = ExportBuilderDashboardService.canEditReport();

                let currentPage = ExportBuilderDashboardService.getBuilder().currentPage;

                ExecutiveSummaryFactory.fetchExecutiveSummary(null, $stateParams.id, currentPage.id);
            })
            .finally(function () {
                vm.loading = false;
            });
    }

    function $onDestroy() {
        _reset();
        _saveReport();
        _updateReportThumbnail();
        _clearUndoRedoState();
        _clearSoftDeletes();
        _setInActive();
        _resetData();
        _closeSlidePanel();
        _unregisterEvents();
    }

    function isLoading() {
        return _.isNil(vm.builder.report) || _.isNil(vm.builder.currentPage) || vm.loading;
    }

    function isPresentationMode() {
        return ExportBuilderFacadeUIService.getPresentationMode();
    }

    function getExportBuilderClasses() {
        const classes = {};

        classes['presentation-mode'] = vm.isPresentationMode();
        classes['is-widget-builder-active'] = WidgetBuilderUIService.isActive();

        if (vm.builder.report) {
            classes['cannot-edit'] = !vm.builder.report.can_be_edited;
        }

        return classes;
    }

    function _reset() {
        ExportBuilderFacadeUIService.resetState();
    }

    function _saveReport() {
        ExportBuilderDashboardService.saveEntireReport()
    }

    function _updateReportThumbnail() {
        ExportBuilderService.refreshThumbnail(ExportBuilderDashboardService.getBuilder().report)
    }

    function _clearUndoRedoState() {
        UndoRedoService.clear();
    }

    function _clearSoftDeletes() {
        ExportBuilderService.clearSoftDeletedElements(ExportBuilderDashboardService.getBuilder().report)
    }

    function _setInActive() {
        ExportBuilderDashboardService.setActive(false);
    }

    function _resetData() {
        ExportBuilderDashboardService.resetData();
        ReportStudioTemplateDataService.setReport(null);
    }

    function _closeSlidePanel() {
        SlidePanelFactory.closeAll();
    }

    function _onBackPressed() {
        FullScreenUtilUIService.exitFullScreen();

        vm.loading = true;
        vm.loaderTitle = 'Loading admin';
        vm.loaderClasses = ['delayed-show'];
        vm.loaderIcon = 'icomoon-brush-pen';
        $window.location.hash = '#/reportstudio';
        // $state.go(ExportBuilderRouterState.EXPORT_BUILDER_ADMIN);
    }

    function _onSetLoadingState() {
        vm.loading = true;
        vm.loaderTitle = 'Loading';
        vm.loaderClasses = ['delayed-show'];
        vm.loaderIcon = 'icomoon-brush-pen';
    }

    function _registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE, _onPanelClose);
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _onPanelCloseAll);
        PubSub.on($ExportBuilderDashboardUIEvents.ON_BACK_PRESSED, _onBackPressed);
        PubSub.on($ExportBuilderDashboardEvents.SET_LOADING_STATE, _onSetLoadingState);
    }

    function _unregisterEvents() {
        PubSub.off($SlidePanelEvents.CLOSE, _onPanelClose);
        PubSub.off($SlidePanelEvents.CLOSE_ALL, _onPanelCloseAll);
        PubSub.off($ExportBuilderDashboardUIEvents.ON_BACK_PRESSED, _onBackPressed);
        PubSub.off($ExportBuilderDashboardEvents.SET_LOADING_STATE, _onSetLoadingState);
    }

    function _onPanelClose(sourcePanel) {
        if (sourcePanel === exportDrawOptionsPanelId) {
            vm.drawPanelOptions = null;
        }
    }
    function _onPanelCloseAll(sourcePanel) {
        if (sourcePanel !== exportDrawOptionsPanelId) {
            vm.drawPanelOptions = null;
        }
    }
}