export class PlatformSearchState {
  /**
   * @var {boolean}
   */
  isShowingPanel = false;

  /**
   * @var {string}
   */
  groupName = '';
}
