var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:{ 'is-comparing': _vm.hasComparison },style:([
    _vm.fontColorStyles,
    _vm.containerStyles,
    _vm.calculatedSize,
    _vm.borderStyles,
    _vm.radiusStyles,
    _vm.radiantStyles,
  ]),attrs:{"data-cy":"rectangle-container"}},[(_vm.isLoadingValue)?_c('div',{staticClass:"value"},[_c('p',{staticClass:"subtitle",attrs:{"data-cy":"loader"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Loading...')))])])]):_c('div',[_c('p',{staticClass:"card-label text-align-center",class:_vm.showMetricName,style:(_vm.getFontSizeStyle(
          _vm.DataLabelMultiplierSquare.MIN,
          _vm.DataLabelMultiplierSquare.MAX,
          _vm.MaxFontSizes.METRIC_LABEL
        )),attrs:{"data-cy":"label"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"value-container"},[_c('div',{staticClass:"value"},[_c('span',{style:([
            _vm.fontStyles,
            _vm.getFontSizeStyle(_vm.DataFontMultiplierSquare.MIN, _vm.DataFontMultiplierSquare.MAX),
          ]),attrs:{"data-cy":"value","title":_vm.emptyDataMessage}},[_c('b-tooltip',{attrs:{"active":!!_vm.isFormattedEllipsisActive(),"label":_vm.isFormattedEllipsisActive(),"type":"is-dark","size":"is-small"}},[_c('div',{ref:"formattedTooltip",staticClass:"overflow-value"},[_vm._v(" "+_vm._s(_vm.formattedValue)+" ")])])],1)]),(_vm.hasComparison && _vm.showComparisonArrows)?_c('div',[_c('div',{style:(_vm.getFontSizeStyle(
              _vm.DataComaprisonMultiplierSquare.MIN,
              _vm.DataComaprisonMultiplierSquare.MAX
            ))},[_c('span',{attrs:{"data-cy":"delta-value"}},[_vm._v(_vm._s(_vm.formattedDeltaValue))]),(!_vm.compareAsValue)?_c('span',[_vm._v("%")]):_vm._e(),(_vm.isExportingReportStudio)?_c('svg',{staticClass:"delta-value",class:[_vm.deltaIconClass, _vm.deltaValueClasses],style:(_vm.getIconSizeStyle(
                _vm.DataComaprisonMultiplierSquare.MIN,
                _vm.DataComaprisonMultiplierSquare.MAX
              ))},[_c('use',{attrs:{"xlink:href":"","href":`#${_vm.deltaIconClass}`}})]):_c('b-icon',{staticClass:"delta-value",class:_vm.deltaValueClasses,style:(_vm.getFontSizeStyle(
                _vm.DataComaprisonMultiplierSquare.MIN,
                _vm.DataComaprisonMultiplierSquare.MAX
              )),attrs:{"pack":"fa","custom-class":_vm.iconClass,"data-cy":"delta-icon"}})],1),_c('span',{staticClass:"subtitle",style:([
            _vm.getFontSizeStyle(
              _vm.DataComaprisonFontMultiplierSquare.MIN,
              _vm.DataComaprisonFontMultiplierSquare.MAX
            ),
          ]),attrs:{"data-cy":"delta-subtitle"}},[_vm._v(" "+_vm._s(_vm.$t('vs'))+" "),_c('span',[_vm._v(_vm._s(_vm.formattedComparisonValue))]),_vm._v(" "+_vm._s(_vm.$t('prev.'))+" ")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }