'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('reportingprofile.services', [])

    .service('ReportingProfileFactory', ReportingProfileFactory);

/**
 * @ngInject
 */
function ReportingProfileFactory(Restangular, ColumnValueType) {
    var reportingProfile = Restangular.service('reportingprofiles');
    var values = reportingProfile.one('values');

    /**
     * Get values for all possible distinct specific field
     * @param fields
     * @param queryParams
     * @returns {*}
     */
    function getFieldValues(fields, queryParams) {
        return values.one(ColumnValueType.DISTINCT)
            .get(_.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));
    }

    return {
        reportingProfile: reportingProfile,
        values: values,
        getFieldValues: getFieldValues
    }
}