import { commits, getters } from '@/modules/core/app/helpers/store';
import UserService from '@/modules/ta/user/services/UserService';

export const actions = {
  /**
   * @param {ListDataSet} dataSet
   * @returns {Promise<*>}
   */
  async addListDataSet({}, dataSet) {
    return commits.list.addListDataSet(dataSet);
  },
  /**
   * @param {ListDataSet} dataSet
   * @param listId
   * @param item
   * @returns {Promise<*>}
   */
  async addItem({}, { listId, item }) {
    commits.list.addItem({ listId, item });
  },
  /**
   * @param {ListDataSet} dataSet
   * @param listId
   * @param item
   * @returns {Promise<*>}
   */
  async updateItem({}, { listId, item }) {
    commits.list.updateItem({ listId, item });
  },
  /**
   * @param listId
   * @param itemId
   * @returns {Promise<*>}
   */
  async deleteItem({}, { listId, itemId }) {
    commits.list.deleteItem({ listId, itemId });
  },
  /**
   * @param listId
   * @param value
   * @returns {Promise<*>}
   */
  async setNeedsRefresh({}, { listId, value }) {
    commits.list.setNeedsRefresh({ listId, value });
  },
  /**
   * @param listId
   * @returns {Promise<*>}
   */
  async removeDataSet({}, listId) {
    commits.list.removeDataSet(listId);
  },
  /**
   * @param state
   * @param {ListParamsState} paramsState
   */
  setListParamsState({}, paramsState) {
    commits.list.setListParamsState(paramsState);
  },

  async loadUserListSettings({}) {
    const settings = await UserService.getUserListSettings(getters.session.getUser().id);
    Object.values(settings).forEach((setting) => {
      commits.list.setUserListSettings(setting);
    });
  },

  async setUserListSettings({}, listSettings) {
    if (!listSettings.user_id) {
      listSettings.user_id = getters.session.getUser().id;
    }
    UserService.setUserListSettings(listSettings);
  },

  /**
   *
   */
  clearListParamsState({}) {
    commits.list.clearListParamsState();
  },
};
