import { commits } from '@/modules/core/app/helpers/store';
import { memoize } from '@/modules/core/app/utils/ArrayUtil';
import { RelativeDateRangeKey } from '@/modules/core/daterange/daterange.constants';

export const mutations = {
  /**
   * @param state
   * @param {DateRange} dateRange
   */
  setDateRange(state, dateRange) {
    state.dateRange = dateRange;

    commits.daterange.updateDateRange();
  },

  /**
   *
   * @param state
   * @param startDate
   * @param endDate
   * @param comparisonStartDate
   * @param comparisonEndDate
   */
  updateCurrentAndPriorDateRange(
    state,
    { startDate, endDate, comparisonStartDate, comparisonEndDate }
  ) {
    state.dateRange.start_date = startDate;
    state.dateRange.end_date = endDate;
    state.dateRange.comparison_start_date = comparisonStartDate;
    state.dateRange.comparison_end_date = comparisonEndDate;

    commits.daterange.updateDateRange();
  },

  /**
   * @param state
   * @param key
   */
  updateRelativeDateRangeKey(state, key) {
    state.dateRange.relative_date_range_key = key;
  },
  /**
   * @param state
   * @param value
   */
  setComparisonActive(state, value) {
    state.dateRange.is_comparison_active = value;

    commits.daterange.updateDateRange();
  },
  /**
   * @param state
   * @param startDate
   * @param endDate
   */
  updateCurrentDateRange(state, { startDate, endDate }) {
    state.dateRange.start_date = startDate;
    state.dateRange.end_date = endDate;

    commits.daterange.updateDateRange();
  },
  /**
   * @param state
   * @param startDate
   * @param endDate
   */
  updateComparisonDateRange(state, { startDate, endDate }) {
    state.dateRange.comparison_start_date = startDate;
    state.dateRange.comparison_end_date = endDate;

    commits.daterange.updateDateRange();
  },
  /**
   * @param state
   * @param key
   */
  updateComparisonPeriod(state, key) {
    state.dateRange.comparison_period = key;
  },
  /**
   * @param state
   * @param ranges
   */
  setRelativeDateRanges(state, ranges) {
    state.relativeDateRanges = memoize(ranges, 'key');
  },
  /**
   * @param state
   * @param ranges
   */
  updateComparisonRelativeDateRanges(state, ranges) {
    state.relativeDateRanges[RelativeDateRangeKey.PRIOR_PERIOD] =
      ranges[RelativeDateRangeKey.PRIOR_PERIOD];
    state.relativeDateRanges[RelativeDateRangeKey.PRIOR_YEAR] =
      ranges[RelativeDateRangeKey.PRIOR_YEAR];
  },
  /**
   * Any date range change, this is called
   */
  updateDateRange() {
    /*  */
  },
};
