import { DatasourcedWidgetMetadata } from '@/modules/ta/widget/models/metadata/DataSourcedWidgetMetadata';
import { GeoChartDrawOption } from '@/modules/ta/widget/models/drawoptions/GeoChartDrawOption';
import { GeoCodeType } from '@/modules/core/maps/maps.constants';

export class GeoChartWidgetMetadata extends DatasourcedWidgetMetadata {
  /**
   * @var {string}
   */
  map_id;

  /**
   * @var {string}
   */
  geo_code;

  /**
   * @var {BaseDrawOption}
   */
  draw_options;

  constructor(model = {}) {
    super(model);

    this.geo_code = model.geo_code || GeoCodeType.WORLD;
    this.map_id = model.map_id;
    this.map_zoom = model.map_zoom;
    this.draw_options = new GeoChartDrawOption(model.draw_options);
  }
}
