'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('overview.ctrls', [])

    .controller('OverviewController', OverviewController);

//
// Overview controller
//
OverviewController.$inject = ['$scope', 'OverviewService','$timeout'];
function OverviewController($scope, OverviewService,$timeout) {
    $timeout(function(){
        $.core.dashboard.services.initialize();
    }, 0, false);


    //Show message to client user if his dashboard isnt generated yet
    if ($scope.user.isOnboarding && !$scope.util.isSuperAdmin()) {
        $.core.main.notify('Your agency has not generated your dashboard yet. Please come back in a few hours and contact them afterwards if you have any questions.', $.globals.notify.warning);
    }
}