import angular from 'angular';
import $ from 'jquery';

angular.module('exportbuilder.layers.directives', [])
    .directive('exportBuilderLayersPanelDirective', exportBuilderLayersPanelDirective);

/**
 * @ngInject
 */
function exportBuilderLayersPanelDirective(
    $timeout,
    PubSub,
    $ExportBuilderDashboardEvents,
    ExportBuilderDashboardService,
    ExecutiveSummaryFactory
) {
    return {
        restrict: 'A',
        link: function($scope, $el) {
            _registerEvents();
            $scope.$on('$destroy', function () {
                _unregisterEvents();
            });

            function _onPageChanged() {
                $timeout(function () {
                    var page = ExportBuilderDashboardService.getBuilder().currentPage;

                    var $page = $('.page.page-'+page.id);
                    var $pages = $el.find('.pages');

                    // if ($page.offset().top + $page.height() > $pages.height()
                    //     || Math.abs($page.offset().top) + $page.height() < $pages.scrollTop()) {
                    $el.find('.pages').animate({
                        scrollTop: $page.offset()?.top
                    }, 0);
                    // }
                    fetchExecutiveSummaries();
                });
            }

            function _loadExecutiveSummary() {
                $timeout(function () {
                    fetchExecutiveSummaries();
                });
            }

             function fetchExecutiveSummaries() {
                 const page = ExportBuilderDashboardService.getBuilder().currentPage;
                 const report = ExportBuilderDashboardService.getReport();
                 if (report.id && page.id && page.is_executive_summary_page) {
                     ExecutiveSummaryFactory.onReportPageChanged(report.id, page.id);
                 }
             }


            function _registerEvents() {
                PubSub.on($ExportBuilderDashboardEvents.ON_PAGE_CHANGED, _loadExecutiveSummary);
                PubSub.on($ExportBuilderDashboardEvents.ON_PAGE_ADDED, _onPageChanged);
            }

            function _unregisterEvents() {
                PubSub.off($ExportBuilderDashboardEvents.ON_PAGE_CHANGED, _loadExecutiveSummary);
                PubSub.on($ExportBuilderDashboardEvents.ON_PAGE_ADDED, _onPageChanged);
            }
        }
    }
}
