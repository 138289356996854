'use strict';
import angular from 'angular';
import _ from 'lodash';

import leadsDetailsHtmlUrl from './leads.details.html';

angular.module('core.leads.components', [])
    .component('leadsDetails', {
        templateUrl: leadsDetailsHtmlUrl,
        controllerAs: 'vm',
        controller: LeadsDetailsController
    });

function LeadsDetailsController(
    PubSub,
    $MessengerEvents,
    $DetailsModalFactoryEvents,
    DetailsModalFactory,
    MessengerModelFactory,
    EndCustomerResource,
    LeadColumns,
    EndCustomerColumns
) {
    var vm = this;
    vm.endCustomerModel = {};
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.LeadColumns = LeadColumns;
    vm.getColumn = getColumn;
    vm.toogleNewEndCustomerForm = toogleNewEndCustomerForm;
    vm.cancelNewEndCustomer = cancelNewEndCustomer;
    vm.editNotes = editNotes;

    function $onInit() {
        _registerEvents();
        vm.model = DetailsModalFactory.getModel();
        vm.metadata = DetailsModalFactory.getMetadata();
        // Used to create a new end customer in the leads details view with client set to lead's client
        vm.showNewEndCustomerForm = false;
        vm.endCustomerDetailColumns = [];
        var endCustomerColumn = getColumn(LeadColumns.CUSTOMER_NAME);
        vm.endCustomerSelect = {
            options: DetailsModalFactory.getDefaultSelectOptions(endCustomerColumn, vm.model),
            selectedValues: DetailsModalFactory.getSelectedValues(endCustomerColumn, vm.model)
        };
        vm.endCustomerSelect.options.formatSelection = function(item) {
            vm.endCustomerModel.id = item.id;
            vm.endCustomerModel.customer_name = item.text;
            return item.text;
        };
        vm.endCustomerSelect.options.onClear = function() {
            vm.endCustomerModel = {};
            vm.model.end_customer_id = null;
        };
        DetailsModalFactory.setAjaxSelectableValues(endCustomerColumn, {client_id: vm.model.client_id}).then(function(values) {
            vm.endCustomerSelect.selectableValues = values;
            if (vm.endCustomerSelect.selectableValues.length == 0) {
                vm.endCustomerSelect.options.loaded = true;
                vm.noSelectableValues = true;
            }
        });
        EndCustomerResource.endCustomers.getList({metadata: true}).then(function (json) {
            vm.endCustomerDetailColumns = _.map(DetailsModalFactory.getDetailColumns(json.plain()), function(column) {
                if (column.field === EndCustomerColumns.CLIENT_NAME) {
                    column.is_read_only = true;
                }
                return column;
            });
        });
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function editNotes() {
        PubSub.emit($MessengerEvents.INIT_PANEL + 'leads-notes-panel', MessengerModelFactory.getPanelData({data: vm.model}));
    }

    /**
     * @param field
     * @returns {any}
     */
    function getColumn(field) {
        return _.find(vm.metadata, {field: field});
    }

    function toogleNewEndCustomerForm() {
        vm.endCustomerModel = _populateNewEndCustomerFromLeadDetails();
        vm.model[LeadColumns.CUSTOMER_NAME] = null;
        vm.showNewEndCustomerForm = true;
    }

    function cancelNewEndCustomer() {
        vm.endCustomerModel = {};
        vm.showNewEndCustomerForm = false;
    }

    function _populateNewEndCustomerFromLeadDetails() {
        return {
            id: null,
            client_id: vm.model[LeadColumns.CLIENT_ID],
            client_name: vm.model[LeadColumns.CLIENT_NAME],
            customer_name: vm.model[LeadColumns.CUSTOMER_NAME],
            customer_number: vm.model[LeadColumns.CUSTOMER_NUMBER],
            customer_email: vm.model[LeadColumns.CUSTOMER_EMAIL],
            customer_address: vm.model[LeadColumns.ADDRESS_1],
            customer_city: vm.model[LeadColumns.CITY],
            customer_state: vm.model[LeadColumns.STATE],
            customer_zip: vm.model[LeadColumns.ZIP],
        }
    }

    function _onSaveDetails() {
        vm.model.end_customer = !_.isEmpty(vm.endCustomerModel)
            ? vm.endCustomerModel
            : null;
    }

    function _registerEvents() {
        PubSub.on($DetailsModalFactoryEvents.PRE_SAVE, _onSaveDetails);
    }

    function _unregisterEvents() {
        PubSub.off($DetailsModalFactoryEvents.PRE_SAVE, _onSaveDetails);
    }
}