'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.keyboard.services', [])
    .constant('$KeyboardServiceEvents', $KeyboardServiceEvents())
    .service('KeyboardAdapterService', KeyboardAdapterService);

function $KeyboardServiceEvents() {
    return {
        ENTER: {name:'$KeyboardServiceEvents:ENTER', value: 13, combo: false},
        SHIFT: {name:'$KeyboardServiceEvents:ENTER', value: 16, combo: false},
        ESCAPE: {name:'$KeyboardServiceEvents:ESCAPE', value: 27, combo: false},
        DELETE: {name:'$KeyboardServiceEvents:DELETE', value: 8, combo: false},
        DELETE_OVERRIDE: {name:'$KeyboardServiceEvents:DELETE_OVERRIDE', value: 8, combo: false, override: true},
        TAB: {name:'$KeyboardServiceEvents:TAB', value: 9, combo: false},
        TAB_OVERRIDE: {name:'$KeyboardServiceEvents:TAB_OVERRIDE', value: 9, combo: false, override: true},
        UP_ARROW: {name:'$KeyboardServiceEvents:UP_ARROW', value: 38, combo: false},
        DOWN_ARROW: {name:'$KeyboardServiceEvents:DOWN_ARROW', value: 40, combo: false},
        LEFT_ARROW: {name:'$KeyboardServiceEvents:LEFT_ARROW', value: 37, combo: false},
        RIGHT_ARROW: {name:'$KeyboardServiceEvents:RIGHT_ARROW', value: 39, combo: false},
        UP_ARROW_OVERRIDE: {name:'$KeyboardServiceEvents:UP_ARROW_OVERRIDE', value: 38, combo: false, override: true},
        DOWN_ARROW_OVERRIDE: {name:'$KeyboardServiceEvents:DOWN_ARROW_OVERRIDE', value: 40, combo: false, override: true},
        LEFT_ARROW_OVERRIDE: {name:'$KeyboardServiceEvents:LEFT_ARROW_OVERRIDE', value: 37, combo: false, override: true},
        RIGHT_ARROW_OVERRIDE: {name:'$KeyboardServiceEvents:RIGHT_ARROW_OVERRIDE', value: 39, combo: false, override: true},
        CMD_C: {name:'$KeyboardServiceEvents:CMD_C', value: 67, combo: true},
        CMD_X: {name:'$KeyboardServiceEvents:CMD_X', value: 88, combo: true},
        CMD_V: {name:'$KeyboardServiceEvents:CMD_V', value: 86, combo: true},
        CMD_Z: {name:'$KeyboardServiceEvents:CMD_Z', value: 90, combo: true},
        CMD_Z_OVERRIDE: {name:'$KeyboardServiceEvents:CMD_Z_OVERRIDE', value: 90, combo: true, override: true},
        CMD_A_OVERRIDE: {name:'$KeyboardServiceEvents:CMD_A', value: 65, combo: true, override: true},
        CMD_S: {name:'$KeyboardServiceEvents:CMD_S', value: 83, combo: true},
        CMD_S_OVERRIDE: {name:'$KeyboardServiceEvents:CMD_S_OVERRIDE', value: 83, combo: true, override: true},
        CMD_FORWARD_SLASH: {name:'$KeyboardServiceEvents:CMD_FORWARD_SLASH', value: 191, combo: true},
        F: {name:'$KeyboardServiceEvents:F', value: 70},
        W: {name:'$KeyboardServiceEvents:W', value: 87},
        I: {name:'$KeyboardServiceEvents:I', value: 73},
        T: {name:'$KeyboardServiceEvents:T', value: 84},
        E: {name:'$KeyboardServiceEvents:E', value: 69},
        G: {name:'$KeyboardServiceEvents:G', value: 71},
        M: {name:'$KeyboardServiceEvents:M', value: 77},
        R: {name:'$KeyboardServiceEvents:R', value: 82},
        B: {name:'$KeyboardServiceEvents:B', value: 66},
        L: {name:'$KeyboardServiceEvents:L', value: 76},
        O: {name:'$KeyboardServiceEvents:O', value: 79},
        P: {name:'$KeyboardServiceEvents:P', value: 80},
        CMD_LEFT_ARROW_OVERRIDE: {name:'$KeyboardServiceEvents:CMD_LEFT_ARROW', value: 37, combo: true, override: true},
        CMD_RIGHT_ARROW_OVERRIDE: {name:'$KeyboardServiceEvents:CMD_RIGHT_ARROW', value: 39, combo: true, override: true}
    };
}

/**
 * Adapter to listen to keyboard events from the brows4er to registered subscribers
 * @constructor
 */
function KeyboardAdapterService(
    $timeout,
    $document,
    PubSub,
    $KeyboardServiceEvents
) {
    this.on = on;
    this.off = off;

    _init();

    /**
     * Registers subscriber to desired keyboard event
     * @param keyboardEvent
     * @param cb
     */
    function on(keyboardEvent, cb) {
        PubSub.on(keyboardEvent.name, cb);
    }

    /**
     * Unregisters subscriber from desired keyboard event
     * @param keyboardEvent
     * @param cb
     */
    function off(keyboardEvent, cb) {
        PubSub.off(keyboardEvent.name, cb);
    }

    function _init() {
        $document.bind('keydown', function (event) {
            var isPressingCommand = false;
            isPressingCommand = event.ctrlKey || event.metaKey;

            _.forEach($KeyboardServiceEvents, function (keyEvent) {
                if (event.keyCode === keyEvent.value
                    && PubSub.get(keyEvent.name).length) { // only emit if have listeners

                    // check if this key is required to having the command/ctrl key pressed
                    if (keyEvent.combo && !isPressingCommand) {
                        return;
                    }

                    // when command/ctrl is not required for key
                    if (!keyEvent.combo && isPressingCommand) {
                        return;
                    }

                    if (keyEvent.override && !angular.element(':focus').length) {
                        if (window.event) {
                            window.event.cancelBubble = true;
                        }
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    $timeout(function () {
                        PubSub.emit(keyEvent.name, event);
                    }, 0);
                }
            });
        });
    }

}
