import { WidgetDataService } from '@/modules/ta/widget/services/data/WidgetDataService';
import { WidgetState } from '@/modules/ta/widget/widget.constants';
import { User } from '@/modules/ta/user/models/User';

export class AccountManagerDataService extends WidgetDataService {
  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @returns {Promise<{data: *, state: (boolean|string)}>}
   */
  async getData(widget, config) {
    if (widget.cached_data instanceof User) {
      return widget.cached_data;
    }

    if (widget.cached_data?.id) {
      return super.retrieveData(widget, config);
    }

    if (this.useSampleData(widget)) {
      return this.widgetSampleDataResource.getWidgetData(widget, config.params);
    }

    return null;
  }

  /**
   * @override
   *
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @param data
   * @returns {string|boolean}
   */
  /* eslint-disable no-unused-vars */
  evaluateLoadingState(widget, config, data) {
    if (data instanceof User || widget.cached_data instanceof User) {
      return WidgetState.STATE_DONE;
    }

    return WidgetState.STATE_NO_DATA_ACTIONABLE;
  }
}
