import angular from 'angular';

export const ManageProductsColumns = {
    ID: 'id',
    NAME: 'name',
    ACTIVE: 'active',
    CREATED_AT: 'created_at',
    CATEGORY_ID: 'category_id',
    CATEGORY_NAME: 'category_name',
    RULE_SET_ID: 'rule_set_id',
    RULE_SET_NAME: 'rule_set_name',
    VERSION: 'version',
    SERVICE_ID: 'service_id',
    SERVICE_NAME: 'service_name'
};

angular.module('core.manageproducts.constants', [])
    .constant('ManageProductsColumns', ManageProductsColumns);