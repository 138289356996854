import ServiceDataResource from '@/modules/ta/service/resources/ServiceDataResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { WidgetDataResource } from '@/modules/ta/widget/resources/WidgetDataResource';

export class ServiceDataWidgetDataResource extends WidgetDataResource {
  constructor() {
    super(RouteGroup.SERVICES);
  }

  /**
   * @override
   * @param {Widget} widget
   * @param {Object} params
   * @returns {Promise<GenericDataModel[]>}
   */
  async getWidgetData(widget, params) {
    const { data_source_id, data_view_id } = widget;
    return ServiceDataResource.getData(data_source_id, data_view_id, params);
  }

  /**
   * @override
   * @param {Widget} widget
   * @param {Object} params
   * @returns {Promise<GenericDataModel[]>}
   * */
  async getColumns(widget, params) {
    const { data_source_id, data_view_id } = widget;
    return ServiceDataResource.getColumns(data_source_id, data_view_id, params);
  }

  /**
   * @param data_source_id
   * @param data_view_id
   * @param field
   * @returns {Promise<*>}
   */
  getValuesDistinct(data_source_id, data_view_id, field) {
    return ServiceDataResource.getValuesDistinct(data_source_id, data_view_id, field);
  }
}

export default new ServiceDataWidgetDataResource();
