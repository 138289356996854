import { cloneDeep, first } from 'lodash';
import { getters, commits, dispatches } from '@/modules/core/app/helpers/store';
import LayoutService from '@/modules/ta/dashboard/services/LayoutService';
import {
  WidgetBuilderConstants,
  WidgetEvent,
  WidgetState,
} from '@/modules/ta/widget/widget.constants';
import WidgetService from '@/modules/ta/widget/services/WidgetService';
import EventBus from '@/modules/core/app/helpers/EventBus';
import { WidgetFactory } from '@/modules/ta/widget/factories/WidgetFactory';

export const actions = {
  async addLayout({}, { layout }) {
    return commits.dashboardLayout.setLayout(layout);
  },
  addGhostWidget({}, { layout_id, widget, index }) {
    const builderWidget = WidgetFactory.build(widget);
    builderWidget.display_order = index;
    builderWidget.layout_id = layout_id;

    commits.dashboardLayout.addGhostWidget({
      layoutId: layout_id,
      widget: builderWidget,
      state: builderWidget.hasDataConfiguration()
        ? WidgetState.STATE_DONE
        : WidgetState.STATE_INCOMPLETE_BUILDER_CONFIGURATION,
    });
  },
  addWidget({}, { widget, layoutId = null }) {
    commits.dashboardLayout.addWidget({
      layoutId: layoutId || widget.layout_id,
      widget,
      state: WidgetState.STATE_NEEDS_RELOAD,
    });
  },
  updateTitleAndOrder({ state }, updatedLayouts) {
    updatedLayouts.forEach((layout) => {
      const updatedLayout = state.layouts[layout.id];
      updatedLayout.display_order = layout.display_order;
      updatedLayout.title = layout.title;
      commits.dashboardLayout.setLayout(updatedLayout);
    });
  },
  updateDrawOptions({ state }, { drawOptions, layoutId }) {
    const clonedOptions = cloneDeep(drawOptions);
    const updatedLayout = state.layouts[layoutId];
    updatedLayout.metadata.draw_options = clonedOptions;
    commits.dashboardLayout.setLayout(updatedLayout);
  },
  moveWidgetToLayout({ state }, { widgetId, layoutId }) {
    const [widget] = getters.widget.getWidgetsFromIds([widgetId]);
    const oldLayoutId = widget.layout_id;

    widget.layout_id = layoutId;
    widget.display_order = Object.keys(state.layouts[layoutId].widgets).length;

    dispatches.widget.saveItem(widget);
    commits.dashboardLayout.removeWidgetInLayout({ layoutId: oldLayoutId, widget });
    commits.dashboardLayout.setWidgetInLayout({ layoutId, widget });
  },
  async removeWidgetFromWidgetBuilder({}, { widgetId, layoutId }) {
    if (!getters.widgetBuilder.isEditing()) {
      const [widget] = getters.widget.getWidgetsFromIds([widgetId]);
      commits.dashboardLayout.removeWidgetInLayout({ layoutId, widget });
    }
  },
  async removeWidget({}, { widgetId, layoutId }) {
    const [widget] = getters.widget.getWidgetsFromIds([widgetId]);
    await WidgetService.deleteWidget(widget);
    commits.dashboardLayout.removeWidgetInLayout({ layoutId, widget });
  },
  async duplicateWidget({}, { widgetId, layoutId = null }) {
    const widget = getters.widget.getWidget([widgetId]);
    let newWidget = await WidgetService.copyItem(widget);
    newWidget = await WidgetService.getItem(newWidget.id);
    newWidget.cached_data = cloneDeep(widget.cached_data);
    commits.dashboardLayout.addWidget({
      layoutId: layoutId || newWidget.layout_id,
      widget: newWidget,
    });
    EventBus.signal(WidgetEvent.DUPLICATED, newWidget);
  },
  /**
   * To create a new Layout.
   * @returns {Promise<void>}
   */
  async addNewLayout() {
    const dashboard = getters.dashboard.getDashboard();
    const layout = await LayoutService.addNewLayout(
      dashboard.id,
      getters.dashboard.getNextLayoutDisplayOrder()
    );
    commits.dashboard.addLayout({ layout });
    commits.dashboard.setCurrentLayoutId(layout.id);
    return layout;
  },

  async removeLayout({}, id) {
    await LayoutService.deleteItem(id);
    const dashboard = getters.dashboard.getDashboard();

    commits.dashboard.removeLayout(id);
    commits.dashboardLayout.removeLayout(id);

    if (id === getters.dashboard.getCurrentLayoutId()) {
      commits.dashboard.setCurrentLayoutId(first(Object.keys(dashboard.layouts)));
    }
  },

  /**
   * To create a layout from a section template.
   * @param layout
   * @param title
   * @returns {Promise<void>}
   */
  async useTemplateAsLayout({}, { layout, title }) {
    const dashboard = getters.dashboard.getDashboard();
    const newLayout = await LayoutService.useTemplateAsLayout(
      layout,
      title,
      dashboard.id,
      getters.dashboard.getNextLayoutDisplayOrder()
    );
    LayoutService.incrementDownloadCount(newLayout.id);

    commits.widget.setWidgetsFromLayout({ layout: newLayout });
    commits.dashboard.addLayout({ layout: newLayout });
    return newLayout;
  },
  /**
   * To set widgets from a layout.
   * @param layout
   */
  setWidgetsFromLayout({}, { layout }) {
    commits.widget.setWidgetsFromLayout({ layout });
  },
  updateDisplayOrders({}, widgetIdIndexMapArray) {
    commits.widget.updatePositions(widgetIdIndexMapArray);
  },
  setOriginalLayoutWidgets({}, layoutId) {
    const widgets = getters.dashboardLayout.getWidgetsInLayout(layoutId);
    commits.dashboardLayout.setOriginalLayoutWidgets(widgets);
  },
  applyOriginalLayoutWidgets({ state }) {
    commits.widget.updateWidgets(state.originalLayoutWidgets);
  },
  async onWidgetBuilderPostSave({}, { widget, layoutId = null }) {
    if (getters.widgetBuilder.isEditing()) {
      dispatches.widget.updateWidget(widget);
    } else {
      commits.dashboardLayout.removeWidgetInLayout({
        widget: { id: WidgetBuilderConstants.NEW_WIDGET_ID },
        layoutId: layoutId || widget.layout_id,
      });
      dispatches.dashboardLayout.addWidget({ widget });
    }
  },
  async saveList({}, layouts) {
    const newLayouts = await LayoutService.saveList(layouts);
    commits.dashboardLayout.saveList(newLayouts);
  },
};
