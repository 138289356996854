import { dispatches, getters } from '@/modules/core/app/helpers/store';
import { Button, Container, Label, p100, p50 } from '@amcharts/amcharts5';
import { ButtonConstant, Constant } from '@/modules/core/charts/am5/charts.constants';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { useTitle } from '@/modules/core/charts/am5/bar/composables/useTitle';
import { useNonAxesTitleContainer } from '@/modules/core/charts/am5/base/composables/useNonAxesTitleContainer';
import { isEmpty } from 'lodash';
import { WidgetConfig, WidgetType } from '@/modules/ta/widget/widget.constants';

export function useButton(context) {
  const { chart, root, config, isDarkTheme } = context();
  const { addTitle } = useTitle(context);
  const { createContainerForNonAxesAndInsert, createContainerForPie } =
    useNonAxesTitleContainer(context);

  /**
   * XY charts we just drop the title / button into the topAxesContainer
   * otherwise we need to generate a new container to push that in
   * @param title
   * @param button
   */
  function addTitleAndButton(title, button) {
    const widget = config.value.get(WidgetConfig.WIDGET);
    if (widget.type === WidgetType.TYPE_FUNNELCHART) {
      createContainerForNonAxesAndInsert([title, button]);
    } else if (widget.type === WidgetType.TYPE_PIECHART) {
      createContainerForPie(title, button);
    } else {
      chart.value.topAxesContainer.children.push(title);
      chart.value.topAxesContainer.children.push(button);
    }
  }

  /**
   * If we have applied a drill-down then we need to display the
   * title and a "back" button to return to the overview
   * @param id
   * @returns {*}
   */
  function applyDrillDownTitleAndButton(id) {
    const widgetConfig = getters.dashboardDrilldown.getWidgetConfig(id);

    if (!isEmpty(widgetConfig) && widgetConfig.drilldownConfigStack.length !== 0) {
      const title = addTitle(getters.dashboardDrilldown.getWidgetTitle(id), { x: p50 });

      const button = Button.new(root.value, {
        x: 0,
        y: 0,
        cursorOverStyle: Constant.POINTER,
        label: Label.new(root.value, {
          text: ButtonConstant.DRILLDOWN_BACK,
          fontSize: 12,
          fontWeight: '400',
          fill: isDarkTheme.value ? color(0xffffff) : color(0x000000),
          paddingTop: 2,
          paddingRight: 2,
          paddingBottom: 2,
          paddingLeft: 2,
        }),
      });

      button.get(Constant.BACKGROUND).setAll({
        fill: isDarkTheme.value ? color(0x000000) : color(0xffffff),
        fillOpacity: 1,
      });

      button.events.on(Constant.CLICK, () => {
        dispatches.dashboardDrilldown.undoDrilldown(id);
        dispatches.dashboardDrilldown.reloadWidget(id);
      });

      addTitleAndButton(title, button);

      return id;
    }
  }

  function addPieTitleForButton(title, button) {
    const widget = config.value.get(WidgetConfig.WIDGET);
    if (widget.type === WidgetType.TYPE_FUNNELCHART || widget.type === WidgetType.TYPE_PIECHART) {
      createContainerForNonAxes(title, button);
    } else {
      chart.value.topAxesContainer.children.push(title);
      chart.value.topAxesContainer.children.push(button);
    }
  }

  function createContainerForNonAxes(title, button) {
    const { id } = config.value.get(WidgetConfig.WIDGET);
    const widgetConfig = getters.dashboardDrilldown.getWidgetConfig(id);
    const container = Container.new(root.value, {
      layout: root.value.horizontalLayout,
      width: p100,
    });
    if (chart.value.children.length > 2) chart.value.children.shift();

    if (widgetConfig.currentGroupByIndex === 0) {
      container.children.pushAll([title, button]);
      chart.value.children.unshift(container);
    }
  }

  /**
   * If we have applied a drill-down then we need to display the
   * title and a "back" button to return to the overview
   * @param id
   * @param setTitle
   * @returns {*}
   */

  function applyPieBackButtonInitialClick(id, setTitle) {
    const title = addTitle(setTitle, { x: p50 });
    const button = createButton();
    const buttonSettings = {
      fill: isDarkTheme.value ? color(0x000000) : color(0xffffff),
      fillOpacity: 1,
    };
    button.get(Constant.BACKGROUND).setAll(buttonSettings);

    button.events.on(Constant.CLICK, () => {
      dispatches.dashboardDrilldown.undoDrilldown(id);
      dispatches.dashboardDrilldown.reloadWidget(id);
      dispatches.dashboardDrilldown.reloadChart();
    });

    addPieTitleForButton(title, button);

    return id;
  }

  function createButton() {
    return Button.new(root.value, {
      x: 0,
      y: 0,
      cursorOverStyle: Constant.POINTER,
      label: Label.new(root.value, {
        text: ButtonConstant.DRILLDOWN_BACK,
        fontSize: 12,
        fontWeight: '400',
        fill: isDarkTheme.value ? color(0xffffff) : color(0x000000),
        paddingTop: 2,
        paddingRight: 2,
        paddingBottom: 2,
        paddingLeft: 2,
      }),
    });
  }

  return {
    applyDrillDownTitleAndButton,
    addTitleAndButton,
    applyPieBackButtonInitialClick,
    addPieTitleForButton,
  };
}
