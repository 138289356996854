import { reject } from 'lodash';
import { commits } from '@/modules/core/app/helpers/store';
import { shallowCopyKeyedObject } from '@/modules/core/helper';

export const mutations = {
  /**
   * @param state
   * @param {ListDataSet} dataSet
   */
  addListDataSet(state, dataSet) {
    state.dataSets[dataSet.id] = dataSet;
    state.dataSets = shallowCopyKeyedObject(state.dataSets);
  },
  /**
   * @param state
   * @param id
   * @param item
   */
  addItem(state, { listId, item }) {
    const dataSet = state.dataSets[listId];
    if (dataSet.isAsyncData) {
      commits.list.setNeedsRefresh({ listId, value: true });
    } else {
      dataSet.data.push(item);
    }
  },
  /**
   * @param state
   * @param listId
   * @param item
   */
  updateItem(state, { listId, item }) {
    const dataSet = state.dataSets[listId];
    if (dataSet.isAsyncData) {
      commits.list.setNeedsRefresh({ listId, value: true });
    } else {
      dataSet.data = dataSet.data.map((datum) =>
        datum[dataSet.primaryKeyField] === item[dataSet.primaryKeyField] ? item : datum
      );
    }
  },
  /**
   * @param state
   * @param listId
   * @param itemId
   */
  deleteItem(state, { listId, itemId }) {
    const dataSet = state.dataSets[listId];
    if (!dataSet.isAsyncData) {
      dataSet.data = reject(
        dataSet.data,
        (item) => item.current_period[dataSet.primaryKeyField] === itemId
      );
    }
    commits.list.setNeedsRefresh({ listId, value: true });
  },
  /**
   * @param state
   * @param listId
   * @param value
   */
  setNeedsRefresh(state, { listId, value }) {
    const dataSet = state.dataSets[listId];
    if (dataSet) {
      dataSet.needsRefresh = value;
    }
  },
  /**
   * @param state
   * @param listId
   */
  removeDataSet(state, listId) {
    delete state.dataSets[listId];
  },

  /**
   * @param state
   * @param {ListParamsState} paramsState
   */
  setListParamsState(state, paramsState) {
    state.paramsState[paramsState.id] = paramsState;
  },

  setUserListSettings(state, listSettings) {
    state.userListSettings[listSettings.list_id] = listSettings;
  },

  /**
   *
   * @param state
   */
  clearListParamsState(state) {
    state.paramsState = {};
  },
};
