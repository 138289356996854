'use strict';
import angular from 'angular';

angular.module('widget.resize.resources', [])
    .factory('WidgetResizeResourceFactory', WidgetResizeResourceFactory);

/**
 * Service which communicates to external boundaries
 * To be used by other internal services only.
 * @param Restangular
 * @constructor
 *
 * @ngInject
 */

function WidgetResizeResourceFactory(
    Restangular
) {

    var dash = Restangular.all('dash');
    var widgets = dash.all('widgets');

    return {
        resize: resize
    };

    /**
     * @param widgetModel
     * @returns {*}
     */
    function resize(widgetModel) {
        return widgets.one('resize').all(widgetModel.id).post({
            width: widgetModel.width,
            height: widgetModel.height
        });
    }
}