'use strict';

import angular from 'angular';
import _ from 'lodash';
import Core from '../../../main';
import {AppConstants} from 'coreModules/shared/scripts/app.constants';

import navMainHtmlUrl from './navmain.html';
import navMainMenuHtmlUrl from './navmain.menu.html';
import navMainMenuModuleHtmlUrl from './navmain.menumodule.html';
import navMainMenuItemHtmlUrl from './navmain.menuitem.html';
import navMainMenuSectionHtmlUrl from './navmain.menusection.html';
import navMainMenuSectionItemHtmlUrl from './navmain.menusectionitem.html';

angular.module('navmain.components', [])

    .component('navMain', {
        templateUrl: navMainHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            user: '<',
        },
        controller: NavMainController
    })
    .component('navMainMenu', {
        templateUrl: navMainMenuHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            user: '<',
        },
        controller: NavMainMenuController
    })
    .component('navMainMenuModule', {
        templateUrl: navMainMenuModuleHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            user: '<',
            menumodule: '<'
        },
        controller: NavMainMenuModuleController
    })
    .component('navMainMenuItem', {
        templateUrl: navMainMenuItemHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            user: '<',
            menuitem: '<'
        },
        controller: NavMainMenuItemController
    })
    .component('navMainMenuSection', {
        templateUrl: navMainMenuSectionHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            user: '<',
            menusection: '<',
            dashboards: '<'
        },
        controller: NavMainMenuSectionController
    })
    .component('navMainMenuSectionItem', {
        templateUrl: navMainMenuSectionItemHtmlUrl,
        controllerAs: 'vm',
        bindings: {
            user: '<',
            menusectionitem: '<',
            page: '<',
            type: '@'
        },
        controller: NavMainMenuSectionItemController
    });

/**
 * @ngInject
 */
function NavMainController(
    $timeout,
    NavMainFactory,
    UIFactory,
    LoginFactory,
    UserIntegrationResource,
    UserFactory,
    AppConfig,
    AppFactory,
    AppModule
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.triggerImpersonationModal = triggerImpersonationModal;
    vm.triggerChangeLanguageModal = triggerChangeLanguageModal;
    vm.triggerUnimpersonation = triggerUnimpersonation;
    vm.triggerImageUploadModal = triggerImageUploadModal;
    vm.isUserIntegrated = isUserIntegrated;
    vm.logout = logout;
    vm.goToNewApp = goToNewApp;
    vm.getUserDisplayName = getUserDisplayName;

    vm.user = AppFactory.getUser();
    vm.useNUI = vm.user.user?.use_nui;
    vm.showMDS = !vm.useNUI && ((vm.user.isSuperAdmin() && !vm.isVendorRestricted) || vm.user.canManageServices);
    const showNewAppLink = _.includes(vm.user.availableModules, AppModule.DISABLE_OLD_UI) || vm.useNUI;
    vm.newAppTitle = showNewAppLink ? "Go to Reports" : "Go to New Experience";

    /**
     * Lifecycle method for initial loading of component.
     *
     * @returns {none}
     */
    function $onInit() {
        setLandingPageState();
    }

    function $postLink() {
        $timeout(function () {
            Core.setUpShowHideToggler();
        }, 250, false);
    }

    function setLandingPageState() {
        if (vm.user.isVendorOrVendorRestricted) {
            vm.landingPageState = 'myTasks';
        } else {
            vm.landingPageState = vm.user.landingPageState;
        }
    }

    function triggerChangeLanguageModal() {
        UIFactory.showModal('change-language-modal');
    }

    function triggerImpersonationModal() {
        UIFactory.showModal('user-impersonation-modal');
    }

    function triggerUnimpersonation() {
        LoginFactory.unimpersonateUser();
    }

    function triggerImageUploadModal() {
        UIFactory.showModal('image-upload-modal');
    }

    function isUserIntegrated() {
        return UserIntegrationResource.isUserIntegrated();
    }

    async function goToNewApp() {
        if (!vm.useNUI) {
            await UserFactory.updateAppPreference({use_nui: true});
        }
        window.location = AppConfig.NUI_PATH;
    }

    function logout() {
        LoginFactory.logout();
    }

    function getUserDisplayName() {
        let displayName = vm.user.userDisplayName;
        if (_.isEmpty(displayName)) {
            displayName = vm.user.userEmail;
        }
        return displayName;
    }
}

/**
 * @ngInject
 */
function NavMainMenuController(
    NavMainFactory,
    UserFactory,
    AppConfig,
    AppFactory,
    AppModule
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.goToNewApp = goToNewApp;
    vm.useNUI = false;
    vm.showNewAppLink = false;

    /**
     * Lifecycle method for initial loading of component.
     *
     * @returns {none}
     */
    function $onInit() {
        vm.menu = NavMainFactory.getNavItems();

        let currentUser = AppFactory.getUser();
        vm.useNUI = currentUser.user?.use_nui;
        vm.showNewAppLink = _.includes(currentUser.availableModules, AppModule.DISABLE_OLD_UI) || vm.useNUI;
    }

    async function goToNewApp() {
        if (!vm.useNUI) {
            await UserFactory.updateAppPreference({use_nui: true});
        }
        window.location = AppConfig.NUI_PATH;
    }
}

/**
 * @ngInject
 */
function NavMainMenuModuleController(
    UserFactory,
    AppConfig)
{
    var vm = this;

    vm.menumoduleitems = [];
    vm.$onInit = $onInit;
    vm.onSectionItemSelected = onSectionItemSelected;
    vm.goToNewApp = goToNewApp;

    function $onInit() {
        vm.menumoduleitems = vm.menumodule.items;
    }

    function onSectionItemSelected(menuKey) {
        for (var i=0; i < vm.menumoduleitems.length; i++) {
            if (vm.menumoduleitems[i].menu_key === menuKey) {
                vm.menumoduleitems[i].isSelected = true;
            } else {
                vm.menumoduleitems[i].isSelected = false;
            }
        }
    }

    async function goToNewApp() {
        await UserFactory.updateAppPreference({use_nui: true});
        window.location = `${AppConfig.NUI_PATH}#/welcome`;
    }
}

/**
 * @ngInject
 */
function NavMainMenuItemController(
    NavMainFactory,
    $scope,
    PageType,
    DesignFactory,
    AppFactory
) {
    var vm = this;

    vm.dashboards = {};
    vm.branding = {};
    vm.menusections = [];
    vm.menuitem = vm.menuitem || {};
    vm.$onInit = $onInit;

    function $onInit() {
        vm.toggleExpand = toggleExpand;
        vm.getMenuIcon = getMenuIcon;

        if (vm.menuitem.menu_key === 'dashboards') {
            vm.branding = AppFactory.getBrandMappings();
            setPages();
            registerEvents();
        }
        vm.menusections = vm.menuitem.items;

        applyDashboardsToMenuSections();
    }

    function applyDashboardsToMenuSections() {
        _.each(vm.menusections, menuSection => {
            addDashboardsToSection(menuSection);
        });
    }

    function _updatePages() {
        setPages();
        applyDashboardsToMenuSections();
    }

    function setPages() {

        var allDashboards = DesignFactory.getPageEntities(PageType.TYPE_DASH);

        var predefinedDashboards = _.filter(allDashboards, {is_predefined: true});
        var selectedEntityDashboards = _.filter(allDashboards, function(page) { return !_.isNull(page.metadata.selected_entity); });
        var sharedDashboards = _.difference(allDashboards, _.concat(predefinedDashboards, selectedEntityDashboards));

        predefinedDashboards = _.orderBy(predefinedDashboards, function(dash) {
            return dash.display_order;
        }, ['desc', 'asc']);

        selectedEntityDashboards = _.orderBy(selectedEntityDashboards, [
            function(dash) { return dash.metadata.selected_entity ? dash.metadata.selected_entity.name : '' },
            function(dash) { return dash.title.toLowerCase() }
        ]);

        sharedDashboards = _.orderBy(sharedDashboards, function(dash) {
            return dash.title.toLowerCase();
        });

        vm.dashboards = {
            predefinedDashboards: predefinedDashboards || [],
            selectedEntityDashboards: selectedEntityDashboards || [],
            sharedDashboards: sharedDashboards || []
        };
    }

    function addDashboardsToSection(menusection) {
        var dashboards = [];
        if (menusection.dashboards_section_key === 'dashboardtemplates') {
            dashboards = vm.dashboards.predefinedDashboards;
        } else if (menusection.dashboards_section_key === 'clientdashboards') {
            dashboards = vm.dashboards.selectedEntityDashboards;
        } else if (menusection.dashboards_section_key === 'shareddashboards') {
            dashboards = vm.dashboards.sharedDashboards;
        }

        if (dashboards.length) {
            menusection.items = [];
        }

        for (var i=0; i < dashboards.length; i++) {
            menusection.items.push({
                page: dashboards[i],
                type: 'dash'
            });
        }
    }

    function registerEvents() {
        $scope.$on('design:refreshMenu', _updatePages);
    }

    function getMenuIcon(menuKey) {
        return NavMainFactory.getMenuIcon(menuKey);
    }

    function toggleExpand(event) {
        var $body = $('body');

        if (!$body.hasClass('responsive')) {
            var $menu = $(event.currentTarget).parent();
            var $subMenu = $menu.find('div.nav-submenu');
            var $caret = $menu.find('div.nav-item-wrapper i.icon');

            //If already shown, close the submenu
            if ($menu.hasClass('active')) {
                $subMenu.hide();
                $menu.removeClass('active');
                $caret.removeClass('icomoon-caret-down').addClass('icomoon-caret-right');
            }
            else {
                var $navItems = $('div.nav-item');
                $navItems.removeClass('active');
                $navItems.find('div.nav-item-wrapper i.icon').removeClass('icomoon-caret-down').addClass('icomoon-caret-right');
                $('div.nav-submenu').hide();

                if ($subMenu.length) {
                    if ($subMenu.is(':hidden')) {
                        $menu.addClass('active');
                        $subMenu.show();
                        $caret.removeClass('icomoon-caret-right').addClass('icomoon-caret-down');
                    }
                    else {
                        $menu.removeClass('active');
                        $subMenu.hide();
                    }
                }
            }
        }
    }
}

/**
 * @ngInject
 */
function NavMainMenuSectionController(
    $timeout,
    ServiceFactory
) {
    var vm = this;

    vm.$onInit = $onInit;

    $timeout(function () {
        if (vm.menusection.data_sources_section_key === 'default') {
            ServiceFactory.getServiceOrders().then(function(json) {
                if (!json.error) {
                    $.core.main.setViewAsMode(json);
                }
                else {
                    Main.formErrorNotify(json);
                }
            });
        }
    }, 0, false);

    function $onInit() {
    }
}

/**
 * @ngInject
 */
function NavMainMenuSectionItemController(
    $timeout,
    $state,
    $stateParams,
    DesignFactory,
    PageType,
    PageEntity,
    NavMainFactory,
    UserFactory
) {
    var vm = this;

    vm.isSelected = false;
    vm.$onInit = $onInit;

    $timeout(function () {
        if (vm.isSelected) {
            Core.highlightCurrentMainNavMenu(vm.menusectionitem.menu_key);
        }
    }, 0, false);

    function $onInit() {
        vm.getMenuIcon = getMenuIcon;
        vm.getPagePath = getPagePath;
        vm.getServicePath = getServicePath;
        vm.getMenuStateRef = getMenuStateRef;
        vm.getMenuStateHRef = getMenuStateHRef;
        vm.setNUIPreference = setNUIPreference;

        // If page is not explicitely set, use type and entity to infer the page
        var pageEntities;
        if (vm.menusectionitem.type && vm.menusectionitem.entity && _.isNil(vm.menusectionitem.page)) {
            pageEntities = DesignFactory.getPageEntities(vm.menusectionitem.type);
            vm.menusectionitem.page = _.first(_.filter(pageEntities, {entity: vm.menusectionitem.entity}));
        }
    }

    function getMenuIcon() {
        return NavMainFactory.getMenuIcon(vm.menusectionitem.menu_key);
    }

    function getPagePath() {
        var stateName;
        var params = {};

        // Predefined pages
        if (!_.isNull(vm.menusectionitem.page.entity)) {
            stateName = vm.menusectionitem.page.entity;

            if (vm.menusectionitem.page.type === PageType.TYPE_SERVICES) {
                stateName = vm.menusectionitem.page.type + '.' + vm.menusectionitem.page.entity;
                params.id = vm.menusectionitem.page.metadata.selected_entity.id;
            }
            else if (vm.menusectionitem.page.entity === PageEntity.PAGE_PREDEFINED_CATEGORY_DASHBOARD) {
                stateName = PageType.TYPE_DASH;
                params.id = vm.menusectionitem.page.id;
            }
        }
        else {
            // Custom dashboards
            stateName = PageType.TYPE_DASH;
            params.id = vm.menusectionitem.page.id;
        }
        // fake vm.menusectionitem.menu_key for nan menu highlight
        vm.menusectionitem.menu_key = 'page_' + stateName;
        if (params.id)
            vm.menusectionitem.menu_key += '_' + params.id;

        var stateHref = $state.href(stateName, params);
        vm.isSelected = $state.href($state.current.name, $stateParams) === stateHref;
        return stateHref;
    }

    function getServicePath() {
        // fake vm.menusectionitem.menu_key for nan menu highlight
        vm.menusectionitem.menu_key = 'service_' + vm.menusectionitem.service_id;

        var stateHref = '#/service/details/'+vm.menusectionitem.service_id;
        vm.isSelected = $state.href($state.current.name, $stateParams) === stateHref;
        return $.core.setAppPath(stateHref);
    }

    function getMenuStateRef() {
        var uiSref = NavMainFactory.getMenuStateRef(vm.menusectionitem.menu_key, vm.user.isIORoute);
        vm.isSelected = $state.current.name === uiSref;
        return uiSref;
    }

    async function setNUIPreference()
    {
        if (NavMainFactory.getForceNUI(vm.menusectionitem.menu_key)) {
            await UserFactory.updateAppPreference({use_nui: true});
        }
    }

    function getMenuStateHRef() {
        var uiSref = NavMainFactory.getMenuStateHRef(vm.menusectionitem.menu_key);
        vm.isSelected = $state.current.name === uiSref;
        return uiSref;
    }
}
