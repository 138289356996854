import angular from 'angular';
import _ from "lodash";
import {ColumnValueType} from "coreModules/shared/scripts/app.constants";

class UserLoginsResource {

    /**
     * @ngInject
     */
    constructor(
        Restangular
    ) {
        this.userlogins = Restangular.all('userlogins');
        this.values = this.userlogins.one('values');
    }

    /**
     * @param queryParams
     * @returns {*}
     */
    getColumns(queryParams) {
        return this.userlogins.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    getColumnValues(fields) {
        return this.values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @param dataSource (unused here)
     * @param queryParams
     * @returns {*}
     */
    getFieldValues(fields, dataSource, queryParams) {
        queryParams = _.extend({fields: fields}, queryParams || {});
        return this.values.one(ColumnValueType.DISTINCT).get(queryParams);
    }

    /**
     * Get data for this resource
     *
     * @param queryParams
     * @returns {*|restangular.ICollectionPromise<any>}
     */
    getData(queryParams) {
        return this.userlogins.getList(_.extend({
            all: true
        }, queryParams));
    }
}

angular.module('core.userlogins.resources', [])
    .service('UserLoginsResource', UserLoginsResource);