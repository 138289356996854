import { extend } from 'lodash';
import { FormSelectOption } from '@/modules/core/form/models/FormSelectOption';
import ClientFormService from '@/modules/ta/client/services/ClientFormService';
import { getters } from '@/modules/core/app/helpers/store';
import { AppModule } from '@/modules/core/app/constants/app.constants';
import ClientListDataService from '@/modules/ta/client/services/ClientListDataService';
import { ClientReportingStatus, IOToolStatusField } from '@/modules/ta/client/client.constants';

export class ClientSelectOption extends FormSelectOption {
  clientId;

  iotoolStatuses;

  constructor(config = {}) {
    const userSettings = getters.session.getUserSettings();
    const placeholder = userSettings.isModuleAvailable(AppModule.BRAND_PACKAGE)
      ? i18n.$t('Select a Campaign')
      : i18n.$t('Select a Client');
    super({
      placeholder,
      ...config,
    });
  }

  setClientId(value) {
    this.clientId = value;
  }

  setIotoolStatuses(iotoolStatuses) {
    this.iotoolStatuses = iotoolStatuses;
  }

  async getClientData(params) {
    if (params.q) {
      params.q = `${params.q.replace(/\|/g, '-_-')}|company_name`;
    }
    if (this.clientId) {
      /* eslint-disable tap/no-raw-text-js */
      const clientsData = await ClientListDataService.getData(
        extend(params, {
          fields: 'id,company_name,reporting_status',
          sort: 'company_name',
        })
      );
      /* eslint-enable tap/no-raw-text-js */

      const nonSelectableClients = clientsData.find(
        (client) =>
          [ClientReportingStatus.HOLD, ClientReportingStatus.TERMINATED].includes(
            client.reporting_status
          ) && client.id === this.clientId
      );

      const values = clientsData
        .filter(
          (client) =>
            ![ClientReportingStatus.HOLD, ClientReportingStatus.TERMINATED].includes(
              client.reporting_status
            )
        )
        .map(({ id, company_name, reporting_status }) => ({
          key: id,
          value: company_name,
          reporting_status,
        }));

      if (nonSelectableClients) {
        const { id, company_name, reporting_status } = nonSelectableClients;
        values.push({
          key: id,
          value: company_name,
          reporting_status,
        });
      }

      return { values };
    }
    if (this.iotoolStatuses) {
      params[IOToolStatusField] = this.iotoolStatuses;
    }
    return ClientFormService.getValues({ fields: 'id', ...params });
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  getData(params) {
    return this.getClientData(params);
  }
}
