import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { Widget } from '@/modules/ta/widget/models/Widget';
import { DataSourceTypeCollection } from '@/modules/core/datasource/DataSourceTypeCollection';
import { GenericModel } from '@/modules/core/app/models/GenericModel';

export class WidgetResource extends BaseEntityResource {
  constructor() {
    super(RouteGroup.DASH_WIDGETS, Widget);
  }

  /**
   * Custom endpoint to save all changes from the widget builder
   * @param data
   * @param id
   * @param config
   * @returns {Promise<*>}
   */
  saveBuilder(data, id, config = {}) {
    return id ? super.putData(data, `save/${id}`, config) : super.postData(data, `save`, config);
  }

  /**
   /**
   * Return the export URL for widget exports.
   * @param type
   * @param params
   * @returns {string}
   */
  buildExportUrl(type, params) {
    return `${type}/${params.widgetId}`;
  }

  /**
   * @param {string} type
   * @param {Object} params
   * @returns {*}
   */
  getMetadataColumnValues(type, params = {}) {
    return super.getData(DataSourceTypeCollection, `metadata/${type}/values/set`, params);
  }

  /**
   * Return the plot type comparison values
   * @returns {*}
   */
  getComparisonValues() {
    return super.getData(GenericModel, `comparisonoptions`);
  }

  /**
   * Return the plot type sparkline values
   * @returns {*}
   */
  getSparklineValues() {
    return super.getData(GenericModel, `sparklineoptions`);
  }

  /**
   * Return the mapbox credentials
   * @returns {*}
   */
  getMapboxAccessToken() {
    return super.getData(GenericModel, 'mapboxaccesstoken');
  }
}

export default new WidgetResource();
