/**
 * @class GaugeExtent
 */
export class GaugeExtent {
  /**
   * Value that the extent runs from
   * @var {Number}
   */
  valueFrom;

  /**
   * Value that the extent runs to
   * @var {Number}
   */
  valueTo;

  /**
   * Whether the gauge band is on the inside or not
   * @var {Boolean}
   */
  inside;

  /**
   * Fill colour
   * @var {String}
   */
  fill;

  /**
   * AxisRanges that this gauge extent uses
   * @var {Array}
   */
  ranges;

  /**
   * Whether this is a single axis on the gauge or multiple
   * @var {boolean}
   */
  isSingleAxis;

  constructor(model = {}) {
    this.valueFrom = model.valueFrom ?? 0;
    this.valueTo = model.valueTo;
    this.inside = model.inside ?? true;
    this.fill = model.fill;
    this.ranges = model.ranges;
    this.isSingleAxis = model.isSingleAxis;
  }
}
