import angular from 'angular';
import './datagrid.tagpicker.constants';
import './datagrid.tagpicker.services';
import './datagrid.tagpicker.resources';
import './datagrid.tagpicker.models';
import './datagrid.tagpicker.components';

angular.module('datagrid.tagpicker', [
    'datagrid.tagpicker.constants',
    'datagrid.tagpicker.services',
    'datagrid.tagpicker.resources',
    'datagrid.tagpicker.models',
    'datagrid.tagpicker.components'
])