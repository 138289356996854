"use strict";
import $ from 'jquery';

var Core = $.core.reporting.pdf = {
    initialize: function () {
        this.build();
        this.events();
    },
    build: function () {
        Core.initCharts();
    },
    events: function () {

    },
    initCharts: function () {
        var chartWidth = 1250;
        $('div.chart-container div.json-chart-data').each(function() {
            //JSON data is contained within the html
            //NOTE: data is passed this way so that pdf generator can grab it directly from the html since it only has access to html
            var data = $.parseJSON($(this).html());
            var $chart = $(this).parent();

            //Remove to avoid bloating html with json data
            $(this).remove();

            if (data.chartType == $.globals.chart.type.pie) {
                $.core.chart.buildPieChart($chart, data, {width: chartWidth, height: 200, animation: false, isMultiAxis: true});
            }
            else if (data.chartType == $.globals.chart.type.line) {
                $.core.chart.buildSeriesChart($chart, data, {width: chartWidth, animation: false, isMultiAxis: true});
            }
            else {
                alert('Invalid chart type');
            }
        });
    }
};
Core.initialize();