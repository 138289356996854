<template>
  <LineChart
    v-if="canShowChart"
    :config="chartConfig"
    :disable-error-messages="disableErrorMessages"
    :widget-type="widgetType"
    width="100%"
    height="100%"
    @frame-ended="setIdle"
  />
</template>

<script>
import LineChart from '@/modules/core/charts/am5/line/components/LineChart.vue';
import { BaseChartWidgetPropsMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetPropsMixin';
import { BaseChartWidgetMethodMixin } from '@/modules/core/charts/am5/base/mixins/BaseChartWidgetMethodMixin';

export default {
  name: 'LegacyLineChartWidget',
  mixins: [BaseChartWidgetPropsMixin, BaseChartWidgetMethodMixin],
  components: { LineChart },
};
</script>
