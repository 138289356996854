'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('role.directives', [])

    .directive('roleReportingProfileSelect', roleReportingProfileSelect);

//
//  directive
//
roleReportingProfileSelect.$inject = [];
function roleReportingProfileSelect() {
    return {
        restrict: 'A',
        link: function(scope, el) {
            let reportingProfileSelector = $(el);
            el.change(() => {
                scope.$evalAsync(() => {
                    scope.assignedReportingProfileSelected = reportingProfileSelector.val() == 'selection';
                });
            });
            el.trigger('change');
        }
    }
}