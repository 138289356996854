'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

import feedbacksHtmlUrl from '../views/feedbacks.html';

angular.module('feedbacks.directives', [])

    .directive('feedBacks', feedBacks);

//
// Directive for submitting customer feedback
//
feedBacks.$inject = ['FeedbacksFactory'];
function feedBacks(FeedbacksFactory) {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: feedbacksHtmlUrl,
        link: function(scope, elem, attrs){
            scope.open = false;
            scope.rating = null;
            scope.feedback = null;
        },
        controller: function($scope){
            $scope.showHide = function(){
                $scope.open = !$scope.open
                if(!$scope.open) {
                    $scope.rating = null;
                    $scope.feedback = null;
                }
            }
            $scope.submit = function(){
                if($scope.rating){
                    FeedbacksFactory.save({
                        "instanceAndRoute": window.location.href,
                        "feedback": _.isNull($scope.feedback) ? '' : $scope.feedback,
                        "rating": $scope.rating
                    }).then(function(res){
                        if (res) {
                            $.core.main.notify('Your feedback has been submitted. Thanks!', $.globals.notify.success);
                            $scope.rating = null;
                            $scope.feedback = null;
                        }
                        else {
                            $.core.main.notify('Error: Feedback not received', $.globals.notify.error);
                        }
                    });
                    $scope.open = null;
                }
            }
            $scope.setRating = function (rating) {
                $scope.rating = rating;
            }
        }
    }
}