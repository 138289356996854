'use strict';
import angular from 'angular';
import widgetImageUrl from 'coreModules/shared/images/widget.png';
import _ from 'lodash';

angular.module('page.library.resources', [])

    .factory('PageLibraryResourceFactory', PageLibraryResourceFactory)
    .factory('PageLibraryDataGridFactory', PageLibraryDataGridFactory);

/**
 *
 * @ngInject
 */
function PageLibraryResourceFactory(
    $q,
    Restangular,
    PageTagFactory
) {
    var dash = Restangular.all('dash');
    var pages = dash.all('pages');
    var resource = pages.all('library');

    return {
        getData: getData,
        getPagesForDisplay: getPagesForDisplay,
        getSearchableValues: getSearchableValues,
        usePageAsTemplate: usePageAsTemplate,
        incrementDownloadCount: incrementDownloadCount,
        publish: publish,
        updateThumbnails: updateThumbnails,
        updateSectionThumbnails: updateSectionThumbnails,
    };

    /**
     * Get data for this resource
     */
    function getData(queryParams) {
        queryParams = _.assign({
            all: true,
            extra: true
        }, queryParams);
        return resource.getList(queryParams);
    }

    /**
     * @param queryParams
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function getPagesForDisplay(queryParams) {
        queryParams = queryParams || {};
        return resource.all('display').getList(queryParams).then(function (json) {
            return json.plain();
        });
    }

    /**
     * @returns {*}
     */
    function getSearchableValues() {
        var deferred = $q.defer();

        var promises = [
            PageTagFactory.getTags(),
            _getDataSourcesInUse()
        ];

        $q.all(promises).then(function (data) {
            deferred.resolve({
                tags: data[0],
                dataSources: data[1]
            });
        });

        return deferred.promise;
    }

    /**
     * @param pageId
     * @returns {PromiseLike<T> | Promise<T> | *}
     */
    function usePageAsTemplate(pageId) {
        return resource.one('template', pageId).get();
    }

    /**
     * @param id
     * @returns {*}
     */
    function incrementDownloadCount(id) {
        return resource.one('incrementdownload', id).get({ignoreLoadingBar: true});
    }

    /**
     * @param model
     */
    function publish(model) {
        return resource.all('publish').post(model);
    }

    /**
     * Returns promise for resolving data sources used across all widgets in library pages
     *
     * @returns {PromiseLike<T> | Promise<T> | *}
     * @private
     */
    function _getDataSourcesInUse() {
        var queryParams = {
            all: true,
            ignoreLoadingBar: true
        };
        return resource.all('datasources').getList(queryParams).then(function (json) {
            return json.plain();
        });
    }

    /**
     * Triggers a thumbnail update for a page or all the pages
     * WARNING: Use updateThumbnails(null) carefully. It stacks the thumbnail generation for all library pages
     *
     * @param pageId
     */
    function updateThumbnails(pageId) {
        if (pageId) {
            return resource.all('updatethumbnails').one(pageId).get();
        } else {
            return resource.all('updatethumbnails').get({
                is_in_library: false
            });
        }
    }

    /**
     * @param sectionId
     * @returns {*}
     */
    function updateSectionThumbnails(sectionId) {
        return resource.all('updatesectionthumbnails').one(sectionId).get();
    }
}

/**
 * @ngInject
 */
function PageLibraryDataGridFactory(
    DataGridRender,
    DesignFactory,
    PageLibraryColumns,
    DataSourceFactory
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return manage widgets datatable options
     * @returns {{}}
     */
    function getDTOptions() {

        var dtOptions = {};
        dtOptions.customRenders = {};

        dtOptions.numActions = 1;
        dtOptions.customRenders[PageLibraryColumns.ID] = function (data, type, full) {
            var html = '';
            html += DataGridRender.getDeleteButton(
                full.can_be_deleted,
                'dash/pages/' + data,
                full['title'],
                full.can_be_deleted ? null : full.can_be_deleted_tooltip
            );
            return html;
        };

        dtOptions.customRenders[PageLibraryColumns.DATA_SOURCES] = function (data, type, full) {
            var result = '';
            var data_sources = [];

            _.each(data, function (data_source) {
                if (data_source.data_type === 'service_data' || data_source.data_type === 'category_data') {
                    data_sources.push(DataSourceFactory.getDataSourceDetails(data_source.data_type, data_source.data_source_id));
                }
            });

            // order the data (the keys are lost)
            data_sources = _.uniqBy(_.orderBy(data_sources, ['is_connected'], ['desc']), 'id');

            // build the list of data sources
            _.each(data_sources, function (data_source) {
                if (data_source) {
                    var className = '';
                    var title = data_source.name;
                    var color = data_source.color;
                    var id = data_source.id;
                    if (data_source.is_connected) {
                        if (data_source.type === 'service_data') {
                            result += '<a href="#/service/details/' + id + '" class="no-link-formatting">';
                        } else {
                            result += '<a href="#/category/details/' + id + '" class="no-link-formatting">';
                        }
                    } else {
                        className = 'disconnected';
                        title += ' (not connected)';
                    }

                    const icon = data_source.has_custom_icon
                      || data_source.type === 'category_data'
                      ? data_source.icon : 'serviceicon-' + data_source.icon;

                    result +=
                        '<div class="has-tooltip service-square service-square-32 ' + className + '" title="' + title + '" style="background-color:' + color + '">' +
                        '<div class="icon ' + icon + '"></div>' +
                        '</div>';

                    if (data_source.is_connected) {
                        result += '</a>';
                    }
                }
            });

            return result;
        }

        dtOptions.customRenders[PageLibraryColumns.LAYOUTS] = function (data, type, full) {
            var url = !_.isEmpty(full) && full.thumbnail
                ? full.thumbnail.secure_url
                : widgetImageUrl;
            return '<div class="dash-thumbnail-container"><div class="inner"><img src="' + url + '" /></div></div>'
        };

        dtOptions.customRenders[PageLibraryColumns.TAGS] = function (data) {
            var html = '';
            _.each(data, function(item) {
                html += '<span class="label label-default mr5">' + item + ' </span>';
            });
            return html;
        };

        dtOptions.customRenders[PageLibraryColumns.CREATED_AT] = function (data, type, full) {
            return full[PageLibraryColumns.FORMATTED_CREATED_AT];
        };

        // column widths
        dtOptions.columnWidths = {};
        dtOptions.columnWidths[PageLibraryColumns.LAYOUTS] = 110;

        return dtOptions;
    }
}