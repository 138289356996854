'use strict';
import angular from 'angular';
import _ from 'lodash';
import { ColumnFormat } from 'coreModules/shared/scripts/app.constants';

angular
  .module('widget.accountmanager.models', [])

  .factory('AccountManagerModelFactory', AccountManagerModelFactory);

/**
 * @ngInject
 */
function AccountManagerModelFactory() {
  return {
    getAccountManagerWidget: getAccountManagerWidget
  };

  /**
   *
   * @param {number} id
   * @param {Object} userImageMetadata
   * @param {Object|Array} data
   * @param {Array} selectedColumns
   * @constructor
   */
  function AccountManagerWidget(id, userImageMetadata, data, selectedColumns) {
    var self = this;

    self.id = id;
    self.managerImage = userImageMetadata ? userImageMetadata.secure_url : null;
    self.selectedColumns = selectedColumns;
    self.predefinedData = data;
    self.mappedData = _.map(selectedColumns, function(column) {
      return {
        field: column.field,
        label: column.label,
        format: column.format,
        value: FormattedValue(column.format, data, column)
      };
    });
  }

  function FormattedValue(column_format, data, column) {
    var value;

    if (column_format === ColumnFormat.FORMAT_DATETIME) {
      value = data[`formatted_${column.field}`];
    } else if (column_format === ColumnFormat.FORMAT_BOOLEAN) {
      value = FormatBoolean(data[column.field]);
    } else if (
      column_format === ColumnFormat.FORMAT_ENUM &&
      column.field !== 'timezone'
    ) {
      const item = _.find(column.values, value => value.key === data[column.field]);
      if (item) {
        value = item.value;
      }
    } else {
      value = data[column.field];
    }
    
    if (!value) {
      value = data[column.field];
    }
    return value;
  }

  function FormatBoolean(stringBoolean) {
    return stringBoolean ? 'Yes' : 'No';
  }

  /**
   *
   * @param {number} id
   * @param {Object} userImageMetadata
   * @param {Object|Array} data
   * @param {Array} selectedColumns
   * @returns {AccountManagerWidget}
   */
  function getAccountManagerWidget(
    id,
    userImageMetadata,
    data,
    selectedColumns
  ) {
    return new AccountManagerWidget(
      id,
      userImageMetadata,
      data,
      selectedColumns
    );
  }
}
