'use strict';
import angular from 'angular';

angular.module('core.scope.services', [])

    .factory('ScopeFactory', ScopeFactory);

function ScopeFactory() {

    var scopes = {};

    /**
     *
     * @param scope
     * @param id
     */
    function $registerScope(scope, id) {
        scopes[id] = scope;

        // Ensure the scope is unregistered upon destruction
        if (scope.$on) {
            scope.$on('$destroy', function() {
                $unregisterScope(id);
            });
        }

        return scope;
    }

    /**
     *
     * @param id
     */
    function $unregisterScope(id) {
        delete scopes[id];
    }

    /**
     *
     * @param id
     * @returns {*}
     */
    function $getScope(id) {
        return scopes[id];
    }

    return {
        $registerScope: $registerScope,
        $getScope: $getScope,
        $unregisterScope: $unregisterScope
    };
}