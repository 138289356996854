'use strict';
import angular from 'angular';
import './base/alert.components';
import './widget/alert.widget.components';
import './item/alert.item.components';
import './alert.constants';
import './base/alert.ctrls';
import './base/alert.models';
import './base/alert.resources';
import './base/alert.services';

import managealertsHtmlUrl from './base/managealerts.html';


angular.module('core.alert', [
    'core.alert.components',
    'core.alert.widget.components',
    'core.alert.item.components',
    'core.alert.constants',
    'core.alert.ctrls',
    'core.alert.models',
    'core.alert.resources',
    'core.alert.services'
])

    .config(['$stateProvider', 'SwitchAppServiceProvider', function ($stateProvider, SwitchAppServiceProvider) {
        $stateProvider
            .state('managealerts', {
                parent:'layout',
                url: '/managealerts',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('managealerts'),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_MANAGE_ALERTS;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: managealertsHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
        // .state('alerts.new', {
        //     parent:'layout',
        //     url: '/alerts/new/',
        //     controller: 'AlertAddController',
        //     templateUrl: $.core.setCorePath();
        // })
        // .state('alerts.edit', {
        //     parent:'layout',
        //     url: '/alerts/:id',
        //     controller: 'AlertEditController',
        //     templateUrl: $.core.setCorePath();
        // })
    }])