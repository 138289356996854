import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { XYChart, XYCursor } from '@amcharts/amcharts5/xy';
import { percent } from '@amcharts/amcharts5';

export function useXYChart(context) {
  const { root, chart, config, isRotated } = context();

  function createXYChart() {
    const pinchOptions = isRotated.value
      ? {
          wheelX: config?.value?.disableZoom ? Constant.NONE : Constant.PAN_Y,
          wheelY: config?.value?.disableZoom ? Constant.NONE : Constant.ZOOM_Y,
          pinchZoomY: !config?.value?.disableZoom,
        }
      : {
          wheelX: config?.value?.disableZoom ? Constant.NONE : Constant.PAN_X,
          wheelY: config?.value?.disableZoom ? Constant.NONE : Constant.ZOOM_X,
          pinchZoomX: !config?.value?.disableZoom,
        };

    const goalProps = {};
    if (config.value?.isGoal) {
      goalProps.height = percent(90);
      goalProps.zoomX = false;
      goalProps.zoomY = false;
    }

    chart.value = root.value.container.children.push(
      XYChart.new(root.value, {
        layout: root.value.verticalLayout,
        panX: !config?.value?.disableZoom,
        panY: !config?.value?.disableZoom,
        ...goalProps,
        ...pinchOptions,
        maxTooltipDistance: -1,
      })
    );
  }

  function createXYCursor() {
    const cursor = chart.value.set(Constant.CURSOR, XYCursor.new(root.value, {}));
    cursor.lineX.set(Constant.VISIBLE, config.value.cursorEnabled);
    cursor.lineY.set(Constant.VISIBLE, config.value.cursorEnabled);
  }
  return {
    createXYChart,
    createXYCursor,
  };
}
