import { BaseDrawOption } from '@/modules/ta/widget/models/drawoptions/BaseDrawOption';

export class ChartDrawOption extends BaseDrawOption {
  /**
   * @type {boolean}
   */
  has_legend;

  /**
   * @type {boolean}
   */
  has_tooltip;

  /**
   * @type {boolean}
   */
  depth;

  /**
   * @type {boolean}
   */
  angle;

  /**
   * @type {boolean}
   */
  is_hand_drawn;

  constructor(model = {}) {
    super(model);
    this.depth = model.depth !== undefined ? model.depth : 0;
    this.angle = model.angle !== undefined ? model.angle : 0;
    this.is_hand_drawn = model.is_hand_drawn !== undefined ? model.is_hand_drawn : false;
    this.has_tooltip = model.has_tooltip !== undefined ? model.has_tooltip : true;
    this.has_bullets = model.has_bullets !== undefined ? model.has_bullets : true;
    this.has_legend = model.has_legend !== undefined ? model.has_legend : false;
  }
}
