export class BaseService {
  /**
   * @param callback
   * @returns {function(...[*]=)}
   */
  debounce(callback) {
    let currentPromise = null;
    return async (...args) => {
      if (currentPromise) {
        return currentPromise;
      }
      currentPromise = callback(...args);
      const response = await currentPromise;
      currentPromise = null;
      return response;
    };
  }
}
