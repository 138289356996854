import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class ReportExecutiveSummaryPageModel extends BaseModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  page_index;

  /**
   * @var {string}
   */
  title;

  /**
   * @var {string}
   */
  page_number;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.page_index = model.page_index;
    this.title = model.title;
    this.page_number = model.page_number;
  }
}
