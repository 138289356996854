<template>
  <section
    class="is-relative"
    :class="{ 'is-comparing': hasComparison }"
    :style="[
      fontColorStyles,
      containerStyles,
      calculatedSize,
      borderStyles,
      radiusStyles,
      radiantStyles,
    ]"
    data-cy="rectangle-container"
  >
    <div class="value" v-if="isLoadingValue || !primaryMetric">
      <p class="subtitle" data-cy="loader">
        <span>{{ $t('Loading...') }}</span>
      </p>
    </div>
    <div v-else>
      <div
        class="value-container m-b-50"
        :class="{ 'value-compare-container': hasComparison }"
        data-cy="value-container"
      >
        <div class="flex is-flex justify-center">
          <div class="value m-10">
            <span
              :style="[
                fontStyles,
                getFontSizeStyle(
                  DataFontMultiplierMultiMetric.MIN,
                  DataFontMultiplierMultiMetric.MAX
                ),
              ]"
              data-cy="value"
              :title="emptyDataMessage"
            >
              <b-tooltip
                :active="!!isMultiMetricFormattedEllipsisActive()"
                :label="isMultiMetricFormattedEllipsisActive()"
                type="is-dark"
                size="is-small"
              >
                <div class="overflow-value" ref="formattedMultimetricTooltip">
                  {{ formatValue(primaryMetric) }}
                </div>
              </b-tooltip>
            </span>
          </div>
          <div v-if="hasComparison && showComparisonArrows" class="m-10">
            <div
              :style="
                getFontSizeStyle(
                  DataComaprisonMultiplierMultiMetric.MIN,
                  DataComaprisonMultiplierMultiMetric.MAX
                )
              "
            >
              <span data-cy="delta-value">{{ getFormattedDeltaValue(primaryMetric) }}</span>
              <span v-if="!primaryMetric.compareAsValue">%</span>
              <svg
                v-if="isExportingReportStudio"
                class="delta-value"
                :class="[
                  getIconClassExportReportStudio(primaryMetric),
                  getDeltaValueClasses(primaryMetric),
                ]"
                :style="
                  getIconSizeStyle(
                    DataComaprisonMultiplierMultiMetric.MIN,
                    DataComaprisonMultiplierMultiMetric.MAX
                  )
                "
              >
                <use
                  xlink:href=""
                  :href="`#${getIconClassExportReportStudio(primaryMetric)}`"
                ></use>
              </svg>
              <b-icon
                v-else
                pack="fa"
                class="delta-value"
                :class="getDeltaValueClasses(primaryMetric)"
                :custom-class="getIconClass(primaryMetric)"
                :style="
                  getFontSizeStyle(
                    DataComaprisonMultiplierMultiMetric.MIN,
                    DataComaprisonMultiplierMultiMetric.MAX
                  )
                "
                data-cy="delta-icon"
              ></b-icon>
            </div>
            <span
              class="subtitle m-b-0"
              :style="[
                getFontSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                ),
              ]"
              data-cy="delta-subtitle"
            >
              {{ $t('vs') }}
              <span>{{ formatComparisonValue(primaryMetric) }}</span>
              {{ $t('prev.') }}
            </span>
          </div>
        </div>
        <p
          class="m-b-10 m-t--10 text-align-center"
          :style="getFontSizeStyleForPrimaryLabel(DataFontMultiplier.MIN, DataFontMultiplier.MAX)"
          data-cy="primary-label"
          :class="showMetricName"
        >
          {{ primaryMetric.label }}
        </p>
      </div>
      <div class="flex is-flex metrics-container">
        <div
          v-for="metric in secondaryMetrics"
          :key="metric.label"
          class="metric-card text-align-center"
          :style="[getFontSizeStyle(DataFontMultiplier.MIN, DataFontMultiplier.MAX), widthStyles]"
          data-cy="secondary-container"
          :title="emptyDataMessage"
        >
          <div>
            <div class="metric-title m-b-5" data-cy="secondary-value">
              <b-tooltip
                :active="!!isMultiMetricSecondaryFormattedEllipsisActive(metric.value)"
                :label="isMultiMetricSecondaryFormattedEllipsisActive(metric.value)"
                type="is-dark"
                size="is-small"
              >
                <div class="overflow-value" ref="formattedMultimetricSecondaryTooltip">
                  {{ formatValue(metric) }}
                </div>
              </b-tooltip>
            </div>
            <div
              v-if="hasComparison"
              class="comparison-metrics"
              :style="[getFontSizeStyle(LabelFontMultiplier.MIN, LabelFontMultiplier.MAX)]"
              data-cy="secondary-delta-container"
            >
              <div>
                <span data-cy="secondary-value">{{ getFormattedDeltaValue(metric) }}</span>
                <span v-if="!metric.compareAsValue">%</span>
                <svg
                  v-if="isExportingReportStudio"
                  class="delta-value"
                  :class="[getIconClassExportReportStudio(metric), getDeltaValueClasses(metric)]"
                  :style="getIconSizeStyle(LabelFontMultiplier.MIN, LabelFontMultiplier.MAX)"
                >
                  <use xlink:href="" :href="`#${getIconClassExportReportStudio(metric)}`"></use>
                </svg>
                <b-icon
                  v-else
                  pack="fa"
                  class="delta-value"
                  :class="getDeltaValueClasses(metric)"
                  :custom-class="getIconClass(metric)"
                  :style="getFontSizeStyle(LabelFontMultiplier.MIN, LabelFontMultiplier.MAX)"
                  data-cy="secondary-delta-icon"
                ></b-icon>
              </div>
              <span class="subtitle m-b-0">
                <span data-cy="secondary-delta-value">{{ formatComparisonValue(metric) }}</span>
              </span>
            </div>
            <span
              :class="[selectMetricLabelClass, showMetricName]"
              :style="[getFontSizeStyle(DataFontMultiplier.MIN, DataFontMultiplier.MAX)]"
              data-cy="secondary-label"
            >
              {{ metric.label }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ScoreCardMixin } from '@/modules/core/scorecard/mixins/ScoreCardMixin';
import NumeralService from '@/modules/core/app/services/NumeralService';
import { FONT_SIZE_THRESHOLD } from '@/modules/core/scorecard/scorecard.constants';
import { isNil } from 'lodash';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export default {
  name: 'MultiMetricCard',
  mixins: [ScoreCardMixin],
  props: {
    metrics: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  computed: {
    isLoadingValue() {
      return this.metrics === undefined;
    },
    primaryMetric() {
      const metricsArray = this.metrics.filter((val) => val);
      return metricsArray[0];
    },
    calculatedSize() {
      const wrap = this.wrapSize < 100 ? this.wrapSize * 3.2 : this.wrapSize * 3;
      const width = `${this.wrapSize * 7}px`;
      const height = `${wrap}px`;
      return { width, height };
    },
    secondaryMetrics() {
      const metricsArray = this.metrics.filter((val) => val);
      return metricsArray.slice(1, 6);
    },
    widthStyles() {
      let width = 20;
      if (this.metrics.length < 6) {
        width = 100 / (this.metrics.length - 1);
      }
      return { width: `${width}%` };
    },
    hasComparison() {
      return !isNil(this.primaryMetric?.comparisonValue);
    },
    selectMetricLabelClass() {
      if (!isNil(this.primaryMetric?.comparisonValue)) {
        return 'metric-label';
      }

      if (this.fontSize > 28) {
        return 'metric-label-non-comparison';
      }

      return '';
    },
  },
  methods: {
    formatValue(metric) {
      return NumeralService.formatValue(
        metric.value,
        metric.format,
        metric.precision,
        this.isCompactNumber,
        metric.currencySymbol
      );
    },
    formatComparisonValue(metric) {
      return NumeralService.formatValue(
        metric.comparisonValue,
        metric.format,
        metric.precision,
        metric.isCompactNumber,
        metric.currencySymbol
      );
    },
    getFontSizeStyleForPrimary(minMultiplier, maxMultiplier) {
      const styles = {};

      if (this.fontSize) {
        let fontSize =
          this.fontSize < FONT_SIZE_THRESHOLD
            ? this.fontSize * maxMultiplier
            : this.fontSize * minMultiplier;
        fontSize *= 2;
        styles.fontSize = `${fontSize}px`;
      }
      return styles;
    },
    getFontSizeStyleForPrimaryLabel(minMultiplier, maxMultiplier) {
      const styles = {};

      if (this.fontSize) {
        let fontSize =
          this.fontSize < FONT_SIZE_THRESHOLD
            ? this.fontSize * maxMultiplier
            : this.fontSize * minMultiplier;
        fontSize *= 1.4;
        styles.fontSize = `${fontSize}px`;
      }
      return styles;
    },
    getDeltaValue(metric) {
      if (!metric.comparisonValue) {
        return null;
      }

      return NumeralService.calculateDelta(
        metric.value,
        metric.comparisonValue,
        metric.compareAsValue,
        metric.format
      );
    },
    getFormattedDeltaValue(metric) {
      return NumeralService.formatValue(
        Math.abs(this.getDeltaValue(metric)),
        ColumnFormat.FORMAT_INTEGER,
        0,
        this.isCompactNumber,
        metric.currencySymbol
      );
    },
    getIconClass(metric) {
      return this.getDeltaValue(metric) >= 0 ? 'fa-caret-up' : 'fa-caret-down';
    },
    getIconClassExportReportStudio(metric) {
      return this.getDeltaValue(metric) >= 0 ? 'icon-caret-up' : 'icon-caret-down';
    },
    getDeltaValueClasses(metric) {
      const classes = [];

      const deltaValue = this.getDeltaValue(metric);
      if (!this.showDefaultComparisonArrowColor || deltaValue === 0) {
        classes.push('neutral-delta');
      } else if (deltaValue > 0) {
        classes.push(this.getPlusOrMinusDelta(metric, 'up'));
      } else if (deltaValue < 0) {
        classes.push(this.getPlusOrMinusDelta(metric, 'down'));
      }
      return classes;
    },
    getPlusOrMinusDelta(metric, deltaDirection = 'up') {
      let plusOrMinusDelta;
      if (deltaDirection === 'up') {
        plusOrMinusDelta = metric.compareInverted ? 'negative-delta' : 'positive-delta';
      } else {
        plusOrMinusDelta = metric.compareInverted ? 'positive-delta' : 'negative-delta';
      }

      if (metric.isComparisonOverridden) {
        plusOrMinusDelta =
          plusOrMinusDelta === 'negative-delta' ? 'positive-delta' : 'negative-delta';
      }

      return plusOrMinusDelta;
    },
    isMultiMetricFormattedEllipsisActive() {
      if (
        (this.isCompactNumber ||
          this.$refs.formattedMultimetricTooltip?.offsetWidth <
            this.$refs.formattedMultimetricTooltip?.scrollWidth) &&
        this.primaryMetric.value > 1000
      ) {
        return NumeralService.formatValue(
          this.primaryMetric.value,
          this.format,
          this.precision,
          false
        );
      }
      return '';
    },
    isMultiMetricSecondaryFormattedEllipsisActive(secondaryMetricValue) {
      if (
        (this.isCompactNumber ||
          this.$refs.formattedMultimetricSecondaryTooltip?.offsetWidth <
            this.$refs.formattedMultimetricSecondaryTooltip?.scrollWidth) &&
        secondaryMetricValue > 1000
      ) {
        return NumeralService.formatValue(secondaryMetricValue, this.format, this.precision, false);
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.value-container {
  top: 10%;
  position: absolute;
  left: 0;
  right: 0;
  @include justify-content(center);
  @include align-items(center);
  @include align-content(center);
}

.value-compare-container {
  top: 5%;
}

.metric-card {
  width: 20%;
  padding: 5px;
  &:not(:last-child) {
    border-right: 1px solid $white-light;
  }
}

.metrics-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 10px;
}

.is-relative {
  position: relative;
}

.justify-center {
  justify-content: center;
}

.m-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-t--10 {
  margin-top: -10px;
}

.metric-title {
  font-weight: bold;
}

.delta-value {
  &.positive-delta {
    color: $green;
  }

  &.negative-delta {
    color: $red;
  }
}

.metric-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inherit;
}

.metric-label-non-comparison {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-align-center {
  text-align: center;
}

.b-tooltip {
  display: block;
}

.overflow-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
