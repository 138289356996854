import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';
import { ExecutiveSummaryItem } from '@/modules/ta/executivesummaries/models/ExecutiveSummaryItem';

export class ExecutiveSummary extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  layout_id;

  /**
   * @var {string}
   */
  page_id;

  /**
   * @var {string}
   */
  page_id_deprecated;

  /**
   * @var {boolean}
   */
  is_enabled;

  /**
   * @var {?string}
   */
  metadata;

  /**
   * @var {Array<ExecutiveSummaryItem>}
   */
  items;

  /**
   * @var {string}
   */
  report_id;

  /**
   * @var {string}
   */
  report_page_id;

  constructor(model = {}) {
    super();

    // When there is no executive summary, an empty array comes with the endpoint
    // todo: @sin - Fix this for the old app as well new app.
    if (Array.isArray(model)) {
      model = {};
    }
    this.id = model.id;
    this.layout_id = model.layout_id;
    this.page_id = model.page_id;
    this.page_id_deprecated = model.page_id_deprecated;
    this.is_enabled = model.is_enabled;
    this.metadata = model.metadata;
    this.items = model.items?.map((item) => new ExecutiveSummaryItem(item));
    this.report_id = model.report_id;
    this.report_page_id = model.report_page_id;
  }
}
