'use strict';
import angular from 'angular';

angular.module('dashboard.ctrls', [])


    .controller('DashboardController', DashboardController)
    .controller('ViewAsController', ViewAsController);

//
// Dashboard controller
//
DashboardController.$inject = ['$scope', '$http', 'DashboardService'];
function DashboardController($scope, $http, DashboardService) {
}

//
// View As Controller
// - Used on top of some pages to "View as client" or "View as client group"
//
ViewAsController.$inject = ['$scope', '$http'];
function ViewAsController($scope, $http) {

    // default is to view the client list (not the client groups list)
    $scope.clientOrGroup = 'client';
    $scope.show = {
        client: true,
        clientGroups: false
    };
    // called when view changes the "View as xxxx" dropdown
    $scope.toggleClientGroups = function() {
        $scope.show.client = ('client' == $scope.clientOrGroup);
        $scope.show.clientGroups = !$scope.show.client;
    };
}