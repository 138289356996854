import { DatasourcedWidgetMetadata } from '@/modules/ta/widget/models/metadata/DataSourcedWidgetMetadata';
import { BigNumberDrawOption } from '@/modules/ta/widget/models/drawoptions/BigNumberDrawOption';

export class BigNumberMetadata extends DatasourcedWidgetMetadata {
  /**
   * @var {Array.<string>}
   */
  rounded_metrics;

  constructor(model = {}) {
    super(model);

    this.draw_options = new BigNumberDrawOption(model.draw_options);
    this.rounded_metrics = model.rounded_metrics || [];
  }
}
