'use strict';
import angular from 'angular';
import _ from 'lodash';
import drawOptionPanelHtmlUrl from './drawoptionpanel.html';
import drawOptionPanelHeaderHtmlUrl from './drawoptionpanelheader.html';
import drawOptionPanelContentHtmlUrl from './drawoptionpanelcontent.html';
import drawOptionPanelFooterHtmlUrl from './drawoptionpanelfooter.html';

angular.module('drawoptionpanel.directives', [])
    .component('drawOptionPanel', {
        templateUrl: drawOptionPanelHtmlUrl,
        bindings: {
            options: '<'
        },
        controllerAs: 'vm',
        controller: DrawOptionPanelController
    })
    .component('drawOptionPanelHeader', {
        templateUrl: drawOptionPanelHeaderHtmlUrl,
        bindings: {
            onClose: '&',
            headerTitle: '<'
        },
        controllerAs: 'vm'
    })
    .component('drawOptionPanelBody', {
        templateUrl: drawOptionPanelContentHtmlUrl,
        bindings: {
            isLoading: '<',
            widgetTypeTabTitle: '<',
            drawOptions: '=',
            reportDrawOptions: '=',
            widgetDrawOptions: '=',
            widgetTypeDrawOptions: '=',
            selectedTabIndex: '<',
            isExport: '<',
            isLayout: '<'
        },
        controller: DrawOptionPanelContentController,
        controllerAs: 'vm'
    })
    .component('drawOptionPanelFooter', {
        templateUrl: drawOptionPanelFooterHtmlUrl,
        bindings: {
            onSave: '&',
            onClose: '&',
            onCancel: '&',
            isLoading: '<',
            isExport: '<',
            isLayout: '<'
        },
        controllerAs: 'vm'
    });

/**
 * @ngInject
 *
 * @binding options
 * {String} options.panelId
 * {String} options.layoutId or options.widgetTypeId
 * {String} options.headerTitle
 * {Array} options.drawOptions
 * {Bool} options.isLayout
 * {Bool} options.isExport
 *
 */
function DrawOptionPanelController(
    DrawOptionPanelFactory,
    $element
) {
    var vm = this;
    vm.isLoading = isLoading;
    vm.onClose = onClose;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        angular.element('body').append($element);
        DrawOptionPanelFactory.setInit(vm.options);
        DrawOptionPanelFactory.retrievePanelDrawOptions(vm.options).then(function (panelData) {
            vm.panelData = panelData;
        });
    }

    function $onDestroy() {
        DrawOptionPanelFactory.closePanel();
        $element.remove();
    }

    /**
     * User Actions
     */
    function onClose() {
        DrawOptionPanelFactory.closePanel();
    }

    /**
     * States
     */
    function isLoading() {
        return _.isUndefined(vm.panelData) || DrawOptionPanelFactory.getIsLoading();
    }

}

function DrawOptionPanelContentController() {
    var vm = this;

    vm.showWidgetTypeDrawOptions = showWidgetTypeDrawOptions;
    vm.toggleTab = toggleTab;

    vm.$onInit = $onInit;

    function $onInit() {
        if (vm.isExport) {
            if (vm.isLayout) {
                toggleTab(1);
            }
            else {
                toggleTab(2);
            }
        }
        else {
            if (vm.isLayout) {
                toggleTab(2);
            }
            else {
                toggleTab(0);
            }
        }
    }

    function toggleTab(index) {
        vm.selectedTabIndex = index;
    }

    function showWidgetTypeDrawOptions() {
        return !vm.isLayout && !vm.isExport;
    }

}