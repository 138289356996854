import { JSONParser, net } from '@amcharts/amcharts5';
import am5geodata_data_countries2 from '@amcharts/amcharts5-geodata/data/countries2';
import { Countries } from '@/modules/core/charts/am5/geo/geo.charts.constants';
import { maxBy } from 'lodash';

export function useGeoMapBase(context) {
  const { chart, config } = context();
  const countriesMap = {};
  function hideAllSeries() {
    chart.value.series.each((series) => {
      series.hide();
    });
  }

  /* eslint-disable tap/no-raw-text-js */
  async function loadIdMap(id) {
    let mapId = countriesMap[id];
    if (mapId === Countries.USA_LOW) {
      mapId = Countries.USA_TERRITORIES_LOW;
    }
    const result = await net.load(
      `https://cdn.amcharts.com/lib/5/geodata/json/${mapId}.json`,
      chart.value
    );
    return JSONParser.parse(result.response);
  }

  /* eslint-disable tap/no-raw-text-js */
  function loadCountryAssociation() {
    const keys = Object.keys(am5geodata_data_countries2);
    keys.forEach((id) => {
      const { maps } = am5geodata_data_countries2[id];
      if (maps.length) {
        [countriesMap[id]] = maps;
      }
    });
  }
  function setActualData() {
    return config.value.comparisonEnabled
      ? config.value.data.priorPeriodData
      : config.value.data.currentPeriodData;
  }

  function findMaxValue(field) {
    const maxFields = {};
    if (maxFields[field]) return maxFields[field];
    maxFields[field] = maxBy(setActualData(), (row) => row[field]);
    return maxFields[field][field];
  }

  function centerToUSA() {
    chart.value.zoomToGeoPoint({ longitude: -115, latitude: 32 }, 5.5);
  }

  /**
   * @param country
   * @param series
   * @param dataItem
   * @returns {{waitForStop: zoomAnimation.waitForStop}}
   */
  function setZoomToCountry(country, series, dataItem) {
    let zoomAnimation = {
      waitForStop: () => {
        /* eslint-disable no-new */
        new Promise(() => {});
      },
    };
    if (country.toUpperCase() === Countries.US) {
      centerToUSA();
    } else {
      zoomAnimation = series.zoomToDataItem(dataItem);
    }
    return zoomAnimation;
  }

  return {
    hideAllSeries,
    loadIdMap,
    loadCountryAssociation,
    findMaxValue,
    setActualData,
    setZoomToCountry,
  };
}
