'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('scheduledreport.services', [])
    .service('ScheduledReportFactory', ScheduledReportFactory);

//
// Scheduled report service
//
ScheduledReportFactory.$inject = ['Restangular', 'ScheduledReportColumns', 'DataViewName', 'ColumnValueType'];
function ScheduledReportFactory(Restangular, ScheduledReportColumns, DataViewName, ColumnValueType) {
    var scheduledreports = Restangular.all('scheduledreports');

    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        return data.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        return scheduledreports.getList(queryParams);
    }

    /**
     * Return user specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.customRenders[ScheduledReportColumns.ID] = function (data, type, full) {
            var href= '#/scheduledreports/detail/' + data;
            return '<a href="' + href + '">' + data + '</a>';
        };
        return dtOptions;
    }

    return {
        scheduledreports: scheduledreports,
        getColumns: getColumns,
        getData: getData,
        getDTOptions: getDTOptions,
    }
}