'use strict';
import angular from 'angular';
import $ from 'jquery';
import './base/emailsoverview.ctrls';
import './base/email.resources';

import dashemailsoverviewHtmlUrl from './base/dashemailsoverview.html';

angular.module('core.email', [
    'core.emailsoverview.ctrls',
    'core.email.resources'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        var dashfilters = {
            templateUrl: AppConfig.MAGE_URL + 'layout/dashfilters',
            controller: 'DashFiltersController'
        };

        $stateProvider
            .state('emailsoverview', {
                parent:'layout',
                url: '/emailsoverview',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('emailsoverview'),
                    pageType: function(PageType) {
                        return PageType.TYPE_DASH;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_EMAILS_OVERVIEW;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: dashemailsoverviewHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
    }]);