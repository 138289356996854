
export const $WidgetFilterEvents = {
    SET_TOOLTIP_VALUES: 'widget:filter:SET_TOOLTIP_VALUES',
    UPDATE_ASSOCIATE_FILTER: 'widget:filter:UPDATE_ASSOCIATE_FILTER',
    TOGGLE_DROP_ZONES: 'widget:filter:TOGGLE_DROP_ZONES',
    RESET_DROP_ZONES: 'widget:filter:RESET_DROP_ZONES',
    WIDGET_FILTER_SAVE: 'widget:filter:WIDGET_SAVE',
    UPDATE_GLOBAL_FILTERS: 'widget:filter:UPDATE_GLOBAL_FILTERS'
};

export const $WidgetCreateEvents = {
    INIT_CREATE: '$WidgetCreateEvents:INIT_CREATE',
    CREATED_FROM_LIBRARY: '$WidgetCreateEvents:CREATED_FROM_LIBRARY',
    CREATED: '$WidgetCreateEvents:CREATED'
};

export const $TimeoutValues = {
    TIMEOUT: 20
};

export const SanitizeHtmlOptionsAllEmpty = {
    allowedTags: [],
    selfClosing: [],
    allowedAttributes: {},
    allowedSchemes: [],
    allowedSchemesAppliedToAttributes: [],
    enforceHtmlBoundary: true // default false
}