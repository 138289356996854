'use strict';
import angular from 'angular';
import { each } from 'lodash';

angular.module('exportbuilder.dashboard.models')
    .factory('ExportBuilderUndoModelFactory', ExportBuilderUndoModelFactory);

function ExportBuilderUndoModelFactory() {
    return {
        getActionModel: getActionModel,
        getMutationModel: getMutationModel,
        getMutationWithAction: getMutationWithAction
    }
}

/**
 * @constructor
 */
export class UndoRedoState {

    constructor() {
        this.doStack = [];
        this.undoStack = [];
        this.currentMutationPromise = null;
    }

}

/**
 * @constructor
 */
export class ActionModel {

    constructor(doFn = null, undoFn = null) {
        this.do = doFn;
        this.undo = undoFn;
    }

    static factory(doFn, undoFn) {
        return new ActionModel(doFn, undoFn);
    }

    /**
     * Setter
     * @param value
     */
    setDo(value) {
        this.do = value;
    }

    /**
     * Setter
     * @param value
     */
    setUndo(value) {
        this.undo = value;
    }
}

/**
 * @constructor
 */
export class MutationModel {

    /**
     * @type {boolean}
     */
    #singleRun = false;
    /**
     * @type {boolean}
     */
    #delayed = false;
    /**
     * @type {boolean}
     */
    #isAsync = false;
    /**
     * @type {Array<ActionModel>}
     */
    #actions = [];

    constructor() {
    }

    /**
     * @param {ActionModel} action
     */
    addAction(action) {
        this.#actions.push(action)
    }

    /**
     * Performs all redos
     * @returns {Array}
     */
    performRedo() {
        const promises = [];
        each(this.#actions, action => {
            promises.push(action.do());
        });
        return promises;
    }

    /**
     * Performs all undos
     * @returns {Array}
     */
    performUndo() {
        const promises = [];
        each(this.#actions, function(action) {
            promises.push(action.undo());
        });
        return promises;
    }

    /**
     * @param value
     */
    setSingleRun(value) {
        this.#singleRun = value;
    }

    /**
     * @returns {boolean}
     */
    getSingleRun() {
        return this.#singleRun
    }

    setDelayed(value) {
        this.#delayed = value;
    }

    getDelayed() {
        return this.#delayed
    }

    setIsAsync(value) {
        this.#isAsync = value;
    }

    getIsAsync() {
        return this.#isAsync
    }
}

function getActionModel(doFn, undoFn) {
    return new ActionModel(doFn, undoFn)
}

function getMutationModel() {
    return new MutationModel();
}

function getMutationWithAction(doFn, undoFn) {
    var mutation = new MutationModel();
    mutation.addAction(new ActionModel(
        doFn,
        undoFn
    ));
    return mutation;
}
