export const mutations = {
  /**
   *
   * @param state
   * @param tabId string
   * @param activeTabIndex int
   */
  setActiveTabIndex(state, { tabId, activeTabIndex }) {
    state.activeTabState[tabId] = activeTabIndex;
  },
};
