'use strict';
import angular from 'angular';
import $ from 'jquery';

angular.module('core.reportingtemplate.directives', [])

    .directive('coverPageUpload', coverPageUpload);

/**
 * @ngInject
 */
function coverPageUpload(AppService) {
    return {
        restrict: 'A',
        controller: function($scope) {

            $scope.pageUrl = [];
            $scope.hasPage = [];
            $scope.pageAssetName = [];

            $scope.setCoverPage = function(type, assetName) {
                AppService.getAssetUrl(assetName, 'pdf').success(function(json) {
                    if (json.status == 'success' && json.data != false) {
                        $scope.pageUrl[type] = json.data;
                        $scope.hasPage[type] = true;
                    }
                    $scope.pageAssetName[type] = assetName;
                });
            };
        },
        link: function(scope, el) {
            scope.uploadCoverPageCallback = function(json, model) {
                //Only handle this callback if files have been uploaded
                if (scope.files != null && scope.files.length) {
                    scope.$evalAsync(function() {
                        angular.forEach(scope.files, function(file) {
                            if (file.key == 'page_cover_page') {
                                $('#page_cover_page').fileinput('clear');
                                //New reporting template was just added so append new reporting template id
                                if (json.data.is_new_entity) {
                                    //Need to update both because we could still be on same page
                                    //and next time is_new_entity will be false
                                    scope.pageAssetName['cover_page'] += json.data.id;
                                }
                                scope.setCoverPage('cover_page', scope.pageAssetName['cover_page']);
                            }
                            else if (file.key == 'page_back_page') {
                                $('#page_back_page').fileinput('clear');
                                //New reporting template was just added so append new reporting template id
                                if (json.data.is_new_entity) {
                                    //Need to update both because we could still be on same page
                                    //and next time is_new_entity will be false
                                    scope.pageAssetName['back_page'] += json.data.id;
                                }
                                scope.setCoverPage('back_page', scope.pageAssetName['back_page']);
                            }
                        });
                    });
                }
                //If removing uncheck and set back to empty cover page
                if (model['remove_cover_page'] == 'yes') {
                    $('input:checkbox[name="remove_cover_page"]').prop('checked', false);
                    scope.hasPage['cover_page'] = false;
                }
                if (model['remove_back_page'] == 'yes') {
                    $('input:checkbox[name="remove_back_page"]').prop('checked', false);
                    scope.hasPage['back_page'] = false;
                }
            };
        }
    }
}
