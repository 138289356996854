'use strict';
import angular from 'angular';
import $ from 'jquery';
import Isotope from 'isotope-layout/dist/isotope.pkgd';
import './design.isotope.constants';

angular.module('design.isotope', ['design.isotope.constants'])
    .controller('isoIsotope', IsotopeController)
    .directive('isoIsotope', IsotopeDirective)
    .directive('isoIsotopeItem', IsotopeItemDirective);

/**
 * @ngdoc controller
 * @name isotope-angular.controller:isoIsotope
 *
 * @description
 * The `isoIsotope` controller contains all the logic to manage a
 * `isotope-angular` instance. It wraps the
 * [`Isotope`](http://isotope.metafizzy.co/) instance and exposes an API to
 * add and remove `Isotope` items.
 *
 * This controller also wraps and extends the native `Isotope` events.
 * Those events are emitted them through the `$rootScope`.
 *
 * @ngInject
 */
function IsotopeController(
    $scope,
    $element,
    $q,
    $timeout,
    $isoIsotope,
    $isoEvents,
    $isoOptions,
    PubSub,
    DesignFactory
) {
    var self = this;
    var deferred = $q.defer();
    var initialized = deferred.promise;
    var isDestroying = false;
    var hash = new Date().getTime();

    self.isotope = undefined;
    self.add = add;
    self.remove = remove;

    registerEvents();
    applyDefaultOptions();

    if (DesignFactory.getIsExportingPage()) {
        initializeIsotope();
    }
    else if (DesignFactory.props.hasVariableHeightWidgets) {
        // Initialization will occur manually later on
        return;
    }
    else {
        // Setting this timeout prevents isotope form zooming in on first init
        $timeout(initializeIsotope, 0, false);
    }

    function add(element) {
        initialized.then(function () {
            if ($scope.isAppended) {
                self.isotope.appended(element);
            }
            else {
                self.isotope.prepended(element);
            }

            /**
             * @description
             * This event is fired when a [`isotope-item`](#/api/isotope-angular.directive:isoIsotopeItem)
             * is added to the `Isotope` instance.
             */
            $scope.$emit($isoEvents.ITEM.ADDED, hash, self.isotope, element);
            refresh();
        });
    }

    function remove(element) {
        initialized.then(function () {
            if (!isDestroying) {
                self.isotope.remove(element);

                /**
                 * @description
                 * This event is fired when a [`isotope-item`](#/api/isotope-angular.directive:isoIsotopeItem)
                 * is destroyed from the `Isotope` instance.
                 */
                $scope.$emit($isoEvents.ITEM.DESTROYED, hash, self.isotope, element);
                refresh();
            }
        });
    }

    function destroy(event)  {
        isDestroying = true;
        initialized.then(function () {
            self.isotope.destroy();

            /**
             * @description
             * This event is fired when the `Isotope` instance
             * associated with the `isoIsotope` controller is destroyed.
             */
            $scope.$emit($isoEvents.DESTROYED, hash, self.isotope, event);
        });
    }

    /**
     * Filter isotope elements
     * @param filterVal
     */
    function filter(filterVal) {
        self.isotope.options.filter =  filterVal;

        refresh();
    }

    /**
     * Search isotope elements
     * @param searchVal
     */
    function search(searchVal) {
        // eslint-disable-next-line
        const searchValEscaped = searchVal.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')

        let gsRegex  = new RegExp(searchValEscaped, 'gi' );

        self.isotope.options.filter = function(el) {
            return gsRegex ? $(el).text().match( gsRegex ) : '.active';
        };

        refresh();
    }

    function registerEvents() {
        //scope destroying
        $scope.$on('$destroy', function (event) {
            destroy(event);
        });

        //isotope destroying only
        $scope.$on($isoEvents.DESTROY, function (event) {
            destroy(event);
        });

        $scope.$on($isoEvents.INIT, function () {
            initializeIsotope();
        });

        $scope.$on($isoEvents.LAYOUT, function (event) {
            // Stop the event from being propagated to other scopes
            if (!event.defaultPrevented) {
                event.defaultPrevented = true;
            }
            updateSort();
        });

        $scope.$on($isoEvents.RESIZE, function (event) {
            // Stop the event from being propagated to other scopes
            if (!event.defaultPrevented) {
                event.defaultPrevented = true;
            }
            self.isotope.layout();
        });

        PubSub.on($isoEvents.FILTER, filter);
        PubSub.on($isoEvents.SEARCH, search);
    }

    function applyDefaultOptions() {
        $scope.options = $scope.isotope ? angular.copy($scope.isotope) : {};
        applyMissingDefaultOptions();
        prepareIsotopeOptions();

        function applyMissingDefaultOptions() {
            var option;

            for (option in $isoOptions) {
                if ($isoOptions.hasOwnProperty(option) && !$scope.options.hasOwnProperty(option)) {
                    $scope.options[option] = angular.copy($isoOptions[option]);
                }
            }
        }

        function prepareIsotopeOptions() {
            $scope.isAppended = $scope.options.isAppended;
            delete $scope.options.isAppended;
        }
    }

    function initializeIsotope() {
        self.isotope = new Isotope($element[0], $scope.options);

        /**
         * @description
         * This event is fired when the `Isotope` instance
         * associated with the `isoIsotope` controller is initialized.
         */
        $scope.$emit($isoEvents.INITIALIZED, hash, self.isotope);

        registerIsotopeEvents();
        deferred.resolve();

        function registerIsotopeEvents() {
            self.isotope.on($isoEvents.ISOTOPE.LAYOUT_COMPLETED, function (items) {
                /**
                 * @description
                 * This event is fired when the [`layoutComplete`]
                 * event is fired by the `Isotope` instance associated with
                 * the `isoIsotope` controller.
                 */
                $scope.$emit($isoEvents.LAYOUT_COMPLETED, hash, self.isotope, items);
            });
            self.isotope.on($isoEvents.ISOTOPE.REMOVED, function (items) {
                /**
                 * @description
                 * This event is fired when the [`removeComplete`]
                 * event is fired by the `Isotope` instance associated with
                 * the `isoIsotope` controller.
                 */
                $scope.$emit($isoEvents.REMOVED, hash, self.isotope, items);
            });
        }
    }

    function refresh() {
        initialized.then(function () {
            $timeout(function () {
                self.isotope.layout();
                updateSort();
            });
        });
    }

    function updateSort() {
        $timeout(function() {
            self.isotope.updateSortData($(self.isotope.getItemElements()));
            self.isotope.arrange({sortBy: 'displayOrder'});
        }, 0, false);
    }
}

/**
 * @description
 * The `pa-isotope` directive marks a HTML element to contain the
 * [`Isotope`](http://isotope.metafizzy.co/) instance.
 *
 * @param {Object=} isoOptions The [options](http://isotope.metafizzy.co/options.html)
 * object that should be passed to the `Isotope` instance to configure it.
 */
function IsotopeDirective() {
    return {
        controller: 'isoIsotope',
        restrict: 'EA',
        scope: {
            isotope: '<?isoOptions',
            layout: '<'
        }
    };
}

/**
 * @description
 * The `pa-isotope-item` directive marks a HTML element to contain an item
 * in a [`Isotope`](http://isotope.metafizzy.co/) instance.
 * @ngInject
 */
function IsotopeItemDirective() {
    return {
        link: link,
        require: '^isoIsotope',
        restrict: 'EA'
    };

    function link(scope, element, attrs, controller) {
        if (controller.isotope) {
            controller.add(element[0]);
        }

        scope.$on('$destroy', function (event) {
            // Stop the event from being propagated to other scopes
            if (!event.defaultPrevented) {
                event.defaultPrevented = true;
            }
            controller.remove(element[0]);
        });
    }
}