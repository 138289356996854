import angular from 'angular';

export default angular.module('worker.constants', []).constant('WorkerManagerConstants', {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  STATUS_URL: '/jobstatus',
  JOB_COMPLETION: 'job_completion',
  JOB_STATUS_LOADING: 'loading',
  JOB_STATUS_SUCCESS: 'success',
  JOB_STATUS_ERROR: 'error',
});
