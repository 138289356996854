import { LegendSettings } from '@/modules/core/charts/am5/base/models/LegendSettings';

/**
 * @class Bubble
 */
export class Bubble {
  /**
   * Visible name for the given Bubble
   * @var {String}
   */
  name;

  /**
   * @var {string} Type of data (Integer, Percent, DataTime, etc)
   */
  dataItemFormat;

  /**
   * Key holding categories name in data
   * @var {String}
   */
  category;

  /**
   * Key holding categories valueX in data
   * @var {String}
   */
  valueX;

  /**
   * Key holding categories valueY in data
   * @var {String}
   */
  valueY;

  /**
   * Key holding value of bubble in data
   * @var {String}
   */
  value;

  /**
   * if tooltip is disabled
   * @var {Boolean}
   * @default false
   */
  tooltipDisabled;

  /**
   * Text/Template to be used for label text
   * @var {String}
   */
  labelText;

  /**
   * Field from datum to use to fill color for series
   * @var {String}
   */
  fill;

  /**
   * Shape of all bubbles
   * @var {string}
   */
  shape;

  /**
   * Control opacity of background color
   * @var {String}
   */
  fillOpacity;

  /**
   * Settings related to legend
   * @var {LegendSettings}
   */
  legendSettings;

  /**
   * @var {integer}
   */
  maxBubbles;

  /**
   * @var {boolean}
   */
  showValuesOnShapes;

  /**
   * @var {boolean}
   */
  showShapeShadow;

  /**
   * @var {integer}
   */
  shapeSize;

  constructor(model = {}) {
    this.name = model.name;
    this.dataItemFormat = model.dataItemFormat;
    this.category = model.category;
    this.value = model.value;
    this.valueX = model.valueX;
    this.valueY = model.valueY;
    this.tooltipDisabled = model.tooltipDisabled ?? false;
    this.labelText = model.labelText;
    this.fill = model.fill;
    this.shape = model.shape;
    this.fillOpacity = model.fillOpacity;
    this.legendSettings = new LegendSettings(model.legendSettings);
    this.maxBubbles = model.maxBubbles;
    this.showValuesOnShapes = model.showValuesOnShapes;
    this.showShapeShadow = model.showShapeShadow;
    this.shapeSize = model.shapeSize;
  }
}
