import { DrilldownStoreModel } from '@/modules/ta/widget/models/DrilldownStoreModel';
import { shallowCopyKeyedObject } from '@/modules/core/helper';
import { DrillDownConfig } from '@/modules/ta/widget/models/DrillDownConfig';

export const mutations = {
  setDrillDownState(widgetState, { id, demoMode }) {
    widgetState.dashboardId = id;
    widgetState.demoMode = demoMode;
  },
  removeWidget(widgetState, id) {
    delete widgetState.widgets[id];
    widgetState.widgets = shallowCopyKeyedObject(widgetState.widgets);
  },
  setWidget(widgetState, { widget, dateRange }) {
    widget = JSON.parse(JSON.stringify(widget));
    widgetState.widgets[widget.id] = new DrilldownStoreModel(widget);
    widgetState.dateRanges[widget.id] = dateRange;
    widgetState.widgets = shallowCopyKeyedObject(widgetState.widgets);
    widgetState.dateRanges = shallowCopyKeyedObject(widgetState.dateRanges);
  },
  updateWidgetConfig(widgetState, { id, category, title, isSubSlice }) {
    const widget = widgetState.widgets[id];
    const isDrilldown = isSubSlice === true || isSubSlice === undefined;
    if (isDrilldown) {
      widget.currentGroupByIndex += 1;
    }
    if (widget.drilldownConfigStack.length === 0) {
      widget.drilldownConfigStack.push(
        new DrillDownConfig({
          title,
          queryParams: category,
          groupByIndex: widget.currentGroupByIndex,
          isSubSlice,
        })
      );
    } else {
      let params = {};
      widget.drilldownConfigStack.forEach((config) => {
        if (isDrilldown) {
          params = { ...params, ...config.queryParams };
        }
      });
      widget.drilldownConfigStack.push(
        new DrillDownConfig({
          queryParams: { ...params, ...category },
          groupByIndex: widget.currentGroupByIndex,
          title,
          isSubSlice,
        })
      );
    }

    widgetState.widgets[id] = widget;
    widgetState.widgets = shallowCopyKeyedObject(widgetState.widgets);
  },
  undoDrilldown(widgetState, id) {
    const widget = widgetState.widgets[id];
    if (widget.currentGroupByIndex > 0) widget.currentGroupByIndex -= 1;
    widget.drilldownConfigStack.pop();
    widgetState.widgets[id] = widget;
    widgetState.widgets = shallowCopyKeyedObject(widgetState.widgets);
  },
};
