'use strict';
import angular from 'angular';
import './scripts/reportbuilder.ctrls';
import './scripts/reportbuilder.directives';
import './scripts/reportbuilder.services';

import layoutHtmlUrl from './views/layout.html';
import modalsHtmlUrl from './views/modals.html';
import exportMenuHtmlUrl from './views/exportmenu.html'

angular.module('reportbuilder', [
    'reportbuilder.ctrls',
    'reportbuilder.directives',
    'reportbuilder.services'
])

    .config(['$stateProvider', 'AppConfig', 'SwitchAppServiceProvider', function ($stateProvider, AppConfig, SwitchAppServiceProvider) {

        $stateProvider
            .state('generatereport',{
                url: '/generatereport',
                views: {
                    '': {
                        templateUrl: layoutHtmlUrl,
                        controller: 'ReportBuilderController',
                        resolve: {
                            isTARoute: SwitchAppServiceProvider.switchAppFn('generatereport'),
                            _userSession: function (LoginFactory, AppFactory) {
                                return LoginFactory.initUser().then(function (json) {
                                    AppFactory.setUser(json.data.settings);
                                });
                            }
                        }
                    },
                    '@generatereport': {
                        templateUrl: AppConfig.MAGE_URL + 'reporting/report_builder'
                    },
                    'globals@generatereport': {
                        templateUrl: AppConfig.MAGE_URL + 'layout/globals'
                    },
                    'reportmodals@generatereport': {
                        templateUrl: modalsHtmlUrl
                    },
                    'exportmenu@generatereport': {
                        templateUrl: exportMenuHtmlUrl
                    }
                }
            })
    }]);