import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { BaseResource } from '@/modules/core/app/resources/BaseResource';

export class BadgeResource extends BaseResource {
  constructor() {
    super(RouteGroup.BADGES);
  }

  /**
   * @param key
   * @returns {Promise<*>}
   */
  async upsell(key) {
    return this.postData(null, `upsell/${key}`);
  }

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getSentRequestsStatuses() {
    return this.apiHelper.get(this._buildRoute('upsell/sentstatuses'));
  }
}

export default new BadgeResource();
