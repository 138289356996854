(function() {
	"use strict";

	// Note: should not prototype Object class
	Object.size = function(obj) {
		var size = 0, key;
		for (key in obj) {
			if (obj.hasOwnProperty(key)) size++;
		}
		return size;
	};

	// Prevents from string.bool() from failing if variable is a bool already
    Boolean.prototype.bool = function() {
        return this;
    };

	Array.prototype.contains = function(obj) {
		var i = this.length;
		while (i--) {
			if (this[i] === obj) {
				return true;
			}
		}
		return false;
	};

	// Check if array has object based on a property and value
	Array.prototype.containsObj = function(property, value) {
		return this.filter(function (item) {
			return (item[property] == value);
		}).length > 0;
	};

	Array.prototype.remove = function(removeItem) {
		return jQuery.grep(this, function(value) {
			return value != removeItem;
		});
	};

	Array.prototype.removeObject = function(property, removeItem) {
		return jQuery.grep(this, function(obj) {
			return obj[property] != removeItem;
		});
	};

	Array.prototype.equals = function (array) {
		return (this.join('') === array.join(''));
	};

	Array.prototype.hasElementOver = function(limit) {
		for(var k = 0; k < this.length; k++){
			if (this[k] > limit)
				return true;
		}
		return false;
	};

	// Move element at index to target index
	Array.prototype.swap = function(old_index, new_index) {
		if (new_index >= this.length) {
			var k = new_index - this.length;
			while ((k--) + 1) {
				this.push(undefined);
			}
		}
		this.splice(new_index, 0, this.splice(old_index, 1)[0]);
		return this;
	};

	// Convert key => value array to object
	Array.prototype.objectify = function() {
		return this.reduce(function(p, c) {
			p[c['key']] = c['value'];
			return p;
		}, {});
	};

	// convert 0..255 R,G,B values to a hexidecimal color string
	Array.prototype.rgbToHex = function() {
		var r = this[0], g = this[1], b = this[2];
		var bin = r << 16 | g << 8 | b;
		return '#' + (function(h){
			return new Array(7-h.length).join("0")+h
		})(bin.toString(16).toUpperCase())
	};

    /**
	 * Removes elements from the array
	 * Returns same reference
     * @returns {Array}
     */
	Array.prototype.clear = function() {
		this.length = 0;
		return this;
	};

	// If number is 1 return true, else false
	Number.prototype.bool = function() {
		return this == 1;
	};

	Number.prototype.reduceNumber = function(numDigits, decimals, format, row, currency_discrepancy, show_currency) {
		var ranges = [
			{ divider: 1e18 , suffix: 'P' },
			{ divider: 1e15 , suffix: 'E' },
			{ divider: 1e12 , suffix: 'T' },
			{ divider: 1e9 , suffix: 'B' },
			{ divider: 1e6 , suffix: 'M' },
			{ divider: 1e3 , suffix: 'K' }
		];

		for (var i = 0; i < ranges.length; i++) {
			if (this >= ranges[i].divider) {
				return format ? $.fn.formatNumber(this / ranges[i].divider, format, numDigits == null ? 2 : numDigits, false, row, currency_discrepancy, show_currency) + ranges[i].suffix : (this / ranges[i].divider).toFixed(numDigits == null ? 2 : numDigits).toString() + ranges[i].suffix;
			}
		}
		return format ? $.fn.formatNumber(this, format, decimals == null ? 0 : decimals, false, row, currency_discrepancy, show_currency) : this.toFixed(decimals == null ? 0 : decimals);
	};

	Number.prototype.numberOfDecimals = function() {
		var match = (''+this).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
		if (!match) { return 0; }
		return Math.max(
		0,
		// Number of digits right of decimal point.
		(match[1] ? match[1].length : 0)
			// Adjust for scientific notation.
		- (match[2] ? +match[2] : 0));
	};

    Number.prototype.formatPhoneNumber = function() {
        //normalize string and remove all unnecessary characters
        var s = this.toString().replace(/[^\d]/g, "");

        //check if number length equals to 10
        if (s.length == 10) {
            //reformat and return phone number
            return s.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }

        return null;
    };

	// Old browsers does not support this function
	if (!String.prototype.endsWith) {
		String.prototype.endsWith = function(searchString, position) {
			var subjectString = this.toString();
			if (position === undefined || position > subjectString.length) {
				position = subjectString.length;
			}
			position -= searchString.length;
			var lastIndex = subjectString.indexOf(searchString, position);
			return lastIndex !== -1 && lastIndex === position;
		};
	}

	String.prototype.URLToAudioTag=function(){
		if(this) {
			var audio = "<audio controls preload='none'> \
                            <source src='" + this + "' type='audio/mpeg'> \
								Your browser does not support the audio element.\
								</audio>";
			return audio;
		}
		return "";
	};


	String.prototype.isInt = function() {
		return /^\d+$/.test(this);
	};

	String.prototype.isFloat = function() {
		return (!isNaN(this) && this.toString().indexOf('.') != -1)
	};

	String.prototype.isPercentage = function() {
		var rawValue = this.replace('%', '');
		return rawValue != this && (rawValue.isInt() || rawValue.isFloat());
	};

	String.prototype.isCurrency = function() {
		var rawValue = this.replace(($.globals.utils.clientCurrencySymbol || $.globals.utils.currencySymbol), '');
		return rawValue != this && (rawValue.isInt() || rawValue.isFloat());
	};

	String.prototype.isDate = function() {
		return Date.parse(this) != null;
	};

	String.prototype.isTime = function() {
		return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(this);
	};

	String.prototype.isUrl = function() {
		return /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(this);
	};

	String.prototype.bool = function() {
		return (this + '').toLowerCase() === 'true' || this == '1';
	};

	String.prototype.capitalize = function() {
		return this.toLowerCase().replace( /\b\w/g, function (m) {
			return m.toUpperCase();
		});
	};

	String.prototype.normalize = function() {
		return this.toLowerCase().replaceAll('-', ' ').replaceAll('_', ' ').replaceAll('.', ' ').replace(')', '').replace('(', '');
	};

	String.prototype.classify = function() {
		return this.toLowerCase().replace(/ /g, '-').replaceAll('.', '').replace(')', '').replace('(', '');
	};

	String.prototype.contains = function(str) {
		return this.indexOf(str) > -1;
	};

	String.prototype.replaceAll = function(find, replace) {
		function escapeRegExp(string) {
			return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
		}
		return this.replace(new RegExp(escapeRegExp(find), 'g'), replace);
	};

	String.prototype.addhttp = function() {
		if (!this.contains('http://') && !this.contains('https://') && !this.contains('ftp://')) {
			return "http://" + this;
		}
		return this;
	};

	String.prototype.prependSlash = function() {
		return this.charAt(0) == '/' ? this : '/' + this;
	};

	String.prototype.escapeHtml = function() {
		return this
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");
	};

	String.prototype.isJSON = function() {
		try {
			JSON.parse(this);
		} catch (e) {
			return false;
		}
		return true;
	};

	// Pluralize any english given word
	String.prototype.pluralize = function() {
		switch(this.slice(-1)) {
			case 'y':
				return this.slice(0,this.length-1) + 'ies';
				break;
			case 's':
				return this + 'es';
				break;
			default:
				return this + 's';
		}
	};

	// Convert hex string to rgb value
	String.prototype.hexToRgb = function() {
		var value = this;
		if (value.contains('#')) {
			value = value.replace('#', '');
		}

		var bigint = parseInt(value, 16);
		var r = (bigint >> 16) & 255;
		var g = (bigint >> 8) & 255;
		var b = bigint & 255;

		return r + "," + g + "," + b;
	};

})();