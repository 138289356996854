import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { User } from '@/modules/ta/user/models/User';
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';
import { GenericModel } from '@/modules/core/app/models/GenericModel';
import { UserForm } from '@/modules/ta/user/models/UserForm';
import { UserAvatar } from '@/modules/ta/user/models/UserAvatar';
import { Module } from '@/modules/core/app/constants/app.constants';
import { UserListSettings } from '@/modules/ta/user/models/UserListSettings';

export class UserResource extends BaseEntityResource {
  itemName = 'users';

  constructor() {
    super(RouteGroup.USERS, User);
  }

  /**
   * @param id
   * @param params
   * @param config
   * @returns {Promise<UserForm>}
   */
  async getForm(id, params = {}, config = {}) {
    const [data, columns] = await super.getForm(id, params, config);
    return new UserForm({ data, columns });
  }

  /**
   * @param id
   * @returns {Promise<AxiosPromise<any>>}
   */
  async terminateUser(id) {
    return apiAxios.get(`${this.routeGroup}/terminate/${id}`);
  }

  /**
   * @returns {Promise<AxiosPromise<any>>}
   */
  async getImpersonationUserList(params) {
    return super.getData(User, 'impersonation', params);
  }

  async getReportLanguageList() {
    return super.getValues({ fields: 'report_language' });
  }

  async setCurrentUserReportLanguage(params) {
    // YP Canada instance requires Browser location to perform custom behaviour.
    return apiAxios.post(`${this.routeGroup}/language`, params, {
      headers: {
        'Browser-Location': window.location.href,
      },
    });
  }

  async saveEditSettings(form) {
    return apiAxios.post(`${this.routeGroup}/editsettings`, form);
  }

  async saveLegacyAppPreference(form) {
    return apiAxios.post(`${this.routeGroup}/updateapppreference`, form);
  }

  async setShowWelcome(userId, value) {
    return super.getData(GenericModel, `${userId}/updateshowwelcomemodal/${value}`);
  }

  async getAvatars() {
    return super.getData(UserAvatar, 'avatars');
  }

  /* saves selected column preferences
   * @param data
   * @returns {Promise<*>}
   */
  async saveColumnPreferences(data) {
    return apiAxios.post(`/savecolumnpreferences/${Module.USER}`, data);
  }

  async getUserListSettings(userId) {
    return super.getData(UserListSettings, `${userId}/listsettings`);
  }

  async setUserListSettings(params) {
    return apiAxios.post(`${this.routeGroup}/listsettings`, params);
  }

  /**
   *
   * @param params
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  async getUserRevokeGranularPermissionsMessage(params) {
    // eslint-disable-next-line tap/no-raw-text-js
    return apiAxios.get('/granularpermissions/recipientpermissionsmessage', { params });
  }

  /**
   *
   * @param module_name
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  async getUsersWithPermissionToWrite(module_name) {
    const params = new URLSearchParams();
    params.append('module_name', module_name);

    // eslint-disable-next-line tap/no-raw-text-js
    return apiAxios.get(`${this.routeGroup}/userswithpermissiontowrite?${params.toString()}`);
  }
}

export default new UserResource();
