'use strict';
import angular from 'angular';
import Isotope from 'isotope-layout/dist/isotope.pkgd';

angular.module('design.isotope.constants', [])
    .constant('$isoIsotope', Isotope)
    .constant('$isoOptions', $OptionsConstant())
    .constant('$isoEvents', $EventsConstant());

function $OptionsConstant() {
    // Default options
    return {
        gutter: 0,
        rowHeight: 80,
        isAppended: true,
        masonry: {
            columnWidth: '.widget-grid-sizer'
        },
        itemSelector: '.widget-grid-item',
        percentPosition: true,
        getSortData: {
            displayOrder: '[data-display-order] parseInt'
        },
        sortBy: ['displayOrder'],
        transitionDuration: '0.1s'
    };
}

function $EventsConstant() {
    return {
        DESTROY: '$isoDestroy',
        DESTROYED: '$isoDestroyed',
        INIT: '$isoInit',
        LAYOUT: '$isoLayout',
        FILTER: '$isoFilter',
        SEARCH: '$isoSearch',
        RESIZE: '$isoItemResize',
        INITIALIZED: '$isoInitialized',
        ITEM: {
            ADDED: '$isoItemAdded',
            DESTROYED: '$isoItemDestroyed'
        },
        LAYOUT_COMPLETED: '$isoLayoutComplete',
        ISOTOPE: {
            LAYOUT_COMPLETED: 'layoutComplete',
            REMOVED: 'removeComplete'
        },
        REMOVED: '$isoRemoveComplete'
    };
}