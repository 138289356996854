'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('widget.create.ctrls')

    .controller('WidgetTypeSelectionListController', WidgetTypeSelectionListController)
    .controller('WidgetTypeSelectionItemController', WidgetTypeSelectionItemController);

/**
 * Widget type list controller
 * @ngInject
 */
function WidgetTypeSelectionListController(
    $scope,
    $timeout,
    $WidgetColumnEvents,
    DesignFactory,
    DrawOption,
    DrawOptionPanelFactory,
    PubSub,
    WidgetFactory,
    WidgetCreateFactory,
    WidgetCreateTypeFactory,
    WidgetColumnFactory,
    DashboardContextService
) {
    WidgetCreateTypeFactory.$registerScope($scope);

    /**
     * Change widget type
     * @param widgetType
     */
    $scope.changeWidgetType = function(widgetType) {

        if ($scope.type !== widgetType.id) {

            var widget = WidgetCreateFactory.getCurrentWidget();
            widget.type = $scope.type;
            // Update the widget state based on widget type

            // Store show sample data to persist it after reset
            var showSampleData = $scope.drawOptions[DrawOption.SHOW_SAMPLE_DATA];

            // Reset the draw options when changing widget types
            // A deep copy of current layout draw options is needed,
            // since we do not want to point to the same object, or properties
            $scope.drawOptions = angular.copy(DashboardContextService.resolveWidgetMetadataDrawOptions());

            // Persist show sample data state
            $scope.drawOptions[DrawOption.SHOW_SAMPLE_DATA] = showSampleData;

            DrawOptionPanelFactory.closePanel();

            // in order to correctly initialize widget type, setSelectColumnStates
            // must be set before setting the widget type state
            WidgetColumnFactory.$setSelectColumnStates(widgetType);

            $timeout(function() {
                // Set create widget type for model
                $scope.type = widgetType.id;

                WidgetFactory.setStateForWidgetType($scope.state, $scope.type);

                // Reset drill down
                $scope.state.isDrilling = false;
                // Reset help needed state
                $scope.state.helpNeeded = false;

                if ($scope.state.isAdding) {
                    WidgetCreateFactory.$updateWidgetDimensions();
                }

                PubSub.emit($WidgetColumnEvents.TOGGLE_HANDLE_DRILL_DOWN_COLUMN);
            })
        }
    };
}

/**
 * @ngInject
 */
function WidgetTypeSelectionItemController(
    $injector,
    $timeout,
    $scope,
    DataSourceFactory,
    WidgetType,
    $WidgetTypeEvents
) {
    // (NOTE: is_of_type_service is true for both services and categories)
    var isServiceDataSourceType = $scope.datasource.is_of_type_service;

    // Inject data view logic if dealing with service data source
    var DataViewName = isServiceDataSourceType ? $injector.get('DataViewName') : null;

    function toggleState() {
        var widgetType = $scope.widgetType;
        var state = $scope.state;

        // Default
        widgetType.is_disabled = false;
        widgetType.is_hidden = false;

        // Disable BIGNUMBER if group by column selected
        if (!widgetType.can_group_by) {
            widgetType.is_disabled = state.groupByColumnSelected;
        }
        // Disable CHART WIDGETS if NO select column is selected OR if columns is selected but no group by
        if (widgetType.can_group_by && widgetType.requires_group_by) {
            widgetType.is_disabled = !state.numSelectedColumns || (!state.groupByColumnSelected && state.numSelectedColumns);
        }
        // Disable DATAGRID, if group by is required, when NO group by column selected
        if (widgetType.id === WidgetType.DATAGRID) {
            if ($scope.datasource.requires_group_by) {
                widgetType.is_disabled = !state.groupByColumnSelected;
            }
        }
        // Remove GEOCHART, if no geo data is available or data source is not service_data
        if (widgetType.id === WidgetType.GEOCHART) {
            if (!_.isNull(DataViewName) && !$scope.datasource.data_view_is_geo
                || !DataSourceFactory.dataSourceContainsServices($scope.datasource.type)) {
                widgetType.is_hidden = true;
            }
        }
        // Only DATAGRID can group by more than 2 columns
        if (widgetType.id !== WidgetType.DATAGRID && state.groupByColumnSelected > 2) {
            widgetType.is_disabled = true;
        }

        // Disable GAUGECHART if less than two columns are selected or at least one grouped column is selected
        if (widgetType.id === WidgetType.GAUGECHART) {
            widgetType.is_disabled = state.numSelectedColumns < 2 || state.groupByColumnSelected > 0;
        }

        $timeout(function () {
            $scope.setTooltip();
        }, 200, false);
    }

    toggleState();

    $scope.$on($WidgetTypeEvents.TOGGLE_TYPE_STATE, function() {
        toggleState();
    });
}
