'use strict';
import angular from 'angular';
import './dashboard/undo/exportbuilder.undo.constants';

export const $ExportBuilderDashboardEvents = {
    ON_ITEM_ADDED: '$ExportBuilderDashboardEvents:ON_ITEM_ADDED',
    ON_ITEM_DELETED: '$ExportBuilderDashboardEvents:ON_ITEM_DELETED',
    ON_ITEM_UPDATED: '$ExportBuilderDashboardEvents:ON_ITEM_UPDATED',
    ON_CURRENT_ITEM_CHANGE: '$ExportBuilderDashboardEvents:ON_CURRENT_ITEM_CHANGE',
    ON_ITEM_FOCUS: '$ExportBuilderDashboardEvents:ON_ITEM_FOCUS',
    ON_ITEM_SNAP_TO_GRID_CHANGE: '$ExportBuilderDashboardEvents:ON_ITEM_SNAP_TO_GRID_CHANGE',
    REFRESH_ITEM_NEEDED: '$ExportBuilderDashboardEvents:REFRESH_ITEM_NEEDED',
    UPDATE_WIDGET_BUILDER_ACTIONS: '$ExportBuilderDashboardEvents:UPDATE_WIDGET_BUILDER_ACTIONS',

    SELECTED_ENTITY_CHANGED: '$ExportBuilderDashboardEvents:SELECTED_ENTITY_CHANGED',
    ON_ASPECT_RATIO_CHANGE: '$ExportBuilderDashboardEvents.ON_ASPECT_RATIO_CHANGE',
    ON_PAGE_ROTATE: '$ExportBuilderDashboardEvents:ON_PAGE_ROTATE',
    ON_PAGE_ADDING: '$ExportBuilderDashboardEvents:ON_PAGE_ADDING',
    ON_PAGE_ADDED: '$ExportBuilderDashboardEvents:ON_PAGE_ADDED',
    ON_PAGE_DELETED: '$ExportBuilderDashboardEvents:ON_PAGE_DELETED',
    ON_PAGE_CHANGED: '$ExportBuilderDashboardEvents:ON_PAGE_CHANGED',

    RIGHT_CLICK_MENU: '$ExportBuilderDashboardEvents:RIGHT_CLICK_MENU',
    CLOSE_RIGHT_CLICK_MENU: '$ExportBuilderDashboardEvents:CLOSE_RIGHT_CLICK_MENU',

    CREATE_PAGE: '$ExportBuilderDashboardEvents:CREATE_PAGE',
    COPY_PAGE: '$ExportBuilderDashboardEvents:COPY_PAGE',
    DELETE_PAGE: '$ExportBuilderDashboardEvents:DELETE_PAGE',
    MAKE_COVER_PAGE: '$ExportBuilderDashboardEvents:MAKE_COVER_PAGE',
    MAKE_BACK_PAGE: '$ExportBuilderDashboardEvents:MARK_BACK_PAGE',

    IS_GRID_MODE: '$ExportBuilderDashboardEvents:IS_GRID_MODE',
    ZOOM_CALCULATED: '$ExportBuilderDashboardEvents:ZOOM_CALCULATED',
    RESET_FORMAT_DROPDOWN: '$ExportBuilderDashboardEvents:RESET_FORMAT_DROPDOWN',

    IS_DOWNLOADING: '$ExportBuilderDashboardEvents:IS_DOWNLOADING',

    SET_LOADING_STATE: '$ExportBuilderDashboardEvents:SET_LOADING_STATE',

    ON_PRESENTATION_MODE: '$ExportBuilderDashboardEvents:ON_PRESENTATION_MODE',

    CHART_PALETTE: '$ExportBuilderDashboardEvents:CHART_PALETTE',
    REPORT_DRAW_OPTIONS: '$ExportBuilderDashboardEvents:REPORT_DRAW_OPTIONS',

    ON_NEW_GLOBAL_FILTER_ADDED: '$ExportBuilderDashboardEvents:ON_NEW_GLOBAL_FILTER_ADDED',
    ON_GLOBAL_FILTER_REMOVED: '$ExportBuilderDashboardEvents:ON_GLOBAL_FILTER_REMOVED',
    ON_GLOBAL_FILTER_APPLY: '$ExportBuilderDashboardEvents:ON_GLOBAL_FILTER_APPLY',
    UPDATE_GLOBAL_FILTER_OPTIONS: '$ExportBuilderDashboardEvents:UPDATE_GLOBAL_FILTER_OPTIONS',
    ON_REMOVE_ALL_FILTERS: '$ExportBuilderDashboardEvents:ON_REMOVE_ALL_FILTERS',

    WIDGET_ELEMENT_SPECIAL_EDIT_NEEDS_UPDATE: '$ExportBuilderDashboardEvents:WIDGET_ELEMENT_SPECIAL_EDIT_NEEDS_UPDATE',
    ON_SPECIAL_EDIT_MODE: '$ExportBuilderDashboardEvents:ON_SPECIAL_EDIT_MODE',
    ON_PAGE_ASSIGNMENT: '$ExportBuilderDashboardEvents:ON_PAGE_ASSIGNMENT',
    SET_PAGE_PANEL_LOADER_TEXT: '$ExportBuilderDashboardEvents:SET_PAGE_PANEL_LOADER_TEXT',

    SET_ENABLE_EXECUTIVE_SUMMARY: '$ExportBuilderDashboardEvents:SET_ENABLE_EXECUTIVE_SUMMARY'

};

export const ExportBuilderNewReportContext = {
    DASHBOARD: 'ExportBuilderNewReportContext:DASHBOARD',
    REPORTSTUDIO: 'ExportBuilderNewReportContext:REPORTSTUDIO',
    REPORTSTUDIO_AGENT: 'ExportBuilderNewReportContext:REPORTSTUDIO_AGENT'
};

export const ExportBuilderAdminNewReportModalConstants = {
    MODAL_ID: 'new-report-modal'
};

export const $ExportBuilderAdminNewReportModalEvents = {
    INIT_MODAL: '$ExportBuilderAdminNewReportModalEvents:INIT_MODAL',
    CLOSE_MODAL: '$ExportBuilderAdminNewReportModalEvents:CLOSE_MODAL',
    DID_SELECT_PREDEFINED_TEMPLATE: '$ExportBuilderAdminNewReportModalEvents:DID_SELECT_PREDEFINED_TEMPLATE',
};

export const ExportBuilderRouterState = {
    EXPORT_BUILDER_ADMIN: 'exportbuilder',
    EXPORT_BUILDER_STUDIO: 'exportbuilder-builder'
};

export const $ExportBuilderDashboardUIEvents = {
    ON_BACK_PRESSED: '$ExportBuilderDashboardUIEvents:ON_BACK_PRESSED',
};

export const $ExportBuilderDashboardModelEvents = {
    ON_NEW_ITEM_CREATED: '$ExportBuilderDashboardModelEvents:ON_NEW_ITEM_CREATED',
    ON_COORDINATE_CHANGE: '$ExportBuilderDashboardModelEvents:ON_COORDINATE_CHANGE',
    DID_SET_CLIENT: '$ExportBuilderDashboardModelEvents:DID_SET_CLIENT'
};

export const PageSpecifications = {
    WIDTH: 1360, //arbitrary size
    INNER_MARGINS: 1, //% of the height or width
    BOTTOM_MARGIN: 40 //margin between each page, in px
};

export const PageAspectRatio = {
    STANDARD: 110/85, // Letter Page Ratio
    WIDESCREEN: 177/100
}

export const PageFormatConstants = {
    STANDARD: 'standard', // Letter Page Ratio
    WIDESCREEN: 'widescreen',
};

export const PageFormatDropdownConstants = {
    STANDARD: '4:3', // Letter Page Ratio
    WIDESCREEN: '16:9',
}


export const ReportTemplateType = {
    TYPE_CUSTOM: 'custom',
    TYPE_DATA: 'data',
    TYPE_REPORT: 'report',
    TYPE_DESIGN: 'design',
};

export const PageOrientation = {
    TYPE_LANDSCAPE:'landscape',
    TYPE_PORTRAIT: 'portrait'
};

export const PageGridConstants = {
    RATIO: 85/110, // not used anywhere
    X: 60,
    Y: 48,
    MINIMUM_PIXEL_HEIGHT: 20,
    MINIMUM_PIXEL_WIDTH: 20
};

export const ExportBuilderPanelConstants = {
    RATIO: 85/110, // not used anywhere
    X: 60,
    Y: 48,
    MINIMUM_PIXEL_HEIGHT: 20,
    MINIMUM_PIXEL_WIDTH: 20
};

export const ExportType = {
    TYPE_EXPORT_PNG: 'export_png',
    TYPE_EXPORT_WIDGET_PNG: 'export_widget_png',
    TYPE_EXPORT_PDF: 'export_pdf',
    TYPE_EXPORT_PPT: 'export_ppt',
    TYPE_SCHEDULED_REPORT_PDF: 'scheduled_report_pdf'
};

export const ExportBuilderDashboardConstants = {
    DELAY_REPORT: 1000
};

export const ZIndexProps = {
    BASE: 1, //lowest z-index
    STEP: 1 //step between each z-index
};

export const ExportBuilderPrefixes = {
    WIDGETID: 'widgetID-',
    ITEMID: 'itemID-'
};

export const ReportElementTypes = {
    WIDGET: 'item-type-widget',
    IMAGE: 'item-type-image',
    SHAPE: 'item-type-shape',
    ICON: 'item-type-icon',
    TEXT: 'item-type-text',
    COLOR: 'item-type-color',
    MACRO: 'item-type-macro',
};

export const ReportElementMacroTypes = {
    DATE_RANGE: 'macro-date-range',
    COMPANY_LOGO: 'macro-company-logo',
    RECIPIENT_NAME: 'macro-recipient-name',
    PAGE_TITLE: 'macro-page-title',
    PAGE_NUMBER: 'macro-page-number',
    CREATED_ON: 'macro-created-on',
};

export const PageLayerPanel = {
    WIDTH: 250
};

export const ReportElementShapeTypes = {
    SQUARE: 'shape-square',
    CIRCLE: 'shape-circle'
};

export const BuilderPanel = {
    WIDTH: 280,
    ROW_HEIGHT: 30,
    STATIC_PANE_HEIGHT: 320,
    STATIC_PANE_HEADER_HEIGHT: 26
};

export const ReportElementBaseOptions = {
    MIRROR_X: 'mirror_x',
    MIRROR_Y: 'mirror_y',
    OPACITY: 'opacity',
    BACKGROUND_COLOR: 'background_color',
    BORDER_RADIUS: 'border_radius',
    SHADOW_COLOR: 'shadow_color',
    SHADOW_BLUR: 'shadow_blur',
    SHADOW_X: 'shadow_x',
    SHADOW_Y: 'shadow_y',
    BORDER_COLOR: 'border_color',
    BORDER_WIDTH: 'border_width',
    BORDER_TYPE: 'border_type',
    BORDER_OPACITY: 'border_opacity'
};

export const ReportElementDesignOptions = {
    SHOW_ON_EVER_PAGE: 'show_on_every_page'
};

export const ReportElementIconDesignOptions = {
    ICON_COLOR: 'icon_color',
};

export const ReportElementShapeDesignOptions = {
    BORDER_RADIUS: 'border_radius',
    BACKGROUND_COLOR: 'background_color'
};

export const ReportElementImageDesignOptions = {
    FILL_MODE: 'fill_mode'
};

export const ReportElementImageSupportedFillModes = {
    ASPECT_FIT: 'aspect_fit',
    ASPECT_FILL: 'aspect_fill',
    STRETCH: 'stretch'
};

export const ReportPageDesignOptions = {
    BACKGROUND_COLOR: 'background_color',
    OPACITY: 'opacity'
};

export const ReportElementTextDesignOptions = {
    TEXT_TITLE: 'text',
    TEXT_ALIGNMENT: 'text_align',
    VERTICAL_ALIGNMENT: 'vertical_alignment',
    FONT_SIZE: 'font_size',
    FONT_FAMILY: 'font_family',
    FONT_STYLE: 'font_style',
    FONT_WEIGHT : 'font_weight',
    TEXT_PADDING: 'text_padding',
    TEXT_COLOR: 'text_color',
    TEXT_BOLD: 'text_bold',
    TEXT_ITALIC: 'text_italic',
    TEXT_UNDERLINE: 'text_underline',
    TEXT_STRIKETHROUGH: 'text_strikethrough',
    TEXT_OPACITY: 'text_opacity',
    TEXT_SHADOW_COLOR: 'text_shadow_color',
    TEXT_SHADOW_BLUR: 'text_shadow_blur',
    TEXT_SHADOW_X: 'text_shadow_x',
    TEXT_SHADOW_Y: 'text_shadow_y',
    TEXT_EMPTY_PLACEHOLDER: 'Title'
};

export const ReportElementDesignOptionConstants = {
    TEXT_ALIGNMENT: {
        LEFT: 'left',
        RIGHT: 'right',
        CENTER: 'center',
        JUSTIFY: 'justify'
    },
    VERTICAL_ALIGNMENT: {
        TOP: 'flex-start',
        CENTER: 'center',
        BOTTOM: 'flex-end',
    },
    BORDER_TYPE: {
        SOLID: 'solid',
        DASHED: 'dashed',
        DOTTED: 'dotted',
        DOUBLE: 'double',
        GROOVE: 'groove',
        RIDGE: 'ridge',
        INSET: 'inset',
        OUTSET: 'outset',
    }
};

export const MenuType = {
    PAGE: 'page',
    PAGE_PREVIEW: 'page_preview',
    ELEMENT: 'element'
};

export const $ExportBuilderIconModalEvents = {
    OPEN_NEW: '$ExportBuilderIconModalEvents:OPEN_NEW',
    OPEN_EDIT: '$ExportBuilderIconModalEvents:OPEN_EDIT',
    SET_NEW_ICON: '$ExportBuilderIconModalEvents:SET_NEW_ICON',
    SET_ICON: '$ExportBuilderIconModalEvents:SET_ICON'
};

export const $ExportBuilderShortcutsModalEvents = {
    OPEN: 'ExportBuilderShortcutsModalEvents:OPEN'
};

export const $ExportBuilderFormatChangeModalEvents = {
    OPEN: 'ExportBuilderFormatChangeModalEvents:OPEN',
    SET_VALUE: '$ExportBuilderFormatChangeModalEvents:SET_VALUE'
}

export const $ExportBuilderQuickExportModalEvents = {
    OPEN: '$ExportBuilderQuickExportModalEvents:OPEN'
};

export const $ExportBuilderTemplateDownloadModalEvents = {
    OPEN: '$ExportBuilderTemplateDownloadModalEvents:OPEN'
};

export const $ExportBuilderTemplatePublishModalEvents = {
    OPEN: '$ExportBuilderTemplatePublishModalEvents:OPEN'
};

export const ExportBuilderPageAssignmentType = {
    DATA_CATEGORY: 'data_category',
    PRODUCT: 'product'
}

export const $ExportBuilderPageAssignmentModalEvents = {
    OPEN: '$ExportBuilderPageAssignmentModalEvents:OPEN'
};

export const FilterModeType = {
    GLOBAL_FILTERS: 'global_filters',
    WIDGET_FILTERS: 'widget_filters'
}

export const HelloSignClientIds = {
    PRODUCTION: 'cb0264b2e254be08abae89334d51dad0',
    STAGING: '1271d9721b08e9555d4e88983d7382f8',
    DEVELOPMENT: '52122ec4b9ec79b4f1d5a9ffdca212cf'
};

export const ReportEmptyExecutiveSummary = {
    EMPTY_MESSAGE: "No Executive Summaries to show in the selected date range",
}

angular.module('exportbuilder.constants', [
    'exportbuilder.undo.constants'
])
    .constant('$ExportBuilderAdminNewReportModalEvents', $ExportBuilderAdminNewReportModalEvents)
    .constant('ExportBuilderAdminNewReportModalConstants', ExportBuilderAdminNewReportModalConstants)
    .constant('ExportBuilderRouterState', ExportBuilderRouterState)
    .constant('ExportBuilderNewReportContext', ExportBuilderNewReportContext)
    .constant('$ExportBuilderDashboardUIEvents', $ExportBuilderDashboardUIEvents)
    .constant('$ExportBuilderDashboardModelEvents', $ExportBuilderDashboardModelEvents)
    .constant('$ExportBuilderDashboardEvents', $ExportBuilderDashboardEvents)
    .constant('$ExportBuilderPageAssignmentModalEvents', $ExportBuilderPageAssignmentModalEvents)
    .constant('PageSpecifications', PageSpecifications)
    .constant('PageAspectRatio', PageAspectRatio)
    .constant('PageFormatConstants', PageFormatConstants)
    .constant('PageFormatDropdownConstants', PageFormatDropdownConstants)
    .constant('PageOrientation', PageOrientation)
    .constant('PageGridConstants', PageGridConstants)
    .constant('ExportBuilderPanelConstants', ExportBuilderPanelConstants)
    .constant('ExportType', ExportType)
    .constant('ExportBuilderDashboardConstants', ExportBuilderDashboardConstants)
    .constant('ZIndexProps', ZIndexProps)
    .constant('ExportBuilderPrefixes', ExportBuilderPrefixes)
    .constant('ReportElementTypes', ReportElementTypes)
    .constant('ReportElementMacroTypes', ReportElementMacroTypes)
    .constant('ReportElementShapeTypes', ReportElementShapeTypes)
    .constant('PageLayerPanel', PageLayerPanel)
    .constant('BuilderPanel', BuilderPanel)
    .constant('ReportElementBaseOptions', ReportElementBaseOptions)
    .constant('ReportElementDesignOptions', ReportElementDesignOptions)
    .constant('ReportElementIconDesignOptions', ReportElementIconDesignOptions)
    .constant('ReportElementShapeDesignOptions', ReportElementShapeDesignOptions)
    .constant('ReportElementImageDesignOptions', ReportElementImageDesignOptions)
    .constant('ReportElementImageSupportedFillModes', ReportElementImageSupportedFillModes)
    .constant('ReportPageDesignOptions', ReportPageDesignOptions)
    .constant('ReportElementTextDesignOptions', ReportElementTextDesignOptions)
    .constant('ReportElementDesignOptionConstants', ReportElementDesignOptionConstants)
    .constant('ReportTemplateType', ReportTemplateType)
    .constant('ExportBuilderPageAssignmentType', ExportBuilderPageAssignmentType)
    .constant('MenuType', MenuType)
    .constant('FilterModeType', FilterModeType)
    .constant('$ExportBuilderIconModalEvents', $ExportBuilderIconModalEvents)
    .constant('$ExportBuilderShortcutsModalEvents', $ExportBuilderShortcutsModalEvents)
    .constant('$ExportBuilderFormatChangeModalEvents', $ExportBuilderFormatChangeModalEvents)
    .constant('$ExportBuilderQuickExportModalEvents', $ExportBuilderQuickExportModalEvents)
    .constant('$ExportBuilderTemplateDownloadModalEvents', $ExportBuilderTemplateDownloadModalEvents)
    .constant('$ExportBuilderTemplatePublishModalEvents', $ExportBuilderTemplatePublishModalEvents)
    .constant('HelloSignClientIds', HelloSignClientIds)
    .constant('ReportEmptyExecutiveSummary', ReportEmptyExecutiveSummary);
