/**
 * @class GaugeHand
 */
export class GaugeHand {
  /**
   * @var {String/Number}
   */
  value;

  /**
   * @var {Number}
   */
  comparativeValue;

  /**
   * Color of the hand
   * @var  {string}
   */
  fill;

  /**
   * Opacity
   * @var {number}
   */
  opacity;

  /**
   * Inside the gauge?
   * @var {boolean}
   */
  inside;

  /**
   * Whether we're drawing the hand on a single axis gauge or a multi-axis
   * @var {boolean}
   */
  isSingleAxis;

  /**
   * @var {BigNumber}
   */
  bigNumber;

  /**
   * @var {number}
   */
  rangeIndex;

  handIsInvalid() {
    if (this.comparativeValue !== -1) {
      return this.value > this.comparativeValue;
    }

    return false;
  }

  constructor(model = {}) {
    this.value = model.value;
    this.comparativeValue = model.comparativeValue ?? -1;
    this.fill = model.fill;
    this.opacity = model.isComparison ? 0.6 : 1;
    this.inside = model.inside;
    this.isSingleAxis = model.isSingleAxis ?? true;
    this.bigNumber = model.bigNumber;
    this.rangeIndex = model.rangeIndex;
  }
}
