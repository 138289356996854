import { DatasourcedWidgetMetadata } from '@/modules/ta/widget/models/metadata/DataSourcedWidgetMetadata';

export class MediaWidgetMetadata extends DatasourcedWidgetMetadata {
  /**
   * Widget content.
   *
   * @var {string}
   */
  content;

  /**
   * Allows us to use what's already in the DB as the content to be saved.
   *
   * @var {boolean}
   */
  keep_content;

  /**
   * @var {SelectedEntity}
   */
  entity;

  constructor(model = {}) {
    super(model);

    this.content = model.content;
    this.keep_content = model.keep_content;
    this.entity = model.entity;
  }
}
