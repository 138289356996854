import SerialWidgetRenderService from '@/modules/ta/widget/services/types/SerialWidgetRenderService';
import { SeriesType } from '@/modules/core/charts/am5/charts.constants';
import { ComboChartConfig } from '@/modules/core/charts//am5/combo/models/ComboChartConfig';
import { appendPercentageInLabel } from '@/modules/core/charts/adapters.helper';

export default class ComboChartWidgetRenderService extends SerialWidgetRenderService {
  /**
   * @param config
   * @returns {ComboChartConfig}
   */
  initConfig(config) {
    return new ComboChartConfig(config);
  }

  /**
   * @param column
   * @returns {string}
   */
  getSeriesType(column) {
    const widget = this.getWidget();
    const lineColumns = Object.keys(widget.metadata.line_columns);
    if (lineColumns.includes(column.field)) {
      if (this.isSmoothedLine()) {
        return this.isRotated() ? SeriesType.SMOOTHEDLINE_XY : SeriesType.SMOOTHEDLINE_X;
      }
      return SeriesType.LINE;
    }
    return SeriesType.COLUMN;
  }

  /**
   * @returns {boolean}
   */
  hasMixedSeriesType() {
    const widget = this.getWidget();
    const lineColumns = Object.keys(widget.metadata.line_columns);
    const similarTypeColumnsCount = this.nonGroupedColumns.reduce(
      (count, column) => (lineColumns.includes(column.field) ? count + 1 : count),
      0
    );
    return this.nonGroupedColumns.length > similarTypeColumnsCount > 0;
  }

  /**
   * @returns {boolean}
   */
  isMixedSeriesAndNotNormalized() {
    return (
      this.hasMixedSeriesType() && !this.getDrawOptions().is_normalized && this.isFullStacked()
    );
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {{}}
   */
  getValueAxisProperties(column, columnIndex) {
    return {
      ...super.getValueAxisProperties(column, columnIndex),
      labelAdapter: this.isFullStacked() ? appendPercentageInLabel : null,
    };
  }

  /**
   * @returns {{}}
   */
  getChartValueAxis() {
    return super.getChartValueAxis();
  }

  /**
   * @returns {boolean}
   */
  isLineFillOpacityRequired() {
    return false;
  }

  /**
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @param isComparison
   * @returns {{}}
   */
  getSeries(column, columnIndex, axisIndex, isComparison = false) {
    const seriesType = this.getSeriesType(column);
    const typeBasedProperties = [
      SeriesType.LINE,
      SeriesType.SMOOTHEDLINE_X,
      SeriesType.SMOOTHEDLINE_Y,
      SeriesType.SMOOTHEDLINE_XY,
    ].includes(seriesType)
      ? super.getLineSeries(column, columnIndex, isComparison)
      : super.getBarSeries(column, columnIndex, isComparison);
    return {
      ...super.getSeries(column, columnIndex, axisIndex, isComparison),
      ...typeBasedProperties,
      seriesType,
    };
  }

  /**
   * @param hasComparison
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @returns {[]}
   */
  getAllSeriesForColumn(hasComparison, column, columnIndex, axisIndex) {
    return super.getAllSeriesForColumn(hasComparison, column, columnIndex, axisIndex);
  }

  /**
   * @returns {{}}
   */
  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
      ...super.getBarChartConfigProperties(),
    };
  }
}
