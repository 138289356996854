'use strict';
import angular from 'angular';

angular.module('core.messenger.constants', [])

    .constant('$MessengerEvents', {
        INIT_PANEL: '$MessengerEvents:INIT_PANEL:',
        EDIT_THREAD: '$MessengerEvents:EDIT_THREAD:',
        SAVE_THREAD: '$MessengerEvents:SAVE_THREAD:',
        SAVE_THREAD_CALLBACK: '$MessengerEvents:SAVE_THREAD_CALLBACK:',
        DELETE_THREAD: '$MessengerEvents:DELETE_THREAD:',
        DELETE_THREAD_CALLBACK: '$MessengerEvents:DELETE_THREAD_CALLBACK:',
    });