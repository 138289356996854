"use strict";
import $ from 'jquery';

var Core = $.core.reporting.profile = {
    initialized: false,
    columnClones: null, //Used to restore initial state of columns
    initialize: function (canEdit) {
        this.build(canEdit);
        this.events(canEdit);
    },
    build: function (canEdit) {
        if (canEdit) {
            $('ul.sortable-columns').sortable({
                stop: function () {
                    Core.setGridData(true);
                },
                done: function () {
                    Core.setGridData(false);
                }
            });
        }
        this.tabs();
    },
    events: function (canEdit) {
        if (canEdit) {
            $('#enable_scores').change(Core.updateScoreCheckboxes());

            $('li[data-field=score]').tooltip({
                placement: 'bottom',
                title: 'You can enable or disable scores with the "Enable Scores?" setting at the top of this page'
            });
            $('li.web-only').tooltip({placement: 'bottom'});

            Core.initMoveColumns();

            $('div.tab-content').on('click', 'span.move-column', function (e) {
                e.preventDefault();
                Core.moveSingleColumn($(this));

                Core.initMoveColumns();
                Core.setGridData(true);
            });

            $('div.tab-content').on('click', 'a.moveAll', function (e) {
                e.preventDefault();
                $(this).parent().parent().find('span.move-column').each(function(){
                    Core.moveSingleColumn($(this));
                });

                Core.initMoveColumns();
                Core.setGridData(true);
            });

            $('#main-form').submit(function(e) {
                Core.setGridData(false);
                $(this).find('select, input[type=text]').each(function(i) {
                    var $input = $(this);
                    if ($input.val() == '')
                        $input.attr('disabled', 'disabled');
                });
            });

            //Extra logic needed to restore original column ordering and active/inactive state
            $.core.main.restoreFormCallback = Core.restoreColumnStates;
            Core.columnClones = $('ul.columns').clone(true);
        }
        else {
            $('#main-form').find('select, input').disableElement();
        }

        $('a.showAllServicesInReportingProfile').click(function() {
            if ($.core.main.formHasChanged && !$.core.main.ignoreFormChange) {
                $.core.main.navAwayUrl = $(this).attr('href');
                $.core.main.showModal($('#form-nav-away-confirm-modal'));
                return false;
            }
        });

    },
    setGridData: function(triggerChange) {
        var grid_data = $.map($('ul.sortable-columns'), function(elm) {
            return { grid_name: $(elm).data('gridName'), columns: $(elm).sortable('toArray', {attribute: 'data-field'}) };
        });

        $('#grid_data').val(JSON.stringify(grid_data));
        if (triggerChange) {
            $('#grid_data').trigger('change');
        }
    },
    tabs: function() {
        //Services
        $('div.service-tab').click(function() {
            $('div.service-tab').removeClass('active');
            $(this).addClass('active');

            //Show the associated content
            $('div.service-content').hide();
            $(this).next('div.service-content').show();

            //Scroll back up to ensure we are back in context when clicking low tab items
            if ($('[class$=-container]').offset().top < 50) {
                $('#right-frame').scrollTop(0);
            }
        });

        //Categories
        $('div.category-tab').click(function() {
            $('div.category-tab').removeClass('active');
            $(this).addClass('active');

            //Show the associated content
            $('div.category-content').hide();
            $(this).next('div.category-content').show();

            //Scroll back up to ensure we are back in context when clicking low tab items
            if ($('div.category-container').offset().top < 50) {
                $('#right-frame').scrollTop(0);
            }
        });
    },
    updateScoreCheckboxes: function () {
        var checked_value = '';
        if ($('#enable_scores').val() == 'yes') {
            checked_value = 'checked';
        }
        else {
            checked_value = false;
        }

        $('.scoreCheckbox').attr('checked', checked_value);

        //move columns
        $('li[data-field=score]').each(function() {
            var parentList = $(this).parent();
            if (parentList.hasClass('sortable-columns')) {
                if ($('#enable_scores').val() != 'yes') {
                    //remove
                    $('ul.not-sortable-columns[data-grid-name='+parentList.attr('data-grid-name')+']').prepend($(this).clone());
                    $(this).remove();
                }
            }
            else {
                //add
                $('ul.sortable-columns[data-grid-name='+parentList.attr('data-grid-name')+']').prepend($(this).clone());
                $(this).remove();
            }
        });
    },
    initMoveColumns: function () {
        $('ul.sortable-columns li span.move-column').html('remove &rarr;');
        $('ul.not-sortable-columns li span.move-column').html('&larr; add');
        $('ul.sortable-columns li[data-field=score] span.move-column').remove();
        $('ul.not-sortable-columns li[data-field=score] span.move-column').remove();
    },
    restoreColumnStates: function () {
        $('ul.columns').each(function(i) {
            $(this).html($(Core.columnClones[i]).html());
        });
    },
    moveSingleColumn: function(ColumnButton){

        var parentList = ColumnButton.parent().parent();

        if (parentList.hasClass('sortable-columns')) {
            //remove
            const $element = $('ul.not-sortable-columns[data-grid-name=' + parentList.attr('data-grid-name') + ']');
            $element.append(ColumnButton.parent().clone());
            $element.find('.list-group-item').sort(function (a, b) {
                return +a.dataset.index - +b.dataset.index;
            }).appendTo($element)
        }
        else {
            //add
            $('ul.sortable-columns[data-grid-name=' + parentList.attr('data-grid-name') + ']').append(ColumnButton.parent().clone());
        }

        ColumnButton.parent().remove();
    }
};