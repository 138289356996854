import { commits } from '@/modules/core/app/helpers/store';
import DateRangeService from '@/modules/core/daterange/services/DateRangeService';

export const actions = {
  /**
   * @returns {Promise<*>}
   */
  async setDateRange({}, dateRange = null) {
    commits.daterange.setDateRange(dateRange ?? (await DateRangeService.getDateRange()));
  },

  async updateCurrentAndPriorDateRange(
    {},
    { startDate, endDate, comparisonStartDate, comparisonEndDate }
  ) {
    commits.daterange.updateCurrentAndPriorDateRange({
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    });
  },

  /**
   *
   * @param dateRange {DateRange}
   * @returns {Promise<*|void|undefined>}
   */
  async setNewDateRange({}, dateRange) {
    try {
      await DateRangeService.setDateRange(dateRange);
      return commits.daterange.setDateRange(dateRange);
      // eslint-disable-next-line no-empty
    } catch (error) {
      Logger.log(error, Logger.LEVEL_ERROR);
    }
  },
  /**
   * @param key
   * @returns {Promise<*>}
   */
  async updateRelativeDateRangeKey({}, key) {
    return commits.daterange.updateRelativeDateRangeKey(key);
  },
  /**
   * @param state
   * @param value
   * @param updateDateRange
   * @returns {Promise<*>}
   */
  async setComparisonActive({ state }, { value, updateDateRange = true }) {
    updateDateRange && DateRangeService.setDateRange(state.dateRange);
    return commits.daterange.setComparisonActive(value);
  },
  /**
   * @param startDate
   * @param endDate
   * @returns {Promise<*>}
   */
  async updateCurrentDateRange({}, { startDate, endDate }) {
    return commits.daterange.updateCurrentDateRange({ startDate, endDate });
  },
  /**
   * @param startDate
   * @param endDate
   * @returns {Promise<*>}
   */
  async updateComparisonDateRange({}, { startDate, endDate }) {
    return commits.daterange.updateComparisonDateRange({ startDate, endDate });
  },
  /**
   * @param key
   * @returns {Promise<*>}
   */
  async updateComparisonPeriod({}, key) {
    return commits.daterange.updateComparisonPeriod(key);
  },
  /**
   * @returns {Promise<*>}
   */
  async setRelativeDateRanges({}, relativeRanges = null) {
    commits.daterange.setRelativeDateRanges(
      relativeRanges ?? (await DateRangeService.getRelativeDateRanges())
    );
  },
  /**
   * @returns {Promise<*>}
   */
  async updateComparisonRelativeDateRanges({}, ranges) {
    commits.daterange.updateComparisonRelativeDateRanges(ranges);
  },
};
