import { ChartConfig } from '@/modules/core/charts/am5/base/models/ChartConfig';
import { SeriesType } from '@/modules/core/charts/chart.constants';
import { isNil } from 'lodash';
import { BackgroundGradientType } from '@/modules/core/charts/am5/charts.constants';
/**
 * @class PieChartConfig
 * @extends SliceChartConfig
 * @extends ChartConfig
 */
export class PieChartConfig extends ChartConfig {
  /**
   * Inner radius for pie chart in percentage string
   * @var {Number}
   */
  innerRadius;

  /**
   * Size of radius in percentage relative to container
   * @var {Number}
   */
  radius;

  /**
   * Starting angle of the Pie circle
   * Sum of startAngle and endAngle must be 360 for full circle
   * @var {Number}
   */
  startAngle;

  /**
   * Ending angle of the Pie circle
   * Sum of startAngle and endAngle must be 360 for full circle
   * @var {Number}
   */
  endAngle;

  /**
   * Default Color Palette contains list of available colors
   * @var {Array}
   */
  colorPalette;

  /**
   * Opacity value for prior comparison
   * @var {Number}
   */
  fillOpacityPrior;

  /**
   * Opacity value for current comparison
   * @var {Number}
   */
  fillOpacityCurrent;

  /**
   * Actual comparison status
   * @var {Boolean}
   */
  hasComparisonStatus;

  /**
   * Hiding label percentage
   * @var {Number}
   */
  labelPct;

  /**
   * Other label percentage
   * @var {Number}
   */
  otherPct;

  /**
   * Plot Type identifier
   * @var {String}
   */
  plotType;

  /**
   * @var {number}
   */
  roundedCorner;

  /**
   * @var {boolean}
   */
  showColumnShadow;

  /**
   * @var {number}
   */
  pieSemiCircleAngle;

  /**
   * Plot Type identifier
   * @var {String}
   */
  gradientType;

  /**
   * Plot Type identifier
   * @var {String}
   */
  gradientColor;

  /**
   * @var {boolean}
   */
  showGrainyPattern;

  /**
   * @var {number}
   */
  grainyPatternSize;

  /**
   * @var {boolean}
   */
  propsComparisonEnabled;

  /**
   * @var {number}
   */
  grainyPatternDensity;

  /**
   * @var {boolean}
   */
  showDonutSum;

  /**
   * @var {boolean}
   */
  showBorders;

  /**
   * @var {Array<widgetData>}
   */
  widgetData;

  /**
   * @var {boolean}
   */
  isNormalized;

  /**
   * @var {string}
   */
  pieGradientOptions;

  /**
   * @var {string}
   */
  pieColorPicker;

  /**
   * @var {boolean}
   */
  showLabels;

  /**
   * @var {boolean}
   */
  showLabelNames;

  /**
   * @var {boolean}
   */
  showLabelValues;

  /**
   * @var {boolean}
   */
  showLabelPercent;

  /**
   * @var {boolean}
   */
  pieVariableRadius;

  /**
   * @var {boolean}
   */
  classicDrillDownBehaviour;

  /**
   * @var {boolean}
   */
  showToolTip;

  /**
   * @var {string}
   */
  fontColorPicker;

  /**
   * @var {string}
   */
  alignLabels;

  constructor(model = {}) {
    super(model);
    const {
      plot_type,
      rounded_corners,
      show_column_shadows,
      pie_semicircle_angle,
      is_normalized,
      gradientType,
      gradientColor,
      showGrainyPattern,
      grainyPatternSize,
      grainyPatternDensity,
      showDonutSum,
      showBorders,
      pieGradientOptions,
      pieColorPicker,
      v2InnerRadius,
      show_labels,
      show_label_names,
      show_label_values,
      show_label_percent,
      pieVariableRadius,
      classicDrillDownBehaviour,
      has_tooltip,
      fontColorPicker,
      alignLabels,
    } = model.drawOptions;
    this.series = model.series;
    this.series.forEach((singleSeries) => {
      singleSeries.seriesType = SeriesType.PIE;
    });
    this.radius = model.radius;
    this.startAngle = model.startAngle;
    this.endAngle = model.endAngle;
    this.innerRadius = v2InnerRadius;
    this.colorPalette = model.colorPalette;
    this.fillOpacityPrior = model.fillOpacityPrior;
    this.fillOpacityCurrent = model.fillOpacityCurrent;
    this.hasComparisonStatus = model.hasComparisonStatus;
    this.labelPct = model.labelPct;
    this.otherPct = model.otherPct;
    this.plotType = plot_type;
    this.roundedCorner = rounded_corners;
    this.showColumnShadow = show_column_shadows;
    this.pieSemiCircleAngle = pie_semicircle_angle;
    this.gradientType = isNil(gradientType) ? BackgroundGradientType.SOLID : gradientType;
    this.gradientColor = gradientColor;
    this.showGrainyPattern = showGrainyPattern;
    this.grainyPatternSize = grainyPatternSize;
    this.propsComparisonEnabled = model.propsComparisonEnabled;
    this.isNormalized = is_normalized;
    this.grainyPatternDensity = grainyPatternDensity;
    this.showDonutSum = showDonutSum;
    this.showBorders = showBorders;
    this.widgetData = model.widgetData;
    this.pieGradientOptions = isNil(pieGradientOptions)
      ? BackgroundGradientType.SOLID
      : pieGradientOptions;
    this.pieColorPicker = pieColorPicker;
    this.showLabels = show_labels;
    this.showLabelNames = show_label_names;
    this.showLabelValues = show_label_values;
    this.showLabelPercent = show_label_percent;
    this.pieVariableRadius = pieVariableRadius;
    this.classicDrillDownBehaviour = classicDrillDownBehaviour;
    this.showToolTip = has_tooltip;
    this.fontColorPicker = fontColorPicker;
    this.alignLabels = alignLabels;
  }
}
