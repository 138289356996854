export const mutations = {
  /**
   * @param state
   * @param {DataCalendarModel} model
   */
  addModel(state, model) {
    state.modelSet = { ...state.modelSet, [model.id]: model };
  },
  updateModel(state, { id, value }) {
    const model = state.modelSet[id];
    if (model) {
      state.modelSet[id] = value;
    }
  },
  /**
   * @param state
   * @param id
   */
  removeModel(state, id) {
    delete state.modelSet[id];
  },
};
