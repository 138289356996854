'use strict';
import angular from 'angular';

export const $ExportBuilderUndoRedoEvents = {
    'UNDO': '$ExportBuilderUndoRedoEvents:UNDO',
    'REDO': '$ExportBuilderUndoRedoEvents:REDO'
};

angular.module('exportbuilder.undo.constants' , [])
    .constant('$ExportBuilderUndoRedoEvents', $ExportBuilderUndoRedoEvents);
