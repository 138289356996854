import { assign } from 'lodash';
import { buildColumnsFromData } from '@/modules/core/app/utils/ApiUtil';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';

export class BaseListService extends BaseService {
  /**
   * @var {BaseEntityResource}
   */
  resource;

  /**
   * @var {CustomColumnsConfig}
   */
  customColumnsConfig;

  /**
   * @var bool
   */
  useStrictMode;

  /**
   * @param resource
   * @param customColumnsConfig
   * @param useStrictMode
   */
  constructor(resource, customColumnsConfig = null, useStrictMode = false) {
    super();
    this.resource = resource;
    this.customColumnsConfig = customColumnsConfig;
    this.useStrictMode = useStrictMode;
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  async getData(params = {}) {
    params = assign({ all: true }, params);
    return this.resource.getItems(params);
  }

  /**
   * @param id
   * @param params
   * @returns {Promise<*>}
   */
  async getItem(id, params = {}) {
    params = assign({ all: true }, params);
    return this.resource.getItem(id, params);
  }

  /**
   * @param columns
   * @returns {Promise<any[]>}
   */
  async getColumns(columns = [], params = {}) {
    params = assign({ summary: true }, params);
    return columns.length ? columns : this.resource.getColumns(null, params);
  }

  /**
   * @param columns
   * @param data
   * @returns {array}
   */
  getColumnsFromData(columns = [], data = {}) {
    return columns.length
      ? columns
      : buildColumnsFromData(data.aaData || data, this.customColumnsConfig);
  }

  /**
   * @param params
   * @param columns
   * @returns {Promise<*>}
   */
  async getDataAndColumns(params, columns) {
    let data;
    let columnsMetadata;
    if (this.hasCustomColumns()) {
      data = await this.getData(params);
      columnsMetadata = this.getColumnsFromData(columns, data);
    } else if (this.useStrictMode) {
      columnsMetadata = await this.getColumns(columns, params);
      const fields = columnsMetadata.map((item) => item.field);
      params = assign({ strict: 1, fields: fields.join(',') }, params);
      data = await this.getData(params);
    } else {
      [data, columnsMetadata] = await Promise.all([
        this.getData(params),
        this.getColumns(columns, params),
      ]);
    }
    return [data, columnsMetadata];
  }

  /**
   * @returns {Promise<any[]>}
   */
  async getPrimaryColumn() {
    const params = { summary: false, is_primary_key: true };
    const columns = await this.resource.getColumns(null, params);
    return columns[0];
  }

  /**
   * Override this method to track deletion in list
   * @param {BaseModel} data
   */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-empty-function */
  async trackDelete(data) {}

  /**
   * Override this method to track copy in list
   * @param {BaseModel} data
   */
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-empty-function */
  async trackCopy(data) {}

  /**
   * Override this method to track export in list
   */
  /* eslint-disable no-empty-function */
  async trackExport() {}

  /**
   * @param id
   * @returns {Promise<AxiosPromise<any>>}
   */
  async copyItem(id) {
    const response = await this.resource.copyItem(id);
    return response;
  }

  /**
   * @param id
   * @returns {Promise<AxiosPromise<any>>}
   */
  async deleteItem(id) {
    const response = await this.resource.deleteItem(id);
    return response;
  }

  /**
   * @returns {Promise<void>}
   */
  async exportList(config = {}) {
    const response = await this.resource.exportList(config);
    this.trackExport();
    return response;
  }

  /**
   * @returns {boolean}
   */
  hasCustomColumns() {
    return !!this.customColumnsConfig;
  }
}
