import angular from 'angular';
import $ from 'jquery';

import loginHtmlUrl from '../views/login.html';
import forgotPasswordHtmlUrl from '../views/forgot_password.html';
import resetPasswordHtmlUrl from '../views/reset_password.html';

angular.module('core.login.components', [])
    .component('loginComponent', {
        bindings: {
            'settings': '<'
        },
        templateUrl: loginHtmlUrl,
        controller: LoginController,
        controllerAs: 'vm'
    })
    .component('forgotPassword', {
        bindings: {
            'settings': '<'
        },
        templateUrl: forgotPasswordHtmlUrl,
        controller: ForgotPasswordController,
        controllerAs: 'vm'
    })
    .component('resetPassword', {
        bindings: {
            'settings': '<'
        },
        templateUrl: resetPasswordHtmlUrl,
        controller: ResetPasswordController,
        controllerAs: 'vm'
    });

/**
 *
 * @param LoginFactory
 * @constructor
 */
function ResetPasswordController(
    LoginFactory,
    $stateParams
) {
    var vm = this;
    vm.isResetting = false;
    vm.model = {'password': '', 'password_confirm': ''};
    vm.date = new Date();
    vm.onSubmit = onSubmit;

    function onSubmit() {
        vm.isResetting = true;
        var resetModel = angular.copy(vm.model);
        resetModel.user_id = $stateParams.user_id;
        resetModel.request_id = $stateParams.request_id;
        resetModel.verification_code = $stateParams.verification_code;
        LoginFactory.resetPassword(resetModel).error(function() {
            vm.isResetting = false;
        });
    }
}

/**
 *
 * @param LoginFactory
 * @constructor
 */
function ForgotPasswordController(
    $stateParams,
    LoginFactory
) {
    var vm = this;
    vm.message_id = $stateParams.message_id;
    vm.date = new Date();
    vm.email = "";
    vm.isSendingEmail = false;
    vm.onSubmit = onSubmit;

    function onSubmit() {
        var model = {'email': vm.email};
        vm.isSendingEmail = true;
        LoginFactory.sendResetPasswordLink(model).error(function () {
            vm.isSendingEmail = false;
        });
    }
}

/**
 *
 * @param LoginFactory
 * @constructor
 */
function LoginController(
    $scope,
    $rootScope,
    $stateParams,
    LoginFactory
) {
    var vm = this;
    vm.isHipaaInstance = false;
    vm.message_id = $stateParams.message_id;
    vm.model = {'email': '', 'password': '', 'hipaa_acknowledgement': false};
    vm.date = new Date();
    vm.isLoggingIn = false;
    vm.onSubmit = onSubmit;
    vm.$onInit = $onInit;

    function $onInit() {
        $.core.main.initNotify();
        $.core.login.initialize();
        //ensure all the opened modals are closed when showing the login form
        //Note: if you plan to add a modal to the login view, make sure this code
        //		does not close it unexpectedly:
        $('.modal:visible').each(function() {
            $(this).modal('hide');
        });
    }

    function onSubmit() {
        vm.isLoggingIn = true;
        var model = vm.model;
        LoginFactory.login(model).error(function (response) {
            vm.isLoggingIn = false;
            if (response.status === 401) {
                if (response.error_type === 'hipaa_acknowledgement_required') {
                    vm.isHipaaInstance = true;
                }
            }
        });
    }
}