import { PlotType } from '@/modules/ta/widget/widget.constants';
import plotTypeLine from '@/assets/plot-type-line.svg';
import plotTypeLineArea from '@/assets/plot-type-area.svg';
import plotTypeStacked from '@/assets/plot-type-stacked-line.svg';
import plotTypeFullStacked from '@/assets/plot-type-full-stacked-line.svg';
import plotTypeClusteredBar from '@/assets/plot-type-clustered-bar.svg';
import plotTypeStackedBar from '@/assets/plot-type-stacked-bar.svg';
import plotTypeFullStackedBar from '@/assets/plot-type-full-stacked-bar.svg';
import plotTypeDeepStackedBar from '@/assets/plot-type-deep-stacked-bar.svg';
import plotTypeGeoBubble from '@/assets/plot-type-geo-bubble.svg';
import plotTypeGeoHeat from '@/assets/plot-type-geo-heat.svg';

export const DatagridOverflowOptions = [
  {
    key: false,
    value: 'Horizontal scroll',
  },
  {
    key: true,
    value: 'Collapsed under "Show More"',
  },
];

export const IframePreviewOptions = [
  { key: true, value: 'Iframe' },
  { key: false, value: 'Image' },
];

export const DatagridDisplayLengthOptions = [
  { key: 3, value: 3 },
  { key: 5, value: 5 },
  { key: 10, value: 10 },
  { key: 15, value: 15 },
  { key: 20, value: 20 },
  { key: 25, value: 25 },
  { key: 50, value: 50 },
  { key: 75, value: 75 },
  { key: 100, value: 100 },
];

export const DatagridReportDisplayLengthKeys = {
  DEFAULT: 'default',
  ALL: -1,
};

export const DatagridReportDisplayLengthOptions = [
  { key: DatagridReportDisplayLengthKeys.DEFAULT, value: 'Default' },
  { key: 3, value: 3 },
  { key: 5, value: 5 },
  { key: 10, value: 10 },
  { key: 15, value: 15 },
  { key: 20, value: 20 },
  { key: 25, value: 25 },
  { key: 50, value: 50 },
  { key: 75, value: 75 },
  { key: 100, value: 100 },
  { key: DatagridReportDisplayLengthKeys.ALL, value: 'All' },
];

export const BigNumberCircleOptions = [
  { key: true, value: 'Circle' },
  { key: false, value: 'Rectangle' },
];

export const LinePlotTypeOptions = [
  { key: PlotType.LINE, label: 'Line', img: plotTypeLine },
  { key: PlotType.AREA, label: 'Area', img: plotTypeLineArea },
  { key: PlotType.STACKED, label: 'Stacked', img: plotTypeStacked },
  { key: PlotType.FULL_STACKED, label: '100% Stacked', img: plotTypeFullStacked },
];

export const BarPlotTypeOptions = [
  { key: PlotType.CLUSTERED, label: 'Clustered', img: plotTypeClusteredBar },
  { key: PlotType.STACKED, label: 'Stacked', img: plotTypeStackedBar },
  { key: PlotType.FULL_STACKED, label: '100% Stacked', img: plotTypeFullStackedBar },
  { key: PlotType.DEEP_STACKED, label: 'Deep Stacked', img: plotTypeDeepStackedBar },
];

export const GeoPlotTypeOptions = [
  { key: PlotType.BUBBLE_MAP, label: 'Bubble', img: plotTypeGeoBubble },
  { key: PlotType.HEAT_MAP, label: 'Heat', img: plotTypeGeoHeat },
];

export const DateFormatType = {
  DEFAULT: 'default',
  NO_YEAR: 'noYear',
};

export const PieChartDrawOption = {
  MAX_RADIUS: 70,
  MAX_FREE_DEPTH: 20,
  DEFAULT_START_ANGLE: -90, // This is amchart default
  OTHERS_METRIC_LABEL: 'Others',
  SHOW_LABEL_PERCENT: '{valuePercentTotal}%',
  SHOW_VALUE: '{value}',
  SHOW_CATEGORY: '{category}',
  SHOW_TOOLTIP: '{metric}: {rawValue} ({percent}%)',
  SHOW_VALUE_PIE: 'value',
  SHOW_METRIC_PIE: 'metric',
  SHOW_RAW_VALUE: '{rawValue}',
  NULL_VALUE: 'Unspecified',
};
