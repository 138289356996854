'use strict';
import angular from 'angular';

angular.module('exportbuilder.publish.models', [])
    .factory('ExportBuilderPublishModelFactory', ExportBuilderPublishModelFactory);

/**
 *
 * @ngInject
 */
function ExportBuilderPublishModelFactory(
    TagPickerSelectConstant,
    gettextCatalog,
    ReportTemplateType
) {
    return {
        getExportBuilderPublishItemFormModel: getExportBuilderPublishItemFormModel,
        getExportBuilderPublishTagPickerSelectOptions: getExportBuilderPublishTagPickerSelectOptions
    };
    
    /**
     * @constructor
     */
    function ExportBuilderPublishItemFormModel(model) {
        model = model || {};
        let self = this;

            self.title = model.title || null;
            self.description = null;
            self.report_id = null;
            self.type = ReportTemplateType.TYPE_DESIGN;
            self.tags = [];
        }

    /**
     * Represents the select2 Options for TagPicker
     * @constructor
     */
    function ExportBuilderPublishTagPickerSelectOptions() {
        var self = this;
        var _currentText = '';

        self.allowClear = true;
        self.multiple = true;
        self.placeholder = gettextCatalog.getString('Create or select a tag ...');
        self.formatResult = _formatDisplay;
        self.formatSelection = function(item) {
            return _formatDisplay(item);
        };
        self.formatNoMatches = _formatNoMatches;
        self.matcher = matchCustom;
        self.onTextChange = onTextChange;
        self.didSelect = didSelect;

        /**
         * Select2 override How to show selected value in dropdown or as a selectable value
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatDisplay(item) {
            var result = null;
            if (item.id === TagPickerSelectConstant.PLACEHOLDER_ID) {
                return _.isEmpty(_currentText) ?  item.text : gettextCatalog.getString('<span>Press <b>Enter</b> to add <u><i>{{currentText}}</i></u> as a new tag</span>', {currentText: _currentText});
            } else if (item.text) {
                result = '<span class="icon icon-tag mr2"></span>' + item.text;
            }
            return result;
        }

        /**
         * @param params
         * @returns {string}
         * @private
         */
        function _formatNoMatches(params) {
            return gettextCatalog.getString('<span>Press <b>Enter</b> to add <u><i>{{params}}</i></u> as a new tag</span>', {params: params});
        }

        /**
         * Select2 override to custom match search string
         * @param params
         * @param data
         * @returns {*}
         */
        function matchCustom(params, data) {
            _currentText = params;
            if (data === TagPickerSelectConstant.PLACEHOLDER_TAGS || data === TagPickerSelectConstant.PLACEHOLDER_NO_TAGS) {
                return _formatNoMatches(params);
            }
            if (data.toLowerCase().contains(params.toLowerCase())) {
                return data;
            }
            return null;
        }

        /**
         * UI-Select2 function implementation callback
         * @param data
         * @param text
         */
        function onTextChange($el, data, text) {
            if (_.isEmpty(text) && !_.isEmpty(_currentText)) {
                _currentText = text; // must be set before select2 events
                // swap placeholders to update to original placeholder with no text entered
                _.remove(data, {id: TagPickerSelectConstant.PLACEHOLDER_ID });
                data.unshift({id: TagPickerSelectConstant.PLACEHOLDER_ID, text: TagPickerSelectConstant.PLACEHOLDER_TAGS });

                // keep reference of data
                var inputData = $el.select2('data');
                // trigger select2 internal state change
                $el.trigger('change.select2');
                // force open
                $el.select2('open');
                // reset data
                $el.select2('data', inputData);
            }
            // this line is here in case above condition is not met yet.
            _currentText = text;
        }

        /**
         * Helper function for clean up after selection
         */
        function didSelect() {
            _currentText = '';
        }
    }
    
    /**
     * @returns {ExportBuilderPublishItemFormModel}
     */
    function getExportBuilderPublishItemFormModel(model) {
        return new ExportBuilderPublishItemFormModel(model);
    }

    /**
     * @returns {ExportBuilderPublishTagPickerSelectOptions}
     */
    function getExportBuilderPublishTagPickerSelectOptions() {
        return new ExportBuilderPublishTagPickerSelectOptions();
    }
}