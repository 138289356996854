'use strict';
import angular from 'angular';
import _ from 'lodash';
import './base/exportbuilder.directives';
import './base/exportbuilder.helper.service';
import './base/exportbuilder.components';
import './base/exportbuilder.models';
import './base/exportbuilder.resources';
import './base/exportbuilder.services';
import './exportbuilder.constants';
import './dashboard/init/exportbuilder.init.services';
import './admin/exportbuilder.admin.module';
import './dashboard/base/exportbuilder.dashboard.module';
import './dashboard/header/exportbuilder.header.module';
import './dashboard/layers/exportbuilder.layers.module';
import './dashboard/panel/exportbuilder.panel.module';
import './dashboard/publish/exportbuilder.publish.module';

import './standalone/exportbuilder.standalone.directives';
import './standalone/exportbuilder.standalone.ctrls';

import initAngularTemplatedDirectives from '../shared/scripts/app.services';

import exportBuilderAdminHtmlUrl from './admin/exportbuilder.admin.html';
import exportBuilderBaseHtmlUrl from './base/exportbuilder.base.html';
import manageReportStudioLibraryHtmlUrl from 'coreModules/exportbuilder/admin/managelibrary/exportbuilder.managelibrary.html';
import $ from "jquery";

angular.module('core.exportbuilder', [
    'exportbuilder.models',
    'exportbuilder.components',
    'exportbuilder.directives',
    'exportbuilder.service',
    'exportbuilder.resources',
    'exportbuilder.helper.service',
    'exportbuilder.constants',

    'exportbuilder.init',
    'exportbuilder.admin',
    'exportbuilder.header',
    'exportbuilder.dashboard',
    'exportbuilder.layers',
    'exportbuilder.panel',
    'exportbuilder.publish'
])
    .config(['$stateProvider', 'ExportBuilderRouterState', 'SwitchAppServiceProvider', function ($stateProvider, ExportBuilderRouterState, SwitchAppServiceProvider) {

        $stateProvider
            .state('managereportstudiolibrary', {
                parent:'layout',
                url: '/managereportstudiolibrary',
                resolve: {
                    isTARoute: SwitchAppServiceProvider.switchAppFn('managereportstudiolibrary'),
                    pageType: function(PageType) {
                        return PageType.TYPE_ADMIN;
                    },
                    pageEntity: function(PageEntity) {
                        return PageEntity.PAGE_MANAGE_REPORT_STUDIO_LIBRARY;
                    },
                    vars: function() {
                        return null;
                    }
                },
                views: {
                    '': {
                        templateUrl: manageReportStudioLibraryHtmlUrl,
                        controller: 'DesignController'
                    }
                }
            })
            .state(ExportBuilderRouterState.EXPORT_BUILDER_ADMIN, {
                parent:'layout',
                url: '/reportstudio',
                templateUrl: exportBuilderAdminHtmlUrl,
                resolve: {
                    _isTARoute: SwitchAppServiceProvider.switchAppFn(ExportBuilderRouterState.EXPORT_BUILDER_ADMIN)
                }
            })
            .state(ExportBuilderRouterState.EXPORT_BUILDER_STUDIO, {
                url: '/reportstudio/:id?sample_data',
                templateUrl: exportBuilderBaseHtmlUrl,
                resolve: {
                    _isTARoute: SwitchAppServiceProvider.switchAppFn(ExportBuilderRouterState.EXPORT_BUILDER_STUDIO),
                    _globalFilters: function (ExportBuilderFilterResource, ReportStudioTemplateDataService, $stateParams, UserLoginFactory, AppFactory, $state) {
                        if ($stateParams.id) {
                            return ExportBuilderFilterResource.getGlobalFiltersOptions($stateParams.id)
                                .then(filterOptions => {
                                    ReportStudioTemplateDataService.setGlobalFilters(filterOptions);
                                }).catch(() => {
                                    const route = UserLoginFactory.getDefaultHomeRoute(AppFactory.getUser(), true);
                                    $state.go(route.state, route.params, route.options);
                                });
                        }
                    },
                    _userSession: function (LoginFactory,AppFactory) {
                        return LoginFactory.getUserSession().then(function (json) {
                            AppFactory.setUser(json.data.settings);
                            $.globals.utils.currencyCode = json.data.settings.currencyCodeValue;
                            if (json.data.settings.availableModules.includes('adobe_ppt_converter')) {
                                import(/* webpackChunkName: "adobe-export-fonts" */ 'coreModules/export/fonts/adobe.export.fonts.css');
                            }
                        });
                    },
                    _widgetTypes: function (WidgetFactory) {
                        WidgetFactory.getColumnValues('type').then(function (data) {
                            WidgetFactory.setWidgetTypes(data.plain());
                        });
                    },
                    _datasources: function(WidgetFactory, WidgetTypeGrouping, AppFactory) {
                        if (!_.isEmpty(AppFactory.getDataSourceTypes())) {
                            return true;
                        }
                        return WidgetFactory.getMetadataColumnValues(WidgetTypeGrouping.DATASOURCED, null, {fields: 'data_source'}).then(function (json) {
                            AppFactory.setDataSourceTypes(json.plain());
                        });
                    },
                    _services: function($stateParams, ServiceResource, AppFactory) {
                        return ServiceResource.getServices({widget_report_id: $stateParams.id, include_exclusive_live_services: 1}).then(function (json) {
                            AppFactory.setServices(json.plain());
                        });
                    },
                    _websites: function ($injector, AppFactory) {
                        // this service is only available on SMA as of now
                        // for getting serp data into report studio
                        // putting a check here to avoid loading SeoService in Enterprise
                        if ($injector.has('SeoService')) {
                            const seoService = $injector.get('SeoService');
                            return seoService.getOverviewList().then(function (json) {
                                AppFactory.setSeoWebsites(json.items);
                            });
                        }
                        return [];
                    },
                    _dataTypes: function(ColumnFactory, AppFactory) {
                        if (!_.isEmpty(AppFactory.getDataTypes())) {
                            return true;
                        }
                        return ColumnFactory.dataTypes.get().then(function (dataTypes) {
                            AppFactory.setDataTypes(dataTypes.plain());
                        });
                    },
                    _daterange: function(Restangular, AppFactory) {
                        if (!_.isNil(AppFactory.getDateRange().start)) {
                            return true;
                        }
                        return Restangular.one('daterange').one('legacy').get().then(function (json) {
                            var daterange = json.plain();
                            AppFactory.setDateRange(daterange.start, daterange.end, daterange.relative_date_range);
                            AppFactory.setComparisonDateRange(daterange.comparison_start, daterange.comparison_end, daterange.comparison_active);
                        });
                    },
                    _relativeDateRanges: function(DateRangeFactory) {
                        if (!_.isEmpty(DateRangeFactory.getRelativeDateRange())) {
                            return true;
                        }
                        // Since relative date ranges are used app-wide, we resolve them at the app level
                        return DateRangeFactory.resource.get().then(function (relativeDateRanges) {
                            DateRangeFactory.setRelativeDateRanges(relativeDateRanges.plain());
                        });
                    },
                    _entity : function (ReportStudioTemplateDataService, ExportBuilderDashboardService, ClusterService, ClientFactory, ClientGroupFactory) {
                        return ExportBuilderDashboardService.getFilter().then(function (filter) {
                            if (!filter) {
                                return;
                            }

                            if (filter.type === 'client') {
                                return ClientFactory.getFieldValues('id', {id: filter.id}).then(function (json) {
                                    _setEntity(json);
                                });
                            } else if (filter.type === 'client_group') {
                                return ClientGroupFactory.getFieldValues('id', {id: filter.id}).then(function (json) {
                                    _setEntity(json);
                                });
                            } else if (filter.type === 'cluster') {
                                return ClusterService.getFieldValues('id', {id: filter.id}).then(function (json) {
                                    _setEntity(json);
                                });
                            }

                            function _setEntity(json) {
                                filter.text = json.values[0].value;
                                ReportStudioTemplateDataService.setEntity(filter);
                            }
                        });
                    },
                    _themeColors: function (UIFactory) {
                        if (!_.isEmpty(UIFactory.getThemeColors())) {
                            return true;
                        }
                        return UIFactory.colors.get().then(function (json) {
                            return UIFactory.setThemeColors(json.plain());
                        });
                    },
                    _brandMappings: function(Restangular, AppFactory) {
                        if (!_.isEmpty(AppFactory.getBrandMappings())) {
                            return true;
                        }
                        return Restangular.all('branding').one('mappings').get().then(function (json) {
                            return AppFactory.setBrandMappings(json.plain());
                        });
                    },
                    _themeBaseColors: function (UIFactory, TapColorsService) {
                        if (!_.isEmpty(UIFactory.getThemeBaseColors())) {
                            return true;
                        }
                        return UIFactory.baseColors.get().then(function (baseColors) {
                            UIFactory.setThemeBaseColors(baseColors.plain());
                            return TapColorsService.setThemeColors(baseColors.plain());
                        });
                    }
                },
                onEnter: function (ExportBuilderDashboardService, $stateParams, $state, $templateCache) {
                    ExportBuilderDashboardService.setActive(true);
                    initAngularTemplatedDirectives($templateCache);

                    //redirect to list if the id is not defined
                    if (!$stateParams.id || isNaN($stateParams.id)) {
                        return $state.go(ExportBuilderRouterState.EXPORT_BUILDER_ADMIN);
                    }
                }
            })
    }]);
