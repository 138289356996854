import { capitalize as capitalizeLodash, camelCase } from 'lodash';

/** Turns 'camelCase' into 'camel/case'
 *
 * @param str
 * @returns {string}
 */
export function turnCamelCaseToSlashCase(str) {
  const separator = '/';
  return str
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`)
    .toLowerCase();
}

/**
 * snake_case convert to PascalCase
 *
 * @param {string} s
 * @returns {string}
 */
export function fromSnakeCaseToPascalCase(s) {
  return capitalize(camelCase(s), true);
}

/**
 * snake_case converted to Pascal Case
 *
 * @param {string} s
 * @returns {string}
 */
export function fromSnakeCaseToPascalCaseWithSpaces(s) {
  return capitalizeAll(_underscoreToSpace(s));
}

/**
 * snake_case converted to kebad-case
 *
 * @param {string} s
 * @returns {string}
 */
export function fromSnakeCaseToKebabCase(s) {
  return _underscoreToHyphen(s);
}

/**
 * Hyphen ISO TIME converted to Forward Slash ISO TIME
 *
 * @param {string} s
 * @returns {string}
 */
export function fromHyphenISOtoForwardSlashISO(s) {
  return capitalizeAll(_hyphenToForwardSlash(s));
}

/**
 * Capitalizes string
 *
 * @param {string} s
 * @param {boolean} onlyFirstLetter
 * @returns {string}
 */
export function capitalize(s, onlyFirstLetter = false) {
  return onlyFirstLetter ? s.charAt(0).toUpperCase() + s.slice(1) : capitalizeLodash(s);
}

/**
 * Checks if variable is of string type
 *
 * @param {string} string
 * @returns {boolean}
 */
export function isString(string) {
  return typeof string === 'string';
}

/**
 * Capitalizes all words in string
 *
 * @param {string} s
 * @returns {string}
 */
export function capitalizeAll(s) {
  return s.replace(/\w\S*/g, (t) => t.charAt(0).toUpperCase() + t.substr(1).toLowerCase());
}

/**
 * If is a bool and need to be changed to string.
 *
 * @param {boolean} bool
 * @return {string}
 */
export function boolToString(bool) {
  return bool ? 'yes' : 'no';
}

/**
 * If string needs to be changed to bool
 *
 * @param {string} s
 * @return {boolean}
 */
export function stringToBool(s) {
  return ['yes', '1', 'true'].includes(s?.toLowerCase());
}

/**
 * Check if string is valid email format
 *
 * @param {string} s
 * @return {boolean}
 */
export function isValidEmail(s) {
  const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return reg.test(s);
}

/**
 * Removes HTML tags from the string
 *
 * @param {string} htmlString
 * @returns {string|string}
 */
export function stripHtmlTags(htmlString) {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
}

/**
 * @param {string} string
 * @private
 */
function _underscoreToSpace(string) {
  return string.replace(/_/g, ' ');
}

/**
 * @param {string} string
 * @private
 */
function _hyphenToForwardSlash(string) {
  return string.replace(/-/g, '/');
}

/**
 * @param {string} string
 * @private
 */
function _underscoreToHyphen(string) {
  return string.replace(/_/g, '-');
}
