'use strict';
import angular from 'angular';

angular.module('core.filterset.directives', [])

    .directive('myDirective', myDirective);

myDirective.$inject = [];
function myDirective() {
    return {
        restrict: 'A'
    }
}